import React from 'react';

import { FIELDS } from '../../validation';
import AppInput from '../../../../../../components/AppInput';
import OfferField from '../../OfferField';

import style from '../../style.module.scss';
import { Col, Row } from 'reactstrap';
import { Field } from 'formik';
import SelectField from '../../../../../../components/SelectField';

import { flexibilityOptions } from '../../options';

const DatesHours = ({ formik, isEdit, tooltip }) => {

    return (<>
        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Job Start Date"
            click={() => tooltip.set(FIELDS.START)}
        >
            <AppInput
                id={FIELDS.START}
                formik={formik}
                label="Start date (dd/mm/yyyy)"
                type={'date'}
            />
        </OfferField>

        <Row>
            <Col xs={12} md={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Publish Date"
                    click={() => tooltip.set(FIELDS.PUBLISH_DATE)}
                >
                    <AppInput
                        id={FIELDS.PUBLISH_DATE}
                        formik={formik}
                        label="Publish date (dd/mm/yyyy)"
                        type={'date'}
                    />
                </OfferField>
            </Col>
            <Col xs={12} md={6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Expiry Date"
                    click={() => tooltip.set(FIELDS.CLOSE_DATE)}
                >
                    <AppInput
                        id={FIELDS.CLOSE_DATE}
                        formik={formik}
                        label="Close date (dd/mm/yyyy)"
                        type={'date'}
                    />
                </OfferField>
            </Col>
        </Row>

        <div className={style.divider} />

        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Flexibility on Hours"
            click={() => tooltip.set(FIELDS.FLEXIBILITY)}
        >
            <Field
                name={FIELDS.FLEXIBILITY}
                id={FIELDS.FLEXIBILITY}
                component={SelectField}
                options={flexibilityOptions}
                placeholder="Flexibility Hours"
            />
        </OfferField>

        { formik.values[FIELDS.FLEXIBILITY] == 'Fixed' ? (
            <OfferField selected={tooltip.get} isEdit={isEdit} showOnEdit label="Fixed Hours (24 Hr)">
                <Row onClick={() => tooltip.set(FIELDS.FIXED_FROM)} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xs={12} sm={6} style={{ display: 'flex', columnGap: '24px' }}>
                        <AppInput id={FIELDS.HOURS_FROM} formik={formik} label="Hr" />
                        :
                        <AppInput id={FIELDS.MINUTES_FROM} formik={formik} label="Mins" />
                    </Col>
                    <Col xs={12} sm={6} style={{ display: 'flex', columnGap: '24px' }}>
                        <AppInput id={FIELDS.HOURS_TO} formik={formik} label="Hr" />
                        :
                        <AppInput id={FIELDS.MINUTES_TO} formik={formik} label="Mins" />
                    </Col>
                </Row>
            </OfferField>
        ) : null }
    </>)
}

export default DatesHours;