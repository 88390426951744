import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import style from '../style.module.scss';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../../NudgeModal';

export const CompanyVideo = ({ videoLink, isOpen, toggle }) => {
    
    if (videoLink.includes('youtube')) {
        videoLink = videoLink.split('?v=')[1];
    } else if (videoLink.includes('youtu.be')) {
        videoLink = videoLink.split('.be/')[1];
    }

    return videoLink ? (
        <NudgeModal headerText={"Video"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                <div className={style.colDiv}>
                    <div class={style.embedContainer}>
                        <iframe
                            title="company-video"
                            src={`https://www.youtube.com/embed/${videoLink}`}
                            frameborder="0"
                            allowfullscreen
                        />
                    </div>
                </div>
            </ModalBody>
        </NudgeModal>
    ) : (
        'null'
    );
};

export default CompanyVideo;
