import * as Yup from 'yup';

export const FIELDS = { EMAIL: 'email', PASSWORD: 'password' };

export const validationSchema = Yup.object({
    [FIELDS.PASSWORD]: Yup.string().required('This field is required'),
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
});

export const initialValues = {
    [FIELDS.PASSWORD]: '',
    [FIELDS.EMAIL]: '',
};
