import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import style from './style.module.scss';
import ClickButton from '../NudgeButton';
import NudgeButtonGroup from '../ButtonGroup';
import useMobile from '../../../hooks/useMobile';

const SaveButton = ({ formik, onClose }) => {

    const saveSize = onClose ? 6 : 12;

    return (
        <div className={style.saveButtons}>
             <NudgeButtonGroup
                buttons={[
                    { label: 'Cancel', color: 'danger', onClick: onClose, available: !!onClose },
                    { label: 'Save', type: 'submit', disabled: formik?.dirty == false}
                ]}
                buttonsWidth={`100`}
            />
        </div>
        
    )
};

SaveButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SaveButton;