import moment from "moment";
import { GetPageTheme } from "../../../../components/pages/GenericCompanyProfile/HelperComponents";

import style from './style.module.scss';
import { MATCH_STATUSES_DICTIONARY } from "../../../../consts/matchOfferStatuses";


export function getTotalAverageTime(data) {
    var d = data.reduce(function (x, y) {
        return x + y.averageTime.days;
    }, 0) / data.length;
    var h = data.reduce(function (x, y) {
        return x + y.averageTime.hours;
    }, 0) / data.length;
    var m = data.reduce(function (x, y) {
        return x + y.averageTime.minutes;
    }, 0) / data.length;
    var s = data.reduce(function (x, y) {
        return x + y.averageTime.seconds;
    }, 0) / data.length;

    return buildTime({d: parseInt(d), h:parseInt(h), m:parseInt(m), s:parseInt(s)});
}

export function toDaysHoursAndMinutes(unixTime) {
    const secondsInDay = 24 * 60 * 60;
    const secondsInHour = 60 * 60;
    const secondsInMinute = 60;

    const d = Math.floor(unixTime / secondsInDay);
    const remainingSeconds1 = unixTime % secondsInDay;

    const h = Math.floor(remainingSeconds1 / secondsInHour);
    const remainingSeconds2 = remainingSeconds1 % secondsInHour;

    const m = Math.floor(remainingSeconds2 / secondsInMinute);
    
    const s = remainingSeconds2 % secondsInMinute;
    return { d: d, h: h, m: m, s: s, total: unixTime };
}

export function calculateDays(match) {
    var arr = [];

    var avDays = 0;
    var avHours = 0;
    var avMinutes = 0;
    var avSeconds = 0;

    match.stages.forEach((item, index) => {
        // start time and end time
        var startTime = moment(index == 0 ? match.matchCreated.replaceAll("T", " ") : match.stages[index - 1].dateMovedOn.replaceAll("T", " "));
        var endTime = moment(item.dateMovedOn.replaceAll("T", " "));

        var { d, h, m, s, total } = toDaysHoursAndMinutes(endTime.unix() - startTime.unix());
        avDays += d; avHours += h; avMinutes += m; avSeconds += s;

        arr.push({ status: item.matchText, total: total, time: { days: d, hours: h, minutes: m, seconds: s } });
    })

    var highest = 0;
    arr.forEach((item) => {
        if (item.total > highest) {
            highest = item.total
        }
    })

    match.highest = highest;
    match.averageTime = {
        days: parseInt(avDays / match.stages.length),
        hours: parseInt(avHours / match.stages.length),
        minutes: parseInt(avMinutes / match.stages.length),
        seconds: parseInt(avSeconds / match.stages.length)
    }

    return {
        stages: arr,
    };
}

export function getBarStyle(index, alt) {
    const theme = GetPageTheme();
    if (theme == 'dark') {
        if (index % (alt ? 3 : 2) == 0) {
            return style.barDark;
        } else {
            return style.barDarkAlt;
        }
    } else {
        if (index % (alt ? 3 : 2) == 0) {
            return style.bar;
        } else {
            return style.barAlt;
        }
    }
}

export function buildTime(timeObj) {
    var str = "";

    if(typeof timeObj != "object") console.log(timeObj);

    var d = timeObj?.days || timeObj?.d || 0;
    var h = timeObj?.hours || timeObj?.h || 0;
    var m = timeObj?.minutes || timeObj?.m || 0;
    var s = timeObj?.seconds || timeObj?.s || 0;

    var arr = [
        { value: d, label: 'd' },
        { value: h, label: 'h' },
        { value: m, label: 'm' },
        { value: s, label: 's' },
    ]

    var i = 0;
    var startingIndex = null;
    while (startingIndex == null && i < arr.length) {
        if (arr[i].value != 0) {
            startingIndex = i;
        } else {
            i++;
        }
    }

    arr.forEach((item, index) => {
        if (index >= startingIndex) {
            str += `${item.value}${item.label} `
        }
    })

    return str.trim();
}

export function getBar( highest, label, text, total, index ) {

    var barMultiplier = (10 + (100 - 10) * (((total / highest)))) / 100;
    var barWidth = 100 * barMultiplier;

    return (<>
        <b>{label} - {text}</b>
        <div className={style.barRemainder} style={{ display: 'flex' }}>
            <div className={getBarStyle(index)} style={{ width: `${barWidth}%` }}>
            </div>
        </div>
    </>)
}