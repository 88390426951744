import React from 'react';
import { Card, Row, Col, Label, Input } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from '../../../../components/SearchBox';
import Select from 'react-select';

import Buttons from './Buttons';

import { customStyles } from '../../../../components/SelectField';

import { JobData, RESULTS_PER_REQUEST } from './JobLookup';
import { GetPageTheme, ProcessSalary } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import nudgeLogo from '../../../../assets/images/nudge-n-forest-full.png';
import adzunaLogo from '../../../../assets/images/adzunaLogo.png';

import style from './style.module.scss';
import Loader from '../../../../components/Loader';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../consts/urls';
import NudgeBoxes from '../../../../components/NudgeBoxes';

const JobsReel = ({ industryData, user }) => {
    const userObject = {
        timing: user.timing,
        workTiming: user.workTiming,
        min: parseInt(user.expectedEarnings.replaceAll(' ', '').split('-')[0]),
        max: parseInt(user.expectedEarnings.replaceAll(' ', '').split('-')[1]),
    };

    const [page, setPage] = React.useState(1);
    const [pageBuffer, setPageBuffer] = React.useState(page);

    //Value of the search box
    const [search, setSearch] = React.useState('');
    const [searchBuffer, setSearchBuffer] = React.useState('');

    //Controls the filter options at the top of the page
    const [industry, setIndustry] = React.useState(industryData[0]);
    const [subIndustry, setSubIndustry] = React.useState(industryData[0].subIndustries[0]);

    //The job the user currently has selected
    const [selectedJob, setSelectedJob] = React.useState('');

    //gets all job data and returns it
    const {
        concatData: jobData,
        matches,
        mutateNudge: mutate,
        comboValidating: isValidating,
        count,
    } = JobData(
        {
            industry: { id: industry?.id, name: industry?.name },
            subIndustry: { id: subIndustry?.id, name: subIndustry?.name },
            search: search,
            page: page,
        },
        userObject,
    );

    const industryFunction = (option) => {
        setIndustry(option);
        setSubIndustry(option?.subIndustries[0]);
        setSelectedJob('');

        setPage(1);
    };

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearch(searchBuffer);
        }, 750);

        setSelectedJob('');
        return () => clearTimeout(delayDebounceFn);
    }, [searchBuffer]);

    React.useEffect(() => {
        setSelectedJob('');
        setPageBuffer(1);
    }, [search, industry]);

    React.useEffect(() => {
        setSelectedJob('');
    }, [page])

    React.useEffect(() => {
        var doc = document.getElementById('description-scroll');

        if (doc) doc.scrollTo(0, 0);
    }, [selectedJob]);

    function getCardStyle(item) {
        if (selectedJob) {
            return selectedJob.id === item.id ? style.jobCardSelected : style.jobCard;
        } else {
            return style.jobCard;
        }
    }

    function processQualification(qual) {
        let split = qual.split('/');

        if (split[1]) {
            return qual.split('/')[0] + ' in ' + qual.split('/')[1];
        } else {
            return qual;
        }
    }

    var maxPages = page > 0 ? Math.ceil(count / RESULTS_PER_REQUEST) || 1 : Math.floor(count / RESULTS_PER_REQUEST) || 1;
    function prevPage() {
        if (page > 1 && !isValidating) {
            setPageBuffer(page - 1);
        }
    }

    function nextPage() {
        if (page < maxPages && !isValidating) {
            setPageBuffer(page + 1);
        }
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(parseInt(pageBuffer) && pageBuffer != page) {
                setPage(parseInt(pageBuffer) > maxPages ? maxPages : pageBuffer)
            }
        }, 750);

        if(pageBuffer > maxPages) {
            setPageBuffer(maxPages > 0 ? maxPages : 1);
        } else {
            return () => clearTimeout(delayDebounceFn);
        }

    }, [pageBuffer])

    const theme = GetPageTheme();
    return (
        <>
            <div className={style.pageContainer}>
                <Card className={style.filterContainer}>
                    {industryData ? (
                        <Row>
                            <Col md={6}>
                                <SearchBox
                                    value={searchBuffer}
                                    setValue={setSearchBuffer}
                                    button={false}
                                    placeholder={'Search by job title or company...'}
                                    useKey={true}
                                />
                            </Col>

                            <Col style={{ display: 'flex', height: '38px' }} md={6}>
                                <Select
                                    styles={customStyles()}
                                    options={industryData}
                                    defaultValue={industryData[0]}
                                    onChange={(option) => {
                                        industryFunction(option);
                                    }}
                                    placeholder={'Industries'}
                                    value={industry}
                                    isSearchable={false}
                                    isClearable
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Card>

                {jobData ? (
                    <Row>
                        <Col lg={4}>
                            <Card id={`card-${theme}`} style={{ marginTop: '20px', padding: '12px' }}>
                                <Row>
                                    <Col xs={3} md={3} onClick={() => prevPage()}>
                                        <div
                                            className={
                                                isValidating || page == 0 || page == 1
                                                    ? style.jobListTitleButtonDisabled
                                                    : style.jobListTitleButton
                                            }
                                        >
                                            Prev
                                        </div>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <div className={style.jobListTitle}>
                                            {' '}
                                            {isValidating
                                                ? `Loading more jobs...`
                                                : jobData.length
                                                ? <div style={{ display: 'flex', columnGap: '6px', margin: 'auto' }}>
                                                    <h5 style={{ fontSize: '1rem', width: '100%' }}>Page </h5>
                                                    <Input style={{ width: '75%' }} type="number" value={pageBuffer} onChange={(e) => setPageBuffer(e.target.value)} min={1} max={maxPages}/>
                                                    <h5 style={{ fontSize: '1rem', width: '100%' }}> of { maxPages || 1 }</h5>
                                                </div>
                                                : `No jobs found.`}
                                        </div>
                                    </Col>
                                    <Col xs={3} md={3} onClick={() => nextPage()}>
                                        <div
                                            className={
                                                isValidating || page == maxPages || maxPages == 1 || maxPages == 0
                                                    ? style.jobListTitleButtonDisabled
                                                    : style.jobListTitleButton
                                            }
                                        >
                                            Next
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                            <div className={style.jobList}>
                                {!isValidating
                                    ? jobData.map((item, index) => (
                                        <Card
                                            id={`card-${theme}`}
                                            key={index}
                                            onClick={() => setSelectedJob(jobData[index])}
                                            className={getCardStyle(item)}
                                        >
                                        <h5 className={style.jobItemTitle}>{item.title}</h5>
                                        <h5 className={style.jobItemCompany}>{item.companyName}</h5>

                                        
                                        <div className={style.boxesWrapper}>
                                        <NudgeBoxes
                                            data={[
                                                { label: item.salary, danger: false },
                                                { label: item.locationName, danger: false },
                                                { label: item.flexibility, danger: false },
                                            ]}
                                        />
                                    </div>
                                        <img
                                            src={item.source === 'Adzuna' ? adzunaLogo : nudgeLogo}
                                            className={style.jobItemLogo}
                                            alt="card-logo"
                                        />
                                    </Card>
                                    )) : null }

                                    { (search == "" && !industry) && <h5 class="text-center mt-10">
                                        {"Type in the search box or choose an option in the select box to find jobs."}
                                    </h5>}
                            </div>
                        </Col>

                        <Col lg={8}>
                            {selectedJob ? (
                                <Card id={`card-${theme}`} className={`${style.mainJobCard}`}>
                                    <div className={style.topMobileBuffer} onClick={() => setSelectedJob('')} />
                                    <FontAwesomeIcon
                                        onClick={() => setSelectedJob(null)}
                                        icon={['fas', 'times']}
                                        className={style.close}
                                    />
                                    <h5 className={style.mainJobTitle}>{selectedJob.title}</h5>
                                    <h5 className={style.jobItemCompany}>{selectedJob.companyName}</h5>

                                    {selectedJob.source == 'Adzuna' ? (
                                        <h5
                                            onClick={() => setSearchBuffer(selectedJob.companyName)}
                                            className={style.link}
                                        >
                                            See more jobs from {selectedJob.companyName}
                                        </h5>
                                    ) : null}
                                    {selectedJob.source === 'Nudge' ? (
                                        <Link target="_blank" to={`${URLS.OFFERS}/${selectedJob.id}`}>
                                            See full job offer
                                        </Link>
                                    ) : null}

                                    {selectedJob.averageText ? selectedJob.averageText : null}

                                    <div className={style.boxesWrapper}>
                                        <NudgeBoxes
                                            data={[
                                                { label: selectedJob.salary, danger: false },
                                                { label: selectedJob.locationName, danger: false },
                                                { label: selectedJob.flexibility, danger: false },
                                            ]}
                                        />
                                    </div>

                                    <img
                                        src={selectedJob.source === 'Adzuna' ? adzunaLogo : nudgeLogo}
                                        className={style.mainJobLogo}
                                        alt="card-logo"
                                    />
                                    <Buttons
                                        source={selectedJob.source}
                                        refresh={mutate}
                                        matches={matches}
                                        offer={selectedJob}
                                    />

                                    <h5 className={style.aboutJobHeader}>About the Job</h5>
                                    <div className={style.jobDescription} id="description-scroll">
                                        {selectedJob.description}
                                        <br />

                                        <h5 className={style.skillCertHeader}>
                                            {selectedJob.qualification ? selectedJob.qualificationHeader : null}
                                        </h5>
                                        <p>
                                            {selectedJob.qualification
                                                ? processQualification(selectedJob.qualification)
                                                : null}
                                        </p>

                                        {selectedJob.source == 'Nudge' ? (
                                            <div>
                                                <h5 className={style.skillCertHeader}>Certificates/Qualifications</h5>
                                                {selectedJob.certificates?.length ? (
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <Label><b>Required</b></Label>
                                                            { selectedJob.certificates.filter((item) => item.required).length ? <NudgeBoxes
                                                                data={selectedJob.certificates.filter((item) => item.required).map((item) => ({ label: item.name, danger: false}))}
                                                            /> : <div><i>Unfilled</i></div> }
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <Label><b>Desired</b></Label>
                                                            { selectedJob.certificates.filter((item) => !item.required).length ? <NudgeBoxes
                                                                data={selectedJob.certificates.filter((item) => !item.required).map((item) => ({ label: item.name, danger: false}))}
                                                            /> : <div><i>Unfilled</i></div> }
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <p className={style.skillCert}>None Listed</p>
                                                )}
                                                <h5 className={style.skillCertHeader}>Skills</h5>
                                                {selectedJob.skills?.length ? (
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <Label><b>Required</b></Label>
                                                            { selectedJob.skills.filter((item) => item.required).length ? <NudgeBoxes
                                                                data={selectedJob.skills.filter((item) => item.required).map((item) => ({ label: item.name, danger: false}))}
                                                            /> : <div><i>Unfilled</i></div> }
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <Label><b>Desired</b></Label>
                                                            { selectedJob.skills.filter((item) => !item.required).length ? <NudgeBoxes
                                                                data={selectedJob.skills.filter((item) => !item.required).map((item) => ({ label: item.name, danger: false}))}
                                                            /> : <div><i>Unfilled</i></div> }
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <p className={style.skillCert}>None Listed</p>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </Card>
                            ) : jobData.length && !isValidating ? (
                                <Card className={style.blankJobCard}>
                                    <h5>Select a job to see more details.</h5>
                                </Card>
                            ) : null}
                        </Col>
                    </Row>
                ) : isValidating ? (
                    <Loader />
                ) : (
                    <h5>There was a problem.</h5>
                )}
            </div>
        </>
    );
};

export default JobsReel;
