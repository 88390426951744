import * as Yup from 'yup';

export const FIELDS = {
    OLD_PASSWORD: 'oldPassword',
    NEW_PASSWORD: 'newPassword',
    NEW_PASSWORD_REPEAT: 'newPasswordRepeat',
};

export const validationSchema = Yup.object({
    [FIELDS.OLD_PASSWORD]: Yup.string().required('This field is required'),
    [FIELDS.NEW_PASSWORD]: Yup.string().required('This field is required'),
    [FIELDS.NEW_PASSWORD_REPEAT]: Yup.string()
        .oneOf([Yup.ref(FIELDS.NEW_PASSWORD), null], 'Passwords must match')
        .required('This field is required'),
});

export const initialValues = {
    [FIELDS.OLD_PASSWORD]: '',
    [FIELDS.NEW_PASSWORD]: '',
    [FIELDS.NEW_PASSWORD_REPEAT]: '',
};
