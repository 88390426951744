import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import { Card, Col, Row, ModalBody, ModalFooter } from 'reactstrap';

import AppPagination, { UseAppPagination, calculateBounds } from '../AppPagination';
import Offer from './Offer';
import ContractorOffer from './ContractorOffer';
import style from './style.module.scss';
import useMobile from '../../hooks/useMobile';
import NudgeModal from '../NudgeModal';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import { MATCH_STATUSES_DICTIONARY } from '../../consts/matchOfferStatuses';

export const PER_PAGE = 4;

const EmptyOffers = ({ isCurrent }) => (
    <Card className={`${style.offer}`}>
        <Card className={style.matchesWrapper}>
            {isCurrent
                ? "You have no offers posted. If you'd like to change that, press the button above to add a new contract."
                : 'No archived offers.'}
        </Card>
    </Card>
);

const NoOfferResults = ({ search }) => (
    <div class="w-100">
        <h5 class="text-center">No jobs {search ? "found" : "posted yet"}.</h5>
    </div>
);

const OffersList = ({ offers, url, process, search, isCurrent, selectedFilter }) => {
    const { userRoles } = useContext(AuthContext);
    const theme = GetPageTheme();

    if (search) offers = offers.filter((item) => item.name.toUpperCase().includes(search.toUpperCase()));

    const total = Math.ceil(offers.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);
    const offersToDisplay = [...offers].slice(start, end);

    const [selectedOffer, setSelectedOffer] = React.useState();
    const toggleSelected = (offer) => { setSelectedOffer(offer) }

    React.useEffect(() => {
        setPage(0);
    }, [search, isCurrent, selectedFilter]);

    const { isMobileWide } = useMobile();

    return (
        <>
            {offersToDisplay.length ? (
                <div className={style.offers}>
                    <Row>

                        { !userRoles.includes('User')
                            ? offersToDisplay.map((el) => 
                                <Col xs={12} sm={12} md={6} lg={12}>
                                    <Offer offer={el} key={el.id} url={url} process={process} select={setSelectedOffer}/>
                                </Col>
                            ) : offersToDisplay.map((el) => (
                                <ContractorOffer offer={el} key={el.id} url={url} process={process} />
                        )) }

                        { [...Array(PER_PAGE - offersToDisplay?.length)].map(() => (
                            <Col xs={12} sm={12} md={6} lg={12}>
                                <Card className={style.emptyOffer} />
                            </Col>
                        )) }
                    </Row>
                </div>
            ) : (
                <NoOfferResults search={!!search} />
            )}

            <NudgeModal id={`modal-${theme}`} toggle={toggleSelected} isOpen={!!selectedOffer} headerText={selectedOffer?.name || "Blank"}>
                <ModalBody style={{ minHeight: '60vh' }}>
                    { selectedOffer ? (
                            selectedOffer.offerMatches.filter((item) => !!item?.contractor?.name).map((item) => (
                                <Card className={style.matchesWrapper}>
                                    <Row>
                                        <Col xs={12} sm={4} md={4} lg={4}>
                                            {item.contractor.name ? (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`/company/offer/${selectedOffer.id}/contractors/${item.contractor.id}`}
                                                >
                                                    {item.contractor.name}
                                                </a>
                                            ) : (
                                                <a>User Profile Unavailable</a>
                                            )}
                                        </Col>
                                        <Col xs={12} sm={8} md={8} lg={8}>
                                            <b className={style.matchesStatus}>
                                                {MATCH_STATUSES_DICTIONARY[item.statusText]}
                                            </b>
                                        </Col>
                                    </Row>
                                </Card>
                            ))
                        ) : (
                            <Card className={style.matchesWrapper}>No current matches for this offer.</Card>
                        ) }
                </ModalBody>
                <ModalFooter/>
            </NudgeModal>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={isMobileWide ? 1 : 2}
            />
        </>
    );
};

OffersList.propTypes = {
    offers: PropTypes.array.isRequired,
    header: PropTypes.node,
};

const OffersListWrapper = ({ offers, ...props }) =>
    offers && offers.length > 0 ? <OffersList offers={offers} {...props} /> : <EmptyOffers {...props} />;

export default OffersListWrapper;
