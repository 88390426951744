export const getFormikError = (formik, name) => formik.submitCount && formik.errors[name] ? formik.errors[name] : null;

export const onClickAddToArray = (arrayHelpers, value) => arrayHelpers.push(value || '');

export const onClickRemoveFromArray = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
};

export const DATE_FORMAT = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

export const testCorrectDate = (value = '') => {
    const [dd, mm, yyy] = value.split('/');
    const timestamp = Date.UTC(yyy, mm - 1, dd, 0, 0, 0);
    const date = new Date(timestamp);

    return date instanceof Date && !isNaN(date);
};
