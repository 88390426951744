import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import style from './style.module.scss';
import TextFieldModalForm from './TextFieldModalForm';
import { initialValues, validationSchema } from './TextFieldModalForm/validation';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../NudgeModal';
import NudgeButton from '../../buttons/NudgeButton';

const TextFieldModal = ({ isOpen, toggle, onSubmit, header, sending, generateContent, extraData, dismiss }) => {

    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    return (
        <Formik {...props}>
            {(formik) => (
                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                    <NudgeModal id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered headerText={header}>
                        <ModalBody className={style.modalBody}>
                            <TextFieldModalForm formik={formik} sending={sending} generateContent={generateContent} extraData={extraData} />
                        </ModalBody>

                        <ModalFooter>
                            <NudgeButton color={dismiss ? 'danger' : 'adzuna'} className={style.button} type="submit" disabled={sending}>
                                { dismiss ? "Dismiss" : "Accept"}
                            </NudgeButton>
                        </ModalFooter>
                    </NudgeModal>
                </Form>
            )}
        </Formik>
    );
};

TextFieldModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
};

export default TextFieldModal;
