import React, { useState } from 'react';
import { Field } from 'formik';

import { Row, Col, Card, CardBody, Button, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authPostData, deleteData } from '../../api/tools';
import { API_DASHBOARD_CONTRACTOR, API_PROFILE } from '../../consts/urls';
import moment from 'moment';

import SelectField from '../SelectField';
import Loader from '../Loader';
import { Form, Formik } from 'formik';
import AppInput from '../AppInput';
import { FIELDS, initialValues, validationSchema } from './validate';

import style from './style.module.scss';
import NudgeCard from '../NudgeCard';
import ClickButton from '../buttons/NudgeButton';
import NudgeButton from '../buttons/NudgeButton';
import { GetPageTheme, calculateLightness } from '../pages/GenericCompanyProfile/HelperComponents';
import ColourInput from '../ColourInput';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import NudgeButtonGroup from '../buttons/ButtonGroup';

const NewReminderForm = ({ formik, toggleOpen, sending, theme }) => {
    const [isActive, setIsActive] = useState(false);
    const [activeColour, setActiveColour] = useState('');

    const toggle = (colour) => {
        setIsActive(true);
        setActiveColour(colour);
    };

    const colorsObject = {
        "a3e7fc": 'red',
        "26c485": 'white',
        "32908f": 'white',
        "553a41": 'white',
        "2f0601": 'white',
    }

    const lengthOptions = [
        { label: 'NO DURATION', value: 0 },
        { label: '30 MINUTES', value: 30 },
        { label: '1 HR', value: 60 },
        { label: '1 HR, 30 MINUTES', value: 90 },
        { label: '2 HRS', value: 120 },
        { label: '2 HR, 30 MINUTES', value: 150 },
        { label: '3 HRS', value: 180 },
        { label: '3 HR, 30 MINUTES', value: 210 },
        { label: '4 HRS', value: 240 },
        { label: '4 HR, 30 MINUTES', value: 270 },
        { label: '5 HRS', value: 300 },
    ];

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} className={style.form} id={`card-sub-${theme}`}>
            {!sending ? (
                <>
                    <FormGroup className="mb-20">
                        <AppInput id={FIELDS.TITLE} formik={formik} label="Reminder title" />
                    </FormGroup>

                    <FormGroup className="mb-20">
                        <AppInput id={FIELDS.DATE} formik={formik} label="Due date" type="datetime-local" />
                    </FormGroup>

                    <FormGroup className="mb-20">
                        <Field
                            name={FIELDS.DURATION}
                            options={lengthOptions}
                            component={SelectField}
                            isSearchable={false}
                            placeholder="Per Day/Hour"
                            sub={true}
                        />
                    </FormGroup>

                    <FormGroup className="mb-20 ">
                        {/* {Object.keys(colorsObject).map((colour) => (
                            <div
                                key={colour}
                                onClick={() => {
                                    formik.setFieldValue(FIELDS.COLOUR, colour);
                                    toggle(colour);
                                }}
                                className={`${style.reminderColour} ${
                                    isActive && colour === activeColour ? style.colourSelected : ''
                                }`}
                                style={{ background: '#' + colour, color: colorsObject[colour] }}
                            />
                        ))}
                        <AppInput id={FIELDS.COLOUR} formik={formik} label="Colour" type="hidden" /> */}
                        <ColourInput id={FIELDS.COLOUR} formik={formik}/>
                    </FormGroup>

                    <div class="flex-right-align-justify w-100">
                        <div class="w-75">
                            <NudgeButtonGroup
                                buttons={[
                                    { label: 'Close', color: 'danger', onClick: toggleOpen },
                                    { label: 'Create', color: 'success', type: 'submit'}
                                ]}
                            />
                        </div>
                    </div>


                </>
            ) : (
                <Loader />
            )}
        </Form>
    );
};

export const ContractDashReminders = ({ data, mutate }) => {
    const [formShown, setFormShown] = useState(false);
    const [sending, setSending] = useState(false);

    const theme = GetPageTheme();

    const toggle = () => setFormShown(!formShown);

    const onSubmit = async (values) => {
        setSending(true);

        values[FIELDS.COLOUR] = values[FIELDS.COLOUR].split("#")[1]

        let result = await authPostData(values, `${API_DASHBOARD_CONTRACTOR}/Reminders`);
        await mutate();

        setSending(false);
        setFormShown(false);
        await result.json();
        return;
    };

    const deleteReminder = async (id) => {
        if (window.confirm(`Delete this reminder?`)) {
            setSending(true);
            await deleteData([id], `${API_PROFILE}/Reminder`, {});
            await mutate();
            setSending(false);
        }
    };

    const props = {
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: onSubmit,
    };

    var reminders = [
        { id: 1, placeholder: true },
        { id: 2, placeholder: true },
        { id: 3, placeholder: true },
    ];

    if (data) {
        var temp = data;

        temp = temp.sort((a, b) => {
            return moment(a.date).diff(moment(b.date));
        });

        // temp = temp.filter((reminder) => {
        //     return moment(reminder.date).add(reminder.duration, 'minutes').isAfter(moment());
        // });

        reminders = temp;
    }

    function getReminderDate(reminder) {
        let dateString = moment(reminder.date).format('Do MMMM YYYY, ');
        let beforeTime = moment(reminder.date).format('h:mma');
        let afterTime = moment(reminder.date).add(reminder.duration, 'minutes').format('h:mma');

        if (beforeTime === afterTime) {
            dateString += beforeTime;
        } else {
            dateString += beforeTime + ' - ' + afterTime;
        }

        return dateString;
    }

    function isReminderActive(reminder) {
        let beforeTime = moment(reminder.date);
        let afterTime = moment(reminder.date).add(reminder.duration, 'minutes');

        return moment().isBetween(beforeTime, afterTime);
    }

    function getRemindersActive() {
        let activeCount = 0;
        reminders.forEach((reminder) => {
            if (isReminderActive(reminder)) {
                activeCount++;
            }
        });

        if (activeCount > 0) {
            return <b style={{ color: 'white', background: 'red', padding: '4px', borderRadius: '0.25rem' }}>{activeCount + ' ongoing'}</b>;
        } else {
            return '';
        }
    }

    function getCalendarLink(reminder) {
        return `https://www.google.com/calendar/render?action=TEMPLATE&text=${reminder.title}&dates=${moment(reminder.date).subtract(1, 'h').format('YYYYMMDDTHHmmss')}Z/${moment(reminder.date).add(1, 'h').format('YYYYMMDDTHHmmss')}Z`
    }

    const colorsObject = {
        "a3e7fc": 'black',
        "26c485": 'black',
        "32908f": 'white',
        "553a41": 'white',
        "2f0601": 'white',
    }

    return (
        <Row>
            <Col>
                <Row className={style.dashHeader}>
                    <Col xs={0} md={0} lg={6}>
                        <h2 className={style.dashHeaderTitle}>Reminders</h2>
                        <h2 className={style.dashHeaderTitle}>{getRemindersActive()}</h2>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        {!formShown ? (
                            <ClickButton color="success" disabled={!data} className={style.dashHeaderButton} onClick={toggle}>
                                New Reminder
                            </ClickButton>
                        ) : null}
                    </Col>
                </Row>
                {!formShown ? (
                    <Card className={style.reelJob}>
                        <CardBody>
                            { !reminders?.length ? <i>No reminders have been set.</i> : null }
                            {reminders.map((reminder) =>
                                reminder.placeholder ? (
                                    <div key={reminder.id} className={style.reminder}>
                                        <div className={style.datePlaceholder}>
                                            <div className={style.activity} />
                                        </div>
                                        <div className={style.namePlaceholder}>
                                            <div className={style.activity} />
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={reminder.id}
                                        className={style.reminder}
                                        style={{ background: `#${reminder.colour}`, color: calculateLightness(`#${reminder.colour}`) < 50 ? 'white' : 'black' }}
                                    >
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <div
                                                    className={`${style.reminderDate} ${
                                                        reminder.placeholder ? style.placeholder : ''
                                                    }`}
                                                >
                                                    {getReminderDate(reminder)}
                                                </div>
                                                <div className={`${style.reminderTitle} ${
                                                        reminder.placeholder ? style.placeholder : ''
                                                    }`}
                                                >
                                                    {reminder.title}
                                                </div>
                                                <div className={`${style.reminderTitle} ${
                                                        reminder.placeholder ? style.placeholder : ''
                                                    }`}
                                                >
                                                    { isReminderActive(reminder) ? (
                                                        <b style={{ color: 'white' }}>Ongoing</b>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6}>  
                                                <div class="flexy">
                                                    <AddToCalendarButton
                                                        name={`Nudge Reminder - ${reminder.title}`}
                                                        options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                                                        location="World Wide Web"
                                                        startDate={moment(reminder.date).format('YYYY-MM-DD')}
                                                        endDate={moment(reminder.date).add(reminder.duration, 'minute').format('YYYY-MM-DD')}
                                                        startTime={moment(reminder.date).format('HH:mm')}
                                                        endTime={moment(reminder.date).add(reminder.duration, 'minute').format('HH:mm')}
                                                        timeZone="Europe/London"
                                                        buttonStyle="default"
                                                        hideBranding={true}
                                                        lightMode={theme}
                                                        hideCheckmark
                                                    />
                                                    <NudgeButton onClick={deleteReminder.bind(this, reminder.id)} color="danger" style={{ width: '100%' }}>
                                                        <FontAwesomeIcon
                                                            icon={['far', 'trash-alt']}
                                                            data-id={reminder.id}
                                                            title="Delete reminder"
                                                        />
                                                    </NudgeButton>
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                          
                                    </div>
                                ),
                            )}
                        </CardBody>
                    </Card>
                ) : (
                    <Card className={style.reelJob}>
                        <CardBody>
                            <Formik {...props}>
                                {(formik) => <NewReminderForm formik={formik} toggleOpen={toggle} sending={sending} theme={theme}/>}
                            </Formik>
                        </CardBody>
                    </Card>
                )}
            </Col>
        </Row>
    );
};

export default ContractDashReminders;
