import React from 'react';
import NudgeButton from '../NudgeButton';

import style from './style.module.scss';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';

function determineAvailability(item) {
    if(item.available === undefined) {
        return true;
    } else {
        if(item.available == true) {
            return true;
        }
        return false
    }
}

const NudgeButtonGroup = ({ buttons, vertical = false, buttonStyles, buttonsWidth = 100, buttonsHeight = `38px` }) => {

    const filteredButtons = buttons.filter((p) => determineAvailability(p));
    const theme = GetPageTheme();

    if(filteredButtons?.length == 0) return null;

    function decideStyle(index) {
        if(filteredButtons.length > 2) {
            if(index == 0) return vertical ? style.topButton : style.leftButton;
            
            if(index == filteredButtons.length - 1) return vertical ? style.bottomButton : style.rightButton;

            return vertical ? style.middleButtonVertical : style.middleButtonHorizontal;
        }

        if(filteredButtons.length == 2) {
            if(index == 0) return vertical ? style.topButton : style.leftButton;
            if(index == filteredButtons.length - 1) return vertical ? style.bottomButton : style.rightButton;
            return vertical ? style.middleButtonVertical : style.middleButtonHorizontal;
        }
        
        return style.soloButton;
    }

    return (<div className={vertical ? style.buttonGroupContainerVertical : style.buttonGroupContainer} style={{ width: `${buttonsWidth}%`, height: vertical ? filteredButtons?.length == 1 ? "100%" : "inherit" : buttonsHeight, columnGap: theme == 'dark' ? '2px' : 0, rowGap: theme == 'dark' ? '2px' : 0 }}>
        { filteredButtons.map((item, index) => <NudgeButton className={decideStyle(index)} {...item} style={{...buttonStyles, height: vertical ? `${100 / filteredButtons.length}%` : '100%'}}>
            {item.label}
        </NudgeButton> )}
    </div>)
}

export default NudgeButtonGroup;