import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import SaveButton from '../../buttons/SaveButton';
import AppInput from '../../AppInput';
import { FIELDS, FIELD_NAMES } from './validation';
import style from './style.module.scss';

export const EditableProject = ({ formik, onClose, projectEdit }) => (
    <div>
        <h5>
            {projectEdit ? 'Add New' : 'Edit'} {'Project'}
        </h5>
        <div className={style.formContainer}>
            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                <div className={style.buttons}>
                    <SaveButton  onClose={onClose} />
                </div>
                <FormGroup row className="mb-20">
                    <Label for={FIELDS.TITLE}>{FIELD_NAMES[FIELDS.TITLE]}</Label>
                    <AppInput id={FIELDS.TITLE} formik={formik} label="TITLE"/>
                </FormGroup>

                <FormGroup row className="mb-20">
                    <Label for={FIELDS.DESCRIPTION}>{FIELD_NAMES[FIELDS.DESCRIPTION]}</Label>
                    <AppInput
                        id={FIELDS.DESCRIPTION}
                        className={style.description}
                        formik={formik}
                        type="textarea"
                        label="DESCRIPTION"
                        charLimit={500}
                    />
                </FormGroup>
            </Form>
        </div>
    </div>
);

export default EditableProject;
