import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import LinkButton from '../../../components/buttons/LinkButton';
import { ProcessSalary } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import SmallHeader from '../../../components/SmallHeader';
import { URLS } from '../../../consts/urls';

import { sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import AppPagination, { UseAppPagination, calculateBounds } from '../../../components/AppPagination';

import style from './style.module.scss';
import { currencyLookup } from '../../company/NewOfferPage/OfferForm/options';
import ClickButton from '../../../components/buttons/NudgeButton';

const PER_PAGE = 6;

const CurrentContracts = ({ data = [], isCurrent, toggle }) => {
    var currency = 'EUR';
    function GetSalary(salary, type) {
        const split = salary.split('/');

        return `${ProcessSalary(split[0], currencyLookup[type])} ${type}/${split[1]}`;
    }

    const total = Math.ceil(data.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);
    const offersToDisplay = [...sort_by_key(data, 'id')].reverse().slice(start, end);

    let emptyOffers = [];

    if (offersToDisplay.length < PER_PAGE) {
        for (var i = 0; i < PER_PAGE - offersToDisplay.length; i++) {
            emptyOffers.push('');
        }
    }

    React.useEffect(() => {
        setPage(0);
    }, [isCurrent]);

    return (
        <>
            <Row>
                <Col>
                    <h1 className={`${style.contractHeader}`}>{isCurrent ? 'Current' : 'Past'} Contracts</h1>
                </Col>
                <Col>
                    <ClickButton color="success" onClick={toggle} className={style.contractLink}>
                        View {!isCurrent ? 'Current' : 'Past'} Contracts
                    </ClickButton>
                </Col>
            </Row>
            <Row>
                {offersToDisplay.map((contract, id) => (
                    <Col xs={12} md={6} lg={4} xl={4}>
                        <Card className={`${style.contractCard}`}>
                            <CardBody>
                                <div key={id}>
                                    <Row>
                                        <Col>
                                            <strong>
                                                {contract.title} - {contract.companyName}
                                            </strong>
                                        </Col>
                                    </Row>

                                    <br />

                                    {contract.amount.split('/')[0] != '0.00' ? (
                                        <Row>
                                            <Col>
                                                <span className="label">Amount:</span>{' '}
                                                {GetSalary(contract.amount, contract.currencyType)}
                                            </Col>
                                        </Row>
                                    ) : null}

                                    <Row>
                                        <Col>
                                            {contract.contractStartDate == contract.contractEndDate ? (
                                                <>
                                                    <span className="label">Duration:</span>{' '}
                                                    {contract.contractStartDate} - {contract.contractEndDate}
                                                </>
                                            ) : (
                                                <>
                                                    <span className="label">Start Date:</span>{' '}
                                                    {contract.contractStartDate}
                                                </>
                                            )}
                                        </Col>
                                    </Row>

                                    <div className={style.cardButtons}>
                                        {isCurrent ? (
                                            <LinkButton link={`${URLS.ADD_TIMESHEET}/${contract.id}`}>
                                                Add Timesheet
                                            </LinkButton>
                                        ) : null}
                                        <LinkButton link={`${URLS.TIMESHEETS}/${contract.id}`}>
                                            View Timesheets
                                        </LinkButton>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                {data.length > 0
                    ? emptyOffers.map(() => (
                          <Col xs={12} md={6} lg={4} xl={4}>
                              <Card className={style.emptyPayment} />
                          </Col>
                      ))
                    : null}
            </Row>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={2}
            />
        </>
    );
};

CurrentContracts.propTypes = {
    data: PropTypes.array,
};

export default CurrentContracts;
