import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';

import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import BenefitsValuesTraitsForm from '../../../forms/BenefitsValuesTraitsForm';
import { ContainerCard, GetPageTheme, sort_by_key, sort_by_key_length } from '../../GenericCompanyProfile/HelperComponents';
import style from '../style.module.scss';
import NudgeBoxes from '../../../NudgeBoxes';
import NudgeTabs from '../../../NudgeTabs';
import ClickButton from '../../../buttons/NudgeButton';

export const CompanyBVT = ({ data, extraDetails, mutate, id, showEdit }) => {
    const [AltInfoEdit, setAltInfoEdit] = useState(false);

    extraDetails.companyValues = sort_by_key(extraDetails.companyValues, 'content');
    extraDetails.companyBenefits = sort_by_key(extraDetails.companyBenefits, 'content');
    extraDetails.companyTraits = sort_by_key_length(extraDetails.companyTraits, 'content');

    const theme = GetPageTheme();

    var tabs = [
        { label: `Traits`, id: 'companyTraits', condition: true },
        { label: 'Values', id: 'companyValues', condition: true },
        { label: 'Benefits', id: 'companyBenefits', condition: true },
    ]
    const [selectedTab, setSelectedTab] = React.useState(tabs[0].id);

    return (
        <div style={{ marginTop: '24px' }}>

            <Card className={style.infoCard}>
                {!AltInfoEdit ? (<Row>
                    <Col md={12}>
                        <NudgeTabs
                            label="Traits, Values and Benefits"
                            selected={selectedTab}
                            setTab={setSelectedTab}
                            tabs={tabs}
                        />
                    </Col>
                    { showEdit ? <Col md={12}>
                        <ClickButton className={style.editButton} color="success" onClick={() => setAltInfoEdit(true)}>
                            Edit
                        </ClickButton>
                    </Col> : null }
                </Row>) : null }
                {!AltInfoEdit ? (<>
                    <Row>
                        {selectedTab == 'companyTraits' ? <Col md={12}>
                            <ContainerCard id={`card-sub-${theme}`} header={'Traits'} className={style.formCard}>
                                <NudgeBoxes
                                    data={extraDetails.companyTraits.map((item) => ({ label: item.content, danger: false}))}
                                />
                            </ContainerCard>
                        </Col> : null }
                        {selectedTab == 'companyValues' ? <Col md={12}>
                            <ContainerCard id={`card-sub-${theme}`} header={'Values'} className={style.formCard}>
                                <div>
                                    {extraDetails.companyValues.map((item) =>
                                        item.content ? <p key={item}>• {item.content}</p> : null,
                                    )}
                                </div>
                            </ContainerCard>
                        </Col> : null }
                        {selectedTab == 'companyBenefits' ? <Col md={12}>
                            <ContainerCard id={`card-sub-${theme}`} header={'Benefits'} className={style.formCard}>
                                <div>
                                    {extraDetails.companyBenefits.map((item) =>
                                        item.content ? <p key={item}>• {item.content}</p> : null,
                                    )}
                                </div>
                            </ContainerCard>
                        </Col> : null }
                        

                    </Row>
                </>) : (
                    <BenefitsValuesTraitsForm
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        url={`${API_DASHBOARD_COMPANY}/CompanyBVT/${id}`}
                        id={data.id}
                        onClickClose={setAltInfoEdit}
                        refresh={mutate}
                        info={extraDetails}
                    />
                )}
            </Card>
        </div>
    );
};

export default CompanyBVT;
