import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

export const ACCEPT_DRAG_AND_DROP = {
    IMAGES: 'image/*',
    DOCS: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
};

const DragAndDrop = ({ accept, activeComponent, inactiveComponent, id, customRootProps, ...props }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...props,
        multiple: false,
    });

    const style = {
        outline: 'none',
    };

    const theme = GetPageTheme();
    return (
        <div id={`card-sub-${theme}`} {...getRootProps({ style, ...customRootProps })} data-testid={id}>
            <input {...getInputProps()} accept={accept} />
            {isDragActive ? activeComponent : inactiveComponent}
        </div>
    );
};

DragAndDrop.propTypes = {
    accept: PropTypes.string.isRequired,
    activeComponent: PropTypes.node.isRequired,
    inactiveComponent: PropTypes.node.isRequired,
    id: PropTypes.string,
    customRootProps: PropTypes.object,
};

export default DragAndDrop;
