import { GoogleMap, LoadScript, MarkerClusterer } from '@react-google-maps/api';
import React, { Component } from 'react';

import { LocationMarker } from './marker';

export default class GoogleMapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            loading: true,
            acceptableTypes: ['Battery', 'Solar Photovoltaics', 'Wind Onshore', 'Wind Offshore'],
            acceptableStatuses: ['Application Submitted', 'Awaiting Construction', 'Operational', 'Under Construction'],
            operatorName: '',
        };
    }

    componentDidMount() {
        const locs = this.getLocations();
        this.setState({ locations: locs, loading: false });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.batteryChecked !== this.props.batteryChecked ||
            prevProps.solarChecked !== this.props.solarChecked ||
            prevProps.onshoreChecked !== this.props.onshoreChecked ||
            prevProps.offshoreChecked !== this.props.offshoreChecked ||
            prevProps.submittedChecked !== this.props.submittedChecked ||
            prevProps.awaitingChecked !== this.props.awaitingChecked ||
            prevProps.underConstructionChecked !== this.props.underConstructionChecked ||
            prevProps.operationalChecked !== this.props.operationalChecked ||
            prevProps.operatorName !== this.props.operatorName
        ) {
            let newAcceptableTypes = [];
            let newAcceptableStatuses = [];
            if (this.props.batteryChecked) {
                newAcceptableTypes.push('Battery');
            }
            if (this.props.solarChecked) {
                newAcceptableTypes.push('Solar Photovoltaics');
            }
            if (this.props.onshoreChecked) {
                newAcceptableTypes.push('Wind Onshore');
            }
            if (this.props.offshoreChecked) {
                newAcceptableTypes.push('Wind Offshore');
            }
            if (this.props.submittedChecked) {
                newAcceptableStatuses.push('Application Submitted');
            }
            if (this.props.awaitingChecked) {
                newAcceptableStatuses.push('Awaiting Construction');
            }
            if (this.props.underConstructionChecked) {
                newAcceptableStatuses.push('Under Construction');
            }
            if (this.props.operationalChecked) {
                newAcceptableStatuses.push('Operational');
            }

            this.setState(
                {
                    acceptableTypes: newAcceptableTypes,
                    acceptableStatuses: newAcceptableStatuses,
                    operatorName: this.props.operatorName,
                },
                () => {},
            );
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ locations: this.getLocations() });
                this.setState({ loading: false });
            }, 10);
        }
    }

    getLocations() {
        const locations = this.props.data.parsedCsvData;

        var tempLocations = [];
        locations.forEach((location) => {
            if (
                location.lat &&
                location.lng &&
                this.state.acceptableTypes.indexOf(location['Technology Type']) !== -1 &&
                this.state.acceptableStatuses.indexOf(location['Development Status (short)']) !== -1 &&
                (this.state.operatorName === '' || location['Operator (or Applicant)'] === this.state.operatorName)
            ) {
                tempLocations.push({
                    coords: {
                        lat: location.lat,
                        lng: location.lng,
                    },
                    name: location['Site Name'],
                    owner: location['Operator (or Applicant)'],
                    type: location['Technology Type'],
                    status: location['Development Status (short)'],
                    last_updated: location['Record Last Updated (dd/mm/yyyy)'],
                });
            }
        });

        return tempLocations;
    }

    renderMap(locations) {
        return (
            <LoadScript googleMapsApiKey="AIzaSyAtrAxasuFyPkHeBpQ9TS5d4SSDD4kg5vY">
                <GoogleMap
                    center={{ lat: 54.61102555312621, lng: -3.8340591962394117 }}
                    zoom={5}
                    mapContainerStyle={{ width: '100%', height: '75vh' }}
                >
                    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
                        {(clusterer) =>
                            this.state.locations.map((location, index) => (
                                <LocationMarker key={index} location={location} clusterer={clusterer} />
                            ))
                        }
                    </MarkerClusterer>
                </GoogleMap>
            </LoadScript>
        );
    }

    render() {
        return this.state.loading ? <p>Loading map...</p> : this.renderMap(this.state.locations);
    }
}
