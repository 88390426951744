import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import PageLink from './PageLink';
import style from './style.module.scss';
import NudgeButton from '../buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FIRST_PAGE = 0;
const STEP = 1;
const hasWindow = typeof window !== 'undefined';

export const calculateBounds = (page, perPage) => {
    let start = page * perPage;
    let end = start + perPage;

    return { start, end };
};

export const UseAppPagination = (total) => {
    const [page, setPage] = useState(FIRST_PAGE);

    const onClickNext = () => {
        const nextPage = page + STEP;

        if (nextPage < total) {
            setPage(nextPage);
        }
    };

    const onClickFirst = () => {
        setPage(0);
    };

    const onClickLast = () => {
        setPage(total - 1);
    };

    const onClickPrev = () => {
        const prevPage = page - STEP;

        if (prevPage >= FIRST_PAGE) {
            setPage(prevPage);
        }
    };

    const onClickPage = (page) => setPage(page);

    return { page, setPage, onClickNext, onClickFirst, onClickPrev, onClickLast, onClickPage };
};

const AppPagination = ({
    total,
    page,
    onClickPrev,
    onClickNext,
    onClickFirst,
    onClickLast,
    onClickPage,
    canPreviousPage = true,
    canNextPage = true,
    inBuffer = 2,
    mobile,
}) => {
    const prepareLinks = () => {
        const links = [];

        var cellsToDisplay = inBuffer * 2 + 1;
        if (cellsToDisplay % 2 == 0) throw 'Cells to display must be odd.';

        let lower = page - (cellsToDisplay - 1) / 2;
        let upper = page + (cellsToDisplay - 1) / 2 + 1;

        if (lower < 0) {
            lower = 0;
            upper = cellsToDisplay;
        }

        if (upper > total) {
            upper = total;
            lower = total - cellsToDisplay;
        }

        if (total <= cellsToDisplay) {
            upper = total;
            lower = 0;
        }

        //add links to array based on upper and lower buffers
        for (let i = lower; i < upper; i++) {
            links.push(<PageLink number={i} key={i} activePage={page} onClickPage={onClickPage} />);
        }

        return links;
    };

    const prevStyle = page === 0 || total == 0 ? 'far' : 'fas'
    const nextStyle = page === total - 1 || total == 0 ? 'far' : 'fas'

    return total > 1 ? (
        <div className={`${style.paginationWrapper}`}>
            <Pagination aria-label="Page navigation" className={style.pagination}>
                {canPreviousPage ? (
                    <>
                        {onClickFirst ? (
                            <NudgeButton color="success" disabled={page === 0 || total == 0} className={style.pageItem} onClick={onClickFirst}>
                                <FontAwesomeIcon icon={[prevStyle, 'arrow-alt-to-left']}/>
                            </NudgeButton>
                        ) : null}
                        <NudgeButton color="success" disabled={page === 0 || total == 0} className={style.pageItem} onClick={onClickPrev}>
                            <FontAwesomeIcon icon={[prevStyle, 'arrow-alt-left']}/>
                        </NudgeButton>
                    </>
                ) : null}

                {prepareLinks()}

                {canNextPage ? (
                    <>
                        <NudgeButton color="success" disabled={page === total - 1 || total == 0} onClick={onClickNext} className={style.pageItem}>
                            <FontAwesomeIcon icon={[nextStyle, 'arrow-alt-right']}/>
                        </NudgeButton>
                        {onClickLast ? (
                            <NudgeButton color="success" disabled={page === total - 1 || total == 0} onClick={onClickLast} className={style.pageItem}>
                                <FontAwesomeIcon icon={[nextStyle, 'arrow-alt-to-right']}/>
                            </NudgeButton>
                        ) : null}
                    </>
                ) : null}
            </Pagination>
        </div>
    ) : null
};

AppPagination.propTypes = {
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onClickPrev: PropTypes.func.isRequired,
    onClickPage: PropTypes.func.isRequired,
    canPreviousPage: PropTypes.bool,
    canNextPage: PropTypes.bool,
};

export default AppPagination;
