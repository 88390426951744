import React from 'react';
import Select from 'react-select';

import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { Col, Row } from 'reactstrap';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import GetConfig from './RGU/GetConfig';
import GetProjects from './RGU/GetProjects';
import GetSingleProject from './RGU/GetSingleProject';
import RetainContractor from './RGU/RetainContractor';
import RetainAllContractors from './RGU/RetainAllContractors';
import RetainProject from './RGU/RetainProject';
import RetrieveContractorMatches from './RGU/RetrieveContractorMatches';
import RetrieveProjectMatches from './RGU/RetrieveProjectMatches';
import UpdateCase from './RGU/UpdateCase';
import DeleteIndividualCase from './RGU/DeleteIndividualCase';
import ClearCases from './RGU/ClearCases';
import GetAllCases from './RGU/GetAllCases';

import { customStyles } from '../../../components/SelectField';
import ManageTemplates from './Willo/ManageTemplates';
import ManageInterviews from './Willo/ManageInterviews';
import ManageUsers from './Willo/ManageUsers';
import ManageWebhooks from './Willo/ManageWebhooks';
import ListDepartments from './Willo/ListDepartments';

import ClearRecommenderCases from './RGU/CareerRecommender/ClearRecommenderCases';
import RetainRecommenderCases from './RGU/CareerRecommender/RetainAllCases';

import SpaceStationResults from './SpaceStationResults/index';

import ManageCompanyPermissions from './Nudge/ManageCompanyPermissions';
import SendEmailNotification from './Nudge/SendEmailNotification';
import LinkedInParser from './RGU/LinkedInParser';
import UploadEdifyResults from './Nudge/UploadEdifyResults';

import NewSkillField from '../../../components/forms/NewInputForm';
import ReportGenerator from './RGU/ReportGenerator';
import ManageBookableAssessments from './Nudge/ManageBookableAssessments';
import TestGetShareCode from './Nudge/TestGetShareCode';
import useMobile from '../../../hooks/useMobile';
import TestConcepts from './Nudge/TestConcepts';

const projectIds = {
    recommenderModel: 'f87f084fe9024938b24a1102559c3ee9',
    matchModel: 'ec32f707d74145619ddff1cbd258bca0',
};

const API_KEYS = {
    willo: 'y1m4hmkkv2z6hqo7rorfgqnq4ytt44u16059',
};

const sectionOptions = [
    {
        label: 'Nudge Dashboard',
        value: [
            //{ label: 'Nudge: New Skill Field', value: <NewSkillField />},
            // { label: 'Nudge: Time Per Status (Test)', value: <TestTimePerStatus/>},
            { label: 'Nudge: Manage Recruitment Flow Assessments', value: <UploadEdifyResults /> },
            { label: 'Nudge: Manage Bookable Assessments', value: <ManageBookableAssessments/> },
            { label: 'Nudge: Send Site Notification', value: 'Under Construction' },
            { label: 'Nudge: Send Email Notification', value: <SendEmailNotification/> }, //
            { label: 'Nudge: Manage Company Permissions', value: <ManageCompanyPermissions /> },
            { label: 'Nudge: Space Station Results', value: <SpaceStationResults /> },
            { label: 'Nudge: Get Share Code', value: <TestGetShareCode/>},
            { label: 'Nudge: Testing Components', value: <TestConcepts/>}
        ],
    },
    {
        label: 'Video Interview Dashboard',
        value: [
            { label: 'Video Interview: Manage Interviews', value: <ManageInterviews api_key={API_KEYS.willo} /> },
            { label: 'Video Interview: Manage Templates', value: <ManageTemplates api_key={API_KEYS.willo} /> },
            { label: 'Video Interview: Manage Users', value: <ManageUsers api_key={API_KEYS.willo} /> },
            { label: 'Video Interview: Manage Webhooks', value: <ManageWebhooks api_key={API_KEYS.willo} /> },
            { label: 'Video Interview: List Departments', value: <ListDepartments api_key={API_KEYS.willo} /> },
        ],
    },
    {
        label: 'RGU Dashboard',
        value: [
            { label: 'Report Generator RGU: Report Tester', value: <ReportGenerator /> },
            { label: 'RGU: Get Projects', value: <GetProjects /> },
            { label: 'RGU: Get Config', value: <GetConfig /> },

            { label: 'RGU: Get All Cases (Matching Model)', value: <GetAllCases id={projectIds.matchModel} /> },
            {
                label: 'RGU: Retrieve Project Matches (Matching Model)',
                value: <RetrieveProjectMatches id={projectIds.matchModel} />,
            },
            {
                label: 'RGU: Retain All Contractors (Matching Model)',
                value: <RetainAllContractors id={projectIds.matchModel} />,
            },
            { label: 'RGU: Clear All Cases (Matching Model)', value: <ClearCases id={projectIds.matchModel} /> },

            {
                label: 'RGU: Get All Cases (Recommender Model)',
                value: <GetAllCases id={projectIds.recommenderModel} />,
            },
            {
                label: 'RGU: Clear All Cases (Recommender Model)',
                value: <ClearRecommenderCases id={projectIds.recommenderModel} />,
            },
            {
                label: 'RGU: Retrieve Project Matches (Recommender Model)',
                value: <RetrieveProjectMatches id={projectIds.recommenderModel} />,
            },
            {
                label: 'RGU: Retain All Cases (Recommender Model)',
                value: <RetainRecommenderCases id={projectIds.recommenderModel} />,
            },

            { label: 'Parser RGU: Submit CV to Parser API', value: <LinkedInParser /> },
            // {label: 'RGU: Get Single Project', value: <GetSingleProject/> },
            // {label: 'RGU: Retain Project', value: <RetainProject id={projectIds.recommenderModel}/>},
            //
            // {label: 'RGU: Retain New Contractor', value: <RetainContractor id={projectIds.matchModel}/>},
            // {label: 'RGU: Retrieve Contractor Matches', value: <RetrieveContractorMatches id={projectIds.matchModel}/>},
            // {label: 'RGU: Update Individual Case', value: <UpdateCase id={projectIds.matchModel}/>},
            // {label: 'RGU: Delete All Cases', value: <DeleteIndividualCase id={projectIds.matchModel}/>},
        ],
    },
];

const TestingGround = () => {
    const [section, setSection] = React.useState(sectionOptions[0]);
    const [component, setComponent] = React.useState(section.value[0]);

    const { isMobileWide } = useMobile();

    React.useEffect(() => {
        if (component) setComponent(section.value[0]);
    }, [section]);

    return (
        <SignInPageTemplate>
            <Row>
                <Col md={2} style={{ minHeight: isMobileWide ? '0' :'100vh' }}>
                    <Select
                        value={section}
                        options={sectionOptions}
                        styles={customStyles()}
                        onChange={(option) => {
                            setSection(option);
                        }}
                        isSearchable={false}
                    />
                    <br />
                    <Select
                        value={component}
                        options={section.value}
                        styles={customStyles()}
                        onChange={(option) => {
                            setComponent(option);
                        }}
                        isSearchable={false}
                    />
                    <br />
                </Col>
                <Col md={10}>{component ? component.value : null}</Col>
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
