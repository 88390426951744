import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, deleteWithAuthorizationHeader } from '../../../../../api/tools';

import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../components/buttons/NudgeButton';

const TestingGround = ({ id }) => {
    id = "a365404606bb40f3a564f771c6ffe4c7"
    const token = "eyJhbGciOiAiSFMyNTYiLCAidHlwIjogIkpXVCJ9.eyJuYW1lIjogIm5ld051ZGdlVG9rZW4iLCAiZXhwaXJ5IjogMTcxNDgyMDQwMH0.uEcwofIQ2y94HEXvCtMMQ2l5EkxDKnjvJh0Vzqyjee4";
    const [success, setSuccess] = React.useState(false);
    async function clearCases() {
        const deleteUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/${id}/case`;
        const response = await deleteWithAuthorizationHeader(deleteUrl, token);

        if (response) {
            setSuccess(true);
        }
    }

    return (
        <>
            <h5>{success ? 'Cases cleared' : null}</h5>
            <ClickButton onClick={clearCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
