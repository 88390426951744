export const rateOptions = [
    { label: 'Hourly', value: 'Hourly', altValue: 'hourly' },
    { label: 'Daily', value: 'Daily', altValue: 'daily' },
    { label: 'Yearly', value: 'Yearly', altValue: 'yearly' },
];

export const workTypeOptions = [
    { label: 'Volunteer', value: 'Volunteer', altValue: 'volunteer' },
    { label: 'Paid Work', value: 'Paid Work', altValue: 'paidWork' },
    { label: 'Open to Both', value: 'Open to both', altValue: 'openToBoth' },
];

export const workSearchOptions = [
    { label: 'Actively searching', value: 'Active', altValue: 'active' },
    { label: 'Interested in work', value: 'Passive', altValue: 'passive' },
];

export const workModeOptions = [
    { label: 'Remote', value: 'Remote', altValue: 'remote' },
    { label: 'On Location', value: 'On location', altValue: 'onLocation' },
    { label: 'Open to Both', value: 'Open to both', altValue: 'openToBoth' },
];

export const timingOptions = [
    { label: 'Full-Time', value: 'Full-Time', altValue: 'fullTime' },
    { label: 'Part-Time', value: 'Part-Time', altValue: 'partTime' },
    { label: 'Open to Both', value: 'Open to both', altValue: 'openToBoth' },
];

export const freelanceStaffOptions = [
    { label: 'Freelance', value: 'Freelance', altValue: 'freelance' },
    { label: 'Staff', value: 'Staff', altValue: 'staff' },
    { label: 'Open to Both', value: 'Open to both', altValue: 'openToBoth' },
];
