import { Formik } from 'formik';
import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import { patchData } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import EditableEducation from './EditableEducation';
import NonEditableEducation from './NonEditableEducation';
import { FIELDS, initialValues, validationSchema } from './validation';
import { updateCasebase } from '../../../ContractorInformation';
import style from './style.module.scss';
import Loader from '../../../Loader';
import NudgeCard from '../../../NudgeCard';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeTooltip from '../../../NudgeTooltip';

const CertificatesAndQualifications = ({
    user,
    certificates,
    qualification,
    showEdit,
    refresh,
    url,
    changeKey,
    theme,
    reset,
}) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const onClose = () => {
        setIsOpen(false);
    };

    const props = {
        initialValues: {
            ...initialValues,
            certifications: [],
            qualification: qualification?.split('/')[0],
            studyField: qualification?.split('/')[1],
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            await updateQualifications(values);
            await updateCasebase(user);

            await refresh();
            if (reset) reset();

            setIsSending(false);
            setIsOpen(false);
        },
    };

    const updateQualifications = async (values) => {
        var patchRequest = { Education: values['qualification'] + '/' + values[FIELDS.STUDY_FIELD] };
        await patchData(patchRequest, url, {});
    };

    return (
        <>
            <div className={style.profileHeader}>
                <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`education-header`} label={<b>{"Highest Qualification Achieved"}</b>}>
                        <div class="text-center">
                            Tell us about your highest achieved qualification.
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={onClickEdit}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <NudgeCard theme={theme} className="mt-10 mb-10">
                <CardBody>
                    {isOpen ? (
                        <Formik {...props}>
                            {(formik) =>
                                !sending ? <EditableEducation formik={formik} onClose={onClose} /> : <Loader />
                            }
                        </Formik>
                    ) : qualification ? (
                        <NonEditableEducation qualification={qualification} />
                    ) : (
                        <p style={{ fontStyle: 'italic' }}>No education details have been added yet.</p>
                    )}
                </CardBody>
            </NudgeCard>
        </>
    );
};

export default CertificatesAndQualifications;
