import React from 'react';
import { Formik } from 'formik';

import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { ADMIN } from '../../../consts/userRoles';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { NO_REFRESH_INTERVAL, authFetcher, authPostData, authSendFile } from '../../../api/tools';
import useSWR from 'swr';
import { API_DASHBOARD_ADMIN, API_PROFILE, URLS } from '../../../consts/urls';
import LinkButton from '../../../components/buttons/LinkButton';
import RecruiterList from './RecruiterList';
import { Button, Row } from 'reactstrap';
import AdminRecruitersFormModal from './AdminRecruitersFormModal';

import { validationSchema, FIELDS, initialValues } from './AdminRecruitersFormModal/validate';

import style from './style.module.scss';
import Loader from '../../../components/Loader';
import { checkIfEmailExists } from '../../../contexts/auth/tools';
import ClickButton from '../../../components/buttons/NudgeButton';

const AdminRecruiters = ({}) => {
    const { data: recruiters, mutate: refresh } = useSWR(
        `${API_DASHBOARD_ADMIN}/Recruiters`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    const [formOpen, setFormOpen] = React.useState(false);
    const toggle = () => setFormOpen(!formOpen);

    const [error, setError] = React.useState(``);
    const toggleError = () => setError(!error);

    async function onSubmit(v) {
        setLoading(true);

        try {
            const emailExists = await checkIfEmailExists(v.email);

            if (!emailExists) {
                const response = await authPostData(v, `${API_DASHBOARD_ADMIN}/Profile/Recruiter`); 

                if (response.success) {
                    await authSendFile(v.photo, `${API_PROFILE}/Photo`, { uid: response.rId });
                }
            } else {
                setError('Email already exists.');
            }
            //
        } catch (e) {
            console.log(e.message);
        } finally {
            await refresh();
            setLoading(false);
            toggle();
        }
    }

    const [loading, setLoading] = React.useState(false);

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await onSubmit(values);
        },
    };

    return (
        <>
            <Formik {...props}>
                {(formik) => (
                    <AdminRecruitersFormModal
                        formik={formik}
                        loading={loading}
                        isOpen={formOpen}
                        toggle={toggle}
                        error={error}
                    />
                )}
            </Formik>

            <SignInPageTemplate>
                <h1 className={style.title}>
                    Recruiters
                    <ClickButton color="success" onClick={toggle}>
                        Add New Recruiter
                    </ClickButton>
                </h1>

                <Row>
                    {recruiters ? (
                        <RecruiterList data={recruiters} />
                    ) : (
                        <Loader message="Fetching recruiter profiles" />
                    )}
                </Row>
            </SignInPageTemplate>
        </>
    );
};

export default withAuthProtectedRouter(AdminRecruiters, [ADMIN]);
