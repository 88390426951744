import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Row } from 'reactstrap';

import OffersList from './PageComponents/OfferList';
import ProjectList from './PageComponents/ProjectList';
import CompanyData from './PageComponents/CompanyData';
import CompanyBVT from './PageComponents/CompanyBVT';

import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { USER, ADMIN, COMPANY, RECRUITER } from '../../../consts/userRoles';

import CompanyInformation from './CompanyInformation';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import useSWR from 'swr';
import { NEVER_REFRESH, authFetcher } from '../../../api/tools';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

import Loader from '../../../components/Loader';

import useMobile from '../../../hooks/useMobile';
import { countNew } from '../../SignInPageTemplate/SignInNavbar/NotificationsModal';
import NudgeTabs from '../../NudgeTabs';
import { API_DASHBOARD_WILLO } from '../../../consts/urls';
import { GetPageTheme, generateMonoLinearGradient } from '../GenericCompanyProfile/HelperComponents';
import NudgeSwiper from '../../NudgeSwiper';

export const FullCompanyProfile = ({ url, detailsUrl, showEdit, id }) => {

    const { userRoles, user } = useContext(AuthContext);
    const [fetching, setFetching] = React.useState(false);

    const { isMobileWide } = useMobile();

    const { data, mutate, isValidating } = useSWR(url, authFetcher, NEVER_REFRESH);
    
    const { data: offers, isValidating: validatingOffers } = useSWR(
        userRoles.includes('Recruiter') ? `` : `${url}/Offers`,
        authFetcher,
        NEVER_REFRESH,
    );
    const {
        data: extraDetails,
        isValidating: validatingDetails,
        mutate: mutateDetails,
    } = useSWR(detailsUrl, authFetcher, NEVER_REFRESH);

    if (!id && data) id = data.id;

    var newNotifications = 0;
    if (user && userRoles) {
        if (!userRoles.includes('Admin')) {
            newNotifications = countNew(user.notifications);
        }
    }

    const [colour, setColour] = React.useState(data?.backColour ? `#${data?.backColour}` : "#407962");
    React.useEffect(() => {
        if(data) {
            if(data?.backColour) setColour(`#${data.backColour}`);
        }
    }, [data]);

    const TABS = {
        OFFERS: 'offers',
        PROJECTS: 'projects',
        BVT: 'bvt',
        DATA: 'data'
    }
    const [selectedTab, setSelectedTab] = React.useState(TABS.OFFERS);

    const loading = !data || !extraDetails || !offers

    return user ? (
        <SignInPageTemplate>
            <div>
                <Helmet>
                    <title>
                        {user ? (newNotifications.length ? `(${newNotifications})` : '') : ''}{' '}
                        {data?.name || 'Companies'} - Nudge Energy Talent Lab
                    </title>
                </Helmet>

                <Row>
                    <Col sm={12} md={6} lg={3}>
                        { !loading ? <>
                            <CompanyInformation user={data} extraData={extraDetails} refresh={mutate} showEdit={showEdit} colour={colour} setColour={setColour} />
                        </> : <>
                            <Card style={{ minHeight: '1250px' }}>
                                <div style={{ width: '100%', height: '100px', background: generateMonoLinearGradient(`${colour}`), position: 'absolute', borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem' }}/>
                                <div class="flex-center-align-justify" style={{ paddingTop: '1.25rem' }}>
                                    <NudgeSwiper 
                                        width={{ desktop: '170px', mobile: '140px' }}
                                        height={{ desktop: '170px', mobile: '140px' }}
                                        borderRadius={'50%'}
                                        gap={'0px'}
                                    />
                                </div>
                                <div class="flex-center-align-justify" style={{ paddingTop: '1.25rem' }}>
                                    <NudgeSwiper
                                        width={{ desktop: '60%', mobile: '60%' }}
                                        height={{ desktop: '36px', mobile: '36px' }}
                                        gap={'7.5px'}
                                    />
                                </div>
                                <div class="flex-center-align-justify" style={{ paddingTop: '1.25rem' }}>
                                    <NudgeSwiper
                                        width={{ desktop: '90%', mobile: '90%' }}
                                        height={{ desktop: '750px', mobile: '750px' }}
                                        gap={'7.5px'}
                                    />
                                </div>
                            </Card>
                        </>} 
                    </Col>

                    <Col sm={12} md={6} lg={9}>
                            { !!isMobileWide && <NudgeTabs
                                label="Profile Sections"
                                selected={selectedTab}
                                setTab={setSelectedTab}
                                tabs={[
                                    { id: TABS.OFFERS, label: 'Current Offers', condition: true },
                                    { id: TABS.PROJECTS, label: 'Current Projects', condition: true },
                                    { id: TABS.BVT, label: 'Traits, Values and Benefits', condition: true },
                                    { id: TABS.DATA, label: 'Company Information', condition: true },
                                ]}
                            /> }
                        <Row>
                            {(selectedTab == TABS.OFFERS || !isMobileWide) && <Col md={12} lg={6}>
                                { !loading ? (
                                    <OffersList
                                        offers={offers}
                                        userRoles={userRoles}
                                        mobile={isMobileWide}
                                        companyName={data.name}
                                    />
                                ) : (
                                    <>
                                        {/* <div style={{ height: '38px', marginBottom: '12px' }}/> */}
                                        {[...Array(5)].map((_) =>
                                            <Card id={`card-${GetPageTheme()}`} style={{ padding: '12px 12px', minHeight: '126px', marginBottom: '12px' }}>
                                                <NudgeSwiper 
                                                    width={{ desktop: '60%', mobile: '100%' }}
                                                    height={{ desktop: '22.5px', mobile: '45px' }}
                                                    gap={'7.5px'}
                                                />

                                                <div class="flexy" style={{ columnGap: '12px' }}>
                                                    {[...Array(4)].map((_) =>
                                                        <NudgeSwiper 
                                                            width={{ desktop: '22%', mobile: '100%' }}
                                                            height={{ desktop: '22.5px', mobile: '28px' }}
                                                            gap={'7.5px'}
                                                        />
                                                    )}
                                                </div>
                                            </Card>
                                        )}
                                    </>
                                )}
                                { !validatingOffers && !offers && (
                                    <h5 className="text-center">Could not load offers</h5>
                                ) }
                            </Col> }
                            { (selectedTab == TABS.PROJECTS || !isMobileWide) && <Col md={12} lg={6}>
                                { !loading ? (
                                    <ProjectList
                                        mobile={isMobileWide}
                                        data={extraDetails}
                                        user={user}
                                        userRoles={userRoles}
                                        mutate={mutateDetails}
                                        showEdit={showEdit}
                                        id={id}
                                    />
                                ) : (
                                    <>
                                        {[...Array(2)].map((_) =>

                                            <Card id={`card-${GetPageTheme()}`} style={{ padding: '12px 27px', minHeight: '333px', marginBottom: '12px' }}>
                                                <div class="w-100" style={{ marginTop: '8px' }}>
                                                    <NudgeSwiper 
                                                        width={{ desktop: '60%', mobile: '10px' }}
                                                        height={{ desktop: '24px', mobile: '10px' }}
                                                        gap={'24px'}
                                                    />
                                                </div>

                                                <NudgeSwiper 
                                                    width={{ desktop: '100%', mobile: '10px' }}
                                                    height={{ desktop: '40px', mobile: '10px' }}
                                                    gap={'12px'}
                                                />

                                                <NudgeSwiper 
                                                    width={{ desktop: '100%', mobile: '10px' }}
                                                    height={{ desktop: '190px', mobile: '10px' }}
                                                    gap={'12px'}
                                                />
                                            </Card>
                                        )}
                                    </>
                                )}
                                {!validatingDetails && !extraDetails && (
                                    <h5 className="text-center">Could not load projects</h5>
                                )}
                            </Col> }
                        </Row>
                        <Row>
                            { (selectedTab == TABS.BVT || !isMobileWide) && <Col md={12} lg={6}>
                                {!loading ? (
                                    <CompanyBVT
                                        data={data}
                                        extraDetails={extraDetails}
                                        user={user}
                                        mutate={mutateDetails}
                                        showEdit={showEdit}
                                        id={data?.id}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </Col> }
                            { (selectedTab == TABS.DATA || !isMobileWide) && <Col md={12} lg={6}>
                                { !loading ? (
                                    <CompanyData
                                        data={data}
                                        offers={offers}
                                        extraDetails={extraDetails}
                                        user={user}
                                        mutate={mutateDetails}
                                        showEdit={showEdit}
                                        id={data?.id}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </Col> }
                        </Row>
                    </Col>
                </Row>
            </div>
        </SignInPageTemplate>
    ) : (
        <Loader fullPage/>
    );
};

export default withAuthProtectedRouter(FullCompanyProfile, [USER, ADMIN, COMPANY, RECRUITER]);
