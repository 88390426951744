import React from 'react';
import Select from 'react-select';
import { customStyles } from '../../../../../components/SelectField';

import Loader from '../../../../../components/Loader';
import { API_DASHBOARD_ADMIN } from '../../../../../consts/urls';
import { authFetcher, authPostData } from '../../../../../api/tools';
import { Button, Col, Input, Label, Row } from 'reactstrap';

import style from './style.module.scss';
import ClickButton from '../../../../../components/buttons/NudgeButton';
import NudgeButton from '../../../../../components/buttons/NudgeButton';

const UploadEdifyResults = () => {
    const [file, setFile] = React.useState();
    const [videoUrl, setVideoUrl] = React.useState(``);
    const [fileName, setFileName] = React.useState('');

    const [selectedMatch, setSelectedMatch] = React.useState();

    const [data, setData] = React.useState();
    const [error, setError] = React.useState();
    const [success, setSuccess] = React.useState();

    const [sending, setSending] = React.useState(true);
    const [mutateKey, setMutateKey] = React.useState();
    const mutate = () => setMutateKey(!mutateKey);

    React.useEffect(() => {
        async function fetchData() {
            setSending(true);
            const response = await authFetcher(`${API_DASHBOARD_ADMIN}/Matches/Edify`);

            const optionsArray = [];
            response.forEach((match) => {
                const option = {
                    label: `${match.broadcastResult ? '(Submitted)' : ''} ${match.id}: ${match.offer.title} - ${
                        match.contractor.name
                    } with ${match.offer.company.name}`,
                    value: match.id,
                    isDisabled: !!match.broadcastResult,
                };

                optionsArray.push(option);
            });

            setData(optionsArray);
            setSending(false);
        }

        fetchData();
    }, [mutateKey]);

    React.useEffect(() => {
        if(selectedMatch) {
            setFile(); setVideoUrl(""); setFileName("");
        }
    }, [selectedMatch])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadFile = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const base64 = await convertBase64(file);
            setFileName(file.name);

            var extension = base64.split(':')[1].split(';')[0].split('/')[1];

            if (extension == 'pdf') {
                setFile('' + base64);
                setError('');
            } else {
                var doc = document.getElementById('user_details');
                doc.value = null;

                setError('Invalid file type. Must be a pdf.');
            }
        }
    };

    function onChangeInput(event) {
        setVideoUrl(event.target.value);
    }

    function validate() {
        if (file && validateUrl() && selectedMatch) {
            return true;
        }

        return false;
    }

    function validateUrl() {
        var url = videoUrl;
        if(!url) return false;

        var REG_EX =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

        if (REG_EX.test(url)) {
            return true;
        } else {
            return false;
        }
    }

    async function submitResults() {
        setSending(true);
        const obj = {
            file: file ? file.split(',')[1] : '',
            videoLink: videoUrl,
            extension: file ? file.split(',')[0].split(':')[1] : '',
        };

        const response = await authPostData(
            obj,
            `${API_DASHBOARD_ADMIN}/Matches/${selectedMatch.value}/AssessmentResult`,
        );

        refresh();

        setSuccess('Results submitted successfully.');
        setSending(false);
        mutate();
    }

    function clickFile() {
        const file = document.getElementById('user_details');

        if(file) {
            file.click();
        }
    }

    function refresh() {
        setSelectedMatch('');

        setFile();
        var doc = document.getElementById('user_details');
        if (doc) doc.value = null;

        setVideoUrl('');
    }

    return (
        <>
            {!sending ? (
                <>
                    <Row>
                        <Col md={9}>
                            <Label>
                                <b>Selected Match to Upload Results To</b>
                            </Label>
                            <Select
                                disabled={!data}
                                isDisabled={!data}
                                value={selectedMatch}
                                options={data}
                                styles={customStyles()}
                                onChange={(option) => {
                                    setSelectedMatch(option);
                                }}
                                isSearchable={false}
                            />
                        </Col>
                    </Row>
                    <br />

                    { selectedMatch ? <>
                        <Row>
                            <Col md={4}>
                                <Label>
                                    <b>Mike's Report</b>
                                </Label>
                                <br />

                                <div style={{ display: 'flex', columnGap: '12px' }}>
                                    <NudgeButton color="success" onClick={() => clickFile()}>Upload File</NudgeButton>
                                    <h5 style={{ fontSize: '1rem', marginTop: 'auto' }}>{fileName}</h5>
                                </div>
                                <input type="file" id="user_details" style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
                                {error ? <h5 className={style.error}>{error}</h5> : null}
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={6}>
                                <Label>
                                    <b>URL of Assessment Broadcast</b>
                                </Label>
                                <Input value={videoUrl} onChange={onChangeInput} invalid={!validateUrl()} />
                                {!validateUrl() ? <h5 className={style.error}>This is not a valid YouTube URL.</h5> : null}
                            </Col>
                        </Row>
                        <br />

                        <h5>{success}</h5>
                        <ClickButton
                            disabled={!validate()}
                            onClick={submitResults}
                            color={'success'}
                        >
                            Submit Assessment Results
                        </ClickButton>
                    </> : null }
                </>
            ) : (
                <Loader message="Fetching. This could take a while" />
            )}
        </>
    );
};

export default UploadEdifyResults;
