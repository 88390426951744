import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { DEFAULT_PICTURE } from '../../../../consts/urls';
import { ResolveSalary, calculateLightness, generateMonoLinearGradient } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../consts/urls';

import style from './style.module.scss';
import NudgeBoxes from '../../../../components/NudgeBoxes';
import moment from 'moment/moment';

const ContractorCard = ({ contractor, search, page }) => {
    const [open, setOpen] = React.useState(false);
    const toggle = () => {
        setOpen(!open);
    };

    function viewCV(url) {
        window.open(`${url}`, '_blank', 'noopener');
    }

    function ProcessJob(contractor) {
        var text = '';
        if (contractor.job.name && contractor.company) {
            if (contractor.job.name == contractor.company) {
                text = `${contractor.job.name}`;
            } else {
                text = `${contractor.job.name} at ${contractor.company}`;
            }
        } else {
        }

        return text ? text : "N/A";
    }

    function ProcessEarnings(earnings, currency, frequency, type) {
        earnings = earnings.replaceAll(' ', '');
        const process = {
            Hourly: 'HOUR',
            Yearly: 'YEAR',
            Daily: 'DAY',
        };

        return ResolveSalary(earnings, currency, process[frequency], type);
    }

    React.useEffect(
        (item) => {
            if (open) setOpen(false);
        },
        [search, page],
    );

    return (
        <Card className={open ? style.offerCardOpen : style.offerCard}>
            <div style={{ background: generateMonoLinearGradient(contractor.bannerColour ? `#${contractor.bannerColour}` : `#407962`), borderBottom: `2px solid ${contractor.bannerColour ? `#${contractor.bannerColour}` : `#407962`}` }} className={style.banner}></div>

            <div className={style.content}>
                <h5 onClick={toggle} className={style.moreDetails}>
                    <FontAwesomeIcon icon={['fas', open ? 'minus-circle' : 'plus-circle']}/>
                </h5>

                <Row>
                    <Col lg={3}>
                        <div class="flexy">
                            <img src={contractor.pictureUrl || DEFAULT_PICTURE} className={style.companyPicture} style={{ borderColor: contractor.bannerColour ? `#${contractor.bannerColour}` : `#407962` }} />
                        </div>
                        {contractor.cv ? (
                            <div className={style.resumeIconWrapper} onClick={() => viewCV(contractor.cv)}>
                                <FontAwesomeIcon className={style.resumeIcon} icon={[`far`, `download`]} />
                                {` CV`}
                            </div>
                        ) : (
                            <div className={style.resumeIconWrapper}>{`No CV`}</div>
                        )}
                    </Col>
                    <Col lg={9}>
                        <h5 className={style.jobName} style={{ color: calculateLightness(`#${contractor.bannerColour || "407962"}`) > 50 ? "black" : "white" }} onClick={ () => window.open(`${URLS.ADMIN_CONTRACTORS}/${contractor.id}`, '_blank', 'noreferrer')}>
                            {contractor.name}
                        </h5>
                        <p className={style.lastLoggedIn} style={{ color: calculateLightness(`#${contractor.bannerColour || "407962"}`) > 50 ? "black" : "white" }}><b>Last Logged In: </b> {moment(contractor.lastLoggedIn).format("DD/MM/YYYY HH:MM:SS a")}</p>
                        <div className={style.boxesWrapper}>
                            <NudgeBoxes
                                data={[
                                    { label: `${contractor.availableFrom ? `Available from: ${contractor.availableFrom}` : ""}`, danger: false },
                                    { label: ProcessJob(contractor), danger: false },
                                    { label: `${contractor.job.industry}`, danger: false },
                                    { label: ProcessEarnings(contractor.expectedEarnings, contractor.currencyType, contractor.timing, contractor.workType), danger: false },
                                ]}
                            /> 
                        </div>
                    </Col>
                </Row>
                {open ? (
                    <Row style={{ borderTop: '1px solid gray', paddingTop: '12px' }}>
                        <Col md={6}>
                            <h5 className={style.attribute}>Certificates</h5>
                            { contractor.certificates.length ? <NudgeBoxes
                                data={contractor.certificates.map((item) => ({ label: item.name, danger: false}))}
                                fullWidth
                            /> : <i>Empty</i> }
                        </Col>
                        <Col md={6}>
                            <h5 className={style.attribute}>Skills</h5>
                            { contractor.skills.length ? <NudgeBoxes
                                data={contractor.skills.map((item) => ({ label: item, danger: false}))}
                                fullWidth
                            /> : <i>Empty</i> }
                        </Col>
                    </Row>
                ) : null}
            </div>
        </Card>
    );
};

export default ContractorCard;
