import React from 'react';
import { Form, Formik } from 'formik';
import { Card, Button, FormGroup, Label } from 'reactstrap';
import { initialValues, validationSchema, FIELDS, formLabels } from './validation';
import SliderComp from '../../../../../../components/SliderComp';
import style from './style.module.scss';
import AppInput from '../../../../../../components/AppInput';
import { authPostData } from '../../../../../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../../../../../consts/urls';
import Loader from '../../../../../../components/Loader';
import ClickButton from '../../../../../../components/buttons/NudgeButton';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';

const AssessmentFeedbackCard = ({ matchId, user, feedback, mutate }) => {
    const theme = GetPageTheme();

    var userId = user.subId || user.id;
    var userName = user.subName || user.name;

    const minValue = 1;
    const maxValue = 10;
    const increment = 1;

    function fillAnswers() {
        const obj = {};

        obj[`answer-${0}`] = minValue;

        return obj;
    }

    const props = {
        initialValues: {
            ...initialValues,
            [FIELDS.WEIGHTS]: fillAnswers(),
        },
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);
            var score = compileScores(Object.values(values[FIELDS.WEIGHTS]));

            const obj = {
                feedback: values.feedback,
                score: parseFloat(score),
                type: 'vr_assessment',
            };

            try {
                const response = await authPostData(
                    obj,
                    `${API_DASHBOARD_COMPANY}/Matches/${matchId}/InternalFeedback`,
                );
                await mutate();
                setSending(false);
            } catch (e) {
                console.log(e.message);
            } finally {
                setSending(false);
            }
        },
    };

    function compileScores(scores) {
        if (!scores || !scores.length) return 0;

        var score = 0;
        scores.forEach((item, index) => {
            score += item;
        });

        score = score / scores.length;

        return score;
    }

    const [sum, setSum] = React.useState(0);
    const [sending, setSending] = React.useState(false);

    React.useEffect(() => {
        // Using reduce function to find the sum
        var _sum = feedback.reduce(function (x, y) {
            return x + y.score;
        }, 0);
        _sum = _sum / feedback?.filter((p => !!p.feedback)).length;

        setSum(_sum ? _sum : 0);
    }, [feedback]);

    const myFeedback = feedback.find((item) => item.companyId == userId && item.feedback != "");

    function filterFeedback() {
        if (myFeedback) {
            return feedback.filter((item) => item.companyId != userId);
        } else {
            return feedback;
        }
    }

    return !sending ? (
        <>
            <Card className={style.matchFeedbackCard}>
                {myFeedback ? (
                    <div className={ theme == 'light' ? style.myFeedback : style.myFeedbackDark}>
                        <h5>{userId == myFeedback.companyId ? 'You' : myFeedback.companyName}</h5>
                        <h4>{myFeedback.feedback}</h4>
                        <h3>Score: {myFeedback.score.toFixed(2)}</h3>
                    </div>
                ) : null}
                {filterFeedback().map((item, index) => (
                    <div className={!item.feedback ? style.feedbackEmpty : theme == 'light' ? style.feedback : style.feedbackDark}>
                        <h5>{userId == item.companyId ? 'You' : item.companyName}</h5>
                        <h4>{item.feedback || <i>Feedback not provided yet</i>}</h4>
                        {item.score > 0 && <h3>Score: {item.score.toFixed(2)}</h3>}
                    </div>
                ))}
                <div className={style.candidateScoreContainer}>
                    <h5 className={theme == 'light' ? style.candidateScore : style.candidateScoreDark}>
                        Overall candidate VR assessment score: {sum > 0 ? `${sum.toFixed(1)}/${maxValue.toFixed(1)}` : `N/A`}
                    </h5>
                </div>
            </Card>
            {!myFeedback ? (
                <Card className={style.matchFeedbackCard}>
                    <Formik {...props}>
                        {(formik) => (
                            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                <div>
                                    <>
                                        <FormGroup className="mb-10">
                                            <h5>Assessment Score</h5>
                                            <p style={{ marginBottom: '24px' }}>
                                                Score this assessment from {minValue} to {maxValue}.
                                            </p>

                                            <SliderComp
                                                form={formik}
                                                field={FIELDS.WEIGHTS}
                                                name={`answer-${0}`}
                                                minValue={minValue}
                                                maxValue={maxValue}
                                                step={increment}
                                            />
                                        </FormGroup>
                                    </>
                                </div>

                                <Label for={FIELDS.FEEDBACK}>
                                    <h5>Additional Comments</h5>
                                </Label>
                                <p>Anything more specific relating to the quality of the assessment results.</p>
                                <FormGroup className="mb-10">
                                    <AppInput
                                        id={FIELDS.FEEDBACK}
                                        formik={formik}
                                        label={formLabels[FIELDS.FEEDBACK]}
                                        type="textarea"
                                        charLimit={200}
                                        className={style.input}
                                    />
                                </FormGroup>
                                <br />

                                <ClickButton color="success" type="submit" style={{ float: 'right' }}>
                                    Submit
                                </ClickButton>
                            </Form>
                        )}
                    </Formik>
                </Card>
            ) : (
                <Card className={style.matchFeedbackCard}>
                    <p>Thank you for your feedback on this assessment.</p>
                </Card>
            )}
        </>
    ) : (
        <Card className={style.matchFeedbackCard}>
            <Loader message="Submitting" />
        </Card>
    );
};

export default AssessmentFeedbackCard;
