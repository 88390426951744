import { Formik } from 'formik';
import React, { useState } from 'react';

import { putData } from '../../../api/tools';
import Loader from '../../Loader';
import EditableBio from './EditableBio';
import { initialValues, validationSchema } from './validation';
import { COMPANY } from '../../../consts/userRoles';
import style from './style.module.scss';

const CompanyInfoForm = ({ id, onClickClose, refresh, url, bioType = COMPANY, info }) => {
    const [key, setKey] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const onClose = () => onClickClose(false);
    const refreshData = async () => {
        await refresh();
        setKey(!key);
    };

    const updateInfo = async (values) => {
        setIsSending(true);
        try {
            await putData(values, url, {});
        } catch (e) {
            setIsSending(false);
        }
    };

    const props = {
        initialValues: info ? info : initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await updateInfo(values);
            await refreshData();
            onClickClose();
        },
    };

    return (
        <div className={style.container}>
            {!isSending ? (
                <Formik {...props}>
                    {(formik) => <EditableBio formik={formik} onClose={onClose} bioType={bioType} />}
                </Formik>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default CompanyInfoForm;
