import React from 'react';
import { Col, Row } from 'reactstrap';
import { API_DASHBOARD_CLOOD } from '../../../../../consts/urls';
import { authPostData } from '../../../../../api/tools';
import { generateRandomString } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import ClickButton from '../../../../../components/buttons/NudgeButton';

const ReportGenerator = ({ user, weights, offerId, stored }) => {
    const [key, setKey] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [error, setError] = React.useState('');
    const [base64, setBase64] = React.useState('');
    
    function grabSimScore(label, weight) {
        const simObj = user.match_explanation.find((p) => p.field == label)
        
        if(simObj) {
            if (weight) {
                if(stored) {
                    return parseFloat(simObj.similarity.toFixed(2));
                } else {
                    return parseFloat((simObj.similarity / weight).toFixed(2));
                }
            } else {
                return parseFloat(simObj.similarity.toFixed(2));
            }
        }
        else {
            return parseFloat(0);
        }
    }

    async function generateReport() {
        setLoading(true);
        setError(``);
        setBase64(``);

        if(user.feedback.Skills) user.feedback.Skills.applicantScores = user.feedback.Skills.scores;
        if(user.feedback.Values) user.feedback.Values.applicantScores = user.feedback.Values.scores ;
        if(user.feedback.Certificates) user.feedback.Certificates.applicantScores = user.feedback.Certificates.scores;

        const reportQueryObject = {
            contractorId: user.Id,
            offerId: parseInt(offerId),
            feedback: JSON.stringify(user?.feedback),
            totalScore: parseInt(user.totalScore__),
            scores: {
                services: grabSimScore('Services Required', weights?.services_required),
                remuneration: grabSimScore('Remuneration', weights?.remuneration),
                workMode: grabSimScore('Work Mode', weights?.workMode),
                education: grabSimScore('Education', weights?.education),
                certificates: grabSimScore('Certificates', weights?.certificates),
                experience: grabSimScore('Experience', weights?.experience),
                skills: grabSimScore('Skills', weights?.skills),
                values: grabSimScore('Values', weights?.values),
            },
        };

        const reportResponse = await authPostData(reportQueryObject, `${API_DASHBOARD_CLOOD}/ReportGenerator/Generate`);

        if (reportResponse) {
            if (reportResponse.message || reportResponse.errors) {
                setError(reportResponse.message);
            } else {
                setBase64(`data:application/pdf;base64,${reportResponse}`);
            }
        } else {
            setError(`Something went wrong.`);
        }
        setLoading(false);
    }

    function downloadFile(url) {
        const a = document.createElement('a');

        a.href = url;
        a.download = `candidateReport-${offerId}-${generateRandomString(5)}.pdf`;
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <Row>
            <Col>
                <div className={style.statusContainer}>
                    {loading ? <h3 className={style.loading}>Generating</h3> : null}
                    {base64 && !loading ? <h5>Report generated successfully.</h5> : null}
                    {error && !loading ? <h4>{error}</h4> : null}
                </div>
                <div className={style.buttonContainer}>
                    {!loading && !base64 ? (
                        <ClickButton color="success" onClick={generateReport}>
                            Generate Feedback Report
                        </ClickButton>
                    ) : null}
                    {!error && base64 ? (
                        <ClickButton color="success" onClick={() => downloadFile(base64)}>
                            Download Feedback Report
                        </ClickButton>
                    ) : null}
                    {!loading && base64 ? (
                        <ClickButton color="success" onClick={generateReport}>
                            Refresh
                        </ClickButton>
                    ) : null}
                </div>
            </Col>
        </Row>
    );
};

export default ReportGenerator;
