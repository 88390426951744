import React from 'react';
import useSWR from 'swr';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import CompanyPageTemplate from '../../../components/CompanyPageTemplate';

import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { COMPANY } from '../../../consts/userRoles';

import Loader from '../../../components/Loader';
import { API_GATEWAY } from '../../../consts/urls';
import ContractorsTimesheet from './ContractorsTimesheet';
import style from './style.module.scss';
import SwitchToDesktop from '../../../components/SwitchToDesktop';

const CompanyPayments = () => {
    const { data, isValidating } = useSWR(`${API_GATEWAY}/Payment/BillingData`, authFetcher, NO_REFRESH_INTERVAL);

    return (
        <SwitchToDesktop>
            <CompanyPageTemplate>
                <h1 style={{ fontFamily: 'Work Sans', fontSize: '2rem' }} className={style.title}>
                    Payments
                </h1>
                {data ? (
                    <ContractorsTimesheet />
                ) : isValidating ? (
                    <Loader />
                ) : (
                    <h5 className="text-center">No timesheets found.</h5>
                )}
            </CompanyPageTemplate>
        </SwitchToDesktop>
    );
};

export default withAuthProtectedRouter(CompanyPayments, [COMPANY]);
