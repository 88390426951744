import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import AppInput from '../../../../../components/AppInput';
import CheckboxField from '../../../../../components/CheckboxField';
import CustomFormikError from '../../../../../components/CustomFormikError';
import style from './style.module.scss';
import { DEFAULT_LABELS, FIELDS, OTHER_LABEL } from './validation';
import ClickButton from '../../../../../components/buttons/NudgeButton';

const OtherLabel = ({ form }) => {
    return (
        <>
            Other:
            <div className="d-inline-block ml-3">
                <AppInput
                    id={FIELDS.OTHERS}
                    formik={form}
                    onChange={() => (form.values[FIELDS.REASON] = OTHER_LABEL)}
                    label={OTHER_LABEL}
                />
            </div>
        </>
    );
};

OtherLabel.propTypes = {
    form: PropTypes.object.isRequired,
};

const DismissModalForm = ({ formik, toggle }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        {DEFAULT_LABELS.map((label) => (
            <FormGroup key={label}>
                <Field
                    name={FIELDS.REASON}
                    component={CheckboxField}
                    formik={formik}
                    label={label}
                    className={style.label}
                />
            </FormGroup>
        ))}

        <FormGroup>
            <Field
                className={style.label}
                name={FIELDS.REASON}
                value={OTHER_LABEL}
                component={CheckboxField}
                label={<OtherLabel form={formik} />}
            />
        </FormGroup>

        <FormGroup>
            <CustomFormikError form={formik} name={FIELDS.REASON} />
        </FormGroup>

        <div className={style.modalFooter}>
            <ClickButton color="success" onClick={toggle} size="lg" className={style.button} type="submit">
                Submit
            </ClickButton>
        </div>
    </Form>
);

export default DismissModalForm;
