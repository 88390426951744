import { Auth } from 'aws-amplify';

import { API_PROFILE } from '../../consts/urls';

export const changeUserPassword = async ({ currentPassword, newPassword }, callback, onError) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, currentPassword, newPassword);
        callback && callback();
    } catch (e) {
        if (e.message === 'Incorrect username or password.') {
            e.message = 'Incorrect password.';
        } else if (e.message === 'Attempt limit exceeded, please try after some time.') {
            e.message = 'Too many attempts made. Try again later.';
        }
        onError(e);
    }
};

export const forgotUserPassword = async (username) => {
    try {
        await Auth.forgotPassword(username);
    } catch (e) {
        console.log(`There was a problem: ${e.message}.`);
    }
};

export const forgotPasswordSubmit = async (username, code, newPassword) =>
    await Auth.forgotPasswordSubmit(username, code, newPassword);

export const signUpUser = async ({ email, password, picture, name, type }) => {
    const data = { username: email, password, attributes: { picture, name, email, 'custom:type': type } };
    const user = await Auth.signUp(data);
    return user;
};

export const resendConfirmationCode = async (username) => {
    try {
        await Auth.resendSignUp(username);
    } catch (err) {
        console.log('error resending code: ', err);
    }
};

export const confirmSignUp = async (username, code, onSuccessConfirmed) => {
    try {
        await Auth.confirmSignUp(username, code);
        onSuccessConfirmed();
    } catch (error) {
        console.log('error confirming sign up', error);
    }
};

export const getUserProfile = async () => {
    try {
        const user = await Auth.currentSession();
        const idToken = user.getIdToken();
        return idToken.payload;
    } catch (error) {
        console.log('ERROR get profile', error);
    }
};

export const checkIfEmailExists = async (email) => {
    const response = await fetch(`${API_PROFILE}/Exists?email=${email}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
};
