import * as Yup from 'yup';

export const FIELDS = {
    PHOTO: 'photo',
    NAME: 'username',
    BIO: 'bio',
    COMPANY: 'company',
    RATE_RANGE: 'expectedEarnings',
    RESUME: 'resume',
    SECTOR: 'jobIndustryId',
    CATEGORY: 'jobSubIndustryId',
    SERVICE_OFFERED: 'jobName',
    RATE_RANGE_LOW: 'rateLow',
    RATE_RANGE_HIGH: 'rateHigh',
    TIMING: 'timing',
    LOCATION: 'location',
    WORK_TYPE: 'workType',
    WORK_MODE: 'workMode',
    WORK_TIMING: 'workTiming',
    FREELANCER_STAFF: 'freelancerStaff',
    WORK_SEARCH: 'workSearch',
    CURRENCY: 'currencyType',
    AVATAR_LINK: 'avatarLink',
    BANNER_COLOUR: 'bannerColour',
    DELETE_PHOTO: 'deletePhoto',
    DELETE_RESUME: 'deleteResume',
    VIDEO_LINK: 'videoLink'
};

export const WORD_LIMIT = 1000;
export const VIDEO_REG_EX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required'),
    [FIELDS.BIO]: Yup.string().min(1, 'What?').max(WORD_LIMIT, 'Bio cannot be larger than 1000 characters.'),
    [FIELDS.RATE_RANGE_HIGH]: Yup.string().required('This field is required'),
    [FIELDS.RATE_RANGE_LOW]: Yup.string().required('This field is required'),
    [FIELDS.SECTOR]: Yup.string().required('This field is required'),
    [FIELDS.CATEGORY]: Yup.string().required('This field is required'),
    [FIELDS.SERVICE_OFFERED]: Yup.string().required('This field is required'),
    [FIELDS.TIMING]: Yup.string().required('This field is required'),
    [FIELDS.WORK_MODE]: Yup.string().required('This field is required'),
    [FIELDS.BANNER_COLOUR]: Yup.string().required('This field is required'),
    [FIELDS.VIDEO_LINK]: Yup.string().matches(VIDEO_REG_EX, 'Must be a valid YouTube link.').nullable(),
});

export const initialValues = {
    [FIELDS.PHOTO]: '',
    [FIELDS.NAME]: '',
    [FIELDS.BIO]: '',
    [FIELDS.COMPANY]: '',
    [FIELDS.RATE_RANGE]: '',
    [FIELDS.RESUME]: '',
    [FIELDS.SECTOR]: '',
    [FIELDS.CATEGORY]: '',
    [FIELDS.SERVICE_OFFERED]: '',
    [FIELDS.TIMING]: '',
    [FIELDS.LOCATION]: '',
    [FIELDS.WORK_TYPE]: 'Paid Work',
    [FIELDS.WORK_MODE]: 'On Location',
    [FIELDS.WORK_SEARCH]: '',
    [FIELDS.CURRENCY]: 'GBP',
    [FIELDS.AVATAR_LINK]: '',
    [FIELDS.BANNER_COLOUR]: '',
    [FIELDS.DELETE_PHOTO]: false,
    [FIELDS.DELETE_RESUME]: false
};
