import React from 'react';
import { Button } from 'reactstrap';
import ScoreBreakdown from '../CompanyMatching/ScoreBreakdown';

import style from './style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';

const BreakdownButton = ({ user, offer }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {isOpen ? <ScoreBreakdown user={user} isOpen={isOpen} toggle={toggle} weights={offer.weights} /> : null}
            <ClickButton color="success" className={style.theButton} onClick={toggle}>
                Breakdown
            </ClickButton>
        </>
    );
};

export default BreakdownButton;
