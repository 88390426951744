import React from 'react';
import SaveButton from '../../../buttons/SaveButton';
import { Card, Col, Form, Row } from 'reactstrap';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';
import moment from 'moment';

import style from './style.module.scss';
import NudgeButton from '../../../buttons/NudgeButton';
import CertificatesFormBody from '../../../forms/CertificatesForm/CertificatesFormBody';
import { FIELDS } from './validation';
import NudgeButtonGroup from '../../../buttons/ButtonGroup';

const NewEditableCerts = ({ formik, onClose, certificates, removeCerts, onRemove, onEdit, reset }) => {
    const theme = GetPageTheme();
    const [isOpen, setIsOpen] = React.useState(true);
    const isPlusButtonShowed = !isOpen || formik.values[FIELDS.CERTIFICATIONS].length < 1;

    function onEditCert(el) {
        setIsOpen(true);
        let certs = formik.values[FIELDS.CERTIFICATIONS]
        let add = { id: el.id, name: el.title, expireDate: moment(el.expiryDate).format(`YYYY-MM-DD`), edit: true };
        formik.setFieldValue(FIELDS.CERTIFICATIONS, [...certs, add]);
        onEdit(el);
    }

    const onClick = () => {
        setIsOpen(true);
        formik.setFieldValue(FIELDS.CERTIFICATIONS, [{ id: formik.values[FIELDS.CERTIFICATIONS].length, name: '', file: null, expireDate: '' }]);
    };

    function onReset(el) {
        reset(el);
        formik.setFieldValue(FIELDS.CERTIFICATIONS, formik.values[FIELDS.CERTIFICATIONS].filter((item) => item.id != el.id));
    }

    function parseRemainingDays(item) {

        if(item.daysUntilExpiry <= 0) {
            return "Expired";
        }

        return `${item.daysUntilExpiry} day(s) until expire`
    }

    function getCountOverride() {

        var existing = (certificates?.length - formik.values[FIELDS.CERTIFICATIONS]?.filter((item) => item.edit == true).length);
        var current = formik.values[FIELDS.CERTIFICATIONS].length;
        var remove = removeCerts?.filter((item) => item.edit == false).length;

        return (existing + current) - remove;
    }
    var countOverride = getCountOverride();



    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    { certificates?.length ? <>
                        <Row>
                            { certificates.map((item, index) => <Col xs={12} sm={12} md={12} lg={12}>
                                <Card id={`card-sub-${theme}`} className={style.removableCertificateCard}>
                                    <Row>
                                        <Col md={6}>
                                            <h5>{item.title}</h5>
                                        </Col>
                                        <Col>
                                            { item.daysUntilExpiry < 3 && item.expiryDate !== "" ? <h5 className={theme == 'dark' ? style.expireDark : style.expire}>{parseRemainingDays(item)}</h5> : ``}
                                        </Col>
                                    </Row>
                                </Card>
                                <Row>
                                <Col md={12}>
                                    <div className={style.buttonGroupContainer}>
                                        <NudgeButtonGroup
                                            buttonStyles={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            }}
                                            buttons={[
                                                {
                                                    label: 'Deleting...',
                                                    color: 'danger',
                                                    disabled: true,
                                                    available: !!removeCerts.find((rCert) => rCert.id == item.id ) && !formik.values[FIELDS.CERTIFICATIONS].find((c) => c.id == item.id)?.edit
                                                },
                                                {
                                                    label: 'Editing...',
                                                    color: 'success',
                                                    disabled: true,
                                                    available: !!removeCerts.find((rCert) => rCert.id == item.id ) && !!formik.values[FIELDS.CERTIFICATIONS].find((c) => c.id == item.id)?.edit
                                                },
                                                { 
                                                    label: 'Edit', 
                                                    onClick: () => onEditCert(item), 
                                                    available: countOverride < 8 && !removeCerts.find((rCert) => rCert.id == item.id ) 
                                                },
                                                { 
                                                    label: 'Remove', 
                                                    color: 'danger', 
                                                    onClick: () => onRemove(item), 
                                                    available: !formik.values[FIELDS.CERTIFICATIONS].find((c) => c.id == item.id)?.edit && !removeCerts.find((rCert) => rCert.id == item.id )
                                                },
                                                { 
                                                    label: 'Cancel', 
                                                    color: 'danger', 
                                                    onClick: () => onReset(item), 
                                                    available: !!removeCerts.find((rCert) => rCert.id == item.id ), 
                                                    disabled: countOverride == 8
                                                },
                                            ]}
                                        />
                                    </div>
                                </Col>
                                </Row>
                            </Col>)}
                        </Row>
                    </> : null }
                </Col>
                <Col md={certificates?.length ? 7 : 12} lg={certificates?.length ? 7 : 12}>
                    {isPlusButtonShowed ? (
                        <Row>
                            <Col sm={6} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <h5 style={{ fontSize: '1.3rem' }}>Limit: {countOverride}/8</h5>
                            </Col>
                            <Col sm={6} md={6}>
                                <NudgeButton style={{ width: '100%' }} color="success" onClick={onClick}>
                                    +
                                </NudgeButton>
                            </Col>
                        </Row>
                    ) : null}
                    <CertificatesFormBody formik={formik} reset={onReset} countOverride={countOverride}/>
                    <br/>
                </Col>
            </Row>

            <br/>
            <SaveButton  onClose={onClose}/>
        </Form>
    );
}

export default NewEditableCerts;