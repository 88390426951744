import * as Yup from 'yup';

export const FIELDS = {
    FEEDBACK: 'feedback',
};

export const validationSchema = Yup.object({
    [FIELDS.FEEDBACK]: Yup.string().required('This field is required.'),
});

export const initialValues = {
    [FIELDS.FEEDBACK]: '',
};
