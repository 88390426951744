import { Field, FieldArray } from 'formik';
import React from 'react';
import { Col } from 'reactstrap';

import AutocompleteInput from '../../AutocompleteInput';
import UploadFileFieldInline from '../../UploadFileFieldInline';
import ArrayFieldWithPlusAndMinus from '../ArrayFieldWithPlusAndMinus';

import style from './style.module.scss';
import NewArrayFieldForm from '../NewArrayFieldForm';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';

const getErrorForField = (formik, index, field, basicName) =>
    formik.errors[basicName] && formik.errors[basicName][index] && formik.errors[basicName][index][field];

const ErrorMessage = ({ formik, index, field, basicName }) => (
    <div className="custom-input-error">{getErrorForField(formik, index, field, basicName)}</div>
);

const ArrayField = ({ index, arrayHelpers, formik, alwaysShowMinus, basicName }) => {
    const errors = (
        <>
            <Col md={{ size: 6 }}>
                <ErrorMessage formik={formik} index={index} field="name" />
            </Col>
            <Col md={{ size: 5 }}>
                <ErrorMessage formik={formik} index={index} field="file" />
            </Col>
        </>
    );

    const theme = GetPageTheme();

    var nameError = '';
    var fileError = '';
    var nameValue = '';
    var fileValue = '';

    if (formik.errors.files) {
        try {
            nameError = formik.errors[basicName][index].name;
            fileError = formik.errors[basicName][index].file;
        } catch (e) {}
    }

    if (formik.values.files) {
        try {
            nameValue = formik.values[basicName][index].name;
            fileValue = formik.values[basicName][index].file;
        } catch (e) {}
    }

    const body = (
        <>
            <Col sm={6} md={{ size: 6 }} style={{ marginBottom: '12px' }}>
                <Field
                    name={`${basicName}.${index}.name`}
                    component={AutocompleteInput}
                    suggestions={[]}
                    placeholder="Attached file name"
                    classNames={formik.submitCount > 0 && !nameValue ? 'is-invalid' : ''}
                />
                {formik.submitCount > 0 && !nameValue && nameError ? <p className={theme == 'light' ? style.error : style.errorDark}>{nameError}</p> : null}
            </Col>

            <Col sm={6} md={{ size: 6 }}>
                <Field component={UploadFileFieldInline} name={`${basicName}.${index}.file`} />
                {formik.submitCount > 0 && !fileValue && fileError ? <p className={theme == 'light' ? style.error : style.errorDark}>{fileError}</p> : null}
            </Col>
        </>
    );

    const addValue = { name: '', file: null }

    return (
        <NewArrayFieldForm formik={formik} index={index} field={basicName} addValue={addValue} fieldLimit={8}>
            {body}
        </NewArrayFieldForm>
    );
};

const AttachedFormBody = ({ formik, alwaysShowMinus, basicName }) => (
    <>
        <FieldArray name={basicName}>
            {(arrayHelpers) => (
                <>
                    {formik.values[basicName].map((_, index) => (
                        <ArrayField
                            key={index}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            formik={formik}
                            alwaysShowMinus={alwaysShowMinus}
                            basicName={basicName}
                        />
                    ))}
                </>
            )}
        </FieldArray>
        <br />
    </>
);
export default AttachedFormBody;
