import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from './genericStyle.module.scss';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../NudgeModal';

const GenericModal = ({ isOpen, toggle, header, body, className = '' }) => {

    const { theme } = React.useContext(ThemeContext);

    return (
        <NudgeModal headerText={""} id={`modal-${GetPageTheme()}`} isOpen={!!isOpen} toggle={toggle} className={`${className} ${style.modal}`} centered headerText={header}>
            <ModalBody id={`modal-${theme}`} className={style.modalBody}>
                <h5>{body}</h5>
            </ModalBody>
        </NudgeModal>
    )
};

GenericModal.propTypes = {
    isOpen: PropTypes.any.isRequired,
    toggle: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
};

export default GenericModal;
