import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ModalHeader, ModalBody, Col, Row, ModalFooter } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import { authPostData } from '../../../../api/tools';

import { GetPageTheme, sort_by_key } from '../../../pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import { URLS } from '../../../../consts/urls';
import NudgeModal from '../../../NudgeModal';
import NudgeButton from '../../../buttons/NudgeButton';
import Loader from '../../../Loader';

import Notification from './Notification';
import NudgeButtonGroup from '../../../buttons/ButtonGroup';
import useMobile from '../../../../hooks/useMobile';

export function countNew(notifications) {
    let count = 0;

    if (notifications) {
        notifications.forEach((item) => {
            if (!item.hasRead) count++;
        });
    }

    return count;
}

export const NotificationsModal = ({ url, isOpen, toggle, notifications, mutate, expiredCertificates, expiringJobs, userMutating }) => {
    const navigate = useNavigate();
    const [key, setKey] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(0);

    const theme = GetPageTheme();
    const { isMobileSlim, isMobileWide } = useMobile();

    if(notifications?.length) {
        notifications = sort_by_key(notifications, 'notificationId').reverse();
        notifications = sort_by_key(notifications, 'hasRead');
    }

    let newNotificationCount = countNew(notifications);

    async function setRead() {
        setSending(true);
        await authPostData({}, `${url}/Notifications/MarkRead/All`);
        await mutate();
        setSending(false);
    }

    async function deleteOne(id) {
        setSending(true);

        setDeleteId(id);

        await authPostData({}, `${url}/Notifications/Delete/${id}`);
        await mutate();
        setSending(false);
    }

    async function deleteAll() {
        setSending(true);
        await authPostData({}, `${url}/Notifications/Delete/All`);
        await mutate();
        setSending(false);
    }

    async function historyFunction(link, id, index) {
        setSending(true);

        const localRead = notifications[index].hasRead;
        notifications = sort_by_key(notifications, 'hasRead');
        setKey(!key);
        
        if(link) {
            navigate(link);
            toggle();
        }

        if (!localRead) {
            await authPostData({}, `${url}/Notifications/MarkRead/${id}`);
            await mutate();
        }

        setSending(false);
    }

    return (<>
        <NudgeModal id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered headerText={"Account Alerts"}>
            <div id={`card-${theme}`} className={style.actionContainer}>
                <NudgeButtonGroup
                    buttons={[
                        { onClick: setRead, available: !(sending || !newNotificationCount || !!userMutating), color: 'success', label: !!notifications.length ? newNotificationCount ? 'Mark all new as read' : 'All notifications read' : 'No notifications remaining' },
                        { onClick: deleteAll, available: !(!notifications.length || sending || !!userMutating), color: 'danger', label: !!notifications.length ? 'Delete all notifications' : 'No notifications remaining' },
                    ]}
                    buttonsHeight={`100%`}
                />
            </div>

            <ModalBody className={style.modalBody}>
                <div id={theme == 'light' ? `card-light` : `card-sub-dark`} className={style.notificationContainer}>
                    { !!expiredCertificates || !!expiringJobs?.length && <h5 className={style.sectionHeader}>Account Alert</h5> }    
                    { !!expiredCertificates &&
                        <Row
                            id={theme == 'light' ? `card-sub-light` : `card-dark`}
                            className={style.notification}
                        >
                            <Col xs={12} sm={4} md={4} lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <b>Action Required</b>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                                {`${expiredCertificates} of your certificates/qualifications ${
                                    expiredCertificates > 1 ? 'are' : 'is'
                                } in need of renewal.`}
                            </Col>
                            <Col xs={12} sm={2} md={2} lg={1}>
                                { !sending && !userMutating ? <NudgeButtonGroup
                                    buttons={[
                                        { disabled: !!sending || !!userMutating, available: true, color: 'success', onClick: () => { toggle(); navigate(`${URLS.PROFILE}`); }, label: <FontAwesomeIcon icon={['fas', 'share-square']} /> }
                                    ]}
                                    vertical={!isMobileSlim}
                                /> : <div class="w-100 flex-center-align-justify" style={{ height: '100%' }} className={style.redirectBanned}><h5>...</h5></div> }
                            </Col>
                        </Row>
                    }

                    { !!expiringJobs &&
                        expiringJobs.map((item, index) => 
                            <Row
                                id={theme == 'light' ? `card-light` : `card-dark`}
                                className={style.notification}
                            >
                                <Col xs={12} sm={4} md={4} lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                                    <b>Job Expiring Today</b>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    {`A job titled ${item.name} is expiring today.`}
                                </Col>
                                <Col xs={12} sm={2} md={2} lg={1}>
                                    <NudgeButtonGroup
                                        buttons={[
                                            { disabled: !!sending || !!userMutating,  available: true, color: 'success', onClick: () => { toggle(); navigate(`${URLS.COMPANY_CONTRACTS}/${item.id}`); }, label: <FontAwesomeIcon icon={['fas', 'share-square']} /> }
                                        ]}
                                        vertical={!isMobileSlim}
                                    />
                                </Col>
                            </Row>
                        )  
                    }

                    { !!notifications?.length && <>
                        { !!newNotificationCount && <h5 className={style.sectionHeader}>{`${newNotificationCount ? newNotificationCount + ' New n' : 'N'}otification${newNotificationCount === 1 ? '' : 's'}`}</h5>}
                        { notifications.filter((item, index) => item.notificationId != deleteId && !item.hasRead).map((item, index) =>
                            <Notification index={index} notification={item} sending={sending || userMutating} onClick={historyFunction} onDelete={deleteOne} />
                        )}

                        { !!notifications?.filter((item, index) => item.notificationId != deleteId && item.hasRead).length && <h5 className={style.sectionHeader}>Past Notifications</h5> }
                        { notifications.filter((item, index) => item.notificationId != deleteId && item.hasRead).map((item, index) =>
                            <Notification index={index} notification={item} sending={sending || userMutating} onClick={historyFunction} onDelete={deleteOne} />
                        )}
                    </>}

                </div>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </NudgeModal>
    </>);
};

export default NotificationsModal;
