import PropTypes from 'prop-types';
import React from 'react';

import { getFormikError } from '../../tools/formikHelpers';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';

const CustomFormikError = ({ form, name }) => {
    const theme = GetPageTheme();

    return (
        form.errors[name] && form.submitCount > 0 ?<h5 className={theme == 'light' ? style.error : style.errorDark} data-testid={`error-${name}`}>
             {getFormikError(form, name)}
        </h5> : null
    )
};

CustomFormikError.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
};

export default CustomFormikError;
