import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import moment from 'moment';

import { patchData } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import { validationSchema } from '../../../../components/forms/AvailabilityForm/validation';
import { formatDateForCalendar } from '../../../../tools/dateHelpers';
import Loader from '../../../Loader';
import EditableCalendar from './EditableCalendar';
import NonEditableCalendar from './NonEditableCalendar';

import { updateCasebase } from '../../../ContractorInformation';
import style from './style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';
import { ToastContext } from '../../../../contexts/toast/ToastContext';
import NudgeTooltip from '../../../NudgeTooltip';

const Calendar = ({ user, startDate, endDate, hours, showEdit, refresh, url, changeKey, canCancel = true, theme, reset }) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { createToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const savedValues = {
        startDate: formatDateForCalendar(startDate),
        finishDate: formatDateForCalendar(endDate),
        hours: hours,
    };

    const props = {
        initialValues: savedValues,
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            try {
                await updateProfileAPI(values);
                await updateCasebase(user);
            } catch(e) {
                console.log(e);
                createToast(e.message, "error")
            } finally {
                if (reset) reset();
                await refresh();

                setIsSending(false);
                setIsOpen(false);
            }
        },
    };

    const updateProfileAPI = async (values) => {
        const patchRequest = {};

        try {
            const split = values.startDate.split('/');

            values.startDate = `${split[2]}-${split[1]}-${split[0]}`;

            values.startDate !== savedValues.startDate &&
                (patchRequest.AvailableFrom = moment(values.startDate).local().format('yyyy-MM-DDTHH:mm:ss'));

            values.finishDate !== savedValues.finishDate &&
                (patchRequest.AvailableTo = moment(values.startDate).local().format('yyyy-MM-DDTHH:mm:ss'));

            values.hours !== savedValues.hours &&
                (patchRequest.AvailableHours = (!isNaN(values.hours) && parseInt(values.hours)) || 0);

            if(Object.keys(patchRequest).length > 0) {
                await patchData(patchRequest, url, {})
            }
        } catch(e) {
            createToast(e.message, "error")
        } finally {
            return true;
        }
    };
    
    return (
        <>
            <div className={style.profileHeader}>
            <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`availability-header`} label={<b>{"Availability"}</b>}>
                        <div class="text-center">
                            Tell us about your earliest start date and available hours
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={onClickEdit}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <Row>
                <Col>
                    <div className="text-center small-calendar">
                        {isOpen ? (
                            !sending ? (
                                <Formik {...props}>
                                    {(formik) => <EditableCalendar formik={formik} onClose={canCancel ? toggle : null} />}
                                </Formik>
                            ) : (
                                <Loader />
                            )
                        ) : (
                            <NonEditableCalendar
                                startDate={startDate}
                                endDate={endDate}
                                hours={hours}
                                onClickEdit={onClickEdit}
                                showEdit={showEdit}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};

Calendar.propTypes = {
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    showEdit: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    hours: PropTypes.number,
};

export default Calendar;
