import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';

import { authFetcher, authPostData, authPostDataWithNoResponse } from '../../../../api/tools';
import { useModal } from '../../../../components/helper';
import TextFieldModal from '../../../../components/modals/TextFieldModal';
import { FIELDS } from '../../../../components/modals/TextFieldModal/TextFieldModalForm/validation';
import { API_DASHBOARD_COMPANY, URLS } from '../../../../consts/urls';
import ClickButton from '../../../../components/buttons/NudgeButton';
import { ToastContext } from '../../../../contexts/toast/ToastContext';
import { generateFeedbackReport } from '../../../../components/buttons/RejectButton/helper';

const AcceptButton = ({ offerId, extraData }) => {
    const navigate = useNavigate();
    const { toggle, isOpen } = useModal();

    const [sending, setSending] = React.useState(false);
    const { createToast } = React.useContext(ToastContext);

    const { id } = useParams();

    const onSubmit = async (values) => {
        setSending(true);

        const data = { feedback: values[FIELDS.REASON] };

        try {
            if(!!values.generateReport) {
                const response = await authFetcher(`${API_DASHBOARD_COMPANY}/GenerateCloodReport/${id}`);
                const mergedReport = await generateFeedbackReport(extraData, values[FIELDS.REASON], response.output, true);
    
                if(response && mergedReport) {
                    const saveResponse = await authPostData({
                        matchId: parseInt(id),
                        base64: mergedReport.split(',')[1]
                    }, `${API_DASHBOARD_COMPANY}/SaveCloodReport`);
                } else {
                    createToast("Something went wrong.", "error");
                }
            }

            await authPostDataWithNoResponse(data, `${API_DASHBOARD_COMPANY}/Matches/${id}/Accept`);
            navigate(`${URLS.COMPANY_CONTRACTS}/${offerId}`);
        } catch(e) {
            createToast(e.message, "error");
        } finally {
            setSending(false);
            toggle();
            
        }
    };

    return (
        <>
            <TextFieldModal
                toggle={toggle}
                isOpen={isOpen}
                onGoBack={toggle}
                onSubmit={onSubmit}
                sending={sending}
                header="Are you sure you want to accept?"
                extraData={extraData}
            />

            <ClickButton
                color={'success'}
                disabled={sending}
                onClick={toggle}
                size="lg"
                className="  w-100"
            >
                Accept Candidate
            </ClickButton>
        </>
    );
};

export default AcceptButton;
