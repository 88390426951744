export const MATCH_STATUS = {
    NO_MATCH: 'NoMatch',
    PENDING: 'Pending',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    INVITED: 'ContractorInvited',
    SCHEDULED: 'InterviewScheduled',
    COMPANYPENDING: 'CompanyPending',
    COMPANYACCEPTED: 'CompanyAccepted',
    COMPANYREJECTED: 'CompanyRejected',
    ADMINREJECTED: 'AdminRejected',
    NEGOTIATION: 'Negotiation',
    ADMINAPPROVAL: 'AdminApproval',
};
