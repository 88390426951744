import React from 'react';

import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import style from './style.module.scss';
import { CERTIFICATE_EXPIRY_THRESHOLD, GetPageTheme, sort_by_key } from '../../GenericCompanyProfile/HelperComponents';
import { Col, Row } from 'reactstrap';

const NonEditableCertificatesAndQualifications = ({ certificates, theme }) => {
    const { userRoles } = React.useContext(AuthContext);
    certificates = sort_by_key(certificates, 'daysUntilExpiry');

    function getExpiryDate(date, expire) {
        let formattedDate = date;
        let styleName = '';

        if (expire > CERTIFICATE_EXPIRY_THRESHOLD) {
            formattedDate = `Expires ${moment(date).format(`Do MMMM, YYYY`)}`;
            styleName = style.entrySubtitle;
        } 
        if (expire > 0 && expire <= CERTIFICATE_EXPIRY_THRESHOLD) {
            formattedDate = `Expires in ${expire} day(s).`;
            styleName = style.entrySubtitleExpired;
        } 
        if(expire < 0) {
            formattedDate = `Expired ${moment(date).format(`Do MMMM, YYYY`)}`;
            styleName = style.entrySubtitleExpired;
        }
        if(expire == 0) formattedDate =  `Expired Today`;


        return <div className={styleName}>{formattedDate}</div>;
    }



    return (
        <>
            <div className={style.textsWrapper}>
                {certificates.map((el, i) => (
                    <div id={`card-sub-${theme}`} className={`${style.entry}`} key={i}>
                        <Row style={{ rowGap: '6px' }}>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <div className={style.entryName}>{el.title}</div>
                                {el.expiryDate ? el.daysUntilExpiry < 7 ? <div id={`error-${GetPageTheme()}`}>{getExpiryDate(el.expiryDate, el.daysUntilExpiry)}</div> : <div>{getExpiryDate(el.expiryDate, el.daysUntilExpiry)}</div> : null }
                                <div className={style.entryDuration}>{el.duration}</div>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} className={style.buttonCol}>
                                {el.url ? (
                                    <a href={el.url} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon
                                            className={style.viewCertificate}
                                            icon={['fas', 'file-certificate']}
                                            title="View file"
                                        />
                                    </a>
                                ) : null}
                                {userRoles.includes('User') && el.expiryDate ? (
                                    <a
                                        href={
                                            'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
                                            `${el.title} - Expire Date` +
                                            '&dates=' +
                                            moment(el.expiryDate).format('YYYYMMDDTHHmmss') +
                                            'Z/' +
                                            moment(el.expiryDate).add(1, 'h').format('YYYYMMDDTHHmmss') +
                                            'Z'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={['far', 'calendar-plus']}
                                            className={style.addCalendar}
                                            title="Add to calendar"
                                        />
                                    </a>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </>
    );
};

export default NonEditableCertificatesAndQualifications;
