import * as Yup from 'yup';

export const FIELDS = {
    SUBJECT: 'subjectLine',
    HEADER: 'emailHeader',
    EMAIL: 'emailAddress',
    MESSAGE: 'emailMessage',
    BODY: 'textBody',
    SUBTITLE: 'subtitle',
    LINK: 'buttonLink',
};

export const validationSchema = {
    [FIELDS.SUBJECT]: Yup.string().required('This field is required.'),
    [FIELDS.HEADER]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().required('This field is required.'),
    [FIELDS.MESSAGE]: Yup.string().required('This field is required.'),
    [FIELDS.BODY]: Yup.string().required('This field is required.'),
    [FIELDS.SUBTITLE]: Yup.string().required('This field is required.'),
    [FIELDS.LINK]: Yup.string().required('This field is required.'),
};

export const formLabels = {
    [FIELDS.SUBJECT]: 'Subject Line',
    [FIELDS.HEADER]: 'Email Header',
    [FIELDS.EMAIL]: 'Email Address',
    [FIELDS.MESSAGE]: 'Message',
    [FIELDS.BODY]: 'Body',
    [FIELDS.SUBTITLE]: 'Subtitle',
    [FIELDS.LINK]: 'Button Link',
};

export const initialValues = {
    [FIELDS.SUBJECT]: '',
    [FIELDS.HEADER]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.MESSAGE]: '',
    [FIELDS.BODY]: '',
    [FIELDS.SUBTITLE]: '',
    [FIELDS.LINK]: '',
};
