import React from 'react';
import { Card, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import pattern from '../../assets/videos/PATTERN_HD.mp4';
import nudge_logo from '../../assets/images/nudge-n-forest.png';
import nudge_logo_white from '../../assets/images/nudge-n-chalk.png';

import { URLS } from '../../consts/urls';
import style from './style.module.scss';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';

export const ForgotPasswordPageWrapper = ({ children }) => {
    const theme = GetPageTheme();
    return (
        <>
        <div className={style.box}>
            <Helmet>
                <title>Forgot Password - Nudge Energy Talent Lab</title>
            </Helmet>
            <div className={style.half1}>
                <h5 className={style.videoSubtitle}>
                    <b>Find Your Why</b>
                    <br />
                    <i style={{ fontSize: '24px' }}>
                        Nudge theory: To help people without forcing them. From that small nudge comes a whole new life
                        and a whole new energy industry all from a single nudge.
                    </i>
                </h5>
                <video className={style.video} autoPlay muted loop id="myVideo">
                    <source id="myVideo" src={pattern} type="video/mp4" />
                </video>
            </div>
            <div className={style.half2}>
                <div className={theme == 'light' ? style.logoContainer : style.logoContainerDark}>
                    <Link to={URLS.HOME}>
                        <img src={nudge_logo_white} className={style.nudgeLogo} alt="nudge-white" />
                    </Link>
                    <h5 className={style.logoLabel}>Password Reset</h5>
                </div>
                <Card id={`card-${theme}`} className={theme == 'light' ? style.signUpCard : style.signUpCardDark}>
                    <CardTitle id={`card-sub-${theme}`}>
                        <h4 className={style.signUpTitle}>Reset your Password</h4>
                    </CardTitle>

                    {children}
                </Card>
            </div>
        </div>
    </>
    )
};

export default ForgotPasswordPageWrapper;
