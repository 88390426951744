import React from 'react';

import AcceptButton from './AcceptButton';
import PayButton from './PayButton';
import RejectButton from './RejectButton';

export const renderButtons =
    (contractId, mutate) =>
    ({ cell }) => {
        const { showAcceptReject, showPay, id } = cell.row.original;

        const props = { contractId, id, refresh: mutate };

        return (
            <div>
                {showAcceptReject ? (
                    <>
                        <AcceptButton {...props} />
                        <RejectButton {...props} />
                    </>
                ) : null}
                {showPay ? <PayButton {...props} /> : null}
                {!(showAcceptReject || showPay) ? <div>No action </div> : null}
            </div>
        );
    };
