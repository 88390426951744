import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useModal } from '../../helper';
import TextFieldModal from '../../modals/TextFieldModal';
import ClickButton from '../NudgeButton';
import { ToastContext } from '../../../contexts/toast/ToastContext';
import { generateFeedbackReport } from './helper';
import { API_DASHBOARD_COMPANY, API_DASHBOARD_CONTRACTOR, URLS } from '../../../consts/urls';
import { authPostDataWithNoResponse, authPostData, authFetcher } from '../../../api/tools';



const RejectButton = ({ offerId, company, extraData, canAccept }) => {
    const navigate = useNavigate();
    const { toggle, isOpen } = useModal();
    const [sending, setSending] = React.useState(false);
    const { id } = useParams();

    const { createToast } = React.useContext(ToastContext);

    const onSubmit = async (values) => {
        setSending(true);

        try {
            if(!!values.generateReport) {
                const response = await authFetcher(`${API_DASHBOARD_COMPANY}/GenerateCloodReport/${id}`);
                const mergedReport = await generateFeedbackReport(extraData, values.reason, response?.output, false);
    
                if(response && mergedReport) {
                    const saveResponse = await authPostData({
                        matchId: parseInt(id),
                        base64: mergedReport.split(',')[1]
                    }, `${API_DASHBOARD_COMPANY}/SaveCloodReport`);
                } else {
                    createToast("Something went wrong.", "error");
                }
            }
    
            const data = { feedback: values['reason'] };
            const api = company ? API_DASHBOARD_COMPANY : API_DASHBOARD_CONTRACTOR;
            await authPostDataWithNoResponse(data, `${api}/Matches/${id}/Reject`);
    
            createToast("Candidate dismissed", "success");
            if(company) navigate(`${URLS.COMPANY_CONTRACTS}/${offerId}`);
        } catch(e) {
            createToast(e.message, "error");
            console.log(e)
        } finally {
            setSending(false);
            toggle();
        }

    };
    return (
        <>
            <TextFieldModal
                toggle={toggle}
                isOpen={isOpen}
                onGoBack={toggle}
                onSubmit={onSubmit}
                sending={sending}
                header="Are you sure you want to dismiss?"
                generateContent={company}
                extraData={extraData}
                dismiss={true}
            />
            
            <ClickButton color={'danger'} disabled={sending} onClick={toggle} size="lg" style={{ width: '100%'}}>
                {company ? 'Dismiss Candidate' : 'Dismiss Job'}
            </ClickButton>
        </>
    );
};

RejectButton.propTypes = {
    company: PropTypes.bool,
};

export default RejectButton;

