import * as Yup from 'yup';

// eslint-disable-next-line max-len
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
    PHONE_NO: 'phone',
    SUBJECT: 'subject',
    MESSAGE: 'message',
};

export const kingsbridgeSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().email('Field must be an email.').required('This field is required.'),
    [FIELDS.PHONE_NO]: Yup.string()
        .matches(phoneRegExp, 'Phone number is invalid.')
        .required('This field is required.'),
    [FIELDS.SUBJECT]: Yup.string().required('This field is required.'),
    [FIELDS.MESSAGE]: Yup.string().required('This field is required.'),
});

export const kingsbridgeValues = {
    [FIELDS.NAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.PHONE_NO]: '',
    [FIELDS.SUBJECT]: '',
    [FIELDS.MESSAGE]: '',
};
