import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
};

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().email('Field must be an email.').required('This field is required.'),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.EMAIL]: '',
};
