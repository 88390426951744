import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { URLS } from '../../../consts/urls';
import SmallHeader from '../../../components/SmallHeader';
import ClickButton from '../../../components/buttons/NudgeButton';

export const RE_LIMIT = 3;

const NoMeetingActiveCard = ({ date, matchStatus, matchId, re_count, hasInterview }) => {
    const navigate = useNavigate();

    const reschedule = () => {
        navigate(`${URLS.RESCHEDULE_CALENDAR}/${matchId}`);
    };

    const meetingDate = moment.utc(moment.utc(date, 'DD/MM/yyyy HH:mm')).add(1, 'hours');
    const currentDate = moment(new Date().toUTCString()).local();
    const isLate = meetingDate.isBefore(currentDate);

    return (
        <Card>
            {!hasInterview ? (
                <CardBody>
                    <SmallHeader>
                        Use the panel to the right to negotiate salary. After this, await the company's response.
                    </SmallHeader>
                </CardBody>
            ) : (
                <CardBody>
                    <SmallHeader>
                        <b>
                            Lack of video preview during the one-way interview is a known issue that we are working hard
                            to fix. Recordings are still being taken and submitted.
                        </b>
                    </SmallHeader>
                    {matchStatus === 'InterviewScheduled' ? (
                        <div>
                            {isLate ? (
                                <SmallHeader>
                                    {re_count < RE_LIMIT ? (
                                        <div>
                                            Unfortunately the scheduled window for recording has passed, please click
                                            the button below to reschedule or press the reject offer button at the
                                            bottom of the page to close this application. You can only reschedule{' '}
                                            {RE_LIMIT - re_count} more time(s).
                                            <br />
                                            <ClickButton
                                                onClick={() => reschedule()}
                                                color="success"
                                                style={{ marginTop: '24px' }}
                                            >
                                                Reschedule Meeting
                                            </ClickButton>
                                        </div>
                                    ) : (
                                        <div>
                                            You have reached the rescheduling limit of {RE_LIMIT}. This interview cannot
                                            be rescheduled further. Please contact admin at{' '}
                                            <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a>for
                                            futher assistance.
                                        </div>
                                    )}
                                </SmallHeader>
                            ) : (
                                <SmallHeader>
                                    <div>
                                        You have scheduled a video interview meeting at{' '}
                                        <strong>
                                            {moment.utc(date, 'DD/MM/yyyy h:mm A').local().format('DD/MM/yyyy h:mm A')}
                                        </strong>
                                        .
                                    </div>
                                    <br />

                                    <div>
                                        When you open this page during that time, you will see couple of interview
                                        questions we would like you to answer.
                                    </div>
                                    <br />

                                    <div>This meeting shouldn't be longer than 1 hour.</div>
                                    <br />

                                    {re_count < RE_LIMIT ? (
                                        <div>
                                            If something else has come up, click here to reschedule. You can only
                                            reschedule {RE_LIMIT - re_count} more time(s).
                                            <br />
                                            <ClickButton
                                                onClick={() => reschedule()}
                                                type="button"
                                                color="success"
                                                style={{ marginTop: '12px' }}
                                            >
                                                Reschedule Meeting
                                            </ClickButton>
                                        </div>
                                    ) : (
                                        <div>
                                            You have reached the rescheduling limit of {RE_LIMIT}. This interview cannot
                                            be rescheduled further. Please contact admin at{' '}
                                            <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a> for
                                            futher assistance.
                                        </div>
                                    )}
                                </SmallHeader>
                            )}
                        </div>
                    ) : (
                        <SmallHeader>
                            Your interview recording has been received. We will be in touch with the result in due
                            course. In the meantime, feel free to review your recording below.
                        </SmallHeader>
                    )}
                </CardBody>
            )}
        </Card>
    );
};

NoMeetingActiveCard.propTypes = {
    date: PropTypes.string.isRequired,
};

export default NoMeetingActiveCard;
