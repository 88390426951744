import React from 'react';
import { authFetcher } from '../../../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import Loader from '../../../../components/Loader';
import moment from 'moment';
import { buildTime, getBar, toDaysHoursAndMinutes } from '../TestTimePerStatus/helpers';
import { Card, Col, Row } from 'reactstrap';
import { GetPageTheme, calculateLightness, convertHexToRGB, generateRandomNumber, generateRandomString, sort_by_key, sort_by_key_length, sort_by_nested_keys } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import { jsPDF } from "jspdf";
import style from './style.module.scss';
import NudgeButton from '../../../../components/buttons/NudgeButton';

import NudgeChalk from '../../../../assets/images/nudge-logo-chalk-full.png'
import NudgeForest from '../../../../assets/images/Nudge full logo.png';
import SearchBox from '../../../../components/SearchBox';

export function drawPDFBar(doc, text, hexColour, y_position, total, highest) {
    const { r, g, b } = convertHexToRGB(hexColour);

    var minBar = 80;
    var barWidth = 170;
    var barMultiplier = (minBar + (100 - minBar) * (((total / highest)))) / 100;

    doc.setFillColor("gray");
    doc.rect(12.5, y_position - 5, barWidth, 7.5, "F");

    doc.setFillColor(r, g, b);
    doc.rect(12.5, y_position - 5, barWidth * barMultiplier, 7.5, "F");
    
    doc.text(text, 15, y_position);
}

export function StartNewPage(title, doc, pageHeight, boxHeight, colour, lightness) {
    doc.addPage("a4", "p");
    doc.setTextColor(lightness < 50 ? "white" : "black");

    // Draw Page Header
    doc.setFontSize(16);
    doc.setFillColor(colour.r, colour.g, colour.b);
    doc.rect(0, 0, 225, 20, "F");
    doc.rect(0, pageHeight - boxHeight, 225, boxHeight, "F");
    doc.text(title, 5, 10);

    //reset text colour
    doc.setTextColor("black");
    doc.setFontSize(12);
}

function drawText(doc, text, color, x, y) {
    var ogCol = doc.getTextColor();
    doc.setTextColor(color)
    doc.text(text, x, y);
    doc.setTextColor(ogCol);
}

function exportPDF(stats, user) {
    const doc = new jsPDF();
    const reportTitle = "Time to Hire Report";
    
    var element_counter = 0;
    var y_incrementer = 32.5;
    const boxHeight = 27.5;
    const pageHeight = 300;

    const forest = convertHexToRGB(`#${user?.backColour || ""}`);
    const lightness = calculateLightness(`#${user?.backColour || ""}`);
    
    var img = new Image()
    img.src = lightness > 50 ? NudgeForest : NudgeChalk;
    
    // DRAW PAGE HEADER AND FOOTER
    doc.setFillColor(forest.r, forest.g, forest.b);
    doc.rect(0, 0, 225, 20, "F");
    doc.rect(0, pageHeight - boxHeight, 225, boxHeight, "F");
    doc.addImage(img, 'png', 210 - 40, 5, 35, 9);

    drawText(doc, reportTitle, lightness < 50 ? "white" : "black", 5, 10);

    stats.forEach((item, i) => {
        var totalHeight = (17 + ((9 * item.matches.length)) + 9)
        if(y_incrementer + totalHeight > (pageHeight - 20)) { //IF NEW ELEMENT WOULD EXTEND FURTHER THAN THE BOUNDS OF THE REPORT, START A NEW PAGE AND DRAW A NEW PAGE HEADER AND FOOTER
            doc.setFontSize(16);
            StartNewPage(reportTitle, doc, pageHeight, boxHeight, forest, lightness);
            y_incrementer = 32.5;
        }

        doc.setFillColor(0, 0, 0);
        doc.rect(5, y_incrementer - 7.5, 200, totalHeight - 2.5);

        //DRAW BAR CHART HEADERS
        doc.setFontSize(12); 
        doc.text(`${item.offerTitle} - ${item.offerId}`, 10, y_incrementer); y_incrementer += 6;
        doc.text(`Average Time to Hire: ${item.avTime.text}`, 12.5, y_incrementer); y_incrementer += 8;
        
        doc.setTextColor("white");
        item.matches.map((match, j) => {
            drawPDFBar(doc, `${match.userName} - ${match.times.text}`, j % 2 == 0 ? "#0e6674" : "#17a2b8", y_incrementer, match.times.total, item.highest); 
            y_incrementer += 9;
        }); doc.setTextColor("black");
        
        y_incrementer += 12;
        element_counter++;
    })

    //DRAW BOX AT THE BOTTOM OF THE LAST PAGE
    doc.setFontSize(16); doc.setTextColor("white");
    var txt = "Overall Average Time to Hire";
    doc.text(txt, 200 - doc.getTextWidth(txt), 282.5);

    txt = getTotalTimeToHire(stats).text
    doc.text(txt, 200 - doc.getTextWidth(txt), 290);

    doc.save(`timetohire_report-${user.id}.pdf`);
}

function getTimeToHire(created, accepted) {
    const unix = moment(accepted).unix() - moment(created).unix()

    const { d, h, m, s } = toDaysHoursAndMinutes(unix);

    return { values: { d: d, h: h, m: m, s: s }, text: `${buildTime({d, h, m, s})}`, total: (unix) };
}

export async function getTimeToHireData(setSending, setStats, justTotal) {
    setSending(true);

    var response = await authFetcher(`${API_DASHBOARD_COMPANY}/Statistics`);

    if(response?.matches) {
        var obj = {};
        var arr = [];


        response?.matches.forEach((p, index) => {
            if(!!p.dateAccepted && !!p.offer.availableFrom) {
                if(moment(p.dateAccepted).isAfter(moment(p.offer.availableFrom))) {
                    const _times = getTimeToHire(p.offer.availableFrom, p.dateAccepted);
                    var el = { userName: p.contractor.name, created: p.offer.availableFrom, accepted: p.dateAccepted, times: _times }

                    if(obj[p.offer.id]) {
                        obj[p.offer.id].matches.push(el)
                    } else {
                        obj[p.offer.id] = { offerId: p.offer.id, offerTitle: p.offer.title, matches: [ el ]}
                    }
                }
            }
        })

        Object.keys(obj).forEach((item, index) => {
            var findObj = obj[item].matches;

            var _highest = 0;
            findObj.forEach((match, index) => {
                if(match.times.total > _highest) {
                    _highest = match.times.total;
                }
            })
            
            var d = findObj.reduce(function (x, y) {
                return x + y.times.values.d;
            }, 0) / findObj.length;
            var h = findObj.reduce(function (x, y) {
                return x + y.times.values.h;
            }, 0) / findObj.length;
            var m = findObj.reduce(function (x, y) {
                return x + y.times.values.m;
            }, 0) / findObj.length;
            var s = findObj.reduce(function (x, y) {
                return x + y.times.values.s;
            }, 0) / findObj.length;

            var avTotal = findObj.reduce(function (x, y) {
                return x + y.times.total;
            }, 0) / findObj.length;

            obj[item].highest = _highest;
            obj[item].avTime = { values: { d: parseInt(d), h: parseInt(h), m: parseInt(m), s: parseInt(s) }, text: `${parseInt(d)}d ${parseInt(h)}h ${parseInt(m)}m ${parseInt(s)}s`, total: avTotal }
        
            arr.push(obj[item]);
        })
        
        
        if(justTotal) {
            var totalTimes = getTotalTimeToHire(arr);
            
            totalTimes.averageMatches = response?.averageMatches
            totalTimes.filledOpportunities = response?.filledOpportunities

            return totalTimes;
        }
        
        var sorted;
        sorted = sort_by_nested_keys(arr, ['avTime', 'total'], false);
        setStats(sorted);
    } else {
        if(justTotal) {
            return { text: "Not enough data" }
        }
    }
    setSending(false);

}

function getTotalTimeToHire(statObj) {
    var findObj = statObj;
    
    var d = findObj.reduce(function (x, y) {
        return x + y.avTime.values.d;
    }, 0) / findObj.length;
    var h = findObj.reduce(function (x, y) {
        return x + y.avTime.values.h;
    }, 0) / findObj.length;
    var m = findObj.reduce(function (x, y) {
        return x + y.avTime.values.m;
    }, 0) / findObj.length;
    var s = findObj.reduce(function (x, y) {
        return x + y.avTime.values.s;
    }, 0) / findObj.length;
    
    return { values: {d, h, m, s}, text: buildTime({ d: parseInt(d), h: parseInt(h), m: parseInt(m), s: parseInt(s) })};
}

const TimeToHireDash = ({ user }) => {
    const [stats, setStats] = React.useState();
    const [sending, setSending] = React.useState(true);
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        getTimeToHireData(setSending, setStats);
    }, [])

    const theme = GetPageTheme();
    return  (<>
        { stats && !sending ? <Card id={`card-${theme}`} style={{ padding: '12px', marginBottom: '12px' }}>
            <Row>
                <Col xs={0} md={0} lg={3}/>
                <Col xs={6} md={6} lg={3}>
                    <h4>Overall Average Time to Hire</h4>
                    <h5>{ getTotalTimeToHire(stats).text }</h5>
                </Col>
                <Col xs={6} md={6} lg={3}>
                    <NudgeButton color="success" style={{ height: '100%', width: '100%' }} onClick={() => exportPDF(sort_by_nested_keys(stats, ['avTime', 'total'], true), user)}>Export to PDF</NudgeButton>
                </Col>
                <Col xs={0} md={0} lg={3}/>
            </Row>
        </Card> : null }
        {stats && !sending ? <>
            <SearchBox value={search} setValue={setSearch}/>
            <Row>
                {sort_by_nested_keys(stats, ['avTime', 'total'], true).filter((item) => item.offerTitle.toUpperCase().includes(search.toUpperCase())).map((offer, index) =>
                    <Col xs={12} md={6} lg={4}>
                        <Card id={`card-${theme}`} className={style.card}>
                            <h4>{offer.offerTitle}</h4>
                            <h5>{offer.avTime.text}</h5>

                            <div className={style.timeScroller}>
                                {sort_by_nested_keys(offer.matches, ['times', 'total'], true).map((match, index) => <>
                                    {getBar(offer.highest, match.userName, match.times.text, match.times.total, index, 50)}
                                </>)}
                            </div>
                        </Card>
                    </Col>
                )}
            </Row>
            <br />

        </> : null}
        { sending ? <Loader message="Tabulating tables"/> : null }
        { !stats && !sending ? <Card style={{ padding: '12px' }}>
            <h5>Not enough data found.</h5>
        </Card> : null }
    </>)}

export default TimeToHireDash;