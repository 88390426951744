import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const CalendarForm = ({ formik, id, excludedTimes = [], errorText, intervals, children }) => {
    const [selected, setSelected] = React.useState(formik.values[id]);

    function generate_series(step) {
        const dt = new Date(1970, 0, 1);
        const rc = [];
        while (dt.getDate() === 1) {
            rc.push(moment(dt).format("HH:mm:ss"));
            dt.setMinutes(dt.getMinutes() + step);
        }
        return rc;
    }

    //This react hook is intended to check both date and time
    //if date has been changed, set value to lowest time available
    //if time has been changed, allow the time to be changed without fixing it
    React.useEffect(() => {
        var lastDate = moment(formik.values[id]);
        const excluded = excludedTimes.filter(p => moment(p).format("yyyy-MM-DD") == moment(selected).format("yyyy-MM-DD")).map(p => moment(p).format("HH:mm:ss"))
        var what = generate_series(60).filter(p => !excluded.includes(p));

        var date = moment(selected).format("yyyy-MM-DD");
        var time = what[0].split(" ")[0];

        var newTime = `${date}T${time}`

        if(date != "Invalid date" && time != "Invalid date") {
            if(lastDate.format("yyyy-MM-DD") != moment(selected).format("yyyy-MM-DD")) {
                formik.setFieldValue(id, new Date(newTime))
            } else {
                formik.setFieldValue(id, selected);
            }
        }
    }, [selected])

    return (
        <div className="text-center big-calendar">
            <DatePicker
                id="calendar-pick"
                locale="en"
                showTimeSelect
                timeIntervals={intervals}
                inline
                
                excludeTimes={excludedTimes.filter(p => moment(p).format("yyyy-MM-DD") == moment(selected).format("yyyy-MM-DD"))}

                value={formik.values[id]}
                selected={formik.values[id]}
                onChange={setSelected}
            />
            { errorText ? <h5 style={{ color: 'red', marginTop: '24px' }}>{errorText}</h5> : null }
            <br/>

            { children }
        </div>
    )
}

export default CalendarForm;