import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import UploadPhotoField from '../../../../components/DragAndDrop/UploadPhotoField/UploadPhotoField';
import AppInput from '../../../../components/AppInput';
import CreateResourceButtons from '../../../../components/buttons/CreateResourceButtons';
import { FIELDS } from './validate';
import { DEFAULT_PICTURE } from '../../../../consts/urls';
import UploadModal from '../../../../components/ContractorInformation/ContractorInformationEdit/UploadModal';

import style from '../style.module.scss';
import { parseYouTube } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdminCompaniesAddForm = ({ formik, onBack }) => {
    const handleSubmit = () => {
        formik.handleSubmit();
    };

    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

    const [photo, setPhoto] = React.useState(formik.values[FIELDS.PHOTO]);
    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[FIELDS.PHOTO]));
    }, [formik.values[FIELDS.PHOTO]]);

    function setPhotoField(photo) { 
        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(FIELDS.PHOTO, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    return (
        <>
            
            <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                    className={style.photoUpload}
                    style={{ backgroundImage: `url(${photo})`, objectFit: 'contain' }}
                    onClick={togglePhotoModal}
                >
                    <h5>
                        <FontAwesomeIcon
                            icon={['fas', photo != DEFAULT_PICTURE ? 'edit' : 'plus-circle']}
                        />
                    </h5>
                    <UploadModal
                        isOpen={photoModalOpen}
                        toggle={togglePhotoModal}
                        avatar={``}
                        setPhoto={setPhotoField}
                    />
                </div>
            </FormGroup>

            <FormGroup row className="mt-60">
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.COMPANY_NAME}>Company Name</Label>
                    <AppInput id={FIELDS.COMPANY_NAME} formik={formik} label="Company name" autocomplete="off" />
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.COMPANY_NUMBER}>Company Number</Label>
                    <AppInput id={FIELDS.COMPANY_NUMBER} formik={formik} label="Company number" autocomplete="off" />
                </Col>
            </FormGroup>

            <FormGroup row className="mt-20">
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.EMAIL}>Company Email</Label>
                    <AppInput id={FIELDS.EMAIL} formik={formik} label="Company email" autocomplete="off" />
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.USERNAME}>Company Owner</Label>
                    <AppInput id={FIELDS.USERNAME} formik={formik} label="Company owner" autocomplete="off" />
                </Col>
            </FormGroup>

            <FormGroup row className="mt-20">
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.COMPANY_ADDRESS}>Company Address</Label>
                    <AppInput id={FIELDS.COMPANY_ADDRESS} formik={formik} label="Company address" autocomplete="off" />
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.FEE}>Company Fee (%)</Label>
                    <AppInput id={FIELDS.FEE} formik={formik} label="% Fee" autocomplete="off" />
                </Col>
            </FormGroup>

            <FormGroup>
                <Label for={FIELDS.DESCRIPTION}>Description</Label>
                <AppInput
                    id={FIELDS.DESCRIPTION}
                    type="textarea"
                    formik={formik}
                    label="Company Description"
                    autocomplete="off"
                    className={style.description}
                />
            </FormGroup>

            <FormGroup row className="mt-20">
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.VISION_STATEMENT}>Vision Statement</Label>
                    <AppInput
                        id={FIELDS.VISION_STATEMENT}
                        formik={formik}
                        label="Vision Statement"
                        autocomplete="off"
                    />
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Label for={FIELDS.VIDEO_LINK}>YouTube Video Link</Label>
                    <AppInput id={FIELDS.VIDEO_LINK} formik={formik} label="Video Link" autocomplete="off" />
                </Col>
            </FormGroup>
        </>
    );
};

AdminCompaniesAddForm.propTypes = {
    formik: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default AdminCompaniesAddForm;
