export const INPUT_DATE_FORMAT = 'dd/MM/yyyy';

export const parseDate = (date) => {
    const [dd, mm, yyy] = date.split('/');
    const timestamp = Date.UTC(yyy, mm - 1, dd, 0, 0, 0);
    return timestamp ? new Date(timestamp) : new Date();
};

export const getDate = (value, defaultValue = new Date()) => (value ? parseDate(value) : defaultValue);

export const formatDateForCalendar = (date, separator = '/') => {
    if (date == null) return;

    return date ? `${date.getUTCDate()}${separator}${date.getUTCMonth() + 1}${separator}${date.getUTCFullYear()}` : '';
};

export const formatDateForCalendar_yyy_mm_dd = (date, separator = '-') =>
    date ? date.getUTCFullYear() + separator + (date.getUTCMonth() + 1) + separator + date.getUTCDate() : '';
