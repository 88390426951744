import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'title',
    OWNER_NAME: 'owner',
};

export const validationSchema = Yup.object({});

export const initialValues = {};
