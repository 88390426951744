import PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';

const SmallHeader = ({ children, className = '' }) => <h2 className={`${style.title} ${className}`}>{children}</h2>;

SmallHeader.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

export default SmallHeader;
