import { Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Card, CardTitle } from 'reactstrap';

import { Helmet } from 'react-helmet-async';

import { authPostData, postData } from '../../api/tools';
import Loader from '../../components/Loader';
import { API_GATEWAY, URLS } from '../../consts/urls';
import { initialValues, validationSchema } from '../NewPasswordRequired/NewPasswordRequiredForm/validation';
import NewPasswordRequiredForm from './NewPasswordRequiredForm';

import pattern from '../../assets/videos/PATTERN_HD.mp4';
import nudge_logo from '../../assets/images/nudge-n-forest.png';
import nudge_logo_white from '../../assets/images/nudge-n-chalk.png';

import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';

const NewPasswordRequired = () => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { email } = useParams();

    const onSubmit = async (values) => { 
        values.email = email;
        const result = await postData(values, `${API_GATEWAY}/Profile/ChangePassword`);

        return result;
    };

    const onClickSubmit = async (values) => {
        setIsLoading(true);

        try {
            const result = await onSubmit(values);
            result.success ? navigate(URLS.SIGN_IN) : setMessage(result.message);
        } catch (error) {
            setMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            onClickSubmit(values);
        },
    };

    const theme = GetPageTheme();
    return (
        <>
            <div className={style.box}>
                <Helmet>
                    <title>New Password Required - Nudge Energy Talent Lab</title>
                </Helmet>
                <div className={style.half1}>
                    <h5 className={style.videoSubtitle}>
                        <b>Find Your Why</b>
                        <br />
                        <i style={{ fontSize: '24px' }}>
                            Nudge theory: To help people without forcing them. From that small nudge comes a whole new
                            life and a whole new energy industry all from a single nudge.
                        </i>
                    </h5>
                    <video className={style.video} autoPlay muted loop id="myVideo">
                        <source id="myVideo" src={pattern} type="video/mp4" />
                    </video>
                </div>
                <div className={style.half2}>
                    <div className={theme == 'light' ? style.logoContainer : style.logoContainerDark}>
                        <Link to={URLS.HOME}>
                            <img src={nudge_logo_white} className={style.nudgeLogo} alt="nudge-white" />
                        </Link>
                        <h5 className={style.logoLabel}>Welcome to Nudge!</h5>
                    </div>
                    <Card id={`card-${theme}`} className={theme == 'light' ? style.signUpCard : style.signUpCardDark}>
                        <CardTitle id={`card-sub-${theme}`}>
                            <h4 className={style.signUpTitle}>Reset your Password</h4>
                        </CardTitle>
                        {!isLoading ? (
                            <Formik {...props}>
                                {(formik) => <NewPasswordRequiredForm formik={formik} message={message} />}
                            </Formik>
                        ) : (
                            <Loader />
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default NewPasswordRequired;
