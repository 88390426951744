import React from 'react';
import { Col, Row, Card, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import SearchBox from '../../../../components/SearchBox';
import NudgeBoxes from '../../../../components/NudgeBoxes';

import { URLS } from '../../../../consts/urls';
import LinkButton from '../../../../components/buttons/LinkButton';
import {
    arrToString,
    capitaliseFirstLetter,
    ProcessSalary,
    sort_by_key_length,
} from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import { sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import useMobile from '../../../../hooks/useMobile';
import ClickButton from '../../../../components/buttons/NudgeButton';

const PER_PAGE = 6;

const OfferList = ({ matches, approve, deny, sending }) => {
    const [search, setSearch] = React.useState(``);

    matches = matches.filter((i) => filterOffer(i));

    const total = Math.ceil(matches.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);
    const matchesToDisplay = [...matches].slice(start, end);
    const emptyOffers = [];

    for (var i = 0; i < PER_PAGE - matchesToDisplay.length; i++) {
        emptyOffers.push('');
    }

    function findIn(string, search) {
        return upper(string).includes(upper(search)) ? true : false;
    }

    function upper(string) {
        return string.toUpperCase();
    }

    function filterOffer(match) {
        let comparison = `${match.companyName}${arrToString(match.oSkills)}${match.cSkills}${match.jobTitle}${
            match.contractorName
        }`.replaceAll(',', '');

        if (findIn(comparison, search)) {
            return true;
        }
    }

    const { isMobileWide } = useMobile();
    const buffer = isMobileWide ? 1 : 3;

    function getSkills(array) {
        if (!array?.length) return null;

        let limit = 3;
        let lArray = [];

        array.forEach((skill) => {
            if(skill) {
                lArray.push({ label: skill.name, danger: false});
            } else {
                lArray.push(null);
            }
        });

        return lArray.splice(0, limit);
    }

    React.useEffect(() => {
        setPage(0);
    }, [search]);

    return (
        <>
            <SearchBox className={style.search} setValue={setSearch} value={search} />
            <Row>
                {matchesToDisplay.map((item) => (
                    <Col xs={12} sm={12} md={6} lg={4}>
                        <Card className={style.offerCard}>
                            <Row>
                                <Col xs={6} md={6}>
                                    <h5 className={style.jobName}>
                                        <Link to={`${URLS.ADMIN_CONTRACTS}/${item.offerId}`}>{item.jobTitle}</Link>
                                    </h5>
                                </Col>
                                <Col xs={6} md={6}>
                                    <h5 className={style.jobName}>
                                        <Link to={`${URLS.ADMIN_CONTRACTORS}/${item.contractorId}`}>
                                            {item.contractorName}
                                        </Link>
                                    </h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6} md={6}>
                                    <h5 className={style.attribute}>Offer Skills</h5>
                                    {item.oSkills.length ? <NudgeBoxes
                                        fullWidth
                                        data={getSkills(item.oSkills)}
                                    /> : <p>No offer skills set</p>} 
                                </Col>
                                <Col xs={6} md={6}>
                                    <h5 className={style.attribute}>Contractor Skills</h5>
                                    {item.cSkills.length ? <NudgeBoxes
                                        fullWidth
                                        data={getSkills(item.cSkills)}
                                    /> : <p>No contractor skills set</p>}
                                </Col>
                            </Row>
                            <br />

                            <Row>
                                <Col xs={6} md={6}>
                                    <ClickButton
                                        disabled={sending}
                                        className={style.theButton}
                                        color={'success'}
                                        onClick={() => approve(item.matchId)}
                                    >
                                        Approve
                                    </ClickButton>
                                </Col>
                                <Col xs={6} md={6}>
                                    <ClickButton
                                        disabled={sending}
                                        className={style.theButton}
                                        color={'danger'}
                                        onClick={() => deny(item.matchId)}
                                    >
                                        Deny
                                    </ClickButton>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
                {emptyOffers.map(() => (
                    <Col xs={12} sm={12} md={6} lg={4}>
                        <Card className={style.emptyOffer} />
                    </Col>
                ))}
            </Row>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={buffer}
            />
            <br />
        </>
    );
};

export default OfferList;
