import * as Yup from 'yup';

export const FIELDS = {
    INDUSTRY: 'industry',
    SIZE: 'size',
    HEADQUARTERS: 'headquarters',
    FOUNDED: 'founded',
};

export const FIELD_NAMES = {
    [FIELDS.INDUSTRY]: 'Industry',
    [FIELDS.SIZE]: 'Size (Employees)',
    [FIELDS.HEADQUARTERS]: 'Headquarters (Based In)',
    [FIELDS.FOUNDED]: 'Founded (Year)',
};

export const validationSchema = Yup.object({
    [FIELDS.INDUSTRY]: Yup.string().required('This field is required'),
    [FIELDS.SIZE]: Yup.number().typeError('Please enter a numeric value.').required('This field is required.'),
    [FIELDS.HEADQUARTERS]: Yup.string().required('This field is required'),
    [FIELDS.FOUNDED]: Yup.number()
        .typeError('Please enter a numeric value.')
        .required('This field is required.')
        .min(1800, 'Year cannot be less than 1800.')
        .max(2099, 'Year cannot be greater than 2099.'),
});

export const initialValues = {
    [FIELDS.INDUSTRY]: '',
    [FIELDS.SIZE]: 0,
    [FIELDS.HEADQUARTERS]: '',
    [FIELDS.FOUNDED]: '',
};
