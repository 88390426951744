import React from 'react';
import useSWR from 'swr';
import { NO_REFRESH_INTERVAL, authFetcher } from '../../../../../api/tools';
import { API_DASHBOARD_COMPANY, URLS } from '../../../../../consts/urls';

import style from './style.module.scss';
import { ContainerCard, GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ClickButton from '../../../../buttons/NudgeButton';
import Loader from '../../../../Loader';
import { Col, Row } from 'reactstrap';

const RecruiterList = ({ recruiters, children, referred }) => {
    const { data } = useSWR(`${API_DASHBOARD_COMPANY}/Recruiters/All`, authFetcher, NO_REFRESH_INTERVAL);
    const theme = GetPageTheme();

    const [_, setSelected] = React.useState(``);
    const navigate = useNavigate();

    function toggleRecruiters(id) {
        var r = recruiters.get;
        setSelected(``);
        recruiters.set();

        var index = r.findIndex((p) => p == id);

        if (index == -1) {
            r.push(id);
            setSelected(`${id}`);
        } else {
            r.splice(index, 1);
            setSelected(``);
        }

        recruiters.set(r);
    }

    function findRecruiter(id) {
        var r = recruiters.get;

        var find = r.find((p) => p == id);

        return find;
    }

    function isAllRecruiters() {

        

        if(referred?.length) {
            var found = 0;
            data.forEach((item, index) => {
                if(referred.includes(item.id)) {
                    found++;
                }
            })

            if(found == data.length) {
                return true;
            }
        } else {
            return false;
        }
    }

    return (
        <ContainerCard className={style.recruiterList} header="Recruiters">
            <p>
                The Nudge talent pool might not always have the individual you are looking for. If you need additional
                search support, please notify us and our expert recruiters will undertake an external search.
            </p>
            <p>
                <b>Good luck, Team Nudge.</b>
            </p>
            <div id={`card-sub-${theme}`} className={style.recruiterContainer}>
                {data
                    ? data.map((recruiter, index) => <div id={`card-${theme}`} className={style.recruiterCard}>
                        <Row>
                            <Col lg={8}>
                                <div className={style.flexContainer}>
                                    <h5 className={style.recruiterName}>{recruiter.name}</h5>
                                    {findRecruiter(recruiter.id) ? (
                                        <FontAwesomeIcon icon={['fas', 'check-circle']} className={style.checkIcon} />
                                    ) : null}
                                </div>
                                <h5 className={style.recruiterCompany}>{recruiter.company}</h5>
                            </Col>
                            <Col lg={4}>
                                <div className={style.buttonContainer}>
                                    { findRecruiter(recruiter.id) ? (
                                        <ClickButton style={{ width: '100%' }} color="success" onClick={() => toggleRecruiters(recruiter.id)}>
                                            Deselect
                                        </ClickButton>
                                    ) : (
                                            <ClickButton style={{ width: '100%' }} color="success" onClick={() => toggleRecruiters(recruiter.id)} disabled={referred.includes(recruiter.id)}>
                                                { referred.includes(recruiter.id) ? "Referred Already" : "Select" }
                                            </ClickButton>
                                    )}
                                    <ClickButton style={{ width: '100%' }} color="success" onClick={() => window.open(`${URLS.COMPANY_RECRUITER_PROFILE}/${recruiter.id}`, '_blank', 'noopener noreferrer')}>View Recruiter Profile</ClickButton>
                                </div>
                            </Col>
                        </Row>
                    </div>) : <Loader message="Loading recruiters"/> }
            </div>

            { data ? !isAllRecruiters()  ? <div className={style.buttonContainer}>{children}</div> : null : null }
        </ContainerCard>
    );
};

export default RecruiterList;
