import * as Yup from 'yup';

import { FIELDS as FIELDS_CERTIFICATION } from '../../../forms/CertificatesForm/validation';
import { FIELDS as FIELDS_QUALIFICATION, OTHER_LABEL } from '../../../forms/QualificationsForm/validation';

export const FIELDS = {
    ...FIELDS_CERTIFICATION,
    ...FIELDS_QUALIFICATION,
};

export const validationSchema = Yup.object({
    [FIELDS.OTHERS]: Yup.string().when(FIELDS.QUALIFICATION, {
        is: (label) => label === OTHER_LABEL,
        then: Yup.string().required('This field is required'),
    }),
    [FIELDS.CERTIFICATIONS]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Name field is required'),
                file: Yup.mixed(),
                expireDate: Yup.string(),
            }),
        ),
    }),
});

export const initialValues = {
    [FIELDS.CERTIFICATIONS]: [{ name: '', file: null, expireDate: '' }],
    [FIELDS.QUALIFICATION]: '',
    [FIELDS.OTHERS]: '',
};
