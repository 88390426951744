import React from 'react';
import { Formik } from 'formik';
import { Button, Form, FormGroup } from 'reactstrap';
import { useParams } from 'react-router';
import useSWR from 'swr';

import AppInput from '../../components/AppInput';
import { postData, exFetcher, NO_REFRESH_INTERVAL } from '../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../consts/urls';
import { initialValues, validationSchema, FIELDS } from './validation';
import style from './style.module.scss';
import { Helmet } from 'react-helmet-async';
import ClickButton from '../../components/buttons/NudgeButton';

export const References = () => {
    let { userId, refId } = useParams();
    const { data } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/User/Exists/${userId}/${refId}`,
        exFetcher,
        NO_REFRESH_INTERVAL,
    );
    const [isComplete, setIsComplete] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    var userMessage = '';
    var canPost = false;

    if (data === -2) {
        userMessage = 'Referee message has already been provided.';
    } else if (data === -1) {
        userMessage = 'There was a problem verifying your information.';
    } else if (data == null) {
        userMessage = 'Could not retrieve information.';
    } else {
        canPost = true;
    }

    const sendReference = async (v) => {
        const url = `${API_DASHBOARD_CONTRACTOR}/References/${userId}/${data}`;
        await postData(v, url, {});
        setIsComplete(true);
    };

    const handleReject = (f) => {
        setSending(true);
        try {
            f.values.reference = 'Reference Rejected';
            f.handleSubmit();
        } catch(e) {
            console.log(e.message)
        } finally {
            setSending(false);
        }
    };

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);
            try {
                values.userId = userId;
                values.refId = data;

                if (!values.ref_name) {
                    values.ref_name = 'Anonymous';
                }
                
                sendReference(values);
            } catch(e) {
                console.log(e.message)
            } finally {
                setSending(false);
            }
        },
    };

    return (
        <div>
            <Helmet>
                <title>Reference - Nudge Energy Talent Lab</title>
            </Helmet>
            <h2 className={style.header}>References</h2>
            {isComplete ? <h3 className={style.header}>Thank you for providing a reference for this person.</h3> : null}

            {!isComplete ? (
                !canPost ? (
                    <h3 className={style.header}>{userMessage}</h3>
                ) : (
                    <Formik {...props}>
                        {(formik) => (
                            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                <h5 className={style.header}>
                                    One of our users has placed you as a reference. Please give a short explanation of
                                    your connection with this candidate.
                                </h5>

                                <FormGroup className={style.header}>
                                    <AppInput
                                        className={style.name}
                                        id={FIELDS.NAME}
                                        formik={formik}
                                        label="Your name goes here"
                                    />
                                    <AppInput
                                        className={style.feedback}
                                        id={FIELDS.REFERENCE}
                                        formik={formik}
                                        label="Reference information goes here..."
                                        type="textarea"
                                    />
                                </FormGroup>

                                    <div className={style.buttonContainer}>
                                        <ClickButton style={{ width: '100%' }} color="success" className={style.uploadButton} type="submit" disabled={sending}>
                                            Submit
                                        </ClickButton>
                                        <ClickButton
                                            style={{ width: '100%' }}
                                            color="danger"
                                            className={style.uploadButton}
                                            onClick={() => handleReject(formik)}
                                            disabled={sending}
                                        >
                                            Reject
                                        </ClickButton>
                                    </div>
                            </Form>
                        )}
                    </Formik>
                )
            ) : null}
        </div>
    );
};

export default References;
