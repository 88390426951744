import React from 'react';

import { Formik } from 'formik';

import { Button, Tooltip } from 'reactstrap';
import Status from './Status';

import CloseModal from './CloseModal';

import FeedbackModal from './FeedbackModal';
import SuccessModal from './SuccessModal';

import { validationSchema, initialValues } from './FeedbackModal/validation';

import { API_DASHBOARD_CONTRACTOR } from '../../../../../consts/urls';
import { authPostData } from '../../../../../api/tools';

import style from './style.module.scss';
import ClickButton from '../../../../../components/buttons/NudgeButton';
import RegisterInterestModal from '../../../../../components/OpportunityPages/User/Buttons/RegisterInterestModal';
import NudgeTooltip from '../../../../../components/NudgeTooltip';

const tooltipText = {
    adzuna:
        'This opportunity is through third party Adzuna who we have partnered with to provide relevant energy' +
        ' sector opportunities for our community of users. It will not be managed by Nudge and we hold no responsibility' +
        ' for the outcome of experience. Best of luck to you. Go Nudge Nation!',
    nudge:
        'This opportunity is managed by Nudge. Should this opportunity be of interest please register and we will be in' +
        ' touch in due course. If you know of someone that it maybe suitable for please feel free to refer that person, good luck and thanks!',
};

const sendRefer = async (v) => {
    const url = `${API_DASHBOARD_CONTRACTOR}/Offers/Refer`;
    await authPostData(v, url);
};

const onClickRegister = async (offerId, flipFunction, setSending) => {
    setSending(true);
    var url = `${API_DASHBOARD_CONTRACTOR}/Offers/${offerId}/Interest`;

    try {
        await authPostData({ oId: offerId }, url);
        await flipFunction();
    } catch (e) {
        console.log(e.message);
    } finally {
        setSending(false);
    }
};

const Buttons = ({ source, refresh, offer, matches }) => {
    const [key, setKey] = React.useState(false);
    const flipFunction = async () => {
        setKey(!key);
        await refresh();
        toggleSuccess();
    };

    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleSuccess = () => setModalOpen(!modalOpen);

    const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
    const toggleFeedback = () => setFeedbackModalOpen(!feedbackModalOpen);

    const [adzunaModalOpen, setAdzunaModalOpen] = React.useState(false);
    const toggleAdzuna = () => setAdzunaModalOpen(!adzunaModalOpen);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const hasMatched = !!matches && !!matches.find((item) => item.offer.id === offer.id);

    const [sending, setSending] = React.useState(false);

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            values.offerId = offer.id;
            sendRefer(values);
            setFeedbackModalOpen(!feedbackModalOpen);
        },
    };

    return (
        <>
            <SuccessModal isOpen={modalOpen} toggle={toggleSuccess} flip={flipFunction} />
            <Formik {...props}>
                {(formik) => <FeedbackModal isOpen={feedbackModalOpen} toggle={toggleFeedback} formik={formik} />}
            </Formik>
            <CloseModal isOpen={adzunaModalOpen} toggle={toggleAdzuna} redirectUrl={offer.redirect_url} />


            {source === 'Nudge' ? (
                <div className={style.buttonContainer}>
                    <ClickButton onClick={toggleFeedback} color="success" className={style.leftButton}>
                        <div className={style.buttonText}>Refer Friend</div>
                    </ClickButton>
                    <RegisterInterestModal onClick={() => onClickRegister(offer.id, flipFunction, setSending)} disabled={hasMatched || sending} size="md">
                        <div className={style.buttonText}>
                            {sending ? 'Registering...' : hasMatched ? 'Registered' : 'Register Interest'}
                        </div>
                    </RegisterInterestModal>
                </div>
            ) : (
                <div onClick={toggleAdzuna} className={style.buttonContainer}>
                    <ClickButton color="adzuna">
                        <div className={style.buttonText}> Apply on Adzuna</div>
                    </ClickButton>
                </div>
            )}
            
            <div className={style.statusWrapper}>
                <NudgeTooltip label={`${offer.source} Job`} id={`nudge-${offer.id}`}>
                    <h5 className={style.tooltipText}>{tooltipText[offer.source.toLowerCase()]}</h5>
                </NudgeTooltip>
            </div>
        </>
    );
};

export default Buttons;
