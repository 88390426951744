import React from 'react';
import { Button } from 'reactstrap';

import { authPostDataWithNoResponse } from '../../../../api/tools';
import { useModal } from '../../../../components/helper';
import ActionOrBackModal from '../../../../components/modals/ActionOrBackModal';
import SuccessModal from '../../../../components/modals/SuccessModal';
import { API_GATEWAY } from '../../../../consts/urls';
import ClickButton from '../../../../components/buttons/NudgeButton';

const AcceptButton = ({ contractId, id, refresh }) => {
    const { toggle: successToggle, isOpen: successIsOpen } = useModal();
    const { toggle, isOpen } = useModal();

    const onClick = async () => {
        const url = `${API_GATEWAY}/InvoiceCompany/Contracts/${contractId}/Timesheets/${id}/Accept`;
        await authPostDataWithNoResponse({}, url);
        toggle();
        successToggle();
    };

    const onCloseSuccess = async () => {
        await refresh();
        successToggle();
    };

    return (
        <>
            <ActionOrBackModal
                toggle={toggle}
                isOpen={isOpen}
                onGoBack={toggle}
                onClick={onClick}
                header="Are you sure you want to accept this timesheet?"
                buttonText="Accept Timesheet"
                color="success"
                text=""
                backText="Return to timesheets"
            />
            <SuccessModal
                isOpen={successIsOpen}
                toggle={onCloseSuccess}
                header="Accepted"
                body="The timesheet was accepted."
            />
            <ClickButton onClick={toggle} className="w-100 mt-10" color="success">
                Accept
            </ClickButton>
        </>
    );
};

export default AcceptButton;
