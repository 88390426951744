import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup } from 'reactstrap';

import AppInput from '../../../components/AppInput';
import { URLS } from '../../../consts/urls';
import { forgotUserPassword } from '../../../contexts/auth/tools';
import ForgotPasswordPageWrapper from '../ForgotPasswordPageWrapper';
import style from '../style.module.scss';
import { FIELDS, initialValues, validationSchema } from './validation';
import ClickButton from '../../../components/buttons/NudgeButton';

const ForgotPasswordEmailForm = ({ formik }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} style={{ padding: '12px' }}>
        <FormGroup row>
            <Col md={{ size: 8, offset: 2 }}>
                <AppInput id={FIELDS.EMAIL} formik={formik} label="E-mail" type="email" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col md={{ size: 8, offset: 2 }}>
                <ClickButton color="success" type="submit" className={style.btnSend} size="lg">
                    Send verification email
                </ClickButton>
            </Col>
        </FormGroup>
    </Form>
);

const ForgotPasswordEmailPage = () => {
    const navigate = useNavigate();
    const props = {
        initialValues,
        validationSchema,
        onSubmit: async ({ email }) => {
            await forgotUserPassword(email);
            navigate(`${URLS.FORGOT_PASSWORD_CODE}/${email}`);
        },
    };

    return (
        <ForgotPasswordPageWrapper data-testid="ForgotPasswordPage">
            <Formik {...props}>{(formik) => <ForgotPasswordEmailForm formik={formik} />}</Formik>
        </ForgotPasswordPageWrapper>
    );
};

export default ForgotPasswordEmailPage;
