import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';

import { authFetcher } from '../../../../api/tools';
import Loader from '../../../../components/Loader';
import { FLASK_API } from '../../../../consts/urls';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

const SpaceStationResults = () => {
    const [output, setOutput] = useState(null);

    const resultsUrl = `${FLASK_API}/space-station/get-results`;

    useEffect(() => {
        async function getData() {
            const response = await authFetcher(resultsUrl);

            let temp = response.map((item) => {
                let result = '';
                if (item.type && item.type.S === 'tasks') {
                    result = item.code.S.split('').join(', ');
                } else {
                    result = item.result.BOOL === true ? 'Passed' : 'Failed';
                    result += ` (${item.triesUsed.N} ${item.triesUsed.N === '1' ? 'try' : 'tries'} used)`;
                }
                return {
                    key: item.sessionId.S,
                    timestamp: moment.unix(item.timestamp.N).format('DD/MM/YYYY HH:mm:ss'),
                    typeHuman: item.type && item.type.S === 'tasks' ? 'Task Priority' : 'Code Input',
                    resultHuman: result,
                };
            });

            temp = temp.sort((a, b) => {
                return moment(b.timestamp, 'DD/MM/YYYY HH:mm:ss').unix() - moment(a.timestamp, 'DD/MM/YYYY HH:mm:ss').unix();
            });

            setOutput(temp);
        }

        getData();
    }, [resultsUrl]);

    const theme = GetPageTheme();

    return output ? (
        <>
            <Row>
                <Col xs={12}>
                    <Table id={`card-${theme}`} className={style.results}>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Activity Type</th>
                                <th>Response/Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {output.map((item, index) => (
                                <tr key={item.key}>
                                    <td>{item.timestamp}</td>
                                    <td>{item.typeHuman}</td>
                                    <td>{item.resultHuman}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    ) : (
        <Loader />
    );
};

export default SpaceStationResults;
