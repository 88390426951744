import React, { useState } from 'react';
import { Field } from 'formik';
import AppInput from '../../../components/AppInput';
import CheckboxField from '../../../components/CheckboxField';
import AberdeenImage from '../../../assets/images/aberdeenLogo.jpg';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form, Label, ModalFooter, Row, Col } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeModal from '../../../components/NudgeModal';

const poundSign = String.fromCharCode(163);
const Services = [
    {
        id: 'Bookkeeping',
        label: `Annual accounts, confirmation statements, corporation tax returns. £90 + VAT per month`,
    },
    { id: 'VAT Filing', label: `£10 + VAT per month` },
    { id: 'PAYE', label: `Payroll for director - £10 + VAT per month` },
    {
        id: 'Self Assessment',
        label: `For director to cover remuneration from company - £10 + VAT per month`,
    },
];

const tallyServices = (values) => {
    var total = 95;
    values.VAT_Filing = values['VAT Filing'];
    values.Self_Assess = values['Self Assessment'];

    if (values.bookkeeping !== '') {
        total += 90;
    }
    if (values.PAYE !== '') {
        total += 10;
    }
    if (values['VAT Filing'] !== '') {
        total += 10;
    }
    if (values['Self Assessment'] !== '') {
        total += 10;
    }

    return total;
};

const AberdeenFormModal = ({ isOpen, toggle, formik }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    formik.values.tally = tallyServices;

    const passSuccess = () => {
        formik.values.successMethod = toggleSuccess;
    };
    const toggleSuccess = () => {
        setIsSuccess(!isSuccess);
    };

    const imageComponent = <span className={style.modalHeaderText}>
        <img src={AberdeenImage} alt="form"></img>
    </span>

    return ( <Form onSubmit={formik.handleSubmit}>
        <NudgeModal headerText={imageComponent} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle.aberdeen} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                {!isSuccess ? (<>
                    
                        <div className={style.text}>Enter details of your request and press submit.</div>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.NAME}
                                id={FIELDS.NAME}
                                formik={formik}
                                component={AppInput}
                                label="Name"
                            />
                        </FormGroup>
                        <br />

                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.PHONE_NO}
                                id={FIELDS.PHONE_NO}
                                formik={formik}
                                component={AppInput}
                                label="Phone Number"
                            />
                        </FormGroup>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.EMAIL}
                                id={FIELDS.EMAIL}
                                formik={formik}
                                component={AppInput}
                                label="Email Address"
                            />
                        </FormGroup>
                        <br />

                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.SUBJECT}
                                id={FIELDS.SUBJECT}
                                formik={formik}
                                component={AppInput}
                                label="Subject Line"
                            />
                        </FormGroup>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.MESSAGE}
                                id={FIELDS.MESSAGE}
                                formik={formik}
                                component={AppInput}
                                type="textarea"
                                label="Message"
                            />
                        </FormGroup>
                        <br />

                        <Label for={FIELDS.SERVICES}>
                            <h4>Services Interested In</h4>
                        </Label>

                        <FormGroup style={{ textAlign: 'left', paddingLeft: '18px', paddingRight: '18px' }}>
                            {Services.map((label, index) => (
                                <Row>
                                    <Col md={12}>
                                        <Field name={label.id} component={CheckboxField} formik={formik} label={label.id} />
                                        <p>{label.label}</p>
                                    </Col>
                                </Row>
                            ))}
                        </FormGroup>
                    </>) : (
                    <div>Message was sent. Thank you for using this service. Click anywhere to exit.</div>
                )}
            </ModalBody>
            <ModalFooter>
                <ClickButton
                    color="success"
                    size="lg"
                    className={style.backButton}
                    onClick={() => passSuccess()}
                >
                    Submit Details
                </ClickButton>

                <ClickButton size="lg" color="danger" onClick={toggle.aberdeen}>
                    Cancel
                </ClickButton>
            </ModalFooter>
        </NudgeModal>
    </Form>
    );
};

export default AberdeenFormModal;
