import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { getCompanyBiddingBody } from './companyBodies';
import { getContractorBiddingBody } from './contractorBodies';

const BiddingCard = ({ status, value, company, matchId, refresh, frequency, toggle }) => {
    return (
        <Card>
            <CardBody>
                {company
                    ? getCompanyBiddingBody(value, matchId, refresh, frequency, status, toggle)
                    : getContractorBiddingBody(value, matchId, refresh, frequency, status, toggle)}
            </CardBody>
        </Card>
    );
};

export default BiddingCard;
