export const MATCH_STATUSES_DICTIONARY = {
    NoMatch: 'Not Matched',
    Rejected: 'Dismissed by user',

    Applied: 'Application sent',
    AwaitingCompanyDecision: 'Awaiting company decision',
    ContractorInvited: 'Awaiting user application',

    WilloInvited: 'Invited to video interview',
    InterviewSubmitted: 'Interview awaiting review',
    CompanyPending: 'Pending',
    CompanyAccepted: 'Application successful',
    CompanyRejected: 'Application unsuccessful',
    Negotiation: 'Rate Negotiation',
    AdminApproval: 'Awaiting approval by admin',
    AdminRejected: 'Interest denied',
    Closed: 'Job archived',
    Expired: 'Job expired',
    FaceInterviewScheduled: 'Face-to-face interview scheduled',

    VRAssessmentScheduled: 'VR assessment scheduled',
    VRAssessmentSubmitted: 'VR assessment submitted',

    WaitingOnFaceSchedule: 'Face-to-face interview date pending',
    WaitingOnVRSchedule: 'VR assessment date pending',

    CompanyReview: 'Company reviewing submission',
    
    Accepted: 'Legacy',
    Pending: 'Legacy',
    InterviewScheduled: 'Legacy'
};

export const MATCH_STATUSES = {
    NoMatch: 'NoMatch',
    Accepted: 'Accepted',
    ContractorInvited: 'ContractorInvited',
    WilloInvited: 'WilloInvited',
    InterviewScheduled: 'InterviewScheduled',
    InterviewSubmitted: 'InterviewSubmitted',
    CompanyPending: 'CompanyPending',
    CompanyAccepted: 'CompanyAccepted',
    CompanyRejected: 'CompanyRejected',
    Rejected: 'Rejected',
    Pending: 'Pending',
    Negotiation: 'Negotiation',
    AdminApproval: 'AdminApproval',
    AdminRejected: 'AdminRejected',

    FaceInterviewScheduled: 'FaceInterviewScheduled',
    VRAssessmentScheduled: 'VRAssessmentScheduled',
    VRAssessmentSubmitted: 'VRAssessmentSubmitted',

    WaitingOnFaceSchedule: 'WaitingOnFaceSchedule',
    WaitingOnVRSchedule: 'WaitingOnVRSchedule',

    CompanyReview: 'CompanyReview',
    ContractorInvited: 'ContractorInvited',
    InterviewScheduled: 'InterviewScheduled',

    AwaitingCompanyDecision: 'AwaitingCompanyDecision',
    Applied: 'Applied',
};

/*
    NoMatch,
    Negotiation,
    InterviewSubmitted,
    InterviewScheduled,
    CompanyPending,
    Pending,
    ContractorInvited,
    CompanyAccepted,
    Accepted,
    CompanyRejected,
    Rejected,
    AdminApproval,
    AdminRejected,
*/
