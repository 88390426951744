import { Form } from 'formik';
import React from 'react';
import { Label } from 'reactstrap';

import AppInput from '../../../../../../components/AppInput';

import { FormGroup } from 'reactstrap';
import { FIELDS, formLabels } from './validation';

const EmailForm = ({ formik }) => {
    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            {Object.values(FIELDS).map((item, index) => (
                <>
                    <Label>{formLabels[item]}</Label>
                    <FormGroup className="mb-20">
                        <AppInput id={item} formik={formik} label={formLabels[item]} type="textarea" />
                    </FormGroup>
                </>
            ))}
        </Form>
    );
};

export default EmailForm;
