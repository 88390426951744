import React from 'react';
import NudgeButton from '../../../../buttons/NudgeButton';
import NudgeModal from '../../../../NudgeModal';
import { Col, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GetPageTheme } from '../../../GenericCompanyProfile/HelperComponents';
import { FIELDS, initialValues, validationSchema } from './validation';
import { Form, Formik } from 'formik';
import AppInput from '../../../../AppInput';
import PhotoUploadForm from '../../../../forms/PhotoUploadForm';
import ColourInput from '../../../../ColourInput';
import { patchData } from '../../../../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../../../../consts/urls';

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const VideoBrandingEdit = ({ user, extraData, branding }) => {
    const [modalOpen, setModalOpen] = React.useState();
    const toggle = () => setModalOpen(!modalOpen);

    const [sending, setSending] = React.useState(false);

    const promps = {
        initialValues: user?.branding ? { ...user.branding, [FIELDS.LOGO]: user.logo } : {
            ...initialValues,
            [FIELDS.NAME]: user.name,
            [FIELDS.LOCATION]: extraData?.companyInfo.headquarters,
            [FIELDS.PRIMARY_COLOUR]: `#${user.backColour}`,
            [FIELDS.SECONDARY_COLOUR]: `#${user.backColour}`
        },
        validationSchema,
        onSubmit: async (v) => {
            setSending(true);
            
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "rflafwr3v5bqm4r19ytfuutnjjbcs1o13004");

            var formdata = new FormData();
            formdata.append("name", v[FIELDS.NAME]);
            formdata.append("location", v[FIELDS.LOCATION]);
            formdata.append("website", v[FIELDS.WEBSITE]);
            formdata.append("primary_colour", v[FIELDS.PRIMARY_COLOUR]);
            formdata.append("secondary_colour", v[FIELDS.SECONDARY_COLOUR]);
            if(typeof v[FIELDS.LOGO] == "object") {
                formdata.append("logo", v[FIELDS.LOGO], "file.png");
            }
            
            var requestOptions = {
                method: user.brandingKey ? 'PATCH' : 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`https://api.willotalent.com/api/integrations/v2/departments/${user.brandingKey ? `${user.brandingKey}/` : ""}`, requestOptions)
                .then(response => response.json())
                .then(async (result) => {
                    const response = await patchData({
                        name: v[FIELDS.NAME],
                        bio: "",
                        branding: {
                            location: v[FIELDS.LOCATION],
                            website: v[FIELDS.WEBSITE],
                            
                            primary_colour: v[FIELDS.PRIMARY_COLOUR],
                            secondary_colour: v[FIELDS.SECONDARY_COLOUR]
                        },
                        brandingKey: result?.key
                    }, `${API_DASHBOARD_COMPANY}`);
                    
                    toggle();
                })
                .finally(() => { setSending(false);  })
                .catch(error => console.log('error', error));
        }
    }
    
    const theme = GetPageTheme();
    return (
        <>
            { user && extraData ? <NudgeButton onClick={toggle} style={{ minHeight: '40px', width: '100%' }}>
                Customise Branding
            </NudgeButton> : null }
            <NudgeModal headerText={"Video Interview Branding"} id={`modal-${theme}`} isOpen={modalOpen} toggle={toggle}>
                <Formik {...promps}>{(formik) => (
                    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <ModalBody style={{ minHeight: '240px' }}>
                            <Row>
                                <Col md={12}>
                                    <Label>Company Logo</Label>
                                    <PhotoUploadForm formik={formik} id={FIELDS.LOGO}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className='mb-20'>
                                                    <Label for={FIELDS.NAME}>Company Name</Label>
                                                    <AppInput formik={formik} label="" id={FIELDS.NAME}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className='mb-20'>
                                                    <Label for={FIELDS.LOCATION}>Company Location</Label>
                                                    <AppInput formik={formik} label="" id={FIELDS.LOCATION}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    <FormGroup className='mb-20'>
                                        <Row>
                                            <Col md={12}>
                                                <Label for={FIELDS.WEBSITE}>Company Website</Label>
                                                <AppInput formik={formik} label="" id={FIELDS.WEBSITE}/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className='mb-20'>
                                        <Row>
                                            <Col md={6}>
                                                <Label for={FIELDS.PRIMARY_COLOUR}>Header/Footer Colour</Label>
                                                <ColourInput style={{ width: '100%' }} id={FIELDS.PRIMARY_COLOUR} formik={formik}/>
                                            </Col>
                                            <Col md={6}>
                                                <Label for={FIELDS.SECONDARY_COLOUR}>Button Colour</Label>
                                                <ColourInput id={FIELDS.SECONDARY_COLOUR} formik={formik}/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <NudgeButton disabled={sending} onClick={formik.handleSubmit}>
                                Submit
                            </NudgeButton>
                        </ModalFooter>
                    </Form>
                )}</Formik>
            </NudgeModal>
        </>
    )
}

export default VideoBrandingEdit;