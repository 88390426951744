import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import style from './style.module.scss';

import { API_DASHBOARD_ADMIN, API_DASHBOARD_CLOOD } from '../../../../consts/urls';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { prepareAuthHeaders, authFetcher, authPostData, NEVER_REFRESH } from '../../../../api/tools';
import { ContainerCard, sort_by_key, arrToString, GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import SearchBox from '../../../SearchBox';
import Loader from '../../../Loader';
import SuccessModal from '../../../modals/SuccessModal';

import NudgeTabs from '../../../NudgeTabs';

import UserCard from './UserCard';
import useMobile from '../../../../hooks/useMobile';

import { getStatusDescription } from '../CandidatesToMatch/CandidateSelect';
import ClickButton from '../../../buttons/NudgeButton';

const CandidatesToMatch = ({ offer }) => {
    const { id } = useParams();
    const { isMobileWide } = useMobile();
    const [search, setSearch] = React.useState('');

    //data variables
    const { data, mutate } = useSWR(
        !isMobileWide ? `${API_DASHBOARD_ADMIN}/Offers/${id}/Contractors` : '',
        authFetcher,
        NEVER_REFRESH,
    );
    const [contractors, setContractors] = React.useState([]);
    const [toMatch, setToMatch] = React.useState([]);

    //state variables
    const [hasSmartMatched, setHasSmartMatched] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [viewMatched, setViewMatched] = React.useState(false);

    //key variables
    const [key, setKey] = React.useState(false);
    const sync = () => {
        setKey(!key);
    };

    //modal variables
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    //interact with casebase
    async function retrieveCases() {
        setSending(true);
        setToMatch([]);

        const response = await authFetcher(`${API_DASHBOARD_CLOOD}/Offers/GetSmartMatches/${offer.id}`);

        if (response) {
            response.bestK.forEach((item) => {
                item.match = offer.matches.find((i) => i.contractor.id == item.Id);
                item.totalScore__ = calculateMatchPercent(item) || 0;
            });

            const best = {
                bestK: sort_by_key(response?.bestK, 'totalScore__').reverse(),
            };

            let smartMatches = [];
            contractors.forEach((contractor) => {
                let matched = false;
                best.bestK.forEach((match, index) => {
                    if (match.Id == contractor.id && match.score__ > 0) {
                        matched = true;

                        contractor.score = match.score__;
                        contractor.rank = index + 1;
                        contractor.match_explanation = match.match_explanation;
                        contractor.totalScore__ = match.totalScore__;
                    }
                });

                if (matched) {
                    smartMatches.push(contractor);
                }
            });
            setContractors(smartMatches);
            setHasSmartMatched(true);
        } else {
            setSending(false);
        }

        setSending(false);
    }

    const weighted_attrs = [
        { id: 'services_Required', name: 'services required' },
        { id: 'remuneration', name: 'remuneration' },
        { id: 'industry', name: 'industry' },
        { id: 'education', name: 'education' },
        { id: 'certificates', name: 'certificates' },
        { id: 'skills', name: 'skills' },
        { id: 'experience', name: 'experience' },
    ];

    function calculateMatchPercent(user) {
        let total = 0;
        weighted_attrs.forEach((item) => {
            let attr = user.match_explanation.find((ex) => item.name.toLowerCase() == ex.field.toLowerCase());
            if (attr) {
                let sim = attr.similarity;
                let weight = offer.weights[item.id];

                if (weight > 0) {
                    total += (sim / weight) * 100;
                } else {
                    total += sim * 100;
                }
            }
        });

        return total / weighted_attrs.length;
    }

    //filter for contractor list
    function filterContractors() {
        let lContractors = !hasSmartMatched ? sort_by_key(contractors, 'name') : sort_by_key(contractors, 'rank');

        if (search.length) {
            lContractors = lContractors.filter(
                (i) =>
                    findInString(search, i.name) ||
                    findInString(search, i.job.industry) ||
                    findInString(search, i.job.name) ||
                    findInString(search, getStatusDescription(i.matchStatus)),
            );
        }

        let matched = lContractors.filter((i) => i.matchStatus != 'NoMatch');
        let unmatched = lContractors.filter((i) => i.matchStatus == 'NoMatch');

        if (!hasSmartMatched) {
            lContractors = viewMatched ? matched : unmatched;
        }

        return lContractors;
    }

    //filter for matches list
    function filterMatches() {
        let lMatches = toMatch;

        return !hasSmartMatched ? sort_by_key(lMatches, 'name') : sort_by_key(lMatches, 'rank');
    }

    //add item to matches and remove from contractors
    function addItem(item) {
        let lContractors = contractors;
        let lMatches = toMatch;

        lMatches.push(item);
        lContractors.splice(
            lContractors.findIndex((p) => p.id == item.id),
            1,
        );

        setToMatch(lMatches);
        setContractors(lContractors);

        sync();
    }

    //remove item from matches and add back to contractors
    function removeItem(item) {
        let lContractors = contractors;
        let lMatches = toMatch;

        lContractors.push(item);
        lMatches.splice(
            lMatches.findIndex((p) => p.id == item.id),
            1,
        );

        setToMatch(lMatches);
        setContractors(lContractors);

        sync();
    }

    //checks search for string
    function findInString(search, string) {
        if (search && string) {
            return string.toUpperCase().includes(search.toUpperCase());
        } else {
            return false;
        }
    }

    //posts matches to API
    async function sendMatches() {
        setSending(true);
        const url = `${API_DASHBOARD_ADMIN}/Offers/${id}/Match`;

        const data = toMatch.map((el) => el.id);

        const headers = await prepareAuthHeaders();

        try {
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(data),
            });

            await resetContractors();
            toggleModal();
        } catch (e) {
            console.log(e.message);
        } finally {
            setSending(false);
        }
    }

    //resets both lists back to original state
    async function resetContractors() {
        setSending(true);

        await mutate();
        setToMatch([]);

        setHasSmartMatched(false);
        setSending(false);
    }

    //whenever data is updated, set contractors state variable
    React.useEffect(() => {
        if (data) {
            setContractors(data.contractors);
        }
    }, [data]);

    //button and search box components
    const MatchButton = () => (
        <div className={style.searchWrapper}>
            <ClickButton
                onClick={sendMatches}
                className={style.matchButton}
                disabled={!toMatch.length || sending}
                color={'success'}
            >
                Match
            </ClickButton>
        </div>
    );

    const SmartMatchButton = () => (
        <>
            {!hasSmartMatched && !toMatch.length ? (
                <ClickButton
                    onClick={retrieveCases}
                    className={style.smartMatchButton}
                    disabled={sending || toMatch.length}
                    color={'success'}
                >
                    {sending ? 'Fetching...' : 'Smart Match'}
                </ClickButton>
            ) : (
                <ClickButton
                    onClick={resetContractors}
                    className={style.smartMatchButton}
                    disabled={sending}
                    color={'success'}
                >
                    Reset
                </ClickButton>
            )}
        </>
    );
    const theme = GetPageTheme();

    return (
        <>
            { isMobileWide ? (
                <h5>This feature does not work on mobile devices. Switch to desktop to match candidates.</h5>
            ) : data ? (
                <>
                    <SuccessModal
                        isOpen={modalOpen}
                        toggle={toggleModal}
                        header={'Match Result'}
                        body={'Selected candidates have been matched.'}
                    />
                    <Row>
                        <Col md={6}>
                            <ContainerCard
                                header={`${filterContractors(data.contractors).length} ${
                                    viewMatched || hasSmartMatched
                                        ? hasSmartMatched
                                            ? 'Smart Matched'
                                            : 'Matched'
                                        : 'Unmatched'
                                } Candidate(s)`}
                                className={style.containerCard}
                                HeaderAdd={SmartMatchButton}
                                columns={[6, 6]}
                            >
                                <SearchBox
                                    value={search}
                                    setValue={setSearch}
                                    button={false}
                                    placeholder={'Search the list of candidates'}
                                    useKey={true}
                                />
                                {!hasSmartMatched ? (
                                    <NudgeTabs
                                        label="Candidates to Match"
                                        selected={viewMatched}
                                        setTab={setViewMatched}
                                        tabs={[
                                            { label: 'Unmatched', id: false, condition: true },
                                            { label: 'Matched', id: true, condition: true },
                                        ]}
                                    />
                                ) : (
                                    <NudgeTabs
                                        label="Candidates to Match"
                                        selected={viewMatched}
                                        setTab={setViewMatched}
                                        tabs={[
                                            { label: 'Smart Match', id: false, condition: true },
                                        ]}
                                    />
                                )}
                                {data.contractors.length ? (
                                    <UserCard
                                        contractors={filterContractors(data.contractors)}
                                        buttonFunction={addItem}
                                        buttonText={'Select'}
                                        offer={offer}
                                    />
                                ) : null}
                            </ContainerCard>
                        </Col>
                        <Col md={6}>
                            <ContainerCard
                                header={`${filterMatches(toMatch).length} Selected Candidates`}
                                className={style.containerCard}
                                HeaderAdd={MatchButton}
                            >
                                {toMatch.length ? (
                                    <UserCard
                                        contractors={filterMatches(toMatch)}
                                        buttonFunction={removeItem}
                                        buttonText={'Deselect'}
                                        offer={offer}
                                    />
                                ) : null}
                            </ContainerCard>
                        </Col>
                    </Row>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default CandidatesToMatch;
