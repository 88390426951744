import * as Yup from 'yup';

export const FIELDS = {
    RESUME: 'resume',
};

export const validationSchema = Yup.object({
    [FIELDS.RESUME]: Yup.mixed().required('This field is required'),
});

export const initialValues = {
    [FIELDS.RESUME]: '',
};
