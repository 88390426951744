import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import { authSendFile, prepareAuthHeaders, patchData } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import { API_DASHBOARD_COMPANY, API_PROFILE } from '../../../../consts/urls';
import CompanyInformationEdit from './CompanyInformationEdit';
import { FIELDS, validationSchema } from './CompanyInformationEdit/validation';
import NonEditable, { getUserPhoto } from './NonEditable';
import Loader from '../../../Loader';
import style from './style.module.scss';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';

const CompanyInformation = ({ user, extraData, amount, refresh, showEdit = true, colour, setColour }) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [isSending, setSending] = useState(false);
    
    const onClose = () => {
        if(user.backColour) setColour(`#${user.backColour}`)
        setIsOpen(false);
        setSending(false);
    };

    const savedValues = {
        [FIELDS.PHOTO]: getUserPhoto(user),
        [FIELDS.NAME]: user.name,
        [FIELDS.BIO]: user.bio != ' ' ? user.bio : '',
        [FIELDS.VISION]: user.visionStatement != ' ' ? user.visionStatement : '',
        [FIELDS.VIDEO_LINK]: user.videoLink != ' ' ? `https://www.youtube.com/watch?v=${user.videoLink}` : '',
        [FIELDS.USER_ID]: user.id || '',
        [FIELDS.BACK_COLOUR]: colour
    };

    const props = {
        initialValues: savedValues,
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);
            
            try { 
                await updateProfile(values);
                if(values[FIELDS.DELETE_PHOTO]) await deletePhoto();
            } catch(e) {
                console.log(e.message);
            }
            
            await refresh();
            setSending(false);
            setIsOpen(false);
        },
    };

    const updateAll = async (v) => {
        if (v[FIELDS.BIO] === '') v[FIELDS.BIO] = ' ';
        if (v[FIELDS.VIDEO_LINK] === '') v[FIELDS.VIDEO_LINK] = ' ';
        if (v[FIELDS.VISION] === '') v[FIELDS.VISION] = ' ';

        if (v.videoLink !== ' ') v.videoLink = v.videoLink.split('v=')[1].substring(0, 11);
        
        if(v[FIELDS.BACK_COLOUR]) {
            v[FIELDS.BACK_COLOUR] = v[FIELDS.BACK_COLOUR].substring(1, v[FIELDS.BACK_COLOUR].length)
        }

        const data = v;
        const headers = await prepareAuthHeaders();

        try {
            await fetch(API_DASHBOARD_COMPANY, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(data),
            });

        } catch (e) {
            console.log(e.message);
        }
    };

    const updateProfile = async (values) => {
        typeof values.photo === 'object' && (await authSendFile(values.photo, `${API_PROFILE}/Photo`, { UID: user.id }));
        await updateAll(values);
    };

    const deletePhoto = async () => {
        setSending(true);

        try {
            await patchData({}, `${API_PROFILE}/Photo`, { uid: user.id });
        } catch (e) {
            setSending(false);
        }

        await refresh();
        setSending(false);
        setIsOpen(false);
    };

    const theme = GetPageTheme();
    return (
        <Card id={`card-${theme}`}  className={style.card}>
            <CardBody>              
                <div className={style.profile}>
                    {isOpen && showEdit ? (
                        !isSending ? (
                            <Formik {...props}>
                                {(formik) => (
                                    <CompanyInformationEdit
                                        user={user}
                                        extraData={extraData}
                                        formik={formik}
                                        onClose={onClose}
                                        onDeletePhoto={deletePhoto}
                                        colour={colour}
                                        setColour={setColour}
                                        refresh={refresh}
                                    />
                                )}
                            </Formik>
                        ) : (
                            <Loader />
                        )
                    ) : (
                        <NonEditable user={user} onClickEdit={onClickEdit} showEdit={showEdit} amount={amount} colour={colour} mutate={refresh} />
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

CompanyInformation.propTypes = {
    user: PropTypes.object.isRequired,
    refresh: PropTypes.func,
    showEdit: PropTypes.bool,
};

export default CompanyInformation;
