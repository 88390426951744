import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DEFAULT_PICTURE, URLS } from '../../consts/urls';
import style from './style.module.scss';
import { ProcessSalary, ResolveSalary, sort_by_key } from '../pages/GenericCompanyProfile/HelperComponents';
import CloseModal from './CloseModal';

import adzunaLogo from '../../assets/images/adzunaLogo.png';
import NudgeCard from '../NudgeCard';
import { useTranslation } from 'react-i18next';
import NudgeBoxes from '../NudgeBoxes';

export const ContractorDashJobReel = ({ data, validating, theme, length }) => {
    const [adzunaModalOpen, setAdzunaModalOpen] = React.useState(false);
    const toggleAdzuna = () => setAdzunaModalOpen(!adzunaModalOpen);
    const { t } = useTranslation();

    const [redirect, setRedirect] = React.useState('');

    const adzunaFunction = (url) => {
        setRedirect(url);
        toggleAdzuna();
    };

    var latestOffers = [];
    for(var i = 0; i < length; i++) {
        latestOffers.push({ id: i, placeholder: true },)
    }

    if (data?.length) {
        latestOffers = data.filter(p => !!p);
    }

    function SalaryFunction(from, to, currency, freq, type) {
        return ResolveSalary(`${from}-${to}`, currency, freq, type);
    }

    function resolveName(job) {
        if (job.source == 'Adzuna') {
            return job.company.display_name;
        }
        
        return job.company.name;
    }

    return (
        <Row>
            <Col>
                <h2 className={style.jobReelHeader}>{t('dashboard.new_jobs')}</h2>
                <Row className={style.row}>
                    {latestOffers.length ? (
                        latestOffers.map((job) => (
                            <Col key={job.id} xs={12} sm={6} md={6} lg={4} xl={3} className={`${style.jobCol} col-xxl-3`}>
                                <NudgeCard theme={theme} className={style.reelJob}>
                                    {job.source == 'Adzuna' ? (
                                        <CloseModal
                                            isOpen={adzunaModalOpen}
                                            toggle={toggleAdzuna}
                                            redirectUrl={redirect}
                                        />
                                    ) : null}

                                    { !job.placeholder ? (
                                        <CardBody className={style.reelJobBody}>
                                            <div className={style.jobInfo}>
                                                <Link
                                                    onClick={
                                                        job.source == 'Adzuna'
                                                            ? () => adzunaFunction(job.redirect_url)
                                                            : null
                                                    }
                                                    to={
                                                        !job.placeholder && job.source == 'Nudge'
                                                            ? `${URLS.OFFERS}/${job.id}`
                                                            : '#'
                                                    }
                                                >
                                                    <h3
                                                        className={`${style.jobTitle} ${
                                                            job.placeholder ? style.placeholder : ''
                                                        }`}
                                                    >
                                                        {job.source == 'Adzuna' ? job.title.split('-')[0] : job.title}
                                                    </h3>
                                                </Link>

                                                {job.source == 'Nudge' ? (
                                                    <Link to={`${URLS.DIRECTORY}/${job.company.id}`}>
                                                        <p
                                                            className={`${style.jobCompanyNudge} ${
                                                                job.placeholder ? style.placeholder : ''
                                                            }`}
                                                        >
                                                            {resolveName(job)}
                                                        </p>
                                                    </Link>
                                                ) : job.source == 'Adzuna' ? (
                                                    <p
                                                        className={`${style.jobCompany} ${
                                                            job.placeholder ? style.placeholder : ''
                                                        }`}
                                                    >
                                                        {resolveName(job)}
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={`${style.jobCompany} ${
                                                            job.placeholder ? style.placeholder : ''
                                                        }`}
                                                    >
                                                        {resolveName(job)}
                                                    </p>
                                                )}


                                                { !latestOffers[0].placeholder ? (
                                                    job.source !== 'Adzuna' ? (
                                                        <div className={style.jobIconNudge}>
                                                            <img
                                                                src={job.company.pictureUrl || DEFAULT_PICTURE}
                                                                alt={job.company.name}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className={style.jobIcon}>
                                                            <img src={adzunaLogo} alt={job.company.name} />
                                                        </div>
                                                    )
                                                ) : null}
                                            </div>
                                        </CardBody>
                                    ) : (
                                        <CardBody>
                                            <div className={style.namePlaceholder}>
                                                <div className={style.activity} />
                                            </div>
                                            <div className={style.companyPlaceholder}>
                                                <div className={style.activity} />
                                            </div>

                                            <div className={style.attrPlaceholderContainer}>
                                                <span className={style.attrPlaceholder}>
                                                    <div className={style.activity} />
                                                </span>
                                                <span className={style.attrPlaceholder}>
                                                    <div className={style.activity} />
                                                </span>
                                                <span className={style.attrPlaceholder}>
                                                    <div className={style.activity} />
                                                </span>
                                            </div>

                                            <div className={style.picturePlaceholder}>
                                                <div className={style.activity} />
                                            </div>
                                        </CardBody>
                                    )}
                                    
                                    {job.source == 'Nudge' ? (
                                        <>
                                            <NudgeBoxes
                                                data={[
                                                    { label: job.location || 'Remote', danger: false},
                                                    { label: SalaryFunction(job.salaryFrom, job.salaryTo, job.currencyType, job.salaryFreq, job.workType), danger: false},
                                                ]}
                                                fullWidth
                                            />
                                        </>
                                    ) : job.source == 'Adzuna' ? (
                                        <>
                                            <NudgeBoxes
                                                data={[
                                                    { label: job.location.display_name || 'Unknown', danger: false},
                                                    { label: SalaryFunction(job.salary_min, job.salary_max, 'GBP', 'YEAR', 'Paid Work'), danger: false},
                                                ]}
                                                fullWidth
                                            />
                                        </>
                                    ) : null}
                                </NudgeCard>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            <h5>No jobs found.</h5>
                        </Col>
                    )}

                    {latestOffers.length ? (
                        <Col xs={12} sm={6} md={6} lg={4} xl={3} className={`${style.jobCol} col-xxl-3`}>
                            <NudgeCard theme={theme} className={`${style.reelJob} ${style.reelMore} ${style.reelMoreCard}`}>
                                <CardBody className={style.reelJobBody}>
                                    <div className={style.jobInfo}>
                                        <Link className={!data ? style.viewMoreDisabled : style.viewMore} to={!!data && `${URLS.JOBS_REEL}`}>
                                            <p>View more</p>
                                        </Link>
                                    </div>
                                </CardBody>
                            </NudgeCard>
                        </Col>
                    ) : null}
                </Row>
            </Col>
        </Row>
    );
};

export default ContractorDashJobReel;
