import React from 'react';

import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import style from './style.module.scss';

const MeetingEnd = () => {
    const { user } = React.useContext(AuthContext);
    window.zE('messenger', 'hide');

    return user ? (
        <div className={style.meetingEnd}>
            <p>You have left the meeting.</p>
        </div>
    ) : null;
};

export default MeetingEnd;
