import React from 'react';
import Select from 'react-select';
import { customStyles } from '../../../../../../components/SelectField';

import { Card, Col, Row, Button } from 'reactstrap';

import style from '../style.module.scss';
import { authPostData } from '../../../../../../api/tools';
import { API_DASHBOARD_ADMIN } from '../../../../../../consts/urls';
import ClickButton from '../../../../../../components/buttons/NudgeButton';

const CompanyCard = ({ item, mutate }) => {
    const accessOptions = [
        { label: 'No', value: false },
        { label: 'Yes', value: true },
    ];

    const [changed, setChanged] = React.useState(false);

    const willoInitial = item.companyPermissions?.length
        ? item.companyPermissions.find((p) => p.permissionType == 'willo_interview')?.enabled
            ? 1
            : 0
        : 0;
    const [willoPermission, setVideoPermission] = React.useState(accessOptions[willoInitial]);

    const edifyInitial = item.companyPermissions?.length
        ? item.companyPermissions.find((p) => p.permissionType == 'edify_interview')?.enabled
            ? 1
            : 0
        : 0;
    const [edifyPermission, setEdifyPermission] = React.useState(accessOptions[edifyInitial]);

    async function constructChange(item, willo, edify) {
        const object = {
            permissions: [
                { companyId: item.id, permissionType: 'willo_interview', enabled: willo.value },
                { companyId: item.id, permissionType: 'edify_interview', enabled: edify.value },
            ],
        };

        const response = await authPostData(object, `${API_DASHBOARD_ADMIN}/Companies/GrantPermissions`);
        mutate();
    }

    React.useEffect(() => {
        if (item) {
            item.companyPermissions.forEach((p, index) => {
                if (p.permissionType == 'willo_interview' && p.enabled) {
                    setVideoPermission(accessOptions[1]);
                }
                if (p.permissionType == 'edify_interview' && p.enabled) {
                    setEdifyPermission(accessOptions[1]);
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (willoPermission.value != willoInitial || edifyPermission.value != edifyInitial) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [willoPermission, edifyPermission]);

    function changeVideo(option) {
        setVideoPermission(option);
    }

    function changeEdify(option) {
        setEdifyPermission(option);
    }

    return (
        <Card className={style.companyCard}>
            <Row>
                <Col md={3}>
                    <h5>{item.name}</h5>
                    {item.id}
                </Col>
                <Col md={2}>
                    <h5>Video</h5>
                    <Select
                        value={willoPermission}
                        options={accessOptions}
                        styles={customStyles()}
                        onChange={(option) => {
                            changeVideo(option);
                        }}
                        isSearchable={false}
                    />
                </Col>
                <Col md={2}>
                    <h5>Edify</h5>
                    <Select
                        value={edifyPermission}
                        options={accessOptions}
                        styles={customStyles()}
                        onChange={(option) => {
                            changeEdify(option);
                        }}
                        isSearchable={false}
                    />
                </Col>
                {changed ? (
                    <Col>
                        <h5>Save changes?</h5>
                        <ClickButton color="success" onClick={() => constructChange(item, willoPermission, edifyPermission)}>
                            Save
                        </ClickButton>
                    </Col>
                ) : null}
            </Row>
        </Card>
    );
};

export default CompanyCard;
