import * as Yup from 'yup';

export const FIELDS = {
    TIME: 'time',
};

export const validationSchema = Yup.object({
    [FIELDS.TIME]: Yup.string().required('This field is required'),
});

export const initialValues = {
    [FIELDS.TIME]: '',
};
