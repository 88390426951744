import * as Yup from 'yup';

export const FIELDS = {
    TITLE: 'title',
    DATE: 'date',
    COLOUR: 'colour',
    DURATION: 'duration',
};

export const validationSchema = Yup.object({
    [FIELDS.TITLE]: Yup.string().required('This field is required.'),
    [FIELDS.DATE]: Yup.string().required('This field is required.'),
    [FIELDS.COLOUR]: Yup.string().required('This field is required.'),
    [FIELDS.DURATION]: Yup.string().required('This field is required.'),
});

export const initialValues = {
    [FIELDS.TITLE]: '',
    [FIELDS.DATE]: '',
    [FIELDS.COLOUR]: '',
    [FIELDS.DURATION]: 0,
};
