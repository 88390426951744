import React from 'react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { initialValues, validationSchema, FIELDS } from './validation';

import CalendarForm from '../../../forms/CalendarForm';
import AppInput from '../../../AppInput';
import { authPostData } from '../../../../api/tools';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeModal from '../../../NudgeModal';
import useMobile from '../../../../hooks/useMobile';

const BookingCalendar = ({ userId, mutate, assessment, url, isOpen, toggle, excludedDates }) => {

    const { isMobileWide } = useMobile();

    const [error, setError] = React.useState();
    const [sending, setSending] = React.useState(false);

    async function bookTimeSlot(v) {
        setSending(true);
        setError("")
        const request = {
            userId: userId,
            
            participantAddress: v.participantAddress,
            timeSlot: moment(v.timeSlot).format("yyyy-MM-DD HH:mm:ss.SSSS")
        }

        const response = await authPostData(request, `${url}/BookableAssessments/${assessment.id}/Book`);

        if(response == -2) {
            setError("You cannot book with the same email address multiple times.")
            setSending(false);
        } else {
            await mutate();
            setSending(false);
            toggle();
        }

    }

    const props = {
        initialValues,
        validationSchema: validationSchema,
        onSubmit: bookTimeSlot,
    };

    return (
        <Formik {...props}>{(formik) => 
            <>
                <Form noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <NudgeModal headerText={`You've selected ${assessment.title}`} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle}>
                        <ModalBody>
                            { !isMobileWide ? <CalendarForm
                                formik={formik}
                                id={FIELDS.TIME_SLOT}
                                excludedTimes={excludedDates.map((item) => new Date(item.timeSlot)) || []}
                                errorText={error}
                                intervals={90}
                            >
                                <div style={{ width: '80%', margin: 'auto' }}>
                                    <Label>Participant Email Address</Label>
                                    <Field
                                        formik={formik}
                                        id={FIELDS.EMAIL}
                                        label={"Participant Email Address"}
                                        component={AppInput}
                                    />
                                    <br/>

                                </div>

                            </CalendarForm> : <i>This feature is not supported on mobile devices.</i> }
                        </ModalBody>
                    <ModalFooter>
                        <ClickButton disabled={sending || isMobileWide} type="submit" color={'success'} style={{ float: 'right'}}>Book Time Slot</ClickButton>
                    </ModalFooter>
                </NudgeModal>
            </Form>
        </>
    }</Formik>
    )
}

export default BookingCalendar;