import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { authPostData } from '../../api/tools';
import { FLASK_API } from '../../consts/urls';

const DiscourseSSO = () => {
    const location = useLocation();
    const [error, setError] = useState(false);

    useEffect(() => {
        let ignore = false;

        const fetchData = async () => {
            const params = new URLSearchParams(location.search);
            const sso = params.get('sso');
            const sig = params.get('sig');

            if (!sso || !sig) {
                setError(true);
                return;
            }

            try {
                const res = await authPostData({ sso, sig }, `${FLASK_API}/sso/discourse-auth`);
                if (res.redirect) {
                    if (!ignore) {
                        console.log('redirecting to: ', res.redirect);
                        window.location.replace(res.redirect);
                    }
                } else {
                    console.log('bad response');
                    setError(true);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
                setError(true);
            }
        };

        fetchData();

        return () => {
            ignore = true;
        };
    }, []);

    return <div>{error ? 'An error occurred.' : 'Logging in to Nudge Forum...'}</div>;
};

export default DiscourseSSO;
