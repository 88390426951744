import React from 'react';
import { Form } from 'reactstrap';

import SaveButton from '../../../buttons/SaveButton';
import GenericFormWithArray from '../../../forms/GenericFormWithArray';

import PlusButton from '../../../buttons/PlusButton';
import { usePlusButtonWithAlwaysMinus } from '../../../buttons/PlusButton';

import { FIELDS } from './validate';

import style from './style.module.scss';
import NewInputForm from '../../../forms/NewInputForm';

const EditableValues = ({ formik, onClose }) => {
    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <NewInputForm
                formik={formik}
                addValue={{ content: '' }}
                field={FIELDS.VALUES}
                subField={'content'}
                fieldLimit={10}
                buttons={<SaveButton  onClose={onClose} />}
            />
        </Form>
    );
};

export default EditableValues;
