import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';

import { FIELDS } from '../CreateOfferForm/validation';
import SliderComp from '../../../../components/SliderComp';
import { GetPageTheme, capitaliseFirstLetter } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../components/NudgeModal';

function filterWeight(weight) {
    switch (weight) {
        case 'weightsId':
        case 'id':
            return false;
        default:
            return true;
    }
}

const CloseModal = ({ formik, isOpen, toggle, submit, txt, showPost = true }) => {
    const increment = 1;

    const minValue = 0;
    const maxValue = 5;

    const attributes = Object.keys(formik.values.weights).filter((i) => filterWeight(i));

    const doSubmit = () => {
        submit();
        toggle();
    };

    return (
        <NudgeModal headerText={"Adjust matching model weights."} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <h5 className={style.sliderHeader}>
                On a scale of {minValue} to {maxValue}, how important are these factors to this specific offer? Our
                Klara matching model uses these values to find just the right candidate.
            </h5>
            <ModalBody className={style.modalBody}>
                {attributes.map((item, index) => (
                    <Row className={style.row}>
                        <Col xs={12} md={12}>
                            <h5 className={style.sliderLabel}>{capitaliseFirstLetter(item.replaceAll('_', ' '), 1)}</h5>
                            <SliderComp
                                form={formik}
                                field={FIELDS.WEIGHTS}
                                name={item}
                                minValue={minValue}
                                maxValue={maxValue}
                                step={increment}
                            />
                        </Col>
                    </Row>
                ))}
                <Row>
                    {showPost ? (
                        <Col md={6}>
                            <ClickButton className={style.button} onClick={doSubmit} color="success">
                                {txt}
                            </ClickButton>
                        </Col>
                    ) : null}

                    <Col md={showPost ? 6 : 12}>
                        <ClickButton className={style.button} onClick={toggle} color="danger">
                            Close
                        </ClickButton>
                    </Col>
                </Row>
            </ModalBody>
        </NudgeModal>
    );
};
CloseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CloseModal;
