import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { URLS } from '../../consts/urls';

import style from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';

export function CompareStringToArray(string, array) {
    if (!string) return false;

    if (array.some((o) => string.includes(o))) {
        return true;
    } else {
        return false;
    }
}

const ScrollButton = () => {

    const { modalOpen } = React.useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const theme = GetPageTheme();
    const SCROLL_AMOUNT = 200;

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > SCROLL_AMOUNT) {
            setVisible(true);
        } else if (scrolled <= SCROLL_AMOUNT) {
            setVisible(false);
        }
    };

    const forbidden = [ URLS.COMPANY_INTERVIEWS ];

    if (CompareStringToArray(window.location.href, forbidden)) {
        return <></>;
    }

    const scrollToTop = () => {
        /* you can also use 'auto' behaviour in place of 'smooth' */
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.removeEventListener('scroll', toggleVisible);
    window.addEventListener('scroll', toggleVisible);

    return (
        <div className={visible && !modalOpen ? style.scrollButtonContainer : style.scrollContainerDisabled}>
            <div className={`${theme == 'light' ? style.themeButton : style.themeButtonDark} ${visible ? style.fadeIn : style.fadeOut}`} onClick={scrollToTop}>
                <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-up']}/>
            </div>
        </div>
    );
};

export default ScrollButton;
