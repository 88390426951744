import React from 'react';
import NudgeModal from '../../../../components/NudgeModal';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import NudgeButton from '../../../../components/buttons/NudgeButton';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

const ConfirmModal = ({ employee, toggle, onClick }) => {

    const theme = GetPageTheme();

    return (
        <NudgeModal headerText={"Are you sure?"} id={`modal-${theme}`} isOpen={!!employee} toggle={toggle}>
            <ModalBody>
                <h5>Are you sure you want to dismiss {employee}?</h5>
            </ModalBody>
            <ModalFooter>
                <NudgeButton color="danger" onClick={onClick}>
                    Yes
                </NudgeButton>
                <NudgeButton onClick={toggle}>
                    No
                </NudgeButton>
            </ModalFooter>
        </NudgeModal>
    )
}

export default ConfirmModal;