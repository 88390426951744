import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData, NEVER_REFRESH } from '../../../../../api/tools';
import { API_DASHBOARD_ADMIN } from '../../../../../consts/urls';
import { ADMIN, USER } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

import SearchBox from '../../../../../components/SearchBox';
import { sort_by_key } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';

const TestingGround = ({ id }) => {
    function arrToString(input) {
        let str = '';
        if (input.length) {
            input.forEach((i, index) => {
                if (index == 0) {
                    str += `${i.title ? i.title : i.content ? i.content : i.name}`;
                } else {
                    str += `,${i.title ? i.title : i.content ? i.content : i.name}`;
                }
            });
        }
        return str;
    }

    const [search, setSearch] = React.useState('');
    const [matches, setMatches] = React.useState([]);

    const getURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/${id}`;
    const { data: attributes } = useSWR(getURL, authFetcher, NEVER_REFRESH);

    const postURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/retrieve`;

    async function retainCase(offerID) {
        const offer = await authFetcher(`${API_DASHBOARD_ADMIN}/Offers/${offerID}`);
        const split = offer.rangeExpected.split('-');

        const body = {
            globalSim: 'Weighted Sum',
            topk: 10,
            projectId: id,
            explanation: true,
            data: [
                {
                    name: 'Id',
                    weight: 0,
                },
                {
                    name: 'Services Required',
                    value: offer.title,
                    weight: 4,
                },
                {
                    name: 'Remuneration',
                    value: (parseInt(split[0]) + parseInt(split[1])) / 2,
                    weight: 4,
                },
                {
                    name: 'Work Mode',
                    value: offer.locationType,
                    weight: 4,
                },
                {
                    name: 'Location',
                    value: offer.location || offer.locationType,
                    weight: 4,
                },
                {
                    name: 'Industry',
                    value: offer.sector,
                    weight: 4,
                },
                {
                    name: 'Education',
                    value: offer.qualification.replaceAll('/', ' in '),
                    weight: 4,
                },
                {
                    name: 'Certificates',
                    value: arrToString(offer.certificates),
                    weight: 4,
                },
                {
                    name: 'Experience',
                    value: offer.description.replaceAll('\\', ''),
                    weight: 4,
                },
                /*{
                    "name": "Start Date",
                    "value": moment(offer.startDate).format(`YYYY-MM-DD`)
                },*/
                {
                    name: 'Skills',
                    value: arrToString(offer.skills),
                    weight: 4,
                },
                {
                    name: 'Freelance',
                    value: offer.type,
                    weight: 4,
                },
                {
                    name: 'Values',
                    value: offer.company.statement,
                    weight: 4,
                },
            ],
        };

        const response = await authPostData(body, postURL);

        if (response?.bestK) {
            setMatches(sort_by_key(response.bestK, 'score__').reverse());
        }
    }

    React.useEffect(() => {
        if (attributes) console.log(attributes);
    }, [attributes]);

    return (
        <>
            <Row></Row>
            <SearchBox value={search} setValue={setSearch} button={false} placeholder={'Offer ID goes here...'} />
            <Button onClick={() => retainCase(search)} color={'success'}>
                Post
            </Button>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
