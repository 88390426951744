import PropTypes from 'prop-types';
import React from 'react';

import SuccessModal from '../../../../components/modals/SuccessModal';

const ContactBody = () => (
    <h5>
        We are currently checking the candidate's interest at which point we will be in touch to request further action.
        If there are any issues, please reach out to us at{' '}
        <a href="mailto: nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a>.
    </h5>
);

const StepsPopup = ({ isOpen, toggle }) => (
    <SuccessModal isOpen={isOpen} toggle={toggle} header="Next Steps" body={<ContactBody />} />
);

StepsPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default StepsPopup;
