import React from 'react';
import { Alert } from 'reactstrap';

import style from './style.module.scss';

const NudgeAlert = ({ text, toggle }) => {
    if(text) {
        console.log(text.split("::/")[0]);
    }
    return (
        text ? <Alert className={style.alert} color={text.split("::/")[0]} toggle={toggle}>
            { text.split("::/")[1] }
        </Alert> : null
    )
}

export default NudgeAlert;