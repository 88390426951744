import PropTypes from 'prop-types';
import React from 'react';
import WeightsDashboard from '../../CreateOffer/WeightsDashboard';
import { Button, Col, Row } from 'reactstrap';

import style from './style.module.scss';
import ClickButton from '../../../../components/buttons/NudgeButton';

const Buttons = ({ formik, postText, sending }) => {
    return (
        <>
            <Row className="mt-15">
                <Col xs={12} md={{ size: 4, offset: 4 }}>
                    <ClickButton
                        disabled={sending}
                        color={'success'}
                        size="lg"
                        className={style.btnPost}
                        type="submit"
                    >
                        {postText}
                    </ClickButton>
                </Col>
            </Row>
        </>
    );
};

Buttons.propTypes = {
    onBack: PropTypes.func.isRequired,
};

export default Buttons;
