import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import ContractorDashJobReel from '../../../components/ContractorDashJobReel';
import ContractorDashOfferList from '../../../components/ContractorDashOfferList';
import ContractorDashReminders from '../../../components/ContractorDashReminders';
import MobileInfo from '../../../components/MobileInfo';
import SignInPageTemplate from '../../../components/SignInPageTemplate';

import { Col, Row } from 'reactstrap';
import { authFetcher, NEVER_REFRESH, exFetcher } from '../../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { customStyles } from '../../../components/SelectField';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

import style from './style.module.scss';
import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';
import NudgeTabs from '../../../components/NudgeTabs';
import useMobile from '../../../hooks/useMobile';
import ContractorDashProjectProgress from '../../../components/ContractorDashProjectProgress';

const historyOptions = (user) => {
    return [
        {
            label: 'New Jobs',
            id: 'new_jobs',
            condition: user?.parentId == ""
        },
        {
            label: 'My Applications',
            id: 'contracts',
            condition: true
        },
        {
            label: 'Reminders',
            id: 'reminders',
            condition: true
        },
    ].filter((item) => !!item.condition)
};

const APP_ID = 'de0a2d37';
const APP_KEY = '6b89e573b8421debfe1173daae90a3b6';

const getSeedURL = (job) => {
    return `https://api.adzuna.com:443/v1/api/jobs/gb/search/1?app_id=${APP_ID}&app_key=${APP_KEY}&results_per_page=5&what=${job.subIndustry}&where=scotland&sort_by=salary&content-type=application/json`;
};

export const DashboardPage = () => {
    const { user, userRoles } = React.useContext(AuthContext);
    const [Tab, setTab] = React.useState(historyOptions(user)[0].id);

    const [concatData, setConcatData] = React.useState([]);
    const { theme } = React.useContext(ThemeContext);
    const { isMobileWide } = useMobile();

    const reel_rows = 2;
    const reel_length = (4 * reel_rows) - 1;

    let { data: reelData } = useSWR(!user?.parentId ? `${API_DASHBOARD_CONTRACTOR}/Offers/All` : ``, authFetcher, NEVER_REFRESH);
    let { data: adzunaData } = useSWR(
        !user?.parentId ? `${API_DASHBOARD_CONTRACTOR}/Offers/Adzuna/${user?.job?.subIndustry}/${1}` : ``,
        authFetcher,
        NEVER_REFRESH,
    );

    const { data: offerData } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Offers`, authFetcher, NEVER_REFRESH);
    const { data: remindersData, mutate: remindersMutate } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/Reminders`,
        authFetcher, NEVER_REFRESH
    );

    React.useEffect(() => {
        if (reelData && adzunaData && user) {
            let nudgeJobs = reelData?.offers || [];
            let adzunaJobs = adzunaData?.results || [];
            let output = [];

            for (let i = 0; i < nudgeJobs.length || i < adzunaJobs.length; i++) {
                if (i < nudgeJobs.length) {
                    nudgeJobs[i].source = 'Nudge';
                    output.push(nudgeJobs[i]);
                }
                if (i < adzunaJobs.length) {
                    adzunaJobs[i].source = 'Adzuna';
                    output.push(adzunaJobs[i]);
                }

                if (output.length >= reel_length) break;
            }

            if (output.length) setConcatData(output.slice(0, reel_length));
        }
    }, [reelData, adzunaData, user]);

    return (
        <SignInPageTemplate>
            <MobileInfo />
            { isMobileWide ? <NudgeTabs
                label="Dashboard Sections"
                selected={Tab}
                setTab={setTab}
                tabs={historyOptions(user)}
            /> : null }

            <div>
                <Row>
                    { Tab === `new_jobs` || !isMobileWide ? <Col lg={12} xl={12}>
                        { !user?.parentId ? <ContractorDashJobReel data={concatData} theme={theme} length={reel_length}/> : null }
                    </Col> : null }
                    { Tab === `contracts` || !isMobileWide ? <Col lg={8} xl={8}>
                        <ContractorDashOfferList data={offerData} theme={theme} />
                    </Col> : null }
                    {/* { Tab === `project` || !isMobileWide ? <Col lg={4} xl={4}>
                        <ContractorDashProjectProgress user={user} roles={userRoles}/>
                    </Col> : null } */}
                    { Tab === `reminders` || !isMobileWide ? <Col lg={4} xl={4}>
                        <ContractorDashReminders data={remindersData} mutate={remindersMutate} theme={theme} />
                    </Col> : null }
                </Row>
            </div>
        </SignInPageTemplate>
    );
};

DashboardPage.propTypes = {
    showEdit: PropTypes.bool,
};

export default withAuthProtectedRouter(DashboardPage, [USER]);
