import en from 'date-fns/locale/pl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import style from './style.module.scss';
import { Card, Col, Row } from 'reactstrap';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';
import useMobile from '../../../../hooks/useMobile';

registerLocale('en', en);

const NonEditableCalendar = ({ startDate, endDate, onClickEdit, showEdit, hours }) => {

    return (
        <Row>
            <Col xs={12} sm={12} md={6} lg={12} >
                { !!startDate && <Card className={style.calendarBox} id={`card-${GetPageTheme()}`} style={{ display: 'flex', flexDirection: 'row', padding: '12px', marginTop: '10px', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={['fas', 'calendar-check']} style={{ fontSize: '2rem', width: '20%' }}/>
                    <div class="d-block" style={{ textAlign: 'left' }}>
                        <div>Earliest start date</div>
                        <strong >{moment(startDate).format('dddd, Do of MMMM YYYY')}</strong>
                    </div>
                </Card> }
            </Col>
            <Col xs={12} sm={12} md={6} lg={12} >
                { !!hours && <Card id={`card-${GetPageTheme()}`} style={{ display: 'flex', flexDirection: 'row', padding: '12px', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={['fas', 'clock']} style={{ fontSize: '2rem', width: '20%' }}/>
                    <div class="d-block" style={{ textAlign: 'left' }}>
                        <div>Available up to:</div>
                        <strong>{hours}</strong> hours a week
                    </div>
                </Card> }
            </Col>
            {/* {!startDate && !hours ? (
                <p style={{ textAlign: 'left' }}>
                    <i>Availability not set yet.</i>
                </p>
            ) : null}
            <div className={style.availabilityInfo}>
                {startDate ? (
                    <p>
                        <FontAwesomeIcon icon={['far', 'calendar-check']} style={{ marginRight: '5px' }} /> Earliest
                        available start date: <strong>{moment(startDate).format('dddd, Do of MMMM YYYY')}</strong>
                    </p>
                ) : (
                    ''
                )}

                {hours ? (
                    <p>
                        <FontAwesomeIcon icon={['far', 'clock']} style={{ marginRight: '5px' }} /> Available up to{' '}
                        <strong>{hours}</strong> hours a week
                    </p>
                ) : (
                    ''
                )}
            </div> */}
        </Row>
    );
};

NonEditableCalendar.propTypes = {
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    hours: PropTypes.object,
    onClickEdit: PropTypes.func.isRequired,
    showEdit: PropTypes.bool,
};

export default NonEditableCalendar;
