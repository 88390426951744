import * as Yup from 'yup';

export const REG_EX = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirmPassword',
    REF_CODE: 'refCode',
    ACCEPT: 'accept',
};

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
    [FIELDS.PASSWORD]: Yup.string()
        .min(8, 'This field should be at least 8 chars length')
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .required('This field is required'),
    [FIELDS.CONFIRM_PASSWORD]: Yup.string()
        .min(8, 'This field should be at last 8 chars length')
        .oneOf([Yup.ref(FIELDS.PASSWORD), null], 'Passwords must match')
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .required('This field is required'),
    [FIELDS.ACCEPT]: Yup.boolean().required('This field is required').oneOf([true], 'Field must be checked'),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.PASSWORD]: '',
    [FIELDS.CONFIRM_PASSWORD]: '',
    [FIELDS.ACCEPT]: false,
    [FIELDS.REF_CODE]: '',
};
