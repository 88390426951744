import React from 'react';
import { useParams } from 'react-router-dom';

import FullCompanyProfile from '../../../components/pages/FullCompanyProfile';
import { API_DASHBOARD_ADMIN } from '../../../consts/urls';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

const getUrl = (id) => `${API_DASHBOARD_ADMIN}/Companies/${id}`;

const getDetailsUrl = (id) => `${API_DASHBOARD_ADMIN}/ExtraDetails/${id}`;

const AdminCompanyProfile = () => {
    const { id } = useParams();

    return <FullCompanyProfile url={getUrl(id)} detailsUrl={getDetailsUrl(id)} id={id} showEdit />;
};

export default withAuthProtectedRouter(AdminCompanyProfile, [ADMIN]);
