import React from 'react';
import { Col, Row, Card } from 'reactstrap';
import { FullStatCardScaling, generateMonoLinearGradient } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../../components/buttons/NudgeButton';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../consts/urls';

import style from '../style.module.scss';

const PER_PAGE = 9;
const CompanyList = ({ companies }) => {

    const total = Math.ceil(companies.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);
    const companiesToDisplay = [...companies].slice(start, end);
    const emptyCompanies = [];

    for (var i = 0; i < PER_PAGE - companiesToDisplay.length; i++) {
        emptyCompanies.push('');
    }

    const navigate = useNavigate();

    return (<>
        { companiesToDisplay.map((item) => (
            <Col xs={12} md={6} lg={4}>
                <Card className={style.offerCard}>
                    <div style={{ background: generateMonoLinearGradient(`#${item.backColour || "407962"}`), borderBottom: `2px solid ${item.backColour ? `#${item.backColour}` : `#407962`}` }} className={style.banner}/>
                    <Row style={{ minHeight: '165px' }}>
                        <Col sm={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={item.pictureUrl || DEFAULT_PICTURE} className={style.companyLogo} style={{ borderColor: `${item.backColour ? `#${item.backColour}` : `#407962`}` }}/>
                        </Col>
                        <Col sm={9} md={9}>
                            <h5 className={style.jobName}>{item.name}</h5>

                            <FullStatCardScaling
                                header=""
                                data={[
                                    { label: '', data: <i>{`"${item.visionStatement && item.visionStatement != " " ? item.visionStatement : "No statement given"}"`}</i>},
                                ]}
                                minHeight={100}
                            />
                        </Col>
                    </Row>
                    <NudgeButton color="success" onClick={() => navigate(`${URLS.DIRECTORY}/${item.id}`)} className={style.linkButton}>
                        View Profile
                    </NudgeButton>
                </Card>
            </Col>
        ))}
        {emptyCompanies.map(() => (
            <Col xs={12} md={4}>
                <Card className={style.emptyOffer} />
            </Col>
        ))}
        
        <AppPagination
            total={total}
            page={page}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            onClickPage={onClickPage}
            onClickFirst={onClickFirst}
            onClickLast={onClickLast}
        />
    </>)
}

export default CompanyList