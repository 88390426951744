import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Row, Col } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';

export const DragAndDropFileActive = () => {
    const theme = GetPageTheme();
    return (
        <div id={`card-sub-${theme}`} className="text-center">
            <div className="d-inline-block">
                <FontAwesomeIcon icon={['fad', 'arrow-alt-circle-up']} className={`mb-3 ${style.arrow}`} />
                <div className={style.title}>
                    <strong>Drop your file here</strong>
                </div>
                <div className={style.smallText}>Max size: 30MB</div>
            </div>
        </div>
    )
};

export const DragAndDropFileInactive = () => {
    const theme = GetPageTheme();
    return (
        <div id={`card-sub-${theme}`} className="text-center">
            <FontAwesomeIcon icon={['fad', 'arrow-alt-circle-up']} className={`mb-3 ${style.arrow}`} />
            <div className={style.title}>
                Drag & Drop <strong>your CV</strong>
            </div>
            <div className={style.smallText}>
                or <span className={style.browse}>browse your files</span>
            </div>
            <div className={style.smallText}>Max size: 30MB</div>
        </div>
    )
};

export const DragAndDropFileInactiveWithFile = ({ file }) => {
    const theme = GetPageTheme();
    return (
        <div id={`card-sub-${theme}`} className="text-center">
            <Row>
                <Col md={6} style={{ float: 'right' }}>
                    <FontAwesomeIcon icon={['far', 'file-pdf']} className={`mb-3 ${style.arrow}`} />
                </Col>
                <Col md={6} style={{ float: 'left' }}>
                    <div className={`mb-10 text-primary ${style.title}`}>
                        <strong>{file.name}</strong>
                    </div>
                    <div className={style.title}>
                        Drag & Drop <strong>your CV</strong>
                    </div>
                    <div className={style.smallText}>
                        or <span className={style.browse}>browse your files</span>
                    </div>
                    <div className={style.smallText}>Max size: 30MB</div>
                </Col>
            </Row>
            <div className="d-inline-block"></div>
        </div>
    )
};
