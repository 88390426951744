import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import DragAndDrop, { ACCEPT_DRAG_AND_DROP } from '../DragAndDrop';
import style from './style.module.scss';
import NudgeTooltip from '../NudgeTooltip';

const DragAndDropFileActive = () => (
    <div className={style.uploadButton}>
        <strong className="text-center">
            <FontAwesomeIcon icon={['far', 'plus']} className="mr-3" />
            Drop Here
        </strong>
    </div>
);

const DragAndDropFileInactive = ({ title = 'Upload', disabled, className }) => (
    <div className={`${style.uploadButton} ${disabled ? style.disabled : null} ${className}`}>
        <strong>
            { disabled ? 'File input unavailable' : title}
            { !disabled ? <FontAwesomeIcon icon={['fas', 'paperclip']} className={style.uploadIcon} /> : null }
        </strong>
    </div>
);

const DragAndDropFileInactiveWithFile = ({ file, disabled, index = 0, className }) => (
    <>
        <div className={`${style.uploadButtonFile} ${disabled ? style.disabled : null} ${style.tooltip} ${className}`}>
            { disabled ? 'File input unavailable' : <NudgeTooltip id={`fileinput-${index}`} label={"File"} icon={!!file ? "file-edit" : "file-plus"} iconOnly noModal>File Name: {file.name}</NudgeTooltip> }
        </div>
    </>
);

const UploadFileFieldInline = ({ form, field, title, accept, disabled, iconOnly, className }) => {
    const onDropFileAccepted = (files) => form.setFieldValue(field.name, files[0]);

    const onDropFileRejected = () => {};

    const inactiveComponent = field.value ? (
        <DragAndDropFileInactiveWithFile file={field.value} disabled={disabled} className={className} />
    ) : (
        <DragAndDropFileInactive title={title} disabled={disabled} className={className} />
    );

    const customRootProps = {
        className: style.dragAndDrop,
    };

    return (
        <>
            <DragAndDrop
                disabled={disabled}
                activeComponent={<DragAndDropFileActive />}
                inactiveComponent={inactiveComponent}
                accept={accept || ACCEPT_DRAG_AND_DROP.DOCS}
                onDropAccepted={onDropFileAccepted}
                onDropRejected={onDropFileRejected}
                customRootProps={customRootProps}
            />
        </>
    );
};

UploadFileFieldInline.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

export default UploadFileFieldInline;
