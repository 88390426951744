import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';

import AppInput from '../../../../components/AppInput';
import AvailabilityForm from '../../../../components/forms/AvailabilityForm';
import { FIELDS } from './validate';
import ClickButton from '../../../../components/buttons/NudgeButton';

function isDisabled(baseColor, sending) {
    return baseColor;
}

const AddTimesheetForm = ({ formik, sending }) => {
    const navigate = useNavigate();
    const onBack = () => navigate(-1);

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <FormGroup row className="mb-20">
                <Col>
                    <Label for={FIELDS.TOTAL}>Total Hours</Label>
                    <AppInput id={FIELDS.TOTAL} formik={formik} label="Total hours" />
                </Col>
                <Col>
                    <Label for={FIELDS.AMOUNT}>Total Amount (GBP)</Label>
                    <AppInput id={FIELDS.AMOUNT} formik={formik} label="Total amount (GBP)" />
                </Col>
            </FormGroup>

            <FormGroup>
                <AvailabilityForm formik={formik} showHours={false} showLabel={false} />
            </FormGroup>

            <div className="text-center mt-40">
                <ClickButton
                    color={isDisabled('danger', sending)}
                    sending={sending}
                    onClick={onBack}
                    size="lg"
                    className="mr-2"
                >
                    Back
                </ClickButton>
                <ClickButton color={isDisabled('success', sending)} sending={sending} type="submit" size="lg">
                    Save
                </ClickButton>
            </div>
        </Form>
    );
};

AddTimesheetForm.propTypes = {
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AddTimesheetForm;
