import * as Yup from 'yup';

import {
    FIELDS as CALENDAR_FIELDS,
    initialValues as calendarInitialValues,
} from '../../../../components/forms/AvailabilityForm/validation';
import { DATE_FORMAT, testCorrectDate } from '../../../../tools/formikHelpers';

export const FIELDS = {
    TOTAL: 'total',
    AMOUNT: 'amount',
    ...CALENDAR_FIELDS,
};

export const validationSchema = Yup.object({
    [FIELDS.TOTAL]: Yup.number()
        .typeError('This field should be a number.')
        .positive('This field should be a positive number.')
        .required('This field is required.'),
    [FIELDS.AMOUNT]: Yup.number()
        .typeError('This field should be a number.')
        .positive('This field should be a positive number.')
        .required('This field is required.'),
    [FIELDS.START]: Yup.string()
        .test('correct-date', 'Use correct format: dd/mm/yyyy', testCorrectDate)
        .matches(DATE_FORMAT, 'Use correct format: dd/mm/yyyy')
        .required('This field is required'),
    [FIELDS.FINISH]: Yup.string()
        .test('correct-date', 'Use correct format: dd/mm/yyyy', testCorrectDate)
        .matches(DATE_FORMAT, 'Use correct format: dd/mm/yyyy')
        .required('This field is required'),
});

export const initialValues = {
    [FIELDS.TOTAL]: '',
    [FIELDS.AMOUNT]: '',
    ...calendarInitialValues,
};
