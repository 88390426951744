import { URLS } from '../../../consts/urls';

export const ELEMENTS_USER = (user) => {
    return [
        { icon: ['far', 'columns'], name: 'sidebar.dashboard', url: URLS.DASHBOARD, altUrls: [] },
        { icon: ['far', 'id-badge'], name: 'sidebar.my_profile', url: URLS.PROFILE, altUrls: [] },
        { icon: ['far', 'film-alt'], name: 'sidebar.jobs_reel', url: URLS.JOBS_REEL, altUrls: [], unavailable: user?.parentId },
        {
            icon: ['fal', 'briefcase'],
            name: 'sidebar.my_applications',
            altUrls: [],
            subElements: [
                //{ icon: ['fal', 'file-signature'], name: 'Contracts', url: URLS.OFFERS, altUrls: [] },
                {
                    icon: ['fal', 'people-arrows'],
                    name: 'Interviews',
                    url: URLS.INTERVIEWS,
                    altUrls: [URLS.MEETING, URLS.INTERVIEW_CALENDAR],
                },
                { icon: ['fal', 'money-check-alt'], name: 'Payments', url: URLS.PAYMENTS, altUrls: [URLS.TIMESHEETS] },
                { icon: ['fal', 'lightbulb-exclamation'], name: 'Recommender', url: URLS.RECOMMENDER, altUrls: [] },
            ],
        },
        {
            icon: ['fal', 'users'],
            name: 'sidebar.community',
            altUrls: [],
            subElements: [
                { icon: ['far', 'address-book'], name: 'Directory', url: URLS.DIRECTORY, altUrls: [] },
                { icon: ['far', 'comment-alt-check'], name: 'Services', url: URLS.COMMUNITY, altUrls: [] },
                { icon: ['far', 'map'], name: 'Project Map', url: URLS.PROJECT_MAP, altUrls: [] },
                /*{ icon: ['far', 'calendar-alt'], name: 'Cleantech Collective', url: URLS.EVENTS, altUrls: [] },*/
            ],
        },
        {
            icon: ['fal', 'users-class'],
            name: 'sidebar.nudge_hub',
            altUrls: [],
            subElements: [
                { icon: ['fal', 'tree'], name: 'Forest', url: URLS.FOREST, altUrls: [] },
                { icon: ['fal', 'road'], name: 'Roadmap', url: URLS.FEEDBACK, altUrls: [] },
                { icon: ['fal', 'pound-sign'], name: 'Predictor', url: URLS.SALARY_PREDICTOR, altUrls: [] },
                { icon: ['fal', 'clipboard-list'], name: 'NEO Talent', url: URLS.NEO_TALENT, altUrls: [] },
            ],
        },
        {
            icon: ['fal', 'question-square'],
            name: 'sidebar.help',
            altUrls: [],
            subElements: [
                {
                    icon: ['fal', 'book-alt'],
                    name: 'Knowledge Base',
                    url: null,
                    altUrls: [],
                    externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/9984001529501-Help-for-candidates',
                },
                {
                    icon: ['fal', 'chalkboard'],
                    name: 'Learning Hub',
                    url: null,
                    altUrls: [],
                    externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/9983419238685-Learning-Hub',
                },
                {
                    icon: ['fal', 'envelope'],
                    name: 'Contact Us',
                    url: null,
                    altUrls: [],
                    externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/requests/new',
                },
            ],
        },
    ]
};

export const ELEMENTS_COMPANY = [
    {
        icon: ['far', 'columns'],
        name: 'sidebar.dashboard',
        url: URLS.COMPANY_DASHBOARD,
        altUrls: [URLS.CONTRACTOR],
    },
    { icon: ['far', 'id-badge'], name: 'sidebar.my_profile', url: URLS.COMPANY_PROFILE, altUrls: [] },
    { icon: ['fal', 'people-arrows'], name: 'Recruitment', url: URLS.COMPANY_INTERVIEWS, altUrls: [] },
    // eslint-disable-next-line max-len
    /* { icon: ['fal', 'money-check-alt'], name: 'Payments', url: URLS.COMPANY_PAYMENTS, altUrls: [URLS.COMPANY_CONTRACT_TIMESHEETS], },*/
    {
        icon: ['fal', 'users-class'],
        name: 'sidebar.nudge_hub',
        altUrls: [],
        subElements: [
            { icon: ['fal', 'chart-bar'], name: 'Statistics', url: URLS.COMPANY_STATISTICS, altUrls: [] },
            { icon: ['fal', 'tree'], name: 'Forest', url: URLS.FOREST, altUrls: [] },
            { icon: ['fal', 'head-vr'], name: 'Assessments', url: URLS.COMPANY_VR_BOOKING, altUrls: [] },
            { icon: ['fal', 'road'], name: 'Roadmap', url: URLS.FEEDBACK, altUrls: [] },
            { icon: ['fal', 'pound-sign'], name: 'Predictor', url: URLS.SALARY_PREDICTOR, altUrls: [],  },
        ],
    },
    {
        icon: ['fal', 'question-square'],
        name: 'sidebar.help',
        altUrls: [],
        subElements: [
            {
                icon: ['fal', 'book-alt'],
                name: 'Knowledge Base',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/9984002432029-Help-for-companies',
            },
            {
                icon: ['fal', 'chalkboard'],
                name: 'Learning Hub',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/9983419238685-Learning-Hub',
            },
            {
                icon: ['fal', 'envelope'],
                name: 'Contact Us',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/requests/new',
            },
        ],
    },
];

export const ELEMENTS_ADMIN = [
    { icon: ['far', 'columns'], name: 'sidebar.dashboard', url: URLS.ADMIN_DASHBOARD, altUrls: [] },
    {
        icon: ['fas', 'hands-helping'],
        url: URLS.ADMIN_OFFERS,
        name: 'Jobs',
        altUrls: [URLS.ADMIN_CONTRACTS, URLS.ADMIN_CONTRACTS_EDIT],
    },
    { icon: ['fas', 'book'], url: URLS.ADMIN_APPROVAL, name: 'Registered Interest', altUrls: [] },
    { icon: ['fal', 'clipboard-list'], name: 'NEO Talent', url: URLS.ADMIN_NEODASH, altUrls: [] },
    {
            icon: ['fas', 'users-cog'], altUrls: [], name: 'Users', subElements: [
                { icon: ['fad', 'users'], url: URLS.ADMIN_CONTRACTORS, name: 'Talent', altUrls: [] },
                { icon: ['fad', 'building'], url: URLS.ADMIN_COMPANIES, name: 'Companies', altUrls: [] },
                { icon: ['fad', 'user-chart'], url: URLS.ADMIN_RECRUITERS, name: 'Recruiters', altUrls: [] },
            ]
    },
    { icon: ['fad', 'dreidel'], url: URLS.TESTING_GROUND, name: 'Admin', altUrls: [] },
    
];

export const ELEMENTS_RECRUITER = [
    {
        icon: ['far', 'columns'],
        name: 'sidebar.dashboard',
        url: URLS.RECRUITER_DASHBOARD,
        altUrls: [],
    },
    {
        icon: ['fal', 'question-square'],
        name: 'sidebar.help',
        altUrls: [],
        subElements: [
            {
                icon: ['fal', 'book-alt'],
                name: 'Knowledge Base',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/12047436721949-Help-for-Recruiters',
            },
            {
                icon: ['fal', 'chalkboard'],
                name: 'Learning Hub',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/categories/9983419238685-Learning-Hub',
            },
            {
                icon: ['fal', 'envelope'],
                name: 'Contact Us',
                url: null,
                altUrls: [],
                externalUrl: 'https://help.nudgetalentlab.com/hc/en-gb/requests/new',
            },
        ],
    },
];
