import React from 'react';

import useMobile from '../../hooks/useMobile';

import style from './style.module.scss';

const NudgeSwiper = ({ width, height, borderRadius, gap }) => {
    const { isMobileWide } = useMobile();

    return (
        <div style={ isMobileWide ? { width: `${width.mobile}`, height: `${height.mobile}`, marginBottom: gap, borderRadius: borderRadius } : { width: `${width.desktop}`, height: `${height.desktop}`, marginBottom: gap, borderRadius: borderRadius }} className={`${style.swiping}`}>
            <div className={`${style.activity}`}/>
        </div>
    )
}

export default NudgeSwiper;