import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from '../style.module.scss';

const htmlString = '<div id="container">Hello</div>';

const ReadyPlayerMeFrame = ({ setUrl }) => {
    const subdomain = 'nudge-talent-lab'; // Replace with your custom subdomain
    const myRef = React.useRef();
    var frame;

    function subscribe(event) {
        const json = parse(event);

        if (json?.source !== 'readyplayerme') {
            return;
        }

        // Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === 'v1.frame.ready') {
            if (frame.contentWindow) {
                frame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**',
                    }),
                    '*',
                );
            }
        }

        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
            console.log(`Avatar URL: ${json.data.url}`);
            setUrl(json.data.url);
        }

        // Get user id
        if (json.eventName === 'v1.user.set') {
            console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
        }
    }

    function parse(event) {
        try {
            return JSON.parse(event.data);
        } catch (error) {
            return null;
        }
    }

    React.useEffect(() => {
        if (myRef.current) {
            myRef.current.src = `https://${subdomain}.readyplayer.me/avatar?frameApi&bodyType=fullbody`;

            frame = myRef.current;

            window.addEventListener('message', subscribe);
            document.addEventListener('message', subscribe);
        }
    }, []);

    return (
        <div>
            <iframe
                src="empty"
                ref={myRef}
                id="frame"
                className={style.readyFrame}
                allow="camera *; microphone *; clipboard-write"
            ></iframe>
        </div>
    );
};

export default ReadyPlayerMeFrame;
