import React, { useState, useContext } from 'react';
import { Formik } from 'formik';

import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';

import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { authFetcher, prepareAuthHeaders } from '../../../../api/tools';
import { API_DASHBOARD_COMPANY, URLS } from '../../../../consts/urls';
import { ADMIN } from '../../../../consts/userRoles';
import FeedbackModal from './FeedbackModal';
import CloseModal from './CloseModal';

import { initialValues, validationSchema } from './FeedbackModal/validation';

export const CompanyAdminModals = ({ toggle, showModal, roleUrl }) => {
    const navigate = useNavigate();
    const { userRoles } = useContext(AuthContext);
    const { id } = useParams();
    const { data } = useSWR(`${roleUrl}/Offers/${id}`, authFetcher, { refreshInterval: 0 });

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const toggleFeedback = () => setShowFeedbackModal(!showFeedbackModal);

    const getRedirectUrl = (roles) => (roles.includes(ADMIN) ? URLS.ADMIN_DASHBOARD : URLS.COMPANY_DASHBOARD);

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            onSendFeedback(values);
        },
    };

    var onCloseOffer = () => {
        if (data.offerStatus === 'Awarded') {
            toggle();
            onSendFeedback();
        } else {
            toggle();
            toggleFeedback();
        }
    };

    const onClickClose = async (value) => {
        let headers = await prepareAuthHeaders();
        const user_headers = { reason: value };
        headers = { ...headers, ...user_headers };
        await fetch(`${API_DASHBOARD_COMPANY}/Offers/${id}/Close`, {
            headers: { 'Content-Type': 'application/json', ...headers },
            method: 'POST',
        });
    };

    const onSendFeedback = (values) => {
        const url = getRedirectUrl(userRoles);

        if (values) {
            if (values.feedback === 'Other') {
                onClickClose(values.other);
            } else {
                onClickClose(values.feedback);
            }
        } else {
            onClickClose('Offer has been awarded to other candidate.');
        }

        navigate(url);
        toggleFeedback();
    };

    return (
        <>
            <CloseModal isOpen={showModal} toggle={toggle} onDelete={onCloseOffer} />
            <Formik {...props}>
                {(formik) => (
                    <FeedbackModal
                        isOpen={showFeedbackModal}
                        toggle={toggleFeedback}
                        onSend={onSendFeedback}
                        formik={formik}
                    />
                )}
            </Formik>
        </>
    );
};

export default CompanyAdminModals;
