import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

import SignInPageTemplate from '../../components/SignInPageTemplate';
import { COMPANY, USER } from '../../consts/userRoles';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';

export const Events = () => {
    return (
        <SignInPageTemplate>
            <Card>
                <h3>Nudge Events</h3>
                <CardBody>
                    <p>Blah blah blah events</p>
                </CardBody>
            </Card>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Events, [USER, COMPANY]);
