import React from 'react';
import { Row, Col, Button, Card, Input, Label } from 'reactstrap';

import style from './style.module.scss';
import AppInput from '../../AppInput';
import { onClickAddToArray } from '../../../tools/formikHelpers';
import { Field } from 'formik';
import AutocompleteInput from '../../AutocompleteInput';
import RequiredBox from '../InputFormWithRequired/RequiredBox';
import { GetPageTheme, capitaliseFirstLetter } from '../../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../buttons/NudgeButton';
import NudgeTabs from '../../NudgeTabs';
import useMobile from '../../../hooks/useMobile';
import NudgeButton from '../../buttons/NudgeButton';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

const NewArrayFieldForm = ({
    formik,
    addValue,
    children,
    field,
    index,
    fieldLimit = 25,
    showRemove = true,
    labelGap = false,
    tabObj,
    override
}) => {
    const v = formik.values;
    const MAX = fieldLimit;
    
    React.useEffect(() => {
        try {
            var arr = v[field];
            arr = arr.filter((i) => i != '');
            formik.setFieldValue(field, arr);
        } catch (e) {}
    }, []);

    if (!children) return null;

    function addSkill() {
        var arr = v[field];
        arr.push(addValue);

        formik.setFieldValue(field, arr);
    }

    function removeSkill(index) {
        var arr = v[field];

        arr.splice(index, 1);

        formik.setFieldValue(field, arr);
        formik.validateForm();
    }

    const { isMobileWide } = useMobile();

    return (
        <div className={style.formContainer}>
            {index == 0 ? (<>
                <Row className={style.buttonHeader}>
                    <Col sm={6} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontSize: '1.3rem' }}>Limit: {`${(override || v[field].length)}/${MAX}`}</h5>
                        { !v[field].length && <i>Nothing added yet</i> }
                    </Col>
                    <Col xs={12} md={6}>
                        {/* <div class="flexy" style={{ columnGap: '6px' }}>
                            <ClickButton disabled={(override || v[field].length) >= MAX} tabindex="-1" className={style.addButton} color="success" onClick={() => { addSkill();}}>
                                +
                            </ClickButton>
                            <ClickButton disabled={!(override || v[field].length)} tabindex="-1" className={style.addButton} color="danger" onClick={() => { formik.setFieldValue(field, []) }}>
                                Clear All
                            </ClickButton>
                        </div> */}
                        <NudgeButtonGroup
                            buttons={[
                                { label: '+', disabled: (override || v[field].length) >= MAX, onClick: addSkill },
                                { label: 'Clear All', disabled: !(override || v[field].length), color: 'danger', onClick: () =>  formik.setFieldValue(field, []) },
                            ]}
                        />
                    </Col>
                </Row>
                { !!tabObj && <Row>
                    <Col md={12}>
                        <NudgeTabs
                            tabs={formik.values[field].map((item, index) => ({ id: index, label: `${item.name || (index + 1)}`, condition: true }))}
                            selected={tabObj?.selected}
                            setTab={tabObj?.setTab}
                            buttons
                        />
                    </Col>
                </Row> }
            </>) : null}

            <>
                { (tabObj?.selected == index || !tabObj) && <Row className={style.formRow} style={{ borderTop: index !== 0 ? '1px solid rgba(0,0,0,0.2)' : '' }}>
                    <Col sm={10} md={10}>
                        <Row>
                            {children}
                        </Row>
                    </Col> 
                    { showRemove ? <Col xs={12} sm={2} md={2}>
                        { labelGap ? <Label style={{ visibility: 'hidden', paddingTop: '6px' }}>Minus</Label> : null }
                        <NudgeButton
                            className={style.removeButton}
                            color="danger"
                            onClick={() => removeSkill(index)}
                            tabindex="-1"
                        >
                            -
                        </NudgeButton>
                        
                    </Col> : null}
                </Row> }

            </>

            

             
        </div>
    );
};

export default NewArrayFieldForm;
