import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';

import style from './style.module.scss';
import { Helmet } from 'react-helmet-async';

import logo_full from '../../../assets/images/nudge-logo-chalk-full.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProfileCompletion, getTasks } from '../../../consts/tasks';
import NotificationsModal, { countNew } from '../SignInNavbar/NotificationsModal';
import SettingsModal from '../SignInNavbar/SettingsModal';
import Checklist from '../SignInNavbar/Checklist';
import { getApiByRole } from '../../../consts/userRoles';
import LogOutModal from '../SignInNavbar/LogOutModal';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { API_DASHBOARD_COMPANY, URLS } from '../../../consts/urls';
import { getPageName } from '../SignInNavbar';
import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';
import { useTranslation } from 'react-i18next';
import useMobile from '../../../hooks/useMobile';
import NudgeButton from '../../buttons/NudgeButton';
import { CERTIFICATE_EXPIRY_THRESHOLD, ResolveDaysRemaining } from '../../pages/GenericCompanyProfile/HelperComponents';
import useSWR from 'swr';
import { NO_REFRESH_INTERVAL, authFetcher } from '../../../api/tools';

export const isActiveStep = (url) => {
    return window.location.href.includes(url);
};

const ICON_STYLES = {
    SELECTED: 'fas',
    NEUTRAL: 'far'
}

const FOLDER_ICONS = {
    OPEN: 'folder-open',
    CLOSED: 'folder'
}

const Element = ({ icon, altIcon, url, altUrls, name, subElements, sub, externalUrl, theme, collapsed, onClick, disabled }) => {
    
    const navigate = useNavigate();
    const allUrls = [...altUrls, url];
    var isActive = allUrls.find(isActiveStep);
    const { t } = useTranslation();

    if(subElements && !isActive) {
        subElements.forEach((el) => {
            if(isActiveStep(el.url)) {
                isActive = true;
            }
        })
    }

    const [iconStyle, setIconStyle] = React.useState(isActive ? ICON_STYLES.SELECTED : ICON_STYLES.NEUTRAL);

    const [folderOpen, setFolderOpen] = React.useState(isActive);
    const [folderIcon, setFolderIcon] = React.useState(isActive ? FOLDER_ICONS.OPEN : FOLDER_ICONS.CLOSED);

    const iconFunction = (into) => {
        if(into) {
            setIconStyle(ICON_STYLES.SELECTED)
            setFolderIcon(FOLDER_ICONS.OPEN);
        } else {
            if(isActive) setIconStyle(ICON_STYLES.SELECTED)
            else setIconStyle(ICON_STYLES.NEUTRAL)

            if(!folderOpen) {
                setFolderIcon(FOLDER_ICONS.CLOSED);
            }
        }
    }

    const openUrl = () => {
        if (externalUrl) {
            window.open(externalUrl, '_blank', 'noreferrer');
        } else if(onClick) {
            onClick();  
        } else {
            navigate(url);
        }
    };

    const menuItemProps = {
        onMouseOver: () => iconFunction(true), 
        onMouseOut: () => iconFunction(false),
        icon: !altIcon ? <h5>
            <FontAwesomeIcon className={isActive ? style.iconActive : style.icon} icon={[iconStyle, icon[1]]}/>
        </h5> : altIcon
    }

    React.useEffect(() => {
        if(subElements && collapsed) {
            setFolderOpen(false);
        }
    }, [collapsed])

    return !subElements ? (
        <MenuItem disabled={disabled} id={sub ? `sub-menu-item-${theme}` :`menu-item-${theme}`} {...menuItemProps} className={`${sub ? style.subMenuItem : style.menuItem}`} style={{ marginLeft: sub ? "6px" : "0" }} onClick={openUrl}>
            <h5 className={isActive ? style.elementActive : style.element}>{t(name)}</h5>
        </MenuItem>
    ) : (
        <SubMenu id={`menu-item-${theme}`} open={folderOpen} onClick={() => setFolderOpen(!folderOpen)} {...menuItemProps} className={`${isActive ? style.menuItemSelected : style.menuItem}`} label={<h5 className={style.usernameContainer}><h5 className={`${isActive ? style.subMenuElementActive : style.subMenuElement}`}>{t(name)}</h5><FontAwesomeIcon onClick={() => setFolderOpen(!folderOpen)} className={`${isActive ? style.folderIconActive : style.folderIcon}`} icon={[iconStyle, folderIcon]}/></h5>}>
            { subElements.map((subEl, index) => <>
                { index != subElements.length? <div className={style[`barrier-folder-${theme}`]}/> : null }
                <Element theme={theme} {...subEl} sub />
            </>)}
        </SubMenu>
    )
}

function getNewNotifications(user) {
    if(user.notifications) {
        return countNew(user.notifications)
    } else {
        return 0;
    }
}

const NewSidebar = ({ user, mutate, userRoles, elements, customHeader, setScroll, leftHand, sending }) => {
    const { logoutUser } = React.useContext(AuthContext);
    const { theme, modalOpen } = React.useContext(ThemeContext);
    
    const navigate = useNavigate();
    const { collapseSidebar, collapsed } = useProSidebar();

    const tasks = userRoles.includes("User") ? getTasks(user) : null;
    const profileCompletion = tasks ? getProfileCompletion(tasks) : [];
    const newNotifications = getNewNotifications(user);

    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [notificationsOpen, setNotificationsOpen] = React.useState(false);
    const [profileCompletionOpen, setCompletionOpen] = React.useState(false);
    const [logOutOpen, setLogOutOpen] = React.useState(false);

    const toggleSettings = () => setSettingsOpen(!settingsOpen);
    const toggleNotifications = () => setNotificationsOpen(!notificationsOpen);
    const toggleCompletion = () => setCompletionOpen(!profileCompletionOpen);
    const toggleLogOut = () => setLogOutOpen(!logOutOpen);

    const [folderOpen, setFolderOpen] = React.useState(false);
    const [iconStyle, setIconStyle] = React.useState('far');

    const { t } = useTranslation();
    const { isMobileWide, isMobileSlim } = useMobile();

    const [expiringJobs, setExpiringJobs] = React.useState([]);
    const { data: offers } = useSWR(userRoles.includes("Company") ? `${API_DASHBOARD_COMPANY}/Offers` : '', authFetcher, NO_REFRESH_INTERVAL);
    React.useEffect(() => {
        if(offers) {
            let localJobs = [];
            offers.forEach((item, index) => {
                if(ResolveDaysRemaining(item).daysLeft == 1) {
                    localJobs.push(item);
                }
            })
            setExpiringJobs(localJobs)
        }
    }, [offers]);

    let expired = 0;
    if(userRoles.includes('User')) {
        user.certificates.forEach((item) => { 
            if(item.daysUntilExpiry <= CERTIFICATE_EXPIRY_THRESHOLD && item.expiryDate) expired++
        })
    }

    var xDown = null;
    var yDown = null;
    function getTouches(evt) {
        // browser API || jQuery
        return evt.touches || evt.originalEvent.touches;
    }


    document.removeEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchstart', handleTouchStart);

    document.removeEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchmove', handleTouchMove);

    function handleTouchStart(evt) {
        if(modalOpen) return;

        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if ( xDown == null || yDown == null ) {
            return;
        }
        
        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
                                                                             
        if(evt.touches[0].target.id != 'slider-comp') {
            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    collapseSidebar(leftHand ? false : true);
                    setScroll(leftHand ? true : false);
                } else {
                    collapseSidebar(leftHand ? true : false);
                    setScroll(leftHand ? false : true);
                }                   
            }
        }

        /* reset values */
        xDown = null;
        yDown = null;
    };

    function logoutFunction() {
        document.body.style.overflow = 'scroll';
        
        logoutUser();
        toggleLogOut();
    }

    const profileMenuProps = [
        {
            name: 'sidebar.homepage',
            icon: ['far', 'home'],
            onClick: () => { document.body.style.overflow = 'scroll'; navigate(URLS.HOME);  },
            condition: true,
            className: style.subMenuItem
        },
        {
            name: ' Incomplete',
            altIcon: <h5 className={style.icon}>{`${profileCompletion}%`}</h5>,
            onClick: () => toggleCompletion(),
            condition: profileCompletion != 100 && userRoles.includes('User'),
            className: style.subMenuItem
        },
        {
            name: `${ newNotifications ? " New Notifications" : expired || expiringJobs?.length ? "Account Alerts" : " New Alerts" }`,
            altIcon: <h5 className={style.notificationAmount}>{`${ sending ? '...' : newNotifications ? newNotifications : expired || expiringJobs?.length ? "!" : 0 }`}</h5>,
            onClick: () => toggleNotifications(),
            condition: true,
            className: style.subMenuItem,
            disabled: !!sending
        },
        {
            name: 'Account Settings',
            icon: ['far', 'user-cog'],
            onClick: () => navigate(URLS.SETTINGS),
            condition: true,
            className: style.subMenuItem
        },
        {
            name: 'sidebar.logout',
            icon: ['far', 'sign-out'],
            onClick: () => toggleLogOut(),
            condition: true,
            className: style.logOut
        },
    ]

    function ResolvePhotoStyle() {
        if(expired > 0 || newNotifications > 0) {
            if(collapsed) {
                if(folderOpen) {
                    return style.photoContainerOpen;
                } else {
                    return style.photoContainerOpen;
                }
            } else {
                if(folderOpen) {
                    return style.photoContainerClosed;
                } else {
                    return style.photoContainerOpen;
                }
            }
        }
    }

    const getPhotoIcon = () => { 

        const text = `${sending ? '...' : newNotifications ? newNotifications : expired || expiringJobs?.length ? "!" : null}`

        return (
            <div className={ResolvePhotoStyle()}>
                <img src={user.photo} className={style.photo}/>
                { expired > 0 || expiringJobs?.length || newNotifications > 0 ? folderOpen ? collapsed ? <h5 className={style.alert}>{ text }</h5> : null : <h5 className={style.alert}>{ text }</h5> : null }
            </div>
        )
    }

    function getUserName(user) {
        if(!user) return "Loading";

        if(user.name) {
            if(user.subname) {
                return user.subname;
            }
            
            return user.name;
        }

        return "Loading";
    }

    function getSidebarWidth() {
        if(isMobileWide) {
            if(window.screen.width <= 525) {
                return `${((window.screen.width / 100) * 85)}px`
            } else {
                return '360px'
            }
        } else {
            return '340px'
        }
    }

    React.useEffect(() => {
        if(collapsed) {
            setFolderOpen(false);
        }
    }, [collapsed]);

    
    const [isBurger, setIsBurger] = React.useState();
    React.useEffect(() => {
        setIsBurger(Math.floor(Math.random() * 100));
    }, []);

    React.useEffect(() => {
        collapseSidebar(true);
    }, [window.screen.width])

    function getHamburgerStyle() {
        if(!!collapsed) {
            return leftHand ? style.hamburgerMobileRight : style.hamburgerMobile
        }

        return leftHand ? style.hamburgerMobileRightExtended : style.hamburgerMobileExtended;
    }

    const BURGER_ODDS = 2.5; // percentage chance the burger will grace you with its presence. all hail burgy.

    
    return (<>
        <Helmet>
            <title>
                {newNotifications ? `(${newNotifications})` : ""} {customHeader ? `${customHeader} - Nudge Energy Talent Lab` : getPageName()} - Nudge Energy Talent Lab
            </title>
        </Helmet>
        
        <SettingsModal isOpen={settingsOpen} mutate={mutate} toggle={toggleSettings} />

        <NotificationsModal expiringJobs={expiringJobs} expiredCertificates={expired} url={getApiByRole(userRoles)} isOpen={notificationsOpen} mutate={mutate} toggle={toggleNotifications} notifications={user?.notifications} userMutating={sending}/> 

        { userRoles.includes('User') ? 
            <Checklist user={user} mutate={mutate} tasks={tasks} completion={profileCompletion} toggle={toggleCompletion} isOpen={profileCompletionOpen}/> 
        : null }

        <LogOutModal toggle={toggleLogOut} isOpen={logOutOpen} logout={logoutFunction}/>
        
        <div onMouseEnter={() => collapseSidebar(!collapsed)} onClick={() => { collapseSidebar(!collapsed); setScroll(collapsed); }} className={collapsed ? style.blockerClosed : style.blocker}/>
        
        <Sidebar id={`sidebar-${theme}`} style={{ borderLeft: leftHand ? theme == 'light' ? '1px solid #274237' : '1px solid #6B728E' : '' }} className={style.sidebar} width={getSidebarWidth()} collapsedWidth={!isMobileWide ? "90px" : "0px"} defaultCollapsed={true} onMouseEnter={() => collapseSidebar(false)}>
            { isMobileWide ? <div id={`sidebar-${theme}`} className={getHamburgerStyle()}>
                <FontAwesomeIcon icon={['far', expired || expiringJobs?.length <= 0 ? isBurger < BURGER_ODDS ? 'cheeseburger' : 'bars' : 'exclamation-circle']} onClick={() => { collapseSidebar(!collapsed); setScroll(collapsed); }}/>
            </div> : null }

            <Menu>
                <img src={logo_full} className={style.nudgeLogo} onClick={() => { navigate(elements[0].url); }}/>
                
                <SubMenu id={`menu-item-${theme}`} className={`${style.menuItem}`} onMouseOver={() => setIconStyle(ICON_STYLES.SELECTED)} onMouseOut={() => setIconStyle(ICON_STYLES.NEUTRAL)} open={folderOpen} onClick={() => setFolderOpen(!folderOpen)} icon={getPhotoIcon()} label={<h5 className={style.usernameContainer}><div className={style.userName}>{getUserName(user)}</div><FontAwesomeIcon onClick={() => setFolderOpen(!folderOpen)} className={style.folderIcon} icon={[iconStyle, folderOpen ? 'folder-open' : 'folder']}/></h5>}>
                    {profileMenuProps.map((props, index) => <>
                        { index != profileMenuProps.length && props.condition ? <div className={style[`barrier-folder-${theme}`]}/> : null }
                        { props.condition ? <Element theme={theme} name={t(props.name)} altUrls={[]} id={`sub-menu-item-${theme}`} {...props} close={() => setScroll(false)}></Element> : null }
                    </>)}
                </SubMenu>
                <div className={style[`barrier-${theme}`]}/>

                {elements.map((el, index) => !el.unavailable ? <>
                    <Element index={index} theme={theme} collapsed={collapsed} {...el} close={() => setScroll(false)}/>
                    { index != elements.length ? <div className={style[`barrier-${theme}`]}/> : null }
                </> : null )}

                {/* icon, url, altUrls, name, subElements, sub, externalUrl, theme, collapsed */}
                <Element
                    theme={theme}
                    icon={['far', 'cogs']}
                    onClick={() => setSettingsOpen(true)}
                    altUrls={[]}
                    name={t('sidebar.settings')}
                />

            </Menu>
        </Sidebar>
    </>)
}

export default NewSidebar;