import { useEffect, useState } from 'react';

const slimMobileWidth = 525;
const wideMobileWidth = 850;

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useMobile() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) ) {
        return { isMobileWide: true, isMobileSlim: windowDimensions.width <= slimMobileWidth }
    }

    return { 
        isMobileWide: windowDimensions.width > slimMobileWidth && windowDimensions.width <= wideMobileWidth , 
        isMobileSlim: windowDimensions.width <= slimMobileWidth
    };
}
