import * as Yup from 'yup';

export const DEFAULT_LABELS = [
    'It doesn’t suit my availability.',
    'I don’t feel it is a relevant match for my skill set.',
    'I don’t feel it is a relevant match for my experience.',
    'The rate range is unrealistic for this contract.',
];

export const OTHER_LABEL = 'Other';

export const FIELDS = {
    REASON: 'reason',
    OTHERS: 'otherDescription',
};

export const validationSchema = Yup.object({
    [FIELDS.REASON]: Yup.string().required('One of these fields is required'),
    [FIELDS.OTHERS]: Yup.string().when(FIELDS.REASON, {
        is: (label) => label === OTHER_LABEL,
        then: Yup.string().required('This field is required'),
    }),
});

export const initialValues = {
    [FIELDS.REASON]: '',
    [FIELDS.OTHERS]: '',
};
