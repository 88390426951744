import React from 'react';
import NudgeButton from '../buttons/NudgeButton';
import { PaginationItem, PaginationLink } from 'reactstrap';
import style from './style.module.scss';

const PageLink = ({ number, total, activePage, onClickPage }) => {
    return (<>
        <NudgeButton selected={number == activePage} className={style.pageItem} color="success" onClick={() => onClickPage(number)}>{ number + 1 }</NudgeButton>
    </>
        
    )
};

export default PageLink;
