import { Field, FieldArray } from 'formik';
import React from 'react';
import { Col, Input, Label, Button } from 'reactstrap';
import useSWR from 'swr';

import moment from 'moment';

import { fetcher } from '../../../api/tools';
import { API_PROFILE } from '../../../consts/urls';
import AutocompleteInput from '../../AutocompleteInput';
import UploadFileFieldInline from '../../UploadFileFieldInline';
import NewArrayFieldForm from '../NewArrayFieldForm';
import SkipAndSubmit from '../SkipAndSubmit';
import { FIELDS } from './validation';

import style from './style.module.scss';
import ClickButton from '../../buttons/NudgeButton';
import NudgeTabs from '../../NudgeTabs';

const getErrorForField = (formik, index, field) =>
    formik.touched[FIELDS.CERTIFICATIONS] &&
    formik.touched[FIELDS.CERTIFICATIONS][index] &&
    formik.errors[FIELDS.CERTIFICATIONS] &&
    formik.errors[FIELDS.CERTIFICATIONS][index] &&
    formik.errors[FIELDS.CERTIFICATIONS][index][field];

const ErrorMessage = ({ formik, index, field }) => (
    <div className="custom-input-error">{getErrorForField(formik, index, field)}</div>
);

const ArrayField = ({ index, formik, tabObj, override }) => {
    const { data: suggestions } = useSWR(`${API_PROFILE}/Certificate`, fetcher, { refreshInterval: 0 });

    let hasError = null;
    if (formik.errors[FIELDS.CERTIFICATIONS]) {
        if (formik.errors[FIELDS.CERTIFICATIONS][index] && formik.submitCount > 0) {
            hasError = formik.errors[FIELDS.CERTIFICATIONS][index];
        }
    }

    const setExpiryDate = (value, index) => {
        let expiry = value;

        // Check if the date format is valid (YYYY-MM-DD)
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(expiry)) {
            return;
        }

        formik.setFieldValue(`${FIELDS.CERTIFICATIONS}.${index}.expireDate`, expiry);
    };

    const body = (
        <>
            <Col xs={12} md={12} lg={8}>
                <h6 className={style.headers}>Name</h6>
                <Field
                    name={`${FIELDS.CERTIFICATIONS}.${index}.name`}
                    component={AutocompleteInput}
                    suggestions={suggestions}
                    placeholder="Name"
                    className={getErrorForField(formik, index, 'name') ? '' : 'is-invalid'}
                />
                <h5 className={style.error}>{hasError ? hasError.name : null}</h5>
            </Col>

            <Col xs={12} md={12} lg={4}>
                <h6 className={style.headers}>File</h6>
                <Field component={UploadFileFieldInline} name={`${FIELDS.CERTIFICATIONS}.${index}.file`} />
                <h5 className={style.error}>{hasError ? hasError.file : null}</h5>
            </Col>

            <Col className={style.dateField} xs={12} md={12} lg={12}>
                <h6 className={style.headers}>Expiry Date</h6>
                <Input
                    id={`expire-${index}`}
                    value={moment(formik.values[FIELDS.CERTIFICATIONS][index].expireDate).format(`YYYY-MM-DD`)}
                    type={'date'}
                    onChange={(e) => setExpiryDate(e.target.value, index)}
                />
                <h5 className={style.error}>{hasError ? hasError.expireDate : null}</h5>
            </Col>
        </>
    );

    const addValue = { name: '', expireDate: '' };
    return (
        <NewArrayFieldForm
            formik={formik}
            index={index}
            field={FIELDS.CERTIFICATIONS}
            addValue={addValue}
            fieldLimit={8}
            showRemove={!formik.values[FIELDS.CERTIFICATIONS][index].edit}
            showAdd={true}
            tabObj={tabObj}
            override={override}
            labelGap
        >
            {body}
        </NewArrayFieldForm>
    );
};

const CertificatesFormBody = ({ formik, skipCurrentStep, alwaysShowMinus, countOverride, reset }) => {

    const [selectedTab, setSelectedTab] = React.useState(0);
    const tabObj = { setTab: setSelectedTab, selected: selectedTab }

    React.useEffect(() => {
        if(!formik.values[FIELDS.CERTIFICATIONS][selectedTab]) {
            setSelectedTab(selectedTab - 1 < 0 ? 0 : selectedTab - 1)
        }
    }, [formik.values[FIELDS.CERTIFICATIONS].length])

    return (
        <>
            <FieldArray name={FIELDS.CERTIFICATIONS}>
                {(arrayHelpers) => (
                    <>
                        {formik.values[FIELDS.CERTIFICATIONS].map((_, index) => (
                            <ArrayField
                                key={index}
                                index={index}
                                arrayHelpers={arrayHelpers}
                                formik={formik}
                                alwaysShowMinus={alwaysShowMinus}
                                reset={reset}
                                tabObj={tabObj}
                                override={countOverride}
                            />
                        ))}

                        {skipCurrentStep ? <SkipAndSubmit skipCurrentStep={skipCurrentStep} formik={formik} /> : null}
                    </>
                )}
            </FieldArray>
        </>
    )
};
export default CertificatesFormBody;
