import PropTypes from 'prop-types';
import React from 'react';

import DragAndDrop, { ACCEPT_DRAG_AND_DROP } from '../index';
import { DragAndDropFileActive, DragAndDropFileInactive, DragAndDropFileInactiveWithFile } from './DragAndDropScreens';
import style from './style.module.scss';

const UploadResumeField = ({ form, field }) => {
    const onDropFileAccepted = (files) => form.setFieldValue(field.name, files[0]);

    const onDropFileRejected = () => {};

    const inactiveComponent = field.value ? (
        <DragAndDropFileInactiveWithFile file={field.value} />
    ) : (
        <DragAndDropFileInactive />
    );

    const customRootProps = {
        className: style.dragAndDrop,
    };

    return (
        <DragAndDrop
            activeComponent={<DragAndDropFileActive />}
            inactiveComponent={inactiveComponent}
            accept={ACCEPT_DRAG_AND_DROP.DOCS}
            onDropAccepted={onDropFileAccepted}
            onDropRejected={onDropFileRejected}
            customRootProps={customRootProps}
        />
    );
};

UploadResumeField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

export default UploadResumeField;
