import PropTypes from 'prop-types';
import React, { useState } from 'react';

import style from './style.module.scss';

export const ACCEPT_TOOLTIP = 'accept';

export const useOfferTooltip = () => {
    const [isOfferTooltipVisible, showOfferTooltip] = useState(false);
    return { isOfferTooltipVisible, showOfferTooltip };
};

export const OfferTooltip = ({ visible, type, message }) => {
    if (!visible) {
        return null;
    }

    return <div className={type === ACCEPT_TOOLTIP ? style.acceptTooltip : style.rejectTooltip}>
        {message} - {}
    </div>;
};

OfferTooltip.propTypes = {
    visible: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default OfferTooltip;
