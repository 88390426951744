import React from 'react';
import useSWR from 'swr';

import { authFetcher, NEVER_REFRESH } from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

import { Row, Col } from 'reactstrap';

const TestingGround = () => {
    const URL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/config`;
    const { data, mutate } = useSWR(URL, authFetcher, NEVER_REFRESH);

    React.useEffect(() => {}, [data]);

    return (
        <>
            <Row>
                {data ? (
                    data.attributeOptions.map((attr) => (
                        <Col md={4}>
                            <h5>{attr.type}</h5>
                            <Row>
                                <Col md={6}>
                                    <p>
                                        <b> Reuse Strategy </b>
                                    </p>
                                    {attr.reuseStrategy.map((item) => (
                                        <Row>
                                            <Col>{item}</Col>
                                        </Row>
                                    ))}
                                </Col>
                                <Col md={6}>
                                    <p>
                                        <b> Similarity Types </b>
                                    </p>
                                    {attr.similarityTypes.map((item) => (
                                        <Row>
                                            <Col>{item}</Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                    ))
                ) : (
                    <Loader />
                )}
            </Row>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
