import { Form } from 'formik';
import React from 'react';

import UploadModal from '../../../../components/ContractorInformation/ContractorInformationEdit/UploadModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Alert } from 'reactstrap';
import AppInput from '../../../../components/AppInput';
import { FIELDS } from './validate';
import Loader from '../../../../components/Loader';

import style from './style.module.scss';
import { DEFAULT_PICTURE } from '../../../../consts/urls';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NewInputForm from '../../../../components/forms/NewInputForm';
import NudgeModal from '../../../../components/NudgeModal';

const AdminRecruitersFormModal = ({ formik, loading, error, isOpen, toggle }) => {
    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

    const [photo, setPhoto] = React.useState(formik.values[FIELDS.PHOTO]);
    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[FIELDS.PHOTO]));
    }, [formik.values[FIELDS.PHOTO]]);

    function setPhotoField(photo) { 
        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(FIELDS.PHOTO, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <NudgeModal headerText={"Add New Recruiter"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle}>
                <ModalBody className={style.formContainer}>
                    {!loading ? (
                        <>
                            {error ? <Alert color="danger">{error}</Alert> : null}

                            <FormGroup className="mb-20" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div
                                    className={style.photoUpload}
                                    style={{ backgroundImage: `url(${photo})`, objectFit: 'contain' }}
                                    onClick={togglePhotoModal}
                                >
                                    <h5>
                                        <FontAwesomeIcon
                                            icon={['fas', photo != DEFAULT_PICTURE ? 'edit' : 'plus-circle']}
                                        />
                                    </h5>
                                    <UploadModal
                                        isOpen={photoModalOpen}
                                        toggle={togglePhotoModal}
                                        avatar={``}
                                        setPhoto={setPhotoField}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Label>Recruiter Name</Label>
                                <AppInput id={FIELDS.NAME} formik={formik} label="Recruiter Name" autocomplete="off" />
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Label>Recruiter Email Address</Label>
                                <AppInput
                                    id={FIELDS.EMAIL}
                                    formik={formik}
                                    label="Recruiter Email"
                                    autocomplete="off"
                                />
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Label>About this Recruiter</Label>
                                <AppInput
                                    id={FIELDS.BIO}
                                    formik={formik}
                                    type="textarea"
                                    label="Recruiter Bio"
                                    autocomplete="off"
                                />
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Row>
                                    <Col md={6}>
                                        <Label>Company Name</Label>
                                        <AppInput
                                            id={FIELDS.COMPANY}
                                            formik={formik}
                                            label="Company Name"
                                            autocomplete="off"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Company Location</Label>
                                        <AppInput
                                            id={FIELDS.LOCATION}
                                            formik={formik}
                                            label="Company Location"
                                            autocomplete="off"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Label>Year Founded</Label>
                                <AppInput
                                    id={FIELDS.YEAR_FOUNDED}
                                    formik={formik}
                                    label="Year Company Was Founded"
                                    autocomplete="off"
                                />
                            </FormGroup>

                            <FormGroup className="mb-20">
                                <Row>
                                    <Col sm={6} md={6}>
                                        <Label>
                                            <b>Sectors Covered</b>
                                        </Label>
                                        <NewInputForm
                                            formik={formik}
                                            field={FIELDS.SECTORS}
                                            subField={'name'}
                                            fieldLimit={8}
                                            removeText="-"
                                        />
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <Label>
                                            <b>Specialisations</b>
                                        </Label>
                                        <NewInputForm
                                            formik={formik}
                                            field={FIELDS.SPECIALISM}
                                            subField={'name'}
                                            fieldLimit={8}
                                            removeText="-"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </>
                    ) : (
                        <Loader message="Creating recruiter profile" />
                    )}
                </ModalBody>
                <ModalFooter>
                    <ClickButton
                        disabled={loading}
                        color={'success'}
                        onClick={formik.handleSubmit}
                    >
                        Create Recruiter
                    </ClickButton>
                </ModalFooter>
            </NudgeModal>
        </Form>
    );
};

export default AdminRecruitersFormModal;
