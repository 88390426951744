import React from 'react';
import { Card, CardBody } from 'reactstrap';

import SmallHeader from '../../../components/SmallHeader';
import STRIPE_STATUES from '../../../consts/stripeStatuses';
import StripeOnboardingLink from './StripeOnboardingLink';

const TEXTS_BY_STATUS = new Map([
    [
        STRIPE_STATUES.NONE,
        `Nudge uses Stripe to get you paid quickly and keep your personal and payment information secure.
         Thousands of companies around the world trust Stripe to process payments for their users.
         Set up a Stripe account to get paid with Nudge.`,
    ],
    [STRIPE_STATUES.NEW, 'Please finish your onboarding process in Stripe.'],
    [STRIPE_STATUES.IN_PROGRESS, 'Your Stripe account is creating.'],
    [STRIPE_STATUES.COMPLETE, 'Stripe account created'],
]);

const TEXT = 'In order to receive payments please fill in billing data.';

const BillingInfoCard = ({ onboardingStatus }) => (
    <Card>
        <CardBody>
            <SmallHeader>Billing data</SmallHeader>
            <div>{TEXTS_BY_STATUS.get(onboardingStatus) || TEXT}</div>
            <StripeOnboardingLink onboardingStatus={onboardingStatus} />
        </CardBody>
    </Card>
);

export default BillingInfoCard;
