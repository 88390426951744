import React from 'react';
import { Formik } from 'formik';

import EmailForm from './EmailForm';
import defaultVR from '../../../../../assets/images/vr_default.png';

import { validationSchema, FIELDS, initialValues, getValidationSchema } from './EmailForm/validation';
import { API_DASHBOARD_ADMIN, DEFAULT_PICTURE } from '../../../../../consts/urls';
import { Button, Card, Col, Row } from 'reactstrap';
import { NEVER_REFRESH, authFetcher, authPostData, deleteData, patchData, putData } from '../../../../../api/tools';
import Image from '../../../../../assets/images/RBS_Hub.jpeg';
import useSWR from 'swr';

import style from './style.module.scss';
import Loader from '../../../../../components/Loader';
import moment from 'moment';
import UploadResultsModal from './UploadResultsModal';
import NudgeBoxes from '../../../../../components/NudgeBoxes';
import ClickButton from '../../../../../components/buttons/NudgeButton';
import { sort_by_key } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';

const ManageBookableAssessments = () => {

    const [sending, setSending] = React.useState();
    const [success, setSuccess] = React.useState(false);

    const [resultsOpen, setResultsOpen] = React.useState(false);

    const [selectedBooking, setSelectedBooking] = React.useState();
    const [selectedEdit, setSelectedEdit] = React.useState();

    const { data, mutate } = useSWR(`${API_DASHBOARD_ADMIN}/BookableAssessments`, authFetcher, NEVER_REFRESH);
    const { data: bookings, mutate: mutateBookings } = useSWR(`${API_DASHBOARD_ADMIN}/AssessmentBookings`, authFetcher, NEVER_REFRESH);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    async function onSubmit(v) {
        setSending(true);
        setSuccess("");

        try {
            if(typeof v.photo == "object") {
                v.photo = await convertBase64(v.photo);
                v.ext = `.${v.photo.split('/')[1].split(";")[0]}`;
                v.photo = v.photo.split(',')[1]
            }

            var response;
            if(selectedEdit) {
                response = await putData(v, `${API_DASHBOARD_ADMIN}/Assessments/Update/${v.id}`);
            } else {
                response = await authPostData(v, `${API_DASHBOARD_ADMIN}/Assessments/Create`);
            }

            if(response) {
                if(selectedEdit) {
                    setSuccess("Assessment updated.");
                } else {
                    setSuccess("Assessment created.");
                }
            }

        } catch(e) {
            console.log(e.message)
        } finally {
            setSelectedEdit(null)
            await mutate();
            setSending(false);
        }
    }

    async function onDelete(id) {
        setSending(true);
        const response = await deleteData({}, `${API_DASHBOARD_ADMIN}/Assessments/Delete/${id}`);

        setSuccess("Assessment deleted.");
        
        await mutate();
        setSending(false);
    }

    const props = {
        initialValues,
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    };

    const editObj = {
        set: setSelectedEdit,
        get: selectedEdit
    }

    return (<>
        <UploadResultsModal booking={selectedBooking} isOpen={resultsOpen} toggle={() => setResultsOpen(!resultsOpen)} mutate={mutateBookings} />
        <Row>
            <Col xs={12} md={4}>
                <Formik {...props}>{(formik) => <EmailForm formik={formik} sending={sending} success={success} selectedEdit={editObj} defaultImage={defaultVR}/>}</Formik>
                <br/>

                { !sending && success ? <h5>{success}</h5> : null }
            </Col>
            <Col md={8}>
                { !sending ? <div className={style.assessmentListContainer}>
                    <Row className={style.assessmentList}>
                        { data ? sort_by_key(data, 'id', true).map((item, index) => <Col xs={12} md={12}>
                            <Card className={style.assessmentCard}>
                                <Row>
                                    <Col md={3} className={style.assessmentCardItem}>
                                        <img src={item.photo || defaultVR}/>
                                    </Col>
                                    <Col md={9} className={style.assessmentCardItem}>
                                        <h5>{item.title}</h5>
                                        <NudgeBoxes
                                            data={item.keySkills.map((skill) => ({ label: skill, danger: false }))}
                                            rows={2}
                                        />
                                        <h4>{item.description}</h4>
                                        
                                        { bookings?.filter((b) => b.assessmentId == item.id).length ? <div className={style.bookingListContainer}>
                                            { bookings ? bookings.filter((b) => b.assessmentId == item.id).map((booking, index) => <div>
                                                <b>{booking.participantAddress}</b> - {moment(booking.timeSlot).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                <p><ClickButton color="success" onClick={() => { setResultsOpen(!resultsOpen); setSelectedBooking(booking)}}>{booking.reportLink ? "View" : "Upload"} Assessment Results</ClickButton></p>
                                                
                                            </div>) : null }
                                        </div> : <div className={style.bookingListContainer}>
                                            <p><i>No bookings have yet been made.</i></p>
                                        </div> }

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className={style.assessmentCardItem}>
                                        <div className={style.buttonContainer}>
                                            { selectedEdit == item ? null : <ClickButton color="success" onClick={() => setSelectedEdit(item)}>Edit</ClickButton> }
                                            <ClickButton color="danger" onClick={() => onDelete(item.id)}>Delete</ClickButton>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>) : <Loader message="Fetching"/> }
                    </Row>
                </div> : <Loader message="Refreshing"/> }
            </Col>
        </Row>
    </>);
};

export default ManageBookableAssessments;
