import React, { useContext, useState } from 'react';
import { Formik } from 'formik';

import FormData from './FormData';

import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { authPostData, authSendFile } from '../../../api/tools';
import { useNavigate } from 'react-router-dom';

import { initialValues, validationSchema } from './FormData/validation';

import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { USER } from '../../../consts/userRoles';
import SuccessModal from './SuccessModal';

import { URLS } from '../../../consts/urls';

var backFunction = null;
var openModal = null;

const toggle = () => {
    openModal();
    backFunction();
};

const tallyServices = (values) => {
    var total = 95;
    values.VAT_Filing = values['VAT Filing'];
    values.Self_Assess = values['Self Assessment'];

    if (values.bookkeeping !== '') {
        total += 90;
    }
    if (values.PAYE !== '') {
        total += 10;
    }
    if (values['VAT Filing'] !== '') {
        total += 10;
    }
    if (values['Self Assessment'] !== '') {
        total += 10;
    }

    return total;
};

const SendEmail = async (values) => {
    values.price = tallyServices(values);

    var url = `${API_DASHBOARD_CONTRACTOR}/Services/AttachedFiles`;
    const response = await authSendFile(values.passport, url, {});
    values.passport = response.fileUrl;

    url = `${API_DASHBOARD_CONTRACTOR}/Services/Aberdeen`;
    await authPostData(values, url);

    openModal();
};

const props = {
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
        SendEmail(values);
    },
};

const AberdeenAccount = () => {
    const { user } = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();

    backFunction = () => {
        navigate(URLS.COMMUNITY);
    };
    openModal = () => {
        setModalOpen(!modalOpen);
    };
    return (
        <div>
            <SuccessModal isOpen={modalOpen} toggle={toggle} />
            <Formik {...props}>{(formik) => <FormData formik={formik} user={user} />}</Formik>
        </div>
    );
};

export default withAuthProtectedRouter(AberdeenAccount, [USER]);
