import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import Loader from '../Loader';
import { authSendFile, patchData, deleteData, NO_REFRESH_INTERVAL } from '../../api/tools';
import DefaultAvatar from '../../assets/images/avatar.png';
import { useEditButton } from '../buttons/EditButton';
import ContractorInformationEdit from './ContractorInformationEdit';
import { FIELDS, validationSchema, initialValues } from './ContractorInformationEdit/validation';
import { shouldSendPhotoUpdate, shouldSendResumeUpdate } from './helpers';
import NonEditableContractorInformation from './NonEditableContractorInformation';

import { authFetcher, putData } from '../../api/tools';
import { GetPageTheme, sort_by_key } from '../pages/GenericCompanyProfile/HelperComponents';

import { API_DASHBOARD_CLOOD, API_DASHBOARD_CONTRACTOR, API_PROFILE } from '../../consts/urls.js';
import useSWR from 'swr';

import style from './style.module.scss';
import { getProfileCompletion, getTasks } from '../../consts/tasks';
import CompanyVideo from '../pages/FullCompanyProfile/PageComponents/CompanyVideo.jsx';

export const updateCasebase = async (user) => {

    if(user?.parentId != "") return "Cannot update internal users."

    const tasks = getTasks(user);
    const completion = getProfileCompletion(tasks);

    if (completion == 100) {
        const putUrl = `${API_DASHBOARD_CLOOD}/Projects/UpdateContractor`;
        await putData({}, putUrl);

        return true;
    }

    return false;
};

const ContractorInformation = ({
    user,
    resume,
    expectedEarnings,
    company,
    showEdit,
    job = {},
    refresh,
    url,
    location,
    showResume,
    changeKey,
    reset,
    email,
    videoLink,
    canCancel
}) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const onClose = () => setIsOpen(false);

    const { data } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Companies/All`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const [companies, setCompanies] = React.useState([]);
    React.useEffect(() => {
        if (data) {
            setCompanies(
                sort_by_key(data.companies, 'name')
                    .filter((c) => c.parentId == '')
                    .map((item) => item.name),
            );
        }
    }, [data]);

    const [sending, setSending] = useState(false);

    const savedValues = {
        ...initialValues,
        expectedEarnings,
        company,
        resume,
        [FIELDS.SECTOR]: job.industryId,
        [FIELDS.BIO]: user.bio,
        [FIELDS.CATEGORY]: job.subIndustryId,
        [FIELDS.SERVICE_OFFERED]: job.name,
        [FIELDS.PHOTO]: user.photo || DefaultAvatar,
        [FIELDS.NAME]: user.username,
        [FIELDS.TIMING]: user.timing,
        [FIELDS.LOCATION]: user.location,
        [FIELDS.WORK_TYPE]: user.workType,
        [FIELDS.WORK_MODE]: user.workMode || 'Both',
        [FIELDS.WORK_TIMING]: user.workTiming || 'Both',
        [FIELDS.FREELANCER_STAFF]: user.freelancerStaff || 'Both',
        [FIELDS.RATE_RANGE_LOW]:
            user.expectedEarnings.split(' ')[0] == '0.01' ? '' : user.expectedEarnings.split(' ')[0],
        [FIELDS.RATE_RANGE_HIGH]:
            user.expectedEarnings.split(' ')[2] == '0.02' ? '' : user.expectedEarnings.split(' ')[2],
        [FIELDS.WORK_SEARCH]: user.workSearch,
        [FIELDS.CURRENCY]: user.currencyType,
        [FIELDS.AVATAR_LINK]: user.avatarLink,
        [FIELDS.BANNER_COLOUR]: `#${user.bannerColour || "407962"}`,
        [FIELDS.RESUME]: user.resumeUrl,
        [FIELDS.VIDEO_LINK]: user.videoLink == " " ? "" : user.videoLink
    };

    const props = {
        initialValues: savedValues,
        validationSchema,
        onSubmit: async (values) => {
            if (!values.bio) values.bio = ' ';

            if (!values[FIELDS.RATE_RANGE_LOW]) {
                values[FIELDS.RATE_RANGE_LOW] = 0.01;
            }

            if (!values[FIELDS.RATE_RANGE_HIGH]) {
                values[FIELDS.RATE_RANGE_HIGH] = 0.02;
            }

            if(values[FIELDS.BANNER_COLOUR]) {
                values[FIELDS.BANNER_COLOUR] = values[FIELDS.BANNER_COLOUR].substring(1, values[FIELDS.BANNER_COLOUR].length)
            }

            if (values[FIELDS.VIDEO_LINK] == "") {
                values[FIELDS.VIDEO_LINK] = " ";
            }

            values[FIELDS.RATE_RANGE] = values[FIELDS.RATE_RANGE_LOW] + ' - ' + values[FIELDS.RATE_RANGE_HIGH];
            setSending(true);

            await updateProfile(values);

            if(values[FIELDS.DELETE_PHOTO]) {
                await deletePhoto();
            }
            if(values[FIELDS.DELETE_RESUME]) {
                await deleteCV();
            }

            if (reset) reset();

            await refresh();
            setIsOpen(false);
            setSending(false);
        },
    };

    const updateProfile = async (values) => {
        await patchData(values, url);

        if(shouldSendResumeUpdate(values) && !values[FIELDS.DELETE_RESUME]) {
            await authSendFile(values.resume, `${url}/CV`)
        }

        if(shouldSendPhotoUpdate(values) && !values[FIELDS.DELETE_PHOTO]) {
            await authSendFile(values.photo, `${url}/Photo`)
        }
        
        await updateCasebase(user);

        return true;
    };

    const deletePhoto = async () => {
        setSending(true);
        await patchData({}, `${API_PROFILE}/Photo`, { uid: user.id });
    };

    const deleteCV = async () => {
        setSending(true);
        await deleteData({}, `${API_PROFILE}/CV`, { uid: user.id });
    };

    const theme = GetPageTheme();

    const [modalOpen, setModalOpen] = React.useState();
    const toggle = () => {
        setModalOpen(!modalOpen);
    }

    return (
        <Card id={`card-${theme}`} className={style.card}>
            <CompanyVideo videoLink={videoLink} isOpen={!!modalOpen} toggle={toggle}/>
            <CardBody>
                <div className={style.profile}>
                    {isOpen ? (
                        <Formik {...props}>
                            {!sending ? (
                                (formik) => (
                                    <ContractorInformationEdit
                                        formik={formik}
                                        onClose={onClose}
                                        onDeletePhoto={deletePhoto}
                                        onDeleteCV={deleteCV}
                                        companies={companies}
                                        canCancel={canCancel}
                                    />
                                )
                            ) : (
                                <Loader />
                            )}
                        </Formik>
                    ) : (
                        <NonEditableContractorInformation
                            user={user}
                            expectedEarnings={expectedEarnings}
                            company={company}
                            resume={resume}
                            onClickEdit={onClickEdit}
                            showEdit={showEdit}
                            job={job}
                            location={location}
                            showResume={showResume}
                            email={email}
                            videoLink={videoLink}
                            toggle={toggle}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

ContractorInformation.propTypes = {
    user: PropTypes.object.isRequired,
    expectedEarnings: PropTypes.string.isRequired,
    resume: PropTypes.string,
    showEdit: PropTypes.bool,
    company: PropTypes.string,
    refresh: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
};

export default ContractorInformation;
