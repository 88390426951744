import * as Yup from 'yup';

export const FIELDS = {
    SKILLS: 'skills',
};

export const validationSchema = Yup.object({
    [FIELDS.SKILLS]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string()
                            .test('len', 'Skill description must be between 1 and 50 characters.', (val) =>
                                val ? val.length <= 50 : null,
                            )
                            .required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),
});

export const initialValues = {
    [FIELDS.SKILLS]: [{ icon: 'brain', name: '' }],
};
