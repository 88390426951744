import React, { useContext } from 'react';
import SignInPageTemplate from '../../../components/SignInPageTemplate';

import { USER } from '../../../consts/userRoles';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { getTasks, getProfileCompletion } from '../../../consts/tasks';

import { API_DASHBOARD_CLOOD, API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { authFetcher } from '../../../api/tools';
import { sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import OfferCard from './OfferCard';

import style from './style.module.scss';
import { Row } from 'reactstrap';
import Loader from '../../../components/Loader';


const Recommender = ({}) => {
    const postURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/retrieve`;

    const { user } = useContext(AuthContext);

    const [offers, setOffers] = React.useState();

    React.useEffect(() => {
        async function getData() {
            const response = await authFetcher(`${API_DASHBOARD_CONTRACTOR}/Offers/All`);
            setOffers(response);
        }

        getData();
    }, [])

    const tasks = getTasks(user);
    const completion = getProfileCompletion(tasks);

    const [preparedOffers, setPreparedOffers] = React.useState();
    const [matches, setMatches] = React.useState();
    const [alert, setAlert] = React.useState('');
    
    const [sending, setSending] = React.useState('');


    const [error, setError] = React.useState(false);

    async function retainCase() {
        try {
            const response = await authFetcher(`${API_DASHBOARD_CLOOD}/Contractors/CareerRecommender`);

            if (response?.bestK) {
                setAlert('');
                return sort_by_key(response.bestK, 'score__').reverse();
            }
        } catch (e) {
            setAlert(`Something went wrong: ${e.message}`);
            setError(!error);
        }
    }

    React.useEffect(() => {
        async function getData() {
            const response = await retainCase();
            setMatches(response);
        }

        if (user && !matches) {
            setSending(true);
            getData();
        }
    }, [user]);

    React.useEffect(() => {
        if (matches && offers && !preparedOffers) {
            var preparedList = [];

            matches.forEach((item) => {
                var offer = offers.offers.find((offer) => offer.id == item.Id);
                var match = offers.matches.find((match) => item.Id == match.offer.id);

                if (offer) {
                    offer.score = item.score__;
                    preparedList.push(offer);

                    if (match) { 
                        offer.matchStatus = match.statusText;
                    }
                }
            });

            setPreparedOffers(preparedList.slice(0, 6));
            setSending(false);
        }
    }, [matches, offers]);

    return (
        <SignInPageTemplate>
            { alert ? <h5>{alert}</h5> : null }
            <h2 className={style.title}>Career Recommender</h2>
            <p className={style.subtitle}>
                The career recommender is powered by our Klara AI system to recommend the best jobs that we have for
                your profile.
            </p>

            {completion < 100 ? (
                <>
                    <h2 className={style.subtitle}>
                        Your profile must be completed to use the career recommender. Check the profile completion
                        progress in the sidebar to the left.    
                    </h2>
                </>
            ) : (
                <>
                    <h2 className={style.subtitle}>
                        Your profile is complete. Here are our best recommended jobs, hand-picked for you by our Klara
                        AI:
                    </h2>
                    <div className={style.offerContainer}>
                        {preparedOffers?.length && !sending ? (
                            <Row>
                                {preparedOffers.map((offer, index) => (
                                    <>
                                        <OfferCard index={index} offer={offer} />
                                    </>
                                ))}
                            </Row>
                        ) : (
                            <h5 class="text-center">No jobs found.</h5>
                        )}
                        { sending ? <Loader message="Klara is working" /> : null }
                    </div>
                </>
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Recommender, [USER]);
