import React from 'react';
import { Label } from 'reactstrap';

const NudgeLabel = ({ field, children, style, show }) => {
    return (
        show ? <Label for={field} style={style}>
            {children}
        </Label> : null
    )
}

export default NudgeLabel;