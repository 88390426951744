import React from 'react';
import useSWR from 'swr';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import ContractorPageTemplate from '../../../components/ContractorPageTemplate';
import Loader from '../../../components/Loader';
import SwitchToDesktop from '../../../components/SwitchToDesktop';
import { API_GATEWAY } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import BillingInfoCard from './BillingInfoCard';
import CurrentContracts from './CurrentContracts';
import PastContractsCard from './PastContractsCard';

import { sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';

const PaymentsPage = () => {
    const { data } = useSWR(`${API_GATEWAY}/Payment/BillingData`, authFetcher, NO_REFRESH_INTERVAL);
    const { data: contracts } = useSWR(`${API_GATEWAY}/InvoiceContractor/Contracts`, authFetcher, NO_REFRESH_INTERVAL);
    const isBillingDataActive = contracts && (contracts.pastContracts.length || contracts.currentContracts.length);

    const [isCurrent, setIsCurrent] = React.useState(true);
    const toggleIsCurrent = () => {
        setIsCurrent(!isCurrent);
    };

    return (
        <>
            <SwitchToDesktop>
                <ContractorPageTemplate>
                    <h1 className={`title ${style.title}`}>My Payments</h1>

                    {data && contracts ? (
                        <div>
                            <>
                                {contracts.currentContracts?.length > 0 ? (
                                    <CurrentContracts
                                        data={isCurrent ? contracts.currentContracts : contracts.pastContracts}
                                        isCurrent={isCurrent}
                                        toggle={toggleIsCurrent}
                                    />
                                ) : (
                                    <h5 className="text-center">No current contracts.</h5>
                                )}

                                <br />
                                <BillingInfoCard
                                    isCreated={data.isOnboarded}
                                    isActive={isBillingDataActive}
                                    onboardingStatus={data.onboardingStatus}
                                />
                            </>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </ContractorPageTemplate>
            </SwitchToDesktop>
        </>
    );
};

export default withAuthProtectedRouter(PaymentsPage, [USER]);
