import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';

import AppInput from '../../../components/AppInput';
import style from './style.module.scss';
import { FIELDS } from './validation';
import ClickButton from '../../../components/buttons/NudgeButton';

export const ChangePasswordPageForm = ({ formik, deleteProfile }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <AppInput
            id={FIELDS.OLD_PASSWORD}
            type="password"
            formik={formik}
            label="Current Password"
            className={style.input}
        />
        <br />
        <AppInput
            id={FIELDS.NEW_PASSWORD}
            type="password"
            formik={formik}
            label="New password"
            className={style.input}
        />
        <br />
        <AppInput id={FIELDS.NEW_PASSWORD_REPEAT} type="password" formik={formik} label="Repeat New Password" />
        <br />

        <Row className="text-center">
            <Col md={6}>
                <ClickButton color="success" type="submit" data-testid="submit-button" size="lg" className={style.button}>
                    Confirm
                </ClickButton>
            </Col>
            <Col md={6}>
                { deleteProfile }
            </Col>
        </Row>
    </Form>
);

ChangePasswordPageForm.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default ChangePasswordPageForm;
