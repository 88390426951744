import React from 'react';
import { Card, Col, Row } from 'reactstrap';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import style from './style.module.scss';
import SearchBox from '../../../../components/SearchBox';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PICTURE, URLS } from '../../../../consts/urls';
import { FullStatCardScaling, GetPageTheme, calculateLightness, generateMonoLinearGradient } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../../components/buttons/NudgeButton';
import useMobile from '../../../../hooks/useMobile';

const PER_PAGE = 9;
const PER_PAGE_MOBILE = 8;

const RecruiterList = ({ data }) => {
    const { isMobileWide } = useMobile();
    const [search, setSearch] = React.useState('');
    const navigate = useNavigate();

    const PER_PAGE_CHANGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE;

    data = data.filter((i) => filterData(i));
    const total = Math.ceil(data.length / PER_PAGE_CHANGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE_CHANGE);
    const recruitersToDisplay = [...data].slice(start, end);

    const emptyRecruiters = [];

    for (var i = 0; i < PER_PAGE_CHANGE - recruitersToDisplay.length; i++) {
        emptyRecruiters.push('');
    }

    function findIn(string, search) {
        return upper(string).includes(upper(search)) ? true : false;
    }

    function upper(string) {
        return string.toUpperCase();
    }

    function filterData(recruiter) {
        if (findIn(recruiter.name, search)) {
            return true;
        }
    }

    const theme = GetPageTheme();   
    return (
        <>
            <SearchBox className={style.search} setValue={setSearch} value={search} />
            {recruitersToDisplay.map((item, index) => (
                <Col xs={12} sm={6} md={6} lg={4}>
                    <Card className={style.recruiterCard}>
                        <div style={{ background: generateMonoLinearGradient(`#${item.backColour || "407962"}`), borderBottom: `2px solid ${`#${item.backColour || "407962"}`}` }} className={style.banner}/>
                        <Row>
                            <Col lg={3}>
                                <div class="flexy">
                                    <img src={item.photo || DEFAULT_PICTURE} className={style.companyPicture} style={{ borderColor: item.backColour ? `#${item.backColour}` : `#407962` }} />
                                </div>
                            </Col>
                            <Col lg={9}>
                                <h5 className={style.jobName} style={{ color: isMobileWide ? '' : calculateLightness(`#${item.backColour || "407962"}`) > 50 ? "black" : "white" }}>{item.name}</h5>
                                <h5 className={style.locationName} style={{ color: isMobileWide ? '' : calculateLightness(`#${item.backColour || "407962"}`) > 50 ? "black" : "white" }}>{item.company}, {item.location}</h5>

                                <p id={`card-sub-${theme}`} style={{ border: `1px solid ${`#${item.backColour || "407962"}`}` }} className={style.recruiterBio}>{item.bio}</p>

                                <NudgeButton color="success" className={style.linkButton} onClick={() => navigate(`${URLS.ADMIN_RECRUITERS}/${item.id}`)}>
                                    View Profile    
                                </NudgeButton>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))}

            {emptyRecruiters.map((item, index) => (
                <Col xs={12} sm={6} md={6} lg={4}>
                    <Card className={style.emptyRecruiterCard}></Card>
                </Col>
            ))}

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={3}
            />
        </>
    );
};

export default RecruiterList;
