import * as Yup from 'yup';

export const FIELDS = {
    VALUES: 'contractorValues',
};

export const validationSchema = Yup.object({
    [FIELDS.VALUES]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        content: Yup.string().required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),
});

export const initialValues = {
    [FIELDS.VALUES]: [],
};
