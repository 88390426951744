import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { setZendeskToken } from '../../api/flask';
import { authFetcher, NO_REFRESH_INTERVAL, prepareAuthHeaders } from '../../api/tools';
import { API_PROFILE, URLS } from '../../consts/urls';
import { logMessage } from '../../tools/logger';
import { getApiUrl, getRedirectUrl, prepareUser } from './helpers';

const logger = logMessage('AuthContextWrapper');

const COGNITO_GROUPS_KEY = 'cognito:groups';

export const USER_COOKIE_KEY = 'userChangePassword';
export const AuthContext = createContext({});

const AuthWrapper = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isAuth, setIsAuth] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [redirect, setRedirect] = useState('');

    const { data: user, mutate: mutateUser, isValidating: userMutating } = useSWR(getApiUrl(userRoles), authFetcher, NO_REFRESH_INTERVAL);

    const verify = location.pathname.split('/')[2] || null;
    const blogPath = location.pathname.substring(6, location.pathname.length);
    const referencePath = location.pathname.substring(12, location.pathname.length);

    const refreshUserData = async () => {
        const session = await Auth.currentSession();
        const userRoles = session.getAccessToken().payload[COGNITO_GROUPS_KEY];
        setUserRoles(userRoles);

        if (!userRoles) return [];

        return { session, userRoles };
    };

    const WITHOUT_FALLBACK_URLS = [
        URLS.SIGN_IN,
        URLS.SIGN_UP,
        `${URLS.SIGN_UP}/${verify}`,
        URLS.HOME,
        URLS.B_CORP,
        URLS.COMPANYPAGE,
        URLS.ABOUTPAGE,
        URLS.INDIVIDUALPAGE,
        URLS.CONTACTPAGE,
        URLS.WELCOME,
        URLS.ABOUT_TEAM,
        URLS.FAQ,
        URLS.KNOWLEDGEBASE,
        URLS.PARTNERS,
        URLS.VALUES,
        URLS.ACCREDITATIONS,
        URLS.BCORP,
        URLS.GIVE_BACK,
        URLS.PLEDGES,
        URLS.BLOGS,
        URLS.SPACE_STATION,
        URLS.SPACE_STATION_TASKS,
        `${URLS.BLOGS}/${blogPath}`,
        `${URLS.REFERENCES}/${referencePath}`,
        //`${URLS.NEW_PASSWORD_REQUIRED}/${verify}`,
        `${URLS.FORGOT_PASSWORD_CODE}/${verify}`,
        `${URLS.FORGOT_PASSWORD}`,
        `${URLS.SIGN_UP_COMPANY}`,
        `${URLS.SIMPLE_ACCOUNT_VERIFY}`,
        '',
    ];

    const setAuthSuccessValues = () => {
        setIsAuth(true);
        setIsAuthenticating(false);
    };

    const loginUser = async (email, password) => {
        setIsAuthenticating(true);

        try {
            const cognitoUser = await Auth.signIn(email.trim(), password.trim());

            if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                navigate(`${URLS.NEW_PASSWORD_REQUIRED}/${email}`);
            } else {
                const { userRoles: roles } = await refreshUserData();
                const url = getRedirectUrl(roles);

                setAuthSuccessValues();

                setZendeskToken();

                navigate(redirect || url);
                setRedirect('');
            }
        } catch (e) {
            setIsAuth(false);
            setIsAuthenticating(false);
            logger(e);
            throw e;
        }
    };

    const logoutUser = async () => {
        await Auth.signOut();
        reset();
        setZendeskToken(true);
        navigate(URLS.HOME);
    };

    const deleteUser = async () => {
        const headers = await prepareAuthHeaders();
        await Auth.signOut();
        await fetch(API_PROFILE, { headers, method: 'DELETE' });
        setIsAuth(false);
    };

    const setRedirectUrl = (url) => !WITHOUT_FALLBACK_URLS.includes(url) && setRedirect(url);

    const reset = () => {
        setIsAuth(false);
        setIsAuthenticating(false);
        setUserRoles([]);
        setRedirect('');
    };

    const values = {
        isAuth,
        logoutUser,
        loginUser,
        isAuthenticating,
        userRoles,
        reset,
        deleteUser,
        user: user && prepareUser(user),
        mutate: mutateUser,
        setRedirectUrl,
        userMutating
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsAuthenticating(true);

            try {
                const { session } = await refreshUserData();

                if (session) {
                    setAuthSuccessValues();
                    navigate(location.pathname);
                }
            } catch (e) {
                logger(e);

                if (!WITHOUT_FALLBACK_URLS.includes(location.pathname)) {
                    setRedirect(location.pathname);
                    navigate(URLS.SIGN_IN);
                }
            }
        };

        fetchUser();
        /* eslint-disable */
    }, [history]);
    /* eslint-enable */

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

AuthWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

export default AuthWrapper;
