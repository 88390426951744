import React from 'react';

import GenericCompanyProfile from '../../../components/pages/GenericCompanyProfile';
import { API_DASHBOARD_COMPANY, URLS } from '../../../consts/urls';
import { COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

const getUrl = (isCurrent) =>
    isCurrent ? `${API_DASHBOARD_COMPANY}/Offers` : `${API_DASHBOARD_COMPANY}/Offers/Archived`;

export const DashboardCompany = () => (
    <>
        <GenericCompanyProfile getUrl={getUrl} showAddNewContract offerUrl={URLS.COMPANY_CONTRACTS} showEdit />
    </>
);

export default withAuthProtectedRouter(DashboardCompany, [COMPANY]);
