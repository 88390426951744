import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, deleteWithAuthorizationHeader } from '../../../../../../api/tools';

import { ADMIN } from '../../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../../components/buttons/NudgeButton';

const TestingGround = ({ id }) => {
    async function clearCases() {
        const deleteUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/${id}/case`;
        const response = await deleteWithAuthorizationHeader(deleteUrl, "eyJhbGciOiAiSFMyNTYiLCAidHlwIjogIkpXVCJ9.eyJuYW1lIjogIk51ZGdlX1Rva2VuMiIsICJleHBpcnkiOiAxNzE4OTY3NjAwfQ.oc3nDHsHsX5ZGmB3NRQaOk5P55M7sXZpK1wEkvKT5HA");
    }

    return (
        <>
            <ClickButton onClick={clearCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
