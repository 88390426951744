import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Row } from 'reactstrap';

import ContractorDashProjectProgress from '../../../components/ContractorDashProjectProgress';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

const NeoTalent = () => {
    const { user, userRoles } = React.useContext(AuthContext);

    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);

    return user ? (
        <SignInPageTemplate>
            <Helmet>
                <title>NEO Talent - Nudge Energy Talent Lab</title>
            </Helmet>
            <Row>
                <Col lg={12}>
                    <Card style={{ padding: '24px', marginBottom: '24px', textAlign: 'center', width: '100%' }}>
                        <h5>NEO Talent</h5>
                        <p style={{ paddingBottom: '0' }}>
                            Welcome to your NEO Talent dashboard! Here you can track your current milestones and attend
                            webinars and meetings.
                        </p>
                    </Card>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {<ContractorDashProjectProgress userId={id} roles={userRoles} />}
                    </div>
                </Col>
            </Row>
        </SignInPageTemplate>
    ) : null;
};

export default NeoTalent;
