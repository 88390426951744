import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

import style from './style.module.scss';
import Loader from '../../Loader';

import { GetPageTheme, arrToString } from '../../pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../NudgeModal';

const UploadModal = ({ isOpen, toggle, avatar, useReadyPlayerMeAvatar, setPhoto }) => {
    const [error, setError] = React.useState('');
    const [sending, setSending] = React.useState(false);

    var inputFile = React.useRef(null);
    function onChangeFile(event) {
        setSending(true);

        // event.stopPropagation();
        // event.preventDefault();
        var file = event.target.files[0];

        var allowedTypes = ['png', 'jpg', 'jpeg'];
        var allowedString = `${allowedTypes}`.replaceAll(',', ', ');

        if (file) {
            if (allowedTypes.includes(file.type.split('/')[1])) {
                const success = setPhoto(file);

                if (!success) {
                    setError("Couldn't upload photo. Try again later.");
                } else {
                    inputFile.current.value = null;
                    if(toggle) toggle();
                }
            } else {
                setError(`Invalid file type. Must be ${allowedString}`);
            }
        }

        if(toggle) toggle();
        setSending(false);
    }

    async function getAvatarImage(aLink) {
        var imageLink = aLink.split('.me/')[1].split('.glb')[0];
        imageLink = `https://models.readyplayer.me/${imageLink}.png`;

        return imageLink;
    }

    async function useReadyPlayerMeAvatar() {
        async function doThing() {
            const r = await getAvatarImage(avatar.get);

            const url = r;
            const fileName = 'avatar.jpg';

            try {
                await fetch(url).then(async (response) => {
                    const contentType = response.headers.get('content-type');
                    const blob = await response.blob();
                    const file = new File([blob], fileName, { contentType });

                    setPhoto(file);
                });
                return true;
            } catch (e) {
                setPhoto(null);
                return false;
            }
        }

        setSending(true);
        const response = await doThing();

        if (response) {
            if(toggle) toggle();
        } else {
            setError('Sorry pal');
        }

        setSending(false);
    }

    React.useEffect(() => {
        if (!avatar.get && inputFile && isOpen) {
            inputFile.current.click();
            if(toggle) toggle();
        }
    }, [inputFile, isOpen]);

    return (
        <>
            <input
                id="myInput"
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => onChangeFile(e)}
            />

            {avatar.get ? (
                <NudgeModal id={`modal-${GetPageTheme()}`} className={style.modal} isOpen={isOpen} toggle={toggle} headerText={"Upload your own photo or use your ReadyPlayerMe avatar?"}>
                    {!sending ? (
                        <ModalBody className={style.modalBody}>
                            <Row>
                                <Col xs={6} md={6} className={style.uploadIcon} onClick={() => inputFile.current.click()}>
                                    <h5>
                                        <FontAwesomeIcon icon={['fas', 'upload']} />
                                    </h5>
                                    <h4>Upload Photo</h4>
                                </Col>
                                <Col xs={6} md={6}
                                    className={`${style.uploadIcon} ${!avatar.get ? style.disabledUpload : null}`}
                                    onClick={avatar.get ? useReadyPlayerMeAvatar : null}
                                >
                                    <h5>
                                        <FontAwesomeIcon icon={['fas', 'user']} />
                                    </h5>
                                    <h4>ReadyPlayerMe Avatar</h4>
                                </Col>
                            </Row>
                            <h5 className={style.errorText}>{error ? error : null}</h5>
                        </ModalBody>
                    ) : (
                        <ModalBody>
                            <Loader message="Uploading" />
                        </ModalBody>
                    )}
                </NudgeModal>
            ) : null}
        </>
    );
};

export default UploadModal;
