import React from 'react';
import { Col, Row, Card } from 'reactstrap';
import SearchBox from '../../../../components/SearchBox';

import { URLS } from '../../../../consts/urls';
import LinkButton from '../../../../components/buttons/LinkButton';
import {
    calculateLightness, FullStatCardScaling,
    generateMonoLinearGradient
} from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import useMobile from '../../../../hooks/useMobile';

const PER_PAGE = 9;
const PER_PAGE_MOBILE = 8;

const OfferList = ({ companies }) => {
    const { isMobileWide } = useMobile();
    const PER_PAGE_CHANGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE;
    const [search, setSearch] = React.useState(``);

    companies = companies.filter((i) => filterOffer(i));

    const total = Math.ceil(companies.length / PER_PAGE_CHANGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE_CHANGE);
    const companiesToDisplay = [...companies].slice(start, end);
    const emptyOffers = [];

    for (var i = 0; i < PER_PAGE_CHANGE - companiesToDisplay.length; i++) {
        emptyOffers.push('');
    }

    function findIn(string, search) {
        return upper(string).includes(upper(search)) ? true : false;
    }

    function upper(string) {
        return string.toUpperCase();
    }

    function filterOffer(company) {
        if (findIn(company.name, search)) {
            return true;
        }
    }

    const buffer = isMobileWide ? 1 : 3;

    React.useEffect(() => {
        setPage(0);
    }, [search]);
    

    return (
        <>
            <SearchBox className={style.search} setValue={setSearch} value={search} />
            <Row>
                {companiesToDisplay.map((company) => (
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <Card className={style.offerCard}>
                            <div style={{ background: generateMonoLinearGradient(`#${company.backColour || "407962"}`), borderBottom: `2px solid ${company.backColour ? `#${company.backColour}` : `#407962`}` }} className={style.banner}/>
                            <Row>
                                <Col lg={3}>
                                    <div class="flexy">
                                        <img src={company.logo} className={style.companyPicture} style={{ borderColor: `${company.backColour ? `#${company.backColour}` : `#407962`}` }}/>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <h5 className={style.jobName} style={{ color: isMobileWide ? "" : calculateLightness(`#${company.backColour || "407962"}`) > 50 ? "black" : "white" }}>{company.name}</h5>

                                    <FullStatCardScaling
                                        header=""
                                        data={[
                                            { label: 'Published Jobs', data: company.currentlyPublished},
                                            { label: 'Current Interviews', data: company.currentMatches},
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <LinkButton className={style.linkButton} link={`${URLS.ADMIN_COMPANIES}/${company.id}`}>
                                View Profile
                            </LinkButton>
                        </Card>
                    </Col>
                ))}
                {emptyOffers.map(() => (
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <Card className={style.emptyOffer} />
                    </Col>
                ))}
            </Row>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={buffer}
            />
            <br />
        </>
    );
};

export default OfferList;
