import * as Yup from 'yup';

export const REG_EX = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const FIELDS = {
    NAME: 'CompanyName',
    EMAIL: 'Email',
    FEE: 'Fee',
    REG_NUMBER: 'CompanyRegistrationNumber',
    REGISTRATION_ADDRESS: 'CompanyRegistrationAddress',
    DESCRIPTION: 'Description',
    PICTURE_URL: 'PictureUrl',
    BACKUP_EMAILS: 'BackupEmails',
    PARENT_ID: 'ParentId',
};

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().email('Field must be an email.').required('This field is required.'),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.FEE]: '0',
    [FIELDS.REG_NUMBER]: '',
    [FIELDS.REGISTRATION_ADDRESS]: '',
    [FIELDS.DESCRIPTION]: '',
    [FIELDS.PICTURE_URL]: '',
    [FIELDS.BACKUP_EMAILS]: '',
    [FIELDS.PARENT_ID]: '',
};
