import React from 'react';
import NudgeModal from '../../../../components/NudgeModal';
import { Card, Col, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GetPageTheme, generateRandomString, generateRandomStringWithSpaces, sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../../components/buttons/NudgeButton';
import UploadFileFieldInline from '../../../../components/UploadFileFieldInline';
import { ToastContext } from '../../../../contexts/toast/ToastContext';
import { authPostData } from '../../../../api/tools';
import { API_DASHBOARD_COMPANY, API_PROFILE } from '../../../../consts/urls';
import NudgeTabs from '../../../../components/NudgeTabs';
import NudgeTooltip from '../../../../components/NudgeTooltip';
import AppInput from '../../../../components/AppInput';

import { initialValues, validationSchema, FIELDS } from './validation';
import { Field, Formik, Form } from 'formik';
import NewArrayFieldForm from '../../../../components/forms/NewArrayFieldForm';
import { Autocomplete } from '@react-google-maps/api';
import AutocompleteInput from '../../../../components/AutocompleteInput';

import PlusButton, { usePlusButtonWithAlwaysMinus } from '../../../../components/buttons/PlusButton';
import NudgeError from '../../../../components/NudgeError';

const CreateEmployeeModal = ({ isOpen, toggle, refresh }) => {

    const { createError, createSuccess } = React.useContext(ToastContext);

    const theme = GetPageTheme();
    const modalProps = {
        id: `modal-${theme}`,
        isOpen,
        toggle
    }

    function generateData(n) {
        let construct = "";
        
        [...Array(n)].forEach(() => {
            construct += generateRandomString(12)
            construct += `, ${generateRandomString(8)}@${generateRandomString(12)}.com\n`
        })
        
        return construct;
    }

    const [sending, setSending] = React.useState(false);

    function refreshData(form) {
        var con = `name, email\n${generateData(5)}`

        form.setFieldValue(FIELDS.CSV, con)
    }

    const TABS = {
        FORM: 'formInput',
        CSV: 'csvInput'
    }
    const [selectedTab, setSelectedTab] = React.useState(TABS.FORM);

    function parseCSVToObject(input) {
        var objArr = [];

        var lineErrors = [];
        const format = 'name, email'
        
        var arr = input.split("\n");
        var definitions = arr[0].split(",")
            .map((item) => item.trim()
            .replaceAll(`"`, "")
            .replaceAll(" ", "_")
        );

        arr.forEach((item, i) => {
            if(i != 0) {
                var obj = {};

                var dataArr = item.split(",")
                    .filter((item) => !!item )
                    .map((item) => item.trim());

                if(dataArr?.length) { 
                    if(dataArr.length == definitions.length) {
                        dataArr.forEach((item2, j) => {
                            var el = item2.replaceAll(`"`, "");
                            obj[definitions[j]] = el;
                        })
                        objArr.push(obj);
                    }
                    else {
                        lineErrors.push(i + 1);
                    }
                }
            }
        })
        
        return sort_by_key(objArr, 'name');
    }

    function parseObjectToCSV(input) {
        if(!input.length) return "";
        var csvConstructor = `name, email`;
        input.forEach((item, index) => {
            csvConstructor += `\n${Object.values(item).join(", ")}`
        })
        return csvConstructor; 
    }

    const [file, setFile] = React.useState();

    const uploadFile = async (form, event) => {
        const file = event.target.files[0];
        const reader = new FileReader()
        var doc = document.getElementById('user_details');

        reader.onload = () => {
            form.setFieldValue(FIELDS.CSV, reader.result.replaceAll(";", ","))
        }

        if (file) {
            var extension = file.name.split(".")[1]

            if (extension == 'csv') {
                setFile(file);
                reader.readAsBinaryString(file)
            } else {
                createError("Error. File must end with '.csv'.");
            }
            
            doc.value = null;
            setFile();
        }
    };

    function clickFile() {
        var doc = document.getElementById('user_details');

        doc?.click();
    }

    /*
        name, email
        Partitio Yellow, fidici8347@talmetry.com
    */
    
    const props = {
        initialValues: initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);

            try {
                var v = values[FIELDS.EMPLOYEES];
                if(!v.length) {
                    console.log("no obj. using csv")
                    v = parseCSVToObject(values[FIELDS.CSV])
                }
                
                var emailsExist = await authPostData(v.map((item) => item.email), `${API_PROFILE}/Exists/List`);

                if(!emailsExist) {
                    createError(`Error. Either no valid email addresses were found, or all email addresses were taken.`);
                } else {
                    await authPostData({ data: v.filter((_, index) => !emailsExist.includes(index)) }, `${API_DASHBOARD_COMPANY}/Employees/Create`)
                    createSuccess(`User(s) created.`);
                }

                await mutate();
            } catch(e) {
                createError(e.message);
            } finally {
                setSending(false);
            }
        },
    };

    async function handleSwap(form, tab) {
        const v = form.values;

        if(tab == TABS.CSV) {
            console.log("Clicking on Form")

            const parsed = parseCSVToObject(v[FIELDS.CSV]);
            form.setFieldValue(FIELDS.EMPLOYEES, parsed)
        } else {
            console.log("Clicking on CSV")

            const parsed = parseObjectToCSV(v[FIELDS.EMPLOYEES]);
            form.setFieldValue(FIELDS.CSV, parsed)
        }
    }

    const guidanceTooltip = <NudgeTooltip id="header-instructions" label={<h5 style={{ fontSize: '20px', margin: 0 }}>Create New Employee(s)</h5>}>
        {"This input can take either a CSV file or direct input via form. CSV must be formatted a particular way for intake of data.\n\ne.g\nname,email\nJohn Doe, johnd@nudgetalentlab.com\n\nFor an idea of how to format this, try out the form input."}
    </NudgeTooltip>

    return (<Formik {...props}>
        {(formik) => 
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <NudgeModal headerText={guidanceTooltip} {...modalProps}>
                <ModalBody>
                    <Card id={`card-${theme}`} style={{ padding: '6px', marginBottom: '12px' }}>
                        { !sending ? <NudgeTabs
                            selected={selectedTab}
                            setTab={setSelectedTab}
                            tabs={[
                                { id: TABS.FORM, label: 'Form', condition: true },
                                { id: TABS.CSV, label: 'CSV File', condition: true }
                            ]}
                            changeFunction={() => handleSwap(formik, selectedTab)}
                        /> : null }
                    </Card>
                    { selectedTab == TABS.CSV ? <div id={`card-${theme}`} style={{ minHeight: '420px', padding: '12px' }}>
                        <input type="file" id="user_details" onChange={(e) => uploadFile(formik, e)} style={{ padding: '6px', display: 'none' }}/>
            
                        <div class="flexy" style={{ columnGap: '12px' }}>
                            <NudgeButton disabled={sending} onClick={clickFile}>Upload .CSV</NudgeButton>
                            |
                            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, fontSize: '16px' }}>{file?.name || "No file"}</h5>
                            |
                            <NudgeButton disabled={sending} onClick={() => refreshData(formik)}>Generate Data</NudgeButton>
                        </div>


                        <div class="flexy">
                            <Field
                                id={FIELDS.CSV}
                                component={AppInput}
                                formik={formik}
                                label=""
                                placeholder=""
                                type="textarea"
                                style={{ minHeight: '350px', marginTop: '12px' }}
                            />
                        </div>
                    </div> : selectedTab == TABS.FORM ? <div id={`card-${theme}`} style={{ minHeight: '420px', padding: '12px' }}>
                        { !formik.values[FIELDS.EMPLOYEES]?.length ? <Row>
                            <Col>
                                <h5 style={{ padding: '6px' }}>Limit 0/999</h5>
                            </Col>
                            <Col>
                                <NudgeButton style={{ width: '100%' }} onClick={() => formik.setFieldValue(FIELDS.EMPLOYEES, [{ name: '', email: '' }])}>+</NudgeButton>
                            </Col>
                        </Row> : null }
                        <div style={{ padding: '0 24px' }}>
                            { formik.values[FIELDS.EMPLOYEES].map((_, index) => <>
                                <Row>
                                    <NewArrayFieldForm formik={formik} index={index} field={FIELDS.EMPLOYEES} addValue={{ name: '', email: '' }} fieldLimit={999}>
                                        <Col lg={6}>
                                            <Field
                                                component={AutocompleteInput}
                                                name={`${FIELDS.EMPLOYEES}.${index}.name`}
                                                formik={formik}
                                                label=""
                                                placeholder="Name"
                                            />
                                            <NudgeError formik={formik} index={index} field={FIELDS.EMPLOYEES} name="name"/>
                                        </Col>
                                        <Col lg={6}>
                                            <Field
                                                component={AutocompleteInput}
                                                name={`${FIELDS.EMPLOYEES}.${index}.email`}
                                                formik={formik}
                                                label=""
                                                placeholder="Email"
                                            />
                                            <NudgeError formik={formik} index={index} field={FIELDS.EMPLOYEES} name="email"/>
                                        </Col>
                                    </NewArrayFieldForm>
                                </Row>
                            </>)}
                        </div>
                    </div> : null }
                </ModalBody>
                <ModalFooter>
                    <NudgeButton disabled={sending}  onClick={formik.handleSubmit} type="submit">Submit</NudgeButton>
                </ModalFooter>
            </NudgeModal>
        </Form>
    }</Formik>)
}

export default CreateEmployeeModal;
