import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { BIDDING_STATUSES } from '../../../consts/biddingStatuses';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import { ProcessSalary } from '../../pages/GenericCompanyProfile/HelperComponents';
import SmallHeader from '../../SmallHeader';
import BiddingCardForm from './BiddingCardForm';
import { FIELDS, initialValues, validationSchema } from './BiddingCardForm/validate';
import { BodyDisplayValue, onClickAccept, onSubmit } from './helpers';
import ClickButton from '../../buttons/NudgeButton';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

const BodyNew = () => <SmallHeader>Waiting for contractor offer.</SmallHeader>;

const BiddingBodyInfo = ({ value, toggle, onClickAcceptButton, frequency, cancel }) => (
    <>
        <SmallHeader>
            Contractor proposed new contract rate: <strong>{ProcessSalary(value)} </strong>{frequency}.
        </SmallHeader>
        <div className="text-center">
            {/* <ClickButton onClick={toggle} color="success">
                Counter-offer
            </ClickButton>
            <ClickButton color="success" onClick={onClickAcceptButton} className="ml-2">
                Accept
            </ClickButton>
            <ClickButton color="success" onClick={onClickAcceptButton} className="ml-2">
                Accept
            </ClickButton> */}

            <NudgeButtonGroup
                buttons={[
                    { available: true, label: 'Counter-Offer', onClick: toggle },
                    { available: true, label: 'Accept', onClick: onClickAcceptButton },
                    { available: true, label: 'Back', onClick: cancel, color: 'danger' },
                ]}
            />
        </div>
    </>
);

const BodyBidding = ({ value, id, refresh, frequency, toggle }) => {
    const [showForm, setShowForm] = useState(false);

    const props = {
        initialValues: { ...initialValues, [FIELDS.SALARY]: value },
        validationSchema,
        onSubmit: onSubmit(id, refresh, toggle, API_DASHBOARD_COMPANY),
    };

    const onClickAcceptButton = async () => {
        await onClickAccept(API_DASHBOARD_COMPANY, id, refresh)();
        toggle();
    };

    const additional = (
        <ClickButton color="danger" onClick={toggle} className="ml-2" style={{ float: 'right' }}>
            Back
        </ClickButton>
    );

    return showForm ? (
        <>
            <SmallHeader>Propose your counter-offer</SmallHeader>
            <Formik {...props}>
                {(formik) => <BiddingCardForm formik={formik} additional={additional} frequency={frequency} />}
            </Formik>
        </>
    ) : (
        <BiddingBodyInfo
            toggle={() => setShowForm(!showForm)}
            onClickAcceptButton={onClickAcceptButton}
            value={value}
            frequency={frequency}
            cancel={toggle}
        />
    );
};

export const getCompanyBiddingBody = (value, matchId, refresh, frequency, status, toggle) => {
    const bodies = {
        [BIDDING_STATUSES.NEW]: <BodyNew toggle={toggle} />,
        [BIDDING_STATUSES.WAITING]: (
            <BodyDisplayValue
                value={value}
                title="Waiting for contractor approval."
                frequency={frequency}
                toggle={toggle}
            />
        ),
        [BIDDING_STATUSES.ACCEPTED]: (
            <BodyDisplayValue value={value} title="Offer is accepted." frequency={frequency} toggle={toggle} />
        ),
        [BIDDING_STATUSES.BIDDING]: (
            <BodyBidding value={value} id={matchId} refresh={refresh} frequency={frequency} toggle={toggle} />
        ),
    };

    return bodies[status];
};
