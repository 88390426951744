import React from 'react';
import { useParams } from 'react-router-dom';

import FullCompanyProfile from '../../../components/pages/FullCompanyProfile';

import { API_DASHBOARD_CONTRACTOR, API_DASHBOARD_RECRUITER } from '../../../consts/urls';
import { RECRUITER, USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

export const CompanyOffers = () => {
    const { id } = useParams();

    return (
        <FullCompanyProfile
            id={id}
            url={`${API_DASHBOARD_RECRUITER}/Companies/${id}`}
            detailsUrl={`${API_DASHBOARD_RECRUITER}/ExtraDetails/${id}`}
            showEdit={false}
        />
    );
};

CompanyOffers.propTypes = {};

export default withAuthProtectedRouter(CompanyOffers, [RECRUITER]);
