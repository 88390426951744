import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import style from './style.module.scss';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import { ThemeContext } from '../theme/ThemeContextWrapper';

export const ToastContext = React.createContext({});

const ToastWrapper = ({ children }) => {
    
    const [currentToastId, setCurrentToastId] = React.useState();
    const { theme, rtl } = React.useContext(ThemeContext);

    function createToast(message, type = "success") 
    {
        let id;
        if(type == "error") {
            id = toast.error(message);
        } else if(type == "info") {
            id = toast.info(message);
        } else  if(type == "success") {
            id = toast.success(message);
        } else {
            id = toast(message)
        }
        setCurrentToastId(id);
    }

    function createSuccess(message) {
        createToast(message, "success")
    }

    function createError(message) {
        createToast(message, "error")
    }

    function updateToast(updater) {
        toast.update(currentToastId, updater)
    }

    const values = {
        createToast,
        createError,
        createSuccess,
        updateToast
    }

    return (
        <ToastContext.Provider  value={values}>
            <div className={style.toastContainer}>
                <ToastContainer rtl={rtl} theme={theme} limit={5} autoClose={4000} position="bottom-left"/>
            </div>
            { children}
        </ToastContext.Provider>
    )
};

export default ToastWrapper;
