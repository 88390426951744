import * as Yup from 'yup';

export const REG_EX = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const FIELDS = {
    CODE: 'code',
};

export const validationSchema = Yup.object({
    [FIELDS.CODE]: Yup.string()
        .matches(/^[0-9]+$/, 'Invalid confirmation code')
        .min(6, 'Confirmation code must be 6 digits')
        .max(6, 'Confirmation code must be 6 digits')
        .required('Confirmation code is required'),
});

export const initialValues = {
    [FIELDS.CODE]: '',
};
