import React, { useEffect } from 'react';
import { Row } from 'reactstrap';

import useMobile from '../../../hooks/useMobile';
import NudgeHistoryOffer from './NudgeHistoryOffer';
import style from './style.module.scss';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../components/AppPagination';

const PER_PAGE = 2;

export const OfferCards = ({ data, label, search = '', tab = null }) => {
    if (search) {
        data = data.filter((e) => e.name.toUpperCase().includes(search.toUpperCase()));
    }

    data = data.filter((e) => e.companyName != null);
    const total = Math.ceil(data.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);

    const offersToDisplay = [...data].slice(start, end);

    useEffect(() => {
        if (page > 0) setPage(0);
    }, [search]);

    useEffect(() => {
        if (page > 0) setPage(0);
    }, [tab]);

    let cardStyle = style.offerCard;
    if (label === 'Awarded') {
        cardStyle = style.awardedCard;
    } else if (label === 'Pending') {
        cardStyle = style.pendingCard;
    } else {
        cardStyle = style.dismissedCard;
    }

    const { isMobileWide } = useMobile();

    return (
        <>
            <div className={style.searchBoxContainer}></div>
            {offersToDisplay.length ? (
                <>
                    <div className={style.offerContainer}>
                        {offersToDisplay.map((item, index) => (
                            <NudgeHistoryOffer
                                key={item.id + ':' + index}
                                offer={item}
                                className={cardStyle}
                                label={label}
                            />
                        ))}
                    </div>
                    <AppPagination
                        total={total}
                        page={page}
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                        onClickPage={onClickPage}
                        onClickFirst={onClickFirst}
                        onClickLast={onClickLast}
                        inBuffer={isMobileWide ? 1 : 2}
                    />
                    <br />
                </>
            ) : (
                <h5 className="text-center">{`No ${!search ? 'offers' : 'search results'}.`}</h5>
            )}
        </>
    );
};

const drawCardRow = (index, data) => {
    const result = [];
    const { awarded, pending, dismissed } = data;

    result.push(
        <NudgeHistoryOffer
            offer={awarded[index]}
            className={`${style.offer} ${style.awarded}`}
            key={awarded[index]?.id}
        />,
    );
    result.push(
        <NudgeHistoryOffer
            offer={pending[index]}
            className={`${style.offer} ${style.pending}`}
            key={pending[index]?.id}
        />,
    );
    result.push(
        <NudgeHistoryOffer
            offer={dismissed[index]}
            className={`${style.offer} ${style.dismissed}`}
            key={dismissed[index]?.id}
        />,
    );

    return <Row>{result}</Row>;
};

export const drawCardRows = (data) => {
    const result = [];
    const { awarded, pending, dismissed } = data;
    const maxLength = Math.max(awarded.length, pending.length, dismissed.length);

    for (let i = 0; i < maxLength; i++) {
        result.push(drawCardRow(i, data));
    }

    return result;
};

export const drawCardCols = (data, type) => {
    return data.map((entry, index) => (
        <NudgeHistoryOffer offer={entry} key={index} className={`${style.offer} ${style[type]}`} />
    ));
};
