import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { authFetcher, NEVER_REFRESH } from '../../../api/tools';
import CompanyPageTemplate from '../../../components/CompanyPageTemplate';
import GenericTable from '../../../components/GenericTable';
import { sortByDate } from '../../../components/GenericTable/helpers';
import Loader from '../../../components/Loader';
import { renderPaidCell } from '../../../components/TimesheetsTableHelpers';
import { API_GATEWAY } from '../../../consts/urls';
import { COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { renderButtons } from './Buttons';
import ContractDetailsCard from './ContractDetailsCard';
import { renderPaymentCell } from './PaymentCell';

const columnSizeByIndex = [1, 2, 2, 1, 1, 1, 3, 1];

const renderAmountCell = ({ cell }) => (
    <span>
        <strong>{cell.row.original.amount}</strong> GBP
    </span>
);

const CompanyContractTimesheets = () => {
    const { id } = useParams();
    const { data, mutate } = useSWR(`${API_GATEWAY}/InvoiceCompany/Contracts/${id}`, authFetcher, NEVER_REFRESH);

    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Start date',
                accessor: 'dateFrom',
                sortBy: sortByDate('dateFrom'),
            },
            {
                Header: 'Finish date',
                accessor: 'dateTo',
                sortBy: sortByDate('dateTo'),
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: renderAmountCell,
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Paid',
                accessor: 'isPaid',
                Cell: renderPaidCell,
            },
            {
                Header: 'Payments',
                Cell: renderPaymentCell,
            },
            {
                Header: 'Actions',
                Cell: renderButtons(id, mutate),
            },
        ],
        [id, mutate],
    );

    return (
        <CompanyPageTemplate withArrow>
            {data ? (
                <>
                    <div className="mt-40">
                        <h1
                            style={{ textTransform: 'uppercase', fontFamily: 'Work Sans' }}
                            className="text-center title mb-40"
                        >
                            Contract timesheets
                        </h1>
                        <div style={{ width: '90%', margin: 'auto' }}>
                            <ContractDetailsCard data={data} />
                        </div>
                        <GenericTable columns={columns} data={data.timesheets} columnSizeByIndex={columnSizeByIndex} />
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </CompanyPageTemplate>
    );
};

export default withAuthProtectedRouter(CompanyContractTimesheets, [COMPANY]);
