import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ADMIN, COMPANY, RECRUITER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { Card, Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import Loader from '../../../components/Loader';

import style from './style.module.scss';
import { API_DASHBOARD_RECRUITER, DEFAULT_PICTURE, URLS } from '../../../consts/urls';
import { NO_REFRESH_INTERVAL, authFetcher, authPostData } from '../../../api/tools';
import useSWR from 'swr';
import {
    ContainerCard, FullStatCardScaling, GetPageTheme, generateMonoLinearGradient, sort_by_key
} from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import { Link, useNavigate } from 'react-router-dom';
import RecruiterProfile from './RecruiterProfile';
import useMobile from '../../../hooks/useMobile';
import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeButton from '../../../components/buttons/NudgeButton';
import FormlessFileInput from '../../../components/forms/FormlessFileInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NudgeModal from '../../../components/NudgeModal';
import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';

const RecruiterDashboard = ({ id, url, disableEdit }) => {
    const navigate = useNavigate();
    const { isMobileWide } = useMobile();

    const { userRoles } = React.useContext(AuthContext);
    const { theme } = React.useContext(ThemeContext);

    const { data: user, mutate: mutateUser, isValidating: userFetching } = useSWR(
        url ? `${url}/Recruiters/${id}` : `${API_DASHBOARD_RECRUITER}`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    const { data: referrals, mutate: refreshReferrals, isValidating: referralFetching } = useSWR(
        url ? `${url}/Recruiters/${id}/Referrals` : `${API_DASHBOARD_RECRUITER}/Offers`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    const [sendingCV, setSendingCV] = React.useState(false);
    const toggleSending = () => setSendingCV(!sendingCV);

    const [editOpen, setEditOpen] = React.useState(false);
    const toggleEdit = () => setEditOpen(!editOpen);

    const [chosenJob, setChosenJob] = React.useState(``);
    const toggleChosenJob = () => setChosenJob();

    const convertBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    async function uploadCV(file, userId) {
        setSendingCV(true);

        try {
            var base = "";
            var ext = "";

            if(file) {
                base = await convertBase64(file);
                ext = `.${file.type.split('/')[1]}`
            }

            const body = {
                base64: base ? base.split(',')[1] : '',
                ext: ext ? `.${file.type.split('/')[1]}` : ext,
            }

            const response = await authPostData(body, `${API_DASHBOARD_RECRUITER}/Contractors/${userId}/UploadCV`);
        } catch(e) {
            console.log(e.message);
        } finally {
            await refreshReferrals();
            setSendingCV(false);
        }
    }

    async function createMatch(contractorId, offerId) {
        console.log(`${API_DASHBOARD_RECRUITER}/Offers/${offerId}/Match/${contractorId}`);
        toggleSending();
        try {
            var response = await authPostData({}, `${API_DASHBOARD_RECRUITER}/Offers/${offerId}/Match/${contractorId}`);
        } catch(e) {
            console.log(e.message);
        } finally {
            await refreshReferrals();
            await mutateUser();

            setSendingCV(false);
        }
    }
    
    async function onDeletePhoto() {
        if (window.confirm('Are you sure you want to delete your photo?')) {
            setLoading(true);

            try {
                await patchData({}, `${API_PROFILE}/Photo`, { uid: user.id });
            } catch (e) {
                setLoading(false);
            }

            await refresh();
            setLoading(false);
            toggle();
        }
    }

    const [copiedText, setCopiedText] = React.useState(``);
    function copyToClip(text) {
        setCopiedText(text);
        navigator.clipboard.writeText(`${process.env.REACT_APP_SIGN_UP_URL}/${text}`)
    }

    return user ? (
        <SignInPageTemplate>
            <Helmet><title>{`${user.name} - Nudge Energy Talent Lab`}</title></Helmet>
            <Row>
                <Col md={6} lg={4}>
                    <Card className={editOpen ? style.profileCardEdit : style.profileCard}>
                        {!editOpen ? (
                            <>
                                    <div className={style.cardBackground} style={{ background: generateMonoLinearGradient(`#${user.backColour || "407962"}`), borderBottom: `2px solid #${user.backColour || "407962"}` }}>
                                        <NudgeButton className={style.editButton} onClick={() => toggleEdit()}>Edit</NudgeButton>
                                        <div class="flexy" style={{ justifyContent: 'center' }}>
                                            <div
                                                className={style.avatar}
                                                style={{ backgroundImage: `url(${user.photo || DEFAULT_PICTURE})`, borderColor: `#${user.backColour || "407962"}` }}
                                            />
                                        </div>
                                    </div>
                                        <h5 className={style.userName}>{user.name}</h5>
                                    <div style={{ padding: '12px' }}>
                                        <div className={style.bioContainer}>
                                            <h5>Company / Location</h5>
                                            <p>
                                                {user.company} / {user.location}
                                            </p>
                                        </div>
                                        <div className={style.bioContainer}>
                                            <h5>About Me</h5>
                                            <p>{user.bio}</p>
                                        </div>
                                        
                                        <FullStatCardScaling
                                            data={[
                                                { label: 'Year Founded', data: user.yearFounded }
                                            ]}
                                        />

                                        <FullStatCardScaling
                                            data={[
                                                { label: 'Sectors Covered', data: user.sectors ? user.sectors.map((i) => i.name).join(', ') : null }
                                            ]}
                                        />

                                        <FullStatCardScaling
                                            data={[
                                                { label: 'Specialisation', data: user.specialism ? user.specialism.map((i) => i.name).join(', ') : null }
                                            ]}
                                        />
                                    </div>
                            </>
                        ) : (
                            <RecruiterProfile user={user} toggle={toggleEdit} refresh={mutateUser} />
                        )}
                    </Card>
                </Col>

                <NudgeModal id={`modal-${theme}`} headerText={`${chosenJob?.offer} - Referred Users`} isOpen={!!chosenJob} toggle={toggleChosenJob}>
                    <ModalBody style={{  minHeight: '60vh' }}>
                        { chosenJob ? chosenJob.contractors.map((item, index) => <>
                            <Card id={`card-${theme}`} style={{ padding: '12px', marginBottom: '6px' }}>
                                <Row>
                                    <Col sm={6} md={12} lg={4}>
                                        <h5><Link to={`/recruiter/contractors/${item.id}`}>{item.name}</Link></h5>
                                    </Col>
                                    <Col sm={6} md={6} lg={4}>
                                        { !item.cv?.url ? <FormlessFileInput id={`${index}-${item.company.companyId}`} contractor={item} uploadResume={uploadCV} sending={sendingCV}/> : <i>Profile has CV uploaded.</i> }
                                    </Col>
                                    <Col sm={12} md={6} lg={4}>
                                        <NudgeButton disabled={item.matchId !== 0 ||sendingCV} onClick={() => createMatch(item.id, referral.offerId)} color="success" style={{ width: '100%' }}>{ sendingCV ? 'Busy' : item.matchId !== 0 ? "Already Matched" : "Invite to Apply" }</NudgeButton>
                                    </Col>
                                </Row>
                            </Card>
                        </>) : null }
                    </ModalBody>
                    <ModalFooter/>
                </NudgeModal>

                { !userRoles.includes("Company") ? <Col md={6} lg={8}>
                    <Card className={style.listCard}>
                        <h5>Referrals</h5>

                        <ContainerCard id={`card-sub-${GetPageTheme()}`} className={style.referralContainer}>

                            { !referrals?.length && !referralFetching ? <Card className={style.referralCard}>
                                You have no referrals.
                            </Card> : null }

                            { referralFetching && !referrals?.length ? <Loader message="Fetching referred jobs"/> : null }

                            { referrals
                                ? sort_by_key(referrals, 'recruitedTalent', true).map((referral, index) => (
                                      <Card id={`card-${GetPageTheme()}`} className={style.referralCard}>
                                          <h5
                                              className={style.textLink}
                                              onClick={() =>
                                                  navigate(
                                                      url
                                                          ? `${URLS.ADMIN_CONTRACTS}/${referral.offerId}`
                                                          : `${URLS.RECRUITER_OPPORTUNITY}/${referral.offerId}`,
                                                  )
                                              }
                                          >
                                              {referral.offer}
                                          </h5>
                                          <h4
                                              className={style.textLink}
                                              onClick={() =>
                                                  navigate(
                                                      url
                                                          ? `${URLS.ADMIN_COMPANIES}/${referral.company.companyId}`
                                                          : `${URLS.RECRUITER_COMPANY_PROFILE}/${referral.company.companyId}`,
                                                  )
                                              }
                                          >
                                              {referral.company.companyName}
                                          </h4>
                                          <br />

                                            <FullStatCardScaling
                                                data={[
                                                    { label: 'Talent Recruited', data: referral.contractors.filter(p => p.matchId !== 0).length },
                                                    { label: 'Talent Requested', data: referral.extraTalent },
                                                ]}
                                            />

                                            { userRoles.includes(RECRUITER) ? <Row>
                                                <Col lg={6}>
                                                    <NudgeButton style={{ width: '100%' }} onClick={() => setChosenJob(referral)}>Show Available Talent</NudgeButton>
                                                </Col>
                                                <Col lg={6}>
                                                    <p style={{ display: 'flex', justifyContent: 'right', columnGap: '6px' }}>
                                                        <b>Referral Code: </b>
                                                        <p className={copiedText !== referral.refCode ? style.refCode : null } onClick={() => copyToClip(referral.refCode)}>{referral.refCode + " "}<FontAwesomeIcon icon={['far', 'copy']}/></p>
                                                    </p>
                                                    <p>{copiedText == referral.refCode ? " Sign-up link copied!" : null }</p>
                                                </Col>

                                            </Row> : null }
                                      </Card>
                                  ))
                            : null}
                        </ContainerCard>
                    </Card>
                </Col> : null }
            </Row>
        </SignInPageTemplate>
    ) : (
        <Loader />
    );
};

export default withAuthProtectedRouter(RecruiterDashboard, [RECRUITER, ADMIN, COMPANY]);
