import React from 'react';
import { Button, Form, FormGroup, Row, Col } from 'reactstrap';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFileFieldInline from '../../../UploadFileFieldInline';

import AppInput from '../../../AppInput';
import { FIELDS } from './validate';

import { BIDDING_STATUSES } from '../../../../consts/biddingStatuses';
import style from './style.module.scss';
import NudgeButtonGroup from '../../../buttons/ButtonGroup';
import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import useMobile from '../../../../hooks/useMobile';

const MessageInputForm = ({ formik, toggleBid, bid, company, offerStatus, disabled }) => {
    const theme = GetPageTheme();

    const { isMobileSlim } = useMobile();

    function resolveBidDisabled() {
        
        if (bid) {
            if(offerStatus == 'Closed') return true;

            if (company) {
                if (bid.status == 'new') {
                    return true;
                }
            } else {
                if (bid.status == 'waiting' || bid.status == 'accepted') {
                    return true;
                }
            }
        }

        return false;
    }

    const buttonText = (status) => {
        if(offerStatus == 'Closed') return "Cannot make bids as job posting is archived";

        const text = {
            [BIDDING_STATUSES.ACCEPTED]: 'Bid accepted',
            [BIDDING_STATUSES.WAITING]: 'Awaiting counter-offer',
            [BIDDING_STATUSES.BIDDING]: 'Make counter-offer',
            [BIDDING_STATUSES.NEW]: company ? 'Waiting on contractor offer' : 'Make Bid',
        };

        return text[status];
    };

    function handleFileClick() {
        const input = document.getElementById("chat-file");

        if(input) {
            input.click();
        }
    }

    function handleFileUpload(evt) {
        var input = document.getElementById("chat-file");
        var file = evt.target.files[0];

        var allowedTypes = ['pdf', 'doc', 'docx'];
        var allowedString = `${allowedTypes}`.replaceAll(',', ', ');

        if (file) {
            if (allowedTypes.includes(file.type.split('/')[1])) {
                const success = formik.setFieldValue(FIELDS.FILE, file);
                input.files = null;
            } else {
                setError(`Invalid file type. Must be ${allowedString}`);
            }
        }
    }

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <Row>
                <Col md="12">
                    <FormGroup style={{  margin: 0 }}>
                        <div className={style.messageInputContainer}>
                            <AppInput
                                showError={false}
                                className={style.messageInput}
                                id={FIELDS.MESSAGE}
                                formik={formik}
                                disabled={disabled}
                                // onEnter={() => {
                                //     formik.handleSubmit();
                                // }}
                                label="Message"
                                type="textarea"
                            />
                            <NudgeButtonGroup
                                buttons={[
                                    { available: true, label: <FontAwesomeIcon icon={['fas', 'paper-plane']}/>, onClick: () => formik.handleSubmit(), disabled: disabled },
                                    { available: true, label: <FontAwesomeIcon icon={['fas', 'pound-sign']}/>, onClick: resolveBidDisabled() ? '' : toggleBid, disabled: resolveBidDisabled() || disabled, title: buttonText(bid?.status) },
                                    { available: true, label: <FontAwesomeIcon icon={['fas', formik.values[FIELDS.FILE] ? 'file-edit' : 'file-plus']}/>, onClick: () => handleFileClick(), disabled: disabled }
                                ]}
                                vertical={!isMobileSlim}
                                buttonStyles={{ borderRadius: !isMobileSlim && 0, height: '100%' }}
                                buttonsWidth={`${!isMobileSlim ? 15 : 100}%`}
                                buttonsHeight={!isMobileSlim ? `125px` : '38px'}
                            />
                        </div>

                        <Row className={style.files}>
                            <Col xs={10} sm={10} md={10} lg={10}>
                                { formik.values[FIELDS.FILE] ? `File uploaded: ${formik.values[FIELDS.FILE]?.name}` : `No attachments` }
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                { formik.values[FIELDS.FILE] ? <FontAwesomeIcon onClick={() => formik.setFieldValue(FIELDS.FILE, null)} className={style.clearButton} icon={['fas', 'times-circle']}/> : null }
                            </Col>
                        </Row>

                        <input type="file" style={{ visibility: 'hidden', position: 'absolute', top: 0 }} id={`chat-file`} onChange={(evt) => handleFileUpload(evt)}/>
                    </FormGroup>
                </Col>
                <Col md="6"></Col>
            </Row>
        </Form>
    );
};

export default MessageInputForm;
