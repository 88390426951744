import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardFooter, CardTitle, FormGroup, Label } from 'reactstrap';
import nudge_logo_white from '../../assets/images/nudge-n-chalk.png';
import nudge_logo from '../../assets/images/nudge-n-forest.png';
import pattern from '../../assets/videos/PATTERN_HD.mp4';
import AppInput from '../../components/AppInput';
import Loader from '../../components/Loader';
import { URLS } from '../../consts/urls';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../consts/userRoles';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import style from './style.module.scss';
import { FIELDS, initialValues, validationSchema } from './validation';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../components/buttons/NudgeButton';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/toast/ToastContext';

const SingleSignUp = () => {

    const { loginUser, isAuth, isAuthenticating, userRoles } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    
    const { createToast } = React.useContext(ToastContext);

    const { t } = useTranslation();

    const navigate = useNavigate();

    if (isAuthenticating === false && isAuth === true) {
        if (userRoles.includes(ADMIN)) {
            return <Navigate to={URLS.ADMIN_DASHBOARD} />;
        } else if (userRoles.includes(COMPANY)) {
            return <Navigate to={URLS.COMPANY_DASHBOARD} />;
        } else if (userRoles.includes(USER)) {
            return <Navigate to={URLS.DASHBOARD} />;
        } else if (userRoles.includes(RECRUITER)) {
            return <Navigate to={URLS.RECRUITER_DASHBOARD} />;
        }
    }

    const onClickSubmit = async (v) => {
        try {
            setIsLoading(true);
            await loginUser(v.email, v.password);
        } catch (error) {
            if (error.message === 'User is not confirmed.') {
                navigate(`${URLS.SIMPLE_ACCOUNT_VERIFY}`, { state: { userEmail: v.email, userPassword: v.password }});
            } else {
                createToast(error.message, 'error');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onCreateClick = () => {
        navigate(URLS.SIGN_UP);
    };

    const props = {
        validateOnBlur: true,
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: onClickSubmit,
    };
    const theme = GetPageTheme();

    return (
        <div className={style.box}>
            <div className={style.half1}>
                <Helmet>
                    <title>Sign In - Nudge Energy Talent Lab</title>
                </Helmet>
                <h5 className={style.videoSubtitle}>
                    <b>Find Your Why</b>
                    <br />
                    <i style={{ fontSize: '24px' }}>
                        Nudge theory: To help people without forcing them. From that small nudge comes a whole new life
                        and a whole new energy industry all from a single nudge.
                    </i>
                </h5>
                <video className={style.video} autoPlay muted loop id="myVideo">
                    <source id="myVideo" src={pattern} type="video/mp4" />
                </video>
            </div>
            <div className={style.half2}>
                <div className={theme == 'light' ? style.logoContainer : style.logoContainerDark}>
                    <Link to={URLS.HOME}>
                        <img src={nudge_logo_white} className={style.nudgeLogo} alt="nudge-white" />
                    </Link>
                    <h5 className={style.logoLabel}>{t('login.welcome')}</h5>
                </div>

                {!isLoading ? (
                    <Card id={`card-${theme}`} className={theme == 'light' ? style.signUpCard : style.signUpCardDark}>
                        <CardTitle id={`card-sub-${theme}`}>
                            <h4 className={style.signUpTitle}>{t('login.title')}</h4>
                        </CardTitle>

                        <Formik {...props}>
                            {(formik) => (
                                <div>
                                    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} style={{ padding: '18px' }}>
                                        <FormGroup className="mb-20">
                                            <Label for={FIELDS.EMAIL}>{t('login.email')}</Label>
                                            <AppInput id={FIELDS.EMAIL} formik={formik} label="Email" />
                                        </FormGroup>

                                        <FormGroup className="mb-20">
                                            <Label for={FIELDS.PASSWORD}>{t('login.password')}</Label>
                                            <AppInput
                                                id={FIELDS.PASSWORD}
                                                type={'password'}
                                                formik={formik}
                                                label="Password"
                                            />
                                        </FormGroup>

                                        <FormGroup className="mb-20">
                                            <Link to={URLS.FORGOT_PASSWORD}>{t('login.forgot_password')}</Link>
                                            <ClickButton className={style.signUp} color="success" type="submit">
                                                {t('login.submit')}
                                            </ClickButton>
                                        </FormGroup>

                                    </Form>
                                    <CardFooter id={`card-sub-${theme}`}>
                                        <h4>{t('login.no_account')}</h4>

                                        <ClickButton className={style.create} color="success" onClick={onCreateClick}>
                                            {t('login.signup')}
                                        </ClickButton>
                                    </CardFooter>
                                </div>
                            )}
                        </Formik>
                    </Card>
                ) : (
                    <Card id={`card-${theme}`} className={style.signUpCard}>
                        <Loader />
                    </Card>
                )}
            </div>
        </div>
    );
};

export default SingleSignUp;
