import { authPostData, postData, sendFile } from '../../api/tools';
import { FIELDS as FIELDS_AVAILABILITY } from '../../components/forms/AvailabilityForm/validation';
import { FIELDS as FIELDS_CERTIFICATIONS } from '../../components/forms/CertificatesForm/validation';
import { API_PROFILE, URLS } from '../../consts/urls';
import { CONTRACTOR_NUMERIC } from '../../consts/userRoles';
import { signUpUser } from '../../contexts/auth/tools';
import { FIELDS as FIELDS_PERSONAL } from './forms/PersonalDetails/PersonalDetailsForm/validation';
import { FIELDS as FIELDS_PHOTO } from './forms/Photo/PhotoForm/validate';
import { FIELDS as FIELDS_RESUME } from './forms/Resume/ResumeForm/validate';

export const FORMS_NAMES = {
    PERSONAL_DETAILS: 'PERSONAL_DETAILS',
    BIO: 'bio',
    SERVICE_SECTION: 'SERVICE_SECTION',
    INDUSTRY_EXPERIENCE: 'INDUSTRY_EXPERIENCE',
    SKILLS: 'SKILLS',
    QUALIFICATIONS: 'QUALIFICATION',
    CERTIFICATES: 'CERTIFICATES',
    AVAILABILITY: 'AVAILABILITY',
    RATE_RANGE: 'RATE_RANGE',
    RESUME: 'RESUME',
    PHOTO: 'PHOTO',
};

const getSkills = (skills) => (skills ? skills.skills.map((p) => p.name) : []);

const getEducation = (education) => (education ? education.qualification : '');

const getExperience = (experience) => (experience ? experience.projects : []);

const getAvailableFrom = (availability) => (availability ? availability[FIELDS_AVAILABILITY.START] : null);

const getAvailableTo = (availability) => (availability ? availability[FIELDS_AVAILABILITY.FINISH] : null);

export const getName = (userData) => userData[FIELDS_PERSONAL.FIRST_NAME] + ' ' + userData[FIELDS_PERSONAL.LAST_NAME];

export const signUpUserHelper = async (newData) => {
    const response = await signUpUser({
        email: newData[FORMS_NAMES.PERSONAL_DETAILS][FIELDS_PERSONAL.EMAIL],
        password: newData[FORMS_NAMES.PERSONAL_DETAILS][FIELDS_PERSONAL.PASSWORD],
        name: getName(newData[FORMS_NAMES.PERSONAL_DETAILS]),
        picture: '',
        type: CONTRACTOR_NUMERIC,
    });

    return response;
};

const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const testNewCertificates = async (certificates, url) => { 

    const sendCertificates = [];
    if (certificates?.length) {
        for (const { name, file, expireDate } of certificates) {
            var base = '';
            var ext = '';
            var expire = '';

            if (file) {
                base = await convertBase64(file);
                ext = `.${file.type.split('/')[1]}`;
            }

            if (expireDate) {
                if(expireDate !== "Invalid date") {
                    expire = expireDate
                }
            } 

            var obj = {
                name: name,
                file: base ? base.split(',')[1] : '',
                expireDate: expire,
                extension: ext,
            };
            sendCertificates.push(obj);
        }

        const sendObj = {
            certs: sendCertificates,
        };

        const response = await authPostData(sendObj, url); 
    }
};

export const sendCertifications = async (headers, certifications, url) => {
    if (certifications && certifications.length > 0) {
        const form = new FormData();
        let expire = { expire: [] };

        for (const { name, file, expireDate } of certifications) {
            form.append(name, file);

            if (expireDate) {
                expire.expire.push(expireDate);
            }
        }

        if (expire) {
            headers = { ...headers, ...expire };
        }

        await fetch(url, {
            method: 'POST',
            headers: headers,
            body: form,
        });
    }
};

export const sendAdditionalSignUpInfo = async (response, userData, navigate) => {
    const fileHeaders = { uid: response.userSub };

    const data = {
        Id: response.userSub,
        JobSubIndustryId: userData[FORMS_NAMES.SERVICE_SECTION].category,
        JobName: userData[FORMS_NAMES.SERVICE_SECTION].serviceOffered,
        Skills: getSkills(userData[FORMS_NAMES.SKILLS]),
        Education: getEducation(userData[FORMS_NAMES.QUALIFICATIONS]),
        Experience: getExperience(userData[FORMS_NAMES.INDUSTRY_EXPERIENCE]),
        AvailableFrom: getAvailableFrom(userData[FORMS_NAMES.AVAILABILITY]),
        AvailableTo: getAvailableTo(userData[FORMS_NAMES.AVAILABILITY]),
        ExpectedEarnings: userData[FORMS_NAMES.RATE_RANGE].expectedEarnings,
        Company: userData[FORMS_NAMES.PERSONAL_DETAILS].companyName,
        Bio: userData[FORMS_NAMES.BIO].bio,
    };

    await postData(data, API_PROFILE);

    if (userData[FORMS_NAMES.RESUME]) {
        const cv = userData[FORMS_NAMES.RESUME][FIELDS_RESUME.RESUME];
        await sendFile(fileHeaders, cv, `${API_PROFILE}/CV`);
    }

    if (userData[FORMS_NAMES.PHOTO]) {
        const photos = userData[FORMS_NAMES.PHOTO][FIELDS_PHOTO.PHOTO];
        await sendFile(fileHeaders, photos, `${API_PROFILE}/Photo`);
    }

    if (userData[FORMS_NAMES.CERTIFICATES]) {
        const certifications = userData[FORMS_NAMES.CERTIFICATES][FIELDS_CERTIFICATIONS.CERTIFICATIONS];
        await sendCertifications(fileHeaders, certifications, `${API_PROFILE}/Certificate`);
    }

    navigate(URLS.WELCOME);
};
