import PropTypes from 'prop-types';
import React from 'react';
import Step from './Step';
import style from './style.module.scss';
import { Col, Row } from 'reactstrap';

const NonEditableProjectHistory = ({ steps, theme }) => {

    function parseDuration(split) {
        const y = split[0];
        const m = split[1];
        let construct = "";

        if(y > 0) {
            construct += `${y} year${y != 1 ? `s` : ``}`
            construct += `, ${m} month${m != 1 ? `s` : ``}`
        } else {
            construct += `${m} month${m != 1 ? `s` : ``}`
        }

        return construct;
    }

    return (
        <Row>
            {steps.map((el, i) => (
                    <Col lg={6}>
                        <div id={`card-sub-${theme}`} className={style.project} key={i}>
                            <div className={style.projectName}>{el.name}</div>
                            <div className={style.projectDuration}>{`${parseDuration(el.duration.split("-"))}`}</div>
                            <div className={style.projectDetails}>{el.details}</div>

                            <Step {...el} index={i} theme={theme} />
                        </div>
                    </Col>
            ))}
        </Row>
    )
};

NonEditableProjectHistory.propTypes = {
    showEdit: PropTypes.bool,
    steps: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
};
export default NonEditableProjectHistory;
