import { FLASK_API } from '../consts/urls';
import { authFetcher } from './tools';

export const generateJobDescription = async (jobTitle) => {
    const res = await fetch(`${FLASK_API}/ai/generate_job_description`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ jobTitle: jobTitle }),
    }).catch((err) => {
        return null;
    });

    const data = await res.json();
    
    if(data.success !== true) return false;

    return data.response;
};

export const generateFeedbackFlask = async (input) => {
    const res = await fetch(`${FLASK_API}/ai/generate_feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reasons: input[0], extra: input[1] }),
    }).catch((err) => {
        return null;
    });

    const data = await res.json();

    if(data.success !== true) return false;

    return data.response;
};

export const uploadCVFlask = async (file, model) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', model);

    const res = await fetch(`${FLASK_API}/ai/parse-cv`, {
        method: 'POST',
        body: formData,
    }).catch((err) => {
        return null;
    });

    const data = await res.json();

    if (data.success !== true) return false;

    return data.response;
};

export const setZendeskToken = async (setNull) => {
    if (!window.zE) {
        return false;
    }

    if(setNull) {
        window.zE('messenger', 'logoutUser');
        return;
    }

    let token = null;
    const res = await authFetcher(`${FLASK_API}/support/token`);
    if (res?.token) {
        token = res.token;

        window.zE('messenger', 'loginUser', function (callback) {
            callback(token);
        });
    }
};
