import React from 'react';
import { Card } from 'reactstrap';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const NudgeCard = ({ className = '', onClick, children }) => {

    const theme = GetPageTheme();
    
    return (
        <Card id={`card-${theme}`} onClick={onClick} className={className}>
            {children}
        </Card>
    )
}

export default NudgeCard;