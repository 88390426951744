import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

import { Card, Col, Row } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';

import { API_DASHBOARD_WILLO, URLS } from '../../../consts/urls';
import {
    ResolveSalary,
    sort_by_key,
    OfferClosedFunction,
    capitaliseFirstLetter,
    GetPageTheme,
    deCamelString,
    generateMonoLinearGradient
} from '../../pages/GenericCompanyProfile/HelperComponents';

import style from '../style.module.scss';
import { authPostData } from '../../../api/tools';
import NudgeBoxes from '../../NudgeBoxes';
import ClickButton from '../../buttons/NudgeButton';
import NudgeTabs from '../../NudgeTabs';

export const OfferInfo = ({ data, company, mutate }) => {
    const navigate = useNavigate();
    const { user, userRoles } = useContext(AuthContext);
    const theme = GetPageTheme();

    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const toggleDescription = () => {
        setDescriptionOpen(!descriptionOpen);
    };

    const [fixing, setFixing] = React.useState(false);

    const leftWidth = 8;
    let qualification = 'loading/loading';

    if (data) {
        let qualArray = [];
        if (data.qualification) {
            qualArray = data.qualification?.split('/');
        } else {
            qualArray = ['', ''];
        }

        if (qualArray[1]) {
            qualification = qualArray[0] + ' in ' + qualArray[1];
        } else {
            qualification = data.qualification;
        }

        data.skills = sort_by_key(data.skills, 'name').reverse();
        data.certificates = sort_by_key(data.certificates, 'name').reverse();

        data.skills = sort_by_key(data.skills, 'required').reverse();
        data.certificates = sort_by_key(data.certificates, 'required').reverse();
    }

    function GetProfileLink() {
        if (userRoles.includes('Admin')) {
            return `${URLS.ADMIN_COMPANIES}/${data.company.id}`;
        } else if (userRoles.includes('User')) {
            return `${URLS.DIRECTORY}/${data.company.id}`;
        } else if (userRoles.includes('Recruiter')) {
            return `${URLS.RECRUITER_COMPANY_PROFILE}/${data.company.id}`;
        } else {
            return `${URLS.COMPANY_PROFILE}`;
        }
    }
    
    function resolveFixedHours(from, to) {
        let fromNum = parseInt(from);
        let toNum = parseInt(to);

        let fromText = '';
        let toText = '';

        if (fromNum > 1259) {
            fromText = `${fromNum - 1200}`;

            if (fromText.length == 3) {
                fromText = `${fromText.substring(0, 1)}:${fromText.substring(1, 3)}pm`;
            } else {
                fromText = `${fromText.substring(0, 2)}:${fromText.substring(2, 4)}pm`;
            }
        } else {
            fromText = `${fromNum}`;
            let addendum = fromNum > 1159 ? 'pm' : 'am';

            if (fromText.length == 3) {
                fromText = `${fromText.substring(0, 1)}:${fromText.substring(1, 3)}${addendum}`;
            } else {
                fromText = `${fromText.substring(0, 2)}:${fromText.substring(2, 4)}${addendum}`;
            }
        }

        if (toNum > 1259) {
            toText = `${toNum - 1200}`;

            if (toText.length == 3) {
                toText = `${toText.substring(0, 1)}:${toText.substring(1, 3)}pm`;
            } else {
                toText = `${toText.substring(0, 2)}:${toText.substring(2, 4)}pm`;
            }
        } else {
            toText = `${toNum}`;
            let addendum = toNum > 1159 ? 'pm' : 'am';

            if (toText.length == 3) {
                toText = `${toText.substring(0, 1)}:${toText.substring(1, 3)}${addendum}`;
            } else {
                toText = `${toText.substring(0, 2)}:${toText.substring(2, 4)}${addendum}`;
            }
        }

        return `Fixed hours ${fromText} to ${toText}`;
    }

    function parseQuestions(questions) {
        var returnQuestions = [];

        questions.forEach((item, index) => {
            let q = {
                text: item.question,
                order: index + 1,
                answer_type: item.type,
                thinking_time: parseInt(item.thinking_time),
                max_words: parseInt(item.max_words),
                max_retakes: parseInt(item.max_retakes),
                max_duration: parseInt(item.max_duration),
                location_of_employment: "USA"
            };
            returnQuestions.push(q);
        });

        return returnQuestions;
    }

    async function findSchema(values) {
        const v = values;

        const questions = parseQuestions(v.questions);
        const requestSchema = {
            title: v.title,
            department: process.env.REACT_APP_WILLO_DEPARTMENT_KEY, // nudge testing labs department key
            message_templates: {
                invitation_email: null,
                invitation_sms: null,
                reminder_email: null,
                reminder_sms: null,
                interview_completed_email: null,
            },
            questions: questions,
            settings: {
                redirect_url: 'https://nudgetalentlab.com/',
                show_hints_and_tips: true,
                show_availability_calendar: true,
                deadline: null, //moment(v[FIELDS.CLOSE_DATE]).format('YYYY-MM-DD')
            },
        };

        const response = await authPostData(requestSchema, `${API_DASHBOARD_WILLO}/Interviews/CreateInterview`);

        return response;
    }

    async function FixInterview() {
        setFixing(true);
        const response = await findSchema(data);

        if (response) {
            if (response.key) {
                var url = `${API_DASHBOARD_WILLO}/Interviews/${data.id}/UpdateKey/${response.key}`;
                var fixResponse = await authPostData({}, url);

                await mutate();

                setFixing(false);
            } else {
                setFixing(false);
            }
        } else {
            setFixing(false);
        }
    }

    const TABS = {
        VIDEO: 'videoInterview',
        VR: 'vrAssessment',
        FACE: 'faceToFace'
    }

    const [selectedTab, setSelectedTab] = React.useState(data.hasInterview ? TABS.VIDEO : data.hasEdify ? TABS.VR : data.faceToFace ? TABS.FACE : '');

    return (
        <>
            <Row>
                <Col xs={12} md={12} lg={leftWidth}>
                    <Card id={`card-${theme}`} className={style.offerDetailsCard}>
                        <h4 className={style.offerTitle}>{data.title}</h4>
                        <NudgeBoxes
                            rows={1}
                            data={[
                                { label: data.location ? data.location : "Remote", danger: false },
                                { label: ResolveSalary(data.rangeExpected, data.currencyType, data.frequency, data.workType), danger: false},
                                { label: `Start Date: ${data.startDate || 'TBD'}`, danger: false},
                                { label: data.flexibility === 'Fixed' ? resolveFixedHours(data.fixedFrom, data.fixedTo) : `${'Flexible hours'}`}
                            ]}
                        />
                        <h5 className={style.closeDate}>{data.validFrom ? 'Published: ' + data.validFrom : null}</h5>
                    </Card>

                    {company ? (
                        !data.interviewKey && data.hasInterview ? (
                            <Card id={`card-${theme}`} className={style.interviewMissingCard}>
                                <Row>
                                    <Col className={style.hourglass} xs={2} md={1}>
                                        <FontAwesomeIcon
                                            className={fixing ? style.loader : ''}
                                            icon={['fal', !fixing ? 'bell-exclamation' : 'sync']}
                                        />
                                    </Col>
                                    <Col xs={10} md={11}>
                                        <h5 className={style.offerLiveTitle}>
                                            {!fixing
                                                ? 'This job posting has video interviews enabled, but does not have an interview created for it. If this job posting has just been added, this will be in error. Please click the button below to fix this error.'
                                                : 'Fixing Interview'}
                                        </h5>
                                        {!fixing ? (
                                            <ClickButton color="danger" onClick={FixInterview}>
                                                Fix Interview
                                            </ClickButton>
                                        ) : null}
                                    </Col>
                                </Row>
                            </Card>
                        ) : (
                            <></>
                        )
                    ) : null}

                    {data.availabilityText ? (
                        <Card
                            className={
                                OfferClosedFunction(data.availabilityText)
                                    ? style.offerLiveCardClosed
                                    : style.offerLiveCard
                            }
                        >
                            <Row>
                                <Col className={`${style.hourglass}`} xs={2} md={1}>
                                    <FontAwesomeIcon icon={['fal', 'clock']} />
                                </Col>
                                <Col xs={10} md={11}>
                                    <h5 className={style.offerLiveTitle}>{data.availabilityText}</h5>
                                </Col>
                            </Row>
                        </Card>
                    ) : null}

                    <Card className={style.jobDescriptionCard}>
                        <Row>
                            {data.description?.length > 220 ? (
                                <FontAwesomeIcon
                                    onClick={() => toggleDescription()}
                                    className={descriptionOpen ? style.expand : style.expandRotate}
                                    icon={['fal', 'angle-up']}
                                />
                            ) : null}
                            <Col xs={12} md={12}>
                                <h5 className={style.jobDescriptionTitle}>About the Job</h5>
                            </Col>
                        </Row>
                        <h5
                            className={
                                descriptionOpen
                                    ? style.jobDescriptionBodyOpen
                                    : data.description?.length < 100
                                    ? style.jobDescriptionBodyOpen
                                    : style.jobDescriptionBody
                            }
                        >
                            {data.description}
                        </h5>
                    </Card>

                    <Card className={style.qualificationsCard}>
                        <div className={style.qualificationHeader}>
                            <FontAwesomeIcon className={style.icon} icon={['fas', 'graduation-cap']} />
                            <h5>Education</h5>
                        </div>
                        <h5>
                            {qualification} <i>{data.required ? '(required)' : '(desired)'}</i>{' '}
                        </h5>
                    </Card>

                    <Row>
                        <Col md={12} lg={6}>
                            <Card className={style.skillsCard}>
                                <div className={style.qualificationHeader}>
                                    <FontAwesomeIcon className={style.icon} icon={['fas', 'brain']} />
                                    <h5>Skills</h5>
                                </div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <b style={{ textTransform: 'uppercase', marginBottom: '12px' }}>Required</b>

                                        { data?.skills.filter((item) => item.required).length ? <NudgeBoxes
                                            data={data.skills.filter((item) => item.required).map((item) => ({ label: item.name, danger: false}))}
                                            fullWidth
                                        /> : <div><i>Empty</i></div> }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <b style={{ textTransform: 'uppercase', marginBottom: '12px' }}>Desired</b>

                                        { data.skills.filter((item) => !item.required) ? <NudgeBoxes
                                            data={data.skills.filter((item) => !item.required).map((item) => ({ label: item.name, danger: false}))}
                                            fullWidth
                                        /> : <div><i>Empty</i></div> }
                                    </Col>
                                </Row> 
                            </Card>
                        </Col>
                        <Col md={12} lg={6}>
                            <Card className={style.certificatesCard}>
                                <div className={style.qualificationHeader}>
                                    <FontAwesomeIcon className={style.icon} icon={['fas', 'certificate']} />
                                    <h5>Qualifications/Certificates</h5>
                                </div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <b style={{ textTransform: 'uppercase', marginBottom: '12px' }}>Required</b>

                                        { data?.certificates.filter((item) => item.required).length ? <NudgeBoxes
                                            data={data.certificates.filter((item) => item.required).map((item) => ({ label: item.name, danger: false}))}
                                            fullWidth
                                        /> : <div><i>Empty</i></div> }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <b style={{ textTransform: 'uppercase', marginBottom: '12px' }}>Desired</b>

                                        { data?.certificates.filter((item) => !item.required).length ? <NudgeBoxes
                                            data={data.certificates.filter((item) => !item.required).map((item) => ({ label: item.name, danger: false}))}
                                            fullWidth
                                        /> : <div><i>Empty</i></div> }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={12 - leftWidth}>
                    { !userRoles.includes("Company") ? <Card className={style.companyCard}>
                        <img className={style.companyImage} src={data?.company?.logo} alt="company" style={{ borderColor: `#${data.company.backColour || "407962"}` }} />
                        <div className={style.banner} style={{ background: generateMonoLinearGradient(`#${data.company.backColour || "407962"}`), borderBottom: `2px solid ${`#${data.company.backColour || "407962"}`}` }}/>

                        <h4 className={style.companyName}>{data?.company?.name}</h4>
                        <ClickButton color="success" onClick={() => navigate(GetProfileLink())}>
                            <b>View Profile</b>
                        </ClickButton>
                    </Card> : null }

                    {data.attachedFiles?.length ? (
                        <Card className={style.additionalFilesCard}>
                            <Row>
                                <div className={style.qualificationHeader}>
                                    <FontAwesomeIcon className={style.icon} icon={['fas', 'file-alt']} />
                                    <h5>Attached Files</h5>
                                </div>
                            </Row>
                            {data.attachedFiles.map((item) => (
                                <Row>
                                    <div className={style.qualificationHeader}>
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            {item.name}
                                        </a>
                                    </div>
                                </Row>
                            ))}
                        </Card>
                    ) : null}

                    { data.hasInterview || data.hasEdify || data.faceToFace ? <div className={style.qualificationHeader}>
                        <FontAwesomeIcon className={style.icon} icon={['fas', 'people-arrows']} />
                        <h5>Interview Process</h5>
                    </div> : null }

                    <NudgeTabs
                        label="Interview Steps"
                        selected={selectedTab}
                        setTab={setSelectedTab}
                        tabs={[
                            { label: 'Video Interview', id: TABS.VIDEO, condition: data.hasInterview },
                            { label: 'VR Assessment', id: TABS.VR, condition: data.hasEdify },
                            { label: 'Face-to-Face Interview', id: TABS.FACE, condition: data.faceToFace },
                        ]}
                        minHeight={`56px`}
                        buttons={1}
                    />

                    { data.questions && data.hasInterview && selectedTab == TABS.VIDEO ? (
                        <Card className={style.additionalFilesCard}>
                            <Row>
                                <div className={style.qualificationHeader}>
                                    <FontAwesomeIcon className={style.icon} icon={['fas', 'video']} />
                                    <h5>Video Interview Questions</h5>
                                </div>
                            </Row>
                            { sort_by_key(data.questions, 'questionOrder').map((item) => (
                                <Row>
                                    <div>
                                        <h5 className={style.questions}>- {item.question}</h5>
                                        <p>
                                            <b>{capitaliseFirstLetter(item.type, true)}</b>
                                        </p>
                                    </div>
                                </Row>
                            )) }
                        </Card>
                    ) : selectedTab == TABS.VIDEO ? <Card className={style.additionalFilesCard}>
                        <i>Interview questions are hidden until the video interview stage.</i>
                    </Card> : null }

                    { data.hasEdify && selectedTab == TABS.VR ? <Card className={style.additionalFilesCard}>
                            <div className={style.qualificationHeader}>
                                <FontAwesomeIcon className={style.icon} icon={['fas', 'head-vr']} />
                                <h5>VR Assessment Skills</h5>
                            </div>
                            { data.assessmentSkills ? <Row>
                                { Object.keys(data.assessmentSkills).map((category, index1) => <Col md={12}>
                                    <b><u>{ deCamelString(category) }</u></b>
                                    <NudgeBoxes
                                        data={data.assessmentSkills[category].map((item) => ({ label: item, danger: false}))}
                                        fullWidth
                                    />
                                </Col>)}
                            </Row> : <i>Empty</i> }
                    </Card> : selectedTab == TABS.VR ? <Card className={style.additionalFilesCard}>
                        <i>Cannot view skills</i>
                    </Card> : null }

                    { data.faceToFace && selectedTab == TABS.FACE ? <Card className={style.additionalFilesCard}>
                        <i>Under Construction</i>
                    </Card> : null }

                    {data.type !== 'Staff' ? (
                        <Card className={style.additionalFilesCard}>
                            <Row>
                                <div className={style.qualificationHeader}>
                                    <FontAwesomeIcon className={style.icon} icon={['fas', 'info-square']} />
                                    <h5>IR35 Status?</h5>
                                </div>
                            </Row>
                            <Row>
                                <div className={style.qualificationHeader}>
                                    <h5>{data.outsideIR35 == 'Potentially' ? 'Potentially' : 'No'}</h5>
                                </div>
                            </Row>
                        </Card>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default OfferInfo;
