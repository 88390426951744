import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';

import AppInput from '../../components/AppInput';
import SignInPageTemplate from '../../components/SignInPageTemplate';
import { FLASK_API } from '../../consts/urls';
import { COMPANY } from '../../consts/userRoles';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';

export const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [isSending, setIsSending] = useState(false);

    const createMessage = (sender, text) => {
        const message = {
            id: Math.random(),
            sender: sender,
            senderText: sender === 'user' ? 'You' : 'Nudgebot',
            text: text,
        };
        setMessages((messages) => [...messages, message]);

        const chatLog = document.querySelector(`.${style.chatLog}`);
        chatLog.scrollTop = chatLog.scrollHeight;
    };

    const requestChatbotResponse = async (text) => {
        const messagesToSend = messages.map((message) => {
            return {
                role: message.sender,
                text: message.text,
            };
        });
        messagesToSend.push({ role: 'user', text: text });

        const res = await fetch(`${FLASK_API}/chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messagesToSend }),
        }).catch((err) => {
            console.log(err);
            return null;
        });

        const data = await res.json();
        if (data.success === true) {
            return data.response;
        } else {
            return false;
        }
    };

    const formik = useFormik({
        initialValues: {
            message: '',
        },
        onSubmit: (values) => {
            setIsSending(true);
            createMessage('user', values.message);
            formik.resetForm();

            requestChatbotResponse(values.message).then((response) => {
                if (response !== false) {
                    createMessage('bot', response);
                } else {
                    createMessage('bot', "Sorry, I didn't understand that.");
                }
                setIsSending(false);
                const input = document.querySelector('input[name="message"]');
                input.focus();
            });
        },
    });

    return (
        <SignInPageTemplate>
            <Row>
                <Col xs={12} xl={8}>
                    <Card>
                        <CardBody>
                            <div className={style.chatBody}>
                                <div className={style.chatHeader}>
                                    <h3 style={{ marginTop: '0', fontWeight: '600' }}>Ask me anything!</h3>
                                </div>

                                <div className={style.chatLog}>
                                    <div className={style.chatMessage} data-type="bot">
                                        <div className={style.chatMessageSender}>Nudgebot</div>
                                        <div className={style.chatMessageContent}>
                                            <div className={style.chatMessageText}>
                                                <p>Hi! Feel free to ask me anything about the UK energy industry.</p>
                                            </div>
                                        </div>
                                    </div>

                                    {messages.map((message, index) => {
                                        return (
                                            <div
                                                className={style.chatMessage}
                                                key={message.id}
                                                data-type={message.sender}
                                            >
                                                <div className={style.chatMessageSender}>{message.senderText}</div>
                                                <div className={style.chatMessageContent}>
                                                    <div className={style.chatMessageText}>
                                                        <p>{message.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className={style.chatFooter}>
                                    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                        <div className={style.inputContainer}>
                                            <AppInput
                                                id="message"
                                                formik={formik}
                                                label="Type your message, please..."
                                                type="text"
                                                className={style.input}
                                                disabled={isSending}
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Chatbot, [COMPANY]);
