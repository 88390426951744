import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, NEVER_REFRESH } from '../../../../api/tools';
import { MATCH_STATUSES } from '../../../../consts/matchOfferStatuses';
import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import Loader from '../../../Loader';
import style from './style.module.scss';
import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageModal from './MessageModal';
import { URLS } from '../../../../consts/urls';
import NudgeButton from '../../../buttons/NudgeButton';
import NudgeModal from '../../../NudgeModal';

import NudgeButtonGroup from '../../../buttons/ButtonGroup';
import NudgeTooltip from '../../../NudgeTooltip';

function getContractorUrl(offerId, candidateId, roles) {
    if (roles.includes('Company')) {
        return `/company/offer/${offerId}/contractors/${candidateId}`;
    } else if (roles.includes('Recruiter')) {
        return `/recruiter/contractors/${candidateId}`;
    } else {
        return `/admin/contractors/${candidateId}`;
    }
}

const KanbanColumn = ({ data, theme, columnData, offerId, roles, disabled, setContractor, setEmail }) => {
    const navigate = useNavigate();

    return (
        !disabled ? <div className={style.kanbanCol}>
            <Card className={style.kanbanCard}>
                <CardHeader className={style.kanbanHeader}>
                    <span className={style.kanbanColHeader}>{columnData.name}</span>
                </CardHeader>
                <CardBody className={style.kanbanBody}>
                    {columnData.subColumns.map((subColumn) => (
                        <div>
                            <div className={style.kanbanSubCol} key={subColumn.name}>
                                <div id={`card-sub-${theme}`} className={style.kanbanSubColHeader}>{subColumn.name}</div>
                                <div id={`card-${theme}`} style={{ padding: '6px' }}>
                                    {data.filter((el) => subColumn.stageKeys.includes(el.status || el.matchStatus))
                                        .map((el) => <div style={{ marginBottom: '6px' }}>
                                            <div className={theme == 'light' ? style.kanbanItem : style.kanbanItemDark}>
                                                <h4 className={style.contractorLink} style={{ width: '60%' }} onClick={() => window.open(getContractorUrl(offerId, el.id, roles), '_blank', 'noreferrer')}>{el.username || el.name}</h4>
                                                { roles.includes("Admin") ? <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                                                    <NudgeTooltip noModal iconOnly icon={'at'} id={`${el.id}-nametooltip`}>
                                                        {el.email}
                                                    </NudgeTooltip>
                                                </div> : null }
                                            </div>
                                            <NudgeButtonGroup
                                                buttons={[
                                                    // { label: <FontAwesomeIcon icon={['fas', 'at']}/>, available: roles.includes("Admin"), onClick: () => setEmail(el.email) },
                                                    { label: <FontAwesomeIcon icon={['fas', 'external-link-alt']}/>, available: !roles.includes("Admin"),  onClick: () => navigate(`${URLS.COMPANY_INTERVIEWS}/${el.matchId}`) },
                                                    { label: <FontAwesomeIcon icon={['fas', 'comments-alt']}/>, available: true, onClick: () => setContractor(el) },
                                                ]}
                                                buttonStyles={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                                            />

                                            {/* <div style={{ display: 'flex', columnGap: '6px' }}>
                                                { !roles.includes("Admin") ? <NudgeButton label={`View Match`} onClick={() => navigate(`${URLS.COMPANY_INTERVIEWS}/${el.matchId}`)} className="w-100"><FontAwesomeIcon icon={['fas', 'external-link-alt']}/></NudgeButton> : null }
                                                <NudgeButton label={`View Chat`} onClick={() => setContractor(el)} className="w-100"><FontAwesomeIcon icon={['fas', 'comments-alt']}/></NudgeButton>
                                                { roles.includes("Admin") ? <NudgeButton onClick={() => setEmail(el.email)} label={`View Candidate Email`} className="w-100"><FontAwesomeIcon icon={['fas', 'at']}/></NudgeButton> : null }
                                            </div> */}
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </CardBody>
            </Card>
        </div> : null 
    );
};

const Candidates = ({ roleUrl, offer }) => {
    const { id: offerId } = useParams();
    const { user, userRoles: roles } = useContext(AuthContext);
    const theme = GetPageTheme();

    const columnData = [
        {
            name: 'Registered Interest',
            subColumns: [
                { name: 'Awaiting Admin Approval', stageKeys: [MATCH_STATUSES.AdminApproval] },
                { name: 'Admin Rejected', stageKeys: [MATCH_STATUSES.AdminRejected] },
            ],
        },
        {
            name: 'Shortlisted',
            subColumns: [
                { name: 'Pending Application', stageKeys: [MATCH_STATUSES.ContractorInvited,] },
                { name: 'Applied', stageKeys: [MATCH_STATUSES.Applied] },
                { name: 'Dismissed', stageKeys: [MATCH_STATUSES.Rejected, MATCH_STATUSES.CompanyRejected] },
            ],
        },
        {
            name: 'Video Interview',
            subColumns: [
                { name: 'Invited', stageKeys: [MATCH_STATUSES.WilloInvited] },
                { name: 'Submitted', stageKeys: [MATCH_STATUSES.InterviewSubmitted] },
            ],
        },
        {
            name: 'VR Assessment',
            subColumns: [
                { name: 'Not Scheduled', stageKeys: [MATCH_STATUSES.WaitingOnVRSchedule] },
                { name: 'Scheduled', stageKeys: [MATCH_STATUSES.VRAssessmentScheduled] },
                { name: 'Submitted', stageKeys: [MATCH_STATUSES.VRAssessmentSubmitted] },
            ],
        },
        {
            name: 'Company Interview',
            subColumns: [
                { name: 'Not Scheduled', stageKeys: [MATCH_STATUSES.WaitingOnFaceSchedule] },
                { name: 'Scheduled', stageKeys: [MATCH_STATUSES.FaceInterviewScheduled] },
            ],
        },
        { name: 'Onboarded', subColumns: [{ name: 'Accepted', stageKeys: [MATCH_STATUSES.CompanyAccepted] }] },
    ];

    let { data } = useSWR(`${roleUrl}/Offers/${offerId}/Contractors`, authFetcher, NEVER_REFRESH);

    if(roles.includes("Admin")) {
        if(data?.contractors) {
            data = data?.contractors
        }
    }

    const [contractor, setContractor] = React.useState();
    const [contractorEmail, setContractorEmail] = React.useState();
    const toggleContractor = () => setContractor(``) 
    
    return !!data ? (
        data.length ? (
            <div className={style.kanbanWrapper}>
                { contractorEmail ? <NudgeModal id={`modal-${theme}`} isOpen={!!contractorEmail} toggle={() => setContractorEmail(``)} headerText={"Candidate Email Address"}>
                    <ModalBody>
                        { contractorEmail }
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </NudgeModal> : null }
                { contractor ? <MessageModal user={user} roles={roles} contractor={contractor} isOpen={!!contractor} toggle={toggleContractor}/> : null }
                {columnData.map((column) => (
                    <KanbanColumn
                        theme={theme}
                        key={column.name}
                        data={data}
                        columnData={column}
                        offerId={offerId}
                        roles={roles}
                        disabled={
                            (!offer.hasInterview && column.name === 'Video Interview') ||
                            (!offer.hasEdify && column.name === 'VR Assessment')
                        }
                        setContractor={setContractor}
                        setEmail={setContractorEmail}
                    />
                ))}
            </div>
        ) : (
            <h5>No candidates have been matched yet. You will be notified when our team has made a good match.</h5>
        )
    ) : (
        <div style={{ height: '60vh' }}>
            <Loader />
        </div>
    );
};
export default Candidates;
