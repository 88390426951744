import React from 'react';
import NudgeButton from '../../../../buttons/NudgeButton';
import useModal from '../../../../../hooks/useModal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import { AuthContext } from '../../../../../contexts/auth/AuthContextWrapper';
import { getProfileCompletion, getTasks } from '../../../../../consts/tasks';
import NudgeModal from '../../../../NudgeModal';

const RegisterInterestModal = ({ onClick, disabled, size = "lg", children }) => {

    const { user } = React.useContext(AuthContext);
    const { open, toggle } = useModal();

    const tasks = getTasks(user);
    const profileCompletion = getProfileCompletion(tasks);
    
    function registerFunction() {
        toggle();
        onClick();
    }

    const theme = GetPageTheme();
    return (
        <>
            <NudgeButton size={size} color="success" disabled={disabled} onClick={profileCompletion == 100 ? onClick : toggle}>{ children }</NudgeButton>
            <NudgeModal id={`modal-${theme}`} isOpen={open} toggle={toggle} headerText={"Warning: Profile Not Complete"}>
                <ModalBody>
                    <h5>Your profile is not complete. Completing your profile gives you a better chance of being accepted for this position. Do you still want to register your interest?</h5>
                </ModalBody>
                <ModalFooter>
                    <NudgeButton color="success" onClick={registerFunction}>Register Interest</NudgeButton>
                </ModalFooter>
            </NudgeModal>
        </>
    )
}

export default RegisterInterestModal;