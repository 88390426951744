/* eslint-disable max-len */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';

import AppInput from '../../components/AppInput';
import { FLASK_API } from '../../consts/urls';
import style from './style.module.scss';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';

const TRIES_MAP = {
    0: 'no tries',
    1: 'one try',
    2: 'two tries',
    3: 'three tries',
};

export const SpaceStation = () => {
    const [currentTries, setCurrentTries] = useState(3);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const verifyCode = async (code) => {
        const res = await fetch(`${FLASK_API}/space-station/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code, attempt: 4 - currentTries }),
        }).catch((err) => {
            return null;
        });

        const data = await res.json();
        if (data.success === true) {
            return true;
        } else {
            return false;
        }
    };

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: (values) => {
            setErrorMessage(null);
            setButtonsDisabled(true);
            const entered_code = values.code.trim();

            if (!/^\d{4}$/.test(entered_code)) {
                setErrorMessage('Please enter a valid code (4 digit number).');
                formik.resetForm();
                setButtonsDisabled(false);
                return;
            }

            verifyCode(entered_code).then((isCorrect) => {
                if (isCorrect === true) {
                    setCurrentTries(0);
                    setSuccessMessage('Correct code!');
                } else if (isCorrect === false) {
                    setCurrentTries(currentTries - 1);
                    setErrorMessage('Incorrect code! You have ' + TRIES_MAP[currentTries - 1] + ' remaining.');

                    if (currentTries <= 1) {
                        // setFinished(true);
                    } else {
                        setButtonsDisabled(false);
                    }
                } else {
                    setErrorMessage('Something went wrong. Please try again later.');
                    setButtonsDisabled(false);
                }

                formik.resetForm();
            });
        },
    });

    const onInputClick = (e) => {
        var target = e.currentTarget;
        if (target.type !== 'button') {
            target = target.parentElement;
        }

        if (target.dataset) {
            if (target.dataset.action === 'back') {
                formik.setFieldValue('code', formik.values.code.slice(0, -1));
                return;
            } else if (target.dataset.action === 'clear') {
                formik.setFieldValue('code', '');
                return;
            }
        }

        let value = target.innerText;
        formik.setFieldValue('code', formik.values.code + value);
    };

    const theme = GetPageTheme();
    return (
        <Container fluid className={style.container}>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody className={style.card}>
                            {true && (
                                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                    <div className={style.inputContainer}>
                                        <Row className={`${style.row} ${style.topRow}`}>
                                            <Col xs={12}>
                                                <div
                                                    id="status"
                                                    className={`${style.status} ${errorMessage ? style.error : ''} ${
                                                        successMessage ? style.success : ''
                                                    }`}
                                                >
                                                    {errorMessage && <p>{errorMessage}</p>}
                                                    {successMessage && <p>{successMessage}</p>}
                                                    {!errorMessage && !successMessage && (
                                                        <p>
                                                            {buttonsDisabled
                                                                ? 'Checking...'
                                                                : 'Enter the code using the keypad and press the checkmark.'}
                                                        </p>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={style.row}>
                                            <Col xs={3}>
                                                <button
                                                    onClick={onInputClick}
                                                    type="button"
                                                    data-action="back"
                                                    disabled={buttonsDisabled}
                                                >
                                                    <FontAwesomeIcon icon="backspace" />
                                                </button>
                                            </Col>
                                            <Col xs={6}>
                                                <AppInput
                                                    id="code"
                                                    formik={formik}
                                                    label="Code"
                                                    type="text"
                                                    className={style.input}
                                                    disabled={currentTries === 0}
                                                />
                                            </Col>
                                            <Col xs={3}>
                                                <button
                                                    className={style.submitButton}
                                                    type="submit"
                                                    disabled={buttonsDisabled}
                                                >
                                                    <FontAwesomeIcon icon="check" />
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row className={style.row}>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    1
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    2
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    3
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    4
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row className={style.row}>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    5
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    6
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    7
                                                </button>
                                            </Col>
                                            <Col xs={3}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    8
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row className={style.row}>
                                            <Col xs={6}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    9
                                                </button>
                                            </Col>
                                            <Col xs={6}>
                                                <button onClick={onInputClick} type="button" disabled={buttonsDisabled}>
                                                    0
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SpaceStation;
