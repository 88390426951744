import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'name',
    START: 'startDate',
    SKILLS: 'skills',
    QUESTIONS: 'questions',
    CERTIFICATES: 'certificates',
    OUTSIDE: 'outside',
    SCOPE: 'scope',
    LOCATION: 'location',
    DURATION: 'duration',
    REVENUE: 'revenue',
    REVENUEMAX: 'revenueMax',
    REVENUEMIN: 'revenueMin',
    REVENUEFREQ: 'salaryFreq',

    REVIEW: 'review',
    MATCH_TYPE: 'hasInterview',

    FILES: 'files',
    CLOSE_DATE: 'validTo',
    PUBLISH_DATE: 'validFrom',
    LOCATION_SELECT: 'locationType',
    MIN_QUALIFICATIONS: 'qualification',
    STUDY_FIELD: 'studyField',
    QUAL_REQUIRED: 'qualificationRequired',

    OFFERTYPE: 'offerType',
    SECTOR: 'sector',
    CATEGORY: 'subIndustryId',

    FLEXIBILITY: 'flexibility',
    FLEXIBLE_HOURS: 'hoursFlexible',
    FLEXIBLE_PERIOD: 'periodFlexible',
    FLEXIBLE_OPTIONAL: 'flexibleOptional',

    FIXED_FROM: 'fixedFrom',
    FIXED_TO: 'fixedTo',

    HOURS_FROM: 'hoursFrom',
    HOURS_TO: 'hoursTo',
    MINUTES_FROM: 'minutesFrom',
    MINUTES_TO: 'minutesTo',

    TIMING: 'Timing',
    WORK_TYPE: 'workType',
    EMPLOYEE_LEVEL: 'employeeLevel',
    WEIGHTS: 'weights',
};

export const VALUE_TO_VALIDATE_DURATION = 'STAFF';
export const VALUE_TO_SHOW_LOCATION_INPUT = 'ONSITE';

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required'),
    [FIELDS.REVENUE]: Yup.string().matches(/^[0-9]+-[0-9]+$/, 'Use correct format: range-range'),
    [FIELDS.OFFERTYPE]: Yup.string().required('This field is required'),
    [FIELDS.SCOPE]: Yup.string().required('This field is required'),
    [FIELDS.MATCH_TYPE]: Yup.string().required('This field is required'),
    [FIELDS.DURATION]: Yup.string().when(FIELDS.OFFERTYPE, {
        is: (label) => label !== VALUE_TO_VALIDATE_DURATION,
        then: Yup.string()
            .matches(/^[0-9]+$/, 'This field should be a number')
            .required('This field is required'),
    }),
    [FIELDS.START]: Yup.string().required('This field is required'),
    [FIELDS.FILES]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('This field is required!'),
                file: Yup.mixed().required('Upload is required!'),
            }),
        ),
    }),
    [FIELDS.CLOSE_DATE]: Yup.string().required('This field is required'),
    [FIELDS.PUBLISH_DATE]: Yup.string().required('This field is required'),
    [FIELDS.SKILLS]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string()
                            .test('len', 'Skill description must be between 1 and 50 characters.', (val) =>
                                val ? val.length <= 50 : null,
                            )
                            .required('This field is required.'),
                        required: Yup.boolean().required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),

    [FIELDS.QUESTIONS]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string().required('This field is required.'),
                        type: Yup.string().required('This field is required.'),
                        thinking_time: Yup.number()
                            .typeError('This field must contain a number.')
                            .when(`${FIELDS.QUESTIONS}.type`, {
                                is: (label) => 'video/audio'.includes(label),
                                then: Yup.number().typeError('Must be a number.').required('This field is required.'),
                            }),
                        max_retakes: Yup.number()
                            .typeError('This field must contain a number.')
                            .when(`${FIELDS.QUESTIONS}.type`, {
                                is: (label) => 'video/audio'.includes(label),
                                then: Yup.number()
                                    .typeError('This field must contain a number.')
                                    .required('This field is required.'),
                            }),
                        max_duration: Yup.number()
                            .typeError('This field must contain a number.')
                            .when(`${FIELDS.QUESTIONS}.type`, {
                                is: (label) => 'video/audio'.includes(label),
                                then: Yup.number()
                                    .typeError('This field must contain a number.')
                                    .required('This field is required.'),
                            }),
                        max_characters: Yup.number()
                            .typeError('This field must contain a number.')
                            .when(`${FIELDS.QUESTIONS}.type`, {
                                is: (label) => 'text'.includes(label),
                                then: Yup.number().typeError('Must be a number.').required('This field is required.'),
                            }),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),

    [FIELDS.CERTIFICATES]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string()
                            .test('len', 'Skill description must be between 1 and 50 characters.', (val) =>
                                val ? val.length <= 50 : null,
                            )
                            .required('This field is required.'),
                        required: Yup.boolean().required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),
    [FIELDS.LOCATION]: Yup.string().when(FIELDS.LOCATION_SELECT, {
        is: (label) => label === VALUE_TO_SHOW_LOCATION_INPUT || label === 'BOTH',
        then: Yup.string().required('This field is required'),
    }),
    [FIELDS.LOCATION_SELECT]: Yup.string().required('This field is required'),
    [FIELDS.MIN_QUALIFICATIONS]: Yup.string().required('This field is required'),
    [FIELDS.SECTOR]: Yup.string().required('This field is required'),
    [FIELDS.STUDY_FIELD]: Yup.string().required('This field is required'),
    [FIELDS.CATEGORY]: Yup.string().required('This field is required'),

    [FIELDS.EMPLOYEE_LEVEL]: Yup.string().required('This field is required'),
    [FIELDS.FLEXIBILITY]: Yup.string().required('This field is required'),

    [FIELDS.HOURS_FROM]: Yup.number().when(FIELDS.FLEXIBILITY, {
        is: (label) => label === 'Fixed',
        then: Yup.number()
            .typeError('Input must be a number between 0 and 23.')
            .min(0, 'Input must be greater than or equal to 0.')
            .max(23, 'Input must be less than 24.')
            .required('This field is required.'),
    }),
    [FIELDS.HOURS_TO]: Yup.number().when(FIELDS.FLEXIBILITY, {
        is: (label) => label === 'Fixed',
        then: Yup.number()
            .typeError('Input must be a number between 0 and 23.')
            .min(0, 'Input must be greater than or equal to 0.')
            .max(23, 'Input must be less than 24.')
            .moreThan(Yup.ref('hoursFrom'), 'Hours To must be greater than Hours From.')
            .required('This field is required.'),
    }),

    [FIELDS.MINUTES_FROM]: Yup.number().when(FIELDS.FLEXIBILITY, {
        is: (label) => label === 'Fixed',
        then: Yup.number()
            .typeError('Input must be a number.')
            .min(0, 'Input must be greater than or equal to 0.')
            .max(59, 'Input must be less than 60.')
            .required('This field is required.'),
    }),
    [FIELDS.MINUTES_TO]: Yup.number().when(FIELDS.FLEXIBILITY, {
        is: (label) => label === 'Fixed',
        then: Yup.number()
            .typeError('Input must be a number.')
            .min(0, 'Input must be greater than or equal to 0.')
            .max(59, 'Input must be less than 60.')
            .required('This field is required.'),
    }),
});

export const initialObject = {
    [FIELDS.QUESTIONS]: {
        name: '',
        type: 'text',
        thinking_time: null,
        max_characters: null,
        max_duration: null,
        max_retakes: null,
    },
};

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.START]: '',
    [FIELDS.OUTSIDE]: '',
    [FIELDS.OFFERTYPE]: 'Staff',
    [FIELDS.SCOPE]: '',
    [FIELDS.LOCATION]: '',
    [FIELDS.DURATION]: '',
    [FIELDS.REVENUEMIN]: '',
    [FIELDS.REVENUEMAX]: '',
    [FIELDS.REVENUEFREQ]: 'Day',
    [FIELDS.SKILLS]: [{ name: '', required: false }],
    [FIELDS.QUESTIONS]: [],
    [FIELDS.CERTIFICATES]: [{ name: '', required: false }],
    [FIELDS.FILES]: [],
    [FIELDS.CLOSE_DATE]: '',
    [FIELDS.PUBLISH_DATE]: '',
    [FIELDS.LOCATION_SELECT]: '',
    [FIELDS.MIN_QUALIFICATIONS]: '',
    [FIELDS.STUDY_FIELD]: '',
    [FIELDS.QUAL_REQUIRED]: false,

    [FIELDS.SECTOR]: '',
    [FIELDS.CATEGORY]: '',
    [FIELDS.REVIEW]: 'Nudge',
    [FIELDS.MATCH_TYPE]: false,

    [FIELDS.FLEXIBILITY]: 'Flexible',
    [FIELDS.FLEXIBLE_HOURS]: '',
    [FIELDS.FLEXIBLE_PERIOD]: 'per Day',
    [FIELDS.FLEXIBLE_OPTIONAL]: true,

    [FIELDS.HOURS_FROM]: '',
    [FIELDS.HOURS_TO]: '',
    [FIELDS.MINUTES_FROM]: '',
    [FIELDS.MINUTES_TO]: '',

    [FIELDS.FIXED_FROM]: '',
    [FIELDS.FIXED_TO]: '',

    [FIELDS.TIMING]: 'Full Time',
    [FIELDS.WORK_TYPE]: 'Paid Work',
    [FIELDS.EMPLOYEE_LEVEL]: 'Junior',

    [FIELDS.WEIGHTS]: {
        weightsId: 0,
        services_required: 3,
        remuneration: 3,
        industry: 3,
        skills: 3,
        experience: 3,
        education: 3,
        certificates: 3,
    },
};
