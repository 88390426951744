import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useMobile from '../../../../../hooks/useMobile';
import DateSelector from './DateSelector';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../../../../components/NudgeModal';

const CalendarModal = ({ data, matchId, isOpen, toggle, mutate, children }) => {
    const [selectedDate, setSelectedDate] = React.useState();
    const { isMobileWide } = useMobile();

    //const video = data.hasInterview;
    const edify = data.hasEdify;
    const face = data.hasFaceToFace;

    function ResolveStage() {
        switch (data.status) {
            case 'InterviewSubmitted':
            case 'FaceInterviewScheduled':
            case 'WaitingOnVRSchedule':
            case 'WaitingOnFaceSchedule':
                return edify ? 'Choose a date for the VR assessment.' : 'Choose a date for the face-to-face interview.';
            case 'VRAssessmentScheduled':
            case 'VRAssessmentSubmitted':
                return face ? 'Choose a date for the face-to-face interview' : 'No';
        }
    }

    var isVR = false;
    if (data.status == 'InterviewSubmitted' || data.status == 'WaitingOnVRSchedule') {
        if (edify) {
            isVR = true;
        }
    }

    return (
        <>
            <NudgeModal headerText={ResolveStage()} id={`modal-${GetPageTheme()}`}className={style.calendarModal} isOpen={isOpen} toggle={toggle}>
                <ModalBody>
                    {isMobileWide ? (
                        <h5>This feature is not currently supported on mobile devices.</h5>
                    ) : (
                        <DateSelector
                            matchId={matchId}
                            toggle={toggle}
                            mutate={mutate}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            availability={data.interviewAvailability}
                            close={data.closeDate}
                            isVR={isVR}
                            excluded={data.excludedDates}
                        />
                    )}
                </ModalBody>
            </NudgeModal>
            {children}
        </>
    );
};

export default CalendarModal;
