import React from 'react';
import { FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UploadModal from '../../ContractorInformation/ContractorInformationEdit/UploadModal';

import style from './style.module.scss';
import { DEFAULT_PICTURE } from '../../../consts/urls';
import { exFetcher } from '../../../api/tools';
import NudgeButton from '../../buttons/NudgeButton';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';

const PhotoUploadForm = ({ formik, id, defaultImage }) => {

    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

    const [photo, setPhoto] = React.useState(formik.values[id]);
    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[id]));
    }, [formik.values[id]]);

    function setPhotoField(e) {
        const photo = e.target.files[0];
        console.log(e.target.files)

        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(id, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return defaultImage || DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    function clickPhoto() {
        var photo = document.getElementById("assessment_photo");
        photo.click();
    }

    const theme = GetPageTheme();

    return (
        <>
            <FormGroup className="mb-20">
                <div className="text-left">
                    <div className={style.avatar}>
                        <div
                            className={style.photoUpload}
                            style={{ backgroundImage: `url(${photo})`, objectFit: 'contain'  }}
                            onClick={clickPhoto}
                        >
                            <h5>
                                <FontAwesomeIcon icon={['fas', 'plus-square']} />
                            </h5>
                        </div>

                        

                        <input id="assessment_photo" type="file" style={{ display: 'none' }} onChange={setPhotoField}/>
                    </div>
                    <h5 style={{ textAlign: 'center', marginTop: '12px' }}>
                        {formik.errors && formik.submitCount ? formik.errors[id] : null }
                    </h5>
                </div>
            </FormGroup>
        </>
    );
}

export default PhotoUploadForm;