import en from 'date-fns/locale/pl';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale('en', en);

const DateSelector = ({ selectedDate, setSelectedDate }) => {
    var maxDate = new Date();
    var advanceDays = 2;
    maxDate.setDate(maxDate.getDate() + advanceDays);

    return (
        <div className="text-center big-calendar">
            <DatePicker
                selected={selectedDate}
                onChange={setSelectedDate}
                startDate={selectedDate}
                locale="en"
                inline
                minDate={new Date()}
                maxDate={new Date(maxDate)}
            />
        </div>
    );
};

export default DateSelector;
