import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'formik';
import AppInput from '../../../../components/AppInput';
import SelectField from '../../../../components/SelectField';
import CheckboxField from '../../../../components/CheckboxField';
import UploadFileFieldInline from '../../../../components/UploadFileFieldInline';
import { Button, FormGroup, Form, Label, Card, Col, Row } from 'reactstrap';
import style from '../style.module.scss';
import { FIELDS } from './validation';
import ClickButton from '../../../../components/buttons/NudgeButton';

const nationalities = [
    'Afghan',
    'Albanian',
    'Algerian',
    'American',
    'Andorran',
    'Angolan',
    'Antiguans',
    'Argentinean',
    'Armenian',
    'Australian',
    'Austrian',
    'Azerbaijani',
    'Bahamian',
    'Bahraini',
    'Bangladeshi',
    'Barbadian',
    'Barbudans',
    'Batswana',
    'Belarusian',
    'Belgian',
    'Belizean',
    'Beninese',
    'Bhutanese',
    'Bolivian',
    'Bosnian',
    'Brazilian',
    'British',
    'Bruneian',
    'Bulgarian',
    'Burkinabe',
    'Burmese',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Cape Verdean',
    'Central African',
    'Chadian',
    'Chilean',
    'Chinese',
    'Colombian',
    'Comoran',
    'Congolese',
    'Congolese',
    'Costa Rican',
    'Croatian',
    'Cuban',
    'Cypriot',
    'Czech',
    'Danish',
    'Djibouti',
    'Dominican',
    'Dominican',
    'Dutch',
    'Dutchman',
    'Dutchwoman',
    'East Timorese',
    'Ecuadorean',
    'Egyptian',
    'Emirian',
    'Equatorial Guinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Greek',
    'Grenadian',
    'Guatemalan',
    'Guinea-Bissauan',
    'Guinean',
    'Guyanese',
    'Haitian',
    'Herzegovinian',
    'Honduran',
    'Hungarian',
    'I-Kiribati',
    'Icelander',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakhstani',
    'Kenyan',
    'Kittian and Nevisian',
    'Kuwaiti',
    'Kyrgyz',
    'Laotian',
    'Latvian',
    'Lebanese',
    'Liberian',
    'Libyan',
    'Liechtensteiner',
    'Lithuanian',
    'Luxembourger',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivan',
    'Malian',
    'Maltese',
    'Marshallese',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Micronesian',
    'Moldovan',
    'Monacan',
    'Mongolian',
    'Moroccan',
    'Mosotho',
    'Motswana',
    'Mozambican',
    'Namibian',
    'Nauruan',
    'Nepalese',
    'Netherlander',
    'New Zealander',
    'Ni-Vanuatu',
    'Nicaraguan',
    'Nigerian',
    'Nigerien',
    'North Korean',
    'Northern Irish',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Palauan',
    'Panamanian',
    'Papua New Guinean',
    'Paraguayan',
    'Peruvian',
    'Polish',
    'Portuguese',
    'Qatari',
    'Romanian',
    'Russian',
    'Rwandan',
    'Saint Lucian',
    'Salvadoran',
    'Samoan',
    'San Marinese',
    'Sao Tomean',
    'Saudi',
    'Scottish',
    'Senegalese',
    'Serbian',
    'Seychellois',
    'Sierra Leonean',
    'Singaporean',
    'Slovakian',
    'Slovenian',
    'Solomon Islander',
    'Somali',
    'South African',
    'South Korean',
    'Spanish',
    'Sri Lankan',
    'Sudanese',
    'Surinamer',
    'Swazi',
    'Swedish',
    'Swiss',
    'Syrian',
    'Taiwanese',
    'Tajik',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tongan',
    'Trinidadian or Tobagonian',
    'Tunisian',
    'Turkish',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Uruguayan',
    'Uzbekistani',
    'Venezuelan',
    'Vietnamese',
    'Welsh',
    'Welsh',
    'Yemenite',
    'Zambian',
    'Zimbabwean',
];

const nationalityOpt = () => {
    const arr = [];

    for (var i = 0; i < nationalities.length; i++) {
        arr[i] = { label: nationalities[i], value: nationalities[i] };
    }
    return arr;
};

const shareholderOpt = () => {
    const arr = [];

    for (var i = 0; i < 100; i++) {
        arr[i] = { label: i, value: i };
    }
    return arr;
};

const poundSign = String.fromCharCode(163);

const Services = [
    {
        id: 'Bookkeeping',
        label:
            ': Annual accounts, confirmation statements, corporation tax returns-' + poundSign + '90 + VAT per month',
    },
    { id: 'VAT Filing', label: ': ' + poundSign + '10 + VAT per month' },
    { id: 'PAYE', label: ': Payroll for director - ' + poundSign + '10 + VAT per month' },
    {
        id: 'Self Assessment',
        label: ': For director to cover remuneration from company - ' + poundSign + '10 + VAT per month',
    },
];

export const FormData = ({ formik, user }) => {
    return (
        <Form onSubmit={formik.handleSubmit}>
            {/* Company Details  */}
            <h2 class={style.header}>Aberdeen Accountants</h2>

            <Row class={style.formWrapper}>
                <Col>
                    <Card className={style.container}>
                        <h4 class={style.header}>Company Details</h4>
                        <div></div>

                        <Label for={FIELDS.COMPANY_NAME}>Company Name</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.COMPANY_NAME}
                                id={FIELDS.COMPANY_NAME}
                                formik={formik}
                                component={AppInput}
                                label="Company Name"
                            />
                        </FormGroup>

                        <Label for={FIELDS.COMPANY_ADDRESS}>Company Address</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.COMPANY_ADDRESS}
                                id={FIELDS.COMPANY_ADDRESS}
                                formik={formik}
                                component={AppInput}
                                label="Company Address"
                                type={'textarea'}
                            />
                        </FormGroup>

                        <Label for={FIELDS.COMPANY_START}>Company Start Date</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.COMPANY_START}
                                id={FIELDS.COMPANY_START}
                                formik={formik}
                                component={AppInput}
                                label="Start Date (DD/MM/YYYY)"
                                type={'date'}
                            />
                        </FormGroup>

                        <Label for={FIELDS.PRINCIPAL}>Principal Activity</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.PRINCIPAL}
                                id={FIELDS.PRINCIPAL}
                                formik={formik}
                                component={AppInput}
                                label="Principal Activity"
                            />
                        </FormGroup>

                        <Label for={FIELDS.SHAREHOLDERS}>Shareholders</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.SHAREHOLDERS}
                                id={FIELDS.SHAREHOLDERS}
                                formik={formik}
                                component={SelectField}
                                placeholder="Shareholders"
                                options={shareholderOpt()}
                            />
                        </FormGroup>

                        <Label for={FIELDS.NO_SHARES}>Number of Shares</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.NO_SHARES}
                                id={FIELDS.NO_SHARES}
                                formik={formik}
                                component={AppInput}
                                label="Number of Shares"
                            />
                        </FormGroup>

                        <Label for={FIELDS.VAL_SHARE}>Share Value ({poundSign})</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.VAL_SHARE}
                                id={FIELDS.VAL_SHARE}
                                formik={formik}
                                component={AppInput}
                                label={'Share Value (' + poundSign + ')'}
                            />
                        </FormGroup>

                        <br />

                        <Label for={FIELDS.SERVICES}>Additional Services</Label>
                        {Services.map((label, index) => (
                            <FormGroup className={style.radio} key={label.id}>
                                <Field name={label.id} component={CheckboxField} formik={formik} label={label.id} />
                                <Label>{label.label}</Label>
                            </FormGroup>
                        ))}
                    </Card>
                </Col>

                {/* Director/Shareholder Details  */}

                <Col>
                    <Card className={style.container}>
                        <h4 class={style.header}>Director/Shareholder Details</h4>
                        <div></div>
                        <Label for={FIELDS.DIR_NAME}>Director Name</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.DIR_NAME}
                                id={FIELDS.DIR_NAME}
                                formik={formik}
                                component={AppInput}
                                label="Director Name"
                            />
                        </FormGroup>

                        <Label for={FIELDS.NI_NO}>National Insurance No.</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.NI_NO}
                                id={FIELDS.NI_NO}
                                formik={formik}
                                component={AppInput}
                                label="National Insurance No."
                            />
                        </FormGroup>

                        <Label for={FIELDS.DOB}>Date of Birth</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.DOB}
                                id={FIELDS.DOB}
                                formik={formik}
                                component={AppInput}
                                label="Date of Birth (DD/MM/YYYY)"
                                type={'date'}
                            />
                        </FormGroup>

                        <Label for={FIELDS.DIR_ADDRESS}>Director's Address w/ Postcode</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.DIR_ADDRESS}
                                id={FIELDS.DIR_ADDRESS}
                                formik={formik}
                                component={AppInput}
                                label="Director's Address"
                                type={'textarea'}
                            />
                        </FormGroup>

                        <Label for={FIELDS.DIR_DATEADDRESS}>Date Address is Effective</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.DIR_DATEADDRESS}
                                id={FIELDS.DIR_DATEADDRESS}
                                formik={formik}
                                component={AppInput}
                                label="Date Address is Effective (DD/MM/YYYY)"
                                type={'date'}
                            />
                        </FormGroup>

                        <Label for={FIELDS.NAT}>Nationality</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.NAT}
                                id={FIELDS.NAT}
                                formik={formik}
                                component={SelectField}
                                placeholder="Nationality"
                                options={nationalityOpt()}
                            />
                        </FormGroup>

                        <Label for={FIELDS.JOB_TITLE}>Job Title</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.JOB_TITLE}
                                id={FIELDS.JOB_TITLE}
                                formik={formik}
                                component={AppInput}
                                label="Job Title"
                            />
                        </FormGroup>

                        <Label for={FIELDS.DIR_TELNO}>Telephone Number</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.DIR_TELNO}
                                id={FIELDS.DIR_TELNO}
                                formik={formik}
                                component={AppInput}
                                label="Telephone Number"
                            />
                        </FormGroup>

                        <Label for={FIELDS.EMAIL}>Email Address</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.EMAIL}
                                id={FIELDS.EMAIL}
                                formik={formik}
                                component={AppInput}
                                label="Email Address"
                            />
                        </FormGroup>

                        <Label for={FIELDS.PASSPORT}>Copy of Passport</Label>
                        <FormGroup className="mb-20 ">
                            <Field component={UploadFileFieldInline} name={FIELDS.PASSPORT} />
                        </FormGroup>

                        <Label for={FIELDS.ASSESS_UTR}>Self Assessment UTR (if registered)</Label>
                        <FormGroup className="mb-20 ">
                            <Field
                                name={FIELDS.ASSESS_UTR}
                                id={FIELDS.ASSESS_UTR}
                                formik={formik}
                                component={AppInput}
                                label="UTR Number"
                            />
                        </FormGroup>
                    </Card>

                    <ClickButton className={style.button} type="submit" size="lg">
                        Send
                    </ClickButton>
                </Col>
            </Row>
        </Form>
    );
};

FormData.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default FormData;

// <Video />
