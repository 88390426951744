import React from 'react';
import useSWR from 'swr';
import { Row, Col } from 'reactstrap';

import { authFetcher, NEVER_REFRESH } from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

import style from '../../style.module.scss';
import { Project } from '../GetProjects';

const TestingGround = () => {
    const URL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/4aedf72155b64e46a61cffd5b83a2d79`;
    const { data: project, mutate } = useSWR(URL, authFetcher, NEVER_REFRESH);

    React.useEffect(() => {
        if (project) console.log(project);
    }, [project]);

    return <Row>{project ? <Project project={project} /> : <Loader />}</Row>;
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
