import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import useSWR from 'swr';
import { ADMIN } from '../../../consts/userRoles';
import MatchList from './MatchList';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';

import SuccessModal from './SuccessModal';
import FeedbackModal from './FeedbackModal';

import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_ADMIN } from '../../../consts/urls';
import { patchData } from '../../../api/tools.js';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import { initialValues, validationSchema } from './FeedbackModal/validation';
import style from './style.module.scss';

var toggleApprove;
var toggleDeny;
var toggleSending;
var refresh;

const approveMatch = async (matchId) => {
    toggleSending();
    var url = `${API_DASHBOARD_ADMIN}/Matches/${matchId}/Approve`;
    await patchData({}, url);
    await refresh();
    toggleApprove();
    toggleSending();
};

const denyMatch = async (matchId, feedback) => {
    toggleSending();
    var url = `${API_DASHBOARD_ADMIN}/Matches/${matchId}/Deny`;
    await patchData({}, url, { Feedback: feedback });
    await refresh();
    toggleDeny();
    toggleSending();
};

export const AdminMatchApproval = () => {
    const { data, mutate } = useSWR(`${API_DASHBOARD_ADMIN}/Matches/Approval`, authFetcher, NO_REFRESH_INTERVAL); //get all matches awaiting admin approval

    const [sending, setSending] = React.useState(false);

    const [key, setKey] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [matchToDeny, setMatchToDeny] = useState(0);

    const toggle = () => {
        setModalOpen(!modalOpen);
        mutate();
        setKey(!key);
    };
    const feedbackToggle = (matchId) => {
        setFeedbackModalOpen(!feedbackModalOpen);
        setMatchToDeny(matchId);
        mutate();
        setKey(!key);
    };

    refresh = mutate;
    toggleApprove = toggle;
    toggleDeny = feedbackToggle;
    toggleSending = () => {
        setSending(!sending);
    };

    var matchData;
    var dataArray = [];

    if (data) {
        matchData = data.matches.filter((item) => item.contractor.name !== '-1' && item.offer.company.name !== '-1');

        matchData.map((label) =>
            dataArray.push({
                matchId: label.id,
                offerId: label.offer.id,
                companyId: label.offer.company.id,
                companyName: label.offer.company.name,
                contractorId: label.contractor.id,
                contractorName: label.contractor.name,
                jobTitle: label.offer.title,
                cSkills: label.contractor.skills,
                oSkills: label.offer.skills,
                experience: label.contractor.experience,
            }),
        );
    }

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            denyMatch(matchToDeny, values.other);
        },
    };

    return (
        <SignInPageTemplate>
            <SuccessModal isOpen={modalOpen} toggle={toggle} />
            <Formik {...props}>
                {(formik) => (
                    <FeedbackModal
                        isOpen={feedbackModalOpen}
                        toggle={feedbackToggle}
                        onSend={denyMatch}
                        formik={formik}
                        matchId={matchToDeny}
                    />
                )}
            </Formik>

            <h1 className={style.title}>Registered Interest</h1>
            {matchData ? (
                <MatchList matches={dataArray} approve={approveMatch} deny={feedbackToggle} sending={sending} />
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(AdminMatchApproval, [ADMIN]);
