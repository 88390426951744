import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'reactstrap';

import Offer from '../../../components/OffersList/ContractorOffer';
import { ProcessSalary } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import { URLS } from '../../../consts/urls';

const MAX_CHARS = 200;

const NudgeHistoryOffer = ({ className, offer }) => {
    if (offer && offer.description.length > MAX_CHARS) {
        offer.description = offer.description.slice(0, MAX_CHARS) + '...';
    }

    return (
        <Col xs="12" md="12" className="mb-60">
            {offer ? (
                <Offer offer={offer} className={className} key={offer.id} url={URLS.OFFERS} process={ProcessSalary} />
            ) : null}
        </Col>
    );
};

NudgeHistoryOffer.propTypes = {
    offer: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
};

export default NudgeHistoryOffer;
