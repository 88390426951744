import React from 'react';

import PropTypes from 'prop-types';
import NudgeCheckbox from '../NudgeCheckbox';

const CheckboxField = ({ field, form, label, value = label, bool, labelOption = 2 }) => {

    const isSelected = bool ? !!field.value : field.value === value;

    return (
        <NudgeCheckbox
            label={label}
            checked={isSelected}
            onClick={() => alterCheckbox(form, field.name, value, bool)}
            labelOption={labelOption}
        />
    )
};

const alterCheckbox = (form, name, value, bool) => {
    if(!bool) {
        if (form.values[name] == value) {
            form.setFieldValue(name, '');
        } else {
            form.setFieldValue(name, value);
        }
    } else {
        if(form.values[name]) {
            form.setFieldValue(name, false)
        } else {
            form.setFieldValue(name, true)
        }
    }
};

CheckboxField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    label: PropTypes.any.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
};

export default CheckboxField;
