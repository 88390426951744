import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';

import { fetcher, authFetcher, NEVER_REFRESH } from '../../../../api/tools';
import { customStyles } from '../../../../components/SelectField';
import { API_DASHBOARD_COMPANY, API_PROFILE } from '../../../../consts/urls';

import useSWR from 'swr';

import { FIELDS, initialValues } from './validation';

import style from './style.module.scss';
import Loader from '../../../../components/Loader';
import { sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import moment from 'moment';

function compileQuestions(q) {
    let qArr = [];

    sort_by_key(q, 'questionOrder').forEach((item) => {
        let tempQ = {
            question: item.question,
            type: item.type,
            max_words: item.max_words || 75,
            thinking_time: item.thinking_time || 120,
            max_duration: item.max_duration || 60,
            max_retakes: item.max_retakes || 5,
            location_of_employment: item.location_of_employment || "USA"
        };
        qArr.push(tempQ);
    });

    return qArr;
}

function resolvePermission(service, requirement) {
    if (service) {
        if (requirement) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function resolveMatchType(willo, hasInterview) {
    if (willo) {
        if (hasInterview) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const OfferTemplates = ({ formik, canWillo, canEdify, begin, children }) => {
    const { data: templateData, isValidating: fetchingTemplates } = useSWR(
        `${API_DASHBOARD_COMPANY}/Offers/Templates`,
        authFetcher,
        NEVER_REFRESH,
    );
    const { data: selectorData } = useSWR(`${API_PROFILE}/Industries`, fetcher, NEVER_REFRESH);
    let sectors = { selectorData }.selectorData;

    const [offer, setOffer] = React.useState('');
    const [working, setWorking] = React.useState(false);

    let offerSelect = [];

    const fillFormik = (r) => {
        formik.resetForm();

        let v = {
            ...initialValues,
            [FIELDS.NAME]: r.title,

            [FIELDS.START]: moment().add(1, 'day').format("YYYY-MM-DD"),

            [FIELDS.PUBLISH_DATE]: moment().add(1, 'day').format("YYYY-MM-DD"),
            [FIELDS.CLOSE_DATE]: moment().add(2, 'day').format("YYYY-MM-DD"),

            [FIELDS.SCOPE]: r.description,
            [FIELDS.FLEXIBILITY]: r.flexibility,
            [FIELDS.LOCATION_SELECT]: r.locationType || '',
            [FIELDS.LOCATION]: r.location || '',
            [FIELDS.OFFER_TYPE]: r.type,
            [FIELDS.OUTSIDE]: r.outsideIR35,
            [FIELDS.DURATION]: r.duration,
            [FIELDS.MIN_QUALIFICATIONS]: r.qualification !== null ? r.qualification.split('/')[0] : null,
            [FIELDS.STUDY_FIELD]: r.qualification !== null ? r.qualification.split('/')[1] : null,
            [FIELDS.QUAL_REQUIRED]: r.required,

            [FIELDS.QUESTIONS]: compileQuestions(r.questions),
            [FIELDS.SCHEDULING]: r.validFrom ? 'LATER' : 'NOW',

            [FIELDS.SKILLS]: r.skills,
            [FIELDS.CERTIFICATES]: r.certificates,
            [FIELDS.REVENUEMIN]: r.rangeExpected.split('-')[0],
            [FIELDS.REVENUEMAX]: r.rangeExpected.split('-')[1],
            [FIELDS.REVENUE]: r.rangeExpected,
            [FIELDS.REVENUEFREQ]: r.salaryFreq || 'DAY',
            [FIELDS.TIMING]: r.offerTiming || 'Full Time',
            [FIELDS.WORK_TYPE]: r.workType || 'Paid Work',
            [FIELDS.EMPLOYEE_LEVEL]: r.employeeLevel || 'JUNIOR',
            [FIELDS.FLEXIBLE_PERIOD]: '',

            [FIELDS.MATCH_TYPE]: resolvePermission(canWillo, r.hasInterview),
            [FIELDS.HAS_EDIFY]: resolvePermission(canEdify, r.hasEdify),
            [FIELDS.FACE_TO_FACE]: r.faceToFace,

            [FIELDS.HOURS_FROM]: r.fixedFrom ? r.fixedFrom.substring(0, 2) : '',
            [FIELDS.HOURS_TO]: r.fixedTo ? r.fixedTo.substring(0, 2) : '',
            [FIELDS.MINUTES_FROM]: r.fixedFrom ? r.fixedFrom.substring(2, 4) : '',
            [FIELDS.MINUTES_TO]: r.fixedTo ? r.fixedTo.substring(2, 4) : '',

            [FIELDS.WEIGHTS]: r.weights || initialValues[FIELDS.WEIGHTS],
            [FIELDS.ASSESSMENT_SKILLS]: r.assessmentSkills || {
                selfManagement: [],
                socialIntelligence: [],
                innovation: [],
                discipline: [],
            },
        };

        if (sectors) {
            sectors.forEach((item1) => {
                item1.subIndustries.forEach((item2) => {
                    if (r.sector == item2.name) {
                        v[FIELDS.SECTOR] = item1.id;
                        v[FIELDS.CATEGORY] = item2.id;
                    }
                });
            });
        }

        formik.setValues(v);

        setOffer(null);
        setWorking(false);
        begin();
    };

    if (templateData) {
        templateData.offerTemplates.reverse().forEach((item) => {
            offerSelect.push({ label: `${item.id}: ${item.title}`, value: item });
        });
    }

    React.useEffect(() => {
        const fetchData = async (id) => {
            const response = await authFetcher(`${API_DASHBOARD_COMPANY}/Offers/${id}`);
            fillFormik(response);
            return response;
        };

        if (offer) {
            setWorking(true);
            fetchData(offer.value.id).catch('Error');
        }
    }, [offer]);

    const selectStyles = customStyles();

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={{ size: 12 }} className={style.templateSelect}>
                    <p className="text-center">
                        Your past job descriptions can be re-used as a template for jobs you may wish to post in the
                        future. Select a job from the dropdown and the form will be auto-filled.
                    </p>
                    {offerSelect.length ? (
                        <>
                            <div className={style.templates}>
                                <Select
                                    styles={selectStyles}
                                    options={offerSelect.reverse()}
                                    defaultValue={offerSelect[0]}
                                    onChange={(option) => {
                                        setOffer(option);
                                    }}
                                    placeholder={'Offer Templates'}
                                    value={offer}
                                    isSearchable={false}
                                />
                            </div>
                            <br />
                        </>
                    ) : (
                        <Loader message="Loading templates" />
                    )}
                </Col>
            </Row>
            {!working ? children : <Loader message={'Merging template'} />}
        </>
    );
};

OfferTemplates.propTypes = {
    formik: PropTypes.object.isRequired,
    offers: PropTypes.array.isRequired,
};

export default OfferTemplates;
