import { Formik } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import useSWR from 'swr';

import { Form } from 'formik';

import {
    authFetcher,
    authSendFiles,
    deleteData,
    putData,
    postWithAuthorizationHeader,
    patchWithAuthorizationHeader,
    patchData,
} from '../../../../api/tools';
import Loader from '../../../../components/Loader';
import {
    API_DASHBOARD_COMPANY,
    API_DASHBOARD_ADMIN,
    API_DASHBOARD_CLOOD,
    API_DASHBOARD_WILLO,
} from '../../../../consts/urls';
import { ADMIN, COMPANY } from '../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../contexts/auth/AuthProtectedRouter';
import { FIELDS, initialValues, getValidationSchema } from '../OfferForm/validation';

import moment from 'moment';

import style from './style.module.scss';
import OfferForm, { translateDates } from '../OfferForm';
import Buttons from './Buttons';

import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import { ToastContext } from '../../../../contexts/toast/ToastContext';


import { OFFER_ERROR } from '..';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

function compileQuestions(q) {
    let qArr = [];

    q.forEach((item) => {
        let tempQ = {
            question: item.question,
            type: item.type,
            max_words: item.max_words,
            thinking_time: item.thinking_time,
            max_duration: item.max_duration,
            max_retakes: item.max_retakes,
            location_of_employment: item.location_of_employment || "GBR"
        };
        qArr.push(tempQ);
    });

    return qArr;
}

export const parseEditValues = (v, id) => {
    return {
        name: v.title,
        [FIELDS.REVENUE]: v.rangeExpected,
        [FIELDS.SCOPE]: v.description,
        [FIELDS.OUTSIDE]: v.outsideIR35,
        [FIELDS.OFFER_TYPE]: v.type,

        [FIELDS.SCHEDULING]: v[FIELDS.PUBLISH_DATE] ? 'LATER' : 'NOW',
        [FIELDS.REVENUE_FIXED]: v.rangeExpected.split('-')[0] == v.rangeExpected.split('-')[1] ? 'Fixed' : 'Variable',

        [FIELDS.QUESTIONS]: compileQuestions(v.questions),
        [FIELDS.REVENUEMIN]: v.rangeExpected.split('-')[0],
        [FIELDS.REVENUEMAX]: v.rangeExpected.split('-')[1],
        [FIELDS.REVENUEFREQ]: v.salaryFreq,
        [FIELDS.MIN_QUALIFICATIONS]: v.qualification ? v.qualification.split('/')[0] : '',
        [FIELDS.STUDY_FIELD]: v.qualification ? v.qualification.split('/')[1] : '',
        [FIELDS.QUAL_REQUIRED]: v.required,
        [FIELDS.FLEXIBLE_OPTIONAL]: v.flexibleOptional,
        [FIELDS.TIMING]: v.offerTiming || 'Full Time',
        [FIELDS.WORK_TYPE]: v.workType || 'Paid Work',
        [FIELDS.EMPLOYEE_LEVEL]: v.employeeLevel || 'JUNIOR',
        [FIELDS.WEIGHTS]: v.weights || initialValues.weights,
        [FIELDS.ASSESSMENT_SKILLS]: v.assessmentSkills || {
            selfManagement: [],
            socialIntelligence: [],
            innovation: [],
            discipline: [],
        },
        interviewKey: v.interviewKey,
        [FIELDS.CONFIRM]: v.questions?.length ? v.questions.map((item) => item.type).includes("idv") ? true : false : false,
    };
};

const EditOffer = ({ section }) => {
    const navigate = useNavigate();
    const onBack = () => navigate(-1);
    const { id } = useParams();

    const { user, userRoles } = React.useContext(AuthContext);
    const { createToast } = React.useContext(ToastContext);

    const { data, mutate } = useSWR(
        `${window.location.href.includes('admin') ? API_DASHBOARD_ADMIN : API_DASHBOARD_COMPANY}/Offers/${id}`,
        authFetcher,
        { refreshInterval: 0 },
    );

    let canWillo = false;
    let canEdify = false;
    if ((user, userRoles)) {
        if (userRoles.includes('admin')) {
            canWillo = true;
        } else {
            let willoPerm = user.permissions && user.permissions.find((p) => p.permissionType == 'willo_interview');
            let edifyPerm = user.permissions && user.permissions.find((p) => p.permissionType == 'edify_interview');

            if (willoPerm) {
                if (willoPerm.enabled) {
                    canWillo = true;
                }
            }

            if (edifyPerm) {
                if (edifyPerm.enabled) {
                    canEdify = true;
                }
            }
        }
    }

    if (data) {
        if (!data.location) {
            data.location = '';
        }
    }

    function parseQuestions(questions) {
        var returnQuestions = [];

        questions.forEach((item, index) => {
            let q = {
                text: item.question,
                order: index + 1,
                answer_type: item.type,
                thinking_time: parseInt(item.thinking_time),
                max_characters: parseInt(item.max_characters),
                max_retakes: parseInt(item.max_retakes),
                max_duration: parseInt(item.max_duration),
            };
            returnQuestions.push(q);
        });

        return returnQuestions;
    }

    async function findSchema(values) {
        const v = values;

        function findDeadline(date) {
            const split = date.split('/');
            return `${split[2]}-${split[1]}-${split[0]}`;
        }

        const requestSchema = {
            title: v[FIELDS.NAME],
            questions: parseQuestions(v[FIELDS.QUESTIONS]),
            settings: {
                redirect_url: 'https://nudgetalentlab.com/',
                show_hints_and_tips: true,
                show_availability_calendar: true,
                deadline: findDeadline(v[FIELDS.CLOSE_DATE]),
            },
        };

        const response = await patchData(
            requestSchema,
            `${API_DASHBOARD_WILLO}/Interviews/UpdateInterview/${values.interviewKey}`,
        );

        return response;
    }

    const [removedFiles, setRemovedFiles] = useState([]);
    const [sending, setSending] = useState(false);

    const onRemoveExistingFiles = (el) => setRemovedFiles([el, ...removedFiles]);

    const displayFiles = data
        ? data.attachedFiles.filter((el) => !removedFiles.map((cert) => cert.id).includes(el.id))
        : [];

    function handleRevenue(revenueMin, revenueMax) {
        if (!revenueMax) revenueMax = 0;
        if (!revenueMin) revenueMin = 0;

        let revenue = revenueMin + '-' + revenueMax;
        return revenue;
    }

    //Call back to ensure edit changes reflected in offer display
    function waitReturn() {
        return new Promise((resolve) => {
            setTimeout(() => resolve(navigate(-1), 3000));
        });
    }

    const getProps = () => ({
        initialValues: {
            ...initialValues,
            ...data,
            ...parseEditValues(data, id),
        },
        validationSchema: getValidationSchema(true),
        onSubmit: async (values) => {
            var copyValues = values;

            values[FIELDS.START] = moment(values[FIELDS.START]).local().format('yyyy-MM-DDTHH:mm:ss');
            values[FIELDS.PUBLISH_DATE] = moment(values[FIELDS.PUBLISH_DATE]).format('yyyy-MM-DDTHH:mm:ss');
            values[FIELDS.CLOSE_DATE] = moment(values[FIELDS.CLOSE_DATE]).local().format('yyyy-MM-DDTHH:mm:ss');

            values.questions.forEach((item, index) => {
                item.questionOrder = index;
            });

            if (values.hasInterview) {
                findSchema(values);
            }

            setSending(true);

            if (values[FIELDS.OFFER_TYPE] === 'STAFF') values[FIELDS.REVENUEFREQ] = 'YEAR';

            values[FIELDS.MIN_QUALIFICATIONS] += '/' + values[FIELDS.STUDY_FIELD];
            delete values.offerStatus;

            try {
                await putData(values, `${API_DASHBOARD_COMPANY}/Offer`);
                await putData({}, `${API_DASHBOARD_CLOOD}/Projects/UpdateOffer/${values.id}`);

                await sendAttachments(values.files);
                await deleteAttachments();

                await mutate();
                returnBack();
            } catch (e) {
                values = copyValues;
                createToast(`There was a problem: ${e.message}.`, 'error')
            } finally {
                setSending(false);
            }
        },
        validateOnChange: false,
        validateOnBlur: true,
    });

    async function returnBack() {
        const wait = await waitReturn();
    }

    const sendAttachments = async (files) => {
        if (files.length > 0) {
            await authSendFiles(files, `${API_DASHBOARD_COMPANY}/Offers/${id}/Attachments`);
        }
    };

    const deleteAttachments = async () => {
        const idsToRemove = removedFiles.map((p) => p.id);
        await deleteData(idsToRemove, `${API_DASHBOARD_COMPANY}/Offers/${id}/Attachments`, {});
    };

    return (
        <>
            {data ? (
                <>
                    <Formik {...getProps()}>
                        {(formik) => (
                            <>
                                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                    { !sending ? <OfferForm
                                        formik={formik}
                                        isEdit={true}
                                        section={section}
                                        files={displayFiles}
                                        onRemove={onRemoveExistingFiles}
                                        matches={data.matches}
                                    /> : <Loader message="Loading" /> }

                                    <Buttons
                                        formik={formik}
                                        postText={data.offerStatus === 'Closed' ? 'Repost' : 'Save'}
                                        sending={sending}
                                    />
                                </Form>
                            </>
                        )}
                    </Formik>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

EditOffer.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default withAuthProtectedRouter(EditOffer, [COMPANY, ADMIN]);
