import React from 'react';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { COMPANY } from '../../../consts/userRoles';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import NudgeTabs from '../../../components/NudgeTabs';
import { Col, Row } from 'reactstrap';
import TimeToHireDash from './TimeToHireDash';
import TestTimePerStatus from './TestTimePerStatus';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

const StatisticsTimePerPage = () => {
    const { user } = React.useContext(AuthContext);

    const TABS = {
        TIME_PER_STAGE: 'time-per-stage',
        TIME_TO_HIRE: 'time-to-hire',
    }

    const [selectedTab, setSelectedTab] = React.useState(Object.values(TABS)[0]);

    return (<SignInPageTemplate>
        <Row>
            <Col md={12}>
                <NudgeTabs
                    label="Report Types"
                    selected={selectedTab}
                    setTab={setSelectedTab}
                    tabs={[
                        { id: TABS.TIME_PER_STAGE, label: 'Time per Stage', condition: true },
                        { id: TABS.TIME_TO_HIRE, label: 'Time to Hire', condition: true },
                    ]}
                />
            </Col>
            <Col md={12}>
                { selectedTab == TABS.TIME_PER_STAGE ? 
                    <TestTimePerStatus user={user}/>
                : selectedTab == TABS.TIME_TO_HIRE ?
                    <TimeToHireDash user={user}/>
                : null }
            </Col>
        </Row>
    </SignInPageTemplate>)
}

export default withAuthProtectedRouter(StatisticsTimePerPage, [COMPANY])