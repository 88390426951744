import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, postData, putData, authFetcher } from '../../../../../api/tools';

import SearchBox from '../../../../../components/SearchBox';
import { API_DASHBOARD_ADMIN } from '../../../../../consts/urls';
import moment from 'moment';
import { capitaliseFirstLetter } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

function arrToString(input) {
    let str = '';
    if (input.length) {
        input.forEach((i, index) => {
            if (index == 0) {
                str += `${i.title ? i.title : i.content ? i.content : i.name}`;
            } else {
                str += `,${i.title ? i.title : i.content ? i.content : i.name}`;
            }
        });
    }
    return str;
}

const TestingGround = ({ id }) => {
    const [search, setSearch] = React.useState('');
    const findId = search;

    async function updateCase() {
        const contractor = await authFetcher(`${API_DASHBOARD_ADMIN}/Contractors/${findId}`);
        const split = contractor.expectedEarnings.replaceAll(' ', '').split('-');

        let salary;
        if (contractor.timing == 'Yearly') {
            salary = ((parseFloat(split[0]) + parseFloat(split[1])) / 2 / 52 / 40).toFixed(2);
        } else if (contractor.timing == 'Daily') {
            salary = ((parseFloat(split[0]) + parseFloat(split[1]) / 2) / 8).toFixed(2);
        } else {
            salary = ((parseFloat(split[0]) + parseFloat(split[1])) / 2).toFixed(2);
        }

        const body = {
            Certificates: arrToString(contractor.certificates),
            Education: contractor.education.replaceAll('/', ' in '),
            Experience: contractor.bio,
            Freelance: contractor.freelancerStaff,
            Id: contractor.id,
            Industry: contractor.job.industry,
            Location: contractor.location || '',
            Remuneration: parseFloat(salary),
            'Services Required': contractor.job.name,
            Skills: arrToString(contractor.skills),
            'Start Date': moment(contractor.availableFrom).format(`YYYY-MM-DD`),
            Values: arrToString(contractor.values),
            'Work Mode': capitaliseFirstLetter(contractor.workMode),
        };

        const putUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/${id}/case/${findId}`;
        const putResponse = await putData(body, putUrl);
    }

    return (
        <>
            <SearchBox value={search} setValue={setSearch} button={false} placeholder={'User ID goes here...'} />
            <Button onClick={updateCase} color={'success'}>
                Post
            </Button>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
