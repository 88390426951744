import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import { authPostData, postWithAuthorizationHeader } from '../../../api/tools';
import { useModal } from '../../../components/helper';
import SuccessModal from '../../../components/modals/SuccessModal';
import { API_DASHBOARD_COMPANY, API_DASHBOARD_WILLO, URLS } from '../../../consts/urls';
import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';

const InviteButton = ({ id, offerId, refresh }) => {
    const { toggle, isOpen } = useModal();
    const [showLoader, setShowLoader] = useState();
    const navigate = useNavigate();

    const onClick = async () => {
        setShowLoader(true);
        const body = { offerId };

        const url = `${API_DASHBOARD_COMPANY}/Contractors/${id}/Invite`;
        const response = await authPostData(body, url);

        setShowLoader(false);
        toggle();
    };

    const onClose = async () => {
        await refresh();
        toggle();
        navigate(`${URLS.COMPANY_CONTRACTS}/${offerId}`);
    };

    return (
        <>
            <SuccessModal
                isOpen={isOpen}
                toggle={onClose}
                body="You have invited this candidate to complete an interview. We will notify you by email once this is complete."
            />
            <ClickButton
                size="lg"
                color={'success'}
                disabled={showLoader}
                className={style.btn}
                onClick={onClick}
            >
                {showLoader ? 'Loading...' : 'Invite to Interview'}
            </ClickButton>
        </>
    );
};

export default InviteButton;
