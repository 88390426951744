import React from 'react';
import { Button } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData, NEVER_REFRESH } from '../../../../../api/tools';
import { API_DASHBOARD_ADMIN, API_DASHBOARD_COMPANY } from '../../../../../consts/urls';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';

const TestingGround = () => {
    function arrToString(input) {
        let str = '';
        if (input.length) {
            input.forEach((i, index) => {
                if (index == 0) {
                    str += `${i.title ? i.title : i.content ? i.content : i.name}`;
                } else {
                    str += `,${i.title ? i.title : i.content ? i.content : i.name}`;
                }
            });
        }
        return str;
    }

    const getURL = `${API_DASHBOARD_ADMIN}/Offers/${416}`;
    const { data, mutate } = useSWR(getURL, authFetcher, NEVER_REFRESH);

    const postURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/retain`;

    async function retainCase() {
        const split = data.rangeExpected.split('-');

        const body = {
            projectId: '4aedf72155b64e46a61cffd5b83a2d79',
            data: {
                Certificates: arrToString(data.certificates),
                Education: data.qualification.replaceAll('/', ' in '),
                Experience: data.description,
                Freelance: data.type,
                Id: data.id,
                Industry: data.sector,
                Location: data.location || data.locationType,
                Remuneration: (parseInt(split[0]) + parseInt(split[1])) / 2,
                'Services Required': data.title,
                Skills: arrToString(data.skills),
                'Start Date': new Date(data.startDate),
                Values: data.company.statement,
                'Work Mode': data.workType,
            },
        };

        try {
            const response = await authPostData(body, postURL);
        } catch (e) {
            console.log('Invalid profile: details missing');
        }
    }

    React.useEffect(() => {
        if (data) console.log(data);
    }, [data]);

    return (
        <>
            <Button onClick={retainCase} color={'success'}>
                Post
            </Button>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
