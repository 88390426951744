import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import useMobile from '../../hooks/useMobile';

import { DEFAULT_PICTURE, URLS } from '../../consts/urls';
import { MATCH_STATUSES_DICTIONARY } from '../../consts/matchOfferStatuses';

import { calculateLightness, generateMonoLinearGradient, sort_by_key } from '../pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';
import NudgeCard from '../NudgeCard';
import ClickButton from '../buttons/NudgeButton';
import NudgeButtonGroup from '../buttons/ButtonGroup';

const PER_PAGE_DESKTOP = 6;
const PER_PAGE_MOBILE = 6;

export const ContractorDashOfferList = ({ data, theme }) => {

    const [showArchived, setShowArchived] = React.useState(false);
    const toggleShowArchived = () =>  setShowArchived(!showArchived);
    
    const { isMobileWide } = useMobile();
    const PER_PAGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE_DESKTOP;

    var localOffers = [];
    for(var i = 1; i <= PER_PAGE; i++) {
        localOffers.push({ id: i, placeholder: true },);
    }

    const navigate = useNavigate();

    function resolveStatus(mStatus, oStatus) {
        if (mStatus == 'CompanyAccepted') return mStatus;

        switch (oStatus) {
            case 'Closed':
            case 'Expired':
                return oStatus;
            default:
                return mStatus;
        }
    }

    

    if (data) {
        let concat = data.pending
        .concat(data.awarded)
        .concat(data.dismissed);

        const STATUSES = [
            'awarded',
            'dismissed',
            'pending'
        ]

        let offers = concat.map((offer, index) => ({
            id: offer.id,
            title: offer.name,
            company: {
                name: offer.companyName || '???',
                icon: offer.logo,
            },
            matchId: offer.matchId,

            matchStatus: resolveStatus(offer.matchStatus, offer.offerStatus),
            status: STATUSES[offer.matchType],
            statusText: STATUSES[offer.matchType],
            statusCode: offer.statusCode,

            lastUpdated: offer.lastUpdated,
            offerStatus: offer.offerStatus,

            // placeholder: true
        }));

        //offers = sort_by_key(offers, 'matchId', 1);
        localOffers = offers.filter((offer) => offer.company.name !== '???').filter((item) => !["Closed", "Expired"].includes(item.offerStatus) || !showArchived).slice(0, offers.length > PER_PAGE ? localOffers.length : offers.length);
    }

    function getRedirect(matchId, offerId, matchStatus, offerStatus) {
        var lStatus = matchStatus;


        switch (lStatus) {
            case 'InterviewScheduled':
            case 'InterviewSubmitted':
            case 'Negotiation':
            case 'CompanyAccepted':
            case 'WilloInvited':
            case 'FaceInterviewScheduled':
            case 'VRAssessmentSubmitted':
            case 'VRAssessmentScheduled':
            case 'WaitingOnFaceSchedule':
            case 'WaitingOnVRSchedule':
                return `${URLS.MEETING}/${matchId}`;
            default:
                return `${URLS.OFFERS}/${offerId}`;
        }
    }

    const colours = (oStatus, mStatus) => {

        if(oStatus == "Closed" || oStatus == "Expired") return "#fd7c7c";

        if(mStatus == "awarded") return "#34c782";
        if(mStatus == "dismissed") return "#fd7c7c";
        if(mStatus == "pending") return "#33a1cc";

        return "gray";
    }

    return (
        <Row className={style.topRow}>
            <Col>
                <Row className={style.dashHeader}>
                    <Col xs={0} sm={0} md={0} lg={6}>
                        <h2 className={style.offerListHeader}>Recent Interviews</h2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <NudgeButtonGroup
                            buttons={[
                                { available: true, disabled: !data, onClick: () => navigate(`${URLS.INTERVIEWS}`), label: 'View All' },
                                { available: true, disabled: !data, onClick: () => toggleShowArchived(), label: `${!showArchived ? 'Hide' : 'Show'} Archived`, color: !showArchived ? 'danger' : 'success' },
                            ]}
                        />
                    </Col>
                </Row>
                <Row className={style.row}>
                    <Card className={style.offerGrid}>
                        <Row>
                            {localOffers.length < 1 ? (
                                <NudgeCard theme={theme} className={style.noContract}>
                                    <CardBody>
                                        No contracts to show. View our job reel to register interest in jobs.
                                    </CardBody>
                                </NudgeCard>
                            ) : (
                                ''
                            )}
                            {sort_by_key(localOffers, 'statusCode', false).map((job) => (
                                <Col key={job.id} xs={12} sm={12} md={12} lg={6} className={`${style.jobCol}`}>
                                    <NudgeCard theme={theme} onClick={() => navigate(!job.placeholder ? getRedirect(job.matchId, job.id, job.matchStatus, job.offerStatus) : '')} className={`${style.reelJob}`}>
                                        <div className={`${style.banner}`} style={{ background: generateMonoLinearGradient(colours(job.offerStatus, job.status)), borderBottom: !job.placeholder ? `2px solid ${colours(job.offerStatus, job.status)}` : ""}}/>
                                        {!job.placeholder ? (
                                            <CardBody className={style.reelJobBody}>
                                                <div className={style.jobInfo}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            {job.company.icon ? (
                                                                <div className={style.offerIcon}>
                                                                    <img
                                                                        style={{ outlineColor: colours(job.offerStatus, job.status) }}
                                                                        src={job.company.icon || DEFAULT_PICTURE}
                                                                        alt={job.company.name}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                        <Col xs={9}>
                                                            <h3
                                                                className={`${style.offerCompany} ${
                                                                    job.placeholder ? style.placeholder : ''
                                                                }`}
                                                                style={{ color: calculateLightness(colours(job.status)) > 50 ? "black" : "white" }}
                                                            >
                                                                {job.title}
                                                            </h3>
                                                            <p
                                                                className={`${style.offerTitle} ${
                                                                    job.placeholder ? style.placeholder : ''
                                                                }`}
                                                                style={{ color: calculateLightness(colours(job.status)) > 50 ? "black" : "white" }}
                                                            >
                                                                {job.company.name}
                                                            </p>
                                                            <p
                                                                className={`${style.offerStatus} ${
                                                                    job.placeholder ? style.placeholder : ''
                                                                }`}
                                                            >
                                                                {MATCH_STATUSES_DICTIONARY[job.matchStatus]}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        ) : (
                                            <CardBody className={style.reelJobBody}>
                                                <div className={style.jobInfo}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div className={style.picturePlaceholder}>
                                                                <div className={style.activity} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div className={style.titlePlaceholder}>
                                                                <div className={style.activity} />
                                                            </div>
                                                            <div className={style.companyPlaceholder}>
                                                                <div className={style.activity} />
                                                            </div>
                                                            <div className={style.statusPlaceholder}>
                                                                <div className={style.activity} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        )}
                                    </NudgeCard>
                                </Col>
                            ))}
                            {[...Array(PER_PAGE - localOffers?.length)].map(() => (
                                <Col xs={12} sm={12} md={6} lg={12}>
                                    <div className={style.emptyOffer} />
                                </Col>
                            ))}
                        </Row>
                    </Card>
                </Row>
            </Col>
        </Row>
    );
};

export default ContractorDashOfferList;
