import React from 'react';
import { Button } from 'reactstrap';

import { authPostDataWithNoResponse } from '../../../../api/tools';
import { useModal } from '../../../../components/helper';
import TextFieldModal from '../../../../components/modals/TextFieldModal';
import { API_GATEWAY } from '../../../../consts/urls';
import ClickButton from '../../../../components/buttons/NudgeButton';

const RejectButton = ({ contractId, id, refresh }) => {
    const { toggle, isOpen } = useModal();

    const onSubmit = async (values) => {
        await authPostDataWithNoResponse(
            values,
            `${API_GATEWAY}/InvoiceCompany/Contracts/${contractId}/Timesheets/${id}/Reject`,
        );
        await refresh();
        toggle();
    };

    return (
        <>
            <TextFieldModal
                toggle={toggle}
                isOpen={isOpen}
                onGoBack={toggle}
                onSubmit={onSubmit}
                header="Are you sure you want to reject?"
            />
            <ClickButton onClick={toggle} className="w-100 mt-10" color="danger">
                Reject
            </ClickButton>
        </>
    );
};

export default RejectButton;
