import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup } from 'reactstrap';

import SkipButton from '../../buttons/SkipButton';
import SubmitButton from '../../buttons/SubmitButton';

const SkipAndSubmit = ({ formik, skipCurrentStep }) => (
    <FormGroup row className="mt-20">
        <Col>
            <SkipButton skipCurrentStep={skipCurrentStep} />
        </Col>
        <Col>
            <SubmitButton formik={formik} />
        </Col>
    </FormGroup>
);

SkipAndSubmit.propTypes = {
    formik: PropTypes.object.isRequired,
    skipCurrentStep: PropTypes.func.isRequired,
};

export default SkipAndSubmit;
