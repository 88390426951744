import { Formik } from 'formik';
import React from 'react';

import { Range } from 'react-range';
import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const SliderComp = ({ form, field, name, minValue, maxValue, step }) => {
    const [state, setState] = React.useState({ values: [form.values[field][name] || minValue] });

    React.useEffect(() => {
        var change = `${field}${!!name && `.${name}`}`;

        form.setFieldValue(change, state.values[0]);
    }, [state]);

    const theme = GetPageTheme();

    return (
        <>
            <div className={style.container} id={'slider-comp'}>
                <Range
                    id={'slider-comp'}
                    step={step}
                    min={minValue}
                    max={maxValue}
                    values={state.values}
                    onChange={(values) => setState({ values })}
                    renderTrack={({ props, children }) => (
                        <div id={'slider-comp'} {...props} className={`${props.style} ${theme == 'light' ? style.track : style.trackDark}`}>
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div {...props} className={`${props.style} ${theme == 'light' ? style.thumb : style.thumbDark}`}>
                            <h5 id={'slider-comp'} className={style.label}>{state.values[0]}</h5>
                        </div>
                    )}
                    renderMark={({ props }) => <div id={'slider-comp'} {...props} className={`${props.style} ${theme == 'light' ? style.mark : style.markDark}`} />}
                />
            </div>
        </>
    );
};

export default SliderComp;
