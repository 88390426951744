import React from 'react';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import CompanyAdminCandidatePage from '../../../components/OpportunityPages/CompanyAdmin';
import { API_DASHBOARD_ADMIN } from '../../../consts/urls';

export const AdminCompanyOffer = () => {
    return <CompanyAdminCandidatePage roleUrl={API_DASHBOARD_ADMIN} />;
};

export default withAuthProtectedRouter(AdminCompanyOffer, [ADMIN]);
