import React from 'react';
import { parseMergedChat } from './helper';

import moment from 'moment';
import style from './style.module.scss';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import { DEFAULT_PICTURE } from '../../consts/urls';

const MessagesWrapper = ({ chat, user, sending, showScroll, scroll, overview, pageRef, currentScroll }) => {

    const [mergedChats, setMergedChats] = React.useState([]);

    React.useEffect(() => {
        if (chat?.length) {
            setMergedChats(parseMergedChat(chat, user));
        }
    }, [chat, sending]);

    React.useEffect(() => {
        let messages = document.getElementById('message-wrapper');

        if(pageRef == 1) {
            messages.scrollTop = messages.scrollHeight;
        } else {
            if(messages.scrollTop == 0) {
                messages.scrollTop += (messages.scrollHeight - currentScroll)
            }
            else  {
                messages.scrollTo({
                    top: messages.scrollHeight,
                });
            }
        }
    }, [mergedChats]);
    
    const theme = GetPageTheme();

    const isToday = (date) => {
        return moment(date).format("DD-MM-YYYY") == moment().format("DD-MM-YYYY");
    }

    return (<>
        <div id="message-wrapper" className={`${style.cardWrapper}`} style={{ padding: '12px', overflowY: 'auto' }}>
            { mergedChats ? Object.keys(mergedChats).map((date) => <div>
                {  mergedChats[date].map((chatArr, index) => <div className={ !chatArr.userAuthor ? style.messageWrapper : style.authorMessageWrapper}>
                    
                    {(index == 0) && <div class="flex-center-align-justify" style={{ width: '100%', marginBottom: '16px' }}>
                        <b id={!overview || theme == 'light' ? `card-sub-${theme}` : `card-${theme}`} style={{ width: '60%', textAlign: 'center', borderRadius: '0.25rem', padding: '4px' }}>
                            {isToday(date) ? "Today" : moment(date).format("MMMM Do, YYYY")}
                        </b>    
                    </div>}

                    <div class="flexy w-100" style={{ columnGap: '12px' }}>
                        { !chatArr.userAuthor && <img src={chatArr.photo || DEFAULT_PICTURE} className={style.userPhoto} /> }
                        <div class="w-100">
                            <h5 style={{ fontSize: '1rem', textAlign: !chatArr.userAuthor ? 'left' : 'right', margin: 0 }}>
                                {chatArr.authorName} {chatArr.isAdmin && <FontAwesomeIcon title="Admin" icon={['fad', 'crown']}/>}
                            </h5>
                            <p style={{ textAlign: !chatArr.userAuthor ? 'left' : 'right', margin: 0, marginBottom: '6px' }}><i>{ moment(chatArr.date).format(`${isToday(chatArr.date) ? "[Today]" : "DD/MM/YYYY"} [at] hh:mm a`)}</i></p>
                        </div>
                        { chatArr.userAuthor && <img src={chatArr.photo || DEFAULT_PICTURE} className={style.userPhotoYou} /> }
                    </div>
                    <div style={{ display: 'flex', justifyContent: !chatArr.userAuthor ? 'left' : 'right' }}>
                    
                    {/* left-side file icon */}
                    { chatArr.userAuthor && chatArr.files.length ? <div className={style.fileContainer} style={{ marginRight: '12px' }}>
                        { chatArr.files.map((item, index) => <div className={theme == 'light' ? style.paperclip : style.paperclipDark} style={{ marginBottom: '4px' }}>
                            <FontAwesomeIcon onClick={() => window.open(item, '_blank', 'noreferrer')} icon={['far', 'file-pdf']}/>
                        </div> )}
                    </div> : null }

                    {/* Message box */}
                    <Card id={ !chatArr.userAuthor ? `card-chat-${theme}-other` : `card-chat-${theme}`} className={`${!chatArr.userAuthor ? style.chat : style.chatOther}`}>
                        { chatArr.messages.map((item, index) => 
                            <h5 title={item.id} style={{ marginBottom: '6px' }}>{item.message}</h5>
                        )}
                        { chatArr.userAuthor && chatArr.read ? <p><FontAwesomeIcon className={style.checkIcon} icon={['fas', 'check-circle']}/></p> : null }
                    </Card>

                    {/* right-side file icon */}
                    { !chatArr.userAuthor && chatArr.files.length ? <div className={style.fileContainer} style={{ marginLeft: '12px' }}>
                        { chatArr.files.map((item, index) => <div className={theme == 'light' ? style.paperclip : style.paperclipDark} style={{ marginBottom: '4px' }}>
                            <FontAwesomeIcon onClick={() => window.open(item, '_blank', 'noreferrer')} icon={['far', 'file-pdf']}/>
                        </div> )}
                    </div> : null }
                </div>
            </div>)}
            </div>) : null }
        </div>

        { !!sending && <div style={{ height: 0 }} class="flex-center-align-justify">
            <div className={style.dropText}>
                Loading messages
            </div> 
        </div>}
        
        { (showScroll === true && !sending) && <div style={{ height: 0 }} class="flex-center-align-justify">
            <div className={style.dropText} onClick={scroll}>
                {"Scroll Down "}<FontAwesomeIcon icon={['fas', 'arrow-alt-down']}/>
            </div> 
        </div> }
    </>)
}

export default MessagesWrapper;