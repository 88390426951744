import React from 'react';

import SignInPageTemplate from '../../SignInPageTemplate';
import { Card, Col, Row } from 'reactstrap';

import defaultVR from '../../../assets/images/vr_default.png';

import BookingCalendar from './BookingCalendar';
import { authFetcher } from '../../../api/tools';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import moment from 'moment';
import Loader from '../../Loader';
import UploadResultsModal from '../../../pages/admin/TestingGround/Nudge/ManageBookableAssessments/UploadResultsModal';
import NudgeBoxes from '../../NudgeBoxes';
import ClickButton from '../../buttons/NudgeButton';

import style from './style.module.scss';
import { GetPageTheme } from '../GenericCompanyProfile/HelperComponents';

const VRBookingPage = ({ url }) => {
    const { user, mutate } = React.useContext(AuthContext);

    const [assessments, setAssessments] = React.useState();
    const [bookings, setBookings] = React.useState();

    const [selectedBooking, setSelectedBooking] = React.useState();
    const toggleBooking = () => setSelectedBooking();

    React.useEffect(() => {
        async function doData() {
            const res_assessments = await authFetcher(`${url}/BookableAssessments`);
            const res_bookings = await authFetcher(`${url}/AssessmentBookings`);
            
            setAssessments(res_assessments.reverse());

            if(res_bookings) {
                setBookings(res_bookings);
            }
        }

        doData();
    }, [user])

    const [selectedAssessment, setSelectedAssessment] = React.useState();
    const [isOpen, setIsOpen] = React.useState(false);

    function toggle(assessment) {
        if(assessment) setSelectedAssessment(assessment)
        setIsOpen(!isOpen);
    }

    const theme = GetPageTheme();

    return (
        <SignInPageTemplate customHeader={"VR Booking"}>
            { assessments ? <>
                <UploadResultsModal booking={selectedBooking} isOpen={!!selectedBooking} toggle={toggleBooking}/>
                {selectedAssessment ? <BookingCalendar userId={user?.id} mutate={mutate} assessment={selectedAssessment} isOpen={isOpen} toggle={toggle} url={url} excludedDates={bookings.filter(p => p.assessmentId == selectedAssessment.id)}/> : null }
                <Card className={style.assessmentPageHeaderCard}>
                    <h5>Skill Assessments</h5>
                    <h4>We offer candidate assessment services utilising our purpose built virtual reality tool. It provides deeper insights into an indviduals skills, behaviours and attitude leading to improved hiring decisions.</h4>
                </Card>
                <div className={style.assessmentListContainer}>
                    <Row className={style.assessmentList}>
                        { assessments.map((item, index) => <Col xs={12} sm={6} md={6}>
                            <Card className={style.assessmentCard}>
                                <Row>
                                    <Col md={12} lg={3} className={style.assessmentCardItem}>
                                        <img src={item.photo || defaultVR}/>
                                    </Col>
                                    <Col md={12} lg={9} className={style.assessmentCardItem}>
                                        <h5>{item.title}</h5>
                                        <div>
                                            <NudgeBoxes
                                                data={item.keySkills.map((skill) => ({ label: skill, danger: false}))}
                                                rows={2}
                                            />
                                        </div>
                                        <h4 id={`card-sub-${theme}`}>{item.description}</h4>
                                        
                                        <p><b><u>Your Bookings</u></b></p>
                                        { bookings?.filter((b) => b.assessmentId == item.id).length ? <div id={`card-sub-${theme}`} className={style.bookingListContainer}>
                                            { bookings ? bookings.filter((b) => b.assessmentId == item.id).map((booking, index) => <div>
                                                <b>{booking.participantAddress}</b> - {moment(booking.timeSlot).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                                <p><ClickButton disabled={!booking.reportLink} onClick={() => setSelectedBooking(booking)} color={'success'}>View Assessment Results</ClickButton></p>
                                                
                                            </div>) : null }
                                        </div> : <div id={`card-sub-${theme}`} className={style.bookingListContainer}>
                                            <p><i>No bookings have yet been made.</i></p>
                                        </div> }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className={style.assessmentCardItem}>
                                        <ClickButton className={style.submitButton} color="success" onClick={() => toggle(item)}>Book Now</ClickButton>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>)}
                    </Row>
                </div>
            </> : <Loader message="Loading bookable assessments" /> }
        </SignInPageTemplate>
    )
}

export default VRBookingPage;