import React from 'react';

import { useNavigate } from 'react-router-dom';
import ClickButton from '../NudgeButton';

const LinkButton = ({ link, className = '', children }) => {
    const navigate = useNavigate();

    return (
        <ClickButton
            className={className}
            disabled={!link}
            color={'success'}
            onClick={() => navigate(link)}
        >
            {children}
        </ClickButton>
    );
};

export default LinkButton;
