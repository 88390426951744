import { Field, Form } from 'formik';
import React from 'react';
import { Label } from 'reactstrap';

import AppInput from '../../../../../../components/AppInput';

import { FormGroup } from 'reactstrap';
import { FIELDS, formLabels } from './validation';
import NewInputForm from '../../../../../../components/forms/NewInputForm';
import Loader from '../../../../../../components/Loader';

import style from '../style.module.scss';
import ClickButton from '../../../../../../components/buttons/NudgeButton';
import PhotoUploadForm from '../../../../../../components/forms/PhotoUploadForm';

const EmailForm = ({ formik, sending, success, selectedEdit, defaultImage }) => {
    
    React.useEffect(() => {
        formik.setValues({ keySkills: [] })
    }, [success])

    React.useEffect(() => {
        if(selectedEdit.get) {
            formik.setValues(selectedEdit.get)
        } else {
            formik.handleReset();
        }
    }, [selectedEdit.get])

    const formStructure = [
        { id: FIELDS.TITLE, label: formLabels[FIELDS.TITLE], component: AppInput },
        { id: FIELDS.DESCRIPTION, label: formLabels[FIELDS.DESCRIPTION], component: AppInput, type: "textarea" },
        { id: FIELDS.KEY_SKILLS, label: formLabels[FIELDS.KEY_SKILLS], component: NewInputForm, field: FIELDS.KEY_SKILLS, fieldLimit: 6, addValue: '' },
    ]
    
    return !sending ? (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <PhotoUploadForm
                formik={formik}
                id={FIELDS.PHOTO}
                defaultImage={defaultImage}
            />

            { formStructure.map((item, index) => <>
                <Label><b>{item.label}</b></Label>
                <FormGroup className="mb-20">
                    <Field
                        formik={formik}
                        {...item}
                    />
                </FormGroup>
            </> )}
            <div className={style.buttonContainer}>
                <ClickButton type="submit" style={{ float: 'right' }} color="success">{selectedEdit.get ? "Update" : "Create"}</ClickButton>
                { selectedEdit.get ? <ClickButton type="button" onClick={() => selectedEdit.set()} style={{ float: 'right' }} color="danger">Cancel</ClickButton> : null }
            </div>
        </Form>
    ) : <Loader message="Creating bookable assessment"/>;
};

export default EmailForm;
