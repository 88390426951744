import React from 'react';
import { Col, Row, Card } from 'reactstrap';


import ScoreBreakdown from '../ScoreBreakdown';

import style from './style.module.scss';
import { MATCH_STATUSES_DICTIONARY } from '../../../../../consts/matchOfferStatuses';
import ClickButton from '../../../../buttons/NudgeButton';
import { GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import { API_DASHBOARD_COMPANY } from '../../../../../consts/urls';
import { authPostData } from '../../../../../api/tools';

export async function recordSmartMatchAndNotify(u, weights, offerId, set) {
    const url = `${API_DASHBOARD_COMPANY}/Offers/${offerId}/Record/${u.Id || u.id}`;

    var body = {};
    if(u) {
        body = {
            Services: u.match_explanation.find((i) => i.field == "Services Required")?.similarity / weights["services_Required"] || 0,
            Remuneration: u.match_explanation.find((i) => i.field == "Remuneration")?.similarity / weights["remuneration"] || 0,
            Work_Mode: u.match_explanation.find((i) => i.field == "Work Mode")?.similarity || 0,
            Education: u.match_explanation.find((i) => i.field == "Education")?.similarity / weights["education"] || 0,
            Experience: u.match_explanation.find((i) => i.field == "Experience")?.similarity / weights["experience"] || 0,
            Freelance: u.match_explanation.find((i) => i.field == "Freelance")?.similarity || 0,
            User_Values: u.match_explanation.find((i) => i.field == "Values")?.similarity || 0,
            Certificates: u.match_explanation.find((i) => i.field == "Certificates")?.similarity / weights["certificates"] || 0,
            Skills: u.match_explanation.find((i) => i.field == "Skills")?.similarity / weights["skills"] || 0,

            Feedback: JSON.stringify(u.feedback)
        }
    }

    const response = await authPostData(body, url, {});

    if(response) {
        set(`success::/Recruiter has been notified of your interest in ${u.name}.`)
    } else {
        set(`danger::/Recruiter has already been notified.`)
    }
}

const UserCard = ({ user, offerId, weights, match, inviteCandidate, sending, setAlert }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    function directUser(id) {
        window.open(`/company/offer/${offerId}/contractors/${id}`, '_blank', 'noreferrer');
    }

    

    if(match?.smartMatch) {
        var smart = match?.smartMatch;

        user = {
            Id: match.contractor.id,
            profileJob: match.contractor.job.name,
            match_explanation: [
                { field: "Services Required", similarity: smart.services },
                { field: "Remuneration", similarity: smart.remuneration },
                { field: "Education", similarity: smart.education },
                { field: "Experience", similarity: smart.experience },
                { field: "Certificates", similarity: smart.certificates },
                { field: "Skills", similarity: smart.skills },
                { field: "Values", similarity: smart.user_Values },
            ],
            feedback: JSON.parse(smart?.feedback),
            totalScore__: smart?.totalScore
        }

        if(user?.feedback) {
            var fb = user.feedback;

            if(fb.Skills) {
                var s = fb.Skills;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Skills
                }
            }

            if(fb.Certificates) {
                var s = fb.Certificates;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Certificates
                }
            }

            if(fb.Values) {
                var s = fb.Values;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Values
                }
            }

            user.feedback = fb;
        }
    }

    const theme = GetPageTheme();
    return !match?.smartMatch ? (
        <Row>
            <Col md={12}>
                <Card className={style.userCard}>
                    { modalOpen ? (
                        <ScoreBreakdown
                            user={user}
                            offerId={offerId}
                            isOpen={modalOpen}
                            toggle={toggle}
                            weights={weights}
                        />
                    ) : null }
                    <Row>
                        <Col xs={12} md={6}>
                            <h5>
                                {user.name && user.name != " " ? user.name : <i>No name</i>} - {user.profileJob && user.profileJob != " " ? user.profileJob : <i>No job title</i>}
                            </h5>
                        </Col>
                        <Col xs={12} md={6}>
                            <h5 style={{ textAlign: 'right' }}>{user.totalScore__.toFixed(1)}%</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            {match ? (
                                <h5 style={theme == 'dark' ? { color: 'white', backgroundColor: 'red', padding: '6px', borderRadius: '0.25rem' } : { color: 'red' }}>{MATCH_STATUSES_DICTIONARY[match.statusText]}</h5>
                            ) : null}
                        </Col>
                        <Col xs={12} md={7}>
                            <div style={{ display: 'flex', columnGap: '6px' }}>
                                { user.refId != 0 ? <ClickButton disabled={match || sending} className={style.button} color="success" onClick={() => recordSmartMatchAndNotify(user, weights, offerId, setAlert)}>
                                    Record Results
                                </ClickButton> : !match ? <ClickButton disabled={match || sending} className={style.button} color="success" onClick={() => inviteCandidate(user, user.Id, offerId)}>
                                    Invite to Apply
                                </ClickButton> : null }
                                <ClickButton className={style.button} color="success" onClick={() => directUser(user.Id)}>
                                    View Profile
                                </ClickButton>
                                <ClickButton className={style.button} color="success" onClick={toggle}>
                                    Breakdown
                                </ClickButton>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    ) : <Row>
        <Col md={12}>
            <Card className={style.userCard}>
                { modalOpen ? (
                    <ScoreBreakdown
                        user={user}
                        offerId={offerId}
                        isOpen={modalOpen}
                        toggle={toggle}
                        weights={weights}
                        stored
                    />
                ) : null }
                <Row>
                    <Col xs={8} md={7}>
                        <h5>
                            { match.contractor.name } - {user.profileJob && user.profileJob != " " ? user.profileJob : <i>No job title</i>}
                        </h5>
                    </Col>
                    <Col xs={4} md={5}>
                        <h5 style={{ textAlign: 'right' }}>{user.totalScore__.toFixed(1)}%</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        {match ? (
                            <h5 style={theme == 'dark' ? { color: 'white', backgroundColor: 'red', padding: '6px', borderRadius: '0.25rem' } : { color: 'red' }}>{MATCH_STATUSES_DICTIONARY[match.statusText]}</h5>
                        ) : null}
                    </Col>
                    <Col xs={12} md={7}>
                        <div style={{ display: 'flex', columnGap: '6px' }}>
                            <ClickButton className={style.button} color="success" onClick={() => directUser(match.contractor.id )}>
                                View Profile
                            </ClickButton>
                            <ClickButton className={style.button} color="success" onClick={toggle}>
                                Breakdown
                            </ClickButton>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>;
};

export default UserCard;
