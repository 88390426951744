import React, { useState } from 'react';
import { Col, Row, Card, CardTitle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import useSWR from 'swr';

import SearchBox from '../../../components/SearchBox';
import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_CONTRACTOR, URLS, DEFAULT_PICTURE } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';
import { FullStatCardScaling, generateMonoLinearGradient } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../components/buttons/NudgeButton';
import CompanyList from './CompanyCard';

const Directory = () => {
    const { data } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Companies/All`, authFetcher, NO_REFRESH_INTERVAL);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    let filteredArray = [];

    if (data) {
        filteredArray = data.companies;
        filteredArray = filteredArray.filter((item) => item.parentId === '' || item.parentId === ' ');

        data.companies.forEach((item) => {
            if (item.pictureUrl === 'Empty') {
                item.pictureUrl = null;
            }
        });

        if (search === '') {
        } else {
            filteredArray = filteredArray.filter((e) => e.name.toUpperCase().includes(search.toUpperCase()));
        }
    }

    return (
        <SignInPageTemplate>
            <Card className={style.headerCard}>
                <h1 className={style.headerText}>Company Directory</h1>
                <h5 className={style.headerDescription}>
                    Use this directory to search for exciting renewables organisations and projects.
                </h5>
                <h5 className={style.headerDescription}>
                    Click on each to find out more and register interest for their projects or specific roles.
                </h5>
                <div className={style.searchBoxContainer}>
                    <SearchBox value={search} setValue={setSearch} />
                </div>
            </Card>
            {data ? (
                <div>
                    <Row className={style.list}>
                        <CompanyList companies={filteredArray} />
                    </Row>
                </div>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Directory, [USER]);
