import React from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import useMobile from '../../../../../hooks/useMobile';
import { GetPageTheme, capitaliseFirstLetter } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';
import ReportGenerator from '../../../../../pages/admin/TestingGround/RGU/ReportGenerator';
import NudgeModal from '../../../../NudgeModal';

const ScoreBreakdown = ({ user, offerId, isOpen, toggle, weights, stored }) => {
    const [scores, setScores] = React.useState();
    const [totalScore, setTotalScore] = React.useState(5.54);

    const { isMobileWide } = useMobile();

    const weighted_attrs = [
        { id: 'services_Required', name: 'services required' },
        { id: 'remuneration', name: 'remuneration' },
        { id: 'education', name: 'education' },
        { id: 'certificates', name: 'certificates' },
        { id: 'skills', name: 'skills' },
        { id: 'experience', name: 'experience' },
        { id: 'values', name: 'experience' },
    ];

    React.useEffect(() => {
        if (user) {
            let localScores = {};
            let localTotal = 0;
            weighted_attrs.forEach((item) => {
                let attr = user.match_explanation.find((ex) => item.name.toLowerCase() == ex.field.toLowerCase());
                let score = 0;

                if (attr) {
                    if(stored) {
                        score = parseFloat(attr.similarity) * 100
                    } else {
                        score = parseFloat(
                            weights[item.id] > 0
                                ? (attr.similarity / weights[item.id]).toFixed(2) * 100
                                : attr.similarity.toFixed(2) * 100,
                        );

                        if(score > 100) score = 100
                    }
                    localScores[item.name.replaceAll(' ', '_')] = score;
                } else {
                    localScores[item.name.replaceAll(' ', '_')] = 0;
                }

                localTotal += score;
            });

            setTotalScore(localTotal / weighted_attrs.length);
            setScores(localScores);
        }
    }, [user]);

/*

    $special-blue-light: #1CC4DD;
    $special-blue: #17a2b8;
    $special-blue-dark: #0e6674;

    $forest: #274237;
    $forest-light: #407962;
    $forest-very-light: #5dad8d;

*/
    
    const theme = GetPageTheme();
    function getColor(i, score) {
        if (score == 0) {
            return theme == 'light' ? 'gray' : 'gray';
        }

        //
        if (i % 3 == 0) {
            return theme == 'light' ? '#274237' : `#0e6674`;
        }

        //
        if (i % 2 == 0) {
            return theme == 'light' ? `#5dad8d` : `#1CC4DD`;
        }

        //
        if (i % 1 == 0) {
            return theme == 'light' ? `#407962` : `#17a2b8`;
        }
    }

    return scores ? (
        <>
            <NudgeModal id={`modal-${theme}`} isOpen={!!isOpen} toggle={toggle} className={`${style.modal}`} centered headerText={`Here's the breakdown of the score. Total score: ${user.totalScore__.toFixed(1)}%`}>
                <ModalBody className={style.modalBody}>
                    <p>
                        These attributes are ranked by how strongly the candidate's details match your descriptions
                        relative to each other. The {isMobileWide ? 'longer' : 'higher'} the bar, the better the candidate
                        matches the offer.
                    </p>
                    <div className={style.dotContainerDesktop}>
                        {Object.keys(scores).map((item, index) => (
                            <div className={style.dotWrapper} key={`score-${item}`}>
                                <div
                                    className={style.dot}
                                    style={{
                                        backgroundColor: getColor(index, scores[item]),
                                        opacity: scores[item] > 0 ? 1 : 0.6,
                                        height: scores[item] > 0 ? `${2 * scores[item]}px` : '6px',
                                    }}
                                />
                                <h5 style={{ textAlign: 'center', width: '100%', marginBottom: '0', marginTop: '6px' }}>
                                    {capitaliseFirstLetter(item.replaceAll('_', ' '), 1)}
                                </h5>
                                <h5 style={{ textAlign: 'center', width: '100%' }}>{scores[item].toFixed(0)}%</h5>
                            </div>
                        ))}
                    </div>
                    <div className={style.dotContainerMobile}>
                        {Object.keys(scores).map((item, index) => (
                            <div className={style.dotWrapper}>
                                <h5 style={{ textAlign: 'center', width: '100%' }}>
                                    {capitaliseFirstLetter(item.replaceAll('_', ' '), 1)}
                                </h5>
                                <div
                                    className={style.dot}
                                    style={{
                                        backgroundColor: getColor(index, scores[item]),
                                        height: '36px',
                                        opacity: scores[item] > 0 ? 1 : 0.6,
                                        width: scores[item] > 0 ? `${2.5 * scores[item]}px` : '36px',
                                        minWidth: '36px'
                                    }}
                                >
                                        <h5 style={{ textAlign: 'center', width: '100%', color: 'white' }}>
                                            {scores[item].toFixed(0)}%
                                        </h5>
                                </div>
                            </div>
                        ))}
                    </div>
                    {isOpen && user.feedback && Object.keys(user.feedback)?.length ? <ReportGenerator offerId={offerId} user={user} weights={weights} stored={stored}/> : null}
                </ModalBody>
            </NudgeModal>
        </>
    ) : null;
};

export default ScoreBreakdown;
