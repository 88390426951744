import React from 'react';

import InfoRow from './InfoRow';
import style from './style.module.scss';

const PaymentOneCell = ({ data }) =>
    data ? (
        <div className={style.paymentInfo}>
            <InfoRow title="Date" text={data.date} />
            <InfoRow title="Status" text={data.status} />
            <div className="text-danger">
                <InfoRow title="Error" text={data.message} />
            </div>
        </div>
    ) : (
        <div className="text-muted">No payments yet</div>
    );

export default PaymentOneCell;
