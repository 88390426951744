import React from 'react';
import RecruiterProfile from '../../recruiter/RecruiterDashboard/RecruiterProfile';
import RecruiterDashboard from '../../recruiter/RecruiterDashboard';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { ADMIN } from '../../../consts/userRoles';
import { useParams } from 'react-router-dom';
import { API_DASHBOARD_ADMIN } from '../../../consts/urls';

const AdminRecruiterProfile = () => {
    const { id } = useParams();

    return <RecruiterDashboard id={id} url={API_DASHBOARD_ADMIN} disableEdit />;
};

export default withAuthProtectedRouter(AdminRecruiterProfile, [ADMIN]);
