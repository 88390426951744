import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import Loader from '../../../Loader';
import { patchData } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import { initialValues, validationSchema } from '../../../../components/forms/IndustryExperienceForm/validation';
import EditableProjectHistory from './EditableProjectHistory';
import NonEditableProjectHistory from './NonEditableProjectHistory';
import style from './style.module.scss';
import NudgeCard from '../../../NudgeCard';
import ClickButton from '../../../buttons/NudgeButton';
import { sort_by_key } from '../../GenericCompanyProfile/HelperComponents';
import NudgeTooltip from '../../../NudgeTooltip';

const mapSteps = (steps) =>
    steps.map((el) => {
        const element = { ...el };
        const split = element.value.split("-");
        
        if(split?.length) {
            element.years = split[0];
            element.months = split[1];
        }

        return element;
    });

const ProjectHistory = ({ steps, showEdit, refresh, url, changeKey, theme, reset }) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);

    const onClose = () => setIsOpen(false);
    const toggle = () => setIsOpen(!isOpen);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const props = {
        initialValues: steps.length < 1 ? initialValues : { projects: mapSteps([...steps]) },
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            await updateProjectHistory(values);
            await refresh();

            if (reset) reset();
            setIsSending(false);
            setIsOpen(false);
        },
    };

    const updateProjectHistory = async ({ projects }) => {
        const patchRequest = { Experience: projects };
        await patchData(patchRequest, url, {});
    };

    return (
        <>
            <div className={style.profileHeader}>
                <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`history-header`} label={<b>{"Projects History"}</b>}>
                        <div class="text-center">
                            Tell us about your past projects and employment history
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={toggle}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <Card style={{ padding: '1.25rem' }} id={`card-${theme}`} className="mt-10 mb-10">
                    {isOpen ? (
                        !sending ? (
                            <Formik {...props}>
                                {(formik) => <EditableProjectHistory formik={formik} onClose={onClose} theme={theme} />}
                            </Formik>
                        ) : (
                            <Loader />
                        )
                    ) : steps.length ? (
                        <NonEditableProjectHistory showEdit={showEdit} steps={steps} onEdit={onClickEdit} theme={theme} />
                    ) : (
                        <p style={{ fontStyle: 'italic' }}>No projects have been added yet.</p>
                    )}
            </Card>
        </>
    );
};

ProjectHistory.propTypes = {
    steps: PropTypes.array.isRequired,
    showEdit: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export default ProjectHistory;
