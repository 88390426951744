/* eslint-disable max-len */
import * as Yup from 'yup';

export const FIELDS = {
    EMPLOYEES: 'employees',
    NAME: 'name',
    EMAIL: 'email',
    CSV: 'csv'
};

export const validationSchema = Yup.object({
    [FIELDS.EMPLOYEES]: Yup.array().of(
        Yup.object().shape({
            [FIELDS.NAME]: Yup.string().required('This field is required'),
            [FIELDS.EMAIL]: Yup.string().email("Must be a valid email address").required('This field is required')
        })
    ),
});

export const initialValues = {
    [FIELDS.EMPLOYEES]: [{ name: 'employee', email: 'emailaddress@employee.com' }],
    [FIELDS.CSV]: ''
};
