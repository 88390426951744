import React from 'react';
import { Col, Row, Card, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SearchBox from '../../../../components/SearchBox';

import { DEFAULT_PICTURE, URLS } from '../../../../consts/urls';
import LinkButton from '../../../../components/buttons/LinkButton';
import {
    arrToString,
    capitaliseFirstLetter,
    count_by_key,
    GetPageTheme,
    ProcessSalary,
    ResolveSalary,
} from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import { sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import useMobile from '../../../../hooks/useMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContractorCard from './ContractorCard';
import NudgeCheckbox from '../../../../components/NudgeCheckbox';

const PER_PAGE = 9;
const PER_PAGE_MOBILE = 8;

const OfferList = ({ contractors }) => {
    const [search, setSearch] = React.useState(``);

    const theme = GetPageTheme();
    const { isMobileWide } = useMobile();

    const [flag, setFlag] = React.useState(false);
    const filters = {
        cv: { checked: true, label: 'No CV' },
        noCV: { checked: true, label: 'CV' },
    }
    const [stateFilters, setStateFilters] = React.useState(filters);
    
    contractors = sort_by_key(contractors, 'id')
        .filter((i) => filterOffer(i))
        .reverse();

    const PER_PAGE_CHANGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE;

    const total = Math.ceil(contractors.length / PER_PAGE_CHANGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE_CHANGE);
    const contractorsToDisplay = [...contractors].slice(start, end);
    const emptyOffers = [];

    for (var i = 0; i < PER_PAGE_CHANGE - contractorsToDisplay.length; i++) {
        emptyOffers.push('');
    }

    function findInString(string, search) {
        if (!string) return false;

        return upper(string).includes(search) ? true : false;
    }

    function handleFilterChange(key) {
        var localState = stateFilters;
        
        if(localState[key].checked) {
            localState[key].checked = false;
        }
        else {
            localState[key].checked = true;
        }

        setFlag(!flag);
        setStateFilters(localState);
    }

    function upper(string) {
        return string.toUpperCase();
    }

    function filterOffer(contractor) {
        let upperSearch = upper(search);

        let skills = arrToString(contractor.skills);
        let certificates = arrToString(contractor.certificates.map((item) => item.name));
        let comparison = `${contractor.name}${contractor.job.name}${contractor.company}${contractor.timing}${contractor.job.industry}${skills}${certificates}${contractor.availableFrom}`;

        if(!stateFilters.cv.checked && !stateFilters.noCV.checked) return true;

        if(!!stateFilters.cv.checked && !contractor.cv) {
            if (findInString(comparison, upperSearch)) {
                return true;
            }
        }

        if(!!stateFilters.noCV.checked && contractor.cv) {
            if (findInString(comparison, upperSearch)) {
                return true;
            }
        }

        return false;
    }

    const buffer = isMobileWide ? 1 : 3;

    React.useEffect(() => {
        setPage(0);
    }, [search]);

    return (
        <>
            <SearchBox className={style.search} setValue={setSearch} value={search} />

            <Row className={style.sortRow}>
                <Col md={12}>
                    <h5 id={`card-${theme}`} style={{ margin: 0, padding: '6px' }} class="text-center">Filters</h5>
                </Col>
                <Col md={12}>
                    <div id={`card-sub-${theme}`} style={{ display: isMobileWide ? 'block' : 'flex', justifyContent: 'center', padding: '6px', columnGap: '24px', borderTop: '1px solid rgba(0,0,0,0.2)' }}>
                        <div style={{ display: 'block', padding: isMobileWide ? '12px' : '' }}>
                            <h5 class="text-center">CV Uploaded?</h5>
                            <div class="flex-center-align-justify" style={{ columnGap: '24px' }}>
                                { Object.keys(stateFilters).map((key, index) => 
                                    <NudgeCheckbox label={stateFilters[key].label} checked={stateFilters[key].checked} onClick={() => handleFilterChange(key)}/>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                { contractorsToDisplay.map((contractor) => (
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <ContractorCard contractor={contractor} search={search} page={page} />
                    </Col>
                ))}
                { emptyOffers.map(() => (
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <Card className={style.emptyOffer} />
                    </Col>
                ))}
            </Row>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={buffer}
            />
            <br />
        </>
    );
};

export default OfferList;
