import React from 'react';

import { MATCH_STATUSES } from '../../../consts/matchOfferStatuses';
import { authPostData } from '../../../api/tools';

import ContactButton from './ContactButton';
import InviteButton from './InviteButton';
import LinkButton from './LinkButton';
import StepsButton from './StepsButton';
import MatchButton from './MatchButton';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import NudgeButton from '../../../components/buttons/NudgeButton';

export const getButtonByStatus = (data, contractorId, offerId, mutate, refId, refresh, completeObj) => {
    const recruiter = data?.matchRecruiter;
    const status = data?.status;

    const { isRefreshing, set: setRefresh } = refresh;
    const { get, set } = completeObj;

    async function referExtra(justNotify) {
        setRefresh(true);
        const url = `${API_DASHBOARD_COMPANY}/Offers/${offerId}/Help`;
        const obj = {
            recruiters: [ recruiter.id ],
            extraTalent: justNotify ? -1 : 1,
            contractorId: contractorId
        }

        const response = await authPostData(obj, url);
        await mutate();
        setRefresh(false);

        //refId = 0;
        
        completeObj.set(justNotify ? "Recruiter has been notified of your interest." : "Job has been referred and company has been notified of your interest.");
    }

    switch (status) {
        case MATCH_STATUSES.Applied:
            return <InviteButton id={contractorId} offerId={offerId} refresh={mutate} matchId={data?.matchId} />;
        case MATCH_STATUSES.InterviewScheduled:
        case MATCH_STATUSES.InterviewSubmitted:
        case MATCH_STATUSES.FaceInterviewScheduled:
        case MATCH_STATUSES.VRAssessmentScheduled:
        case MATCH_STATUSES.VRAssessmentSubmitted:
        case MATCH_STATUSES.WaitingOnFaceSchedule:
        case MATCH_STATUSES.WaitingOnVRSchedule:
        case MATCH_STATUSES.CompanyPending:
        case MATCH_STATUSES.CompanyAccepted:
        case MATCH_STATUSES.CompanyRejected:
        case MATCH_STATUSES.WilloInvited:
            return <LinkButton matchId={data?.matchId} />;
        case MATCH_STATUSES.Pending:
            return <StepsButton />;
        case MATCH_STATUSES.NoMatch:
            if(refId) {
                return ( !recruiter?.isReferred ? <div style={{ width: '100%', padding: '12px'}} className="text-center">
                    { !completeObj.get ? <>
                        <h5>User belongs to a recruiter. Click the button below to refer the job and notify {recruiter?.name} that you are interested in interviewing this candidate.</h5>
                        <NudgeButton color="success" disabled={isRefreshing} onClick={() => referExtra(false)}>Refer Offer and Notify</NudgeButton>
                    </> : <h5>{completeObj.get}</h5> }
                </div> : <div style={{ width: '100%', padding: '12px'}} className="text-center">
                    { !completeObj.get ? <>
                        <h5>User belongs to a recruiter. Click the button below to notify {recruiter?.name} that you are interested in interviewing this candidate.</h5>
                        <NudgeButton color="success" disabled={isRefreshing} onClick={() => referExtra(true)}>Notify</NudgeButton>
                    </> : <h5>{completeObj.get}</h5> }
                </div> )
            } else {
                return <div style={{ width: '100%'}}><MatchButton offerId={offerId} contractorId={contractorId} refresh={mutate} /></div>
            }
        default:
            return <div style={{ width: '100%'}}><ContactButton /></div>;
    }
};
