import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

export const useLoader = () => {
    const [isLoading, setIsLoading] = useState(false);
    return { isLoading, setIsLoading };
};

const loadingText = ['Loading', 'Buffering', 'Nudging', 'Working'];

const Loader = ({ message = '', onlyIcon, fullPage }) => {
    const [text, setText] = useState('');

    useEffect(() => {
        const sample = _.sample(loadingText)
        setText(sample);
        if (message) {
            setText(message);
        }
    }, [message]);

    const theme = GetPageTheme();
    return <div class="flexy" style={{ height: fullPage ? '100vh' : '100%', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        { !onlyIcon ? <div id={`card-${theme}`} className={style.loaderContainer}>
            <FontAwesomeIcon className={style.loader} icon={['far', 'sync-alt']} />
            <h4 id="label" className={style.loaderTitle}>
                {text}
            </h4>
        </div> : <FontAwesomeIcon className={style.loader} icon={['far', 'sync-alt']} />}
    </div>;
};

export default Loader;
