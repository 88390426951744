import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import LinkButton from '../../../components/buttons/LinkButton';
import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import ContractorPageTemplate from '../../../components/ContractorPageTemplate';
import GenericTable from '../../../components/GenericTable';
import { sortByDate } from '../../../components/GenericTable/helpers';
import Loader from '../../../components/Loader';
import SmallHeader from '../../../components/SmallHeader';
import { renderPaidCell } from '../../../components/TimesheetsTableHelpers';
import { API_GATEWAY, URLS } from '../../../consts/urls';

const columnSizeByIndex = [1, 2, 2, 3, 2, 2];

const Timesheets = () => {
    const { id } = useParams();
    const { data } = useSWR(`${API_GATEWAY}/InvoiceContractor/Contracts/${id}`, authFetcher, NO_REFRESH_INTERVAL);

    if (data) {
        data.timesheets.forEach((item) => {
            if (!item.rejectReason) {
                item.rejectReason = 'n/a';
            }
        });
        if (data.status == 'Close') {
            data.status = 'Closed';
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Start date',
                accessor: 'dateFrom',
                sortType: sortByDate('dateFrom'),
            },
            {
                Header: 'Finish date',
                accessor: 'dateTo',
                sortType: sortByDate('dateTo'),
            },
            {
                Header: 'Salary (GBP)',
                accessor: 'amount',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Reject Reason',
                accessor: 'rejectReason',
            },
            {
                Header: 'Paid',
                accessor: 'isPaid',
                Cell: renderPaidCell,
            },
        ],
        [],
    );

    return (
        <ContractorPageTemplate>
            {data ? (
                <Card className="mt-20">
                    <CardBody style={{ width: '100%' }}>
                        <SmallHeader>
                            <Row>
                                <Col xs="6" md="3">
                                    Contract Title: <h2>{data.title}</h2>
                                </Col>
                                <Col xs="6" md="3">
                                    Company: <h2>{data.companyName}</h2>
                                </Col>
                                {data.status !== 'Closed' ? (
                                    <Col xs="12" md="6">
                                        <LinkButton link={`${URLS.ADD_TIMESHEET}/${data.contractId}`}>
                                            Add New Timesheet
                                        </LinkButton>
                                    </Col>
                                ) : null}
                            </Row>
                        </SmallHeader>
                        <GenericTable columns={columns} data={data.timesheets} columnSizeByIndex={columnSizeByIndex} />
                    </CardBody>
                </Card>
            ) : (
                <Loader />
            )}
        </ContractorPageTemplate>
    );
};

export default Timesheets;
