import React from 'react';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import { Card } from 'reactstrap';
import UploadModal from '../../../../components/ContractorInformation/ContractorInformationEdit/UploadModal';
import { API_PROFILE, DEFAULT_PICTURE } from '../../../../consts/urls';
import { authPostData, authSendFile } from '../../../../api/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NudgeButton from '../../../../components/buttons/NudgeButton';

const ProfileCard = ({ user, mutate }) => {

    const [sending, setSending] = React.useState(false);
    const [photo, setPhoto] = React.useState(user?.photo || DEFAULT_PICTURE);
    const [photoBuffer, setPhotoBuffer] = React.useState();

    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    function togglePhotoModal() {
        setPhotoModalOpen(!photoModalOpen);
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    async function submitPhoto() {
        setSending(true);
        const response = await authSendFile(photoBuffer, `${API_PROFILE}/Photo`);
        await mutate();
        
        setPhotoBuffer(null);
        setSending(false);
    }

    React.useEffect(() => {
        if(photoBuffer) {
            setPhoto(getPhotoField(photoBuffer));
            togglePhotoModal();
        }
    }, [photoBuffer])

    const theme = GetPageTheme();
    return (
        user ? <Card id={`card-${theme}`} className={theme == 'dark' ? style.profileCardDark : style.profileCard}>
            <>
                <UploadModal
                    toggle={togglePhotoModal}
                    isOpen={photoModalOpen}
                    setPhoto={setPhotoBuffer}
                    avatar={``}
                />
                <div
                    className={style.photoUpload}
                    style={{ backgroundImage: `url(${photo})`, objectFit: 'contain'  }}
                    onClick={togglePhotoModal}
                >
                    <h5>
                        <FontAwesomeIcon icon={['fas', photo != DEFAULT_PICTURE ? 'edit' :'plus-circle']} />
                    </h5>
                </div>

                <h5>{user.name}</h5>
                { photoBuffer ? <NudgeButton color="success" onClick={submitPhoto} disabled={sending}>
                    { sending ? "Uploading" : "Upload" }
                </NudgeButton> : null }

            </> 
        </Card> : <Loader/>
    );
}

export default ProfileCard;