import React from 'react';

import { Field } from 'formik';
import AppInput from '../../../../../../components/AppInput';
import { Col, Row, FormGroup, Label, Button } from 'reactstrap';
import { FIELDS } from './validate';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';

const EditForm = ({ formik, toggle }) => {
    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup className="mb-20">
                        <Label for={FIELDS.NAME}>Name</Label>
                        <AppInput id={FIELDS.NAME} formik={formik} label="Name" />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup className="mb-20">
                        <Label for={FIELDS.OWNER_NAME}>Owner</Label>
                        <Field
                            formik={formik}
                            component={AppInput}
                            name={`${FIELDS.OWNER_NAME}.full_name`}
                            label={'Owner'}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <NudgeButton type="button" color="danger" onClick={toggle}>
                Cancel
            </NudgeButton>
        </>
    );
};

export default EditForm;
