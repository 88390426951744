import React from 'react';
import { Row, Col } from 'reactstrap';

import ClickButton from '../../buttons/NudgeButton';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

import { Field } from 'formik';
import AutocompleteInput from '../../AutocompleteInput';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import { convertNumber } from './helpers';
import { NEVER_REFRESH, authFetcher } from '../../../api/tools';
import useSWR from 'swr';

import NudgeCheckbox from '../../NudgeCheckbox';

import style from './style.module.scss';

const NewInputForm = ({
    formik,
    addValue,
    hasRequired,
    field,
    subField,
    fieldLimit = 25,
    buttonSize = { xs: 12, sm: 3, md: 3 },
    removeText = '-',
    autocompleteUrl = ``,
    buttons
}) => {
    const v = formik.values;
    const MAX = fieldLimit;

    function addSkill() {
        var arr = v[field];
        arr.push(addValue);

        formik.setFieldValue(field, arr);
        formik.validateForm();
    }

    function removeSkill(index) {
        var arr = v[field];
        arr.splice(index, 1);

        formik.setFieldValue(field, arr);
        formik.validateForm();  
    }

    function getFormikError(field, index, subfield) {

        try {
            if(subfield) {
                return formik.errors[field][index][subfield];
            } else {
                return formik.errors[field][index];
            }
        } catch (e) {
            //return `${field}.${index}.${subfield}`;
        }
    }

    React.useEffect(() => {
        try {
            var arr = v[field];
            arr = arr.filter((i) => i != '');
            formik.setFieldValue(field, arr);
        } catch (e) {}
    }, []);

    function doFullNumber(n) {
        return convertNumber(n).toUpperCase();
    }

    const { data: options } = useSWR(`${autocompleteUrl}`, authFetcher, NEVER_REFRESH);
    const [suggestions, setSuggestions] = React.useState([]);

    React.useEffect(() => {
        if (options?.length) {
            setSuggestions([...new Set(options.map((i) => i.name || i))]);
        }
    }, [options]);

    return (
        <div className={style.formContainer}>
            <Row className={style.formRowTop}>
                <Col xs={12} md={6} className={style.formHeader}>
                    <h5>Limit: {`${v[field].length}/${MAX}`}</h5>
                    { !v[field].length && <i>Nothing added yet</i> }
                </Col>
                <Col xs={12} md={6}>
                    <NudgeButtonGroup
                        buttons={[
                            { label: '+', disabled: v[field]?.length >= MAX, onClick: () => addSkill() },
                            { label: 'Clear All', color: 'danger', available: !!v[field]?.length, onClick: () => formik.setFieldValue(field, []) }
                        ]}
                    />
                </Col>
            </Row>

            <div className={style.formRowBottom}>
                {v[field].length ? (
                    v[field].map((_, index) => (
                        <Row className={style.form} style={{ borderTop: index !== 0 ? '1px solid rgba(0,0,0,0.1)' : '' }}>
                            <Col xs={12} sm={hasRequired ? 6 : 9} md={hasRequired ? 6 : 9}>
                                <Field
                                    formik={formik}
                                    name={subField ? `${field}.${index}.${subField}` : `${field}.${index}`}
                                    label={`${field}`}
                                    placeholder={`FIELD ${doFullNumber(index + 1)}`}
                                    component={AutocompleteInput}
                                    suggestions={suggestions}
                                    invalid={!!getFormikError(field, index, subField)}
                                />
                            </Col>

                            { hasRequired ? (
                                <Col xs={12} sm={3} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '38px' }}>
                                    <NudgeCheckbox checked={formik.values[field][index].required} onClick={() => formik.setFieldValue(`${field}.${index}.required`, !formik.values[field][index].required)}/>
                                </Col>
                            ) : null }

                            <Col {...buttonSize}>
                                <ClickButton
                                    className={style.removeButton}
                                    color="danger"
                                    onClick={() => removeSkill(index)}
                                    tabindex="-1"
                                >
                                    {removeText}
                                </ClickButton>
                            </Col>

                            { !!getFormikError(field, index, subField) && formik.submitCount > 0 ? (
                                <Col xs={12} md={12}>
                                    <p className={GetPageTheme() == 'light' ? style.errorText : style.errorTextDark}>{ getFormikError(field, index, subField) }</p>
                                </Col>
                            ) : null}
                        </Row>
                    ))
                ) : null }
                <Row className={style.form}>
                    <Col>
                        { !!buttons && buttons }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default NewInputForm;
