import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../buttons/NudgeButton';

const SearchBox = ({ value, setValue, onEnter, onChange, placeholder = 'Search...', className = ``, disabled }) => {

    const theme = GetPageTheme();

    function changeFunction(v) {
        setValue(v);
        if (onChange) {
            onChange();
        }
    }

    function onKeyUpValue(event) {
        if (event.key == 'Enter') {
            if (!onEnter && setValue) {
                setValue(event.target.value);
            } else {
                onEnter();
            }
        }
    }

    return (
        <div id={`search-box-${theme}`} className={`${style.wrapper}  ${className}`}>
            <InputGroup>
                <Input
                    type="search"
                    disabled={disabled}
                    className={style.input}
                    placeholder={placeholder}
                    value={value || ''}
                    onChange={(e) => {
                        changeFunction(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        onKeyUpValue(e);
                    }}
                    isClearable={true}
                />
            </InputGroup>
        </div>
    );
};

SearchBox.propTypes = {
    setValue: PropTypes.func.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default SearchBox;
