import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import Col from 'reactstrap/lib/Col';

import AppInput from '../../../components/AppInput';
import { FIELDS } from './validation';
import ClickButton from '../../../components/buttons/NudgeButton';

export const NewPasswordRequiredForm = ({ formik, message }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} style={{ padding: '18px' }}>
        <FormGroup row>
            <Col>
                <AppInput id={FIELDS.OLD_PASSWORD} type="password" formik={formik} label="Old password" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col>
                <AppInput id={FIELDS.NEW_PASSWORD} type="password" formik={formik} label="New password" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col>
                <AppInput id={FIELDS.NEW_PASSWORD_REPEAT} type="password" formik={formik} label="Repeat New Password" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col>
                <ClickButton color="success" onClick={formik.handleSubmit} className="w-100 mt-20">
                    Confirm
                </ClickButton>
            </Col>
        </FormGroup>
        <h5 style={{ color: 'red', textAlign: 'center', fontSize: '1.2rem' }}>{message}</h5>
    </Form>
);

NewPasswordRequiredForm.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default NewPasswordRequiredForm;
