import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { MATCH_STATUS } from '../../../../consts/candidateStatuses';
import { MATCH_STATUSES_DICTIONARY } from '../../../../consts/matchOfferStatuses';

export const getStatusDescription = (status) => {
    return MATCH_STATUSES_DICTIONARY[status];
};

const CandidateSelect = ({ selectedCandidates, id, onChangeCheckbox }) => {
    return (
        <FormGroup check style={{ marginBottom: '16px' }}>
            <Input
                type="checkbox"
                checked={selectedCandidates.includes(id)}
                onChange={() => onChangeCheckbox(id)}
                disabled={false}
            />
        </FormGroup>
    );
};

export default CandidateSelect;
