/* eslint-disable max-len */
import * as Yup from 'yup';

export const FIELDS = {
    PHOTO: 'photo',
    NAME: 'name',
    BIO: 'bio',
    VISION: 'visionStatement',
    VIDEO_LINK: 'videoLink',
    USER_ID: 'userId',
    BACK_COLOUR: 'backColour',
    DELETE_PHOTO: 'deletePhoto',

    //VIDEO INTERVIEW BRANDING
    
};

export const WORD_LIMIT = 1000;
export const VIDEO_REG_EX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required'),
    [FIELDS.BIO]: Yup.string().min(1, 'What?').max(WORD_LIMIT, 'Bio cannot be larger than 1000 characters.'),
    [FIELDS.VISION]: Yup.string().max(128, 'Vision statement cannot be larger than 128 characters.').nullable(),
    [FIELDS.VIDEO_LINK]: Yup.string().matches(VIDEO_REG_EX, 'Must be a valid YouTube link.').nullable(),
    [FIELDS.BACK_COLOUR]: Yup.string().required('This field is required'),
});

export const initialValues = {
    [FIELDS.PHOTO]: '',
    [FIELDS.NAME]: '',
    [FIELDS.BIO]: '',
    [FIELDS.VISION]: '',
    [FIELDS.VIDEO_LINK]: '',
    [FIELDS.BACK_COLOUR]: '',
    [FIELDS.DELETE_PHOTO]: false
};
