import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoWindow, Marker } from '@react-google-maps/api';
import moment from 'moment';
import React, { Component } from 'react';

import ClickButton from '../../components/buttons/NudgeButton';
import style from './style.module.scss';

const OPERATOR_PROFILES = {
    'RWE Renewables UK Limited': '5425a366-83dd-4db0-a24e-adc026978e42',
    'Orsted (formerly Dong Energy)': 'b21d4207-e91b-4fec-8870-e0bd2da37784',
    'Orsted (formerly Dong Energy)/ Macquarie Group': 'b21d4207-e91b-4fec-8870-e0bd2da37784',
    'Wave Hub': '7741901e-8861-4440-8ba1-d4317bc170be',
    'Scottish Power Renewables': 'f835c68a-1a25-4daf-b058-8ba9f036d466',
    Cierco: 'aa92d9a8-c988-4bd2-b748-a08677e415de',
    'Kincardine Offshore Wind': '15192d94-b39b-46ed-8878-6de7ccabef2d',
    Vattenfall: 'c084c2d1-1e02-4c18-975b-e89e205b7f38',
    'EDF Energy - Northern Offshore Windfarm Ltd': 'c74dfdc5-ccf3-4299-ae03-bd7c84c0f5f9',
    'SSE / Seagreen Wind Energy Ltd': 'd81ecf43-6c53-4d70-9864-0ec3d1ad7483',
    EDF: 'c74dfdc5-ccf3-4299-ae03-bd7c84c0f5f9',
};

export class LocationMarker extends Component {
    static displayName = LocationMarker.name;

    constructor(props) {
        super(props);
        this.state = {
            mapMarker: null,
            isOpen: false,
        };
    }

    onToggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    onLoad(mapMarker) {
        this.setState({
            mapMarker: mapMarker,
        });
    }

    render() {
        return (
            <Marker
                clusterer={this.props.clusterer}
                position={this.props.location.coords}
                onLoad={this.onLoad.bind(this)}
                onClick={this.onToggleOpen.bind(this)}
            >
                {this.state.isOpen && (
                    <InfoWindow
                        anchor={this.state.mapMarker}
                        position={this.props.location.coords}
                        onClose={this.onToggleOpen.bind(this)}
                    >
                        <div>
                            <h2 className={style.forest}>{this.props.location.name}</h2>
                            <p className={style.forest}>
                                <strong>Operator</strong>: {this.props.location.owner}
                                <br />
                                <strong>Energy Type</strong>: {this.props.location.type}
                                <br />
                                <strong>Status</strong>: {this.props.location.status}
                                <br />
                                <strong>Last updated</strong>: {this.props.location.last_updated} (
                                {moment(this.props.location.last_updated, 'DD/MM/YYYY').fromNow()})
                            </p>

                            {OPERATOR_PROFILES[this.props.location.owner] && (
                                <p>
                                    <ClickButton
                                        color="success"
                                        onClick={() =>
                                            window.open(
                                                '/companies/' + OPERATOR_PROFILES[this.props.location.owner],
                                                '_blank',
                                                'noreferrer',
                                            )
                                        }
                                    >
                                        Check available positions
                                    </ClickButton>
                                </p>
                            )}
                            <p>
                                <ClickButton
                                    color="success"
                                    onClick={() =>
                                        window.open(
                                            'https://www.google.com/search?q=' +
                                                encodeURIComponent(
                                                    this.props.location.owner + ' ' + this.props.location.name,
                                                ),
                                            '_blank',
                                            'noreferrer',
                                        )
                                    }
                                >
                                    Search project{' '}
                                    <FontAwesomeIcon icon={['fa', 'external-link']} style={{ marginLeft: '4px' }} />
                                </ClickButton>
                            </p>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}
