import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import Loader from '../../../components/Loader';
import { ContractorProfilePage } from '../../../components/pages/ContractorProfilePage';
import { useDismissModal } from '../../../components/pages/OfferGenericPage/DismissModal';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import DismissCandidateModal from './DismissCandidateModal';
import { FIELDS, OTHER_LABEL } from './DismissCandidateModal/DismissModalForm/validation';
import { getButtonByStatus } from './helper';
import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';
import RejectButton from '../../../components/buttons/RejectButton';

const Buttons = () => {
    const { offerId, id } = useParams();
    const { isOpen, toggle, showRejectTooltip, onSubmitReject } = useDismissModal();
    const navigate = useNavigate();
    const { data, mutate, isValidating } = useSWR(
        `${API_DASHBOARD_COMPANY}/Contractors/${id}/MatchStatus/${offerId || 0}`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    const { data: contractor } = useSWR(
        `${API_DASHBOARD_COMPANY}/Contractors/${id}`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    const canDismiss = ['Accepted', 'CompanyAccepted'];

    const onSubmit = async (values) => {
        const data = { offerId };
        await onSubmitReject(`${API_DASHBOARD_COMPANY}/Contractors/${id}/Reject`, FIELDS, OTHER_LABEL, values, data);
        toggle();
        navigate(-1);
    };
    
    const [refreshing, setRefreshing] = React.useState(false);
    const refresh = {
        isRefreshing: refreshing,
        set: setRefreshing
    }

    const [completeMessage, setCompleteMessage] = React.useState(false);
    const completeObj = {
        get: completeMessage,
        set: setCompleteMessage
    }

    return data ? (
        <>
            <DismissCandidateModal
                isOpen={isOpen}
                toggle={toggle}
                onSubmit={onSubmit}
                showRejectTooltip={showRejectTooltip}
            />

            <div className={style.buttons}>
                { offerId ? getButtonByStatus(data, id, offerId, mutate, contractor?.refId, refresh, completeObj) : null }
                {canDismiss.includes(data.status) ? (
                    <ClickButton size="lg" color="danger" className={style.dismiss} onClick={toggle}>
                        Dismiss
                    </ClickButton>
                    // <RejectButton
                    //     offerId={offerId}
                    //     company
                    //     extraData={{
                    //         companyName: user.subname,
                    //         feedback: data?.internalFeedback,
                    //         colour: user?.backColour,
                    //         offerName: data.offerName,
                    //         contractorName: contractor?.name, 
                    //         smartMatch: data.smartMatch
                    //     }}
                    // />
                ) : null}
            </div>
        </>
    ) : (
        <Loader />
    );
};

const CandidateProfile = () => (
    <ContractorProfilePage showEdit={false} showResume={true} additions={<Buttons />} showBackArrow />
);

export default CandidateProfile;
