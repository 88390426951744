import en from 'date-fns/locale/pl';
import format from 'date-format';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Card, Col, FormGroup } from 'reactstrap';

import { getDate, INPUT_DATE_FORMAT } from '../../../tools/dateHelpers';
import AppInput from '../../AppInput';
import { FIELDS } from './validation';
import style from './style.module.scss';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import moment from 'moment';

registerLocale('en', en);

export const AvailabilityForm = ({ formik, showHours = 'true', showLabel = true }) => {
    const [startDate, setStartDate] = useState(new Date());
    //eslint-disable-next-line
    const [endDate, setEndDate] = useState(null);
    //eslint-disable-next-line
    const [hours, setHours] = useState(null);

    useEffect(() => {
        const start = getDate(formik.values[FIELDS.START]);
        const end = getDate(formik.values[FIELDS.FINISH]);

        setStartDate(start);
        setEndDate(end);
        /* eslint-disable */
    }, []);
    /* eslint-enable */

    const onChange = (field, date) => {
        const value = format.asString(INPUT_DATE_FORMAT, date);
        formik.setFieldValue(field, value);
    };

    const handleSelect = (dates) => {
        const start = dates;
        const end = dates;

        const newDate = moment(dates).format('yyyy-MM-DD');

        onChange(FIELDS.START, start);
        onChange(FIELDS.FINISH, end);

        setStartDate(start);
        setEndDate(end);
    };

    const updateStartField = (date) => {
        const value = getDate(date.target.value);
        
        setStartDate(value);
    };

    const updateHoursField = (hours) => {
        setHours(hours.target.value);
    };

    const theme = GetPageTheme();

    return (
        <>
            <FormGroup row className="mb-10">
                <Col md={{ size: 12 }}>
                    {showLabel ? (
                        <div className={style.infoLabel}>Earliest Available Start Date (DD/MM/YYYY)</div>
                    ) : null}
                    <AppInput
                        id={FIELDS.START}
                        value={moment(startDate).format('yyyy-MM-DD')}
                        formik={formik}
                        label={showLabel ? 'Start Date' : 'Paid From'}
                        onChange={updateStartField}
                        autoComplete="off"
                        disabled
                        type="date"
                    />
                </Col>
            </FormGroup>

            <Card id={`card-sub-${theme}`} style={{ padding: '12px' }}>
                <div id={`modal-${GetPageTheme()}`} className="text-center big-calendar">
                    <DatePicker id={`calendar-pick`} selected={startDate} onChange={handleSelect} locale="en" inline />
                </div>
            </Card>

            {showHours === 'true' && (
                <FormGroup row className="mb-20">
                    <Col md={{ size: 12 }}>
                        <div className={style.infoLabel}>Available hours per week</div>
                        <AppInput
                            id={FIELDS.HOURS}
                            formik={formik}
                            label="Available hours per week"
                            onChange={updateHoursField}
                            autoComplete="off"
                            type="number"
                        />
                    </Col>
                </FormGroup>
            )}
        </>
    );
};

AvailabilityForm.propTypes = {
    formik: PropTypes.object.isRequired,
    skipCurrentStep: PropTypes.func,
    showSubmit: PropTypes.bool,
};

export default AvailabilityForm;
