import React from 'react';
import { Formik, Field } from 'formik';
import { initialValues, validationSchema, FIELDS } from './validate';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';

import AppInput from '../../../../components/AppInput';

import UploadPhotoField from '../../../../components/DragAndDrop/UploadPhotoField/UploadPhotoField';
import style from './style.module.scss';
import NewInputForm from '../../../../components/forms/NewInputForm';
import { authSendFile, patchData } from '../../../../api/tools';
import { API_DASHBOARD_RECRUITER, API_PROFILE, DEFAULT_PICTURE } from '../../../../consts/urls';
import Loader from '../../../../components/Loader';
import { shouldSendPhotoUpdate } from '../../../../components/ContractorInformation/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadModal from '../../../../components/ContractorInformation/ContractorInformationEdit/UploadModal';
import ClickButton from '../../../../components/buttons/NudgeButton';
import { GetPageTheme, generateMonoLinearGradient } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ColourInput from '../../../../components/ColourInput';

import CheckboxField from '../../../../components/CheckboxField';
import NudgeButtonGroup from '../../../../components/buttons/ButtonGroup';

const RecruiterProfileForm = ({ user, refresh, formik, loading, setLoading, toggle }) => {
    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

    const [photo, setPhoto] = React.useState(formik.values[FIELDS.PHOTO]);
    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[FIELDS.PHOTO]));
    }, [formik.values[FIELDS.PHOTO]]);

    const [canDelete, setCanDelete] = React.useState(false);
    React.useEffect(() => {
        if(formik.values[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            setCanDelete(true);
        }
    }, [])

    function setPhotoField(photo) {
        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(FIELDS.PHOTO, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    function getProfileIcon(v) {
        if(v[FIELDS.CONFIRM]) {
            return 'ban'
        }

        if(canDelete || v[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            return 'pencil'
        } else {
            return 'plus-circle'
        }
    }
    
    const theme = GetPageTheme();
    return (
        <>
            {!loading ? (
                <div className={style.cardBackground} style={{ background: generateMonoLinearGradient(`${formik.values[FIELDS.BACK_COLOUR] || "#407962"}`), borderBottom: `2px solid ${formik.values[FIELDS.BACK_COLOUR] || "#407962"}` }}>
                    <FormGroup className={style.photoUploadContainer}>
                        <div className={style.avatar}>
                            <div
                                className={style.photoUpload}
                                style={{ backgroundImage: `url(${photo})`, objectFit: 'contain', borderColor: `${formik.values[FIELDS.BACK_COLOUR] || "#407962"}` }}
                                onClick={togglePhotoModal}
                            >
                                <h5>
                                    <FontAwesomeIcon icon={['fas', getProfileIcon(formik.values)]} />
                                </h5>
                                <UploadModal
                                    isOpen={photoModalOpen}
                                    toggle={togglePhotoModal}
                                    avatar={``}
                                    setPhoto={setPhotoField}
                                />
                            </div>
                            
                            { canDelete ? <div style={{ display: 'flex', fontSize: '14px', columnGap: '6px', justifyContent: 'center', marginTop: '12px'}}>
                                <Field component={CheckboxField} formik={formik} name={FIELDS.CONFIRM} label={"Delete Photo"} bool />
                            </div> : null }
                        </div>
                    </FormGroup>

                    <div className={style.flexContainer}>
                        <NudgeButtonGroup
                            buttons={[
                                { label: 'Cancel', color: 'danger', onClick: toggle },
                                { label: 'Save', type: 'submit', disabled: !formik?.dirty }
                            ]}
                            button
                        />
                    </div>
                </div>
            ) : null}

            <div className={style.formContainer}>
                <FormGroup className="mb-20">
                    <Label>
                        <b>Name</b>
                    </Label>
                    <AppInput id={FIELDS.NAME} formik={formik} label="Your Name" />
                </FormGroup>

                <FormGroup className="mb-20">
                    <Row>
                        <Col md={6}>
                            <Label>
                                <b>Company Name</b>
                            </Label>
                            <AppInput id={FIELDS.COMPANY} formik={formik} label="Company Name" />
                        </Col>
                        <Col md={6}>
                            <Label>
                                <b>Location</b>
                            </Label>
                            <AppInput id={FIELDS.LOCATION} formik={formik} label="Location" />
                        </Col>
                    </Row>
                </FormGroup>
                
                <FormGroup>
                    <Label for={FIELDS.BACK_COLOUR}><b>Banner Colour</b></Label>
                    <ColourInput id={FIELDS.BACK_COLOUR} formik={formik}/>
                </FormGroup>

                <FormGroup className="mb-20">
                    <Label>
                        <b>Personal Description</b>
                    </Label>
                    <AppInput
                        id={FIELDS.BIO}
                        className={style.bio}
                        formik={formik}
                        label="Tell us about yourself..."
                        type="textarea"
                        charLimit={500}
                    />
                </FormGroup>

                <FormGroup className="mb-20">
                    <Label>
                        <b>Year Founded</b>
                    </Label>
                    <AppInput id={FIELDS.YEAR_FOUNDED} formik={formik} label="Year Founded" />
                </FormGroup>

                <FormGroup className="mb-20">
                    <Row>
                        <Col xs={12} md={12}>
                            <Label>
                                <b>Sectors Covered</b>
                            </Label>
                            <NewInputForm
                                formik={formik}
                                field={FIELDS.SECTORS}
                                subField={'name'}
                                fieldLimit={8}
                                removeText="-"
                            />
                        </Col>
                        <Col xs={12} md={12}>
                            <Label>
                                <b>Specialisations</b>
                            </Label>
                            <NewInputForm
                                formik={formik}
                                field={FIELDS.SPECIALISM}
                                subField={'name'}
                                fieldLimit={8}
                                removeText="-"
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        </>
    );
};

const RecruiterProfile = ({ user, toggle, refresh }) => {
    const props = {
        initialValues: {
            ...initialValues,
            [FIELDS.NAME]: user.name,
            [FIELDS.BIO]: user.bio,
            [FIELDS.COMPANY]: user.company,
            [FIELDS.LOCATION]: user.location,
            [FIELDS.PHOTO]: user.photo,

            [FIELDS.YEAR_FOUNDED]: user.yearFounded,
            [FIELDS.SECTORS]: user.sectors || [],
            [FIELDS.SPECIALISM]: user.specialism || [],
            [FIELDS.BACK_COLOUR]: `#${user.backColour}`
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);

            if(values[FIELDS.BACK_COLOUR]) {
                values[FIELDS.BACK_COLOUR] = values[FIELDS.BACK_COLOUR].substring(1, values[FIELDS.BACK_COLOUR].length)
            }

            try {
                var response = await patchData(values, `${API_DASHBOARD_RECRUITER}`);
                await authSendFile(values.photo, `${API_PROFILE}/Photo`);
            } catch (e) {
                console.log(e.message);
            } finally {

                await refresh();

                setLoading(false);
                
                toggle();
            }

            if(!!values[FIELDS.CONFIRM]) {
                try {
                    await patchData({}, `${API_PROFILE}/Photo`, { uid: user.id });
                } catch (e) {
                    setLoading(false);
                }
            }
        },
    };

    const [loading, setLoading] = React.useState(false);

    return (
        <Formik {...props}>
            {(formik) => (
                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} className={style.form}>
                    {!loading ? (
                        <div>
                            <RecruiterProfileForm user={user} refresh={refresh} formik={formik} toggle={toggle} loading={loading} setLoading={setLoading} />
                        </div>
                    ) : (
                        <Loader message={'Updating profile'} />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default RecruiterProfile;
