import * as Yup from 'yup';

export const FIELDS = {
    PHOTO: 'photo',
};

export const validationSchema = Yup.object({
    [FIELDS.PHOTO]: Yup.mixed().required('This field is required'),
});

export const initialValues = {
    [FIELDS.PHOTO]: '',
};
