import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import DefaultAvatar from '../../../../assets/images/avatar.png';
import EditButton from '../../../../components/buttons/EditButton';
import CompanyVideo from '../../FullCompanyProfile/PageComponents/CompanyVideo';
import style from './style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';
import { FullStatCardScaling, GetPageTheme, generateMonoLinearGradient } from '../../GenericCompanyProfile/HelperComponents';
import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import NudgeButton from '../../../buttons/NudgeButton';
import { authPostData } from '../../../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../../../consts/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMobile from '../../../../hooks/useMobile';

export const getUserPhoto = (user) => user.photo || user.logo || DefaultAvatar;

const NonEditable = ({ user, onClickEdit, showEdit, colour, mutate }) => {
    const { isMobileWide } = useMobile();
    const { user: profile, userRoles } = React.useContext(AuthContext);
    const [showVideo, setShowVideo] = useState(false);
    const toggle = () => {
        setShowVideo(!showVideo);
    };

    const [sendFollow, setSendFollow] = React.useState(false);
    async function followCompany() {
        setSendFollow(true);
        var response = await authPostData({}, `${API_DASHBOARD_CONTRACTOR}/Companies/${user.id}/Follow`);

        await mutate();
        setSendFollow(false);
    }

    const theme = GetPageTheme();
    return (
        <>
            { showEdit && !isMobileWide ? <EditButton onEdit={onClickEdit} show={showEdit} /> : null}
            
            
            <div
                style={{ backgroundImage: `url(${user.logo || DefaultAvatar})`, borderColor: `#${user.backColour || "407962"}` }}
                className={style.avatarNonEdit}
            >
                {showEdit ? (
                    <div style={{ background: `#${user.backColour ? user.backColour : theme == 'light' ? '407962' : '17a2b8'}` }} onClick={onClickEdit} className={style.editInPicture}>
                        <FontAwesomeIcon className={style.iconInPicture} icon={['fas', 'edit']} />
                    </div>
                ) : null}
            </div>

            <div className={style.name}>{user.name}</div>
            <div style={{ background: generateMonoLinearGradient(`${colour}` || '#407962'), borderBottom: `2px solid ${`${colour}` || '#407962'}`}} className={style.banner}></div>

            { userRoles.includes("User") && profile?.parentId == "" ? <>
                { !user.isFollowing ? 
                    <NudgeButton disabled={sendFollow} className={style.followButton} onClick={followCompany} color="success">Follow</NudgeButton>
                        : 
                    <div className={style.following}><h5>Following</h5><FontAwesomeIcon icon={['far', 'check-circle']}/></div>
                }
            </> : null }

            <div>
                {user.bio != ' ' ? (
                    <>
                        <FullStatCardScaling
                            data={[
                                { label: `About Us`, data: <p className={style.bio}>{`${user.bio}`}</p> }
                            ]}
                        />
                    </>
                ) : null}
            </div>

            <div>
                {user.visionStatement && user.visionStatement != ' ' ? (
                    <>
                        <FullStatCardScaling
                            data={[
                                { label: `Vision Statement`, data: `"${user.visionStatement || 'No statement given.'}"` }
                            ]}
                        />
                    </>
                ) : null}
            </div>

            {user.videoLink && user.videoLink != ' ' ? (
                <div>
                    <h5 className={style.header}>Personal Video:</h5>
                    <ClickButton className={style.videoButton} onClick={() => toggle()} color="success">
                        Show Video
                    </ClickButton>
                    <CompanyVideo videoLink={user.videoLink} isOpen={showVideo} toggle={toggle} />
                </div>
            ) : null}
        </>
    );
};

NonEditable.propTypes = {
    user: PropTypes.object.isRequired,
    showEdit: PropTypes.bool,
    onClickEdit: PropTypes.func.isRequired,
};

export default NonEditable;
