import React from 'react';
import style from './style.module.scss';

const NonEditableEducation = ({ qualification }) => {
    const qualArray = qualification?.split('/');

    if (!qualArray) {
        return <></>;
    }

    return (
        <>
            <div className={style.textsWrapper}>
                <div className={style.entry}>
                    <h5 className={style.entryName}>
                        {qualArray[1] ? qualArray[0] + ' in ' + qualArray[1] : qualArray[0]}
                    </h5>
                </div>
            </div>
        </>
    );
};

export default NonEditableEducation;
