import React, { useState } from 'react';

import { prepareAuthHeaders } from '../../../api/tools';
import STRIPE_STATUES from '../../../consts/stripeStatuses';
import { API_GATEWAY } from '../../../consts/urls';
import styles from './style.module.scss';

const StripeOnboardingLink = ({ onboardingStatus }) => {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async () => {
        setIsLoading(true);
        const headers = await prepareAuthHeaders();
        const response = await fetch(`${API_GATEWAY}/Payment/OnboardingUrl`, { headers });
        const { url } = await response.json();
        window.location.href = url;
    };

    return (
        <div className="text-right">
            {onboardingStatus !== STRIPE_STATUES.COMPLETE ? (
                <div role="button" onClick={onClick} className={styles.stripeBtn} disabled={isLoading} />
            ) : null}
        </div>
    );
};

export default StripeOnboardingLink;
