import React from 'react';
import { Button } from 'reactstrap';
import GenericModal from '../../../../../../components/modals/GenericModal';

import style from '../style.module.scss';

const AudioModal = ({ header, audio, extension }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const audioBody = (
        <div className="text-center">
            <audio controls>
                <source src={audio} type={extension} />
            </audio>
        </div>
    );

    React.useEffect(() => {
        var aud = document.querySelector('audio');

        if (aud) {
            aud.src = audio; 
        }
    }, [audio]);

    return (
        <>
            { audioBody ? audioBody : null }
            {/* <GenericModal isOpen={isOpen} toggle={toggle} header={header} body={videoBody} /> */}
        </>
    );
};

export default AudioModal;
