import React from 'react';
import useSWR from 'swr';
import { authFetcher, NO_REFRESH_INTERVAL, exFetcher, NEVER_REFRESH } from '../../../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../../../consts/urls';
import { ResolveSalary } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import moment from 'moment';

import { sort_by_key, ProcessSalary } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

const APP_ID = 'de0a2d37';
const APP_KEY = '6b89e573b8421debfe1173daae90a3b6';

export const NUDGE_JOBS_PER_PAGE = 5;
export const RESULTS_PER_REQUEST = 20;

function capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function findInString(search, string) {
    if (search && string) {
        return string.toUpperCase().includes(search.toUpperCase());
    } else {
        return false;
    }
}

export const JobData = (FILTER_INFO, USER) => {
    const {
        data: nudgeData,
        isValidating: nudgeValidating,
        mutate: mutateNudge,
    } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Offers/All/${FILTER_INFO.industry.id}`, authFetcher, NEVER_REFRESH);
    const {
        data: adzunaData,
        isValidating: adzunaValidating,
        mutate: mutateAdzuna,
    } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/Offers/Adzuna/${FILTER_INFO.search == "Adzuna" ? FILTER_INFO.industry.name : FILTER_INFO.search || FILTER_INFO.industry.name}/${
            FILTER_INFO.page
        }`,
        exFetcher,
        NEVER_REFRESH,
    );

    const [addValidating, setAddValidating] = React.useState(false);
    const [addData, setAddData] = React.useState();

    let filteredNudge = [];
    let filteredAdzuna = [];

    let matches = [];
    let meanSalary;

    if (nudgeData) {
        const jobData = nudgeData.offers;
        matches = nudgeData.matches;

        if(!!jobData) {
            jobData.forEach((e) => {
                let newJob = {};
                newJob.id = e.id;
                newJob.description = e.description;
    
                newJob.source = 'Nudge';
                newJob.companyName = e.company.name || '';
                newJob.locationName = e.locationType === 'REMOTE' ? 'Remote' : e.location || '';
                newJob.flexibility = e.flexibility || '';
    
                newJob.title = `${e.title.split(' - ')[0]}` || e.title || '';
                newJob.qualificationHeader = `Minimum ${e.qualificationRequired ? 'Required' : 'Desired'} Education`;
                newJob.qualification = e.qualification;
    
                newJob.certificates = e.certificates;
                newJob.skills = e.skills;
    
                newJob.currency = e.currencyType;
    
                if (e.salaryFrom && e.salaryTo)
                    newJob.salary = ResolveSalary(`${e.salaryFrom}-${e.salaryTo}`, e.currencyType, e.salaryFreq, e.workType);
                else newJob.salary = '';
    
                newJob.created = moment(e.availableFrom);
    
                filteredNudge.push(newJob);
            });
        }
    }

    if (adzunaData) {
        meanSalary = adzunaData.mean;
        let localData = adzunaData.results;

        if (addData) {
            localData = localData.concat(addData);
        }

        if (localData) {
            localData.forEach((p) => {
                let newJob = {};
                newJob.source = 'Adzuna';
                newJob.description = p.description;

                newJob.id = p.id;
                newJob.title = p.title.split(' - ')[0] || p.title;
                newJob.companyName = p.company.display_name.replaceAll('&', ' and ') || '';
                newJob.locationName = p.location ? p.location.display_name : '';
                newJob.flexibility = p.contract_type || '';

                newJob.flexibility = capitaliseFirstLetter(newJob.flexibility);
                newJob.redirect_url = p.redirect_url;

                newJob.tag = p.category.tag;
                newJob.salary = ResolveSalary(`${p.salary_min}-${p.salary_max}`, 'GBP', 'Year', 'Paid Work');

                newJob.created = moment(p.created.split('Z')[0]);

                filteredAdzuna.push(newJob);
            });
        }
    }

    if (filteredNudge) {
        if (FILTER_INFO.search) filteredNudge = filteredNudge.filter((e) => 
            findInString(FILTER_INFO.search, e.title) || 
            findInString(FILTER_INFO.search, e.companyName) ||
            findInString(FILTER_INFO.search, e.source)
        );

        if (filteredNudge.length > NUDGE_JOBS_PER_PAGE) {
            if (FILTER_INFO.page == 1) {
                filteredNudge = filteredNudge.slice(0, NUDGE_JOBS_PER_PAGE);
            } else {
                filteredNudge = filteredNudge.slice(NUDGE_JOBS_PER_PAGE, FILTER_INFO.page * NUDGE_JOBS_PER_PAGE);
            }
        }
    }

    if (filteredNudge) {
        if (filteredNudge.length < FILTER_INFO.page * NUDGE_JOBS_PER_PAGE && FILTER_INFO.page > 1) {
            filteredNudge = [];
        }
    }

    let concatData = filteredNudge.concat(filteredAdzuna);
    let comboValidating = adzunaValidating || nudgeValidating || addValidating;

    concatData = sort_by_key(concatData, 'title');

    return {
        concatData,
        matches,
        mutateNudge,
        comboValidating,
        count: adzunaData ? adzunaData.count + filteredNudge.length : 0,
    };
};
