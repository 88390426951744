/* eslint-disable max-len */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';

import { FLASK_API } from '../../consts/urls';
import style from './style.module.scss';

const PriorityCol = ({ onInputClick, buttonsDisabled, value, priorities }) => {
    const task = priorities[value];
    return (
        <Col xs={3}>
            <button onClick={onInputClick} type="button" disabled={buttonsDisabled} data-id={value}>
                {value} {task && `(${task})`}
            </button>
        </Col>
    );
};

export const SpaceStationTasks = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [selectingPriority, setSelectingPriority] = useState(false);
    const [priorities, setPriorities] = useState({});

    const verifyCode = async (code) => {
        const res = await fetch(`${FLASK_API}/space-station/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code }),
        }).catch((err) => {
            return null;
        });

        const data = await res.json();
        if (data.success === true) {
            return true;
        } else {
            return false;
        }
    };

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: (values) => {
            setErrorMessage(null);
            setButtonsDisabled(true);

            const code = Object.values(priorities).join('');
            // check if code is 16 digits
            if (code.length !== 16) {
                setErrorMessage('Please fill in all tasks.');
                setButtonsDisabled(false);
                return;
            }

            // check if code has the same number twice
            if (code.split('').some((v, i, a) => a.lastIndexOf(v) !== i)) {
                setErrorMessage('You cannot use the same task twice.');
                setButtonsDisabled(false);
                return;
            }

            verifyCode(code).then((isCorrect) => {
                if (isCorrect === true) {
                    setSuccessMessage('Your answer has been submitted. Thank you.');
                } else {
                    setErrorMessage('Something went wrong. Please try again later.');
                    setButtonsDisabled(false);
                }

                formik.resetForm();
            });
        },
    });

    const onInputClick = (e) => {
        var target = e.currentTarget;
        if (target.type !== 'button') {
            target = target.parentElement;
        }

        let value = target.dataset.id;
        setSelectingPriority(value);
    };

    const onLetterClick = (e) => {
        var target = e.currentTarget;
        if (target.type !== 'button') {
            target = target.parentElement;
        }

        let lastPrio = selectingPriority;
        let value = target.innerText;

        // set the priority
        if (selectingPriority) {
            setPriorities({ ...priorities, [selectingPriority]: value });
            setSelectingPriority(false);
        } else {
            // set the priority
            if (priorities[value]) {
                setSelectingPriority(value);
            } else {
                setPriorities({ ...priorities, [value]: '' });
                setSelectingPriority(value);
            }
        }

        console.log('new priorities');
        console.log(priorities);
    };

    return (
        <Container fluid className={style.container}>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody className={style.card}>
                            {true && (
                                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                    {!selectingPriority && 
<div className={style.inputContainer} id="prioSelect">
                                        <Row className={`${style.row} ${style.topRow}`}>
                                            <Col xs={12}>
                                                <div
                                                    id="status"
                                                    className={`${style.status} ${errorMessage ? style.error : ''} ${
                                                        successMessage ? style.success : ''
                                                    }`}
                                                >
                                                    {errorMessage && <p>{errorMessage}</p>}
                                                    {successMessage && <p>{successMessage}</p>}
                                                    {!errorMessage && !successMessage && (
                                                        <p>
                                                            {buttonsDisabled
                                                                ? 'Checking...'
                                                                : 'Set each priority\'s task and press the checkmark when done.'}
                                                        </p>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={style.row}>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="1" priorities={priorities} />
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="2"  priorities={priorities}/>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="3"  priorities={priorities}/>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="4" priorities={priorities} />

                                        </Row>
                                        <Row className={style.row}>
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="5" priorities={priorities} />
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="6"  priorities={priorities}/>
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="7" priorities={priorities} />
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="8" priorities={priorities} />

                                        </Row>
                                        <Row className={style.row}>
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="9"  priorities={priorities}/>
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="10" priorities={priorities} />
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="11" priorities={priorities} />
                                        <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="12"  priorities={priorities}/>

                                        </Row>
                                        <Row className={style.row}>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="13" priorities={priorities} />
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="14"  priorities={priorities}/>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="15"  priorities={priorities}/>
                                            <PriorityCol onInputClick={onInputClick} buttonsDisabled={buttonsDisabled} value="16" priorities={priorities} />
                                        </Row>

                                        <Row className={style.row}>
                                            <Col xs={12}>
                                                <button
                                                    className={style.submitButton}
                                                    type="submit"
                                                    disabled={buttonsDisabled}
                                                >
                                                    <FontAwesomeIcon icon="check" />
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
 }
                                    {selectingPriority && (
                                        <div
                                            className={`${style.inputContainer} ${style.subContainer}`}
                                            id="taskSelect"
                                        >
                                            <Row className={`${style.row} ${style.topRow}`}>
                                                <Col xs={12}>
                                                    <div className={style.status}>
                                                        <p>Select a task for priority {selectingPriority}.</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={style.row}>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        A
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        B
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        C
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        D
                                                    </button>
                                                </Col>
                                            </Row>
                                            <Row className={style.row}>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        E
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        F
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        G
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        H
                                                    </button>
                                                </Col>
                                            </Row>
                                            <Row className={style.row}>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        I
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        J
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        K
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        L
                                                    </button>
                                                </Col>
                                            </Row>
                                            <Row className={style.row}>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        M
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        N
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        O
                                                    </button>
                                                </Col>
                                                <Col xs={3}>
                                                    <button
                                                        onClick={onLetterClick}
                                                        type="button"
                                                        disabled={buttonsDisabled}
                                                    >
                                                        P
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Form>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SpaceStationTasks;
