import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, CardTitle, FormGroup } from 'reactstrap';

import nudge_logo_white from '../../assets/images/nudge-n-chalk.png';
import nudge_logo from '../../assets/images/nudge-n-forest.png';
import pattern from '../../assets/videos/PATTERN_HD.mp4';
import AppInput from '../../components/AppInput';
import Loader from '../../components/Loader';
import { FLASK_API, URLS } from '../../consts/urls';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../consts/userRoles';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import style from './style.module.scss';
import { FIELDS, validationSchema } from './validation';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../components/buttons/NudgeButton';

const SimpleAccountVerify = () => {

    const { loginUser, isAuth, isAuthenticating, userRoles } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { code } = useParams();

    const { state } = useLocation();
    const userEmail = state ? state.userEmail : '';
    const userPassword = state ? state.userPassword : '';

    const navigate = useNavigate();

    useEffect(() => {
        if (!userEmail) {
            navigate(URLS.SIGN_IN);
        }
    }, [navigate, userEmail]);

    if (isAuthenticating === false && isAuth === true) {
        if (userRoles.includes(ADMIN)) {
            return <Navigate to={URLS.ADMIN_DASHBOARD} />;
        } else if (userRoles.includes(COMPANY)) {
            return <Navigate to={URLS.COMPANY_DASHBOARD} />;
        } else if (userRoles.includes(USER)) {
            return <Navigate to={URLS.DASHBOARD} />;
        } else if (userRoles.includes(RECRUITER)) {
            return <Navigate to={URLS.RECRUITER_DASHBOARD} />;
        }
    }

    const resendCode = async () => {
        const res = await fetch(`${FLASK_API}/auth/resend-verification-code`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: userEmail }),
        }).catch((err) => {
            console.log(err);
            return null;
        });

        const data = await res.json();
        if (data.success === true) {
            setErrorMessage('Confirmation code re-sent.');
        } else {
            setErrorMessage('An error occurred: ' + data.error);
        }
    };

    const onResendClick = async () => {
        try {
            setIsLoading(true);
            setErrorMessage('');
            const success = await resendCode();
            if (success) {
                setErrorMessage('Confirmation code re-sent.');
            }
        } catch (err) {
            console.log(err);
            setErrorMessage('An error occurred.');
        } finally {
            setIsLoading(false);
        }
    };

    const confirmUser = async (v) => {
        const res = await fetch(`${FLASK_API}/auth/verify-email`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: userEmail, code: v.code }),
        }).catch((err) => {
            console.log(err);
            return null;
        });

        const data = await res.json();
        if (data.success === true) {
            return true;
        } else {
            if (data.error_type === 'ExpiredCodeException' || data.error_type === 'CodeMismatchException') {
                setErrorMessage('Invalid confirmation code. Please try again or request a new code.');
            } else {
                setErrorMessage('An error occurred: ' + data.error);
            }
        }
    };

    const onClickSubmit = async (v) => {
        try {
            setIsLoading(true);
            setErrorMessage('');
            const success = await confirmUser(v);
            if (success) {
                if (userPassword) {
                    await loginUser(userEmail, userPassword);
                } else {
                    setErrorMessage('Your account has been confirmed! You may now log in.');
                    navigate(URLS.SIGN_IN);
                }
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const props = {
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            [FIELDS.CODE]: code,
        },
        validationSchema,
        onSubmit: onClickSubmit,
    };

    const theme = GetPageTheme();
    return (
        <div className={style.box}>
            <Helmet>
                <title>Confirm Account - Nudge Energy Talent Lab</title>
            </Helmet>
            <div className={style.half1}>
                <h5 className={style.videoSubtitle}>
                    <b>Find Your Why</b>
                    <br />
                    <i style={{ fontSize: '24px' }}>
                        Nudge theory: To help people without forcing them. From that small nudge comes a whole new life
                        and a whole new energy industry all from a single nudge.
                    </i>
                </h5>
                <video className={style.video} autoPlay muted loop id="myVideo">
                    <source id="myVideo" src={pattern} type="video/mp4" />
                </video>
            </div>
            <div className={style.half2}>
                <div className={theme == 'light' ? style.logoContainer : style.logoContainerDark}>
                    <Link to={URLS.HOME}>
                        <img src={nudge_logo_white} className={style.nudgeLogo} alt="nudge-white" />
                    </Link>
                    <h5 className={style.logoLabel}>Welcome to Nudge!</h5>
                </div>

                <Alert
                    isOpen={!!errorMessage}
                    toggle={() => setErrorMessage('')}
                    color="danger"
                    fade={true}
                    className={style.errorMessage}
                >
                    {errorMessage}
                </Alert>

                {!isLoading ? (
                    <Card id={`card-${theme}`} className={theme == 'light' ? style.signUpCard : style.signUpCardDark}>
                        <CardTitle id={`card-sub-${theme}`}>
                            <h4 className={style.signUpTitle}>Confirm Account</h4>
                        </CardTitle>

                        <Formik {...props}>
                            {(formik) => (
                                <div style={{ padding: '12px', paddingTop: '0' }}>
                                    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                        <p>
                                            Please check your email inbox for a confirmation code. If you require a new
                                            code to be sent, click <strong>Re-send code</strong>.
                                        </p>

                                        <FormGroup className="mb-20">
                                            <AppInput
                                                id={FIELDS.CODE}
                                                type="text"
                                                formik={formik}
                                                label="Confirmation Code"
                                                required={true}
                                            />
                                        </FormGroup>

                                        <ClickButton className={style.signUp} color="success" type="submit">
                                            Confirm
                                        </ClickButton>

                                        <ClickButton className={style.resend} color="success" onClick={onResendClick}>
                                            Re-send code
                                        </ClickButton>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </Card>
                ) : (
                    <Card id={`card-${theme}`} className={style.signUpCard}>
                        <Loader />
                    </Card>
                )}
            </div>
        </div>
    );
};

export default SimpleAccountVerify;
