import moment from "moment";

export function parseMergedChat(chat, user) {
    if(chat?.length) {
        var copyChat = chat;
        
        var currentAuthor = "";
        var currentDate = "";

        var chatsArr = [];
        var firstChat = copyChat[0];
        
        var localChatsObj = { 
            author: firstChat.authorName || firstChat.author, 
            authorName: firstChat.authorName || firstChat.author, 
            date: firstChat.date, 
            messages: [],
            files: [], 
            read: false, 
            userAuthor: firstChat.author === (user.subId || user.id),
            isAdmin: firstChat.isAdmin,
            photo: firstChat.photo
        }

        copyChat.forEach((item, index) => {
            if(index == 0) {
                currentAuthor = item.author;
                currentDate = item.date;
            };
            
            const minuteDiff = moment.duration(moment(item.date).diff(moment(currentDate))).asMinutes();
            
            if(currentAuthor != item.author || minuteDiff > 5 || item.filePath) {
                currentAuthor = item.author;
                currentDate = item.date;

                localChatsObj.read = item.hasRead;

                chatsArr.push(localChatsObj);

                localChatsObj = {
                    isAdmin: item.isAdmin,
                    author: item.author, 
                    authorName: item.authorName || item.author, 
                    date: item.date, 
                    messages: [],
                    files: [],
                    read: false, 
                    userAuthor: item.author === (user.subId || user.id),
                    photo: item.photo
                }

                localChatsObj.messages.push({ id: item.id, message: item.message })
                if(item.filePath) localChatsObj.files.push(item.filePath);
            } else {
                currentDate = item.date;
                localChatsObj.messages.push({ id: item.id, message: item.message })
                if(item.filePath) localChatsObj.files.push(item.filePath);
            }
        })
        
        chatsArr.push(localChatsObj);

        const dateGrouping = {};
        chatsArr.forEach((item, index) => {
            const formatted = moment(item.date).startOf('day').format("YYYY-MM-DD");
            if(dateGrouping[formatted]) {
                dateGrouping[formatted].push(item)
            } else {
                dateGrouping[formatted] = [item];
            }
        })

        return dateGrouping;
    }
}