export const datalistArray = [
    'Data Analysis',
    'Foreign Language',
    'Analytical Thinking',
    'Project Management',
    'Social Media Marketing',
    'Team Management',
    'Computing',
    'Software Engineering',
    'Data Presentation',
    'Economics',
    'Flexibility',
    'Collaboration/Teamwork',
    'Communication Skills',
    'Customer Service',
    'Conflict Resolution',
    'Active Listening',
    'Interpersonal Skills',
    'Detail Oriented',
    'Empathy',
    'Clarity Communication',
    'Creating Good Rapport',
    'Giving and Receiving Feedback',
    'Open-Mindedness',
    'Confidence',
    'Communication',
    'Delegation',
    'Mental Organisational Skills',
    'Physical Organization',
    'Planning',
    'Prioritizing',
    'Teamwork',
    'Time Management',
    'Building Good Rapport',
    'Decision Making',
    'Tolerance',
    'Data Reporting',
    'Problem Solving',
    'Research',
    'Diagnostics',
    'Forecasting',
    'Data Mining',
    'Analytical Reasoning',
    'Strategic Planning',
    'Motivating',
    'People/Team Management',
    'Emotional Intelligence',
    'Public Speaking',
    'Team Building',
    'Data Gathering',
    'Brainstorming',
    'Managing Risk',
    'Reliability',
];
