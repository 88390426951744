/* eslint-disable max-len */
import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'name',
    //INDUSTRY: 'industry',
    LOCATION: 'location',
    WEBSITE: 'website',
    PRIMARY_COLOUR: 'primary_colour',
    SECONDARY_COLOUR: 'secondary_colour',
    
    LOGO: 'logo',
    LOGO_BUFFER: 'logoBuffer',

    DEFAULT: 'is_default',
    GLOBAL: 'is_global',
    
    BACK_COLOUR: 'backColour',
};

const REG_EX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required'),
    //[FIELDS.INDUSTRY]: Yup.string().required('This field is required'),
    [FIELDS.LOCATION]: Yup.string().required('This field is required'),
    [FIELDS.WEBSITE]: Yup.string().matches(REG_EX, "Must be a valid URL.").required('This field is required'),
    [FIELDS.PRIMARY_COLOUR]: Yup.string().required('This field is required'),
    [FIELDS.SECONDARY_COLOUR]: Yup.string().required('This field is required'),
    [FIELDS.LOGO]: Yup.mixed().required('This field is required'),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    //[FIELDS.INDUSTRY]: '28bdabb97b1b4553abce5d0f4dc8bc2f',
    [FIELDS.LOCATION]: '',
    [FIELDS.WEBSITE]: '',
    [FIELDS.PRIMARY_COLOUR]: '',
    [FIELDS.SECONDARY_COLOUR]: '',
    [FIELDS.LOGO]: '',
    [FIELDS.DEFAULT]: false,
    [FIELDS.GLOBAL]: false,
};
