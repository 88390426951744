                    import React from 'react';

import style from './style.module.scss';
import { Button } from 'reactstrap';
import NudgeButton from '../../components/buttons/NudgeButton';

export const ThemeContext = React.createContext({});

const ThemeWrapper = ({ children }) => {
    const page_theme = window.localStorage.getItem('page_theme');
    const sidebar_position = window.localStorage.getItem('sidebar_position');

    const [theme, setTheme] = React.useState(page_theme || 'auto');
    const [isAuto, setIsAuto] = React.useState(false);
    const [rtl, setRtl] = React.useState(sidebar_position === 'true');

    React.useEffect(() => {
        window.localStorage.setItem('page_theme', theme);

        if (theme === 'auto') {
            setIsAuto(true);
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                setTheme('dark');
            } else {
                setTheme('light');
            }
            window.localStorage.setItem('page_theme', 'auto');
        }
    }, [theme]);

    React.useEffect(() => {
        window.localStorage.setItem('sidebar_position', rtl);

        setRtl(rtl);
    }, [rtl])

    var html = document.getElementsByTagName('html')[0];
    html.style.background = theme === 'light' ? '#f5f6fa' : '#404258';
    html.style.color = theme === 'light' ? '#274237' : 'white';

    var body = document.getElementsByTagName('body')[0];
    body.style.background = theme === 'light' ? '#f5f6fa' : '#404258';
    body.style.color = theme === 'light' ? '#274237' : 'white';

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    const setThemeAuto = () => {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
        window.localStorage.setItem('page_theme', 'auto');
    };

    var globalStyle = document.documentElement.style
    const [modalOpen, setModalOpen] = React.useState(false);

    if(theme == 'light') {
        globalStyle.setProperty("--trackColour", "#407962");
        globalStyle.setProperty("--trackColourHover", "#274237");
        globalStyle.setProperty("--trackBorderLeft", "1px solid white");
    } else if(theme == 'dark') {
        globalStyle.setProperty("--trackColour", "#17a2b8");
        globalStyle.setProperty("--trackColourHover", "#0a7686");
        globalStyle.setProperty("--trackBorderLeft", "");
    }

    const values = {
        theme,
        toggleTheme,
        setTheme,
        isAuto,
        setThemeAuto,

        modalOpen,
        setModalOpen,

        rtl,
        setRtl
    };
    
    return theme && html && body ? (
        <ThemeContext.Provider value={values}>
            {/* <NudgeButton onClick={() => toggleTheme()} style={{ position: 'absolute', top: '12px', left: '12px',zIndex: '999' }} color="success">Change Theme</NudgeButton> */}
            <div>{children}</div>
        </ThemeContext.Provider>
    ) : null;
};

export default ThemeWrapper;
