import * as Yup from 'yup';

export const FIELDS = {
    MESSAGE: 'message',
    FILE: 'file',
};

export const validationSchema = Yup.object({
    [FIELDS.MESSAGE]: Yup.string().required('This field is required.'),
});

export const initialValues = {
    [FIELDS.MESSAGE]: '',
    [FIELDS.FILE]: null,
};
