import React, { useMemo } from 'react';
import useSWR from 'swr';

import LinkButton from '../../../components/buttons/LinkButton';

import { authFetcher, NEVER_REFRESH, NO_REFRESH_INTERVAL } from '../../../api/tools';
import { renderLogoCell, renderNameCell } from '../../../components/GenericTable/helpers';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_ADMIN, URLS } from '../../../consts/urls';

import { sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import CompanyList from './CompanyList';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AdminCompaniesAdd from '../AdminCompaniesAdd';
import ClickButton from '../../../components/buttons/NudgeButton';

import style from './style.module.scss';

const AdminCompanies = () => {
    const { data, mutate } = useSWR(`${API_DASHBOARD_ADMIN}/Companies`, authFetcher, NO_REFRESH_INTERVAL);

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => { setIsOpen(!isOpen); }


    let filteredArray = [];
    if (data) {
        filteredArray = sort_by_key(data.companies, 'currentlyPublished').reverse();
        filteredArray = filteredArray.filter((item) => item.parentId === '' || item.parentId === ' ');
    }

    return (
        <SignInPageTemplate>
            <AdminCompaniesAdd isOpen={isOpen} toggle={toggle}/>
            <h1 className={style.title}>
                Companies<ClickButton color="success" onClick={toggle}>Add New Company</ClickButton>
            </h1>
            {data ? <CompanyList companies={filteredArray} /> : <Loader />}
        </SignInPageTemplate>
    );
};

export default AdminCompanies;
