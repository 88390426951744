import { Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../Loader';
import BiddingCard from '../../forms/BiddingCard';

import { authPostDataWithNoResponse, authSendFile } from '../../../api/tools';
import { API_DASHBOARD_COMPANY, API_DASHBOARD_CONTRACTOR, API_PROFILE } from '../../../consts/urls';
import MessageInputForm from './MessageInputForm';
import { initialValues, validationSchema } from './MessageInputForm/validate';
import style from './style.module.scss';
import { ToastContext } from '../../../contexts/toast/ToastContext';
import { MESSAGES_PER_PAGE } from '../../../pages/company/InterviewDetails/helpers';

const MessageInput = ({ matchId, afterSendAction, company, bidding, mutate, frequency, offerStatus, isDisabled, advancePage, pageRef, messages }) => {
    const { id } = useParams();
    const [key, setKey] = useState(false);
    const [sending, setSending] = useState(false);

    const [showBid, setShowBid] = useState(false);
    const toggle = () => setShowBid(!showBid);

    const { createError } = React.useContext(ToastContext);

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const url = company ? API_DASHBOARD_COMPANY : API_DASHBOARD_CONTRACTOR;
            setKey(!key);
            setSending(true);

            try {
                if (values.file != null) {
                    await authSendFile(values.file, `${API_PROFILE}/Matches/Attachment`, {
                        matchId: matchId || id,
                        message: values.message,
                    });
                    setSending(false);
                } else {
                    await authPostDataWithNoResponse(values, `${url}/Matches/${matchId || id}/Chat`);
                    setSending(false);
                }

                if(Math.ceil(messages / MESSAGES_PER_PAGE) == pageRef?.current) {
                    advancePage(pageRef?.current + 1);
                } else {
                    afterSendAction();
                }
                
                resetForm(initialValues);
            } catch(e) {
                createError(`Message failed to send - ${e.message}`);
            }

        },
    };

    return !showBid ? (
        <div className={style.inputWrapper}>
            {(
                <Formik {...props}>
                    {(formik) => (
                        <MessageInputForm formik={formik} toggleBid={toggle} bid={bidding} company={company} offerStatus={offerStatus} disabled={sending || isDisabled}/>
                    )}
                </Formik>
            )}
        </div>
    ) : (
        <BiddingCard
            company={company}
            status={bidding.status}
            value={bidding.amount}
            matchId={id}
            frequency={frequency}
            toggle={toggle}
            refresh={mutate}
        />
    );
};

export default MessageInput;
