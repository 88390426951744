import * as Yup from 'yup';

export const REG_EX = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const FIELDS = {
    EMAIL: 'email',
    PASSWORD: 'password',
};

export const validationSchema = Yup.object({
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
    [FIELDS.PASSWORD]: Yup.string()
        .min(8, 'This field should be at least 8 chars length')
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .required('This field is required'),
});

export const initialValues = {
    [FIELDS.EMAIL]: '',
    [FIELDS.PASSWORD]: '',
};
