import { Form } from 'formik';
import React from 'react';

import SaveButton from '../../../buttons/SaveButton';
import QualificationsFormBody from '../../../forms/QualificationsForm/QualificationFormBody';
import style from './style.module.scss';

const EditableEducation = ({ formik, onClose }) => {
    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <div className={style.textsWrapper}>
                <QualificationsFormBody formik={formik} />
            </div>
            <SaveButton  onClose={onClose} />
        </Form>
    );
};

export default EditableEducation;
