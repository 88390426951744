import React from 'react';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import UserCandidatePage from '../../../components/OpportunityPages/User';

export const ContractOffer = () => {
    return <UserCandidatePage />;
};

export default withAuthProtectedRouter(ContractOffer, [USER]);
