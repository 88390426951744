import React from 'react';
import { COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import CompanyAdminCandidatePage from '../../../components/OpportunityPages/CompanyAdmin';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';

export const CompanyContractOffer = () => {
    return <CompanyAdminCandidatePage roleUrl={API_DASHBOARD_COMPANY} />;
};

export default withAuthProtectedRouter(CompanyContractOffer, [COMPANY]);
