import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import NudgeButton from '../NudgeButton';

const SkipButton = ({ skipCurrentStep }) => (
    <NudgeButton className="w-100" color="success" type="button" onClick={skipCurrentStep} data-testid="skip-button">
        <strong>Skip this step for now</strong>
    </NudgeButton>
);

SkipButton.propTypes = {
    skipCurrentStep: PropTypes.func.isRequired,
};

export default SkipButton;
