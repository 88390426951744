import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';

import bcorp from '../../assets/images/b_corp_sm.png';
import bcorp_white from '../../assets/images/b_corp_sm_white.png';

import Logo from '../../assets/images/Nudge full logo.png';
import Logo_white from '../../assets/images/nudge-logo-chalk-full.png';
import { URLS } from '../../consts/urls';
import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import { customStyles } from '../SelectField';
import ScrollButton from '../SignInPageTemplate/ScrollButton';

const FooterComp = () => {
    const theme = GetPageTheme();
    const { t, i18n } = useTranslation();
    
    const options = [
        { label: 'English', value: 'en-GB' },
        // { label: 'Cymraeg', value: 'cy' },
    ];
    
    const [languageOption, setLanguageOption] = React.useState(options[0]);
    const handleLanguageChange = (e) => {
        // TODO: implement language change
        setLanguageOption(e);
        i18n.changeLanguage(e.value);
    };

    useEffect(() => {
        const currentLanguage = i18n.language;
        const languageSelect = document.getElementById('language-select');
        if (languageSelect) {
            languageSelect.value = currentLanguage;
        }
    }, [i18n.language]);


    return (
        <div className={style.footer} id={`card-${theme}`}>
            <ScrollButton/>
            <Container style={{ textAlign: 'center'}}>
                <Row>
                    <Col lg={3} className={style.logoCol}>
                        <div className={style.languageRow}>
                                <img src={theme == 'light' ? Logo : Logo_white} alt="the Nudge logo" className={style.nudgeLogo} />
                                
                                <div class="d-block w-100">
                                    <p><b>Language Select (WIP): </b></p>
                                    <Select
                                        isDisabled
                                        isSearchable={false}
                                        styles={customStyles()}
                                        options={options}
                                        defaultValue={options[0]}
                                        onChange={handleLanguageChange}
                                        value={languageOption}
                                    />
                                </div>
                            </div>
                    </Col>
                    <Col md={4} sm={6} lg={3} className={style.logoCol}>
                        <p><b>Useful Links</b></p>
                        <a href="https://blog.nudgetalentlab.com/" target="_blank" rel="noopener noreferrer">
                            Nudge Blog
                        </a>
                        <a href={URLS.SERVICE_TERMS} target="_blank" rel="noopener noreferrer">
                            Terms &amp; Conditions
                        </a>
                        <a href={URLS.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                        <a href="mailto:nudgeme@nudgeexchange.com">
                            Contact Us
                        </a>
                    </Col>
                    <Col md={4} sm={6} lg={3} className={style.logoCol}>
                        <p><b>Find us on:</b></p>
                        <div className={style.logos}>
                            <a href="https://www.linkedin.com/company/19037721">
                                <FontAwesomeIcon icon={['fab', 'linkedin']} />
                            </a>
                            <a href="https://www.instagram.com/nudgeenergytalentlab/?hl=en">
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                            </a>
                            <a href="https://www.facebook.com/nudgeenergytalentlab">
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                            </a>
                            <a href="https://www.youtube.com/channel/UC-zD6k8WMo5x3_DdbvwrEFg">
                                <FontAwesomeIcon icon={['fab', 'youtube']} />
                            </a>
                        </div>
                    </Col>
                    <Col md={4} sm={12} lg={3} className={style.logoCol}>
                        <div style={{ lineHeight: '1.3' }}>
                            <p><b>Registered in Scotland:</b></p>
                            SC460309 <br />6 Ury Dale, Inverurie <br />
                            Aberdeenshire, Scotland <br />
                            AB51 3XW <br />
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <Container>
                <Row>
                    <Col xs={6}>
                        <Row style={{ height: '100%' }}>
                            <Col xs={12} xl={6} style={{ alignSelf: 'flex-end', marginBottom: '5vh' }}>
                                <Select
                                    options={options}
                                    defaultValue={options[0]}
                                    onChange={handleLanguageChange}
                                    value={languageOption}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4} md={4} xl={4}>
                        <img src={theme == 'light' ? Logo : Logo_white} alt="the Nudge logo" className={style.nudgeLogo} />
                        <div style={{ lineHeight: '1.3', marginBottom: '5vh', lineBreak: 'anywhere' }}>
                            Registered in Scotland: SC460309 <br />6 Ury Dale, Inverurie <br />
                            Aberdeenshire, Scotland <br />
                            AB51 3XW <br />
                            <br />
                            <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a>
                        </div>
                    </Col>
                    <Col xs={12} xl={5}>
                        <Row style={{ height: '100%' }}>
                            <Col xs={12} xl={6} style={{ alignSelf: 'flex-end', marginBottom: '5vh' }}>
                                <a href="https://blog.nudgetalentlab.com/" target="_blank" rel="noopener noreferrer">
                                    Nudge Blog
                                </a>
                                <a href={URLS.SERVICE_TERMS} target="_blank" rel="noopener noreferrer">
                                    Terms &amp; Conditions
                                </a>
                                <a href={URLS.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>

                                
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} xl={3}>
                        <a
                            href="https://www.bcorporation.net/en-us/find-a-b-corp/company/nudge-exchange-ltd"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={theme == 'light' ? bcorp : bcorp_white} alt="b corp logo" className={style.logo} />
                        </a>
                    </Col>
                </Row>
            </Container> */}
        </div>
    );
};

export default FooterComp;
