function ResolveDuration(pos) {
    var years = 0;
    var months = 0;

    if (pos.durationYears) {
        years = `${parseInt(pos.durationYears)}-`;
    }

    if (pos.durationMonths) {
        months = `${parseInt(pos.durationMonths)}`;
    }

    return `${years}${months}`;
}


export function parseCertificates(certificates) {
    if (!certificates.length) return null;

    var certs = [];
    certificates.forEach((item) => {
        const obj = {
            expireDate: item.expiry,
            file: null,
            name: item.name,
        };
        certs.push(obj);
    });

    return certs;
}

export function parseValues(values) {
    if (!values.length) return null;

    var vals = [];
    values.forEach((item) => {
        const obj = {
            content: item,
        };
        if (obj.content != '') vals.push(obj);
    });

    return vals;
}

export function parseExperience(experience, currentExperience) {
    if (!experience.length) return null;

    var exp = [];
    experience.forEach((item) => {
        const obj = {
            Name: item.title,
            Duration: ResolveDuration(item),
            present: item.currentlyWorking,
            company: item.company,
            details: item.description,
        };
        exp.push(obj);
    });

    return exp;

    currentExperience.forEach((item) => {
        if (item.ref_message || item.ref_email) {
            const obj = {
                Name: item.name,
                Duration: item.duration,

                ref_message: item.ref_message,
                ref_code: item.ref_code,
                ref_email: item.ref_email,
                ref_name: item.ref_name,

                details: item.details,
                present: false,
                company: null,
            };
            exp.push(obj);
        }
    });

    return exp;
}
