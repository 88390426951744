import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';

import AppInput from '../../AppInput';
import SaveButton from '../../buttons/SaveButton';
import { FIELDS, FIELD_NAMES } from './validation';
import style from './style.module.scss';

export const EditableBio = ({ formik, onClose, bioType }) => (
    <>
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <div className={style.buttons}>
                <SaveButton  onClose={onClose} />
            </div>

            <FormGroup row className="mb-20">
                <Label for={FIELDS.INDUSTRY}>{FIELD_NAMES[FIELDS.INDUSTRY]}</Label>
                <AppInput id={FIELDS.INDUSTRY} formik={formik} label="Industry" />
            </FormGroup>

            <FormGroup row className="mb-20">
                <Label for={FIELDS.SIZE}>{FIELD_NAMES[FIELDS.SIZE]}</Label>
                <AppInput id={FIELDS.SIZE} formik={formik} label="Size" />
            </FormGroup>

            <FormGroup row className="mb-20">
                <Label for={FIELDS.HEADQUARTERS}>{FIELD_NAMES[FIELDS.HEADQUARTERS]}</Label>
                <AppInput id={FIELDS.HEADQUARTERS} formik={formik} label="Headquarters" />
            </FormGroup>

            <FormGroup row className="mb-20">
                <Label for={FIELDS.FOUNDED}>{FIELD_NAMES[FIELDS.FOUNDED]}</Label>
                <AppInput id={FIELDS.FOUNDED} formik={formik} label="Founded" />
            </FormGroup>
        </Form>
    </>
);

export default EditableBio;
