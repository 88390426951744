import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import { ThemeContext } from '../../../../contexts/theme/ThemeContextWrapper';
import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import { customStyles } from '../../../SelectField';
import style from './style.module.scss';
import NudgeModal from '../../../NudgeModal';
import NudgeButton from '../../../buttons/NudgeButton';
import { URLS } from '../../../../consts/urls';

export const SettingsModal = ({ isOpen, toggle }) => {
    const { t, i18n } = useTranslation();
    const { theme, setTheme, isAuto, setThemeAuto, rtl, setRtl } = React.useContext(ThemeContext);

    const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);
    const [currentTheme, setCurrentTheme] = React.useState(theme);
    const [currentPosition, setCurrentPosition] = React.useState(`${rtl}`);

    const navigate = useNavigate();

    const languageOptions = [
        { value: 'en-GB', label: 'English' },
        // { value: 'cy', label: 'Cymraeg' },
    ];

    const themeOptions = [
        { value: 'auto', label: t('settings.auto') },
        { value: 'light', label: t('settings.light') },
        { value: 'dark', label: t('settings.dark') },
    ];

    const sidebarOptions = [
        { value: 'false', label: 'Left' },
        { value: 'true', label: <>Right <i>(beta)</i></>, isDisabled: false },
    ]

    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.value);
    };

    const handleThemeChange = (e) => {
        if (e.value === 'auto') {
            setThemeAuto();
        } else {
            setTheme(e.value);
        }
    };

    const handlePositionChange = (e) => {
        setRtl(e?.value === 'true');
    }

    React.useEffect(() => {
        // const cur = i18n.language;
        // setCurrentLanguage(cur);
        // setCurrentTheme(isAuto ? 'auto' : theme);
    }, []);

    return (
        <NudgeModal headerText={t('settings.title')} id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>

            <ModalBody className={style.modalBody}>
                <Row className={style.setting}>
                    <Col xs={12} sm={5} lg={3} className={style.settingName}>
                        <FontAwesomeIcon icon="language" className={style.settingIcon} style={{ width: '30px' }}/>
                        <span className={style.settingNameText}>{t('settings.language')}</span>
                    </Col>
                    <Col xs={12} sm={7} lg={9} className={style.settingChoice}>
                        {currentLanguage ? (
                            <Select
                                options={languageOptions}
                                defaultValue={languageOptions.find((option) => option.value === currentLanguage)}
                                onChange={handleLanguageChange}
                                styles={customStyles()}
                                isSearchable={false}
                            />
                        ) : null }
                    </Col>
                </Row>
                <Row className={style.setting}>
                    <Col xs={12} sm={5} lg={3} className={style.settingName}>
                        <FontAwesomeIcon icon="palette" className={style.settingIcon} style={{ width: '30px' }} />
                        <span className={style.settingNameText}>{t('settings.theme')}</span>
                    </Col>
                    <Col xs={12} sm={7} lg={9} className={style.settingChoice}>
                        {currentTheme ? (
                            <Select
                                options={themeOptions}
                                defaultValue={themeOptions.find((option) => option.value === currentTheme)}
                                onChange={handleThemeChange}
                                styles={customStyles()}
                                isSearchable={false}
                            />
                        ) : null }
                    </Col>
                </Row>
                <Row className={style.setting}>
                    <Col xs={12} sm={5} lg={3} className={style.settingName}>
                        <FontAwesomeIcon icon={rtl === true ? "border-right" : "border-left"} className={style.settingIcon} style={{ width: '30px' }} />
                        <span className={style.settingNameText}>{t('Sidebar Position')}</span>
                    </Col>
                    <Col xs={12} sm={7} lg={9} className={style.settingChoice}>
                        { currentPosition != null ? (
                            <Select
                                options={sidebarOptions}
                                defaultValue={sidebarOptions.find((option) => option.value === currentPosition)}
                                onChange={handlePositionChange}
                                styles={customStyles()}
                                isSearchable={false}
                            />
                        ) : null }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </NudgeModal>
    );
};

export default SettingsModal;
