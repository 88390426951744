import React from 'react';
import { Formik } from 'formik';
import { Button, Card, Col, Row } from 'reactstrap';
import { checkIfEmailExists } from '../../../contexts/auth/tools';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';
import { ContainerCard, GetPageTheme } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import { COMPANY, USER, ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { validationSchema, initialValues } from './FeedbackModal/validation';

import NudgeButton from '../../../components/buttons/NudgeButton';
import useSWR from 'swr';
import FeedbackModal from './FeedbackModal';
import Loader from '../../../components/Loader';

import { authPostData, deleteData } from '../../../api/tools';

import style from './style.module.scss';
import { IndividualSublogin } from './IndividualSublogin';

export const ChangePasswordPage = ({ user }) => {
    let { data, mutate } = useSWR(`${API_DASHBOARD_COMPANY}/Sublogins`, authFetcher, NO_REFRESH_INTERVAL);

    /*const mutate = () => console.log("Mutate")

    data = [
        { name: 'Example Account 1', id: '1' },
        { name: 'Example Account 2', id: '2' },
        { name: 'Example Account 3', id: '3' },
        { name: 'Example Account 4', id: '4' },
        { name: 'Example Account 5', id: '5' },
    ];*/

    const [sending, setSending] = React.useState(false);

    const [error, setError] = React.useState('');
    const toggleError = () => setError('');

    const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
    const toggleFeedback = () => setFeedbackModalOpen(!feedbackModalOpen);

    const onPost = async (values) => {
        setSending(true);
        toggleError();

        await authPostData(values, `${API_DASHBOARD_COMPANY}/Sublogins`);

        await mutate();

        toggleFeedback();
        setSending(false);
    };

    const onDelete = async (id) => {
        setSending(true);

        await deleteData({}, `${API_DASHBOARD_COMPANY}/Sublogins/${id}`);

        await mutate();

        setSending(false);
    };

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const isEmailAvailable = await checkIfEmailExists(values.Email);

            if (!isEmailAvailable) {
                onPost(values);
            } else {
                setError('An account with the given email already exists.');
            }
        },
    };

    const theme = GetPageTheme();
    return data ? (
        <Card id={`card-${theme}`} style={{ height: '450px', padding: '28px' }}>
            <Formik {...props}>
                {(formik) => (
                    <FeedbackModal
                        formik={formik}
                        isOpen={feedbackModalOpen}
                        toggle={toggleFeedback}
                        error={error}
                        toggleError={toggleError}
                    />
                )}
            </Formik>
            <Row>
                <Col xs={12} md={6}>
                    <h5 class="w-100">Sub-Accounts</h5>
                </Col>
                <Col xs={12} md={6}>
                    { user.isMain && data.length < 5 ? (
                        <NudgeButton onClick={toggleFeedback} className={style.subAccountAdd}>
                            Create New Sublogin
                        </NudgeButton>
                    ) : null }
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    { !sending && user.isMain ? (
                        <Card id={`card-sub-${theme}`} style={{ height: '340px', padding: '12px' }}>
                            {data.map((item) => (
                                <IndividualSublogin item={item} onDelete={onDelete} />
                            ))}
                        </Card>
                    ) : null }
                </Col>
            </Row>

            
            { !user.isMain ? (
                <ContainerCard id={`card-sub-${theme}`} className={style.subAccountContainer}>
                    <h5 className="text-center">
                        Only the account admin can add new sub-accounts.
                        <br />
                        <br />
                        Contact your account's admin if there are any problems.
                    </h5>
                </ContainerCard>
            ) : null }
            { sending ? (
                <ContainerCard id={`card-sub-${theme}`}>
                    <Loader />
                </ContainerCard>
            ) : null }
        </Card>
    ) : (
        <Loader />
    );
};

export default withAuthProtectedRouter(ChangePasswordPage, [USER, COMPANY, ADMIN]);
