import * as Yup from 'yup';

export const REG_EX = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const FIELDS = {
    NEW_PASSWORD: 'newPassword',
    OLD_PASSWORD: 'currentPassword',
    NEW_PASSWORD_REPEAT: 'newPasswordRepeat',
};

export const validationSchema = Yup.object({
    [FIELDS.OLD_PASSWORD]: Yup.string()
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .required('This field is required'),
    [FIELDS.NEW_PASSWORD]: Yup.string()
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .required('This field is required'),
    [FIELDS.NEW_PASSWORD_REPEAT]: Yup.string()
        .matches(REG_EX, 'Password should contain upper and lower case letters and numbers')
        .oneOf([Yup.ref(FIELDS.NEW_PASSWORD), null], 'Passwords must match')
        .required('This field is required'),
});

export const initialValues = {
    [FIELDS.OLD_PASSWORD]: '',
    [FIELDS.NEW_PASSWORD]: '',
    [FIELDS.NEW_PASSWORD_REPEAT]: '',
};
