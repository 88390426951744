import React from 'react';
import { Button } from 'reactstrap';
import GenericModal from '../../../../../../components/modals/GenericModal';

import style from '../style.module.scss';
import ClickButton from '../../../../../../components/buttons/NudgeButton';

const VideoModal = ({ header, video, extension }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const videoBody = (
        <div className="text-center">
            <video className={style.videoPlayer} controls style={{ margin: 'auto' }}>
                <source src={video} type={extension} />
            </video>
        </div>
    );

    React.useEffect(() => {
        var vid = document.querySelector('video');

        if (vid) {
            vid.src = video; 
        }
    }, [video]);

    return (
        <>
            <div className="text-center">
                <ClickButton disabled={!video} color={'success'} onClick={toggle}>
                    Show Video
                </ClickButton>
            </div>
            <GenericModal isOpen={isOpen} toggle={toggle} header={header} body={videoBody} />
        </>
    );
};

export default VideoModal;
