const COMPANY = '/company';
const CONTRACTOR = '/contractor';
const ADMIN = '/admin';
const RECRUITER = '/recruiter';
const FORMS = '/forms';

const CLOOD_DEV = process.env.REACT_APP_CLOOD_API_URL;

export const URLS = {
    DEV: '/dev',
    DASHBOARD: '/dashboard',
    HOME: '/',
    COMMUNITY: '/community',
    EVENTS: '/events',
    PROJECT_MAP: '/project-map',
    CHATBOT: '/chatbot',
    B_CORP: '/B_CorpPage',
    COMPANYPAGE: '/services/company',
    INDIVIDUALPAGE: '/services/talent',
    ABOUTPAGE: '/about',
    SIGN_IN: '/sign-in',
    CHANGE_PASSWORD: '/change-password',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_CODE: '/forgot-password-code',
    PRE_SIGN_UP: '/pre-sign-up',
    SIGN_UP: '/sign-up',
    SIMPLE_ACCOUNT_VERIFY: '/confirm-account',
    NEW_SIGN_UP: '/new-sign-up',
    SIGN_UP_COMPANY: '/sign-up-company',
    CONFIRM_SIGN_UP: '/confirm-sign-up',
    OFFERS: '/contracts',
    COMPANY_OFFERS: '/company-offers',
    WELCOME: '/welcome',
    PROFILE: '/profile',
    SETTINGS: '/settings',
    SERVICE_TERMS: process.env.REACT_APP_SERVICE_TERMS,
    PRIVACY_POLICY: process.env.REACT_APP_PRIVACY_POLICY,
    CONTRACTOR,
    MEETING: '/meeting',
    INTERVIEWS: '/interviews',
    INTERVIEW_CALENDAR: '/calendar',
    RESCHEDULE_CALENDAR: '/reschedule-interview',
    PAYMENTS: '/payments',
    ADD_TIMESHEET: '/add-timesheet',
    TIMESHEETS: '/timesheets',
    KYC: '/kyc',
    NEW_PASSWORD_REQUIRED: '/new-password-required',
    BLOGS: '/blog',
    JOBS_REEL: '/jobs-reel',
    FEEDBACK: '/roadmap',
    REFERENCES: '/references',
    FOREST: '/forest',
    ABOUT_TEAM: '/about/team',
    CONTACTPAGE: '/about/contact',
    FAQ: '/about/faq',
    KNOWLEDGEBASE: '/about/knowledgebase',
    DIRECTORY: '/companies',
    RECOMMENDER: '/recommender',
    SPACE_STATION: '/space-station',
    SPACE_STATION_TASKS: '/space-tasks',

    HOME_NEO_TALENT: '/neo-talent',
    NEO_TALENT: '/contractor/neo-talent',
    NEO_TALENT_MEETING: '/contractor/neo-talent/meeting',
    NEO_TALENT_MEETING_END: '/meeting-end',

    DISCOURSE_SSO: '/sso/discourse',

    PARTNERS: '/partners',
    VALUES: '/values',
    ACCREDITATIONS: '/accreditations',

    BCORP: '/bcorp',
    GIVE_BACK: '/give-back',
    PLEDGES: '/pledges',

    FORMS,
    ABERDEEN_FORM: '/aberdeen-form',

    COMPANY,
    COMPANY_PROFILE: `${COMPANY}/profile`,
    COMPANY_DASHBOARD: `${COMPANY}/dashboard`,
    COMPANY_INTERVIEWS: `${COMPANY}/interviews`,
    COMPANY_CONTRACTS: `${COMPANY}/contracts`,
    COMPANY_CONTRACTS_ADD: `${COMPANY}/add-contract`,
    COMPANY_CONTRACTS_EDIT: `${COMPANY}/edit-contract`,
    COMPANY_CONTRACTOR_PROFILE: `${COMPANY}${CONTRACTOR}`,
    COMPANY_RECRUITER_PROFILE: `${COMPANY}/recruiters`,
    COMPANY_PAYMENTS: `${COMPANY}/payments`,
    COMPANY_CONTRACT_TIMESHEETS: `${COMPANY}/contract-timesheets`,
    COMPANY_KYC: `${COMPANY}/kyc`,
    COMPANY_STATISTICS: `${COMPANY}/statistics`,
    COMPANY_VR_BOOKING: `${COMPANY}/vr-booking`,
    SALARY_PREDICTOR: '/salary-predictor',

    ADMIN,
    ADMIN_DASHBOARD: `${ADMIN}/dashboard`,
    ADMIN_OFFERS: `${ADMIN}/offers`,
    ADMIN_CONTRACTS: `${ADMIN}/contracts`,
    ADMIN_CONTRACTORS: `${ADMIN}/contractors`,
    ADMIN_COMPANIES: `${ADMIN}/companies`,
    ADMIN_COMPANIES_ADD: `${ADMIN}/companies-add`,
    ADMIN_CONTRACTS_EDIT: `${ADMIN}/edit-contract`,
    ADMIN_APPROVAL: `${ADMIN}/matches`,
    TESTING_GROUND: `${ADMIN}/testing-ground`,
    ADMIN_RECRUITERS: `${ADMIN}/recruiters`,
    ADMIN_NEOTALENT: `${ADMIN}/neo-talent`,
    ADMIN_NEODASH: `${ADMIN}/neo-dashboard`,
    ADMIN_WEBINAR: `${ADMIN}/neo-webinar`,
    ZOOM_LEAVE: '/leave-zoom',

    RECRUITER,
    RECRUITER_DASHBOARD: `${RECRUITER}/dashboard`,
    RECRUITER_OPPORTUNITY: `${RECRUITER}/opportunity`,
    RECRUITER_COMPANY_PROFILE: `${RECRUITER}/companies`,
    RECRUITER_CANDIDATE_PROFILE: `${RECRUITER}/contractors`,

    ERROR_PAGE: `/404`
};

// export const API_GATEWAY = `https://localhost:5001/api`;
export const API_GATEWAY = process.env.REACT_APP_API_URL;

export const API_PROFILE = `${API_GATEWAY}/Profile`;

export const API_DASHBOARD_CONTRACTOR = `${API_GATEWAY}/DashboardContractor`;
export const API_DASHBOARD_COMPANY = `${API_GATEWAY}/DashboardCompany`;
export const API_DASHBOARD_ADMIN = `${API_GATEWAY}/DashboardAdmin`;
export const API_DASHBOARD_RECRUITER = `${API_GATEWAY}/DashboardRecruiter`;

export const API_DASHBOARD_CLOOD = `${API_GATEWAY}/Clood`;
export const API_DASHBOARD_WILLO = `${API_GATEWAY}/Willo`;

export const FLASK_API = process.env.REACT_APP_FLASK_API_URL;

export const DEFAULT_PICTURE = process.env.REACT_APP_DEFAULT_PICTURE;
export const MAP_DATA = '/mapdata/repd-january-2024.csv';
export const BLOGS = '/blogdata';
