import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, ModalBody, ModalFooter } from 'reactstrap';

import AppInput from '../../../../AppInput';
import CheckboxField from '../../../../CheckboxField';
import CustomFormikError from '../../../../CustomFormikError';
import { DEFAULT_LABELS, FIELDS, OTHER_LABEL } from './validation';
import ClickButton from '../../../../buttons/NudgeButton';


import style from './style.module.scss';

const OtherLabel = ({ form }) => (
    <>
        Other:
        <div className="d-inline-block ml-3">
            <AppInput id={FIELDS.OTHERS} formik={form} label={OTHER_LABEL} onClick={() => form.setFieldValue(FIELDS.REASON, OTHER_LABEL)} />
        </div>
    </>
);

OtherLabel.propTypes = {
    form: PropTypes.object.isRequired,
};

const DismissModalForm = ({ formik, toggle }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <ModalBody className={style.modalBody}>
            <div style={{ display: 'block' }}>
                {DEFAULT_LABELS.map((label) => (
                    <FormGroup key={label} style={{ width: '100%' }}>
                        <Field
                            name={FIELDS.REASON}
                            component={CheckboxField}
                            formik={formik}
                            label={label}
                            className={style.label}
                        />
                    </FormGroup>
                ))}

                <FormGroup>
                    <Field
                        className={style.label}
                        name={FIELDS.REASON}
                        value={OTHER_LABEL}
                        component={CheckboxField}
                        label={<OtherLabel form={formik} />}
                    />
                </FormGroup>

                <FormGroup>
                    <CustomFormikError form={formik} name={FIELDS.REASON} />
                </FormGroup>
            </div>
        </ModalBody>
        <ModalFooter>
            <ClickButton color="success" onClick={toggle} size="lg" className={style.button} type="submit">
                Submit Answer
            </ClickButton>
        </ModalFooter>
    </Form>
);

export default DismissModalForm;
