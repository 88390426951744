import * as Yup from 'yup';

export const FIELDS = {
    TITLE: 'title',
    DESCRIPTION: 'description',
    KEY_SKILLS: 'keySkills',
    PHOTO: 'photo'
};

export const validationSchema = Yup.object({
    [FIELDS.PHOTO]: Yup.string().required('This field is required'),
    [FIELDS.TITLE]: Yup.string().required('This field is required'),
    [FIELDS.DESCRIPTION]: Yup.string().required('This field is required'),
    [FIELDS.KEY_SKILLS]: Yup.array().of(
        Yup.string().required("This field is required")
    ).required('No thanks'),
});


export const formLabels = {
    [FIELDS.PHOTO]: 'Assessment Photo',
    [FIELDS.TITLE]: 'Assessment Title',
    [FIELDS.DESCRIPTION]: 'Assessment Description',
    [FIELDS.KEY_SKILLS]: 'Skills to Assess',
};

export const initialValues = {
    [FIELDS.PHOTO]: '',
    [FIELDS.TITLE]: '',
    [FIELDS.DESCRIPTION]: '',
    [FIELDS.KEY_SKILLS]: [],
};
