import React from 'react';

import { authPostData, authFetcher } from '../../../../api/tools';
import { GetPageTheme, arrToString } from '../../../pages/GenericCompanyProfile/HelperComponents';
import { getStatusDescription } from '../CandidatesToMatch/CandidateSelect';
import { Card, Button } from 'reactstrap';

import { API_DASHBOARD_CLOOD, URLS } from '../../../../consts/urls';
import BreakdownButton from './BreakdownButton';

import style from './style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';

export const UserCard = ({ contractors, buttonFunction, buttonText, offer }) => {
    function processRank(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + 'st';
        }
        if (j == 2 && k != 12) {
            return i + 'nd';
        }
        if (j == 3 && k != 13) {
            return i + 'rd';
        }
        return i + 'th';
    }

    function viewProfile(userId) {
        window.open(`/admin/contractors/${userId}`, '_blank', 'noopener');
    }

    const theme = GetPageTheme();
    return (
        <>
            <Card id={`card-sub-${theme}`} className={style.candidateList}>
                {contractors.map((item, index) => (
                    <Card  className={style.contractorCard}>
                        <h5 className={style.name}>
                            {item.name}
                            {item.score ? ` - ${processRank(item.rank)}` : null}
                        </h5>
                        <h5 className={style.jobTitle}>{`Current/Last Job: ${item.job.name}`}</h5>
                        <h5 className={style.status}>{getStatusDescription(item.matchStatus)}</h5>
                        {item.totalScore__ ? (
                            <h5 className={style.fullScore}>{item.totalScore__.toFixed(1)}%</h5>
                        ) : null}
                        <div className={style.theButtonContainer}>
                            <ClickButton color="success" className={style.theButton} onClick={() => viewProfile(item.id)}>
                                View Profile
                            </ClickButton>
                            {item.score ? <BreakdownButton offer={offer} user={item} /> : null}
                            {item.matchStatus == 'NoMatch' ? (
                                <ClickButton
                                    disabled={item.onMatch}
                                    color={
                                        'success'
                                    }
                                    className={style.theButton}
                                    onClick={() => buttonFunction(item, index)}
                                >
                                    {buttonText}
                                </ClickButton>
                            ) : null}
                        </div>
                    </Card>
                ))}
            </Card>
        </>
    );
};

export default UserCard;
