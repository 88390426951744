import React from 'react';
import { Row, Col } from 'reactstrap';
import style from '../style.module.scss';

import moment from 'moment';
import { GetPageTheme, ResolveSalary } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import {
    arrToString,
    capitaliseFirstLetter,
    ProcessSalary,
} from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { FIELDS } from '../validation';

const OfferSummary = ({ pointers, formik, sectors, pages }) => {
    const values = formik.values;
    const errors = formik.errors;
    const [display, setDisplay] = React.useState(values);

    function reformatDate(date) {
        if (!date) return;

        const split = date.split('-');

        return moment(`${split[1]}-${split[2]}-${split[0]}`).format('MMMM Do YYYY');
    }

    const theme = GetPageTheme();
    const Unfilled = () => theme == 'light' ? <i className={style.errorText}>Empty</i> : <i className={style.errorTextDark}>Empty</i>;

    const sector = sectors.find((p) => p.id == values[FIELDS.SECTOR]);
    const subIndustries = sector?.subIndustries;

    return display ? (
        <div className={style.summary}>
            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[1].label}</h5>

            <div className={style.field}>
                <h5>{pointers[1][FIELDS.NAME][0]}</h5>
                <p>{values[FIELDS.NAME] || <Unfilled />}</p>
            </div>

            <div className={style.field}>
                <h5>{pointers[1][FIELDS.SCOPE][0]}</h5>
                <p>{values[FIELDS.SCOPE] || <Unfilled />}</p>
            </div>

            <Row>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[1][FIELDS.SECTOR][0]}</h5>
                        <p>{sector ? sector?.name : <Unfilled />}</p>
                    </div>
                </Col>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[1][FIELDS.CATEGORY][0]}</h5>
                        <p>
                            {subIndustries ? (
                                subIndustries.find((p) => p.id == values[FIELDS.CATEGORY]).name
                            ) : (
                                <Unfilled />
                            )}
                        </p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[1][FIELDS.LOCATION_SELECT][0]}</h5>
                        <p>{values[FIELDS.LOCATION_SELECT] || <Unfilled />}</p>
                    </div>
                </Col>
                {values[FIELDS.LOCATION_SELECT] !== 'Remote' ? (
                    <Col>
                        <div className={style.field}>
                            <h5>{pointers[1][FIELDS.LOCATION][0]}</h5>
                            <p>{values[FIELDS.LOCATION] || <Unfilled />}</p>
                        </div>
                    </Col>
                ) : null}
            </Row>

            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[2].label}</h5>

            <Row>
                <Col md={4}>
                    <div className={style.field}>
                        <h5>{pointers[2][FIELDS.START][0]}</h5>
                        <p>{reformatDate(values[FIELDS.START]) || <Unfilled />}</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={style.field}>
                        <h5>{pointers[2][FIELDS.PUBLISH_DATE][0]}</h5>
                        <p>{reformatDate(values[FIELDS.PUBLISH_DATE]) || <Unfilled />}</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={style.field}>
                        <h5>{pointers[2][FIELDS.CLOSE_DATE][0]}</h5>
                        <p>{reformatDate(values[FIELDS.CLOSE_DATE]) || <Unfilled />}</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[2][FIELDS.FLEXIBILITY][0]}</h5>
                        <p>{values[FIELDS.FLEXIBILITY] || <Unfilled />}</p>
                    </div>
                </Col>
                {values[FIELDS.FLEXIBILITY] !== 'Flexible' ? (
                    <Col>
                        <div className={style.field}>
                            <h5>Fixed Hours</h5>
                            {values[FIELDS.FIXED_FROM] && values[FIELDS.FIXED_TO] ? (
                                <p>
                                    {values[FIELDS.FIXED_FROM]}-{values[FIELDS.FIXED_TO]}
                                </p>
                            ) : (
                                <Unfilled />
                            )}
                        </div>
                    </Col>
                ) : null}
            </Row>

            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[3].label}</h5>

            <div className={style.field}>
                <h5>{pointers[3][FIELDS.QUAL_REQUIRED][0]}</h5>
                {values[FIELDS.MIN_QUALIFICATIONS] && values[FIELDS.STUDY_FIELD] ? (
                    <p>
                        {values[FIELDS.MIN_QUALIFICATIONS] + ' in ' + values[FIELDS.STUDY_FIELD]}{' '}
                        {values[FIELDS.QUAL_REQUIRED] ? <i>(required)</i> : <i>(desired)</i>}
                    </p>
                ) : (
                    <Unfilled />
                )}
            </div>

            <Row>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[3][FIELDS.SKILLS][0]}</h5>
                        {values[FIELDS.SKILLS].length ? (
                            values[FIELDS.SKILLS].map((item) => (
                                <p>
                                    - {item.name ? item.name : <Unfilled />}{' '}
                                    {item.required ? <i>(required)</i> : <i>(desired)</i>}
                                </p>
                            ))
                        ) : (
                            <Unfilled />
                        )}
                    </div>
                </Col>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[3][FIELDS.CERTIFICATES][0]}</h5>
                        {values[FIELDS.CERTIFICATES].length ? (
                            values[FIELDS.CERTIFICATES].map((item) => (
                                <p>
                                    - {item.name ? item.name : <Unfilled />}{' '}
                                    {item.required ? <i>(required)</i> : <i>(desired)</i>}
                                </p>
                            ))
                        ) : (
                            <Unfilled />
                        )}
                    </div>
                </Col>
            </Row>

            <div className={style.field}>
                <h5>{pointers[3][FIELDS.FILES][0]}</h5>
                {values[FIELDS.FILES].length ? (
                    values[FIELDS.FILES].map((item) => (
                        <p>
                            - {item.name ? item.name : <Unfilled />}{' '}
                            {item.file?.path ? <i>{item.file?.path}</i> : <i>No file attached</i>}
                        </p>
                    ))
                ) : (
                    <Unfilled />
                )}
            </div>

            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[4].label}</h5>

            <Row>
                <Col md={3}>
                    <div className={style.field}>
                        <h5>Video Interview?</h5>
                        <p>{values[FIELDS.MATCH_TYPE] ? 'Yes' : 'No'}</p>
                    </div>
                    <div className={style.field}>
                        <h5>VR Assessment?</h5>
                        <p>{values[FIELDS.HAS_EDIFY] ? 'Yes' : 'No'}</p>
                    </div>
                    <div className={style.field}>
                        <h5>Face-to-Face Interview?</h5>
                        <p>{values[FIELDS.FACE_TO_FACE] ? 'Yes' : 'No'}</p>
                    </div>
                </Col>
                {values[FIELDS.MATCH_TYPE] ? (
                    <Col md={5}>
                        <div className={style.field}>
                            <h5>Questions</h5>
                            {values[FIELDS.QUESTIONS].length ? (
                                values[FIELDS.QUESTIONS].map((item, index) => (
                                    <p>
                                        {index != 0 ? <div className={style.divider} /> : null}-{' '}
                                        {item.question ? item.question : <Unfilled />} | Question Type: {item.type}
                                        <p>
                                            {item.type == 'text' ? (
                                                <>Max Words: {item.max_words != null ? item.max_words : <Unfilled />}</>
                                            ) : item.type == 'audio' || item.type == 'video' ? (
                                                <>
                                                    Thinking Time:{' '}
                                                    {item.thinking_time != null ? item.thinking_time : <Unfilled />} |
                                                    Max Duration:{' '}
                                                    {item.max_duration != null ? item.max_duration : <Unfilled />} | Max
                                                    Retakes:{' '}
                                                    {item.max_retakes != null ? item.max_retakes : <Unfilled />}
                                                </>
                                            ) : null}
                                        </p>
                                    </p>
                                ))
                            ) : (
                                <Unfilled />
                            )}
                        </div>
                    </Col>
                ) : null}

                {values[FIELDS.HAS_EDIFY] ? (
                    <Col md={4}>
                        <div className={style.field}>
                            <h5>Skills to be Assessed</h5>

                            <h5>Self Management</h5>
                            {values[FIELDS.ASSESSMENT_SKILLS].selfManagement.length ? (
                                <>
                                    {values[FIELDS.ASSESSMENT_SKILLS].selfManagement.map((item) => (
                                        <p>- {item}</p>
                                    ))}
                                </>
                            ) : (
                                <Unfilled />
                            )}

                            <h5>Social Intelligence</h5>
                            {values[FIELDS.ASSESSMENT_SKILLS].socialIntelligence.length ? (
                                <>
                                    {values[FIELDS.ASSESSMENT_SKILLS].socialIntelligence.map((item) => (
                                        <p>- {item}</p>
                                    ))}
                                </>
                            ) : (
                                <Unfilled />
                            )}

                            <h5>Innovation</h5>
                            {values[FIELDS.ASSESSMENT_SKILLS].innovation.length ? (
                                <>
                                    {values[FIELDS.ASSESSMENT_SKILLS].innovation.map((item) => (
                                        <p>- {item}</p>
                                    ))}
                                </>
                            ) : (
                                <Unfilled />
                            )}

                            <h5>Discipline</h5>
                            {values[FIELDS.ASSESSMENT_SKILLS].discipline.length ? (
                                <>
                                    {values[FIELDS.ASSESSMENT_SKILLS].discipline.map((item) => (
                                        <p>- {item}</p>
                                    ))}
                                </>
                            ) : (
                                <Unfilled />
                            )}
                        </div>
                    </Col>
                ) : null}
            </Row>

            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[6].label}</h5>

            <div className={style.field}>
                <h5>Weights (for Klara Matching)</h5>
                {Object.keys(values.weights)
                    .filter((i) => i != 'weightsId' && i != 'id')
                    .map((item) => (
                        <p>
                            - {capitaliseFirstLetter(item.replaceAll('_', ' '), 1)} : {values.weights[item]}
                        </p>
                    ))}
            </div>

            <div className={style.divider} />
            <h5 className={style.summaryHeader}>{pointers[5].label}</h5>

            <div className={style.field}>
                <h5>{pointers[5][FIELDS.OFFER_TYPE][0]}</h5>
                <p>{values[FIELDS.OFFER_TYPE] || <Unfilled />}</p>
            </div>

            {values[FIELDS.OFFER_TYPE] != 'Staff' && values[FIELDS.OFFER_TYPE] != '' ? (
                <>
                    <div className={style.field}>
                        <h5>{pointers[5][FIELDS.OUTSIDE][0]}</h5>
                        <p>{values[FIELDS.OUTSIDE] || <Unfilled />}</p>
                    </div>

                    <div className={style.field}>
                        <h5>{pointers[5][FIELDS.DURATION][0]}</h5>
                        <p>{values[FIELDS.DURATION] ? values[FIELDS.DURATION] + ' days' : <Unfilled />}</p>
                    </div>
                </>
            ) : null}

            <div className={style.field}>
                <h5>{pointers[5][FIELDS.TIMING][0]}</h5>
                <p>{values[FIELDS.TIMING] || <Unfilled />}</p>
            </div>

            <Row>
                <Col>
                    <div className={style.field}>
                        <h5>{pointers[5][FIELDS.WORK_TYPE][0]}</h5>
                        <p>{values[FIELDS.WORK_TYPE] || <Unfilled />}</p>
                    </div>
                </Col>
                <Col>
                    {values[FIELDS.WORK_TYPE] != 'Volunteer' && values[FIELDS.WORK_TYPE] != '' ? (
                        <>
                            <div className={style.field}>
                                <h5>{pointers[5][FIELDS.REVENUE][0]}</h5>
                                {values[FIELDS.REVENUEMIN] && values[FIELDS.REVENUEMAX] ? (
                                    <>
                                        <p>
                                            {ResolveSalary(
                                                `${values[FIELDS.REVENUEMIN]}-${values[FIELDS.REVENUEMAX]}`,
                                                values[FIELDS.CURRENCY],
                                                values[FIELDS.REVENUEFREQ],
                                                values[FIELDS.WORK_TYPE]
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <Unfilled />
                                )}
                            </div>
                        </>
                    ) : null}
                </Col>
            </Row>

            <div className={style.field}>
                <h5>{pointers[5][FIELDS.EMPLOYEE_LEVEL][0]}</h5>
                <p>{values[FIELDS.EMPLOYEE_LEVEL] || <Unfilled />}</p>
            </div>

            <div className={style.divider} />
        </div>
    ) : null;
};

export default OfferSummary;
