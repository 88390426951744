import './amplify';

import React, { Suspense } from 'react';
import CookieConsent from 'react-cookie-consent';
import { BrowserRouter } from 'react-router-dom';

import AuthContextWrapper from './contexts/auth/AuthContextWrapper';
import ThemeContextWrapper from './contexts/theme/ThemeContextWrapper';
import DelayedFallback from './pages/Loading';
import AllRoutes from './routing/AllRoutes';
import ToastContextWrapper from './contexts/toast/ToastContext';

import NudgeButton from './components/buttons/NudgeButton';

const App = () => {
    
    try {
        return (
            <ThemeContextWrapper>
                <CookieConsent
                    location="top"
                    cookieName="analyticsCookies"
                    style={{ background: '#407962', zIndex: 10000 }}
                    contentStyle={{ fontWeight: 'bold' }}

                    buttonStyle={{ borderRadius: '0.25rem' }}
                    declineButtonStyle={{ borderRadius: '0.25rem' }}

                    ButtonComponent={NudgeButton}
                    customDeclineButtonProps={{ color: 'danger' }}

                    enableDeclineButton
                    buttonText="Accept"
                    declineButtonText="Decline"
                    expires={150}
                    flipButtons={true}
                    
                    //debug
                >
                    Can you help Nudge improve by allowing impersonal website cookies?
                </CookieConsent>
    
                <ToastContextWrapper>
                    <BrowserRouter>
                        <Suspense fallback={<DelayedFallback />}>
                            <AuthContextWrapper>
                                <AllRoutes />
                            </AuthContextWrapper>
                        </Suspense>
                    </BrowserRouter>
                </ToastContextWrapper>
            </ThemeContextWrapper>
        );
    } catch(e) {
        throw "Error"
    }
};

export default App;
