import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';
import AppInput from '../../../../../../components/AppInput';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../../../components/NudgeModal';

const FeedbackModal = ({ isOpen, toggle, onSend, formik, matchId }) => (
    <NudgeModal headerText={"Refer friend to this Nudge offer?"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
        <ModalBody className={style.modalBody}>
            <div className={style.text}>Type the name and email address of the friend you wish to refer.</div>

            <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-10 ">
                    <Field name={FIELDS.NAME} id={FIELDS.NAME} formik={formik} component={AppInput} label="Name" />
                </FormGroup>
                <FormGroup className="mb-10 ">
                    <Field
                        name={FIELDS.EMAIL}
                        id={FIELDS.EMAIL}
                        formik={formik}
                        component={AppInput}
                        label="Email Address"
                    />
                </FormGroup>
                <br />

                <ClickButton type="submit" color="success" size="lg" className={style.backButton}>
                    Refer
                </ClickButton>

                <ClickButton size="lg" color="danger" onClick={toggle}>
                    Cancel
                </ClickButton>
            </Form>
        </ModalBody>
    </NudgeModal>
);

FeedbackModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default FeedbackModal;
