function ResolveDuration(pos) {
    const position = pos;

    const split = position.duration.split(' ');

    if (split[1].includes('year')) {
        return `${parseInt(split[0])}-${parseInt(split[0]) + 1}`;
    } else {
        return '0-1';
    }
}

export function parseCertificates(certificates) {
    if (!certificates.length) return null;

    var certs = [];
    certificates.forEach((item) => {
        const obj = {
            expireDate: '',
            file: null,
            name: item,
        };
        certs.push(obj);
    });

    return certs;
}

export function parseValues(values) {
    if (!values.length) return null;

    var vals = [];
    values.forEach((item) => {
        const obj = {
            content: item,
        };
        if (obj.content != '') vals.push(obj);
    });

    return vals;
}

export function parseExperience(experience) {
    if (!experience.length) return null;

    var exp = [];
    experience.forEach((item) => {
        if (Object.keys(item).length) {
            if (item.positions?.length) {
                item.positions.forEach((pos) => {
                    const obj = {
                        Name: pos.title,
                        Duration: ResolveDuration(pos),
                        present: pos.enddate == 'Present',
                        company: item.company,
                    };
                    exp.push(obj);
                });
            }
        }
    });

    return exp;
}
