import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { URLS } from '../../consts/urls';
import { AuthContext } from './AuthContextWrapper';

const hasRole = (userRoles, allowedRoles) => allowedRoles.some((role) => userRoles.includes(role));

const AuthProtectedRouter = ({ children, allowedRoles }) => {
    const { isAuth, isAuthenticating, userRoles } = useContext(AuthContext);
    const isRouteAllowed = isAuth && !isAuthenticating && hasRole(userRoles, allowedRoles);

    return isRouteAllowed ? children : <Navigate to={URLS.SIGN_IN} />;
};

AuthProtectedRouter.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.any.isRequired,
};

export const withAuthProtectedRouter = (WrappedComponent, allowedRoles) => (props) => {
    return (
        <AuthProtectedRouter allowedRoles={allowedRoles}>
            <WrappedComponent {...props} />
        </AuthProtectedRouter>
    );
}
