import React, { useEffect, useState } from 'react';
import SearchBox from '../../SearchBox';
import Loader from '../../../components/Loader';
import OffersList, { PER_PAGE } from '../../../components/OffersList';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card } from 'reactstrap';
import { customStyles } from '../../SelectField';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { ADMIN, COMPANY } from '../../../consts/userRoles';
import { URLS } from '../../../consts/urls';
import { ProcessSalary, sort_by_key, FullStatCardScaling, GetPageTheme } from './HelperComponents';

import style from './style.module.scss';
import ClickButton from '../../buttons/NudgeButton';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { getTimeToHireData } from '../../../pages/company/StatisticsTimePerPage/TimeToHireDash';
import { authFetcher } from '../../../api/tools';
import NudgeSwiper from '../../NudgeSwiper';
import useMobile from '../../../hooks/useMobile';

export const GenericCompanyProfile = ({ getUrl, showAddNewContract, offerUrl }) => {
    
    //Current or Archived Switch
    const [isCurrent, setIsCurrent] = useState(true);
    const toggleIsCurrent = () => setIsCurrent(!isCurrent);

    const { user } = React.useContext(AuthContext); 
    var timeout;

    const { isMobileWide } = useMobile();

    //
    const [data, setData] = React.useState();
    const [mutateKey, setMutateKey] = React.useState(false);
    const mutate = () => setMutateKey(!mutateKey);

    React.useEffect(() => {
        setData();
        async function getData() {
            const response = await authFetcher(getUrl(isCurrent));

            if(response) {
                clearTimeout(timeout)
                setData(response);
            } else {
                timeout = setTimeout(() => mutate(), 4000);
            }
        }

        getData();
    }, [mutateKey, isCurrent])
 
    const ascOptions = [
        { label: <><FontAwesomeIcon icon={['far', 'sort-amount-down']}/></>, value: false },
        { label: <><FontAwesomeIcon icon={['far', 'sort-amount-up']}/></>, value: true },
    ]
    const [ascending, setAscending] = React.useState(ascOptions[0]);

    const navigate = useNavigate(); //History React Hook

    //search state variable
    const [search, setSearch] = useState('');

    //Key for re-rendering React
    const [key, setKey] = useState(false);
    const toggleKey = () => setKey(!key);

    //Click to get to add contract page
    const clickAdd = () => navigate(URLS.COMPANY_CONTRACTS_ADD);

    var totalMatches = 0;

    let filterList = [
        { key: 'id', label: 'Date Created', value: 'Date Created', reverse: true },
        { key: 'daysLeft', label: 'Days Remaining', value: 'Days Remaining', reverse: true },
        { key: 'averageSalary', label: 'Average Salary', value: 'Average Salary', reverse: true },
        { key: 'name', label: 'Job Title', value: 'Job Name', reverse: true },
        { key: 'matches', label: 'Matches', value: 'Matches', reverse: true },
        { key: 'offerViews', label: 'Views', value: 'Views', reverse: true },
        { key: 'awardedMatches', label: 'Awarded', value: 'Awarded', reverse: true },
    ];

    //selected filter
    const [selectedFilter, setSelectedFilter] = useState(filterList[0]);

    function filterOffers(offers) {
        let sorted = sort_by_key(offers, selectedFilter.key, ascending?.value);
        
        return !selectedFilter.reverse ? sorted : sorted.reverse();
    }

    if (data) {
        data.forEach((item) => {
            totalMatches += item.matches;
            if (item.workType == 'Volunteer') {
                item.averageSalary = 0;
            }
        });
    }

    useEffect(() => {
        setSearch('');
    }, [selectedFilter]);

    const [statistics, setStatistics] = React.useState(null);
    React.useEffect(() => {
        async function getData() {
            const stat = await getTimeToHireData(() => {}, setStatistics, true);
            
            setStatistics(stat);
        }
        if(data) {
            getData();
        }
    }, [data])

    return (
        <SignInPageTemplate>
            <Row>
                <Col xs={12} sm={12} md={12} lg={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <h5 className={style.flexStatus}>
                        <p style={{ textAlign: 'right' }} className={isCurrent ? style.active : style.inactive}>{'Open'}</p>
                        <FontAwesomeIcon
                            id={`icons-${GetPageTheme()}`}
                            className={data ? style.icon : style.iconInactive}
                            onClick={data ? () => toggleIsCurrent() : null}
                            icon={['fas', data ? 'exchange' : 'sync-alt']}
                        />
                        <p style={{ textAlign: 'left' }} className={!isCurrent ? style.active : style.inactive}>{'Closed'}</p>
                    </h5>
                </Col>
                <Col xs={12} sm={12} md={9} lg={4}>
                    <h5 style={{ textAlign: 'center'}}>Filter by:</h5>
                    <Row>
                        <Col xs={8} sm={7} md={7} lg={8}>
                            <Select
                                styles={customStyles()}
                                options={filterList}
                                defaultValue={filterList[0]}
                                onChange={(option) => {
                                    setSelectedFilter(option);
                                }}
                                placeholder={'Industries'}
                                value={selectedFilter}
                                isSearchable={false}
                            />
                        </Col>
                        <Col xs={4} sm={5} md={5} lg={4}>
                            <Select
                                styles={customStyles()}
                                options={ascOptions}
                                defaultValue={ascOptions[0]}
                                onChange={(option) => {
                                    setAscending(option);
                                }}
                                placeholder={'Ascending?'}
                                value={ascending}
                                isSearchable={false}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={3} lg={2}>
                    <h5 className={`text-center ${style.divideHeader}`}>Post job:</h5>
                    {showAddNewContract ? (
                        <ClickButton className={style.createButton} color="success" onClick={clickAdd}>
                            Post New Job
                        </ClickButton>
                    ) : null}
                </Col>
            </Row>
            <br />

            <Row>
                <Col xs={12} lg={8} className={style.searchBoxContainer}>
                    <SearchBox value={search} setValue={setSearch} disabled={!data} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={8}>
                    { data ? <OffersList
                        search={search}
                        isCurrent={isCurrent}
                        offers={filterOffers(data)}
                        url={offerUrl}
                        process={ProcessSalary}
                        selectedFilter={selectedFilter}
                    /> : <Row>{[...Array(PER_PAGE)].map(() => (
                            <Col xs={12} sm={12} md={6} lg={12}>
                                <Card className={style.emptyOffer}>
                                    <NudgeSwiper gap={`12px`} width={{ desktop: "60%", mobile: "60%" }} height={{ desktop: "33px", mobile: "33px" }} />
                                    <div className={style.swiperBoxContainer}>
                                        { [...Array(PER_PAGE)].map(() => (<NudgeSwiper gap={`6px`} width={{ desktop: "150px", mobile: "100%" }} height={{ desktop: "26px", mobile: "30px" }} />)) }
                                    </div>
                                    <NudgeSwiper gap={`12px`} width={{ desktop: "40%", mobile: "80%" }} height={{ desktop: "25px", mobile: "25px" }} />
                                    <NudgeSwiper gap={`12px`} width={{ desktop: "100%", mobile: "100%" }} height={{ desktop: "90px", mobile: "90px" }} />
                                </Card>
                            </Col>
                        
                    ))}</Row> }
                </Col>
                <Col lg={4}>
                    <Card className={style.companyCard}>
                        <Row>
                            <Col>
                                <FullStatCardScaling
                                    header={`${isCurrent ? `Open/Upcoming` : `Archived/Expired`} Opportunities`}
                                    data={[
                                        { label: 'Permanent', data: data?.filter((item) => item.type === 'Staff').length, loading: !data },
                                        { label: 'Contract', data: data?.filter((item) => item.type !== 'Staff').length, loading: !data },
                                    ]}
                                />
                                <FullStatCardScaling
                                    header={"Filled Opportunities"}
                                    data={[
                                        { label: 'Permanent', data: statistics?.filledOpportunities?.staff, loading: !statistics },
                                        { label: 'Contract', data: statistics?.filledOpportunities?.contract, loading: !statistics },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FullStatCardScaling
                                    header=""
                                    data={[
                                        { label: 'Average Matches', data: statistics?.averageMatches.toFixed(2), loading: !statistics }
                                    ]}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FullStatCardScaling
                                    header=""
                                    data={[
                                        { label: 'Time to Hire', data: statistics?.text || statistics, loading: !statistics }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(GenericCompanyProfile, [ADMIN, COMPANY]);
