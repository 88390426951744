import React from 'react';
import NudgeModal from '../NudgeModal';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TabModal = ({ label, isOpen, toggle, tabs, selected, setTab }) => {
    const theme = GetPageTheme();

    function tabFunction(id) {
        setTab(id);
        toggle();
    }

    function parseOption(selectedLabel) {
        return selectedLabel;
    }

    const TabLabel = ({ children, isSelected }) => {

        return (
            isSelected ? <div>
                <h5 style={{ fontSize: '1rem', margin: 0 }}>{"> "}{ children }{" <"}</h5>
            </div> : <>
                { children }
            </>
        )
    }

    return (
        <NudgeModal id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} centered headerText={label || "Options"}>
            <ModalBody>
                { tabs.map((item, index) => 
                    <div key={`tab-modal-element-${index}`}>
                        <NudgeButton style={{ width: '100%', height: '48px', marginBottom: '6px' }} disabled={selected == item.id} onClick={() => tabFunction(item.id)} color={item.hasError ? "danger" : "success"}>
                            <TabLabel isSelected={selected == item.id}>
                                { parseOption(item.label) } { item.hasError ? <FontAwesomeIcon icon={['fas', 'exclamation-circle']}/> : null }
                            </TabLabel>
                        </NudgeButton>
                    </div>
                )}
            </ModalBody>
            <ModalFooter></ModalFooter>
        </NudgeModal>
    )
}

export default TabModal;