import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';

import { FIELDS } from '../OfferForm/validation';
import SliderComp from '../../../../components/SliderComp';
import { capitaliseFirstLetter } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';

function filterWeight(weight) {
    switch (weight) {
        case 'weightsId':
        case 'id':
            return false;
        default:
            return true;
    }
}

const CloseModal = ({ formik, toggle }) => {
    const increment = 1;

    const minValue = 0;
    const maxValue = 5;

    const attributes = Object.keys(formik.values.weights).filter((i) => filterWeight(i));

    return (<>
        <h5 className={style.header}>Adjust matching model weights.</h5>
        <div className={style.modal}>
            <h5 className={style.sliderHeader}>
                On a scale of {minValue} to {maxValue}, how important are these factors to this job? Our
                Klara matching model uses these values to find just the right candidate.
            </h5>

            {attributes.map((item, index) => (
                <Row className={style.row}>
                    <Col xs={12} md={12}>
                        <h5 className={style.sliderLabel}>{capitaliseFirstLetter(item.replaceAll('_', ' '), 1)}</h5>
                        <SliderComp
                            form={formik}
                            field={FIELDS.WEIGHTS}
                            name={item}
                            minValue={minValue}
                            maxValue={maxValue}
                            step={increment}
                        />
                    </Col>
                </Row>
            ))}
        </div>
    </>);
};
CloseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CloseModal;
