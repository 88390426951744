import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { exFetcher, NEVER_REFRESH } from '../../../../api/tools';
import adzunaLogo from '../../../../assets/images/adzunaLogo.png';
import { URLS } from '../../../../consts/urls';
import AppPagination, { calculateBounds, UseAppPagination } from '../../../AppPagination';
import CloseModal from '../../../ContractorDashJobReel/CloseModal';
import NudgeBoxes from '../../../NudgeBoxes';
import { ContainerCard, ResolveDaysRemaining, ResolveSalary, sort_by_key } from '../../GenericCompanyProfile/HelperComponents';
import style from '../style.module.scss';
import NudgeTooltip from '../../../NudgeTooltip';
import useMobile from '../../../../hooks/useMobile';

const PER_PAGE = 5;
const PER_PAGE_MOBILE = 4;

const APP_ID = 'de0a2d37';
const APP_KEY = '6b89e573b8421debfe1173daae90a3b6';

function getTypeInfo(input) {
    if (!input) return 'N/A';

    const capital = input.toUpperCase();

    switch (capital) {
        case 'STAFF':
        case 'PERMANENT':
            return 'Permanent, full-time';
        case 'FREELANCE':
        case 'PAYE':
        case 'CONTRACT':
            return 'Contract Work';
        default:
            return 'N/A';
    }
}

function normalizeCompanyName(companyName) {
    if (!companyName) return false;

    return companyName
        .toLowerCase()
        .replaceAll(
            // eslint-disable-next-line max-len
            /[^\w\s]|ltd|limited|plc|inc|llc|uk|energy|renewables?|wind|solar|hydro|gas|electric|power|oil|exploration|drilling|operations/g,
            '',
        )
        .replaceAll(/\s+/g, '');
}

export const OfferList = ({ offers, userRoles, companyName }) => {
    const navigate = useNavigate();

    const params = new URLSearchParams({
        app_id: APP_ID,
        app_key: APP_KEY,
        results_per_page: 100,
        what: companyName,
    });

    const { isMobileWide } = useMobile();

    const { data } = useSWR(offers?.length ? `` : `https://api.adzuna.com/v1/api/jobs/gb/search/1?${params}`, exFetcher, NEVER_REFRESH);

    if (offers.length === 0) {
        if (data && data.results && data.results.length > 0) {
            for (let i = 0; i < data.results.length; i++) {
                let offer = data.results[i];
                let offerCompany = normalizeCompanyName(offer.company.display_name);
                let profileCompany = normalizeCompanyName(companyName);
                if (offer && offer.id && offerCompany === profileCompany) {
                    offers.push({
                        id: offer.id,
                        name: offer.title,
                        workType: 'Paid Work',
                        frequency: 'Year',
                        rangeExpected: `${Math.round(offer.salary_min)}-${Math.round(offer.salary_max)}`,
                        currencyType: 'GBP',
                        type: offer.contract_type,
                        redirect_url: offer.redirect_url,
                        location: offer.location.display_name.replace(/, UK$/gm, ''),
                        isAdzuna: true,
                    });
                }
            }
        }
    }
    const PER_PAGE_CHANGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE;

    const total = Math.ceil(offers.length / PER_PAGE_CHANGE);
    const { page, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE_CHANGE);
    const offersToDisplay = [...sort_by_key(offers, 'daysLeft', 0)].slice(start, end);
    const [adzunaModalOpen, setAdzunaModalOpen] = React.useState(false);
    const toggleAdzuna = () => setAdzunaModalOpen(!adzunaModalOpen);

    const [redirect, setRedirect] = React.useState('');

    const handleOfferClick = (offer) => {
        if (offer.isAdzuna) {
            setRedirect(offer.redirect_url);
            toggleAdzuna();
        } else {
            var titleLink = '';
            if (userRoles) {
                if (userRoles.includes('Admin')) titleLink += URLS.ADMIN;
                if (userRoles.includes('Company')) titleLink += URLS.COMPANY;
                titleLink += URLS.OFFERS;
            }

            navigate(`${titleLink}/${offer.id}`);
        }
    };

    return (
        <div>
            {!isMobileWide ? <h5 className={style.header}>
                <NudgeTooltip label={"Current Positions"} large>
                    { !offers.length ? "These jobs have been sourced through Adzuna and may not be from the company this page belongs to." : null }
                </NudgeTooltip>
            </h5> : null }
            
            <div className={style.offerContainer}>
                {data ? (
                    offers.length < 1 ? (
                        <ContainerCard header={'No Offers Available'} />
                    ) : null
                ) : !offers.length ? (
                    <ContainerCard header={'Loading offers...'} />
                ) : null}
                <Row>
                    {offersToDisplay.map((item) => (
                        <Col xs={12} sm={6} md={12} lg={12}>
                            <Card className={style.offer} onClick={() => handleOfferClick(item)} key={item.id}>
                                {item.isAdzuna ? (
                                    <CloseModal isOpen={adzunaModalOpen} toggle={toggleAdzuna} redirectUrl={redirect} />
                                ) : null}
                                <Row>
                                    <Col>
                                        <div className={style.jobTitle}>
                                            <h5 className={style.nameLink}>{item.name}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NudgeBoxes
                                            data={[
                                                { label: item.location || 'Remote', danger: false},
                                                { label: getTypeInfo(item.type) || 'TBD', danger: false},
                                                { label: item.workType !== 'Volunteer' ? ResolveSalary(item.rangeExpected, item.currencyType, item.frequency, item.workType) : 'Volunteer Position', danger: false},
                                                !item.isAdzuna ? ResolveDaysRemaining(item) : ''
                                            ]}
                                        />
                                    </Col>
                                </Row>


                                {item.isAdzuna ? <img src={adzunaLogo} className={style.jobItemLogo} alt="card-logo" /> : null}
                            </Card>
                        </Col>
                    ))}
                    { offers.length > 0 ? [...Array(PER_PAGE_CHANGE - offersToDisplay.length)].map((offer, index) =>
                        <Col xs={12} sm={6} md={12} lg={12}>
                            <Card key={`emptyOffer-${index}`} className={style.emptyOffer} />
                        </Col>
                    ) : null}
                </Row>

                {offers.length > PER_PAGE ? (
                    <AppPagination
                        total={total}
                        page={page}
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                        onClickPage={onClickPage}
                        onClickFirst={onClickFirst}
                        onClickLast={onClickLast}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default OfferList;
