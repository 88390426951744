import PropTypes from 'prop-types';
import React from 'react';

import SuccessModal from '../../../../components/modals/SuccessModal';

const ContactBody = () => <b>To contact the candidate, use the chat window, accessible through the interview page or the kanban board.</b>;

const ContactPopup = ({ isOpen, toggle }) => (
    <SuccessModal isOpen={isOpen} toggle={toggle} header="Contact with candidate" body={<ContactBody />} />
);

ContactPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default ContactPopup;
