import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { authPostData } from '../../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import GenericModal from '../../../components/modals/GenericModal';

import StepsPopup from './StepsPopup';
import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';

const MatchButton = ({ offerId, contractorId, refresh }) => {
    const [sending, setSending] = React.useState(false);

    async function inviteToMatch() {
        setSending(true);

        var url = `${API_DASHBOARD_COMPANY}/Offers/${offerId}/Match/${contractorId}`;
        var response = await authPostData({}, url);
        await refresh();

        setSending(false);
    }

    return (
        <>
            <ClickButton
                size="lg"
                color={'success'}
                disabled={sending}
                className={style.btn}
                onClick={inviteToMatch}
            >
                Invite to Apply
            </ClickButton>
        </>
    );
};

export default MatchButton;
