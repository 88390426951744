import { Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { useAlert } from '../../../components/Alert';
import { FORM_COLUMNS } from '../../../components/forms/SignInForm';
import Loader, { useLoader } from '../../../components/Loader';
import { URLS } from '../../../consts/urls';
import { forgotPasswordSubmit } from '../../../contexts/auth/tools';
import ForgotPasswordPageWrapper from '../ForgotPasswordPageWrapper';
import ForgotPasswordEmailForm from './ForgotPasswordEmailForm';
import { initialValues, validationSchema } from './validation';

const ForgotPasswordNewPasswordPage = () => {
    const navigate = useNavigate();
    const { email } = useParams();
    const { isLoading, setIsLoading } = useLoader();
    const { error, onCloseAlert, handleError } = useAlert();

    const onSubmit = async ({ code, newPassword }) => {
        setIsLoading(true);
        onCloseAlert();

        try {
            await forgotPasswordSubmit(email, code, newPassword);
            navigate(URLS.SIGN_IN);
        } catch (e) {
            e.success = false;
            handleError(e);
            if (e.message.includes('PostConfirmation')) navigate(URLS.SIGN_IN);
        } finally {
            setIsLoading(false);
        }
    };

    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    return (
        <ForgotPasswordPageWrapper>
            <Row>
                <Col {...FORM_COLUMNS}>
                    <Alert color="danger" isOpen={!!error} toggle={onCloseAlert}>
                        {error}
                    </Alert>
                </Col>
            </Row>

            {isLoading ? (
                <Loader />
            ) : (
                <Formik {...props}>{(formik) => <ForgotPasswordEmailForm formik={formik} email={email} />}</Formik>
            )}
        </ForgotPasswordPageWrapper>
    );
};

export default ForgotPasswordNewPasswordPage;
