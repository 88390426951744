import React from 'react';

const NudgeError = ({ formik, index, field, name }) => {
    var errorObj = formik?.errors[field];

    return (
        <h5 style={{ color: 'red', marginTop: '6px', fontSize: '16px' }}>{errorObj && formik.submitCount ? errorObj[index] ? errorObj[index][name] : null : null}</h5>
    )
}

export default NudgeError;

