import useSWR from 'swr';
import { authFetcher } from '../../../../api/tools';
import { API_PROFILE } from '../../../../consts/urls';

import { sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

export const IndustryData = () => {
    const { data } = useSWR(`${API_PROFILE}/Industries`, authFetcher, { refreshInterval: 0 });

    if (data) {
        data.forEach((item) => {
            item.label = item.name;
            item.value = item.name;

            item.subIndustries = item.subIndustries.filter((word) => !word.name.includes('&'));

            sort_by_key(item.subIndustries, 'id').forEach((item2) => {
                item2.label = item2.name;
                item2.value = item2.name;
            });
        });
    }

    return data;
};
