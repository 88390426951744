/* eslint-disable quotes */
import moment from 'moment';
import React from 'react';

import style from './style.module.scss';

export const MESSAGES_PER_PAGE = 10;

export function getHeader(data) {
    var header = '';

    switch (data.status) {
        case 'InterviewSubmitted':
            header = 'The user has submitted their video interview.';
            break;
        case 'CompanyAccepted':
            header = 'You have accepted the candidate for this position.';
            break;
        case 'CompanyRejected':
            header = 'You have rejected this candidate for this position.';
            break;
        case 'Rejected':
            header = 'This candidate has dismissed this position. See their feedback below:';
            break;
        case 'WilloInvited':
            header = 'Waiting for the user to complete their video interview.';
            break;
        case 'FaceInterviewScheduled':
            header = 'Face-to-face interview scheduled.';
            break;
        case 'VRAssessmentScheduled':
            header = 'VR assessment scheduled.';
            break;
        case 'VRAssessmentSubmitted':
            header = 'VR assessment results have been submitted.';
            break;
        case 'Negotiation':
            header = 'Salary Negotiation';
            break;
        case 'Applied':
            header = 'Application received.';
            break;
        default:
            header = null;
            break;
    }

    return <h5 className={style.header}>{header}</h5>;
}

export function getSubtitle(data) {
    var subtitle = '';

    var vrPast = moment(data.vrAssessmentDate).isBefore(moment());
    var facePast = moment(data.faceInterviewDate).isBefore(moment());

    switch (data.status) {
        case 'FaceInterviewScheduled':
            subtitle = `Interview date ${facePast ? 'was' : 'is'} ${moment(data.faceInterviewDate)
                .local()
                .format('MMMM Do YYYY [at] h:mm a')}`;
            break;
        case 'VRAssessmentScheduled':
            subtitle = `VR assessment date ${vrPast ? 'was' : 'is'} ${moment(data.vrAssessmentDate)
                .local()
                .format('MMMM Do YYYY [at] h:mm a')}`;
            break;
        case 'VRAssessmentSubmitted':
            subtitle = "View the results using the 'VR Assessment Results' tab below.";
            break;
        case 'WilloInvited':
            subtitle =
                data.bid.status !== 'Accepted'
                    ? 'While you wait, why not negotiate remuneration using the chat box on the right?'
                    : null;
            break;
        case 'InterviewSubmitted':
            subtitle = "See their responses using the 'Video Answers' tab below.";
            break;
        case 'Negotiation':
            subtitle = "This interview contains no additional interview features. Negotiate remuneration using the chat box on the right."
            break;
        default:
            subtitle = null;
            break;
    }

    return <h5 className={style.subtitle}>{subtitle}</h5>;
}

export function canProgress(data) {
    switch (data.status) {
        case 'InterviewSubmitted':
            if (data.hasEdify) {
                return true;
            } else if (data.hasFaceToFace) {
                return true;
            }
            return false;
        case 'WaitingOnVRSchedule':
            return true;
        case 'WaitingOnFaceSchedule':
            return true;
        case 'VRAssessmentSubmitted':
            return data.hasFaceToFace ? (data.broadcastResults ? true : false) : false;
        default:
            return false;
    }
}

export function isFinalStage(data) {
    const { status, hasEdify: edify, hasFaceToFace: face, broadcastResults } = data;

    if (status === 'InterviewSubmitted') {
        if (edify || face) {
            return false;
        } else {
            return true;
        }
    } else if (status === 'VRAssessmentScheduled' || status == 'VRAssessmentSubmitted') {
        if (face) {
            return false;
        } else {
            return broadcastResults ? true : false;
        }
    } else if (status === 'FaceInterviewScheduled') {
        return moment(data.faceInterviewDate).isBefore(moment()) ? true : false;
    } else if (status === 'Negotiation') {
        return true;
    }
}

export function getButtonText(data) {
    switch (data.status) {
        case 'WaitingOnVRSchedule':
            return 'Schedule VR Assessment';
        case 'WaitingOnFaceSchedule':
            return 'Schedule Face-to-Face';
        case 'InterviewSubmitted':
            return data.hasEdify ? 'Progress to VR' : 'Progress to Face-to-Face';
        case 'VRAssessmentScheduled':
            return data.hasFaceToFace ? 'Progress to Face-to-Face' : '';
        default:
            return 'Progress Candidate';
    }
}
