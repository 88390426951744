import React, { useState } from 'react';
import { Card, Col, Row, Tooltip } from 'reactstrap';
import Status from '../Status';
import CompanyProjectForm from '../../../forms/CompanyProjectForm';
import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import AppPagination, { UseAppPagination, calculateBounds } from '../../../AppPagination';
import { ContainerCard } from '../../GenericCompanyProfile/HelperComponents';

import style from '../style.module.scss';
import NudgeTooltip from '../../../NudgeTooltip';
import NudgeButton from '../../../buttons/NudgeButton';

const PER_PAGE = 2;

export const ProjectList = ({ data, user, userRoles, showEdit, mutate, id, mobile }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };


    const [projectAdd, setProjectAdd] = useState(false);

    if (data.companyProjects === null) {
        data.companyProjects = [];
    }

    const total = Math.ceil(data.companyProjects.length / PER_PAGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);

    const projectsToDisplay = [...data.companyProjects].slice(start, end); 

    return (
        <div style={{ minHeight: '920px' }}>
            <h5 className={style.header}>
                <Row style={{ marginBottom: '12px' }}>
                    <Col xs={8} sm={6} md={12} lg={6}>
                        <div className="mb-10">
                            <NudgeTooltip label={'Upcoming Projects'} id={'upcomingProjects'} large>
                                    Upcoming projects are projects that aren't yet confirmed but are used to connect interested parties with
                                    those potential projects. It does not mean the projects will go ahead but it does mean you can express
                                    interest should it progress. Only the company will be able to see you have registered interest, not
                                    other candidates.
                            </NudgeTooltip>
                        </div>
                    </Col>
                    <Col xs={4} sm={6} md={12} lg={6}>
                        {showEdit ? (
                            !projectAdd ? (
                                <NudgeButton color="success" className={style.addButton} onClick={() => setProjectAdd(true)}>
                                    Add
                                </NudgeButton>
                            ) : null
                        ) : null}
                    </Col>
                </Row>
            </h5>

            <div className={style.offerContainer}>
                {data.companyProjects.length < 1 ? <ContainerCard header={'No Upcoming Projects'} /> : null}
                {projectAdd ? (
                    <Card className={style.project}>
                        <CompanyProjectForm
                            url={`${API_DASHBOARD_COMPANY}/CompanyProject/${id}`}
                            id={data.id}
                            refresh={mutate}
                            userTypes={userRoles}
                            showEdit={showEdit}
                            onClickClose={setProjectAdd}
                            canClose={true}
                            userId={user.id}
                        />
                    </Card>
                ) : null}
                {!projectAdd ? projectsToDisplay.map((item, index) => (
                    <Card className={style.project}>
                        <CompanyProjectForm
                            url={`${API_DASHBOARD_COMPANY}/CompanyProject/${id}`}
                            id={data.id}
                            refresh={mutate}
                            info={item}
                            userTypes={userRoles}
                            showEdit={showEdit}
                            canClose={false}
                            userId={user.id}
                            page={page}
                        />
                    </Card>
                    ))
                : null}
                {data.companyProjects.length > 0 && !projectAdd ? [...Array(PER_PAGE - projectsToDisplay.length)].map(() => 
                    <Card className={style.emptyProject} />
                ) : null }
            </div>
            {data.companyProjects.length > PER_PAGE && !projectAdd ? (
                <AppPagination
                    total={total}
                    page={page}
                    onClickNext={onClickNext}
                    onClickPrev={onClickPrev}
                    onClickPage={onClickPage}
                    onClickFirst={onClickFirst}
                    onClickLast={onClickLast}
                    inBuffer={mobile ? 1 : 2}
                />
            ) : null}
        </div>
    );
};

export default ProjectList;
