import React from 'react';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { Row, Col } from 'reactstrap';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import DateSelector from './DateSelector';
import CalendarCard from './CalendarCard';

import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { authFetcher, fetcher, fetcherWithAuthorizationHeader, NO_REFRESH_INTERVAL } from '../../../api/tools';
import useSWR from 'swr';

import SimpleChatCard from '../../../components/SimpleChatCard';

import { RE_LIMIT } from '../ZoomPocPage/NoMeetingActiveCard';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';

import { validationSchema, initialValues } from './TimeForm/validate';

const WILLO_KEY = 'rflafwr3v5bqm4r19ytfuutnjjbcs1o13004';

const NewCalendar = () => {
    const [selectedDate, setSelectedDate] = React.useState();

    const { id } = useParams();

    const { data } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/InterviewPlanner/${id}/Dates`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );
    const { data: bidding, mutate } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/Matches/${id}`,
        authFetcher,
        NO_REFRESH_INTERVAL,
    );

    async function onSubmit(v) {
        const key = bidding.interviewKey;
        const url = `https://api.willotalent.com/api/integrations/v2/interviews/${key}/`;
        const response = await fetcherWithAuthorizationHeader(url, WILLO_KEY);
    }

    function resolveCanSchedule(bid) {
        if (window.location.href.includes('reschedule')) {
            if (bid.re_count < RE_LIMIT) {
                return true;
            } else {
                return false;
            }
        } else {
            if (bid.meetingDate != '') {
                return false;
            } else {
                return true;
            }
        }
    }

    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    const [willoData, setVideoData] = React.useState();
    React.useEffect(() => {
        async function doData() {
            const key = bidding.interviewKey;
            const url = `https://api.willotalent.com/api/integrations/v2/interviews/${key}/`;
            const response = await fetcherWithAuthorizationHeader(url, WILLO_KEY);

            setVideoData(response);
        }

        if (bidding) doData();
    }, [bidding]);

    return (
        <SignInPageTemplate>
            {data && bidding ? (
                <Row>
                    <Col md={8}>
                        {resolveCanSchedule(bidding) ? (
                            <CalendarCard
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                recount={bidding.re_count}
                                data={data}
                                props={props}
                                id={id}
                            />
                        ) : null}
                    </Col>
                    <Col md={4}>
                        <SimpleChatCard
                            id={id}
                            mutate={mutate}
                            bidding={bidding.bid}
                            frequency={bidding.frequency}
                            chat={bidding.chat}
                            afterSendAction={mutate}
                        />
                    </Col>
                    {willoData ? <a href={willoData.invite_link}>Interview Link</a> : null}
                </Row>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(NewCalendar, [USER]);
