import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'name',
    START: 'startDate',
    SKILLS: 'skills',
    QUESTIONS: 'questions',
    CERTIFICATES: 'certificates',
    OUTSIDE: 'outside',
    SCOPE: 'scope',
    LOCATION: 'location',
    DURATION: 'duration',

    REVENUE: 'revenue',
    REVENUEMAX: 'revenueMax',
    REVENUEMIN: 'revenueMin',
    REVENUEFREQ: 'salaryFreq',

    CURRENCY: 'currencyType',
    REVENUE_FIXED: 'salaryFixed',

    REVIEW: 'review',
    MATCH_TYPE: 'hasInterview',
    HAS_EDIFY: 'hasEdify',
    FACE_TO_FACE: 'faceToFace',

    FILES: 'files',
    CLOSE_DATE: 'validTo',
    PUBLISH_DATE: 'validFrom',
    LOCATION_SELECT: 'locationType',
    MIN_QUALIFICATIONS: 'qualification',
    STUDY_FIELD: 'studyField',
    QUAL_REQUIRED: 'qualificationRequired',

    OFFER_TYPE: 'offerType',
    
    SECTOR: 'sector',
    CATEGORY: 'subIndustryId',

    FLEXIBILITY: 'flexibility',
    FLEXIBLE_HOURS: 'hoursFlexible',
    FLEXIBLE_PERIOD: 'periodFlexible',
    FLEXIBLE_OPTIONAL: 'flexibleOptional',

    FIXED_FROM: 'fixedFrom',
    FIXED_TO: 'fixedTo',

    HOURS_FROM: 'hoursFrom',
    HOURS_TO: 'hoursTo',
    MINUTES_FROM: 'minutesFrom',
    MINUTES_TO: 'minutesTo',

    TIMING: 'Timing',
    WORK_TYPE: 'workType',
    EMPLOYEE_LEVEL: 'employeeLevel',
    WEIGHTS: 'weights',
    SCHEDULING: 'scheduling',

    ASSESSMENT_SKILLS: 'assessmentSkills',
    CONFIRM: 'confirmId',
    INTERNAL: 'internalExternal'
};

export const VALUE_TO_VALIDATE_DURATION = 'Staff';
export const VALUE_TO_SHOW_LOCATION_INPUT = 'On location';
export const VALUE_TO_VALIDATE_PUBLISH_DATE = 'NOW';

export function getValidationSchema(isEdit) {
    return Yup.object({
        [FIELDS.NAME]: Yup.string().required('This field is required'),
        [FIELDS.OUTSIDE]: Yup.string().when(FIELDS.OFFER_TYPE, {
            is: (label) => label !== VALUE_TO_VALIDATE_DURATION,
            then: Yup.string().required('This field is required'),
        }),
        [FIELDS.OFFER_TYPE]: Yup.string().required('This field is required'),
        [FIELDS.SCOPE]: Yup.string().required('This field is required'),
        [FIELDS.MATCH_TYPE]: Yup.string().required('This field is required'),
        [FIELDS.HAS_EDIFY]: Yup.string().required('This field is required'),
        [FIELDS.FACE_TO_FACE]: Yup.string().required('This field is required'),

        [FIELDS.LOCATION_SELECT]: Yup.string().required('This field is required'),
        [FIELDS.LOCATION]: Yup.string().when(FIELDS.LOCATION_SELECT, {
            is: (label) => label === VALUE_TO_SHOW_LOCATION_INPUT || label === 'BOTH',
            then: Yup.string().required('This field is required'),
        }),
        [FIELDS.DURATION]: Yup.string().when(FIELDS.OFFER_TYPE, {
            is: (label) => label !== VALUE_TO_VALIDATE_DURATION,
            then: Yup.string()
                .matches(/^[0-9]+$/, 'This field should be a number')
                .required('This field is required'),
        }),
        [FIELDS.START]: Yup.string().required('This field is required'),
        [FIELDS.FILES]: Yup.array().when({
            is: (data) => data.length > 0,
            then: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required('This field is required!'),
                    file: Yup.mixed().required('Upload is required!'),
                }),
            ),
        }),
        [FIELDS.SKILLS]: Yup.array().when({
            is: (data) => data.length > 0,
            then: Yup.array()
                .of(
                    Yup.object()
                        .shape({
                            name: Yup.string()
                                .test('len', 'Skill description must be between 1 and 50 characters. ', (val) =>
                                    val ? val.length <= 50 : null,
                                )
                                .required('This field is required.'),
                            required: Yup.boolean().required('This field is required.'),
                        })
                        .required('No thanks'),
                )
                .required('No thanks'),
        }),
        [FIELDS.CLOSE_DATE]: Yup.string().required('This field is required'),
        [FIELDS.PUBLISH_DATE]: Yup.string().when(FIELDS.SCHEDULING, {
            is: (label) => label !== VALUE_TO_VALIDATE_PUBLISH_DATE,
            then: Yup.string().required('This field is required'),
        }),

        [FIELDS.SECTOR]: !isEdit ? Yup.string().required('This field is required') : null,
        [FIELDS.CATEGORY]: !isEdit ? Yup.string().required('This field is required') : null,

        [FIELDS.FLEXIBILITY]: Yup.string().required('This field is required'),
        [FIELDS.QUESTIONS]: Yup.array().when(FIELDS.MATCH_TYPE, {
            is: (data) => data == 'true',
            then: Yup.array().of(
                Yup.object().shape({
                    question: Yup.string().required('This field is required.'),
                    type: Yup.string().required('This field is required.'),
                    thinking_time: Yup.number()
                        .typeError('Must be a number.')
                        .required('This field is required.')
                        .min(1, 'Minimum thinking time is 1 second.'),
                    max_retakes: Yup.number()
                        .typeError('Must be a number.')
                        .required('This field is required.')
                        .min(1, 'Minimum retakes is 1.'),
                    max_duration: Yup.number()
                        .typeError('Must be a number.')
                        .required('This field is required.')
                        .min(15, 'Minimum duration is 15 seconds.'),
                    max_words: Yup.number()
                        .typeError('Must be a number.')
                        .required('This field is required.')
                        .min(15, 'Minimum is 15 words.'),
                }),
            ).min(1, 'Must have at least one question.').required('Must have at least one question.'),
        }),
        
        [FIELDS.CONFIRM]: Yup.string().when(FIELDS.MATCH_TYPE, {
            is: (data) => data == "true",
            then: Yup.string().when(FIELDS.QUESTIONS, {
                is: (data2) => data2?.map((item) => item.type).includes("idv"),
                then: Yup.string().required("Please confirm you understand.")
            })
        }),

        [FIELDS.CERTIFICATES]: Yup.array().when({
            is: (data) => data.length > 0,
            then: Yup.array()
                .of(
                    Yup.object()
                        .shape({
                            name: Yup.string()
                                .test('len', 'Certificate description must be between 1 and 50 characters. ', (val) =>
                                    val ? val.length <= 50 : null,
                                )
                                .required('This field is required.'),
                            required: Yup.boolean().required('This field is required.'),
                        })
                        .required('No thanks'),
                )
                .required('No thanks'),
        }),
        [FIELDS.MIN_QUALIFICATIONS]: Yup.string().required('This field is required'),
        [FIELDS.STUDY_FIELD]: Yup.string().required('This field is required'),
        [FIELDS.REVENUEMIN]: Yup.number().when(FIELDS.WORK_TYPE, {
            is: (label) => label == 'Paid Work',
            then: Yup.number()
                .typeError('Input must be a number.')
                .max(Yup.ref(FIELDS.REVENUEMAX), 'Min must be less than or equal to min.'),
        }),
        [FIELDS.REVENUEMAX]: Yup.number().when(FIELDS.WORK_TYPE, {
            is: (label) => label == 'Paid Work',
            then: Yup.number()
                .typeError('Input must be a number.')
                .min(Yup.ref(FIELDS.REVENUEMIN), 'Max must be greater than or equal to min.'),
        }),
        [FIELDS.HOURS_FROM]: Yup.number().when(FIELDS.FLEXIBILITY, {
            is: (label) => label === 'Fixed',
            then: Yup.number()
                .typeError('Input must be a number between 0 and 23.')
                .min(0, 'Input must be greater than or equal to 0.')
                .max(23, 'Input must be less than 24.')
                .required('This field is required.'),
        }),
        [FIELDS.HOURS_TO]: Yup.number().when(FIELDS.FLEXIBILITY, {
            is: (label) => label === 'Fixed',
            then: Yup.number()
                .typeError('Input must be a number between 0 and 23.')
                .min(0, 'Input must be greater than or equal to 0.')
                .max(23, 'Input must be less than 24.')
                .moreThan(Yup.ref('hoursFrom'), 'Hours To must be greater than Hours From.')
                .required('This field is required.'),
        }),

        [FIELDS.MINUTES_FROM]: Yup.number().when(FIELDS.FLEXIBILITY, {
            is: (label) => label === 'Fixed',
            then: Yup.number()
                .typeError('Input must be a number between 0 and 59.')
                .min(0, 'Input must be greater than or equal to 0.')
                .max(59, 'Input must be less than 60.')
                .required('This field is required.'),
        }),
        [FIELDS.MINUTES_TO]: Yup.number().when(FIELDS.FLEXIBILITY, {
            is: (label) => label === 'Fixed',
            then: Yup.number()
                .typeError('Input must be a number between 0 and 59.')
                .min(0, 'Input must be greater than or equal to 0.')
                .max(59, 'Input must be less than 60.')
                .required('This field is required.'),
        }),
        [FIELDS.EMPLOYEE_LEVEL]: Yup.string().required('This field is required'),
        [FIELDS.TIMING]: Yup.string().required('This field is required'),
        [FIELDS.WORK_TYPE]: Yup.string().required('This field is required'),
    });
}

export const initialObject = {
    [FIELDS.SKILLS]: { name: '', required: false },
    [FIELDS.CERTIFICATES]: { name: '', required: false },
    [FIELDS.QUESTIONS]: {
        id: 0,
        question: '',
        type: 'text',
        thinking_time: 30,
        max_words: 75,
        max_duration: 60,
        max_retakes: 5,
        location_of_employment: 'GBR'
    },
};

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.START]: '',
    [FIELDS.OUTSIDE]: '',
    [FIELDS.OFFER_TYPE]: '',
    [FIELDS.SCOPE]: '',
    [FIELDS.LOCATION]: '',
    [FIELDS.DURATION]: '',
    [FIELDS.REVENUEMIN]: '',
    [FIELDS.REVENUEMAX]: '',
    [FIELDS.REVENUEFREQ]: '',
    [FIELDS.SKILLS]: [{ name: '', required: false }],
    [FIELDS.QUESTIONS]: [],
    [FIELDS.CERTIFICATES]: [{ name: '', required: false }],
    [FIELDS.FILES]: [],
    [FIELDS.CLOSE_DATE]: '',
    [FIELDS.PUBLISH_DATE]: '',
    [FIELDS.LOCATION_SELECT]: '',
    [FIELDS.MIN_QUALIFICATIONS]: '',
    [FIELDS.STUDY_FIELD]: '',
    [FIELDS.QUAL_REQUIRED]: false,

    [FIELDS.REVIEW]: '',

    [FIELDS.MATCH_TYPE]: false,
    [FIELDS.HAS_EDIFY]: false,
    [FIELDS.FACE_TO_FACE]: false,

    [FIELDS.CURRENCY]: 'GBP',
    [FIELDS.REVENUE_FIXED]: 'Variable',

    [FIELDS.FLEXIBILITY]: '',
    [FIELDS.FLEXIBLE_HOURS]: '',
    [FIELDS.FLEXIBLE_PERIOD]: '',
    [FIELDS.FLEXIBLE_OPTIONAL]: true,

    [FIELDS.HOURS_FROM]: '',
    [FIELDS.HOURS_TO]: '',
    [FIELDS.MINUTES_FROM]: '',
    [FIELDS.MINUTES_TO]: '',

    [FIELDS.FIXED_FROM]: '',
    [FIELDS.FIXED_TO]: '',

    [FIELDS.TIMING]: '',
    [FIELDS.WORK_TYPE]: '',
    [FIELDS.EMPLOYEE_LEVEL]: '',
    [FIELDS.SCHEDULING]: '',

    [FIELDS.CONFIRM]: '',

    [FIELDS.WEIGHTS]: {
        weightsId: 0,
        services_required: 3,
        remuneration: 3,
        industry: 3,
        skills: 3,
        experience: 3,
        education: 3,
        certificates: 3,
    },
    [FIELDS.ASSESSMENT_SKILLS]: { selfManagement: [], socialIntelligence: [], innovation: [], discipline: [] },
};
