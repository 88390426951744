import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../buttons/NudgeButton';
import NudgeModal from '../../NudgeModal';

const ActionOrBackModal = ({
    toggle,
    isOpen,
    onGoBack,
    onClick,
    header,
    text = 'This operation is permanent. All your data will be lost.',
    buttonText,
    color = 'success',
    backText = 'Back',
}) => {
    const theme = GetPageTheme();
    return (
        <NudgeModal id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} centered headerText={header}>
            <ModalBody className={style.modalBody}>
                <div className={style.text}>{text}</div>

                <ClickButton size="lg" color={color} onClick={onClick}>
                    {buttonText}
                </ClickButton>
                <ClickButton size="lg" color="danger" className={style.backButton} onClick={onGoBack}>
                    {backText}
                </ClickButton>
            </ModalBody>
        </NudgeModal>
    )
};

ActionOrBackModal.propTypes = {
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    color: PropTypes.string,
    backText: PropTypes.string,
};

export default ActionOrBackModal;
