import PropTypes from 'prop-types';
import React from 'react';

import { ContractorProfilePage } from '../../../components/pages/ContractorProfilePage';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';

export const ProfilePage = ({ showEdit }) => {
    const { theme } = React.useContext(ThemeContext);
    return (
        <ContractorProfilePage theme={theme} showEdit={showEdit} showResume={true} />
    )
};

ProfilePage.propTypes = {
    showEdit: PropTypes.bool,
};

export default withAuthProtectedRouter(ProfilePage, [USER]);
