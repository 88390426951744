import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';

const SortArrow = ({ column }) =>
    column.disableSortBy ? null : (
        <span>
            {column.isSorted ? (
                column.isSortedDesc ? (
                    <FontAwesomeIcon icon={['far', 'sort-up']} className={style.sort} />
                ) : (
                    <FontAwesomeIcon icon={['far', 'sort-down']} className={style.sort} />
                )
            ) : (
                <FontAwesomeIcon icon={['far', 'sort']} className={style.sort} />
            )}
        </span>
    );

SortArrow.propTypes = {
    column: PropTypes.object.isRequired,
};

export default SortArrow;
