import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, FormGroup, Label, Button, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppInput from '../../AppInput';
import SaveButton from '../../buttons/SaveButton';
import SelectField from '../../SelectField';
import UploadPhotoField from '../../DragAndDrop/UploadPhotoField/UploadPhotoField';
import UploadResumeField from '../../DragAndDrop/UploadResumeField/UploadResumeField';
import ServiceOfferedEdit from './ServiceOfferedEdit';
import { FIELDS, WORD_LIMIT } from './validation';

import { DEFAULT_PICTURE } from '../../../consts/urls';
import style from './style.module.scss';
import AutocompleteInput from '../../AutocompleteInput';
import { currencyOptions } from '../../../pages/company/NewOfferPage/OfferForm/options';
import ReadyPlayerMeModal from '../../pages/ContractorProfilePage/ReadyPlayerMeModal';
import UploadModal from './UploadModal';
import ClickButton from '../../buttons/NudgeButton';
import ColourInput from '../../ColourInput';
import { generateMonoLinearGradient } from '../../pages/GenericCompanyProfile/HelperComponents';

import CheckboxField from '../../CheckboxField';

import { freelanceStaffOptions, timingOptions, workModeOptions, workSearchOptions, workTypeOptions, rateOptions } from './helpers';
import NudgeButton from '../../buttons/NudgeButton';
import useMobile from '../../../hooks/useMobile';
import NudgeLabel from '../../NudgeLabel';

const emptyText = () => {
    var inputVal = document.getElementById(FIELDS.BIO);
    if (inputVal?.value === ' ') inputVal.value = '';
};

const ContractorInformationEdit = ({ formik, onClose, companies, canCancel = true }) => {
    const atWordLimit = formik.values.bio.length > WORD_LIMIT;
    if (atWordLimit) {
        formik.setFieldValue(FIELDS.BIO, formik.values[FIELDS.BIO].substring(0, 1000));
    }

    const { isMobileWide } = useMobile();
    const workMode = formik.values[FIELDS.WORK_MODE];

    function checkFormikError(field) {
        if (formik.errors[field] && formik.submitCount > 0) {
            return true;
        }
    }

    const [canDeletePhoto, setCanDeletePhoto] = React.useState(false);
    const [canDeleteResume, setCanDeleteResume] = React.useState(false);
    React.useEffect(() => {
        if(formik.values[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            setCanDeletePhoto(true);
        }
        
        if(formik.values[FIELDS.RESUME] != false) {
            setCanDeleteResume(true);
        }
    }, [])

    const [avatarUrl, setAvatarUrl] = React.useState(formik.values[FIELDS.AVATAR_LINK] || '');
    const avatar = { get: avatarUrl, set: setAvatarUrl };

    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    function togglePhotoModal() {
        setPhotoModalOpen(!photoModalOpen);
    }

    React.useEffect(() => {
        formik.setFieldValue(FIELDS.AVATAR_LINK, avatarUrl);
    }, [avatarUrl]);

    function setPhotoField(photo) {
        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(FIELDS.PHOTO, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    const [photo, setPhoto] = React.useState('');
    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[FIELDS.PHOTO]));
        formik.setFieldValue(FIELDS.DELETE_PHOTO, false);
    }, [formik.values[FIELDS.PHOTO]]);

    React.useEffect(() => {
        formik.setFieldValue(FIELDS.DELETE_RESUME, false);
    }, [formik.values[FIELDS.RESUME]]);
    
    function getProfileIcon(v) {
        if(v[FIELDS.DELETE_PHOTO]) {
            return 'ban'
        }

        if(canDeletePhoto || v[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            return 'pencil'
        } else {
            return 'plus-circle'
        }
    }


    return (
        <>
            <div style={{ background: generateMonoLinearGradient(`${formik.values.bannerColour ? formik.values.bannerColour : "#407962"}`), borderBottom: `2px solid ${formik.values.bannerColour || "#407962"}` }} className={style.banner}></div>

            <UploadModal
                isOpen={photoModalOpen}
                toggle={togglePhotoModal}
                avatar={avatar}
                setPhoto={setPhotoField}
            />

            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                { canCancel && isMobileWide ? <div className={style.cancelButton}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <NudgeButton onClick={onClose} color="danger">Cancel</NudgeButton>
                        </Col>
                    </Row>
                </div> : null }
                <div className={style.saveButton}>
                    <SaveButton  onClose={canCancel && !isMobileWide ? onClose : null }/>
                    {formik.errors && Object.keys(formik.errors).length > 0 ? (
                        <p style={{ color: 'red', fontSize: '15px', fontWeight: '600', textAlign: 'center' }}>
                            Please fill out all required fields.
                        </p>
                    ) : null}
                </div>
                <FormGroup className={style.avatarContainer}>
                    <div className={isMobileWide ? "text-center" : "text-left"}>
                        <div className={style.avatar} style={{ borderColor: `${formik.values.bannerColour || "#407962"}` }}>
                            <div
                                className={style.photoUpload}
                                style={{ backgroundImage: `url(${photo})`, objectFit: 'contain'  }}
                                onClick={togglePhotoModal}
                            >
                                <h5>
                                    <FontAwesomeIcon icon={['fas', getProfileIcon(formik.values)]} />
                                </h5>
                            </div>
                            { canDeletePhoto ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: '14px', columnGap: '6px', marginTop: '12px'}}>
                                <Field component={CheckboxField} form={formik} name={FIELDS.DELETE_PHOTO} label={"Delete Photo"} bool/>
                            </div> : null }
                        </div>
                    </div>
                    <br/>

                    <p class="text-center">{ formik.values[FIELDS.DELETE_PHOTO] ? "Press 'Save' to delete profile picture." : null }</p>
                </FormGroup>
                
                <Row>
                    <Col xs={12} md={12}>
                        <FormGroup className="mb-20">
                            <Label for={FIELDS.NAME}>Name</Label>
                            <AppInput id={FIELDS.NAME} formik={formik} label="Name" />
                        </FormGroup>
                    </Col>
                </Row>
                
                <FormGroup>
                    <Label for={FIELDS.BANNER_COLOUR}>Banner Colour</Label>
                    <ColourInput id={FIELDS.BANNER_COLOUR} formik={formik}/>
                </FormGroup>

                <Row>
                    <Col xs={12} md={3}>
                        <FormGroup className="mb-20">
                            <Label for={FIELDS.AVATAR_LINK}>Avatar</Label>

                            <div style={{ display: 'flex', columnGap: '12px' }}>
                                <ReadyPlayerMeModal avatar={avatar} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className="mb-20">
                            <Label for={FIELDS.AVATAR_LINK}>Avatar Link</Label>
                            <AppInput
                                id={FIELDS.AVATAR_LINK}
                                formik={formik}
                                label="Click button to create readyplayerme avatar"
                                disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormGroup className="mb-20">
                            <NudgeLabel style={{ opacity: 0 }} for={FIELDS.AVATAR_LINK} show={true} >
                                ...
                            </NudgeLabel>

                            <div style={{ display: 'block' }}>
                                <ClickButton
                                    style={{ width: '100%', minHeight: '38px' }}
                                    onClick={() => {
                                        formik.setFieldValue(FIELDS.AVATAR_LINK, '');
                                        setAvatarUrl('');
                                    }}
                                    color="success"
                                >
                                    Remove
                                </ClickButton>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={6} md={6}>
                        <FormGroup className="mb-20">
                            <Label for={FIELDS.SERVICE_OFFERED}>Current/Last Job Title</Label>
                            <AppInput id={FIELDS.SERVICE_OFFERED} formik={formik} label="Current/Last Job Title" />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <FormGroup className="mb-20">
                            <Label for={FIELDS.COMPANY}>Current/Last Employer</Label>
                            <Field
                                name={FIELDS.COMPANY}
                                component={AutocompleteInput}
                                suggestions={companies}
                                error={checkFormikError(FIELDS.COMPANY)}
                            />
                            {checkFormikError(FIELDS.COMPANY) ? (
                                <h5 style={{ color: 'red', fontSize: '14px', paddingTop: '4px' }}>
                                    {formik.errors[FIELDS.COMPANY]}
                                </h5>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup className="mb-20" onClick={emptyText}>
                    <Label for={FIELDS.BIO}>User Bio</Label>
                    <AppInput id={FIELDS.BIO} formik={formik} className={style.bioInput} label="Bio" type="textarea" charLimit={WORD_LIMIT} />
                </FormGroup>

                <FormGroup className="mb-20" onClick={emptyText}>
                    <Label for={FIELDS.VIDEO_LINK}>YouTube Link (for personal video introduction)</Label>
                    <AppInput id={FIELDS.VIDEO_LINK} formik={formik} label={"YouTube Video Link"} />
                </FormGroup>

                <FormGroup className="mb-20">
                    <Row>
                        <Col xs={12} sm={workMode == 'Remote' ? 12 : 6} md={workMode == 'Remote' ? 12 : 6}>
                            <Label for={FIELDS.WORK_MODE}>Work Mode</Label>
                            <Field
                                name={FIELDS.WORK_MODE}
                                options={workModeOptions}
                                component={SelectField}
                                placeholder="Work Mode"
                            />
                        </Col>
                        {workMode != 'Remote' ? (
                            <Col xs={12} sm={6} md={6}>
                                <Label for={FIELDS.LOCATION}>My Location</Label>
                                <AppInput id={FIELDS.LOCATION} formik={formik} label="Location" />
                            </Col>
                        ) : null}
                    </Row>
                </FormGroup>

                <FormGroup className="mb-20">
                    <Row>
                        <Col xs={12} sm={6} md={6}>
                            <Label for={FIELDS.WORK_TYPE}>Work Type</Label>
                            <Field
                                name={FIELDS.WORK_TYPE}
                                options={workTypeOptions}
                                component={SelectField}
                                placeholder="Work Type"
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                            <Label for={FIELDS.WORK_SEARCH}>Searching?</Label>
                            <Field
                                name={FIELDS.WORK_SEARCH}
                                options={workSearchOptions}
                                component={SelectField}
                                placeholder="Active or Passive?"
                            />
                        </Col>
                    </Row>
                </FormGroup>

                {formik.values[FIELDS.WORK_TYPE] != 'Volunteer' ? (
                    <FormGroup className="mb-20">
                        <Row>
                            <Col md={12}>
                                <Label>Rate Range</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={3}>
                                <Field
                                    name={FIELDS.CURRENCY}
                                    options={currencyOptions}
                                    component={SelectField}
                                    placeholder="Currency Type"
                                />
                            </Col>

                            <Col xs={6} md={3}>
                                <AppInput id={FIELDS.RATE_RANGE_LOW} formik={formik} label="Min" />
                            </Col>

                            <Col xs={6} md={3}>
                                <AppInput id={FIELDS.RATE_RANGE_HIGH} formik={formik} label="Max" />
                            </Col>

                            <Col xs={12} md={3}>
                                <Field
                                    name={FIELDS.TIMING}
                                    options={rateOptions}
                                    component={SelectField}
                                    placeholder="Per Day/Hour"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                ) : null}

                <ServiceOfferedEdit formik={formik} />

                <FormGroup className="mb-20">
                    <Row>
                        <Col xs={12} md={6}>
                            <Label for={FIELDS.FREELANCER_STAFF}>Freelance or Staff Work?</Label>
                            <Field
                                name={FIELDS.FREELANCER_STAFF}
                                options={freelanceStaffOptions}
                                component={SelectField}
                                placeholder="Freelance or Staff"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Label for={FIELDS.WORK_TIMING}>Full Time or Part Time?</Label>
                            <Field
                                name={FIELDS.WORK_TIMING}
                                options={timingOptions}
                                component={SelectField}
                                placeholder="Full Time or Part Time"
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup className="mt-20" >
                    <Field component={UploadResumeField} name={FIELDS.RESUME} />
                </FormGroup>

                { canDeleteResume ? <div style={{ display: 'flex', fontSize: '14px', columnGap: '6px', justifyContent: 'center', marginBottom: '12px'}}>
                    <Field component={CheckboxField} formik={formik} name={FIELDS.DELETE_RESUME} label={"Delete CV"} />
                </div> : null }
                
                { formik.values[FIELDS.DELETE_RESUME] ? <p class="text-center">{"Press 'Save' to delete CV."}</p> : null }
            </Form>
        </>
    );
};

ContractorInformationEdit.propTypes = {
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContractorInformationEdit;
