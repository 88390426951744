import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import style from '../../style.module.scss';

import {
    fetcherWithAuthorizationHeader,
    deleteWithAuthorizationHeader,
    patchWithAuthorizationHeader,
} from '../../../../../../api/tools';
import Loader from '../../../../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Formik } from 'formik';
import EditForm from '../EditForm';
import { validationSchema } from '../EditForm/validate';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';

const Details = ({ api_key, interview_key, setPage, mutate }) => {
    const fetchUrl = `https://api.willotalent.com/api/integrations/v2/interviews/${interview_key}/`;

    const [data, setData] = React.useState();
    const [pData, setPData] = React.useState();

    const [showEdit, setShowEdit] = React.useState(false);
    const toggleEdit = () => setShowEdit(!showEdit);

    const [sending, setSending] = React.useState(false);

    const [showDetails, setShowDetails] = React.useState(false);
    const toggleShow = () => setShowDetails(!showDetails);

    let participantFetchUrl = `https://api.willotalent.com/api/integrations/v2/participants/?interview=${interview_key}`;

    React.useEffect(() => {
        async function getData() {
            setSending(true);

            const response = await fetcherWithAuthorizationHeader(fetchUrl, api_key);
            const participants = await fetcherWithAuthorizationHeader(participantFetchUrl, api_key);

            setData(response);
            setPData(participants);

            setSending(false);
        }

        getData();
    }, [fetchUrl]);

    async function deleteInterview(key) {
        const deleteUrl = `https://api.willotalent.com/api/integrations/v2/interviews/${key}`;

        setSending(true);

        const response = await deleteWithAuthorizationHeader(deleteUrl, api_key);
        mutate();
        setPage(1);

        setSending(false);
    }

    async function editInterview(v) {
        const patchUrl = `https://api.willotalent.com/api/integrations/v2/interviews/${interview_key}`;

        setSending(true);

        const response = await patchWithAuthorizationHeader(patchUrl, api_key);
    }

    const props = {
        initialValues: { ...data },
        validationSchema,
        onSubmit: (values) => {
            editInterview(values);
        },
    };

    return !showEdit ? (
        <>
            <div className={style.buttonContainer}>
                <FontAwesomeIcon
                    className={style.expandIcon}
                    icon={['far', !showDetails ? 'plus-circle' : 'minus-circle']}
                    onClick={toggleShow}
                />

                <NudgeButton className={style.button} color="success" onClick={toggleEdit}>
                    Edit
                </NudgeButton>
                <NudgeButton className={style.button} color="danger" onClick={() => deleteInterview(interview_key)}>
                    Delete
                </NudgeButton>
            </div>

            {showDetails ? (
                data && !sending ? (
                    <>
                        <p>{data.description.replaceAll('<p>', '').replaceAll('</p>', '.')}</p>
                        <a href={data.invite_link}>Invite Link</a>
                        <br />

                        {pData
                            ? pData.results.map((item, index) => (
                                  <>
                                      <div>
                                          {item.name} - {item.key}
                                      </div>
                                  </>
                              ))
                            : null}
                    </>
                ) : (
                    <Loader />
                )
            ) : null}
        </>
    ) : (
        <Formik {...props}>{(formik) => <EditForm formik={formik} toggle={toggleEdit} />}</Formik>
    );
};

export default Details;
