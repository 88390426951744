import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';
import AppInput from '../../../../components/AppInput';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../components/NudgeModal';

const FeedbackModal = ({ isOpen, toggle, onSend, formik, matchId }) => (
    <NudgeModal headerText={"Send feedback to rejected candidate."} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
        <ModalBody className={style.modalBody}>
            <div className={style.text}>Choose the feedback you would like to send to candidate.</div>

            <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-20">
                    <Field
                        name={FIELDS.OTHER}
                        id={FIELDS.OTHER}
                        formik={formik}
                        component={AppInput}
                        label="Reason for Denial"
                    />
                </FormGroup>

                <ClickButton type="submit" color="success" size="lg" className={style.backButton}>
                    Reject Match
                </ClickButton>
                <ClickButton size="lg" color="danger" onClick={toggle}>
                    Go Back
                </ClickButton>
            </Form>
        </ModalBody>
    </NudgeModal>
);

FeedbackModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default FeedbackModal;
