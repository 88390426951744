import React from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { COMPANY, USER } from '../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../contexts/auth/AuthProtectedRouter';

import DeleteModal from '../DeleteModal';

import style from '../style.module.scss';
import ClickButton from '../../../../components/buttons/NudgeButton';

export const IndividualSublogin = ({ item, onDelete }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const toggleDelete = () => setShowDeleteModal(!showDeleteModal);

    const clickDelete = async () => {
        await onDelete(item.id);
        toggleDelete();
    };

    return (
        <Card className={style.subAccountCard}>
            <Row>
                <Col xs={8} md={8}>
                    <h5 className={style.subAccountName}>{item.name}</h5>
                </Col>
                <Col xs={4} md={4}>
                    <ClickButton color="danger" onClick={toggleDelete} className={style.subAccountEdit}>
                        Delete
                    </ClickButton>
                </Col>
            </Row>
            <DeleteModal
                toggle={toggleDelete}
                isOpen={showDeleteModal}
                onGoBack={toggleDelete}
                onClick={clickDelete}
                header={`Delete this sub-account named ${item.name}?`}
                buttonText="Delete"
            />
        </Card>
    );
};

export default withAuthProtectedRouter(IndividualSublogin, [USER, COMPANY]);
