import React from 'react';

import FullCompanyProfile from '../../../components/pages/FullCompanyProfile';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import { COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

export const CompanyProfile = () => (
    <>
        <FullCompanyProfile url={API_DASHBOARD_COMPANY} detailsUrl={`${API_DASHBOARD_COMPANY}/ExtraDetails`} showEdit />
    </>
);

export default withAuthProtectedRouter(CompanyProfile, [COMPANY]);
