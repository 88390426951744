import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import PlusButton from '../../buttons/PlusButton';
import { usePlusButtonWithAlwaysMinus } from '../../buttons/PlusButton';

import SaveButton from '../../buttons/SaveButton';
import GenericFormWithArray from '../GenericFormWithArray';
import { ContainerCard, GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import { FIELDS, FIELD_NAMES } from './validation';
import NewInputForm from '../NewInputForm';
import NudgeTabs from '../../NudgeTabs';
import NudgeButton from '../../buttons/NudgeButton';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

export const EditableBVTForm = ({ formik, onClose, tabs, selectedTab, setSelectedTab }) => {
    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);
    const theme = GetPageTheme();

    return (
        <>
            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>

                <Row style={{ marginBottom: '12px' }}>
                    <Col xs={12} md={12}>
                        <NudgeTabs
                            label="Traits, Values and Benefits"
                            selected={selectedTab}
                            setTab={setSelectedTab}
                            tabs={tabs}
                            buttons
                        />
                    </Col>
                    <Col xs={12} md={12}>
                        <NudgeButtonGroup
                            buttons={[
                                { label: 'Cancel', color: 'danger', onClick: onClose },
                                { label: 'Save', type: 'submit' }
                            ]}
                            buttonsHeight='38px'
                        />
                    </Col>
                </Row>
                
                { selectedTab == 'companyTraits' ? <ContainerCard id={`card-sub-${theme}`} header={'Traits'}>
                    <FormGroup className="mb-20 ">
                        <NewInputForm
                            formik={formik}
                            addValue={{ content: '' }}
                            field={FIELDS.TRAITS}
                            subField={'content'}
                            fieldLimit={8}
                        />
                    </FormGroup>
                </ContainerCard>
                : selectedTab == 'companyValues' ? <ContainerCard id={`card-sub-${theme}`} header={'Values'}>
                    <FormGroup className="mb-20 ">
                        <NewInputForm
                            formik={formik}
                            addValue={{ content: '' }}
                            field={FIELDS.VALUES}
                            subField={'content'}
                            fieldLimit={8}
                        />
                    </FormGroup>
                </ContainerCard>
                : selectedTab == 'companyBenefits' ? <ContainerCard id={`card-sub-${theme}`} header={'Benefits'}>
                    <FormGroup className="mb-20 ">
                        <NewInputForm
                            formik={formik}
                            addValue={{ content: '' }}
                            field={FIELDS.BENEFITS}
                            subField={'content'}
                            fieldLimit={8}
                        />
                    </FormGroup>
                </ContainerCard> : null }
            </Form>
        </>
    );
};

export default EditableBVTForm;
