import React from 'react';
import { deleteWithAuthorizationHeader, fetcherWithAuthorizationHeader } from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';

import { Row, Col, Button, Card } from 'reactstrap';
import style from '../style.module.scss';

const ManageInterviews = ({ api_key }) => {
    const [data, setData] = React.useState();
    const [page, setPage] = React.useState(1);

    const [sending, setSending] = React.useState(false);

    let fetchUrl = `https://api.willotalent.com/api/integrations/v2/users/`;

    React.useEffect(() => {
        async function getData() {
            setSending(true);

            const response = await fetcherWithAuthorizationHeader(fetchUrl, api_key); 
            setData(response);

            setSending(false);
        }

        getData();
    }, [fetchUrl]);

    async function deleteInterview(key) {
        const deleteUrl = `https://api.willotalent.com/api/integrations/v2/user/${key}`;

        setSending(true);

        const response = await deleteWithAuthorizationHeader(deleteUrl, api_key);
        setPage(1);

        setSending(false);
    }

    return data ? (
        <>
            <Row>
                {/* <Col md={1}><Button color={data.previous && !sending ? "success" : "disabled-success"} disabled={!data.previous && !sending} onClick={() => setPage(page - 1)}>Prev</Button></Col> */}
                <Col md={12}>
                    {data.results && !sending ? (
                        data.results.map((item, index) => (
                            <Card className={style.card}>
                                <div>
                                    <h5 className={style.content}>
                                        {item.full_name} - {item.key}
                                    </h5>
                                </div>
                                <br />
                            </Card>
                        ))
                    ) : (
                        <Loader />
                    )}
                </Col>
                {/* <Col md={1}><Button color={data.next && !sending ? "success" : "disabled-success"} disabled={!data.next && !sending} onClick={() => setPage(page + 1)}>Next</Button></Col> */}
            </Row>
        </>
    ) : (
        <Loader />
    );
};

export default ManageInterviews;
