import React from 'react';
import CreateResourceButtons from '../../../../components/buttons/CreateResourceButtons';
import WeightsDashboard from '../WeightsDashboard';
import Loader from '../../../../components/Loader';
import moment from 'moment';

import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import { authPostData, authSendFiles, postWithAuthorizationHeader } from '../../../../api/tools';
import { API_DASHBOARD_CLOOD, API_DASHBOARD_COMPANY, API_DASHBOARD_WILLO, URLS } from '../../../../consts/urls';
import { Button } from 'reactstrap';

import OfferForm, { translateDates } from '../OfferForm';
import { FIELDS, initialValues, getValidationSchema } from '../OfferForm/validation';
import { OFFER_ERROR } from '..';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { ToastContext } from '../../../../contexts/toast/ToastContext';

function parseQuestions(questions) {
    var returnQuestions = [];

    questions.forEach((item, index) => {
        let q = {
            text: item.question,
            order: index + 1,
            answer_type: item.type,
            thinking_time: parseInt(item.thinking_time),
            max_words: parseInt(item.max_words),
            max_retakes: parseInt(item.max_retakes),
            max_duration: parseInt(item.max_duration),
            location_of_employment: item.location_of_employment
        };
        returnQuestions.push(q);
    });

    return returnQuestions;
}

async function findSchema(values, userKey) {
    const v = values;

    const questions = parseQuestions(v[FIELDS.QUESTIONS]);
    const requestSchema = {
        title: v[FIELDS.NAME],
        department: userKey || process.env.REACT_APP_WILLO_DEPARTMENT_KEY, // nudge testing labs department key
        message_templates: {
            invitation_email: null,
            invitation_sms: null,
            reminder_email: null,
            reminder_sms: null,
            interview_completed_email: null,
        },
        questions: questions,
        settings: {
            redirect_url: 'https://nudgetalentlab.com/',
            show_hints_and_tips: true,
            show_availability_calendar: true,
            deadline: null, //moment(v[FIELDS.CLOSE_DATE]).format('YYYY-MM-DD')
        },
    };

    const response = await authPostData(requestSchema, `${API_DASHBOARD_WILLO}/Interviews/CreateInterview`);

    return { response, questions };
}

export const handleTime = (hours, minutes) => {
    //translates individual time to parseable timestring
    var lHours = hours;
    var lMinutes = minutes;

    lHours = '' + lHours;
    lMinutes = '' + lMinutes;

    if (lHours.length === 1) {
        lHours = '0' + lHours;
    }

    if (lMinutes.length === 1) {
        lMinutes = '0' + lMinutes;
    }

    let combineTime = lHours + lMinutes;
    return combineTime;
};

const CreateOfferCard = ({ section, begin }) => {
    const navigate = useNavigate();
    const onBack = () => navigate(-1);
    const { user, userRoles } = React.useContext(AuthContext);
    const { createToast } = React.useContext( ToastContext );

    let canWillo = false;
    let canEdify = false;
    if ((user, userRoles)) {
        if (userRoles.includes('admin')) {
            canWillo = true;
        } else {
            let willoPerm = user.permissions.find((p) => p.permissionType == 'willo_interview');
            let edifyPerm = user.permissions.find((p) => p.permissionType == 'edify_interview');

            if (willoPerm) {
                if (willoPerm.enabled) {
                    canWillo = true;
                }
            }

            if (edifyPerm) {
                if (edifyPerm.enabled) {
                    canEdify = true;
                }
            }
        }
    }

    const [sending, setSending] = React.useState(false);

    async function testParse(date) {
        const response = await authPostData(`${API_DASHBOARD_COMPANY}/TestParse/${date}`); 
    }

    const postRequest = async (values) => {
        var copyValues = values;

        values[FIELDS.START] = moment(values[FIELDS.START]).local().format('yyyy-MM-DDTHH:mm:ss');
        values[FIELDS.PUBLISH_DATE] = moment(values[FIELDS.PUBLISH_DATE]).format('yyyy-MM-DDTHH:mm:ss');
        values[FIELDS.CLOSE_DATE] = moment(values[FIELDS.CLOSE_DATE]).local().format('yyyy-MM-DDTHH:mm:ss');

        values[FIELDS.MIN_QUALIFICATIONS] += '/' + values.studyField;

        if (values.flexibility === 'Fixed') {
            values.fixedFrom = handleTime(values.hoursFrom, values.minutesFrom);
            values.fixedTo = handleTime(values.hoursTo, values.minutesTo);
        }

        if (values.offerType !== 'Freelancer') {
            values.duration = '0';
        }

        if (!values.outside) {
            values.outside = 'NO';
        }

        let id;
        setSending(true);
        const objQuestions = values.questions;

        try {
            values.questions.forEach((item, index) => {
                item.questionOrder = index;

                item.thinking_time = parseInt(item.thinking_time);
                item.max_words = parseInt(item.max_words);
                item.max_retakes = parseInt(item.max_retakes);
                item.max_duration = parseInt(item.max_duration);
            });

            if (values.hasInterview) {
                const { response, questions } = await findSchema(values, user.brandingKey);

                if (!response) {
                    createToast('Interview ID was not returned. Please try again, or contact if the issue persists.', 'error')
                }

                values.interviewKey = response.key;
            }

            id = await authPostData(values, `${API_DASHBOARD_COMPANY}/Offer`);
            await authSendFiles(values.files, `${API_DASHBOARD_COMPANY}/Offers/${id}/Attachments`);
        } catch (e) {
            console.log(e.message);
        } finally {
            setSending(false);
            values = copyValues;
            values.questions = objQuestions;
        }

        if (!id) {
            setSending(false);
            createToast('Something went wrong in the Nudge system. Please try again, or contact if the issue persists.', 'error');
        } else {
            if (typeof id == 'object') {
                createToast('Response from Nudge system was not as expected. Please try again, or contact if the issue persists.', 'error');
            } else {
                navigate(`${URLS.COMPANY_CONTRACTS}/${id}`);
            }
        }
    };

    const props = {
        initialValues,
        validationSchema: getValidationSchema(false),
        onSubmit: postRequest,
        validateOnChange: false,
        validateOnBlur: true
    };

    return user ? (
        <>
            <Formik {...props}>
                {(formik) => (
                    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                        { !sending ? <OfferForm
                            formik={formik}
                            isEdit={false}
                            section={section}
                            canWillo={canWillo}
                            canEdify={canEdify}
                            begin={begin}
                        /> : <Loader message="Loading"/> }

                        <CreateResourceButtons onBack={onBack} onTemplate={true} disabled={!!sending} />
                    </Form>
                )}
            </Formik>
        </>
    ) : (
        <Loader />
    );
};

export default CreateOfferCard;
