import { postData } from '../../api/tools';
import { API_PROFILE } from '../../consts/urls';
import { CONTRACTOR_NUMERIC } from '../../consts/userRoles';
import { signUpUser } from '../../contexts/auth/tools';

export const FORMS_NAMES = [
    'PERSONAL_DETAILS', // 0
    'BIO', // 1
    'SERVICE_SECTION', // 2
    'INDUSTRY_EXPERIENCE', // 3
    'SKILLS', // 4
    'QUALIFICATION', // 5
    'CERTIFICATES', // 6
    'AVAILABILITY', // 7
    'RATE_RANGE', // 8
    'RESUME', // 9
    'PHOTO', // 10
];

export const signUpUserHelper = async (newData, history, activateSignUp) => {
    const response = await signUpUser({
        email: newData.email,
        password: newData.password,
        name: newData.name,
        picture: '',
        type: CONTRACTOR_NUMERIC,
    });

    if (response.userSub) {
        sendAdditionalSignUpInfo(response, newData.refCode, activateSignUp);
    }
};

export const sendAdditionalSignUpInfo = async (response, refCode, activateSignUp) => {
    const data = {
        Id: response.userSub,
        JobSubIndustryId: 3,
        JobName: ' ',
        Skills: [],
        Education: '',
        Experience: [],
        AvailableFrom: null,
        AvailableTo: null,
        ExpectedEarnings: '0-0',
        Company: '',
        Bio: ' ',
        Flow: 'Energy',
        Timing: 'Hourly',
        WorkType: '',
        WorkMode: '',
        WorkTiming: '',
        FreelancerStaff: '',
        WorkSearch: '',

        refCode: refCode,
        BannerColour: '',
        parentId: '',
        videoLink: ''
    };

    try {
        await postData(data, API_PROFILE);
        activateSignUp();
    } catch (e) {
        console.log(`There was a problem: ${e.message}.`);
    }
};
