import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, FormGroup } from 'reactstrap';

import AppInput from '../../AppInput';
import { FIELDS } from './validation';
import ClickButton from '../../buttons/NudgeButton';

export const FORM_COLUMNS = {
    md: { size: 4, offset: 4 },
};

export const SignInPageForm = ({ formik }) => (
    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <FormGroup row>
            <Col {...FORM_COLUMNS}>
                <AppInput id={FIELDS.EMAIL} formik={formik} label="Login" type="email" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col {...FORM_COLUMNS}>
                <AppInput id={FIELDS.PASSWORD} type="password" formik={formik} label="Password" />
            </Col>
        </FormGroup>

        <FormGroup row>
            <Col md={{ size: 2, offset: 5 }}>
                <ClickButton color="success" type="submit" className="w-100 mt-20">
                    Log in
                </ClickButton>
            </Col>
        </FormGroup>
    </Form>
);

SignInPageForm.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default SignInPageForm;
