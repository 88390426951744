import { Field } from 'formik';
import React from 'react';
import { FormGroup, Row, Col } from 'reactstrap';

import SelectField from '../../SelectField';

import { QUALIFICATIONS_OPTIONS } from '../../../consts/offers';

import AppInput from '../../AppInput';
import CustomFormikError from '../../CustomFormikError';
import { FIELDS } from './validation';

const QualificationsFormBody = ({ formik }) => {
    return (
        <>
            <FormGroup className="mb-20 ">
                <Row>
                    <Col sm={4} md={3} lg={3}>
                        <Field
                            name={FIELDS.QUALIFICATION}
                            component={SelectField}
                            options={QUALIFICATIONS_OPTIONS}
                            placeholder="Type"
                        />
                    </Col>
                    <Col sm={8} md={9} lg={9}>
                        <AppInput id={FIELDS.STUDY_FIELD} formik={formik} label="Field of Study" />
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup>
                <CustomFormikError form={formik} name={FIELDS.QUALIFICATION} />
            </FormGroup>
        </>
    );
};

export default QualificationsFormBody;
