import React from 'react';

const LeavePage = () => (
    <div className="left-width-height h-100vh">
        Thank you for your video. Your submission will now be sent to the company for review, they will be in touch via
        email or through the instant messenger with feedback/decision.
    </div>
);

export default LeavePage;
