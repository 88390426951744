import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'formik';
import AppInput from '../../../components/AppInput';
import KariaImage from '../../../assets/images/kingsbridgeLogo.jpg';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeModal from '../../../components/NudgeModal';

const KariaFormModal = ({ isOpen, toggle, formik }) => {
    const [isSuccess, setIsSuccess] = useState(false);

    const toggleSuccess = () => {
        setIsSuccess(!isSuccess);
    };
    const passSuccess = () => {
        formik.values.successMethod = toggleSuccess;
    };

    return (
        <NudgeModal headerText={""} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle.kingsbridge} className={style.modal} centered>
            <ModalHeader toggle={toggle.kingsbridge} className={style.modalHeader}>
                <span className={style.modalHeaderText}>
                    <img src={KariaImage} alt="form"></img>
                </span>
            </ModalHeader>
            <ModalBody className={style.modalBody}>
                {!isSuccess ? (
                    <Form onSubmit={formik.handleSubmit}>
                        <div className={style.text}>Enter details of your request and press submit.</div>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.NAME}
                                id={FIELDS.NAME}
                                formik={formik}
                                component={AppInput}
                                label="Name"
                            />
                        </FormGroup>
                        <br />

                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.PHONE_NO}
                                id={FIELDS.PHONE_NO}
                                formik={formik}
                                component={AppInput}
                                label="Phone Number"
                            />
                        </FormGroup>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.EMAIL}
                                id={FIELDS.EMAIL}
                                formik={formik}
                                component={AppInput}
                                label="Email Address"
                            />
                        </FormGroup>
                        <br />

                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.SUBJECT}
                                id={FIELDS.SUBJECT}
                                formik={formik}
                                component={AppInput}
                                label="Subject Line"
                            />
                        </FormGroup>
                        <FormGroup className="mb-10">
                            <Field
                                name={FIELDS.MESSAGE}
                                id={FIELDS.MESSAGE}
                                formik={formik}
                                component={AppInput}
                                type="textarea"
                                label="Message"
                            />
                        </FormGroup>
                        <br />

                        <div>
                            <ClickButton
                                color="success"
                                size="lg"
                                className={style.backButton}
                                onClick={() => passSuccess()}
                            >
                                Submit Details
                            </ClickButton>

                            <ClickButton size="lg" type="button" color="danger" onClick={toggle.kingsbridge}>
                                Cancel
                            </ClickButton>
                        </div>
                    </Form>
                ) : (
                    <div>Message was sent. Thank you for using this service. Click anywhere to exit.</div>
                )}
            </ModalBody>
        </NudgeModal>
    );
};

KariaFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default KariaFormModal;
