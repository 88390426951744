import React from 'react';
import useSWR from 'swr';

import { Card, Col, Form, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import { NEVER_REFRESH, authFetcher, authPostData, authSendFile } from '../../../../../api/tools';
import { API_DASHBOARD_ADMIN, API_DASHBOARD_COMPANY, API_PROFILE, URLS } from '../../../../../consts/urls';
import Loader from '../../../../Loader';
import { validationSchema, initialValues, FIELDS } from './validation';

import style from './style.module.scss';
import AppInput from '../../../../AppInput';
import { Field, Formik } from 'formik';
import NudgeButton from '../../../../buttons/NudgeButton';
import { Helmet } from 'react-helmet-async';
import NudgeModal from '../../../../NudgeModal';
import UploadFileFieldInline from '../../../../UploadFileFieldInline';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseMergedChat } from '../../../../SimpleChatCard/helper';
import moment from 'moment';
import MessagesWrapper from '../../../../SimpleChatCard/MessagesWrapper';
import MessageInput from '../../../../SimpleChatCard/MesaageInput';
import useMobile from '../../../../../hooks/useMobile';
import { getApiByRole } from '../../../../../consts/userRoles';

const MessageModal = ({ user, roles, contractor, isOpen, toggle }) => {

    const [ chat, setChat ] = React.useState();
    const [sending, setSending] = React.useState(false);
    const [showScrollDown, setShowScrollDown] = React.useState(false);

    const { isMobileWide } = useMobile();

    const [pageNumber, _setPageNumber] = React.useState(1);
    const pageRef = React.useRef(pageNumber);
    
    const advancePage = (data) => {
        if(pageRef.current < chat?.maxPages) {
            pageRef.current = data;
            _setPageNumber(data);
        }
    };

    //
    const id = 0;
    const theme = GetPageTheme();
    const { data: messages, mutate, isValidating } = useSWR(`${getApiByRole(roles)}/Matches/${contractor.matchId}/GetChat/${pageRef?.current}`, authFetcher, NEVER_REFRESH);

    const [currentScroll, setCurrentScroll] = React.useState(0);

    //checks the current position of scroll on the chat box.
    //advances page if top, triggers scroll button if 60%
    function checkScroll() {
        let messages = document.getElementById('message-wrapper');
        
        setCurrentScroll(messages.scrollHeight);

        if(messages.scrollTop == 0) {
            advancePage(pageRef?.current + 1);
        }

        if(messages.scrollHeight < 750) {
            setShowScrollDown(false);
        }
        else {
            let scrollThreshold = (messages.scrollHeight) * 0.6;
        
            if((messages.scrollTop + messages.offsetHeight) > scrollThreshold) {
                setShowScrollDown(false);
            } else {
                setShowScrollDown(true);
            }
        }
    }

    //self-explanatory
    function scrollBottom() {
        let wrapper = document.getElementById('message-wrapper');
        
        if(!wrapper) return;

        wrapper.scrollTo({
            top: wrapper.scrollHeight,
            behavior: 'smooth',
        });
    }

    //refreshes chat messages whenever chat is updated
    React.useEffect(() => {
        if(messages) {
            setChat(messages);
        }
    }, [messages, sending, isValidating])

    //Adds event listeners to the chat box
    React.useEffect(() => {
        let messages = document.getElementById('message-wrapper');
        
        if(messages) {
            messages.removeEventListener('scroll', checkScroll);
            messages.addEventListener('scroll', checkScroll);
        }
    }, [chat])

    //formik props
    const props = {
        onSubmit: async (values, { resetForm }) => {
            setSending(true);
            await resetForm();

            try {
                if(values.file) {
                    await authSendFile(values.file, `${API_PROFILE}/Matches/Attachment`, {
                        matchId: contractor.matchId,
                        message: values.message,
                    });
                } else {
                    await authPostData(values, `${API_DASHBOARD_COMPANY}/Matches/${contractor.matchId}/Chat`);
                }
            } catch(e) {
                console.log(e.message);
            } finally {
                await mutate();
                setSending(false);
            }
            
        },
        validationSchema,
        initialValues,
    }

    const HeaderComponent = <>
        Chat with {contractor?.username || contractor?.name}
        <p onClick={() => window.open(`${URLS.COMPANY_INTERVIEWS}/${contractor.matchId}`, '_blank', 'noreferrer')} style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '16px' }}>View full interview page.</p>
    </>
    
    return (
        <>
        <NudgeModal id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} centered headerText={HeaderComponent}>
            <Helmet><title>{`Chat with ${contractor?.username || contractor?.name || "nobody"} - Nudge Energy Talent Lab}`}</title></Helmet>
            <ModalBody style={{ minHeight: '325px', padding: 0, overflowY: 'hidden', minWidth: !isMobileWide ? '1014px' : '' }}>
                <MessagesWrapper chat={chat?.chats} user={user} sending={sending || isValidating} showScroll={showScrollDown} scroll={scrollBottom} pageRef={pageRef} currentScroll={currentScroll} overview/>
            </ModalBody>
            <Formik {...props}>
                {(formik) => <>
                    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <ModalFooter style={{ display: 'block', height: '100%' }}>
                            <MessageInput
                                matchId={contractor?.matchId}
                                company={true}
                                mutate={mutate}
                                afterSendAction={mutate}
                                bidding={messages?.bid}
                                frequency={messages?.frequency}
                                offerStatus={messages?.offerStatus}
                                isDisabled={sending || isValidating}
                                advancePage={advancePage}
                                pageRef={pageRef}
                                messages={chat?.chats?.length}
                            />
                        </ModalFooter>
                    </Form>
                </>}
            </Formik>
        </NudgeModal>
        
        </>
    )
}

export default MessageModal;