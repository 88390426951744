import React from 'react';
import AppNavbarComp from '../../components/AppNavbarComp';
import { Helmet } from 'react-helmet-async';

import style from './style.module.scss';
import { Col, Container, Row } from 'reactstrap';
import FooterComp from '../../components/FooterComp';

import nudge_logo from '../../assets/images/Nudge full logo.png';
import evami from '../../assets/images/evami.png';
import orlo from '../../assets/images/orlo.png';

import neo_talent from '../../assets/videos/neo_talent.mp4';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import NudgeButton from '../../components/buttons/NudgeButton';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';
import { Link } from 'react-router-dom';

const HomeNeoTalent = () => {
    const { user, isAuth } = React.useContext(AuthContext);
    const { theme } = React.useContext(ThemeContext);
    const { t } = useTranslation();

    return (
        <div className={style.homeBackground}>
            <AppNavbarComp />
            <Helmet>
                <title>NEO Talent Program - Nudge Energy Talent Lab</title>
            </Helmet>

            <section id={`card-sub-${theme}`}>
                <video autoPlay muted loop className={style.videobg}>
                    <source id="gradient" src={neo_talent} type="video/mp4" />
                </video>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={style.firstSection}>
                                <h1>NEO Talent Program</h1>
                                <h3 style={{ marginTop: '5vh' }}>Your pipeline into a rewarding renewable energy career.</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.secondSection}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div class="flex-center-align-justify" style={{ columnGap: '11%' }}>
                                <img src={nudge_logo} style={{ width: '22%', aspectRatio: 'auto' }}/>
                                <img src={evami} style={{ width: '22%', aspectRatio: 'auto' }}/>
                                <img src={orlo} style={{ width: '22%', aspectRatio: 'auto' }}/>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div class="flex-center-align-justify h-100">
                                <h1>
                                    Nudge, Evami, & Orlo (NEO) have joined forces to support future energy talent pave a way into the industry.
                                </h1>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div class="flex-center-align-justify h-100" style={{ flexDirection: 'column', rowGap: '4vh' }}>
                                <h3>
                                    We are committed to bridging the gap between forward-thinking 
                                    companies in the renewable energy sector and talented individuals they 
                                    can trust to make a positive impact to their organisation.
                                </h3>
                                <h3>
                                    Our collaboration is dedicated to preparing candidates to excel in matched roles within the clean energy sector.
                                </h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.thirdSection}>
                <Container>
                    <Row>
                        <Col md={6} lg={6}>
                            <div class="flex-center-align-justify h-100">
                                <div class="d-block mb-4">
                                    <h1>
                                        We're not just filling positions
                                    </h1>
                                    <div >
                                        <Link
                                            to="mailto:karen.keil@nudgeexchange.com"
                                            className={style.buttonChalk}
                                        >
                                            <span>Contact Karen →</span>
                                        </Link>
                                    </div>
                                    {/* <NudgeButton id="button-light-success" onClick={() => window.open("mailto:karen.keil@nudgeexchange.com")}>Contact Karen</NudgeButton> */}
                                </div>
                            </div>
                            
                        </Col>
                        <Col md={6} lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '4vh' }}>
                                <h3>
                                    We're cultivating optimal talent with the 
                                    skills and knowledge essential for success in sustainable energy careers.
                                    Join us and gain access to a tailored 4 week programme that will support 
                                    and educate as well as source you opportunities in the clean energy 
                                    sector.
                                </h3>
                                <h3>
                                    Diversify your opportunities, unlock your potential, and be part of shaping 
                                    a cleaner, brighter future. Contact Karen Keil today to find out more at karen.keil@nudgeexchange.com.
                                </h3>
                            </div>
                        </Col>
                        <Col>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterComp/>
        </div>
    )
}

export default HomeNeoTalent;