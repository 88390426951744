import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Col, Container, Row } from 'reactstrap';

import Loader from '../../components/Loader';
import style from './style.module.scss';

const DelayedFallback = () => {
    const delay = 12000;
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return (
        <>
            <Helmet>
                <title>Nudge Energy Talent Lab</title>
            </Helmet>
 
            {1 == 1 ? (
                <div className={style.jumbotron}>
                    <Container>
                        <Row>
                            <Col className={style.wrapper}>
                                <Loader message="Logging you in"/>
                            </Col>
                        </Row>
                        <div className={'d-none d-lg-block'} />
                    </Container>
                </div>
            ) : null}
        </>
    );
};

export default DelayedFallback;
