import React from 'react';

import { FIELDS } from '../../validation';
import AppInput from '../../../../../../components/AppInput';
import OfferField from '../../OfferField';

import { generateJobDescription } from '../../../../../../api/flask';
import { Col, Row } from 'reactstrap';
import { Field } from 'formik';
import SelectField from '../../../../../../components/SelectField';

import { locationOptions } from '../../options';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';

import openai from '../../../../../../assets/images/openai.svg';
import openai_dark from '../../../../../../assets/images/openai-dark.png';

import style from '../../style.module.scss';

const BasicInfo = ({ formik, isEdit, tooltip, sectors, selectorData }) => {
    const [key, setKey] = React.useState(false);

    const theme = GetPageTheme();
    const prepareOptions = (elements) => elements && elements.map((el) => ({ value: el.id, label: el.name }));

    

    const [isGenerating, setIsGenerating] = React.useState(false);
    const [lastGeneratedTitle, setLastGeneratedTitle] = React.useState(isEdit ? formik.values[FIELDS.NAME] : '');
    const generateJobDesc = async () => {
        if (formik.values[FIELDS.NAME] === '') {
            return;
        }

        setIsGenerating(true);
        generateJobDescription(formik.values[FIELDS.NAME])
            .then((response) => {
                if (response) {
                    response = response.trim();
                    formik.setFieldValue(FIELDS.SCOPE, response);
                    setLastGeneratedTitle(formik.values[FIELDS.NAME]);
                }
            })
            .catch((error) => {
                setErrorMessage('Unable to generate description. Please try again later.');
            })
            .finally(() => {
                setIsGenerating(false);
            });
        setIsGenerating(false);
    };

    const showLocationField = formik.values[FIELDS.LOCATION_SELECT] != "Remote" && !!formik.values[FIELDS.LOCATION_SELECT];

    return (<>
        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Job Title"
            click={() => tooltip.set(FIELDS.NAME)}
        >
            <AppInput id={FIELDS.NAME} formik={formik} label="Name" />
        </OfferField>

        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Scope of Work Description"
            click={() => tooltip.set(FIELDS.SCOPE)}
        >
            <AppInput
                className={style.scope}
                id={FIELDS.SCOPE}
                formik={formik}
                label="Scope of Work Description"
                type="textarea"
                disabled={isGenerating}
                charLimit={1500}
            >
                {formik.values[FIELDS.NAME].length && lastGeneratedTitle !== formik.values[FIELDS.NAME] ? (
                    <div id={`card-sub-${GetPageTheme()}`} className={style.generateJobDesc}>
                        <p>
                            Need help coming up with a scope of work description? Let us create a draft for
                            you!
                        </p>
                        <NudgeButton color="success" onClick={() => generateJobDesc()} disabled={isGenerating}>
                            {isGenerating
                                ? 'Generating, please wait...'
                                : 'Generate Scope of Work Description'}
                        </NudgeButton>
                        <p className={style.poweredBy}>
                            Powered by <img src={GetPageTheme() == 'light' ? openai : openai_dark} alt="OpenAI" />
                        </p>
                    </div>
            ) : null}
            </AppInput>
        </OfferField>

        <Row>
            <Col lg={6}>                    
                { !isEdit ? <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Work Sector/Specialisation"
                    click={() => tooltip.set(FIELDS.SECTOR)}
                >
                        <Row>
                            <Col md={formik.values[FIELDS.SECTOR] != null ? 6 : 12}>
                                <Field
                                    name={FIELDS.SECTOR}
                                    id={FIELDS.SECTOR}
                                    component={SelectField}
                                    options={prepareOptions(selectorData)}
                                    placeholder="Sector"
                                />
                            </Col>
                            { parseInt(formik.values[FIELDS.SECTOR]) ? (
                                <Col md={6}>
                                    <Field
                                        id={FIELDS.CATEGORY}
                                        name={FIELDS.CATEGORY}
                                        component={SelectField}
                                        options={prepareOptions(
                                            sectors[formik.values[FIELDS.SECTOR] - 1]?.subIndustries,
                                        )}
                                        placeholder="Category"
                                    />
                                </Col>
                            ) : null }
                        </Row>
                </OfferField> : null }
            </Col>
            <Col lg={isEdit ? 12 : 6}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    showOnEdit
                    label="Location"
                    click={() => tooltip.set(FIELDS.LOCATION)}
                >
                    <Row>
                        <Col sm={formik.values[FIELDS.LOCATION_SELECT] == "Remote" ? 12 : 6} md={formik.values[FIELDS.LOCATION_SELECT] == "Remote" ? 12 : 6}>
                            <Field
                                name={FIELDS.LOCATION_SELECT}
                                component={SelectField}
                                options={locationOptions}
                                placeholder="Location type"
                            />
                        </Col>
                        { !!showLocationField && <Col sm={6} md={6}>
                            <AppInput id={FIELDS.LOCATION} formik={formik} label="Location" />
                        </Col> }
                    </Row>
                </OfferField>
            </Col>
        </Row>
        <h5>{formik.values[FIELDS.LOCATION]}</h5>
    </>)
}

export default BasicInfo;