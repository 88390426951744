import * as Yup from 'yup';

export const FIELDS = {
    REASON: 'reason',
    GENERATE_REPORT: 'generateReport'
};

export const validationSchema = Yup.object({
    [FIELDS.REASON]: Yup.string().required('This field is required.'),
});

export const initialValues = {
    [FIELDS.REASON]: '',
    [FIELDS.GENERATE_REPORT]: false
};
