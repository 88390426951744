import * as Yup from 'yup';

export const FIELDS = {
    START: 'startDate',
    FINISH: 'finishDate',
    HOURS: 'hours',
};

export const validationSchema = Yup.object({
    [FIELDS.START]: Yup.string().required('This field is required'),
    [FIELDS.FINISH]: Yup.string().required('This field is required'),
    [FIELDS.HOURS]: Yup.number()
        .typeError('Please enter a numeric value')
        .min(1, 'Must be 1 or greater.')
        .max(168, 'Must be less than 168 hours.'),
});

export const initialValues = {
    [FIELDS.START]: '',
    [FIELDS.FINISH]: '',
    [FIELDS.HOURS]: '',
};
