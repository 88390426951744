import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { countNew } from '../SignInPageTemplate/SignInNavbar/NotificationsModal';

import { Link } from 'react-router-dom';
import { Col, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';

import nudge_full from '../../assets/images/Nudge full logo.png';
import nudge_full_white from '../../assets/images/nudge-logo-chalk-full.png'

import { URLS } from '../../consts/urls';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import LogOutModal from '../SignInPageTemplate/SignInNavbar/LogOutModal';
import style from './style.module.scss';
import useMobile, { getWindowDimensions } from '../../hooks/useMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';
import NudgeCheckbox from '../NudgeCheckbox';

const AppNavbarComp = () => {
    const { isMobileWide } = useMobile();
    const { t } = useTranslation();

    const { theme, toggleTheme, modalOpen, setModalOpen } = React.useContext(ThemeContext);

    const [logOutOpen, setLogOutOpen] = React.useState(false);
    const toggleLogOut = () => setLogOutOpen(!logOutOpen);

    const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false);
    const toggleMobileSidebar = () => setMobileSidebarOpen(!mobileSidebarOpen);

    const { user, logoutUser, isAuth, isAuthenticating } = useContext(AuthContext);
    const loggedIn = isAuth === true && isAuthenticating === false;

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        setModalOpen(mobileSidebarOpen);
    }, [mobileSidebarOpen]);

    React.useEffect(() => {
        setMobileSidebarOpen(false);
    }, [isMobileWide]);

    const logo = nudge_full;
    const logo_white = nudge_full_white;

    const logoutFunction = () => {
        logoutUser();
        toggleLogOut();
        toggleMobileSidebar();
    };

    if(!!modalOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'scroll';
    }

    return (
        <div>
            <LogOutModal isOpen={logOutOpen} toggle={toggleLogOut} logout={logoutFunction} />
            { !isMobileWide ? <Row style={{ position: 'absolute', top: 0, left: 0, width: '100vw', zIndex: 9999 }}>
                <Col lg={3}>
                    <Link to={URLS.HOME} style={{ marginTop: '20px' }}>
                        <img src={logo} alt="the Nudge logo" className={style.logo} />
                    </Link>
                </Col>
                <Col lg={9}>
                    <div class="flexy" style={{ justifyContent: 'right', padding: '20px', paddingTop: '32px', paddingRight: '12px', paddingBottom: '20px', columnGap: '25px' }}>
                        <Link className={style.link} to={URLS.ABOUTPAGE}>
                            <h5>{t('menu.about')}</h5>
                        </Link>

                        <Link className={style.link} to={URLS.COMPANYPAGE}>
                            <h5>{t('menu.companies')}</h5>
                        </Link>
                        
                        {<Link className={style.link} to={URLS.HOME_NEO_TALENT}>
                            <h5>{t('menu.neo_talent')}</h5>
                        </Link>}

                        <div className={style.middleBorder}/>

                        <Link className={style.link} to={loggedIn === true ? URLS.DASHBOARD : URLS.SIGN_IN}>
                            <h5>{t(loggedIn === true ? 'menu.dashboard' : 'menu.login')}</h5>
                        </Link>
                        
                        { loggedIn ? (
                            <Link className={style.link} onClick={() => toggleLogOut()}>
                                <h5>{t('menu.logout')}</h5>
                            </Link>
                        ) : null }
                    </div>
                    <div class={(isAuth && !user) && 'dots'}>
                        <h5  style={{ textAlign: 'right', color: 'black', paddingRight: '20px' }}>{ user ? `Welcome back, ${user.name}!` : loggedIn ? `Logging in` : null }</h5>
                    </div>
                </Col>
            </Row> : <Row>
                <Col md={12} style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
                    <div className={mobileSidebarOpen ? style.blocker : ''} onClick={toggleMobileSidebar}/>

                    <div id={`sidebar-${theme}`} className={mobileSidebarOpen ? style.mobileNavbarOpen : style.mobileNavbar}>
                        <h5 onClick={toggleMobileSidebar} className={style.bars}><FontAwesomeIcon icon={['fas', mobileSidebarOpen ? 'times' : 'bars']}/></h5>
                        
                        { (user && isAuth ) && <div class={'text-center txt-white'}>
                            <h5 style={{ margin: 0 }}>{ `Welcome back, ${user.name}` }</h5>
                        </div> }
                        <div class="flex-center-align-justify w-100">
                            <div class={`pt-10 pb-10`}>
                                <div class="flex-center-align-justify w-100" >
                                    <Link style={{ border: 'none', width: '100%' }} className={style.link} to={loggedIn === true ? URLS.DASHBOARD : URLS.SIGN_IN}>
                                        <h5>{t(loggedIn === true ? 'menu.dashboard' : 'menu.login')}</h5>
                                    </Link>
                                    { (!user && isAuth ) && <div class={'dots text-center txt-white'}>
                                        <h5>Logging in</h5>
                                    </div> }
                                    { loggedIn && <div style={{ height: '38px', width: '2px', background: 'rgba(0,0,0,0.2)' }} /> }
                                    { loggedIn ? 
                                        <Link style={{ border: 'none', width: '100%' }} className={style.link} onClick={() => toggleLogOut()}>
                                            <h5>{t('menu.logout')}</h5>
                                        </Link>
                                    : null }
                                </div>
                            </div>
                        </div>

                        <Link to={URLS.HOME}>
                            <img src={logo_white} alt="the Nudge logo" className={style.logoWhite} />
                        </Link>
                        <Link className={style.link} to={URLS.ABOUTPAGE}>
                            <h5>{t('menu.about')}</h5>
                        </Link>
                        <Link className={style.link} to={URLS.COMPANYPAGE}>
                            <h5>{t('menu.companies')}</h5>
                        </Link>
                        {<Link className={style.link} to={URLS.HOME_NEO_TALENT}>
                            <h5>{t('menu.neo_talent')}</h5>
                        </Link>}
                        
                        <div style={{ marginTop: '12px', color: 'white' }}>
                            <NudgeCheckbox
                                label="Page Theme"
                                checked={theme == 'light'}
                                onClick={toggleTheme}
                                labelOption={4}
                            />
                        </div>
                    </div>
                </Col>
            </Row> }


            {/* <Navbar light expand="lg" className={style.navbar}>
                <Link to={URLS.HOME} style={{ marginTop: '20px' }}>
                    <img src={logo} alt="the Nudge logo" className={style.logo} />
                </Link>

                <div className={style.loggedInBanner}>
                    <h5>{ user ? `Logged in as ${user.name}` : loggedIn ? `Authenticating ` : null }</h5>
                </div>

                <NavbarToggler onClick={toggle} className={style.navToggle}/>
                <Collapse isOpen={isOpen} navbar className={style.collapse}>
                    <Nav className={`ml-auto ${style.navItems} ${style.stickyNav}`} navbar>
                        <NavItem>
                            <Link to={URLS.ABOUTPAGE} className={!isMobileWide ? 'nav-link' : 'nav-link-mobile'}>
                                <h5 className={style.link}>{t('menu.about')}</h5>
                            </Link>
                        </NavItem>

                        <NavItem>
                            <Link to={URLS.COMPANYPAGE} className={!isMobileWide ? 'nav-link' : 'nav-link-mobile'}>
                                <h5 className={style.link}>{t('menu.companies')}</h5>
                            </Link>
                        </NavItem>

                        <NavItem className={style.middleBorder}>
                            <Link to={loggedIn === true ? URLS.DASHBOARD : URLS.SIGN_IN} className={!isMobileWide ? 'nav-link' : 'nav-link-mobile'}>
                                <h5 className={style.link}>{t(loggedIn === true ? 'menu.dashboard' : 'menu.login')}</h5>
                            </Link>
                        </NavItem>

                        { loggedIn ? (
                            <NavItem>
                                <Link onClick={() => toggleLogOut()} className={!isMobileWide ? "nav-link" : "nav-link-mobile"}>
                                    <h5 className={style.link}>{t('menu.logout')}</h5>
                                </Link>
                            </NavItem>
                        ) : null }
                    </Nav>
                </Collapse>
            </Navbar> */}
        </div>
    );
};

export default AppNavbarComp;
