import React from 'react';

const InfoRow = ({ title, text }) =>
    text ? (
        <div>
            <strong>{title}: </strong>
            {text}
        </div>
    ) : null;

export default InfoRow;
