import * as Yup from 'yup';

export const FIELDS = {
    NEW_PASSWORD: 'newPassword',
    CODE: 'code',
    REPEAT_PASSWORD: 'repeatNewPassword',
};

export const validationSchema = Yup.object({
    [FIELDS.CODE]: Yup.string().required('This field is required'),
    [FIELDS.NEW_PASSWORD]: Yup.string()
        .min(8, 'This field should be at last 8 chars length')
        .matches(
            /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
            'Password should contain upper and lower case letters and numbers',
        )
        .required('This field is required'),
    [FIELDS.REPEAT_PASSWORD]: Yup.string()
        .required('This field is required')
        .oneOf([Yup.ref(FIELDS.NEW_PASSWORD), null], 'Passwords must match'),
    [FIELDS.REPEAT_PASSWORD]: Yup.string().oneOf([Yup.ref(FIELDS.NEW_PASSWORD), null], 'Passwords must match'),
});

export const initialValues = {
    [FIELDS.NEW_PASSWORD]: '',
    [FIELDS.CODE]: '',
    [FIELDS.REPEAT_PASSWORD]: '',
};
