import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Card } from 'reactstrap';
import useSWR from 'swr';

import moment from 'moment';

import { authFetcher, NEVER_REFRESH, NO_REFRESH_INTERVAL } from '../../../api/tools';
import RejectButton from '../../../components/buttons/RejectButton';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import SimpleChatCard from '../../../components/SimpleChatCard';
import SwitchToDesktop from '../../../components/SwitchToDesktop';
import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { MATCH_STATUS } from '../../../consts/candidateStatuses';

import style from './style.module.scss';

import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeButton from '../../../components/buttons/NudgeButton';

const showCard = (status) => {
    switch (status) {
        case 'InterviewSubmitted':
        case 'InterviewScheduled':
            return true;
        default:
            return false;
    }
};

const ZoomPocPage = () => {
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const { data: connectionDetails } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/Matches/${id}/ConnectionDetails`,
        authFetcher,
        NEVER_REFRESH,
    );

    const [pageNumber, _setPageNumber] = React.useState(1);
    const pageRef = React.useRef(pageNumber);

    var showMeetingCard = false;

    function validateBidding(status) {
        switch (status) {
            case MATCH_STATUS.ACCEPTED:
            case MATCH_STATUS.COMPANYACCEPTED:
            case MATCH_STATUS.REJECTED:
            case MATCH_STATUS.COMPANYREJECTED:
                return false;
            default:
                return true;
        }
    }
    

    const { data, mutate, isValidating } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Matches/${id}`, authFetcher, NO_REFRESH_INTERVAL);
    const { data: chat, mutate: mutateChat, isValidating: loadingChat } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Matches/${id}/GetChat/${pageRef?.current}`, authFetcher, NEVER_REFRESH);

    const advancePage = (data) => {
        if(pageRef.current < chat?.maxPages) {
            pageRef.current = data;
            _setPageNumber(data);
        }
    };

    if (data) {
        showMeetingCard = showCard(data.matchStatus);
    }
    const [isOpen, setIsOpen] = useState(false);

    const InterviewButtons = ({ link, status }) => {
        return (
            <>
                <div className={style.buttonContainer}>
                    {status == 'WilloInvited' ? (
                        <>
                            <ClickButton
                                className={style.interviewButton}
                                color="success"
                                onClick={() => window.open(link, '_blank', 'noopener')}
                            >
                                Go to Interview
                            </ClickButton>
                        </>
                    ) : null}
                    {validateBidding(status) ? <RejectButton /> : null}
                </div>
            </>
        );
    };

    function fetchHeader(status) {
        const headers = {
            WilloInvited:
                'Click the button below to begin the video interview. Good luck!',
            InterviewSubmitted:
                'Thank you for submitting your interview. Your submission is being reviewed currently and you will be notified with results and/or feedback.',
            CompanyAccepted: 'Your application has been successful.',
            CompanyRejected: 'Your application has been unsuccessful. Please review the reason given below:',
            FaceInterviewScheduled: moment().isAfter(moment(data.faceInterviewDate)) ? `Scheduled interview time has passed. Awaiting final decision.` : `Your face-to-face interview has been scheduled for ${moment(data.faceInterviewDate).format('MMMM Do YYYY [at] h:mm a')}`,
            VRAssessmentScheduled: moment().isAfter(moment(data.vrAssessmentDate)) ?  `Scheduled assessment time has passed. Awaiting results.` : `Your VR assessment has been scheduled for ${moment(data.vrAssessmentDate).format('MMMM Do YYYY [at] h:mm a')}`,
            VRAssessmentSubmitted: `Your VR assessment has been submitted. The assesser is reviewing the results and will be in touch in due course.`,
            WaitingOnVRSchedule: `Waiting on company to schedule your VR assessment.`,
            WaitingOnFaceSchedule: `Waiting on company to schedule your face-to-face interview.`,
            ContractorInvited: `Awaiting your application. Go to the offer page to apply for this job.`
        };

        return headers[status] || "Header not found";
    }

    function showFeedback(status) {
        switch (status) {
            case 'CompanyAccepted':
            case 'CompanyRejected':
                return true;
            default:
                return false;
        }
    }

    return (
        <SignInPageTemplate>
            {/* <SwitchToDesktop /> */}
            {user && data ? (
                <>
                    <Card className={style.offerCard}>
                        <h5>{data.offerName}</h5>
                    </Card>
                    <Row>
                        <Col md={12} lg={8}>
                            {data.hasInterview || data.hasEdify || data.hasFaceToFace || data.matchStatus == "Negotiation" ? (
                                <>
                                    <Card className={style.headerCard}>
                                        { fetchHeader(data.matchStatus) ? <>
                                            <h5 className={style.feedbackHeader}>{fetchHeader(data.matchStatus)}</h5>
                                            {showFeedback(data.matchStatus) && data.feedback ? (
                                                <>
                                                    <h5 className={style.header}>The feedback given was:</h5>
                                                    <h5 className={style.feedback}>{data.feedback}</h5>
                                                    <br/>

                                                    { data.reportLink ? <>
                                                        <h5 className={style.header}>The feedback contains a report with information about your interview:</h5>
                                                        <NudgeButton style={{ width: '50%', margin: 'auto' }} onClick={() => window.open(data.reportLink, '_blank', 'noreferrer')}>Open Report</NudgeButton>
                                                    </> : null }
                                                </>
                                            )  : null}
                                        </> : null}
                                        <InterviewButtons link={data.interviewLink} status={data.matchStatus} />
                                    </Card>
                                </>
                            ) : (
                                <Card className={style.headerCard}>
                                    <h5>Zoom one-way interviews are no longer supported on the Nudge platform.</h5>
                                </Card>
                            )}
                        </Col>

                        <Col md={4} lg={4}>
                                <SimpleChatCard
                                    id={id}
                                    bidding={data?.bid}
                                    frequency={data?.frequency}
                                    chat={chat?.chats}

                                    sending={loadingChat}
                                    mutate={mutateChat}
                                    afterSendAction={mutateChat}

                                    advancePage={advancePage}
                                    pageRef={pageRef}
                                />
                        </Col>
                    </Row>
                </>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(ZoomPocPage, [USER]);
