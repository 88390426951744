import jsPDF from "jspdf";
import PDFMerger from "pdf-merger-js";
import { calculateLightness, convertHexToRGB, generateRandomString } from "../../pages/GenericCompanyProfile/HelperComponents";

import NudgeChalk from '../../../assets/images/nudge-logo-chalk-full.png'
import NudgeForest from '../../../assets/images/Nudge full logo.png';

export function getLines({ doc, text, textWidth }) {
    const lines = doc.splitTextToSize(text, textWidth);

    return 4;
}


export function addWrappedText({ text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10 }) {
    doc.setFontSize(fontSize);
    var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    var pageHeight = doc.internal.pageSize.height;        // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
    var cursorY = initialYPosition;

    textLines.forEach(lineText => {
        if (cursorY > pageHeight) { // Auto-paging
            doc.addPage();
            cursorY = pageWrapInitialYPosition;
        }
        doc.text(xPosition, cursorY, lineText);
        cursorY += lineSpacing;
    })

    return textLines.length;
}

export function StartNewPage(title, doc, pageHeight, boxHeight, colour, lightness) {
    var img = new Image()
    img.src = lightness > 50 ? NudgeForest : NudgeChalk;

    doc.addPage("a4", "p");
    doc.setTextColor(lightness < 50 ? "white" : "black");

    // Draw Page Header
    doc.setFontSize(16);
    doc.setFillColor(colour.r, colour.g, colour.b);
    doc.rect(0, 0, 225, 20, "F");
    doc.rect(0, pageHeight - boxHeight, 225, boxHeight, "F");
    doc.text(title, 5, 10);
    doc.addImage(img, 'png', 210 - 40, 5, 35, 9);

    //reset text colour
    doc.setTextColor("black");
    doc.setFontSize(12);
}

export async function generateFeedbackReport(data, givenFeedback, base64report, success) {
    var a = document.createElement("a");

    var y_incrementer = 32.5;
    const boxHeight = 27.5;

    const pageHeight = 300;
    const pageWidth = 210;
    

    const reportTitle = "Match Internal Feedback";

    const linkData = `data:application/pdf;base64,${base64report}`;

    const doc = new jsPDF();

    const mainColour = convertHexToRGB(`#${data.colour || ""}`);
    const bgColour = convertHexToRGB(`#ebebeb`);

    const lightness = calculateLightness(`#${data.colour || ""}`);

    var img = new Image()
    img.src = lightness > 50 ? NudgeForest : NudgeChalk;
    
    const textColour = lightness < 50 ? "white" : "black";
  
    const video = data.feedback.filter((item) => item.type == "video_interview");
    const vr = data.feedback.filter((item) => item.type == "vr_assessment");
    const face = data.feedback.filter((item) => item.type == "face_to_face");

    const total = [
        { label: 'Video Interview', data: video },
        { label: 'VR Assessment', data: vr },
        { label: 'Face-to-Face Interview', data: face },
    ]

    // DRAW PAGE HEADER AND FOOTER
    doc.setFillColor(mainColour.r, mainColour.g, mainColour.b);
    doc.rect(0, 0, 225, 20, "F");
    doc.rect(0, pageHeight - boxHeight, 225, boxHeight, "F");
    drawText(doc, `${reportTitle}`, lightness < 50 ? "white" : "black", 5, 10);
    doc.addImage(img, 'png', 210 - 40, 5, 35, 9);

    //DRAW FEEDBACK HEADER
    if(success) {
        doc.setFontSize(16); drawText(doc, "You have been successful.", "black", 5, y_incrementer); y_incrementer += 7;
        doc.setFontSize(12); drawText(doc, "Congratulations, you've been successful. The reason given was: ", "black", 5, y_incrementer); y_incrementer += 12;
    } else {
        doc.setFontSize(16); drawText(doc, "You have been unsuccessful.", "black", 5, y_incrementer); y_incrementer += 7;
        doc.setFontSize(12); drawText(doc, "Unfortunately, you've been unsuccessful this time. The reason given was: ", "black", 5, y_incrementer); y_incrementer += 12;
    }

    //DRAW FEEDBACK MAIN CONTENT
    var lMargin=5; //left margin in mm
    var rMargin=5; //right margin in mm
    var pdfInMM=210;  // width of A4 in mm

    var lines = doc.splitTextToSize(givenFeedback, (pdfInMM-lMargin-rMargin));
    lines.forEach((item) => item = item.trim())

    doc.setFontSize(12); doc.text(lMargin, y_incrementer, lines); y_incrementer += 12 + (5 * lines.length);
    doc.setFontSize(12); drawText(doc, "See the following page(s) for more specific feedback for each stage of the interview.", "black", 5, y_incrementer); y_incrementer += 12;

    //MOVE TO FIRST PAGE
    StartNewPage(`${reportTitle} - ${video?.length ? "Video Interview" : vr?.length ? "VR Assessment" : face?.length ? "Face-to-Face Interview" : ""}`, doc, pageHeight, boxHeight, mainColour, lightness);

    total.map((item, index) => {
        y_incrementer = 32.5;

        let total = 0;

        item.data.map((item, index) => {
            total += parseFloat(item.score)

            if(index == 0) lines = getLines({doc, text: item.feedback, textWidth: 180});

            doc.setFillColor(bgColour.r, bgColour.g, bgColour.b);
            doc.rect(2.5, y_incrementer - 7.5, 205, ((17.5) + (7*lines)), "F");
            
            doc.setFillColor(mainColour.r, mainColour.g, mainColour.b);
            doc.rect(2.5, y_incrementer + 25, 205, 12.5, "F");

            addWrappedText({
                doc,
                text: `"${item.feedback}"`, // Put a really long string here
                textWidth: 190,
                fontSize: 14,
                // Space between lines
                xPosition: lMargin,                // Text offset from left of document
                initialYPosition: y_incrementer,         // Initial offset from top of document; set based on prior objects in document
                pageWrapInitialYPosition: 10  // Initial offset from top of document when page-wrapping
            });
            y_incrementer += (5 + (7 * lines));
            
            doc.setFontSize(12); drawText(doc, item.companyName, textColour, 5, y_incrementer);
            doc.setFontSize(14); drawText(doc, `Score: ${item.score}`, textColour, 205, y_incrementer, 'right');

            

            y_incrementer += 20;
        })
        doc.setFontSize(18); drawText(doc, `Section Score: ${total / item.data.length}`, textColour, pageWidth -5, pageHeight - 10, 'right');

        if(index == 0) {
            if(vr?.length || face?.length) {
                StartNewPage(`${reportTitle} - ${vr?.length ? "VR Assessment" : face?.length ? "Face-to-Face Interview" : ""}`, doc, pageHeight, boxHeight, mainColour, lightness);
            }
        }
        else if(index == 1) {
            if(face?.length) {
                StartNewPage(`${reportTitle} - ${face?.length ? "Face-to-Face Interview" : ""}`, doc, pageHeight, boxHeight, mainColour, lightness);
            }
        }

    })

    doc.save(`report-${generateRandomString(5)}.pdf`);

    if(!!linkData) {
        var feedbackBlob =  new Blob([ doc.output() ], { type : 'application/pdf'});
        const merged = await merge(linkData, feedbackBlob);
        
        return merged;
    }
}

const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

async function merge(pdf1, pdf2) {
    var merger = new PDFMerger();
    
    await merger.add(pdf1);
    await merger.add(pdf2);
    
    const response = await merger.saveAsBlob();
    const awaited = await convertBase64(response);

    return awaited;
}

function drawText(doc, text, color, x, y, align = "left") {
    var ogCol = doc.getTextColor();
    doc.setTextColor(color)
    doc.text(text, x, y, align);
    doc.setTextColor(ogCol);
}