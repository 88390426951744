import fuzzysort from 'fuzzysort';
import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from 'react-autocomplete';

import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const AutocompleteInput = ({ suggestions = [], error, field, form, placeholder, classNames, invalid }) => {
    const { value } = field;
    const onChange = (value) => form.setFieldValue(field.name, value);
    const shouldItemRender = (item) => item && item.toLowerCase().indexOf(value && value.toLowerCase()) !== -1;

    let sortedSuggestions = suggestions;
    if (suggestions.length) {
        const results = fuzzysort.go(value, suggestions);

        sortedSuggestions = results.map((result) => result.target);
    }

    const theme = GetPageTheme();
    return (
        <>
            <Autocomplete
                wrapperStyle={{ background: theme == 'dark' ? '#474E68' : '#fff', width: '100%', border: error || invalid ? '1px solid red' : null, borderRadius: '5px' }}
                value={value}
                items={sortedSuggestions}
                getItemValue={(item) => item}
                shouldItemRender={shouldItemRender}
                renderMenu={(item) => <div id={`card-sub-${theme}`} className={style.dropdown}>{item}</div>}
                renderItem={(item) => (
                    <div className={style.item} key={`${item}`}>
                        { item }
                    </div>
                )}
                onChange={(_, value) => onChange(value)}
                onSelect={onChange}
                inputProps={{ className: `form-control ${classNames}`, placeholder, style: { background: theme == 'dark' ? '#6B728E' : '#fff' } }}
            />
        </>
    );
};

AutocompleteInput.propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default AutocompleteInput;
