import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../components/NudgeModal';

const SuccessModal = ({ isOpen, toggle }) => (
    <NudgeModal headerText={"Interest Registered"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
        <ModalBody className={style.modalBody}>
            <div className={style.text}>
                Information has been sent to Aberdeen Insurance. Somebody will be in touch.
            </div>

            <ClickButton size="lg" className={style.backButton} onClick={toggle}>
                Okay
            </ClickButton>
        </ModalBody>
    </NudgeModal>
);
SuccessModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default SuccessModal;
