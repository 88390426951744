import * as Yup from 'yup';

export const FIELDS = {
    TIME_SLOT: 'timeSlot',
    EMAIL: 'participantAddress'
};

export const validationSchema = Yup.object({
    [FIELDS.TIME_SLOT]: Yup.date().required('This field is required'),
    [FIELDS.EMAIL]: Yup.string().email("Must be a valid email.").required("This field is required")
});


export const formLabels = {
    [FIELDS.TIME_SLOT]: `Time Slot`,
    [FIELDS.EMAIL]: `Participant Email Address`
};

export const initialValues = {
    [FIELDS.TIME_SLOT]: '',
    [FIELDS.EMAIL]: '',
};
