import React from 'react';

import { Card, Row, Col } from 'reactstrap';
import { authPostData, patchData, prepareAuthHeaders } from '../../../../../api/tools';
import { getProfileCompletion, getParserCompletion, getTasks } from '../../../../../consts/tasks';
import {
    API_DASHBOARD_CLOOD, API_PROFILE
} from '../../../../../consts/urls';

import NudgeButton from '../../../../../components/buttons/NudgeButton';
import linkedInImg from '../../../../../assets/images/LI-In-Bug.png';

import style from './style.module.scss';

import { parseValues, parseCertificates, parseExperience } from './helpers';
import { testNewCertificates } from '../../../../SignUp/signUpHelper';

function ResolveDegree(degree) {
    if (degree.includes('Bachelor')) {
        return 'BSc';
    } else if (degree.includes('Master')) {
        return 'MSc';
    } else {
        return degree;
    }
}

const LinkedInParser = ({ user, mutate, toggle }) => {

    const [file, setFile] = React.useState('');
    const [fileName, setFileName] = React.useState('');

    const [sending, setSending] = React.useState(false);
    const [error, setError] = React.useState('');
    const [status, setStatus] = React.useState('');

    const tasks = getTasks(user);
    const percentage = getProfileCompletion(tasks);

    React.useEffect(() => {
        if (error != ' ' || error) {
            setTimeout(() => setError(''), 10000);
        }
    }, [error]);

    async function parseResume() {
        const obj = {
            base64: file.b64,
        };

        toggle();
        setSending(true);
        setStatus('Reading CV'); //
        const response = await authPostData(obj, `${API_DASHBOARD_CLOOD}/Parser/Submit`);
        //

        if (!response) {
            setSending(false);
            setError('Could not parse CV.');
            return;
        } else {
            if (response.status == 'error') {
                setSending(false);
                setError('Could not parse CV.');
                return;
            }
        }
        //

        const data = response.data;
        setStatus('Parsing fields');

        //Education
        const firstEducation = data.Education[0];
        const educationString = `${ResolveDegree(firstEducation.degree)}/${firstEducation.field}`;

        //Values
        const values = parseValues(data.Values);

        //Experience
        const experience = parseExperience(data.Experience);
        const current = experience.length ? experience.find((item) => item.present == true) : null;

        //Skills
        const currentSkills = user.skills.map((item) => item.name);

        const patchObj = {
            Username: data.Name,
            skills: currentSkills.concat(data.Skills),
            bio: data.Summary.trim(),
            education: educationString,
            workMode: typeof data.Location == "object" ? data.Location ? 'On location' : 'Remote' : data.Location, //,
            location: typeof data.Location == "object" ? "" : data.Location,

            Experience: user?.experience?.length ? experience.concat(user.experience) : experience,

            JobName: current ? current.Name : null,
            JobSubIndustryId: current ? user.job.subIndustryId || null : null,
            Company: current ? current.company : null,
            FreelancerStaff: data.Freelance || "Staff",
        }; 

        const followUp = await patchData(patchObj, `${API_PROFILE}`); 

        //Certificates
        setStatus('Uploading certifications');
        const certificates = parseCertificates(data.Certificates);
        if (certificates?.length) {
            const headers = await prepareAuthHeaders();
            await testNewCertificates(certificates, `${API_PROFILE}/Certificate`);
        }

        setStatus('Finished. Make sure to double check profile details.');
        if (mutate) await mutate();

        setSending(false);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadFile = async (event) => {
        const file = event.target.files[0];

        if(file) {
            const base64 = await convertBase64(file);

            var extension = base64.split(':')[1].split(';')[0].split('/')[1]; 

            if (extension == 'pdf') {
                setFile('' + base64);
                setFileName(file.name);
                setError('');
            } else {
                var doc = document.getElementById('user_details');
                doc.value = null;

                setError('Invalid file type. Must be a pdf.');
            }
        }
    };

    function clickInput() {
        var input = document.getElementById('user_details');

        if(input) {
            input.click();
        }
    }

    return getParserCompletion(tasks) ? (
        <>
            {user && !sending ? (
                <Card className={style.parserCard} style={{ color: 'white' }}>
                    <Row>
                        <Col xs={12} md={12} lg={6} className={style.col}>
                            <h5>
                                Use your LinkedIn CV to fill in parts of your Nudge profile automatically. Check profile
                                details on completion.
                            </h5>
                            <h5 onClick={() => window.open("https://help.nudgetalentlab.com/hc/en-gb/articles/10700594753949-How-to-create-a-profile-with-a-Linkedin-CV", '_blank', 'noreferrer')} className={style.link}>
                                Where do I find my LinkedIn CV?
                            </h5>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div className={style.fileInput}>
                                <input type="file" id="user_details" onChange={(e) => uploadFile(e)} style={{ display: 'none' }} />

                                <NudgeButton color="success" onClick={clickInput}>{file ? 'Change' : 'Choose' } File</NudgeButton>
                                <h5>{ file ? fileName : null}</h5>
                            </div>
                            <NudgeButton
                                className={style.submitButton}
                                disabled={!file}
                                color={'success'}
                                onClick={() => parseResume()}
                            >
                                Submit
                            </NudgeButton>
                        </Col>
                        <Col xs={6} md={6} lg={3} className={style.linkInCol}>
                            <img src={linkedInImg} className={style.linkIn} />
                        </Col>
                    </Row>

                    {error ? (
                        <>
                            {' '}
                            <br />
                            <h5>{error}</h5>{' '}
                        </>
                    ) : null}
                </Card>
            ) : null}
            {sending ? (
                <Card className={style.parserCard}>
                    <h4>{status}</h4>
                </Card>
            ) : null}
        </>
    ) : null;
};

export default LinkedInParser;
