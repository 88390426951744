import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody, CardTitle, Button } from 'reactstrap';

import { putData, deleteData } from '../../../api/tools';
import Loader from '../../Loader';
import EditableProject from './EditableProject';
import { initialValues, validationSchema, TAB_NAMES } from './validation';
import style from './style.module.scss';
import { API_DASHBOARD_CONTRACTOR, API_DASHBOARD_COMPANY } from '../../../consts/urls';
import NudgeTabs from '../../NudgeTabs';
import ClickButton from '../../buttons/NudgeButton';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import { ToastContext } from '../../../contexts/toast/ToastContext';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

const CompanyProjectForm = ({ canClose, onClickClose, refresh, url, info, showEdit, userTypes, userId, page }) => {
    const [key, setKey] = useState(false); // state variable used to force a re-render

    const { createToast } = React.useContext(ToastContext);

    const [isSending, setIsSending] = useState(false); //state variable for if data is being posted
    const [registering, setRegistering] = useState(false);

    const [projectEdit, setProjectEdit] = useState(canClose);
    const [currentTab, setCurrentTab] = useState(TAB_NAMES.DETAILS);

    const validateUserRole = {
        Company: userTypes.includes('Company'),
        User: userTypes.includes('User'),
        Admin: userTypes.includes('Admin'),
    };

    const onClose = () => {
        onClickClose ? onClickClose() : setProjectEdit(false);
    };
    const refreshData = async () => {
        await refresh();
        setKey(!key);
    };

    const registerInterest = async (pId) => {
        var lUrl = `${API_DASHBOARD_CONTRACTOR}/CompanyProject/Interest/${pId}`;

        setRegistering(true);
        try {
            await putData({}, lUrl, {});
        } catch (e) {
            setIsSending(false);
        }
        await refreshData();
        setRegistering(false);
    };

    const getMatched = () => {
        var canInterest = info?.interestedContractors.find((item) => item.contractorId === userId);
        if (registering) return true;

        return canInterest;
    };
    var isMatched = getMatched();

    const removeProject = async () => {
        let url = `${API_DASHBOARD_COMPANY}/CompanyProject/${info.projectId}`;

        if (window.confirm('Are you sure you want to remove this project?')) {
            try {
                setIsSending(true);
                await deleteData({}, url);
                createToast("Project successfully deleted.", "success");
            } catch (e) {
                createToast(e.message, "error");
                setIsSending(false);
            }
        }
        await refreshData();
        setIsSending(false);
    };

    const updateInfo = async (values) => {
        setIsSending(true);
        try {
            await putData(values, url, {});
        } catch (e) {
            setIsSending(false);
        }
    };

    const getStatistics = () => {
        let contractors = info?.interestedContractors;
        if (!contractors) return;
        if (validateUserRole['Admin']) return contractors;

        let industries = [];
        contractors.forEach((item) => {
            industries.push(item.industry);
        });
        industries = new Set(industries);

        let data = {};
        industries.forEach((item) => {
            if (data[item]) {
                data[item].count += 1;
            } else {
                data[item] = { name: item, count: 1 };
            }
        });
        return Object.values(data);
    };
    const data = getStatistics();

    const props = {
        initialValues: info ? info : initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await updateInfo(values);
            await refreshData();
            setIsSending(false);
            onClose();
        },
    };

    useEffect(() => {
        if (!canClose) setProjectEdit(false);
        setCurrentTab(TAB_NAMES.DETAILS);
    }, [page]);

    const tabs = [
        { id: TAB_NAMES.DETAILS, label: 'Details', condition: true },
        { id: TAB_NAMES.CANDIDATES, label: 'Candidates', condition: !userTypes.includes("User") },
    ]

    const theme = GetPageTheme();
    return (
        <Card className={style.cardContainer}>
            {!projectEdit ? (
                !isSending ? (
                    <div>
                        <div className={style.headerContainer}>
                            <CardTitle className={style.titleWrapper}>
                                <h5 className={style.projectTitle}>{info?.title}</h5>
                            </CardTitle>
                            <NudgeButtonGroup
                                buttons={[
                                    { label: 'Edit', onClick: () => setProjectEdit(true), available: showEdit && !projectEdit },
                                    { label: 'Delete', onClick: removeProject, available: showEdit && !projectEdit, color: 'danger' },
                                    { label: registering ? 'Registering...' : isMatched ? 'Registered' : 'Register Interest', onClick: () => registerInterest(info?.projectId), disabled: !!isMatched, available: !!userTypes.includes('User') }
                                ]}
                            />
                        </div>

                        <NudgeTabs
                            label={`${info?.title} - Sections`}
                            selected={currentTab}
                            setTab={setCurrentTab}
                            tabs={tabs}
                        />

                        { currentTab === TAB_NAMES.DETAILS ? (
                            <CardBody id={`card-sub-${theme}`} className={`${style.projectDescription} mb-20`}>
                                {info?.description}
                            </CardBody>
                        ) : (
                            <CardBody className={style.projectCandidateWrapper}>
                                <Card className={style.projectCandidate}>
                                    {data.length > 0
                                        ? data.map((item) => (
                                                <div>
                                                    <h5 className={style.candidateNameLabel}>
                                                        {validateUserRole['Company'] ? 'Discipline' : 'Name'}
                                                    </h5>
                                                    <h5 className={style.candidateIndustryLabel}>
                                                        {validateUserRole['Company']
                                                            ? 'No. Interested'
                                                            : 'Discipline'}
                                                    </h5>
                                                    <Row>
                                                        <Col xs="6" md="6">
                                                            {validateUserRole['Admin'] ? (
                                                                <a href={`/admin/contractors/${item.contractorId}`}>
                                                                    {item.name}
                                                                </a>
                                                            ) : (
                                                                <p>{item.name}</p>
                                                            )}
                                                        </Col>
                                                        <Col xs="6" md="6" className={style.industryDisplay}>
                                                            {validateUserRole['Company'] ? item.count : item.industry}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                        : 'No Interest Has Been Registered'}
                                </Card>
                            </CardBody>
                        )}
                    </div>
                ) : (
                    <Loader />
                )
            ) : !isSending ? (
                <Formik {...props}>
                    {(formik) => <EditableProject formik={formik} onClose={onClose} projectEdit={onClickClose} />}
                </Formik>
            ) : (
                <Loader />
            )}
        </Card>
    );
};

export default CompanyProjectForm;
