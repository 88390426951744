import React, { useState } from 'react';

import { Card, CardBody, Button } from 'reactstrap';
import { Formik } from 'formik';

import { ContainerCard } from '../../GenericCompanyProfile/HelperComponents';
import { useEditButton } from '../../../../components/buttons/EditButton';
import { initialValues, validationSchema } from './validate';

import { patchData } from '../../../../api/tools';
import Loader from '../../../Loader';

import EditableValues from './EditableValues';
import style from './style.module.scss';

import { sort_by_key_length } from '../../GenericCompanyProfile/HelperComponents';
import { updateCasebase } from '../../../ContractorInformation';
import NudgeCard from '../../../NudgeCard';
import NudgeBoxes from '../../../NudgeBoxes';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeTooltip from '../../../NudgeTooltip';

const Values = ({ user, data, showEdit, refresh, url, changeKey, canCancel = true, theme, reset }) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);
    const onClose = () => setIsOpen(false);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const props = {
        initialValues: data ? { contractorValues: data } : initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            await updateProfileAPI(values);
            await updateCasebase(user);

            await refresh();
            if (reset) reset();
            setIsSending(false);
            setIsOpen(false);
        },
    };

    let filteredData = sort_by_key_length(data, 'content');

    const updateProfileAPI = async (values) => {
        let contractorValues = values.contractorValues;
        await patchData({ contractorValues }, url, {});
    };

    return (
        <>
            <div className={style.profileHeader}>
                <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`values-header`} label={<b>{"Core Values"}</b>}>
                        <div class="text-center">
                            Tell us your core beliefs and values. This will help us determine companies you best fit within.
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={onClickEdit}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <NudgeCard theme={theme} className="mt-10 mb-10">
                
                    {isOpen ? (
                        !sending ? (
                            <Formik {...props}>
                                {(formik) => <EditableValues formik={formik} onClose={canCancel ? onClose : null} />}
                            </Formik>
                        ) : (
                            <Loader />
                        )
                    ) : filteredData.length ? (
                        <CardBody >
                            <NudgeBoxes
                                data={filteredData.map((item) => ({ label: item.content, danger: false}))}
                            />
                        </CardBody>
                    ) : (
                        <p style={{ fontStyle: 'italic', padding: '16px' }}>No core values have been added yet.</p>
                    )}
            </NudgeCard>
        </>
    );
};

export default Values;
