import * as Yup from 'yup';

export const FIELDS = {
    COMPANY_START: 'companyStart',
    COMPANY_NAME: 'companyName',
    COMPANY_ADDRESS: 'companyAddress',
    PRINCIPAL: 'principal',
    SHAREHOLDERS: 'shareholders',
    NO_SHARES: 'no_shares',
    VAL_SHARE: 'val_share',

    DIR_NAME: 'directorName',
    NI_NO: 'ni_no',
    DOB: 'date_of_birth',
    DIR_ADDRESS: 'directorAddress',
    DIR_DATEADDRESS: 'addressEffective',
    NAT: 'nationality',
    JOB_TITLE: 'job_title',
    DIR_TELNO: 'telephone',
    EMAIL: 'email',
    PASSPORT: 'passport',
    ASSESS_UTR: 'assess_utr',

    BOOKS: 'Bookkeeping',
    VAT: 'VAT Filing',
    PAYE: 'PAYE',
    SELF_ASSESS: 'Self Assessment',
};

export const validationSchema = Yup.object({
    [FIELDS.COMPANY_START]: Yup.date().typeError('Field must contain date value.').required('This field is required.'),
    [FIELDS.COMPANY_NAME]: Yup.string().required('This field is required.'),
    [FIELDS.COMPANY_ADDRESS]: Yup.string().required('This field is required.'),
    [FIELDS.PRINCIPAL]: Yup.string().required('This field is required.'),
    [FIELDS.SHAREHOLDERS]: Yup.number().typeError('This field must be a number.').required('This field is required.'),
    [FIELDS.NO_SHARES]: Yup.number().typeError('This field must be a number.').required('This field is required.'),
    [FIELDS.VAL_SHARE]: Yup.number().typeError('This field must be a number.').required('This field is required.'),

    [FIELDS.DIR_NAME]: Yup.string().required('This field is required.'),
    [FIELDS.NI_NO]: Yup.string()
        .required('This field is required.')
        .min(9, 'Invalid National Insurance Number')
        .max(9, 'Invalid National Insurance Number'),
    [FIELDS.DOB]: Yup.date().typeError('Field must contain date value.').required('This field is required.'),
    [FIELDS.DIR_ADDRESS]: Yup.string().required('This field is required.'),
    [FIELDS.DIR_DATEADDRESS]: Yup.date()
        .typeError('Field must contain date value.')
        .required('This field is required.'),
    [FIELDS.NAT]: Yup.string().required('This field is required.'),
    [FIELDS.JOB_TITLE]: Yup.string().required('This field is required.'),
    [FIELDS.DIR_TELNO]: Yup.string().required('This field is required.'),
    [FIELDS.EMAIL]: Yup.string().email('This field is required.'),
    [FIELDS.PASSPORT]: Yup.mixed().required('Upload is required'),
    [FIELDS.ASSESS_UTR]: Yup.number().typeError('This field must be a number.').required('This field is required.'),
});

export const initialValues = {
    [FIELDS.COMPANY_START]: '',
    [FIELDS.COMPANY_NAME]: '',
    [FIELDS.COMPANY_ADDRESS]: '',
    [FIELDS.PRINCIPAL]: '',
    [FIELDS.SHAREHOLDERS]: 5,
    [FIELDS.NO_SHARES]: '',
    [FIELDS.VAL_SHARE]: '',

    [FIELDS.DIR_NAME]: '',
    [FIELDS.NI_NO]: '',
    [FIELDS.DOB]: '',
    [FIELDS.DIR_ADDRESS]: '',
    [FIELDS.DIR_DATEADDRESS]: '',
    [FIELDS.NAT]: '',
    [FIELDS.JOB_TITLE]: '',
    [FIELDS.DIR_TELNO]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.ASSESS_UTR]: '',

    [FIELDS.BOOKS]: '',
    [FIELDS.VAT]: '',
    [FIELDS.PAYE]: '',
    [FIELDS.SELF_ASSESS]: '',
};
