import * as Yup from 'yup';

export const FIELDS = {
    REPORT_LINK: 'reportLink',
    VIDEO_LINK: 'videoLink'
}
// eslint-disable-next-line max-len
const VIDEO_REG_EX =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
export const validationSchema = Yup.object({
    [FIELDS.VIDEO_LINK]: Yup.string().matches(VIDEO_REG_EX, 'Must be a valid YouTube link').required('This field is required'),
    [FIELDS.REPORT_LINK]: Yup.mixed().required('Upload is required'),
})

export const initialValues = {
    [FIELDS.REPORT_LINK]: null,
    [FIELDS.VIDEO_LINK]: ''
}