import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Row, Col, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkButton from '../buttons/LinkButton';

import { FullStatCardScaling, GetPageTheme, OfferClosedFunction, ResolveDaysRemaining, ResolveSalary } from '../pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import { MATCH_STATUSES_DICTIONARY } from '../../consts/matchOfferStatuses';
import NudgeBoxes from '../NudgeBoxes';
import NudgeButton from '../buttons/NudgeButton';
import NudgeModal from '../NudgeModal';
import NudgeButtonGroup from '../buttons/ButtonGroup';

const Offer = ({ offer, className = '', url, select }) => {
    const data = offer.offerMatches;

    const navigate = useNavigate();

    const [matchesOpen, setMatchesOpen] = React.useState(false);
    const toggleMatches = () => setMatchesOpen(!matchesOpen);

    function getTypeInfo(input) {
        switch (input) {
            case 'Freelance':
            case 'Pay-E':
                return 'Contract Work';
            case 'Staff':
                return 'Permanent, full-time';
            default:
                return '';
        }
    }
    
    return (
        <Card className={`${style.offer}`}>
            <div className={style.header}>
                <Row>
                    <Col xs={12} lg={8}>
                        <div className={style.jobTitle}>
                            <Link to={`${url}/${offer.id}`} className={style.nameLink}>
                                {offer.name}
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <NudgeButtonGroup
                            buttons={[
                                { label: offer.offerStatus === 'Closed' ? 'Repost' : 'Edit', onClick: () => navigate(`/company/edit-contract/${offer.id}`), available: true },
                                { label: 'Matches', onClick: () => select(offer), available: data.length },
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NudgeBoxes
                            data={[
                                { label: offer.location ? offer.location : 'Remote', danger: false },
                                { label: offer.workType != "Volunteer" ? ResolveSalary(offer.rangeExpected, offer.currencyType, offer.frequency, offer.workType) : "Volunteer Position", danger: false },
                                { label: getTypeInfo(offer.type), danger: false },
                                ResolveDaysRemaining(offer)
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className={style.date}>
                            PUBLISH DATE: {offer.publishDate} - EXPIRY DATE: {offer.closeDate}{' '}
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FullStatCardScaling
                            header={""}
                            data={[
                                { label: "Matched", data: data ? data.length : <FontAwesomeIcon className={style.loading} icon={['far', 'sync-alt']} />  },
                                { label: "Awarded", data: offer ? offer.awardedMatches : <FontAwesomeIcon className={style.loading} icon={['far', 'sync-alt']} /> },
                                { label: "Views", data: offer ? offer.offerViews : <FontAwesomeIcon className={style.loading} icon={['far', 'sync-alt']} /> },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </Card>
    );
};

Offer.propTypes = {
    offer: PropTypes.object.isRequired,
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
};

export default Offer;
