import { Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, FormGroup } from 'reactstrap';

import AppInput from '../../../AppInput';
import { FIELDS } from './validate';
import ClickButton from '../../../buttons/NudgeButton';

const BiddingCardForm = ({ formik, additional, frequency }) => {
    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-20 ">
                <AppInput id={FIELDS.SALARY} formik={formik} label={`Rate GBP ${frequency}`} />
            </FormGroup>

            <FormGroup className="mb-20 ">
                <AppInput id={FIELDS.COMMENT} formik={formik} label="Comment (Optional)" type="textarea" />
            </FormGroup>

            <div className="text-center">
                <ClickButton color="success" type="submit">
                    Send offer
                </ClickButton>
                {additional}
            </div>
        </Form>
    );
};

BiddingCardForm.propTypes = {
    formik: PropTypes.object.isRequired,
    additional: PropTypes.any,
};

export default BiddingCardForm;
