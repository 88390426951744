/* eslint-disable quotes */
/* eslint-disable max-len */
import AberdeenImage from '../../../assets/images/aberdeenLogo.jpg';
import honestMobileLogo from '../../../assets/images/honestmobile.png';
import kingsbridgeLogo from '../../../assets/images/kingsbridgeLogo.jpg';
import survivexLogo from '../../../assets/images/survivexLogo.png';
//Add new

const poundSign = String.fromCharCode(163);

export const articleArray = [
    {
        title: 'AIS Survivex',
        subtitle: "The UK's Largest Energy Sector Training Provider",
        image: survivexLogo,
        body: 'At AIS Survivex we are passionately focused on allowing people ti fulfill their potential, be better prepared and more confident in their job role to maximise their career opportunities.',
        body2: 'Search and book hundreds of safety, skills, and technical training courses. Get in touch today to find out more at 01224 794 800 or training@aissurvivex.com',
        action: 'survivex',
        link: 'https://www.survivex.com',
        headline: '0% Finance Available',
        section: 0,
    },
    {
        title: 'Kingsbridge- Freelancers Insurance',
        subtitle: 'Employers Liability and Public Liability',
        image: kingsbridgeLogo,
        body: 'Kingsbridge are the experts in limited company insurance.',
        body2: 'Get in touch for a no obligation quote for all your contracting needs by calling us on 01242 808 740.',
        action: 'kingsbridge',
        headline: '10% Discount',
        section: 0,
    },
    {
        title: 'Aberdeen Accountants- Company Set Up Support',
        subtitle: 'Accountancy Services and Book Keeping',
        image: AberdeenImage,
        body: 'Aberdeen Accounts is an innovative business advisory & Chartered Accountants firm.',
        body2: 'Company set up- ' + poundSign + '95 + VAT\nAccountancy Services- from ' + poundSign + '90 + VAT/month',
        action: 'aberdeen',
        headline: 'Fixed Fee',
        section: 0,
    },
    {
        title: 'Honest Mobile',
        subtitle: 'Carbon Negative Mobile Network',
        image: honestMobileLogo,
        body: "Honest Mobile offers simple, affordable mobile contracts that are better for the planet. They are the world's first carbon negative mobile network and are B Corp certified.",
        body2: 'Nudge members get 50% off their first month using code nudge50!',
        headline: '50% off 1st month',
        link: 'https://honestmobile.co.uk/nudge/',
        action: 'honestMobile',
        section: 1,
    },
    {
        title: 'Interview Success',
        subtitle: 'CV Review and Development',
        image: '',
        body: 'Mike works with individuals to help them home and optimise their CVs to maximise their impact and increase the chances of being selected for interview.',
        body2: 'Interviews are challenging. Your competition will be preparing for that interview, but not everyone will invest in the benefits that individualised interview coaching could give them. The crucial question is therefore whether you want to be the candidate with that crucial edge?',
        body3: 'Mike is an experienced coach and a member of the British Psychological Society coaching section, as well as a qualified chartered and occupational psychologist. He offers you many years of experience working with interviewees to help them optimise their CV and then to succeed in their interviews. ',
        headline: 'Interview Coaching',
        link: 'https://www.interviewsuccess.uk',
        //action: 'interviewSuccess',
        section: 1,
    },
];
