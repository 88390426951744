import { Formik } from 'formik';
import React, { useState } from 'react';

import { putData } from '../../../api/tools';
import Loader from '../../Loader';
import EditableBVTForm from './EditableBVTForm';
import { initialValues, validationSchema } from './validation';
import { COMPANY } from '../../../consts/userRoles';
import style from './style.module.scss';

const BenefitsValuesTraitsForm = ({ id, tabs, setSelectedTab, selectedTab, onClickClose, refresh, url, bioType = COMPANY, info }) => {
    const [key, setKey] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const onClose = () => onClickClose(false);
    const refreshData = async () => {
        await refresh();
        setKey(!key);
    };

    const updateInfo = async (values) => {
        setIsSending(true);
        try {
            await putData(values, url, {});
        } catch (e) {
            setIsSending(false);
        }
    };

    const extractValues = async (values) => {
        let newObject = {};

        newObject.companyBenefits = await correctValues(values.companyBenefits);
        newObject.companyValues = await correctValues(values.companyValues);
        newObject.companyTraits = await correctValues(values.companyTraits);

        return newObject;
    };

    const correctValues = async (obj) => {
        obj.forEach((item) => {
            if (!item.companyId) item.companyId = info.id;
        });

        return obj;
    };

    const props = {
        initialValues: info ? info : initialValues,
        validationSchema,
        onSubmit: async (values) => {
            values = await extractValues(values);
            await updateInfo(values);
            await refreshData();
            onClickClose();
        },
    };

    function parseTabs(formik, tabs) {
        if(!formik || !tabs) return [];

        if(formik.submitCount > 0) {
            var errors = formik?.errors;
            tabs.forEach((item, index) => {
                item.hasError = errors[item.id] ? true : false;
            })
        }

        return tabs;
    }

    return (
        <div className={style.container}>
            {!isSending ? (
                <Formik {...props}>
                    {(formik) => <EditableBVTForm id={id} tabs={parseTabs(formik, tabs)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} formik={formik} onClose={onClose} bioType={bioType} />}
                </Formik>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default BenefitsValuesTraitsForm;
