import * as Yup from 'yup';

export const FIELDS = {
    EMAIL: 'email',
};

export const validationSchema = Yup.object({
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
});

export const initialValues = {
    [FIELDS.EMAIL]: '',
};
