import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../../../components/NudgeModal';

const SuccessModal = ({ isOpen, toggle, flip }) => {
    const flipAndToggle = () => {
        flip();
        toggle();
    };

    return (
        <NudgeModal headerText={"Interest Registered"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                <div className={style.text}>Interest registered in offer.</div>

                <ClickButton color="success" size="lg" className={style.backButton} onClick={flipAndToggle}>
                    Okay
                </ClickButton>
            </ModalBody>
        </NudgeModal>
    );
};
SuccessModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default SuccessModal;
