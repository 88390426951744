import React from 'react';
import PropTypes from 'prop-types';
import MessageInput from './MesaageInput';
import MessagesWrapper from './MessagesWrapper';

import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import { parseMergedChat } from './helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../buttons/NudgeButton';

const SimpleChatCard = ({ chat = [], sending, afterSendAction, company, bidding, id, mutate, frequency, offerStatus, advancePage, pageRef }) => {
    const [chatOpen, setChatOpen] = React.useState(false);
    const toggleChatOpen = () => setChatOpen(!chatOpen);

    const { user } = React.useContext(AuthContext);

    const [showScrollDown, setShowScrollDown] = React.useState(false);

    const [currentScroll, setCurrentScroll] = React.useState(0);

    function checkScroll() {
        let messages = document.getElementById('message-wrapper');
        
        setCurrentScroll(messages.scrollHeight);

        if(messages.scrollTop == 0) {
            advancePage(pageRef?.current + 1);
        }

        if(messages.scrollHeight < 750) {
            setShowScrollDown(false);
        }
        else {
            let scrollThreshold = (messages.scrollHeight) * 0.6;
        
            if((messages.scrollTop + messages.offsetHeight) > scrollThreshold) {
                setShowScrollDown(false);
            } else {
                setShowScrollDown(true);
            }
        }
    }

    function scrollBottom() {
        let messages = document.getElementById('message-wrapper');
        
        messages.scrollTo({
            top: messages.scrollHeight,
            behavior: 'smooth',
        });
    }

    React.useEffect(() => {
        let messages = document.getElementById('message-wrapper');

        messages.removeEventListener('scroll', checkScroll);
        messages.addEventListener('scroll', checkScroll);
    }, []);

    document.body.style.overflow = chatOpen ? 'hidden' : 'scroll';
    
    return (
        <>
            <div className={!chatOpen ? style.topBufferClosed : style.topBuffer} onClick={toggleChatOpen} />
            <Card className={!chatOpen ? style.chatCardWrapperClosed : style.chatCardWrapper}>
                <CardBody>
                    <Row>
                        <Col md={12} className={style.chatHeader}>
                            <FontAwesomeIcon
                                onClick={toggleChatOpen}
                                className={style.showChat}
                                icon={['far', chatOpen ? 'comment-alt-minus' : 'comment-alt-plus']}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} md={6}>
                            <h5>
                                Chat
                            </h5>
                        </Col>
                        <Col xs={9} md={6}>
                            <NudgeButton style={{ width: '100%' }} disabled={!!sending} onClick={() => { mutate(); }}>
                                <FontAwesomeIcon icon={['fad', 'sync-alt']} />
                            </NudgeButton>
                        </Col>
                    </Row>
                    <br/>

                    <MessagesWrapper chat={chat} user={user} sending={sending} showScroll={showScrollDown} scroll={scrollBottom} pageRef={pageRef} currentScroll={currentScroll}/>
                    
                    <MessageInput
                        afterSendAction={afterSendAction}
                        company={company}
                        bidding={bidding}
                        id={id}
                        frequency={frequency}
                        mutate={mutate}
                        offerStatus={offerStatus}

                        advancePage={advancePage}
                        pageRef={pageRef}
                        messages={chat?.length}
                    />
                </CardBody>
            </Card>
        </>
    );
};

SimpleChatCard.propTypes = {
    chat: PropTypes.array.isRequired,
    className: PropTypes.string,
    afterSendAction: PropTypes.func.isRequired,
    company: PropTypes.bool,
};

export default SimpleChatCard;
