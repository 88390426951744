import React from 'react';
import Select from 'react-select';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

import style from './style.module.scss';
import useMobile from '../../hooks/useMobile';
import { customStyles } from '../SelectField';
import NudgeButton from '../buttons/NudgeButton';
import TabModal from './TabModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'reactstrap';

export function UseTabs(initial) {
    const [tab, setTab] = React.useState(initial);

    return { tab, setTab }
}

const NudgeTabs = ({ selected, setTab, tabs, minHeight, buttons, label, changeFunction, dots }) => {
    const { isMobileWide } = useMobile();
    const theme = GetPageTheme();


    if(!tabs) {
        tabs = [
            { id: 'example-tab', label: 'Example', condition: true },
        ]
    }
    tabs = tabs.filter((item) => !!item.condition);

    var selectedIndex = 0;
    tabs.forEach((item, index) => {
        if(item.id == selected) {
            selectedIndex = index
        }
    })


    function ResolveTabStyle(i, tab) {
        var tabs = getTabCount();

        var constructor = ``;

        if (tabs == 1) {
            constructor += style.tab
        } else if(tabs == 2) {
            if(i == 0) {
                constructor += style.tabLeft
            } else {
                constructor += style.tabRight;
            }
        } else {
            if (i == 0) {
                constructor += style.tabLeft;
            } else if (i == tabs - 1) {
                constructor += style.tabRight;
            } else {
                constructor += style.tabMiddle;
            }
        }
        

        if(selected == tab.id) {
            constructor += tab.hasError ? ` ${style.selectedTabError}` : theme == 'light' ? ` ${style.selectedTabLight}` : ` ${style.selectedTabDark}`
        }
        else {
            constructor += tab.hasError ? ` ${style.tabError}` : theme == 'light' ? ` ${style.tabLight}` : ` ${style.tabDark}`
        }
        


        return constructor;
    }

    function getTabCount() {
        var count = 0;
        tabs.forEach((p) => {
            if(p.condition) {
                count++;
            }
        })
        return count;
    }

    const [showTabModal, setShowTabModal] = React.useState(false);
    function toggleTabModal() {
        setShowTabModal(!showTabModal);
    }
    
    function tabFunction(input) {
        if(input != selected) {
            setTab(input);
    
            if(changeFunction) changeFunction();
        }
    }

    return tabs.length ? (<>
        
        { isMobileWide || buttons ? <>
            <div className={style.mobileTabContainer}>
                <TabModal label={label} isOpen={showTabModal} toggle={toggleTabModal} tabs={tabs} setTab={setTab} selected={selected}/>
                
                    <NudgeButton color={tabs[selectedIndex - 1]?.hasError ? 'danger': 'success'} style={{ width: '25%'}} disabled={selectedIndex == 0} onClick={() => tabFunction(tabs[selectedIndex - 1].id)}>Back</NudgeButton>

                    <NudgeButton color={tabs[selectedIndex].hasError ? 'danger': 'success'} style={{ width: '100%', display: 'flex', columnGap: '6px', alignItems: 'center', justifyContent: 'center'}} disabled={tabs.length == 1 || !isMobileWide} selected onClick={tabs.length > 1 ? toggleTabModal : null }>
                        {tabs[selectedIndex].label}
                        {tabs[selectedIndex].hasError ? <FontAwesomeIcon icon={['fas', 'exclamation-circle']}/> : ''}
                    </NudgeButton>

                    <NudgeButton color={tabs[selectedIndex + 1]?.hasError ? 'danger': 'success'} style={{ width: '25%'}} disabled={selectedIndex == tabs.length - 1} onClick={() => tabFunction(tabs[selectedIndex + 1].id)}>Next</NudgeButton>
            </div>
            { dots ? <div class="flexy" className={style.tabDotContainer}>
                { tabs.map((tab, index) => <>
                    <div className={`${tab.hasError ? style.tabDotError : theme == 'light' ? style.tabDot : style.tabDotDark} ${ selectedIndex == index ? theme == 'light' ? style.selectedTabDot : style.selectedTabDotDark : ''}`}/>
                </>)}
            </div> : null }
        
        </>: <div className={style.tabContainer}>
                { tabs.map((tab, index) => 
                    tab.condition ? <div key={`nudge-box-${index}`} id={`tabs-${theme}`} style={{ minHeight: minHeight || 0 }} onClick={() => tabFunction(tab.id)}  className={`${ResolveTabStyle(index, tab)}`} xs={12} md={4}>
                        <h5>{tab.label} {tab.hasError ? (<FontAwesomeIcon icon={['fas', 'exclamation-circle']}/>) : ''}</h5>
                    </div> : null
                )}
            </div>
            }
    </>) : null
}

export default NudgeTabs;