import { Form } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import PlusButton, { usePlusButtonWithAlwaysMinus } from '../../../buttons/PlusButton';

import SaveButton from '../../../buttons/SaveButton';
import IndustryExperienceFormBody from '../../../forms/IndustryExperienceForm/IndustryExperienceFormBody';

import DURATION_OPTIONS from '../../../forms/IndustryExperienceForm/durationOptions';
import ClickButton from '../../../buttons/NudgeButton';

const EditableProjectHistory = ({ onClose, formik }) => {
    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);

    return (
        <>
            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                {isPlusButtonShowed('projects') ? (
                    <Row>
                        <Col md={6}>
                            <i>Nothing added yet.</i>
                        </Col>
                        <Col md={6}>
                            <ClickButton
                                style={{ width: '100%' }}
                                color="success"
                                onClick={onClickPlusButton('projects', [
                                    { name: '', duration: DURATION_OPTIONS[0].value, ref_email: '' },
                                ])}
                            >
                                Add
                            </ClickButton>
                        </Col>
                    </Row>
                ) : null}
                <IndustryExperienceFormBody formik={formik} alwaysShowMinus />
                <br />

                <SaveButton  onClose={onClose} />
            </Form>
        </>
    );
};

EditableProjectHistory.propTypes = {
    onClose: PropTypes.func.isRequired,
    formik: PropTypes.object.isRequired,
};

export default EditableProjectHistory;
