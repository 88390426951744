import React, { useContext, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { authFetcher, NEVER_REFRESH, NO_REFRESH_INTERVAL } from '../../../api/tools';
import RejectButton from '../../../components/buttons/RejectButton';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import SimpleChatCard from '../../../components/SimpleChatCard';
import { MATCH_STATUSES } from '../../../consts/matchOfferStatuses';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import AcceptButton from './components/AcceptButton';
import AcceptInfo from './components/AcceptInfo';
import AnswerViewer from './components/AnswerViewer';

import style from './style.module.scss';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import CalendarModal from './components/CalendarModal';
import moment from 'moment';
import AssessmentFeedbackCard from './components/FeedbackForms/AssessmentFeedbackCard';
import FaceToFaceFeedbackCard from './components/FeedbackForms/FaceToFaceFeedbackCard';
import CompanyVideo from '../../../components/pages/FullCompanyProfile/PageComponents/CompanyVideo';

import { getHeader, getSubtitle, isFinalStage, canProgress, getButtonText } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculateLightness, generateMonoLinearGradient, generateRandomString, GetPageTheme, ResolveSalary } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeTabs from '../../../components/NudgeTabs';
import NudgeBoxes from '../../../components/NudgeBoxes';
import NudgeButton from '../../../components/buttons/NudgeButton';
import ScoreBreakdown from '../../../components/OpportunityPages/CompanyAdmin/CompanyMatching/ScoreBreakdown';
import useSWR from 'swr';

const InterviewDetails = () => {
    const { user } = useContext(AuthContext);
    const theme = GetPageTheme();

    const [isOpen, setIsOpen] = useState(false);
    const { id } = useParams();

    const [sending, setSending] = React.useState(false);

    const [pageNumber, _setPageNumber] = React.useState(1);

    const pageRef = React.useRef(pageNumber);

    const { data, mutate, isValidating } = useSWR(`${API_DASHBOARD_COMPANY}/Matches/${id}`, authFetcher, NEVER_REFRESH);
    const { data: chat, mutate: mutateChat, isValidating: loadingChat } = useSWR(`${API_DASHBOARD_COMPANY}/Matches/${id}/GetChat/${pageRef?.current}`, authFetcher, NEVER_REFRESH);

    const maxPageRef = React.useRef(chat?.maxPages);

    const advancePage = (data) => {
        if(pageRef.current < maxPageRef.current) {
            pageRef.current = data;
            _setPageNumber(data);
        }
    };

    React.useEffect(() => {
        if(!!chat) maxPageRef.current = chat.maxPages;
    }, [chat])

    if(data) {
        data.status = MATCH_STATUSES.Negotiation;
    }

    function validateBidding(status) {
        switch (status) {
            case MATCH_STATUSES.WilloInvited:
            case MATCH_STATUSES.ContractorInvited:
            case MATCH_STATUSES.Accepted:
                return false;
            default:
                return true;
        }
    }

    const [pageState, setPageState] = React.useState({
        header: '',
        subtitle: '',
        dates: {
            vr: '',
            face: '',
        },
    });

    function updatePageState(data) {
        var _pageState = pageState;

        pageState.header = getHeader(data);
        pageState.subtitle = getSubtitle(data);

        pageState.dates.vr = moment(data.vrAssessmentDate).local().format('MMMM Do YYYY [at] h:mm a');
        pageState.dates.face = moment(data.faceInterviewDate).local().format('MMMM Do YYYY [at] h:mm a');

        setPageState(_pageState);
    }

    let showInterview = false;
    // let bidActive = true;
    if (data) {
        showInterview = validateBidding(data.status);
        //if (data.bid.status == 'accepted') bidActive = false;
    }

    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const toggleCalendar = () => setCalendarOpen(!calendarOpen);

    const TABS = {
        VIDEO: 'videoTab',
        VR: 'vrTab',
        FACE: 'faceTab',
    };

    const tabArray = [
        { label: 'Video Interview', id: TABS.VIDEO, condition: data?.hasInterview || data?.answers },
        { label: 'VR Assessment Results', id: TABS.VR, condition: data?.hasEdify },
        { label: 'Face-to-Face Interview', id: TABS.FACE, condition: data?.hasFaceToFace },
    ];

    const [selectedTab, setSelectedTab] = React.useState();

    const [videoModalOpen, setVideoModalOpen] = React.useState(false);
    const toggleVideoModal = () => {
        setVideoModalOpen(!videoModalOpen);
    };

    const [fileLink, setFileLink] = React.useState();
    React.useEffect(() => {
        if (data) {
            const { hasInterview: video, hasEdify: edify, hasFaceToFace: face } = data;

            if (video) {
                setSelectedTab(TABS.VIDEO);
            } else if (edify) {
                setSelectedTab(TABS.VR);
            } else if (face) {
                setSelectedTab(TABS.FACE);
            }

            updatePageState(data);

            if (
                data.status == MATCH_STATUSES.VRAssessmentScheduled ||
                data.status == MATCH_STATUSES.FaceInterviewScheduled
            ) {
                setFileLink(createFile());
            }
        }
    }, [data]);

    function getGoogleMeetLink(d) {
        var dateInitial = '';
        var title = `${d.offerName}: `;

        if (d.status == MATCH_STATUSES.VRAssessmentScheduled) {
            title += `VR Assessment with ${d.contractor.name}`;
            dateInitial = d.vrAssessmentDate;
        } else {
            title += `Face-to-Face Interview with ${d.contractor.name}`;
            dateInitial = d.faceInterviewDate;
        }

        const dateObj = dateInitial;
        const gMeetLink = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&details=${''}&location=${''}&dates=${moment(
            dateObj,
        ).format('yyyyMMDDTHHmmssZ')}/${moment(dateObj).add(30, 'm').format('yyyyMMDDTHHmmssZ')}`;

        return (
            <a href={gMeetLink} target="_blank" rel="noopener noreferrer">
                Google
            </a>
        );
    }

    function getMicrosoftMeetLink(d) {
        var dateInitial = '';
        var title = `${d.offerName}: `;

        if (d.status == MATCH_STATUSES.VRAssessmentScheduled) {
            title += `VR Assessment with ${d.contractor.name}`;
            dateInitial = d.vrAssessmentDate;
        } else {
            title += `Face-to-Face Interview with ${d.contractor.name}`;
            dateInitial = d.faceInterviewDate;
        }

        const dateObj = dateInitial;

        const mMeetLink = `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=${''}&enddt=${moment(
            dateObj,
        )
            .add(30, 'm')
            .format(
                'yyyy-MM-DDTHH:mm:ss',
            )}&location=${''}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${moment(dateObj).format(
            'yyyy-MM-DDTHH:mm:ss',
        )}&subject=${title}`;

        return (
            <a href={mMeetLink} target="_blank" rel="noopener noreferrer">
                Microsoft
            </a>
        );
    }

    var icsFile = null;
    function createFile() {
        var link = {};
        link.href = makeIcsFile(data); 

        return link.href;
    }

    function makeIcsFile(d) {
        //date, summary, description
        var dateInitial = '';
        var title = `${d.offerName}: `;

        if (d.status == MATCH_STATUSES.VRAssessmentScheduled) {
            title += `VR Assessment with ${d.contractor.name}`;
            dateInitial = d.vrAssessmentDate;
        } else {
            title += `Face-to-Face Interview with ${d.contractor.name}`;
            dateInitial = d.faceInterviewDate;
        }

        const dateObjectStart = moment(dateInitial);
        const dateObjectEnd = moment(dateInitial).add(30, 'm');

        var test =
            'BEGIN:VCALENDAR\n' +
            'CALSCALE:GREGORIAN\n' +
            'METHOD:PUBLISH\n' +
            'PRODID:-//Test Cal//EN\n' +
            'VERSION:2.0\n' +
            'BEGIN:VEVENT\n' +
            'UID:test-1\n' +
            `DTSTART:${dateObjectStart.format('yyyyMMDDTHHmmss')}` +
            '\n' +
            `DTEND:${dateObjectEnd.format('yyyyMMDDTHHmmss')}` +
            '\n' +
            'SUMMARY:' +
            title +
            '\n' +
            'DESCRIPTION:' +
            '' +
            '\n' +
            'END:VEVENT\n' +
            'END:VCALENDAR';

        var blob = new File([test], { type: 'text/plain' });
        if (icsFile !== null) {
            window.URL.revokeObjectURL(icsFile);
        }

        icsFile = window.URL.createObjectURL(blob);

        return icsFile;
    }
    
    const FREQUENCIES = {
        Daily: 'DAY',
        Hourly: 'HOUR',
        Yearly: 'YEAR',
    };

    const processSearchStatus = (status) => {
        switch (status) {
            case 'Active':
                return 'Actively searching';
            case 'Passive':
                return 'Interested in work';
            default:
                return '';
        }
    };

    const [scoreOpen, setScoreOpen] = React.useState();
    const toggleScore = () =>  setScoreOpen(!scoreOpen);
    function compileScores () {
        var smart = data.smartMatch;

        var user = {
            Id: data.contractor.id,
            profileJob: null,
            match_explanation: [
                { field: "Services Required", similarity: smart.services },
                { field: "Remuneration", similarity: smart.remuneration },
                { field: "Education", similarity: smart.education },
                { field: "Experience", similarity: smart.experience },
                { field: "Certifications", similarity: smart.certificates },
                { field: "Skills", similarity: smart.skills },
                { field: "Values", similarity: smart.user_Values },
            ],
            feedback: JSON.parse(smart?.feedback),
            totalScore__: smart?.totalScore
        }

        if(user?.feedback) {
            var fb = user.feedback;

            if(fb.Skills) {
                var s = fb.Skills;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Skills
                }
            }

            if(fb.Certificates) {
                var s = fb.Certificates;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Certificates
                }
            }

            if(fb.Values) {
                var s = fb.Values;

                if(!s.scores.length || !s.applicantElements.length || !s.requiredElements.length) {
                    delete fb.Values
                }
            }

            user.feedback = fb;
        }

        return user;
    }


    return (
        <SignInPageTemplate>
                <>
                    { data?.smartMatch ? <ScoreBreakdown
                        user={compileScores()}
                        offerId={data.offerId}
                        toggle={toggleScore}
                        isOpen={scoreOpen}
                        stored
                    /> : null }
                    <Card className={style.offerCard}>
                        <Row>
                            <Col sm={9} md={9} lg={9}>
                                <h5>{data?.offerName || "Loading"}</h5>
                            </Col>
                            <Col sm={3} md={3} lg={3}>
                                <ClickButton disabled={!!isValidating} onClick={mutate} style={{ fontSize: '24px', float: 'right' }} color="success">
                                    <FontAwesomeIcon icon={['far', 'sync']} />
                                </ClickButton>
                            </Col>
                        </Row>
                    </Card>
                        <Row>
                            { !!data && !isValidating ? <Col md={12} lg={8}>
                                <Row>
                                    <Col md={6} lg={5}>
                                        <Card className={style.subtitleCard}>
                                            <div className={style.banner} style={{ background: generateMonoLinearGradient(data.contractor.bannerColour ? `#${data.contractor.bannerColour}` : "#407962"), borderBottom: `2px solid ${data.contractor.bannerColour ? `#${data.contractor.bannerColour}` : "#407962"}` }}/>
                                            <Row>
                                                <Col xs={4} md={4}><img className={theme == 'dark' ? style.imageDark : style.imageLight} style={{ borderColor: data.contractor.bannerColour ? `#${data.contractor.bannerColour}` : "#407962" }} src={data.contractor.pictureUrl}/></Col>
                                                <Col xs={8} md={8}>
                                                    <div style={{ color: calculateLightness(`#${data.contractor.bannerColour || "407962"}`) < 50 ? 'white' : 'black' }}>
                                                        <h5 className={style.contractorName}>{data.contractor.name}</h5>
                                                        <h5 className={style.contractorEmail}>{data.contractor.email}</h5>
                                                    </div>

                                                    <NudgeBoxes
                                                        data={[
                                                            { label: ResolveSalary(`${data.contractor.expectedEarnings[0]}-${data.contractor.expectedEarnings[1]}`, data.contractor.currencyType, FREQUENCIES[data.contractor.timing], data.contractor.workType), danger: false },
                                                            { label: data.contractor.workType, danger: false},
                                                            { label: processSearchStatus(data.contractor.workSearch), danger: false}
                                                        ]}
                                                        fullWidth
                                                    />
                                                </Col>
                                            </Row>
                                            { data.smartMatch ? <NudgeButton color="success" onClick={toggleScore}>Smart Match Breakdown</NudgeButton> : null }
                                        </Card>
                                    </Col>
                                    <Col md={6} lg={7}>
                                        <Card className={style.subtitleCard}>
                                            <h5 className={style.header}>
                                                {pageState.header}
                                                <h5 className={style.subtitle}>
                                                    {pageState.subtitle}
                                                </h5>
                                            </h5>
                                            

                                            {data.status == 'FaceInterviewScheduled' ||
                                            data.status == 'VRAssessmentScheduled' ? (
                                                <>
                                                    <h5 className={style.header}>Add to Personal Calendar</h5>
                                                    <h5 className={style.subtitle}>
                                                        {getGoogleMeetLink(data)} | {getMicrosoftMeetLink(data)} |{' '}
                                                        <a href={fileLink} download={`${id}-${generateRandomString(5)}.ics`}>
                                                            Export ICS (beta)
                                                        </a>
                                                    </h5>
                                                </>
                                            ) : null}
                                        </Card>
                                    </Col>
                                </Row>

                                { showInterview ? (
                                    <>
                                        <NudgeTabs
                                            label="Interview Steps"
                                            selected={selectedTab}
                                            setTab={setSelectedTab}
                                            tabs={tabArray}
                                        />

                                        { selectedTab == TABS.VIDEO && !sending ? (
                                            <AnswerViewer
                                                id={id}
                                                user={user}
                                                answers={data.answers}
                                                feedback={data.internalFeedback.filter(
                                                    (i) => i.type == 'video_interview',
                                                )}
                                                mutate={mutate}
                                            ></AnswerViewer>
                                        ) : selectedTab == TABS.VR && !sending ? (
                                            <>
                                                {data.broadcastResults != null ? (
                                                    <>
                                                        <Card className={style.answerCard}>
                                                            {moment(data.vrAssessmentDate).isBefore(moment()) ? (
                                                                <h5 className={style.header}>
                                                                    Assessment was conducted on {pageState.dates.vr}
                                                                </h5>
                                                            ) : null}

                                                            <h5 className={style.subtilte}>
                                                                View the video broadcast of the assessment and the
                                                                report generated below.
                                                            </h5>
                                                            <br />

                                                            <Row>
                                                                <Col xs={6} md={6}>
                                                                    <h5 className={style.buttonHeader}>Report</h5>

                                                                    <ClickButton
                                                                        color="success"
                                                                        onClick={() =>
                                                                            window.open(data.broadcastResults.fileName, '_blank', 'noreferrer')
                                                                        }
                                                                    >
                                                                        Open Report
                                                                    </ClickButton>
                                                                </Col>
                                                                <Col xs={6} md={6}>
                                                                    <h5 className={style.buttonHeader}>Video</h5>

                                                                    <CompanyVideo
                                                                        isOpen={videoModalOpen}
                                                                        toggle={toggleVideoModal}
                                                                        videoLink={data.broadcastResults.videoLink}
                                                                    />

                                                                    <ClickButton
                                                                        color="success"
                                                                        onClick={toggleVideoModal}
                                                                        disabled={!data.broadcastResults.videoLink}
                                                                    >
                                                                        View Broadcast
                                                                    </ClickButton>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </Card>

                                                        <AssessmentFeedbackCard
                                                            matchId={id}
                                                            user={user}
                                                            feedback={data.internalFeedback.filter(
                                                                (i) => i.type == 'vr_assessment',
                                                            )}
                                                            mutate={mutate}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Card className={style.answerCard}>
                                                            <h5 className={style.header}>
                                                                {moment(data.vrAssessmentDate).isBefore(moment())
                                                                    ? 'Awaiting upload of assessment result'
                                                                    : 'Assessment not yet completed'}
                                                            </h5>
                                                            <br />
                                                        </Card>
                                                    </>
                                                )}
                                            </>
                                        ) : selectedTab == TABS.FACE && !sending ? (
                                            <>
                                                {moment(data.faceInterviewDate).isBefore(moment()) ? (
                                                    <>
                                                        <FaceToFaceFeedbackCard
                                                            matchId={id}
                                                            user={user}
                                                            feedback={data.internalFeedback.filter(
                                                                (i) => i.type == 'face_to_face',
                                                            )}
                                                            mutate={mutate}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Card className={style.answerCard}>
                                                            <h5 className={style.header}>
                                                                {'Face-to-Face interview has not yet happened.'}
                                                            </h5>
                                                        </Card>
                                                        <br />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Card>
                                                <Loader message="Fetching" />
                                            </Card>
                                        )}

                                        <div className={style.buttonWrapper}>
                                            <CalendarModal
                                                data={data}
                                                matchId={id}
                                                isOpen={calendarOpen}
                                                toggle={toggleCalendar}
                                                mutate={mutate}
                                            >
                                                {isFinalStage(data) ? <AcceptButton offerId={data?.offerId} extraData={{companyName: user?.subname || user?.name, feedback: data?.internalFeedback, colour: user?.backColour, offerName: data.offerName, contractorName: data.contractor.name, smartMatch: data.smartMatch}} /> : null}
                                                {canProgress(data) ? (
                                                    <ClickButton color="success" size="lg" onClick={toggleCalendar}>
                                                        { getButtonText(data) }
                                                    </ClickButton>
                                                ) : null}
                                            </CalendarModal>
                                            {data.status != "CompanyRejected" && user ? <RejectButton offerId={data?.offerId} company extraData={{companyName: user?.subname || user?.name, feedback: data?.internalFeedback, colour: user?.backColour, offerName: data.offerName, contractorName: data.contractor.name, smartMatch: data.smartMatch}} /> : null }
                                        </div>
                                    </>
                                ) : (
                                    <Card>
                                        {null}
                                    </Card>
                                )}
                            </Col> : <Col md={8}>
                                <Card>
                                    <Loader message="Fetching interview details" />
                                </Card>
                            </Col>}
                            
                            <Col md="4">
                                <SimpleChatCard
                                    company
                                    id={id}
                                    bidding={data?.bid}
                                    frequency={data?.frequency}
                                    chat={chat?.chats}

                                    sending={loadingChat}
                                    mutate={mutateChat}
                                    afterSendAction={mutateChat}

                                    advancePage={advancePage}
                                    pageRef={pageRef}
                                />
                            </Col>
                        </Row>
                </>
        </SignInPageTemplate>
    );
};

export default InterviewDetails;
