import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import { patchData } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import EditableSkills from './EditableSkills';
import NonEditableSkills from './NonEditableSkills';
import { initialValues, validationSchema } from './validate';
import style from './style.module.scss';
import Loader from '../../../Loader';
import { updateCasebase } from '../../../ContractorInformation';
import NudgeCard from '../../../NudgeCard';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeTooltip from '../../../NudgeTooltip';

const Skills = ({ user, skills, showEdit, refresh, url, changeKey, theme, canCancel = true, reset }) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    };

    const toggle = () => setIsOpen(!isOpen);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const props = {
        initialValues: skills.length < 1 ? initialValues : { skills },
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            await updateProfileAPI(values);
            await updateCasebase(user);

            await refresh();

            if (reset) reset();
            setIsSending(false);
            setIsOpen(false);
        },
    };

    const updateProfileAPI = async (values) => {
        if (values.skills) {
            await patchData({ skills: values.skills }, url, {});
        }
    };

    return (
        <>
            <div className={style.profileHeader}>
                <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`skills-header`} label={<b>{"Skills"}</b>}>
                        <div class="text-center">
                            Tell us about your most important personal skills.
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={toggle}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <Card id={`card-${theme}`} className="mt-10 mb-10">
                <CardBody>
                    {isOpen ? (
                        !sending ? (
                            <Formik {...props}>
                                {(formik) => <EditableSkills formik={formik} onClose={canCancel ? onClose : null} />}
                            </Formik>
                        ) : (
                            <Loader />
                        )
                    ) : skills.length ? (
                        <NonEditableSkills skills={skills} showEdit={showEdit} onEdit={onClickEdit} />
                    ) : (
                        <p style={{ fontStyle: 'italic' }}>No skills have been added yet.</p>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

Skills.propTypes = {
    skills: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })).isRequired,
    showEdit: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
};

export default Skills;
