import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import CustomFormikError from '../CustomFormikError';
import style from './style.module.scss';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';

import { Col, Row } from 'reactstrap';

export const customStyles = (form, field, sub, widthPerc = 100) => {
    const isError = form?.errors[field] && form?.submitCount > 0;

    const { theme } = React.useContext(ThemeContext);
    const isDark = theme === 'dark';
    return {
        menuList: (provided, state) => ({
            ...provided,
            background: isDark ? sub ? '#474E68' : '#6B728E' :'#fff',
            boxShadow: '0px 3px 6px #00000029',
            marginTop: '10px',
            color: isDark ? 'white' :'black',
            zIndex: '1'
        }),
        menu: (provided, state) => ({ 
            ...provided, 
            color: isDark ? 'white' :'black',
            background: isDark ? sub ? '#474E68' : '#6B728E' :'#fff',
            outline: isDark ? '1px solid white' :'none',
            zIndex: '101'
        }),
        option: (provided, { isDisabled }) => ({
            ...provided,
            fontSize: '16px',
            padding: '4px 15px',
            margin: '10px 0',
            textTransform: 'uppercase', 
            opacity: isDisabled ? 0.5 : 1,
            color: isDark ? 'white' : 'black',
            background: isDark ? sub ? '#474E68' : '#6B728E' :'#fff',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            zIndex: '101',
            textAlign: 'left'
        }),
        control: (provided, state) => ({
            ...provided,
            background: isDark ? sub ? '#474E68' : '#6B728E' :'#fff',
            color: isDark ? 'white' :'black',
            height: '35px',
            border: isError ? '1px solid red !important' : '',
            zIndex: '1',
            textAlign: 'left',
            width: '100%'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'lightgray',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }),
        container: (provided, state) => ({
            ...provided,
            width: `${widthPerc}%`
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: isDark ? 'white' : 'black',
            opacity: state.isDisabled ? 0.2 : 1
        })
    };
};

const SelectField = ({ options, field, form, placeholder, disabled, sub, clearable }) => {
    const getOption = () => {
        const option = options.find((option) => option.value === field.value);
        return option ? option : '';
    };
    
    return (
        <div data-testid={`select-${field.name}`} className={style.maxHeight}>
            <Row class="flexy">
                <Col sm={12} md={12} lg={12}>
                    <Select 
                        styles={customStyles(form, field.name, sub)}
                        options={options}
                        name={field.name}
                        value={options ? getOption() : ''}
                        onChange={(option) => form.setFieldValue(field.name, option?.value)}
                        onBlur={field.onBlur}
                        placeholder={placeholder.toUpperCase()}
                        isSearchable={false}
                        isMulti={false}
                        isDisabled={disabled}
                        isClearable={clearable}
                    />
                </Col>
            </Row>
            <CustomFormikError form={form} name={field.name} />
        </div>
    );
};

SelectField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default SelectField;
