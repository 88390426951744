import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { CERTIFICATE_EXPIRY_THRESHOLD, capitaliseFirstLetter } from '../../pages/GenericCompanyProfile/HelperComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';
import DefaultAvatar from '../../../assets/images/avatar.png';
import { URLS, API_DASHBOARD_COMPANY, API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import Checklist from './Checklist';

import NotificationsModal, { countNew } from './NotificationsModal';
import LogOutModal from './LogOutModal';

import style from './style.module.scss';
import { getTasks } from '../../../consts/tasks';
import { getProfileCompletion } from '../../../consts/tasks';
import { getApiByRole } from '../../../consts/userRoles';

export function getPageName() {
    let pageName = '';
    const linkArr = window.location.href.split('/');
    pageName = linkArr[linkArr.length - 1];

    //check if name contains a number. If so, use lower content.
    if (parseInt(pageName)) {
        pageName = linkArr[linkArr.length - 2];
    }

    //format URLs containing -
    if (pageName.includes('-')) {
        let nameArr = pageName.split('-');
        pageName = '';

        nameArr.forEach((item) => {
            pageName += capitaliseFirstLetter(item) + ' ';
        });

        return pageName;
    } else {
        return capitaliseFirstLetter(pageName);
    }
}

const SignInNavbar = ({ user, leftPanel }) => {
    const { logoutUser, userRoles, mutate } = useContext(AuthContext);

    const [logOutOpen, setLogOutOpen] = React.useState(false);
    const toggleLogOut = () => setLogOutOpen(!logOutOpen);

    const [notificationsOpen, setNotificationsOpen] = React.useState(truew);
    const toggleNotifications = () => setNotificationsOpen(!notificationsOpen);

    const [checklistOpen, setChecklistOpen] = React.useState(false);
    const toggleChecklist = () => setChecklistOpen(!checklistOpen);

    var tasks = {};
    var completion = 100;
    if (user && userRoles) { 
        if (userRoles.includes('User')) {
            tasks = getTasks(user);
            completion = getProfileCompletion(tasks);
        }
    }

    function logOutFunction() {
        toggleLogOut();
    }

    const navigate = useNavigate();
    const clickHome = () => {
        window.scrollTo(0, 0);
        navigate(URLS.HOME);
    };
    const clickSettings = () => {
        window.scrollTo(0, 0);
        navigate(URLS.SETTINGS);
    };
    let nameLength = 10;

    let notifications = [];

    let newNotifications = 0;
    let expiredCertificates = 0;

    if (user) {
        const name = user.name || '';
        nameLength = name.length;

        if (user.notifications) {
            notifications = user.notifications;
        }
        newNotifications = countNew(notifications);

        if (userRoles.includes('User')) {
            user.certificates.forEach((item) => {
                if (item.daysUntilExpiry <= CERTIFICATE_EXPIRY_THRESHOLD && item.expiryDate) expiredCertificates++;
            });
        }
    }

    const getAlertStatus = () => {
        //{ expiredCertificates || newNotifications ? <div className={style.notificationAmount}>{ newNotifications ? newNotifications : expiredCertificates || completion < 100 ? '!' : null }</div> : null }

        var hasAlert = false;

        if (expiredCertificates || newNotifications || completion < 100) {
            hasAlert = true;
        }

        if (hasAlert) {
            return (
                <div className={style.notificationAmount}>{newNotifications ? newNotifications : `${completion}%`}</div>
            );
        }
    };

    return (
        <Navbar>
            <Helmet>
                <title>
                    {newNotifications ? `(${newNotifications})` : ''} {getPageName()} - Nudge Energy Talent Lab
                </title>
            </Helmet>
            <LogOutModal toggle={toggleLogOut} isOpen={logOutOpen} logout={logoutUser} />

            {userRoles.includes('User') ? (
                <Checklist
                    user={user}
                    mutate={mutate}
                    tasks={tasks}
                    completion={completion}
                    toggle={toggleChecklist}
                    isOpen={checklistOpen}
                />
            ) : null}
            <Nav>
                <div>
                    <div className={style.userActions}>
                        <div>
                            <UncontrolledDropdown direction="left" nav inNavbar>
                                <DropdownToggle nav caret>
                                    <div className={style.usernameContainer}>
                                        <div className={style.userName}>
                                            {user.subname || user.name || 'Loading...'}
                                        </div>
                                        <FontAwesomeIcon className={style.caret} icon={['fas', 'caret-left']} />
                                        <img src={user.photo || DefaultAvatar} className={style.avatar} alt="user" />
                                        {!userRoles.includes('Admin') ? getAlertStatus() : null}
                                    </div>
                                </DropdownToggle>
                                <div className={style.dropdownMenuContainer}>
                                    <DropdownMenu right className={style.dropdownMenu}>
                                        <DropdownItem onClick={() => clickHome()} className={style.dropdownItem}>
                                            <div style={{ width: '12vh' }}>Home Page</div>
                                        </DropdownItem>
                                        {userRoles.includes('User') && completion < 100 ? (
                                            <DropdownItem
                                                onClick={() => toggleChecklist()}
                                                className={style.dropdownItem}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    {completion < 100
                                                        ? `Profile Progress: ${completion}%`
                                                        : 'Profile Complete'}
                                                </div>
                                            </DropdownItem>
                                        ) : null}
                                        {!userRoles.includes('Admin') ? (
                                            <DropdownItem
                                                onClick={() => toggleNotifications()}
                                                className={style.dropdownItem}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    {!expiredCertificates
                                                        ? `${
                                                              newNotifications ? newNotifications + ' new n' : 'N'
                                                          }otification${notifications.length === 1 ? '' : 's'}`
                                                        : `Action Required!`}
                                                </div>
                                            </DropdownItem>
                                        ) : null}
                                        <DropdownItem onClick={() => clickSettings()} className={style.dropdownItem}>
                                            Account Settings
                                        </DropdownItem>
                                        <DropdownItem onClick={() => logOutFunction()} className={style.dropdownItem}>
                                            Sign Out
                                        </DropdownItem>
                                    </DropdownMenu>
                                </div>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>
            </Nav>
        </Navbar>
    );
};

SignInNavbar.propTypes = {
    user: PropTypes.object,
    leftPanel: PropTypes.node,
};

export default SignInNavbar;
