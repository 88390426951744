import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import DismissModalForm from './DismissModalForm';
import { initialValues, validationSchema } from './DismissModalForm/validation';
import style from './style.module.scss';
import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../../NudgeModal';

const DismissModal = ({ isOpen, toggle, onSubmit, showRejectTooltip }) => {
    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    return (
        <NudgeModal id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered style={{ width: 'fit-content' }} headerText={"Why did the job not suit your needs?"}>
            <Formik {...props}>{(formik) => <DismissModalForm formik={formik} />}</Formik>
            {showRejectTooltip ? <div className={style.tooltip}>The contract has been declined.</div> : null}
        </NudgeModal>
    );
};

DismissModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showRejectTooltip: PropTypes.bool.isRequired,
};

export default DismissModal;
