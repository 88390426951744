import { DEFAULT_PICTURE } from './urls';

// eslint-disable-next-line max-len
//Certificates, Education, Experience, Freelance, Id, Industry, Location, Remuneration, Services Required, Skills, Values, Work Mode

export const getTasks = (user) => {
    const tasks = {
        add_certs: ['Add Certificates', user?.certificates?.length, 'Do you hold any recognised certifications?'],
        add_education: ['Add your education', user?.education, 'How educated are you?'],
        add_bio: ['Add a short bio', user?.bio && user.bio !== ' ', 'Tell us a bit about yourself.'],
        add_industry: ['Add your industry', user?.job?.id, 'What industry are you looking for work in?'],
        add_location: [
            'Add location',
            user?.location ? user?.location : user?.workMode,
            'Where are you looking for work?',
        ],
        add_expected_earnings: [
            'Add your expected earnings',
            user?.expectedEarnings,
            'What is your time and work worth?',
        ],
        add_skills: ['Add your skills', user?.skills.length, 'Add any notable skills that you have.'],
        add_values: ['Add your values', user?.values.length, 'What are your core values?'],
        add_availability: ['Add your availability', user?.availableFrom, 'Tell us when you are available from.'],
        add_projects: [
            'Add previous experience',
            user?.experience.length,
            'Telll us about your past experience/job history.',
        ],
        upload_cv: ['Upload your CV', user?.resumeUrl, 'Upload an up-to-date CV and show what you can do.'],
        add_photo: [
            'Upload a profile photo',
            user?.photo && user?.photo !== DEFAULT_PICTURE,
            'Upload a photo and show us your smile.',
        ],
    };

    return tasks;
};

export const getProfileCompletion = (tasks) => {
    const completedTasks = Object.keys(tasks).filter((task) => tasks[task][1]).length;
    const completedTaskPercentage = Math.round(
        (parseFloat(completedTasks + 1) / (Object.keys(tasks).length + 1)) * 100,
    );

    return completedTaskPercentage;
};

export const getParserCompletion = (tasks) => {
    //return true;
    //parser gets certs, experience, user bio, skills, values
    const taskArr = ['add_certs', 'add_education', 'add_bio', 'add_skills', 'add_projects'];

    var complete = true;
    taskArr.forEach((item) => {
        if(tasks[item]) {
            if (!tasks[item][1]) {
                complete = false;
            }
        }
        
    });

    return !complete;
};
