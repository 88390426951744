import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../buttons/NudgeButton';
import NudgeModal from '../../NudgeModal';

const CloseModal = ({ isOpen, toggle, redirectUrl }) => {
    const headerText = "This will take you off of Nudge and will redirect you through the advertiser's site.";
    return (
        <NudgeModal headerText={"This will take you off of Nudge and will redirect you through the advertiser's site."} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                <div className={style.text}><b>Are you sure you want to do this?</b></div>
                <div className={style.text}>
                    We provide this to benefit our community, but from here on the engagement will no longer be managed
                    by Nudge. Good luck.
                </div>
                <br/>

                
            </ModalBody>
            <ModalFooter>
                <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                    <ClickButton size="lg" color="adzuna" className={style.backButton} onClick={toggle}>
                        Go to Adzuna
                    </ClickButton>
                </a>
                <ClickButton size="lg" color="danger" onClick={toggle}>
                    Go back
                </ClickButton>
            </ModalFooter>
        </NudgeModal>
    );
};
CloseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CloseModal;
