import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'reactstrap';

import PlusButton, { usePlusButtonWithAlwaysMinus } from '../../../../components/buttons/PlusButton';
import SaveButton from '../../../buttons/SaveButton';
import SkillsFormBody from '../../../forms/SkillsForm/SkillsFormBody';

import { FIELDS, initialValues } from './validate';

import style from './style.module.scss';
import NewInputForm from '../../../forms/NewInputForm';
import { API_DASHBOARD_CONTRACTOR } from '../../../../consts/urls';
import Skills from '.';
import NudgeButton from '../../../buttons/NudgeButton';
import NudgeButtonGroup from '../../../buttons/ButtonGroup';

const EditableSkills = ({ formik, onClose }) => {

    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);
    const addValue = initialValues[FIELDS.SKILLS];

    function addFunction() {
        formik.setFieldValue(FIELDS.SKILLS, addValue);
    }

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            { isPlusButtonShowed(FIELDS.SKILLS) ? <Row style={{ marginBottom: '12px' }}>
                <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'left' }}>
                    <h5>{"Limit: 0/8"}</h5>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                    <NudgeButtonGroup
                        buttons={[
                            { label: '+', onClick: addFunction },
                            { label: 'Clear All', disabled: true, color: 'danger'}
                        ]}
                    />
                </Col>
            </Row> : null }

            <SkillsFormBody
                formik={formik}
                alwaysShowMinus
            />
            <SaveButton  onClose={onClose} />
        </Form>
    );
};

EditableSkills.propTypes = {
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditableSkills;
