import React, { useEffect } from 'react';

import { useModal } from './helper';
import SuccessModal from './modals/SuccessModal';

const KEY = 'infoFeedbackModal';

const Body = () => (
    <>
        <p>This is a beta version that is best used on desktop, work is in progress to make mobile friendly. </p>
        <p>
            Thanks for your patience, any thoughts or comments email us on{' '}
            <a href="mailto: nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a>
        </p>
    </>
);

const MobileInfo = () => {
    const { isOpen, toggle, setIsOpen } = useModal();

    const closeModal = () => {
        window.localStorage.setItem(KEY, true);
        toggle();
    };

    useEffect(() => {
        const wasModalClosed = window.localStorage.getItem(KEY) === 'true';
        setIsOpen(!wasModalClosed);
    }, [setIsOpen]);

    return <SuccessModal isOpen={isOpen} toggle={closeModal} body={<Body />} />;
};

export default MobileInfo;
