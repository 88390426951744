import React from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import AppNavbar from './AppNavbar';
import Footer from './Footer';
import style from './style.module.scss';

const infoText = [
    [
        `B Lab is the non-profit network transforming the 
        global economy to benefit all 
        people, communities and the planet.`,

        `Imagine a world where all stakeholders — not just 
        shareholders — are valued and prioritised.<br /><br/>
        As a B Corp the role of business is redefined so that all 
        businesses are a force for good — and play a leading role 
        in positively impacting and transforming the global 
        economy toward a more inclusive, equitable, and 
        regenerative system.<br /><br/>
        Nudge gained B Corp accreditation in January 2022 and we 
        are the first in our sector (Energy and Talent) to achieve 
        the status. We are extremely proud of this fact, but it is 
        only the start of our journey to using our business as a 
        force for good in the communities in which we operate.`,
    ],
    [
        `Nudge supports Code Your Future. We want to help 
        the underrepresented and disadvantaged members 
        of society to become web developers and find 
        rewarding work in the tech industry.`,
        `Code Your Future really is a success story, from their first 
        class in 2016 with a small group of refugees and asylum 
        seekers they have grown across the UK and have now set 
        up in Cape Town. It’s an organisation run by an awesome 
        but lean core team with the rest of the support and 
        teaching coming from volunteers. Over 250 individuals 
        have graduated from their software development 
        bootcamps and around 70% have found jobs in the tech 
        sector. Nudge was born in order to support individuals to 
        achieve their potential including the disadvantaged which 
        is why we chose Code Your Future as one of our give back 
        partners.`,
    ],
    [
        `The Axis network has a vision of a prosperous and 
        sustainable Aberdeen Energy Industry which has an 
        equal gender balance across all areas of the sector.`,
        `The Axis Network are focused on attracting, developing 
        and retaining the talent that will help this industry 
        transition and thrive. That's why creating an inclusive 
        offshore workplace is so important.<br/><br/>
        AXIS Network in partnership with Step Change in Safety 
        has completed an Inclusive Offshore Working Project, 
        showcasing some of the best inclusion practices across our 
        industry. Nudge supports the Axis Network with both 
        recruitment and web support.`,
    ],
    [
        `Developing the Young Workforce (DYW) is the Scottish Government’s
        national strategy for strengthening links between businesses and education
        with the headline aim of reducing youth unemployment by 40% by 2021. <br/><br/>
        The DYW team's mission is to be the vital link for businesses and schools
        to engage, inspire and develop the young workforce and effectively bridge
        the gap between education and employers to help young people find fulfilling careers`,
        `At Nudge we are proud to work with DYW and very much share their vision that
        investing in our young people now will ensure that they have the skills and
        knowledge they require for long and rewarding careers and in turn that the
        Energy sector has the talent required to make the energy transition a success`,
    ],
    [
        `Our collective home is in the process of being turned totally
        upside down which is leading to strife for almost all life on 
        Earth. We know it’s preventable. And we know it’s not 
        going to save itself. So, it is up to us to solve it. But what 
        levers can we pull? <br/><br/>
        Reducing our carbon footprint is entirely necessary, but the
        transition of change is far slower than the time we have 
        remaining.`,
        `The tools that we associate with fighting climate change 
        are inadequate. What if that changed and the billions of us 
        who care became empowered? <br/><br/>
        There are enough climate solutions that are out there right 
        now, that if we supported them, it’d undo 30 years of 
        carbon damage by avoiding 1,500 billion tonnes of CO2. <br/><br/>
        This is why Nudge has partnered with fellow B Corp Ecologi
        to ensure that at a very minimum our service is carbon 
        neutral.  For every sign up and connection made we plant a
        tree in the nudge forest.`,
    ],
    [
        `Inkedama is a collab that was formed with the Siyakhathala
        Orphans Charity from the South African townships to create
        unique one of one NFTs and Merchandise. <br/><br/>
        Every sale goes to the shelter, education and support of 
        the under-privileged. In addition to the collab we provide 
        web support and undertake fundraising activities on behalf 
        of www.siyakhathala.com. <br/><br/>
        Siyakhathala We Care is a registered Non-Profit 
        organization (NPO: 068-260) founded in May of 2009 which 
        works in the township of Khayelitsha, Western Cape, South 
        Africa.`,
        `Siyakhathala improves the lives of orphaned children in the
        township, as well as victims of neglect, violence, abuse, 
        and rape by providing a safe haven for at-risk children who 
        would otherwise end up in troubling circumstances. <br/><br/>
        They provide a safe haven, they educate, and they run 
        summer camps to give the kids, what can be, the first trip 
        out of the townships. Everyone deserves a safe space.`,
    ],
];

const HomeAbout = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const infoClick = (idx) => {
        setSelectedIndex(idx);
    };

    React.useEffect(() => {
        if (selectedIndex != null) {
            infoChange(selectedIndex);
        }
    }, [selectedIndex]);

    const infoChange = (idx) => {
        const infoContent = document.getElementById('infoContent');
        infoContent.style.display = 'block';

        const infoContentLeft = document.getElementById('infoContentLeft');
        infoContentLeft.innerHTML = infoText[idx][0];

        const infoContentRight = document.getElementById('infoContentRight');
        infoContentRight.innerHTML = infoText[idx][1];
    };

    return (
        <div>
            <AppNavbar />
            <Helmet>
                <title>About - Nudge Energy Talent Lab</title>
            </Helmet>

            <section className={style.sectionChange}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={style.firstSection}>
                                <h1>Join our mission to change the world</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionPower}>
                <Container>
                    <Row>
                        <Col xs={12} xl={6}>
                            <div className={style.subHeading}>The energy sector has the power to change the world</div>
                        </Col>
                        <Col xs={12} xl={6}>
                            <div className={style.subText} style={{ marginTop: '40px' }}>
                                Tackling climate change is vital, but it&apos;s not only through developing new
                                technologies that we&apos;ll progress. Removing barriers to career progression and
                                encouraging diversity in STEM roles benefits us all: a complex problem requires a
                                diverse range of solutions.
                            </div>
                            <div className={style.subsubText} style={{ marginTop: '20px' }}>
                                We want to see more people find their dream careers and more businesses find the right
                                people for the job. Recruitment doesn’t have to be a long and painful process. Nudge
                                supports companies and individuals to achieve their goals while contributing to real and
                                lasting change.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} xl={6} />
                        <Col xs={12} xl={6}>
                            <div className={style.subHeading}>Invite only</div>

                            <div className={style.subText} style={{ marginTop: '40px' }}>
                                We’re serious about our goals, and that means we choose to only work with companies
                                making great strides in these key areas.
                                <br />
                                <br />B Corporations get direct entry.
                            </div>
                            <div style={{ marginTop: '10vh', marginBottom: '10vh' }}>
                                <Link to="/services/company#contact" className={style.button}>
                                    <span>Get in touch →</span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10vh' }}>
                        <Col xs={12} l={6} xl={3} className={style.infobox}>
                            <p>Transitioning to low carbon energy</p>
                        </Col>
                        <Col xs={12} l={6} xl={3} className={style.infobox}>
                            <p>Improving through Digital Transformation</p>
                        </Col>
                        <Col xs={12} l={6} xl={3} className={style.infobox}>
                            <p>Diversity, Equality and Inclusion</p>
                        </Col>
                        <Col xs={12} l={6} xl={3} className={style.infobox}>
                            <p>Being active in their communities</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionCar}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Carousel showDots cols={1} loop>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <h1 className={style.carouselCenter}>Experience</h1>
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <h3 className={style.carouselCenter}>
                                                        We know the energy industry; we’ve worked in it ourselves.
                                                        That’s why we’ve built a platform dedicated to STEM
                                                        professionals and are constantly exploring new technologies to
                                                        improve our service.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <h1 className={style.carouselCenter}>Data</h1>
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <h3 className={style.carouselCenter}>
                                                        Big data, blockchain and extended reality might sound like
                                                        buzzword bingo. But if we see an opportunity to make our service
                                                        more cost-effective, higher quality, and easier to use, we will
                                                        integrate it into our technology roadmap.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <h1 className={style.carouselCenter}>Learning</h1>
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <h3 className={style.carouselCenter}>
                                                        Our Machine Learning technology predicts the more appropriate
                                                        candidate for each opportunity, which can be weighted by what
                                                        matters most for each role, e.g., experience versus
                                                        certificates.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <h1 className={style.carouselCenter}>Facts</h1>
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <h3 className={style.carouselCenter}>
                                                        Suggestions are made based on a candidate’s profiles rather than
                                                        gut feelings—no more human bias—before being reviewed by human
                                                        recruitment experts to ensure accuracy and allow our models to
                                                        learn and improve prediction accuracy.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionForce}>
                <Container>
                    <Row>
                        <Col xs={12} xl={12}>
                            <div className={style.subHeading}>A force for good</div>
                            <div className={style.subText} style={{ marginTop: '40px' }}>
                                Be it by our time or our dime, we support those who are doing great things, with a focus
                                on STEM subjects.
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10vh' }}>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 0 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(0)}
                        >
                            <p>B Corp</p>
                        </Col>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 1 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(1)}
                        >
                            <p>Code Your Future</p>
                        </Col>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 2 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(2)}
                        >
                            <p>AXIS Network</p>
                        </Col>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 3 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(3)}
                        >
                            <p>Developing The Young Workforce</p>
                        </Col>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 4 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(4)}
                        >
                            <p>Ecologi</p>
                        </Col>
                        <Col
                            xs={12}
                            l={6}
                            xl={2}
                            className={selectedIndex == 5 ? style.infoboxSelectable : style.infoboxUnselected}
                            onClick={() => infoClick(5)}
                        >
                            <p>Inkedama</p>
                        </Col>
                        <Col xs={12} className={`${style.infoboxContent}`} id="infoContent">
                            <Row>
                                <Col xs={12} xl={6}>
                                    <h3 id="infoContentLeft" className={style.infoContentLeft}></h3>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <p id="infoContentRight" className={style.infoContentRight}></p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    );
};

export default HomeAbout;
