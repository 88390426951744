import { useState } from 'react';

import { prepareAuthHeaders } from '../../../../api/tools';
import { useOfferTooltip } from '../../../../components/pages/OfferGenericPage/OfferTooltip';

const prepareReason = (values, fields, otherLabel) =>
    values[fields.REASON] === otherLabel ? `${values[fields.REASON]}: ${values[fields.OTHERS]}` : values[fields.REASON];

export const useDismissModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isOfferTooltipVisible, showOfferTooltip } = useOfferTooltip();

    const toggle = () => {
        setIsOpen(!isOpen);
        showOfferTooltip(false);
    };

    const onSubmitReject = async (url, fields, otherLabel, values, data = {}) => {
        const headers = await prepareAuthHeaders();
        const reason = prepareReason(values, fields, otherLabel);
        const body = JSON.stringify({ reason, ...data });

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body,
        });

        showOfferTooltip(true);
    };

    const isRejectOfferTooltipVisible = isOfferTooltipVisible;

    return { isOpen, toggle, setIsOpen, isRejectOfferTooltipVisible, onSubmitReject };
};
