import React from 'react';
import { Col, Row, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import SearchBox from '../../../../components/SearchBox';

import { URLS } from '../../../../consts/urls';
import LinkButton from '../../../../components/buttons/LinkButton';
import {
    capitaliseFirstLetter,
    count_by_key,
    ProcessSalary,
    ResolveSalary,
} from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import style from '../style.module.scss';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../components/AppPagination';
import { sort_by_key } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import useMobile from '../../../../hooks/useMobile';

const PER_PAGE = 9;
const PER_PAGE_MOBILE = 8;

const OfferList = ({ offers, ascending, sortOption, reverse }) => {
    const [search, setSearch] = React.useState(``);
    const { isMobileWide } = useMobile();

    offers = filterOffers(offers);

    const PER_PAGE_CHANGE = isMobileWide ? PER_PAGE_MOBILE : PER_PAGE;

    const total = Math.ceil(offers.length / PER_PAGE_CHANGE);
    const { page, setPage, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE_CHANGE);
    const offersToDisplay = [...offers.reverse()].slice(start, end);
    const emptyOffers = [];

    for (var i = 0; i < PER_PAGE_CHANGE - offersToDisplay.length; i++) {
        emptyOffers.push('');
    }

    function parseRate(pay1, pay2, currency, frequency, type) {
        return ResolveSalary(`${pay1}-${pay2}`, currency, frequency, type);
    }

    function filterOffers(oInput) {
        function getComparison(offer) {
            return `${offer.jobName}${offer.offerStatus}${offer.companyName}${offer.locationType}`
        }

        var statuses = new Set(oInput.map((item) => item.offerStatus).filter((item) => item != "Template"));

        var filtered = oInput.filter((item) => upper(getComparison(item)).includes(upper(search.replaceAll('!', ''))))
        filtered = sort_by_key(filtered, sortOption, ascending)

        if(reverse) {
            filtered = filtered.reverse();
        }

        return filtered;
    }

    function upper(string) {
        return string.toUpperCase();
    }

    const buffer = isMobileWide ? 1 : 3;

    React.useEffect(() => {
        setPage(0);
    }, [search]);

    return (
        <>
            <SearchBox className={style.search} setValue={setSearch} value={search} />
            <Row>
                {offersToDisplay.map((offer) => (
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={style.offerCard}>
                            <h5 className={style.jobName}>{offer.jobName}</h5>
                            <Link
                                target="_blank"
                                to={`/admin/companies/${offer.companyId}`}
                                className={style.companyNameLink}
                            >
                                <h5 className={style.companyName}>{offer.companyName}</h5>
                            </Link>

                            <h5 className={style.attribute}>
                                {' '}
                                {parseRate(offer.salaryFrom, offer.salaryTo, offer.currencyType, offer.frequency, offer.workType)}
                            </h5>
                            <h5 className={style.attribute}>{`${offer.availabilityText}`}</h5>
                            <h5 className={style.attribute}>{`Location: ${capitaliseFirstLetter(
                                offer.locationType || `TBD`,
                            )}`}</h5>

                            <h5 className={style.offerStatus}>{offer.offerStatus}</h5>
                            <LinkButton className={style.linkButton} link={`${URLS.ADMIN_CONTRACTS}/${offer.id}`}>
                                View Offer
                            </LinkButton>
                        </Card>
                    </Col>
                ))}
                {emptyOffers.map(() => (
                    <Col xs={12} md={4}>
                        <Card className={style.emptyOffer} />
                    </Col>
                ))}
            </Row>

            <AppPagination
                total={total}
                page={page}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                onClickPage={onClickPage}
                onClickFirst={onClickFirst}
                onClickLast={onClickLast}
                inBuffer={buffer}
            />
            <br />
        </>
    );
};

export default OfferList;
