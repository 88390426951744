import React from 'react';
import { Button, Card } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import style from './style.module.scss';

import useMobile from '../../hooks/useMobile';
import ClickButton from '../buttons/NudgeButton';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const SwitchToDesktop = ({ children }) => {
    const navigate = useNavigate();
    const { isMobileWide } = useMobile();

    const theme = GetPageTheme();
    return isMobileWide ? (
        <Card className={style.switchToDesktop}>
            <div>
                <p>This page is only available on desktop. To access it, please switch.</p>
                <p>
                    Thanks for your patience, any thoughts or comments email us on{' '}
                    <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a>
                </p>
                <ClickButton onClick={() => navigate(-1)} color="success">
                    Go Back
                </ClickButton>
            </div>
        </Card>
    ) : (
        <div>{children}</div>
    );
};

export default SwitchToDesktop;
