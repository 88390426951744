import React from 'react';
import useSWR from 'swr';
import { Row, Col } from 'reactstrap';

import { authFetcher, NEVER_REFRESH } from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import { API_DASHBOARD_CLOOD } from '../../../../../consts/urls';

export const Project = ({ project }) => {
    return (
        <>
            <Col md={6} style={{ marginBottom: '48px' }}>
                <h5>{project.name}</h5>
                <p>{project.id__}</p>
                <p>{project.description}</p>
                <br />
                {project.attributes.map((attr) => (
                    <Row>
                        <Col md={3}>{attr.name}</Col>
                        <Col md={3}>{attr.similarity}</Col>
                        <Col md={3}>{attr.type}</Col>
                        <Col md={3}>{attr.weight}</Col>
                    </Row>
                ))}
            </Col>
        </>
    );
};

const TestingGround = () => {
    const URL = `${API_DASHBOARD_CLOOD}/Projects/GetAll`;
    const { data, mutate } = useSWR(URL, authFetcher, NEVER_REFRESH);

    React.useEffect(() => {
        if (data) console.log(data);
    }, [data]);

    return <Row>{data ? data.map((project) => <Project project={project} />) : <Loader />}</Row>;
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
