import { Auth } from 'aws-amplify';

export const NEVER_REFRESH = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
};

export const NO_REFRESH_INTERVAL = { refreshInterval: 0 };

export const prepareAuthHeaders = async () => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    return {
        Authorization: `Bearer ${token}`,
    };
};

export const exFetcher = async (url) => {
    let res;
    try {
        res = await fetch(url, {});
    } catch (e) {
        console.log(e.message);
    }

    if(!res) return null;

    if (res.status !== 500) {
        return res.json();
    } else {
        return null;
    }
};

export const exFetcherWithForm = async (url, body) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });
        return response.json();
    } catch (e) {
        console.log(e.message);
    }
};

export const authFetcher = async (url) => {
    const headers = await prepareAuthHeaders();

    try {
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        });

        if (res.status !== 500) {
            return res.json();
        } else {
            return null;
        }
    } catch (e) {
        console.log(e.message);
    }
};

export const fetcher = async (url) => {
    const res = await fetch(url, { headers: { 'Content-Type': 'application/json' } });
    return res.json();
};

export const fetcherWithAuthorizationHeader = async (url, token) => {
    try {
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return res.json();
    } catch (e) {
        console.log(e.message);
    }
};

export const postWithAuthorizationHeader = async (data, url, token) => {
    let body;
    try {
        body = JSON.stringify(data);
    } catch (e) {
        console.log(e.message);
    }

    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body,
        });
        return res.json();
    } catch (e) {
        console.log(e.message);
    }
};

export const patchWithAuthorizationHeader = async (data, url, token) => {
    let body;
    try {
        body = JSON.stringify(data);
    } catch (e) {
        console.log(e.message);
    }

    try {
        await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body,
        });
        return 'success';
    } catch (e) {
        console.log(e.message);
    }
};

export const deleteWithAuthorizationHeader = async (url, token) => {
    try {
        await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return 'success';
    } catch (e) {
        console.log(e.message);
    }
};

export const authPostData = async (data, url, custom_headers) => {
    let headers = await prepareAuthHeaders();

    if (custom_headers) {
        headers = { ...headers, ...custom_headers };
    }

    var body = '';

    try {
        body = JSON.stringify(data);
    } catch (e) {
        console.log(e.message);
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body,
        });
        
        return await response.json();
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const authPostDataWithNoResponse = async (data, url) => {
    const headers = await prepareAuthHeaders();
    const body = JSON.stringify(data);

    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body,
        });
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const postData = async (data, url, custom_headers) => {
    let headers;
    if (custom_headers) {
        headers = { ...headers, ...custom_headers };
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
        });

        return await response.json();
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const deleteData = async (data, url, custom_headers) => {
    let headers = await prepareAuthHeaders();
    headers = { ...headers, ...custom_headers };

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
        });

        return await response.json();
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const patchData = async (data, url, custom_headers) => {
    let headers = await prepareAuthHeaders();
    headers = { ...headers, ...custom_headers };

    try {
        await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const putData = async (data, url) => {
    const headers = await prepareAuthHeaders();

    try {
        await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log('ERROR', e);
    }
};

export const authSendFile = async (file, url, user_headers) => {
    if (file) {
        let headers = await prepareAuthHeaders();
        if (typeof user_headers == 'object') {
            headers = { ...headers, ...user_headers };
        }

        try {
            return await sendFile(headers, file, url);
        } catch (e) {
            console.log('ERROR', e);
        }
    }
};

export const sendFile = async (headers, file, url, fieldName = 'file') => {
    if (file) {
        try {
            const form = new FormData();
            form.append(fieldName, file);
            var response;

            response = await fetch(url, {
                method: 'POST',
                headers,
                body: form,
            });

            return await response.json();
        } catch (e) {
            console.log('ERROR', e);
        }

        return null;
    }
};

export const authSendFiles = async (files, url) => {
    if (files && files.length > 0) {
        const form = new FormData();

        for (const { name, file } of files) {
            form.append(name, file);
        }

        const headers = await prepareAuthHeaders();

        await fetch(url, {
            method: 'POST',
            headers,
            body: form,
        });
    }
};
