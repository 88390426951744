import { API_DASHBOARD_ADMIN, API_DASHBOARD_COMPANY, API_DASHBOARD_CONTRACTOR, API_DASHBOARD_RECRUITER } from './urls';

export const ADMIN = 'Admin';
export const USER = 'User';
export const COMPANY = 'Company';
export const RECRUITER = 'Recruiter';
export const ALL_ROLES = [ADMIN, USER, COMPANY, RECRUITER]

export const CONTRACTOR_NUMERIC = '1';

export const getApiByRole = (roles) => {
    if (roles.includes(ADMIN)) {
        return API_DASHBOARD_ADMIN;
    } else if (roles.includes(COMPANY)) {
        return API_DASHBOARD_COMPANY;
    } else if (roles.includes(RECRUITER)) {
        return API_DASHBOARD_RECRUITER;
    } else {
        return API_DASHBOARD_CONTRACTOR;
    }
};
