import React from 'react';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import VRBookingPage from '../../../components/pages/VRBookingPage';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { COMPANY } from '../../../consts/userRoles';

const CompanyVRBooking = ({ }) => {

    return (
        <VRBookingPage url={API_DASHBOARD_COMPANY}/>
    )
}

export default withAuthProtectedRouter(CompanyVRBooking, [COMPANY]);