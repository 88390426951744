import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher } from '../../../../api/tools';
import SelectField from '../../../../components/SelectField';
import { API_DASHBOARD_CONTRACTOR } from '../../../../consts/urls';
import style from './style.module.scss';
import { FIELDS } from './validate';
import ClickButton from '../../../../components/buttons/NudgeButton';

const MINUTE_INCREMENT = 60;

const prepareOptions = (date, hours) => {
    var timeMap = [];

    const minutes = [];
    for (var i = 0; i < 60; i += MINUTE_INCREMENT) {
        if (i < 10) {
            minutes.push('0' + i);
        } else {
            minutes.push('' + i);
        }
    }

    hours.forEach((hour) => {
        minutes.forEach((minute) => {
            timeMap.push({
                value: parseInt(hour.split(':')[0]) + `:${minute}`,
                label: parseInt(hour.split(':')[0]) + `:${minute}`,
            });
        });
    });

    return timeMap;
};

const TimeForm = ({ formik, date, id }) => {
    const { data: hours } = useSWR(
        `${API_DASHBOARD_CONTRACTOR}/InterviewPlanner/${id}/Dates/${date}/Times`,
        authFetcher,
        { refreshInterval: 0 },
    );

    React.useEffect(() => {
        formik.values[FIELDS.TIME] = '';
    }, [date]);

    return hours ? (
        <>
            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit} className={style.form}>
                <div className={style.time}>
                    <Field
                        name={FIELDS.TIME}
                        component={SelectField}
                        options={prepareOptions(date, hours)}
                        placeholder="Time"
                    />
                </div>

                <div className={style.button}>
                    <ClickButton color="success" type="submit">
                        {window.location.href.includes('reschedule') ? 'Reschedule Meeting' : 'Book Interview Slot'}
                    </ClickButton>
                </div>
            </Form>
        </>
    ) : null;
};

TimeForm.propTypes = {
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
};

export default TimeForm;
