import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher } from '../../../api/tools';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { FLASK_API, URLS } from '../../../consts/urls';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

const NeoTalentMeeting = () => {
    const { user, userRoles } = React.useContext(AuthContext);
    const adminContractorId = useParams().userId;

    const resultsUrl = !userRoles.includes('Admin')
        ? `${FLASK_API}/checklist`
        : `${FLASK_API}/checklist/${adminContractorId}`;
    const { data } = useSWR(resultsUrl, authFetcher, { revalidateOnFocus: false });

    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);

    var frameUrl = `${URLS.NEO_TALENT_MEETING}frame/${id}`;
    if (userRoles.includes('Admin') && adminContractorId) {
        frameUrl = `${URLS.ADMIN_NEOTALENT}/${adminContractorId}/meetingframe/${id}`;
    } else if (userRoles.includes('Admin') && !adminContractorId) {
        frameUrl = `${URLS.ADMIN_NEOTALENT}/0/meetingframe/${id}`;
    }

    const [meetingItem, setMeetingItem] = React.useState(null);
    const [meetingActive, setMeetingActive] = React.useState(true);

    React.useEffect(() => {
        if (data) {
            const item = data.checklist.find((item) => item.id === id);
            setMeetingItem(item);
            window.addEventListener('message', (event) => {
                if (event.data === 'selfLeave') {
                    setMeetingActive(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        return () => {
            const frame = document.getElementById('zoom-content');
            if (frame) {
                frame.contentWindow.postMessage('leave');
            }
        };
    }, []);

    return user ? (
        <SignInPageTemplate>
            <Row>
                <Col lg={12}>
                    {meetingItem && (
                        <Card
                            style={{
                                padding: '24px 24px 12px 24px',
                                marginBottom: '24px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <h5>{meetingItem.name}</h5>
                            <p>{moment(meetingItem.date).format('Do MMMM YYYY, h:mma')}</p>
                            {meetingItem.document && (
                                <p>
                                    <FontAwesomeIcon
                                        icon={['fad', 'external-link']}
                                        style={{ marginRight: '10px', width: '16px' }}
                                    />
                                    <Link to={meetingItem.document} target="_blank">
                                        Supporting documents
                                    </Link>
                                </p>
                            )}
                        </Card>
                    )}

                    {meetingActive && (
                        <iframe
                            src={frameUrl}
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                height: '80vh',
                                border: 'none',
                                borderRadius: '8px',
                            }}
                            allow="camera; microphone; cross-origin-isolated"
                            title="Zoom Meeting"
                            id="zoom-content"
                        />
                    )}

                    {!meetingActive && (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '36px' }}>
                            <p>You have left the meeting.</p>
                        </div>
                    )}

                    {meetingItem && meetingActive && (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '36px' }}>
                            <p>
                                <Link
                                    to={
                                        'https://app.zoom.us/wc/' +
                                        meetingItem.meeting.id +
                                        '/join?fromPWA=1&pwd=' +
                                        meetingItem.meeting.password
                                    }
                                    target="_blank"
                                >
                                    Having trouble joining the meeting? Click here to open in a new tab.
                                </Link>
                            </p>
                        </div>
                    )}
                </Col>
            </Row>
        </SignInPageTemplate>
    ) : null;
};

export default NeoTalentMeeting;
