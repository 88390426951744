import en from 'date-fns/locale/pl';
import React from 'react';
import { Button } from 'reactstrap';
import DatePicker, { registerLocale, setHours, setMinutes } from 'react-datepicker';
import {
    getDaysBetweenDates,
    highestIndex,
} from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { getDatesBetween } from './helpers';

import { API_DASHBOARD_COMPANY } from '../../../../../consts/urls';
import { authPostData } from '../../../../../api/tools';

import moment from 'moment';
import style from './style.module.scss';
import Loader from '../../../../../components/Loader';
import ClickButton from '../../../../../components/buttons/NudgeButton';

registerLocale('en', en);

const DateSelector = ({
    matchId,
    mutate,
    toggle,
    selectedDate,
    setSelectedDate,
    isVR,
    availability,
    close,
    excluded,
}) => {
    const [dates, setDates] = React.useState([]);
    const [tempDate, setTempDate] = React.useState();
    const [sending, setSending] = React.useState(false);

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    var today = new Date();
    var start = availability?.length ? new Date(availability[0]) : today;
    var end = availability?.length ? new Date(availability[availability.length - 1]) : new Date(close);

    if (moment(today).isAfter(end)) {
        availability = [];
        start = new Date(today);
        end = new Date(close);
    }

    React.useEffect(() => {
        if (availability?.length) {
            setDates(getTodayTimes());
        } else {
            setDates(getDefaultTimes());
        }
    }, [tempDate]);

    React.useEffect(() => {
        var tDate = moment(tempDate).format('yyyy-MM-DD');
        var sDate = moment(selectedDate).format('yyyy-MM-DD');

        if (dates) {
            if (!selectedDate) {
                setSelectedDate(dates[0]);
            } else {
                if (tDate != sDate) {
                    setSelectedDate(dates[0]);
                } else {
                    setSelectedDate(tempDate);
                }
            }
        } else {
            setSelectedDate(tempDate);
        }
    }, [dates]);

    async function submitTime() { 
        setSending(true);

        const response = await authPostData(
            { date: moment(selectedDate).format('yyyy-MM-DDTHH:mm:ss') },
            `${API_DASHBOARD_COMPANY}/Matches/${matchId}/Progress`,
        );
        await mutate();
        toggle();

        setSending(false);
    }

    function addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes * 60000);
    }

    function getDefaultTimes() {
        if (tempDate) {
            var timesArr = [];
            var dateString = moment(tempDate).format('yyyy-MM-DD');

            var dateObj = new Date(`${dateString}T09:00:00`);

            const hours = 8;
            while (timesArr.length < hours * 2 + 1) {
                timesArr.push(dateObj);
                dateObj = addMinutes(dateObj, 30);
            }

            return timesArr.filter((p) => !excluded.includes(moment(p).format('yyyy-MM-DDTHH:mm:ss')));
        }

        return [];
    }

    function getTodayTimes() {
        if (!tempDate) return [];

        var dates = [];
        if (availability?.length) {
            availability.forEach((i) => {
                var iDate = moment(i).format('yyyy-MM-DD');
                var tDate = moment(tempDate).format('yyyy-MM-DD');

                if (iDate == tDate) dates.push(new Date(i.substring(0, i.length - 1)));
            });
            return dates;
        } else {
            return null;
        }
    }

    function getExcludedDays() {
        if (moment(today).isAfter(moment(end))) {
            return [];
        }

        if (availability?.length) {
            var excluded = [];
            var dates = [...new Set(availability.map((i) => moment(i).format('yyyy-MM-DD')))];

            getDatesBetween(start, end).forEach((date1) => {
                var finder = dates.find(
                    (date2) => moment(date1).format('yyyy-MM-DD') == moment(date2).format('yyyy-MM-DD'),
                );

                if (!finder) {
                    excluded.push(date1);
                }
            });

            return excluded;
        } else {
            return [];
        }
    }
    const excludedDates = getExcludedDays();

    return (
        <>
            <div className="text-center big-calendar">
                {!sending ? (
                    <DatePicker
                        id="calendar-pick"
                        locale="en"
                        value={selectedDate}
                        selected={selectedDate}
                        onChange={setTempDate}

                        includeTimes={
                            availability?.length && !moment(today).isAfter(moment(end)) ? dates || [] : getDefaultTimes() || []
                        }
                        
                        excludeDates={excludedDates}
                        showTimeSelect
                        timeIntervals={30}
                        inline
                        minDate={start}
                        maxDate={end}
                    />
                ) : (
                    <Loader />
                )}
            </div>
            <br />

            {selectedDate ? (
                <>
                    <h5 className={style.interviewHeader}>
                        {isVR ? 'Assessment' : 'Interview'} will be on{' '}
                        {moment(selectedDate).format('MMMM Do YYYY, h:mm a')}.
                    </h5>
                    <div className={style.interviewButton}>
                        <ClickButton
                            color={'success'}
                            disabled={!selectedDate || sending}
                            onClick={submitTime}
                        >
                            { isVR ? 'Invite to VR Assessment' : 'Invite to Face-to-Face Interview' }
                        </ClickButton>
                    </div>
                </>
            ) : (
                <h5 className={style.interviewHeader}>No Time Selected.</h5>
            )}
            <br />
        </>
    );
};

export default DateSelector;
