import React from 'react';

import style from './style.module.scss';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';
import { ModalHeader } from 'reactstrap';

const NudgeModal = ({ id, isOpen, toggle, centered = true, children, modalStyle, headerText, className }) => {
    const { modalOpen, setModalOpen } = React.useContext(ThemeContext);
    const [isClicked, setIsClicked] = React.useState(false);

    const toggleModal = () => {
        toggle();
        setModalOpen(false);
    }

    const handleParentClick = (event) => {
        event.preventDefault();

        toggleModal();
    }

    const handleChildClick = (event) => {
        event.stopPropagation();
    }

    React.useEffect(() => {
        if(isOpen && !isClicked) {
            setIsClicked(true);
        }

        if(isOpen) { setModalOpen(true) } else { setModalOpen(false); }
    }, [isOpen])

    return ( isClicked ? <>
        <div id="background" style={modalStyle} className={`${isOpen ? style.modalFadeIn : style.modalFadeOut} ${centered ? style.center : null}`} onClick={handleParentClick}>
            <div id={id} className={isOpen ? style.modal : style.modalFade}>
                <div className={`${style.innerModal} ${className}`} onClick={handleChildClick}>
                    <ModalHeader toggle={toggleModal}>
                        {headerText}
                    </ModalHeader>
                    { children }
                </div>
            </div>
        </div>
    </> : null )
}

export default NudgeModal;