import React from 'react';
import { Col, Input, Row } from 'reactstrap';

import DefaultAvatar from '../../assets/images/avatar.png';
import EditButton from '../buttons/EditButton';
import style from './style.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetPageTheme, ResolveSalary, generateMonoLinearGradient } from '../pages/GenericCompanyProfile/HelperComponents';
import NudgeBoxes from '../NudgeBoxes';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';

const timingOptions = {
    Daily: ' per day',
    Hourly: ' per hour',
    Yearly: ' per annum',
};

const processSearchStatus = (status) => {
    switch (status) {
        case 'Active':
            return 'Actively searching';
        case 'Passive':
            return 'Interested in work';
        default:
            return '';
    }
};

const processEarnings = (earnings, currency, timing, type) => {
    earnings = earnings.replaceAll(' ', '');

    const process = {
        Hourly: 'HOUR',
        Yearly: 'YEAR',
        Daily: 'DAY',
    };

    return ResolveSalary(earnings, currency, process[timing], type)
};

const NonEditableContractorInformation = ({
    user,
    resume,
    expectedEarnings,
    onClickEdit,
    showEdit,
    job,
    showResume,
    videoLink,
    toggle
}) => {

    const theme = GetPageTheme();
    const { userRoles } = React.useContext(AuthContext);

    function resolveJob() {
        if(user.parentCompany) return `Works for ${user.parentCompany}`

        if (user.company && job.name) {
            if (user.company == job.name) {
                return `${job.name}`;
            } else {
                return `${job.name} for ${user.company}`;
            }
        } else {
            return '';
        }
    }

    const [color, setColor] = React.useState(`#407962`);
    const [parsedColor, setParsedColor] = React.useState(`#407962`);
    React.useEffect(() => {
        setParsedColor(color);
    }, [color])

    return (
        <>
            <div style={{ background: generateMonoLinearGradient(`${user.bannerColour ? `#${user.bannerColour}` : "#407962"}`), borderBottom: `2px solid #${user.bannerColour }` }} className={style.banner}></div>
            <Row>
                <Col className={style.detailsMobile}>
                    <div className={style.detailsPhoto}>
                        <div
                            style={{ backgroundImage: `url(${user.photo || DefaultAvatar})`, borderColor: `#${user.bannerColour || "407962"}` }}
                            className={style.avatar}
                        >
                            {showEdit ? (
                                <div style={{ outlineColor: `#${user.bannerColour ? user.bannerColour : theme == 'light' ? '407962' : '17a2b8'}`, background: `#${user.bannerColour ? user.bannerColour : theme == 'light' ? '407962' : '17a2b8'}` }} onClick={onClickEdit} className={style.editInPicture}>
                                    <FontAwesomeIcon className={style.iconInPicture} icon={['fas', 'edit']} />
                                </div>
                            ) : null}
                        </div>


                        {resume && showResume ? (
                            <a
                                href={resume}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.resumeContainer}
                            >
                                <h5><FontAwesomeIcon className={style.resume} icon={['fas', 'file-alt']} /></h5>
                                <h5>View CV</h5>
                            </a>
                        ) : null}

                        { !!videoLink && videoLink != " " ? (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.videoContainer}
                                onClick={toggle}
                            >
                                <h5><FontAwesomeIcon className={style.resume} icon={['fas', 'video']} /></h5>
                                <h5>View Video</h5>
                            </a>
                        ) : null}

                    </div>
                    <div className={style.detailsInfoMobile}>
                        <div className={style.name}>{user.name || user.username}</div> 
                        <div className={style.jobTitle}>{resolveJob()}</div>
                        { user.email && userRoles.includes("Admin") ? <>Contact at: <a href={`mailto:${user.email}`} className={style.jobTitle}>{user.email}</a></> : null }

                        <NudgeBoxes
                            data={[
                                { label: user.workType != 'Volunteer' ? processEarnings(expectedEarnings, user.currencyType, user.timing, user.workType) : null, danger: '' },
                                { label: user.workType ? user.workType : null, danger: '' },
                                { label: user.workSearch ? processSearchStatus(user.workSearch) : null, danger: '' },
                            ]}
                        />
                        
                        { user.idReports?.length ? <>
                            <p>ID Check Report: <b><a href={user.idReports[0]}>Download</a></b></p>
                        </> : null }

                        {user.bio?.trim().length ? <div className={style.bio}>{user.bio}</div> : null}
                    </div>
                </Col>
                <Col className={style.detailsDesktop}>
                    <div className={style.detailsPhoto}>
                        <EditButton onEdit={onClickEdit} show={showEdit} />
                        <div
                            style={{ backgroundImage: `url(${user.photo || DefaultAvatar})`, borderColor: `#${user.bannerColour || "407962"}` }}
                            className={style.avatar}
                        />
                    </div>

                    <div className={style.detailsInfo}>
                        <div className={style.name}>{user.name || user.username}</div>
                        <div className={style.jobTitle}>{resolveJob()}</div>
                        { user.email && userRoles.includes("Admin") ? <>Contact at: <a href={`mailto:${user.email}`} className={style.jobTitle}>{user.email}</a></> : null }
                        { user.shareCode && userRoles.includes("Admin") ? <p>Share code is: {user.shareCode}</p> : null }
                        <NudgeBoxes
                            data={[
                                { label: user.workType != 'Volunteer' ? processEarnings(expectedEarnings, user.currencyType, user.timing, user.workType) : null, danger: '' },
                                { label: user.workType ? user.workType : null, danger: '' },
                                { label: user.workSearch ? processSearchStatus(user.workSearch) : null, danger: '' },
                            ]}
                        />

                        { user.idReports?.length ? <>
                            <p>ID Check Report: <b><a href={user.idReports[0]}>Download</a></b></p>
                        </> : null }

                        {resume && showResume ? (
                            <a
                                href={resume}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.resumeContainer}
                            >
                                <h5><FontAwesomeIcon className={style.resume} icon={['fas', 'file-alt']} /></h5>
                                <h5>View CV</h5>
                            </a>
                        ) : null}

                        { !!videoLink && videoLink != " " ? (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.videoContainer}
                                onClick={toggle}
                            >
                                <h5><FontAwesomeIcon className={style.video} icon={['fas', 'video']} /></h5>
                                <h5>View Video</h5>
                            </a>
                        ) : null}


                        {user.bio?.trim().length ? <div className={style.bio}>{user.bio}</div> : null}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default NonEditableContractorInformation;
