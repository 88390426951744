import React from 'react';

import { Button } from 'reactstrap';
import { authPostDataWithNoResponse } from '../../../api/tools';
import { ProcessSalary } from '../../pages/GenericCompanyProfile/HelperComponents';
import SmallHeader from '../../SmallHeader';
import ClickButton from '../../buttons/NudgeButton';

export const onSubmit = (id, refresh, toggle, api) => async (values) => {
    const url = `${api}/Matches/${id}/Bid`;

    values.amount = parseFloat(values.amount);

    await authPostDataWithNoResponse(values, url);

    await refresh();
    toggle();
};

export const onClickAccept = (api, id, refresh) => async () => {
    const url = `${api}/Matches/${id}/Bid/Accept`;
    await authPostDataWithNoResponse({}, url);
    await refresh();
};

export const BodyDisplayValue = ({ value, title, frequency, toggle }) => {

    return (
        <>
            <SmallHeader>{title}</SmallHeader>
            <SmallHeader>
                <strong>{ProcessSalary(value)} </strong>GBP {frequency}
            </SmallHeader>
            <ClickButton color="success" onClick={toggle} className="ml-2" style={{ float: 'right' }}>
                Back
            </ClickButton>
        </>
    );
};
