import React from 'react';

import { Card, Col, Row } from 'reactstrap';
import NudgeButton from '../../../../components/buttons/NudgeButton';
import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import { authFetcher } from '../../../../api/tools';
import { GetPageTheme, calculateLightness, convertHexToRGB, generateRandomString, sort_by_key, sort_by_nested_keys } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';

import { calculateDays, getTotalAverageTime, getBar, buildTime } from './helpers';
import style from './style.module.scss';
import Loader from '../../../../components/Loader';
import { MATCH_STATUSES_DICTIONARY } from '../../../../consts/matchOfferStatuses';
import jsPDF from 'jspdf';
import { StartNewPage, drawPDFBar } from '../TimeToHireDash';

import NudgeChalk from '../../../../assets/images/nudge-logo-chalk-full.png'
import NudgeForest from '../../../../assets/images/Nudge full logo.png';

import PDFMerger from 'pdf-merger-js';
import SearchBox from '../../../../components/SearchBox';

async function merge(pdf1, pdf2) {
    var merger = new PDFMerger();
    
    await merger.add(pdf1);
    await merger.add(pdf2);

    await merger.save('merged-file');
}

function drawText(doc, text, color, x, y) {
    var ogCol = doc.getTextColor();
    doc.setTextColor(color)
    doc.text(text, x, y);
    doc.setTextColor(ogCol);
}

async function exportPDF(data, user, highestPerStage) {

    var y_incrementer = 32.5;
    const boxHeight = 27.5;
    const pageHeight = 300;

    const doc = new jsPDF();
    const reportTitle = "Time per Stage Report";

    const forest = convertHexToRGB(`#${user?.backColour || ""}`);
    const lightness = calculateLightness(`#${user?.backColour || ""}`);

    var img = new Image()
    img.src = lightness > 50 ? NudgeForest : NudgeChalk;

    // DRAW PAGE HEADER AND FOOTER
    doc.setFillColor(forest.r, forest.g, forest.b);
    doc.rect(0, 0, 225, 20, "F");
    doc.rect(0, pageHeight - boxHeight, 225, boxHeight, "F");
    doc.addImage(img, 'png', 210 - 40, 5, 35, 9);

    drawText(doc, reportTitle, lightness < 50 ? "white" : "black", 5, 10);
    
    //RESET FONT COLOUR
    data.stages.map((offer, index) => {
        doc.setTextColor("black");

        var totalHeight = (17 + ((9 * offer.times.stages.length)) + 9)
        if(y_incrementer + totalHeight > (pageHeight - 20)) { //IF NEW ELEMENT WOULD EXTEND FURTHER THAN THE BOUNDS OF THE REPORT, START A NEW PAGE AND DRAW A NEW PAGE HEADER AND FOOTER
            doc.setFontSize(16);
            StartNewPage(reportTitle, doc, pageHeight, boxHeight, forest, lightness);
            y_incrementer = 32.5;
        }

        doc.setFillColor(0, 0, 0);
        doc.rect(5, y_incrementer - 7.5, 200, totalHeight - 2.5)

        //DRAW BAR CHART HEADERS
        doc.setFontSize(12); 
        doc.text(`${offer.title}`, 10, y_incrementer); y_incrementer += 6;
        doc.text(`Average Time per Stage: ${buildTime(offer.averageTime)}`, 12.5, y_incrementer); y_incrementer += 8;

        //DRAW BAR CHART
        doc.setTextColor("white");
        offer.times.stages.forEach((stage, index2) => {
            drawPDFBar(doc, `${stage.status}: ${buildTime(stage.time)}`, index2 % 2 == 0 ? "#0e6674" : "#17a2b8", y_incrementer, stage.total, offer.highest); y_incrementer += 9;
        })
        
        y_incrementer += 12;
    })

    //START NEW PAGE AND DRAW PER STAGE STATISTICS
    StartNewPage(reportTitle, doc, pageHeight, boxHeight, forest, lightness)
    y_incrementer = 32.5;

    doc.setTextColor("black");
    doc.text("Total Average Time per Stage", 10, y_incrementer); y_incrementer += 8;
    doc.setTextColor("white");

    doc.setFillColor(0, 0, 0);
    doc.rect(5, 25, 200, 25 + (data.uniqueStages.length * 9))

    data.uniqueStages.forEach((item, index) => {
        drawPDFBar(doc, `${item.stage}: ${buildTime(item.time)}`, index % 2 == 0 ? "#0e6674" : "#17a2b8", y_incrementer, item.total, highestPerStage);
        y_incrementer += 9;
    })

    //DRAW OVERALL PER STAGE
    doc.setFontSize(16);
    var txt = "Overall Average Time per Stage"; doc.text(txt, 200 - doc.getTextWidth(txt), 282.5);
    txt = data.totalAverage; doc.text(txt, 200 - doc.getTextWidth(txt), 290);
    
    doc.save(`timeperstage_report-${user.id}.pdf`);
}

const TestTimePerStatus = ({ user }) => {
    const [highestStageTotal, setHighestStageTotal] = React.useState(0);
    const [data, setData] = React.useState();
    const [mutateKey, setMutateKey] = React.useState();;

    const [sending, setSending] = React.useState(false);

    const [search, setSearch] = React.useState('');

    function mutate() {
        setMutateKey(!mutateKey);
    }

    React.useEffect(() => {
        async function getData() {
            setSending(true);
            setData();
            //
            var response = await authFetcher(`${API_DASHBOARD_COMPANY}/Matches`)
            if(!response) return;

            var timeMatches = response.filter((i) => i.timesPerStage.length).map((match, index) => ({
                title: `${match.offerName} - ${match.contractor.name}`,
                matchId: match?.id,
                matchCreated: match?.dateCreated,
                stages: sort_by_key(match?.timesPerStage, 'id', false)
            }));

            if(timeMatches?.length) {
                timeMatches.forEach((match, index) => {
                    match.times = calculateDays(match);
                })
    
                var buildObj = {
                    stages: timeMatches,
                    uniqueStages: getUniqueStages(timeMatches),
                    totalAverage: getTotalAverageTime(timeMatches)
                }
    
                setData(buildObj);
            }

            //
            setSending(false);
        }

        getData();
    }, [mutateKey])

    function getUniqueStages(d) {
        var arr = [];
    
        var perStageArr = [];
        var calcObj = [];
    
        d.forEach((match) => {
            arr = arr.concat(match.stages);
            calcObj = calcObj.concat(calculateDays(match).stages)
        })
    
        arr = sort_by_key(arr, 'matchStage', false)
    
        var stageObj = [...new Set(arr.map((item) =>  item.matchText))];
        stageObj.forEach((i) => {
            var findObj = calcObj.filter((j) => j.status == i);

            var avDays = findObj.reduce(function (x, y) {
                return x + y.time.days;
            }, 0) / findObj.length;
            var avHours = findObj.reduce(function (x, y) {
                return x + y.time.hours;
            }, 0) / findObj.length;
            var avMinutes = findObj.reduce(function (x, y) {
                return x + y.time.minutes;
            }, 0) / findObj.length;
            var avSeconds = findObj.reduce(function (x, y) {
                return x + y.time.seconds;
            }, 0) / findObj.length;
    
            
            var avTotal = findObj.reduce(function (x, y) {
                return x + y.total;
            }, 0) / findObj.length;
            perStageArr.push({ stage: i, total: avTotal, time: { days: parseInt(avDays), hours: parseInt(avHours), minutes: parseInt(avMinutes), seconds: parseInt(avSeconds) } })
        })
        
        var highest = 0;
        perStageArr.forEach((item) => {
            if(item.total > highest) {
                highest = item.total;
            }
        })
    
        if(highestStageTotal == 0) {
            setHighestStageTotal(highest);
        }
    
        return perStageArr;
    }


    const theme = GetPageTheme()
    return  (<>
        { data && !sending ? <Row>
            <Col md={12}>
                <Card id={`card-${theme}`} style={{ padding: '12px', marginBottom: '12px' }}>
                    <Row>
                        <Col xs={0} md={0} lg={3}/>
                        <Col xs={6} md={6} lg={3}>
                            <h4>Overall Average Time per Status</h4>
                            <h5>{data?.totalAverage}</h5>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <NudgeButton color="success" style={{ height: '100%', width: '100%', marginBottom: '6px' }} onClick={() => exportPDF(data, user, highestStageTotal)}>Export to PDF</NudgeButton>
                        </Col>
                        <Col xs={0} md={0} lg={3}/>
                    </Row>
                </Card>
            </Col>
        </Row> : null }
        
        <div>
            { !sending ? <>
                <SearchBox value={search} setValue={setSearch} />
                <Row className={data ? style.cardScroller : ``}>
                    { data?.stages ? data.stages.filter((item) => item.title.toUpperCase().includes(search.toUpperCase())).map((match, i) => <>
                        <Col xs={12} md={6} lg={3}>
                            <Card className={style.card}>
                                <h5><b>{match.title}</b></h5>
                                <div className={style.barContainer}>
                                    { match.times.stages.map((item, index) => (<>
                                        { getBar(match?.highest, MATCH_STATUSES_DICTIONARY[item.status], buildTime(item.time), item.total, index, 80) }
                                    </>)) }
                                </div>

                                <h5><b>Average Time Per Stage for this Match</b></h5>
                                <p>{buildTime(match?.averageTime)}</p>
                            </Card>
                        </Col>
                        <br/>
                    </>) : <Col md={12}>
                        <Card className={style.card}>
                            <i>No data found.</i>
                        </Card>
                    </Col>}
                    { data?.stages ? [...Array((Math.ceil(data?.stages.length / 4) * 4) - data?.stages.length)].map(() => (
                        <Col xs={12} md={6} lg={3}>
                            <Card style={{ opacity: '0.6' }} className={style.card}/>
                        </Col>
                    )) : null }
                </Row>

                <Row>
                    <Col md={12}>
                        <Card className={style.card}>
                            <h5><b>Total Average Time By Stage</b></h5>
                            { data ? data?.uniqueStages.map((stage, index) => <>
                                { getBar(highestStageTotal, MATCH_STATUSES_DICTIONARY[stage.stage], buildTime(stage.time), stage.total, index, 80) }
                            </>) : <i>No data found.</i>}
                            <br/>
                            <NudgeButton color="success" onClick={mutate}>Refresh</NudgeButton>
                        </Card>
                    </Col>
                </Row>
            </> : null }
            { sending ? <Loader message="Collecting data"/> : null }
        </div>
    
    </>)
}

export default TestTimePerStatus;