import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, postData, NEVER_REFRESH, authPostData } from '../../../../../api/tools';
import { API_DASHBOARD_ADMIN } from '../../../../../consts/urls';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import { sort_by_key } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';

import SearchBox from '../../../../../components/SearchBox';

const TestingGround = () => {
    function arrToString(input) {
        let str = '';
        if (input.length) {
            input.forEach((i, index) => {
                if (index == 0) {
                    str += `${i.title ? i.title : i.content ? i.content : i.name}`;
                } else {
                    str += `,${i.title ? i.title : i.content ? i.content : i.name}`;
                }
            });
        }
        return str;
    }

    const [search, setSearch] = React.useState('');
    const [matches, setMatches] = React.useState([]);
    const [alert, setAlert] = React.useState('');

    const getURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/project/4aedf72155b64e46a61cffd5b83a2d79`;
    const { data: attributes } = useSWR(getURL, authFetcher, NEVER_REFRESH);

    const postURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/retrieve`;

    async function retainCase(searchID) {
        let contractor = await authFetcher(`${API_DASHBOARD_ADMIN}/Contractors`);

        if (contractor) {
            const split = contractor.expectedEarnings.replaceAll(' ', '').split('-');

            let fromFloat = parseFloat(split[0] || 50);
            let toFloat = parseFloat(split[1] || 50);

            let salary;
            if (contractor.timing == 'Yearly') {
                salary = ((fromFloat + toFloat) / 2 / 52 / 40).toFixed(2);
            } else if (contractor.timing == 'Daily') {
                salary = ((fromFloat + toFloat / 2) / 8).toFixed(2);
            } else {
                salary = (fromFloat + toFloat / 2).toFixed(2);
            }

            const body = {
                globalSim: 'Weighted Sum',
                topk: 10,
                projectId: '4aedf72155b64e46a61cffd5b83a2d79',
                data: [
                    {
                        name: 'Id',
                        type: 'String',
                    },
                    {
                        name: 'Services Required',
                        type: 'String',
                        value: contractor.job.name,
                    },
                    {
                        name: 'Remuneration',
                        type: 'Float',
                        value: parseFloat(salary),
                    },
                    {
                        name: 'Work Mode',
                        type: 'Categorical',
                        value: contractor.workMode,
                    },
                    {
                        name: 'Location',
                        type: 'String',
                        value: contractor.location || '',
                    },
                    {
                        name: 'Industry',
                        type: 'String',
                        value: contractor.job.industry,
                    },
                    {
                        name: 'Education',
                        type: 'String',
                        value: contractor.education.replaceAll('/', ' in '),
                    },
                    {
                        name: 'Certificates',
                        type: 'String',
                        value: arrToString(contractor.certificates),
                    },
                    {
                        name: 'Experience',
                        type: 'String',
                        value: contractor.bio,
                    },
                    {
                        name: 'Start Date',
                        type: 'Date',
                        //"value": new Date(contractor.availableFrom)
                    },
                    {
                        name: 'Skills',
                        type: 'String',
                        value: arrToString(contractor.skills),
                    },
                    {
                        name: 'Freelance',
                        type: 'Categorical',
                        value: contractor.freelancerStaff,
                    },
                    {
                        name: 'Values',
                        type: 'String',
                        value: arrToString(contractor.values),
                    },
                ],
            };

            try {
                const response = await authPostData(body, postURL);

                if (response?.bestK) {
                    setMatches(sort_by_key(response.bestK, 'score__').reverse());
                    setAlert('');
                }
            } catch (e) {
                setAlert(`Something went wrong: ${e.message}`);
            }
        }

        if (!contractor) {
            setAlert('Contractor not found.');
        }
    }

    React.useEffect(() => {
        if (attributes) console.log(attributes);
    }, [attributes]);

    return (
        <>
            <SearchBox value={search} setValue={setSearch} button={false} placeholder={'User ID goes here...'} />
            <Button onClick={() => retainCase(search)} color={'success'}>
                Post
            </Button>
            <Row>
                {alert ? <h5>{alert}</h5> : null}
                {matches.length ? (
                    <Col md={4}>
                        {matches.map((item) => (
                            <>
                                {item.score__.toFixed(2) != '0.00' ? (
                                    <h5>
                                        {item['Services Required'] || 'Title not found'} -{' '}
                                        {(item.score__ * 10).toFixed(2)}%
                                    </h5>
                                ) : null}
                            </>
                        ))}
                    </Col>
                ) : null}
            </Row>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
