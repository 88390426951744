import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import OfferInfo from '../OfferInfo';

import BackButton from '../../buttons/BackButton';
import LinkButton from '../../buttons/LinkButton';

import { authFetcher } from '../../../api/tools';
import { useDismissModal } from '../../pages/OfferGenericPage/DismissModal';
import OfferTooltip, { ACCEPT_TOOLTIP, useOfferTooltip } from '../../pages/OfferGenericPage/OfferTooltip';
import SignInPageTemplate from '../../SignInPageTemplate';
import { API_DASHBOARD_CONTRACTOR, URLS } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { FIELDS, OTHER_LABEL } from './DismissModal/DismissModalForm/validation';

import { prepareAuthHeaders } from '../../../api/tools';

import Buttons from './Buttons';
import DismissModal from './DismissModal';
import { authPostData } from '../../../api/tools';
import { OfferClosedFunction } from '../../pages/GenericCompanyProfile/HelperComponents';

import moment from 'moment';

import style from '../style.module.scss';
import Loader from '../../Loader';
import { countNew } from '../../SignInPageTemplate/SignInNavbar/NotificationsModal';

export const ContractOffer = () => {
    const navigate = useNavigate();
    const { user, userRoles } = useContext(AuthContext);

    const { id } = useParams();
    const { data, mutate, isValidating } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Offers/${id}`, authFetcher, {
        refreshInterval: 0,
        revalidateOnFocus: false,
    });

    const { isOpen, toggle, setIsOpen, isRejectOfferTooltipVisible, onSubmitReject } = useDismissModal();
    const { isOfferTooltipVisible, showOfferTooltip } = useOfferTooltip();
    const [sending, setSending] = React.useState(false);

    const leftWidth = 8;

    function GetProfileLink() {
        if (userRoles.includes('Admin')) {
            return `${URLS.ADMIN_COMPANIES}/${data.company.id}`;
        } else if (userRoles.includes('User')) {
            return `${URLS.DIRECTORY}/${data.company.id}`;
        } else {
            return `${URLS.COMPANY_PROFILE}`;
        }
    }

    const clickView = () => {
        navigate(`${URLS.MEETING}/${data.matchId}`);
    };

    const onClickRegister = async () => {
        setSending(true);
        var url = `${API_DASHBOARD_CONTRACTOR}/Offers/${id}/Interest`;
        await authPostData({ oId: id }, url);
        await mutate();
        setSending(false);
    };

    const onClickDismiss = () => setIsOpen(true);

    const onClickAccept = async () => {
        setSending(true);
        const headers = await prepareAuthHeaders();

        try {
            await fetch(`${API_DASHBOARD_CONTRACTOR}/Offers/${id}/Accept`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
            });
        } catch (e) {
            console.log(e.message);
        }

        showOfferTooltip(true);

        await mutate();
        setSending(false);
    };

    const onSubmit = async (values) => {
        setSending(true);
        await onSubmitReject(`${API_DASHBOARD_CONTRACTOR}/Offers/${id}/Reject`, FIELDS, OTHER_LABEL, values);
        navigate(`${URLS.OFFERS}/${id}`);

        await mutate();

        toggle();
        setSending(false);
    };

    var newNotifications = 0;
    if (user && userRoles) {
        if (!userRoles.includes('Admin')) {
            newNotifications = countNew(user.notifications);
        }
    }

    return (
        <SignInPageTemplate>
            <Helmet>
                <title>
                    {user ? (newNotifications.length ? `(${newNotifications})` : ``) : ``}{' '}
                    {data ? `${data.title} - Nudge Energy Talent Lab` : 'Contracts'}
                </title>
            </Helmet>
            {data ? (
                <>
                    <DismissModal
                        isOpen={isOpen}
                        toggle={toggle}
                        onSubmit={onSubmit}
                        showRejectTooltip={isRejectOfferTooltipVisible}
                    />
                    <OfferTooltip
                        visible={isOfferTooltipVisible}
                        message={'Thanks, your application has been sent to ' + data.company.name + '.'}
                        type={ACCEPT_TOOLTIP}
                    />

                    <Row className={style.headerContainer}>
                        <Col xs={12}>
                            <Card className={style.mobileCompanyCard}>
                                <Row>
                                    <Col xs={2}>
                                        <img
                                            className={style.mobileCompanyImage}
                                            src={data.company.logo}
                                            alt="company"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        Posted by:
                                        <h5 className={style.mobileCompanyTitle}>{data.company.name}</h5>
                                    </Col>
                                    <Col xs={4}>
                                        <Row className={style.mobileCompanyButtonContainer}>
                                            {userRoles ? (
                                                <LinkButton
                                                    color="success"
                                                    link={GetProfileLink()}
                                                    className={style.mobileCompanyButton}
                                                >
                                                    View Profile
                                                </LinkButton>
                                            ) : null}
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={style.headerContainer}>
                        <Col xs={12} md={leftWidth} className={style.offerButtonContainer}>
                            {!OfferClosedFunction(data.availabilityText) ? (
                                <Buttons
                                    onClickDismiss={onClickDismiss}
                                    onClickAccept={onClickAccept}
                                    clickView={clickView}
                                    clickRegister={onClickRegister}
                                    matchId={data.matchId}
                                    matchStatus={data.matchStatus}
                                    offerStatus={data.offerStatus}
                                    sending={sending}
                                    companyFeedback={data.companyFeedback}
                                    contractorFeedback={data.contractorFeedback}
                                />
                            ) : (
                                <h5></h5>
                            )}
                        </Col>
                    </Row>
                    <OfferInfo data={data} />
                </>
            ) : null}
            {data ? null : isValidating ? (
                <Loader />
            ) : (
                <>
                    {' '}
                    <BackButton className={style.offerButton} show={true} />{' '}
                    <h5 className="text-center">
                        Offer unavailable. Either the contract hasn't been published yet, or there is another problem.
                    </h5>{' '}
                </>
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(ContractOffer, [USER]);
