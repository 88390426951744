import React from 'react';
import { Button } from 'reactstrap';
import { deleteData, authPostData, putData } from '../../../../../api/tools';

import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../components/buttons/NudgeButton';

const obj = [
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "BSc/BEng Mechanical",
            "rep": [
                -0.04975113272666931,
                -0.07609160989522934,
                -0.017733944579958916,
                0.007807464338839054,
                0.0431661456823349,
                0.00990508496761322,
                -0.011086503975093365,
                0.0362747386097908,
                0.023860443383455276,
                -0.02522106282413006,
                0.04789162799715996,
                -0.003382554743438959,
                0.02656995877623558,
                0.05652273818850517,
                0.02763313241302967,
                0.029432101175189018,
                -0.0007527027628384531,
                0.004115332383662462,
                -0.061810676008462906,
                -0.0441141314804554,
                -0.06106032803654671,
                -0.008632439188659191,
                -0.05181359872221947,
                0.029810138046741486,
                -0.011007767170667648,
                -0.009360713884234428,
                0.009644846431910992,
                0.002939443802461028,
                0.04364606738090515,
                0.03527755290269852,
                0.018062453716993332,
                0.04454728960990906,
                0.01704314909875393,
                0.037772178649902344,
                1.3483318070939276e-06,
                -0.005145159550011158,
                0.022534137591719627,
                0.027723096311092377,
                0.002444940386340022,
                -0.026913294568657875,
                -0.0049729980528354645,
                0.04560181871056557,
                -0.014458340592682362,
                0.0028785336762666702,
                -0.017272042110562325,
                0.06373271346092224,
                0.011374439112842083,
                -0.021515367552638054,
                -0.020580867305397987,
                -0.012716127559542656,
                0.025725850835442543,
                -0.03458297252655029,
                -0.03278821334242821,
                -0.019883854314684868,
                -0.01724916324019432,
                -0.05126215144991875,
                0.01233815960586071,
                0.018536970019340515,
                0.054339535534381866,
                0.07783301174640656,
                -0.04761621728539467,
                -0.01719881221652031,
                -0.005069108214229345,
                -0.013411768712103367,
                0.07365044206380844,
                0.07102030515670776,
                0.034811753779649734,
                0.0233173705637455,
                0.011750091798603535,
                0.021028710529208183,
                0.05515946447849274,
                -0.0316336564719677,
                0.007957706227898598,
                0.029022306203842163,
                0.00453384593129158,
                0.012187584303319454,
                -0.004766587167978287,
                0.019631555303931236,
                -0.021808624267578125,
                -0.003304340410977602,
                -0.04560864344239235,
                0.01650271750986576,
                -0.013758262619376183,
                0.0014589414931833744,
                0.00500831613317132,
                -0.004697446711361408,
                -0.02056867815554142,
                0.018320376053452492,
                0.014318804256618023,
                0.01121867261826992,
                0.027398478239774704,
                0.010709594003856182,
                -0.030575919896364212,
                0.003855821443721652,
                -0.010538301430642605,
                -0.02409696765244007,
                0.047820065170526505,
                0.03242935985326767,
                -0.0012502833269536495,
                0.013922084122896194,
                -0.04481644183397293,
                0.002656809287145734,
                0.002203348558396101,
                0.03318047150969505,
                -0.055336207151412964,
                0.03743646293878555,
                0.014917643740773201,
                0.043176308274269104,
                -0.011944056488573551,
                0.02191603183746338,
                -0.07705169171094894,
                -0.015759896486997604,
                0.0007689959602430463,
                -0.019563086330890656,
                -0.009673845022916794,
                0.057822275906801224,
                -0.022931328043341637,
                -0.015299202874302864,
                -0.0161125510931015,
                -0.020303696393966675,
                0.0430300198495388,
                0.015370765700936317,
                -0.01654256135225296,
                0.026789270341396332,
                -0.011681441217660904,
                -0.013781906105577946,
                0.004710707813501358,
                0.03142058476805687,
                0.06177098676562309,
                0.030613066628575325,
                -0.03291619196534157,
                -0.024182608351111412,
                -0.020969916135072708,
                -0.01177937537431717,
                -0.00785029772669077,
                0.0067004612646996975,
                -0.05682447925209999,
                0.07215949892997742,
                -0.043133944272994995,
                -0.021889688447117805,
                -0.008969313465058804,
                -0.03211339935660362,
                0.023529697209596634,
                0.06268532574176788,
                -0.002148663392290473,
                0.019411519169807434,
                0.008591679856181145,
                0.009565779939293861,
                0.028793929144740105,
                -0.016550680622458458,
                -0.0028383301105350256,
                -0.025170940905809402,
                0.013466797769069672,
                -0.04394622519612312,
                0.010840727016329765,
                0.010325321927666664,
                0.04259377717971802,
                0.0021923650056123734,
                -0.053294409066438675,
                0.07731430232524872,
                -0.04271671548485756,
                0.01078083273023367,
                -0.01950579136610031,
                -0.0019523550290614367,
                -0.02995077706873417,
                -0.04036259278655052,
                -0.020797938108444214,
                -0.06155414134263992,
                0.01222336944192648,
                0.007969272322952747,
                -0.0030190672259777784,
                -0.04741208255290985,
                -0.02933524362742901,
                0.058050308376550674,
                0.0037281897384673357,
                -0.022157542407512665,
                0.05701019614934921,
                0.015901779755949974,
                -0.052361153066158295,
                -0.011614356189966202,
                0.03771449998021126,
                -0.10933326929807663,
                0.03774837777018547,
                0.012295021675527096,
                -0.035742808133363724,
                -0.006628491450101137,
                -0.029977142810821533,
                0.010664077475667,
                -0.029918469488620758,
                -0.0027565485797822475,
                0.009175160899758339,
                0.03204236552119255,
                -0.04836687073111534,
                -0.0034082497004419565,
                0.04336618632078171,
                -0.005042292643338442,
                0.0034768262412399054,
                0.03715493902564049,
                0.01710778847336769,
                0.008728984743356705,
                -0.01892882026731968,
                0.021983813494443893,
                0.0010082622757181525,
                -0.022260036319494247,
                0.020154455676674843,
                -0.051445212215185165,
                -0.015083329752087593,
                -0.028319111093878746,
                -0.026654621586203575,
                0.057722363620996475,
                -0.03371730446815491,
                -0.0009573673596605659,
                0.0761217474937439,
                0.02040725201368332,
                -0.04632947966456413,
                -0.035653255879879,
                -0.007119708228856325,
                -0.014567790552973747,
                -0.06238500773906708,
                -0.03919420391321182,
                -0.027471520006656647,
                0.0117438193410635,
                0.00594443129375577,
                -0.02287384122610092,
                -0.04576205089688301,
                0.04832759499549866,
                0.05080901458859444,
                -0.02541865035891533,
                0.0012117684818804264,
                0.03333073481917381,
                0.01205886248499155,
                -0.013756714761257172,
                0.038928866386413574,
                -0.04282473400235176,
                0.009374904446303844,
                -0.035073257982730865,
                0.04330790042877197,
                -0.02270062454044819,
                0.0449303463101387,
                -0.034209150820970535,
                0.015754977241158485,
                -0.03215445950627327,
                0.016601260751485825,
                -0.02612650953233242,
                0.019846994429826736,
                0.030938243493437767,
                -0.020682763308286667,
                0.012232114560902119,
                0.02583535760641098,
                -0.028601238504052162,
                -0.03892374783754349,
                -0.02684907801449299,
                0.019456954672932625,
                0.05765973776578903,
                -0.010713420808315277,
                -0.013119802810251713,
                -0.03787803649902344,
                0.08441398292779922,
                -0.04599456489086151,
                -0.06126054376363754,
                0.07265426218509674,
                -0.07493537664413452,
                0.0002713797730393708,
                0.003898478811606765,
                0.003510352224111557,
                0.016557348892092705,
                -0.009562061168253422,
                -0.03659624606370926,
                -0.0548216737806797,
                0.031054258346557617,
                -0.0336068794131279,
                0.03280898183584213,
                0.026477523148059845,
                -0.009389822371304035,
                -0.011925425380468369,
                0.010593078099191189,
                -0.0011769343400374055,
                0.005900005344301462,
                -0.09655050188302994,
                0.010269375517964363,
                0.023252638056874275,
                0.048918042331933975,
                -0.004400428384542465,
                -0.014552210457623005,
                -0.03446555137634277,
                0.0002443577686790377,
                0.04880358651280403,
                0.025883318856358528,
                -0.0683208778500557,
                0.01254693791270256,
                -0.013936232775449753,
                0.01787152700126171,
                0.00793861411511898,
                -0.08165241777896881,
                -0.0018935087136924267,
                0.04371989890933037,
                -0.00309503055177629,
                0.05358091741800308,
                0.02544519305229187,
                -0.018397880718111992,
                0.014677116647362709,
                0.020404452458024025,
                0.013115346431732178,
                0.01736033707857132,
                -0.006064196117222309,
                0.07057011127471924,
                0.0192209891974926,
                0.02937338687479496,
                -0.01327528990805149,
                -0.04184284806251526,
                -0.028139032423496246,
                0.01631118357181549,
                0.0191828403621912,
                0.03191099315881729,
                0.05736631527543068,
                -0.0365479476749897,
                -0.04079684615135193,
                0.00358385406434536,
                -0.009675715118646622,
                0.02088748849928379,
                0.02107124775648117,
                0.050551630556583405,
                -0.008111026138067245,
                -0.034523606300354004,
                0.00637245737016201,
                0.01984909176826477,
                -0.010378353297710419,
                0.016128212213516235,
                0.007645559962838888,
                -0.045728426426649094,
                -0.011984216049313545,
                0.04779902473092079,
                -0.029004951938986778,
                -0.01853133551776409,
                0.08593045175075531,
                -0.02929694391787052,
                0.003293297253549099,
                -0.11721090972423553,
                -0.012952694669365883,
                0.0559915155172348,
                -0.005357643123716116,
                -0.03451085463166237,
                -0.048807285726070404,
                -0.006647753529250622,
                0.012289689853787422,
                0.02655082195997238,
                0.019121378660202026,
                -0.012263178825378418,
                0.014316520653665066,
                -0.0018805032595992088,
                0.06285092979669571,
                0.015674734488129616,
                -0.01170350145548582,
                0.022528713569045067,
                -0.02511458843946457,
                0.011530893854796886,
                0.04257260262966156,
                0.045336149632930756,
                0.034705061465501785,
                0.01385169941931963,
                -0.040686991065740585,
                -0.023320002481341362,
                -0.030438700690865517,
                -0.038507137447595596,
                -0.041121356189250946,
                -0.03764007240533829,
                0.08899254351854324,
                0.0018122214823961258,
                -0.004156442824751139,
                -0.0023281380999833345,
                -0.040558744221925735,
                0.009997999295592308,
                -0.05461276322603226,
                -0.017170177772641182,
                -0.02902887389063835,
                0.03220178186893463,
                -0.009967276826500893,
                0.03930284082889557,
                -0.009776080958545208,
                -0.006443696562200785,
                0.035989902913570404,
                -0.031635481864213943,
                -0.007487182971090078,
                -0.004751840140670538,
                0.028055425733327866,
                -0.01147543080151081,
                -0.013069516979157925,
                2.708261126826983e-05,
                -0.011517033912241459,
                -0.031952496618032455,
                0.003028731094673276,
                -0.015421888791024685,
                -0.06620188057422638,
                0.02437879890203476,
                -1.6177053112187423e-05,
                -0.02477393113076687,
                -0.03140799701213837,
                0.011883576400578022,
                0.0074966177344322205,
                0.007246395573019981,
                0.055311962962150574,
                0.01766126975417137,
                -0.00576208159327507,
                0.026419201865792274,
                0.015393950045108795,
                -0.04281668737530708,
                0.037758756428956985,
                -0.04000713303685188,
                -0.0786314383149147,
                0.012481173500418663,
                0.04075925797224045,
                -0.013114873319864273,
                0.012380353175103664,
                -0.03483482077717781,
                -0.029033703729510307,
                -0.03544991835951805,
                -0.016985664144158363,
                0.006810237653553486,
                0.0010196005459874868,
                -0.03798878937959671,
                0.0036300774663686752,
                -0.0053888605907559395,
                0.06749595701694489,
                -0.06197410076856613,
                0.05187211185693741,
                -0.04621344432234764,
                -0.019771719351410866,
                0.010374171659350395,
                0.0009538617450743914,
                -0.03421028330922127,
                0.013174752704799175,
                -0.005681029055267572,
                -0.058324288576841354,
                0.005235282704234123,
                0.04231109097599983,
                -0.016748538240790367,
                -0.02350793220102787,
                -0.0029883377719670534,
                -0.05989702418446541,
                0.03262707218527794,
                0.019788572564721107,
                0.004190499894320965,
                -0.00012716333731077611,
                -0.04837358370423317,
                0.08667746186256409,
                0.1275588870048523,
                -0.030616896227002144,
                -0.03126799687743187,
                -0.02207835391163826,
                -0.04164116829633713,
                0.10650748759508133,
                -0.07123779505491257,
                -0.0026530814357101917,
                -0.015392431989312172,
                -0.031838174909353256,
                -0.08054279536008835,
                -0.10785455256700516,
                0.06202159449458122,
                -0.029694905504584312,
                0.030341802164912224,
                0.048381540924310684,
                0.02026805281639099,
                -0.024152196943759918,
                -0.039527714252471924,
                -0.03907052427530289,
                -0.015473082661628723,
                0.029013581573963165,
                -0.012923863716423512,
                0.039811570197343826,
                -0.0461014099419117,
                -0.06632597744464874,
                -0.006353079341351986,
                -0.04288415238261223,
                0.0035720805171877146,
                -0.010189452208578587,
                -0.0023800728376954794,
                0.009081413969397545,
                0.08136104792356491,
                0.034394316375255585,
                0.02510998211801052,
                0.03399444371461868,
                0.0032754975836724043,
                0.010463230311870575,
                -0.019609803333878517,
                -0.020093850791454315,
                0.012188803404569626,
                0.04258417710661888,
                0.01723797805607319,
                0.0006696960190311074,
                -0.015014968812465668,
                0.06624571979045868,
                -0.1110951229929924,
                -0.0058709969744086266,
                -0.09735569357872009,
                0.07262565195560455,
                0.05469478666782379,
                0.03473460301756859,
                0.05598655715584755,
                -0.060281913727521896,
                0.07650715112686157,
                -0.031999167054891586,
                0.010683557018637657,
                0.01956242136657238,
                -0.05566900968551636,
                0.035430654883384705,
                0.03241704776883125,
                -0.008910601027309895,
                0.033604998141527176,
                0.012977485544979572,
                0.007953927852213383,
                0.03558976575732231,
                0.04153076931834221,
                -0.0013034588191658258,
                0.05059593915939331,
                -0.006419127341359854,
                -0.014214159920811653,
                0.012727002613246441,
                0.012635186314582825,
                0.02866056188941002,
                0.039387647062540054,
                -0.003018443938344717,
                -0.06067315489053726,
                0.03496508300304413,
                -0.022313004359602928,
                0.04251907765865326,
                -0.005735770333558321,
                -0.0024112826213240623,
                0.02484571933746338,
                0.0154556380584836,
                0.028506634756922722,
                0.005092026200145483,
                -0.040050625801086426,
                -0.004223095718771219,
                0.023742686957120895,
                -0.013515196740627289,
                0.04226042330265045,
                0.06317242234945297,
                0.031026802957057953,
                0.015061191283166409,
                0.06921389698982239,
                -0.03685571998357773,
                0.0221390500664711,
                0.03275186941027641,
                0.03979506343603134,
                0.0188444871455431,
                -0.058132369071245193,
                0.040474023669958115,
                0.00018381183326710016,
                0.01883205771446228,
                0.021690567955374718,
                0.024159057065844536,
                -0.011047572828829288,
                0.05071152001619339,
                0.05831729620695114,
                -0.01341160573065281,
                -4.813423818638703e-33,
                -0.02732320874929428,
                -0.02672613598406315,
                -0.02128642052412033,
                -0.06204163283109665,
                -0.02847377583384514,
                -0.03809399530291557,
                0.05465345457196236,
                0.0005786062101833522,
                -0.07435660809278488,
                -0.01153817493468523,
                -0.016784919425845146,
                0.015787990763783455,
                0.01226595789194107,
                0.018367880955338478,
                0.06572795659303665,
                -0.010779088363051414,
                0.017427807673811913,
                0.061642829328775406,
                0.012963763438165188,
                -0.03219782933592796,
                -0.026375558227300644,
                0.023227619007229805,
                0.020884903147816658,
                0.05249074846506119,
                0.0017077811062335968,
                -0.013119220733642578,
                -0.019266052171587944,
                -0.020694300532341003,
                -0.026545453816652298,
                -0.019379952922463417,
                0.02106006070971489,
                -0.012961633503437042,
                0.009417218156158924,
                0.06864868104457855,
                -0.00014760292833670974,
                -0.0028852075338363647,
                0.0006219783099368215,
                -0.03582083806395531,
                -0.0701286718249321,
                0.04043557494878769,
                0.03567268326878548,
                -0.024535780772566795,
                0.03284802660346031,
                -0.013004734180867672,
                -0.09430371224880219,
                -0.10297107696533203,
                -0.003185897134244442,
                0.024924546480178833,
                0.04558190703392029,
                -0.0363348163664341,
                -0.04755866155028343,
                -0.049022529274225235,
                -0.002629328053444624,
                0.008296217769384384,
                -0.019718382507562637,
                -0.01173262670636177,
                0.002911779098212719,
                0.015207864344120026,
                0.026344195008277893,
                0.013167587108910084,
                0.008628764189779758,
                0.051611948758363724,
                0.05333210900425911,
                -0.07226219773292542,
                -0.002037760568782687,
                -0.008221834897994995,
                0.010243289172649384,
                -0.014804246835410595,
                0.024118443951010704,
                -0.07857196033000946,
                -0.06854184716939926,
                0.10561223328113556,
                -0.010107045993208885,
                -0.06315965205430984,
                -0.022137362509965897,
                -0.061497077345848083,
                -0.050904981791973114,
                -0.015207595191895962,
                0.05936450883746147,
                0.04103865846991539,
                -0.06334441900253296,
                0.013080566190183163,
                0.028095420449972153,
                -0.028672849759459496,
                -0.0038844833616167307,
                -0.0026594267692416906,
                0.02272985130548477,
                0.04405088722705841,
                0.0272297952324152,
                -0.00466599827632308,
                0.04219549521803856,
                -0.04653351753950119,
                -0.03677131608128548,
                -0.025486676022410393,
                0.0924627035856247,
                -0.014279753901064396,
                0.0037274747155606747,
                -0.036721449345350266,
                0.010439886711537838,
                0.017288580536842346,
                -0.057859789580106735,
                0.04089277982711792,
                -0.027160760015249252,
                -0.01346368808299303,
                -0.002366036642342806,
                0.01616053283214569,
                -0.0546569898724556,
                -0.03231368958950043,
                0.03274111449718475,
                0.02046891115605831,
                -0.00809467863291502,
                -0.01178715005517006,
                -0.005296207964420319,
                0.013081451877951622,
                0.020882828161120415,
                0.015003434382379055,
                0.00976443663239479,
                -0.08950185775756836,
                -0.03493763878941536,
                0.03536655008792877,
                0.02699141763150692,
                -0.06372345983982086,
                -0.025946708396077156,
                0.009735142812132835,
                -0.008170326240360737,
                -0.011184223927557468,
                0.07587917149066925,
                -0.029671795666217804,
                -0.018472934141755104,
                -0.018058152869343758,
                -0.004461411852389574,
                0.012614840641617775,
                1.76806281615427e-07,
                -0.015624216757714748,
                0.06851816177368164,
                0.03730689734220505,
                0.040197283029556274,
                -0.045157767832279205,
                -0.0016511478461325169,
                -0.0026055581402033567,
                0.016113094985485077,
                0.0023228151258081198,
                0.0652453601360321,
                -0.03175634145736694,
                -0.01829425059258938,
                -0.001365585601888597,
                -0.11027515679597855,
                -0.034436557441949844,
                -0.018342243507504463,
                0.008739675395190716,
                0.021633334457874298,
                -0.02712859958410263,
                -0.010801220312714577,
                -0.0247257761657238,
                0.029172230511903763,
                -0.013849793002009392,
                0.007511825300753117,
                -0.010400076396763325,
                -0.007130767684429884,
                0.002797028049826622,
                0.007949401624500751,
                0.05823146551847458,
                0.028906309977173805,
                0.06090172007679939,
                0.01392280776053667,
                0.005867893807590008,
                0.021638188511133194,
                0.005807467736303806,
                0.0008058795356191695,
                0.056151360273361206,
                0.013204719871282578,
                0.0014421161031350493,
                0.061715397983789444,
                -0.03642107546329498,
                0.035141631960868835,
                0.03976400941610336,
                0.03992074728012085,
                -0.0030116841662675142,
                0.002746760146692395,
                0.013859870843589306,
                -0.0018691085278987885,
                -0.09811977297067642,
                -0.03935167193412781,
                -0.04471106082201004,
                0.06269440054893494,
                0.007337744813412428,
                -0.0249724593013525,
                0.022983774542808533,
                -0.008947378024458885,
                -0.015184495598077774,
                0.022156396880745888,
                0.030650023370981216,
                0.005909911822527647,
                -0.04043692722916603,
                -0.060241810977458954,
                0.015805866569280624,
                0.015086567029356956,
                0.09332851320505142,
                -0.07532300055027008,
                -0.041189827024936676,
                1.3430454584976577e-34,
                0.004812631756067276,
                -0.005872050300240517,
                0.031351037323474884,
                0.052553050220012665,
                0.013702412135899067,
                -0.027911540120840073,
                0.022488746792078018,
                0.01707214117050171,
                0.0005306990933604538,
                -0.0370170958340168,
                -0.027923859655857086
            ]
        },
        "Experience": {
            "name": "Experienced Design Engineer with a demonstrated history of working in the oil & energy industry. Strong engineering professional skilled in Mechanical and Structural Engineering, FS2000, Mathcad, Staad, FEA, Computer-Aided Design (CAD), Inventor, AutoCAD, SolidWorks, Microsoft Word, and Excel.",
            "rep": [
                -0.0182778500020504,
                -0.016692981123924255,
                -0.030193205922842026,
                0.00048231103573925793,
                0.021729260683059692,
                -0.015079147182404995,
                -0.004012011922895908,
                0.010154020972549915,
                -0.04151185601949692,
                -0.05360577628016472,
                0.005856547970324755,
                0.0006753423949703574,
                0.04671218991279602,
                0.05141448974609375,
                0.03480905294418335,
                0.0060961320996284485,
                -0.03402407839894295,
                0.029742540791630745,
                -0.0005695745348930359,
                0.003915260545909405,
                -0.03921480476856232,
                0.019406436011195183,
                -0.04138093441724777,
                0.026791594922542572,
                -0.04349585995078087,
                0.0064301094971597195,
                -0.002843524096533656,
                0.009379671886563301,
                0.036100901663303375,
                0.05788281559944153,
                0.04477035999298096,
                0.026010626927018166,
                0.012668469920754433,
                -0.026796793565154076,
                1.957879703695653e-06,
                0.043669357895851135,
                0.00543750636279583,
                0.0041392710991203785,
                0.05274191126227379,
                -0.004178523551672697,
                0.0013327865162864327,
                -0.00627277884632349,
                0.004219653084874153,
                0.00822832528501749,
                -0.003407333279028535,
                -0.022154083475470543,
                0.022971488535404205,
                0.00283645442686975,
                0.03818484768271446,
                -0.016506999731063843,
                -0.00671695452183485,
                -0.0009369994513690472,
                -0.016740864142775536,
                -0.038198232650756836,
                -0.049364931881427765,
                -0.023403361439704895,
                -0.0004867450625170022,
                -0.0012215954484418035,
                0.015026639215648174,
                0.014704005792737007,
                0.024852005764842033,
                0.027742836624383926,
                -0.012848776765167713,
                -0.048039596527814865,
                0.06679809838533401,
                0.03281237185001373,
                0.017765717580914497,
                -0.020873060449957848,
                0.03381853178143501,
                0.01603502221405506,
                0.075288325548172,
                -0.0011805577669292688,
                -0.02121475338935852,
                -0.01008517388254404,
                -0.028122685849666595,
                0.024972643703222275,
                0.014979983679950237,
                -0.03568469360470772,
                -0.03595384210348129,
                -0.00863280426710844,
                -0.013786640018224716,
                -0.010889731347560883,
                -0.04951167851686478,
                -0.09489653259515762,
                0.012224612757563591,
                0.030388224869966507,
                -0.03824258968234062,
                0.020067377015948296,
                0.03118855692446232,
                -0.0067278179340064526,
                0.058258701115846634,
                0.007424183189868927,
                0.017152827233076096,
                0.0009867701446637511,
                -0.014052010141313076,
                -0.006909379735589027,
                0.056419286876916885,
                0.010718142613768578,
                0.01560972910374403,
                -0.0015548475785180926,
                0.02696642279624939,
                0.04518917575478554,
                0.04747133329510689,
                0.03313170373439789,
                -0.07521632313728333,
                -0.015666872262954712,
                0.006197369657456875,
                0.0009922160534188151,
                -0.04030609503388405,
                0.021783197298645973,
                -0.026301249861717224,
                -0.01512683741748333,
                -0.06928965449333191,
                -0.062260497361421585,
                -0.018869901075959206,
                0.060612451285123825,
                -0.03326329216361046,
                0.02067885547876358,
                0.012873048894107342,
                0.004722466226667166,
                0.09856504946947098,
                0.033320352435112,
                0.039842065423727036,
                0.028388043865561485,
                -0.038342393934726715,
                -0.008167521096765995,
                0.011128799058496952,
                0.03335009887814522,
                0.009483308531343937,
                -0.007352923508733511,
                -0.004622443579137325,
                0.029904264956712723,
                -0.021035676822066307,
                -0.03440184146165848,
                0.009857984259724617,
                0.04367611184716225,
                -0.03183697164058685,
                0.0014272232074290514,
                -0.03788825869560242,
                0.001708416035398841,
                0.04053520783782005,
                0.038120564073324203,
                -0.02266848273575306,
                0.04795839637517929,
                -0.02417116053402424,
                -0.05719340965151787,
                -0.02079593390226364,
                0.03284550458192825,
                0.02347251959145069,
                -0.04098353162407875,
                0.026147915050387383,
                -0.02042226307094097,
                -0.002015654928982258,
                0.005602292716503143,
                0.05109836533665657,
                -0.019923344254493713,
                0.0096371378749609,
                0.022038334980607033,
                -0.00753891421481967,
                0.044895585626363754,
                -0.00454571982845664,
                -0.029714485630393028,
                -0.03555199131369591,
                0.043725788593292236,
                -0.057063352316617966,
                0.00827152281999588,
                0.02884713001549244,
                0.030360907316207886,
                -0.0303050447255373,
                0.03037622757256031,
                0.0186929889023304,
                -0.060040298849344254,
                -0.006800443399697542,
                0.056112274527549744,
                0.031149834394454956,
                -0.07602565735578537,
                0.03805562108755112,
                -0.022824496030807495,
                0.04611840844154358,
                0.0633217915892601,
                -0.016478927806019783,
                -0.17643994092941284,
                0.019555188715457916,
                0.002687235362827778,
                0.007213234901428223,
                0.0036185525823384523,
                -0.017398998141288757,
                0.023959556594491005,
                -0.0545867420732975,
                0.02282445877790451,
                0.03641104698181152,
                0.027694949880242348,
                -0.05894440785050392,
                0.03810621798038483,
                0.0281066857278347,
                0.03018912859261036,
                -0.0617583803832531,
                0.012221133336424828,
                -0.034534335136413574,
                0.02250499837100506,
                -0.010981973260641098,
                -0.004458471667021513,
                -0.07008860260248184,
                0.0006389376940205693,
                0.008756711147725582,
                -0.0401868037879467,
                -0.04446370154619217,
                -0.03554803505539894,
                -0.02049189805984497,
                0.02974751964211464,
                0.07356590777635574,
                -0.005000211298465729,
                0.027057599276304245,
                0.0402868427336216,
                -0.03992171958088875,
                -0.029904687777161598,
                0.0027208602987229824,
                -0.02469567582011223,
                0.015072645619511604,
                -0.00010287702752975747,
                -0.029331674799323082,
                0.0016805349150672555,
                -0.019430093467235565,
                0.012148328125476837,
                0.036566391587257385,
                -0.050984304398298264,
                0.03988024964928627,
                0.01684623770415783,
                -0.03557909280061722,
                0.05639767274260521,
                0.0023533706553280354,
                0.015374771319329739,
                0.0363934189081192,
                -0.06640905886888504,
                -0.05063611641526222,
                0.010644743219017982,
                0.005557417869567871,
                0.08468060940504074,
                0.01021068636327982,
                -0.022883903235197067,
                -0.028899917379021645,
                -0.03432357311248779,
                -0.004532554652541876,
                0.0560576356947422,
                -0.025600414723157883,
                -0.01634347252547741,
                -0.0006509521044790745,
                0.018520435318350792,
                0.03594140335917473,
                -0.0005575203686021268,
                0.002754349960014224,
                -0.02215411886572838,
                0.033910077065229416,
                0.045235421508550644,
                0.002941388636827469,
                0.010575637221336365,
                -0.034494202584028244,
                0.04431941360235214,
                -0.11533739417791367,
                -0.016036976128816605,
                -0.0028435850981622934,
                -0.050321195274591446,
                -0.03535859286785126,
                -0.04989161714911461,
                0.026778899133205414,
                -0.0014977984828874469,
                0.04504074901342392,
                -0.02098730020225048,
                -0.029281562194228172,
                0.024019256234169006,
                0.07298770546913147,
                0.060665734112262726,
                -0.0026429034769535065,
                -0.027475865557789803,
                -0.014115594327449799,
                -0.017857979983091354,
                0.011607437394559383,
                0.017001021653413773,
                -0.043635282665491104,
                0.008362085558474064,
                0.02621736191213131,
                -0.07014167308807373,
                -0.015486876480281353,
                -0.0064918571151793,
                -0.02212114818394184,
                0.027439309284090996,
                0.050670769065618515,
                -0.04824122413992882,
                -0.07959846407175064,
                0.057244934141635895,
                0.010707785375416279,
                -0.011063777841627598,
                0.034957580268383026,
                -0.07901348918676376,
                -0.05011759698390961,
                0.028924118727445602,
                -0.004572898615151644,
                0.026286128908395767,
                -0.017454493790864944,
                0.06778131425380707,
                -0.017232060432434082,
                0.008511155843734741,
                0.00178324815351516,
                0.0036335706245154142,
                -0.012283801101148129,
                0.06272430717945099,
                0.014127404429018497,
                0.03126569837331772,
                -0.023384733125567436,
                -0.0746716782450676,
                0.017843252047896385,
                0.03960306942462921,
                0.04539811983704567,
                0.02469571679830551,
                0.0820884108543396,
                -0.0414455346763134,
                -0.0020386758260428905,
                0.014931462705135345,
                0.03228272870182991,
                0.05729801952838898,
                0.01703733019530773,
                0.028922898694872856,
                -0.028710074722766876,
                -0.0293736532330513,
                0.012541437521576881,
                0.03215759992599487,
                -0.041023701429367065,
                -0.01710871420800686,
                0.009488276205956936,
                -0.06077629327774048,
                -0.00297536444850266,
                0.006920501124113798,
                -0.03498486429452896,
                -0.012423017993569374,
                0.05034644529223442,
                -0.04035565257072449,
                0.023893246427178383,
                -0.11010558158159256,
                -0.01981973834335804,
                -0.014133484102785587,
                -0.024031132459640503,
                -0.035492222756147385,
                -0.08355576545000076,
                0.018482953310012817,
                0.009200042113661766,
                0.020313110202550888,
                0.0517287403345108,
                -0.007124876603484154,
                -0.033876899629831314,
                0.04133971780538559,
                0.056377675384283066,
                0.05934833735227585,
                -0.023415546864271164,
                0.032875753939151764,
                -0.00631743622943759,
                0.05462197586894035,
                0.03866492211818695,
                0.048108432441949844,
                5.893904744880274e-05,
                0.04044017568230629,
                -0.03529565781354904,
                0.004165234509855509,
                -0.025091303512454033,
                0.018203770741820335,
                -0.07877703756093979,
                0.008977150544524193,
                0.05654721334576607,
                -0.03430422022938728,
                -0.03266547620296478,
                -0.034720249474048615,
                -0.03376602381467819,
                0.005969631019979715,
                -0.03053315170109272,
                -0.04398561641573906,
                -0.0022603715769946575,
                0.04527481645345688,
                0.01128485519438982,
                0.04978913441300392,
                -0.002800211077556014,
                -0.006423070561140776,
                0.04499228298664093,
                -0.03620393946766853,
                0.045685432851314545,
                -0.024291109293699265,
                0.002303594956174493,
                -0.04559843987226486,
                0.01751771569252014,
                -0.0007933310116641223,
                -0.012298795394599438,
                0.02680877596139908,
                -0.018553130328655243,
                -0.010598785243928432,
                -0.04688888415694237,
                -0.033613093197345734,
                -0.009240604005753994,
                -0.009117862209677696,
                -0.13244479894638062,
                -0.02518516033887863,
                -0.011165373958647251,
                0.014569285325706005,
                0.03751067817211151,
                0.03593452274799347,
                -0.0872185006737709,
                0.0029443632811307907,
                0.023748667910695076,
                -0.034740421921014786,
                0.04353680461645126,
                0.008608237840235233,
                0.02498597465455532,
                -0.021757137030363083,
                0.011851959861814976,
                0.00996758695691824,
                0.00843103788793087,
                -0.0009304904378950596,
                -0.051352787762880325,
                -0.04775400459766388,
                0.008368671871721745,
                0.009243552573025227,
                0.004515060223639011,
                -0.09299946576356888,
                0.046320509165525436,
                0.014957420527935028,
                0.07160384953022003,
                -0.05548270791769028,
                0.0934123694896698,
                -0.056214068084955215,
                0.019283300265669823,
                0.0034477997105568647,
                0.007596787065267563,
                -0.06034864857792854,
                -0.06398352235555649,
                0.00029372729477472603,
                0.01272664126008749,
                0.004233022220432758,
                -0.003084540134295821,
                -0.018737204372882843,
                -0.027202054858207703,
                -0.02096446231007576,
                0.03742105886340141,
                -0.0032217586413025856,
                0.03948173299431801,
                0.019648335874080658,
                0.015911899507045746,
                0.015299230813980103,
                -0.02730771154165268,
                -0.002863488858565688,
                -0.0015618392499163747,
                0.01382453739643097,
                -0.052316706627607346,
                -0.02426614798605442,
                0.10716130584478378,
                -0.013745819218456745,
                -0.012219754979014397,
                -0.02961086668074131,
                -0.039330046623945236,
                -0.032759275287389755,
                -0.06634097546339035,
                0.03383839502930641,
                0.0002169806248275563,
                0.015503830276429653,
                0.0058150459080934525,
                0.035806573927402496,
                -0.03220927715301514,
                -0.00031545644742436707,
                -0.010721685364842415,
                -0.020430373027920723,
                0.013310594484210014,
                -0.032526303082704544,
                -0.017794568091630936,
                -0.07240477204322815,
                0.015818744897842407,
                -0.016524270176887512,
                -0.03145851567387581,
                -0.00537928706035018,
                -0.0019974454771727324,
                0.011783991008996964,
                -0.021750502288341522,
                0.09765006601810455,
                -0.08760334551334381,
                0.007868044078350067,
                0.04824216291308403,
                -0.07004950195550919,
                0.03973003104329109,
                -0.02052360400557518,
                -0.0151488883420825,
                0.06766123324632645,
                0.03988412395119667,
                0.014962907880544662,
                -0.0019772499799728394,
                0.016527732834219933,
                0.009551548399031162,
                -0.09172358363866806,
                0.014195568859577179,
                -0.01892496645450592,
                0.06779100000858307,
                0.05773268640041351,
                0.005181219894438982,
                0.07965657114982605,
                -0.027799170464277267,
                -0.007497049402445555,
                -0.06694267690181732,
                0.014677085913717747,
                0.03373586758971214,
                -0.021078530699014664,
                0.006391953211277723,
                0.003925967495888472,
                0.003995971288532019,
                -0.01115185022354126,
                -0.02932230941951275,
                0.01463480107486248,
                -0.06990378350019455,
                0.03523852676153183,
                -0.051076531410217285,
                -0.05086371675133705,
                -0.004800904076546431,
                -0.012627171352505684,
                -0.005965473596006632,
                -0.014375288039445877,
                0.013650786131620407,
                -0.012973581440746784,
                0.01822197437286377,
                -0.013512862846255302,
                -0.004820286296308041,
                0.03902074694633484,
                -0.03804049640893936,
                -0.041361983865499496,
                0.02590830996632576,
                0.054942674934864044,
                -0.02681366726756096,
                0.005719788372516632,
                0.017434634268283844,
                -0.022607004269957542,
                -0.04309573397040367,
                -0.005752346012741327,
                -0.013063550926744938,
                0.009018762968480587,
                0.07633420079946518,
                0.03159881383180618,
                -0.010744324885308743,
                0.01639002189040184,
                -0.0330374613404274,
                0.016803469508886337,
                0.010565131902694702,
                0.021345682442188263,
                -0.002971637761220336,
                0.023541921749711037,
                0.002312054391950369,
                0.03016195446252823,
                0.05051489919424057,
                -0.05400359258055687,
                0.01684577390551567,
                -0.04407833516597748,
                -0.006228704005479813,
                0.059401750564575195,
                -0.03740589693188667,
                -5.52060746490713e-33,
                0.01315436139702797,
                -0.01842767931520939,
                0.0026502334512770176,
                -0.0012739545200020075,
                -0.014420907013118267,
                -0.005563382990658283,
                0.05352851375937462,
                -0.025815479457378387,
                -0.025533515959978104,
                -0.04542037472128868,
                -0.01881490834057331,
                0.0017722513293847442,
                0.010431147180497646,
                0.00964821595698595,
                0.0024942310992628336,
                0.07343247532844543,
                0.05572495982050896,
                -0.014891895465552807,
                -0.004092790652066469,
                -0.0203725416213274,
                -0.07332028448581696,
                0.0012229245621711016,
                0.03701396286487579,
                -0.005962783005088568,
                0.029537495225667953,
                -0.004646179266273975,
                -0.03631456196308136,
                0.05910009518265724,
                0.024424191564321518,
                -0.009990387596189976,
                -0.005181150045245886,
                0.004496855195611715,
                -0.0068196202628314495,
                0.038923680782318115,
                -0.008374358527362347,
                0.00021061350707896054,
                -0.0754929929971695,
                -0.001779847894795239,
                0.009053285233676434,
                0.04494573548436165,
                -0.006365136243402958,
                0.0056199440732598305,
                0.04655507951974869,
                -0.005907150916755199,
                -0.01707383058965206,
                -0.05677611008286476,
                0.0017721561016514897,
                0.004441632889211178,
                0.06258917599916458,
                0.005160538479685783,
                -0.04029770940542221,
                -0.019298598170280457,
                -0.031556855887174606,
                0.030202841386198997,
                -0.04305725917220116,
                0.026823626831173897,
                -0.0019306319300085306,
                0.008051997981965542,
                -0.02914457768201828,
                0.005941254086792469,
                -0.017429403960704803,
                0.0018909157952293754,
                0.06839106976985931,
                -0.0568530447781086,
                0.007476045750081539,
                0.02085474692285061,
                0.031470295041799545,
                0.06832950562238693,
                0.007903721183538437,
                -0.06464122235774994,
                -0.05228085070848465,
                0.05696668103337288,
                -0.054871004074811935,
                -0.006905768532305956,
                -0.023293735459446907,
                -0.026053205132484436,
                -0.049598678946495056,
                0.0016831554239615798,
                -0.004799226298928261,
                0.04477892816066742,
                0.02601994015276432,
                -0.03420926630496979,
                0.016530688852071762,
                -0.011094539426267147,
                -0.017505254596471786,
                -0.02061031199991703,
                -0.02400401048362255,
                0.016338299959897995,
                0.012635462917387486,
                0.034139011055231094,
                0.03580796718597412,
                -0.00916113331913948,
                -0.050713155418634415,
                0.020762385800480843,
                0.13399390876293182,
                0.003723573638126254,
                0.010842129588127136,
                -0.048498716205358505,
                -0.0002287585666636005,
                0.01734701730310917,
                -0.009310644119977951,
                0.05861865356564522,
                -0.053933773189783096,
                -0.011816593818366528,
                0.030530311167240143,
                0.015951022505760193,
                -0.05948788672685623,
                -0.004415055736899376,
                -0.031060561537742615,
                0.005043771117925644,
                -0.010600599460303783,
                -0.02432898059487343,
                -0.009800746105611324,
                -0.0037976170424371958,
                -0.012614843435585499,
                -0.00581271480768919,
                0.014163951389491558,
                -0.06746469438076019,
                -0.02580215223133564,
                0.006206408143043518,
                0.049004413187503815,
                -0.031522296369075775,
                -0.017258435487747192,
                0.017533810809254646,
                0.016695106402039528,
                -0.010663425549864769,
                0.033503610640764236,
                -0.04795828461647034,
                -0.03428677096962929,
                -0.0484483502805233,
                -7.565734267700464e-05,
                0.008880341425538063,
                2.5786931701077265e-07,
                -0.01335296779870987,
                0.013198905624449253,
                -0.0034419349394738674,
                0.06211471930146217,
                0.018955491483211517,
                -0.04781637340784073,
                -0.025418072938919067,
                0.0038781152106821537,
                -0.0026107956655323505,
                0.027026256546378136,
                -0.00290902191773057,
                -0.014602385461330414,
                -0.02940370887517929,
                -0.08270808309316635,
                -0.021759577095508575,
                -0.045307986438274384,
                0.06711260974407196,
                0.017093533650040627,
                -0.014613570645451546,
                -0.0016034434083849192,
                0.10374750196933746,
                0.01324463076889515,
                0.0261895339936018,
                0.008446795865893364,
                -0.02953038178384304,
                0.027672212570905685,
                -0.0021920024883002043,
                -0.023675162345170975,
                0.0328957624733448,
                0.03596648573875427,
                0.037366561591625214,
                -0.025949671864509583,
                0.03526398912072182,
                0.069908007979393,
                -0.0066565703600645065,
                -0.007986710406839848,
                0.05232296139001846,
                0.04915424808859825,
                0.024761147797107697,
                0.03650151565670967,
                0.028688399121165276,
                -0.0052449945360422134,
                0.03497404605150223,
                0.008863338269293308,
                -0.02883750945329666,
                -0.024899065494537354,
                0.018603907898068428,
                0.026728542521595955,
                -0.0645037516951561,
                -0.017776986584067345,
                -0.020930085331201553,
                0.055436111986637115,
                -0.005913782399147749,
                -0.048011090606451035,
                0.03353695943951607,
                -0.04541061446070671,
                -0.04399740323424339,
                0.035077255219221115,
                0.01685185171663761,
                0.010493689216673374,
                -0.04492222145199776,
                -0.005136354826390743,
                0.008915076963603497,
                -0.00036278433981351554,
                0.10349009931087494,
                0.011017763055860996,
                -0.0192343108355999,
                2.877663664989472e-34,
                -0.04541231319308281,
                0.012465564534068108,
                -0.007023336365818977,
                0.047637175768613815,
                0.036566197872161865,
                -0.03981789946556091,
                0.020344890654087067,
                -0.012123442254960537,
                0.017624037340283394,
                -0.05886653810739517,
                -0.02752239815890789
            ]
        },
        "Freelance": "Open to both",
        "Id": "26d24a63-e347-4aea-9666-70fee72b8430",
        "Industry": {
            "name": "\tDrilling / Rig",
            "rep": [
                -0.0011542883003130555,
                -0.13064201176166534,
                0.0027850023470818996,
                -0.03734075278043747,
                -0.012292912229895592,
                0.0361180379986763,
                -0.011133753694593906,
                0.012193123809993267,
                -0.03792116418480873,
                0.0041445340029895306,
                0.06432663649320602,
                0.0031063940841704607,
                0.037355855107307434,
                -0.01156067568808794,
                0.027203483507037163,
                0.006367753725498915,
                -0.04835761711001396,
                0.01653728261590004,
                -0.047293227165937424,
                0.027455823495984077,
                -0.030993469059467316,
                0.013423352502286434,
                -0.017363280057907104,
                0.0014814498135820031,
                -0.057087067514657974,
                0.013909406028687954,
                -0.03589138388633728,
                0.0027091549709439278,
                0.018432769924402237,
                0.01630929857492447,
                0.08288894593715668,
                0.003404004964977503,
                0.07949017733335495,
                0.026346618309617043,
                1.8317697367820074e-06,
                0.003945176489651203,
                -0.01249743066728115,
                0.013766670599579811,
                -0.023928267881274223,
                0.045534923672676086,
                -0.09494048357009888,
                -0.08838846534490585,
                0.022045297548174858,
                0.02838507853448391,
                -0.030738946050405502,
                -0.0779140293598175,
                0.04181251302361488,
                -0.08504419028759003,
                -0.02699524722993374,
                -0.012645993381738663,
                -0.023250311613082886,
                0.0049605113454163074,
                -0.0701802596449852,
                -0.031107505783438683,
                -0.021707339212298393,
                -0.02136765979230404,
                -0.014452061615884304,
                0.10206039994955063,
                0.0654570609331131,
                0.08984678238630295,
                0.0008116281242109835,
                -0.04364768788218498,
                0.030820753425359726,
                -0.0011388073908165097,
                0.0399969145655632,
                0.022702496498823166,
                0.06169847026467323,
                -0.03277784213423729,
                0.01469541434198618,
                -0.023083556443452835,
                -0.03272746503353119,
                -0.025846628472208977,
                0.013348620384931564,
                0.030699994415044785,
                -0.04563509672880173,
                -0.0895700678229332,
                -0.005512854550033808,
                0.04276910796761513,
                -0.03731536492705345,
                0.0062137022614479065,
                -0.014517937786877155,
                -0.03878016397356987,
                -0.002780458191409707,
                -0.004240379203110933,
                -0.0012976942816749215,
                -0.0033621680922806263,
                -0.0170009583234787,
                0.008111606352031231,
                -0.05183962360024452,
                0.0711304172873497,
                0.06696594506502151,
                0.00013807674986310303,
                -0.016933703795075417,
                -0.020183051005005836,
                -0.039463214576244354,
                0.012433447875082493,
                0.012768382206559181,
                0.06082974374294281,
                0.010080325417220592,
                0.048903197050094604,
                -0.03232439607381821,
                0.06856488436460495,
                0.04218665510416031,
                0.019165819510817528,
                -0.0514744333922863,
                -0.05496341735124588,
                0.02187289670109749,
                -0.0017082850681617856,
                -0.025985298678278923,
                0.025976311415433884,
                -0.04084976017475128,
                -0.014148060232400894,
                -0.02274945005774498,
                -0.02381104975938797,
                -0.01756589114665985,
                -0.030020015314221382,
                0.013108975253999233,
                0.03364221751689911,
                0.028917310759425163,
                -0.0012142283376306295,
                -0.015357797965407372,
                0.028670091181993484,
                -0.028522640466690063,
                -0.01301591657102108,
                0.029902126640081406,
                -0.05872628465294838,
                -0.024816490709781647,
                0.08890940248966217,
                0.05266233906149864,
                0.0020047370344400406,
                -0.03954199701547623,
                0.031212471425533295,
                0.044044021517038345,
                -0.026501664891839027,
                -0.025999147444963455,
                -0.04203883558511734,
                -0.0153788011521101,
                -0.002543048234656453,
                -0.06544797122478485,
                -0.017519846558570862,
                0.06291601061820984,
                0.004896026570349932,
                -0.013144567608833313,
                0.01702628843486309,
                0.01347193494439125,
                0.012611334212124348,
                -0.013374704867601395,
                0.09677686542272568,
                0.011791614815592766,
                -0.0155097134411335,
                0.04828966036438942,
                -0.009192823432385921,
                0.02834821678698063,
                -0.02474520541727543,
                0.04114532843232155,
                0.04619888588786125,
                -0.003811602247878909,
                0.017078947275877,
                0.003564747516065836,
                0.043999336659908295,
                -0.018822314217686653,
                -0.007229501381516457,
                -0.09083996713161469,
                0.01263436023145914,
                -0.005463117733597755,
                -0.015485549345612526,
                0.07279225438833237,
                -0.0017416427144780755,
                0.023239532485604286,
                0.010114484466612339,
                -0.023702790960669518,
                -0.011081448756158352,
                -0.012833365239202976,
                0.05512934550642967,
                -0.02850007638335228,
                -0.032657694071531296,
                0.04997533932328224,
                -0.057586852461099625,
                0.05447705462574959,
                0.02849390357732773,
                0.002298980485647917,
                -0.09920547902584076,
                0.01787753775715828,
                -0.034628819674253464,
                -0.039472587406635284,
                -0.034029051661491394,
                0.005027420353144407,
                -0.015209300443530083,
                -0.0641123354434967,
                0.004859547130763531,
                0.01866801828145981,
                -0.0019017055165022612,
                0.00275771995075047,
                -0.008968493901193142,
                -0.01293260045349598,
                0.013766609132289886,
                -0.025367774069309235,
                -0.004933727905154228,
                -0.04427679628133774,
                0.05012005567550659,
                -0.012101827189326286,
                0.01696626842021942,
                -0.07586276531219482,
                -0.07664687186479568,
                -0.04431799054145813,
                -0.037302106618881226,
                -0.039546359330415726,
                -0.006706149782985449,
                -0.011943030171096325,
                0.030670838430523872,
                -0.02779361605644226,
                0.0324389711022377,
                -0.006625560577958822,
                0.01428361888974905,
                -0.03871186822652817,
                0.01499792281538248,
                -0.012184010818600655,
                0.003619582625105977,
                0.01953921839594841,
                0.028024405241012573,
                -0.04220597445964813,
                0.011653419584035873,
                0.029706617817282677,
                0.014591298066079617,
                -0.0006165654049254954,
                -0.05637297034263611,
                0.020170878618955612,
                0.008646396920084953,
                -0.042790744453668594,
                -0.03761819750070572,
                0.02311702072620392,
                0.014129619114100933,
                0.06283050775527954,
                -0.026835164055228233,
                0.000311159499688074,
                0.035674262791872025,
                0.042653895914554596,
                0.039009030908346176,
                0.0029219703283160925,
                -0.04154534265398979,
                0.04014342278242111,
                -0.084076888859272,
                0.004796937108039856,
                -0.03454049676656723,
                0.041857555508613586,
                -0.0564926378428936,
                0.011279627680778503,
                0.028311457484960556,
                0.017195113003253937,
                -0.014999501407146454,
                -0.01561102643609047,
                -0.06687634438276291,
                0.016362736001610756,
                0.010130632668733597,
                0.033843714743852615,
                -0.025912264361977577,
                -0.00900615006685257,
                0.03771989047527313,
                -0.04303991049528122,
                -0.029832227155566216,
                0.06450585275888443,
                -0.05793147161602974,
                -0.004233135376125574,
                0.002902384614571929,
                0.018075862899422646,
                -0.012781800702214241,
                -0.01044884230941534,
                -0.018886622041463852,
                0.007218455895781517,
                -0.009212280623614788,
                0.041684217751026154,
                0.041749101132154465,
                0.008946320042014122,
                -0.03501708060503006,
                -0.017113858833909035,
                0.029642736539244652,
                -0.040377866476774216,
                -0.007064207922667265,
                -0.016615405678749084,
                -0.009315398521721363,
                0.033581677824258804,
                0.06109137460589409,
                -0.027542119845747948,
                -0.048707012087106705,
                0.016958076506853104,
                -0.0005377863999456167,
                -0.009141226299107075,
                -0.06018105894327164,
                -0.11465063691139221,
                -0.03301939740777016,
                0.04244609549641609,
                -0.07585948705673218,
                0.02958536334335804,
                -0.05421503260731697,
                0.006037084851413965,
                0.03548227250576019,
                0.030683036893606186,
                0.02498796209692955,
                -0.03806888312101364,
                0.0227164626121521,
                -0.023519432172179222,
                0.03980541229248047,
                0.004883925896137953,
                -0.010858774185180664,
                -0.00022955071472097188,
                -0.00015970555250532925,
                0.046843983232975006,
                -0.005368396639823914,
                0.00729405926540494,
                -0.03319210931658745,
                0.044812753796577454,
                0.02030157297849655,
                0.00799834169447422,
                -0.005771063268184662,
                -0.00330468756146729,
                -0.03497922047972679,
                0.04690477252006531,
                -0.0026182832662016153,
                -0.015141460113227367,
                0.03613557294011116,
                0.022937096655368805,
                -0.0008463059784844518,
                0.01174852903932333,
                0.02940484881401062,
                0.008417285978794098,
                0.033274147659540176,
                -0.0001855844457168132,
                -0.01348707266151905,
                0.005793159827589989,
                0.05752112716436386,
                -0.02577756904065609,
                -0.003338601440191269,
                -0.041503943502902985,
                0.03946935757994652,
                -0.015075592324137688,
                0.0384332500398159,
                0.005960191134363413,
                -0.004719748627394438,
                -0.015616309829056263,
                0.03989984840154648,
                0.011892112903296947,
                -0.02917403355240822,
                -0.051292404532432556,
                0.04889998584985733,
                -0.017759200185537338,
                0.04925864189863205,
                0.04599882662296295,
                0.00017569218471180648,
                -0.003174553858116269,
                0.0026288586668670177,
                0.007707567885518074,
                0.012392598204314709,
                -0.0065076411701738834,
                0.023699549958109856,
                -0.007701571565121412,
                0.05442332476377487,
                -0.015861624851822853,
                0.027367541566491127,
                -0.008540879935026169,
                0.036547914147377014,
                0.003020805772393942,
                0.0004260592395439744,
                -0.055074937641620636,
                0.0240876916795969,
                -0.05396386608481407,
                0.005700266920030117,
                0.02262120135128498,
                0.022071145474910736,
                -0.04160843417048454,
                -0.012619337998330593,
                -0.0692523717880249,
                0.006223510485142469,
                -0.04066434130072594,
                -0.04678306728601456,
                0.025042710825800896,
                0.08329875022172928,
                -0.00047597422963008285,
                0.042932383716106415,
                0.028462857007980347,
                -0.026659466326236725,
                -0.019999360665678978,
                -0.07680056244134903,
                0.008517535403370857,
                -0.012034525163471699,
                0.007547428831458092,
                0.029353484511375427,
                -0.008167142048478127,
                0.0035245774779468775,
                -0.03242748603224754,
                0.027316873893141747,
                -0.0201104786247015,
                0.018423346802592278,
                0.03597758710384369,
                -0.014647530391812325,
                0.017094627022743225,
                -0.03873337432742119,
                -0.09219679981470108,
                0.007297465577721596,
                0.0045235296711325645,
                0.046536885201931,
                0.04938509687781334,
                0.036970268934965134,
                -0.04712462052702904,
                -0.04223432391881943,
                -0.03709156811237335,
                -0.02313588745892048,
                -0.0007297047413885593,
                0.008938551880419254,
                0.020034700632095337,
                -0.01647973246872425,
                -0.026363449171185493,
                -0.018743600696325302,
                -0.002133170375600457,
                -0.02007737010717392,
                0.017447365447878838,
                0.0045015388168394566,
                0.003293538698926568,
                0.030303306877613068,
                -0.01991848833858967,
                -0.059499211609363556,
                -0.001068815472535789,
                -0.04068956524133682,
                0.04741041734814644,
                -0.04948745295405388,
                0.07806196063756943,
                -0.0002274907601531595,
                0.006590896751731634,
                0.022970158606767654,
                9.716691420180723e-05,
                -0.02433367446064949,
                -0.025638259947299957,
                -0.030666423961520195,
                -0.013613655231893063,
                -0.022581202909350395,
                0.03130652382969856,
                0.006253009662032127,
                -0.015144305303692818,
                0.005259258206933737,
                0.007980464957654476,
                -0.015692006796598434,
                0.006640658713877201,
                0.08716411888599396,
                0.05666758492588997,
                0.03188111260533333,
                0.04102307930588722,
                0.006408174987882376,
                0.016893824562430382,
                -0.06618479639291763,
                -0.058143842965364456,
                0.019851528108119965,
                0.03687480092048645,
                0.018034374341368675,
                0.01878160797059536,
                0.001972929807379842,
                0.006356026511639357,
                0.014542074874043465,
                -0.06245816871523857,
                -0.037778519093990326,
                -0.045240845531225204,
                0.003649639431387186,
                0.034174565225839615,
                0.03630761429667473,
                -0.02930849976837635,
                -0.04006858170032501,
                0.036683522164821625,
                -0.1582934409379959,
                0.006500180810689926,
                -0.03446979448199272,
                -0.03014097362756729,
                -0.04726076498627663,
                0.007194129750132561,
                0.0026404454838484526,
                -0.0039227912202477455,
                0.034476716071367264,
                -0.03438001126050949,
                0.024941468611359596,
                -0.02629218064248562,
                0.007992402650415897,
                -0.045797593891620636,
                -0.00552218034863472,
                0.015683777630329132,
                -0.019295435398817062,
                -0.003846833948045969,
                -0.04010558873414993,
                -0.007364603225141764,
                0.018654605373740196,
                0.03612379729747772,
                -0.01386414933949709,
                0.007234981283545494,
                0.030002456158399582,
                -0.03350408747792244,
                -0.030694449320435524,
                -0.014898519963026047,
                0.07228656113147736,
                0.02895592339336872,
                0.09107547998428345,
                0.022928545251488686,
                0.007846636697649956,
                0.02031893841922283,
                -0.00942352693527937,
                -0.052945591509342194,
                0.04927336797118187,
                0.0043860264122486115,
                -0.02504561096429825,
                -0.0153059596195817,
                0.0504303053021431,
                0.015743035823106766,
                0.0017201791051775217,
                -0.003958897665143013,
                0.004413176327943802,
                -0.0026135884691029787,
                0.0158549714833498,
                0.030374057590961456,
                -0.060453761368989944,
                0.07369178533554077,
                -0.0018929417710751295,
                -0.03378308564424515,
                -0.10836182534694672,
                -0.0022520471829921007,
                0.04278811812400818,
                -0.005068757105618715,
                -0.02252672240138054,
                0.02444184198975563,
                -0.013480360619723797,
                0.01692720130085945,
                -0.026799071580171585,
                0.03092873841524124,
                0.04951534792780876,
                -0.029667027294635773,
                -0.00885244831442833,
                -0.0480416864156723,
                -0.0038841268979012966,
                0.020518293604254723,
                0.0018540724413469434,
                -0.03924812003970146,
                0.09004512429237366,
                0.08723551034927368,
                0.01977284625172615,
                -0.004588195122778416,
                0.028626104816794395,
                0.0023997779935598373,
                0.007085807621479034,
                -0.05786234512925148,
                -0.018046952784061432,
                -0.017508871853351593,
                0.0538199320435524,
                0.05156037583947182,
                0.0011786491377279162,
                0.0461457222700119,
                -0.0071049220860004425,
                -0.002462472766637802,
                -0.043247103691101074,
                -0.035363078117370605,
                -0.008777279406785965,
                -0.03759586066007614,
                -6.535533328168924e-33,
                -0.002304902533069253,
                -0.01100220438092947,
                0.011309472844004631,
                0.0089945113286376,
                -0.025384007021784782,
                0.02127968892455101,
                0.03733031079173088,
                0.03642989695072174,
                -0.022964520379900932,
                -0.02835593745112419,
                -0.013245616108179092,
                0.026027224957942963,
                -0.00281035085208714,
                0.037342850118875504,
                -0.010591757483780384,
                0.02864065393805504,
                0.03006584383547306,
                0.005886318162083626,
                0.010777449235320091,
                -0.05174661800265312,
                -0.01732693612575531,
                0.02915269508957863,
                0.05598955973982811,
                0.03836892172694206,
                0.043806154280900955,
                0.04852644354104996,
                -0.030020132660865784,
                -0.01454177312552929,
                0.016028985381126404,
                0.0045091514475643635,
                -0.026676496490836143,
                -0.015495059080421925,
                0.01391592063009739,
                0.04386303946375847,
                0.009915010072290897,
                0.04672728478908539,
                -0.0689665749669075,
                -0.0193206574767828,
                -0.009137126617133617,
                0.02828214317560196,
                -0.04384039714932442,
                -0.003669839585199952,
                0.008117136545479298,
                -0.02732367068529129,
                -0.0060100252740085125,
                -0.05632299184799194,
                -0.021713552996516228,
                -0.0004061664512846619,
                0.01915280520915985,
                0.07738067954778671,
                -0.041775550693273544,
                -0.008489290252327919,
                0.0031071084085851908,
                -0.08371105790138245,
                0.014369864948093891,
                0.014724352397024632,
                -0.012711605988442898,
                0.006984357256442308,
                -0.08652681857347488,
                0.012906772084534168,
                -0.03518187627196312,
                0.007321689743548632,
                0.0024665496312081814,
                -0.03784200921654701,
                -0.00919608399271965,
                0.03546156361699104,
                -0.05590591952204704,
                -0.0140003627166152,
                -0.010124620981514454,
                -0.0341176837682724,
                -0.04493289813399315,
                0.0607791393995285,
                -0.008939727209508419,
                -0.03763098642230034,
                0.029868561774492264,
                -0.009431351907551289,
                -0.050903454422950745,
                0.01692425273358822,
                -9.861133003141731e-05,
                0.04796125367283821,
                0.04078463092446327,
                0.017638832330703735,
                0.00279027852229774,
                -0.005366215482354164,
                -0.04538968577980995,
                -0.019347360357642174,
                0.02545328624546528,
                0.027490856125950813,
                -0.007250096183270216,
                0.03526076674461365,
                -0.005693112034350634,
                0.002450793981552124,
                0.0036937633994966745,
                -0.0018505797488614917,
                0.129542276263237,
                0.04354071244597435,
                -0.003443379420787096,
                -0.05536385625600815,
                0.010371079668402672,
                -0.005045754835009575,
                -0.017225176095962524,
                0.056243572384119034,
                -0.036288172006607056,
                -0.029236534610390663,
                0.010664327070116997,
                -0.01615346223115921,
                -0.07260365784168243,
                0.012310451827943325,
                -0.03161884844303131,
                -0.0050896587781608105,
                -0.03376813977956772,
                0.025296729058027267,
                0.011176585219800472,
                0.05617954581975937,
                0.05688032507896423,
                -0.005330744199454784,
                -0.010547583922743797,
                -0.004903266206383705,
                -0.0005800461512990296,
                -0.03603127971291542,
                0.01094612292945385,
                0.02096966654062271,
                -0.06388206779956818,
                0.007814967073500156,
                0.02425745129585266,
                0.010917842388153076,
                0.05003776401281357,
                -0.05732930079102516,
                0.012786898761987686,
                -0.03082001395523548,
                -0.005151556804776192,
                0.009710036218166351,
                2.378807977265751e-07,
                -0.01038812194019556,
                0.018093308433890343,
                0.032182786613702774,
                -0.006791236810386181,
                0.02207104302942753,
                0.027007177472114563,
                0.03322590887546539,
                0.029860887676477432,
                0.02505539357662201,
                -0.05970781669020653,
                -0.007578378543257713,
                0.005450505297631025,
                0.00017431934247724712,
                -0.029016058892011642,
                -0.032222189009189606,
                -0.003817289602011442,
                0.13903337717056274,
                -0.001617457834072411,
                0.019432639703154564,
                -0.042045995593070984,
                0.041384629905223846,
                0.032258257269859314,
                -0.02156008780002594,
                -0.004301716573536396,
                -0.013198692351579666,
                -0.09324520081281662,
                -0.02157880924642086,
                0.025538023561239243,
                0.051340796053409576,
                -0.004300364293158054,
                0.03644588962197304,
                0.012726444751024246,
                0.017333664000034332,
                0.04326342046260834,
                -0.002869891934096813,
                -0.034741681069135666,
                0.05044811591506004,
                0.023275546729564667,
                0.005704279989004135,
                0.0856572762131691,
                -0.05554584041237831,
                -0.01785985194146633,
                0.008038307540118694,
                -0.023197466507554054,
                -0.035411983728408813,
                -0.04138566553592682,
                -0.01282185222953558,
                -0.029463285580277443,
                0.033072542399168015,
                0.025246232748031616,
                0.0031228887382894754,
                0.04825481399893761,
                0.04082164540886879,
                -0.0363457016646862,
                0.02672211267054081,
                -0.00014530724729411304,
                0.00840996578335762,
                0.04873121529817581,
                6.350378680508584e-05,
                0.09540227800607681,
                -0.02327529340982437,
                0.011163344606757164,
                -0.003739466890692711,
                0.0031329418998211622,
                0.0017995142843574286,
                -0.03087480366230011,
                -0.071982242166996,
                1.4954005466237652e-34,
                -0.023793231695890427,
                -0.014776053838431835,
                0.014120768755674362,
                0.0691203698515892,
                -0.001274053007364273,
                0.030198918655514717,
                -0.013494297862052917,
                -0.018419157713651657,
                0.03523783013224602,
                -0.03530648350715637,
                0.00040757370879873633
            ]
        },
        "Location": "Aberdeen",
        "Remuneration": 70,
        "Services Required": {
            "name": "Mechanical design engineer",
            "rep": [
                -0.019102731719613075,
                -0.025611117482185364,
                -0.06807278096675873,
                0.00821107067167759,
                0.018527964130043983,
                0.01987912505865097,
                0.05026311054825783,
                0.028363686054944992,
                -0.04949510097503662,
                -0.005584163591265678,
                0.05574771389365196,
                0.0044981022365391254,
                -0.005978272296488285,
                0.02681652456521988,
                0.03457152470946312,
                -0.018603721633553505,
                -0.04239646717905998,
                0.019230766221880913,
                -0.050883058458566666,
                -0.0015296993078663945,
                -0.030622044578194618,
                -0.0064548105001449585,
                -0.03460012748837471,
                0.03336307406425476,
                -0.012321517802774906,
                0.02361530251801014,
                -0.00034436487476341426,
                -0.009077288210391998,
                0.04431352764368057,
                0.05693748593330383,
                0.030178435146808624,
                0.03824085369706154,
                -0.01900457963347435,
                0.030511753633618355,
                1.4604100897486205e-06,
                0.05131680145859718,
                0.07640158385038376,
                0.015030810609459877,
                0.020734617486596107,
                -0.02848656475543976,
                0.00825201440602541,
                -0.0509999580681324,
                -0.04652160778641701,
                0.015164373442530632,
                0.02475293166935444,
                -0.00682881660759449,
                0.017096851021051407,
                0.01839788258075714,
                -0.03390933573246002,
                -0.03916531428694725,
                0.004096092190593481,
                -0.023677213117480278,
                -0.02962040901184082,
                -0.030829792842268944,
                0.013412279076874256,
                0.009156144224107265,
                0.01787753216922283,
                0.031046848744153976,
                0.06291203945875168,
                0.05076368898153305,
                0.0024373396299779415,
                0.012444489635527134,
                0.0011595138348639011,
                -0.01458002533763647,
                0.07160349935293198,
                0.038436491042375565,
                0.07324983179569244,
                0.01062546856701374,
                0.015290516428649426,
                0.03044755756855011,
                0.07222141325473785,
                0.0017605292377993464,
                0.00049456802662462,
                0.034814123064279556,
                0.009742372669279575,
                0.03422550484538078,
                0.01582278311252594,
                -0.005367183592170477,
                -0.017640728503465652,
                -0.03737230971455574,
                -0.023068828508257866,
                -0.00953647866845131,
                -0.03106662631034851,
                0.007022467441856861,
                0.009719498455524445,
                -0.03431146219372749,
                -0.021880414336919785,
                0.0033401495311409235,
                0.0030292952433228493,
                -0.0026073434855788946,
                0.06971563398838043,
                -0.025633687153458595,
                0.03106035478413105,
                -0.020940057933330536,
                0.0023089859168976545,
                -0.005314575042575598,
                0.03569792956113815,
                -0.05913596227765083,
                -0.01921510510146618,
                -0.025965524837374687,
                -0.07787314057350159,
                0.05069149658083916,
                0.06767289340496063,
                0.011997958645224571,
                -0.06328367441892624,
                0.01718888059258461,
                -0.015547974035143852,
                0.039540115743875504,
                -0.016341743990778923,
                0.04573233798146248,
                -0.06782039254903793,
                -0.007450147997587919,
                -0.0048775146715343,
                -0.06353466957807541,
                0.018457375466823578,
                0.05227261781692505,
                -0.0023891334421932697,
                0.043261922895908356,
                0.023027505725622177,
                -0.01350646372884512,
                -0.005323891062289476,
                0.02629721723496914,
                -0.033174678683280945,
                0.01718655787408352,
                -0.027283737435936928,
                -0.0005915002548135817,
                -0.02841617725789547,
                0.03572008013725281,
                0.059570327401161194,
                0.06005999818444252,
                -0.018783725798130035,
                0.019142692908644676,
                -0.019810741767287254,
                -0.03674433380365372,
                -0.023280562832951546,
                0.0759657621383667,
                -0.04996609687805176,
                0.023148328065872192,
                -0.027868330478668213,
                -0.04811744764447212,
                0.0083913030102849,
                0.021728871390223503,
                -0.016964111477136612,
                0.04740592837333679,
                -0.0037309047766029835,
                -0.025976458564400673,
                -0.009943264536559582,
                0.034225091338157654,
                0.029856406152248383,
                -0.030508339405059814,
                -0.014357652515172958,
                0.015266716480255127,
                0.011244078166782856,
                0.013651532121002674,
                0.05530595779418945,
                0.01644357107579708,
                0.017333874478936195,
                -0.012876410037279129,
                -0.04342822730541229,
                0.06321366131305695,
                -0.010761900804936886,
                -0.008395702578127384,
                0.01472377311438322,
                0.0239856094121933,
                -0.047700729221105576,
                -0.007815660908818245,
                0.021343518048524857,
                -0.032446667551994324,
                -0.06885301321744919,
                -0.005314586218446493,
                -0.006163464393466711,
                -0.043129660189151764,
                -0.02437613159418106,
                0.09773930162191391,
                0.007941214367747307,
                -0.060529403388500214,
                0.06906219571828842,
                0.036609895527362823,
                0.010757089592516422,
                0.033424362540245056,
                0.009483620524406433,
                -0.1144726574420929,
                0.054270513355731964,
                -0.008854898624122143,
                -0.04745608940720558,
                -0.020691700279712677,
                -0.026812568306922913,
                -0.02066715992987156,
                -0.07159104198217392,
                -0.011579363606870174,
                0.054293762892484665,
                -0.010035003535449505,
                -0.008511899039149284,
                0.022639203816652298,
                0.047760866582393646,
                -0.011667396873235703,
                0.0025257489178329706,
                0.0019484487129375339,
                -0.01882931962609291,
                0.021738095209002495,
                -0.06816963106393814,
                0.01716652698814869,
                -0.06610847264528275,
                0.013235100544989109,
                -0.0012976799625903368,
                -0.04630971699953079,
                -0.06380871683359146,
                -0.05107857659459114,
                -0.033774953335523605,
                0.030494436621665955,
                0.044873859733343124,
                0.0018256355542689562,
                0.0015477396082133055,
                0.0344589427113533,
                -0.024647150188684464,
                -0.013297989033162594,
                0.0017831487348303199,
                0.007056847680360079,
                -0.05062134563922882,
                0.0029661874286830425,
                -0.001034833607263863,
                -0.02488393522799015,
                -0.00560627318918705,
                -0.036345068365335464,
                -0.039019931107759476,
                -0.03644496202468872,
                0.04180910438299179,
                0.00790585856884718,
                -0.01834219880402088,
                0.035905420780181885,
                -0.003921693190932274,
                0.004379205405712128,
                0.023994309827685356,
                -0.041135307401418686,
                0.008731947280466557,
                0.04540224373340607,
                0.0236459132283926,
                0.07080671191215515,
                -0.007585988845676184,
                -0.1251443326473236,
                -0.035601984709501266,
                -0.0008646545466035604,
                0.019643953070044518,
                -0.013202391564846039,
                0.01779494248330593,
                -0.03154633566737175,
                -0.030652908608317375,
                0.020873885601758957,
                0.03081595152616501,
                0.00727315666154027,
                -0.02429504692554474,
                -0.040129661560058594,
                0.01622690260410309,
                0.0284589696675539,
                0.035654276609420776,
                -0.010337901301681995,
                0.01745382882654667,
                0.04151226580142975,
                0.0032968611922115088,
                -0.0522291325032711,
                0.06455758213996887,
                -0.04177413508296013,
                0.0009355275542475283,
                0.019444042816758156,
                0.05983900651335716,
                0.016315145418047905,
                -0.04131177067756653,
                -0.042842477560043335,
                -0.041512880474328995,
                0.0374596044421196,
                -0.010441480204463005,
                0.0683714896440506,
                0.021618615835905075,
                -0.020213831216096878,
                0.026742564514279366,
                0.02700471132993698,
                0.0009456893894821405,
                0.012257405556738377,
                -0.07869018614292145,
                0.026602981612086296,
                0.05303157493472099,
                -0.010004815645515919,
                0.015854550525546074,
                -0.006999595556408167,
                -0.020815687254071236,
                -0.0150566091760993,
                0.07026414573192596,
                -0.04577762261033058,
                -0.028330475091934204,
                0.058713313192129135,
                -0.027286112308502197,
                -0.04618427902460098,
                0.014877057634294033,
                -0.06685683876276016,
                -0.02303239144384861,
                0.022257033735513687,
                0.018051235005259514,
                -0.022041095420718193,
                0.005402735900133848,
                0.06422992795705795,
                -0.0007691361242905259,
                -0.020615577697753906,
                0.04161738604307175,
                -0.00774777727201581,
                0.01551229041069746,
                0.02702418714761734,
                0.04178980365395546,
                0.0020490887109190226,
                -0.05114651843905449,
                -0.03438831865787506,
                -0.011636546812951565,
                0.04709925502538681,
                0.009338334202766418,
                0.03951886668801308,
                0.06429058313369751,
                -0.0430392362177372,
                0.017885388806462288,
                0.0457892082631588,
                0.0069724456407129765,
                0.06556108593940735,
                -0.003825163934379816,
                0.0655633881688118,
                -0.009101354517042637,
                0.010036691091954708,
                0.02513795904815197,
                0.05709543451666832,
                -0.012639571912586689,
                -0.0057968986220657825,
                0.0018328267615288496,
                -0.0691293254494667,
                -0.0055311559699475765,
                0.07808339595794678,
                -0.05219195783138275,
                0.0010777786374092102,
                0.09604626893997192,
                -0.03593938425183296,
                -0.008427337743341923,
                -0.09296803921461105,
                -0.03739519417285919,
                0.0032650844659656286,
                -0.036477912217378616,
                -0.03570205345749855,
                -0.04192746430635452,
                0.02499469928443432,
                -0.035933587700128555,
                0.01356271281838417,
                -0.005665221717208624,
                -0.0025059080217033625,
                -0.0012555861612781882,
                -0.013828227296471596,
                0.051138199865818024,
                0.011098121292889118,
                -0.016750693321228027,
                0.0150705361738801,
                -0.05080199986696243,
                0.019841397181153297,
                0.013967001810669899,
                0.0800759494304657,
                -0.027082273736596107,
                -0.010851775296032429,
                -0.025178130716085434,
                -0.01283103134483099,
                -0.01928877830505371,
                -0.03557593375444412,
                -0.03292524814605713,
                0.00046199781354516745,
                0.05717022716999054,
                0.03587586060166359,
                0.004553336184471846,
                0.012667425908148289,
                -0.05280344933271408,
                0.01772129535675049,
                -0.035765647888183594,
                -0.017643161118030548,
                -0.009446351788938046,
                0.012175027281045914,
                0.021044230088591576,
                0.054288312792778015,
                0.054049182683229446,
                -0.015565219335258007,
                0.019861703738570213,
                -0.07052595913410187,
                0.008183060213923454,
                -0.00399881461635232,
                0.016983898356556892,
                -0.05422274023294449,
                -0.014823198318481445,
                0.02527523972094059,
                0.014839349314570427,
                0.006297340616583824,
                -0.004625638015568256,
                0.004579522646963596,
                -0.060635313391685486,
                0.012864959426224232,
                -0.00042562204180285335,
                0.013336476869881153,
                -0.028880400583148003,
                0.017887728288769722,
                0.01472441665828228,
                0.04427722468972206,
                0.03631078451871872,
                0.03853680193424225,
                -0.09645401686429977,
                0.025273485109210014,
                0.0008751982823014259,
                -0.03740060701966286,
                0.09386420249938965,
                -0.01417925301939249,
                -0.03835029900074005,
                -0.020135315135121346,
                0.06783882528543472,
                0.006058542989194393,
                0.007676572538912296,
                -0.0229257233440876,
                -0.03760531544685364,
                -0.026747051626443863,
                -0.03526666760444641,
                -0.05457761511206627,
                0.0050861649215221405,
                -0.05817849189043045,
                0.042288754135370255,
                0.011339097283780575,
                0.05690073221921921,
                -0.03500733524560928,
                0.07204380631446838,
                -0.03463944047689438,
                -0.04916893318295479,
                -0.014995979145169258,
                -0.0210551880300045,
                -0.02716003730893135,
                -0.05569940432906151,
                -0.04743528366088867,
                -0.035402100533246994,
                0.028163058683276176,
                0.017098600044846535,
                -0.024162471294403076,
                -0.02652944251894951,
                0.006525212433189154,
                -0.0087985098361969,
                0.03292306885123253,
                0.022398367524147034,
                -0.0071297637186944485,
                0.005706809926778078,
                -0.026687735691666603,
                -0.030233699828386307,
                0.020168134942650795,
                0.02969096414744854,
                -0.05368048697710037,
                -0.052216511219739914,
                -0.006109439302235842,
                0.08922956138849258,
                0.006655698176473379,
                -0.006646537221968174,
                -0.06959115713834763,
                -0.04867644980549812,
                -0.01538052037358284,
                -0.09688296169042587,
                0.046362053602933884,
                0.004811395891010761,
                -0.005702587775886059,
                0.0759308934211731,
                0.03158705309033394,
                -0.016049306839704514,
                -0.021223315969109535,
                -0.03814387321472168,
                -0.04629441350698471,
                0.007546445354819298,
                -0.03846469521522522,
                0.003617089707404375,
                -0.04599476605653763,
                -0.03774471580982208,
                0.018437150865793228,
                -0.06933178752660751,
                0.005827880464494228,
                -0.02941187657415867,
                -0.017153138294816017,
                -0.011600258760154247,
                0.04107682406902313,
                -0.008368657901883125,
                0.0016642415430396795,
                0.06580372899770737,
                -0.030118657276034355,
                0.02736606076359749,
                0.016735056415200233,
                -0.015730883926153183,
                0.05631885677576065,
                0.04519103467464447,
                0.002537443768233061,
                -0.00789772067219019,
                0.02308310568332672,
                0.033372912555933,
                -0.0983704924583435,
                -0.025762367993593216,
                -0.07416293770074844,
                0.03578423336148262,
                0.04959795996546745,
                0.004524775315076113,
                0.08919017761945724,
                -0.09082041680812836,
                0.019232526421546936,
                -0.03619756922125816,
                -0.00969532411545515,
                0.02807052806019783,
                -0.004007674753665924,
                0.03782440721988678,
                0.03806184604763985,
                -0.015603050589561462,
                0.005906755104660988,
                -0.008973891846835613,
                0.01754370704293251,
                0.023542799055576324,
                0.04262134060263634,
                -0.006561631802469492,
                -0.03531400114297867,
                -0.02407905086874962,
                -0.05562485754489899,
                0.007646394427865744,
                0.012649445794522762,
                0.008773406967520714,
                0.0031164486426860094,
                0.01440675463527441,
                -0.03655407950282097,
                0.008639417588710785,
                -0.015093369409441948,
                0.04506658762693405,
                -0.04769500344991684,
                0.006304764188826084,
                0.056336838752031326,
                -0.05797016993165016,
                0.017531191930174828,
                -0.03488323837518692,
                -0.02556338720023632,
                -0.03247537463903427,
                0.006055417470633984,
                -0.03808046132326126,
                0.0635138601064682,
                0.07048998773097992,
                0.016050757840275764,
                0.004199402406811714,
                0.02837020717561245,
                -0.04546351358294487,
                0.011178083717823029,
                0.011555023491382599,
                0.010033654980361462,
                0.007210161071270704,
                -0.02349548228085041,
                -0.00791126862168312,
                0.0010310430079698563,
                0.011240401305258274,
                0.0356256477534771,
                -0.0028910585679113865,
                -0.0358499139547348,
                0.05146171525120735,
                0.0797414556145668,
                0.013673504814505577,
                -5.138461598294497e-33,
                -0.004056841600686312,
                -0.03092263452708721,
                0.015444761142134666,
                -0.014369440264999866,
                -0.06567949056625366,
                -0.022425806149840355,
                0.004305139649659395,
                0.03775235638022423,
                -0.048750437796115875,
                -0.02843545936048031,
                -0.020529046654701233,
                -0.022822795435786247,
                0.005192635580897331,
                0.02970898523926735,
                0.032595887780189514,
                0.02450796589255333,
                0.0369618758559227,
                0.02720763348042965,
                0.013264985755085945,
                -0.013058812357485294,
                -0.06507110595703125,
                0.009268280118703842,
                -0.01355950627475977,
                0.0261493269354105,
                0.009784435853362083,
                -0.02575044520199299,
                -0.018063023686408997,
                0.005960585083812475,
                -0.019498566165566444,
                -0.049654487520456314,
                0.011526721529662609,
                0.010518726892769337,
                -0.00963549129664898,
                0.028267785906791687,
                -0.025882121175527573,
                0.0005344640812836587,
                -0.05542067810893059,
                -0.009203312918543816,
                -0.06109757721424103,
                0.01964840292930603,
                0.012509572319686413,
                0.013482571579515934,
                0.030040401965379715,
                -0.000662204169202596,
                -0.06756612658500671,
                -0.03549768403172493,
                -0.00479067163541913,
                -0.008697841316461563,
                0.06628811359405518,
                -0.011161797679960728,
                -0.05776720494031906,
                0.0026393593288958073,
                -0.04738713428378105,
                0.004935903009027243,
                0.03835928812623024,
                0.02107742615044117,
                0.007320122793316841,
                -0.0020187036134302616,
                0.017837975174188614,
                -0.005964178591966629,
                0.006023479625582695,
                -0.005879048258066177,
                0.057305172085762024,
                -0.0658312737941742,
                0.012024031952023506,
                -0.001080491696484387,
                0.026105888187885284,
                0.04970740154385567,
                0.007804768159985542,
                -0.06251727789640427,
                -0.056222084909677505,
                0.08796975761651993,
                -0.033721644431352615,
                -0.021248074248433113,
                -0.04587920382618904,
                -0.035614028573036194,
                -0.004535962361842394,
                0.005574071314185858,
                -0.007901824079453945,
                0.06918839365243912,
                -0.040543489158153534,
                0.008585489355027676,
                -0.0036593053955584764,
                -0.033701200038194656,
                0.028250569477677345,
                -0.004586504772305489,
                0.011547648347914219,
                0.030470632016658783,
                0.0003092465631198138,
                0.025972481817007065,
                0.01605210453271866,
                -0.06331822276115417,
                -0.022197986021637917,
                -0.012487304396927357,
                0.11123950034379959,
                -0.03171776980161667,
                -0.024656543508172035,
                -0.018407756462693214,
                -0.011076089926064014,
                0.04156948998570442,
                -0.04471650347113609,
                0.031521767377853394,
                -0.06082679703831673,
                0.0024381340481340885,
                0.013129879720509052,
                -0.008649863302707672,
                -0.07439454644918442,
                -0.03673933073878288,
                -0.0285495575517416,
                0.022260721772909164,
                -0.03698398545384407,
                -0.02871568687260151,
                0.001725934911519289,
                0.0071662119589746,
                -0.007500385865569115,
                -0.03166937828063965,
                0.012047962285578251,
                -0.10433594137430191,
                0.0019342764280736446,
                0.0020728250965476036,
                -0.003937246277928352,
                0.022874852642416954,
                -0.037523724138736725,
                0.028185131028294563,
                -0.007596924901008606,
                -0.011690470390021801,
                0.0727897435426712,
                -0.018660595640540123,
                0.007589067332446575,
                -0.07562293857336044,
                -0.0005621824529953301,
                -0.02205040119588375,
                1.996339591414653e-07,
                -0.02980547957122326,
                0.03432825207710266,
                0.00431166123598814,
                0.01961498148739338,
                0.01372656598687172,
                0.024831676855683327,
                -0.037569861859083176,
                0.01636498048901558,
                0.020578041672706604,
                0.011650708504021168,
                0.03639000281691551,
                -0.02808486670255661,
                0.017142824828624725,
                -0.08018818497657776,
                0.024326270446181297,
                -0.005745376460254192,
                0.08589214086532593,
                0.0473906584084034,
                -0.0010544852120801806,
                0.025717798620462418,
                0.053450003266334534,
                -0.015392174012959003,
                -0.006654584780335426,
                -0.0010831719264388084,
                -0.0507148914039135,
                -0.004619434475898743,
                -0.003928730729967356,
                -0.00555464019998908,
                0.06577859818935394,
                -0.003565517719835043,
                0.05437014624476433,
                0.00027034434606321156,
                0.0065521798096597195,
                0.05199013277888298,
                0.018362831324338913,
                -0.03631868585944176,
                0.056051965802907944,
                0.022180477157235146,
                0.0016288679325953126,
                0.07013492286205292,
                0.0062446799129247665,
                0.021339165046811104,
                0.012216761708259583,
                0.052140749990940094,
                -0.0011242348700761795,
                0.0007739902357570827,
                0.0032786913216114044,
                0.031155874952673912,
                -0.061222516000270844,
                0.0013235132209956646,
                -0.022999215871095657,
                0.07188254594802856,
                0.04628172889351845,
                -0.034331485629081726,
                0.015560793690383434,
                -0.006813546176999807,
                -0.03416493162512779,
                0.013638707809150219,
                0.02481316588819027,
                0.019878994673490524,
                -0.06361544877290726,
                -0.03344409912824631,
                0.026941275224089622,
                0.013780840672552586,
                0.0631277933716774,
                -0.014079834334552288,
                -0.04131164401769638,
                1.2653474630013383e-34,
                -0.023111067712306976,
                0.04059630632400513,
                0.01250938605517149,
                0.07110239565372467,
                0.031865812838077545,
                -0.033907558768987656,
                0.01145984698086977,
                -0.00010253033542539924,
                0.029391596093773842,
                -0.002442574128508568,
                -0.034768521785736084
            ]
        },
        "Skills": {
            "name": [
                "Autocad",
                "Mathcad",
                "FEA",
                "Staad",
                "FS2000",
                "Structural",
                "Design Engineering",
                "Inventor",
                "Mechanical Engineering"
            ],
            "rep": [
                -0.05173574388027191,
                -0.033139634877443314,
                -0.042090900242328644,
                -0.005857531446963549,
                -0.021399693563580513,
                0.012761234305799007,
                0.03487157076597214,
                -0.021900082007050514,
                -0.07406829297542572,
                -0.007712601218372583,
                0.042709436267614365,
                0.04691588506102562,
                0.027743089944124222,
                0.05229918286204338,
                -0.039708223193883896,
                -0.01843170076608658,
                -0.036318693310022354,
                -0.0336502343416214,
                -0.03925973176956177,
                -0.028601180762052536,
                -0.030292585492134094,
                0.006281114183366299,
                -0.05482572317123413,
                0.030848048627376556,
                -0.06970112025737762,
                -0.03742625564336777,
                -0.04817187413573265,
                -0.051570627838373184,
                0.016053223982453346,
                0.05217628553509712,
                -0.014392088167369366,
                0.09062599390745163,
                0.003738855244591832,
                0.009103025309741497,
                1.5336372598540038e-06,
                -0.04405580833554268,
                -0.007334199268370867,
                0.02074243314564228,
                0.018481209874153137,
                0.06426467001438141,
                -0.00666164793074131,
                0.0031472062692046165,
                -0.04860073700547218,
                -0.04514016956090927,
                0.006476160138845444,
                0.007504281122237444,
                0.031484268605709076,
                -0.045499179512262344,
                0.03481152281165123,
                -0.010369857773184776,
                0.011463281698524952,
                -0.06211235001683235,
                0.03192359209060669,
                -0.04747789353132248,
                -0.012074426747858524,
                -0.04186057299375534,
                0.02523615024983883,
                0.05098960921168327,
                0.021151453256607056,
                0.045323941856622696,
                -0.010013957507908344,
                0.04342704266309738,
                -0.015370000153779984,
                -0.010348422452807426,
                0.09159389138221741,
                -0.0012796622468158603,
                0.025573818013072014,
                -0.050003085285425186,
                0.03471464663743973,
                0.03312843292951584,
                0.018170062452554703,
                0.0036939349956810474,
                0.012595552951097488,
                -0.027454707771539688,
                -0.04213814064860344,
                -0.008234191685914993,
                -0.062440209090709686,
                0.02838045544922352,
                0.009204471483826637,
                -0.08031537383794785,
                0.006171827670186758,
                -0.026356838643550873,
                -0.05001800134778023,
                -0.0227853674441576,
                0.0328323170542717,
                -0.0652574673295021,
                0.002461896277964115,
                0.016149526461958885,
                -0.041620612144470215,
                0.021173186600208282,
                0.024040624499320984,
                -0.05677073076367378,
                -0.02834703028202057,
                0.02816811203956604,
                0.05642755702137947,
                -0.01993393898010254,
                -0.052679773420095444,
                0.019059808924794197,
                0.005893433932214975,
                0.01605348475277424,
                0.039308905601501465,
                0.03813977167010307,
                -0.016904624179005623,
                0.05490410700440407,
                -0.04777723550796509,
                0.0631927028298378,
                -0.00906859990209341,
                0.03530307859182358,
                -0.01777772046625614,
                0.05737372115254402,
                -0.025626903399825096,
                -0.03400683403015137,
                -0.09121512621641159,
                -0.018256230279803276,
                0.02328450046479702,
                0.0073094721883535385,
                -0.024943219497799873,
                0.011153526604175568,
                -0.05481462925672531,
                0.004422580357640982,
                0.06717634946107864,
                0.053746845573186874,
                -0.005577826872467995,
                0.005859269294887781,
                -0.01356419175863266,
                -0.03468327224254608,
                -0.05066008120775223,
                0.042702995240688324,
                -0.009444564580917358,
                0.030294569209218025,
                0.024011829867959023,
                0.048806775361299515,
                0.00021724842372350395,
                0.042715903371572495,
                -0.03438454493880272,
                0.025018997490406036,
                -0.02174239046871662,
                0.028670208528637886,
                0.005552866030484438,
                -0.022886455059051514,
                0.04503246769309044,
                -0.0209330003708601,
                -0.03308789059519768,
                -0.04263996705412865,
                -0.042826227843761444,
                0.013616184704005718,
                0.011141199618577957,
                0.05930535867810249,
                0.03246649354696274,
                -0.03645172715187073,
                0.022668320685625076,
                0.028839023783802986,
                0.001675111474469304,
                0.03478098660707474,
                0.053023889660835266,
                0.009149779565632343,
                0.004999791271984577,
                0.0010810877429321408,
                0.0036021345295011997,
                -0.0008672941476106644,
                -0.010463081300258636,
                -0.014395645819604397,
                0.03306305408477783,
                -0.010692321695387363,
                0.0015506977215409279,
                0.0012697562342509627,
                -0.033069852739572525,
                -0.0054112267680466175,
                -0.04214535281062126,
                -0.019463850185275078,
                -0.017284231260418892,
                -0.0570538304746151,
                -0.032918985933065414,
                -0.0023491953033953905,
                -0.028859900310635567,
                0.0005517177633009851,
                0.03729915991425514,
                0.05433056131005287,
                0.05789724364876747,
                0.00715209823101759,
                0.016882190480828285,
                -0.07372040301561356,
                -0.046522192656993866,
                0.014364846050739288,
                -0.0173061303794384,
                0.029028918594121933,
                -0.05929766595363617,
                -0.007241174578666687,
                -0.015730952844023705,
                -0.009533494710922241,
                0.038601770997047424,
                -0.013202446512877941,
                -0.003676935564726591,
                -0.02267720177769661,
                0.02248353511095047,
                0.07320573180913925,
                0.03001939319074154,
                -0.03998167812824249,
                -0.05370347574353218,
                0.004444811027497053,
                -0.07635357230901718,
                -0.007719405461102724,
                -0.021262070164084435,
                -0.003645394928753376,
                -0.021383080631494522,
                -0.014263431541621685,
                -0.009020114317536354,
                -0.019223038107156754,
                -0.0004536313936114311,
                -0.007344292011111975,
                0.05015328526496887,
                0.009300573728978634,
                0.00902413111180067,
                0.022211682051420212,
                -0.008845812641084194,
                -0.009373743087053299,
                -0.0036871617194265127,
                0.028368625789880753,
                -0.00336103281006217,
                0.0006516158464364707,
                -0.018899347633123398,
                0.017286421731114388,
                0.02276686392724514,
                0.024674687534570694,
                -0.06588632613420486,
                -0.09268296509981155,
                0.010420138016343117,
                0.027869613841176033,
                -0.033175669610500336,
                0.015681933611631393,
                -0.02635359764099121,
                0.02655824087560177,
                0.04131542518734932,
                0.004667473025619984,
                -0.004845982883125544,
                0.019464833661913872,
                -5.024667188990861e-07,
                0.020242970436811447,
                0.013571851886808872,
                -0.079177625477314,
                -0.08863698691129684,
                -0.029986007139086723,
                0.03842177242040634,
                0.038587432354688644,
                -0.011925667524337769,
                0.007172569166868925,
                0.011465061455965042,
                0.01029690820723772,
                0.0298635121434927,
                0.0014669332886114717,
                -0.03548907861113548,
                -0.055430807173252106,
                0.0411657877266407,
                -0.006526703014969826,
                0.01095510832965374,
                0.0422196201980114,
                0.047756049782037735,
                0.039576832205057144,
                0.011207997798919678,
                -0.012668204493820667,
                0.016047757118940353,
                -0.023306475952267647,
                0.019872767850756645,
                -0.05558544769883156,
                0.027162296697497368,
                0.03570529818534851,
                0.034011680632829666,
                -0.008276944048702717,
                -0.06549813598394394,
                0.013917388394474983,
                -0.041403599083423615,
                0.005022177938371897,
                -0.064715676009655,
                -0.000596090336330235,
                -0.02751537412405014,
                0.01823989860713482,
                0.025246286764740944,
                -0.011081919074058533,
                -0.010624159127473831,
                0.004229182843118906,
                -0.0011586337350308895,
                0.025162992998957634,
                -0.002179093658924103,
                -0.009190893732011318,
                0.012152934446930885,
                -0.026584135368466377,
                0.0822206661105156,
                -0.04158956557512283,
                -0.03826895356178284,
                0.07543744146823883,
                -0.07580006867647171,
                -0.009641843847930431,
                0.0558260940015316,
                -0.029852135106921196,
                -0.019941730424761772,
                -0.008799249306321144,
                -0.03898243233561516,
                0.06336234509944916,
                -0.034605227410793304,
                0.01751079596579075,
                0.021788137033581734,
                0.01553740818053484,
                -0.016410883516073227,
                0.01850561425089836,
                -0.0031483459752053022,
                0.03789234161376953,
                0.0932108536362648,
                0.012661871500313282,
                -0.006103352643549442,
                0.01469591073691845,
                0.0679890438914299,
                0.009916801005601883,
                0.00618997635319829,
                0.021934524178504944,
                -0.007945016026496887,
                0.0016443751519545913,
                0.02796304039657116,
                -0.07493109256029129,
                -0.0015741048846393824,
                0.06617707014083862,
                0.019547946751117706,
                -0.04016067087650299,
                0.04964488372206688,
                -0.05053175240755081,
                -0.02661236561834812,
                0.10115725547075272,
                0.002434262540191412,
                0.009373735636472702,
                0.05851499363780022,
                0.011331909336149693,
                -0.011595632880926132,
                -0.008026857860386372,
                -0.004007898271083832,
                0.04736548662185669,
                0.06535810977220535,
                0.07219099253416061,
                -0.021259907633066177,
                -0.058130599558353424,
                -0.014780483208596706,
                0.04462294653058052,
                -0.028966093435883522,
                -0.06308569014072418,
                -0.04738161712884903,
                0.05982265621423721,
                -0.07168595492839813,
                -0.0066933841444551945,
                0.036319755017757416,
                -0.039589669555425644,
                -0.0008621227461844683,
                0.04233725368976593,
                -0.004045757465064526,
                0.03098311461508274,
                -0.03311188519001007,
                0.012237297371029854,
                -0.01184329204261303,
                0.05714467540383339,
                0.019789021462202072,
                0.001516213989816606,
                -0.07504262775182724,
                -0.06357374042272568,
                -0.03186288848519325,
                -0.002049483126029372,
                -0.04375280439853668,
                0.041246041655540466,
                -0.09382707625627518,
                0.03832342475652695,
                0.018978767096996307,
                0.03963708505034447,
                0.003985672723501921,
                -0.026582686230540276,
                -0.02586860954761505,
                0.07207639515399933,
                -6.644743552897125e-05,
                -0.018520010635256767,
                0.004420972429215908,
                -0.0040773372165858746,
                -0.022236935794353485,
                0.019601402804255486,
                -0.008333937264978886,
                0.021095702424645424,
                -0.008389580063521862,
                -0.08401370048522949,
                -0.004342868458479643,
                0.006219116505235434,
                -0.01638822816312313,
                0.01679103448987007,
                -0.023024355992674828,
                0.038459308445453644,
                -0.01325128972530365,
                0.043702900409698486,
                -0.009719357825815678,
                -0.09945052117109299,
                -0.04287884011864662,
                -0.006479006726294756,
                0.01729094237089157,
                -0.019919821992516518,
                -0.08421734720468521,
                -0.04534230753779411,
                -0.004599391017109156,
                -0.016119113191962242,
                0.00025731793721206486,
                -0.033239323645830154,
                0.04909975081682205,
                -0.019790567457675934,
                0.017273765057325363,
                0.054747674614191055,
                0.052450068295001984,
                0.023812348023056984,
                0.02282155491411686,
                -0.08233383297920227,
                0.051947496831417084,
                0.07905175536870956,
                0.01881897822022438,
                -0.017348652705550194,
                0.02660597860813141,
                0.03497518599033356,
                -0.03109103813767433,
                0.010111085139214993,
                -0.04349564388394356,
                0.026846667751669884,
                0.0071918959729373455,
                -0.012068623676896095,
                0.007388113997876644,
                0.008674858137965202,
                0.05242163687944412,
                -0.02209896594285965,
                -0.01581238955259323,
                -0.03340669721364975,
                0.02190718799829483,
                0.008357391692698002,
                0.021177686750888824,
                -0.02203209511935711,
                -0.08577003329992294,
                0.11400804668664932,
                -0.026466652750968933,
                -0.08563172072172165,
                -0.0455256886780262,
                -0.02802457846701145,
                -0.0523001067340374,
                -0.002036473946645856,
                0.027541274204850197,
                0.02735993079841137,
                -0.0030343763064593077,
                -0.016558662056922913,
                -0.05482944846153259,
                -0.005771326832473278,
                0.0177124235779047,
                -0.06146851181983948,
                -0.056971367448568344,
                0.06730159372091293,
                0.04274414852261543,
                0.01806706190109253,
                0.006506676319986582,
                -0.02852516993880272,
                -0.004257619380950928,
                -0.0012705629924312234,
                0.0502404123544693,
                -0.036513060331344604,
                -0.017618859186768532,
                0.03727326914668083,
                0.059483449906110764,
                0.031042082235217094,
                -0.023836180567741394,
                -0.04181903600692749,
                -0.013106518425047398,
                -0.03599418327212334,
                0.02164330519735813,
                0.00907753687351942,
                -0.011181448586285114,
                -0.02792307361960411,
                0.0029314071871340275,
                0.025774752721190453,
                -0.0228761974722147,
                0.03509409353137016,
                -0.012175912968814373,
                -0.03383268415927887,
                -0.024534162133932114,
                0.01959236152470112,
                -0.0607849583029747,
                0.0007190460455603898,
                0.03885786607861519,
                -0.02227889746427536,
                0.01515900157392025,
                0.08057045936584473,
                -0.04063611477613449,
                0.018149420619010925,
                0.005695416126400232,
                -0.013234375044703484,
                0.04658793285489082,
                0.09747538715600967,
                -0.0047280071303248405,
                -0.07855655997991562,
                0.02754894457757473,
                -0.031500425189733505,
                0.04688639938831329,
                0.0022122368682175875,
                0.0063360328786075115,
                -0.04811179265379906,
                0.020439011976122856,
                0.07233157753944397,
                0.047232333570718765,
                0.013002811931073666,
                -0.026887286454439163,
                0.07596921175718307,
                0.012039230205118656,
                0.03134870156645775,
                0.02887078747153282,
                -0.035247500985860825,
                -0.03519216924905777,
                0.0012939268490299582,
                -0.018466612324118614,
                -0.007109249010682106,
                0.007431673351675272,
                -0.09376445412635803,
                0.021072067320346832,
                0.06179971247911453,
                0.034395478665828705,
                -0.028303299099206924,
                -0.030824551358819008,
                0.002570871729403734,
                -0.008450083434581757,
                0.00037400954170152545,
                -0.0063591618090868,
                0.023979561403393745,
                0.004172383341938257,
                -0.06211584061384201,
                -0.01285754144191742,
                -0.0020345060620456934,
                -0.05069679766893387,
                0.016024816781282425,
                -0.035090669989585876,
                0.007985888980329037,
                0.0016193034825846553,
                -0.015471214428544044,
                -0.03148077800869942,
                0.08838307112455368,
                0.003953960724174976,
                0.00537091726437211,
                -0.027842413634061813,
                0.012354012578725815,
                -0.01983611285686493,
                0.005803571082651615,
                -0.03147796541452408,
                0.002198845613747835,
                -0.010267291218042374,
                -0.004474760964512825,
                0.004913677461445332,
                0.01389587577432394,
                -0.010967540554702282,
                -0.01954786106944084,
                0.03226062282919884,
                0.06886693835258484,
                0.0015084008919075131,
                0.03574541211128235,
                0.01975409872829914,
                -4.773700925788541e-33,
                -0.0168907530605793,
                -0.03169187158346176,
                0.007689325138926506,
                -0.010797470808029175,
                0.009376531466841698,
                -0.04086906835436821,
                0.015304099768400192,
                0.035576511174440384,
                0.035321250557899475,
                -0.00653040362522006,
                0.005458424799144268,
                -0.03880917653441429,
                0.0037103474605828524,
                -0.04350052773952484,
                0.04174400120973587,
                0.04763433709740639,
                0.02933969534933567,
                0.0186590738594532,
                -0.03946239501237869,
                -0.019626080989837646,
                -0.0968017652630806,
                0.012461448088288307,
                -0.052548833191394806,
                -6.0378577472874895e-05,
                -0.025041446089744568,
                -0.029045216739177704,
                0.02619989775121212,
                -0.05895943567156792,
                0.015774089843034744,
                0.020499784499406815,
                0.0018026952166110277,
                0.009760743007063866,
                -0.026240723207592964,
                0.06647045165300369,
                -0.00893311109393835,
                -0.0203427542001009,
                -0.10400226712226868,
                -0.049126558005809784,
                -0.016248255968093872,
                0.043616652488708496,
                -0.06251096725463867,
                -0.011720972135663033,
                -0.027019402012228966,
                0.018803823739290237,
                -0.0014354025479406118,
                0.011226908303797245,
                0.03337113931775093,
                -0.027285261079669,
                -0.018113475292921066,
                -0.0069651249796152115,
                -0.0514024943113327,
                0.009204124100506306,
                -0.04051187261939049,
                -0.07751606404781342,
                0.026917478069663048,
                0.023703372105956078,
                0.027772439643740654,
                0.006241637282073498,
                -0.025665320456027985,
                0.0586036816239357,
                0.037203188985586166,
                -0.024522261694073677,
                0.03983089700341225,
                -0.028968382626771927,
                0.0016683908179402351,
                -0.006877794861793518,
                0.026243338361382484,
                0.011419704183936119,
                -0.024489710107445717,
                -0.034977104514837265,
                -0.004742544610053301,
                0.04431963339447975,
                -0.06717371195554733,
                -0.06425931304693222,
                -0.03148156404495239,
                -0.021653912961483,
                0.009861159138381481,
                0.030084742233157158,
                -0.03791647031903267,
                0.009992151521146297,
                -0.014359238557517529,
                -0.025241084396839142,
                -0.012586035765707493,
                0.0004848482203669846,
                -0.0015883123269304633,
                -0.019357925280928612,
                -0.01693572662770748,
                0.04137912765145302,
                0.011592317372560501,
                0.0671408474445343,
                1.7203819879796356e-05,
                0.014967198483645916,
                -0.04514184221625328,
                -0.01951887086033821,
                0.023252079263329506,
                0.00887762475758791,
                -0.015155031345784664,
                -0.01932002417743206,
                -0.010995032265782356,
                0.04621538519859314,
                0.019370583817362785,
                0.04381431266665459,
                -0.06694343686103821,
                0.007360614370554686,
                0.016812894493341446,
                0.0027856321539729834,
                -0.05615796148777008,
                0.005763016175478697,
                -0.03866728022694588,
                0.0018578064627945423,
                -0.006255052983760834,
                -0.022303815931081772,
                0.02461988292634487,
                0.01419038511812687,
                0.0013435379369184375,
                0.02499752677977085,
                0.010403021238744259,
                -0.01104074064642191,
                -0.02440705895423889,
                -0.005782688967883587,
                -0.012507475912570953,
                -0.026880284771323204,
                -0.026828154921531677,
                -0.007806353271007538,
                -0.012881436385214329,
                -0.027482308447360992,
                -0.012030723504722118,
                0.05617282912135124,
                0.016855092719197273,
                -0.04622874781489372,
                -0.013924580067396164,
                -0.003058802802115679,
                2.0171609094177256e-07,
                -0.02081894502043724,
                0.08181489259004593,
                0.0563301146030426,
                0.028778061270713806,
                -0.02696317806839943,
                -0.003083918709307909,
                -0.026971153914928436,
                0.02523711696267128,
                0.036318615078926086,
                0.06704621762037277,
                0.017740344628691673,
                -0.006023860070854425,
                -0.01621006801724434,
                -0.0030660289339721203,
                0.02325967140495777,
                0.08724432438611984,
                0.034299250692129135,
                0.029269004240632057,
                -0.047863345593214035,
                0.033066488802433014,
                -0.011662201955914497,
                0.010000335983932018,
                -0.017613783478736877,
                0.012651911936700344,
                0.013554348610341549,
                0.04861106351017952,
                -0.04484986141324043,
                0.014323032461106777,
                0.0597219355404377,
                -0.065674789249897,
                0.003314008703455329,
                0.013246090151369572,
                -0.021160250529646873,
                -0.009324315935373306,
                -0.006860830821096897,
                0.008611472323536873,
                0.04239669442176819,
                0.002547863870859146,
                -0.030984031036496162,
                0.06780122220516205,
                0.07281792163848877,
                -0.007089444436132908,
                -0.020927006378769875,
                0.000177344074472785,
                0.006064978893846273,
                -0.016058774664998055,
                0.10762321949005127,
                -0.034634675830602646,
                -0.0017814756138250232,
                0.03796514496207237,
                0.06419552117586136,
                0.05073930695652962,
                0.009972694329917431,
                -0.015005498193204403,
                0.005808217916637659,
                0.005240463186055422,
                0.05619286745786667,
                0.03308672830462456,
                -0.002587429480627179,
                0.03945600986480713,
                -0.04562104865908623,
                0.020118769258260727,
                0.0044801621697843075,
                0.03132686764001846,
                0.0062332055531442165,
                0.03414641320705414,
                0.006094653625041246,
                9.579706135294358e-35,
                -0.024391155689954758,
                0.006936413235962391,
                0.021298717707395554,
                0.08818326145410538,
                -0.018429389223456383,
                0.037249013781547546,
                0.0025077934842556715,
                0.010598567314445972,
                0.0021693925373256207,
                0.010952494107186794,
                -0.022402323782444,
                -0.07921146601438522,
                -0.028769519180059433,
                -0.0460854209959507,
                0.02430690824985504,
                -0.006498644128441811,
                0.010353928431868553,
                0.026708068326115608,
                -0.054142989218235016,
                -0.05730195343494415,
                0.008796858601272106,
                0.04012134298682213,
                0.04044102132320404,
                0.06476359814405441,
                0.008205237798392773,
                -0.04872303083539009,
                -0.02824610471725464,
                0.00037452118704095483,
                0.007760896813124418,
                -0.03483881056308746,
                0.006187055259943008,
                -0.01906822808086872,
                0.036728210747241974,
                -0.052286069840192795,
                0.021902501583099365,
                -0.0653015524148941,
                -0.0817556232213974,
                -0.027619754895567894,
                -0.06267506629228592,
                0.005986359436064959,
                0.050239041447639465,
                -0.011699359863996506,
                0.03609606251120567,
                -0.01164369098842144,
                0.005434848368167877,
                1.8090360072164913e-06,
                -0.0335586741566658,
                0.026304492726922035,
                0.061712462455034256,
                0.05244053900241852,
                0.0796961784362793,
                0.0008024739217944443,
                0.01782081462442875,
                -0.045417528599500656,
                -0.012336943298578262,
                -0.01252239290624857,
                0.022848941385746002,
                0.01766120083630085,
                -0.05060234293341637,
                0.05436855927109718,
                0.03138742595911026,
                0.005874398164451122,
                -0.06413474678993225,
                0.02645067311823368,
                0.005228552967309952,
                0.028125090524554253,
                -0.058709461241960526,
                0.036043569445610046,
                0.06622713059186935,
                0.025829387828707695,
                0.037905994802713394,
                0.021861134096980095,
                0.01768951304256916,
                -0.02961074560880661,
                -0.014285622164607048,
                0.06804829090833664,
                0.010348833166062832,
                0.050897687673568726,
                0.006040066946297884,
                0.01829826459288597,
                0.014379304833710194,
                0.07844068855047226,
                -0.015108547173440456,
                0.009609516710042953,
                0.00046769026084803045,
                -0.027718989178538322,
                -0.013841803185641766,
                -0.07412917912006378,
                0.005636468064039946,
                0.014185819774866104,
                -0.04040098935365677,
                -0.0300888791680336,
                -0.04166017845273018,
                -0.03281417489051819,
                0.006155048031359911,
                0.007836790755391121,
                -0.026414718478918076,
                -0.017014263197779655,
                -0.033048875629901886,
                -0.05762473866343498,
                0.05420579016208649,
                -0.021749094128608704,
                -0.034361470490694046,
                0.02567342482507229,
                0.014622392132878304,
                0.03499598801136017,
                -0.0033557466231286526,
                -0.07834729552268982,
                -0.05494311824440956,
                -0.030104784294962883,
                -0.05791504681110382,
                0.02882942371070385,
                0.029788384214043617,
                0.02614671178162098,
                0.03674931824207306,
                -0.028957616537809372,
                0.02935699187219143,
                -0.011523669585585594,
                -0.004303350113332272,
                -0.03878101333975792,
                0.0689699724316597,
                -0.0077049401588737965,
                -0.017588138580322266,
                -0.06779931485652924,
                0.01450811605900526,
                0.09609470516443253,
                0.03371589630842209,
                -0.03450699523091316,
                0.014616278931498528,
                -0.04734520986676216,
                -0.01437363214790821,
                0.07472836226224899,
                -0.02285400964319706,
                0.033931951969861984,
                0.00824901182204485,
                -0.006362978368997574,
                -0.059747662395238876,
                -0.04079219326376915,
                -0.0024694623425602913,
                -0.02237088792026043,
                0.020549505949020386,
                0.010228961706161499,
                0.05504539608955383,
                -0.04839782044291496,
                -0.005121101159602404,
                -0.03470015898346901,
                0.05522641912102699,
                -0.014081030152738094,
                0.01783369481563568,
                0.0382809154689312,
                0.015531769953668118,
                0.04227185249328613,
                -0.02021976187825203,
                -0.03916743025183678,
                -0.05785956233739853,
                0.008422537706792355,
                -0.0014630240621045232,
                -0.017959145829081535,
                0.029599322006106377,
                0.024701939895749092,
                -0.001991818193346262,
                0.0331454835832119,
                0.019976943731307983,
                -0.03160257637500763,
                0.007471882272511721,
                0.06937570869922638,
                0.024702535942196846,
                -0.05638795346021652,
                -0.00041224685264751315,
                0.00698700500652194,
                -0.00841577909886837,
                -0.036092303693294525,
                -0.010957571677863598,
                0.03834943473339081,
                -0.025037208572030067,
                0.02439829148352146,
                -0.021124539896845818,
                -0.05132671073079109,
                0.03878713771700859,
                -0.03894153609871864,
                0.022826742380857468,
                -0.017290813848376274,
                -0.0027967793866991997,
                -0.047164205461740494,
                -0.010419154539704323,
                -0.035290658473968506,
                0.009275984950363636,
                0.02798227034509182,
                0.0400654673576355,
                0.011418979614973068,
                0.003798370948061347,
                0.018434414640069008,
                -0.08519899845123291,
                -0.05212540179491043,
                0.02037598192691803,
                -0.049872443079948425,
                -0.02638392336666584,
                -0.05416239798069,
                -0.008656353689730167,
                0.0057796090841293335,
                -0.004224512726068497,
                0.02817477099597454,
                0.02583893947303295,
                0.002112480578944087,
                0.003412215504795313,
                -0.008329946547746658,
                0.019215844571590424,
                0.04581759124994278,
                0.007860939018428326,
                -0.027270758524537086,
                -0.02780456654727459,
                -0.07572121173143387,
                0.004638026002794504,
                0.04658517614006996,
                -0.0626087561249733,
                0.014156874269247055,
                -0.024969125166535378,
                0.02568860910832882,
                -0.019506480544805527,
                0.012173311784863472,
                -0.0396362729370594,
                0.06874532252550125,
                0.027977215126156807,
                -0.0163873303681612,
                -0.027632271870970726,
                0.0131151732057333,
                0.014013711363077164,
                -0.001172347692772746,
                0.009395801462233067,
                0.04282410442829132,
                -0.03459423407912254,
                -0.029809601604938507,
                -0.023824365809559822,
                0.06447988003492355,
                0.009415877982974052,
                -0.0049875774420797825,
                -0.06357914209365845,
                0.015165094286203384,
                0.008902239613234997,
                -0.013196984305977821,
                -0.010778767056763172,
                0.020353810861706734,
                0.011913884431123734,
                0.03870837762951851,
                0.03005707822740078,
                -0.01918480545282364,
                -0.03751179203391075,
                -0.0320209339261055,
                -0.003006017068400979,
                0.05131087452173233,
                -0.09056048840284348,
                -0.03680267557501793,
                -0.03093530610203743,
                0.029053928330540657,
                0.03859562799334526,
                0.07862485945224762,
                -0.02461046539247036,
                0.007009555585682392,
                -0.02643769234418869,
                0.019050749018788338,
                -0.03167286515235901,
                -0.03988264873623848,
                0.009477793239057064,
                0.06359595060348511,
                -0.021767633035779,
                0.026527686044573784,
                0.06688304245471954,
                0.017491785809397697,
                -0.02310589700937271,
                0.06031843647360802,
                0.054627325385808945,
                0.003793892450630665,
                -0.03740108013153076,
                0.05050855502486229,
                -0.10113991796970367,
                0.028273511677980423,
                0.0631612092256546,
                0.03098474070429802,
                0.014829808846116066,
                -0.04473558068275452,
                -0.016005655750632286,
                -0.005513437557965517,
                0.021543310955166817,
                -0.048291437327861786,
                0.013749860227108002,
                -0.0070926533080637455,
                0.003509944537654519,
                0.018277479335665703,
                -0.07727394998073578,
                -0.010201351717114449,
                -0.03940754383802414,
                0.009653653018176556,
                0.01221543364226818,
                -0.01308682095259428,
                -0.014182381331920624,
                0.0020474065095186234,
                0.02956109680235386,
                0.006867746356874704,
                -0.002857881598174572,
                -0.012654470279812813,
                0.09586100280284882,
                -0.049779392778873444,
                -0.02965175174176693,
                0.001337653025984764,
                0.02355968952178955,
                -0.014280020259320736,
                -0.04813579469919205,
                -0.004048474598675966,
                0.029503686353564262,
                -0.0015004678862169385,
                0.07391700893640518,
                -0.04361415654420853,
                0.02498326264321804,
                -0.01817021146416664,
                -0.00523749366402626,
                0.006673459429293871,
                0.06819827854633331,
                0.03798821195960045,
                0.008344266563653946,
                0.0008151239017024636,
                0.019746700301766396,
                0.0487261600792408,
                0.019097065553069115,
                -0.018589023500680923,
                0.03468884900212288,
                -0.011762847192585468,
                -0.04586330056190491,
                -0.06530754268169403,
                0.0009888592176139355,
                -0.035507623106241226,
                0.021411500871181488,
                0.04090039059519768,
                -0.04852449148893356,
                0.00396950775757432,
                -0.015233811922371387,
                -0.02956623025238514,
                0.052873700857162476,
                -0.03927260637283325,
                0.0015410197665914893,
                -0.025211801752448082,
                -0.02216033823788166,
                -0.02041662484407425,
                -0.06036139279603958,
                -0.0338796004652977,
                0.038410842418670654,
                0.06021246686577797,
                0.026778150349855423,
                -0.04409124702215195,
                -0.056125201284885406,
                0.03688861429691315,
                0.0478380061686039,
                -0.03364797309041023,
                -0.033400606364011765,
                -0.028485599905252457,
                0.08745045959949493,
                -3.744742207345553e-05,
                0.007353351451456547,
                0.018126031383872032,
                -0.05750665441155434,
                0.01671907864511013,
                0.014630435034632683,
                0.05366494879126549,
                -0.044877875596284866,
                -0.04386085644364357,
                -0.020793581381440163,
                0.021315842866897583,
                0.0308244451880455,
                -0.01582023873925209,
                0.021941598504781723,
                -0.023020926862955093,
                -0.03747177869081497,
                -0.033831529319286346,
                0.009177779778838158,
                -0.02561122551560402,
                0.02754397690296173,
                -0.028040310367941856,
                -0.0013961032964289188,
                0.043587442487478256,
                0.01789850927889347,
                -0.0033347501885145903,
                0.03804890066385269,
                -0.03688029944896698,
                0.06731772422790527,
                -0.00813216995447874,
                -0.07257214188575745,
                0.04348680004477501,
                -0.02639136090874672,
                -0.032186396420001984,
                -0.010639063082635403,
                0.013108943589031696,
                0.03921318054199219,
                -0.02034042775630951,
                -0.11370129883289337,
                0.00365651398897171,
                0.03390977531671524,
                -0.030940435826778412,
                0.0183964055031538,
                -0.013715123757719994,
                0.022956078872084618,
                -0.03857537358999252,
                0.008586396463215351,
                -0.017434392124414444,
                -0.1192651018500328,
                -0.022159935906529427,
                -0.025811072438955307,
                0.0023169901687651873,
                0.02215511165559292,
                -0.04994839057326317,
                0.03317035734653473,
                -0.015087291598320007,
                0.008213337510824203,
                0.022868817672133446,
                0.011126098223030567,
                0.017007771879434586,
                -0.031429365277290344,
                0.038346778601408005,
                -0.03244071453809738,
                -0.022142980247735977,
                0.007078582886606455,
                -0.019502529874444008,
                -0.06684914976358414,
                0.021697623655200005,
                0.08467724174261093,
                0.0179373137652874,
                -0.023756252601742744,
                0.0008765086531639099,
                0.01115819625556469,
                -0.008498342707753181,
                -0.026089416816830635,
                -0.017878983169794083,
                -0.036777351051568985,
                -0.010001162998378277,
                0.005715029314160347,
                0.06522709131240845,
                0.02455281652510166,
                0.03251520171761513,
                -0.016764331609010696,
                0.0022111074067652225,
                -0.02914128266274929,
                -0.01677037961781025,
                0.007082125172019005,
                0.02308579906821251,
                -0.047928325831890106,
                -0.09401137381792068,
                0.03516174852848053,
                -0.04990958422422409,
                -0.0593719445168972,
                -0.05411247909069061,
                0.05632561445236206,
                -0.020540794357657433,
                0.03255724906921387,
                0.02303970605134964,
                0.04044349119067192,
                0.014100727625191212,
                -0.025379603728652,
                -0.08399126678705215,
                0.04348810389637947,
                0.011319181881844997,
                -0.04619702696800232,
                -0.03345736488699913,
                -0.00284242769703269,
                0.10179571062326431,
                0.05667350813746452,
                0.05124370753765106,
                -0.034886907786130905,
                0.02251901477575302,
                0.004585414659231901,
                0.014681683853268623,
                -0.06999384611845016,
                -0.018391070887446404,
                -0.04664696007966995,
                0.06064414978027344,
                0.012330479919910431,
                -0.03136537969112396,
                -0.06965357810258865,
                0.0071217212826013565,
                -0.06603541970252991,
                -0.01072901114821434,
                -0.01949908770620823,
                0.009092509746551514,
                -0.04490506276488304,
                -0.04077526181936264,
                0.025516416877508163,
                -0.04096679762005806,
                0.034993961453437805,
                -0.024514567106962204,
                0.015454496257007122,
                0.007772497367113829,
                -0.026855947449803352,
                0.020036067813634872,
                0.004622580483555794,
                0.014250913634896278,
                -0.027252914384007454,
                0.024290816858410835,
                0.07671991735696793,
                -0.016621561720967293,
                -0.0044865719974040985,
                -0.05609888583421707,
                -0.001962801907211542,
                0.041680727154016495,
                0.04721445217728615,
                -0.01706702634692192,
                -0.019828950986266136,
                -0.00647446746006608,
                -0.02043052203953266,
                0.04478856548666954,
                0.00592262065038085,
                0.031470902264118195,
                0.024790052324533463,
                0.02564947120845318,
                0.0326727069914341,
                0.04601703956723213,
                0.006129925604909658,
                -0.021593507379293442,
                -0.002140396274626255,
                -0.023404618725180626,
                -0.005853679031133652,
                0.035149406641721725,
                0.00777263380587101,
                -0.019152091816067696,
                -0.0001841687480919063,
                0.03827768936753273,
                -0.046421218663454056,
                0.015570691786706448,
                -0.1282501518726349,
                -0.009238903410732746,
                0.008927886374294758,
                0.02573411911725998,
                0.02447964996099472,
                -0.035603731870651245,
                -0.009248737245798111,
                0.0129551962018013,
                -0.02411487139761448,
                0.057415466755628586,
                -0.008904824033379555,
                -0.007730494253337383,
                -0.028686262667179108,
                0.011124576441943645,
                -0.010774035938084126,
                -0.06808368861675262,
                0.023759163916110992,
                -0.07110190391540527,
                -0.017416220158338547,
                -0.02779053896665573,
                0.04591791331768036,
                0.007980147376656532,
                0.006913527846336365,
                0.05476020649075508,
                0.041281331330537796,
                0.0019161362433806062,
                -0.04585839435458183,
                -0.023343253880739212,
                0.013754205778241158,
                -0.04951091110706329,
                -0.03259660303592682,
                0.005950568243861198,
                0.03042047657072544,
                0.011794502846896648,
                0.001365899108350277,
                -0.029804542660713196,
                -0.0069256252609193325,
                0.01318778470158577,
                0.06808988749980927,
                -0.02160070091485977,
                -0.01491142064332962,
                -0.016722485423088074,
                -4.712726197101466e-33,
                0.016983099281787872,
                0.01852600648999214,
                0.0038910990115255117,
                0.04610644280910492,
                -0.027066482231020927,
                -0.05482705309987068,
                0.0715377926826477,
                0.03832898288965225,
                0.03784710541367531,
                -0.015394343063235283,
                0.013893425464630127,
                0.03680535405874252,
                -0.010457261465489864,
                -0.06314294040203094,
                -0.006733152084052563,
                0.04079935699701309,
                -0.006125357002019882,
                0.004343424923717976,
                -0.03396165743470192,
                -0.053150806576013565,
                -0.07795000076293945,
                0.003736858954653144,
                0.02820277214050293,
                -0.03776141628623009,
                -0.06006072089076042,
                0.003584394697099924,
                0.0024643924552947283,
                -0.039472490549087524,
                -0.011321255005896091,
                0.019371846690773964,
                0.014473607763648033,
                0.040162548422813416,
                -0.04094097763299942,
                0.02812451496720314,
                0.014098655432462692,
                -0.004404961597174406,
                0.003935900516808033,
                -0.025342358276247978,
                0.00532953254878521,
                0.052984196692705154,
                -0.023629676550626755,
                -0.036268945783376694,
                -0.035579025745391846,
                0.04481285810470581,
                -0.037230510264635086,
                0.002383338985964656,
                0.01387789100408554,
                0.003952091094106436,
                -0.01210614200681448,
                0.03974457457661629,
                -0.025616366416215897,
                0.02636110410094261,
                -0.02461438812315464,
                -0.006322063971310854,
                0.010467000305652618,
                -0.0035962017718702555,
                -0.016213780269026756,
                -0.000829507305752486,
                -0.013368873856961727,
                0.0399022251367569,
                0.04590902104973793,
                -0.0358656570315361,
                0.015724699944257736,
                -0.016485508531332016,
                0.01651022583246231,
                -0.01841544546186924,
                0.06699001044034958,
                0.0035707554779946804,
                0.005341393873095512,
                -0.024349858984351158,
                0.0014657402643933892,
                0.014006135985255241,
                -0.0009805734734982252,
                -0.020396439358592033,
                -0.014570910483598709,
                -0.020924614742398262,
                0.019238140434026718,
                -0.0020229793153703213,
                -0.05327500030398369,
                -0.016720404848456383,
                -0.02349809557199478,
                0.0010898347245529294,
                -0.015495378524065018,
                -0.00536803575232625,
                0.01884976401925087,
                0.05834045633673668,
                -0.027719151228666306,
                0.029608285054564476,
                0.014437025412917137,
                0.036026496440172195,
                -0.00014637401909567416,
                0.04517758637666702,
                -0.061113882809877396,
                0.007963116280734539,
                0.024592438712716103,
                0.03471557796001434,
                0.0031486228108406067,
                0.018272073939442635,
                0.01051272265613079,
                0.026922032237052917,
                -0.0006827826728112996,
                0.06192132085561752,
                -0.033396076411008835,
                -0.032345350831747055,
                0.028323188424110413,
                0.00129610113799572,
                -0.04288602992892265,
                -0.012408261187374592,
                -0.036227788776159286,
                -0.0038105780258774757,
                0.0025431974790990353,
                -0.0450950488448143,
                0.03936716914176941,
                0.07348806411027908,
                0.024740001186728477,
                0.004843282978981733,
                0.020213810727000237,
                -0.028040574863553047,
                -0.027632350102066994,
                -0.009835740551352501,
                -0.051067717373371124,
                -0.07210633903741837,
                -0.023882059380412102,
                0.0019276143284514546,
                0.011621701531112194,
                -0.024402273818850517,
                -0.029329055920243263,
                0.02544659748673439,
                0.00046521538752131164,
                -0.04748094826936722,
                -0.03267250210046768,
                0.027032122015953064,
                2.2358061357863335e-07,
                -0.019333433359861374,
                0.07313703745603561,
                0.019895173609256744,
                0.05871497094631195,
                -0.007638704497367144,
                -0.005578318145126104,
                -0.003078719135373831,
                0.033295806497335434,
                0.014778710901737213,
                0.05073545500636101,
                -0.0005429783486761153,
                -0.0005986829055473208,
                -0.0019603853579610586,
                0.021244607865810394,
                0.0336310975253582,
                0.0827651396393776,
                0.012069650925695896,
                0.02986147440969944,
                -0.03263653442263603,
                0.007615329697728157,
                0.06762249767780304,
                0.008302201516926289,
                0.04544615373015404,
                -0.0007337547722272575,
                -0.002134631620720029,
                0.017189519479870796,
                0.02457251213490963,
                0.035255640745162964,
                0.04607969522476196,
                -0.05936379358172417,
                -0.025975309312343597,
                -0.04616999998688698,
                0.028980320319533348,
                -0.04185045138001442,
                0.03858353570103645,
                -0.016562944278120995,
                0.03524281084537506,
                0.05045291781425476,
                -0.011594846844673157,
                0.08743548393249512,
                0.034108005464076996,
                -0.018541375175118446,
                -0.016719363629817963,
                0.018719349056482315,
                0.005595986265689135,
                0.03801047429442406,
                0.09532823413610458,
                -0.07247161865234375,
                -0.019069155678153038,
                0.02282041497528553,
                0.030898138880729675,
                0.01646716520190239,
                0.0706935003399849,
                -0.024937260895967484,
                0.015704859048128128,
                0.017292065545916557,
                0.021153828129172325,
                0.040951333940029144,
                -0.0016370577504858375,
                0.06364790350198746,
                -0.04235892742872238,
                -0.02371855638921261,
                0.019438812509179115,
                0.056164875626564026,
                0.043003715574741364,
                0.03669867664575577,
                0.005175401456654072,
                1.2953392141309852e-34,
                -0.015271789394319057,
                0.004923421423882246,
                0.009945681318640709,
                0.09770394116640091,
                -0.04722484201192856,
                0.008427254855632782,
                -0.06311825662851334,
                -0.03396466374397278,
                -0.003634104738011956,
                0.013198168016970158,
                0.0023439277429133654,
                -0.007085081189870834,
                0.037318676710128784,
                -0.013103488832712173,
                -0.015567430295050144,
                0.036944933235645294,
                0.02637072652578354,
                -0.007420039735734463,
                -0.01803930103778839,
                0.036996450275182724,
                0.02640368789434433,
                0.02541869319975376,
                -0.07041656225919724,
                0.04279358685016632,
                0.042508870363235474,
                -0.0017142443684861064,
                0.04270283132791519,
                0.006406780332326889,
                0.017101436853408813,
                -0.02615993469953537,
                -0.036940161138772964,
                -0.03708990290760994,
                -0.014683619141578674,
                -0.0127706965431571,
                0.04742138087749481,
                -0.01872173510491848,
                -0.015808839350938797,
                0.0017262877663597465,
                0.0013852439587935805,
                -0.040413256734609604,
                0.04229388386011124,
                0.06264640390872955,
                -0.020586403086781502,
                -0.015932586044073105,
                -0.05197202041745186,
                2.1832615857420024e-06,
                -0.01527625136077404,
                -0.006504145450890064,
                -0.013592438772320747,
                0.04878082126379013,
                0.01626635529100895,
                -0.0615556463599205,
                0.008143078535795212,
                -0.056745514273643494,
                0.024946922436356544,
                0.007893742062151432,
                -0.06861778348684311,
                0.09274902194738388,
                0.025713570415973663,
                -0.009463182650506496,
                -0.020580796524882317,
                0.00599247869104147,
                -0.08290664851665497,
                -0.01796695403754711,
                -0.057809676975011826,
                -0.01695200428366661,
                -0.003173834178596735,
                0.010529326274991035,
                -0.08136431127786636,
                0.007269714493304491,
                -0.028769206255674362,
                0.007989983074367046,
                -0.03628821298480034,
                0.0024747455026954412,
                -0.016622966155409813,
                -0.013441755436360836,
                0.03196131810545921,
                0.046810396015644073,
                -0.024823658168315887,
                -0.028239810839295387,
                0.06018895283341408,
                -0.013758102431893349,
                0.007913446985185146,
                -0.0036163171753287315,
                0.14570735394954681,
                -0.07905911654233932,
                0.05652396380901337,
                -0.03587646409869194,
                0.008615254424512386,
                -0.023814579471945763,
                0.0037680482491850853,
                0.05015289783477783,
                0.037616532295942307,
                -0.018283892422914505,
                0.03743553161621094,
                -0.03087807074189186,
                0.08973941951990128,
                0.018983159214258194,
                -0.026480114087462425,
                0.006006340030580759,
                0.000741176656447351,
                -0.02660709246993065,
                -0.0397341325879097,
                0.019154811277985573,
                0.04292367771267891,
                0.005116211250424385,
                -0.0328061506152153,
                -0.005688048433512449,
                -0.0003504809574224055,
                0.041999559849500656,
                -0.06943419575691223,
                -0.027478693053126335,
                0.04998551309108734,
                -0.0015871573705226183,
                0.04175766929984093,
                -0.012459428049623966,
                -0.005650233011692762,
                0.00828150287270546,
                -0.020494945347309113,
                -0.00578127708286047,
                0.008665516041219234,
                -0.006360062398016453,
                0.009278452955186367,
                0.012369513511657715,
                0.03084118850529194,
                -0.029915668070316315,
                0.02549763396382332,
                -0.015176976099610329,
                -0.008988721296191216,
                -0.0197326447814703,
                0.03361080586910248,
                -0.02533300220966339,
                0.01581623964011669,
                0.01122960913926363,
                0.04142506793141365,
                0.04272111877799034,
                0.04940872639417648,
                -0.0601673349738121,
                0.05187302827835083,
                -0.009046618826687336,
                -0.0029544394928961992,
                -0.05306251719594002,
                0.008468426764011383,
                -0.0281197689473629,
                0.00017157249385491014,
                -0.0016966391121968627,
                0.0574537068605423,
                -0.013020004145801067,
                -0.06916391104459763,
                -0.05838111788034439,
                0.0040298691019415855,
                0.05871838331222534,
                -0.054377231746912,
                -0.007371614687144756,
                0.027184881269931793,
                -0.00782613456249237,
                -0.013594862073659897,
                0.05039443075656891,
                -0.019035663455724716,
                -0.005836205091327429,
                -0.00508675305172801,
                0.03869045525789261,
                -0.012985330075025558,
                -0.01877761073410511,
                0.020038986578583717,
                0.08809194713830948,
                -0.006720596458762884,
                0.0353793129324913,
                -0.008334236219525337,
                -0.025975817814469337,
                0.018488863483071327,
                0.012345955707132816,
                0.01051819883286953,
                -0.06079861894249916,
                0.02656451053917408,
                -0.005702586378902197,
                0.05685748904943466,
                0.05409364774823189,
                0.017485374584794044,
                -0.06445284932851791,
                -0.06251499801874161,
                0.01609109714627266,
                -0.018382947891950607,
                0.02745530754327774,
                0.008772283792495728,
                -0.015109538100659847,
                0.06300122290849686,
                0.09956193715333939,
                0.012807684950530529,
                -0.01010602992027998,
                -0.0013026796514168382,
                0.04141460359096527,
                -0.13543274998664856,
                0.011308780871331692,
                0.004555846098810434,
                -0.016288038343191147,
                -0.008443545550107956,
                0.03135666251182556,
                0.045484405010938644,
                -0.07272478938102722,
                0.01057534758001566,
                0.037934694439172745,
                -0.0189372431486845,
                -0.05739356949925423,
                -0.0018535651033744216,
                -0.04618379473686218,
                0.017096830531954765,
                0.046351149678230286,
                -0.03280634060502052,
                0.030642764642834663,
                -0.007956323213875294,
                -0.06684347242116928,
                -0.05444725975394249,
                0.08852868527173996,
                0.016987059265375137,
                -0.06545964628458023,
                -0.03896263986825943,
                -0.03441833332180977,
                -0.010262314230203629,
                -0.0036827879957854748,
                -0.027944771572947502,
                -0.01750163547694683,
                -0.009515327401459217,
                0.027440404519438744,
                0.014926562085747719,
                -0.0002290255797561258,
                0.028817905113101006,
                0.03299972787499428,
                0.051816631108522415,
                -0.09531941264867783,
                0.00873526930809021,
                0.004962824285030365,
                0.023404544219374657,
                -0.05834471806883812,
                -0.0251657385379076,
                0.021902956068515778,
                -0.008329972624778748,
                0.019549813121557236,
                0.04005386680364609,
                -0.04003916680812836,
                0.008418608456850052,
                0.0010781966848298907,
                0.013211291283369064,
                0.008731926791369915,
                -0.004721973557025194,
                0.05726015567779541,
                0.04520164802670479,
                0.16226649284362793,
                0.05742081254720688,
                0.007601138669997454,
                0.0044947960413992405,
                -0.02465931698679924,
                0.014525826089084148,
                -0.013339314609766006,
                0.021309969946742058,
                0.07699678838253021,
                -0.0033926900941878557,
                0.049298398196697235,
                0.014673360623419285,
                -0.003363019088283181,
                0.00991819892078638,
                -0.013541820459067822,
                0.030803384259343147,
                0.04017229005694389,
                0.012158414348959923,
                0.02128666453063488,
                0.012069201096892357,
                -0.10325095802545547,
                0.04922078549861908,
                -0.0670640617609024,
                0.005430181045085192,
                0.01565173827111721,
                -0.001007308717817068,
                0.01214814092963934,
                -0.040567174553871155,
                0.0673343688249588,
                -0.00804100837558508,
                0.04152737185359001,
                0.04226883873343468,
                0.009189322590827942,
                -0.012221403419971466,
                0.022466842085123062,
                0.014178138226270676,
                0.003193996613845229,
                -0.022220419719815254,
                -0.003454981604591012,
                -0.008909729309380054,
                -0.04933920502662659,
                -0.06103842705488205,
                -0.009568247944116592,
                0.001540633151307702,
                0.02760760858654976,
                0.012515632435679436,
                -0.04952450469136238,
                0.002682305406779051,
                0.007025263272225857,
                0.02327735535800457,
                0.054820697754621506,
                0.047949787229299545,
                -0.0565933957695961,
                -0.012776872143149376,
                -0.018663041293621063,
                -0.011074423789978027,
                -0.026564128696918488,
                -0.0223797969520092,
                0.01658260077238083,
                0.026863181963562965,
                -0.04983312264084816,
                -0.018389521166682243,
                0.002780111273750663,
                0.02341545931994915,
                0.016324153169989586,
                0.03027496114373207,
                0.03466781601309776,
                -0.009506678208708763,
                0.006858752109110355,
                0.018587633967399597,
                0.0708814188838005,
                0.01064149010926485,
                0.03403039276599884,
                0.008066742680966854,
                0.0026092659682035446,
                0.0068882061168551445,
                0.010574545711278915,
                0.0320519357919693,
                0.012320572510361671,
                0.02283281460404396,
                -0.0008924501016736031,
                0.005908539053052664,
                -0.0025461444165557623,
                0.05923878401517868,
                0.01578536443412304,
                0.035830479115247726,
                -0.008407572284340858,
                -0.016963383182883263,
                -0.0033777777571231127,
                0.03153081610798836,
                -0.033194687217473984,
                -0.053281594067811966,
                -0.0556597076356411,
                -0.07363445311784744,
                0.004111970774829388,
                0.007411421276628971,
                -0.03439819812774658,
                0.01712471805512905,
                0.03074333816766739,
                -0.08135156333446503,
                -0.03119775466620922,
                -0.011266151443123817,
                0.025420373305678368,
                0.02276059240102768,
                -0.002676727483049035,
                -0.03391847014427185,
                0.0068176379427313805,
                -0.047199856489896774,
                0.052557315677404404,
                -0.018387574702501297,
                -0.10313767939805984,
                0.024208644405007362,
                -0.020775649696588516,
                0.0522720068693161,
                -0.032009050250053406,
                -0.0011837672209367156,
                -0.02519754134118557,
                -0.010148866102099419,
                -0.005092117935419083,
                0.07190210372209549,
                -0.008749694563448429,
                0.02401496097445488,
                -0.04670453071594238,
                -0.03582724928855896,
                -0.09489011764526367,
                -0.03319269046187401,
                0.03358497843146324,
                -0.04808440059423447,
                0.015382112003862858,
                0.011408267542719841,
                0.03146979957818985,
                -0.012402611784636974,
                -0.0260205939412117,
                -0.05198674649000168,
                0.0223990511149168,
                0.04547036066651344,
                0.0283444132655859,
                0.01382550597190857,
                0.008638612926006317,
                0.0539516806602478,
                0.03193191811442375,
                0.043520547449588776,
                0.035558708012104034,
                0.021180346608161926,
                0.05138188600540161,
                -0.03774501010775566,
                0.018890419974923134,
                0.002778806956484914,
                0.01953212358057499,
                0.009276865050196648,
                -0.04356932267546654,
                -0.034307848662137985,
                -0.036966875195503235,
                0.01919047348201275,
                0.005575852002948523,
                0.06740584969520569,
                -0.10219864547252655,
                0.030575377866625786,
                0.06809032708406448,
                -0.020273197442293167,
                -0.0014796290779486299,
                -0.00256621022708714,
                -0.016115644946694374,
                0.024888016283512115,
                0.016884488984942436,
                0.14744338393211365,
                0.003799379337579012,
                0.034519556909799576,
                -0.025430163368582726,
                0.014250049367547035,
                -0.0027248896658420563,
                0.031051695346832275,
                0.006287211086601019,
                -0.050434961915016174,
                -0.01811409555375576,
                -0.0623939149081707,
                0.002025492722168565,
                -0.016141047701239586,
                -0.0533096045255661,
                -0.0526503324508667,
                -0.06963898986577988,
                0.0068099359050393105,
                0.05474879965186119,
                0.017012976109981537,
                0.027026357129216194,
                0.024450119584798813,
                -0.027868319302797318,
                -0.02639414370059967,
                0.08204314112663269,
                -0.028407368808984756,
                0.01773720607161522,
                0.03132864460349083,
                0.022133082151412964,
                0.03900006413459778,
                -0.016083812341094017,
                0.0041841669008135796,
                -0.07028762996196747,
                0.035857293754816055,
                0.04343383386731148,
                -0.05040216073393822,
                -0.032773230224847794,
                0.008485022000968456,
                0.01253044418990612,
                -0.011163103394210339,
                -0.033555712550878525,
                0.01647825539112091,
                -0.02528071030974388,
                0.008528059348464012,
                -0.05312260612845421,
                -0.0266838688403368,
                -0.01758197322487831,
                -0.01989368535578251,
                -0.03220360726118088,
                0.03599730506539345,
                0.028304651379585266,
                0.041820306330919266,
                -0.041402414441108704,
                -0.031947098672389984,
                0.03582163527607918,
                -0.032865989953279495,
                0.02338964305818081,
                0.030494490638375282,
                0.014718671329319477,
                0.012378278188407421,
                0.02154652401804924,
                0.041910771280527115,
                -0.058478716760873795,
                0.002625071909278631,
                0.05504563823342323,
                0.0026159307453781366,
                0.09201278537511826,
                -0.10012497752904892,
                0.018539126962423325,
                -0.021442176774144173,
                -0.06331754475831985,
                -0.0013173343613743782,
                0.00967682059854269,
                0.019684994593262672,
                0.02791905775666237,
                0.006281488109380007,
                0.029778648167848587,
                0.013599852100014687,
                -0.013805543072521687,
                -0.004313324578106403,
                0.00892793107777834,
                0.046460457146167755,
                0.021822087466716766,
                -0.011795669794082642,
                -0.014212286099791527,
                0.029736274853348732,
                0.004263298586010933,
                0.007323318626731634,
                0.016715604811906815,
                0.01765964925289154,
                0.005791864357888699,
                -0.02289578504860401,
                0.005219820886850357,
                -0.06200188398361206,
                -0.022245967760682106,
                0.08507771790027618,
                -0.03335348144173622,
                -0.010598806664347649,
                -0.02454848401248455,
                0.01373465545475483,
                -0.038599830120801926,
                0.01529659517109394,
                0.02896895259618759,
                0.0017061269609257579,
                0.042958762496709824,
                0.02730756811797619,
                -0.0002909335307776928,
                -0.0040453351102769375,
                -0.01173335500061512,
                -0.0023666725028306246,
                -0.035724204033613205,
                0.06397143006324768,
                0.025561507791280746,
                -0.062460701912641525,
                -0.005397110246121883,
                0.08567532896995544,
                -0.024914344772696495,
                -0.05746825411915779,
                0.0009695731569081545,
                -0.02379280887544155,
                0.02290327660739422,
                0.008274740539491177,
                -0.00017500574176665395,
                -0.012574531137943268,
                0.006376730743795633,
                0.05542999505996704,
                -0.012783319689333439,
                -0.0008671254618093371,
                -0.032298438251018524,
                0.028520407155156136,
                0.021558064967393875,
                0.05224724858999252,
                -0.022964274510741234,
                0.016610750928521156,
                -0.052983008325099945,
                -0.026074105873703957,
                0.027831900864839554,
                -0.06593716889619827,
                -0.015987630933523178,
                -0.003917010501027107,
                -0.06232445687055588,
                -0.01214057020843029,
                0.03791888430714607,
                -0.04151665046811104,
                -0.01731307990849018,
                0.06494250893592834,
                -0.012084126472473145,
                0.019873302429914474,
                -0.022213561460375786,
                -0.0011667022481560707,
                -0.0002686088264454156,
                -0.0211956650018692,
                -0.044243160635232925,
                -0.05224117636680603,
                0.0010983740212395787,
                -8.015477042434338e-33,
                0.0003361077106092125,
                -0.005305122118443251,
                -0.004516368731856346,
                0.027921227738261223,
                -0.055252816528081894,
                0.039351385086774826,
                0.0038707919884473085,
                -0.005516748875379562,
                -0.03548837825655937,
                -0.04236738011240959,
                -0.036159370094537735,
                -0.0005014936905354261,
                0.030914518982172012,
                0.008811713196337223,
                -0.018135707825422287,
                0.0008944802102632821,
                0.0006656936020590365,
                -0.007718008942902088,
                -0.025693600997328758,
                -0.04159322753548622,
                -0.038876451551914215,
                -0.008378297090530396,
                -0.005876985844224691,
                0.028841964900493622,
                0.02019599825143814,
                0.01994212344288826,
                -0.0579330176115036,
                0.009855775162577629,
                -0.02615492418408394,
                -0.004342016763985157,
                -0.029835371300578117,
                0.02873997762799263,
                -0.015354952774941921,
                -0.01239161565899849,
                0.036373939365148544,
                -0.007007450796663761,
                0.012523172423243523,
                -0.032050710171461105,
                0.024990994483232498,
                -0.025959381833672523,
                -0.002093212679028511,
                -0.011945061385631561,
                -0.012124910950660706,
                0.001322795171290636,
                -0.019994167611002922,
                -0.02818438597023487,
                -0.02087068185210228,
                -0.024030936881899834,
                0.06545720249414444,
                0.027401557192206383,
                -0.013342923484742641,
                -0.006626532878726721,
                -0.012217468582093716,
                -0.029660038650035858,
                0.005826849490404129,
                0.02292393334209919,
                -0.004293721169233322,
                -0.04097682982683182,
                -0.008493470028042793,
                -0.03671662509441376,
                0.00930059514939785,
                -0.007112829014658928,
                -0.003394009545445442,
                -0.04066669940948486,
                0.000739328155759722,
                -0.015184388495981693,
                0.0007024177466519177,
                0.0341193862259388,
                -0.04597894474864006,
                0.01774502918124199,
                -0.03943616896867752,
                0.02792060375213623,
                -0.07232173532247543,
                0.009986701421439648,
                -0.06020714342594147,
                -0.055541448295116425,
                -0.080040343105793,
                0.04970620945096016,
                -0.00038452749140560627,
                0.05936083942651749,
                -0.0431574247777462,
                -0.03895537182688713,
                -0.02418689616024494,
                0.021887704730033875,
                -0.0574786439538002,
                0.03922734037041664,
                -0.03558215871453285,
                0.005328971426934004,
                -0.011216056533157825,
                -0.004731090273708105,
                -0.021869748830795288,
                0.007697452791035175,
                -0.055796775966882706,
                -0.009782054461538792,
                0.00942477211356163,
                -0.029753876850008965,
                -0.05451014265418053,
                0.01612040214240551,
                0.00035388898686505854,
                0.003830053610727191,
                -0.04783345386385918,
                0.00862448662519455,
                -0.035023968666791916,
                0.02813389152288437,
                -0.01941232569515705,
                -0.013178140856325626,
                0.013180297799408436,
                0.045312654227018356,
                -0.06659674644470215,
                0.03252948448061943,
                0.011916923336684704,
                -0.0029875882901251316,
                0.024218546226620674,
                0.04347260296344757,
                0.018751071766018867,
                0.018067143857479095,
                0.018737895414233208,
                -0.055704765021800995,
                -0.0010143095860257745,
                0.05607353895902634,
                0.0480140782892704,
                0.028663689270615578,
                -8.835335029289126e-05,
                0.05331556126475334,
                -0.08443285524845123,
                0.018743719905614853,
                0.00081572902854532,
                0.03424273803830147,
                -0.03272814303636551,
                -0.06809098273515701,
                0.02670702338218689,
                -0.0438416413962841,
                2.6907741812465247e-07,
                -0.03282923623919487,
                0.0035573989152908325,
                0.006771480664610863,
                0.04468328878283501,
                0.04338306188583374,
                0.02831917069852352,
                -0.0307900533080101,
                0.0606410838663578,
                -0.012288416735827923,
                0.11609987914562225,
                -0.005508286412805319,
                -0.04212357848882675,
                -0.0021588460076600313,
                -0.028306838124990463,
                0.02561071515083313,
                0.006810057908296585,
                -0.012714563868939877,
                -0.015135150402784348,
                -0.008289563469588757,
                -0.05737772583961487,
                -0.06413772702217102,
                0.06748311966657639,
                0.011599140241742134,
                -0.03656227886676788,
                -0.01018464658409357,
                -0.03071841225028038,
                0.052454520016908646,
                0.022360200062394142,
                -0.014783332124352455,
                -0.02581939660012722,
                -0.034502673894166946,
                0.02450648322701454,
                -0.04282313212752342,
                -0.05953245237469673,
                0.016145212575793266,
                -0.03141108155250549,
                0.031227316707372665,
                0.015814054757356644,
                0.027102923020720482,
                0.026278899982571602,
                0.0009435553220100701,
                0.01062031276524067,
                -0.015820736065506935,
                -0.02007284015417099,
                0.02887566201388836,
                0.02466505765914917,
                -0.0508313924074173,
                0.024282334372401237,
                -0.028622942045331,
                0.021205881610512733,
                0.01711999997496605,
                -0.003925832454115152,
                -0.0034299823455512524,
                0.02152012661099434,
                -0.02730490081012249,
                0.014512908644974232,
                0.03483552485704422,
                0.026501815766096115,
                0.02808588184416294,
                0.03657979518175125,
                0.013784065842628479,
                0.00905019324272871,
                -0.009289752691984177,
                -0.008752279914915562,
                0.03135837987065315,
                -0.053891371935606,
                0.014056272804737091,
                1.7577509115068854e-34,
                -0.03165913745760918,
                -0.0576004795730114,
                0.020594196394085884,
                0.02108852192759514,
                -0.046231355518102646,
                0.004767505917698145,
                -0.028399745002388954,
                -0.00936097465455532,
                -0.010817641392350197,
                0.01747576706111431,
                0.023198572918772697,
                -0.0009017098927870393,
                -0.0004469800041988492,
                0.019633540883660316,
                0.01935325190424919,
                0.037689097225666046,
                -0.025445453822612762,
                -0.009145732969045639,
                -0.04305119439959526,
                -0.03074815310537815,
                -0.00974121131002903,
                0.05057154968380928,
                -0.061606742441654205,
                0.02897614799439907,
                0.08221100270748138,
                0.03173891082406044,
                0.015047499909996986,
                0.01442550029605627,
                -0.003226812928915024,
                0.03551972284913063,
                0.013116557151079178,
                0.003609259380027652,
                0.01002208236604929,
                -0.03549434617161751,
                0.023534856736660004,
                0.05763018876314163,
                -0.00761796161532402,
                0.03452387452125549,
                -0.0438167080283165,
                0.02672281116247177,
                -0.024506494402885437,
                0.01473697368055582,
                0.03781456872820854,
                -0.020291564986109734,
                -0.03899434953927994,
                2.3524703465227503e-06,
                -0.015283007174730301,
                0.0731758400797844,
                -0.020529579371213913,
                0.012510043568909168,
                0.0603940486907959,
                0.0318363681435585,
                -0.0035386276431381702,
                -0.015053870156407356,
                0.007822946645319462,
                -0.03976655751466751,
                -0.046948954463005066,
                0.041313327848911285,
                -0.09593398869037628,
                -0.044062625616788864,
                0.009428411722183228,
                0.006043340545147657,
                -0.038322582840919495,
                -0.0408087819814682,
                0.005330152343958616,
                0.002959859324619174,
                0.02916417270898819,
                -0.016678139567375183,
                0.029192885383963585,
                0.040277935564517975,
                0.018739275634288788,
                -0.029710182920098305,
                0.039790939539670944,
                -0.030855068936944008,
                -0.041590236127376556,
                0.15178394317626953,
                0.04296259209513664,
                0.0016693425131961703,
                0.04463677480816841,
                0.021464169025421143,
                0.0018845839658752084,
                0.05531696602702141,
                0.014301693066954613,
                -0.0037879645824432373,
                0.11370735615491867,
                -0.022172365337610245,
                -0.035846833139657974,
                -0.00712422514334321,
                0.008531955070793629,
                -0.031017305329442024,
                -0.01215091161429882,
                -0.05268352851271629,
                0.007938334718346596,
                -0.009338649921119213,
                0.010332581587135792,
                -0.03737572208046913,
                -0.04254718869924545,
                -0.02911023423075676,
                -0.004620202351361513,
                0.0002787666453514248,
                -0.018049033358693123,
                -0.05923081189393997,
                -0.019749855622649193,
                0.0083412891253829,
                0.0032384414225816727,
                0.01715005189180374,
                -0.0017058486118912697,
                -0.034884974360466,
                -0.11500327289104462,
                0.007445682305842638,
                -0.009426292963325977,
                -0.008451169356703758,
                0.021471651270985603,
                0.03390723839402199,
                0.03306543454527855,
                -0.09648079425096512,
                0.0001057043336913921,
                -0.022333573549985886,
                -0.014011595398187637,
                -0.030510040000081062,
                -0.018444471061229706,
                0.02554890885949135,
                -0.022600041702389717,
                -0.01507022324949503,
                0.00453777052462101,
                0.010581293143332005,
                -0.04637547954916954,
                -0.0007230946212075651,
                0.0014841662487015128,
                0.006828222423791885,
                0.038314759731292725,
                -0.07096701860427856,
                0.03844426944851875,
                -0.001368371769785881,
                0.05675041675567627,
                0.033907268196344376,
                -0.001609097234904766,
                -0.03445539250969887,
                -0.0036350651644170284,
                -0.03982001170516014,
                0.09737084060907364,
                0.009814070537686348,
                0.06613505631685257,
                0.003707299241796136,
                0.018200939521193504,
                0.003041783580556512,
                0.019511563703417778,
                -0.06775595992803574,
                0.022417644038796425,
                0.05722370743751526,
                -0.07384654879570007,
                0.022090241312980652,
                0.014182076789438725,
                0.07189543545246124,
                -0.02620561048388481,
                0.031959328800439835,
                -0.015480529516935349,
                0.045732758939266205,
                -0.09920398890972137,
                0.007235914468765259,
                -0.04960528761148453,
                -0.012295132502913475,
                0.006775008514523506,
                -0.0400836244225502,
                0.003367906901985407,
                0.0009540770552121103,
                -0.04203496500849724,
                0.0036636306904256344,
                -0.0072985985316336155,
                -0.017817305400967598,
                0.0004182927659712732,
                0.01222988311201334,
                -0.03473256528377533,
                -0.0032808776013553143,
                -0.009080233052372932,
                0.05115508288145065,
                0.012124721892178059,
                0.007730529643595219,
                0.0008382452069781721,
                0.02572932094335556,
                -0.027366232126951218,
                0.012528261169791222,
                -0.035824574530124664,
                -0.016318438574671745,
                -0.05919096991419792,
                0.005188661627471447,
                0.050695959478616714,
                -0.03349355235695839,
                0.07660146802663803,
                -0.024916011840105057,
                -0.0684744268655777,
                -0.004974687937647104,
                -0.08366633206605911,
                0.024951033294200897,
                0.03338009864091873,
                -0.021857472136616707,
                0.03400006890296936,
                -0.007622852921485901,
                0.03604935109615326,
                -0.02091563120484352,
                -0.01861339621245861,
                0.03232480213046074,
                0.045919109135866165,
                -0.03815542906522751,
                0.024572506546974182,
                -0.02416948415338993,
                -0.0425330214202404,
                0.01047577615827322,
                0.028595492243766785,
                -0.046194467693567276,
                0.010455061681568623,
                -0.012788066640496254,
                0.023130258545279503,
                0.005692902021110058,
                0.02404683083295822,
                -0.0020422295201569796,
                -0.02172049507498741,
                -0.04400436207652092,
                -0.015344697050750256,
                0.04589090868830681,
                -0.008104389533400536,
                0.004489566665142775,
                0.038184236735105515,
                -0.028141390532255173,
                -0.024152761325240135,
                0.017879759892821312,
                -0.02744903415441513,
                0.042174313217401505,
                0.0073589151725173,
                -0.0757480338215828,
                0.009387676604092121,
                -0.03444359824061394,
                0.015424645505845547,
                0.047542817890644073,
                -0.00646966602653265,
                0.030406847596168518,
                -0.05893552675843239,
                -0.034141603857278824,
                -0.05768686532974243,
                -0.01231727097183466,
                -0.024144263938069344,
                -0.02693134732544422,
                0.0422050766646862,
                0.00939889531582594,
                -0.0125481141731143,
                0.0008235144196078181,
                -0.00714434077963233,
                0.03428112715482712,
                -0.029610605910420418,
                -0.014695543795824051,
                0.011822233907878399,
                -0.000489500118419528,
                -0.003324293065816164,
                0.024876292794942856,
                -0.000161653762916103,
                0.08898746222257614,
                0.0005881003453396261,
                -0.0216257032006979,
                0.0331060066819191,
                0.0358482226729393,
                -0.0036794831976294518,
                -0.022318493574857712,
                0.04188757389783859,
                -0.0020393142476677895,
                -0.032591160386800766,
                0.026783332228660583,
                -0.021298540756106377,
                -0.012219489552080631,
                0.029954267665743828,
                -0.0209210142493248,
                -0.031356003135442734,
                0.01679086685180664,
                -0.0777159258723259,
                0.014378439635038376,
                -0.01782539114356041,
                0.0819762647151947,
                0.043694332242012024,
                0.002760247327387333,
                0.06034116446971893,
                -0.03250619396567345,
                -0.039717886596918106,
                0.023213330656290054,
                -0.0047898609191179276,
                0.015888113528490067,
                0.007388267666101456,
                -0.01811574026942253,
                -0.010169519111514091,
                -0.0698554664850235,
                -0.02410326711833477,
                -0.011977951042354107,
                -0.035803887993097305,
                0.003722680266946554,
                -0.045529380440711975,
                -0.017592689022421837,
                0.033162686973810196,
                0.0003403508453629911,
                0.017611714079976082,
                0.0224322360008955,
                -0.060965411365032196,
                -0.11406852304935455,
                0.019664594903588295,
                -0.07478464394807816,
                0.02959512546658516,
                -0.023648995906114578,
                0.02133389189839363,
                0.03217943385243416,
                0.0008346977410838008,
                0.009691598825156689,
                0.041438356041908264,
                -0.008297037333250046,
                -0.0008239107555709779,
                0.0012489041546359658,
                0.014269703067839146,
                0.01601770892739296,
                0.0059034572914242744,
                0.016118256375193596,
                0.01206162478774786,
                -0.04769410192966461,
                0.025549495592713356,
                0.00986215565353632,
                -0.009394891560077667,
                0.052601661533117294,
                0.05693543702363968,
                -0.0006668640999123454,
                -0.046892572194337845,
                0.05867810919880867,
                0.008261100389063358,
                -0.043951164931058884,
                -0.05673903971910477,
                -0.006202818360179663,
                0.02297031134366989,
                0.001110105193220079,
                0.0203256793320179,
                -0.0008998483535833657,
                0.023341918364167213,
                -0.015370036475360394,
                0.024053430184721947,
                -0.03722425550222397,
                0.024691065773367882,
                -0.012718324549496174,
                -0.047146886587142944,
                0.04790908470749855,
                -0.017604166641831398,
                -0.029385576024651527,
                0.01101269107311964,
                -0.0011552401119843125,
                0.015733810141682625,
                -0.01960775814950466,
                -0.07441939413547516,
                -0.039437972009181976,
                -0.0004945634864270687,
                -0.015877222642302513,
                -0.05349532142281532,
                0.014269569888710976,
                4.9086433136835694e-05,
                -0.09654662758111954,
                -0.01797095499932766,
                0.002847990021109581,
                -0.027425263077020645,
                -0.04051871597766876,
                -0.05565445125102997,
                0.01709490828216076,
                -0.03607834875583649,
                0.00807837676256895,
                -0.016079869121313095,
                -0.002191876992583275,
                0.005021070130169392,
                0.021011535078287125,
                -0.01744624599814415,
                -0.032070569694042206,
                0.0351879857480526,
                -0.010563033632934093,
                0.01943828910589218,
                0.01052126195281744,
                -0.04113047569990158,
                -0.02167094126343727,
                0.03577661141753197,
                0.07316261529922485,
                0.029317308217287064,
                -0.04667108878493309,
                -0.01761605776846409,
                0.03723851591348648,
                0.04780229553580284,
                -0.0902833640575409,
                0.01615697704255581,
                0.05157940089702606,
                0.11073444038629532,
                0.011501035653054714,
                0.015392538160085678,
                0.09141620248556137,
                0.003750857897102833,
                0.0037312947679311037,
                -0.09403085708618164,
                -0.050206247717142105,
                -0.016489332541823387,
                -0.04269661381840706,
                0.025646202266216278,
                -0.012670277617871761,
                -0.0031193497125059366,
                -0.023690294474363327,
                0.0722947046160698,
                0.002325376495718956,
                -0.11572156101465225,
                -0.02439529076218605,
                -0.013581317849457264,
                -0.0033869885373860598,
                0.003464876674115658,
                -0.0295322947204113,
                0.021982982754707336,
                -0.026371149346232414,
                0.02572566270828247,
                -0.014916596002876759,
                0.02599273808300495,
                0.011958017013967037,
                0.031137028709053993,
                0.027333466336131096,
                -0.01031284686177969,
                0.03574564307928085,
                0.0020740837790071964,
                0.025872835889458656,
                -0.06728631258010864,
                0.08025284856557846,
                -0.016231587156653404,
                0.0053034452721476555,
                0.013537994585931301,
                -0.019950255751609802,
                -0.07041928917169571,
                -0.02725987508893013,
                -0.049616750329732895,
                0.05321614071726799,
                -0.014086294919252396,
                0.07077441364526749,
                0.027326442301273346,
                0.034533023834228516,
                0.008049416355788708,
                0.01668885536491871,
                -0.00274292123503983,
                0.06586765497922897,
                -0.004126307554543018,
                -0.02006433717906475,
                -0.005285827908664942,
                -0.0013566429261118174,
                -0.038283515721559525,
                -0.018866010010242462,
                0.007047581020742655,
                0.027925871312618256,
                -0.06934710592031479,
                0.0041294400580227375,
                0.04884367063641548,
                0.04320360720157623,
                0.031075434759259224,
                -0.06604131311178207,
                0.015419370494782925,
                -0.044074367731809616,
                -0.03067796304821968,
                0.07054094970226288,
                -0.014905148185789585,
                -0.029627136886119843,
                -0.0017609067726880312,
                -0.05320364609360695,
                -0.015952210873365402,
                0.024385033175349236,
                -0.026401735842227936,
                0.005905864294618368,
                0.004269452299922705,
                0.03337602689862251,
                -0.004552187863737345,
                0.019092287868261337,
                0.02618112973868847,
                0.026142535731196404,
                0.031197439879179,
                0.013805652968585491,
                0.034603964537382126,
                0.025055354461073875,
                0.006172794383019209,
                -0.0008855501655489206,
                -0.046670664101839066,
                -0.06372090429067612,
                0.010990401729941368,
                0.03463411331176758,
                0.0017244757618755102,
                -0.06730524450540543,
                0.024965576827526093,
                -0.026990747079253197,
                0.041450273245573044,
                -0.0024866049643605947,
                -0.01919013261795044,
                -0.023027487099170685,
                0.03213966637849808,
                0.026498356834053993,
                -0.013753517530858517,
                0.05763332545757294,
                -0.011267758905887604,
                -0.019472042098641396,
                -0.08159220218658447,
                -0.01996857300400734,
                0.005037156865000725,
                0.039337556809186935,
                0.01271054521203041,
                0.018012354150414467,
                -0.015560006722807884,
                0.008192498236894608,
                -0.00742089468985796,
                -0.016884155571460724,
                0.06278765201568604,
                -0.013198984786868095,
                0.027449361979961395,
                -0.047418780624866486,
                0.02102631889283657,
                0.07900549471378326,
                -0.014275109395384789,
                0.030664656311273575,
                0.011445930227637291,
                -0.019725149497389793,
                0.00902730505913496,
                0.003135344246402383,
                0.05721847712993622,
                0.001881180563941598,
                -0.04955880716443062,
                0.017468057572841644,
                -0.011458885855972767,
                0.005246584769338369,
                0.0611579529941082,
                -0.0863194540143013,
                0.026920465752482414,
                -0.007830738089978695,
                0.024088528007268906,
                0.0032742631155997515,
                0.0033718834165483713,
                -0.034093961119651794,
                -0.013786070980131626,
                0.02319234050810337,
                0.0013523398665711284,
                0.10204924643039703,
                0.030351275578141212,
                0.02112908847630024,
                0.0029568152967840433,
                -0.0016467869281768799,
                -0.03026619926095009,
                0.01779886707663536,
                0.009861691854894161,
                -0.00013112500892020762,
                0.018115587532520294,
                0.0034037120640277863,
                -0.059076372534036636,
                0.0010117827914655209,
                0.008209883235394955,
                -0.03101600892841816,
                -0.0027668119873851538,
                -0.039043404161930084,
                -0.011469434015452862,
                -0.027966849505901337,
                0.02309926226735115,
                0.020546577870845795,
                -0.04416204243898392,
                0.0670715719461441,
                0.030858634039759636,
                -0.05207770690321922,
                0.006912565790116787,
                -0.03925343602895737,
                0.047543227672576904,
                -0.026066603139042854,
                0.06515875458717346,
                0.004503167700022459,
                -0.0715763196349144,
                -0.04591931030154228,
                -8.436928501402591e-33,
                0.007090022787451744,
                -0.010955914855003357,
                0.02462078630924225,
                -0.04030701518058777,
                -0.06861473619937897,
                -0.016888530924916267,
                -0.008271986618638039,
                0.04244917631149292,
                -0.05339789018034935,
                0.029350586235523224,
                0.010083680041134357,
                -0.015397393144667149,
                0.028018293902277946,
                -0.010461712256073952,
                0.013492108322679996,
                -0.03407517820596695,
                0.00843187514692545,
                -0.022282199934124947,
                -0.03353177011013031,
                -0.011465810239315033,
                0.017038950696587563,
                0.011623172089457512,
                0.045972082763910294,
                0.06252606958150864,
                0.020633842796087265,
                -0.05973174795508385,
                -0.022388814017176628,
                -0.0013290697243064642,
                0.04512284696102142,
                0.012415706180036068,
                -0.061896126717329025,
                0.0023804057855159044,
                0.0024358988739550114,
                -0.029173191636800766,
                0.020941194146871567,
                0.04061500355601311,
                -0.0028244422283023596,
                -0.04230755195021629,
                -0.005180311389267445,
                -0.017841722816228867,
                -0.08027887344360352,
                0.06641790270805359,
                -0.0013201667461544275,
                0.01611289009451866,
                0.0012752169277518988,
                0.0228014774620533,
                0.0073209344409406185,
                -0.005607995670288801,
                -0.05120229721069336,
                -0.066408671438694,
                -0.03031015768647194,
                0.014345899224281311,
                -0.0016303877346217632,
                -0.07259412109851837,
                -0.018444141373038292,
                0.012416261248290539,
                0.019969986751675606,
                -0.0005818771896883845,
                0.010912185534834862,
                -0.010140016674995422,
                -0.00264747510664165,
                -0.03169231489300728,
                -0.004363791085779667,
                -0.023890234529972076,
                -0.007297162897884846,
                -0.004590391647070646,
                -0.03252294287085533,
                -0.009462700225412846,
                0.0066964589059352875,
                0.04533176124095917,
                -0.008487438783049583,
                -0.002123739803209901,
                -0.029660724103450775,
                -0.01963881403207779,
                -0.04512672871351242,
                -0.00833806861191988,
                0.013261867687106133,
                -0.02609880454838276,
                -0.034400444477796555,
                -0.04926146939396858,
                -0.04224875569343567,
                0.015267610549926758,
                -0.03783944249153137,
                -0.002955751260742545,
                -0.00890316627919674,
                0.05513981357216835,
                0.010701561346650124,
                0.04279520362615585,
                0.017324332147836685,
                -0.00022882834309712052,
                -0.030621593818068504,
                0.02042073756456375,
                0.019866198301315308,
                0.004823525436222553,
                0.03135436400771141,
                -0.019715839996933937,
                0.004937733057886362,
                0.011157822795212269,
                -0.015171041712164879,
                -0.0037220462691038847,
                0.05859636515378952,
                0.052853912115097046,
                0.011240864172577858,
                0.01099814847111702,
                -0.0031532112043350935,
                0.014628264121711254,
                0.09512526541948318,
                -0.0012498561991378665,
                -0.06012943759560585,
                0.01825428381562233,
                -0.02751612477004528,
                -0.006314190570265055,
                0.044432878494262695,
                0.020310647785663605,
                0.01291977521032095,
                0.005207093432545662,
                -0.007401641458272934,
                0.023414960131049156,
                0.013355114497244358,
                0.02770843543112278,
                0.0034423975739628077,
                0.04246598854660988,
                -0.0035988634917885065,
                -0.027071433141827583,
                -0.05765632539987564,
                0.0009444940369576216,
                0.030741380527615547,
                -0.028148530051112175,
                -0.08200690895318985,
                -0.018151666969060898,
                -0.009001045487821102,
                -0.05677495524287224,
                3.0720178756382666e-07,
                0.08459203690290451,
                0.005053864326328039,
                -0.031701259315013885,
                -0.05356186628341675,
                0.026844654232263565,
                0.030233168974518776,
                -0.019099095836281776,
                0.05637560039758682,
                -0.037670720368623734,
                -0.00028597639175131917,
                0.0038953411858528852,
                0.055850621312856674,
                0.0015505641931667924,
                0.001245224615558982,
                0.0133324284106493,
                -0.016302339732646942,
                0.00035283443867228925,
                0.05548456683754921,
                0.010839054360985756,
                -0.09028151631355286,
                0.05726763233542442,
                -0.00975813902914524,
                -0.03367123752832413,
                -0.039009347558021545,
                -0.015191836282610893,
                -0.05774560943245888,
                0.0215948186814785,
                0.03319268301129341,
                0.06024469807744026,
                -0.014676406979560852,
                0.043963201344013214,
                0.038315072655677795,
                -0.00479097431525588,
                0.04988524317741394,
                0.001714964397251606,
                -0.079661525785923,
                -0.000549468444660306,
                0.0052057295106351376,
                -0.05925655737519264,
                0.043864235281944275,
                -0.006798757240176201,
                -0.01761063002049923,
                0.013344165869057178,
                0.016340719535946846,
                0.01495183166116476,
                0.05319131538271904,
                -0.006205283105373383,
                0.08672977983951569,
                -0.12364302575588226,
                0.015847688540816307,
                0.01634739339351654,
                -0.014758980832993984,
                -0.008225550875067711,
                0.06469618529081345,
                0.0015422713477164507,
                0.0009804393630474806,
                -0.0019141888478770852,
                -0.016446460038423538,
                -0.02064417488873005,
                -0.07186815142631531,
                0.024126151576638222,
                0.029931386932730675,
                0.04534659907221794,
                -0.006874867249280214,
                0.030056258663535118,
                -0.005501102190464735,
                -0.02038654126226902,
                2.345364236682379e-34,
                0.03688671067357063,
                -0.0010715785901993513,
                0.010445707477629185,
                0.045071788132190704,
                -0.009588880464434624,
                -0.055108483880758286,
                0.07406266778707504,
                0.02567462809383869,
                0.057013776153326035,
                -0.0025314672384411097,
                -0.02863118052482605,
                -0.023220181465148926,
                -0.07484729588031769,
                0.013364999555051327,
                -0.006817144341766834,
                -0.00771767133846879,
                0.003759176703169942,
                0.0036390870809555054,
                0.00795235950499773,
                -0.032449472695589066,
                0.03999451920390129,
                0.03397165611386299,
                -0.057053614407777786,
                0.05714709684252739,
                0.13881033658981323,
                -0.0382329598069191,
                -0.005972166545689106,
                0.00215151091106236,
                0.05634698271751404,
                -0.06160788610577583,
                -0.0006153631256893277,
                -0.011999308131635189,
                -0.01148377824574709,
                -0.006119263358414173,
                -0.006719760596752167,
                -0.11353223025798798,
                -0.01815166138112545,
                -0.0024109373334795237,
                -0.031442806124687195,
                -0.07297322899103165,
                0.06739751994609833,
                -0.006691962946206331,
                0.0305220615118742,
                0.09159798920154572,
                0.05535901337862015,
                1.775684381755127e-06,
                -0.06576860696077347,
                -0.035525087267160416,
                -0.007369915023446083,
                -0.05235236510634422,
                0.05471046268939972,
                0.07938583940267563,
                0.08056151866912842,
                -0.001466901507228613,
                -0.028174910694360733,
                0.015211924910545349,
                -0.09604109078645706,
                0.044463224709033966,
                0.012012028135359287,
                0.002540059620514512,
                0.004819091409444809,
                -0.0013258176622912288,
                -0.09841015934944153,
                0.02671593241393566,
                0.024473223835229874,
                0.006321091670542955,
                -0.04684009402990341,
                0.002323528053238988,
                -0.004229804966598749,
                0.008016741834580898,
                -0.0330844447016716,
                -0.008525069802999496,
                -0.022556573152542114,
                -0.00755928922444582,
                -0.0012473270762711763,
                0.003811470465734601,
                0.017416710034012794,
                -0.017923040315508842,
                -0.037556085735559464,
                0.013399790041148663,
                0.010809332132339478,
                0.06437384337186813,
                0.017927203327417374,
                -0.02344360016286373,
                0.054634761065244675,
                -0.11199972033500671,
                -0.01833130419254303,
                0.006279568187892437,
                -0.051478248089551926,
                -0.031757425516843796,
                -0.02276294119656086,
                -0.0038358133751899004,
                -0.050763681530952454,
                0.02511935494840145,
                0.014427357353270054,
                -0.014031041413545609,
                0.004677244927734137,
                -0.020539699122309685,
                -0.00117232627235353,
                -0.01967940293252468,
                -0.012473165057599545,
                -0.007305850274860859,
                -0.013069716282188892,
                0.0018367047887295485,
                0.014450713992118835,
                -0.010908499360084534,
                0.024129647761583328,
                0.005526750814169645,
                -0.008761128410696983,
                -0.03964167833328247,
                -0.031784288585186005,
                -0.03174591436982155,
                -0.01064389944076538,
                0.03596837818622589,
                -0.006066047120839357,
                -0.035664692521095276,
                -0.013582486659288406,
                -0.016328390687704086,
                -0.01742452010512352,
                -0.009667078964412212,
                0.0018480572616681457,
                0.009055116213858128,
                -0.019031601026654243,
                -0.05114611238241196,
                0.08133962005376816,
                0.01875380426645279,
                -0.043444156646728516,
                -0.001905821613036096,
                -0.033720046281814575,
                0.003972955513745546,
                0.06396660208702087,
                0.028433846309781075,
                0.015867114067077637,
                0.055025603622198105,
                -0.015155789442360401,
                -0.009184998460114002,
                0.008672354742884636,
                0.05047634243965149,
                -0.04056274890899658,
                0.004654689226299524,
                -0.03080175630748272,
                -0.014955480583012104,
                -0.019057266414165497,
                0.008630562573671341,
                -0.003149437252432108,
                -0.025604866445064545,
                0.052781444042921066,
                -0.009510798379778862,
                -0.008071154356002808,
                -0.04367111623287201,
                0.020993389189243317,
                0.00818171352148056,
                0.04511506110429764,
                -0.007147436495870352,
                0.023018764331936836,
                0.023174770176410675,
                0.022554371505975723,
                -0.009426986798644066,
                0.05617138370871544,
                0.015657640993595123,
                0.052787914872169495,
                0.035958174616098404,
                -0.013017558492720127,
                -0.052382323890924454,
                -0.022045593708753586,
                0.03671437129378319,
                -0.03560001403093338,
                0.017357828095555305,
                -0.02130410075187683,
                -0.027407290413975716,
                0.02226310595870018,
                0.052251700311899185,
                0.05747578293085098,
                -0.08372334390878677,
                0.028434548527002335,
                -0.04166553169488907,
                0.03035060502588749,
                -0.055218733847141266,
                -0.03451727703213692,
                -0.04219325631856918,
                -0.07593678683042526,
                -0.010417056269943714,
                -0.026919733732938766,
                0.03249229118227959,
                0.011510107666254044,
                0.008239061571657658,
                -0.02947881631553173,
                0.010431194677948952,
                0.01621180586516857,
                -0.017802579328417778,
                0.011817645281553268,
                -0.01405911985784769,
                -0.14807038009166718,
                0.026488497853279114,
                -0.02276039868593216,
                -0.028997929766774178,
                0.03754539415240288,
                -0.0016616758657619357,
                -0.01437609363347292,
                -0.007263267878443003,
                -0.009858783334493637,
                -0.007757007610052824,
                -0.05258692428469658,
                -0.017881006002426147,
                0.049812525510787964,
                0.035031963139772415,
                -0.010179081000387669,
                0.013924290426075459,
                -0.017269020900130272,
                -0.041888799518346786,
                0.004744061268866062,
                -0.005181498359888792,
                -0.05770222842693329,
                0.08089355379343033,
                0.058129798620939255,
                -0.037138015031814575,
                0.0025554634630680084,
                -0.027893630787730217,
                -0.03214111179113388,
                -0.002169425832107663,
                0.009324485436081886,
                0.0519852414727211,
                0.019618334248661995,
                0.04279625788331032,
                -0.0050379568710923195,
                -0.022887248545885086,
                0.03792271763086319,
                0.02707703784108162,
                0.04715434089303017,
                -0.0789993479847908,
                -0.009973009116947651,
                -0.008940720930695534,
                0.04236876964569092,
                -0.028069881722331047,
                -0.013897315599024296,
                0.025199996307492256,
                0.005000588484108448,
                0.006432796828448772,
                -0.024252519011497498,
                -0.04448032006621361,
                -0.03696039319038391,
                0.021051906049251556,
                0.033651042729616165,
                0.01335749588906765,
                0.04974869638681412,
                0.05536215007305145,
                -0.022363558411598206,
                0.04769124835729599,
                0.025310449302196503,
                0.00019714930385816842,
                0.016054579988121986,
                -0.05413071811199188,
                0.025768209248781204,
                -0.02711208537220955,
                0.02590193971991539,
                0.014094432815909386,
                -0.01580837182700634,
                -0.05722738429903984,
                0.007753346115350723,
                0.0007601912948302925,
                0.003984586335718632,
                0.006683896761387587,
                -0.015410471707582474,
                -0.019564252346754074,
                0.029646413400769234,
                0.041214220225811005,
                -0.03946083039045334,
                -0.09433076530694962,
                0.03648649901151657,
                -0.05794641003012657,
                -0.024477878585457802,
                -0.021737603470683098,
                -0.03532560169696808,
                0.003922535106539726,
                -0.06472182273864746,
                0.06928646564483643,
                0.022954270243644714,
                0.023867208510637283,
                0.02888735942542553,
                0.005844324827194214,
                0.008125673979520798,
                -0.05363127216696739,
                0.05421823635697365,
                0.010409083217382431,
                0.008431089110672474,
                0.006927729118615389,
                -0.0041397917084395885,
                0.004281612113118172,
                -0.00925291795283556,
                -0.029040208086371422,
                -0.01976453699171543,
                0.0010024933144450188,
                0.021001508459448814,
                0.021054821088910103,
                -0.012170983478426933,
                -0.024325521662831306,
                0.024099096655845642,
                -0.025564085692167282,
                -0.04130230471491814,
                -0.012439875863492489,
                0.0428026020526886,
                -0.09676176309585571,
                -0.0046007223427295685,
                0.05666409432888031,
                -0.00536356121301651,
                0.01664506085216999,
                0.03855963796377182,
                -0.04744448885321617,
                0.0065660979598760605,
                0.016862215474247932,
                -0.007951338775455952,
                -0.005996417719870806,
                0.0229810681194067,
                -0.0017322424100711942,
                -0.04455723986029625,
                -0.021475091576576233,
                -0.022433605045080185,
                -0.03284457325935364,
                0.03978373855352402,
                0.06169651821255684,
                -0.028150657191872597,
                0.04819798097014427,
                0.00468843337148428,
                -0.0013026553206145763,
                0.027058245614171028,
                -0.04271700605750084,
                0.07386381924152374,
                0.04895751178264618,
                -0.002446643775328994,
                -0.04147819057106972,
                0.02247024141252041,
                -0.02164102904498577,
                0.03425795957446098,
                -0.007352521643042564,
                -0.001940768794156611,
                -0.01569196954369545,
                0.07932664453983307,
                0.02416297048330307,
                0.01720828376710415,
                -0.034724295139312744,
                0.02313365787267685,
                -0.01230274885892868,
                -0.004640392493456602,
                -0.008129283785820007,
                0.06769461929798126,
                0.014282690361142159,
                0.035201430320739746,
                -0.05195837467908859,
                0.013435774482786655,
                0.03292928263545036,
                0.022902389988303185,
                -0.0494953952729702,
                0.007909443229436874,
                0.03519676998257637,
                -0.0848500207066536,
                0.009268599562346935,
                0.027825335040688515,
                -0.03747484087944031,
                0.02372293919324875,
                -0.04372017830610275,
                -0.0024988404475152493,
                0.02020581252872944,
                0.03985954448580742,
                0.018665224313735962,
                0.014049039222300053,
                -0.01306217722594738,
                0.01844075694680214,
                -0.016514575108885765,
                -0.03978706896305084,
                0.042706143110990524,
                -0.04369926452636719,
                -0.03690594434738159,
                -0.019953666254878044,
                0.028664477169513702,
                0.009265357628464699,
                -0.029469633474946022,
                0.044498689472675323,
                0.0037527706008404493,
                0.025828147307038307,
                -0.03475254401564598,
                -0.043579332530498505,
                0.05608825013041496,
                0.040520939975976944,
                -0.010048174299299717,
                0.02810382843017578,
                -0.041130296885967255,
                0.01012010034173727,
                -0.005411626771092415,
                0.024975698441267014,
                -0.008666172623634338,
                0.0698026493191719,
                -0.01408111397176981,
                0.011813957244157791,
                0.02395857684314251,
                0.04929228127002716,
                -0.002471130108460784,
                -0.0072746737860143185,
                0.011540054343640804,
                0.03544244170188904,
                -0.003807139117270708,
                0.017966968938708305,
                0.021718475967645645,
                -0.042529601603746414,
                -0.016367971897125244,
                0.05074538663029671,
                -0.014788966625928879,
                -0.031031174585223198,
                0.015890857204794884,
                -0.015732530504465103,
                0.026369594037532806,
                0.03438232094049454,
                0.031078359112143517,
                0.061722464859485626,
                -0.001073455554433167,
                -0.06598794460296631,
                0.008336085826158524,
                0.031038064509630203,
                0.027954095974564552,
                -0.04006205126643181,
                -0.0008361903019249439,
                -0.03344277665019035,
                -0.006411947775632143,
                -0.022706396877765656,
                0.012941722758114338,
                -0.007178603205829859,
                -0.009574473835527897,
                -0.05526845157146454,
                -0.004508859943598509,
                0.0057176388800144196,
                -0.05171067267656326,
                0.07473879307508469,
                0.018980665132403374,
                0.012851793318986893,
                -0.05671652406454086,
                0.0333150178194046,
                0.050949446856975555,
                0.015366332605481148,
                0.02768368273973465,
                0.031681597232818604,
                0.0023141473066061735,
                0.0390615276992321,
                0.005132217891514301,
                0.010581539943814278,
                -0.05146615952253342,
                0.016363177448511124,
                -0.03408631309866905,
                -0.034603916108608246,
                0.011862467043101788,
                -0.0026804690714925528,
                0.027740851044654846,
                0.02706398256123066,
                0.037863314151763916,
                -0.007279078010469675,
                -0.026334315538406372,
                -0.013655565679073334,
                -0.011195030994713306,
                -0.044414013624191284,
                0.000376894255168736,
                -0.04243039712309837,
                -0.04743465408682823,
                0.04272234067320824,
                0.017799200490117073,
                -0.058312851935625076,
                0.024740420281887054,
                -0.04778779298067093,
                0.029419410973787308,
                -0.06251436471939087,
                0.07380027323961258,
                0.04468976706266403,
                -0.004491071216762066,
                0.01522485725581646,
                0.0372406430542469,
                0.02541307732462883,
                -0.0975731611251831,
                -0.023350877687335014,
                0.041927535086870193,
                -0.07931002229452133,
                0.030965976417064667,
                -0.030717933550477028,
                -0.033833060413599014,
                0.02620149403810501,
                -0.05159123241901398,
                -0.02115054987370968,
                0.011797899380326271,
                0.03679182752966881,
                -0.017945773899555206,
                0.018234165385365486,
                -0.01577608287334442,
                0.04155845195055008,
                -0.021946480497717857,
                0.010167166590690613,
                -0.018632063642144203,
                0.04154780134558678,
                -0.016814174130558968,
                -0.04042712226510048,
                0.04069482535123825,
                -0.03807659074664116,
                0.01764082722365856,
                -0.0046064769849181175,
                0.03266395628452301,
                0.027903128415346146,
                -0.009330476634204388,
                -0.024690337479114532,
                -0.025387439876794815,
                -0.045012105256319046,
                0.015188091434538364,
                0.053832683712244034,
                0.06370174139738083,
                -0.06633111089468002,
                -0.015876103192567825,
                -0.05007120594382286,
                -0.037783656269311905,
                -0.003909305203706026,
                -0.02853544056415558,
                0.015797501429915428,
                0.023268267512321472,
                -0.02115369401872158,
                -0.02853100374341011,
                0.05343037471175194,
                -0.001624033902771771,
                0.028283312916755676,
                -0.06444002687931061,
                0.04392427206039429,
                0.006636311300098896,
                0.010828621685504913,
                -0.04980278015136719,
                0.025152653455734253,
                0.048720583319664,
                -0.09444454312324524,
                -0.05419398471713066,
                0.049263328313827515,
                0.04497047886252403,
                -0.010133879259228706,
                0.024373723194003105,
                -0.0027528423815965652,
                -0.04933897778391838,
                0.03021671250462532,
                0.038183506578207016,
                -0.037143126130104065,
                0.05522781237959862,
                0.004802299197763205,
                -0.01898629032075405,
                -0.031097043305635452,
                0.04297379404306412,
                -0.006251398008316755,
                -0.009442933835089207,
                0.06838852912187576,
                0.004091564100235701,
                0.02545834891498089,
                0.004476346541196108,
                0.03518027812242508,
                -0.04153424873948097,
                -0.06134440749883652,
                0.06579891592264175,
                -0.009967898949980736,
                -0.012990733608603477,
                0.04753439873456955,
                0.04030589386820793,
                0.02025843970477581,
                -0.018192928284406662,
                -0.04244735836982727,
                0.039459094405174255,
                0.046138375997543335,
                -0.05251087620854378,
                -0.022538067772984505,
                -0.07969280332326889,
                -5.8930283198186166e-33,
                0.013263922184705734,
                0.023080386221408844,
                -0.0038417528849095106,
                0.04512287303805351,
                -0.0423336960375309,
                -0.015616586431860924,
                0.02554526925086975,
                -0.008413111791014671,
                -0.01592263951897621,
                -0.004590444266796112,
                -0.039379362016916275,
                -0.03550747036933899,
                0.024272851645946503,
                0.06257062405347824,
                -0.02558152563869953,
                0.017736993730068207,
                0.03638405352830887,
                -0.04051846265792847,
                0.03333045169711113,
                -0.07775959372520447,
                0.039723724126815796,
                0.0015640340279787779,
                0.04023383557796478,
                0.040497612208127975,
                -0.06806938350200653,
                -0.0015859389677643776,
                0.03911838307976723,
                0.022223426029086113,
                0.016337741166353226,
                0.006996820215135813,
                0.05123593658208847,
                0.03827904909849167,
                -0.010653343982994556,
                -0.0005456946673803031,
                0.04038441926240921,
                0.0158084686845541,
                -0.04034353792667389,
                0.014620799571275711,
                0.03686985746026039,
                0.042077332735061646,
                0.04771966487169266,
                -0.031508442014455795,
                0.016439180821180344,
                -0.026847830042243004,
                -0.006954052485525608,
                -0.03174569830298424,
                0.01591474935412407,
                -0.05578633025288582,
                -0.049660176038742065,
                -0.014065446332097054,
                0.0025246513541787863,
                0.015616532415151596,
                0.000841445114929229,
                -0.0005078897811472416,
                -0.04048870503902435,
                0.01907576061785221,
                -0.023346036672592163,
                -0.10493882745504379,
                0.03102477826178074,
                -0.014565370045602322,
                -0.057520076632499695,
                0.06341563910245895,
                -0.031050726771354675,
                0.08212785422801971,
                0.031077083200216293,
                -0.021189512684941292,
                0.04338827729225159,
                0.018623417243361473,
                -0.03500460088253021,
                -0.021734438836574554,
                0.03839048743247986,
                0.04985669627785683,
                -0.00034345098538324237,
                0.02651679515838623,
                -0.007765514776110649,
                -0.05236097052693367,
                -0.0422932505607605,
                -0.00031493749702349305,
                0.03650475665926933,
                0.0035981545224785805,
                0.018463648855686188,
                -0.06004355102777481,
                0.00613598944619298,
                0.025231780484318733,
                -0.07629504054784775,
                -0.01471717283129692,
                -0.03878705948591232,
                -0.05304957553744316,
                -0.0037049006205052137,
                0.018459908664226532,
                0.014203641563653946,
                0.017204925417900085,
                0.03112076036632061,
                0.011017478071153164,
                0.0124762998893857,
                -0.05078493058681488,
                0.03641218692064285,
                -0.0055506592616438866,
                0.012642663903534412,
                -0.03667657822370529,
                0.001322601456195116,
                -0.04264596477150917,
                -0.03036193922162056,
                0.09586341679096222,
                -0.03861008211970329,
                0.009065849706530571,
                -0.03091299533843994,
                -0.006170541979372501,
                0.0067879268899559975,
                0.010011478327214718,
                -0.02112507075071335,
                0.029694030061364174,
                -0.002557635074481368,
                0.041026052087545395,
                0.07414086908102036,
                -0.0013274179073050618,
                -0.020890839397907257,
                -0.042700041085481644,
                -0.04933199658989906,
                -0.003723341738805175,
                0.024524949491024017,
                -0.0407874695956707,
                -0.019056102260947227,
                0.07412024587392807,
                -0.06359274685382843,
                0.041695866733789444,
                -0.051158562302589417,
                0.07019533962011337,
                -0.008656587451696396,
                0.013622838072478771,
                0.018890274688601494,
                -0.006104810629040003,
                2.3058738918280142e-07,
                -0.033653948456048965,
                0.0061476691626012325,
                0.018807267770171165,
                0.024354113265872,
                -0.007585486862808466,
                -0.02681577578186989,
                -0.0029445455875247717,
                0.011815500445663929,
                0.006910413503646851,
                0.03734375908970833,
                -0.00870543997734785,
                0.01092394720762968,
                -0.02053774707019329,
                -0.003985049203038216,
                0.03036913089454174,
                -0.04677244648337364,
                0.030863041058182716,
                0.05638691037893295,
                0.024074377492070198,
                0.002789797494187951,
                0.009342372417449951,
                0.026980344206094742,
                0.04836679995059967,
                0.012445586733520031,
                0.037610575556755066,
                -0.019795775413513184,
                -0.025865325704216957,
                -0.028784144669771194,
                -0.014724339358508587,
                -0.0983215868473053,
                -0.026178497821092606,
                0.034498609602451324,
                -0.0024479548446834087,
                0.0585891529917717,
                0.03645653277635574,
                -0.05159713327884674,
                0.03294353932142258,
                0.05535879358649254,
                -0.04813035577535629,
                -0.05930845066905022,
                -0.008856752887368202,
                0.012931600213050842,
                -0.041987206786870956,
                0.010317294858396053,
                0.015540271997451782,
                -0.001491798902861774,
                -0.015519308857619762,
                0.04870207980275154,
                -0.05566611513495445,
                -0.0020324746146798134,
                -0.06216921657323837,
                0.0012579616159200668,
                0.014095385558903217,
                0.05299801751971245,
                0.017626360058784485,
                -0.002629720140248537,
                -0.024376869201660156,
                0.011673066765069962,
                -0.01654832437634468,
                0.011707448400557041,
                0.008115783333778381,
                -0.03434167802333832,
                0.017244020476937294,
                0.010003140196204185,
                0.0159918162971735,
                0.030851740390062332,
                -0.06861987709999084,
                1.5141093886954413e-34,
                0.0023214295506477356,
                0.006361779291182756,
                0.06850660592317581,
                -0.021584609523415565,
                -0.010301572270691395,
                0.015718722715973854,
                0.03978390246629715,
                -0.03027825616300106,
                -0.013423138298094273,
                0.0037915746215730906,
                0.01544415857642889,
                -0.007523099891841412,
                0.05554800108075142,
                0.0010873369174078107,
                -0.006974640302360058,
                -0.00010131789167644456,
                -0.013069256208837032,
                -0.027599677443504333,
                -0.01984378881752491,
                -0.025956761091947556,
                0.0035295109264552593,
                0.020866692066192627,
                -0.05358032137155533,
                0.022127963602542877,
                0.11330747604370117,
                0.03219625726342201,
                -0.08573474735021591,
                0.003754157107323408,
                0.013642428442835808,
                -0.02441009320318699,
                -0.039770662784576416,
                -0.025434937328100204,
                0.0078689344227314,
                -0.0021798359230160713,
                -0.05066407099366188,
                0.02855251170694828,
                0.009371563792228699,
                -0.03657082840800285,
                -0.02828519605100155,
                -0.019520478323101997,
                0.007835013791918755,
                -0.002482661046087742,
                0.005991924554109573,
                -0.03754661604762077,
                -0.028097501024603844,
                2.0729221432702616e-06,
                -0.001691027544438839,
                -0.00015589784015901387,
                -0.017581608146429062,
                -0.0358118936419487,
                0.044505082070827484,
                -0.03748943656682968,
                -0.048050496727228165,
                -0.06213675066828728,
                0.0032399154733866453,
                0.001566631137393415,
                0.015521960332989693,
                0.05551706627011299,
                -0.011492629535496235,
                -0.033809173852205276,
                -0.05239759385585785,
                0.011801797896623611,
                -0.08244489878416061,
                -0.01844165287911892,
                -0.0066412934102118015,
                0.07713692635297775,
                0.034733906388282776,
                -0.00959823839366436,
                0.03330302983522415,
                -0.020337076857686043,
                0.030647477135062218,
                -0.0476561114192009,
                -0.024156279861927032,
                0.019848007708787918,
                -0.014923689886927605,
                0.03286493569612503,
                0.005534924566745758,
                0.006270270328968763,
                -0.021189874038100243,
                0.04530682414770126,
                0.06968812644481659,
                0.1264468878507614,
                0.03718229755759239,
                0.016168124973773956,
                0.053636662662029266,
                -0.031397733837366104,
                0.03842524066567421,
                -0.012736838310956955,
                0.03904665634036064,
                0.00644571240991354,
                -0.03190368413925171,
                0.06340619176626205,
                0.04508209228515625,
                -0.003781587816774845,
                -0.004656952805817127,
                -0.011547817848622799,
                0.0422748327255249,
                0.005902780219912529,
                0.018497493118047714,
                -0.01429375633597374,
                0.024482624605298042,
                -0.01305936649441719,
                -0.04578781872987747,
                -0.03882693499326706,
                0.017879480496048927,
                -0.02881537191569805,
                0.01106068305671215,
                -0.014203224331140518,
                0.005496957805007696,
                -0.002162322634831071,
                -0.07923354208469391,
                -0.019104594364762306,
                0.050691064447164536,
                0.011175552383065224,
                0.013236557133495808,
                -0.045433469116687775,
                0.013929849490523338,
                -0.031214354559779167,
                0.03852642700076103,
                -0.046025387942790985,
                0.06942656636238098,
                -0.03670771047472954,
                0.008003750815987587,
                0.009794667363166809,
                0.03678617253899574,
                -0.0027058504056185484,
                -0.0381406769156456,
                -0.03495907038450241,
                0.04593028873205185,
                -0.011358844116330147,
                -0.012386870570480824,
                -0.035345375537872314,
                -0.011198986321687698,
                -0.014110495336353779,
                0.009909281507134438,
                0.029085496440529823,
                -0.005086920689791441,
                -0.030822303146123886,
                0.0022906684316694736,
                0.003995684906840324,
                -0.029560059309005737,
                0.007313368376344442,
                0.003996995277702808,
                0.0032602695282548666,
                -0.006301397457718849,
                0.0044311280362308025,
                -0.05822395905852318,
                -0.026219302788376808,
                0.0018534149276092649,
                -0.06157609820365906,
                -0.051687031984329224,
                0.006990392226725817,
                -0.062070082873106,
                -0.026721738278865814,
                0.007554532028734684,
                0.0017666904022917151,
                -0.010049901902675629,
                0.0289978738874197,
                -0.00823912676423788,
                -0.007081120740622282,
                -0.02139391377568245,
                0.016360418871045113,
                0.022197818383574486,
                0.017202191054821014,
                -0.007432988379150629,
                -0.00464639812707901,
                0.021311050280928612,
                0.028864558786153793,
                -0.01864827238023281,
                -0.02997307851910591,
                0.018968256190419197,
                0.014056243933737278,
                -0.002389355096966028,
                -0.07474402338266373,
                -0.03468776494264603,
                -0.014780481345951557,
                -0.0025843523908406496,
                -0.08503519743680954,
                -0.0023062191903591156,
                0.012142839841544628,
                -0.046891938894987106,
                -0.03855264186859131,
                0.018880203366279602,
                0.0008586903568357229,
                0.05080970749258995,
                0.027148164808750153,
                0.03925621137022972,
                0.033431749790906906,
                0.06818068027496338,
                -0.0344870500266552,
                0.009573257528245449,
                0.014808974228799343,
                -0.0572374053299427,
                -0.00312154833227396,
                0.0025455087888985872,
                -0.020384354516863823,
                0.02726004086434841,
                -0.05899279564619064,
                -0.019306831061840057,
                -0.03049924224615097,
                -0.002586592687293887,
                0.0015043823514133692,
                0.02609146013855934,
                0.03512532636523247,
                0.048149701207876205,
                0.022816084325313568,
                0.01724851131439209,
                0.039110977202653885,
                0.08851418644189835,
                -0.01787441410124302,
                0.013734152540564537,
                -0.029241453856229782,
                -0.013030407018959522,
                0.05416695401072502,
                -0.031087635084986687,
                -0.042153555899858475,
                0.0234339889138937,
                -0.00225628144107759,
                0.01855284906923771,
                0.01224134024232626,
                0.014094701036810875,
                -0.02214507944881916,
                0.018732860684394836,
                -0.015804532915353775,
                0.011370649561285973,
                -0.008934397250413895,
                -0.013750658370554447,
                0.01728862151503563,
                -0.003799833357334137,
                -0.07891540974378586,
                0.02700616978108883,
                -0.06373478472232819,
                -0.006166159175336361,
                0.051705218851566315,
                0.008329087868332863,
                0.009452098049223423,
                0.015503273345530033,
                -0.0005437759100459516,
                0.006373807322233915,
                -5.410551602835767e-05,
                0.019407158717513084,
                0.012700973078608513,
                0.02334975078701973,
                0.05543278157711029,
                0.025932228192687035,
                -0.02740960568189621,
                0.024018364027142525,
                -0.04462447389960289,
                0.0716903805732727,
                0.019984908401966095,
                -0.037469882518053055,
                0.034080296754837036,
                -0.08442777395248413,
                0.030160047113895416,
                -0.01996200531721115,
                0.11658300459384918,
                -0.031161457300186157,
                0.008162193931639194,
                0.01678919792175293,
                0.023153334856033325,
                0.007706272415816784,
                -0.014216127805411816,
                -0.018695281818509102,
                0.03897172212600708,
                -0.012827092781662941,
                0.00992027297616005,
                -0.04257664456963539,
                -0.017854059115052223,
                0.02496965415775776,
                -0.030321665108203888,
                -0.00396176939830184,
                0.03606179356575012,
                -0.024682559072971344,
                -0.002995589980855584,
                -0.011315342038869858,
                0.001723208581097424,
                -0.00019506689568515867,
                0.019635910168290138,
                -0.02482612244784832,
                -0.035294823348522186,
                -0.010466128587722778,
                0.030153637751936913,
                0.026222705841064453,
                0.023585299029946327,
                -0.018365491181612015,
                0.014157766476273537,
                -0.024534208700060844,
                -0.018392469733953476,
                0.0018915802938863635,
                -0.010764501057565212,
                0.0007551174494437873,
                -0.014201337471604347,
                -0.0465770848095417,
                -0.019454684108495712,
                -0.02469220571219921,
                0.02135748788714409,
                -0.014108292758464813,
                -0.009551032446324825,
                -0.05681275948882103,
                -0.06800973415374756,
                -0.02981642074882984,
                -0.003528138855472207,
                -0.0011331239948049188,
                -0.0005984869203530252,
                -0.0799383744597435,
                0.008200360462069511,
                -0.001290875137783587,
                0.045624010264873505,
                0.019235191866755486,
                -0.028501475229859352,
                0.014717196114361286,
                0.002813952276483178,
                -0.003351851599290967,
                0.018209993839263916,
                -0.007771451026201248,
                0.009732339531183243,
                0.047381628304719925,
                -0.013896369375288486,
                -0.027343280613422394,
                0.008106433786451817,
                0.035859595984220505,
                0.023728838190436363,
                0.008425339125096798,
                0.0004614267672877759,
                -0.04098418354988098,
                0.05524401366710663,
                -0.014562482014298439,
                -0.01975785568356514,
                -0.060502372682094574,
                0.0018713702447712421,
                0.05905739217996597,
                -0.0018551998073235154,
                0.03490734472870827,
                -0.013216814026236534,
                0.0011766112875193357,
                0.012632911093533039,
                0.04032742604613304,
                0.005114707630127668,
                0.004786581266671419,
                0.05675243213772774,
                0.0025422002654522657,
                -0.006330044940114021,
                0.07446962594985962,
                0.016445018351078033,
                0.020871732383966446,
                -0.03442772105336189,
                0.06849753111600876,
                -0.011075813323259354,
                -0.0766928642988205,
                -0.045857492834329605,
                0.04288401082158089,
                -0.008465954102575779,
                -0.07852665334939957,
                -0.023279612883925438,
                0.010257626883685589,
                -0.021227970719337463,
                0.003968344535678625,
                0.05221523344516754,
                -0.021742090582847595,
                -0.0356336385011673,
                0.015443584881722927,
                0.02396911382675171,
                0.009867212735116482,
                -0.0015954113332554698,
                -0.0018192401621490717,
                -0.01843092404305935,
                0.04397275671362877,
                -0.02558925189077854,
                0.014086948707699776,
                0.0220158901065588,
                -0.06315913051366806,
                -0.014248855412006378,
                0.03405416011810303,
                -0.015575641766190529,
                0.020138585940003395,
                -0.05745267495512962,
                0.005923978518694639,
                0.04554663971066475,
                0.05613454058766365,
                -0.01645541936159134,
                -0.03769809007644653,
                0.009135867469012737,
                -0.005782040301710367,
                -0.004892994184046984,
                0.018101610243320465,
                -0.019079729914665222,
                0.06961645931005478,
                0.026781383901834488,
                0.026324301958084106,
                0.04124005511403084,
                -0.011610027402639389,
                0.0113938944414258,
                -0.050959303975105286,
                -0.00022726214956492186,
                0.010966990143060684,
                -0.02796292118728161,
                0.009187447838485241,
                -0.017870783805847168,
                0.010572629049420357,
                0.01737329177558422,
                -0.009360305033624172,
                0.01180922519415617,
                -0.02853214368224144,
                -0.005380470305681229,
                0.045808639377355576,
                0.024069394916296005,
                -0.01924600638449192,
                -0.03338019922375679,
                0.0377066396176815,
                -0.03750346973538399,
                0.027376670390367508,
                0.00837533175945282,
                0.0013092845911160111,
                0.029154350981116295,
                0.02773771807551384,
                -0.04543411359190941,
                -0.017232749611139297,
                -0.055997174233198166,
                0.01151168905198574,
                -0.01947246678173542,
                -0.12086665630340576,
                0.15319672226905823,
                -0.005929592531174421,
                0.004615091718733311,
                0.0046759191900491714,
                -0.05194447562098503,
                0.021314313635230064,
                -0.019599977880716324,
                -0.049149904400110245,
                0.02265896648168564,
                -0.029980693012475967,
                0.003983682952821255,
                -0.031874172389507294,
                0.023107683286070824,
                -0.003878279123455286,
                0.07549665123224258,
                -0.00031244297861121595,
                0.020385151728987694,
                -0.006896804552525282,
                0.017240092158317566,
                -0.014348520897328854,
                0.0002735528105404228,
                -0.04496857523918152,
                -0.06238057091832161,
                0.029691509902477264,
                0.051605064421892166,
                -0.04513310641050339,
                0.04104077070951462,
                -0.020055660977959633,
                0.11262835562229156,
                -0.027830686420202255,
                0.007524187210947275,
                -0.012667383998632431,
                0.016223296523094177,
                -0.011649608612060547,
                0.009971339255571365,
                -0.08890400826931,
                -0.030050178989768028,
                -0.05535326153039932,
                -0.03107745759189129,
                -0.009562249295413494,
                0.04149940237402916,
                0.01217387244105339,
                0.052459366619586945,
                -0.012477959506213665,
                -0.05915994569659233,
                -0.002821409609168768,
                -0.12417230755090714,
                0.04040306806564331,
                -0.024402597919106483,
                0.06997833400964737,
                0.013613023795187473,
                0.02439899742603302,
                -0.030172156170010567,
                -0.01972148008644581,
                -0.004459920339286327,
                -0.01748938485980034,
                -0.02368076890707016,
                -0.08679211139678955,
                -0.015606950037181377,
                -0.015108124352991581,
                -0.0537770539522171,
                0.005237959325313568,
                -0.024355102330446243,
                0.04024302959442139,
                0.016387727111577988,
                0.019542813301086426,
                -0.043327849358320236,
                -0.009182153269648552,
                0.09998803585767746,
                0.02758253924548626,
                0.015513396821916103,
                0.02810882218182087,
                0.005092864390462637,
                0.06162191182374954,
                -0.008777670562267303,
                0.009767092764377594,
                0.0276917964220047,
                0.024008117616176605,
                0.013689454644918442,
                0.0035094781778752804,
                0.014727316796779633,
                -0.002581473207101226,
                -0.016217457130551338,
                0.05849173665046692,
                0.01538759097456932,
                0.09995384514331818,
                -0.021934546530246735,
                0.03245038911700249,
                -0.015296311117708683,
                -0.03305089473724365,
                0.010277388617396355,
                0.020370371639728546,
                0.0017445035045966506,
                0.1168675646185875,
                0.02309066243469715,
                0.0660204291343689,
                0.0095424959436059,
                0.02170793153345585,
                0.04142365977168083,
                0.011132708750665188,
                -0.024575311690568924,
                0.009555656462907791,
                -0.008584626950323582,
                -0.001069875666871667,
                -0.03631085529923439,
                -0.0540006197988987,
                -0.05102764442563057,
                -0.001660682843066752,
                -0.006715073715895414,
                -0.0019118156051263213,
                0.0010297722183167934,
                -0.0005808777059428394,
                0.0022223652340471745,
                0.009480996988713741,
                0.0025862648617476225,
                0.011248519644141197,
                -0.00309538166038692,
                0.0175136998295784,
                -0.030487174168229103,
                -0.005097182001918554,
                -0.002842739224433899,
                -0.01675858348608017,
                -0.05208593234419823,
                -0.0010836113942787051,
                -0.04551856592297554,
                0.0829252302646637,
                -0.022905733436346054,
                0.01711784303188324,
                -0.025260332971811295,
                -0.05138847976922989,
                -0.06028342992067337,
                0.00522726122289896,
                -0.004148806445300579,
                0.011959019117057323,
                0.06864512711763382,
                -0.022900115698575974,
                -0.00574149563908577,
                0.034218721091747284,
                0.040203437209129333,
                0.01835200935602188,
                0.0041794003918766975,
                -0.09316611289978027,
                0.03616224229335785,
                0.01584751345217228,
                0.058364443480968475,
                -8.141660483523356e-33,
                -0.012783101759850979,
                0.015263122506439686,
                -0.018867187201976776,
                0.04445831850171089,
                -0.045678526163101196,
                0.013622761704027653,
                -0.01594814658164978,
                0.03658575564622879,
                -0.016433851793408394,
                -0.015667345374822617,
                -0.009408187121152878,
                0.00397988548502326,
                0.016051724553108215,
                0.017264878377318382,
                0.06250584870576859,
                -0.015049735084176064,
                -0.010736839845776558,
                0.0006831102655269206,
                -0.033671487122774124,
                -0.061580464243888855,
                -0.025723395869135857,
                -0.007701068185269833,
                0.044781845062971115,
                -0.000982430181466043,
                0.025312578305602074,
                -0.03762250766158104,
                -0.008574155159294605,
                -0.038235604763031006,
                -0.03024503029882908,
                -0.02086452953517437,
                -0.008996988646686077,
                -0.034725189208984375,
                0.002118533244356513,
                0.019684363156557083,
                -0.004675691016018391,
                0.058602482080459595,
                -0.050261639058589935,
                0.013342369347810745,
                -0.009389056824147701,
                0.0019380688900128007,
                -0.11719148606061935,
                0.009581281803548336,
                -0.024242306128144264,
                -0.013092461973428726,
                -0.01875782012939453,
                -0.07104665040969849,
                -0.00030609784880653024,
                -0.04291681945323944,
                0.018523089587688446,
                -0.034605495631694794,
                -0.007969059981405735,
                0.0028032592963427305,
                -0.025064878165721893,
                0.07611826062202454,
                0.011522553861141205,
                -0.017719173803925514,
                -0.010300248861312866,
                0.029085950925946236,
                0.018177196383476257,
                0.03306454420089722,
                -0.014179459773004055,
                0.006595682352781296,
                -0.0432112030684948,
                0.02426617220044136,
                -0.03329593688249588,
                -0.006436410825699568,
                0.02123701572418213,
                0.006710477638989687,
                -0.0429619699716568,
                -0.012070868164300919,
                -0.003265000879764557,
                0.10007002204656601,
                0.06347334384918213,
                -0.023742515593767166,
                0.03616594895720482,
                -0.015863332897424698,
                -0.0473792590200901,
                -0.019727088510990143,
                0.0955047532916069,
                0.018970686942338943,
                -0.014156081713736057,
                0.09155398607254028,
                0.0020123363938182592,
                -0.005219197832047939,
                -0.06411492824554443,
                -0.08995899558067322,
                -0.007681427523493767,
                0.03171050176024437,
                -0.03485329449176788,
                0.024957919493317604,
                0.014303675852715969,
                0.028270436450839043,
                -0.009175783023238182,
                0.05524122342467308,
                -0.00048607229837216437,
                -0.031521305441856384,
                0.04592979699373245,
                0.00234717084094882,
                -0.01758887618780136,
                -0.03615357354283333,
                0.036991722881793976,
                0.01787898689508438,
                -0.0006658071069978178,
                0.058541931211948395,
                0.0019485243828967214,
                0.01079409196972847,
                0.002974769799038768,
                0.029819348827004433,
                -0.0025202296674251556,
                0.0003366511664353311,
                -0.020278533920645714,
                -0.03354674577713013,
                0.005794665776193142,
                0.01873261108994484,
                0.06049526855349541,
                -0.030038023367524147,
                0.012587514705955982,
                0.03606858849525452,
                -0.009442825801670551,
                0.07045239955186844,
                -0.0010650729527696967,
                0.11102280765771866,
                -0.009770674630999565,
                0.011857608333230019,
                0.006968882400542498,
                0.013494161888957024,
                0.03353415057063103,
                -0.09930933266878128,
                0.039836008101701736,
                -0.09666396677494049,
                -0.014550155960023403,
                -0.000879450177308172,
                2.7635303467832273e-07,
                -0.014094571582973003,
                -0.003915142733603716,
                0.00252674357034266,
                0.015779413282871246,
                0.03585360571742058,
                0.06375379860401154,
                0.018538903445005417,
                0.02478472888469696,
                -0.03237020596861839,
                -0.03883916884660721,
                -0.01071082055568695,
                0.026015909388661385,
                0.030253252014517784,
                0.004580980632454157,
                0.012167238630354404,
                0.010264674201607704,
                -0.04480140283703804,
                -0.07884544134140015,
                0.030593853443861008,
                -0.007783610373735428,
                -0.03768838569521904,
                0.014731009490787983,
                -0.028662580996751785,
                -0.0054191844537854195,
                -0.009314673952758312,
                -0.025465328246355057,
                -0.0046855853870511055,
                0.025815939530730247,
                0.029773224145174026,
                -0.017876267433166504,
                -0.03523934260010719,
                -0.02108607068657875,
                -0.047872137278318405,
                -0.0007014356087893248,
                -0.02646804228425026,
                -0.007302117068320513,
                0.04106304049491882,
                -0.030009740963578224,
                -0.012944434769451618,
                0.08790641278028488,
                -0.021332111209630966,
                -0.02134733460843563,
                -0.006654328666627407,
                0.0589011050760746,
                0.03664197400212288,
                -0.029766127467155457,
                0.0030662582721561193,
                0.08623242378234863,
                -0.12417064607143402,
                -0.0034239371307194233,
                0.02502315491437912,
                0.020137926563620567,
                0.0038361207116395235,
                0.06128409504890442,
                0.02243887446820736,
                -0.022263452410697937,
                -0.00632957648485899,
                -0.03442264720797539,
                0.044157374650239944,
                -0.01659875363111496,
                -0.039087675511837006,
                0.08272594958543777,
                0.015052888542413712,
                0.0467359721660614,
                0.04670094698667526,
                -0.05301455780863762,
                -0.09140698611736298,
                2.4449905971569332e-34,
                0.017321160063147545,
                -0.012196667492389679,
                -0.011616487056016922,
                0.02875920571386814,
                0.02030261792242527,
                -0.04394647479057312,
                -0.027234364300966263,
                0.0026653215754777193,
                0.0421687476336956,
                -0.050380926579236984,
                -0.03229410573840141,
                0.011752537451684475,
                -0.05963100120425224,
                -0.05398430675268173,
                0.03268707916140556,
                -0.04801104590296745,
                -0.00045134720858186483,
                0.04453415796160698,
                -0.031032618135213852,
                -0.02751867100596428,
                0.03007674589753151,
                0.06154414266347885,
                -0.011771711520850658,
                0.040444448590278625,
                0.07498643547296524,
                0.013731898739933968,
                -0.02146364562213421,
                -0.0220623929053545,
                0.035861264914274216,
                -0.055528197437524796,
                0.010726169683039188,
                -0.0324128195643425,
                -0.007968408055603504,
                -0.014665512368083,
                0.011451524682343006,
                -0.04952182620763779,
                -0.015609192661941051,
                -0.045177631080150604,
                0.016280721873044968,
                -0.0039012592751532793,
                -0.005258816294372082,
                0.038149140775203705,
                0.06794366985559464,
                -0.05176383629441261,
                -0.016460319980978966,
                1.5425058563778293e-06,
                0.0010574567131698132,
                0.027516422793269157,
                0.013388097286224365,
                0.004224567674100399,
                0.04815245792269707,
                0.06316227465867996,
                0.007950338535010815,
                -0.0532633513212204,
                -0.001404627226293087,
                0.03704731911420822,
                0.000900621060281992,
                0.01862281933426857,
                0.06581632792949677,
                -0.014436492696404457,
                -0.0502893403172493,
                -0.015156726352870464,
                -0.09008409827947617,
                -0.018120329827070236,
                -0.034908004105091095,
                -0.054754603654146194,
                0.011479555629193783,
                -0.009583570994436741,
                0.03011464513838291,
                0.12624812126159668,
                0.030992835760116577,
                -0.0026032535824924707,
                0.011374663561582565,
                0.01658688485622406,
                7.448010001098737e-05,
                0.06779082119464874,
                -0.022770889103412628,
                0.06031433492898941,
                0.0002995551913045347,
                -0.0025081865023821592,
                0.03770880401134491,
                0.05592787265777588,
                0.0630115196108818,
                -0.001287436462007463,
                0.02305780164897442,
                -0.026858463883399963,
                -0.016570094972848892,
                -0.006630823947489262,
                -0.05137103050947189,
                -0.0066503677517175674,
                -0.03303517773747444,
                -0.04138572886586189,
                -0.03520829603075981,
                -0.034157585352659225,
                0.00109331333078444,
                0.021393882110714912,
                0.013513319194316864,
                0.005223145708441734,
                0.02029726840555668,
                -0.037149578332901,
                0.0009447414195165038,
                0.040932558476924896,
                0.0013996099587529898,
                0.027914226055145264,
                -0.00662432424724102,
                -0.026587238535284996,
                -0.02582578733563423,
                0.07157738506793976,
                -0.019406255334615707,
                0.017461052164435387,
                -0.0029365066438913345,
                0.021800022572278976,
                0.005405436269938946,
                0.07157419621944427,
                0.023347876965999603,
                -0.04128070920705795,
                0.0255435723811388,
                -0.011341789737343788,
                -0.012075047008693218,
                -0.04500243440270424,
                0.07061535865068436,
                -0.027543282136321068,
                -0.019854525104165077,
                -0.022669421508908272,
                -0.013497369363904,
                -0.016172802075743675,
                -0.0018856868846341968,
                -0.04937644675374031,
                0.03331382945179939,
                0.01591290533542633,
                0.000433437671745196,
                0.020807703956961632,
                0.049709513783454895,
                -0.014107170514762402,
                -0.028762290254235268,
                0.01362728513777256,
                -0.008230009116232395,
                -0.020825257524847984,
                0.025375474244356155,
                0.0168472733348608,
                0.03357551619410515,
                0.02022974006831646,
                -0.008674133569002151,
                -0.026154298335313797,
                -0.025239994749426842,
                -0.04342116042971611,
                0.07840222120285034,
                -0.016649145632982254,
                0.04004841297864914,
                -0.05407875403761864,
                -0.07127329707145691,
                0.04361230507493019,
                -0.010705680586397648,
                -0.013220694847404957,
                -0.01404618564993143,
                -0.04738562926650047,
                -0.06290149688720703,
                0.007974985055625439,
                0.027630669996142387,
                -0.013814475387334824,
                -0.032020453363657,
                -0.02818681113421917,
                -0.007188752759248018,
                0.008289515972137451,
                0.04387642815709114,
                0.05765928328037262,
                0.04060386121273041,
                0.015215015970170498,
                0.03163180127739906,
                -0.014417965896427631,
                0.015239207074046135,
                0.009417634457349777,
                -0.014466344378888607,
                0.0041343942284584045,
                -0.013275187462568283,
                0.004452087916433811,
                -0.00039521450526081026,
                0.011578957550227642,
                0.0029737658333033323,
                -0.0756792202591896,
                -0.04553918167948723,
                -0.054765257984399796,
                -0.004017793107777834,
                -0.019050899893045425,
                0.12117645144462585,
                0.040022119879722595,
                -0.031480446457862854,
                0.06711094081401825,
                0.019224537536501884,
                -0.06214778125286102,
                0.05952460691332817,
                -0.00784156285226345,
                -0.08913637697696686,
                0.06336992233991623,
                0.02446337230503559,
                0.010160399600863457,
                -0.015246996656060219,
                -0.031178364530205727,
                -0.01623225398361683,
                -0.09096188843250275,
                -0.02488156594336033,
                0.022511489689350128,
                -0.02822047472000122,
                0.004202139563858509,
                0.004986147861927748,
                -0.010338910855352879,
                0.038461171090602875,
                0.0156046561896801,
                0.005663649644702673,
                -0.030674640089273453,
                -0.017778800800442696,
                -0.046760402619838715,
                -0.031260810792446136,
                0.009400194510817528,
                0.040806107223033905,
                -0.029410505667328835,
                -0.0012574142310768366,
                -0.0016188115114346147,
                0.0034233478363603354,
                -0.04268305376172066,
                0.01685982011258602,
                0.07781537622213364,
                0.00660278694704175,
                -0.025032564997673035,
                0.06510164588689804,
                -0.024234062060713768,
                0.0006744526908732951,
                0.012026363052427769,
                0.005909275729209185,
                -0.08164002746343613,
                0.02587069198489189,
                -0.012250872328877449,
                0.03168851137161255,
                -0.019999565556645393,
                -0.012201068922877312,
                0.0003049627412110567,
                0.00359201617538929,
                0.031240306794643402,
                0.00643350463360548,
                -0.018398305401206017,
                0.03737062215805054,
                0.010422129184007645,
                0.02930406481027603,
                0.022919153794646263,
                -0.02870691940188408,
                -0.0010791377862915397,
                0.018295040354132652,
                0.09691578894853592,
                0.08842262625694275,
                -0.01768648810684681,
                -0.10208937525749207,
                0.008684157393872738,
                0.01359578873962164,
                0.019048042595386505,
                0.0045438967645168304,
                0.06166757643222809,
                -0.06596055626869202,
                0.022516848519444466,
                0.03086468018591404,
                0.032640621066093445,
                -0.023697834461927414,
                -0.04697813093662262,
                -0.05372394621372223,
                0.0190131813287735,
                -0.0084694167599082,
                0.05760239437222481,
                -0.018876759335398674,
                0.018234163522720337,
                0.06550473719835281,
                0.007935639470815659,
                -0.08255580812692642,
                0.07062239199876785,
                -0.029804840683937073,
                -0.014720435254275799,
                0.04229900240898132,
                0.04527120664715767,
                -0.03988337144255638,
                0.02394913323223591,
                -0.009012986905872822,
                -0.012367350049316883,
                0.04465922713279724,
                0.0101400688290596,
                0.04744796082377434,
                0.002545889001339674,
                -0.0005354164750315249,
                0.032324522733688354,
                0.008638468571007252,
                -0.004812379367649555,
                -0.022083258256316185,
                -0.03715348243713379,
                0.0287180133163929,
                0.04678564518690109,
                -0.03910345956683159,
                -0.0208123791962862,
                -0.01801879145205021,
                0.032614924013614655,
                0.0009907657513394952,
                0.08292973786592484,
                -0.015208519995212555,
                -0.03494379669427872,
                0.05679206922650337,
                0.012796862050890923,
                -0.054037388414144516,
                0.046298734843730927,
                -0.0608271099627018,
                -0.0006017087143845856,
                0.013613909482955933,
                0.028133975341916084,
                0.004160990472882986,
                -0.011623181402683258,
                0.025308607146143913,
                -0.024039052426815033,
                -0.04718821868300438,
                0.008389018476009369,
                -0.002834131009876728,
                -0.02275034971535206,
                0.02656133659183979,
                0.05184516683220863,
                -0.0022518460173159838,
                -0.03901870176196098,
                -0.01654539629817009,
                -0.005423153284937143,
                0.015088289976119995,
                0.011463042348623276,
                0.05795975774526596,
                0.025609031319618225,
                -0.00847804918885231,
                0.013613958843052387,
                0.010470984503626823,
                0.044410064816474915,
                0.1087597981095314,
                -0.01901298575103283,
                0.021533679217100143,
                -0.009389019571244717,
                0.019255537539720535,
                0.04822474718093872,
                -0.011491206474602222,
                -0.014213413000106812,
                0.006606634706258774,
                0.010210935957729816,
                -0.07081520557403564,
                -0.004817066714167595,
                0.0033231417182832956,
                -0.002505769021809101,
                0.009371086023747921,
                0.002619775477796793,
                -0.02159239910542965,
                -0.025829339399933815,
                -0.08604011684656143,
                -0.03664065897464752,
                -0.043751321732997894,
                -0.04504479840397835,
                -0.029231026768684387,
                -0.08175250887870789,
                0.03220623359084129,
                0.0020739929750561714,
                -0.001340922899544239,
                -0.001797135453671217,
                0.03948425501585007,
                -0.007045031059533358,
                0.01200077310204506,
                0.03079804591834545,
                -0.009174903854727745,
                -0.020694628357887268,
                0.021345118060708046,
                -0.029123129323124886,
                0.027470482513308525,
                -0.02438584342598915,
                0.0292971208691597,
                -0.0015445902245119214,
                -0.004485678859055042,
                -0.03539099916815758,
                -0.01555326022207737,
                -0.052710700780153275,
                0.04881809279322624,
                -0.05707808956503868,
                8.814638567855582e-05,
                0.05134112387895584,
                0.0710582509636879,
                -0.032690711319446564,
                0.005247201770544052,
                0.008698074147105217,
                0.025155168026685715,
                0.00841118860989809,
                -0.024717843160033226,
                0.005653752945363522,
                0.023183291777968407,
                0.02704118750989437,
                -0.0027111507952213287,
                0.02216271124780178,
                -0.04333027824759483,
                0.004536143038421869,
                -0.004514772444963455,
                0.05663105100393295,
                -0.023055359721183777,
                0.01009944174438715,
                -0.06223515048623085,
                -0.007302931975573301,
                0.009843165054917336,
                0.003260148921981454,
                -0.003177318722009659,
                -0.008430523797869682,
                0.011084305122494698,
                -0.004213267471641302,
                0.025117063894867897,
                1.0789528460009024e-05,
                0.022438721731305122,
                -0.010269098915159702,
                0.030797788873314857,
                0.01452728547155857,
                0.042328283190727234,
                0.033604733645915985,
                -0.04116746410727501,
                -0.05607777461409569,
                0.013158739544451237,
                -0.004634528886526823,
                -0.056018564850091934,
                0.07620004564523697,
                0.030773721635341644,
                -0.0241080429404974,
                -0.04706981033086777,
                0.05109783634543419,
                -6.858287088107318e-05,
                0.03349517285823822,
                -0.029040595516562462,
                -0.03575751930475235,
                0.05444105342030525,
                -0.046248678117990494,
                -0.05684979632496834,
                -0.009667872451245785,
                -0.06569681316614151,
                0.0501047819852829,
                0.026120349764823914,
                0.00477205403149128,
                0.004133717156946659,
                0.06472404301166534,
                -0.0671561136841774,
                -0.03867190331220627,
                0.03695470467209816,
                -0.013562849722802639,
                -0.023032039403915405,
                -0.04630492255091667,
                -0.05161365494132042,
                -0.030608071014285088,
                -0.0010449271649122238,
                -0.0661698579788208,
                -0.017263654619455338,
                -0.025753244757652283,
                -0.014682852663099766,
                0.0015305348206311464,
                -0.009567057713866234,
                0.03647910803556442,
                -0.012631988152861595,
                0.011252718977630138,
                0.008364380337297916,
                -0.0385197214782238,
                -0.013822034001350403,
                0.01423551794141531,
                -0.0009756458457559347,
                -0.04531453922390938,
                0.033228710293769836,
                0.07314402610063553,
                -0.0010344103211537004,
                0.022193681448698044,
                -0.03614255413413048,
                -0.012233751825988293,
                -0.012303336523473263,
                -0.02281941846013069,
                0.04688045009970665,
                0.0001964317780220881,
                0.05233549699187279,
                0.08267711102962494,
                0.03303850069642067,
                -0.04316219687461853,
                -0.03345341980457306,
                -0.020808648318052292,
                -0.08759838342666626,
                -0.029434768483042717,
                0.02399800345301628,
                -0.019088858738541603,
                -0.00584474066272378,
                -0.028537703678011894,
                0.025796670466661453,
                -0.055863216519355774,
                0.06915704905986786,
                0.017408138141036034,
                0.007669632788747549,
                -0.02959919348359108,
                0.003384215058758855,
                -0.002851383062079549,
                -0.015863005071878433,
                0.05406545102596283,
                -0.03764753043651581,
                0.007019009906798601,
                0.04340576380491257,
                0.02595922350883484,
                0.06857968121767044,
                0.028927788138389587,
                -0.025816576555371284,
                0.030663905665278435,
                -0.039930567145347595,
                -0.02322252281010151,
                -0.06545696407556534,
                -0.0037211047019809484,
                -0.01818430796265602,
                -0.033115748316049576,
                0.03348724916577339,
                -0.023236118257045746,
                0.10408257693052292,
                0.023461220785975456,
                -0.027185820043087006,
                -0.01898972876369953,
                0.025881411507725716,
                -0.00596834160387516,
                -0.03288756310939789,
                -0.010493651032447815,
                0.037510499358177185,
                0.018317582085728645,
                -0.030251726508140564,
                -0.024563973769545555,
                -0.004287544637918472,
                0.008697209879755974,
                0.05049556493759155,
                0.03360835462808609,
                -0.032773151993751526,
                -0.02197890728712082,
                -0.021595053374767303,
                -0.05152233690023422,
                0.0025201018434017897,
                -0.04868767037987709,
                0.030012698844075203,
                0.00754518760368228,
                -0.03136659041047096,
                0.02396736852824688,
                -0.04289218410849571,
                0.03184914216399193,
                -0.033950045704841614,
                0.03382670506834984,
                0.04724855720996857,
                -0.10290302336215973,
                -0.01511544082313776,
                -0.04478218033909798,
                -0.06731721013784409,
                -0.055792320519685745,
                0.01872749626636505,
                -0.00964932981878519,
                0.11744530498981476,
                0.05184274539351463,
                0.058058690279722214,
                -0.023175852373242378,
                0.012838653288781643,
                -0.08702157437801361,
                -0.014182777144014835,
                0.01701393537223339,
                0.021183544769883156,
                0.06005627289414406,
                0.0019423915073275566,
                0.021051764488220215,
                -0.019372621551156044,
                -0.002009796677157283,
                0.013552086427807808,
                0.0020759054459631443,
                -0.03784719854593277,
                -0.010252634063363075,
                0.0431743785738945,
                -0.0004578567750286311,
                -6.097888699834125e-33,
                -0.03257088363170624,
                -0.028881341218948364,
                0.024338997900485992,
                0.007116895634680986,
                -0.05044441670179367,
                0.009955255314707756,
                -0.01796134002506733,
                0.02713805064558983,
                -0.048918716609478,
                -0.034159105271101,
                -0.041151855140924454,
                -0.07150599360466003,
                -0.002925857435911894,
                0.04047885909676552,
                0.04210050031542778,
                -0.011320309713482857,
                0.027816906571388245,
                0.052402108907699585,
                -0.031840547919273376,
                -0.04353867843747139,
                -0.0548405796289444,
                0.016172971576452255,
                -0.020569071173667908,
                -0.04214092716574669,
                -0.00902797095477581,
                -0.05190951004624367,
                -0.039404913783073425,
                -0.011574418283998966,
                -0.00868870597332716,
                -0.009084010496735573,
                -0.0024308536667376757,
                0.021999165415763855,
                -0.00490528391674161,
                0.006254331674426794,
                -0.03236312419176102,
                0.017001930624246597,
                -0.04621502012014389,
                -0.05251835286617279,
                0.020490605384111404,
                0.027715720236301422,
                -0.00827085878700018,
                -0.025566453114151955,
                -0.023941703140735626,
                -0.0028408956713974476,
                -0.06279827654361725,
                -0.026496216654777527,
                -0.029832731932401657,
                -0.01585700362920761,
                0.05961435288190842,
                0.03640827536582947,
                -0.0746726468205452,
                -0.013313417322933674,
                -0.01599378138780594,
                -0.002317782025784254,
                0.053993429988622665,
                -0.01235120091587305,
                0.003887915750965476,
                -0.019674282521009445,
                0.00520001957193017,
                0.009828690439462662,
                0.0022291608620435,
                -0.009410973638296127,
                0.05167935788631439,
                -0.018810048699378967,
                0.0039635831490159035,
                -0.02293647825717926,
                -0.03953106328845024,
                0.06888649612665176,
                0.05839250236749649,
                -0.06443122774362564,
                -0.03210125491023064,
                0.09885072708129883,
                0.006653521675616503,
                -0.03816121816635132,
                -0.04779201000928879,
                -0.03847296163439751,
                -0.004022176377475262,
                -0.009440646506845951,
                -0.00892513059079647,
                0.03755176067352295,
                -0.02488195151090622,
                0.023641513660550117,
                0.0036325273104012012,
                -0.018964407965540886,
                0.01194259338080883,
                -0.013721936382353306,
                -0.005864013452082872,
                0.01339657697826624,
                -0.03352353721857071,
                0.03165201470255852,
                -0.03644362837076187,
                0.004380264785140753,
                -0.012593714520335197,
                -0.00045864758430980146,
                0.07314608246088028,
                -0.026084506884217262,
                0.0034928207751363516,
                -0.019590768963098526,
                -0.00016810769739095122,
                0.0043932185508310795,
                0.00473662419244647,
                -0.002177905524149537,
                -0.07084140181541443,
                0.07795695215463638,
                -0.011882902123034,
                -0.002117340685799718,
                -0.046757958829402924,
                -0.012022946961224079,
                0.007156308740377426,
                -0.0005568544729612768,
                0.0029095213394612074,
                -0.03978671878576279,
                0.04880763590335846,
                -0.025598369538784027,
                -0.052800945937633514,
                -0.013654572889208794,
                -0.0026373371947556734,
                -0.08303303271532059,
                -0.017082130536437035,
                -0.0004297926207073033,
                -0.004371627699583769,
                0.06824790686368942,
                -0.038435183465480804,
                -0.004015980754047632,
                -0.0033427400048822165,
                -0.031618040055036545,
                0.03961743414402008,
                -0.029414286836981773,
                0.03275888040661812,
                -0.055209580808877945,
                -0.03941573202610016,
                -0.008373978547751904,
                2.2292628898412659e-07,
                0.019751837477087975,
                0.010760447010397911,
                -0.02118501625955105,
                0.05327136069536209,
                0.04684509336948395,
                -0.002608944894745946,
                -0.023401064798235893,
                0.061844710260629654,
                0.03846406936645508,
                -0.027259012684226036,
                0.00021515839034691453,
                -0.024153100326657295,
                -0.015360419638454914,
                -0.02847062237560749,
                -0.00805102102458477,
                -0.06269672513008118,
                0.054768580943346024,
                0.05273756384849548,
                0.008617703802883625,
                0.015526404604315758,
                0.03412405773997307,
                -0.002179210539907217,
                0.024464096873998642,
                -0.003922834061086178,
                -0.09712950885295868,
                0.03403204306960106,
                -0.03337635099887848,
                -0.0171709805727005,
                0.07636870443820953,
                0.01999627612531185,
                0.011182949878275394,
                0.008749050088226795,
                0.051504358649253845,
                0.03840971365571022,
                0.037748754024505615,
                -0.05013145133852959,
                0.060589320957660675,
                0.005941272713243961,
                0.0038407223764806986,
                0.039401423186063766,
                0.0187038816511631,
                -0.007948755286633968,
                -0.005588872823864222,
                0.015744924545288086,
                0.020087501034140587,
                0.01310644205659628,
                0.005276403855532408,
                0.010065863840281963,
                -0.059918444603681564,
                -0.027184607461094856,
                0.005323250778019428,
                0.012050921097397804,
                0.04054974392056465,
                -0.0130439642816782,
                -0.002993098460137844,
                -0.0185503289103508,
                0.030105162411928177,
                0.05597970634698868,
                0.03427407518029213,
                0.05402510240674019,
                -0.020072560757398605,
                -0.02649465948343277,
                0.023526964709162712,
                0.03580865263938904,
                0.045739419758319855,
                0.020109066739678383,
                -0.030073851346969604,
                6.653045478039713e-35,
                0.012650635093450546,
                -0.025141829624772072,
                0.022679993882775307,
                0.05381756275892258,
                0.012341359630227089,
                -0.009308022446930408,
                0.0327298529446125,
                -0.034609291702508926,
                0.023299645632505417,
                0.025624064728617668,
                -0.026208670809864998,
                0.01856999844312668,
                0.05762245133519173,
                -0.024268608540296555,
                0.009604919701814651,
                -0.017569823190569878,
                -0.027328088879585266,
                0.08034196496009827,
                -0.00030595847056247294,
                -0.021865040063858032,
                0.023713352158665657,
                0.03346088528633118,
                0.02235010825097561,
                -0.0335601270198822,
                -0.008313147351145744,
                0.03171486407518387,
                0.03533542901277542,
                -0.024750761687755585,
                0.04486745595932007,
                -0.012493227608501911,
                0.013705317862331867,
                0.00029252166859805584,
                0.04866662621498108,
                0.02512296661734581,
                0.029367372393608093,
                -0.002874030265957117,
                0.0020368348341435194,
                0.031245149672031403,
                -0.01886773481965065,
                -0.040638312697410583,
                0.0109627153724432,
                0.022491132840514183,
                0.006367307621985674,
                -0.027451133355498314,
                0.009337774477899075,
                1.6157574691533227e-06,
                -0.0026641697622835636,
                0.06328784674406052,
                -0.0091177299618721,
                0.0063764555379748344,
                -0.0229291170835495,
                0.01662122830748558,
                0.04694169759750366,
                -0.040502820163965225,
                0.0316837802529335,
                -0.04387471452355385,
                -0.037443868815898895,
                -0.002987484447658062,
                0.02324499934911728,
                -0.053451575338840485,
                -0.01270944345742464,
                -0.01200859434902668,
                -0.0472385473549366,
                0.02632349729537964,
                -0.04022467881441116,
                0.04819055646657944,
                1.5043015991977882e-05,
                -0.0185034591704607,
                0.026621535420417786,
                0.02542378194630146,
                -0.026701554656028748,
                -0.006978893652558327,
                0.03648953139781952,
                -0.0027274638414382935,
                0.006564259994775057,
                0.04437476769089699,
                -0.012430062517523766,
                0.0662827119231224,
                -0.029372690245509148,
                -0.003184948116540909,
                0.023537850007414818,
                0.0161439199000597,
                0.007347154896706343,
                0.03410162404179573,
                0.10452883690595627,
                0.0008248459198512137,
                -0.0014588638441637158,
                -0.0038538218941539526,
                0.011467905715107918,
                -0.018029319122433662,
                -0.005160016939043999,
                0.04449616000056267,
                -0.00019440497271716595,
                -0.029493803158402443,
                0.06425709277391434,
                0.006886124610900879,
                0.03473011404275894,
                -0.03130779042840004,
                0.00967490579932928,
                -0.012782884761691093,
                0.003947399090975523,
                0.08420807123184204,
                -0.05403101071715355,
                0.05635254085063934,
                0.010281577706336975,
                0.025780845433473587,
                -0.024668388068675995,
                -0.011083723977208138,
                -0.045003511011600494,
                -0.055779341608285904,
                -0.021422581747174263,
                -0.03299742192029953,
                -0.014406300149857998,
                0.06120450422167778,
                0.05296196788549423,
                -0.06721755862236023,
                0.07567820698022842,
                -0.0120314946398139,
                0.04953105002641678,
                -0.025848545134067535,
                0.07391606271266937,
                -0.07133371382951736,
                -0.003901224583387375,
                -0.014952221885323524,
                -0.007929768413305283,
                0.07012172043323517,
                -0.0005575824179686606,
                -0.05303358659148216,
                0.049014657735824585,
                0.022103894501924515,
                -0.01615404710173607,
                -0.0451083742082119,
                0.04923440143465996,
                0.027027279138565063,
                0.05094025656580925,
                -0.00476326048374176,
                -0.017957868054509163,
                -0.04174615815281868,
                0.03126481920480728,
                0.032174259424209595,
                0.0075849140994250774,
                -0.013161279261112213,
                0.025028087198734283,
                -0.006318661849945784,
                -0.0430358462035656,
                0.006725975312292576,
                -0.026601767167448997,
                -0.029134150594472885,
                -0.06036246567964554,
                -0.019169798120856285,
                -0.03736497089266777,
                0.014122427441179752,
                -0.03478452190756798,
                -0.03337012976408005,
                -0.008647415786981583,
                -0.004731441847980022,
                0.027610186487436295,
                0.011542148888111115,
                -0.07476518303155899,
                -0.005297475028783083,
                -0.0007690779166296124,
                -0.05311194062232971,
                -0.03730408102273941,
                -0.02566482499241829,
                0.048651937395334244,
                0.02612384594976902,
                0.047244105488061905,
                0.023478854447603226,
                -0.036480143666267395,
                0.004091073293238878,
                -0.045231305062770844,
                -0.006845923140645027,
                0.024073291569948196,
                0.012691499665379524,
                -0.027340693399310112,
                0.014241131022572517,
                -0.06486956775188446,
                0.0684266984462738,
                -0.027656128630042076,
                -0.1355365663766861,
                -0.011606082320213318,
                -0.02530692331492901,
                -0.01710432581603527,
                -0.043040499091148376,
                0.10888966172933578,
                0.008427846245467663,
                -0.015400352887809277,
                0.04825253412127495,
                0.02925976552069187,
                0.002548959804698825,
                -0.017944684252142906,
                0.03789165988564491,
                -0.13540349900722504,
                0.0485629178583622,
                -0.023557931184768677,
                -0.018718672916293144,
                -0.02197859063744545,
                0.029456298798322678,
                0.013287516310811043,
                -0.045216985046863556,
                -0.0068023731000721455,
                0.06057247892022133,
                0.004692034795880318,
                0.027454793453216553,
                0.007947525940835476,
                0.022361835464835167,
                -0.002118901116773486,
                0.031096365302801132,
                0.019055645912885666,
                -0.05247955769300461,
                -0.008482543751597404,
                -0.01400864776223898,
                -0.0020713675767183304,
                0.0033860669936984777,
                0.04599205031991005,
                -0.03205948323011398,
                -0.004794044885784388,
                0.04597268998622894,
                -0.01706385612487793,
                -0.04670298472046852,
                -0.05052700638771057,
                0.01995411515235901,
                0.03792675584554672,
                -0.03315718472003937,
                0.04813774675130844,
                -0.023898473009467125,
                -0.016476968303322792,
                0.028257515281438828,
                0.00758106354624033,
                0.02560361661016941,
                0.12359880656003952,
                -0.015072991140186787,
                -0.022207604721188545,
                0.06912244111299515,
                -0.030442705377936363,
                -0.05640890821814537,
                -0.05776851996779442,
                0.031526632606983185,
                -0.021786265075206757,
                -0.008864608593285084,
                0.023293660953640938,
                -0.0073943850584328175,
                0.038682688027620316,
                0.03577905893325806,
                -0.046205293387174606,
                -0.028087567538022995,
                0.042079221457242966,
                0.06676419824361801,
                0.012594759464263916,
                -0.014353178441524506,
                -0.1385437697172165,
                0.032405585050582886,
                0.042617376893758774,
                0.03463433310389519,
                -0.03521043434739113,
                0.053579408675432205,
                -0.02671760693192482,
                0.06210183724761009,
                -0.011758104898035526,
                0.01228352077305317,
                -0.02364712953567505,
                -0.038476064801216125,
                -0.022895365953445435,
                0.02217472717165947,
                0.006353262811899185,
                0.0691569596529007,
                0.030997399240732193,
                -0.0067984419874846935,
                0.004647057969123125,
                0.003008838277310133,
                -0.02401973493397236,
                -0.020996078848838806,
                -0.03758597746491432,
                -0.036584511399269104,
                -0.02133655734360218,
                0.045370008796453476,
                0.024611197412014008,
                -0.0005609721411019564,
                -0.07668282091617584,
                -0.039504654705524445,
                -0.011724337935447693,
                -0.010300188325345516,
                0.009441426023840904,
                0.036796290427446365,
                -0.014075969345867634,
                0.026099611073732376,
                -0.0012336034560576081,
                -0.010825656354427338,
                -0.02074393630027771,
                -0.022926101461052895,
                0.035191670060157776,
                0.009635630995035172,
                -0.05214141681790352,
                -0.013260033912956715,
                -0.01491368468850851,
                0.014763301238417625,
                -0.031952645629644394,
                0.04882184416055679,
                -0.023865701630711555,
                -0.014656039886176586,
                -0.03425518795847893,
                -0.03302498906850815,
                0.02209998480975628,
                0.023549314588308334,
                0.07157883793115616,
                0.0353257916867733,
                0.0014799159253016114,
                -0.011920214630663395,
                -0.04359433799982071,
                -0.0021464552264660597,
                0.060501229017972946,
                -0.04688088968396187,
                -0.03856571391224861,
                0.04449526220560074,
                0.012402176856994629,
                -0.02771811932325363,
                0.02282925881445408,
                0.0484640933573246,
                0.004084277432411909,
                -0.024249130859971046,
                0.02205720730125904,
                -0.028124501928687096,
                0.030989086255431175,
                0.03678145259618759,
                0.026629822328686714,
                0.0272061824798584,
                -0.0401495099067688,
                0.019125474616885185,
                -0.028538957238197327,
                0.00853321235626936,
                0.0936405137181282,
                -0.011298459023237228,
                0.02506999857723713,
                -0.001686696195974946,
                0.035909559577703476,
                0.010754802264273167,
                0.05135137960314751,
                -0.006934654898941517,
                -0.009847218170762062,
                0.02675219438970089,
                -0.028161706402897835,
                0.004537044558674097,
                0.003102713730186224,
                0.033218204975128174,
                0.006211128551512957,
                0.06253961473703384,
                0.04696524515748024,
                -0.06963760405778885,
                -0.08369575440883636,
                -0.026867380365729332,
                0.0021384197752922773,
                -0.016764868050813675,
                -0.053821999579668045,
                -0.03199620917439461,
                -0.09602246433496475,
                -0.02211410365998745,
                -0.03172844275832176,
                -0.014928755350410938,
                0.020782237872481346,
                -0.08493912220001221,
                -0.010566415265202522,
                0.039070289582014084,
                0.05838292837142944,
                0.0266589242964983,
                -0.003763458924368024,
                -0.033821381628513336,
                0.022978482767939568,
                0.016321275383234024,
                -0.016832441091537476,
                -0.020069215446710587,
                0.04264530539512634,
                -0.02783830650150776,
                -0.011783835478127003,
                0.02726083993911743,
                -0.004400075878947973,
                0.012748073786497116,
                -0.003981806803494692,
                0.04711659625172615,
                0.07802088558673859,
                -0.027018355205655098,
                -0.015332776121795177,
                0.02986566349864006,
                0.08393058180809021,
                -0.004819811787456274,
                -0.009251649491488934,
                -0.02276102639734745,
                0.04526032507419586,
                0.03937521576881409,
                0.03705378994345665,
                -0.002652434166520834,
                0.012588541954755783,
                0.031129561364650726,
                -0.07471374422311783,
                0.010832441970705986,
                -0.06441424787044525,
                -0.06284187734127045,
                -0.013542698696255684,
                -0.0016705458983778954,
                -0.0345771461725235,
                -0.01773797906935215,
                0.021809319034218788,
                -0.0015333290211856365,
                -0.041802335530519485,
                -0.013984532095491886,
                -0.017716698348522186,
                -0.036678750067949295,
                0.027292653918266296,
                -0.02975320629775524,
                0.03757324814796448,
                0.002969025168567896,
                0.029058201238512993,
                -0.011592580005526543,
                0.026358941569924355,
                0.03659778833389282,
                -0.02302616834640503,
                -0.004366979002952576,
                -0.06678827852010727,
                0.08603262156248093,
                -0.01980208232998848,
                0.0018868179759010673,
                0.002563528483733535,
                -0.05280186980962753,
                0.011056278832256794,
                -0.012650439515709877,
                -0.01721026562154293,
                0.0010539647191762924,
                -0.07009035348892212,
                -0.042277976870536804,
                -0.028365490958094597,
                0.035771261900663376,
                -0.04283718019723892,
                0.00748333428055048,
                -0.01669042371213436,
                0.0006003009038977325,
                0.02564496360719204,
                0.02407245896756649,
                -0.032086700201034546,
                0.00874379277229309,
                0.05231473594903946,
                -0.01124043669551611,
                -0.018882784992456436,
                -0.03569013997912407,
                -0.040932994335889816,
                -0.012097504921257496,
                0.021494969725608826,
                -0.042994968593120575,
                -0.08848702162504196,
                -0.09978767484426498,
                0.06655973941087723,
                -0.03221640735864639,
                -0.053522124886512756,
                0.060093216598033905,
                -0.005133252125233412,
                -0.020348217338323593,
                -0.007599875796586275,
                -0.027679262682795525,
                0.003935572691261768,
                0.009690620005130768,
                -0.030021993443369865,
                -0.024298904463648796,
                0.028457269072532654,
                -0.03820699080824852,
                0.002160249976441264,
                -0.021131612360477448,
                -0.004497686866670847,
                0.005434586200863123,
                0.013607322238385677,
                0.052517447620630264,
                -0.00452133035287261,
                -0.0017458531074225903,
                -0.05065978318452835,
                0.0183832086622715,
                0.03068132884800434,
                0.008436373434960842,
                -0.0023659970611333847,
                0.05767979100346565,
                -0.02101699262857437,
                0.004833632614463568,
                -0.030039168894290924,
                -0.018053630366921425,
                -0.06132816523313522,
                -0.022092431783676147,
                -0.007190505973994732,
                -0.0054341997019946575,
                -0.006796122994273901,
                -0.04697379469871521,
                0.012606902047991753,
                -0.02465743198990822,
                -0.03902186080813408,
                0.01751076430082321,
                0.048715732991695404,
                -0.014684860594570637,
                0.043424367904663086,
                -0.025295298546552658,
                0.0891985073685646,
                0.03725828230381012,
                0.01650991663336754,
                0.04482003673911095,
                0.012692974880337715,
                -0.010220099240541458,
                -0.008730565197765827,
                -0.028417527675628662,
                -0.06450245529413223,
                -0.00919799879193306,
                0.016244148835539818,
                0.05299805477261543,
                0.05913066864013672,
                -0.03330019861459732,
                -0.030271444469690323,
                -0.045314155519008636,
                -0.028371935710310936,
                -0.018535245209932327,
                0.011109939776360989,
                0.03640196472406387,
                0.07107913494110107,
                -0.012978849932551384,
                0.025368675589561462,
                0.013070616871118546,
                0.012108411639928818,
                -0.015611998736858368,
                0.029977163299918175,
                0.044193804264068604,
                0.007171958219259977,
                -0.07881099730730057,
                -0.06980659067630768,
                -0.0248462725430727,
                0.017088722437620163,
                -0.01909448578953743,
                0.009803736582398415,
                0.03905273973941803,
                0.0015780669637024403,
                -0.017230769619345665,
                -0.021212926134467125,
                0.016106199473142624,
                -0.05450519546866417,
                0.008646483533084393,
                -0.05875735729932785,
                0.053031791001558304,
                0.023152818903326988,
                -0.04175431653857231,
                0.04268733412027359,
                -0.03444444388151169,
                0.005757792387157679,
                -0.025575987994670868,
                0.011522489599883556,
                -0.04347958788275719,
                0.03335113823413849,
                0.010388202965259552,
                -0.020749138668179512,
                0.043189115822315216,
                0.016859665513038635,
                0.0011331478599458933,
                0.04979785904288292,
                0.02100413292646408,
                -0.021144723519682884,
                0.04201442748308182,
                0.01814042404294014,
                0.02860323153436184,
                0.0373678132891655,
                -0.03459816798567772,
                0.0022663739509880543,
                0.006266310811042786,
                -0.01886190101504326,
                -0.0667547732591629,
                -0.0494670607149601,
                -0.00010101207590196282,
                -7.220302133877093e-33,
                0.03119785711169243,
                0.022835031151771545,
                0.03845380246639252,
                0.0007061188225634396,
                -0.006602342240512371,
                -0.02239549532532692,
                -0.04044923558831215,
                0.042076218873262405,
                -0.07222315669059753,
                -0.04850761964917183,
                -0.06043436750769615,
                -0.059369418770074844,
                0.040026165544986725,
                -0.002757519716396928,
                -0.0011658872244879603,
                -0.016311218962073326,
                -0.007031172513961792,
                0.008552073501050472,
                0.004663020372390747,
                0.04899745434522629,
                -0.003641847288236022,
                -0.009105259552598,
                -0.018303733319044113,
                0.04356123134493828,
                -0.0058492557145655155,
                0.009109169244766235,
                0.0038190691266208887,
                -0.032038699835538864,
                -0.026084259152412415,
                -0.03077855333685875,
                -0.0072846366092562675,
                -0.00032604517764411867,
                0.0037261024117469788,
                0.004584828857332468,
                -0.01713300682604313,
                -0.015114093199372292,
                -0.004461319651454687,
                0.05406682938337326,
                -0.04271263629198074,
                0.02232063189148903,
                -0.11144550144672394,
                0.002254628809168935,
                -0.01245460007339716,
                0.0022988657001405954,
                0.015836602076888084,
                -0.02388807386159897,
                -0.03712547570466995,
                0.029061399400234222,
                0.06987869739532471,
                0.013327375054359436,
                -0.03153762221336365,
                -0.01893514394760132,
                -0.04717143252491951,
                -0.04169142246246338,
                0.030589476227760315,
                -0.006324421614408493,
                -0.0008778719347901642,
                -0.05177411809563637,
                -6.40091675450094e-05,
                0.010945415124297142,
                0.00518514821305871,
                -0.010511254891753197,
                0.018724864348769188,
                0.02985484153032303,
                -0.050770487636327744,
                -0.012314648367464542,
                0.12521636486053467,
                0.06349964439868927,
                -0.020711367949843407,
                0.02824809029698372,
                -0.03173613175749779,
                0.05474871024489403,
                0.025798413902521133,
                0.007943450473248959,
                -0.030384140089154243,
                0.01050582155585289,
                0.013642099685966969,
                0.015711862593889236,
                0.02410765364766121,
                -0.018150214105844498,
                -0.000579449231736362,
                -0.00796028133481741,
                0.0388764850795269,
                -0.04720573127269745,
                0.019833073019981384,
                -0.03885384649038315,
                0.009760992601513863,
                0.07127473503351212,
                0.005833392962813377,
                0.03653932362794876,
                0.00969826988875866,
                0.0014693073462694883,
                0.0054489802569150925,
                -0.04000835865736008,
                0.0035761971957981586,
                -0.057010944932699203,
                0.013973871245980263,
                0.015468809753656387,
                0.018956076353788376,
                -0.0520244836807251,
                -0.038745611906051636,
                -0.011925896629691124,
                -0.05940133333206177,
                0.10222423076629639,
                0.02911161445081234,
                0.01055088173598051,
                -0.015095092356204987,
                -0.021469848230481148,
                -1.128627536672866e-05,
                -0.035453785210847855,
                -0.021864186972379684,
                0.009530166164040565,
                0.03970903158187866,
                -0.0521649606525898,
                0.009974018670618534,
                0.017845667898654938,
                -0.010905022732913494,
                0.006154861766844988,
                -0.011570154689252377,
                -0.03668275848031044,
                -0.016680067405104637,
                0.007068582344800234,
                -0.016887888312339783,
                0.023547619581222534,
                -0.004351676907390356,
                0.0016457418678328395,
                0.046857334673404694,
                0.025867128744721413,
                0.0120288897305727,
                -0.02253100275993347,
                -0.01606091484427452,
                -0.05270663648843765,
                2.2298232238426863e-07,
                0.0007702065631747246,
                -0.012967873364686966,
                0.011293421499431133,
                -0.05028809979557991,
                -0.009761958383023739,
                -0.02365778386592865,
                0.024523256346583366,
                0.02865801937878132,
                0.0024037985131144524,
                -0.0026974710635840893,
                0.00383393163792789,
                0.0027896780520677567,
                -0.016717704012989998,
                -0.04128101095557213,
                -0.03788568079471588,
                -0.045767296105623245,
                -0.02244127355515957,
                0.03299649804830551,
                0.02621011808514595,
                0.002927503315731883,
                0.10439427942037582,
                -0.01843557134270668,
                0.0046306634321808815,
                -0.04505603015422821,
                -0.025852873921394348,
                -0.01992497593164444,
                0.02365599200129509,
                0.016105366870760918,
                0.09771402925252914,
                0.0007699258276261389,
                -0.004102583974599838,
                0.026651104912161827,
                -0.012858645059168339,
                0.03282231464982033,
                -0.010643184185028076,
                0.000485286524053663,
                0.02078109048306942,
                0.02681611478328705,
                0.047160763293504715,
                0.10003255307674408,
                -0.06255678832530975,
                -0.03960498794913292,
                -0.035849809646606445,
                0.02786925993859768,
                -0.0007269939524121583,
                0.04176932945847511,
                0.02059490792453289,
                0.08775787055492401,
                -0.0543411560356617,
                0.021872401237487793,
                -0.054130855947732925,
                0.020989829674363136,
                0.06255607306957245,
                -0.03098108246922493,
                0.0047208089381456375,
                -0.01019968930631876,
                -0.02759828418493271,
                0.030285179615020752,
                0.016413990408182144,
                -0.020615993067622185,
                -0.03530728816986084,
                -0.018381593748927116,
                -0.013423023745417595,
                0.05335039272904396,
                0.035126976668834686,
                -0.016408240422606468,
                0.014404230751097202,
                4.714945436304435e-35,
                -9.561060869600624e-05,
                0.012062786146998405,
                0.03165784850716591,
                0.017859317362308502,
                0.009028793312609196,
                -0.015849998220801353,
                0.008906811475753784,
                -0.019706591963768005,
                -0.026841649785637856,
                0.012716153636574745,
                -0.016447583213448524,
                -0.04348258301615715,
                -0.029778888449072838,
                -0.03911971673369408,
                0.019519327208399773,
                -0.02142840251326561,
                -9.028072963701561e-05,
                0.005129225552082062,
                0.04991162568330765,
                -0.046971097588539124,
                0.006125899031758308,
                0.08195419609546661,
                0.0057904887944459915,
                0.006060614716261625,
                0.002445630729198456,
                0.024785609915852547,
                -0.024428289383649826,
                -0.014865516684949398,
                0.025189025327563286,
                -0.08516959846019745,
                -0.024662354961037636,
                -0.04745810851454735,
                -0.003616356523707509,
                -0.03050266019999981,
                0.02131565473973751,
                0.0009543045307509601,
                0.04132243990898132,
                -0.009949794970452785,
                -0.03727133944630623,
                0.03885914757847786,
                0.0573154091835022,
                0.00021815745276398957,
                0.03253443166613579,
                -0.01221675705164671,
                0.06954138725996017,
                1.312009771936573e-06,
                0.024570109322667122,
                0.04745054990053177,
                0.01880682446062565,
                0.03581380471587181,
                0.017362935468554497,
                0.017575543373823166,
                -0.05325650796294212,
                -0.05617411807179451,
                0.006112277042120695,
                0.014868670143187046,
                0.011443426832556725,
                0.009815533645451069,
                -0.024962879717350006,
                -0.05459454283118248,
                -0.024205844849348068,
                0.009558378718793392,
                -0.018790554255247116,
                -0.022087788209319115,
                -0.03172144666314125,
                0.03856455534696579,
                -0.020609447732567787,
                0.0029262141324579716,
                0.018752116709947586,
                0.051303453743457794,
                0.05367325246334076,
                -0.04544110968708992,
                0.008450105786323547,
                0.010943376459181309,
                -0.03132857754826546,
                0.04343272000551224,
                0.02892148680984974,
                0.0006074985140003264,
                0.023343026638031006,
                0.005431962199509144,
                0.0440792590379715,
                0.035813625901937485,
                -0.0065758186392486095,
                -0.000974592927377671,
                0.02143559604883194,
                -0.030599622055888176,
                0.03338411822915077,
                -0.0043941219337284565,
                0.03503895923495293,
                0.002913976553827524,
                -0.023100215941667557,
                -0.023525958880782127,
                -0.01905542053282261,
                -0.018486233428120613,
                0.02589678205549717,
                0.012623336166143417,
                -0.015525267459452152,
                -0.027429841458797455,
                0.021041324362158775,
                0.021286122500896454,
                0.027966128662228584,
                0.04668011516332626,
                -0.005426474846899509,
                0.021828506141901016,
                -0.0008623651810921729,
                0.03146204724907875,
                -0.017223525792360306,
                0.014760802499949932,
                -0.04797844961285591,
                0.015142377465963364,
                -0.03181992471218109,
                -0.07061368227005005,
                0.03406403586268425,
                0.06484848260879517,
                0.03655657544732094,
                -0.07860639691352844,
                0.005786328576505184,
                -0.005981937516480684,
                0.018103426322340965,
                -0.025903107598423958,
                0.03460772708058357,
                -0.0916864424943924,
                -0.03559546172618866,
                0.01467872690409422,
                -0.03526483103632927,
                0.03164738044142723,
                0.02328753098845482,
                -0.005036903545260429,
                0.010362190194427967,
                0.02582140825688839,
                -0.022635802626609802,
                -0.022683434188365936,
                0.0013554117176681757,
                -0.04141790047287941,
                -0.028809068724513054,
                -0.010286068543791771,
                0.03891982510685921,
                -0.02950957417488098,
                0.02885376289486885,
                0.04443034529685974,
                0.04998168349266052,
                -0.03219159319996834,
                0.0381787046790123,
                -0.028739644214510918,
                -0.0274844691157341,
                -0.026937615126371384,
                0.031861983239650726,
                -0.03598407283425331,
                0.07040353119373322,
                -0.03909533843398094,
                -0.07726384699344635,
                0.023947304114699364,
                -0.018636111170053482,
                -0.004113425966352224,
                0.056376248598098755,
                0.012771185487508774,
                -0.004253210499882698,
                0.04601460322737694,
                0.01953877881169319,
                0.048344843089580536,
                -0.027197424322366714,
                -0.030720200389623642,
                0.004972806200385094,
                0.011601626873016357,
                -0.020262081176042557,
                0.058312494307756424,
                0.037617236375808716,
                0.01176376547664404,
                0.006266073789447546,
                -0.06055792048573494,
                0.0488632433116436,
                -0.012521078810095787,
                1.920400063681882e-05,
                0.012129233218729496,
                -0.003621066454797983,
                -0.004568359814584255,
                -0.03712170198559761,
                -0.033834297209978104,
                -0.05890234187245369,
                -0.07041686773300171,
                0.005663098767399788,
                -0.003036763519048691,
                -0.014239853248000145,
                -0.026150504127144814,
                0.08831702917814255,
                0.009391981177031994,
                -0.003788670524954796,
                0.04633908346295357,
                0.045721158385276794,
                -0.013371394015848637,
                0.030677763745188713,
                0.03695164620876312,
                -0.09504860639572144,
                0.05895792692899704,
                -0.02783099375665188,
                -0.039034828543663025,
                -0.023140668869018555,
                0.0037231335882097483,
                -0.028374897316098213,
                -0.07004822045564651,
                -0.053772252053022385,
                0.03619015961885452,
                -0.018765214830636978,
                -0.015812693163752556,
                0.0019151661545038223,
                0.03970784693956375,
                0.012719903141260147,
                0.028402969241142273,
                0.014374064281582832,
                -0.0026039048098027706,
                0.04882539063692093,
                -0.06186631694436073,
                0.01381539274007082,
                -0.0423172302544117,
                -0.01180938072502613,
                -0.006012259051203728,
                -0.043939705938100815,
                -0.011742915026843548,
                -0.008544838055968285,
                -0.04601428285241127,
                0.010789982974529266,
                0.028188321739435196,
                0.022188834846019745,
                0.013759768567979336,
                0.024173032492399216,
                -0.014046846888959408,
                -0.010160583071410656,
                -0.004622636362910271,
                -0.035487908869981766,
                -0.04363727942109108,
                -0.01068603340536356,
                -0.008209769614040852,
                -0.02229570597410202,
                -0.00211661821231246,
                -0.0379912443459034,
                -0.022568540647625923,
                0.023578960448503494,
                0.03828791528940201,
                -0.01145110186189413,
                -0.04223103076219559,
                0.022455774247646332,
                0.04185541346669197,
                -0.000104983126220759,
                0.013062489219009876,
                -0.027589933946728706,
                -0.01000240258872509,
                0.04680371657013893,
                0.06624507158994675,
                0.09212802350521088,
                0.023084254935383797,
                -0.1048920750617981,
                0.0071515487506985664,
                -0.04136208817362785,
                0.027049154043197632,
                -0.026556290686130524,
                0.020701609551906586,
                -0.01758819818496704,
                -0.030265333130955696,
                0.03592851758003235,
                0.02870607189834118,
                0.0038717063143849373,
                -0.03996487334370613,
                -0.03986247256398201,
                0.03307797759771347,
                0.027543246746063232,
                0.053458090871572495,
                -0.01974445953965187,
                0.02028079889714718,
                0.02758726105093956,
                0.02023494616150856,
                -0.0827212855219841,
                0.11709374934434891,
                -0.06263789534568787,
                -0.02887798473238945,
                0.019351471215486526,
                0.016522403806447983,
                -0.0070573738776147366,
                -0.03278428316116333,
                -0.024954913184046745,
                -0.061002302914857864,
                0.0514458604156971,
                -0.04324495047330856,
                0.025141727179288864,
                0.04219377413392067,
                0.0025282062124460936,
                0.02463383413851261,
                0.046109944581985474,
                0.010484005324542522,
                -0.0148171940818429,
                -0.11277304589748383,
                0.04143813997507095,
                0.026883598417043686,
                0.013698246330022812,
                -0.006060215178877115,
                -0.020193759351968765,
                0.016182301566004753,
                0.015918882563710213,
                0.08478862047195435,
                -0.0021035640966147184,
                -0.05127471312880516,
                0.036128703504800797,
                -0.004204283468425274,
                -0.052145201712846756,
                0.017472628504037857,
                -0.07587791234254837,
                -0.03163618966937065,
                0.022090809419751167,
                0.028575440868735313,
                0.004040634259581566,
                0.020403822883963585,
                0.025689542293548584,
                -0.0002630712988320738,
                0.021204903721809387,
                0.061077676713466644,
                -0.008770876564085484,
                0.008762543089687824,
                0.011304948478937149,
                0.024791225790977478,
                -0.03324016183614731,
                -0.0322185717523098,
                -0.007286114618182182,
                -0.010457026772201061,
                0.04429676756262779,
                -0.0018030103528872132,
                0.051636893302202225,
                4.5361874072114006e-05,
                -0.0389213040471077,
                0.00947739277034998,
                0.0009201544453389943,
                -0.0019113447051495314,
                0.04574979841709137,
                0.036149196326732635,
                0.05735741928219795,
                -0.02424485608935356,
                0.0027885071467608213,
                0.037256237119436264,
                0.04405265673995018,
                -0.005820493679493666,
                0.023219630122184753,
                0.026384510099887848,
                -0.031569045037031174,
                0.005347390193492174,
                0.07264464348554611,
                -0.018760129809379578,
                0.05549008026719093,
                0.10986214131116867,
                -0.027371857315301895,
                -0.013280009850859642,
                -0.07642154395580292,
                -0.04339761659502983,
                0.04260063171386719,
                -0.030412182211875916,
                -0.04886436462402344,
                -0.03852942958474159,
                -0.015672342851758003,
                -0.0003379657573532313,
                0.0302367452532053,
                -0.014165730215609074,
                -0.007782311644405127,
                0.027784135192632675,
                -0.056651704013347626,
                0.07266507297754288,
                0.020896362140774727,
                -0.014187082648277283,
                0.014678467065095901,
                -0.06794090569019318,
                0.02538662776350975,
                -0.00858001597225666,
                0.05775022134184837,
                -0.020568525418639183,
                -0.011096748523414135,
                -0.001618883921764791,
                -0.023692579939961433,
                0.02191784605383873,
                -0.01255626231431961,
                -0.05180075019598007,
                0.0038457370828837156,
                0.030095916241407394,
                0.059944357722997665,
                -0.0062643359415233135,
                0.017205480486154556,
                -0.04051969572901726,
                0.020688192918896675,
                -0.056514233350753784,
                -0.023931462317705154,
                -0.014294473454356194,
                0.04279101639986038,
                0.010526416823267937,
                0.03346561640501022,
                0.03385424241423607,
                -0.032685574144124985,
                0.004600345157086849,
                -0.031072435900568962,
                0.008737089112401009,
                0.023603659123182297,
                0.009723849594593048,
                -0.020001471042633057,
                -0.008216802030801773,
                0.018786266446113586,
                0.007003327831625938,
                0.012312016449868679,
                0.007287808693945408,
                0.0068863676860928535,
                -0.09847962111234665,
                0.003278884571045637,
                -0.0011333659058436751,
                -0.008441726677119732,
                -0.00845339521765709,
                0.01299755647778511,
                0.01606440171599388,
                0.05421772226691246,
                0.009496145881712437,
                0.034305837005376816,
                -0.048974402248859406,
                0.010220283642411232,
                -0.029848022386431694,
                -0.0309336856007576,
                0.04453461989760399,
                0.0033758000936359167,
                -0.07184717804193497,
                0.0019541680812835693,
                0.07278874516487122,
                0.013145525008440018,
                0.04343998059630394,
                -0.07854374498128891,
                -0.014410324394702911,
                0.01924963667988777,
                0.0012409334303811193,
                -0.03867621347308159,
                -0.01878354139626026,
                -0.045111555606126785,
                -0.017076851800084114,
                0.009219747968018055,
                0.05306606739759445,
                -0.056068193167448044,
                0.07830000668764114,
                -0.026011483743786812,
                -0.06237969547510147,
                -0.0059812734834849834,
                -0.012758200988173485,
                -0.014940403401851654,
                -0.05902347341179848,
                -0.06288576871156693,
                -0.062204811722040176,
                0.049875639379024506,
                -0.0061574368737638,
                -0.06616095453500748,
                -0.058274876326322556,
                0.025727568194270134,
                -0.012294594198465347,
                0.018657058477401733,
                0.02968231961131096,
                -0.003099350258708,
                -0.016941389068961143,
                -0.0075050657615065575,
                0.03338312730193138,
                0.07997208833694458,
                0.0171539094299078,
                -0.05373862013220787,
                -0.06885326653718948,
                -0.005318423733115196,
                0.06375777721405029,
                0.0015332550974562764,
                0.01799902878701687,
                -0.05215756222605705,
                -0.03105488233268261,
                -0.021351991221308708,
                -0.09509562700986862,
                0.04204021766781807,
                -0.006937152706086636,
                0.03378530591726303,
                0.05036387965083122,
                0.027020277455449104,
                -0.01734166592359543,
                -0.03198736533522606,
                -0.013049840927124023,
                -0.024485958740115166,
                -0.02104474976658821,
                -0.06855069100856781,
                -0.01644858345389366,
                -0.020733542740345,
                -0.05459633097052574,
                0.022188158705830574,
                -0.04618961364030838,
                0.026940329000353813,
                -0.039223723113536835,
                -0.02641720324754715,
                -0.005553572904318571,
                0.004106104373931885,
                -0.013190834783017635,
                -0.001184034743346274,
                0.03706124797463417,
                -0.012255119159817696,
                -0.009601177647709846,
                0.009792052209377289,
                -0.01456417329609394,
                0.028136350214481354,
                0.0787673145532608,
                -0.025269506499171257,
                0.03968879207968712,
                -0.0014815565664321184,
                0.05204642936587334,
                -0.10418377816677094,
                -0.016745397821068764,
                -0.03101225569844246,
                0.03906992822885513,
                0.046364862471818924,
                0.015475727617740631,
                0.07595659047365189,
                -0.06437898427248001,
                0.03821844980120659,
                -0.024431493133306503,
                -0.0054706246592104435,
                0.021826501935720444,
                -0.002190573140978813,
                -0.0003084490308538079,
                0.061616718769073486,
                -0.02685202844440937,
                0.00016538970521651208,
                -0.010973982512950897,
                0.012842678464949131,
                0.022090688347816467,
                0.004293904174119234,
                0.007120652589946985,
                0.003321646247059107,
                -0.01853206753730774,
                -0.011973317712545395,
                -0.0033455833327025175,
                -0.01324236486107111,
                0.021465333178639412,
                -0.0020196593832224607,
                -0.01345429103821516,
                -0.023357581347227097,
                0.022079922258853912,
                -0.03596806898713112,
                0.07320468872785568,
                -0.049389101564884186,
                0.018020786345005035,
                0.06319647282361984,
                -0.01946951076388359,
                0.011037429794669151,
                -0.03622303158044815,
                -0.026094136759638786,
                0.006454039365053177,
                0.04907151684165001,
                -0.02135547436773777,
                0.09370818734169006,
                0.06325949728488922,
                0.017827393487095833,
                -0.003664424642920494,
                -0.00045860683894716203,
                -0.019131531938910484,
                0.01721877232193947,
                -0.008019612170755863,
                0.03910130634903908,
                0.03537478297948837,
                -0.032612692564725876,
                0.026887694373726845,
                0.005963063333183527,
                -0.0011294493451714516,
                0.04217558726668358,
                -0.013151349499821663,
                -0.00524186110123992,
                0.04848949611186981,
                0.07773474603891373,
                0.014458036050200462,
                -5.4609008007510046e-33,
                -0.02057351917028427,
                -0.047145672142505646,
                -0.0003823061706498265,
                -0.0011282929917797446,
                -0.10183858871459961,
                -0.029090769588947296,
                0.009556678123772144,
                0.017446160316467285,
                -0.08140601217746735,
                -0.010150430724024773,
                -0.02281278744339943,
                -0.01593172922730446,
                0.0040076738223433495,
                0.023848367854952812,
                0.04069836065173149,
                -0.017333146184682846,
                0.0022734063677489758,
                0.015753474086523056,
                -0.016581004485487938,
                -0.036423008888959885,
                -0.05624888464808464,
                0.023555133491754532,
                -0.003718520049005747,
                0.04543662816286087,
                0.025608215481042862,
                -0.02494955249130726,
                -0.04313896596431732,
                -0.0017385617829859257,
                -0.03327201306819916,
                -0.06794607639312744,
                0.006162955425679684,
                0.021002275869250298,
                0.008685506880283356,
                -0.011302894912660122,
                -0.02249571681022644,
                0.024819806218147278,
                -0.027934547513723373,
                -0.05316311493515968,
                -0.07532769441604614,
                0.03687386214733124,
                -0.007737343665212393,
                0.004695994779467583,
                0.027022507041692734,
                -0.0047908504493534565,
                -0.06259133666753769,
                -0.07770316302776337,
                -0.02493671327829361,
                -0.025706464424729347,
                0.06486654281616211,
                -0.02126200869679451,
                -0.044701915234327316,
                -0.011072992347180843,
                -0.014718777500092983,
                -0.014054683968424797,
                0.07243235409259796,
                0.027411017566919327,
                0.005048524122685194,
                0.02342357113957405,
                0.013711037114262581,
                0.0057209269143640995,
                -0.021925995126366615,
                0.05991338565945625,
                0.03793932870030403,
                -0.06044921651482582,
                -0.0019830255769193172,
                -0.007830079644918442,
                -0.030072705820202827,
                -0.002772072795778513,
                0.017061034217476845,
                -0.04410944879055023,
                -0.05409404635429382,
                0.09552476555109024,
                -0.024471404030919075,
                -0.05921493098139763,
                0.002108264248818159,
                -0.05399766564369202,
                -0.03941820561885834,
                -0.02415391616523266,
                0.020863601937890053,
                0.06437326222658157,
                -0.02458968386054039,
                0.024084122851490974,
                0.01720988191664219,
                -0.02173973061144352,
                0.0025806985795497894,
                -0.019244302064180374,
                0.010626114904880524,
                0.058368753641843796,
                -0.03702366352081299,
                0.0284236129373312,
                0.03458976373076439,
                -0.0834108516573906,
                -0.01342364214360714,
                0.010400144383311272,
                0.10109516978263855,
                0.016904348507523537,
                0.022849146276712418,
                0.001967556541785598,
                -0.007169011514633894,
                0.035934850573539734,
                -0.015707645565271378,
                0.01485268771648407,
                -0.08098001033067703,
                0.017099104821681976,
                0.035951752215623856,
                -0.016008751466870308,
                -0.07686595618724823,
                -0.021052522584795952,
                -0.0026609408669173717,
                0.04396781325340271,
                -0.019813118502497673,
                -0.041858378797769547,
                0.031535737216472626,
                -0.0016093867598101497,
                -0.0044192178174853325,
                0.0005366248078644276,
                0.004073965363204479,
                -0.07056599855422974,
                -0.0008041977416723967,
                0.023419244214892387,
                -0.0013939483324065804,
                -0.0038067607674747705,
                -0.02319895289838314,
                0.020894348621368408,
                -0.03407827019691467,
                -0.01012229360640049,
                0.08510877937078476,
                -0.022955143824219704,
                0.0030928808264434338,
                -0.0617334209382534,
                -0.040756359696388245,
                0.005499489139765501,
                1.9102436965567904e-07,
                -0.02353648841381073,
                0.04294178634881973,
                0.03311556950211525,
                0.04611586779356003,
                -0.0007473539444617927,
                0.02119755931198597,
                -0.04043853282928467,
                0.015198473818600178,
                0.02828456461429596,
                -0.013334169052541256,
                0.05233006179332733,
                -0.02945292741060257,
                0.025548165664076805,
                -0.039028823375701904,
                0.009274258278310299,
                -0.004325773101300001,
                0.052838560193777084,
                0.05706879869103432,
                0.013506232760846615,
                0.015713682398200035,
                0.012650774791836739,
                0.02277122624218464,
                -0.013447022065520287,
                0.009764399379491806,
                -0.056625157594680786,
                0.0035292913671582937,
                0.006716476753354073,
                -0.017798611894249916,
                0.06034538149833679,
                0.015106644481420517,
                0.03657907620072365,
                0.011419708840548992,
                0.0006348345195874572,
                0.03433895483613014,
                -0.000357273209374398,
                -0.022493911907076836,
                0.06775566190481186,
                0.006481189746409655,
                -0.022223547101020813,
                0.057421982288360596,
                -0.0164497010409832,
                0.038556959480047226,
                0.038670122623443604,
                0.03724781796336174,
                -0.006093703676015139,
                0.012285923585295677,
                0.005459011998027563,
                -0.005830645095556974,
                -0.05594542622566223,
                0.001050956780090928,
                0.0007099588983692229,
                0.06306498497724533,
                0.043574683368206024,
                -0.03760172054171562,
                0.023609092459082603,
                0.029032405465841293,
                -0.03486734256148338,
                0.0011232802644371986,
                0.006089712958782911,
                0.03422490134835243,
                -0.04545048996806145,
                -0.014401452615857124,
                0.02319691702723503,
                0.003940479829907417,
                0.04698022082448006,
                -0.005566594656556845,
                -0.05511431768536568,
                6.734341129098022e-35,
                -0.012021316215395927,
                -0.008849271573126316,
                -0.0023831562139093876,
                0.06248542293906212,
                -0.0033044489100575447,
                -0.012914927676320076,
                0.017408909276127815,
                0.021719591692090034,
                0.010575736872851849,
                0.011386492289602757,
                -0.03675946593284607
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'5\\xb1G\\xe5a\\x1d\\xa8\\x99\\xc6\\x11@\\xca\\xd61G\\xbf'",
        "id__": "26d24a63-e347-4aea-9666-70fee72b8430"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "MSc/Climate Change & Policy (Distinction)",
            "rep": [
                -0.014365741051733494,
                0.036925897002220154,
                -0.001900278963148594,
                0.056482329964637756,
                -0.029229454696178436,
                0.005313816014677286,
                0.020851727575063705,
                -0.015221118927001953,
                0.019391434267163277,
                -0.019807765260338783,
                0.06471545249223709,
                0.04742102697491646,
                -0.019030170515179634,
                0.028997479006648064,
                0.04120413213968277,
                -0.0445716418325901,
                0.05855097994208336,
                -0.044051386415958405,
                -0.030194582417607307,
                -0.024110177531838417,
                -0.0028798095881938934,
                -0.048432886600494385,
                -0.0243392251431942,
                0.008365914225578308,
                0.02551805041730404,
                -0.03957313671708107,
                0.013953696005046368,
                0.02384083904325962,
                0.03066319227218628,
                -0.03062182292342186,
                0.11215391755104065,
                0.025145886465907097,
                0.02039233222603798,
                -0.024569492787122726,
                1.2659185131269624e-06,
                -0.023480985313653946,
                -0.04540366306900978,
                -0.006264452822506428,
                0.05449918657541275,
                -0.022052140906453133,
                0.040402624756097794,
                0.053217142820358276,
                0.005784062668681145,
                0.024549517780542374,
                -0.024244172498583794,
                0.10826128721237183,
                0.020806103944778442,
                -0.02014373615384102,
                0.004852010868489742,
                -0.01380056980997324,
                -0.001944327843375504,
                -0.04273237660527229,
                -0.034770503640174866,
                -0.02380494959652424,
                -0.04481612145900726,
                0.014298062771558762,
                0.031022917479276657,
                -0.02000677026808262,
                0.006967795547097921,
                0.02482495829463005,
                -0.027669763192534447,
                0.06984389573335648,
                -0.05200624465942383,
                -0.013711870647966862,
                0.020667677745223045,
                0.04419103264808655,
                0.06630480289459229,
                0.0071242256090044975,
                -0.02794450893998146,
                -0.0044188108295202255,
                0.06985142081975937,
                0.019361233338713646,
                0.02236863225698471,
                0.013635917566716671,
                0.03648332133889198,
                -0.014554576948285103,
                0.021711640059947968,
                -0.019980691373348236,
                -0.0023607902694493532,
                -0.024593695998191833,
                0.05503184348344803,
                0.012741793878376484,
                -0.028988739475607872,
                0.06699780374765396,
                -0.057983703911304474,
                0.031263940036296844,
                -0.04109956696629524,
                -0.023225421085953712,
                0.02331751585006714,
                -0.008607232943177223,
                0.08735209703445435,
                0.011747288517653942,
                -0.040144182741642,
                0.010293715633451939,
                -0.04424533247947693,
                -0.012134668417274952,
                0.05109076201915741,
                0.03405638784170151,
                -0.01799933798611164,
                -0.03668450564146042,
                -0.009210437536239624,
                0.006047153379768133,
                0.03035666048526764,
                0.025911293923854828,
                0.058915358036756516,
                0.061367664486169815,
                -0.024613451212644577,
                0.053213927894830704,
                0.007475289050489664,
                -0.005769811570644379,
                -0.04646822065114975,
                -0.019331853836774826,
                0.02587837725877762,
                -0.05592991039156914,
                -0.054987967014312744,
                0.0653039813041687,
                0.03958398476243019,
                -0.06720715016126633,
                -0.05599801987409592,
                0.010678283870220184,
                0.010363615117967129,
                -0.011263985186815262,
                -0.02960868366062641,
                -0.00928459782153368,
                0.007641713134944439,
                0.04596143215894699,
                -0.009064451791346073,
                0.031751055270433426,
                0.0396786704659462,
                0.08088816702365875,
                -0.020615478977560997,
                -0.02184067852795124,
                0.004031200893223286,
                0.011102166026830673,
                0.011121964082121849,
                0.033596474677324295,
                -0.009176233783364296,
                0.016300205141305923,
                0.03298879787325859,
                -0.013070264831185341,
                -0.02192145586013794,
                -0.012522534467279911,
                0.036724694073200226,
                0.017593322321772575,
                -0.06246223300695419,
                -0.03709002211689949,
                0.03322240710258484,
                -0.016796577721834183,
                -0.038371071219444275,
                -0.06469926238059998,
                -0.029341276735067368,
                -0.02778792567551136,
                -0.004571013618260622,
                -0.07125912606716156,
                0.042064402252435684,
                0.06704381108283997,
                0.03481775522232056,
                0.00857490859925747,
                0.009768130257725716,
                0.0252186618745327,
                0.0036883701104670763,
                0.022196587175130844,
                0.0012982861371710896,
                -0.02114088460803032,
                -0.057013001292943954,
                0.04285533353686333,
                0.0035689387004822493,
                0.00017522375856060535,
                -0.05605188384652138,
                -0.0159564558416605,
                0.016403168439865112,
                -0.06391749531030655,
                -0.020093699917197227,
                0.031008752062916756,
                -0.00577580975368619,
                0.010678419843316078,
                -0.021042026579380035,
                -0.010872762650251389,
                -0.051238059997558594,
                0.042486004531383514,
                -0.03249472379684448,
                0.010109330527484417,
                0.06472351402044296,
                -0.016390090808272362,
                0.025009270757436752,
                -0.012335428036749363,
                -0.008408160880208015,
                -0.022565746679902077,
                0.0039769914001226425,
                -0.04498829320073128,
                0.006259727291762829,
                0.03080086037516594,
                -0.0314520038664341,
                -0.02283332124352455,
                -0.004069468006491661,
                0.004653474316000938,
                0.017204375937581062,
                -0.04040391743183136,
                -0.002607411937788129,
                0.032485995441675186,
                -0.01321781612932682,
                -0.01569286361336708,
                0.003517561126500368,
                0.008877730928361416,
                -0.04345253109931946,
                0.005137566942721605,
                -0.05816718935966492,
                -0.057944633066654205,
                -0.0723872110247612,
                0.07880868762731552,
                0.034557733684778214,
                -0.00951947271823883,
                0.0215706005692482,
                0.01348752062767744,
                -0.011855030432343483,
                -0.04447433724999428,
                -0.02100961282849312,
                0.009716520085930824,
                0.001292891800403595,
                -0.0005853475886397064,
                0.009544632397592068,
                0.04467570036649704,
                0.026914719492197037,
                -0.005830153822898865,
                0.016207361593842506,
                0.031565334647893906,
                0.006265828851610422,
                -0.031750939786434174,
                0.04559421166777611,
                -0.005033492110669613,
                -0.021060721948742867,
                0.014976447448134422,
                -0.028892531991004944,
                -0.013422949239611626,
                -0.007248271256685257,
                0.02162160351872444,
                0.023275146260857582,
                0.028865622356534004,
                0.013471705839037895,
                -0.04488280415534973,
                -0.0003632978769019246,
                0.060075633227825165,
                -0.005014644470065832,
                0.048308003693819046,
                0.02180047519505024,
                0.03970139101147652,
                -0.010857697576284409,
                0.019694732502102852,
                -0.02845819853246212,
                -0.015417560003697872,
                0.005254294257611036,
                -0.0032636283431202173,
                -0.013401026837527752,
                0.009875040501356125,
                0.014517048373818398,
                0.0038378569297492504,
                -0.03236139193177223,
                -0.03266739845275879,
                -0.04110286384820938,
                0.02236226573586464,
                -0.018031571060419083,
                -0.015431971289217472,
                -0.005651571787893772,
                0.019810570403933525,
                -0.03255472332239151,
                -0.04697960615158081,
                0.002976882504299283,
                0.055646732449531555,
                0.03314691036939621,
                0.0025273121427744627,
                0.011226270347833633,
                -0.006356075871735811,
                -0.013506109826266766,
                -0.014085774309933186,
                0.019447656348347664,
                0.05414273217320442,
                -0.05165714770555496,
                0.018821345642209053,
                -0.0398741140961647,
                0.016580013558268547,
                0.028162585571408272,
                0.07504012435674667,
                -0.0702720433473587,
                0.020540978759527206,
                -0.0020094842184334993,
                0.03083634190261364,
                -0.0032234792597591877,
                -0.06901392340660095,
                0.0013536525657400489,
                0.025716273114085197,
                -0.012405412271618843,
                0.008926652371883392,
                -0.005059052724391222,
                -0.021643642336130142,
                -0.021822072565555573,
                0.010990248993039131,
                -0.0056975544430315495,
                -0.011084242723882198,
                0.025552494451403618,
                0.0834793746471405,
                -0.007484415080398321,
                -0.04339120537042618,
                -0.007954356260597706,
                0.01059706136584282,
                0.02212994359433651,
                0.05424416810274124,
                -0.00919590424746275,
                0.059864483773708344,
                -0.011669670231640339,
                -0.020745528861880302,
                -0.032347556203603745,
                0.012379053980112076,
                0.11853431165218353,
                0.016705850139260292,
                0.01356483157724142,
                0.006188775412738323,
                -0.023471159860491753,
                -0.01835264079272747,
                0.010301347821950912,
                0.02681918628513813,
                -0.028334742411971092,
                -0.03343198820948601,
                -0.052752528339624405,
                -0.018630236387252808,
                0.01165592111647129,
                -0.0937507376074791,
                -0.0503581166267395,
                0.023136841133236885,
                0.032414235174655914,
                -0.09348901361227036,
                -0.0013443223433569074,
                -0.02937263995409012,
                0.015920042991638184,
                -0.017933985218405724,
                -0.11537280678749084,
                -0.042943548411130905,
                0.000988443149253726,
                -0.07178044319152832,
                -0.06279376149177551,
                0.030156712979078293,
                -0.03480449318885803,
                -0.03217200189828873,
                -0.006859251298010349,
                -0.008842376992106438,
                0.0035853651352226734,
                0.05807560682296753,
                0.004914863500744104,
                -0.006540019065141678,
                -0.013976311311125755,
                0.008838455192744732,
                0.04212992638349533,
                0.0609632171690464,
                -0.007031559012830257,
                0.01036093384027481,
                -0.01903734728693962,
                -0.03464309871196747,
                0.0027479412965476513,
                0.0707465186715126,
                0.006625565234571695,
                -0.012538395822048187,
                0.002101230202242732,
                -0.037996117025613785,
                -0.0317704901099205,
                -0.02043456956744194,
                0.0006449284264817834,
                0.006676599383354187,
                0.03630729019641876,
                -0.026575420051813126,
                0.023363685235381126,
                -0.01280571985989809,
                0.0032347675878554583,
                -0.03321651741862297,
                -0.04610364884138107,
                0.011525340378284454,
                -0.06370838731527328,
                0.029635393992066383,
                -0.011980179697275162,
                -0.030979929491877556,
                -0.015641117468476295,
                -0.00477978028357029,
                0.017633724957704544,
                0.0055126589722931385,
                0.05529967322945595,
                0.012358429841697216,
                -0.028173163533210754,
                -0.06575501710176468,
                0.015637816861271858,
                -0.030901145190000534,
                0.08311320841312408,
                0.057326801121234894,
                0.00038511684397235513,
                -0.042548492550849915,
                -0.008541996590793133,
                0.014532366767525673,
                0.0017717606388032436,
                0.031666349619627,
                -0.08221389353275299,
                -0.04237915948033333,
                0.02666269615292549,
                0.00775609677657485,
                0.05840077996253967,
                0.02282130718231201,
                0.008622968569397926,
                -0.00800065416842699,
                0.037251122295856476,
                -0.014800281263887882,
                0.0677851140499115,
                0.008836736902594566,
                -0.03235067054629326,
                -0.02392684854567051,
                0.04483715817332268,
                -0.047881197184324265,
                -0.010324408300220966,
                0.021426813676953316,
                -0.06938712298870087,
                -0.017886878922581673,
                -0.008978573605418205,
                -0.01817818358540535,
                -0.032281067222356796,
                0.03360678255558014,
                -0.001868903054855764,
                0.05111565813422203,
                0.057625554502010345,
                -0.024024082347750664,
                0.013072093948721886,
                0.01697206124663353,
                0.045748285949230194,
                0.010610410943627357,
                -0.0022694410290569067,
                -0.010222263634204865,
                0.07261601835489273,
                -0.0631861463189125,
                -0.05459319055080414,
                0.02442464418709278,
                -0.0002703532518353313,
                0.021933645009994507,
                -0.002870835131034255,
                -0.07193153351545334,
                0.008039283566176891,
                0.07009134441614151,
                -0.011874313466250896,
                0.027791084721684456,
                0.04130200669169426,
                -0.02550259791314602,
                0.06432494521141052,
                0.08075955510139465,
                -0.013311630114912987,
                -0.03394625708460808,
                -0.009977760724723339,
                -0.04741158336400986,
                0.07672296464443207,
                -0.0013320964062586427,
                -0.049699243158102036,
                0.009626764804124832,
                0.07653757929801941,
                -0.0469508059322834,
                -0.04222682863473892,
                0.014093003235757351,
                -0.03559418395161629,
                0.004950201138854027,
                -0.00832623615860939,
                0.032025329768657684,
                -0.02203693613409996,
                0.026273854076862335,
                0.021471720188856125,
                0.011100583709776402,
                0.0697796419262886,
                0.0660109892487526,
                0.0066213421523571014,
                0.034922271966934204,
                0.011505404487252235,
                -0.031076297163963318,
                -0.037395451217889786,
                0.07926623523235321,
                -0.021519804373383522,
                -0.03101407177746296,
                0.0012684139655902982,
                -0.021521976217627525,
                0.0008370411233045161,
                -0.02567296102643013,
                -0.04191809520125389,
                0.04453934356570244,
                0.02922108955681324,
                -0.045066624879837036,
                -0.002564924070611596,
                0.012521100230515003,
                0.06093766912817955,
                -0.025599412620067596,
                -0.00843713991343975,
                -0.07229512184858322,
                0.023172330111265182,
                -0.017459245398640633,
                -0.05191486328840256,
                0.0350676029920578,
                -0.017980238422751427,
                0.009110279381275177,
                -0.026366498321294785,
                0.08386271446943283,
                -0.017661619931459427,
                0.07356838136911392,
                -0.017533492296934128,
                -0.004386996850371361,
                -0.010426190681755543,
                -0.05936410650610924,
                0.013994126580655575,
                -0.011340063065290451,
                0.022982902824878693,
                -0.013812053948640823,
                0.011045943945646286,
                0.0025741588324308395,
                0.005427761934697628,
                0.013103410601615906,
                -0.012014895677566528,
                -0.020227663218975067,
                -0.004287326708436012,
                0.06639648228883743,
                -0.0068677510134875774,
                0.04357326403260231,
                -0.04203958064317703,
                0.016590099781751633,
                0.015784205868840218,
                -0.023145927116274834,
                -3.225134059903212e-05,
                -0.0495639443397522,
                0.00898263230919838,
                0.017581667751073837,
                -0.015493562445044518,
                -0.018836569041013718,
                -0.019873443990945816,
                -0.003649756545200944,
                0.019932759925723076,
                -0.0001716629631118849,
                -0.0008732449496164918,
                -0.01144178956747055,
                -0.007359372917562723,
                -0.08022534102201462,
                -0.04310932755470276,
                -0.02167447656393051,
                0.05835314840078354,
                0.04018149897456169,
                -0.038013339042663574,
                0.0010001679183915257,
                0.06512264907360077,
                -0.005948659498244524,
                0.017806638032197952,
                -0.0010159314842894673,
                -0.04188703000545502,
                -0.015193060040473938,
                -0.010328865610063076,
                -0.04955359175801277,
                0.029352040961384773,
                0.01420077495276928,
                0.03858794644474983,
                0.008868650533258915,
                0.033663660287857056,
                -4.908198418015735e-33,
                -0.026014160364866257,
                -0.0014470742316916585,
                0.0032789388205856085,
                -0.006144712679088116,
                -0.058499548584222794,
                -0.003624768927693367,
                0.013262156397104263,
                -0.014291439205408096,
                -0.03242209926247597,
                0.010852281004190445,
                -0.02232309989631176,
                -0.006316945422440767,
                0.03589435666799545,
                -0.0047763134352862835,
                0.018252892419695854,
                0.04188239574432373,
                0.03460510075092316,
                0.03133727237582207,
                0.027027320116758347,
                -0.04710721969604492,
                0.013360166922211647,
                -0.007043095305562019,
                0.026751456782221794,
                -0.021081168204545975,
                0.04723977670073509,
                7.534262840636075e-05,
                -0.002814304083585739,
                -0.03936180844902992,
                -0.030882297083735466,
                -0.03291725739836693,
                0.047636326402425766,
                -0.02352074161171913,
                0.03656002879142761,
                -0.0249920804053545,
                -0.0260026752948761,
                0.012718765996396542,
                -0.03607885167002678,
                -0.03612703084945679,
                0.016138238832354546,
                -0.04715452343225479,
                0.014466558583080769,
                -0.05917226895689964,
                0.006198630202561617,
                0.07100197672843933,
                -0.028668195009231567,
                -0.026159601286053658,
                -0.016348138451576233,
                0.0018367958255112171,
                0.010868456214666367,
                -0.05566772073507309,
                -0.04454957693815231,
                0.013256782665848732,
                -0.04389667138457298,
                0.07538733631372452,
                -0.08909299224615097,
                -0.03343559056520462,
                0.01881498284637928,
                0.061989571899175644,
                -0.04207310080528259,
                0.017261415719985962,
                0.017645593732595444,
                0.0629129409790039,
                0.017573799937963486,
                0.028713010251522064,
                -0.04221257567405701,
                0.007446827366948128,
                -0.06063181906938553,
                0.04217052459716797,
                0.042173102498054504,
                -0.07688160240650177,
                -0.004708950407803059,
                0.010544144548475742,
                -0.022868022322654724,
                0.04876801744103432,
                -0.016229264438152313,
                -0.05919448286294937,
                -0.042403098195791245,
                0.013156739994883537,
                0.03693694248795509,
                0.05087168887257576,
                -0.028365634381771088,
                0.009479678235948086,
                0.07385145872831345,
                -0.002839884487912059,
                -0.06756869703531265,
                -0.026262696832418442,
                -0.015166915953159332,
                0.10560158640146255,
                0.040675122290849686,
                -0.04879976063966751,
                0.03589210659265518,
                0.06607919186353683,
                -0.003703491063788533,
                -0.01227631513029337,
                0.021856462582945824,
                -0.060204751789569855,
                -0.002496343571692705,
                0.03671504184603691,
                0.041930221021175385,
                0.04186771810054779,
                -0.056240227073431015,
                -0.016482481732964516,
                -0.01881193183362484,
                0.04497542232275009,
                0.011934409849345684,
                0.003713756101205945,
                -0.02286810427904129,
                -0.05100885033607483,
                0.0036546376068145037,
                -0.0038950040470808744,
                -0.039623890072107315,
                -0.012842086143791676,
                0.020846525207161903,
                -0.016655145213007927,
                -0.06266390532255173,
                -0.002153150737285614,
                0.0010917274048551917,
                -0.034434497356414795,
                0.002388496184721589,
                0.04316195473074913,
                0.07995312660932541,
                -0.025032442063093185,
                -0.0516437329351902,
                0.03647501766681671,
                0.015866342931985855,
                0.011435933411121368,
                0.025950636714696884,
                -0.008210399188101292,
                -0.03685354068875313,
                -0.03104356862604618,
                0.042113929986953735,
                -0.0014778939075767994,
                1.838850778312917e-07,
                -0.0372672975063324,
                0.015435819514095783,
                -0.020867345854640007,
                -0.04815021902322769,
                0.0144092608243227,
                -0.02403511106967926,
                -0.031741704791784286,
                0.04216435179114342,
                -0.01569356955587864,
                0.06900753080844879,
                -0.05067432299256325,
                -0.05169922485947609,
                0.0034658212680369616,
                -0.07150527089834213,
                0.028364181518554688,
                -0.037266116589307785,
                -0.010550721548497677,
                -0.014220979064702988,
                -0.05617230013012886,
                0.01903650537133217,
                -0.02135162241756916,
                -0.021015124395489693,
                -0.010736523196101189,
                0.027342867106199265,
                -0.019961079582571983,
                0.06109250709414482,
                0.045186933130025864,
                -0.04041800647974014,
                0.041070789098739624,
                -0.011333237402141094,
                0.08066443353891373,
                -0.028882188722491264,
                0.06481263786554337,
                0.014282007701694965,
                0.003223470179364085,
                -0.09397637844085693,
                -0.010727417655289173,
                0.03755820915102959,
                0.07543186098337173,
                -0.0295724980533123,
                -0.040234532207250595,
                0.014454931952059269,
                0.017650926485657692,
                -0.007339297328144312,
                -0.018420763313770294,
                0.0407579243183136,
                0.01298484019935131,
                -0.018554721027612686,
                -0.11536741256713867,
                0.03093780390918255,
                0.015752891078591347,
                0.004062923602759838,
                -0.028102802112698555,
                0.011858475394546986,
                0.03664058819413185,
                -0.019165823236107826,
                0.006448183208703995,
                0.045567382127046585,
                0.0503230057656765,
                -0.06569600105285645,
                -0.000867065042257309,
                -0.036814600229263306,
                -0.01685480587184429,
                -0.0084076514467597,
                0.08216497302055359,
                -0.06080213189125061,
                -0.010116225108504295,
                1.1503775722118173e-34,
                -0.008624699898064137,
                0.021870894357562065,
                -0.02647547423839569,
                -0.02520839124917984,
                0.0029790562111884356,
                0.014987406320869923,
                -0.01593434251844883,
                0.057780999690294266,
                0.010683105327188969,
                -0.0011951432097703218,
                -0.003540560370311141
            ]
        },
        "Experience": {
            "name": "A marketing leader with 20 years' multi-sector experience, latterly at scale-ups across energy, mobility and fintech.  A strong track record scaling businesses including OVO Energy and Zego (new mobility insurtech).  Expertise across the full range of brand and commercial marketing with a strong digital focus.\n\nA member of the Chartered Institute of Marketing with an MSc in Climate Change.  I'm passionate about making a positive impact through marketing.\n",
            "rep": [
                0.04881318286061287,
                0.018617089837789536,
                -0.0083603635430336,
                -0.024475732818245888,
                0.06219333037734032,
                -0.005793604534119368,
                -0.054641805589199066,
                0.0011032447218894958,
                0.012312049977481365,
                -0.05330832302570343,
                0.017547354102134705,
                -0.018697714433073997,
                0.0060701216571033,
                0.08070079237222672,
                0.04240993782877922,
                0.010078673250973225,
                0.0015954886330291629,
                -0.00017750455299392343,
                -0.0003364113508723676,
                0.009423354640603065,
                -0.005386851262301207,
                0.03713524714112282,
                -0.028331752866506577,
                0.009286715649068356,
                -0.030197152867913246,
                -0.009932496584951878,
                0.012822950258851051,
                0.05925511568784714,
                0.05019402876496315,
                0.03032863140106201,
                0.011164771392941475,
                -0.015897203236818314,
                -0.004939355421811342,
                -0.008004766888916492,
                1.7521361996841733e-06,
                0.011565017513930798,
                -0.028153080493211746,
                -0.030982986092567444,
                0.005196988116949797,
                -0.04539647698402405,
                0.038274914026260376,
                0.032770294696092606,
                -0.012527341023087502,
                0.04798533394932747,
                -0.010295619256794453,
                0.030262846499681473,
                0.038022592663764954,
                -0.01584833674132824,
                -0.007987354882061481,
                0.0677788257598877,
                -0.00683891586959362,
                -0.022544851526618004,
                -0.008310150355100632,
                -0.011957844719290733,
                -0.04032445698976517,
                0.020670300349593163,
                0.02342280186712742,
                -0.021223191171884537,
                -0.0206728707998991,
                -0.006750971078872681,
                0.00960403773933649,
                0.004215559922158718,
                -0.06947886198759079,
                -0.026698997244238853,
                0.034072574228048325,
                0.059771761298179626,
                0.021595779806375504,
                -0.05327451974153519,
                -0.013102855533361435,
                -0.04412674903869629,
                0.05135276913642883,
                -0.0011632547248154879,
                -0.019062384963035583,
                0.01797098107635975,
                0.021225616335868835,
                -0.014841877855360508,
                0.023381339386105537,
                0.025587206706404686,
                -0.0073087867349386215,
                0.043501727283000946,
                -0.09009154886007309,
                0.06149398535490036,
                0.009467328898608685,
                -0.059479452669620514,
                -0.03824891522526741,
                0.06785077601671219,
                -0.01746600866317749,
                0.027038734406232834,
                0.03292149305343628,
                -0.02684001624584198,
                0.027454841881990433,
                -0.0026221538428217173,
                0.013261677697300911,
                -0.027644041925668716,
                0.004567334894090891,
                -0.023932406678795815,
                0.08395249396562576,
                0.024165624752640724,
                0.02283451333642006,
                -0.018272873014211655,
                -0.02419012226164341,
                0.020530754700303078,
                0.008361654356122017,
                0.0021827707532793283,
                0.019366303458809853,
                0.003067317884415388,
                -0.003437540726736188,
                -0.03269017115235329,
                -0.09898601472377777,
                0.0055175418965518475,
                -0.024258704856038094,
                -0.012323642149567604,
                -0.03836674988269806,
                -0.009760790504515171,
                -0.042104609310626984,
                0.03784685581922531,
                -0.030975842848420143,
                -0.04786201938986778,
                0.008112511597573757,
                -0.03369325026869774,
                0.018402745947241783,
                -0.0037661842070519924,
                0.0492926649749279,
                0.017041556537151337,
                -0.036339059472084045,
                0.021302200853824615,
                -0.03798723593354225,
                -0.020269200205802917,
                -0.0177084282040596,
                -0.025631768628954887,
                0.018067123368382454,
                0.010179262608289719,
                -0.04130781441926956,
                -0.034020230174064636,
                0.047596774995326996,
                0.04131500422954559,
                -0.029121598228812218,
                -0.025305207818746567,
                -0.038040488958358765,
                -0.003272667294368148,
                -0.0008924188441596925,
                -0.0064568608067929745,
                -0.028866853564977646,
                0.050634488463401794,
                -0.002658118261024356,
                -0.05014707148075104,
                0.01913389191031456,
                0.011945422738790512,
                0.012025524862110615,
                -0.03148956596851349,
                0.018054230138659477,
                -0.02216736041009426,
                0.045366957783699036,
                -0.012077100574970245,
                0.015713386237621307,
                -0.0005212819669395685,
                0.0540088452398777,
                -0.002131054410710931,
                -0.021759944036602974,
                0.03268613666296005,
                0.014706709422171116,
                -0.01906329207122326,
                -0.02703326754271984,
                0.027495870366692543,
                -0.06070506200194359,
                0.03531338647007942,
                0.1134360209107399,
                0.021429287269711494,
                -0.05596368387341499,
                0.015317167155444622,
                0.03137829899787903,
                -0.04710239917039871,
                0.006197233218699694,
                0.0593082495033741,
                0.08276190608739853,
                -0.05916128680109978,
                0.005382406059652567,
                -0.07156547158956528,
                0.026790184900164604,
                -0.016986776143312454,
                -0.04193296283483505,
                -0.1654670685529709,
                -0.010265191085636616,
                -0.021724378690123558,
                0.035399001091718674,
                0.052889272570610046,
                0.018412373960018158,
                0.059173960238695145,
                -0.011969796381890774,
                0.014950803481042385,
                0.02329675480723381,
                0.009729220531880856,
                0.016136707738041878,
                0.05342395603656769,
                -0.038495391607284546,
                0.004131975583732128,
                -0.05417892336845398,
                -0.026396529749035835,
                -0.035666078329086304,
                -0.0322573147714138,
                -0.009937118738889694,
                -0.01258270163089037,
                -0.04565175995230675,
                -0.022903738543391228,
                0.03442540764808655,
                0.01056988537311554,
                0.010543560609221458,
                -0.0371609665453434,
                0.0008824061951600015,
                0.04765813425183296,
                0.0724857822060585,
                0.009412957355380058,
                0.02154872566461563,
                0.007682543247938156,
                -0.017174718901515007,
                -0.03870968148112297,
                0.021688852459192276,
                -0.020026881247758865,
                0.030645975843071938,
                0.04641890525817871,
                0.03177531808614731,
                0.007396525237709284,
                0.06844411790370941,
                -0.020127739757299423,
                0.08805567771196365,
                -0.03318073973059654,
                0.024021292105317116,
                -0.004878137726336718,
                0.007856864482164383,
                0.0963679850101471,
                -0.010427653789520264,
                0.013378501869738102,
                0.015083053149282932,
                -0.054934676736593246,
                -0.008471483364701271,
                0.009619385935366154,
                0.07338275760412216,
                0.0691634863615036,
                0.021027350798249245,
                -0.03871902823448181,
                -0.018984299153089523,
                0.023086441680788994,
                0.01925448700785637,
                0.03768321871757507,
                -0.07480604946613312,
                0.0603666789829731,
                -0.012897033244371414,
                0.0023553974460810423,
                -0.009890753775835037,
                0.002260062377899885,
                0.0045789978466928005,
                0.04368371516466141,
                0.04148917272686958,
                0.047585379332304,
                -0.07185166329145432,
                0.01797720417380333,
                -0.04587894305586815,
                -0.014988466165959835,
                -0.10748795419931412,
                0.037348609417676926,
                -0.06024183705449104,
                -0.002773829735815525,
                -0.03416067361831665,
                -0.003889495274052024,
                -0.005902167409658432,
                -0.01811888813972473,
                0.05729497969150543,
                -0.01890426315367222,
                0.04416465014219284,
                0.0028545022942125797,
                0.060828160494565964,
                0.03126302361488342,
                0.05675321817398071,
                -0.008229415863752365,
                0.009026412852108479,
                0.003699435153976083,
                0.012324387207627296,
                0.021946728229522705,
                -0.04880870506167412,
                0.03383848816156387,
                0.04410342872142792,
                -0.05765577405691147,
                -0.04434715956449509,
                0.018817199394106865,
                0.015169856138527393,
                -0.01062288973480463,
                0.05741836503148079,
                -0.04256274551153183,
                -0.02720859833061695,
                -0.020152784883975983,
                -0.029052650555968285,
                0.0036377538926899433,
                0.005939837545156479,
                -0.04876193031668663,
                -0.03495464473962784,
                0.03236662596464157,
                -0.02133612148463726,
                -0.03105771355330944,
                0.021908804774284363,
                0.02949669398367405,
                -0.03078639693558216,
                -0.03307373821735382,
                0.07002324610948563,
                0.0017116148956120014,
                -0.01299846451729536,
                0.03732314705848694,
                -0.02214917354285717,
                0.007661645300686359,
                -0.01931307464838028,
                -0.0031586033292114735,
                0.01661515235900879,
                0.029962671920657158,
                0.030561748892068863,
                -0.0005337818874977529,
                0.06408370286226273,
                -0.03522564843297005,
                -0.055955830961465836,
                0.005523399915546179,
                0.013478955253958702,
                0.10701046139001846,
                -0.03225124627351761,
                0.001634047832340002,
                -0.016537802293896675,
                -0.03559491038322449,
                0.011082826182246208,
                0.017893442884087563,
                -0.06138833984732628,
                -0.020505741238594055,
                0.006756202783435583,
                -0.07061803340911865,
                0.01605018600821495,
                -0.020667556673288345,
                -0.018507497385144234,
                0.001950488076545298,
                -0.07438945770263672,
                -0.1027769073843956,
                0.0025002947077155113,
                -0.031119199469685555,
                -0.024641582742333412,
                -0.027055587619543076,
                -0.02551988698542118,
                -0.006915539503097534,
                -0.04511624202132225,
                -0.03638039901852608,
                -0.004273776896297932,
                -0.027748795226216316,
                0.060404617339372635,
                0.01847894862294197,
                -0.10711825639009476,
                0.04261504113674164,
                0.030056564137339592,
                0.07433609664440155,
                0.019787587225437164,
                0.011483641341328621,
                -0.003969510551542044,
                0.009306153282523155,
                -0.009605437517166138,
                0.04178702458739281,
                0.0036541291046887636,
                0.013096097856760025,
                -0.03480471670627594,
                -0.014891104772686958,
                -0.0233578160405159,
                0.029856206849217415,
                0.0010668911272659898,
                -0.01565278321504593,
                0.006441742647439241,
                -0.044499825686216354,
                -0.012390917167067528,
                -0.025753526017069817,
                -0.0025928362738341093,
                0.02358187921345234,
                -0.019748779013752937,
                0.005643771030008793,
                -0.009429034776985645,
                0.02396753430366516,
                0.027324894443154335,
                0.029131097719073296,
                0.0021419464610517025,
                -0.015439856797456741,
                0.07240216434001923,
                -0.04207561910152435,
                0.02806343510746956,
                -0.001955031417310238,
                -0.002948828972876072,
                -0.08467142283916473,
                0.044072650372982025,
                -0.058126095682382584,
                0.007228360511362553,
                0.04017076641321182,
                0.04672276973724365,
                0.06175673380494118,
                -0.02258787490427494,
                0.03325532004237175,
                -0.05414697900414467,
                0.010101295076310635,
                -0.03210117667913437,
                -0.048806533217430115,
                -0.04266516491770744,
                0.0133240707218647,
                0.00029062401154078543,
                0.034691616892814636,
                -0.035930927842855453,
                0.0039730616845190525,
                -0.00726291723549366,
                -0.08164029568433762,
                0.037421952933073044,
                0.023813990876078606,
                0.011166232638061047,
                0.02839811146259308,
                -0.08439357578754425,
                -0.006095520686358213,
                0.025529049336910248,
                0.056872159242630005,
                -0.04171467944979668,
                -0.08674095571041107,
                0.02007625624537468,
                0.006328517105430365,
                0.04188942164182663,
                -0.09828480333089828,
                0.037375204265117645,
                0.008769311010837555,
                0.01126785483211279,
                0.02609994076192379,
                0.021436505019664764,
                -0.012334874831140041,
                0.05010738968849182,
                -0.032228488475084305,
                0.03674620762467384,
                -0.05772959068417549,
                -0.05067591741681099,
                0.02838476002216339,
                0.02268073335289955,
                0.048604510724544525,
                0.019687077030539513,
                -0.04384228214621544,
                0.023984966799616814,
                0.02974807284772396,
                0.01546771265566349,
                0.03790711238980293,
                0.025035526603460312,
                0.02799573354423046,
                -0.02181830257177353,
                0.04359905794262886,
                -0.012110291980206966,
                -0.03003711625933647,
                0.00258513237349689,
                0.07877356559038162,
                -0.011038774624466896,
                -0.040459275245666504,
                0.11830753087997437,
                -0.07592573016881943,
                0.017273757606744766,
                0.007549358531832695,
                -0.03632394224405289,
                -0.059717874974012375,
                -0.045826081186532974,
                -0.05629190802574158,
                -0.007371703162789345,
                -0.028297102078795433,
                -0.01409214362502098,
                0.023898573592305183,
                0.03121986985206604,
                0.05190106853842735,
                0.02157507836818695,
                0.017962900921702385,
                0.06363790482282639,
                0.001857115188613534,
                0.04971950128674507,
                -0.07013857364654541,
                0.03184639289975166,
                -0.018349604681134224,
                -0.033505842089653015,
                -0.004753454588353634,
                0.003075873013585806,
                -0.0153748644515872,
                0.01822609081864357,
                0.038277555257081985,
                0.00471105333417654,
                0.04781155288219452,
                -0.0027654070872813463,
                0.014129149727523327,
                0.021629732102155685,
                0.01103079505264759,
                -0.022598639130592346,
                0.03387383371591568,
                0.055873509496450424,
                0.026914099231362343,
                -0.03095402754843235,
                -0.05747905746102333,
                0.040076278150081635,
                -0.012697105295956135,
                0.00750598544254899,
                -0.01176542043685913,
                -0.04211798682808876,
                0.019733764231204987,
                0.029360990971326828,
                0.005941780749708414,
                -0.043998297303915024,
                0.04980791360139847,
                -0.01967521384358406,
                -0.007367630023509264,
                0.001376463915221393,
                -0.015068675391376019,
                0.0053308154456317425,
                -0.03718017414212227,
                -0.04177786409854889,
                -0.025311216711997986,
                -0.011151237413287163,
                0.0034846318885684013,
                -0.07284405827522278,
                -0.002316112630069256,
                -0.005139322020113468,
                -0.027842190116643906,
                0.03941472992300987,
                0.01460005808621645,
                -0.02212345041334629,
                -0.028231805190443993,
                0.04544777050614357,
                -0.05149859935045242,
                0.029792100191116333,
                -0.01232917234301567,
                -0.026590237393975258,
                0.019560031592845917,
                -0.02192753367125988,
                -0.010637763887643814,
                0.010940799489617348,
                0.027210574597120285,
                0.006334000267088413,
                0.026806535199284554,
                0.019887160509824753,
                -0.028852127492427826,
                -0.014710301533341408,
                -0.003245611907914281,
                -0.015013134106993675,
                -0.004268904682248831,
                0.008955258876085281,
                0.05025934427976608,
                0.037427715957164764,
                0.041539520025253296,
                -0.03524395078420639,
                0.01616312563419342,
                0.0548640713095665,
                -0.005587274208664894,
                -0.037256255745887756,
                -0.00521479919552803,
                -0.03365606442093849,
                -0.026176828891038895,
                0.028931040316820145,
                -0.09179571270942688,
                0.0007053808076307178,
                -0.035020217299461365,
                -0.0490151047706604,
                0.05107147619128227,
                -0.043695006519556046,
                -5.336916268781993e-33,
                -0.012489831075072289,
                -2.845449671440292e-05,
                0.018865080550312996,
                0.03390803933143616,
                -0.05233779549598694,
                -0.02113541029393673,
                0.05325876921415329,
                -0.05199407413601875,
                -0.01952742226421833,
                0.0018493288662284613,
                -0.01769830659031868,
                -0.007281155325472355,
                0.017901046201586723,
                0.0018827250460162759,
                -0.03659389168024063,
                0.058409590274095535,
                0.07162084430456161,
                -0.006251109763979912,
                -0.0033028172329068184,
                -0.024740947410464287,
                -0.047164227813482285,
                -0.005023047793656588,
                -0.03346667438745499,
                0.01039425190538168,
                0.04430406168103218,
                -0.05695214867591858,
                -0.03958340361714363,
                0.05787769332528114,
                0.03783614933490753,
                0.03822123631834984,
                0.006268306635320187,
                0.00582091836258769,
                0.0037592286244034767,
                0.023527171462774277,
                0.027094341814517975,
                0.0033398091327399015,
                -0.05121341347694397,
                0.041268784552812576,
                0.010661003179848194,
                0.036958836019039154,
                -0.04723111540079117,
                0.023901356384158134,
                -0.0015163504285737872,
                -0.008195134811103344,
                -0.0002411523018963635,
                -0.007420402020215988,
                0.00579165481030941,
                -0.01672368496656418,
                0.04003679007291794,
                0.03065263107419014,
                -0.017507579177618027,
                -0.03550243750214577,
                -0.05312462896108627,
                0.06025542691349983,
                -0.040722381323575974,
                -0.019209668040275574,
                0.004721780773252249,
                0.05843086913228035,
                -0.002986135659739375,
                -0.011885363608598709,
                -0.028586015105247498,
                0.00806112214922905,
                0.038723476231098175,
                0.03991525247693062,
                -0.029141534119844437,
                0.028388652950525284,
                0.028505733236670494,
                0.06524176895618439,
                0.03199777379631996,
                0.020631656050682068,
                -0.0676456093788147,
                0.022211486473679543,
                -0.05647186189889908,
                0.06763746589422226,
                -0.05576750636100769,
                -0.01605980470776558,
                -0.007772868499159813,
                -0.024040689691901207,
                0.07172303646802902,
                -0.04426128417253494,
                -0.06500129401683807,
                -0.03309253975749016,
                0.07197309285402298,
                -0.0019509613048285246,
                0.008444898761808872,
                -0.029878009110689163,
                -0.03885292634367943,
                -0.0018732005264610052,
                0.029545823112130165,
                0.013328876346349716,
                -0.023303847759962082,
                0.039550866931676865,
                -0.06695898622274399,
                0.005920043680816889,
                0.08475286513566971,
                -0.06443563848733902,
                0.016286440193653107,
                -0.018143856897950172,
                -0.007047025486826897,
                -0.03257397934794426,
                0.015196619555354118,
                0.035499535501003265,
                -0.06803642213344574,
                -0.016010038554668427,
                -0.01315864734351635,
                0.03761347010731697,
                -0.038432974368333817,
                0.010778713040053844,
                -0.01491831336170435,
                0.002991270972415805,
                0.048308491706848145,
                0.0036395536735653877,
                0.009934225119650364,
                -0.04043462872505188,
                -0.038904767483472824,
                -0.025365933775901794,
                0.04057871922850609,
                -0.003656606888398528,
                -0.005595236085355282,
                0.018589641898870468,
                0.09293662011623383,
                -0.06720884144306183,
                5.2052204409847036e-05,
                0.015192805789411068,
                -0.0013470652047544718,
                -0.0015268063871189952,
                -0.0014505881117656827,
                -0.028810400515794754,
                -0.002259501488879323,
                -0.036269526928663254,
                0.00030054920352995396,
                -0.0012106630019843578,
                2.4782048058114015e-07,
                0.010469420813024044,
                -0.012220735661685467,
                -0.03233125060796738,
                0.015112487599253654,
                0.014234749600291252,
                -0.021260259672999382,
                -0.011198888532817364,
                -0.015936871990561485,
                -0.035054709762334824,
                -0.007105662487447262,
                0.006683679763227701,
                -0.035290200263261795,
                -0.05195673927664757,
                -0.04987576603889465,
                -0.03394998982548714,
                -0.023888476192951202,
                -0.019404347985982895,
                -0.04617634415626526,
                -0.02376018464565277,
                -0.01494548935443163,
                -0.018918896093964577,
                0.03363465145230293,
                0.023042215034365654,
                0.013436630368232727,
                0.02309461310505867,
                -0.0021494776010513306,
                0.015083164907991886,
                -0.04338226094841957,
                0.03728880733251572,
                0.03956500068306923,
                -0.0026859103236347437,
                -0.06542752683162689,
                0.05579405650496483,
                -0.010775105096399784,
                0.018561720848083496,
                0.002684828359633684,
                0.01944536715745926,
                0.08576887845993042,
                0.03878045827150345,
                0.001967543503269553,
                0.0005153822712600231,
                0.025551768019795418,
                0.00764878885820508,
                0.009387584403157234,
                0.012704106979072094,
                -0.037057675421237946,
                -0.001507131033577025,
                -0.0016027322271838784,
                -0.055502038449048996,
                0.0255608931183815,
                -0.022821415215730667,
                0.027264101430773735,
                0.002204594202339649,
                0.005552077665925026,
                0.03665565326809883,
                -0.02502315305173397,
                -0.025536853820085526,
                0.03875080496072769,
                0.05321478471159935,
                -0.04237022623419762,
                -0.016496246680617332,
                -0.018364889547228813,
                -0.015325667336583138,
                0.006444986443966627,
                0.023827938362956047,
                -0.017739079892635345,
                -0.009498178958892822,
                2.143290558880037e-34,
                0.00766422413289547,
                0.015346807427704334,
                -0.023026321083307266,
                -0.024250783026218414,
                0.03353039547801018,
                -0.058819618076086044,
                -0.05595090985298157,
                -0.018659261986613274,
                0.01797431893646717,
                -0.04822838678956032,
                -0.02240676060318947
            ]
        },
        "Freelance": "Open to both",
        "Id": "47c63b77-12c3-48fd-8145-87f9e4c2b721",
        "Industry": {
            "name": "Marketing",
            "rep": [
                0.06910858303308487,
                -0.041355036199092865,
                -0.043698567897081375,
                -0.036314524710178375,
                0.017968399450182915,
                0.04157230630517006,
                -0.01194357592612505,
                -0.019256897270679474,
                -0.01006162166595459,
                0.0046901823952794075,
                0.03790372237563133,
                -0.03818262740969658,
                0.026255499571561813,
                0.06666823476552963,
                0.021907886490225792,
                -0.01729758083820343,
                0.012621530331671238,
                0.02608245238661766,
                -0.044594764709472656,
                0.005515947472304106,
                0.01053373422473669,
                0.01223464123904705,
                0.04659774526953697,
                -0.02149541676044464,
                -0.01430988684296608,
                -0.025188926607370377,
                0.017960280179977417,
                -0.039651043713092804,
                0.004227800760418177,
                -0.03331460803747177,
                0.022408287972211838,
                -0.038080137223005295,
                0.05878244712948799,
                -0.030071869492530823,
                1.7592492440599017e-06,
                -0.008985928259789944,
                -0.029508858919143677,
                -0.028571777045726776,
                -0.08048038184642792,
                0.0302363820374012,
                0.08345013856887817,
                0.04432089254260063,
                -0.01255461573600769,
                0.054290901869535446,
                -0.0024519895669072866,
                0.03706865385174751,
                0.0804671198129654,
                -0.0003428588970564306,
                -0.010507025755941868,
                0.03156617283821106,
                -0.008320982567965984,
                -0.03050980344414711,
                -0.09595263004302979,
                -0.05928652733564377,
                -0.05405760928988457,
                -0.025057582184672356,
                0.003691505640745163,
                -0.005066238343715668,
                0.023939358070492744,
                0.022093933075666428,
                0.008389641530811787,
                -0.02017718181014061,
                -0.022384610027074814,
                0.01842883601784706,
                0.03349452093243599,
                0.021225977689027786,
                0.08866269141435623,
                -0.02267986349761486,
                -0.022389160469174385,
                -0.03858675807714462,
                0.05264212563633919,
                0.030080514028668404,
                0.008878619410097599,
                0.06338442116975784,
                0.006182766519486904,
                -0.017981979995965958,
                0.00143305619712919,
                0.01016627810895443,
                0.008201520889997482,
                0.029699862003326416,
                -0.04148547723889351,
                0.038918714970350266,
                -0.015853233635425568,
                0.013155164197087288,
                0.04576123505830765,
                0.06958619505167007,
                -0.035511769354343414,
                -0.007268346846103668,
                -0.007992377504706383,
                -0.003896869020536542,
                -0.013149173930287361,
                -0.039169177412986755,
                0.014123269356787205,
                0.017632612958550453,
                0.06802093982696533,
                -0.028145555406808853,
                0.05249766632914543,
                0.03303323686122894,
                0.024500001221895218,
                -0.05138765275478363,
                -0.054910242557525635,
                -0.011221587657928467,
                0.0050160544924438,
                0.007527882698923349,
                0.01840408891439438,
                -0.004834636114537716,
                0.009817530401051044,
                -0.010938459075987339,
                -0.06206236407160759,
                0.08006797730922699,
                -0.07233526557683945,
                -0.005164911039173603,
                -0.015853919088840485,
                0.029639218002557755,
                0.004246778320521116,
                -0.03994745388627052,
                -0.05387783795595169,
                0.039586544036865234,
                -0.03069171868264675,
                -0.014630848541855812,
                -0.06458402425050735,
                0.030366763472557068,
                -0.02828095853328705,
                0.005307287443429232,
                0.019195200875401497,
                0.035352516919374466,
                -0.09993541240692139,
                0.026811445131897926,
                0.02961025759577751,
                0.021142883226275444,
                0.015091821551322937,
                0.01461348682641983,
                0.013395263813436031,
                -0.003752930788323283,
                0.0504753552377224,
                -0.014897672459483147,
                -0.012968135997653008,
                0.004592927172780037,
                -0.12137328088283539,
                -0.03428935632109642,
                -0.008162010461091995,
                -0.022904952988028526,
                -0.027317717671394348,
                -0.003368169767782092,
                -0.03704918920993805,
                0.015619887039065361,
                0.009424078278243542,
                0.03534705191850662,
                -0.0056941271759569645,
                -0.042965974658727646,
                -0.007242891006171703,
                -0.02847891114652157,
                0.07064321637153625,
                0.0022847268264740705,
                0.10935047268867493,
                0.04233488813042641,
                0.06713547557592392,
                -0.010886112228035927,
                -0.041654378175735474,
                0.012348501943051815,
                0.017240425571799278,
                0.031214933842420578,
                0.007465783506631851,
                0.004992557689547539,
                -0.047693539410829544,
                0.027871470898389816,
                0.06649741530418396,
                0.00809893012046814,
                -0.09593377262353897,
                -0.026339512318372726,
                -0.005762368906289339,
                -0.048043474555015564,
                -0.02347138710319996,
                0.05658860132098198,
                0.03249198570847511,
                -0.07046263664960861,
                0.016137314960360527,
                -0.03662201017141342,
                -0.02914181910455227,
                0.005080818198621273,
                -0.05164460465312004,
                -0.09237948060035706,
                0.034623902291059494,
                -0.01864403486251831,
                0.014677450992166996,
                0.050596244633197784,
                0.019188012927770615,
                -0.001608341815881431,
                -0.07223594933748245,
                -0.002011520555242896,
                0.0006002971203997731,
                0.019181307405233383,
                -0.014885781332850456,
                0.01633749157190323,
                -0.006936098448932171,
                0.011498414911329746,
                -0.011178428307175636,
                -0.0048317755572497845,
                -0.023234596475958824,
                -0.03308369219303131,
                -0.0036329952999949455,
                -0.015831928700208664,
                0.012281722389161587,
                0.04641884192824364,
                -0.05202111601829529,
                0.04539673775434494,
                0.07008683681488037,
                0.005865337792783976,
                -0.037274815142154694,
                0.010980792343616486,
                0.010618152096867561,
                -0.0009252497693523765,
                -0.023122720420360565,
                0.029464926570653915,
                0.03044373355805874,
                -0.05426621809601784,
                0.009763156063854694,
                0.006785429082810879,
                -0.016967779025435448,
                0.061753977090120316,
                -0.027684351429343224,
                -0.0037667229771614075,
                0.06429574638605118,
                -0.04613165929913521,
                0.021570643410086632,
                -0.04422757774591446,
                -0.028402406722307205,
                -0.020199662074446678,
                -0.011134701780974865,
                0.020045485347509384,
                0.009855668991804123,
                0.03737673535943031,
                0.04717019572854042,
                -0.025742346420884132,
                0.0026904651895165443,
                0.04099620506167412,
                0.1386156678199768,
                0.08899278938770294,
                0.018467357382178307,
                -0.07048478722572327,
                -0.05297596752643585,
                -0.0063804322853684425,
                0.023191796615719795,
                0.017719483003020287,
                0.049710456281900406,
                0.004139253869652748,
                0.03167292848229408,
                0.02170644886791706,
                -0.03114892542362213,
                -0.00025092597934417427,
                -0.03719407692551613,
                0.00041695224354043603,
                0.03181004896759987,
                -0.011912986636161804,
                0.004116540774703026,
                -0.052527155727148056,
                -0.06694714725017548,
                0.03353845328092575,
                -0.02460560016334057,
                0.029574338346719742,
                -0.013242609798908234,
                -0.03208765760064125,
                -0.028010046109557152,
                -0.02235121838748455,
                0.0035727107897400856,
                -0.03888336569070816,
                0.07213868945837021,
                -0.018873218446969986,
                -0.015518381260335445,
                -0.02106829173862934,
                0.04768767207860947,
                0.025182710960507393,
                0.030915578827261925,
                0.007153285667300224,
                0.01636834628880024,
                -0.05148674175143242,
                -0.005422902759164572,
                -0.029733721166849136,
                -0.02377297356724739,
                0.02250620722770691,
                0.05482342094182968,
                -0.03895619139075279,
                -0.02307855151593685,
                0.003766001434996724,
                0.015232284553349018,
                -0.031168697401881218,
                0.04003269597887993,
                -0.02543896995484829,
                -0.027570510283112526,
                -0.021532494574785233,
                -0.056443873792886734,
                0.007137587293982506,
                -0.005070332437753677,
                -0.02657470479607582,
                -0.0010124901309609413,
                0.007036841474473476,
                0.004254788625985384,
                -0.06008389964699745,
                0.0021640013437718153,
                -0.01855398342013359,
                -0.039966896176338196,
                -0.004800436552613974,
                0.06960146874189377,
                -0.029978834092617035,
                -0.05588308349251747,
                0.0014666621573269367,
                -0.0172885749489069,
                -0.04080697521567345,
                -0.0021481297444552183,
                -0.0003800696285907179,
                0.029578693211078644,
                0.021396037191152573,
                0.07879933714866638,
                0.019967185333371162,
                0.0357329398393631,
                -0.002819419139996171,
                -0.04793309047818184,
                0.010515875183045864,
                -0.022847920656204224,
                0.13700123131275177,
                0.033978961408138275,
                -0.02436896599829197,
                -0.026736406609416008,
                -0.0009826513705775142,
                0.050011709332466125,
                -0.002349639544263482,
                -0.01899964176118374,
                0.015423091128468513,
                -0.03928452730178833,
                -0.04859280213713646,
                1.7900187231134623e-05,
                -0.008997064083814621,
                -0.04096914827823639,
                0.00879369955509901,
                -0.06849481165409088,
                0.05315563082695007,
                -0.026387538760900497,
                -0.021160906180739403,
                -0.00999613106250763,
                -0.014440190978348255,
                0.021354062482714653,
                0.0025552483275532722,
                0.0015362236881628633,
                -0.03653433918952942,
                -0.00045805436093360186,
                -0.0776791051030159,
                0.03275146707892418,
                0.04364541172981262,
                -0.03687639534473419,
                0.02038111351430416,
                0.0054724691435694695,
                0.02663174830377102,
                -0.00651443749666214,
                -0.011603557504713535,
                -0.010306662879884243,
                0.033613018691539764,
                -0.041726186871528625,
                0.04157289117574692,
                -0.01881185546517372,
                -0.08042638748884201,
                -0.05642078071832657,
                -0.0016260568518191576,
                -0.013316061347723007,
                0.04259864613413811,
                0.021329320967197418,
                0.03234180063009262,
                0.04883130267262459,
                0.05599910020828247,
                0.014762500301003456,
                0.04233403876423836,
                -0.019994506612420082,
                0.08636505901813507,
                0.025800641626119614,
                0.03190717101097107,
                0.011395472101867199,
                0.056028928607702255,
                0.01206848956644535,
                0.01756245642900467,
                -0.028362179175019264,
                -0.013869205489754677,
                0.04877357557415962,
                -0.05794909968972206,
                0.042147524654865265,
                -0.001337495632469654,
                0.016665033996105194,
                -0.026519084349274635,
                0.018041163682937622,
                -0.050938207656145096,
                -0.02971472404897213,
                0.006030276883393526,
                0.015437936410307884,
                0.0036188855301588774,
                -0.05099094286561012,
                0.0628398135304451,
                -0.04678616300225258,
                0.0034409710206091404,
                0.03466740623116493,
                -0.004613215569406748,
                -0.002637457102537155,
                0.015449879691004753,
                0.028195027261972427,
                0.014667346142232418,
                0.004125434439629316,
                0.01200773287564516,
                -0.04835643246769905,
                -0.08937489241361618,
                0.06867805868387222,
                0.03228585422039032,
                -0.02241336740553379,
                -0.05450625345110893,
                -0.0458863265812397,
                0.05036875605583191,
                0.0234950240701437,
                -0.011632013134658337,
                0.052238501608371735,
                -0.05699039250612259,
                -0.016714297235012054,
                0.010456308722496033,
                0.008566206321120262,
                -0.043057575821876526,
                0.03980713710188866,
                -0.03768771514296532,
                -0.022195102646946907,
                0.021995525807142258,
                -0.025003066286444664,
                0.010441743768751621,
                0.051443662494421005,
                0.04515543580055237,
                0.04973616078495979,
                -0.015854304656386375,
                -0.03675273433327675,
                0.03346724063158035,
                -0.04833047837018967,
                0.0371406190097332,
                0.05720818415284157,
                -0.01591268740594387,
                0.010268352925777435,
                -0.01803334802389145,
                -0.03980899974703789,
                -0.016614526510238647,
                0.04675387591123581,
                0.06054753437638283,
                0.013518721796572208,
                0.03615712374448776,
                -0.02619357220828533,
                -0.0324057936668396,
                0.0013427702942863107,
                -0.02293960563838482,
                -0.06554373353719711,
                0.030384430661797523,
                0.025181934237480164,
                -0.038844864815473557,
                0.015992967411875725,
                -0.04041460528969765,
                -0.00737414276227355,
                -0.0037388226483017206,
                -0.0674157589673996,
                -0.0017148538026958704,
                -0.015735536813735962,
                -0.029954710975289345,
                0.02514585852622986,
                0.03930018097162247,
                0.00323308864608407,
                0.008789770305156708,
                0.008179462514817715,
                -0.04956015571951866,
                0.04639533907175064,
                -0.0034634589683264494,
                0.018006866797804832,
                -0.04214409366250038,
                0.007286963984370232,
                0.003882292890921235,
                -0.0027456763200461864,
                0.039177555590867996,
                0.02973141334950924,
                0.03698020055890083,
                -0.027312273159623146,
                -0.025718621909618378,
                0.06275858730077744,
                0.04625444859266281,
                -0.01565445028245449,
                -0.0017580956919118762,
                0.013258573599159718,
                0.06676643341779709,
                -0.004781592171639204,
                -0.006146731786429882,
                0.04955950379371643,
                0.003398836823180318,
                0.03090684860944748,
                -0.05391133204102516,
                0.03712267056107521,
                0.002951353555545211,
                -0.006465113256126642,
                -0.01774289458990097,
                -0.009123923256993294,
                0.015780838206410408,
                -0.005365735851228237,
                0.01453495118767023,
                -0.014450511895120144,
                -0.0004431041015777737,
                0.008531803265213966,
                -0.0019167058635503054,
                0.002892913296818733,
                -0.005472753196954727,
                -0.035920657217502594,
                -0.01026833988726139,
                -0.01283025462180376,
                0.05717778205871582,
                0.014848584309220314,
                -0.010659332387149334,
                -0.01623312011361122,
                -0.002488908590748906,
                0.014828231185674667,
                -0.11103418469429016,
                0.00428115064278245,
                0.024479685351252556,
                -0.026118287816643715,
                -0.09304582327604294,
                0.012606785632669926,
                -0.022563710808753967,
                -0.0028192338068038225,
                -0.028944185003638268,
                0.008826587349176407,
                -0.011342566460371017,
                0.009627758525311947,
                0.0017908094450831413,
                0.024072987958788872,
                -0.0170475821942091,
                -0.011021156795322895,
                0.02748834155499935,
                -0.0358760729432106,
                -0.05544136464595795,
                -0.0439576730132103,
                0.009621484205126762,
                -0.037261713296175,
                0.10149546712636948,
                0.05294949188828468,
                0.05824930965900421,
                0.02411329559981823,
                0.030788742005825043,
                -0.04878293722867966,
                -0.007923958823084831,
                0.01626458577811718,
                -0.012539641931653023,
                0.03177960216999054,
                -0.0309784896671772,
                0.006672775838524103,
                0.032016608864068985,
                0.020486626774072647,
                0.021576479077339172,
                -0.03427191078662872,
                -0.006030222401022911,
                -0.04892426356673241,
                0.013062207959592342,
                0.0180001650005579,
                -7.132779232618681e-33,
                0.013222908601164818,
                -0.022432012483477592,
                -0.003497103461995721,
                0.04999002814292908,
                -0.041036609560251236,
                -0.02334865927696228,
                0.03186316415667534,
                0.01218661479651928,
                -0.03903067111968994,
                -0.03323505073785782,
                0.005861259065568447,
                -0.008302891626954079,
                0.02500929683446884,
                -0.00015243745292536914,
                0.030099375173449516,
                -0.04352182149887085,
                0.0034854188561439514,
                0.023379210382699966,
                -0.02008451707661152,
                -0.04910586401820183,
                -0.017068980261683464,
                0.038129232823848724,
                0.001851639011874795,
                0.06473444402217865,
                -0.011993302963674068,
                -0.034005630761384964,
                -0.030781108886003494,
                -0.038305796682834625,
                0.029202010482549667,
                -0.021495409309864044,
                -0.00014318482135422528,
                -0.029057687148451805,
                0.01663166470825672,
                -0.06968100368976593,
                0.00818116869777441,
                0.04232656955718994,
                0.00437457300722599,
                -0.0005797064513899386,
                0.03377220034599304,
                -0.026345152407884598,
                -0.025072121992707253,
                -0.0406346432864666,
                0.0013909562258049846,
                -0.020501626655459404,
                0.011063640005886555,
                0.027943938970565796,
                0.016324417665600777,
                -0.01175652164965868,
                0.028291776776313782,
                0.07719497382640839,
                0.004031014163047075,
                -0.020560918375849724,
                -0.025013359263539314,
                0.08173905313014984,
                0.034367989748716354,
                0.0044579110108315945,
                -0.020607391372323036,
                0.07111398130655289,
                -0.016183676198124886,
                -0.004214122891426086,
                -0.03772927075624466,
                0.016642432659864426,
                -0.014133340679109097,
                0.013537888415157795,
                -0.027495352551341057,
                -0.0049134804867208,
                -0.10291539132595062,
                -0.01440068893134594,
                0.04323560744524002,
                0.05352878198027611,
                -0.03815064579248428,
                0.09761859476566315,
                -0.01382117997854948,
                -0.006784032564610243,
                -0.03586452081799507,
                -0.06154335290193558,
                0.04598754271864891,
                -0.02056252583861351,
                0.0571034736931324,
                -0.07932387292385101,
                -0.05214308202266693,
                -0.008989159017801285,
                0.01413812767714262,
                -0.004898674786090851,
                -0.017514709383249283,
                -0.014882360585033894,
                -0.03551201894879341,
                0.029625529423356056,
                0.011277026496827602,
                0.009144322946667671,
                0.002974440110847354,
                0.017969314008951187,
                -0.013190019875764847,
                0.0317051038146019,
                -0.041760679334402084,
                -0.0519193634390831,
                0.012240083888173103,
                0.02976175956428051,
                0.03349463269114494,
                -0.04819237068295479,
                -0.045335303992033005,
                0.053661081939935684,
                -0.11247548460960388,
                0.010598625987768173,
                -0.012228948995471,
                -0.028816115111112595,
                -0.0005703415954485536,
                -0.030016401782631874,
                -0.023213393986225128,
                -0.005500643979758024,
                0.04259439557790756,
                -0.007426914293318987,
                0.030673861503601074,
                -0.06913773715496063,
                -0.004693692084401846,
                -0.01608256809413433,
                0.010578443296253681,
                -0.05899395793676376,
                -0.024711502715945244,
                -0.005212428048253059,
                0.026895634829998016,
                -0.0042289383709430695,
                -0.0414249487221241,
                -0.017724784091114998,
                -0.030947940424084663,
                0.02740074135363102,
                0.024260874837636948,
                0.008763584308326244,
                0.037819914519786835,
                -0.10441584885120392,
                -0.045929428189992905,
                -0.014581281691789627,
                2.370280611785347e-07,
                0.018917707726359367,
                0.018535882234573364,
                -0.01516681257635355,
                0.046541567891836166,
                0.03862347826361656,
                0.017920436337590218,
                -0.03514392673969269,
                0.027333147823810577,
                0.04061348736286163,
                -0.03993862494826317,
                0.022990642115473747,
                -0.018571848049759865,
                -0.06114282086491585,
                -0.01788472570478916,
                -0.002782097551971674,
                -0.023395026102662086,
                -0.005481607746332884,
                -0.030078832060098648,
                0.008246304467320442,
                -0.0231695044785738,
                0.031116999685764313,
                0.05487602949142456,
                0.008846637792885303,
                -0.005663325544446707,
                -0.008419008925557137,
                -0.05724159628152847,
                0.02704974263906479,
                -0.03970974683761597,
                0.0579097680747509,
                0.029566720128059387,
                -0.002578719286248088,
                0.008017966523766518,
                -0.027306504547595978,
                -0.04456011950969696,
                -0.006755142007023096,
                -0.009459652937948704,
                -0.0064695957116782665,
                0.02870417758822441,
                0.05226410552859306,
                0.03617777302861214,
                -0.013990231789648533,
                0.008052009157836437,
                -0.02295474149286747,
                0.0332680381834507,
                0.03045148402452469,
                0.01670967973768711,
                0.009782521985471249,
                -0.02666124701499939,
                -0.03123348392546177,
                0.018149780109524727,
                -0.032560914754867554,
                0.0008996129035949707,
                0.031203381717205048,
                0.0530797615647316,
                -0.009569006972014904,
                -0.029987599700689316,
                0.06416842341423035,
                0.014351816847920418,
                0.08537178486585617,
                0.031699057668447495,
                -0.023440102115273476,
                0.00024127047799993306,
                -0.017741894349455833,
                0.024575019255280495,
                0.01846875436604023,
                0.03531217575073242,
                -0.01905706897377968,
                7.894446720623485e-35,
                0.025262931361794472,
                -0.053860682994127274,
                0.009447380900382996,
                -0.016621682792901993,
                0.028373779729008675,
                -0.043338265269994736,
                -0.027474071830511093,
                -0.029069626703858376,
                -0.009410531260073185,
                -0.0004321400774642825,
                -0.021472061052918434
            ]
        },
        "Location": "London",
        "Remuneration": 125,
        "Services Required": {
            "name": "Marketing Director",
            "rep": [
                0.061363305896520615,
                -0.01463569886982441,
                -0.030313188210129738,
                -0.01026740949600935,
                0.03545092046260834,
                0.04665762931108475,
                0.00507223280146718,
                -0.01654701493680477,
                -0.0006409080233424902,
                -0.019195979461073875,
                0.0361749529838562,
                -0.051946282386779785,
                -0.02646775357425213,
                0.05182797089219093,
                0.015584912151098251,
                -0.005863192491233349,
                -0.020023323595523834,
                -0.0016211773036047816,
                0.0040373303927481174,
                -0.02235005795955658,
                -0.027751363813877106,
                0.033913012593984604,
                0.016439037397503853,
                0.021426578983664513,
                -0.04376703128218651,
                -0.018205149099230766,
                0.027640070766210556,
                -0.0004596605431288481,
                0.031951118260622025,
                0.037131745368242264,
                0.031061511486768723,
                -0.011123854666948318,
                0.02531738393008709,
                -0.04655368626117706,
                1.3459517731462256e-06,
                0.028219349682331085,
                0.03639155998826027,
                -0.01544947735965252,
                -0.04433680325746536,
                -0.05356321111321449,
                0.03308485820889473,
                0.0287190992385149,
                -0.02507426030933857,
                0.05913804471492767,
                0.00033873290522024035,
                0.055802445858716965,
                0.06153860315680504,
                -0.006251039449125528,
                -0.041239455342292786,
                0.015842454507946968,
                -0.01640007644891739,
                -0.07030866295099258,
                -0.09183977544307709,
                -0.047693923115730286,
                -0.018723605200648308,
                -0.027634264901280403,
                0.013336301781237125,
                0.010323979891836643,
                -0.02116250991821289,
                0.0529182143509388,
                0.018420051783323288,
                0.012894281186163425,
                -0.03829646855592728,
                0.015405941754579544,
                0.03876924514770508,
                0.054263513535261154,
                0.12819327414035797,
                -0.007853180170059204,
                0.018291128799319267,
                -0.03874349221587181,
                0.05058153346180916,
                0.02210872992873192,
                0.00018903346790466458,
                0.09914697706699371,
                0.0649663507938385,
                0.013690357096493244,
                0.0042808884754776955,
                0.043205440044403076,
                -0.020152907818555832,
                0.017859062179923058,
                -0.04496133327484131,
                0.026617107912898064,
                0.017349664121866226,
                0.024530885741114616,
                -0.011968470178544521,
                0.07421665638685226,
                -0.017762072384357452,
                -0.016421111300587654,
                0.0034953185822814703,
                -0.006447404623031616,
                0.018525052815675735,
                -0.006258622743189335,
                -0.0020340201444923878,
                -0.0040229614824056625,
                -0.0005425545386970043,
                0.01918926276266575,
                0.05027022585272789,
                -0.014822139404714108,
                -0.043404851108789444,
                -0.042313382029533386,
                -0.024915743619203568,
                0.01770932413637638,
                0.01987098529934883,
                0.014365042559802532,
                0.03154388815164566,
                0.028865018859505653,
                0.025383878499269485,
                0.06372015923261642,
                -0.00567494286224246,
                0.0699002668261528,
                -0.01831287518143654,
                -0.010494895279407501,
                -0.005986199248582125,
                -0.018254857510328293,
                0.0211221631616354,
                0.026913167908787727,
                -0.02044672891497612,
                0.01928870752453804,
                -0.013029399327933788,
                0.0359782911837101,
                -0.029656628146767616,
                0.012059209868311882,
                -0.0217570960521698,
                0.010903522372245789,
                -0.016343291848897934,
                0.001870052656158805,
                -0.05100118741393089,
                0.028048692271113396,
                0.05119850113987923,
                0.07247873395681381,
                0.016178397461771965,
                0.01903432421386242,
                -0.01578112132847309,
                -0.01352748367935419,
                0.023536302149295807,
                0.026497524231672287,
                -0.037277527153491974,
                -0.004089972469955683,
                -0.07008541375398636,
                -0.015106702223420143,
                -0.03459474816918373,
                -0.0050300611183047295,
                -0.055008210241794586,
                0.023213552311062813,
                -0.019243840128183365,
                0.00531012611463666,
                -0.049503639340400696,
                0.01384168490767479,
                0.013546869158744812,
                -0.0096907252445817,
                0.003621924202889204,
                -0.008979810401797295,
                0.06955737620592117,
                0.027992289513349533,
                0.09843559563159943,
                0.03139777109026909,
                0.03322035074234009,
                -0.0056372350081801414,
                -0.03894331306219101,
                0.07216797024011612,
                0.012920576147735119,
                0.013462741859257221,
                0.002060109516605735,
                0.030651098117232323,
                -0.05781058967113495,
                0.018529580906033516,
                0.06538278609514236,
                -0.010961409658193588,
                -0.13494399189949036,
                -0.018527457490563393,
                0.002235644729807973,
                -0.03678847849369049,
                -0.021900709718465805,
                0.09383578598499298,
                0.0073715816251933575,
                -0.09280318766832352,
                0.04486677423119545,
                -0.05749373510479927,
                0.04751623794436455,
                -0.02207621932029724,
                -0.0511585995554924,
                -0.11333740502595901,
                -0.0024083286989480257,
                -0.03151004761457443,
                -0.02548692561686039,
                0.02204880677163601,
                -0.0233659315854311,
                -0.01736111007630825,
                -0.03864036127924919,
                0.010100011713802814,
                0.051278237253427505,
                -0.023157615214586258,
                -0.01598157174885273,
                0.03681570291519165,
                -0.009068305604159832,
                -0.006296341307461262,
                -0.056640177965164185,
                -0.009686961770057678,
                -0.060651957988739014,
                -0.025726160034537315,
                -0.026070650666952133,
                0.011227722279727459,
                -0.05092903599143028,
                0.04555608332157135,
                -0.025176530703902245,
                -0.02103564701974392,
                -0.004241957329213619,
                -0.009337781928479671,
                -0.016926178708672523,
                0.02454148232936859,
                0.007817686535418034,
                -0.02064446732401848,
                -0.029483120888471603,
                0.03994622826576233,
                -0.02613728679716587,
                -0.01693800464272499,
                0.04469193518161774,
                0.02744932845234871,
                -0.038464225828647614,
                0.06438127160072327,
                0.00889509916305542,
                -0.014034464955329895,
                0.0699453130364418,
                -0.03505805879831314,
                -0.011692573316395283,
                -0.08257629722356796,
                -0.01637764275074005,
                0.009551010094583035,
                -0.0027137279976159334,
                0.0447346568107605,
                -0.015349337831139565,
                0.025363855063915253,
                0.06494023650884628,
                -0.05040685832500458,
                0.03645530343055725,
                0.022091850638389587,
                0.08878247439861298,
                0.010567303746938705,
                0.01444158609956503,
                -0.02433285303413868,
                -0.055940624326467514,
                0.04279174655675888,
                -0.007602272555232048,
                -0.0321628637611866,
                0.04145551845431328,
                0.00036653768620453775,
                -0.034494947642087936,
                0.0022889513056725264,
                -0.02740943431854248,
                -0.007974869571626186,
                -0.04414508864283562,
                0.01976671814918518,
                0.017275094985961914,
                0.013131532818078995,
                -0.004191447049379349,
                -0.006353114731609821,
                -0.04245302081108093,
                0.00955712329596281,
                -0.042519401758909225,
                0.01815703697502613,
                0.004327894188463688,
                0.0021856632083654404,
                -0.021962996572256088,
                0.01428523100912571,
                0.04374580830335617,
                0.0018201626371592283,
                -0.0013394476845860481,
                -0.04986024647951126,
                0.011033166199922562,
                -0.014639549888670444,
                0.026833467185497284,
                0.03455483168363571,
                0.049872130155563354,
                0.0005595166585408151,
                0.006477776449173689,
                -0.020625153556466103,
                -0.03153562918305397,
                -7.055472815409303e-05,
                -0.01664992794394493,
                -0.004935375414788723,
                0.05016035586595535,
                -0.05318750813603401,
                -0.015850184485316277,
                0.03351890295743942,
                0.018593071028590202,
                -0.04641856998205185,
                0.02581680193543434,
                -0.05383986979722977,
                -0.03374462202191353,
                -0.03133949264883995,
                -0.07956103980541229,
                0.0035434123128652573,
                -0.01392652839422226,
                -0.03007158637046814,
                -0.01512163132429123,
                0.002614232711493969,
                -0.012916389852762222,
                -0.0701017901301384,
                0.012534619309008121,
                0.01487727090716362,
                0.000321249826811254,
                -0.05403833091259003,
                0.10396905988454819,
                0.00021062782616354525,
                -0.0037441516760736704,
                -0.030936118215322495,
                -0.00891867931932211,
                0.022490346804261208,
                -0.03189334273338318,
                -0.006024212576448917,
                0.028520682826638222,
                0.023431237787008286,
                0.04093536362051964,
                -0.0002603419707156718,
                0.048195190727710724,
                -0.020937927067279816,
                -0.01236006896942854,
                0.03286714479327202,
                -0.00766884395852685,
                0.12731687724590302,
                -0.005426480434834957,
                0.030501650646328926,
                -0.04859180003404617,
                0.013116858899593353,
                0.02330123260617256,
                0.060037191957235336,
                -0.07040216773748398,
                -0.008300282061100006,
                -0.05855561047792435,
                -0.08844133466482162,
                0.006248730234801769,
                -0.0751664862036705,
                -0.03627179563045502,
                -0.02556375414133072,
                -0.04192657396197319,
                -0.008578210137784481,
                -0.04522822052240372,
                -0.01260948833078146,
                -0.037582602351903915,
                -0.011978336609899998,
                -0.020504891872406006,
                -0.010798785835504532,
                -0.03386218845844269,
                -0.010400104336440563,
                -0.05135774612426758,
                -0.06580562889575958,
                0.03965706005692482,
                0.07097519934177399,
                -0.08423863351345062,
                0.0380731038749218,
                0.005059588234871626,
                0.039087943732738495,
                0.0039162109605968,
                0.0035114847123622894,
                -0.01444939337670803,
                -0.003101895097643137,
                -0.013674924150109291,
                0.0592486597597599,
                -0.0644090473651886,
                -0.058312393724918365,
                -0.0435483381152153,
                -0.03339126706123352,
                -0.056793298572301865,
                -0.0074874223209917545,
                0.014724304899573326,
                0.011654668487608433,
                0.0221530944108963,
                0.025496726855635643,
                0.012801631353795528,
                0.013030323199927807,
                -0.03307010233402252,
                0.014786945655941963,
                0.0023601630236953497,
                0.038464874029159546,
                0.0034215040504932404,
                0.008597752079367638,
                0.020965607836842537,
                0.021103335544466972,
                -0.007640209048986435,
                -0.03295351192355156,
                0.049040794372558594,
                -0.03432482108473778,
                0.010927231051027775,
                -0.000939327641390264,
                0.01643725112080574,
                -0.03459712490439415,
                0.004224493633955717,
                -0.012441989034414291,
                -0.008197016082704067,
                0.014924462884664536,
                0.03048275224864483,
                0.017689717933535576,
                -0.03260223567485809,
                0.04813340678811073,
                -0.03550691157579422,
                0.011952693574130535,
                0.05122527480125427,
                -0.005857623647898436,
                -0.009398406371474266,
                0.019366255030035973,
                0.022946398705244064,
                0.07713742554187775,
                -0.014887556433677673,
                0.011367329396307468,
                -0.019110500812530518,
                -0.10403121262788773,
                0.07504363358020782,
                -0.013223732821643353,
                -0.016972428187727928,
                0.04605201259255409,
                -0.01966363936662674,
                0.03790462762117386,
                0.0001012706634355709,
                0.045704297721385956,
                -0.0020628764759749174,
                -0.047176212072372437,
                -0.00513712503015995,
                0.028097031638026237,
                -0.014487548731267452,
                -0.06296449154615402,
                0.08214140683412552,
                -0.008963808417320251,
                0.010455234907567501,
                0.025601020082831383,
                0.01703546941280365,
                0.0036355918273329735,
                0.07048545032739639,
                0.03512715920805931,
                0.04124600440263748,
                -0.029814913868904114,
                -0.06593266129493713,
                -0.01235235296189785,
                -0.008002618327736855,
                0.08081752806901932,
                0.039311014115810394,
                0.019500745460391045,
                0.020497798919677734,
                0.02889115922152996,
                -0.0025964195374399424,
                0.01510031521320343,
                0.044705044478178024,
                0.08279035985469818,
                -0.06392338871955872,
                0.020404407754540443,
                -0.059398431330919266,
                -0.04903285577893257,
                -0.018351884558796883,
                -0.006706027314066887,
                -0.11880523711442947,
                -0.004471080843359232,
                0.08506236970424652,
                -0.041639018803834915,
                0.005698051769286394,
                -0.04861254617571831,
                -0.008828599005937576,
                -0.012305096723139286,
                -0.057841844856739044,
                -0.02490651234984398,
                -0.005887842271476984,
                -0.060758378356695175,
                0.008967241272330284,
                0.027656175196170807,
                0.024306586012244225,
                0.03942013531923294,
                0.0004398701712489128,
                -0.00566945830360055,
                0.1066427007317543,
                -0.03181125968694687,
                0.04265735670924187,
                -0.08379209786653519,
                0.022737035527825356,
                -0.030845506116747856,
                -0.01656266115605831,
                -0.03532589599490166,
                0.003973088692873716,
                -0.004785820841789246,
                -0.003127256641164422,
                -0.017816752195358276,
                0.048998136073350906,
                0.05102717503905296,
                -0.015178282745182514,
                0.0060272542759776115,
                0.017201285809278488,
                0.046332165598869324,
                -0.014928989112377167,
                0.010434575378894806,
                0.00595332495868206,
                0.005492778494954109,
                -0.04297877103090286,
                -0.01901833526790142,
                0.050600312650203705,
                -0.04155552759766579,
                0.0031679461244493723,
                -0.03466080501675606,
                -0.019709255546331406,
                -0.00661903340369463,
                0.02810228057205677,
                -0.03704109787940979,
                -0.05635672062635422,
                0.003425685688853264,
                -0.01838781125843525,
                -0.008516832254827023,
                -0.0010119077051058412,
                -0.016449859365820885,
                0.01911221630871296,
                -0.04144905507564545,
                0.00010921443754341453,
                -0.005565347615629435,
                0.01667344942688942,
                0.011048940941691399,
                -0.011553408578038216,
                0.006089670117944479,
                0.008560038171708584,
                -0.07013550400733948,
                0.03224317356944084,
                0.017483117058873177,
                0.02376139909029007,
                -0.06751937419176102,
                0.02753908559679985,
                -0.03082730434834957,
                0.028536099940538406,
                -0.05353713780641556,
                0.007747396361082792,
                -0.00692672049626708,
                -0.0019108846317976713,
                -0.01714142970740795,
                0.02382519096136093,
                0.0005060397088527679,
                -0.007068568374961615,
                0.0621270053088665,
                3.2796338928164914e-05,
                -0.01032766792923212,
                -0.06736867129802704,
                -0.007024866994470358,
                -0.061167340725660324,
                0.05627299100160599,
                0.04751032963395119,
                0.04240847006440163,
                0.06457824259996414,
                0.03433508425951004,
                -0.04628702253103256,
                0.01809489168226719,
                0.051030710339546204,
                -0.011608953587710857,
                -0.02274378575384617,
                0.007883098907768726,
                -0.04917019605636597,
                -0.002166473539546132,
                0.001996878068894148,
                0.049165550619363785,
                -0.028321174904704094,
                -0.035665299743413925,
                -0.03175229951739311,
                0.030051838606595993,
                -0.00673295371234417,
                -5.332637469345e-33,
                -0.007831662893295288,
                -0.028433063998818398,
                0.03099871799349785,
                0.042578794062137604,
                -0.05401095002889633,
                -0.04506257548928261,
                0.03586757183074951,
                -0.004833651706576347,
                -0.014165394008159637,
                -0.019345425069332123,
                -0.017146261408925056,
                -0.03648655116558075,
                0.0225214920938015,
                0.037673428654670715,
                0.020669201388955116,
                -0.0018044672906398773,
                0.03812684118747711,
                0.033888380974531174,
                0.01881232298910618,
                -0.009957990609109402,
                -0.0017690577078610659,
                0.0181902963668108,
                -0.04578916355967522,
                0.06549446284770966,
                -0.032098956406116486,
                -0.024496467784047127,
                -0.02995448186993599,
                0.0289284847676754,
                0.013973118737339973,
                -0.008242511190474033,
                0.031317539513111115,
                0.011058108881115913,
                -0.013196389190852642,
                -0.008358586579561234,
                0.007032716181129217,
                0.00912349671125412,
                0.003857639152556658,
                0.030279582366347313,
                -0.0014752792194485664,
                -0.006602664012461901,
                -0.07155206054449081,
                0.03207466006278992,
                0.0005957952234894037,
                -0.010781856253743172,
                0.020183313637971878,
                0.0325707346200943,
                -0.016443638131022453,
                -0.027901340276002884,
                -0.001248308690264821,
                0.04138285666704178,
                -0.019059941172599792,
                -0.011865302920341492,
                -0.05827173963189125,
                0.03896396607160568,
                0.04013145714998245,
                -0.009862644597887993,
                0.012899237684905529,
                0.0300791934132576,
                0.01975419744849205,
                -0.03741498291492462,
                -0.0379813127219677,
                0.00791344977915287,
                0.015650765970349312,
                0.06609658151865005,
                -0.0472291074693203,
                0.016129104420542717,
                -0.0005781817017123103,
                0.06461212038993835,
                -0.0011265117209404707,
                0.06145506724715233,
                -0.0808701142668724,
                0.08345971256494522,
                -0.054886698722839355,
                -0.003363874042406678,
                -0.06435801833868027,
                -0.02252134494483471,
                0.042593080550432205,
                0.0038006415124982595,
                0.03018922545015812,
                -0.030887484550476074,
                -0.09988749027252197,
                -0.021264314651489258,
                0.03986181318759918,
                -0.024766121059656143,
                0.022025732323527336,
                -0.010777130722999573,
                -0.024881169199943542,
                0.029062436893582344,
                0.029610052704811096,
                0.01798084005713463,
                -0.010131869465112686,
                0.030190501362085342,
                -0.02297724224627018,
                0.0040297904051840305,
                0.031424395740032196,
                -0.08909361064434052,
                -0.017617488279938698,
                -0.02225005067884922,
                0.017145324498414993,
                -0.0179518461227417,
                -0.009624883532524109,
                0.028838757425546646,
                -0.03792040795087814,
                0.024159962311387062,
                -0.030187232419848442,
                0.01577196829020977,
                -0.031175702810287476,
                -0.02331559732556343,
                -0.016979491338133812,
                -0.012409326620399952,
                0.030186612159013748,
                0.02130907215178013,
                0.043300528079271317,
                -0.0051505789160728455,
                -0.026849105954170227,
                -0.05094829946756363,
                0.0162088330835104,
                -0.029711604118347168,
                -0.003358755260705948,
                -0.017654353752732277,
                0.04742877557873726,
                -0.007359074894338846,
                -0.017931342124938965,
                -0.01598113402724266,
                -0.015049234963953495,
                0.02693326771259308,
                0.03657105565071106,
                -0.02210395038127899,
                0.03464564308524132,
                -0.08158097416162491,
                -0.015495063737034798,
                -0.04444880783557892,
                2.0506462306002504e-07,
                0.041649095714092255,
                -0.010005336254835129,
                -0.022759027779102325,
                0.025924429297447205,
                0.04889507591724396,
                0.011932427063584328,
                -0.015766898170113564,
                0.02144745923578739,
                0.006848656106740236,
                -0.04811849445104599,
                0.02585311233997345,
                -0.022524042055010796,
                -0.0247443038970232,
                -0.041558887809515,
                0.009139416739344597,
                -0.013934134505689144,
                -0.0014077044324949384,
                -0.03570554032921791,
                0.009892510250210762,
                0.015621069818735123,
                -0.004346003755927086,
                0.009330390952527523,
                0.013896806165575981,
                -0.048165831714868546,
                -0.007255403324961662,
                -0.0619160495698452,
                0.03309308737516403,
                -0.003323666751384735,
                0.06263981759548187,
                0.0064118546433746815,
                0.05050006881356239,
                -0.011273530311882496,
                0.007598311640322208,
                0.011632781475782394,
                0.017485497519373894,
                -0.03745473921298981,
                0.01583503745496273,
                0.05989573150873184,
                0.014778416603803635,
                0.0737256184220314,
                -0.005633465014398098,
                0.00912077259272337,
                -0.030040638521313667,
                0.06423079967498779,
                -0.016299186274409294,
                -0.015065046958625317,
                0.00024410421610809863,
                0.031313322484493256,
                -0.0421324297785759,
                0.01117174606770277,
                -0.04457046464085579,
                0.007203104440122843,
                0.04480418935418129,
                0.023172495886683464,
                0.002920896280556917,
                -0.01275006216019392,
                -0.002496147993952036,
                0.048501309007406235,
                0.06228441372513771,
                -0.00745178060606122,
                -0.050095245242118835,
                -0.022094901651144028,
                -0.007608925923705101,
                0.0377235934138298,
                0.03955685347318649,
                -0.03628765791654587,
                -0.026712151244282722,
                7.991194841854077e-35,
                0.023142509162425995,
                0.015057557262480259,
                -0.007348306477069855,
                -0.017424771562218666,
                0.016450028866529465,
                -0.052172042429447174,
                -0.01704024150967598,
                -0.011538752354681492,
                -0.017565030604600906,
                -0.012349937111139297,
                -0.024677978828549385
            ]
        },
        "Skills": {
            "name": [
                "Marketing, Growth, Brand, Digital Product Management, Behavioural Economics"
            ],
            "rep": [
                0.05039113387465477,
                -0.0021844198927283287,
                -0.04925484582781792,
                -0.05113809183239937,
                0.0054783509112894535,
                0.011116603389382362,
                0.05911233648657799,
                -0.01817815564572811,
                -0.004376773722469807,
                -0.0141179533675313,
                0.03922266513109207,
                -0.0029597377870231867,
                0.027466336265206337,
                0.09967610239982605,
                -0.008122916333377361,
                0.010057308711111546,
                0.021732710301876068,
                0.01785796694457531,
                -0.014726925641298294,
                -0.006281287409365177,
                -0.02634618431329727,
                -0.031839702278375626,
                0.009890630841255188,
                -0.014668360352516174,
                -0.036654260009527206,
                -0.006484529003500938,
                0.04003112390637398,
                -0.0287457462400198,
                -0.015210306271910667,
                -0.04570002108812332,
                0.04307594150304794,
                -0.024034110829234123,
                0.02572343870997429,
                -0.02435324154794216,
                1.245389739779057e-06,
                -0.03738044574856758,
                -0.0032186845783144236,
                -0.005739111918956041,
                -0.019273972138762474,
                0.0357876755297184,
                0.06699345260858536,
                0.05618855729699135,
                -0.00016206975851673633,
                0.041855327785015106,
                0.020436890423297882,
                0.05059429630637169,
                0.019442886114120483,
                0.05153881013393402,
                -0.06210099533200264,
                0.019913669675588608,
                -0.023673659190535545,
                -0.05221659317612648,
                -0.07038027793169022,
                -0.03902515396475792,
                -0.05158993974328041,
                -0.01625528745353222,
                0.027592815458774567,
                0.028551824390888214,
                -0.010528631508350372,
                -0.0013687467435374856,
                0.004854116123169661,
                -0.009061400778591633,
                -0.012894341722130775,
                8.427455759374425e-05,
                0.024221401661634445,
                0.02044297754764557,
                0.09037774801254272,
                -0.04203841835260391,
                -0.03148975223302841,
                -0.018618948757648468,
                0.07324397563934326,
                0.005504831671714783,
                0.06466148793697357,
                0.01954926736652851,
                0.02533920854330063,
                -0.04104030504822731,
                -0.019217731431126595,
                -0.037197042256593704,
                0.009518987499177456,
                0.03830733522772789,
                -0.04692608863115311,
                0.010219409130513668,
                -0.018226098269224167,
                0.02756091021001339,
                0.02677314542233944,
                0.04236083850264549,
                -0.037083372473716736,
                -0.023903952911496162,
                -0.032763995230197906,
                -0.029678145423531532,
                0.03420816361904144,
                0.03299558162689209,
                -0.012777606025338173,
                -0.02247682958841324,
                0.051449209451675415,
                -0.01014614850282669,
                0.028559759259223938,
                -0.033273544162511826,
                0.03924676775932312,
                -0.08108281344175339,
                -0.0053426967933773994,
                -0.04621243476867676,
                0.022125020623207092,
                0.021508440375328064,
                0.0023524852003902197,
                0.02935824915766716,
                -0.04445378854870796,
                -0.046992529183626175,
                0.0004423585196491331,
                0.09695819020271301,
                -0.023037515580654144,
                -0.025105522945523262,
                -0.01464057620614767,
                0.02846582606434822,
                0.00237270281650126,
                -0.031214766204357147,
                -0.05151032283902168,
                -0.004519255831837654,
                -0.004275025334209204,
                -0.030171377584338188,
                -0.0032142915297299623,
                0.04619294032454491,
                -0.0016388383228331804,
                0.005919634364545345,
                -0.03250649571418762,
                0.05738012120127678,
                -0.06923630088567734,
                -0.003759150393307209,
                -2.9888245990150608e-05,
                0.02450922504067421,
                0.07889720052480698,
                0.003878958523273468,
                0.0408344566822052,
                -0.0031032110564410686,
                0.0617525652050972,
                0.060905203223228455,
                0.012696678750216961,
                0.025723101571202278,
                -0.10170973837375641,
                -0.028170708566904068,
                0.016406046226620674,
                -0.011944610625505447,
                -0.018896082416176796,
                -0.018469054251909256,
                -0.03437842056155205,
                -0.022183949127793312,
                0.0057242633774876595,
                0.030364200472831726,
                -0.008732127025723457,
                -0.010226870886981487,
                -0.036457765847444534,
                -0.07058794051408768,
                0.013571497052907944,
                0.0061626373790204525,
                0.05483255162835121,
                -0.012634613551199436,
                0.08704303205013275,
                -0.00911774393171072,
                -0.03442893922328949,
                -0.04861973598599434,
                0.007007172796875238,
                0.015593862161040306,
                -0.047136709094047546,
                0.04053671658039093,
                -0.03389720618724823,
                -0.00027312003658153117,
                0.01767793856561184,
                -0.012211242690682411,
                -0.03193318471312523,
                -0.04813665151596069,
                -0.044046152383089066,
                0.004127191845327616,
                0.00479915551841259,
                0.07857854664325714,
                0.046826574951410294,
                -0.07608217746019363,
                0.007203223649412394,
                -0.00436593871563673,
                -0.04838774353265762,
                -0.033551350235939026,
                -0.03331952914595604,
                -0.043349314481019974,
                0.06284135580062866,
                -0.028510643169283867,
                0.05467104911804199,
                0.05298071354627609,
                -0.012693947181105614,
                -0.013141670264303684,
                -0.044175438582897186,
                -0.00017352320719510317,
                0.03786351531744003,
                -0.03241327777504921,
                0.0394049733877182,
                0.048014264553785324,
                0.022414961829781532,
                0.016758793964982033,
                -0.011302931234240532,
                -0.05577479302883148,
                -0.05451678857207298,
                -0.00021644699154421687,
                -0.014771858230233192,
                -0.005260818637907505,
                0.09914838522672653,
                -0.013431096449494362,
                -0.004676906857639551,
                0.052310165017843246,
                0.008377840742468834,
                -0.013046946376562119,
                -0.04759811609983444,
                0.021624386310577393,
                0.012856968678534031,
                0.011282217688858509,
                -0.022951630875468254,
                -0.0019296599784865975,
                0.04807690531015396,
                -0.047816261649131775,
                -0.030565399676561356,
                0.010004830546677113,
                -0.021499134600162506,
                0.03153783455491066,
                -0.0029917138163000345,
                0.033859092742204666,
                0.07163253426551819,
                -0.05805996432900429,
                0.03523440286517143,
                0.0029090913012623787,
                -0.0036546525079756975,
                -0.03305031731724739,
                -0.024014025926589966,
                0.05730330944061279,
                0.0020363423973321915,
                0.008353829383850098,
                -0.009615794755518436,
                -0.05162184312939644,
                0.0029905817937105894,
                0.0351996086537838,
                0.164124995470047,
                0.09469156712293625,
                0.02802390418946743,
                -0.03906019777059555,
                -0.03077901527285576,
                -0.04483793303370476,
                0.005843630526214838,
                0.011928998865187168,
                0.038870919495821,
                -0.01652502454817295,
                0.09334885329008102,
                0.04618871957063675,
                -0.04515210539102554,
                0.0038978310767561197,
                -0.027239525690674782,
                0.057675592601299286,
                0.01216150727123022,
                0.011568032205104828,
                -0.002744069090113044,
                0.027599509805440903,
                -0.008911042474210262,
                0.01312685664743185,
                0.020405426621437073,
                0.03686092421412468,
                -0.01842563785612583,
                -0.02111058682203293,
                -0.020877500995993614,
                0.053279805928468704,
                -0.043966300785541534,
                -0.04563146084547043,
                0.048411089926958084,
                0.0270876195281744,
                -0.027182545512914658,
                0.026770323514938354,
                0.04815832898020744,
                -0.016037940979003906,
                0.03699039667844772,
                -0.028585299849510193,
                0.03227555379271507,
                0.015642724931240082,
                -0.02774292230606079,
                0.05353258177638054,
                -0.008051734417676926,
                0.0002978927514050156,
                0.02079031988978386,
                -0.03100089356303215,
                0.04063432663679123,
                -0.0026231245137751102,
                -1.7747464880812913e-05,
                -0.03527837619185448,
                0.08171823620796204,
                -0.021580487489700317,
                0.01350638922303915,
                -0.015706239268183708,
                -0.031857725232839584,
                -0.022472640499472618,
                -0.028303824365139008,
                5.1812181482091546e-05,
                -0.00122462201397866,
                0.025217609480023384,
                0.06723847985267639,
                -0.05285701900720596,
                0.008257564157247543,
                0.0014344841474667192,
                -0.05947381258010864,
                -0.06352383643388748,
                0.007661470212042332,
                -0.015082755126059055,
                -0.006690619979053736,
                0.026880038902163506,
                -0.015166305005550385,
                -0.006534127984195948,
                0.03149285539984703,
                0.03098391368985176,
                0.009198939427733421,
                -0.0012729036388918757,
                0.016549333930015564,
                -0.004407256375998259,
                -0.004800505004823208,
                -0.016552641987800598,
                -0.030908823013305664,
                0.021645504981279373,
                -0.023894397541880608,
                0.08536773175001144,
                0.020448222756385803,
                -0.03195846453309059,
                -0.04700786992907524,
                0.019087761640548706,
                0.03592251241207123,
                0.01612916588783264,
                -0.030449163168668747,
                0.020874664187431335,
                -0.04342763125896454,
                -0.03391123563051224,
                -0.02062087319791317,
                0.028776004910469055,
                -0.04675106704235077,
                0.0012263611424714327,
                -0.013244918547570705,
                0.02636951580643654,
                -0.004680235404521227,
                -0.0045966096222400665,
                0.04397231340408325,
                -0.005547864828258753,
                -0.023659296333789825,
                0.020247668027877808,
                0.014947164803743362,
                -0.07371968030929565,
                0.043185845017433167,
                -0.056331586092710495,
                0.019451463595032692,
                -0.029950223863124847,
                -0.022920817136764526,
                -0.06114920228719711,
                -0.007804717402905226,
                -0.05055629462003708,
                -0.01943860575556755,
                0.019092919304966927,
                0.02306775003671646,
                0.009616450406610966,
                -0.0033846015576273203,
                0.00046645308611914515,
                -0.051542170345783234,
                -0.05999410152435303,
                -0.042846329510211945,
                -0.03997071832418442,
                -0.03563966229557991,
                0.0013829534873366356,
                -0.02348901890218258,
                0.009951175190508366,
                -0.009830426424741745,
                0.02268436923623085,
                0.04935488477349281,
                0.04507993906736374,
                -0.008893221616744995,
                0.0582047663629055,
                0.02972443588078022,
                0.0052107698284089565,
                -0.04877170920372009,
                0.09032917022705078,
                -0.002648301888257265,
                0.008459040895104408,
                -0.03314073756337166,
                -0.0021746226120740175,
                0.035565946251153946,
                -0.01671629771590233,
                0.08311542123556137,
                -0.027616968378424644,
                0.013816515915095806,
                -0.06334506720304489,
                0.05336737632751465,
                -0.03373730555176735,
                -0.015280552208423615,
                -0.001310930703766644,
                -0.015136921778321266,
                0.03982853516936302,
                -0.010736804455518723,
                0.07074286788702011,
                -0.044916510581970215,
                0.018041452392935753,
                0.05236878991127014,
                -0.024525290355086327,
                -0.03197344392538071,
                0.03909851238131523,
                0.06544589251279831,
                -0.014777613803744316,
                -0.005761666689068079,
                -0.014563504606485367,
                -0.029620608314871788,
                -0.04117622971534729,
                0.06742465496063232,
                0.010111730545759201,
                -0.06293138861656189,
                -0.013349450193345547,
                -0.036125000566244125,
                0.013024779036641121,
                0.028569668531417847,
                0.024270977824926376,
                0.0038880822248756886,
                -0.023099815472960472,
                0.0015639190096408129,
                -0.02402424067258835,
                0.0014190089423209429,
                -0.03216094523668289,
                0.01861165277659893,
                -2.690058136067819e-05,
                -0.06950466334819794,
                0.05440321937203407,
                -0.037684451788663864,
                -0.0580194890499115,
                0.0059110200963914394,
                -0.0507497601211071,
                0.01084057055413723,
                -0.017203599214553833,
                -0.0005844836123287678,
                -0.0016811947571113706,
                -0.02979232743382454,
                0.025268180295825005,
                0.002358243567869067,
                -0.013121113181114197,
                -0.0016216366784647107,
                0.007722281850874424,
                0.007180845830589533,
                0.04968008026480675,
                0.03274044767022133,
                -0.025751056149601936,
                0.027450015768408775,
                0.019631316885352135,
                -0.010299661196768284,
                -0.034507621079683304,
                0.03196875378489494,
                0.03222407400608063,
                -0.07698895037174225,
                0.029601046815514565,
                0.016224291175603867,
                -0.04132409766316414,
                0.051342397928237915,
                0.057310089468955994,
                -0.017967358231544495,
                0.006212592590600252,
                -0.06188296154141426,
                -0.0715482234954834,
                -0.03225720673799515,
                -0.006436933763325214,
                0.05459265410900116,
                0.027091262862086296,
                0.017609110102057457,
                0.028834499418735504,
                -0.014440011233091354,
                0.017849372699856758,
                0.054197002202272415,
                0.08334790170192719,
                -0.0017461343668401241,
                0.028190026059746742,
                0.04355047270655632,
                -0.01377504225820303,
                -0.027183925732970238,
                0.019046278670430183,
                -0.018419796600937843,
                -0.010296685621142387,
                0.008487384766340256,
                -0.01079423539340496,
                0.02082754299044609,
                0.020990747958421707,
                -0.010777042247354984,
                0.01955292373895645,
                -0.022855639457702637,
                0.06944159418344498,
                0.010589919984340668,
                -0.018526047468185425,
                0.07019544392824173,
                0.018087921664118767,
                0.003743273438885808,
                -0.05518930032849312,
                0.01886589080095291,
                0.012770776636898518,
                0.00610739691182971,
                -0.052763305604457855,
                -0.015753544867038727,
                -0.0403292253613472,
                0.007929612882435322,
                0.03592778369784355,
                -0.038862403482198715,
                0.056659065186977386,
                0.004550018813461065,
                0.0014812323497608304,
                0.018093062564730644,
                0.005410300567746162,
                -0.06948668509721756,
                0.01716708205640316,
                -0.008636354468762875,
                0.01922014355659485,
                -0.005162776913493872,
                0.02710561640560627,
                0.004082126077264547,
                0.027673620730638504,
                0.06828366965055466,
                -0.026684319600462914,
                0.0009386413730680943,
                -0.0032823667861521244,
                -0.04130064696073532,
                -0.054372675716876984,
                0.010652036406099796,
                -0.0603308230638504,
                0.003147606272250414,
                -0.01937118172645569,
                0.011765247210860252,
                -0.06386026740074158,
                0.013881638646125793,
                -0.016053633764386177,
                -0.01070037018507719,
                -0.022875342518091202,
                0.006735930684953928,
                0.04949773848056793,
                -0.007458845153450966,
                -0.0650673359632492,
                -0.03466804325580597,
                0.0029874236788600683,
                -0.015438223257660866,
                0.04681674391031265,
                0.008115449920296669,
                0.038063108921051025,
                0.048301659524440765,
                0.00855435710400343,
                0.011631532572209835,
                -0.045740410685539246,
                0.017236704006791115,
                -0.017587946727871895,
                0.04400389641523361,
                -0.035947415977716446,
                -0.022397495806217194,
                -0.008466647937893867,
                0.010673398151993752,
                -0.0220192763954401,
                -0.026859058067202568,
                0.008253298699855804,
                -0.0696771889925003,
                0.01972256600856781,
                -0.00041191873606294394,
                -4.659723156822889e-33,
                0.03766677901148796,
                -0.03181507810950279,
                -0.021399924531579018,
                0.05579021945595741,
                -0.0810079351067543,
                0.0022977956105023623,
                -0.0020142525900155306,
                0.02712155506014824,
                -0.027668526396155357,
                -0.03865675628185272,
                -0.03997650742530823,
                -0.016727827489376068,
                0.03458968922495842,
                0.05199222266674042,
                0.05021488666534424,
                -0.04690173640847206,
                0.016056345775723457,
                0.009197993203997612,
                0.03231588006019592,
                -0.03875552862882614,
                -0.02854774333536625,
                0.03518674895167351,
                0.028541188687086105,
                0.02578861080110073,
                0.016637125983834267,
                -0.019817234948277473,
                -0.025306688621640205,
                -0.048035770654678345,
                0.0544637031853199,
                0.016383063048124313,
                0.0379866361618042,
                -0.019384240731596947,
                0.04995603859424591,
                -0.05165223777294159,
                0.05175740271806717,
                0.02476469799876213,
                -0.05217696353793144,
                0.022593336179852486,
                0.013606741093099117,
                -0.011791192926466465,
                0.01685953140258789,
                -0.037981368601322174,
                0.03539804741740227,
                -0.003537433221936226,
                -0.01659492217004299,
                0.020926933735609055,
                0.024389339610934258,
                0.0015328966546803713,
                -0.009063465520739555,
                -0.010625183582305908,
                -0.01689479872584343,
                -0.010725131258368492,
                -0.013550318777561188,
                0.0999838188290596,
                -0.00747836846858263,
                0.02196025289595127,
                0.00699002668261528,
                -0.009148509241640568,
                -0.07880357652902603,
                0.010366067290306091,
                -0.04108775779604912,
                -0.0001525878324173391,
                -0.018394533544778824,
                0.032724808901548386,
                0.031739432364702225,
                -0.0037383835297077894,
                -0.038312386721372604,
                0.020544834434986115,
                0.044861726462841034,
                0.0218513086438179,
                -6.765664875274524e-05,
                0.0752023234963417,
                -0.053460102528333664,
                0.011524545028805733,
                -0.037941355258226395,
                -0.024238167330622673,
                0.07368221133947372,
                0.00727694621309638,
                0.12673553824424744,
                -0.07365192472934723,
                -0.06652656197547913,
                -0.012412041425704956,
                0.02468537911772728,
                -0.03025287576019764,
                0.05641574412584305,
                -0.045490771532058716,
                -0.005004405975341797,
                -0.030053263530135155,
                0.03728632628917694,
                -0.022748343646526337,
                -0.008610047399997711,
                0.015044117346405983,
                0.009705341421067715,
                -0.022081976756453514,
                -0.052003126591444016,
                -0.07990016788244247,
                0.06151050701737404,
                0.04309353232383728,
                0.014627452939748764,
                -0.052898045629262924,
                -0.02301127091050148,
                -0.00958134513348341,
                -0.07143354415893555,
                0.04869385436177254,
                -0.028057992458343506,
                -0.024847637861967087,
                -0.0758722722530365,
                -0.011860477738082409,
                -0.010900009423494339,
                -0.0033165437635034323,
                0.06801331043243408,
                0.0007672011270187795,
                0.02218799479305744,
                -0.05836543068289757,
                -0.007706961594521999,
                -0.014583401381969452,
                0.020663809031248093,
                -0.010117440484464169,
                -0.01643725112080574,
                0.009156967513263226,
                0.01286100409924984,
                -0.036658987402915955,
                -0.012020915746688843,
                -0.035955850034952164,
                -0.04372064769268036,
                -0.009266839362680912,
                -0.019411280751228333,
                0.024822639301419258,
                0.02567172795534134,
                -0.04886728525161743,
                -0.011469447053968906,
                -0.026574552059173584,
                1.992267613104559e-07,
                0.012164230458438396,
                -0.0015612844144925475,
                -0.009025760926306248,
                0.04632142558693886,
                0.025207877159118652,
                0.052089013159275055,
                -0.004613108932971954,
                -0.0019726231694221497,
                0.048051703721284866,
                -0.046503081917762756,
                0.04511072114109993,
                -0.06683944165706635,
                -0.07778110355138779,
                0.0038807380478829145,
                0.023580046370625496,
                -0.004632280208170414,
                -0.056599561125040054,
                -0.05580544471740723,
                -0.005582927260547876,
                0.021874818950891495,
                -0.010214538313448429,
                -0.006053586956113577,
                0.008519349619746208,
                -0.020105447620153427,
                -0.022793957963585854,
                0.02734845131635666,
                -0.0030194930732250214,
                -0.012510967440903187,
                0.05784929543733597,
                -0.005167824681848288,
                -0.02599586732685566,
                0.006679098587483168,
                -0.019458027556538582,
                -0.04867358133196831,
                -0.012997987680137157,
                0.0106893852353096,
                -0.00268982769921422,
                -0.033377520740032196,
                0.09587802737951279,
                0.03777124732732773,
                0.009074614383280277,
                0.012477070093154907,
                -0.011703521013259888,
                -0.01173008605837822,
                0.05160846933722496,
                0.020497143268585205,
                -0.0322323814034462,
                -0.04008670151233673,
                -0.029790591448545456,
                0.013625981286168098,
                -0.006033554207533598,
                -0.04111502319574356,
                0.021744998171925545,
                0.02149365097284317,
                -0.030763981863856316,
                -0.013692338950932026,
                0.03965698927640915,
                -0.0026233335956931114,
                0.0513969287276268,
                0.010455182753503323,
                -0.04275313392281532,
                0.028707658872008324,
                -0.0004712700319942087,
                0.0910513699054718,
                -0.01057056151330471,
                0.01796337217092514,
                0.04039972275495529,
                1.00268428345943e-34,
                0.005753286648541689,
                -0.05860711261630058,
                0.028712626546621323,
                -0.008928018622100353,
                0.005879686214029789,
                -0.0029678787104785442,
                -0.04021598398685455,
                0.003604419995099306,
                -0.007761555258184671,
                -0.016555946320295334,
                -0.03216959908604622
            ]
        },
        "Values": {
            "name": [
                "Insight, Innovation, Impact"
            ],
            "rep": [
                0.012225879356265068,
                -0.007934078574180603,
                -0.0091886967420578,
                -0.024280918762087822,
                -0.0336759053170681,
                -0.0230154637247324,
                0.021607641130685806,
                0.024462513625621796,
                -0.017985915765166283,
                -0.012334302999079227,
                0.02562851831316948,
                0.09828738868236542,
                0.01808859407901764,
                0.11365623772144318,
                0.0018261466175317764,
                0.02341674454510212,
                0.021446792408823967,
                0.03569041192531586,
                -0.07169003784656525,
                0.030358033254742622,
                -0.029166221618652344,
                0.004261314403265715,
                -0.02718193642795086,
                0.03087511658668518,
                0.012250018306076527,
                -1.896128014777787e-05,
                0.012880461290478706,
                0.018047071993350983,
                -0.0020407838746905327,
                -0.041724346578121185,
                0.0063141402788460255,
                0.0016825832426548004,
                -0.05739730969071388,
                0.01603258214890957,
                1.5278399132512277e-06,
                -0.0199546180665493,
                -0.02298770286142826,
                0.01043489295989275,
                -0.0014550426276400685,
                0.011811853386461735,
                0.010965711437165737,
                0.004435892682522535,
                -0.016248708590865135,
                0.01105690561234951,
                -0.014590345323085785,
                0.004440983757376671,
                -0.029850872233510017,
                -0.02727256901562214,
                -0.06983521580696106,
                -0.02054785192012787,
                0.016867732629179955,
                0.00450473977252841,
                -0.015683170408010483,
                -0.052449289709329605,
                -0.03209869563579559,
                -0.02829156629741192,
                0.00017098666285164654,
                -0.02680845931172371,
                -0.03838680684566498,
                0.00171551329549402,
                -0.019385918974876404,
                0.04785116761922836,
                -0.04688843712210655,
                -0.037056099623441696,
                0.06220513582229614,
                0.03617161884903908,
                0.05876721814274788,
                -0.005146168638020754,
                -0.0060208747163414955,
                -0.01160349603742361,
                0.054197926074266434,
                0.013873117044568062,
                0.008694079704582691,
                0.03925338760018349,
                0.029819361865520477,
                0.017421355471014977,
                0.010700883343815804,
                -0.04294154793024063,
                0.014922101981937885,
                -0.019380265846848488,
                -0.0012008472112938762,
                0.05592065677046776,
                -0.0028032553382217884,
                0.018575290217995644,
                0.022164030000567436,
                0.011353827081620693,
                -0.020388856530189514,
                -0.0030866682063788176,
                -0.01839069463312626,
                -0.009827574715018272,
                0.06344997882843018,
                -0.006870203651487827,
                -0.025612378492951393,
                0.012212341651320457,
                0.017858652397990227,
                -0.027681991457939148,
                0.03288707882165909,
                0.020253095775842667,
                -0.017995258793234825,
                -0.020418569445610046,
                -0.01627803035080433,
                0.011508798226714134,
                0.046496614813804626,
                0.026297982782125473,
                -0.04195031896233559,
                0.036309774965047836,
                -0.025567883625626564,
                -0.041560206562280655,
                -0.027268512174487114,
                0.0037898931186646223,
                -0.05382183939218521,
                -0.019955988973379135,
                -0.032788462936878204,
                0.04646342992782593,
                -0.004128654021769762,
                -0.037765517830848694,
                -0.041912298649549484,
                0.0066155968233942986,
                -0.00885515846312046,
                0.033183302730321884,
                -0.03161543235182762,
                0.047510575503110886,
                0.00842373725026846,
                0.019844399765133858,
                -0.013383312150835991,
                0.03281204774975777,
                -0.06427668035030365,
                0.005751254968345165,
                -0.0028234052006155252,
                0.0011454899795353413,
                0.0010421875631436706,
                -0.01794988475739956,
                0.007917067967355251,
                -0.00314332265406847,
                0.020187048241496086,
                -0.006151075940579176,
                -0.006460451055318117,
                -0.031222477555274963,
                -0.032179977744817734,
                -0.020920995622873306,
                0.02027934417128563,
                -0.030779989436268806,
                -0.043883249163627625,
                0.014677531085908413,
                -0.023332297801971436,
                0.008000275120139122,
                0.02339763753116131,
                -0.03508073464035988,
                0.005696326494216919,
                -0.01832493208348751,
                0.008101759478449821,
                -0.0883568525314331,
                -0.05098520964384079,
                0.014377309940755367,
                -0.0015144055942073464,
                0.052901364862918854,
                0.06847406923770905,
                -0.008217915892601013,
                -0.035489264875650406,
                -0.026382926851511,
                0.024154914543032646,
                0.024740509688854218,
                0.013808930292725563,
                -0.01285751722753048,
                0.014992468990385532,
                -0.008647032082080841,
                0.02164488472044468,
                -0.0010233984794467688,
                -0.10234921425580978,
                0.011635112576186657,
                -0.055704619735479355,
                -0.02281482145190239,
                -0.02307303622364998,
                0.1532873958349228,
                0.03746335580945015,
                -0.004191741347312927,
                0.0062594362534582615,
                0.023883987218141556,
                -0.040305472910404205,
                -0.026030637323856354,
                -0.019089512526988983,
                -0.15775540471076965,
                0.05000653490424156,
                -0.016677897423505783,
                0.0764356404542923,
                0.01622956059873104,
                0.047592610120773315,
                -0.0047044274397194386,
                -0.01953211985528469,
                0.008465398102998734,
                0.03391777351498604,
                0.03727429360151291,
                -0.03317537158727646,
                -0.0013684555888175964,
                -0.008043787442147732,
                -0.0071351053193211555,
                0.0028145734686404467,
                0.03498533368110657,
                -0.06451749056577682,
                0.047240495681762695,
                -0.03836669400334358,
                -0.027024466544389725,
                0.0406673401594162,
                0.006401440594345331,
                0.023555228486657143,
                0.03103119134902954,
                0.06336042284965515,
                -0.02953697182238102,
                -0.06913089752197266,
                0.014882861636579037,
                0.010906098410487175,
                0.022271066904067993,
                -0.023742251098155975,
                0.06700416654348373,
                0.021485690027475357,
                -0.041170865297317505,
                0.019941920414566994,
                0.012140141800045967,
                -0.010729886591434479,
                0.08427738398313522,
                -0.04608076065778732,
                0.06947124749422073,
                0.05568397045135498,
                -0.047113772481679916,
                0.013558094389736652,
                -0.007080380339175463,
                0.010934402234852314,
                -0.04741207882761955,
                -0.026269666850566864,
                0.06186367943882942,
                -0.003826244967058301,
                0.03131544962525368,
                -0.0025321149732917547,
                -0.030381817370653152,
                -0.028300566598773003,
                0.03324386477470398,
                0.0921596810221672,
                0.11562728136777878,
                -0.020422307774424553,
                -0.1059952974319458,
                0.01164261531084776,
                -0.04048607498407364,
                0.03612438216805458,
                0.016545424237847328,
                0.041787292808294296,
                0.006950549315661192,
                0.06544099748134613,
                0.00021309911971911788,
                -0.012729075737297535,
                -0.016384830698370934,
                -0.03444008529186249,
                -0.0011833305470645428,
                0.03992641717195511,
                -0.033133380115032196,
                0.03399026393890381,
                0.012252597138285637,
                -0.08689652383327484,
                0.009968052618205547,
                0.0507340170443058,
                0.02642851136624813,
                0.017999645322561264,
                -0.022293662652373314,
                -0.03229701891541481,
                0.006734202150255442,
                -0.043499626219272614,
                -0.03821542486548424,
                0.05506710708141327,
                -0.030071264132857323,
                -0.040562547743320465,
                0.001880489639006555,
                0.0031076360028237104,
                -0.016073497012257576,
                0.04958481714129448,
                -0.00017274651327170432,
                0.011333676986396313,
                0.04493445158004761,
                -0.05656415596604347,
                0.031558647751808167,
                -0.032766737043857574,
                0.01383544784039259,
                0.06966273486614227,
                -0.006340847350656986,
                -0.00891321711242199,
                0.004673087038099766,
                0.011530004441738129,
                -0.0039996132254600525,
                0.07328183948993683,
                -0.08309770375490189,
                0.006292364560067654,
                -0.0659487247467041,
                -0.005227369721978903,
                -0.025155674666166306,
                0.010909659788012505,
                -0.029435213655233383,
                0.03113347478210926,
                0.021128853783011436,
                -0.05673844739794731,
                0.0049404785968363285,
                -0.013778718188405037,
                0.05405732989311218,
                -0.07393007725477219,
                -0.044945210218429565,
                0.04761412739753723,
                -0.005659457296133041,
                -0.050644949078559875,
                0.10197021812200546,
                0.03813180699944496,
                0.04796367138624191,
                -0.0017204302130267024,
                -0.019445372745394707,
                0.009532793425023556,
                0.009090942330658436,
                0.05377279222011566,
                0.03799573704600334,
                0.006284754257649183,
                -0.05427050217986107,
                -0.030612079426646233,
                -0.06688503921031952,
                -0.015747494995594025,
                0.10256502032279968,
                -0.027547014877200127,
                -0.017931770533323288,
                -0.06253557652235031,
                0.011012282222509384,
                0.021902021020650864,
                -0.016869859769940376,
                -0.004228973761200905,
                -0.03274083137512207,
                0.044657256454229355,
                -0.07006409019231796,
                -0.00836492795497179,
                -0.020688079297542572,
                -0.017924049869179726,
                0.05155736580491066,
                0.0093785235658288,
                0.014734090305864811,
                -0.02985382452607155,
                0.0016051462152972817,
                -0.025442857295274734,
                -0.006257345899939537,
                -0.006063526030629873,
                -0.022997993975877762,
                -0.027755293995141983,
                0.028330877423286438,
                0.04156005382537842,
                -0.0011765151284635067,
                0.10811708867549896,
                0.0036237146705389023,
                -0.04214063659310341,
                -0.017730996012687683,
                0.05966045334935188,
                0.05405448004603386,
                -0.011294451542198658,
                -0.015279852785170078,
                -0.019006161019206047,
                -0.06546752899885178,
                -0.047502972185611725,
                -0.00012879041605629027,
                0.009814498946070671,
                0.024725191295146942,
                0.031994644552469254,
                -0.002368534915149212,
                -0.007984843105077744,
                -0.0348631925880909,
                0.0009544533095322549,
                0.010330366902053356,
                0.024461613968014717,
                0.015381713397800922,
                -0.015937533229589462,
                -0.02480776607990265,
                0.04364773631095886,
                0.02870004251599312,
                -0.05138922855257988,
                0.004255755804479122,
                -0.01865643449127674,
                0.003372630337253213,
                0.0013277081307023764,
                0.0030131866224110126,
                -0.05233915150165558,
                0.052795443683862686,
                0.02838650345802307,
                -0.028075607493519783,
                0.04673255980014801,
                -0.03798648342490196,
                -0.02837284281849861,
                -0.02592635527253151,
                -0.03311999887228012,
                -0.02848926931619644,
                -0.004182068631052971,
                0.03551632910966873,
                -0.025925930589437485,
                0.04492099583148956,
                -0.06162036582827568,
                0.05195603892207146,
                -0.03893527761101723,
                -0.01118852011859417,
                -0.03708489611744881,
                0.005097284913063049,
                -0.01903415657579899,
                0.01995272934436798,
                0.01893679052591324,
                -0.011758183129131794,
                0.012795386835932732,
                -0.01858922839164734,
                -0.012764554470777512,
                -0.08567517250776291,
                0.13234741985797882,
                -0.007983622141182423,
                -0.05624594911932945,
                0.033023472875356674,
                0.006504003889858723,
                0.008864488452672958,
                0.023479076102375984,
                -0.020555008202791214,
                0.009940150193870068,
                -0.0788462683558464,
                -0.016083208844065666,
                0.013922962360084057,
                0.019238052889704704,
                -0.003104348201304674,
                0.0256244707852602,
                -0.0002073274226859212,
                -0.00763080595061183,
                -0.026750598102808,
                0.06746777892112732,
                -0.015533486381173134,
                -0.04102088510990143,
                0.03127928823232651,
                -0.01264929212629795,
                0.003069857833907008,
                -0.012298171408474445,
                0.015506445430219173,
                -0.004078796599060297,
                0.022556141018867493,
                -0.011959699913859367,
                -0.08217686414718628,
                -0.02388874813914299,
                0.052008405327796936,
                -0.005467770621180534,
                -0.024888701736927032,
                0.007635121233761311,
                0.0184281338006258,
                0.04943934455513954,
                -0.006201139651238918,
                0.05452629178762436,
                -0.0738665759563446,
                0.00562027283012867,
                0.013595187105238438,
                -0.032851699739694595,
                0.020377257838845253,
                0.009018873795866966,
                -0.010783921927213669,
                -0.0035826233215630054,
                -0.017945416271686554,
                -0.0034791547805070877,
                -0.03228311613202095,
                -0.0098506398499012,
                -0.01499221008270979,
                0.023672029376029968,
                0.022512119263410568,
                0.033324599266052246,
                0.04957618936896324,
                -0.0011377485934644938,
                0.010633290745317936,
                0.040985532104969025,
                0.0584367997944355,
                0.020647011697292328,
                0.04327498748898506,
                -0.02930300123989582,
                -0.01083152461796999,
                0.026665816083550453,
                -0.009349452331662178,
                -0.03169185295701027,
                0.02360890805721283,
                -0.04860507324337959,
                -0.012080159969627857,
                -0.012681916356086731,
                -0.004901682026684284,
                -0.03621935099363327,
                0.008876551873981953,
                -0.04402482509613037,
                0.06571676582098007,
                -0.0020405740942806005,
                0.02966681309044361,
                0.002145170234143734,
                -0.02298598922789097,
                0.035923443734645844,
                0.004374044016003609,
                0.001024695229716599,
                -0.027343863621354103,
                0.013630494475364685,
                -0.03197130188345909,
                -0.025737708434462547,
                0.07157742977142334,
                -0.0017655834089964628,
                -0.007423744536936283,
                -0.07873792201280594,
                0.056086115539073944,
                -0.01718183048069477,
                0.07455164939165115,
                -0.012572475709021091,
                0.013477989472448826,
                0.00010355660924687982,
                0.022088930010795593,
                -0.029052967205643654,
                0.013252230361104012,
                0.00488303042948246,
                0.06879138946533203,
                -0.00831594318151474,
                0.007937217131257057,
                0.016650160774588585,
                0.016182072460651398,
                -0.036828309297561646,
                -0.03105602227151394,
                0.028488026931881905,
                0.04174265265464783,
                0.0015289309667423368,
                0.04978753998875618,
                -0.011918812990188599,
                -0.006015041843056679,
                -0.01553595531731844,
                0.002996554132550955,
                0.029527761042118073,
                -0.018313076347112656,
                0.015585171058773994,
                -0.01320625003427267,
                0.017773039638996124,
                0.0176892951130867,
                -0.02786492556333542,
                0.025833509862422943,
                -0.07985527813434601,
                -0.03082953952252865,
                -0.02524667978286743,
                0.00448253657668829,
                -0.005432853475213051,
                0.0224982388317585,
                0.06816909462213516,
                0.05219952389597893,
                0.04719596728682518,
                0.049249425530433655,
                -0.052422381937503815,
                0.03605320677161217,
                0.0466831810772419,
                0.020069055259227753,
                0.025298476219177246,
                -0.01853080466389656,
                0.021321093663573265,
                0.03484860062599182,
                0.05317075178027153,
                -0.06442023813724518,
                -0.03138319402933121,
                -0.007153620012104511,
                -0.13427327573299408,
                -0.019530845806002617,
                0.06600280106067657,
                -5.9715733829726233e-33,
                0.01945624127984047,
                -0.010961032472550869,
                0.0075024692341685295,
                -0.02940836362540722,
                -0.05857459083199501,
                0.026103639975190163,
                -0.006100947503000498,
                0.03897159919142723,
                -0.0780513659119606,
                -0.02602325566112995,
                -0.030369605869054794,
                -0.015272347256541252,
                0.018472854048013687,
                -0.01979513093829155,
                0.038050319999456406,
                -0.005173067562282085,
                0.0042392052710056305,
                0.019612878561019897,
                0.006362740881741047,
                -0.027918504551053047,
                -0.01652829349040985,
                0.002052944153547287,
                0.008029622957110405,
                0.03672376275062561,
                0.05134929344058037,
                -0.0369032584130764,
                -0.019348762929439545,
                -0.007813825272023678,
                -0.0023227734491229057,
                -0.014035996980965137,
                0.010696006007492542,
                -0.057038698345422745,
                0.00045231025433167815,
                -0.14188021421432495,
                0.019954219460487366,
                -0.011073603294789791,
                -0.00827724952250719,
                -0.025152066722512245,
                -0.005521114449948072,
                -0.011298982426524162,
                -0.06174841150641441,
                0.009217065759003162,
                -0.0048066917806863785,
                0.016608858481049538,
                -0.010924422182142735,
                -0.02054443210363388,
                -0.015900172293186188,
                0.006610386539250612,
                -0.022686412557959557,
                -0.022908585146069527,
                -0.0385199598968029,
                -0.008061960339546204,
                -0.03399643674492836,
                0.05226485803723335,
                0.010550092905759811,
                -0.061586860567331314,
                -0.016500217840075493,
                0.0004435321025084704,
                -0.039821039885282516,
                -0.03730722889304161,
                0.007463210728019476,
                0.04971982538700104,
                0.010580956935882568,
                0.004564419388771057,
                -0.04985738918185234,
                0.00018776294018607587,
                0.06324063241481781,
                0.015929680317640305,
                0.035656314343214035,
                0.014834832400083542,
                -0.013216896913945675,
                0.05746355280280113,
                0.01293360535055399,
                0.03487914800643921,
                -0.1049661636352539,
                -0.006061153020709753,
                -0.005285220220685005,
                0.005263267084956169,
                0.09035652130842209,
                0.013006591238081455,
                0.02694631926715374,
                -0.03290357068181038,
                -0.013605606742203236,
                0.01039840653538704,
                0.04368847981095314,
                -0.05343947187066078,
                -0.01576964184641838,
                0.03235085681080818,
                0.013737572357058525,
                0.0015195913147181273,
                0.02393806353211403,
                0.0950402021408081,
                0.023421093821525574,
                -0.015806961804628372,
                0.03818940743803978,
                -0.021079396829009056,
                -0.02250942960381508,
                0.04124525561928749,
                -0.010544098913669586,
                -0.03476966544985771,
                -0.07267574965953827,
                0.016772182658314705,
                -0.0912787914276123,
                0.06161872297525406,
                0.01126120425760746,
                -0.034631434828042984,
                -0.04336277395486832,
                -0.011516358703374863,
                -0.0015544879715889692,
                -0.0060417624190449715,
                0.0028034686110913754,
                0.03392808511853218,
                0.025790130719542503,
                -0.07354586571455002,
                -0.04368463158607483,
                -0.0027404732536524534,
                -0.0069498238153755665,
                0.004451125394552946,
                0.004469697363674641,
                0.047222595661878586,
                0.04898201301693916,
                -0.05055221542716026,
                -0.027563145384192467,
                0.009178908541798592,
                -0.03660139441490173,
                0.02015526406466961,
                0.03398401290178299,
                0.021197138354182243,
                -0.04163457825779915,
                0.020616425201296806,
                -0.023470861837267876,
                0.010650635696947575,
                2.1170957609228935e-07,
                0.0060396757908165455,
                -0.0016407552175223827,
                0.026459574699401855,
                0.054730892181396484,
                0.028520267456769943,
                0.0065240743570029736,
                0.0031249993480741978,
                0.03392310440540314,
                0.0007927790866233408,
                0.011139373295009136,
                0.07268431037664413,
                -0.018463701009750366,
                -0.021604949608445168,
                -0.0309381615370512,
                -0.02894812822341919,
                -0.10098366439342499,
                -0.03225933760404587,
                -0.007494649384170771,
                -0.02041866071522236,
                -0.001040235161781311,
                0.012969490140676498,
                -0.0025757234543561935,
                -0.006873646751046181,
                0.005657116416841745,
                -0.01879413053393364,
                0.03671213239431381,
                0.005101054906845093,
                -0.03270576521754265,
                0.06408417969942093,
                0.02569226548075676,
                0.04016660526394844,
                0.02463071048259735,
                -0.024141766130924225,
                0.006434170063585043,
                0.004830110352486372,
                -0.01464157272130251,
                0.014126179739832878,
                -0.02597207762300968,
                0.03961390629410744,
                0.04021675884723663,
                0.0039026839658617973,
                -0.023947207257151604,
                0.0248422771692276,
                -0.007246173452585936,
                0.05058364197611809,
                0.04368436709046364,
                -0.010925871320068836,
                0.027310188859701157,
                -0.06257888674736023,
                0.02843334712088108,
                0.0007352600805461407,
                0.023447781801223755,
                0.00679600378498435,
                0.001151329604908824,
                -0.0073534781113266945,
                -0.0034131552092731,
                -0.023834455758333206,
                0.00012949135270901024,
                0.03507557883858681,
                -0.028946219012141228,
                -0.017004674300551414,
                -0.012893665581941605,
                0.013596277683973312,
                -0.01240293774753809,
                0.01834687776863575,
                -0.0068273162469267845,
                -0.009097454138100147,
                1.1510867718308974e-34,
                0.01007525809109211,
                -0.04994715377688408,
                0.005917864851653576,
                0.034763213247060776,
                0.016792014241218567,
                -0.013375235721468925,
                -0.05433293431997299,
                -0.03256558999419212,
                0.016663145273923874,
                -0.01507357694208622,
                -0.03582090884447098
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\xf4\\x17\\xe7\\xfa\\x91S\\x94\\x82\\xd4\\x9f\\x86\\xf1K5\\x1a8'",
        "id__": "47c63b77-12c3-48fd-8145-87f9e4c2b721"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "PhD/Science in Renewable Energy ",
            "rep": [
                0.000836700142826885,
                0.015019562095403671,
                -0.06086394935846329,
                -0.00633228151127696,
                0.02571086958050728,
                -0.009067421779036522,
                0.0024834773503243923,
                0.012030141428112984,
                -0.002704779850319028,
                -0.026762941852211952,
                0.009965071454644203,
                0.06166788935661316,
                0.013666586950421333,
                0.03896615654230118,
                0.07681179791688919,
                -0.02506578154861927,
                0.04119225963950157,
                0.027018040418624878,
                -0.004075308330357075,
                -0.027687814086675644,
                -0.01342620700597763,
                -0.033613450825214386,
                -0.025242751464247704,
                0.018554968759417534,
                -0.030306361615657806,
                -0.03762965276837349,
                -0.015542099252343178,
                0.06685811281204224,
                0.04222691431641579,
                -0.014864804223179817,
                0.045428503304719925,
                -0.031280290335416794,
                -0.040314681828022,
                0.02575615979731083,
                1.1049642125726677e-06,
                -0.03092111647129059,
                -0.0458373986184597,
                0.01241714134812355,
                0.06508871912956238,
                -0.026194598525762558,
                0.0993884727358818,
                0.048937831073999405,
                -0.011697620153427124,
                0.023219846189022064,
                0.008822138421237469,
                0.013598878867924213,
                0.037049271166324615,
                -0.03427637368440628,
                -0.022945787757635117,
                -0.02467072382569313,
                -0.013017473742365837,
                -0.0461115762591362,
                -0.0036333613097667694,
                -0.02814449742436409,
                -0.06861311197280884,
                0.05961882323026657,
                0.0037864178884774446,
                -0.0008604055619798601,
                -0.01134265586733818,
                -0.02273588813841343,
                -0.007622679229825735,
                0.04249786213040352,
                -0.06081342324614525,
                0.012797139585018158,
                0.06588302552700043,
                0.03120337799191475,
                0.030097434297204018,
                -0.025245528668165207,
                -0.006126206833869219,
                0.00990409404039383,
                0.03909221664071083,
                -0.016276340931653976,
                -0.014813091605901718,
                0.00469593470916152,
                -0.033742085099220276,
                0.08856281638145447,
                0.033014651387929916,
                -0.023942163214087486,
                0.023683274164795876,
                -0.0020082457922399044,
                -0.05149219557642937,
                -0.002832892583683133,
                0.010880164802074432,
                0.03254891186952591,
                -0.0135180139914155,
                0.0446181446313858,
                -0.014806834980845451,
                -0.037933915853500366,
                0.05573214590549469,
                -0.010790933854877949,
                0.09027023613452911,
                0.011492808349430561,
                0.01524414587765932,
                0.023051178082823753,
                -0.0044144755229353905,
                -0.004462163429707289,
                0.022999202832579613,
                0.002733225468546152,
                0.015249335207045078,
                0.056475598365068436,
                0.02712329663336277,
                -0.012383571825921535,
                0.04549558833241463,
                -0.005691695958375931,
                -0.00975359883159399,
                0.006171232555061579,
                -0.014003937132656574,
                -0.023111196234822273,
                0.006379037629812956,
                -0.029693588614463806,
                -0.017751986160874367,
                -0.011892594397068024,
                0.03223378211259842,
                -0.05298883467912674,
                -0.050526417791843414,
                0.03133717179298401,
                0.00503674428910017,
                -0.046283066272735596,
                -0.0014309395337477326,
                -0.0052437251433730125,
                -0.0019348346395418048,
                -0.003526357701048255,
                -0.017344126477837563,
                0.0331878662109375,
                0.012009038589894772,
                0.10367795825004578,
                -0.01765645295381546,
                0.005573098547756672,
                0.008049994707107544,
                0.012568219564855099,
                -0.015450786799192429,
                -0.04201545938849449,
                -0.03289033845067024,
                0.012365540489554405,
                -0.02216746099293232,
                0.03960089385509491,
                -0.0011648739455267787,
                -0.006687512621283531,
                0.007308010011911392,
                -0.06791143864393234,
                0.02178788371384144,
                -0.040213920176029205,
                -0.00620416272431612,
                0.040270306169986725,
                -0.055272769182920456,
                -0.023332348093390465,
                0.033819880336523056,
                0.01439796481281519,
                0.02161947265267372,
                -0.03443889692425728,
                0.010548544116318226,
                -0.019654473289847374,
                0.013200625777244568,
                -0.02718210406601429,
                0.03046434372663498,
                0.03496178984642029,
                0.0049697705544531345,
                -0.027990752831101418,
                -0.004069348331540823,
                0.04037247598171234,
                -0.04255261644721031,
                -0.019384996965527534,
                -0.01481554377824068,
                -0.010778844356536865,
                0.001079668290913105,
                0.0012212669244036078,
                0.02244461327791214,
                -0.023463567718863487,
                -0.08691338449716568,
                -0.001846712315455079,
                -0.028995271772146225,
                -0.02889273874461651,
                -0.009383679367601871,
                0.0423368476331234,
                -0.01176754105836153,
                0.014835978858172894,
                -0.03662984445691109,
                -0.02227873168885708,
                -0.053882915526628494,
                0.04361588880419731,
                0.006241181865334511,
                -0.08338786661624908,
                0.039410799741744995,
                0.008983869105577469,
                -0.012127327732741833,
                0.0026260933373123407,
                -0.02469775266945362,
                0.030247442424297333,
                -0.005171472206711769,
                0.005378968548029661,
                -0.010119598358869553,
                0.038695819675922394,
                -0.0734044536948204,
                0.017350830137729645,
                -0.011516095139086246,
                0.031692568212747574,
                0.023241030052304268,
                -0.0005034464411437511,
                0.024758124724030495,
                0.00659033190459013,
                -0.03101441264152527,
                0.050610095262527466,
                -0.03806041181087494,
                0.012625537812709808,
                0.009357987903058529,
                0.010978047735989094,
                0.0036254413425922394,
                -0.04503074288368225,
                -0.0449606329202652,
                -0.017314516007900238,
                0.026840021833777428,
                -0.028512343764305115,
                0.03827386349439621,
                0.0591072253882885,
                0.007475431077182293,
                -0.07470250874757767,
                0.003906836733222008,
                0.01950947567820549,
                0.016163259744644165,
                0.023989802226424217,
                -0.03022380918264389,
                0.03784937411546707,
                0.026746440678834915,
                -0.021247409284114838,
                -0.019827621057629585,
                0.032796673476696014,
                0.021660491824150085,
                -0.0610470287501812,
                0.024449914693832397,
                0.04494967684149742,
                -0.002659349236637354,
                -0.018731119111180305,
                0.03145137056708336,
                -0.013249626383185387,
                -0.04779348894953728,
                0.014245806261897087,
                0.0570584312081337,
                0.013986323960125446,
                0.009811199270188808,
                -0.04718068242073059,
                0.00034600787330418825,
                0.006817512679845095,
                0.026294857263565063,
                0.03385346755385399,
                0.016161959618330002,
                -0.0031225758139044046,
                -0.046168722212314606,
                -0.01410722453147173,
                0.021133355796337128,
                -0.016946488991379738,
                0.04002422094345093,
                -0.040738802403211594,
                0.03455526754260063,
                0.04985078424215317,
                0.07763851433992386,
                -0.017328720539808273,
                -0.008782952092587948,
                -0.0203765407204628,
                -0.08363495022058487,
                -0.0058503709733486176,
                -0.043251652270555496,
                -0.07379942387342453,
                -0.048408299684524536,
                -0.007661481853574514,
                -0.05358736217021942,
                0.01547158882021904,
                0.059123992919921875,
                0.07730835676193237,
                0.0181454885751009,
                0.053825508803129196,
                0.012017163448035717,
                0.0010956376791000366,
                0.010038426145911217,
                -0.009479125961661339,
                -0.021322274580597878,
                0.00034740465343929827,
                0.01372477225959301,
                0.04363088309764862,
                -0.07418732345104218,
                0.011670611798763275,
                0.01697556860744953,
                0.02753153070807457,
                -0.0426887609064579,
                -0.0028194973710924387,
                -0.020169885829091072,
                0.0560184083878994,
                0.014630191959440708,
                -0.02000710740685463,
                -0.02571687288582325,
                0.011563332751393318,
                0.03531207889318466,
                0.02571549266576767,
                -0.007704948075115681,
                -0.02361844852566719,
                -0.039998605847358704,
                0.07921276241540909,
                0.06892271339893341,
                -0.022952120751142502,
                0.0016408143565058708,
                0.04832770302891731,
                -0.0072427294217050076,
                -0.020807798951864243,
                0.0005324629601091146,
                0.0651264563202858,
                0.003979249391704798,
                0.019902685657143593,
                0.0195147767663002,
                0.02106150984764099,
                -0.012551721185445786,
                -0.10280238091945648,
                0.018505774438381195,
                0.038119733333587646,
                0.10294811427593231,
                0.05584735795855522,
                0.0033363630063831806,
                -0.07164136320352554,
                0.018409188836812973,
                0.051460377871990204,
                0.043557725846767426,
                0.027615252882242203,
                0.01833619736135006,
                0.007379263173788786,
                0.051597267389297485,
                -0.025223923847079277,
                -0.004345637280493975,
                -0.028164315968751907,
                -0.06750542670488358,
                0.00951510202139616,
                0.022242363542318344,
                -0.056984830647706985,
                0.002351215109229088,
                -0.02617790549993515,
                0.03247532248497009,
                0.00565712945535779,
                -0.04797337204217911,
                -0.12082510441541672,
                0.012142087332904339,
                -0.020353348925709724,
                0.014839139766991138,
                -0.0407206155359745,
                -0.0359843447804451,
                -0.029308617115020752,
                -0.05150482803583145,
                -0.01824340783059597,
                0.03978097438812256,
                -0.014935934916138649,
                0.03410620987415314,
                -0.0018943360773846507,
                -0.0484829917550087,
                -0.04876696690917015,
                0.08183019608259201,
                0.029264532029628754,
                -0.049699634313583374,
                0.016444025561213493,
                -0.04245409741997719,
                0.07865428924560547,
                -0.0031731496565043926,
                -0.051523737609386444,
                0.011617752723395824,
                0.03463274985551834,
                0.0064923884347081184,
                -0.06322801858186722,
                -0.09267710149288177,
                -0.031614795327186584,
                0.004319347441196442,
                0.021492503583431244,
                0.03508107736706734,
                -0.017656350508332253,
                0.0022030251566320658,
                -0.023227041587233543,
                -0.006988009437918663,
                0.007374511566013098,
                -0.034562185406684875,
                -0.013620554469525814,
                -0.06375285238027573,
                -0.0009008027263917029,
                0.013478335924446583,
                -0.03159455209970474,
                0.025523219257593155,
                0.007302762009203434,
                0.04563860222697258,
                -0.01943632960319519,
                0.024404462426900864,
                -0.015745721757411957,
                0.02192435786128044,
                -0.03876068815588951,
                0.02319728396832943,
                -0.06133740395307541,
                0.02639048732817173,
                -0.0002178620343329385,
                -0.02766866236925125,
                0.06006826087832451,
                -0.013522767461836338,
                0.014762721955776215,
                0.010821315459907055,
                0.012332473881542683,
                -0.03864728659391403,
                -0.046731311827898026,
                0.02471347339451313,
                0.004001791123300791,
                -0.0009366489248350263,
                0.042622242122888565,
                -0.00335276429541409,
                0.005745996255427599,
                -0.004393172450363636,
                -0.052913274616003036,
                0.08878060430288315,
                -0.0030438757967203856,
                -0.010642118752002716,
                -0.03574657440185547,
                -0.03372643515467644,
                -0.02641160599887371,
                0.030516522005200386,
                -0.0025561144575476646,
                -0.08093269169330597,
                -0.028287414461374283,
                0.0019377486314624548,
                0.005292955320328474,
                0.003985801246017218,
                -0.06261780858039856,
                0.028267504647374153,
                0.04355265945196152,
                0.04631633311510086,
                -0.04411955922842026,
                0.07810679823160172,
                0.008349336683750153,
                -0.04215259104967117,
                0.05434044450521469,
                -0.0004959593643434346,
                -0.027846068143844604,
                0.008738657459616661,
                -0.0871482715010643,
                -0.0163054671138525,
                0.040788885205984116,
                0.010093335062265396,
                0.028599541634321213,
                -0.058928586542606354,
                0.0033809917513281107,
                -0.0007389126112684608,
                0.026388850063085556,
                0.026386423036456108,
                0.006101080682128668,
                -0.028482718393206596,
                0.0041475421749055386,
                0.04345041513442993,
                0.08260028809309006,
                -0.0386776328086853,
                -0.053413502871990204,
                -0.014182772487401962,
                -0.02323314920067787,
                0.054425712674856186,
                -0.006319468375295401,
                -0.1022111028432846,
                0.05576005578041077,
                -0.008683267049491405,
                -0.010500765405595303,
                -0.05615810304880142,
                -0.003244643099606037,
                -0.02330010198056698,
                -0.034628018736839294,
                0.020304372534155846,
                0.022410862147808075,
                0.01443176157772541,
                -0.0061506894417107105,
                0.019817907363176346,
                -0.07375107705593109,
                0.06327950209379196,
                -0.06178120896220207,
                0.0017580125713720918,
                -0.020187528803944588,
                -0.003595154732465744,
                0.013566979207098484,
                -0.02346864901483059,
                0.05501564219594002,
                0.015908805653452873,
                -0.06501760333776474,
                0.0016387981595471501,
                0.005387746728956699,
                -0.03525596484541893,
                0.03922527655959129,
                -0.010221559554338455,
                0.03227246552705765,
                0.002708524465560913,
                -0.044122595340013504,
                0.026521867141127586,
                0.015388818457722664,
                0.00683575589209795,
                0.024237165227532387,
                -0.01430168654769659,
                -0.08707501739263535,
                0.0644935593008995,
                -0.0050993566401302814,
                0.01171606220304966,
                0.0212863776832819,
                0.059624508023262024,
                0.005454892758280039,
                0.009246690198779106,
                0.06430556625127792,
                0.028559992089867592,
                0.0614737793803215,
                -0.028827868402004242,
                -0.012257893569767475,
                0.006719693075865507,
                -0.04519253596663475,
                -0.0441034734249115,
                -0.02732389234006405,
                -0.0027267499826848507,
                -0.05524202063679695,
                -0.004389194305986166,
                0.004940737970173359,
                0.027255147695541382,
                -0.005085614509880543,
                -0.006729366257786751,
                -0.03753672540187836,
                0.03317848965525627,
                -0.010137680917978287,
                -0.014561031945049763,
                0.05363982915878296,
                0.0023684129118919373,
                0.0021628111135214567,
                0.00832135509699583,
                0.004562271758913994,
                -0.0026623725425451994,
                -0.04678472876548767,
                -0.0047991108149290085,
                0.008666740730404854,
                0.06700246036052704,
                0.020781518891453743,
                0.011349605396389961,
                0.007626261096447706,
                0.08284734189510345,
                -0.015872005373239517,
                -0.024809086695313454,
                -0.0054046716541051865,
                0.014049784280359745,
                0.014837798662483692,
                -0.044589027762413025,
                -0.00957521703094244,
                0.01751658320426941,
                0.042714934796094894,
                -0.037427060306072235,
                0.012465433217585087,
                -0.017802787944674492,
                0.03382863476872444,
                -0.01868969202041626,
                -0.056301966309547424,
                0.0157152246683836,
                -0.03931764140725136,
                0.0010144703555852175,
                -0.037742067128419876,
                0.01445218175649643,
                0.027145005762577057,
                0.022389346733689308,
                -0.011665387079119682,
                0.018319247290492058,
                -5.551380437643719e-33,
                0.017317553982138634,
                -0.04840267449617386,
                0.021825704723596573,
                0.005704972892999649,
                -0.05378120020031929,
                -0.0064813257195055485,
                0.00724532175809145,
                -0.04114139452576637,
                -0.04971027374267578,
                -0.010152279399335384,
                -0.033380165696144104,
                0.015386493876576424,
                0.01291678100824356,
                -0.010497468523681164,
                0.005305050872266293,
                0.07118076086044312,
                0.0298838522285223,
                0.007277376484125853,
                0.0030707332771271467,
                -0.03588499128818512,
                -0.0024381994735449553,
                -0.008249022997915745,
                -0.02565857768058777,
                0.037099067121744156,
                0.012852458283305168,
                0.010932819917798042,
                -0.011216577142477036,
                -0.01709298975765705,
                -0.038406141102313995,
                -0.03452201932668686,
                0.02413269504904747,
                0.011091954074800014,
                0.000926345819607377,
                -0.026586180552840233,
                -0.04443429782986641,
                -0.012399531900882721,
                -0.03545667603611946,
                0.018389267846941948,
                -0.04445900768041611,
                0.043277353048324585,
                -0.015536238439381123,
                -0.016191473230719566,
                0.006593361962586641,
                -0.02236056700348854,
                -0.06558585911989212,
                -0.006677107885479927,
                -0.002834490267559886,
                0.021361254155635834,
                0.015621536411345005,
                -0.012759578414261341,
                -0.02356823906302452,
                -0.05049679055809975,
                -0.043374668806791306,
                0.057674601674079895,
                -0.060501400381326675,
                -0.038475893437862396,
                0.028538869693875313,
                0.06264461576938629,
                0.0170738622546196,
                0.0002168825303670019,
                0.05991683155298233,
                0.07494126260280609,
                0.06242785230278969,
                -0.020691843703389168,
                -0.046714283525943756,
                0.02553197368979454,
                0.024089206010103226,
                0.01715688407421112,
                0.021128114312887192,
                -0.054285213351249695,
                -0.031700849533081055,
                0.002035778481513262,
                0.0010556179331615567,
                0.015527819283306599,
                -0.01847536675632,
                -0.025602145120501518,
                -0.04279839247465134,
                -0.015166466124355793,
                0.06822188943624496,
                0.022162534296512604,
                -0.023622121661901474,
                0.024066371843218803,
                0.1276015341281891,
                0.008599850349128246,
                0.008685125969350338,
                0.01667696051299572,
                -0.010936268605291843,
                0.07224389910697937,
                0.019798442721366882,
                -0.041095178574323654,
                0.012990369461476803,
                0.03214336931705475,
                -0.009334331378340721,
                0.009699974209070206,
                0.06587186455726624,
                0.11582867056131363,
                0.004852120764553547,
                0.00033307503326795995,
                -0.01611306332051754,
                0.06918007880449295,
                -0.016728486865758896,
                -0.010932871140539646,
                -0.0522799976170063,
                0.05317487195134163,
                -0.011516218073666096,
                0.013783327303826809,
                -0.03456299751996994,
                -0.029109053313732147,
                0.02612299472093582,
                0.002911657327786088,
                0.01973673142492771,
                0.0019568726420402527,
                0.010659810155630112,
                0.028273968026041985,
                -0.012516003102064133,
                -0.0059930854476988316,
                0.011195207946002483,
                -0.015256528742611408,
                -0.024717897176742554,
                0.05301424488425255,
                0.07689894735813141,
                -0.016063714399933815,
                -0.021897023543715477,
                0.01594964787364006,
                0.01143630500882864,
                -0.04022722691297531,
                0.02382548525929451,
                -0.04435928165912628,
                -0.061212729662656784,
                -0.009350815787911415,
                0.002101687714457512,
                0.015660397708415985,
                1.8894209574682463e-07,
                -0.03590800613164902,
                0.034143801778554916,
                -0.009475437924265862,
                0.026473164558410645,
                -0.026844307780265808,
                -0.025901125743985176,
                -0.010358689352869987,
                -0.009921606630086899,
                -0.05446105822920799,
                0.002274756319820881,
                -0.051554471254348755,
                -0.047022849321365356,
                -0.004931502044200897,
                -0.06144816428422928,
                -0.026964634656906128,
                -0.08705182373523712,
                -0.0014800771605223417,
                0.06680183857679367,
                -0.02395237423479557,
                -0.05249784514307976,
                -0.009383643046021461,
                0.015121744945645332,
                0.012850580736994743,
                0.0032705222256481647,
                -0.0074978661723434925,
                0.056054145097732544,
                0.02088093012571335,
                0.04424413666129112,
                0.12040113657712936,
                0.016865193843841553,
                0.04862957075238228,
                -0.03727168217301369,
                0.06839389353990555,
                0.001498935860581696,
                0.027818944305181503,
                -0.02387140691280365,
                0.022552715614438057,
                0.023159177973866463,
                0.047309510409832,
                -0.045330531895160675,
                0.0024122949689626694,
                0.03952932730317116,
                0.016422830522060394,
                0.0030175212305039167,
                -0.043112386018037796,
                0.053880784660577774,
                0.0004883070942014456,
                0.06029336154460907,
                -0.06317053735256195,
                -0.006327266804873943,
                -0.0287570059299469,
                0.010288603603839874,
                0.007626445032656193,
                -0.02665606699883938,
                -0.008131091482937336,
                -0.023044029250741005,
                -0.04218776896595955,
                0.08192728459835052,
                0.0251388531178236,
                -0.06558303534984589,
                0.017203666269779205,
                0.018537340685725212,
                -0.05282856151461601,
                0.03713762387633324,
                0.10969908535480499,
                -0.04505325108766556,
                -0.0207861065864563,
                1.2856704287123613e-34,
                -0.006050898227840662,
                -0.0077931759878993034,
                -0.05654601752758026,
                0.01117128599435091,
                -0.035523802042007446,
                -0.03502235561609268,
                -0.013338477350771427,
                0.018935244530439377,
                -0.018262939527630806,
                0.014839968644082546,
                -0.008420328609645367
            ]
        },
        "Experience": {
            "name": "Development of renewable energy projects, implementation of biogas generation projects to obtain different types of biofuels and their commercialisation, obtaining carbon credits, sizing of a stack of electrochemical cells for energy generation and bioremediation of marine sediments. Design of an electrolyser for hydrogen generation, installation of cells for wastewater remediation in homes.",
            "rep": [
                0.06975606828927994,
                -0.00955782737582922,
                -0.009015899151563644,
                -0.017147362232208252,
                0.02943936176598072,
                -0.001314274501055479,
                0.04200488701462746,
                0.01605968177318573,
                0.01687343791127205,
                -0.0012528300285339355,
                0.021330758929252625,
                0.049144014716148376,
                0.019218187779188156,
                0.048823293298482895,
                0.027126172557473183,
                -0.0008997626719065011,
                0.029343141242861748,
                0.015983259305357933,
                -0.025519253686070442,
                -0.012813206762075424,
                -0.026785435155034065,
                -0.015542613342404366,
                0.020389629527926445,
                0.04232346639037132,
                0.01956425979733467,
                0.06139996275305748,
                -0.007455372717231512,
                0.020057521760463715,
                0.03214215859770775,
                -0.08093658834695816,
                0.08610552549362183,
                -0.017320971935987473,
                0.02011781558394432,
                -0.044714074581861496,
                1.8803872308126302e-06,
                -0.033566664904356,
                -0.03362511098384857,
                -0.04395425319671631,
                0.02168750949203968,
                -0.03638216480612755,
                0.06424295157194138,
                0.020872507244348526,
                -0.011989404447376728,
                -0.006353867240250111,
                0.04490761458873749,
                -0.0021098291035741568,
                0.018341684713959694,
                0.024533182382583618,
                -0.011661850847303867,
                -0.0027975921984761953,
                -0.033241987228393555,
                -0.07563585788011551,
                -0.006732993759214878,
                -0.019763188436627388,
                -0.01588449254631996,
                0.08516784757375717,
                0.01938723586499691,
                -0.0013501654611900449,
                0.01558677013963461,
                -0.013621323741972446,
                -0.007995015941560268,
                0.04218917340040207,
                -0.02271943911910057,
                0.03746412694454193,
                0.045278169214725494,
                -0.019236188381910324,
                -0.007862179540097713,
                -0.012313065119087696,
                0.03534872829914093,
                0.03235626965761185,
                0.04754441976547241,
                -0.046063147485256195,
                0.024034004658460617,
                -0.00644401041790843,
                -0.013741756789386272,
                -0.009904881939291954,
                -0.006571562960743904,
                -0.012364775873720646,
                -0.032558590173721313,
                0.023830126971006393,
                -2.8515220037661493e-05,
                -0.02278459444642067,
                0.011881808750331402,
                0.003921177238225937,
                0.03699822723865509,
                0.05914432555437088,
                -0.02710396610200405,
                -0.009535825811326504,
                0.007349599618464708,
                0.004342901520431042,
                0.0310216024518013,
                0.026090361177921295,
                0.05269043147563934,
                0.007400811649858952,
                0.01888568513095379,
                0.009900541976094246,
                0.02186550199985504,
                -0.0470074899494648,
                0.018539665266871452,
                -0.022535918280482292,
                0.028762206435203552,
                -0.017144761979579926,
                0.07943351566791534,
                0.009787843562662601,
                -0.037380579859018326,
                -0.03485981747508049,
                -0.04128571227192879,
                -0.027030086144804955,
                -0.014114624820649624,
                -0.030297202989459038,
                -0.003666324308142066,
                -0.03206851705908775,
                -0.0794898122549057,
                0.0438765250146389,
                -0.008999884128570557,
                0.05204521492123604,
                -0.005798118654638529,
                -0.0333479568362236,
                -0.01325517613440752,
                0.009807160124182701,
                0.028504271060228348,
                0.013893981464207172,
                0.00859483890235424,
                -0.003955869935452938,
                0.026449384167790413,
                0.025937356054782867,
                -0.016160573810338974,
                0.025105226784944534,
                -0.052234213799238205,
                -0.004490156192332506,
                4.688194167101756e-05,
                -0.03396421670913696,
                -0.027366140857338905,
                -0.001425833790563047,
                -0.04982411861419678,
                0.04589852690696716,
                0.04723571613430977,
                -0.030902981758117676,
                -0.0497838594019413,
                -0.0010973355965688825,
                0.04242094233632088,
                0.020292021334171295,
                -0.06635409593582153,
                0.06122979149222374,
                -0.053453315049409866,
                -0.012575718574225903,
                0.0027739766519516706,
                0.03777085244655609,
                -0.004255024716258049,
                -0.009618647396564484,
                0.028644943609833717,
                -0.05146046727895737,
                -0.005153870675712824,
                -0.005555413197726011,
                0.06928940862417221,
                0.022544439882040024,
                -0.02975752018392086,
                -0.053769614547491074,
                0.02853279560804367,
                0.004311597906053066,
                -0.0574498176574707,
                -0.03265964984893799,
                0.012635472230613232,
                -0.0065267919562757015,
                0.041404638439416885,
                -0.07950827479362488,
                0.016926657408475876,
                -0.0018148673698306084,
                -0.10120975226163864,
                0.05096719413995743,
                0.014104921370744705,
                -0.053919896483421326,
                -0.03015737235546112,
                0.09734420478343964,
                -0.011137121357023716,
                -0.013022422790527344,
                -0.0064561935141682625,
                0.006844034418463707,
                -0.04996358975768089,
                -0.024520475417375565,
                0.03940650820732117,
                -0.0525379404425621,
                0.002266376279294491,
                0.04437685385346413,
                0.003781468141824007,
                -0.03694896027445793,
                -0.010981005616486073,
                0.02011769264936447,
                0.0013366532512009144,
                0.011754216626286507,
                0.028888924047350883,
                0.026802023872733116,
                -0.008309382013976574,
                -0.008956515230238438,
                0.0042946175672113895,
                0.001083892653696239,
                -0.020005617290735245,
                0.04402114450931549,
                -0.023408962413668633,
                -0.046223387122154236,
                0.04270748421549797,
                -0.023390527814626694,
                0.02894509583711624,
                0.020777426660060883,
                0.03298553451895714,
                -0.014178158715367317,
                -0.029693948104977608,
                -0.01025089155882597,
                -0.052403371781110764,
                -0.04964390769600868,
                -0.0015731895109638572,
                -0.03189694136381149,
                -0.009309019893407822,
                0.03951644152402878,
                -0.004871012642979622,
                -0.03383759781718254,
                -0.019232427701354027,
                0.001244564657099545,
                -0.025751162320375443,
                0.03823056071996689,
                0.008636715821921825,
                0.018020546063780785,
                -0.031311772763729095,
                -0.0031948378309607506,
                0.08073901385068893,
                0.04871116578578949,
                -0.03402816131711006,
                -0.022453680634498596,
                0.054622311145067215,
                0.04411022365093231,
                0.01835617981851101,
                0.00454590143635869,
                0.012411042116582394,
                -0.03894200921058655,
                -0.013488328084349632,
                -0.03739389404654503,
                0.019906241446733475,
                0.0185225997120142,
                0.022348307073116302,
                -0.015486749820411205,
                -0.02590240351855755,
                0.013021166436374187,
                0.05629134178161621,
                0.009630411863327026,
                -0.00837565679103136,
                0.029583819210529327,
                0.012017052620649338,
                0.0007453481666743755,
                0.040169790387153625,
                -0.005288236308842897,
                0.01900690793991089,
                -0.011730007827281952,
                0.01610482670366764,
                0.057880647480487823,
                0.06429199874401093,
                0.012869905680418015,
                0.010031012818217278,
                -0.002887713722884655,
                -0.07005045562982559,
                -0.013362935744225979,
                0.010722908191382885,
                -0.03756966069340706,
                -0.06287968903779984,
                0.024273639544844627,
                -0.020869571715593338,
                -0.0321967788040638,
                0.08827310800552368,
                -0.06941628456115723,
                -0.020471680909395218,
                0.011298036202788353,
                -0.0050604380667209625,
                0.0327540822327137,
                0.018847273662686348,
                0.02977505885064602,
                -0.03583969175815582,
                -0.0016262070275843143,
                0.09848712384700775,
                -0.003086942248046398,
                -0.021425550803542137,
                0.01757616363465786,
                0.045528460294008255,
                0.011259694583714008,
                -0.03554779663681984,
                0.013397740200161934,
                -0.037597961723804474,
                0.09530209004878998,
                0.0542314387857914,
                0.0014838112983852625,
                -0.04421987757086754,
                0.05222361162304878,
                0.07090766727924347,
                0.01734575815498829,
                0.010267503559589386,
                -0.009839548729360104,
                -0.06003445386886597,
                0.03443519398570061,
                0.03470904007554054,
                -0.01384029071778059,
                0.06880615651607513,
                -0.010853758081793785,
                -0.02463417686522007,
                0.004955594427883625,
                -0.0453537218272686,
                0.03690959885716438,
                0.04306731000542641,
                0.042757101356983185,
                -0.014198788441717625,
                -0.0085822818800807,
                0.02794184535741806,
                -0.039758678525686264,
                -0.030711276456713676,
                0.03424099460244179,
                0.007982064969837666,
                0.01055426336824894,
                0.022831862792372704,
                -0.0420369990170002,
                0.028561126440763474,
                -0.004888323135674,
                0.028641074895858765,
                -0.004180443473160267,
                -0.01318067405372858,
                -0.004822845570743084,
                0.0263905581086874,
                0.040837809443473816,
                -0.026273757219314575,
                -0.06194173917174339,
                -0.008877563290297985,
                0.0013174843043088913,
                0.04052332788705826,
                -0.011686624027788639,
                0.024715205654501915,
                -0.03047422505915165,
                -0.01695222407579422,
                0.003013627603650093,
                -0.021710647270083427,
                -0.0411364883184433,
                0.024072468280792236,
                -0.018362734466791153,
                0.02305673249065876,
                -0.02024039439857006,
                -0.045276492834091187,
                -0.013513131998479366,
                -0.04167813062667847,
                -0.05764304846525192,
                0.04948808252811432,
                0.02008875459432602,
                0.023440875113010406,
                0.04343470185995102,
                0.003675548592582345,
                -0.007245067972689867,
                0.08770915120840073,
                -0.006287731695920229,
                -0.049934711307287216,
                0.011055606417357922,
                0.009769566357135773,
                0.049329355359077454,
                -0.0416334867477417,
                -0.025228284299373627,
                -0.05805373564362526,
                0.10274918377399445,
                0.025992587208747864,
                -0.028223702684044838,
                -0.06471961736679077,
                -0.028441602364182472,
                0.012688030488789082,
                0.038748230785131454,
                -0.019572963938117027,
                -0.017906375229358673,
                -0.03087490051984787,
                -0.017925042659044266,
                0.01042198110371828,
                -0.006743944250047207,
                0.0010756615083664656,
                -0.0051198117434978485,
                -0.027873942628502846,
                -0.0012184013612568378,
                0.0015298373764380813,
                -0.004049613606184721,
                -0.05741721764206886,
                0.0008170869550667703,
                0.013402518816292286,
                0.0012987303780391812,
                0.05523362383246422,
                0.017929859459400177,
                -0.01386991050094366,
                -0.03862867131829262,
                0.009419746696949005,
                -0.06485867500305176,
                0.031601905822753906,
                -0.034032534807920456,
                -0.00718516344204545,
                -0.030818264931440353,
                -0.05930573120713234,
                -0.010865649208426476,
                0.052322663366794586,
                -0.023227408528327942,
                -0.09162560105323792,
                0.029089460149407387,
                0.001971326768398285,
                0.023441152647137642,
                0.0062213484197855,
                -0.0431620329618454,
                -0.03517360985279083,
                -0.022787602618336678,
                -0.04661230370402336,
                -0.050075627863407135,
                0.06628943979740143,
                0.01346137560904026,
                0.05225140601396561,
                0.05551670119166374,
                -0.010149478912353516,
                -0.034981321543455124,
                -0.0009842730360105634,
                -0.07197732478380203,
                -0.033013634383678436,
                0.02104443870484829,
                -0.022093163803219795,
                0.0005431283498182893,
                0.02147522196173668,
                0.015286839567124844,
                -0.014647495932877064,
                0.061856385320425034,
                0.022326134145259857,
                -0.0019247998716309667,
                0.02593851648271084,
                -0.016457250341773033,
                -0.03949940949678421,
                0.06032060086727142,
                -0.019602004438638687,
                -0.013572322204709053,
                -0.04576336219906807,
                -0.027612224221229553,
                0.005904715042561293,
                0.004756711423397064,
                -0.09588366746902466,
                0.0744219571352005,
                -0.05591786280274391,
                -0.011298269964754581,
                -0.007101635914295912,
                0.002995457034558058,
                -0.008591556921601295,
                -0.0026250409428030252,
                0.003593472531065345,
                0.018260762095451355,
                0.010185138322412968,
                0.10237863659858704,
                -0.013198748230934143,
                -0.00565749267116189,
                -0.02945932000875473,
                0.0147505272179842,
                0.04268825799226761,
                -0.03153785690665245,
                -0.11625538766384125,
                0.052495840936899185,
                0.023197071626782417,
                0.009730299934744835,
                0.00748096127063036,
                -0.015062258578836918,
                -0.03942457213997841,
                0.0008786627440713346,
                -0.004960504826158285,
                0.006708112079650164,
                -0.043887026607990265,
                0.006954561453312635,
                0.010652454569935799,
                -0.01202522125095129,
                -0.010965227149426937,
                0.0005226808716543019,
                -0.004621610045433044,
                0.019580502063035965,
                0.11720643192529678,
                -0.03035544976592064,
                0.028799619525671005,
                0.05573681369423866,
                0.020263953134417534,
                -0.07337617874145508,
                0.05780760943889618,
                -0.06468785554170609,
                -0.03756989166140556,
                0.02965337596833706,
                0.045652080327272415,
                0.0454384982585907,
                -0.010725757107138634,
                0.004075090866535902,
                -0.0064662424847483635,
                -0.013936722651124,
                0.08114298433065414,
                0.007793523371219635,
                0.016068410128355026,
                -0.005643363110721111,
                0.010414672084152699,
                -0.04457763209939003,
                0.016439305618405342,
                0.040829818695783615,
                0.03392651677131653,
                -0.037403106689453125,
                0.019773155450820923,
                0.08663837611675262,
                0.014104845002293587,
                -0.01457882858812809,
                0.03660590946674347,
                0.02562331035733223,
                -0.01949865184724331,
                -0.04768037423491478,
                0.008277586661279202,
                -0.047137919813394547,
                0.018044887110590935,
                -0.07219558209180832,
                -0.005743186920881271,
                0.00029253389220684767,
                -0.00356130744330585,
                0.032008860260248184,
                0.03330595791339874,
                0.05792517215013504,
                -0.02116457000374794,
                0.05833450332283974,
                -0.0039026832673698664,
                0.06958940625190735,
                -0.03739628568291664,
                0.01903311163187027,
                -0.027134783565998077,
                0.0029378323815762997,
                -0.02133806236088276,
                -0.05675920844078064,
                -0.029309915378689766,
                0.011928115040063858,
                0.05772140249609947,
                0.01865100860595703,
                -0.04110841080546379,
                0.030246904119849205,
                0.04092425853013992,
                -0.02433808147907257,
                -0.004362696316093206,
                0.013611786998808384,
                0.026024900376796722,
                0.025940576568245888,
                0.05030456930398941,
                -0.013253363780677319,
                -0.02089267410337925,
                0.00929204374551773,
                -0.005166974849998951,
                -0.012818294577300549,
                0.012912271544337273,
                0.007319512311369181,
                -0.011616724543273449,
                -0.02081100083887577,
                -0.00021148733503650874,
                -0.08167091012001038,
                -0.011013446375727654,
                -0.02134706638753414,
                0.002160256262868643,
                -0.0056460886262357235,
                -0.01834258623421192,
                -0.03903684392571449,
                -0.001405636896379292,
                -6.281943931866032e-33,
                0.021391361951828003,
                -0.02403304912149906,
                0.02741040661931038,
                0.04920509085059166,
                -0.08464334905147552,
                -0.02754262275993824,
                -0.04369285702705383,
                0.01720580831170082,
                -0.027858484536409378,
                0.004692662972956896,
                -0.014508450403809547,
                -0.0003819223493337631,
                -0.005715266801416874,
                -0.03435804694890976,
                0.028558028861880302,
                0.04747587814927101,
                0.010656553320586681,
                -0.021506167948246002,
                -0.02157682739198208,
                -0.005599562078714371,
                0.016055207699537277,
                0.03294704854488373,
                0.04675733670592308,
                0.03015979193150997,
                0.02247372269630432,
                0.027522306889295578,
                -0.04234791919589043,
                -0.026467768475413322,
                -0.0423821322619915,
                -0.01604011096060276,
                0.026680508628487587,
                0.04402601346373558,
                0.03799549490213394,
                -0.06501113623380661,
                -0.01956850104033947,
                0.016832612454891205,
                -0.046511948108673096,
                -0.00952961202710867,
                0.011071691289544106,
                0.07025857269763947,
                -0.04339771717786789,
                -0.04443788528442383,
                -0.006081190425902605,
                -0.027465587481856346,
                -0.06846770644187927,
                0.020865177735686302,
                -0.03842839226126671,
                -0.01150723546743393,
                0.07566745579242706,
                0.04129597172141075,
                0.029816264286637306,
                -0.04908035695552826,
                0.012370865792036057,
                0.04423501342535019,
                0.030496055260300636,
                -0.014311146922409534,
                0.018284713849425316,
                0.018400682136416435,
                0.05012860521674156,
                0.026765931397676468,
                0.016697432845830917,
                0.013244899921119213,
                0.025542931631207466,
                -5.334005254553631e-05,
                -0.04872395098209381,
                -0.005974495317786932,
                -0.0619538240134716,
                0.003365847747772932,
                -0.037524573504924774,
                0.018118808045983315,
                -0.01270805299282074,
                0.011523706838488579,
                -0.03283719718456268,
                -0.006009729579091072,
                -0.011809932999312878,
                0.004023413639515638,
                -0.02555191144347191,
                0.0021359010133892298,
                0.011360962875187397,
                0.0305580236017704,
                0.02396313101053238,
                -0.02630128338932991,
                0.07762289047241211,
                -0.024712564423680305,
                0.0016813321271911263,
                0.05130990967154503,
                -0.014253681525588036,
                0.031011225655674934,
                -0.036067597568035126,
                -0.02590990997850895,
                -0.014182879589498043,
                0.02121792547404766,
                -0.01973692700266838,
                -0.01986483857035637,
                0.034914106130599976,
                0.12288223952054977,
                -0.009581578895449638,
                -0.040978435426950455,
                0.04614933207631111,
                0.059729356318712234,
                -0.01937430165708065,
                -0.012577845714986324,
                -0.018791990354657173,
                0.07316913455724716,
                0.007264093030244112,
                -0.02488536201417446,
                -0.07711779326200485,
                -0.03581837937235832,
                0.022407399490475655,
                2.4203656721510924e-05,
                0.004600383806973696,
                -0.03806021437048912,
                0.006404992658644915,
                0.028940916061401367,
                -0.049041152000427246,
                -0.006095827091485262,
                -0.017030838876962662,
                -0.006620587781071663,
                -0.07641829550266266,
                -0.021842749789357185,
                0.043326251208782196,
                -0.010953647084534168,
                0.007489562034606934,
                0.04269075393676758,
                0.01802561618387699,
                -0.004688349086791277,
                0.008684881031513214,
                0.04513886570930481,
                -0.06062939018011093,
                0.06061592325568199,
                -0.018703000620007515,
                0.044110700488090515,
                2.631354902860039e-07,
                -0.08981765806674957,
                0.0015713326865807176,
                -0.021342894062399864,
                0.010759418830275536,
                0.012851468287408352,
                -0.024016497656702995,
                0.023625573143363,
                0.022164588794112206,
                -0.10903474688529968,
                -0.026765460148453712,
                -0.004457778763025999,
                -0.06351931393146515,
                -0.006658415775746107,
                -0.013208998367190361,
                -0.05021163076162338,
                -0.12251835316419601,
                -0.050754841417074203,
                0.02249043434858322,
                0.027312513440847397,
                -0.0006383633590303361,
                0.0657511055469513,
                -0.04482779651880264,
                -0.015448320657014847,
                -0.021217163652181625,
                0.00034850419615395367,
                0.015578076243400574,
                0.023563914000988007,
                -0.004102764651179314,
                0.0701444149017334,
                0.05872522294521332,
                -0.021115213632583618,
                0.02919827587902546,
                0.004840298090130091,
                0.015929022803902626,
                -0.005601434502750635,
                -0.03723550960421562,
                0.031190352514386177,
                -0.018878625705838203,
                0.0158244501799345,
                0.025327272713184357,
                -0.02128049172461033,
                0.016397196799516678,
                -0.005661081522703171,
                -0.007660286966711283,
                -0.04995808005332947,
                0.026469137519598007,
                0.008346927352249622,
                0.01850978657603264,
                0.009291455149650574,
                -0.03880436345934868,
                -0.04914725944399834,
                0.0012518978910520673,
                0.014278636313974857,
                -0.09332102537155151,
                -0.03150876611471176,
                -0.041090257465839386,
                -0.009525688365101814,
                0.026639362797141075,
                0.013254538178443909,
                0.032737333327531815,
                -0.013008772395551205,
                -0.019696710631251335,
                -0.06799216568470001,
                0.058433327823877335,
                0.0482960045337677,
                0.021717628464102745,
                0.05167229846119881,
                1.5380992309736828e-34,
                -0.0008479143143631518,
                -0.06296917051076889,
                -0.05327025055885315,
                -0.019133875146508217,
                -0.051702115684747696,
                -0.0012227344559505582,
                -0.11022591590881348,
                -0.00925368256866932,
                -0.023392414674162865,
                -0.04628820717334747,
                -0.018176741898059845
            ]
        },
        "Freelance": "Open to both",
        "Id": "5fd7ca5a-42e0-4f26-94df-efca9f43006a",
        "Industry": {
            "name": "\tBioenergy",
            "rep": [
                0.07769370079040527,
                -0.005833006463944912,
                -0.01947455294430256,
                0.01913745515048504,
                -0.0045487890020012856,
                0.015439243987202644,
                -0.04848156496882439,
                0.05133933201432228,
                -0.03718943148851395,
                -0.020587919279932976,
                0.012728930450975895,
                0.027028651908040047,
                -0.0034611027222126722,
                0.031154338270425797,
                0.04978047311306,
                0.013662262819707394,
                0.08140690624713898,
                0.033506494015455246,
                -0.028240883722901344,
                -0.02238653600215912,
                -0.06964445114135742,
                -0.006685284432023764,
                0.0010355490958318114,
                0.04251566529273987,
                0.02488165907561779,
                0.040067318826913834,
                -0.01799609512090683,
                0.09161915630102158,
                0.026532072573900223,
                -0.01134824100881815,
                0.02127683535218239,
                -0.021407829597592354,
                -0.002684325445443392,
                0.00869807694107294,
                1.5375848079202115e-06,
                -0.06089668348431587,
                -0.04463314637541771,
                -0.021655479446053505,
                0.007336725015193224,
                -0.019927019253373146,
                0.04831172898411751,
                0.034228358417749405,
                -0.0020625467877835035,
                0.002090683439746499,
                0.05299313738942146,
                -0.05116841197013855,
                0.04753710329532623,
                -0.043239936232566833,
                -0.009483976289629936,
                -0.04148607328534126,
                -0.03434760123491287,
                -0.0033769700676202774,
                -0.016328485682606697,
                -0.025654282420873642,
                -0.04874476045370102,
                0.13100239634513855,
                -0.00514557771384716,
                -0.0376138836145401,
                -0.0032449616119265556,
                0.03564808517694473,
                -0.016830720007419586,
                0.06566894799470901,
                -0.03044041059911251,
                0.017063304781913757,
                0.07950837165117264,
                -0.01751537248492241,
                0.06326387077569962,
                -0.028281664475798607,
                0.041217781603336334,
                0.017141619697213173,
                0.014585047028958797,
                0.0051641506142914295,
                0.02582569234073162,
                0.044499967247247696,
                -0.049750424921512604,
                0.030383842065930367,
                0.058106061071157455,
                0.01475544273853302,
                -0.017628243193030357,
                0.0355837419629097,
                0.0015702785458415747,
                -0.029105110093951225,
                0.01595817692577839,
                -0.0025663282722234726,
                -0.009008453227579594,
                0.039504535496234894,
                -0.018601926043629646,
                -0.009344013407826424,
                0.04310806095600128,
                0.02630685456097126,
                0.05357867479324341,
                0.010644734837114811,
                0.008856864646077156,
                0.014530546963214874,
                0.07876280695199966,
                0.015942832455039024,
                -0.03868340700864792,
                0.09380640834569931,
                0.0008291350677609444,
                -0.015137210488319397,
                0.010731657035648823,
                -0.013618195429444313,
                0.04818231612443924,
                0.013922009617090225,
                -0.01977931708097458,
                -0.07702916115522385,
                -0.04054061695933342,
                -0.03187471628189087,
                -0.014399915933609009,
                -0.04717715457081795,
                0.012753316201269627,
                -0.013910778798162937,
                0.016896726563572884,
                0.006832195445895195,
                -0.0031564240343868732,
                -0.012732420116662979,
                -0.005643132142722607,
                -0.05255484953522682,
                -0.03998179733753204,
                -0.011227678507566452,
                0.01128085795789957,
                -0.0019573059398680925,
                0.03902939707040787,
                -5.530627822736278e-05,
                0.016656704246997833,
                0.05514170229434967,
                0.05911595746874809,
                0.027845323085784912,
                0.012282361276447773,
                -0.00695137120783329,
                -0.017577217891812325,
                -0.026996254920959473,
                -0.02245244011282921,
                0.0018740928499028087,
                -0.07004115730524063,
                0.05290386453270912,
                0.009772643446922302,
                -0.02403217926621437,
                -0.010829712264239788,
                -0.06015980243682861,
                0.0043336497619748116,
                -0.006039682310074568,
                -0.05556667968630791,
                0.022454552352428436,
                -0.004946937318891287,
                0.007883964106440544,
                0.039817970246076584,
                0.011738952249288559,
                0.030646145343780518,
                0.00043363607255741954,
                0.037956539541482925,
                0.0015394212678074837,
                -0.05766843259334564,
                0.014932656660676003,
                0.038172364234924316,
                0.01400343980640173,
                -0.04836254566907883,
                -0.03595392033457756,
                0.045366570353507996,
                0.0059347315691411495,
                -0.05416341871023178,
                0.016924792900681496,
                -0.03175705298781395,
                -0.016456445679068565,
                0.02911885641515255,
                -0.06023884937167168,
                -0.008503403514623642,
                -0.03783734142780304,
                -0.12460191547870636,
                -0.002340675098821521,
                0.03658510372042656,
                -0.06697295606136322,
                -0.04154273122549057,
                0.07161188125610352,
                0.007041345816105604,
                0.04506832733750343,
                0.040407050400972366,
                -0.02166597917675972,
                0.023374594748020172,
                -0.02258004993200302,
                0.011356103233993053,
                -0.07561272382736206,
                0.024082666262984276,
                -0.019536346197128296,
                -0.03744443878531456,
                -0.03163335099816322,
                0.050437428057193756,
                0.06156396493315697,
                0.03655551001429558,
                -0.034061044454574585,
                -0.004823030438274145,
                0.017098579555749893,
                -0.0005987699260003865,
                -0.005984135437756777,
                0.01771811582148075,
                0.04090520739555359,
                0.005830228328704834,
                0.04753405228257179,
                0.02659468911588192,
                -0.023265007883310318,
                0.019697412848472595,
                -0.0027878570836037397,
                -0.01602776348590851,
                -0.006665061693638563,
                -0.014461761340498924,
                0.017532004043459892,
                -0.016791386529803276,
                -0.0030751482117921114,
                -0.08955687284469604,
                -0.030050143599510193,
                -0.013572483323514462,
                -0.012628640979528427,
                -0.01763373240828514,
                0.043742477893829346,
                0.006689457688480616,
                -0.016887938603758812,
                -0.012522051110863686,
                -0.009399570524692535,
                -0.0898999571800232,
                0.0318705253303051,
                0.00029263622127473354,
                0.015905065461993217,
                0.019502010196447372,
                -0.009124604985117912,
                0.009242100641131401,
                0.0484689436852932,
                -0.05914345011115074,
                -0.012369616888463497,
                0.04590630158782005,
                0.006699858233332634,
                0.014293249696493149,
                0.0013552872696891427,
                0.06011122465133667,
                0.0074019418098032475,
                -0.0334511399269104,
                0.03297780454158783,
                0.053606048226356506,
                -0.001615356421098113,
                0.006846006028354168,
                0.023095199838280678,
                -0.03258242830634117,
                0.05139971897006035,
                0.061979036778211594,
                0.02085976116359234,
                0.037851978093385696,
                -2.213187144661788e-05,
                0.03687278553843498,
                0.005909954663366079,
                0.044575128704309464,
                0.021897345781326294,
                -0.0018799907993525267,
                0.010550325736403465,
                0.021379277110099792,
                0.047089386731386185,
                0.06397800147533417,
                -0.057579852640628815,
                0.00952207576483488,
                0.03376948833465576,
                -0.026998037472367287,
                0.023148512467741966,
                0.06551330536603928,
                -0.06952476501464844,
                -0.0489029735326767,
                0.02252940647304058,
                -0.004206588491797447,
                -0.03214092552661896,
                0.1049807220697403,
                -0.03526860475540161,
                -0.011357957497239113,
                -0.014195509254932404,
                0.010462685488164425,
                -0.00888054445385933,
                0.020822525024414062,
                0.02537834271788597,
                -0.002496645785868168,
                0.007408022414892912,
                0.04006671905517578,
                0.016252389177680016,
                -0.05577724799513817,
                -0.0023403975646942854,
                0.010694053955376148,
                0.026945561170578003,
                -0.01403158251196146,
                0.01039139460772276,
                -0.04473794996738434,
                0.09322294592857361,
                0.010355946607887745,
                -0.01117311604321003,
                -0.05787530168890953,
                0.009537608362734318,
                0.029031189158558846,
                -0.01880517601966858,
                -0.022024763748049736,
                -0.020509744063019753,
                -0.028751859441399574,
                0.003563511185348034,
                -0.015366989187896252,
                -0.024555208161473274,
                0.03575458750128746,
                -8.716567390365526e-05,
                -0.06576184928417206,
                0.05066289007663727,
                -0.047915246337652206,
                0.006820580922067165,
                0.01896158792078495,
                0.04890276491641998,
                0.008323414251208305,
                -0.057588960975408554,
                0.03772023320198059,
                -0.02950998581945896,
                0.021192850545048714,
                0.06090490520000458,
                0.039101749658584595,
                0.08194531500339508,
                0.012990353628993034,
                -0.0539543516933918,
                0.008180194534361362,
                -0.015444534830749035,
                0.03473031148314476,
                0.03909827396273613,
                0.006235014647245407,
                -0.018847433850169182,
                0.03096625953912735,
                0.006653914228081703,
                0.006519581191241741,
                -0.015995508059859276,
                -0.04780945926904678,
                0.06551871448755264,
                0.002322838641703129,
                -0.009697690606117249,
                0.013824456371366978,
                0.009402007795870304,
                -0.004721926990896463,
                0.007086333818733692,
                -0.030218807980418205,
                -0.018610075116157532,
                0.03329897299408913,
                -0.057255085557699203,
                -0.010545577853918076,
                0.014981837943196297,
                0.008987796492874622,
                -0.028315510600805283,
                -0.0556100457906723,
                -0.00898350216448307,
                0.0014228041982278228,
                0.020228853449225426,
                -0.00038289622170850635,
                -0.008412213064730167,
                -0.048079073429107666,
                -0.024836454540491104,
                0.08007044345140457,
                0.0030154462438076735,
                -0.020198479294776917,
                -0.03640724718570709,
                -0.003784773638471961,
                0.09388966113328934,
                -0.048181720077991486,
                -0.037024401128292084,
                -0.0024826000444591045,
                0.054888732731342316,
                -0.013866299763321877,
                0.017104703933000565,
                -0.06292162835597992,
                -0.03887171670794487,
                0.01196576002985239,
                0.054114095866680145,
                0.0074407923966646194,
                0.035422664135694504,
                0.028539272025227547,
                -0.04632601514458656,
                0.046682488173246384,
                0.01371600478887558,
                -0.05517718568444252,
                0.02196544036269188,
                -0.030467767268419266,
                0.04690352454781532,
                0.012439625337719917,
                0.016115494072437286,
                0.0009424760937690735,
                -0.019540198147296906,
                0.03648639842867851,
                -0.014956001192331314,
                -0.0365801677107811,
                0.017721425741910934,
                -0.0044959532096982,
                0.029935425147414207,
                0.021467993035912514,
                -0.03129544109106064,
                0.019467812031507492,
                0.0006497706635855138,
                -0.021132202818989754,
                0.027404818683862686,
                -0.06282279640436172,
                -0.0013861183542758226,
                0.017331337556242943,
                -0.03439303860068321,
                -0.03794718161225319,
                0.021274756640195847,
                -0.03805985301733017,
                0.0180681012570858,
                -0.004678746685385704,
                0.06111002713441849,
                0.012248153798282146,
                0.004953976254910231,
                -0.050508759915828705,
                -0.007437019143253565,
                0.10018204152584076,
                -0.023089533671736717,
                0.05350589379668236,
                -0.004263975191861391,
                0.055832937359809875,
                -0.050054002553224564,
                0.01928599923849106,
                -0.09453115612268448,
                -0.050704892724752426,
                0.0011249794624745846,
                -0.008744113147258759,
                -0.023815443739295006,
                -0.02101295441389084,
                -0.01132354699075222,
                -0.006181152071803808,
                0.048147138208150864,
                0.01996776834130287,
                -0.01747455634176731,
                0.011586232110857964,
                0.022934088483452797,
                0.006087134126573801,
                0.005405483301728964,
                0.022843658924102783,
                0.0096298111602664,
                -0.019791871309280396,
                -0.10727641731500626,
                -0.06321223080158234,
                0.01206936500966549,
                -0.05301312729716301,
                -0.018569137901067734,
                -0.03437361121177673,
                -0.019715895876288414,
                -0.022831348702311516,
                -0.013348699547350407,
                0.011511293239891529,
                -0.02553260140120983,
                -0.006747421342879534,
                -0.05695614218711853,
                -0.006551980506628752,
                0.11403977125883102,
                -0.011844796128571033,
                -0.018202178180217743,
                -0.048575613647699356,
                -0.0033093474339693785,
                -0.009251273237168789,
                -0.058267559856176376,
                -0.08918949216604233,
                0.051428619772195816,
                0.035449475049972534,
                0.026792801916599274,
                0.008311818353831768,
                0.011006140150129795,
                -0.009304545819759369,
                -0.025664810091257095,
                0.04331878200173378,
                0.021486669778823853,
                -0.052800290286540985,
                0.036620549857616425,
                0.06344719976186752,
                0.03260781615972519,
                0.0785449892282486,
                -0.06519021838903427,
                0.009291796945035458,
                0.0317382775247097,
                0.06067441403865814,
                -0.02267174981534481,
                0.019909238442778587,
                0.03209073096513748,
                -0.008203578181564808,
                -0.04983457550406456,
                0.015729399397969246,
                -0.05660279467701912,
                0.00982267688959837,
                0.004732244648039341,
                0.02710556797683239,
                -0.0019691381603479385,
                -0.03461930900812149,
                -0.013243140652775764,
                0.008360711857676506,
                -0.02463115192949772,
                0.008834119886159897,
                0.0062013347633183,
                -0.060583170503377914,
                0.001557788928039372,
                0.009264425374567509,
                -0.01744890958070755,
                0.024082748219370842,
                0.05046088621020317,
                0.002304146299138665,
                -0.03281452879309654,
                -0.0584053136408329,
                0.031578581780195236,
                0.01385329756885767,
                0.02551659755408764,
                -0.045895759016275406,
                0.003613230772316456,
                -0.005691687110811472,
                -0.05542095750570297,
                -0.023278292268514633,
                -0.021594593301415443,
                -0.010941535234451294,
                -0.004511886741966009,
                0.0017512498889118433,
                -0.019126253202557564,
                0.019824013113975525,
                0.04382014274597168,
                -0.012571061961352825,
                0.02608003094792366,
                0.04507562890648842,
                0.015816397964954376,
                -0.020512280985713005,
                0.014496739953756332,
                0.023477958515286446,
                0.01921743154525757,
                -0.017986971884965897,
                0.020543411374092102,
                0.016032934188842773,
                -0.08244277536869049,
                0.03508901596069336,
                -0.013675076887011528,
                0.04749632254242897,
                0.010756395757198334,
                -0.03167496249079704,
                0.06267333030700684,
                0.04212302342057228,
                0.02850954234600067,
                -0.018061455339193344,
                0.008534114807844162,
                -0.04977341741323471,
                0.0028718828689306974,
                0.0007572273607365787,
                0.019584106281399727,
                -0.0008962757419794798,
                0.03977850824594498,
                -0.006383167114108801,
                0.00454685278236866,
                0.057262957096099854,
                0.02342314086854458,
                -0.0018235960742458701,
                -0.0003558093449100852,
                -0.00466950936242938,
                -0.04850911349058151,
                -0.0007797002326697111,
                -0.09281454980373383,
                0.02172764018177986,
                0.05633847415447235,
                -0.05390535667538643,
                -0.04697959125041962,
                -0.012667600065469742,
                -6.420902792496581e-33,
                -0.012131985276937485,
                -0.017671091482043266,
                -0.009745354764163494,
                0.04155667498707771,
                -0.09108739346265793,
                -0.030104652047157288,
                -0.0017417395720258355,
                -0.021627113223075867,
                -0.03824084252119064,
                0.015900075435638428,
                0.023683443665504456,
                -0.03751282021403313,
                0.0065560974180698395,
                0.007411599159240723,
                0.013182380236685276,
                0.011159227229654789,
                -0.040675949305295944,
                0.0002860048261936754,
                0.019105257466435432,
                -0.026228828355669975,
                0.03472357615828514,
                0.007919690571725368,
                -0.01500053983181715,
                0.027503879740834236,
                0.014116683043539524,
                -0.0024301561061292887,
                -0.08145906776189804,
                0.011092397384345531,
                -0.061564601957798004,
                -0.038666825741529465,
                0.010988161899149418,
                0.002816195832565427,
                -0.0011817787308245897,
                -0.03484005108475685,
                -0.01473347656428814,
                0.0034920922480523586,
                -0.0003528632514644414,
                -0.019579291343688965,
                -0.046635594218969345,
                -0.011906168423593044,
                -0.1035294383764267,
                0.010019169189035892,
                0.04169116169214249,
                -0.013178803026676178,
                -0.04840471222996712,
                -0.02076629363000393,
                -0.008423126302659512,
                -0.06023407727479935,
                0.019939523190259933,
                -0.01282249391078949,
                0.004356296733021736,
                -0.03120281733572483,
                0.004363956395536661,
                0.025432510301470757,
                -0.014406229369342327,
                0.07795801013708115,
                -0.033694297075271606,
                0.05381428450345993,
                -0.02899416908621788,
                0.005351916886866093,
                0.05075303092598915,
                0.04992566257715225,
                0.007526209577918053,
                0.009534782730042934,
                -0.018722692504525185,
                0.02302587404847145,
                -0.029871823266148567,
                -0.03228195011615753,
                -0.033368825912475586,
                0.06091485917568207,
                -0.01526084914803505,
                0.040650565177202225,
                0.02370731718838215,
                -0.032258130609989166,
                -0.024791037663817406,
                -0.03734389692544937,
                -0.030927574262022972,
                0.03167693689465523,
                0.028962375596165657,
                0.004544598050415516,
                0.011908133514225483,
                -0.052895769476890564,
                0.09625892341136932,
                -0.019703492522239685,
                -0.008725300431251526,
                0.03086993470788002,
                -0.035729505121707916,
                0.06961160153150558,
                -0.00495481351390481,
                -0.016529114916920662,
                0.008206481114029884,
                0.0169337410479784,
                0.011388266459107399,
                -0.00430207559838891,
                0.023754162713885307,
                0.09624017030000687,
                -0.009780946187675,
                -0.048601869493722916,
                -6.387287430698052e-05,
                0.019817287102341652,
                0.025031518191099167,
                0.004822661634534597,
                -0.03366808593273163,
                0.07602503895759583,
                -0.007873127236962318,
                -0.0445832833647728,
                -0.020430931821465492,
                -0.03645617887377739,
                -0.002720061456784606,
                0.024212639778852463,
                -0.014820178039371967,
                -0.002126721665263176,
                0.01571919396519661,
                -0.03001222014427185,
                0.014060198329389095,
                -0.017557885497808456,
                0.008331090211868286,
                0.005033435765653849,
                -0.049245674163103104,
                0.06726126372814178,
                0.03177809342741966,
                0.04008544981479645,
                -0.027664141729474068,
                0.05288619548082352,
                -0.030269140377640724,
                0.007721554022282362,
                -0.014915388077497482,
                0.0468422994017601,
                -0.10608953982591629,
                0.0062406244687736034,
                0.011674268171191216,
                0.029114479199051857,
                2.1519096549127426e-07,
                -0.04702235758304596,
                -0.012728576548397541,
                -0.03053017519414425,
                -0.061577245593070984,
                -0.03023367002606392,
                0.024434782564640045,
                0.024789879098534584,
                0.029730459675192833,
                -0.04622102528810501,
                -0.028831299394369125,
                -0.023384355008602142,
                -0.03911999613046646,
                -0.003811078378930688,
                -0.02921210415661335,
                -0.002861558925360441,
                -0.036314867436885834,
                0.0001032764557749033,
                0.00041878342744894326,
                0.03903009742498398,
                -0.027808062732219696,
                0.0036603393964469433,
                -0.019828153774142265,
                -0.07310666888952255,
                -0.022767672315239906,
                -0.03108791820704937,
                0.036508798599243164,
                0.027127310633659363,
                -0.01977595128118992,
                0.05279792845249176,
                0.006552015896886587,
                0.022842245176434517,
                0.014237419702112675,
                0.03064526803791523,
                -0.07125736773014069,
                -0.017559127882122993,
                -0.03461310639977455,
                0.008013002574443817,
                0.030612988397479057,
                -0.03832947462797165,
                0.028534051030874252,
                0.005695690866559744,
                0.047107845544815063,
                0.002615922363474965,
                0.04485827684402466,
                -0.07261447608470917,
                0.039848022162914276,
                0.022247688844799995,
                -0.008642110042273998,
                -0.04482226446270943,
                -0.0029399569611996412,
                -0.03766849637031555,
                -0.005484469700604677,
                -0.007469597272574902,
                -0.0013865387300029397,
                -0.023116527125239372,
                -0.020296502858400345,
                -0.013992149382829666,
                0.01953285001218319,
                0.02892795391380787,
                0.03192707523703575,
                -0.023083489388227463,
                0.06276760250329971,
                -0.03892151638865471,
                0.08101075142621994,
                0.04617752134799957,
                -0.008770817890763283,
                0.01197048556059599,
                9.147847418724e-35,
                0.016436807811260223,
                -0.023830043151974678,
                -0.10275816917419434,
                -0.012594128958880901,
                -0.024151314049959183,
                0.006061450112611055,
                -0.049334924668073654,
                0.021394625306129456,
                -0.051190491765737534,
                -0.06057390198111534,
                -0.004399037454277277
            ]
        },
        "Location": "México",
        "Remuneration": 33.65384615384615,
        "Services Required": {
            "name": "CleanAim",
            "rep": [
                -0.03777630999684334,
                0.05293795093894005,
                -0.02454930730164051,
                0.005433413665741682,
                -0.016874520108103752,
                -0.01717325486242771,
                -0.026559876278042793,
                0.031173352152109146,
                0.027799829840660095,
                -0.031041517853736877,
                0.03880079835653305,
                -0.0034489028621464968,
                0.008755313232541084,
                0.019828643649816513,
                -0.019781114533543587,
                0.012477443553507328,
                0.0057717058807611465,
                -0.00623384490609169,
                0.09839237481355667,
                -0.028834855183959007,
                -0.04178966209292412,
                -0.002518679480999708,
                0.002579157007858157,
                0.0606524758040905,
                0.05603742226958275,
                -0.036546461284160614,
                -0.006492944434285164,
                -0.011752179823815823,
                -0.03545961156487465,
                0.00854073278605938,
                0.038308337330818176,
                -0.03303854167461395,
                -0.07088901102542877,
                0.04105895012617111,
                2.083028221022687e-06,
                -0.006938365288078785,
                0.0012397979153320193,
                -0.003367834258824587,
                0.02050517313182354,
                0.0547247938811779,
                -0.03811483830213547,
                0.03112100623548031,
                -0.0019111098954454064,
                0.03390922769904137,
                -0.013672394677996635,
                -0.0008834716863930225,
                0.0472089946269989,
                -0.028901228681206703,
                0.030316928401589394,
                0.032877422869205475,
                -0.007551142014563084,
                0.007337610237300396,
                -0.0548366978764534,
                -0.005628325045108795,
                0.03176877275109291,
                -0.0019502331269904971,
                0.013658898882567883,
                -0.03190751001238823,
                -0.10378198325634003,
                0.00052495242562145,
                -0.007773309014737606,
                0.03257075324654579,
                -0.052015434950590134,
                -0.0021086016204208136,
                0.027157869189977646,
                0.038232140243053436,
                0.08246637880802155,
                -0.0012128448579460382,
                0.05192694067955017,
                0.0058694863691926,
                -0.04817710071802139,
                0.045156676322221756,
                0.01283752080053091,
                0.06872359663248062,
                -0.044122084975242615,
                0.024042367935180664,
                -0.01823994517326355,
                0.06539344787597656,
                -0.0285272728651762,
                0.039203498512506485,
                -0.06366425007581711,
                0.0131872883066535,
                0.03897252306342125,
                0.059489428997039795,
                -0.005558020435273647,
                0.03660554438829422,
                -0.0056230733171105385,
                -0.004753672517836094,
                0.03008856251835823,
                -0.00978612806648016,
                -0.0453224778175354,
                -0.061316702514886856,
                0.01342060323804617,
                0.04474031925201416,
                -0.04245179891586304,
                -0.00766535522416234,
                0.006554177962243557,
                -0.06898859143257141,
                -0.005033167079091072,
                -0.07398636639118195,
                0.02584259957075119,
                0.025967594236135483,
                0.00528324767947197,
                0.02894335798919201,
                -0.06473773717880249,
                -0.04032057151198387,
                0.05259287357330322,
                0.026463598012924194,
                0.06281362473964691,
                -0.021723074838519096,
                0.07105723768472672,
                0.018763385713100433,
                -0.008908550255000591,
                0.059651631861925125,
                0.00531516969203949,
                0.025763632729649544,
                -0.039012931287288666,
                0.018816998228430748,
                -0.0034143042284995317,
                -0.018114322796463966,
                0.01611553691327572,
                0.0016269960906356573,
                -0.00996851921081543,
                0.003681026166304946,
                -0.00563168665394187,
                -0.007978919893503189,
                -0.03755166754126549,
                0.026769541203975677,
                -0.01338760182261467,
                0.013009273447096348,
                0.01061832532286644,
                -0.011513094417750835,
                -0.029673730954527855,
                -0.013547525741159916,
                0.02722243405878544,
                0.05313591659069061,
                -0.01985502615571022,
                -0.03429960086941719,
                -0.02574777975678444,
                -0.037594087421894073,
                0.03294720500707626,
                -0.00842626579105854,
                -0.00675197271630168,
                -0.012424474582076073,
                -0.04057183861732483,
                -0.03000081144273281,
                0.019185833632946014,
                -0.07600883394479752,
                0.005189649760723114,
                0.015586654655635357,
                -0.015375731512904167,
                -0.020502040162682533,
                -0.046084824949502945,
                0.03903815522789955,
                0.029320785775780678,
                -0.0003234900359530002,
                -0.022356295958161354,
                -0.02425103820860386,
                0.0009009085479192436,
                -0.03214620053768158,
                -0.004273501690477133,
                -0.023833898827433586,
                0.0003306675353087485,
                -0.0009195503662340343,
                -0.004902447108179331,
                -0.013730958104133606,
                0.004706515930593014,
                -0.029687222093343735,
                0.02835189178586006,
                0.006032310891896486,
                0.013334122486412525,
                0.015477326698601246,
                -0.014079391956329346,
                -3.6611036193789914e-05,
                0.052911434322595596,
                -0.009558961726725101,
                -0.017236463725566864,
                -0.0315958671271801,
                -0.06642017513513565,
                -0.050064172595739365,
                0.042414966970682144,
                -0.08689098805189133,
                -0.013499186374247074,
                -0.031768277287483215,
                0.03475362807512283,
                0.03076276183128357,
                0.05404713749885559,
                0.10373388230800629,
                -0.017971178516745567,
                -0.005261839833110571,
                0.02562340907752514,
                -0.019622065126895905,
                -0.016533443704247475,
                -0.037659741938114166,
                0.022333679720759392,
                0.0090707466006279,
                1.7646723790676333e-05,
                -0.01790754124522209,
                0.027277860790491104,
                0.009280214086174965,
                -0.037985458970069885,
                -0.04419002681970596,
                0.07057831436395645,
                0.005886887665838003,
                -0.02109798789024353,
                0.029999561607837677,
                -0.08841505646705627,
                -0.03291688486933708,
                -0.017410200089216232,
                -0.03525485470890999,
                -0.04665983468294144,
                0.02483237534761429,
                0.05085892602801323,
                -0.018292725086212158,
                -0.049703530967235565,
                -0.011906689032912254,
                0.010009437799453735,
                0.026484642177820206,
                -0.04491916298866272,
                -0.011074138805270195,
                0.016129937022924423,
                0.020982230082154274,
                0.022026652470231056,
                0.018594928085803986,
                0.005952585022896528,
                -0.013256668113172054,
                0.03852022811770439,
                -0.022729555144906044,
                -0.004128307569772005,
                -0.02546348050236702,
                0.021894017234444618,
                -0.012583783827722073,
                0.02996973879635334,
                -0.017402956262230873,
                0.022537216544151306,
                -0.019503798335790634,
                0.06551773101091385,
                0.002631356008350849,
                0.00015334368799813092,
                -0.05016600340604782,
                0.021058304235339165,
                0.1139107346534729,
                0.010464758612215519,
                -0.030899981036782265,
                0.008271845057606697,
                0.06685478240251541,
                0.05800798907876015,
                0.0006325038848444819,
                0.017531555145978928,
                -0.005139069631695747,
                0.013381999917328358,
                0.004543686285614967,
                0.07688616216182709,
                0.014161644503474236,
                0.02898150496184826,
                0.0794028490781784,
                -0.05706888437271118,
                -0.003568135667592287,
                0.0239853635430336,
                -0.047151435166597366,
                -0.030895639210939407,
                -0.04358866810798645,
                0.023347487673163414,
                -0.006521034054458141,
                0.11622028797864914,
                -0.0038361710030585527,
                0.021228734403848648,
                -0.014777109958231449,
                0.007861173711717129,
                0.034719474613666534,
                -0.03282085061073303,
                -0.02350742183625698,
                0.03195704147219658,
                -0.029795274138450623,
                0.001511658658273518,
                -0.037385109812021255,
                -0.006614436395466328,
                0.057688258588314056,
                -0.025717785581946373,
                0.003944138064980507,
                -0.015597540885210037,
                -0.03721807897090912,
                0.007385332137346268,
                0.032697487622499466,
                0.03407444432377815,
                0.04667291417717934,
                0.0052736070938408375,
                -0.007929982617497444,
                -0.01740039885044098,
                0.014683976769447327,
                -0.05444812774658203,
                -0.025909313932061195,
                0.0287726242095232,
                -0.021117016673088074,
                -0.007243014872074127,
                0.016734188422560692,
                -0.0522180013358593,
                0.004517605062574148,
                0.015326179563999176,
                -0.07844442129135132,
                -0.009346101433038712,
                -0.0397813618183136,
                -0.022375982254743576,
                -0.032762326300144196,
                0.030460326001048088,
                -0.03412942588329315,
                0.03209276497364044,
                -0.012870845384895802,
                0.05123760923743248,
                0.039285335689783096,
                -0.019140806049108505,
                0.015220521949231625,
                0.026650482788681984,
                0.04466497525572777,
                0.0210581012070179,
                0.03247084841132164,
                0.023459231480956078,
                -0.028530344367027283,
                -0.017424562945961952,
                0.07408351451158524,
                -0.001096119056455791,
                -0.0003670987789519131,
                -0.017655769363045692,
                -0.01334390603005886,
                0.015421150252223015,
                0.059359800070524216,
                -0.0009050085791386664,
                0.0199881698936224,
                -0.0558258593082428,
                -0.06448940932750702,
                0.02949882671236992,
                0.06937852501869202,
                -0.021339626982808113,
                -0.004442836157977581,
                -0.02960815094411373,
                -0.017383325845003128,
                -0.022012382745742798,
                -0.011030172929167747,
                -0.01759893260896206,
                0.01399254985153675,
                0.008786611258983612,
                -0.01231483556330204,
                -0.02375790663063526,
                -0.0797676220536232,
                -0.011058267205953598,
                -0.023873569443821907,
                0.06854909658432007,
                -0.012045136652886868,
                0.0021609533578157425,
                0.0356486551463604,
                -0.04252495616674423,
                -0.007329913321882486,
                -0.00085518165724352,
                -0.03923522308468819,
                0.005478360690176487,
                -0.03211663290858269,
                0.001989871496334672,
                -0.028127145022153854,
                -0.022766826674342155,
                0.06614567339420319,
                0.019337322562932968,
                0.056545060127973557,
                0.04460769146680832,
                -0.021174553781747818,
                0.000800884619820863,
                -0.03391188010573387,
                -0.001189627917483449,
                -0.017155256122350693,
                -0.029165245592594147,
                -0.030278151854872704,
                0.010947366245090961,
                0.017193246632814407,
                -0.002766854129731655,
                0.037693969905376434,
                -0.02568024955689907,
                0.043724361807107925,
                0.018649645149707794,
                0.04147951304912567,
                -0.028894754126667976,
                -0.024996431544423103,
                0.007572774775326252,
                -0.05936587229371071,
                -0.028788143768906593,
                0.03223365172743797,
                -0.0023534081410616636,
                -0.026410704478621483,
                -0.046736977994441986,
                0.0038480297662317753,
                -0.021330898627638817,
                -0.03084350936114788,
                0.04681384563446045,
                -0.0716540589928627,
                -0.033327873796224594,
                0.058558639138936996,
                0.048249080777168274,
                -0.0025737222749739885,
                0.045645181089639664,
                0.027760587632656097,
                -0.001714616548269987,
                0.04283714294433594,
                0.020172609016299248,
                0.10202247649431229,
                -0.09139461070299149,
                0.046608902513980865,
                -0.07385779172182083,
                0.08832836896181107,
                0.06274556368589401,
                0.013617891818284988,
                0.03318556398153305,
                -0.06197712570428848,
                0.07141057401895523,
                -0.013446942903101444,
                0.011025490239262581,
                0.009438158944249153,
                -0.02850334346294403,
                -0.012911366298794746,
                -0.031390585005283356,
                -0.012989800423383713,
                0.011283562518656254,
                -0.09172827005386353,
                0.032830435782670975,
                0.041729219257831573,
                -0.01583869755268097,
                -0.01600772887468338,
                0.02921987883746624,
                0.037511229515075684,
                0.018989194184541702,
                -0.0553947351872921,
                0.067989781498909,
                0.025575799867510796,
                -0.004276645369827747,
                -0.0058367326855659485,
                -0.037422314286231995,
                0.01549537479877472,
                0.003289014333859086,
                -0.07929177582263947,
                -0.03216918185353279,
                -0.02675226703286171,
                -0.040897924453020096,
                -0.03344697132706642,
                -0.03762054443359375,
                -0.0036902439314872026,
                0.037127211689949036,
                0.03330284729599953,
                0.00040290463948622346,
                -0.06956025958061218,
                0.01697290875017643,
                0.03677834942936897,
                -0.035434525460004807,
                -0.01782744750380516,
                0.044405918568372726,
                -0.006195538677275181,
                -0.034221675246953964,
                -0.018484527245163918,
                0.03949642926454544,
                -0.011632867157459259,
                0.08287087827920914,
                -0.031566061079502106,
                -0.0009044319740496576,
                0.07608038187026978,
                0.08295566588640213,
                0.04909889027476311,
                -0.026421621441841125,
                0.007935451343655586,
                0.03299230709671974,
                -0.04978017136454582,
                -0.050753090530633926,
                -0.040967099368572235,
                0.015202975831925869,
                -0.005008261650800705,
                -0.01067535299807787,
                0.002261224202811718,
                0.001553549780510366,
                -0.018974317237734795,
                0.022833505645394325,
                0.017525093629956245,
                -0.017237989231944084,
                -0.060008469969034195,
                -0.012016208842396736,
                0.020285265520215034,
                0.0008484044810757041,
                0.11214862763881683,
                -0.020115453749895096,
                -0.060737382620573044,
                -0.013757002539932728,
                -0.03436441347002983,
                -0.006290573161095381,
                -0.002406456507742405,
                0.005837176460772753,
                0.016883669421076775,
                -0.02653379924595356,
                0.03552158176898956,
                0.012173236347734928,
                0.02643120102584362,
                -0.02768533304333687,
                -0.034355100244283676,
                0.021559635177254677,
                -0.14762526750564575,
                -0.044346582144498825,
                0.015214601531624794,
                0.015387470833957195,
                0.015814760699868202,
                0.022794337943196297,
                0.05781639739871025,
                0.024680083617568016,
                -0.013635837472975254,
                -7.831901893950999e-05,
                0.003954415675252676,
                0.039763614535331726,
                0.01823613978922367,
                0.03186389058828354,
                -0.018766963854432106,
                0.0013992968015372753,
                -0.0021153416018933058,
                0.005396772176027298,
                0.06851062923669815,
                0.04957392066717148,
                -0.02386927232146263,
                -0.04881379380822182,
                0.005581330042332411,
                0.011985499411821365,
                0.0004633454082068056,
                -0.00584374601021409,
                0.02688628062605858,
                -0.008632848970592022,
                -0.02489500306546688,
                -0.0017752735875546932,
                -0.01734105497598648,
                -0.0344834178686142,
                0.05396381393074989,
                -0.10770497471094131,
                -0.007118373643606901,
                0.0045923818834125996,
                -0.05096828192472458,
                -0.04026000574231148,
                0.07302705198526382,
                -0.03557547554373741,
                0.013780653476715088,
                -0.008758720010519028,
                -0.08272171765565872,
                -0.006842837203294039,
                0.019444523379206657,
                -0.019398942589759827,
                -0.002376851160079241,
                0.048445865511894226,
                0.016087401658296585,
                -0.01643749140202999,
                0.0553487166762352,
                0.024079035967588425,
                -0.0024649733677506447,
                -0.012062499299645424,
                0.07345222681760788,
                -0.08667883276939392,
                0.017580127343535423,
                -0.05324508994817734,
                -7.88481129444884e-33,
                0.013207976706326008,
                -0.01903507485985756,
                0.0030703041702508926,
                -0.01368052326142788,
                -0.035058390349149704,
                -0.02295846864581108,
                -0.014988670125603676,
                0.028739692643284798,
                -0.004301592707633972,
                0.014897600747644901,
                -0.02475462295114994,
                -0.004318857099860907,
                0.028209630399942398,
                0.00015772382903378457,
                0.04257027432322502,
                -0.003405921859666705,
                0.04249699413776398,
                -0.019941367208957672,
                -0.03497789055109024,
                0.007090090773999691,
                -0.03929956629872322,
                0.030201714485883713,
                -0.0448685921728611,
                0.04098799452185631,
                0.008316741324961185,
                -0.024969439953565598,
                -0.042490020394325256,
                0.005877512041479349,
                -0.011283371597528458,
                -0.0008914395002648234,
                -0.019387656822800636,
                -0.020397303625941277,
                -0.005452202633023262,
                0.04736506938934326,
                0.01481606811285019,
                -0.0008451727335341275,
                0.014230978675186634,
                -0.011084601283073425,
                0.010350772179663181,
                0.01609260030090809,
                -0.05688110738992691,
                -0.07673919945955276,
                0.07419988512992859,
                -0.008844643831253052,
                -0.008111239410936832,
                -0.0018981383182108402,
                0.01695208251476288,
                -0.07498317956924438,
                0.008985370397567749,
                0.020329857245087624,
                0.053933270275592804,
                -0.002353535732254386,
                -0.0030034815426915884,
                -0.11325856298208237,
                -0.024534741416573524,
                -0.016857260838150978,
                0.02427678555250168,
                0.018978038802742958,
                -0.04103613272309303,
                0.0005918883834965527,
                0.049509197473526,
                -0.012344333343207836,
                -0.018964523449540138,
                0.022358497604727745,
                -0.014874602667987347,
                0.03070261888206005,
                0.06409458816051483,
                0.008260912261903286,
                0.002672482980415225,
                0.04563869535923004,
                -0.008854763582348824,
                -0.014990255236625671,
                0.021652858704328537,
                -0.06537806987762451,
                0.023459671065211296,
                -0.05186773091554642,
                0.019714711233973503,
                0.06543216854333878,
                0.010308749042451382,
                0.032443538308143616,
                -0.053981706500053406,
                -0.013393006287515163,
                0.08599328249692917,
                0.010409236885607243,
                0.0015832219505682588,
                -0.04859866201877594,
                -0.006491486914455891,
                0.01920989342033863,
                -0.022944526746869087,
                0.019846796989440918,
                -0.02887892536818981,
                0.046620454639196396,
                0.008518255315721035,
                0.02587004378437996,
                0.04016002640128136,
                -0.05164606124162674,
                -0.013074995018541813,
                -0.047991134226322174,
                -0.03565419837832451,
                -0.03508766368031502,
                0.041976362466812134,
                0.030539726838469505,
                0.00338476593606174,
                0.05612463504076004,
                0.03431633487343788,
                0.023850491270422935,
                -0.011469665914773941,
                0.019770720973610878,
                -0.082680344581604,
                -0.002739586867392063,
                -0.011515517719089985,
                0.033792465925216675,
                -0.009315895847976208,
                -0.0013336101546883583,
                -0.008807086385786533,
                0.02599804289638996,
                0.015262619592249393,
                -0.00826084055006504,
                -0.006914263125509024,
                0.010968652553856373,
                0.008961638435721397,
                -0.04022515192627907,
                0.023241788148880005,
                0.004494810942560434,
                0.011756635271012783,
                -0.0019046261440962553,
                0.020578067749738693,
                -0.026198145002126694,
                0.005836439318954945,
                -0.03024289570748806,
                -0.014030691236257553,
                -0.03250304237008095,
                2.7547415015760635e-07,
                0.0073361643590033054,
                -0.019067930057644844,
                -0.030655406415462494,
                -0.023526184260845184,
                -0.019287265837192535,
                0.05658866465091705,
                -0.06942043453454971,
                -0.006779612507671118,
                -0.05295323207974434,
                0.00166641129180789,
                -0.02814129926264286,
                -0.0345509797334671,
                0.0186911653727293,
                -0.029017703607678413,
                -0.03186556696891785,
                -0.06487855315208435,
                -0.039012424647808075,
                -0.010134600102901459,
                -0.0183053407818079,
                -0.0032837041653692722,
                -0.010427423752844334,
                0.002433106303215027,
                0.022021811455488205,
                -0.05259128659963608,
                0.023213090375065804,
                -0.02920546382665634,
                -0.006731150671839714,
                -0.04266613349318504,
                0.029904041439294815,
                0.012243236415088177,
                0.07362394034862518,
                -0.07606039196252823,
                0.00775359570980072,
                -0.01612972468137741,
                0.005187279544770718,
                -0.062187619507312775,
                0.018746329471468925,
                0.04927777126431465,
                0.028869397938251495,
                0.07443632930517197,
                -0.009236805140972137,
                -0.0041552879847586155,
                -0.004067515954375267,
                0.05224248021841049,
                -0.0006343627464957535,
                0.01900619827210903,
                -0.03245394676923752,
                -0.004534886218607426,
                -0.11538484692573547,
                -0.047835271805524826,
                0.02379496395587921,
                0.00021012683282606304,
                -0.014776580967009068,
                0.027978429570794106,
                -0.007572450675070286,
                0.02613699436187744,
                0.01322894636541605,
                -0.0040231975726783276,
                -0.021293681114912033,
                -0.06694043427705765,
                -0.0036693373695015907,
                0.03810674324631691,
                0.035653743892908096,
                -0.03847075253725052,
                0.020461440086364746,
                -0.005001010838896036,
                0.001770860981196165,
                1.5183192426336938e-34,
                0.032760512083768845,
                -0.0841258242726326,
                -0.06621506810188293,
                0.027630778029561043,
                -0.019404606893658638,
                0.003892765613272786,
                0.013392958790063858,
                0.04027162864804268,
                -0.03510480374097824,
                -0.006703815422952175,
                0.011738082394003868
            ]
        },
        "Skills": {
            "name": [
                "Project planning",
                "Organization ",
                "Project management",
                "Leadership & Leadership Coaching"
            ],
            "rep": [
                0.018381668254733086,
                0.04342448338866234,
                -0.02211470901966095,
                0.0002537981199566275,
                -0.028149791061878204,
                -0.03528537228703499,
                -0.02143491618335247,
                -0.03770109638571739,
                -0.07121681421995163,
                0.014904573559761047,
                0.07827585935592651,
                0.008876209147274494,
                -0.011785712093114853,
                0.0785800889134407,
                -0.02495887316763401,
                -0.06208249181509018,
                -0.0239462498575449,
                -0.01172337681055069,
                -0.045668452978134155,
                -0.0007684384472668171,
                -0.013999239541590214,
                -0.009838398545980453,
                -0.022307055070996284,
                0.007326734717935324,
                -0.05682133510708809,
                0.01295365858823061,
                -0.08935309201478958,
                0.04353606328368187,
                1.2307614269957412e-05,
                -0.04255374148488045,
                0.0035187460016459227,
                0.041875798255205154,
                -0.029868463054299355,
                -0.023357100784778595,
                1.4369124983204529e-06,
                0.019013626500964165,
                -0.03617720305919647,
                -0.04301628842949867,
                -0.0036938649136573076,
                0.036795880645513535,
                -0.0021527966018766165,
                -0.034781280905008316,
                0.02756001241505146,
                0.011234987527132034,
                -0.01468648575246334,
                0.03969518095254898,
                0.013459809124469757,
                0.02452702634036541,
                -0.023582858964800835,
                -0.04860484600067139,
                0.008050497621297836,
                -0.04432109370827675,
                -0.07109643518924713,
                -0.03259364515542984,
                -0.03783269226551056,
                -0.061570607125759125,
                0.004137007053941488,
                -0.013402892276644707,
                0.07315381616353989,
                0.04108189046382904,
                0.00936615839600563,
                0.00847452413290739,
                0.011047097854316235,
                0.010304943658411503,
                0.06980829685926437,
                -0.023429693654179573,
                0.0070030223578214645,
                -0.03207855299115181,
                -0.014616346918046474,
                -0.0008015964995138347,
                0.03526180237531662,
                0.045216891914606094,
                0.01304188184440136,
                -0.011576447635889053,
                -0.042222585529088974,
                -0.05912785604596138,
                -0.028323939070105553,
                -0.01912451907992363,
                -0.014641087502241135,
                -0.02517743408679962,
                -0.025657497346401215,
                -0.02679637260735035,
                -0.032745007425546646,
                0.0023847799748182297,
                0.00731880497187376,
                0.08119990676641464,
                -0.021583251655101776,
                -0.004128229804337025,
                -0.014449569396674633,
                0.05153907090425491,
                0.01366428192704916,
                -0.053345486521720886,
                0.0595361702144146,
                0.0398227721452713,
                -0.06719454377889633,
                -0.001547417021356523,
                0.04524994269013405,
                0.023000221699476242,
                0.01536513864994049,
                0.0164086502045393,
                0.012478865683078766,
                0.019286878407001495,
                0.018400276079773903,
                0.04140026122331619,
                0.036578770726919174,
                0.049046315252780914,
                -0.045944664627313614,
                -0.03168604522943497,
                -0.043886952102184296,
                0.051064953207969666,
                -0.03431925177574158,
                0.014559958130121231,
                -0.04901973158121109,
                0.041744962334632874,
                -0.07681068778038025,
                0.005059470888227224,
                -0.038472309708595276,
                0.01987254060804844,
                0.03361697122454643,
                0.028311144560575485,
                0.05047903209924698,
                0.022452330216765404,
                -0.006431478541344404,
                -0.010791643522679806,
                -0.043553225696086884,
                -0.0347747839987278,
                -0.08254142850637436,
                0.01580962911248207,
                -0.04723503813147545,
                0.03107295371592045,
                0.022013243287801743,
                -0.015478494577109814,
                0.01507567334920168,
                0.002658535959199071,
                -0.01571653038263321,
                0.07056169956922531,
                0.008661343716084957,
                -0.001183400279842317,
                -0.03572479635477066,
                -0.02633517235517502,
                0.013008923269808292,
                -0.007416379172354937,
                -0.026858339086174965,
                -0.019039155915379524,
                -0.053856849670410156,
                -0.019512338563799858,
                -0.05737156793475151,
                0.07906483113765717,
                0.024721192196011543,
                -0.00455087935552001,
                -0.017373278737068176,
                -0.006985718384385109,
                0.02213764376938343,
                0.021254317834973335,
                0.0379166454076767,
                0.05226261168718338,
                -0.021601950749754906,
                0.033733513206243515,
                -0.0054010068997740746,
                0.07998247444629669,
                -0.03115374781191349,
                0.049386922270059586,
                0.032090745866298676,
                0.021933268755674362,
                -0.04458864405751228,
                -0.01995125040411949,
                0.07166165113449097,
                0.014137308113276958,
                0.005046493839472532,
                -0.009776072576642036,
                -0.10594573616981506,
                0.02753603830933571,
                0.01231884490698576,
                0.07688787579536438,
                0.028871934860944748,
                -0.031878866255283356,
                0.07214327901601791,
                -0.012451691552996635,
                -0.03544757887721062,
                0.05129478871822357,
                -0.020855534821748734,
                -0.09225448966026306,
                0.016116827726364136,
                0.08798322081565857,
                0.0279056616127491,
                -0.016856973990797997,
                -0.04768780618906021,
                0.03098401241004467,
                -0.0290197916328907,
                0.01688307709991932,
                -0.0037592705339193344,
                -0.01565156877040863,
                -0.06345005333423615,
                -0.016168484464287758,
                0.012219676747918129,
                0.0022585063707083464,
                -0.01674206182360649,
                -0.03148508816957474,
                -0.027053192257881165,
                0.009654225781559944,
                0.01036893855780363,
                0.03604720160365105,
                0.016900403425097466,
                0.008141322061419487,
                -0.004808994475752115,
                -0.05328560248017311,
                -0.04792408272624016,
                0.014484643936157227,
                -0.04578588157892227,
                -0.012879825197160244,
                0.05093352124094963,
                -0.031228890642523766,
                0.03563545644283295,
                0.020626960322260857,
                -0.0374600887298584,
                0.004298259504139423,
                -0.005996564403176308,
                -0.013273987919092178,
                -0.059240322560071945,
                -0.007461812812834978,
                -0.03576963022351265,
                0.041505180299282074,
                0.024989254772663116,
                0.04313226789236069,
                -0.00042551246588118374,
                -0.020190706476569176,
                0.026194442063570023,
                0.008442944847047329,
                0.06793728470802307,
                0.04043760523200035,
                0.003044100245460868,
                0.016256170347332954,
                0.03098052181303501,
                -0.03358624875545502,
                0.006980253849178553,
                -0.01513474527746439,
                0.07201291620731354,
                0.023013725876808167,
                -0.008072043769061565,
                -0.053983818739652634,
                0.012192582711577415,
                -0.06001860648393631,
                -0.01901552453637123,
                -0.018501562997698784,
                0.04456888511776924,
                0.018106114119291306,
                0.00743673462420702,
                0.04507134109735489,
                0.03825458511710167,
                -0.04113221913576126,
                -0.06699598580598831,
                -0.05265532061457634,
                0.02685425989329815,
                0.02951463684439659,
                0.026391131803393364,
                0.06401395052671432,
                -0.04869027063250542,
                0.04660070687532425,
                -0.03446199372410774,
                -0.021871043369174004,
                0.044725142419338226,
                0.0076128472574055195,
                -0.03447727486491203,
                -0.028465643525123596,
                0.04743253439664841,
                -0.013559823855757713,
                -0.011470346711575985,
                -0.0752042755484581,
                -0.05738311633467674,
                -0.0032957205548882484,
                -0.00662927096709609,
                -0.009384936653077602,
                0.014308488927781582,
                -0.00736579392105341,
                -0.061073850840330124,
                0.029809610918164253,
                0.020504120737314224,
                -0.04711781442165375,
                -0.04027309641242027,
                0.004756253678351641,
                0.05716371163725853,
                -0.006153677124530077,
                -0.05027958005666733,
                0.018229225650429726,
                0.01189452689141035,
                -0.022988449782133102,
                0.05789998173713684,
                -0.08194150030612946,
                -0.0432591438293457,
                0.05398405343294144,
                -0.022348619997501373,
                -0.04621214047074318,
                0.021480850875377655,
                -0.014655496925115585,
                -0.008688520640134811,
                0.014122555032372475,
                0.041706692427396774,
                -0.04078848659992218,
                -0.04124169424176216,
                -0.015564821660518646,
                0.03241752088069916,
                -0.055023472756147385,
                -0.007311966735869646,
                -0.012447609566152096,
                -0.015268152579665184,
                0.04017074033617973,
                0.05627485364675522,
                0.005186060443520546,
                0.021001296117901802,
                0.024798540398478508,
                -0.012603207491338253,
                0.026945026591420174,
                -0.016704389825463295,
                0.02431425265967846,
                -0.004533759318292141,
                0.005060424096882343,
                -0.0013363620964810252,
                -0.012731998227536678,
                0.030046530067920685,
                0.10519442707300186,
                -0.01724623702466488,
                -0.016490453854203224,
                -0.00471844058483839,
                -0.0008720833575353026,
                -0.003979124128818512,
                -0.0023380008060485125,
                -0.006422265898436308,
                -0.027971042320132256,
                0.02359972707927227,
                0.002059894148260355,
                -0.003135612001642585,
                -0.059621647000312805,
                -0.003386831609532237,
                -0.04708174616098404,
                0.006041050888597965,
                -0.00781407579779625,
                0.022622184827923775,
                -0.023050934076309204,
                -0.036088019609451294,
                -0.0069342972710728645,
                -0.0222203079611063,
                -0.05292963236570358,
                -0.07388070225715637,
                0.008084242232143879,
                -0.0032663382589817047,
                0.022661756724119186,
                0.05938908830285072,
                0.036640554666519165,
                0.005769689101725817,
                0.03140047565102577,
                0.040667835623025894,
                0.020386068150401115,
                -0.026816992089152336,
                0.009285848587751389,
                0.014884321950376034,
                0.0049662720412015915,
                -0.00959024578332901,
                0.020787635818123817,
                -0.00047966212150640786,
                0.048997968435287476,
                -0.052171554416418076,
                -0.018547020852565765,
                0.04755616933107376,
                0.03236890956759453,
                -0.02006680704653263,
                0.0019144825637340546,
                0.0411495678126812,
                0.02185119315981865,
                0.001079495414160192,
                0.04211867228150368,
                0.008876983076334,
                0.06062145531177521,
                0.011522388085722923,
                -0.016338547691702843,
                0.036765292286872864,
                -0.013799235224723816,
                0.010516935959458351,
                -0.0059020849876105785,
                -0.028935691341757774,
                0.005395206622779369,
                0.023256974294781685,
                0.000181438255822286,
                0.04596683755517006,
                0.005332972388714552,
                -0.0029211558867245913,
                -0.018442373722791672,
                -0.04394511133432388,
                -0.04216846823692322,
                0.021886341273784637,
                0.034599144011735916,
                -0.03377087041735649,
                -0.0010448761750012636,
                0.0058629210107028484,
                -0.011810259893536568,
                0.023202411830425262,
                -0.04670218378305435,
                -0.09506303817033768,
                -0.00118580786511302,
                0.02354545332491398,
                0.0004384397470857948,
                0.0050393943674862385,
                -0.055773161351680756,
                0.011171308346092701,
                -0.025227850303053856,
                0.011662562377750874,
                -0.04809308424592018,
                0.06822659820318222,
                0.03390297293663025,
                0.022708872333168983,
                0.033623527735471725,
                -0.005142939276993275,
                0.0395515002310276,
                0.016879718750715256,
                -0.04009220376610756,
                0.012915986590087414,
                0.04107381030917168,
                -0.02863655425608158,
                -0.007769130636006594,
                0.0022475363221019506,
                -0.044304050505161285,
                -0.017063964158296585,
                0.07531170547008514,
                0.021332761272788048,
                -0.04398735240101814,
                0.08693947643041611,
                -0.018194254487752914,
                0.03630317375063896,
                0.06584073603153229,
                -0.026286110281944275,
                -0.03476852551102638,
                -0.03224775195121765,
                -0.008796109817922115,
                -0.010858225636184216,
                0.03873565420508385,
                -0.024487711489200592,
                0.039673514664173126,
                0.01287868246436119,
                -0.03213614597916603,
                0.1047421246767044,
                -0.0062060668133199215,
                0.02228614315390587,
                0.08156295120716095,
                0.06054959446191788,
                0.03636985644698143,
                -0.011603445746004581,
                0.017638351768255234,
                0.0297636017203331,
                0.0022260951809585094,
                -0.06320618838071823,
                0.08099260181188583,
                0.03874879330396652,
                0.008173505775630474,
                -0.020939527079463005,
                -0.021600160747766495,
                -0.050490718334913254,
                0.02538876049220562,
                -0.009930039756000042,
                -0.042460180819034576,
                -0.02612229809165001,
                0.020319607108831406,
                0.050308145582675934,
                0.028678467497229576,
                -0.08005814999341965,
                0.0047905342653393745,
                -0.014343569986522198,
                -0.00952561292797327,
                -0.07032813131809235,
                0.031121941283345222,
                -0.0312684066593647,
                -0.04321209341287613,
                0.0790991559624672,
                0.0005559456767514348,
                -0.008024114184081554,
                0.0202309712767601,
                0.013628985732793808,
                0.024809718132019043,
                0.04862258955836296,
                -0.06140967085957527,
                5.116613124300784e-07,
                -0.013810032978653908,
                0.05833302438259125,
                0.04303446412086487,
                -0.0014689478557556868,
                -0.022397534921765327,
                -0.027230240404605865,
                0.01278624776750803,
                0.0070427036844193935,
                -0.005921420641243458,
                -0.02069525606930256,
                0.014806880615651608,
                -0.009315183386206627,
                -0.024082042276859283,
                0.029527178034186363,
                0.002971985610201955,
                -0.0153696583583951,
                -0.01158110424876213,
                0.04754077270627022,
                0.006252162158489227,
                0.06512556970119476,
                -0.04843587800860405,
                0.0408853217959404,
                0.03945317491889,
                0.0024161115288734436,
                -0.04167003929615021,
                -0.0003716901410371065,
                0.023042138665914536,
                0.0355600081384182,
                -0.00348807405680418,
                -0.02571670524775982,
                -0.0017987793544307351,
                -0.01783517748117447,
                -0.05015236511826515,
                0.03273063153028488,
                -0.11448771506547928,
                0.03204658627510071,
                0.02465820126235485,
                -0.012425638735294342,
                0.03490462154150009,
                -0.05372995883226395,
                -0.051986195147037506,
                0.03212038427591324,
                -0.010376612655818462,
                0.0020917453803122044,
                -0.02392280474305153,
                0.02159137651324272,
                -0.012871216051280499,
                0.04505529999732971,
                -0.008972964249551296,
                -0.0407433845102787,
                0.026874685660004616,
                -0.01707059144973755,
                -0.04289698600769043,
                -0.07313603907823563,
                -0.015439963899552822,
                -0.03278249874711037,
                0.061953868716955185,
                0.008725026622414589,
                0.07767082005739212,
                -0.03863587975502014,
                0.011394767090678215,
                -0.06683602929115295,
                0.03543948754668236,
                0.007388889789581299,
                -0.01794370263814926,
                0.01555855292826891,
                -0.0270677600055933,
                0.004904980305582285,
                -0.03895088657736778,
                0.01741931028664112,
                0.027129368856549263,
                0.01272666547447443,
                -0.027661878615617752,
                -0.0085368063300848,
                -0.0028360725846141577,
                -0.012156789191067219,
                -5.7996158245206004e-33,
                -0.018760599195957184,
                -0.06005694717168808,
                0.04618094488978386,
                -0.019997991621494293,
                0.017179260030388832,
                0.01016143150627613,
                0.02318839728832245,
                0.02769542671740055,
                -0.031070837751030922,
                -0.04991735890507698,
                -0.02732165716588497,
                -0.01630125567317009,
                0.01909058354794979,
                -0.01213186327368021,
                0.07594404369592667,
                0.024513034150004387,
                -0.0041966973803937435,
                0.012938673608005047,
                -0.019276781007647514,
                -0.07099892199039459,
                -0.035330988466739655,
                0.020359810441732407,
                0.026550903916358948,
                0.014803526923060417,
                -0.054252371191978455,
                0.026249652728438377,
                0.029017260298132896,
                0.06147930026054382,
                0.05483770743012428,
                -0.01069810800254345,
                -0.00011006929707946256,
                -0.039949435740709305,
                0.008216089569032192,
                0.010065020993351936,
                -0.01729373075067997,
                0.01258085761219263,
                -0.006233801133930683,
                -0.04761926829814911,
                -0.0145350256934762,
                0.010612682439386845,
                0.0014509680913761258,
                -0.010508653707802296,
                -0.01878969371318817,
                0.014299605041742325,
                -0.02115880325436592,
                -0.022116323933005333,
                0.005309644620865583,
                0.021402087062597275,
                0.02188374660909176,
                0.08472026884555817,
                -0.05145663768053055,
                0.0029975997749716043,
                -0.012900467962026596,
                0.03436576947569847,
                0.05238746851682663,
                0.027985617518424988,
                0.0012244326062500477,
                0.010005075484514236,
                0.05159251391887665,
                0.0046880668960511684,
                -0.07426667213439941,
                0.005344732198864222,
                0.02296978235244751,
                -0.02481384202837944,
                -0.032248686999082565,
                0.029478931799530983,
                0.0015373342903330922,
                0.025638211518526077,
                -0.02950887382030487,
                -0.06118450686335564,
                -0.06846699863672256,
                0.07695727050304413,
                0.022583000361919403,
                -0.026749063283205032,
                -0.041191816329956055,
                0.024781104177236557,
                -2.0213553852954647e-06,
                -0.011339353397488594,
                0.056800637394189835,
                0.06813651323318481,
                0.007526312954723835,
                0.04368523135781288,
                0.027882441878318787,
                0.0031977901235222816,
                0.003537018084898591,
                -0.05935481935739517,
                -0.024630526080727577,
                0.056784167885780334,
                -0.006988100707530975,
                0.02706584520637989,
                -0.005242191720753908,
                0.09492655098438263,
                0.00848541222512722,
                0.015774153172969818,
                0.0843505933880806,
                0.054083451628685,
                -0.02955676056444645,
                0.004859131760895252,
                -0.02121049165725708,
                0.054533522576093674,
                -0.07188640534877777,
                0.0011707431403920054,
                -0.04425932839512825,
                -0.01507400069385767,
                -0.02209923043847084,
                -0.006570127326995134,
                -0.07130571454763412,
                -0.014019584283232689,
                0.018057357519865036,
                0.00016457615129183978,
                -0.00914026703685522,
                -0.015028226189315319,
                -0.016202786937355995,
                -0.01722576469182968,
                0.021730825304985046,
                -0.03154217079281807,
                -0.020697062835097313,
                -0.055902149528265,
                0.0009518328006379306,
                -0.05509261414408684,
                0.030559087172150612,
                -0.03184123709797859,
                0.005023419391363859,
                -0.00843000691384077,
                0.0026174657978117466,
                -0.049956995993852615,
                0.016971804201602936,
                -0.010298626497387886,
                0.018447017297148705,
                -0.022406063973903656,
                -0.039645567536354065,
                0.0340239554643631,
                2.1647915104949789e-07,
                0.004513493273407221,
                0.04187800735235214,
                0.03138001263141632,
                0.06681762635707855,
                -0.016398699954152107,
                -0.016090117394924164,
                0.010613135062158108,
                -0.013358591124415398,
                -0.05980067700147629,
                0.00499221496284008,
                0.04409957304596901,
                -0.02924412302672863,
                0.005370138678699732,
                0.03044428490102291,
                -0.006771337240934372,
                -0.10612892359495163,
                0.008617154322564602,
                -0.008432581089437008,
                -0.045469533652067184,
                -0.031189503148198128,
                0.025279663503170013,
                0.048175957053899765,
                0.03577645123004913,
                0.002884132554754615,
                0.006815695203840733,
                -0.00429369043558836,
                -0.03566289320588112,
                -0.018164584413170815,
                0.0372348390519619,
                0.031806230545043945,
                0.03571445867419243,
                -0.050709910690784454,
                -0.005435043480247259,
                0.04810056835412979,
                0.019932035356760025,
                -0.06322953850030899,
                0.06796323508024216,
                0.019229533150792122,
                0.03335978090763092,
                -0.021231940016150475,
                -0.010635502636432648,
                -0.02659248746931553,
                -0.03857014328241348,
                0.0380764976143837,
                -0.04700188338756561,
                0.01676822267472744,
                0.008405045606195927,
                0.0894942432641983,
                0.02624487690627575,
                -0.006926913280040026,
                0.015908414497971535,
                0.04142490774393082,
                0.08245833218097687,
                0.029717467725276947,
                -0.012104739435017109,
                -0.026207217946648598,
                -0.008439486846327782,
                0.02549990825355053,
                0.044780243188142776,
                0.04797338321805,
                -0.03895469382405281,
                -0.024410586804151535,
                -0.03282798454165459,
                0.032225944101810455,
                0.02239333838224411,
                0.013226312585175037,
                -0.015676699578762054,
                8.508621855941494e-35,
                -0.04773496091365814,
                -0.05565410852432251,
                -0.04727388173341751,
                -0.007554277777671814,
                -0.043871503323316574,
                0.03051469288766384,
                -0.051838431507349014,
                -0.03289838507771492,
                0.003973967861384153,
                -0.0025981173384934664,
                -0.03369089215993881,
                0.0422629788517952,
                0.09448395669460297,
                0.002036517485976219,
                0.009614896960556507,
                0.012472941540181637,
                -0.02045159973204136,
                0.026991240680217743,
                -0.006985790096223354,
                -0.016252459958195686,
                0.01843046024441719,
                0.053762953728437424,
                -0.03284982591867447,
                0.005725146736949682,
                0.0033376452047377825,
                -0.013108774088323116,
                -0.0926910862326622,
                0.017543701454997063,
                0.011766839772462845,
                -0.018442222848534584,
                -0.02544669806957245,
                -0.008438367396593094,
                0.018241312354803085,
                0.011323715560138226,
                -0.0038722262252122164,
                0.03730687499046326,
                -0.025002140551805496,
                -0.008543104864656925,
                0.038766101002693176,
                -0.006087073124945164,
                0.02721531130373478,
                0.00951412320137024,
                0.018250977620482445,
                -0.024660959839820862,
                0.008334462530910969,
                2.1375433334469562e-06,
                -0.020527582615613937,
                -0.0481390506029129,
                -0.030413789674639702,
                -0.027724873274564743,
                0.02223573625087738,
                0.05296880379319191,
                -0.042129576206207275,
                -0.034917186945676804,
                0.024595580995082855,
                -0.0029868874698877335,
                0.025623464956879616,
                0.05022413283586502,
                -0.005495755467563868,
                -0.010624827817082405,
                0.009330515749752522,
                0.0008942466229200363,
                -0.03927500918507576,
                -0.07569419592618942,
                -0.010124551132321358,
                -0.04310092329978943,
                0.011469889432191849,
                -0.005888692103326321,
                0.012515499256551266,
                0.0169846098870039,
                -0.003956468775868416,
                -0.02095896005630493,
                -0.0430140346288681,
                -0.027627645060420036,
                0.029119422659277916,
                0.08309406787157059,
                0.035969655960798264,
                0.005086669232696295,
                -0.03328578546643257,
                -0.011462469585239887,
                -0.005939434748142958,
                0.024675877764821053,
                0.05592221021652222,
                0.010505221784114838,
                0.03427103906869888,
                -0.010559982620179653,
                0.0006210107821971178,
                -0.009420868940651417,
                0.05373506620526314,
                0.05298644304275513,
                -0.01963673159480095,
                0.0030213540885597467,
                0.09498805552721024,
                -0.021577047184109688,
                0.05629509687423706,
                -0.03498121351003647,
                0.022681118920445442,
                -0.04178013280034065,
                0.007409568410366774,
                -0.06010131537914276,
                0.016470778733491898,
                -0.02658659592270851,
                -0.06081889197230339,
                -0.0013808604562655091,
                0.04447253793478012,
                -0.014024756848812103,
                -0.024313200265169144,
                0.019484993070364,
                0.04754586145281792,
                0.020405778661370277,
                -0.05973469465970993,
                -0.040782380849123,
                -0.005338097456842661,
                0.0056367856450378895,
                0.01225054170936346,
                0.06302224099636078,
                0.01284860447049141,
                -0.048007089644670486,
                0.02503092959523201,
                -0.05021344870328903,
                0.05951613932847977,
                -0.06443390995264053,
                0.022747956216335297,
                -0.014688152819871902,
                0.045025479048490524,
                -0.012269046157598495,
                -0.028763405978679657,
                -0.022268300876021385,
                0.0027640201151371002,
                -0.011217706836760044,
                -0.023549100384116173,
                -0.030931808054447174,
                0.01885100081562996,
                0.02976532280445099,
                -0.015336479991674423,
                -0.00811760313808918,
                -0.04290259629487991,
                -0.0708925649523735,
                0.026259910315275192,
                -0.031840045005083084,
                0.011070623062551022,
                0.027016572654247284,
                -0.035680461674928665,
                0.0033538364805281162,
                0.019288742914795876,
                -0.003840571967884898,
                -0.012074379250407219,
                -0.002130447654053569,
                0.016186730936169624,
                -0.03905663266777992,
                -0.05079932510852814,
                0.011506794951856136,
                -0.034725748002529144,
                -0.0488157756626606,
                -0.027727581560611725,
                0.02154618501663208,
                -0.002522746566683054,
                0.015549708157777786,
                0.011866330169141293,
                -0.01987544260919094,
                -0.026430653408169746,
                -0.008140712045133114,
                -0.02325388789176941,
                -0.01396885234862566,
                0.04103866592049599,
                0.10015743225812912,
                0.057265959680080414,
                0.06188766285777092,
                -0.04555857926607132,
                0.0046904725022614,
                -0.0051164403557777405,
                3.4071905247401446e-05,
                0.0069261700846254826,
                0.003201678628101945,
                -0.02012770064175129,
                -0.042228687554597855,
                -0.04815724492073059,
                0.08975177258253098,
                -0.013868400827050209,
                -0.10830865800380707,
                1.6935731537159882e-06,
                -0.058940988034009933,
                0.01631966046988964,
                -0.04562561586499214,
                0.06541336327791214,
                0.018511010333895683,
                -0.007317143026739359,
                0.08775877207517624,
                0.022045569494366646,
                -0.10406645387411118,
                0.010546104051172733,
                -0.04890020564198494,
                -0.14111001789569855,
                -0.01490502804517746,
                0.059732336550951004,
                0.06392211467027664,
                0.030468204990029335,
                -0.002010489348322153,
                0.030532578006386757,
                -0.05818437412381172,
                0.009289090521633625,
                -0.02350352331995964,
                0.023420576006174088,
                -0.04533475264906883,
                0.004416284151375294,
                0.008647909387946129,
                -0.03556765615940094,
                0.028116196393966675,
                -0.018469253554940224,
                -0.016255253925919533,
                -0.025720665231347084,
                -0.03017900139093399,
                0.007325280457735062,
                0.0285386573523283,
                0.07031149417161942,
                -0.019817909225821495,
                0.023737117648124695,
                0.014161909930408001,
                -0.013663623481988907,
                0.020153677091002464,
                0.01757172681391239,
                0.01486240141093731,
                0.02853185124695301,
                -0.029057752341032028,
                -0.009825772605836391,
                0.02250361442565918,
                0.05386898294091225,
                0.019786350429058075,
                0.0077432263642549515,
                -0.026756640523672104,
                0.039068110287189484,
                -0.00028165525873191655,
                0.05514424666762352,
                -0.0013603187398985028,
                -0.005625282879918814,
                0.03934872895479202,
                0.016971943899989128,
                -0.01841968484222889,
                0.021545108407735825,
                0.03403080254793167,
                0.029440421611070633,
                -0.011597217060625553,
                0.03931944817304611,
                0.01252736710011959,
                0.025205792859196663,
                -0.008672825992107391,
                -0.052124250680208206,
                0.045851562172174454,
                0.07437597215175629,
                0.022456036880612373,
                0.02343570441007614,
                0.025161534547805786,
                -0.05724005028605461,
                0.04368659481406212,
                -0.021425899118185043,
                0.04323069006204605,
                0.04483862221240997,
                0.021832827478647232,
                0.0002885638386942446,
                -0.03407161310315132,
                0.006964169908314943,
                -0.04512028396129608,
                -0.016740143299102783,
                0.03153878450393677,
                -0.013302561827003956,
                -0.028937513008713722,
                0.016254929825663567,
                -0.03567815572023392,
                0.03844369947910309,
                -0.02536194585263729,
                0.0461854562163353,
                0.004380769096314907,
                -0.01822165958583355,
                0.017797168344259262,
                0.009394858032464981,
                0.009687816724181175,
                -0.015322142280638218,
                0.009525791741907597,
                -0.020198272541165352,
                -0.011795475147664547,
                -0.012335637584328651,
                0.01029966864734888,
                0.007592073641717434,
                0.016844214871525764,
                -0.00822032243013382,
                -0.004258158151060343,
                0.02530217543244362,
                -0.0316072553396225,
                0.022109439596533775,
                -0.012025631032884121,
                0.00577548099681735,
                0.00010254382505081594,
                -0.053720876574516296,
                -0.03633870556950569,
                0.0032906148117035627,
                -0.0049584731459617615,
                0.0038119766395539045,
                -0.001724930596537888,
                -0.11149200052022934,
                -0.061787717044353485,
                -0.02811121568083763,
                -0.03226640447974205,
                -0.046537626534700394,
                0.02733207307755947,
                -0.02637093886733055,
                0.027339361608028412,
                0.00668724812567234,
                0.04747556895017624,
                0.0028682423289865255,
                -0.008738283067941666,
                0.06520908325910568,
                0.05282776430249214,
                -0.01840454712510109,
                0.025784065946936607,
                -0.035246748477220535,
                0.016266053542494774,
                0.04561439901590347,
                0.044321559369564056,
                -0.020364059135317802,
                -0.014005012810230255,
                -0.002123770536854863,
                0.033959727734327316,
                0.013300231657922268,
                0.013464540243148804,
                0.0130770867690444,
                -0.02600102499127388,
                0.02103852480649948,
                -0.0564790777862072,
                -0.06016502529382706,
                -0.013610375113785267,
                0.09018807858228683,
                -0.03002392128109932,
                0.05784814432263374,
                -0.06996479630470276,
                0.025654830038547516,
                0.017197467386722565,
                -0.025938667356967926,
                -0.02059614285826683,
                0.0026352277491241693,
                0.0738220363855362,
                -0.03391430154442787,
                0.015827763825654984,
                0.026334721595048904,
                -0.03015531785786152,
                -0.0036145509220659733,
                -0.0630018413066864,
                0.021386366337537766,
                -0.02364065870642662,
                -0.05902080237865448,
                -0.029353605583310127,
                0.016673319041728973,
                0.0001435955346096307,
                -0.018289504572749138,
                -0.060234587639570236,
                0.005136013496667147,
                -0.0025710405316203833,
                0.016675813123583794,
                0.03182579204440117,
                0.02926715277135372,
                -0.06882461905479431,
                0.017124855890870094,
                0.047820307314395905,
                0.04248501732945442,
                0.011715617962181568,
                -0.005857867654412985,
                0.03486809879541397,
                0.0359383299946785,
                -0.05393737182021141,
                0.035200368613004684,
                0.04896806180477142,
                -0.09098173677921295,
                -0.045350782573223114,
                0.024037932977080345,
                0.06857986003160477,
                -0.008871293626725674,
                -0.015815822407603264,
                -0.06410519033670425,
                0.052243318408727646,
                0.007143340539187193,
                -0.020017391070723534,
                -0.006313228514045477,
                0.02125622145831585,
                0.04294739291071892,
                -0.002010984579101205,
                0.09371227025985718,
                0.03564634174108505,
                -0.016484223306179047,
                0.013899755664169788,
                -0.03662663698196411,
                -0.05953850969672203,
                -0.014828634448349476,
                0.03563152253627777,
                -0.005293755326420069,
                0.05253802612423897,
                -0.03210626542568207,
                -0.02628502994775772,
                -0.000589278235565871,
                -0.018175950273871422,
                -0.003479636972770095,
                0.039881374686956406,
                -0.012947971001267433,
                0.030096042901277542,
                -0.0756453275680542,
                -0.03106987662613392,
                0.046701569110155106,
                0.009836777113378048,
                -0.040572065860033035,
                -0.03144204616546631,
                0.012658528052270412,
                -0.008029027841985226,
                -0.00920864287763834,
                0.008665444329380989,
                -0.04446323588490486,
                0.03103042207658291,
                0.013103724457323551,
                -0.055571094155311584,
                0.008732082322239876,
                0.07598987966775894,
                0.034606896340847015,
                -0.009349741972982883,
                -0.0026148739270865917,
                0.032657064497470856,
                -0.02979990467429161,
                -0.0029348391108214855,
                0.010783633217215538,
                0.04679952561855316,
                -0.04017948731780052,
                -0.012897320091724396,
                -0.015778565779328346,
                0.01015130989253521,
                -0.044916171580553055,
                -0.0027845490258187056,
                -0.009831489063799381,
                0.0007307407795451581,
                0.0030774318147450686,
                0.017556725069880486,
                0.0018476995173841715,
                0.021178707480430603,
                0.010271945968270302,
                0.03450559079647064,
                -0.041616059839725494,
                0.01586439274251461,
                0.006079038139432669,
                -0.025819608941674232,
                0.05758708715438843,
                0.032099731266498566,
                0.04065487161278725,
                -0.01178793516010046,
                -0.023772574961185455,
                -0.0005074283690191805,
                -0.03358505293726921,
                0.002915953053161502,
                0.06915470957756042,
                0.014938048087060452,
                0.006959736812859774,
                -0.0024756526108831167,
                -0.033126894384622574,
                -0.010082238353788853,
                0.011644559912383556,
                -0.04427965730428696,
                0.006144126411527395,
                0.029720500111579895,
                -0.05586135759949684,
                -0.054767951369285583,
                -0.06779550015926361,
                0.06401913613080978,
                -0.007895618677139282,
                0.01969166286289692,
                0.02969937026500702,
                0.027155812829732895,
                0.06660084426403046,
                -0.007183296140283346,
                0.03659400716423988,
                -0.039510857313871384,
                0.004248521756380796,
                0.014803724363446236,
                -0.03534755855798721,
                -0.0395926758646965,
                -0.10326150059700012,
                0.04372057691216469,
                -0.09447097033262253,
                -0.0221053846180439,
                -0.013551359996199608,
                -0.0036038828548043966,
                0.021166736260056496,
                0.0870630219578743,
                0.022290565073490143,
                -0.021854670718312263,
                -0.11335167288780212,
                0.027649199590086937,
                -0.028969774022698402,
                -0.03145373985171318,
                0.002845168812200427,
                -0.035533469170331955,
                -0.03295627981424332,
                -0.01932569593191147,
                -0.016844090074300766,
                0.015758313238620758,
                0.0026685898192226887,
                0.012920885346829891,
                -0.053961604833602905,
                0.06691538542509079,
                -0.03318118676543236,
                -0.010688796639442444,
                0.034092120826244354,
                -0.019214795902371407,
                -0.007868039421737194,
                -0.034593526273965836,
                0.017471451312303543,
                0.0034564798697829247,
                0.023211073130369186,
                0.007516602054238319,
                0.03178032860159874,
                -0.01949259638786316,
                0.030371930450201035,
                -0.00617526238784194,
                0.028300834819674492,
                -0.005595077760517597,
                0.022397872060537338,
                0.013187455013394356,
                -0.0027649137191474438,
                -0.03789305314421654,
                0.024290863424539566,
                -0.0030908251646906137,
                -0.05802183598279953,
                0.012775982730090618,
                0.002353781135752797,
                -0.07711081206798553,
                -0.014544284902513027,
                -0.009495613165199757,
                -0.011887066066265106,
                0.02013307809829712,
                0.006113520823419094,
                0.004264786373823881,
                -0.017332959920167923,
                0.05151695758104324,
                -0.014851844869554043,
                0.0008488176390528679,
                -0.015042588114738464,
                -0.025082403793931007,
                0.007926618680357933,
                -0.01296057365834713,
                -0.051204532384872437,
                -0.025503626093268394,
                0.03111569955945015,
                -0.034889813512563705,
                0.14745494723320007,
                0.0025278956163674593,
                0.05273767188191414,
                -0.019130825996398926,
                0.0007600162061862648,
                -0.056073546409606934,
                0.0756399855017662,
                -0.023435693234205246,
                0.014996934682130814,
                0.029256589710712433,
                -0.0034715612418949604,
                0.011493176221847534,
                0.006242014467716217,
                -0.006225757300853729,
                0.006391547154635191,
                -0.006991156376898289,
                -0.047267597168684006,
                -0.08708024024963379,
                -0.047126706689596176,
                0.013405178673565388,
                -8.091111287702121e-33,
                -0.01887441612780094,
                -0.06527938693761826,
                0.0409819558262825,
                -0.03098941221833229,
                -0.018840668722987175,
                -0.026045583188533783,
                0.016417143866419792,
                0.03024667128920555,
                -0.056198712438344955,
                -0.02361106127500534,
                0.012397446669638157,
                0.007749639917165041,
                0.022876322269439697,
                -0.019448459148406982,
                0.06814558804035187,
                0.0338447242975235,
                0.03356081619858742,
                0.012952242977917194,
                -0.021666329354047775,
                -0.04899724945425987,
                -0.0017841177759692073,
                0.02903614565730095,
                0.0048904879949986935,
                0.046264197677373886,
                -0.02539403922855854,
                -0.013412028551101685,
                -0.014188261702656746,
                0.0072511108592152596,
                0.005702069960534573,
                0.011837957426905632,
                -0.008473950438201427,
                -0.05793985724449158,
                -0.0020642420276999474,
                -0.04285470023751259,
                0.01779249496757984,
                0.07182536274194717,
                -0.01112877856940031,
                -0.019216103479266167,
                -0.009090951643884182,
                -0.015501763671636581,
                -0.017323901876807213,
                -0.033766649663448334,
                -0.03816923871636391,
                -0.010074774734675884,
                0.025378961116075516,
                -0.06998468935489655,
                0.018140867352485657,
                0.010409238748252392,
                -0.028756428509950638,
                0.09958315640687943,
                -0.03900498151779175,
                0.015430898405611515,
                -0.0023434630129486322,
                -0.014288966543972492,
                0.046409863978624344,
                -0.047503143548965454,
                -0.014350950717926025,
                -0.03765428811311722,
                0.002403662307187915,
                0.016776980832219124,
                -0.06854917854070663,
                0.04091879725456238,
                -0.0168951153755188,
                0.03238629177212715,
                -0.07654497772455215,
                0.027140213176608086,
                -0.09104979038238525,
                0.05852311849594116,
                -0.002845012117177248,
                0.006122462917119265,
                -0.02978338859975338,
                0.10047896206378937,
                0.011994155123829842,
                -0.0763804093003273,
                -0.050007861107587814,
                0.009636870585381985,
                -0.01794721744954586,
                0.02047133445739746,
                0.04982997477054596,
                -0.06511130183935165,
                -0.03980905935168266,
                0.07845840603113174,
                0.0019474327564239502,
                0.014340637251734734,
                0.04027755185961723,
                -0.03590342774987221,
                -0.019371407106518745,
                0.010299481451511383,
                -0.014077074825763702,
                0.02982473373413086,
                -0.07462038844823837,
                0.0077295927330851555,
                -0.0014856731286272407,
                0.0030171647667884827,
                -0.03329717367887497,
                0.013037191703915596,
                0.02168741263449192,
                0.0038306766655296087,
                -0.009718862362205982,
                -0.03496471792459488,
                -0.07938884198665619,
                0.012801697477698326,
                -0.04661455377936363,
                0.04141496866941452,
                -0.018871041014790535,
                0.0386449471116066,
                -0.008376777172088623,
                -0.034228771924972534,
                -0.016490919515490532,
                -0.012386837974190712,
                0.03478261083364487,
                -0.010805494152009487,
                -0.03589140251278877,
                -0.11583256721496582,
                -0.001985174836590886,
                -0.01062286552041769,
                0.027673592790961266,
                0.005000296048820019,
                0.011922654695808887,
                -0.04150863736867905,
                0.05348917841911316,
                0.0993637889623642,
                -0.02613232471048832,
                -0.026365557685494423,
                -0.0025669217575341463,
                0.0027330750599503517,
                0.0083608478307724,
                0.004991428926587105,
                0.050975095480680466,
                -0.001795413438230753,
                -0.020448582246899605,
                -0.022888921201229095,
                2.6822681320481934e-07,
                0.01782108098268509,
                0.03206177055835724,
                0.024827107787132263,
                0.049719423055648804,
                -0.0020601977594196796,
                -0.028629982843995094,
                0.019158439710736275,
                0.016149157658219337,
                -0.054927434772253036,
                -0.02550920844078064,
                0.03529644384980202,
                0.037108417600393295,
                -0.00915462989360094,
                -0.00398725550621748,
                0.04457225650548935,
                -0.04683701694011688,
                0.027248870581388474,
                0.012513360939919949,
                -0.023497750982642174,
                -0.05729364976286888,
                -0.006638041697442532,
                0.005257251672446728,
                0.03446454927325249,
                0.004237942863255739,
                -0.04932541400194168,
                -0.0065681217238307,
                0.0023297828156501055,
                -0.0022722911089658737,
                0.04504874348640442,
                0.014172609895467758,
                0.01572594605386257,
                -0.046496450901031494,
                -0.047627631574869156,
                0.008993789553642273,
                0.019047962501645088,
                -0.004717257805168629,
                0.02277122065424919,
                0.009049645625054836,
                -0.0012576933950185776,
                0.07459007948637009,
                -0.003082018345594406,
                -0.018190981820225716,
                -0.020594026893377304,
                0.025857439264655113,
                0.010797540657222271,
                -0.012341871857643127,
                -0.0017807839903980494,
                0.05253692343831062,
                -0.03638584166765213,
                -0.009252665564417839,
                0.02413102425634861,
                0.03504947945475578,
                0.04187007248401642,
                0.06213289871811867,
                0.007245474029332399,
                -0.019329264760017395,
                0.02234550565481186,
                0.0032045685220509768,
                0.040878042578697205,
                0.041696447879076004,
                -0.018596090376377106,
                -0.0008785831741988659,
                -0.00601270142942667,
                -0.003990159370005131,
                0.0364823080599308,
                0.01232583075761795,
                -0.07724220305681229,
                8.537897290201109e-35,
                -0.030473148450255394,
                -0.01156019140034914,
                -0.0494537316262722,
                0.030454330146312714,
                0.03684107959270477,
                -0.029241733253002167,
                -0.030999133363366127,
                0.0064026773907244205,
                0.00026387503021396697,
                0.05669798329472542,
                0.0022818860597908497,
                0.03208990395069122,
                0.06187519058585167,
                -0.033957935869693756,
                -0.02028234489262104,
                0.01045618299394846,
                0.01858975924551487,
                -0.012608196586370468,
                -0.028463374823331833,
                -0.10083688050508499,
                0.05331563204526901,
                0.049518734216690063,
                0.035046063363552094,
                -0.026278076693415642,
                0.04499281570315361,
                -0.01669459231197834,
                -0.0766165554523468,
                0.018337495625019073,
                -0.03007485903799534,
                -0.007751181721687317,
                0.014431130141019821,
                0.001312998472712934,
                0.009494315832853317,
                -0.005465133115649223,
                0.02681160904467106,
                -0.03357281535863876,
                -0.0011126691242679954,
                -0.0328974574804306,
                0.0030853666830807924,
                0.03248082846403122,
                -0.026725338771939278,
                0.0028203825931996107,
                0.02342466078698635,
                -0.03421087935566902,
                -0.025930063799023628,
                1.6188499785130261e-06,
                0.0481349378824234,
                0.007736665196716785,
                -0.040277719497680664,
                -0.025562895461916924,
                0.06614158302545547,
                0.010857929475605488,
                -0.0196718480437994,
                0.023414524272084236,
                0.014774019829928875,
                -0.002179560484364629,
                0.001229269546456635,
                0.0506124310195446,
                0.04177908971905708,
                -0.007803361862897873,
                -0.07237352430820465,
                0.026359014213085175,
                0.025475427508354187,
                -0.08939142525196075,
                -0.04713260382413864,
                -0.008149327710270882,
                -0.07104215025901794,
                0.0022029471583664417,
                -0.014129690825939178,
                0.02886030822992325,
                0.04152737185359001,
                -0.0003251821326557547,
                -0.014835312962532043,
                0.00329066370613873,
                0.016459403559565544,
                0.04105055332183838,
                0.009437876753509045,
                0.06486502289772034,
                -0.021058574318885803,
                -0.0009955663699656725,
                -0.03341180086135864,
                0.025474267080426216,
                0.03610611334443092,
                -0.014535283669829369,
                -0.02333618700504303,
                0.001326852012425661,
                -0.0549575537443161,
                -0.008986681699752808,
                -0.004378207493573427,
                -0.009659390896558762,
                0.0005670859245583415,
                -0.028494009748101234,
                0.02739514224231243,
                -0.021296696737408638,
                0.007458865642547607,
                0.016446029767394066,
                0.015550973825156689,
                -0.03943731635808945,
                0.01710379868745804,
                -0.02778109908103943,
                0.04754886403679848,
                0.00734592555090785,
                -0.0139584606513381,
                0.02486604079604149,
                0.03627762943506241,
                -0.052640389651060104,
                -0.008157050237059593,
                0.002101612975820899,
                -0.013848965056240559,
                0.02340875379741192,
                -0.004960120655596256,
                -0.0376274399459362,
                0.01027617882937193,
                0.012589155696332455,
                0.05053647607564926,
                0.07906399667263031,
                0.04246358573436737,
                -0.03765489533543587,
                -0.035069458186626434,
                -0.03505373373627663,
                0.005048596765846014,
                -0.060440339148044586,
                0.010660831816494465,
                -0.01702333614230156,
                0.04485201835632324,
                0.0040457285940647125,
                0.01246770191937685,
                -0.04728653281927109,
                0.030319031327962875,
                4.619492756319232e-05,
                0.03352048620581627,
                0.040645960718393326,
                0.021410075947642326,
                -0.04265008121728897,
                0.01449158601462841,
                -0.04215133935213089,
                -0.008729378692805767,
                -0.051169101148843765,
                0.017516251653432846,
                -0.06499838829040527,
                0.07334113866090775,
                0.024316953495144844,
                -0.03740505874156952,
                -0.010935399681329727,
                -0.0017754699802026153,
                -0.008750551380217075,
                0.03963710367679596,
                -0.005113689694553614,
                0.015407465398311615,
                -0.06250516325235367,
                -0.002340370789170265,
                0.025970689952373505,
                0.020397672429680824,
                -0.07091300934553146,
                -0.025478340685367584,
                -0.039433542639017105,
                -0.02791212871670723,
                -0.08791300654411316,
                0.03244173899292946,
                0.010494432412087917,
                -0.008606615476310253,
                -0.01108805276453495,
                -0.026348570361733437,
                0.0032997371163219213,
                0.02923842892050743,
                0.05809006467461586,
                0.07125724852085114,
                -0.015773704275488853,
                -0.012094917707145214,
                -0.019805606454610825,
                0.04231508821249008,
                -0.01120375469326973,
                0.0425398126244545,
                0.07270161807537079,
                0.04446524381637573,
                -0.05319051444530487,
                0.003275379305705428,
                0.08606723695993423,
                -0.011635130271315575,
                -0.012365027330815792,
                -0.04815021902322769,
                -0.08658202737569809,
                0.040572620928287506,
                0.011457199230790138,
                0.09409988671541214,
                0.044868215918540955,
                -0.07586777210235596,
                0.040171995759010315,
                -0.01387398224323988,
                -0.06487317383289337,
                0.009271028451621532,
                -0.026707753539085388,
                -0.07976903766393661,
                0.032165154814720154,
                0.08330924808979034,
                0.06508784741163254,
                0.012809433043003082,
                -0.05661197379231453,
                0.02159484662115574,
                -0.0428452342748642,
                0.002965444466099143,
                0.021510504186153412,
                -0.026458872482180595,
                -0.09469558298587799,
                -0.0061955624260008335,
                0.027799034491181374,
                -0.006169733591377735,
                0.015055738389492035,
                -0.017696449533104897,
                -0.023975493386387825,
                0.017691656947135925,
                0.0014349832199513912,
                0.02082582749426365,
                0.02499569021165371,
                0.0016688610194250941,
                -0.04762475565075874,
                -0.08561155200004578,
                -0.051220815628767014,
                -0.001639181631617248,
                -0.060854990035295486,
                -0.0359518863260746,
                0.027073487639427185,
                -0.01789778470993042,
                -0.0036183062475174665,
                0.04557343199849129,
                -0.0190154816955328,
                0.043245088309049606,
                -0.001039331778883934,
                0.010260420851409435,
                -0.051386747509241104,
                0.03226708993315697,
                -0.03088502585887909,
                0.010440931655466557,
                0.013390600681304932,
                0.02788827195763588,
                0.010765588842332363,
                -0.03156760707497597,
                0.021942149847745895,
                -0.014106826856732368,
                0.018409602344036102,
                0.005541260354220867,
                0.021742982789874077,
                0.006801282055675983,
                0.054584018886089325,
                -0.03338349610567093,
                -0.01883942075073719,
                -0.008016201667487621,
                0.0649528056383133,
                0.042243264615535736,
                0.00272623379714787,
                -0.04431147500872612,
                -0.021241653710603714,
                -0.07740683853626251,
                -0.04580696299672127,
                -0.014613903127610683,
                0.066911980509758,
                0.01774921640753746,
                0.008431977592408657,
                0.025250902399420738,
                0.03736865147948265,
                -0.04562128335237503,
                -0.04781639575958252,
                -0.04243797808885574,
                0.0360802486538887,
                0.019917864352464676,
                0.016182631254196167,
                0.07044365257024765,
                -0.06826318800449371,
                0.012536733411252499,
                -0.020833538845181465,
                -0.0035467962734401226,
                0.07014694809913635,
                0.012572251260280609,
                -0.0023792805150151253,
                -0.023373600095510483,
                0.024487784132361412,
                -0.017939167097210884,
                -0.018838083371520042,
                -0.024105912074446678,
                -0.053793199360370636,
                0.01819700561463833,
                0.011940224096179008,
                -0.008542927913367748,
                0.024392658844590187,
                -0.018336383625864983,
                -0.048880547285079956,
                0.0016523398226127028,
                -0.026773253455758095,
                0.03667958080768585,
                -0.02598920464515686,
                0.021548504009842873,
                0.015564091503620148,
                0.01887640729546547,
                -0.06281020492315292,
                0.03520873561501503,
                0.021395083516836166,
                -0.010491868481040001,
                0.017458777874708176,
                -0.07976725697517395,
                -0.04739035293459892,
                0.022630475461483,
                -0.032766666263341904,
                -0.06921432912349701,
                0.035192664712667465,
                0.02413882128894329,
                -0.025476617738604546,
                -0.0032878059428185225,
                0.060035884380340576,
                -0.07879284024238586,
                -0.02534930221736431,
                0.013055738992989063,
                0.01510667521506548,
                -0.04479428380727768,
                0.023537542670965195,
                -0.04534991458058357,
                0.016122836619615555,
                0.010075419209897518,
                0.06572342664003372,
                0.015683025121688843,
                -0.0116981016471982,
                0.0006045311456546187,
                0.028536537662148476,
                0.03340316563844681,
                0.018637806177139282,
                0.05668367072939873,
                -0.03120187483727932,
                0.05251002311706543,
                0.0028838959988206625,
                -0.008729325607419014,
                0.032442279160022736,
                0.06518550217151642,
                -0.017443211749196053,
                0.015921136364340782,
                -0.003489085705950856,
                0.011221285909414291,
                0.026404349133372307,
                0.015351473353803158,
                -0.05357914790511131,
                -0.03961214795708656,
                0.0541045106947422,
                -0.03432970866560936,
                -0.014056125655770302,
                -0.035513442009687424,
                -0.028187820687890053,
                -0.022480757907032967,
                0.0026878167409449816,
                -0.014175615273416042,
                0.004432894755154848,
                -0.04462350904941559,
                -0.030063116922974586,
                0.005682086106389761,
                -0.021673325449228287,
                -0.019065886735916138,
                -0.04026348516345024,
                0.007182043511420488,
                -0.03583916276693344,
                0.01453458797186613,
                0.020068982616066933,
                0.06485619395971298,
                -0.003765863599255681,
                0.02687539905309677,
                0.03842553868889809,
                0.01611078344285488,
                -0.010808569379150867,
                -0.006687352433800697,
                0.00011804432870121673,
                -0.043246038258075714,
                -0.06306514143943787,
                0.003976167645305395,
                -0.0021474298555403948,
                -0.023565536364912987,
                -0.07313240319490433,
                -0.010487128980457783,
                0.04743654653429985,
                -0.029853202402591705,
                -0.023612452670931816,
                -0.017249951139092445,
                0.030375590547919273,
                0.019182216376066208,
                0.02692795731127262,
                0.0220890324562788,
                -0.044896841049194336,
                0.011935306712985039,
                0.006669218186289072,
                -0.00816288497298956,
                0.028048064559698105,
                0.0022477207239717245,
                0.008900834247469902,
                -0.009098327718675137,
                -0.04157411679625511,
                0.005249375943094492,
                0.00026583511498756707,
                0.022044610232114792,
                0.019007958471775055,
                -0.030355727300047874,
                -0.0038499110378324986,
                0.012819617055356503,
                -0.024790218099951744,
                -0.005804451182484627,
                0.06471829861402512,
                0.021019039675593376,
                -0.02534462697803974,
                0.003423877526074648,
                0.006086477544158697,
                0.033601969480514526,
                0.03768696263432503,
                -0.03550628200173378,
                -0.06588364392518997,
                0.011284738779067993,
                0.0450906828045845,
                -0.00355461030267179,
                0.003028011880815029,
                -0.012440393678843975,
                0.03231918066740036,
                -0.008109715767204762,
                -0.04561762139201164,
                -0.055519949644804,
                0.08596912026405334,
                0.006486996542662382,
                -0.0056798397563397884,
                0.01734462007880211,
                -0.012870199047029018,
                0.011075001209974289,
                -0.0157296285033226,
                -0.013922193087637424,
                0.04533941671252251,
                0.004672557581216097,
                -0.0364333875477314,
                0.022885244339704514,
                -0.04902627691626549,
                -0.04624570533633232,
                0.01805914007127285,
                0.048080507665872574,
                0.024172717705368996,
                -0.036997899413108826,
                0.06061722710728645,
                -0.007688992191106081,
                -0.00767663074657321,
                0.02907201275229454,
                -0.013156705535948277,
                -0.010987523943185806,
                -0.032444264739751816,
                0.017381714656949043,
                -0.0033689767587929964,
                0.051930539309978485,
                0.055339664220809937,
                0.05642728507518768,
                0.006968607660382986,
                -0.019806986674666405,
                0.0893481895327568,
                0.0019006725633516908,
                0.013196422718465328,
                0.09992476552724838,
                0.052002761512994766,
                0.02391895465552807,
                -0.008022070862352848,
                0.026253538206219673,
                0.0014587721088901162,
                -0.02504795975983143,
                -0.11165529489517212,
                0.07154464721679688,
                0.035397548228502274,
                0.007495129480957985,
                -0.03778716176748276,
                -0.022537117823958397,
                -0.004786525387316942,
                0.022658657282590866,
                -0.03420066833496094,
                -0.05252423882484436,
                0.010848330333828926,
                -0.03013613261282444,
                0.06678637117147446,
                0.02879154495894909,
                -0.11949280649423599,
                0.0017202653689309955,
                -0.027221666648983955,
                -0.036213457584381104,
                -0.04383708909153938,
                0.012198150157928467,
                0.010660061612725258,
                -0.043194256722927094,
                0.05244578421115875,
                -0.03007497638463974,
                -0.018892107531428337,
                0.007015611510723829,
                0.023828735575079918,
                0.00932789221405983,
                0.03900428116321564,
                -0.06713448464870453,
                -0.0037923746276646852,
                0.002559267682954669,
                -0.011990874074399471,
                0.030987003818154335,
                -0.004278199747204781,
                -0.08088298887014389,
                -0.017404887825250626,
                0.0020667766220867634,
                0.02685476280748844,
                -0.0035527674481272697,
                -0.0015452944207936525,
                -0.0005717036547139287,
                0.0117404256016016,
                -0.01587965153157711,
                0.01438447367399931,
                0.006761892233043909,
                0.007735620252788067,
                -0.01636294089257717,
                0.034440185874700546,
                -0.00965727400034666,
                0.039973724633455276,
                -0.04874696210026741,
                0.008966405875980854,
                0.04138055443763733,
                -0.01618286408483982,
                -0.049418941140174866,
                0.0020089754834771156,
                -0.017978079617023468,
                -0.006600161083042622,
                0.008763769641518593,
                -0.03276795148849487,
                0.011085518635809422,
                -0.0145194036886096,
                -0.029460875317454338,
                0.030200297012925148,
                -0.08226223289966583,
                0.06653930246829987,
                0.04606220871210098,
                0.012544872239232063,
                0.04267186298966408,
                -0.02284502424299717,
                -0.03974508121609688,
                0.023163817822933197,
                -0.027218418195843697,
                0.0013504724483937025,
                -0.0218153428286314,
                0.05729028582572937,
                -0.017108609899878502,
                0.02740415558218956,
                -0.020335279405117035,
                -0.021483322605490685,
                0.023842262104153633,
                -0.0032622963190078735,
                -0.06152493506669998,
                -0.11012791842222214,
                0.010893156751990318,
                -0.008346738293766975,
                0.08030878007411957,
                0.004160693846642971,
                0.05471495911478996,
                -0.02648613229393959,
                0.002679153112694621,
                -0.050316065549850464,
                0.05267136171460152,
                0.007847779430449009,
                -0.0015847011236473918,
                0.014018921181559563,
                -0.06598485261201859,
                -0.02351682260632515,
                -0.007140382658690214,
                0.010879343375563622,
                0.032408151775598526,
                -0.010992305353283882,
                -0.03648783266544342,
                0.0010503977537155151,
                0.02866598218679428,
                -0.027972163632512093,
                -5.683616205271534e-33,
                -0.05298333615064621,
                -0.0688483789563179,
                0.03901948407292366,
                0.029863623902201653,
                0.009182602167129517,
                0.0015202785143628716,
                0.024961672723293304,
                0.04113603010773659,
                -0.01216693501919508,
                -0.05290563404560089,
                -0.001556790666654706,
                0.004131094552576542,
                0.02193627879023552,
                -0.008446070365607738,
                0.10536891222000122,
                0.04360052943229675,
                0.05317624285817146,
                0.023999571800231934,
                0.02665325254201889,
                -0.038025062531232834,
                -0.0232936330139637,
                0.022868050262331963,
                0.03162243589758873,
                0.06746840476989746,
                -0.014119207859039307,
                0.012679269537329674,
                0.020421721041202545,
                0.023443952202796936,
                0.0699121430516243,
                0.006362713873386383,
                0.00874320324510336,
                -0.04861536622047424,
                0.005716544575989246,
                -0.012018447741866112,
                -0.0006204135715961456,
                0.039036825299263,
                -0.008112738840281963,
                -0.023955106735229492,
                -0.03183998167514801,
                -0.016718586906790733,
                0.02718801237642765,
                0.022625381126999855,
                -0.015232361853122711,
                0.015149010345339775,
                -0.018174713477492332,
                -0.002921925624832511,
                0.009503892622888088,
                -0.0016216172371059656,
                0.00025291703059338033,
                0.06089138984680176,
                -0.04452301189303398,
                0.009181701578199863,
                -0.018199682235717773,
                0.03272561356425285,
                0.022421590983867645,
                -0.008497750386595726,
                -0.01080982107669115,
                0.04400359094142914,
                0.008268051780760288,
                0.010663234628736973,
                -0.07613397389650345,
                0.012673260644078255,
                0.0016278792172670364,
                0.006986299064010382,
                -0.04050757363438606,
                0.0029265566263347864,
                -0.044312044978141785,
                0.04325136914849281,
                -0.02874043583869934,
                -0.033437762409448624,
                -0.04565020278096199,
                0.08388998359441757,
                0.027731552720069885,
                -0.03751244768500328,
                -0.08409304171800613,
                0.010290783829987049,
                0.0035743515472859144,
                0.006550181191414595,
                0.07556717842817307,
                0.03304038196802139,
                -0.017003480345010757,
                0.07438560575246811,
                0.03386974334716797,
                -0.00885019265115261,
                0.017095545306801796,
                -0.06957782059907913,
                -0.01700502261519432,
                0.07555899769067764,
                0.006883630994707346,
                0.02523934841156006,
                -0.03240320459008217,
                0.053565338253974915,
                0.010533110238611698,
                0.02294366993010044,
                0.03587792441248894,
                0.03026638552546501,
                -0.016939710825681686,
                0.02424035780131817,
                -0.025388101115822792,
                0.028210468590259552,
                -0.05083364620804787,
                0.0351799875497818,
                -0.06888963282108307,
                -0.004754296038299799,
                -0.011278503574430943,
                -0.005466071888804436,
                -0.039434220641851425,
                -0.05520511046051979,
                -0.00544803636148572,
                0.0366014800965786,
                0.012286779470741749,
                -0.005768319591879845,
                -0.041899777948856354,
                -0.03406507894396782,
                -0.025926128029823303,
                -0.046543918550014496,
                0.023737406358122826,
                -0.03423314169049263,
                0.003670712234452367,
                -0.04141538217663765,
                -0.028419509530067444,
                0.017367471009492874,
                -0.027355602011084557,
                -0.022332211956381798,
                0.014980842359364033,
                -0.04135431349277496,
                0.006670400500297546,
                0.019313303753733635,
                0.04674111679196358,
                -0.01393807865679264,
                -0.01284155435860157,
                0.020780909806489944,
                2.2358189255555772e-07,
                -0.00642681960016489,
                0.03819578140974045,
                0.040856897830963135,
                0.04203781485557556,
                0.021065838634967804,
                0.03403599187731743,
                -0.022078268229961395,
                -0.031434327363967896,
                -0.017998313531279564,
                0.006782860960811377,
                0.049865201115608215,
                -0.029474565759301186,
                -0.001676215440966189,
                0.03722574561834335,
                0.04511256143450737,
                -0.12294664978981018,
                -0.014730163849890232,
                -0.008261250331997871,
                -0.06514047831296921,
                -0.03310244902968407,
                0.006858536507934332,
                0.03329465538263321,
                0.05817826837301254,
                -0.00011494427599245682,
                -0.005012588109821081,
                -0.016877438873052597,
                -0.010617809370160103,
                0.01905280165374279,
                0.03127400949597359,
                0.02953376993536949,
                0.02809394896030426,
                -0.01801225170493126,
                -0.039969246834516525,
                0.03358609601855278,
                0.03840290382504463,
                -0.08022067695856094,
                0.0517033189535141,
                0.022783836349844933,
                0.025440439581871033,
                0.02000313624739647,
                0.012695005163550377,
                -0.021539079025387764,
                -0.03278366103768349,
                0.05888742208480835,
                -0.06474548578262329,
                0.0025522587820887566,
                -0.0021004751324653625,
                0.06127573549747467,
                0.028266219422221184,
                -0.0023947099689394236,
                0.03980524092912674,
                0.042772747576236725,
                0.0537005178630352,
                0.00883602723479271,
                -0.013674202375113964,
                -0.029283246025443077,
                -0.04297828674316406,
                0.010521719232201576,
                0.054890040308237076,
                0.029747098684310913,
                -0.04167797788977623,
                -0.007549756206572056,
                -0.018549760803580284,
                0.008701415732502937,
                -0.003312797052785754,
                0.03573498874902725,
                -0.008067155256867409,
                4.942453241753658e-35,
                -0.030651070177555084,
                -0.05562421306967735,
                -0.05893056094646454,
                -0.04055287688970566,
                -0.03378883749246597,
                0.013660525903105736,
                -0.08730491995811462,
                -0.0156480073928833,
                -0.010841675102710724,
                0.018977338448166847,
                -0.025762176141142845,
                0.06009536236524582,
                0.018063735216856003,
                -0.03332475572824478,
                0.03142069652676582,
                0.01862500235438347,
                0.023912448436021805,
                -0.046576306223869324,
                0.007187385577708483,
                0.012267397716641426,
                0.033629707992076874,
                0.012561481446027756,
                -0.04866931214928627,
                0.01950247958302498,
                -0.005602862685918808,
                0.030310506001114845,
                -0.07581858336925507,
                0.0018244866514578462,
                -0.021303584799170494,
                0.02963273413479328,
                0.0044008586555719376,
                0.0020072103943675756,
                0.028586795553565025,
                -0.0021065270993858576,
                -0.010250850580632687,
                -0.006336864549666643,
                -0.018396837636828423,
                0.0031796449329704046,
                0.0336189828813076,
                0.0006496526766568422,
                0.01858007349073887,
                0.013255752623081207,
                -0.02898586541414261,
                -0.028108585625886917,
                -0.0985439345240593,
                1.7017455320456065e-06,
                0.03305341303348541,
                -0.0029413884039968252,
                -0.06115688756108284,
                0.019090838730335236,
                -0.011425089091062546,
                0.045755766332149506,
                0.06993713974952698,
                -0.014264283701777458,
                0.029185015708208084,
                0.002610574709251523,
                0.10641420632600784,
                0.004340243525803089,
                0.06639467179775238,
                0.050251107662916183,
                0.032823510468006134,
                0.017571769654750824,
                0.028057025745511055,
                -0.02528426982462406,
                -0.018660681322216988,
                -0.07835415750741959,
                -0.025192594155669212,
                -0.0037778764963150024,
                -0.009955977089703083,
                -0.004244046751409769,
                0.02090448886156082,
                -0.02712930366396904,
                -0.044433217495679855,
                -0.04981137812137604,
                -0.0040967646054923534,
                0.005541096441447735,
                -0.003862312762066722,
                -0.03363880515098572,
                -0.026068245992064476,
                0.01648803986608982,
                0.007131831254810095,
                -0.032296184450387955,
                0.05936683341860771,
                0.0317612923681736,
                0.021389244124293327,
                0.04511258006095886,
                0.00042424839921295643,
                0.010740509256720543,
                -0.004336190409958363,
                0.02225618250668049,
                0.010752246715128422,
                0.0033592188265174627,
                0.02597774937748909,
                -0.034084949642419815,
                0.009637545794248581,
                0.0670415610074997,
                0.0014922701520845294,
                -0.004894830286502838,
                -0.0009570452966727316,
                -0.03917509317398071,
                0.04096737504005432,
                -0.034357111901044846,
                -0.03261812403798103,
                -0.01283494383096695,
                0.03536004573106766,
                -0.017203204333782196,
                -0.027595624327659607,
                -0.00332462415099144,
                0.038121387362480164,
                -0.01744612120091915,
                0.04577269032597542,
                0.025843454524874687,
                0.007732053752988577,
                -0.011527598835527897,
                -0.022022591903805733,
                0.00047542492393404245,
                0.03196118399500847,
                -0.0013545660767704248,
                0.02110186591744423,
                -0.045210711658000946,
                0.02042795903980732,
                -0.03990555182099342,
                0.026074809953570366,
                0.017044056206941605,
                0.014908148907124996,
                -0.017354846000671387,
                -0.011030110530555248,
                -0.038432493805885315,
                -0.022050591185688972,
                -0.005380040034651756,
                0.03183590620756149,
                -0.015385815873742104,
                0.0019711998756974936,
                -0.017684733495116234,
                0.017656514421105385,
                0.020090529695153236,
                0.044064391404390335,
                -0.03983131796121597,
                0.014028276316821575,
                0.011641056276857853,
                -0.002895739860832691,
                0.02952686697244644,
                0.010845511220395565,
                0.014837446622550488,
                0.0471138060092926,
                0.07856051623821259,
                0.012392895296216011,
                -0.02356543205678463,
                0.049418315291404724,
                -0.06002265587449074,
                0.026546329259872437,
                -0.022099168971180916,
                -0.04339948296546936,
                -0.02088114619255066,
                -0.016146110370755196,
                -0.028518835082650185,
                -0.0664479061961174,
                0.0033678847830742598,
                0.028453782200813293,
                0.05405575782060623,
                0.014823775738477707,
                0.0036281717475503683,
                -0.026703352108597755,
                0.030944569036364555,
                -0.009985051117837429,
                0.05334410071372986,
                0.02880171686410904,
                0.03859882056713104,
                0.030399179086089134,
                -0.028285754844546318,
                0.011540965177118778,
                -0.0007773344987072051,
                0.032549623399972916,
                0.020310690626502037,
                0.006206701509654522,
                0.03858987241983414,
                0.03965861722826958,
                0.05267487093806267,
                -0.008986229076981544,
                -0.005065699573606253,
                0.03140411525964737,
                -0.06809326261281967,
                0.06298446655273438,
                -0.03765600919723511,
                0.0539771169424057,
                0.011659604497253895,
                0.03154484182596207,
                0.04267869144678116,
                -0.06941038370132446,
                -0.040088459849357605,
                -0.01086210273206234,
                -0.007449764758348465,
                -0.1149301677942276,
                -0.019304964691400528,
                -0.036205630749464035,
                0.07506226003170013,
                0.03143247961997986,
                -0.029467031359672546,
                0.0678839460015297,
                -0.037190958857536316,
                -0.02423207089304924,
                0.0067414576187729836,
                0.019813962280750275,
                -0.08105246722698212,
                0.030189499258995056,
                -0.00028192708850838244,
                -0.007782012224197388,
                -0.04531526938080788,
                -0.05592832341790199,
                -0.044013604521751404,
                -0.04977419227361679,
                0.045187197625637054,
                0.02476770244538784,
                -0.06403880566358566,
                -0.006428385153412819,
                -0.03010263293981552,
                0.02034902200102806,
                0.016049779951572418,
                -0.03904801234602928,
                0.01689336635172367,
                -0.030123738572001457,
                0.017621267586946487,
                0.009179982356727123,
                0.03378800302743912,
                -3.6788238503504544e-05,
                0.011594918556511402,
                0.030228648334741592,
                0.004779054317623377,
                0.010317973792552948,
                -0.0071010165847837925,
                0.029528219252824783,
                -0.03723979741334915,
                0.06613073498010635,
                -0.04659396409988403,
                0.01933048479259014,
                0.02127222716808319,
                -0.04899091646075249,
                -0.009170222096145153,
                -0.034445323050022125,
                0.018252259120345116,
                0.05326066538691521,
                -0.008821162395179272,
                0.009140608832240105,
                -0.026990076526999474,
                0.023903658613562584,
                -0.04380251094698906,
                -0.06458146870136261,
                0.006374564953148365,
                0.019958214834332466,
                0.03854543715715408,
                -0.03635638952255249,
                -0.05868731439113617,
                -0.006273611448705196,
                0.01988062635064125,
                -0.006895659491419792,
                0.02900467813014984,
                0.020385054871439934,
                0.014456079341471195,
                0.01696084626019001,
                0.0003886974591296166,
                -0.023976748809218407,
                -0.005401506554335356,
                0.011403204873204231,
                -0.016495496034622192,
                0.001451682299375534,
                -0.021386945620179176,
                0.102351613342762,
                0.041456423699855804,
                -0.003034977475181222,
                -0.008270465768873692,
                0.017215978354215622,
                0.013398089446127415,
                -0.004222806077450514,
                -0.03094789944589138,
                0.026832040399312973,
                0.04820118099451065,
                -0.05523211881518364,
                0.008443974889814854,
                0.007158239837735891,
                -0.007637899834662676,
                -0.020672563463449478,
                0.008913951925933361,
                -0.010025674477219582,
                -0.0030520777218043804,
                -0.025226732715964317,
                0.003532000118866563,
                -0.007580325938761234,
                -0.09084755182266235,
                0.07020027935504913,
                -0.02416849322617054,
                2.0946019503753632e-05,
                0.016405152156949043,
                0.014621663838624954,
                -0.03971882537007332,
                -0.011748915538191795,
                0.023171070963144302,
                -0.017657799646258354,
                0.008620502427220345,
                0.0081313606351614,
                -0.006133946590125561,
                -0.0139554962515831,
                -0.018923964351415634,
                0.0026138124521821737,
                0.006394680123776197,
                -0.05595194175839424,
                0.0214824378490448,
                0.009236994199454784,
                0.08853793889284134,
                0.00012156504817539826,
                -0.007433404214680195,
                0.07096436619758606,
                -0.002104563405737281,
                -0.04007141292095184,
                0.049362070858478546,
                -0.005255240481346846,
                -0.015334741212427616,
                -0.03318318724632263,
                -0.03323531150817871,
                0.012499213218688965,
                0.00026961523690260947,
                -0.01820056326687336,
                0.058471210300922394,
                -0.0068403687328100204,
                0.01853199675679207,
                0.013481071218848228,
                -0.02843252755701542,
                0.0058083380572497845,
                -0.021525612100958824,
                -0.02181384153664112,
                0.031677428632974625,
                0.027870718389749527,
                0.008693664334714413,
                0.030619416385889053,
                -0.03093140758574009,
                -0.01232973299920559,
                -0.0036110414657741785,
                0.004214564338326454,
                -0.057653579860925674,
                -0.02318941056728363,
                -0.005692837759852409,
                -0.031222643330693245,
                -0.011460495181381702,
                -0.002155185677111149,
                -0.07682511955499649,
                -0.006848794408142567,
                0.0054293363355100155,
                0.028414275497198105,
                -0.037346746772527695,
                -0.07855703681707382,
                -0.005217829719185829,
                -0.009505804628133774,
                0.020533766597509384,
                0.03768068179488182,
                -0.04344840347766876,
                0.09783513098955154,
                -0.025326458737254143,
                -0.0031260442920029163,
                0.04471556842327118,
                0.060866110026836395,
                -0.06412722170352936,
                -0.06535989791154861,
                0.03461214900016785,
                -0.03454941511154175,
                -0.015648813918232918,
                -0.021343417465686798,
                0.01604294963181019,
                -0.01908518187701702,
                -0.028177183121442795,
                -0.011497545056045055,
                -0.011054441332817078,
                -0.06051194667816162,
                -0.023751141503453255,
                0.01923825591802597,
                0.04288754612207413,
                -0.022916607558727264,
                0.028220104053616524,
                0.027386551722884178,
                0.03192948177456856,
                -0.01098595466464758,
                -0.013055489398539066,
                0.04976392537355423,
                0.013479789718985558,
                0.04233592003583908,
                -0.0452413484454155,
                0.019907064735889435,
                0.021022900938987732,
                0.022598594427108765,
                0.004244663752615452,
                -0.027437949553132057,
                -0.007066270802170038,
                -0.007162885740399361,
                0.035215333104133606,
                0.0027871448546648026,
                0.028368672356009483,
                -0.022216711193323135,
                0.0009749705786816776,
                -0.029144279658794403,
                0.020374855026602745,
                -0.03208615630865097,
                0.01116435881704092,
                0.004052870906889439,
                0.022268498316407204,
                0.06752842664718628,
                -0.10167039930820465,
                0.05238103121519089,
                -0.04257265478372574,
                -0.0021564846392720938,
                -0.0012213692534714937,
                -0.007840421050786972,
                -0.02583596669137478,
                0.034097008407115936,
                0.05230220407247543,
                0.023491568863391876,
                0.0002138729760190472,
                -0.002724620047956705,
                -0.026366645470261574,
                -0.007457887288182974,
                0.10564318299293518,
                -0.0085564274340868,
                0.010824955999851227,
                0.044346489012241364,
                -0.07842910289764404,
                -0.03422478586435318,
                -0.01768595539033413,
                0.04687213897705078,
                -0.011179057881236076,
                -0.07226429134607315,
                -0.017827197909355164,
                -0.028094163164496422,
                0.06760977953672409,
                -0.01428790483623743,
                0.021567702293395996,
                0.028401335701346397,
                0.0005039797979407012,
                0.012322420254349709,
                0.00427996926009655,
                -0.01537406351417303,
                0.061109352856874466,
                0.0340552031993866,
                -0.027202175930142403,
                -0.03293207660317421,
                0.03225771710276604,
                0.014562400989234447,
                0.0014408075949177146,
                0.0486271008849144,
                0.07153461128473282,
                -0.12863481044769287,
                -0.01844657026231289,
                0.022960824891924858,
                0.15317481756210327,
                0.011971034109592438,
                0.009646326303482056,
                0.07170872390270233,
                0.006149467080831528,
                -0.004100988153368235,
                0.02902504801750183,
                -0.05945954844355583,
                -0.026391716673970222,
                0.027680331841111183,
                -0.041256461292505264,
                0.010871045291423798,
                0.030642444267868996,
                -0.04074883460998535,
                -0.019930565729737282,
                -0.04594828933477402,
                0.024429073557257652,
                -0.00809691846370697,
                -0.027115806937217712,
                -0.08161155879497528,
                -0.003083053044974804,
                -0.004930899944156408,
                -0.029445169493556023,
                0.012164618819952011,
                0.011583337560296059,
                0.07041306793689728,
                0.02331542782485485,
                -0.04827406629920006,
                0.006516937166452408,
                0.029509441927075386,
                0.01752280630171299,
                -0.05632747709751129,
                0.08204282075166702,
                0.020702967420220375,
                -0.015235875733196735,
                0.012003092095255852,
                0.07469257712364197,
                0.016315821558237076,
                -0.010830190032720566,
                0.014029765501618385,
                0.06225615367293358,
                -0.0010283965384587646,
                -0.008411621674895287,
                -0.0015996989095583558,
                -0.00465773930773139,
                -0.032920632511377335,
                -0.02072812430560589,
                -0.024411555379629135,
                -0.03971528634428978,
                -0.02973582223057747,
                -0.0016869757091626525,
                -0.010967131704092026,
                -0.009840610437095165,
                0.010907880030572414,
                0.013962890021502972,
                -0.05460001155734062,
                -0.009814686141908169,
                -0.0016027819365262985,
                -0.04469115287065506,
                0.04117623716592789,
                0.04318301007151604,
                -0.017176462337374687,
                -0.00591731071472168,
                0.002004804788157344,
                0.0021230161655694246,
                -0.03261338546872139,
                0.004937278106808662,
                -0.010743396356701851,
                -0.0054246727377176285,
                0.022886766120791435,
                0.004248920828104019,
                -0.01125548966228962,
                0.013178885914385319,
                -0.031164685264229774,
                -0.012927225790917873,
                -0.08306176960468292,
                -0.011043276637792587,
                0.03830689936876297,
                -0.020224668085575104,
                -0.015274018980562687,
                -0.043852921575307846,
                -0.03652036190032959,
                0.032571617513895035,
                0.04037607088685036,
                0.00023127674649003893,
                -0.04235827177762985,
                0.007574798073619604,
                0.03333674743771553,
                0.0067518954165279865,
                0.003750681644305587,
                0.022402890026569366,
                -0.048556920140981674,
                0.03204573318362236,
                -0.02914893999695778,
                -0.026451660320162773,
                0.04256030172109604,
                0.007079459726810455,
                0.038410305976867676,
                0.003880169242620468,
                0.07980808615684509,
                0.039237577468156815,
                0.04571571201086044,
                -0.017688332125544548,
                0.02504122629761696,
                0.0034544768277555704,
                0.015407925471663475,
                -0.002268239390105009,
                -0.014821638353168964,
                -0.005977865308523178,
                -0.0223066583275795,
                -0.01128255296498537,
                -0.020749086514115334,
                -0.02903437800705433,
                -0.029974650591611862,
                0.007738173473626375,
                -0.0037629033904522657,
                0.002432732144370675,
                -5.429066577020814e-33,
                0.021797362715005875,
                -0.028755581006407738,
                0.051380500197410583,
                -0.007214698474854231,
                -0.08752959221601486,
                0.003974298946559429,
                0.044529639184474945,
                0.0043653203174471855,
                -0.050847526639699936,
                -0.019571125507354736,
                -0.04147742688655853,
                0.026183005422353745,
                0.0022486881352961063,
                0.01888275146484375,
                0.0508563332259655,
                -0.007368116173893213,
                -0.006879445165395737,
                0.0006874495884403586,
                -0.003159091342240572,
                -0.027978140860795975,
                -0.06281904131174088,
                0.020620647817850113,
                0.03596070781350136,
                0.050817426294088364,
                0.022922176867723465,
                -0.03512243926525116,
                -0.02502008154988289,
                -0.0031187382992357016,
                0.04131355136632919,
                -0.037918295711278915,
                -0.005548269487917423,
                -0.047305628657341,
                -0.028394876047968864,
                -0.055346135050058365,
                -0.00663524866104126,
                0.036189887672662735,
                0.0065786452032625675,
                -0.03446566313505173,
                -0.022641494870185852,
                -0.01646447367966175,
                -0.04005051031708717,
                -0.023593489080667496,
                0.0024570391979068518,
                -0.03213309124112129,
                0.06519895792007446,
                -0.0727599710226059,
                -0.0003810545022133738,
                0.03972211852669716,
                0.0206350926309824,
                0.040238477289676666,
                -0.06641855090856552,
                0.007299999240785837,
                -0.03495211526751518,
                0.05615708976984024,
                -0.009313181973993778,
                0.024612320587038994,
                -0.03543764725327492,
                -0.020585790276527405,
                -0.02244645170867443,
                -0.019678650423884392,
                0.03668983653187752,
                0.06850937753915787,
                0.003957937937229872,
                0.051545556634664536,
                -0.08732961863279343,
                0.01528009120374918,
                -0.03840848058462143,
                0.02379942685365677,
                0.00436605978757143,
                -0.028179071843624115,
                -0.0267792996019125,
                0.09631246328353882,
                -0.005220573395490646,
                -0.029311975464224815,
                -0.09262678027153015,
                0.016941934823989868,
                0.0003378360124770552,
                -0.03587943688035011,
                0.10088904201984406,
                -0.02923869900405407,
                -0.09421170502901077,
                0.024756938219070435,
                -0.03647651895880699,
                -0.011782050132751465,
                -0.008778640069067478,
                -0.06640929728746414,
                -0.024365371093153954,
                0.033388152718544006,
                0.03702545911073685,
                0.009288898669183254,
                -0.018112804740667343,
                -0.0010304233292117715,
                0.006133293733000755,
                0.07009269297122955,
                0.021129321306943893,
                -0.011257939040660858,
                -0.007158313877880573,
                0.03986433893442154,
                -0.0021665028762072325,
                0.006539681926369667,
                -0.07288391888141632,
                -0.00014769041445106268,
                -0.00022248133609537035,
                -0.012799101881682873,
                -0.016357546672225,
                -0.02273251861333847,
                -0.08436188101768494,
                -0.03519779071211815,
                -0.025696853175759315,
                -0.018265357241034508,
                0.018818216398358345,
                0.02872110903263092,
                -0.002122531644999981,
                -0.1377478986978531,
                -0.052074600011110306,
                -0.0008744546212255955,
                0.0005273044807836413,
                0.06481534987688065,
                -0.009856254793703556,
                -0.05121545493602753,
                0.036251094192266464,
                0.004424452316015959,
                0.030325477942824364,
                -0.03741171956062317,
                -0.041565265506505966,
                -0.03024805523455143,
                -0.027295351028442383,
                0.06221863627433777,
                -0.0013239230029284954,
                -0.03520456701517105,
                0.006098793353885412,
                -0.0021290017757564783,
                2.2650216635611287e-07,
                0.03265954554080963,
                0.04092166945338249,
                0.0222292710095644,
                0.00504612922668457,
                -0.00723829073831439,
                -0.0036854033824056387,
                0.0024420861154794693,
                0.02292175590991974,
                0.024652626365423203,
                0.07404181361198425,
                0.03833571821451187,
                -0.010629544965922832,
                -0.04353319853544235,
                0.009814773686230183,
                0.048945557326078415,
                -0.13566765189170837,
                -0.04168310388922691,
                -0.05644923821091652,
                -0.0414956659078598,
                -0.051831360906362534,
                0.037983570247888565,
                0.01651747152209282,
                0.04132265970110893,
                0.012224708683788776,
                -0.0071581508964300156,
                0.08414965122938156,
                0.0007599260425195098,
                0.02296527475118637,
                0.04488736763596535,
                0.04318446293473244,
                0.035229116678237915,
                -0.02641647681593895,
                -0.01499046292155981,
                0.02459157630801201,
                -0.010804645717144012,
                -0.0166468508541584,
                0.02975304052233696,
                0.0313250869512558,
                0.006427125073969364,
                0.03212699666619301,
                -0.013796191662549973,
                0.03891822323203087,
                -0.04803631827235222,
                0.05346078425645828,
                0.018894007429480553,
                -0.04530812054872513,
                -0.026689406484365463,
                0.00015198996698018163,
                -0.07381076365709305,
                -0.0013615370262414217,
                -0.006920541636645794,
                0.054926492273807526,
                -0.005433294922113419,
                0.07980667054653168,
                -0.007180018816143274,
                0.014214553870260715,
                -0.006721751764416695,
                0.05512562394142151,
                0.06138991937041283,
                -0.05787449702620506,
                0.015535515733063221,
                -0.026811320334672928,
                -0.020408451557159424,
                0.018789295107126236,
                -0.024557381868362427,
                -0.005393868312239647,
                -0.04712151736021042,
                8.009868442054103e-35,
                -0.022815711796283722,
                -0.05273483321070671,
                0.008598665706813335,
                -0.028513368219137192,
                0.015126590617001057,
                -0.03132262080907822,
                -0.053307343274354935,
                -0.0031791573856025934,
                0.008892221376299858,
                0.016973480582237244,
                -0.04104916378855705
            ]
        },
        "Values": {
            "name": [
                "Dicipline ",
                "Punctuality",
                "Teamwork",
                "Responsibility",
                "Humility"
            ],
            "rep": [
                0.03719009831547737,
                -0.020609544590115547,
                -0.0013265423476696014,
                0.01348022185266018,
                -0.01256258599460125,
                0.004450023174285889,
                0.014071558602154255,
                -0.01738378405570984,
                -0.03410349413752556,
                0.04838709160685539,
                0.07345616072416306,
                -0.005057288333773613,
                0.023070115596055984,
                -0.05291792005300522,
                -0.02292461134493351,
                -0.07072312384843826,
                -0.013076042756438255,
                -0.00985411275178194,
                0.012263590469956398,
                -0.023434821516275406,
                0.0014105658046901226,
                -0.009466230869293213,
                0.010571093298494816,
                0.013456188142299652,
                0.032374244183301926,
                -0.06979577988386154,
                0.013426762074232101,
                0.010904801078140736,
                -0.002252033678814769,
                0.0310111902654171,
                0.09188476204872131,
                0.04231394827365875,
                0.002362258965149522,
                -0.035699449479579926,
                1.8428754628985189e-06,
                0.001340100890956819,
                0.02210300602018833,
                -0.022407574579119682,
                0.003744119079783559,
                -0.03395817056298256,
                -0.03194187209010124,
                -0.003937914501875639,
                0.01655365526676178,
                -0.002370846923440695,
                -0.03175131231546402,
                0.00857139565050602,
                0.018357835710048676,
                -0.03383738920092583,
                0.03258576989173889,
                -0.031253308057785034,
                0.02986963465809822,
                -0.06306494772434235,
                -0.05446334183216095,
                -0.0563054084777832,
                -0.0337708406150341,
                0.017500633373856544,
                -0.022982079535722733,
                0.02451370283961296,
                0.005032785702496767,
                -0.023331820964813232,
                -0.006286832969635725,
                -0.05421317741274834,
                -0.0226315688341856,
                -0.002310890471562743,
                -0.0021126624196767807,
                -0.010602325201034546,
                -0.010910737328231335,
                -0.030601345002651215,
                0.0208981242030859,
                0.032792553305625916,
                0.011588264256715775,
                0.014947634190320969,
                0.05954026058316231,
                0.07295691967010498,
                -0.014122946187853813,
                0.023437678813934326,
                -0.02925366722047329,
                -8.750636334298179e-05,
                -0.005216396413743496,
                -0.017331279814243317,
                0.06570465117692947,
                0.05640396103262901,
                -0.029062271118164062,
                0.04078535735607147,
                0.007589593064039946,
                -0.011081041768193245,
                -0.0029449407011270523,
                0.015055006369948387,
                -0.007187791168689728,
                0.0151815889403224,
                0.020079053938388824,
                -0.07315036654472351,
                -0.039507631212472916,
                0.013943573459982872,
                0.004876056220382452,
                -0.03594627231359482,
                -0.01138664223253727,
                -0.013006886467337608,
                0.00806365441530943,
                0.04403672739863396,
                -0.0391111820936203,
                0.020439647138118744,
                0.06804554909467697,
                0.014995012432336807,
                0.011460530571639538,
                -0.008202604949474335,
                0.006075713317841291,
                0.014777891337871552,
                0.004375461954623461,
                0.0811920017004013,
                -0.05702340602874756,
                0.03338401019573212,
                0.027452291920781136,
                0.002794257365167141,
                -0.02342255786061287,
                -0.0385119765996933,
                0.027167093008756638,
                -0.010851286351680756,
                -0.05918772891163826,
                0.013314086012542248,
                0.027990907430648804,
                0.0012415543897077441,
                -0.021640583872795105,
                0.0008867884753271937,
                0.04449041560292244,
                -0.0010182360420003533,
                -0.08828209340572357,
                0.02055693045258522,
                0.011556180194020271,
                0.02344435080885887,
                -0.02554105408489704,
                -0.030911024659872055,
                -0.04929870739579201,
                -0.0011114421067759395,
                0.009786678478121758,
                0.016977421939373016,
                -0.008402151055634022,
                0.04362635314464569,
                -0.02160683088004589,
                -0.06520897895097733,
                -0.04553874209523201,
                -0.0792011171579361,
                -0.0056015802547335625,
                -0.03094378300011158,
                0.00820387713611126,
                -0.037123505026102066,
                0.024191981181502342,
                -0.0613393597304821,
                -0.007831116206943989,
                -0.03778845816850662,
                -0.014696731232106686,
                0.016138387843966484,
                -0.016710953786969185,
                0.005687736440449953,
                0.055738918483257294,
                0.07710335403680801,
                0.012965831905603409,
                -0.014257606118917465,
                -0.027268439531326294,
                -0.05133514478802681,
                0.04140964522957802,
                0.02412625215947628,
                -0.019685426726937294,
                -0.03840484470129013,
                0.03966590762138367,
                0.007552242372184992,
                -0.0226261168718338,
                0.01403447613120079,
                -0.02002668008208275,
                -0.04058832675218582,
                -0.003513410920277238,
                0.0056287446059286594,
                -0.04987572878599167,
                -0.0015880950959399343,
                -0.007607049308717251,
                0.047109127044677734,
                0.013530554249882698,
                -0.00044260077993385494,
                -0.10324852913618088,
                0.022728044539690018,
                -0.02925199642777443,
                -0.04592394456267357,
                0.031195228919386864,
                -0.031717248260974884,
                0.08899665623903275,
                0.051687415689229965,
                0.06677961349487305,
                0.026379626244306564,
                -0.03860120102763176,
                -0.019620077684521675,
                0.013250348158180714,
                0.025379344820976257,
                0.023179877549409866,
                0.0062889158725738525,
                -0.0035739601589739323,
                -0.012666813097894192,
                0.043301429599523544,
                -0.002036375692114234,
                0.01725754328072071,
                -0.016618791967630386,
                0.009158547036349773,
                -0.025470783933997154,
                0.08793871849775314,
                0.0977313444018364,
                0.02191990800201893,
                0.020748699083924294,
                -0.009588745422661304,
                0.0036241954658180475,
                0.08743442595005035,
                0.019427986815571785,
                -0.05945204943418503,
                -0.006689191795885563,
                0.0020817178301513195,
                0.0084861870855093,
                -0.041081905364990234,
                -0.011752949096262455,
                0.025605276226997375,
                -0.02609708346426487,
                -0.04206298291683197,
                -0.02254113368690014,
                -0.05440782010555267,
                0.08601406961679459,
                -0.08656726032495499,
                0.004329450894147158,
                0.03874896466732025,
                -0.03328973427414894,
                -0.02845573052763939,
                -0.01852128654718399,
                0.013146498240530491,
                0.01249738223850727,
                -0.06847917288541794,
                -0.018018851056694984,
                0.009877015836536884,
                0.02482963539659977,
                0.046333689242601395,
                -0.023908793926239014,
                0.037135716527700424,
                0.013881622813642025,
                -0.016971640288829803,
                -0.12144896388053894,
                0.003676739288493991,
                -0.024087948724627495,
                0.026080654934048653,
                0.014505290426313877,
                0.007148388307541609,
                0.031949933618307114,
                0.007370754610747099,
                0.021064072847366333,
                -0.017207158729434013,
                -0.02156965062022209,
                -0.010689496994018555,
                -0.04135085269808769,
                -0.020770713686943054,
                -0.030473701655864716,
                0.0059301708824932575,
                -0.01791769452393055,
                0.03508652746677399,
                0.005254559684544802,
                0.07337498664855957,
                -0.05044066533446312,
                0.01820497214794159,
                -0.008652208372950554,
                -0.00855935737490654,
                0.007838701829314232,
                0.035015400499105453,
                -0.029359331354498863,
                -0.01760352961719036,
                0.028445689007639885,
                0.010462911799550056,
                -0.03242931142449379,
                0.0027413065545260906,
                0.0038130527827888727,
                0.001497408258728683,
                0.0017281834734603763,
                0.01886807009577751,
                0.04024890810251236,
                -0.0048913597129285336,
                0.055148955434560776,
                -0.009314588271081448,
                0.009388335049152374,
                0.0336739607155323,
                -0.01985917054116726,
                -0.008255844004452229,
                -0.004106094595044851,
                0.021053927019238472,
                0.029962388798594475,
                -0.04398368299007416,
                0.042742010205984116,
                0.0014834317844361067,
                0.03797563165426254,
                -0.07044298201799393,
                -0.01358275767415762,
                -0.007947747595608234,
                -0.051657773554325104,
                0.04751826450228691,
                0.025545654818415642,
                -0.010045361705124378,
                0.027096085250377655,
                -0.010085850022733212,
                0.02801342122256756,
                0.03865446895360947,
                -0.08030721545219421,
                -0.010951189324259758,
                -0.011425143107771873,
                0.00406248914077878,
                -0.02510429546236992,
                0.003092442639172077,
                -0.05127869173884392,
                -0.06337785720825195,
                0.03536750748753548,
                0.004429918248206377,
                0.002411775290966034,
                -0.023224953562021255,
                0.02373611554503441,
                -0.005898721050471067,
                -0.03164178505539894,
                -0.013102749362587929,
                -0.1155390664935112,
                0.006037765182554722,
                0.04488018900156021,
                -0.007895122282207012,
                -0.012581486254930496,
                -0.041985783725976944,
                0.016726678237318993,
                0.030591744929552078,
                -0.018708327785134315,
                -0.007854116149246693,
                0.06711786985397339,
                0.05673103407025337,
                -0.05854829028248787,
                -0.015507451258599758,
                0.02551969513297081,
                -0.019417500123381615,
                -0.016557889059185982,
                -0.013799767009913921,
                0.07131516933441162,
                -0.04279109463095665,
                0.04595556482672691,
                -0.08008376508951187,
                -0.0012300600064918399,
                -0.01663215272128582,
                0.009677105583250523,
                -0.03297348693013191,
                0.029043056070804596,
                0.03739990293979645,
                -0.007828852161765099,
                0.01679304428398609,
                0.02168838120996952,
                -0.021058667451143265,
                -0.06076439097523689,
                -0.03138415515422821,
                0.004241614136844873,
                -0.019411800429224968,
                -0.00824662297964096,
                0.022884458303451538,
                -0.0026834290474653244,
                -0.010666829533874989,
                0.0010545251425355673,
                -0.011705123819410801,
                -0.07154794782400131,
                -0.04064452648162842,
                0.043478623032569885,
                0.054092030972242355,
                -0.038266099989414215,
                0.0716930404305458,
                -0.004596691578626633,
                0.05370446294546127,
                0.04847376048564911,
                -0.019751807674765587,
                0.0036534902174025774,
                0.06203613802790642,
                0.0077203670516610146,
                -0.06282193213701248,
                0.05254160612821579,
                -0.04027629643678665,
                0.0070323399268090725,
                -0.005230172537267208,
                -0.02256191149353981,
                0.06506165862083435,
                -0.013280159793794155,
                -0.005803287960588932,
                -0.051735274493694305,
                0.02036026306450367,
                -0.005279965233057737,
                0.02685842290520668,
                0.03435448557138443,
                -0.025327375158667564,
                -0.011281294748187065,
                0.05132109671831131,
                -0.037285540252923965,
                0.024310464039444923,
                0.05036766082048416,
                -0.055926017463207245,
                0.08705288171768188,
                0.0004783574549946934,
                0.011042446829378605,
                0.0002235372958239168,
                -0.004943091422319412,
                0.05577213689684868,
                0.03800834342837334,
                0.009128181263804436,
                0.047806158661842346,
                0.005799055565148592,
                0.00488676130771637,
                -0.01811690255999565,
                0.042270686477422714,
                -0.006493075750768185,
                -0.00674017146229744,
                0.026740528643131256,
                -0.0690208226442337,
                -0.01798073761165142,
                -0.028956865891814232,
                -0.021180644631385803,
                0.00803393591195345,
                0.05015382915735245,
                -0.049652744084596634,
                0.0032417483162134886,
                -0.0012702440144494176,
                0.025364181026816368,
                0.005826067645102739,
                -0.0108571732416749,
                0.028165794909000397,
                0.008692837320268154,
                0.008481289260089397,
                0.011770355515182018,
                -0.01548689603805542,
                0.0585784912109375,
                0.03712316229939461,
                0.020259063690900803,
                0.010580320842564106,
                0.027382399886846542,
                -0.03810896724462509,
                -0.00721783610060811,
                0.01245321985334158,
                0.0015535533893853426,
                -0.03959953784942627,
                -0.0599549263715744,
                0.02620271034538746,
                0.030306968837976456,
                0.02107274904847145,
                -0.023032929748296738,
                0.04959825053811073,
                0.027785364538431168,
                0.011311093345284462,
                0.0027065558824688196,
                -0.12332010269165039,
                0.023508582264184952,
                -0.04524695500731468,
                -0.011425882577896118,
                0.06105446442961693,
                0.014397614635527134,
                -0.014263433404266834,
                -0.019362645223736763,
                -0.08726891130208969,
                0.08330190181732178,
                -0.02248886041343212,
                -0.010990007780492306,
                -0.02818010374903679,
                -0.01683277264237404,
                0.045554161071777344,
                0.025585606694221497,
                0.04926174134016037,
                -0.014216514304280281,
                0.05164612457156181,
                0.014616547152400017,
                -0.021498095244169235,
                -0.016380395740270615,
                0.04227098450064659,
                -0.008351219817996025,
                0.015800222754478455,
                -0.065089151263237,
                0.005547811742872,
                0.0067354291677474976,
                0.053013525903224945,
                0.04939786344766617,
                0.05056549236178398,
                -0.05195760726928711,
                -0.07051395624876022,
                -0.02596832625567913,
                -0.037219200283288956,
                -0.07350918650627136,
                0.026925604790449142,
                -0.03243045136332512,
                -0.06229659169912338,
                -0.02918199636042118,
                -0.024429237470030785,
                0.03985840454697609,
                -0.02943548932671547,
                0.03859445080161095,
                -0.04496757313609123,
                0.014322742819786072,
                -0.052713215351104736,
                -0.016422957181930542,
                0.0196655485779047,
                -0.02581542357802391,
                0.0029358211904764175,
                -0.0835355594754219,
                0.053683627396821976,
                0.011478783562779427,
                0.012725594453513622,
                0.02045317552983761,
                0.02106861025094986,
                0.032672930508852005,
                0.028788192197680473,
                0.0059665050357580185,
                0.015084427781403065,
                -0.015251893550157547,
                0.04050089046359062,
                0.028511350974440575,
                0.03366023674607277,
                0.014127413742244244,
                -0.010411374270915985,
                -0.009464566595852375,
                -0.03507126867771149,
                0.007985912263393402,
                -0.026860756799578667,
                -0.07631216198205948,
                -0.04198740795254707,
                -0.024527063593268394,
                0.04954429715871811,
                -0.017064951360225677,
                -0.0014022013638168573,
                0.01602187566459179,
                -0.05838242918252945,
                0.042366817593574524,
                0.03793386369943619,
                -0.007940239273011684,
                0.008293786086142063,
                -0.0218980610370636,
                0.026248369365930557,
                -0.09002171456813812,
                0.04385611042380333,
                0.02422597073018551,
                0.04259528964757919,
                -0.03449960798025131,
                -0.016635410487651825,
                0.04021326079964638,
                0.0867924615740776,
                0.015180079266428947,
                0.03719469904899597,
                -0.05662000551819801,
                0.05670678988099098,
                0.002507947152480483,
                -0.0028871018439531326,
                0.03701414167881012,
                0.011601675301790237,
                -0.02420113980770111,
                0.02090013027191162,
                0.007843546569347382,
                -0.021767878904938698,
                -0.006962866056710482,
                0.04051375389099121,
                -0.016200050711631775,
                -0.028857901692390442,
                0.002733181696385145,
                -6.0926816272019666e-33,
                -0.005916170310229063,
                -0.06310145556926727,
                -0.003867621999233961,
                0.02768484689295292,
                -0.14947834610939026,
                -0.009784557856619358,
                -0.0016859432216733694,
                0.010441115126013756,
                -0.05121073126792908,
                0.02629251219332218,
                -0.013841662555932999,
                0.018955128267407417,
                0.013001360930502415,
                -0.0037807277403771877,
                0.08323436975479126,
                -0.052333932369947433,
                -0.012470968067646027,
                0.005002385471016169,
                0.00921688787639141,
                -0.017983295023441315,
                0.02450302056968212,
                -0.008610907010734081,
                -0.03308849036693573,
                0.01808631233870983,
                0.032124798744916916,
                -0.04649940878152847,
                -0.026291627436876297,
                -0.026674101129174232,
                0.03749624639749527,
                -0.043760597705841064,
                0.007377934642136097,
                -0.056782424449920654,
                -0.010776105336844921,
                -0.006888165604323149,
                0.0166709553450346,
                0.0751148983836174,
                -0.011655756272375584,
                -0.03751024976372719,
                0.0045591057278215885,
                -0.020836131647229195,
                -0.05288140848278999,
                -0.0067721279338002205,
                0.00998526718467474,
                0.04210597276687622,
                0.0013540887739509344,
                -0.08745867758989334,
                -0.016357729211449623,
                0.011511695571243763,
                -0.011586518958210945,
                0.05028805509209633,
                -0.05015448480844498,
                0.01913531683385372,
                -0.03665468841791153,
                -0.06996935606002808,
                0.006573623977601528,
                -0.0542142316699028,
                0.029324481263756752,
                0.02459738217294216,
                -0.11057888716459274,
                0.03705985099077225,
                0.008793135173618793,
                0.045173320919275284,
                -0.014062165282666683,
                -0.018934093415737152,
                -0.013399665243923664,
                0.02021040767431259,
                -0.005313887260854244,
                0.027762919664382935,
                0.04654652997851372,
                -0.04541521891951561,
                -0.0402221642434597,
                0.09865335375070572,
                0.0202105101197958,
                -0.031503643840551376,
                -0.02805257961153984,
                -0.04752355068922043,
                -0.01483574416488409,
                -0.014412390999495983,
                0.02032182738184929,
                -0.015080523677170277,
                -0.03186030685901642,
                0.041563212871551514,
                -0.0066421134397387505,
                -0.006129975896328688,
                0.029385248199105263,
                -0.022537481039762497,
                0.017575930804014206,
                0.05220531299710274,
                -0.019355962052941322,
                0.007444140966981649,
                -0.017535170540213585,
                -0.0075336722657084465,
                0.03279872611165047,
                0.03576374799013138,
                0.03546103462576866,
                0.03440030291676521,
                0.03067287802696228,
                0.01937747560441494,
                0.02171643078327179,
                -0.07417190819978714,
                -0.034300219267606735,
                -0.003060760907828808,
                -0.05318869650363922,
                0.03714108467102051,
                0.029355300590395927,
                0.0024501837324351072,
                -0.03965088352560997,
                -0.06985288113355637,
                -0.04418754577636719,
                -0.028526712208986282,
                0.007304234895855188,
                0.023563837632536888,
                -0.022779172286391258,
                -0.0009694715845398605,
                -0.024188192561268806,
                0.013369540683925152,
                -0.008288097567856312,
                -0.07970907539129257,
                0.03428768739104271,
                -0.007853278890252113,
                0.014527124352753162,
                0.04264610633254051,
                -0.009353069588541985,
                -0.033735617995262146,
                0.031199786812067032,
                0.025267917662858963,
                -0.005720951594412327,
                0.025248687714338303,
                -0.031943660229444504,
                -0.003599774558097124,
                -0.01166123989969492,
                -0.055835120379924774,
                2.3690145667387696e-07,
                -0.059964369982481,
                0.04123302921652794,
                0.01677107624709606,
                -0.03192192316055298,
                0.026866864413022995,
                -0.02303428016602993,
                -0.036926962435245514,
                0.05302922800183296,
                -0.008413443341851234,
                -0.014318394474685192,
                0.05961928144097328,
                -0.0052488744258880615,
                0.03311220929026604,
                0.015552370809018612,
                0.04290873184800148,
                0.026777168735861778,
                0.00909341312944889,
                -0.06051824614405632,
                0.0021397946402430534,
                -0.05439193546772003,
                0.0047275712713599205,
                -0.08779271692037582,
                -0.023673411458730698,
                0.010592032223939896,
                -0.014958338811993599,
                0.06610529869794846,
                -0.03625756502151489,
                0.03766746073961258,
                0.04319669306278229,
                0.020151792094111443,
                0.04337478429079056,
                -0.0594586580991745,
                -0.02370488829910755,
                0.01788720116019249,
                0.00710338493809104,
                0.017002668231725693,
                0.015232300385832787,
                0.04762757197022438,
                0.01702364720404148,
                0.09391596168279648,
                -0.04460714012384415,
                -0.03017900325357914,
                0.007618577219545841,
                0.015188882127404213,
                0.019764211028814316,
                0.02164539322257042,
                0.04114294424653053,
                0.005183487664908171,
                -0.0643932968378067,
                -0.026151617988944054,
                0.06255841255187988,
                0.01897173374891281,
                -0.012714757584035397,
                0.04887634515762329,
                0.02658025361597538,
                0.022203214466571808,
                0.023568332195281982,
                0.006457955110818148,
                0.01373591274023056,
                0.060009513050317764,
                -0.05144789069890976,
                0.024079278111457825,
                0.028461184352636337,
                0.0057423897087574005,
                0.011700580827891827,
                0.047721680253744125,
                -0.027129337191581726,
                1.3007283506342766e-34,
                -0.001287613296881318,
                -0.031849205493927,
                0.0008859977824613452,
                -0.021362194791436195,
                -0.014580339193344116,
                -0.02264839969575405,
                -0.021067775785923004,
                0.006147957872599363,
                0.03669683262705803,
                0.007373935543000698,
                -0.00031822724849916995,
                0.04228707402944565,
                0.013406248763203621,
                -0.025125695392489433,
                -0.002636249642819166,
                -0.04176388308405876,
                -0.003977053798735142,
                -0.043590664863586426,
                0.015030566602945328,
                -0.061925578862428665,
                0.04785120114684105,
                0.13293661177158356,
                -0.01106857880949974,
                -0.02112598344683647,
                -0.022819772362709045,
                -0.0009815930388867855,
                -0.006599803920835257,
                0.02534753456711769,
                0.001933188526891172,
                -0.011362757533788681,
                0.007296809460967779,
                -0.05049600824713707,
                0.0077441660687327385,
                -0.01407526433467865,
                -0.007564245257526636,
                0.020081494003534317,
                -0.041503872722387314,
                0.0006607286632061005,
                0.013776129111647606,
                -0.0023000058718025684,
                -0.012392869219183922,
                0.04711460322141647,
                0.001265150960534811,
                -0.0128081776201725,
                -0.014796498231589794,
                1.4832913848294993e-06,
                -0.028425157070159912,
                0.007050962187349796,
                -0.0019647087901830673,
                -0.03713449463248253,
                0.032939862459897995,
                -0.009475073777139187,
                -0.0650964081287384,
                0.020207589492201805,
                0.02967519871890545,
                -0.048253871500492096,
                0.0728929191827774,
                0.020391300320625305,
                0.022002046927809715,
                -0.036832574754953384,
                0.040369875729084015,
                -0.02658027969300747,
                0.037418413907289505,
                0.01857176423072815,
                -0.03707895055413246,
                0.01129963155835867,
                -0.02665042132139206,
                -0.011493329890072346,
                -0.03668199107050896,
                -0.03391145542263985,
                -0.017521768808364868,
                0.006239006295800209,
                -0.021289678290486336,
                -0.02560713142156601,
                0.00922068115323782,
                0.00739489309489727,
                -0.02622564323246479,
                0.003830977715551853,
                0.021270010620355606,
                0.0015109912492334843,
                -0.04977855086326599,
                0.06574861705303192,
                0.039986975491046906,
                0.04846186935901642,
                0.05822312459349632,
                -0.050574950873851776,
                0.005288183223456144,
                -0.03002289868891239,
                -0.021350011229515076,
                0.029987702146172523,
                -0.01743723452091217,
                0.029374051839113235,
                0.03270717337727547,
                0.010433499701321125,
                0.043795373290777206,
                0.048930276185274124,
                0.0019096176838502288,
                -0.012992634437978268,
                -0.028124136850237846,
                -0.018343674018979073,
                -0.016056619584560394,
                0.01188554149121046,
                -0.07406458258628845,
                0.012600746005773544,
                0.03824743255972862,
                0.025654800236225128,
                -0.059239041060209274,
                0.039268817752599716,
                0.0018340031383559108,
                0.042737580835819244,
                -0.041398268193006516,
                -0.059792760759592056,
                0.018207604065537453,
                0.03593224287033081,
                -0.021056462079286575,
                -0.011573027819395065,
                0.07054372876882553,
                -0.03299304097890854,
                0.0008344146772287786,
                -0.053882211446762085,
                0.05440928786993027,
                -0.0026846681721508503,
                0.031186150386929512,
                0.006413561291992664,
                -0.0017211721278727055,
                0.04946072772145271,
                -0.02396549843251705,
                -0.014380489476025105,
                0.0096919946372509,
                -0.009289025329053402,
                -0.005473962984979153,
                -0.010900629684329033,
                0.028159786015748978,
                -0.02257956564426422,
                -0.007296293508261442,
                -0.018946660682559013,
                0.01806662045419216,
                -0.08070254325866699,
                0.01939147710800171,
                -0.0004784710181411356,
                0.07038434594869614,
                -0.014351255260407925,
                -0.009185324423015118,
                0.004222335759550333,
                0.021805834025144577,
                -0.027131717652082443,
                0.03842509165406227,
                -0.003813248360529542,
                0.007739200722426176,
                -0.07978138327598572,
                0.0105471545830369,
                0.012319935485720634,
                -0.08494777232408524,
                -0.030056418851017952,
                -0.006326089147478342,
                -0.03063815087080002,
                -0.014844988472759724,
                -0.016356663778424263,
                0.007928144186735153,
                -0.0108275031670928,
                -0.02874336950480938,
                0.05947593227028847,
                -0.013507668860256672,
                -0.0055525219067931175,
                0.040322039276361465,
                0.01161459181457758,
                0.01835385337471962,
                0.01374924834817648,
                -0.018006976693868637,
                0.033894967287778854,
                -0.01644555665552616,
                0.02796112559735775,
                0.0236943569034338,
                0.03259370103478432,
                -0.03415274620056152,
                -0.009918236173689365,
                -0.004958407022058964,
                0.039021462202072144,
                -0.011543648317456245,
                0.009146827273070812,
                -0.034585729241371155,
                0.03350944072008133,
                0.011537454091012478,
                -0.02954728901386261,
                0.0813818871974945,
                -0.0015674016904085875,
                -0.0009201582870446146,
                -0.056681837886571884,
                -0.022398635745048523,
                -0.008753915317356586,
                -0.05754780396819115,
                -0.03703237697482109,
                -0.008261825889348984,
                -0.008739624172449112,
                0.018727198243141174,
                0.05637122318148613,
                0.04271650314331055,
                0.013309956528246403,
                0.01672414503991604,
                -0.0309388879686594,
                0.004880161955952644,
                -0.04766456410288811,
                -0.015736840665340424,
                -9.481270535616204e-05,
                -0.01931040734052658,
                -0.016504483297467232,
                -0.021774116903543472,
                0.015635110437870026,
                0.006764090154320002,
                -0.05368787795305252,
                -0.036170221865177155,
                -0.008442669175565243,
                -0.016866635531187057,
                0.007387337740510702,
                0.10308009386062622,
                -0.04281316697597504,
                0.06266968697309494,
                -0.004410698544234037,
                0.007840742357075214,
                0.05745844915509224,
                -0.0631767138838768,
                0.0103912940248847,
                0.019609738141298294,
                0.03747072070837021,
                0.0096900574862957,
                0.015136037953197956,
                0.07657842338085175,
                0.008040877990424633,
                0.01879214309155941,
                -0.027239913120865822,
                0.08045423030853271,
                -0.005099546164274216,
                0.09274840354919434,
                0.026485715061426163,
                -7.190838368842378e-05,
                0.017885899171233177,
                -0.01190497912466526,
                -0.06204072758555412,
                0.019002705812454224,
                0.028197940438985825,
                -0.007040091324597597,
                -0.0017719861352816224,
                0.03947252780199051,
                0.013251817785203457,
                -0.018388349562883377,
                0.06595540791749954,
                0.04104289412498474,
                0.039686787873506546,
                0.0550510510802269,
                -0.006082738749682903,
                -0.04866216704249382,
                0.0049683451652526855,
                0.016490403562784195,
                -0.003523181425407529,
                -0.007609546184539795,
                -0.04535793140530586,
                0.037707023322582245,
                0.04191439598798752,
                -0.03477032110095024,
                0.00084546027937904,
                -0.000870486197527498,
                -0.02088726870715618,
                -0.017128614708781242,
                0.011753925122320652,
                -0.05946856364607811,
                0.06394674628973007,
                -0.04829934984445572,
                0.008058648556470871,
                0.02119140699505806,
                -0.03842035308480263,
                0.01961672306060791,
                0.03787636011838913,
                -0.03865934535861015,
                -0.007230874616652727,
                0.021140186116099358,
                0.05113360658288002,
                -0.037800390273332596,
                -0.03188001736998558,
                -0.0479302816092968,
                -0.018526148051023483,
                -0.008998670615255833,
                0.013989702798426151,
                -0.03734072670340538,
                -0.012273713946342468,
                -0.003580008400604129,
                -0.010678221471607685,
                0.03897237777709961,
                -0.02031455934047699,
                0.01877996325492859,
                0.0015869064955040812,
                0.05381862819194794,
                0.01713290810585022,
                -0.07268735021352768,
                -0.025860507041215897,
                -0.01749586872756481,
                -0.021651295945048332,
                -0.09253798425197601,
                0.022668631747364998,
                -0.022720539942383766,
                0.005540336482226849,
                -0.06120570749044418,
                -0.010107052512466908,
                -0.034880056977272034,
                0.04874761402606964,
                -0.08381249755620956,
                0.005423512775450945,
                -0.004334150347858667,
                0.0282385665923357,
                0.04475681483745575,
                -0.0005529349436983466,
                -0.07553015649318695,
                0.0011524908477440476,
                -0.040457528084516525,
                0.03434094414114952,
                -0.0017573559889569879,
                -0.03085349127650261,
                0.024011623114347458,
                0.022603247314691544,
                -0.04571656882762909,
                -0.06822261214256287,
                0.03380885347723961,
                0.004480252042412758,
                0.026233026757836342,
                0.031540628522634506,
                0.0028972062282264233,
                -0.01656104065477848,
                -0.016520824283361435,
                0.017033357173204422,
                0.005371985957026482,
                -0.009944676421582699,
                0.09331571310758591,
                0.010896226391196251,
                0.011171293444931507,
                -0.03483685851097107,
                -0.0020931148901581764,
                0.012327712029218674,
                0.050732262432575226,
                0.004940832499414682,
                0.047562163323163986,
                0.05948268994688988,
                0.012242629192769527,
                -0.028685107827186584,
                -0.020390484482049942,
                -0.03893942013382912,
                -0.0053107538260519505,
                -0.013224744237959385,
                0.06695182621479034,
                -0.030849434435367584,
                -0.014530554413795471,
                0.012408892624080181,
                -0.011308806948363781,
                0.009360551834106445,
                -0.05772146210074425,
                -0.029081109911203384,
                0.005827304907143116,
                -0.023685621097683907,
                -0.007175678387284279,
                -0.047679394483566284,
                -0.036001283675432205,
                -0.09093436598777771,
                -0.030314551666378975,
                -0.03107970952987671,
                0.006111603695899248,
                -0.03489386662840843,
                -0.018298223614692688,
                0.011475901119410992,
                -0.02733498252928257,
                0.009482791647315025,
                0.067763552069664,
                0.03860760107636452,
                -0.03316662088036537,
                -0.07005032896995544,
                0.02198535017669201,
                0.025596635416150093,
                0.03940998762845993,
                0.059233251959085464,
                0.03230755403637886,
                0.04577513784170151,
                0.04853535071015358,
                -0.0666937604546547,
                0.06677732616662979,
                -0.0043945652432739735,
                0.03550822660326958,
                -0.0006661242805421352,
                0.06792949885129929,
                -0.01518737431615591,
                0.06248108297586441,
                0.0020440698135644197,
                -0.04957776516675949,
                0.036964502185583115,
                0.027127107605338097,
                0.07479262351989746,
                -0.02804187685251236,
                0.029665768146514893,
                -0.002190779894590378,
                0.004245344549417496,
                -0.021789731457829475,
                -0.05174589529633522,
                0.011679432354867458,
                0.05580051243305206,
                0.005864613689482212,
                0.011938386596739292,
                -0.07055537402629852,
                -0.028142042458057404,
                0.07469358295202255,
                -0.025865914300084114,
                -0.027585648000240326,
                0.0029173230286687613,
                0.011433854699134827,
                0.029248099774122238,
                0.0048220171593129635,
                0.023438243195414543,
                -0.045407235622406006,
                0.004543059505522251,
                0.059949662536382675,
                -0.021556613966822624,
                0.0022123430389910936,
                0.050869498401880264,
                0.005119248293340206,
                0.027464769780635834,
                -0.13424283266067505,
                -0.056027330458164215,
                0.011160757392644882,
                0.011599327437579632,
                0.02045295760035515,
                0.0017991497879847884,
                -0.052858393639326096,
                -0.00865889061242342,
                -0.057630594819784164,
                0.01054412592202425,
                -0.009078738279640675,
                -0.012145965360105038,
                0.005658499896526337,
                0.0023900503292679787,
                -0.005570467095822096,
                0.012157049961388111,
                -0.04436049610376358,
                0.027302158996462822,
                0.059143535792827606,
                0.0006577644380740821,
                -0.03802620619535446,
                0.013086095452308655,
                -0.040553320199251175,
                0.03763110563158989,
                0.009504115208983421,
                -0.052132561802864075,
                -0.00599320512264967,
                -0.018129272386431694,
                0.013676361180841923,
                -0.03269809111952782,
                -0.024592800065875053,
                -0.028863275423645973,
                -0.020044850185513496,
                0.05777515843510628,
                0.0016939891502261162,
                -0.030442524701356888,
                -0.07383855432271957,
                0.03782845288515091,
                -0.020523566752672195,
                -0.008909427560865879,
                0.042162444442510605,
                -0.0073812068440020084,
                -0.07258858531713486,
                -0.035924799740314484,
                -0.06758861988782883,
                0.03767752647399902,
                0.00710715539753437,
                -0.0192046407610178,
                -0.045227229595184326,
                0.03474641218781471,
                0.07555335760116577,
                0.023912370204925537,
                0.044183362275362015,
                -0.05464259907603264,
                0.005458488129079342,
                0.004445559345185757,
                -0.07277528196573257,
                0.014899177476763725,
                -0.04155923053622246,
                0.043627139180898666,
                0.019706765189766884,
                -0.05986206978559494,
                0.009743102826178074,
                0.009496046230196953,
                0.015738509595394135,
                0.030131656676530838,
                -0.022567084059119225,
                0.014239704236388206,
                -0.050402674823999405,
                0.016811316832900047,
                -0.0371038094162941,
                -0.00956651195883751,
                0.07105442136526108,
                -0.026951510459184647,
                -0.015564563684165478,
                -0.02002723328769207,
                -0.01847229152917862,
                0.01842806302011013,
                -0.028700685128569603,
                0.0118238665163517,
                -0.01486776489764452,
                -0.05425509065389633,
                0.017811909317970276,
                -0.036366816610097885,
                -0.05975762754678726,
                -0.003317156108096242,
                -0.027597477659583092,
                -0.04532608762383461,
                -0.009976457804441452,
                0.022125769406557083,
                -0.028064796701073647,
                0.0005083121359348297,
                0.03460552170872688,
                0.017041213810443878,
                0.003326171077787876,
                0.005985205993056297,
                -0.02523769438266754,
                0.013371245935559273,
                0.07930706441402435,
                -0.01403429638594389,
                0.04711756855249405,
                -0.04286815598607063,
                -0.06264407932758331,
                -0.08385144919157028,
                0.009625026024878025,
                0.011700630187988281,
                0.04930628463625908,
                -0.038745585829019547,
                -0.06862087547779083,
                -0.03560856729745865,
                0.014193171635270119,
                0.0008517850073985755,
                -0.005416287574917078,
                0.05276822671294212,
                0.01615145057439804,
                0.05198715254664421,
                0.02196824923157692,
                0.026760149747133255,
                -0.0029705262277275324,
                -0.05717441812157631,
                -0.01239705178886652,
                -0.12553928792476654,
                -0.004726874642074108,
                -0.021920641884207726,
                0.010678254067897797,
                -0.019274694845080376,
                0.002284417161718011,
                0.001014307956211269,
                0.038906365633010864,
                0.01094354223459959,
                0.004171023145318031,
                -0.04012802615761757,
                0.02953934110701084,
                0.0692528560757637,
                0.049976322799921036,
                -0.005407593213021755,
                0.03545118868350983,
                -0.03406480699777603,
                0.015058477409183979,
                -0.003986408468335867,
                -0.042951859533786774,
                -0.05199992656707764,
                -0.04667291417717934,
                -0.008864270523190498,
                -0.0016556237824261189,
                0.03726451098918915,
                -5.382613979012194e-33,
                -0.031165344640612602,
                -0.04678110033273697,
                0.04516390711069107,
                0.04983574151992798,
                -0.0526290126144886,
                0.026339516043663025,
                -0.007243321277201176,
                0.05028083920478821,
                -0.024341126903891563,
                -0.0025198955554515123,
                -0.03531142696738243,
                -0.004415006842464209,
                0.017748624086380005,
                -0.04205195978283882,
                0.10694151371717453,
                -0.022849874570965767,
                -0.025153066962957382,
                0.032948244363069534,
                0.023153604939579964,
                -0.04468768835067749,
                0.016763869673013687,
                0.008205878548324108,
                0.007240485865622759,
                0.08561281859874725,
                0.00493029598146677,
                -0.06393572688102722,
                -0.002576742321252823,
                0.0022156795021146536,
                0.01669146865606308,
                -0.00010832159750862047,
                0.01167736854404211,
                -0.04865296930074692,
                0.003942710347473621,
                -0.004399402532726526,
                0.01092798076570034,
                0.04351301118731499,
                -0.040607184171676636,
                -0.07707813382148743,
                0.024993959814310074,
                -0.02957911603152752,
                -0.05027144402265549,
                -0.05143580213189125,
                -0.007024293299764395,
                -0.021585684269666672,
                0.0008658724254928529,
                -0.016230383887887,
                0.0071823569014668465,
                0.03301035612821579,
                -0.015746083110570908,
                0.06788500398397446,
                -0.020078502595424652,
                0.0007101872470229864,
                -0.06178043782711029,
                -0.005778989288955927,
                0.023359468206763268,
                -0.009028900414705276,
                0.015695910900831223,
                -0.00018218638433609158,
                -0.07288219034671783,
                0.025905460119247437,
                -0.06494760513305664,
                0.04324943944811821,
                -0.012996071018278599,
                -8.316992170875892e-05,
                0.008506068959832191,
                -0.0076695894822478294,
                -0.0015798583626747131,
                0.046242281794548035,
                0.018401367589831352,
                0.00673595629632473,
                0.041471343487501144,
                0.052694063633680344,
                0.04587850719690323,
                -0.004026672802865505,
                -0.027339451014995575,
                -0.08858093619346619,
                -0.013064047321677208,
                0.021820971742272377,
                0.06329097598791122,
                -0.024891404435038567,
                0.015409824438393116,
                0.01570856384932995,
                -0.043568819761276245,
                0.020496416836977005,
                0.04403354227542877,
                0.03160086274147034,
                0.016078155487775803,
                0.047954294830560684,
                -0.04229392111301422,
                0.015566883608698845,
                0.006554681342095137,
                0.01633586175739765,
                0.05834117904305458,
                0.05471348762512207,
                -0.01979811303317547,
                0.05561451613903046,
                -0.022068848833441734,
                -0.02082919143140316,
                -0.012826437130570412,
                0.010747401975095272,
                -0.05978468433022499,
                0.013495531864464283,
                -0.10559231787919998,
                0.018336618319153786,
                0.026081716641783714,
                0.009676216170191765,
                0.02866007201373577,
                0.05763447284698486,
                -0.03526618331670761,
                -0.06513824313879013,
                0.010966564528644085,
                0.020747387781739235,
                0.011690236628055573,
                -0.07760126143693924,
                0.015825709328055382,
                0.020451586693525314,
                0.006677370518445969,
                0.04956827312707901,
                -0.018053404986858368,
                -0.04641702398657799,
                0.006817311979830265,
                0.02567029371857643,
                0.04801236465573311,
                -0.0039679487235844135,
                -0.029025953263044357,
                0.01644303649663925,
                -0.007527113426476717,
                0.09904776513576508,
                -0.03983991593122482,
                -0.002350335940718651,
                0.0011539905099198222,
                -0.0017806345131248236,
                2.0644999665364594e-07,
                -0.015513534657657146,
                0.016766581684350967,
                -0.022042063996195793,
                0.01860172674059868,
                0.005317142698913813,
                -0.03708724305033684,
                -0.06999284774065018,
                -0.004858721978962421,
                -0.01942582055926323,
                0.05062497779726982,
                0.10150589793920517,
                -0.005745145492255688,
                -0.015007009729743004,
                -0.02908812090754509,
                0.004536448512226343,
                -0.003755811834707856,
                -0.025605197995901108,
                -0.044858667999506,
                0.03536195307970047,
                -0.025706840679049492,
                0.0059869904071092606,
                0.009457121603190899,
                0.00030556245474144816,
                -0.0006587551324628294,
                -0.004415147937834263,
                0.03369378298521042,
                -0.02063611149787903,
                -0.03476184234023094,
                0.03884291648864746,
                -0.02668122574687004,
                0.07717189937829971,
                -0.034106213599443436,
                -0.0320289172232151,
                0.043550603091716766,
                -0.04235650971531868,
                -0.061243753880262375,
                0.04166555777192116,
                0.04433584585785866,
                -0.005536987911909819,
                0.03253617882728577,
                0.007926219142973423,
                -0.03881843015551567,
                -0.02641495130956173,
                0.02522675320506096,
                0.03650631383061409,
                0.050277117639780045,
                -0.021073531359434128,
                0.019074833020567894,
                0.0013481436762958765,
                -0.008508476428687572,
                0.014725105836987495,
                0.05388403311371803,
                0.023890594020485878,
                0.06437712162733078,
                -0.016530852764844894,
                -0.012135159224271774,
                0.004700783174484968,
                -0.001754813827574253,
                0.03452880308032036,
                0.0537869855761528,
                0.003303119447082281,
                -0.037919893860816956,
                0.000945722043979913,
                0.0007748041534796357,
                0.017947813495993614,
                0.011322081089019775,
                -0.02752671204507351,
                6.62809549564914e-35,
                0.019467735663056374,
                0.007537779863923788,
                0.004910270683467388,
                0.028310099616646767,
                0.019947677850723267,
                -0.028603069484233856,
                0.09005385637283325,
                0.01234398502856493,
                -0.01059756986796856,
                0.016340455040335655,
                -0.03709672763943672,
                0.06297138333320618,
                -0.020030226558446884,
                -0.01851087063550949,
                -0.01355514582246542,
                -0.0081430459395051,
                0.015342488884925842,
                -0.00289041711948812,
                -0.012953532859683037,
                -0.07682368159294128,
                0.03963539004325867,
                0.09105234593153,
                -0.010542556643486023,
                0.01994759775698185,
                9.340199903817847e-05,
                0.014310413971543312,
                -0.06361132115125656,
                -0.0213488657027483,
                -0.01084519550204277,
                -0.01234785933047533,
                -0.018096040934324265,
                0.016596073284745216,
                -0.0009509806404821575,
                0.020331501960754395,
                -0.01907469518482685,
                -0.007727308198809624,
                -0.001515217823907733,
                0.011137845925986767,
                -0.015745410695672035,
                0.0031539457850158215,
                0.009570619091391563,
                0.033249251544475555,
                -0.011063605546951294,
                -0.0345420315861702,
                -0.03493824973702431,
                1.7798971612137393e-06,
                -0.01617990992963314,
                -0.013270092196762562,
                -0.013051571324467659,
                -0.04737669974565506,
                -0.0006717214128002524,
                -0.025954782962799072,
                0.014087371528148651,
                0.0024699531495571136,
                0.012408548034727573,
                -0.018384279683232307,
                0.04357967525720596,
                0.04205286502838135,
                -0.02389903925359249,
                0.008362963795661926,
                -0.02042340114712715,
                0.013418659567832947,
                0.024848870933055878,
                -0.03989572823047638,
                -0.021699588745832443,
                -0.05298088863492012,
                -0.06360477954149246,
                0.010305345058441162,
                -0.00302508263848722,
                0.02660723589360714,
                -0.01115645281970501,
                0.02305903099477291,
                -0.036303967237472534,
                -0.017194148153066635,
                0.0017112118657678366,
                0.00916990265250206,
                0.028958143666386604,
                -0.006314282305538654,
                -0.026135539636015892,
                0.023788269609212875,
                -0.031529247760772705,
                -0.05683957412838936,
                0.0586889311671257,
                0.011101938784122467,
                0.04647001996636391,
                -0.028656432405114174,
                0.024476859718561172,
                -0.0155355678871274,
                0.002862231805920601,
                -0.004065073095262051,
                -0.025115838274359703,
                -0.0592896044254303,
                0.07618720829486847,
                0.004567478783428669,
                0.037021931260824203,
                0.05157000944018364,
                0.04116832837462425,
                -0.03368355333805084,
                0.014563703909516335,
                -0.007947874255478382,
                0.08154463768005371,
                -0.036198895424604416,
                -0.058073557913303375,
                -0.002967443782836199,
                0.0379207506775856,
                -0.0003316003130748868,
                -0.02395743504166603,
                0.047088682651519775,
                -0.034118056297302246,
                0.00019303627777844667,
                -0.026050778105854988,
                -0.03063163161277771,
                0.020076116546988487,
                0.06313139200210571,
                0.030274055898189545,
                -0.0013407990336418152,
                -0.03308170661330223,
                0.00038506320561282337,
                -0.02612236887216568,
                -0.013507059775292873,
                0.016545874997973442,
                -0.06862055510282516,
                0.0250538382679224,
                0.013826743699610233,
                0.02504534088075161,
                0.013198206201195717,
                -0.00898381695151329,
                0.013888045214116573,
                0.03566257283091545,
                0.0036194175481796265,
                0.02899121679365635,
                0.02174203470349312,
                0.033229317516088486,
                -0.013426052406430244,
                -0.01915440335869789,
                0.03667103126645088,
                0.0011879130033776164,
                -0.05156896635890007,
                -0.014935481362044811,
                -0.015188196673989296,
                0.024016929790377617,
                -0.029199765995144844,
                -0.008160333149135113,
                -0.007618365343660116,
                -0.0011331260902807117,
                0.015071714296936989,
                -0.007815597578883171,
                0.02046455256640911,
                0.052374158054590225,
                -0.001081508002243936,
                -0.023820433765649796,
                0.037750180810689926,
                -0.05113408342003822,
                -0.055241648107767105,
                -0.04340974986553192,
                0.006894682999700308,
                -0.019132912158966064,
                0.026869503781199455,
                0.0009733161423355341,
                0.002682944992557168,
                -0.04391414672136307,
                0.009451952762901783,
                0.009556841105222702,
                -0.025735413655638695,
                0.021754104644060135,
                0.06349022686481476,
                0.05943182110786438,
                -0.04374711960554123,
                -0.015244457870721817,
                0.007172335404902697,
                0.022682340815663338,
                0.026212647557258606,
                0.027126070111989975,
                0.02787509746849537,
                -0.017222387716174126,
                -0.03252493590116501,
                0.022180180996656418,
                0.05246797204017639,
                0.004503427539020777,
                0.004589589312672615,
                0.01009135227650404,
                -0.02794230729341507,
                -0.009793498553335667,
                -0.03598269075155258,
                0.03997126594185829,
                0.009018140845000744,
                -0.013576311059296131,
                0.0531267449259758,
                -0.010824387893080711,
                -0.045657649636268616,
                0.016049077734351158,
                -0.042207542806863785,
                -0.09788147360086441,
                -0.005282562226057053,
                0.030634956434369087,
                0.0911991074681282,
                0.03617919236421585,
                0.026997549459338188,
                0.07317988574504852,
                -0.06281581521034241,
                0.02162131667137146,
                -0.01426839642226696,
                0.00827897246927023,
                -0.0231942068785429,
                -0.01713011972606182,
                0.011120382696390152,
                -0.0019526334945112467,
                -0.0042784251272678375,
                -0.029859958216547966,
                -0.002049531089141965,
                -0.024714091792702675,
                0.019105181097984314,
                -0.03168540447950363,
                0.027602961286902428,
                0.030626701191067696,
                -0.028452545404434204,
                0.057803764939308167,
                -0.030067510902881622,
                -0.001172611489892006,
                0.018563827499747276,
                -0.021469103172421455,
                -0.01933840848505497,
                0.02620343118906021,
                -0.010435955598950386,
                0.030545320361852646,
                -0.013951357454061508,
                0.06578528881072998,
                -0.004512975458055735,
                0.023150019347667694,
                -0.06210441514849663,
                -0.013672965578734875,
                -0.035554736852645874,
                0.10513146966695786,
                -0.005403775721788406,
                0.015235996805131435,
                -0.011046680621802807,
                -0.015504547394812107,
                -0.04097675904631615,
                -0.0031822281889617443,
                0.02463957853615284,
                6.40039870631881e-05,
                -0.016770409420132637,
                0.007337599527090788,
                0.011007280088961124,
                0.009400242939591408,
                0.007126213051378727,
                0.037584275007247925,
                0.10329683870077133,
                0.005038606934249401,
                -0.007148104254156351,
                -0.07374077290296555,
                -0.032068077474832535,
                0.027800627052783966,
                0.028603093698620796,
                0.04130866751074791,
                0.034576255828142166,
                -0.016317671164870262,
                -0.014414221979677677,
                0.009620442986488342,
                -0.05512022599577904,
                0.002472243970260024,
                -0.06654636561870575,
                0.0026561871636658907,
                0.030905338004231453,
                -0.01881570555269718,
                -0.003957780543714762,
                -0.04775841534137726,
                -0.0015170031692832708,
                0.059204958379268646,
                -0.033476296812295914,
                0.04733232036232948,
                0.0703466609120369,
                0.018394768238067627,
                0.005961939692497253,
                -0.006907863076776266,
                0.0767509862780571,
                -0.03140188381075859,
                -0.016706222668290138,
                -0.06440400332212448,
                0.0036898429971188307,
                -0.026816075667738914,
                0.018902361392974854,
                -0.02756560407578945,
                0.003220499726012349,
                -0.009305300191044807,
                0.0035570894833654165,
                0.03546351566910744,
                -0.0011158682173117995,
                0.023799465969204903,
                -0.05539754405617714,
                -0.01020327489823103,
                0.03396446257829666,
                -0.05654710531234741,
                0.0044419062323868275,
                0.036499280482530594,
                0.01919539086520672,
                -0.0042818458750844,
                0.06759760528802872,
                -0.04361709952354431,
                -0.07257236540317535,
                -0.02830132655799389,
                -0.03987385705113411,
                -0.04812726750969887,
                0.04012399539351463,
                -0.037791717797517776,
                0.01316086109727621,
                0.01393275149166584,
                -0.02075197547674179,
                -0.0033891184721142054,
                -0.009835680015385151,
                -0.03351657837629318,
                0.009179085493087769,
                -0.01496406365185976,
                0.04329540953040123,
                0.002131384564563632,
                -0.026081660762429237,
                -0.041982658207416534,
                0.0025728351902216673,
                -0.03893110156059265,
                -0.02971639856696129,
                0.047545116394758224,
                0.019576862454414368,
                0.01674787886440754,
                0.017752882093191147,
                0.0053843907080590725,
                -0.004704164806753397,
                -0.012864630669355392,
                -0.03280482068657875,
                -0.09642548114061356,
                -0.024504996836185455,
                0.021875279024243355,
                -0.017571747303009033,
                0.037943899631500244,
                -0.012159526348114014,
                -0.010472841560840607,
                -0.010557218454778194,
                -0.0386449433863163,
                -0.013035117648541927,
                0.023990163579583168,
                0.09033310413360596,
                -0.025432433933019638,
                0.0005482584238052368,
                -0.046328309923410416,
                -0.018860172480344772,
                0.013286841101944447,
                -0.005099867936223745,
                0.04332202300429344,
                -0.02105230651795864,
                -0.08333152532577515,
                -0.03305012360215187,
                -0.007715372834354639,
                -0.0009039905271492898,
                0.02024284563958645,
                -0.02637132816016674,
                0.03161296993494034,
                -0.06449821591377258,
                0.008740589953958988,
                0.010908630676567554,
                0.005915893707424402,
                -0.029526276513934135,
                0.019498230889439583,
                0.07401979714632034,
                -0.03889836370944977,
                -0.027940141037106514,
                -0.02414744719862938,
                0.014507189393043518,
                0.002926306566223502,
                -0.04131206497550011,
                -0.0002484141441527754,
                0.01243541482836008,
                -0.11988945305347443,
                -0.02741401456296444,
                0.061648763716220856,
                0.12538467347621918,
                0.002705095801502466,
                0.007350313011556864,
                -0.015599802136421204,
                0.040194712579250336,
                0.04161231219768524,
                -0.06749708950519562,
                -0.007221169304102659,
                0.037984590977430344,
                0.04205646738409996,
                -0.04486502334475517,
                0.042188894003629684,
                0.0007470796699635684,
                0.045052140951156616,
                0.025495236739516258,
                -0.022806616500020027,
                -0.0021613396238535643,
                -0.03284895792603493,
                0.03910167142748833,
                0.005419054068624973,
                -0.0043020229786634445,
                0.016391055658459663,
                -0.018840402364730835,
                0.024968110024929047,
                -0.01971573755145073,
                -0.015733715146780014,
                0.006142922677099705,
                0.007660830393433571,
                0.04321318864822388,
                -0.028260303661227226,
                -0.04539937525987625,
                0.02094855159521103,
                0.03120153583586216,
                -0.03786049410700798,
                0.020583098754286766,
                0.018062828108668327,
                0.020447196438908577,
                0.024623561650514603,
                0.03932354971766472,
                0.05140425264835358,
                0.017823589965701103,
                0.03508676961064339,
                -0.1025950238108635,
                0.009912560693919659,
                0.07151670008897781,
                0.036134883761405945,
                0.022745097056031227,
                -0.0731261670589447,
                -0.002854143036529422,
                0.03063310869038105,
                0.04846082627773285,
                0.016994841396808624,
                0.07511763274669647,
                -0.05720028281211853,
                -0.004024891648441553,
                0.02624264359474182,
                -0.024716198444366455,
                -0.0352543443441391,
                0.004652857780456543,
                -0.012279428541660309,
                -0.03968528285622597,
                -0.024559546262025833,
                0.006311753764748573,
                -0.010698828846216202,
                0.041433658450841904,
                0.042660847306251526,
                0.0015124353813007474,
                -0.036041438579559326,
                -0.019328923895955086,
                0.03379042074084282,
                -0.016214273869991302,
                0.03256166726350784,
                -0.0306767039000988,
                -0.09799874573945999,
                -0.05594131350517273,
                0.026124877855181694,
                0.048051174730062485,
                0.007828796282410622,
                -0.03411595895886421,
                0.07888607680797577,
                -0.013248682022094727,
                -0.009196123108267784,
                0.005459252279251814,
                -0.10226037353277206,
                0.05206620320677757,
                -0.008977077901363373,
                -0.04128170758485794,
                0.017878437414765358,
                0.03726978972554207,
                -0.02290213294327259,
                -0.0413040891289711,
                -0.08380405604839325,
                0.00258586835116148,
                0.027490047737956047,
                -0.02535823918879032,
                -0.004178723786026239,
                0.028877148404717445,
                0.04377531260251999,
                0.04297036677598953,
                0.036934539675712585,
                -0.06302425265312195,
                0.03590594232082367,
                -0.034767188131809235,
                -0.042884454131126404,
                -0.0018384897848591208,
                -0.05047190189361572,
                0.0374758280813694,
                -0.033562272787094116,
                -0.03841419517993927,
                0.005249700974673033,
                0.001960208872333169,
                0.03827369585633278,
                0.04041573032736778,
                0.05714983865618706,
                -0.04552339017391205,
                -0.06972348690032959,
                -0.01462964154779911,
                -0.027301892638206482,
                -0.03253792226314545,
                0.021725105121731758,
                -0.01152777299284935,
                0.01193762756884098,
                0.006665598135441542,
                -0.012371590360999107,
                0.04657374322414398,
                -0.011601122096180916,
                0.007252522278577089,
                -0.032443754374980927,
                -0.027837581932544708,
                -0.0016823462210595608,
                0.017389174550771713,
                0.045055802911520004,
                0.013917909003794193,
                -0.003918072674423456,
                -0.0324154756963253,
                0.06939880549907684,
                0.03136226534843445,
                0.035907961428165436,
                -0.00027725001564249396,
                0.03315938264131546,
                0.017945628613233566,
                -0.015977568924427032,
                0.00580255500972271,
                0.0008367536356672645,
                0.001978115411475301,
                0.008855738677084446,
                -0.005266263149678707,
                -0.006640581414103508,
                0.03945579752326012,
                -0.015998175367712975,
                -0.05668594688177109,
                -0.08097890764474869,
                0.028766360133886337,
                0.017875347286462784,
                -0.011866318993270397,
                -0.013812423683702946,
                -0.021412905305624008,
                -0.019878756254911423,
                0.01576460711658001,
                -0.01734108291566372,
                -0.014141474850475788,
                -0.03820447623729706,
                0.09392786771059036,
                -0.05297766253352165,
                0.00857451930642128,
                -0.005229842849075794,
                -0.018155822530388832,
                0.013497824780642986,
                -0.073200523853302,
                0.011767003685235977,
                -0.024935856461524963,
                0.02958228997886181,
                -0.038923006504774094,
                0.07995864748954773,
                0.040595490485429764,
                0.07464562356472015,
                -0.010990714654326439,
                0.03030582331120968,
                -0.02796434611082077,
                0.07658019661903381,
                0.0036576094571501017,
                0.017104122787714005,
                0.0547112300992012,
                0.003721782937645912,
                -0.016776418313384056,
                0.020280389115214348,
                0.014258204028010368,
                0.007038504350930452,
                -0.024930492043495178,
                -0.025340555235743523,
                0.012995543889701366,
                -0.006621304899454117,
                0.034029342234134674,
                -6.658257142446679e-33,
                0.009736639447510242,
                -0.10228925198316574,
                0.005459193140268326,
                0.016655035316944122,
                -0.070747509598732,
                0.011791379190981388,
                0.02801264449954033,
                0.016763754189014435,
                -0.022320158779621124,
                -0.010693486779928207,
                -0.036130551248788834,
                0.007388413418084383,
                0.028734974563121796,
                -0.019877616316080093,
                0.049280039966106415,
                -0.006473969202488661,
                -0.003155846381559968,
                0.009668529033660889,
                0.0035773098934441805,
                -0.02533268742263317,
                -0.007635725662112236,
                0.0433528833091259,
                -0.01959475874900818,
                0.03690043464303017,
                0.0051634288392961025,
                -0.013903236016631126,
                -0.04856400564312935,
                0.013434428721666336,
                -0.012599753215909004,
                -0.018298281356692314,
                0.0450870580971241,
                -0.0728093683719635,
                -0.008558939211070538,
                -0.08625273406505585,
                0.006424303166568279,
                0.08432704210281372,
                0.011773946695029736,
                -0.012798511423170567,
                -0.02633892372250557,
                -0.01050441712141037,
                -0.053769856691360474,
                0.0030061749275773764,
                0.0069018942303955555,
                -0.00434002885594964,
                0.02054382488131523,
                -0.07773146778345108,
                1.0566177479631733e-06,
                0.0060662939213216305,
                -0.0007683397852815688,
                0.06105506047606468,
                0.015231376513838768,
                0.03440345823764801,
                0.008243108168244362,
                -0.0036457697860896587,
                0.04379427805542946,
                -0.06665921211242676,
                -0.02124221809208393,
                -0.018015773966908455,
                -0.07322943955659866,
                0.015409507788717747,
                -0.053782906383275986,
                0.04467401280999184,
                -0.01774664595723152,
                -0.00312166684307158,
                -0.008884329348802567,
                0.015252448618412018,
                -0.03256220743060112,
                -0.0003873383393511176,
                -0.018221944570541382,
                -0.02830142341554165,
                -0.06556708365678787,
                0.08289098739624023,
                0.08211833238601685,
                -0.0015126803191378713,
                -0.06837037950754166,
                -0.0475233756005764,
                -0.03473502770066261,
                0.029491975903511047,
                0.04087354987859726,
                -0.013408664613962173,
                -0.05617094784975052,
                0.04991144686937332,
                -0.018917495384812355,
                0.04226672276854515,
                0.049990370869636536,
                -0.01408837828785181,
                0.010678084567189217,
                -0.0020664772018790245,
                -0.050452955067157745,
                0.030050164088606834,
                -0.03912001848220825,
                0.039281316101551056,
                0.036170732229948044,
                0.04557414352893829,
                0.03632458671927452,
                0.024129901081323624,
                0.034668464213609695,
                0.003881108481436968,
                -0.006854180712252855,
                -0.06583387404680252,
                -0.06711961328983307,
                0.03966986760497093,
                -0.11437886953353882,
                0.017191670835018158,
                0.016402510926127434,
                -0.004306620918214321,
                0.005812039598822594,
                0.03375832736492157,
                -0.005069784354418516,
                -0.005271255504339933,
                -0.005256183445453644,
                0.007010695058852434,
                0.010919475927948952,
                -0.08314572274684906,
                -0.019881203770637512,
                -0.00820478517562151,
                0.011049947701394558,
                -0.05438787862658501,
                0.01055813767015934,
                -0.08225469291210175,
                0.017197631299495697,
                0.06558447331190109,
                -0.06462635099887848,
                -0.011064319871366024,
                -0.015142777003347874,
                0.016452563926577568,
                0.0033000782132148743,
                -0.002213215222582221,
                0.008443024009466171,
                0.006982580292969942,
                -0.04027076065540314,
                -0.08039932698011398,
                2.40127860706707e-07,
                -0.03779483586549759,
                0.029568150639533997,
                -0.006997796706855297,
                0.0005654068663716316,
                -0.006096625234931707,
                0.03579186275601387,
                -0.05577486380934715,
                0.04967151954770088,
                0.025772687047719955,
                -0.003521906677633524,
                0.07008292526006699,
                0.03125642240047455,
                -0.014891483820974827,
                0.0054951924830675125,
                0.03953378275036812,
                -0.04046189785003662,
                0.014985055662691593,
                -0.0299602709710598,
                0.057715170085430145,
                -0.07706625014543533,
                -0.01260012574493885,
                -0.014921383932232857,
                0.03963411971926689,
                0.010950140655040741,
                -0.017150195315480232,
                -0.006667299196124077,
                -0.017472408711910248,
                -0.025191301479935646,
                0.01826169155538082,
                0.01534043624997139,
                0.010136714205145836,
                -0.07717917114496231,
                -0.04460590332746506,
                0.007253971882164478,
                0.009397834539413452,
                -0.050046104937791824,
                0.03870610147714615,
                -0.004694999195635319,
                -0.023926591500639915,
                0.07079073041677475,
                -0.007876288145780563,
                -0.008752085268497467,
                -0.01322090532630682,
                0.04278379678726196,
                -0.0009034420363605022,
                -0.023675156757235527,
                -0.006392008159309626,
                0.043390918523073196,
                -0.052931733429431915,
                -0.01708471029996872,
                0.004165406804531813,
                0.06652325391769409,
                0.02469564415514469,
                0.07808101922273636,
                -0.0015964196063578129,
                0.023859353736042976,
                -0.0032899787183851004,
                0.039852071553468704,
                0.0165200587362051,
                -0.017948200926184654,
                -0.02303279936313629,
                -0.03320681303739548,
                0.04634419456124306,
                0.015774019062519073,
                -0.05442292243242264,
                0.0719001442193985,
                -0.07101555913686752,
                7.444094631014312e-35,
                -0.01676349528133869,
                -0.03465087711811066,
                -0.027406731620430946,
                0.0017532510682940483,
                0.048210617154836655,
                -0.016943544149398804,
                -0.013589381240308285,
                -0.009029293432831764,
                0.019016413018107414,
                0.08736943453550339,
                -0.03717505931854248,
                0.035816505551338196,
                0.008083708584308624,
                0.023493096232414246,
                -0.045467887073755264,
                -0.05816281586885452,
                0.03033033199608326,
                -0.0026028011925518513,
                -0.037734415382146835,
                -0.041735585778951645,
                0.020281804725527763,
                0.07445299625396729,
                -0.0069724260829389095,
                -0.012983852066099644,
                -0.06604866683483124,
                0.03052395209670067,
                0.009160865098237991,
                -0.0435992069542408,
                0.016447678208351135,
                0.018582388758659363,
                -0.03748328983783722,
                -0.029427610337734222,
                0.008669346570968628,
                0.0035203418228775263,
                -0.000552103971131146,
                -0.05359027534723282,
                -0.004544313531368971,
                0.030176619067788124,
                -0.0010925583774223924,
                0.023532435297966003,
                0.03852943331003189,
                0.05794237181544304,
                0.00749950809404254,
                0.016554728150367737,
                -0.015103889629244804,
                1.977812871700735e-06,
                -0.016352741047739983,
                0.03853240981698036,
                -0.00756578054279089,
                -0.013387034647166729,
                -0.017549213021993637,
                0.009802513755857944,
                -0.015399985946714878,
                -0.026778656989336014,
                0.002870517084375024,
                0.0008679156308062375,
                0.07126499712467194,
                0.06281626969575882,
                0.0015020149294286966,
                -0.029921840876340866,
                0.011688651517033577,
                -0.017463242635130882,
                0.0045856451615691185,
                -0.051186393946409225,
                -0.008762111887335777,
                0.10150889307260513,
                0.04296282306313515,
                -0.006143440026789904,
                0.06510517001152039,
                -0.02550792321562767,
                -0.06121727079153061,
                -0.024742860347032547,
                -0.03958115726709366,
                0.0016240468248724937,
                -0.02240837551653385,
                0.04107576236128807,
                -0.05104394257068634,
                0.1144428551197052,
                -0.012826602905988693,
                0.011182162910699844,
                -0.03861334174871445,
                0.014382913708686829,
                0.020979465916752815,
                0.020615575835108757,
                0.03418982774019241,
                -0.026836225762963295,
                0.0023675907868891954,
                0.00594548461958766,
                0.0844862312078476,
                0.013214769773185253,
                -0.0025069715920835733,
                -0.02370646595954895,
                0.02790994755923748,
                0.005097699351608753,
                0.00929458998143673,
                -0.0006551197730004787,
                0.021769952028989792,
                -0.027261875569820404,
                -0.011240631341934204,
                -0.06090504676103592,
                0.03437820449471474,
                -0.010926876217126846,
                -0.022069934755563736,
                -0.029253175482153893,
                0.005642508156597614,
                0.006040087901055813,
                0.021349867805838585,
                0.0010524922981858253,
                -0.017759723588824272,
                0.020396515727043152,
                -0.04368065297603607,
                -0.010122276842594147,
                0.009794116020202637,
                -0.010747160762548447,
                0.010202973149716854,
                0.04271402955055237,
                0.0768488273024559,
                -0.024586567655205727,
                0.017491549253463745,
                -0.028320690616965294,
                0.04644788056612015,
                -0.012242188677191734,
                0.015112577006220818,
                0.03363081440329552,
                0.01452607847750187,
                0.034830983728170395,
                0.034401148557662964,
                -0.03188225254416466,
                0.028544409200549126,
                -0.043360888957977295,
                0.012695188634097576,
                -0.017159471288323402,
                -0.00852842628955841,
                -0.026241401210427284,
                -0.014275562949478626,
                -0.0008068773313425481,
                0.05899880826473236,
                -0.01969875395298004,
                0.010937916114926338,
                -0.006240470334887505,
                0.0041386582888662815,
                0.0036523581948131323,
                -0.06620429456233978,
                0.01555836945772171,
                -0.010097409598529339,
                0.011906287632882595,
                0.06243232637643814,
                -0.013567464426159859,
                -0.039102088660001755,
                -0.03180239349603653,
                -0.02582642249763012,
                -0.002141073113307357,
                -0.033673714846372604,
                -0.06827627122402191,
                -0.0020333565771579742,
                -0.03775308281183243,
                -0.012930812314152718,
                0.010645263828337193,
                -0.06505283713340759,
                -0.007219239603728056,
                -0.013178758323192596,
                -0.016933780163526535,
                0.015421860851347446,
                0.07501421123743057,
                0.013397959060966969,
                0.07777062803506851,
                0.03237256780266762,
                -0.022738821804523468,
                -0.0028341992292553186,
                0.014587120153009892,
                -0.009315316565334797,
                -0.003380546811968088,
                0.0094909081235528,
                0.027869392186403275,
                -0.037265367805957794,
                -0.016406098380684853,
                0.026298167183995247,
                0.07185649871826172,
                0.0090799480676651,
                -0.021482858806848526,
                -0.012079065665602684,
                0.008591243997216225,
                -0.039060771465301514,
                -0.020620014518499374,
                0.12531708180904388,
                -0.009374469518661499,
                0.054988063871860504,
                -0.027111636474728584,
                -0.04856984689831734,
                -0.038378916680812836,
                -0.016024049371480942,
                -0.038968585431575775,
                -0.03198346495628357,
                0.003012449247762561,
                0.019370662048459053,
                0.0808587297797203,
                -0.022899692878127098,
                -0.024013914167881012,
                0.016455480828881264,
                0.008933603763580322,
                0.020844561979174614,
                0.032413940876722336,
                -0.014464209787547588,
                -0.042892858386039734,
                0.032842621207237244,
                0.012751448899507523,
                0.005187057889997959,
                0.033566348254680634,
                -0.009864917024970055,
                0.009723585098981857,
                -0.039654187858104706,
                -0.00022884513600729406,
                -0.02497018501162529,
                0.08920349925756454,
                0.05598106235265732,
                -0.038907233625650406,
                -0.016713466495275497,
                -0.022292857989668846,
                -0.0038846188690513372,
                0.05126189813017845,
                -0.008979911915957928,
                0.026448961347341537,
                0.0009399508708156645,
                -0.03363516554236412,
                0.03195146471261978,
                -0.05135893076658249,
                0.009373112581670284,
                0.036758460104465485,
                0.04554104804992676,
                -0.09172730147838593,
                0.020747771486639977,
                -0.01615537516772747,
                0.06156698986887932,
                -0.08176037669181824,
                0.007416073698550463,
                0.00433069933205843,
                -0.015602651983499527,
                -0.027072150260210037,
                0.037282656878232956,
                0.017931168898940086,
                -0.010861634276807308,
                -0.00902412086725235,
                0.042049750685691833,
                -0.0035423929803073406,
                -0.002766135847195983,
                0.03174590691924095,
                -0.035399146378040314,
                0.009088736027479172,
                -0.018793290480971336,
                -0.02752370573580265,
                -0.15493084490299225,
                -0.027067694813013077,
                -0.026909232139587402,
                0.024780770763754845,
                -0.037682097405195236,
                0.022371338680386543,
                0.03194412216544151,
                -0.042724959552288055,
                -0.0005983266746625304,
                -0.014963824301958084,
                -0.03358958289027214,
                -0.0495690256357193,
                -0.0187331922352314,
                0.04452410340309143,
                0.013031767681241035,
                0.05008816346526146,
                -0.07261889427900314,
                0.015340271405875683,
                0.05882525444030762,
                -0.036860205233097076,
                -0.012107878923416138,
                -0.0241201501339674,
                -0.023985618725419044,
                0.005501804873347282,
                0.004558653570711613,
                0.051529694348573685,
                -0.006338992156088352,
                0.04242623597383499,
                0.019907554611563683,
                0.00682980939745903,
                0.005980527028441429,
                0.004548686556518078,
                -0.00830562599003315,
                0.029824743047356606,
                -0.01496857963502407,
                0.01795559749007225,
                -0.0283548291772604,
                -0.08543802052736282,
                0.005517470650374889,
                -0.03514762967824936,
                -0.025539929047226906,
                -0.01686585694551468,
                0.025037089362740517,
                -0.018794415518641472,
                0.023228123784065247,
                0.015232917852699757,
                0.0017206068150699139,
                0.040345098823308945,
                0.017796244472265244,
                0.003761169733479619,
                -0.0010766728082671762,
                -0.05112249404191971,
                0.007912440225481987,
                0.004577285144478083,
                -0.026020459830760956,
                0.013798517175018787,
                -0.013072787784039974,
                0.005569801200181246,
                0.0021473653614521027,
                -0.016347328200936317,
                0.03691473230719566,
                -0.015887929126620293,
                0.020106475800275803,
                -0.005540317855775356,
                -0.03421332687139511,
                -0.057567570358514786,
                -0.05061750486493111,
                0.014732906594872475,
                -0.02383589930832386,
                -0.035320792347192764,
                0.0656929537653923,
                -0.005871166475117207,
                0.01766226254403591,
                -0.0018641477217897773,
                -0.01848202385008335,
                -0.04074307158589363,
                0.0014273382257670164,
                0.03918304294347763,
                -0.03228546679019928,
                0.028256820514798164,
                0.10295082628726959,
                0.005388730671256781,
                -0.017275312915444374,
                -0.023001575842499733,
                0.009832136332988739,
                0.024693749845027924,
                0.006192216649651527,
                0.00870956014841795,
                0.04424130171537399,
                0.09023452550172806,
                0.03448888659477234,
                -0.012049678713083267,
                0.05474264174699783,
                -0.03100852482020855,
                -0.06019151955842972,
                -0.06023554131388664,
                0.08712118119001389,
                -0.014687635004520416,
                -0.02340663969516754,
                -0.03688802942633629,
                0.06351058930158615,
                0.0037945264484733343,
                -0.04271797463297844,
                0.012359309010207653,
                -0.014643767848610878,
                -0.028498515486717224,
                -0.034032776951789856,
                -0.03136901929974556,
                0.0033755204640328884,
                -0.014887823723256588,
                0.0062451777048408985,
                0.04696032777428627,
                0.008356817997992039,
                -0.02995694987475872,
                -0.004625273402780294,
                0.016639305278658867,
                -0.028768641874194145,
                -0.019924623891711235,
                0.012082171626389027,
                0.03833147883415222,
                -0.03252856805920601,
                -0.027330374345183372,
                -0.019092431291937828,
                0.04271131008863449,
                0.022801779210567474,
                0.02008773759007454,
                0.03660211339592934,
                0.051079943776130676,
                0.03307279199361801,
                -0.006857497151941061,
                0.030611727386713028,
                0.039196547120809555,
                0.0428282655775547,
                -0.0519726499915123,
                0.056051306426525116,
                0.007939169183373451,
                0.05271534249186516,
                -0.00536405248567462,
                -0.06926087290048599,
                -0.059302303940057755,
                -0.011593502014875412,
                0.005267419386655092,
                -0.049252528697252274,
                0.024796701967716217,
                -0.004586418624967337,
                0.004882464185357094,
                -0.05932806059718132,
                0.0006937558064237237,
                0.02530905231833458,
                -0.00034569017589092255,
                0.048723381012678146,
                0.005333940498530865,
                0.012480641715228558,
                0.008179742842912674,
                0.06267628818750381,
                0.005595909431576729,
                -0.026866458356380463,
                -0.02035858854651451,
                0.032419994473457336,
                0.012788095511496067,
                -0.0009212717413902283,
                0.028826363384723663,
                0.08215676993131638,
                0.028525615110993385,
                0.04636220633983612,
                -0.08087226748466492,
                -0.02067406475543976,
                0.07718367129564285,
                0.024826712906360626,
                0.039817243814468384,
                0.044523417949676514,
                -0.035416990518569946,
                -0.005210311617702246,
                -0.04601265490055084,
                0.004058718681335449,
                -0.020152302458882332,
                -0.03144378587603569,
                -0.05371232330799103,
                -0.07085799425840378,
                0.017082851380109787,
                -0.006322835572063923,
                0.007348477374762297,
                -0.013102962635457516,
                0.04943656176328659,
                -0.02841932699084282,
                -0.03454946354031563,
                -0.0015947846695780754,
                -0.03775186091661453,
                0.016205577179789543,
                0.03966815024614334,
                -0.024862386286258698,
                0.027891747653484344,
                -0.015582895837724209,
                -0.022477775812149048,
                -0.02749497815966606,
                -0.011584658175706863,
                0.0056349304504692554,
                0.013736662454903126,
                -0.007605170365422964,
                -0.0286636333912611,
                -0.0035635707899928093,
                0.006495203822851181,
                0.04313500598073006,
                0.039188023656606674,
                0.040191520005464554,
                -0.0793355256319046,
                0.03814344480633736,
                -0.005152130499482155,
                -0.0653301477432251,
                -0.07672324776649475,
                0.015294103883206844,
                -0.03194030001759529,
                0.044407643377780914,
                0.01815713569521904,
                -0.025062164291739464,
                0.020514091476798058,
                0.009007030166685581,
                -0.0682063028216362,
                0.006260607857257128,
                0.023589206859469414,
                0.034124694764614105,
                0.043126434087753296,
                0.042809344828128815,
                -0.014771091751754284,
                -0.004993905778974295,
                -0.05203941464424133,
                -0.020218821242451668,
                0.007057246286422014,
                -0.1021118238568306,
                0.029600568115711212,
                -0.05650264769792557,
                0.015247977338731289,
                -0.035472121089696884,
                0.017433080822229385,
                -0.022713668644428253,
                0.0484943762421608,
                0.02548142895102501,
                -0.026111584156751633,
                -0.04178047180175781,
                0.08586182445287704,
                0.012040349654853344,
                -0.04316248372197151,
                0.03294000402092934,
                -0.010638425126671791,
                0.008727622218430042,
                -0.035917043685913086,
                -0.05465779826045036,
                0.024644117802381516,
                -0.016651445999741554,
                -0.008297145366668701,
                0.026126287877559662,
                0.04071778431534767,
                0.02160678245127201,
                -0.0020164200104773045,
                -0.015718139708042145,
                -0.006102185696363449,
                0.010744824074208736,
                -0.06241006776690483,
                0.012519912794232368,
                0.017973529174923897,
                0.030780835077166557,
                -0.007751830853521824,
                0.028820060193538666,
                0.021802224218845367,
                -0.05762314796447754,
                0.014187239110469818,
                -0.024327527731657028,
                0.020255377516150475,
                0.031136678531765938,
                0.03702216222882271,
                0.015081819146871567,
                0.007218405604362488,
                -0.0491514690220356,
                -0.054977063089609146,
                -0.021380797028541565,
                -0.009233745746314526,
                0.040369126945734024,
                -0.008198453113436699,
                -0.020463084802031517,
                0.022539108991622925,
                0.0018952947575598955,
                0.028240567073225975,
                0.0062171113677322865,
                0.04287154600024223,
                0.020940298214554787,
                0.004759589675813913,
                -0.004657413344830275,
                -0.0038753885310143232,
                -0.016003871336579323,
                -0.05818033218383789,
                0.042823124676942825,
                -0.051582515239715576,
                0.01701502688229084,
                -0.06473648548126221,
                0.019526030868291855,
                0.010853974148631096,
                0.008969186805188656,
                -0.03587194159626961,
                -0.043297383934259415,
                -0.022792037576436996,
                -0.023840708658099174,
                -0.042609766125679016,
                -0.012514103204011917,
                -0.015566089190542698,
                0.005213589407503605,
                0.008427856490015984,
                -0.01687510497868061,
                -0.020452413707971573,
                -0.009420322254300117,
                0.022176695987582207,
                -0.010649016126990318,
                0.007441041991114616,
                -0.007915379479527473,
                -0.10983536392450333,
                -0.02008005604147911,
                0.0245348047465086,
                -8.039882509209316e-33,
                -0.04244035854935646,
                -0.009165673516690731,
                -0.004567182157188654,
                -0.009968854486942291,
                -0.06898001581430435,
                0.00254371901974082,
                0.005081044510006905,
                -0.00034374205279164016,
                -0.010376458056271076,
                0.0013434510910883546,
                0.006654477212578058,
                0.029828904196619987,
                0.03144676238298416,
                0.035758040845394135,
                0.09857214987277985,
                -0.09799123555421829,
                -0.03615418076515198,
                0.03914854675531387,
                0.026816848665475845,
                0.004301437642425299,
                0.03980144485831261,
                0.02960590086877346,
                0.026799064129590988,
                0.050611499696969986,
                0.01836181804537773,
                -0.01120943482965231,
                -0.0391203872859478,
                0.0032071382738649845,
                0.01448284462094307,
                -0.0022433283738791943,
                -0.015009073540568352,
                -0.03508143126964569,
                0.017237719148397446,
                -0.057553138583898544,
                0.026197105646133423,
                0.11726362258195877,
                -0.05792335420846939,
                -0.02927457168698311,
                0.00315556931309402,
                -0.017622418701648712,
                -0.09006494283676147,
                -0.04378344863653183,
                0.012794990092515945,
                0.01704285852611065,
                -0.014030300080776215,
                -0.06163708493113518,
                0.025487443432211876,
                0.025685038417577744,
                -0.005315793678164482,
                0.005714261904358864,
                -0.04513083025813103,
                -0.018704188987612724,
                -0.06285526603460312,
                -0.05451859161257744,
                0.01882966421544552,
                -0.010101387277245522,
                0.0007786899222992361,
                0.00862607266753912,
                0.035428937524557114,
                -0.0021002134308218956,
                -0.11501253396272659,
                -0.000990369007922709,
                0.003915570676326752,
                0.05373818427324295,
                0.013033129274845123,
                0.0154448002576828,
                -0.06867282092571259,
                0.036493316292762756,
                -0.04130585491657257,
                0.01244429126381874,
                -0.03142533451318741,
                0.06993341445922852,
                -0.02220948226749897,
                -0.020307663828134537,
                -0.08447354286909103,
                -0.028580915182828903,
                -0.0406840518116951,
                -0.0027329851873219013,
                0.09982869029045105,
                0.013767367228865623,
                0.010032287798821926,
                0.02528994530439377,
                -0.007609710097312927,
                -0.021731261163949966,
                -0.022415192797780037,
                -0.062076635658741,
                -0.01532891858369112,
                0.046678245067596436,
                -0.006686107721179724,
                -0.0245231781154871,
                -0.07003870606422424,
                0.01007405947893858,
                -0.03617466986179352,
                0.010979457758367062,
                -0.02410023845732212,
                -0.07095393538475037,
                -0.0018524933839216828,
                0.00464507844299078,
                -0.005141003523021936,
                -0.03231514245271683,
                0.00563738914206624,
                0.017512740567326546,
                -0.011225106194615364,
                0.0510404072701931,
                0.007216804660856724,
                0.03915677219629288,
                0.014074007980525494,
                0.0007549641304649413,
                -0.00796548556536436,
                -0.036693692207336426,
                -0.037543293088674545,
                0.005865446757525206,
                -0.009816956706345081,
                -0.014461454935371876,
                -0.001604348886758089,
                0.011571051552891731,
                0.007473462726920843,
                -0.0945378765463829,
                0.00718293571844697,
                -0.025053637102246284,
                0.019824223592877388,
                0.0932573452591896,
                -0.08375241607427597,
                0.004373228643089533,
                -0.04489455744624138,
                0.03539643809199333,
                -0.011244447901844978,
                0.021413929760456085,
                0.02123604528605938,
                -0.034962140023708344,
                -0.012291382998228073,
                -0.03988628461956978,
                2.699637491332396e-07,
                -0.04760206863284111,
                -0.003425703849643469,
                -0.004821339622139931,
                -0.0019988014828413725,
                0.04231113940477371,
                0.03553837537765503,
                0.0022996533662080765,
                0.05292169377207756,
                -0.0033188601955771446,
                0.02372211590409279,
                0.06565972417593002,
                -0.07977569848299026,
                0.014828545972704887,
                -0.040279194712638855,
                0.05977502465248108,
                -0.016437038779258728,
                0.05664803087711334,
                -0.03623347356915474,
                0.06030901521444321,
                0.00863439030945301,
                0.0419183112680912,
                -0.022138472646474838,
                0.0005186177440918982,
                -0.04263165220618248,
                -0.008067467249929905,
                0.0271867997944355,
                0.03130723163485527,
                -0.035138089209795,
                0.1088322252035141,
                -0.012216395698487759,
                0.0457548089325428,
                0.0021429213229566813,
                -0.008418651297688484,
                -0.005194060038775206,
                0.017125796526670456,
                -0.03295319154858589,
                0.06911992281675339,
                0.021479370072484016,
                0.06147841364145279,
                0.009744546376168728,
                -0.02639993280172348,
                -0.046512097120285034,
                0.03136621415615082,
                0.029470598325133324,
                0.015398196876049042,
                -0.00778723880648613,
                -0.008006058633327484,
                0.01717805303633213,
                -0.020969605073332787,
                -0.007072916254401207,
                -0.04399758204817772,
                0.023473680019378662,
                0.03507456183433533,
                0.05164643004536629,
                0.016709230840206146,
                0.010674653574824333,
                0.021794086322188377,
                0.024873727932572365,
                0.018179086968302727,
                -0.007562811020761728,
                0.02536417357623577,
                0.029711946845054626,
                -0.014839326031506062,
                0.08451255410909653,
                -0.003335817251354456,
                -0.011062784120440483,
                -0.015183928422629833,
                8.269188924330231e-35,
                -0.059729624539613724,
                -0.01410782989114523,
                -0.03608575090765953,
                -0.05966321378946304,
                0.01959172636270523,
                0.036369819194078445,
                -0.0537617988884449,
                0.011945147998631,
                0.033265139907598495,
                0.024564538151025772,
                0.008488643914461136,
                0.02733425609767437,
                0.06812633574008942,
                0.0041031925939023495,
                -0.03619157895445824,
                -0.016226058825850487,
                0.039688125252723694,
                -0.03825310245156288,
                0.010407705791294575,
                -0.04656365513801575,
                0.027362007647752762,
                0.0676320418715477,
                0.08836241066455841,
                -0.014474303461611271,
                -0.09692542999982834,
                -0.005360413808375597,
                -0.03132874146103859,
                -0.006029639858752489,
                0.04628606513142586,
                0.016208386048674583,
                -0.020060133188962936,
                0.00585384713485837,
                -0.005831541493535042,
                0.008987141773104668,
                0.01044798456132412,
                0.011270467191934586,
                -0.026760177686810493,
                0.010784775018692017,
                0.01714184880256653,
                -0.010337907820940018,
                -0.04647260904312134,
                0.012295117601752281,
                0.03355781361460686,
                0.02286774478852749,
                -0.04152587801218033,
                1.645521024329355e-06,
                -0.014449396170675755,
                -0.022697322070598602,
                0.004853296093642712,
                -0.06797613203525543,
                -0.0050204480066895485,
                0.07322105020284653,
                0.01252517569810152,
                -0.022234033793210983,
                0.023214586079120636,
                -0.014199578203260899,
                -0.005994240287691355,
                0.006802718620747328,
                0.022700482979416847,
                -0.001338144764304161,
                0.005483653396368027,
                0.00596472155302763,
                0.023229744285345078,
                -0.04272634536027908,
                -0.05041386932134628,
                0.013291674666106701,
                -0.059106096625328064,
                0.008977512829005718,
                -0.014427279122173786,
                -0.09393642097711563,
                -0.03819379210472107,
                0.02770695835351944,
                -0.00011380811338312924,
                -0.06487191468477249,
                0.00023055647034198046,
                0.027585383504629135,
                -0.029546737670898438,
                0.037081021815538406,
                0.022763129323720932,
                0.028088761493563652,
                -0.05987657234072685,
                -0.014976844191551208,
                0.043798353523015976,
                0.01845385506749153,
                0.08423671126365662,
                0.01307410467416048,
                -0.02059750258922577,
                -0.043276675045490265,
                -0.008284917101264,
                -0.011942325159907341,
                -0.016535373404622078,
                -0.046067241579294205,
                0.027761809527873993,
                -0.013358484953641891,
                0.004743794444948435,
                0.03344696760177612,
                -0.011844371445477009,
                -0.003964608535170555,
                0.004208021331578493,
                0.03694092482328415,
                -0.03247730806469917,
                0.028048986569046974,
                -0.0342579185962677,
                -0.04144349694252014,
                0.04755369573831558,
                0.03772832080721855,
                -0.03571441024541855,
                0.03266073018312454,
                0.043544597923755646,
                -0.005681532435119152,
                -0.020569711923599243,
                -0.030618704855442047,
                -0.004520245362073183,
                0.01956392452120781,
                -0.0032464114483445883,
                -0.002316020429134369,
                0.08588068187236786,
                0.03887036070227623,
                -0.027199411764740944,
                -0.012445212341845036,
                0.048493288457393646,
                0.023578809574246407,
                0.054081354290246964,
                0.005586897023022175,
                0.045863594859838486,
                0.011923546902835369,
                -0.00961347483098507,
                -0.0491543672978878,
                -0.030864501371979713,
                -0.0464031845331192,
                0.038010187447071075,
                -0.09384279698133469,
                -0.009028478525578976,
                -0.03534400835633278,
                -0.003619359340518713,
                0.040361907333135605,
                0.04917526990175247,
                0.011648537591099739,
                0.004155756905674934,
                0.003571410896256566,
                -0.036445263773202896,
                -0.010868210345506668,
                -0.018634255975484848,
                -0.005580701399594545,
                0.006257164292037487,
                0.06116072088479996,
                0.028412066400051117,
                0.0037094480358064175,
                -0.001109404838643968,
                -0.07375067472457886,
                0.05623381584882736,
                0.01979917660355568,
                -0.030236877501010895,
                0.00392524991184473,
                0.013093722052872181,
                -0.0032208897173404694,
                -0.015358836390078068,
                0.03635154291987419,
                -0.09574100375175476,
                0.005406995769590139,
                -0.0004866101953666657,
                0.005905455909669399,
                0.03882037475705147,
                0.008563561365008354,
                -0.030822638422250748,
                0.09220808744430542,
                0.01410157885402441,
                -0.04328278824687004,
                -0.01726590469479561,
                -0.009687056764960289,
                0.00342539488337934,
                0.04310021921992302,
                0.02125660888850689,
                0.016386117786169052,
                -0.019395697861909866,
                -0.016538433730602264,
                0.0022397153079509735,
                -0.04193710535764694,
                -0.006738272495567799,
                -0.026015492156147957,
                -0.037383757531642914,
                0.007433111313730478,
                -0.025716761127114296,
                -0.02128296159207821,
                0.020617486909031868,
                0.013580281287431717,
                0.035778336226940155,
                -0.09654998034238815,
                -0.018122047185897827,
                -0.07512214779853821,
                0.017509007826447487,
                0.038125887513160706,
                -0.090023472905159,
                0.03458213433623314,
                -0.0009423538576811552,
                0.058423031121492386,
                -0.005722895264625549,
                0.04864557459950447,
                0.03710014373064041,
                -0.020430028438568115,
                -0.0087483124807477,
                -0.028855925425887108,
                -0.011889368295669556,
                -0.038779690861701965,
                0.026346612721681595,
                -6.280258730839705e-06,
                -0.013154825195670128,
                0.003576490096747875,
                -0.0303779449313879,
                0.004638039506971836,
                -0.10273241996765137,
                -0.027984725311398506,
                -0.019745832309126854,
                0.07094218581914902,
                -0.0009419863927178085,
                -0.032599564641714096,
                0.02844999171793461,
                0.03774430975317955,
                0.01587892323732376,
                0.0037999048363417387,
                -0.0067139072343707085,
                0.01647559553384781,
                -0.027094507589936256,
                -0.032412074506282806,
                -0.01761714555323124,
                0.0003806957683991641,
                0.017461983487010002,
                0.023847538977861404,
                0.004344382788985968,
                -0.022541122511029243,
                -0.00881996937096119,
                -0.016302891075611115,
                0.06563961505889893,
                0.019601888954639435,
                -0.00533020356670022,
                0.01985890604555607,
                -0.05001328885555267,
                -0.03473534807562828,
                -0.0053192866034805775,
                0.06213235855102539,
                -0.02050677314400673,
                -0.006501813884824514,
                0.028700657188892365,
                0.016376374289393425,
                0.02475525066256523,
                0.020256219431757927,
                0.03597954660654068,
                0.11589519679546356,
                -0.027207760140299797,
                0.011805441230535507,
                -0.057134710252285004,
                0.016467563807964325,
                -0.01963960751891136,
                0.004476260859519243,
                0.038081392645835876,
                -0.023620937019586563,
                0.021519556641578674,
                -0.012692571617662907,
                -0.015914449468255043,
                0.02438506856560707,
                -0.03400881960988045,
                -0.007335782516747713,
                -0.026781920343637466,
                0.03621594235301018,
                -0.01707192324101925,
                0.008291693404316902,
                -0.016205117106437683,
                -0.04528040438890457,
                0.0266571082174778,
                0.05049514397978783,
                -0.026894589886069298,
                -0.0017512599006295204,
                -0.009630882181227207,
                0.014355050399899483,
                -0.010080253705382347,
                0.02125135436654091,
                -0.015208547003567219,
                -0.0002612233511172235,
                -0.08464007824659348,
                0.006970438174903393,
                0.030305849388241768,
                0.013052045367658138,
                -0.05354069918394089,
                -0.007477050647139549,
                -0.0040410906076431274,
                -0.0027470793575048447,
                0.012879601679742336,
                0.021820951253175735,
                0.032463815063238144,
                -0.043034616857767105,
                0.009924874641001225,
                -0.008114869706332684,
                -0.016455797478556633,
                -0.03373730182647705,
                0.02958463318645954,
                0.03837339207530022,
                -0.0004895316087640822,
                -0.05378328636288643,
                0.06331618875265121,
                0.043536476790905,
                -0.003835620591416955,
                -0.07007931172847748,
                0.0049666836857795715,
                0.023545337840914726,
                -0.07381846010684967,
                0.00973296258598566,
                -0.005757071077823639,
                -0.027760421857237816,
                0.018331829458475113,
                0.02255578711628914,
                0.04016328230500221,
                -0.0056106033734977245,
                0.01650344580411911,
                0.027408257126808167,
                0.020251253619790077,
                -0.013605930842459202,
                -0.017905060201883316,
                -0.0029617887921631336,
                -0.007396795321255922,
                -0.04150949791073799,
                0.029381874948740005,
                -0.010710079222917557,
                0.05200684070587158,
                0.0040535456500947475,
                0.088856041431427,
                0.0034699714742600918,
                -0.01820226013660431,
                0.05999336391687393,
                -0.0037734108045697212,
                0.023003440350294113,
                0.029801340773701668,
                0.0606396347284317,
                -0.031039981171488762,
                -0.028077898547053337,
                -0.0075251255184412,
                0.010717419907450676,
                0.0008505493751727045,
                0.015948299318552017,
                0.06616587936878204,
                0.06184129789471626,
                -0.022477740421891212,
                -0.019049283117055893,
                0.02127578854560852,
                0.01836279034614563,
                0.00977017916738987,
                -0.035155195742845535,
                0.006604284048080444,
                -0.04407789185643196,
                0.053019143640995026,
                -0.010118778795003891,
                0.002355211181566119,
                0.0070762038230896,
                -0.051251120865345,
                -0.041468746960163116,
                0.013474022969603539,
                -0.06842629611492157,
                -0.05231605842709541,
                0.009446621872484684,
                -0.002380875637754798,
                -0.0765906572341919,
                -0.06654900312423706,
                0.07302521169185638,
                -0.0049184332601726055,
                -0.024540679529309273,
                -0.055291082710027695,
                0.04635538160800934,
                -0.06422816962003708,
                -0.001316247507929802,
                0.051193419843912125,
                0.057479530572891235,
                0.005842168349772692,
                -0.03583917021751404,
                0.025986824184656143,
                0.1332731544971466,
                0.06486532837152481,
                0.07904743403196335,
                0.022355277091264725,
                0.055780574679374695,
                0.06324431300163269,
                0.003293360583484173,
                0.034788358956575394,
                0.010300717316567898,
                0.07867316156625748,
                -0.027700047940015793,
                0.05672454088926315,
                -0.008103197440505028,
                0.021871181204915047,
                0.010727434419095516,
                -0.08941010385751724,
                0.0421023890376091,
                0.025522250682115555,
                0.016099069267511368,
                -0.03841106966137886,
                -0.026011129841208458,
                0.02484874241054058,
                0.016272667795419693,
                -0.014774368144571781,
                -0.010494272224605083,
                0.03133989870548248,
                -0.01564021036028862,
                -0.010844884440302849,
                0.02670280635356903,
                -0.017725065350532532,
                -0.02874038927257061,
                0.05310118943452835,
                0.03665436431765556,
                0.002655238611623645,
                0.002290783217176795,
                -0.008272265084087849,
                0.03775579482316971,
                0.011731642298400402,
                0.046809110790491104,
                0.02100813202559948,
                -0.03837788105010986,
                0.0469670295715332,
                -0.027756670489907265,
                0.05426263436675072,
                -0.041071873158216476,
                -0.004463902208954096,
                0.03183316811919212,
                -0.0729295164346695,
                -0.028386415913701057,
                0.06161478906869888,
                -0.0018759557278826833,
                -0.005763493478298187,
                0.022162165492773056,
                -0.036170292645692825,
                -0.002413870533928275,
                -0.027474991977214813,
                -0.0074865263886749744,
                -0.06830956041812897,
                0.003183122491464019,
                0.026076408103108406,
                0.024134598672389984,
                0.022931300103664398,
                0.013079051859676838,
                -0.009053240530192852,
                0.007583073806017637,
                -0.003205238375812769,
                0.03710846230387688,
                -0.026012742891907692,
                0.032854609191417694,
                0.026740312576293945,
                -0.01698954962193966,
                0.009457743726670742,
                0.03677238151431084,
                -0.04773576185107231,
                0.015242790803313255,
                0.004547538701444864,
                0.06524655222892761,
                -0.003089418401941657,
                0.009424563497304916,
                0.026240086182951927,
                0.018661905080080032,
                -0.0013211233308538795,
                -0.06947381049394608,
                -0.06198141351342201,
                0.06685563176870346,
                -0.05609530955553055,
                -0.06846792250871658,
                0.062061555683612823,
                -0.015630410984158516,
                0.004289120901376009,
                0.022068209946155548,
                -0.007654283195734024,
                0.004403799306601286,
                -0.019212156534194946,
                0.023338623344898224,
                -0.014481686986982822,
                0.03506886586546898,
                0.04819460213184357,
                0.06901180744171143,
                0.040594737976789474,
                -0.031955536454916,
                0.01824922300875187,
                -0.008094733580946922,
                -0.004494344349950552,
                -0.04991813004016876,
                -0.005717316176742315,
                0.054540831595659256,
                0.031243838369846344,
                -0.02588037960231304,
                -0.02457459457218647,
                0.024271490052342415,
                0.03286855295300484,
                0.004011701792478561,
                -0.003946416545659304,
                -0.058265164494514465,
                -0.016111472621560097,
                -0.016220103949308395,
                -0.009459237568080425,
                -0.01542411744594574,
                -0.044264499098062515,
                -0.029159007593989372,
                -0.05771350488066673,
                -0.015745602548122406,
                -0.00493839243426919,
                0.01680399850010872,
                -0.017790192738175392,
                -0.021406810730695724,
                -0.030221659690141678,
                -0.0070035988464951515,
                -0.008524886332452297,
                -0.020655136555433273,
                -0.07671193033456802,
                0.021191464737057686,
                -0.02089955098927021,
                -0.06063813343644142,
                -0.009451271966099739,
                0.014598364010453224,
                -0.013179250061511993,
                -0.039420053362846375,
                0.003468935377895832,
                0.006589255295693874,
                -0.028906282037496567,
                -0.0007198810926638544,
                -0.020434172824025154,
                0.012943189591169357,
                0.07203216850757599,
                -0.013057523407042027,
                -0.014180649071931839,
                0.022885218262672424,
                -0.006680984515696764,
                -0.04788442701101303,
                -0.042660389095544815,
                0.025687286630272865,
                0.029086224734783173,
                -0.025802232325077057,
                -0.05370955541729927,
                0.025070514529943466,
                0.02753065712749958,
                -0.002053702250123024,
                -0.02329172194004059,
                -0.019257327541708946,
                -0.01605178415775299,
                0.024923447519540787,
                0.002052446361631155,
                -0.007467904593795538,
                -0.0036091890651732683,
                -0.03376620262861252,
                0.03773132339119911,
                -0.025173252448439598,
                0.023420855402946472,
                -0.04274214059114456,
                -0.0001901955547509715,
                -0.02765127830207348,
                0.017283746972680092,
                0.02624487318098545,
                0.03061055764555931,
                0.010396500118076801,
                0.02680879458785057,
                -0.07105010747909546,
                0.0050290776416659355,
                0.033895332366228104,
                0.022679340094327927,
                0.046973228454589844,
                -0.0403229296207428,
                -0.049370769411325455,
                0.055430904030799866,
                0.011246797628700733,
                -0.0030639381147921085,
                -0.023880554363131523,
                -0.0069038462825119495,
                -0.0924832671880722,
                -0.010160407051444054,
                0.012300992384552956,
                -6.291207562034481e-33,
                -0.021652070805430412,
                -0.018493058159947395,
                -0.023969147354364395,
                -0.015646224841475487,
                -0.0924113541841507,
                0.014932023361325264,
                0.015526196919381618,
                0.03079974092543125,
                -0.0533449649810791,
                0.009087936952710152,
                -0.05942651629447937,
                -0.019960420206189156,
                0.007764282636344433,
                -0.01882757991552353,
                0.0701705813407898,
                -0.02026393823325634,
                -0.04266868904232979,
                0.02581075392663479,
                0.00030275952303782105,
                -0.00857712235301733,
                -0.001988658681511879,
                -0.005248110741376877,
                0.02328464575111866,
                -0.006065649911761284,
                0.03168407455086708,
                -0.0039587970823049545,
                -0.0323156975209713,
                -0.0017771486891433597,
                0.056029681116342545,
                0.004716286435723305,
                -0.020123351365327835,
                -0.08223507553339005,
                -0.018849145621061325,
                -0.002226446056738496,
                0.02872980199754238,
                0.012185940518975258,
                -0.0005798658821731806,
                -0.01582479104399681,
                0.006181873846799135,
                -0.000608792994171381,
                -0.046769194304943085,
                -0.043497443199157715,
                -0.04341020807623863,
                0.033720746636390686,
                -0.02321534976363182,
                -0.05979597941040993,
                -0.020691702142357826,
                0.058333780616521835,
                0.0035729771479964256,
                -0.060354430228471756,
                -0.033568475395441055,
                0.0070170387625694275,
                -0.03019704855978489,
                -0.06452389806509018,
                -0.01983790472149849,
                -0.04473539814352989,
                0.030424240976572037,
                -0.008046824485063553,
                -0.013595828786492348,
                -0.018563788384199142,
                -0.043400127440690994,
                -0.005310749635100365,
                -0.04236559197306633,
                -0.0048154196701943874,
                0.006611610762774944,
                0.02530575543642044,
                -0.011321705766022205,
                0.0368652306497097,
                0.0497732013463974,
                -0.0016850822139531374,
                -0.021997153759002686,
                0.05267811566591263,
                0.07843197137117386,
                -0.06902345269918442,
                -0.04496310278773308,
                -0.05517781898379326,
                -0.020348696038126945,
                0.03212415799498558,
                0.0637909322977066,
                -0.006199877243489027,
                0.01667257398366928,
                0.040617797523736954,
                -0.0012861102586612105,
                0.011975987814366817,
                0.02201821655035019,
                -0.07239541411399841,
                0.01651262678205967,
                0.059348080307245255,
                -0.03493540361523628,
                -0.00930921919643879,
                -0.006761298980563879,
                -0.04503320902585983,
                0.02027452178299427,
                0.051708534359931946,
                -0.06731515377759933,
                0.05229707807302475,
                -0.0006288540316745639,
                0.010441997088491917,
                0.011894668452441692,
                0.0026842772495001554,
                0.03293915465474129,
                0.02330561727285385,
                -0.041695334017276764,
                0.008830460719764233,
                0.03893914818763733,
                0.01617860421538353,
                -0.006716928444802761,
                0.05491334944963455,
                -0.048330169171094894,
                -0.022100774571299553,
                0.0038790018297731876,
                0.0056954785250127316,
                -0.02203017845749855,
                -0.003925906959921122,
                -0.025376947596669197,
                0.011439847759902477,
                0.008587032556533813,
                -0.0939120426774025,
                -0.028446204960346222,
                -0.04178116098046303,
                0.016259806230664253,
                0.1110241562128067,
                0.02046680636703968,
                0.0007963189273141325,
                -0.042246803641319275,
                0.0056100692600011826,
                -0.012124622240662575,
                0.030770860612392426,
                -0.044684164226055145,
                -0.011045548133552074,
                -0.01369357667863369,
                0.006597564555704594,
                2.325211880815914e-07,
                -0.10637928545475006,
                0.001359908259473741,
                -0.02891511656343937,
                -0.012844828888773918,
                0.008967950008809566,
                0.07630383968353271,
                -0.0854809582233429,
                -0.0009603526559658349,
                -0.014200246892869473,
                -0.008226119913160801,
                0.0688081607222557,
                -0.017717678099870682,
                0.014164237305521965,
                -0.01840299926698208,
                0.05744314193725586,
                -0.08566496521234512,
                0.04134446755051613,
                -0.04779443144798279,
                0.039320796728134155,
                -0.04921741783618927,
                -0.03385293856263161,
                0.004803360439836979,
                -0.0011813102755695581,
                0.011244374327361584,
                -0.0029093569610267878,
                0.060961268842220306,
                5.317716841091169e-06,
                -0.03966662287712097,
                0.05072009935975075,
                0.007459708023816347,
                0.05851161852478981,
                -0.09313510358333588,
                -0.057689517736434937,
                0.005967995151877403,
                -0.018474118784070015,
                -0.002877187915146351,
                0.064421646296978,
                0.04217441380023956,
                0.00043969732359983027,
                0.007248825393617153,
                0.024236995726823807,
                -0.020350653678178787,
                -0.0303557887673378,
                0.026791375130414963,
                -0.02025185525417328,
                0.07995034754276276,
                -0.011669640429317951,
                -0.028357530012726784,
                -0.013250714167952538,
                -0.05137573927640915,
                0.011380371637642384,
                0.0687837302684784,
                -0.024222398176789284,
                0.025069380179047585,
                0.022697703912854195,
                0.00784087460488081,
                0.03753412514925003,
                0.00743502750992775,
                0.041743434965610504,
                -0.06563562154769897,
                -0.019499285146594048,
                0.050385259091854095,
                -0.00978798046708107,
                0.03616448864340782,
                -0.033964499831199646,
                0.08066771179437637,
                -0.0029272008687257767,
                1.0820973068464999e-34,
                -0.0007798589649610221,
                -0.05499695613980293,
                -0.009496251121163368,
                0.041333530098199844,
                0.043770018965005875,
                -0.0016486450331285596,
                0.02244986779987812,
                0.00961918756365776,
                0.006517733447253704,
                0.008059599436819553,
                -0.00668181199580431
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\x89\\xf4\\xb2HN\\xd2\\x8b\\x97M\\x1c\\x86]o\\x8b\\x92\\x8c'",
        "id__": "5fd7ca5a-42e0-4f26-94df-efca9f43006a"
    },
    {
        "Certificates": {
            "name": [
                "BOSIET, MIST, RPS"
            ],
            "rep": [
                -0.035801153630018234,
                -0.09140287339687347,
                -0.017396297305822372,
                0.03383766487240791,
                -0.043542515486478806,
                -0.03374633565545082,
                -0.048209626227617264,
                -0.008309462107717991,
                0.04653870686888695,
                0.004860956221818924,
                0.018055789172649384,
                -0.0014301757328212261,
                0.02802283503115177,
                0.08182115852832794,
                -0.0004774715343955904,
                -0.09396359324455261,
                0.019039425998926163,
                0.02272072248160839,
                -0.02387678436934948,
                -0.004022306762635708,
                0.05191325023770332,
                0.003562773810699582,
                -0.0026871415320783854,
                0.05513009428977966,
                0.06757517158985138,
                -0.03130466490983963,
                -0.035842034965753555,
                -0.011543186381459236,
                0.020238475874066353,
                -0.023606056347489357,
                0.04022073373198509,
                -0.022029954940080643,
                0.02093128114938736,
                0.013299685902893543,
                1.9446206351858564e-06,
                0.002516881562769413,
                -0.0067202746868133545,
                0.0070678009651601315,
                -0.04090891778469086,
                -0.03266238048672676,
                -0.03130950778722763,
                0.00740631902590394,
                0.030221136286854744,
                0.0315062552690506,
                0.02848196215927601,
                -0.017630506306886673,
                0.03276056423783302,
                0.018689142540097237,
                0.016048012301325798,
                0.05001448094844818,
                0.010622965171933174,
                0.0019309805938974023,
                -0.06654632091522217,
                -0.0010680811246857047,
                0.13667026162147522,
                -0.06581754237413406,
                0.007226143032312393,
                -0.09348373115062714,
                0.05945640057325363,
                0.06505492329597473,
                -0.050704456865787506,
                -0.027947701513767242,
                -0.055693209171295166,
                0.012138755060732365,
                0.02893764339387417,
                0.017397364601492882,
                0.07569704204797745,
                -0.03863059729337692,
                -0.01498427614569664,
                -0.007387764286249876,
                0.004118768498301506,
                0.0030216090381145477,
                -0.036618661135435104,
                0.056745048612356186,
                -0.05262564495205879,
                -0.013704357668757439,
                -0.01431688666343689,
                0.0060575855895876884,
                -0.03705549240112305,
                -0.005874826107174158,
                -0.013537672348320484,
                0.09378104656934738,
                0.015653837472200394,
                0.019653212279081345,
                0.06490210443735123,
                0.004443598445504904,
                0.004186074249446392,
                -0.020422285422682762,
                0.027665341272950172,
                0.056420162320137024,
                0.014964512549340725,
                -0.03706756979227066,
                0.004349080380052328,
                -0.0423165038228035,
                -0.04331298545002937,
                0.014826426282525063,
                0.0355970561504364,
                0.04841366782784462,
                -0.009206144139170647,
                0.1139448806643486,
                0.009171534329652786,
                -0.031448837369680405,
                0.04314817488193512,
                0.01577233523130417,
                -0.054793864488601685,
                -0.023479951545596123,
                0.004597417078912258,
                -0.04814355820417404,
                0.012673744931817055,
                0.024210955947637558,
                -0.029292285442352295,
                -0.037064339965581894,
                -0.015517564490437508,
                0.03334130719304085,
                -0.012608303688466549,
                -0.0004217690438963473,
                -0.05843648687005043,
                -0.039495840668678284,
                -0.03503294289112091,
                0.022638579830527306,
                0.1347436010837555,
                -0.016308045014739037,
                0.021925898268818855,
                0.07169784605503082,
                -0.01614091731607914,
                0.0033956284169107676,
                0.019329914823174477,
                -0.017938654869794846,
                0.0087435906752944,
                0.007906537503004074,
                -0.06795147806406021,
                -0.03650316596031189,
                -0.05806325748562813,
                -0.029947489500045776,
                0.007149744313210249,
                0.03283069282770157,
                0.03224194422364235,
                0.0006939470185898244,
                0.00022028169769328088,
                0.021152352914214134,
                -0.00832846574485302,
                -0.05330083146691322,
                -0.02175765484571457,
                -0.018670333549380302,
                -0.037732865661382675,
                0.0016044600633904338,
                0.020700836554169655,
                0.02144242823123932,
                -0.005586719606071711,
                0.03860198333859444,
                -0.022351181134581566,
                -0.012499452568590641,
                -0.04032009840011597,
                0.04734421521425247,
                -0.01598147116601467,
                -0.03773806616663933,
                -0.05792168155312538,
                0.022814271971583366,
                -0.05855104327201843,
                -0.009921276941895485,
                -0.02923847734928131,
                0.010569825768470764,
                0.022773364558815956,
                -0.007136974949389696,
                0.013420929200947285,
                0.031138848513364792,
                0.03634248673915863,
                -0.03925619646906853,
                -0.02701161429286003,
                -0.02703862451016903,
                -0.015183286741375923,
                -0.04666979983448982,
                -0.0622306689620018,
                -0.058648061007261276,
                -0.0033623576164245605,
                -0.030982481315732002,
                0.012801123782992363,
                -0.03375616297125816,
                -0.031593214720487595,
                -0.011417482048273087,
                0.06159025430679321,
                -0.07154684513807297,
                0.06774312257766724,
                0.008139442652463913,
                0.08511923253536224,
                0.04409071058034897,
                -0.0017474876949563622,
                0.023006973788142204,
                -0.008786927908658981,
                0.004918316379189491,
                -0.025422949343919754,
                0.03776434436440468,
                -0.09086087346076965,
                0.03245684504508972,
                -0.015812553465366364,
                0.024227123707532883,
                -0.0007278765551745892,
                -0.01983952522277832,
                -0.037352610379457474,
                0.026420388370752335,
                -0.05568746104836464,
                -0.05067436024546623,
                -0.011231685057282448,
                -0.006343943998217583,
                -0.03658955916762352,
                -0.016557633876800537,
                -0.009332273155450821,
                -0.029516184702515602,
                -0.05188160389661789,
                0.02688681147992611,
                0.04148317873477936,
                0.04424043372273445,
                0.021390514448285103,
                -0.0029486853163689375,
                -0.01985194720327854,
                -0.001073851133696735,
                0.016916126012802124,
                0.0714854970574379,
                -0.0626140758395195,
                -0.0461755134165287,
                -0.056996509432792664,
                0.05196705833077431,
                0.05424422025680542,
                -0.0015774411149322987,
                0.04701188579201698,
                0.04636818915605545,
                -0.018102604895830154,
                -0.024166321381926537,
                -0.027082938700914383,
                0.014955599792301655,
                0.01980075240135193,
                0.024345550686120987,
                0.009130571037530899,
                0.010367306880652905,
                0.015178948640823364,
                0.030839959159493446,
                0.048267778009176254,
                -0.10186796635389328,
                -0.011153324507176876,
                0.06404408067464828,
                -0.0340891070663929,
                -0.03330598399043083,
                -0.02224712073802948,
                -0.027263600379228592,
                0.028469037264585495,
                0.0038991798646748066,
                0.024451514706015587,
                0.0036084128078073263,
                -0.003346051787957549,
                0.017853010445833206,
                0.0016247829189524055,
                -0.01247568428516388,
                0.0046878051944077015,
                0.035542070865631104,
                0.03357396647334099,
                -0.024217765778303146,
                0.046989526599645615,
                -0.00909552350640297,
                -0.00409504072740674,
                -0.021530142053961754,
                -0.037596456706523895,
                0.008805406279861927,
                -0.007742457091808319,
                0.02627735584974289,
                0.04506118223071098,
                0.005793672986328602,
                0.052575837820768356,
                0.050563957542181015,
                0.04705226048827171,
                0.008962827734649181,
                -0.0029135544318705797,
                -0.021739531308412552,
                0.065086729824543,
                -0.024145277217030525,
                0.016276681795716286,
                -0.017662102356553078,
                -0.045437488704919815,
                0.048284173011779785,
                0.007752269506454468,
                0.018790505826473236,
                -0.026505030691623688,
                -0.05377107858657837,
                0.0066854930482804775,
                0.017674840986728668,
                0.026401333510875702,
                0.024993840605020523,
                -0.03633499518036842,
                -0.030727379024028778,
                -0.01509842462837696,
                -0.024897310882806778,
                -0.024646291509270668,
                -0.037426501512527466,
                0.06370721757411957,
                -0.02965874969959259,
                -0.02196473442018032,
                -0.005089989397674799,
                0.00448165088891983,
                0.028918659314513206,
                -0.03282323479652405,
                0.030070101842284203,
                -0.05606525391340256,
                -0.014345001429319382,
                -0.04252840206027031,
                -0.02345992624759674,
                -0.044629573822021484,
                -0.06372909247875214,
                0.07315343618392944,
                0.03406345844268799,
                0.022648755460977554,
                0.03869074955582619,
                0.01436405349522829,
                -0.006190156564116478,
                0.013547167181968689,
                0.04694940522313118,
                0.021274542436003685,
                0.05793016776442528,
                -0.03772764280438423,
                -0.0720197930932045,
                0.014990827068686485,
                0.011937781237065792,
                0.02012263797223568,
                9.962563490262255e-05,
                -0.018536245450377464,
                -0.020398523658514023,
                -0.004650469403713942,
                -0.07553470134735107,
                -0.03579983860254288,
                -0.0012697413330897689,
                -0.09159087389707565,
                0.021049998700618744,
                0.03217758983373642,
                -0.0906834676861763,
                -0.02255580946803093,
                0.01098738331347704,
                -0.013550928793847561,
                0.028230566531419754,
                -0.0034347097389400005,
                -0.13503128290176392,
                0.029576385393738747,
                0.024565590545535088,
                -0.010158835910260677,
                -0.04843103885650635,
                -0.019381534308195114,
                0.007471541874110699,
                0.0030727162957191467,
                -0.015899579972028732,
                0.05332207679748535,
                0.017831696197390556,
                -0.07033902406692505,
                0.009996753185987473,
                0.032355692237615585,
                -0.02341546304523945,
                -0.004104958847165108,
                -0.06074027717113495,
                -0.013834024779498577,
                -0.013510643504559994,
                -0.019413957372307777,
                -0.04495268315076828,
                0.06577417254447937,
                -0.011274742893874645,
                0.0020049153827130795,
                0.019106561318039894,
                0.057670608162879944,
                -0.011749793775379658,
                -0.006040974520146847,
                0.021966733038425446,
                0.015878943726420403,
                -0.013297546654939651,
                -0.03177674859762192,
                -0.04515628516674042,
                0.06223393231630325,
                0.016440464183688164,
                -0.015671543776988983,
                0.006019813008606434,
                -0.020358722656965256,
                0.050502095371484756,
                -0.012804119847714901,
                -0.022519640624523163,
                0.04626043885946274,
                0.015488652512431145,
                -9.444625175092369e-05,
                0.04129951819777489,
                0.0197035800665617,
                -0.01151483878493309,
                -0.010458476841449738,
                -0.024230295792222023,
                -0.042062800377607346,
                -0.014432848431169987,
                -0.043728917837142944,
                0.04299969971179962,
                0.033081959933042526,
                0.0017879316583275795,
                0.051774054765701294,
                -0.04225374758243561,
                0.0016501022037118673,
                -0.018754398450255394,
                -0.012900009751319885,
                -0.01317434199154377,
                0.01132479403167963,
                0.030165469273924828,
                0.016064925119280815,
                0.020959490910172462,
                0.027125589549541473,
                0.019975529983639717,
                -0.0031204642727971077,
                -0.05473336949944496,
                0.03766481950879097,
                -0.007742313202470541,
                0.020789971575140953,
                -0.023575374856591225,
                0.025315506383776665,
                -0.014965282753109932,
                0.0037231300957500935,
                -0.023091649636626244,
                -0.0354965440928936,
                -0.010156293399631977,
                -0.006011065095663071,
                -0.018008241429924965,
                -0.00982299167662859,
                0.01496370043605566,
                -0.011856631375849247,
                0.04856383800506592,
                -0.04406629875302315,
                -0.020747238770127296,
                0.013699375092983246,
                -0.025677168741822243,
                0.05103002488613129,
                0.04862320423126221,
                0.03320889174938202,
                0.007353302091360092,
                0.044717952609062195,
                -0.10794802755117416,
                -0.03883836418390274,
                0.016792209818959236,
                0.054391924291849136,
                -0.06817474216222763,
                0.0026776674203574657,
                -0.018416058272123337,
                0.01067212875932455,
                -0.005313867703080177,
                0.04758777469396591,
                -0.0014323487412184477,
                0.08245738595724106,
                -0.0007970496080815792,
                0.017386730760335922,
                -0.043807461857795715,
                0.012306513264775276,
                0.03040042333304882,
                -0.05864265561103821,
                0.013784107752144337,
                -0.015542292967438698,
                -0.01856713928282261,
                0.05455956980586052,
                0.056100934743881226,
                -0.028896866366267204,
                -0.004206818528473377,
                -0.022146614268422127,
                -0.0007917091134004295,
                -0.02677932009100914,
                0.07391110807657242,
                0.048223935067653656,
                0.04614764079451561,
                -0.030755406245589256,
                -0.01380724087357521,
                0.04604001343250275,
                -0.013447575271129608,
                -0.030674653127789497,
                0.019502414390444756,
                -0.004079500213265419,
                -0.025184547528624535,
                -0.00397984404116869,
                -0.028679387643933296,
                -0.03284364193677902,
                0.025479631498456,
                0.07880064845085144,
                -0.050930239260196686,
                0.0014787924010306597,
                0.0369865708053112,
                0.03995874524116516,
                0.022103063762187958,
                0.011305226013064384,
                0.07334242761135101,
                -0.011098547838628292,
                -0.006473938003182411,
                0.006131760776042938,
                -0.056399691849946976,
                0.015563150867819786,
                0.020028386265039444,
                -0.002193019026890397,
                0.0496821254491806,
                0.011694477871060371,
                -0.01238370779901743,
                -0.0005447339499369264,
                -0.0316755585372448,
                -0.0072281197644770145,
                -0.012767969630658627,
                0.02034953236579895,
                -0.05867869034409523,
                0.0376104936003685,
                0.004042130894958973,
                -0.007482554297894239,
                -0.0070418999530375,
                0.03152541443705559,
                0.0155245466157794,
                0.03411678224802017,
                0.0232673492282629,
                -0.015834959223866463,
                0.01855875737965107,
                0.010134848766028881,
                0.000329428818076849,
                0.020322365686297417,
                -0.006376137025654316,
                -0.05104527994990349,
                0.023773513734340668,
                -0.01721963658928871,
                0.05140938609838486,
                0.03792897239327431,
                -0.02315930277109146,
                0.0007701315334998071,
                -0.08583580702543259,
                -0.019705241546034813,
                -0.023202847689390182,
                0.0143384775146842,
                -0.036171093583106995,
                0.00242990767583251,
                -0.030165240168571472,
                0.014601028524339199,
                -0.04354633018374443,
                0.0004780793678946793,
                0.024203382432460785,
                -0.040876515209674835,
                -0.02112497203052044,
                0.01333725918084383,
                -0.0682748556137085,
                -0.04604433849453926,
                0.06284681707620621,
                -0.07799900323152542,
                0.04258469492197037,
                0.06345877796411514,
                -0.005853361450135708,
                -0.048981502652168274,
                0.06415087729692459,
                0.03730370104312897,
                -0.024522585794329643,
                0.018641233444213867,
                0.06356798857450485,
                -0.05185506492853165,
                0.01167523954063654,
                -0.005125187803059816,
                0.04393748193979263,
                0.0012696805642917752,
                0.0495607927441597,
                -0.06492361426353455,
                -0.0009676999761722982,
                -0.0010155999334529042,
                -6.485521186329221e-33,
                0.006180714815855026,
                -0.03649885207414627,
                0.01265752874314785,
                -0.06833945214748383,
                -0.051085494458675385,
                0.03886805474758148,
                0.032859135419130325,
                -0.005194826517254114,
                -0.046821240335702896,
                -0.011459033004939556,
                -0.020620768889784813,
                -0.0821409597992897,
                0.024688294157385826,
                0.00877247005701065,
                0.010638720355927944,
                -0.05275300145149231,
                0.01859375089406967,
                0.027860594913363457,
                -0.0018109848024323583,
                0.021916648373007774,
                0.03747697174549103,
                -0.027880921959877014,
                0.0134584316983819,
                0.02388228476047516,
                -0.021124044433236122,
                -0.033107563853263855,
                -0.03812118247151375,
                0.06267385929822922,
                -0.059145908802747726,
                -0.008126568980515003,
                0.006473718676716089,
                -0.007841289043426514,
                0.0038391456473618746,
                0.017175888642668724,
                -0.019187739118933678,
                0.038753923028707504,
                -0.002543324837461114,
                -0.03399844840168953,
                -0.03375843167304993,
                0.00706139113754034,
                0.011233866214752197,
                0.032673537731170654,
                -0.0014510533073917031,
                0.013600053265690804,
                -0.005829323083162308,
                -0.054297346621751785,
                -0.03636859357357025,
                -0.018796555697917938,
                -0.023910123854875565,
                -0.011912266723811626,
                0.053635142743587494,
                -0.00045934790978208184,
                0.03715579956769943,
                -0.01318319421261549,
                -0.04347575455904007,
                -0.02725663036108017,
                -0.029833348467946053,
                -0.0031684464775025845,
                -0.053475990891456604,
                0.0034022689796984196,
                0.01848931796848774,
                0.015143459662795067,
                0.021499445661902428,
                -0.029075980186462402,
                0.013404091820120811,
                0.0027544547338038683,
                0.0470096655189991,
                0.018284674733877182,
                0.05246175453066826,
                0.07527635991573334,
                -0.03646465390920639,
                0.06623814254999161,
                0.03545185923576355,
                0.001970564713701606,
                -0.0027808176819235086,
                0.014864037744700909,
                -0.0048420364037156105,
                0.01148855872452259,
                0.0002127295738318935,
                0.037724923342466354,
                -0.046556226909160614,
                0.00719817727804184,
                0.0017301009502261877,
                0.018577558919787407,
                -0.01938343606889248,
                0.06340581178665161,
                -0.00344356382265687,
                0.0002884019340854138,
                0.02526571974158287,
                0.002383557613939047,
                0.03745187446475029,
                -0.020497266203165054,
                0.010756502859294415,
                0.025592243298888206,
                -0.01000851858407259,
                -0.04171165078878403,
                0.02250179648399353,
                -0.043867819011211395,
                0.012460831552743912,
                -0.04086454212665558,
                0.06528103351593018,
                0.04472962021827698,
                0.012210734188556671,
                0.02350594289600849,
                -0.029919229447841644,
                0.02912050299346447,
                0.00782410241663456,
                -0.025784403085708618,
                -0.022680671885609627,
                -0.002091455738991499,
                0.004156296141445637,
                0.08230388164520264,
                -0.013168156147003174,
                0.07807037234306335,
                0.040032148361206055,
                -0.02986650913953781,
                -0.016965797170996666,
                0.0041701095178723335,
                -0.003405556548386812,
                -0.04400184378027916,
                0.017364315688610077,
                0.018232926726341248,
                0.018849490210413933,
                0.02022455260157585,
                -0.027680976316332817,
                0.03517919406294823,
                0.08036325126886368,
                -0.015527942217886448,
                0.013246487826108932,
                0.0011310360860079527,
                -0.005582328420132399,
                -0.01891903392970562,
                2.535954308768851e-07,
                -0.005592504516243935,
                -0.04189755395054817,
                0.04278123006224632,
                0.025968290865421295,
                -0.03891375660896301,
                0.00928564090281725,
                -0.023050133138895035,
                0.04031360149383545,
                -0.0034171640872955322,
                -0.022710181772708893,
                0.00035134906647726893,
                -0.04623802378773689,
                0.028186161071062088,
                0.014227388426661491,
                -0.006860437337309122,
                -0.09259623289108276,
                -0.016599241644144058,
                -0.01903449557721615,
                -0.019975952804088593,
                -0.010590950958430767,
                -0.006629595533013344,
                0.0060641346499323845,
                0.020836692303419113,
                0.030354585498571396,
                0.009144173935055733,
                0.07119300961494446,
                -0.004351587500423193,
                -0.011287108063697815,
                -0.0134245241060853,
                -0.05442255735397339,
                0.09950967133045197,
                -0.03473828732967377,
                0.019734632223844528,
                0.0055344789288938046,
                0.020657802000641823,
                0.009994042105972767,
                0.0579070970416069,
                -0.07075143605470657,
                -0.00760527653619647,
                -0.0031101861968636513,
                -0.04961906373500824,
                -0.009326152503490448,
                -0.011107130907475948,
                0.04347274452447891,
                -0.020882530137896538,
                0.01745680719614029,
                0.014425016939640045,
                -0.008915969170629978,
                -0.0886026993393898,
                -0.04500227048993111,
                0.0830858126282692,
                0.012259001843631268,
                -0.01720476523041725,
                0.03945294767618179,
                -0.018842270597815514,
                0.011989010497927666,
                -0.02057533711194992,
                0.0032451257575303316,
                0.005223192740231752,
                -0.011211751960217953,
                -0.0013161946553736925,
                0.016229981556534767,
                0.01686340570449829,
                0.06962797045707703,
                0.048649802803993225,
                -0.047133687883615494,
                -0.052326541393995285,
                2.6406576011585533e-34,
                0.019270960241556168,
                -0.07428842782974243,
                -0.02649737149477005,
                -0.036826200783252716,
                -0.010739194229245186,
                0.0006198977935127914,
                0.05263238772749901,
                0.02704165317118168,
                -0.011091269552707672,
                0.0006667448906227946,
                0.0064575825817883015
            ]
        },
        "Education": {
            "name": "MSc/Petroleum Engineering",
            "rep": [
                -0.032264526933431625,
                -0.031131310388445854,
                -0.024911632761359215,
                -0.04261056333780289,
                0.0299625676125288,
                0.013723786920309067,
                -0.0371982604265213,
                0.03336041048169136,
                0.03130367398262024,
                -0.03713248297572136,
                0.03307093679904938,
                0.0026182462461292744,
                0.016947565600275993,
                0.06243538856506348,
                0.06904006749391556,
                -0.004737274721264839,
                -0.009945426136255264,
                -0.047369059175252914,
                -0.020756889134645462,
                -0.030633971095085144,
                -0.0658506453037262,
                -0.004845685791224241,
                -0.014716721139848232,
                0.02301117219030857,
                0.006901980377733707,
                -0.010453975759446621,
                -0.004288258496671915,
                -0.016570307314395905,
                0.01857616752386093,
                0.02338435687124729,
                0.06712280213832855,
                0.02799072489142418,
                0.030875973403453827,
                -0.00852079689502716,
                1.2896792895844555e-06,
                -0.010418770834803581,
                -0.013581180945038795,
                0.0029034167528152466,
                0.06595105677843094,
                -0.026188697665929794,
                0.031224098056554794,
                0.04660230875015259,
                0.03971521556377411,
                0.0307078268378973,
                -0.018848810344934464,
                0.06451430916786194,
                0.036320820450782776,
                -0.04993293061852455,
                0.052662480622529984,
                -0.009251167997717857,
                0.004546394106000662,
                -0.08393911272287369,
                -0.09359344840049744,
                -0.03420964255928993,
                -0.021999265998601913,
                -0.031454283744096756,
                0.018601348623633385,
                0.008393998257815838,
                0.02851712331175804,
                0.04314053803682327,
                0.004830877296626568,
                0.011044432409107685,
                -0.04552073031663895,
                -0.013224315829575062,
                0.042812883853912354,
                0.03718050196766853,
                0.03321950510144234,
                -0.00966048426926136,
                0.006337279453873634,
                0.00792629923671484,
                0.03247496858239174,
                -0.026418067514896393,
                0.003943042829632759,
                0.013234660029411316,
                -0.003944682888686657,
                -0.008554031141102314,
                -0.009019529446959496,
                0.008487559854984283,
                -0.009054872207343578,
                -0.002856417093425989,
                0.006444296333938837,
                0.012525800615549088,
                0.005133344791829586,
                0.010010546073317528,
                -0.06841724365949631,
                0.05914835259318352,
                0.006333448924124241,
                -0.0013817830476909876,
                0.04289272800087929,
                0.025297189131379128,
                -0.024905137717723846,
                0.057750411331653595,
                -0.03697246313095093,
                -0.006641133222728968,
                -0.01601799763739109,
                -0.03620123863220215,
                0.0860423818230629,
                0.040995948016643524,
                -0.022316092625260353,
                0.03165845572948456,
                0.0027460413984954357,
                0.020175423473119736,
                0.049227844923734665,
                0.029653586447238922,
                -0.033547427505254745,
                0.005203125532716513,
                0.03204331547021866,
                0.039990320801734924,
                0.01882445439696312,
                -0.03999971225857735,
                -0.07958247512578964,
                -0.006940361112356186,
                -0.002401955658569932,
                -0.009948966093361378,
                -0.010716556571424007,
                0.07021249830722809,
                -0.009794622659683228,
                -0.03450978547334671,
                -0.007874863222241402,
                0.01731465756893158,
                0.010466844774782658,
                -0.00421262439340353,
                -0.026769662275910378,
                0.030384739860892296,
                0.0091253612190485,
                0.0291866734623909,
                0.03617652505636215,
                0.04709164425730705,
                0.04768477752804756,
                0.06270132958889008,
                -0.04223453626036644,
                -0.022226054221391678,
                -0.017301034182310104,
                0.012945066206157207,
                0.0029039299115538597,
                0.008169793523848057,
                -0.04133457690477371,
                0.003061883617192507,
                -0.04974590614438057,
                -0.028144342824816704,
                0.03857075423002243,
                -0.0010406409855931997,
                0.04576614871621132,
                0.06639497727155685,
                -0.026717500761151314,
                -0.017814714461565018,
                0.01490683201700449,
                0.021344345062971115,
                0.005444718524813652,
                -0.06120755523443222,
                0.037965741008520126,
                -0.05190740525722504,
                0.02990810014307499,
                -0.05721914768218994,
                0.055014412850141525,
                0.018369188532233238,
                0.024460237473249435,
                0.001436777412891388,
                0.010111886076629162,
                0.04713718593120575,
                -0.03373511880636215,
                -0.016594139859080315,
                -0.06067252531647682,
                0.012075805105268955,
                -0.0014505211729556322,
                -0.02303791418671608,
                0.0014421523083001375,
                -0.05555037409067154,
                -0.038241658359766006,
                0.025895683094859123,
                -0.014871932566165924,
                -0.06258110702037811,
                -0.011915885843336582,
                0.013513132929801941,
                -0.0010886379750445485,
                -0.061184611171483994,
                0.028741536661982536,
                0.000456701178336516,
                -0.010157017037272453,
                0.048487670719623566,
                0.015904990956187248,
                -0.08863940089941025,
                0.058410339057445526,
                -0.008584899827837944,
                0.029662083834409714,
                0.0013969341525807977,
                0.01480051875114441,
                0.02712545171380043,
                -0.047658104449510574,
                0.015371323563158512,
                0.032095685601234436,
                0.03928043693304062,
                -0.08203615993261337,
                -0.02833038941025734,
                0.016486942768096924,
                0.0015424976591020823,
                0.012027391232550144,
                0.0011572756338864565,
                0.01741316355764866,
                0.024923792108893394,
                -0.018479444086551666,
                0.021698743104934692,
                -0.04646763205528259,
                -0.008122667670249939,
                0.013334952294826508,
                -0.055627331137657166,
                -0.04384641721844673,
                -0.08412908017635345,
                -0.0634625181555748,
                0.028263216838240623,
                -0.00042716594180092216,
                -0.026609893888235092,
                0.09019337594509125,
                0.023769719526171684,
                -0.03794952481985092,
                -0.05683828145265579,
                -0.013448284938931465,
                -0.03045761026442051,
                0.002107919892296195,
                -0.008864887990057468,
                -0.01910136453807354,
                -0.07232551276683807,
                0.04255567863583565,
                -0.013102425262331963,
                -0.005805275868624449,
                0.024673137813806534,
                0.050866588950157166,
                -0.0440523624420166,
                0.014204521663486958,
                0.0553617961704731,
                0.013137521222233772,
                0.011340660974383354,
                0.043804362416267395,
                -0.06687694042921066,
                0.0002747653052210808,
                -0.022599684074521065,
                0.03348001092672348,
                0.032637301832437515,
                0.031468331813812256,
                -0.061957571655511856,
                -0.0068289246410131454,
                0.036397069692611694,
                0.00016149337170645595,
                -0.034480441361665726,
                0.006814491935074329,
                0.014252583496272564,
                0.005102945491671562,
                0.02299131080508232,
                0.04720413312315941,
                -0.02611597813665867,
                0.02836213819682598,
                -0.028055503964424133,
                0.013759281486272812,
                0.011594646610319614,
                0.02211502380669117,
                -0.014374596066772938,
                -0.022674620151519775,
                0.018526380881667137,
                -0.07389099150896072,
                -0.0070388298481702805,
                -0.006756884977221489,
                -0.04512837529182434,
                -0.003388242330402136,
                -0.016305599361658096,
                -0.022304458543658257,
                0.017863331362605095,
                0.02503575012087822,
                -0.00723233399912715,
                -0.04854375496506691,
                0.0027171995025128126,
                0.04272160679101944,
                0.04424934461712837,
                0.023401329293847084,
                -0.023816002532839775,
                -0.041103240102529526,
                -0.00941960234194994,
                0.0006779962568543851,
                0.03079398162662983,
                -0.062402546405792236,
                0.03533339500427246,
                0.02435801550745964,
                0.059845276176929474,
                -0.06564901024103165,
                -0.04496072232723236,
                -0.012994319200515747,
                0.05072711780667305,
                0.031369663774967194,
                -0.039707865566015244,
                -0.023906607180833817,
                0.0010094098979607224,
                0.01623053476214409,
                -0.018390735611319542,
                -0.003534043440595269,
                -0.05735740810632706,
                -0.048742856830358505,
                0.07931364327669144,
                -0.006022758781909943,
                0.025953399017453194,
                0.0221540704369545,
                0.02165379747748375,
                -0.002371856477111578,
                -0.002153251087293029,
                0.007760040927678347,
                0.026850013062357903,
                -0.008889690041542053,
                0.037000495940446854,
                0.024548405781388283,
                0.01835571974515915,
                -0.035539593547582626,
                -0.06136539578437805,
                -0.05938722938299179,
                0.029036344960331917,
                0.058305349200963974,
                0.02681332267820835,
                0.07608470320701599,
                -0.07851310074329376,
                0.018440090119838715,
                0.03502921015024185,
                0.0390823595225811,
                0.0004128590226173401,
                0.03210426867008209,
                0.016871165484189987,
                -0.01594935916364193,
                -0.030599093064665794,
                0.00025772108347155154,
                -0.00499690230935812,
                -0.026430955156683922,
                0.016264695674180984,
                0.01227134745568037,
                -0.06937969475984573,
                -0.026843467727303505,
                0.0142061123624444,
                -0.04413668066263199,
                -0.03509758040308952,
                -0.015918228775262833,
                -0.05944767966866493,
                0.013866490684449673,
                -0.0844278484582901,
                -0.03246564045548439,
                -0.004354347940534353,
                0.013486796990036964,
                0.016783250495791435,
                -0.037562619894742966,
                -0.04240628704428673,
                0.05090141296386719,
                0.037852201610803604,
                0.03176125884056091,
                0.010730799287557602,
                0.012745988555252552,
                0.015680506825447083,
                0.06719794869422913,
                0.03512749448418617,
                0.0005425424315035343,
                0.03142805024981499,
                -0.00578530365601182,
                0.05014989525079727,
                0.061096321791410446,
                0.04277152195572853,
                0.026411298662424088,
                -0.020219901576638222,
                0.008081920444965363,
                -0.0357058048248291,
                -0.06814323365688324,
                -0.03855801373720169,
                -0.04438410699367523,
                0.0037020414602011442,
                0.04483666270971298,
                -0.044121887534856796,
                -0.004929056391119957,
                -0.012494800612330437,
                -0.06236783042550087,
                -0.01625927910208702,
                -0.08191215991973877,
                -0.029633894562721252,
                -0.03751745447516441,
                0.03118368424475193,
                -0.016817929223179817,
                0.06715469062328339,
                0.025786522775888443,
                0.019013037905097008,
                0.02323037013411522,
                -0.013325074687600136,
                0.030968308448791504,
                -0.027340585365891457,
                0.0017327119130641222,
                0.030726144090294838,
                0.004944481886923313,
                0.0023234535474330187,
                0.01226271502673626,
                0.0027176341973245144,
                -0.013964899815618992,
                -0.04397183284163475,
                -0.03431783989071846,
                -0.027067305520176888,
                0.02961021661758423,
                0.014231367036700249,
                -0.069405697286129,
                -0.029330087825655937,
                0.020912427455186844,
                0.0015130540123209357,
                0.06643007695674896,
                0.04069060459733009,
                -0.03992550075054169,
                0.03167695924639702,
                0.014778794720768929,
                -0.026995165273547173,
                0.04962022230029106,
                -0.037305258214473724,
                -0.04134594276547432,
                -0.039246100932359695,
                0.05234821140766144,
                -0.022917497903108597,
                0.016458705067634583,
                -1.1521637134137563e-05,
                -0.028864409774541855,
                -0.052741728723049164,
                -0.012829383835196495,
                0.024176716804504395,
                -0.02423601970076561,
                -0.07082998007535934,
                0.04113750532269478,
                -0.015915963798761368,
                0.07058098912239075,
                -0.07265642285346985,
                0.06977967917919159,
                -0.036501575261354446,
                -0.012804779224097729,
                0.009913545101881027,
                -0.03228669613599777,
                -0.07143061608076096,
                -0.01724018156528473,
                -0.030839622020721436,
                -0.01967630721628666,
                -0.030735528096556664,
                0.039665788412094116,
                0.012115211226046085,
                0.0115022212266922,
                0.013084706850349903,
                -0.03387678414583206,
                0.03919452056288719,
                0.008115564472973347,
                0.016837282106280327,
                -0.01595834270119667,
                -0.03357013687491417,
                0.06603579968214035,
                0.09710704535245895,
                -0.036301400512456894,
                -0.03448885306715965,
                -0.032053641974925995,
                -0.050574593245983124,
                0.08612452447414398,
                -0.03511499986052513,
                -0.0536283403635025,
                -0.017601944506168365,
                -0.010856904089450836,
                -0.04565364494919777,
                -0.09381185472011566,
                -0.020227858796715736,
                -0.035119831562042236,
                0.001254910253919661,
                0.013325587846338749,
                0.020202064886689186,
                -0.021442297846078873,
                -0.012124399654567242,
                -0.031524837017059326,
                -0.004060142207890749,
                0.03407995402812958,
                0.010061901062726974,
                0.03400437906384468,
                -0.034895334392786026,
                -0.004917513579130173,
                -0.015787694603204727,
                -0.013595731928944588,
                -0.004740497563034296,
                -0.01575659215450287,
                -0.012048419564962387,
                0.03983357921242714,
                0.052653808146715164,
                -0.06704024225473404,
                0.004185332916676998,
                0.009262144565582275,
                0.027325881645083427,
                0.061901409178972244,
                -0.02074713632464409,
                0.0042870226316154,
                0.034368544816970825,
                0.036483000963926315,
                0.01744447462260723,
                0.012120989151299,
                -0.0007676352979615331,
                0.04153408482670784,
                -0.05256457254290581,
                -8.289188554044813e-05,
                -0.07474467903375626,
                0.07619935274124146,
                0.010563253425061703,
                -0.02040131203830242,
                0.030958939343690872,
                -0.043456725776195526,
                -0.01656760275363922,
                -0.020916270092129707,
                0.014882475137710571,
                -0.0004666742170229554,
                -0.0923125222325325,
                0.006679482292383909,
                0.0046135601587593555,
                0.01392215397208929,
                0.018624568358063698,
                0.0029111846815794706,
                0.009340050630271435,
                0.029712287709116936,
                0.035819005221128464,
                -0.0015443027950823307,
                0.018093710765242577,
                0.02366269938647747,
                -0.0058232699520885944,
                0.029930537566542625,
                -0.030124645680189133,
                0.013985943980515003,
                0.04429379105567932,
                0.03153081238269806,
                -0.019430316984653473,
                0.0253891684114933,
                -0.014756510965526104,
                -0.032099928706884384,
                -0.03147800639271736,
                0.007423878647387028,
                0.029883936047554016,
                0.02640807256102562,
                0.010963650420308113,
                0.03727414086461067,
                -0.031217623502016068,
                0.016649289056658745,
                0.009270135313272476,
                -0.004647999070584774,
                -0.013679967261850834,
                0.07239466160535812,
                0.04742862656712532,
                0.009295221418142319,
                0.08994191884994507,
                -0.01710616610944271,
                -0.0032664116006344557,
                0.006878973450511694,
                0.04244346171617508,
                -0.003179732942953706,
                -0.015592086128890514,
                0.06519223004579544,
                0.051085762679576874,
                0.01539849117398262,
                -0.016667276620864868,
                -0.0010039635235443711,
                -0.008860591799020767,
                0.03982885181903839,
                0.022103002294898033,
                0.01783917285501957,
                -5.0871740450841515e-33,
                -0.037550948560237885,
                0.001499597099609673,
                -0.0023954894859343767,
                -0.009347069077193737,
                -0.009603600949048996,
                -0.03859858959913254,
                0.037160854786634445,
                0.011271102353930473,
                -0.03979511559009552,
                -0.01769432984292507,
                -0.040561266243457794,
                0.04604806378483772,
                0.007724324706941843,
                -0.008054249919950962,
                0.055790383368730545,
                0.07455606758594513,
                0.02667698636651039,
                0.03380750119686127,
                0.004528800956904888,
                -0.032945673912763596,
                0.011283796280622482,
                0.0026427959091961384,
                0.04898964241147041,
                0.04294198006391525,
                0.03443717584013939,
                0.03189076855778694,
                -0.010735846124589443,
                -0.010676508769392967,
                -0.030075358226895332,
                -0.001302515622228384,
                0.018233999609947205,
                0.0010234767105430365,
                0.014032101258635521,
                -0.003696240484714508,
                -0.03162961080670357,
                -0.048773620277643204,
                -0.02035125158727169,
                -0.02804691158235073,
                -0.04602067172527313,
                0.005052503198385239,
                0.011947470717132092,
                -0.004788098856806755,
                0.05827721953392029,
                -0.023902451619505882,
                -0.05161905661225319,
                -0.07542799413204193,
                -0.00895695574581623,
                0.04628638178110123,
                0.05818263441324234,
                -0.02185232751071453,
                -0.04585607349872589,
                -0.03772587701678276,
                -0.023541336879134178,
                0.0467584989964962,
                -0.01409105770289898,
                -0.04108879342675209,
                0.018315283581614494,
                -0.015699831768870354,
                -0.02084195427596569,
                0.03339570760726929,
                -0.05067841708660126,
                0.049521513283252716,
                0.0701015368103981,
                -0.0491848886013031,
                -0.01081869751214981,
                0.007880114018917084,
                -0.05266347900032997,
                0.04748992621898651,
                0.07783076912164688,
                -0.025263549759984016,
                -0.0511782169342041,
                0.03544064611196518,
                -0.05915479362010956,
                -0.041906461119651794,
                -0.07731064409017563,
                -0.04914018511772156,
                -0.04906661435961723,
                -0.02089867554605007,
                -0.02013866975903511,
                0.03806335851550102,
                -0.013846277259290218,
                0.03010769560933113,
                0.05485546961426735,
                0.020445959642529488,
                -0.042643722146749496,
                0.0030042065773159266,
                0.011935663409531116,
                0.09541911631822586,
                0.002755366265773773,
                0.008842631243169308,
                0.03671690449118614,
                0.023855049163103104,
                0.0019142156234011054,
                -0.03133562579751015,
                0.09351690858602524,
                -0.02945557050406933,
                -0.027593344449996948,
                -0.01436539739370346,
                0.013231726363301277,
                0.06739214062690735,
                -0.05367470905184746,
                0.02706749550998211,
                -0.020485490560531616,
                -0.02359175682067871,
                0.014807436615228653,
                0.045355211943387985,
                -0.07626660913228989,
                -0.01511444803327322,
                0.0007201441912911832,
                -0.01587631180882454,
                -0.031460631638765335,
                0.0027316545601934195,
                0.011354790069162846,
                0.005637352354824543,
                -0.0010528232669457793,
                0.00865692738443613,
                -0.020321205258369446,
                -0.11458401381969452,
                -0.02428029663860798,
                0.05521700903773308,
                0.046640146523714066,
                -0.05659570172429085,
                -0.02013883739709854,
                5.1040387916145846e-05,
                0.039778534322977066,
                -0.009863677434623241,
                0.04322750121355057,
                -0.044347722083330154,
                -0.03181377425789833,
                0.028851879760622978,
                0.012933400459587574,
                -0.003642289899289608,
                1.818457917579508e-07,
                -0.029066359624266624,
                0.03227231651544571,
                0.04925719276070595,
                0.03387736901640892,
                -0.0198774766176939,
                0.011896212585270405,
                0.009116900153458118,
                0.036135539412498474,
                -0.005185835063457489,
                0.028834085911512375,
                -0.03587345406413078,
                -0.01478076446801424,
                -0.037689462304115295,
                -0.10185013711452484,
                -0.013425891287624836,
                -0.01657337322831154,
                0.06751475483179092,
                -0.001643435563892126,
                -0.030063403770327568,
                -0.021837955340743065,
                -0.010839730501174927,
                0.04669860750436783,
                -0.026057856157422066,
                -0.02139214426279068,
                -0.004696794785559177,
                -0.010951612144708633,
                0.005617986898869276,
                -0.012978116981685162,
                0.04995547980070114,
                0.04111562296748161,
                0.07569050788879395,
                -0.012901244685053825,
                0.033473215997219086,
                0.06407828629016876,
                0.0058111222460865974,
                -0.028735468164086342,
                0.05017692968249321,
                -0.003383329138159752,
                0.004419422708451748,
                0.031989239156246185,
                -0.05425164848566055,
                0.018660781905055046,
                0.04894820228219032,
                -0.01193286757916212,
                -0.05214730277657509,
                0.0279788039624691,
                -0.00250697648152709,
                -0.02834465727210045,
                -0.09559790045022964,
                -0.012833134271204472,
                -0.014840798452496529,
                0.06345108151435852,
                0.005385867785662413,
                -0.022338982671499252,
                0.013327243737876415,
                0.0015555924037471414,
                -0.005764281377196312,
                0.04645746573805809,
                0.03563220798969269,
                -0.022195789963006973,
                0.007826217450201511,
                -0.030304063111543655,
                -0.022939495742321014,
                0.019979627802968025,
                0.09892667084932327,
                -0.04402952641248703,
                -0.039771828800439835,
                1.2109405564518682e-34,
                -0.016463587060570717,
                -0.026233909651637077,
                0.0009572175913490355,
                0.03856680914759636,
                0.022072283551096916,
                -0.03640533983707428,
                -0.029449842870235443,
                0.0431465283036232,
                -0.009207452647387981,
                -0.01652677357196808,
                -0.03689771145582199
            ]
        },
        "Experience": {
            "name": " Engineer with experience in project management, data analysis, client success and onshore/offshore energy operations.\n\nI have an engineering skillset and a can-do attitude. I enjoy working on-site as well as in an office.\n\nI took part in multiple drilling projects as a field engineer (subsurface data engineer), and in offshore installation, renovation and maintenance projects as a Subsea Project Engineer, including construction, pre-commissioning as well as decommissioning. MIST, BOSIET, HUET, CA-EBS, Offshore Medical, Radiation Protection Supervisor certified.\n\nI am vividly interested in \"what's up\", especially in audio engineering and renewable energy (geothermal drilling is the bomb!). \n\n...but don't let that sentence fool you. I also love new technologies and I'm highly interested in IT.\n\nThat's pretty much all when it comes to work.\n\nAfter hours I dabble in playing a few instruments. I also love to travel and bake (the results here are... well, exciting every time).",
            "rep": [
                0.01669585146009922,
                0.020786268636584282,
                -0.027523964643478394,
                -0.06357720494270325,
                0.027228232473134995,
                0.00040593938319943845,
                -0.04866239055991173,
                0.019216975197196007,
                -0.023767659440636635,
                -0.022389423102140427,
                0.0490696020424366,
                -0.00553264282643795,
                -0.01570119522511959,
                0.0455147959291935,
                0.06826440244913101,
                -0.018831009045243263,
                -0.002764593344181776,
                -0.010209430940449238,
                0.004179955460131168,
                0.021539356559515,
                -0.06634815782308578,
                0.046113673597574234,
                -0.040970079600811005,
                0.028641363605856895,
                0.021546650677919388,
                0.053526636213064194,
                0.016566315665841103,
                0.005743763875216246,
                0.014724623411893845,
                0.07935171574354172,
                0.09435969591140747,
                -0.020772596821188927,
                0.034991804510354996,
                0.015536372549831867,
                1.8713406007009326e-06,
                0.033449575304985046,
                -0.03785207122564316,
                -0.007028466090559959,
                0.038369957357645035,
                -0.10392506420612335,
                -0.02035658434033394,
                -0.04232383891940117,
                0.017434531822800636,
                0.011074988171458244,
                0.01350250281393528,
                -0.029473157599568367,
                0.0250912643969059,
                -0.06317824125289917,
                -0.005648641847074032,
                0.014651410281658173,
                -0.0241070706397295,
                -0.021234232932329178,
                -0.07737533748149872,
                -0.007576459087431431,
                -0.027647927403450012,
                0.0004724743776023388,
                -0.0012807381572201848,
                -0.06288257986307144,
                0.03460898995399475,
                0.03735893592238426,
                0.017622284591197968,
                -0.00695465225726366,
                0.0011675747809931636,
                -0.015730813145637512,
                0.06289888173341751,
                0.07073984295129776,
                0.030863121151924133,
                0.010444656014442444,
                0.027364615350961685,
                -0.03611569106578827,
                0.06289073079824448,
                -0.009706751443445683,
                0.016704697161912918,
                0.0017007476417347789,
                0.004891086835414171,
                0.002575936494395137,
                0.029573818668723106,
                0.058942656964063644,
                -0.010834725573658943,
                -0.011032306589186192,
                -0.026299305260181427,
                0.05025150626897812,
                -0.03191427141427994,
                -0.04463949054479599,
                -0.028318101540207863,
                0.012164488434791565,
                -0.007189525291323662,
                0.032610949128866196,
                -0.007009095512330532,
                0.022661367431282997,
                0.02620701491832733,
                -0.03297168016433716,
                -0.01725303940474987,
                0.04145974665880203,
                0.00036654126597568393,
                -0.0070625897496938705,
                0.05278271064162254,
                0.0008329207776114345,
                0.02424953505396843,
                0.049672093242406845,
                -0.030455946922302246,
                0.037580735981464386,
                -0.00011860708036692813,
                0.045757364481687546,
                0.019453154876828194,
                -0.038346655666828156,
                0.044374871999025345,
                -0.007735646329820156,
                -0.01710698753595352,
                -0.04742513597011566,
                -0.01067780889570713,
                -0.04317334666848183,
                -0.00058948207879439,
                0.006172741297632456,
                0.02055974490940571,
                0.002497646491974592,
                0.03970682621002197,
                -0.01672477275133133,
                0.04174729436635971,
                -0.010920355096459389,
                0.0371684804558754,
                -0.022186357527971268,
                0.004786309320479631,
                0.06154860556125641,
                -0.018132198601961136,
                -0.009026709944009781,
                0.014172698371112347,
                0.028131036087870598,
                0.015988631173968315,
                0.015774546191096306,
                0.0036835672799497843,
                0.0030285411048680544,
                -0.052329547703266144,
                -0.06524898111820221,
                0.018601849675178528,
                -0.021467501297593117,
                -0.009349824860692024,
                0.017414353787899017,
                -0.0024479024577885866,
                -0.08221999555826187,
                0.024434706196188927,
                0.00375316571444273,
                0.003549995133653283,
                0.0820191353559494,
                0.02446983940899372,
                -0.07137799263000488,
                -0.03993769735097885,
                0.006566205993294716,
                -0.013678315095603466,
                -0.015834461897611618,
                0.09394863992929459,
                -0.013845741748809814,
                0.012026523239910603,
                -0.0031177557539194822,
                0.054940059781074524,
                0.04864110052585602,
                -0.02466701716184616,
                0.019485443830490112,
                -0.00819952692836523,
                0.030546214431524277,
                -0.02980637736618519,
                -0.05642624571919441,
                -0.020201319828629494,
                0.01909119449555874,
                -0.041331373155117035,
                0.005240131635218859,
                0.055656734853982925,
                0.007846500724554062,
                0.018575182184576988,
                -0.01126541756093502,
                0.039378613233566284,
                -0.03465806692838669,
                0.0027444246225059032,
                -0.030705057084560394,
                0.027087299153208733,
                -0.06243424490094185,
                0.02018890716135502,
                0.025565294548869133,
                0.03904156759381294,
                -0.0022508299443870783,
                0.01698467507958412,
                -0.10091500729322433,
                0.033021822571754456,
                0.019735926762223244,
                0.037107907235622406,
                -0.008687756955623627,
                -0.033270735293626785,
                0.05035891383886337,
                -0.03833414986729622,
                0.029521208256483078,
                0.033491577953100204,
                -0.0008254125714302063,
                -0.024053819477558136,
                0.031303390860557556,
                -0.03449339047074318,
                0.03614748641848564,
                -0.02586180530488491,
                -0.000269330688752234,
                -0.007670952007174492,
                -0.022929875180125237,
                -0.02104746550321579,
                0.0025954069569706917,
                -0.050991225987672806,
                0.018979016691446304,
                0.032166965305805206,
                -0.0301786158233881,
                -0.04866841062903404,
                -0.07016666978597641,
                -0.027938708662986755,
                0.055873893201351166,
                0.05146101489663124,
                0.0031931428238749504,
                0.019875284284353256,
                0.028753703460097313,
                -0.05955558642745018,
                -0.02743736281991005,
                0.017932984977960587,
                0.0032905847765505314,
                0.041954949498176575,
                0.015253624878823757,
                0.016463585197925568,
                -0.024722052738070488,
                0.018214093521237373,
                -0.034189801663160324,
                -0.015076931565999985,
                -0.04545240476727486,
                0.0016950132558122277,
                0.013664945028722286,
                -0.02770581841468811,
                0.05216306075453758,
                0.0055484953336417675,
                0.013493778184056282,
                -0.0027650173287838697,
                -0.038878183811903,
                -0.040840983390808105,
                0.005959624890238047,
                0.007343301083892584,
                0.105314239859581,
                -0.027759598568081856,
                -0.005288309883326292,
                0.016564320772886276,
                -0.02979886531829834,
                -0.005025934893637896,
                0.007943865843117237,
                -0.03125616908073425,
                0.01701822131872177,
                -0.008902676403522491,
                0.03972703218460083,
                0.006376780103892088,
                -0.0025100908242166042,
                0.007044013123959303,
                0.011564304120838642,
                -0.01393717247992754,
                0.05044377222657204,
                -0.02912106364965439,
                -0.026412617415189743,
                -0.03311672806739807,
                0.008020728826522827,
                -0.14035490155220032,
                -0.017980534583330154,
                -0.01572052203118801,
                -0.04414301738142967,
                -0.020799702033400536,
                0.007978488691151142,
                -0.018748817965388298,
                -0.0002004807029152289,
                0.009712493978440762,
                0.024615507572889328,
                -0.031428467482328415,
                0.027462244033813477,
                0.02936084382236004,
                0.051288314163684845,
                0.007564027793705463,
                -0.016662899404764175,
                -0.015842773020267487,
                0.027545742690563202,
                -0.020357603207230568,
                0.0030930391512811184,
                -0.02843998558819294,
                0.01782696507871151,
                0.019680967554450035,
                0.005673354025930166,
                -0.06715831905603409,
                -0.013937192037701607,
                -0.029977785423398018,
                0.026561442762613297,
                0.04634151607751846,
                0.02329225465655327,
                -0.038633786141872406,
                0.011841563507914543,
                0.001138563733547926,
                -0.02894454449415207,
                -0.005397392436861992,
                -0.06402230262756348,
                -0.05613919347524643,
                0.027998264878988266,
                -0.009592322632670403,
                -0.019085470587015152,
                -0.017669614404439926,
                -0.0036968316417187452,
                -0.0064462218433618546,
                0.014184900559484959,
                0.02595025673508644,
                0.019313791766762733,
                -0.02205946482717991,
                0.04584968462586403,
                0.02411036007106304,
                0.010578774847090244,
                -0.03806767612695694,
                0.02221963368356228,
                -0.02572213113307953,
                0.023679403588175774,
                0.021968510001897812,
                0.03749403357505798,
                0.048123713582754135,
                -0.011532219126820564,
                0.008936366066336632,
                -0.014562251977622509,
                0.02351117506623268,
                0.03914454206824303,
                0.024563990533351898,
                0.08039841800928116,
                -0.0037223941180855036,
                -0.007367812562733889,
                0.00764749338850379,
                -0.04572426527738571,
                -0.0398867167532444,
                -0.00797293521463871,
                0.016514727845788002,
                -0.08517599105834961,
                0.0022572835441678762,
                0.046655960381031036,
                -0.02960307151079178,
                -0.015007688663899899,
                -0.023548630997538567,
                -0.047491494566202164,
                -0.01769358292222023,
                -0.056317735463380814,
                -0.019123144447803497,
                -0.017794640734791756,
                -0.0027248708065599203,
                0.032265182584524155,
                -0.05198097974061966,
                -0.011617070995271206,
                -0.0077278343960642815,
                0.04017128795385361,
                0.06969144940376282,
                0.02636713534593582,
                -0.021102046594023705,
                0.020127423107624054,
                0.027783457189798355,
                0.056164585053920746,
                0.03726370632648468,
                0.020168837159872055,
                -0.032224368304014206,
                0.03764821216464043,
                -0.002289006719365716,
                0.0877743735909462,
                -0.02796502597630024,
                0.054420169442892075,
                -0.0531119666993618,
                -7.86741657066159e-05,
                -0.027552936226129532,
                0.010751602239906788,
                -0.005661915987730026,
                -0.03875836357474327,
                0.06132325902581215,
                -0.05472894385457039,
                -0.04730628803372383,
                -0.012845526449382305,
                -0.030919520184397697,
                -0.019787084311246872,
                -0.04428253322839737,
                -0.03369741141796112,
                -0.0030182620976120234,
                -0.0018009585328400135,
                -0.019614888355135918,
                0.10849237442016602,
                0.007047766819596291,
                0.009500999934971333,
                0.0489339642226696,
                0.014625768177211285,
                0.0653017982840538,
                -0.021400541067123413,
                0.017413072288036346,
                -0.018719492480158806,
                0.009823309257626534,
                -0.023298218846321106,
                0.012346369214355946,
                0.026718243956565857,
                0.01616821065545082,
                0.021562892943620682,
                0.026583893224596977,
                -0.06434909999370575,
                0.010761871933937073,
                -0.013265377841889858,
                -0.050739485770463943,
                -0.022832846269011497,
                0.0346488393843174,
                0.0023299416061490774,
                0.03282513469457626,
                0.00528705446049571,
                -0.05699082463979721,
                0.03184089809656143,
                -0.04615563526749611,
                -0.036087144166231155,
                0.05438804253935814,
                0.0031875502318143845,
                -0.002368256216868758,
                -0.018310442566871643,
                -0.032629676163196564,
                -0.02142718993127346,
                0.02221694588661194,
                0.03653232380747795,
                -0.027283543720841408,
                -0.03902847319841385,
                0.009097986854612827,
                -0.04957186058163643,
                0.015544895082712173,
                -0.028012452647089958,
                0.05674760416150093,
                -0.0005250292015261948,
                0.05774784833192825,
                -0.022195596247911453,
                0.04051930829882622,
                -0.03275494650006294,
                0.02679322473704815,
                0.0738607794046402,
                -0.006548447068780661,
                -0.07343775033950806,
                -0.037594493478536606,
                0.0009643001831136644,
                0.07149242609739304,
                -0.00813407264649868,
                -0.0072903940454125404,
                0.007864748127758503,
                0.011439220979809761,
                0.021215451881289482,
                -0.013959119096398354,
                0.02193276211619377,
                -0.018016111105680466,
                -0.050271980464458466,
                -0.008844194933772087,
                0.04456019401550293,
                0.001824049511924386,
                -0.0020059742964804173,
                0.008013660088181496,
                -0.014632719568908215,
                -0.04935580864548683,
                -0.01186659187078476,
                0.11996014416217804,
                0.002699390286579728,
                -0.04802674800157547,
                0.029649879783391953,
                -0.06607931107282639,
                -0.04592165723443031,
                -0.06375760585069656,
                -0.04832850396633148,
                0.008960358798503876,
                0.023373354226350784,
                -0.01492157019674778,
                0.004755089525133371,
                -0.045632053166627884,
                -0.02012927457690239,
                -0.04598870873451233,
                -0.03457903489470482,
                0.002838285407051444,
                -0.07031820714473724,
                0.021789828315377235,
                -0.06068683788180351,
                0.0711188018321991,
                -0.029592253267765045,
                -0.03792824596166611,
                -0.048019226640462875,
                -0.04552652686834335,
                -0.014499892480671406,
                0.031394749879837036,
                0.02718435227870941,
                -0.08413979411125183,
                -0.008029903285205364,
                0.02281738445162773,
                -0.017666833475232124,
                -0.016155006363987923,
                0.0035175613593310118,
                -0.014118344523012638,
                0.005902857985347509,
                -6.444206519518048e-05,
                0.02569824457168579,
                -0.007706672418862581,
                -0.02126721292734146,
                0.028199782595038414,
                -0.09783527255058289,
                0.047741182148456573,
                0.03342503309249878,
                0.037804123014211655,
                0.032363034784793854,
                -0.026413636282086372,
                0.051195178180933,
                -0.0221386831253767,
                -0.015747804194688797,
                -0.017093846574425697,
                0.023211942985653877,
                0.015376442112028599,
                -0.017289286479353905,
                0.055942729115486145,
                0.015209108591079712,
                -0.029336320236325264,
                -0.00325008830986917,
                -0.027057521045207977,
                -0.008017698302865028,
                -0.05004804581403732,
                -0.012648583389818668,
                0.028361238539218903,
                -0.035788360983133316,
                0.09724949300289154,
                -0.00792353879660368,
                -0.01827145926654339,
                -0.04777148738503456,
                0.02035854198038578,
                -0.0332358293235302,
                0.019688805565238,
                -0.013674655929207802,
                -0.01543404906988144,
                -0.0047087399289011955,
                -0.002119386335834861,
                0.011631520465016365,
                -0.007868636399507523,
                0.029646020382642746,
                -0.04506281390786171,
                0.011452455073595047,
                0.04113639146089554,
                0.028817493468523026,
                0.002882635220885277,
                0.03667004033923149,
                0.005135629326105118,
                0.055909667164087296,
                0.043631792068481445,
                0.04207293689250946,
                -0.036759089678525925,
                0.05563119426369667,
                -0.06263399124145508,
                0.020659424364566803,
                0.002089154440909624,
                -0.003542450023815036,
                -0.056733857840299606,
                0.018604930490255356,
                0.02042757160961628,
                0.01446594763547182,
                0.012194168753921986,
                -0.02127901464700699,
                0.03335464745759964,
                -0.06634929031133652,
                -0.00511790020391345,
                0.042434047907590866,
                -0.041507069021463394,
                -5.478615867959881e-33,
                -0.007137513719499111,
                -0.029049040749669075,
                0.07692816108465195,
                -0.005285922437906265,
                0.013566497713327408,
                -0.03285599127411842,
                0.061627741903066635,
                -0.04928051680326462,
                -0.00031738411053083837,
                -0.0620310977101326,
                -0.028204208239912987,
                0.032315414398908615,
                -0.0001345708005828783,
                -0.021441129967570305,
                -0.03991175442934036,
                0.032625313848257065,
                0.05887484550476074,
                0.018900461494922638,
                0.012752139009535313,
                -0.007165588904172182,
                0.004840749315917492,
                -0.028810633346438408,
                -0.004096531309187412,
                -0.09608250111341476,
                0.02639801613986492,
                0.04678591713309288,
                -0.02388743869960308,
                -0.008246888406574726,
                -0.006813029758632183,
                0.019911954179406166,
                -0.0014242584584280849,
                0.015594685450196266,
                -0.005592626519501209,
                0.0374363511800766,
                0.008492224849760532,
                -0.06873418390750885,
                -0.057175781577825546,
                0.015052887611091137,
                -0.009860308840870857,
                0.013933594338595867,
                0.024659255519509315,
                -0.05500977858901024,
                0.020566029474139214,
                -0.020129170268774033,
                -0.009260802529752254,
                -0.11731427162885666,
                0.03562786057591438,
                0.049929097294807434,
                0.05856504291296005,
                0.03062603808939457,
                -0.03263521194458008,
                0.008482538163661957,
                -0.03396288678050041,
                -0.014865639619529247,
                -0.01788189448416233,
                0.008505834266543388,
                0.004623790271580219,
                -0.00017446334823034704,
                -0.06759526580572128,
                0.058280300348997116,
                -0.012219804339110851,
                -0.012786268256604671,
                0.053096652030944824,
                -0.03842167183756828,
                -0.016685985028743744,
                0.029789557680487633,
                0.05605385825037956,
                0.06564874947071075,
                0.004808666650205851,
                -0.022217540070414543,
                -0.041410893201828,
                0.0652620866894722,
                -0.024339839816093445,
                -0.007342786528170109,
                -0.03789881616830826,
                -0.04009794816374779,
                0.005192149430513382,
                -0.006904990877956152,
                0.03896205872297287,
                -0.02958686277270317,
                -0.013775977306067944,
                0.006516282446682453,
                -0.006115782540291548,
                -0.019819192588329315,
                -0.0014793372247368097,
                0.0054426416754722595,
                0.006438469048589468,
                0.03381863236427307,
                0.009655088186264038,
                0.029960133135318756,
                0.019462691619992256,
                -0.029335273429751396,
                -0.01597481220960617,
                -0.013006823137402534,
                0.11287232488393784,
                -0.022854801267385483,
                0.002577368402853608,
                -0.07090994715690613,
                -0.0024234962183982134,
                0.018128840252757072,
                0.028722617775201797,
                0.057926252484321594,
                0.01755618304014206,
                -0.04159608855843544,
                0.01637955754995346,
                0.0378991961479187,
                -0.0726185068488121,
                0.0584716796875,
                -0.02123277820646763,
                -0.034358326345682144,
                0.0037212478928267956,
                -0.03034968487918377,
                0.008443194441497326,
                0.003271647961810231,
                0.018510019406676292,
                -0.02121077850461006,
                0.04664760082960129,
                -0.05652891844511032,
                -0.032384198158979416,
                0.014058622531592846,
                0.05316677689552307,
                -0.05430663377046585,
                -0.037748586386442184,
                0.0702676996588707,
                0.036931686103343964,
                -0.024047337472438812,
                0.02101912349462509,
                -0.028729867190122604,
                -0.03605800122022629,
                0.025315219536423683,
                -0.008854899555444717,
                0.035645563155412674,
                2.4245193230854056e-07,
                -0.02204904519021511,
                -0.05641142651438713,
                0.008161574602127075,
                0.04334736615419388,
                0.04844232648611069,
                -0.0329112783074379,
                0.00960601307451725,
                -0.02671731635928154,
                -0.0282069630920887,
                0.03831951692700386,
                0.0008420487283729017,
                -0.0022248101886361837,
                -0.004465473350137472,
                -0.0513179749250412,
                0.013949883170425892,
                -0.008317722007632256,
                0.03601381927728653,
                -0.020168770104646683,
                -0.006429532077163458,
                -0.035649001598358154,
                0.07323839515447617,
                0.00931096263229847,
                -0.01846655271947384,
                -0.010998777113854885,
                0.003802182851359248,
                -0.01612044870853424,
                -0.004553426988422871,
                0.007945290766656399,
                0.07613594084978104,
                0.0760626569390297,
                0.027683144435286522,
                -0.011991579085588455,
                0.049117568880319595,
                0.03343842551112175,
                -0.01919514127075672,
                -0.04011248052120209,
                0.06134955212473869,
                0.0640459954738617,
                0.01653270050883293,
                0.020450210198760033,
                -0.027659855782985687,
                0.05321478098630905,
                0.039225537329912186,
                -0.08632239699363708,
                -0.04374907538294792,
                -0.03346361964941025,
                -0.005689782090485096,
                0.011232360266149044,
                -0.07639992982149124,
                -0.026896553114056587,
                -0.02311437577009201,
                0.021875355392694473,
                -0.024403810501098633,
                -0.07459870725870132,
                0.04729939252138138,
                -0.006005109753459692,
                -0.025675270706415176,
                0.04684870317578316,
                0.050323646515607834,
                0.05374111235141754,
                -0.024644482880830765,
                -0.02530471421778202,
                -0.04028914496302605,
                -0.010563143528997898,
                0.09527991712093353,
                -0.03727619722485542,
                -0.03985049948096275,
                2.0775120072244334e-34,
                -0.061834875494241714,
                0.012632409110665321,
                -0.04621848091483116,
                -0.0067665427923202515,
                0.06859425455331802,
                -0.04102233052253723,
                -0.017050035297870636,
                0.007836176082491875,
                0.0200957041233778,
                -0.015584086067974567,
                -0.03612614795565605
            ]
        },
        "Freelance": "Open to both",
        "Id": "91613ac5-67c4-4393-b869-e1b3a51c1c30",
        "Industry": {
            "name": "\tEngineering & Science",
            "rep": [
                -0.0017743173521012068,
                -0.06540191918611526,
                -0.057183925062417984,
                -0.01021968200802803,
                -0.0015464175958186388,
                0.01280168816447258,
                0.013760849833488464,
                0.025824731215834618,
                0.04748212546110153,
                0.014538892544806004,
                0.07163389772176743,
                -0.02353169396519661,
                0.007837912999093533,
                0.0468134805560112,
                0.03347809240221977,
                -0.04737117141485214,
                0.024343164637684822,
                0.0053009167313575745,
                -0.06869987398386002,
                -0.022259341552853584,
                -0.05149216204881668,
                0.016036516055464745,
                -0.0029042467940598726,
                -0.0036947294138371944,
                0.03511287644505501,
                0.02267715334892273,
                -0.009673421271145344,
                -0.02188161388039589,
                0.028720607981085777,
                -0.001588314538821578,
                0.055428676307201385,
                0.005989522207528353,
                -0.005354196298867464,
                0.0049379137344658375,
                1.2993297104912926e-06,
                -0.01576869934797287,
                -0.00576708372682333,
                0.02383703552186489,
                -0.0022687974851578474,
                -0.0018692055018618703,
                0.05417262390255928,
                0.001066653523594141,
                -0.017826486378908157,
                0.01946091279387474,
                -0.01068202592432499,
                0.036366671323776245,
                0.06042829155921936,
                -0.02791542187333107,
                -0.03251570090651512,
                -0.011414684355258942,
                0.004598107188940048,
                -0.023577813059091568,
                -0.0533662773668766,
                -0.04057376831769943,
                0.006178968586027622,
                -0.004947854205965996,
                0.010500216856598854,
                0.021099824458360672,
                0.06645642220973969,
                0.11665841937065125,
                -0.03664793074131012,
                0.009184550493955612,
                0.0010128330904990435,
                -0.01641770638525486,
                0.061953917145729065,
                0.06694300472736359,
                0.02779962867498398,
                0.005071847699582577,
                0.02688797563314438,
                0.048882175236940384,
                -0.005267103668302298,
                -0.03187001496553421,
                0.03142936900258064,
                0.0387137271463871,
                -0.05631473660469055,
                0.0008644514018669724,
                0.007649511564522982,
                -0.007274557836353779,
                -0.0034950587432831526,
                -0.026800289750099182,
                0.020549960434436798,
                -0.02407636120915413,
                -0.02598515711724758,
                0.04286003112792969,
                0.009501971304416656,
                0.04636073485016823,
                0.007049275562167168,
                0.0060906438156962395,
                -0.03664964437484741,
                0.04484967514872551,
                0.047846660017967224,
                -0.038602571934461594,
                -0.008565465919673443,
                0.052147820591926575,
                -0.005129795055836439,
                -0.036405809223651886,
                0.06214723363518715,
                -0.037100836634635925,
                0.009797950275242329,
                0.011255650781095028,
                -0.04623638466000557,
                0.013494366779923439,
                0.049839895218610764,
                0.06818942725658417,
                -0.02336544170975685,
                -0.012083678506314754,
                0.016029803082346916,
                0.010762610472738743,
                -0.002976007526740432,
                0.014918896369636059,
                -0.0616215355694294,
                -0.04933493584394455,
                0.028447167947888374,
                -0.021234361454844475,
                -0.014418636448681355,
                0.006654031574726105,
                -0.0271573755890131,
                -0.02996896207332611,
                -0.0005638709990307689,
                -0.018916169181466103,
                0.006914740428328514,
                -0.004927004221826792,
                -0.030801018700003624,
                0.01750366762280464,
                0.00013634675997309387,
                0.051058609038591385,
                -0.018595006316900253,
                0.03834829479455948,
                0.06489744782447815,
                0.05713330954313278,
                -0.04034727066755295,
                0.0097020473331213,
                -0.03492753952741623,
                -0.008715752512216568,
                -0.01896018162369728,
                0.010189753025770187,
                -0.022900084033608437,
                0.04345731809735298,
                -0.04867205396294594,
                -0.05859113112092018,
                0.00870323646813631,
                -0.0462886206805706,
                -0.006004113703966141,
                0.038837503641843796,
                -0.008635315112769604,
                0.012587402015924454,
                0.009399673901498318,
                -0.03136058896780014,
                0.007352481596171856,
                -0.04316581413149834,
                -0.012796744704246521,
                0.005841889884322882,
                0.0027215732261538506,
                -0.036414735019207,
                0.0891813412308693,
                0.08010143786668777,
                0.023114873096346855,
                -0.015704849734902382,
                -0.03315276280045509,
                0.030541645362973213,
                -0.023199571296572685,
                0.0056291306391358376,
                0.014752672985196114,
                -0.016788126900792122,
                0.0025534990709275007,
                -0.018654752522706985,
                -6.193467561388388e-05,
                -0.05598640814423561,
                -0.0917934700846672,
                -0.026601018384099007,
                -0.021686745807528496,
                -0.016171488910913467,
                -0.044810712337493896,
                0.08664409816265106,
                -0.019789692014455795,
                0.010372578166425228,
                0.04868040606379509,
                0.08399804681539536,
                -0.010954980738461018,
                0.0013931734720245004,
                0.010570270009338856,
                -0.09535917639732361,
                0.05067220330238342,
                0.041590310633182526,
                -0.018909789621829987,
                -0.0101022245362401,
                0.019323201850056648,
                0.01273751724511385,
                -0.05922035127878189,
                -0.01729491353034973,
                -0.0017075749346986413,
                0.018786583095788956,
                -0.028185132890939713,
                0.012809669598937035,
                0.032584551721811295,
                -3.3066320611396804e-05,
                0.04127120599150658,
                0.05619976669549942,
                0.012629766017198563,
                0.015644049271941185,
                -0.02323523908853531,
                0.02560052089393139,
                0.011489770375192165,
                0.025895219296216965,
                -0.03191575035452843,
                -0.03299576789140701,
                -0.002642235951498151,
                -0.021089790388941765,
                -0.031134791672229767,
                0.016075531020760536,
                0.005856629461050034,
                0.0019014630233868957,
                0.018873490393161774,
                0.042496997863054276,
                -0.03686288371682167,
                -0.047764651477336884,
                -0.004917530808597803,
                -0.01776270382106304,
                -0.01836264505982399,
                0.07179222255945206,
                -0.03291505202651024,
                -0.001267125946469605,
                -0.0006816671811975539,
                -0.038330309092998505,
                -0.07291236519813538,
                0.05226932838559151,
                0.005105580203235149,
                -0.013606888242065907,
                -0.0692729726433754,
                0.008071285672485828,
                0.02182641066610813,
                0.012057531625032425,
                -0.005548383109271526,
                -0.0031251709442585707,
                -0.037087056785821915,
                -0.013688976876437664,
                0.10410924255847931,
                0.06923938542604446,
                0.014982401393353939,
                -0.07639991492033005,
                -0.0013492621947079897,
                -0.009595651179552078,
                0.03280947357416153,
                -0.010513856075704098,
                0.0693952664732933,
                -0.00674893194809556,
                -0.0040186685509979725,
                0.026536034420132637,
                0.019472749903798103,
                -0.0024507390335202217,
                -0.019428933039307594,
                -0.041336141526699066,
                -0.003967972472310066,
                0.03499075025320053,
                0.044245488941669464,
                -0.013669931329786777,
                0.0035641281865537167,
                0.025144953280687332,
                -0.01206567045301199,
                -0.034417856484651566,
                0.05132358893752098,
                -0.04069953411817551,
                -0.03511369600892067,
                0.009065980091691017,
                -0.015663450583815575,
                -0.03660093992948532,
                0.011884779669344425,
                -0.003439027816057205,
                -0.06403208523988724,
                0.016917480155825615,
                -0.016081731766462326,
                0.020020799711346626,
                0.03123612515628338,
                0.025537483394145966,
                0.004139277618378401,
                0.04903271794319153,
                -0.00635261507704854,
                0.011376053094863892,
                -0.08072163909673691,
                0.03785659745335579,
                -0.008460483513772488,
                -0.02718336321413517,
                -0.05454104766249657,
                -0.019385183230042458,
                -0.017387347295880318,
                0.05038188025355339,
                0.09581398218870163,
                0.0092593003064394,
                -0.053333599120378494,
                0.05570715293288231,
                0.0364857017993927,
                -0.007456821855157614,
                0.021791968494653702,
                -0.0565451942384243,
                -0.036639828234910965,
                0.03200154006481171,
                0.01295300293713808,
                0.02939945086836815,
                0.0237658079713583,
                0.04532232508063316,
                -0.025542980059981346,
                -0.016188429668545723,
                0.03225584328174591,
                0.006099569145590067,
                -0.04577561840415001,
                0.07980228960514069,
                0.023080529645085335,
                0.015911584720015526,
                -0.03646751865744591,
                -0.006720623932778835,
                -0.022509191185235977,
                0.0036206527147442102,
                0.04420250654220581,
                0.06384111940860748,
                0.0012845927849411964,
                -0.021697869524359703,
                0.00047764135524630547,
                0.0024303242098540068,
                0.010023209266364574,
                0.04140721634030342,
                0.02861838787794113,
                0.03636392578482628,
                -0.005346886347979307,
                0.011524404399096966,
                0.0283814687281847,
                -0.025607867166399956,
                -0.031243568286299706,
                0.020761825144290924,
                -0.026624124497175217,
                -0.0887894555926323,
                0.023491082713007927,
                0.04883400723338127,
                0.0002653809788171202,
                0.014956270344555378,
                0.032600052654743195,
                -0.012761695310473442,
                -0.02906772308051586,
                -0.1098470613360405,
                -0.0720798522233963,
                -0.002630222588777542,
                -0.01889841817319393,
                -0.05489363148808479,
                -0.04373946785926819,
                -0.002363318344578147,
                0.05170441418886185,
                0.023372793570160866,
                0.006708999164402485,
                -0.011214704252779484,
                -0.03292061761021614,
                -0.02016380801796913,
                0.08121182024478912,
                0.003225380787625909,
                -0.021122446283698082,
                -0.0080596674233675,
                -0.02341657690703869,
                0.1013602688908577,
                0.025336887687444687,
                -0.0026257652789354324,
                -0.030554361641407013,
                -0.02798670344054699,
                0.007601641118526459,
                -0.006280527450144291,
                -0.02578720822930336,
                0.010721688158810139,
                -0.030866021290421486,
                0.01974581554532051,
                0.04880548268556595,
                0.03907543048262596,
                -0.02903064154088497,
                -0.0009675032342784107,
                -0.004987239371985197,
                0.020052462816238403,
                -0.06692034751176834,
                0.01737486943602562,
                -0.04406049847602844,
                0.06046206131577492,
                -0.008542458526790142,
                0.0024866245221346617,
                -0.02887776494026184,
                0.025312619283795357,
                0.01964651793241501,
                -0.009896640665829182,
                0.07627659291028976,
                -0.01639441028237343,
                -0.03395586460828781,
                0.013064821250736713,
                -0.0017192899249494076,
                -0.01622377149760723,
                -0.009655657224357128,
                0.01517992839217186,
                -0.0009206710383296013,
                -0.02817266434431076,
                -0.09267241507768631,
                -0.0008540258859284222,
                -0.019641930237412453,
                -0.0007602212717756629,
                -0.03888799250125885,
                -0.010008890181779861,
                0.017907794564962387,
                0.047803595662117004,
                0.0524168461561203,
                0.0015596344601362944,
                -0.020741410553455353,
                0.03174930438399315,
                -0.03815450891852379,
                -0.01056206226348877,
                0.04187600687146187,
                -0.005584597587585449,
                -0.061267998069524765,
                -0.05936271324753761,
                0.06823540478944778,
                0.012599026784300804,
                0.019565891474485397,
                -0.05452597141265869,
                -0.03842714801430702,
                0.0061697885394096375,
                -0.007233491167426109,
                0.01469630841165781,
                0.05801752582192421,
                -0.035308193415403366,
                0.0007446767413057387,
                0.009688337333500385,
                0.05383720248937607,
                -0.03592207282781601,
                0.050496481359004974,
                -0.06128356605768204,
                -0.031835924834012985,
                0.07156262546777725,
                -0.038054097443819046,
                -0.01356301549822092,
                -0.04346425458788872,
                -0.05686658248305321,
                -0.03063010610640049,
                0.02246243692934513,
                0.011242418549954891,
                -0.06800971925258636,
                -0.026293816044926643,
                0.006412680726498365,
                -0.027966275811195374,
                0.019357476383447647,
                0.015695231035351753,
                -0.0055010817013680935,
                0.008329937234520912,
                -0.011423695832490921,
                0.042729124426841736,
                0.08997506648302078,
                -0.01751033402979374,
                -0.029703937470912933,
                -0.030772864818572998,
                0.0036130899097770452,
                0.07050012797117233,
                0.0002166055783163756,
                -0.03286697715520859,
                -0.008572977036237717,
                -0.000593512027990073,
                -0.0702681690454483,
                -0.09880347549915314,
                0.008402476087212563,
                -0.007183195557445288,
                0.03428265079855919,
                0.055359479039907455,
                0.03472364693880081,
                0.001414939877577126,
                -0.008271502330899239,
                -0.02719271183013916,
                -0.05252493917942047,
                -0.014354700222611427,
                -0.034187838435173035,
                -0.008611141704022884,
                -0.058294765651226044,
                -0.06004806607961655,
                0.004532868042588234,
                -0.040676601231098175,
                0.038621366024017334,
                0.03232700005173683,
                -0.0019419651944190264,
                -0.025560732930898666,
                -0.003749529365450144,
                -0.06081440672278404,
                0.001982335466891527,
                0.010449839755892754,
                0.04206414520740509,
                0.0035561274271458387,
                0.00031803056481294334,
                0.009081798605620861,
                0.012773960828781128,
                0.06645696610212326,
                -0.008435937575995922,
                0.03942039981484413,
                -0.035196226090192795,
                0.02432766743004322,
                -0.046596709638834,
                -0.0017720973119139671,
                0.0040373667143285275,
                0.07339132577180862,
                -0.00046642113011330366,
                -0.032675981521606445,
                0.04841974005103111,
                -0.04371641203761101,
                0.027053670957684517,
                -0.010026565752923489,
                0.02269737236201763,
                0.01814666949212551,
                -0.027478838339447975,
                -0.02897261269390583,
                0.06065663322806358,
                -0.012787632644176483,
                0.008937472477555275,
                0.009660284034907818,
                0.048926178365945816,
                0.033044617623090744,
                0.041089706122875214,
                -0.019281571730971336,
                0.014472861774265766,
                -0.00469200499355793,
                0.007286329288035631,
                0.014715084806084633,
                0.0058217598125338554,
                0.018734531477093697,
                -6.466866034315899e-05,
                -0.015688244253396988,
                -0.0482611320912838,
                0.03710126131772995,
                -0.022588051855564117,
                0.020474163815379143,
                -0.02017272636294365,
                0.023963525891304016,
                0.030054882168769836,
                -0.018328415229916573,
                0.04008016735315323,
                0.002509585116058588,
                -0.029936660081148148,
                -0.003048679558560252,
                0.030600858852267265,
                -0.0019531305879354477,
                0.07794678211212158,
                0.04352230951189995,
                -0.0034578845370560884,
                0.01852659322321415,
                0.04253392294049263,
                -0.017582248896360397,
                -0.004543545190244913,
                0.029553107917308807,
                0.03787558153271675,
                0.002392122521996498,
                -0.029270343482494354,
                0.05650115758180618,
                0.037886254489421844,
                -0.009176854975521564,
                0.013886663131415844,
                0.01452728547155857,
                0.029345789924263954,
                -0.019008072093129158,
                0.018383849412202835,
                0.008360820822417736,
                -5.898599428357545e-33,
                -0.012992216274142265,
                -0.05055350437760353,
                0.033946070820093155,
                0.021838240325450897,
                -0.06032838299870491,
                -0.006668700370937586,
                0.005518296267837286,
                -0.010050260461866856,
                -0.07863125950098038,
                -0.032855767756700516,
                -0.035664014518260956,
                0.028676290065050125,
                0.012177946045994759,
                0.010619809851050377,
                0.09085848927497864,
                -0.004797806032001972,
                0.012364107184112072,
                0.04357704520225525,
                -0.03241569176316261,
                -0.029752900823950768,
                -0.013326914049685001,
                -0.00731003237888217,
                0.031149961054325104,
                -0.00952809676527977,
                0.004275968763977289,
                0.019867558032274246,
                0.0009520570747554302,
                -0.04570572078227997,
                -0.04534522071480751,
                -0.03596016764640808,
                0.029313793405890465,
                -0.0029952023178339005,
                0.009016580879688263,
                -0.07380249351263046,
                -0.009366876445710659,
                0.013480423018336296,
                -0.03198337182402611,
                -0.04767417162656784,
                -0.06673281639814377,
                -0.00622722040861845,
                -0.0557442232966423,
                -0.02033654786646366,
                0.0012247110716998577,
                -0.008437703363597393,
                -0.08251630514860153,
                -0.08836662769317627,
                -0.0020226319320499897,
                0.03445456176996231,
                0.06014007329940796,
                -0.04722597822546959,
                -0.05010077357292175,
                -0.015959786251187325,
                -0.0530838742852211,
                0.022117670625448227,
                0.026202257722616196,
                -0.01288970373570919,
                0.033160798251628876,
                0.020847586914896965,
                -0.006134416908025742,
                0.04026922956109047,
                0.005261702463030815,
                0.07015175372362137,
                0.04783659428358078,
                -0.03785121813416481,
                -0.044399816542863846,
                0.0017585697351023555,
                -0.051651906222105026,
                0.013455122709274292,
                0.030932970345020294,
                -0.06747494637966156,
                -0.028269382193684578,
                0.10485785454511642,
                -0.02750256471335888,
                -0.01648966409265995,
                -0.06180451065301895,
                -0.0795271098613739,
                -0.06387650221586227,
                0.004508204758167267,
                0.03768589347600937,
                0.03334031626582146,
                -0.05304616317152977,
                0.019716084003448486,
                0.028164973482489586,
                -0.020500879734754562,
                0.005080795381218195,
                0.017877187579870224,
                0.014887123368680477,
                0.0635659247636795,
                -0.010712355375289917,
                0.021326206624507904,
                -0.0015016476390883327,
                -0.0016375117702409625,
                -0.017584970220923424,
                0.007558851502835751,
                0.05878940224647522,
                0.03293522074818611,
                0.013192577287554741,
                -0.02235224097967148,
                0.013460843823850155,
                0.04769549146294594,
                -0.04170247167348862,
                -0.03598294407129288,
                -0.05927641689777374,
                0.06491481512784958,
                0.008507668040692806,
                -0.01312621496617794,
                -0.09682156890630722,
                -0.001383923809044063,
                0.01585647277534008,
                0.010230083018541336,
                0.018489768728613853,
                -0.03452018275856972,
                0.007088878657668829,
                -0.05044499412178993,
                -0.004053717479109764,
                0.024780362844467163,
                0.011229673400521278,
                -0.06925664842128754,
                -0.04792051762342453,
                0.03726189211010933,
                0.0154191255569458,
                0.00782549474388361,
                -0.00815290492027998,
                0.03136647120118141,
                0.012572133913636208,
                -0.030076339840888977,
                0.04221596196293831,
                -0.05594365671277046,
                -0.015350867994129658,
                -0.022234898060560226,
                -0.028064433485269547,
                -0.002707128180190921,
                1.989383662248656e-07,
                -0.003663399489596486,
                0.020726189017295837,
                0.02562667988240719,
                0.0788407176733017,
                0.003729171585291624,
                0.008759234100580215,
                -0.011377460323274136,
                0.02534625120460987,
                0.036834366619586945,
                0.026211975142359734,
                -0.03337661549448967,
                -0.012577319517731667,
                -0.009858987294137478,
                -0.055611297488212585,
                -0.014493854716420174,
                -0.04928743839263916,
                0.01638929359614849,
                0.019042158499360085,
                0.01651046983897686,
                -0.0061246068216860294,
                0.024325072765350342,
                0.021678773686289787,
                -0.024747753515839577,
                -0.005820015911012888,
                -0.06039511039853096,
                0.007074548862874508,
                0.016126533970236778,
                -0.024271320551633835,
                0.1279972940683365,
                0.06805207580327988,
                -0.015136363916099072,
                0.03435857966542244,
                0.03590838611125946,
                0.06845895946025848,
                -0.029281949624419212,
                -0.030865633860230446,
                0.05221743881702423,
                0.006936078891158104,
                0.004176529124379158,
                0.03560412675142288,
                -0.038102887570858,
                0.027268260717391968,
                0.0194871723651886,
                -0.04315545782446861,
                0.020147953182458878,
                0.04318975657224655,
                -0.010638458654284477,
                -0.03508364036679268,
                -0.04278896003961563,
                -0.025483710691332817,
                -0.04675222560763359,
                0.041356656700372696,
                0.008236278779804707,
                -0.05844304710626602,
                0.0077453237026929855,
                -0.007109657395631075,
                -0.004218420945107937,
                0.0360700786113739,
                0.016749609261751175,
                0.04077713191509247,
                -0.012798509560525417,
                -0.04603108763694763,
                0.006606091745197773,
                0.02246125042438507,
                0.08042347431182861,
                -0.02296208031475544,
                -0.0825435146689415,
                1.1378931104809894e-34,
                0.014905774034559727,
                -0.02790183201432228,
                -0.022027336061000824,
                0.06944898515939713,
                -0.015952391549944878,
                -0.03914716839790344,
                -0.012335644103586674,
                -0.001011217711493373,
                -0.0070094638504087925,
                0.0010940851643681526,
                -0.05692191421985626
            ]
        },
        "Location": "Poland",
        "Remuneration": 41.18990384615385,
        "Services Required": {
            "name": " Subsea Project Installation Engineer",
            "rep": [
                0.0022684147115796804,
                0.03802826255559921,
                0.0018173697171732783,
                -0.07070377469062805,
                0.01603582501411438,
                0.0013856330187991261,
                0.010150201618671417,
                -0.04225970059633255,
                -0.016124408692121506,
                -0.011015420779585838,
                0.020113123580813408,
                -0.034994691610336304,
                0.02444685809314251,
                0.017556825652718544,
                0.015039350837469101,
                -0.05492724850773811,
                -0.01282872911542654,
                0.015447798185050488,
                -0.03486602380871773,
                0.026815146207809448,
                -0.04538782313466072,
                0.013005649670958519,
                -0.027743477374315262,
                -0.0240774042904377,
                0.028035148978233337,
                0.029662106186151505,
                -0.051740214228630066,
                0.001316807814873755,
                -0.02515612170100212,
                0.006986452266573906,
                0.08932957053184509,
                0.02785954624414444,
                -0.012338300235569477,
                0.011977162212133408,
                1.662133172430913e-06,
                0.06813155114650726,
                -0.001707232790067792,
                -0.027285508811473846,
                -0.047142814844846725,
                0.039027534425258636,
                -0.06148778647184372,
                -0.05373414233326912,
                0.007387069519609213,
                -0.011900077573955059,
                -0.02295040152966976,
                0.007855964824557304,
                0.03460004925727844,
                -0.03190213069319725,
                0.033629123121500015,
                -0.05372992902994156,
                -0.0029169758781790733,
                -0.05165523663163185,
                -0.06053538993000984,
                -0.01674584113061428,
                -0.0328713059425354,
                -0.10949934273958206,
                0.007080414332449436,
                0.002017566468566656,
                0.010342317633330822,
                0.0755329355597496,
                0.05764005705714226,
                0.0324537418782711,
                0.0029930996242910624,
                0.0073109460063278675,
                0.03666571527719498,
                0.004492369946092367,
                0.010714754462242126,
                -0.029974112287163734,
                -0.02902720496058464,
                0.027037043124437332,
                0.005183635279536247,
                0.04482448101043701,
                -0.013245318084955215,
                0.06307535618543625,
                -0.036322884261608124,
                -0.013250396586954594,
                0.02127862349152565,
                -0.018204301595687866,
                -0.023277100175619125,
                -0.03176850453019142,
                -0.003925314173102379,
                -0.03382839262485504,
                -0.027964435517787933,
                -0.011632783338427544,
                -0.02980434149503708,
                0.08640746027231216,
                -0.02717406302690506,
                0.014063915237784386,
                -0.025587406009435654,
                0.04696269705891609,
                -0.011876384727656841,
                -0.04189547896385193,
                -0.014106030575931072,
                -0.02475159242749214,
                -0.007342569995671511,
                -0.0002492748899385333,
                0.008233016356825829,
                -0.019112959504127502,
                -0.025874275714159012,
                -0.017381716519594193,
                -0.017245933413505554,
                0.08404005318880081,
                0.057961393147706985,
                0.07294033467769623,
                -0.02405528724193573,
                0.05411289259791374,
                -0.021778039634227753,
                0.014353511855006218,
                0.0047692046500742435,
                -0.003460157196968794,
                0.05306839570403099,
                -0.0042012277990579605,
                -0.009648418053984642,
                -0.05028688907623291,
                -0.044992439448833466,
                0.0059509738348424435,
                0.002517239423468709,
                0.030483338981866837,
                0.06333356350660324,
                0.03571588918566704,
                -0.018948907032608986,
                0.02101024240255356,
                -0.0135071761906147,
                0.013845249079167843,
                -0.018939035013318062,
                -0.04993210732936859,
                0.04952835291624069,
                0.001994102029129863,
                0.0052880761213600636,
                0.0637238472700119,
                -0.01184918824583292,
                0.026419002562761307,
                0.00211013644002378,
                -0.024251336231827736,
                -0.011824368499219418,
                -0.010289901867508888,
                -0.0075605325400829315,
                -0.012226969003677368,
                -0.04333359748125076,
                -0.017294593155384064,
                0.04720968008041382,
                0.00012748432345688343,
                -0.04274121671915054,
                0.02878233976662159,
                -0.0174057949334383,
                -0.06545893847942352,
                -0.058213844895362854,
                0.009033778682351112,
                0.01713036559522152,
                -0.002974714618176222,
                0.03684557229280472,
                -0.009221016429364681,
                -0.02904232032597065,
                0.0030187275260686874,
                0.05105552077293396,
                0.04465095326304436,
                -0.02416408248245716,
                -0.025878526270389557,
                0.0009755070204846561,
                0.09212648868560791,
                -0.03136521577835083,
                -0.013364756479859352,
                -0.04387028142809868,
                0.07124929875135422,
                -0.03335622698068619,
                -0.0051423655822873116,
                0.054306935518980026,
                -0.005166822113096714,
                0.005781443789601326,
                0.00416390597820282,
                0.04011043161153793,
                -0.013187545351684093,
                0.055846478790044785,
                0.023774992674589157,
                0.0219187680631876,
                -0.06058017536997795,
                -0.01571516878902912,
                0.004600705113261938,
                0.03234364837408066,
                0.05173541605472565,
                0.03709007054567337,
                -0.08814933896064758,
                -0.011741585098206997,
                0.03662813827395439,
                0.06477800011634827,
                0.008792203851044178,
                -0.029133370146155357,
                0.0255766399204731,
                -0.05826291814446449,
                0.020503520965576172,
                0.00906047411262989,
                -0.027501411736011505,
                -0.061351992189884186,
                0.006706457119435072,
                0.01587929204106331,
                0.038092583417892456,
                -0.03569433093070984,
                0.0164638739079237,
                -0.029423484578728676,
                0.03039001300930977,
                0.0035543215926736593,
                0.008765840902924538,
                0.012288213707506657,
                0.010952101089060307,
                -0.00593534205108881,
                -0.023436661809682846,
                0.006884599570184946,
                -0.04014817997813225,
                -0.0057155354879796505,
                0.029420122504234314,
                -0.009590204805135727,
                0.008581956848502159,
                -0.007737194653600454,
                -0.013843024149537086,
                -0.02555292285978794,
                0.013771632686257362,
                -0.0394948348402977,
                0.016468722373247147,
                0.023972589522600174,
                0.0008220214513130486,
                -0.0294890608638525,
                -0.018892943859100342,
                -0.010596103966236115,
                0.022363116964697838,
                -0.01941056177020073,
                -0.05610986426472664,
                -0.047164976596832275,
                0.02495438978075981,
                -0.05092426389455795,
                0.06825029104948044,
                -0.009864198975265026,
                -0.004500976763665676,
                -0.0003966563963331282,
                0.0037236781790852547,
                -0.059886954724788666,
                -0.021610258147120476,
                -0.008499788120388985,
                0.010077225975692272,
                -0.0008724541403353214,
                -0.044998519122600555,
                -0.0059434096328914165,
                0.015506553463637829,
                -0.04839971289038658,
                0.015939190983772278,
                0.05669721961021423,
                -0.017195120453834534,
                0.04126238822937012,
                0.025232240557670593,
                0.06635624915361404,
                0.027700889855623245,
                -0.015188525430858135,
                -0.05471393093466759,
                0.02493131160736084,
                0.004025857429951429,
                0.014127060770988464,
                0.01641790382564068,
                -0.056808434426784515,
                0.05028365179896355,
                -0.04192385450005531,
                -0.051660023629665375,
                -0.019418200477957726,
                -0.025402886793017387,
                -0.03834869712591171,
                -0.007314003072679043,
                0.05489687621593475,
                0.025405194610357285,
                0.05277537181973457,
                -0.036956220865249634,
                -0.019249022006988525,
                0.018015829846262932,
                0.01604146882891655,
                0.03204493597149849,
                0.018591416999697685,
                -0.017315149307250977,
                -0.032630160450935364,
                0.030143048614263535,
                -0.0687716081738472,
                -0.03499159961938858,
                -0.04231426492333412,
                0.003363625379279256,
                0.009488264098763466,
                0.024643637239933014,
                -0.06087229773402214,
                -0.03173978626728058,
                0.02587435021996498,
                0.04386977106332779,
                0.06468420475721359,
                0.014577255584299564,
                -0.04656240716576576,
                0.049739230424165726,
                -0.0024407526943832636,
                -0.06694138050079346,
                0.03315271437168121,
                -0.09510214626789093,
                -0.0560087151825428,
                0.06299883872270584,
                -0.03762469440698624,
                -0.027937231585383415,
                -0.017746660858392715,
                0.008890820667147636,
                -0.01860186457633972,
                0.025595594197511673,
                -0.001919964444823563,
                -0.012790940701961517,
                -0.013037274591624737,
                0.007975060492753983,
                0.02815898507833481,
                0.08951843529939651,
                -0.07518274337053299,
                0.016597583889961243,
                -0.02704361453652382,
                0.03668944165110588,
                0.04025810956954956,
                0.03775140643119812,
                0.018014153465628624,
                -0.03165625408291817,
                0.07074284553527832,
                -0.039710111916065216,
                0.0010542238596826792,
                0.07009345293045044,
                -0.009607823565602303,
                -0.0004301508888602257,
                -0.04935420677065849,
                -0.026186740025877953,
                -0.023406842723488808,
                -0.07639581710100174,
                -0.005191669333726168,
                -0.02547428570687771,
                0.0005069398903287947,
                -0.03194279223680496,
                0.0008944153087213635,
                0.0249570794403553,
                -0.014067478477954865,
                -0.043199148029088974,
                0.005031808279454708,
                -0.029042573645710945,
                0.032292354851961136,
                -0.053253233432769775,
                -0.02638775296509266,
                -0.005851492285728455,
                0.00869449507445097,
                -0.03291521593928337,
                -0.08552775532007217,
                0.009695082902908325,
                -0.012907066382467747,
                0.020313479006290436,
                0.04420804977416992,
                -0.032185476273298264,
                -0.016284596174955368,
                0.027413804084062576,
                0.0730905756354332,
                0.012574873864650726,
                0.0016017116140574217,
                0.0256218109279871,
                -0.008235091343522072,
                0.02534048818051815,
                -0.002137728501111269,
                0.06110960990190506,
                -0.01298462599515915,
                0.029998518526554108,
                -0.010512310080230236,
                -0.014412452466785908,
                0.00540549261495471,
                -0.01810884289443493,
                -0.03250943496823311,
                -0.027020933106541634,
                0.037198975682258606,
                0.007854105904698372,
                0.010070595890283585,
                -0.0283211600035429,
                -0.04519525170326233,
                0.04248032346367836,
                0.016805186867713928,
                -0.05570896714925766,
                0.024289969354867935,
                0.014403666369616985,
                -0.01283816248178482,
                0.025803277269005775,
                0.03379901498556137,
                0.009051581844687462,
                -0.05417297035455704,
                0.004186945501714945,
                0.02677408792078495,
                0.02672833763062954,
                0.025525303557515144,
                -0.004075761418789625,
                0.01079591829329729,
                -0.028068508952856064,
                0.05455615371465683,
                0.053906023502349854,
                0.010178579948842525,
                0.001545551000162959,
                0.0017497764201834798,
                -0.05959053710103035,
                0.04271760955452919,
                -0.0035901060327887535,
                -0.049601346254348755,
                0.006548634730279446,
                0.06226872652769089,
                0.03056834451854229,
                0.03948713466525078,
                -0.019557898864150047,
                -0.08298768848180771,
                -0.022666582837700844,
                -0.03440721705555916,
                -0.04980716109275818,
                0.06204836443066597,
                0.01123864483088255,
                0.019468067213892937,
                -0.0006133861606940627,
                0.03500920534133911,
                -0.005975273437798023,
                0.03693883866071701,
                0.010031058453023434,
                0.021136382594704628,
                -0.006989256013184786,
                -0.00878964178264141,
                -0.05839550867676735,
                -0.051563192158937454,
                -0.0951462909579277,
                0.028314048424363136,
                0.020447678864002228,
                0.04662594571709633,
                -0.038209471851587296,
                0.10790027678012848,
                -0.05273249000310898,
                0.021757982671260834,
                0.057089682668447495,
                -0.03801487386226654,
                -0.00531849404796958,
                -0.060585398226976395,
                0.014330839738249779,
                0.017143171280622482,
                0.01983802579343319,
                0.07215424627065659,
                0.029274534434080124,
                0.03522982820868492,
                -0.030952569097280502,
                0.0562722347676754,
                -0.0026735675055533648,
                0.01710185781121254,
                0.032160114496946335,
                0.011043366976082325,
                0.024824179708957672,
                -0.0383453294634819,
                -0.005154571030288935,
                0.018558431416749954,
                -0.0002723766665440053,
                -0.02573489584028721,
                -0.011543912813067436,
                0.1019209548830986,
                0.031618453562259674,
                -0.040720827877521515,
                -0.007203609216958284,
                -0.05511914938688278,
                0.039717480540275574,
                -0.04321863129734993,
                0.06496702134609222,
                -0.013710091821849346,
                0.07013244926929474,
                0.05909284949302673,
                0.030908457934856415,
                -0.08333450555801392,
                -0.03917229175567627,
                -0.024950023740530014,
                0.002952371956780553,
                -0.022603457793593407,
                -0.07436762005090714,
                -0.031108049675822258,
                -0.0018684507813304663,
                0.05317942798137665,
                -0.0001634872314753011,
                -0.02970552071928978,
                0.03328466787934303,
                -0.05090400204062462,
                -0.042989686131477356,
                0.007796484045684338,
                0.021000757813453674,
                -0.03856567665934563,
                0.013908999972045422,
                0.05607620254158974,
                0.015545956790447235,
                0.009832832962274551,
                0.009968861937522888,
                0.032729197293519974,
                0.03414851054549217,
                0.026674190536141396,
                0.005064342170953751,
                0.01547254528850317,
                0.04090606048703194,
                -0.016045577824115753,
                -0.005195098929107189,
                0.011001710779964924,
                0.003974588122218847,
                0.03018609620630741,
                0.016957782208919525,
                0.03384370356798172,
                0.07455096393823624,
                0.030092639848589897,
                -0.05683619901537895,
                0.0006877343403175473,
                0.04739676043391228,
                0.02747689001262188,
                -0.018656117841601372,
                -0.024161335080862045,
                -0.003966658376157284,
                0.017877714708447456,
                -0.058391038328409195,
                -0.0179451797157526,
                -0.044514816254377365,
                -0.08298709243535995,
                -0.009379606693983078,
                -0.0028239961247891188,
                -0.0536315031349659,
                0.04062332585453987,
                -0.014793705195188522,
                -0.004008693620562553,
                0.038128357380628586,
                -0.034591738134622574,
                -0.003686075797304511,
                0.024470003321766853,
                -0.02155308611690998,
                -0.03795301169157028,
                -0.03949522599577904,
                0.019816169515252113,
                -0.02342999167740345,
                0.018135596066713333,
                0.02496866136789322,
                -0.030615728348493576,
                0.004245326854288578,
                0.032115645706653595,
                0.037982359528541565,
                -0.0516338087618351,
                -0.011910085566341877,
                -0.003368416568264365,
                -0.0018423993606120348,
                0.026208439841866493,
                0.1222815066576004,
                -0.04521583020687103,
                -0.02719057723879814,
                -0.06077765300869942,
                0.0055438438430428505,
                -0.025001218542456627,
                0.007802470121532679,
                0.030657991766929626,
                -0.0006062838947400451,
                0.045326150953769684,
                0.02033768966794014,
                0.0044769467785954475,
                0.0026193580124527216,
                0.0019676743540912867,
                -0.09946876764297485,
                0.018680989742279053,
                0.009047337807714939,
                -0.007967256009578705,
                -5.1221611650684384e-33,
                -0.015669668093323708,
                -0.039381250739097595,
                0.029610561206936836,
                0.04868784174323082,
                -0.007693123072385788,
                0.053110700100660324,
                0.014789627864956856,
                0.0328359492123127,
                -0.017228271812200546,
                -0.0795627012848854,
                0.006510236766189337,
                0.018568387255072594,
                0.0019956380128860474,
                -0.012822856195271015,
                0.00926356390118599,
                0.06526628881692886,
                0.020403139293193817,
                -0.013451116159558296,
                -0.027034524828195572,
                -0.05998484045267105,
                -0.02588980831205845,
                0.03015955723822117,
                0.029407842084765434,
                -0.04838040471076965,
                -0.007248959969729185,
                0.003880451200529933,
                0.014226886443793774,
                0.026546660810709,
                0.04924311116337776,
                -0.02841675840318203,
                -0.0028391219675540924,
                0.009262673556804657,
                -0.004185396246612072,
                0.04763304442167282,
                -0.005425543524324894,
                -0.017110122367739677,
                -0.05127650126814842,
                -0.002924524247646332,
                0.006925042252987623,
                0.02581937052309513,
                -0.0231282040476799,
                -0.007730180863291025,
                -0.0009873190429061651,
                -0.011292979121208191,
                -0.025257233530282974,
                -0.03521294519305229,
                0.006474960595369339,
                0.05899849161505699,
                0.035581499338150024,
                0.062299735844135284,
                -0.08327958732843399,
                0.03493085131049156,
                0.026444029062986374,
                -0.02943943627178669,
                0.04103880003094673,
                0.03600452467799187,
                0.019461316987872124,
                -0.04767590016126633,
                0.028610460460186005,
                -0.0034011981915682554,
                -0.0009161056368611753,
                0.0011382371885702014,
                0.04663712531328201,
                -0.030764933675527573,
                -0.043433915823698044,
                0.014907864853739738,
                0.03324967995285988,
                0.026134027168154716,
                -0.01185817550867796,
                -0.051584191620349884,
                -0.04386946186423302,
                0.01817217469215393,
                -0.03549634665250778,
                -0.00410346919670701,
                -0.011988876387476921,
                -0.006932214833796024,
                -0.005031569395214319,
                0.043787118047475815,
                0.0440346784889698,
                0.030877022072672844,
                -0.013799641281366348,
                0.00833295751363039,
                -0.04085773974657059,
                -0.0016229937318712473,
                0.0037717025261372328,
                -0.042815644294023514,
                0.005474545061588287,
                0.01052226684987545,
                0.005461833439767361,
                0.0192722175270319,
                -0.015746358782052994,
                -0.05746803805232048,
                0.013860381208360195,
                0.0032702861353754997,
                0.1249900758266449,
                0.007822887040674686,
                -0.01425943709909916,
                -0.04213596135377884,
                -0.009297975338995457,
                0.05443133786320686,
                0.002767968690022826,
                0.017039109021425247,
                -0.015751192346215248,
                -0.0030413977801799774,
                0.027774661779403687,
                0.006987541913986206,
                -0.09493475407361984,
                0.05643106624484062,
                0.021651173010468483,
                0.003367261029779911,
                -0.01766563579440117,
                -0.042633306235075,
                0.008330143988132477,
                0.018185902386903763,
                -0.05634140968322754,
                -0.0036148997023701668,
                -0.015141710638999939,
                -0.038367725908756256,
                -0.002782810479402542,
                0.03603103384375572,
                0.04016317054629326,
                -0.010751147754490376,
                -0.029851891100406647,
                0.004936447367072105,
                -0.02140720933675766,
                -0.008112066425383091,
                0.007477535400539637,
                -0.04817206412553787,
                -0.03733162581920624,
                0.006096165161579847,
                -0.00032086961437016726,
                0.06005684658885002,
                2.1129430649580172e-07,
                -0.07315800338983536,
                0.027183376252651215,
                0.05107928067445755,
                0.02849443443119526,
                0.015475290827453136,
                -0.014727477915585041,
                0.027696475386619568,
                -0.027400190010666847,
                -0.04121413081884384,
                -0.020685113966464996,
                0.022321689873933792,
                0.003976374864578247,
                -0.0193952526897192,
                -0.03475809842348099,
                -0.005719548556953669,
                -0.0467911958694458,
                0.07327678054571152,
                -0.024092882871627808,
                0.0007945949910208583,
                -0.05745577812194824,
                0.11204047501087189,
                0.027247127145528793,
                0.006065222434699535,
                -0.02363760769367218,
                -0.031621091067790985,
                -0.0038598899263888597,
                -0.011221282184123993,
                0.014731522649526596,
                0.03522385656833649,
                -0.01674586907029152,
                0.046068862080574036,
                0.029374120756983757,
                -0.009622025303542614,
                0.06281733512878418,
                0.007000920362770557,
                -0.07198012620210648,
                0.11110179871320724,
                -0.02717338129878044,
                -0.038130488246679306,
                0.0025919508188962936,
                0.010591776110231876,
                0.004914507269859314,
                0.007996770553290844,
                -0.03772887587547302,
                -0.049375083297491074,
                -0.012647123076021671,
                -0.03126963973045349,
                -0.015209979377686977,
                -0.04504609853029251,
                -0.03503291308879852,
                -0.005164616275578737,
                0.0877770408987999,
                0.011786527000367641,
                -0.05416129156947136,
                0.017703324556350708,
                -0.012747809290885925,
                -0.06450338661670685,
                0.015800127759575844,
                0.031770940870046616,
                0.05218204855918884,
                -0.00604714872315526,
                -0.029876181855797768,
                0.007404100149869919,
                0.0033520194701850414,
                0.04378671199083328,
                0.006783472374081612,
                0.003934962209314108,
                1.8148021063738546e-34,
                -0.06185150146484375,
                -0.019590962678194046,
                -0.02481100521981716,
                0.05695908144116402,
                0.027868440374732018,
                -0.0035660273861140013,
                -0.06790264695882797,
                -0.007768074981868267,
                0.037469957023859024,
                -0.06875010579824448,
                0.0028177746571600437
            ]
        },
        "Skills": {
            "name": [
                "Application Engineering",
                "Pressure testing",
                "FEED",
                "Project planning",
                "EPC",
                "Geology",
                "Drilling Engineering",
                "Project Management",
                "Pre-commissioning",
                "Subsea Projects"
            ],
            "rep": [
                0.005418773274868727,
                -0.03290842846035957,
                -0.04750852286815643,
                -0.002335500670596957,
                -0.044313184916973114,
                0.0025583598762750626,
                -0.010362760163843632,
                -0.012030750513076782,
                -0.030515072867274284,
                -0.018841326236724854,
                0.0969386026263237,
                0.018796049058437347,
                0.017710993066430092,
                0.05079727992415428,
                0.03058450110256672,
                -0.04575158655643463,
                0.03657697141170502,
                0.017110200598835945,
                -0.07256610691547394,
                0.03997737541794777,
                -0.004291693679988384,
                -0.022211426869034767,
                0.008711897768080235,
                0.008749406784772873,
                -0.039133768528699875,
                -0.030760373920202255,
                -0.0414305180311203,
                0.04553459957242012,
                0.0072434511967003345,
                0.017030181363224983,
                0.003506128443405032,
                0.005200397223234177,
                -0.007636462803930044,
                0.004813235253095627,
                1.6973629044514382e-06,
                -0.009921850636601448,
                -0.020414477214217186,
                0.05699517950415611,
                0.0017394974129274487,
                0.06638442724943161,
                0.06540720909833908,
                0.04802421107888222,
                -0.03510501608252525,
                0.015893256291747093,
                0.029507985338568687,
                0.07398271560668945,
                0.09265365451574326,
                -0.027117444202303886,
                0.03654414415359497,
                -0.04101758822798729,
                0.006890277378261089,
                -0.04274141788482666,
                -0.048490773886442184,
                -0.0009480171138420701,
                -0.024081947281956673,
                -0.03170325607061386,
                0.02372940257191658,
                0.02711794711649418,
                0.14830704033374786,
                0.027721911668777466,
                -0.006084706634283066,
                -0.008502204902470112,
                0.016018150374293327,
                -0.02212470769882202,
                0.08165007829666138,
                -0.007574001792818308,
                0.023760981857776642,
                0.010861169546842575,
                -0.024303041398525238,
                -0.006087432615458965,
                0.035363081842660904,
                0.035146553069353104,
                0.005357048939913511,
                0.03920968249440193,
                -0.05529923364520073,
                -0.010385662317276001,
                0.005318541079759598,
                -0.04166320711374283,
                0.00955529510974884,
                -0.033348165452480316,
                -0.0338316336274147,
                0.0002192648680647835,
                -0.022638410329818726,
                0.003442076500505209,
                -0.01461128331720829,
                0.048440948128700256,
                -0.008749526925384998,
                -0.0035079673398286104,
                -0.005308542400598526,
                -0.018934665247797966,
                -0.002432727487757802,
                0.010406344197690487,
                0.009624102152884007,
                -0.00032064528204500675,
                -0.018073217943310738,
                -0.01733158715069294,
                0.031123125925660133,
                -0.027409926056861877,
                0.0009001829312182963,
                -0.032015204429626465,
                0.02846495248377323,
                0.0035610520280897617,
                0.04759727790951729,
                0.03344123810529709,
                0.013045924715697765,
                0.011394781060516834,
                0.00965545792132616,
                -0.037950072437524796,
                -0.0037716845981776714,
                0.04164532572031021,
                -0.05343995615839958,
                -0.0025028695818036795,
                -0.03051331266760826,
                0.05381721258163452,
                -0.00036510961945168674,
                -0.0003907517238985747,
                -0.047978904098272324,
                0.03784435987472534,
                -0.0275777205824852,
                0.03719693049788475,
                0.03466278687119484,
                0.0404793880879879,
                -0.028324468061327934,
                0.014933658763766289,
                -0.003932807128876448,
                -0.02512465976178646,
                -0.03757888823747635,
                -0.011972068808972836,
                0.00702818064019084,
                -0.00798327662050724,
                0.011677912436425686,
                -0.013360505923628807,
                -0.04022873565554619,
                -0.010011866688728333,
                -0.028524478897452354,
                0.05175046995282173,
                0.013903451152145863,
                0.07609043270349503,
                -0.10998328030109406,
                -0.05481148511171341,
                0.034432630985975266,
                -0.00907669123262167,
                -0.09373953938484192,
                -0.03246600553393364,
                -0.06640928983688354,
                -0.04035194218158722,
                0.003599584335461259,
                0.002088601002469659,
                -0.0037147009279578924,
                -0.013091293163597584,
                -0.014452888630330563,
                0.0028105040546506643,
                0.016796153038740158,
                0.0129372114315629,
                0.09278973191976547,
                0.0650569349527359,
                0.04576520249247551,
                0.03584667295217514,
                -0.04482105374336243,
                0.038132503628730774,
                0.013167984783649445,
                -0.03321267291903496,
                0.05121336877346039,
                0.004570837132632732,
                -0.05178765580058098,
                -0.0058411224745213985,
                -0.001783308805897832,
                0.01983289048075676,
                -0.04323529452085495,
                -0.05044581741094589,
                -0.07156281173229218,
                -0.012769849970936775,
                -0.005508602596819401,
                0.08023242652416229,
                0.041317638009786606,
                -0.0009188545518554747,
                0.04227355867624283,
                -0.0035090078599750996,
                0.010480020195245743,
                0.06282273679971695,
                -0.021826140582561493,
                -0.08517678081989288,
                0.02038990519940853,
                0.059407856315374374,
                -0.039857517927885056,
                -0.009222675114870071,
                -0.03771742060780525,
                0.03161222115159035,
                -0.050026409327983856,
                -0.02011186070740223,
                0.036651186645030975,
                -0.029582712799310684,
                -0.03069840930402279,
                0.0032783246133476496,
                -0.0024905228056013584,
                0.02250569313764572,
                0.022559359669685364,
                0.0019401817116886377,
                -0.005014689173549414,
                -0.0007970306905917823,
                -0.033962100744247437,
                -0.0462193489074707,
                -0.04152577370405197,
                0.03767334297299385,
                0.025494884699583054,
                -0.06002523750066757,
                0.03086826205253601,
                0.008912840858101845,
                -0.08746623247861862,
                -0.013017128221690655,
                0.08099828660488129,
                -0.010219788178801537,
                0.0032595545053482056,
                0.05438714101910591,
                -0.007662543561309576,
                -0.004137995652854443,
                0.024977533146739006,
                0.006900987587869167,
                -0.05377393960952759,
                0.04806935414671898,
                -0.04370721057057381,
                -0.004874274134635925,
                -0.01460088137537241,
                -0.021963566541671753,
                -0.019687755033373833,
                -0.03988679125905037,
                0.03718911483883858,
                0.014269986189901829,
                -0.0255926214158535,
                0.04246397316455841,
                0.011748072691261768,
                -0.006470046006143093,
                0.03548111021518707,
                0.0299124363809824,
                -0.005358695052564144,
                -0.008670710027217865,
                0.10871873795986176,
                0.13311900198459625,
                -0.027596738189458847,
                -0.0374210849404335,
                0.01631489396095276,
                0.015512288548052311,
                0.005988082382827997,
                0.009107132442295551,
                0.0511031374335289,
                -0.024431632831692696,
                0.0013883509673178196,
                0.050740066915750504,
                0.03151668608188629,
                -0.05337377265095711,
                -0.007705609779804945,
                -0.04153376817703247,
                0.01361467782407999,
                0.051563460379838943,
                0.03628801554441452,
                -0.046666018664836884,
                -0.04575413838028908,
                0.035552527755498886,
                0.01180354692041874,
                -0.047783542424440384,
                0.03203057497739792,
                -0.04946162551641464,
                -0.003805680898949504,
                0.021633652970194817,
                -0.009890341199934483,
                -0.014187730848789215,
                0.03550662472844124,
                -0.04004409536719322,
                -0.029318293556571007,
                0.013669926673173904,
                0.008871465921401978,
                0.048892371356487274,
                0.03469746559858322,
                0.009654936380684376,
                -0.003793338080868125,
                0.042403001338243484,
                0.0072873495519161224,
                -0.06635820865631104,
                -0.09567169100046158,
                0.04803377017378807,
                0.03928472474217415,
                -0.038029760122299194,
                -0.010009106248617172,
                -0.004233351908624172,
                0.005302267614752054,
                0.03533522039651871,
                0.02505987137556076,
                -0.024727532640099525,
                -0.04078739136457443,
                0.027702465653419495,
                0.003735303645953536,
                -0.04585062712430954,
                0.047326069325208664,
                -0.045160286128520966,
                -0.023201538249850273,
                0.02917647548019886,
                -0.00017044747073668987,
                0.02568778395652771,
                -0.037998128682374954,
                0.05004198104143143,
                -0.022068312391638756,
                7.9440011177212e-05,
                0.04518367350101471,
                -0.025189360603690147,
                -0.0007900669006630778,
                0.015960175544023514,
                0.031255096197128296,
                -0.02933025173842907,
                0.01274824608117342,
                0.004773756023496389,
                -0.004433727823197842,
                0.0034048634115606546,
                0.03958260267972946,
                0.03440496698021889,
                0.008217365480959415,
                0.015740497037768364,
                -0.01701236516237259,
                -0.024682246148586273,
                0.031241822987794876,
                0.04911169782280922,
                -0.008550799451768398,
                0.028560107573866844,
                0.03675084188580513,
                -0.02525872364640236,
                0.042267054319381714,
                -0.019813327118754387,
                -0.06009192019701004,
                -0.009569249115884304,
                0.02131621353328228,
                -0.07556337118148804,
                0.021294236183166504,
                0.02640012465417385,
                -0.011171112768352032,
                0.03602483868598938,
                -0.05363089591264725,
                -0.013982792384922504,
                -0.013150711543858051,
                -0.03918585926294327,
                -0.03986404836177826,
                -0.0375090092420578,
                -0.021596873179078102,
                0.002653170609846711,
                -0.010696155950427055,
                -0.00644202483817935,
                0.0623331144452095,
                -0.007423784118145704,
                -0.02313973382115364,
                0.041688356548547745,
                -0.012761997990310192,
                0.014271380379796028,
                0.05901126191020012,
                0.029785098508000374,
                -0.006898767314851284,
                -0.038918010890483856,
                -0.011363336816430092,
                -0.022528253495693207,
                -0.011511227115988731,
                0.009317132644355297,
                -0.03712501376867294,
                0.0033012800849974155,
                0.02897980809211731,
                -0.025115719065070152,
                0.027290882542729378,
                0.015643315389752388,
                -0.07357776910066605,
                0.0176484864205122,
                0.01861393265426159,
                -0.0256193894892931,
                -0.025521039962768555,
                0.06813108175992966,
                0.004250817932188511,
                0.002486466895788908,
                0.01981712132692337,
                -0.028140608221292496,
                0.023179009556770325,
                0.0042638895101845264,
                0.009861350059509277,
                -0.008566612377762794,
                -0.02656937763094902,
                0.025785207748413086,
                0.030556684359908104,
                -0.03604676574468613,
                0.05314800888299942,
                -0.003573960391804576,
                0.01845550537109375,
                -0.053921304643154144,
                -0.024222737178206444,
                0.004125854466110468,
                0.015384037978947163,
                0.05803325027227402,
                0.005756738595664501,
                -0.01330964919179678,
                -0.04004490375518799,
                -0.002229718491435051,
                0.028026923537254333,
                0.005687914788722992,
                -0.007368141319602728,
                0.02242782898247242,
                0.01563173532485962,
                0.0219182800501585,
                0.013642903417348862,
                -0.04615600034594536,
                -0.07045537233352661,
                0.028768351301550865,
                7.218584505608305e-05,
                -0.027708983048796654,
                0.05852581933140755,
                0.02910907007753849,
                -0.029688924551010132,
                0.01940397173166275,
                0.013354643248021603,
                0.000862151151522994,
                0.05400409922003746,
                -0.055455707013607025,
                -0.04589885100722313,
                0.026244040578603745,
                -0.01666170544922352,
                0.014150567352771759,
                0.017042022198438644,
                -0.049925547093153,
                0.03366686403751373,
                0.03263917565345764,
                0.054699886590242386,
                -0.04063715785741806,
                0.04734203591942787,
                -0.06521885097026825,
                -0.045046962797641754,
                0.09496083110570908,
                -0.03702167049050331,
                0.0011918762465938926,
                -0.05692357197403908,
                -0.04027213528752327,
                -0.01569775678217411,
                0.015881529077887535,
                -0.01551727019250393,
                -0.06342051923274994,
                -0.015971090644598007,
                0.012618024833500385,
                -0.029098449274897575,
                0.024915382266044617,
                0.009418691508471966,
                -0.017442530021071434,
                0.047009676694869995,
                0.03231339529156685,
                0.004294822923839092,
                0.06520340591669083,
                0.02144390530884266,
                -0.0219420213252306,
                -0.0475824810564518,
                0.015749862417578697,
                0.09594575315713882,
                0.04017181321978569,
                0.023910656571388245,
                -0.04559401050209999,
                -0.01638517528772354,
                -0.0605546236038208,
                -0.102246955037117,
                -0.005472436547279358,
                0.014908661134541035,
                0.051258545368909836,
                0.07525675743818283,
                0.04072851315140724,
                -0.03240089491009712,
                -0.03597317636013031,
                -0.004272358492016792,
                -0.07137246429920197,
                -0.016043705865740776,
                -0.04111236706376076,
                0.008698242716491222,
                -0.042443495243787766,
                -0.07011537253856659,
                -0.0066015832126140594,
                -0.06505560129880905,
                0.021290937438607216,
                0.03537464141845703,
                0.01486459095031023,
                -0.028031036257743835,
                0.005689096637070179,
                -0.01720917411148548,
                -0.019939497113227844,
                0.013734888285398483,
                -0.017650676891207695,
                0.05281895026564598,
                0.0031923989299684763,
                0.05650532990694046,
                0.041233595460653305,
                0.03678341954946518,
                -0.0245183315128088,
                0.017493339255452156,
                -0.04636530950665474,
                -0.01868995651602745,
                -0.0048158965073525906,
                0.05095960199832916,
                -0.03206160292029381,
                0.014290218241512775,
                -0.02662309817969799,
                0.031121661886572838,
                0.06030437722802162,
                -0.0014895921340212226,
                0.0011877843644469976,
                -0.03546146675944328,
                0.03518016263842583,
                -0.013333674520254135,
                -0.04421834275126457,
                -0.027034973725676537,
                0.034884560853242874,
                -0.05181179568171501,
                0.007090682629495859,
                -0.04284922406077385,
                0.03716668114066124,
                0.004779953043907881,
                0.0252571702003479,
                0.02175101637840271,
                -0.007257371675223112,
                0.03338496759533882,
                0.04887602850794792,
                0.01648314669728279,
                0.008495843037962914,
                -0.04921291396021843,
                -0.030164139345288277,
                0.04229244217276573,
                -0.028738388791680336,
                0.001856141141615808,
                0.004257614724338055,
                0.04127344861626625,
                -0.07525649666786194,
                0.03290881961584091,
                0.015446897596120834,
                -0.037953440099954605,
                -0.012888270430266857,
                -0.07076241075992584,
                -0.06429305672645569,
                -0.08336034417152405,
                -0.01356773916631937,
                -0.01987970434129238,
                0.06640367209911346,
                0.04371131211519241,
                0.0333145335316658,
                -0.024819379672408104,
                0.0185844749212265,
                -0.08132344484329224,
                -0.003824498038738966,
                -0.05613836646080017,
                0.044601161032915115,
                0.03697531297802925,
                -0.0535401776432991,
                0.04204969108104706,
                0.03049861267209053,
                0.0209062397480011,
                0.045815374702215195,
                -0.022409003227949142,
                -0.035073164850473404,
                -0.06383400410413742,
                0.041059769690036774,
                0.02416948601603508,
                -6.376474248823283e-33,
                -0.03822820633649826,
                -0.03378219157457352,
                0.0070741381496191025,
                0.005392051301896572,
                -0.047250550240278244,
                -0.027017543092370033,
                0.007289509288966656,
                0.0072182537987828255,
                -0.022256486117839813,
                -0.053134649991989136,
                -0.021989131346344948,
                -0.02981179766356945,
                0.011017710901796818,
                0.005950762890279293,
                0.01645038276910782,
                0.04499025270342827,
                -0.006717989221215248,
                0.03742861747741699,
                -0.01398173626512289,
                -0.030443215742707253,
                -0.04382023215293884,
                0.0023171748034656048,
                -0.054608386009931564,
                -0.027380412444472313,
                0.014793691225349903,
                -0.015267747454345226,
                -0.02704332210123539,
                -0.01317847054451704,
                -0.016771424561738968,
                -0.02889116480946541,
                0.02664804458618164,
                0.009785131551325321,
                -0.009311019442975521,
                -0.02789497934281826,
                -0.013925617560744286,
                0.015262046828866005,
                -0.061384811997413635,
                -0.016041861847043037,
                0.0009736363426782191,
                -0.005868865177035332,
                0.008242879062891006,
                -0.0007392132538370788,
                0.0005253450362943113,
                -0.04288217052817345,
                -0.07379038631916046,
                -0.0034471391700208187,
                0.017417531460523605,
                0.015516310930252075,
                0.05481783673167229,
                0.022088326513767242,
                -0.07745970785617828,
                -0.0034184318501502275,
                -0.053134091198444366,
                0.061858613044023514,
                0.019389940425753593,
                0.0011638033902272582,
                -0.006343894172459841,
                0.02052806317806244,
                0.003066357458010316,
                -0.009756899438798428,
                -0.03415898233652115,
                0.01191539317369461,
                0.024966992437839508,
                -0.02368537336587906,
                0.00321088801138103,
                -0.03439871594309807,
                -0.10343616455793381,
                0.0289141945540905,
                0.03423045948147774,
                -0.05981055274605751,
                -0.01814151555299759,
                0.09840096533298492,
                -0.015964480116963387,
                -0.04030851647257805,
                -0.00015745802375022322,
                -0.0809178277850151,
                -0.0028381235897541046,
                0.006730543449521065,
                0.04496027156710625,
                -0.03707493469119072,
                -0.0207260400056839,
                -0.006017381325364113,
                0.01617543213069439,
                -0.008506382815539837,
                0.028406446799635887,
                0.059644993394613266,
                -0.007890523411333561,
                -0.013668157160282135,
                -0.01905723847448826,
                -0.005328475963324308,
                -0.03298886865377426,
                0.04568713158369064,
                -0.039868466556072235,
                -0.010265464894473553,
                0.04679451882839203,
                -0.015144596807658672,
                0.01257921103388071,
                0.028380028903484344,
                0.021342255175113678,
                0.035925474017858505,
                0.0037281091790646315,
                0.010119037702679634,
                -0.06314510852098465,
                0.050071313977241516,
                0.020752960816025734,
                -0.008820466697216034,
                -0.05071868374943733,
                0.038761984556913376,
                -0.020611071959137917,
                0.02796199731528759,
                0.0215919092297554,
                -0.006886214017868042,
                -0.0033501561265438795,
                -0.019442200660705566,
                -0.03441254794597626,
                0.0004779710725415498,
                0.014302481897175312,
                -0.022486498579382896,
                -0.03904635086655617,
                -0.013398971408605576,
                0.02236242964863777,
                0.011302274651825428,
                -0.03760962933301926,
                0.01923271082341671,
                -0.026212332770228386,
                -0.03505618870258331,
                0.011767764575779438,
                0.009753272868692875,
                0.011456948705017567,
                -0.029428035020828247,
                -0.058460693806409836,
                -0.003259385237470269,
                2.2007868949458498e-07,
                0.012610702775418758,
                0.016003618016839027,
                -0.028203701600432396,
                0.09280046820640564,
                0.04943973943591118,
                0.0014341725036501884,
                -0.03467667102813721,
                0.029827900230884552,
                0.02689635194838047,
                -0.008396243676543236,
                0.026553107425570488,
                -0.049672968685626984,
                0.012552008964121342,
                -0.02201807126402855,
                0.01616537757217884,
                -0.02989755943417549,
                -0.010899500921368599,
                0.03589131310582161,
                -0.04170909523963928,
                0.022549232468008995,
                0.07213108241558075,
                0.01580561324954033,
                0.047010112553834915,
                -0.010917814448475838,
                -0.04050261154770851,
                0.017580242827534676,
                -0.03575972095131874,
                0.007728679105639458,
                0.11140720546245575,
                0.05168410763144493,
                -0.03450902923941612,
                0.05241192504763603,
                0.0533333458006382,
                0.04058460146188736,
                -0.0014017579378560185,
                -0.04690035432577133,
                0.021822098642587662,
                0.03589903935790062,
                -0.026713533326983452,
                0.04849215969443321,
                0.01677812822163105,
                -0.023137252777814865,
                0.012664192356169224,
                -0.02642795257270336,
                0.025854632258415222,
                0.018139733001589775,
                -0.016435423865914345,
                0.012771100737154484,
                -0.03387778624892235,
                -0.012709168717265129,
                -0.054794974625110626,
                0.03651030734181404,
                0.035248808562755585,
                -0.034292858093976974,
                0.0012496324488893151,
                -0.018227623775601387,
                -0.01288256049156189,
                0.03986455127596855,
                0.043795738369226456,
                0.06878612190485,
                0.011450964026153088,
                -0.019873516634106636,
                0.010705961845815182,
                -0.0015025795437395573,
                0.06723112612962723,
                0.012137386947870255,
                -0.05655151233077049,
                1.4845710753280744e-34,
                0.012424029409885406,
                -0.013681507669389248,
                0.005229124333709478,
                0.0029099425300955772,
                -0.020555784925818443,
                -0.01806318573653698,
                -0.006561564281582832,
                -0.02921733818948269,
                -0.012257624417543411,
                0.04733334854245186,
                -0.02993411011993885,
                -0.05364801734685898,
                -0.06389179825782776,
                -0.03847620636224747,
                0.009505819529294968,
                -0.04475940763950348,
                -0.02227458357810974,
                0.027283160015940666,
                0.05459301173686981,
                0.04598504304885864,
                0.06975624710321426,
                0.05891542509198189,
                -0.0773286521434784,
                0.009017828851938248,
                0.025842158123850822,
                0.020649800077080727,
                -0.015629125759005547,
                -0.005373026710003614,
                -0.006410867441445589,
                0.027659710496664047,
                -0.00974183063954115,
                -0.039534687995910645,
                -0.034369830042123795,
                -0.01917492039501667,
                0.008583315648138523,
                0.02429751306772232,
                0.012527159415185452,
                0.011928615160286427,
                -0.06040634587407112,
                0.027107341215014458,
                -0.03848716989159584,
                -0.014392958953976631,
                0.027176691219210625,
                0.005303780548274517,
                -0.02356421761214733,
                1.4074433920541196e-06,
                -0.006136023905128241,
                0.0421820729970932,
                0.0032374360598623753,
                -0.0013854403514415026,
                0.024233365431427956,
                0.0062637547962367535,
                -0.07380709052085876,
                0.019675012677907944,
                0.03227677941322327,
                -0.03653629869222641,
                -0.09414400905370712,
                0.006574609782546759,
                -0.0002538615372031927,
                0.017986919730901718,
                -0.003569069318473339,
                0.012515044771134853,
                0.04127134382724762,
                -0.0335555374622345,
                -0.04506548121571541,
                0.04759630188345909,
                -0.07480715960264206,
                -0.023603470996022224,
                -0.015412038192152977,
                0.07264698296785355,
                0.04188275337219238,
                0.006054852623492479,
                -0.03559282794594765,
                0.0063871415331959724,
                -0.012277568690478802,
                -0.1180972084403038,
                0.004789345432072878,
                -0.0020003707613795996,
                0.00928014051169157,
                -0.0050987317226827145,
                -0.0006297549116425216,
                0.004056824371218681,
                0.03404346853494644,
                0.009812084026634693,
                0.00936857145279646,
                -0.05240011587738991,
                -0.0407104529440403,
                0.0124276764690876,
                -0.01898954249918461,
                -0.017792480066418648,
                -0.029602618888020515,
                0.0008167435298673809,
                0.048801518976688385,
                -0.019088931381702423,
                -0.06981005519628525,
                -0.005571101792156696,
                0.022700348868966103,
                -0.008094200864434242,
                -0.01733747497200966,
                0.044833313673734665,
                0.05608520284295082,
                -0.00028962420765310526,
                -0.009663999080657959,
                -0.0049720038659870625,
                -0.06360886991024017,
                -0.03778303414583206,
                -0.030551226809620857,
                0.0047194091603159904,
                -0.04648882523179054,
                0.06801970303058624,
                -0.0027514116372913122,
                0.03442143648862839,
                0.00427027465775609,
                0.07273142784833908,
                0.02588494122028351,
                -0.03865969181060791,
                0.03862399607896805,
                0.04674716666340828,
                0.004460921511054039,
                -0.019395887851715088,
                0.016883740201592445,
                -0.0650218203663826,
                -0.022144200280308723,
                0.05284849926829338,
                -0.04511595889925957,
                0.0956464409828186,
                -0.004260432440787554,
                -0.012990417890250683,
                -0.07120834290981293,
                -0.015006620436906815,
                0.010649992153048515,
                -0.015603914856910706,
                -0.03034828044474125,
                0.04884924367070198,
                0.020367583259940147,
                -0.003682982176542282,
                0.041945602744817734,
                0.008217965252697468,
                0.01864912174642086,
                -0.04124026373028755,
                0.0005397814675234258,
                -0.020177653059363365,
                0.005276816431432962,
                -0.04639919102191925,
                0.05168242007493973,
                0.006964174099266529,
                0.025232668966054916,
                -0.016536997631192207,
                0.027107059955596924,
                -0.04908078536391258,
                -0.03030821867287159,
                0.05920543521642685,
                0.008801253512501717,
                0.05178098753094673,
                0.037311702966690063,
                0.025294849649071693,
                -0.018526244908571243,
                0.05025547370314598,
                0.02925274707376957,
                0.01570889726281166,
                -0.011623268947005272,
                0.004278163425624371,
                -0.01887795701622963,
                0.0584854856133461,
                0.03536951169371605,
                -0.01643359288573265,
                -0.012180475518107414,
                0.01716558262705803,
                0.005065293982625008,
                -0.005186245311051607,
                -0.035448841750621796,
                0.01039400789886713,
                -0.02980153262615204,
                0.01209382712841034,
                -0.006788351107388735,
                -0.0034109598491340876,
                0.03253835067152977,
                -0.04266762733459473,
                -0.014738562516868114,
                -0.015483306720852852,
                0.008649203926324844,
                -0.011098110117018223,
                0.021815909072756767,
                -0.00736316479742527,
                0.015082045458257198,
                0.03408383950591087,
                -0.02533290907740593,
                0.014075228944420815,
                -0.018610259518027306,
                0.006063665263354778,
                0.010540343821048737,
                0.04382948577404022,
                0.003131716977804899,
                0.03183162584900856,
                -0.006571269128471613,
                -0.043823253363370895,
                0.027741655707359314,
                -0.015180008485913277,
                -0.03806420788168907,
                -0.029123494401574135,
                -0.06024031341075897,
                -0.05545872077345848,
                0.03543554246425629,
                -0.0004177429946139455,
                0.005550092086195946,
                0.02579905278980732,
                0.03401210159063339,
                7.373196422122419e-05,
                -0.007340580690652132,
                -0.0019088301341980696,
                0.03135997802019119,
                -0.03545321896672249,
                0.00037863932084292173,
                0.026754453778266907,
                0.005767161957919598,
                -0.020403221249580383,
                -0.04658321663737297,
                0.061577215790748596,
                0.01203630119562149,
                -0.0476643443107605,
                -0.0022564209066331387,
                0.04637185484170914,
                -0.004077201709151268,
                0.004259761888533831,
                0.0076212226413190365,
                -0.014600992202758789,
                0.009046835824847221,
                0.005132419988512993,
                -0.009019050747156143,
                -0.028796084225177765,
                0.036442212760448456,
                -0.0014292995911091566,
                -0.026415366679430008,
                0.007755340542644262,
                0.026697613298892975,
                -0.03561188280582428,
                2.970642299260362e-06,
                0.054686542600393295,
                0.005591109860688448,
                -0.05985052511096001,
                -0.008777262642979622,
                0.0067327567376196384,
                -0.0418381504714489,
                0.016681037843227386,
                0.002966376254335046,
                0.0066069005988538265,
                0.023029252886772156,
                0.02037092112004757,
                0.03743242099881172,
                -0.010942772030830383,
                -0.026444505900144577,
                -0.015561549924314022,
                -0.012475196272134781,
                -0.04192904382944107,
                -0.01721564494073391,
                0.002435945440083742,
                0.043530941009521484,
                0.02564065530896187,
                -0.007319876924157143,
                -0.021163808181881905,
                0.005970143247395754,
                0.004604737274348736,
                -0.03530852869153023,
                -0.0037653190083801746,
                0.00984374899417162,
                0.014875948429107666,
                -0.035295408219099045,
                0.06805244088172913,
                0.023106243461370468,
                0.03159251809120178,
                -0.06297892332077026,
                -0.00239015300758183,
                -0.05661511793732643,
                -0.025822537019848824,
                0.004675320815294981,
                0.040519338101148605,
                -0.014025593176484108,
                0.021697891876101494,
                -0.03392812982201576,
                0.010432570241391659,
                -0.0024004774168133736,
                0.030973738059401512,
                -0.008166583254933357,
                0.02692503109574318,
                -0.007180523592978716,
                -0.03587384149432182,
                -0.020321225747466087,
                -0.02107464335858822,
                0.009802814573049545,
                -0.04968443512916565,
                0.010819279588758945,
                -0.04258272424340248,
                0.05693434178829193,
                -0.004708799533545971,
                -0.01142829842865467,
                0.027649864554405212,
                0.06563542783260345,
                0.028802653774619102,
                0.027105743065476418,
                -0.012419136241078377,
                0.04934868589043617,
                0.028936337679624557,
                -0.03946156054735184,
                0.05477624386548996,
                -0.047967329621315,
                0.03335893899202347,
                -0.0005758769693784416,
                0.005697101820260286,
                0.01425971183925867,
                0.06725377589464188,
                -0.03787601366639137,
                -0.05157734453678131,
                0.07571032643318176,
                0.04633205384016037,
                -0.004350007511675358,
                0.011222184635698795,
                0.017673714086413383,
                0.05017441138625145,
                -0.017936967313289642,
                -0.03868665173649788,
                0.027081334963440895,
                -0.030461998656392097,
                -0.01047422457486391,
                0.009391793981194496,
                0.03249596059322357,
                -0.033667951822280884,
                0.02573991008102894,
                0.013335185125470161,
                -0.019234392791986465,
                -0.014490275643765926,
                -0.029408598318696022,
                0.020741306245326996,
                0.06208440661430359,
                0.013202023692429066,
                -0.034524865448474884,
                0.006424539722502232,
                -0.02044428512454033,
                -0.035511426627635956,
                0.008698116056621075,
                0.03172491490840912,
                -0.013237815350294113,
                -0.0021653741132467985,
                0.05790206417441368,
                0.009789112955331802,
                0.06261429935693741,
                0.06638871878385544,
                0.005744157824665308,
                -0.0008972579962573946,
                -0.07090090960264206,
                -0.05598975718021393,
                0.03359929099678993,
                0.005417189095169306,
                -0.08991488814353943,
                -0.03917444869875908,
                -0.023534616455435753,
                0.011672275140881538,
                -0.005428400821983814,
                -0.011732385493814945,
                -0.016617799177765846,
                0.007722486276179552,
                -0.03379583731293678,
                0.0014449205482378602,
                0.03988898918032646,
                0.013780303299427032,
                -0.029232323169708252,
                -0.01692832075059414,
                -0.03670848533511162,
                0.04220956563949585,
                0.03180772066116333,
                0.03135157376527786,
                -0.031311046332120895,
                -0.02681734226644039,
                -0.06855226308107376,
                -0.014751129783689976,
                0.02528688870370388,
                -0.07373210787773132,
                0.0772932693362236,
                -0.03601828217506409,
                -0.009202511049807072,
                0.02026650309562683,
                0.0349418930709362,
                -0.06645064800977707,
                0.035037290304899216,
                -0.0547771155834198,
                -0.0006668812711723149,
                0.0029866741970181465,
                0.022030923515558243,
                0.0061975815333426,
                0.02672738768160343,
                -0.019692115485668182,
                0.018594913184642792,
                0.03226843848824501,
                0.026030728593468666,
                0.005114375613629818,
                0.010345835238695145,
                0.020905189216136932,
                0.0013677040114998817,
                0.008993755094707012,
                0.007555331569164991,
                -0.021156886592507362,
                0.04917050525546074,
                -0.01399831473827362,
                0.06274201720952988,
                0.047707654535770416,
                -0.04536350443959236,
                0.057233113795518875,
                -0.0254092738032341,
                0.012795336544513702,
                -0.040143486112356186,
                0.010355724953114986,
                0.059238698333501816,
                -0.01971648633480072,
                -0.0021270555444061756,
                -0.10787396132946014,
                0.05285055935382843,
                -0.01664959453046322,
                -0.0455021858215332,
                0.03056553192436695,
                -0.03183167427778244,
                -0.07644041627645493,
                -0.006866171024739742,
                0.03365372493863106,
                0.01508656982332468,
                0.01940890960395336,
                -0.06140919402241707,
                -0.02941131964325905,
                0.10327393561601639,
                -0.008557246066629887,
                0.03337562456727028,
                -0.012578470632433891,
                -0.02970128133893013,
                0.042954470962285995,
                0.03512760251760483,
                -0.06502202898263931,
                -0.010157759301364422,
                -0.028585873544216156,
                -0.020853416994214058,
                0.01724892109632492,
                0.03267417475581169,
                0.004405036102980375,
                0.01832069642841816,
                -0.013887640088796616,
                -0.038990624248981476,
                -0.0006115917349234223,
                0.020903199911117554,
                0.040695562958717346,
                -0.04956130310893059,
                -0.023808469995856285,
                0.03189728036522865,
                0.038737811148166656,
                0.026136254891753197,
                0.05928322672843933,
                0.02363651432096958,
                0.04919164627790451,
                -0.0049181305803358555,
                0.026491869240999222,
                0.021565644070506096,
                -0.0033571317326277494,
                0.005866565741598606,
                -0.03842497617006302,
                0.008669696748256683,
                0.07550197094678879,
                0.005977935623377562,
                0.027386493980884552,
                0.031548742204904556,
                0.00807094480842352,
                -0.005581446923315525,
                0.03438276797533035,
                -0.06202781945466995,
                0.004689723718911409,
                0.08728018403053284,
                0.027332626283168793,
                0.02621956169605255,
                -0.05514386668801308,
                -0.07409413158893585,
                0.0312872976064682,
                -0.014488199725747108,
                -0.07380138337612152,
                -0.07325854897499084,
                0.018388202413916588,
                -0.0400405116379261,
                -0.05584016814827919,
                -0.008804814890027046,
                -0.04940660297870636,
                0.028378991410136223,
                -0.0032934732735157013,
                -0.06867395341396332,
                0.012172987684607506,
                0.03159540519118309,
                -0.12313465029001236,
                -0.004534995649009943,
                0.07715271413326263,
                0.03488066792488098,
                0.06501499563455582,
                -0.09179230034351349,
                -0.005446301773190498,
                0.04471684247255325,
                0.02002514712512493,
                -0.03969934955239296,
                0.03243483975529671,
                0.03892122954130173,
                -0.006894603371620178,
                -0.021549075841903687,
                -0.01821158081293106,
                -0.04423767700791359,
                -0.007817248813807964,
                0.053083017468452454,
                -0.0335146002471447,
                0.002773033920675516,
                0.0689055547118187,
                -0.08382858335971832,
                0.0005503350403159857,
                -0.03238537907600403,
                0.009217065759003162,
                -0.004897082224488258,
                0.02155640348792076,
                0.0790826603770256,
                0.02358298748731613,
                0.026554085314273834,
                -0.013551403768360615,
                -0.012643205001950264,
                -0.041496530175209045,
                -0.02027122862637043,
                -0.05390239879488945,
                0.06546001136302948,
                0.004117254167795181,
                0.04955631494522095,
                -0.056736934930086136,
                -0.0002772063307929784,
                -0.014982967637479305,
                -0.02191350795328617,
                0.019593151286244392,
                -0.014416240155696869,
                -0.048584405332803726,
                -0.055576615035533905,
                0.005765155889093876,
                -0.004354735370725393,
                -0.01784372702240944,
                0.04298447445034981,
                -0.024830814450979233,
                0.007284247316420078,
                -0.04148345813155174,
                -0.029543861746788025,
                -0.004903607536107302,
                0.034342020750045776,
                -0.038546983152627945,
                0.04472072795033455,
                0.00581438560038805,
                0.06927969306707382,
                0.01723218895494938,
                0.029351120814681053,
                0.023522358387708664,
                0.013850969262421131,
                -0.015311570838093758,
                0.07574570178985596,
                0.04821281507611275,
                0.030262649059295654,
                0.009440334513783455,
                0.0451398529112339,
                -0.009851839393377304,
                -0.006494539324194193,
                -0.012003295123577118,
                0.01738937385380268,
                -0.01983555220067501,
                -0.005650970619171858,
                -0.03011002019047737,
                -5.7212933706092806e-33,
                -0.026183729991316795,
                -0.03800010308623314,
                0.03322020545601845,
                -0.03975612297654152,
                -0.00955168902873993,
                0.018744396045804024,
                -0.008162012323737144,
                0.012972261756658554,
                0.014488829299807549,
                0.028884923085570335,
                -0.023609284311532974,
                -0.032560769468545914,
                0.02870829589664936,
                -0.025865843519568443,
                0.010626987554132938,
                -0.05188071355223656,
                0.0500091128051281,
                0.00638991454616189,
                -0.03129987418651581,
                -0.060704607516527176,
                -0.05392858386039734,
                0.003407324431464076,
                -0.036075882613658905,
                0.061208393424749374,
                0.02322792075574398,
                -0.04875229671597481,
                -0.02490871399641037,
                -0.029074126854538918,
                -0.07487578690052032,
                0.0009089779923669994,
                0.015117847360670567,
                0.0026022649835795164,
                0.01882186532020569,
                -0.0222227293998003,
                0.0043336255475878716,
                0.03228266164660454,
                -0.0744493305683136,
                -0.10407181829214096,
                0.00402025505900383,
                -0.0499260313808918,
                -0.04174366220831871,
                0.10952771455049515,
                -0.005983192939311266,
                -0.02735132910311222,
                0.007632321212440729,
                -0.012367093935608864,
                0.008958123624324799,
                0.004905541427433491,
                0.047540172934532166,
                -0.057345546782016754,
                0.055656176060438156,
                -0.034386735409498215,
                0.03947468101978302,
                -0.0002981749421451241,
                0.023863576352596283,
                -0.0030623720958828926,
                0.005990344099700451,
                -0.08435607701539993,
                0.02197910286486149,
                -0.005374803673475981,
                0.0016582831740379333,
                0.015693139284849167,
                0.021269990131258965,
                -0.010947348549962044,
                0.012185339815914631,
                0.020453494042158127,
                -0.08391204476356506,
                0.02791902981698513,
                0.06809841096401215,
                0.010671908035874367,
                0.027460820972919464,
                -0.07417317479848862,
                0.02770799770951271,
                0.016536233946681023,
                -0.018342362716794014,
                -0.04649385064840317,
                -0.02626386098563671,
                -0.008829581551253796,
                0.08277150988578796,
                -0.06253810971975327,
                0.008130119182169437,
                0.0007138001383282244,
                -0.019307518377900124,
                -0.021530963480472565,
                -0.007447118870913982,
                0.02093246392905712,
                -0.004980416968464851,
                -0.00854329951107502,
                -0.02163606695830822,
                0.043920863419771194,
                0.03310345858335495,
                0.002433106768876314,
                0.01705898717045784,
                0.004562126938253641,
                0.06964720785617828,
                -0.030217738822102547,
                0.026700789108872414,
                -0.015695586800575256,
                -0.007196403574198484,
                -0.028013616800308228,
                0.01563570462167263,
                -0.011077946983277798,
                0.03894663602113724,
                0.006662870291620493,
                0.027723614126443863,
                -0.03605801612138748,
                -0.05147799476981163,
                -0.0029245284385979176,
                -0.012377854436635971,
                0.011164850555360317,
                0.01292313914746046,
                0.05307329073548317,
                0.03875027596950531,
                0.0024622566998004913,
                -0.004061507992446423,
                0.05659714713692665,
                -0.0017701289616525173,
                -0.07422348856925964,
                0.029986022040247917,
                0.004290718585252762,
                -0.027061810716986656,
                0.06861778348684311,
                0.00013403054617810994,
                0.024148473516106606,
                -0.01932499371469021,
                -0.019099362194538116,
                0.05212939530611038,
                -0.0041491370648145676,
                -0.035246964544057846,
                0.007666625082492828,
                -0.019354913383722305,
                0.07382462173700333,
                2.1602080835236848e-07,
                -0.05055471137166023,
                0.009571874514222145,
                0.012297148816287518,
                0.023781398311257362,
                -0.012727785855531693,
                -0.0002538412227295339,
                -0.06229223683476448,
                -0.00222041760571301,
                0.02402510680258274,
                -0.07259967923164368,
                -0.006720125675201416,
                -0.020985454320907593,
                -0.005469241179525852,
                0.035953421145677567,
                -0.03957642242312431,
                0.014604964293539524,
                0.06558056175708771,
                -0.04064687713980675,
                -0.05482514947652817,
                0.01605103723704815,
                0.03978471830487251,
                -0.015392336063086987,
                -0.04099816083908081,
                -0.010798567906022072,
                -0.003910457715392113,
                -0.043584294617176056,
                -0.022599538788199425,
                -0.03627552092075348,
                0.004239638801664114,
                0.04580852389335632,
                0.10554411262273788,
                0.02219845913350582,
                0.023362718522548676,
                0.12037195265293121,
                -0.019432419911026955,
                0.016212714836001396,
                -0.0028152891900390387,
                -0.03202078863978386,
                0.0031861686147749424,
                0.008029439486563206,
                -0.024884020909667015,
                0.013012489303946495,
                0.07944854348897934,
                -0.03483826294541359,
                0.0126199871301651,
                0.04691782221198082,
                0.04689890891313553,
                -0.05038520321249962,
                0.026828525587916374,
                -0.05204000696539879,
                0.04507686570286751,
                -0.012330359779298306,
                0.015681536868214607,
                0.012944068759679794,
                0.024870123714208603,
                0.03345884010195732,
                -0.0023753379937261343,
                0.04584731161594391,
                -0.019429897889494896,
                -0.011429037898778915,
                -0.009990300051867962,
                0.05495841056108475,
                0.04701488837599754,
                -0.029504869133234024,
                -0.06723609566688538,
                -0.04185717552900314,
                -0.057018864899873734,
                1.2896312936616555e-34,
                0.00011262922635069117,
                -0.017771873623132706,
                -0.025553759187459946,
                -0.044279519468545914,
                -0.00321389501914382,
                0.006806996185332537,
                -0.01954062469303608,
                0.04674826189875603,
                -0.030127905309200287,
                -0.015453686006367207,
                0.0008271784172393382,
                -0.029527368023991585,
                0.05739052966237068,
                -0.023966465145349503,
                0.01256326213479042,
                0.013684618286788464,
                0.05302112177014351,
                -0.057394713163375854,
                0.028428995981812477,
                0.05225375294685364,
                -0.0005840750527568161,
                0.057523902505636215,
                -0.017660310491919518,
                0.023585116490721703,
                0.15850377082824707,
                0.0004585640854202211,
                -0.04462476447224617,
                0.04079762473702431,
                0.03857190161943436,
                -0.01155600231140852,
                -0.010819924995303154,
                -0.019222306087613106,
                0.026810135692358017,
                0.02502896636724472,
                -0.03205517679452896,
                -0.030210716649889946,
                0.00776688102632761,
                0.025391314178705215,
                0.02724459581077099,
                -0.045536573976278305,
                -0.024720173329114914,
                0.0017889600712805986,
                -0.0427054800093174,
                0.014860847033560276,
                0.014846086502075195,
                2.172366521335789e-06,
                0.009523401968181133,
                -0.027266409248113632,
                -0.013221090659499168,
                -0.042181286960840225,
                0.03073164075613022,
                0.054658014327287674,
                -0.10024955123662949,
                -0.0006611121352761984,
                0.004136877134442329,
                -0.01800101064145565,
                -0.03749333694577217,
                0.024345962330698967,
                0.04354747757315636,
                0.0028031407855451107,
                0.03604980185627937,
                0.01208706758916378,
                -0.04288105294108391,
                -0.005932781845331192,
                -0.02539035491645336,
                0.01802355982363224,
                0.028990019112825394,
                0.0025024679489433765,
                0.03621853515505791,
                -0.07146119326353073,
                0.03557678684592247,
                0.010354812256991863,
                0.02642902173101902,
                0.015046215616166592,
                0.028448523953557014,
                0.041509442031383514,
                -0.004043779335916042,
                0.04247599467635155,
                -0.06135094165802002,
                0.01582074910402298,
                -0.030294813215732574,
                -0.0006225354736670852,
                -0.005349012091755867,
                -0.03921183943748474,
                0.05460864678025246,
                -0.03701908513903618,
                -0.012234628200531006,
                0.006647261790931225,
                -0.001359343877993524,
                -0.02020692639052868,
                0.019347675144672394,
                -0.02252107858657837,
                -0.017697937786579132,
                0.0062710074707865715,
                0.0013027669629082084,
                -0.034087665379047394,
                0.017011113464832306,
                0.021562209352850914,
                -0.006362261716276407,
                -0.027984824031591415,
                0.04867330566048622,
                0.04450685530900955,
                -0.025161776691675186,
                0.0293867327272892,
                -0.006580322980880737,
                -0.02358224429190159,
                0.008292586542665958,
                -0.0019364847103133798,
                -0.07798611372709274,
                -0.02638179063796997,
                -0.039088666439056396,
                -0.04889741167426109,
                0.018942242488265038,
                0.014221842400729656,
                -0.017921971157193184,
                0.04332417622208595,
                -0.09290055930614471,
                0.00739638227969408,
                -0.022431116551160812,
                -0.03854227438569069,
                0.09267444908618927,
                -0.033692244440317154,
                -0.0277921874076128,
                0.012193740345537663,
                0.06762285530567169,
                -0.021244656294584274,
                -0.029819201678037643,
                0.015957852825522423,
                0.03123636730015278,
                0.009870950132608414,
                0.0153911542147398,
                0.01169345248490572,
                0.009635758586227894,
                -0.021100366488099098,
                0.023800890892744064,
                0.013237192295491695,
                0.0466633215546608,
                -0.029835183173418045,
                0.0009062362951226532,
                0.03145873174071312,
                -0.05415854603052139,
                -0.021751563996076584,
                -0.03945915773510933,
                -0.009762411937117577,
                0.00919167511165142,
                0.00036238261964172125,
                0.027944469824433327,
                0.012178260833024979,
                -0.053314413875341415,
                -0.06408678740262985,
                -0.023971058428287506,
                0.011549766175448895,
                -0.02345520444214344,
                -0.05386772379279137,
                0.014036750420928001,
                -0.00035013799788430333,
                -0.02539830282330513,
                0.03237244486808777,
                -0.006823526229709387,
                -0.02814326249063015,
                -0.022049440070986748,
                0.014857184141874313,
                0.037160348147153854,
                -0.015620863065123558,
                -0.010471533052623272,
                0.06895942986011505,
                0.002824551658704877,
                0.04392354190349579,
                -0.015730464830994606,
                0.015865545719861984,
                0.01193979475647211,
                -0.008645092137157917,
                0.009595951996743679,
                -0.0718306228518486,
                0.007606202736496925,
                0.021516047418117523,
                0.022734826430678368,
                -0.003072961000725627,
                -0.038128580898046494,
                -0.046915698796510696,
                -0.003943630959838629,
                0.03256183862686157,
                0.033266372978687286,
                -0.011948985047638416,
                0.011696937493979931,
                0.1087944284081459,
                0.05839615315198898,
                0.06444549560546875,
                -0.05799969285726547,
                0.007714776322245598,
                0.04099920392036438,
                -0.016171647235751152,
                -0.04040050134062767,
                0.004801423754543066,
                0.029817892238497734,
                0.01035283412784338,
                0.027034591883420944,
                0.04552983120083809,
                0.013001106679439545,
                -0.024373749271035194,
                0.001419023610651493,
                0.01736944541335106,
                -0.029287101700901985,
                0.013027641922235489,
                0.06548532098531723,
                0.01058521680533886,
                0.014985830523073673,
                0.02142871730029583,
                -0.024759937077760696,
                0.07710245251655579,
                -0.004278984852135181,
                0.04561936482787132,
                -0.01581108756363392,
                0.08635754883289337,
                -0.007524019572883844,
                0.019789427518844604,
                0.04406367242336273,
                -0.024672245606780052,
                -0.01201180461794138,
                0.017765995115041733,
                0.015443861484527588,
                0.06485885381698608,
                -0.001665963907726109,
                -0.0417637825012207,
                0.02930462919175625,
                0.027571547776460648,
                0.03224540501832962,
                0.039650674909353256,
                -0.0012379869585856795,
                -0.06339126080274582,
                0.0802731066942215,
                -0.06195974722504616,
                0.03939415141940117,
                -0.05588364228606224,
                0.027758706361055374,
                -0.012509603053331375,
                -0.013132307678461075,
                -0.0298509132117033,
                0.009223343804478645,
                0.0025272697675973177,
                0.022483056411147118,
                0.0009452503290958703,
                0.057509731501340866,
                0.04027285799384117,
                0.0177651047706604,
                0.036740742623806,
                0.015929562970995903,
                0.06326311081647873,
                -0.045073073357343674,
                0.01753602735698223,
                -0.023913549259305,
                -0.017949433997273445,
                0.04767470061779022,
                0.013496366329491138,
                0.0004835685540456325,
                0.04872022196650505,
                -0.030066991224884987,
                0.08574099093675613,
                -0.0038377305027097464,
                -0.030224788933992386,
                -0.010967622511088848,
                -0.017939185723662376,
                -0.0006606100359931588,
                0.018932221457362175,
                0.007435498293489218,
                0.029611879959702492,
                -0.08996500074863434,
                -0.03243434429168701,
                -0.001351432059891522,
                -0.054208192974328995,
                0.05131744220852852,
                0.021319953724741936,
                0.013578856363892555,
                -0.02441573515534401,
                -0.00984610803425312,
                0.02507432922720909,
                -0.04435162991285324,
                0.031899936497211456,
                0.08535219728946686,
                0.013290145434439182,
                -0.010448896326124668,
                0.022235075011849403,
                0.025017481297254562,
                0.037953320890665054,
                0.005973127670586109,
                0.01811152882874012,
                -0.017099548131227493,
                0.028119772672653198,
                -0.02783939801156521,
                -0.0008419489022344351,
                -0.03549565374851227,
                -0.0014502897392958403,
                0.02408801019191742,
                0.001447578426450491,
                0.02247965708374977,
                0.009846106171607971,
                0.0098562715575099,
                -0.015033441595733166,
                -0.04323645681142807,
                -0.026322543621063232,
                -0.1253862977027893,
                -0.005387783981859684,
                -0.021175242960453033,
                0.0046849302016198635,
                0.00899091549217701,
                0.023347631096839905,
                -0.009593456983566284,
                -0.09083162993192673,
                0.03628771752119064,
                0.06948600709438324,
                0.00956054963171482,
                -0.047498028725385666,
                0.010210549458861351,
                -0.005719004198908806,
                -0.014554986730217934,
                -0.05176446586847305,
                0.00775116914883256,
                -0.027767125517129898,
                -0.016229676082730293,
                0.05171004310250282,
                0.0022995455656200647,
                0.046174705028533936,
                -0.008933602832257748,
                -0.0025321943685412407,
                0.06927429884672165,
                -0.015292954631149769,
                -0.00650061946362257,
                -0.07932388782501221,
                0.020279260352253914,
                0.016487274318933487,
                -0.006964501459151506,
                0.024289118126034737,
                -0.036399051547050476,
                0.03373376280069351,
                0.009327360428869724,
                0.019008871167898178,
                0.02279476262629032,
                -0.03998182713985443,
                -0.04825013130903244,
                -0.008366364054381847,
                -0.01942984014749527,
                0.03677985817193985,
                0.06753984093666077,
                -0.025541188195347786,
                -0.0183249544352293,
                0.036481183022260666,
                0.01588824763894081,
                -0.001503759529441595,
                -0.039601925760507584,
                0.0516265332698822,
                0.007055879104882479,
                0.00755590945482254,
                -0.006819021888077259,
                0.0038833513390272856,
                -0.027866985648870468,
                -0.04108429327607155,
                -0.0387207493185997,
                0.0630728155374527,
                -0.0407475121319294,
                -0.07900648564100266,
                0.005507519468665123,
                -0.010021783411502838,
                -0.0617569237947464,
                -0.004637292120605707,
                -0.04396844655275345,
                0.02591979317367077,
                0.048391006886959076,
                -0.03216448798775673,
                -0.022298311814665794,
                -0.028105419129133224,
                -0.050917185842990875,
                -0.02008185349404812,
                0.004154536407440901,
                -0.02710321731865406,
                0.044687263667583466,
                -0.03237447142601013,
                0.0808408111333847,
                0.05692795291543007,
                -0.012440405786037445,
                0.004912057891488075,
                -0.02286013588309288,
                0.04807279631495476,
                0.050168368965387344,
                -0.0021415320225059986,
                0.05258357524871826,
                0.005219120066612959,
                -0.08114553987979889,
                0.01867312751710415,
                0.005745949689298868,
                -0.007509448099881411,
                -0.0395880788564682,
                0.04368910193443298,
                -0.011592789553105831,
                -0.003591905115172267,
                0.002591164084151387,
                0.04072568565607071,
                -0.006530319340527058,
                -0.03115512616932392,
                -0.032463189214468,
                -0.036466777324676514,
                -0.0027250805869698524,
                0.03643690049648285,
                -0.041608382016420364,
                -0.06208593025803566,
                -0.05488882586359978,
                0.012354821898043156,
                -0.012446856126189232,
                -0.0303189717233181,
                0.02051764354109764,
                -0.01647217385470867,
                0.011118413880467415,
                0.016311079263687134,
                0.04297149181365967,
                -0.004401207435876131,
                -0.03433562070131302,
                -0.07991621643304825,
                0.058316074311733246,
                0.025217732414603233,
                -0.01447086688131094,
                0.057460103183984756,
                0.004793422296643257,
                -0.06315821409225464,
                -0.023668430745601654,
                -0.015490666031837463,
                -0.01569146104156971,
                0.033782608807086945,
                -0.06711343675851822,
                0.007973971776664257,
                -0.02027883008122444,
                0.033865172415971756,
                -0.01833113469183445,
                0.035828787833452225,
                -0.01963067427277565,
                -0.04760965332388878,
                0.03498055040836334,
                -0.02780289389193058,
                -0.004509509075433016,
                0.03745386004447937,
                0.06862073391675949,
                0.04581109806895256,
                0.02220330573618412,
                0.018276648595929146,
                0.021701505407691002,
                -0.010434991680085659,
                -0.013351796194911003,
                -0.028159571811556816,
                -0.06432362645864487,
                0.049388401210308075,
                0.01374565064907074,
                -0.08246003091335297,
                -0.049920231103897095,
                0.0011671768734231591,
                -0.006017191335558891,
                0.014808711595833302,
                0.011799831874668598,
                0.006816910579800606,
                0.11458343267440796,
                -0.0009722081013023853,
                0.025366919115185738,
                -0.028315173462033272,
                0.0019451716216281056,
                -0.03004772961139679,
                -0.00029292466933839023,
                -0.025239937007427216,
                -0.038942981511354446,
                -0.03480326756834984,
                0.028043925762176514,
                0.0198360662907362,
                -0.10653122514486313,
                0.019381068646907806,
                -0.009395384229719639,
                0.00795729923993349,
                0.02951631136238575,
                0.04429744929075241,
                -0.00803567748516798,
                0.03563859313726425,
                -0.024432871490716934,
                0.044156067073345184,
                -0.039216067641973495,
                0.013656307011842728,
                -0.03264507278800011,
                -0.051384635269641876,
                0.01486358791589737,
                0.02139119803905487,
                0.034465886652469635,
                0.05326353386044502,
                -0.009380864910781384,
                -0.09014023095369339,
                0.06876462697982788,
                -0.027032041922211647,
                -0.0026069681625813246,
                -0.052759211510419846,
                -0.025774920359253883,
                -0.013037829659879208,
                -0.11061274260282516,
                0.0011066491715610027,
                -0.024597790092229843,
                -0.023505810648202896,
                -0.002167387865483761,
                -0.023478705435991287,
                0.005039272364228964,
                0.014965292066335678,
                0.005778177175670862,
                -0.01080259494483471,
                -0.035129062831401825,
                -0.035662151873111725,
                0.02687237411737442,
                -0.06624586880207062,
                -0.07580471783876419,
                0.010449068620800972,
                -0.0780414417386055,
                -0.01011531613767147,
                0.009410714730620384,
                -0.012904493138194084,
                -0.03053867071866989,
                -0.016746696084737778,
                -0.0023853706661611795,
                -0.031008418649435043,
                -0.014208879321813583,
                0.01600326970219612,
                0.014727167785167694,
                0.045722804963588715,
                0.017089810222387314,
                -0.052158575505018234,
                0.011580625548958778,
                0.009096073918044567,
                -0.022244472056627274,
                0.011007861234247684,
                -0.07423339039087296,
                0.01213029958307743,
                -0.004605911672115326,
                0.028574664145708084,
                -0.005087883677333593,
                0.0051190489903092384,
                -0.004191906191408634,
                0.021179422736167908,
                -0.032305121421813965,
                0.027139823883771896,
                -0.017772257328033447,
                0.004317383747547865,
                0.019937891513109207,
                -0.0975988507270813,
                -0.005690015386790037,
                0.045267194509506226,
                -0.02258707582950592,
                -0.01853995770215988,
                0.05499710515141487,
                0.006829577498137951,
                0.015275663696229458,
                -0.04563659429550171,
                0.004438348580151796,
                -0.004855255130678415,
                -0.02064366266131401,
                0.01910286396741867,
                -0.030498528853058815,
                0.01441146433353424,
                0.05735452473163605,
                -0.005709107965230942,
                -0.0034457927104085684,
                0.022324856370687485,
                -0.0022941387724131346,
                0.010697056539356709,
                0.07161534577608109,
                -0.1071469634771347,
                -0.01464806217700243,
                -0.005822589620947838,
                -8.126890397005274e-33,
                -0.004601360764354467,
                -0.020111873745918274,
                -0.006500702351331711,
                0.0224139541387558,
                -0.05895956605672836,
                0.01276357937604189,
                -0.0025669033639132977,
                -0.03517888858914375,
                -0.020707689225673676,
                -0.019132906571030617,
                -0.02715415135025978,
                -0.010038485750555992,
                0.03415994346141815,
                0.029205473139882088,
                0.04084913432598114,
                -0.013965035788714886,
                0.03261443227529526,
                0.0012755130883306265,
                -0.04718120023608208,
                -0.02539793588221073,
                -0.01601376198232174,
                0.02128283865749836,
                -0.01985081285238266,
                0.10536957532167435,
                0.013828055933117867,
                -0.041911400854587555,
                0.0031384758185595274,
                0.022142894566059113,
                0.009806288406252861,
                -0.05250019580125809,
                -0.023867053911089897,
                0.028074759989976883,
                -0.024996992200613022,
                -0.031116917729377747,
                -0.02040201611816883,
                0.0978768914937973,
                -0.03709126263856888,
                -0.0386350043118,
                -0.024342641234397888,
                -0.019208470359444618,
                -0.040965184569358826,
                0.0186164453625679,
                0.04068104922771454,
                0.0030500805005431175,
                -0.03645242750644684,
                0.03261854127049446,
                -0.004518568515777588,
                -0.02174539491534233,
                -0.013565839268267155,
                0.07805048674345016,
                -0.020063940435647964,
                0.027545299381017685,
                -0.005458667408674955,
                -0.061386238783597946,
                -0.021334026008844376,
                0.04404919221997261,
                0.016698278486728668,
                0.010584595613181591,
                0.008593494072556496,
                0.012945831753313541,
                -0.010788876563310623,
                0.015587273053824902,
                -0.024206282570958138,
                -0.028471289202570915,
                -0.005771828815340996,
                0.030081769451498985,
                -0.01999959908425808,
                0.029463669285178185,
                -0.03257650509476662,
                0.08675216883420944,
                -0.0037744345609098673,
                -0.011380920186638832,
                0.021505506709218025,
                -0.06317731738090515,
                -0.033759113401174545,
                -0.06167582795023918,
                0.013424460776150227,
                0.027850423008203506,
                0.04383043199777603,
                0.05219944939017296,
                -0.005137472413480282,
                0.00895830150693655,
                0.03371264785528183,
                0.005016505252569914,
                0.003279075026512146,
                -0.02427404560148716,
                0.01833936758339405,
                -0.052974883466959,
                -0.021507184952497482,
                0.03694896027445793,
                0.011859518475830555,
                0.08194539695978165,
                -0.012054933235049248,
                0.03178069740533829,
                0.07671796530485153,
                -0.02827225811779499,
                0.007906457409262657,
                -0.0009538274607621133,
                -0.03181761875748634,
                -0.010702542029321194,
                0.041802216321229935,
                -0.008369852788746357,
                -0.0071350825019180775,
                0.05628040060400963,
                -0.027653388679027557,
                -0.0012944865738973022,
                0.045277632772922516,
                0.008118233643472195,
                0.008841196075081825,
                -0.044795017689466476,
                0.0027064194437116385,
                0.014370257966220379,
                0.05035528913140297,
                0.04311462864279747,
                0.057628195732831955,
                0.02925148233771324,
                -0.010872908867895603,
                -0.016496529802680016,
                -0.000744073826353997,
                0.00867095310240984,
                0.03777805343270302,
                0.12199375778436661,
                -0.008211199194192886,
                -0.019804341718554497,
                -0.01015798095613718,
                -0.006754965987056494,
                -0.05177505314350128,
                0.06762044131755829,
                -0.025352921336889267,
                -0.051316700875759125,
                -0.029806653037667274,
                -0.054087575525045395,
                2.811302124428039e-07,
                -0.02013288252055645,
                0.01976696588099003,
                0.0160893015563488,
                0.04974962770938873,
                0.03491056710481644,
                0.004237576387822628,
                -0.004771603737026453,
                0.044679854065179825,
                -0.056734777987003326,
                -0.009847849607467651,
                -0.016631124541163445,
                -0.007425670977681875,
                0.027868419885635376,
                0.019321832805871964,
                0.004733813926577568,
                -0.12423128634691238,
                -0.06327012926340103,
                0.013033958151936531,
                0.026790449395775795,
                -0.03027479723095894,
                0.012146410532295704,
                0.029361752793192863,
                0.0012743886327371001,
                -0.007561379112303257,
                0.04494575411081314,
                -0.02558961696922779,
                -0.00959088560193777,
                -0.015046664513647556,
                -0.007629679515957832,
                -0.03528420254588127,
                -0.01856730505824089,
                0.03661961853504181,
                -0.02238415740430355,
                0.0022219487000256777,
                -0.05389232188463211,
                -0.037820298224687576,
                -0.028934281319379807,
                -0.029000457376241684,
                0.010380455292761326,
                0.03385523334145546,
                -0.06541828066110611,
                0.04022728651762009,
                0.0008750860579311848,
                -0.028020065277814865,
                0.03589886054396629,
                -0.022272704169154167,
                -0.03201103210449219,
                -0.005248230881989002,
                0.012480027973651886,
                -0.01121979858726263,
                -0.03943131864070892,
                0.0022298379335552454,
                0.0008541169227100909,
                0.06232021749019623,
                -0.018836405128240585,
                0.005993539467453957,
                0.022988280281424522,
                -0.026601076126098633,
                0.031966280192136765,
                -0.04010852426290512,
                -0.03440750017762184,
                -0.03634404018521309,
                -0.003947970923036337,
                0.015347848646342754,
                0.007596063893288374,
                0.022151922807097435,
                -0.0053785680793225765,
                1.7937127734473931e-34,
                0.03009670227766037,
                0.02289004810154438,
                0.03376992419362068,
                0.02569674886763096,
                -0.06526593863964081,
                -0.024786077439785004,
                -0.013511518947780132,
                -0.033837221562862396,
                0.025140782818198204,
                -0.05182593688368797,
                -0.02184939570724964,
                0.018381668254733086,
                0.04342448338866234,
                -0.02211470901966095,
                0.0002537981199566275,
                -0.028149791061878204,
                -0.03528537228703499,
                -0.02143491618335247,
                -0.03770109638571739,
                -0.07121681421995163,
                0.014904573559761047,
                0.07827585935592651,
                0.008876209147274494,
                -0.011785712093114853,
                0.0785800889134407,
                -0.02495887316763401,
                -0.06208249181509018,
                -0.0239462498575449,
                -0.01172337681055069,
                -0.045668452978134155,
                -0.0007684384472668171,
                -0.013999239541590214,
                -0.009838398545980453,
                -0.022307055070996284,
                0.007326734717935324,
                -0.05682133510708809,
                0.01295365858823061,
                -0.08935309201478958,
                0.04353606328368187,
                1.2307614269957412e-05,
                -0.04255374148488045,
                0.0035187460016459227,
                0.041875798255205154,
                -0.029868463054299355,
                -0.023357100784778595,
                1.4369124983204529e-06,
                0.019013626500964165,
                -0.03617720305919647,
                -0.04301628842949867,
                -0.0036938649136573076,
                0.036795880645513535,
                -0.0021527966018766165,
                -0.034781280905008316,
                0.02756001241505146,
                0.011234987527132034,
                -0.01468648575246334,
                0.03969518095254898,
                0.013459809124469757,
                0.02452702634036541,
                -0.023582858964800835,
                -0.04860484600067139,
                0.008050497621297836,
                -0.04432109370827675,
                -0.07109643518924713,
                -0.03259364515542984,
                -0.03783269226551056,
                -0.061570607125759125,
                0.004137007053941488,
                -0.013402892276644707,
                0.07315381616353989,
                0.04108189046382904,
                0.00936615839600563,
                0.00847452413290739,
                0.011047097854316235,
                0.010304943658411503,
                0.06980829685926437,
                -0.023429693654179573,
                0.0070030223578214645,
                -0.03207855299115181,
                -0.014616346918046474,
                -0.0008015964995138347,
                0.03526180237531662,
                0.045216891914606094,
                0.01304188184440136,
                -0.011576447635889053,
                -0.042222585529088974,
                -0.05912785604596138,
                -0.028323939070105553,
                -0.01912451907992363,
                -0.014641087502241135,
                -0.02517743408679962,
                -0.025657497346401215,
                -0.02679637260735035,
                -0.032745007425546646,
                0.0023847799748182297,
                0.00731880497187376,
                0.08119990676641464,
                -0.021583251655101776,
                -0.004128229804337025,
                -0.014449569396674633,
                0.05153907090425491,
                0.01366428192704916,
                -0.053345486521720886,
                0.0595361702144146,
                0.0398227721452713,
                -0.06719454377889633,
                -0.001547417021356523,
                0.04524994269013405,
                0.023000221699476242,
                0.01536513864994049,
                0.0164086502045393,
                0.012478865683078766,
                0.019286878407001495,
                0.018400276079773903,
                0.04140026122331619,
                0.036578770726919174,
                0.049046315252780914,
                -0.045944664627313614,
                -0.03168604522943497,
                -0.043886952102184296,
                0.051064953207969666,
                -0.03431925177574158,
                0.014559958130121231,
                -0.04901973158121109,
                0.041744962334632874,
                -0.07681068778038025,
                0.005059470888227224,
                -0.038472309708595276,
                0.01987254060804844,
                0.03361697122454643,
                0.028311144560575485,
                0.05047903209924698,
                0.022452330216765404,
                -0.006431478541344404,
                -0.010791643522679806,
                -0.043553225696086884,
                -0.0347747839987278,
                -0.08254142850637436,
                0.01580962911248207,
                -0.04723503813147545,
                0.03107295371592045,
                0.022013243287801743,
                -0.015478494577109814,
                0.01507567334920168,
                0.002658535959199071,
                -0.01571653038263321,
                0.07056169956922531,
                0.008661343716084957,
                -0.001183400279842317,
                -0.03572479635477066,
                -0.02633517235517502,
                0.013008923269808292,
                -0.007416379172354937,
                -0.026858339086174965,
                -0.019039155915379524,
                -0.053856849670410156,
                -0.019512338563799858,
                -0.05737156793475151,
                0.07906483113765717,
                0.024721192196011543,
                -0.00455087935552001,
                -0.017373278737068176,
                -0.006985718384385109,
                0.02213764376938343,
                0.021254317834973335,
                0.0379166454076767,
                0.05226261168718338,
                -0.021601950749754906,
                0.033733513206243515,
                -0.0054010068997740746,
                0.07998247444629669,
                -0.03115374781191349,
                0.049386922270059586,
                0.032090745866298676,
                0.021933268755674362,
                -0.04458864405751228,
                -0.01995125040411949,
                0.07166165113449097,
                0.014137308113276958,
                0.005046493839472532,
                -0.009776072576642036,
                -0.10594573616981506,
                0.02753603830933571,
                0.01231884490698576,
                0.07688787579536438,
                0.028871934860944748,
                -0.031878866255283356,
                0.07214327901601791,
                -0.012451691552996635,
                -0.03544757887721062,
                0.05129478871822357,
                -0.020855534821748734,
                -0.09225448966026306,
                0.016116827726364136,
                0.08798322081565857,
                0.0279056616127491,
                -0.016856973990797997,
                -0.04768780618906021,
                0.03098401241004467,
                -0.0290197916328907,
                0.01688307709991932,
                -0.0037592705339193344,
                -0.01565156877040863,
                -0.06345005333423615,
                -0.016168484464287758,
                0.012219676747918129,
                0.0022585063707083464,
                -0.01674206182360649,
                -0.03148508816957474,
                -0.027053192257881165,
                0.009654225781559944,
                0.01036893855780363,
                0.03604720160365105,
                0.016900403425097466,
                0.008141322061419487,
                -0.004808994475752115,
                -0.05328560248017311,
                -0.04792408272624016,
                0.014484643936157227,
                -0.04578588157892227,
                -0.012879825197160244,
                0.05093352124094963,
                -0.031228890642523766,
                0.03563545644283295,
                0.020626960322260857,
                -0.0374600887298584,
                0.004298259504139423,
                -0.005996564403176308,
                -0.013273987919092178,
                -0.059240322560071945,
                -0.007461812812834978,
                -0.03576963022351265,
                0.041505180299282074,
                0.024989254772663116,
                0.04313226789236069,
                -0.00042551246588118374,
                -0.020190706476569176,
                0.026194442063570023,
                0.008442944847047329,
                0.06793728470802307,
                0.04043760523200035,
                0.003044100245460868,
                0.016256170347332954,
                0.03098052181303501,
                -0.03358624875545502,
                0.006980253849178553,
                -0.01513474527746439,
                0.07201291620731354,
                0.023013725876808167,
                -0.008072043769061565,
                -0.053983818739652634,
                0.012192582711577415,
                -0.06001860648393631,
                -0.01901552453637123,
                -0.018501562997698784,
                0.04456888511776924,
                0.018106114119291306,
                0.00743673462420702,
                0.04507134109735489,
                0.03825458511710167,
                -0.04113221913576126,
                -0.06699598580598831,
                -0.05265532061457634,
                0.02685425989329815,
                0.02951463684439659,
                0.026391131803393364,
                0.06401395052671432,
                -0.04869027063250542,
                0.04660070687532425,
                -0.03446199372410774,
                -0.021871043369174004,
                0.044725142419338226,
                0.0076128472574055195,
                -0.03447727486491203,
                -0.028465643525123596,
                0.04743253439664841,
                -0.013559823855757713,
                -0.011470346711575985,
                -0.0752042755484581,
                -0.05738311633467674,
                -0.0032957205548882484,
                -0.00662927096709609,
                -0.009384936653077602,
                0.014308488927781582,
                -0.00736579392105341,
                -0.061073850840330124,
                0.029809610918164253,
                0.020504120737314224,
                -0.04711781442165375,
                -0.04027309641242027,
                0.004756253678351641,
                0.05716371163725853,
                -0.006153677124530077,
                -0.05027958005666733,
                0.018229225650429726,
                0.01189452689141035,
                -0.022988449782133102,
                0.05789998173713684,
                -0.08194150030612946,
                -0.0432591438293457,
                0.05398405343294144,
                -0.022348619997501373,
                -0.04621214047074318,
                0.021480850875377655,
                -0.014655496925115585,
                -0.008688520640134811,
                0.014122555032372475,
                0.041706692427396774,
                -0.04078848659992218,
                -0.04124169424176216,
                -0.015564821660518646,
                0.03241752088069916,
                -0.055023472756147385,
                -0.007311966735869646,
                -0.012447609566152096,
                -0.015268152579665184,
                0.04017074033617973,
                0.05627485364675522,
                0.005186060443520546,
                0.021001296117901802,
                0.024798540398478508,
                -0.012603207491338253,
                0.026945026591420174,
                -0.016704389825463295,
                0.02431425265967846,
                -0.004533759318292141,
                0.005060424096882343,
                -0.0013363620964810252,
                -0.012731998227536678,
                0.030046530067920685,
                0.10519442707300186,
                -0.01724623702466488,
                -0.016490453854203224,
                -0.00471844058483839,
                -0.0008720833575353026,
                -0.003979124128818512,
                -0.0023380008060485125,
                -0.006422265898436308,
                -0.027971042320132256,
                0.02359972707927227,
                0.002059894148260355,
                -0.003135612001642585,
                -0.059621647000312805,
                -0.003386831609532237,
                -0.04708174616098404,
                0.006041050888597965,
                -0.00781407579779625,
                0.022622184827923775,
                -0.023050934076309204,
                -0.036088019609451294,
                -0.0069342972710728645,
                -0.0222203079611063,
                -0.05292963236570358,
                -0.07388070225715637,
                0.008084242232143879,
                -0.0032663382589817047,
                0.022661756724119186,
                0.05938908830285072,
                0.036640554666519165,
                0.005769689101725817,
                0.03140047565102577,
                0.040667835623025894,
                0.020386068150401115,
                -0.026816992089152336,
                0.009285848587751389,
                0.014884321950376034,
                0.0049662720412015915,
                -0.00959024578332901,
                0.020787635818123817,
                -0.00047966212150640786,
                0.048997968435287476,
                -0.052171554416418076,
                -0.018547020852565765,
                0.04755616933107376,
                0.03236890956759453,
                -0.02006680704653263,
                0.0019144825637340546,
                0.0411495678126812,
                0.02185119315981865,
                0.001079495414160192,
                0.04211867228150368,
                0.008876983076334,
                0.06062145531177521,
                0.011522388085722923,
                -0.016338547691702843,
                0.036765292286872864,
                -0.013799235224723816,
                0.010516935959458351,
                -0.0059020849876105785,
                -0.028935691341757774,
                0.005395206622779369,
                0.023256974294781685,
                0.000181438255822286,
                0.04596683755517006,
                0.005332972388714552,
                -0.0029211558867245913,
                -0.018442373722791672,
                -0.04394511133432388,
                -0.04216846823692322,
                0.021886341273784637,
                0.034599144011735916,
                -0.03377087041735649,
                -0.0010448761750012636,
                0.0058629210107028484,
                -0.011810259893536568,
                0.023202411830425262,
                -0.04670218378305435,
                -0.09506303817033768,
                -0.00118580786511302,
                0.02354545332491398,
                0.0004384397470857948,
                0.0050393943674862385,
                -0.055773161351680756,
                0.011171308346092701,
                -0.025227850303053856,
                0.011662562377750874,
                -0.04809308424592018,
                0.06822659820318222,
                0.03390297293663025,
                0.022708872333168983,
                0.033623527735471725,
                -0.005142939276993275,
                0.0395515002310276,
                0.016879718750715256,
                -0.04009220376610756,
                0.012915986590087414,
                0.04107381030917168,
                -0.02863655425608158,
                -0.007769130636006594,
                0.0022475363221019506,
                -0.044304050505161285,
                -0.017063964158296585,
                0.07531170547008514,
                0.021332761272788048,
                -0.04398735240101814,
                0.08693947643041611,
                -0.018194254487752914,
                0.03630317375063896,
                0.06584073603153229,
                -0.026286110281944275,
                -0.03476852551102638,
                -0.03224775195121765,
                -0.008796109817922115,
                -0.010858225636184216,
                0.03873565420508385,
                -0.024487711489200592,
                0.039673514664173126,
                0.01287868246436119,
                -0.03213614597916603,
                0.1047421246767044,
                -0.0062060668133199215,
                0.02228614315390587,
                0.08156295120716095,
                0.06054959446191788,
                0.03636985644698143,
                -0.011603445746004581,
                0.017638351768255234,
                0.0297636017203331,
                0.0022260951809585094,
                -0.06320618838071823,
                0.08099260181188583,
                0.03874879330396652,
                0.008173505775630474,
                -0.020939527079463005,
                -0.021600160747766495,
                -0.050490718334913254,
                0.02538876049220562,
                -0.009930039756000042,
                -0.042460180819034576,
                -0.02612229809165001,
                0.020319607108831406,
                0.050308145582675934,
                0.028678467497229576,
                -0.08005814999341965,
                0.0047905342653393745,
                -0.014343569986522198,
                -0.00952561292797327,
                -0.07032813131809235,
                0.031121941283345222,
                -0.0312684066593647,
                -0.04321209341287613,
                0.0790991559624672,
                0.0005559456767514348,
                -0.008024114184081554,
                0.0202309712767601,
                0.013628985732793808,
                0.024809718132019043,
                0.04862258955836296,
                -0.06140967085957527,
                5.116613124300784e-07,
                -0.013810032978653908,
                0.05833302438259125,
                0.04303446412086487,
                -0.0014689478557556868,
                -0.022397534921765327,
                -0.027230240404605865,
                0.01278624776750803,
                0.0070427036844193935,
                -0.005921420641243458,
                -0.02069525606930256,
                0.014806880615651608,
                -0.009315183386206627,
                -0.024082042276859283,
                0.029527178034186363,
                0.002971985610201955,
                -0.0153696583583951,
                -0.01158110424876213,
                0.04754077270627022,
                0.006252162158489227,
                0.06512556970119476,
                -0.04843587800860405,
                0.0408853217959404,
                0.03945317491889,
                0.0024161115288734436,
                -0.04167003929615021,
                -0.0003716901410371065,
                0.023042138665914536,
                0.0355600081384182,
                -0.00348807405680418,
                -0.02571670524775982,
                -0.0017987793544307351,
                -0.01783517748117447,
                -0.05015236511826515,
                0.03273063153028488,
                -0.11448771506547928,
                0.03204658627510071,
                0.02465820126235485,
                -0.012425638735294342,
                0.03490462154150009,
                -0.05372995883226395,
                -0.051986195147037506,
                0.03212038427591324,
                -0.010376612655818462,
                0.0020917453803122044,
                -0.02392280474305153,
                0.02159137651324272,
                -0.012871216051280499,
                0.04505529999732971,
                -0.008972964249551296,
                -0.0407433845102787,
                0.026874685660004616,
                -0.01707059144973755,
                -0.04289698600769043,
                -0.07313603907823563,
                -0.015439963899552822,
                -0.03278249874711037,
                0.061953868716955185,
                0.008725026622414589,
                0.07767082005739212,
                -0.03863587975502014,
                0.011394767090678215,
                -0.06683602929115295,
                0.03543948754668236,
                0.007388889789581299,
                -0.01794370263814926,
                0.01555855292826891,
                -0.0270677600055933,
                0.004904980305582285,
                -0.03895088657736778,
                0.01741931028664112,
                0.027129368856549263,
                0.01272666547447443,
                -0.027661878615617752,
                -0.0085368063300848,
                -0.0028360725846141577,
                -0.012156789191067219,
                -5.7996158245206004e-33,
                -0.018760599195957184,
                -0.06005694717168808,
                0.04618094488978386,
                -0.019997991621494293,
                0.017179260030388832,
                0.01016143150627613,
                0.02318839728832245,
                0.02769542671740055,
                -0.031070837751030922,
                -0.04991735890507698,
                -0.02732165716588497,
                -0.01630125567317009,
                0.01909058354794979,
                -0.01213186327368021,
                0.07594404369592667,
                0.024513034150004387,
                -0.0041966973803937435,
                0.012938673608005047,
                -0.019276781007647514,
                -0.07099892199039459,
                -0.035330988466739655,
                0.020359810441732407,
                0.026550903916358948,
                0.014803526923060417,
                -0.054252371191978455,
                0.026249652728438377,
                0.029017260298132896,
                0.06147930026054382,
                0.05483770743012428,
                -0.01069810800254345,
                -0.00011006929707946256,
                -0.039949435740709305,
                0.008216089569032192,
                0.010065020993351936,
                -0.01729373075067997,
                0.01258085761219263,
                -0.006233801133930683,
                -0.04761926829814911,
                -0.0145350256934762,
                0.010612682439386845,
                0.0014509680913761258,
                -0.010508653707802296,
                -0.01878969371318817,
                0.014299605041742325,
                -0.02115880325436592,
                -0.022116323933005333,
                0.005309644620865583,
                0.021402087062597275,
                0.02188374660909176,
                0.08472026884555817,
                -0.05145663768053055,
                0.0029975997749716043,
                -0.012900467962026596,
                0.03436576947569847,
                0.05238746851682663,
                0.027985617518424988,
                0.0012244326062500477,
                0.010005075484514236,
                0.05159251391887665,
                0.0046880668960511684,
                -0.07426667213439941,
                0.005344732198864222,
                0.02296978235244751,
                -0.02481384202837944,
                -0.032248686999082565,
                0.029478931799530983,
                0.0015373342903330922,
                0.025638211518526077,
                -0.02950887382030487,
                -0.06118450686335564,
                -0.06846699863672256,
                0.07695727050304413,
                0.022583000361919403,
                -0.026749063283205032,
                -0.041191816329956055,
                0.024781104177236557,
                -2.0213553852954647e-06,
                -0.011339353397488594,
                0.056800637394189835,
                0.06813651323318481,
                0.007526312954723835,
                0.04368523135781288,
                0.027882441878318787,
                0.0031977901235222816,
                0.003537018084898591,
                -0.05935481935739517,
                -0.024630526080727577,
                0.056784167885780334,
                -0.006988100707530975,
                0.02706584520637989,
                -0.005242191720753908,
                0.09492655098438263,
                0.00848541222512722,
                0.015774153172969818,
                0.0843505933880806,
                0.054083451628685,
                -0.02955676056444645,
                0.004859131760895252,
                -0.02121049165725708,
                0.054533522576093674,
                -0.07188640534877777,
                0.0011707431403920054,
                -0.04425932839512825,
                -0.01507400069385767,
                -0.02209923043847084,
                -0.006570127326995134,
                -0.07130571454763412,
                -0.014019584283232689,
                0.018057357519865036,
                0.00016457615129183978,
                -0.00914026703685522,
                -0.015028226189315319,
                -0.016202786937355995,
                -0.01722576469182968,
                0.021730825304985046,
                -0.03154217079281807,
                -0.020697062835097313,
                -0.055902149528265,
                0.0009518328006379306,
                -0.05509261414408684,
                0.030559087172150612,
                -0.03184123709797859,
                0.005023419391363859,
                -0.00843000691384077,
                0.0026174657978117466,
                -0.049956995993852615,
                0.016971804201602936,
                -0.010298626497387886,
                0.018447017297148705,
                -0.022406063973903656,
                -0.039645567536354065,
                0.0340239554643631,
                2.1647915104949789e-07,
                0.004513493273407221,
                0.04187800735235214,
                0.03138001263141632,
                0.06681762635707855,
                -0.016398699954152107,
                -0.016090117394924164,
                0.010613135062158108,
                -0.013358591124415398,
                -0.05980067700147629,
                0.00499221496284008,
                0.04409957304596901,
                -0.02924412302672863,
                0.005370138678699732,
                0.03044428490102291,
                -0.006771337240934372,
                -0.10612892359495163,
                0.008617154322564602,
                -0.008432581089437008,
                -0.045469533652067184,
                -0.031189503148198128,
                0.025279663503170013,
                0.048175957053899765,
                0.03577645123004913,
                0.002884132554754615,
                0.006815695203840733,
                -0.00429369043558836,
                -0.03566289320588112,
                -0.018164584413170815,
                0.0372348390519619,
                0.031806230545043945,
                0.03571445867419243,
                -0.050709910690784454,
                -0.005435043480247259,
                0.04810056835412979,
                0.019932035356760025,
                -0.06322953850030899,
                0.06796323508024216,
                0.019229533150792122,
                0.03335978090763092,
                -0.021231940016150475,
                -0.010635502636432648,
                -0.02659248746931553,
                -0.03857014328241348,
                0.0380764976143837,
                -0.04700188338756561,
                0.01676822267472744,
                0.008405045606195927,
                0.0894942432641983,
                0.02624487690627575,
                -0.006926913280040026,
                0.015908414497971535,
                0.04142490774393082,
                0.08245833218097687,
                0.029717467725276947,
                -0.012104739435017109,
                -0.026207217946648598,
                -0.008439486846327782,
                0.02549990825355053,
                0.044780243188142776,
                0.04797338321805,
                -0.03895469382405281,
                -0.024410586804151535,
                -0.03282798454165459,
                0.032225944101810455,
                0.02239333838224411,
                0.013226312585175037,
                -0.015676699578762054,
                8.508621855941494e-35,
                -0.04773496091365814,
                -0.05565410852432251,
                -0.04727388173341751,
                -0.007554277777671814,
                -0.043871503323316574,
                0.03051469288766384,
                -0.051838431507349014,
                -0.03289838507771492,
                0.003973967861384153,
                -0.0025981173384934664,
                -0.03369089215993881,
                0.009254563599824905,
                0.04924453794956207,
                -0.004361447878181934,
                0.010748203843832016,
                0.024457596242427826,
                0.042038969695568085,
                -0.06360120326280594,
                0.007831264287233353,
                -0.08973219990730286,
                0.056135643273591995,
                0.12096100300550461,
                0.026135150343179703,
                0.020781749859452248,
                0.08122806251049042,
                0.01144313532859087,
                -0.0027560892049223185,
                -0.03550807386636734,
                0.043911874294281006,
                0.013746479526162148,
                0.008904138579964638,
                -0.034643422812223434,
                0.027329228818416595,
                -0.01362425647675991,
                0.04639984667301178,
                0.029783256351947784,
                0.002547516953200102,
                -0.0030847727321088314,
                0.042360927909612656,
                -0.012004551477730274,
                -0.02439364604651928,
                0.0846036970615387,
                -0.0558139942586422,
                -0.030649950727820396,
                -0.020287765190005302,
                2.0432041765161557e-06,
                -0.024781858548521996,
                -0.024948589503765106,
                -0.022874731570482254,
                -0.04468270018696785,
                0.04983086138963699,
                -0.04784868285059929,
                -0.00017924241547007114,
                -0.02084924839437008,
                -0.06156051158905029,
                0.01841793581843376,
                -0.02336989901959896,
                0.07286401093006134,
                0.07270579785108566,
                -0.009671258740127087,
                0.023210015147924423,
                -0.005518960300832987,
                0.036187537014484406,
                -0.06657518446445465,
                0.005491201300173998,
                0.04085538536310196,
                0.019334526732563972,
                0.007820154540240765,
                -0.07812240719795227,
                0.025121157988905907,
                -0.0001306800841121003,
                0.027834583073854446,
                -0.06166801601648331,
                0.01599043980240822,
                0.0007180508691817522,
                0.00041102798422798514,
                0.04015273228287697,
                0.05429205298423767,
                -0.02455163560807705,
                -0.01839359477162361,
                -0.023428823798894882,
                0.03706028312444687,
                -0.030762922018766403,
                0.03266862779855728,
                0.09013018012046814,
                -0.004831109661608934,
                -0.07608602941036224,
                -0.019366346299648285,
                -0.027881819754838943,
                -0.016256658360362053,
                -0.02230369672179222,
                -0.01808042638003826,
                0.007855558767914772,
                -0.014426515437662601,
                0.025717629119753838,
                0.0046594212763011456,
                0.03716209903359413,
                0.03281332924962044,
                -0.033875200897455215,
                -0.011667509563267231,
                0.023655539378523827,
                -0.02506416104733944,
                -0.04623078927397728,
                0.0011832838645204902,
                0.013965313322842121,
                0.040889397263526917,
                -0.05631772056221962,
                0.0008991177892312407,
                0.0027614235877990723,
                -0.022305933758616447,
                0.005744100082665682,
                -0.05602317303419113,
                0.03893929719924927,
                0.06917605549097061,
                0.051694516092538834,
                -0.02833469957113266,
                -0.0098556624725461,
                -0.008815036155283451,
                0.09397698193788528,
                0.0006214694585651159,
                0.02679803967475891,
                -0.003821420017629862,
                -0.012175924144685268,
                -0.035212062299251556,
                0.0574578158557415,
                0.017192870378494263,
                -0.0020294373389333487,
                -0.1005336344242096,
                0.013575483113527298,
                0.009046145714819431,
                0.047185227274894714,
                0.0684283897280693,
                -0.016636056825518608,
                -0.03022429347038269,
                -0.008582677692174911,
                0.016342129558324814,
                0.04085991159081459,
                -0.03650340810418129,
                -0.007809698581695557,
                0.007738684304058552,
                0.03498385101556778,
                -0.0375339649617672,
                -0.010041870176792145,
                -0.01631435938179493,
                0.013912898488342762,
                0.007850481197237968,
                0.004606637638062239,
                0.02584688365459442,
                -0.01697596162557602,
                -0.06349164992570877,
                0.0006929944502189755,
                -0.0130795668810606,
                0.002291650976985693,
                -0.06818556040525436,
                -0.002430410822853446,
                -0.007550824899226427,
                -0.024798911064863205,
                -0.0008296255255118012,
                -0.004414601717144251,
                -0.021394969895482063,
                -0.038865815848112106,
                0.016858581453561783,
                0.030543191358447075,
                0.008897329680621624,
                -0.0017884763656184077,
                0.07270201295614243,
                0.03820829465985298,
                0.022494854405522346,
                -0.036912836134433746,
                0.008951902389526367,
                -0.05185482278466225,
                0.02882879599928856,
                0.039223939180374146,
                -0.0280048456043005,
                0.032119642943143845,
                -0.04934029281139374,
                -0.03154846653342247,
                0.05342971161007881,
                -0.02037629671394825,
                0.03339618816971779,
                -0.025138448923826218,
                -0.016576429829001427,
                -0.01995421014726162,
                -0.023296674713492393,
                -0.01447091531008482,
                0.042473603039979935,
                -0.04619340971112251,
                9.374024557473604e-06,
                -0.02261173538863659,
                -0.060998931527137756,
                -0.02847152017056942,
                -0.03569144010543823,
                -0.13475720584392548,
                0.00018111785175278783,
                0.004359140060842037,
                -0.0064873285591602325,
                -0.008803756907582283,
                0.01527696568518877,
                -0.0497414767742157,
                -0.035895898938179016,
                0.013323371298611164,
                0.018315313383936882,
                0.018487311899662018,
                -0.0847240760922432,
                0.005457388702780008,
                0.003596583614125848,
                0.010683780536055565,
                0.007131778169423342,
                0.07913719862699509,
                -0.02025349624454975,
                -0.053481049835681915,
                -0.01554983202368021,
                -0.02519751526415348,
                0.029768122360110283,
                -0.031308285892009735,
                -0.03911714255809784,
                0.014971532858908176,
                0.018338706344366074,
                -0.005751173011958599,
                -0.04820455610752106,
                0.007739510852843523,
                0.03898419439792633,
                -0.008890892378985882,
                0.057493627071380615,
                0.035092949867248535,
                0.003289346117526293,
                -0.03157036378979683,
                -0.02235141210258007,
                -0.015824662521481514,
                -0.060941968113183975,
                0.021300528198480606,
                -0.0368189737200737,
                0.015177038498222828,
                -0.007271097041666508,
                0.023858394473791122,
                -0.03334442153573036,
                0.016680987551808357,
                0.00785781815648079,
                0.005064197815954685,
                -0.018465973436832428,
                -0.004792495165020227,
                -0.02059953287243843,
                0.023242419585585594,
                0.018862877041101456,
                -0.0165630541741848,
                -0.003942382987588644,
                0.025646481662988663,
                0.043710775673389435,
                -0.08182945847511292,
                0.04313641041517258,
                -0.10371357947587967,
                -0.0032274220138788223,
                -0.031042197719216347,
                -0.0013222020352259278,
                0.005727001000195742,
                -0.0013533884193748236,
                -0.02382585220038891,
                0.11895069479942322,
                -0.011607951484620571,
                0.08058339357376099,
                -0.046502768993377686,
                -0.02622225508093834,
                0.01613541878759861,
                0.011053493246436119,
                -0.004718527663499117,
                0.017704926431179047,
                0.16160906851291656,
                -0.044048700481653214,
                0.02504708617925644,
                -0.020966090261936188,
                -0.05126061290502548,
                0.04011001065373421,
                0.028835948556661606,
                -0.04317154735326767,
                -0.013353178277611732,
                0.03923512622714043,
                -0.03483642637729645,
                -0.02542329579591751,
                -0.02951458841562271,
                0.023910142481327057,
                0.018228253349661827,
                -0.012062231078743935,
                0.04886500537395477,
                -0.017831962555646896,
                -0.010954760946333408,
                -0.022122275084257126,
                0.0006497749127447605,
                -0.059182364493608475,
                -0.04068547487258911,
                -0.010207757353782654,
                -0.02423056960105896,
                0.050628893077373505,
                -0.027634555473923683,
                0.013429221697151661,
                0.01678948849439621,
                0.03670239448547363,
                0.026709696277976036,
                -0.004805549047887325,
                0.00575971556827426,
                -0.05125891789793968,
                -0.009913582354784012,
                -0.024434693157672882,
                -0.035692498087882996,
                0.040492668747901917,
                -0.005078865215182304,
                -0.028660571202635765,
                -0.030156096443533897,
                -0.08187399059534073,
                -0.010840225033462048,
                -0.018315374851226807,
                0.01711900345981121,
                -0.06894079595804214,
                -0.011753017082810402,
                -0.013964790850877762,
                -0.01791246607899666,
                -0.006855671294033527,
                0.03690429404377937,
                0.024396007880568504,
                -0.0051782457157969475,
                -0.00909922644495964,
                0.075102299451828,
                0.005017753224819899,
                0.014543205499649048,
                -0.011039729230105877,
                -0.029606230556964874,
                -0.013221402652561665,
                -0.0647500678896904,
                -0.022408295422792435,
                -0.047803882509469986,
                -0.014970753341913223,
                0.014826185069978237,
                -0.004673038609325886,
                0.008296658284962177,
                0.02657032199203968,
                -0.01530364528298378,
                -0.012835335917770863,
                0.033817607909440994,
                -0.04812438040971756,
                0.009274750016629696,
                -0.05881231650710106,
                0.02073366753757,
                -0.0040822336450219154,
                -0.0698210820555687,
                -0.02623213827610016,
                -0.04584312066435814,
                0.030570894479751587,
                -0.044402193278074265,
                -0.017423100769519806,
                -0.03336304798722267,
                -0.03447726368904114,
                0.010938454419374466,
                0.02221176028251648,
                -0.058396048843860626,
                0.015365353785455227,
                0.028986668214201927,
                0.02654002606868744,
                -0.004589570686221123,
                -0.01544083934277296,
                0.03604794666171074,
                0.006872508674860001,
                0.06607738137245178,
                -0.01599081978201866,
                0.03271576762199402,
                -0.010777176357805729,
                -0.03551574423909187,
                0.015389050357043743,
                0.021696662530303,
                0.0077860672026872635,
                0.01718852110207081,
                0.05366203188896179,
                -0.07932872325181961,
                0.004394837189465761,
                0.03312432020902634,
                0.029103342443704605,
                0.01225100364536047,
                -0.03805258125066757,
                0.06579820066690445,
                0.03494788706302643,
                -0.008106084540486336,
                -0.01570766046643257,
                -0.0033072459045797586,
                -0.02257539890706539,
                0.01133721973747015,
                -0.03203596547245979,
                0.056688342243433,
                -0.0051803505048155785,
                -0.0434739887714386,
                -0.033497557044029236,
                0.019648093730211258,
                0.027015799656510353,
                0.010669724084436893,
                0.0023389302659779787,
                0.03816027566790581,
                0.0420280359685421,
                0.007744136732071638,
                0.02289600297808647,
                -0.03775280341506004,
                -0.014417311176657677,
                -0.050266727805137634,
                -0.004876241087913513,
                -0.0093079237267375,
                0.00429243128746748,
                -0.05273127183318138,
                -0.05766439437866211,
                0.014640167355537415,
                0.014008695259690285,
                0.0061422716826200485,
                -0.07664462924003601,
                0.017475737258791924,
                0.032684795558452606,
                0.0018442792352288961,
                -0.012385562062263489,
                -0.016498515382409096,
                0.038034580647945404,
                0.029720157384872437,
                -0.03422130271792412,
                0.01521115843206644,
                -0.03973457217216492,
                0.006305461749434471,
                -0.017192140221595764,
                -0.02069724164903164,
                0.00044465798418968916,
                -0.05025844648480415,
                -0.054136186838150024,
                0.021499892696738243,
                -0.021675610914826393,
                -0.09416839480400085,
                -0.022713158279657364,
                0.003465263405814767,
                0.09245245903730392,
                0.0075439889915287495,
                0.029833074659109116,
                0.01948886550962925,
                -0.010635106824338436,
                -0.022199254482984543,
                -0.014635561034083366,
                0.0007009143591858447,
                0.06602304428815842,
                0.024718502536416054,
                0.050191063433885574,
                -0.005761800333857536,
                -0.03615938872098923,
                -0.03513575345277786,
                -0.03137664124369621,
                0.03112201765179634,
                0.012386827729642391,
                -0.010583852417767048,
                0.07984311133623123,
                0.04874458163976669,
                0.02218608371913433,
                0.041411932557821274,
                0.016922039911150932,
                0.0345391184091568,
                0.042837779968976974,
                0.017193960025906563,
                -0.011980747804045677,
                -0.04753381386399269,
                -0.015296606346964836,
                0.019740266725420952,
                -0.10126278549432755,
                -0.00303186709061265,
                0.013444803655147552,
                -0.03163924068212509,
                -0.011387131176888943,
                -0.006782048847526312,
                0.08380985260009766,
                -0.034572944045066833,
                0.023460501804947853,
                -0.06508301198482513,
                -0.014774066396057606,
                0.031063545495271683,
                0.05488508939743042,
                0.03798554837703705,
                -0.0119777237996459,
                0.031400199979543686,
                -0.0492384172976017,
                -0.025834165513515472,
                0.000937903649173677,
                -0.037525344640016556,
                0.02758171781897545,
                -0.07569485902786255,
                0.018559984862804413,
                -0.03913615643978119,
                0.023171357810497284,
                0.021351484581828117,
                0.010088380426168442,
                0.014861767180263996,
                0.05006145313382149,
                0.030137352645397186,
                -0.12554584443569183,
                0.04573236033320427,
                -0.009764493443071842,
                0.004826416727155447,
                -0.03941597789525986,
                -0.06517447531223297,
                -0.01710643619298935,
                0.05152696743607521,
                0.015802212059497833,
                -0.0022106198593974113,
                0.026339169591665268,
                -0.04458126425743103,
                -0.05176244303584099,
                -0.041298169642686844,
                -0.0019762027077376842,
                0.03213023021817207,
                -0.005393819883465767,
                0.019233398139476776,
                -0.058636900037527084,
                -0.03431974723935127,
                -0.019419843330979347,
                -0.07706011086702347,
                0.03821514546871185,
                -0.0026566728483885527,
                0.032167188823223114,
                0.042291898280382156,
                -0.0030641912017017603,
                0.021379264071583748,
                -0.00242022005841136,
                0.08969075232744217,
                0.029449189081788063,
                -0.009361164644360542,
                0.031165597960352898,
                0.002471642103046179,
                -0.02180907130241394,
                0.10538773238658905,
                0.03549496829509735,
                0.06768903881311417,
                0.03678852319717407,
                0.0007945908582769334,
                0.008060761727392673,
                -0.039165567606687546,
                -0.021766941994428635,
                -0.012339300476014614,
                0.005324610974639654,
                0.01112927682697773,
                0.001761922030709684,
                0.028625844046473503,
                0.023179134353995323,
                -0.009333097375929356,
                0.050450649112463,
                -0.008291641250252724,
                -0.02047565020620823,
                0.0022036200389266014,
                -0.04076314717531204,
                -0.009088592603802681,
                -0.04280180111527443,
                0.030121488496661186,
                0.07438107579946518,
                -0.0038365228101611137,
                -0.01976839266717434,
                -0.007691879756748676,
                -0.011216011829674244,
                0.0320981964468956,
                -0.022778529673814774,
                0.01884518377482891,
                -0.023622488602995872,
                -0.00921714585274458,
                0.04037724435329437,
                0.00331782060675323,
                -0.010419624857604504,
                -0.028096307069063187,
                0.02528516948223114,
                -0.008915879763662815,
                -0.00021320344239939004,
                0.01808633655309677,
                -0.02494118921458721,
                -6.992799895954825e-33,
                0.0273280069231987,
                -0.021290484815835953,
                0.004834495950490236,
                0.09064149111509323,
                -0.04955851659178734,
                0.024320535361766815,
                0.00914770271629095,
                -0.004123158287256956,
                0.05170421302318573,
                -0.034395549446344376,
                -0.01019962877035141,
                0.03949081897735596,
                0.0255129411816597,
                0.039167050272226334,
                -0.001443477114662528,
                -0.008156375959515572,
                0.058679379522800446,
                -0.009963387623429298,
                -0.034269779920578,
                -0.04330965876579285,
                0.022176409140229225,
                0.0098949633538723,
                0.015438767150044441,
                0.030771834775805473,
                0.03787532076239586,
                0.0014654560945928097,
                -0.005433208774775267,
                -0.005389709956943989,
                0.06330764293670654,
                0.02427634224295616,
                0.021376075223088264,
                -0.03459501266479492,
                0.000826425151899457,
                -0.017995938658714294,
                0.02417054958641529,
                0.00908625964075327,
                -0.0011304791551083326,
                0.01239719707518816,
                -0.015181710943579674,
                -0.03782657906413078,
                -0.09329590946435928,
                -0.030897822231054306,
                0.016388975083827972,
                0.005671218037605286,
                -0.021724294871091843,
                -0.0032817646861076355,
                -0.012357027269899845,
                0.004347934387624264,
                -0.01519622839987278,
                0.0004501226940192282,
                0.06918103247880936,
                -0.00033387739676982164,
                -0.0448649600148201,
                0.06770580261945724,
                -0.0038933323230594397,
                -0.029324548318982124,
                -0.0015531666576862335,
                0.0005538023542612791,
                -0.004692590795457363,
                -0.0035589004401117563,
                -0.038706518709659576,
                0.0027120152954012156,
                0.0017227490898221731,
                0.008165474981069565,
                0.00990953017026186,
                0.008292650803923607,
                -0.017499564215540886,
                0.02874106541275978,
                -0.0017557130195200443,
                0.031004788354039192,
                -0.001796572469174862,
                0.04867452383041382,
                -0.028106573969125748,
                -0.027112672105431557,
                -0.07936350256204605,
                -0.029514538124203682,
                0.03966465964913368,
                0.056324321776628494,
                0.009508791379630566,
                0.010906041599810123,
                0.004975759889930487,
                -0.03182492032647133,
                0.008253924548625946,
                0.007632113993167877,
                -0.024789702147245407,
                0.04142935574054718,
                -0.03361859545111656,
                -0.007511167321354151,
                0.043443579226732254,
                -0.015516962856054306,
                -0.01814698800444603,
                0.01756097562611103,
                -0.021023686975240707,
                -0.031898047775030136,
                0.02238871529698372,
                -0.009629561565816402,
                -0.0034080646000802517,
                0.011607566848397255,
                -0.002460516057908535,
                0.020497014746069908,
                -0.028008311986923218,
                0.0395323783159256,
                0.00358212785795331,
                -0.02613343857228756,
                -0.01070373598486185,
                -0.02978387661278248,
                -0.0023624608293175697,
                -0.003019054653123021,
                -0.04364887624979019,
                0.010626954026520252,
                0.03034643456339836,
                -0.021105455234646797,
                -0.00829768180847168,
                0.017927665263414383,
                -0.0043309652246534824,
                0.01752714067697525,
                0.03374850004911423,
                -0.1186431348323822,
                -0.027645494788885117,
                0.04081610590219498,
                0.03352485969662666,
                -0.041435930877923965,
                0.013202867470681667,
                0.044209953397512436,
                -0.03909723088145256,
                0.01020185649394989,
                -0.022981176152825356,
                0.0392935685813427,
                -0.0043158214539289474,
                -0.05588856339454651,
                0.016257023438811302,
                0.018038732931017876,
                2.715894709126587e-07,
                -0.03904668241739273,
                -0.03166576102375984,
                0.040619879961013794,
                0.014092328026890755,
                0.05538433417677879,
                -0.046219926327466965,
                0.011291531845927238,
                0.054443761706352234,
                -0.03549250587821007,
                -0.012541579082608223,
                0.04581265524029732,
                0.024267006665468216,
                0.012256794609129429,
                -0.02317030169069767,
                0.02337338961660862,
                -0.009162865579128265,
                -0.046487804502248764,
                0.05207200348377228,
                -0.021513691172003746,
                -0.013491704128682613,
                0.061786651611328125,
                -0.00409876462072134,
                0.021530725061893463,
                -0.016217118129134178,
                -0.02858307957649231,
                0.056404050439596176,
                0.02955922670662403,
                -0.06274057179689407,
                -0.008512131869792938,
                0.02848561480641365,
                -0.036928046494722366,
                0.05714388191699982,
                -0.00725806737318635,
                0.07058446109294891,
                0.01299171894788742,
                -0.012259596027433872,
                0.07644962519407272,
                0.055663302540779114,
                0.004131522495299578,
                0.02234441041946411,
                3.81666723114904e-05,
                -0.01834847405552864,
                -0.016824331134557724,
                0.022701149806380272,
                0.039469823241233826,
                -0.015119843184947968,
                0.01508445106446743,
                0.04387025162577629,
                -0.05488475784659386,
                -0.06778454780578613,
                0.029982656240463257,
                -0.032764263451099396,
                -0.04772619158029556,
                0.03295130282640457,
                0.014859579503536224,
                -0.012225769460201263,
                0.011476611718535423,
                0.04981603845953941,
                0.061443034559488297,
                0.006383189931511879,
                0.01657126285135746,
                -0.013425294309854507,
                -0.009835810400545597,
                0.01948108710348606,
                0.04064152017235756,
                -0.04921034350991249,
                -0.04845474660396576,
                1.863838243519265e-34,
                -0.02449926733970642,
                -0.03625746816396713,
                -0.025881825014948845,
                -0.049709420651197433,
                -0.0045637646690011024,
                -0.04376520588994026,
                0.062309108674526215,
                -0.009931419976055622,
                -0.03863801062107086,
                -0.07335855811834335,
                -0.026133548468351364,
                0.03123880736529827,
                -0.008793222717940807,
                -0.014528419822454453,
                -0.006786220706999302,
                -0.04057738929986954,
                -0.001242428901605308,
                -0.03590978682041168,
                -0.016356835141777992,
                0.013729569502174854,
                0.038547854870557785,
                0.04190630838274956,
                0.000633403891697526,
                -0.022832849994301796,
                -0.037387020885944366,
                -0.006037388928234577,
                -0.09451238811016083,
                -0.011023996397852898,
                -0.018566422164440155,
                0.008195017464458942,
                0.013186565600335598,
                -0.052519239485263824,
                0.0018446819158270955,
                -0.0009397583198733628,
                -0.04532988369464874,
                0.037525858730077744,
                0.023243360221385956,
                -0.026929086074233055,
                -0.027916794642806053,
                0.037022482603788376,
                0.0090065011754632,
                0.06004458665847778,
                -0.021872911602258682,
                -0.005267181433737278,
                -0.060356587171554565,
                1.474346277063887e-06,
                0.004359131678938866,
                -0.0012292779283598065,
                0.004423152189701796,
                -0.003208520356565714,
                0.0066163064911961555,
                -0.022772731259465218,
                -0.028288552537560463,
                -0.035940539091825485,
                -0.023627176880836487,
                -0.03720366209745407,
                -0.010550979524850845,
                0.04161369800567627,
                -0.041584983468055725,
                -0.05350251868367195,
                -0.01765120029449463,
                -0.015168892219662666,
                -0.06107800453901291,
                -0.08317669481039047,
                -0.071031853556633,
                0.05231604352593422,
                0.0003024932520929724,
                -0.0003119718749076128,
                0.03761324658989906,
                0.032159216701984406,
                0.13651341199874878,
                -0.022865405306220055,
                0.025252552703022957,
                -0.002574666403234005,
                0.033225081861019135,
                -0.0276008490473032,
                0.010762253776192665,
                -0.004040353931486607,
                0.030226843431591988,
                0.04974471777677536,
                0.04433443769812584,
                0.024256745353341103,
                0.02145768329501152,
                0.0001972899481188506,
                0.06135294958949089,
                -0.07741834968328476,
                0.03853225335478783,
                0.013247795403003693,
                0.07794245332479477,
                0.01760433241724968,
                -0.04298538342118263,
                0.032201141119003296,
                -0.017810866236686707,
                -0.008932159282267094,
                0.07289467751979828,
                -0.0008869333541952074,
                0.020781146362423897,
                -0.023529956117272377,
                -0.025642983615398407,
                -0.020299265161156654,
                0.05894649028778076,
                0.0014759013429284096,
                -0.024778183549642563,
                -0.01953749731183052,
                0.0398079976439476,
                0.016954563558101654,
                -0.02406124956905842,
                0.024229075759649277,
                0.07999374717473984,
                0.03665498271584511,
                0.04397188127040863,
                -0.00951702892780304,
                0.054428789764642715,
                -0.024637537077069283,
                0.09226682782173157,
                -0.011038227938115597,
                0.0009282574173994362,
                -0.008266247808933258,
                0.0038402657955884933,
                0.03535095229744911,
                0.012470931746065617,
                -0.021467022597789764,
                0.00018401794659439474,
                0.020042045041918755,
                -0.09010963886976242,
                0.07074495404958725,
                -0.009027349762618542,
                0.048338066786527634,
                -0.04418312385678291,
                0.0376293770968914,
                -0.00028546282555907965,
                -0.09733080863952637,
                0.011691683903336525,
                -0.0049755387008190155,
                0.012080270797014236,
                -0.003081849543377757,
                -0.020774533972144127,
                -0.0281745046377182,
                0.036595068871974945,
                0.06108490377664566,
                0.01007254607975483,
                -0.034188129007816315,
                0.023031121119856834,
                0.00824080128222704,
                0.018554000183939934,
                0.01408442109823227,
                0.000605928769800812,
                0.006074848584830761,
                -0.019457025453448296,
                -0.06464530527591705,
                -0.01965460740029812,
                -0.02662382833659649,
                -0.042103588581085205,
                -0.005850356537848711,
                0.03058726154267788,
                0.028031542897224426,
                0.021316662430763245,
                0.001378223649226129,
                0.025829648599028587,
                -0.024843847379088402,
                -0.04669126123189926,
                -0.005678660236299038,
                0.021494366228580475,
                0.01721712201833725,
                -0.03669366240501404,
                0.12025033682584763,
                0.09387635439634323,
                -0.025585882365703583,
                0.006669238209724426,
                0.0235886313021183,
                0.046854469925165176,
                -0.021900683641433716,
                0.009732600301504135,
                -0.028154416009783745,
                -0.026076195761561394,
                0.0004967174609191716,
                0.021051406860351562,
                -0.011474755592644215,
                -0.023937230929732323,
                -0.07702411711215973,
                -0.011145043186843395,
                0.011913346126675606,
                0.0074803209863603115,
                -0.03596623241901398,
                -0.005818991921842098,
                -0.010300825349986553,
                0.000811849138699472,
                -0.032067470252513885,
                0.07379782944917679,
                0.010437756776809692,
                -0.013009169138967991,
                0.006126463413238525,
                -0.05737651512026787,
                0.01556603517383337,
                -0.01229192316532135,
                0.01678398996591568,
                -0.0033937194384634495,
                0.06309767812490463,
                0.009712288156151772,
                -0.03840559720993042,
                -0.03214513510465622,
                -0.017158856615424156,
                -0.007044320926070213,
                0.023650318384170532,
                -0.000580372114200145,
                -0.008954844437539577,
                0.023920182138681412,
                0.06335774064064026,
                -0.0030971618834882975,
                -0.030356813222169876,
                -0.0011854978511109948,
                -0.032842960208654404,
                0.07491900771856308,
                0.07139886170625687,
                -0.0017416782211512327,
                -0.05133025348186493,
                -0.0020063226111233234,
                -0.010355555452406406,
                0.03436807170510292,
                -0.014923814684152603,
                -0.005630985368043184,
                -0.014990763738751411,
                0.023341629654169083,
                -0.0009159590699709952,
                0.0033263592049479485,
                -0.046062834560871124,
                -0.0630040392279625,
                0.0034695386420935392,
                0.0033690184354782104,
                0.013808121904730797,
                0.07289372384548187,
                0.024162577465176582,
                0.00012842001160606742,
                0.0025779083371162415,
                0.009716004133224487,
                -0.05267750099301338,
                -0.023044908419251442,
                -0.002185451565310359,
                0.01397724635899067,
                -0.040866419672966,
                -0.06140865385532379,
                0.015956731513142586,
                0.0361701101064682,
                0.0029686212074011564,
                0.018035106360912323,
                -0.019357148557901382,
                -0.0260930135846138,
                0.07776197046041489,
                0.050424832850694656,
                0.003112687263637781,
                -0.09456350654363632,
                0.04886829853057861,
                -0.08422233909368515,
                -0.0020027540158480406,
                -0.007143272086977959,
                0.08208899945020676,
                -0.019976917654275894,
                0.06761811673641205,
                0.008754480630159378,
                -0.009832195937633514,
                -0.0030628039967268705,
                -0.016615962609648705,
                -0.0706167072057724,
                0.04010804370045662,
                -0.021985381841659546,
                0.08595481514930725,
                -0.005314781796187162,
                0.02290653996169567,
                0.023883648216724396,
                -0.033579710870981216,
                -0.021583588793873787,
                -0.016786126419901848,
                -0.025567742064595222,
                -0.04203558713197708,
                0.0070260437205433846,
                -0.017221501097083092,
                0.016655679792165756,
                -0.017550591379404068,
                0.018843844532966614,
                -0.03633171692490578,
                -0.024310320615768433,
                0.019860155880451202,
                0.026975097134709358,
                0.027881106361746788,
                -0.012179169803857803,
                -0.0004954105825163424,
                0.022825129330158234,
                -0.05818658694624901,
                -0.0028015454299747944,
                -0.012412692420184612,
                0.014927200973033905,
                0.0017007238930091262,
                0.02990552969276905,
                -0.07147876918315887,
                -0.0336889922618866,
                0.02650144323706627,
                0.05623039975762367,
                0.010591274127364159,
                -0.0021661771461367607,
                -0.03804725408554077,
                -0.009838862344622612,
                -0.03268096223473549,
                -0.05827046185731888,
                -0.014358866959810257,
                -0.056126661598682404,
                0.009237590245902538,
                0.025743193924427032,
                0.04508553817868233,
                -0.04248736426234245,
                0.032817430794239044,
                -0.008955199271440506,
                0.0010679360711947083,
                -0.016798801720142365,
                0.023693818598985672,
                -0.010707143694162369,
                -0.008868752047419548,
                0.014346052892506123,
                0.07440932840108871,
                -0.05271590128540993,
                -0.011237330734729767,
                0.033687058836221695,
                1.9894261640729383e-05,
                -0.00752166286110878,
                0.0527411587536335,
                0.03973586484789848,
                0.007886882871389389,
                -0.02725246734917164,
                0.06714893877506256,
                -0.0454428605735302,
                0.01711917668581009,
                0.06319060176610947,
                0.011625230312347412,
                0.010220442898571491,
                -0.0010860717156901956,
                -0.0020744726061820984,
                0.022104978561401367,
                -0.05298430100083351,
                -0.012289333157241344,
                -0.00839958619326353,
                0.009066502563655376,
                -0.03284323960542679,
                -0.023786533623933792,
                0.08721168339252472,
                0.031739987432956696,
                0.024377333000302315,
                -0.06604024767875671,
                0.007058158051222563,
                -0.046308763325214386,
                -0.07017644494771957,
                -0.05523252487182617,
                0.022104665637016296,
                0.032035019248723984,
                -0.032690923660993576,
                -0.051123764365911484,
                -0.029344744980335236,
                0.027747610583901405,
                0.011468752287328243,
                -0.016994711011648178,
                -0.011622549034655094,
                -0.003890170017257333,
                0.004360514227300882,
                0.049560386687517166,
                0.029066335409879684,
                -0.02580529823899269,
                -0.009067834354937077,
                -0.00015796766092535108,
                0.08375274389982224,
                0.025123219937086105,
                0.09321708232164383,
                -0.024442914873361588,
                0.019298475235700607,
                -0.035681989043951035,
                0.0043677459470927715,
                -0.011967450380325317,
                0.04044857248663902,
                -0.008825013414025307,
                0.04028427228331566,
                -0.008996683172881603,
                0.07939232885837555,
                -0.02884538657963276,
                -0.01037670113146305,
                -0.05196930095553398,
                0.026598284021019936,
                -0.03374224528670311,
                0.01715453527867794,
                -0.02613697573542595,
                0.04278823360800743,
                -0.020650263875722885,
                0.022999433800578117,
                0.007802489213645458,
                0.008341606706380844,
                -0.027333194389939308,
                0.0034300910774618387,
                0.04692770168185234,
                -0.02574153244495392,
                -0.05300451070070267,
                0.025855187326669693,
                0.015849443152546883,
                -0.019972555339336395,
                0.007775492966175079,
                0.020892320200800896,
                0.025708574801683426,
                0.018053269013762474,
                0.02936525084078312,
                -0.029426898807287216,
                -0.009914183057844639,
                0.03209608420729637,
                -0.024527819827198982,
                -0.027171650901436806,
                0.03252089396119118,
                0.045351188629865646,
                0.04541671276092529,
                -0.0005633487016893923,
                0.01245669275522232,
                0.012719535268843174,
                -0.05647430196404457,
                -0.07382559031248093,
                0.035490307956933975,
                -0.011536984704434872,
                -0.028330618515610695,
                -0.08671434223651886,
                0.03504374995827675,
                0.040423683822155,
                0.037663936614990234,
                0.007202248089015484,
                -0.017824489623308182,
                0.07256285846233368,
                -0.0005472043412737548,
                0.013665184378623962,
                -0.02585143782198429,
                -0.017196595668792725,
                -0.006116238888353109,
                -0.027860185131430626,
                0.032556138932704926,
                -0.039418838918209076,
                0.07339675724506378,
                -0.05045295134186745,
                0.012826251797378063,
                0.04791441559791565,
                0.02223513461649418,
                0.024640165269374847,
                -0.015037702396512032,
                -0.09137555956840515,
                -0.07353092730045319,
                0.015067361295223236,
                -0.006745098624378443,
                -0.025205479934811592,
                0.03610621765255928,
                0.04689067602157593,
                -0.0008010489982552826,
                0.00547704566270113,
                0.013559874147176743,
                0.046603769063949585,
                -0.02171013131737709,
                -0.01264960877597332,
                -0.0540858656167984,
                0.02394755184650421,
                0.006388572975993156,
                -0.07677385956048965,
                0.01692325994372368,
                0.014529688283801079,
                0.04021821916103363,
                -0.0024060013238340616,
                0.009442722424864769,
                0.026667345315217972,
                -0.00891989003866911,
                -0.012664265930652618,
                0.031047314405441284,
                -0.03157256916165352,
                0.0031132767908275127,
                0.01985809952020645,
                0.040973201394081116,
                0.01713654212653637,
                -0.022364620119333267,
                -0.006124802865087986,
                0.0038280743174254894,
                0.02821207046508789,
                -0.032027799636125565,
                -0.07919028401374817,
                -0.015922054648399353,
                -0.05923343822360039,
                -0.048745013773441315,
                0.0055789644829928875,
                -0.04316690191626549,
                0.0549098402261734,
                -0.014229065738618374,
                0.007228042930364609,
                -0.03387516364455223,
                -0.024196265265345573,
                0.007196292281150818,
                -0.029298927634954453,
                -0.045146189630031586,
                0.11336781084537506,
                0.012478544376790524,
                -0.011922046542167664,
                0.0434594564139843,
                -0.01269959844648838,
                0.05712149292230606,
                -0.006784691940993071,
                0.007611332461237907,
                -0.03745303303003311,
                0.011529049836099148,
                -0.02427573688328266,
                -0.009954561479389668,
                -0.026582909747958183,
                -0.018130935728549957,
                0.0448748879134655,
                0.0038495580665767193,
                0.010723663493990898,
                0.01193628367036581,
                -0.038723286241292953,
                0.004187386948615313,
                0.06694560497999191,
                -0.033194657415151596,
                0.006093848031014204,
                -0.0197491105645895,
                0.02543039806187153,
                0.023411201313138008,
                0.005125744733959436,
                0.03545481711626053,
                0.018430810421705246,
                -0.00875936634838581,
                -0.0009542049956507981,
                -0.06013166531920433,
                0.003725745715200901,
                -0.012067492119967937,
                -0.049266453832387924,
                -0.04056740179657936,
                -0.0467180572450161,
                -0.025112558156251907,
                0.04186427220702171,
                0.009293738752603531,
                0.004606887232512236,
                -0.014623106457293034,
                -0.004010521341115236,
                -0.046972956508398056,
                0.029322875663638115,
                0.00820447038859129,
                0.013384195975959301,
                0.019290270283818245,
                -0.026681778952479362,
                0.05318288132548332,
                -0.03066566213965416,
                0.007039130199700594,
                0.010157311335206032,
                -0.006910259369760752,
                0.0579276941716671,
                -0.09529878199100494,
                0.08076760172843933,
                -0.007348759099841118,
                0.00010086983093060553,
                -0.029317937791347504,
                -0.004374234937131405,
                -0.03019259124994278,
                -0.007276322692632675,
                -0.011275332421064377,
                0.000695530092343688,
                0.015058757737278938,
                0.055678948760032654,
                -0.010269899852573872,
                -0.025554228574037552,
                -0.0062958174385130405,
                0.013420567847788334,
                -0.07658405601978302,
                -0.00816781260073185,
                -0.03923947736620903,
                -7.010349291928632e-33,
                -0.023969756439328194,
                -0.0008432212052866817,
                0.027947254478931427,
                0.0020057223737239838,
                -0.007973087951540947,
                -0.009459295310080051,
                -0.03768494725227356,
                0.03201494738459587,
                -0.05298608914017677,
                -0.02822807803750038,
                -0.03148780018091202,
                -0.009859567508101463,
                0.012695317156612873,
                0.006023528520017862,
                0.08451150357723236,
                -0.00012918224092572927,
                -0.029569074511528015,
                0.01844811998307705,
                -0.04658583179116249,
                -0.005740114022046328,
                -0.01701785996556282,
                0.015795182436704636,
                0.05190509930253029,
                -0.01850779354572296,
                -0.004059110302478075,
                0.04452034458518028,
                0.014553004875779152,
                -0.04940437525510788,
                -0.06652876734733582,
                0.016936196014285088,
                -0.0031995323952287436,
                -0.015702998265624046,
                -0.0037172269076108932,
                -0.07293923944234848,
                0.01193756889551878,
                -0.017418108880519867,
                -0.021337492391467094,
                -0.03510767221450806,
                0.01407705433666706,
                -0.01193432230502367,
                -0.05293312668800354,
                -0.026598908007144928,
                -0.04867015779018402,
                -0.005089948419481516,
                -0.029370373114943504,
                -0.06941758096218109,
                -0.014038072898983955,
                -0.03170033544301987,
                -0.016071666032075882,
                0.058930445462465286,
                0.026320448145270348,
                -0.00473354198038578,
                0.006576531566679478,
                0.019808605313301086,
                0.046674251556396484,
                0.014573933556675911,
                0.018599238246679306,
                0.01788518764078617,
                0.006945779547095299,
                0.07295739650726318,
                0.05870946869254112,
                0.009221374057233334,
                0.009807986207306385,
                0.025100814178586006,
                0.014602516777813435,
                0.007053401321172714,
                -0.021113991737365723,
                -0.0028083506040275097,
                -0.01215401291847229,
                -0.05633573234081268,
                0.001258860225789249,
                0.07717185467481613,
                -0.06525277346372604,
                0.02405637316405773,
                -0.01350930891931057,
                -0.08571907877922058,
                -0.014201454818248749,
                -0.02077600173652172,
                0.023642390966415405,
                -0.03237784281373024,
                -0.014662852510809898,
                0.01959364488720894,
                -0.009652438573539257,
                -0.0285445936024189,
                -0.009055395610630512,
                0.034309014678001404,
                0.03295158967375755,
                0.08268146961927414,
                -0.01855676993727684,
                0.010600650683045387,
                0.04087156429886818,
                0.014061172492802143,
                -0.013100647367537022,
                0.01646796427667141,
                0.008811332285404205,
                0.09932039678096771,
                0.00953755248337984,
                -0.03598989546298981,
                0.01035484578460455,
                0.021336987614631653,
                0.007456026040017605,
                -0.017183102667331696,
                -0.0510733425617218,
                0.030133826658129692,
                -0.013204305432736874,
                0.019911209121346474,
                -0.056871455162763596,
                0.03714890033006668,
                -0.0005932280328124762,
                0.00380306551232934,
                0.0133748734369874,
                -0.023891856893897057,
                0.01275281049311161,
                -0.0314067006111145,
                -0.036862462759017944,
                -0.03554515168070793,
                -0.007222154177725315,
                -0.030114898458123207,
                -0.017696058377623558,
                0.06381596624851227,
                0.03160674124956131,
                0.04780987650156021,
                -0.04918519780039787,
                0.011417187750339508,
                0.0019502138020470738,
                0.013976000249385834,
                0.04337054863572121,
                -0.06887857615947723,
                -0.029917079955339432,
                0.03169349953532219,
                -0.00045747877447865903,
                0.03447197005152702,
                2.30215320584648e-07,
                -0.017705827951431274,
                0.0015446707839146256,
                0.035591915249824524,
                -0.04388255625963211,
                0.03751097619533539,
                0.0033122424501925707,
                -0.06141626834869385,
                0.026371726766228676,
                0.025794224813580513,
                0.015334174036979675,
                0.015386057086288929,
                0.03136075288057327,
                -0.00011264673958066851,
                -0.011802288703620434,
                -0.024547385051846504,
                0.040332213044166565,
                0.03212229534983635,
                -0.11127382516860962,
                -0.009480996988713741,
                -0.054808564484119415,
                -0.01670670136809349,
                -0.029465479776263237,
                -0.048214543610811234,
                -0.012917530722916126,
                -0.03422484174370766,
                -0.045203473418951035,
                0.0054786535911262035,
                -0.050277967005968094,
                0.051975179463624954,
                0.003082151524722576,
                0.045840390026569366,
                -0.007125673815608025,
                -0.007250584661960602,
                -0.04549645632505417,
                0.009849848225712776,
                -0.01214873418211937,
                0.04089989513158798,
                0.009969448670744896,
                0.034528736025094986,
                0.03670147433876991,
                -0.06652116775512695,
                -0.00513669615611434,
                0.003513806965202093,
                -0.023147519677877426,
                -0.017387904226779938,
                0.12460435181856155,
                0.04404117912054062,
                -0.05192707106471062,
                -0.06960157305002213,
                0.02275693416595459,
                -0.007198772858828306,
                0.06214574724435806,
                0.017546499148011208,
                -0.02907228097319603,
                0.013144215568900108,
                -0.018047986552119255,
                0.022045379504561424,
                -0.0004703626618720591,
                0.04492645338177681,
                0.0846269354224205,
                -0.017595214769244194,
                0.04898625612258911,
                -0.01750674471259117,
                0.032628707587718964,
                0.03929360955953598,
                -0.008270232006907463,
                -0.046621233224868774,
                8.638973159188147e-35,
                -0.00830030906945467,
                -0.005998114123940468,
                -0.029508385807275772,
                -0.06610211730003357,
                -0.04316841438412666,
                0.00579167390242219,
                0.02383303828537464,
                -0.007845449261367321,
                0.05163281410932541,
                -0.004227117169648409,
                0.025714153423905373,
                -0.027100922539830208,
                -0.11419344693422318,
                -0.0013000174658372998,
                -0.0020866242703050375,
                -0.02644229307770729,
                0.018062638118863106,
                -0.025948835536837578,
                -0.0061703333631157875,
                -0.05472743511199951,
                0.01834491267800331,
                0.06637461483478546,
                0.009719251655042171,
                0.0015498589491471648,
                0.015572861768305302,
                -0.016370778903365135,
                0.001196047174744308,
                -0.011152691207826138,
                0.025973035022616386,
                -0.030401289463043213,
                0.02568582259118557,
                -0.04950224980711937,
                0.0017989915795624256,
                -0.03280161693692207,
                0.007516827434301376,
                -0.02599792554974556,
                0.036690592765808105,
                -0.020295727998018265,
                -0.017459483817219734,
                0.020130762830376625,
                -0.006496071349829435,
                0.10458400845527649,
                0.006481207441538572,
                0.014285916462540627,
                0.02385994791984558,
                1.5260660575222573e-06,
                -0.003795773722231388,
                0.0054000308737158775,
                0.008134066127240658,
                0.010432756505906582,
                0.05485208332538605,
                -0.06686514616012573,
                -0.10204184055328369,
                0.0036116684786975384,
                -0.0023397088516503572,
                -0.04082142934203148,
                -0.04612790793180466,
                0.004530639853328466,
                -0.007621604483574629,
                0.006768463645130396,
                -0.011329405009746552,
                -0.0028338339179754257,
                -0.06258200854063034,
                -0.04976915568113327,
                -0.05701723322272301,
                -0.001788509776815772,
                -0.03528299927711487,
                -0.014884090051054955,
                0.05277051404118538,
                0.054641615599393845,
                0.12943516671657562,
                0.01831716112792492,
                -0.004954962525516748,
                0.03351553529500961,
                0.002764219418168068,
                0.0313669852912426,
                -0.0011054676724597812,
                0.030351152643561363,
                0.012950081378221512,
                0.018249504268169403,
                0.02183975838124752,
                0.0071698566898703575,
                -0.030202535912394524,
                0.01755988970398903,
                0.008647133596241474,
                -0.06260297447443008,
                -0.08416921645402908,
                -0.02478666976094246,
                0.04460950940847397,
                -0.0726168230175972,
                -0.006448777858167887,
                0.008487818762660027,
                -0.04714711382985115,
                -0.0046249134466052055,
                -0.009726939722895622,
                0.02380824089050293,
                0.005982608068734407,
                -0.05204949155449867,
                -0.0007140564266592264,
                -0.020212387666106224,
                0.040498241782188416,
                0.08051156252622604,
                0.01274460181593895,
                -0.012802322395145893,
                -0.023681456223130226,
                -0.004441664554178715,
                0.006967159453779459,
                0.010734141804277897,
                0.05708786100149155,
                0.033058539032936096,
                0.03168615698814392,
                -0.05526890605688095,
                0.07608038932085037,
                0.041953183710575104,
                0.04717983677983284,
                -0.04970192909240723,
                -0.04802108183503151,
                0.02142939902842045,
                0.02655257284641266,
                -0.016784844920039177,
                0.0342942476272583,
                -0.05206773430109024,
                -0.03049389086663723,
                -0.006838445086032152,
                -0.057691629976034164,
                0.042739156633615494,
                0.002884384011849761,
                0.000845875998493284,
                0.008541387505829334,
                0.014045871794223785,
                0.01669762283563614,
                -0.013818839564919472,
                0.00899733416736126,
                -0.011116623878479004,
                -0.019475534558296204,
                0.022868506610393524,
                -0.04447910562157631,
                -0.016064323484897614,
                0.051631491631269455,
                0.049073826521635056,
                0.021373091265559196,
                -0.046520017087459564,
                0.016050439327955246,
                0.014458976686000824,
                -0.0021851202473044395,
                -0.027968894690275192,
                0.025814902037382126,
                -0.030596008524298668,
                -0.00782978069037199,
                -0.08803922683000565,
                -0.035422783344984055,
                0.06529571115970612,
                0.009650962427258492,
                -0.006911347154527903,
                0.022644497454166412,
                0.0032153504434973,
                -0.007556648924946785,
                -0.029227646067738533,
                0.04883650690317154,
                0.04070513695478439,
                -0.019174829125404358,
                0.022256765514612198,
                -0.02201114594936371,
                -0.003617615904659033,
                -0.02201974019408226,
                0.09675238281488419,
                0.061887435615062714,
                -0.0239087026566267,
                0.027015911415219307,
                0.009790641255676746,
                0.04450666159391403,
                -0.03437117487192154,
                -0.0038380722980946302,
                -0.06435472518205643,
                0.03127986565232277,
                0.017014862969517708,
                -0.00588649045675993,
                0.057795263826847076,
                -0.007469324395060539,
                -0.02826358564198017,
                0.029236700385808945,
                -0.007154366932809353,
                -0.011448929086327553,
                -0.018010232597589493,
                0.008105507120490074,
                -0.0004928667331114411,
                -0.04357079043984413,
                0.04986168444156647,
                0.0498196966946125,
                0.08370613306760788,
                0.061555176973342896,
                0.038364097476005554,
                -0.08546754717826843,
                0.02884565480053425,
                -0.04844898357987404,
                -0.011581172235310078,
                -0.03861803933978081,
                0.038508638739585876,
                -0.031941864639520645,
                -0.06296809762716293,
                -0.015456060878932476,
                0.02544156275689602,
                0.011149432510137558,
                0.006478891242295504,
                0.008081282489001751,
                -0.02503592148423195,
                0.05754530802369118,
                -0.030322236940264702,
                0.019076993688941002,
                -0.021818330511450768,
                0.060444436967372894,
                -0.011495150625705719,
                0.02531600557267666,
                -0.03776140138506889,
                -0.09482642263174057,
                -0.034710731357336044,
                -0.040161069482564926,
                -0.01848616451025009,
                -0.0068350424990057945,
                -0.04227708652615547,
                0.010189183987677097,
                0.007477949373424053,
                0.021599056199193,
                0.013256201520562172,
                0.00929764099419117,
                -0.04371500387787819,
                -0.010376717895269394,
                0.002242082729935646,
                0.0003813182411249727,
                0.0186233539134264,
                0.023183932527899742,
                -0.014921453781425953,
                -0.033832892775535583,
                0.0010025616502389312,
                -0.005018186755478382,
                0.012683186680078506,
                -0.03951193764805794,
                0.0372365266084671,
                -0.0021128454245626926,
                -0.05984378606081009,
                0.0064241113141179085,
                0.03642573580145836,
                0.015693137422204018,
                0.054598450660705566,
                -0.041536394506692886,
                -0.02120734192430973,
                0.043521881103515625,
                0.020937107503414154,
                0.06325646489858627,
                0.010134597308933735,
                -0.06589426100254059,
                0.029940208420157433,
                -0.05813760682940483,
                -0.004699959885329008,
                -0.016409486532211304,
                0.0558500699698925,
                -0.04933596029877663,
                0.08026251196861267,
                0.03510558605194092,
                0.01607217825949192,
                -0.02222873643040657,
                -0.03233416751027107,
                -0.07128950953483582,
                0.029086176306009293,
                0.016124429181218147,
                0.0349099226295948,
                -0.03084373101592064,
                0.006524795666337013,
                0.03813577815890312,
                -0.04259209707379341,
                -0.03198007866740227,
                0.05949129909276962,
                -0.07697809487581253,
                -0.0367029532790184,
                -0.0018574049463495612,
                0.007610731292515993,
                -0.03696770593523979,
                -0.0026960207615047693,
                0.032926492393016815,
                0.004532530438154936,
                0.018000677227973938,
                0.03935560584068298,
                0.03926136717200279,
                0.0010677650570869446,
                -0.03113980032503605,
                -0.0172282624989748,
                0.043720100075006485,
                -0.028838688507676125,
                -0.004691871348768473,
                -0.05154898017644882,
                -0.014118454419076443,
                0.038680821657180786,
                0.05825592949986458,
                -0.025706667453050613,
                -0.048150982707738876,
                -0.00013534790195990354,
                0.01079970970749855,
                0.011547240428626537,
                -0.02965368703007698,
                -0.09603798389434814,
                0.045451853424310684,
                0.031365472823381424,
                -0.08260743319988251,
                0.002936853328719735,
                -0.08343686908483505,
                -0.013616731390357018,
                0.034915897995233536,
                0.043206583708524704,
                0.02426091767847538,
                -0.01837962679564953,
                0.05773269385099411,
                -0.0459725484251976,
                0.005566892214119434,
                0.008509209379553795,
                -0.021945489570498466,
                -0.012549924664199352,
                0.02564346045255661,
                0.0412614680826664,
                -0.014378530904650688,
                -0.02867390215396881,
                -0.023848874494433403,
                0.029927819967269897,
                0.025414442643523216,
                0.016422400251030922,
                0.03383529186248779,
                -0.005657176021486521,
                -0.057333655655384064,
                0.048941366374492645,
                -0.02070768177509308,
                0.010487053543329239,
                0.032860442996025085,
                0.040480002760887146,
                0.013286027126014233,
                0.010315182618796825,
                0.014409009367227554,
                0.011084816418588161,
                -0.0013842168264091015,
                -0.024377675727009773,
                -0.04465845972299576,
                0.021282300353050232,
                0.026789862662553787,
                -0.012498685158789158,
                0.012050606310367584,
                -0.041102904826402664,
                0.024168269708752632,
                0.021028833463788033,
                0.010194003582000732,
                -0.002614435041323304,
                -0.041992586106061935,
                -0.009263462387025356,
                0.0051970635540783405,
                0.005009460262954235,
                -0.011877812445163727,
                -0.07479536533355713,
                -0.023585529997944832,
                0.01707303896546364,
                0.042121756821870804,
                0.03421413525938988,
                0.010023226030170918,
                0.021406900137662888,
                -0.0013750864891335368,
                0.04412368685007095,
                -0.00043445895425975323,
                -0.007512614130973816,
                0.013732917606830597,
                -0.009297128766775131,
                0.015274331904947758,
                -0.009028611704707146,
                0.0494898222386837,
                0.014258245006203651,
                0.04148649796843529,
                0.011344997212290764,
                -0.013017451390624046,
                -0.034259095788002014,
                0.020714523270726204,
                -0.0681198462843895,
                0.04231685772538185,
                -0.008128241635859013,
                0.037545353174209595,
                -0.02441110648214817,
                0.0020681440364569426,
                -0.07050132751464844,
                0.010122241452336311,
                -0.04877501726150513,
                -0.059484466910362244,
                0.0022885254584252834,
                0.04245266318321228,
                -0.0002629808441270143,
                0.009002204053103924,
                0.006637911312282085,
                -0.030530942603945732,
                -0.020587878301739693,
                -0.03773653507232666,
                0.01010211557149887,
                -0.0044269259087741375,
                0.029995376244187355,
                0.020381208509206772,
                -0.000442475953605026,
                -0.008384395390748978,
                -0.029843736439943314,
                0.05253282189369202,
                -0.025786293670535088,
                0.04678018018603325,
                0.03868153691291809,
                -0.015139629133045673,
                0.005623879376798868,
                -0.010878288187086582,
                -0.09332887083292007,
                0.009584322571754456,
                0.018259944394230843,
                0.04702623933553696,
                0.03464830666780472,
                -0.0035545637365430593,
                -0.07130865752696991,
                -0.012818980030715466,
                -0.04996998608112335,
                -0.0409122034907341,
                0.028453819453716278,
                -0.0057461378164589405,
                0.009772527031600475,
                0.015935180708765984,
                0.004374299664050341,
                0.021223273128271103,
                0.012251218780875206,
                -0.044130679219961166,
                0.01980729028582573,
                0.03892092779278755,
                -0.009643681347370148,
                0.03308314085006714,
                -0.014003424905240536,
                -0.058162424713373184,
                -0.011708158068358898,
                -0.0017232558457180858,
                0.0401291623711586,
                -0.06358430534601212,
                0.09893971681594849,
                -0.02822357416152954,
                0.010898697189986706,
                0.02282135561108589,
                -0.02661125920712948,
                -0.02912246249616146,
                -0.007471193093806505,
                -0.04076248034834862,
                -0.028446892276406288,
                -0.01449920330196619,
                0.003966901451349258,
                -0.029476625844836235,
                0.02967841736972332,
                0.01804177090525627,
                -0.010058059357106686,
                -0.010950603522360325,
                0.028690874576568604,
                0.04088510200381279,
                0.02212287113070488,
                0.02829110063612461,
                0.014314643107354641,
                0.020820705220103264,
                0.023898625746369362,
                -0.05132638290524483,
                -0.05685523897409439,
                0.007883385755121708,
                0.04842400178313255,
                0.018660439178347588,
                0.036926090717315674,
                -0.026968998834490776,
                -0.039571814239025116,
                0.015022297389805317,
                -0.06828426569700241,
                -0.0413382314145565,
                -0.028797509148716927,
                0.02867446467280388,
                0.07244798541069031,
                0.027562735602259636,
                -0.02009623683989048,
                -0.04307107254862785,
                0.005618450231850147,
                -0.10030980408191681,
                -0.06805195659399033,
                -0.017892783507704735,
                -0.07271198183298111,
                -0.030561359599232674,
                0.03773443400859833,
                0.006918119266629219,
                -0.012992768548429012,
                0.060541074723005295,
                -0.0379171259701252,
                0.0015601018676534295,
                -0.02265215292572975,
                0.006447771564126015,
                -0.06936439871788025,
                -0.0284870695322752,
                0.020329471677541733,
                -0.018365856260061264,
                0.030117040500044823,
                -0.01180580910295248,
                0.0010330160148441792,
                0.04412221163511276,
                0.027743518352508545,
                -0.011396186426281929,
                0.02621683105826378,
                -0.0010386583162471652,
                -0.037733498960733414,
                -0.06957793235778809,
                -0.004691953770816326,
                -0.015147656202316284,
                0.022215286269783974,
                0.09089948982000351,
                0.005674201529473066,
                0.04199744015932083,
                0.03239181265234947,
                -0.0539882630109787,
                -0.03717173635959625,
                0.04513632133603096,
                0.002356057520955801,
                -0.06364365667104721,
                0.013641285710036755,
                0.06182654947042465,
                0.004720640834420919,
                0.0026874111499637365,
                -0.005268576554954052,
                0.0050077238120138645,
                -0.007666009943932295,
                0.0088104959577322,
                0.005080030765384436,
                -0.05123285576701164,
                0.048472415655851364,
                -0.0660441592335701,
                -0.04086020216345787,
                -0.06570548564195633,
                -0.003333479631692171,
                0.028828714042901993,
                0.004727920982986689,
                -0.0058690812438726425,
                0.034159209579229355,
                -0.04699564352631569,
                0.0016020846087485552,
                -0.03766245394945145,
                0.03086434118449688,
                0.05248851701617241,
                -0.03723921626806259,
                -0.01809120550751686,
                -0.004713051952421665,
                -0.01111289020627737,
                0.011625132523477077,
                0.041584715247154236,
                -0.01656392775475979,
                0.07093150168657303,
                0.08992251008749008,
                0.0688483715057373,
                0.00025884233764372766,
                0.004932300187647343,
                -0.01022893562912941,
                0.018213367089629173,
                -0.04195129871368408,
                -0.014249039813876152,
                0.011226777918636799,
                0.044222909957170486,
                0.05351761728525162,
                0.055298637598752975,
                0.034926947206258774,
                0.01482298132032156,
                -0.016607800498604774,
                -0.04047725722193718,
                -0.03954855352640152,
                0.01715260185301304,
                -0.04636148363351822,
                -6.19819436747376e-33,
                0.0069700805470347404,
                -0.001802181825041771,
                0.024997273460030556,
                -0.011754407547414303,
                -0.0521402470767498,
                0.013394531793892384,
                0.01986626163125038,
                0.03441503271460533,
                -0.0494186133146286,
                -0.038484182208776474,
                -0.030913619324564934,
                0.03184785321354866,
                0.003332108026370406,
                0.05280645936727524,
                -0.00637724157422781,
                0.0070566534996032715,
                0.016455428674817085,
                0.02863319218158722,
                -0.01283009722828865,
                -0.05691080167889595,
                -0.06310808658599854,
                0.02720584161579609,
                0.07051239162683487,
                0.021517567336559296,
                0.048523567616939545,
                0.026391858235001564,
                -0.02709105797111988,
                -0.033005475997924805,
                0.022895973175764084,
                -0.02171332575380802,
                -0.013271701522171497,
                0.0056535289622843266,
                0.005855287425220013,
                0.0002883397100958973,
                -0.022334245964884758,
                -0.01315300539135933,
                -0.07391349971294403,
                -0.043467920273542404,
                -0.03480115905404091,
                0.029558850452303886,
                -0.011096702888607979,
                0.0242861770093441,
                -0.00965894479304552,
                -0.02855578064918518,
                -0.021008120849728584,
                -0.05850472301244736,
                -0.03954770788550377,
                0.014197982847690582,
                0.03232305869460106,
                0.06340177357196808,
                -0.058273814618587494,
                -0.01535508967936039,
                -0.0027824740391224623,
                -0.0526052825152874,
                0.03262028470635414,
                -0.0023864093236625195,
                -0.014266034588217735,
                -0.017497925087809563,
                -0.08132537454366684,
                0.02072901278734207,
                0.008029013872146606,
                0.0033597189467400312,
                0.034602344036102295,
                -0.06374260783195496,
                -0.010755308903753757,
                0.029529377818107605,
                -0.011687315069139004,
                0.018866490572690964,
                0.04226185381412506,
                -0.05169868469238281,
                -0.048835910856723785,
                0.04065738245844841,
                -0.02394549548625946,
                -0.016726132482290268,
                -0.013396733440458775,
                -0.016511455178260803,
                -0.04826618358492851,
                0.0065103573724627495,
                0.010330071672797203,
                0.05199872702360153,
                0.051374662667512894,
                0.014094777405261993,
                0.016330167651176453,
                -0.016733169555664062,
                -0.018776187673211098,
                0.022968348115682602,
                0.020169612020254135,
                0.03427952900528908,
                -0.025510352104902267,
                0.018917903304100037,
                0.005201184656471014,
                -0.0012054883409291506,
                -0.00022028501552995294,
                -0.0015846815658733249,
                0.15797938406467438,
                0.07081613689661026,
                -0.018908800557255745,
                -0.04124640300869942,
                -0.0032347238156944513,
                0.009996852837502956,
                -0.031537383794784546,
                0.006875127088278532,
                -0.06057014316320419,
                -0.026206955313682556,
                0.0382915697991848,
                -0.00868587289005518,
                -0.1028866171836853,
                -0.0014691781252622604,
                -0.020372724160552025,
                0.005604289472103119,
                -0.018232539296150208,
                0.011686098761856556,
                0.023611363023519516,
                0.04197865352034569,
                0.004474941175431013,
                -0.010184640064835548,
                -0.02656085044145584,
                -0.04834024980664253,
                0.023401692509651184,
                0.01601945236325264,
                0.00345032662153244,
                0.015721742063760757,
                -0.03301592543721199,
                0.01634870655834675,
                0.017520638182759285,
                -0.006071462761610746,
                0.06335834413766861,
                -0.08525962382555008,
                -0.0049004750326275826,
                -0.009216273203492165,
                -0.01737348735332489,
                0.013530534692108631,
                2.1484834178409074e-07,
                -0.035306744277477264,
                0.040049318224191666,
                0.02029753476381302,
                0.0015967529034242034,
                0.012741894461214542,
                0.039452262222766876,
                -0.020320948213338852,
                0.05378677695989609,
                0.04040788486599922,
                -0.036426275968551636,
                0.028845289722085,
                0.0018815069925040007,
                0.011568691581487656,
                -0.0037023071199655533,
                -0.07050618529319763,
                -0.04072626680135727,
                0.11256399005651474,
                -0.010513721033930779,
                0.018259232863783836,
                -0.0078019797801971436,
                0.05867130309343338,
                0.013309634290635586,
                -0.004680442623794079,
                -0.004275463987141848,
                -0.03660094738006592,
                -0.06747382134199142,
                0.003412452759221196,
                0.015883391723036766,
                0.033054567873477936,
                0.006155951879918575,
                0.003415630431845784,
                0.0012315065832808614,
                0.038980379700660706,
                0.019492896273732185,
                0.003547375090420246,
                -0.017592500895261765,
                0.07114510983228683,
                -0.003775686724111438,
                0.00892586912959814,
                0.04942995309829712,
                -0.033626407384872437,
                -0.013020711950957775,
                0.0387502983212471,
                0.0020523215644061565,
                -0.031162628903985023,
                -0.011877612210810184,
                -0.018726682290434837,
                -0.024280469864606857,
                0.022714955732226372,
                0.00053672946523875,
                -0.009319810196757317,
                0.03456427901983261,
                0.019449612125754356,
                -0.06480830907821655,
                0.02514389343559742,
                0.025169258937239647,
                0.008315397426486015,
                0.04254455864429474,
                -0.0027851848863065243,
                0.0803062915802002,
                -0.003029715735465288,
                -0.012874535284936428,
                -0.01035785861313343,
                0.030972709879279137,
                0.024577444419264793,
                -0.01655040867626667,
                -0.0465460829436779,
                1.0758038202947927e-34,
                0.0011035895440727472,
                -0.040804751217365265,
                0.011979009956121445,
                0.07203703373670578,
                -0.0018181907944381237,
                0.025745069608092308,
                -0.00461278110742569,
                0.019720375537872314,
                0.047924671322107315,
                -0.02601165510714054,
                0.006655726116150618,
                0.03208990395069122,
                0.06187519058585167,
                -0.033957935869693756,
                -0.02028234489262104,
                0.01045618299394846,
                0.01858975924551487,
                -0.012608196586370468,
                -0.028463374823331833,
                -0.10083688050508499,
                0.05331563204526901,
                0.049518734216690063,
                0.035046063363552094,
                -0.026278076693415642,
                0.04499281570315361,
                -0.01669459231197834,
                -0.0766165554523468,
                0.018337495625019073,
                -0.03007485903799534,
                -0.007751181721687317,
                0.014431130141019821,
                0.001312998472712934,
                0.009494315832853317,
                -0.005465133115649223,
                0.02681160904467106,
                -0.03357281535863876,
                -0.0011126691242679954,
                -0.0328974574804306,
                0.0030853666830807924,
                0.03248082846403122,
                -0.026725338771939278,
                0.0028203825931996107,
                0.02342466078698635,
                -0.03421087935566902,
                -0.025930063799023628,
                1.6188499785130261e-06,
                0.0481349378824234,
                0.007736665196716785,
                -0.040277719497680664,
                -0.025562895461916924,
                0.06614158302545547,
                0.010857929475605488,
                -0.0196718480437994,
                0.023414524272084236,
                0.014774019829928875,
                -0.002179560484364629,
                0.001229269546456635,
                0.0506124310195446,
                0.04177908971905708,
                -0.007803361862897873,
                -0.07237352430820465,
                0.026359014213085175,
                0.025475427508354187,
                -0.08939142525196075,
                -0.04713260382413864,
                -0.008149327710270882,
                -0.07104215025901794,
                0.0022029471583664417,
                -0.014129690825939178,
                0.02886030822992325,
                0.04152737185359001,
                -0.0003251821326557547,
                -0.014835312962532043,
                0.00329066370613873,
                0.016459403559565544,
                0.04105055332183838,
                0.009437876753509045,
                0.06486502289772034,
                -0.021058574318885803,
                -0.0009955663699656725,
                -0.03341180086135864,
                0.025474267080426216,
                0.03610611334443092,
                -0.014535283669829369,
                -0.02333618700504303,
                0.001326852012425661,
                -0.0549575537443161,
                -0.008986681699752808,
                -0.004378207493573427,
                -0.009659390896558762,
                0.0005670859245583415,
                -0.028494009748101234,
                0.02739514224231243,
                -0.021296696737408638,
                0.007458865642547607,
                0.016446029767394066,
                0.015550973825156689,
                -0.03943731635808945,
                0.01710379868745804,
                -0.02778109908103943,
                0.04754886403679848,
                0.00734592555090785,
                -0.0139584606513381,
                0.02486604079604149,
                0.03627762943506241,
                -0.052640389651060104,
                -0.008157050237059593,
                0.002101612975820899,
                -0.013848965056240559,
                0.02340875379741192,
                -0.004960120655596256,
                -0.0376274399459362,
                0.01027617882937193,
                0.012589155696332455,
                0.05053647607564926,
                0.07906399667263031,
                0.04246358573436737,
                -0.03765489533543587,
                -0.035069458186626434,
                -0.03505373373627663,
                0.005048596765846014,
                -0.060440339148044586,
                0.010660831816494465,
                -0.01702333614230156,
                0.04485201835632324,
                0.0040457285940647125,
                0.01246770191937685,
                -0.04728653281927109,
                0.030319031327962875,
                4.619492756319232e-05,
                0.03352048620581627,
                0.040645960718393326,
                0.021410075947642326,
                -0.04265008121728897,
                0.01449158601462841,
                -0.04215133935213089,
                -0.008729378692805767,
                -0.051169101148843765,
                0.017516251653432846,
                -0.06499838829040527,
                0.07334113866090775,
                0.024316953495144844,
                -0.03740505874156952,
                -0.010935399681329727,
                -0.0017754699802026153,
                -0.008750551380217075,
                0.03963710367679596,
                -0.005113689694553614,
                0.015407465398311615,
                -0.06250516325235367,
                -0.002340370789170265,
                0.025970689952373505,
                0.020397672429680824,
                -0.07091300934553146,
                -0.025478340685367584,
                -0.039433542639017105,
                -0.02791212871670723,
                -0.08791300654411316,
                0.03244173899292946,
                0.010494432412087917,
                -0.008606615476310253,
                -0.01108805276453495,
                -0.026348570361733437,
                0.0032997371163219213,
                0.02923842892050743,
                0.05809006467461586,
                0.07125724852085114,
                -0.015773704275488853,
                -0.012094917707145214,
                -0.019805606454610825,
                0.04231508821249008,
                -0.01120375469326973,
                0.0425398126244545,
                0.07270161807537079,
                0.04446524381637573,
                -0.05319051444530487,
                0.003275379305705428,
                0.08606723695993423,
                -0.011635130271315575,
                -0.012365027330815792,
                -0.04815021902322769,
                -0.08658202737569809,
                0.040572620928287506,
                0.011457199230790138,
                0.09409988671541214,
                0.044868215918540955,
                -0.07586777210235596,
                0.040171995759010315,
                -0.01387398224323988,
                -0.06487317383289337,
                0.009271028451621532,
                -0.026707753539085388,
                -0.07976903766393661,
                0.032165154814720154,
                0.08330924808979034,
                0.06508784741163254,
                0.012809433043003082,
                -0.05661197379231453,
                0.02159484662115574,
                -0.0428452342748642,
                0.002965444466099143,
                0.021510504186153412,
                -0.026458872482180595,
                -0.09469558298587799,
                -0.0061955624260008335,
                0.027799034491181374,
                -0.006169733591377735,
                0.015055738389492035,
                -0.017696449533104897,
                -0.023975493386387825,
                0.017691656947135925,
                0.0014349832199513912,
                0.02082582749426365,
                0.02499569021165371,
                0.0016688610194250941,
                -0.04762475565075874,
                -0.08561155200004578,
                -0.051220815628767014,
                -0.001639181631617248,
                -0.060854990035295486,
                -0.0359518863260746,
                0.027073487639427185,
                -0.01789778470993042,
                -0.0036183062475174665,
                0.04557343199849129,
                -0.0190154816955328,
                0.043245088309049606,
                -0.001039331778883934,
                0.010260420851409435,
                -0.051386747509241104,
                0.03226708993315697,
                -0.03088502585887909,
                0.010440931655466557,
                0.013390600681304932,
                0.02788827195763588,
                0.010765588842332363,
                -0.03156760707497597,
                0.021942149847745895,
                -0.014106826856732368,
                0.018409602344036102,
                0.005541260354220867,
                0.021742982789874077,
                0.006801282055675983,
                0.054584018886089325,
                -0.03338349610567093,
                -0.01883942075073719,
                -0.008016201667487621,
                0.0649528056383133,
                0.042243264615535736,
                0.00272623379714787,
                -0.04431147500872612,
                -0.021241653710603714,
                -0.07740683853626251,
                -0.04580696299672127,
                -0.014613903127610683,
                0.066911980509758,
                0.01774921640753746,
                0.008431977592408657,
                0.025250902399420738,
                0.03736865147948265,
                -0.04562128335237503,
                -0.04781639575958252,
                -0.04243797808885574,
                0.0360802486538887,
                0.019917864352464676,
                0.016182631254196167,
                0.07044365257024765,
                -0.06826318800449371,
                0.012536733411252499,
                -0.020833538845181465,
                -0.0035467962734401226,
                0.07014694809913635,
                0.012572251260280609,
                -0.0023792805150151253,
                -0.023373600095510483,
                0.024487784132361412,
                -0.017939167097210884,
                -0.018838083371520042,
                -0.024105912074446678,
                -0.053793199360370636,
                0.01819700561463833,
                0.011940224096179008,
                -0.008542927913367748,
                0.024392658844590187,
                -0.018336383625864983,
                -0.048880547285079956,
                0.0016523398226127028,
                -0.026773253455758095,
                0.03667958080768585,
                -0.02598920464515686,
                0.021548504009842873,
                0.015564091503620148,
                0.01887640729546547,
                -0.06281020492315292,
                0.03520873561501503,
                0.021395083516836166,
                -0.010491868481040001,
                0.017458777874708176,
                -0.07976725697517395,
                -0.04739035293459892,
                0.022630475461483,
                -0.032766666263341904,
                -0.06921432912349701,
                0.035192664712667465,
                0.02413882128894329,
                -0.025476617738604546,
                -0.0032878059428185225,
                0.060035884380340576,
                -0.07879284024238586,
                -0.02534930221736431,
                0.013055738992989063,
                0.01510667521506548,
                -0.04479428380727768,
                0.023537542670965195,
                -0.04534991458058357,
                0.016122836619615555,
                0.010075419209897518,
                0.06572342664003372,
                0.015683025121688843,
                -0.0116981016471982,
                0.0006045311456546187,
                0.028536537662148476,
                0.03340316563844681,
                0.018637806177139282,
                0.05668367072939873,
                -0.03120187483727932,
                0.05251002311706543,
                0.0028838959988206625,
                -0.008729325607419014,
                0.032442279160022736,
                0.06518550217151642,
                -0.017443211749196053,
                0.015921136364340782,
                -0.003489085705950856,
                0.011221285909414291,
                0.026404349133372307,
                0.015351473353803158,
                -0.05357914790511131,
                -0.03961214795708656,
                0.0541045106947422,
                -0.03432970866560936,
                -0.014056125655770302,
                -0.035513442009687424,
                -0.028187820687890053,
                -0.022480757907032967,
                0.0026878167409449816,
                -0.014175615273416042,
                0.004432894755154848,
                -0.04462350904941559,
                -0.030063116922974586,
                0.005682086106389761,
                -0.021673325449228287,
                -0.019065886735916138,
                -0.04026348516345024,
                0.007182043511420488,
                -0.03583916276693344,
                0.01453458797186613,
                0.020068982616066933,
                0.06485619395971298,
                -0.003765863599255681,
                0.02687539905309677,
                0.03842553868889809,
                0.01611078344285488,
                -0.010808569379150867,
                -0.006687352433800697,
                0.00011804432870121673,
                -0.043246038258075714,
                -0.06306514143943787,
                0.003976167645305395,
                -0.0021474298555403948,
                -0.023565536364912987,
                -0.07313240319490433,
                -0.010487128980457783,
                0.04743654653429985,
                -0.029853202402591705,
                -0.023612452670931816,
                -0.017249951139092445,
                0.030375590547919273,
                0.019182216376066208,
                0.02692795731127262,
                0.0220890324562788,
                -0.044896841049194336,
                0.011935306712985039,
                0.006669218186289072,
                -0.00816288497298956,
                0.028048064559698105,
                0.0022477207239717245,
                0.008900834247469902,
                -0.009098327718675137,
                -0.04157411679625511,
                0.005249375943094492,
                0.00026583511498756707,
                0.022044610232114792,
                0.019007958471775055,
                -0.030355727300047874,
                -0.0038499110378324986,
                0.012819617055356503,
                -0.024790218099951744,
                -0.005804451182484627,
                0.06471829861402512,
                0.021019039675593376,
                -0.02534462697803974,
                0.003423877526074648,
                0.006086477544158697,
                0.033601969480514526,
                0.03768696263432503,
                -0.03550628200173378,
                -0.06588364392518997,
                0.011284738779067993,
                0.0450906828045845,
                -0.00355461030267179,
                0.003028011880815029,
                -0.012440393678843975,
                0.03231918066740036,
                -0.008109715767204762,
                -0.04561762139201164,
                -0.055519949644804,
                0.08596912026405334,
                0.006486996542662382,
                -0.0056798397563397884,
                0.01734462007880211,
                -0.012870199047029018,
                0.011075001209974289,
                -0.0157296285033226,
                -0.013922193087637424,
                0.04533941671252251,
                0.004672557581216097,
                -0.0364333875477314,
                0.022885244339704514,
                -0.04902627691626549,
                -0.04624570533633232,
                0.01805914007127285,
                0.048080507665872574,
                0.024172717705368996,
                -0.036997899413108826,
                0.06061722710728645,
                -0.007688992191106081,
                -0.00767663074657321,
                0.02907201275229454,
                -0.013156705535948277,
                -0.010987523943185806,
                -0.032444264739751816,
                0.017381714656949043,
                -0.0033689767587929964,
                0.051930539309978485,
                0.055339664220809937,
                0.05642728507518768,
                0.006968607660382986,
                -0.019806986674666405,
                0.0893481895327568,
                0.0019006725633516908,
                0.013196422718465328,
                0.09992476552724838,
                0.052002761512994766,
                0.02391895465552807,
                -0.008022070862352848,
                0.026253538206219673,
                0.0014587721088901162,
                -0.02504795975983143,
                -0.11165529489517212,
                0.07154464721679688,
                0.035397548228502274,
                0.007495129480957985,
                -0.03778716176748276,
                -0.022537117823958397,
                -0.004786525387316942,
                0.022658657282590866,
                -0.03420066833496094,
                -0.05252423882484436,
                0.010848330333828926,
                -0.03013613261282444,
                0.06678637117147446,
                0.02879154495894909,
                -0.11949280649423599,
                0.0017202653689309955,
                -0.027221666648983955,
                -0.036213457584381104,
                -0.04383708909153938,
                0.012198150157928467,
                0.010660061612725258,
                -0.043194256722927094,
                0.05244578421115875,
                -0.03007497638463974,
                -0.018892107531428337,
                0.007015611510723829,
                0.023828735575079918,
                0.00932789221405983,
                0.03900428116321564,
                -0.06713448464870453,
                -0.0037923746276646852,
                0.002559267682954669,
                -0.011990874074399471,
                0.030987003818154335,
                -0.004278199747204781,
                -0.08088298887014389,
                -0.017404887825250626,
                0.0020667766220867634,
                0.02685476280748844,
                -0.0035527674481272697,
                -0.0015452944207936525,
                -0.0005717036547139287,
                0.0117404256016016,
                -0.01587965153157711,
                0.01438447367399931,
                0.006761892233043909,
                0.007735620252788067,
                -0.01636294089257717,
                0.034440185874700546,
                -0.00965727400034666,
                0.039973724633455276,
                -0.04874696210026741,
                0.008966405875980854,
                0.04138055443763733,
                -0.01618286408483982,
                -0.049418941140174866,
                0.0020089754834771156,
                -0.017978079617023468,
                -0.006600161083042622,
                0.008763769641518593,
                -0.03276795148849487,
                0.011085518635809422,
                -0.0145194036886096,
                -0.029460875317454338,
                0.030200297012925148,
                -0.08226223289966583,
                0.06653930246829987,
                0.04606220871210098,
                0.012544872239232063,
                0.04267186298966408,
                -0.02284502424299717,
                -0.03974508121609688,
                0.023163817822933197,
                -0.027218418195843697,
                0.0013504724483937025,
                -0.0218153428286314,
                0.05729028582572937,
                -0.017108609899878502,
                0.02740415558218956,
                -0.020335279405117035,
                -0.021483322605490685,
                0.023842262104153633,
                -0.0032622963190078735,
                -0.06152493506669998,
                -0.11012791842222214,
                0.010893156751990318,
                -0.008346738293766975,
                0.08030878007411957,
                0.004160693846642971,
                0.05471495911478996,
                -0.02648613229393959,
                0.002679153112694621,
                -0.050316065549850464,
                0.05267136171460152,
                0.007847779430449009,
                -0.0015847011236473918,
                0.014018921181559563,
                -0.06598485261201859,
                -0.02351682260632515,
                -0.007140382658690214,
                0.010879343375563622,
                0.032408151775598526,
                -0.010992305353283882,
                -0.03648783266544342,
                0.0010503977537155151,
                0.02866598218679428,
                -0.027972163632512093,
                -5.683616205271534e-33,
                -0.05298333615064621,
                -0.0688483789563179,
                0.03901948407292366,
                0.029863623902201653,
                0.009182602167129517,
                0.0015202785143628716,
                0.024961672723293304,
                0.04113603010773659,
                -0.01216693501919508,
                -0.05290563404560089,
                -0.001556790666654706,
                0.004131094552576542,
                0.02193627879023552,
                -0.008446070365607738,
                0.10536891222000122,
                0.04360052943229675,
                0.05317624285817146,
                0.023999571800231934,
                0.02665325254201889,
                -0.038025062531232834,
                -0.0232936330139637,
                0.022868050262331963,
                0.03162243589758873,
                0.06746840476989746,
                -0.014119207859039307,
                0.012679269537329674,
                0.020421721041202545,
                0.023443952202796936,
                0.0699121430516243,
                0.006362713873386383,
                0.00874320324510336,
                -0.04861536622047424,
                0.005716544575989246,
                -0.012018447741866112,
                -0.0006204135715961456,
                0.039036825299263,
                -0.008112738840281963,
                -0.023955106735229492,
                -0.03183998167514801,
                -0.016718586906790733,
                0.02718801237642765,
                0.022625381126999855,
                -0.015232361853122711,
                0.015149010345339775,
                -0.018174713477492332,
                -0.002921925624832511,
                0.009503892622888088,
                -0.0016216172371059656,
                0.00025291703059338033,
                0.06089138984680176,
                -0.04452301189303398,
                0.009181701578199863,
                -0.018199682235717773,
                0.03272561356425285,
                0.022421590983867645,
                -0.008497750386595726,
                -0.01080982107669115,
                0.04400359094142914,
                0.008268051780760288,
                0.010663234628736973,
                -0.07613397389650345,
                0.012673260644078255,
                0.0016278792172670364,
                0.006986299064010382,
                -0.04050757363438606,
                0.0029265566263347864,
                -0.044312044978141785,
                0.04325136914849281,
                -0.02874043583869934,
                -0.033437762409448624,
                -0.04565020278096199,
                0.08388998359441757,
                0.027731552720069885,
                -0.03751244768500328,
                -0.08409304171800613,
                0.010290783829987049,
                0.0035743515472859144,
                0.006550181191414595,
                0.07556717842817307,
                0.03304038196802139,
                -0.017003480345010757,
                0.07438560575246811,
                0.03386974334716797,
                -0.00885019265115261,
                0.017095545306801796,
                -0.06957782059907913,
                -0.01700502261519432,
                0.07555899769067764,
                0.006883630994707346,
                0.02523934841156006,
                -0.03240320459008217,
                0.053565338253974915,
                0.010533110238611698,
                0.02294366993010044,
                0.03587792441248894,
                0.03026638552546501,
                -0.016939710825681686,
                0.02424035780131817,
                -0.025388101115822792,
                0.028210468590259552,
                -0.05083364620804787,
                0.0351799875497818,
                -0.06888963282108307,
                -0.004754296038299799,
                -0.011278503574430943,
                -0.005466071888804436,
                -0.039434220641851425,
                -0.05520511046051979,
                -0.00544803636148572,
                0.0366014800965786,
                0.012286779470741749,
                -0.005768319591879845,
                -0.041899777948856354,
                -0.03406507894396782,
                -0.025926128029823303,
                -0.046543918550014496,
                0.023737406358122826,
                -0.03423314169049263,
                0.003670712234452367,
                -0.04141538217663765,
                -0.028419509530067444,
                0.017367471009492874,
                -0.027355602011084557,
                -0.022332211956381798,
                0.014980842359364033,
                -0.04135431349277496,
                0.006670400500297546,
                0.019313303753733635,
                0.04674111679196358,
                -0.01393807865679264,
                -0.01284155435860157,
                0.020780909806489944,
                2.2358189255555772e-07,
                -0.00642681960016489,
                0.03819578140974045,
                0.040856897830963135,
                0.04203781485557556,
                0.021065838634967804,
                0.03403599187731743,
                -0.022078268229961395,
                -0.031434327363967896,
                -0.017998313531279564,
                0.006782860960811377,
                0.049865201115608215,
                -0.029474565759301186,
                -0.001676215440966189,
                0.03722574561834335,
                0.04511256143450737,
                -0.12294664978981018,
                -0.014730163849890232,
                -0.008261250331997871,
                -0.06514047831296921,
                -0.03310244902968407,
                0.006858536507934332,
                0.03329465538263321,
                0.05817826837301254,
                -0.00011494427599245682,
                -0.005012588109821081,
                -0.016877438873052597,
                -0.010617809370160103,
                0.01905280165374279,
                0.03127400949597359,
                0.02953376993536949,
                0.02809394896030426,
                -0.01801225170493126,
                -0.039969246834516525,
                0.03358609601855278,
                0.03840290382504463,
                -0.08022067695856094,
                0.0517033189535141,
                0.022783836349844933,
                0.025440439581871033,
                0.02000313624739647,
                0.012695005163550377,
                -0.021539079025387764,
                -0.03278366103768349,
                0.05888742208480835,
                -0.06474548578262329,
                0.0025522587820887566,
                -0.0021004751324653625,
                0.06127573549747467,
                0.028266219422221184,
                -0.0023947099689394236,
                0.03980524092912674,
                0.042772747576236725,
                0.0537005178630352,
                0.00883602723479271,
                -0.013674202375113964,
                -0.029283246025443077,
                -0.04297828674316406,
                0.010521719232201576,
                0.054890040308237076,
                0.029747098684310913,
                -0.04167797788977623,
                -0.007549756206572056,
                -0.018549760803580284,
                0.008701415732502937,
                -0.003312797052785754,
                0.03573498874902725,
                -0.008067155256867409,
                4.942453241753658e-35,
                -0.030651070177555084,
                -0.05562421306967735,
                -0.05893056094646454,
                -0.04055287688970566,
                -0.03378883749246597,
                0.013660525903105736,
                -0.08730491995811462,
                -0.0156480073928833,
                -0.010841675102710724,
                0.018977338448166847,
                -0.025762176141142845,
                -0.059806130826473236,
                -0.022342348471283913,
                -0.004007982090115547,
                -0.01830482855439186,
                0.03595764562487602,
                0.027725283056497574,
                -0.0033059068955481052,
                -0.01633913628757,
                0.0007979892543517053,
                -0.007945884019136429,
                0.028525104746222496,
                -0.010749301873147488,
                0.016530295833945274,
                0.08934899419546127,
                0.032652873545885086,
                0.019971953704953194,
                -0.005981096066534519,
                0.036972012370824814,
                -0.1202266737818718,
                -0.03452547639608383,
                -0.015077321790158749,
                0.010646755807101727,
                -0.025192199274897575,
                -0.026940498501062393,
                -0.021475447341799736,
                -0.029855599626898766,
                0.018905773758888245,
                0.0025613978505134583,
                0.0006145226070657372,
                -0.04016221687197685,
                -0.0009101115865632892,
                0.05360371991991997,
                0.012487303465604782,
                0.016406424343585968,
                1.6106553175632143e-06,
                -0.03431549668312073,
                -0.019826417788863182,
                -0.026608118787407875,
                -0.08087897300720215,
                0.014230770990252495,
                -0.004824592266231775,
                0.01920314133167267,
                0.03983273729681969,
                0.0251156073063612,
                -0.04123997688293457,
                -0.019408250227570534,
                0.03741402179002762,
                0.04090815782546997,
                0.060565344989299774,
                -0.0051709734834730625,
                -0.012009273283183575,
                0.0325128436088562,
                -0.04102224484086037,
                0.013413495384156704,
                -0.09293641895055771,
                -0.02402673289179802,
                -0.0273294597864151,
                0.013884049840271473,
                0.038582827895879745,
                0.03397392854094505,
                -0.0019502309150993824,
                -0.023269688710570335,
                0.015956396237015724,
                0.04575832188129425,
                0.009087281301617622,
                0.023676490411162376,
                0.07027920335531235,
                -0.06345991790294647,
                -0.012211950495839119,
                -0.034656211733818054,
                0.02493532933294773,
                -0.03175484016537666,
                0.05557510629296303,
                0.02284107729792595,
                -0.05395796522498131,
                -0.08066216111183167,
                0.010843179188668728,
                -0.016915585845708847,
                -0.04313821345567703,
                -0.0025438216980546713,
                -0.01883181743323803,
                0.0010596137726679444,
                0.003394055413082242,
                -0.018282199278473854,
                -0.014031652361154556,
                0.1128096953034401,
                0.020511075854301453,
                -0.0061422716826200485,
                -0.017288774251937866,
                0.10420390218496323,
                0.00996279064565897,
                -0.06599188596010208,
                -0.019314225763082504,
                0.0198019091039896,
                -0.06432537734508514,
                -0.0031450092792510986,
                0.0081886425614357,
                0.018738869577646255,
                0.008301152847707272,
                0.05747859552502632,
                -0.043397754430770874,
                0.015437910333275795,
                0.015172125771641731,
                0.017332419753074646,
                -0.016238946467638016,
                -0.0013618539087474346,
                -0.01767202652990818,
                -0.001954419305548072,
                -0.04690789431333542,
                0.04639740660786629,
                0.008239256218075752,
                0.003178982064127922,
                -0.0349794402718544,
                0.017390243709087372,
                0.005996570456773043,
                -0.0006677693454548717,
                0.03785264119505882,
                0.0625445693731308,
                -0.008465525694191456,
                -0.03248359262943268,
                0.07714639604091644,
                0.022280143573880196,
                0.003084515919908881,
                0.010845217853784561,
                0.042606938630342484,
                -0.05845598876476288,
                0.0035418125335127115,
                -0.020558973774313927,
                -0.004645823035389185,
                -0.09256170690059662,
                -0.024568801745772362,
                -0.049220308661460876,
                0.01632142998278141,
                -0.003603267017751932,
                -0.027380404993891716,
                -0.00032377507886849344,
                -0.004074462223798037,
                -0.022234154865145683,
                -0.0391002781689167,
                -0.030176565051078796,
                0.10611941665410995,
                -0.022166291251778603,
                -0.015536084771156311,
                0.027529669925570488,
                -0.006809612736105919,
                -0.0025201987009495497,
                0.032422542572021484,
                0.08481273800134659,
                -0.007874272763729095,
                0.022471042349934578,
                0.03689614683389664,
                0.025197818875312805,
                -0.008223867043852806,
                0.017146654427051544,
                -0.06863243132829666,
                -0.003718878608196974,
                0.014732751995325089,
                -0.013306403532624245,
                -0.05106722563505173,
                -0.03827931731939316,
                0.018346300348639488,
                -0.009550926275551319,
                -0.03184213489294052,
                0.012885881587862968,
                0.0061278739012777805,
                0.08498508483171463,
                0.04373784363269806,
                0.008302359841763973,
                0.0045506940223276615,
                -0.0694146528840065,
                -0.02004304900765419,
                -0.06368350237607956,
                0.007511105388402939,
                0.07405553758144379,
                -0.04693807661533356,
                -0.09118964523077011,
                0.03319169953465462,
                -0.02325199358165264,
                0.03983447700738907,
                0.03845638409256935,
                -0.05366208404302597,
                -0.056071240454912186,
                -0.06119266152381897,
                0.02038740925490856,
                -0.09073423594236374,
                -0.007622465491294861,
                -0.06599581241607666,
                0.04533685743808746,
                -0.01943005621433258,
                0.01088771503418684,
                -0.01254377793520689,
                -0.02253803238272667,
                -0.0294649675488472,
                0.03869759663939476,
                -0.012705822475254536,
                0.04572082310914993,
                -0.043132588267326355,
                -0.00044638424878939986,
                0.028349192813038826,
                0.04170709848403931,
                0.02365054003894329,
                -0.05544213205575943,
                -0.02210250124335289,
                0.04082725942134857,
                0.002506993943825364,
                -0.011805655434727669,
                -0.052893735468387604,
                0.03153597190976143,
                0.023234805092215538,
                -0.023738451302051544,
                0.006197143811732531,
                0.010325606912374496,
                0.03522597625851631,
                0.017773671075701714,
                -0.027602871879935265,
                0.03549504280090332,
                -0.024604104459285736,
                -0.009614299982786179,
                -0.030328208580613136,
                0.041844457387924194,
                -0.06624194979667664,
                0.05195830389857292,
                0.023220323026180267,
                0.03191449120640755,
                -0.01152761746197939,
                -0.052663885056972504,
                -0.016289575025439262,
                0.04158279299736023,
                -0.02129080332815647,
                -0.021115344017744064,
                -0.03358052298426628,
                -0.007619593758136034,
                0.008836577646434307,
                0.061384573578834534,
                0.045484934002161026,
                0.013316458091139793,
                -0.016139455139636993,
                0.02327818237245083,
                0.030593615025281906,
                -0.0041567422449588776,
                -0.01149560883641243,
                0.012094363570213318,
                -0.020417606458067894,
                -0.0053669242188334465,
                0.03306659311056137,
                -0.008784901350736618,
                -0.01850942149758339,
                0.02528226561844349,
                -0.005545610096305609,
                0.0272777508944273,
                0.021587107330560684,
                -0.036614883691072464,
                -0.003790546441450715,
                0.011281360872089863,
                -0.002143917605280876,
                0.06019427627325058,
                0.028529295697808266,
                0.04727049916982651,
                0.01801919937133789,
                -0.039583850651979446,
                -0.0164431631565094,
                0.003222011961042881,
                0.012221028096973896,
                -0.011573323048651218,
                0.03685162216424942,
                -0.016591595485806465,
                0.07805570960044861,
                -0.09191720932722092,
                -0.009629991836845875,
                -0.005915011744946241,
                -0.03989225998520851,
                0.034217383712530136,
                0.007901550270617008,
                -0.012079276144504547,
                -0.028850466012954712,
                0.002138506853953004,
                0.011054830625653267,
                0.01720084808766842,
                -0.003635524306446314,
                -0.03667260706424713,
                0.013716459274291992,
                0.017788982018828392,
                0.01656116545200348,
                -0.0011220354354009032,
                0.039711255580186844,
                0.032887667417526245,
                0.04930071160197258,
                0.03511735051870346,
                -0.06639616936445236,
                0.042261723428964615,
                0.003074849024415016,
                -0.014853986911475658,
                0.034192584455013275,
                -0.06157218664884567,
                -0.020434914156794548,
                -0.008601236157119274,
                0.00848594680428505,
                0.07864395529031754,
                0.019899601116776466,
                -0.04091327637434006,
                -0.022422555834054947,
                -0.012056877836585045,
                -0.03883177787065506,
                -0.02152170054614544,
                -0.03745010495185852,
                -0.008072883822023869,
                -0.006083942484110594,
                0.04356925189495087,
                -0.005913528613746166,
                0.03783614933490753,
                -0.021865278482437134,
                -0.0010449854889884591,
                -0.03743340075016022,
                -0.022730184718966484,
                -0.04252897948026657,
                -0.02120419219136238,
                -0.016701238229870796,
                0.008369917050004005,
                -0.04132548347115517,
                0.0720093697309494,
                0.009016626514494419,
                0.0018562368350103498,
                0.015380299650132656,
                0.002760141622275114,
                -0.00510337995365262,
                -0.03842758759856224,
                -0.002027014037594199,
                0.004681793507188559,
                -0.02936938963830471,
                0.030130550265312195,
                0.011882826685905457,
                0.0067560141906142235,
                0.020960113033652306,
                -0.03081458993256092,
                0.06021841987967491,
                -0.07698798924684525,
                0.030704258009791374,
                -0.03829897567629814,
                -0.01809990219771862,
                6.652429146924987e-05,
                -0.0009166467352770269,
                -0.013616879470646381,
                -0.013661408796906471,
                -0.07162962853908539,
                0.0032920408993959427,
                -0.038937196135520935,
                0.04772554337978363,
                0.017206329852342606,
                0.047671716660261154,
                -0.0339377224445343,
                -0.015784963965415955,
                0.046917278319597244,
                -0.02710176259279251,
                0.0371536985039711,
                -0.00364039558917284,
                0.023088833317160606,
                -0.07267920672893524,
                -0.014767193235456944,
                0.020715905353426933,
                -0.05134250596165657,
                0.0017133407527580857,
                -0.05566263571381569,
                -0.002834178740158677,
                -0.11460940539836884,
                -0.049043137580156326,
                0.004390873480588198,
                0.03390700742602348,
                -0.0012391551863402128,
                -0.01718548685312271,
                0.030763527378439903,
                -0.05041711777448654,
                0.055818330496549606,
                -0.03874887526035309,
                -0.018630098551511765,
                -0.004406111780554056,
                -0.0048340484499931335,
                -0.009773661382496357,
                0.06755205988883972,
                0.024608703330159187,
                -0.002091427333652973,
                -0.002506765304133296,
                -0.010168340988457203,
                0.059312429279088974,
                0.05730435997247696,
                0.028761129826307297,
                -0.014588229358196259,
                -0.022002924233675003,
                -0.040562305599451065,
                0.01791156455874443,
                -0.006249502766877413,
                -0.023989442735910416,
                -0.05622660368680954,
                -0.005305030383169651,
                0.05324210599064827,
                -0.01853172294795513,
                -0.022263575345277786,
                -0.0928066074848175,
                0.008525548502802849,
                -0.005328123923391104,
                0.04361666366457939,
                0.010329902172088623,
                0.01985914073884487,
                -0.05188612639904022,
                0.007234629243612289,
                -0.021476417779922485,
                0.04376433044672012,
                0.007342585362493992,
                -0.028373414650559425,
                0.0016035927692428231,
                0.0439717061817646,
                0.010906510055065155,
                0.005522360559552908,
                0.022584762424230576,
                0.0003199286002200097,
                -0.02709135413169861,
                0.018838653340935707,
                -0.004154620226472616,
                0.005622273776680231,
                -0.018051277846097946,
                -0.017401978373527527,
                -0.049091652035713196,
                0.01362951472401619,
                -0.013079839758574963,
                -0.0455755852162838,
                0.0018861365970224142,
                -0.0007548099965788424,
                0.09009863436222076,
                0.08479394018650055,
                -0.010916238650679588,
                -0.010592969134449959,
                -0.04415866732597351,
                -0.004074790980666876,
                0.009502575732767582,
                0.03738218918442726,
                -0.03915901109576225,
                -0.061228107661008835,
                0.018405206501483917,
                -0.01839427649974823,
                -0.005963594187051058,
                -0.011534464545547962,
                -0.03625115007162094,
                -0.008836507797241211,
                0.11449602991342545,
                0.045985378324985504,
                -0.03454793244600296,
                0.04756748303771019,
                0.005931036081165075,
                -0.038089897483587265,
                0.023574355989694595,
                -0.019484004005789757,
                0.008732568472623825,
                -0.10020876675844193,
                -0.02922576665878296,
                0.013974936679005623,
                -0.0711950957775116,
                -0.04610263556241989,
                -0.035999249666929245,
                0.0016529778949916363,
                0.000389101478504017,
                0.14675666391849518,
                0.03226694092154503,
                0.04242970049381256,
                -0.05850754305720329,
                -0.04292744770646095,
                0.025807781144976616,
                -0.008394562639296055,
                0.01004142314195633,
                -0.055615417659282684,
                0.012606617994606495,
                -0.01481619756668806,
                -0.0019909401889890432,
                -0.005152761470526457,
                -0.007244388572871685,
                -0.0036345887929201126,
                0.07554346323013306,
                -0.006260479800403118,
                -0.034915223717689514,
                -0.016040826216340065,
                -0.010170628316700459,
                0.01784101501107216,
                0.06195138394832611,
                0.04597219079732895,
                -0.022179922088980675,
                0.010961025021970272,
                -0.011947331950068474,
                -0.00497187627479434,
                0.07792840152978897,
                -0.02363157831132412,
                -0.01785753108561039,
                0.03568057715892792,
                -0.027544138953089714,
                -0.010854835622012615,
                -0.023120226338505745,
                0.009031391702592373,
                0.014371574856340885,
                0.014284773729741573,
                0.03340405598282814,
                -0.04188745468854904,
                0.0424397774040699,
                -0.02101706527173519,
                0.031305741518735886,
                0.0525551438331604,
                0.026310091838240623,
                0.047785624861717224,
                -0.014234536327421665,
                -0.02549002878367901,
                0.013501749373972416,
                -0.043261244893074036,
                0.007179588545113802,
                -0.005927379243075848,
                -0.0376896969974041,
                0.016108835116028786,
                -0.021194592118263245,
                0.004750151187181473,
                0.023962708190083504,
                -0.022438382729887962,
                -0.03699760138988495,
                0.03401894494891167,
                0.006327664013952017,
                -0.013219209387898445,
                0.062376100569963455,
                -0.00610930472612381,
                -0.01910615898668766,
                -0.02261330746114254,
                0.05011143162846565,
                -0.008327264338731766,
                0.02243081107735634,
                0.029745539650321007,
                -0.02095819264650345,
                0.02077825367450714,
                -0.027062105014920235,
                0.00553071079775691,
                0.026232294738292694,
                0.016321519389748573,
                -0.04726124182343483,
                -0.03449558839201927,
                0.01213734783232212,
                0.031565479934215546,
                -0.008119363337755203,
                0.026845678687095642,
                -0.006566101219505072,
                0.02561567910015583,
                -0.01613975688815117,
                0.02331228367984295,
                -0.016194703057408333,
                -0.0375857837498188,
                0.09631238877773285,
                -0.06654335558414459,
                0.004680196288973093,
                0.0440128818154335,
                0.032268501818180084,
                0.010060790926218033,
                0.030228804796934128,
                0.020845994353294373,
                0.03801408037543297,
                -6.143576491830741e-33,
                -0.03475251793861389,
                -0.011117776855826378,
                0.005800054874271154,
                0.0358913317322731,
                -0.011771111749112606,
                0.017368268221616745,
                0.017075594514608383,
                0.0002936258679255843,
                -0.011990176513791084,
                -0.03257538378238678,
                0.008507194928824902,
                0.006654640194028616,
                0.003124329261481762,
                -0.005439889617264271,
                -0.013169863261282444,
                -0.017144346609711647,
                0.03667159005999565,
                -0.009262834675610065,
                -0.02769891545176506,
                0.024612724781036377,
                -0.018281202763319016,
                0.016380371525883675,
                -0.04904403164982796,
                0.017272500321269035,
                -0.0013658764073625207,
                -0.021589182317256927,
                -0.004620980937033892,
                0.03632565960288048,
                -0.0615963451564312,
                -0.015481416136026382,
                0.0032749823294579983,
                0.002471777843311429,
                0.00548796309158206,
                0.011915542185306549,
                -0.03064669668674469,
                0.0902368426322937,
                -0.03600528836250305,
                -0.017229482531547546,
                0.02889031358063221,
                0.02482609637081623,
                -0.05052686855196953,
                -0.015683799982070923,
                0.012777692638337612,
                -0.008694290183484554,
                -0.03639467805624008,
                -0.018780864775180817,
                0.015864986926317215,
                0.042913246899843216,
                0.07132074981927872,
                -0.05480856075882912,
                -0.013071292079985142,
                -0.011625397019088268,
                0.010890059173107147,
                -0.021854691207408905,
                0.044728752225637436,
                -0.01703173667192459,
                0.03216724097728729,
                0.03879883885383606,
                0.035848215222358704,
                -0.002958419034257531,
                0.018006881698966026,
                0.022416016086935997,
                -0.019332699477672577,
                -0.0036821705289185047,
                0.0027317169588059187,
                0.026672618463635445,
                -0.08739224821329117,
                -0.008449772372841835,
                0.012375791557133198,
                0.06356828659772873,
                0.012727347202599049,
                0.03656180202960968,
                -0.05268776789307594,
                -0.034375689923763275,
                0.01704818569123745,
                -0.04107927531003952,
                -0.020287569612264633,
                0.021553801372647285,
                -0.04289529100060463,
                -0.02587057650089264,
                -0.018890216946601868,
                -0.03279326483607292,
                0.050047412514686584,
                0.007782660890370607,
                -0.028609585016965866,
                -0.038786329329013824,
                -0.030898967757821083,
                -0.0031506060622632504,
                -0.015645992010831833,
                0.03988196700811386,
                -0.04128707945346832,
                0.02438734658062458,
                0.01974444091320038,
                0.01715489849448204,
                0.10331515222787857,
                0.05198948457837105,
                0.03258810192346573,
                -0.014780916273593903,
                -0.00015472345694433898,
                0.009251480922102928,
                -0.005211106967180967,
                0.032909173518419266,
                0.05117502808570862,
                -0.03987919166684151,
                0.00957560632377863,
                0.02237675152719021,
                -0.06865990906953812,
                0.052209362387657166,
                0.009377663023769855,
                0.031277429312467575,
                0.01912808232009411,
                -0.023509429767727852,
                0.025280321016907692,
                0.04584937542676926,
                -0.025871917605400085,
                0.061496552079916,
                0.0069620320573449135,
                0.025611335411667824,
                -0.005100852344185114,
                -0.05203227326273918,
                0.012520099058747292,
                -0.042100559920072556,
                0.06592947989702225,
                -0.010037016123533249,
                -0.04005373269319534,
                0.013554556295275688,
                -0.039642348885536194,
                0.015375110320746899,
                -0.015420754440128803,
                0.011802866123616695,
                -0.017347967252135277,
                0.04309837147593498,
                2.2445766489909147e-07,
                -0.011845150031149387,
                0.03814903274178505,
                0.020915497094392776,
                0.029427140951156616,
                -0.009193030185997486,
                -0.06512897461652756,
                -0.007914392277598381,
                -0.007917786948382854,
                -0.011333676055073738,
                -0.032328538596630096,
                -0.0031442625913769007,
                -0.0067627266980707645,
                -0.013345206156373024,
                0.0025312933139503,
                -0.0235032606869936,
                -0.09018610417842865,
                0.013184667564928532,
                0.026457348838448524,
                -0.007863996550440788,
                -0.08913537114858627,
                0.09355271607637405,
                0.021105317398905754,
                -0.019159765914082527,
                0.026205427944660187,
                0.015654616057872772,
                0.0034036163706332445,
                0.013885569758713245,
                -0.002639863872900605,
                -0.018139733001589775,
                0.01944386027753353,
                0.01828240416944027,
                0.08704513311386108,
                0.024087462574243546,
                0.10967936366796494,
                -0.0037593627348542213,
                -0.02544361911714077,
                0.009864872321486473,
                -0.03454208001494408,
                -0.02776273526251316,
                -0.05243890732526779,
                -0.02728964388370514,
                -0.03817985951900482,
                -0.0018224617233499885,
                0.0397796668112278,
                0.024087397381663322,
                -0.02031918615102768,
                -0.04385460913181305,
                0.01648709550499916,
                -0.06539331376552582,
                -0.08173759281635284,
                -1.9538641936378554e-05,
                0.02247709035873413,
                0.023949062451720238,
                0.010552946478128433,
                0.04257170855998993,
                0.0005988621851429343,
                -0.027565425261855125,
                0.01818927749991417,
                0.04947028309106827,
                0.07493434846401215,
                0.001923580071888864,
                -0.06792644411325455,
                0.03811529651284218,
                0.03844746574759483,
                0.03405222296714783,
                -0.013285433873534203,
                -0.02405012771487236,
                1.807306263588699e-34,
                -0.009736428037285805,
                -0.025291575118899345,
                0.017710143700242043,
                -0.00805786345154047,
                0.019846469163894653,
                -0.0090644471347332,
                -0.04775037616491318,
                -0.04122050851583481,
                0.01403286773711443,
                -0.12409975379705429,
                0.0007392259431071579,
                0.0320889912545681,
                0.027821794152259827,
                -0.01508530043065548,
                -0.03995076194405556,
                -0.011301844380795956,
                -0.02558900974690914,
                0.0699845403432846,
                -0.012510140426456928,
                -0.01858668215572834,
                0.001958417473360896,
                -0.025152606889605522,
                -0.03943692520260811,
                0.03654942288994789,
                0.05472077429294586,
                0.02859116718173027,
                -0.07686098664999008,
                0.022751862183213234,
                -0.03884581848978996,
                -0.12126468867063522,
                0.0037337529938668013,
                -0.044503647834062576,
                -0.017211182042956352,
                -0.02782357484102249,
                -0.01920159161090851,
                0.05643833428621292,
                0.023005465045571327,
                -0.027405520901083946,
                -0.030000878497958183,
                -0.040397483855485916,
                0.012379896827042103,
                0.10980606824159622,
                0.010674896650016308,
                0.0011365971295163035,
                0.027675718069076538,
                1.6889670177988592e-06,
                0.0711497813463211,
                -0.013405398465692997,
                -0.02936507947742939,
                0.002381013473495841,
                0.046866461634635925,
                0.009296786971390247,
                -0.022401150315999985,
                0.010406361892819405,
                0.004274323116987944,
                -0.027237407863140106,
                -0.044200263917446136,
                0.0019219673704355955,
                -0.08423555642366409,
                0.006885958835482597,
                -0.04129470884799957,
                -0.0207267627120018,
                -0.002026527188718319,
                -0.06586922705173492,
                -0.007229635491967201,
                -0.02776365727186203,
                -0.0802493616938591,
                -0.016603795811533928,
                -0.028528796508908272,
                0.026067236438393593,
                0.07232268899679184,
                0.05403164401650429,
                0.033063020557165146,
                -0.013393236324191093,
                -0.019159715622663498,
                0.0014321436174213886,
                0.02226926200091839,
                -0.0456467941403389,
                -0.0672212541103363,
                -0.013689241372048855,
                0.03101518377661705,
                -0.027764325961470604,
                0.01639172062277794,
                0.010503539815545082,
                0.05010741949081421,
                -0.0431506521999836,
                -0.017751559615135193,
                0.005781810265034437,
                0.020951922982931137,
                -0.007082812488079071,
                -0.022967444732785225,
                0.008778804913163185,
                -0.032130252569913864,
                -0.01279284805059433,
                0.02786041796207428,
                -0.0013176427455618978,
                0.08997943997383118,
                -0.034543488174676895,
                0.05007049813866615,
                -0.017116328701376915,
                0.026061180979013443,
                0.00950929056853056,
                -0.05839152634143829,
                -0.0022874174173921347,
                -0.017275284975767136,
                -0.004886394366621971,
                -0.005040056072175503,
                -0.01898854784667492,
                -0.03518134355545044,
                0.015782324597239494,
                -0.0018370557809248567,
                0.010728515684604645,
                0.060952018946409225,
                -0.00208929437212646,
                0.09977301210165024,
                -0.0036695245653390884,
                0.011685916222631931,
                -0.05108751729130745,
                -0.012607410550117493,
                0.037843044847249985,
                -0.007434256374835968,
                0.021080374717712402,
                -0.015202050097286701,
                -0.021287396550178528,
                0.009054167196154594,
                -0.03070784918963909,
                -0.00236643198877573,
                0.0400877371430397,
                -0.0038824554067105055,
                0.04026877507567406,
                0.03669211268424988,
                -0.015529811382293701,
                -0.011550845578312874,
                0.0031380869913846254,
                0.001850632019340992,
                0.025605948641896248,
                -0.001644769450649619,
                0.03871005028486252,
                0.019481049850583076,
                0.025607559829950333,
                0.04282691702246666,
                -0.006588927935808897,
                0.007156816311180592,
                -0.003541636047884822,
                -0.05248148366808891,
                -0.002829312812536955,
                -0.013594835065305233,
                -0.012466044165194035,
                -0.030103804543614388,
                -0.06114678084850311,
                -0.031019292771816254,
                0.08094452321529388,
                0.0015377644449472427,
                -0.036440130323171616,
                0.03792283684015274,
                -0.029743056744337082,
                -0.0796087309718132,
                -0.021420452743768692,
                0.056492943316698074,
                -0.014077559113502502,
                -0.01999625749886036,
                0.04220842570066452,
                0.009041515178978443,
                -0.005225792992860079,
                0.0019810828380286694,
                0.0343535877764225,
                0.05768977850675583,
                -0.05295315384864807,
                -0.01659206673502922,
                0.015520564280450344,
                0.10537183284759521,
                -0.024124283343553543,
                -0.043092381209135056,
                -0.03719732537865639,
                0.050351910293102264,
                0.0021988325752317905,
                0.009608360007405281,
                0.04451345279812813,
                0.010690628550946712,
                -0.01850929483771324,
                0.0012368741445243359,
                0.026856034994125366,
                0.018191060051321983,
                -0.009862573817372322,
                0.013237237930297852,
                0.040236540138721466,
                -0.057244230061769485,
                -0.010547200217843056,
                0.06183790788054466,
                -0.006411603651940823,
                0.009495435282588005,
                0.016557369381189346,
                -0.11306069046258926,
                -0.0009177578031085432,
                0.03469417616724968,
                0.05142689868807793,
                -0.013368983753025532,
                0.010134791024029255,
                0.026664305478334427,
                -0.04429103434085846,
                0.010484539903700352,
                0.007814099080860615,
                -0.025994453579187393,
                -0.0286200363188982,
                0.008697143755853176,
                -0.000825313909444958,
                0.028500739485025406,
                -0.026974493637681007,
                -0.01643688976764679,
                -0.045035723596811295,
                0.026673588901758194,
                0.036839574575424194,
                -0.02155170775949955,
                0.051546525210142136,
                0.01573389582335949,
                -0.008189867250621319,
                -0.027856364846229553,
                0.023097708821296692,
                -0.02564929611980915,
                -0.024284370243549347,
                0.034729473292827606,
                0.02406502701342106,
                0.002637606579810381,
                0.048243626952171326,
                0.018123120069503784,
                -0.01650707796216011,
                0.0037778380792587996,
                -0.014979425817728043,
                0.030794410035014153,
                -0.005766668356955051,
                0.005220228340476751,
                -0.05846041813492775,
                -0.059115201234817505,
                0.0033845538273453712,
                0.02056388556957245,
                0.0032754952553659678,
                0.03313632309436798,
                -0.01786382868885994,
                0.010506824590265751,
                -0.031307872384786606,
                0.03391420468688011,
                0.02741200104355812,
                -0.013737918809056282,
                -0.022339865565299988,
                -0.008390732109546661,
                -0.07137973606586456,
                -0.032233912497758865,
                0.06108319014310837,
                0.021130014210939407,
                -0.04306717589497566,
                -0.07597444951534271,
                0.012403644621372223,
                0.005652888212352991,
                -0.028999531641602516,
                0.0036766836419701576,
                0.03818899020552635,
                0.03257053345441818,
                0.04536287114024162,
                0.03139900788664818,
                0.04253764450550079,
                0.04409685358405113,
                -0.041255075484514236,
                -0.027200233191251755,
                0.003935296554118395,
                -0.018603870645165443,
                0.016602648422122,
                0.030194779857993126,
                -0.04321041330695152,
                0.031258873641490936,
                -0.0285106860101223,
                -0.010631428100168705,
                0.01896016299724579,
                -0.035834189504384995,
                -0.04303303360939026,
                -0.019051091745495796,
                0.04283866286277771,
                0.030127495527267456,
                0.07916680723428726,
                -0.033454399555921555,
                0.016189537942409515,
                -0.0018159898463636637,
                0.014322696253657341,
                0.0022132021840661764,
                0.02940027043223381,
                0.000791058293543756,
                -0.02538372203707695,
                0.031141428276896477,
                -0.04046821966767311,
                -0.009590058587491512,
                -0.02521931566298008,
                0.01175954844802618,
                0.0242692269384861,
                0.046645812690258026,
                -0.09567935019731522,
                -0.035026565194129944,
                0.036551184952259064,
                0.08193865418434143,
                0.05416987091302872,
                0.043853916227817535,
                -0.0950198844075203,
                0.05182469263672829,
                0.03413340076804161,
                -0.04707134887576103,
                -0.0078115505166351795,
                -0.08600658923387527,
                -0.050492063164711,
                0.048190802335739136,
                -0.0378447026014328,
                -0.03752847760915756,
                0.02253485657274723,
                0.0042878082022070885,
                -0.009013052098453045,
                0.013129462487995625,
                -0.02652646414935589,
                0.01816982962191105,
                -0.01802053675055504,
                -0.003262750804424286,
                0.02820204757153988,
                0.06532004475593567,
                -0.033292293548583984,
                0.017836185172200203,
                -0.0491645410656929,
                0.020421532914042473,
                0.03689158335328102,
                0.07920428365468979,
                0.020460009574890137,
                -0.050735365599393845,
                0.06215687468647957,
                -0.017562000080943108,
                -0.012202516198158264,
                0.08063636720180511,
                0.025227466598153114,
                -0.015671441331505775,
                -0.06832701712846756,
                -0.004968725610524416,
                -0.036290377378463745,
                -0.08167467266321182,
                -0.027233678847551346,
                -0.016680557280778885,
                0.036183860152959824,
                -0.07944846898317337,
                0.006863900925964117,
                0.025709960609674454,
                -0.03031056560575962,
                0.022117426618933678,
                0.009792136959731579,
                -0.016124160960316658,
                0.007727717515081167,
                -0.044879406690597534,
                -0.025341475382447243,
                -0.006396162323653698,
                0.029382606968283653,
                -0.0287547055631876,
                -0.09472198784351349,
                -0.015061650425195694,
                -0.002257552230730653,
                0.00034527727984823287,
                0.048698361963033676,
                -0.009431695565581322,
                -0.025486372411251068,
                3.079944144701585e-05,
                0.06315727531909943,
                0.021296927705407143,
                -0.001050438149832189,
                -0.006495472975075245,
                -0.014648664742708206,
                0.025019170716404915,
                -0.014901088550686836,
                0.01431678980588913,
                -0.04850507900118828,
                0.07946314662694931,
                -0.023037098348140717,
                -0.032894033938646317,
                -0.01677742600440979,
                -0.043488092720508575,
                -0.02167549729347229,
                0.010386885143816471,
                0.0423758327960968,
                0.007137286476790905,
                -0.02209579199552536,
                -0.030699387192726135,
                -0.030850984156131744,
                0.03850366175174713,
                -0.0426158607006073,
                -0.06681262701749802,
                -0.0004299924767110497,
                -0.012854058295488358,
                -0.002200606046244502,
                0.04443350434303284,
                0.05774839594960213,
                0.0067347208969295025,
                -0.04087653383612633,
                0.0417894572019577,
                0.01853879727423191,
                -0.007730340585112572,
                0.009659740142524242,
                -0.009721553884446621,
                -0.007751788944005966,
                -0.05886226147413254,
                0.015167783945798874,
                0.06415126472711563,
                -0.008715611882507801,
                -0.023657970130443573,
                0.028601789847016335,
                -0.0867978036403656,
                0.02858576364815235,
                -2.2484264263766818e-05,
                -0.055954333394765854,
                0.004436031449586153,
                0.05975738540291786,
                0.017560817301273346,
                0.02125190757215023,
                -0.03297463059425354,
                -0.0770159438252449,
                -0.015187176875770092,
                -0.04044581577181816,
                -0.05174753814935684,
                -0.0002832645259331912,
                0.03987260162830353,
                0.00987419206649065,
                -0.002438294468447566,
                0.02600746415555477,
                -0.011662996374070644,
                0.0345049686729908,
                -0.022145245224237442,
                0.025116899982094765,
                0.02056182734668255,
                -0.022835344076156616,
                -0.0031314902007579803,
                -0.016541549935936928,
                -0.06673642992973328,
                0.04005510360002518,
                0.01442512683570385,
                0.006603189744055271,
                -0.05401059612631798,
                0.06471174955368042,
                -0.042551908642053604,
                0.010954339988529682,
                0.042476821690797806,
                -0.03424249589443207,
                -0.049342576414346695,
                -0.023838816210627556,
                0.010654186829924583,
                0.028223659843206406,
                -0.012414811179041862,
                0.02708994410932064,
                0.013480963185429573,
                0.02964567206799984,
                -0.029422830790281296,
                0.04160407930612564,
                -0.009196910075843334,
                0.01489392202347517,
                0.015719125047326088,
                0.009986157529056072,
                0.0006522346520796418,
                0.005268041044473648,
                0.026704175397753716,
                0.030897164717316628,
                0.024133043363690376,
                -0.026447327807545662,
                -0.018454669043421745,
                0.06209699809551239,
                0.009265176951885223,
                -0.03958999738097191,
                0.002938277553766966,
                -0.07283429801464081,
                0.015515279024839401,
                -0.009355811402201653,
                -0.03777611628174782,
                -0.03532326593995094,
                0.06253601610660553,
                0.05615021660923958,
                0.03703151270747185,
                -0.10039478540420532,
                0.005586925894021988,
                -0.002473964588716626,
                0.01566820964217186,
                -0.022426586598157883,
                -0.07832671701908112,
                -0.05529377982020378,
                0.012447388842701912,
                0.022736897692084312,
                -0.007276154588907957,
                -0.03841845318675041,
                0.011376644484698772,
                -0.052167631685733795,
                -0.018837938085198402,
                -0.0031073756981641054,
                -0.0011834307806566358,
                -0.005175442900508642,
                0.02666192501783371,
                0.008731415495276451,
                0.05955857038497925,
                -0.012716845609247684,
                0.0008001208188943565,
                0.01924881711602211,
                0.05201568827033043,
                0.01720300503075123,
                0.01472633145749569,
                0.010265888646245003,
                0.06819301843643188,
                -0.011761371977627277,
                -0.009333731606602669,
                0.008894079364836216,
                0.07646806538105011,
                0.032594501972198486,
                0.03926767781376839,
                0.017203370109200478,
                0.04571795091032982,
                0.048956532031297684,
                -0.047826483845710754,
                0.013904708437621593,
                0.04999055340886116,
                0.013483869843184948,
                0.00434136250987649,
                -0.022429360076785088,
                -0.0036731588188558817,
                0.018073121085762978,
                -0.0712231770157814,
                0.036410070955753326,
                -0.052232686430215836,
                -0.03877922147512436,
                0.0031205841805785894,
                -0.015349765308201313,
                -0.05427737906575203,
                0.0624781958758831,
                0.0356646403670311,
                0.022632848471403122,
                0.06554706394672394,
                -0.024865254759788513,
                -0.016896160319447517,
                0.021215438842773438,
                0.013071971945464611,
                -0.01965334638953209,
                -0.02955654449760914,
                -0.024105658754706383,
                -0.022802209481596947,
                0.03327765688300133,
                0.018641412258148193,
                -1.1418720532674342e-05,
                0.004566286690533161,
                0.0471443310379982,
                -0.000566067174077034,
                0.029237879440188408,
                0.047387298196554184,
                -0.03485887870192528,
                0.05167187750339508,
                0.027517082169651985,
                0.1153339147567749,
                -0.037296049296855927,
                -0.03974325209856033,
                -0.01873072236776352,
                -0.005565458443015814,
                -0.0005832873866893351,
                0.01807527430355549,
                -0.004425493534654379,
                -0.02789049968123436,
                0.06454724818468094,
                0.017118562012910843,
                0.0108288349583745,
                -0.013562683947384357,
                0.023743461817502975,
                -0.05671805888414383,
                -0.01679174043238163,
                -0.008036819286644459,
                -0.04498746618628502,
                -6.364463635293757e-33,
                -0.0033631576225161552,
                -0.01625528372824192,
                0.05682769790291786,
                0.04477522894740105,
                -0.03798237070441246,
                0.03256308287382126,
                -0.0016779530560597777,
                0.0003709212760441005,
                0.004004776012152433,
                -0.07293001562356949,
                -0.02502184920012951,
                0.008205058984458447,
                0.008639509789645672,
                -0.045252203941345215,
                -0.037794675678014755,
                0.031058402732014656,
                0.03908906877040863,
                0.011684120632708073,
                -0.048771705478429794,
                -0.012264912948012352,
                -0.026271043345332146,
                0.005844029597938061,
                0.027380958199501038,
                0.0014632066013291478,
                -0.0017446413403376937,
                0.022811610251665115,
                -0.01067114807665348,
                -0.014471787959337234,
                0.03415997698903084,
                0.002445728750899434,
                -0.0035395557060837746,
                0.043701592832803726,
                -0.0007892962312325835,
                0.0023696862626820803,
                -0.013603424653410912,
                -0.036106593906879425,
                -0.06005178019404411,
                0.035745926201343536,
                -0.0011097764363512397,
                -0.007419418077915907,
                -0.01793241687119007,
                -0.016256483271718025,
                -0.005056032445281744,
                0.009254100732505322,
                -0.00803154893219471,
                -0.03831181675195694,
                -0.006520366761833429,
                0.06310636550188065,
                0.0132059995085001,
                0.05316681042313576,
                -0.02478608675301075,
                0.009218467399477959,
                0.02287956513464451,
                -0.002932831645011902,
                0.05438487231731415,
                -0.008886435069143772,
                0.008454499766230583,
                -0.020101504400372505,
                0.025092940777540207,
                0.05132398381829262,
                -0.0030492970254272223,
                0.029949262738227844,
                0.05131431668996811,
                -0.050359293818473816,
                -0.03931094706058502,
                0.02514638565480709,
                -0.03812042996287346,
                0.04950486868619919,
                0.01707877591252327,
                -0.03844074532389641,
                -0.010400033555924892,
                0.06235937029123306,
                -0.016966434195637703,
                0.015928281471133232,
                0.009458789601922035,
                -0.028305228799581528,
                0.026146192103624344,
                -0.008070572279393673,
                0.04461630806326866,
                0.011274551972746849,
                0.02307330071926117,
                0.011358468793332577,
                0.013436633162200451,
                0.014224499464035034,
                -0.019537825137376785,
                -0.02996443584561348,
                0.023023303598165512,
                0.05945349857211113,
                0.029183661565184593,
                0.023629803210496902,
                -0.007137002423405647,
                -0.005784444976598024,
                0.008101499639451504,
                -0.002569718286395073,
                0.10964171588420868,
                0.04790712147951126,
                0.013298340141773224,
                -0.018594108521938324,
                -0.005646752193570137,
                0.04137760400772095,
                0.03981740027666092,
                0.005923542194068432,
                -0.013045505620539188,
                -0.006663070060312748,
                0.007366966921836138,
                0.027871504426002502,
                -0.10117685794830322,
                0.042434681206941605,
                0.0025527700781822205,
                -0.007867226377129555,
                -0.02256527543067932,
                -0.013551075011491776,
                -0.0026715470012277365,
                0.0060053602792322636,
                -0.037294477224349976,
                -0.0328075997531414,
                -0.006551350466907024,
                -0.014521023258566856,
                -0.02021947130560875,
                0.030096614733338356,
                0.02652580477297306,
                -0.04203903302550316,
                -0.036305926740169525,
                0.050751693546772,
                -0.01493024080991745,
                -0.006699683144688606,
                0.010549458675086498,
                -0.04809224605560303,
                -0.019471874460577965,
                -0.020028037950396538,
                -0.021462110802531242,
                0.05488905310630798,
                2.1915789716331346e-07,
                -0.10858429223299026,
                0.042701151221990585,
                0.037960413843393326,
                0.04103050380945206,
                0.002643284387886524,
                0.0024656152818351984,
                0.03504389151930809,
                0.005376890301704407,
                -0.05827726796269417,
                0.01011352427303791,
                0.012141232378780842,
                -0.006683969404548407,
                -0.014649667777121067,
                0.008273537270724773,
                -0.017176348716020584,
                -0.01793387159705162,
                0.04884549230337143,
                -0.07450275123119354,
                -0.011711083352565765,
                -0.06371917575597763,
                0.09772388637065887,
                0.014552445150911808,
                0.012792125344276428,
                -0.020339246839284897,
                -0.030703650787472725,
                -0.012773025780916214,
                -0.014315091073513031,
                -0.0038353444542735815,
                0.03862404078245163,
                -0.003295666305348277,
                0.023336956277489662,
                0.06044803187251091,
                0.017829200252890587,
                0.020028114318847656,
                0.005062068812549114,
                -0.07617891579866409,
                0.07328272610902786,
                -0.0740705281496048,
                -0.004991439636796713,
                -0.04697254300117493,
                -0.056334540247917175,
                0.0056567080318927765,
                -0.0013784036273136735,
                -0.06290953606367111,
                -0.046201884746551514,
                0.004700960125774145,
                0.016660263761878014,
                -0.01617383025586605,
                0.0011769536649808288,
                -0.028649209067225456,
                -0.0007958761998452246,
                0.034759022295475006,
                -0.010355167090892792,
                -0.07823003828525543,
                0.031870245933532715,
                0.013528455048799515,
                -0.030867818742990494,
                0.03720216453075409,
                0.04855702817440033,
                0.03631957247853279,
                -0.03835010156035423,
                0.01576179265975952,
                -0.02803899347782135,
                0.026508452370762825,
                0.011451948434114456,
                0.012971241027116776,
                -0.022836167365312576,
                1.4529262852675836e-34,
                -0.02309929020702839,
                -0.06319333612918854,
                -0.08337319642305374,
                0.052338097244501114,
                -0.018656793981790543,
                0.002558657666668296,
                -0.08544702082872391,
                -0.014484372921288013,
                0.000519545515999198,
                -0.040655072778463364,
                0.024121111258864403
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\xd2E\\xfd\\xa99`\\xd6^\\xecL\\x1e\\xa6\\x13\\x1dvr'",
        "id__": "91613ac5-67c4-4393-b869-e1b3a51c1c30"
    },
    {
        "Certificates": {
            "name": [
                "Analysis and Visualization with Power BI",
                "Basic Seismic Interpretation "
            ],
            "rep": [
                -0.06142786145210266,
                0.017744434997439384,
                -0.04882017895579338,
                -0.03206685185432434,
                -0.024722643196582794,
                0.01029706560075283,
                0.0148500707000494,
                0.010018526576459408,
                -0.006438508629798889,
                0.044570352882146835,
                0.01888103410601616,
                0.012248389422893524,
                0.03599201515316963,
                0.11444851756095886,
                0.0044055781327188015,
                -0.03938501700758934,
                0.04538730904459953,
                0.06945271790027618,
                0.0038321407046169043,
                0.04350580275058746,
                -0.0003971933911088854,
                -0.01813589781522751,
                -0.005575047340244055,
                0.021258365362882614,
                -0.05638980492949486,
                -0.0020065857097506523,
                -0.008709951303899288,
                0.023218700662255287,
                0.0083797387778759,
                -0.05235709622502327,
                -0.0014748721150681376,
                0.03665802627801895,
                0.0052055856212973595,
                0.021133679896593094,
                1.4403850627786596e-06,
                -0.060342658311128616,
                -0.011390550062060356,
                0.04220188036561012,
                -0.016911940649151802,
                0.02131251059472561,
                0.0013791901292279363,
                0.03411015495657921,
                0.040289655327796936,
                -0.001206788350827992,
                0.006000232417136431,
                -0.0003023866156581789,
                -0.009989840909838676,
                -0.008460795506834984,
                -0.019262712448835373,
                -0.055760499089956284,
                -0.01608147844672203,
                0.05233257636427879,
                -0.05369258299469948,
                -0.025091860443353653,
                0.005911795422434807,
                0.04582734405994415,
                -0.014506349340081215,
                -0.024519827216863632,
                0.06755411624908447,
                0.0006371053168550134,
                0.018302232027053833,
                0.023669006302952766,
                0.004083069507032633,
                -0.022982565686106682,
                0.03676578402519226,
                0.020901117473840714,
                0.09315226227045059,
                0.00805211067199707,
                -0.0076538072898983955,
                -0.005204515065997839,
                0.01726219244301319,
                0.009834671393036842,
                -0.0022608956787735224,
                0.016480514779686928,
                -0.007710520643740892,
                0.017477653920650482,
                -0.03505944833159447,
                -0.03213977441191673,
                0.0018748814472928643,
                -0.017001552507281303,
                -0.04778222739696503,
                -0.002743462100625038,
                -0.03879157453775406,
                -0.06693616509437561,
                -0.02063647471368313,
                0.008336472325026989,
                -0.01334016676992178,
                -0.009222940541803837,
                0.019259750843048096,
                0.002652173861861229,
                0.03193024545907974,
                -0.005244392901659012,
                -0.008942942135035992,
                0.008698299527168274,
                -0.01614104025065899,
                -0.02419440448284149,
                0.07689505815505981,
                0.00662277452647686,
                0.04546312242746353,
                -0.015137678943574429,
                0.08715934306383133,
                0.006693688221275806,
                -0.004728240426629782,
                0.029276350513100624,
                0.06844320148229599,
                0.03268346190452576,
                -0.023179667070508003,
                -0.038324594497680664,
                0.04580274224281311,
                0.03313088044524193,
                -0.026191657409071922,
                -0.005587952211499214,
                -0.003407832235097885,
                -0.010952111333608627,
                -0.0011330535635352135,
                0.030982688069343567,
                -0.03593826666474342,
                -0.026222379878163338,
                -0.07896336168050766,
                -0.028087537735700607,
                0.01920918934047222,
                -0.006895723287016153,
                0.03783812001347542,
                0.045933738350868225,
                -0.021700559183955193,
                0.02378401905298233,
                -0.035963743925094604,
                -0.008815409615635872,
                -0.044091831892728806,
                -0.06806366890668869,
                -0.026111647486686707,
                -0.023828351870179176,
                -0.013029095716774464,
                0.003109669778496027,
                0.011099294759333134,
                -0.03988759219646454,
                0.01965886726975441,
                0.01156730018556118,
                -0.0863761454820633,
                -0.042963989078998566,
                0.011296660639345646,
                -0.02928849682211876,
                0.007032983936369419,
                0.020191354677081108,
                0.0601535327732563,
                0.001446265378035605,
                -0.018084917217493057,
                -0.011829348281025887,
                -0.013662519864737988,
                0.023617666214704514,
                0.010717310011386871,
                0.007118426263332367,
                -0.0443384163081646,
                0.02741025760769844,
                -0.046398017555475235,
                -0.007317465264350176,
                -0.010337429121136665,
                -0.0065847584046423435,
                -0.0033314547035843134,
                -0.05659524351358414,
                -0.04143335670232773,
                -0.039395034313201904,
                0.01960575580596924,
                -0.004179357551038265,
                0.025332653895020485,
                0.024823911488056183,
                -0.057040829211473465,
                -0.006406170781701803,
                -0.012365356087684631,
                -0.048601388931274414,
                -0.038769446313381195,
                -0.036919787526130676,
                0.014655206352472305,
                0.08868935704231262,
                0.03675049543380737,
                -0.040615957230329514,
                0.0706140398979187,
                0.01409208308905363,
                -0.016947628930211067,
                0.009334604255855083,
                0.010845213197171688,
                -0.11582659184932709,
                0.05058128014206886,
                0.011634059250354767,
                -0.0163512472063303,
                -0.006693367380648851,
                -0.008545265533030033,
                0.02977103367447853,
                0.0030318463686853647,
                -0.022925708442926407,
                0.00048088858602568507,
                0.01412595622241497,
                -0.0203473549336195,
                -0.011151034384965897,
                0.02016172930598259,
                0.045821662992239,
                0.05617913603782654,
                0.002360099460929632,
                0.057984448969364166,
                -0.02869979478418827,
                0.0017249626107513905,
                0.0029737981967628,
                0.032245662063360214,
                -0.04191621392965317,
                0.031218666583299637,
                -0.048963651061058044,
                0.11495882272720337,
                -0.024342427030205727,
                -0.002905737143009901,
                0.0026897527277469635,
                0.017372630536556244,
                0.011520491912961006,
                -0.016264766454696655,
                9.361058619106188e-05,
                -0.01046835444867611,
                0.027965258806943893,
                -0.0022021986078470945,
                -0.028690895065665245,
                -0.04677377641201019,
                0.05151243880391121,
                -0.048263415694236755,
                0.014331542886793613,
                -0.00553933996707201,
                -0.03477870300412178,
                0.03811751306056976,
                0.10798421502113342,
                0.03162015974521637,
                0.019593484699726105,
                0.02977042645215988,
                0.04760570079088211,
                -0.022246789187192917,
                -0.0099561782553792,
                0.001794461510144174,
                -0.030007628723978996,
                -0.00492996908724308,
                0.039298251271247864,
                0.0901886522769928,
                0.09519302845001221,
                0.011644084006547928,
                0.01705000177025795,
                0.041291266679763794,
                -0.07546050101518631,
                0.022763444110751152,
                -0.02035866677761078,
                0.017275236546993256,
                -0.06806787103414536,
                -0.034308623522520065,
                0.0077918218448758125,
                0.017872577533125877,
                -0.052115894854068756,
                0.026424050331115723,
                -0.03648814558982849,
                0.012314211577177048,
                -0.003444082336500287,
                0.026483239606022835,
                0.01410733349621296,
                -0.07112038135528564,
                0.013321857899427414,
                0.018826231360435486,
                0.05929230526089668,
                0.013816053047776222,
                -0.03290781006217003,
                -0.05709068104624748,
                -0.01096290908753872,
                -0.09213081747293472,
                -0.022207695990800858,
                0.07255494594573975,
                0.02965918742120266,
                -0.01079366821795702,
                0.04483456164598465,
                -0.005062356125563383,
                0.008635938167572021,
                0.0331716425716877,
                -0.01279609464108944,
                0.012999841012060642,
                0.0033360200468450785,
                -0.02096807211637497,
                -0.0361425019800663,
                -0.019562367349863052,
                0.04025697335600853,
                0.021963387727737427,
                0.037873201072216034,
                -0.0071890451945364475,
                0.005913235247135162,
                -0.047403693199157715,
                0.03263004496693611,
                0.08646433055400848,
                -0.013859501108527184,
                0.024007495492696762,
                0.00481091532856226,
                0.04525991156697273,
                -0.013821035623550415,
                0.0027311125304549932,
                0.021876398473978043,
                0.006212526932358742,
                -0.012783138081431389,
                0.08996715396642685,
                0.007419085595756769,
                -0.0011168266646564007,
                0.021971430629491806,
                -0.021355655044317245,
                -0.029898565262556076,
                -0.03077864833176136,
                0.031067010015249252,
                -0.007869726046919823,
                0.11442869156599045,
                0.07380310446023941,
                -0.06734705716371536,
                0.04579068720340729,
                0.0032263128086924553,
                0.033266983926296234,
                0.018019990995526314,
                0.04719546437263489,
                -0.018439356237649918,
                -2.2351214283844456e-05,
                0.0015451032668352127,
                0.02236318588256836,
                -0.022227460518479347,
                0.017527559772133827,
                0.017545092850923538,
                0.027440302073955536,
                -0.012422709725797176,
                -0.043731916695833206,
                -0.04445279762148857,
                7.610672764712945e-05,
                -0.046693965792655945,
                -0.031615711748600006,
                0.017252156510949135,
                0.022700024768710136,
                -0.01909906417131424,
                -0.007550597656518221,
                -0.09581471234560013,
                0.009520556777715683,
                0.03641967847943306,
                -0.033599723130464554,
                -0.026913192123174667,
                -0.012805753387510777,
                -0.04971368983387947,
                -0.035281430929899216,
                -0.03219446912407875,
                0.017308318987488747,
                0.0656941831111908,
                -0.0040593333542346954,
                0.053239572793245316,
                0.053735338151454926,
                -0.037445101886987686,
                0.05913468450307846,
                -0.010348337702453136,
                -0.032229747623205185,
                0.03398524224758148,
                0.09803145378828049,
                -0.03943713381886482,
                -0.010664865374565125,
                -0.013519925996661186,
                0.04591381549835205,
                -0.05473308265209198,
                0.004928631242364645,
                0.027404455468058586,
                0.009320304729044437,
                -0.0026270332746207714,
                -0.03374633565545082,
                -0.011880609206855297,
                -0.0585896298289299,
                0.04075980558991432,
                -0.07990548759698868,
                0.00962743628770113,
                -0.039726682007312775,
                0.0032390051055699587,
                0.006171951536089182,
                -0.02510576695203781,
                -0.015208639204502106,
                0.026527447625994682,
                0.006846836768090725,
                -0.011553546413779259,
                0.048056840896606445,
                -0.07325833290815353,
                0.004075923468917608,
                0.0027574580162763596,
                -0.04682894051074982,
                -0.024693042039871216,
                -0.031242724508047104,
                -0.01143631897866726,
                0.05383390188217163,
                0.026249488815665245,
                0.01966417022049427,
                -0.05020622909069061,
                -0.028867896646261215,
                -0.03391661494970322,
                -0.06317688524723053,
                0.023086272180080414,
                0.0016422068001702428,
                0.024993160739541054,
                -0.11118032038211823,
                -0.03205776959657669,
                0.024487243965268135,
                -0.02850363589823246,
                -0.050704289227724075,
                -0.008255067281425,
                0.018422994762659073,
                0.015985533595085144,
                0.00550435483455658,
                -0.03696279972791672,
                -0.017399992793798447,
                -0.019638018682599068,
                -0.016392502933740616,
                -0.06496488302946091,
                -0.018576495349407196,
                0.030765894800424576,
                -0.03576143831014633,
                0.03612896800041199,
                0.0008165304316207767,
                0.03437712788581848,
                -0.010977260768413544,
                -0.08776960521936417,
                0.05939043313264847,
                0.07634048163890839,
                -0.012764926068484783,
                -0.04117472469806671,
                0.06379850953817368,
                0.03890484198927879,
                0.018883882090449333,
                0.006033084820955992,
                0.029711198061704636,
                -0.03682348504662514,
                -0.01845485530793667,
                -0.08787811547517776,
                0.06974583864212036,
                0.0392000637948513,
                0.011950363405048847,
                0.06029881164431572,
                -0.0016862294869497418,
                0.006498397793620825,
                -0.0007617314695380628,
                0.04737558215856552,
                -0.04117681458592415,
                0.006409480702131987,
                0.013139089569449425,
                0.04892641305923462,
                -0.018919674679636955,
                -0.00224492559209466,
                -0.015633370727300644,
                0.028708865866065025,
                0.0539633110165596,
                0.027240073308348656,
                -0.006854057312011719,
                0.0026117602828890085,
                -0.02142459526658058,
                -0.01590273343026638,
                0.01454436406493187,
                0.04080736264586449,
                0.07052163779735565,
                0.014492487534880638,
                -0.027236316353082657,
                0.005782268010079861,
                0.06145723909139633,
                -0.018036847934126854,
                0.03349157050251961,
                0.006932355929166079,
                -0.008402612991631031,
                0.050255533307790756,
                0.039581745862960815,
                0.025007855147123337,
                -0.03558323532342911,
                -0.02060001902282238,
                -0.0035512882750481367,
                -0.034288905560970306,
                0.020275771617889404,
                -0.03706750646233559,
                -0.06990820914506912,
                -0.10005995631217957,
                0.00939441379159689,
                0.008663980290293694,
                -0.04893609136343002,
                0.04602440819144249,
                -0.01037961058318615,
                0.011394862085580826,
                0.009741640649735928,
                -0.0037147703114897013,
                -0.003967502154409885,
                -0.023504532873630524,
                0.004072113428264856,
                0.017563292756676674,
                0.07635724544525146,
                -0.017285671085119247,
                -0.04559522867202759,
                -0.007362807635217905,
                -0.01557837799191475,
                0.015917891636490822,
                0.0024630348198115826,
                -0.06329642236232758,
                0.013046988286077976,
                -0.015901170670986176,
                -0.01614057458937168,
                0.005055281799286604,
                -0.004758435767143965,
                -0.11739252507686615,
                -0.024171527475118637,
                0.04053809866309166,
                0.012400713749229908,
                0.04090311750769615,
                -0.02838737517595291,
                -0.006846147123724222,
                0.020693650469183922,
                -0.051127150654792786,
                -0.01205938309431076,
                0.03634745255112648,
                0.02698858641088009,
                0.03438539803028107,
                -0.033678557723760605,
                -0.042760416865348816,
                -0.022751083597540855,
                0.04108878970146179,
                -0.0038858167827129364,
                0.047984443604946136,
                0.0608266182243824,
                -0.04806091636419296,
                -0.06140981242060661,
                0.050538551062345505,
                0.007135256193578243,
                -0.004023896995931864,
                -0.01195057574659586,
                -0.003262148704379797,
                0.0050394064746797085,
                -0.04998810216784477,
                0.08531098067760468,
                0.03533797338604927,
                -0.003000132041051984,
                -0.04251313582062721,
                -0.023387424647808075,
                0.01720578595995903,
                0.010866859927773476,
                -0.03362417221069336,
                -0.06905870139598846,
                0.0029053755570203066,
                -0.021487310528755188,
                0.007405839394778013,
                0.03521309047937393,
                -0.006954170297831297,
                -0.023502672091126442,
                0.012522455304861069,
                -0.08691231161355972,
                -0.008215966634452343,
                0.015578175894916058,
                -0.025588205084204674,
                0.0071870144456624985,
                -0.00554391136392951,
                -0.0005023839767090976,
                -0.049645908176898956,
                -0.003991955425590277,
                -0.009640657342970371,
                0.044517263770103455,
                0.027765978127717972,
                -0.01984592340886593,
                -0.049179807305336,
                -0.03418625518679619,
                -5.277907554396668e-33,
                0.007675745524466038,
                -0.03413460776209831,
                -0.033201176673173904,
                -0.01660468615591526,
                -0.03617598116397858,
                -0.020147591829299927,
                0.002666024724021554,
                -0.027215436100959778,
                -0.027725277468562126,
                -0.007219180930405855,
                -0.025323057547211647,
                -0.0005928765167482197,
                0.0012093900004401803,
                0.023311423137784004,
                0.022381283342838287,
                0.0065707797184586525,
                -0.0031181620433926582,
                0.053551580756902695,
                -0.03617889806628227,
                -0.00349501334130764,
                -0.020977383479475975,
                0.029211997985839844,
                -0.017849301919341087,
                -0.08418256044387817,
                0.004692825488746166,
                0.04428604617714882,
                -0.03900971636176109,
                0.03448675945401192,
                -0.016053607687354088,
                0.021454662084579468,
                0.04315829649567604,
                -0.06520335376262665,
                -0.008748884312808514,
                0.0020023302640765905,
                -0.027284953743219376,
                0.02039426937699318,
                -0.05171307548880577,
                -0.020680541172623634,
                -0.006602561566978693,
                0.003556726034730673,
                0.008014610968530178,
                -0.006622794549912214,
                0.02968599833548069,
                -0.0006110743852332234,
                0.018305141478776932,
                -0.03106699138879776,
                0.013934839516878128,
                0.03582676872611046,
                0.018441984429955482,
                0.011530856601893902,
                -0.0065635195933282375,
                -0.024811754003167152,
                -0.050302550196647644,
                0.0740092322230339,
                0.01095063891261816,
                0.026340855285525322,
                -0.021587977185845375,
                0.04198877140879631,
                -0.020882343873381615,
                0.04087917506694794,
                0.07694897055625916,
                -0.056052133440971375,
                0.026536185294389725,
                -0.006129489280283451,
                0.07508551329374313,
                -0.03620361164212227,
                -0.004873119294643402,
                -0.02669375017285347,
                0.036578163504600525,
                0.009875210002064705,
                -0.0008296491578221321,
                0.03919590264558792,
                0.06937891244888306,
                -0.031753748655319214,
                -0.05554807186126709,
                -0.023549066856503487,
                -0.0804094597697258,
                0.07252056896686554,
                0.034806665033102036,
                -0.01871439442038536,
                -0.03278757631778717,
                -0.05611890181899071,
                0.004293260630220175,
                0.003935323562473059,
                0.04514281079173088,
                -0.03817228600382805,
                -0.05857362970709801,
                -0.009671448729932308,
                -0.006361624225974083,
                -0.05259603261947632,
                -0.014521432109177113,
                0.06857411563396454,
                -0.028529005125164986,
                0.010222108103334904,
                0.0051661645993590355,
                0.017200086265802383,
                0.03808724880218506,
                0.027268297970294952,
                0.05759347230195999,
                -0.043510615825653076,
                0.004000541288405657,
                0.03643488138914108,
                -0.0004295283870305866,
                0.07627157866954803,
                0.02018037810921669,
                0.011482336558401585,
                0.001266798353753984,
                -0.014286577701568604,
                -0.02050153911113739,
                0.008622526191174984,
                0.008541791699826717,
                0.0005904074059799314,
                -0.03602328523993492,
                -0.01601567491889,
                -0.013316942378878593,
                -0.006528884172439575,
                -0.002023139037191868,
                -0.005139418411999941,
                -0.00018665440438780934,
                0.039748549461364746,
                -0.0034558544866740704,
                0.0051134806126356125,
                -0.02889135293662548,
                -0.0015516355633735657,
                0.007443831767886877,
                -0.059659622609615326,
                0.018269553780555725,
                -0.057223107665777206,
                -0.0006372134084813297,
                -0.016598617658019066,
                -0.009777253493666649,
                -0.03226856887340546,
                2.142301127605606e-07,
                0.0388784222304821,
                0.0340481698513031,
                -0.003884688252583146,
                0.07607442885637283,
                0.021347196772694588,
                -0.04133469983935356,
                -0.015372955240309238,
                -0.013431744650006294,
                -0.01013515517115593,
                0.08860250562429428,
                0.026024719700217247,
                -0.01033240556716919,
                0.005038282833993435,
                0.020382417365908623,
                0.03791586309671402,
                -0.07402075827121735,
                0.03923477604985237,
                -0.04421195387840271,
                -0.058980997651815414,
                -0.041004981845617294,
                0.02587728388607502,
                -0.0059084719978272915,
                0.015598319470882416,
                0.003594173351302743,
                0.02310836873948574,
                -0.06900037825107574,
                -0.028429260477423668,
                -0.05301326885819435,
                0.03967619314789772,
                0.023551827296614647,
                -0.009853245690464973,
                0.04416695237159729,
                0.026646556332707405,
                0.010890104807913303,
                -0.0022851843386888504,
                0.04342228174209595,
                -0.054330404847860336,
                -0.031129203736782074,
                -0.009733165614306927,
                -0.003922330681234598,
                0.043961022049188614,
                -0.024875137954950333,
                0.03693326935172081,
                -0.03502696380019188,
                0.029285317286849022,
                -0.03134080395102501,
                -0.012596298940479755,
                0.04952883720397949,
                -0.011192763224244118,
                -0.023100227117538452,
                0.06477850675582886,
                -0.008245040662586689,
                -0.005600691772997379,
                0.02384260855615139,
                -0.0346536822617054,
                -0.021553587168455124,
                0.01795014552772045,
                0.10278287529945374,
                0.017121924087405205,
                0.0515836663544178,
                0.00339916511438787,
                0.0288982056081295,
                0.0013054446317255497,
                0.020383469760417938,
                0.011064859107136726,
                0.036818329244852066,
                -0.00684661278501153,
                1.9338571844155937e-34,
                -0.004908393137156963,
                -0.025361694395542145,
                0.020450172945857048,
                -0.0042826454155147076,
                0.054058149456977844,
                -0.005455615930259228,
                -0.031690433621406555,
                0.012675195001065731,
                -0.0006382337305694818,
                -0.0512135811150074,
                -0.0006067947251722217,
                -0.017003530636429787,
                -0.05098358169198036,
                0.004018882289528847,
                0.0730266273021698,
                0.005102501250803471,
                0.0011579386191442609,
                -0.04250938072800636,
                0.007454527076333761,
                -0.036266494542360306,
                0.0013151710154488683,
                -0.017100442200899124,
                -0.015461084432899952,
                -0.007821405306458473,
                0.007573354057967663,
                -0.0425310879945755,
                -0.023898107931017876,
                -0.025006571784615517,
                -0.03771099075675011,
                0.04264271259307861,
                0.021143237128853798,
                -0.04346653074026108,
                -0.037452057003974915,
                -0.020410921424627304,
                -0.0028653270564973354,
                0.01239181961864233,
                0.004116881638765335,
                0.025495154783129692,
                -0.0016054604202508926,
                0.033283405005931854,
                -0.014582045376300812,
                0.009885654784739017,
                -0.011603455059230328,
                0.01031794585287571,
                -0.015586383640766144,
                1.2538426972241723e-06,
                0.010000262409448624,
                -0.01779242791235447,
                -0.03243625909090042,
                0.024553213268518448,
                0.043448712676763535,
                -0.061352457851171494,
                -0.050380952656269073,
                0.0007705414900556207,
                -0.040185730904340744,
                -0.013035639189183712,
                -0.02896249294281006,
                -0.04737091064453125,
                -0.07616604119539261,
                -0.013166599906980991,
                -0.022403381764888763,
                0.02987424097955227,
                -0.04214220494031906,
                -0.08559861034154892,
                -0.026539819315075874,
                0.03935093805193901,
                -0.01435923669487238,
                -0.01878107152879238,
                0.02646455727517605,
                0.011881105601787567,
                0.1296062022447586,
                -0.013692446053028107,
                0.005491516552865505,
                0.004836722277104855,
                0.03074008785188198,
                0.006418759003281593,
                0.023166170343756676,
                -0.01660993881523609,
                0.03731578215956688,
                0.00886036828160286,
                0.030203070491552353,
                0.09721925109624863,
                -0.001761468593031168,
                0.029137328267097473,
                0.023378463461995125,
                -0.05392216891050339,
                0.056970421224832535,
                -0.03896169736981392,
                -0.0028776065446436405,
                -0.0047146715223789215,
                -0.04193828999996185,
                -0.010284722782671452,
                0.0025813572574406862,
                -0.050628818571567535,
                -0.020836511626839638,
                -0.047562818974256516,
                -0.030922550708055496,
                -0.03779326006770134,
                0.05334147810935974,
                -0.059291549026966095,
                0.029695209115743637,
                0.001276398659683764,
                -0.004323187749832869,
                0.0035668869968503714,
                0.058870408684015274,
                0.026173491030931473,
                0.0008172278758138418,
                0.026817703619599342,
                0.007069818209856749,
                0.05664411187171936,
                -0.019039316102862358,
                -0.016076277941465378,
                0.06806562840938568,
                -0.04066317155957222,
                0.0858713760972023,
                0.03513264283537865,
                0.005796186625957489,
                0.0027728527784347534,
                0.01562631130218506,
                -0.024169327691197395,
                0.026367684826254845,
                -0.008748273365199566,
                -0.0088445283472538,
                0.022921839728951454,
                -0.043081607669591904,
                0.005509058944880962,
                0.0010503240628167987,
                0.03229517117142677,
                -0.06372299790382385,
                0.08253224939107895,
                -0.028264252468943596,
                -0.05484772101044655,
                -0.012693141587078571,
                0.004937951453030109,
                -0.026234794408082962,
                0.004069474991410971,
                0.006776082329452038,
                -0.003346371464431286,
                0.04977607727050781,
                0.0012451871298253536,
                -0.0513361319899559,
                -0.022238027304410934,
                0.020206991583108902,
                0.00022950381389819086,
                0.061155546456575394,
                0.0035901344381272793,
                -0.02479473128914833,
                -0.0045932503417134285,
                0.021237514913082123,
                -0.014843763783574104,
                -0.02639750763773918,
                0.004004485439509153,
                -0.021220596507191658,
                0.000650462694466114,
                -0.021075226366519928,
                0.030807528644800186,
                0.06278762221336365,
                -0.024204835295677185,
                0.07208632677793503,
                0.017109181731939316,
                0.0053095510229468346,
                0.019690537825226784,
                0.028332872316241264,
                0.005242310464382172,
                -0.0034985244274139404,
                0.048864539712667465,
                0.04488765075802803,
                -0.015810741111636162,
                0.02722645364701748,
                0.007387571036815643,
                0.00746719166636467,
                -0.037175118923187256,
                -0.004754326771944761,
                -0.052936945110559464,
                -0.019352199509739876,
                -0.006018339656293392,
                0.01958509534597397,
                -0.09591425210237503,
                0.02482241950929165,
                -0.023046206682920456,
                -0.03970049321651459,
                -0.018838470801711082,
                0.016950730234384537,
                0.022017693147063255,
                0.035682883113622665,
                0.038448840379714966,
                0.016573278233408928,
                -0.06880851835012436,
                0.01845957338809967,
                0.0340876430273056,
                0.03675384819507599,
                -0.004445225466042757,
                -0.018513135612010956,
                0.020747128874063492,
                -0.018857503309845924,
                0.007043840363621712,
                0.003916806075721979,
                -0.07378879189491272,
                -0.0377664789557457,
                0.01908339187502861,
                -0.029403898864984512,
                -0.04288475587964058,
                -0.01947658695280552,
                -0.02788344956934452,
                0.0170214232057333,
                0.019744057208299637,
                0.016471978276968002,
                0.011987905018031597,
                0.019550753757357597,
                -0.016172707080841064,
                0.011934508569538593,
                0.046817999333143234,
                0.03608139604330063,
                0.02828778512775898,
                -0.06838470697402954,
                -0.0209963358938694,
                -0.030332915484905243,
                0.0005617416463792324,
                -0.0032082630787044764,
                0.03608303517103195,
                -0.004162503406405449,
                0.0003532743430696428,
                0.058336272835731506,
                -0.0006939813611097634,
                -0.05027786269783974,
                -0.06456175446510315,
                0.0157408956438303,
                0.01721484400331974,
                -0.006106771994382143,
                -0.08017023652791977,
                -0.01152915321290493,
                0.03221595659852028,
                -0.02790193445980549,
                -0.009610544890165329,
                -0.037234239280223846,
                -0.0011286413064226508,
                -0.0014235988492146134,
                0.010567385703325272,
                0.0489865206182003,
                -0.034516848623752594,
                0.023760706186294556,
                0.04801340028643608,
                0.01022577565163374,
                0.04834981635212898,
                -0.05382286012172699,
                -0.010256802663207054,
                0.036960624158382416,
                -0.052634529769420624,
                0.07973126322031021,
                -0.04736337438225746,
                -0.013546179980039597,
                0.06892426311969757,
                -0.04261942580342293,
                -0.0028444076888263226,
                -0.03313453122973442,
                0.028043100610375404,
                -0.0244115199893713,
                -0.01353541575372219,
                -0.007818314246833324,
                -0.008665480650961399,
                -0.02717217430472374,
                -0.02596430666744709,
                -0.03395453095436096,
                0.0255610179156065,
                -0.012027275748550892,
                0.002684420207515359,
                0.012803909368813038,
                0.017450181767344475,
                0.0390947051346302,
                -0.005941551644355059,
                -0.009315801784396172,
                -0.010566400364041328,
                -0.09718519449234009,
                -0.057545412331819534,
                0.01042347215116024,
                -0.02744688093662262,
                0.01970139890909195,
                -0.03516608476638794,
                0.07566051930189133,
                -0.05576939135789871,
                0.004736555740237236,
                0.029810164123773575,
                0.06732048839330673,
                0.012789140455424786,
                -0.022210126742720604,
                -0.005515063181519508,
                0.06172540783882141,
                0.007551540620625019,
                0.005449643824249506,
                -0.03086545318365097,
                0.02436583861708641,
                -0.014609940350055695,
                0.006593022495508194,
                0.0025215100031346083,
                -0.01063874177634716,
                -0.030628696084022522,
                0.01839117333292961,
                0.027828235179185867,
                -0.03936475142836571,
                -0.03210926055908203,
                -0.03133513033390045,
                0.016575554385781288,
                -0.0683688148856163,
                -0.00515096727758646,
                -0.1293310970067978,
                -0.008253133855760098,
                -0.05501386150717735,
                0.009283028542995453,
                0.019280053675174713,
                -0.030767157673835754,
                0.08992751687765121,
                0.008116236887872219,
                0.010429799556732178,
                0.006435352377593517,
                0.005431030411273241,
                -0.0037493333220481873,
                0.05776529386639595,
                -0.04712183400988579,
                -0.04481733217835426,
                -0.00416029104962945,
                0.10199814289808273,
                0.06592194736003876,
                0.03155064582824707,
                0.035602036863565445,
                0.0010240285191684961,
                -0.029777705669403076,
                -0.035768408328294754,
                0.045670196413993835,
                -0.011169109493494034,
                0.010825633071362972,
                -0.032603830099105835,
                0.018110813573002815,
                0.019489441066980362,
                -0.03786880895495415,
                0.0009805652080103755,
                -0.006435639224946499,
                -0.06486716121435165,
                -0.020899884402751923,
                -0.05368626117706299,
                -0.032142870128154755,
                -0.07664031535387039,
                -0.03463126718997955,
                -0.015190377831459045,
                -0.015595510601997375,
                0.09197014570236206,
                -0.05766728147864342,
                -0.019964268431067467,
                -0.01951703615486622,
                -0.07199675589799881,
                -0.03286411613225937,
                0.025479571893811226,
                0.029611637815833092,
                0.02339501492679119,
                -0.010523984208703041,
                0.029371041804552078,
                0.00965835526585579,
                -0.03571023792028427,
                0.02179313823580742,
                -0.03242156282067299,
                0.040305282920598984,
                0.016919460147619247,
                -0.010749200358986855,
                -0.01625695452094078,
                0.0033379080705344677,
                0.05596296861767769,
                -0.028486590832471848,
                -0.03374838829040527,
                0.021179400384426117,
                0.06849316507577896,
                0.011980980634689331,
                0.006424042861908674,
                0.04579458013176918,
                -0.021292459219694138,
                -0.017779475077986717,
                0.04313325881958008,
                -0.07677138596773148,
                -0.002701441291719675,
                -0.014099199324846268,
                0.007596546318382025,
                0.006856915075331926,
                0.018520457670092583,
                0.029712961986660957,
                -0.021772215142846107,
                -0.03464127331972122,
                -0.015585420653223991,
                -0.03207985684275627,
                0.012780733406543732,
                0.02356049232184887,
                0.03292262181639671,
                -0.08512209355831146,
                -0.006145541090518236,
                -0.06695879250764847,
                0.024045363068580627,
                -0.017251433804631233,
                -0.02352140285074711,
                0.015660956501960754,
                0.04312753677368164,
                -0.002703441074118018,
                0.002078691264614463,
                0.03279758244752884,
                0.06146116554737091,
                -0.0024586301296949387,
                -0.04163375869393349,
                -0.008935107849538326,
                -0.013848336413502693,
                -0.018733471632003784,
                0.01945354789495468,
                -0.055562399327754974,
                -0.017969297245144844,
                0.05039406567811966,
                0.045593637973070145,
                0.011250326409935951,
                0.007716893684118986,
                0.011108878068625927,
                -0.00016812383546493948,
                -0.050424907356500626,
                -0.008821851573884487,
                -0.05860808491706848,
                0.01582155004143715,
                -0.030565831810235977,
                0.0075891753658652306,
                -0.014506082981824875,
                -0.03607388585805893,
                0.044537197798490524,
                -0.006209293846040964,
                -0.08388981223106384,
                0.09211426973342896,
                0.03734837472438812,
                0.024834519252181053,
                0.014871242456138134,
                -0.05935704708099365,
                0.046622615307569504,
                -0.011542190797626972,
                0.0022641930263489485,
                -0.04446663707494736,
                -0.013790186494588852,
                -0.056283220648765564,
                0.06446149200201035,
                0.08571859449148178,
                0.004574853461235762,
                0.017379874363541603,
                0.027322785928845406,
                -0.02405707538127899,
                -0.012807048857212067,
                0.009268932975828648,
                0.019122738391160965,
                0.02663574367761612,
                0.05018182471394539,
                0.04430800676345825,
                0.022806789726018906,
                0.032034583389759064,
                0.002370018744841218,
                0.007282349746674299,
                0.01063904631882906,
                0.03306473419070244,
                0.00665306719020009,
                0.05081692337989807,
                -0.005973117891699076,
                -0.009980534203350544,
                0.005483272485435009,
                0.05857493355870247,
                -0.003271837020292878,
                0.050327058881521225,
                0.05474838241934776,
                0.052334997802972794,
                -0.05581336095929146,
                -0.008853466250002384,
                -0.034408874809741974,
                -0.0970311313867569,
                0.05314403772354126,
                0.05917782709002495,
                0.01381660159677267,
                0.021318702027201653,
                -0.017913183197379112,
                -0.0451839342713356,
                0.00992304366081953,
                -0.029652873054146767,
                -0.0272486824542284,
                -0.09774357825517654,
                -0.03670908138155937,
                -0.05636591836810112,
                0.03975694626569748,
                -0.000558786210604012,
                -0.034945420920848846,
                0.011186612769961357,
                0.0156871248036623,
                -0.03356730565428734,
                -0.02847125381231308,
                -0.0011339995544403791,
                0.011003573425114155,
                -0.034380193799734116,
                0.0005075198132544756,
                0.12468241900205612,
                0.07481206953525543,
                -0.0067209419794380665,
                0.053896330296993256,
                0.01321425847709179,
                -0.014887931756675243,
                -0.004426490981131792,
                0.017560334876179695,
                -0.05409303680062294,
                0.01763286627829075,
                -0.05675819516181946,
                -0.0258968323469162,
                0.021023660898208618,
                -0.01806541159749031,
                0.0184718519449234,
                0.05395232141017914,
                0.04370248690247536,
                0.0724095031619072,
                -0.04677193611860275,
                -0.06592467427253723,
                -0.00953216664493084,
                0.006044833920896053,
                0.0558929480612278,
                0.03915294632315636,
                0.02117689698934555,
                0.0022756089456379414,
                -0.010222628712654114,
                0.01577870361506939,
                0.0010827939258888364,
                0.03259334713220596,
                -0.029128920286893845,
                -0.026227161288261414,
                -0.017412448301911354,
                -0.0016749879578128457,
                -0.06459177285432816,
                -0.04210173338651657,
                0.03195979818701744,
                -0.0012287077261134982,
                0.01896735280752182,
                0.05531536415219307,
                -0.015495246276259422,
                -0.000601508654654026,
                0.015047674998641014,
                -0.0074167074635624886,
                0.05435626953840256,
                -0.018316715955734253,
                -0.004955934826284647,
                0.04347255825996399,
                -0.009111396037042141,
                0.0010992841562256217,
                0.029242293909192085,
                0.02930186316370964,
                0.04023538902401924,
                0.004451964050531387,
                0.02766772173345089,
                0.004470200277864933,
                -0.02501397579908371,
                -0.046069592237472534,
                0.005595596972852945,
                -0.0024354644119739532,
                0.02319052815437317,
                0.016876837238669395,
                0.013440167531371117,
                0.005570792127400637,
                0.023897526785731316,
                -0.011813562363386154,
                0.03384026139974594,
                -0.041231051087379456,
                0.0038800006732344627,
                0.006795045919716358,
                -0.013425324112176895,
                -0.045927420258522034,
                0.0013158221263438463,
                0.013447470031678677,
                -4.857062944387027e-33,
                0.0010871102567762136,
                0.025681182742118835,
                0.020873725414276123,
                0.0016203979030251503,
                0.04403430595993996,
                0.018467649817466736,
                -0.01781984604895115,
                -0.041905730962753296,
                0.052726633846759796,
                -0.04329713433980942,
                0.011374207213521004,
                0.03186557814478874,
                0.0081747742369771,
                0.024796590209007263,
                0.008054237812757492,
                -0.018858926370739937,
                -0.0014693528646603227,
                -0.01873955875635147,
                0.037560682743787766,
                -0.047831736505031586,
                0.04081001132726669,
                0.019676094874739647,
                0.07774742692708969,
                -0.14937128126621246,
                0.010185527615249157,
                -0.036053940653800964,
                -0.0124991899356246,
                -0.03161173686385155,
                0.04003624618053436,
                0.01795070990920067,
                0.002291954355314374,
                0.00013471150305122137,
                -0.02315603755414486,
                0.04021569713950157,
                0.02376379817724228,
                -0.016325034201145172,
                -0.054280899465084076,
                -0.03957292437553406,
                0.016718599945306778,
                0.0580165795981884,
                0.00895183626562357,
                -0.0008810158469714224,
                0.004162271972745657,
                -0.015870001167058945,
                -0.0339471772313118,
                -0.06732203811407089,
                0.019460847601294518,
                0.004403389524668455,
                -0.015048342756927013,
                -0.017655743286013603,
                -0.017805669456720352,
                -0.023492196574807167,
                -0.01881393976509571,
                -0.019903510808944702,
                -0.0003097672888543457,
                0.008711844682693481,
                -0.0681644007563591,
                0.01419984269887209,
                -0.021731985732913017,
                0.05715816840529442,
                0.0376889742910862,
                -0.012393901124596596,
                0.04523885250091553,
                -0.008803308941423893,
                -0.007480595260858536,
                0.03473376855254173,
                0.03695656731724739,
                -0.007781836204230785,
                -0.010008449666202068,
                -0.07434143871068954,
                0.037400878965854645,
                0.024471653625369072,
                -0.04390531778335571,
                0.044318921864032745,
                0.08603610098361969,
                -0.04211684688925743,
                -0.05493561923503876,
                -0.01300563383847475,
                0.03542257472872734,
                0.028173405677080154,
                -0.004236828535795212,
                0.005370053928345442,
                -0.0402330681681633,
                0.015996109694242477,
                -0.023022331297397614,
                -0.04322636127471924,
                0.016614669933915138,
                -0.0040111481212079525,
                -0.03222931921482086,
                -0.05303959921002388,
                -0.02668750286102295,
                0.011516893282532692,
                -0.025943342596292496,
                -0.01345858070999384,
                -0.0024125187192112207,
                0.015196822583675385,
                0.013295791111886501,
                -0.039141945540905,
                0.017077714204788208,
                0.02155788242816925,
                0.04283544793725014,
                -0.02146480232477188,
                0.0519656278192997,
                -0.03950735554099083,
                0.03379783779382706,
                0.022088930010795593,
                -0.0495138093829155,
                0.04584881290793419,
                0.04594343155622482,
                0.006683086045086384,
                0.02891349233686924,
                -0.028530197218060493,
                0.021473467350006104,
                -0.07049591094255447,
                0.04651787504553795,
                -0.025665897876024246,
                0.016681727021932602,
                0.028613904491066933,
                0.01778925582766533,
                0.10368973016738892,
                0.03219201788306236,
                0.0178093072026968,
                0.0033608656376600266,
                -0.003638364840298891,
                -0.013817169703543186,
                -0.03273725137114525,
                0.05993908643722534,
                -0.1192299872636795,
                -0.05205712094902992,
                -0.01753091625869274,
                -0.02963082678616047,
                0.03293634206056595,
                2.1536438055136387e-07,
                0.0018868959741666913,
                0.048047322779893875,
                0.0723923072218895,
                0.02262207679450512,
                0.0309118814766407,
                0.007515567354857922,
                -0.0019166317069903016,
                0.04674993082880974,
                0.05142054706811905,
                -0.000507681688759476,
                0.004775651730597019,
                -0.01776302605867386,
                0.0019573811441659927,
                -0.005031517241150141,
                0.007565626408904791,
                0.049075156450271606,
                0.017987871542572975,
                -0.06235148757696152,
                -0.03412078693509102,
                0.01030727382749319,
                0.001409958815202117,
                -0.05184762552380562,
                -0.06416868418455124,
                0.03538205847144127,
                0.025184256955981255,
                -0.008009289391338825,
                -0.0012995735742151737,
                -0.0018616851884871721,
                0.005056312773376703,
                0.004090044181793928,
                0.014523756690323353,
                -0.019723158329725266,
                -0.004130986053496599,
                -0.04544144123792648,
                -0.00675034336745739,
                -0.03144880756735802,
                0.07373068481683731,
                0.039687380194664,
                -0.025128966197371483,
                0.011264224536716938,
                -0.027743879705667496,
                -0.07472479343414307,
                0.059017833322286606,
                0.010681486688554287,
                -0.020550137385725975,
                -0.004194505978375673,
                0.015045993030071259,
                0.022700227797031403,
                -0.0933670625090599,
                -0.006048345938324928,
                0.007033842150121927,
                -0.007790116593241692,
                0.03666037693619728,
                -0.05710747838020325,
                0.014010278508067131,
                -0.0018738157814368606,
                -0.03767484053969383,
                0.04501921311020851,
                0.03209107741713524,
                0.025498002767562866,
                0.00025582933449186385,
                0.03602588549256325,
                -0.0035370993427932262,
                -0.004352952819317579,
                0.03432836756110191,
                -0.048207275569438934,
                -0.04667970910668373,
                1.3403394108089923e-34,
                -0.006304224953055382,
                0.002882239408791065,
                -0.00030559502192772925,
                -0.02311152219772339,
                -0.02905421517789364,
                0.02544601634144783,
                0.03786230832338333,
                0.045483242720365524,
                -0.0025719862896949053,
                -0.019728101789951324,
                0.019789019599556923
            ]
        },
        "Education": {
            "name": "MSc/IT with Business Intelligence",
            "rep": [
                -0.010458995588123798,
                -0.021416032686829567,
                -0.04111628234386444,
                -0.012094592675566673,
                0.004961253143846989,
                0.03984902799129486,
                -0.00890485942363739,
                -0.0014717156300321221,
                0.06415310502052307,
                -0.009754144586622715,
                0.0004233305808156729,
                0.010091486386954784,
                -0.009640401229262352,
                0.127759650349617,
                0.028111381456255913,
                0.0283737201243639,
                0.0007091308943927288,
                0.038992006331682205,
                -0.03506984934210777,
                0.006319411098957062,
                0.0011473731137812138,
                0.03654695674777031,
                -0.03151343762874603,
                -0.009272006340324879,
                0.007740218658000231,
                0.018239840865135193,
                0.011304084211587906,
                -0.0008278008317574859,
                -0.05079031363129616,
                0.008868109434843063,
                0.03257811814546585,
                -0.007170228753238916,
                -0.025290081277489662,
                0.04071157053112984,
                1.339009145340242e-06,
                -0.04730086773633957,
                -0.04551539942622185,
                0.03580237179994583,
                -0.03837990760803223,
                -0.005640177521854639,
                0.04666787013411522,
                0.08157766610383987,
                0.02200346440076828,
                0.044358767569065094,
                -0.02661135233938694,
                0.10894634574651718,
                0.0179531779140234,
                -0.0036668244283646345,
                -0.02597496658563614,
                -0.0194348506629467,
                -0.018334785476326942,
                -0.043899375945329666,
                -0.057615265250205994,
                -0.03587023541331291,
                -0.045210301876068115,
                -0.062202632427215576,
                0.006741782184690237,
                0.013654375448822975,
                0.00388320442289114,
                0.06633532792329788,
                0.008506887592375278,
                -0.03914974257349968,
                -0.07905497401952744,
                0.017548082396388054,
                -0.007438889238983393,
                0.04701206833124161,
                0.04978019744157791,
                0.014403069391846657,
                -0.06837058812379837,
                -0.06779197603464127,
                0.028712065890431404,
                0.0065298257395625114,
                -0.05645542964339256,
                0.02655804343521595,
                0.016037534922361374,
                -0.05111066624522209,
                -0.0036896790843456984,
                0.01548395399004221,
                -0.004397837910801172,
                0.019385505467653275,
                0.018503129482269287,
                0.003176445374265313,
                -0.014314853586256504,
                -0.005054063629359007,
                -0.024536073207855225,
                0.01660333387553692,
                -0.007847394794225693,
                -0.009802553802728653,
                0.04832768440246582,
                -0.004828148055821657,
                0.06416025757789612,
                0.03183805197477341,
                -0.042917780578136444,
                0.01975972205400467,
                -0.07658280432224274,
                -0.02416813373565674,
                0.04287609085440636,
                0.046346113085746765,
                -0.056587208062410355,
                0.04568551480770111,
                0.009015343151986599,
                -0.02462778426706791,
                0.021894266828894615,
                0.038413237780332565,
                0.05302048847079277,
                -0.008922395296394825,
                0.01958301290869713,
                0.010116791352629662,
                -0.019791213795542717,
                -0.03458372876048088,
                0.002229398814961314,
                -0.06206277385354042,
                0.008777303621172905,
                0.016085654497146606,
                -0.028609151020646095,
                0.03135604411363602,
                0.008156578056514263,
                0.01717858761548996,
                -0.03965920954942703,
                0.02203497290611267,
                -0.011981198564171791,
                -0.02462482824921608,
                0.019778762012720108,
                0.004756247159093618,
                -0.00384803325869143,
                -0.013577151112258434,
                -0.008061808533966541,
                0.021356524899601936,
                -0.001352425548247993,
                0.04194113239645958,
                0.007452860940247774,
                -0.03317542001605034,
                -0.04131796583533287,
                -0.006507688667625189,
                0.04155074805021286,
                -0.027149124071002007,
                0.0059526232071220875,
                0.036091145128011703,
                -0.09338457882404327,
                0.0086283003911376,
                0.0005714866565540433,
                -0.01773330755531788,
                0.047027889639139175,
                0.06856310367584229,
                0.003199059283360839,
                -0.01599612645804882,
                -0.08037520200014114,
                0.015200107358396053,
                -0.01565706729888916,
                0.006922765169292688,
                -0.013287922367453575,
                0.035991400480270386,
                0.031290631741285324,
                -0.04276888072490692,
                0.0013202293775975704,
                -0.04297594726085663,
                0.15579970180988312,
                0.0022779295686632395,
                -0.01907847262918949,
                -0.011723303236067295,
                0.016467422246932983,
                0.030359039083123207,
                0.02415541559457779,
                0.051919326186180115,
                -0.009305241517722607,
                0.008377941325306892,
                -0.021859871223568916,
                0.022621018812060356,
                -0.06559062749147415,
                -0.03895135596394539,
                -0.010518787428736687,
                -0.016059426590800285,
                0.04519352316856384,
                0.0683429017663002,
                0.03361901268362999,
                0.0031310368794947863,
                0.0029412447474896908,
                0.06508394330739975,
                -0.025955896824598312,
                0.05525662750005722,
                -0.04915348067879677,
                -0.029198940843343735,
                0.06308329105377197,
                0.039121076464653015,
                -0.004854158032685518,
                0.005911658518016338,
                -0.02579573169350624,
                0.01737399213016033,
                -0.030305542051792145,
                -0.010648357681930065,
                -0.012923037633299828,
                0.012499968521296978,
                -0.032942887395620346,
                0.020671337842941284,
                0.0320122130215168,
                -0.028354965150356293,
                -0.04055559262633324,
                -0.00931475032120943,
                -0.008649293333292007,
                -0.009696844033896923,
                -0.045463334769010544,
                -0.0030443936120718718,
                -0.0012243763776496053,
                0.03229150176048279,
                0.03310806676745415,
                -0.07995571941137314,
                0.024449018761515617,
                -0.06431826204061508,
                -0.03642205893993378,
                0.08193276822566986,
                0.014992664568126202,
                -0.005664630327373743,
                -0.009281414560973644,
                -0.0001623254647711292,
                -0.025922149419784546,
                -0.015535928308963776,
                0.0007381844334304333,
                -0.027550827711820602,
                -0.02033311128616333,
                -0.02807747758924961,
                -0.057248447090387344,
                -0.012397839687764645,
                0.024703022092580795,
                -0.0014666389906778932,
                0.04768931865692139,
                0.04102260246872902,
                0.02322692796587944,
                0.0031825352925807238,
                0.0258779413998127,
                0.09887523204088211,
                -0.024570265784859657,
                0.0027775221969932318,
                0.02077789418399334,
                0.005225747358053923,
                -0.003005929058417678,
                0.004542745184153318,
                0.06301560997962952,
                0.001554851303808391,
                0.023222245275974274,
                -0.007969332858920097,
                -0.009332073852419853,
                0.050649918615818024,
                0.015142218209803104,
                -0.02052716724574566,
                0.026328330859541893,
                -0.033590905368328094,
                -0.0285674799233675,
                0.011095436289906502,
                0.012084375135600567,
                -0.06483568251132965,
                0.03498862683773041,
                -0.011365818791091442,
                -0.02071555145084858,
                -0.0065228864550590515,
                0.03696855530142784,
                -0.029377983883023262,
                -0.07255039364099503,
                0.009694407694041729,
                0.004869907628744841,
                0.01713852770626545,
                0.026761595159769058,
                0.022624598816037178,
                0.040173932909965515,
                0.019862079992890358,
                -0.03504907712340355,
                -0.004763847682625055,
                0.028732681646943092,
                -0.05212503671646118,
                0.004760038573294878,
                0.03556327894330025,
                0.005900197196751833,
                0.021555567160248756,
                0.0007908606785349548,
                0.0034546328242868185,
                -0.0042272950522601604,
                -0.022719712927937508,
                -0.050690747797489166,
                0.042786408215761185,
                -0.060382600873708725,
                0.0039033510256558657,
                -0.010909650474786758,
                0.050193026661872864,
                0.0086482809856534,
                -0.018282946199178696,
                -0.010756039060652256,
                -0.0008683816995471716,
                0.01808921992778778,
                -0.04340273141860962,
                0.03735268861055374,
                -0.02325792796909809,
                0.008825618773698807,
                0.037746306508779526,
                -0.012559053488075733,
                0.030752146616578102,
                -0.012531734071671963,
                0.019678650423884392,
                0.01711544394493103,
                0.03928878530859947,
                -0.04732198268175125,
                0.006897269282490015,
                -0.01099414937198162,
                -0.03050568886101246,
                0.011224606074392796,
                0.027325360104441643,
                0.016261780634522438,
                0.07231943309307098,
                0.01984243467450142,
                -0.012341710738837719,
                -0.0032957266084849834,
                -0.042594797909259796,
                -0.054332200437784195,
                0.012056335806846619,
                0.08128863573074341,
                -0.01018295157700777,
                0.023808946833014488,
                -0.026609981432557106,
                -0.031263478100299835,
                -0.007709338329732418,
                -0.004998467396944761,
                0.03431392461061478,
                -0.011118209920823574,
                0.013833219185471535,
                -0.07907173037528992,
                -0.052407391369342804,
                0.014478703029453754,
                -0.0382867231965065,
                -0.04422520101070404,
                -0.017794793471693993,
                0.027389073744416237,
                -0.06626661121845245,
                -0.02363097481429577,
                -0.1028100922703743,
                -0.012935432605445385,
                -0.03234165161848068,
                -0.01610938273370266,
                -0.05137176066637039,
                -0.008311232551932335,
                -0.05759286880493164,
                -0.027415547519922256,
                -0.0374080054461956,
                0.0031399226281791925,
                0.04053255543112755,
                -0.0025544213131070137,
                0.02726517617702484,
                0.011595423333346844,
                0.0017497600056231022,
                0.09400609880685806,
                -0.0012800361728295684,
                -0.014419347047805786,
                0.03358948230743408,
                0.05933387950062752,
                0.030234115198254585,
                0.048626627773046494,
                -0.000864229747094214,
                -0.0017100891564041376,
                -0.04496670886874199,
                0.04295070841908455,
                0.038622476160526276,
                -0.009272470138967037,
                0.012590053491294384,
                -0.044854361563920975,
                -0.04479869455099106,
                -0.09425784647464752,
                -0.014122860506176949,
                0.038787711411714554,
                -0.0016656764782965183,
                0.04933449625968933,
                -0.00545303151011467,
                0.08414652943611145,
                -0.023107975721359253,
                -0.010522442869842052,
                0.012039671652019024,
                -0.06392285227775574,
                -0.02798028290271759,
                -0.0018396353116258979,
                0.0047617279924452305,
                -0.011967060156166553,
                -0.040095821022987366,
                -0.04833824932575226,
                0.0028227176517248154,
                0.027952367439866066,
                -0.00430929334834218,
                0.02442989870905876,
                -0.014761448837816715,
                0.04369261860847473,
                -0.03230047971010208,
                -0.029771167784929276,
                0.010005279444158077,
                -0.014819600619375706,
                0.01515117660164833,
                -0.014999590814113617,
                -0.0437236949801445,
                -0.10174673050642014,
                0.005270951893180609,
                -0.0016961338697001338,
                -0.02294967882335186,
                0.03917643427848816,
                -0.02722819708287716,
                0.02369035594165325,
                -0.00651939632371068,
                0.02928823046386242,
                -0.05483639985322952,
                -0.001201900653541088,
                0.004819205962121487,
                0.008443591184914112,
                -0.06223034858703613,
                0.0446256585419178,
                0.022290926426649094,
                -0.08508549630641937,
                0.051143284887075424,
                -0.03210218995809555,
                -0.0005373149760998785,
                -0.01334288064390421,
                -0.007692308630794287,
                0.04115954414010048,
                -0.01615333743393421,
                0.010057945735752583,
                -0.012035049498081207,
                -0.017118820920586586,
                0.004957898054271936,
                0.039430882781744,
                0.05301624909043312,
                0.02473835088312626,
                -0.03223443776369095,
                0.0495435856282711,
                -0.019289696589112282,
                -0.024797016754746437,
                -0.005534687079489231,
                0.05049017444252968,
                -0.018142467364668846,
                -0.00830137450248003,
                -0.008917268365621567,
                0.0009169299737550318,
                0.03026224486529827,
                0.017053652554750443,
                -0.011771149933338165,
                0.07704595476388931,
                -0.027933398261666298,
                -0.07192547619342804,
                0.04832033812999725,
                -0.04945804178714752,
                0.06290924549102783,
                0.004256138112396002,
                -0.026487009599804878,
                0.023019228130578995,
                0.022949885576963425,
                -0.029026111587882042,
                -0.04333055764436722,
                -0.040408454835414886,
                -0.018257668241858482,
                0.10266968607902527,
                -0.02678750641644001,
                0.013813553377985954,
                -0.07644498348236084,
                -0.028000373393297195,
                -0.03559151664376259,
                -0.017180074006319046,
                0.021493688225746155,
                0.016088945791125298,
                -0.006500368006527424,
                0.008548588491976261,
                0.016412528231739998,
                -0.011471858248114586,
                -0.03205464407801628,
                0.011815079487860203,
                0.041134756058454514,
                0.011793146841228008,
                0.016847318038344383,
                -0.013291312381625175,
                -0.0035633493680506945,
                -0.0029608563054353,
                -0.013110521249473095,
                -0.02088206820189953,
                0.0013572813477367163,
                0.013856635428965092,
                -0.0005547606851905584,
                0.01193428598344326,
                -0.003986035007983446,
                -0.025973094627261162,
                0.011892733164131641,
                0.048257533460855484,
                -0.0300751905888319,
                0.02395443618297577,
                -0.0005447877338156104,
                0.013901439495384693,
                0.03517288342118263,
                -0.021203074604272842,
                0.01507628709077835,
                0.02482004091143608,
                -0.06609497964382172,
                0.06765187531709671,
                -0.007612315472215414,
                0.005790021736174822,
                0.022978510707616806,
                -0.01990003138780594,
                -0.1028798297047615,
                -0.052498459815979004,
                -0.017440620809793472,
                -0.09489495307207108,
                0.059806931763887405,
                -0.04186473786830902,
                -0.053532760590314865,
                -0.049113549292087555,
                -0.08356014639139175,
                -0.007815061137080193,
                -0.0380815826356411,
                0.016977515071630478,
                0.029526622965931892,
                -0.015745172277092934,
                -0.035705532878637314,
                0.033066969364881516,
                0.02184085175395012,
                0.015810739248991013,
                0.07219253480434418,
                0.030914636328816414,
                0.02779279462993145,
                -0.01938517950475216,
                -0.023057108744978905,
                -0.009875125251710415,
                -0.009206725284457207,
                0.039025358855724335,
                -0.005321044009178877,
                0.04829386994242668,
                -0.011912636458873749,
                0.0019277241080999374,
                -0.03377794846892357,
                0.02749454788863659,
                -0.054576463997364044,
                0.033718522638082504,
                0.017816489562392235,
                -0.006307672709226608,
                -0.023767931386828423,
                -0.06493514031171799,
                -0.048514410853385925,
                -0.044521741569042206,
                -0.027890421450138092,
                -0.009326023980975151,
                0.04838227480649948,
                0.041921377182006836,
                0.08350806683301926,
                -0.03939307853579521,
                0.012315206229686737,
                0.04488440603017807,
                -0.022082433104515076,
                -0.011421957984566689,
                -0.06576340645551682,
                -0.014797229319810867,
                0.03051670826971531,
                0.03930939733982086,
                0.04260808601975441,
                0.01531567145138979,
                0.00628737872466445,
                -0.015645813196897507,
                0.05705280229449272,
                -0.014766449108719826,
                -4.734977203136578e-33,
                -0.016735976561903954,
                0.01585807278752327,
                0.03966471180319786,
                0.014784338884055614,
                0.03171415627002716,
                -0.0028212813194841146,
                0.03433370962738991,
                -0.0015544482739642262,
                -0.035642705857753754,
                -0.03309972584247589,
                -0.0466463677585125,
                -0.001836489187553525,
                0.014361346140503883,
                0.024634426459670067,
                -0.02340652421116829,
                0.08572958409786224,
                -0.0078574912622571,
                0.05230552330613136,
                0.028795061632990837,
                -0.041727613657712936,
                -0.00714455870911479,
                -0.005635971203446388,
                0.007760093547403812,
                -0.05212792009115219,
                -0.006735690403729677,
                -0.04400394856929779,
                -0.01831701025366783,
                0.019461968913674355,
                -0.01168605126440525,
                -0.011716976761817932,
                0.03160190209746361,
                -0.03128658980131149,
                0.019451329484581947,
                0.04311571270227432,
                0.011582267470657825,
                0.03257521241903305,
                0.03189476579427719,
                -0.015874695032835007,
                -0.02723953127861023,
                0.056488506495952606,
                0.0648363009095192,
                -0.006506245117634535,
                0.050460949540138245,
                -0.03580005466938019,
                -0.010798687115311623,
                0.005935561377555132,
                -0.010804781690239906,
                0.039930857717990875,
                -0.005696690641343594,
                -0.047924213111400604,
                -0.04450483247637749,
                -0.036063939332962036,
                -0.04135354980826378,
                0.03903958573937416,
                -0.02181410789489746,
                0.0026316118892282248,
                -0.00613433588296175,
                -0.030509214848279953,
                -0.011750935576856136,
                0.022890698164701462,
                0.027424370869994164,
                0.007395446300506592,
                0.013702779076993465,
                0.0024800661485642195,
                0.0021453071385622025,
                -0.035634253174066544,
                -0.02546154335141182,
                -0.007501585874706507,
                0.051283933222293854,
                -0.010590591467916965,
                -0.0176241397857666,
                0.044571567326784134,
                0.027272026985883713,
                -0.07422152906656265,
                -0.12783724069595337,
                -0.0031385046895593405,
                0.015986653044819832,
                -0.015712929889559746,
                0.025128822773694992,
                -0.0048351711593568325,
                -0.032048262655735016,
                0.002612194512039423,
                -0.010103550739586353,
                -0.01140072662383318,
                0.05973483994603157,
                0.0014814349124208093,
                0.036187123507261276,
                0.05183016136288643,
                0.05636916309595108,
                -0.03610573709011078,
                -0.016275500878691673,
                0.12674480676651,
                -0.02803562581539154,
                0.02981564961373806,
                -0.004296697210520506,
                -0.03583494573831558,
                0.01565517857670784,
                0.017591655254364014,
                0.017204541712999344,
                0.03268393501639366,
                -0.047155287116765976,
                -0.016015540808439255,
                0.0007990143494680524,
                0.005265774205327034,
                -0.04209306091070175,
                0.04084387794137001,
                0.0140237333253026,
                -0.008933835662901402,
                -0.02936847321689129,
                0.012667017988860607,
                0.005777257494628429,
                0.06671074032783508,
                -0.00709541467949748,
                -0.016304869204759598,
                0.01597706973552704,
                0.027543796226382256,
                -0.012295147404074669,
                -0.06233159452676773,
                -0.014543798752129078,
                0.05041155591607094,
                0.047351568937301636,
                -0.08881807327270508,
                -0.018385766074061394,
                -0.007414134684950113,
                0.011253029108047485,
                -0.01960909739136696,
                0.021535424515604973,
                -0.04165881127119064,
                -0.03407421335577965,
                -0.04457753151655197,
                0.011646801605820656,
                0.013554557226598263,
                1.82963347583609e-07,
                0.011222989298403263,
                0.06356416642665863,
                -0.031217897310853004,
                0.028415007516741753,
                -0.016662709414958954,
                -0.026600468903779984,
                0.03379829227924347,
                -0.022903069853782654,
                0.0008374196477234364,
                0.09677645564079285,
                9.941976895788684e-05,
                -0.004344071261584759,
                -0.009682513773441315,
                -0.028313320130109787,
                0.041785143315792084,
                -0.0795682966709137,
                0.04072926938533783,
                -0.028434336185455322,
                -0.07378868013620377,
                0.025382665917277336,
                -0.017265763133764267,
                0.050270676612854004,
                0.049417536705732346,
                0.021224260330200195,
                -0.013334013521671295,
                -0.10054775327444077,
                -0.05102359876036644,
                0.009881429374217987,
                0.027611928060650826,
                -0.008731594309210777,
                0.07870279252529144,
                0.03468276560306549,
                0.009878553450107574,
                0.040724582970142365,
                0.02353716641664505,
                0.005518878344446421,
                0.026281768456101418,
                0.008192128501832485,
                -0.011160177178680897,
                0.027462556958198547,
                0.0332937091588974,
                -0.025358568876981735,
                -0.01287988293915987,
                0.05440029129385948,
                0.015333707444369793,
                -0.008453382179141045,
                -0.004852849990129471,
                0.007081311661750078,
                -0.09896259754896164,
                -0.024832863360643387,
                -0.051683202385902405,
                0.03560522943735123,
                0.00045376611524261534,
                -0.011494600214064121,
                -0.0001833164569688961,
                -0.014188393019139767,
                -0.019430700689554214,
                0.07120124250650406,
                0.06250365078449249,
                0.028375253081321716,
                -0.015330706723034382,
                -0.0038357977755367756,
                0.005939014721661806,
                -0.010293335653841496,
                0.06496118009090424,
                -0.05141710862517357,
                -0.007681699004024267,
                1.3264458481839902e-34,
                0.0019199359230697155,
                0.0007651500054635108,
                0.040595777332782745,
                0.03589740768074989,
                0.0074346959590911865,
                0.009936393238604069,
                -0.003934736363589764,
                0.023269057273864746,
                -0.006786323618143797,
                -0.02542550303041935,
                -0.0016357781132683158
            ]
        },
        "Experience": {
            "name": " I am a knowledge driven MSc Information Technology with Business Intelligence graduate from Robert Gordon University, Aberdeen, having professional background in Oil and Gas industry, with proficient skills in seismic data interpretation, data analysis and visualization, data management, data cleaning and preparation, and information technology, comfortable working in a hands-on, growth-oriented work environment and I am willing to hone for more skills and knowledge, while adding value to any organization within the laid down policies and procedures of the company.",
            "rep": [
                0.044942036271095276,
                0.02397199347615242,
                -0.009067409671843052,
                -0.04903411492705345,
                0.0505431666970253,
                -0.007124584633857012,
                -0.06658156961202621,
                0.00926966406404972,
                -0.016283003613352776,
                -0.029993712902069092,
                0.004158549942076206,
                0.03854135423898697,
                -0.01011952105909586,
                0.04634520411491394,
                0.050497766584157944,
                -0.019075525924563408,
                0.014230647124350071,
                0.02485590986907482,
                -0.0005868418957106769,
                -0.004829308949410915,
                0.0016151044983416796,
                0.028710901737213135,
                -0.0465255044400692,
                -0.027417486533522606,
                -0.028920501470565796,
                0.059189323335886,
                -0.003951527178287506,
                0.05359001085162163,
                0.0547950342297554,
                0.06309942901134491,
                0.026624152436852455,
                -0.03158951550722122,
                -0.02498927339911461,
                -0.004824543371796608,
                1.7874044715426862e-06,
                0.001512176706455648,
                -0.022752083837985992,
                -0.002376088174059987,
                -0.0057962615974247456,
                -0.023383500054478645,
                0.0026905150152742863,
                0.026653384789824486,
                0.018106603994965553,
                0.035888370126485825,
                -0.046284619718790054,
                0.03794793412089348,
                0.040111612528562546,
                -0.08434677869081497,
                -0.007310817018151283,
                0.04062420874834061,
                -0.0025864013005048037,
                0.004915036726742983,
                0.015566964633762836,
                -0.03268056362867355,
                -0.04565751552581787,
                -0.03535996749997139,
                -0.013794615864753723,
                0.029957802966237068,
                0.029875535517930984,
                0.05532053858041763,
                0.02438489906489849,
                0.0012542388867586851,
                -0.06337757408618927,
                -0.05542667955160141,
                0.039369042962789536,
                0.05878204107284546,
                0.025212334468960762,
                -0.0180735532194376,
                0.01162752602249384,
                -0.03564368933439255,
                0.04040919989347458,
                -0.02008168026804924,
                -0.06255193054676056,
                -0.006101744715124369,
                0.032996490597724915,
                0.042656611651182175,
                0.004567582160234451,
                -0.009031019173562527,
                -0.006480437237769365,
                0.019756877794861794,
                -0.04825672507286072,
                0.010783622972667217,
                -0.02592085674405098,
                -0.06384824216365814,
                -0.018814582377672195,
                0.022461112588644028,
                -0.023801660165190697,
                -0.0009332980262115598,
                0.015461311675608158,
                -0.013893545605242252,
                0.022099029272794724,
                0.06736571341753006,
                -0.022272426635026932,
                0.03601744398474693,
                -0.023411059752106667,
                -0.0025310716591775417,
                0.04184858128428459,
                0.004278014414012432,
                0.008619211614131927,
                0.032009292393922806,
                -0.02208598330616951,
                0.0314762219786644,
                0.011201884597539902,
                0.03646361827850342,
                0.009706759825348854,
                -0.0433502160012722,
                -0.011956802569329739,
                -0.03812525048851967,
                -0.05098578706383705,
                -0.04461843520402908,
                -0.0485602542757988,
                -0.0396612249314785,
                -0.029014911502599716,
                -0.019053297117352486,
                0.004408984445035458,
                0.050608452409505844,
                0.0036575105041265488,
                0.00035263990866951644,
                0.01768699288368225,
                -0.018743524327874184,
                0.023245878517627716,
                -0.002210714155808091,
                0.040830157697200775,
                0.053126897662878036,
                -0.052604399621486664,
                -0.0027286517433822155,
                0.023909403011202812,
                0.029791677370667458,
                -0.05122987553477287,
                -0.05053186044096947,
                0.012892230413854122,
                0.038209669291973114,
                -0.013886039145290852,
                -0.01470899861305952,
                0.05475093051791191,
                0.020186610519886017,
                -0.01591290719807148,
                0.007353493478149176,
                -0.07589271664619446,
                -0.001825173501856625,
                0.027356605976819992,
                0.027975931763648987,
                -0.0006516523426398635,
                0.058571383357048035,
                0.014056386426091194,
                -0.061571892350912094,
                -0.060541268438100815,
                0.020796237513422966,
                0.02368868887424469,
                -0.00892074778676033,
                0.013897678814828396,
                -0.011009826324880123,
                -0.034034427255392075,
                0.006854446139186621,
                0.01766660064458847,
                -0.028363332152366638,
                0.020523978397250175,
                0.034450504928827286,
                -0.02418319694697857,
                0.009603657759726048,
                0.012603774666786194,
                -0.009967424906790257,
                0.0002912181953433901,
                0.055688656866550446,
                -0.06411352753639221,
                0.06210731342434883,
                0.04269181564450264,
                0.06603645533323288,
                -0.038687415421009064,
                0.038552120327949524,
                0.0703972578048706,
                -0.055280786007642746,
                -0.021220680326223373,
                0.03607911616563797,
                0.04809536412358284,
                -0.04613307863473892,
                0.03808794170618057,
                -0.03236418589949608,
                0.06973528116941452,
                0.021821437403559685,
                -0.05194130912423134,
                -0.17733857035636902,
                0.010278921574354172,
                0.03286218270659447,
                0.04263164848089218,
                0.04776943847537041,
                0.009006178006529808,
                0.05882467329502106,
                -0.008742241188883781,
                0.009989878162741661,
                0.01776716485619545,
                0.01081748865544796,
                -0.024190913885831833,
                0.04949677735567093,
                0.0011570197530090809,
                0.0010615407954901457,
                -0.04385073482990265,
                -0.032680392265319824,
                -0.031910188496112823,
                -0.0541001558303833,
                -0.011308486573398113,
                -0.02799290232360363,
                -0.05080709233880043,
                -0.02586228959262371,
                0.072054423391819,
                -0.06543224304914474,
                -0.04760671779513359,
                -0.07093004137277603,
                -0.006848757155239582,
                0.04709361493587494,
                0.049357231706380844,
                -0.007017213385552168,
                -0.02219969779253006,
                0.005716176237910986,
                -0.04680134728550911,
                0.0012531523825600743,
                -0.026667499914765358,
                -0.0066285221837460995,
                0.06929866969585419,
                0.04877779260277748,
                -0.0014119987608864903,
                -0.00727307703346014,
                -0.0022764515597373247,
                0.013162711635231972,
                0.03749407082796097,
                -0.0002932249044533819,
                0.008567293174564838,
                -0.0033237074967473745,
                0.007282300852239132,
                0.08057713508605957,
                -0.01252079475671053,
                0.024130679666996002,
                0.008075172081589699,
                -0.033882491290569305,
                -0.018878553062677383,
                0.03269747272133827,
                0.0410938635468483,
                0.03522167727351189,
                -0.01372554525732994,
                -0.007136691827327013,
                -0.018538476899266243,
                -0.028425220400094986,
                0.019345421344041824,
                0.056343644857406616,
                -0.04222774878144264,
                0.02106117457151413,
                0.003959318622946739,
                -0.004057373385876417,
                -0.010857765562832355,
                -0.022320128977298737,
                0.022958211600780487,
                0.0072852750308811665,
                0.04082120209932327,
                0.021179398521780968,
                -0.03573189675807953,
                -0.034539420157670975,
                -0.05644931644201279,
                0.016058526933193207,
                -0.10934171825647354,
                0.05492854118347168,
                -0.05900077894330025,
                -0.031179280951619148,
                -0.012552468106150627,
                -0.014150022529065609,
                0.007807969581335783,
                0.0018092654645442963,
                0.026411229744553566,
                -0.03458583727478981,
                -0.00012434954987838864,
                0.030749592930078506,
                0.06906650960445404,
                0.041363608092069626,
                0.02574222907423973,
                -0.029926879331469536,
                -0.021583357825875282,
                -0.02713780477643013,
                0.022694891318678856,
                0.045115385204553604,
                -0.03141247108578682,
                0.011946219950914383,
                0.004412688780575991,
                -0.03106539696455002,
                -0.009503123350441456,
                0.016951212659478188,
                -0.002773126121610403,
                -0.025747891515493393,
                0.006634417921304703,
                0.007656197063624859,
                -0.024234088137745857,
                0.006886132527142763,
                0.021097823977470398,
                -0.001601458410732448,
                0.018787100911140442,
                -0.04362152889370918,
                -0.034575968980789185,
                0.015385162085294724,
                -0.03530135750770569,
                0.014430015347898006,
                -0.026515236124396324,
                0.021102191880345345,
                -0.02353021688759327,
                0.02966727502644062,
                0.050086431205272675,
                -0.011921191588044167,
                0.0027269949205219746,
                0.08249270170927048,
                0.02716565690934658,
                -0.006746672093868256,
                0.021587874740362167,
                -0.037183213979005814,
                -0.012815420515835285,
                0.0011191901285201311,
                0.03679586201906204,
                0.035278983414173126,
                0.04875057935714722,
                -0.02286119945347309,
                -0.04214838147163391,
                -0.03849998861551285,
                0.06236063688993454,
                0.044104140251874924,
                -0.023378489539027214,
                0.020862014964222908,
                -0.04126862809062004,
                -0.05361470207571983,
                0.03543167933821678,
                -0.037058502435684204,
                -0.1019572764635086,
                -0.037970975041389465,
                0.02284643054008484,
                -0.0625116378068924,
                -0.0002842809772118926,
                -0.04850339516997337,
                -0.011197326704859734,
                -0.04571297764778137,
                -0.04189324378967285,
                -0.033376533538103104,
                0.019620735198259354,
                -0.08321736007928848,
                -0.01887170411646366,
                -0.029342012479901314,
                -0.002582647604867816,
                0.06641869246959686,
                -0.04462919384241104,
                0.032835859805345535,
                0.04543916881084442,
                -0.0009414393571205437,
                0.0734168216586113,
                0.02662082016468048,
                -0.033684369176626205,
                0.06956081837415695,
                0.05505276098847389,
                0.07197239249944687,
                0.02993021532893181,
                -0.024342067539691925,
                -0.020865892991423607,
                -0.016564801335334778,
                -0.0010303138988092542,
                0.05217384919524193,
                -0.011404099874198437,
                0.030579201877117157,
                -0.051459453999996185,
                0.00690460717305541,
                -0.04952090233564377,
                0.005133380182087421,
                0.005571051500737667,
                -0.005655957385897636,
                0.0321388840675354,
                -0.04991515725851059,
                0.015898017212748528,
                -0.059795018285512924,
                -0.010838625952601433,
                -0.020365549251437187,
                -0.04538356512784958,
                -0.05349376052618027,
                -0.004415248986333609,
                -0.014137092977762222,
                0.004844097886234522,
                0.012273912318050861,
                -0.04306613281369209,
                -4.9632049922365695e-05,
                0.049525510519742966,
                0.001797015080228448,
                0.045076292008161545,
                -0.030218426138162613,
                0.012343041598796844,
                -0.029977180063724518,
                0.02934562973678112,
                -0.012644948437809944,
                -0.012020851485431194,
                0.0418902263045311,
                0.0124983424320817,
                0.013042991980910301,
                -0.06322162598371506,
                -0.03391242399811745,
                0.0030903834849596024,
                -0.018429478630423546,
                -0.05301875248551369,
                -0.030290767550468445,
                -0.009917790070176125,
                0.01672457531094551,
                0.0038104441482573748,
                -0.010818707756698132,
                -0.05442998930811882,
                0.0008174810791388154,
                -0.02876124158501625,
                -0.06172413006424904,
                0.06454732269048691,
                0.035896819084882736,
                -0.00045747996773570776,
                0.036611173301935196,
                -0.05250971391797066,
                0.017737217247486115,
                0.04299655184149742,
                0.05930200591683388,
                -0.00992497056722641,
                -0.05478104203939438,
                0.017110414803028107,
                0.014408232644200325,
                0.04723567143082619,
                -0.07219114899635315,
                0.05286266654729843,
                0.012633463367819786,
                0.04618286341428757,
                0.003080196212977171,
                0.05225738510489464,
                -0.038546353578567505,
                0.0008078104583546519,
                0.009734128601849079,
                0.05424623563885689,
                -0.07750428467988968,
                -0.07936245203018188,
                0.07743752747774124,
                0.08108372986316681,
                0.027249054983258247,
                0.0391274094581604,
                -0.031749606132507324,
                0.06605608761310577,
                0.016348114237189293,
                0.003641001647338271,
                -0.0022616281639784575,
                -0.0011459605302661657,
                0.009750732220709324,
                -0.005226555280387402,
                0.0456562377512455,
                -0.012600922025740147,
                -0.01372718345373869,
                -0.05893363058567047,
                0.03979020565748215,
                -0.03572007268667221,
                -0.015240930020809174,
                0.10522085428237915,
                -0.03919652849435806,
                -0.011943316087126732,
                -0.034576818346977234,
                -0.0360361710190773,
                -0.05447780340909958,
                -0.08431119471788406,
                -0.011670448817312717,
                0.030529092997312546,
                -0.024733563885092735,
                -0.0023597325198352337,
                0.024605300277471542,
                0.006745792925357819,
                0.017383797094225883,
                0.014744183979928493,
                0.03240062668919563,
                -0.00015394206275232136,
                -0.06300202757120132,
                0.015429351478815079,
                -0.08965461701154709,
                0.03439147770404816,
                -0.03391236066818237,
                -0.03956548124551773,
                -0.024015238508582115,
                -0.009443412534892559,
                -0.017488189041614532,
                -0.03798241913318634,
                0.03366183862090111,
                -0.050994887948036194,
                -0.009791918098926544,
                0.01689452864229679,
                -0.0007307593477889895,
                0.02704056352376938,
                -0.05542900040745735,
                -0.00021790832397527993,
                0.03415354713797569,
                0.020999012514948845,
                0.0445457361638546,
                -0.007527270819991827,
                -0.029637666419148445,
                0.07226330041885376,
                -0.02132071554660797,
                0.04167205095291138,
                -0.02360367402434349,
                0.002501601120457053,
                -0.014660949818789959,
                -0.024525899440050125,
                0.02373419515788555,
                -0.03964844346046448,
                0.004927573259919882,
                -0.025405466556549072,
                -0.03173600509762764,
                -0.03130447119474411,
                -0.030356287956237793,
                -0.01945052482187748,
                -0.040003564208745956,
                -0.03353017196059227,
                -0.007604406215250492,
                -0.016902321949601173,
                -0.010675559751689434,
                0.0025988854467868805,
                -0.0025495763402432203,
                -0.040734902024269104,
                -0.045558203011751175,
                0.05456648766994476,
                0.005049153696745634,
                -0.019070694223046303,
                -0.05525845289230347,
                0.013907656073570251,
                -0.025032131001353264,
                0.054318685084581375,
                0.01685154251754284,
                -0.00699389586225152,
                0.07625094056129456,
                -0.023145627230405807,
                0.025248141959309578,
                0.014635601080954075,
                0.011154543608427048,
                0.02901320345699787,
                0.012729935348033905,
                0.004832177888602018,
                0.013262782245874405,
                -0.05916959047317505,
                -0.03312717378139496,
                0.000820328772533685,
                0.008051703684031963,
                0.034742582589387894,
                0.051812224090099335,
                0.00915150623768568,
                0.08436523377895355,
                -0.005145840812474489,
                0.04013029485940933,
                -0.015081104822456837,
                -0.007069283165037632,
                -0.055960942059755325,
                -0.021227553486824036,
                -0.06156107783317566,
                0.015498131513595581,
                0.05669310316443443,
                -0.027600938454270363,
                0.009376296773552895,
                -0.035663966089487076,
                -0.050176430493593216,
                0.056160181760787964,
                -0.04443032667040825,
                -5.2540699983299306e-33,
                0.01769048534333706,
                0.010295563377439976,
                0.01940302923321724,
                0.031284917145967484,
                -0.011968501843512058,
                -0.058801744133234024,
                0.05429304391145706,
                -0.05151549354195595,
                -0.03906760364770889,
                -0.03765138238668442,
                -0.010290279984474182,
                0.01256799791008234,
                0.002075708005577326,
                -0.017779354006052017,
                -0.046777572482824326,
                0.09349077194929123,
                0.012945307418704033,
                -0.001572891022078693,
                0.013423954136669636,
                -0.01596250757575035,
                -0.014514314942061901,
                -0.02377118356525898,
                -0.004057901445776224,
                -0.013968986459076405,
                0.0048026698641479015,
                -0.02971756085753441,
                -0.052544429898262024,
                0.08690240234136581,
                0.05460129678249359,
                0.02308245189487934,
                0.01405648235231638,
                -0.025672100484371185,
                0.010840085335075855,
                0.024419203400611877,
                -0.0007548498106189072,
                -0.012104038149118423,
                -0.01813036948442459,
                0.04430319368839264,
                0.028511598706245422,
                0.0572783537209034,
                0.0037620211951434612,
                0.009189058095216751,
                0.018821056932210922,
                -0.05392219126224518,
                0.029689926654100418,
                -0.016382064670324326,
                0.020075449720025063,
                -0.0036516489926725626,
                -0.0009889384964480996,
                -0.012242911383509636,
                -0.03796202316880226,
                -0.02796994149684906,
                -0.09231451153755188,
                0.03974735736846924,
                -0.0711653083562851,
                0.039515722543001175,
                -0.010666845366358757,
                -0.03325415030121803,
                -0.05837619677186012,
                -0.006936658173799515,
                0.010994230397045612,
                0.021067822352051735,
                0.0427100732922554,
                -0.011632590554654598,
                0.012624449096620083,
                0.025673970580101013,
                0.030761659145355225,
                0.02838769741356373,
                0.045791905373334885,
                0.008804749697446823,
                -0.013166018761694431,
                0.0344359427690506,
                -0.002466800855472684,
                0.009112157858908176,
                -0.05183235928416252,
                -0.03127945587038994,
                -0.03950398042798042,
                -0.00802962388843298,
                0.022240974009037018,
                -0.00922410562634468,
                -0.032608550041913986,
                0.031225046142935753,
                0.0019462797790765762,
                -0.021519040688872337,
                0.019123313948512077,
                -0.007819771766662598,
                -0.017649488523602486,
                -0.003114657010883093,
                0.037291232496500015,
                -0.006914402358233929,
                0.006647958420217037,
                0.02761005237698555,
                -0.058689482510089874,
                0.029340123757719994,
                0.11759144067764282,
                0.003848476568236947,
                0.013445300981402397,
                -0.03202933445572853,
                -0.02549167163670063,
                -0.003073106985539198,
                0.006812657229602337,
                0.02578267641365528,
                -0.024070177227258682,
                -0.015501964837312698,
                0.012391024269163609,
                0.07800086587667465,
                -0.010427847504615784,
                0.005714176222681999,
                -0.04958028718829155,
                0.006875927094370127,
                0.018124248832464218,
                0.05376993492245674,
                -0.017709091305732727,
                -0.05675532668828964,
                0.010994737036526203,
                -0.012819778174161911,
                0.03372914716601372,
                -0.02561834082007408,
                0.018550949171185493,
                0.03268898278474808,
                0.0682581216096878,
                -0.045355942100286484,
                -0.015563023276627064,
                0.008449936285614967,
                0.028742175549268723,
                -0.011470816098153591,
                0.01359462458640337,
                -0.06547743082046509,
                -0.04116874560713768,
                -0.05402153730392456,
                0.020910413935780525,
                0.030165936797857285,
                2.5338422915410774e-07,
                -0.0028492494020611048,
                -0.0033142969477921724,
                -0.024023819714784622,
                0.03258885070681572,
                0.03841334953904152,
                -0.040972813963890076,
                0.01012530829757452,
                -0.03296172618865967,
                -0.04410058259963989,
                0.031122464686632156,
                -0.0017387664411216974,
                -0.01905648782849312,
                -0.04489704221487045,
                -0.035158127546310425,
                -0.01978524588048458,
                -0.07081430405378342,
                0.04056578129529953,
                -0.0346699059009552,
                -0.020109890028834343,
                -0.0054500470869243145,
                0.032968487590551376,
                -0.018767662346363068,
                0.04413498193025589,
                0.012508462183177471,
                0.014137005433440208,
                -0.009092484600841999,
                -0.028124598786234856,
                -0.02880484238266945,
                0.020698778331279755,
                0.07378194481134415,
                0.056943245232105255,
                -0.03343226760625839,
                0.018557926639914513,
                0.04869694262742996,
                0.01104965340346098,
                0.014161624014377594,
                0.03190164640545845,
                0.056462109088897705,
                0.0052938926964998245,
                0.06102890893816948,
                0.034772709012031555,
                -0.007236795965582132,
                0.03175615519285202,
                0.004987974185496569,
                0.03752170875668526,
                -0.030263042077422142,
                -0.006272444501519203,
                0.0319100059568882,
                -0.09634700417518616,
                0.00952922273427248,
                -0.013773004524409771,
                0.030025910586118698,
                0.006487648468464613,
                -0.03233014792203903,
                0.028511175885796547,
                -0.04702400416135788,
                -0.04388979822397232,
                0.03056230954825878,
                0.05648517981171608,
                0.021059298887848854,
                -0.007723139598965645,
                -0.0227985680103302,
                0.010459096170961857,
                -0.0005938461981713772,
                0.04385705292224884,
                -0.009278569370508194,
                -0.027828726917505264,
                2.4700093413752548e-34,
                -0.02387254685163498,
                0.03165685385465622,
                0.013923637568950653,
                -0.025945644825696945,
                0.04099459573626518,
                -0.027734724804759026,
                -0.022316815331578255,
                -0.007554002106189728,
                0.03270729258656502,
                -0.03226760774850845,
                -0.008458510041236877
            ]
        },
        "Freelance": "Open to both",
        "Id": "fd6e1cf0-13f1-4ca1-bca7-9a00a8b79c55",
        "Industry": {
            "name": "\tDrilling / Rig",
            "rep": [
                -0.0011542883003130555,
                -0.13064201176166534,
                0.0027850023470818996,
                -0.03734075278043747,
                -0.012292912229895592,
                0.0361180379986763,
                -0.011133753694593906,
                0.012193123809993267,
                -0.03792116418480873,
                0.0041445340029895306,
                0.06432663649320602,
                0.0031063940841704607,
                0.037355855107307434,
                -0.01156067568808794,
                0.027203483507037163,
                0.006367753725498915,
                -0.04835761711001396,
                0.01653728261590004,
                -0.047293227165937424,
                0.027455823495984077,
                -0.030993469059467316,
                0.013423352502286434,
                -0.017363280057907104,
                0.0014814498135820031,
                -0.057087067514657974,
                0.013909406028687954,
                -0.03589138388633728,
                0.0027091549709439278,
                0.018432769924402237,
                0.01630929857492447,
                0.08288894593715668,
                0.003404004964977503,
                0.07949017733335495,
                0.026346618309617043,
                1.8317697367820074e-06,
                0.003945176489651203,
                -0.01249743066728115,
                0.013766670599579811,
                -0.023928267881274223,
                0.045534923672676086,
                -0.09494048357009888,
                -0.08838846534490585,
                0.022045297548174858,
                0.02838507853448391,
                -0.030738946050405502,
                -0.0779140293598175,
                0.04181251302361488,
                -0.08504419028759003,
                -0.02699524722993374,
                -0.012645993381738663,
                -0.023250311613082886,
                0.0049605113454163074,
                -0.0701802596449852,
                -0.031107505783438683,
                -0.021707339212298393,
                -0.02136765979230404,
                -0.014452061615884304,
                0.10206039994955063,
                0.0654570609331131,
                0.08984678238630295,
                0.0008116281242109835,
                -0.04364768788218498,
                0.030820753425359726,
                -0.0011388073908165097,
                0.0399969145655632,
                0.022702496498823166,
                0.06169847026467323,
                -0.03277784213423729,
                0.01469541434198618,
                -0.023083556443452835,
                -0.03272746503353119,
                -0.025846628472208977,
                0.013348620384931564,
                0.030699994415044785,
                -0.04563509672880173,
                -0.0895700678229332,
                -0.005512854550033808,
                0.04276910796761513,
                -0.03731536492705345,
                0.0062137022614479065,
                -0.014517937786877155,
                -0.03878016397356987,
                -0.002780458191409707,
                -0.004240379203110933,
                -0.0012976942816749215,
                -0.0033621680922806263,
                -0.0170009583234787,
                0.008111606352031231,
                -0.05183962360024452,
                0.0711304172873497,
                0.06696594506502151,
                0.00013807674986310303,
                -0.016933703795075417,
                -0.020183051005005836,
                -0.039463214576244354,
                0.012433447875082493,
                0.012768382206559181,
                0.06082974374294281,
                0.010080325417220592,
                0.048903197050094604,
                -0.03232439607381821,
                0.06856488436460495,
                0.04218665510416031,
                0.019165819510817528,
                -0.0514744333922863,
                -0.05496341735124588,
                0.02187289670109749,
                -0.0017082850681617856,
                -0.025985298678278923,
                0.025976311415433884,
                -0.04084976017475128,
                -0.014148060232400894,
                -0.02274945005774498,
                -0.02381104975938797,
                -0.01756589114665985,
                -0.030020015314221382,
                0.013108975253999233,
                0.03364221751689911,
                0.028917310759425163,
                -0.0012142283376306295,
                -0.015357797965407372,
                0.028670091181993484,
                -0.028522640466690063,
                -0.01301591657102108,
                0.029902126640081406,
                -0.05872628465294838,
                -0.024816490709781647,
                0.08890940248966217,
                0.05266233906149864,
                0.0020047370344400406,
                -0.03954199701547623,
                0.031212471425533295,
                0.044044021517038345,
                -0.026501664891839027,
                -0.025999147444963455,
                -0.04203883558511734,
                -0.0153788011521101,
                -0.002543048234656453,
                -0.06544797122478485,
                -0.017519846558570862,
                0.06291601061820984,
                0.004896026570349932,
                -0.013144567608833313,
                0.01702628843486309,
                0.01347193494439125,
                0.012611334212124348,
                -0.013374704867601395,
                0.09677686542272568,
                0.011791614815592766,
                -0.0155097134411335,
                0.04828966036438942,
                -0.009192823432385921,
                0.02834821678698063,
                -0.02474520541727543,
                0.04114532843232155,
                0.04619888588786125,
                -0.003811602247878909,
                0.017078947275877,
                0.003564747516065836,
                0.043999336659908295,
                -0.018822314217686653,
                -0.007229501381516457,
                -0.09083996713161469,
                0.01263436023145914,
                -0.005463117733597755,
                -0.015485549345612526,
                0.07279225438833237,
                -0.0017416427144780755,
                0.023239532485604286,
                0.010114484466612339,
                -0.023702790960669518,
                -0.011081448756158352,
                -0.012833365239202976,
                0.05512934550642967,
                -0.02850007638335228,
                -0.032657694071531296,
                0.04997533932328224,
                -0.057586852461099625,
                0.05447705462574959,
                0.02849390357732773,
                0.002298980485647917,
                -0.09920547902584076,
                0.01787753775715828,
                -0.034628819674253464,
                -0.039472587406635284,
                -0.034029051661491394,
                0.005027420353144407,
                -0.015209300443530083,
                -0.0641123354434967,
                0.004859547130763531,
                0.01866801828145981,
                -0.0019017055165022612,
                0.00275771995075047,
                -0.008968493901193142,
                -0.01293260045349598,
                0.013766609132289886,
                -0.025367774069309235,
                -0.004933727905154228,
                -0.04427679628133774,
                0.05012005567550659,
                -0.012101827189326286,
                0.01696626842021942,
                -0.07586276531219482,
                -0.07664687186479568,
                -0.04431799054145813,
                -0.037302106618881226,
                -0.039546359330415726,
                -0.006706149782985449,
                -0.011943030171096325,
                0.030670838430523872,
                -0.02779361605644226,
                0.0324389711022377,
                -0.006625560577958822,
                0.01428361888974905,
                -0.03871186822652817,
                0.01499792281538248,
                -0.012184010818600655,
                0.003619582625105977,
                0.01953921839594841,
                0.028024405241012573,
                -0.04220597445964813,
                0.011653419584035873,
                0.029706617817282677,
                0.014591298066079617,
                -0.0006165654049254954,
                -0.05637297034263611,
                0.020170878618955612,
                0.008646396920084953,
                -0.042790744453668594,
                -0.03761819750070572,
                0.02311702072620392,
                0.014129619114100933,
                0.06283050775527954,
                -0.026835164055228233,
                0.000311159499688074,
                0.035674262791872025,
                0.042653895914554596,
                0.039009030908346176,
                0.0029219703283160925,
                -0.04154534265398979,
                0.04014342278242111,
                -0.084076888859272,
                0.004796937108039856,
                -0.03454049676656723,
                0.041857555508613586,
                -0.0564926378428936,
                0.011279627680778503,
                0.028311457484960556,
                0.017195113003253937,
                -0.014999501407146454,
                -0.01561102643609047,
                -0.06687634438276291,
                0.016362736001610756,
                0.010130632668733597,
                0.033843714743852615,
                -0.025912264361977577,
                -0.00900615006685257,
                0.03771989047527313,
                -0.04303991049528122,
                -0.029832227155566216,
                0.06450585275888443,
                -0.05793147161602974,
                -0.004233135376125574,
                0.002902384614571929,
                0.018075862899422646,
                -0.012781800702214241,
                -0.01044884230941534,
                -0.018886622041463852,
                0.007218455895781517,
                -0.009212280623614788,
                0.041684217751026154,
                0.041749101132154465,
                0.008946320042014122,
                -0.03501708060503006,
                -0.017113858833909035,
                0.029642736539244652,
                -0.040377866476774216,
                -0.007064207922667265,
                -0.016615405678749084,
                -0.009315398521721363,
                0.033581677824258804,
                0.06109137460589409,
                -0.027542119845747948,
                -0.048707012087106705,
                0.016958076506853104,
                -0.0005377863999456167,
                -0.009141226299107075,
                -0.06018105894327164,
                -0.11465063691139221,
                -0.03301939740777016,
                0.04244609549641609,
                -0.07585948705673218,
                0.02958536334335804,
                -0.05421503260731697,
                0.006037084851413965,
                0.03548227250576019,
                0.030683036893606186,
                0.02498796209692955,
                -0.03806888312101364,
                0.0227164626121521,
                -0.023519432172179222,
                0.03980541229248047,
                0.004883925896137953,
                -0.010858774185180664,
                -0.00022955071472097188,
                -0.00015970555250532925,
                0.046843983232975006,
                -0.005368396639823914,
                0.00729405926540494,
                -0.03319210931658745,
                0.044812753796577454,
                0.02030157297849655,
                0.00799834169447422,
                -0.005771063268184662,
                -0.00330468756146729,
                -0.03497922047972679,
                0.04690477252006531,
                -0.0026182832662016153,
                -0.015141460113227367,
                0.03613557294011116,
                0.022937096655368805,
                -0.0008463059784844518,
                0.01174852903932333,
                0.02940484881401062,
                0.008417285978794098,
                0.033274147659540176,
                -0.0001855844457168132,
                -0.01348707266151905,
                0.005793159827589989,
                0.05752112716436386,
                -0.02577756904065609,
                -0.003338601440191269,
                -0.041503943502902985,
                0.03946935757994652,
                -0.015075592324137688,
                0.0384332500398159,
                0.005960191134363413,
                -0.004719748627394438,
                -0.015616309829056263,
                0.03989984840154648,
                0.011892112903296947,
                -0.02917403355240822,
                -0.051292404532432556,
                0.04889998584985733,
                -0.017759200185537338,
                0.04925864189863205,
                0.04599882662296295,
                0.00017569218471180648,
                -0.003174553858116269,
                0.0026288586668670177,
                0.007707567885518074,
                0.012392598204314709,
                -0.0065076411701738834,
                0.023699549958109856,
                -0.007701571565121412,
                0.05442332476377487,
                -0.015861624851822853,
                0.027367541566491127,
                -0.008540879935026169,
                0.036547914147377014,
                0.003020805772393942,
                0.0004260592395439744,
                -0.055074937641620636,
                0.0240876916795969,
                -0.05396386608481407,
                0.005700266920030117,
                0.02262120135128498,
                0.022071145474910736,
                -0.04160843417048454,
                -0.012619337998330593,
                -0.0692523717880249,
                0.006223510485142469,
                -0.04066434130072594,
                -0.04678306728601456,
                0.025042710825800896,
                0.08329875022172928,
                -0.00047597422963008285,
                0.042932383716106415,
                0.028462857007980347,
                -0.026659466326236725,
                -0.019999360665678978,
                -0.07680056244134903,
                0.008517535403370857,
                -0.012034525163471699,
                0.007547428831458092,
                0.029353484511375427,
                -0.008167142048478127,
                0.0035245774779468775,
                -0.03242748603224754,
                0.027316873893141747,
                -0.0201104786247015,
                0.018423346802592278,
                0.03597758710384369,
                -0.014647530391812325,
                0.017094627022743225,
                -0.03873337432742119,
                -0.09219679981470108,
                0.007297465577721596,
                0.0045235296711325645,
                0.046536885201931,
                0.04938509687781334,
                0.036970268934965134,
                -0.04712462052702904,
                -0.04223432391881943,
                -0.03709156811237335,
                -0.02313588745892048,
                -0.0007297047413885593,
                0.008938551880419254,
                0.020034700632095337,
                -0.01647973246872425,
                -0.026363449171185493,
                -0.018743600696325302,
                -0.002133170375600457,
                -0.02007737010717392,
                0.017447365447878838,
                0.0045015388168394566,
                0.003293538698926568,
                0.030303306877613068,
                -0.01991848833858967,
                -0.059499211609363556,
                -0.001068815472535789,
                -0.04068956524133682,
                0.04741041734814644,
                -0.04948745295405388,
                0.07806196063756943,
                -0.0002274907601531595,
                0.006590896751731634,
                0.022970158606767654,
                9.716691420180723e-05,
                -0.02433367446064949,
                -0.025638259947299957,
                -0.030666423961520195,
                -0.013613655231893063,
                -0.022581202909350395,
                0.03130652382969856,
                0.006253009662032127,
                -0.015144305303692818,
                0.005259258206933737,
                0.007980464957654476,
                -0.015692006796598434,
                0.006640658713877201,
                0.08716411888599396,
                0.05666758492588997,
                0.03188111260533333,
                0.04102307930588722,
                0.006408174987882376,
                0.016893824562430382,
                -0.06618479639291763,
                -0.058143842965364456,
                0.019851528108119965,
                0.03687480092048645,
                0.018034374341368675,
                0.01878160797059536,
                0.001972929807379842,
                0.006356026511639357,
                0.014542074874043465,
                -0.06245816871523857,
                -0.037778519093990326,
                -0.045240845531225204,
                0.003649639431387186,
                0.034174565225839615,
                0.03630761429667473,
                -0.02930849976837635,
                -0.04006858170032501,
                0.036683522164821625,
                -0.1582934409379959,
                0.006500180810689926,
                -0.03446979448199272,
                -0.03014097362756729,
                -0.04726076498627663,
                0.007194129750132561,
                0.0026404454838484526,
                -0.0039227912202477455,
                0.034476716071367264,
                -0.03438001126050949,
                0.024941468611359596,
                -0.02629218064248562,
                0.007992402650415897,
                -0.045797593891620636,
                -0.00552218034863472,
                0.015683777630329132,
                -0.019295435398817062,
                -0.003846833948045969,
                -0.04010558873414993,
                -0.007364603225141764,
                0.018654605373740196,
                0.03612379729747772,
                -0.01386414933949709,
                0.007234981283545494,
                0.030002456158399582,
                -0.03350408747792244,
                -0.030694449320435524,
                -0.014898519963026047,
                0.07228656113147736,
                0.02895592339336872,
                0.09107547998428345,
                0.022928545251488686,
                0.007846636697649956,
                0.02031893841922283,
                -0.00942352693527937,
                -0.052945591509342194,
                0.04927336797118187,
                0.0043860264122486115,
                -0.02504561096429825,
                -0.0153059596195817,
                0.0504303053021431,
                0.015743035823106766,
                0.0017201791051775217,
                -0.003958897665143013,
                0.004413176327943802,
                -0.0026135884691029787,
                0.0158549714833498,
                0.030374057590961456,
                -0.060453761368989944,
                0.07369178533554077,
                -0.0018929417710751295,
                -0.03378308564424515,
                -0.10836182534694672,
                -0.0022520471829921007,
                0.04278811812400818,
                -0.005068757105618715,
                -0.02252672240138054,
                0.02444184198975563,
                -0.013480360619723797,
                0.01692720130085945,
                -0.026799071580171585,
                0.03092873841524124,
                0.04951534792780876,
                -0.029667027294635773,
                -0.00885244831442833,
                -0.0480416864156723,
                -0.0038841268979012966,
                0.020518293604254723,
                0.0018540724413469434,
                -0.03924812003970146,
                0.09004512429237366,
                0.08723551034927368,
                0.01977284625172615,
                -0.004588195122778416,
                0.028626104816794395,
                0.0023997779935598373,
                0.007085807621479034,
                -0.05786234512925148,
                -0.018046952784061432,
                -0.017508871853351593,
                0.0538199320435524,
                0.05156037583947182,
                0.0011786491377279162,
                0.0461457222700119,
                -0.0071049220860004425,
                -0.002462472766637802,
                -0.043247103691101074,
                -0.035363078117370605,
                -0.008777279406785965,
                -0.03759586066007614,
                -6.535533328168924e-33,
                -0.002304902533069253,
                -0.01100220438092947,
                0.011309472844004631,
                0.0089945113286376,
                -0.025384007021784782,
                0.02127968892455101,
                0.03733031079173088,
                0.03642989695072174,
                -0.022964520379900932,
                -0.02835593745112419,
                -0.013245616108179092,
                0.026027224957942963,
                -0.00281035085208714,
                0.037342850118875504,
                -0.010591757483780384,
                0.02864065393805504,
                0.03006584383547306,
                0.005886318162083626,
                0.010777449235320091,
                -0.05174661800265312,
                -0.01732693612575531,
                0.02915269508957863,
                0.05598955973982811,
                0.03836892172694206,
                0.043806154280900955,
                0.04852644354104996,
                -0.030020132660865784,
                -0.01454177312552929,
                0.016028985381126404,
                0.0045091514475643635,
                -0.026676496490836143,
                -0.015495059080421925,
                0.01391592063009739,
                0.04386303946375847,
                0.009915010072290897,
                0.04672728478908539,
                -0.0689665749669075,
                -0.0193206574767828,
                -0.009137126617133617,
                0.02828214317560196,
                -0.04384039714932442,
                -0.003669839585199952,
                0.008117136545479298,
                -0.02732367068529129,
                -0.0060100252740085125,
                -0.05632299184799194,
                -0.021713552996516228,
                -0.0004061664512846619,
                0.01915280520915985,
                0.07738067954778671,
                -0.041775550693273544,
                -0.008489290252327919,
                0.0031071084085851908,
                -0.08371105790138245,
                0.014369864948093891,
                0.014724352397024632,
                -0.012711605988442898,
                0.006984357256442308,
                -0.08652681857347488,
                0.012906772084534168,
                -0.03518187627196312,
                0.007321689743548632,
                0.0024665496312081814,
                -0.03784200921654701,
                -0.00919608399271965,
                0.03546156361699104,
                -0.05590591952204704,
                -0.0140003627166152,
                -0.010124620981514454,
                -0.0341176837682724,
                -0.04493289813399315,
                0.0607791393995285,
                -0.008939727209508419,
                -0.03763098642230034,
                0.029868561774492264,
                -0.009431351907551289,
                -0.050903454422950745,
                0.01692425273358822,
                -9.861133003141731e-05,
                0.04796125367283821,
                0.04078463092446327,
                0.017638832330703735,
                0.00279027852229774,
                -0.005366215482354164,
                -0.04538968577980995,
                -0.019347360357642174,
                0.02545328624546528,
                0.027490856125950813,
                -0.007250096183270216,
                0.03526076674461365,
                -0.005693112034350634,
                0.002450793981552124,
                0.0036937633994966745,
                -0.0018505797488614917,
                0.129542276263237,
                0.04354071244597435,
                -0.003443379420787096,
                -0.05536385625600815,
                0.010371079668402672,
                -0.005045754835009575,
                -0.017225176095962524,
                0.056243572384119034,
                -0.036288172006607056,
                -0.029236534610390663,
                0.010664327070116997,
                -0.01615346223115921,
                -0.07260365784168243,
                0.012310451827943325,
                -0.03161884844303131,
                -0.0050896587781608105,
                -0.03376813977956772,
                0.025296729058027267,
                0.011176585219800472,
                0.05617954581975937,
                0.05688032507896423,
                -0.005330744199454784,
                -0.010547583922743797,
                -0.004903266206383705,
                -0.0005800461512990296,
                -0.03603127971291542,
                0.01094612292945385,
                0.02096966654062271,
                -0.06388206779956818,
                0.007814967073500156,
                0.02425745129585266,
                0.010917842388153076,
                0.05003776401281357,
                -0.05732930079102516,
                0.012786898761987686,
                -0.03082001395523548,
                -0.005151556804776192,
                0.009710036218166351,
                2.378807977265751e-07,
                -0.01038812194019556,
                0.018093308433890343,
                0.032182786613702774,
                -0.006791236810386181,
                0.02207104302942753,
                0.027007177472114563,
                0.03322590887546539,
                0.029860887676477432,
                0.02505539357662201,
                -0.05970781669020653,
                -0.007578378543257713,
                0.005450505297631025,
                0.00017431934247724712,
                -0.029016058892011642,
                -0.032222189009189606,
                -0.003817289602011442,
                0.13903337717056274,
                -0.001617457834072411,
                0.019432639703154564,
                -0.042045995593070984,
                0.041384629905223846,
                0.032258257269859314,
                -0.02156008780002594,
                -0.004301716573536396,
                -0.013198692351579666,
                -0.09324520081281662,
                -0.02157880924642086,
                0.025538023561239243,
                0.051340796053409576,
                -0.004300364293158054,
                0.03644588962197304,
                0.012726444751024246,
                0.017333664000034332,
                0.04326342046260834,
                -0.002869891934096813,
                -0.034741681069135666,
                0.05044811591506004,
                0.023275546729564667,
                0.005704279989004135,
                0.0856572762131691,
                -0.05554584041237831,
                -0.01785985194146633,
                0.008038307540118694,
                -0.023197466507554054,
                -0.035411983728408813,
                -0.04138566553592682,
                -0.01282185222953558,
                -0.029463285580277443,
                0.033072542399168015,
                0.025246232748031616,
                0.0031228887382894754,
                0.04825481399893761,
                0.04082164540886879,
                -0.0363457016646862,
                0.02672211267054081,
                -0.00014530724729411304,
                0.00840996578335762,
                0.04873121529817581,
                6.350378680508584e-05,
                0.09540227800607681,
                -0.02327529340982437,
                0.011163344606757164,
                -0.003739466890692711,
                0.0031329418998211622,
                0.0017995142843574286,
                -0.03087480366230011,
                -0.071982242166996,
                1.4954005466237652e-34,
                -0.023793231695890427,
                -0.014776053838431835,
                0.014120768755674362,
                0.0691203698515892,
                -0.001274053007364273,
                0.030198918655514717,
                -0.013494297862052917,
                -0.018419157713651657,
                0.03523783013224602,
                -0.03530648350715637,
                0.00040757370879873633
            ]
        },
        "Location": "Aberdeen, United Kingdom",
        "Remuneration": 15.625,
        "Services Required": {
            "name": " Upstream Intern",
            "rep": [
                -0.007625529542565346,
                0.04409159719944,
                -7.525145338149741e-05,
                -0.04437533766031265,
                0.011570454575121403,
                0.028461385518312454,
                0.001533580245450139,
                -0.015997575595974922,
                0.0495966337621212,
                -0.007845702581107616,
                0.05692511796951294,
                -0.021459249779582024,
                -0.01012577023357153,
                0.0305445846170187,
                0.022953009232878685,
                0.04694565013051033,
                0.026934368535876274,
                0.033308275043964386,
                -0.073756642639637,
                -0.050868116319179535,
                -0.020765474066138268,
                0.04044676572084427,
                -0.004704480059444904,
                0.016109706833958626,
                0.029761863872408867,
                -0.02740068919956684,
                0.013404054567217827,
                0.02541210502386093,
                -0.017326772212982178,
                0.040525056421756744,
                0.07207188010215759,
                0.014379561878740788,
                -0.00851417612284422,
                -0.024630321189761162,
                2.2182064185471972e-06,
                0.033110808581113815,
                0.01478509046137333,
                -0.013287557289004326,
                -0.043387386947870255,
                -0.02890559658408165,
                -0.0703047588467598,
                -0.06059866026043892,
                -0.005526463035494089,
                -0.0029928244184702635,
                -0.022083431482315063,
                0.04225330799818039,
                0.04906347766518593,
                -0.027263281866908073,
                0.025388959795236588,
                -0.009738613851368427,
                -0.018512815237045288,
                -0.030762553215026855,
                -0.06982063502073288,
                -0.009826887398958206,
                0.0318031832575798,
                -0.006094768177717924,
                -0.0026331341359764338,
                -0.005011721048504114,
                -0.018814483657479286,
                -0.004096963442862034,
                -0.009110786020755768,
                -0.0006328151794150472,
                -0.014903496019542217,
                -0.0013659128453582525,
                0.0550646036863327,
                0.038392744958400726,
                0.06440446525812149,
                -0.0073895081877708435,
                0.015250981785356998,
                -0.022633211687207222,
                -0.030679995194077492,
                0.05148664489388466,
                -0.006943734362721443,
                0.044148899614810944,
                -0.012624207884073257,
                0.018206333741545677,
                -0.027614710852503777,
                -0.05344482883810997,
                -0.019055206328630447,
                -0.003820155281573534,
                -0.020378747954964638,
                -0.023252779617905617,
                -0.003457955550402403,
                0.04846898093819618,
                0.004600467160344124,
                -0.022085661068558693,
                -0.024339959025382996,
                -0.003736116224899888,
                0.009166212752461433,
                0.059878163039684296,
                0.03372223302721977,
                -0.009906703606247902,
                -0.0030174432322382927,
                -0.0022760829888284206,
                -0.05181140825152397,
                -0.03499867022037506,
                0.008811922743916512,
                -0.024213891476392746,
                -0.06087149307131767,
                0.010277445428073406,
                0.027834588661789894,
                0.043239347636699677,
                0.042750284075737,
                0.05493876338005066,
                -0.022280771285295486,
                0.021881377324461937,
                -0.02328474260866642,
                0.1279856264591217,
                0.03062787838280201,
                0.02199721708893776,
                -0.014294417575001717,
                0.020167605951428413,
                -0.012998681515455246,
                0.052110593765974045,
                -0.03074699640274048,
                -0.016778282821178436,
                0.001622656243853271,
                0.021306820213794708,
                -0.0009876757394522429,
                0.023041311651468277,
                -0.032526928931474686,
                0.027762604877352715,
                -0.03216242417693138,
                0.01872284524142742,
                -0.0033409097231924534,
                0.0032166617456823587,
                0.016383960843086243,
                0.007435678504407406,
                0.016176173463463783,
                0.03170933946967125,
                -0.02859576977789402,
                -0.01739366538822651,
                0.004993037786334753,
                -0.05348668619990349,
                0.0002769638376776129,
                0.026844311505556107,
                -0.015977350994944572,
                0.010014886036515236,
                -0.023472391068935394,
                -0.014778794720768929,
                0.0757007822394371,
                0.0008273824350908399,
                -0.008861090987920761,
                0.008665932342410088,
                -0.007543377578258514,
                -0.010395579971373081,
                0.006820701994001865,
                -0.025407588109374046,
                0.01596399024128914,
                -0.02301163040101528,
                0.00127505196724087,
                0.0030995383858680725,
                0.046937841922044754,
                -0.0023991651833057404,
                0.019567390903830528,
                0.045198939740657806,
                0.007073614746332169,
                0.015948506072163582,
                -0.023990599438548088,
                0.07766248285770416,
                0.014382507652044296,
                -0.047851212322711945,
                -0.05284227430820465,
                0.04128657653927803,
                0.007532748859375715,
                0.021972572430968285,
                0.06680795550346375,
                -0.05693523958325386,
                -0.01599711924791336,
                0.03888242319226265,
                0.008614382706582546,
                -0.017951713874936104,
                -0.0024976625572890043,
                0.017514364793896675,
                0.00945565477013588,
                -0.026026790961623192,
                -0.012513132765889168,
                -0.06522109359502792,
                0.01125345192849636,
                -0.005018745549023151,
                -0.07642572373151779,
                -0.08214934915304184,
                -0.0109175406396389,
                0.04200449213385582,
                0.03056793473660946,
                0.0008457354851998389,
                0.003523478517308831,
                0.03426462039351463,
                -0.05324277654290199,
                0.03381295129656792,
                0.025916460901498795,
                -0.0033053704537451267,
                -0.1806023120880127,
                0.028382152318954468,
                -0.02042125165462494,
                -0.015303066000342369,
                -0.04240147024393082,
                -0.002304859459400177,
                0.010768542997539043,
                0.00020502180268522352,
                -0.010955383069813251,
                -0.024633120745420456,
                -0.025687694549560547,
                0.11890219897031784,
                0.01968446373939514,
                0.017175422981381416,
                -0.002639003098011017,
                -0.06380155682563782,
                0.02014942467212677,
                0.02381899394094944,
                0.0393790528178215,
                0.027308814227581024,
                0.017270857468247414,
                0.009047990664839745,
                -0.014888581819832325,
                -0.027042705565690994,
                0.00595044856891036,
                0.027681464329361916,
                -0.030889861285686493,
                0.06141962856054306,
                -0.03913020342588425,
                0.03763624280691147,
                -0.08703071624040604,
                0.033501386642456055,
                -0.13262978196144104,
                -0.01267915964126587,
                0.046663668006658554,
                0.015485228039324284,
                0.007753688842058182,
                -0.006394525989890099,
                0.01109362207353115,
                -0.003420888911932707,
                0.023011747747659683,
                -0.0326518639922142,
                0.06759914755821228,
                0.003189114620909095,
                0.07602711766958237,
                -0.04428916051983833,
                -0.000353842246113345,
                -0.06787553429603577,
                -0.02188652567565441,
                0.02494334988296032,
                -0.01776104047894478,
                0.008409373462200165,
                0.0956924557685852,
                0.014489310793578625,
                0.021313119679689407,
                0.007694343104958534,
                -0.0012378342216834426,
                0.015616928227245808,
                0.02458227053284645,
                -0.004971260204911232,
                -0.0063222190365195274,
                0.0002461208205204457,
                0.04163684323430061,
                -0.03609639033675194,
                0.01984475739300251,
                -0.030410071834921837,
                0.005851071793586016,
                0.027450064197182655,
                -0.07094558328390121,
                -0.024619778618216515,
                -0.042611293494701385,
                -0.0318034365773201,
                0.022973595187067986,
                0.01236730720847845,
                -0.028157852590084076,
                -0.009358679875731468,
                -0.029717059805989265,
                -0.001923650735989213,
                0.03943776339292526,
                0.01407097652554512,
                0.01264855358749628,
                -0.007620407268404961,
                0.006420429330319166,
                -0.00448816129937768,
                -0.0556781105697155,
                -6.190902786329389e-05,
                -0.014611572958528996,
                -0.039015498012304306,
                0.021778469905257225,
                0.06456515938043594,
                -0.02761100046336651,
                -0.005298029165714979,
                0.003384909126907587,
                0.03568975627422333,
                0.04981691762804985,
                -0.0961725115776062,
                -0.022499559447169304,
                -0.11680985987186432,
                0.01914774626493454,
                -0.04571026191115379,
                0.019568223506212234,
                -0.020098654553294182,
                -0.018644317984580994,
                0.004634012933820486,
                0.0024047086481004953,
                0.021979449316859245,
                0.015411159954965115,
                0.033711086958646774,
                0.019278157502412796,
                0.004450595006346703,
                -0.015451393090188503,
                -0.01741660386323929,
                -0.004910256247967482,
                -0.02761533297598362,
                0.05006604641675949,
                0.023639675229787827,
                -0.048021100461483,
                -0.06398231536149979,
                0.004652773030102253,
                0.014704280532896519,
                0.04184582829475403,
                0.06909936666488647,
                0.012582282535731792,
                -0.021633891388773918,
                -0.016861291602253914,
                -0.022565733641386032,
                -0.003431242424994707,
                -0.01243273913860321,
                -0.008969577960669994,
                0.04689754545688629,
                -0.004697552416473627,
                0.014270571060478687,
                -0.005169653799384832,
                -0.04510616511106491,
                -0.062410663813352585,
                0.00906657800078392,
                0.0035898620262742043,
                -0.11041828989982605,
                -0.00039642510819248855,
                0.02538212202489376,
                -0.02470826543867588,
                -0.046183403581380844,
                0.03530460596084595,
                -0.08560995012521744,
                -0.01930857077240944,
                -0.02981889247894287,
                -0.012748626992106438,
                0.007689932826906443,
                0.002896238351240754,
                0.0059907385148108006,
                -0.03682715445756912,
                -0.09294426441192627,
                0.022689547389745712,
                -0.0034866994246840477,
                0.05879056081175804,
                0.01614433154463768,
                -0.05540082976222038,
                -0.010309823788702488,
                -0.011116393841803074,
                0.025921598076820374,
                -0.04393050819635391,
                -0.017650142312049866,
                -0.00488863792270422,
                0.016894886270165443,
                0.005290165543556213,
                -0.013890290632843971,
                0.0011288621462881565,
                -0.0514712780714035,
                0.035500820726156235,
                -0.002947491593658924,
                0.07276654243469238,
                -0.03660016506910324,
                0.030747879296541214,
                0.003535614814609289,
                0.04152090102434158,
                0.005768877919763327,
                -0.011035013943910599,
                -0.03919538855552673,
                -0.03204796463251114,
                -0.0186116062104702,
                -0.08541909605264664,
                0.016544243320822716,
                0.011193857528269291,
                -0.06193941831588745,
                -0.006540550850331783,
                0.030140040442347527,
                0.052539657801389694,
                -0.001994977006688714,
                0.02864953689277172,
                -0.052741654217243195,
                -0.004765335004776716,
                0.008720596320927143,
                0.029350029304623604,
                -0.007881678640842438,
                -0.05606253445148468,
                -0.059346046298742294,
                -0.03345267474651337,
                0.05157366394996643,
                0.006393915973603725,
                -0.005599017255008221,
                -0.021740205585956573,
                -0.03241290897130966,
                0.010547862388193607,
                0.061342306435108185,
                -0.039117615669965744,
                -0.005604761652648449,
                0.008442570455372334,
                0.0317564383149147,
                0.02338160015642643,
                0.10060039162635803,
                -0.03818996623158455,
                0.014715590514242649,
                -0.002451311331242323,
                -0.02694886177778244,
                0.022840585559606552,
                -0.005755693651735783,
                -0.029707759618759155,
                0.016172541305422783,
                0.13464179635047913,
                -0.09611528366804123,
                0.0037099900655448437,
                0.03956754133105278,
                -0.022350916638970375,
                -0.11022022366523743,
                -0.016829879954457283,
                0.015644367784261703,
                0.01117895171046257,
                -0.05239665135741234,
                0.07707949727773666,
                0.013443158008158207,
                0.03301720693707466,
                -0.042779043316841125,
                0.030660275369882584,
                -0.04265807941555977,
                0.08826891332864761,
                0.06614738702774048,
                0.004932214040309191,
                -0.005772436037659645,
                -0.023416992276906967,
                -0.007666641380637884,
                -0.05174115300178528,
                0.06884340196847916,
                -0.020917421206831932,
                0.0101365577429533,
                -0.0016611107857897878,
                -0.013508856296539307,
                -0.01242065615952015,
                0.0017004875699058175,
                -0.007073801010847092,
                0.0365602932870388,
                0.04454461485147476,
                0.016777506098151207,
                0.012376464903354645,
                -0.015627460554242134,
                -0.005804645828902721,
                0.0015031462535262108,
                0.029167555272579193,
                -0.004334764089435339,
                0.05449126288294792,
                0.011806612834334373,
                -0.10345033556222916,
                -0.05697654187679291,
                0.01607855223119259,
                -0.041039593517780304,
                0.014529620297253132,
                0.0027284175157546997,
                -0.010432446375489235,
                0.06364361196756363,
                -0.013813765719532967,
                0.031101737171411514,
                0.0034534353762865067,
                -0.0025212066248059273,
                0.04165217652916908,
                -0.06660347431898117,
                0.16833697259426117,
                -0.06520114094018936,
                -0.0057784272357821465,
                -0.03370899334549904,
                -0.007674337364733219,
                0.0059820059686899185,
                -0.016615496948361397,
                -0.03751664236187935,
                0.00193599087651819,
                0.01900487020611763,
                -0.020480412989854813,
                0.020533662289381027,
                0.05227511748671532,
                0.013682486489415169,
                0.008047266863286495,
                0.00996013917028904,
                0.022816261276602745,
                -0.05202256888151169,
                0.013601843267679214,
                0.005626400001347065,
                0.017793789505958557,
                -0.01877732388675213,
                -0.03033418580889702,
                -0.02301427721977234,
                0.035485610365867615,
                -0.02282765507698059,
                0.002332176547497511,
                0.04615598917007446,
                0.021791057661175728,
                -0.030464770272374153,
                -0.013986493460834026,
                -0.023875964805483818,
                -0.11538124084472656,
                0.022712573409080505,
                -0.021040502935647964,
                0.019070859998464584,
                -0.008801130577921867,
                0.013844616711139679,
                0.008354492485523224,
                -0.009434318169951439,
                0.00020813722221646458,
                -0.010187426581978798,
                0.02209176868200302,
                0.021285418421030045,
                -0.011493248865008354,
                0.03306231275200844,
                -0.04311784356832504,
                0.00118881999514997,
                -0.002083214232698083,
                0.06171698868274689,
                -0.025849398225545883,
                0.030876707285642624,
                -0.026521485298871994,
                0.005860672332346439,
                0.018024245277047157,
                0.007526768371462822,
                -0.005915479734539986,
                -0.04460672289133072,
                -0.04721665754914284,
                0.0026535021606832743,
                0.024960679933428764,
                0.019398963078856468,
                -0.003178583923727274,
                0.030289649963378906,
                -0.03581804037094116,
                0.03352357819676399,
                -0.011529886163771152,
                0.03723603114485741,
                0.016426511108875275,
                0.009026424027979374,
                0.045784056186676025,
                0.026096219196915627,
                0.022500913590192795,
                0.040770210325717926,
                -0.011005043052136898,
                0.020310349762439728,
                -0.11339666694402695,
                0.0005563073791563511,
                -0.026075828820466995,
                0.02532564476132393,
                -0.008597826585173607,
                0.018725261092185974,
                0.008256186731159687,
                -0.008763190358877182,
                0.005265644285827875,
                0.005997618194669485,
                -0.04530077427625656,
                0.007099461276084185,
                0.03880646079778671,
                -7.762091888274901e-33,
                -0.01753460057079792,
                -0.04171527177095413,
                0.00276288203895092,
                -0.03860348090529442,
                -0.05653484910726547,
                0.011808134615421295,
                0.013779256492853165,
                -0.01833541877567768,
                -0.012691796757280827,
                -0.043654706329107285,
                -0.021193165332078934,
                0.05477132275700569,
                0.018953466787934303,
                -0.0018208768451586366,
                0.07614784687757492,
                0.022302504628896713,
                -0.0028159706853330135,
                0.023273253813385963,
                -0.028390934690833092,
                0.0034602591767907143,
                0.0036479858681559563,
                0.04948940873146057,
                -0.009893100708723068,
                0.018826158717274666,
                -0.002276606624945998,
                -0.019127806648612022,
                0.0012141226325184107,
                0.042072881013154984,
                -0.0418919175863266,
                -0.025207769125699997,
                0.009244406595826149,
                -0.010559345595538616,
                -0.028778335079550743,
                -0.028125371783971786,
                0.005768327973783016,
                -0.002010921249166131,
                -0.010604930110275745,
                0.017250603064894676,
                -0.02210438810288906,
                -0.015330757945775986,
                -0.062020622193813324,
                -0.02104431949555874,
                0.04046180471777916,
                -0.006614025682210922,
                0.015283830463886261,
                -0.05110647529363632,
                0.0005321191856637597,
                0.02902119792997837,
                0.029649093747138977,
                0.061546407639980316,
                -0.04563366249203682,
                -0.012957166880369186,
                -0.04653817415237427,
                0.0017066472209990025,
                -0.03098449669778347,
                -0.02041860669851303,
                0.02614009752869606,
                -0.012912417761981487,
                -0.02029246836900711,
                0.01489781029522419,
                -0.06668198853731155,
                0.03567611426115036,
                -0.01319872122257948,
                -0.02688819356262684,
                -0.035883329808712006,
                0.03934471681714058,
                0.07729967683553696,
                0.04125523194670677,
                -0.01563197374343872,
                0.03683590143918991,
                -0.016395842656493187,
                0.007348431274294853,
                -0.0017649151850491762,
                -0.09376155585050583,
                0.028262268751859665,
                -0.044625841081142426,
                0.009035984985530376,
                0.01605186052620411,
                0.06383610516786575,
                0.06386547535657883,
                -0.016400406137108803,
                0.0027769156731665134,
                0.02465839311480522,
                0.0018305787816643715,
                0.024713214486837387,
                -0.09560321271419525,
                0.0021838487591594458,
                -0.027674730867147446,
                0.001288395724259317,
                -5.581817822530866e-05,
                0.020233960822224617,
                -0.0023074045311659575,
                -0.008011743426322937,
                -0.013712231069803238,
                0.050371862947940826,
                -0.032421909272670746,
                0.0357455238699913,
                -0.009662582539021969,
                -0.0016202530823647976,
                0.00458852294832468,
                -0.08272414654493332,
                0.0373835563659668,
                0.012560776434838772,
                0.02966930717229843,
                0.04660964384675026,
                0.021292375400662422,
                0.0026884295511990786,
                0.021317940205335617,
                -0.002286996226757765,
                -0.01755315437912941,
                -0.015383024699985981,
                0.004847180098295212,
                0.009975018911063671,
                0.003941474482417107,
                -0.04271721467375755,
                0.022803865373134613,
                0.018715815618634224,
                -0.0003727475123014301,
                0.008444858714938164,
                0.0446472093462944,
                0.03239213302731514,
                -0.030992502346634865,
                -0.014856348745524883,
                0.030862603336572647,
                -0.0071433838456869125,
                0.01463340688496828,
                -0.011275391094386578,
                0.06170424073934555,
                -0.05186877027153969,
                -0.04295870661735535,
                0.0045106359757483006,
                -0.0045599727891385555,
                2.755691070888133e-07,
                -0.05811716243624687,
                0.005012835841625929,
                0.016908768564462662,
                -0.02068973332643509,
                -0.010012865997850895,
                -0.051927171647548676,
                -0.006526697427034378,
                0.004047813359647989,
                -0.00477267149835825,
                0.02693512849509716,
                0.012405630201101303,
                -0.03150355815887451,
                0.01859143190085888,
                -0.035320721566677094,
                -0.03800497204065323,
                -0.004916485399007797,
                -0.014642643742263317,
                0.009411372244358063,
                -0.009952079504728317,
                -0.06894045323133469,
                0.00031912236590869725,
                -0.0020483594853430986,
                0.020198216661810875,
                -0.048455651849508286,
                -0.004656417295336723,
                0.00785228330641985,
                -0.01757010817527771,
                0.04175615310668945,
                -0.037239838391542435,
                0.013353593647480011,
                0.048754528164863586,
                -0.01889543980360031,
                0.043531086295843124,
                0.04624601826071739,
                0.0036600190214812756,
                -0.028684988617897034,
                0.01910066045820713,
                -0.042675990611314774,
                -0.04488624259829521,
                -0.00865919329226017,
                -0.05094124376773834,
                0.0187761802226305,
                -0.005061060190200806,
                -0.021381011232733727,
                0.019587133079767227,
                -0.01990516670048237,
                0.011408587917685509,
                0.0045005991123616695,
                -0.08862332254648209,
                -0.04449480399489403,
                0.0005038284580223262,
                0.05739978700876236,
                -0.038572508841753006,
                0.028767528012394905,
                0.03825235366821289,
                0.010208891704678535,
                -0.014611857011914253,
                0.016036253422498703,
                0.06748569011688232,
                -0.034935396164655685,
                0.0006238012574613094,
                -0.023045986890792847,
                0.028337230905890465,
                0.10711764544248581,
                0.022110769525170326,
                -0.022539591416716576,
                -0.029731862246990204,
                2.2102432273540204e-34,
                -0.0051471153274178505,
                0.016088241711258888,
                0.0348832942545414,
                0.04480205476284027,
                0.035750772804021835,
                -0.017874564975500107,
                0.0871826633810997,
                0.01539447158575058,
                0.01246099453419447,
                0.03542706370353699,
                -0.022051578387618065
            ]
        },
        "Skills": {
            "name": [
                "Data Analysis and Visualization with PowerBi",
                "Powerpivot, Pivot Table with Microsoft Excel ",
                "Seismic Interpretation with Petrel",
                "Teamwork and communication",
                "Data Warehousing with Visual Studio and MySQL",
                "Python programming",
                "Data Processing and preparation with R",
                "Data Management with SQL",
                "Microsoft Word, Powerpoint"
            ],
            "rep": [
                -0.0442759245634079,
                0.004273588303476572,
                -0.059717677533626556,
                -0.05266321823000908,
                0.0056369309313595295,
                0.013134459033608437,
                0.008870481513440609,
                -0.0015955234412103891,
                -0.018767448142170906,
                0.07373835891485214,
                0.02455645427107811,
                0.024040300399065018,
                0.028092101216316223,
                0.11644313484430313,
                0.004692011512815952,
                -0.07051853090524673,
                0.051611095666885376,
                0.058389630168676376,
                0.017733760178089142,
                0.07663334161043167,
                0.024369509890675545,
                -0.017311209812760353,
                0.004611762706190348,
                0.00570466136559844,
                -0.07625573873519897,
                0.0021972814574837685,
                -0.012246716767549515,
                0.015829753130674362,
                -0.01232212409377098,
                -0.05166363716125488,
                0.019434364512562752,
                0.03617088869214058,
                0.024633001536130905,
                -0.020298004150390625,
                1.4883117955832859e-06,
                -0.048736896365880966,
                -0.0191961620002985,
                0.024256408214569092,
                -0.009558359161019325,
                0.026980550959706306,
                0.016675930470228195,
                0.020159510895609856,
                0.03571424260735512,
                -0.0017868836876004934,
                -0.003067292273044586,
                -0.0029327995143830776,
                -0.0047011650167405605,
                -0.013559170998632908,
                0.02218995988368988,
                -0.025338398292660713,
                -0.016752853989601135,
                0.03331112489104271,
                -0.021527303382754326,
                -0.0060673663392663,
                -0.017362520098686218,
                0.04528658837080002,
                -0.01177957933396101,
                -0.026189224794507027,
                0.07711747288703918,
                -0.031292978674173355,
                0.0270505603402853,
                -0.0011664527701213956,
                0.0048207733780145645,
                -0.015473717823624611,
                0.002249073935672641,
                0.038625169545412064,
                0.054509952664375305,
                -0.019137315452098846,
                -0.018635332584381104,
                0.00887654721736908,
                0.03558098152279854,
                0.0013845304492861032,
                0.006612187251448631,
                0.015393540263175964,
                -0.00607859855517745,
                0.02890663780272007,
                -0.02539145015180111,
                -0.01568802073597908,
                0.013815405778586864,
                -0.01046541053801775,
                -0.04661843553185463,
                0.0132778100669384,
                -0.05694466829299927,
                -0.03915729746222496,
                -0.04427657276391983,
                -0.028401101008057594,
                -0.016665548086166382,
                -0.028504066169261932,
                0.012126123532652855,
                -0.03745529428124428,
                0.02756446786224842,
                -0.014388172887265682,
                -0.019034314900636673,
                0.03423168137669563,
                -0.022381065413355827,
                -0.02165672928094864,
                0.05116492882370949,
                -0.003278618212789297,
                0.04210793972015381,
                0.005050075706094503,
                0.0727749913930893,
                0.006527110934257507,
                -0.0033927822951227427,
                0.03158184885978699,
                0.08720526099205017,
                0.009386482648551464,
                -0.023723788559436798,
                -0.02405756525695324,
                0.036645542830228806,
                0.022471940144896507,
                -0.02305096760392189,
                0.014173656702041626,
                -0.020516514778137207,
                0.026730146259069443,
                -0.0036243069916963577,
                0.029843101277947426,
                -0.05008326843380928,
                -0.006298039108514786,
                -0.07777231186628342,
                -0.026779621839523315,
                0.019358672201633453,
                -0.002211888087913394,
                0.02387816086411476,
                0.048372842371463776,
                -0.038771335035562515,
                0.009991049766540527,
                -0.028263838961720467,
                -0.018108800053596497,
                -0.05478934571146965,
                -0.09383656084537506,
                -0.018878702074289322,
                -0.0035470561124384403,
                0.006152017042040825,
                0.0035672110971063375,
                -0.0006721182144246995,
                -0.049003053456544876,
                0.03174901381134987,
                0.023908892646431923,
                -0.1021808460354805,
                -0.032465800642967224,
                0.010557631030678749,
                -0.03779049962759018,
                -0.0005769021227024496,
                0.015466372482478619,
                0.046677589416503906,
                -0.0249821487814188,
                -0.03590504452586174,
                0.014426041394472122,
                -0.007743515074253082,
                0.02339327149093151,
                0.010438373312354088,
                0.0029973292257636786,
                -0.022504713386297226,
                0.02559775859117508,
                -0.03732308745384216,
                0.0010767908534035087,
                -0.008044079877436161,
                -0.0202281903475523,
                -0.02254555933177471,
                -0.03747008740901947,
                -0.04140935093164444,
                -0.051363106817007065,
                0.027290262281894684,
                0.010898370295763016,
                0.044028203934431076,
                0.009299200028181076,
                -0.0544576495885849,
                0.021382244303822517,
                -0.007625143975019455,
                -0.05315406993031502,
                -0.05724460631608963,
                -0.03096488118171692,
                0.007453060708940029,
                0.08907066285610199,
                0.03012891672551632,
                -0.0354263111948967,
                0.05933350697159767,
                0.009901103563606739,
                -0.04783380404114723,
                0.019667183980345726,
                -0.004038062412291765,
                -0.12471456080675125,
                0.07353590428829193,
                0.013865651562809944,
                0.0057331351563334465,
                -0.016834242269396782,
                -0.03818647935986519,
                0.008796420879662037,
                -0.013908489607274532,
                -0.017450807616114616,
                0.015120293945074081,
                -0.015283665619790554,
                -0.010809501633048058,
                -0.015126298181712627,
                0.029949786141514778,
                0.03426136076450348,
                0.04000488296151161,
                -0.0001571123139001429,
                0.06606312096118927,
                -0.0465724878013134,
                -0.025376304984092712,
                -0.006751887034624815,
                0.048850979655981064,
                -0.020528636872768402,
                0.019610976800322533,
                -0.038108084350824356,
                0.08863960951566696,
                -0.007095400243997574,
                0.04972941055893898,
                -0.00920967385172844,
                0.031301021575927734,
                0.01258939504623413,
                -0.023793784901499748,
                -0.030791321769356728,
                -0.0006721491226926446,
                0.03984707593917847,
                -0.00392599543556571,
                -0.03301502391695976,
                -0.03545651584863663,
                0.04624951630830765,
                -0.041835177689790726,
                0.05642811208963394,
                0.006772737950086594,
                -0.03543330356478691,
                0.026453005149960518,
                0.10119939595460892,
                0.026449117809534073,
                0.010884991846978664,
                0.04626927524805069,
                0.03401432931423187,
                -0.03165574371814728,
                -0.01827177032828331,
                -0.0040126764215528965,
                -0.02186102792620659,
                0.03401679918169975,
                0.026706082746386528,
                0.07218782603740692,
                0.06759626418352127,
                -0.00891225878149271,
                -0.00809015054255724,
                0.03655540198087692,
                -0.06388989090919495,
                0.015772558748722076,
                -0.01795976422727108,
                0.012360011227428913,
                -0.08130138367414474,
                -0.025146067142486572,
                0.006140416022390127,
                0.022697698324918747,
                -0.052996497601270676,
                0.02739698253571987,
                -0.032247405499219894,
                0.0408649779856205,
                0.003542429069057107,
                0.017965802922844887,
                0.02084527537226677,
                -0.057245295494794846,
                0.00946385134011507,
                -0.0033182387705892324,
                0.07237987965345383,
                0.00910699088126421,
                -0.032263293862342834,
                -0.0510282926261425,
                -0.019446007907390594,
                -0.07074373215436935,
                -0.010363982990384102,
                0.07633021473884583,
                0.006066964473575354,
                -0.011272399686276913,
                0.05865367874503136,
                -0.019884318113327026,
                0.01640533097088337,
                0.046544190496206284,
                -0.011402389965951443,
                -0.013788125477731228,
                -0.018678903579711914,
                0.006746544502675533,
                -0.039350476115942,
                -0.046059973537921906,
                0.03553811088204384,
                0.03050236962735653,
                0.029507486149668694,
                0.004033377859741449,
                0.01902853138744831,
                -0.04477821663022041,
                -0.010425696149468422,
                0.058005500584840775,
                -0.03719961270689964,
                0.038123682141304016,
                0.026710668578743935,
                0.04391332343220711,
                -0.0009135195286944509,
                -0.010167679749429226,
                0.022856364026665688,
                -0.004002060275524855,
                -0.010996410623192787,
                0.05580928549170494,
                -0.04059882462024689,
                -0.017420250922441483,
                0.008570817299187183,
                -0.014583113603293896,
                -0.0495743453502655,
                -0.05116775259375572,
                0.03798437863588333,
                -0.004208101890981197,
                0.09853418171405792,
                0.08263155817985535,
                -0.05861075967550278,
                0.04572681710124016,
                -0.028317008167505264,
                0.07207316160202026,
                0.010047933086752892,
                0.027228228747844696,
                -0.007489020936191082,
                0.027635714039206505,
                0.004006966482847929,
                0.03032914362847805,
                -0.01608872599899769,
                0.0017158628907054663,
                0.015312718227505684,
                -0.008556395769119263,
                -0.02544892206788063,
                -0.02651923894882202,
                -0.02596791461110115,
                0.022303128615021706,
                -0.044992536306381226,
                -0.047746334224939346,
                0.026955660432577133,
                0.004088152199983597,
                0.00759022356942296,
                -0.007382209412753582,
                -0.09755917638540268,
                0.017931053414940834,
                0.026679597795009613,
                -0.021674824878573418,
                -0.017415277659893036,
                -0.0162432212382555,
                -0.04260857030749321,
                -0.047460731118917465,
                -0.019386501982808113,
                0.001974714221432805,
                0.04647021368145943,
                -0.020263344049453735,
                0.018944157287478447,
                0.06900472939014435,
                -0.045343849807977676,
                0.05183466151356697,
                0.0008060550317168236,
                -0.014170439913868904,
                0.018627692013978958,
                0.06450297683477402,
                -0.02867903746664524,
                -0.0013162738177925348,
                -0.010546187870204449,
                0.054367709904909134,
                -0.05529814958572388,
                0.024062760174274445,
                0.03167273476719856,
                0.0034979372285306454,
                -0.0002214826236013323,
                -0.026071980595588684,
                -0.012017397210001945,
                -0.0718100294470787,
                0.04751129448413849,
                -0.06804817169904709,
                0.03250008448958397,
                -0.03172970190644264,
                -0.0176104549318552,
                0.016524875536561012,
                -0.016257956624031067,
                0.0010253845248371363,
                0.016003791242837906,
                0.011826792731881142,
                0.022479165345430374,
                0.06569117307662964,
                -0.04455442354083061,
                0.0022203591652214527,
                -0.004992128349840641,
                -0.05184883996844292,
                -0.052387069910764694,
                -0.023422472178936005,
                -0.02637246809899807,
                0.06366981565952301,
                0.013970383442938328,
                0.020396273583173752,
                -0.06489253789186478,
                0.0019201101968064904,
                -0.027449889108538628,
                -0.0642375722527504,
                0.029858525842428207,
                -0.0014045577263459563,
                0.009869611822068691,
                -0.08435484766960144,
                -0.023036420345306396,
                0.01748647727072239,
                -0.006008688360452652,
                -0.01957443356513977,
                0.008617911487817764,
                0.019020024687051773,
                -0.004153815563768148,
                0.01370641216635704,
                -0.022532805800437927,
                -0.03231189399957657,
                -0.02678580768406391,
                -0.01469709537923336,
                -0.0488964319229126,
                0.015388845466077328,
                0.02068866789340973,
                -0.04456231743097305,
                0.020514754578471184,
                -0.031701743602752686,
                0.03405112028121948,
                -0.01816752180457115,
                -0.06379715353250504,
                0.08299161493778229,
                0.07909504324197769,
                -0.010802507400512695,
                -0.05112877115607262,
                0.04935815930366516,
                0.028282785788178444,
                0.021418657153844833,
                0.005063908640295267,
                0.004945904947817326,
                -0.039673708379268646,
                -0.020751431584358215,
                -0.09191616624593735,
                0.04754461720585823,
                0.017765501514077187,
                0.007065758109092712,
                0.03720429539680481,
                0.008192820474505424,
                0.016340844333171844,
                0.011418294161558151,
                0.04674922302365303,
                -0.03246447443962097,
                -0.0025255288928747177,
                0.01723368838429451,
                0.01592923514544964,
                -0.021334532648324966,
                -0.00982741080224514,
                -0.03593490645289421,
                0.04747709259390831,
                0.04048755019903183,
                0.02971283718943596,
                -0.00994501356035471,
                -0.014483505859971046,
                -0.008177944459021091,
                -0.02693321742117405,
                0.009867544285953045,
                0.06339141726493835,
                0.04534127935767174,
                0.019551957026124,
                -0.055702321231365204,
                0.01982124149799347,
                0.06778549402952194,
                -0.01393934153020382,
                0.013117549940943718,
                -0.01139154564589262,
                0.009047017432749271,
                0.05878264456987381,
                0.031710099428892136,
                0.025209760293364525,
                -0.03331366926431656,
                -0.010428347624838352,
                0.013992572203278542,
                0.0018376018851995468,
                0.03176206350326538,
                -0.02163419872522354,
                -0.07043527811765671,
                -0.11604795604944229,
                0.009616652503609657,
                0.029520098119974136,
                -0.043552007526159286,
                0.04227277636528015,
                -0.00959746353328228,
                0.01572921872138977,
                -0.005046429578214884,
                0.01402017567306757,
                0.04588910564780235,
                -0.005808905232697725,
                0.008806915022432804,
                0.025318944826722145,
                0.08825861662626266,
                -0.0732516348361969,
                -0.04050850868225098,
                0.015487853437662125,
                -0.03294687718153,
                0.003961126320064068,
                0.012137063778936863,
                -0.05352143943309784,
                0.02912183478474617,
                0.009711854159832,
                0.0077010332606732845,
                -0.04232088476419449,
                0.007090508006513119,
                -0.1292204111814499,
                -0.03664647042751312,
                0.0567186176776886,
                0.03527367487549782,
                0.045199569314718246,
                -0.02450428158044815,
                -0.003279393771663308,
                0.009012619033455849,
                -0.03068380430340767,
                -0.005386398173868656,
                0.02749502658843994,
                0.024637630209326744,
                0.0507817380130291,
                -0.047738078981637955,
                -0.05667193979024887,
                -0.02047009952366352,
                0.02972589060664177,
                0.014263631775975227,
                0.04090200364589691,
                0.061841949820518494,
                -0.01751571334898472,
                -0.08278082311153412,
                0.012065820395946503,
                -0.023896323516964912,
                -0.006412407848984003,
                -0.011649620719254017,
                0.007660208735615015,
                0.008836212567985058,
                -0.04305127263069153,
                0.08115334063768387,
                0.042048368602991104,
                -0.0016491477144882083,
                -0.05586760491132736,
                -0.02970833331346512,
                0.006052736192941666,
                0.014462730847299099,
                -0.013288979418575764,
                -0.06222609430551529,
                0.009541241452097893,
                -0.017812535166740417,
                0.01994553580880165,
                0.04792383685708046,
                0.0031992513686418533,
                -0.02263742685317993,
                0.03325578197836876,
                -0.06327923387289047,
                0.007727295160293579,
                -0.0061903526075184345,
                -0.02092040702700615,
                -0.006533980835229158,
                0.019647428765892982,
                0.013883601874113083,
                -0.057924069464206696,
                -0.006990214344114065,
                0.023790597915649414,
                0.04283004626631737,
                0.024164030328392982,
                -0.02767898514866829,
                -0.05931432917714119,
                -0.01983078010380268,
                -5.1760123979895305e-33,
                -0.0230006892234087,
                -0.023921247571706772,
                -0.02663385309278965,
                0.028792912140488625,
                -0.02196773886680603,
                -0.05290549248456955,
                0.008674436248838902,
                -0.033020079135894775,
                -0.02150399051606655,
                -0.03643104434013367,
                -0.037970252335071564,
                0.017752982676029205,
                0.012723833322525024,
                0.021891439333558083,
                0.012044066563248634,
                0.010518868453800678,
                0.0011662027100101113,
                0.05276072025299072,
                -0.03125317394733429,
                -0.009958844631910324,
                -0.03995056450366974,
                0.043863244354724884,
                -0.01481541246175766,
                -0.06676924228668213,
                0.007526594214141369,
                0.03668179363012314,
                -0.0426521971821785,
                0.007388723082840443,
                -0.014054086990654469,
                0.029687240719795227,
                0.028306975960731506,
                -0.043463584035634995,
                -0.013058467768132687,
                0.01517659705132246,
                -0.02063397504389286,
                0.02180163934826851,
                -0.049087267369031906,
                -0.039171501994132996,
                -0.027161957696080208,
                0.012267479673027992,
                -0.011221678927540779,
                0.0022034733556210995,
                0.04748693108558655,
                -0.027538906782865524,
                0.02379201538860798,
                -2.0470935851335526e-05,
                0.02301802858710289,
                0.01889641024172306,
                0.01578580215573311,
                0.04821031168103218,
                0.008477815426886082,
                -0.00596432713791728,
                -0.0498216412961483,
                0.06661073118448257,
                0.02119915746152401,
                -0.01549142599105835,
                -0.026280680671334267,
                0.044424623250961304,
                -0.04065784811973572,
                0.04703899100422859,
                0.09974943101406097,
                -0.03174405172467232,
                0.016946004703640938,
                -0.0007480459753423929,
                0.08868668973445892,
                -0.04655228927731514,
                0.014779330231249332,
                -0.03890965133905411,
                0.020457513630390167,
                -0.016481608152389526,
                0.0034927348606288433,
                0.01754806563258171,
                0.027969032526016235,
                -0.0012794006615877151,
                -0.04977203160524368,
                -0.04049963504076004,
                -0.08391760289669037,
                0.07249107956886292,
                0.04873840510845184,
                -0.012650939635932446,
                -0.041574202477931976,
                -0.03527814894914627,
                -0.02857518568634987,
                0.00014316919259727,
                0.05250981077551842,
                -0.03166263923048973,
                -0.04490028694272041,
                -0.027150480076670647,
                0.009681876748800278,
                -0.0275505892932415,
                -0.0051615177653729916,
                0.05743039399385452,
                -0.035681188106536865,
                0.01934238150715828,
                -0.008915349841117859,
                0.030395887792110443,
                0.05076519027352333,
                0.025401310995221138,
                0.04495617747306824,
                -0.03683266416192055,
                0.01834741234779358,
                0.022610601037740707,
                0.0049860854633152485,
                0.05305296182632446,
                0.029983969405293465,
                0.038102228194475174,
                0.011445770040154457,
                0.011263597756624222,
                -0.034158144146203995,
                -0.005857580807060003,
                0.008799790404736996,
                -0.011135644279420376,
                -0.042057979851961136,
                -0.022526763379573822,
                -0.015704944729804993,
                -0.01854998990893364,
                -0.001568296574987471,
                -0.007183190900832415,
                0.017883211374282837,
                0.01741044968366623,
                -0.002474670298397541,
                0.009021922945976257,
                -0.021724874153733253,
                -0.04907365143299103,
                -0.013774722814559937,
                -0.04244445264339447,
                0.016569258645176888,
                -0.05108543857932091,
                -0.0007489778799936175,
                -0.008178431540727615,
                0.01531021948903799,
                -0.011235927231609821,
                2.1782383896606916e-07,
                0.033256035298109055,
                0.04955431818962097,
                -0.04923591762781143,
                0.06999796628952026,
                -0.000497660890687257,
                -0.061915818601846695,
                -0.02883002907037735,
                -0.02083541825413704,
                -0.008884741924703121,
                0.0802813470363617,
                0.04315027594566345,
                -0.026182852685451508,
                0.003027732251212001,
                0.04349388927221298,
                0.01053785439580679,
                -0.04277428612112999,
                0.06463931500911713,
                -0.03075276128947735,
                -0.07899875938892365,
                -0.04026859626173973,
                0.05739419534802437,
                0.04391051083803177,
                0.035808198153972626,
                0.010681635700166225,
                0.049009770154953,
                -0.022591212764382362,
                -0.020093338564038277,
                -0.06063256040215492,
                0.023073531687259674,
                0.014398772269487381,
                -0.01275697909295559,
                0.03901175409555435,
                0.00441764248535037,
                0.009086493402719498,
                -0.027567822486162186,
                0.041546933352947235,
                -0.054069068282842636,
                0.0021042723674327135,
                -0.0005072994390502572,
                0.019317153841257095,
                0.04872767627239227,
                -0.03120390884578228,
                0.028874827548861504,
                -0.04582269489765167,
                0.025739461183547974,
                -0.05235060304403305,
                -0.012442832812666893,
                0.044677168130874634,
                -0.00431811111047864,
                -0.008496717549860477,
                0.05617664381861687,
                -0.0003223844396416098,
                -0.008579952642321587,
                0.00025673958589322865,
                -0.038341499865055084,
                -0.02335605025291443,
                0.021934010088443756,
                0.09126068651676178,
                0.020226072520017624,
                0.02663499489426613,
                0.00585959292948246,
                0.0029259896837174892,
                0.0067294598557055,
                0.013074788264930248,
                -0.012897921726107597,
                0.05151204764842987,
                0.010352219454944134,
                2.0670039601652956e-34,
                -0.024558167904615402,
                -0.026564957574009895,
                0.038968488574028015,
                -0.012423763982951641,
                0.05393951013684273,
                -0.014580699615180492,
                0.0034346841275691986,
                -0.007325890939682722,
                0.007786085829138756,
                -0.05360821262001991,
                -0.002092493698000908,
                -0.0643896758556366,
                -0.0573398694396019,
                -0.019631950184702873,
                -0.04302918165922165,
                -0.019309986382722855,
                0.011531023308634758,
                -0.00720421364530921,
                -0.002979273209348321,
                -0.051500894129276276,
                0.025324387475848198,
                -0.02020174451172352,
                -0.01850096881389618,
                0.06295793503522873,
                0.03190772235393524,
                0.048739589750766754,
                -0.01726037636399269,
                0.016878096386790276,
                0.06721854954957962,
                -0.03315884247422218,
                0.04227132350206375,
                0.02281550131738186,
                -0.018564308062195778,
                0.016371550038456917,
                0.02588009461760521,
                0.0036454051733016968,
                0.05372763052582741,
                0.03545559197664261,
                0.032856810837984085,
                -0.04363589361310005,
                0.01899387501180172,
                0.02432752214372158,
                0.031979333609342575,
                -0.008029015734791756,
                -0.01626269705593586,
                9.46434511206462e-07,
                -0.003532961243763566,
                -0.00934539269655943,
                0.008712603710591793,
                0.03324221819639206,
                -0.037376824766397476,
                -0.01698525995016098,
                -0.01958462968468666,
                0.036902230232954025,
                0.030254974961280823,
                -0.03622891753911972,
                -0.003191085997968912,
                -0.028814425691962242,
                0.007371213752776384,
                0.015271730720996857,
                0.00468180887401104,
                -0.013035211712121964,
                -0.004644245840609074,
                -0.007469202857464552,
                -0.015880484133958817,
                -0.011404531076550484,
                -0.010709021240472794,
                -0.06323742121458054,
                -0.03839554265141487,
                -0.05595635995268822,
                -0.0737396702170372,
                -0.015435573644936085,
                -0.0034201706293970346,
                0.012457139790058136,
                -0.0159564558416605,
                -0.007468201220035553,
                0.024849707260727882,
                0.027083296328783035,
                0.018960367888212204,
                0.01593131385743618,
                0.011245070025324821,
                -0.07283225655555725,
                0.001915715984068811,
                -0.0023487438447773457,
                -0.004629387054592371,
                0.0723545253276825,
                -0.009735959582030773,
                -0.033460915088653564,
                -0.022253405302762985,
                0.01559149008244276,
                -0.001533137634396553,
                0.04308095574378967,
                0.03209710121154785,
                -0.05710954591631889,
                -0.015362882986664772,
                0.0012004426680505276,
                -0.039487648755311966,
                -0.03525439649820328,
                -0.009356202557682991,
                0.050959955900907516,
                -0.025200365111231804,
                0.09892291575670242,
                -0.017118602991104126,
                0.02760908380150795,
                0.01403782982379198,
                0.004791806917637587,
                -0.002325120149180293,
                0.014982571825385094,
                0.07250286638736725,
                0.017642801627516747,
                -0.08198811113834381,
                0.027136510238051414,
                0.018039552494883537,
                0.0634676143527031,
                -0.02533916011452675,
                0.03252209350466728,
                0.027089659124612808,
                -0.04537366330623627,
                -0.009614923968911171,
                -0.022224465385079384,
                0.011765411123633385,
                -0.038980185985565186,
                0.021336937323212624,
                -0.01204548217356205,
                0.00682765943929553,
                -0.05284751579165459,
                0.04325665906071663,
                -0.032929886132478714,
                -0.0637991800904274,
                -0.037892214953899384,
                -0.028616122901439667,
                -0.00923663005232811,
                0.006034153513610363,
                0.0033324072137475014,
                -0.018492283299565315,
                -0.01383891236037016,
                0.15449272096157074,
                0.0065959300845861435,
                0.0022196006029844284,
                -0.02543712593615055,
                -0.070301353931427,
                -0.02194124460220337,
                0.030361587181687355,
                -0.0070198276080191135,
                0.029633622616529465,
                0.003387086559087038,
                0.013300408609211445,
                -0.006660682149231434,
                0.013490770012140274,
                -0.041647668927907944,
                -0.02913750149309635,
                -0.03354661539196968,
                -0.021352428942918777,
                0.002375780837610364,
                -0.008919132873415947,
                0.053186699748039246,
                -0.026563653722405434,
                -0.006282548885792494,
                0.031186189502477646,
                0.019101737067103386,
                0.029868604615330696,
                -0.04859247803688049,
                0.00473558297380805,
                0.010106747969985008,
                0.06770437210798264,
                -0.02294640801846981,
                -0.023571796715259552,
                -0.006109349429607391,
                -0.015256411395967007,
                0.017087221145629883,
                -0.025986487045884132,
                -0.02238897606730461,
                0.0007121111266314983,
                0.03459462150931358,
                -0.004940241575241089,
                0.005559520795941353,
                0.005345245823264122,
                0.011081893928349018,
                0.03245877847075462,
                -0.0633576437830925,
                -0.032086972147226334,
                -0.07838357985019684,
                -0.03719773143529892,
                0.037878766655921936,
                0.062231820076704025,
                0.01418252382427454,
                -0.04303807392716408,
                0.020591096952557564,
                0.009730071760714054,
                -0.06997347623109818,
                -0.02153220772743225,
                0.03489432483911514,
                -0.07401779294013977,
                0.07345444709062576,
                -0.004281208384782076,
                0.0030143102630972862,
                -0.047286853194236755,
                -0.053929660469293594,
                0.02128439024090767,
                -0.04968145489692688,
                -0.023330725729465485,
                0.03635380417108536,
                -0.025106389075517654,
                -0.050555288791656494,
                0.04160045459866524,
                -0.008605838753283024,
                0.04333600774407387,
                0.032105304300785065,
                0.01393533032387495,
                -0.007302648387849331,
                -0.01123994030058384,
                -0.02450203336775303,
                0.000351508118910715,
                0.008770653046667576,
                -0.0015800357796251774,
                -0.033662501722574234,
                0.014249930158257484,
                0.012737346813082695,
                -0.0313858725130558,
                -0.0008057670784182847,
                0.01509802509099245,
                0.02148483134806156,
                0.007242855615913868,
                -0.018592651933431625,
                -0.009186133742332458,
                0.010506266728043556,
                0.08509913086891174,
                -0.04640570655465126,
                -0.034925878047943115,
                0.004968844819813967,
                -0.005467783194035292,
                -0.004502933472394943,
                -0.005710727069526911,
                -0.019148612394928932,
                -0.04883145913481712,
                0.006552797742187977,
                0.10421738773584366,
                0.04158814251422882,
                -0.03128884732723236,
                0.03976515680551529,
                0.06293555349111557,
                0.011782892048358917,
                0.017060089856386185,
                0.042571671307086945,
                -0.0452842116355896,
                0.016712237149477005,
                0.04594578221440315,
                0.014436181634664536,
                0.04219666123390198,
                0.015702838078141212,
                -0.001602227333933115,
                -0.015315135009586811,
                -0.06830202043056488,
                0.028916753828525543,
                -0.007103370502591133,
                0.0024283775128424168,
                0.01737813837826252,
                -0.10024502128362656,
                0.01909557543694973,
                0.06486904621124268,
                -0.055177152156829834,
                0.01686844602227211,
                -0.05183875560760498,
                0.012434992007911205,
                0.005345277953892946,
                0.03662090003490448,
                -0.03499351069331169,
                -0.08031779527664185,
                -0.051280632615089417,
                0.00586369913071394,
                0.06347650289535522,
                0.028984839096665382,
                -0.0006734410999342799,
                -0.03883577883243561,
                -0.03604182228446007,
                -0.04834548011422157,
                -0.014666042290627956,
                0.05954880267381668,
                -0.0007581721292808652,
                0.027109351009130478,
                0.0642397329211235,
                -0.04984239116311073,
                0.022889861837029457,
                0.01156169269233942,
                -0.014348731376230717,
                -0.020174331963062286,
                -0.0758051946759224,
                -0.03845294937491417,
                -0.012997198849916458,
                -0.05486177280545235,
                0.06118633598089218,
                -0.02450997196137905,
                0.017953140661120415,
                -0.00958526786416769,
                0.03865911439061165,
                -0.027638979256153107,
                -0.05561743304133415,
                0.026835961267352104,
                -0.014573998749256134,
                0.07029538601636887,
                0.026930082589387894,
                0.06794469803571701,
                -0.02442477084696293,
                -0.0019358998397365212,
                0.050404805690050125,
                0.015520872548222542,
                0.03293488174676895,
                0.07681670039892197,
                -0.05844555422663689,
                0.01832299679517746,
                2.855950924640638e-06,
                -0.028377149254083633,
                -0.020186718553304672,
                -0.011518470011651516,
                0.02346649393439293,
                -0.005661447066813707,
                0.05310004949569702,
                0.009213014505803585,
                -0.056678034365177155,
                0.027740804478526115,
                -0.007037561386823654,
                0.029210690408945084,
                -0.004607099574059248,
                0.005789225455373526,
                -0.07170581072568893,
                -0.008033799938857555,
                0.004977724980562925,
                0.0695786401629448,
                0.03597893565893173,
                0.042942047119140625,
                -0.01543786283582449,
                0.004758468829095364,
                0.06278008967638016,
                -0.057939060032367706,
                -0.022488174960017204,
                -0.0016857676673680544,
                -0.010741527192294598,
                -0.015329023823142052,
                0.03320951759815216,
                0.03346015512943268,
                -0.01626609079539776,
                -0.007232407573610544,
                -0.08594371378421783,
                0.0774390697479248,
                0.007426709868013859,
                -0.04173453897237778,
                -0.0263377632945776,
                -0.011417455971240997,
                0.08747028559446335,
                0.01673743687570095,
                -0.020597711205482483,
                -0.03414659574627876,
                0.02316245250403881,
                0.004400524310767651,
                -0.014971381053328514,
                0.03311672434210777,
                0.013788159005343914,
                0.006834909785538912,
                0.011213340796530247,
                -0.0400271862745285,
                -0.02706795558333397,
                0.07692555338144302,
                -0.00214755954220891,
                -0.007785349152982235,
                -0.019875235855579376,
                0.021387171000242233,
                0.018202541396021843,
                0.025275370106101036,
                0.054692842066287994,
                -0.05853688716888428,
                -0.016969526186585426,
                -0.008572219870984554,
                0.001378464512526989,
                -0.0354258231818676,
                0.007470002397894859,
                -0.0018967074574902654,
                0.031040767207741737,
                -0.028759434819221497,
                -0.0024668944533914328,
                0.010920499451458454,
                0.018220584839582443,
                -0.03703103959560394,
                0.04697265475988388,
                0.0472964309155941,
                -0.0019642049446702003,
                0.028979679569602013,
                -0.008922065608203411,
                -0.022641511633992195,
                0.005812729708850384,
                -0.06169528514146805,
                0.031286779791116714,
                0.03890576213598251,
                0.00936951208859682,
                0.03192008659243584,
                -0.014475110918283463,
                0.0033215784933418036,
                -0.003342460608109832,
                0.018186043947935104,
                -0.03435362130403519,
                -0.048425689339637756,
                -0.008901004679501057,
                -0.016532301902770996,
                -0.025241730734705925,
                -0.16261886060237885,
                0.01955195888876915,
                -0.010735888034105301,
                -0.019528605043888092,
                -0.0037355059757828712,
                0.038904834538698196,
                0.04619409888982773,
                -0.04072170704603195,
                0.015226665884256363,
                -0.02747880294919014,
                0.014210126362740993,
                0.009074797853827477,
                -0.003126065945252776,
                -0.0637887567281723,
                0.0001386404619552195,
                0.041991036385297775,
                -0.08701641112565994,
                -0.03188629820942879,
                0.0004293900274205953,
                0.008340858854353428,
                -0.049746543169021606,
                -0.015455935150384903,
                0.02504008077085018,
                -0.016158347949385643,
                0.007206093519926071,
                0.008539102971553802,
                -0.02320142649114132,
                -0.03504712134599686,
                -0.03172478452324867,
                0.05616576969623566,
                0.00900642853230238,
                0.01668561063706875,
                -0.01864669844508171,
                0.002136231865733862,
                -0.010860040783882141,
                -0.01891304738819599,
                -0.015198872424662113,
                0.031660109758377075,
                -0.007253590039908886,
                0.023105094209313393,
                0.0032429301645606756,
                0.009148025885224342,
                -0.04452746361494064,
                0.02863304316997528,
                0.057557154446840286,
                -0.02381516993045807,
                -0.0045242467895150185,
                0.005487306509166956,
                0.04399599879980087,
                0.055140674114227295,
                0.04328286275267601,
                0.012960083782672882,
                0.010100232437252998,
                -0.019121699035167694,
                0.027490630745887756,
                -0.01271370705217123,
                -0.05321326106786728,
                0.04169231653213501,
                0.04078629985451698,
                0.04227958992123604,
                -0.046848099678754807,
                -0.013377546332776546,
                0.09927015006542206,
                -0.008805747143924236,
                0.012294811196625233,
                0.03490176424384117,
                0.005198891274631023,
                0.0025790948420763016,
                -0.051482412964105606,
                0.02096160128712654,
                -0.05800662562251091,
                -0.01493068877607584,
                0.07515989243984222,
                -0.037499330937862396,
                0.07426518201828003,
                -0.03047267720103264,
                -0.03833985701203346,
                -0.06880839169025421,
                0.00021465320605784655,
                0.021202631294727325,
                0.032168515026569366,
                0.01995084434747696,
                0.0036996554117649794,
                -0.022708654403686523,
                0.02114851213991642,
                -0.016313478350639343,
                0.02023843303322792,
                0.010637783445417881,
                0.014125467278063297,
                0.029259759932756424,
                0.022437116131186485,
                -0.08878543227910995,
                -0.011327874846756458,
                0.011975799687206745,
                -0.020395873114466667,
                -0.06181187555193901,
                -0.01916627772152424,
                -0.022669820114970207,
                0.00859933253377676,
                0.004482833202928305,
                0.024578753858804703,
                -0.03105909191071987,
                -0.004330768249928951,
                -0.08383162319660187,
                -0.05340756103396416,
                -0.02183540351688862,
                0.005480851046741009,
                0.031194107607007027,
                -0.034135255962610245,
                -0.010159319266676903,
                0.020089125260710716,
                0.0003857334959320724,
                -0.04549867659807205,
                -0.004531795624643564,
                0.028544051572680473,
                -0.028163811191916466,
                -0.005909207742661238,
                -0.030301518738269806,
                -0.023640111088752747,
                0.030288901180028915,
                0.014889828860759735,
                0.021880129352211952,
                -0.00702336011454463,
                -0.022242696955800056,
                -0.06869237124919891,
                0.032731130719184875,
                -0.010266904719173908,
                0.008263429626822472,
                -0.026438526809215546,
                0.06660722941160202,
                0.01734418421983719,
                -0.011466769501566887,
                0.04365253821015358,
                0.035803020000457764,
                0.007435377221554518,
                -0.05405909940600395,
                -0.042779091745615005,
                0.056677188724279404,
                0.04026372358202934,
                -0.03243384137749672,
                -0.039691261947155,
                0.01727280393242836,
                0.011037571355700493,
                -0.022385461255908012,
                -0.03534805402159691,
                -0.052772555500268936,
                0.025481754913926125,
                0.0335468128323555,
                -0.05731428414583206,
                0.010614058002829552,
                0.008821943774819374,
                -0.017918553203344345,
                0.08129838854074478,
                0.01191786490380764,
                -0.047533366829156876,
                0.036958351731300354,
                0.028079891577363014,
                0.04242653772234917,
                -0.008601954206824303,
                0.013673678040504456,
                0.05242127180099487,
                -0.025169076398015022,
                -0.01005854457616806,
                -4.0934144269768514e-33,
                -0.03809783607721329,
                0.00044722497113980353,
                -0.044504739344120026,
                0.044231612235307693,
                -0.028636010363698006,
                -0.032939307391643524,
                0.009310277178883553,
                -0.013270765542984009,
                -0.016891615465283394,
                -0.04553189501166344,
                -0.03745027258992195,
                0.042986106127500534,
                0.023569894954562187,
                -0.01326918788254261,
                -0.02439715340733528,
                -0.013126357458531857,
                -0.040839239954948425,
                -0.0028064497746527195,
                0.010932883247733116,
                0.002948487177491188,
                -0.05716010555624962,
                -0.0015225777169689536,
                -0.015468723140656948,
                -0.014851839281618595,
                0.004669686313718557,
                0.020055651664733887,
                -0.03518320247530937,
                0.011548995040357113,
                -0.015115994960069656,
                -0.021534375846385956,
                0.021019941195845604,
                -0.057628974318504333,
                0.030468903481960297,
                -0.05917927622795105,
                -0.021015504375100136,
                -0.02723436988890171,
                -0.027722438797354698,
                0.05909246578812599,
                -0.03211374580860138,
                -0.025001561269164085,
                -0.03459492698311806,
                0.03223162144422531,
                0.04759349673986435,
                0.017168613150715828,
                0.00521714985370636,
                -0.015091154724359512,
                0.007506867405027151,
                0.015828123316168785,
                -0.003720189444720745,
                0.03388455882668495,
                0.04211171343922615,
                0.023846512660384178,
                0.03346393257379532,
                0.05851498991250992,
                -0.039505910128355026,
                0.012712907046079636,
                0.001708421972580254,
                0.009177607484161854,
                0.023741088807582855,
                -0.004085050895810127,
                0.06484505534172058,
                -0.0003869310603477061,
                0.03420114517211914,
                0.02235269360244274,
                0.05316626653075218,
                -0.10278626531362534,
                0.026239320635795593,
                0.016013991087675095,
                -0.02821432426571846,
                -0.02141512930393219,
                -0.03380018100142479,
                -0.014126819558441639,
                0.013702675700187683,
                0.015125446021556854,
                -0.038316793739795685,
                -0.01429116539657116,
                -0.030897041782736778,
                0.03810076788067818,
                0.011937431991100311,
                -0.00803434383124113,
                0.029851941391825676,
                -0.035562850534915924,
                -0.011051679961383343,
                -0.010223088786005974,
                0.08496057242155075,
                -0.07159531861543655,
                -0.013538852334022522,
                0.03779952600598335,
                -0.006090090610086918,
                0.0323856882750988,
                0.049103159457445145,
                0.07907305657863617,
                -0.070809505879879,
                -0.0008940545376390219,
                0.004951027687638998,
                0.028949074447155,
                -0.002384189050644636,
                0.054833970963954926,
                0.009575003758072853,
                -0.022714955732226372,
                0.0073498389683663845,
                -0.01891607977449894,
                -0.011624959297478199,
                0.03789035230875015,
                0.011976774781942368,
                0.0418865829706192,
                0.018934572115540504,
                0.001040713395923376,
                -0.029149070382118225,
                -0.009951203130185604,
                0.01260438747704029,
                0.020745644345879555,
                0.03434544801712036,
                0.019591860473155975,
                -0.054072439670562744,
                0.022485850378870964,
                -0.01622185856103897,
                0.010130764916539192,
                0.0327441431581974,
                -0.007191353943198919,
                -0.014658384025096893,
                0.0028026250656694174,
                -0.024540975689888,
                -0.02662542834877968,
                -0.0035271833185106516,
                -0.029765261337161064,
                0.027667423710227013,
                0.013895061798393726,
                -0.029567567631602287,
                0.021792419254779816,
                -0.0035171450581401587,
                -0.026641465723514557,
                1.789075270153262e-07,
                -0.007978029549121857,
                -0.035576194524765015,
                -0.006833730265498161,
                -0.04296095296740532,
                0.0029074707999825478,
                -0.01950187422335148,
                0.006068781018257141,
                0.02868255227804184,
                0.04132384434342384,
                0.08247701078653336,
                0.05918722599744797,
                0.007964350283145905,
                0.04110460728406906,
                0.07487856596708298,
                -0.015509524382650852,
                -0.09333835542201996,
                0.045635927468538284,
                -0.013993384316563606,
                -0.04551729932427406,
                0.013383091427385807,
                0.03440769761800766,
                0.04826526343822479,
                0.008191338740289211,
                0.011699024587869644,
                0.048481252044439316,
                -0.000748861173633486,
                0.01581108197569847,
                -0.0653911605477333,
                -0.004056535195559263,
                -0.010911399498581886,
                -0.03600246086716652,
                0.002101366175338626,
                -0.02736218087375164,
                0.07203812897205353,
                0.014007829129695892,
                0.06404141336679459,
                -0.030591802671551704,
                0.018632983788847923,
                0.03800904005765915,
                -0.008560195565223694,
                0.023792754858732224,
                -0.06499910354614258,
                0.00479078246280551,
                -0.05683181434869766,
                -0.03327583521604538,
                -0.057095713913440704,
                -0.003278840333223343,
                0.0800439864397049,
                0.0051111518405377865,
                0.07358093559741974,
                0.06403863430023193,
                0.06621847301721573,
                -0.06454424560070038,
                0.01619580388069153,
                -0.05237555131316185,
                -0.022281421348452568,
                -0.04198262095451355,
                0.04279644414782524,
                -0.05332017317414284,
                0.015230812132358551,
                0.011548218317329884,
                0.03737284615635872,
                0.03137150779366493,
                -0.038046225905418396,
                0.009091030806303024,
                0.11416004598140717,
                0.013496242463588715,
                1.269823639878449e-34,
                -0.04565858840942383,
                -0.019469257444143295,
                0.04270118474960327,
                -0.058060891926288605,
                0.025164596736431122,
                -0.022128306329250336,
                0.06311357021331787,
                0.016939247027039528,
                -0.010117455385625362,
                -0.018275773152709007,
                -0.002943117404356599,
                -0.03895851597189903,
                0.04150013253092766,
                -0.0007469468400813639,
                0.053980693221092224,
                0.012226451188325882,
                -0.009199206717312336,
                -0.03440862521529198,
                0.009180948138237,
                -0.023898614570498466,
                0.018367089331150055,
                0.006885590497404337,
                0.0016307247569784522,
                -0.02438637800514698,
                0.00794387049973011,
                0.015356837771832943,
                -0.07629210501909256,
                -0.03651164844632149,
                -0.01812870427966118,
                0.08209865540266037,
                0.047716960310935974,
                -0.024519743397831917,
                -0.021074285730719566,
                -0.009640968404710293,
                0.0007220293628051877,
                -0.005131361540406942,
                -0.017779849469661713,
                -0.029743297025561333,
                0.009213106706738472,
                0.034743621945381165,
                -0.04469131678342819,
                0.018685264512896538,
                0.0018458495615050197,
                0.013160168193280697,
                0.017272839322686195,
                1.3199216937209712e-06,
                0.015310880728065968,
                -0.015125825069844723,
                0.0309209693223238,
                0.07836615294218063,
                0.0674094632267952,
                -0.022049518302083015,
                -0.013088093139231205,
                0.05346890166401863,
                -0.06261300295591354,
                -0.003638110589236021,
                -0.05792970582842827,
                -0.05676428973674774,
                -0.04296799376606941,
                -0.018715010955929756,
                -0.00706603704020381,
                0.015408934094011784,
                0.03774814307689667,
                -0.05140654370188713,
                -0.028600433841347694,
                0.010929668322205544,
                -0.05770688131451607,
                -0.00922414567321539,
                0.016815083101391792,
                -0.042130935937166214,
                0.12715619802474976,
                -0.02913087233901024,
                -0.009005672298371792,
                0.022509295493364334,
                -0.009531812742352486,
                0.012745467945933342,
                -0.006046107970178127,
                -0.057935286313295364,
                0.04873026907444,
                -0.014718543738126755,
                0.0026455146726220846,
                0.06573694944381714,
                -0.01933903992176056,
                0.022715674713253975,
                0.028177950531244278,
                -0.06894970685243607,
                0.003153764409944415,
                -0.01329744327813387,
                -0.031099781394004822,
                -0.025273310020565987,
                -0.04150133952498436,
                -0.019204597920179367,
                -0.02408076450228691,
                -0.08629892766475677,
                -0.02118667960166931,
                -0.06683404743671417,
                -0.02836299128830433,
                0.012572483159601688,
                0.01124633476138115,
                0.003535708412528038,
                0.026736777275800705,
                -0.06043876335024834,
                0.011383891105651855,
                0.015232961624860764,
                0.006434915121644735,
                0.009019458666443825,
                -0.0068848892115056515,
                -0.010216400027275085,
                0.026086734607815742,
                0.050547972321510315,
                -0.0013933491427451372,
                0.008424724452197552,
                0.03658868372440338,
                -0.01608932949602604,
                0.05791735649108887,
                0.0486702099442482,
                -0.0004849712713621557,
                -0.034428536891937256,
                -0.03153505548834801,
                -0.03175671398639679,
                0.011996251530945301,
                -0.04183683171868324,
                0.03074660152196884,
                -0.01469204667955637,
                -0.03651164844632149,
                0.03313746675848961,
                -0.046697311103343964,
                0.047979686409235,
                -0.04804462566971779,
                0.08337271213531494,
                -0.030710110440850258,
                -0.02077416330575943,
                -0.011874556541442871,
                0.04639419540762901,
                -0.01791928894817829,
                -0.019558385014533997,
                0.05076617747545242,
                0.05575072765350342,
                0.04340758174657822,
                0.011039159260690212,
                0.011963095515966415,
                -0.01525911409407854,
                0.031054049730300903,
                0.011673923581838608,
                -0.0111400056630373,
                7.614451897097751e-05,
                -0.05078889802098274,
                -0.03213062509894371,
                -0.01563406176865101,
                0.012000307440757751,
                -0.04391825571656227,
                0.01983698457479477,
                -0.04325437545776367,
                -0.0235904511064291,
                -0.0010264189913868904,
                0.032684992998838425,
                0.04626050963997841,
                -0.028684325516223907,
                0.05267590656876564,
                0.02856154926121235,
                -0.003238894045352936,
                0.01889948546886444,
                0.02842770516872406,
                0.03359297662973404,
                -0.04111957177519798,
                -0.009649474173784256,
                0.0317644439637661,
                -0.03710075467824936,
                0.04797577112913132,
                -0.04783099144697189,
                0.023096641525626183,
                -0.040203843265771866,
                -0.0010679755359888077,
                -0.0609535314142704,
                -0.015210175886750221,
                0.020884765312075615,
                0.012454025447368622,
                -0.042184945195913315,
                0.009770485572516918,
                -0.03890141472220421,
                -0.04961103945970535,
                0.00846420880407095,
                0.01525577250868082,
                0.0013021857012063265,
                0.03662397712469101,
                0.05208873748779297,
                -0.03813307359814644,
                -0.010179839096963406,
                -0.030488047748804092,
                0.029425594955682755,
                0.03390871360898018,
                0.0054970053024590015,
                -0.019165655598044395,
                0.02352663315832615,
                -0.024084968492388725,
                -0.0517856664955616,
                -0.0020032599568367004,
                -0.09769541025161743,
                -0.0305623821914196,
                0.01590188592672348,
                -0.02255740575492382,
                -0.028057245537638664,
                -0.005106903612613678,
                -0.04678221419453621,
                0.05137704685330391,
                0.0740995779633522,
                0.03364981710910797,
                0.0028927153907716274,
                0.043404482305049896,
                -0.0021368598099797964,
                -0.008004958741366863,
                0.06416015326976776,
                0.06060239300131798,
                0.06648264825344086,
                -0.06640759855508804,
                0.010860101319849491,
                -0.043289732187986374,
                -0.014591550454497337,
                -0.004822908900678158,
                0.046704381704330444,
                0.017363205552101135,
                -0.008205150254070759,
                0.05055040866136551,
                0.021029753610491753,
                -0.04003646597266197,
                -0.07134406268596649,
                -0.009649154730141163,
                0.03651741147041321,
                -0.05876125395298004,
                -0.02087630145251751,
                0.02479902096092701,
                0.00984167866408825,
                -0.05997622758150101,
                0.08096649497747421,
                0.0009842037688940763,
                -0.013285779394209385,
                -0.02251102775335312,
                0.018121184781193733,
                0.029274778440594673,
                -0.02252437360584736,
                0.023040834814310074,
                0.012868314981460571,
                0.0074103157967329025,
                0.05987365543842316,
                -0.03653343766927719,
                -0.00727575458586216,
                0.031092552468180656,
                -0.014385215938091278,
                0.054402370005846024,
                -0.0014962079003453255,
                -0.03620167821645737,
                0.034863363951444626,
                -0.01725773885846138,
                -0.004690785892307758,
                -0.012182560749351978,
                0.06653908640146255,
                -0.024786172434687614,
                -0.027567217126488686,
                -0.018567010760307312,
                0.02488262765109539,
                -0.04480987787246704,
                -0.02499418333172798,
                -0.016769737005233765,
                0.013497200794517994,
                -0.01745661348104477,
                0.03282026946544647,
                -0.013862588442862034,
                0.010701168328523636,
                0.020461276173591614,
                -0.03951545059680939,
                -0.020106349140405655,
                -0.004488454665988684,
                -0.04652532562613487,
                -0.051244813948869705,
                0.03967653214931488,
                -0.013113378547132015,
                0.06176946684718132,
                -0.03945283591747284,
                0.026557182893157005,
                -0.061590079218149185,
                0.0015447447076439857,
                0.0173177570104599,
                0.05103863775730133,
                0.012800125405192375,
                -0.00011042501864721999,
                0.019666004925966263,
                0.04965822398662567,
                -0.022645456716418266,
                -0.024985460564494133,
                0.020224975422024727,
                0.0052287038415670395,
                -0.05275701358914375,
                -0.046425964683294296,
                -0.012092094868421555,
                -0.006267610937356949,
                -0.019133061170578003,
                0.012210831046104431,
                -0.010756016708910465,
                -0.050234105437994,
                -0.05594077706336975,
                -0.0532878078520298,
                0.02375662699341774,
                -0.03547564893960953,
                -0.015269935131072998,
                -0.08349043130874634,
                -0.0078084589913487434,
                -0.07556316256523132,
                -0.035628579556941986,
                -0.013120037503540516,
                -0.031810157001018524,
                0.05115078017115593,
                -0.03651927039027214,
                0.01108712051063776,
                -3.481873500277288e-05,
                0.009419851936399937,
                -0.03379551321268082,
                -0.011454710736870766,
                0.027316857129335403,
                -0.024273406714200974,
                -0.01545695960521698,
                0.05537357181310654,
                0.02740629017353058,
                0.02651517279446125,
                0.06458757072687149,
                0.014347393065690994,
                -0.04637613892555237,
                -0.04252944886684418,
                0.0073163206689059734,
                -0.04564022272825241,
                0.07052213698625565,
                -0.026799989864230156,
                0.005616024602204561,
                0.06317900121212006,
                -0.028728552162647247,
                -0.009173068217933178,
                -0.014285707846283913,
                -0.06943820416927338,
                -0.023821458220481873,
                -0.08384663611650467,
                -0.035883914679288864,
                -0.021729398518800735,
                -0.032123640179634094,
                -0.01765088178217411,
                -0.016860567033290863,
                0.06302173435688019,
                -0.09144379943609238,
                0.014130269177258015,
                0.002826872281730175,
                -0.08490411937236786,
                -0.048499494791030884,
                0.020242486149072647,
                0.03190181776881218,
                0.02436736598610878,
                -0.003086123615503311,
                0.011901873163878918,
                0.03473789989948273,
                -0.004965602420270443,
                -0.05500344932079315,
                -0.006031828001141548,
                0.06297031044960022,
                0.01640707440674305,
                0.0007443824433721602,
                -0.01951015740633011,
                0.008533847518265247,
                0.003776893252506852,
                -0.04701652005314827,
                0.032359976321458817,
                0.052897609770298004,
                0.023749051615595818,
                0.012922859750688076,
                -0.03332981467247009,
                0.030062995851039886,
                -0.017270663753151894,
                0.009632405824959278,
                0.004685754422098398,
                -0.09261888265609741,
                0.006571847479790449,
                -0.008070649579167366,
                0.006628634408116341,
                0.020810360088944435,
                -0.03347988799214363,
                0.04837799072265625,
                -0.024973226711153984,
                -0.04373379796743393,
                -0.017060283571481705,
                -0.023844683542847633,
                -0.0022543722297996283,
                -0.018602650612592697,
                0.031173091381788254,
                -0.03929861634969711,
                0.017679277807474136,
                -0.07356376945972443,
                0.0730767771601677,
                0.005295721348375082,
                -0.023366721346974373,
                0.0022472485434263945,
                0.05378294363617897,
                -0.026190772652626038,
                0.021609090268611908,
                0.04662521183490753,
                0.03929530084133148,
                -0.0055731795728206635,
                -0.007893797941505909,
                -0.045330166816711426,
                -0.03321274369955063,
                -0.02364242635667324,
                0.004365880042314529,
                -0.05091695860028267,
                -0.007251331582665443,
                0.051202766597270966,
                0.014744292013347149,
                0.026996200904250145,
                0.05911223590373993,
                0.01695089228451252,
                -0.017071517184376717,
                -0.064418263733387,
                -0.060216307640075684,
                -0.041959673166275024,
                0.010943038389086723,
                -0.003450199030339718,
                -0.06728236377239227,
                0.037867870181798935,
                -0.03264205902814865,
                0.013262986205518246,
                0.0076490240171551704,
                -0.05644555762410164,
                0.10482409596443176,
                0.04324379190802574,
                -0.0015239116037264466,
                0.014750811271369457,
                -0.05697612464427948,
                0.04747043177485466,
                -0.0031474120914936066,
                0.00010145227861357853,
                -0.02988041751086712,
                0.022701000794768333,
                -0.06868042051792145,
                0.06473638862371445,
                0.0919806957244873,
                -0.014617337845265865,
                0.025432297959923744,
                0.04919927567243576,
                -0.07209503650665283,
                0.03190185874700546,
                0.023531466722488403,
                0.054081667214632034,
                0.011297852732241154,
                0.018666232004761696,
                0.03297354280948639,
                -0.031858138740062714,
                -0.002409589011222124,
                0.03480461239814758,
                0.056642498821020126,
                0.0518680177628994,
                -0.02484922669827938,
                0.014124744571745396,
                0.05353713780641556,
                0.00809654500335455,
                -0.036361515522003174,
                0.006911387667059898,
                0.031125223264098167,
                -0.015675149857997894,
                0.011167754419147968,
                0.01189882680773735,
                0.027697494253516197,
                -0.029964623972773552,
                0.00017269978707190603,
                0.0025100705679506063,
                -0.07255061715841293,
                0.0429157018661499,
                0.04512384906411171,
                0.045384883880615234,
                0.031325098127126694,
                -0.006234046071767807,
                -0.02993588335812092,
                0.03200289607048035,
                -0.03827904537320137,
                -0.00255006761290133,
                -0.07149388641119003,
                0.008749728091061115,
                -0.06368035823106766,
                0.01756877265870571,
                0.008398473262786865,
                -0.039464253932237625,
                0.026226064190268517,
                0.012817447073757648,
                -0.04773770645260811,
                -0.010885178111493587,
                0.03929036483168602,
                -0.05097010359168053,
                -0.02548990584909916,
                0.013019803911447525,
                0.11063407361507416,
                0.06008511781692505,
                -0.054141681641340256,
                0.02632691152393818,
                0.0066437008790671825,
                -0.051190219819545746,
                0.020781666040420532,
                -0.00863321777433157,
                -0.04326929524540901,
                0.015756994485855103,
                -0.044619642198085785,
                -0.03952176496386528,
                -0.018379641696810722,
                -0.0219652708619833,
                0.04334261268377304,
                0.0505312979221344,
                0.0039667231030762196,
                0.07931194454431534,
                -0.0288919098675251,
                -0.019489064812660217,
                -0.00827260036021471,
                -0.0058648549020290375,
                0.04666765779256821,
                -0.014576028101146221,
                0.03687751665711403,
                0.0023489072918891907,
                0.0015636453172191978,
                -0.016354365274310112,
                -0.005191474221646786,
                0.01831808313727379,
                -0.013125590048730373,
                -0.02687535062432289,
                -0.03188958391547203,
                -0.005286573898047209,
                -0.06586898118257523,
                0.005630927160382271,
                0.050438590347766876,
                0.06240208446979523,
                0.053276099264621735,
                0.0018925754120573401,
                -0.01653900183737278,
                0.023337291553616524,
                0.010450968518853188,
                0.010303381830453873,
                0.009534737095236778,
                -0.025695282965898514,
                -0.011481636203825474,
                0.04071706905961037,
                -0.009743435308337212,
                0.044322412461042404,
                0.020418213680386543,
                0.03700971603393555,
                0.0055852062068879604,
                0.005565945990383625,
                0.020793214440345764,
                0.025470932945609093,
                0.002387781161814928,
                -0.005578565411269665,
                0.021658459678292274,
                -0.01654825545847416,
                -0.01755981147289276,
                -0.04096301645040512,
                0.019195428118109703,
                -0.04250043258070946,
                0.0030172003898769617,
                0.003462017746642232,
                0.04968533664941788,
                -0.03815123438835144,
                -0.01683887094259262,
                0.016240941360592842,
                0.013824952766299248,
                0.0066542839631438255,
                0.0068491301499307156,
                -0.023228423669934273,
                -4.861638188805618e-33,
                -0.0425606407225132,
                0.052280932664871216,
                0.007099288981407881,
                0.05891385301947594,
                0.006784595549106598,
                0.038950804620981216,
                -0.018619433045387268,
                -0.050433870404958725,
                0.05541615188121796,
                -0.023929081857204437,
                0.033753540366888046,
                -0.03194202110171318,
                -0.0018102851463481784,
                0.009830509312450886,
                0.020848281681537628,
                -0.009046141058206558,
                0.04916337877511978,
                0.029086509719491005,
                0.021495526656508446,
                -0.011147560551762581,
                0.030803216621279716,
                0.02257751300930977,
                0.06059135124087334,
                -0.13414378464221954,
                -0.03487340360879898,
                0.002121530007570982,
                -0.0029223607853055,
                0.007892800495028496,
                0.016573352739214897,
                0.030928323045372963,
                -0.004007806070148945,
                -0.0013558041537180543,
                -0.04312514886260033,
                0.07960550487041473,
                -0.02621067687869072,
                -0.03779993951320648,
                -0.11125333607196808,
                -0.05888667330145836,
                0.016397252678871155,
                0.04174049198627472,
                0.018411343917250633,
                0.027431778609752655,
                -0.012582200579345226,
                -0.017225254327058792,
                -0.029262302443385124,
                -0.04897291958332062,
                0.04220689833164215,
                0.05441466346383095,
                -0.032426703721284866,
                -0.06674165278673172,
                -0.04010501876473427,
                -0.019083987921476364,
                -0.013115787878632545,
                -0.04712069034576416,
                0.03987295180559158,
                -0.010148582048714161,
                -0.031028995290398598,
                0.04619891196489334,
                -0.051775019615888596,
                -0.02717795968055725,
                0.015684526413679123,
                -0.028993619605898857,
                0.03852229192852974,
                -0.017337268218398094,
                0.010501416400074959,
                0.035992685705423355,
                0.057415708899497986,
                -0.00033935331157408655,
                0.024791855365037918,
                0.005502420011907816,
                0.049976542592048645,
                0.03162182867527008,
                -0.02355463057756424,
                0.044427599757909775,
                0.04048103094100952,
                -0.012107133865356445,
                -0.041129615157842636,
                -0.009802130050957203,
                0.09685874730348587,
                0.026625195518136024,
                -0.0010221452685073018,
                0.017924828454852104,
                -0.006138332653790712,
                -0.004487407393753529,
                -0.02030866965651512,
                -0.03384043648838997,
                0.011762031354010105,
                -0.002299741841852665,
                -0.03181146830320358,
                -0.015837669372558594,
                0.02027191035449505,
                -0.0035015949979424477,
                -0.00886406097561121,
                0.02144055999815464,
                0.0023881506640464067,
                0.00205391738563776,
                0.0013108111452311277,
                -0.019379742443561554,
                0.013462609611451626,
                0.022359773516654968,
                0.04544952139258385,
                -0.01902640610933304,
                0.08408299088478088,
                0.00766713498160243,
                0.033520497381687164,
                -0.01327153854072094,
                -0.01711454428732395,
                0.043838851153850555,
                0.026175053790211678,
                0.0015290495939552784,
                -0.012705174274742603,
                0.014496737159788609,
                0.011612304486334324,
                -0.025165634229779243,
                0.04432139918208122,
                -0.025814196094870567,
                0.0037293541245162487,
                0.0018174886936321855,
                0.0028235125355422497,
                0.07551879435777664,
                0.013480311259627342,
                0.0744900107383728,
                0.020650589838624,
                0.005333165172487497,
                0.02429087646305561,
                -0.046308327466249466,
                0.05210837349295616,
                -0.09837628155946732,
                -0.009166196919977665,
                -0.012227965518832207,
                -0.01746978610754013,
                0.023289117962121964,
                2.2118072706689418e-07,
                0.0029811037238687277,
                0.04435587301850319,
                0.07984725385904312,
                0.04070895165205002,
                0.009292189963161945,
                0.018372833728790283,
                0.0016152242897078395,
                0.10352685302495956,
                0.060882750898599625,
                -0.04306826367974281,
                0.007809207774698734,
                -0.0010359416482970119,
                -0.02330901101231575,
                0.005956338718533516,
                0.012621293775737286,
                -0.025866549462080002,
                0.027465619146823883,
                -0.034695807844400406,
                -0.020825615152716637,
                -0.01378550659865141,
                0.03317727893590927,
                -0.036483343690633774,
                -0.020985957235097885,
                -0.010410319082438946,
                0.015303385443985462,
                0.02599947340786457,
                0.014074079692363739,
                0.01852668821811676,
                -0.05144226551055908,
                0.014929504133760929,
                -0.0072381868958473206,
                -0.03654944896697998,
                -0.016161104664206505,
                0.015726977959275246,
                -0.010911737568676472,
                0.0019566037226468325,
                0.0489896796643734,
                -0.013941923156380653,
                -0.023349978029727936,
                0.00370898749679327,
                -0.032490164041519165,
                -0.028315957635641098,
                0.0669776052236557,
                -0.02978549711406231,
                0.018228303641080856,
                0.019858837127685547,
                -0.0024683985393494368,
                0.02114354632794857,
                -0.08643895387649536,
                -0.02056465670466423,
                0.01571546494960785,
                0.0150571558624506,
                0.025016983970999718,
                0.004486724268645048,
                -0.002433647634461522,
                -0.0136299729347229,
                -0.03393008932471275,
                0.010366427712142467,
                0.0181155763566494,
                -0.025578996166586876,
                -0.015940431505441666,
                0.021869372576475143,
                0.015892699360847473,
                -0.021734576672315598,
                0.03996969014406204,
                -0.07642952352762222,
                -0.03902586176991463,
                1.906098872552509e-34,
                0.02571076899766922,
                0.009818185120821,
                -0.03316408395767212,
                -0.045105259865522385,
                -0.01174238882958889,
                0.01724136248230934,
                0.045779868960380554,
                0.037127118557691574,
                -0.0014104058500379324,
                -0.0226000864058733,
                0.04277695342898369,
                0.062188874930143356,
                -0.019021421670913696,
                -0.02910155989229679,
                0.007523658219724894,
                0.005643910728394985,
                0.009680170565843582,
                0.008025454357266426,
                -0.006630169693380594,
                -0.07201176881790161,
                0.014679567888379097,
                0.035051893442869186,
                -0.02013956382870674,
                0.024420252069830894,
                -0.02269444800913334,
                0.010258709080517292,
                -0.05434394255280495,
                -0.007774775847792625,
                -0.023926565423607826,
                0.024801218882203102,
                -0.018819143995642662,
                0.0376034714281559,
                -0.007322267163544893,
                -0.0031674455385655165,
                0.007994205690920353,
                0.004724330268800259,
                -0.012175001204013824,
                -0.01948609948158264,
                -0.023964496329426765,
                0.013965725898742676,
                0.02334701269865036,
                -0.011473342776298523,
                0.012465904466807842,
                -0.01652674563229084,
                -0.07518410682678223,
                1.6494444707859657e-06,
                0.03891890123486519,
                0.008807254023849964,
                -0.041287727653980255,
                -0.034112103283405304,
                0.007697938475757837,
                -0.006715212017297745,
                -0.022979723289608955,
                0.023376664146780968,
                0.0165516659617424,
                -0.03533636033535004,
                0.028682509437203407,
                0.04679263383150101,
                0.01593102514743805,
                0.03483385220170021,
                0.005328934174031019,
                0.025851108133792877,
                0.03252670168876648,
                -0.04907185211777687,
                -0.009185015223920345,
                -0.06909642368555069,
                -0.08819589763879776,
                -0.016424719244241714,
                0.03495108708739281,
                0.0032595498487353325,
                0.0031256864313036203,
                -0.007993114180862904,
                -0.032709162682294846,
                -0.02370806783437729,
                -0.013342959806323051,
                -0.005985156167298555,
                0.019320456311106682,
                -0.016327321529388428,
                -0.028914809226989746,
                0.02008543722331524,
                -0.0003761097032111138,
                0.029190793633461,
                0.02652178704738617,
                -0.021139666438102722,
                0.008720522746443748,
                -0.0022616274654865265,
                0.01071080006659031,
                -0.002308497903868556,
                -0.006252487655729055,
                -0.009113463573157787,
                -0.011067494750022888,
                -0.05409601330757141,
                0.022638242691755295,
                -0.013912146911025047,
                0.0001943875104188919,
                0.09443438798189163,
                0.026131341233849525,
                -0.04291902482509613,
                -0.012478306889533997,
                -0.047214873135089874,
                0.05409209430217743,
                -0.019231177866458893,
                -0.05781656503677368,
                -0.006536522414535284,
                0.017820268869400024,
                -0.02346748486161232,
                -0.004126166459172964,
                0.031098123639822006,
                -0.05034288018941879,
                -0.004356812685728073,
                -0.040129125118255615,
                0.00346473790705204,
                0.0182344987988472,
                0.056991081684827805,
                0.0014474588679149747,
                0.03268660977482796,
                -0.04619700834155083,
                -0.00160251185297966,
                0.019869238138198853,
                -0.008628330193459988,
                -0.005131212063133717,
                -0.08931948989629745,
                0.003740898799151182,
                -0.024871382862329483,
                0.022986629977822304,
                0.001851364504545927,
                0.0025717539247125387,
                0.00039117925916798413,
                0.016907600685954094,
                -0.0017997807590290904,
                0.012108366936445236,
                -0.005694049410521984,
                -0.003950146492570639,
                -0.0664195641875267,
                -0.004650474060326815,
                0.029960939660668373,
                0.03522906452417374,
                -0.03527458384633064,
                0.024416636675596237,
                -0.0008699094760231674,
                0.004274224862456322,
                -0.0031317882239818573,
                -0.03284174203872681,
                -0.012025341391563416,
                0.04445930942893028,
                0.0063848854042589664,
                0.039164647459983826,
                -0.014566250145435333,
                0.03238882124423981,
                0.028929060325026512,
                -0.001131852506659925,
                0.021242331713438034,
                -0.05468974635004997,
                -0.04957648366689682,
                -0.04446103051304817,
                -0.028974981978535652,
                0.007921980693936348,
                -0.021241046488285065,
                -0.009700033813714981,
                0.014533662237226963,
                -0.031064443290233612,
                0.003701860550791025,
                0.010116559453308582,
                -0.03139946609735489,
                0.020915953442454338,
                0.0662965402007103,
                0.07767374068498611,
                0.008344372734427452,
                -0.03399131819605827,
                0.03162546828389168,
                0.021061735227704048,
                -0.006459330674260855,
                0.031797345727682114,
                0.020955907180905342,
                -0.006160328630357981,
                -0.013942815363407135,
                -0.0009008373599499464,
                0.04989798367023468,
                -0.008209586143493652,
                -0.0006824060110375285,
                0.03940035402774811,
                -0.0667569488286972,
                0.05193396657705307,
                -0.02785823866724968,
                0.042609941214323044,
                0.03456853702664375,
                -0.03454626724123955,
                0.07567736506462097,
                -0.029245905578136444,
                -0.04255593940615654,
                0.03757167607545853,
                -0.04928765073418617,
                -0.03518925979733467,
                0.03744019940495491,
                0.027433473616838455,
                0.11361981183290482,
                0.049479931592941284,
                0.06428753584623337,
                0.024936258792877197,
                -0.06702938675880432,
                0.01529686152935028,
                -0.012096401304006577,
                0.011414889246225357,
                -0.01022604200989008,
                0.012827699072659016,
                0.00615172041580081,
                -0.018127059563994408,
                -0.02308105118572712,
                0.02200520597398281,
                -0.0025458321906626225,
                -0.04780319333076477,
                0.02245301567018032,
                0.004398315213620663,
                0.04500867798924446,
                0.029718704521656036,
                -0.024420034140348434,
                0.05437115952372551,
                0.006760632619261742,
                -0.018896780908107758,
                0.039887599647045135,
                0.013109435327351093,
                0.008644245564937592,
                -0.00020665820920839906,
                0.02281126007437706,
                -0.021250901743769646,
                0.02494373917579651,
                0.0507480762898922,
                -0.0038559038657695055,
                0.02329086698591709,
                -0.08202523738145828,
                0.02911650389432907,
                -0.022247256711125374,
                0.10500319302082062,
                -0.0056978086940944195,
                0.018424605950713158,
                0.04290151968598366,
                0.010618355125188828,
                -0.05330788344144821,
                -0.025235751643776894,
                0.03440292924642563,
                0.0265000332146883,
                -0.03927091136574745,
                0.0036441595293581486,
                -0.0028632304165512323,
                0.002686805324628949,
                -0.007879659533500671,
                0.060373760759830475,
                0.032676540315151215,
                -0.030588602647185326,
                -0.0005770238931290805,
                -0.06028811261057854,
                -0.019055869430303574,
                0.005694339517503977,
                0.0013383880723267794,
                0.033793993294239044,
                0.07254144549369812,
                -0.0006047138012945652,
                0.0032629119232296944,
                0.00816299393773079,
                -0.03900979831814766,
                -0.004992056638002396,
                -0.017175285145640373,
                0.016029242426156998,
                -0.045276619493961334,
                0.0013374629197642207,
                -0.021366847679018974,
                -0.048116717487573624,
                -0.0002161258744308725,
                0.027699865400791168,
                -0.04224700108170509,
                0.052097223699092865,
                0.07441625744104385,
                0.03382021188735962,
                0.02888289839029312,
                -0.001246070023626089,
                0.029590891674160957,
                -0.02226090431213379,
                0.005600930657237768,
                -0.08551912009716034,
                -0.04944441840052605,
                -0.014743802137672901,
                -0.002333540003746748,
                -0.030822085216641426,
                -0.004313028883188963,
                -0.011815489269793034,
                -0.019779091700911522,
                0.037058714777231216,
                0.032169174402952194,
                0.0670265182852745,
                -0.014632483012974262,
                0.006815594621002674,
                0.051600534468889236,
                -0.030176877975463867,
                -0.03895389288663864,
                0.004452090710401535,
                0.03012259118258953,
                -0.014789587818086147,
                0.05711382254958153,
                -0.08969242125749588,
                -0.06943581998348236,
                0.012721391394734383,
                -0.011417785659432411,
                -0.02858424000442028,
                0.028460701927542686,
                -0.04803745076060295,
                0.014437801204621792,
                0.02626902610063553,
                0.0026319364551454782,
                -0.00393659109249711,
                -0.012159639969468117,
                0.03740822151303291,
                0.030501335859298706,
                -0.05343923345208168,
                0.023153938353061676,
                -0.0003336773661430925,
                -0.017406640574336052,
                -0.021786199882626534,
                -0.02611144445836544,
                -0.015988720580935478,
                -0.006859724409878254,
                0.03337188437581062,
                0.044858016073703766,
                0.006238309200853109,
                0.0321212075650692,
                0.006245804484933615,
                -0.0316157341003418,
                -0.015696657821536064,
                -0.05269881710410118,
                -0.09674140065908432,
                -0.022418318316340446,
                -0.0007591423927806318,
                -0.009248074144124985,
                0.01514605525881052,
                -0.028855010867118835,
                -0.012076539918780327,
                -0.012289903126657009,
                -0.06010444089770317,
                -0.007977502420544624,
                0.0029968847520649433,
                0.07387012988328934,
                -0.06446092575788498,
                -0.011640544980764389,
                -0.006232079118490219,
                -0.053418196737766266,
                -0.0036431546323001385,
                -0.04214778542518616,
                0.059761203825473785,
                6.700235098833218e-05,
                -0.0798725038766861,
                -0.0360773503780365,
                -0.025067104026675224,
                0.004968111868947744,
                0.011659562587738037,
                0.006596158258616924,
                0.0611983947455883,
                -0.03909316286444664,
                0.03759299963712692,
                0.0661129429936409,
                0.011753136292099953,
                -0.048767536878585815,
                -0.0011350944405421615,
                0.048681437969207764,
                -0.057223353534936905,
                0.00925257708877325,
                -0.02166231907904148,
                0.004883290734142065,
                -0.028142660856246948,
                -0.07393984496593475,
                0.009751019068062305,
                0.01532790157943964,
                -0.12471984326839447,
                -0.029716020449995995,
                0.02932591922581196,
                0.05601472780108452,
                0.0014975869562476873,
                0.03106323815882206,
                -0.034997377544641495,
                0.015073442831635475,
                0.06006316840648651,
                -0.00771471718326211,
                -0.01836455799639225,
                0.058742910623550415,
                0.032304659485816956,
                -0.03987622261047363,
                0.0209584329277277,
                -0.01770216040313244,
                0.013215417973697186,
                0.013596653938293457,
                -0.0032750226091593504,
                -0.053649723529815674,
                -0.06970002502202988,
                0.02894088625907898,
                0.013313988223671913,
                0.02619684860110283,
                -0.007230831775814295,
                -0.0033024854492396116,
                -0.004203053656965494,
                0.007199014537036419,
                -0.02054545283317566,
                0.03776893392205238,
                0.03642050549387932,
                0.030422011390328407,
                -0.009508748538792133,
                -0.07918946444988251,
                0.017273252829909325,
                -0.006398617289960384,
                0.020635373890399933,
                0.07268199324607849,
                -0.017843132838606834,
                0.0647590383887291,
                0.017642375081777573,
                0.055243249982595444,
                -0.00464941281825304,
                -0.012383271008729935,
                -0.0027282731607556343,
                -0.07821087539196014,
                0.027734223753213882,
                0.053126849234104156,
                0.04545344412326813,
                0.009747492149472237,
                -0.05693216621875763,
                0.008917253464460373,
                0.026485200971364975,
                0.031440723687410355,
                -0.0037364705931395292,
                0.03450722247362137,
                -0.06699938327074051,
                -0.00798217486590147,
                -0.0053629400208592415,
                -0.029469314962625504,
                -0.0019382861210033298,
                -0.00047815850120969117,
                -0.00908323097974062,
                -0.05151461437344551,
                -0.04636676609516144,
                -0.011696159839630127,
                0.016569003462791443,
                0.07198244333267212,
                0.05640045925974846,
                -0.014045692048966885,
                -0.02234264463186264,
                0.007269564084708691,
                0.02332945354282856,
                0.02591356635093689,
                0.030313562601804733,
                0.006609282456338406,
                -0.046377383172512054,
                -0.04463842883706093,
                0.03717103973031044,
                0.009364659897983074,
                0.006354141049087048,
                -0.050620973110198975,
                0.05223420634865761,
                0.02102271281182766,
                -0.005953731015324593,
                0.040494225919246674,
                -0.09565746039152145,
                0.04171978682279587,
                0.017847616225481033,
                -0.045181676745414734,
                0.032352108508348465,
                0.03153306245803833,
                -0.0171293243765831,
                -0.030377177521586418,
                -0.10265792161226273,
                -0.005844171158969402,
                0.02616860345005989,
                -0.03580378368496895,
                -0.023957135155797005,
                0.002833847189322114,
                0.025509251281619072,
                0.011948187835514545,
                0.02814958058297634,
                -0.05880014970898628,
                0.06732799112796783,
                -0.07358792424201965,
                -0.06387054920196533,
                0.015999089926481247,
                -0.007855258882045746,
                0.031974680721759796,
                -0.04074161499738693,
                0.01831458881497383,
                0.019229719415307045,
                0.0068351952359080315,
                0.04885854199528694,
                0.034671854227781296,
                0.044700101017951965,
                -0.02584194764494896,
                -0.034550316631793976,
                -0.017853012308478355,
                -0.00543354544788599,
                -0.004853158723562956,
                -0.01954679936170578,
                0.013433068990707397,
                -0.04236796498298645,
                -0.033770851790905,
                -0.0175260528922081,
                0.010054382495582104,
                -0.002302141161635518,
                -0.013236409053206444,
                -0.04179001227021217,
                -0.04202710837125778,
                0.0034341567661613226,
                0.03715088590979576,
                0.01853637583553791,
                -0.019331716001033783,
                0.004760563373565674,
                0.032166920602321625,
                0.11450570076704025,
                0.034886542707681656,
                0.0320957787334919,
                0.007266828324645758,
                0.042205531150102615,
                0.013653713278472424,
                -0.025358576327562332,
                -0.005921822506934404,
                0.0009158947505056858,
                0.03027557022869587,
                0.04535546898841858,
                0.007314497604966164,
                -0.026532918214797974,
                0.024578768759965897,
                -0.005714918486773968,
                0.006300834473222494,
                -0.09777127206325531,
                0.01005367562174797,
                0.021651616320014,
                -0.026000456884503365,
                0.025236481800675392,
                -0.03694457188248634,
                -0.05181516706943512,
                0.018115855753421783,
                -0.008261056616902351,
                0.030568623915314674,
                -0.03000006452202797,
                0.07578908652067184,
                0.010850595310330391,
                -0.011829428374767303,
                -0.014854167588055134,
                0.0006287753349170089,
                -0.01661291904747486,
                -0.046846985816955566,
                -0.03266885504126549,
                -0.07003598660230637,
                0.030146827921271324,
                -0.03506167232990265,
                0.09591677039861679,
                0.02185242809355259,
                0.05609096586704254,
                -0.02615831233561039,
                0.04684062674641609,
                -0.019966183230280876,
                0.06562954932451248,
                0.027236225083470345,
                0.0020950434263795614,
                0.042765356600284576,
                -0.008396713994443417,
                0.013102319091558456,
                0.05098213255405426,
                0.04945076256990433,
                0.0006832621293142438,
                -0.03164776787161827,
                8.24890739750117e-05,
                0.01514278445392847,
                0.025507260113954544,
                0.0021535272244364023,
                -5.912407078875891e-33,
                -0.0223571565002203,
                -0.08602342754602432,
                0.0073589482344686985,
                0.040260832756757736,
                -0.04803677648305893,
                0.020363708958029747,
                -0.01076811645179987,
                0.033019840717315674,
                -0.009156981483101845,
                -0.0066818539053201675,
                0.005539966747164726,
                0.04156532511115074,
                0.01242520660161972,
                -0.016564683988690376,
                0.05689051002264023,
                -0.02732919529080391,
                0.022191280499100685,
                0.009938713163137436,
                -0.002000982640311122,
                -0.025715941563248634,
                0.016254954040050507,
                0.03034152463078499,
                0.029800381511449814,
                -0.008112152107059956,
                -5.4356503824237734e-05,
                -0.017190206795930862,
                -0.020414205268025398,
                0.003201519139111042,
                0.03884214162826538,
                -0.037190161645412445,
                0.043566685169935226,
                -0.038206081837415695,
                0.01679341122508049,
                -0.06326641887426376,
                -0.010232392698526382,
                0.08558367937803268,
                0.03339062258601189,
                -0.011600233614444733,
                -0.012322264723479748,
                -0.0334961973130703,
                -0.02757374383509159,
                -0.004840427543967962,
                0.0059394254349172115,
                0.0052887131460011005,
                0.02046828344464302,
                -0.07716288417577744,
                -0.011120788753032684,
                0.019138649106025696,
                -0.010991666465997696,
                0.061131324619054794,
                -0.024047814309597015,
                0.030971666797995567,
                -0.006579037290066481,
                0.01228376105427742,
                0.03317177668213844,
                -0.0002232690603705123,
                -0.04924999177455902,
                -0.054715219885110855,
                -0.08871746808290482,
                0.018425900489091873,
                -0.043485283851623535,
                0.0194473247975111,
                0.006015892140567303,
                -0.01954016461968422,
                -0.035941850394010544,
                0.017790595069527626,
                -0.00626537436619401,
                0.05483604967594147,
                -0.03659353405237198,
                -0.060400690883398056,
                -0.04051027446985245,
                0.0857122540473938,
                0.053296297788619995,
                0.0029170927591621876,
                -0.10487793385982513,
                -0.0021186089143157005,
                -0.01770983636379242,
                -0.020141398534178734,
                0.034906551241874695,
                0.02561017870903015,
                -0.02948160655796528,
                0.06483378261327744,
                -0.05232353135943413,
                -0.0012397102545946836,
                0.03482452780008316,
                0.0044502438977360725,
                0.0195289459079504,
                -0.034788262099027634,
                -0.02114228531718254,
                0.0034766129683703184,
                -0.01255528349429369,
                0.026675721630454063,
                0.042593393474817276,
                0.0676504522562027,
                0.04165193438529968,
                -0.004708309657871723,
                0.029972253367304802,
                0.00904516689479351,
                -0.014621528796851635,
                -0.04150497913360596,
                -0.12612642347812653,
                0.02765270695090294,
                -0.07487814128398895,
                0.051125116646289825,
                -0.011688567698001862,
                -0.0019316376419737935,
                -0.009800279513001442,
                -0.020162465050816536,
                0.029736286029219627,
                -0.011077736504375935,
                0.010392372496426105,
                -0.002374083735048771,
                0.004480406641960144,
                -0.07481026649475098,
                -0.0412607416510582,
                -0.011301971040666103,
                -0.012088951654732227,
                -0.017759181559085846,
                -0.014389550313353539,
                -0.08914796262979507,
                0.024075113236904144,
                0.0495598241686821,
                -0.048593852669000626,
                -0.03342391550540924,
                -0.03697332367300987,
                -0.0016850923420861363,
                0.003223718609660864,
                0.015958821401000023,
                0.02680109441280365,
                0.008178380317986012,
                -0.014625193551182747,
                -0.08331356942653656,
                2.2518614173350215e-07,
                0.0003381413407623768,
                0.04081736132502556,
                0.013655165210366249,
                0.04601818323135376,
                -0.005342856515198946,
                0.02446252666413784,
                -0.010245735757052898,
                0.0642460286617279,
                0.026216713711619377,
                0.02687978930771351,
                0.05570826306939125,
                0.03520791977643967,
                -0.0070743137039244175,
                0.004002633970230818,
                0.028545407578349113,
                -0.06639815866947174,
                -0.02195153571665287,
                -0.06117618829011917,
                0.048044353723526,
                -0.04837898910045624,
                0.0314856581389904,
                -0.008667835034430027,
                0.030659092590212822,
                0.024637142196297646,
                -0.030135637149214745,
                0.02764294110238552,
                -0.0036366204731166363,
                -0.02600892260670662,
                0.012739475816488266,
                0.017508188262581825,
                0.03277426213026047,
                -0.08304733037948608,
                -0.042226940393447876,
                0.06497027724981308,
                0.009761892259120941,
                -0.0768270194530487,
                0.02040788158774376,
                -0.032498620450496674,
                -0.029253410175442696,
                0.051420677453279495,
                -0.010671342723071575,
                0.01763240434229374,
                -0.03877030685544014,
                0.04387812316417694,
                -0.04991278424859047,
                -0.06549333035945892,
                -0.043326593935489655,
                0.0443299375474453,
                -0.011627067811787128,
                -0.013055485673248768,
                0.02127889357507229,
                0.055950991809368134,
                0.034317538142204285,
                0.04104795306921005,
                -0.023718655109405518,
                0.011432531289756298,
                0.013906723819673061,
                0.01894320733845234,
                0.04035038873553276,
                -0.026427652686834335,
                -0.01847664639353752,
                -0.06240068003535271,
                0.04414399340748787,
                -0.024286022409796715,
                -0.042114872485399246,
                0.04613105580210686,
                -0.02868330292403698,
                6.021746466519344e-35,
                0.023576343432068825,
                -0.010830375365912914,
                -0.0325951986014843,
                0.040156420320272446,
                0.021470343694090843,
                -0.044175922870635986,
                -0.0055004386231303215,
                -0.020913168787956238,
                0.016460051760077477,
                0.047560274600982666,
                -0.020715802907943726,
                -0.055611781775951385,
                0.035027891397476196,
                -0.02290288917720318,
                -0.01328590139746666,
                0.007263104896992445,
                -0.0018299343064427376,
                0.04234786704182625,
                -0.015751857310533524,
                -0.0027968992944806814,
                -0.012107416987419128,
                0.03035476617515087,
                -0.019608428701758385,
                -0.04223921149969101,
                0.031848032027482986,
                0.022595839574933052,
                0.012536352500319481,
                0.03348870202898979,
                0.013046427629888058,
                -0.027775228023529053,
                0.009894187562167645,
                -0.0012319996021687984,
                -0.025666408240795135,
                0.022901587188243866,
                -0.010972391813993454,
                -0.035609886050224304,
                0.014527346938848495,
                -0.014537541195750237,
                0.021632343530654907,
                -0.05694454908370972,
                0.00196499633602798,
                -0.0003206089895684272,
                0.0550701804459095,
                0.022958291694521904,
                0.012719303369522095,
                1.1414811069698771e-06,
                -0.0026659888681024313,
                -0.06500228494405746,
                0.025884289294481277,
                -0.04609936475753784,
                0.03379146382212639,
                -0.029479218646883965,
                0.0038992129266262054,
                0.00207535014487803,
                0.01850772649049759,
                -0.02509058453142643,
                -0.04685209318995476,
                0.007762370631098747,
                -0.035844042897224426,
                0.001609970349818468,
                -0.016701774671673775,
                0.01099019218236208,
                0.005953844636678696,
                -0.019138934090733528,
                0.0015498371794819832,
                0.007782245520502329,
                0.046190325170755386,
                -0.004689781926572323,
                0.09636608511209488,
                0.07066797465085983,
                0.0030560886953026056,
                -0.05068737640976906,
                0.027823926880955696,
                -0.026682041585445404,
                0.03298266977071762,
                0.07207001745700836,
                0.009025592356920242,
                -0.0015888441121205688,
                0.0019875827711075544,
                -0.0034939516335725784,
                -0.03629961609840393,
                0.06298618763685226,
                -0.03772498294711113,
                -0.01185842789709568,
                0.039605092257261276,
                -0.01922343298792839,
                -0.04203790798783302,
                -0.01672529987990856,
                -0.00033287762198597193,
                0.02640744298696518,
                0.02751307003200054,
                -0.06412843614816666,
                -0.11387009918689728,
                -0.05940846726298332,
                -0.0103897824883461,
                -0.06323045492172241,
                -0.03356999531388283,
                -0.01744064874947071,
                -0.05510568991303444,
                -0.006429845467209816,
                -0.029900001361966133,
                -0.004583985079079866,
                0.011851193383336067,
                -0.0009342220728285611,
                0.03267005831003189,
                -0.03987295180559158,
                -0.003986048512160778,
                -0.01744140312075615,
                0.02672029472887516,
                0.03517601266503334,
                -0.06300999969244003,
                -0.10564437508583069,
                0.022315558046102524,
                -0.02281714789569378,
                0.01353300828486681,
                0.022406937554478645,
                0.05825910344719887,
                0.016359630972146988,
                -0.011361751705408096,
                -0.009275760501623154,
                -0.06353197246789932,
                -0.06984386593103409,
                0.0076347156427800655,
                -0.006937990430742502,
                9.151643462246284e-05,
                0.009902147576212883,
                0.034889355301856995,
                -0.015352833084762096,
                0.01586339995265007,
                -0.039630863815546036,
                0.06089094281196594,
                -0.01733452081680298,
                -0.008237544447183609,
                0.03241908177733421,
                0.024806000292301178,
                -0.018182773143053055,
                -0.0379621759057045,
                -0.02228458784520626,
                -0.007171161472797394,
                -0.04855753853917122,
                -0.08605670183897018,
                -0.03867373615503311,
                0.013540172018110752,
                -0.002713409485295415,
                0.009696063585579395,
                0.08366550505161285,
                -0.05972138047218323,
                -0.008374936878681183,
                0.07384512573480606,
                -0.08756054937839508,
                -0.03728661313652992,
                0.025535261258482933,
                0.06265858560800552,
                -0.010127309709787369,
                -0.017391150817275047,
                0.0201393011957407,
                0.015071198344230652,
                -0.08134058862924576,
                0.05799048766493797,
                -0.009768242947757244,
                0.020943401381373405,
                -0.046413224190473557,
                -0.0014040616806596518,
                -0.06202608719468117,
                0.04576755687594414,
                0.04294667765498161,
                0.0018465569010004401,
                0.07807552069425583,
                -0.006691494025290012,
                -0.016387052834033966,
                0.016553808003664017,
                -0.031651969999074936,
                0.05405382439494133,
                0.028077661991119385,
                -0.023748096078634262,
                -0.026998667046427727,
                -0.02784707210958004,
                -0.08634665608406067,
                0.06485545635223389,
                -0.03081236593425274,
                0.020383989438414574,
                -0.03735998645424843,
                -0.022166525945067406,
                -0.004801067989319563,
                0.050068117678165436,
                0.0020539010874927044,
                0.005397595465183258,
                0.012723468244075775,
                0.008391049690544605,
                -0.021694917231798172,
                0.055399876087903976,
                0.012747455388307571,
                -0.0242224782705307,
                0.046279117465019226,
                0.035111092031002045,
                0.12591297924518585,
                -0.038421399891376495,
                -0.0716809406876564,
                -0.04608767479658127,
                -0.033256564289331436,
                -0.0020985137671232224,
                -0.004030955955386162,
                0.0024562336038798094,
                -0.034395307302474976,
                0.033091962337493896,
                -0.010061991401016712,
                0.012916380539536476,
                0.008720789104700089,
                0.016548004001379013,
                -0.05274417996406555,
                -0.003922699484974146,
                -0.039035696536302567,
                -0.004455818794667721,
                -0.05469672009348869,
                0.07530365884304047,
                -0.01935792900621891,
                -0.09760355949401855,
                -0.025222158059477806,
                -0.0073635573498904705,
                -0.0091332932934165,
                0.0359436571598053,
                0.0350569449365139,
                -0.013730506412684917,
                -0.03989207744598389,
                -0.04829103499650955,
                -0.022313538938760757,
                -0.01903715170919895,
                0.04451955482363701,
                0.00748825678601861,
                -0.025289978832006454,
                0.025901323184370995,
                -0.01361938752233982,
                0.03203100338578224,
                0.01854277402162552,
                0.013710386119782925,
                0.07681796699762344,
                -0.009223400615155697,
                0.03360734507441521,
                0.005306400824338198,
                -0.032342392951250076,
                0.032076723873615265,
                -0.051992252469062805,
                0.014987552538514137,
                -0.0009668262209743261,
                0.01358743291348219,
                -0.09293647110462189,
                -0.025451820343732834,
                0.052223801612854004,
                0.06395156681537628,
                0.011705026961863041,
                -0.021310560405254364,
                -0.011918437667191029,
                -0.10994113236665726,
                0.016005724668502808,
                -0.02844734489917755,
                0.023701783269643784,
                -0.0005158393178135157,
                -0.04102138802409172,
                -0.008741127327084541,
                0.032110560685396194,
                -0.02013496682047844,
                0.033165235072374344,
                -0.05823451280593872,
                -0.026118945330381393,
                0.019277451559901237,
                0.016832131892442703,
                -0.016909141093492508,
                -0.09238637238740921,
                0.0414787158370018,
                -0.005565819796174765,
                0.0423126220703125,
                -0.005601434037089348,
                -0.018186001107096672,
                0.05522741749882698,
                -0.070863276720047,
                -0.011723417788743973,
                0.023318389430642128,
                0.08284961432218552,
                -0.06461949646472931,
                0.036915965378284454,
                0.029662977904081345,
                0.011507290415465832,
                -0.030135294422507286,
                0.019159812480211258,
                -0.0018080062000080943,
                -0.027431948110461235,
                -0.05816836282610893,
                -0.04203425720334053,
                -0.04293474182486534,
                -0.02552139014005661,
                0.02170676738023758,
                -0.027104180306196213,
                0.03955809399485588,
                -0.00465196231380105,
                -0.01266520656645298,
                -0.015032032504677773,
                -0.0031323342118412256,
                -0.04047920182347298,
                -0.016714558005332947,
                0.04236435890197754,
                -0.07696311175823212,
                0.012827297672629356,
                0.056534525007009506,
                0.012465179897844791,
                0.008594563230872154,
                -0.0021532985847443342,
                -0.012888137251138687,
                0.06623604893684387,
                -0.002231038874015212,
                -0.07096615433692932,
                -0.0162313561886549,
                0.05591623857617378,
                0.00983641017228365,
                -0.017893433570861816,
                -0.016858093440532684,
                0.040908001363277435,
                0.053386613726615906,
                0.07344364374876022,
                -0.01916750892996788,
                0.015523869544267654,
                -0.06735298782587051,
                0.039895739406347275,
                -0.006118813995271921,
                0.04587913304567337,
                0.029924724251031876,
                0.006202732212841511,
                0.021838298067450523,
                -0.03806186467409134,
                0.010998379439115524,
                -0.004067517351359129,
                0.03932274878025055,
                0.03885285183787346,
                0.009126229211688042,
                -0.007690140977501869,
                -0.044693198055028915,
                -0.028374316170811653,
                0.014009717851877213,
                -0.07226642221212387,
                -0.02738923765718937,
                -0.009563786908984184,
                -0.03052402101457119,
                -0.0006865086033940315,
                -0.08352839201688766,
                0.005448019597679377,
                -0.020351529121398926,
                0.0053648692555725574,
                0.02854851447045803,
                -0.021524861454963684,
                0.0327761285007,
                -0.013818765990436077,
                -0.04159214720129967,
                0.03596428036689758,
                0.05176034942269325,
                -0.01245810091495514,
                -0.00594249926507473,
                0.03103351593017578,
                0.0212508887052536,
                0.029156707227230072,
                0.010219475254416466,
                0.043156698346138,
                0.04086511954665184,
                0.0281157698482275,
                0.031804803758859634,
                0.018070809543132782,
                0.0031752116046845913,
                0.046528372913599014,
                -0.006415164563804865,
                -0.040362220257520676,
                0.005774338264018297,
                -0.027567850425839424,
                -0.01482145395129919,
                -0.020996227860450745,
                -0.028432128950953484,
                -0.03544050455093384,
                -0.008988642133772373,
                -0.030823880806565285,
                0.003798504127189517,
                0.012227731756865978,
                -0.0017771359998732805,
                0.021266939118504524,
                -0.07242026180028915,
                -0.05239519849419594,
                -0.03703822195529938,
                0.007698370609432459,
                -0.026064421981573105,
                0.001085842726752162,
                0.021874770522117615,
                0.005411896854639053,
                0.039251554757356644,
                -0.035243920981884,
                -0.0049102697521448135,
                0.013292969204485416,
                -0.050400033593177795,
                0.036487359553575516,
                -0.017950141802430153,
                0.020792817696928978,
                0.017611071467399597,
                -0.0021457020193338394,
                -0.013830172829329967,
                0.014320628717541695,
                -0.022692233324050903,
                -0.02586696855723858,
                0.003488308982923627,
                -0.041245363652706146,
                -0.05495206639170647,
                -0.04465474933385849,
                -0.03582143038511276,
                -0.019175508990883827,
                0.04829315096139908,
                0.060309749096632004,
                0.015287059359252453,
                0.006834957282990217,
                -0.04088985547423363,
                -0.034158702939748764,
                -0.00848357193171978,
                0.011730270460247993,
                -0.0008454947383143008,
                0.06711262464523315,
                0.027713190764188766,
                -0.008689342997968197,
                0.041584547609090805,
                -0.04271811991930008,
                0.037195585668087006,
                -0.02609885483980179,
                -0.06099240109324455,
                0.053854163736104965,
                0.035243041813373566,
                0.0012471623485907912,
                0.036812808364629745,
                -0.018394121900200844,
                0.06657785922288895,
                0.04405141621828079,
                0.027366168797016144,
                0.06272268295288086,
                -0.009928695857524872,
                -0.030559072270989418,
                -0.020273596048355103,
                -0.032316215336322784,
                -0.009766081348061562,
                0.024098793044686317,
                0.03737790510058403,
                0.038345202803611755,
                -0.03618985414505005,
                0.0154682956635952,
                -0.012247694656252861,
                0.04821113124489784,
                -0.004508515354245901,
                0.07673854380846024,
                -0.0234631709754467,
                -0.08974157273769379,
                0.046219129115343094,
                -0.026193389669060707,
                0.03299209475517273,
                -0.0008741015335544944,
                -0.007378400303423405,
                0.03450806811451912,
                -0.02881631627678871,
                -0.023169003427028656,
                -0.08127117156982422,
                0.006072076968848705,
                0.017334729433059692,
                0.0036632779520004988,
                0.0946902260184288,
                0.00705560902133584,
                -0.01761859655380249,
                -0.00848727859556675,
                -0.0017560884589329362,
                -0.05345330387353897,
                0.013432038016617298,
                -0.015816880390048027,
                -0.018918361514806747,
                0.012357302010059357,
                0.0035363121423870325,
                -0.01117724645882845,
                0.054044969379901886,
                -0.04907569661736488,
                -0.03248422220349312,
                0.009355799295008183,
                -0.019038593396544456,
                -0.04989151284098625,
                -0.03960704058408737,
                0.005133643746376038,
                0.012955233454704285,
                -0.005002486519515514,
                -0.0017701314063742757,
                0.00429752329364419,
                -0.007729026954621077,
                -0.05757187679409981,
                0.006573073100298643,
                0.04092796519398689,
                0.020870013162493706,
                0.02236446738243103,
                -0.0136296721175313,
                0.03686795383691788,
                -0.007995424792170525,
                -0.016036616638302803,
                0.05215054377913475,
                -0.0015946276253089309,
                -0.035884447395801544,
                -0.018526947125792503,
                0.02992210164666176,
                0.030796173959970474,
                -0.004850794095546007,
                -0.0240838173776865,
                0.01241176389157772,
                0.02836337871849537,
                -0.02803281880915165,
                0.01905755326151848,
                0.056498147547245026,
                -0.003347392426803708,
                -0.016266999766230583,
                -0.03919864073395729,
                -0.009175620041787624,
                -0.06316456943750381,
                -0.03224461153149605,
                0.02004377357661724,
                0.022778432816267014,
                0.005481459200382233,
                -0.03179820626974106,
                0.0028253337368369102,
                -0.0035234231036156416,
                0.005601111799478531,
                -0.010594223625957966,
                -0.00026097262161783874,
                -0.07174268364906311,
                0.07546419650316238,
                0.02118290774524212,
                -0.03033156879246235,
                0.005394513253122568,
                -0.026782521978020668,
                -0.005028957035392523,
                0.002915671095252037,
                0.03087187372148037,
                0.028221426531672478,
                -0.011717862449586391,
                0.09177087247371674,
                -0.0483829490840435,
                0.0015495966654270887,
                -0.03534828498959541,
                -0.03690898418426514,
                0.0009528017835691571,
                -0.024959048256278038,
                -0.03044862300157547,
                -0.045974552631378174,
                -0.061321720480918884,
                -0.03053135983645916,
                0.03598000481724739,
                0.014503372833132744,
                -0.05532795935869217,
                -0.004011171869933605,
                0.009982187300920486,
                -0.037612129002809525,
                0.025714773684740067,
                -0.021863825619220734,
                0.03754021227359772,
                -0.03219477832317352,
                -0.011514123529195786,
                0.0019293081713840365,
                0.018247878178954124,
                0.02161472849547863,
                0.050596825778484344,
                0.032927822321653366,
                -0.00789074320346117,
                0.042523883283138275,
                0.010081591084599495,
                -0.011345590464770794,
                -3.810316816390458e-33,
                -0.021817661821842194,
                -0.046473708003759384,
                0.022978469729423523,
                -0.043290600180625916,
                0.030698513612151146,
                -0.058685559779405594,
                -0.009982286021113396,
                -0.04630156606435776,
                -0.02747175469994545,
                2.12516933970619e-05,
                0.0296866986900568,
                0.020610757172107697,
                0.016773024573922157,
                -0.05277417600154877,
                0.03354853019118309,
                0.0443524494767189,
                0.03371082618832588,
                0.006435819901525974,
                0.050843097269535065,
                -0.04961021989583969,
                -0.042159657925367355,
                0.012653828598558903,
                0.03600822389125824,
                -0.013788355514407158,
                -0.0024636173620820045,
                0.0220422875136137,
                0.02180604264140129,
                0.05176885053515434,
                0.04568381980061531,
                -0.034126535058021545,
                -0.010195682756602764,
                0.0035433729644864798,
                -0.0238534864038229,
                0.0018722513923421502,
                0.03150789067149162,
                -0.017947077751159668,
                -0.028257859870791435,
                0.011377537623047829,
                -0.00759778032079339,
                0.006259981542825699,
                0.0437275730073452,
                0.049128126353025436,
                0.10441995412111282,
                -0.04412872716784477,
                -0.031824734061956406,
                0.043000027537345886,
                0.04372851550579071,
                0.052798666059970856,
                0.008590788580477238,
                -0.03386197239160538,
                -0.005432484671473503,
                -0.002388739725574851,
                -0.0031026233918964863,
                0.040376268327236176,
                0.06942683458328247,
                -0.008230452425777912,
                0.029279958456754684,
                -0.06340144574642181,
                0.0028382560703903437,
                -0.04415316879749298,
                0.027988353744149208,
                -0.022240133956074715,
                0.006867913529276848,
                0.02374267578125,
                0.04892798140645027,
                -0.03442546725273132,
                0.08719305694103241,
                0.019218649715185165,
                0.025622926652431488,
                0.005654065404087305,
                0.025464607402682304,
                0.027415769174695015,
                0.02417297475039959,
                -0.07423821836709976,
                -0.02018607035279274,
                0.024393897503614426,
                0.004776606801897287,
                -0.03240722045302391,
                -0.019423363730311394,
                -0.053842443972826004,
                -0.025172049179673195,
                0.010776804760098457,
                -0.046073589473962784,
                -0.015656860545277596,
                0.02574196830391884,
                0.024034615606069565,
                -0.005796601064503193,
                0.05153362825512886,
                -0.0063854181207716465,
                -0.011424886994063854,
                0.000179991009645164,
                0.01840389519929886,
                -0.014155137352645397,
                0.016186125576496124,
                -0.0325467549264431,
                0.020129237323999405,
                -0.0013695901725441217,
                -0.06945592910051346,
                0.04667859151959419,
                0.05696410685777664,
                0.014778357930481434,
                0.0780656635761261,
                -0.02772139012813568,
                -0.020575959235429764,
                -0.022834325209259987,
                0.10178834944963455,
                0.004077789839357138,
                -0.061285294592380524,
                -0.031858477741479874,
                0.014457127079367638,
                0.004807681776583195,
                0.012396779842674732,
                -0.05667245388031006,
                0.00036975700641050935,
                0.009020672179758549,
                0.008950749412178993,
                0.0018979173619300127,
                -0.036718398332595825,
                0.011777055449783802,
                -0.005451912526041269,
                0.02709643542766571,
                -0.02645188383758068,
                0.004569837357848883,
                -0.04141807556152344,
                0.05273747071623802,
                0.001013413886539638,
                0.026274096220731735,
                -0.019629504531621933,
                0.024321844801306725,
                -0.011589808389544487,
                0.011076541617512703,
                0.02781657688319683,
                1.715229842602639e-07,
                0.033155106008052826,
                0.04503762349486351,
                -0.04267323762178421,
                0.09735947102308273,
                -0.005678623449057341,
                -0.009819446131587029,
                -0.028308279812335968,
                0.006045105867087841,
                0.020340140908956528,
                0.013269104063510895,
                0.01590299792587757,
                0.04851975291967392,
                0.002735765650868416,
                0.021944560110569,
                0.00927115511149168,
                -0.051624953746795654,
                0.09437593817710876,
                -0.042094986885786057,
                -0.043071355670690536,
                -0.020837442949414253,
                -0.0017863214015960693,
                0.03500867262482643,
                0.006364140659570694,
                0.03230529651045799,
                0.04602405056357384,
                0.009964775294065475,
                -0.025001345202326775,
                0.013449136167764664,
                0.03769007325172424,
                0.035213299095630646,
                0.05952266603708267,
                0.030601445585489273,
                -0.04846904054284096,
                0.03844710811972618,
                0.007934749126434326,
                0.00491452356800437,
                -0.018494941294193268,
                0.023113839328289032,
                -0.0384855680167675,
                0.016157573089003563,
                0.049949098378419876,
                -0.08944835513830185,
                -0.015506674535572529,
                0.07574357092380524,
                -0.005269879940897226,
                -0.0014363250229507685,
                0.03769228607416153,
                0.0633995532989502,
                0.003972942009568214,
                -0.007180558051913977,
                0.011973166838288307,
                -0.0016550448490306735,
                -0.03498240187764168,
                -0.025360403582453728,
                0.01083099003881216,
                -0.007601471617817879,
                0.003805568441748619,
                0.0144230080768466,
                -0.017523540183901787,
                0.08313947916030884,
                -0.032320551574230194,
                -0.02681959606707096,
                0.02678498812019825,
                0.07661702483892441,
                -0.0063111986964941025,
                0.0007650177576579154,
                0.005252608563750982,
                1.5843714640678482e-34,
                0.000593277276493609,
                0.056718841195106506,
                0.014119019731879234,
                0.03538252040743828,
                -0.007202835287898779,
                0.02557661384344101,
                0.09019383043050766,
                -0.012850427068769932,
                0.010788441635668278,
                -0.010238004848361015,
                -0.00918380357325077,
                0.04926241189241409,
                -0.0634099617600441,
                -0.01478524785488844,
                0.01431269571185112,
                0.017001653090119362,
                0.059515416622161865,
                0.016744092106819153,
                -0.0019702333956956863,
                0.0031233718618750572,
                -0.029448408633470535,
                0.04629393666982651,
                -0.006940673571079969,
                0.038224607706069946,
                0.09159480035305023,
                -0.04305689409375191,
                -0.07756350934505463,
                0.0306281466037035,
                -0.020706258714199066,
                0.030886730179190636,
                -0.0030651134438812733,
                -0.04803790524601936,
                0.04621784761548042,
                -0.047846488654613495,
                -0.0025628323201090097,
                -0.034217603504657745,
                -0.002592106582596898,
                -0.01115601509809494,
                -0.03423721343278885,
                -0.012335984036326408,
                -0.03386494144797325,
                0.005574397277086973,
                -0.016492178663611412,
                0.028346316888928413,
                -0.0006498083821497858,
                1.4709761444464675e-06,
                -0.02811514027416706,
                -0.007977956905961037,
                -0.004705473780632019,
                -0.015594184398651123,
                0.009283408522605896,
                0.0609220527112484,
                0.035155829042196274,
                0.0033854246139526367,
                0.008260183036327362,
                -0.031866881996393204,
                -0.03548120707273483,
                0.036456216126680374,
                -0.06745898723602295,
                0.07392412424087524,
                0.02036248706281185,
                0.004360842984169722,
                -0.016040287911891937,
                -0.005739519372582436,
                0.022290032356977463,
                -0.024264832958579063,
                -0.03138721361756325,
                0.030346421524882317,
                0.030797190964221954,
                0.0523357167840004,
                -0.04035220667719841,
                0.040177129209041595,
                -0.016916295513510704,
                2.388679058640264e-05,
                0.027821917086839676,
                0.046237025409936905,
                0.013715325854718685,
                -0.014260497875511646,
                -0.06140568479895592,
                -0.01323119830340147,
                -0.01667860709130764,
                0.03812473639845848,
                -0.050080493092536926,
                0.018768606707453728,
                0.028357580304145813,
                -0.02910683862864971,
                -0.07527163624763489,
                -0.0831441804766655,
                0.05894100293517113,
                0.0301826149225235,
                -0.047634441405534744,
                -0.020327787846326828,
                -0.056632112711668015,
                -0.03406883031129837,
                -0.004900312051177025,
                -0.015569359064102173,
                0.022347625344991684,
                -0.013186940923333168,
                -0.062305666506290436,
                -0.05926048010587692,
                0.03207419067621231,
                0.022757358849048615,
                -0.03654782101511955,
                0.05641684681177139,
                0.0539524145424366,
                -0.03703419119119644,
                -0.028023917227983475,
                -0.037979770451784134,
                -0.01620488427579403,
                0.006149597931653261,
                0.013523884117603302,
                -0.0367756225168705,
                -0.02656327746808529,
                0.03785640001296997,
                0.04927581921219826,
                0.031152287498116493,
                0.007055759429931641,
                0.010035090148448944,
                0.06677059829235077,
                -0.018018539994955063,
                0.06112397834658623,
                -0.031954992562532425,
                -0.0765049159526825,
                -0.023129941895604134,
                0.011439662426710129,
                0.09370741993188858,
                0.04307471960783005,
                0.012961825355887413,
                0.024407530203461647,
                -0.011154498904943466,
                0.04721716046333313,
                -0.05352871119976044,
                0.006986342836171389,
                0.017183618620038033,
                0.05032791197299957,
                -0.03727896884083748,
                0.008746388368308544,
                0.013422469608485699,
                -0.0020370744168758392,
                -0.0005907063605263829,
                0.02610180154442787,
                -0.0058154151774942875,
                0.004755356814712286,
                0.015146519057452679,
                -0.03588787838816643,
                0.008838055655360222,
                0.06947200745344162,
                -0.006775279063731432,
                0.031863708049058914,
                -0.014120213687419891,
                -0.028331048786640167,
                0.038485411554574966,
                -0.06454776972532272,
                -0.04765710234642029,
                -0.08821433037519455,
                -0.0580114871263504,
                0.011625105515122414,
                0.020689629018306732,
                -0.00287256995216012,
                0.021811217069625854,
                0.024561701342463493,
                -0.011225142516195774,
                0.024822484701871872,
                -0.03850618749856949,
                -0.013256775215268135,
                0.0714855045080185,
                -0.011002426035702229,
                0.013162556104362011,
                -0.07036095857620239,
                0.025422045961022377,
                0.006799750030040741,
                0.021586949005723,
                -0.06403318047523499,
                0.05732942745089531,
                -0.07242520153522491,
                -0.027639096602797508,
                0.0006397852557711303,
                0.012451058253645897,
                0.02482776530086994,
                -0.0168458241969347,
                -0.0019049049587920308,
                -0.08100159466266632,
                0.011527042835950851,
                0.0009229714632965624,
                0.015255037695169449,
                0.0028077412862330675,
                0.051245104521512985,
                0.014814418740570545,
                -0.0168535765260458,
                0.026192786172032356,
                0.007175030652433634,
                0.007762182503938675,
                -0.025569986552000046,
                -0.012168503366410732,
                0.08538856357336044,
                -0.019745653495192528,
                -0.02486487850546837,
                -0.02762194350361824,
                0.025592250749468803,
                -0.000624799111392349,
                -0.026021074503660202,
                0.008306949399411678,
                0.014948311261832714,
                0.02969839796423912,
                0.007932365871965885,
                0.05131718888878822,
                -0.021483900025486946,
                -0.013219055719673634,
                0.004802962765097618,
                -0.0015822388231754303,
                -0.06995132565498352,
                -0.0692806988954544,
                -0.04746851325035095,
                0.01152859814465046,
                0.0479133278131485,
                -0.0018938676221296191,
                -0.03832079470157623,
                0.039340995252132416,
                -0.016908101737499237,
                -0.026209194213151932,
                -0.007416716776788235,
                0.014643749222159386,
                0.012022113427519798,
                -0.012099419720470905,
                -0.021642616018652916,
                -0.026722552254796028,
                -0.005170057062059641,
                -0.021151486784219742,
                -0.025617539882659912,
                0.008555300533771515,
                -0.02356426976621151,
                -0.001014277688227594,
                0.04117577522993088,
                0.03086145594716072,
                0.04657343775033951,
                -0.005348110105842352,
                -0.032573629170656204,
                -0.023110447451472282,
                0.01827213540673256,
                -0.03305681794881821,
                0.0239457655698061,
                -0.006449945271015167,
                0.021365685388445854,
                0.01976979523897171,
                0.062492188066244125,
                0.03704692795872688,
                -0.06404094398021698,
                0.016196833923459053,
                -7.430016557918862e-05,
                0.013133283704519272,
                -0.01890246942639351,
                -0.017575405538082123,
                0.03880295902490616,
                0.07672549784183502,
                0.014944464899599552,
                0.06501413136720657,
                0.040730785578489304,
                0.014699375256896019,
                -0.006587405223399401,
                0.04771279916167259,
                -0.03581216558814049,
                -0.023056989535689354,
                0.02713434398174286,
                0.016839725896716118,
                0.04659144580364227,
                0.0727376639842987,
                0.03014504536986351,
                -0.015558887273073196,
                0.05017388239502907,
                -0.07053609192371368,
                -0.0441892109811306,
                -0.0028626874554902315,
                -0.03264036774635315,
                0.01538204587996006,
                0.008136221207678318,
                0.03708117827773094,
                -0.0057576242834329605,
                0.011454729363322258,
                0.008917161263525486,
                -0.020994558930397034,
                -0.008535456843674183,
                -0.04306136816740036,
                0.032727260142564774,
                -0.024717191234230995,
                -0.00334108155220747,
                -0.013073612935841084,
                0.03618621826171875,
                -0.04281068965792656,
                -0.10623160004615784,
                -0.036837138235569,
                -0.001341931987553835,
                0.06242191046476364,
                -0.03265788406133652,
                -0.05330008640885353,
                -0.037208009511232376,
                0.01528497226536274,
                0.03301381319761276,
                0.05318134278059006,
                -0.05795751139521599,
                -0.0120792081579566,
                0.007922079414129257,
                -0.02111349254846573,
                -0.01711767166852951,
                0.0022517351899296045,
                -0.032857317477464676,
                -0.014496573247015476,
                -0.03775101155042648,
                0.015735628083348274,
                0.0004996458301320672,
                -0.003093091305345297,
                0.05332682281732559,
                0.048373207449913025,
                0.02106897532939911,
                0.04355364665389061,
                -0.0059174420312047005,
                -0.004593020770698786,
                0.015576512552797794,
                0.09621124714612961,
                -0.006543680559843779,
                -0.024291032925248146,
                0.04924442619085312,
                0.068082295358181,
                -0.010499751195311546,
                -0.028581256046891212,
                0.02877790480852127,
                0.007905633188784122,
                -0.013890090398490429,
                -0.07097630202770233,
                0.031406886875629425,
                0.00493041705340147,
                0.04721515625715256,
                0.02228805422782898,
                -0.004715317394584417,
                0.07074693590402603,
                0.012600401416420937,
                0.02248568646609783,
                -0.011701489798724651,
                -0.08506650477647781,
                0.012753871269524097,
                0.03670070692896843,
                0.04225056990981102,
                -0.00023784612130839378,
                -0.002045628847554326,
                -0.07900428771972656,
                0.009009107016026974,
                0.0419275127351284,
                0.0551280677318573,
                -0.02013739012181759,
                -0.027778448536992073,
                -0.015115726739168167,
                0.03945315256714821,
                0.0020806894171983004,
                0.0018157858867198229,
                0.009612337686121464,
                0.03140854462981224,
                -0.04359240084886551,
                0.0020670003723353148,
                -0.02726505510509014,
                -0.023342089727520943,
                0.03858383372426033,
                -0.01764858141541481,
                0.09150832891464233,
                0.03603193163871765,
                0.02539946138858795,
                0.011476458050310612,
                0.01794757880270481,
                0.006179153919219971,
                -0.07061275839805603,
                -0.025987502187490463,
                -0.07932593673467636,
                -0.021348053589463234,
                0.011055342853069305,
                -0.04805109277367592,
                0.0025156561750918627,
                0.08550192415714264,
                -0.020349066704511642,
                0.05887668952345848,
                0.0001903428346849978,
                -0.03068981133401394,
                -0.019255179911851883,
                0.002661049598827958,
                -0.04712820425629616,
                -0.005881094839423895,
                -0.007000487297773361,
                0.005287521984428167,
                0.039762455970048904,
                0.04160492122173309,
                0.011019841767847538,
                -0.031956251710653305,
                -0.08731371909379959,
                0.013621093705296516,
                0.013897816650569439,
                -0.03984912112355232,
                0.019746894016861916,
                -0.031181976199150085,
                0.05281612277030945,
                -0.04489210247993469,
                0.012484412640333176,
                0.005523937754333019,
                0.0037595517933368683,
                0.031207958236336708,
                -0.012629909440875053,
                0.015813514590263367,
                -0.09969409555196762,
                -0.04690318554639816,
                -0.02328207716345787,
                -0.03151501715183258,
                -0.0026195915415883064,
                0.10773730278015137,
                -0.02684168517589569,
                0.027299122884869576,
                0.02072962187230587,
                0.009408967569470406,
                0.012780380435287952,
                0.014398865401744843,
                0.06479159742593765,
                -0.051676299422979355,
                0.03030398115515709,
                0.01023482158780098,
                -0.08870641887187958,
                0.019253568723797798,
                0.02715395949780941,
                0.021897511556744576,
                0.01276649720966816,
                -0.018974289298057556,
                0.04022245481610298,
                0.04814713075757027,
                -0.029616739600896835,
                -0.0019065496744588017,
                -0.005211027339100838,
                -0.04906724765896797,
                -0.03597790375351906,
                0.005187722388654947,
                -0.015373790636658669,
                0.011889154091477394,
                -0.006985884625464678,
                0.020380057394504547,
                0.005658680573105812,
                0.00791250355541706,
                0.00648568756878376,
                0.017965266481041908,
                0.050033584237098694,
                -0.03341817483305931,
                -0.05278777331113815,
                -0.01691446267068386,
                -0.025219764560461044,
                -0.04088958725333214,
                -0.009434717707335949,
                0.02071043662726879,
                0.0010947809787467122,
                -0.05199062079191208,
                0.07671643793582916,
                0.04553388059139252,
                -0.022455085068941116,
                -0.025731565430760384,
                -0.04282760247588158,
                0.015093399211764336,
                0.03554755076766014,
                -0.07340819388628006,
                -0.08081309497356415,
                0.05516519024968147,
                0.08858002722263336,
                -0.032275862991809845,
                -0.0756354108452797,
                0.018931381404399872,
                0.008302616886794567,
                -0.01211463287472725,
                0.013083330355584621,
                -0.050011880695819855,
                -0.0012218437623232603,
                0.013341627083718777,
                0.010599233210086823,
                0.02728467434644699,
                -0.051492027938365936,
                0.011613191105425358,
                0.021900873631238937,
                -0.07572093605995178,
                0.04675474762916565,
                0.013040472753345966,
                0.032289452850818634,
                -0.057191040366888046,
                0.0018797223456203938,
                0.03393375128507614,
                -0.020918017253279686,
                -0.020352715626358986,
                -0.05826319009065628,
                -0.019884996116161346,
                0.010925732553005219,
                -0.07102645933628082,
                -0.00523361936211586,
                0.033954184502363205,
                0.0692538246512413,
                -0.031887639313936234,
                0.015297627076506615,
                -0.08343542367219925,
                -0.040009208023548126,
                -0.0034077411983162165,
                0.0074932761490345,
                -0.02960284985601902,
                0.01988312229514122,
                -0.019352391362190247,
                -0.02651270665228367,
                -0.00921772699803114,
                0.022080309689044952,
                -0.01315864734351635,
                0.005505527835339308,
                0.005664337892085314,
                -0.013111984357237816,
                0.10327549278736115,
                0.03447399288415909,
                0.018350942060351372,
                0.02334665320813656,
                -0.03197932615876198,
                -0.05496729537844658,
                -0.02453811839222908,
                -0.00023311561380978674,
                0.05513451248407364,
                0.03125273808836937,
                0.08312898129224777,
                -0.036771439015865326,
                -0.013130929321050644,
                0.0002879441308323294,
                0.009472471661865711,
                -0.028475752100348473,
                -0.0688091591000557,
                0.008245027624070644,
                0.033235639333724976,
                -0.027209309861063957,
                -0.020258842036128044,
                -0.032370708882808685,
                -0.0339445024728775,
                -0.018934976309537888,
                -0.03569949045777321,
                0.05595484748482704,
                -0.011217421852052212,
                0.028304656967520714,
                -0.04391572251915932,
                0.010275948792696,
                -0.007229842245578766,
                0.01764385588467121,
                0.029468564316630363,
                -0.034675389528274536,
                0.03041277453303337,
                0.053116075694561005,
                0.009911675937473774,
                -0.024777274578809738,
                0.05569006875157356,
                0.01615183614194393,
                0.02442736178636551,
                0.0030123023316264153,
                0.04709576070308685,
                0.05391646549105644,
                -0.016383593901991844,
                -0.03774961084127426,
                0.037918899208307266,
                0.07013101130723953,
                -0.00586968008428812,
                0.0005169546348042786,
                0.007172245066612959,
                -0.05083627626299858,
                0.07125729322433472,
                -0.035862136632204056,
                0.02370515652000904,
                0.033118702471256256,
                0.04732236638665199,
                -0.006243652664124966,
                -5.4208263616215496e-33,
                -0.039029281586408615,
                -0.041178688406944275,
                0.01951787807047367,
                0.04705897346138954,
                -0.0014504804275929928,
                -0.019787823781371117,
                0.048075977712869644,
                0.025536727160215378,
                -0.03919481113553047,
                -0.048677247017621994,
                0.003407958894968033,
                0.019980022683739662,
                0.023132184520363808,
                -0.01216041762381792,
                0.003810744034126401,
                0.013325701467692852,
                -0.021349454298615456,
                0.045677702873945236,
                -0.021440334618091583,
                -0.051002100110054016,
                0.030721956863999367,
                0.0205368734896183,
                0.056597668677568436,
                0.0019005009671673179,
                -0.01733715832233429,
                0.010144239291548729,
                -0.04494243487715721,
                -0.0012312679318711162,
                -0.016335725784301758,
                -0.005372729152441025,
                -0.009782614186406136,
                0.01113995723426342,
                -0.02832268737256527,
                -0.028186513110995293,
                0.008274313062429428,
                0.05707879364490509,
                0.00023072802287060767,
                -0.03938000276684761,
                -0.031784046441316605,
                0.011153516359627247,
                0.00559208681806922,
                0.009732278995215893,
                0.08236293494701385,
                -0.04357688128948212,
                0.0028591849841177464,
                0.025292357429862022,
                0.04719585180282593,
                -0.058084528893232346,
                0.018576601520180702,
                0.03662080317735672,
                -0.0356324166059494,
                -0.004906186368316412,
                -0.033441945910453796,
                -0.015127596445381641,
                0.026169372722506523,
                -0.062360480427742004,
                -0.054877087473869324,
                -0.02527419477701187,
                0.02413700334727764,
                0.07516326755285263,
                0.03570070490241051,
                0.031494803726673126,
                -0.01690593548119068,
                -0.024665342643857002,
                -0.017221668735146523,
                -0.020349698141217232,
                0.01126008853316307,
                0.009387585334479809,
                0.034156136214733124,
                -0.005425099283456802,
                -0.04416367784142494,
                -0.012241723015904427,
                -0.000298280268907547,
                -0.017209621146321297,
                -0.0455065555870533,
                -0.02950207144021988,
                -0.04391181096434593,
                -0.006038402207195759,
                0.037671156227588654,
                -0.025509977713227272,
                -0.041780851781368256,
                0.014667599461972713,
                0.04139348492026329,
                0.019662512466311455,
                0.012971007265150547,
                0.02538890950381756,
                -0.020075758919119835,
                0.0013262599240988493,
                -0.024920666590332985,
                -0.018070368096232414,
                -0.04080856591463089,
                0.004814806394279003,
                -0.05171759054064751,
                0.001288711209781468,
                0.012198274955153465,
                -0.016568342223763466,
                -0.03698740154504776,
                -0.0036784305702894926,
                -0.022831931710243225,
                -0.025537095963954926,
                0.007838710211217403,
                -0.0014166465261951089,
                -0.02352808229625225,
                -0.02870795503258705,
                0.055342819541692734,
                0.014237452298402786,
                -0.06475186347961426,
                0.04173378273844719,
                -0.019954141229391098,
                -0.027629952877759933,
                0.023928822949528694,
                -0.02416549064218998,
                -0.0045031593181192875,
                0.08825982362031937,
                -0.001091914833523333,
                0.003266598330810666,
                -0.014477592892944813,
                -0.04464335739612579,
                -0.01638147234916687,
                -0.055532995611429214,
                -0.02348829060792923,
                0.0330340638756752,
                -0.034975841641426086,
                -0.02986166626214981,
                -0.0710318386554718,
                -0.04279431700706482,
                -0.05422430485486984,
                -0.023303601890802383,
                -0.017672691494226456,
                0.058374762535095215,
                -0.022853514179587364,
                0.0063266814686357975,
                2.0685628498995356e-07,
                0.006020055618137121,
                0.04919755458831787,
                -0.0021994509734213352,
                0.0722145140171051,
                -0.04444336146116257,
                0.013608253560960293,
                -0.05012122914195061,
                0.03768274933099747,
                -0.001123490510508418,
                -0.03461705148220062,
                0.03415466472506523,
                -0.05579378083348274,
                0.007554125040769577,
                0.00803618598729372,
                0.007336427457630634,
                0.033306486904621124,
                -0.02262638695538044,
                0.008665459230542183,
                -0.019717279821634293,
                0.018285732716321945,
                0.0746351107954979,
                0.007853779010474682,
                0.09398584812879562,
                0.0020671633537858725,
                0.007955638691782951,
                -0.061551786959171295,
                -0.02253265492618084,
                -0.021586045622825623,
                0.07964568585157394,
                -0.009877917356789112,
                -0.044685717672109604,
                0.0028617428615689278,
                0.01974627375602722,
                0.010392643511295319,
                -0.022381257265806198,
                -0.05892552062869072,
                0.030623339116573334,
                0.041526202112436295,
                -3.836517862509936e-05,
                0.06646876782178879,
                0.0443875752389431,
                0.011271108873188496,
                2.8764114176738076e-05,
                -0.038943592458963394,
                0.001515782903879881,
                0.04259759187698364,
                0.022064654156565666,
                -0.012563429772853851,
                -0.01985672302544117,
                -0.010583131574094296,
                -0.0223623625934124,
                0.011396952904760838,
                0.021891294047236443,
                0.015496842563152313,
                -0.022789878770709038,
                0.04716982692480087,
                -0.017613325268030167,
                0.04471513628959656,
                0.016296759247779846,
                -0.02548159845173359,
                -0.010880911722779274,
                -0.040893878787755966,
                0.023788029327988625,
                0.011800947599112988,
                0.06850113719701767,
                0.025822987779974937,
                0.017731908708810806,
                1.0889085160077977e-34,
                0.0025266550946980715,
                -0.04119044542312622,
                0.03635256364941597,
                -0.012935089878737926,
                -0.05232680216431618,
                0.03659787029027939,
                0.007090163417160511,
                -0.014872534200549126,
                0.036237865686416626,
                0.02031872048974037,
                0.00031137431506067514,
                -0.016266604885458946,
                0.007322377059608698,
                -0.0471716970205307,
                0.039962004870176315,
                -0.007814200595021248,
                0.024163074791431427,
                -0.023128006607294083,
                0.011838412843644619,
                -0.00838617142289877,
                -0.005983985029160976,
                0.009079741314053535,
                0.0010093329474329948,
                0.04539478197693825,
                0.08029432594776154,
                -0.026375385001301765,
                -0.016637397930026054,
                0.022849498316645622,
                0.00625274796038866,
                -0.03063979186117649,
                0.039881858974695206,
                0.017571445554494858,
                -0.011273667216300964,
                0.013526678085327148,
                -0.037937719374895096,
                -0.01996745727956295,
                -0.0025982174556702375,
                0.010125468485057354,
                -0.005332304630428553,
                0.05094121769070625,
                -0.01900535449385643,
                0.05086600035429001,
                0.04689248278737068,
                0.030029863119125366,
                -0.018106238916516304,
                9.704651802167064e-07,
                -0.048078753054142,
                0.004900749307125807,
                0.03309900313615799,
                0.013459521345794201,
                -0.006250437814742327,
                0.05257562920451164,
                0.013391312211751938,
                0.01904558762907982,
                0.0021593745332211256,
                0.002343464409932494,
                -0.04036517068743706,
                -0.015085671097040176,
                -0.03197401389479637,
                0.020775573328137398,
                0.0020336208399385214,
                -0.004730187356472015,
                -0.009572172537446022,
                -0.02375837415456772,
                0.02546275593340397,
                0.029815176501870155,
                0.027044478803873062,
                0.019885491579771042,
                0.03692113235592842,
                0.05519913136959076,
                0.07430168241262436,
                -0.0006297135259956121,
                0.03231898322701454,
                -0.014712664298713207,
                0.007581739220768213,
                -0.0003638689231593162,
                0.01645861566066742,
                0.015192647464573383,
                -0.017134059220552444,
                -0.009534124284982681,
                0.013978967443108559,
                0.02401116117835045,
                -0.017646947875618935,
                0.060031529515981674,
                0.07044942677021027,
                -0.04117138311266899,
                -0.042095158249139786,
                -0.00855493824928999,
                -0.0010215486399829388,
                -0.04916256293654442,
                -0.015448451042175293,
                -0.050269681960344315,
                -0.002276355866342783,
                -0.0631694346666336,
                -0.04972478374838829,
                0.014798884280025959,
                0.018427053466439247,
                -0.02253108285367489,
                -0.03215240314602852,
                0.0012626234674826264,
                -0.016882089897990227,
                -0.014880940318107605,
                -0.0052952454425394535,
                0.003659453708678484,
                -0.0067423558793962,
                -0.039944056421518326,
                0.00663033127784729,
                -0.019030041992664337,
                -0.06983691453933716,
                0.06864216923713684,
                -0.025141902267932892,
                -0.00139772763941437,
                -0.014746849425137043,
                0.054456546902656555,
                -0.00046262965770438313,
                -0.031467314809560776,
                -0.007314086426049471,
                -0.02897179313004017,
                0.017807792872190475,
                0.026706434786319733,
                0.02137075923383236,
                -0.03291407227516174,
                0.005988483317196369,
                -0.006266839802265167,
                0.04995403438806534,
                -0.020235178992152214,
                0.03477194532752037,
                -0.04380619153380394,
                -0.008323602378368378,
                0.025261742994189262,
                -0.018589388579130173,
                0.03119640238583088,
                -0.05417115241289139,
                -0.012555678375065327,
                0.020088132470846176,
                -0.04660851135849953,
                -0.0359775573015213,
                -0.019552843645215034,
                -0.01612008735537529,
                -0.02413536049425602,
                -0.019932307302951813,
                0.01586051844060421,
                0.001012130407616496,
                0.027889614924788475,
                0.011449725367128849,
                0.03847223520278931,
                0.0077763828448951244,
                0.007663849741220474,
                0.0584893673658371,
                -0.0808660015463829,
                -0.018643194809556007,
                0.03951607272028923,
                -0.05916984751820564,
                -0.008744942955672741,
                0.008536657318472862,
                0.04821312427520752,
                0.04598820582032204,
                -0.0641140416264534,
                0.07354520261287689,
                0.012343022972345352,
                0.06896825134754181,
                -0.04479349032044411,
                0.04479710012674332,
                -0.031056880950927734,
                0.02262035943567753,
                0.05506538972258568,
                -0.008375277742743492,
                0.028503209352493286,
                -0.01572125405073166,
                -0.02241303212940693,
                -0.011481178924441338,
                -0.021816924214363098,
                -0.07900398224592209,
                0.04166850820183754,
                -0.02280048467218876,
                -0.040924493223428726,
                -0.020429208874702454,
                -0.03633522614836693,
                0.08116202801465988,
                -0.017235424369573593,
                -0.029790792614221573,
                0.05120382085442543,
                -0.009219283238053322,
                -0.003127467818558216,
                0.02831207402050495,
                -0.000666951178573072,
                0.010326464660465717,
                0.04352041333913803,
                0.04561219736933708,
                -0.03083154745399952,
                0.06663592159748077,
                0.06400976330041885,
                -0.07182926684617996,
                0.06343062222003937,
                0.05568218603730202,
                -0.030805092304944992,
                -0.008152354508638382,
                -0.07165401428937912,
                0.035388167947530746,
                -0.0001842798083089292,
                -0.058657921850681305,
                0.01173481997102499,
                0.021278994157910347,
                0.011946800164878368,
                0.0013773974496871233,
                0.026193948462605476,
                0.06922022253274918,
                -0.010882438160479069,
                -0.07595255225896835,
                0.06057950481772423,
                -0.024105798453092575,
                -0.01882893033325672,
                -0.011723516508936882,
                0.08658736199140549,
                -0.04016527161002159,
                -0.013261639513075352,
                -0.061245791614055634,
                0.033396802842617035,
                -0.08404158055782318,
                0.012216659262776375,
                -0.010427024215459824,
                -0.0064177666790783405,
                -0.0018462690059095621,
                -0.0008769423584453762,
                -0.03482930734753609,
                0.003934502135962248,
                0.045972295105457306,
                -0.023085936903953552,
                0.03223932161927223,
                0.007986849173903465,
                0.05100977048277855,
                -0.032139454036951065,
                0.07365000247955322,
                0.0017188031924888492,
                -0.036479853093624115,
                0.012743528932332993,
                0.01897621341049671,
                0.017535485327243805,
                -0.02722226269543171,
                0.052905939519405365,
                -0.0035519571974873543,
                0.024420684203505516,
                -0.025947393849492073,
                0.009177111089229584,
                -0.021462779492139816,
                0.010398946702480316,
                0.003347828285768628,
                0.06967958062887192,
                -0.013584083877503872,
                -0.020852571353316307,
                0.010474918410182,
                -0.002663582330569625,
                -0.049695905297994614,
                0.005544711835682392,
                0.020210739225149155,
                -0.003125349758192897,
                -0.017606668174266815,
                -0.04239583760499954,
                -0.035735078155994415,
                0.038100000470876694,
                -0.021257175132632256,
                -0.031253740191459656,
                -0.022163517773151398,
                0.05697179213166237,
                -0.008247601799666882,
                0.05584970861673355,
                -0.00413858937099576,
                -0.020098617300391197,
                -0.028581850230693817,
                -0.0361778624355793,
                0.00904540903866291,
                -0.0499873161315918,
                -0.0835164338350296,
                0.004705504514276981,
                0.015689875930547714,
                -0.023564189672470093,
                -0.03398049995303154,
                0.043113358318805695,
                0.0643022283911705,
                -0.006936183199286461,
                0.023292994126677513,
                0.01758633740246296,
                0.01898236572742462,
                -0.0017441298114135861,
                -0.015558377839624882,
                -0.002697604475542903,
                0.0009946930222213268,
                -0.01960553601384163,
                -0.00019142671953886747,
                -0.04847465083003044,
                0.06977225840091705,
                0.024019310250878334,
                -0.044254008680582047,
                -0.02280384488403797,
                0.026464279741048813,
                1.792425973690115e-05,
                -0.012219845317304134,
                0.02427431382238865,
                -0.03909679502248764,
                -0.024612009525299072,
                0.021042564883828163,
                0.004917058628052473,
                -0.005054953973740339,
                -0.024258555844426155,
                -0.03294508531689644,
                -0.004002320114523172,
                -0.00025151559384539723,
                0.035695385187864304,
                -0.026228979229927063,
                -0.041427820920944214,
                -0.010162604041397572,
                -0.05155254527926445,
                -0.01512698270380497,
                -0.002758128335699439,
                0.042921192944049835,
                0.033528976142406464,
                0.0902605652809143,
                0.0018809862667694688,
                -0.052285026758909225,
                0.07737503945827484,
                0.011133486405014992,
                0.02513468638062477,
                0.004327883943915367,
                0.019465358927845955,
                0.0383828766644001,
                -0.019096694886684418,
                -0.024667154997587204,
                -0.020709101110696793,
                0.017117174342274666,
                -0.03865166753530502,
                0.007484931033104658,
                0.0004478858318179846,
                -0.0032040909864008427,
                -0.033917732536792755,
                -0.0015129593666642904,
                -0.057412512600421906,
                -0.0835006907582283,
                -0.06240694224834442,
                0.03151680901646614,
                -0.048474665731191635,
                0.0112509960308671,
                0.02912033535540104,
                -0.12072088569402695,
                -0.010738428682088852,
                0.02978282980620861,
                -0.04877009615302086,
                0.021738527342677116,
                -0.06711337715387344,
                -0.03665962070226669,
                0.0548519492149353,
                -0.025396671146154404,
                -0.003878556424751878,
                0.059063225984573364,
                -0.019392352551221848,
                -0.022009393200278282,
                0.09999972581863403,
                -0.0747167095541954,
                0.024024076759815216,
                0.012536420486867428,
                -0.012458628974854946,
                0.02575669251382351,
                0.01237832847982645,
                0.0055695208720862865,
                -0.01233422290533781,
                -0.0009362882119603455,
                0.00423083920031786,
                -0.013422797434031963,
                0.012989264912903309,
                -0.03927058354020119,
                -0.09028980135917664,
                0.017870398238301277,
                0.048809945583343506,
                0.00887352041900158,
                -0.06132332980632782,
                -0.015210114419460297,
                -0.02332339435815811,
                -0.007455680053681135,
                -0.006344543769955635,
                0.023791013285517693,
                0.008297557942569256,
                0.02008787915110588,
                -0.023164862766861916,
                0.004570117220282555,
                0.029866661876440048,
                -0.027785293757915497,
                0.06252554059028625,
                -0.026989882811903954,
                -0.04099481925368309,
                -0.07489031553268433,
                -0.08901522308588028,
                0.004980682861059904,
                0.002180743496865034,
                0.012270133011043072,
                0.12071377038955688,
                0.016431739553809166,
                -0.022283151745796204,
                -0.025698570534586906,
                -0.04531916230916977,
                -0.005968872457742691,
                0.0051284730434417725,
                0.0589437261223793,
                -0.04137328639626503,
                -0.08368436247110367,
                -0.07698972523212433,
                -0.03393080085515976,
                -0.004092840012162924,
                -0.007903816178441048,
                -0.004779858980327845,
                0.06636042147874832,
                -0.05780406668782234,
                0.004959686193615198,
                0.019582970067858696,
                -0.022609248757362366,
                0.0037297741509974003,
                0.02163303643465042,
                -0.024159833788871765,
                -0.026560503989458084,
                -0.0020953069906681776,
                0.03356289491057396,
                -0.07043760269880295,
                -0.005336225964128971,
                0.008143708109855652,
                0.016749417409300804,
                -0.014177418313920498,
                -0.09381715208292007,
                0.06119252368807793,
                0.08867303282022476,
                -0.032705582678318024,
                -0.05421329289674759,
                0.07254104316234589,
                0.017714712768793106,
                0.010300836525857449,
                0.011972366832196712,
                0.014975689351558685,
                -0.012342979200184345,
                -0.007320471107959747,
                -0.007228676695376635,
                0.04171357676386833,
                0.04120231792330742,
                0.016940556466579437,
                0.040115270763635635,
                0.0204035472124815,
                0.017632240429520607,
                0.04382462054491043,
                -0.005280223675072193,
                -0.027598164975643158,
                -0.012881052680313587,
                0.02337239310145378,
                -0.031197454780340195,
                0.029840046539902687,
                -0.02215733751654625,
                -0.004021581262350082,
                0.08440569043159485,
                0.013154938817024231,
                0.022138888016343117,
                0.022781390696763992,
                -0.024771330878138542,
                0.006838459987193346,
                -0.03663073480129242,
                -0.023006116971373558,
                0.034300241619348526,
                0.012457592412829399,
                -0.009804326109588146,
                -0.028898531571030617,
                0.023080380633473396,
                0.0414728969335556,
                -0.040319569408893585,
                -0.0456332266330719,
                -0.0457426942884922,
                -0.017570720985531807,
                0.055464327335357666,
                0.09180223196744919,
                0.028172440826892853,
                -0.02363250032067299,
                -0.006563025526702404,
                0.038440387696027756,
                -0.017438767477869987,
                -0.008926626294851303,
                -0.04422590136528015,
                -0.017553692683577538,
                -0.08896216750144958,
                -0.0061233872547745705,
                0.013901572674512863,
                -0.052261970937252045,
                0.04381730034947395,
                0.057830289006233215,
                0.05495905503630638,
                -0.02808108739554882,
                -0.0027029539924114943,
                -0.019688565284013748,
                0.018741974607110023,
                0.03595462813973427,
                0.05110521614551544,
                0.06643937528133392,
                -0.02242848090827465,
                -0.007508568000048399,
                0.005117130931466818,
                -0.04645077511668205,
                0.01618620939552784,
                -0.013733143918216228,
                -0.03225906565785408,
                0.0029456354677677155,
                0.051669422537088394,
                -0.04573146998882294,
                -0.029841765761375427,
                -0.019571717828512192,
                -0.09076622873544693,
                -0.008539367467164993,
                0.03288310021162033,
                0.04765043407678604,
                0.018171198666095734,
                0.04235772788524628,
                0.058773770928382874,
                0.019463740289211273,
                -0.04737388715147972,
                0.035821035504341125,
                0.030755117535591125,
                0.03718959912657738,
                0.033307816833257675,
                -0.03433354198932648,
                0.0007664453587494791,
                -0.01290587056428194,
                0.018122943118214607,
                0.034708745777606964,
                0.027073217555880547,
                0.015093311667442322,
                0.018399041146039963,
                -0.029882969334721565,
                -0.004871252458542585,
                -0.04158131033182144,
                0.015666572377085686,
                0.024760061874985695,
                -0.002116575138643384,
                0.025514138862490654,
                -0.03124694526195526,
                0.12193139642477036,
                0.027918575331568718,
                0.004487760830670595,
                -0.060160115361213684,
                -0.048757538199424744,
                0.003846310544759035,
                -0.0289964247494936,
                -0.09002933651208878,
                0.02628168649971485,
                -0.05488097295165062,
                -0.03563852608203888,
                0.05724659934639931,
                -0.020960532128810883,
                -0.04842550680041313,
                -0.024699339643120766,
                -0.05506877228617668,
                0.03928293287754059,
                -0.0542866550385952,
                0.002483061281964183,
                0.04457535967230797,
                -0.00101207394618541,
                0.037511326372623444,
                -0.06984590739011765,
                -0.03689156845211983,
                -0.011157374829053879,
                0.03838822990655899,
                -0.006078914273530245,
                0.023395173251628876,
                -0.02238379791378975,
                -0.0431913286447525,
                0.051671672612428665,
                -4.7243826929578075e-33,
                0.0016545348335057497,
                0.01627085916697979,
                -0.01617780141532421,
                0.03190067410469055,
                -0.00891110673546791,
                -0.027310490608215332,
                -0.005890114698559046,
                -0.0217894297093153,
                0.04418618232011795,
                -0.006657140329480171,
                -0.018474608659744263,
                0.01680613122880459,
                0.025094684213399887,
                -0.04582085832953453,
                0.016109494492411613,
                -0.0184883214533329,
                0.029097704216837883,
                0.014170179143548012,
                0.03635036572813988,
                -0.0620451495051384,
                -0.008304384537041187,
                0.03496808558702469,
                0.010588441975414753,
                -0.017328860238194466,
                -0.026499107480049133,
                -0.03084871731698513,
                -0.018745189532637596,
                -0.006197983864694834,
                -0.03929489478468895,
                0.008950811810791492,
                0.02720002830028534,
                -0.019525732845067978,
                0.005123510956764221,
                -0.004491711501032114,
                -0.05919438600540161,
                0.05345156416296959,
                -0.11234942823648453,
                0.01259670127183199,
                0.0552782267332077,
                0.02906211093068123,
                0.017622632905840874,
                0.0006411565700545907,
                0.021565234288573265,
                -0.03567177802324295,
                0.011482848785817623,
                0.022613046690821648,
                0.04000372812151909,
                -0.0005803690874017775,
                0.030496234074234962,
                -0.004254230763763189,
                -0.011423295363783836,
                0.007089568767696619,
                0.0092308409512043,
                0.06824536621570587,
                0.07632767409086227,
                -0.0035946324933320284,
                -0.012624804861843586,
                0.004903485998511314,
                -0.025998149067163467,
                -0.020499518141150475,
                0.04833131656050682,
                0.019304869696497917,
                -0.004318335559219122,
                0.02322685718536377,
                0.07114051282405853,
                -0.02836911752820015,
                0.03122684173285961,
                0.038690559566020966,
                0.04092492535710335,
                0.0025018483866006136,
                -0.037846889346838,
                0.019676851108670235,
                -0.008869475685060024,
                0.02323373593389988,
                0.044463738799095154,
                -0.05164779722690582,
                -0.030151305720210075,
                0.053700078278779984,
                0.00493384525179863,
                -0.0017249492229893804,
                -0.026067564263939857,
                -0.025923850014805794,
                0.033337101340293884,
                -0.00022514084412250668,
                0.024727223441004753,
                0.03543512523174286,
                -0.09192958474159241,
                -0.014053098857402802,
                0.004956328310072422,
                -0.009715777821838856,
                -0.046573206782341,
                0.042895715683698654,
                0.0043882024474442005,
                -0.009666413068771362,
                0.05302505940198898,
                -0.04592493548989296,
                0.016838431358337402,
                0.0025927347596734762,
                -0.0011776075698435307,
                -0.0046196188777685165,
                0.04640538617968559,
                0.01918499357998371,
                0.03174864873290062,
                -0.02475590631365776,
                -0.0005442489054985344,
                -0.019041700288653374,
                -0.01977028138935566,
                -0.004488096106797457,
                -0.04775405302643776,
                0.005147488787770271,
                0.04949941113591194,
                0.09727563709020615,
                0.016283290460705757,
                0.020838161930441856,
                -0.03642686456441879,
                -0.021460071206092834,
                0.005492898635566235,
                0.007613785099238157,
                0.029302731156349182,
                -0.0038791983388364315,
                -0.020748062059283257,
                0.061884261667728424,
                0.023755494505167007,
                -0.04178202152252197,
                0.015094672329723835,
                -0.043910447508096695,
                0.005006967578083277,
                -0.007956739515066147,
                0.02681121416389942,
                -0.002894879085943103,
                0.007644306402653456,
                -0.006551538128405809,
                1.7992140044498228e-07,
                -0.02177535742521286,
                0.026186350733041763,
                -0.02440754882991314,
                0.026726888492703438,
                -0.016173891723155975,
                -0.09266621619462967,
                -0.059687718749046326,
                0.01755639910697937,
                0.0061424169689416885,
                0.023047391325235367,
                0.021716678515076637,
                -0.04251738637685776,
                0.008621427230536938,
                0.04978763684630394,
                -0.03399914875626564,
                -0.07612698525190353,
                -0.0038150320760905743,
                -0.039441488683223724,
                -0.0250821802765131,
                0.01597076654434204,
                0.0019026316003873944,
                0.03672010451555252,
                -0.020297104492783546,
                -0.006254174280911684,
                -0.03452730551362038,
                0.017380762845277786,
                0.00010369328811066225,
                -0.053267404437065125,
                -0.006679560989141464,
                0.03181704878807068,
                0.034135714173316956,
                -0.016320910304784775,
                0.006800372153520584,
                0.05327998846769333,
                -0.01987663470208645,
                -0.005068434402346611,
                -0.0012292833998799324,
                0.059221796691417694,
                0.028808556497097015,
                0.0418899767100811,
                0.06733213365077972,
                0.0055990200489759445,
                -0.006897024344652891,
                -0.01175984088331461,
                0.009486798197031021,
                -0.0230750422924757,
                0.0008955001831054688,
                0.015748802572488785,
                0.005717829801142216,
                -0.032561708241701126,
                0.032938793301582336,
                -0.0400148369371891,
                0.04456142708659172,
                -0.006696826312690973,
                -0.02071264572441578,
                -0.0040253461338579655,
                0.023702815175056458,
                0.0568612776696682,
                0.006805063225328922,
                0.056173983961343765,
                -0.024415500462055206,
                -0.029910096898674965,
                0.03941500931978226,
                0.03333289921283722,
                0.09642170369625092,
                -0.008600221946835518,
                0.030775057151913643,
                1.5944887510908337e-34,
                0.02772904746234417,
                -0.0034923115745186806,
                -0.02009904757142067,
                -0.02187798172235489,
                -0.03620152547955513,
                -0.007978011853992939,
                0.018848104402422905,
                0.026423277333378792,
                -0.03009556233882904,
                -0.08571562170982361,
                -0.014949865639209747,
                -0.010960444808006287,
                0.05369270220398903,
                -0.04163197800517082,
                -0.028634000569581985,
                0.0008648904622532427,
                0.0280796866863966,
                0.005293046124279499,
                0.021388519555330276,
                -0.011341935023665428,
                0.01531511265784502,
                -0.012205736711621284,
                -0.012517569586634636,
                -0.02363518252968788,
                0.04523755982518196,
                -0.021888719871640205,
                -0.06225137412548065,
                0.020916923880577087,
                0.006933441385626793,
                -0.023336634039878845,
                0.017529362812638283,
                0.0019578731153160334,
                0.010490301065146923,
                0.019565343856811523,
                -0.06268901377916336,
                -0.023716293275356293,
                -0.008010312914848328,
                -0.03530009835958481,
                0.005243565421551466,
                -0.03771910443902016,
                -0.0008194274269044399,
                0.010211668908596039,
                0.04958222061395645,
                0.016459070146083832,
                0.02022477053105831,
                1.1474007806100417e-06,
                0.0016568020218983293,
                -0.036248743534088135,
                0.011362525634467602,
                -0.038219839334487915,
                0.018853962421417236,
                -0.00920338835567236,
                -0.027941709384322166,
                0.00352165917865932,
                -0.003998812288045883,
                -0.03777596354484558,
                -0.03723624721169472,
                0.024066248908638954,
                -0.06271073967218399,
                0.052341654896736145,
                -0.01967799663543701,
                -0.01951570436358452,
                -0.05007609352469444,
                -0.05662566050887108,
                0.020121488720178604,
                0.026554172858595848,
                -0.0002315355814062059,
                0.027791228145360947,
                0.08478400111198425,
                0.08726616948843002,
                0.005370693746954203,
                -0.02819889225065708,
                0.0015412296634167433,
                -0.003419402288272977,
                -0.02685898169875145,
                0.03816387057304382,
                -0.017507309094071388,
                0.042477790266275406,
                0.011509247124195099,
                -0.026576107367873192,
                -0.040555551648139954,
                0.05945482850074768,
                0.016709115356206894,
                0.034606434404850006,
                0.029548365622758865,
                -0.048692379146814346,
                -0.029819929972290993,
                -0.013779778964817524,
                0.021637361496686935,
                0.022201871499419212,
                -0.02821003831923008,
                -0.04556203633546829,
                -0.025257287546992302,
                -0.045902736485004425,
                -0.0007234352524392307,
                -0.03905031085014343,
                -0.07027329504489899,
                0.010797519236803055,
                -0.03197940066456795,
                -0.020286280661821365,
                -0.03201884403824806,
                0.04162558168172836,
                -0.025128286331892014,
                0.06843435764312744,
                0.0658193901181221,
                -0.00973700825124979,
                0.018117021769285202,
                -0.0628078281879425,
                0.015916381031274796,
                0.00488153425976634,
                -0.054201770573854446,
                -0.06310783326625824,
                -0.000881136511452496,
                0.008615080267190933,
                0.011867961846292019,
                0.06472349911928177,
                -0.003115363186225295,
                -0.012639652006328106,
                0.03332719951868057,
                -0.05496923625469208,
                -0.029435042291879654,
                -0.03889332339167595,
                -0.021538471803069115,
                -0.014674125239253044,
                0.09880871325731277,
                -0.006346034351736307,
                0.031624067574739456,
                -0.0328606553375721,
                0.03240172192454338,
                0.005131097976118326,
                0.03016652725636959,
                0.0287576075643301,
                -0.00015271904703695327,
                -0.020711472257971764,
                -0.004733731970191002,
                -0.05658799782395363,
                -0.07321587949991226,
                -0.03610145300626755,
                0.026301944628357887,
                -0.002938202815130353,
                0.017253713682293892,
                -0.007816907949745655,
                -0.009628149680793285,
                0.0328313484787941,
                0.016195975244045258,
                0.020077642053365707,
                -0.0023598801344633102,
                0.02355242520570755,
                0.05701335147023201,
                -0.14757347106933594,
                -0.02334575541317463,
                0.0037137269973754883,
                0.01718520186841488,
                -0.06945712864398956,
                -0.00576536962762475,
                0.04564877972006798,
                0.012590245343744755,
                -0.10275108367204666,
                0.033864766359329224,
                -0.018883688375353813,
                0.04716317355632782,
                -0.022668275982141495,
                -0.011348887346684933,
                -0.020106904208660126,
                0.06447385996580124,
                0.06834354996681213,
                0.0004956677439622581,
                0.07110639661550522,
                -0.013421395793557167,
                -0.018173424527049065,
                0.007858536206185818,
                0.00022402047761715949,
                0.01337821502238512,
                0.00841501820832491,
                -0.016824211925268173,
                0.0021082141902297735,
                0.01474715769290924,
                -0.05098049342632294,
                0.07291723787784576,
                -0.0481288768351078,
                0.016937460750341415,
                -0.13129743933677673,
                0.07414554804563522,
                -0.02852047048509121,
                0.00824145320802927,
                0.041573140770196915,
                -0.033954232931137085,
                0.016218014061450958,
                0.00370985665358603,
                -0.06499441713094711,
                0.0044854008592665195,
                -0.026153603568673134,
                -0.0459047369658947,
                0.06711142510175705,
                0.0851895809173584,
                0.04938074201345444,
                -0.030760781839489937,
                -0.017201140522956848,
                -0.06301307678222656,
                -0.04266327619552612,
                -0.023076701909303665,
                -0.029268212616443634,
                -0.02251441404223442,
                0.01677594520151615,
                0.013987043872475624,
                -0.0006018373533152044,
                -0.008566093631088734,
                0.04276612028479576,
                -0.029601028189063072,
                -0.023721931502223015,
                0.0067380378022789955,
                -0.00921539030969143,
                -0.028179172426462173,
                -0.01906079612672329,
                0.014964152127504349,
                -0.04392094910144806,
                -0.008720179088413715,
                0.002895516809076071,
                -0.014014656655490398,
                0.02617982216179371,
                -0.06038391962647438,
                0.033507607877254486,
                -0.003998213447630405,
                -0.010247532278299332,
                -0.006529496517032385,
                0.03748446702957153,
                0.11499349027872086,
                0.007009738124907017,
                -0.020604973658919334,
                -0.033140506595373154,
                0.024708395823836327,
                -0.001568308100104332,
                0.04514681175351143,
                -0.02989167347550392,
                0.0011182388989254832,
                0.010128234513103962,
                0.015832697972655296,
                -0.020354529842734337,
                0.015743697062134743,
                0.021458309143781662,
                0.016906101256608963,
                -0.0029083320405334234,
                0.020711779594421387,
                0.04701365530490875,
                0.0004911089781671762,
                -0.005321918986737728,
                0.005555113311856985,
                0.045048899948596954,
                0.017816787585616112,
                0.008215618319809437,
                0.033253755420446396,
                -0.00617415364831686,
                -0.09648101031780243,
                0.015164993703365326,
                -0.06813729554414749,
                0.055314112454652786,
                0.01255850587040186,
                -0.039581477642059326,
                -0.03686250001192093,
                0.047121163457632065,
                -0.014732079580426216,
                -0.03429578244686127,
                -0.027500608935952187,
                0.04575193673372269,
                0.025628577917814255,
                0.03128022328019142,
                0.0028898015152662992,
                -0.08418373018503189,
                0.014868884347379208,
                -0.022175395861268044,
                0.05205366015434265,
                0.014063156209886074,
                0.015857623890042305,
                0.06247477978467941,
                -0.040836431086063385,
                -0.0393928661942482,
                -0.00554264523088932,
                0.0422593392431736,
                -0.005832473747432232,
                0.0023580773267894983,
                0.038544394075870514,
                -0.008616805076599121,
                0.019007820636034012,
                0.03402256593108177,
                -0.006198898423463106,
                -0.018176589161157608,
                -0.0683288499712944,
                -0.04708193242549896,
                0.028973635286092758,
                -0.022980542853474617,
                0.01803426258265972,
                0.06619947403669357,
                0.00049458077410236,
                -0.0314825177192688,
                -0.026472046971321106,
                0.012421303428709507,
                -0.048167455941438675,
                -0.029521578922867775,
                -0.07169435173273087,
                0.010520793497562408,
                -0.03255850821733475,
                -0.0440855398774147,
                0.027439096942543983,
                0.01947762444615364,
                0.03332574665546417,
                -0.01153387501835823,
                -0.0440460704267025,
                -0.005425042472779751,
                -0.019543392583727837,
                -0.038276202976703644,
                -0.06121651083230972,
                0.04734724387526512,
                -0.025984134525060654,
                0.008832374587655067,
                -0.01300784945487976,
                0.0407957099378109,
                0.04435386508703232,
                0.056142400950193405,
                0.007087613921612501,
                0.023150566965341568,
                -0.0313834473490715,
                0.06866906583309174,
                -0.020200293511152267,
                0.0073816110379993916,
                0.04612799361348152,
                0.011111733503639698,
                0.027986925095319748,
                -0.047686491161584854,
                -0.006484543904662132,
                -0.015023400075733662,
                0.038677770644426346,
                0.015332872048020363,
                0.065787173807621,
                -0.022644510492682457,
                0.04578012600541115,
                0.024126525968313217,
                0.005235724151134491,
                -0.06102690473198891,
                0.02682489901781082,
                0.005114893428981304,
                0.01836218684911728,
                0.033537838608026505,
                -0.05172858387231827,
                -0.008303530514240265,
                -0.003204277716577053,
                -0.07656200975179672,
                0.031922001391649246,
                -0.011630099266767502,
                0.0025141602382063866,
                0.005973624065518379,
                -0.0104594174772501,
                0.0017469738377258182,
                0.03921739384531975,
                -0.04998952895402908,
                0.04724011942744255,
                0.009954864159226418,
                0.0023585816379636526,
                0.009375486522912979,
                0.050532273948192596,
                0.03847451135516167,
                -0.006070310715585947,
                0.008625061251223087,
                0.026635106652975082,
                0.008357290178537369,
                0.03142549470067024,
                0.06288320571184158,
                -0.0758020207285881,
                -0.03503841161727905,
                0.00168023316655308,
                -0.031085355207324028,
                -0.024030577391386032,
                -0.01673230342566967,
                -0.013899410143494606,
                -0.02581796608865261,
                -0.004103736020624638,
                -0.010554113425314426,
                -0.010737583972513676,
                0.04751778021454811,
                -0.0016020063776522875,
                0.02522376738488674,
                0.019421877339482307,
                -0.053708307445049286,
                -0.010199443437159061,
                0.04928185045719147,
                -0.011113576591014862,
                0.02021440677344799,
                0.03804817795753479,
                -0.030716782435774803,
                0.007502380758523941,
                -0.08527201414108276,
                0.006733886897563934,
                0.024903221055865288,
                -0.04738776758313179,
                0.04061463102698326,
                -0.06726152449846268,
                -0.02636810764670372,
                0.023143740370869637,
                -0.04016013443470001,
                -0.019232695922255516,
                0.03379927948117256,
                0.007418598979711533,
                -0.015970949083566666,
                0.004201898351311684,
                -0.05121476948261261,
                -0.018901783972978592,
                0.004663438070565462,
                -0.026172015815973282,
                -0.004637294914573431,
                0.10714592039585114,
                0.01776944100856781,
                -0.03463699296116829,
                0.010001290589571,
                0.011464733630418777,
                0.01835673116147518,
                -0.0271526500582695,
                -0.04440401494503021,
                -0.03610712289810181,
                -0.01808113045990467,
                0.051536086946725845,
                -0.02748105861246586,
                -0.013255673460662365,
                0.0029831344727426767,
                0.01366737112402916,
                -0.04851662367582321,
                -0.03262621909379959,
                0.05013691633939743,
                -0.0013401920441538095,
                0.01354049053043127,
                0.003112523350864649,
                -0.019314736127853394,
                0.03749550133943558,
                0.07923611253499985,
                -0.00905571598559618,
                0.07139330357313156,
                -0.02144523523747921,
                0.042950812727212906,
                0.0010755714029073715,
                -0.020029790699481964,
                -0.011276873759925365,
                0.02280724048614502,
                0.011433474719524384,
                0.008540917187929153,
                -0.0036600250750780106,
                0.026340680196881294,
                0.01313714124262333,
                0.01599607616662979,
                0.07264062762260437,
                0.07623689621686935,
                -0.03846006467938423,
                -0.013108679093420506,
                -0.0038997912779450417,
                -0.015742463991045952,
                0.04696878045797348,
                -0.010842293500900269,
                0.03281133249402046,
                -0.007294656243175268,
                0.029060814529657364,
                -0.028899217024445534,
                -0.020628463476896286,
                -0.11814598739147186,
                0.02813282422721386,
                -0.014081845059990883,
                0.025495927780866623,
                -0.006048561539500952,
                -0.0013940006028860807,
                0.0012366384034976363,
                -0.009912519715726376,
                -0.12236644327640533,
                -0.027194568887352943,
                -0.002706240164116025,
                -0.000609245034866035,
                0.03291408717632294,
                0.01931430585682392,
                -0.04274273291230202,
                0.0277677271515131,
                -0.01146000251173973,
                -0.07225403934717178,
                -0.022221775725483894,
                -0.01963883265852928,
                -0.03809615969657898,
                -0.0326949879527092,
                0.032650530338287354,
                -0.005890011787414551,
                0.024431807920336723,
                -0.00608169985935092,
                0.004871223121881485,
                -0.014672993682324886,
                -0.06320899724960327,
                -0.05463823676109314,
                0.06774803251028061,
                0.016953662037849426,
                0.02907121554017067,
                0.001545581966638565,
                0.07852771133184433,
                -0.026022840291261673,
                -0.010375562123954296,
                0.0601482167840004,
                -0.08040360361337662,
                -0.013345078565180302,
                0.009854113683104515,
                0.018415434285998344,
                0.04542335122823715,
                0.0345224104821682,
                0.004700351506471634,
                -0.05884600058197975,
                0.013426929712295532,
                -0.053716614842414856,
                -0.046987708657979965,
                0.060143839567899704,
                -0.0010898205218836665,
                -0.022047866135835648,
                -0.029580743983387947,
                0.05864179506897926,
                -0.032284773886203766,
                -0.029111556708812714,
                0.020892105996608734,
                0.07427003234624863,
                -0.007084270007908344,
                -0.009283998981118202,
                0.024672646075487137,
                0.007224543951451778,
                0.021541757509112358,
                -0.01319935079663992,
                0.00675389776006341,
                -0.02383110113441944,
                0.033479366451501846,
                0.0018675373867154121,
                -0.009470474906265736,
                0.014353451319038868,
                -0.06988394260406494,
                -0.015968499705195427,
                -0.0057237474247813225,
                0.021833224222064018,
                0.044456325471401215,
                0.04018963500857353,
                0.07324935495853424,
                -0.026498183608055115,
                0.04925168305635452,
                -0.04809647798538208,
                -0.04352591559290886,
                -0.0025589847937226295,
                0.0058063180185854435,
                -0.03680478408932686,
                -0.05003907531499863,
                -0.039342835545539856,
                -0.03346311300992966,
                0.06462118029594421,
                -0.006003109738230705,
                -0.003009623382240534,
                -0.02164747193455696,
                -0.030302388593554497,
                -0.0008303661015816033,
                0.052455753087997437,
                0.0007477460894733667,
                0.06590788066387177,
                -0.010097447782754898,
                -0.03288550674915314,
                0.011260154657065868,
                -0.009129409678280354,
                0.01590784080326557,
                0.07494544982910156,
                -0.0054926457814872265,
                -0.0026678182184696198,
                -0.028409887105226517,
                -0.020059259608387947,
                0.004471242893487215,
                -4.62897002055347e-33,
                -0.04301246628165245,
                0.024184538051486015,
                0.02186838909983635,
                0.0088224271312356,
                0.026605874300003052,
                -0.04310533404350281,
                -0.026617884635925293,
                -0.04836426302790642,
                -0.03764791041612625,
                -0.06351793557405472,
                -0.04259619861841202,
                0.042321205139160156,
                0.023214144632220268,
                -0.02101900987327099,
                0.03257659450173378,
                -0.009356421418488026,
                0.0563151091337204,
                -0.0075056422501802444,
                0.004098000470548868,
                -0.05146155506372452,
                0.009852073155343533,
                -0.00011692787666106597,
                0.04723278060555458,
                0.0424380861222744,
                0.021435661241412163,
                -0.01764109544456005,
                -0.01830483041703701,
                0.05058879032731056,
                0.024045221507549286,
                0.007219481747597456,
                0.011886270716786385,
                -0.013418757356703281,
                0.001950286328792572,
                0.02156868204474449,
                0.0054086800664663315,
                0.009673768654465675,
                -0.03932967409491539,
                -0.0094052879139781,
                -0.021945880725979805,
                0.0001799484743969515,
                0.0680660754442215,
                0.025633318349719048,
                0.03666812926530838,
                -0.052410468459129333,
                -0.04300345852971077,
                0.045705780386924744,
                0.012831444852054119,
                -0.015814727172255516,
                -0.023257814347743988,
                -0.023604741320014,
                0.04627655819058418,
                -0.00510055385529995,
                0.020370475947856903,
                0.07504305243492126,
                0.07593036442995071,
                -0.05063159018754959,
                -0.010156535543501377,
                -0.007354996632784605,
                -0.010309524834156036,
                0.0315246619284153,
                0.0372576080262661,
                0.04430375248193741,
                -0.03324448689818382,
                0.026234518736600876,
                0.0544685497879982,
                -0.0264469925314188,
                -0.009684759192168713,
                0.014768042601644993,
                -0.0008291143458336592,
                0.027451016008853912,
                -0.03705361858010292,
                0.0403553768992424,
                -0.033791132271289825,
                0.011387891136109829,
                -0.014174643903970718,
                -0.015700042247772217,
                -0.0525255911052227,
                0.05164823308587074,
                -0.022453658282756805,
                -0.01905101165175438,
                -0.04706580936908722,
                0.05375896766781807,
                -0.02315070852637291,
                -0.00819790456444025,
                0.012337617576122284,
                0.028899678960442543,
                0.012979533523321152,
                0.012516127899289131,
                -0.016227126121520996,
                0.04558230936527252,
                -0.041198331862688065,
                0.02747441828250885,
                0.01765923760831356,
                -0.012565423734486103,
                -0.06744976341724396,
                -0.013378731906414032,
                0.026854800060391426,
                -0.06570196896791458,
                0.003338427282869816,
                0.01002149935811758,
                -0.0012313303304836154,
                -0.00969041045755148,
                0.021105147898197174,
                -0.007931732572615147,
                -0.059504907578229904,
                0.031027818098664284,
                -0.01074227411299944,
                -0.02379290573298931,
                -0.04281596094369888,
                0.030306875705718994,
                0.04897924140095711,
                -0.018956564366817474,
                -0.04996385797858238,
                -0.04252399504184723,
                -0.04008721187710762,
                -0.0013110765721648932,
                0.017056481912732124,
                0.01825759932398796,
                0.01981358230113983,
                -0.044206760823726654,
                -0.03966735303401947,
                -0.004565691575407982,
                -0.009778125211596489,
                -0.06701549142599106,
                0.01958206109702587,
                -0.029183434322476387,
                0.038197197020053864,
                -0.027999943122267723,
                0.05127761885523796,
                0.009466352872550488,
                0.003921044524759054,
                0.06234110891819,
                1.7578122424311005e-07,
                0.06684593111276627,
                0.005329957697540522,
                0.010781018063426018,
                0.06341820955276489,
                0.024968035519123077,
                -0.039248157292604446,
                0.02577712945640087,
                -0.042119234800338745,
                0.03282821923494339,
                0.0026416222099214792,
                0.057449907064437866,
                -0.040291231125593185,
                -0.008678882382810116,
                0.02180209942162037,
                0.015610326081514359,
                -0.033011190593242645,
                -0.00604231096804142,
                -0.02813856676220894,
                -0.05054660141468048,
                -0.005484743043780327,
                0.023089149966835976,
                0.04878790304064751,
                0.02172628790140152,
                0.05064379796385765,
                -0.004298766143620014,
                0.001974793616682291,
                -0.02098640240728855,
                0.006532677914947271,
                0.0008302698261104524,
                0.017811153084039688,
                0.07413123548030853,
                -0.009680232033133507,
                -0.01816004328429699,
                0.02484559454023838,
                -0.01771063357591629,
                -0.0034956205636262894,
                -0.034433700144290924,
                0.05996501445770264,
                -0.014875114895403385,
                0.038485921919345856,
                0.016402030363678932,
                -0.0889858528971672,
                -0.001183647196739912,
                0.04646097496151924,
                0.010951310396194458,
                0.0013717309338971972,
                0.041642576456069946,
                0.06994985044002533,
                0.017231298610568047,
                -0.0337756872177124,
                0.0346573069691658,
                0.04093853384256363,
                -0.030148308724164963,
                -0.018592339009046555,
                -0.023375341668725014,
                0.04930189251899719,
                0.018641740083694458,
                0.03716196492314339,
                0.002007091650739312,
                0.10124029219150543,
                0.006279440131038427,
                0.006786212790757418,
                0.03861302509903908,
                -0.03451235219836235,
                0.017702089622616768,
                0.022339321672916412,
                -0.014973215758800507,
                4.836080760525708e-35,
                -0.011056018993258476,
                0.03309183195233345,
                -0.022750653326511383,
                -0.05663183704018593,
                -0.06531515717506409,
                -0.0025673198979347944,
                0.002110694069415331,
                -0.03936999291181564,
                0.02311578392982483,
                -0.029193533584475517,
                -0.013360992074012756,
                -0.07342326641082764,
                -0.06652088463306427,
                0.007240561302751303,
                -0.007179121021181345,
                -0.04520310461521149,
                0.015644770115613937,
                -0.015889782458543777,
                0.04459971562027931,
                -0.0640045553445816,
                0.0128792067989707,
                0.010586916469037533,
                -0.0016711323987692595,
                0.02183769829571247,
                0.015045705251395702,
                0.0054788850247859955,
                -0.10865237563848495,
                0.060066789388656616,
                0.026424868032336235,
                -0.0006652677548117936,
                0.017372634261846542,
                -0.013529662042856216,
                -0.002081924118101597,
                -0.027039511129260063,
                -0.006498534698039293,
                0.0005341976648196578,
                -0.03697337582707405,
                -0.03877821937203407,
                -0.023664157837629318,
                -0.022003643214702606,
                0.0069764163345098495,
                -0.022612063214182854,
                0.07952746748924255,
                0.04507744684815407,
                -0.03855348750948906,
                1.4265928029999486e-06,
                0.018407901749014854,
                -0.0296429842710495,
                -0.0009144325158558786,
                0.012807977385818958,
                0.05770045518875122,
                0.024103881791234016,
                0.028719862923026085,
                0.03399297595024109,
                0.008508860133588314,
                0.028762629255652428,
                -0.029385820031166077,
                -0.02850026823580265,
                0.05819796398282051,
                -0.001147623872384429,
                0.02986491098999977,
                -0.011059047654271126,
                0.0019709018524736166,
                0.04759066924452782,
                -0.0864705890417099,
                0.030955199152231216,
                -0.035555534064769745,
                -0.02860218472778797,
                0.02782195433974266,
                0.048347413539886475,
                -0.011268997564911842,
                0.05441497638821602,
                -0.03956332802772522,
                -0.07149319350719452,
                -0.04180558770895004,
                0.030484024435281754,
                -0.004307218361645937,
                0.006838186644017696,
                -0.062498386949300766,
                -0.005331284832209349,
                0.06964258849620819,
                -0.003693297738209367,
                0.07812517881393433,
                0.011721218936145306,
                -0.013904890976846218,
                -0.001293754088692367,
                -0.009458841755986214,
                -0.07302842289209366,
                -0.01226623635739088,
                0.010403781197965145,
                -0.03395529463887215,
                0.006662121973931789,
                -0.0119674326851964,
                -0.013184581883251667,
                0.003378947963938117,
                0.06071438267827034,
                -0.05625918507575989,
                0.006168917287141085,
                -0.01457097940146923,
                0.0058188363909721375,
                -0.02016405761241913,
                -0.03887046501040459,
                -0.022946229204535484,
                0.011836624704301357,
                -0.005025115329772234,
                0.057675790041685104,
                0.012649026699364185,
                -0.0202651210129261,
                -0.01334445271641016,
                -0.02000129409134388,
                -0.07931005954742432,
                0.012747296132147312,
                -0.03050115890800953,
                0.04612089693546295,
                0.05051087960600853,
                -0.0032518261577934027,
                0.014637577347457409,
                -0.011928578838706017,
                -0.02388596348464489,
                0.042733334004879,
                0.06118336692452431,
                0.018176378682255745,
                0.016978338360786438,
                -0.06108148396015167,
                0.03247777000069618,
                -0.020703010261058807,
                -0.06585326790809631,
                0.0037408974021673203,
                -0.02224220708012581,
                -0.025260189548134804,
                0.05598980560898781,
                0.03150756657123566,
                0.018831893801689148,
                -0.04723682254552841,
                -0.02068338915705681,
                -0.003318115836009383,
                0.04990268498659134,
                0.01663300395011902,
                -0.011426005512475967,
                -0.024229533970355988,
                -0.03943755105137825,
                0.02748754620552063,
                0.06293465197086334,
                0.03102796897292137,
                0.04837297275662422,
                -0.025693051517009735,
                -0.00860217772424221,
                -0.02064010314643383,
                0.022839419543743134,
                -0.048969417810440063,
                -0.014155736193060875,
                0.026422927156090736,
                -0.006488478742539883,
                -0.046053435653448105,
                -0.003850129898637533,
                -0.01597444899380207,
                0.0334332212805748,
                -0.035002678632736206,
                -0.0020136444363743067,
                0.04186242073774338,
                0.04011568799614906,
                -0.039590246975421906,
                -0.01286595780402422,
                0.013929614797234535,
                -0.0036634430289268494,
                0.057821594178676605,
                -0.0037063665222376585,
                -0.0731053501367569,
                -0.015387075021862984,
                -0.023912925273180008,
                0.008456340990960598,
                0.01115644071251154,
                -0.014549538493156433,
                0.047798313200473785,
                0.029677454382181168,
                -0.02033812738955021,
                -0.034641921520233154,
                0.0013341611484065652,
                -0.035921357572078705,
                0.010740319266915321,
                -0.04165910184383392,
                -0.05884949117898941,
                0.01137747336179018,
                -0.053743842989206314,
                0.06875819712877274,
                0.0335414819419384,
                -0.04672345146536827,
                0.05310573801398277,
                -0.00507413549348712,
                -0.048072732985019684,
                -0.025131721049547195,
                -0.023872481659054756,
                -0.04341483861207962,
                -0.06772319972515106,
                0.03877148777246475,
                0.005029972176998854,
                0.020241381600499153,
                -0.015455572865903378,
                -0.06479740887880325,
                -0.020984744653105736,
                0.008190209046006203,
                -0.04771469533443451,
                -0.0021266592666506767,
                -0.023010125383734703,
                -0.05490114539861679,
                0.028166700154542923,
                -0.021175306290388107,
                -0.10470140725374222,
                0.0678727999329567,
                -0.049759577959775925,
                -0.04159897565841675,
                0.0013232113560661674,
                0.03758621588349342,
                -0.022403638809919357,
                0.07458513975143433,
                0.003382141934707761,
                -0.02883569896221161,
                0.06003405898809433,
                -0.023261452093720436,
                -0.009608143009245396,
                0.02869250997900963,
                0.08387904614210129,
                0.04046701639890671,
                0.015723468735814095,
                -0.0778067409992218,
                -0.03649038448929787,
                -0.05683199316263199,
                -0.026969078928232193,
                -0.008567647077143192,
                0.024442538619041443,
                0.0174572691321373,
                0.009140212088823318,
                -0.06718340516090393,
                0.007501540239900351,
                0.030414912849664688,
                -0.001388892182148993,
                -0.062091048806905746,
                -0.030144672840833664,
                -0.011569869704544544,
                0.027989378198981285,
                0.021064497530460358,
                -0.029339008033275604,
                0.04706449806690216,
                0.004638065118342638,
                0.048120733350515366,
                -0.03284827247262001,
                0.04886264726519585,
                -0.0419042631983757,
                0.008932279422879219,
                -0.020181607455015182,
                -0.018515752628445625,
                -0.02197546511888504,
                -0.026287231594324112,
                0.01720433495938778,
                0.03997949883341789,
                0.006571433041244745,
                0.012816011905670166,
                0.0008892057812772691,
                -0.057935457676649094,
                0.03684699535369873,
                -0.014370756223797798,
                -0.02191733941435814,
                0.010495561175048351,
                -0.005277459975332022,
                0.01642092689871788,
                0.0068825786001980305,
                0.04496925324201584,
                0.007074280641973019,
                0.002521900460124016,
                -0.07068824768066406,
                -0.004115517716854811,
                0.0395166277885437,
                -0.029309185221791267,
                -0.02476104535162449,
                -0.03949235752224922,
                -0.06861501932144165,
                0.046766892075538635,
                -0.002125313738361001,
                -0.052621837705373764,
                0.00012386027083266526,
                0.01869678497314453,
                -0.037885040044784546,
                0.0067447335459291935,
                0.019882148131728172,
                -0.03666927292943001,
                -0.011740807443857193,
                -0.02340116538107395,
                -0.02591867931187153,
                -0.04521054029464722,
                0.036865219473838806,
                -0.003401410300284624,
                -0.0655779242515564,
                0.09389331936836243,
                -0.03155875951051712,
                -0.0016926827374845743,
                0.0173530001193285,
                0.004237139131873846,
                0.02113988623023033,
                -0.09087460488080978,
                -0.03599119186401367,
                0.09751734137535095,
                0.02512221224606037,
                0.015375962480902672,
                0.06730644404888153,
                0.06462237983942032,
                -0.015350510366261005,
                -0.020849300548434258,
                0.06944292038679123,
                -0.012729509733617306,
                -0.02113298326730728,
                0.03364956006407738,
                0.018319619819521904,
                -0.0340660884976387,
                -0.024484118446707726,
                0.023759542033076286,
                -0.015058628283441067,
                0.07263750582933426,
                0.04352764040231705,
                -0.047270625829696655,
                -0.004029615316540003,
                0.020876672118902206,
                0.10252070426940918,
                0.03350913152098656,
                0.005036441143602133,
                -0.05571451038122177,
                -0.06388447433710098,
                0.02118181809782982,
                0.043463364243507385,
                -0.04218016564846039,
                0.008464895188808441,
                -0.04319128766655922,
                0.009246576577425003,
                -0.007926278747618198,
                -0.0165348332375288,
                -0.03096199221909046,
                0.03509879857301712,
                0.06625721603631973,
                -0.00040375927346758544,
                -0.008697085082530975,
                0.02208331786096096,
                -0.01861022785305977,
                -0.025893893092870712,
                -0.08507630974054337,
                -0.006972587667405605,
                -0.011619248427450657,
                -0.06863550841808319,
                0.06364934146404266,
                -0.012244820594787598,
                -0.031506750732660294,
                -0.01173895038664341,
                0.010348958894610405,
                -0.06864546984434128,
                -0.006930693984031677,
                -0.008465226739645004,
                -0.011798368766903877,
                0.021033048629760742,
                0.03260389342904091,
                0.023461632430553436,
                0.030461786314845085,
                -0.07089932262897491,
                0.05594690144062042,
                0.03693508729338646,
                0.031968940049409866,
                -0.017848942428827286,
                0.028942042961716652,
                0.006910726893693209,
                0.02439568191766739,
                0.0028037361335009336,
                -0.01155439019203186,
                -0.060054220259189606,
                -0.06386864930391312,
                -0.032616738229990005,
                -0.031168019399046898,
                -0.005353886634111404,
                4.526974953478202e-05,
                -0.0455099381506443,
                -0.02775326557457447,
                -0.0437292717397213,
                -0.02780195325613022,
                -0.04599101468920708,
                -0.013455729000270367,
                0.012430489994585514,
                0.07214783877134323,
                0.09291471540927887,
                0.0536513589322567,
                0.005593218840658665,
                -0.002058981452137232,
                -0.03738516941666603,
                0.0036563773173838854,
                -0.06313905119895935,
                -0.020344320684671402,
                0.027993926778435707,
                0.002074885182082653,
                0.015957532450556755,
                0.03145580366253853,
                0.03721999749541283,
                0.04449203237891197,
                -0.0209746565669775,
                0.007647656835615635,
                -0.025729196146130562,
                0.0222468301653862,
                -0.018926776945590973,
                0.047852739691734314,
                -0.036917299032211304,
                -0.022347725927829742,
                0.025673305615782738,
                -0.0034739896655082703,
                -0.026071688160300255,
                -0.007793002296239138,
                0.01392669603228569,
                -0.013745303265750408,
                0.015158725902438164,
                -0.06849393248558044,
                0.028081081807613373,
                -0.04197048768401146,
                0.020017096772789955,
                -0.04118112847208977,
                0.03996161371469498,
                0.006494737230241299,
                -0.025221770629286766,
                -0.07997871935367584,
                0.002127184998244047,
                0.02870837040245533,
                0.040307193994522095,
                -0.02447221800684929,
                0.04711483418941498,
                0.026811838150024414,
                0.008712547831237316,
                0.025130609050393105,
                0.012257457710802555,
                0.06464844197034836,
                -0.04892042651772499,
                0.02756849117577076,
                -0.01619311422109604,
                -0.03131037950515747,
                -0.02537364698946476,
                -0.008980979211628437,
                0.023273294791579247,
                0.009471394121646881,
                -0.011935316026210785,
                -0.02041098102927208,
                -0.04274006187915802,
                -0.056741178035736084,
                -0.01860097423195839,
                0.09977759420871735,
                -0.026747427880764008,
                0.03124813176691532,
                -0.047554146498441696,
                -0.02616358920931816,
                0.00626510800793767,
                -0.028032492846250534,
                0.04470031335949898,
                0.03502078354358673,
                -0.004013897851109505,
                0.02541976422071457,
                -0.0024076872505247593,
                -0.005114695057272911,
                0.041627608239650726,
                -0.06386838108301163,
                -0.02356131188571453,
                0.060179442167282104,
                -0.002889626193791628,
                -0.03868508338928223,
                -0.034406330436468124,
                -0.027787473052740097,
                0.07726796716451645,
                0.005979950074106455,
                0.0643831342458725,
                0.004097124096006155,
                0.01768648438155651,
                -0.004187123849987984,
                0.007985913194715977,
                0.016859978437423706,
                -0.0659014955163002,
                0.005594116635620594,
                0.01266279723495245,
                0.013206316158175468,
                0.0529850497841835,
                -0.007166012655943632,
                0.017415158450603485,
                0.0027856752276420593,
                -0.011171615682542324,
                -0.02226657047867775,
                -0.0029002425726503134,
                -0.03794063255190849,
                -0.0790751725435257,
                0.03557925298810005,
                0.00025649802410043776,
                0.06877155601978302,
                0.01357291080057621,
                -0.04960610345005989,
                5.597412382485345e-05,
                0.0011891676113009453,
                -0.019033759832382202,
                -0.015753764659166336,
                -0.044243380427360535,
                -0.01991306059062481,
                -0.007924890145659447,
                -0.004452622029930353,
                0.027817679569125175,
                0.03967105969786644,
                -0.012379111722111702,
                -0.005500427447259426,
                0.012837187387049198,
                -0.021917257457971573,
                -0.004198845475912094,
                -0.0677613690495491,
                -0.023417523130774498,
                -0.03389222174882889,
                0.003907223232090473,
                -0.04957722872495651,
                -0.005753978621214628,
                -4.1414896259084344e-05,
                -0.022466208785772324,
                -0.0029812909197062254,
                -0.05972973257303238,
                -0.01701204665005207,
                0.005503067746758461,
                0.047157660126686096,
                -0.06473173201084137,
                0.02488335780799389,
                0.028822287917137146,
                0.014106319285929203,
                0.03472471237182617,
                -0.014800028875470161,
                0.01555594988167286,
                0.06718473881483078,
                0.039291732013225555,
                -0.03473612666130066,
                -0.05557238310575485,
                -0.01928495243191719,
                -0.0040386877954006195,
                -0.027712391689419746,
                0.0359463207423687,
                0.028570134192705154,
                0.00576750235632062,
                -0.03499884158372879,
                0.00977378524839878,
                0.00277629098854959,
                0.003907278645783663,
                0.04377611353993416,
                -0.058387015014886856,
                -0.011398312635719776,
                -0.010100452229380608,
                0.01307459082454443,
                -0.029687587171792984,
                0.02170402929186821,
                0.05555658042430878,
                -0.009537720121443272,
                0.0010623738635331392,
                -0.026634199544787407,
                -0.019748307764530182,
                -0.0024636671878397465,
                0.04772401228547096,
                -0.05555981770157814,
                -0.04160434380173683,
                0.03230609744787216,
                0.03958369418978691,
                0.030222726985812187,
                -0.06041885167360306,
                0.024909766390919685,
                0.004020442720502615,
                0.08063773810863495,
                0.008168443106114864,
                0.026574527844786644,
                -0.034239400178194046,
                -4.335260634715029e-33,
                -0.007243727799504995,
                -0.035204675048589706,
                -0.0005613099201582372,
                0.0839301198720932,
                0.06519924104213715,
                -0.011707566678524017,
                0.040992360562086105,
                0.01657862402498722,
                -0.0007598568336106837,
                0.01672215387225151,
                -0.032407063990831375,
                -0.045497361570596695,
                0.04494704306125641,
                -0.025207290425896645,
                -0.04935025796294212,
                0.04170509800314903,
                0.01489943452179432,
                -0.037618499249219894,
                0.01243794709444046,
                0.005058536771684885,
                -0.03461373969912529,
                -0.05125778540968895,
                -0.004797909874469042,
                -0.0030778320506215096,
                -0.016032621264457703,
                0.0329507514834404,
                -0.018946079537272453,
                -0.027790145948529243,
                0.0140110207721591,
                -0.02209043875336647,
                0.013652593828737736,
                -0.0014877449721097946,
                -0.008802260272204876,
                -0.012970900163054466,
                0.003692246275022626,
                0.08324391394853592,
                0.0009508051443845034,
                -0.015897231176495552,
                0.028386591002345085,
                0.016707822680473328,
                -0.02799951657652855,
                -0.019328484311699867,
                0.01701091043651104,
                -0.023127418011426926,
                0.040189437568187714,
                0.01991129480302334,
                0.018422860652208328,
                0.0406443253159523,
                -0.06024399772286415,
                0.026586713269352913,
                0.001082553993910551,
                0.019219620153307915,
                -0.012728017754852772,
                0.014079350978136063,
                -0.03286716341972351,
                -0.020612407475709915,
                -0.027695124968886375,
                -0.05814123526215553,
                -0.058375440537929535,
                0.03662595897912979,
                0.0889003723859787,
                0.006852784659713507,
                0.05634664371609688,
                -0.02899332344532013,
                0.015598159283399582,
                0.011244320310652256,
                -0.037786178290843964,
                0.06795090436935425,
                0.04202340543270111,
                0.008798720315098763,
                -0.004785945639014244,
                0.11320960521697998,
                0.06084686517715454,
                -0.029955891892313957,
                -0.00801841076463461,
                0.015490350313484669,
                0.021231232210993767,
                0.043947964906692505,
                -0.02166040427982807,
                -0.012127893976867199,
                0.011053485795855522,
                -0.010993029922246933,
                0.05768771842122078,
                -0.00982112530618906,
                0.02062276005744934,
                -0.03136768192052841,
                0.0018404190195724368,
                -0.026980409398674965,
                0.00031066490919329226,
                0.01936277560889721,
                0.04129211977124214,
                0.021224534139037132,
                0.03005540370941162,
                0.02767484448850155,
                -0.03148641437292099,
                0.019529104232788086,
                0.0009422607254236937,
                0.0004174279747530818,
                0.012345362454652786,
                -0.04571865499019623,
                -0.03960259631276131,
                -0.02445882558822632,
                -0.010724165476858616,
                0.03805963322520256,
                0.0010060938075184822,
                0.00807028729468584,
                -0.052851952612400055,
                -0.021468961611390114,
                -0.03449207916855812,
                -0.020280899479985237,
                0.0255547147244215,
                0.01575741171836853,
                0.0016717321705073118,
                -0.04073063284158707,
                0.005304803140461445,
                0.015239556320011616,
                -0.03744637221097946,
                -0.04257702827453613,
                -0.011651482433080673,
                -0.023858988657593727,
                -0.013971608132123947,
                -0.05493219196796417,
                -0.007692474406212568,
                -0.019960589706897736,
                0.03544331341981888,
                -0.004654929973185062,
                0.009636850096285343,
                0.06725338846445084,
                0.005324249621480703,
                0.04997880384325981,
                0.012111054733395576,
                -0.00356700224801898,
                1.8244720934035286e-07,
                0.03165670856833458,
                0.022057414054870605,
                0.029630955308675766,
                0.09028542786836624,
                0.03698323294520378,
                -0.04908241704106331,
                0.025260353460907936,
                0.0589410737156868,
                -0.005659387446939945,
                0.058683354407548904,
                0.020530235022306442,
                -0.0030160308815538883,
                0.001754652475938201,
                0.07879722863435745,
                -0.04577852040529251,
                -0.012952255085110664,
                -0.011480509303510189,
                0.02662961371243,
                -0.025576358661055565,
                -0.014872238039970398,
                0.04047952964901924,
                0.031411901116371155,
                0.008690694347023964,
                0.021217208355665207,
                -0.001958162523806095,
                -0.048775773495435715,
                0.06115690991282463,
                0.001059656497091055,
                0.01160398405045271,
                -0.056533083319664,
                -0.01695878989994526,
                0.0022202518302947283,
                0.0028728290926665068,
                0.028718596324324608,
                -0.029305221512913704,
                -0.016985777765512466,
                0.024991145357489586,
                0.04385517165064812,
                -0.04653710126876831,
                0.013525941409170628,
                0.03255142644047737,
                0.00034410477383062243,
                -0.021789230406284332,
                -0.013494937680661678,
                0.020216908305883408,
                -0.023961318656802177,
                0.04250700771808624,
                0.07917559146881104,
                -0.02721823938190937,
                -0.029624760150909424,
                0.027056828141212463,
                0.04840787872672081,
                0.03483310714364052,
                0.04046664014458656,
                0.016672559082508087,
                -0.01615173928439617,
                -0.09069186449050903,
                0.061668794602155685,
                -0.006159300450235605,
                -0.019979551434516907,
                0.01812455616891384,
                0.026684628799557686,
                9.008361666928977e-05,
                -0.053664691746234894,
                -0.03911811485886574,
                0.08110995590686798,
                0.004358389414846897,
                9.140241717726565e-35,
                -0.0006616385071538389,
                -0.029469197615981102,
                -0.016993004828691483,
                -0.004789791535586119,
                0.032189592719078064,
                0.02876053936779499,
                0.07437082380056381,
                0.035475775599479675,
                -0.00023703866463620216,
                0.005389816127717495,
                -0.010722501203417778
            ]
        },
        "Values": {
            "name": [
                "Teamwork ",
                "Communication",
                "Always open to learn new skills",
                "Resilient",
                "Problem solving"
            ],
            "rep": [
                0.06297138333320618,
                -0.020030226558446884,
                -0.01851087063550949,
                -0.01355514582246542,
                -0.0081430459395051,
                0.015342488884925842,
                -0.00289041711948812,
                -0.012953532859683037,
                -0.07682368159294128,
                0.03963539004325867,
                0.09105234593153,
                -0.010542556643486023,
                0.01994759775698185,
                9.340199903817847e-05,
                0.014310413971543312,
                -0.06361132115125656,
                -0.0213488657027483,
                -0.01084519550204277,
                -0.01234785933047533,
                -0.018096040934324265,
                0.016596073284745216,
                -0.0009509806404821575,
                0.020331501960754395,
                -0.01907469518482685,
                -0.007727308198809624,
                -0.001515217823907733,
                0.011137845925986767,
                -0.015745410695672035,
                0.0031539457850158215,
                0.009570619091391563,
                0.033249251544475555,
                -0.011063605546951294,
                -0.0345420315861702,
                -0.03493824973702431,
                1.7798971612137393e-06,
                -0.01617990992963314,
                -0.013270092196762562,
                -0.013051571324467659,
                -0.04737669974565506,
                -0.0006717214128002524,
                -0.025954782962799072,
                0.014087371528148651,
                0.0024699531495571136,
                0.012408548034727573,
                -0.018384279683232307,
                0.04357967525720596,
                0.04205286502838135,
                -0.02389903925359249,
                0.008362963795661926,
                -0.02042340114712715,
                0.013418659567832947,
                0.024848870933055878,
                -0.03989572823047638,
                -0.021699588745832443,
                -0.05298088863492012,
                -0.06360477954149246,
                0.010305345058441162,
                -0.00302508263848722,
                0.02660723589360714,
                -0.01115645281970501,
                0.02305903099477291,
                -0.036303967237472534,
                -0.017194148153066635,
                0.0017112118657678366,
                0.00916990265250206,
                0.028958143666386604,
                -0.006314282305538654,
                -0.026135539636015892,
                0.023788269609212875,
                -0.031529247760772705,
                -0.05683957412838936,
                0.0586889311671257,
                0.011101938784122467,
                0.04647001996636391,
                -0.028656432405114174,
                0.024476859718561172,
                -0.0155355678871274,
                0.002862231805920601,
                -0.004065073095262051,
                -0.025115838274359703,
                -0.0592896044254303,
                0.07618720829486847,
                0.004567478783428669,
                0.037021931260824203,
                0.05157000944018364,
                0.04116832837462425,
                -0.03368355333805084,
                0.014563703909516335,
                -0.007947874255478382,
                0.08154463768005371,
                -0.036198895424604416,
                -0.058073557913303375,
                -0.002967443782836199,
                0.0379207506775856,
                -0.0003316003130748868,
                -0.02395743504166603,
                0.047088682651519775,
                -0.034118056297302246,
                0.00019303627777844667,
                -0.026050778105854988,
                -0.03063163161277771,
                0.020076116546988487,
                0.06313139200210571,
                0.030274055898189545,
                -0.0013407990336418152,
                -0.03308170661330223,
                0.00038506320561282337,
                -0.02612236887216568,
                -0.013507059775292873,
                0.016545874997973442,
                -0.06862055510282516,
                0.0250538382679224,
                0.013826743699610233,
                0.02504534088075161,
                0.013198206201195717,
                -0.00898381695151329,
                0.013888045214116573,
                0.03566257283091545,
                0.0036194175481796265,
                0.02899121679365635,
                0.02174203470349312,
                0.033229317516088486,
                -0.013426052406430244,
                -0.01915440335869789,
                0.03667103126645088,
                0.0011879130033776164,
                -0.05156896635890007,
                -0.014935481362044811,
                -0.015188196673989296,
                0.024016929790377617,
                -0.029199765995144844,
                -0.008160333149135113,
                -0.007618365343660116,
                -0.0011331260902807117,
                0.015071714296936989,
                -0.007815597578883171,
                0.02046455256640911,
                0.052374158054590225,
                -0.001081508002243936,
                -0.023820433765649796,
                0.037750180810689926,
                -0.05113408342003822,
                -0.055241648107767105,
                -0.04340974986553192,
                0.006894682999700308,
                -0.019132912158966064,
                0.026869503781199455,
                0.0009733161423355341,
                0.002682944992557168,
                -0.04391414672136307,
                0.009451952762901783,
                0.009556841105222702,
                -0.025735413655638695,
                0.021754104644060135,
                0.06349022686481476,
                0.05943182110786438,
                -0.04374711960554123,
                -0.015244457870721817,
                0.007172335404902697,
                0.022682340815663338,
                0.026212647557258606,
                0.027126070111989975,
                0.02787509746849537,
                -0.017222387716174126,
                -0.03252493590116501,
                0.022180180996656418,
                0.05246797204017639,
                0.004503427539020777,
                0.004589589312672615,
                0.01009135227650404,
                -0.02794230729341507,
                -0.009793498553335667,
                -0.03598269075155258,
                0.03997126594185829,
                0.009018140845000744,
                -0.013576311059296131,
                0.0531267449259758,
                -0.010824387893080711,
                -0.045657649636268616,
                0.016049077734351158,
                -0.042207542806863785,
                -0.09788147360086441,
                -0.005282562226057053,
                0.030634956434369087,
                0.0911991074681282,
                0.03617919236421585,
                0.026997549459338188,
                0.07317988574504852,
                -0.06281581521034241,
                0.02162131667137146,
                -0.01426839642226696,
                0.00827897246927023,
                -0.0231942068785429,
                -0.01713011972606182,
                0.011120382696390152,
                -0.0019526334945112467,
                -0.0042784251272678375,
                -0.029859958216547966,
                -0.002049531089141965,
                -0.024714091792702675,
                0.019105181097984314,
                -0.03168540447950363,
                0.027602961286902428,
                0.030626701191067696,
                -0.028452545404434204,
                0.057803764939308167,
                -0.030067510902881622,
                -0.001172611489892006,
                0.018563827499747276,
                -0.021469103172421455,
                -0.01933840848505497,
                0.02620343118906021,
                -0.010435955598950386,
                0.030545320361852646,
                -0.013951357454061508,
                0.06578528881072998,
                -0.004512975458055735,
                0.023150019347667694,
                -0.06210441514849663,
                -0.013672965578734875,
                -0.035554736852645874,
                0.10513146966695786,
                -0.005403775721788406,
                0.015235996805131435,
                -0.011046680621802807,
                -0.015504547394812107,
                -0.04097675904631615,
                -0.0031822281889617443,
                0.02463957853615284,
                6.40039870631881e-05,
                -0.016770409420132637,
                0.007337599527090788,
                0.011007280088961124,
                0.009400242939591408,
                0.007126213051378727,
                0.037584275007247925,
                0.10329683870077133,
                0.005038606934249401,
                -0.007148104254156351,
                -0.07374077290296555,
                -0.032068077474832535,
                0.027800627052783966,
                0.028603093698620796,
                0.04130866751074791,
                0.034576255828142166,
                -0.016317671164870262,
                -0.014414221979677677,
                0.009620442986488342,
                -0.05512022599577904,
                0.002472243970260024,
                -0.06654636561870575,
                0.0026561871636658907,
                0.030905338004231453,
                -0.01881570555269718,
                -0.003957780543714762,
                -0.04775841534137726,
                -0.0015170031692832708,
                0.059204958379268646,
                -0.033476296812295914,
                0.04733232036232948,
                0.0703466609120369,
                0.018394768238067627,
                0.005961939692497253,
                -0.006907863076776266,
                0.0767509862780571,
                -0.03140188381075859,
                -0.016706222668290138,
                -0.06440400332212448,
                0.0036898429971188307,
                -0.026816075667738914,
                0.018902361392974854,
                -0.02756560407578945,
                0.003220499726012349,
                -0.009305300191044807,
                0.0035570894833654165,
                0.03546351566910744,
                -0.0011158682173117995,
                0.023799465969204903,
                -0.05539754405617714,
                -0.01020327489823103,
                0.03396446257829666,
                -0.05654710531234741,
                0.0044419062323868275,
                0.036499280482530594,
                0.01919539086520672,
                -0.0042818458750844,
                0.06759760528802872,
                -0.04361709952354431,
                -0.07257236540317535,
                -0.02830132655799389,
                -0.03987385705113411,
                -0.04812726750969887,
                0.04012399539351463,
                -0.037791717797517776,
                0.01316086109727621,
                0.01393275149166584,
                -0.02075197547674179,
                -0.0033891184721142054,
                -0.009835680015385151,
                -0.03351657837629318,
                0.009179085493087769,
                -0.01496406365185976,
                0.04329540953040123,
                0.002131384564563632,
                -0.026081660762429237,
                -0.041982658207416534,
                0.0025728351902216673,
                -0.03893110156059265,
                -0.02971639856696129,
                0.047545116394758224,
                0.019576862454414368,
                0.01674787886440754,
                0.017752882093191147,
                0.0053843907080590725,
                -0.004704164806753397,
                -0.012864630669355392,
                -0.03280482068657875,
                -0.09642548114061356,
                -0.024504996836185455,
                0.021875279024243355,
                -0.017571747303009033,
                0.037943899631500244,
                -0.012159526348114014,
                -0.010472841560840607,
                -0.010557218454778194,
                -0.0386449433863163,
                -0.013035117648541927,
                0.023990163579583168,
                0.09033310413360596,
                -0.025432433933019638,
                0.0005482584238052368,
                -0.046328309923410416,
                -0.018860172480344772,
                0.013286841101944447,
                -0.005099867936223745,
                0.04332202300429344,
                -0.02105230651795864,
                -0.08333152532577515,
                -0.03305012360215187,
                -0.007715372834354639,
                -0.0009039905271492898,
                0.02024284563958645,
                -0.02637132816016674,
                0.03161296993494034,
                -0.06449821591377258,
                0.008740589953958988,
                0.010908630676567554,
                0.005915893707424402,
                -0.029526276513934135,
                0.019498230889439583,
                0.07401979714632034,
                -0.03889836370944977,
                -0.027940141037106514,
                -0.02414744719862938,
                0.014507189393043518,
                0.002926306566223502,
                -0.04131206497550011,
                -0.0002484141441527754,
                0.01243541482836008,
                -0.11988945305347443,
                -0.02741401456296444,
                0.061648763716220856,
                0.12538467347621918,
                0.002705095801502466,
                0.007350313011556864,
                -0.015599802136421204,
                0.040194712579250336,
                0.04161231219768524,
                -0.06749708950519562,
                -0.007221169304102659,
                0.037984590977430344,
                0.04205646738409996,
                -0.04486502334475517,
                0.042188894003629684,
                0.0007470796699635684,
                0.045052140951156616,
                0.025495236739516258,
                -0.022806616500020027,
                -0.0021613396238535643,
                -0.03284895792603493,
                0.03910167142748833,
                0.005419054068624973,
                -0.0043020229786634445,
                0.016391055658459663,
                -0.018840402364730835,
                0.024968110024929047,
                -0.01971573755145073,
                -0.015733715146780014,
                0.006142922677099705,
                0.007660830393433571,
                0.04321318864822388,
                -0.028260303661227226,
                -0.04539937525987625,
                0.02094855159521103,
                0.03120153583586216,
                -0.03786049410700798,
                0.020583098754286766,
                0.018062828108668327,
                0.020447196438908577,
                0.024623561650514603,
                0.03932354971766472,
                0.05140425264835358,
                0.017823589965701103,
                0.03508676961064339,
                -0.1025950238108635,
                0.009912560693919659,
                0.07151670008897781,
                0.036134883761405945,
                0.022745097056031227,
                -0.0731261670589447,
                -0.002854143036529422,
                0.03063310869038105,
                0.04846082627773285,
                0.016994841396808624,
                0.07511763274669647,
                -0.05720028281211853,
                -0.004024891648441553,
                0.02624264359474182,
                -0.024716198444366455,
                -0.0352543443441391,
                0.004652857780456543,
                -0.012279428541660309,
                -0.03968528285622597,
                -0.024559546262025833,
                0.006311753764748573,
                -0.010698828846216202,
                0.041433658450841904,
                0.042660847306251526,
                0.0015124353813007474,
                -0.036041438579559326,
                -0.019328923895955086,
                0.03379042074084282,
                -0.016214273869991302,
                0.03256166726350784,
                -0.0306767039000988,
                -0.09799874573945999,
                -0.05594131350517273,
                0.026124877855181694,
                0.048051174730062485,
                0.007828796282410622,
                -0.03411595895886421,
                0.07888607680797577,
                -0.013248682022094727,
                -0.009196123108267784,
                0.005459252279251814,
                -0.10226037353277206,
                0.05206620320677757,
                -0.008977077901363373,
                -0.04128170758485794,
                0.017878437414765358,
                0.03726978972554207,
                -0.02290213294327259,
                -0.0413040891289711,
                -0.08380405604839325,
                0.00258586835116148,
                0.027490047737956047,
                -0.02535823918879032,
                -0.004178723786026239,
                0.028877148404717445,
                0.04377531260251999,
                0.04297036677598953,
                0.036934539675712585,
                -0.06302425265312195,
                0.03590594232082367,
                -0.034767188131809235,
                -0.042884454131126404,
                -0.0018384897848591208,
                -0.05047190189361572,
                0.0374758280813694,
                -0.033562272787094116,
                -0.03841419517993927,
                0.005249700974673033,
                0.001960208872333169,
                0.03827369585633278,
                0.04041573032736778,
                0.05714983865618706,
                -0.04552339017391205,
                -0.06972348690032959,
                -0.01462964154779911,
                -0.027301892638206482,
                -0.03253792226314545,
                0.021725105121731758,
                -0.01152777299284935,
                0.01193762756884098,
                0.006665598135441542,
                -0.012371590360999107,
                0.04657374322414398,
                -0.011601122096180916,
                0.007252522278577089,
                -0.032443754374980927,
                -0.027837581932544708,
                -0.0016823462210595608,
                0.017389174550771713,
                0.045055802911520004,
                0.013917909003794193,
                -0.003918072674423456,
                -0.0324154756963253,
                0.06939880549907684,
                0.03136226534843445,
                0.035907961428165436,
                -0.00027725001564249396,
                0.03315938264131546,
                0.017945628613233566,
                -0.015977568924427032,
                0.00580255500972271,
                0.0008367536356672645,
                0.001978115411475301,
                0.008855738677084446,
                -0.005266263149678707,
                -0.006640581414103508,
                0.03945579752326012,
                -0.015998175367712975,
                -0.05668594688177109,
                -0.08097890764474869,
                0.028766360133886337,
                0.017875347286462784,
                -0.011866318993270397,
                -0.013812423683702946,
                -0.021412905305624008,
                -0.019878756254911423,
                0.01576460711658001,
                -0.01734108291566372,
                -0.014141474850475788,
                -0.03820447623729706,
                0.09392786771059036,
                -0.05297766253352165,
                0.00857451930642128,
                -0.005229842849075794,
                -0.018155822530388832,
                0.013497824780642986,
                -0.073200523853302,
                0.011767003685235977,
                -0.024935856461524963,
                0.02958228997886181,
                -0.038923006504774094,
                0.07995864748954773,
                0.040595490485429764,
                0.07464562356472015,
                -0.010990714654326439,
                0.03030582331120968,
                -0.02796434611082077,
                0.07658019661903381,
                0.0036576094571501017,
                0.017104122787714005,
                0.0547112300992012,
                0.003721782937645912,
                -0.016776418313384056,
                0.020280389115214348,
                0.014258204028010368,
                0.007038504350930452,
                -0.024930492043495178,
                -0.025340555235743523,
                0.012995543889701366,
                -0.006621304899454117,
                0.034029342234134674,
                -6.658257142446679e-33,
                0.009736639447510242,
                -0.10228925198316574,
                0.005459193140268326,
                0.016655035316944122,
                -0.070747509598732,
                0.011791379190981388,
                0.02801264449954033,
                0.016763754189014435,
                -0.022320158779621124,
                -0.010693486779928207,
                -0.036130551248788834,
                0.007388413418084383,
                0.028734974563121796,
                -0.019877616316080093,
                0.049280039966106415,
                -0.006473969202488661,
                -0.003155846381559968,
                0.009668529033660889,
                0.0035773098934441805,
                -0.02533268742263317,
                -0.007635725662112236,
                0.0433528833091259,
                -0.01959475874900818,
                0.03690043464303017,
                0.0051634288392961025,
                -0.013903236016631126,
                -0.04856400564312935,
                0.013434428721666336,
                -0.012599753215909004,
                -0.018298281356692314,
                0.0450870580971241,
                -0.0728093683719635,
                -0.008558939211070538,
                -0.08625273406505585,
                0.006424303166568279,
                0.08432704210281372,
                0.011773946695029736,
                -0.012798511423170567,
                -0.02633892372250557,
                -0.01050441712141037,
                -0.053769856691360474,
                0.0030061749275773764,
                0.0069018942303955555,
                -0.00434002885594964,
                0.02054382488131523,
                -0.07773146778345108,
                1.0566177479631733e-06,
                0.0060662939213216305,
                -0.0007683397852815688,
                0.06105506047606468,
                0.015231376513838768,
                0.03440345823764801,
                0.008243108168244362,
                -0.0036457697860896587,
                0.04379427805542946,
                -0.06665921211242676,
                -0.02124221809208393,
                -0.018015773966908455,
                -0.07322943955659866,
                0.015409507788717747,
                -0.053782906383275986,
                0.04467401280999184,
                -0.01774664595723152,
                -0.00312166684307158,
                -0.008884329348802567,
                0.015252448618412018,
                -0.03256220743060112,
                -0.0003873383393511176,
                -0.018221944570541382,
                -0.02830142341554165,
                -0.06556708365678787,
                0.08289098739624023,
                0.08211833238601685,
                -0.0015126803191378713,
                -0.06837037950754166,
                -0.0475233756005764,
                -0.03473502770066261,
                0.029491975903511047,
                0.04087354987859726,
                -0.013408664613962173,
                -0.05617094784975052,
                0.04991144686937332,
                -0.018917495384812355,
                0.04226672276854515,
                0.049990370869636536,
                -0.01408837828785181,
                0.010678084567189217,
                -0.0020664772018790245,
                -0.050452955067157745,
                0.030050164088606834,
                -0.03912001848220825,
                0.039281316101551056,
                0.036170732229948044,
                0.04557414352893829,
                0.03632458671927452,
                0.024129901081323624,
                0.034668464213609695,
                0.003881108481436968,
                -0.006854180712252855,
                -0.06583387404680252,
                -0.06711961328983307,
                0.03966986760497093,
                -0.11437886953353882,
                0.017191670835018158,
                0.016402510926127434,
                -0.004306620918214321,
                0.005812039598822594,
                0.03375832736492157,
                -0.005069784354418516,
                -0.005271255504339933,
                -0.005256183445453644,
                0.007010695058852434,
                0.010919475927948952,
                -0.08314572274684906,
                -0.019881203770637512,
                -0.00820478517562151,
                0.011049947701394558,
                -0.05438787862658501,
                0.01055813767015934,
                -0.08225469291210175,
                0.017197631299495697,
                0.06558447331190109,
                -0.06462635099887848,
                -0.011064319871366024,
                -0.015142777003347874,
                0.016452563926577568,
                0.0033000782132148743,
                -0.002213215222582221,
                0.008443024009466171,
                0.006982580292969942,
                -0.04027076065540314,
                -0.08039932698011398,
                2.40127860706707e-07,
                -0.03779483586549759,
                0.029568150639533997,
                -0.006997796706855297,
                0.0005654068663716316,
                -0.006096625234931707,
                0.03579186275601387,
                -0.05577486380934715,
                0.04967151954770088,
                0.025772687047719955,
                -0.003521906677633524,
                0.07008292526006699,
                0.03125642240047455,
                -0.014891483820974827,
                0.0054951924830675125,
                0.03953378275036812,
                -0.04046189785003662,
                0.014985055662691593,
                -0.0299602709710598,
                0.057715170085430145,
                -0.07706625014543533,
                -0.01260012574493885,
                -0.014921383932232857,
                0.03963411971926689,
                0.010950140655040741,
                -0.017150195315480232,
                -0.006667299196124077,
                -0.017472408711910248,
                -0.025191301479935646,
                0.01826169155538082,
                0.01534043624997139,
                0.010136714205145836,
                -0.07717917114496231,
                -0.04460590332746506,
                0.007253971882164478,
                0.009397834539413452,
                -0.050046104937791824,
                0.03870610147714615,
                -0.004694999195635319,
                -0.023926591500639915,
                0.07079073041677475,
                -0.007876288145780563,
                -0.008752085268497467,
                -0.01322090532630682,
                0.04278379678726196,
                -0.0009034420363605022,
                -0.023675156757235527,
                -0.006392008159309626,
                0.043390918523073196,
                -0.052931733429431915,
                -0.01708471029996872,
                0.004165406804531813,
                0.06652325391769409,
                0.02469564415514469,
                0.07808101922273636,
                -0.0015964196063578129,
                0.023859353736042976,
                -0.0032899787183851004,
                0.039852071553468704,
                0.0165200587362051,
                -0.017948200926184654,
                -0.02303279936313629,
                -0.03320681303739548,
                0.04634419456124306,
                0.015774019062519073,
                -0.05442292243242264,
                0.0719001442193985,
                -0.07101555913686752,
                7.444094631014312e-35,
                -0.01676349528133869,
                -0.03465087711811066,
                -0.027406731620430946,
                0.0017532510682940483,
                0.048210617154836655,
                -0.016943544149398804,
                -0.013589381240308285,
                -0.009029293432831764,
                0.019016413018107414,
                0.08736943453550339,
                -0.03717505931854248,
                0.0928579717874527,
                -0.012957746163010597,
                -0.022264588624238968,
                0.024618564173579216,
                -0.025196580216288567,
                0.0017743100179359317,
                -0.015223382972180843,
                0.004269381985068321,
                -0.04579605162143707,
                0.018556728959083557,
                0.04908941313624382,
                -0.034265972673892975,
                0.04418038949370384,
                0.0009179369080811739,
                -0.021188994869589806,
                -0.015627318993210793,
                0.028583187609910965,
                0.010677377693355083,
                -0.005096024833619595,
                -0.02437625452876091,
                0.03600189834833145,
                -0.002264378359541297,
                0.014472625218331814,
                -0.020462092012166977,
                -0.0016479311743751168,
                -0.03821047395467758,
                -0.012712743133306503,
                -0.02006693184375763,
                -0.006738104857504368,
                0.02285190485417843,
                -0.01350979134440422,
                0.021413857117295265,
                0.034434862434864044,
                -0.03258047625422478,
                2.016128746618051e-06,
                -0.006386845372617245,
                0.003811442758888006,
                -0.033880315721035004,
                -0.056534379720687866,
                0.02073611319065094,
                0.06748830527067184,
                -0.0410010926425457,
                0.015534067526459694,
                0.033943332731723785,
                -0.03445965796709061,
                0.022678731009364128,
                0.06907913088798523,
                0.032619770616292953,
                0.029649918898940086,
                0.009881940670311451,
                0.012434135191142559,
                0.00963050127029419,
                -0.009655787609517574,
                -0.02900395728647709,
                -0.03137960657477379,
                -0.02581099420785904,
                0.0218504685908556,
                0.00823674350976944,
                0.007865441963076591,
                0.032211028039455414,
                -0.03705775365233421,
                -0.011494877748191357,
                -0.011874111369252205,
                -0.013504633679986,
                0.026842011138796806,
                0.011183980852365494,
                0.03601669892668724,
                -0.03718102350831032,
                0.011764047667384148,
                -0.005114949308335781,
                0.05728240683674812,
                0.018497349694371223,
                -0.0038683931343257427,
                0.034595269709825516,
                -0.0643129050731659,
                -0.04822622612118721,
                -0.04079015552997589,
                -0.02557610347867012,
                0.013323872350156307,
                0.010724634863436222,
                -0.020267663523554802,
                -6.493400633189594e-06,
                -0.008601824752986431,
                0.015588940121233463,
                0.08007127046585083,
                0.028175804764032364,
                -0.034040432423353195,
                -0.0261535681784153,
                -0.05736937001347542,
                0.032419949769973755,
                0.049736905843019485,
                -0.07390227168798447,
                0.0007221942651085556,
                0.008638426661491394,
                0.009522774256765842,
                -0.004295550752431154,
                0.016103526577353477,
                -0.02244112640619278,
                -0.008453291840851307,
                -0.05584290996193886,
                -0.018649805337190628,
                0.023090338334441185,
                0.025401415303349495,
                -0.013128047809004784,
                0.07582342624664307,
                -0.04642327129840851,
                0.012099345214664936,
                0.027301328256726265,
                0.0011110961204394698,
                0.07718026638031006,
                -0.06119377538561821,
                -0.0014476978685706854,
                -0.005980257410556078,
                0.03591109439730644,
                0.050577159970998764,
                -0.015973323956131935,
                -0.028902485966682434,
                -0.00020354700973257422,
                -0.015057174488902092,
                0.008416956290602684,
                -0.0814131572842598,
                -0.02639177441596985,
                -0.04980945587158203,
                0.01712324656546116,
                -0.015284194611012936,
                0.08362754434347153,
                -0.07699292153120041,
                0.016647513955831528,
                0.03828428313136101,
                -0.039751145988702774,
                -0.013856984674930573,
                -0.04820645600557327,
                0.011405473574995995,
                0.01909472793340683,
                0.01431644894182682,
                0.07021012902259827,
                -0.04528898745775223,
                0.022081980481743813,
                -0.011805016547441483,
                0.003003425430506468,
                -0.004302241373807192,
                -0.07825715839862823,
                -0.0681711956858635,
                -0.02460538223385811,
                0.001987603260204196,
                0.028687236830592155,
                -0.00618414580821991,
                -0.03528236225247383,
                0.01771632581949234,
                -0.06461361050605774,
                -0.009876694530248642,
                0.05624053627252579,
                0.024857670068740845,
                -0.006871641613543034,
                0.11472378671169281,
                0.0776064544916153,
                0.050263725221157074,
                -0.02068544737994671,
                0.0122700035572052,
                -0.021020939573645592,
                0.021757062524557114,
                0.014874559827148914,
                0.019843043759465218,
                -0.022337039932608604,
                0.025565255433321,
                -0.01121263112872839,
                0.029621949419379234,
                -0.026754289865493774,
                -0.03453953564167023,
                0.004183060489594936,
                -0.03085550107061863,
                0.010708109475672245,
                -0.04096852242946625,
                0.011662205681204796,
                0.03859686851501465,
                0.008955452591180801,
                0.0004231311322655529,
                -0.0005535422242246568,
                -0.07011693716049194,
                0.06268370896577835,
                -0.03238078951835632,
                -0.0616099052131176,
                0.03649481013417244,
                0.025610225275158882,
                0.04523724690079689,
                0.029887909069657326,
                0.07999773323535919,
                0.025401759892702103,
                -0.0700802356004715,
                -0.008607985451817513,
                0.004790093749761581,
                -0.02900294028222561,
                -0.0004127963911741972,
                -0.0004602415137924254,
                0.009444029070436954,
                -0.003699114779010415,
                0.04187314584851265,
                0.016482381150126457,
                0.015607978217303753,
                -0.02279721200466156,
                0.009276673197746277,
                0.0023719549644738436,
                0.06299374997615814,
                0.04307346045970917,
                -0.052137792110443115,
                0.07428500801324844,
                0.08556824177503586,
                -0.0371352843940258,
                0.04313826560974121,
                0.0377763994038105,
                -0.019020233303308487,
                0.003265341045334935,
                0.01971662975847721,
                0.008203297853469849,
                0.02511957287788391,
                0.03283018246293068,
                -0.007735142018646002,
                0.03420315682888031,
                -0.03557707369327545,
                0.06452089548110962,
                -0.023793570697307587,
                0.08142950385808945,
                0.01682331971824169,
                -0.0014380431966856122,
                0.05307817459106445,
                -0.0390893779695034,
                -0.030414218083024025,
                -0.01959390938282013,
                0.04578601196408272,
                -0.023602645844221115,
                -0.021795492619276047,
                0.021121088415384293,
                0.012906580232083797,
                0.03454338759183884,
                0.0005857325159013271,
                0.024128764867782593,
                0.04823283106088638,
                0.03325488418340683,
                0.004798328038305044,
                -0.05360095947980881,
                0.0009490718366578221,
                0.000686179322656244,
                0.010927807539701462,
                0.02688802033662796,
                0.07975524663925171,
                -0.01843407191336155,
                0.051433928310871124,
                -0.004307183902710676,
                -0.012278902344405651,
                -0.015257689170539379,
                -0.04043252021074295,
                0.017462778836488724,
                -0.015649206936359406,
                -0.02018674649298191,
                0.00962802954018116,
                -0.07485109567642212,
                -0.03435873985290527,
                0.032144516706466675,
                -0.049715183675289154,
                0.014344464987516403,
                0.0405791699886322,
                -0.02105299010872841,
                0.013945011422038078,
                0.018104471266269684,
                0.04326226934790611,
                -0.06202249601483345,
                -0.0010568491416051984,
                -0.042382340878248215,
                -0.04540848731994629,
                -0.007928100414574146,
                0.03193527087569237,
                0.009718671441078186,
                0.0050812275148928165,
                0.0034160022623836994,
                -0.010903212241828442,
                -0.006230361759662628,
                0.037514545023441315,
                0.013729607686400414,
                -0.002355942502617836,
                0.03287531062960625,
                0.05759135261178017,
                0.0016768582863733172,
                -0.06380052864551544,
                -0.04109006002545357,
                0.04413866251707077,
                0.02641807124018669,
                0.001213083858601749,
                -0.0584738589823246,
                -0.03956127166748047,
                -0.03321632370352745,
                -0.0623692162334919,
                0.0046255155466496944,
                -0.004630688577890396,
                -0.0700157880783081,
                0.04457905888557434,
                -0.006420996505767107,
                -0.02274526096880436,
                -0.0008306598756462336,
                0.03906375914812088,
                0.05079415813088417,
                0.004730940330773592,
                0.0021986663341522217,
                -0.01748347468674183,
                -0.030659660696983337,
                -0.04105399176478386,
                -0.031641870737075806,
                -0.029445916414260864,
                -0.01483019907027483,
                -0.02368791028857231,
                0.043146710842847824,
                0.03481541946530342,
                0.016358694061636925,
                0.03852031007409096,
                0.02244579792022705,
                -0.002786672208458185,
                -0.04407598078250885,
                -0.055565427988767624,
                -0.05050547048449516,
                0.016468264162540436,
                0.05157146602869034,
                0.0132460817694664,
                0.006148516666144133,
                -0.005973752588033676,
                0.00244010123424232,
                0.03770405426621437,
                -0.013847517780959606,
                0.0229404978454113,
                0.06217121705412865,
                0.06175409257411957,
                -0.07687091082334518,
                -0.014041253365576267,
                0.025896647945046425,
                -0.05127788335084915,
                -0.018879005685448647,
                -0.12478965520858765,
                0.09480373561382294,
                -0.0031561171635985374,
                -0.08183953166007996,
                -0.0330868735909462,
                0.0031226598657667637,
                0.013015685603022575,
                -0.028608182445168495,
                0.0022258677054196596,
                0.013150743208825588,
                -0.025858942419290543,
                -0.020726189017295837,
                0.012629124335944653,
                0.010176293551921844,
                -0.06585779041051865,
                0.0082877641543746,
                0.03352385014295578,
                -0.011970783583819866,
                0.0018384904833510518,
                -0.027202516794204712,
                -0.02821183390915394,
                -0.04259016737341881,
                -0.057260170578956604,
                0.028982389718294144,
                -0.0009312749025411904,
                -0.07746756821870804,
                -0.04059285670518875,
                0.03524961322546005,
                -0.011652204208076,
                0.0309323500841856,
                0.04334559291601181,
                0.016417246311903,
                0.04983465000987053,
                0.040366824716329575,
                -0.0003399296838324517,
                0.01671999879181385,
                0.08365805447101593,
                0.07666388154029846,
                -0.06147468462586403,
                0.047892216593027115,
                -0.006505653262138367,
                -0.009280817583203316,
                -0.012345407158136368,
                -0.045028895139694214,
                -0.022169621661305428,
                -0.07718084752559662,
                0.012312695384025574,
                -0.06639014184474945,
                0.030548682436347008,
                -0.02578839287161827,
                -0.00432594446465373,
                -0.024534419178962708,
                0.01793750189244747,
                0.00490148551762104,
                0.0029759989120066166,
                0.00872559193521738,
                0.030913332477211952,
                -0.023414013907313347,
                -0.06669310480356216,
                0.05708244815468788,
                -0.0014905068092048168,
                0.036408089101314545,
                0.04010246694087982,
                0.0004065270768478513,
                0.0541822649538517,
                -0.0037668170407414436,
                0.013835521414875984,
                0.017862239852547646,
                0.009623858146369457,
                0.02000478096306324,
                -0.05099359154701233,
                0.008601118810474873,
                0.045867178589105606,
                0.04257785901427269,
                -0.009072558023035526,
                -0.053807709366083145,
                -0.026173314079642296,
                0.010027007199823856,
                0.017389966174960136,
                -0.01113799586892128,
                0.023946449160575867,
                -0.042026519775390625,
                -0.014139884151518345,
                -0.033967841416597366,
                -0.005575693678110838,
                0.016848847270011902,
                -0.01458179485052824,
                -0.04415799304842949,
                -0.037756744772195816,
                -0.01797409914433956,
                -0.033185720443725586,
                0.026093503460288048,
                0.064695343375206,
                0.06979963183403015,
                0.011380898766219616,
                -0.03001079149544239,
                -0.03680436313152313,
                -0.0035760952159762383,
                0.002895100275054574,
                0.037734758108854294,
                0.08208148181438446,
                -0.013079999946057796,
                -0.045070476830005646,
                0.0682680755853653,
                -0.05552021041512489,
                -0.03505157306790352,
                0.016428044065833092,
                0.006061329040676355,
                0.02227817475795746,
                0.03241681680083275,
                0.03630198910832405,
                0.00782040972262621,
                0.009618316777050495,
                -0.01736663654446602,
                -0.06318455189466476,
                0.04153812304139137,
                0.01880752481520176,
                -0.013447519391775131,
                -0.007489487063139677,
                -0.14381027221679688,
                0.008166145533323288,
                -0.0016766199842095375,
                -0.02883520908653736,
                -0.036595869809389114,
                0.01059387344866991,
                0.004472647327929735,
                -0.006286296993494034,
                0.04236776381731033,
                -0.019755955785512924,
                0.009015357121825218,
                -0.04896865412592888,
                -0.06879818439483643,
                0.04646379500627518,
                -0.011607302352786064,
                0.023830216377973557,
                -0.03403957933187485,
                0.009414749220013618,
                0.009529709815979004,
                0.009888277389109135,
                0.05267547070980072,
                0.022132398560643196,
                0.0386357381939888,
                -0.028110357001423836,
                -0.020013373345136642,
                0.0034286263398826122,
                -0.0103214206174016,
                -0.014937615022063255,
                -0.02010066621005535,
                -0.0022149186115711927,
                -0.034006234258413315,
                -0.02085070125758648,
                -0.03475838899612427,
                0.0011993383523076773,
                0.025878356769680977,
                -0.005743368063122034,
                -0.04943322017788887,
                -0.026494136080145836,
                0.019165031611919403,
                0.02345040626823902,
                -0.06363342702388763,
                -0.004083502572029829,
                -0.005025381688028574,
                0.0041214278899133205,
                0.03225138038396835,
                0.0027241238858550787,
                0.032274890691041946,
                -0.014112181961536407,
                0.04091786965727806,
                0.0025346162728965282,
                -0.006506671197712421,
                -0.015141037292778492,
                0.01406581699848175,
                0.007808650843799114,
                0.06500876694917679,
                0.03899165615439415,
                -0.014614773914217949,
                0.03503331169486046,
                0.006017432082444429,
                0.013571320101618767,
                -0.09663690626621246,
                -0.040260523557662964,
                0.03552776202559471,
                -0.029607286676764488,
                0.02515401691198349,
                -0.053273558616638184,
                -0.019254226237535477,
                0.008211761713027954,
                -0.005912305787205696,
                0.06297805905342102,
                -0.00466910470277071,
                0.039949577301740646,
                0.030309898778796196,
                0.0017075221985578537,
                -0.03992515802383423,
                -0.004337017890065908,
                -0.047032177448272705,
                -0.10611017793416977,
                -0.051861561834812164,
                -0.05629349127411842,
                0.03821849450469017,
                -0.015744024887681007,
                0.10121986269950867,
                -0.00019152465392835438,
                0.0546625591814518,
                -0.024429013952612877,
                0.047750987112522125,
                -0.014446178451180458,
                -0.011207249946892262,
                0.044778741896152496,
                0.008013710379600525,
                0.016651714220643044,
                -0.04512954503297806,
                0.03558972477912903,
                0.024790145456790924,
                0.045046839863061905,
                0.009035732597112656,
                -0.010223977267742157,
                -0.019600778818130493,
                -0.011552833952009678,
                -0.03800218924880028,
                -0.0031661312095820904,
                -7.965023355529107e-33,
                -0.041074398905038834,
                -0.030155714601278305,
                -0.008088107220828533,
                0.05812201276421547,
                -0.06074916943907738,
                -0.001366914133541286,
                -0.012155316770076752,
                0.05464326590299606,
                -0.036570530384778976,
                -0.008855586871504784,
                0.0005676962318830192,
                0.02033144235610962,
                0.03089599870145321,
                -0.017726365476846695,
                0.06378400325775146,
                -0.0524008572101593,
                0.001792842405848205,
                0.016184572130441666,
                -0.043152887374162674,
                -0.044914767146110535,
                0.03829494118690491,
                0.027260545641183853,
                0.03173554688692093,
                -0.011071395128965378,
                0.050690069794654846,
                -0.03637006878852844,
                -0.022582361474633217,
                -0.016426051035523415,
                0.03569852560758591,
                -0.012821046635508537,
                -0.009851687587797642,
                -0.0057691666297614574,
                -0.011598777957260609,
                -0.026130011305212975,
                0.0072643025778234005,
                0.0779690071940422,
                -0.01487403828650713,
                -0.05319282412528992,
                0.01137354876846075,
                -0.039937760680913925,
                -0.06381546705961227,
                -0.01796303689479828,
                -0.002237570472061634,
                -0.009242262691259384,
                -0.018901171162724495,
                -0.06682582944631577,
                -0.001654168707318604,
                0.02622268535196781,
                0.0034990101121366024,
                0.07152120023965836,
                -0.015601029619574547,
                -0.018706176429986954,
                -0.01758260652422905,
                0.022228319197893143,
                0.04045191407203674,
                -0.0008655872079543769,
                -0.03671267628669739,
                -0.00666914414614439,
                -0.07169743627309799,
                0.03103988990187645,
                -0.02280496619641781,
                0.04779660329222679,
                0.008611071854829788,
                -0.024397507309913635,
                -0.009328281506896019,
                0.017539341002702713,
                -0.08665398508310318,
                -0.01246622670441866,
                0.01138188038021326,
                -0.02487817406654358,
                0.006546328775584698,
                0.0601440891623497,
                0.017030028626322746,
                0.025304242968559265,
                -0.09836951643228531,
                -0.08937239646911621,
                -0.009434168227016926,
                -0.03715352714061737,
                0.08608748763799667,
                0.008206023834645748,
                0.0012397859245538712,
                0.021219227463006973,
                -0.027100902050733566,
                -0.02499346062541008,
                -0.012768324464559555,
                -0.012486200779676437,
                -0.005799942649900913,
                -0.03416189178824425,
                -0.006702857557684183,
                -0.0040521021001040936,
                0.03045818582177162,
                -0.0020555618684738874,
                0.020880838856101036,
                0.011320715770125389,
                -0.03592601791024208,
                -0.054026201367378235,
                0.008029581978917122,
                0.05645386874675751,
                0.012227203696966171,
                -0.05671948194503784,
                -0.05366288125514984,
                0.007261922582983971,
                -0.033519260585308075,
                0.02143675833940506,
                -0.007072448264807463,
                -0.005641669500619173,
                -0.01218988373875618,
                -0.0055651296861469746,
                0.007605096325278282,
                -0.04626595973968506,
                0.018539179116487503,
                0.027175305411219597,
                0.018610529601573944,
                -0.04666488617658615,
                -0.06045479327440262,
                0.0024265337269753218,
                -0.030425775796175003,
                -0.026046905666589737,
                -0.031758397817611694,
                0.004264647141098976,
                0.018384218215942383,
                0.0553879551589489,
                -0.028903299942612648,
                0.005095885135233402,
                -0.036444056779146194,
                0.024385595694184303,
                -0.06435537338256836,
                0.02327856235206127,
                0.036524027585983276,
                -0.04382980987429619,
                0.003044131211936474,
                -0.043681759387254715,
                2.5348518306600454e-07,
                -0.03219226002693176,
                0.01564415544271469,
                -0.03164460137486458,
                0.08477245271205902,
                0.033884014934301376,
                0.008095731027424335,
                -0.011459129862487316,
                0.07803220301866531,
                0.01804281212389469,
                -0.03889824077486992,
                0.002608468057587743,
                0.020046252757310867,
                0.023227734491229057,
                0.00618753070011735,
                0.011831730604171753,
                -0.05080128461122513,
                -0.04337553307414055,
                -0.06145286187529564,
                0.022395947948098183,
                -0.06670766323804855,
                0.06907573342323303,
                0.022245975211262703,
                0.015227122232317924,
                -0.0036789700388908386,
                -0.007449154742062092,
                0.042430978268384933,
                0.024656178429722786,
                -0.03176233172416687,
                0.04609314352273941,
                -0.0005724136135540903,
                0.01599816419184208,
                -0.043350741267204285,
                -0.0003934547712560743,
                0.053800068795681,
                -0.013379155658185482,
                -0.06463509798049927,
                0.012179329991340637,
                0.03923548385500908,
                -0.012767654843628407,
                0.03297670558094978,
                -0.037476204335689545,
                0.04293159395456314,
                -0.017918197438120842,
                0.025039656087756157,
                -0.027204422280192375,
                -0.04747932031750679,
                -0.05283742770552635,
                0.041469912976026535,
                0.008618326857686043,
                -0.014816387556493282,
                -0.0058616092428565025,
                0.025067118927836418,
                0.020984329283237457,
                0.046414390206336975,
                -0.0036841256078332663,
                -0.002831423422321677,
                0.02747167833149433,
                0.029508914798498154,
                0.059798166155815125,
                0.01096518337726593,
                0.008224810473620892,
                -0.05722333490848541,
                -0.006789976265281439,
                -0.008617103099822998,
                -0.014643095433712006,
                0.04062991961836815,
                0.0015161997871473432,
                3.064032784182576e-35,
                0.008843122981488705,
                -0.02465561032295227,
                -0.020137805491685867,
                0.042014990001916885,
                0.04322340711951256,
                -0.013225954957306385,
                0.04602031409740448,
                -0.01673946902155876,
                0.030375352129340172,
                0.019852513447403908,
                0.00581256952136755,
                0.017372986301779747,
                0.01634523831307888,
                0.006061980966478586,
                0.005229674279689789,
                0.04401146247982979,
                -0.00658477982506156,
                -0.041793763637542725,
                -0.024912260472774506,
                -0.01373630203306675,
                0.029392601922154427,
                -0.0287834070622921,
                -0.027187298983335495,
                -0.004801097325980663,
                0.043444037437438965,
                0.05248703807592392,
                -0.05897384509444237,
                -0.0363723449409008,
                -0.01919439621269703,
                -0.030565783381462097,
                -0.028318284079432487,
                -0.00859061162918806,
                0.02971867471933365,
                -0.06876783072948456,
                -0.0042400541715323925,
                0.017750095576047897,
                -0.007101485040038824,
                -0.022347545251250267,
                -0.020086640492081642,
                0.009793486446142197,
                0.03498539328575134,
                0.007310369051992893,
                -0.038432199507951736,
                0.0012853152584284544,
                0.06304562091827393,
                1.3880840015190188e-06,
                -0.025311525911092758,
                -0.06509200483560562,
                -0.03309723362326622,
                -0.013050775043666363,
                -0.010186845436692238,
                0.0018173980060964823,
                -0.01084918063133955,
                -0.0013163866242393851,
                -0.015042089857161045,
                -0.07562490552663803,
                -0.01073351502418518,
                0.04449274763464928,
                -0.07466360181570053,
                0.0012763632694259286,
                0.0430062860250473,
                0.009821390733122826,
                -0.00826318934559822,
                0.0013345599872991443,
                -0.027842262759804726,
                0.02832740731537342,
                -0.023223213851451874,
                0.019101237878203392,
                0.012257811613380909,
                0.08605463802814484,
                0.007548331283032894,
                -0.0457116961479187,
                -0.05346599966287613,
                0.007983360439538956,
                -0.033788662403821945,
                0.06974072754383087,
                0.02270120568573475,
                0.05003555864095688,
                0.019403642043471336,
                0.01941758766770363,
                -0.03478097543120384,
                -0.014702847227454185,
                0.03652254119515419,
                -0.06315246969461441,
                -0.02932502143085003,
                -0.010551350191235542,
                0.07861606776714325,
                -0.07327619194984436,
                0.032878465950489044,
                0.002757046837359667,
                0.02169555053114891,
                -0.021490804851055145,
                -0.03954543545842171,
                -0.029155457392334938,
                -0.0019257781095802784,
                0.07979078590869904,
                -0.002420926932245493,
                -0.02311873622238636,
                -0.004033001139760017,
                0.0011818353086709976,
                -0.007673914078623056,
                -0.00894042756408453,
                0.03953218460083008,
                -0.045368436723947525,
                0.014868080615997314,
                -0.007733524311333895,
                0.00877108983695507,
                0.021942755207419395,
                0.018596038222312927,
                0.005826815962791443,
                0.007682329975068569,
                0.04752803593873978,
                0.02509189397096634,
                -0.012596091255545616,
                -0.012763085775077343,
                0.05853968486189842,
                -0.09389127790927887,
                0.023598235100507736,
                -0.0329924039542675,
                -0.03541282191872597,
                0.03156189247965813,
                -0.042419228702783585,
                -0.016570018604397774,
                0.0018374245846644044,
                0.0459432527422905,
                0.03105795383453369,
                0.010987003333866596,
                0.005844711791723967,
                0.03628198429942131,
                0.0517861433327198,
                -0.029166925698518753,
                0.01951420120894909,
                -0.04055559262633324,
                -0.0010061835637316108,
                0.047136448323726654,
                -0.03701592981815338,
                0.014545228332281113,
                -0.007885010913014412,
                0.03683891519904137,
                -0.02794969268143177,
                -0.025933964177966118,
                -0.028602715581655502,
                -0.013913526199758053,
                0.002477445872500539,
                -0.0634302869439125,
                0.048970747739076614,
                0.02896612323820591,
                -0.07315804809331894,
                0.04833098128437996,
                -0.017729369923472404,
                0.019598307088017464,
                0.0018976022256538272,
                0.005640055518597364,
                0.047146450728178024,
                0.004885646514594555,
                0.008185368962585926,
                -0.02265724167227745,
                -0.01769401505589485,
                0.001279350370168686,
                -0.018765226006507874,
                0.003288113512098789,
                0.013523022644221783,
                0.03570990264415741,
                -0.08362674713134766,
                -0.017419490963220596,
                0.056752804666757584,
                -0.007089750375598669,
                -0.02402503788471222,
                0.03781524673104286,
                -0.03807272017002106,
                -0.002370529342442751,
                0.003769725328311324,
                0.02473350428044796,
                -0.0035006783436983824,
                -0.05610288307070732,
                -0.04164692386984825,
                0.018575740978121758,
                0.005473536439239979,
                -0.003981088753789663,
                0.04893213137984276,
                0.023159705102443695,
                -0.008675686083734035,
                -0.06256717443466187,
                -0.06029844284057617,
                -0.01606476493179798,
                -0.002258835593238473,
                -0.02684829570353031,
                0.04627805948257446,
                0.031588900834321976,
                0.013367841951549053,
                0.023573968559503555,
                0.004652744624763727,
                -0.09330795705318451,
                -0.0202480461448431,
                -0.00467110937461257,
                0.06120194494724274,
                0.00985005870461464,
                -0.08256641030311584,
                0.06746027618646622,
                -0.004398854915052652,
                -0.004609389696270227,
                -0.001045002369210124,
                0.03905996307730675,
                0.025791974738240242,
                -0.007109612692147493,
                0.0008810226572677493,
                0.0113680399954319,
                0.018666395917534828,
                -0.020594937726855278,
                0.00854445993900299,
                -0.08275260776281357,
                -0.013845530338585377,
                -0.025233345106244087,
                -0.0025375457480549812,
                0.02050049602985382,
                0.010518135502934456,
                -0.00011384119716240093,
                -0.0556725338101387,
                -0.03273151442408562,
                0.03014964424073696,
                -0.0007794476114213467,
                0.06829392164945602,
                0.005369025748223066,
                0.008673803880810738,
                0.02205975539982319,
                -0.012474523857235909,
                0.021896468475461006,
                0.002057412639260292,
                0.01430673711001873,
                0.0030152853578329086,
                0.048681724816560745,
                0.012250942178070545,
                0.02067105658352375,
                -0.03167193755507469,
                -0.0007286388427019119,
                -0.009895860217511654,
                -0.012485383078455925,
                0.047072168439626694,
                0.012442618608474731,
                -0.01711871102452278,
                0.04507029801607132,
                0.025041071698069572,
                -0.010516025125980377,
                0.012021603062748909,
                -0.01133700180798769,
                0.005389565601944923,
                0.029985183849930763,
                0.02160433679819107,
                0.034049518406391144,
                -0.002068467903882265,
                -0.051961738616228104,
                -0.03223121538758278,
                -0.04538504779338837,
                0.030685588717460632,
                0.015960142016410828,
                0.012836468406021595,
                0.020345963537693024,
                0.013389349915087223,
                -0.03645453602075577,
                0.006867485586553812,
                -0.026705168187618256,
                -0.03266476094722748,
                0.027494845911860466,
                0.017651433125138283,
                0.07596901804208755,
                0.0001749809889588505,
                -0.02310206927359104,
                -0.04072519764304161,
                0.01011242438107729,
                -0.07355169951915741,
                -0.022107597440481186,
                -0.027988774701952934,
                -0.0440976582467556,
                0.04836207255721092,
                -0.008288157172501087,
                0.061252810060977936,
                -0.02546762116253376,
                -0.003978543449193239,
                -0.02698838710784912,
                0.01965479925274849,
                0.00023729180975351483,
                0.02536010555922985,
                -0.003981540445238352,
                -0.020593369379639626,
                -0.030136028304696083,
                -0.07040974497795105,
                0.002879712963476777,
                -0.015157789923250675,
                -0.008359597995877266,
                -0.052625756710767746,
                0.012697243131697178,
                0.01792098395526409,
                0.020195158198475838,
                0.001218703342601657,
                0.005750975571572781,
                -0.006749019492417574,
                0.0168601144105196,
                0.0481601357460022,
                0.029766058549284935,
                -0.033428121358156204,
                -0.005148929078131914,
                0.03334684297442436,
                0.033751230686903,
                -0.025720346719026566,
                -0.037981148809194565,
                -0.049621306359767914,
                -0.015764813870191574,
                -0.07481550425291061,
                -0.014765085652470589,
                -0.03840434551239014,
                -0.014790118671953678,
                0.03747083619236946,
                0.03871677815914154,
                0.041946154087781906,
                0.01659049466252327,
                -0.02091851271688938,
                -0.07910680770874023,
                0.012980476953089237,
                0.05460143834352493,
                -0.023193897679448128,
                -0.00099531514570117,
                -0.014532784931361675,
                -0.04103367403149605,
                0.04177339747548103,
                -0.00962055940181017,
                0.07719241827726364,
                0.009888362139463425,
                -0.007460580673068762,
                -0.0038424176163971424,
                0.033729538321495056,
                -0.021083680912852287,
                0.013520652428269386,
                0.004733404144644737,
                0.005003762897104025,
                0.02913595549762249,
                -0.032929856330156326,
                -0.05597993731498718,
                -0.07188151776790619,
                0.00863846205174923,
                0.038335759192705154,
                -0.023404374718666077,
                0.024064408615231514,
                -0.007931366562843323,
                -0.0020069845486432314,
                -0.032553356140851974,
                -0.0006174373556859791,
                0.030256522819399834,
                0.006022227928042412,
                -0.046471644192934036,
                -0.03695302456617355,
                -0.0005200968589633703,
                0.013095399364829063,
                0.006626670248806477,
                -0.060158226639032364,
                0.023075710982084274,
                -0.02170909196138382,
                -0.005851759575307369,
                0.0049842344596982,
                0.04179900139570236,
                -0.02969369664788246,
                -0.03153226897120476,
                0.00505936099216342,
                -0.025939228013157845,
                0.009818642400205135,
                -0.02394542098045349,
                0.004392046015709639,
                -0.015102743171155453,
                -0.01140498835593462,
                0.038695335388183594,
                -0.08136507123708725,
                -0.06121843308210373,
                -0.05988393351435661,
                0.026497745886445045,
                -0.010597646236419678,
                -0.02616102248430252,
                0.010236093774437904,
                -0.007824837230145931,
                0.017722362652420998,
                -0.049036748707294464,
                -0.016763761639595032,
                -0.04391944035887718,
                -0.03377669304609299,
                0.00023329051327891648,
                -0.0733770951628685,
                -0.021554065868258476,
                -0.03134752810001373,
                -0.05069795623421669,
                -0.008204187266528606,
                0.01343973632901907,
                -0.003569553140550852,
                0.000335393357090652,
                0.08039441704750061,
                -0.044557202607393265,
                0.0214802585542202,
                -0.024203002452850342,
                0.05462058633565903,
                -0.0274241641163826,
                0.041572634130716324,
                -0.018550995737314224,
                -0.032800573855638504,
                0.02965129166841507,
                0.001829420798458159,
                -0.06571665406227112,
                -0.03687015175819397,
                -0.024092063307762146,
                -0.039542682468891144,
                -0.04288579151034355,
                -0.023389752954244614,
                0.01728208363056183,
                -0.024751408025622368,
                0.0052169570699334145,
                -0.010669209994375706,
                0.07374909520149231,
                -0.04348582774400711,
                -0.023362938314676285,
                -0.02878914214670658,
                0.023074110969901085,
                0.006592761259526014,
                0.02328743226826191,
                0.0037570232525467873,
                -0.02952657639980316,
                -0.028603939339518547,
                0.04129406809806824,
                0.05398795008659363,
                -0.02730991132557392,
                0.0040358067490160465,
                -0.04930488392710686,
                0.013737269677221775,
                0.05356454476714134,
                0.02137966826558113,
                0.05034923553466797,
                -0.02266881987452507,
                0.027544112876057625,
                0.024785466492176056,
                0.021016323938965797,
                0.05043448135256767,
                -0.014020760543644428,
                0.003816680284217,
                0.08453229814767838,
                0.007148211821913719,
                -0.04808079078793526,
                -0.023908624425530434,
                0.0700305849313736,
                0.018513550981879234,
                0.007817147299647331,
                0.020840151235461235,
                -0.04840768128633499,
                0.049472562968730927,
                0.03421500325202942,
                -0.0053351144306361675,
                -6.0148911870783195e-05,
                0.04472525417804718,
                0.0015495766419917345,
                0.05521421134471893,
                0.0030224695801734924,
                0.029831618070602417,
                0.00544694671407342,
                0.014793013222515583,
                -0.07926468551158905,
                -0.010299052111804485,
                0.06381434202194214,
                0.020161204040050507,
                -0.04701809957623482,
                -0.057973187416791916,
                0.006217891816049814,
                -0.08436335623264313,
                -0.03999638557434082,
                -0.05299016088247299,
                -0.05399297550320625,
                0.03252754732966423,
                -0.03813112527132034,
                0.022690681740641594,
                0.03003212995827198,
                0.02754743956029415,
                0.0425059050321579,
                0.01044006273150444,
                -0.04142918065190315,
                0.014057421125471592,
                -0.07037622481584549,
                0.08567843586206436,
                -0.0729939341545105,
                0.04762168601155281,
                0.006245187949389219,
                -0.03763571381568909,
                -0.013762510381639004,
                -0.040439896285533905,
                0.04341229051351547,
                -0.069629967212677,
                0.04115531966090202,
                -0.027011005207896233,
                0.0012750949244946241,
                0.01598367840051651,
                0.037440575659275055,
                0.004847600124776363,
                0.008700982667505741,
                -0.025267163291573524,
                0.054256126284599304,
                0.04392481595277786,
                0.009230094030499458,
                -0.07103075087070465,
                -0.006404944229871035,
                0.01869080774486065,
                -0.04171700403094292,
                0.015299857594072819,
                0.005577523726969957,
                0.05629296228289604,
                0.02771645225584507,
                -0.06310782581567764,
                0.003213045420125127,
                -0.004298570100218058,
                0.02085404098033905,
                -0.0022238229867070913,
                0.02929454669356346,
                0.012567823752760887,
                -0.04593098536133766,
                0.036139365285634995,
                -0.03507750481367111,
                0.05824653431773186,
                0.0026044677942991257,
                -0.007345758844166994,
                -0.013957041315734386,
                0.016033627092838287,
                0.010092080570757389,
                -0.024724556133151054,
                -0.11294633895158768,
                0.024865372106432915,
                0.07893230766057968,
                -0.04008404165506363,
                -0.02624616213142872,
                -0.01015305332839489,
                -0.045096952468156815,
                0.05071372538805008,
                0.024616165086627007,
                0.03980528563261032,
                0.020101273432374,
                -0.071665458381176,
                0.06831660866737366,
                -0.01604370027780533,
                -0.00685460027307272,
                -0.008638023398816586,
                0.023897459730505943,
                -0.04016904532909393,
                0.04059690609574318,
                -0.052799105644226074,
                0.011496896855533123,
                -0.007636106573045254,
                0.10195805132389069,
                0.05383869633078575,
                0.05190602317452431,
                0.019774338230490685,
                0.10140324383974075,
                0.03205588087439537,
                -0.004121507052332163,
                -0.02123340405523777,
                0.02427832968533039,
                -0.03637896105647087,
                0.023962728679180145,
                0.002140485681593418,
                0.05004001036286354,
                -0.018180888146162033,
                -0.009113138541579247,
                -0.027463680133223534,
                -0.041336119174957275,
                0.021204644814133644,
                0.03431785851716995,
                -0.04587341472506523,
                -5.426642854606212e-33,
                0.011203838512301445,
                -0.025226004421710968,
                -0.009325968101620674,
                0.045115768909454346,
                -0.03217015042901039,
                -0.08237350732088089,
                0.06686002761125565,
                0.016015224158763885,
                0.039125893265008926,
                -0.05176939815282822,
                0.027530301362276077,
                0.02591855823993683,
                0.0001789773814380169,
                0.052535466849803925,
                0.014900612644851208,
                0.0343058705329895,
                0.0344310887157917,
                0.03616081178188324,
                0.07798559218645096,
                -0.02809673547744751,
                -0.04115413874387741,
                0.02736777812242508,
                0.07722236961126328,
                0.018762722611427307,
                0.00029142663697712123,
                0.03723058104515076,
                -0.020586660131812096,
                0.04231461510062218,
                0.029395120218396187,
                0.00436877366155386,
                0.04143977537751198,
                -0.016718648374080658,
                -0.00722952326759696,
                0.008185630664229393,
                0.023279787972569466,
                -0.04055095091462135,
                0.00012705053086392581,
                0.017529284581542015,
                -0.017545605078339577,
                0.06950792670249939,
                0.010957897640764713,
                -0.06719022989273071,
                0.03241794556379318,
                -0.06580361723899841,
                0.05576661229133606,
                -0.03051324561238289,
                0.04181705787777901,
                -0.003277835203334689,
                0.04848818480968475,
                0.06905397027730942,
                -0.02864675223827362,
                0.011959024704992771,
                -0.08580804616212845,
                -0.02125782147049904,
                -0.0658450499176979,
                0.012965795584022999,
                0.01555020734667778,
                -0.11630846560001373,
                -0.05995354801416397,
                -0.0029190760105848312,
                0.036728452891111374,
                0.009663836099207401,
                0.009803514927625656,
                -0.03088235855102539,
                -0.01498835626989603,
                -0.02162173204123974,
                0.017235593870282173,
                -0.0370987243950367,
                0.04981391131877899,
                -0.02990681119263172,
                -0.006986062973737717,
                0.10021650046110153,
                -0.06701202690601349,
                0.009018044918775558,
                0.005790486931800842,
                -0.006470564752817154,
                -0.025360489264130592,
                0.04507608711719513,
                0.02853885479271412,
                -0.00715800654143095,
                -0.05889742448925972,
                0.05653733015060425,
                0.0019686012528836727,
                -0.024587247520685196,
                0.010181647725403309,
                0.029900042340159416,
                0.005361953284591436,
                -0.0015903286403045058,
                0.018203657120466232,
                0.009835400618612766,
                0.04164189100265503,
                0.0021887454204261303,
                -0.058050721883773804,
                0.021227439865469933,
                0.07352180778980255,
                -0.042541101574897766,
                -0.00020314431458245963,
                -0.015394827350974083,
                0.004406097810715437,
                -0.014440253376960754,
                -0.03461046889424324,
                -0.00810395646840334,
                -0.056678153574466705,
                -0.025874752551317215,
                -0.02550537697970867,
                0.02115396223962307,
                -0.03731343150138855,
                0.10725350677967072,
                -0.04922555387020111,
                0.018189124763011932,
                0.024025358259677887,
                0.0005407742573879659,
                0.03623038902878761,
                0.05017629638314247,
                0.030297262594103813,
                -0.012289488688111305,
                0.00544754508882761,
                0.07788955420255661,
                -0.04000449553132057,
                -0.05309925228357315,
                0.010315295308828354,
                -0.008656547404825687,
                -0.011000833474099636,
                0.028913604095578194,
                -0.03762613981962204,
                0.01620144583284855,
                0.09206035733222961,
                0.0018667905824258924,
                -0.04144926369190216,
                -0.07517469674348831,
                0.03107849881052971,
                -0.009623472578823566,
                2.1903025526626152e-07,
                0.024864768609404564,
                -0.013185488060116768,
                -0.037165164947509766,
                0.020370695739984512,
                0.016210714355111122,
                0.012333805672824383,
                -0.03434386104345322,
                0.04223652556538582,
                -0.037232231348752975,
                0.08109007775783539,
                0.014152787625789642,
                0.008793831802904606,
                0.04360752925276756,
                -0.024227838963270187,
                0.009748308919370174,
                -0.014052045531570911,
                0.001253669266588986,
                -0.007251215633004904,
                -0.030076799914240837,
                0.015645837411284447,
                0.01175274420529604,
                0.02758810669183731,
                0.012459670193493366,
                0.018148202449083328,
                0.0006802694406360388,
                -0.028780510649085045,
                0.0154146458953619,
                0.00542059913277626,
                0.07453488558530807,
                0.01243028324097395,
                0.009313752874732018,
                0.003802715102210641,
                -0.02308967523276806,
                0.046899426728487015,
                -0.018222404643893242,
                0.003835987066850066,
                0.007040787488222122,
                0.018365230411291122,
                0.027083054184913635,
                0.057804305106401443,
                0.06949702650308609,
                0.014487933367490768,
                0.007222458254545927,
                -0.03616367280483246,
                0.03923822566866875,
                -0.068726547062397,
                -0.009333702735602856,
                0.01672947034239769,
                -0.040016889572143555,
                0.043516337871551514,
                0.010381115600466728,
                -0.013618052005767822,
                0.013163158670067787,
                -0.007577857002615929,
                0.03628459572792053,
                -0.06102227419614792,
                -0.029931947588920593,
                0.06696708500385284,
                0.03987047076225281,
                -0.018282296136021614,
                -0.037219174206256866,
                -0.02469921112060547,
                -0.00890514999628067,
                0.0009479362634010613,
                0.006599582731723785,
                0.016870325431227684,
                -0.030046919360756874,
                9.108879895788611e-35,
                -0.03658711910247803,
                0.02413577027618885,
                0.021089430898427963,
                0.017662694677710533,
                -0.0005322732031345367,
                -0.05191224068403244,
                0.013888426125049591,
                -0.019193749874830246,
                0.007455264218151569,
                0.07455819100141525,
                -0.0240952018648386,
                0.029116356745362282,
                0.02781478874385357,
                -0.045211341232061386,
                -0.02461855672299862,
                0.03127998113632202,
                0.02609885297715664,
                -0.042060356587171555,
                0.014652167446911335,
                -0.053249750286340714,
                0.0007435015868395567,
                0.04916991665959358,
                0.024208402261137962,
                -0.04723774269223213,
                0.002863057656213641,
                0.019005708396434784,
                -0.029545480385422707,
                0.003833703463897109,
                -0.008352027274668217,
                -0.01335934642702341,
                -0.01734834723174572,
                -0.03906116262078285,
                0.00540218036621809,
                -0.02877708151936531,
                -0.010431326925754547,
                0.04427024722099304,
                -0.020325781777501106,
                0.031583428382873535,
                -0.015063785947859287,
                -0.0021257903426885605,
                -0.02580995298922062,
                0.04686824977397919,
                -0.008638089522719383,
                -0.023849371820688248,
                0.019034981727600098,
                2.0455818230402656e-06,
                0.013977213762700558,
                -0.05264520272612572,
                -0.006695094518363476,
                -0.0167149156332016,
                0.003446818795055151,
                -0.0015428909100592136,
                -0.056382860988378525,
                -0.044759832322597504,
                0.05886378884315491,
                -0.02989189326763153,
                -0.043298978358507156,
                0.05426359176635742,
                -0.0015471872175112367,
                -0.030284922569990158,
                -0.0024678902700543404,
                0.02555660903453827,
                -0.022473188117146492,
                0.02327215112745762,
                -0.030790170654654503,
                -0.01592506282031536,
                -0.010400952771306038,
                -0.016436483711004257,
                -0.03385494276881218,
                -0.002551520708948374,
                -0.04841194674372673,
                -0.02925821766257286,
                0.003909173421561718,
                -0.024462107568979263,
                -0.01431687455624342,
                -0.024657493457198143,
                -0.020807787775993347,
                0.019414274021983147,
                0.03424461930990219,
                0.014954079873859882,
                0.06503266096115112,
                -0.04301709681749344,
                0.05109884962439537,
                0.0018943718168884516,
                0.03421984985470772,
                -0.05248565226793289,
                0.06650323420763016,
                -0.0008496440714225173,
                0.02846621535718441,
                0.03305867686867714,
                -0.03732907399535179,
                -0.023878829553723335,
                0.08072564750909805,
                0.029424123466014862,
                0.009810442104935646,
                0.014387124218046665,
                -0.004899377003312111,
                0.00668146088719368,
                0.01847025938332081,
                -0.016481898725032806,
                0.013610918074846268,
                -0.03784162551164627,
                -0.06219307333230972,
                -0.04591485485434532,
                0.04311809688806534,
                -0.01613019034266472,
                0.0028043019119650126,
                -0.025950277224183083,
                -0.010842098854482174,
                -0.02165021188557148,
                -0.019885176792740822,
                -0.07574126124382019,
                0.020863907411694527,
                -0.03169487044215202,
                -0.028532033786177635,
                -0.06856683641672134,
                -0.018065940588712692,
                0.006090568378567696,
                -0.04401787742972374,
                0.027367116883397102,
                0.052306339144706726,
                -0.004241924732923508,
                0.006800817791372538,
                0.06764013320207596,
                0.053429488092660904,
                0.050997208803892136,
                0.02953578531742096,
                0.035209499299526215,
                0.027875976637005806,
                -0.010760239325463772,
                0.014677044935524464,
                -0.08343884348869324,
                -0.021725740283727646,
                -0.014478817582130432,
                0.001118541113100946,
                -0.00040845602052286267,
                -0.02556881494820118,
                -0.042218152433633804,
                0.0169774629175663,
                -0.007273872382938862,
                -0.02890789695084095,
                -0.012591009959578514,
                -0.027319766581058502,
                -0.011085248552262783,
                -0.033240705728530884,
                -0.005796333309262991,
                0.03468968719244003,
                0.03536836802959442,
                -0.052248649299144745,
                -0.08128949254751205,
                -0.0018721832893788815,
                -0.009130766615271568,
                -0.02044074982404709,
                -0.024889584630727768,
                0.010836686939001083,
                -0.0050172749906778336,
                0.03224826604127884,
                0.03071756474673748,
                -0.1098327785730362,
                0.0019994224421679974,
                -0.01936447247862816,
                0.05271732062101364,
                -0.004669765010476112,
                -0.006537077948451042,
                -0.006915018428117037,
                -0.024428950622677803,
                0.0029649953357875347,
                -0.031106920912861824,
                -0.05174947530031204,
                0.03396976739168167,
                -0.010469963774085045,
                0.03017258644104004,
                0.049801114946603775,
                -0.0029483952093869448,
                -0.05933370068669319,
                0.0006070827948860824,
                0.012772931717336178,
                0.006764654535800219,
                0.019238222390413284,
                0.04868345335125923,
                -0.05429856479167938,
                -0.013775610364973545,
                -0.023592034354805946,
                0.0025435311254113913,
                0.01484452560544014,
                0.032123591750860214,
                0.006370291113853455,
                -0.05124771595001221,
                0.06703651696443558,
                -0.04851202294230461,
                -0.027656318619847298,
                -0.057676803320646286,
                -0.0616946779191494,
                -0.06253509223461151,
                0.010028041899204254,
                0.021371910348534584,
                0.03264286369085312,
                -0.03900037333369255,
                0.041490864008665085,
                -0.03830641135573387,
                -0.0031643982511013746,
                -0.00023978488752618432,
                -0.012602613307535648,
                0.013019717298448086,
                0.005063741933554411,
                0.007255085743963718,
                0.023261364549398422,
                0.02609059400856495,
                -0.00918922945857048,
                0.00799963716417551,
                -0.017153900116682053,
                -0.005464131943881512,
                -0.05303196609020233,
                0.0762811228632927,
                -0.008436175063252449,
                0.0033967061899602413,
                0.014605764299631119,
                -0.05210472270846367,
                -0.006933471187949181,
                0.003184789326041937,
                -0.0379200279712677,
                -0.0046806372702121735,
                0.04370437562465668,
                0.0191727913916111,
                0.03906329348683357,
                0.017256982624530792,
                0.008317185565829277,
                0.043638844043016434,
                0.028368722647428513,
                -0.05590154975652695,
                0.03880259022116661,
                -0.02825435996055603,
                0.04808337241411209,
                0.018787551671266556,
                0.030058277770876884,
                -0.008363867178559303,
                -0.03344004601240158,
                -0.014347279444336891,
                -0.018347790464758873,
                0.008829299360513687,
                -0.021964048966765404,
                -0.030743801966309547,
                0.008904599584639072,
                0.034615352749824524,
                0.03294111415743828,
                0.010017385706305504,
                0.0611962229013443,
                0.11420828849077225,
                -0.000688013737089932,
                -0.03819582611322403,
                -0.07325175404548645,
                0.006437909323722124,
                -0.02310807816684246,
                0.008837037719786167,
                0.015922093763947487,
                0.06350750476121902,
                0.0021471348591148853,
                0.0026659981813281775,
                0.019998637959361076,
                0.01547305379062891,
                0.014246995560824871,
                0.030588427558541298,
                0.017486296594142914,
                0.0006005926406942308,
                -0.012813795357942581,
                0.006338667590171099,
                -0.06864664703607559,
                -0.04204077273607254,
                -0.00036720861680805683,
                -0.017641130834817886,
                0.018220530822873116,
                0.03118753992021084,
                -0.039841607213020325,
                -0.03583081439137459,
                -0.029957300052046776,
                0.04741460457444191,
                -0.006537568289786577,
                -0.009460500441491604,
                0.017173876985907555,
                -0.011913230642676353,
                -0.03464218229055405,
                0.03729627653956413,
                3.642425872385502e-05,
                0.008400920778512955,
                -0.02043323405086994,
                -0.030332008376717567,
                0.044156141579151154,
                -0.013475146144628525,
                0.018855532631278038,
                -0.02420816197991371,
                -0.015160598792135715,
                -0.031054003164172173,
                -0.05125923454761505,
                -0.01955372653901577,
                -0.011434976011514664,
                0.019369084388017654,
                0.0009532238473184407,
                -0.01125362515449524,
                -0.015473942272365093,
                -0.005247420631349087,
                -0.015402238816022873,
                -0.018988845869898796,
                -0.0141129856929183,
                0.02587207593023777,
                -0.023295626044273376,
                0.005380664020776749,
                0.04584422707557678,
                0.026977764442563057,
                -0.01752004772424698,
                0.012159592472016811,
                0.007755730301141739,
                0.011437871493399143,
                -0.0020922988187521696,
                0.09366672486066818,
                0.023107778280973434,
                -0.017547504976391792,
                -0.10469513386487961,
                0.012640147469937801,
                -0.0004804060736205429,
                -0.056651677936315536,
                0.009740221314132214,
                0.01609792932868004,
                0.009009980596601963,
                0.023171698674559593,
                -0.014082805253565311,
                0.03627525269985199,
                -0.013446914963424206,
                -0.029181387275457382,
                0.0008348659030161798,
                -0.009742365218698978,
                0.045252785086631775,
                0.05773950740695,
                0.009808068163692951,
                -0.031159264966845512,
                0.034890349954366684,
                0.05817711725831032,
                0.030661748722195625,
                -0.04251081123948097,
                0.04172157123684883,
                0.06990985572338104,
                -0.00867232121527195,
                -0.04186427965760231,
                -0.044437285512685776,
                0.07050914317369461,
                -0.019748464226722717,
                -0.055591169744729996,
                0.026574840769171715,
                -0.022581269964575768,
                -0.04720813408493996,
                -0.023097950965166092,
                -0.015771007165312767,
                -0.017563747242093086,
                -0.02509133890271187,
                -0.03194734454154968,
                -0.02797822654247284,
                -0.028808854520320892,
                -0.0024363058619201183,
                -0.02772553265094757,
                0.011729470454156399,
                -0.07199350744485855,
                0.004422776401042938,
                0.029407987371087074,
                0.0139491381123662,
                -0.020814336836338043,
                -0.04797948896884918,
                0.041921745985746384,
                0.013962400145828724,
                -0.03324475884437561,
                0.004443438723683357,
                0.02273661270737648,
                -0.03548525646328926,
                0.038777731359004974,
                0.04251975193619728,
                0.0580095574259758,
                -0.009614497423171997,
                0.03739454224705696,
                0.02150801569223404,
                0.005814810283482075,
                -0.01268739439547062,
                0.015431792475283146,
                0.0003227843844797462,
                0.042368583381175995,
                0.001957386964932084,
                -0.041236817836761475,
                0.020353909581899643,
                -0.043722860515117645,
                0.07616130262613297,
                0.021901879459619522,
                -0.013263916596770287,
                0.05818533897399902,
                -0.0031144311651587486,
                0.06346684694290161,
                -0.04303587228059769,
                -0.017020106315612793,
                0.03850630298256874,
                -0.03011872060596943,
                0.016186991706490517,
                -0.0012451111106202006,
                0.0022624051198363304,
                0.05077119916677475,
                0.027137547731399536,
                0.007176718674600124,
                -0.025753390043973923,
                -0.05271785706281662,
                0.033429697155952454,
                0.008360963314771652,
                -0.032120026648044586,
                0.04127546772360802,
                0.07014697790145874,
                -0.027293868362903595,
                0.0027511203661561012,
                -0.0023393486626446247,
                0.021941179409623146,
                0.031680867075920105,
                0.04541502892971039,
                -0.04142610356211662,
                -0.03851410374045372,
                0.002872258424758911,
                -0.022994624450802803,
                0.022427329793572426,
                -0.014418615028262138,
                0.04256825149059296,
                -0.028734171763062477,
                0.038552094250917435,
                -0.000696674338541925,
                -0.02260569855570793,
                -0.023417821153998375,
                -0.04396551847457886,
                0.017578087747097015,
                -0.000648694986011833,
                -0.07233329862356186,
                -0.005910389591008425,
                0.0482683926820755,
                -0.021862782537937164,
                -0.04383198544383049,
                0.01705958880484104,
                -0.04375147074460983,
                -0.039036501199007034,
                0.0331285335123539,
                0.012681017629802227,
                0.009851209819316864,
                -0.02761807292699814,
                -0.0816430002450943,
                -0.00013971418957225978,
                0.00033768347930163145,
                -0.06555099785327911,
                -0.0711122676730156,
                0.028391126543283463,
                0.0047449530102312565,
                0.07929016649723053,
                0.007326231338083744,
                -0.007351601962000132,
                -0.04311896115541458,
                0.035332877188920975,
                -0.03125862777233124,
                -0.07065233588218689,
                -0.07776163518428802,
                0.02871224656701088,
                -0.04061969369649887,
                -0.061917029321193695,
                0.07420127838850021,
                0.039659738540649414,
                -0.032977145165205,
                0.039924923330545425,
                0.0013008721871301532,
                0.006013973616063595,
                -0.00910270120948553,
                -0.038964178413152695,
                0.007650246378034353,
                0.02702600695192814,
                0.0842214971780777,
                0.04337300360202789,
                0.052373237907886505,
                -0.08509466052055359,
                -0.014584892429411411,
                -0.03409432992339134,
                0.06792046129703522,
                -0.014075342565774918,
                -0.09784125536680222,
                0.028852447867393494,
                -0.002041502157226205,
                -0.04518000781536102,
                -0.025858085602521896,
                0.006114769261330366,
                0.003888138569891453,
                0.02564089559018612,
                0.005387201439589262,
                -0.022531427443027496,
                0.013730394653975964,
                0.02246580272912979,
                -0.023779604583978653,
                -0.0576089546084404,
                0.057384077459573746,
                -0.061944667249917984,
                -0.013546671718358994,
                -0.0029435891192406416,
                -0.011232176795601845,
                -0.00936922337859869,
                -0.005510050803422928,
                0.01678329147398472,
                0.07095090299844742,
                0.01647639460861683,
                0.04675886780023575,
                -0.013863325119018555,
                -0.02283305488526821,
                0.01976720243692398,
                0.05650453642010689,
                -0.05558830872178078,
                -0.003185590263456106,
                0.017639603465795517,
                -0.009185612201690674,
                -0.0407034270465374,
                0.020095139741897583,
                -0.020527563989162445,
                0.06994152814149857,
                0.0062677813693881035,
                0.01862432062625885,
                0.015900330618023872,
                0.11374720931053162,
                -0.009089848026633263,
                0.0061232238076627254,
                -0.03469753637909889,
                0.00319584715180099,
                -0.07985016703605652,
                0.00710585480555892,
                -0.03193194791674614,
                0.047226086258888245,
                -0.047418251633644104,
                0.040664803236722946,
                0.010586882010102272,
                -0.03176252916455269,
                -0.02180401422083378,
                -0.009698757901787758,
                0.003210163675248623,
                0.012011150829494,
                0.07431863993406296,
                0.028787286952137947,
                0.014446821995079517,
                0.0120088504627347,
                -0.008344181813299656,
                -0.011543508619070053,
                -0.0432765893638134,
                -0.009700733236968517,
                0.00908077321946621,
                -0.011292858049273491,
                -0.058536216616630554,
                0.0421280562877655,
                -0.014235910028219223,
                0.024834780022501945,
                -0.013501707464456558,
                -0.01873873919248581,
                -0.05659995600581169,
                -0.014744688756763935,
                0.05687339976429939,
                0.025384655222296715,
                0.07188061624765396,
                0.025845766067504883,
                -0.022870980203151703,
                0.01690887100994587,
                0.020686011761426926,
                0.02768087387084961,
                -0.023621363565325737,
                0.028244614601135254,
                -0.06293605268001556,
                0.0001964470138773322,
                0.046723999083042145,
                -7.847281433246931e-33,
                -0.02217063680291176,
                -0.01595311611890793,
                0.001983542926609516,
                -0.01669035479426384,
                -0.054985154420137405,
                0.014275767840445042,
                0.0009073087712749839,
                0.077814020216465,
                0.007038863841444254,
                -0.008910829201340675,
                -0.0017282585613429546,
                0.03213557228446007,
                0.0225562434643507,
                -0.0103799719363451,
                -0.009072661399841309,
                -0.06768841296434402,
                -0.030796481296420097,
                0.03575870022177696,
                0.0034804248716682196,
                -0.011534599587321281,
                0.0010099223582074046,
                0.023571664467453957,
                -0.058172065764665604,
                -0.05989322438836098,
                0.05427807942032814,
                -0.017788514494895935,
                -0.015407711267471313,
                -0.005842852871865034,
                -0.0022351802326738834,
                0.027698418125510216,
                -0.01050451397895813,
                -0.007317667827010155,
                -0.006758970208466053,
                0.01286917831748724,
                0.020795753225684166,
                0.02625681646168232,
                -0.043182626366615295,
                -0.05879049003124237,
                -0.037083711475133896,
                -0.025204068049788475,
                -0.07262277603149414,
                -0.01983471028506756,
                0.03080786019563675,
                0.017117757350206375,
                -0.017623363062739372,
                -0.12155145406723022,
                -0.004363871179521084,
                0.013548695482313633,
                0.005367832724004984,
                0.049426883459091187,
                0.009327431209385395,
                0.04626636952161789,
                -0.049571841955184937,
                0.03528156504034996,
                -0.07407049834728241,
                -0.04888679087162018,
                0.014184306375682354,
                0.06203439086675644,
                -0.04655000567436218,
                -0.020069362595677376,
                -0.04972835257649422,
                0.050720274448394775,
                -0.046652901917696,
                -0.01057495828717947,
                -0.03136231005191803,
                0.011844856664538383,
                0.0782419890165329,
                0.019279561936855316,
                0.029150517657399178,
                0.012050538323819637,
                -0.03883011266589165,
                0.03164054825901985,
                0.04758068919181824,
                0.10755432397127151,
                0.01787751540541649,
                -0.09651727974414825,
                -0.01489894650876522,
                0.03641685098409653,
                0.08233989775180817,
                -0.0480746328830719,
                0.0177215114235878,
                0.036160409450531006,
                -0.0027983884792774916,
                0.04356207326054573,
                0.01576484553515911,
                -0.03186415508389473,
                0.007906859740614891,
                0.03293818607926369,
                -0.037294741719961166,
                0.020288417115807533,
                0.002702932571992278,
                0.06712311506271362,
                -0.013015888631343842,
                0.05207851901650429,
                0.032783083617687225,
                0.008806115947663784,
                0.004389739129692316,
                -0.040071576833724976,
                -0.0026919615920633078,
                -0.005548922810703516,
                0.08798198401927948,
                0.010277463123202324,
                -0.05322109907865524,
                0.04559357091784477,
                0.0130543177947402,
                -0.05716356262564659,
                -0.01032431609928608,
                0.08426740020513535,
                -0.05193735286593437,
                -0.024197988212108612,
                0.008528759703040123,
                0.015114406123757362,
                -0.03343266621232033,
                0.009418146684765816,
                -0.019252760335803032,
                0.01756405457854271,
                0.023245764896273613,
                -0.05144575238227844,
                0.04764803871512413,
                -0.036560676991939545,
                0.059353139251470566,
                0.044487643986940384,
                -0.041945263743400574,
                0.03278839960694313,
                0.006748661398887634,
                0.023107867687940598,
                0.050224557518959045,
                0.06408098340034485,
                -0.05822688713669777,
                -0.1208876520395279,
                -0.00791847612708807,
                0.0023511217441409826,
                2.5947036874640617e-07,
                0.018804267048835754,
                -0.006316327024251223,
                0.010577293112874031,
                -0.05563392490148544,
                0.04798474162817001,
                0.027527665719389915,
                -0.049194082617759705,
                0.04652969166636467,
                0.017420805990695953,
                0.041882745921611786,
                0.003851888235658407,
                -0.01524462178349495,
                0.0352838896214962,
                0.0007845812942832708,
                0.019297407940030098,
                0.031669147312641144,
                -0.03772047534584999,
                -0.0626596137881279,
                0.0216874610632658,
                -0.04722961410880089,
                0.05481432005763054,
                0.024607710540294647,
                0.004215930588543415,
                -0.013048716820776463,
                -0.023945700377225876,
                -0.021821388974785805,
                -0.033581752330064774,
                -0.03732423484325409,
                0.031340211629867554,
                -0.08281871676445007,
                -0.0038763699121773243,
                0.02821224555373192,
                0.011544858105480671,
                -0.01615689881145954,
                -0.029534481465816498,
                0.014914150349795818,
                -0.004588519223034382,
                0.006050856318324804,
                -0.001085281721316278,
                0.017727583646774292,
                0.03257238119840622,
                0.0013889120891690254,
                0.008315568789839745,
                0.04407726973295212,
                0.02706063538789749,
                0.0018214695155620575,
                0.019784506410360336,
                0.08817873895168304,
                -0.07192020118236542,
                0.04552411660552025,
                0.00704718055203557,
                0.03293268755078316,
                -0.027854332700371742,
                0.07722889631986618,
                0.019991295412182808,
                -0.010360626503825188,
                -0.014612087048590183,
                -0.029945388436317444,
                0.031154194846749306,
                0.013939697295427322,
                -0.016395604237914085,
                -0.006012946367263794,
                0.004022504668682814,
                0.04813796281814575,
                0.014813650399446487,
                0.019583815708756447,
                -0.011727838777005672,
                1.0402606134223952e-34,
                0.04547803848981857,
                -0.049311596900224686,
                -0.020374763756990433,
                0.03511005640029907,
                0.05145517364144325,
                -0.04214601218700409,
                0.02921498566865921,
                -0.0105977738276124,
                -0.015447045676410198,
                0.021149417385458946,
                -0.041098423302173615,
                0.032467592507600784,
                -0.06776800006628036,
                -0.013221646659076214,
                0.0022721022833138704,
                -0.049086276441812515,
                0.02653006836771965,
                -0.017886431887745857,
                0.021437736228108406,
                -0.05896434187889099,
                0.03658934310078621,
                0.11515730619430542,
                0.024856409057974815,
                0.010829627513885498,
                0.04418177157640457,
                -0.009737039916217327,
                -0.043893877416849136,
                0.0021841763518750668,
                0.0035959084052592516,
                0.0278550423681736,
                -0.0017374176532030106,
                -0.004975695163011551,
                0.0017367203254252672,
                -0.03174789994955063,
                -0.013686458580195904,
                -0.045376040041446686,
                -0.08624924719333649,
                0.0068021854385733604,
                -0.027944350615143776,
                -0.0052631753496825695,
                0.004293961450457573,
                0.021001579239964485,
                -0.026406483724713326,
                -0.009780834428966045,
                0.0008355132886208594,
                1.8760975990517181e-06,
                -0.022976886481046677,
                -0.028813425451517105,
                -0.006170356180518866,
                -0.017850158736109734,
                -0.011390529572963715,
                -0.011635848321020603,
                0.026770833879709244,
                -0.0011497060768306255,
                0.05853499099612236,
                -0.0366026945412159,
                -0.0036922707222402096,
                0.02895563282072544,
                0.04689301550388336,
                0.02032303996384144,
                -0.016534917056560516,
                0.005482177250087261,
                -0.0008461525430902839,
                -0.046579014509916306,
                -0.03587963059544563,
                0.019046638160943985,
                -0.08084482699632645,
                0.03394577279686928,
                0.05817358195781708,
                0.09921465069055557,
                -0.05218799039721489,
                -0.015546188689768314,
                -0.002443843986839056,
                0.011974210850894451,
                0.010805835947394371,
                0.025905130431056023,
                0.010197640396654606,
                0.045214828103780746,
                -0.007852209731936455,
                0.02580093964934349,
                -0.012405019253492355,
                0.01634126715362072,
                0.0423383004963398,
                0.001824515056796372,
                0.06485553085803986,
                -0.032386425882577896,
                0.03322266787290573,
                -0.03257744759321213,
                0.04479197785258293,
                -0.049393340945243835,
                -0.029934292659163475,
                -0.022974230349063873,
                0.017940852791070938,
                -0.023214207962155342,
                -0.000778638874180615,
                0.010267410427331924,
                0.06292524933815002,
                -0.04300277680158615,
                -0.036436937749385834,
                0.00020334243890829384,
                -0.012533290311694145,
                -0.034055162221193314,
                -0.04181673377752304,
                0.039189714938402176,
                0.030962729826569557,
                -0.016004111617803574,
                -0.0016948458505794406,
                0.04350253567099571,
                -0.026937784627079964,
                -0.02999882586300373,
                0.009041782468557358,
                0.03148313984274864,
                0.031581852585077286,
                0.0927826389670372,
                0.03467053547501564,
                -0.00891034584492445,
                -0.005405801348388195,
                -0.041180528700351715,
                -0.023125695064663887,
                -0.053053684532642365,
                0.10039795935153961,
                -0.07356784492731094,
                -0.027961211279034615,
                -0.0710625946521759,
                0.0775146633386612,
                0.04323574900627136,
                0.0059621287509799,
                -0.008892577141523361,
                -0.015000627376139164,
                0.0023787766695022583,
                -0.009042073972523212,
                -0.0737602710723877,
                0.011285070329904556,
                -0.037325549870729446,
                0.02520807832479477,
                -0.020535122603178024,
                0.0503324419260025,
                -0.06470542401075363,
                0.022688020020723343,
                -0.02072146348655224,
                0.028545504435896873,
                -0.009077037684619427,
                -0.01389201171696186,
                -0.03838874027132988,
                -0.045912668108940125,
                0.00643161078915,
                0.037152644246816635,
                0.004827949218451977,
                0.03271690756082535,
                -0.07702614367008209,
                -0.050681717693805695,
                0.05374465882778168,
                -0.07997696101665497,
                -0.03920034319162369,
                -0.03746945410966873,
                -0.04750559478998184,
                -0.0260910764336586,
                0.021492300555109978,
                -0.004873240366578102,
                0.009699555113911629,
                -0.022231953218579292,
                0.005588459316641092,
                0.001786601496860385,
                0.001498950645327568,
                0.012805289588868618,
                0.10269051790237427,
                0.07327909022569656,
                0.025520017370581627,
                0.030808553099632263,
                0.009432004764676094,
                -0.032492496073246,
                -0.017671354115009308,
                0.009052643552422523,
                0.04756033420562744,
                -0.034111637622117996,
                0.03951746225357056,
                0.004199380986392498,
                0.06551581621170044,
                -0.010412333533167839,
                -0.028869735077023506,
                0.022864550352096558,
                -0.02341250702738762,
                -0.00520532950758934,
                -0.04228944331407547,
                0.08377554267644882,
                0.02516373246908188,
                0.05221688002347946,
                0.010455640964210033,
                0.028631940484046936,
                -0.062119998037815094,
                -0.01804204471409321,
                -0.011962809599936008,
                -0.019513459876179695,
                0.012931005097925663,
                0.07803923636674881,
                -0.02147301472723484,
                0.031650129705667496,
                -0.037120793014764786,
                0.004306819289922714,
                -0.04418404772877693,
                -0.011961538344621658,
                -0.019027676433324814,
                0.027835017070174217,
                -0.022566597908735275,
                0.00011260818428127095,
                0.026179194450378418,
                0.01590817980468273,
                0.027780607342720032,
                -0.030671048909425735,
                -0.02815118059515953,
                0.02069707028567791,
                -0.005999724846333265,
                -0.03388507291674614,
                0.08217942714691162,
                0.013366324827075005,
                -0.005228162277489901,
                -0.012113960459828377,
                -0.04845067858695984,
                -0.042076073586940765,
                0.04834846034646034,
                -0.07719330489635468,
                0.0230291448533535,
                0.010994747281074524,
                -0.0011732804123312235,
                0.014200909063220024,
                -0.009845578111708164,
                -0.02738756686449051,
                -0.004708583001047373,
                -0.015423009172081947,
                0.006092783994972706,
                -0.009641360491514206,
                -0.03026055172085762,
                0.0673837661743164,
                0.04386858269572258,
                -0.007338047958910465,
                -0.034747179597616196,
                -0.004757953342050314,
                -0.02238466776907444,
                -0.008185234852135181,
                -0.016504254192113876,
                -0.01755577325820923,
                -0.012381475418806076,
                0.056571561843156815,
                0.014235944487154484,
                -0.015113855712115765,
                -0.014633764512836933,
                0.04826686158776283,
                0.052676767110824585,
                -0.002115850569680333,
                0.002579128835350275,
                -0.11395872384309769,
                -0.02447739988565445,
                -0.019040396437048912,
                -0.008675126358866692,
                -0.0001522743987152353,
                0.08211272954940796,
                -0.050646234303712845,
                -0.000815939623862505,
                0.005604587960988283,
                0.016449904069304466,
                -0.038910310715436935,
                -0.0936003029346466,
                0.003955323714762926,
                0.03844257816672325,
                0.00131359719671309,
                0.044521741569042206,
                -0.03545909374952316,
                -0.024222327396273613,
                0.01856415718793869,
                -0.06586134433746338,
                0.006721499375998974,
                -0.023112161085009575,
                -0.014187978580594063,
                -0.02598216012120247,
                -0.020786644890904427,
                0.05654625967144966,
                -0.04216907545924187,
                0.021491792052984238,
                0.04190145805478096,
                -0.014250271022319794,
                0.006820519454777241,
                0.04145557060837746,
                0.009691913612186909,
                0.025005511939525604,
                -0.011325690895318985,
                -0.029084162786602974,
                0.022417433559894562,
                -0.05007712170481682,
                -0.0002247102529508993,
                -0.022000109776854515,
                0.022101137787103653,
                0.04059673845767975,
                -0.005932316184043884,
                -0.027951980009675026,
                -0.023943109437823296,
                -0.022730590775609016,
                -0.014653511345386505,
                0.00929765123873949,
                -0.04280248284339905,
                -0.024264205247163773,
                0.013181780464947224,
                -0.049963537603616714,
                0.025530999526381493,
                0.028397556394338608,
                -0.015637394040822983,
                0.023770852014422417,
                -0.03049054555594921,
                0.003508029505610466,
                0.029367413371801376,
                0.022606872022151947,
                0.016452090814709663,
                -0.010701301507651806,
                -0.016477394849061966,
                0.0008846980636008084,
                -0.025601835921406746,
                -0.011517098173499107,
                0.015605689957737923,
                0.05322905257344246,
                -0.06822048127651215,
                -0.019616959616541862,
                0.06200367584824562,
                0.01710534654557705,
                -0.00961703434586525,
                0.008149110712110996,
                0.069522924721241,
                -0.03800755366683006,
                -0.01717914640903473,
                -0.08801282942295074,
                -0.03587092086672783,
                0.029216663911938667,
                0.05204860493540764,
                -0.010505144484341145,
                -0.003371603088453412,
                0.06579366326332092,
                0.05888098105788231,
                0.013452555052936077,
                -0.05640421435236931,
                0.012664354406297207,
                0.007609629537910223,
                0.028902316465973854,
                -0.05432429537177086,
                0.044272538274526596,
                -0.04768385365605354,
                -0.03488019108772278,
                0.01157392468303442,
                -0.03681930527091026,
                0.06474901735782623,
                -0.020399093627929688,
                -0.028796272352337837,
                -0.03501347452402115,
                -0.007723615504801273,
                -0.007327598053961992,
                -0.05689500272274017,
                -0.023755282163619995,
                0.00217245239764452,
                0.018313858658075333,
                -0.020291754975914955,
                -0.00030788392177782953,
                0.014909796416759491,
                -0.05524434521794319,
                -0.012913782149553299,
                0.048740729689598083,
                0.010399787686765194,
                -0.0029786680825054646,
                -0.04650929570198059,
                -0.010337857529520988,
                -0.022915862500667572,
                -0.02620343305170536,
                0.042169179767370224,
                0.029211167246103287,
                -0.043492790311574936,
                0.04539741203188896,
                0.046655066311359406,
                0.08642839640378952,
                0.025200320407748222,
                0.006077614147216082,
                0.0675462856888771,
                0.034944694489240646,
                0.018035126850008965,
                -0.057437215000391006,
                -0.0004293161618988961,
                -0.013775119557976723,
                0.012481874786317348,
                -0.032828234136104584,
                0.03963354602456093,
                0.06305532157421112,
                0.05556727200746536,
                0.014031585305929184,
                -0.08329372107982635,
                -0.07420904189348221,
                -0.010751914232969284,
                0.042945586144924164,
                -0.06303253769874573,
                0.03815891221165657,
                0.04025372117757797,
                -0.01594679243862629,
                -0.011381621472537518,
                -0.015517976135015488,
                -0.02973923087120056,
                -0.05714961141347885,
                0.03227263689041138,
                0.02460552752017975,
                0.010956530459225178,
                -0.07258697599172592,
                0.017323359847068787,
                -0.013076769188046455,
                -0.004114265087991953,
                -0.03771959990262985,
                0.02706534042954445,
                -0.023314280435442924,
                0.043974023312330246,
                0.007075380999594927,
                0.057694870978593826,
                0.014539258554577827,
                0.012951791286468506,
                -0.01784341037273407,
                -0.04456721618771553,
                0.026798062026500702,
                0.023623397573828697,
                0.02006906270980835,
                -0.03740471974015236,
                0.00995485670864582,
                0.06704730540513992,
                0.015689102932810783,
                -0.027213875204324722,
                -0.014439905062317848,
                -0.0301378034055233,
                -0.002798306057229638,
                -0.01701492816209793,
                0.014710885472595692,
                -0.051541004329919815,
                -0.025532305240631104,
                0.043402958661317825,
                0.02707776613533497,
                0.005388728808611631,
                0.06197498366236687,
                -0.009509673342108727,
                0.06538631021976471,
                0.030943065881729126,
                -0.00038681854493916035,
                -0.009821782819926739,
                0.04398169741034508,
                -0.007649112492799759,
                0.00016815069830045104,
                0.03358417749404907,
                0.05090531334280968,
                -0.030070584267377853,
                0.007551048882305622,
                0.06863615661859512,
                0.048314861953258514,
                -0.024295520037412643,
                0.0006606192328035831,
                0.06297087669372559,
                0.11898891627788544,
                0.025409463793039322,
                -0.032410476356744766,
                0.004340615123510361,
                0.05261604115366936,
                -0.015792954713106155,
                -0.01817476749420166,
                0.057506341487169266,
                0.06707929074764252,
                -0.031092654913663864,
                0.04788171872496605,
                -0.06296110898256302,
                0.00781666487455368,
                -0.02498745545744896,
                -0.030908236280083656,
                -0.04322129115462303,
                -0.02458290383219719,
                -0.017602309584617615,
                0.049867819994688034,
                0.05017497390508652,
                -0.05422811210155487,
                -0.0024417468812316656,
                0.012784935534000397,
                -0.08992230892181396,
                -0.04612156003713608,
                -0.000824551738332957,
                0.013132283464074135,
                -0.013717327266931534,
                -0.016202179715037346,
                0.022031277418136597,
                -0.02654346264898777,
                0.04340696334838867,
                0.028753984719514847,
                0.0006492888205684721,
                0.0007233198848553002,
                -0.11233104765415192,
                0.060106076300144196,
                0.012300175614655018,
                0.009345571510493755,
                -0.014088662341237068,
                0.030096963047981262,
                -0.03048848733305931,
                -0.03091888315975666,
                0.01018492691218853,
                0.013833966106176376,
                -0.06743200868368149,
                0.009977609850466251,
                -0.020953267812728882,
                -0.016550689935684204,
                0.01771990768611431,
                0.016543367877602577,
                0.007805919740349054,
                0.022163324058055878,
                -0.02440885454416275,
                -0.005121185909956694,
                0.05295119807124138,
                0.02368895523250103,
                -0.04427338019013405,
                0.0146440789103508,
                0.02162192575633526,
                0.001649849466048181,
                -0.03237638622522354,
                0.0012853327207267284,
                0.044416964054107666,
                0.01180348265916109,
                0.05968324840068817,
                0.001928497920744121,
                0.004816235508769751,
                -0.033352263271808624,
                -0.03678616136312485,
                -0.02252713032066822,
                -0.14617964625358582,
                0.03754333779215813,
                0.047078702598810196,
                -0.0245203897356987,
                -0.037315674126148224,
                -0.06142907962203026,
                0.002195795765146613,
                -0.005912520922720432,
                0.023224622011184692,
                -0.011701316572725773,
                -0.029074255377054214,
                0.029417164623737335,
                0.05155697837471962,
                0.04777737706899643,
                0.017846768721938133,
                -0.04965067282319069,
                0.03900248557329178,
                -0.08938410878181458,
                0.01730806566774845,
                -0.029197709634900093,
                0.012135322205722332,
                -0.023290343582630157,
                0.06454692780971527,
                0.00876291561871767,
                0.03642481192946434,
                0.026698442175984383,
                0.029393281787633896,
                -0.05019116401672363,
                -0.001110797980800271,
                -0.057374127209186554,
                0.017229516059160233,
                0.045735567808151245,
                0.03090391494333744,
                -0.021445227786898613,
                0.003316937480121851,
                0.025400223210453987,
                -0.03871387243270874,
                -0.00548592722043395,
                -0.0026974393986165524,
                -0.012470480054616928,
                0.003939979709684849,
                0.01590561680495739,
                -6.539447724357162e-33,
                -0.026680197566747665,
                -0.07499188184738159,
                0.02822151966392994,
                0.03113093040883541,
                -0.05787208303809166,
                -0.029542040079832077,
                -0.00273722130805254,
                0.02779264748096466,
                -0.029124664142727852,
                -0.01758883334696293,
                -0.03677072003483772,
                0.02484722249209881,
                0.0478646419942379,
                -0.04795188829302788,
                0.005366095341742039,
                0.016267001628875732,
                -0.01157291978597641,
                0.02250383049249649,
                -0.0028994556050747633,
                -0.05866360664367676,
                0.01155376248061657,
                0.02790031209588051,
                0.027686750516295433,
                -0.015636418014764786,
                0.01302953902631998,
                0.011428026482462883,
                -0.02324429526925087,
                3.203025698894635e-06,
                -0.018823694437742233,
                -0.014504625461995602,
                0.008173258975148201,
                -0.04727083072066307,
                -0.019947532564401627,
                -0.0327533483505249,
                -0.007785412482917309,
                0.06257417798042297,
                -0.03325365111231804,
                -0.057815760374069214,
                0.021408019587397575,
                0.02422233670949936,
                -0.03887766972184181,
                -0.031690049916505814,
                -0.018189894035458565,
                0.008136384189128876,
                0.03389425203204155,
                -0.03215619549155235,
                0.006693946197628975,
                -0.02446504682302475,
                0.04894183203577995,
                0.07032027095556259,
                -0.04804154112935066,
                0.0005808087880723178,
                -0.0572701133787632,
                -0.04122919216752052,
                0.05072773993015289,
                -0.008987975306808949,
                -0.022899260744452477,
                -0.049905430525541306,
                -0.021569866687059402,
                0.06260017305612564,
                0.009641166776418686,
                0.023736506700515747,
                -0.026774471625685692,
                -0.045274391770362854,
                -0.016516713425517082,
                -0.029188450425863266,
                -0.051736414432525635,
                -0.05070893093943596,
                -0.03582175821065903,
                -0.05249838903546333,
                -0.013294131495058537,
                0.050512995570898056,
                0.0388486422598362,
                -0.028146978467702866,
                0.014686803333461285,
                -0.0675518661737442,
                -0.0032706393394619226,
                -0.028713488951325417,
                0.0456509068608284,
                0.051865071058273315,
                0.009759128093719482,
                0.024306530132889748,
                -0.03110266849398613,
                0.0016210833564400673,
                -0.034379322081804276,
                -0.012180708348751068,
                -0.03192632272839546,
                0.004261781927198172,
                -0.013364871963858604,
                0.010479938238859177,
                -0.03843430057168007,
                0.00669277785345912,
                -0.0013704290613532066,
                0.009155452251434326,
                0.011268554255366325,
                -0.02250836044549942,
                0.03303062170743942,
                0.024821218103170395,
                -0.02743426524102688,
                -0.017379656434059143,
                -0.07744622975587845,
                0.012085486203432083,
                -0.0526273213326931,
                0.005914843641221523,
                0.041820790618658066,
                0.006756295450031757,
                -0.004159917123615742,
                0.032079942524433136,
                -0.02425464428961277,
                0.008924822323024273,
                -0.007162965834140778,
                0.03174176812171936,
                0.0396488793194294,
                0.007202833890914917,
                0.006260693538933992,
                -0.0164334699511528,
                -0.0052152350544929504,
                -0.06698425114154816,
                -0.02377455122768879,
                -0.020960118621587753,
                -0.027075760066509247,
                0.07668926566839218,
                -0.07050437480211258,
                0.022829728201031685,
                0.03327829763293266,
                -0.02212144434452057,
                0.009619265794754028,
                0.03999971225857735,
                -0.0073533011600375175,
                -0.037407584488391876,
                -0.018156198784708977,
                -0.01716722548007965,
                2.460689074723632e-07,
                -0.03736679255962372,
                0.012830965220928192,
                0.02430461160838604,
                0.032526060938835144,
                0.01785123161971569,
                0.0009780296823009849,
                -0.09019207209348679,
                0.018224848434329033,
                0.04112778976559639,
                -0.017619602382183075,
                0.03283558785915375,
                0.016711661592125893,
                0.011209219694137573,
                0.028912032023072243,
                0.06659476459026337,
                -8.079369581537321e-05,
                0.006971635855734348,
                -0.03267773240804672,
                0.016013234853744507,
                -0.003509989706799388,
                -0.00462255347520113,
                -0.0177027378231287,
                0.04659067094326019,
                0.0053435214795172215,
                -0.025744318962097168,
                -0.030916977673768997,
                0.02776399999856949,
                -0.015598452650010586,
                0.05905797705054283,
                -0.019653325900435448,
                0.005773460492491722,
                0.014338056556880474,
                0.0027076273690909147,
                0.023125242441892624,
                0.016312461346387863,
                -0.04097631201148033,
                0.023963935673236847,
                0.04287198185920715,
                0.05495915189385414,
                0.06332185864448547,
                -0.009821767918765545,
                0.033132605254650116,
                -0.015081369318068027,
                0.026882542297244072,
                0.015093596652150154,
                -0.04780874028801918,
                -0.02009948529303074,
                0.02953222766518593,
                0.0028504778165370226,
                -0.03800513595342636,
                0.003076049266383052,
                0.018094873055815697,
                0.01324569620192051,
                0.07965172082185745,
                0.014025053009390831,
                -0.011827533133327961,
                0.011299273930490017,
                -0.009170819073915482,
                0.02962176874279976,
                0.05369134247303009,
                -0.029537124559283257,
                0.008821608498692513,
                0.022385437041521072,
                0.018886296078562737,
                0.012488869950175285,
                0.02041514404118061,
                -0.06830290704965591,
                1.3750643634106222e-34,
                -0.008224100805819035,
                -0.021484550088644028,
                -0.0033263382501900196,
                -0.023781705647706985,
                0.01194226834923029,
                -0.0248770359903574,
                -0.03662159666419029,
                -0.020235702395439148,
                0.04618818685412407,
                0.04025492072105408,
                -0.028166279196739197
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\r3\\x97\\xd1\\xb5\\x00\\x80J\\xd3\\x93u\\x95\\xd3H\\x1dc'",
        "id__": "fd6e1cf0-13f1-4ca1-bca7-9a00a8b79c55"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "MSc/Environmental Management ",
            "rep": [
                0.004808058030903339,
                -0.01332938764244318,
                -0.023258499801158905,
                -0.025104062631726265,
                0.07049711793661118,
                0.02138664573431015,
                -0.029966918751597404,
                0.0023638177663087845,
                0.03632919862866402,
                -0.0016173115000128746,
                0.031462572515010834,
                0.07711699604988098,
                -0.00904507003724575,
                0.02903882786631584,
                0.049769531935453415,
                -0.02280241623520851,
                0.007598690688610077,
                -0.032164834439754486,
                -0.08085473626852036,
                -0.023409489542245865,
                -0.026378531008958817,
                -0.008510738611221313,
                -0.0019279788248240948,
                0.04074721783399582,
                0.03755256161093712,
                -0.00212635169737041,
                -0.012440879829227924,
                0.03245905041694641,
                0.03517305478453636,
                0.024357471615076065,
                0.04964794963598251,
                -0.012659871019423008,
                0.02010430209338665,
                0.002747539198026061,
                1.3940916687715799e-06,
                -0.021952584385871887,
                -0.03677186369895935,
                -0.019967539235949516,
                0.06763342767953873,
                -0.04274965450167656,
                0.07196476310491562,
                0.06404571980237961,
                0.010923209600150585,
                0.036712974309921265,
                0.01736259274184704,
                0.07244964689016342,
                0.041046202182769775,
                -0.05480654910206795,
                0.003914779983460903,
                -0.027527913451194763,
                -0.005954114720225334,
                -0.06104832515120506,
                -0.05145353078842163,
                -0.041041746735572815,
                -0.04163831099867821,
                0.0021420535631477833,
                0.04150057211518288,
                0.011309487745165825,
                0.04410246014595032,
                0.052006300538778305,
                -0.01944580115377903,
                0.012099860236048698,
                -0.04587619751691818,
                0.00856880471110344,
                0.035802651196718216,
                0.06114116683602333,
                0.1103660985827446,
                -0.016402635723352432,
                -0.03306746110320091,
                -0.014909631572663784,
                0.03169819340109825,
                0.022931542247533798,
                -0.025068210437893867,
                0.010482395067811012,
                0.01770862005650997,
                0.012976625934243202,
                0.005479149054735899,
                -0.0014266566140577197,
                -0.006205899175256491,
                -0.019618473947048187,
                -0.012279565446078777,
                0.05023926869034767,
                0.0032749236561357975,
                0.030360857024788857,
                -0.05014593154191971,
                0.06455995887517929,
                -0.023485684767365456,
                0.00022031337721273303,
                0.028958920389413834,
                -0.006847636308521032,
                0.022595666348934174,
                0.04868127033114433,
                -0.015250813215970993,
                0.05937793105840683,
                -0.03399414196610451,
                -0.010345840826630592,
                0.05433426424860954,
                -0.008397744968533516,
                -0.01736370660364628,
                0.04185384884476662,
                -0.03266095742583275,
                -0.002910963026806712,
                0.00446757348254323,
                0.05766620859503746,
                0.02553647756576538,
                0.01652822457253933,
                0.007700440939515829,
                0.006251453887671232,
                0.023998158052563667,
                -0.05298810452222824,
                -0.046219345182180405,
                -0.014785452745854855,
                0.03110780566930771,
                -0.01871117390692234,
                -0.02522442117333412,
                0.06541445851325989,
                0.01954788714647293,
                -0.04281528294086456,
                0.002058714162558317,
                0.010148134082555771,
                0.016719643026590347,
                0.0058410679921507835,
                -0.012828748673200607,
                0.02693757601082325,
                0.04041168838739395,
                0.018849346786737442,
                -0.001675261533819139,
                0.039932020008563995,
                0.04471242055296898,
                0.08993388712406158,
                -0.01646961271762848,
                -0.05924169719219208,
                -0.012859409675002098,
                -0.002481823554262519,
                -0.0074379779398441315,
                0.015735764056444168,
                -0.003943493589758873,
                -0.011410925537347794,
                -0.03285664692521095,
                -0.018848709762096405,
                0.03467152267694473,
                0.0009411840583197773,
                0.01882913149893284,
                0.07885770499706268,
                -0.016269804909825325,
                -0.014753703959286213,
                0.01712351106107235,
                0.010193790309131145,
                0.004419619683176279,
                -0.02459992840886116,
                -0.03780875727534294,
                -0.03050512634217739,
                0.019555741921067238,
                -0.05259428918361664,
                0.06353063136339188,
                0.03306009992957115,
                0.08171409368515015,
                -0.014240612275898457,
                -0.00709181884303689,
                0.07584782689809799,
                -0.03624552860856056,
                0.010098216123878956,
                -0.02865636721253395,
                0.006786982994526625,
                -0.02584918960928917,
                -0.009819382801651955,
                0.03372107073664665,
                -0.02988339774310589,
                -0.09478773176670074,
                0.02077585458755493,
                -0.006760760210454464,
                -0.036117348819971085,
                -0.009825448505580425,
                0.06138533353805542,
                0.005987029988318682,
                -0.037996940314769745,
                0.013323020190000534,
                -0.015107432380318642,
                -0.029749436303973198,
                0.027469711378216743,
                -0.041612956672906876,
                -0.08525890111923218,
                0.040733348578214645,
                0.0074537391774356365,
                0.05533323436975479,
                -0.0058446768671274185,
                -0.004959085490554571,
                0.015914468094706535,
                -0.026223523542284966,
                -0.027345996350049973,
                0.021827148273587227,
                0.05997912585735321,
                -0.061586979776620865,
                -0.03128629922866821,
                0.012721424922347069,
                -0.032861243933439255,
                0.010894599370658398,
                -0.03845996782183647,
                0.02086510881781578,
                -0.011898730881512165,
                0.0015995491994544864,
                0.032904136925935745,
                0.01574077270925045,
                0.023872973397374153,
                -0.035165172070264816,
                -0.045632217079401016,
                0.013819213025271893,
                -0.08058375865221024,
                -0.05406402796506882,
                0.038016222417354584,
                0.0031630981247872114,
                -0.010764486156404018,
                0.054514043033123016,
                0.014602269977331161,
                -0.034051794558763504,
                -0.05748925358057022,
                0.005399890709668398,
                -0.023321816697716713,
                0.0019265830051153898,
                0.009837375953793526,
                0.020672215148806572,
                -0.05038340762257576,
                0.02228517271578312,
                0.005476826336234808,
                0.004291617777198553,
                0.003692698897793889,
                0.03271249309182167,
                -0.03156539425253868,
                0.008271760307252407,
                0.024269623681902885,
                -0.017310474067926407,
                0.010196265764534473,
                0.0071164947003126144,
                -0.01639804244041443,
                -0.014450417831540108,
                0.004852757789194584,
                0.05777783691883087,
                0.001967392163351178,
                0.009509282186627388,
                -0.029953476041555405,
                -0.0008930671028792858,
                0.07550495862960815,
                4.990740126231685e-05,
                -0.003329269587993622,
                -0.03682442381978035,
                0.013952188193798065,
                -0.00839524157345295,
                0.028587745502591133,
                0.019247202202677727,
                -0.024911968037486076,
                0.019358882680535316,
                -0.02849239483475685,
                0.018958892673254013,
                0.017512336373329163,
                0.029026411473751068,
                0.0006816020468249917,
                -0.06208256632089615,
                0.007062203250825405,
                -0.06482859700918198,
                -0.021316729485988617,
                -0.06831545382738113,
                -0.030567355453968048,
                -0.012053697369992733,
                -0.008172096684575081,
                -0.005129754543304443,
                -0.011707967147231102,
                -0.0037254318594932556,
                -0.0073938071727752686,
                -0.004415565636008978,
                0.00798717513680458,
                0.0061202216893434525,
                0.045241013169288635,
                0.012735027819871902,
                -0.012956946156919003,
                -0.01268613152205944,
                0.030191458761692047,
                -0.0392913855612278,
                0.03593428432941437,
                -0.05664766952395439,
                0.035666629672050476,
                0.020343856886029243,
                0.06843897700309753,
                -0.058213040232658386,
                -0.00294072600081563,
                -0.03570893406867981,
                0.05454643815755844,
                0.018788740038871765,
                -0.08566531538963318,
                -0.010022314265370369,
                0.0231203380972147,
                0.007102757226675749,
                -0.005709705408662558,
                0.0033991518430411816,
                -0.021281423047184944,
                -0.05301130190491676,
                0.07866556197404861,
                0.0037844949401915073,
                -0.024614833295345306,
                0.026866646483540535,
                0.01775769516825676,
                -0.010866666212677956,
                -0.008737225085496902,
                0.015186483971774578,
                0.030846524983644485,
                0.022887231782078743,
                0.05403243005275726,
                0.027274109423160553,
                0.06286309659481049,
                -0.011479239910840988,
                -0.061307866126298904,
                -0.08833371102809906,
                -0.000127877457998693,
                0.0711272656917572,
                0.08081718534231186,
                0.05792001634836197,
                -0.020623713731765747,
                -0.011458850465714931,
                -0.011359957046806812,
                0.07410503923892975,
                0.003252174938097596,
                -0.008831332437694073,
                -0.010302317328751087,
                -0.02295272797346115,
                -0.026029475033283234,
                -0.005978261586278677,
                -0.02477920614182949,
                -0.0225658118724823,
                0.011660625226795673,
                -0.0049841743893921375,
                -0.06928859651088715,
                -0.01466954592615366,
                0.017534129321575165,
                -0.04062744975090027,
                -0.016704343259334564,
                -0.06685355305671692,
                -0.09636755287647247,
                -0.002346689347177744,
                -0.12120932340621948,
                -0.04590444266796112,
                -0.02282489649951458,
                0.024633461609482765,
                -0.017566204071044922,
                -0.03775506466627121,
                -0.008973339572548866,
                0.03532964363694191,
                0.026132624596357346,
                0.028557220473885536,
                0.04051721468567848,
                -0.04022694751620293,
                0.01646791771054268,
                0.07775948196649551,
                2.100697201967705e-05,
                -0.01437770202755928,
                0.00823226198554039,
                -0.025059478357434273,
                0.05215500667691231,
                0.05867138132452965,
                0.07438074797391891,
                0.006490110885351896,
                -0.03949382156133652,
                -0.022587202489376068,
                -0.0530196949839592,
                -0.057294610887765884,
                -0.05037863180041313,
                0.021097589284181595,
                -0.010918993502855301,
                0.057203542441129684,
                -0.0026274598203599453,
                -0.0057703228667378426,
                -0.008435825817286968,
                -0.03997451066970825,
                -0.014896934852004051,
                -0.04714608192443848,
                -0.016944952309131622,
                -0.03345395624637604,
                0.01569797843694687,
                -0.0020624063909053802,
                -0.03563711419701576,
                -0.008789122104644775,
                0.004782155156135559,
                0.03803522139787674,
                0.025387311354279518,
                0.03236978128552437,
                -0.013130281120538712,
                -0.0063844420947134495,
                -0.019809115678071976,
                0.018998008221387863,
                0.008294893428683281,
                0.022294463589787483,
                0.0017422251403331757,
                0.012889262288808823,
                -0.09950133413076401,
                -0.02265872433781624,
                0.01924765110015869,
                -0.0003456311533227563,
                -0.014356818981468678,
                -0.018498113378882408,
                -0.03894784301519394,
                0.04268348962068558,
                -0.013633144088089466,
                0.07153312116861343,
                0.0261871125549078,
                0.0056488895788788795,
                0.032760027796030045,
                0.013060025870800018,
                -0.022209931164979935,
                0.08267834782600403,
                -0.016200518235564232,
                -0.06254289299249649,
                -0.0072410376742482185,
                0.03481445461511612,
                -0.035233668982982635,
                0.02909582108259201,
                0.01168108731508255,
                -0.03956625610589981,
                -0.07212647795677185,
                -0.01404518075287342,
                0.03289920836687088,
                -0.025907695293426514,
                -0.04020528122782707,
                0.017439255490899086,
                0.03979162126779556,
                0.05771758779883385,
                -0.01800430752336979,
                0.0868334248661995,
                -0.008980171754956245,
                -0.011760732159018517,
                0.03701555356383324,
                -0.03670384734869003,
                -0.04167382791638374,
                0.008325881324708462,
                -0.041095949709415436,
                -0.03331967815756798,
                -0.0004332984099164605,
                0.04083419591188431,
                0.003709044773131609,
                -0.02341601625084877,
                -0.03308497741818428,
                -0.03714320808649063,
                0.05028092861175537,
                0.006847384385764599,
                0.06901543587446213,
                -0.05099302530288696,
                -0.029612086713314056,
                0.024168813601136208,
                0.082548588514328,
                -0.06203639879822731,
                -0.034813784062862396,
                -0.005383036099374294,
                -0.03539801016449928,
                0.08363565057516098,
                -0.015093570575118065,
                -0.02319207414984703,
                0.018555734306573868,
                0.03881150111556053,
                -0.053876619786024094,
                -0.025724289938807487,
                0.007069719489663839,
                -0.040969349443912506,
                -0.021763773635029793,
                0.04132978245615959,
                0.01167257595807314,
                -0.010418479330837727,
                0.04359094798564911,
                0.012113376520574093,
                0.002396913478150964,
                0.007445498835295439,
                0.045172035694122314,
                0.016270505264401436,
                -0.005609296262264252,
                0.002343703294172883,
                -0.023761486634612083,
                -0.027691315859556198,
                0.03580796718597412,
                0.014106661081314087,
                -0.031347159296274185,
                0.05969902500510216,
                0.006526197772473097,
                -0.001574512803927064,
                0.013332689180970192,
                0.00012395578960422426,
                0.03735467791557312,
                0.048999954015016556,
                -0.04177321866154671,
                -0.014422768726944923,
                0.04125908017158508,
                0.033556003123521805,
                0.01267915964126587,
                0.013896865770220757,
                -0.03196673095226288,
                0.05543284863233566,
                -0.027071498334407806,
                -0.018892481923103333,
                -0.05218566954135895,
                0.03672749921679497,
                -0.015316173434257507,
                -0.020736679434776306,
                0.018047818914055824,
                -0.06975284218788147,
                -0.030609508976340294,
                -0.03895484283566475,
                -0.010953323915600777,
                -0.03418205678462982,
                -0.08711643517017365,
                0.03183703497052193,
                -0.018610648810863495,
                0.024242689833045006,
                -0.008372737094759941,
                0.02918894961476326,
                0.03372754529118538,
                0.022617213428020477,
                -0.004471867810934782,
                0.03950227424502373,
                0.0721665695309639,
                0.017012104392051697,
                0.024926191195845604,
                0.021291183307766914,
                -0.011973835527896881,
                -0.0004197560774628073,
                0.03669071942567825,
                0.037672098726034164,
                -0.04911702498793602,
                -0.009935976006090641,
                -0.02948237955570221,
                -0.012854269705712795,
                -5.343719385564327e-05,
                0.024612033739686012,
                0.0015592058189213276,
                0.007949145510792732,
                -0.005674052983522415,
                0.04902352765202522,
                -0.01289686094969511,
                -0.02049352414906025,
                -0.009217224083840847,
                0.002123416867107153,
                0.034898336976766586,
                0.0075075444765388966,
                0.051096100360155106,
                0.04254709556698799,
                0.07740996778011322,
                -0.02031327411532402,
                0.029545878991484642,
                0.04341747984290123,
                0.029128151014447212,
                -0.007253281306475401,
                -0.05715753138065338,
                -0.015374246053397655,
                0.008008996956050396,
                0.0012237494811415672,
                -0.016734693199396133,
                0.000407918996643275,
                -0.00881726574152708,
                0.009818627499043941,
                0.05044484883546829,
                0.010242484509944916,
                -5.105378411816558e-33,
                -0.05677157640457153,
                -0.029289672151207924,
                0.027635838836431503,
                0.008212723769247532,
                -0.0074807098135352135,
                -0.04818637669086456,
                0.023414574563503265,
                -0.026615716516971588,
                -0.030807888135313988,
                -0.010598713532090187,
                -0.0306805782020092,
                0.009655406698584557,
                0.012130023911595345,
                -0.007956402376294136,
                0.04622838273644447,
                0.11251110583543777,
                0.012408816255629063,
                0.03586510568857193,
                0.005904235411435366,
                -0.05438721552491188,
                0.020136121660470963,
                -0.01628049649298191,
                -0.0018407206516712904,
                0.019267670810222626,
                -0.022109583020210266,
                0.02255111001431942,
                -0.014757610857486725,
                -0.03531326353549957,
                -0.07027040421962738,
                -0.015968499705195427,
                0.04956136643886566,
                0.0026272451505064964,
                0.020032834261655807,
                -0.018230324611067772,
                -0.027023719623684883,
                -0.0017531451303511858,
                -0.03166649490594864,
                -0.005911423824727535,
                -0.010294844396412373,
                -0.020183678716421127,
                0.010625822469592094,
                -0.01398390717804432,
                0.016305208206176758,
                0.01048158947378397,
                -0.006082983687520027,
                -0.03883006051182747,
                -0.010562069714069366,
                -0.004720493219792843,
                0.02450399473309517,
                -0.05607783794403076,
                -0.025395479053258896,
                -0.033205535262823105,
                -0.04680664464831352,
                0.04838813468813896,
                -0.05407407507300377,
                -0.05353223532438278,
                0.03796651214361191,
                0.07462332397699356,
                0.014195520430803299,
                0.01030124630779028,
                0.005964707583189011,
                0.0548500120639801,
                0.0636812373995781,
                0.006629505194723606,
                -0.007048510015010834,
                0.003914492670446634,
                -0.03743687644600868,
                0.03653834015130997,
                0.022349141538143158,
                -0.026526810601353645,
                -0.004797051660716534,
                0.03205587714910507,
                -0.038823459297418594,
                -0.03703819960355759,
                -0.08845634013414383,
                -0.030165035277605057,
                -0.01520058698952198,
                -0.0328347310423851,
                0.020180046558380127,
                0.029439175501465797,
                -0.049500323832035065,
                0.034125033766031265,
                0.09781655669212341,
                0.011950712651014328,
                -0.035155925899744034,
                0.015427936799824238,
                -0.0011699540773406625,
                0.09592201560735703,
                0.04412166774272919,
                -0.01189318485558033,
                -0.013262617401778698,
                0.031132426112890244,
                -0.04263322800397873,
                -0.027334868907928467,
                0.035009875893592834,
                -0.00973058957606554,
                -0.030382392928004265,
                -0.011572383344173431,
                -0.007698132656514645,
                0.07630880177021027,
                -0.07395948469638824,
                0.0007977907662279904,
                -0.028941690921783447,
                0.0070564537309110165,
                -0.00290679675526917,
                0.029820328578352928,
                -0.043271489441394806,
                -0.02226298488676548,
                -0.022050676867365837,
                -0.010686623863875866,
                -0.006873838137835264,
                0.011707301251590252,
                -0.02312038280069828,
                -0.022164830937981606,
                0.004524076357483864,
                -0.0319938063621521,
                -0.0008201124728657305,
                -0.1078791618347168,
                -0.0215850081294775,
                0.010322143323719501,
                0.08490968495607376,
                -0.06415760517120361,
                -0.010521786287426949,
                0.003953929524868727,
                0.03097822330892086,
                -0.008058251813054085,
                0.06637337803840637,
                -0.04476781561970711,
                -0.020416537299752235,
                0.029994472861289978,
                0.038822583854198456,
                0.0112361591309309,
                1.9447108456915885e-07,
                -0.012015738524496555,
                0.06040680781006813,
                0.01628974638879299,
                0.0008883155533112586,
                -0.021265307441353798,
                -0.028310546651482582,
                -0.024101555347442627,
                0.004883662331849337,
                -0.016942378133535385,
                0.0405062660574913,
                -0.042482756078243256,
                -0.04843232035636902,
                -0.007249314337968826,
                -0.09740191698074341,
                0.012991215102374554,
                0.015810377895832062,
                -0.033390600234270096,
                -0.036486316472291946,
                -0.06327095627784729,
                -0.028736520558595657,
                -0.06104793772101402,
                0.027322892099618912,
                -0.007528604008257389,
                0.00313969305716455,
                0.002546883886680007,
                -0.009417249821126461,
                0.003625589655712247,
                -0.003808448323979974,
                0.06774545460939407,
                0.017890755087137222,
                0.06392165273427963,
                -0.03475435823202133,
                0.03822784125804901,
                0.016395745798945427,
                0.01445880625396967,
                -0.027891965582966805,
                0.037982627749443054,
                0.04733595997095108,
                0.007549798581749201,
                0.02457256428897381,
                -0.017016369849443436,
                0.017775684595108032,
                -0.021062573418021202,
                0.008078877814114094,
                -0.027509693056344986,
                0.026832768693566322,
                0.03325410559773445,
                -0.004721951205283403,
                -0.10462061315774918,
                0.012799547985196114,
                -0.0543094165623188,
                0.04536052048206329,
                -0.005756720434874296,
                -0.025452708825469017,
                0.014727412723004818,
                -0.003400108776986599,
                -0.02276146225631237,
                0.0517171286046505,
                0.04473608732223511,
                -0.07013723254203796,
                0.013991176150739193,
                -0.07073590159416199,
                -0.02733646146953106,
                -0.017657361924648285,
                0.0741780549287796,
                -0.07408779859542847,
                -0.07081428170204163,
                8.802321530176217e-35,
                -0.02363067865371704,
                -0.023443449288606644,
                -0.029309391975402832,
                -0.0031756788957864046,
                -0.009366524405777454,
                -0.035254284739494324,
                -0.0061879088170826435,
                0.04020863026380539,
                -0.0207283366471529,
                0.010897374711930752,
                -0.029387902468442917
            ]
        },
        "Experience": {
            "name": "A Senior Health, Safety and Environmental Professional, with over 30 years Oil, Gas and Renewable Energy industry experience as an HSE Manager, HSSE Director and HSEQ Vice President. \n\nProvided HSE expertise and support for multi-billion dollar energy projects from FEED and EPC to Commissioning and Start-up, including Offshore Platforms, FPSO’s, Gas Plants, Pipelines and Terminals.\n\nSet up HSE departments and led international teams across the UK, Europe, Middle East, Africa and Australia, including organisational design, recruitment, training, mentoring and succession planning.\n\nDeveloped HSEQ Policies, Management Systems and Training Programs to meet regulatory requirements and international standards, including ISO 9001, ISO 14001, ISO 45001, OSHA, GRI and World Bank IFC.\n\nCertified and Registered Safety Professional, Certified Risk Manager and Registered Safety Consultant. ",
            "rep": [
                -0.018533753231167793,
                -0.02810368686914444,
                0.009547255001962185,
                -0.03516901656985283,
                0.014887812547385693,
                0.0020032194443047047,
                0.01919887214899063,
                0.039831146597862244,
                0.014142846688628197,
                -0.03717638924717903,
                0.04158344864845276,
                -0.002255028812214732,
                -0.0009738585213199258,
                0.04321224242448807,
                0.061263926327228546,
                -0.02723836898803711,
                0.023382674902677536,
                -0.00246817315928638,
                -0.05843362957239151,
                0.020329976454377174,
                -0.023264408111572266,
                0.030894776806235313,
                -0.001476515899412334,
                0.01860964484512806,
                0.004088563844561577,
                0.04072357714176178,
                -0.00010078751802211627,
                0.016728924587368965,
                0.05906969681382179,
                -0.011388886719942093,
                0.07933836430311203,
                -0.02036144956946373,
                0.05513197183609009,
                -0.008808845654129982,
                2.3145603336161003e-06,
                0.00946599617600441,
                -0.02769632637500763,
                -0.008815299719572067,
                -0.004879479296505451,
                -0.004347989801317453,
                -0.028258776292204857,
                -0.037594933062791824,
                -0.04875485971570015,
                0.007239580620080233,
                0.013472912833094597,
                -0.0208424124866724,
                0.041924335062503815,
                -0.06819505244493484,
                0.03102118708193302,
                0.012944580055773258,
                -0.014616408385336399,
                0.04076360538601875,
                -0.09166120737791061,
                -0.022605378180742264,
                -0.04116735979914665,
                -0.0274900421500206,
                -0.020171033218503,
                0.008165613748133183,
                -0.02288557030260563,
                -0.0005019663949497044,
                0.009583648294210434,
                0.04350381717085838,
                -0.0007633154164068401,
                0.006064313929527998,
                0.029480906203389168,
                0.004371384158730507,
                -0.022108547389507294,
                0.026933914050459862,
                0.009812808595597744,
                0.047466833144426346,
                0.04260929301381111,
                -0.012266123667359352,
                0.010696263052523136,
                -0.0016729430062696338,
                -0.052817728370428085,
                -0.02806154638528824,
                0.016803734004497528,
                -0.007189861964434385,
                -0.01558288000524044,
                -0.03398873284459114,
                -0.01760374940931797,
                0.07966972887516022,
                -0.023861508816480637,
                -0.03373192623257637,
                0.04580580070614815,
                0.013711981475353241,
                -0.020803013816475868,
                -0.014171169139444828,
                0.025523869320750237,
                0.04968958720564842,
                0.04531789943575859,
                0.021374573931097984,
                -0.002498256042599678,
                -0.0027779657393693924,
                -0.013872806914150715,
                0.010019899345934391,
                0.09711345285177231,
                -0.005291416309773922,
                0.08295055478811264,
                -0.045205436646938324,
                -0.026822034269571304,
                0.026160938665270805,
                0.07454611361026764,
                0.016729874536395073,
                -0.019506823271512985,
                -0.01825476624071598,
                -0.017105165868997574,
                -0.006602585315704346,
                -0.021973054856061935,
                0.05114069953560829,
                0.002438623458147049,
                -0.009998731315135956,
                -0.010723275132477283,
                -0.05504678189754486,
                -0.032026972621679306,
                0.03334580734372139,
                -0.02923390083014965,
                -0.021059919148683548,
                0.009761443361639977,
                0.06709589064121246,
                0.04367085173726082,
                0.012141479179263115,
                -0.04185612127184868,
                0.02953379414975643,
                0.04211964085698128,
                0.036660127341747284,
                -0.02935020998120308,
                0.027082078158855438,
                0.014334317296743393,
                0.033591557294130325,
                -0.014149862341582775,
                0.020665504038333893,
                0.002441203687340021,
                -0.0011632404057309031,
                -0.023977264761924744,
                0.05076146870851517,
                -0.01603969931602478,
                -0.01405035238713026,
                -0.042127907276153564,
                -0.02012951299548149,
                0.04391166567802429,
                0.041453681886196136,
                0.012170091271400452,
                0.033643048256635666,
                -0.023644721135497093,
                -0.0419004000723362,
                0.005642562638968229,
                0.00538425100967288,
                -0.03585210070014,
                -0.027181852608919144,
                0.020783836022019386,
                -0.07280949503183365,
                -0.07447724044322968,
                -0.005466760601848364,
                0.07199908792972565,
                0.0452292300760746,
                -0.02431044727563858,
                -0.02644203044474125,
                0.016224330291152,
                -0.015376959927380085,
                0.0020693710539489985,
                -0.06073407083749771,
                -0.011134839616715908,
                0.038201186805963516,
                -0.05567659065127373,
                0.02050964906811714,
                0.01768617331981659,
                -0.026175465434789658,
                -0.020367100834846497,
                0.012807096354663372,
                -0.0005034506320953369,
                0.0020322962664067745,
                -0.03835234045982361,
                0.048003364354372025,
                -0.015181917697191238,
                -0.0551581010222435,
                -0.033849794417619705,
                -0.046817127615213394,
                0.03770133852958679,
                0.058835357427597046,
                0.006265540141612291,
                -0.10641620308160782,
                0.01606731489300728,
                -0.013729163445532322,
                0.03791802003979683,
                0.01569976471364498,
                -0.006164702121168375,
                0.044752538204193115,
                -0.007910442538559437,
                -0.013494438491761684,
                -0.012972069904208183,
                0.043724965304136276,
                -0.07344619929790497,
                0.017313815653324127,
                0.005068018566817045,
                0.019342878833413124,
                -0.05214827507734299,
                0.0032313289120793343,
                0.008285999298095703,
                -0.029336867853999138,
                -0.0012522670440375805,
                0.03862606734037399,
                -0.05547533929347992,
                0.06950928270816803,
                0.0011920616962015629,
                0.012414938770234585,
                -0.06562765687704086,
                -0.0775492712855339,
                -0.08011505752801895,
                -0.0444919727742672,
                -0.019816771149635315,
                0.008958891965448856,
                0.063054159283638,
                0.01034629438072443,
                -0.041549161076545715,
                -0.0034090038388967514,
                0.01950286515057087,
                0.003771112533286214,
                -0.03644803538918495,
                0.04670426994562149,
                -0.012712055817246437,
                0.05590272322297096,
                -0.049157727509737015,
                0.005196233280003071,
                -0.02207689732313156,
                -0.024193447083234787,
                -0.04699870944023132,
                0.02373667061328888,
                -0.03762006759643555,
                0.043947070837020874,
                -0.014840254560112953,
                0.006390848197042942,
                -0.0009023203165270388,
                -0.01868591643869877,
                -0.04690232872962952,
                0.0033207209780812263,
                0.008530811406672001,
                0.09249862283468246,
                -0.017630748450756073,
                -0.01831413246691227,
                -0.005028084851801395,
                -0.010115143842995167,
                0.026001956313848495,
                0.028476422652602196,
                -0.044614311307668686,
                0.022826263681054115,
                -0.05827019363641739,
                0.0009380325791426003,
                0.03077099286019802,
                0.002617104444652796,
                -0.04107372835278511,
                -0.0510534904897213,
                0.016881832852959633,
                0.05029530078172684,
                -0.045402705669403076,
                -0.06437794864177704,
                -0.021609514951705933,
                0.020467117428779602,
                -0.08016599714756012,
                -0.0034481235779821873,
                0.007457663770765066,
                -0.03918720409274101,
                -0.03927559033036232,
                -0.04436170309782028,
                0.009232076816260815,
                -0.009159996174275875,
                0.09392247349023819,
                -0.04372488707304001,
                -0.018237529322504997,
                -0.028590135276317596,
                0.04965442791581154,
                0.03094330243766308,
                0.0017860367661342025,
                -0.04023993760347366,
                -0.06015079468488693,
                0.02480240724980831,
                0.0077844965271651745,
                0.003890224965289235,
                -0.025872940197587013,
                0.032488226890563965,
                -0.017101071774959564,
                -0.005594574846327305,
                -0.04642120376229286,
                -0.038231585174798965,
                -0.026337608695030212,
                0.1124662309885025,
                0.04953407496213913,
                -0.0020687889773398638,
                -0.08914560824632645,
                0.07194605469703674,
                0.0011420859955251217,
                -0.03759470582008362,
                0.0314830057322979,
                -0.06279445439577103,
                -0.03943802788853645,
                0.017517687752842903,
                0.026903240010142326,
                0.012903912924230099,
                0.047795385122299194,
                0.03544413298368454,
                0.007573301903903484,
                0.0012513331603258848,
                -0.024772319942712784,
                0.01210972759872675,
                -0.0166917871683836,
                0.005167446564882994,
                0.0193928349763155,
                0.03363385051488876,
                -0.05143965035676956,
                -0.02219092659652233,
                -0.03077460452914238,
                0.030994541943073273,
                0.04934973642230034,
                0.023973315954208374,
                0.04351240396499634,
                -0.018252307549118996,
                0.0008983809384517372,
                -0.06865855306386948,
                0.02812739834189415,
                0.048538755625486374,
                0.07491976022720337,
                0.02618611603975296,
                -0.036032535135746,
                -0.004950325004756451,
                0.009435323067009449,
                -0.0270261038094759,
                -0.030633462592959404,
                -0.012736951932311058,
                0.011289581656455994,
                -0.08639068901538849,
                0.0250302292406559,
                0.010408521629869938,
                -0.024924742057919502,
                0.0033347357530146837,
                -0.05185886099934578,
                -0.042568981647491455,
                0.013870651833713055,
                -0.11981845647096634,
                -0.005614713300019503,
                0.005110120866447687,
                -0.017437322065234184,
                -0.028380531817674637,
                -0.023325731977820396,
                0.023129140958189964,
                -0.019594235345721245,
                -0.0006959413294680417,
                0.008079655468463898,
                0.02637128345668316,
                0.0001688817428657785,
                -0.0015849081100896,
                0.05094294250011444,
                0.07824268192052841,
                -0.0017263853223994374,
                0.0027255304157733917,
                -0.004712067078799009,
                0.011543606407940388,
                0.016637368127703667,
                -0.030299052596092224,
                0.061866603791713715,
                -0.018651271238923073,
                0.023809537291526794,
                0.002188259270042181,
                0.018434742465615273,
                -0.03170984610915184,
                -0.055720847100019455,
                0.015093402937054634,
                0.013205020688474178,
                -0.013772060163319111,
                -0.006086634937673807,
                0.01343868114054203,
                0.011228460818529129,
                -0.019079718738794327,
                -0.02563752792775631,
                -0.0333828441798687,
                -0.03882673382759094,
                0.04627757519483566,
                0.003994066268205643,
                0.04024208337068558,
                0.005566802807152271,
                -0.015731388702988625,
                0.0252836886793375,
                -0.00687258830294013,
                0.07397984713315964,
                -0.011259985156357288,
                0.01639644429087639,
                -0.014193523675203323,
                -0.009999963454902172,
                -0.002672851551324129,
                0.08479898422956467,
                0.0627397894859314,
                0.01448123436421156,
                -0.04478924721479416,
                -0.0008903207490220666,
                0.014226281084120274,
                0.01902182772755623,
                -0.036437030881643295,
                0.010425294749438763,
                -0.004057072103023529,
                0.0008923944551497698,
                -0.0006592548452317715,
                0.04982074722647667,
                0.009456132538616657,
                -0.04837244749069214,
                -0.01685522496700287,
                -0.03173253685235977,
                0.0038490931037813425,
                0.07451240718364716,
                -0.08055632561445236,
                0.027034273371100426,
                0.004423245787620544,
                0.00951042678207159,
                -0.012212521396577358,
                -0.012528584338724613,
                -0.0076407757587730885,
                -0.015836890786886215,
                -0.056981705129146576,
                -0.02369461953639984,
                0.006231058854609728,
                -0.0023895404301583767,
                -0.04948971047997475,
                0.03411982208490372,
                0.040737103670835495,
                0.03678963705897331,
                -0.04957452788949013,
                0.0142052685841918,
                -0.051960110664367676,
                0.0840049758553505,
                0.045877713710069656,
                -0.0159924179315567,
                -0.02404654212296009,
                -0.003982489928603172,
                -0.025296729058027267,
                0.006221542600542307,
                0.020874807611107826,
                0.00023199770657811314,
                0.025197265669703484,
                0.026952514424920082,
                -0.009012239053845406,
                -0.0007458535837940872,
                -0.010965241119265556,
                -0.0004428042157087475,
                0.01800132915377617,
                0.017463015392422676,
                0.007646872196346521,
                -0.019720299169421196,
                0.032825931906700134,
                0.023317214101552963,
                0.03093264251947403,
                0.0038538810331374407,
                0.018056660890579224,
                0.0264870785176754,
                -0.011526758782565594,
                0.02404596097767353,
                0.03551982715725899,
                0.04668695479631424,
                0.0284634567797184,
                -0.04301240295171738,
                0.010067814029753208,
                0.008887023665010929,
                0.0152161018922925,
                0.04127821698784828,
                0.03151260316371918,
                -0.038867827504873276,
                0.00014451798051595688,
                0.014539515599608421,
                -0.02145926095545292,
                0.015864161774516106,
                -0.05259344354271889,
                -0.05305895209312439,
                -0.05035143345594406,
                -0.0007514459430240095,
                -0.03839600831270218,
                -0.02097255177795887,
                0.02188217267394066,
                -0.012828581035137177,
                -0.02700152061879635,
                0.027646757662296295,
                -0.002104277955368161,
                -0.0007214974611997604,
                0.003910626284778118,
                0.011349238455295563,
                -0.026331275701522827,
                -0.004231011960655451,
                0.012014990672469139,
                0.009236283600330353,
                0.006006530951708555,
                0.04260982573032379,
                -0.0015306101413443685,
                -0.02251388505101204,
                0.045050643384456635,
                -0.013180077075958252,
                -0.0208041500300169,
                -0.007368665654212236,
                0.059819623827934265,
                0.033627670258283615,
                0.09091219305992126,
                -0.05801820382475853,
                0.06633862853050232,
                -0.02274499647319317,
                -0.05711003765463829,
                0.02846388705074787,
                0.020377887412905693,
                0.03688362240791321,
                -0.018645690754055977,
                -0.004125152714550495,
                -0.009301794692873955,
                0.02462645433843136,
                0.014178628101944923,
                -0.014792010188102722,
                -0.031762417405843735,
                -0.004314514808356762,
                0.04070445895195007,
                -0.04354848712682724,
                -0.04700668901205063,
                -0.006440531462430954,
                -0.034386977553367615,
                -0.029369909316301346,
                0.047739241272211075,
                -0.012738680467009544,
                -0.0009588339016772807,
                0.010182925499975681,
                -0.014239591546356678,
                -0.005655181128531694,
                -0.028489654883742332,
                0.004174993373453617,
                -0.026259729638695717,
                0.015777740627527237,
                0.014263688586652279,
                -0.02331547811627388,
                0.006633761338889599,
                0.059183575212955475,
                -0.03156217187643051,
                -0.012357158586382866,
                0.022771410644054413,
                0.01281533483415842,
                -0.04304727911949158,
                0.08590187877416611,
                0.018785791471600533,
                -0.013337274082005024,
                -0.03829270601272583,
                -0.0484483502805233,
                -0.011137459427118301,
                0.029573649168014526,
                0.028124963864684105,
                -0.013950717635452747,
                0.049549076706171036,
                0.01918898895382881,
                -0.01969509944319725,
                0.037315137684345245,
                -0.10496057569980621,
                0.04134577140212059,
                -0.05127629265189171,
                -0.0036773437168449163,
                0.02139657735824585,
                0.033351831138134,
                -6.570775383490546e-33,
                0.015582144260406494,
                -0.0436360239982605,
                0.016268229112029076,
                -0.020701609551906586,
                -0.0527007058262825,
                0.03420012444257736,
                -0.027670856565237045,
                0.006728281732648611,
                0.0032245037145912647,
                -0.0380779467523098,
                -0.018546294420957565,
                0.031137026846408844,
                0.015854770317673683,
                -0.0033013029024004936,
                0.05153217166662216,
                -0.008639054372906685,
                0.03058256395161152,
                -0.007311338558793068,
                -0.04776101931929588,
                -0.018205836415290833,
                0.02572835423052311,
                0.018848838284611702,
                0.07647159695625305,
                0.02823406085371971,
                0.021945947781205177,
                0.06858189404010773,
                -0.0148999048396945,
                0.02166493609547615,
                -0.016244472935795784,
                -0.007394353859126568,
                0.015866758301854134,
                -0.0055074444971978664,
                0.0015940101584419608,
                -0.012687042355537415,
                0.0032103476114571095,
                0.05190357565879822,
                -0.06967028230428696,
                0.017078947275877,
                -0.05655934289097786,
                -0.04239095002412796,
                -0.05778203532099724,
                -0.028319472447037697,
                0.08474866300821304,
                -0.03143804520368576,
                -0.03858710080385208,
                -0.067125603556633,
                -0.04365238547325134,
                0.022890528663992882,
                0.03435301408171654,
                -0.07117564976215363,
                -0.002436860464513302,
                -0.023282285779714584,
                -0.028086602687835693,
                0.0841888040304184,
                -0.05218127369880676,
                0.039987482130527496,
                0.03687506541609764,
                -0.008025852963328362,
                -0.03394075855612755,
                -0.028148336336016655,
                -0.020461486652493477,
                -0.05457746982574463,
                0.05697876587510109,
                -0.019765151664614677,
                -0.04199839010834694,
                0.013843764550983906,
                -0.0756070613861084,
                0.08116328716278076,
                0.0018056025728583336,
                -0.0017151148058474064,
                0.009995666332542896,
                0.01965390145778656,
                -0.053152743726968765,
                -0.025536881759762764,
                -0.10242599248886108,
                -0.001021521631628275,
                -0.0992899239063263,
                0.0421043261885643,
                -0.02259720489382744,
                0.059985384345054626,
                -0.0202571339905262,
                -0.004529847297817469,
                -0.0024351777974516153,
                -0.0037230250891298056,
                -0.03289494290947914,
                0.030460888519883156,
                0.016109434887766838,
                0.0150904580950737,
                -0.021527644246816635,
                -0.005616532638669014,
                -0.015932166948914528,
                0.06705963611602783,
                -0.030872400850057602,
                0.035394757986068726,
                0.13978467881679535,
                -0.00965722743421793,
                0.043322015553712845,
                -0.05445428937673569,
                0.027056613937020302,
                0.056009359657764435,
                0.007119771093130112,
                0.0873931348323822,
                -0.013391675427556038,
                0.009924006648361683,
                -0.009625247679650784,
                -0.039289481937885284,
                -0.04663937911391258,
                -0.021863345056772232,
                0.014990107156336308,
                0.03348110616207123,
                0.04215199500322342,
                -0.002329470356926322,
                0.0049676173366606236,
                -0.035507846623659134,
                -0.015751630067825317,
                -0.03418530523777008,
                0.03572904318571091,
                0.0018813402857631445,
                -0.012107978574931622,
                0.014110158197581768,
                0.037740308791399,
                0.029011249542236328,
                -0.04079964756965637,
                0.045187048614025116,
                0.013560923747718334,
                -0.013193967752158642,
                0.01875283569097519,
                -0.04465271160006523,
                0.004710635170340538,
                0.006101368926465511,
                0.009345696307718754,
                0.030492151156067848,
                2.855324794381886e-07,
                -0.013606012798845768,
                0.00800322089344263,
                0.037305060774087906,
                -0.0054234969429671764,
                0.03951707482337952,
                -0.0020579418633133173,
                -0.022877098992466927,
                0.0315973274409771,
                -0.04691734164953232,
                0.019116559997200966,
                0.03772854432463646,
                -0.009705029428005219,
                -0.0475471168756485,
                -0.04368041083216667,
                0.0017795008607208729,
                -0.12627404928207397,
                0.044193558394908905,
                -0.02765609323978424,
                -0.02915078029036522,
                -0.026905082166194916,
                0.050958968698978424,
                -0.033045828342437744,
                -0.025740597397089005,
                -0.02676801010966301,
                0.010879207402467728,
                0.01345103606581688,
                0.02629685401916504,
                -0.06169695407152176,
                0.017336875200271606,
                0.044311877340078354,
                -0.004060659091919661,
                0.009495696052908897,
                0.034085825085639954,
                0.044528260827064514,
                7.479243504349142e-05,
                -0.053483784198760986,
                0.024588080123066902,
                0.0551755391061306,
                0.0162919033318758,
                0.0002693949791137129,
                -0.021861882880330086,
                -0.03883486986160278,
                0.041274793446063995,
                -0.026233753189444542,
                -0.026872988790273666,
                -0.029461607336997986,
                0.003793813521042466,
                0.07591426372528076,
                -0.12040545046329498,
                0.0057951705530285835,
                -0.0033634493593126535,
                0.027449555695056915,
                0.004711891524493694,
                -0.02940996177494526,
                0.008005933836102486,
                0.014707296155393124,
                -0.013393750414252281,
                0.05857987701892853,
                0.0848311260342598,
                0.035507120192050934,
                0.03274182230234146,
                -0.042982425540685654,
                -0.004626485984772444,
                0.027847856283187866,
                -0.015859773382544518,
                -0.006243967916816473,
                -0.006583440117537975,
                2.883971615631824e-34,
                -0.0480293370783329,
                -0.04674135893583298,
                -0.03690653294324875,
                0.005315286573022604,
                0.038093969225883484,
                -0.02480190061032772,
                -0.024084441363811493,
                -0.0033862523268908262,
                0.018036283552646637,
                -0.018168123438954353,
                -0.030793234705924988
            ]
        },
        "Freelance": "Freelance",
        "Id": "0a8ed1dd-5c8f-4132-8cea-42d2949ab209",
        "Industry": {
            "name": "\tHSE",
            "rep": [
                -0.03685125708580017,
                -0.01657501608133316,
                0.003314658999443054,
                -0.07932716608047485,
                0.008194558322429657,
                0.031215304508805275,
                0.0003529594396241009,
                0.049774862825870514,
                0.06881198287010193,
                -0.021201174706220627,
                0.034332290291786194,
                -0.04272540658712387,
                -0.009486406110227108,
                0.053008876740932465,
                0.009733002632856369,
                -0.011781317181885242,
                -0.014540028758347034,
                0.0419248603284359,
                -0.09979690611362457,
                -0.05908474326133728,
                -0.06343039870262146,
                -0.0021985541097819805,
                0.007416986394673586,
                0.016077732667326927,
                0.053343113511800766,
                -0.020044637843966484,
                -0.004571474622935057,
                0.03848188742995262,
                0.010049867443740368,
                0.017328554764389992,
                0.02129318378865719,
                0.016213662922382355,
                0.04244451969861984,
                0.005176773760467768,
                2.0508728084678296e-06,
                -0.05023736506700516,
                -0.014448721893131733,
                -0.017635855823755264,
                -0.05302052199840546,
                0.027526816353201866,
                -0.00045846306602470577,
                -0.01301429234445095,
                -0.06011107563972473,
                -0.04578414931893349,
                0.019643252715468407,
                0.01598517782986164,
                0.03720696270465851,
                -0.08054251223802567,
                -0.01806720532476902,
                0.01781182922422886,
                0.005921225994825363,
                0.002345501910895109,
                -0.0859333947300911,
                -0.047574594616889954,
                0.049122318625450134,
                0.00417691795155406,
                0.0346580445766449,
                -0.01360210869461298,
                -0.037392955273389816,
                0.04845576733350754,
                -0.01744878850877285,
                0.011655253358185291,
                0.0068984427489340305,
                0.014126528054475784,
                0.030006660148501396,
                0.014358499087393284,
                0.05852549150586128,
                0.06005492061376572,
                0.006283771712332964,
                0.0337834395468235,
                0.00847979448735714,
                0.008189388550817966,
                0.02546057105064392,
                0.07645092904567719,
                -0.08149553090333939,
                0.053298644721508026,
                0.029787158593535423,
                -0.013233895413577557,
                0.041894715279340744,
                -0.013745737262070179,
                0.03614375367760658,
                0.03497995063662529,
                -0.021407874301075935,
                0.04741082713007927,
                0.06522899121046066,
                -0.04334568604826927,
                0.04029965028166771,
                -0.025037720799446106,
                0.006739766336977482,
                0.025639913976192474,
                0.058155521750450134,
                0.011697249487042427,
                -0.02022063359618187,
                0.0376497246325016,
                0.008689581416547298,
                -0.009797167032957077,
                0.05613671988248825,
                -0.0020110642071813345,
                0.005759708117693663,
                -0.03499922156333923,
                -0.05434751883149147,
                0.053160715848207474,
                0.03691635653376579,
                0.02721245028078556,
                -0.018649259582161903,
                -0.009115146473050117,
                0.0031842361204326153,
                -0.028915459290146828,
                0.04424672946333885,
                0.05836746469140053,
                0.0022822332102805376,
                0.02241927571594715,
                -0.00294851534999907,
                0.0023822165094316006,
                -0.03406437858939171,
                -0.03968391567468643,
                -0.08272931724786758,
                -0.051064878702163696,
                0.02427133359014988,
                0.054543279111385345,
                0.05930677428841591,
                0.02924846112728119,
                -0.05288175866007805,
                0.018463486805558205,
                0.011536616832017899,
                0.05457199737429619,
                -0.06434034556150436,
                -0.03381482884287834,
                0.05298803746700287,
                0.06667397171258926,
                -0.020712506026029587,
                -0.0028974812012165785,
                0.024423526600003242,
                -0.017031995579600334,
                -0.0006800033152103424,
                0.034394603222608566,
                -0.022414501756429672,
                0.01962115429341793,
                -0.02179063856601715,
                0.001119128311984241,
                0.011077017523348331,
                -0.016020679846405983,
                0.016928518190979958,
                -0.000526859366800636,
                0.009035457856953144,
                -0.051925692707300186,
                0.026032941415905952,
                -0.026261793449521065,
                -0.0279846228659153,
                -0.04606979340314865,
                0.023054255172610283,
                -0.03609849885106087,
                -0.04772422835230827,
                0.0008687516092322767,
                0.09490667283535004,
                0.027759741991758347,
                -0.022819919511675835,
                -0.008776867762207985,
                -0.041007593274116516,
                0.015276465564966202,
                0.03868231177330017,
                -0.061055563390254974,
                -0.00535820797085762,
                0.06716826558113098,
                -0.03072492964565754,
                0.016085583716630936,
                -0.020954083651304245,
                -0.04445428028702736,
                -0.04261691868305206,
                -0.02422439120709896,
                -0.053983092308044434,
                0.012899758294224739,
                -0.018810251727700233,
                -0.003841618774458766,
                -0.014876110479235649,
                0.10533250868320465,
                -0.06264746189117432,
                -0.032226599752902985,
                0.00411494355648756,
                0.03012348897755146,
                0.008455021306872368,
                -0.11496981978416443,
                0.02057122252881527,
                -0.003961794078350067,
                -0.036941833794116974,
                0.03975575417280197,
                -0.009650425054132938,
                -0.01957075670361519,
                -0.002018721541389823,
                0.007646224927157164,
                -0.006599840242415667,
                -0.018569808453321457,
                -0.11304377019405365,
                0.028772978112101555,
                0.04482211545109749,
                -0.026141973212361336,
                0.01826297864317894,
                0.030839627608656883,
                0.0609561912715435,
                -0.03779829293489456,
                -0.03322606906294823,
                0.029899291694164276,
                0.009741021320223808,
                0.12083198875188828,
                -0.04547933116555214,
                0.06424146145582199,
                0.010034061968326569,
                -0.03346625715494156,
                -0.10449231415987015,
                0.02890401892364025,
                -0.017898457124829292,
                0.0074194082990288734,
                0.06207598000764847,
                0.004061626270413399,
                -0.014097385108470917,
                -0.0014041990507394075,
                0.031052980571985245,
                -0.022386906668543816,
                -0.042861949652433395,
                0.006362789310514927,
                0.010996024124324322,
                0.06080808490514755,
                -0.024130698293447495,
                -0.02620464563369751,
                -0.022796805948019028,
                0.03667369857430458,
                0.0025993145536631346,
                0.026451319456100464,
                -0.02241837978363037,
                -0.0011822498636320233,
                -0.0035132230259478092,
                -0.0159650519490242,
                -0.025551702827215195,
                0.010270954109728336,
                -0.021882349625229836,
                -0.027589930221438408,
                0.03832511603832245,
                -0.02828664891421795,
                -0.004409033339470625,
                -0.024773459881544113,
                -0.03245682641863823,
                0.024719974026083946,
                0.02447105199098587,
                -0.0073642670176923275,
                0.020662041381001472,
                -0.006280495319515467,
                -0.028368132188916206,
                -0.0010135131888091564,
                0.04210059344768524,
                -0.027261773124337196,
                -0.04116883873939514,
                -0.06944476068019867,
                0.055953249335289,
                0.012194007635116577,
                -0.0009639711934141815,
                -0.05441855639219284,
                -0.01644933968782425,
                0.03143205866217613,
                -0.06711354851722717,
                -0.05244152247905731,
                0.011629830114543438,
                -0.013956636190414429,
                -0.03160460293292999,
                -0.00983649492263794,
                0.020093917846679688,
                -0.027014058083295822,
                0.053573474287986755,
                -0.026348846033215523,
                -0.009582108817994595,
                -0.009560828097164631,
                -0.00031194344046525657,
                0.06992793083190918,
                0.031904835253953934,
                -0.022596081718802452,
                -0.03892432898283005,
                0.02100004442036152,
                0.010827349498867989,
                0.00598536292091012,
                -0.030110666528344154,
                -0.007403758820146322,
                -0.03696449473500252,
                0.054421767592430115,
                -0.03748113662004471,
                -0.04253160208463669,
                0.006563362665474415,
                0.05068180710077286,
                -0.02207084186375141,
                -0.017353493720293045,
                -0.003040001727640629,
                0.062376003712415695,
                0.0069121792912483215,
                -0.01717907376587391,
                0.05812815949320793,
                -0.03435572609305382,
                0.00025295844534412026,
                -0.0161474347114563,
                -0.008874270133674145,
                0.049952130764722824,
                0.03798952326178551,
                0.0006799320108257234,
                0.004669926129281521,
                -0.029815256595611572,
                -0.029083266854286194,
                -0.02759464457631111,
                -0.052015043795108795,
                0.017839554697275162,
                -0.0022434054408222437,
                -0.013496818020939827,
                -0.05804898589849472,
                0.027194427326321602,
                -0.010887043550610542,
                0.03708073869347572,
                0.005994778126478195,
                -0.0027392662595957518,
                0.04231886938214302,
                0.013252545148134232,
                -0.018126297742128372,
                -0.0033896109089255333,
                0.013300508260726929,
                0.00985020212829113,
                0.025406090542674065,
                0.0577717125415802,
                0.024337591603398323,
                -0.008141852915287018,
                0.0074375588446855545,
                0.01877426914870739,
                -0.014698564074933529,
                -0.004904686473309994,
                -0.08238186687231064,
                -0.0762549340724945,
                0.005705391522496939,
                -0.027688078582286835,
                -0.015485993586480618,
                0.03154872730374336,
                -0.008182735182344913,
                -0.05432841554284096,
                0.00230246945284307,
                -0.06343507021665573,
                0.033013951033353806,
                -0.022082271054387093,
                0.0016432648990303278,
                -0.014443268068134785,
                -0.005600922740995884,
                0.032873302698135376,
                -0.05359342321753502,
                -0.024783750995993614,
                0.004780496004968882,
                0.04124421998858452,
                0.013620979152619839,
                -0.038212791085243225,
                -0.04325305297970772,
                0.07302837818861008,
                0.0009343738784082234,
                -0.019968515262007713,
                -0.02443954534828663,
                0.024621648713946342,
                0.07714539021253586,
                0.003355527762323618,
                -0.034779056906700134,
                -0.025089286267757416,
                0.0389857217669487,
                0.01774459518492222,
                0.06639578938484192,
                -0.018561286851763725,
                0.02287350222468376,
                0.037205617874860764,
                0.060926653444767,
                -0.03833305835723877,
                0.01759009249508381,
                0.047838930040597916,
                -0.010569619946181774,
                0.0010318218264728785,
                -0.03170333057641983,
                0.10788894444704056,
                -0.02164539135992527,
                -0.000545381277333945,
                0.01828945428133011,
                -0.006042133085429668,
                0.05628438666462898,
                0.007167545147240162,
                0.010313427075743675,
                -0.05648284777998924,
                0.010233794339001179,
                -0.02125047706067562,
                0.019328806549310684,
                -0.045938845723867416,
                -0.03919787332415581,
                0.03884366154670715,
                -0.032656364142894745,
                -0.006518029607832432,
                0.03181646391749382,
                -0.04767889156937599,
                0.03274351358413696,
                0.06402094662189484,
                0.007116675842553377,
                -0.007339539006352425,
                0.005725801922380924,
                -0.010834027081727982,
                0.01268639788031578,
                0.018278930336236954,
                0.007444597315043211,
                0.0022697162348777056,
                0.021906187757849693,
                0.037823550403118134,
                -0.028892122209072113,
                -0.044468119740486145,
                0.010067545808851719,
                -0.054135408252477646,
                0.00822718720883131,
                -0.07501734048128128,
                -0.006321273278445005,
                0.02992868423461914,
                -0.005669264122843742,
                -0.011866016313433647,
                -0.007538811303675175,
                -0.11492093652486801,
                -0.019823038950562477,
                -0.0007617110968567431,
                0.05133528262376785,
                -0.06644307076931,
                0.07207271456718445,
                0.032823629677295685,
                0.03604476898908615,
                0.004830424673855305,
                0.039755742996931076,
                0.00655582919716835,
                0.061099033802747726,
                -0.0038201562128961086,
                0.011237683705985546,
                0.008282584138214588,
                -0.018811726942658424,
                0.010024708695709705,
                -0.03382425010204315,
                0.03365083783864975,
                0.053641725331544876,
                -0.024829426780343056,
                0.04708762466907501,
                0.02689392678439617,
                -0.004411522764712572,
                -0.002805946161970496,
                -0.01821073517203331,
                0.000906671688426286,
                -0.021565616130828857,
                -0.021145423874258995,
                -0.01079342421144247,
                0.04408646002411842,
                0.0050343796610832214,
                0.008884888142347336,
                -0.008963328786194324,
                -0.01429791934788227,
                -0.0197148434817791,
                -0.04333818703889847,
                -0.0028520275373011827,
                0.0062950062565505505,
                0.039439789950847626,
                -0.03443879261612892,
                -0.04807544872164726,
                0.0541766956448555,
                0.018029341474175453,
                -0.0030368389561772346,
                0.005180715583264828,
                0.024708708748221397,
                0.01844296045601368,
                -0.0106425816193223,
                0.01816195249557495,
                -0.07391250878572464,
                -0.01754738949239254,
                -0.03087136149406433,
                -0.04914958402514458,
                -0.04098249226808548,
                -0.06553101539611816,
                -0.030156245455145836,
                0.004806310869753361,
                0.059374988079071045,
                0.024992771446704865,
                0.029562192037701607,
                0.004892531782388687,
                0.002988729625940323,
                0.020644094794988632,
                0.02947092615067959,
                0.024829749017953873,
                0.06724236160516739,
                -0.035025905817747116,
                -0.022214801982045174,
                0.012646673247218132,
                0.035953257232904434,
                -0.02566155418753624,
                -0.035464201122522354,
                0.017658639699220657,
                0.0072099887765944,
                0.00983349233865738,
                -0.0006778680835850537,
                -0.0007978875655680895,
                0.01766493357717991,
                0.01637873612344265,
                0.06932146102190018,
                -0.06518493592739105,
                0.0017691974062472582,
                -0.06176212430000305,
                -0.006694388575851917,
                0.021058738231658936,
                0.004857403226196766,
                0.027737461030483246,
                0.019312793388962746,
                0.009624680504202843,
                0.0009779245592653751,
                -0.029751785099506378,
                0.05346846580505371,
                -0.0071416269056499004,
                -0.04379104822874069,
                0.07299738377332687,
                0.0030784299597144127,
                -0.016055390238761902,
                0.01401692908257246,
                -0.027254413813352585,
                -0.029963256791234016,
                -0.007601976860314608,
                -0.001735934754833579,
                -0.005612875800579786,
                0.015120056457817554,
                -0.022046642377972603,
                -0.028394391760230064,
                0.008324217982590199,
                -0.005196384619921446,
                -0.02510753646492958,
                -0.01630508340895176,
                0.008610543794929981,
                -0.02218102663755417,
                0.04571254923939705,
                0.018089033663272858,
                0.015099340118467808,
                -0.0059597305953502655,
                0.03625042736530304,
                -0.02103201299905777,
                -0.00770405400544405,
                -0.005735128186643124,
                0.05120404437184334,
                0.01414494588971138,
                -0.003703110618516803,
                -0.022342907264828682,
                -0.03523479402065277,
                -0.027726979926228523,
                -0.021128995344042778,
                0.008483268320560455,
                -0.00808438565582037,
                0.05614004656672478,
                0.0143426563590765,
                0.0223250575363636,
                -0.04707222431898117,
                -0.024903034791350365,
                0.008183161728084087,
                0.03301595523953438,
                -0.0003470101801212877,
                0.030104774981737137,
                0.003457611659541726,
                -7.198302757101454e-33,
                0.026321059092879295,
                -0.039965223520994186,
                0.00010492283763596788,
                -0.08323731273412704,
                -0.03443983569741249,
                0.006994680501520634,
                -0.043240055441856384,
                0.0385698638856411,
                0.012967739254236221,
                -0.009518982842564583,
                0.008800892159342766,
                -0.022940952330827713,
                0.03171882405877113,
                0.057188574224710464,
                0.04681925103068352,
                -0.010930481366813183,
                0.009966114535927773,
                -0.009954015724360943,
                -0.046469200402498245,
                -0.02375009097158909,
                -0.01165890321135521,
                0.03406380116939545,
                0.03161436319351196,
                0.08386724442243576,
                -0.03223445266485214,
                0.03148708492517471,
                0.006678428500890732,
                -0.015097795985639095,
                -0.014930112287402153,
                -0.01326088048517704,
                -0.008642896078526974,
                0.034626610577106476,
                -0.029329175129532814,
                -0.012024247087538242,
                0.05064896494150162,
                0.016455166041851044,
                -0.03199014812707901,
                -0.00445997528731823,
                -0.08030718564987183,
                -0.032795943319797516,
                -0.03723868727684021,
                -0.04188859462738037,
                0.051887817680835724,
                -0.03629254549741745,
                -0.0839151069521904,
                -0.05964106321334839,
                -0.031754788011312485,
                0.000995315727777779,
                -0.00614954624325037,
                -0.05331378057599068,
                -0.05468224734067917,
                0.01710367389023304,
                -0.03329242020845413,
                0.05925020948052406,
                -0.030206430703401566,
                -0.025399282574653625,
                0.036359455436468124,
                -0.010465668514370918,
                0.006581807509064674,
                -0.030394740402698517,
                -0.0010257611284032464,
                -0.05213318020105362,
                0.04244549572467804,
                -0.06785944104194641,
                -0.032158657908439636,
                0.021071987226605415,
                -0.017228057608008385,
                0.04164402186870575,
                -8.147917833412066e-05,
                0.03304961696267128,
                0.04386548325419426,
                -0.003874558489769697,
                -0.028340822085738182,
                -0.08277396857738495,
                -0.06891737878322601,
                -0.006280460394918919,
                -0.03492097556591034,
                0.01642276905477047,
                -0.008953026495873928,
                0.030736874788999557,
                -0.069803886115551,
                0.024223165586590767,
                -0.02906348742544651,
                -0.0016350731020793319,
                -0.045761290937662125,
                0.06206997111439705,
                0.002138972282409668,
                -0.034103672951459885,
                -0.011472931131720543,
                -0.03186270594596863,
                -0.03458184748888016,
                0.1318308711051941,
                -0.005469546187669039,
                0.020656771957874298,
                0.05114947631955147,
                -0.0056325513869524,
                0.032741256058216095,
                -0.013345634564757347,
                0.00798998773097992,
                0.006110830698162317,
                -0.013933693990111351,
                0.062261104583740234,
                0.014869051054120064,
                0.03743664547801018,
                -0.05179344490170479,
                -0.016231928020715714,
                -0.047113656997680664,
                -0.03595306724309921,
                -0.03796287253499031,
                0.03782160207629204,
                0.032112304121255875,
                -0.044014882296323776,
                0.028728174045681953,
                -0.006056183949112892,
                0.033601902425289154,
                -0.004482136107981205,
                0.029917562380433083,
                0.00491274893283844,
                -0.023696336895227432,
                0.0063455309718847275,
                0.011926512233912945,
                0.06025778502225876,
                -0.011152447201311588,
                0.03028537705540657,
                -0.009470597840845585,
                0.02697419747710228,
                0.03443237021565437,
                -0.03490692749619484,
                0.0007484653033316135,
                -0.006377083249390125,
                0.03656454011797905,
                0.019060855731368065,
                2.600141897346475e-07,
                -0.03258039802312851,
                0.012365873903036118,
                0.028313010931015015,
                -0.00453123589977622,
                0.01943942904472351,
                0.015588072128593922,
                0.013507279567420483,
                0.027532530948519707,
                -0.009259370155632496,
                0.022923247888684273,
                -0.005744289141148329,
                -0.023823285475373268,
                0.03897255286574364,
                -0.07083597034215927,
                0.04554566368460655,
                -0.13668283820152283,
                -0.01683257892727852,
                -0.030447278171777725,
                -0.013288713060319424,
                0.04146161675453186,
                -0.03603033721446991,
                -0.01695653796195984,
                -0.014065686613321304,
                -0.04629897698760033,
                0.012997078709304333,
                0.0841071605682373,
                0.014349095523357391,
                -0.02182966098189354,
                -0.00956354197114706,
                0.05968823656439781,
                -0.09403406083583832,
                0.016209129244089127,
                0.0497027188539505,
                0.04831983521580696,
                -0.00697699747979641,
                -0.03498224541544914,
                0.07676232606172562,
                0.02440491132438183,
                0.01480394508689642,
                0.00753513490781188,
                -0.039436306804418564,
                -0.005179176572710276,
                0.007637327536940575,
                -0.007169374730437994,
                0.014266245067119598,
                -0.05045629292726517,
                -0.03455546870827675,
                0.04642457142472267,
                -0.10824178904294968,
                0.014740199781954288,
                -0.04486421123147011,
                -0.014080184511840343,
                0.014739513397216797,
                0.015115943737328053,
                0.03715788945555687,
                0.014642827212810516,
                0.04079792648553848,
                0.0182964950799942,
                0.05746523290872574,
                0.013381506316363811,
                0.016499478369951248,
                -0.03810867667198181,
                -0.00021311388991307467,
                0.011169617995619774,
                0.035165149718523026,
                -0.01107279397547245,
                -0.027961039915680885,
                1.8434475491298255e-34,
                -0.0029062938410788774,
                -0.004879959858953953,
                -0.0007389443344436586,
                0.026091033592820168,
                0.018017955124378204,
                -0.03408228978514671,
                0.0326843187212944,
                -0.06149273365736008,
                0.00037541124038398266,
                -0.0020012876484543085,
                -0.0341515988111496
            ]
        },
        "Location": "Worldwide",
        "Remuneration": 143.75,
        "Services Required": {
            "name": "Health and Safety Consultant | HSE Director | HSEQ Vice President ",
            "rep": [
                -0.0025459607131779194,
                0.025027411058545113,
                -0.006503182929009199,
                -0.01784067042171955,
                0.026184359565377235,
                0.0025714898947626352,
                0.039273813366889954,
                0.049917250871658325,
                0.0529620423913002,
                -0.03476680442690849,
                0.019813988357782364,
                -0.006132824812084436,
                -0.011914991773664951,
                0.039634134620428085,
                0.03179483488202095,
                -0.01911800540983677,
                -0.010843897238373756,
                0.04102155938744545,
                -0.05654653534293175,
                -0.011530083604156971,
                -0.020008103922009468,
                0.02247384563088417,
                -0.03555857017636299,
                0.052788201719522476,
                -0.018799101933836937,
                0.038267508149147034,
                -0.0024450374767184258,
                0.046672750264406204,
                0.0779583677649498,
                0.022974546998739243,
                0.05622094124555588,
                -0.012238516472280025,
                0.01580370031297207,
                -0.022879457101225853,
                1.7869588191388175e-06,
                0.018733713775873184,
                -0.015657668933272362,
                0.015183579176664352,
                -0.0323181189596653,
                -0.03623554855585098,
                -0.02298000268638134,
                -0.04725267365574837,
                -0.05581117421388626,
                0.02191854640841484,
                0.029195528477430344,
                0.0009253690368495882,
                0.05315714702010155,
                -0.03299637883901596,
                0.013571019284427166,
                0.012212618254125118,
                -0.01849929429590702,
                0.03970674052834511,
                -0.07200468331575394,
                -0.04379057511687279,
                -0.01669429801404476,
                -0.02304815500974655,
                0.018753085285425186,
                0.009296738542616367,
                0.02217463217675686,
                0.019344519823789597,
                -0.005359862931072712,
                -0.004474482499063015,
                -0.003472647164016962,
                0.00746217742562294,
                0.04275187477469444,
                0.03193202242255211,
                0.04194403067231178,
                0.03422646224498749,
                0.025763671845197678,
                0.05370086804032326,
                0.07382229715585709,
                -0.0044714841060340405,
                0.004260264802724123,
                0.06124912574887276,
                -0.029252290725708008,
                0.040134817361831665,
                0.0544605478644371,
                -0.057719215750694275,
                0.007726144976913929,
                -0.03535130247473717,
                -0.031502947211265564,
                0.05461481958627701,
                -0.017581576481461525,
                -0.04582591727375984,
                0.08909809589385986,
                -0.03105897828936577,
                0.006471023894846439,
                -0.0348508246243,
                -0.013515730388462543,
                0.0033689283300191164,
                0.09047213196754456,
                0.018383264541625977,
                0.026698756963014603,
                0.02644488401710987,
                -0.028544778004288673,
                0.0005784377572126687,
                0.054096490144729614,
                -0.05802334100008011,
                0.05254267156124115,
                -0.020060734823346138,
                -0.03104945831000805,
                0.03947938606142998,
                0.0038638352416455746,
                0.00954361166805029,
                0.020934920758008957,
                -0.005539354868233204,
                -0.0023710147943347692,
                0.014598941430449486,
                -0.004963154438883066,
                0.046183519065380096,
                0.014814233407378197,
                0.01157089602202177,
                0.012829028069972992,
                -0.041541680693626404,
                -0.03335481137037277,
                0.030893415212631226,
                -0.08042129129171371,
                -0.013882342725992203,
                0.00997400563210249,
                0.05687132477760315,
                0.06690403074026108,
                0.020984210073947906,
                -0.044796284288167953,
                0.03532678261399269,
                -0.0036632088012993336,
                0.015576403588056564,
                -0.038866810500621796,
                0.014171617105603218,
                0.008792294189333916,
                0.0369473472237587,
                -0.0169531162828207,
                0.010162721388041973,
                0.0047216871753335,
                -0.010817481204867363,
                -0.023134876042604446,
                0.06949011981487274,
                -0.03982476890087128,
                -0.004966474138200283,
                -0.018721260130405426,
                -0.015227273106575012,
                -0.0064420392736792564,
                0.01832415908575058,
                -0.007684731390327215,
                0.02598971500992775,
                -0.024565039202570915,
                -0.04872306063771248,
                -0.02122434787452221,
                -0.01062963530421257,
                -0.00851720292121172,
                -0.027547789737582207,
                -0.038314927369356155,
                -0.03227319195866585,
                -0.029527459293603897,
                0.0021881030406802893,
                0.10829158127307892,
                0.03631870821118355,
                0.007694757543504238,
                -0.008624879643321037,
                -0.05654836818575859,
                0.002383643528446555,
                -0.004065150395035744,
                -0.03391830250620842,
                -0.03131319209933281,
                0.024959802627563477,
                -0.0394180603325367,
                0.019002001732587814,
                0.009296687319874763,
                -0.028298666700720787,
                -0.048821672797203064,
                0.007931442931294441,
                -0.04005669057369232,
                0.054133038967847824,
                -0.03885331004858017,
                0.06908564269542694,
                -0.007357825990766287,
                0.005376254208385944,
                -0.01735181361436844,
                -0.06302814185619354,
                0.03974488750100136,
                0.0213903971016407,
                -0.016674989834427834,
                -0.12404797226190567,
                -0.02467666007578373,
                -0.014165696687996387,
                0.03591489419341087,
                0.007184100802987814,
                -0.016655966639518738,
                0.010163647122681141,
                -0.019380196928977966,
                0.005898164585232735,
                -0.0015570204704999924,
                0.018385518342256546,
                -0.06954915076494217,
                0.040266092866659164,
                0.026119941845536232,
                -0.0031456181313842535,
                -0.05900876596570015,
                -0.009123539552092552,
                0.018351582810282707,
                -0.01876193843781948,
                -0.0168612040579319,
                0.02856176719069481,
                -0.043060339987277985,
                0.12565839290618896,
                -0.03256050869822502,
                -0.007550382055342197,
                -0.03738459572196007,
                -0.05717357248067856,
                -0.05189483240246773,
                0.005201836582273245,
                -0.015071336179971695,
                0.007435981649905443,
                0.040500689297914505,
                0.026289964094758034,
                -0.024378666654229164,
                0.018304593861103058,
                0.06693427264690399,
                0.0016971667064353824,
                0.0034257385414093733,
                0.05898216739296913,
                0.0005747446557506919,
                0.0549507699906826,
                -0.026757212355732918,
                0.004839682020246983,
                -0.0564325675368309,
                -0.019389448687434196,
                -0.013805429451167583,
                0.003964567556977272,
                -0.010008091107010841,
                0.026221109554171562,
                -0.0002555070095695555,
                0.043201882392168045,
                -0.00864351075142622,
                -0.007291959598660469,
                -0.011453711427748203,
                0.002558881649747491,
                0.0574037991464138,
                0.021051879972219467,
                0.008735326118767262,
                -0.016875306144356728,
                -0.0015037835109978914,
                0.003872301895171404,
                0.00794829148799181,
                0.0240668635815382,
                -0.013382790610194206,
                0.029608773067593575,
                -0.04056353121995926,
                -0.01594766043126583,
                0.021280445158481598,
                0.031860750168561935,
                -0.0711415559053421,
                -0.03179565444588661,
                0.029760073870420456,
                0.03143414482474327,
                -0.041399676352739334,
                -0.030802123248577118,
                -0.04850218445062637,
                0.011274575255811214,
                -0.07156676054000854,
                0.013228092342615128,
                -0.05786653235554695,
                -0.014625906012952328,
                -0.006886596325784922,
                -0.04805653169751167,
                0.029504796490073204,
                -0.01750044897198677,
                0.04861780256032944,
                -0.046569980680942535,
                -0.04453093931078911,
                -0.028827518224716187,
                -0.005104796960949898,
                0.039802443236112595,
                0.020072096958756447,
                -0.013286902569234371,
                -0.04227549955248833,
                0.013811948709189892,
                -0.023216210305690765,
                0.0048675742000341415,
                -0.02302386984229088,
                0.025183487683534622,
                -0.0026544693391770124,
                0.009770628996193409,
                -0.02968643046915531,
                -0.022938339039683342,
                -0.01862267218530178,
                0.03101390041410923,
                0.008211152628064156,
                -0.026553897187113762,
                -0.038776203989982605,
                0.0329776257276535,
                0.00909815076738596,
                0.013876045122742653,
                0.05785994604229927,
                -0.07870430499315262,
                -0.03392772749066353,
                0.022751299664378166,
                -0.012660719454288483,
                0.02638808824121952,
                0.024807816371321678,
                0.03945363685488701,
                -0.002871907316148281,
                -0.01626679301261902,
                0.003829467808827758,
                0.005533377639949322,
                -0.02532791718840599,
                0.03823987394571304,
                0.03209313750267029,
                0.05781562626361847,
                -0.040545087307691574,
                -0.012890549376606941,
                -0.0036755253095179796,
                0.034740615636110306,
                0.028044171631336212,
                -0.01377375703305006,
                0.04906275495886803,
                0.013013860210776329,
                -0.015973588451743126,
                -0.03133857250213623,
                0.03379375487565994,
                0.04613635316491127,
                0.025155270472168922,
                0.060844723135232925,
                -0.03241279348731041,
                0.009432329796254635,
                0.01847633719444275,
                0.01561360340565443,
                -0.029385486617684364,
                -0.024107299745082855,
                -0.05156693980097771,
                -0.11618848145008087,
                0.04348428547382355,
                -0.019693588837981224,
                -0.027524497359991074,
                -0.002518130000680685,
                -0.058379147201776505,
                -0.0497124008834362,
                -0.00838235579431057,
                -0.09352972358465195,
                -0.0009650833089835942,
                0.0006925268098711967,
                -0.039701320230960846,
                -0.07018338888883591,
                -0.059713877737522125,
                0.043686915189027786,
                -0.047910261899232864,
                -0.027131471782922745,
                0.015194859355688095,
                0.062024105340242386,
                -0.028250467032194138,
                0.03198631852865219,
                -0.015204220078885555,
                0.09238484501838684,
                0.01497301273047924,
                0.0034936880692839622,
                -0.017942607402801514,
                -0.0100671062245965,
                0.03155717998743057,
                -0.0006412327638827264,
                0.008004612289369106,
                -0.022605083882808685,
                -0.013171939179301262,
                -0.01131029799580574,
                0.01379457302391529,
                -0.015781693160533905,
                -0.012656128033995628,
                -1.6235037037404254e-05,
                0.038728855550289154,
                -0.029349861666560173,
                -0.02054477483034134,
                0.007841898128390312,
                -0.028361409902572632,
                0.015167596749961376,
                -0.016524843871593475,
                0.03162846341729164,
                -0.02858687750995159,
                -0.014220403507351875,
                0.016139404848217964,
                0.010937642306089401,
                -0.035224296152591705,
                -0.04746708273887634,
                0.035677168518304825,
                -0.0052399099804461,
                0.042614296078681946,
                -0.04376095533370972,
                -0.008373367600142956,
                -0.04060177505016327,
                0.00104179244954139,
                0.01119457557797432,
                0.03232361376285553,
                0.06079191714525223,
                0.02961100824177265,
                -0.008341076783835888,
                -0.023482155054807663,
                0.06662066280841827,
                -0.008944123052060604,
                0.0006412797374650836,
                0.0214143805205822,
                -0.021883489564061165,
                -0.015456466004252434,
                0.007715648505836725,
                0.025942623615264893,
                -0.006744429003447294,
                -0.04153271019458771,
                -0.009199844673275948,
                -0.005135238636285067,
                -0.050514306873083115,
                0.10331162065267563,
                -0.06136097386479378,
                -0.0005673274863511324,
                -0.0003896318667102605,
                -0.0014346159296110272,
                -0.027435440570116043,
                -0.032093387097120285,
                0.031123163178563118,
                -0.021245576441287994,
                -0.1110648363828659,
                -0.025695936754345894,
                0.007243060972541571,
                0.05625011771917343,
                -0.04453188553452492,
                0.06924058496952057,
                0.04722486808896065,
                0.00845750980079174,
                -0.017558002844452858,
                0.05250520259141922,
                -0.0244661346077919,
                0.11087184399366379,
                0.017270784825086594,
                0.0008366833790205419,
                -0.0298288706690073,
                -0.0068145617842674255,
                0.018584851175546646,
                0.004908596631139517,
                0.06637799739837646,
                0.029781121760606766,
                0.033069632947444916,
                0.0178417656570673,
                -0.0026887108106166124,
                -0.0018269206630066037,
                -0.005828985013067722,
                0.02806561067700386,
                0.057959720492362976,
                -0.017144368961453438,
                0.0014998512342572212,
                -0.004619878251105547,
                0.04181568697094917,
                -0.013003222644329071,
                0.035578653216362,
                -0.055001288652420044,
                -0.009292683564126492,
                0.04909659922122955,
                0.026472995057702065,
                0.033401209861040115,
                0.008433176204562187,
                0.048986244946718216,
                -0.015407484024763107,
                -0.04579223692417145,
                0.022711873054504395,
                0.01209370605647564,
                -0.020091870799660683,
                0.030304595828056335,
                0.02989058569073677,
                -0.0024422884453088045,
                -0.011840212158858776,
                0.01833091676235199,
                -0.02921985276043415,
                0.00832615327090025,
                -0.05716020613908768,
                -0.05389199033379555,
                -0.09327598661184311,
                -0.013025306165218353,
                -0.02013746090233326,
                -0.026860162615776062,
                -0.0013422849588096142,
                0.001674461760558188,
                -0.013202544301748276,
                0.0015487336786463857,
                -0.018739929422736168,
                -0.009583775885403156,
                0.027566852048039436,
                0.009309224784374237,
                -0.013208837248384953,
                0.010113216005265713,
                0.04091009870171547,
                -0.013841764070093632,
                0.04518112540245056,
                -0.019800350069999695,
                -0.006369008217006922,
                -0.031210627406835556,
                0.013851214200258255,
                0.01546283345669508,
                -0.03669999912381172,
                -0.02359073981642723,
                0.00317525677382946,
                0.03047346882522106,
                0.10279254615306854,
                -0.03166985139250755,
                0.0196243803948164,
                -0.07225015759468079,
                -0.02396392822265625,
                0.005958971101790667,
                0.006617910228669643,
                0.03170500323176384,
                -0.0059707146137952805,
                0.012643488124012947,
                -0.018845384940505028,
                0.008978390134871006,
                0.002940760925412178,
                0.0015216468600556254,
                0.006243259645998478,
                0.011776895262300968,
                0.02386493794620037,
                -0.02699144184589386,
                -0.011453988961875439,
                -0.03444922715425491,
                -0.019309915602207184,
                0.01427775714546442,
                0.0519876629114151,
                -0.013542404398322105,
                -0.0072562796995043755,
                0.009436719119548798,
                -0.03767422214150429,
                0.016068054363131523,
                -0.0014259000308811665,
                0.0037455803249031305,
                0.03035944141447544,
                0.015068327076733112,
                -4.029990668641403e-05,
                -0.008873973041772842,
                0.06823965907096863,
                0.07284427434206009,
                -0.028914131224155426,
                0.0020696434658020735,
                0.014495179057121277,
                0.0054603395983576775,
                -0.032005440443754196,
                0.06924129277467728,
                0.04271441325545311,
                -0.02539629116654396,
                -0.027493860572576523,
                -0.0480799600481987,
                -0.02227121964097023,
                0.031020503491163254,
                -0.034814294427633286,
                -0.044316232204437256,
                0.011782574467360973,
                -0.019751444458961487,
                -0.03811338171362877,
                0.041626762598752975,
                -0.07533419877290726,
                0.005841026082634926,
                -0.03314151242375374,
                0.008410291746258736,
                0.027443649247288704,
                0.04518552124500275,
                -5.55592078457377e-33,
                0.02228967286646366,
                -0.039368726313114166,
                -0.0027076152618974447,
                -0.04044036567211151,
                -0.011114412918686867,
                0.0175002533942461,
                0.01239212416112423,
                -0.01668037474155426,
                -0.015402956865727901,
                -0.016590572893619537,
                0.029578901827335358,
                0.016682278364896774,
                0.029671160504221916,
                0.02266005426645279,
                0.05732168257236481,
                -0.028006551787257195,
                0.038453925400972366,
                0.022119253873825073,
                -0.03450695425271988,
                -0.019051769748330116,
                -0.058537520468235016,
                0.01857866533100605,
                0.026898350566625595,
                0.026328330859541893,
                -0.026400960981845856,
                0.03874828293919563,
                0.0006117317825555801,
                0.04857528209686279,
                -0.03403981402516365,
                -0.017020240426063538,
                0.006026881746947765,
                -0.001998883904889226,
                -0.022669577971100807,
                -0.01654365286231041,
                0.018510518595576286,
                0.022114023566246033,
                -0.059935469180345535,
                -0.009771712124347687,
                -0.037545736879110336,
                -0.06412342190742493,
                -0.07267123460769653,
                -0.0335766039788723,
                0.04926527664065361,
                -0.018830593675374985,
                -0.028951724991202354,
                -0.06199456378817558,
                -0.03782909736037254,
                0.0034004277549684048,
                0.022432047873735428,
                -0.057847462594509125,
                -0.05058755725622177,
                -0.02063535340130329,
                -0.038976334035396576,
                0.05481960251927376,
                -0.027587031945586205,
                0.03099387139081955,
                0.04178983345627785,
                -0.018291184678673744,
                -0.02381712943315506,
                -0.09045472741127014,
                -0.016056034713983536,
                -0.024362094700336456,
                0.035948920994997025,
                0.004003333859145641,
                -0.036496881395578384,
                0.02058441936969757,
                -0.02679768204689026,
                0.07288189977407455,
                -0.021725090220570564,
                0.0004079128848388791,
                -0.02202756144106388,
                0.06548460572957993,
                -0.07208922505378723,
                -0.03546950966119766,
                -0.1186935305595398,
                -0.011560332030057907,
                -0.05416110157966614,
                0.044448718428611755,
                -0.014112028293311596,
                0.039054036140441895,
                -0.04376963526010513,
                -0.022328510880470276,
                -0.009983758442103863,
                -0.007595541421324015,
                -0.018536623567342758,
                0.03972425311803818,
                -0.015868406742811203,
                -0.008614099584519863,
                -0.0019984287209808826,
                0.011017106473445892,
                0.022243812680244446,
                0.08275960385799408,
                -0.07637780159711838,
                0.035977620631456375,
                0.11263123154640198,
                -0.025540558621287346,
                0.039103925228118896,
                -0.06136957183480263,
                0.013410614803433418,
                0.05174728110432625,
                -0.054521314799785614,
                0.09159977734088898,
                -0.02722928486764431,
                0.03967789560556412,
                -0.04778582230210304,
                -0.046029232442379,
                -0.052270881831645966,
                -0.03186841309070587,
                -0.01428289245814085,
                0.04525177553296089,
                0.05974358320236206,
                0.007968936115503311,
                0.011236951686441898,
                0.007030068431049585,
                0.004164291545748711,
                -0.04188423976302147,
                0.054837264120578766,
                0.012413933873176575,
                -0.00054782978259027,
                0.018831241875886917,
                0.05026265233755112,
                0.024214867502450943,
                -0.002946052700281143,
                0.030522914603352547,
                -0.021335069090127945,
                -0.012391784228384495,
                0.06125566363334656,
                -0.04569993168115616,
                0.005808285437524319,
                -0.00866803340613842,
                0.030534662306308746,
                -0.02285553701221943,
                2.338176727789687e-07,
                0.029195675626397133,
                -0.02205507457256317,
                0.029577311128377914,
                0.00649121031165123,
                0.056916359812021255,
                -0.025868762284517288,
                0.001680102664977312,
                0.02851191721856594,
                -0.03100358322262764,
                0.047881342470645905,
                0.02636083774268627,
                -0.025689713656902313,
                0.012930389493703842,
                -0.057962529361248016,
                -0.011488997377455235,
                -0.12508419156074524,
                -0.009682458825409412,
                -0.02907410077750683,
                -0.021138427779078484,
                0.004163870122283697,
                0.028052864596247673,
                0.001363273011520505,
                -0.00886239018291235,
                -0.04564780369400978,
                0.02778005041182041,
                0.04599444940686226,
                0.023597026243805885,
                -0.018518615514039993,
                -0.0039466675370931625,
                0.05366723611950874,
                -0.016611002385616302,
                0.008734873495995998,
                0.05348643288016319,
                0.03954790160059929,
                0.009258757345378399,
                -0.04139121249318123,
                0.012757369317114353,
                0.08167164772748947,
                0.0033986293710768223,
                0.001982650253921747,
                -0.004119422752410173,
                -0.04515276104211807,
                0.008481808006763458,
                0.007619741838425398,
                0.015448004938662052,
                -0.03666163608431816,
                0.002371658571064472,
                0.0964096412062645,
                -0.11575444042682648,
                0.008769485168159008,
                -0.003673778148368001,
                0.016088077798485756,
                0.00043803805601783097,
                -0.017073659226298332,
                0.02488960139453411,
                0.042536817491054535,
                -0.03419824317097664,
                0.03650018200278282,
                0.08702046424150467,
                -0.011921709403395653,
                0.011728564277291298,
                -0.08377643674612045,
                0.026039207354187965,
                0.028764920309185982,
                0.003037340473383665,
                -0.042106568813323975,
                -0.026982558891177177,
                2.1832142041248005e-34,
                -0.026571502909064293,
                -0.0010205225553363562,
                -0.0248237457126379,
                0.004425549414008856,
                0.044915057718753815,
                -0.03528150916099548,
                -0.018506446853280067,
                -0.005806343164294958,
                0.01635507494211197,
                0.020066075026988983,
                -0.046525195240974426
            ]
        },
        "Skills": {
            "name": [
                "Safety Case",
                "EHS",
                "Safety and Environmental ",
                "Offshore",
                "Sustainability",
                "LNG",
                "Oil and Gas",
                "ESG",
                "HAZID",
                "HAZOP",
                "HSE Management",
                "HSEQ",
                "HSSE",
                "Health and Safety",
                "Process Safety",
                "IOSH",
                "Environmental Management ",
                "NEBOSH",
                "Risk Management",
                "ISO 9001",
                "ISO 45001",
                "Auditing",
                "Incident Investigation",
                "HSE Culture ",
                "HSE Training ",
                "Behavioural Safety ",
                "ISO 14001",
                "HSE",
                "COMAH",
                "Expert Witness",
                "CDM Regulations"
            ],
            "rep": [
                -0.03168891370296478,
                -0.0449543222784996,
                -0.012559615075588226,
                0.01924692466855049,
                -0.014659765176475048,
                0.040089044719934464,
                0.02130352146923542,
                -0.03549293801188469,
                -0.048668064177036285,
                -0.025755753740668297,
                0.0781141147017479,
                0.006938213482499123,
                0.06867105513811111,
                0.005353657063096762,
                0.05590397119522095,
                0.03300069272518158,
                0.027270764112472534,
                -0.008526759222149849,
                0.0070510380901396275,
                -0.0077800326980650425,
                0.016126446425914764,
                0.017441613599658012,
                -0.026873137801885605,
                0.03995220363140106,
                -0.010194315575063229,
                -0.011236421763896942,
                0.03961842507123947,
                -0.007383157964795828,
                0.030097713693976402,
                -0.03353581577539444,
                0.028905542567372322,
                -0.0021334378980100155,
                0.021585926413536072,
                -0.04738892614841461,
                1.639243350837205e-06,
                -0.007006615865975618,
                0.05514773726463318,
                -0.02532872185111046,
                0.004122973419725895,
                -0.03359808772802353,
                -0.04239565506577492,
                -0.01249854825437069,
                -0.04720420390367508,
                0.03550393879413605,
                0.017000745981931686,
                -0.05233244225382805,
                0.012925323098897934,
                0.03082524612545967,
                0.003908535931259394,
                0.03248386085033417,
                -0.017539814114570618,
                0.012134598568081856,
                0.009254224598407745,
                0.018000110983848572,
                -0.010066590271890163,
                -0.06035603955388069,
                -0.0043624574318528175,
                0.022450212389230728,
                0.06109952926635742,
                0.004671082831919193,
                -0.00500473752617836,
                -0.031514618545770645,
                -0.001613086904399097,
                -0.03664299473166466,
                -0.029052814468741417,
                -0.01776084676384926,
                0.01804889738559723,
                0.020105432718992233,
                0.013801111839711666,
                0.030486740171909332,
                0.02756526693701744,
                0.011072857305407524,
                -0.014573284424841404,
                0.08352179080247879,
                0.005235167685896158,
                -0.011037846095860004,
                0.01673164777457714,
                0.033161673694849014,
                0.0045882221311330795,
                -0.02603386528789997,
                0.04092642664909363,
                0.09127356112003326,
                -0.012215157970786095,
                0.0201299786567688,
                0.04816586896777153,
                0.05176546797156334,
                -0.009622947312891483,
                0.0153526421636343,
                0.012811414897441864,
                -0.021235788241028786,
                0.043936531990766525,
                -0.07462115585803986,
                0.02820822037756443,
                0.013188783079385757,
                0.006916723679751158,
                0.007942820899188519,
                -0.006380517967045307,
                -0.0467073917388916,
                0.03546140342950821,
                -0.16838394105434418,
                -0.050839442759752274,
                0.015130762942135334,
                0.03453060984611511,
                -0.002358367433771491,
                -0.001440134015865624,
                -0.06071820110082626,
                -0.007650475483387709,
                0.011474686674773693,
                -0.03585711494088173,
                0.11884631961584091,
                0.015328641049563885,
                0.0057920534163713455,
                -0.020017234608530998,
                0.026828743517398834,
                0.0016407998045906425,
                0.031411271542310715,
                -0.011996765621006489,
                0.022771546617150307,
                0.003600714961066842,
                0.025932881981134415,
                -0.0013225548900663853,
                0.009448560886085033,
                -0.015619241632521152,
                0.029171373695135117,
                0.07588689029216766,
                -0.07943086326122284,
                -0.03763800114393234,
                0.02482827752828598,
                0.04693787917494774,
                0.028105037286877632,
                -0.008811120875179768,
                0.00013947323895990849,
                0.019986823201179504,
                -0.02376428246498108,
                -0.0427023209631443,
                0.08798114955425262,
                -0.022262273356318474,
                -0.013698448427021503,
                -0.09693263471126556,
                -0.03447333723306656,
                -0.01624409109354019,
                -0.010405038483440876,
                -0.02885969914495945,
                -0.02040066197514534,
                -0.02304239571094513,
                -0.0013505180831998587,
                -0.0242632944136858,
                -0.08278950303792953,
                0.010626619681715965,
                0.026130426675081253,
                -0.03430023044347763,
                -0.018405890092253685,
                0.00021014396043028682,
                -0.009481550194323063,
                -0.011027618311345577,
                0.03537999093532562,
                -0.0037609185092151165,
                -0.01938871666789055,
                0.02037886716425419,
                0.0028064243961125612,
                0.023433420807123184,
                0.010265134274959564,
                -0.0029126498848199844,
                0.014500117860734463,
                -0.04685845226049423,
                0.058853331953287125,
                -0.011504835449159145,
                -0.011865504086017609,
                -0.020461130887269974,
                -0.021677980199456215,
                -0.012156549841165543,
                0.007176239974796772,
                -0.04670966789126396,
                0.03743970766663551,
                0.009435184299945831,
                0.03389269486069679,
                0.01813434436917305,
                -0.016430867835879326,
                -0.06657962501049042,
                -0.0020193124655634165,
                -0.04769444838166237,
                -0.05426232889294624,
                0.028449712321162224,
                0.002817464293912053,
                -0.13281488418579102,
                -0.02905290201306343,
                -0.043705444782972336,
                0.0004123617254663259,
                -0.059857506304979324,
                0.02157096564769745,
                -0.006955754477530718,
                -0.016938848420977592,
                0.07624717056751251,
                0.014224696904420853,
                -0.008976655080914497,
                0.00960641447454691,
                -0.020663948729634285,
                0.010921585373580456,
                0.0036668344400823116,
                -0.01474311575293541,
                -0.03623310476541519,
                -0.02310231514275074,
                0.012628891505300999,
                0.0023122287821024656,
                -0.05638071522116661,
                0.06616438925266266,
                -0.06826239824295044,
                -0.008993487805128098,
                0.006829957477748394,
                -0.0237086471170187,
                -0.014631659723818302,
                0.009077249094843864,
                0.05424509197473526,
                0.01818755269050598,
                -0.03252468630671501,
                0.023403750732541084,
                0.027708744630217552,
                0.006344052962958813,
                -0.031267378479242325,
                0.07534018158912659,
                0.02917148545384407,
                0.007341420277953148,
                -0.01095599215477705,
                -0.0008801858639344573,
                -0.03388717770576477,
                -0.038134414702653885,
                -0.031167389824986458,
                -0.013508959673345089,
                -0.0326484851539135,
                -0.05014419183135033,
                0.02293488010764122,
                0.01958872377872467,
                -0.02189241349697113,
                0.01149363536387682,
                0.034792687743902206,
                0.02513042651116848,
                -0.018920425325632095,
                -0.014288017526268959,
                0.0008571149664930999,
                -0.022562989965081215,
                -0.010849419981241226,
                -0.034307897090911865,
                0.02072880230844021,
                0.01620427705347538,
                -0.019298886880278587,
                -0.007166710216552019,
                -0.062130216509103775,
                0.0025966232642531395,
                -0.013210934586822987,
                0.022650886327028275,
                -0.04309113323688507,
                -0.006264023017138243,
                0.036643773317337036,
                0.06423942744731903,
                -0.044519249349832535,
                -0.10888325423002243,
                0.01012896467000246,
                0.06865716725587845,
                -0.06306413561105728,
                -0.01178906112909317,
                0.010654493235051632,
                -0.05158860236406326,
                0.022284507751464844,
                -0.004426035564392805,
                0.0235267486423254,
                -0.016715409234166145,
                0.06533019989728928,
                -0.006300219334661961,
                0.011498106643557549,
                -0.022466879338026047,
                -0.014727472327649593,
                -0.008068680763244629,
                0.059701427817344666,
                -0.017462296411395073,
                0.024971306324005127,
                0.015630604699254036,
                -0.048024311661720276,
                -0.0026910139713436365,
                -0.005923261865973473,
                -0.024308955296874046,
                0.006001075264066458,
                0.007057319860905409,
                0.004767282400280237,
                -0.016591360792517662,
                -0.008889470249414444,
                0.0302706491202116,
                -0.00843606237322092,
                -0.026870954781770706,
                -0.06466535478830338,
                0.12946821749210358,
                0.010913841426372528,
                0.024921923875808716,
                -0.008545340970158577,
                -0.0031868843361735344,
                -0.022758912295103073,
                -0.0212891586124897,
                -0.01184083055704832,
                0.02302977442741394,
                0.030507884919643402,
                0.014730662107467651,
                0.0010856235167011619,
                0.01307661458849907,
                -0.03141031414270401,
                0.007087178993970156,
                0.03716643899679184,
                -0.0013251247582957149,
                0.023007502779364586,
                0.016282178461551666,
                -0.031030593439936638,
                -0.019676288589835167,
                0.015098814852535725,
                0.010167263448238373,
                -0.0019980533979833126,
                -0.013511519879102707,
                -0.004704997409135103,
                -0.023343415930867195,
                -0.0013963190140202641,
                -0.0803089365363121,
                -0.02137855626642704,
                0.08670516312122345,
                0.03290833905339241,
                0.011094953864812851,
                0.017657414078712463,
                0.019382216036319733,
                0.0290206391364336,
                0.015775376930832863,
                -0.022740617394447327,
                0.02751779928803444,
                0.0650177001953125,
                -0.04369393736124039,
                0.015615169890224934,
                -0.025283314287662506,
                -0.03532242402434349,
                0.005310886073857546,
                -0.035265423357486725,
                -0.00179297907743603,
                -0.026682564988732338,
                -0.04652706906199455,
                -0.002291856799274683,
                0.04845535382628441,
                -0.011054507456719875,
                -0.04039855673909187,
                0.0010327920317649841,
                -0.05690640211105347,
                -0.04474936053156853,
                -0.03201761096715927,
                -0.08822482824325562,
                0.055043190717697144,
                -0.02912321873009205,
                0.013491368852555752,
                0.023333286866545677,
                0.0076757073402404785,
                -0.0306121613830328,
                0.0062812515534460545,
                -0.030903350561857224,
                -0.019163940101861954,
                0.004718731623142958,
                -0.0737004280090332,
                0.018486322835087776,
                -0.030745983123779297,
                0.010484255850315094,
                0.04219270497560501,
                0.007345438934862614,
                0.05403229221701622,
                -0.07087311893701553,
                -0.012444665655493736,
                0.0321817621588707,
                0.002247397555038333,
                -0.037725724279880524,
                0.043958310037851334,
                0.027759473770856857,
                0.05704789608716965,
                0.00032837953767739236,
                -0.007873808033764362,
                -0.055523719638586044,
                0.01031078677624464,
                0.03873511776328087,
                0.033756084740161896,
                -0.0179431289434433,
                -0.026798803359270096,
                0.04953353479504585,
                0.024404184892773628,
                0.027950087562203407,
                0.024503320455551147,
                -0.020858585834503174,
                0.015722230076789856,
                -0.029090382158756256,
                -0.005188498646020889,
                0.018575670197606087,
                0.07806900143623352,
                0.034661535173654556,
                -0.007323706056922674,
                -0.050631120800971985,
                0.06943759322166443,
                0.024732759222388268,
                0.022950787097215652,
                0.050047215074300766,
                0.0545588843524456,
                -0.014174683019518852,
                0.016659220680594444,
                -0.0018578707240521908,
                0.06112057715654373,
                -0.037234943360090256,
                0.05974215641617775,
                -0.030714068561792374,
                -0.023448940366506577,
                0.019481036812067032,
                -0.039963845163583755,
                -0.005326384212821722,
                0.006984718143939972,
                -0.03228963166475296,
                0.011288781650364399,
                -0.010446657426655293,
                -0.025905728340148926,
                0.004386579617857933,
                -0.03229275345802307,
                -0.0020126900635659695,
                -0.05993933975696564,
                0.02110971324145794,
                -0.0016668621683493257,
                -0.014612771570682526,
                0.04869920387864113,
                0.007813196629285812,
                -0.05788501724600792,
                -0.024127082899212837,
                -0.04921925067901611,
                0.0174639280885458,
                0.03116196021437645,
                -0.007722878362983465,
                -0.006942602805793285,
                0.027189962565898895,
                0.022973984479904175,
                0.01800273358821869,
                0.017813347280025482,
                -0.023858148604631424,
                -0.019303228706121445,
                0.00582174863666296,
                0.022159500047564507,
                -0.04106704518198967,
                0.007809999864548445,
                0.009351801127195358,
                -0.015347730368375778,
                0.09307632595300674,
                0.022246604785323143,
                -0.02070968598127365,
                0.02019452676177025,
                0.012589985504746437,
                -0.03958886116743088,
                -0.0005842341925017536,
                0.021722223609685898,
                0.02745964191854,
                0.04484345391392708,
                0.15419508516788483,
                0.004489947576075792,
                0.026045657694339752,
                -0.027351349592208862,
                -0.01192942913621664,
                0.08221154659986496,
                -0.025816133245825768,
                0.025870852172374725,
                0.007698042783886194,
                0.04080504551529884,
                -0.05979199334979057,
                -0.019489672034978867,
                -0.02105904370546341,
                -0.02188802696764469,
                -0.029052887111902237,
                -0.03267478197813034,
                -0.04375063255429268,
                -0.01315679308027029,
                -0.09341423958539963,
                0.006338803563266993,
                -0.0409308597445488,
                0.0073645408265292645,
                0.014164118096232414,
                0.001972866477444768,
                -0.005947655998170376,
                -0.038360849022865295,
                0.030767878517508507,
                0.010850287042558193,
                0.04604188725352287,
                0.04191403463482857,
                0.014591868035495281,
                0.023758715018630028,
                -0.012069844640791416,
                -0.00014002906391397119,
                0.01731877401471138,
                -0.017992405220866203,
                0.0016978452913463116,
                0.022556601092219353,
                -0.0030136131681501865,
                -0.020134873688220978,
                -0.018646495416760445,
                -0.04723893478512764,
                0.01434831041842699,
                0.06715413182973862,
                0.033611927181482315,
                0.05022159591317177,
                0.003985930699855089,
                -0.06044880300760269,
                -0.024080218747258186,
                0.023756904527544975,
                0.01176654826849699,
                0.0061924695037305355,
                0.017732178792357445,
                0.020313506945967674,
                -0.015315083786845207,
                0.010556908324360847,
                0.001566474325954914,
                -0.001683803740888834,
                -0.03363770246505737,
                0.027513211593031883,
                -0.003549703164026141,
                -0.01381041668355465,
                0.014363721013069153,
                0.017961986362934113,
                -0.06383587419986725,
                -0.03060108609497547,
                -0.018867310136556625,
                0.0006574231665581465,
                0.023915428668260574,
                -0.03723026439547539,
                0.034178804606199265,
                -0.02461659163236618,
                -0.04070422425866127,
                0.02914297580718994,
                0.03255147859454155,
                0.0010961343068629503,
                -0.02668164297938347,
                0.02509654499590397,
                0.021691612899303436,
                -0.0482986755669117,
                -0.03490396961569786,
                0.003961985930800438,
                -0.017467278987169266,
                0.03261507302522659,
                0.0497945174574852,
                0.061784904450178146,
                -0.015235207043588161,
                -0.06812786310911179,
                -0.06300774216651917,
                -0.03851469233632088,
                -0.041754476726055145,
                -0.005174502730369568,
                0.0395689457654953,
                0.06486951559782028,
                -0.01610909216105938,
                0.013100074604153633,
                0.04554007202386856,
                -0.04401998594403267,
                0.01763874664902687,
                -0.0025786887854337692,
                0.007621536497026682,
                -0.005891178268939257,
                -0.02166713774204254,
                -6.44980893326727e-33,
                0.026888450607657433,
                -0.015561557374894619,
                -0.002835553837940097,
                0.04443349689245224,
                -0.013128040358424187,
                -0.018235087394714355,
                -0.01928575336933136,
                0.035241372883319855,
                -0.018805846571922302,
                -0.01282301265746355,
                -0.014700404368340969,
                0.03928845375776291,
                0.038298025727272034,
                0.009255942888557911,
                0.05491391941905022,
                -0.05555252358317375,
                -0.02800251916050911,
                0.04687972366809845,
                0.010272236540913582,
                -0.034007634967565536,
                -0.05333223193883896,
                -0.003414290025830269,
                0.07766608148813248,
                -0.04171816632151604,
                0.006956797558814287,
                -0.019733760505914688,
                -0.03505415841937065,
                -0.0035619083791971207,
                -0.034122489392757416,
                -0.02333718165755272,
                0.01726319082081318,
                -0.027502838522195816,
                -0.002608182141557336,
                -0.004057363606989384,
                0.01317273173481226,
                0.08935756236314774,
                -0.001347552053630352,
                -0.04259897768497467,
                0.008963821455836296,
                -0.03985833749175072,
                -0.07905034720897675,
                -0.06353207677602768,
                0.03880586475133896,
                -0.03274388983845711,
                -0.03309950977563858,
                -0.04532371833920479,
                -0.021691394969820976,
                -0.0006547461962327361,
                0.0010029989061877131,
                -0.058269254863262177,
                -0.028206732124090195,
                0.014873722568154335,
                -0.0029635915998369455,
                -0.03949063643813133,
                0.03508603200316429,
                -0.010028154589235783,
                0.0024629251565784216,
                0.018592528998851776,
                0.025020604953169823,
                -0.02322939783334732,
                -0.06893172115087509,
                -0.03126321732997894,
                0.0019227158045396209,
                -0.05038323998451233,
                -0.03648311272263527,
                0.0016610154416412115,
                -0.10847405344247818,
                0.03876681253314018,
                -0.044376228004693985,
                -0.05009101703763008,
                -0.009761535562574863,
                0.08771955221891403,
                -0.040644582360982895,
                0.0014377146726474166,
                -0.011168276891112328,
                -0.07837890833616257,
                -0.09824080020189285,
                0.029818978160619736,
                0.024240577593445778,
                0.09261920303106308,
                -0.004886369686573744,
                0.0008233344415202737,
                -0.071622334420681,
                -0.027838289737701416,
                -0.05847277492284775,
                0.03614206239581108,
                0.005998672917485237,
                0.007886651903390884,
                -0.014248070307075977,
                -0.01691836304962635,
                0.0037919809110462666,
                -0.023346932604908943,
                -0.02710672654211521,
                0.06190776452422142,
                0.09352137893438339,
                -0.02197219617664814,
                0.07537655532360077,
                -0.024063840508461,
                -0.017278479412198067,
                0.010620168410241604,
                0.06218226999044418,
                -0.014717822894454002,
                -0.04011128097772598,
                0.006886749528348446,
                0.008977556601166725,
                -0.04631464555859566,
                -0.006807093974202871,
                0.03860463947057724,
                -0.0054388875141739845,
                -0.0024001235142350197,
                0.008230850100517273,
                0.005592884961515665,
                0.006237408611923456,
                0.003563488367944956,
                -0.008650442585349083,
                0.002404590602964163,
                0.029954029247164726,
                -0.0070175183936953545,
                0.05128919333219528,
                -0.04431048780679703,
                0.019043980166316032,
                0.01920199953019619,
                -0.04375641420483589,
                0.011203036643564701,
                -0.011288763955235481,
                -0.0028882999904453754,
                0.07634460180997849,
                0.0687682032585144,
                0.03580985590815544,
                -0.032800108194351196,
                0.033559780567884445,
                0.04475347325205803,
                2.235965439467691e-07,
                -0.00614456320181489,
                0.010910321958363056,
                -0.021869199350476265,
                0.05795416608452797,
                0.03187447413802147,
                0.03413798287510872,
                -0.028984669595956802,
                0.057158540934324265,
                0.06196458637714386,
                -0.013181392103433609,
                0.03515833988785744,
                -0.01631501503288746,
                0.022632621228694916,
                0.006758247502148151,
                0.044103410094976425,
                -0.09697269648313522,
                0.03203117102384567,
                0.004144584760069847,
                0.027739010751247406,
                -0.048881273716688156,
                0.0826205387711525,
                -0.0015231086872518063,
                0.008239351212978363,
                -0.0316191166639328,
                0.0012540282914415002,
                -0.03885842114686966,
                0.023410482332110405,
                -0.012629177421331406,
                0.05010011047124863,
                -0.04179627448320389,
                0.042451221495866776,
                0.081807442009449,
                0.010115708224475384,
                0.03466721624135971,
                0.0025870101526379585,
                -0.021467311307787895,
                -0.005505716428160667,
                0.08511800318956375,
                0.0158555768430233,
                0.05145154520869255,
                -0.009507390670478344,
                -0.08708398044109344,
                -0.0012716683559119701,
                0.0009617874165996909,
                0.0034827846102416515,
                -0.030558854341506958,
                -0.0074433996342122555,
                0.03626982122659683,
                -0.10436590760946274,
                0.01583964377641678,
                -0.014575783163309097,
                0.03490004688501358,
                0.006924776826053858,
                0.01933007314801216,
                -0.0017564673908054829,
                0.002407791092991829,
                0.01303501334041357,
                0.016676021739840508,
                0.019021017476916313,
                0.058205489069223404,
                0.015305113047361374,
                0.007481852546334267,
                0.050121795386075974,
                0.027089329436421394,
                0.005992719903588295,
                -0.02602820284664631,
                -0.02475571259856224,
                1.3726571254675808e-34,
                -0.004966910928487778,
                -0.034025803208351135,
                -0.01649191416800022,
                -0.018617786467075348,
                0.027649836614727974,
                -0.0037167593836784363,
                0.002128924010321498,
                -0.015125906094908714,
                -0.011134406551718712,
                -0.025339089334011078,
                -0.028286609798669815,
                -0.005004862789064646,
                0.06532568484544754,
                -0.0044135707430541515,
                -0.034538134932518005,
                0.06745126098394394,
                0.024533500894904137,
                0.04437104985117912,
                0.054475270211696625,
                0.0031997470650821924,
                0.03845793753862381,
                0.03436713293194771,
                -0.02437720075249672,
                -0.011696861125528812,
                0.017096497118473053,
                0.019959596917033195,
                -0.002477556699886918,
                0.010889370925724506,
                -0.011368398554623127,
                0.035572946071624756,
                -0.033473145216703415,
                -0.033022888004779816,
                -0.02331368811428547,
                -0.00747165409848094,
                -0.009377307258546352,
                0.02989782951772213,
                0.023268219083547592,
                0.009489203803241253,
                -0.0015761514659970999,
                0.0008003078401088715,
                0.021283384412527084,
                0.0402723103761673,
                -5.8648332924349234e-05,
                0.023666080087423325,
                -0.05190601944923401,
                2.2264314338826807e-06,
                -0.045960932970047,
                -0.012439235113561153,
                0.010094480589032173,
                -0.020238738507032394,
                0.07233095169067383,
                -0.010196357034146786,
                -0.02255319058895111,
                -0.02935221418738365,
                -0.010814819484949112,
                0.011835726909339428,
                -0.03827858343720436,
                0.021300120279192924,
                0.032421644777059555,
                0.051995649933815,
                -0.004047623835504055,
                -0.015532136894762516,
                0.030747653916478157,
                -0.02798660658299923,
                0.014198231510818005,
                0.07529465854167938,
                -0.004636939615011215,
                -0.007926306687295437,
                -0.03322165831923485,
                -0.003560659708455205,
                -0.02546338364481926,
                -0.04371762275695801,
                0.058948107063770294,
                -0.02174372971057892,
                -0.01047791913151741,
                0.038857560604810715,
                0.02612222172319889,
                0.02856341004371643,
                0.03808854520320892,
                0.049008842557668686,
                -0.035676755011081696,
                0.03199029341340065,
                -0.07661830633878708,
                -0.01761692762374878,
                0.04960145801305771,
                -0.031761929392814636,
                -0.06529337912797928,
                -0.02975582517683506,
                0.024884521961212158,
                0.027365364134311676,
                0.0027294675819575787,
                -0.024940328672528267,
                0.033392611891031265,
                0.0039029044564813375,
                0.0712430402636528,
                -0.02844228781759739,
                -0.012137206271290779,
                0.03201555460691452,
                -0.06177082657814026,
                -0.010541983880102634,
                0.01355865690857172,
                0.022538378834724426,
                -0.05543023347854614,
                -0.0009858057601377368,
                0.018213387578725815,
                0.020233601331710815,
                -0.05603484809398651,
                0.006834791973233223,
                0.03421768173575401,
                -0.012813642621040344,
                0.04345285892486572,
                -0.03881647065281868,
                0.05143013596534729,
                0.049695756286382675,
                -0.004852190613746643,
                0.01130471471697092,
                0.017981983721256256,
                -0.01550421305000782,
                0.0010349047370254993,
                0.010611776262521744,
                -0.011834613978862762,
                0.042887527495622635,
                -0.02389329858124256,
                -0.011280075646936893,
                0.029699651524424553,
                -0.027609433978796005,
                0.03336665406823158,
                -0.04994643107056618,
                -0.04855339601635933,
                -0.014968592673540115,
                0.021304696798324585,
                0.03428235277533531,
                -0.006960746832191944,
                -0.020549273118376732,
                0.022579625248908997,
                -0.022917719557881355,
                0.04843015596270561,
                0.002903782529756427,
                0.024141769856214523,
                0.008771785534918308,
                0.0005145006580278277,
                -0.021962415426969528,
                -0.0029678265564143658,
                -0.020465439185500145,
                -0.051523223519325256,
                0.01686382293701172,
                0.10084167122840881,
                0.023521069437265396,
                -0.006719911936670542,
                0.0537637434899807,
                0.04054834693670273,
                -0.03682820498943329,
                -0.010510822758078575,
                0.03396955132484436,
                -0.020497947931289673,
                0.011040447279810905,
                0.005717603024095297,
                -0.007585054263472557,
                -0.045938704162836075,
                0.017504220828413963,
                0.00435829721391201,
                0.01700633391737938,
                0.05797833949327469,
                -0.0336637869477272,
                -0.009439095854759216,
                0.05766966938972473,
                0.006234930362552404,
                -0.035594645887613297,
                0.02076597884297371,
                -0.003040820127353072,
                0.0039367955178022385,
                -0.005262516438961029,
                0.05471917986869812,
                0.05373600125312805,
                0.013618631288409233,
                -0.028884755447506905,
                0.053862761706113815,
                0.02224285528063774,
                -0.04181063920259476,
                -0.05941063165664673,
                0.033376678824424744,
                0.015032203868031502,
                0.03291564807295799,
                -0.03551078960299492,
                -0.04013026878237724,
                0.05711638182401657,
                0.004125381354242563,
                -0.06514834612607956,
                -0.013438887894153595,
                -0.021006690338253975,
                -0.02344195358455181,
                -0.034329358488321304,
                -0.058318208903074265,
                -0.0629274845123291,
                -0.05362881347537041,
                0.02216196432709694,
                0.07092121243476868,
                0.0485675148665905,
                -0.04357336834073067,
                0.0003433716483414173,
                -0.027776876464486122,
                0.029696254059672356,
                -0.06959521770477295,
                -0.06452573090791702,
                -0.029612906277179718,
                0.03992387279868126,
                0.008559300564229488,
                0.03901776298880577,
                0.03145602345466614,
                0.04790657013654709,
                -0.06780111789703369,
                -0.00834124069660902,
                0.004006968345493078,
                0.00492538046091795,
                -0.013552611693739891,
                -0.059263717383146286,
                0.04469291865825653,
                -0.03999335318803787,
                -0.026850659400224686,
                -0.033551570028066635,
                -0.03659675270318985,
                0.027277110144495964,
                0.03294327110052109,
                0.03706749901175499,
                -0.07488769292831421,
                0.010893511585891247,
                -0.10676861554384232,
                0.030152782797813416,
                -0.006966643966734409,
                -0.05735374242067337,
                0.059647344052791595,
                0.0012344270944595337,
                0.07130322605371475,
                -0.01775774359703064,
                0.020917322486639023,
                -0.060864388942718506,
                0.03753717243671417,
                -0.004924631677567959,
                0.01505233347415924,
                -0.025145748630166054,
                0.03381418436765671,
                -0.013271667063236237,
                0.0430917963385582,
                0.006804207339882851,
                -0.01555102039128542,
                -0.028965013101696968,
                0.005187139380723238,
                0.06496657431125641,
                -0.05455096811056137,
                0.01984678953886032,
                -0.034190863370895386,
                -0.01755758933722973,
                -0.06553928554058075,
                0.004569074604660273,
                0.020822491496801376,
                0.03772357851266861,
                0.02122957445681095,
                -0.007318507879972458,
                -0.02016008086502552,
                -0.031527984887361526,
                0.014217979274690151,
                -0.05129530280828476,
                -0.01079024188220501,
                -0.046137504279613495,
                0.021665014326572418,
                0.051845863461494446,
                0.007779777515679598,
                -0.012113332748413086,
                -0.009083066135644913,
                -0.028965547680854797,
                -0.021660152822732925,
                -0.06353695690631866,
                0.030135519802570343,
                -0.019900929182767868,
                -0.04841336980462074,
                0.012831637635827065,
                -0.03076683171093464,
                0.05464232712984085,
                -0.04591972008347511,
                -0.03402005136013031,
                0.02958700805902481,
                0.026619356125593185,
                0.04824460670351982,
                0.017622997984290123,
                -0.03704269602894783,
                -0.02625695802271366,
                0.03993985056877136,
                -0.08585295081138611,
                -0.00037104793591424823,
                -0.0297092292457819,
                -0.008326195180416107,
                0.010485565289855003,
                0.029412616044282913,
                -0.04553062096238136,
                0.007549054455012083,
                -0.02847726084291935,
                0.0045639825984835625,
                -0.05067015811800957,
                -0.017146078869700432,
                0.03369322791695595,
                -0.03669016808271408,
                -0.030588896945118904,
                0.010218687355518341,
                0.07580971717834473,
                -0.06490115821361542,
                -0.01588880829513073,
                -0.029081245884299278,
                0.051318127661943436,
                0.011457921005785465,
                0.026313576847314835,
                0.005888480227440596,
                -0.03195102885365486,
                -0.024549191817641258,
                0.02507765218615532,
                -0.028531081974506378,
                -0.05962268263101578,
                -0.02990429662168026,
                -0.0046717640943825245,
                0.008058431558310986,
                -0.02737639658153057,
                -0.043175533413887024,
                0.03124910593032837,
                -0.007550865411758423,
                -0.003723031608387828,
                0.062181323766708374,
                0.03761214762926102,
                -0.02305132895708084,
                -0.06895411759614944,
                -0.0021764261182397604,
                -0.00601964071393013,
                -0.016340361908078194,
                0.032116878777742386,
                0.005609970539808273,
                -0.015058829449117184,
                -0.02037828415632248,
                -0.012840031646192074,
                0.08249189704656601,
                -0.02002912387251854,
                0.0393802784383297,
                -0.07815106213092804,
                0.07081419974565506,
                0.012072809040546417,
                0.03951680660247803,
                -0.024198828265070915,
                -0.030831240117549896,
                -0.0076107606291770935,
                -0.04640943929553032,
                -0.027621181681752205,
                -0.05911781266331673,
                0.03599301353096962,
                -0.060276906937360764,
                -0.024976784363389015,
                -0.038915615528821945,
                -0.02936447039246559,
                -0.031097758561372757,
                -0.11099906265735626,
                0.024489372968673706,
                0.022321268916130066,
                0.047972969710826874,
                -0.03649646043777466,
                -0.028668686747550964,
                0.007919345051050186,
                -0.005040050484240055,
                0.007978357374668121,
                -0.02841615118086338,
                0.018585825338959694,
                0.019879043102264404,
                -0.033354390412569046,
                -0.029443038627505302,
                0.01782998815178871,
                -0.07114046066999435,
                -0.002633924363180995,
                -0.0034925024956464767,
                0.02741939201951027,
                -0.03401234745979309,
                0.011644075624644756,
                0.0377609021961689,
                -0.0024876161478459835,
                0.04358159005641937,
                -0.01805034838616848,
                -0.0512041412293911,
                0.047467898577451706,
                0.07069901376962662,
                -0.054221734404563904,
                0.10407821834087372,
                -0.03631196543574333,
                -0.023604020476341248,
                0.05387922748923302,
                0.02961629442870617,
                0.008633074350655079,
                0.048312850296497345,
                0.025192685425281525,
                0.06597493588924408,
                -0.017384527251124382,
                0.0578894168138504,
                0.04093671217560768,
                -0.007952788844704628,
                0.035358916968107224,
                -0.019170811399817467,
                -0.0645446702837944,
                -0.003785065608099103,
                0.0013161053648218513,
                -0.05735946446657181,
                -0.0045450469478964806,
                0.07874047756195068,
                -0.00029613415244966745,
                -0.025397077202796936,
                0.02940204367041588,
                -0.04628194868564606,
                0.031510502099990845,
                0.043812304735183716,
                0.05116724222898483,
                0.012138496153056622,
                0.054624322801828384,
                -0.031113041564822197,
                -0.023866211995482445,
                0.05434763804078102,
                -0.02939089760184288,
                -0.0008610189543105662,
                0.03119228407740593,
                -0.08472249656915665,
                -0.026498904451727867,
                -0.032400213181972504,
                0.009366647340357304,
                0.024584513157606125,
                -0.02163613773882389,
                -0.06737233698368073,
                -0.007083771284669638,
                -0.007615468930453062,
                0.011549346148967743,
                0.00737365335226059,
                -0.03986745327711105,
                0.02163112908601761,
                -0.0018804608844220638,
                -0.04498932883143425,
                -0.031081322580575943,
                0.008815845474600792,
                0.012785595841705799,
                0.09766676276922226,
                0.029217736795544624,
                -0.003499621292576194,
                -0.012504268437623978,
                0.05008628964424133,
                0.0162812490016222,
                -0.02145550213754177,
                -0.014056272804737091,
                -0.05105385556817055,
                0.013711541891098022,
                0.004246587865054607,
                -0.032020509243011475,
                0.04470791667699814,
                -0.01695818267762661,
                -0.04132463037967682,
                -0.023762846365571022,
                -0.022492602467536926,
                0.012425927445292473,
                0.025197375565767288,
                -0.021808961406350136,
                0.03628621995449066,
                -0.025878386572003365,
                -0.01694311946630478,
                0.015029230155050755,
                -0.08368907123804092,
                0.0014739857288077474,
                0.05797838419675827,
                0.017630841583013535,
                -0.015429824590682983,
                -0.016971230506896973,
                0.029269862920045853,
                -0.01762494258582592,
                -0.011579572223126888,
                0.01896878518164158,
                0.021484041586518288,
                -0.00309236953034997,
                0.01031275000423193,
                -0.007115155458450317,
                -0.00675248634070158,
                0.04653460159897804,
                -0.05424957349896431,
                0.013211187906563282,
                -0.05851694196462631,
                0.0011831779265776277,
                -0.0003454813559073955,
                -0.0005328156985342503,
                0.04823048412799835,
                -0.00028998006018809974,
                0.05670536309480667,
                -0.013715505599975586,
                0.014385390095412731,
                -0.03198270872235298,
                -0.028305180370807648,
                -0.018371425569057465,
                -0.008939587511122227,
                -0.0852120891213417,
                0.023201381787657738,
                0.010069896467030048,
                -0.0039812009781599045,
                0.05223283916711807,
                -0.02220558375120163,
                0.050561826676130295,
                -0.007504620123654604,
                -0.03354834392666817,
                -0.018558714538812637,
                -0.026777854189276695,
                0.020581040531396866,
                0.0320516861975193,
                0.06164407357573509,
                -0.09824655950069427,
                -0.05687570944428444,
                -0.05291968584060669,
                -0.06918734312057495,
                0.01900053583085537,
                -0.03369873762130737,
                0.04627294838428497,
                -0.02084694802761078,
                -0.009196868166327477,
                -0.01141311228275299,
                -0.023252179846167564,
                -0.03386619687080383,
                0.03201461210846901,
                0.01760716177523136,
                0.038415826857089996,
                0.06014614179730415,
                -0.00025093473959714174,
                0.007725215516984463,
                0.010791830718517303,
                -0.010854670777916908,
                0.04193330928683281,
                -0.03654814884066582,
                0.02553497813642025,
                0.007371361367404461,
                -0.0004624089051503688,
                -0.04716826230287552,
                0.01856316439807415,
                0.05690056085586548,
                0.03202642872929573,
                -0.029642285779118538,
                0.026189865544438362,
                -0.051867228001356125,
                0.07483971863985062,
                -0.02670804038643837,
                -0.02902618795633316,
                -0.0022307976614683867,
                0.030087320134043694,
                0.014682932756841183,
                0.006848953664302826,
                0.041425880044698715,
                0.02189483866095543,
                0.02119588851928711,
                0.009625512175261974,
                0.021294670179486275,
                0.018620533868670464,
                -0.042063500732183456,
                -0.008102775551378727,
                0.012691870331764221,
                -0.04845333844423294,
                0.022471655160188675,
                0.06876073032617569,
                0.019757216796278954,
                0.04346749186515808,
                -0.008054628036916256,
                0.024055270478129387,
                0.020639048889279366,
                -0.0373128280043602,
                -0.04983457550406456,
                -0.038594502955675125,
                -8.63881892147235e-33,
                0.028644639998674393,
                0.015444159507751465,
                -0.03474181517958641,
                -0.039323560893535614,
                -0.08081507682800293,
                -0.04305911436676979,
                0.04458017274737358,
                -0.04660461097955704,
                0.05516544356942177,
                -0.005219907965511084,
                -0.016149679198861122,
                -0.0010885137598961592,
                0.031946972012519836,
                0.04627924785017967,
                0.0407210998237133,
                0.06852675974369049,
                0.03490067645907402,
                -0.013971448875963688,
                -0.05328110232949257,
                -0.05038077384233475,
                0.0038257997948676348,
                0.030202705413103104,
                0.03799966722726822,
                0.05875394493341446,
                -0.04712223261594772,
                0.00022277521202340722,
                -0.017254048958420753,
                0.013134926557540894,
                0.03237289562821388,
                0.0169416144490242,
                0.0019591536838561296,
                0.0401361882686615,
                0.0015036164550110698,
                0.060757044702768326,
                0.025195226073265076,
                -0.009330305270850658,
                -0.002261500805616379,
                0.054110873490571976,
                0.0022664647549390793,
                0.04299067705869675,
                -0.027063045650720596,
                -0.007226074580103159,
                0.05253739282488823,
                -0.0009996906155720353,
                0.00983084924519062,
                -0.002011987380683422,
                -0.02158639021217823,
                0.014499608427286148,
                0.015305355191230774,
                -0.04802033677697182,
                -0.006310456898063421,
                0.017038190737366676,
                -0.009655892848968506,
                0.005528759676963091,
                0.007619836367666721,
                -0.04020450636744499,
                0.0019360899459570646,
                0.0033150366507470608,
                -0.08595524728298187,
                -0.006285672541707754,
                -0.04489810764789581,
                -0.001536308671347797,
                -0.03323795273900032,
                -0.011467869393527508,
                -0.028413238003849983,
                -0.08633065223693848,
                -0.0019211494363844395,
                -0.020108431577682495,
                -0.053242020308971405,
                0.017965946346521378,
                0.00376865454018116,
                -0.002438449067994952,
                0.007423819042742252,
                -0.05975659191608429,
                0.0005697077722288668,
                -0.0006386852473951876,
                -0.054538387805223465,
                0.006846814416348934,
                -0.02591751702129841,
                0.06260371208190918,
                -0.0010779789881780744,
                0.001655740663409233,
                -0.05990837514400482,
                -0.009966181591153145,
                -0.04600266367197037,
                0.06269373744726181,
                -0.008645908907055855,
                -0.007119693327695131,
                -0.0007714558159932494,
                0.007553751114755869,
                -0.026946181431412697,
                -0.019753962755203247,
                0.0020584026351571083,
                0.008724723942577839,
                0.034822121262550354,
                -0.004762060474604368,
                0.010219406336545944,
                -0.06691978126764297,
                0.019502578303217888,
                -0.01905878260731697,
                0.006871487013995647,
                -0.0002749391132965684,
                -0.028572695329785347,
                0.027784589678049088,
                0.005766109097748995,
                -0.04818156361579895,
                -0.00490457471460104,
                -0.016462737694382668,
                -0.07700218260288239,
                0.00427034730091691,
                -0.014884931966662407,
                0.03213491290807724,
                0.03345779702067375,
                0.0016001265030354261,
                -0.002821399364620447,
                -0.021176092326641083,
                0.03389362618327141,
                -0.06832414865493774,
                -0.04197420924901962,
                0.05146249383687973,
                0.014105153270065784,
                0.09791374206542969,
                -0.02466636523604393,
                0.0666828453540802,
                -0.029357103630900383,
                0.016490817070007324,
                0.040256161242723465,
                0.016913888975977898,
                -0.08179117739200592,
                -0.00885259173810482,
                -0.014546098187565804,
                -0.03450746834278107,
                3.1058297622621467e-07,
                -0.05788275972008705,
                0.0008034489001147449,
                0.014243103563785553,
                -0.0011482390109449625,
                0.05399245023727417,
                0.026986954733729362,
                0.010158869437873363,
                0.05042131245136261,
                -0.014743725769221783,
                0.0148548549041152,
                -0.025046145543456078,
                -0.027215126901865005,
                0.0034596773330122232,
                -0.04065726324915886,
                0.02675524540245533,
                0.03620026260614395,
                0.002579045481979847,
                0.0015242535155266523,
                -0.007865811698138714,
                0.0031746188178658485,
                0.029470952227711678,
                0.0036325445398688316,
                0.04937538504600525,
                0.015178299508988857,
                0.013546918518841267,
                0.005357848946005106,
                -0.009475247003138065,
                0.003543613711372018,
                0.007679401431232691,
                -0.030472058802843094,
                -0.013926592655479908,
                -0.02366655319929123,
                0.020609058439731598,
                0.06291493773460388,
                -0.008917629718780518,
                0.0031799927819520235,
                0.06994263082742691,
                -0.03419605642557144,
                0.02379583567380905,
                0.0588470920920372,
                -0.020779980346560478,
                -0.02815740555524826,
                -0.045290492475032806,
                -0.007394332904368639,
                0.07038410753011703,
                -0.009242347441613674,
                0.044534288346767426,
                0.023750275373458862,
                -0.03573218733072281,
                0.01302069891244173,
                -0.02059701830148697,
                0.030777357518672943,
                -0.025631219148635864,
                -0.039088547229766846,
                0.020935941487550735,
                0.03988181799650192,
                0.06323442608118057,
                -0.0783676952123642,
                0.028200747445225716,
                0.03220818564295769,
                -0.004777818918228149,
                -0.060060907155275345,
                -0.05955314263701439,
                0.05676431953907013,
                0.04173073545098305,
                -0.014171300455927849,
                -0.03935064747929573,
                1.853762512058291e-34,
                -0.024093138054013252,
                -0.008424748666584492,
                0.009092905558645725,
                0.07271590083837509,
                0.06624288856983185,
                -0.010782557539641857,
                0.08440281450748444,
                -0.025564443320035934,
                0.02085595577955246,
                -0.06650115549564362,
                -0.04178479313850403,
                -0.03056894801557064,
                -0.02933693118393421,
                0.00562119297683239,
                0.011380847543478012,
                0.012478264980018139,
                -0.0025166834238916636,
                0.009935583919286728,
                0.012978470884263515,
                0.01845521852374077,
                -0.025207143276929855,
                0.03593483939766884,
                0.029718918725848198,
                0.030623923987150192,
                0.015118534676730633,
                0.042949989438056946,
                -0.030982505530118942,
                0.0376749224960804,
                -0.033971838653087616,
                -0.10866560786962509,
                0.011835739016532898,
                -0.017243068665266037,
                0.02679947391152382,
                0.003734141355380416,
                0.033013831824064255,
                -0.009091682732105255,
                0.008492645807564259,
                0.016056232154369354,
                -0.011139538139104843,
                0.038922153413295746,
                -0.012082180939614773,
                0.06351882964372635,
                -0.012028162367641926,
                0.05782323330640793,
                -0.036624640226364136,
                1.4296335848484887e-06,
                0.020122280344367027,
                0.019343944266438484,
                -0.0038905879482626915,
                -0.0028193097095936537,
                -0.04499896615743637,
                0.016202151775360107,
                -0.015677381306886673,
                -0.028732821345329285,
                0.041862450540065765,
                0.02614077553153038,
                -0.07915908098220825,
                0.021778268739581108,
                -0.023248521611094475,
                0.013224597088992596,
                0.008164769969880581,
                -0.031515397131443024,
                0.04692448303103447,
                -0.06400322169065475,
                0.006855017505586147,
                -0.008454738184809685,
                0.030358048155903816,
                -0.005420381668955088,
                0.01013921294361353,
                0.05539758503437042,
                0.03888079151511192,
                -0.03790280967950821,
                0.009880844503641129,
                -0.0002998575218953192,
                -0.005123662296682596,
                -0.004653585609048605,
                0.0015253601595759392,
                0.012969802133738995,
                -0.04841548576951027,
                0.001661035930737853,
                0.05724966898560524,
                0.01742332987487316,
                0.007728963159024715,
                -0.0021562366746366024,
                0.0729602575302124,
                -0.035779960453510284,
                -0.014709306880831718,
                0.006201998330652714,
                -0.0016155263874679804,
                -0.0011078796815127134,
                -0.04363293945789337,
                -0.0003117680025752634,
                0.05280619487166405,
                -0.023589400574564934,
                0.015072673559188843,
                0.08965056389570236,
                0.049553606659173965,
                -0.0022080400958657265,
                0.006209719926118851,
                0.007797372527420521,
                0.014195512048900127,
                0.03736856207251549,
                -0.009206067770719528,
                -0.018458368256688118,
                0.024824228137731552,
                -0.010275257751345634,
                -0.006569951307028532,
                0.03271544352173805,
                -0.0036049431655555964,
                0.0704800933599472,
                -0.0755971148610115,
                -0.03517620638012886,
                -0.013366609811782837,
                0.01155699323862791,
                0.012478409335017204,
                -0.006574384402483702,
                -0.04165259003639221,
                -0.0033640002366155386,
                0.000866935879457742,
                -0.011256867088377476,
                0.053938835859298706,
                -0.0008887879666872323,
                -0.008429114706814289,
                -0.014742603525519371,
                -0.03251918405294418,
                0.025111926719546318,
                0.032541584223508835,
                -0.005378203000873327,
                0.0007902428624220192,
                -0.01666959375143051,
                0.0015039866557344794,
                0.05449678748846054,
                0.009162761270999908,
                -0.017465131357312202,
                0.044181257486343384,
                0.07449882477521896,
                -0.014427950605750084,
                -0.04729197174310684,
                0.04317278042435646,
                0.021539608016610146,
                0.0038235215470194817,
                -0.00022721628192812204,
                0.009800797328352928,
                4.778429229190806e-06,
                -0.02484949305653572,
                -0.028391720727086067,
                0.10092078894376755,
                -0.003915408160537481,
                -0.030217992141842842,
                -0.10470698773860931,
                -0.013994216918945312,
                0.051721490919589996,
                0.03417631983757019,
                -0.01874653808772564,
                0.054946426302194595,
                -0.0013582143001258373,
                -0.01704264245927334,
                -0.029344875365495682,
                -0.02923690713942051,
                -0.023308096453547478,
                0.00827611144632101,
                -0.019977301359176636,
                -0.056514617055654526,
                -0.020460940897464752,
                -0.025585100054740906,
                0.026459118351340294,
                0.049978435039520264,
                0.005952613428235054,
                -0.03514856845140457,
                0.03748607635498047,
                0.01489337533712387,
                -0.0007516394252888858,
                0.005533813498914242,
                -0.03325601667165756,
                -0.04287145659327507,
                -0.04044850915670395,
                0.010017231106758118,
                0.017993224784731865,
                -0.005609314888715744,
                -0.03377601504325867,
                0.018099768087267876,
                0.007280038204044104,
                0.03333659470081329,
                -0.06374657899141312,
                0.08235064893960953,
                0.009391306899487972,
                -0.05758688226342201,
                0.000616546138189733,
                -0.03711838275194168,
                -0.027046827599406242,
                0.034897759556770325,
                -0.014853798784315586,
                -0.07099922746419907,
                0.00870142225176096,
                -0.02474024146795273,
                0.016194839030504227,
                -0.005357660353183746,
                0.038146622478961945,
                0.020705968141555786,
                -0.035005729645490646,
                -0.025243287906050682,
                0.006459411233663559,
                -0.0008372252923436463,
                0.03316080570220947,
                -0.005296104121953249,
                -0.0026704026386141777,
                -0.021946851164102554,
                -0.022567087784409523,
                0.01140831783413887,
                0.0004367814108263701,
                -0.03379359841346741,
                -0.020209796726703644,
                0.004385306965559721,
                0.028207125142216682,
                0.05714857578277588,
                -0.07474934309720993,
                0.03494463115930557,
                -0.024574916809797287,
                -0.07071187347173691,
                -0.06291878968477249,
                -0.09472434967756271,
                -0.047255437821149826,
                -0.012484884820878506,
                0.08609716594219208,
                0.017528120428323746,
                -0.02156878635287285,
                -0.005344085395336151,
                0.038521282374858856,
                0.011563948355615139,
                -0.03304802626371384,
                0.08659620583057404,
                -0.0104709817096591,
                0.011567693203687668,
                -0.04197010025382042,
                0.027365611866116524,
                -0.00039450087933801115,
                -0.019442107528448105,
                -0.045075248926877975,
                0.017664767801761627,
                -0.06010580062866211,
                -0.02423444576561451,
                0.013262966647744179,
                0.018548956140875816,
                -0.010942691937088966,
                0.019239632412791252,
                -0.03592413663864136,
                0.03646378964185715,
                0.05858074501156807,
                0.0749848410487175,
                0.03846956416964531,
                -0.038465410470962524,
                0.0030455223750323057,
                0.005168905947357416,
                0.017577536404132843,
                0.012270726263523102,
                -0.029489221051335335,
                0.03735346347093582,
                -0.03385442495346069,
                0.0371762253344059,
                0.002249813638627529,
                0.027051232755184174,
                -0.02134959027171135,
                -0.02666965313255787,
                0.006686569191515446,
                0.036195725202560425,
                -0.027785150334239006,
                -0.03700874000787735,
                0.013256185688078403,
                0.05273498594760895,
                -0.03869367018342018,
                -0.019519420340657234,
                -0.015183915384113789,
                -0.03624372556805611,
                0.020345821976661682,
                -0.02585885114967823,
                0.02683859132230282,
                -0.05712437257170677,
                0.07980484515428543,
                -0.006514735985547304,
                0.005420885514467955,
                -0.02738056145608425,
                -0.007066724821925163,
                -0.006979272700846195,
                0.010365934111177921,
                0.0013990089064463973,
                -0.01282499823719263,
                0.03975246474146843,
                -0.017612723633646965,
                -0.006830458529293537,
                -0.004475957714021206,
                0.007232440169900656,
                0.00581861287355423,
                -0.0010382450418546796,
                -0.016386184841394424,
                -0.028528645634651184,
                -0.006023040972650051,
                0.08473371714353561,
                0.0386815071105957,
                -0.05241721123456955,
                -0.06871042400598526,
                0.07341879606246948,
                0.03895483911037445,
                0.01255121361464262,
                0.02318562939763069,
                -0.03050338476896286,
                -0.04047321528196335,
                0.02132171206176281,
                -0.009539347141981125,
                -0.0101893600076437,
                0.021448062732815742,
                0.0356702096760273,
                -0.03745188191533089,
                0.010961118154227734,
                -0.019972074776887894,
                -0.004874935373663902,
                0.006585506722331047,
                0.01564016006886959,
                0.0746341198682785,
                0.014903653413057327,
                -0.0472235307097435,
                -0.026646509766578674,
                -0.06702794879674911,
                -0.025623207911849022,
                0.046942148357629776,
                0.0695805773139,
                0.002961372956633568,
                -0.009607256390154362,
                0.03312503546476364,
                -0.06728862971067429,
                0.0266379714012146,
                0.07719839364290237,
                0.05573570355772972,
                -0.02164177969098091,
                -0.03551264852285385,
                0.02787080593407154,
                0.01711525395512581,
                -0.007109255064278841,
                0.014674740843474865,
                0.026522688567638397,
                -0.015950428321957588,
                -0.06249493733048439,
                0.009544950909912586,
                0.05157352238893509,
                -0.0008151045185513794,
                0.024326350539922714,
                -0.035889264196157455,
                -0.001917580608278513,
                0.005398979876190424,
                -0.08504939824342728,
                -0.04487130790948868,
                0.04098333418369293,
                0.003457916434854269,
                -0.09015269577503204,
                -0.017527485266327858,
                -0.020319435745477676,
                0.03951854258775711,
                -0.00578762823715806,
                -0.03378915786743164,
                0.03292253613471985,
                -0.03301791846752167,
                0.013477097265422344,
                0.030509239062666893,
                0.011277062818408012,
                -0.008443586528301239,
                0.0020314843859523535,
                -0.026729347184300423,
                0.03810025379061699,
                0.036159493029117584,
                -0.033439673483371735,
                0.016735699027776718,
                -0.021147076040506363,
                -0.002017634455114603,
                0.02653992548584938,
                -0.008362362161278725,
                -0.028393471613526344,
                -0.02668256126344204,
                0.02395441383123398,
                0.0011859488440677524,
                0.041977327316999435,
                -0.04477918520569801,
                0.003593070898205042,
                -0.004372189752757549,
                0.038466379046440125,
                -0.02647155337035656,
                -0.037562787532806396,
                -0.032215435057878494,
                0.08138847351074219,
                0.03671582415699959,
                0.012912534177303314,
                -0.03166481480002403,
                -0.057915620505809784,
                0.03850367292761803,
                0.07914658635854721,
                0.05657077580690384,
                0.016589751467108727,
                -0.0198365431278944,
                0.005392478778958321,
                -0.010409447364509106,
                0.012064631097018719,
                0.055097609758377075,
                0.056447748094797134,
                0.015417248010635376,
                -0.08380957692861557,
                -0.054919272661209106,
                0.029861005023121834,
                0.01795853301882744,
                -0.03702324628829956,
                0.07823474705219269,
                0.020665422081947327,
                -0.014442899264395237,
                0.03269997984170914,
                0.02712542936205864,
                0.01928677223622799,
                -0.08107797801494598,
                0.007862543687224388,
                -0.043483469635248184,
                -0.026037566363811493,
                0.06449595838785172,
                -0.06895379722118378,
                -0.01322952564805746,
                -0.00585580337792635,
                0.041541822254657745,
                -0.027481192722916603,
                -0.01478953193873167,
                -0.019697781652212143,
                -0.011369411833584309,
                -0.04337313026189804,
                -0.01773497648537159,
                -0.009192160330712795,
                0.029605666175484657,
                -0.005371526814997196,
                -0.015481839887797832,
                0.012934058904647827,
                -0.010466646403074265,
                -0.03478644788265228,
                0.0027185685466974974,
                -0.03963398188352585,
                0.06566781550645828,
                0.048300690948963165,
                -0.024676213040947914,
                0.0008532382198609412,
                -0.01398239005357027,
                -0.029300019145011902,
                -0.036224525421857834,
                0.010312632657587528,
                -0.024987759068608284,
                0.0091715008020401,
                -0.030227815732359886,
                -0.08411858975887299,
                -0.07064507901668549,
                -0.024273725226521492,
                -0.002678916323930025,
                0.024284230545163155,
                0.05724743381142616,
                -0.012064014561474323,
                0.0013708455953747034,
                0.008456691168248653,
                0.024533409625291824,
                0.02026606909930706,
                0.03280380368232727,
                0.04158853366971016,
                0.04282219335436821,
                0.02306724712252617,
                0.07660263031721115,
                0.03142446279525757,
                0.05814743787050247,
                -0.036483343690633774,
                0.010668663308024406,
                -0.004072700627148151,
                -0.054936062544584274,
                0.05827009677886963,
                0.07318782806396484,
                0.04249640554189682,
                -0.02014908194541931,
                0.011431426741182804,
                0.045524612069129944,
                -0.019526630640029907,
                -0.035256315022706985,
                0.004751038737595081,
                -0.054659441113471985,
                -0.022692134603857994,
                -0.031091492623090744,
                0.02759179100394249,
                -0.023512793704867363,
                0.01651882380247116,
                0.0007606681901961565,
                -0.016419949010014534,
                -0.008685228414833546,
                -0.022007495164871216,
                0.004859278444200754,
                0.02933366782963276,
                0.037472568452358246,
                -0.007353933993726969,
                0.005886548198759556,
                0.05528685450553894,
                -0.05163324624300003,
                0.04804506525397301,
                0.07490874826908112,
                0.001524431281723082,
                -0.018564380705356598,
                0.05867919325828552,
                -0.021643662825226784,
                -0.03511936590075493,
                -0.03563766926527023,
                0.0049804565496742725,
                0.03611515089869499,
                0.04501780867576599,
                0.005816075950860977,
                0.04245540499687195,
                -0.020574448630213737,
                -0.0406620167195797,
                0.02705143578350544,
                0.04058148339390755,
                -0.007249456364661455,
                -0.014716967940330505,
                -0.0024506058543920517,
                0.02009672112762928,
                0.014014366082847118,
                0.0021582581102848053,
                0.018767446279525757,
                0.016735974699258804,
                -0.030254913493990898,
                0.0347394198179245,
                0.018793294206261635,
                0.020299509167671204,
                -0.027229461818933487,
                0.02086697146296501,
                -0.06277076154947281,
                -0.010017119348049164,
                -0.025527194142341614,
                -0.01789752021431923,
                0.02050979994237423,
                -0.041236408054828644,
                0.03593500331044197,
                -0.006198757328093052,
                -0.004210692830383778,
                0.02149823121726513,
                0.011656864546239376,
                0.02002137154340744,
                -0.0670507550239563,
                0.08271604031324387,
                0.03354841098189354,
                -0.03410559520125389,
                -0.017009297385811806,
                0.014427918940782547,
                -0.018873339518904686,
                0.04835246875882149,
                0.036657676100730896,
                0.021777302026748657,
                -0.009163198992609978,
                -0.009351411834359169,
                -0.015444410964846611,
                -0.06209949776530266,
                0.02620234340429306,
                0.016667328774929047,
                0.00902349129319191,
                0.02344593219459057,
                -0.0024510466028004885,
                -0.04407164454460144,
                0.061172690242528915,
                -0.0687459409236908,
                0.03675979748368263,
                -0.023168036714196205,
                -0.018059536814689636,
                0.011609768494963646,
                -0.0018073507817462087,
                -5.44432155495861e-33,
                -0.026076287031173706,
                -0.03339064493775368,
                -0.005278128664940596,
                0.00188030069693923,
                -0.04987606778740883,
                -0.008188729174435139,
                -0.003581180004402995,
                0.00833822600543499,
                -0.033025745302438736,
                -0.0261091161519289,
                -0.013967186212539673,
                0.018824467435479164,
                0.04378983750939369,
                -0.0029530993197113276,
                0.056407250463962555,
                -0.08172918111085892,
                -0.03558296337723732,
                0.01491470541805029,
                -0.018131082877516747,
                -0.050598353147506714,
                -0.011022030375897884,
                0.0061349933966994286,
                0.01884879544377327,
                0.02066505327820778,
                0.01763438619673252,
                0.018294362351298332,
                -0.023772018030285835,
                -0.0673840194940567,
                -0.04802278056740761,
                -0.0035351114347577095,
                0.02709590457379818,
                -0.031864479184150696,
                0.03088396228849888,
                -0.029203083366155624,
                -0.04853901267051697,
                0.11127500236034393,
                -0.024399863556027412,
                -0.025872139260172844,
                -0.004153104964643717,
                -0.03879654407501221,
                -0.10961227118968964,
                -0.06895749270915985,
                0.0378849022090435,
                -0.01609678380191326,
                -0.01113230362534523,
                -0.04138205200433731,
                -0.047519538551568985,
                -0.021306509152054787,
                0.015843480825424194,
                -0.034124113619327545,
                -0.013044451363384724,
                -0.0436997264623642,
                0.009941397234797478,
                0.02805737778544426,
                0.03387405723333359,
                0.04603786766529083,
                0.03493277356028557,
                -0.0069143581204116344,
                0.01907876506447792,
                -0.051675695925951004,
                -0.033604007214307785,
                0.0010519667994230986,
                0.007753034122288227,
                -0.0029488936997950077,
                -0.03541156277060509,
                -0.028004422783851624,
                -0.1356794387102127,
                0.04458914324641228,
                -0.0012154930736869574,
                -0.04878547415137291,
                -0.021848609670996666,
                0.06767808645963669,
                -0.0394924059510231,
                -0.0398031584918499,
                -0.07660654187202454,
                -0.013621458783745766,
                -0.08618056029081345,
                0.022361785173416138,
                0.00858132354915142,
                0.07708393782377243,
                -0.04056813567876816,
                -0.018795963376760483,
                0.02631775662302971,
                -0.015221605077385902,
                -0.0627179816365242,
                0.06513029336929321,
                -0.010007962584495544,
                0.06230909377336502,
                -0.005617835093289614,
                0.028126461431384087,
                -0.0015536525752395391,
                -0.026929577812552452,
                -0.04915003851056099,
                0.05070384591817856,
                0.07279936224222183,
                0.01576019823551178,
                0.04619193822145462,
                -0.027288496494293213,
                0.02207121253013611,
                0.059105921536684036,
                -0.00442930543795228,
                0.040926896035671234,
                -0.0703015998005867,
                0.030649229884147644,
                0.0077045694924890995,
                -0.01894363760948181,
                -0.038979556411504745,
                0.020425383001565933,
                0.007679715286940336,
                0.007968205958604813,
                0.007811825256794691,
                0.029400108382105827,
                0.003584933001548052,
                -0.032991379499435425,
                0.00746192829683423,
                -0.02204928733408451,
                0.0233452245593071,
                -0.013200027868151665,
                0.0028009710367769003,
                -0.011549235321581364,
                0.06869817525148392,
                0.008521389216184616,
                0.003809444373473525,
                0.011213666759431362,
                0.02376636676490307,
                -0.014229346066713333,
                0.08508627861738205,
                -0.02438483200967312,
                0.012454758398234844,
                -0.024337923154234886,
                0.018619973212480545,
                0.011078246869146824,
                1.9311349319650617e-07,
                -0.0032348502427339554,
                0.021538203582167625,
                0.028299856930971146,
                0.058423012495040894,
                0.047792091965675354,
                0.0013135612243786454,
                -0.027451569214463234,
                0.055296093225479126,
                -0.04635221138596535,
                0.020317725837230682,
                0.022349411621689796,
                -0.0302130039781332,
                -0.023590857163071632,
                -0.004860938526690006,
                0.016218941658735275,
                -0.05284147337079048,
                0.006141081918030977,
                -0.03443131968379021,
                -0.0005597450071945786,
                -0.014859287068247795,
                0.04485300928354263,
                0.011127789504826069,
                -0.06571372598409653,
                -0.013203752227127552,
                -0.018856190145015717,
                -0.020951448008418083,
                0.025916505604982376,
                -0.07404264807701111,
                0.058455940335989,
                0.0035165217705070972,
                0.04788065701723099,
                0.06918343901634216,
                0.026107588782906532,
                0.03776662424206734,
                -0.0024245574604719877,
                -0.06570654362440109,
                0.04119526967406273,
                0.05140175297856331,
                -0.00031213866895996034,
                0.018209444358944893,
                0.006016446277499199,
                -0.08653807640075684,
                -0.0347190797328949,
                0.021739011630415916,
                0.033325448632240295,
                0.005852266680449247,
                0.043557580560445786,
                0.038775037974119186,
                -0.09215369820594788,
                0.02063256874680519,
                -0.006247617769986391,
                0.0074636442586779594,
                -0.033227115869522095,
                -0.008101050741970539,
                0.007013835944235325,
                -0.017912639304995537,
                -0.0015979221789166331,
                0.057795993983745575,
                0.06690932810306549,
                -0.006486205849796534,
                0.0036937459371984005,
                -0.03793499246239662,
                0.006467925384640694,
                0.04441579431295395,
                -0.0002970501664094627,
                -0.040932077914476395,
                -0.0617346353828907,
                9.83812892559554e-35,
                -0.0034054014831781387,
                -0.06177634373307228,
                -0.06384485214948654,
                -0.038806986063718796,
                0.014066043309867382,
                -0.018641123548150063,
                -0.006098958197981119,
                -0.014410092495381832,
                -0.01696670427918434,
                -0.04235212132334709,
                -0.03951160982251167,
                -0.03459421172738075,
                0.021337362006306648,
                -0.023452574387192726,
                -0.02287900820374489,
                -0.030936898663640022,
                -0.004226396791636944,
                0.031007075682282448,
                -0.005348594859242439,
                -0.03639939799904823,
                0.029682524502277374,
                0.01776379905641079,
                -0.04104940593242645,
                0.024225803092122078,
                0.027705423533916473,
                0.04738488048315048,
                0.026859818026423454,
                -0.00596286915242672,
                -0.031037677079439163,
                -0.09567566961050034,
                -0.02521110698580742,
                -0.06793376058340073,
                0.025989733636379242,
                -0.037841517478227615,
                -0.005052355118095875,
                0.10082544386386871,
                0.02789604291319847,
                -0.02562134712934494,
                -0.03570067510008812,
                -0.018843654543161392,
                0.0663532093167305,
                0.05077635496854782,
                -0.025800270959734917,
                0.04817620664834976,
                -0.008338111452758312,
                1.9375372630747734e-06,
                0.0302492156624794,
                0.010483812540769577,
                0.011663508601486683,
                0.031019091606140137,
                0.0060187289491295815,
                -0.04714066907763481,
                0.02526627480983734,
                -0.019174937158823013,
                0.006248445250093937,
                -0.022843563929200172,
                -0.06984834372997284,
                0.04803682491183281,
                -0.08967990428209305,
                -0.049009378999471664,
                -0.00019921077182516456,
                0.0016203990671783686,
                0.021467870101332664,
                -0.10664283484220505,
                0.008847915567457676,
                -0.02885657362639904,
                0.0007928615668788552,
                -0.032716717571020126,
                0.005288232583552599,
                -0.0771842673420906,
                -0.0031638338696211576,
                0.018637463450431824,
                -0.006967802532017231,
                0.005030353553593159,
                -0.009589861147105694,
                0.013041848316788673,
                0.009750757366418839,
                0.022094091400504112,
                0.014833847060799599,
                0.03136131167411804,
                0.007967855781316757,
                -0.02836509235203266,
                0.04515443742275238,
                -0.04327433556318283,
                0.03833826631307602,
                -0.03079768642783165,
                0.02360815368592739,
                -0.0020061093382537365,
                0.08783050626516342,
                0.022443478927016258,
                0.02101895585656166,
                0.0009213748853653669,
                0.01739952713251114,
                0.002741959411650896,
                0.05093522369861603,
                -0.05890018120408058,
                0.06201095134019852,
                -0.009562702849507332,
                0.029965953901410103,
                -0.05005090683698654,
                0.033854492008686066,
                -0.07080212235450745,
                -0.03112204000353813,
                0.01923048309981823,
                -0.0004401815531309694,
                0.04990019276738167,
                0.0026978743262588978,
                0.026956884190440178,
                0.03558692708611488,
                0.006972619332373142,
                -0.03799136355519295,
                0.016073813661932945,
                0.015975870192050934,
                -0.040369465947151184,
                0.06341776251792908,
                0.010483779944479465,
                0.007965353317558765,
                0.012732471339404583,
                -0.024376748129725456,
                0.005533967167139053,
                0.04579824581742287,
                -0.01604989543557167,
                -0.010663967579603195,
                -0.016812600195407867,
                0.026192422956228256,
                -0.04442644491791725,
                -0.009684634394943714,
                0.004836273845285177,
                -0.0037405923940241337,
                0.04761989787220955,
                0.0019028204260393977,
                0.05249091982841492,
                -0.00135144277010113,
                -0.01234299223870039,
                0.028400056064128876,
                0.0025206725113093853,
                0.05058792978525162,
                -0.01902950555086136,
                0.034326642751693726,
                0.053605448454618454,
                -0.01847577467560768,
                -0.029348323121666908,
                0.011843740940093994,
                -0.004037303384393454,
                -0.058826517313718796,
                -0.012739496305584908,
                -0.0287367831915617,
                -0.001219496363773942,
                -0.01137172244489193,
                0.04714962840080261,
                -0.03234877437353134,
                0.01291399821639061,
                0.01641618274152279,
                -0.022569499909877777,
                0.003685350064188242,
                -0.0018001977587118745,
                -0.06929351389408112,
                0.006045053713023663,
                0.03262341395020485,
                -0.01440129429101944,
                -0.006351792719215155,
                0.08832769840955734,
                -0.010365355759859085,
                0.013559185899794102,
                -0.003113744081929326,
                0.025350023061037064,
                0.005851444788277149,
                0.03912390023469925,
                -0.016334014013409615,
                -0.00038898002821952105,
                0.0056273299269378185,
                -0.0006251209415495396,
                -0.04889044910669327,
                -0.031082633882761,
                0.02314637415111065,
                0.0004927991540171206,
                0.011830239556729794,
                -0.03345244750380516,
                0.004063212778419256,
                0.021414289250969887,
                -0.009567363187670708,
                0.0009552791016176343,
                -0.04851560667157173,
                -0.05413627624511719,
                0.054348718374967575,
                0.04075780510902405,
                -0.043033406138420105,
                -0.03675706312060356,
                -0.061008233577013016,
                0.04892394691705704,
                0.004172209650278091,
                -0.07391781359910965,
                -0.15255165100097656,
                -0.012937421910464764,
                -0.06012922525405884,
                0.03178955614566803,
                0.04291656240820885,
                0.031083744019269943,
                0.07449384033679962,
                -0.051371946930885315,
                -0.0005313042202033103,
                0.03355010598897934,
                -0.014478739351034164,
                -0.05749602988362312,
                0.0454920195043087,
                0.0027059854473918676,
                -0.004212314262986183,
                0.048597488552331924,
                0.012125718407332897,
                -0.04329364374279976,
                0.030348526313900948,
                -0.029438940808176994,
                0.004070973955094814,
                0.02571813017129898,
                0.021511917933821678,
                -0.03707854077219963,
                0.02205178141593933,
                -0.03605664148926735,
                -0.018407756462693214,
                -0.02809707447886467,
                0.006160267628729343,
                -0.025274081155657768,
                0.012388688512146473,
                0.049911018460989,
                0.050315532833337784,
                -0.01610507443547249,
                0.011012759059667587,
                0.007040387485176325,
                0.03263532742857933,
                0.006544148083776236,
                0.06766118109226227,
                -0.03866714611649513,
                -0.08887665718793869,
                0.02286488749086857,
                0.02853933721780777,
                -0.004263687878847122,
                -0.01570250280201435,
                -0.0122406966984272,
                -0.0051808967255055904,
                0.006436995230615139,
                -0.014877608977258205,
                -0.022469017654657364,
                -0.010663500986993313,
                0.024652017280459404,
                -0.025668974965810776,
                -0.04363042861223221,
                0.04211224243044853,
                0.02065056562423706,
                0.03269125893712044,
                0.005440123844891787,
                -0.053028929978609085,
                0.04233379289507866,
                -0.0752350315451622,
                -0.033231187611818314,
                0.02339046262204647,
                0.001712584518827498,
                0.08496833592653275,
                -0.022970015183091164,
                0.029936889186501503,
                0.024647090584039688,
                0.014098279178142548,
                0.018672000616788864,
                0.004897329956293106,
                0.03408509120345116,
                -0.030918948352336884,
                0.05508669838309288,
                -0.07331845164299011,
                0.03683652728796005,
                0.061032768338918686,
                -0.047635093331336975,
                0.006711780559271574,
                0.012297550216317177,
                -0.034436240792274475,
                -0.012578356079757214,
                0.003577100345864892,
                0.04216190055012703,
                -0.015825239941477776,
                0.03256699815392494,
                0.010166914202272892,
                0.02018558979034424,
                -0.0008691279217600822,
                0.03803083673119545,
                0.026473110541701317,
                0.022407323122024536,
                -0.001906064455397427,
                -0.007948346436023712,
                -0.06362296640872955,
                -0.07359626144170761,
                -0.0025193537585437298,
                -0.011481928639113903,
                -0.015486755408346653,
                0.01761121302843094,
                -0.02174447663128376,
                -0.0002541531284805387,
                -0.029704399406909943,
                0.07061418145895004,
                0.05037490278482437,
                -0.025826742872595787,
                -0.02799293026328087,
                -0.12294739484786987,
                -0.04436206445097923,
                -0.03245122730731964,
                -0.03873366490006447,
                0.005886188708245754,
                -0.017324361950159073,
                -0.03208605945110321,
                -0.003907121252268553,
                -0.061762213706970215,
                0.010638064704835415,
                -0.03591565042734146,
                -0.04984543099999428,
                0.04209130257368088,
                0.011369944550096989,
                0.008830896578729153,
                -0.00583221111446619,
                -0.015674319118261337,
                -0.04453752189874649,
                0.04626801609992981,
                0.008465141989290714,
                -0.03648249804973602,
                -0.003264922648668289,
                -0.012467029504477978,
                0.029648032039403915,
                0.04405336081981659,
                0.002200250281020999,
                -0.012386217713356018,
                -0.007438892964273691,
                -0.029293587431311607,
                0.029602808877825737,
                0.008699931204319,
                0.04500630497932434,
                0.05433747544884682,
                -0.02316008135676384,
                -0.045782603323459625,
                -0.01215303037315607,
                0.04286294803023338,
                -0.051863569766283035,
                -0.030298152938485146,
                0.02170264534652233,
                -0.0006466042250394821,
                -0.06427618116140366,
                0.0006470562657341361,
                0.01986568607389927,
                -0.029916610568761826,
                0.04092288762331009,
                0.009089761413633823,
                -0.0025643855333328247,
                0.01663152314722538,
                -0.0943891629576683,
                -0.02032155729830265,
                0.026292352005839348,
                0.016002560034394264,
                -0.031062832102179527,
                0.025130806490778923,
                -0.07739384472370148,
                -0.03710266575217247,
                -0.00532371224835515,
                0.031169958412647247,
                0.009775595739483833,
                0.06391637027263641,
                -0.01779664307832718,
                0.018265413120388985,
                0.07039598375558853,
                -0.028854869306087494,
                -0.008586682379245758,
                -0.04297737404704094,
                0.047418978065252304,
                0.0017923738341778517,
                0.01059704925864935,
                -0.03890145570039749,
                0.008298632688820362,
                -0.054478004574775696,
                -0.009402946569025517,
                -0.062105730175971985,
                -0.015547600574791431,
                0.018610458821058273,
                0.011230369098484516,
                0.028221866115927696,
                0.03509538248181343,
                -0.08287393301725388,
                0.033713143318891525,
                0.02748234197497368,
                0.039491355419158936,
                -0.10898130387067795,
                -0.024999020621180534,
                0.004455021116882563,
                0.02303919568657875,
                -0.009242871776223183,
                0.04558289796113968,
                0.06054360046982765,
                0.011015128344297409,
                -0.00428110221400857,
                0.04580390080809593,
                0.038819365203380585,
                -0.0038545066490769386,
                -0.0014515353832393885,
                -0.007773079909384251,
                -0.0023786539677530527,
                -0.032870784401893616,
                -0.03683660179376602,
                0.022940458729863167,
                0.021253081038594246,
                0.0025705716107040644,
                0.009603367187082767,
                -0.011380651965737343,
                0.007417221087962389,
                0.010347841307520866,
                0.0414184108376503,
                0.0018267958657816052,
                0.03709270805120468,
                0.021607449278235435,
                -0.011860818602144718,
                -0.00723447697237134,
                0.030177423730492592,
                0.021543094888329506,
                -0.056414395570755005,
                -0.038550686091184616,
                -0.014262872748076916,
                -0.027223331853747368,
                0.01521527860313654,
                -0.03590204939246178,
                0.04489555209875107,
                -0.12689174711704254,
                0.035032954066991806,
                0.009315083734691143,
                -0.03394894674420357,
                -0.017010929062962532,
                -0.014917600899934769,
                0.006060004699975252,
                -0.01727316714823246,
                -0.04121289774775505,
                0.04205881431698799,
                -0.010721754282712936,
                0.022669512778520584,
                -0.01285957358777523,
                0.04245399311184883,
                0.06575724482536316,
                0.08091449737548828,
                0.006125451531261206,
                -0.006348673719912767,
                -0.017935950309038162,
                -0.04205194488167763,
                -0.05437300354242325,
                -0.03176915645599365,
                0.007056979928165674,
                0.006095584947615862,
                0.034384991973638535,
                0.02896914631128311,
                0.012464028783142567,
                -0.045326873660087585,
                -0.018431149423122406,
                0.015503130853176117,
                0.007887694053351879,
                0.054143235087394714,
                0.029160046949982643,
                -0.023019835352897644,
                0.03088684007525444,
                -0.033013686537742615,
                0.020410997793078423,
                0.013077504932880402,
                -0.0646326094865799,
                0.04373234882950783,
                0.0909622460603714,
                0.018692100420594215,
                -0.02640293352305889,
                -0.020563287660479546,
                0.0019227499142289162,
                0.03174874186515808,
                0.01576186716556549,
                -0.005140509456396103,
                0.04149293527007103,
                0.0020400143694132566,
                0.029805924743413925,
                -0.02506200410425663,
                -0.018104523420333862,
                0.0278753861784935,
                -0.014850495383143425,
                0.030349986627697945,
                -0.18544505536556244,
                -5.659467205987312e-07,
                -0.04871481657028198,
                0.04912589490413666,
                0.004905313719063997,
                -0.02691775932908058,
                0.009253999218344688,
                -0.035456184297800064,
                0.02847551368176937,
                -0.027105728164315224,
                -0.02348054200410843,
                0.08635962754487991,
                0.016495799645781517,
                -0.014208788052201271,
                0.09048145264387131,
                0.001958905952051282,
                -0.06755160540342331,
                0.006617777515202761,
                0.013397786766290665,
                0.029408670961856842,
                -0.0070886155590415,
                0.0006795277586206794,
                0.016860727220773697,
                0.02995302900671959,
                -0.01198091171681881,
                0.01773524470627308,
                0.0975334569811821,
                0.04478234052658081,
                -0.007869342342019081,
                -0.0032013829331845045,
                -0.04541679471731186,
                0.02658679708838463,
                -0.027903201058506966,
                -0.026859421283006668,
                0.03404960408806801,
                -0.04642704874277115,
                -0.0005826958222314715,
                -0.037844933569431305,
                0.03300516679883003,
                0.0019986366387456656,
                0.0019972831942141056,
                0.021670904010534286,
                -0.026594623923301697,
                -0.034928228706121445,
                0.008466356433928013,
                -0.028572196140885353,
                -0.027972914278507233,
                0.05895693227648735,
                0.02565271221101284,
                -0.016827477142214775,
                -0.06488921493291855,
                -0.004587473347783089,
                0.02672029845416546,
                0.0009895145194604993,
                0.003728004638105631,
                0.003806241089478135,
                -0.02788488194346428,
                -0.02850760892033577,
                -0.023326164111495018,
                0.023993736132979393,
                0.003646420082077384,
                0.011245234869420528,
                -0.02078365534543991,
                -0.026310747489333153,
                -0.029290275648236275,
                0.08140286803245544,
                0.014996733516454697,
                -0.04674162343144417,
                0.10914377123117447,
                0.024777909740805626,
                0.06497311592102051,
                0.005461547523736954,
                0.0004618515959009528,
                0.05817488580942154,
                -0.0064407652243971825,
                -0.03500386327505112,
                0.039435286074876785,
                -0.007755659986287355,
                0.004747430793941021,
                0.043620649725198746,
                0.027880579233169556,
                0.01162264496088028,
                -0.039331648498773575,
                -0.0043868375942111015,
                0.03266742825508118,
                -0.05562806501984596,
                0.020739184692502022,
                -0.07000111043453217,
                -7.957612598331141e-33,
                -0.0609821118414402,
                -0.016667038202285767,
                0.06862308830022812,
                -0.02222205139696598,
                -0.04363715276122093,
                0.007303732447326183,
                -0.001176845864392817,
                0.03830498829483986,
                -0.007194950710982084,
                -0.01840605027973652,
                -0.029297998175024986,
                0.02056252770125866,
                0.027949120849370956,
                0.03140886873006821,
                -0.014962047338485718,
                -0.034908097237348557,
                -0.02870507910847664,
                -0.02636999636888504,
                -0.018187131732702255,
                -0.020766889676451683,
                0.016367290169000626,
                0.004600314889103174,
                0.029623975977301598,
                0.045542359352111816,
                -0.00432313559576869,
                0.008735665120184422,
                -0.04110545665025711,
                0.031541094183921814,
                0.013346006162464619,
                -0.02375330775976181,
                -0.016752686351537704,
                0.009017113596200943,
                -0.009854165837168694,
                -0.003914757166057825,
                -0.01853933371603489,
                -0.05146230757236481,
                0.04174303635954857,
                0.012320823967456818,
                -0.01630549132823944,
                0.0006162757053971291,
                -0.0002017406513914466,
                -0.029427925124764442,
                0.06635084003210068,
                -0.02537190169095993,
                0.005801311228424311,
                -0.04578765854239464,
                -0.01774725131690502,
                -0.01450574491173029,
                -0.008720563724637032,
                0.03753619268536568,
                -0.02167019248008728,
                0.00486860703676939,
                -0.03019621968269348,
                -0.0028434586711227894,
                0.02474437840282917,
                -0.027072802186012268,
                -0.0011101254494860768,
                -0.026468422263860703,
                -0.019749626517295837,
                0.026104744523763657,
                0.0233177337795496,
                0.05663999915122986,
                0.005259443074464798,
                -0.07922286540269852,
                0.01551471184939146,
                -0.00581343611702323,
                -0.004220354370772839,
                0.009648100472986698,
                -0.04814906418323517,
                0.027510905638337135,
                -0.0041407691314816475,
                0.03926489129662514,
                0.01574784331023693,
                -0.06443943828344345,
                -0.0022337252739816904,
                0.021403996273875237,
                -0.03418125957250595,
                -0.02017170377075672,
                -0.0012273434549570084,
                -0.0252637080848217,
                0.04920119419693947,
                -0.008157601580023766,
                0.005666003096848726,
                -0.013903125189244747,
                -0.03761851787567139,
                -0.08526383340358734,
                0.012520059011876583,
                0.103865846991539,
                0.017413906753063202,
                0.03506689891219139,
                0.040036723017692566,
                -0.02720765583217144,
                0.013535764999687672,
                0.037987545132637024,
                0.061564162373542786,
                -0.04802554100751877,
                0.030655771493911743,
                0.005252188071608543,
                0.00547313317656517,
                -0.029863858595490456,
                0.03533828631043434,
                0.0137863140553236,
                -0.05913929641246796,
                -0.0418216735124588,
                0.014765102416276932,
                0.0449453666806221,
                0.021797843277454376,
                0.011929715983569622,
                -0.06028898432850838,
                -0.023544568568468094,
                -0.028284721076488495,
                0.0036443257704377174,
                -0.0013961963122710586,
                0.044790253043174744,
                0.002120597753673792,
                0.031887397170066833,
                -0.0011501776752993464,
                0.05523434281349182,
                -0.0019021861953660846,
                0.010040530934929848,
                0.041655492037534714,
                0.022390909492969513,
                -0.010045140981674194,
                0.04466928541660309,
                -0.02529272623360157,
                0.027764465659856796,
                0.05048104748129845,
                0.02834579534828663,
                -0.009852866642177105,
                -0.017674989998340607,
                -0.05683598294854164,
                0.03833863511681557,
                2.4902337258936313e-07,
                -0.0023881415836513042,
                -0.005961576476693153,
                0.02951720915734768,
                0.028115326538681984,
                0.03608041629195213,
                -0.03067833185195923,
                0.004617270547896624,
                0.01673722453415394,
                0.0024041091091930866,
                -0.03909919038414955,
                -0.013599452562630177,
                0.010730991140007973,
                -0.0329308919608593,
                0.010037505999207497,
                0.013376563787460327,
                0.030258888378739357,
                0.103394515812397,
                -0.003700528061017394,
                0.002578460145741701,
                -0.04741549864411354,
                0.01950077712535858,
                0.007696226239204407,
                -0.03649445250630379,
                -0.024987101554870605,
                0.004805660806596279,
                -0.027039313688874245,
                0.009013320319354534,
                -0.04689955711364746,
                0.04310363158583641,
                -0.03849911689758301,
                -0.02554708905518055,
                -0.016640737652778625,
                0.0075713531114161015,
                0.035438891500234604,
                0.03961449861526489,
                -0.010289885103702545,
                0.033506203442811966,
                0.043915726244449615,
                -0.018879244104027748,
                0.049494460225105286,
                -0.08713667094707489,
                -0.011152300052344799,
                0.004339455161243677,
                -0.07730083167552948,
                -0.013780813664197922,
                -0.027680739760398865,
                -0.011857619509100914,
                -0.00798250176012516,
                -0.05355554074048996,
                -0.028297437354922295,
                0.00957101583480835,
                0.03573335334658623,
                0.008907642215490341,
                0.01868239976465702,
                0.042710330337285995,
                0.013402215205132961,
                0.007993217557668686,
                0.08934469521045685,
                0.038930077105760574,
                -0.0068809823133051395,
                -0.013680364936590195,
                0.02569127269089222,
                -0.03368445485830307,
                0.05616791173815727,
                0.009137826971709728,
                -0.021119019016623497,
                -0.007343239150941372,
                1.8199114889969747e-34,
                -0.014418377541005611,
                -0.039606593549251556,
                -0.04237629845738411,
                0.004750223830342293,
                0.024230578914284706,
                -0.02217959426343441,
                0.016584672033786774,
                -0.018307069316506386,
                0.010543395765125751,
                -0.06993022561073303,
                0.014634461142122746,
                0.018780497834086418,
                0.0880221575498581,
                -0.0029388039838522673,
                0.01856284961104393,
                -0.010450406931340694,
                -0.014432135038077831,
                -0.03186909854412079,
                0.009330238215625286,
                -0.027456482872366905,
                0.019610809162259102,
                0.07173661887645721,
                0.07131867855787277,
                0.0007182854460552335,
                0.0411628820002079,
                0.015552648343145847,
                -0.03267534822225571,
                0.03782789036631584,
                0.007833440788090229,
                -0.05508693680167198,
                0.0008617767016403377,
                -0.029763583093881607,
                -0.0003105767536908388,
                0.027071356773376465,
                -0.017760852351784706,
                -0.03428018093109131,
                -0.0007683297735638916,
                -0.013940517790615559,
                0.019344596192240715,
                0.0054403808899223804,
                0.012089966796338558,
                0.012892322614789009,
                -0.002632789546623826,
                0.006330979522317648,
                -0.05369655415415764,
                1.816115400288254e-06,
                -0.024459797888994217,
                -0.04301661625504494,
                -0.024213964119553566,
                -0.02155904285609722,
                0.005339218769222498,
                0.08056972175836563,
                -0.01412782073020935,
                -0.03834777697920799,
                0.02913813851773739,
                -0.0005115381209179759,
                -0.0020128698088228703,
                0.04854513704776764,
                -0.03911709040403366,
                -0.019612865522503853,
                -0.04447878152132034,
                -0.008906104601919651,
                -0.01811252161860466,
                -0.06752163171768188,
                -0.014639755710959435,
                -0.03808258846402168,
                0.11576464027166367,
                0.03362426534295082,
                -0.030651981011033058,
                -0.012945921160280704,
                -0.023417199030518532,
                -0.022703859955072403,
                0.043163277208805084,
                -0.012837665155529976,
                -0.017595522105693817,
                0.024677682667970657,
                -0.015602893196046352,
                0.035777270793914795,
                -0.04266506806015968,
                0.04418206587433815,
                -0.005380260292440653,
                0.054286081343889236,
                0.045087918639183044,
                -0.021855967119336128,
                0.07615552842617035,
                -0.020424174144864082,
                -0.004996242001652718,
                0.020591706037521362,
                -0.006306162569671869,
                0.05221037194132805,
                -0.015842726454138756,
                0.03530949726700783,
                0.03549351170659065,
                -0.020187798887491226,
                0.029754361137747765,
                0.004077388904988766,
                0.04243335872888565,
                -0.04457799717783928,
                -0.009595325216650963,
                -0.00569120654836297,
                0.020419517531991005,
                -0.008256726898252964,
                -0.004307253751903772,
                -0.044598788022994995,
                0.013013694435358047,
                0.03928109630942345,
                -0.012743202038109303,
                0.009371035732328892,
                0.04589112848043442,
                0.015851974487304688,
                0.001095788786187768,
                -0.029162893071770668,
                0.015879880636930466,
                0.06207679584622383,
                0.004664073698222637,
                0.023298034444451332,
                -0.013088745065033436,
                -0.03828907757997513,
                -0.07455422729253769,
                -0.0008357545011676848,
                0.01792578399181366,
                -0.025350358337163925,
                -0.008988307788968086,
                0.010077563114464283,
                -0.025024699047207832,
                0.019911611452698708,
                -0.03043607994914055,
                0.014805929735302925,
                0.014063623733818531,
                -0.04631807282567024,
                -0.023937242105603218,
                -0.0681706890463829,
                0.03573164343833923,
                0.04282442107796669,
                -0.00967398751527071,
                0.03693249821662903,
                0.0746128261089325,
                -0.05844203382730484,
                0.01365581527352333,
                0.01702258735895157,
                0.011036857031285763,
                0.021201355382800102,
                -0.012482729740440845,
                0.005874957889318466,
                0.027425916865468025,
                -0.004366341978311539,
                0.062292445451021194,
                0.027703018859028816,
                -0.03516843169927597,
                -0.0939457044005394,
                -0.04237804561853409,
                0.0034345670137554407,
                -0.019557181745767593,
                -0.06472839415073395,
                0.011965902522206306,
                -0.0076305759139359,
                -0.03683092072606087,
                0.01191320363432169,
                0.004404156934469938,
                -0.003553345100954175,
                -0.004626351408660412,
                -0.012487216852605343,
                -0.003840461140498519,
                -0.03639998659491539,
                0.016504038125276566,
                0.07065760344266891,
                0.051337383687496185,
                0.022768696770071983,
                0.022744540125131607,
                0.03661882132291794,
                -0.0052816118113696575,
                0.006793566979467869,
                0.011600619181990623,
                -0.06996477395296097,
                -0.030752116814255714,
                -0.016170931980013847,
                -0.004913839977234602,
                0.05514597147703171,
                0.0004928255802951753,
                -0.054383955895900726,
                -0.03855252265930176,
                -0.036132968962192535,
                -0.015314356423914433,
                -0.05358067899942398,
                0.09884384274482727,
                0.04527273029088974,
                -0.003550408175215125,
                -0.004995205905288458,
                0.009658697992563248,
                -0.053671348839998245,
                0.02122715301811695,
                -0.0542609840631485,
                -0.09426572918891907,
                0.00844097975641489,
                -0.010790976695716381,
                0.06336048245429993,
                0.0228229071944952,
                0.01487269252538681,
                0.013183859176933765,
                -0.004017697647213936,
                -0.03981560841202736,
                -0.014509378932416439,
                -0.0029601375572383404,
                -0.003747287206351757,
                0.04355047270655632,
                -0.002550864592194557,
                -0.0027353812474757433,
                0.03279026970267296,
                0.06538917869329453,
                -0.026294363662600517,
                -0.051044486463069916,
                -0.008090915158390999,
                -0.02524605579674244,
                0.08495884388685226,
                0.019957277923822403,
                -0.06509030610322952,
                0.04644181579351425,
                0.034988805651664734,
                -0.0010846294462680817,
                -0.08579133450984955,
                -0.04203291982412338,
                0.0075111230835318565,
                -0.010251414962112904,
                0.009899405762553215,
                0.025238020345568657,
                -0.014262799173593521,
                -0.0031793699599802494,
                0.00118902453687042,
                0.023129578679800034,
                -0.029005035758018494,
                0.06306994706392288,
                0.019308123737573624,
                0.009391957893967628,
                0.014924340881407261,
                -0.011377155780792236,
                0.0365540087223053,
                0.024969391524791718,
                -0.026007413864135742,
                0.028972096741199493,
                0.008059774525463581,
                -0.018373535946011543,
                -0.002292875200510025,
                0.031967632472515106,
                0.015772180631756783,
                0.006196185480803251,
                -0.03194297105073929,
                0.04542335122823715,
                0.08879311382770538,
                0.1259249597787857,
                0.02075558342039585,
                -0.107663594186306,
                0.011341399513185024,
                0.029990529641509056,
                0.06994644552469254,
                0.010506142862141132,
                0.015285075642168522,
                0.01264918316155672,
                0.03101170063018799,
                -0.02729916200041771,
                -0.02178708091378212,
                0.0021530857775360346,
                0.009256363846361637,
                -0.009710777550935745,
                0.018496470525860786,
                0.005216357298195362,
                0.033793870359659195,
                -0.051933594048023224,
                -0.044885579496622086,
                0.06201352924108505,
                -0.02851787395775318,
                0.01876184344291687,
                -0.006244610529392958,
                -0.05652833729982376,
                -0.029956212267279625,
                0.037665415555238724,
                0.05432687699794769,
                -0.06916093826293945,
                0.06231699138879776,
                -0.05903936177492142,
                -0.005995241459459066,
                -0.021756408736109734,
                0.027786975726485252,
                -0.021422559395432472,
                0.00741574726998806,
                0.0066102538257837296,
                0.04111107066273689,
                0.020665742456912994,
                0.024617040529847145,
                0.00019705247541423887,
                0.0005504134460352361,
                -0.019786236807703972,
                0.0050733862444758415,
                0.027281681075692177,
                -0.02359876222908497,
                0.016965940594673157,
                0.020689543336629868,
                0.039780907332897186,
                0.02593216858804226,
                -0.021864311769604683,
                -0.02941364422440529,
                -0.04315947741270065,
                -0.02234678715467453,
                -0.04599132388830185,
                0.01973026618361473,
                -0.00905106496065855,
                0.004883101209998131,
                0.04727793484926224,
                0.010509013198316097,
                -0.0468420684337616,
                0.011975964531302452,
                -0.0017214216059073806,
                -0.03695889189839363,
                0.009539089165627956,
                0.010896333493292332,
                -0.029131826013326645,
                0.0334908589720726,
                0.0176087599247694,
                0.05882680416107178,
                -0.0171169713139534,
                -0.030371911823749542,
                0.0043776449747383595,
                -0.0019715207163244486,
                0.03149476274847984,
                0.058318860828876495,
                0.08463391661643982,
                0.0031379228457808495,
                -0.024636097252368927,
                -0.023894377052783966,
                -0.004132621455937624,
                0.01966608688235283,
                0.10164280235767365,
                0.034408342093229294,
                -0.021275049075484276,
                -0.035319000482559204,
                -0.0031559797935187817,
                0.019950931891798973,
                -0.0035060050431638956,
                0.0005795739125460386,
                0.019213011488318443,
                0.0050028059631586075,
                -0.05301893129944801,
                -0.003062531817704439,
                0.07992630451917648,
                0.017673665657639503,
                0.006667164154350758,
                -0.008956899866461754,
                0.001356769003905356,
                0.01050733681768179,
                -0.040692415088415146,
                -0.025913788005709648,
                0.041563358157873154,
                -0.03527149185538292,
                -0.05665026232600212,
                -0.030697772279381752,
                -0.019257087260484695,
                0.010179022327065468,
                -0.012530268169939518,
                0.009213882498443127,
                0.025094658136367798,
                -0.0440327413380146,
                0.010763939470052719,
                0.03096921555697918,
                0.020122937858104706,
                -0.00935077853500843,
                -0.021788055077195168,
                0.00031420664163306355,
                0.041117481887340546,
                -0.0266941599547863,
                0.038651663810014725,
                -0.005759750492870808,
                0.015388879925012589,
                -0.04443419724702835,
                0.034381136298179626,
                -0.028174029663205147,
                0.021443355828523636,
                0.048772454261779785,
                0.04456740617752075,
                0.05283457040786743,
                0.0518876388669014,
                0.008868923410773277,
                -0.006139431614428759,
                0.03548691049218178,
                0.042157307267189026,
                -0.008978912606835365,
                0.050757840275764465,
                -0.028446050360798836,
                0.09513656049966812,
                0.03192912042140961,
                -0.032322924584150314,
                0.024638589471578598,
                -0.015240131877362728,
                0.034714967012405396,
                -0.02267058752477169,
                0.03660990297794342,
                0.023601092398166656,
                -0.01501532457768917,
                0.00920212734490633,
                -0.008143344894051552,
                -0.016400078311562538,
                0.04881485551595688,
                0.011941767297685146,
                0.017133492976427078,
                -0.0345040038228035,
                0.01865985430777073,
                0.05869579315185547,
                -0.008624856360256672,
                -0.035339925438165665,
                0.015185466036200523,
                -0.004107255954295397,
                -0.00889420136809349,
                0.022799178957939148,
                0.008888033218681812,
                0.015828212723135948,
                0.05755804851651192,
                0.008605697192251682,
                -0.02536621131002903,
                -0.07204603403806686,
                0.13293389976024628,
                0.027975860983133316,
                0.012200841680169106,
                -0.029614508152008057,
                0.0021927228663116693,
                -0.054278675466775894,
                0.013546988368034363,
                -0.0072425599209964275,
                -0.020058361813426018,
                -0.04998838156461716,
                -0.05069800093770027,
                0.02118302322924137,
                0.005097863730043173,
                -0.04699892923235893,
                -0.019241204485297203,
                -0.0013372290413826704,
                0.01436980627477169,
                0.011322046630084515,
                -0.01584305427968502,
                0.007930451072752476,
                0.0013148316647857428,
                0.017166443169116974,
                0.0191030390560627,
                -0.00672845309600234,
                -0.00512911519035697,
                -0.062125589698553085,
                -0.0790395736694336,
                -0.0397128127515316,
                0.01996762678027153,
                0.0076855760999023914,
                -0.01535562053322792,
                -0.0003807854955084622,
                -0.05202627554535866,
                0.006415331270545721,
                0.027461295947432518,
                0.04105975106358528,
                0.012371084652841091,
                -0.041756220161914825,
                -0.05122950300574303,
                0.035247672349214554,
                0.006086885463446379,
                -0.07426124811172485,
                -0.04975292086601257,
                -0.006182541139423847,
                0.027084048837423325,
                -0.01219517458230257,
                0.028884895145893097,
                0.0047803944908082485,
                0.08495097607374191,
                0.0024883728474378586,
                -0.035405706614255905,
                -0.015323396772146225,
                -0.016110997647047043,
                0.03552163392305374,
                0.04321863874793053,
                0.038945272564888,
                -0.03898880258202553,
                0.03216502442955971,
                0.07789567112922668,
                -0.03750072047114372,
                0.06295566260814667,
                -0.045693133026361465,
                -0.004054663702845573,
                -0.0137743279337883,
                0.0020383575465530157,
                -0.025287078693509102,
                -0.028578905388712883,
                0.060285136103630066,
                0.0024774253834038973,
                -0.029877008870244026,
                -0.015212955884635448,
                -0.0356501005589962,
                0.029768360778689384,
                -0.002665229607373476,
                -0.014903387054800987,
                -0.006065567955374718,
                -0.04481763020157814,
                -0.005415331572294235,
                0.006484556943178177,
                -0.0494653582572937,
                0.05968626216053963,
                0.01454560924321413,
                -0.01968448795378208,
                0.0006395376985892653,
                -0.023670563474297523,
                0.014581755734980106,
                -0.0011978120310232043,
                0.06350196152925491,
                -0.03889874741435051,
                -0.01106796134263277,
                -0.013736136257648468,
                0.02857269160449505,
                -0.058414626866579056,
                -0.02288302406668663,
                -0.02286643348634243,
                0.04161651059985161,
                -0.029136335477232933,
                -0.0035017598420381546,
                -0.012695014476776123,
                -0.011807614006102085,
                -0.006629626732319593,
                0.028057830408215523,
                0.013768975622951984,
                0.032981351017951965,
                0.0018008446786552668,
                0.03519635647535324,
                0.02060934528708458,
                -0.06605147570371628,
                0.003257575910538435,
                0.032315414398908615,
                -0.03959051892161369,
                -0.0043458398431539536,
                -0.03348194807767868,
                0.008504745550453663,
                -0.021900208666920662,
                0.028256148099899292,
                -0.009146789088845253,
                -0.022993512451648712,
                0.006630030460655689,
                0.021365875378251076,
                0.014128891751170158,
                -0.04996819794178009,
                -0.04489201679825783,
                0.02269156463444233,
                -0.007346694823354483,
                -0.0477713979780674,
                -0.02840392291545868,
                0.042399417608976364,
                -0.042471595108509064,
                0.05625839903950691,
                -0.06575759500265121,
                0.027059931308031082,
                0.0093036238104105,
                0.0501365102827549,
                -0.055635854601860046,
                -0.011360077187418938,
                0.045529112219810486,
                0.0017957836389541626,
                0.013705935329198837,
                -0.010381670668721199,
                -0.00464902026578784,
                -0.019521979615092278,
                0.017113659530878067,
                -0.02826385386288166,
                0.010381820611655712,
                -0.02069929800927639,
                -0.09706108272075653,
                0.008936273865401745,
                0.022885970771312714,
                -7.324895417161353e-33,
                -0.01615716889500618,
                -0.04278118163347244,
                0.015566720627248287,
                -0.02312517538666725,
                -0.0822763592004776,
                -0.02771012671291828,
                0.014356096275150776,
                0.01787116378545761,
                -0.1076374351978302,
                -0.02121107093989849,
                -0.01500859297811985,
                -0.04176129773259163,
                0.03792624548077583,
                0.004971988499164581,
                0.034410662949085236,
                -0.05286526679992676,
                -0.04103381186723709,
                0.029524240642786026,
                0.00037662044633179903,
                -0.04924575611948967,
                0.004539779853075743,
                0.011144094169139862,
                -0.008903669193387032,
                0.032671716064214706,
                0.043494127690792084,
                -0.009145980700850487,
                -0.023138223215937614,
                -0.04291318729519844,
                -0.01463358011096716,
                -0.043755196034908295,
                -0.03853100910782814,
                0.007835443131625652,
                0.0015925941988825798,
                -0.07898978888988495,
                -0.0071726590394973755,
                0.08680792897939682,
                -0.03270355984568596,
                -0.05717940255999565,
                0.003606957383453846,
                -0.03785700350999832,
                -0.09137577563524246,
                -0.05005427077412605,
                -0.016658039763569832,
                0.01799558475613594,
                -0.019684867933392525,
                -0.02057562954723835,
                0.002013178076595068,
                -0.02327190525829792,
                -0.027787193655967712,
                -0.019698766991496086,
                -0.005954536143690348,
                -0.03025025874376297,
                -0.018882576376199722,
                0.08515658974647522,
                -0.02092679589986801,
                0.02327743172645569,
                0.0026604384183883667,
                0.04845407232642174,
                -0.010771100409328938,
                0.010962886735796928,
                -0.033763572573661804,
                -0.009626539424061775,
                -0.020890260115265846,
                -0.0013902356149628758,
                -0.016972461715340614,
                -0.011668452061712742,
                -0.13509173691272736,
                -0.04296054318547249,
                0.015887828543782234,
                -0.03435180336236954,
                0.02100386470556259,
                0.05617871508002281,
                0.027372825890779495,
                -0.012840332463383675,
                -0.0853210911154747,
                -0.07937533408403397,
                0.023435529321432114,
                0.004749113228172064,
                0.061566904187202454,
                -0.0003801653510890901,
                -0.04958239942789078,
                -0.012294959276914597,
                0.08493469655513763,
                -0.021509632468223572,
                -0.0369638055562973,
                -0.024344732984900475,
                -0.021081874147057533,
                0.09025466442108154,
                -0.0025211579632014036,
                0.0016916770255193114,
                0.002438031369820237,
                0.010375534184277058,
                -0.02012212574481964,
                0.035042814910411835,
                0.014527102932333946,
                0.05810530111193657,
                0.01790645532310009,
                -0.024447081610560417,
                0.053200460970401764,
                -0.012009688653051853,
                0.001948924851603806,
                0.02160003036260605,
                -0.09917501360177994,
                0.06800635159015656,
                -0.002820901805534959,
                -0.014293325133621693,
                -0.02688264101743698,
                -0.013444718904793262,
                -0.03986193612217903,
                -0.012533396482467651,
                -0.009768974035978317,
                0.0142504358664155,
                0.013958478346467018,
                -0.07339903712272644,
                -0.03609452024102211,
                -0.0411418192088604,
                0.04063631221652031,
                -0.03366878628730774,
                -0.0075411200523376465,
                0.005910397972911596,
                0.018392330035567284,
                0.08948542177677155,
                -0.039295922964811325,
                0.053788330405950546,
                0.003365582088008523,
                0.03096749447286129,
                0.02161072939634323,
                0.0153791019693017,
                -0.0402538999915123,
                -0.03804239258170128,
                -0.02532309852540493,
                0.01429448090493679,
                2.2774946728532086e-07,
                -0.030584480613470078,
                0.003420203924179077,
                -0.02786567434668541,
                -0.007175910752266645,
                0.03312043473124504,
                0.044758498668670654,
                -0.021952491253614426,
                0.03311479091644287,
                -0.02027413621544838,
                0.019004862755537033,
                0.03387682884931564,
                -0.04076683521270752,
                0.0028167942073196173,
                -0.01513995137065649,
                0.02516975626349449,
                -0.061196792870759964,
                -0.004657236393541098,
                -0.05206639692187309,
                -0.023767046630382538,
                0.005863791331648827,
                0.028041470795869827,
                0.019417185336351395,
                -0.0542740635573864,
                -0.0077927871607244015,
                0.00976529624313116,
                -0.014688750728964806,
                0.006657350342720747,
                -0.06863653659820557,
                0.07755337655544281,
                -0.024080855771899223,
                0.022503120824694633,
                -0.00030548585345968604,
                -0.005742032080888748,
                -0.09451735764741898,
                0.007792686577886343,
                -0.02031777799129486,
                0.02174329198896885,
                0.05768148973584175,
                0.01564936153590679,
                0.0010300573194399476,
                -0.005487450864166021,
                -0.02525893598794937,
                -0.02039232850074768,
                -0.014532668516039848,
                0.062375910580158234,
                0.031183626502752304,
                0.02301698550581932,
                0.018728215247392654,
                -0.0589178241789341,
                0.036161720752716064,
                -0.01717432029545307,
                0.035166822373867035,
                -0.03726458549499512,
                0.06766416877508163,
                0.009027672000229359,
                -0.03353411331772804,
                0.018313322216272354,
                0.029393251985311508,
                0.0809951052069664,
                -0.04437735676765442,
                -0.020007582381367683,
                0.018337732180953026,
                -0.02898644469678402,
                0.06854952871799469,
                0.014139881357550621,
                0.021934054791927338,
                -0.0445483960211277,
                7.435047112727196e-35,
                0.00581867340952158,
                -0.03537505492568016,
                -0.019760191440582275,
                -0.029903246089816093,
                -0.001805725391022861,
                0.023009194061160088,
                -0.036478929221630096,
                0.001398090273141861,
                -0.0002911504125222564,
                0.0215250626206398,
                0.016831563785672188,
                0.008917453698813915,
                -0.020558541640639305,
                -0.01499359030276537,
                0.0303887240588665,
                0.025583036243915558,
                0.01627979427576065,
                0.004198186565190554,
                0.03283907100558281,
                0.04779987037181854,
                -0.0073866164311766624,
                -0.0031741063576191664,
                -0.030580978840589523,
                -0.025908932089805603,
                0.009848222136497498,
                0.04128613322973251,
                -0.027495350688695908,
                -0.006993716116994619,
                0.025622567161917686,
                0.0675111785531044,
                -0.03666151687502861,
                -0.03330666571855545,
                0.007802177686244249,
                0.0028332455549389124,
                -0.0031220619566738605,
                -0.01001180149614811,
                -0.02309899590909481,
                -0.005574246868491173,
                0.027564873918890953,
                -0.008883322589099407,
                -0.010848335921764374,
                0.048832736909389496,
                -0.022433115169405937,
                -0.009133943356573582,
                -0.011785278096795082,
                2.2195242763700662e-06,
                -0.04290362820029259,
                -0.0032316441647708416,
                -0.009030470624566078,
                -0.021593011915683746,
                -0.0014760760823264718,
                0.10128248482942581,
                0.036005664616823196,
                -0.05168791487812996,
                0.003968509379774332,
                -0.02677673101425171,
                -0.07807601988315582,
                0.1034044623374939,
                -0.008441902697086334,
                -0.005398262292146683,
                -0.02922770008444786,
                0.003866929095238447,
                -0.00995116587728262,
                -0.046458058059215546,
                0.005625265184789896,
                0.12419778108596802,
                -0.01745874434709549,
                0.011807410977780819,
                -0.005401452537626028,
                0.011049938388168812,
                -0.035681240260601044,
                -0.00951651856303215,
                0.0019342460436746478,
                0.003998460713773966,
                0.018408019095659256,
                -0.01269760262221098,
                0.034774426370859146,
                0.006974331568926573,
                0.005740189924836159,
                -0.005708502139896154,
                0.03297824785113335,
                -0.042822983115911484,
                -0.003617779351770878,
                0.02786518819630146,
                0.06370088458061218,
                -0.032995156943798065,
                -0.10777143388986588,
                -0.0052037229761481285,
                0.061388615518808365,
                0.022906269878149033,
                -0.03483687341213226,
                0.06087248772382736,
                -0.04567980393767357,
                0.014560005627572536,
                0.03628595545887947,
                -0.02927292510867119,
                0.01244273316115141,
                0.003051471896469593,
                -0.029794132336974144,
                -0.015010643750429153,
                0.016081245616078377,
                -0.08849301934242249,
                -0.09322158247232437,
                -0.030670251697301865,
                -0.007035244256258011,
                0.04627780616283417,
                0.0051279389299452305,
                0.06375383585691452,
                0.055179234594106674,
                -0.004374089650809765,
                -0.04043496400117874,
                -0.028119347989559174,
                0.021109139546751976,
                7.938733324408531e-05,
                -0.01728997938334942,
                -0.04061565920710564,
                0.016594896093010902,
                0.025428002700209618,
                0.03784624859690666,
                -0.05449555814266205,
                0.04082939773797989,
                0.00479108327999711,
                0.007488511502742767,
                -0.03079426847398281,
                0.06648820638656616,
                -0.05482063814997673,
                0.00406329520046711,
                -0.0403769351541996,
                -0.010312591679394245,
                -0.0008091320632956922,
                0.02987482398748398,
                0.04968351125717163,
                0.03916681557893753,
                -0.00905750785022974,
                0.03499554097652435,
                0.010265486314892769,
                0.024085799232125282,
                0.004133082926273346,
                0.029673095792531967,
                0.07139529287815094,
                -0.10895194858312607,
                0.002933797426521778,
                0.0331319198012352,
                -0.04053940996527672,
                -0.01835576817393303,
                -0.018449315801262856,
                0.044558972120285034,
                -0.01705404743552208,
                0.01335153728723526,
                -0.033645838499069214,
                0.017580607905983925,
                -0.040626443922519684,
                -0.007037483621388674,
                0.030489660799503326,
                -0.01993369497358799,
                -0.055478401482105255,
                -0.044959910213947296,
                0.03060218319296837,
                -0.013723193667829037,
                -0.0018660922069102526,
                0.027075840160250664,
                0.09751755744218826,
                0.02380114234983921,
                -0.04928708076477051,
                -0.01776835136115551,
                -0.017895536497235298,
                0.04173756763339043,
                -0.022394606843590736,
                -0.03901265189051628,
                0.036042895168066025,
                0.03110995702445507,
                0.019086772575974464,
                -0.0026385197415947914,
                -0.037486132234334946,
                0.013187717646360397,
                0.004169578198343515,
                0.024083849042654037,
                0.03660668060183525,
                -0.05618967488408089,
                -0.05393989384174347,
                -0.03120281733572483,
                -0.0076265232637524605,
                -0.019146529957652092,
                -0.003221005667001009,
                -0.0008483382407575846,
                0.05351658910512924,
                0.02645687945187092,
                0.08618822693824768,
                -0.006325275171548128,
                -0.03875499218702316,
                0.013434077613055706,
                -0.020941516384482384,
                -0.09783706068992615,
                -0.03912908956408501,
                0.06542186439037323,
                -0.054404277354478836,
                0.055922381579875946,
                -0.005794252268970013,
                0.03630995750427246,
                -0.012821138836443424,
                -0.026890406385064125,
                -0.015349337831139565,
                -0.027226774021983147,
                -0.0007864150102250278,
                -0.0077035254798829556,
                -0.030354561284184456,
                0.02303585223853588,
                0.04737410321831703,
                0.08196154981851578,
                0.023326382040977478,
                0.009462617337703705,
                -0.0551215261220932,
                -0.06047727167606354,
                0.02028108760714531,
                0.012296055443584919,
                -0.022996380925178528,
                0.020720211789011955,
                -0.061133552342653275,
                -0.041732292622327805,
                -0.037702109664678574,
                -0.014657603576779366,
                -0.0017575278179720044,
                0.008344419300556183,
                0.019649038091301918,
                -0.010855655185878277,
                0.005972038954496384,
                0.011334875598549843,
                0.004880794323980808,
                0.043888963758945465,
                -0.10563240200281143,
                0.018030093982815742,
                -0.010943684726953506,
                -0.09704917669296265,
                0.014160817489027977,
                -0.014387620612978935,
                -0.06279606372117996,
                -0.03323615342378616,
                0.035539429634809494,
                -0.0031661291141062975,
                0.0013013104908168316,
                -0.004147435538470745,
                -0.01055248361080885,
                0.035338785499334335,
                0.03476275876164436,
                -0.01331192348152399,
                -0.03563452512025833,
                0.09258463233709335,
                0.07094547897577286,
                -0.010957867838442326,
                -0.001898196991533041,
                0.013171298429369926,
                0.016863888129591942,
                0.004327474627643824,
                -0.012458592653274536,
                0.01468905620276928,
                0.03157767280936241,
                0.03439495339989662,
                0.06481695175170898,
                -0.040858566761016846,
                -0.030149834230542183,
                0.0020760109182447195,
                -0.058180227875709534,
                -0.023932674899697304,
                0.06084654852747917,
                -0.010424907319247723,
                0.044905614107847214,
                0.039984386414289474,
                0.07103615999221802,
                0.006446239538490772,
                -0.07457956671714783,
                -0.03178851678967476,
                0.047449592500925064,
                -0.06657950580120087,
                -0.0050125764682888985,
                -0.030903317034244537,
                0.07213623076677322,
                -0.045998018234968185,
                0.037394411861896515,
                0.00449850270524621,
                0.039063747972249985,
                -0.0076220291666686535,
                0.0037762729916721582,
                0.017026260495185852,
                -0.006393756717443466,
                -0.00976515468209982,
                -0.02074793539941311,
                -0.037013888359069824,
                -0.04290977865457535,
                0.02165706269443035,
                -0.012667535804212093,
                0.02459431253373623,
                0.0160856731235981,
                -0.011910932138562202,
                -0.025246476754546165,
                -0.007001268211752176,
                0.008635703474283218,
                -0.005682426039129496,
                -0.027025915682315826,
                -0.003029646584764123,
                -0.05161578208208084,
                0.02390451915562153,
                -0.06559990346431732,
                -0.023957978934049606,
                0.01485422533005476,
                -0.028000783175230026,
                -0.005103175528347492,
                -0.022911138832569122,
                -0.04641250520944595,
                0.0698588564991951,
                0.02888878807425499,
                -0.06723854690790176,
                -0.03219481185078621,
                -0.007903235964477062,
                -0.012865056283771992,
                -0.0013215531362220645,
                0.009603877551853657,
                -0.030284635722637177,
                0.04854807257652283,
                -0.016706213355064392,
                0.026927178725600243,
                0.04056103527545929,
                -0.0019214630592614412,
                0.002812431426718831,
                -0.002209177240729332,
                -0.018902117386460304,
                -0.004376963712275028,
                -0.04596218839287758,
                0.03438880294561386,
                0.05170247703790665,
                -0.011562792584300041,
                0.052166834473609924,
                0.01671646349132061,
                -0.030186744406819344,
                -0.005048457067459822,
                0.005658530630171299,
                -0.02286508120596409,
                -0.02903517708182335,
                0.02164517529308796,
                0.011342492885887623,
                -0.018875954672694206,
                -0.03189451992511749,
                0.027408767491579056,
                -0.014862071722745895,
                -0.03926299139857292,
                0.009625768288969994,
                0.022315459325909615,
                0.009940169751644135,
                -0.014679559506475925,
                -0.01929479092359543,
                -0.0017064197454601526,
                0.009975606575608253,
                -0.056161001324653625,
                -0.07719270139932632,
                0.005875376984477043,
                -0.03269943222403526,
                0.003208104521036148,
                -0.0656682550907135,
                0.004130118526518345,
                0.024680668488144875,
                0.03050488978624344,
                -0.007334625348448753,
                -0.04804731905460358,
                -0.012874548323452473,
                0.015742409974336624,
                -0.02413271553814411,
                -0.02257593907415867,
                0.06894408911466599,
                -0.04600935056805611,
                -0.030622946098446846,
                0.02940404787659645,
                0.024266693741083145,
                0.016970477998256683,
                0.013781992718577385,
                0.021048927679657936,
                0.004544329363852739,
                -0.024770012125372887,
                0.06210257112979889,
                0.03627580776810646,
                0.01018481608480215,
                -0.06535526365041733,
                -0.004061970394104719,
                -0.013395557180047035,
                0.05219023674726486,
                -0.023168649524450302,
                0.08380969613790512,
                -0.01118297316133976,
                0.04590495303273201,
                0.015458578243851662,
                0.033184606581926346,
                0.027886513620615005,
                0.01270254235714674,
                0.01901867240667343,
                -0.07879143208265305,
                -0.015700481832027435,
                0.003960784524679184,
                -0.06813040375709534,
                0.012533871456980705,
                -0.028799626976251602,
                -0.0444447286427021,
                -0.016035305336117744,
                0.03899415209889412,
                0.006648204755038023,
                0.0550963468849659,
                -0.03330012038350105,
                0.040851544588804245,
                0.007505841553211212,
                -0.01244234386831522,
                8.311239071190357e-05,
                -0.018628444522619247,
                -0.04896567389369011,
                0.035301800817251205,
                0.055583756417036057,
                -0.014048761688172817,
                0.01716773211956024,
                0.03938629478216171,
                -0.037457212805747986,
                0.002125390339642763,
                0.057992544025182724,
                -0.004096572753041983,
                0.008139644749462605,
                -0.019921833649277687,
                0.005940459202975035,
                0.0405496209859848,
                -0.015459740534424782,
                -0.021119849756360054,
                -0.06846215575933456,
                0.0017379865748807788,
                -0.006886442191898823,
                -0.021296197548508644,
                0.02977367304265499,
                -0.025172771885991096,
                0.031899090856313705,
                -0.006316200364381075,
                -0.03324570506811142,
                0.022464949637651443,
                -0.02022254839539528,
                0.05250027775764465,
                0.09264899790287018,
                0.014762195758521557,
                0.011596502736210823,
                -0.019233379513025284,
                -0.012763828039169312,
                -0.048310548067092896,
                -0.07315671443939209,
                -0.008035756647586823,
                0.08857604116201401,
                -0.08898239582777023,
                0.06184097006917,
                0.013177497312426567,
                -0.01814485900104046,
                -0.007112338673323393,
                0.014899460598826408,
                -0.04530879482626915,
                0.0019372761016711593,
                -0.004292493686079979,
                0.022707514464855194,
                -0.06351000815629959,
                -0.025141309946775436,
                0.026982631534337997,
                -0.10349851101636887,
                -0.018602292984724045,
                0.05245878919959068,
                -0.011658142320811749,
                0.04970921576023102,
                0.017329052090644836,
                -0.03888227418065071,
                -0.018712876364588737,
                0.08507135510444641,
                -0.08222868293523788,
                0.007549039553850889,
                0.028114525601267815,
                0.03276113420724869,
                0.03736121207475662,
                -0.027067383751273155,
                -0.003973883576691151,
                0.06961081176996231,
                -0.0024421950802206993,
                0.017661966383457184,
                0.0793016254901886,
                0.010354640893638134,
                -0.008925456553697586,
                0.020998651161789894,
                0.05399148166179657,
                0.029990166425704956,
                0.043073177337646484,
                0.028701908886432648,
                0.041109729558229446,
                -0.017945241183042526,
                -0.06741011142730713,
                -0.03257658705115318,
                0.008924711495637894,
                0.05008990317583084,
                0.00539032556116581,
                -0.020134299993515015,
                -0.04949178919196129,
                -0.011768297292292118,
                0.006873470265418291,
                0.06856995075941086,
                0.007825820706784725,
                0.003098434302955866,
                -0.019635681062936783,
                0.034189336001873016,
                0.00013923755614086986,
                0.0034517510794103146,
                0.04554210230708122,
                0.06001090630888939,
                -0.017627770081162453,
                -0.06992601603269577,
                0.0019470881670713425,
                0.06769438087940216,
                -0.04523853957653046,
                0.00520513579249382,
                0.06352896988391876,
                0.016206325963139534,
                -0.07889857888221741,
                0.025008425116539,
                0.05613100528717041,
                -0.021177463233470917,
                0.03895815461874008,
                0.00522757088765502,
                -0.04114145413041115,
                0.011578606441617012,
                0.0658310279250145,
                -0.034273985773324966,
                -0.0169108547270298,
                0.016537748277187347,
                -0.011637159623205662,
                0.050498466938734055,
                -0.08960960805416107,
                0.009933455847203732,
                -0.03556989133358002,
                0.026126127690076828,
                -0.03128106892108917,
                0.008451045490801334,
                -0.013830892741680145,
                -0.03570142015814781,
                -0.0010408565867692232,
                -0.019953938201069832,
                -0.009526108391582966,
                -0.014435132034122944,
                0.0018446679459884763,
                0.02661941573023796,
                0.0014972537755966187,
                0.029343880712985992,
                0.04764125496149063,
                -0.05300016328692436,
                0.06877371668815613,
                -0.003973061218857765,
                0.000721435877494514,
                -0.012105558067560196,
                -0.06348858773708344,
                0.011193821206688881,
                -0.007643990684300661,
                -0.005144172813743353,
                -0.013292931020259857,
                0.04360617324709892,
                0.05076161399483681,
                0.04074808955192566,
                0.012252896092832088,
                0.034863319247961044,
                -0.03532528132200241,
                0.015442472882568836,
                -0.027899233624339104,
                -0.07554331421852112,
                -0.021797090768814087,
                -0.006288529839366674,
                -8.027290760660102e-33,
                -0.018389035016298294,
                -0.005969458259642124,
                0.040368806570768356,
                0.003344598924741149,
                -0.02943868190050125,
                0.03821045905351639,
                0.00785489659756422,
                0.029697049409151077,
                -0.04051441699266434,
                7.66487792134285e-05,
                -0.011874551884829998,
                -0.03534068912267685,
                0.045037683099508286,
                -0.005910793319344521,
                0.025123488157987595,
                -0.024050695821642876,
                0.010018080472946167,
                0.008178488351404667,
                0.030108865350484848,
                -0.05027485266327858,
                -0.008601321838796139,
                0.0033799621742218733,
                -0.008145763538777828,
                0.012340780347585678,
                0.04282847419381142,
                0.04403451085090637,
                -0.05713655427098274,
                -0.022312328219413757,
                -0.040518078953027725,
                0.03586128354072571,
                -0.00791100598871708,
                -0.0022905822843313217,
                -0.0010217998642474413,
                -0.01594727113842964,
                -0.025044430047273636,
                0.009951810352504253,
                -0.0369262658059597,
                0.0011273635318502784,
                -0.01261900831013918,
                -0.008385233581066132,
                -0.06015787273645401,
                -0.007262626197189093,
                -0.011205344460904598,
                -0.020197173580527306,
                -0.0021390216425061226,
                -0.018141502514481544,
                -0.007844287902116776,
                -0.04344954714179039,
                0.004962335340678692,
                0.0266399048268795,
                0.002669263631105423,
                -0.012258966453373432,
                -0.015411502681672573,
                0.0130564384162426,
                0.028225338086485863,
                0.015812747180461884,
                -0.03904956579208374,
                0.029687650501728058,
                -0.06620082259178162,
                0.03396255895495415,
                -0.011839205399155617,
                0.027508487924933434,
                0.05342387780547142,
                0.0022980631329119205,
                0.024859901517629623,
                0.0009284320985898376,
                0.02213055081665516,
                0.01473472360521555,
                -0.03252744302153587,
                0.03766101598739624,
                -0.01607874408364296,
                0.03764548897743225,
                -0.013670308515429497,
                -0.10166225582361221,
                -0.0037214511539787054,
                -0.09427569061517715,
                -0.010950982570648193,
                0.024708067998290062,
                0.04563712701201439,
                0.00024411280173808336,
                -0.02353372611105442,
                0.03611857816576958,
                0.03807777911424637,
                -0.009997899644076824,
                -0.04879790171980858,
                -0.0265173502266407,
                -0.010361479595303535,
                -0.03190101683139801,
                0.0277858879417181,
                0.006112031172960997,
                -0.023572804406285286,
                -0.023141227662563324,
                0.005360718816518784,
                -0.009519833140075207,
                0.024794215336441994,
                -0.0638316199183464,
                0.005748575087636709,
                -0.011825828813016415,
                -0.0345117449760437,
                -0.020923160016536713,
                -0.03331573307514191,
                0.030186576768755913,
                -0.028453947976231575,
                -0.003916431218385696,
                -0.028852421790361404,
                0.024740107357501984,
                0.017990341410040855,
                0.02705357037484646,
                -0.031046563759446144,
                -7.743479363853112e-05,
                -0.02743879146873951,
                -0.017123356461524963,
                0.052392225712537766,
                -0.0274502020329237,
                0.00553822610527277,
                -0.004562768153846264,
                0.02395986020565033,
                0.015301700681447983,
                0.01216497179120779,
                0.002813306637108326,
                0.019388975575566292,
                0.033320292830467224,
                -0.079271599650383,
                -0.008694139309227467,
                -0.05634445324540138,
                0.03606401011347771,
                -0.04379042983055115,
                0.036166589707136154,
                0.012153452262282372,
                -0.02025013044476509,
                0.017585784196853638,
                -0.0032283389009535313,
                2.795710827285802e-07,
                -0.026674984022974968,
                -0.007379593327641487,
                0.026828857138752937,
                0.060444097965955734,
                0.05067579820752144,
                0.02830306440591812,
                0.0006824758020229638,
                0.012801224365830421,
                0.026534758508205414,
                0.024300487712025642,
                0.005784683860838413,
                -0.02004857175052166,
                -0.012245137244462967,
                0.00400905217975378,
                -0.03173088654875755,
                -0.014336713589727879,
                0.032209448516368866,
                -0.024004152044653893,
                0.02470439299941063,
                -0.005250153131783009,
                0.00929426308721304,
                0.01587899960577488,
                -0.025846155360341072,
                -0.029005272313952446,
                0.028445307165384293,
                -0.024769525974988937,
                0.00024068847415037453,
                -0.07294648140668869,
                0.013052009046077728,
                0.003911852370947599,
                0.08996748179197311,
                -0.001857343828305602,
                0.030560020357370377,
                -0.021818414330482483,
                -0.04557712376117706,
                -0.07823745161294937,
                0.03846563771367073,
                0.008057564496994019,
                -0.01688971184194088,
                -0.0006625323439948261,
                -0.023326080292463303,
                -0.02807130105793476,
                -0.0014821318909525871,
                0.060310669243335724,
                0.01739952154457569,
                0.09020203351974487,
                -0.0017207417404279113,
                -0.02585608884692192,
                -0.050010330975055695,
                -0.019942756742239,
                -0.043162159621715546,
                -0.0509185828268528,
                -0.016414854675531387,
                0.03621015325188637,
                0.011478510685265064,
                0.032707151025533676,
                0.03071991354227066,
                0.047376979142427444,
                0.02009608782827854,
                -0.022784657776355743,
                -0.021053185686469078,
                0.044690582901239395,
                -0.021687425673007965,
                0.13177171349525452,
                0.023415809497237206,
                0.012497756630182266,
                -0.06736262142658234,
                2.0397538429790753e-34,
                -0.028901323676109314,
                -0.049639176577329636,
                -0.009184995666146278,
                0.05280344560742378,
                0.022563066333532333,
                0.010006634518504143,
                -0.03632572293281555,
                -0.002676941454410553,
                0.0031163678504526615,
                -0.03940777853131294,
                -0.04273969307541847,
                0.024676796048879623,
                0.01780385710299015,
                0.005923114251345396,
                0.012754293158650398,
                0.0008841226226650178,
                0.020863879472017288,
                -0.040519893169403076,
                0.04351381957530975,
                0.015605824068188667,
                -0.022981761023402214,
                0.04416857659816742,
                0.03180811181664467,
                0.06163951754570007,
                0.008086253888905048,
                0.047827720642089844,
                -0.03504873439669609,
                0.01599503494799137,
                -0.0005241240141913295,
                -0.03412511944770813,
                0.017028532922267914,
                -0.032308224588632584,
                0.04589105024933815,
                0.023727649822831154,
                -0.029798030853271484,
                -0.01705705188214779,
                -0.00783578585833311,
                0.023861201480031013,
                -0.05099659040570259,
                -0.02749244123697281,
                0.03381378948688507,
                0.06918347626924515,
                -0.03708286210894585,
                0.04659845679998398,
                -0.037475183606147766,
                1.6538072031835327e-06,
                -0.05421396344900131,
                -0.03364899381995201,
                0.03410152345895767,
                0.0009472641977481544,
                -0.01612795703113079,
                0.03041325882077217,
                0.037355970591306686,
                -0.004496665205806494,
                0.05146057903766632,
                -0.024251772090792656,
                -0.09510248899459839,
                0.018538914620876312,
                -0.002549723256379366,
                0.049523502588272095,
                0.011694498360157013,
                -0.0014758451143279672,
                0.02980005368590355,
                -0.09811922162771225,
                -0.042060744017362595,
                0.017058685421943665,
                0.02161364071071148,
                0.03187815472483635,
                -0.029403584077954292,
                0.009898936375975609,
                0.008518935181200504,
                0.03160082548856735,
                -0.032779790461063385,
                -0.013099197298288345,
                -0.023857958614826202,
                0.00027892051730304956,
                0.030293602496385574,
                -0.06278509646654129,
                -0.03788803517818451,
                0.03713254630565643,
                0.052903324365615845,
                -0.03351161256432533,
                -0.003526095999404788,
                0.06974174827337265,
                0.055402930825948715,
                -0.03359615430235863,
                -0.02052588015794754,
                0.020495574921369553,
                0.033771488815546036,
                0.005175094585865736,
                0.036214668303728104,
                0.0400589257478714,
                -0.027682069689035416,
                0.013822299428284168,
                0.06947171688079834,
                -5.706513184122741e-05,
                0.0594317801296711,
                -0.012205342762172222,
                0.0330657958984375,
                0.02318335883319378,
                0.03778231143951416,
                -0.06548469513654709,
                -0.04483228921890259,
                -0.002518010325729847,
                -0.012199832126498222,
                0.038518570363521576,
                -0.027541175484657288,
                0.009484358131885529,
                0.04206688329577446,
                0.019224097952246666,
                0.006884920410811901,
                -0.05323523283004761,
                0.025389021262526512,
                -0.029994705691933632,
                0.03011670708656311,
                -0.048167768865823746,
                -0.017935076728463173,
                0.023157166317105293,
                -0.04094577953219414,
                0.010889661498367786,
                -0.020439425483345985,
                -0.01733727566897869,
                -0.0024097710847854614,
                -0.024298273026943207,
                0.0289204902946949,
                -0.006662833038717508,
                -0.02614184282720089,
                0.016625193879008293,
                0.005679601803421974,
                -0.027111534029245377,
                0.002677561715245247,
                0.011128946207463741,
                -0.02639685571193695,
                0.04226178675889969,
                0.050419993698596954,
                0.01981239952147007,
                0.04934093356132507,
                -0.030343765392899513,
                0.05237310007214546,
                0.06140708178281784,
                -0.029178673401474953,
                -0.046057429164648056,
                0.02607935480773449,
                0.023220177739858627,
                -0.017445310950279236,
                0.009060375392436981,
                0.008826480247080326,
                -0.001394369057379663,
                0.03313805162906647,
                -0.050451911985874176,
                -0.0113057317212224,
                0.013433224521577358,
                -0.015942471101880074,
                -0.004657323006540537,
                0.04158114641904831,
                -0.02620732970535755,
                -0.013462824746966362,
                0.025976339355111122,
                0.0413690023124218,
                -0.0007197652012109756,
                -0.01244398858398199,
                -0.010348462499678135,
                -0.0058633070439100266,
                -0.0006908101495355368,
                -0.039537493139505386,
                0.04706081375479698,
                0.029451388865709305,
                0.014032226987183094,
                -0.03166623041033745,
                0.0561533086001873,
                -0.017404617741703987,
                0.01179550588130951,
                0.03611615672707558,
                -0.03400332108139992,
                -0.07153745740652084,
                0.01649160124361515,
                -0.0006352892378345132,
                0.02615242451429367,
                -0.026203949004411697,
                -0.027707455679774284,
                -0.006171057466417551,
                0.01934477873146534,
                -0.06402639299631119,
                -0.04580027982592583,
                -0.008572874590754509,
                -0.0029111334588378668,
                -0.014410644769668579,
                -0.03243032842874527,
                -0.024930836632847786,
                -0.004684902727603912,
                0.05115881562232971,
                -0.010967522859573364,
                -0.08861434459686279,
                0.051570650190114975,
                -0.01357240229845047,
                -0.004747198428958654,
                0.03218309208750725,
                0.10166054219007492,
                0.05470001697540283,
                -0.024813212454319,
                -0.02653774991631508,
                0.011714396998286247,
                0.025583865121006966,
                -0.007599574979394674,
                -0.013656766153872013,
                0.021160710602998734,
                0.009582186117768288,
                -0.012789564207196236,
                0.042312607169151306,
                -0.027096876874566078,
                0.02485698275268078,
                -0.012243679724633694,
                0.029921747744083405,
                -0.026502450928092003,
                -0.03430552035570145,
                -0.030316781252622604,
                0.026891358196735382,
                -0.001007388811558485,
                0.016049666330218315,
                -0.018180418759584427,
                -0.02386755868792534,
                -0.005035116337239742,
                0.0392678938806057,
                0.03055112063884735,
                0.021105749532580376,
                -0.006703706458210945,
                -0.038119859993457794,
                0.015890201553702354,
                -0.0245384331792593,
                -0.045925963670015335,
                0.012973728589713573,
                -0.0362490676343441,
                -0.0034892354160547256,
                0.06643180549144745,
                -0.017037471756339073,
                0.05610538274049759,
                0.021218491718173027,
                0.0021770535968244076,
                -0.0017637519631534815,
                -0.03675761818885803,
                -0.040079277008771896,
                0.037332914769649506,
                0.05044250190258026,
                -0.006962114479392767,
                -0.014741653576493263,
                -0.04113532602787018,
                0.0216104406863451,
                0.10745450109243393,
                0.09808016568422318,
                0.040672339498996735,
                -0.039109013974666595,
                0.003347830381244421,
                -0.03703927993774414,
                0.01657264307141304,
                -0.009842736646533012,
                0.062269944697618484,
                0.008000104688107967,
                0.006284385919570923,
                -0.013927575200796127,
                -0.0033544714096933603,
                0.02927413210272789,
                -0.016003470867872238,
                -0.035707149654626846,
                0.05459082871675491,
                -0.008140930905938148,
                0.01792171224951744,
                -0.07303477078676224,
                0.014078560285270214,
                0.04678560420870781,
                0.03149614855647087,
                0.055520977824926376,
                0.04133192077279091,
                -0.04964444041252136,
                -0.024257006123661995,
                -0.016595548018813133,
                -0.0373808927834034,
                -0.007114303298294544,
                0.03811478614807129,
                0.05096537247300148,
                -0.04663311690092087,
                -0.01598336733877659,
                0.03356507048010826,
                -0.02793235331773758,
                0.0025509148836135864,
                -0.005471073091030121,
                0.0016119552310556173,
                0.014820897951722145,
                0.023353373631834984,
                -0.011020745150744915,
                -0.002598036779090762,
                -0.017280487343668938,
                -0.020653318613767624,
                0.02575322985649109,
                -0.020581329241394997,
                0.015258833765983582,
                -0.024159563705325127,
                0.010297508910298347,
                -0.031023425981402397,
                0.011434527114033699,
                -0.009717568755149841,
                -0.04561614245176315,
                0.024525977671146393,
                -0.0035450500436127186,
                0.01631111279129982,
                -0.03671956807374954,
                -0.018033308908343315,
                -0.016227655112743378,
                -0.01108083687722683,
                -0.02101747877895832,
                -0.041111916303634644,
                -0.008369628340005875,
                -0.005428242962807417,
                -0.050203174352645874,
                -0.026749828830361366,
                0.014782684855163097,
                -0.029585275799036026,
                0.03335718438029289,
                0.03663960099220276,
                -0.0034300461411476135,
                0.00039679789915680885,
                0.06148445978760719,
                0.021931244060397148,
                0.03422021493315697,
                0.06250626593828201,
                0.055625446140766144,
                -0.013868717476725578,
                -0.022411951795220375,
                -0.006020944565534592,
                -0.012846539728343487,
                0.045117832720279694,
                0.06569176912307739,
                0.01576400361955166,
                0.013496852479875088,
                -0.012843207456171513,
                -0.04668746143579483,
                -0.01932421326637268,
                -0.005183442495763302,
                0.011092614382505417,
                0.0025062316562980413,
                -0.036066021770238876,
                -0.013991044834256172,
                -0.03191392123699188,
                -0.01571531407535076,
                0.015404424630105495,
                -0.009880687110126019,
                0.004283353220671415,
                0.07631435245275497,
                -0.004423569422215223,
                -0.02704452909529209,
                -0.024660734459757805,
                0.05436926335096359,
                0.012511431239545345,
                -0.02938782423734665,
                -0.020290544256567955,
                -0.08943980932235718,
                0.018163852393627167,
                -0.005454560741782188,
                0.02571294829249382,
                -0.0378112830221653,
                -0.00962864514440298,
                -0.00248492113314569,
                0.07298428565263748,
                0.04737313836812973,
                0.0250250231474638,
                -0.028139730915427208,
                -0.013193181715905666,
                0.08025755733251572,
                0.0029101716354489326,
                -0.030997002497315407,
                -0.045914676040410995,
                0.007391444873064756,
                -0.01834411360323429,
                -0.004940679296851158,
                -0.0026158299297094345,
                0.03831750899553299,
                0.013632911257445812,
                0.06056191027164459,
                0.03397201746702194,
                0.04422267526388168,
                -0.0212150439620018,
                -0.004498481284826994,
                -0.05316188931465149,
                0.03208981826901436,
                -0.03998357802629471,
                0.04006660357117653,
                -0.01523561216890812,
                0.09622617810964584,
                0.01151922345161438,
                0.051256515085697174,
                -0.0018083312315866351,
                0.029839692637324333,
                0.09256752580404282,
                -0.07533781230449677,
                0.03377731889486313,
                0.019785312935709953,
                -0.05422866344451904,
                0.04918766766786575,
                -0.01359453983604908,
                -0.06411781907081604,
                -0.08003965020179749,
                0.01478097215294838,
                -0.001914320164360106,
                -0.010669682174921036,
                -0.03734901174902916,
                -0.011613186448812485,
                0.01441754586994648,
                -0.07823523133993149,
                -0.06805616617202759,
                -0.046760134398937225,
                -0.01583961211144924,
                0.026263663545250893,
                0.04932335391640663,
                0.04580153897404671,
                0.015834268182516098,
                -0.014196330681443214,
                -0.01723640039563179,
                -0.05780239403247833,
                0.06005005165934563,
                0.008705275133252144,
                0.04221513867378235,
                -0.0400502011179924,
                0.03238089010119438,
                -0.03527076542377472,
                -0.006418304052203894,
                -0.016425220295786858,
                -0.004955169279128313,
                -0.0034131554421037436,
                -0.006781437899917364,
                0.020154733210802078,
                0.02005571685731411,
                -0.03921139985322952,
                0.017809372395277023,
                -0.037112027406692505,
                0.0004281452565919608,
                -0.06428667902946472,
                -0.007175523322075605,
                0.021485373377799988,
                0.03752287104725838,
                0.02619059756398201,
                -0.027051648125052452,
                -0.013330933637917042,
                0.00691900122910738,
                -0.03445601090788841,
                -0.03162712976336479,
                -0.028545856475830078,
                -0.027592528611421585,
                -0.010692093521356583,
                0.010908461175858974,
                0.013945427723228931,
                -0.026451844722032547,
                0.006596623454242945,
                -0.010561974719166756,
                -0.005906602367758751,
                -0.012355233542621136,
                -0.044345468282699585,
                0.02306370995938778,
                0.03647252917289734,
                0.016189714893698692,
                0.015498058870434761,
                -0.03862864524126053,
                0.01774362474679947,
                -0.04606592282652855,
                0.03937486559152603,
                -0.035264644771814346,
                -0.058976806700229645,
                0.002085090149194002,
                -0.017783507704734802,
                -0.012694074772298336,
                -0.10718273371458054,
                -0.017525048926472664,
                -0.003370690857991576,
                0.010325593873858452,
                0.023889118805527687,
                -0.021513570100069046,
                0.004750033374875784,
                0.02709050662815571,
                -0.048159386962652206,
                0.07765284180641174,
                -0.013560930266976357,
                -0.027588514611124992,
                -0.0015487609198316932,
                -0.0470365434885025,
                0.013936330564320087,
                0.002259440952911973,
                0.021130157634615898,
                -0.009589496068656445,
                0.03562602028250694,
                -0.009822084568440914,
                -0.05095100775361061,
                0.005668391473591328,
                0.02373620495200157,
                -0.05044266954064369,
                0.01460348628461361,
                -0.0023810307029634714,
                0.020189525559544563,
                -0.033854637295007706,
                0.02404843643307686,
                0.04026981070637703,
                0.017290988937020302,
                -0.028740815818309784,
                0.003910045139491558,
                -0.04241039976477623,
                -0.05075467377901077,
                -0.016059281304478645,
                0.03876469284296036,
                0.0763714462518692,
                0.0707106962800026,
                0.017499927431344986,
                -0.05071483179926872,
                -0.00797114335000515,
                0.03796674311161041,
                -0.002701403573155403,
                0.01037335954606533,
                0.017536530271172523,
                0.0029386698734015226,
                -1.3580565791926347e-05,
                0.03046766109764576,
                0.06321826577186584,
                0.07379325479269028,
                0.015217196196317673,
                0.006038181018084288,
                0.026883788406848907,
                -0.0052528842352330685,
                -0.015635572373867035,
                -0.07912066578865051,
                0.013321403414011002,
                0.013968129642307758,
                -0.0035708548966795206,
                -0.12533718347549438,
                0.013377662748098373,
                0.027739105746150017,
                0.0134397828951478,
                -0.0056431894190609455,
                0.03866758942604065,
                -0.024903200566768646,
                -0.0073586939834058285,
                -0.05958201363682747,
                -0.019278623163700104,
                -0.010820754803717136,
                -0.009961352683603764,
                0.07097446173429489,
                -0.026357516646385193,
                -0.01981089636683464,
                0.030334826558828354,
                0.02843436226248741,
                -0.053740136325359344,
                0.024095304310321808,
                0.04923679307103157,
                0.03151717782020569,
                0.0163909699767828,
                0.08154205977916718,
                0.016461361199617386,
                0.0007026090170256793,
                -0.0489899180829525,
                -0.013400588184595108,
                -0.00301823066547513,
                0.077239029109478,
                0.006519629620015621,
                0.038997143507003784,
                0.02523829974234104,
                0.027047060430049896,
                0.008488626219332218,
                -0.031092176213860512,
                -0.14461356401443481,
                -0.043020788580179214,
                0.029942281544208527,
                -6.793162751618799e-33,
                0.004906999878585339,
                -0.04200254753232002,
                0.030988546088337898,
                -0.01332625187933445,
                -0.02383001334965229,
                0.04770632088184357,
                -0.016643021255731583,
                -0.021101046353578568,
                -0.08455852419137955,
                -0.028397008776664734,
                -0.044235751032829285,
                -0.002817474538460374,
                0.035658955574035645,
                0.0059010786935687065,
                -0.0018560594180598855,
                -0.04161497950553894,
                -0.015240456908941269,
                0.00885956734418869,
                -0.01233822014182806,
                -0.012226646766066551,
                0.035682737827301025,
                0.0231100395321846,
                0.04661732539534569,
                0.0824519619345665,
                0.05305774137377739,
                0.08641605824232101,
                -0.027566447854042053,
                -0.07110651582479477,
                -0.0014957101084291935,
                0.019327105954289436,
                -0.042359016835689545,
                -0.008634964935481548,
                0.011216635815799236,
                -0.07902377843856812,
                -0.009857313707470894,
                0.024926554411649704,
                -0.033824045211076736,
                -0.08531893789768219,
                0.008592787198722363,
                -0.05773097276687622,
                -0.055020350962877274,
                -0.03307024762034416,
                0.02357874996960163,
                0.04160679131746292,
                0.06498311460018158,
                -0.0626910924911499,
                0.0006521622417494655,
                0.009414931759238243,
                0.02029414102435112,
                0.04821871966123581,
                -0.006194614805281162,
                -0.004670287016779184,
                -0.02866365574300289,
                0.060168616473674774,
                -0.02172221429646015,
                0.051957178860902786,
                -0.01984127052128315,
                0.03801463916897774,
                -0.03172854334115982,
                0.027855850756168365,
                -0.0036942537408322096,
                0.058158013969659805,
                -0.01547408290207386,
                -0.05614696443080902,
                0.02190777286887169,
                0.0253212358802557,
                -0.10568894445896149,
                -0.017343534156680107,
                -0.006902251858264208,
                -0.009299660101532936,
                -0.01165653858333826,
                0.05268774554133415,
                -0.06490232795476913,
                -0.006392831448465586,
                -0.07144895195960999,
                -0.026115762069821358,
                -0.030983543023467064,
                0.019618365913629532,
                0.016107745468616486,
                0.026362255215644836,
                0.0384046770632267,
                -0.030275743454694748,
                0.0273418128490448,
                0.029037265107035637,
                -0.09272798150777817,
                0.009086893871426582,
                -0.03450014814734459,
                0.12028723955154419,
                6.995581497903913e-05,
                0.051825616508722305,
                -0.003090271493420005,
                0.006252001971006393,
                0.031071888282895088,
                0.036565959453582764,
                -0.013613466173410416,
                0.019290419295430183,
                0.009035518392920494,
                -0.08358356356620789,
                0.0062070912681519985,
                -0.01032294426113367,
                -0.033970896154642105,
                0.04247872903943062,
                -0.027693679556250572,
                -0.040916115045547485,
                0.027824468910694122,
                0.024480357766151428,
                -0.0818367674946785,
                -0.009655716829001904,
                -0.009061508812010288,
                -0.025666192173957825,
                -0.043228618800640106,
                0.0076447646133601665,
                0.029556481167674065,
                0.005924816243350506,
                -0.013648645021021366,
                0.023878533393144608,
                0.0012947552604600787,
                -0.04366578906774521,
                -0.05504376068711281,
                0.009673590771853924,
                0.043319735676050186,
                -0.019186031073331833,
                -0.044512685388326645,
                -0.001872462104074657,
                0.02554858662188053,
                0.021885637193918228,
                0.017137328162789345,
                -0.013036093674600124,
                -0.006651857867836952,
                -0.025104910135269165,
                -0.012183031067252159,
                -0.009852475486695766,
                2.2948663058741658e-07,
                0.012970752082765102,
                -0.04434734210371971,
                -0.011433573439717293,
                -0.018045920878648758,
                0.004468007944524288,
                0.04820637404918671,
                0.027872733771800995,
                0.03957038000226021,
                -0.01607951521873474,
                0.007939141243696213,
                0.023385081440210342,
                -0.008290672674775124,
                0.00045940731070004404,
                -0.02166438102722168,
                0.0019189540762454271,
                -0.0006398828118108213,
                0.02420840412378311,
                -0.09090318530797958,
                -0.027717314660549164,
                -0.01696835085749626,
                0.015776950865983963,
                0.04735596477985382,
                -0.027711112052202225,
                -0.01357660349458456,
                -0.028054222464561462,
                0.010990210808813572,
                -0.0034508754033595324,
                -0.10957364737987518,
                -0.006155061069875956,
                0.003733380464836955,
                0.028700148686766624,
                0.024134133011102676,
                -0.01050521805882454,
                0.01435030810534954,
                -0.03822140023112297,
                -0.004621654283255339,
                0.02599553018808365,
                -0.03782885894179344,
                0.013926416635513306,
                -0.0032318623270839453,
                -0.047940757125616074,
                -0.023569179698824883,
                -0.061755143105983734,
                -0.017216214910149574,
                -0.02361368201673031,
                0.04002663120627403,
                -0.012285000644624233,
                -0.043433383107185364,
                -0.029290486127138138,
                0.014509808272123337,
                -0.04739759862422943,
                0.02829117141664028,
                0.0023989067412912846,
                -0.0004914081655442715,
                0.019509198144078255,
                0.018903981894254684,
                0.039581894874572754,
                0.01771845854818821,
                0.035396378487348557,
                0.028328489512205124,
                -0.021183153614401817,
                0.05972874537110329,
                -0.07673532515764236,
                0.057787321507930756,
                0.002646551700308919,
                0.06263329088687897,
                -0.041067227721214294,
                1.4723015086582562e-34,
                -0.03588792309165001,
                -0.07716912031173706,
                -0.03301550820469856,
                0.02303626574575901,
                0.031288210302591324,
                0.02215765044093132,
                0.0035314876586198807,
                0.007743625435978174,
                -0.03471210598945618,
                -0.07011903822422028,
                -0.01548835914582014,
                -0.019294874742627144,
                -0.09993848204612732,
                0.0032627840992063284,
                -0.005241112783551216,
                0.02940112166106701,
                0.05767647922039032,
                -0.04527301713824272,
                -0.015506385825574398,
                0.014195309951901436,
                0.0035646981559693813,
                0.06960936635732651,
                0.07011149823665619,
                -0.011664440855383873,
                0.0941254124045372,
                0.040414925664663315,
                0.010733520612120628,
                -0.02696477249264717,
                0.025968175381422043,
                0.045643217861652374,
                -0.0285493191331625,
                0.005289933644235134,
                0.017046626657247543,
                0.018653109669685364,
                0.06479626148939133,
                0.0859212875366211,
                0.023784274235367775,
                -0.041706815361976624,
                0.0404646210372448,
                0.01220649853348732,
                -0.027022741734981537,
                0.06629931926727295,
                -0.08348038047552109,
                -0.014507042244076729,
                0.007329614832997322,
                2.0286013295844896e-06,
                -0.0061416300013661385,
                -0.028135232627391815,
                0.0072221229784190655,
                -0.02139538712799549,
                0.031655147671699524,
                -0.06277081370353699,
                -0.034674469381570816,
                -0.022004181519150734,
                -0.036755405366420746,
                -0.021330293267965317,
                -0.08468165248632431,
                0.031223993748426437,
                -0.03910712152719498,
                0.025765836238861084,
                -0.011278005316853523,
                0.003510397160425782,
                -0.008268948644399643,
                -0.06123844534158707,
                -0.020200233906507492,
                0.07382183521986008,
                0.02245652675628662,
                0.016831550747156143,
                -0.027565453201532364,
                -0.012493370100855827,
                0.046182721853256226,
                0.020275408402085304,
                0.05501210317015648,
                -0.013146319426596165,
                -0.003270873799920082,
                0.06739839166402817,
                0.050514522939920425,
                0.031556423753499985,
                0.035823237150907516,
                0.04198135808110237,
                0.020217908546328545,
                0.014314339496195316,
                -0.020987529307603836,
                0.031045231968164444,
                0.06277575343847275,
                -0.01709567755460739,
                0.023216940462589264,
                0.016491247341036797,
                -0.027478469535708427,
                -0.05540527030825615,
                -0.03782160207629204,
                -0.0014041927643120289,
                0.014953377656638622,
                -0.0275458674877882,
                0.02135075069963932,
                0.03653596714138985,
                -0.01726578176021576,
                0.02032284624874592,
                0.02503327652812004,
                -0.0358310341835022,
                0.01570267602801323,
                -0.030028196051716805,
                -0.05611063912510872,
                -0.023515596985816956,
                0.030201321467757225,
                -0.034104201942682266,
                -0.01196593139320612,
                0.003020358271896839,
                -0.006540636532008648,
                -0.03133973851799965,
                -0.04929658770561218,
                -0.015043484978377819,
                0.053518641740083694,
                0.07052428275346756,
                0.021657317876815796,
                -0.027851464226841927,
                -0.020766112953424454,
                0.04160196706652641,
                0.04370700940489769,
                -0.029096173122525215,
                0.02909795381128788,
                -0.0017946244915947318,
                -0.018549691885709763,
                -0.05716194957494736,
                0.0593235045671463,
                -0.05324254184961319,
                0.01915329322218895,
                -0.0724039301276207,
                -0.039541538804769516,
                0.031626973301172256,
                0.03198102489113808,
                0.058041565120220184,
                0.015071375295519829,
                -0.0460350401699543,
                0.017697209492325783,
                0.018604541197419167,
                0.01014567818492651,
                -0.0050804163329303265,
                0.007931328378617764,
                0.055864207446575165,
                0.017434068024158478,
                -0.015222755260765553,
                -0.034866802394390106,
                -0.029790394008159637,
                -0.0219289418309927,
                0.023551329970359802,
                0.02796996384859085,
                0.005480049643665552,
                0.006242814939469099,
                -0.04611680656671524,
                0.025583259761333466,
                -0.0355343222618103,
                0.00518834451213479,
                -0.039741139858961105,
                -0.0375555120408535,
                -0.01437187660485506,
                0.010636204853653908,
                -0.015594391152262688,
                -0.012507040984928608,
                -0.013875612989068031,
                -0.0026577136013656855,
                0.0450533889234066,
                0.005066874902695417,
                -0.013467861339449883,
                0.0028520680498331785,
                0.04827570170164108,
                0.005122506525367498,
                -0.017603665590286255,
                -0.005010323598980904,
                0.002666728338226676,
                -0.03177805617451668,
                0.050740547478199005,
                -0.030831925570964813,
                -0.009700906462967396,
                0.027671337127685547,
                -0.044856857508420944,
                -0.018111389130353928,
                0.0034229292068630457,
                -0.05230988934636116,
                0.0009980355389416218,
                -0.0663103312253952,
                -0.012454913929104805,
                0.0013447533128783107,
                -0.01647876389324665,
                -0.06676087528467178,
                0.03377367556095123,
                0.03152713179588318,
                -0.07330743968486786,
                0.014434306882321835,
                -0.03818211704492569,
                -0.007440435700118542,
                0.006393641699105501,
                -0.1170663982629776,
                0.01723712682723999,
                -0.026507729664444923,
                0.014344229362905025,
                0.03737896308302879,
                -0.031124891713261604,
                0.0548359751701355,
                -0.06509339064359665,
                0.006299914792180061,
                0.0012961013708263636,
                0.014606393873691559,
                0.028291042894124985,
                -0.009657442569732666,
                0.049209535121917725,
                0.0004300793807487935,
                -0.03861789405345917,
                0.0683419406414032,
                0.030746011063456535,
                -0.02239602617919445,
                -0.055525798350572586,
                -0.03718790039420128,
                0.05736009031534195,
                -0.005858503747731447,
                -0.0071657816879451275,
                0.02427559718489647,
                -0.0635354071855545,
                0.01396366860717535,
                -0.054892558604478836,
                -0.004010769538581371,
                0.058750759810209274,
                0.014834181405603886,
                0.009130707010626793,
                0.006931349169462919,
                -0.023172808811068535,
                -0.026135355234146118,
                -0.0007123221876099706,
                0.06321611255407333,
                -0.0951632708311081,
                0.05131002515554428,
                -0.012631116434931755,
                -0.07800297439098358,
                -0.029624562710523605,
                -0.017257658764719963,
                0.006854967679828405,
                -0.0006855099345557392,
                0.045250244438648224,
                -0.02245422825217247,
                -0.048247080296278,
                -0.008689255453646183,
                -0.002676536561921239,
                0.02205212600529194,
                0.005613149143755436,
                -0.018529798835515976,
                -0.0033723083324730396,
                0.05313568189740181,
                0.03567995876073837,
                -0.04779062792658806,
                0.013404362834990025,
                -0.06622356921434402,
                0.0018833312205970287,
                0.0411083959043026,
                -0.01741347461938858,
                0.04037638008594513,
                -0.016704590991139412,
                0.01803179271519184,
                0.06421538442373276,
                -0.011120208539068699,
                2.0502831830526702e-05,
                -0.004445545841008425,
                -0.04375924915075302,
                -0.050059974193573,
                0.04088164493441582,
                0.05946601554751396,
                0.004769345745444298,
                -0.05973571911454201,
                0.02828894928097725,
                0.044115737080574036,
                -0.038209520280361176,
                -0.006322611588984728,
                0.04449140653014183,
                -0.024396227672696114,
                -0.006585581228137016,
                -0.027203017845749855,
                0.06849773228168488,
                -0.05267209932208061,
                0.023545287549495697,
                -0.04722641780972481,
                9.152387065114453e-06,
                0.007834719493985176,
                0.021329235285520554,
                0.031729456037282944,
                -0.011054865084588528,
                -0.020528003573417664,
                0.013649115338921547,
                0.0066200364381074905,
                -0.047082118690013885,
                0.05861072242259979,
                -0.05544902756810188,
                -0.03235411271452904,
                0.02701500989496708,
                0.010918905958533287,
                -0.014969959855079651,
                0.017879454419016838,
                0.01144997589290142,
                0.053431395441293716,
                -0.02136126160621643,
                -0.05423274263739586,
                -0.09428832679986954,
                0.0372987799346447,
                -0.040425803512334824,
                -0.015511597506701946,
                0.005802607163786888,
                -0.05940400809049606,
                -0.019068682566285133,
                0.01906469091773033,
                -0.05705082044005394,
                0.05448046326637268,
                0.026197107508778572,
                -0.007609750609844923,
                -0.04577802121639252,
                0.00555460574105382,
                -0.008662070147693157,
                -0.04908780753612518,
                -0.031725864857435226,
                -0.035730428993701935,
                0.03165581077337265,
                0.07614657282829285,
                0.009635642170906067,
                0.05354343354701996,
                0.028228972107172012,
                -0.005594276823103428,
                -0.02558191493153572,
                0.011317785829305649,
                0.022457929328083992,
                -0.000495661050081253,
                0.03166910633444786,
                -0.005659833084791899,
                -0.024621890857815742,
                0.0015299745136871934,
                -0.02093609794974327,
                -0.0043725185096263885,
                -0.003449847921729088,
                0.004498835653066635,
                -0.04115070775151253,
                0.028823653236031532,
                0.02208985760807991,
                0.025992559269070625,
                -0.089341901242733,
                -0.036959052085876465,
                0.010246818885207176,
                0.048374008387327194,
                -0.0016152444295585155,
                0.003795838914811611,
                0.00621637562289834,
                0.011317222379148006,
                -0.028808465227484703,
                -0.08039384335279465,
                -0.026400774717330933,
                0.014615489169955254,
                0.007165114860981703,
                -0.03860139101743698,
                -0.009562134742736816,
                0.015408226288855076,
                -0.06066926568746567,
                -0.007208665832877159,
                0.03141586482524872,
                0.009812490083277225,
                -0.03075116127729416,
                0.04253387823700905,
                -0.01813596300780773,
                0.041481539607048035,
                -0.023428048938512802,
                -0.056176479905843735,
                0.014379343017935753,
                0.03645888343453407,
                0.012573051266372204,
                -0.06117171794176102,
                -0.010535120964050293,
                -0.028365328907966614,
                0.034428223967552185,
                0.08680751174688339,
                0.017199693247675896,
                -0.04917905107140541,
                -0.06058460846543312,
                0.030919672921299934,
                0.018861664459109306,
                -0.011197046376764774,
                -0.03903716802597046,
                -0.04465952888131142,
                0.04145237058401108,
                0.01648140139877796,
                0.023657940328121185,
                0.03412514552474022,
                0.0018223130609840155,
                0.014113092795014381,
                0.005244888830929995,
                -0.004529817029833794,
                0.03812556713819504,
                0.003247240325435996,
                0.05081053078174591,
                -0.03468950465321541,
                0.010374107398092747,
                0.008613865822553635,
                0.042485661804676056,
                0.018336880952119827,
                -0.03189711645245552,
                -0.043411605060100555,
                -0.004523283336311579,
                0.038820620626211166,
                0.07286630570888519,
                -0.07239343971014023,
                0.039740677922964096,
                0.06714441627264023,
                0.0031277132220566273,
                -0.03645413741469383,
                -0.024067863821983337,
                0.01188261155039072,
                -0.030790437012910843,
                0.06450024247169495,
                0.07036319375038147,
                0.026704059913754463,
                0.03603917732834816,
                0.044598594307899475,
                -0.051136963069438934,
                0.0015138078015297651,
                0.01405429095029831,
                -0.03717269003391266,
                0.030083918944001198,
                -0.023367928341031075,
                0.019738178700208664,
                -0.006482720375061035,
                0.0037009192164987326,
                -0.017182890325784683,
                -0.025168457999825478,
                -0.0529082752764225,
                -0.02839314565062523,
                0.009245260618627071,
                0.04770948365330696,
                0.019101111218333244,
                0.034041061997413635,
                0.060702528804540634,
                -0.04439512640237808,
                -0.012387098744511604,
                -0.03018018789589405,
                0.019200779497623444,
                -0.01049777027219534,
                0.004873344209045172,
                0.053713109344244,
                -0.003334180684760213,
                -0.058323316276073456,
                -0.012941252440214157,
                -0.08056288957595825,
                0.037391673773527145,
                0.054902832955121994,
                -0.12112652510404587,
                0.04550427570939064,
                0.040286168456077576,
                0.006809755694121122,
                -0.0034154453314840794,
                -0.016118178144097328,
                0.015045997686684132,
                -0.002733958652243018,
                -0.030148016288876534,
                0.038405969738960266,
                0.0003420101711526513,
                -0.024368012323975563,
                0.027939442545175552,
                -0.052050407975912094,
                0.0022963820956647396,
                0.05184178799390793,
                0.03748786076903343,
                0.009783955290913582,
                -0.025840207934379578,
                -0.008570486679673195,
                -0.0436895526945591,
                -0.03006005473434925,
                0.0007295008981600404,
                -0.043560028076171875,
                0.0031455145217478275,
                0.03969065099954605,
                0.03212510049343109,
                -0.022017313167452812,
                0.009600750170648098,
                0.03075386770069599,
                0.04264078661799431,
                0.02636769786477089,
                0.011892225593328476,
                0.004163873847573996,
                0.0018134732963517308,
                0.006583991926163435,
                -0.021309223026037216,
                -0.007906685583293438,
                0.028930099681019783,
                0.022924700751900673,
                0.04493565857410431,
                -0.009853976778686047,
                -0.011690782383084297,
                0.00887378677725792,
                0.0045235916040837765,
                0.033331334590911865,
                0.08809356391429901,
                -0.06556487828493118,
                -0.030070103704929352,
                -0.015830643475055695,
                -0.02868511900305748,
                0.057795602828264236,
                0.00936264917254448,
                -0.027557870373129845,
                -0.014587253332138062,
                -0.04097779840230942,
                -0.0453430637717247,
                0.019000856205821037,
                0.026998447254300117,
                -0.009540660306811333,
                0.0019819096196442842,
                -0.07382054626941681,
                -0.04576665535569191,
                -0.035796768963336945,
                -0.039168715476989746,
                0.055180635303258896,
                0.022855935618281364,
                0.02182401902973652,
                -0.012459035962820053,
                0.04722939804196358,
                0.006656530778855085,
                -0.06844528019428253,
                0.02646578475832939,
                0.07740933448076248,
                -0.009492926299571991,
                0.03995837643742561,
                0.025592312216758728,
                -0.0012936400016769767,
                -0.0463046133518219,
                0.001368363737128675,
                0.013252252712845802,
                0.08047284930944443,
                -0.023985665291547775,
                0.016065577045083046,
                0.03162186220288277,
                -0.019363969564437866,
                -0.05663055181503296,
                0.02288566716015339,
                0.03134067729115486,
                0.022792384028434753,
                0.010713594034314156,
                -0.022352896630764008,
                0.022412322461605072,
                0.023783991113305092,
                0.014611102640628815,
                0.02616080828011036,
                0.03777364641427994,
                0.024312138557434082,
                0.015164573676884174,
                -0.029848257079720497,
                0.056504808366298676,
                -0.027997661381959915,
                -0.05973433330655098,
                -0.03251538425683975,
                -0.03524000197649002,
                -0.02263946272432804,
                -0.020909614861011505,
                -0.048369478434324265,
                0.033663369715213776,
                0.013366295956075191,
                0.04746675863862038,
                0.029925186187028885,
                0.025850530713796616,
                -0.015829041600227356,
                0.02132118120789528,
                0.011756920255720615,
                -0.0012854643864557147,
                -0.060567706823349,
                -0.002949158428236842,
                0.019494958221912384,
                -7.414357149414652e-33,
                0.04724457487463951,
                -0.019656945019960403,
                0.010183868929743767,
                0.0484883151948452,
                -0.013583640567958355,
                0.015076087787747383,
                -0.019648240879178047,
                0.06270432472229004,
                -0.006169744301587343,
                0.018836522474884987,
                -0.01635821722447872,
                -0.006754858884960413,
                0.039815571159124374,
                -0.014661159366369247,
                0.004860204178839922,
                -0.004020183812826872,
                0.0331549271941185,
                -0.0460536815226078,
                0.000488458841573447,
                -0.023837272077798843,
                -0.045385971665382385,
                -0.009465891867876053,
                0.0063112410716712475,
                -0.06827908754348755,
                0.013513682410120964,
                -0.018127715215086937,
                -0.026485590264201164,
                -0.028978077694773674,
                -0.0020384748931974173,
                0.03940469026565552,
                0.0035744640044867992,
                -0.03843710944056511,
                -0.03556918725371361,
                -0.015366112813353539,
                0.005841937847435474,
                0.019249795004725456,
                -0.004348341841250658,
                0.004524479620158672,
                -0.018235385417938232,
                -0.001983084250241518,
                -0.057505518198013306,
                -0.0596063956618309,
                -0.013355099596083164,
                -0.01617266982793808,
                0.0059379697777330875,
                -0.03874555230140686,
                0.012968333438038826,
                -0.026128116995096207,
                0.008946451358497143,
                -0.028246590867638588,
                0.06985396891832352,
                0.011139055714011192,
                -0.020930493250489235,
                0.03629906848073006,
                -0.006389022804796696,
                0.04294637218117714,
                -0.017290225252509117,
                0.010991578921675682,
                0.07139807194471359,
                -0.025696422904729843,
                -0.061952143907547,
                -0.0885634496808052,
                0.026828359812498093,
                -0.01088026911020279,
                -0.0002576309780124575,
                0.05209977552294731,
                -0.07521938532590866,
                0.014374352991580963,
                -0.06490466743707657,
                0.01995135471224785,
                -0.014957860112190247,
                0.01596229523420334,
                0.005572187714278698,
                -0.0960073173046112,
                -0.050582755357027054,
                -0.01397731900215149,
                -0.03898118808865547,
                0.03847559541463852,
                0.0017884296830743551,
                0.010998990386724472,
                -0.013925144448876381,
                -0.022572236135601997,
                -0.001427140785381198,
                -0.01004564668983221,
                -0.0074175698682665825,
                -0.03249258175492287,
                -0.026388894766569138,
                -0.060922153294086456,
                0.035731878131628036,
                -0.004460054915398359,
                -0.019673317670822144,
                -0.044441837817430496,
                -0.011391732841730118,
                -0.0037870071828365326,
                0.026920940726995468,
                0.013994441367685795,
                -0.0008198493742384017,
                -0.0880001038312912,
                -0.02220884896814823,
                -0.06367906928062439,
                0.02407875657081604,
                -0.0034306682646274567,
                -0.025351854041218758,
                0.03828933462500572,
                -0.02795267663896084,
                -0.024445727467536926,
                0.015072978101670742,
                -0.026236388832330704,
                -0.024109015241265297,
                0.07108124345541,
                0.030093582347035408,
                0.02105230838060379,
                -0.0056641316041350365,
                -0.007021442521363497,
                -0.00791312288492918,
                -0.05644870176911354,
                -0.0058207521215081215,
                -0.093016117811203,
                0.020631548017263412,
                0.010456675663590431,
                -0.004604382440447807,
                -0.01621677726507187,
                -0.003923514857888222,
                0.06641685962677002,
                -0.03299136087298393,
                0.05355043336749077,
                -0.004497440066188574,
                0.0037384030874818563,
                0.005096067674458027,
                0.019478030502796173,
                -0.019825195893645287,
                -0.040800079703330994,
                2.6223025884064555e-07,
                0.015728764235973358,
                -0.018942005932331085,
                0.009448469616472721,
                0.08406221121549606,
                -0.0030137826688587666,
                0.0626680999994278,
                0.006545485928654671,
                0.02274549938738346,
                -0.009545297361910343,
                0.026827694848179817,
                -0.00012835748202633113,
                0.014774329960346222,
                0.0339224748313427,
                -0.054189685732126236,
                0.010255331173539162,
                -0.001511399750597775,
                0.006063503213226795,
                0.06292275339365005,
                0.033659592270851135,
                0.032194025814533234,
                0.011133300140500069,
                -0.007032540161162615,
                0.023026658222079277,
                -0.014573340304195881,
                0.0032923545222729445,
                -0.023010825738310814,
                0.02060585469007492,
                -0.004634452983736992,
                0.03877321258187294,
                0.017169952392578125,
                0.01563972607254982,
                0.03300292044878006,
                -0.04165583848953247,
                -0.01312331110239029,
                0.014736233279109001,
                -0.06105300411581993,
                0.04067549481987953,
                -0.0005601108423434198,
                -0.027421066537499428,
                0.08401196449995041,
                0.017913343384861946,
                -0.017324870452284813,
                -0.008382683619856834,
                -0.009543702006340027,
                -0.015342997387051582,
                -0.019969988614320755,
                0.03894660621881485,
                0.05134514346718788,
                -0.1009395569562912,
                -0.01880616508424282,
                -0.04449298605322838,
                0.003286937717348337,
                -0.012661118060350418,
                -0.02241538092494011,
                0.014378400519490242,
                0.023899616673588753,
                0.02285649999976158,
                0.02321780100464821,
                0.02489680051803589,
                0.07621985673904419,
                0.016470100730657578,
                -0.026694228872656822,
                0.0608765110373497,
                0.11739152669906616,
                0.018118487671017647,
                0.0784899964928627,
                -0.02066522091627121,
                1.6617876393853328e-34,
                0.01868860051035881,
                -0.029629524797201157,
                -0.03916659951210022,
                0.025111576542258263,
                -0.007600650656968355,
                -0.03287848085165024,
                0.0033417493104934692,
                -0.03829861804842949,
                0.02667461521923542,
                -0.04661760479211807,
                -0.033941201865673065,
                0.004059575963765383,
                0.01360226795077324,
                0.025614019483327866,
                -0.06068803369998932,
                -0.03156609460711479,
                0.002781268674880266,
                0.03023463860154152,
                0.021206708624958992,
                0.03440316393971443,
                0.0010781665332615376,
                0.04148782417178154,
                0.004293515346944332,
                0.0016401379834860563,
                0.0022934619337320328,
                0.01666988432407379,
                -0.0191459059715271,
                0.021173499524593353,
                0.01667298935353756,
                0.00023437183699570596,
                -0.030185265466570854,
                0.040153224021196365,
                0.00759004894644022,
                -0.02467424049973488,
                0.06332467496395111,
                0.01605856791138649,
                0.01175280287861824,
                -0.0323847159743309,
                -0.044889647513628006,
                -0.0051015885546803474,
                0.05929766967892647,
                0.02647526189684868,
                0.0021461595315486193,
                -0.0263543501496315,
                -0.03012770786881447,
                1.9351746232132427e-06,
                -0.043423399329185486,
                0.013526435010135174,
                -0.02242853492498398,
                0.00556996650993824,
                0.0065197814255952835,
                0.07045076787471771,
                -0.03354913368821144,
                -0.09284336119890213,
                -0.01100592128932476,
                0.027829468250274658,
                -0.06914221495389938,
                0.009861181490123272,
                0.029224073514342308,
                0.0028831574600189924,
                0.02012201026082039,
                0.026897266507148743,
                -0.064356729388237,
                -0.049350935965776443,
                0.03276853635907173,
                0.055511076003313065,
                0.0012120208702981472,
                -0.008381846360862255,
                -0.008085271343588829,
                -0.03030644729733467,
                -0.000556493119802326,
                0.010355067439377308,
                0.017815925180912018,
                -0.046275053173303604,
                -0.033725809305906296,
                0.04115423560142517,
                0.01941712573170662,
                0.03221925348043442,
                0.015554558485746384,
                0.039884936064481735,
                0.0018923634197562933,
                0.012102056294679642,
                -0.04525293409824371,
                0.0027047693729400635,
                0.05853283405303955,
                -0.05863163620233536,
                0.02858644537627697,
                -0.028605381026864052,
                0.04881690442562103,
                -0.02743757888674736,
                -0.06433074921369553,
                0.05698654055595398,
                0.06087696924805641,
                0.013976244255900383,
                0.06549035757780075,
                -0.028403257951140404,
                0.05338973551988602,
                0.0256588626652956,
                -0.01058137696236372,
                0.020575661212205887,
                -0.04211132228374481,
                -0.00480960588902235,
                -0.030313989147543907,
                0.025706198066473007,
                0.0491454154253006,
                0.00221964530646801,
                -0.02752189338207245,
                -0.03631911426782608,
                -0.04486708715558052,
                0.06616377085447311,
                -0.08938188850879669,
                -0.047130465507507324,
                0.03553329035639763,
                0.008843140676617622,
                0.04512130469083786,
                -0.024898990988731384,
                -0.008748441934585571,
                0.05852840095758438,
                -0.101579450070858,
                -0.011838818900287151,
                0.049303144216537476,
                -0.0004809036327060312,
                0.018025601282715797,
                -0.03955775871872902,
                0.05743172764778137,
                -0.018074750900268555,
                -0.027364863082766533,
                -0.029450060799717903,
                -0.014292778447270393,
                0.03674749657511711,
                0.033778995275497437,
                -0.0626504123210907,
                -0.005933072883635759,
                -0.011115843430161476,
                0.018121114000678062,
                0.021227845922112465,
                -0.016560189425945282,
                -0.05688284710049629,
                0.019126225262880325,
                -0.03218575567007065,
                -0.004707330837845802,
                0.029560411348938942,
                0.02229168638586998,
                0.0049970936961472034,
                0.050870127975940704,
                -0.006561179179698229,
                -0.0010430394904688,
                -0.022906119003891945,
                -0.06026844307780266,
                0.030975554138422012,
                0.004195180255919695,
                0.01735963299870491,
                0.04514990374445915,
                0.014100898057222366,
                -0.027529733255505562,
                0.057405002415180206,
                -0.024302303791046143,
                0.02425871230661869,
                -0.03219733014702797,
                -0.05239679291844368,
                0.008497841656208038,
                0.10927712172269821,
                0.03570442274212837,
                0.008104303851723671,
                -0.004415921401232481,
                0.05479361489415169,
                -0.01822880282998085,
                -0.012258543632924557,
                -0.005519535858184099,
                -0.050258226692676544,
                0.014461856335401535,
                -0.012733695097267628,
                0.034163568168878555,
                0.021402528509497643,
                -0.0049415454268455505,
                -0.01209989096969366,
                -0.012651779688894749,
                0.03421473503112793,
                0.02684354968369007,
                -0.04441677778959274,
                -0.01432096678763628,
                -0.03438206762075424,
                -0.03180640935897827,
                -0.046145085245370865,
                0.01600184105336666,
                0.00764172850176692,
                0.06891504675149918,
                -0.039479777216911316,
                0.001826006919145584,
                -0.06002171337604523,
                -0.017103752121329308,
                0.035807423293590546,
                -0.04884142428636551,
                0.02144039049744606,
                -0.031017355620861053,
                0.014716179110109806,
                -0.01432876382023096,
                0.05813361704349518,
                0.004879370331764221,
                0.008301002904772758,
                -0.028105756267905235,
                0.03881874307990074,
                0.00410442752763629,
                -0.0224232766777277,
                -0.006258722860366106,
                0.02183951437473297,
                0.025839973241090775,
                0.03245246037840843,
                -0.03570711985230446,
                0.035675689578056335,
                0.013005748391151428,
                -0.009869755245745182,
                0.0015127334045246243,
                0.005721044261008501,
                -0.009652850218117237,
                -0.012737867422401905,
                0.03486058488488197,
                0.05437019094824791,
                -0.031865496188402176,
                -0.013880305923521519,
                -0.04277804493904114,
                -0.03961128741502762,
                0.00846193078905344,
                0.016500379890203476,
                0.010715588927268982,
                -0.014835432171821594,
                0.051578544080257416,
                0.03813057392835617,
                -0.038388144224882126,
                -0.027042124420404434,
                0.0004224315925966948,
                0.03375918045639992,
                0.08336980640888214,
                0.02693903259932995,
                -0.022830551490187645,
                0.018182771280407906,
                -0.012058117426931858,
                -0.04202551767230034,
                -0.0015972517430782318,
                -0.0028426656499505043,
                -0.016725806519389153,
                -0.016767051070928574,
                0.02534748800098896,
                -0.019415203481912613,
                0.0413382351398468,
                -0.04679595306515694,
                -0.010880202986299992,
                0.02921886555850506,
                -0.055099647492170334,
                -0.007424983661621809,
                -0.07704931497573853,
                0.07013411819934845,
                0.000267351686488837,
                0.004601603373885155,
                0.0030125610064715147,
                0.09169554710388184,
                -0.0021857076790183783,
                -0.0057770321145653725,
                -0.0352909080684185,
                0.036002252250909805,
                0.0074348850175738335,
                -0.037092503160238266,
                -0.022079110145568848,
                0.012603129260241985,
                -0.00751638226211071,
                -0.014405209571123123,
                -0.12031396478414536,
                -0.02511495351791382,
                0.04354380816221237,
                0.04353358969092369,
                -0.03795579448342323,
                0.09728293120861053,
                -0.06698441505432129,
                -0.002153728622943163,
                0.02282537892460823,
                0.06593464314937592,
                0.011907776817679405,
                0.03694480285048485,
                0.05014011263847351,
                -0.0035154130309820175,
                0.004149637185037136,
                0.014063739217817783,
                0.009290696121752262,
                0.011839183047413826,
                0.031311195343732834,
                -0.0004780589079018682,
                0.014263330958783627,
                -0.024049676954746246,
                0.029208015650510788,
                -0.0075840456411242485,
                -0.006034932564944029,
                4.109534711460583e-05,
                -0.019393501803278923,
                0.027914972975850105,
                0.021220387890934944,
                -0.0237945057451725,
                0.030263476073741913,
                0.021820705384016037,
                -0.01067444309592247,
                0.01765798218548298,
                -0.01144442055374384,
                -0.06604236364364624,
                -0.01205100491642952,
                0.01814490742981434,
                -0.005435544066131115,
                0.05364435166120529,
                0.029717054218053818,
                -0.04985716938972473,
                -0.011647067964076996,
                -0.02262052707374096,
                0.010593695566058159,
                -0.060795240104198456,
                -0.018433937802910805,
                -0.052413564175367355,
                -0.028549131006002426,
                -0.060813166201114655,
                -0.022627834230661392,
                0.004408380948007107,
                0.026293672621250153,
                -0.0034607546404004097,
                -0.049720484763383865,
                0.04256496578454971,
                0.03782961145043373,
                -0.03923502936959267,
                -0.02705732360482216,
                -0.010609079152345657,
                0.03605378419160843,
                0.03678034245967865,
                -0.023360643535852432,
                0.01061490923166275,
                0.04781802371144295,
                0.0313262976706028,
                0.009439431130886078,
                -0.07572758197784424,
                0.0036021466366946697,
                0.020442284643650055,
                0.059558697044849396,
                -0.0010421558981761336,
                -0.010994513519108295,
                -0.049335215240716934,
                -0.018423670902848244,
                0.04860173910856247,
                0.009498932398855686,
                -0.031286243349313736,
                -0.005124891176819801,
                8.630322554381564e-05,
                -0.0042634685523808,
                -0.01570827141404152,
                -0.07778844237327576,
                0.0042029619216918945,
                0.02734028734266758,
                -0.026521287858486176,
                -0.027325769886374474,
                -0.009535633027553558,
                0.04507007449865341,
                -0.04385865852236748,
                -0.020321594551205635,
                0.022316234186291695,
                0.013682973571121693,
                0.0007248863112181425,
                -0.008503258228302002,
                -0.05693808197975159,
                -0.001297455164603889,
                -0.0007521079969592392,
                -0.04368097707629204,
                -0.04199625551700592,
                -0.05467567592859268,
                0.018396111205220222,
                -0.07402662187814713,
                0.023274336010217667,
                0.05284534767270088,
                -0.05571374669671059,
                0.08662287145853043,
                0.013285694643855095,
                -0.053561192005872726,
                -0.016124345362186432,
                0.02251523733139038,
                0.06533721834421158,
                -0.016802173107862473,
                0.05314034968614578,
                -0.0379243902862072,
                -0.046707019209861755,
                0.038406550884246826,
                0.023989826440811157,
                0.03582169488072395,
                -0.0013875877484679222,
                -0.024410493671894073,
                0.017839275300502777,
                0.03789179399609566,
                0.006259728688746691,
                -0.02912110462784767,
                -0.0345248244702816,
                -0.07380881160497665,
                -0.009486834518611431,
                0.03494997322559357,
                -0.031580451875925064,
                -0.044918447732925415,
                -0.011331078596413136,
                0.11310385167598724,
                -0.06453157961368561,
                -0.0029954765923321247,
                0.033570993691682816,
                0.03976992890238762,
                -0.02388782799243927,
                -0.04431978985667229,
                0.03831520676612854,
                0.03053470514714718,
                -0.041938964277505875,
                -0.025502342730760574,
                0.0013219077372923493,
                0.04793062433600426,
                -0.021366102620959282,
                0.07311159372329712,
                -0.0503084622323513,
                0.03201469033956528,
                0.024352556094527245,
                0.024935370311141014,
                0.05454135313630104,
                -0.02318207174539566,
                0.041388630867004395,
                -0.05054493620991707,
                -0.006212061271071434,
                -0.020487984642386436,
                -0.02855021320283413,
                -0.028861399739980698,
                -0.04874616861343384,
                -0.06597965955734253,
                -0.004107682034373283,
                -0.09433040767908096,
                0.02323485165834427,
                -0.012689844705164433,
                0.020665252581238747,
                0.010627232491970062,
                -0.03132680058479309,
                -0.001352568157017231,
                0.06931502372026443,
                0.020438456907868385,
                0.050989970564842224,
                0.0006086790235713124,
                0.008983016945421696,
                0.0003575469017960131,
                -0.018731823191046715,
                -0.028333965688943863,
                -0.0028382353484630585,
                -0.012697131372988224,
                0.024709900841116905,
                -0.058899376541376114,
                0.002745480975136161,
                0.04889490827918053,
                0.0038883306551724672,
                -0.004171076696366072,
                -0.04189435392618179,
                0.018214071169495583,
                -0.021720286458730698,
                0.03214316442608833,
                -0.001303466851823032,
                -0.0037113490980118513,
                0.029138699173927307,
                0.04057252034544945,
                -0.05920449644327164,
                0.015943003818392754,
                0.024317655712366104,
                -0.0222537349909544,
                0.017988884821534157,
                0.015410604886710644,
                -0.014227690175175667,
                0.003918966744095087,
                0.046794917434453964,
                -0.06002343073487282,
                0.028178490698337555,
                0.029504913836717606,
                0.03574409335851669,
                0.03356878086924553,
                -0.008991369046270847,
                0.016342302784323692,
                -0.06832363456487656,
                -0.09575477987527847,
                -0.04513553902506828,
                -0.054210249334573746,
                -0.004174981731921434,
                -0.02686617709696293,
                -0.03243336081504822,
                0.0009130139369517565,
                -0.008901050314307213,
                0.014321467839181423,
                0.031222177669405937,
                -0.030957533046603203,
                -0.03216981887817383,
                -0.023665443062782288,
                -0.03232426941394806,
                0.0019982503727078438,
                0.044423650950193405,
                -0.010365775786340237,
                -0.030997207388281822,
                -0.039777036756277084,
                0.033877331763505936,
                -0.020505664870142937,
                0.052240364253520966,
                0.01358374860137701,
                0.01596246100962162,
                0.017445383593440056,
                0.019607696682214737,
                0.050792396068573,
                0.023785855621099472,
                0.00581969553604722,
                0.0077737304382026196,
                -0.019437989220023155,
                -0.0213177390396595,
                -0.07402685284614563,
                -0.007171874865889549,
                -0.05335155501961708,
                0.008818268775939941,
                0.029214298352599144,
                0.010052084922790527,
                -0.015475522726774216,
                -0.0038859592750668526,
                -0.03023410029709339,
                -0.02798023633658886,
                -0.016321098431944847,
                0.019412456080317497,
                -0.09302246570587158,
                -0.03104928694665432,
                0.003297441639006138,
                0.016023054718971252,
                -0.010007555596530437,
                -0.016561519354581833,
                0.034098707139492035,
                -0.0005059435497969389,
                0.007990927435457706,
                -0.010548066347837448,
                0.009265894070267677,
                0.009928942658007145,
                0.0426269993185997,
                0.015550224110484123,
                0.03136024251580238,
                -0.024908054620027542,
                -0.018212780356407166,
                0.011406383477151394,
                -0.00666795251891017,
                -0.010749084874987602,
                -0.014400837942957878,
                0.09834253787994385,
                -0.030126800760626793,
                0.004470035433769226,
                0.0009370328043587506,
                -0.034393515437841415,
                -0.01021319068968296,
                0.0014991529751569033,
                0.03406059369444847,
                -0.04563675820827484,
                0.0020499711390584707,
                -0.04982641711831093,
                -0.04485080763697624,
                -0.033752813935279846,
                -0.008123186416924,
                0.011120731011033058,
                0.02221827395260334,
                -0.02266615256667137,
                0.021394677460193634,
                -0.0362389013171196,
                -0.041666485369205475,
                -0.003972915932536125,
                0.08819535374641418,
                -0.028514865785837173,
                -0.0059896670281887054,
                0.006444017868489027,
                -7.660456442650898e-33,
                0.027346180751919746,
                0.013149334117770195,
                0.012501470744609833,
                -0.06608409434556961,
                -0.060457926243543625,
                -0.07055023312568665,
                0.005541331134736538,
                0.03289615735411644,
                -0.015283480286598206,
                0.028038034215569496,
                -0.019256113097071648,
                -0.025379810482263565,
                0.03338971361517906,
                0.03015540912747383,
                0.027932962402701378,
                0.019269205629825592,
                0.042831625789403915,
                -0.013416505418717861,
                -0.04470077529549599,
                -0.01674719527363777,
                0.005596954841166735,
                0.020424680784344673,
                0.04367358982563019,
                0.10090701282024384,
                -0.0709088072180748,
                -0.06813560426235199,
                -0.06187045946717262,
                0.06645216792821884,
                0.004013610538095236,
                -0.01823554001748562,
                -0.02167304791510105,
                0.044991638511419296,
                -0.033572692424058914,
                -0.04554210230708122,
                0.01833728887140751,
                -0.020160822197794914,
                -0.04237353429198265,
                -0.030437614768743515,
                0.005254643503576517,
                0.013980673626065254,
                -0.03684486821293831,
                -0.003703705733641982,
                -0.006723085418343544,
                0.013963790610432625,
                -0.08925686031579971,
                0.008894690312445164,
                0.006065488792955875,
                -0.012629229575395584,
                -0.028276879340410233,
                0.018887870013713837,
                -0.040006645023822784,
                0.020281871780753136,
                -0.038528673350811005,
                -0.01617232896387577,
                -0.020345790311694145,
                0.03932994231581688,
                0.010079747065901756,
                0.029086338356137276,
                -0.04375448450446129,
                -0.04988729581236839,
                0.010544534772634506,
                -0.02254924178123474,
                0.052840765565633774,
                -0.0020692525431513786,
                -0.020938284695148468,
                0.01581006869673729,
                -0.0036069913767278194,
                -0.003645473625510931,
                -0.026824941858649254,
                0.05497313663363457,
                -0.01666507124900818,
                -0.04650646075606346,
                -0.08533088117837906,
                0.01467627752572298,
                -0.01457290630787611,
                -0.06428741663694382,
                -0.039252229034900665,
                0.05705644562840462,
                -0.01276155561208725,
                0.01739436946809292,
                -0.044772058725357056,
                0.005138603504747152,
                -0.033561836928129196,
                0.010358133353292942,
                0.01788746938109398,
                0.03704269602894783,
                0.03314081206917763,
                0.02179078198969364,
                0.013556323014199734,
                0.05851847305893898,
                -0.013625584542751312,
                0.10036320239305496,
                -0.006400587502866983,
                0.05880976840853691,
                0.046915989369153976,
                0.0002190301747759804,
                -0.0005356682231649756,
                -0.014528108760714531,
                0.017391586676239967,
                -0.008957836776971817,
                0.024891899898648262,
                0.0034884363412857056,
                -0.01719966158270836,
                0.020257990807294846,
                0.01465130876749754,
                -0.004256121348589659,
                0.0351998433470726,
                0.021940724924206734,
                -0.04250118508934975,
                0.016169069334864616,
                -0.046941813081502914,
                -0.00892701931297779,
                0.09334413707256317,
                0.054301854223012924,
                0.0070193116553127766,
                -0.003671115031465888,
                -0.019482413306832314,
                -0.054466262459754944,
                0.007125789299607277,
                -0.04383985325694084,
                0.0322551466524601,
                0.05630510300397873,
                -0.05719080567359924,
                -0.040490876883268356,
                -0.019174009561538696,
                0.03901856765151024,
                0.006594707258045673,
                0.03789452090859413,
                -0.020550070330500603,
                -0.037633538246154785,
                0.001133481040596962,
                -0.0001277850242331624,
                2.7457542728370754e-07,
                0.017161423340439796,
                -0.04760656878352165,
                -0.0290144681930542,
                -0.01966075971722603,
                0.00654458487406373,
                0.04699493199586868,
                0.004562824033200741,
                0.046476591378450394,
                -0.02487269416451454,
                0.07683458924293518,
                -0.00217952742241323,
                -0.010540252551436424,
                0.026210546493530273,
                -0.05057652294635773,
                0.007937980815768242,
                -0.12475266307592392,
                0.023878369480371475,
                0.020459311082959175,
                0.009230581112205982,
                -0.020575400441884995,
                0.07966822385787964,
                0.019742043688893318,
                -0.04107176512479782,
                -0.032673414796590805,
                0.014021940529346466,
                -0.016882412135601044,
                -0.00023229409998748451,
                0.03682372719049454,
                -0.04651578143239021,
                0.028335997834801674,
                -0.03952504321932793,
                -0.02726184017956257,
                -0.006805879529565573,
                -0.035120997577905655,
                0.03586962819099426,
                -0.04170924425125122,
                0.054197534918785095,
                0.07591988891363144,
                -0.020265579223632812,
                0.05436429753899574,
                -0.04554838314652443,
                -0.04968561977148056,
                0.023788778111338615,
                -0.025717096403241158,
                0.037462830543518066,
                0.025668945163488388,
                -0.021326888352632523,
                0.08103250712156296,
                -0.06309989094734192,
                0.0374605655670166,
                0.03330419212579727,
                0.01231292448937893,
                0.06338339298963547,
                0.030727757140994072,
                -0.016117217019200325,
                0.06248825415968895,
                0.017781201750040054,
                0.008935871534049511,
                0.001310368301346898,
                -0.0634729266166687,
                0.02557222545146942,
                -0.0007704404997639358,
                0.022893236950039864,
                -0.030668014660477638,
                -0.00476421182975173,
                0.10313523560762405,
                -0.006834456697106361,
                1.666237987529149e-34,
                -0.002679566852748394,
                0.0016657911473885179,
                0.024605972692370415,
                0.004929475020617247,
                -0.022013697773218155,
                -0.027428144589066505,
                0.006699281744658947,
                -0.029563074931502342,
                0.011694949120283127,
                0.01548320148140192,
                -0.008357352577149868,
                0.0025140726938843727,
                -0.006910363212227821,
                0.006762054283171892,
                -0.07916028797626495,
                -0.002193635329604149,
                -0.005375020205974579,
                -0.0036488294135779142,
                0.02541491389274597,
                0.04605478048324585,
                0.00015145624638535082,
                0.003908786457031965,
                0.0754798874258995,
                -0.01787695847451687,
                0.051235709339380264,
                0.0049139210022985935,
                -0.05725441500544548,
                -0.010571525432169437,
                0.011514900252223015,
                -0.019513634964823723,
                -0.039290737360715866,
                0.057680729776620865,
                0.010898609645664692,
                -0.015689538791775703,
                0.08235451579093933,
                0.037961941212415695,
                -0.001993731362745166,
                -0.025551049038767815,
                -0.003777315840125084,
                -0.011349130421876907,
                0.013996642082929611,
                0.03583378717303276,
                -0.01192576251924038,
                -0.05360223352909088,
                0.0025577526539564133,
                2.0466216028580675e-06,
                -0.010911721736192703,
                -0.023071961477398872,
                0.0058738598600029945,
                -0.00921403057873249,
                0.0044358219020068645,
                0.042587317526340485,
                -0.016929563134908676,
                -0.05707326903939247,
                0.01700342260301113,
                0.020195510238409042,
                -0.04977850243449211,
                0.045425254851579666,
                0.0016987122362479568,
                0.0014427840942516923,
                0.0011143593583256006,
                -0.00969545915722847,
                -0.03596220165491104,
                -0.04981282353401184,
                0.011712141335010529,
                0.10460414737462997,
                0.00917722750455141,
                -0.006604988127946854,
                0.022567572072148323,
                -0.008962095715105534,
                0.020314311608672142,
                0.038907475769519806,
                -0.011726520024240017,
                -0.055533405393362045,
                -0.029566874727606773,
                0.05004425346851349,
                0.013807757757604122,
                0.05906052514910698,
                -0.006102986633777618,
                -0.02034720405936241,
                0.02528376877307892,
                0.0018224131781607866,
                -0.016221454367041588,
                0.017614521086215973,
                0.060118094086647034,
                -0.003785117529332638,
                0.06684993207454681,
                -0.007995205000042915,
                0.033751558512449265,
                0.011508330702781677,
                -0.029126323759555817,
                0.0484703853726387,
                0.050488755106925964,
                0.006339068058878183,
                0.043841540813446045,
                0.01286053005605936,
                0.0381450280547142,
                0.048341237008571625,
                -0.0016312216175720096,
                0.014674436300992966,
                0.0038471322041004896,
                0.05024376139044762,
                -0.05408189445734024,
                0.0600019209086895,
                0.08721917122602463,
                0.039767250418663025,
                -0.019623108208179474,
                0.02841363288462162,
                0.021401934325695038,
                0.05969521030783653,
                -0.07912307232618332,
                0.017187338322401047,
                0.0534159280359745,
                -0.008321362547576427,
                0.051045313477516174,
                -0.04378706216812134,
                -0.020501337945461273,
                0.005584138911217451,
                -0.07247903198003769,
                0.021601419895887375,
                0.05918341130018234,
                0.01960170827805996,
                -0.01270440686494112,
                -0.0356643870472908,
                0.01435388345271349,
                0.014633914455771446,
                -0.009380224160850048,
                -0.027285873889923096,
                0.010448075830936432,
                -0.00687122717499733,
                0.03972219303250313,
                -0.056117426604032516,
                -0.020149661228060722,
                -0.0009222388616763055,
                -0.014253206551074982,
                0.022776640951633453,
                0.0286039337515831,
                -0.06651701033115387,
                0.016628459095954895,
                -0.014909787103533745,
                -0.025279996916651726,
                -0.002565097063779831,
                -0.0002624543849378824,
                -0.006648185197263956,
                0.03971104696393013,
                0.09303444623947144,
                0.06096653267741203,
                -0.01275606919080019,
                0.02322833612561226,
                0.05700385197997093,
                -0.03052530623972416,
                -0.001648993929848075,
                -0.004810210317373276,
                0.030685368925333023,
                -0.07091690599918365,
                0.0345027782022953,
                -0.0495007187128067,
                0.01781453564763069,
                0.023053402081131935,
                -0.05300647020339966,
                -0.0031002915930002928,
                0.020258137956261635,
                -0.02003973349928856,
                -0.01578288897871971,
                -0.00013812904944643378,
                0.008649252355098724,
                -0.06065906584262848,
                -0.03619306907057762,
                -0.0735643208026886,
                -0.028989145532250404,
                0.008789687417447567,
                0.005865766666829586,
                0.0053539336659014225,
                0.022527456283569336,
                0.019612859934568405,
                0.009007171727716923,
                -0.004921522922813892,
                0.07963429391384125,
                -0.003153359517455101,
                0.006390674505382776,
                -0.0050135888159275055,
                -0.03877900913357735,
                -0.0401802584528923,
                -0.04726184904575348,
                0.023162811994552612,
                0.008277497254312038,
                0.05696078762412071,
                -0.05105772241950035,
                -0.02297774888575077,
                -0.054472941905260086,
                -0.06667449325323105,
                0.010479394346475601,
                -0.09068438410758972,
                -0.017278101295232773,
                -0.01756402663886547,
                -0.020434647798538208,
                0.01707855425775051,
                0.058817531913518906,
                0.018250729888677597,
                0.006542523391544819,
                -0.04404691606760025,
                -0.01388078834861517,
                -0.013887510634958744,
                -0.04644577205181122,
                0.018019050359725952,
                -0.02415037341415882,
                0.023798374459147453,
                0.030485190451145172,
                -0.050418008118867874,
                0.02594892494380474,
                0.02832045592367649,
                -0.06514714658260345,
                0.004295441322028637,
                0.022649850696325302,
                -0.008022541180253029,
                -0.03264627605676651,
                0.004455123096704483,
                0.06403113156557083,
                -0.05980728194117546,
                -0.0317324697971344,
                -0.022138742730021477,
                -0.0479162335395813,
                0.011697886511683464,
                0.022120777517557144,
                0.0547642782330513,
                -0.024005621671676636,
                0.03411019593477249,
                0.014896612614393234,
                -0.014019758440554142,
                -0.05785752832889557,
                -0.006890662480145693,
                0.027257489040493965,
                0.0822884663939476,
                0.04266716167330742,
                0.009654873982071877,
                -0.0010788824874907732,
                0.010921000503003597,
                -0.024509591981768608,
                0.03518475219607353,
                -0.012960230000317097,
                0.015106246806681156,
                0.0002880650863517076,
                0.044305071234703064,
                -0.0031039586756378412,
                0.008467436768114567,
                -0.0007554801995866001,
                0.021069275215268135,
                0.07271231710910797,
                -0.07161764055490494,
                0.014682150445878506,
                -0.06998582184314728,
                0.06256679445505142,
                -0.030567852780222893,
                -2.0624973330996e-05,
                0.04624835401773453,
                0.053469639271497726,
                -0.007205458357930183,
                -0.025363225489854813,
                -0.014664717949926853,
                0.015981243923306465,
                -0.00021930363436695188,
                0.006004705559462309,
                -0.04711173102259636,
                -0.017680350691080093,
                0.04032263904809952,
                -0.004021310713142157,
                -0.09614674001932144,
                0.011983010917901993,
                -0.016914237290620804,
                -0.03130189701914787,
                -0.021484913304448128,
                0.06393548101186752,
                -0.012217093259096146,
                -0.01325365249067545,
                0.020712371915578842,
                0.06575470417737961,
                0.016996873542666435,
                0.051400717347860336,
                0.04574771597981453,
                0.01888255402445793,
                0.018987460061907768,
                -0.007565179839730263,
                0.03808276355266571,
                -0.018411239609122276,
                0.001773318275809288,
                -0.037981487810611725,
                -0.0009990907274186611,
                0.060081303119659424,
                0.016888093203306198,
                -0.012455683201551437,
                0.002404034836217761,
                0.007279504090547562,
                -0.0015108227962628007,
                0.010739676654338837,
                -0.043911248445510864,
                -0.0016706343740224838,
                0.01934235170483589,
                0.05058962479233742,
                0.06915487349033356,
                -0.01871182583272457,
                -0.03451485186815262,
                -0.06273997575044632,
                -0.042359717190265656,
                0.048995740711688995,
                -0.02460685931146145,
                0.03623805195093155,
                0.008610263466835022,
                -0.030892392620444298,
                0.013924275524914265,
                -0.028882570564746857,
                -0.028494328260421753,
                -0.012325469404459,
                -0.010677979327738285,
                -0.018950747326016426,
                -0.010509109124541283,
                -0.08408107608556747,
                0.004110245034098625,
                0.048293061554431915,
                -0.025137200951576233,
                -0.01212826743721962,
                0.03283735737204552,
                0.042589396238327026,
                0.018664279952645302,
                -0.025335799902677536,
                -0.05560065060853958,
                -0.006529941689223051,
                0.04682367295026779,
                0.04490023851394653,
                -0.04728660359978676,
                0.020911794155836105,
                -0.014703251421451569,
                0.00941928569227457,
                0.04461922124028206,
                -0.020524656400084496,
                -0.014311031438410282,
                0.0017048702575266361,
                0.03174646571278572,
                -0.03347581624984741,
                -0.0057697962038218975,
                -0.012640098109841347,
                -0.0060693430714309216,
                0.026559490710496902,
                0.023237908259034157,
                -0.02417335845530033,
                0.03317352011799812,
                -0.005359012167900801,
                0.017510494217276573,
                -0.023489361628890038,
                -0.05516648292541504,
                0.031272172927856445,
                -0.006914249621331692,
                -0.010051540099084377,
                -0.04491379112005234,
                -0.006778527516871691,
                0.031362637877464294,
                -0.050378166139125824,
                -0.028730038553476334,
                0.03649124130606651,
                0.01747240126132965,
                -0.02226746641099453,
                -0.01542267668992281,
                -0.03980043902993202,
                0.023897983133792877,
                -0.013342166319489479,
                -0.046829093247652054,
                -0.003490233328193426,
                -0.02034468576312065,
                -0.01080731675028801,
                -0.060508325695991516,
                -0.002232335275039077,
                0.004591208882629871,
                -0.02894657664000988,
                0.06527792662382126,
                0.1118268072605133,
                -0.007166391704231501,
                -0.02538605034351349,
                0.021786430850625038,
                0.041230883449316025,
                0.0007980983355082572,
                -0.02938329614698887,
                -0.04042619466781616,
                -0.03324195742607117,
                0.017435425892472267,
                0.015489280223846436,
                0.06122197210788727,
                -0.03263432905077934,
                -0.0013833869015797973,
                0.02680651657283306,
                -0.004501724150031805,
                0.06386591494083405,
                -0.0037972370628267527,
                0.0204317569732666,
                0.0024731908924877644,
                -0.007006908766925335,
                -0.0007239471306093037,
                -0.07078380882740021,
                -0.08696497231721878,
                -0.054469380527734756,
                0.03540477529168129,
                -0.053135570138692856,
                -0.04535245895385742,
                0.02440868504345417,
                0.03238995745778084,
                0.02522607333958149,
                -0.05422382429242134,
                0.040343694388866425,
                -0.002856843639165163,
                -0.05047716945409775,
                -0.010749848559498787,
                -0.06161600723862648,
                0.026224346831440926,
                -0.026902884244918823,
                0.0185533557087183,
                0.028306784108281136,
                0.03621172904968262,
                0.03736424073576927,
                0.046228017657995224,
                0.0030749968718737364,
                -0.010714578442275524,
                -0.005232821684330702,
                -0.022777950391173363,
                0.014434105716645718,
                -0.009218063205480576,
                0.04229399189352989,
                -0.023464318364858627,
                -0.05542030557990074,
                -0.07027030736207962,
                0.009116707369685173,
                -0.06960061192512512,
                0.039125461131334305,
                -0.024096883833408356,
                -0.008367541246116161,
                0.022079547867178917,
                -0.06593676656484604,
                -0.0017295695142820477,
                0.02007495053112507,
                -0.0014059437671676278,
                0.07737935334444046,
                -0.018141958862543106,
                -0.002801318187266588,
                0.00789033342152834,
                -0.017651278525590897,
                -0.058526989072561264,
                0.01357706356793642,
                0.025305598974227905,
                0.04706774279475212,
                -0.039085496217012405,
                0.024077700451016426,
                0.06831755489110947,
                0.005168549716472626,
                -0.0054964120499789715,
                -0.018442023545503616,
                0.025796692818403244,
                -0.019847974181175232,
                0.052823860198259354,
                -0.025822767987847328,
                -0.03339111804962158,
                0.005048958584666252,
                0.04900798201560974,
                -0.009753390215337276,
                -0.0021827181335538626,
                0.0104781873524189,
                -0.011586240492761135,
                0.03247863054275513,
                0.005002664402127266,
                0.027167584747076035,
                -0.044284023344516754,
                0.07210641354322433,
                -0.059516750276088715,
                0.022449512034654617,
                0.03431924059987068,
                0.03175794333219528,
                0.0347590409219265,
                -0.03964732214808464,
                0.024988193064928055,
                -0.04030777886509895,
                -0.031595103442668915,
                -0.027987318113446236,
                -0.025746392086148262,
                -0.029670635238289833,
                -0.023354029282927513,
                0.008159075863659382,
                0.024019567295908928,
                -0.02657042257487774,
                0.014216993004083633,
                0.02049633488059044,
                0.004255338571965694,
                -0.022907234728336334,
                -0.02597789652645588,
                -0.007766571827232838,
                -0.0029226362239569426,
                -0.009502824395895004,
                0.006069352850317955,
                -0.05623514950275421,
                0.02694559097290039,
                0.010508943349123001,
                -0.06826639175415039,
                0.05720704048871994,
                0.03330109640955925,
                0.013126439414918423,
                0.025131063535809517,
                -0.007079176139086485,
                0.04916396364569664,
                0.0538366325199604,
                0.0034790022764354944,
                -0.03876575455069542,
                0.020994199439883232,
                -0.006230568513274193,
                -0.09316880255937576,
                0.005963481497019529,
                -0.07276466488838196,
                0.018652314320206642,
                0.02604268305003643,
                -0.005856230389326811,
                0.007727275136858225,
                0.02835489809513092,
                0.040787678211927414,
                0.004551402758806944,
                0.0026153079234063625,
                0.030937613919377327,
                -0.0649125725030899,
                -0.03900919854640961,
                -0.002087216591462493,
                -0.02004949375987053,
                0.016967125236988068,
                0.028980359435081482,
                0.036308176815509796,
                0.0395183190703392,
                -0.004876728635281324,
                -0.003898455062881112,
                -0.02250269614160061,
                -0.00996095035225153,
                0.01715664565563202,
                0.027226032689213753,
                0.028004087507724762,
                -0.041538432240486145,
                -0.054950226098299026,
                0.028036795556545258,
                -0.026075400412082672,
                -0.017231911420822144,
                -0.01975650154054165,
                0.0859835222363472,
                0.019424403086304665,
                -0.04627755284309387,
                0.003603796474635601,
                -0.07759416103363037,
                0.021738262847065926,
                -0.03069933131337166,
                0.014420142397284508,
                -0.06736738234758377,
                -0.015448542311787605,
                -0.04433949664235115,
                -0.007604188285768032,
                -0.05932220071554184,
                -0.018796587362885475,
                0.00972256064414978,
                0.059112709015607834,
                0.020007800310850143,
                -0.018449191004037857,
                -0.0414452999830246,
                -0.0033832197077572346,
                -0.012212232686579227,
                0.07148178666830063,
                -0.013887116685509682,
                0.028297513723373413,
                -0.0036226517986506224,
                -8.168783546300642e-33,
                0.012014498934149742,
                -0.023043576627969742,
                0.012358591891825199,
                -0.112213134765625,
                -0.056151892989873886,
                -0.05957750231027603,
                0.016242653131484985,
                0.04693807661533356,
                -0.03750638663768768,
                0.016820184886455536,
                -0.04207282140851021,
                -0.058666668832302094,
                0.03577281907200813,
                -0.023915672674775124,
                0.046321891248226166,
                0.04990289732813835,
                0.03314448148012161,
                0.007678115274757147,
                -0.06066743656992912,
                -0.032678522169589996,
                -0.03743111714720726,
                0.023834768682718277,
                0.010558226145803928,
                0.1172703206539154,
                -0.005097018089145422,
                -0.06655775010585785,
                -0.06861107051372528,
                0.03260956332087517,
                -0.008341120555996895,
                -0.012023412622511387,
                -0.024460434913635254,
                0.0572962649166584,
                -0.02714923769235611,
                0.0012791012413799763,
                -0.0033833577763289213,
                -0.003449520096182823,
                -0.03114176355302334,
                -0.00019454254652373493,
                -0.014003289863467216,
                0.0054458994418382645,
                -0.07856039702892303,
                0.0009882665472105145,
                0.008206121623516083,
                -0.015359724871814251,
                -0.04622231796383858,
                -0.050643354654312134,
                0.014300498180091381,
                -0.06240334361791611,
                -0.013016673736274242,
                0.05716202035546303,
                -0.018275141716003418,
                -0.008320966735482216,
                -0.02912350930273533,
                -0.029055044054985046,
                -0.0030894428491592407,
                0.06907961517572403,
                0.012929989956319332,
                -0.0055691637098789215,
                -0.028608227148652077,
                -0.014881383627653122,
                -0.0092929070815444,
                -0.042693786323070526,
                0.0008031605393625796,
                0.031664423644542694,
                -0.007625690195709467,
                0.05342749133706093,
                0.03344253823161125,
                0.009568682871758938,
                -0.03663549944758415,
                0.0829186886548996,
                0.00837773084640503,
                -0.023215370252728462,
                -0.015645841136574745,
                -0.013091666623950005,
                0.02654837630689144,
                -0.08770781010389328,
                0.01571623794734478,
                0.004142432473599911,
                0.006693826988339424,
                -0.00369171891361475,
                -0.03213624656200409,
                -0.013316896744072437,
                -0.014728033915162086,
                0.029529601335525513,
                0.027298718690872192,
                0.035055700689554214,
                0.023962439969182014,
                -0.03007083386182785,
                -0.05551249533891678,
                0.04418698698282242,
                -0.029689179733395576,
                0.11024388670921326,
                0.03482326865196228,
                0.0014493431663140655,
                -0.02626008540391922,
                -0.050054583698511124,
                -0.02114245295524597,
                -0.041022222489118576,
                -0.021565385162830353,
                -0.01518629863858223,
                0.013768197037279606,
                -0.020492063835263252,
                -0.024579854682087898,
                -0.005166142247617245,
                0.013666902668774128,
                -0.01235109381377697,
                0.002789013087749481,
                0.037557635456323624,
                -0.03567693755030632,
                0.013784421607851982,
                -0.013113826513290405,
                -0.01028613280504942,
                0.06807365268468857,
                0.052272308617830276,
                0.018728066235780716,
                -0.00870685838162899,
                -0.0005623032338917255,
                -0.02380509115755558,
                0.0057088821195065975,
                0.022500650957226753,
                0.049720391631126404,
                0.07422737032175064,
                -0.01491343230009079,
                -0.014456724748015404,
                -0.04037586972117424,
                0.026994680985808372,
                -0.025612888857722282,
                0.05868984013795853,
                -0.021465329453349113,
                -0.027751421555876732,
                -0.019175050780177116,
                -0.02677515149116516,
                2.775138625565887e-07,
                -0.007337660063058138,
                -0.01985614188015461,
                -0.011395877227187157,
                -0.020741591230034828,
                0.027704453095793724,
                0.05459413304924965,
                -0.015658041462302208,
                0.00881049782037735,
                -0.06126189976930618,
                -0.003598464885726571,
                -0.01139288954436779,
                -0.04229285195469856,
                0.0676676407456398,
                -0.04707927256822586,
                -0.026785369962453842,
                -0.14296288788318634,
                0.012090393342077732,
                -0.022225534543395042,
                -0.005125977098941803,
                0.013628799468278885,
                0.05002171918749809,
                0.00914941169321537,
                0.004375695250928402,
                -0.014060807414352894,
                0.018328653648495674,
                0.04008445888757706,
                0.00895480066537857,
                0.03526986762881279,
                -0.016916658729314804,
                0.023507095873355865,
                -0.03862736374139786,
                0.030095530673861504,
                -0.03791576996445656,
                0.0397791787981987,
                0.02161090262234211,
                -0.026953604072332382,
                0.07258309423923492,
                -0.0013906152453273535,
                -0.01848185807466507,
                0.000710371183231473,
                -0.013007819652557373,
                -0.05022804066538811,
                -0.018159223720431328,
                -0.06420507282018661,
                0.028482716530561447,
                -0.01824159547686577,
                -0.03634518384933472,
                0.06679399311542511,
                -0.08576320856809616,
                -0.02091558463871479,
                0.04725991189479828,
                -0.016189593821763992,
                0.0015492694219574332,
                0.028200509026646614,
                -0.006184919737279415,
                0.029653824865818024,
                0.006287270691245794,
                0.01889237016439438,
                0.03516224026679993,
                -0.0639004185795784,
                0.005539376754313707,
                -0.013111898675560951,
                0.02719174697995186,
                -0.011057604104280472,
                0.00019686635641846806,
                0.03554322198033333,
                -0.00885050743818283,
                1.763223962694791e-34,
                -0.002437218092381954,
                0.011273175477981567,
                0.011573455296456814,
                0.03422290459275246,
                -0.06829987466335297,
                0.009527343325316906,
                0.09578689932823181,
                0.0009472117526456714,
                0.00556708499789238,
                0.022045325487852097,
                -0.00937513168901205,
                -0.002862156368792057,
                -0.020761970430612564,
                0.0009174220613203943,
                -0.07376474887132645,
                0.010569067671895027,
                0.0228272657841444,
                0.015849431976675987,
                0.039719026535749435,
                0.06916920840740204,
                -0.02718762308359146,
                0.014803020283579826,
                -0.034990210086107254,
                -0.030162643641233444,
                0.05458289012312889,
                -0.0204936470836401,
                -0.026168763637542725,
                0.004947296809405088,
                0.03221068158745766,
                -0.087668776512146,
                -0.04191554710268974,
                -0.010589060373604298,
                -0.02094106376171112,
                0.011429633013904095,
                -0.003983964212238789,
                0.01670810580253601,
                -0.012540213763713837,
                -0.0003899727889802307,
                0.04197026416659355,
                0.053169362246990204,
                0.010750848799943924,
                0.012256651185452938,
                0.028654122725129128,
                0.06284493952989578,
                -0.010244112461805344,
                1.8606164076118148e-06,
                -0.012482360005378723,
                -0.014897323213517666,
                -0.030815308913588524,
                -0.04865478724241257,
                0.022625163197517395,
                0.0019680210389196873,
                -0.06298256665468216,
                -0.029915446415543556,
                -0.02828524075448513,
                0.0339386947453022,
                0.038659241050481796,
                0.01774258352816105,
                -0.04608957841992378,
                0.03830927982926369,
                -0.013087661005556583,
                0.004765065386891365,
                0.047922391444444656,
                -0.06088569760322571,
                -0.0372888445854187,
                -0.011219138279557228,
                -0.02716895006597042,
                0.03191271424293518,
                -0.03329770267009735,
                -0.004522096365690231,
                -0.010459369979798794,
                -0.01783614046871662,
                0.02756468392908573,
                0.02852083556354046,
                0.024760285392403603,
                0.06507216393947601,
                -0.008445137180387974,
                0.04682345688343048,
                0.03890608623623848,
                -0.007661605719476938,
                0.004587727598845959,
                0.026826580986380577,
                0.03455856442451477,
                0.015937035903334618,
                0.0015348495217040181,
                -0.07298003137111664,
                0.022802576422691345,
                0.04375497251749039,
                -0.041022889316082,
                0.04159143939614296,
                -0.031884633004665375,
                0.04452209174633026,
                0.021744834259152412,
                -0.03341308608651161,
                -0.012278171256184578,
                0.08016780763864517,
                -0.04677800089120865,
                0.022481728345155716,
                -0.03673363849520683,
                -0.0016988207353278995,
                0.055669743567705154,
                0.058002032339572906,
                0.012732379138469696,
                0.004837563261389732,
                0.036339979618787766,
                -0.024392317980527878,
                0.000376884505385533,
                0.05284295603632927,
                -0.025679055601358414,
                0.03928826004266739,
                0.001451750285923481,
                -0.06518987566232681,
                0.02812490426003933,
                0.03119613602757454,
                0.0005904398858547211,
                0.04361327737569809,
                -0.03226112574338913,
                -0.04203975573182106,
                -0.06147493049502373,
                0.033281125128269196,
                0.04323560371994972,
                0.01193285919725895,
                0.008367329835891724,
                0.0026444601826369762,
                -0.02699594758450985,
                -0.019998379051685333,
                -0.044002920389175415,
                -0.06920256465673447,
                -0.04293260723352432,
                0.01571502722799778,
                0.05160157009959221,
                0.06962082535028458,
                0.016458775848150253,
                -0.05719926953315735,
                0.0006300800014287233,
                0.009790907613933086,
                0.03824346885085106,
                -0.0738101378083229,
                -0.019050221890211105,
                0.011153366416692734,
                0.0434226393699646,
                -0.004401166923344135,
                -0.03036555089056492,
                0.02629638835787773,
                -0.003674715058878064,
                -0.018549740314483643,
                0.013641790486872196,
                0.005914948880672455,
                0.034175675362348557,
                -0.0795292779803276,
                0.0006100620375946164,
                0.014665750786662102,
                -0.01749693974852562,
                -0.01577788218855858,
                -0.010770752094686031,
                0.004800622351467609,
                -0.06383063644170761,
                0.0010311549995094538,
                0.014042835682630539,
                -0.04106004908680916,
                -0.037559714168310165,
                -0.01666240021586418,
                -0.062128812074661255,
                -0.03201602026820183,
                0.002985261380672455,
                0.0948532447218895,
                0.027668263763189316,
                -0.0055332244373857975,
                -0.007578164804726839,
                -0.03811377286911011,
                -0.009496361948549747,
                0.021160047501325607,
                -0.06416864693164825,
                0.02706282027065754,
                0.06766480952501297,
                -0.01752363331615925,
                0.03815136104822159,
                0.009069995954632759,
                -0.012493627145886421,
                -0.04629684239625931,
                -0.01964530162513256,
                -0.0803331583738327,
                0.049546562135219574,
                -0.0171493086963892,
                0.02524668537080288,
                -0.03618199750781059,
                0.04752027615904808,
                -0.020958267152309418,
                -0.05411038547754288,
                -0.0011013898765668273,
                0.011274779215455055,
                -0.02234068140387535,
                -0.025723103433847427,
                0.008646510541439056,
                0.006811053026467562,
                0.0025200294330716133,
                0.02445262111723423,
                -0.014981858432292938,
                -0.03743349388241768,
                -0.0019362936727702618,
                0.017474757507443428,
                -0.020471977069973946,
                -0.002281814580783248,
                -0.11108339577913284,
                0.016024483367800713,
                0.03766964003443718,
                -0.02239348739385605,
                0.015481851994991302,
                0.016810208559036255,
                0.08216152340173721,
                0.005831728223711252,
                0.020176956430077553,
                0.01566777192056179,
                -0.005931982770562172,
                0.14917239546775818,
                -0.03294498100876808,
                0.0526302307844162,
                -0.005754721350967884,
                -0.038687922060489655,
                -0.09379012882709503,
                -0.001193844829685986,
                -0.017698321491479874,
                -0.004564464557915926,
                0.02213682234287262,
                -0.012020009569823742,
                -0.025036700069904327,
                0.05270006135106087,
                0.024085894227027893,
                -0.024378059431910515,
                -0.05709503963589668,
                0.004926437046378851,
                -0.014928906224668026,
                0.09282102435827255,
                -0.00716946879401803,
                -0.014056909829378128,
                -0.02150680683553219,
                0.03993098810315132,
                -0.006271323189139366,
                0.040091194212436676,
                -0.020460233092308044,
                -0.0003649209684226662,
                -0.004366323817521334,
                -0.015316838398575783,
                -0.04357260465621948,
                0.014937471598386765,
                -0.021111642941832542,
                -0.02752584032714367,
                -0.003381033195182681,
                0.011319654993712902,
                -0.025133740156888962,
                0.034804895520210266,
                -0.021420156583189964,
                -0.004185191355645657,
                0.0531943179666996,
                -0.029199544340372086,
                -0.020339762791991234,
                -0.01842813566327095,
                -0.030841317027807236,
                0.018084663897752762,
                0.04727286100387573,
                -0.013719893991947174,
                -0.05153612419962883,
                -0.07943693548440933,
                0.030731450766324997,
                0.02650141902267933,
                -0.05155741423368454,
                -0.04689985141158104,
                -0.036645982414484024,
                0.013768474571406841,
                -0.06479760259389877,
                -0.004960043355822563,
                -0.011285818181931973,
                0.029604177922010422,
                -0.027491895481944084,
                -0.0015684249810874462,
                -0.011258835904300213,
                -0.0317874401807785,
                0.05925058200955391,
                -0.020454563200473785,
                -0.023867445066571236,
                -0.019512105733156204,
                -0.004027859773486853,
                0.055352695286273956,
                0.04203246533870697,
                -0.0171316247433424,
                -0.05401618033647537,
                0.024438908323645592,
                0.037020109593868256,
                0.02834811434149742,
                -0.011691056191921234,
                0.003771999618038535,
                -0.01792825199663639,
                0.04942258819937706,
                -0.004547533113509417,
                -0.03178861737251282,
                -0.017527872696518898,
                0.05163125321269035,
                -0.0029828243423253298,
                0.0024121731985360384,
                -0.006233408581465483,
                0.06771950423717499,
                0.022016556933522224,
                -0.021226173266768456,
                0.047939322888851166,
                -0.01914959028363228,
                -0.03265717253088951,
                -0.034083738923072815,
                0.02332538552582264,
                0.027297379449009895,
                0.012731033377349377,
                0.01228848285973072,
                0.013193616643548012,
                -0.029160186648368835,
                -0.05626334995031357,
                -0.02231646329164505,
                -0.015648504719138145,
                0.0251485425978899,
                0.011153553612530231,
                0.005264543928205967,
                -0.04639264941215515,
                0.020806964486837387,
                0.005437701009213924,
                0.03868061676621437,
                -0.007016373332589865,
                0.010622306726872921,
                0.016892509534955025,
                0.03313487023115158,
                -0.01560302171856165,
                -0.013991625979542732,
                0.016208142042160034,
                0.016968363896012306,
                0.03778905048966408,
                0.05755874887108803,
                0.014804183505475521,
                0.038443610072135925,
                0.02247781865298748,
                -0.028830310329794884,
                -0.018262552097439766,
                -0.03078737109899521,
                -0.08708082139492035,
                -0.07897500693798065,
                0.0013791413512080908,
                -0.010852460749447346,
                -0.039194345474243164,
                0.022669048979878426,
                -0.04026200622320175,
                -0.053220994770526886,
                0.041817888617515564,
                -0.05997396260499954,
                0.02324892207980156,
                -0.018015464767813683,
                -0.007249492220580578,
                -0.00868663378059864,
                -0.007392485160380602,
                0.03499847277998924,
                -0.025231609120965004,
                -0.028903672471642494,
                0.009459992870688438,
                0.07580818980932236,
                0.007356871850788593,
                -0.06148305535316467,
                -0.008715650998055935,
                0.07052524387836456,
                0.011880233883857727,
                -0.01344296894967556,
                0.010109071619808674,
                -0.002122540958225727,
                0.05069872736930847,
                0.0027782239485532045,
                -0.018795503303408623,
                -0.019764885306358337,
                0.03364051133394241,
                0.007273517083376646,
                0.04561391845345497,
                -0.02472754381597042,
                0.0002828642900567502,
                0.02472320757806301,
                0.04944419488310814,
                -0.05360334366559982,
                0.007889322936534882,
                0.05493852496147156,
                -0.0068115359172225,
                -0.015008818358182907,
                -0.009868382476270199,
                0.07020951807498932,
                -0.01600438728928566,
                -0.008657973259687424,
                0.00567212700843811,
                -0.046215835958719254,
                0.014336485415697098,
                -0.015512702986598015,
                -0.00778009882196784,
                -0.033839888870716095,
                0.04238204285502434,
                -0.03796670213341713,
                0.008420092985033989,
                -0.023604921996593475,
                -0.030692312866449356,
                0.04362551122903824,
                0.03562484309077263,
                0.019764550030231476,
                0.012124302797019482,
                -0.04538660869002342,
                0.029703889042139053,
                0.07829058915376663,
                0.009905927814543247,
                -0.015085779130458832,
                0.015605258755385876,
                -0.037316687405109406,
                0.023669444024562836,
                0.017005523666739464,
                0.015918582677841187,
                -0.011176444590091705,
                -0.011902286671102047,
                0.015191078186035156,
                -0.02692057378590107,
                -0.04192719981074333,
                0.03742058575153351,
                -0.07118415087461472,
                0.017775293439626694,
                -0.024995582178235054,
                -0.017646733671426773,
                0.011494279839098454,
                6.806788587709889e-05,
                -0.02279665693640709,
                0.009830739349126816,
                -0.07919327914714813,
                -0.03769249841570854,
                0.008644472807645798,
                0.04443662241101265,
                -0.058256249874830246,
                0.05909616872668266,
                0.06731324642896652,
                0.018052855506539345,
                -0.006681282073259354,
                0.017561987042427063,
                -0.009739712812006474,
                0.050531841814517975,
                0.008920781314373016,
                -0.005364249460399151,
                0.010494688525795937,
                -0.021324845030903816,
                0.0336250364780426,
                0.010440445505082607,
                0.0357850082218647,
                0.04359487071633339,
                0.03275475278496742,
                0.03998659551143646,
                0.01183256320655346,
                0.040675606578588486,
                -0.027689991518855095,
                -0.026965972036123276,
                0.024540221318602562,
                0.026775237172842026,
                0.012483995407819748,
                0.0010904015507549047,
                0.04122541844844818,
                0.024390527978539467,
                0.0342986099421978,
                -0.026040853932499886,
                0.00799938291311264,
                -0.015696292743086815,
                -0.014471599832177162,
                0.0068823229521512985,
                0.01064958143979311,
                0.04952292516827583,
                0.012152023613452911,
                -0.1114426925778389,
                0.07099669426679611,
                0.02124827913939953,
                0.002344983397051692,
                0.022125111892819405,
                0.01650911010801792,
                0.02109764702618122,
                -0.004404433071613312,
                -0.008684205822646618,
                -0.04791789501905441,
                -0.0291034784168005,
                -0.04655987769365311,
                -0.0524108000099659,
                -0.03911737725138664,
                -0.00450606644153595,
                -0.028471339493989944,
                0.00202939216978848,
                0.05113689973950386,
                0.05075695738196373,
                0.03686767444014549,
                0.02699788473546505,
                0.012074985541403294,
                0.016191260889172554,
                0.00285199168138206,
                0.020100438967347145,
                0.003912356216460466,
                -0.044077128171920776,
                -0.010025113821029663,
                -0.01018609944730997,
                0.02186092548072338,
                -0.033186107873916626,
                -0.03733989596366882,
                -0.004791414365172386,
                0.04091533273458481,
                -0.0006979960598982871,
                0.027020297944545746,
                -0.0010906782699748874,
                0.002529874909669161,
                -0.01326414942741394,
                0.003963793162256479,
                -0.05882364511489868,
                0.038272425532341,
                -0.05342137813568115,
                -0.02870623953640461,
                0.026770390570163727,
                0.032239992171525955,
                0.025278570130467415,
                -0.0029177444521337748,
                0.012893743813037872,
                -0.018519530072808266,
                -0.02969195507466793,
                0.07420528680086136,
                -0.011613584123551846,
                -0.036071717739105225,
                0.053900524973869324,
                -0.005021323915570974,
                0.00920614693313837,
                -0.001693628029897809,
                -0.006140983663499355,
                -0.05116157606244087,
                -0.02851913310587406,
                0.03427661582827568,
                -0.02041054144501686,
                0.002377350814640522,
                -0.014966298826038837,
                -0.024087104946374893,
                -0.003033896442502737,
                -0.01912337727844715,
                0.03639651834964752,
                -0.005992206744849682,
                0.0048664407804608345,
                -0.01863158866763115,
                -0.0011586759937927127,
                -0.004116016440093517,
                0.03948291391134262,
                -0.03184463456273079,
                -0.007448059972375631,
                0.0014025080017745495,
                -0.016554497182369232,
                -0.025425026193261147,
                0.06500077992677689,
                0.0029743053019046783,
                -0.012655186466872692,
                -0.036133624613285065,
                -0.026138823479413986,
                -0.006125976797193289,
                -0.022522205486893654,
                -0.0008877459331415594,
                0.0024493082892149687,
                0.04396555572748184,
                0.011200007051229477,
                -0.03047294169664383,
                -0.02956664003431797,
                -0.024521341547369957,
                -0.004722892306745052,
                0.0049855369143188,
                0.024485638365149498,
                0.03327620029449463,
                0.018369942903518677,
                -6.61181336064569e-33,
                0.015692180022597313,
                -0.030328266322612762,
                0.01636047661304474,
                -0.08959123492240906,
                -5.70128504477907e-05,
                0.020098133012652397,
                -0.043452173471450806,
                0.02063903957605362,
                0.03073965199291706,
                -0.02375701628625393,
                0.034622933715581894,
                0.002806577365845442,
                0.02000359818339348,
                0.05532433092594147,
                0.040593501180410385,
                -0.0026111681945621967,
                0.014881114475429058,
                0.014330558478832245,
                -0.07038267701864243,
                -0.008833596482872963,
                -0.03570706769824028,
                0.04245651885867119,
                0.04416236653923988,
                0.06421729922294617,
                -0.06151901185512543,
                0.032432522624731064,
                0.0475827157497406,
                0.011316689662635326,
                -0.022695794701576233,
                -0.007993952371180058,
                -0.005918456241488457,
                0.01448714267462492,
                -0.004414795897901058,
                -0.009661243297159672,
                0.05985144525766373,
                0.07224094122648239,
                -0.04062040522694588,
                -0.017578916624188423,
                -0.07962185144424438,
                -0.013323928229510784,
                -0.030266061425209045,
                -0.05295100808143616,
                0.05860339105129242,
                -0.027138646692037582,
                -0.08074343204498291,
                -0.05222135782241821,
                -0.024745287373661995,
                0.020857516676187515,
                -0.00264904648065567,
                -0.061348021030426025,
                -0.04765251651406288,
                0.014430239796638489,
                -0.025880012661218643,
                0.08200440555810928,
                -0.03405889496207237,
                -0.020680371671915054,
                0.034902527928352356,
                0.0022160219959914684,
                -0.003174351993948221,
                -0.04411735758185387,
                -0.023044085130095482,
                -0.07149066776037216,
                0.021402107551693916,
                -0.05051029846072197,
                -0.04963516443967819,
                0.006795484572649002,
                -0.09942986816167831,
                0.050072524696588516,
                -0.004857741761952639,
                0.025003066286444664,
                0.030414411798119545,
                -0.033091772347688675,
                -0.0576978474855423,
                -0.0935247614979744,
                -0.0622028112411499,
                0.01179970521479845,
                -0.017392000183463097,
                0.030616531148552895,
                -0.04278505593538284,
                0.028424644842743874,
                -0.06989485770463943,
                0.04016351327300072,
                -0.03860178962349892,
                -0.005752632860094309,
                -0.009912372566759586,
                0.057264480739831924,
                -0.0068260738626122475,
                -0.032927289605140686,
                -0.01477950531989336,
                -0.0286085344851017,
                -0.03276587277650833,
                0.12956707179546356,
                0.0011629370274022222,
                0.02026955410838127,
                0.08457893878221512,
                -0.0033199861645698547,
                0.04728126525878906,
                -0.012868124060332775,
                0.02933596819639206,
                0.010295496322214603,
                -0.022735491394996643,
                0.05815860256552696,
                0.026685914024710655,
                0.028703894466161728,
                -0.0773337110877037,
                -0.03328836336731911,
                -0.05044921487569809,
                -0.03529796376824379,
                -0.00017805844254326075,
                0.061671603471040726,
                0.06591883301734924,
                -0.022674595937132835,
                -0.003588323947042227,
                -0.03793071582913399,
                0.023947516456246376,
                -0.040813710540533066,
                0.04572506248950958,
                0.043361082673072815,
                0.004006774630397558,
                -0.036327194422483444,
                0.0012929377844557166,
                0.07936730980873108,
                0.059999071061611176,
                0.0028884855564683676,
                0.009371277876198292,
                0.014841035939753056,
                0.028908127918839455,
                -0.028231166303157806,
                0.038875557482242584,
                -0.011517895385622978,
                0.03025921620428562,
                0.0009734492050483823,
                2.3797920789547788e-07,
                -0.018922705203294754,
                0.004847569856792688,
                0.026047447696328163,
                0.006473097484558821,
                0.04970267042517662,
                0.005820282734930515,
                0.0055123199708759785,
                0.011488542892038822,
                -0.033601049333810806,
                0.034866612404584885,
                0.03209083527326584,
                -0.044328220188617706,
                0.011478216387331486,
                -0.055147260427474976,
                0.06410160660743713,
                -0.1507696807384491,
                -0.006938221864402294,
                -0.05755413696169853,
                -0.028485679998993874,
                0.04606596753001213,
                0.009785182774066925,
                -0.006264832336455584,
                -0.005946272984147072,
                -0.044017501175403595,
                0.014852313324809074,
                0.0873572900891304,
                0.01561153307557106,
                0.005177052225917578,
                -0.0030319455545395613,
                0.06033570319414139,
                -0.07948359102010727,
                0.053360819816589355,
                0.045990943908691406,
                0.03950141370296478,
                -0.023131996393203735,
                -0.03634490445256233,
                0.0332287922501564,
                0.03905417025089264,
                -0.010086462832987309,
                -0.03695891052484512,
                -0.00103850313462317,
                -0.004887198098003864,
                0.0026030573062598705,
                0.02181262895464897,
                -0.006141690071672201,
                -0.060472678393125534,
                -0.05918968841433525,
                0.05010790377855301,
                -0.04303980618715286,
                0.006867604795843363,
                -0.014195249415934086,
                -0.005262077786028385,
                0.016395490616559982,
                0.00999948289245367,
                0.021753914654254913,
                0.003877623938024044,
                0.011163883842527866,
                0.017085399478673935,
                0.07336019724607468,
                0.05111170932650566,
                0.010704592801630497,
                -0.037708885967731476,
                0.029583808034658432,
                -0.024677036330103874,
                -0.00861677248030901,
                -0.018552767112851143,
                -0.03929612413048744,
                1.8515550163193933e-34,
                -0.01077597588300705,
                -0.004207013640552759,
                -0.021288050338625908,
                0.014215472154319286,
                0.01628841646015644,
                -0.011315892450511456,
                0.005775380879640579,
                -0.04739992320537567,
                0.03909355029463768,
                0.023513957858085632,
                -0.035745810717344284,
                -0.034712959080934525,
                -0.012518106028437614,
                0.003025706624612212,
                -0.05509317293763161,
                -0.0207512266933918,
                0.028935329988598824,
                0.002320219064131379,
                0.055523503571748734,
                0.05874557048082352,
                -0.0103965038433671,
                0.005496269557625055,
                -0.03407509997487068,
                -0.009244131855666637,
                0.06710947304964066,
                0.016143864020705223,
                0.026221388950943947,
                -0.01546923816204071,
                0.05932358279824257,
                -0.08308204263448715,
                -0.027743522077798843,
                -0.05821385607123375,
                0.014930486679077148,
                -0.019715027883648872,
                0.02473405934870243,
                0.04684394225478172,
                0.005248661153018475,
                -0.001961251487955451,
                0.029808031395077705,
                0.012534858658909798,
                0.00817516352981329,
                0.03755651041865349,
                0.025234533473849297,
                0.00913937110453844,
                0.026154235005378723,
                1.932345185196027e-06,
                -0.06260401010513306,
                0.016679994761943817,
                0.0019868453964591026,
                -0.0397128164768219,
                0.04002953693270683,
                -0.05199446529150009,
                -0.036600250750780106,
                -0.052302248775959015,
                -0.02749597653746605,
                0.026702245697379112,
                -0.002315233461558819,
                0.025019841268658638,
                -0.054276153445243835,
                -0.025213148444890976,
                0.026720451191067696,
                0.01499185711145401,
                0.023013167083263397,
                -0.0881962701678276,
                -0.028615133836865425,
                0.07879438996315002,
                -0.04619875177741051,
                0.019756801426410675,
                -0.002968287793919444,
                0.01518650259822607,
                0.04755667597055435,
                0.022779401391744614,
                0.007885467261075974,
                0.009903231635689735,
                0.00787570420652628,
                0.018312133848667145,
                0.008595136925578117,
                0.0032949717715382576,
                0.055260296911001205,
                0.02578081376850605,
                0.05208148434758186,
                0.05693143978714943,
                -0.013224015012383461,
                0.030317625030875206,
                0.0790233239531517,
                -0.06377635151147842,
                0.007776389829814434,
                0.012063108384609222,
                -0.05455552041530609,
                0.028122665360569954,
                -0.03947438299655914,
                -0.0006904978072270751,
                0.04198035970330238,
                -0.022783426567912102,
                0.012717322446405888,
                0.0498988963663578,
                -0.041835516691207886,
                0.03334209322929382,
                -0.002079613506793976,
                -0.027220437303185463,
                0.009640038944780827,
                0.05227030813694,
                0.004268423188477755,
                0.007770341355353594,
                0.03032478131353855,
                -0.01597335934638977,
                -0.01570947840809822,
                0.04444659873843193,
                -0.012023280374705791,
                0.027003439143300056,
                -0.040528543293476105,
                -0.06673844158649445,
                0.05494924262166023,
                0.04219672828912735,
                0.04570813849568367,
                -0.00523440632969141,
                -0.007595621980726719,
                0.01496883574873209,
                -0.046651702374219894,
                0.01772172935307026,
                0.048536814749240875,
                0.00622967816889286,
                -0.0015744679840281606,
                -0.007564799394458532,
                0.015226992778480053,
                -0.02628692425787449,
                -0.05114981532096863,
                -0.07223997265100479,
                -0.021222149953246117,
                0.042529892176389694,
                0.05149559676647186,
                0.06679490208625793,
                0.016506513580679893,
                -0.03731486573815346,
                0.02774207480251789,
                0.01875469833612442,
                0.01924203522503376,
                -0.05917549133300781,
                -0.022157814353704453,
                0.03565487638115883,
                0.02848813310265541,
                -0.03244897350668907,
                0.006666242610663176,
                0.002188440877944231,
                -0.01275712251663208,
                0.015429405495524406,
                0.050954822450876236,
                -0.009208033792674541,
                0.00792627688497305,
                0.021282846108078957,
                -0.005256825126707554,
                0.008272001519799232,
                -0.015798762440681458,
                0.0007118507637642324,
                0.012152626179158688,
                0.000447933649411425,
                -0.0354110449552536,
                -0.008655264973640442,
                -0.006499262992292643,
                -0.029679810628294945,
                -0.02964242547750473,
                0.011291048489511013,
                -0.030375715345144272,
                -0.059906333684921265,
                -0.0031106295064091682,
                0.11929985880851746,
                0.007476648781448603,
                -0.03751417621970177,
                0.017618324607610703,
                -0.05394875630736351,
                -0.0339290015399456,
                0.038582030683755875,
                -0.062482237815856934,
                -0.0058527360670268536,
                0.04877050220966339,
                -0.044210467487573624,
                0.030613072216510773,
                -0.04781319946050644,
                -0.015814660117030144,
                -0.08769103139638901,
                -0.028280720114707947,
                -0.07331757992506027,
                0.03563642501831055,
                -0.0057480414398014545,
                0.009432999417185783,
                -0.012173119932413101,
                0.08441168814897537,
                -0.034762319177389145,
                -0.052291128784418106,
                -0.04067077487707138,
                0.02777457796037197,
                -0.014772211201488972,
                -0.14145991206169128,
                -0.01593201421201229,
                0.020043546333909035,
                0.006054593715816736,
                0.035262227058410645,
                0.0039804899133741856,
                -0.03130651265382767,
                -0.004723205231130123,
                -0.026965370401740074,
                -0.008124575950205326,
                0.005139700137078762,
                -0.10202982276678085,
                0.02861817553639412,
                0.053429391235113144,
                -0.0004269563651178032,
                0.005899201612919569,
                0.0005034430068917572,
                0.03011942468583584,
                -0.012420575134456158,
                -0.014109342359006405,
                -0.0038502193056046963,
                0.01639723964035511,
                0.08647437393665314,
                -0.037357110530138016,
                0.05695290490984917,
                0.02477394789457321,
                -0.02188367396593094,
                -0.11616303026676178,
                -0.002696459414437413,
                -0.013809860683977604,
                0.025795934721827507,
                0.01584821566939354,
                -0.007168598007410765,
                -0.011368067003786564,
                0.02358180657029152,
                0.05957358330488205,
                -0.037494633346796036,
                -0.055037155747413635,
                0.03350815922021866,
                -0.0003881993470713496,
                0.053865205496549606,
                0.004188417922705412,
                -0.02513481117784977,
                -0.007354538422077894,
                1.9584469555411488e-05,
                -0.0012676184996962547,
                0.018521521240472794,
                -0.026863686740398407,
                9.594273433322087e-05,
                -0.01631280593574047,
                0.02935139276087284,
                -0.010003630071878433,
                0.023885371163487434,
                -0.014398758299648762,
                -0.01788434572517872,
                0.050023697316646576,
                -0.04029694199562073,
                -0.009548035450279713,
                -0.03273877501487732,
                -0.0004950004513375461,
                0.0020856489427387714,
                0.022589925676584244,
                0.01901806704699993,
                0.03636976331472397,
                -0.025390230119228363,
                -0.025507234036922455,
                -0.023597635328769684,
                0.020788058638572693,
                -0.02083102986216545,
                -0.06335040926933289,
                -0.0457618273794651,
                0.046335335820913315,
                0.026326065883040428,
                -0.014689545147120953,
                -0.031616948544979095,
                -0.0043497090227901936,
                0.0010519528295844793,
                -0.04108655825257301,
                0.0018834276124835014,
                0.00876062735915184,
                -0.005740279797464609,
                -0.014564456418156624,
                -0.036791734397411346,
                0.010614925064146519,
                -0.019554708153009415,
                0.04605502262711525,
                -0.012825192883610725,
                -0.01926495134830475,
                -0.020617136731743813,
                -0.0006078435690142214,
                0.0736958235502243,
                0.026201821863651276,
                -0.018155056983232498,
                -0.047096140682697296,
                0.0005463649285957217,
                -0.05298997089266777,
                0.008913729339838028,
                -0.027081290259957314,
                0.002882512053474784,
                -0.010299237444996834,
                0.009830506518483162,
                -0.011464247480034828,
                -0.028152935206890106,
                0.00018257091869600117,
                0.06983253359794617,
                -0.013163643889129162,
                -0.007471010088920593,
                -0.01384833361953497,
                0.02096167393028736,
                -0.02551606483757496,
                -0.019371142610907555,
                0.056379448622465134,
                -0.04793114587664604,
                -0.00358590972609818,
                -0.01399151049554348,
                -0.041029468178749084,
                0.05424600467085838,
                0.06087550148367882,
                -0.023387424647808075,
                -0.02313200570642948,
                0.012406415306031704,
                -0.02845267951488495,
                -0.02017427608370781,
                -0.03191526606678963,
                0.0257563516497612,
                0.05330655723810196,
                -0.012761726975440979,
                -0.017505642026662827,
                0.030187860131263733,
                -0.0019797934219241142,
                0.013927333056926727,
                -0.03204870596528053,
                0.019841492176055908,
                0.03786394000053406,
                0.016770247370004654,
                -0.03808097168803215,
                -0.009246814996004105,
                -0.017073173075914383,
                0.031583018600940704,
                0.03909873589873314,
                0.019631633535027504,
                0.007553094532340765,
                0.004143883008509874,
                0.013734317384660244,
                0.013634932227432728,
                0.0012754446361213923,
                -0.012421861290931702,
                -0.07049942761659622,
                -0.1123272180557251,
                0.027212847024202347,
                -0.055509354919195175,
                -0.033722516149282455,
                0.035219956189394,
                -0.05070439726114273,
                -0.07472654432058334,
                -0.01235368475317955,
                -0.08854241669178009,
                0.029541432857513428,
                -0.012146400287747383,
                -0.009725824929773808,
                -0.010643106885254383,
                -0.0034160325303673744,
                0.08088546246290207,
                -0.04215224087238312,
                -0.02257942222058773,
                0.01820472441613674,
                0.035482458770275116,
                0.03044935129582882,
                -0.00016349906218238175,
                -0.05221189185976982,
                0.07171011716127396,
                -0.024886131286621094,
                -0.003129150252789259,
                -0.013123531825840473,
                -0.0012831420172005892,
                0.054991692304611206,
                -0.016249598935246468,
                0.0049446746706962585,
                -0.00310128484852612,
                0.05297938361763954,
                0.00036938540870323777,
                0.05402207002043724,
                0.005220610182732344,
                0.05518004298210144,
                0.04867446795105934,
                0.04920734465122223,
                -0.0116410618647933,
                -0.015461055561900139,
                0.029576566070318222,
                0.011313402093946934,
                0.014743399806320667,
                -0.03462671488523483,
                0.0890820249915123,
                -0.015169892460107803,
                0.015870235860347748,
                -0.0050649382174015045,
                -0.00313229626044631,
                0.015036365948617458,
                0.00038417053292505443,
                0.019329510629177094,
                -0.07335447520017624,
                0.01770772598683834,
                0.0019082141807302833,
                0.0034598740749061108,
                0.005041923839598894,
                -0.019298791885375977,
                0.020355772227048874,
                0.0007634763023816049,
                0.021245356649160385,
                0.05764530226588249,
                -0.05515909567475319,
                0.002751176944002509,
                0.07534338533878326,
                0.0011744714574888349,
                -0.00024080113507807255,
                0.034691277891397476,
                0.002030614297837019,
                0.0010186770232394338,
                0.025443339720368385,
                0.03549717366695404,
                0.03519986942410469,
                0.032492462545633316,
                0.028087731450796127,
                -0.04490538313984871,
                -0.036275818943977356,
                0.011773195117712021,
                -0.024221161380410194,
                0.018568798899650574,
                -0.060638681054115295,
                -0.028040416538715363,
                -0.025283021852374077,
                -0.03099277801811695,
                0.019718825817108154,
                -0.04671105742454529,
                -0.10242228955030441,
                -0.04390260577201843,
                -0.04293001815676689,
                0.08606628328561783,
                -0.04668952524662018,
                0.08625295758247375,
                0.0374235175549984,
                -0.01745447888970375,
                -0.01779496669769287,
                0.01911129802465439,
                0.02017165906727314,
                0.09012886881828308,
                -0.013794822618365288,
                0.049846895039081573,
                0.0073080952279269695,
                -0.010268422774970531,
                -0.031875453889369965,
                -0.021010592579841614,
                0.0421745628118515,
                -0.008095578290522099,
                -0.04603774473071098,
                0.07619471102952957,
                0.03950759023427963,
                -0.021360693499445915,
                -0.009315719828009605,
                -0.009012393653392792,
                0.0140529228374362,
                -0.0061956592835485935,
                -0.021331392228603363,
                0.006194091867655516,
                0.0195551086217165,
                -0.012209828943014145,
                0.041733551770448685,
                -0.023782286792993546,
                0.022740989923477173,
                -0.003847843501716852,
                -0.06119509041309357,
                0.02718627080321312,
                0.04080723226070404,
                0.02740466222167015,
                -0.034203704446554184,
                -0.04591717943549156,
                0.054967671632766724,
                0.010216089896857738,
                -0.0013129963772371411,
                0.009048162959516048,
                0.028439179062843323,
                -0.03069903887808323,
                -0.01411073375493288,
                0.04857144504785538,
                -0.09570948034524918,
                0.015591682866215706,
                -0.045110419392585754,
                -0.03261015936732292,
                -0.053842052817344666,
                -0.03708914294838905,
                -0.039639249444007874,
                0.004035275429487228,
                0.03348027169704437,
                0.04576354846358299,
                -0.02178230695426464,
                0.0333065427839756,
                -0.009630285203456879,
                -0.0028434915002435446,
                0.0022931420244276524,
                0.0031770106870681047,
                0.019033411517739296,
                -0.0106338020414114,
                -0.05862028896808624,
                0.013495050370693207,
                0.026739969849586487,
                -0.004575065337121487,
                -0.010248132981359959,
                -0.006758611649274826,
                -0.008184506557881832,
                -0.008519364520907402,
                -0.008996555581688881,
                0.0015437370166182518,
                0.030973749235272408,
                -0.007843749597668648,
                0.06981957703828812,
                -0.03441111370921135,
                -0.033659834414720535,
                -0.07674992829561234,
                -0.017497071996331215,
                0.04157010465860367,
                -0.008703291416168213,
                0.006898019928485155,
                0.05005260184407234,
                0.00817912258207798,
                0.01810855232179165,
                -0.02658873051404953,
                0.03145565465092659,
                0.005309224594384432,
                -0.03559630364179611,
                0.08102636784315109,
                0.008977259509265423,
                0.005137851927429438,
                -0.005908248946070671,
                0.00847829133272171,
                -0.016560807824134827,
                0.024153247475624084,
                0.03372124210000038,
                -0.02048075571656227,
                0.06757304072380066,
                -0.012070640921592712,
                -0.0229913592338562,
                0.016187269240617752,
                0.010058569721877575,
                0.017368482425808907,
                -0.015221227891743183,
                0.010179365053772926,
                -0.04185616597533226,
                0.03868439793586731,
                -0.008186884224414825,
                0.05670543015003204,
                -0.006834063213318586,
                0.03559635579586029,
                0.004876561928540468,
                -0.0036928271874785423,
                0.003013922832906246,
                0.06238333880901337,
                -0.010009514167904854,
                -0.030584918335080147,
                -0.03518438711762428,
                -0.04982517659664154,
                -0.024838130921125412,
                -0.006279815919697285,
                0.008440674282610416,
                -0.051030777394771576,
                0.05014464631676674,
                0.023194074630737305,
                0.0038249059580266476,
                -0.02600090578198433,
                -0.06906755268573761,
                0.02785729430615902,
                0.035863664001226425,
                -0.043351173400878906,
                0.020246542990207672,
                0.03908344730734825,
                -6.953082880576417e-33,
                0.0238180048763752,
                -0.04794955253601074,
                -0.009169264696538448,
                -0.08520986139774323,
                -0.01247023232281208,
                0.0050051272846758366,
                -0.0018046194454655051,
                0.01752682961523533,
                0.016306506469845772,
                -0.020523667335510254,
                0.017571942880749702,
                -0.028468310832977295,
                0.03355950862169266,
                0.046170324087142944,
                0.012390831485390663,
                -0.022082610055804253,
                0.029786288738250732,
                -0.012308364734053612,
                -0.040111616253852844,
                -0.017808590084314346,
                -0.009090523235499859,
                0.02491149678826332,
                0.030563941225409508,
                0.11446750164031982,
                -0.04850064218044281,
                -0.011650067754089832,
                0.032112013548612595,
                0.025671780109405518,
                -0.015031947754323483,
                0.016392474994063377,
                0.018277863040566444,
                -0.02622380293905735,
                -0.0510980524122715,
                0.00641903281211853,
                0.051948003470897675,
                0.02797996625304222,
                -0.06401564180850983,
                -0.009740244597196579,
                -0.02702968940138817,
                -0.03839736804366112,
                -0.017360761761665344,
                -0.021565254777669907,
                0.034569207578897476,
                -0.030307192355394363,
                -0.09620120376348495,
                -0.05127706378698349,
                -0.03764711692929268,
                0.0033527654595673084,
                -0.01293074432760477,
                -0.07923711836338043,
                -0.0013784983893856406,
                -0.0016937050968408585,
                -0.032846495509147644,
                0.04688921198248863,
                -0.06446782499551773,
                0.028576118871569633,
                0.026151929050683975,
                0.003975227475166321,
                -0.02556270733475685,
                -0.04986698552966118,
                0.016119815409183502,
                -0.05245810002088547,
                0.027484390884637833,
                -0.021772323176264763,
                0.003829950699582696,
                0.012518693692982197,
                0.0042207189835608006,
                0.026735421270132065,
                0.022167526185512543,
                0.04668295010924339,
                0.03886798396706581,
                0.017067011445760727,
                -0.02417050488293171,
                -0.09718292951583862,
                -0.07921292632818222,
                -0.02699732407927513,
                -0.044164422899484634,
                0.03530310094356537,
                -0.021444108337163925,
                0.007941721938550472,
                -0.016884958371520042,
                7.357570575550199e-05,
                -0.03925492241978645,
                0.018599556758999825,
                -0.05066779628396034,
                0.06739548593759537,
                -0.012805873528122902,
                -0.03646381199359894,
                -0.005514457821846008,
                0.004959098529070616,
                -0.014509199187159538,
                0.1282569020986557,
                0.01027018204331398,
                0.032199352979660034,
                0.03522903472185135,
                -0.021650351583957672,
                0.02581377699971199,
                -0.047213975340127945,
                -0.003427172778174281,
                0.015798980370163918,
                0.02318248711526394,
                0.04500181972980499,
                0.052431270480155945,
                0.02917981520295143,
                -0.05655280500650406,
                -0.04119635000824928,
                -0.029705340042710304,
                -0.04933308809995651,
                -0.040685225278139114,
                0.05702405422925949,
                0.04876292869448662,
                0.00173803954385221,
                0.023714065551757812,
                0.0010450260015204549,
                0.008046144619584084,
                -0.012805433943867683,
                0.048057910054922104,
                0.03581017628312111,
                -0.024852870032191277,
                0.015866229310631752,
                0.011184222996234894,
                0.05298400670289993,
                -0.0323605090379715,
                0.07207611203193665,
                -0.04280693829059601,
                0.02481660433113575,
                -0.0036429925821721554,
                -0.008909288793802261,
                0.0014926117146387696,
                -0.02395532839000225,
                0.023229509592056274,
                0.020887915045022964,
                2.536852719003946e-07,
                -0.018981721252202988,
                -0.00038393569411709905,
                0.01399342343211174,
                -0.007585345767438412,
                0.012174171395599842,
                0.012964727357029915,
                0.0008272489649243653,
                0.0415523387491703,
                -0.015337154269218445,
                0.00985539611428976,
                -0.0020561437122523785,
                0.00765659986063838,
                0.010091003030538559,
                -0.06804496794939041,
                0.0017881747335195541,
                -0.11605153977870941,
                -0.07135820388793945,
                -0.014647615142166615,
                -0.03376760706305504,
                0.02513277158141136,
                -0.04153198376297951,
                -0.02518313191831112,
                -0.030879147350788116,
                -0.03460722416639328,
                0.046782683581113815,
                0.060647912323474884,
                0.015332307666540146,
                -0.029134251177310944,
                -0.0352136567234993,
                0.05387028306722641,
                -0.06323640048503876,
                0.0008146724430844188,
                0.04015263915061951,
                0.012630071491003036,
                0.009314258582890034,
                -0.028202872723340988,
                0.04908214136958122,
                0.05503241345286369,
                0.0017639005091041327,
                -0.017704399302601814,
                -0.02606961503624916,
                -0.024123748764395714,
                0.019189316779375076,
                0.010092366486787796,
                0.026297684758901596,
                -0.03195889666676521,
                -0.00917387567460537,
                0.04168671369552612,
                -0.0925932377576828,
                -0.020774904638528824,
                0.012152297422289848,
                -0.005617656745016575,
                0.015253173187375069,
                0.010094400495290756,
                0.03026873618364334,
                0.04006413742899895,
                0.037775032222270966,
                0.028077077120542526,
                0.030396053567528725,
                0.017976287752389908,
                0.015925133600831032,
                -0.037327323108911514,
                0.020409462973475456,
                0.012540923431515694,
                -0.006960728671401739,
                0.02640538662672043,
                -0.040601830929517746,
                1.8538333401847029e-34,
                -0.013182871043682098,
                0.0017389694694429636,
                -0.012927728705108166,
                0.03106982633471489,
                0.013438199646770954,
                -0.044836848974227905,
                0.03574086353182793,
                -0.05254603177309036,
                0.025687379762530327,
                -0.0236157588660717,
                -0.007583183702081442,
                -0.028680050745606422,
                -0.005601042415946722,
                0.00279961246997118,
                -0.07838722318410873,
                0.015207772143185139,
                -0.005009710788726807,
                0.007904332131147385,
                -0.002713041380047798,
                0.05214766040444374,
                -0.011733727529644966,
                0.05588341876864433,
                -0.032002124935388565,
                0.03380231931805611,
                0.029890026897192,
                0.009525343775749207,
                -0.014964909292757511,
                0.04424598440527916,
                0.013081405311822891,
                -0.12038962543010712,
                -0.06286872178316116,
                -0.11230800300836563,
                -0.01719016209244728,
                -0.005601603537797928,
                0.023409316316246986,
                0.058106958866119385,
                -0.03346484526991844,
                -0.015584135428071022,
                0.03698033466935158,
                0.008817887865006924,
                0.010431160219013691,
                0.012513426132500172,
                0.020720558241009712,
                0.03519560396671295,
                0.01614273339509964,
                1.935929276442039e-06,
                -0.06836605072021484,
                -0.016437245532870293,
                -0.030074065551161766,
                -0.04139592498540878,
                0.0365133173763752,
                -0.013477467000484467,
                0.011481232941150665,
                -0.0710529237985611,
                -0.033205412328243256,
                -0.004842180293053389,
                0.0425112284719944,
                -0.005865291226655245,
                -0.10069692879915237,
                -0.02812766470015049,
                0.010878597386181355,
                -0.003156115533784032,
                -0.06909117847681046,
                -0.11542323231697083,
                -0.015998225659132004,
                0.00783144123852253,
                -0.030570676550269127,
                0.03280586749315262,
                -0.05093058571219444,
                -0.034793511033058167,
                0.0642794594168663,
                -0.036849621683359146,
                0.02253747545182705,
                -0.016516445204615593,
                0.014308934099972248,
                -0.004290541633963585,
                0.030891165137290955,
                0.03721948713064194,
                0.07592182606458664,
                -0.008512530475854874,
                0.012328721582889557,
                0.02809266559779644,
                -0.0032204899471253157,
                0.030335383489727974,
                0.06043205037713051,
                -0.07555300742387772,
                0.05065998062491417,
                0.0333753302693367,
                -0.027261225506663322,
                0.037806786596775055,
                0.009749406948685646,
                0.009775020182132721,
                0.054160065948963165,
                -0.02528211660683155,
                0.06991080939769745,
                0.05062210559844971,
                -0.05306759476661682,
                0.048294760286808014,
                -0.03268561512231827,
                -0.002675966825336218,
                0.015607074834406376,
                -0.006238327827304602,
                0.004061688203364611,
                -0.029382623732089996,
                0.046232543885707855,
                -0.020151734352111816,
                -0.042085010558366776,
                0.05792667716741562,
                0.0005507201422005892,
                -0.017845196649432182,
                -0.04179470241069794,
                -0.032217077910900116,
                0.04303404688835144,
                0.02142857201397419,
                0.010747482068836689,
                -0.00525823375210166,
                -0.01575555093586445,
                0.019385965541005135,
                -0.010096016339957714,
                0.010235387831926346,
                0.05756228044629097,
                0.011493431404232979,
                0.03611245006322861,
                0.027419274672865868,
                0.023057615384459496,
                -0.016785692423582077,
                -0.012208175845444202,
                -0.0634322464466095,
                -0.08570156991481781,
                0.03899870067834854,
                0.06630513817071915,
                0.01378968171775341,
                0.04284576699137688,
                -0.043121013790369034,
                0.03411208465695381,
                -0.007176597137004137,
                0.03943103179335594,
                -0.046281881630420685,
                -0.008078009821474552,
                0.0287197083234787,
                0.07381562143564224,
                0.014642653986811638,
                0.00809595175087452,
                -0.0007980851223692298,
                0.009977798908948898,
                0.006882492918521166,
                -0.004746889229863882,
                -0.03462968394160271,
                0.00875325221568346,
                -0.008322557434439659,
                0.007414751220494509,
                0.02210361883044243,
                -0.008667081594467163,
                0.04474413022398949,
                0.03841763734817505,
                -0.00815519131720066,
                -0.0062464396469295025,
                0.003191557712852955,
                -0.05042720586061478,
                -0.04896920174360275,
                -0.028293702751398087,
                0.04976018890738487,
                -0.054291050881147385,
                -0.038186993449926376,
                -0.004063473083078861,
                0.05825728178024292,
                0.032031867653131485,
                -0.0210499819368124,
                -0.021940547972917557,
                -0.065467469394207,
                0.0682249441742897,
                0.010315418243408203,
                -0.03334849327802658,
                -0.02924760989844799,
                0.02702372707426548,
                -0.006423968821763992,
                0.019811183214187622,
                -0.04886642470955849,
                -0.018827565014362335,
                -0.030296146869659424,
                -0.022585958242416382,
                -0.023796861991286278,
                0.01781201921403408,
                -0.0019023431232199073,
                -0.00714610842987895,
                -0.017266282811760902,
                0.08372093737125397,
                -0.052181027829647064,
                -0.004503875970840454,
                -0.000719616306014359,
                0.0232443418353796,
                0.0018682641675695777,
                -0.13927002251148224,
                0.04120399057865143,
                -0.016975359991192818,
                -0.024040840566158295,
                0.07524722069501877,
                -0.013005907647311687,
                -0.006485420744866133,
                0.021002480760216713,
                -0.00924145057797432,
                -0.012340550310909748,
                -0.01737581379711628,
                -0.10878781229257584,
                0.06021813675761223,
                0.025260474532842636,
                -0.03718532621860504,
                0.02295420505106449,
                0.04817986115813255,
                0.06392430514097214,
                -0.06284596025943756,
                -0.0123524134978652,
                -0.011961130425333977,
                0.016163427382707596,
                0.10880739986896515,
                -0.04585132747888565,
                0.0420423299074173,
                0.018362103030085564,
                -0.021667828783392906,
                -0.07178281247615814,
                0.03905870392918587,
                -0.0171639584004879,
                0.0009701570961624384,
                0.0868099182844162,
                -0.0020628743804991245,
                0.004018403124064207,
                -0.03193015605211258,
                0.0013044554507359862,
                -0.004786093253642321,
                -0.02155962772667408,
                0.0006071671959944069,
                0.006589351687580347,
                0.05615409463644028,
                -0.0026129824109375477,
                -0.010481161065399647,
                -0.026401039212942123,
                0.07835263013839722,
                0.005587761290371418,
                0.010090384632349014,
                -0.03862529620528221,
                0.029899070039391518,
                0.006384358741343021,
                -0.006414666306227446,
                0.008676474913954735,
                0.025927547365427017,
                0.004976772703230381,
                -0.0493883453309536,
                0.03767712041735649,
                -0.045141298323869705,
                0.022485293447971344,
                0.0013250212650746107,
                -0.04297826811671257,
                -0.004782792180776596,
                -0.010254918597638607,
                0.00988901685923338,
                0.04896122217178345,
                0.019820308312773705,
                -0.019961459562182426,
                -0.00042544593452475965,
                0.022830862551927567,
                -0.005181170534342527,
                3.7189427530393004e-05,
                -0.07149733603000641,
                0.057739678770303726,
                -0.011284306645393372,
                0.04426676034927368,
                -0.010181096382439137,
                -0.03130050748586655,
                0.054094333201646805,
                -0.051818229258060455,
                -0.0521945059299469,
                -0.010638958774507046,
                -0.048234693706035614,
                -0.007847979664802551,
                -0.01831759512424469,
                -0.004600896965712309,
                -0.010954326018691063,
                0.02810019813477993,
                -0.021565217524766922,
                -0.03023267537355423,
                0.034763265401124954,
                -0.018100174143910408,
                0.025394920259714127,
                0.03524170070886612,
                -0.006015309132635593,
                -0.04770727828145027,
                0.03030996024608612,
                -0.0006743628182448447,
                0.009807348251342773,
                -0.030068673193454742,
                0.010833270847797394,
                -0.0436711311340332,
                0.05093972757458687,
                -0.06493543088436127,
                -0.009428849443793297,
                -0.0013367109932005405,
                0.04438357800245285,
                -0.022738059982657433,
                0.013653288595378399,
                -0.006519882008433342,
                0.06453456729650497,
                -0.017886584624648094,
                -0.013777852058410645,
                0.07833296060562134,
                -0.021132666617631912,
                0.006045288406312466,
                0.014666806906461716,
                -0.016725720837712288,
                0.062942273914814,
                0.03727594017982483,
                -0.060522135347127914,
                0.03039829060435295,
                -0.030860723927617073,
                -0.02522486262023449,
                -0.0276629701256752,
                -0.06677505373954773,
                0.03172776848077774,
                -0.030962994322180748,
                0.01088885497301817,
                -0.05638362094759941,
                0.005569856148213148,
                0.03059333935379982,
                0.03054499812424183,
                0.04613564535975456,
                -0.019510556012392044,
                0.055204618722200394,
                0.02161566913127899,
                0.012808122672140598,
                0.004104342777282,
                0.020243659615516663,
                0.021646758541464806,
                0.02516181208193302,
                0.06212965399026871,
                -0.008098535239696503,
                -0.045164287090301514,
                0.014269189909100533,
                0.020004795864224434,
                -0.00792866013944149,
                0.021603869274258614,
                -0.03628986328840256,
                -0.06827088445425034,
                0.00779219064861536,
                -0.05551495775580406,
                0.007739664055407047,
                0.03845546767115593,
                -0.025493929162621498,
                -0.0357293076813221,
                -0.015308987349271774,
                -0.08915521204471588,
                0.03850105032324791,
                -0.005781585816293955,
                -0.012171243317425251,
                0.0036137523129582405,
                -0.034021783620119095,
                0.036681000143289566,
                -0.04558463394641876,
                -0.009434214793145657,
                0.0253176037222147,
                0.04682893678545952,
                0.024337805807590485,
                -0.026055902242660522,
                -0.024038227275013924,
                0.04854322969913483,
                0.03576546907424927,
                -0.0223056860268116,
                -0.028047380968928337,
                -0.009674365632236004,
                0.04318505898118019,
                0.01655130833387375,
                -0.0004853008722420782,
                -0.01639689691364765,
                0.04278255254030228,
                0.014043453149497509,
                0.035036902874708176,
                0.008842932060360909,
                0.01917683519423008,
                0.012877246364951134,
                0.04201749712228775,
                -0.019096719101071358,
                0.019870882853865623,
                0.039614640176296234,
                0.031784188002347946,
                0.008789107203483582,
                -0.06818851828575134,
                0.10094590485095978,
                -0.03407562896609306,
                -0.007764704059809446,
                0.055069103837013245,
                -0.004172493703663349,
                0.03393636271357536,
                0.021273193880915642,
                0.024774713441729546,
                -0.02369234710931778,
                0.006675406359136105,
                -0.0017868042923510075,
                0.006398215424269438,
                -0.02234579622745514,
                -0.026676896959543228,
                0.026163598522543907,
                -0.027433698996901512,
                0.0035160710103809834,
                0.04200068861246109,
                -0.036109115928411484,
                0.05154097452759743,
                0.058271754533052444,
                -0.015779685229063034,
                -0.008224884048104286,
                -0.007742567453533411,
                -0.004999644123017788,
                0.021841885522007942,
                0.01922733336687088,
                0.0052642798982560635,
                -0.003894837573170662,
                0.02341378852725029,
                0.03462143614888191,
                -0.020321201533079147,
                -0.06877484917640686,
                -0.005707054864615202,
                -0.03697721287608147,
                0.0278148390352726,
                -0.11325676739215851,
                0.014575473964214325,
                0.015425700694322586,
                0.009920237585902214,
                -0.017814889550209045,
                0.002467826008796692,
                -0.10837099701166153,
                -0.0020421056542545557,
                -0.013638007454574108,
                0.015691913664340973,
                -0.05262266471982002,
                0.04154200851917267,
                0.024767091497778893,
                0.04567789286375046,
                0.003010387299582362,
                0.05314740166068077,
                -0.008611003868281841,
                0.030245887115597725,
                0.012767073698341846,
                0.033203594386577606,
                0.03160954639315605,
                0.024395311251282692,
                -0.0016722631407901645,
                -0.07871638238430023,
                0.020755764096975327,
                0.07009757310152054,
                0.0015970634995028377,
                0.059969235211610794,
                0.019957413896918297,
                0.029033159837126732,
                -0.003179760416969657,
                -0.009468482807278633,
                -0.0048503270372748375,
                -0.025023624300956726,
                -0.049486979842185974,
                -0.0014811580767855048,
                0.025709236040711403,
                0.021570051088929176,
                0.004766021389514208,
                -0.01935490220785141,
                -0.010147493332624435,
                0.021143151447176933,
                -0.02974858321249485,
                0.014107778668403625,
                0.035622019320726395,
                0.031087128445506096,
                -0.05950699746608734,
                -0.06969636678695679,
                0.048600394278764725,
                -0.02601485699415207,
                0.016939915716648102,
                -0.001415069680660963,
                0.017494257539510727,
                -0.026701156049966812,
                -0.0059023043140769005,
                0.014404959976673126,
                -0.025443684309720993,
                -0.010321494191884995,
                -0.029556546360254288,
                -0.02545314095914364,
                -0.021688535809516907,
                -0.07287506014108658,
                -0.01803460717201233,
                -0.004547924734652042,
                0.09082596004009247,
                0.027584196999669075,
                0.03304697945713997,
                0.0007346420316025615,
                -0.004371704533696175,
                0.04970858618617058,
                0.002285965718328953,
                0.024140601977705956,
                0.08886771649122238,
                -0.029804354533553123,
                -0.03288428485393524,
                0.04615568742156029,
                0.0330875962972641,
                -0.029852936044335365,
                -0.016944609582424164,
                0.04644749313592911,
                -0.018913405016064644,
                0.0025331233628094196,
                -0.010985046625137329,
                0.0037175933830440044,
                0.028647959232330322,
                0.01839599944651127,
                0.054393474012613297,
                -0.05188295245170593,
                0.006306999363005161,
                -0.05370815843343735,
                0.003816623240709305,
                0.006768939085304737,
                0.007930675521492958,
                0.015887204557657242,
                0.03172065317630768,
                0.010161255486309528,
                0.0005251237889751792,
                -0.017178257927298546,
                0.023113509640097618,
                0.0026038498617708683,
                0.0025022486224770546,
                0.07096588611602783,
                -0.01961272582411766,
                -0.019976450130343437,
                0.012756461277604103,
                -0.04920070618391037,
                -0.02873220667243004,
                0.011766370385885239,
                0.016434740275144577,
                -0.021009981632232666,
                0.06784646213054657,
                -0.005508465692400932,
                -0.010880043730139732,
                0.03465013951063156,
                -0.01302491594105959,
                -0.059643302112817764,
                -0.04147316515445709,
                -0.016001887619495392,
                -0.05820416659116745,
                0.03436825051903725,
                0.021468456834554672,
                -0.008990415371954441,
                -0.01863744668662548,
                0.031068874523043633,
                -0.010041053406894207,
                0.0042089251801371574,
                0.0056398180313408375,
                0.025722907856106758,
                0.0710434541106224,
                0.009375756606459618,
                0.03141390159726143,
                -0.03858301416039467,
                -0.026118343695998192,
                -0.02131592109799385,
                -0.0045491596683859825,
                -0.0031600373331457376,
                0.03548242524266243,
                0.016480308026075363,
                0.017569713294506073,
                -0.024972794577479362,
                -0.023528052493929863,
                0.030990513041615486,
                0.04013467952609062,
                0.00877152569591999,
                0.013633479364216328,
                0.00505560077726841,
                -7.07249473952073e-33,
                0.02076578326523304,
                -0.006427944637835026,
                -0.028083188459277153,
                -0.035162437707185745,
                -0.022003529593348503,
                -0.022259581834077835,
                -0.053340472280979156,
                0.0025000660680234432,
                -0.021899716928601265,
                -0.02495570108294487,
                0.013772644102573395,
                -0.03948715701699257,
                0.025789139792323112,
                0.08012071996927261,
                0.056400325149297714,
                -0.014516450464725494,
                -0.0020012403838336468,
                0.01005288865417242,
                -0.051823362708091736,
                -0.04064587876200676,
                0.008721964433789253,
                0.03565311059355736,
                0.0388936921954155,
                0.07813496142625809,
                -0.048303574323654175,
                -0.005097635556012392,
                0.01669672317802906,
                0.006745068822056055,
                0.00039573246613144875,
                -0.035404887050390244,
                0.005522555205971003,
                -0.00021280281362123787,
                -0.01999719627201557,
                -0.027242379263043404,
                0.04719642549753189,
                -0.004319680389016867,
                -0.019246205687522888,
                -0.04195699095726013,
                -0.06214658543467522,
                -0.04692952334880829,
                -0.011951093561947346,
                -0.03766869753599167,
                0.04597458243370056,
                -0.017299260944128036,
                -0.07826577126979828,
                -0.060391753911972046,
                -0.049385856837034225,
                0.0020285111386328936,
                -0.020448489114642143,
                -0.02740478701889515,
                -0.05191783979535103,
                -0.004217637702822685,
                -0.0041739786975085735,
                0.043627697974443436,
                -0.047812286764383316,
                -0.03930456191301346,
                0.026749376207590103,
                -0.01767847128212452,
                0.04243452474474907,
                0.003696501487866044,
                0.017692234367132187,
                -0.004433515481650829,
                0.04190225899219513,
                -0.060527071356773376,
                -0.030427636578679085,
                0.0070650032721459866,
                0.02973015047609806,
                0.024420522153377533,
                0.006625669542700052,
                0.01869356818497181,
                0.0308892410248518,
                0.018050000071525574,
                -0.0015525799244642258,
                -0.08177139610052109,
                -0.07634668052196503,
                -0.03663476184010506,
                -0.05646451935172081,
                -0.028811834752559662,
                0.012147978879511356,
                0.016468575224280357,
                -0.06372973322868347,
                0.03262677416205406,
                -0.016936227679252625,
                -0.023809729143977165,
                -0.052961476147174835,
                0.035322465002536774,
                0.011352703906595707,
                -0.038505468517541885,
                -0.007270008325576782,
                -0.024663589894771576,
                -0.05213052034378052,
                0.10086224973201752,
                0.006372823845595121,
                0.03661562129855156,
                0.02971610054373741,
                0.015584627166390419,
                0.05514718219637871,
                -0.022619685158133507,
                0.0013081069337204099,
                0.0010595493949949741,
                -0.030396075919270515,
                0.023642556741833687,
                0.000397621130105108,
                0.03678537905216217,
                -0.025570880621671677,
                0.002117226365953684,
                -0.04706960916519165,
                -0.049647025763988495,
                -0.019963828846812248,
                0.03483734652400017,
                0.01247825101017952,
                -0.0467388890683651,
                0.019373327493667603,
                0.0023824262898415327,
                0.021325742825865746,
                0.039590150117874146,
                0.0030455715022981167,
                0.007018243428319693,
                -0.059410952031612396,
                0.036459844559431076,
                0.005186690017580986,
                0.047156285494565964,
                0.016597867012023926,
                0.04192052781581879,
                -0.024971842765808105,
                0.03791399672627449,
                0.029055749997496605,
                -0.05721940100193024,
                0.0008505600853823125,
                0.0047961012460291386,
                0.03209841996431351,
                0.003468968439847231,
                2.509210901280312e-07,
                -0.0317094586789608,
                0.029492707923054695,
                0.013246695511043072,
                0.015081248246133327,
                -0.0009856800315901637,
                -0.00643225759267807,
                0.013157126493752003,
                0.0175583865493536,
                -0.0015734427142888308,
                0.0299678985029459,
                -0.04090498760342598,
                -0.001811872236430645,
                0.0557556077837944,
                -0.04768159240484238,
                0.020465904846787453,
                -0.05779647454619408,
                -0.05489451810717583,
                -0.02407269924879074,
                0.010357333347201347,
                0.03736120089888573,
                -0.05220845714211464,
                -0.020349862053990364,
                -0.019374316558241844,
                -0.02986016869544983,
                0.007120145484805107,
                0.03229961171746254,
                -0.007732472848147154,
                0.0049715847708284855,
                -0.021848030388355255,
                0.019775552675127983,
                -0.04063943028450012,
                -0.03626837581396103,
                0.05127076432108879,
                0.01380819734185934,
                -0.005493819247931242,
                -0.047443028539419174,
                0.03308765962719917,
                -0.011066610924899578,
                0.004280656576156616,
                0.0323503352701664,
                -0.055665433406829834,
                -0.023618239909410477,
                -0.0003242926031816751,
                -0.007718442473560572,
                -0.0007418306777253747,
                -0.07911251485347748,
                0.0007942369556985795,
                0.031780023127794266,
                -0.07487671822309494,
                -0.005186364986002445,
                -0.07060360908508301,
                0.007122437469661236,
                0.0335373692214489,
                0.004919008817523718,
                0.027191925793886185,
                0.033452920615673065,
                0.059393685311079025,
                0.028158782050013542,
                -0.007413595914840698,
                -0.003762289648875594,
                0.03371264412999153,
                -0.06915634870529175,
                -0.0006539602763950825,
                0.020898517221212387,
                0.0564594641327858,
                0.0016278118127956986,
                -0.025111008435487747,
                1.6614704625404772e-34,
                0.03515325114130974,
                -0.010109767317771912,
                -0.003533907001838088,
                0.03158419206738472,
                0.025080829858779907,
                -0.0730716809630394,
                -0.002956491895020008,
                -0.071565181016922,
                0.006483776494860649,
                0.004554017912596464,
                -0.03299064561724663,
                -0.00990268588066101,
                -0.013068459928035736,
                -0.001400032197125256,
                -0.05707933381199837,
                -0.01534702442586422,
                0.03222699090838432,
                0.04549571871757507,
                0.03926035389304161,
                0.0683908760547638,
                -0.011301850900053978,
                0.08092299103736877,
                -0.051228661090135574,
                0.062030237168073654,
                0.02332516759634018,
                0.026029221713542938,
                -0.04104801267385483,
                0.003396447515115142,
                0.02416885271668434,
                -0.08686941862106323,
                0.008612989448010921,
                -0.04201098904013634,
                0.02673765830695629,
                -0.017120683565735817,
                0.021316740661859512,
                -0.013753868639469147,
                0.0008442318066954613,
                -0.0009850505739450455,
                0.0056681521236896515,
                0.0203224066644907,
                -0.022698109969496727,
                0.07151949405670166,
                -0.02641446329653263,
                0.031114352867007256,
                -0.03605484589934349,
                1.5623572835465893e-06,
                -0.029819266870617867,
                0.011262810789048672,
                0.009881366044282913,
                -0.037184938788414,
                -0.02276632748544216,
                0.037558238953351974,
                -0.02707599475979805,
                -0.03082054853439331,
                0.03237335756421089,
                0.040094707161188126,
                -0.03379979357123375,
                0.05168797820806503,
                -0.004105384461581707,
                0.011310159228742123,
                0.037311065942049026,
                -0.02709011174738407,
                0.03266880661249161,
                -0.1296304315328598,
                -0.052706170827150345,
                -0.034330517053604126,
                -0.003439085092395544,
                0.01812996156513691,
                0.00011676436406560242,
                0.030318161472678185,
                0.039446137845516205,
                0.001603714656084776,
                -0.004121293779462576,
                -0.009675852954387665,
                0.008293677121400833,
                0.026430612429976463,
                0.006611637771129608,
                0.008014550432562828,
                -0.005629017483443022,
                0.010878980159759521,
                0.060276564210653305,
                -0.002748912898823619,
                0.0001444626395823434,
                0.03494074195623398,
                0.07882047444581985,
                -0.03851950168609619,
                -0.007111995946615934,
                0.0054071093909442425,
                -0.014484981074929237,
                -0.023661332204937935,
                -0.02696259133517742,
                0.0006871942896395922,
                0.05507241562008858,
                -0.003884259145706892,
                0.04651687294244766,
                0.06819380819797516,
                0.006557360291481018,
                0.03376910462975502,
                -0.002553454367443919,
                -0.007293457165360451,
                0.03356057405471802,
                0.050187475979328156,
                -0.027502648532390594,
                0.028961386531591415,
                0.055108748376369476,
                0.015254354104399681,
                -0.02968176268041134,
                0.05520406365394592,
                -0.0177011601626873,
                0.09634993970394135,
                -0.051399946212768555,
                -0.08178214728832245,
                0.028628898784518242,
                -0.003253310453146696,
                0.02318076603114605,
                0.019267473369836807,
                -0.05167003720998764,
                -0.017016807571053505,
                0.009840937331318855,
                -0.0031424930784851313,
                0.060839176177978516,
                0.015627359971404076,
                0.01933726854622364,
                0.0016986322589218616,
                -0.0034849438816308975,
                -0.015582024119794369,
                0.028299683704972267,
                -0.06306817382574081,
                -0.0017528411699458957,
                -0.00455525703728199,
                0.006476823706179857,
                0.047279901802539825,
                0.002797947032377124,
                -0.05025308579206467,
                0.022369811311364174,
                0.05682682245969772,
                0.009601701982319355,
                -0.0524192675948143,
                0.01728847250342369,
                0.026834998279809952,
                0.024681752547621727,
                -0.027691150084137917,
                0.016490589827299118,
                0.01080864854156971,
                -0.004424512851983309,
                -0.0011877312790602446,
                0.07294497638940811,
                0.0018815788207575679,
                0.005241600796580315,
                -0.08284734934568405,
                -0.006788212340325117,
                0.00875087920576334,
                -0.015144013799726963,
                -0.0080788005143404,
                0.04856172576546669,
                0.00020270486129447818,
                -0.036372020840644836,
                -0.011325920931994915,
                -0.017027271911501884,
                -0.0114573510363698,
                -0.005581079516559839,
                -0.0159224234521389,
                -0.06185436248779297,
                -0.021214887499809265,
                -0.020782962441444397,
                0.055940479040145874,
                0.04150687903165817,
                0.03945453092455864,
                -0.04288182780146599,
                -0.04389670491218567,
                0.006987622939050198,
                0.02255997061729431,
                -0.003266623243689537,
                -0.004321340937167406,
                0.011186234652996063,
                -0.038769811391830444,
                0.03282521292567253,
                0.057990819215774536,
                -0.013887477107346058,
                -0.02607189118862152,
                -0.016884343698620796,
                -0.009994115680456161,
                0.03442530706524849,
                -0.06202642247080803,
                0.05686679854989052,
                0.017344702035188675,
                0.026876360177993774,
                0.01890065148472786,
                -0.04349014163017273,
                -0.019384540617465973,
                0.036661211401224136,
                -0.02417706325650215,
                -0.10557712614536285,
                0.02338903769850731,
                -0.030919309705495834,
                -0.009141698479652405,
                0.01794464699923992,
                -0.0005977053660899401,
                0.003010989399626851,
                -0.044846680015325546,
                -0.02805314213037491,
                0.006564117968082428,
                -0.007318810094147921,
                -0.04509219154715538,
                0.002020358107984066,
                0.017714092507958412,
                -0.013354779221117496,
                -0.004167002160102129,
                0.05049419403076172,
                0.019441144540905952,
                -0.041934993118047714,
                -0.0016982881352305412,
                0.007152615115046501,
                -0.01733560860157013,
                0.10673882067203522,
                -0.04647084325551987,
                0.043436821550130844,
                0.0021383785642683506,
                -0.036886319518089294,
                -0.046836964786052704,
                -0.041207488626241684,
                -0.031142784282565117,
                -0.012706915847957134,
                0.06894632428884506,
                0.00509417150169611,
                -0.011388062499463558,
                0.008518886752426624,
                0.03876873478293419,
                0.01991136744618416,
                -0.04116903990507126,
                0.06800401955842972,
                -0.00755923381075263,
                0.06679633259773254,
                -0.02404467761516571,
                -0.02574990876019001,
                0.005426574032753706,
                0.01887332648038864,
                -0.037114158272743225,
                0.029313068836927414,
                -0.031460952013731,
                0.006703495979309082,
                0.04114873707294464,
                0.04120103269815445,
                -0.005084258504211903,
                0.020588288083672523,
                -0.015972796827554703,
                -0.011231101118028164,
                0.09647465497255325,
                0.09161826968193054,
                0.026656677946448326,
                -0.046337150037288666,
                0.008237803354859352,
                0.006893233396112919,
                0.020593276247382164,
                0.026872949674725533,
                -0.023975754156708717,
                0.004419518169015646,
                -0.03478303551673889,
                -0.010839253664016724,
                -0.012332594953477383,
                0.028896383941173553,
                -0.047435782849788666,
                -0.04749907925724983,
                0.0020953170023858547,
                0.0143882492557168,
                -0.0305379219353199,
                -0.08387936651706696,
                -0.01818396896123886,
                0.05456775426864624,
                -0.020674683153629303,
                -0.006849132012575865,
                0.03273747116327286,
                -0.058124199509620667,
                0.009516589343547821,
                -0.016069984063506126,
                0.03992658853530884,
                -0.03537869453430176,
                0.08856475353240967,
                -0.0374302975833416,
                -0.01805688999593258,
                -0.01172587275505066,
                -0.002038386184722185,
                0.03480207175016403,
                0.01875508576631546,
                0.0009761542896740139,
                -0.02210116945207119,
                0.017187226563692093,
                -0.01917799934744835,
                -0.0014747473178431392,
                -0.017627166584134102,
                0.014535424299538136,
                -0.019814258441329002,
                -0.04114886373281479,
                -0.023070169612765312,
                -0.016603726893663406,
                -0.004931540694087744,
                0.01250844169408083,
                0.022686731070280075,
                0.00538790225982666,
                -0.010389273054897785,
                0.0028437261935323477,
                0.028062330558896065,
                -0.02439897321164608,
                0.04095657914876938,
                -0.04678187146782875,
                -0.03165411576628685,
                0.02252376824617386,
                -0.031453937292099,
                0.006212460808455944,
                -0.007196031045168638,
                0.008124101907014847,
                -0.013851233758032322,
                -0.042542193084955215,
                0.006929933559149504,
                -0.0027546053752303123,
                -0.07203748822212219,
                0.045362766832113266,
                0.04091992974281311,
                0.020070280879735947,
                -0.0017319392645731568,
                0.007652953267097473,
                0.01786218211054802,
                0.013178272172808647,
                0.014887415803968906,
                0.02697429433465004,
                0.015369570814073086,
                0.017447633668780327,
                -0.03104211948812008,
                -0.026016823947429657,
                -0.006849233526736498,
                0.07566943764686584,
                0.04805438965559006,
                0.003150117350742221,
                -0.06449209898710251,
                -0.017151635140180588,
                0.030542921274900436,
                0.009610269218683243,
                0.009206416085362434,
                -0.006268101744353771,
                -0.0702487975358963,
                -0.09651841968297958,
                -0.009304499253630638,
                0.002060235943645239,
                -0.015245997346937656,
                0.06384092569351196,
                0.01452846359461546,
                0.012609009630978107,
                -0.050547532737255096,
                -0.10140174627304077,
                -0.0180203877389431,
                0.03495423123240471,
                -0.018287643790245056,
                -0.03687477856874466,
                -0.021098027005791664,
                0.004083108622580767,
                0.020560692995786667,
                -0.047537125647068024,
                -0.04499806463718414,
                0.06471316516399384,
                -0.025859935209155083,
                0.00017346342792734504,
                0.027474651113152504,
                0.044086672365665436,
                0.013660685159265995,
                -0.007878105156123638,
                -0.0324961356818676,
                0.03486945852637291,
                -0.011266740970313549,
                -0.07575255632400513,
                -0.0006976042641326785,
                -0.04281401261687279,
                -0.03919487074017525,
                0.039681125432252884,
                0.016273047775030136,
                -0.01245160959661007,
                0.006974771618843079,
                0.024209830909967422,
                0.033352941274642944,
                0.04722355678677559,
                -0.05806639418005943,
                0.025575613602995872,
                -0.005940430797636509,
                0.05291793495416641,
                0.006260275840759277,
                0.007574670948088169,
                -0.0625096932053566,
                0.03692767396569252,
                0.02057328075170517,
                0.00974719226360321,
                -0.013282054103910923,
                -0.018399005755782127,
                0.010422836057841778,
                0.034384556114673615,
                0.05780699476599693,
                0.010671070776879787,
                0.0003373183717485517,
                0.001946236938238144,
                -0.01845003105700016,
                0.019772710278630257,
                0.06166676804423332,
                0.0636606439948082,
                0.03196953982114792,
                -0.05690110847353935,
                -0.09259607642889023,
                0.08340161293745041,
                0.03858604282140732,
                -0.027455458417534828,
                0.044811639934778214,
                -0.03859248012304306,
                -0.0230754055082798,
                0.028536846861243248,
                0.04731873795390129,
                0.016406802460551262,
                -0.05793467164039612,
                -0.005504838190972805,
                -0.027786731719970703,
                -0.0353231243789196,
                0.07930561155080795,
                -0.03442152589559555,
                0.002125259954482317,
                -0.08399181067943573,
                -0.018077749758958817,
                -0.03483958914875984,
                -0.04512918367981911,
                -0.008647767826914787,
                0.019726967439055443,
                -0.07501012831926346,
                -0.008165325038135052,
                -0.03687063232064247,
                0.041425347328186035,
                -0.026878977194428444,
                0.017306135967373848,
                -0.007125308737158775,
                -0.0032532147597521544,
                -0.02884816937148571,
                0.005676614120602608,
                -0.031548626720905304,
                0.06797178834676743,
                0.0541662760078907,
                -0.006128755398094654,
                -0.004054610151797533,
                -0.016407908871769905,
                0.0001638545363675803,
                -0.027813656255602837,
                0.026331188157200813,
                -0.009561995044350624,
                0.01873081550002098,
                0.011225390248000622,
                -0.050003573298454285,
                -0.029310667887330055,
                -0.01648871600627899,
                0.00010700747952796519,
                0.0017245978815481067,
                0.03307763487100601,
                0.016000304371118546,
                -0.003255297662690282,
                0.045908525586128235,
                -5.400015288614668e-05,
                0.031252820044755936,
                -0.01764628477394581,
                0.0030067453626543283,
                0.03510535880923271,
                0.017948711290955544,
                0.05235967040061951,
                -0.02205376885831356,
                0.05460963025689125,
                -0.021983111277222633,
                -0.01842512935400009,
                0.017225679010152817,
                0.023900389671325684,
                0.03949641063809395,
                0.06260909140110016,
                0.04573255777359009,
                -0.03810473158955574,
                -0.00029209666536189616,
                0.03674453869462013,
                -0.08197225630283356,
                -0.011265628971159458,
                -0.048594407737255096,
                -0.0455198772251606,
                -0.036655645817518234,
                -0.029530556872487068,
                0.004380621947348118,
                -0.020615272223949432,
                0.04908663406968117,
                0.006710578687489033,
                0.005991711281239986,
                -0.006364711560308933,
                0.011520368047058582,
                -0.030923297628760338,
                0.0053622107952833176,
                0.031124139204621315,
                -0.05133713781833649,
                -0.010301059111952782,
                0.05079301446676254,
                -0.016153665259480476,
                0.022191034629940987,
                0.040293145924806595,
                0.0021783318370580673,
                0.012122754007577896,
                0.008978721685707569,
                -0.010328132659196854,
                -0.04256940260529518,
                -0.027203552424907684,
                0.02076745592057705,
                0.02953953482210636,
                0.03848644718527794,
                -0.02183210849761963,
                0.0060132271610200405,
                -0.06744454056024551,
                -0.020960740745067596,
                0.02305828221142292,
                0.02543281763792038,
                0.024611974135041237,
                -0.019727732986211777,
                0.001520516467280686,
                0.023525789380073547,
                0.007437694352120161,
                0.04194488748908043,
                0.000920400838367641,
                -0.0005827266140840948,
                -0.0023911853786557913,
                0.009579659439623356,
                0.004856929648667574,
                -0.026674559339880943,
                0.004669289104640484,
                0.0038275723345577717,
                -0.03366066515445709,
                0.012260949239134789,
                -0.007268203888088465,
                0.01584271341562271,
                0.015526046976447105,
                -0.03904217481613159,
                0.017953529953956604,
                0.015144029632210732,
                -0.01216872688382864,
                -0.016998035833239555,
                0.009818270802497864,
                0.0008925748406909406,
                -0.04383496195077896,
                0.03627088665962219,
                0.02075023576617241,
                -0.035998038947582245,
                -0.01029111910611391,
                0.04040247201919556,
                -0.010508733801543713,
                0.02428656816482544,
                0.07475489377975464,
                0.07193242758512497,
                -0.05111830309033394,
                -0.013962690718472004,
                -0.04691365733742714,
                -0.03538812696933746,
                0.03533439338207245,
                0.0009076101705431938,
                -0.003664808813482523,
                0.046938005834817886,
                0.014159798622131348,
                -0.00900159403681755,
                0.013750491663813591,
                -0.08576739579439163,
                0.01251043938100338,
                -0.027534248307347298,
                -0.05977987125515938,
                0.038985978811979294,
                0.041807036846876144,
                -6.109792049504139e-33,
                -0.020924126729369164,
                -0.05543447285890579,
                0.03138488531112671,
                0.008188007399439812,
                -0.07137814164161682,
                -0.004470053594559431,
                -0.0059428745880723,
                -0.017271144315600395,
                -0.040816016495227814,
                -0.046099092811346054,
                0.0002216093271272257,
                -0.0020311353728175163,
                0.03786997124552727,
                0.03766306862235069,
                0.05800223350524902,
                -0.04056248068809509,
                -0.0006030126241967082,
                0.019376643002033234,
                -0.033425841480493546,
                -0.06017402559518814,
                -0.03385494276881218,
                -0.006053358316421509,
                0.030335646122694016,
                0.09470842033624649,
                0.0064810654148459435,
                0.02577325887978077,
                -0.06018625199794769,
                -0.010112100280821323,
                -0.024889569729566574,
                -0.039731740951538086,
                -0.000779254303779453,
                -0.009486237540841103,
                0.02932889200747013,
                -0.031117022037506104,
                0.01700681447982788,
                0.12599270045757294,
                -0.023564795032143593,
                -0.03880484402179718,
                -0.021891679614782333,
                -0.029464516788721085,
                -0.10575967282056808,
                -0.0472288504242897,
                0.049887172877788544,
                -0.002061054576188326,
                -0.03191456198692322,
                -0.08437852561473846,
                -0.0443180575966835,
                -0.02279297262430191,
                0.01432585809379816,
                -0.0443175844848156,
                -0.0027998625300824642,
                -0.03299868851900101,
                -0.02190198004245758,
                0.03748776391148567,
                0.02324473299086094,
                0.06387826800346375,
                0.008342397399246693,
                0.03911464288830757,
                0.007928569801151752,
                -0.06372355669736862,
                -0.01647556573152542,
                -0.0203297957777977,
                -0.008539299480617046,
                -0.020773520693182945,
                -0.035827331244945526,
                -0.014156515710055828,
                -0.13491801917552948,
                -0.015110019594430923,
                -0.0212558563798666,
                -0.01390004064887762,
                -0.0071130674332380295,
                0.06203370541334152,
                -0.039326127618551254,
                -0.045265328139066696,
                -0.0914381667971611,
                -0.04943631961941719,
                -0.08485028147697449,
                0.0545649453997612,
                -0.03065282292664051,
                0.07000792026519775,
                -0.043342214077711105,
                -0.0180771853774786,
                -0.0030198502354323864,
                0.0065447743982076645,
                -0.04564928263425827,
                0.037084244191646576,
                0.0019963805098086596,
                0.027276797220110893,
                -0.004740271717309952,
                0.03224452957510948,
                -0.01051507517695427,
                0.05849761515855789,
                -0.027794798836112022,
                0.08320139348506927,
                0.05236855149269104,
                0.006305298302322626,
                0.04616188257932663,
                -0.04020846262574196,
                0.013055644929409027,
                -0.009699340909719467,
                -0.02098078280687332,
                0.03431108593940735,
                -0.05688241124153137,
                0.013530813157558441,
                0.009317902848124504,
                -0.06080535426735878,
                -0.05551280081272125,
                -0.02541971206665039,
                -0.0106289591640234,
                0.046127915382385254,
                0.03454515337944031,
                0.031839869916439056,
                0.03072202205657959,
                -0.01623820699751377,
                0.019269956275820732,
                -0.016685044392943382,
                0.0360088050365448,
                0.025863660499453545,
                -0.02993227168917656,
                -0.03402039036154747,
                0.028384588658809662,
                0.06929618120193481,
                -0.012022686190903187,
                0.003140135435387492,
                -0.006570689845830202,
                0.0038562400732189417,
                0.05063436180353165,
                0.0029821512289345264,
                -0.008010679855942726,
                -0.029082516208291054,
                0.009009291417896748,
                -0.029479961842298508,
                2.1050057341653883e-07,
                -0.004873884841799736,
                0.017675677314400673,
                0.05322916433215141,
                0.04416917636990547,
                0.045723527669906616,
                -0.0026998252142220736,
                -0.0113785769790411,
                0.021879468113183975,
                -0.02983163110911846,
                0.011139246635138988,
                0.05098201707005501,
                -0.025725899264216423,
                0.0029635976534336805,
                -0.012294608168303967,
                0.04438094422221184,
                -0.09466694295406342,
                0.039079450070858,
                -0.03488617762923241,
                0.030838634818792343,
                0.00079545215703547,
                0.02751636691391468,
                0.0020698048174381256,
                -0.03461213409900665,
                -0.010923592373728752,
                0.0060446239076554775,
                0.032360900193452835,
                0.02653236873447895,
                -0.028455324470996857,
                0.018631085753440857,
                0.008260456845164299,
                0.023937204852700233,
                0.06275475025177002,
                0.01645594649016857,
                0.050896819680929184,
                -0.016241082921624184,
                -0.0659303218126297,
                0.04884780943393707,
                0.061917565762996674,
                0.004972705151885748,
                0.01941143535077572,
                -0.043093178421258926,
                -0.06262762099504471,
                -0.019222822040319443,
                -0.007838113233447075,
                0.02235579863190651,
                0.014804079197347164,
                -0.0011028447188436985,
                0.011812666431069374,
                -0.07942014932632446,
                -0.0016526399413123727,
                -0.01700258068740368,
                0.028309615328907967,
                -0.004557854495942593,
                -0.02149648778140545,
                0.01662413775920868,
                -0.0011509511386975646,
                0.024815967306494713,
                0.016302723437547684,
                0.08214191347360611,
                -0.007696051150560379,
                0.012673319317400455,
                -0.023044809699058533,
                0.002339443890377879,
                0.051161736249923706,
                -0.005805728957056999,
                -0.03189368546009064,
                -0.046699829399585724,
                1.118683076189031e-34,
                -0.018923640251159668,
                -0.05314703658223152,
                -0.030636712908744812,
                -0.015593663789331913,
                0.011692509055137634,
                -0.027674535289406776,
                -0.032117296010255814,
                -0.01363613922148943,
                -0.028745178133249283,
                -0.003115117782726884,
                -0.011705913580954075,
                -0.077632836997509,
                -0.010044360533356667,
                -0.007379113230854273,
                0.004970785230398178,
                0.02269740216434002,
                -0.015449048019945621,
                0.008559691719710827,
                -0.03455312177538872,
                0.017417652532458305,
                0.018218915909528732,
                0.0020858990028500557,
                0.031880542635917664,
                0.032841432839632034,
                0.07408030331134796,
                0.017142845317721367,
                0.044058095663785934,
                0.047563932836055756,
                -0.03664080798625946,
                -0.022723127156496048,
                0.04919895902276039,
                -0.021371226757764816,
                0.024790937080979347,
                -0.005925836972892284,
                0.02451314963400364,
                -0.027379745617508888,
                -0.03458482399582863,
                0.009892587549984455,
                0.018413381651043892,
                0.03892601281404495,
                -0.024295467883348465,
                -0.005943210329860449,
                -0.006308927666395903,
                0.0034364473540335894,
                0.009182434529066086,
                1.359524617328134e-06,
                -0.0037194297183305025,
                0.04725631698966026,
                0.035182323306798935,
                -0.018325045704841614,
                -0.03645654022693634,
                -0.00021321771782822907,
                -0.02047596499323845,
                -0.033141620457172394,
                0.050943754613399506,
                0.050395067781209946,
                -0.005419612396508455,
                0.03340006247162819,
                0.019989468157291412,
                0.06178497523069382,
                -0.039219316095113754,
                -0.024536846205592155,
                0.0426284521818161,
                -0.00351187726482749,
                0.04377485811710358,
                -0.08649798482656479,
                -0.021085359156131744,
                -0.006203493569046259,
                -0.02629200555384159,
                0.05632162466645241,
                0.0372401587665081,
                0.0210000891238451,
                -0.00958032812923193,
                -0.01720484532415867,
                -0.047942958772182465,
                -0.02823088876903057,
                -0.02490486204624176,
                0.04201757162809372,
                -0.05419930815696716,
                -0.0025720647536218166,
                0.02307899482548237,
                0.004042521584779024,
                0.01563241146504879,
                -0.015682734549045563,
                0.029606632888317108,
                -0.01831178367137909,
                -0.08716215193271637,
                -0.023297781124711037,
                0.011339018121361732,
                -0.001425949507392943,
                -0.023632843047380447,
                -0.024973563849925995,
                0.024160083383321762,
                -0.004130972549319267,
                -0.023328078910708427,
                0.09198780357837677,
                0.06971527636051178,
                0.01543984841555357,
                -0.03597918152809143,
                0.0461958572268486,
                0.06405031681060791,
                -0.011189749464392662,
                0.006203141063451767,
                -0.02287786826491356,
                -0.044542327523231506,
                -0.04183950647711754,
                0.006980848032981157,
                -0.0064840251579880714,
                -0.003834853647276759,
                0.03117949329316616,
                -0.08052835613489151,
                -0.022688716650009155,
                0.009304800070822239,
                0.07750143110752106,
                0.019520491361618042,
                -0.0008394108735956252,
                -0.07878874242305756,
                -0.0078116185031831264,
                0.02594693936407566,
                0.014432216994464397,
                0.03677450865507126,
                0.008779456838965416,
                -0.002153396839275956,
                0.004428493324667215,
                0.01637413538992405,
                0.03839399293065071,
                0.00754961371421814,
                -0.01443350501358509,
                0.02561560831964016,
                -0.06927776336669922,
                0.047579966485500336,
                0.06394601613283157,
                -0.0003266931453254074,
                -0.05043316259980202,
                0.03695234656333923,
                0.046975623816251755,
                0.011796308681368828,
                -0.014363150112330914,
                0.00999732967466116,
                -0.027964873239398003,
                0.022162748500704765,
                -0.012463358230888844,
                0.016765763983130455,
                -0.0006150537519715726,
                -0.008335372433066368,
                -0.06211309880018234,
                0.06508538872003555,
                0.003020132426172495,
                0.028146017342805862,
                -0.08153749257326126,
                -0.012852661311626434,
                0.05923747643828392,
                0.005167681258171797,
                -0.003343436401337385,
                0.05318347364664078,
                0.0001735240948619321,
                -0.04212456941604614,
                -0.020271485671401024,
                0.012749587185680866,
                -0.023479269817471504,
                0.04252389445900917,
                -0.04229304566979408,
                -0.046273522078990936,
                0.01301883440464735,
                -0.015277452766895294,
                -0.018038079142570496,
                0.0512005090713501,
                -0.004685551859438419,
                -0.04585789516568184,
                0.040011927485466,
                -0.005944708827883005,
                0.040093712508678436,
                -0.025498146191239357,
                0.005966848693788052,
                -0.024171702563762665,
                -0.06520583480596542,
                0.0278144683688879,
                0.028109954670071602,
                0.020774101838469505,
                -0.023214861750602722,
                -0.03293805569410324,
                0.04448673129081726,
                0.059695396572351456,
                -0.004776235204190016,
                0.024932555854320526,
                0.013473520055413246,
                -0.02455228753387928,
                -0.047177959233522415,
                -0.021077200770378113,
                0.019443927332758904,
                0.05068853124976158,
                -0.02448561042547226,
                -0.036896854639053345,
                0.007077177986502647,
                -0.018132807686924934,
                -0.02641986683011055,
                -0.009918785654008389,
                -0.02090185508131981,
                -0.005194087512791157,
                -0.034542810171842575,
                0.009650512598454952,
                -0.02933494932949543,
                -0.0045065428130328655,
                0.005679532885551453,
                -0.02387295290827751,
                -0.02877223677933216,
                0.032141007483005524,
                -0.027344640344381332,
                -0.010746506042778492,
                0.0005159704014658928,
                0.0017408023122698069,
                -0.03929987922310829,
                -0.0272423904389143,
                0.015912091359496117,
                0.07796573638916016,
                -0.07078814506530762,
                -0.008888479322195053,
                -0.020697088912129402,
                -0.06744083762168884,
                -0.050526414066553116,
                -0.0570407509803772,
                -0.04467902332544327,
                -0.02202361449599266,
                0.08779899775981903,
                0.05057939514517784,
                -0.010193190537393093,
                0.056454285979270935,
                0.026749227195978165,
                -0.0015187497483566403,
                -0.039647094905376434,
                0.04525359347462654,
                -0.04033047333359718,
                0.05944854021072388,
                -0.026460181921720505,
                0.01805906370282173,
                -0.022074377164244652,
                -0.07879912853240967,
                0.015030669048428535,
                0.01665496826171875,
                -0.028932491317391396,
                -0.0004714264359790832,
                0.0236176997423172,
                -0.014264680445194244,
                0.030603036284446716,
                0.03462275490164757,
                0.019756415858864784,
                0.06305427849292755,
                0.01390789169818163,
                0.05593398958444595,
                -0.008066849783062935,
                0.04059304669499397,
                -0.030329488217830658,
                -0.04777438938617706,
                0.02108050510287285,
                0.024504121392965317,
                0.06422773003578186,
                0.022601906210184097,
                -0.0638548731803894,
                0.05056602880358696,
                0.020084908232092857,
                0.035187333822250366,
                -0.028737230226397514,
                -0.028327111154794693,
                -0.007645042613148689,
                0.012445653788745403,
                -0.009151402860879898,
                -0.011326114647090435,
                -0.018095262348651886,
                0.044744815677404404,
                -0.009961497969925404,
                -0.032178301364183426,
                0.005343608558177948,
                -0.017292380332946777,
                0.0017643372993916273,
                -0.018169637769460678,
                0.043022338300943375,
                -0.015553947538137436,
                0.027632219716906548,
                -0.019650420174002647,
                0.007889796979725361,
                0.0016592105384916067,
                0.02156795933842659,
                0.003692026250064373,
                -0.005860387813299894,
                -0.004622266162186861,
                -0.032443784177303314,
                0.043911177664995193,
                0.044157497584819794,
                5.892716581001878e-05,
                -0.004624685738235712,
                -0.003919541835784912,
                0.04459773004055023,
                0.0024039933923631907,
                0.018832799047231674,
                -0.035896752029657364,
                0.018447065725922585,
                0.0689253881573677,
                0.024338344112038612,
                0.030407626181840897,
                -0.0740952119231224,
                0.08528788387775421,
                0.04871736094355583,
                -0.010802279226481915,
                0.026770738884806633,
                -0.006631834432482719,
                -0.038829702883958817,
                -0.0035688430070877075,
                -0.07472096383571625,
                -0.004367809742689133,
                -0.03079434297978878,
                -0.0038749161176383495,
                -0.010034940205514431,
                0.016490543261170387,
                0.029825368896126747,
                -0.01734147220849991,
                0.023685049265623093,
                -0.04547377675771713,
                0.0705721527338028,
                0.003333191154524684,
                -0.02328863926231861,
                -0.01463280152529478,
                -0.009422946721315384,
                -0.028913402929902077,
                0.0049230558797717094,
                0.010784177109599113,
                -0.01202313881367445,
                -0.04134524241089821,
                0.027105307206511497,
                -0.055662184953689575,
                -0.022164547815918922,
                0.009940491057932377,
                0.030658908188343048,
                0.0018771549221128225,
                0.01931670866906643,
                0.001050348742865026,
                0.011809634044766426,
                -0.013266072608530521,
                -0.02664179913699627,
                0.037346720695495605,
                0.000935716147068888,
                -0.0463443398475647,
                -0.009147876873612404,
                -0.0024583125486969948,
                -0.06211712583899498,
                -0.025878243148326874,
                -0.009026129730045795,
                0.04037725552916527,
                0.010135904885828495,
                -0.06248854845762253,
                -0.06928203254938126,
                -0.00625120522454381,
                -0.03642581030726433,
                -0.04160141572356224,
                -0.0030730224680155516,
                -0.026198914274573326,
                0.07573319971561432,
                0.008853752166032791,
                -0.015813903883099556,
                0.04282490536570549,
                0.01386489812284708,
                -0.007275618612766266,
                0.03955612704157829,
                -0.007057509385049343,
                -0.013853355310857296,
                -0.035756103694438934,
                -0.016141876578330994,
                -0.0024725659750401974,
                -0.020012058317661285,
                -0.10052397102117538,
                0.004651508294045925,
                0.0007021686178632081,
                0.028669901192188263,
                0.01842627115547657,
                0.02749546617269516,
                -0.04184892773628235,
                -0.028841666877269745,
                -0.02446373924612999,
                -0.003973178565502167,
                0.03782205656170845,
                -0.02541530691087246,
                0.026875553652644157,
                0.02958141267299652,
                0.04411119967699051,
                -0.023103607818484306,
                -0.04406166076660156,
                -0.021916422992944717,
                0.04947814717888832,
                0.013730376027524471,
                0.06387189775705338,
                -0.03240511938929558,
                -0.027971722185611725,
                0.020666884258389473,
                0.03599155321717262,
                0.07541152834892273,
                0.03719744458794594,
                0.01687454991042614,
                -0.0007008942775428295,
                -0.026994137093424797,
                0.006811951752752066,
                0.050701484084129333,
                0.0946602150797844,
                -0.012398736551404,
                -0.016558725386857986,
                -0.07151933759450912,
                -0.006980161648243666,
                0.04821989685297012,
                -0.05322592332959175,
                0.07353384047746658,
                -0.015349469147622585,
                -0.02692895382642746,
                0.007104392163455486,
                0.002694016555324197,
                0.017843041568994522,
                -0.08295992016792297,
                0.033554431051015854,
                -0.016788920387625694,
                -0.07893161475658417,
                0.06607700884342194,
                -0.034047216176986694,
                -0.02978464588522911,
                -0.0016129049472510815,
                0.04802744463086128,
                -0.04598205164074898,
                -0.032945550978183746,
                -0.03129778429865837,
                -0.0018276668852195144,
                0.010082858614623547,
                -0.01798347383737564,
                -0.0010046109091490507,
                -0.005284290760755539,
                0.024378662928938866,
                -0.02627987414598465,
                -0.00249141501262784,
                0.011655786074697971,
                -0.006609390489757061,
                -0.05087755620479584,
                -0.009929696097970009,
                0.01716492511332035,
                0.059786614030599594,
                0.026874447241425514,
                -0.0008034534985199571,
                -0.012573844753205776,
                -0.044545114040374756,
                -0.005043243058025837,
                0.028523577377200127,
                0.0058086104691028595,
                0.025277724489569664,
                -0.03491009771823883,
                -0.04459454491734505,
                -0.035575851798057556,
                -0.027714928612113,
                -0.007901454344391823,
                -0.007957929745316505,
                0.0772361159324646,
                0.02077912539243698,
                -0.018122080713510513,
                -0.008503184653818607,
                0.057906005531549454,
                0.012078824453055859,
                -0.009781947359442711,
                0.009754694998264313,
                0.07193878293037415,
                0.04218405485153198,
                0.07188470661640167,
                -0.029653625562787056,
                0.09441930800676346,
                -0.022241024300456047,
                0.021216485649347305,
                -0.009057619608938694,
                -0.03977617248892784,
                0.04102865606546402,
                0.07488071918487549,
                0.033637888729572296,
                -0.02723068743944168,
                -0.020047495141625404,
                0.01429426297545433,
                0.002135810675099492,
                -0.06614706665277481,
                -0.015849793329834938,
                -0.03431130200624466,
                -0.005610975436866283,
                -0.034610047936439514,
                0.02202463708817959,
                -0.05622321739792824,
                -0.009979487396776676,
                -0.042261555790901184,
                0.023742282763123512,
                -0.016975071281194687,
                -0.025461547076702118,
                -0.054004132747650146,
                -0.023877432569861412,
                0.07599564641714096,
                -0.036500006914138794,
                -0.0019080399069935083,
                0.06467276811599731,
                -0.023407433182001114,
                0.06423112750053406,
                0.05602216348052025,
                -0.018168430775403976,
                -0.027679765596985817,
                0.059092916548252106,
                -0.004532135557383299,
                -0.05172593146562576,
                -0.021629415452480316,
                -0.016402622684836388,
                0.019839171320199966,
                0.042885445058345795,
                0.036094214767217636,
                0.010457037016749382,
                -0.02182595804333687,
                -0.014421320520341396,
                0.014622841961681843,
                0.06451525539159775,
                -0.0028746852185577154,
                0.0029748035594820976,
                0.01909894309937954,
                0.03935525566339493,
                0.023288344964385033,
                0.016416415572166443,
                -0.03906993195414543,
                0.03516353294253349,
                -0.000366421154467389,
                0.05396169796586037,
                -0.0008180176955647767,
                -0.005524730309844017,
                -0.027620859444141388,
                0.006257993169128895,
                -0.059713397175073624,
                -0.026623686775565147,
                -0.027404416352510452,
                -0.02974063530564308,
                0.027555329725146294,
                -0.0517105758190155,
                0.05173264816403389,
                -0.03285961598157883,
                0.038941457867622375,
                -0.05027679726481438,
                0.001408552983775735,
                -0.0011203887406736612,
                -0.11617540568113327,
                0.05527379363775253,
                -0.017603378742933273,
                -0.035985104739665985,
                -0.07645046710968018,
                -0.005229346454143524,
                -0.04249868169426918,
                0.0016656657680869102,
                0.00589339155703783,
                0.053310852497816086,
                0.019821444526314735,
                0.010332956910133362,
                -0.021798422560095787,
                -0.07367251068353653,
                -0.047599516808986664,
                0.013528185896575451,
                0.01907201111316681,
                -0.010558748617768288,
                0.0029091592878103256,
                -0.007972352206707,
                0.02584834210574627,
                0.022704117000102997,
                -0.025051580742001534,
                -0.06712677329778671,
                0.0756140723824501,
                -0.031081626191735268,
                -0.02696741186082363,
                -5.566065668163351e-33,
                -0.03526019677519798,
                0.0016539787175133824,
                -0.031231509521603584,
                0.022529883310198784,
                -0.017756497487425804,
                -0.033215656876564026,
                -0.025837630033493042,
                0.004484003875404596,
                0.03703064098954201,
                0.0024073165841400623,
                -0.0075908261351287365,
                0.010593844577670097,
                0.035044338554143906,
                0.015834379941225052,
                -0.002727229380980134,
                -0.06100788339972496,
                -0.0005054062348790467,
                0.00014041116810403764,
                -0.021685028448700905,
                -0.007264359388500452,
                0.0014373470330610871,
                0.006726967170834541,
                -0.0007091269944794476,
                -0.002813580445945263,
                0.027384687215089798,
                0.04147082567214966,
                -0.03844396770000458,
                0.023328887298703194,
                -0.004649469628930092,
                -0.02273160219192505,
                0.029939258471131325,
                -0.01961459219455719,
                0.015629928559064865,
                -0.0071314796805381775,
                -0.05970195308327675,
                0.07217883318662643,
                -0.025114068761467934,
                -0.03741312772035599,
                -0.05013686791062355,
                -0.01564115099608898,
                -0.04707194119691849,
                -0.0181172713637352,
                0.04637125879526138,
                -0.03370683267712593,
                -0.015569067560136318,
                0.0004555586783681065,
                -0.02834218181669712,
                0.016952374950051308,
                0.041128624230623245,
                -0.014277683570981026,
                -0.03240279480814934,
                -0.02846609614789486,
                0.01880962960422039,
                0.02888954058289528,
                0.0680742934346199,
                -0.0027188214007765055,
                0.0283135324716568,
                -0.05870947614312172,
                -0.02563760057091713,
                -0.009736329317092896,
                -0.030323220416903496,
                -0.025810185819864273,
                0.036613836884498596,
                -0.019451389089226723,
                0.013667010702192783,
                -0.0251297727227211,
                -0.10090091079473495,
                0.0699683427810669,
                0.0399748720228672,
                -0.03333701565861702,
                -0.018804529681801796,
                0.04725389927625656,
                -0.0066563887521624565,
                -0.05786081776022911,
                -0.03244367986917496,
                -0.046239420771598816,
                -0.05134103447198868,
                0.05626972019672394,
                -0.06923522800207138,
                0.070752814412117,
                -0.03635191544890404,
                -0.013698571361601353,
                0.010427197441458702,
                -0.0353163406252861,
                -0.03943111002445221,
                0.035676054656505585,
                -0.007555939257144928,
                0.06434766948223114,
                -0.01587115041911602,
                0.032116398215293884,
                -0.029785064980387688,
                -0.0021521965973079205,
                0.006506414618343115,
                0.017822779715061188,
                0.08493079990148544,
                0.011768991127610207,
                0.04547680541872978,
                -0.01537647470831871,
                0.010805182158946991,
                -0.005470050498843193,
                0.00025435516727156937,
                0.03697618469595909,
                -0.04495307430624962,
                0.010164677165448666,
                0.028516750782728195,
                -0.016387665644288063,
                -0.02974669635295868,
                3.9779093640390784e-05,
                -0.01800069399178028,
                0.018035683780908585,
                0.007907415740191936,
                0.10569489747285843,
                0.005442060064524412,
                0.016097936779260635,
                -0.015380704775452614,
                -0.02678050473332405,
                0.003952218219637871,
                -0.03064955584704876,
                -0.00042215827852487564,
                0.008406857959926128,
                0.06870872527360916,
                0.09849904477596283,
                0.005522937513887882,
                -0.012624308466911316,
                0.02609051764011383,
                0.010758502408862114,
                0.09087657928466797,
                0.007361145690083504,
                0.039456937462091446,
                -0.07044718414545059,
                0.01908002980053425,
                0.03008115664124489,
                2.043964713038804e-07,
                0.01780037209391594,
                -0.021994704380631447,
                0.012828575447201729,
                0.07538308948278427,
                0.06005510315299034,
                0.03308236226439476,
                -0.036915477365255356,
                0.052413951605558395,
                -0.046455804258584976,
                -0.05093837529420853,
                0.016865059733390808,
                -0.05355633422732353,
                -0.013408161699771881,
                0.027577992528676987,
                -0.009044349193572998,
                -0.10114293545484543,
                0.03342806175351143,
                0.0374908484518528,
                0.016673915088176727,
                -0.05316749960184097,
                0.02509155124425888,
                -0.01885576732456684,
                -0.049627553671598434,
                -0.04226267337799072,
                -0.01700860634446144,
                -0.0473843477666378,
                0.018085606396198273,
                0.005892836954444647,
                0.07442910224199295,
                0.0015037443954497576,
                0.07315748929977417,
                0.015001220628619194,
                0.06917249411344528,
                0.03338440880179405,
                -0.02599065750837326,
                -0.05028650909662247,
                0.03593175485730171,
                0.04843004047870636,
                -0.013078493997454643,
                -0.007743572350591421,
                0.0004145980055909604,
                -0.07027097791433334,
                0.007567118853330612,
                0.05083017796278,
                0.008156503550708294,
                -0.027874108403921127,
                0.009344642981886864,
                -0.006543838884681463,
                -0.029147550463676453,
                -0.023077303543686867,
                0.05099504813551903,
                0.028730520978569984,
                -0.023986343294382095,
                0.016839301213622093,
                0.011874294839799404,
                0.007958941161632538,
                0.030865907669067383,
                0.057288412004709244,
                0.04792958125472069,
                -0.011240248568356037,
                -0.010249202139675617,
                -0.07124770432710648,
                0.03756694495677948,
                0.004797604866325855,
                -0.023020312190055847,
                0.00919890683144331,
                -0.0038384629879146814,
                1.6473858820834665e-34,
                -0.014572467654943466,
                -0.0647093877196312,
                -0.039759617298841476,
                -0.03283614292740822,
                0.010262132622301579,
                -0.010395455174148083,
                -0.028271498158574104,
                -0.002503739669919014,
                0.01312106754630804,
                -0.015797242522239685,
                -0.018815066665410995,
                -0.007192652672529221,
                0.012454026378691196,
                0.006848921068012714,
                -0.060776323080062866,
                -0.0553671196103096,
                -0.03145632520318031,
                0.008504248224198818,
                0.017880728468298912,
                0.04513131454586983,
                -0.01840599626302719,
                0.012290116399526596,
                -0.01670895516872406,
                0.015578603371977806,
                0.006299508269876242,
                -0.020310619845986366,
                0.022577492520213127,
                0.04304646700620651,
                -0.01660332642495632,
                -0.024989508092403412,
                -0.04166512191295624,
                0.01570463925600052,
                0.0026995413936674595,
                0.02137027308344841,
                0.04071016237139702,
                0.004805328790098429,
                0.0005227485671639442,
                0.0063081602565944195,
                0.0278413537889719,
                -0.03800326585769653,
                -0.06572329998016357,
                -0.0004818422021344304,
                -0.007770195137709379,
                -0.037732601165771484,
                -0.008509844541549683,
                2.2180254291015444e-06,
                -0.013912498950958252,
                -0.006550933234393597,
                -0.004926383960992098,
                -0.009159930981695652,
                0.01301672775298357,
                0.027219947427511215,
                -0.005622427444905043,
                -0.01913464069366455,
                -0.010823696851730347,
                0.022652724757790565,
                -0.03638070449233055,
                0.010914613492786884,
                0.011809823103249073,
                -0.009033460170030594,
                0.017604127526283264,
                0.003023373195901513,
                -0.047241199761629105,
                -0.05714816972613335,
                -0.024851659312844276,
                0.05400253087282181,
                0.08429062366485596,
                0.02066446840763092,
                -0.0418795645236969,
                -0.00016320793656632304,
                0.0021478531416505575,
                0.0031697703525424004,
                -0.006372790317982435,
                0.004843888338655233,
                -0.03201853483915329,
                -0.015700284391641617,
                0.036445245146751404,
                0.06116991490125656,
                -0.006849062629044056,
                0.00668381666764617,
                -0.006462781690061092,
                0.06702335178852081,
                0.012310368940234184,
                0.0217771977186203,
                0.09215974807739258,
                -0.01937057077884674,
                0.027569938451051712,
                0.007869678549468517,
                0.006588888354599476,
                -0.058060336858034134,
                -0.012640460394322872,
                0.024392545223236084,
                0.062216419726610184,
                -0.004915150348097086,
                0.04713254049420357,
                0.0030065116006881,
                -0.03563316538929939,
                0.03767797350883484,
                -0.024543320760130882,
                0.02494765818119049,
                0.015278518199920654,
                -0.09576582163572311,
                -0.061029624193906784,
                -0.01017670426517725,
                0.013315292075276375,
                -0.007918303832411766,
                -0.012566298246383667,
                -0.028157616034150124,
                -0.014416108839213848,
                0.007384622469544411,
                -0.06782378256320953,
                -0.06877564638853073,
                0.0541154146194458,
                0.016519932076334953,
                0.01876232773065567,
                -0.02282310277223587,
                0.007308600004762411,
                0.06254512816667557,
                0.02688790112733841,
                0.009801078587770462,
                0.020034048706293106,
                -0.017422320321202278,
                0.02317143604159355,
                -0.0022723358124494553,
                0.10350126773118973,
                -0.02020038291811943,
                -0.04280159994959831,
                -0.0591006875038147,
                -0.007626782637089491,
                -0.013140402734279633,
                -0.022303884848952293,
                0.037803664803504944,
                0.03719930723309517,
                -0.0035140833351761103,
                0.015504459850490093,
                0.0457695871591568,
                -0.03188956901431084,
                -0.03379533812403679,
                0.006088279187679291,
                -0.03599182888865471,
                -0.02026800625026226,
                -0.00400639558210969,
                0.04592115432024002,
                0.008925237692892551,
                0.017817582935094833,
                0.026821181178092957,
                0.059238824993371964,
                -0.03879554942250252,
                0.00824499037116766,
                -0.011233287863433361,
                0.01746279187500477,
                0.03922894969582558,
                0.007396444212645292,
                -0.035837963223457336,
                -0.0013906302629038692,
                -0.02757624164223671,
                -0.037695690989494324,
                0.03748653829097748,
                -0.06394974887371063,
                -0.03787992522120476,
                0.032222945243120193,
                0.053865041583776474,
                -0.03376706689596176,
                -0.026017772033810616,
                -0.00579446367919445,
                0.019568581134080887,
                0.03321316838264465,
                -0.01910482533276081,
                -0.035930391401052475,
                -0.0682622641324997,
                0.00012667197734117508,
                0.0032150764018297195,
                -0.0480746366083622,
                0.032648853957653046,
                -0.015899786725640297,
                0.02359084039926529,
                -0.004778583999723196,
                0.05883263796567917,
                0.03417300060391426,
                -0.13568544387817383,
                0.005174695048481226,
                -0.006406263913959265,
                0.01506957970559597,
                -0.028246650472283363,
                0.013164878822863102,
                0.02018122747540474,
                0.08779100328683853,
                0.014501875266432762,
                0.021968575194478035,
                -0.060856893658638,
                -0.01918823830783367,
                0.0059059555642306805,
                -0.0973014086484909,
                0.018735894933342934,
                0.05009760335087776,
                0.014515450224280357,
                0.029654573649168015,
                -0.04071485251188278,
                0.012403810396790504,
                -0.0143278231844306,
                -0.02494075335562229,
                0.01735450141131878,
                0.030388711020350456,
                -0.06717323511838913,
                0.05589005723595619,
                0.006567545235157013,
                0.024821365252137184,
                0.04474295303225517,
                0.0024041745346039534,
                0.023307284340262413,
                -0.04716372489929199,
                -0.04148710146546364,
                -0.02426258474588394,
                0.04103556647896767,
                0.027649836614727974,
                -0.04070602357387543,
                -0.03600803017616272,
                0.027240151539444923,
                -0.026508837938308716,
                0.04055578634142876,
                0.07702035456895828,
                0.007451882120221853,
                -0.028826305642724037,
                0.06657955050468445,
                0.004805444274097681,
                0.04622872918844223,
                0.02888527885079384,
                0.00036657584132626653,
                0.052587904036045074,
                -0.018743671476840973,
                0.055860746651887894,
                -0.014291549101471901,
                -0.004446393810212612,
                -0.028958411887288094,
                -0.009777635335922241,
                -0.0085901552811265,
                0.011543422006070614,
                0.0329432375729084,
                0.050643470138311386,
                -0.04095670208334923,
                0.034823037683963776,
                -0.008974215015769005,
                1.775896453182213e-05,
                0.004365096800029278,
                -0.016040824353694916,
                0.04652620851993561,
                0.0031638622749596834,
                0.07029832154512405,
                0.027080198749899864,
                -0.010162031278014183,
                -0.026478825137019157,
                -0.012285423465073109,
                -0.01919860951602459,
                0.018658319488167763,
                -0.0184527188539505,
                0.10166548192501068,
                -0.00978695135563612,
                0.05816017836332321,
                0.0034693533089011908,
                0.015547409653663635,
                -0.028973598033189774,
                -0.010686833411455154,
                -0.026030704379081726,
                0.02951943129301071,
                0.011296289041638374,
                -0.017126645892858505,
                0.0127452677115798,
                -0.019377242773771286,
                -0.013045028783380985,
                0.06140311434864998,
                -0.025602074339985847,
                0.05485027655959129,
                -0.019317325204610825,
                0.019875476136803627,
                -0.0086843715980649,
                0.05028046295046806,
                -0.012379282154142857,
                0.0009226087713614106,
                -0.06829746812582016,
                -0.009998558089137077,
                0.029132135212421417,
                0.009692407213151455,
                0.00943378359079361,
                0.012292406521737576,
                0.010628394782543182,
                0.013366914354264736,
                0.003822961123660207,
                -0.09857960790395737,
                0.004806297365576029,
                0.003452833741903305,
                -0.009777909144759178,
                0.017511209473013878,
                -0.02054760977625847,
                -0.037464603781700134,
                0.004511096980422735,
                0.0011804754612967372,
                0.041459016501903534,
                -0.015926538035273552,
                0.00531721068546176,
                -0.00397033616900444,
                -0.12201618403196335,
                -0.02126532606780529,
                -0.002896652091294527,
                0.03689923137426376,
                0.02654998190701008,
                0.010790329426527023,
                0.004370817914605141,
                -0.08603449910879135,
                0.02022223174571991,
                -0.02445184625685215,
                -0.014072246849536896,
                -0.031566016376018524,
                -0.08392700552940369,
                0.005915651097893715,
                -0.014313705265522003,
                0.017635531723499298,
                -0.030128508806228638,
                0.02486131154000759,
                -0.060175858438014984,
                -0.024622539058327675,
                0.0027991628739982843,
                0.02164445072412491,
                0.04381531849503517,
                0.014821500517427921,
                -0.04257788881659508,
                -0.009301254525780678,
                0.0024557511787861586,
                0.012540781870484352,
                -0.007841527462005615,
                0.013677071779966354,
                -0.0154279675334692,
                0.00973117258399725,
                -0.006143347360193729,
                0.015575772151350975,
                -0.04758742079138756,
                0.05664641410112381,
                0.047112442553043365,
                -0.02885393053293228,
                -0.0013511065626516938,
                0.014474545605480671,
                -0.06706603616476059,
                0.035895030945539474,
                0.07159075140953064,
                -0.03737231716513634,
                0.037406258285045624,
                0.034682709723711014,
                -0.049163080751895905,
                0.02273261360824108,
                -0.0754198208451271,
                -0.0005440369714051485,
                0.007980301044881344,
                0.03857068717479706,
                -0.03465094417333603,
                0.04315880313515663,
                -0.054677899926900864,
                -0.02753334492444992,
                -0.05316774547100067,
                -0.00835055485367775,
                0.003422534791752696,
                0.019103560596704483,
                0.00671794731169939,
                0.006081907544285059,
                -0.06074981018900871,
                -0.01915227249264717,
                -0.03131880983710289,
                -0.005313325207680464,
                0.022593988105654716,
                0.018890991806983948,
                -0.010562519542872906,
                -0.011560156010091305,
                0.0026399625930935144,
                0.04490265995264053,
                -0.020948534831404686,
                0.029569389298558235,
                0.029208604246377945,
                -0.013834628276526928,
                0.0466594323515892,
                0.02040782943367958,
                -0.012813100591301918,
                -0.0010822126641869545,
                -0.036644551903009415,
                -0.029889844357967377,
                0.05082521215081215,
                -0.017199022695422173,
                0.05526435375213623,
                -0.03912576660513878,
                0.0035629039630293846,
                0.0217690858989954,
                0.01882079988718033,
                0.05653446167707443,
                -0.0017668084474280477,
                0.04213418811559677,
                -0.009756922721862793,
                -0.027384862303733826,
                0.01824343390762806,
                -0.018169716000556946,
                -0.041915226727724075,
                -0.04814034327864647,
                0.028222352266311646,
                -0.0785703957080841,
                -0.03628886863589287,
                0.02692762389779091,
                0.031472835689783096,
                -0.04080607369542122,
                0.012997704558074474,
                0.022537505254149437,
                0.014776632189750671,
                -0.0637681633234024,
                -0.012329483404755592,
                -0.03230326622724533,
                0.06140133738517761,
                0.00813317485153675,
                0.046011507511138916,
                0.10649385303258896,
                0.025336535647511482,
                -0.0017811370780691504,
                -0.00367174856364727,
                0.0873073861002922,
                -0.028286689892411232,
                0.02033630944788456,
                0.04455363005399704,
                0.07980301231145859,
                -0.04049498960375786,
                0.00035405904054641724,
                0.016381440684199333,
                -0.07387489825487137,
                -0.08693166077136993,
                0.030232809484004974,
                -0.08546534180641174,
                0.043096642941236496,
                0.02163861133158207,
                0.019277174025774002,
                -0.010806056670844555,
                -0.024553393945097923,
                -0.0068264794535934925,
                0.06268154084682465,
                0.018779484555125237,
                0.020353257656097412,
                0.0030056436080485582,
                0.034823160618543625,
                0.005024439189583063,
                -0.01605558581650257,
                -0.052464667707681656,
                -0.02352141961455345,
                0.029859991744160652,
                0.007422272115945816,
                -0.055384691804647446,
                0.08495507389307022,
                0.09301108866930008,
                0.019905265420675278,
                -0.005490806885063648,
                -0.026948021724820137,
                -0.0650559663772583,
                0.010246717371046543,
                0.03634507581591606,
                -0.003750016912817955,
                -0.04722100496292114,
                0.06600916385650635,
                0.06439416110515594,
                -0.055675238370895386,
                0.02766803279519081,
                -0.013033916242420673,
                -0.06363390386104584,
                0.07995010912418365,
                -0.01785437762737274,
                -0.041133396327495575,
                -0.042583443224430084,
                0.08123071491718292,
                0.06547688692808151,
                -0.008747680112719536,
                0.027405278757214546,
                0.0028472410049289465,
                0.03145970031619072,
                0.02218705788254738,
                0.028992842882871628,
                -0.023791026324033737,
                0.028120482340455055,
                -0.030942240729928017,
                -0.02966579794883728,
                0.03401120752096176,
                -0.05578786879777908,
                -0.03735072910785675,
                -0.012667796574532986,
                0.02327449806034565,
                0.02174743264913559,
                0.007753449957817793,
                0.0012263590469956398,
                0.022868691012263298,
                -0.023966126143932343,
                0.09735043346881866,
                -0.043605104088783264,
                -0.009677348658442497,
                0.0179196298122406,
                -0.0008115118253044784,
                -0.07543017715215683,
                0.031225839629769325,
                -0.015519426204264164,
                0.02546877972781658,
                0.00836164690554142,
                0.028058921918272972,
                -0.01909037120640278,
                -0.009542180225253105,
                -0.014344603754580021,
                0.0010308735072612762,
                0.014211543835699558,
                0.060818638652563095,
                -0.00043158847256563604,
                0.03194819390773773,
                -0.013405032455921173,
                -0.06644733995199203,
                0.062473736703395844,
                -0.04862267151474953,
                0.021486477926373482,
                0.010626486502587795,
                0.035635679960250854,
                -0.011674273759126663,
                0.022879520431160927,
                0.022117046639323235,
                0.007259089034050703,
                -0.01970549114048481,
                -0.049024488776922226,
                -0.011454682797193527,
                0.00947294570505619,
                -0.014402687549591064,
                -0.008114704862236977,
                -0.02446901425719261,
                0.044763483107089996,
                0.020248068496584892,
                0.022550752386450768,
                0.05272892862558365,
                0.008341017179191113,
                -0.01337876170873642,
                0.019115008413791656,
                -0.0053817410953342915,
                0.01700207218527794,
                -0.026065673679113388,
                -0.05258011817932129,
                0.0131023358553648,
                -0.022114595398306847,
                -0.00840317364782095,
                0.0314553864300251,
                0.023571444675326347,
                0.04251262918114662,
                -0.002809577388688922,
                0.028174366801977158,
                -0.029170602560043335,
                0.043591275811195374,
                0.010721436701714993,
                -0.0009165530209429562,
                -0.011488189920783043,
                0.002231151796877384,
                -0.005535867530852556,
                0.04435734078288078,
                0.003967772237956524,
                -0.04577643796801567,
                -0.019663583487272263,
                0.000999889220111072,
                -0.01767830364406109,
                -0.002595718251541257,
                0.008944889530539513,
                0.008243568241596222,
                0.044774897396564484,
                0.008886333554983139,
                -0.08370134979486465,
                -0.02653169073164463,
                -0.016926323994994164,
                -7.921389005226583e-33,
                -0.039473649114370346,
                0.008931828662753105,
                -0.007499200291931629,
                -0.02498527429997921,
                -0.05862804129719734,
                0.05192391574382782,
                -0.03134138882160187,
                -0.002559854183346033,
                -0.035733748227357864,
                -0.0348777249455452,
                -0.001297720824368298,
                -0.03315795585513115,
                0.030836472287774086,
                0.02716175839304924,
                0.03350343555212021,
                -0.005266654305160046,
                0.017999665811657906,
                -0.0037631369195878506,
                -0.011831612326204777,
                0.004694231785833836,
                -0.00765267014503479,
                0.018479837104678154,
                0.005729116499423981,
                0.022726863622665405,
                -0.024319760501384735,
                -0.005485237576067448,
                -0.036312784999608994,
                0.06270404905080795,
                -0.015185225754976273,
                -0.06277258694171906,
                -0.023096531629562378,
                -0.030475841835141182,
                -0.03992876037955284,
                0.011528665199875832,
                0.058571089059114456,
                0.002883917186409235,
                -0.027336569502949715,
                -0.03032175824046135,
                -0.01844300515949726,
                -0.028901536017656326,
                -0.05113937705755234,
                0.006525750737637281,
                0.06532828509807587,
                0.01393254566937685,
                -0.019492927938699722,
                -0.00934217032045126,
                -0.02833031676709652,
                0.003805741434916854,
                0.00023441195662599057,
                0.07183775305747986,
                -0.03262440115213394,
                0.028778117150068283,
                0.01735621690750122,
                0.0024479427374899387,
                0.0046805329620838165,
                0.03538534417748451,
                -0.012332241050899029,
                -0.03044632449746132,
                -0.018269287422299385,
                -0.02927151694893837,
                -0.03821457549929619,
                0.009669538587331772,
                -0.01912545971572399,
                -0.02197819948196411,
                0.05793379992246628,
                0.08569151908159256,
                0.1318909078836441,
                -0.003401970723643899,
                0.041210971772670746,
                0.022810081019997597,
                -0.04243476316332817,
                0.042217910289764404,
                -0.014499262906610966,
                -0.020274752750992775,
                -0.06727910786867142,
                -0.04680870845913887,
                0.05176553502678871,
                0.006181272678077221,
                -0.04959024116396904,
                -0.000692007306497544,
                -0.025339094921946526,
                -0.022976823151111603,
                -0.048707034438848495,
                -0.022761903703212738,
                0.059372272342443466,
                -0.01255823951214552,
                -0.033583931624889374,
                -0.0782788023352623,
                -0.01580832153558731,
                -0.01463051326572895,
                -0.004736501257866621,
                0.02104230970144272,
                0.012771383859217167,
                0.0331108495593071,
                0.013140086084604263,
                -0.03563951328396797,
                -0.002902831183746457,
                0.03856968879699707,
                -0.01156102679669857,
                -0.01516222208738327,
                0.015327238477766514,
                -0.04237540811300278,
                -0.03319436311721802,
                0.040432270616292953,
                0.002033774508163333,
                0.00351669080555439,
                0.045341212302446365,
                -0.007760050240904093,
                -0.0571959987282753,
                -0.025493629276752472,
                -0.012538155540823936,
                -0.005498241633176804,
                -0.006333582103252411,
                -0.0023535541258752346,
                0.014393042773008347,
                0.04111504182219505,
                -0.008343145251274109,
                -0.03721851110458374,
                -0.034035809338092804,
                -0.03289061784744263,
                0.01951119862496853,
                -0.04708774387836456,
                -0.046030063182115555,
                0.01720154657959938,
                -0.03769077733159065,
                0.00658976286649704,
                -0.0027606822550296783,
                -0.07913873344659805,
                -0.09048811346292496,
                -0.036962952464818954,
                -0.024693788960576057,
                -0.022366123273968697,
                2.9272140977809613e-07,
                -0.03950250893831253,
                -0.0014144193846732378,
                -0.00016147388669196516,
                -0.038019679486751556,
                0.0002858669904526323,
                0.009346033446490765,
                0.008141579106450081,
                0.004706286359578371,
                -0.06008090823888779,
                0.006981393788009882,
                0.060519516468048096,
                -0.045171674340963364,
                0.03749333322048187,
                0.01075716782361269,
                0.03849831596016884,
                -0.0752962976694107,
                -0.034682393074035645,
                0.01619991473853588,
                0.022802427411079407,
                0.049475524574518204,
                -0.0037175144534558058,
                -0.03329138085246086,
                -0.017881100997328758,
                -0.021364133805036545,
                0.04437394067645073,
                -0.025367069989442825,
                -0.0075956061482429504,
                -0.004591624718159437,
                0.012873230502009392,
                0.027300288900732994,
                -0.12535764276981354,
                0.00877011101692915,
                0.0077323317527771,
                -0.03146476298570633,
                0.041892919689416885,
                -0.059316083788871765,
                0.0754372626543045,
                -0.03672739863395691,
                -0.037128038704395294,
                0.05458110570907593,
                -0.081934355199337,
                0.029071247205138206,
                -0.0015069354558363557,
                -0.009799342602491379,
                -0.012834182009100914,
                0.011142170056700706,
                -0.004030609503388405,
                0.008430905640125275,
                -0.06652672588825226,
                -0.045520883053541183,
                -0.051683567464351654,
                -0.00026870955480262637,
                0.035953111946582794,
                0.023701857775449753,
                -0.02566032111644745,
                0.004910223186016083,
                0.005423202645033598,
                -0.014345592819154263,
                -0.00919454824179411,
                -0.0033556008711457253,
                0.015031795017421246,
                0.012751458212733269,
                0.005906571634113789,
                0.1188291534781456,
                -0.0024942310992628336,
                -0.006100293714553118,
                0.011744201183319092,
                2.261630697584186e-34,
                0.041330497711896896,
                0.016493480652570724,
                0.017752768471837044,
                0.02331288531422615,
                0.025645138695836067,
                0.003765122266486287,
                0.05346088483929634,
                -0.07566554099321365,
                0.0007337399292737246,
                -0.021613458171486855,
                -0.030917201191186905,
                0.039103444665670395,
                0.01783054508268833,
                -0.015689801424741745,
                0.00046260308590717614,
                0.019110139459371567,
                0.01792030595242977,
                -0.01933225616812706,
                0.009540536440908909,
                -0.028341693803668022,
                -0.016504110768437386,
                0.05814353749155998,
                0.10025900602340698,
                -0.01852591335773468,
                0.00011578902922337875,
                0.008929171599447727,
                -0.030281584709882736,
                0.022684548050165176,
                -0.024946855381131172,
                -0.07540597766637802,
                0.0068220230750739574,
                -0.02700936608016491,
                -0.01304580271244049,
                0.045045528560876846,
                -0.001958264270797372,
                0.04162708297371864,
                0.02134578861296177,
                -0.026091299951076508,
                0.03345276787877083,
                0.02493683248758316,
                -0.008401826024055481,
                0.04136212170124054,
                -0.031040284782648087,
                0.03716389089822769,
                -0.04471323639154434,
                1.6723389535400202e-06,
                0.008457669988274574,
                -0.05147435516119003,
                -0.04106726497411728,
                0.04132840782403946,
                0.032358139753341675,
                0.045158278197050095,
                -0.018368758261203766,
                -0.030568111687898636,
                0.024377526715397835,
                0.027235999703407288,
                -0.03022167459130287,
                0.05135589465498924,
                -0.036491937935352325,
                -0.018380846828222275,
                -0.017587125301361084,
                -0.0020051284227520227,
                0.010659256018698215,
                -0.09297901391983032,
                -0.0185884777456522,
                -0.04902810603380203,
                0.04949965700507164,
                0.043627817183732986,
                -0.028235353529453278,
                0.04246218875050545,
                0.044989440590143204,
                -0.05603966489434242,
                0.004250283818691969,
                -0.01811901293694973,
                0.025655902922153473,
                0.02412581816315651,
                0.027954233810305595,
                0.10346566140651703,
                -0.007706157397478819,
                0.0066089872270822525,
                -0.008667657151818275,
                0.050199978053569794,
                0.05203323811292648,
                0.011503277346491814,
                0.05359852686524391,
                0.012141959741711617,
                -0.016619360074400902,
                0.016297196969389915,
                0.007564825471490622,
                0.0070841494016349316,
                -0.0400533527135849,
                -0.016397535800933838,
                0.04602109640836716,
                -0.013916406780481339,
                0.06119247525930405,
                -0.00580104673281312,
                0.05057261139154434,
                -0.037097323685884476,
                -0.018992817029356956,
                -0.029452107846736908,
                -0.0011819450883194804,
                0.026536229997873306,
                0.015890546143054962,
                -0.012980584055185318,
                0.057708464562892914,
                -0.015501328743994236,
                0.008048120886087418,
                0.007886480540037155,
                0.0029853072483092546,
                0.02442098967730999,
                0.002345841843634844,
                -0.06145990267395973,
                0.01549914013594389,
                0.02960219234228134,
                0.044721201062202454,
                0.06759006530046463,
                -0.014531712979078293,
                -0.018460901454091072,
                -0.030804995447397232,
                0.025039339438080788,
                -0.01634206809103489,
                -0.008822137489914894,
                -0.02095390111207962,
                -0.004659469239413738,
                -0.00550905941054225,
                0.003724598791450262,
                0.003335347631946206,
                0.014352095313370228,
                -0.027170633897185326,
                -0.013734553940594196,
                -6.89057560521178e-05,
                0.02912077307701111,
                0.01927853189408779,
                0.00912406574934721,
                -0.0004807794466614723,
                0.028611959889531136,
                -0.0003550653636921197,
                -0.07550156116485596,
                0.041701123118400574,
                0.01323685236275196,
                0.05379493907094002,
                0.007339601404964924,
                -0.042560774832963943,
                0.0019764145836234093,
                -0.037486374378204346,
                -0.011836189776659012,
                0.08758987486362457,
                0.052828192710876465,
                -0.02517235092818737,
                -0.11555373668670654,
                -0.0027419128455221653,
                0.039258576929569244,
                0.015730077400803566,
                -0.03253140300512314,
                0.04896926134824753,
                -0.02073017694056034,
                -0.06936194002628326,
                0.01014871709048748,
                -0.0020652252715080976,
                -0.010501138865947723,
                -0.0010927090188488364,
                -0.051112882792949677,
                -0.03029356710612774,
                -0.032492056488990784,
                0.003511598566547036,
                0.11583264917135239,
                0.05716945230960846,
                0.04060296341776848,
                -0.007732347585260868,
                0.005625178571790457,
                0.03235436603426933,
                0.012886191718280315,
                0.015245238319039345,
                0.010326752439141273,
                -0.0020280906464904547,
                -0.011272931471467018,
                0.006107416469603777,
                0.06208520010113716,
                -0.0011608392233029008,
                -0.11100353300571442,
                0.0049929628148674965,
                -0.0022762685548514128,
                -0.0077743385918438435,
                -0.018839335069060326,
                0.0772605761885643,
                0.01605900190770626,
                -0.0619509257376194,
                0.010711416602134705,
                -0.044438667595386505,
                -0.054495468735694885,
                0.03751334175467491,
                -0.08450351655483246,
                -0.08393925428390503,
                0.030392084270715714,
                0.002933716867119074,
                0.03722206875681877,
                -0.012151253409683704,
                0.03050742670893669,
                0.023325135931372643,
                -0.030255693942308426,
                -0.025863979011774063,
                0.03309044986963272,
                0.021348966285586357,
                -0.025749603286385536,
                -0.029575470834970474,
                -0.007411068305373192,
                -0.029719261452555656,
                0.008607928641140461,
                -0.0021459877025336027,
                -0.027070708572864532,
                -0.005729827098548412,
                0.008521840907633305,
                -0.005615327972918749,
                0.054327383637428284,
                0.013121834956109524,
                -0.09584631770849228,
                -0.0022155477199703455,
                0.02787703089416027,
                -0.03299347683787346,
                -0.061088062822818756,
                -0.03440823033452034,
                -0.0005799321224913001,
                0.00824658665806055,
                0.02152453176677227,
                0.012790322303771973,
                -0.03356111794710159,
                -0.008577925153076649,
                0.016297325491905212,
                0.01665540225803852,
                -0.041303519159555435,
                0.05806221440434456,
                0.026136280968785286,
                -0.005508434493094683,
                0.006443065125495195,
                0.025847097858786583,
                0.02752888761460781,
                -0.050423845648765564,
                -0.016471905633807182,
                0.02512521669268608,
                -0.03507091850042343,
                -0.022028392180800438,
                -0.009591428562998772,
                0.015720106661319733,
                -0.0013205469585955143,
                0.010204551741480827,
                -0.05294715240597725,
                0.02148955501616001,
                0.10391150414943695,
                0.0518675297498703,
                0.013363038189709187,
                -0.05053697153925896,
                -0.016963474452495575,
                0.015037963166832924,
                -0.011654146946966648,
                -0.024386167526245117,
                0.016951505094766617,
                0.0009507964714430273,
                0.0404215008020401,
                0.011136481538414955,
                -0.0001302484015468508,
                -0.0011131168575957417,
                -0.016159700229763985,
                -0.030555536970496178,
                0.03282584622502327,
                0.007389643229544163,
                -0.006165261380374432,
                -0.006174126639962196,
                -0.017943480983376503,
                0.019131464883685112,
                -0.05878475680947304,
                -0.03667295351624489,
                -0.03304488956928253,
                -0.015080041252076626,
                -0.028694352135062218,
                0.0042875600047409534,
                0.024331366643309593,
                -0.05164361000061035,
                0.021679623052477837,
                -0.011382287368178368,
                0.023239854723215103,
                -0.022519774734973907,
                0.004697010386735201,
                0.035345952957868576,
                0.03853880241513252,
                0.009059511125087738,
                -0.008245264180004597,
                0.02878504991531372,
                -0.06639515608549118,
                0.020812610164284706,
                -0.009324041195213795,
                0.003965660464018583,
                0.014450008980929852,
                -0.012214047834277153,
                -0.034153807908296585,
                0.0080862483009696,
                -0.002251989673823118,
                0.05962899699807167,
                0.017968008294701576,
                -0.09011827409267426,
                -0.055869635194540024,
                0.04688812047243118,
                -0.004615468438714743,
                -0.05257704108953476,
                0.028026200830936432,
                -0.02388438582420349,
                -0.0559435598552227,
                0.01632389985024929,
                -0.018245240673422813,
                -0.05024644359946251,
                0.011921078898012638,
                0.013845540583133698,
                -0.028883779421448708,
                -0.011775031685829163,
                0.0024499446153640747,
                -0.026598075404763222,
                0.024218527600169182,
                0.01621943525969982,
                0.08911748230457306,
                0.01666039042174816,
                0.004193362779915333,
                0.0031938632018864155,
                -0.06067279726266861,
                0.0015406887978315353,
                0.04638152942061424,
                0.13753585517406464,
                -0.011956928297877312,
                0.044002093374729156,
                -0.03314429894089699,
                -0.05991015210747719,
                0.06258683651685715,
                0.0891365259885788,
                0.00709094014018774,
                -0.007266113068908453,
                0.0036147695500403643,
                0.022075356915593147,
                0.011087505146861076,
                -0.015316703356802464,
                -0.0015034310054033995,
                0.005453906953334808,
                -0.007928949780762196,
                -0.04943099617958069,
                -0.026593666523694992,
                0.07623326033353806,
                -0.048096735030412674,
                0.027113107964396477,
                -0.03496520221233368,
                -0.0314306765794754,
                0.005346580408513546,
                -0.10043147206306458,
                -0.0492447167634964,
                0.0062209488824009895,
                0.02228427864611149,
                -0.046602003276348114,
                0.0038359591271728277,
                0.03081434965133667,
                0.02218788117170334,
                0.011583796702325344,
                0.004395086318254471,
                0.05830726772546768,
                -0.044357724487781525,
                0.016531620174646378,
                0.03505894914269447,
                -0.017674004659056664,
                -0.012010659091174603,
                -0.020886804908514023,
                -0.010496659204363823,
                0.051376886665821075,
                0.02471105568110943,
                0.051578305661678314,
                0.006617800798267126,
                -0.015271581709384918,
                -0.028114231303334236,
                0.006299725733697414,
                -0.009890959598124027,
                -0.044946666806936264,
                0.021961050108075142,
                0.036789145320653915,
                0.023890338838100433,
                0.034706950187683105,
                -0.017769362777471542,
                -0.0023552279453724623,
                -0.044775739312171936,
                -0.009425141848623753,
                -0.02021211013197899,
                0.015935275703668594,
                -0.017240220680832863,
                0.06714413315057755,
                0.018913699313998222,
                -0.047600071877241135,
                -0.039071790874004364,
                -0.022958556190133095,
                0.025260252878069878,
                0.034181877970695496,
                0.044352784752845764,
                0.013166740536689758,
                -0.031531136482954025,
                0.00728295324370265,
                -0.014851056970655918,
                0.007751461584120989,
                0.0399998277425766,
                -0.0006588075775653124,
                0.006734844762831926,
                -0.1001991257071495,
                -0.03825978562235832,
                0.01908743567764759,
                -0.0035217213444411755,
                -0.065404012799263,
                0.016187287867069244,
                -0.024332284927368164,
                0.03268996253609657,
                -0.0026203289162367582,
                0.03653578832745552,
                0.017941048368811607,
                -0.009482107125222683,
                -0.0022843899205327034,
                -0.038745615631341934,
                -0.045379240065813065,
                0.13212136924266815,
                -0.001349722035229206,
                -0.038167282938957214,
                0.005259487312287092,
                0.042790837585926056,
                -0.04320434853434563,
                0.011599683202803135,
                0.019079942256212234,
                -0.03866627812385559,
                -0.038800325244665146,
                -0.030387530103325844,
                0.016339464113116264,
                0.005498297046869993,
                -0.033251482993364334,
                0.009549462236464024,
                0.051493626087903976,
                0.021343840286135674,
                -0.016252629458904266,
                0.060975126922130585,
                0.018996799364686012,
                0.02945827692747116,
                0.06377008557319641,
                -0.00452740304172039,
                0.0015924255130812526,
                -0.015293881297111511,
                -0.054851233959198,
                -0.04459134861826897,
                -0.009941327385604382,
                0.011419649235904217,
                0.03746332973241806,
                -0.005709066987037659,
                -0.05611606687307358,
                -0.029307393357157707,
                -0.010537685826420784,
                0.00022174514015205204,
                0.09761318564414978,
                -0.017593324184417725,
                0.0008672495605424047,
                -0.04512465372681618,
                0.05017586052417755,
                -0.015717117115855217,
                0.00783805176615715,
                -0.017506636679172516,
                0.019093884155154228,
                0.03822968527674675,
                0.0044061895459890366,
                -0.003991725854575634,
                0.00932601559907198,
                0.10749326646327972,
                -0.00623508682474494,
                -0.00359953660517931,
                -0.007987742312252522,
                -0.04697628691792488,
                0.019082922488451004,
                0.069701187312603,
                0.027541551738977432,
                -0.046086251735687256,
                0.04497923329472542,
                0.03556040674448013,
                -0.010180656798183918,
                -0.017315002158284187,
                -0.01189431268721819,
                0.007469811011105776,
                -0.007140222005546093,
                0.030499856919050217,
                -0.019323810935020447,
                -0.012675561010837555,
                0.06621498614549637,
                0.0470457524061203,
                0.0005147016490809619,
                0.05362866818904877,
                -0.06677796691656113,
                0.04174797236919403,
                0.002714476315304637,
                -0.006810813210904598,
                0.03261833265423775,
                -0.027881020680069923,
                -0.03313172236084938,
                -0.020227208733558655,
                -0.0019169863080605865,
                0.05507080256938934,
                -0.0034311735071241856,
                -0.0019993844907730818,
                -0.004883386194705963,
                0.006461040582507849,
                0.00016288331244140863,
                -0.012030832469463348,
                0.030453449115157127,
                -0.007545939646661282,
                -0.03532661497592926,
                -0.023217109963297844,
                0.022098980844020844,
                -0.01900520734488964,
                -0.07898479700088501,
                -0.006103182677179575,
                0.023604655638337135,
                -0.03718718886375427,
                -0.06811091303825378,
                0.044115155935287476,
                0.000935944844968617,
                -0.0036203181371092796,
                0.018228592351078987,
                0.025023620575666428,
                0.038332499563694,
                0.010224557481706142,
                -0.012441462837159634,
                0.07103010267019272,
                0.01814712956547737,
                0.05166663974523544,
                0.07394525408744812,
                -0.011608931235969067,
                -0.031293828040361404,
                -0.02890637330710888,
                -0.004136556759476662,
                0.003962414339184761,
                -0.012619849294424057,
                0.0006303919944912195,
                -0.01198093593120575,
                0.01956622302532196,
                0.03541974350810051,
                0.020643511787056923,
                -0.026936590671539307,
                -0.021418005228042603,
                0.010143928229808807,
                0.034712232649326324,
                -0.025205103680491447,
                -0.04357185959815979,
                0.021436240524053574,
                -0.012388435192406178,
                0.11158745735883713,
                -0.004669129382818937,
                0.07321351021528244,
                0.015388553962111473,
                0.057728882879018784,
                -0.03870776295661926,
                0.041886571794748306,
                0.005495449528098106,
                0.020896852016448975,
                0.002068348927423358,
                -0.034342531114816666,
                -0.0333404578268528,
                -0.031644947826862335,
                0.0070350016467273235,
                -0.007011278998106718,
                -0.0070979176089167595,
                -0.01152451615780592,
                -0.06265714019536972,
                0.04480069503188133,
                -0.013155371882021427,
                -5.9119578196286864e-33,
                -0.08608178049325943,
                -0.08528710901737213,
                0.03606690466403961,
                -0.004392681643366814,
                -0.02923099510371685,
                -0.047438476234674454,
                0.025525381788611412,
                0.019247207790613174,
                -0.022504683583974838,
                -0.02450549043715,
                -0.012091433629393578,
                -0.026907028630375862,
                0.014515592716634274,
                0.009211286902427673,
                0.03998580574989319,
                0.02020883560180664,
                -0.011978558264672756,
                0.022297848016023636,
                -0.016337042674422264,
                -0.0661122277379036,
                0.034412968903779984,
                0.022702660411596298,
                0.003795874072238803,
                0.02911149151623249,
                -0.027379818260669708,
                0.03469105064868927,
                -0.015817828476428986,
                -0.05451682209968567,
                -0.04353509843349457,
                -0.025611810386180878,
                0.013628695160150528,
                -0.03350718319416046,
                0.018552472814917564,
                -0.06814559549093246,
                -0.033590495586395264,
                0.07972199469804764,
                -0.03629567474126816,
                -0.024132395163178444,
                0.010318215936422348,
                -0.028829285874962807,
                -0.027059845626354218,
                -0.036675844341516495,
                0.024643326178193092,
                0.02370089292526245,
                0.020400380715727806,
                -0.025121742859482765,
                -0.0019852917175740004,
                -0.046883463859558105,
                -0.012907423079013824,
                -0.013553672470152378,
                -0.023320268839597702,
                -0.023031184449791908,
                -0.027635369449853897,
                0.07319268584251404,
                -0.0056121679954230785,
                0.001154248253442347,
                -0.0030676249880343676,
                0.09617443382740021,
                -0.041324060410261154,
                -0.020254023373126984,
                0.003653100458905101,
                0.022222843021154404,
                0.001138335675932467,
                0.018405744805932045,
                -0.006678043864667416,
                -0.007462760433554649,
                -0.0935182124376297,
                0.029090994969010353,
                -0.01265222392976284,
                -0.020225893706083298,
                0.012274588458240032,
                0.029930245131254196,
                -0.07710684835910797,
                -0.0673908144235611,
                -0.0885525792837143,
                -0.038313690572977066,
                0.009535213001072407,
                0.013642634265124798,
                0.03154468163847923,
                0.00883815810084343,
                -0.025273984298110008,
                0.011790034361183643,
                0.08114539831876755,
                -0.030407527461647987,
                -0.011403449811041355,
                0.032226141542196274,
                -0.011824504472315311,
                0.09639573842287064,
                0.021390967071056366,
                0.028746390715241432,
                -0.03147095441818237,
                -0.025048665702342987,
                -0.02849365398287773,
                0.004122862126678228,
                0.01649840921163559,
                0.008594918064773083,
                -0.011414759792387486,
                -0.027766838669776917,
                -0.010870593599975109,
                0.02610953152179718,
                -0.0185721293091774,
                0.020734377205371857,
                -0.08268135040998459,
                0.028367403894662857,
                0.008061219938099384,
                0.010642020963132381,
                -0.02174333669245243,
                -0.026863103732466698,
                -0.04789559170603752,
                0.013328622095286846,
                -0.003893153741955757,
                0.0009204329107888043,
                -0.008130515925586224,
                -0.057110488414764404,
                -0.03369462490081787,
                -0.042646076530218124,
                0.04201171174645424,
                -0.05564373731613159,
                -0.015762154012918472,
                -0.02574695646762848,
                0.06510245054960251,
                0.007613177876919508,
                0.01310347393155098,
                0.026925597339868546,
                0.026163816452026367,
                -0.0031863958574831486,
                0.0417291596531868,
                -0.04203369468450546,
                0.014673719182610512,
                0.02989189326763153,
                0.007279443554580212,
                0.014946416951715946,
                2.0733814665163663e-07,
                -0.00825142115354538,
                0.019902655854821205,
                0.007661263458430767,
                0.019467413425445557,
                0.03006792441010475,
                0.011975406669080257,
                -0.017268799245357513,
                0.006018869578838348,
                -0.027857448905706406,
                0.004410211928188801,
                0.03500063344836235,
                -0.06767825037240982,
                -0.022868089377880096,
                -0.0519152507185936,
                0.02137080207467079,
                -0.038924384862184525,
                -0.055447861552238464,
                -0.05550112947821617,
                -0.036375321447849274,
                -0.016299763694405556,
                -0.0280752070248127,
                0.025919470936059952,
                -0.009058935567736626,
                -0.00033902324503287673,
                -0.002520642476156354,
                0.013055144809186459,
                0.017400536686182022,
                -0.09430726617574692,
                0.06079624965786934,
                0.011368872597813606,
                0.0541241392493248,
                -0.00952194258570671,
                -0.019631018862128258,
                0.019258778542280197,
                -0.004952243063598871,
                -0.07458897680044174,
                0.027288341894745827,
                0.09073758870363235,
                0.012290440499782562,
                0.021685119718313217,
                0.006671118084341288,
                -0.02443392015993595,
                -0.06869129091501236,
                0.02805728279054165,
                -0.006189657840877771,
                0.048813655972480774,
                0.01513348426669836,
                0.026902159675955772,
                -0.06736357510089874,
                0.010194444097578526,
                -0.01367118675261736,
                0.005317829083651304,
                -0.01596716046333313,
                0.0018090710509568453,
                0.008442969061434269,
                -0.02850230783224106,
                -0.0028880031313747168,
                0.02897552214562893,
                0.08293717354536057,
                0.017758263275027275,
                -0.002626223722472787,
                -0.030352622270584106,
                -0.01872476190328598,
                0.015840783715248108,
                0.032672714442014694,
                -0.03064519539475441,
                -0.0710541158914566,
                5.956063423779744e-35,
                -0.0037271857727319,
                -0.04515266790986061,
                -0.052074357867240906,
                -0.046385232359170914,
                -0.00954412017017603,
                -0.000889000715687871,
                -0.022857526317238808,
                -0.010575293563306332,
                -0.013278898783028126,
                -0.012655476108193398,
                0.007920251227915287,
                -0.08746208250522614,
                -0.002414773451164365,
                0.0005239417660050094,
                -0.020867353305220604,
                -0.051365625113248825,
                -0.05142506957054138,
                -0.020783966407179832,
                -0.009462987072765827,
                -0.046332232654094696,
                0.004028527066111565,
                0.008268740028142929,
                0.003287162631750107,
                -0.04354844242334366,
                0.027501791715621948,
                0.03773502632975578,
                -0.009274202398955822,
                0.040374211966991425,
                -0.0035502465907484293,
                0.017088942229747772,
                0.014377799816429615,
                -0.03415476158261299,
                0.011226378381252289,
                -0.0009800830157473683,
                0.023113474249839783,
                0.023702962324023247,
                -0.013681324198842049,
                0.0005572290974669158,
                -0.03616471588611603,
                -0.030358416959643364,
                -0.028453277423977852,
                -0.013696150854229927,
                -0.010777936317026615,
                -0.06587827205657959,
                0.04131997004151344,
                2.3595866878167726e-06,
                -0.02834698185324669,
                0.017516059800982475,
                0.031763702630996704,
                -0.04070485383272171,
                -0.03310021385550499,
                0.07787693291902542,
                -0.07154322415590286,
                -0.012957555241882801,
                0.0002185932535212487,
                -0.00823269970715046,
                0.010350916534662247,
                0.0020392872393131256,
                0.01071683969348669,
                -0.04111465811729431,
                0.030473796650767326,
                0.008942043408751488,
                0.01946542039513588,
                -0.10728859156370163,
                -0.005189578048884869,
                0.12226339429616928,
                0.036070358008146286,
                -0.026772279292345047,
                -0.03549399971961975,
                -0.023681748658418655,
                0.017794214189052582,
                0.006329692434519529,
                0.01958725042641163,
                -0.021819287911057472,
                -0.03966139256954193,
                0.022691762074828148,
                0.07493780553340912,
                0.02984476462006569,
                0.04422283545136452,
                0.026321018114686012,
                0.029348693788051605,
                -0.022322488948702812,
                0.03046330250799656,
                0.012887977994978428,
                0.0792977511882782,
                -0.019813064485788345,
                0.002812175313010812,
                -0.010169247165322304,
                0.02663353644311428,
                -0.04063345119357109,
                -0.025936134159564972,
                -0.02942320890724659,
                0.028403520584106445,
                0.005786176770925522,
                0.06922326236963272,
                -0.01104024238884449,
                0.0039526973851025105,
                0.018110960721969604,
                -0.022386811673641205,
                -0.007645079400390387,
                -0.026029741391539574,
                -0.03242608532309532,
                0.030753137543797493,
                0.032749902456998825,
                0.023197781294584274,
                -0.03053361363708973,
                -0.0003290416207164526,
                0.017071975395083427,
                0.06713356822729111,
                0.015559375286102295,
                0.018278639763593674,
                0.01976316049695015,
                -0.002420311328023672,
                -0.015487228520214558,
                0.04726264253258705,
                -0.057606473565101624,
                -0.08705855906009674,
                0.05533166602253914,
                0.011055690236389637,
                0.06116605922579765,
                0.016326582059264183,
                0.009056620299816132,
                0.029194843024015427,
                0.04201185330748558,
                0.037284910678863525,
                -0.009163894690573215,
                -0.0188723374158144,
                0.03160838782787323,
                0.008114933967590332,
                -0.012977388687431812,
                -0.009715664200484753,
                0.02966701053082943,
                0.0335424467921257,
                0.029219193384051323,
                -0.015230601653456688,
                -0.025870729237794876,
                -0.0028524550143629313,
                0.01543773990124464,
                -0.04051682725548744,
                -0.031308338046073914,
                0.04303006827831268,
                -0.03671923279762268,
                -0.023414060473442078,
                -0.019271792843937874,
                0.0017887373687699437,
                0.0671805739402771,
                0.014888071455061436,
                0.027083078399300575,
                0.002891600364819169,
                -0.04926686733961105,
                0.014179172925651073,
                0.005343796219676733,
                -0.00031478411983698606,
                -0.032048389315605164,
                -0.023285673931241035,
                -0.013670127838850021,
                -0.043741244822740555,
                -0.0054949624463915825,
                -0.04034091904759407,
                -0.03245706111192703,
                -0.001004796358756721,
                0.06252055615186691,
                -0.018245764076709747,
                -0.0507577620446682,
                -0.02131108194589615,
                0.02761109173297882,
                0.0423009879887104,
                -0.009544300846755505,
                -0.027948647737503052,
                -0.04897662252187729,
                -0.006207481492310762,
                -0.02133362740278244,
                0.01920117437839508,
                -0.0389406755566597,
                -0.0599856898188591,
                0.008663088083267212,
                0.011248549446463585,
                -0.02896927483379841,
                0.014260934665799141,
                -0.013013993389904499,
                -0.023371489718556404,
                0.0176969263702631,
                0.007523502688854933,
                -0.02735016494989395,
                -0.08751218020915985,
                0.031139150261878967,
                0.042391642928123474,
                -0.06948225200176239,
                0.008346626535058022,
                -0.03893345966935158,
                -0.03034556843340397,
                -0.01134444773197174,
                -0.17011091113090515,
                -0.0007100142538547516,
                0.014931170269846916,
                -0.010858996771275997,
                0.03397293761372566,
                -0.00020267156651243567,
                0.06138766556978226,
                -0.023945003747940063,
                0.004285498987883329,
                0.05024060606956482,
                -0.02319910377264023,
                -0.015950925648212433,
                -0.0024857104290276766,
                -0.010673058219254017,
                -0.02866574190557003,
                0.02900969609618187,
                0.008474459871649742,
                -0.02421603724360466,
                -0.030780494213104248,
                -0.03120952844619751,
                0.01788409799337387,
                0.023830892518162727,
                0.008570418693125248,
                -0.045280639082193375,
                -0.036118533462285995,
                -0.07065490633249283,
                -0.037727829068899155,
                -0.12732307612895966,
                0.08945237100124359,
                -0.04529746249318123,
                -0.016872601583600044,
                0.07109556347131729,
                -0.01805652678012848,
                0.05197523161768913,
                0.010399571619927883,
                0.02087664045393467,
                0.015191596932709217,
                -0.049381669610738754,
                0.06781195104122162,
                0.011690148152410984,
                0.048821043223142624,
                0.019701367244124413,
                -0.007066212594509125,
                -0.005868987180292606,
                0.061535824090242386,
                0.007808235008269548,
                -0.0015797740779817104,
                -0.010078271850943565,
                0.026312274858355522,
                0.0339011587202549,
                0.03196430578827858,
                0.003658110508695245,
                0.016146698966622353,
                -0.022140245884656906,
                -0.06631962954998016,
                0.02577662095427513,
                -0.07015460729598999,
                0.004858390428125858,
                -0.03183865174651146,
                0.020437762141227722,
                -0.011742888018488884,
                -0.002159783151000738,
                -0.04247286915779114,
                0.15990924835205078,
                0.05515474081039429,
                -0.012602504342794418,
                0.03644033521413803,
                -0.02056196890771389,
                -0.005143458489328623,
                -0.04491477459669113,
                -0.018069976940751076,
                0.016329118981957436,
                0.001090699341148138,
                0.0525125153362751,
                0.04409906640648842,
                -0.03354025259613991,
                0.026646673679351807,
                -0.03202575072646141,
                -0.03292887285351753,
                0.02420220896601677,
                -0.01864158734679222,
                -0.014846500009298325,
                -0.03981689363718033,
                0.041785966604948044,
                0.01555178128182888,
                0.014630192890763283,
                -0.02131190337240696,
                0.01740938611328602,
                0.02481105551123619,
                0.04343724995851517,
                0.044920843094587326,
                -0.009072288870811462,
                -0.02657250314950943,
                -0.04331444203853607,
                -0.02598760463297367,
                0.039735592901706696,
                0.00734035624191165,
                -0.04610111936926842,
                -0.007524827495217323,
                0.01159637700766325,
                0.05409916490316391,
                0.014350821264088154,
                0.02033437415957451,
                0.00020306782971601933,
                0.004179616924375296,
                -0.007836194708943367,
                0.010264149866998196,
                -0.014094395563006401,
                0.005653280299156904,
                -0.13092435896396637,
                -0.019071782007813454,
                0.03813634440302849,
                0.0558997206389904,
                0.02950681373476982,
                0.014550783671438694,
                -0.06522863358259201,
                0.013864472508430481,
                0.00666405213996768,
                -0.02845645323395729,
                -0.032765526324510574,
                0.0030817186925560236,
                0.006568118464201689,
                -0.011146903969347477,
                -0.028788454830646515,
                0.01945403590798378,
                0.02601736970245838,
                -0.05252274125814438,
                0.008443471044301987,
                -0.03509011119604111,
                0.028992287814617157,
                -0.005375565029680729,
                0.01797308772802353,
                0.03603643551468849,
                0.04177761822938919,
                0.001043439726345241,
                -0.016772819682955742,
                -0.043050624430179596,
                -0.01432480476796627,
                -0.033180247992277145,
                0.004377282224595547,
                -0.051579978317022324,
                -0.015024212189018726,
                -0.00030356147908605635,
                0.007968880236148834,
                0.044429682195186615,
                -0.036671314388513565,
                -0.01427809800952673,
                0.0014123331056907773,
                -0.024180809035897255,
                0.06077280268073082,
                0.10551504045724869,
                -0.031742941588163376,
                0.04215327277779579,
                0.0005264812498353422,
                0.011693896725773811,
                0.007384968921542168,
                -0.038457345217466354,
                0.033418379724025726,
                0.01644255965948105,
                0.009004130028188229,
                -0.03654443100094795,
                -0.013538632541894913,
                -0.019792795181274414,
                -0.012556878849864006,
                0.030568815767765045,
                0.042897600680589676,
                0.013686505146324635,
                -0.045500487089157104,
                0.024445228278636932,
                0.022585373371839523,
                -0.002514610067009926,
                -0.013785804621875286,
                -0.04959261417388916,
                0.016084160655736923,
                -0.02298809587955475,
                -0.020672470331192017,
                0.009585455991327763,
                0.02928275801241398,
                0.05750551447272301,
                -0.04353141412138939,
                0.002230814192444086,
                -0.05505051836371422,
                -0.005842444486916065,
                -0.03134743869304657,
                0.050523724406957626,
                -0.04885654151439667,
                0.028094526380300522,
                0.01565195433795452,
                -0.013465147465467453,
                -0.04494169354438782,
                0.034549519419670105,
                0.011964432895183563,
                0.06455890089273453,
                0.010667268186807632,
                0.05168832838535309,
                0.015454676933586597,
                -0.04225844144821167,
                0.0763813927769661,
                -0.003375109750777483,
                0.031732767820358276,
                -0.027081547304987907,
                -0.027556972578167915,
                -0.0012778355740010738,
                -0.0053058527410030365,
                -0.0012778877280652523,
                -0.04629054665565491,
                -0.009540030732750893,
                -0.09250004589557648,
                -0.02289743721485138,
                0.04121001064777374,
                -0.050731122493743896,
                0.11812863498926163,
                0.01387304998934269,
                0.012802467681467533,
                -0.005456718150526285,
                -0.052602436393499374,
                0.007905048318207264,
                0.008333424106240273,
                0.016767259687185287,
                -0.008602097630500793,
                0.1256031095981598,
                0.059789445251226425,
                0.0025476180016994476,
                -0.006402072496712208,
                0.038929179310798645,
                -0.09550515562295914,
                -0.021326396614313126,
                0.030524229630827904,
                0.0406673438847065,
                -0.016806358471512794,
                -0.007398806978017092,
                0.01101631298661232,
                -0.002270142314955592,
                -0.012591306120157242,
                -0.05926007777452469,
                -0.005466684233397245,
                -0.07382386177778244,
                0.0009806567104533315,
                -0.02736596390604973,
                0.008408084511756897,
                -0.004066395107656717,
                -0.005886349827051163,
                -0.020591486245393753,
                0.0336521714925766,
                -0.0006672422750853002,
                0.045539114624261856,
                0.028054408729076385,
                0.04500709846615791,
                -0.05258769541978836,
                0.034365322440862656,
                -0.02518422342836857,
                -0.0014828211860731244,
                0.006486088037490845,
                0.05359233543276787,
                -0.08615963160991669,
                0.005435827188193798,
                0.04174964874982834,
                0.0033009331673383713,
                -0.06038995459675789,
                -0.040566328912973404,
                0.004051119554787874,
                0.032770782709121704,
                -0.0010717007098719478,
                0.002694604219868779,
                0.003884385572746396,
                -0.013623795472085476,
                0.052296556532382965,
                0.023287424817681313,
                -0.01648181490600109,
                -0.005269496235996485,
                -0.08672168850898743,
                0.06236601620912552,
                0.006984742823988199,
                0.04315020889043808,
                -0.045907024294137955,
                0.049434561282396317,
                0.06381522864103317,
                -0.00622737780213356,
                -0.021934444084763527,
                0.021905219182372093,
                0.03210977464914322,
                0.00782411266118288,
                -0.008971145376563072,
                0.025816425681114197,
                0.0039177555590868,
                0.02367972768843174,
                -0.013071892783045769,
                0.002721942262724042,
                -0.014512882567942142,
                -0.02820153906941414,
                -0.007995403371751308,
                0.0012033544480800629,
                0.052391838282346725,
                0.020949574187397957,
                -0.040909457951784134,
                0.03420544043183327,
                -0.01610257849097252,
                -0.06274650245904922,
                -0.04956385865807533,
                -0.018487589433789253,
                0.011524034664034843,
                -0.01757826656103134,
                0.04885886609554291,
                0.008711904287338257,
                -0.049132559448480606,
                0.05033555626869202,
                0.019416917115449905,
                0.03641011565923691,
                0.001314916298724711,
                0.0009967578807845712,
                -0.0256136916577816,
                0.035538554191589355,
                -0.005207014735788107,
                -0.004344466608017683,
                0.08058474957942963,
                0.02612859383225441,
                -0.04159264639019966,
                0.02634056657552719,
                -0.017408227548003197,
                -0.004442120436578989,
                0.0026552763301879168,
                0.01677454635500908,
                0.01845497637987137,
                0.016144871711730957,
                0.03227454796433449,
                -0.0008968219626694918,
                0.04148278757929802,
                -0.01971031352877617,
                -0.05552688613533974,
                -0.058177441358566284,
                0.00887489877641201,
                -0.050382789224386215,
                0.07852321118116379,
                0.01715018041431904,
                -0.02419867180287838,
                -0.022090427577495575,
                0.014478368684649467,
                0.04994988441467285,
                -0.0005176268750801682,
                -0.001998468767851591,
                -0.009313530288636684,
                0.014637842774391174,
                -0.01089243870228529,
                -0.004039791878312826,
                -0.03094787895679474,
                0.014495247043669224,
                -0.025248395279049873,
                0.006636886391788721,
                0.02653961069881916,
                -0.024471092969179153,
                -0.02860514633357525,
                0.027962321415543556,
                -0.0009247555863112211,
                -0.004913044162094593,
                -0.011983071453869343,
                0.03815915808081627,
                0.012761715799570084,
                -0.003340003080666065,
                0.01760057546198368,
                -0.08874019235372543,
                0.060146234929561615,
                0.029893625527620316,
                -0.0507928803563118,
                -0.008852219209074974,
                0.014786295592784882,
                0.01349261961877346,
                -0.0493067167699337,
                -0.008241910487413406,
                0.060670215636491776,
                0.03243248164653778,
                0.07607057690620422,
                -0.08562648296356201,
                0.0066806841641664505,
                -0.04105304181575775,
                -8.051540474433596e-33,
                0.014918765053153038,
                -0.04656349495053291,
                0.03444179147481918,
                -0.037330448627471924,
                -0.08048500865697861,
                -0.011504849418997765,
                -0.009215866215527058,
                0.013832622207701206,
                -0.013270560652017593,
                -0.041844386607408524,
                -0.001614176668226719,
                -0.04001808166503906,
                0.023534098640084267,
                -0.0020891837775707245,
                0.018266543745994568,
                -0.01635592058300972,
                -0.011294255964457989,
                0.04496871680021286,
                -0.02696608565747738,
                -0.016271131113171577,
                0.03166446089744568,
                0.03983817249536514,
                0.025759108364582062,
                0.060175277292728424,
                0.03481566905975342,
                -0.021008184179663658,
                -0.027977749705314636,
                0.052239980548620224,
                0.04823906719684601,
                -0.0340595543384552,
                -0.008976188488304615,
                0.022506821900606155,
                -0.017087897285819054,
                -0.082427017390728,
                0.01502811349928379,
                -0.031522247940301895,
                0.006827151402831078,
                -0.06939688324928284,
                -0.010718107223510742,
                -0.07926388829946518,
                -0.03304285183548927,
                0.026990633457899094,
                -0.014312045648694038,
                0.006528348196297884,
                -0.019439930096268654,
                0.03970232233405113,
                -0.027340684086084366,
                -0.04385193809866905,
                -0.010686674155294895,
                0.09550387412309647,
                -0.03819705918431282,
                0.055670931935310364,
                -0.010918570682406425,
                -0.011195211671292782,
                -0.016186701133847237,
                -0.02732136845588684,
                -0.009706730954349041,
                -0.05626999959349632,
                -0.044979896396398544,
                0.01774783618748188,
                0.007173775229603052,
                0.0251220241189003,
                -0.01089967880398035,
                0.0015566247748211026,
                0.02053060755133629,
                0.008065168745815754,
                0.10050150752067566,
                0.02012004889547825,
                0.03866003453731537,
                -0.02695746347308159,
                -0.019939584657549858,
                0.022059109061956406,
                0.006950775161385536,
                -0.010688947513699532,
                0.0003448596689850092,
                -0.04520227760076523,
                0.0445137545466423,
                0.05020323023200035,
                -0.035373177379369736,
                0.05857318639755249,
                -0.0039276182651519775,
                -0.0060208342038095,
                -0.009788057766854763,
                -0.012999980710446835,
                0.01418117806315422,
                -0.03753693029284477,
                -0.01728447526693344,
                0.006074497010558844,
                -0.022904561832547188,
                0.021836234256625175,
                -0.038634758442640305,
                0.021180253475904465,
                -3.8438050978584215e-05,
                0.06153523549437523,
                -0.004721162840723991,
                0.06273458153009415,
                0.02295185625553131,
                0.0014053297927603126,
                -0.0021817712113261223,
                -0.003955835476517677,
                0.009075398556888103,
                -0.011124023236334324,
                -0.02603835053741932,
                0.09121322631835938,
                0.011561592109501362,
                -0.013497254811227322,
                0.055451489984989166,
                0.054280348122119904,
                -0.07687793672084808,
                -0.010842598043382168,
                -0.027904314920306206,
                -0.023168418556451797,
                -0.025898639112710953,
                0.056374359875917435,
                0.004908038768917322,
                -0.03523855656385422,
                -0.0036060288548469543,
                -0.02599780447781086,
                -0.019846560433506966,
                -0.007828720845282078,
                0.05893629789352417,
                0.023656435310840607,
                -0.05397626757621765,
                0.014597886241972446,
                -0.025899561122059822,
                0.045828670263290405,
                0.00680962298065424,
                -0.00450085150077939,
                0.0012396638048812747,
                -0.014860757626593113,
                -0.011731158941984177,
                -0.014300813898444176,
                3.003126209932816e-07,
                -0.015983901917934418,
                -0.004724086262285709,
                -0.05337044969201088,
                0.008007803931832314,
                0.012207506224513054,
                0.053058985620737076,
                -0.032382115721702576,
                -0.01685524359345436,
                -0.017434794455766678,
                0.013188515789806843,
                0.02441214956343174,
                -0.029494551941752434,
                0.013023789040744305,
                0.00839094165712595,
                -0.06260846555233002,
                -0.043043989688158035,
                0.005975840613245964,
                -0.02231430634856224,
                -0.010189441032707691,
                0.022567979991436005,
                -0.0251737292855978,
                0.0045389109291136265,
                0.00542482128366828,
                -0.056788068264722824,
                0.029949160292744637,
                -0.04389721155166626,
                -0.04129350185394287,
                -0.006563516333699226,
                0.04031277820467949,
                -0.005741267930716276,
                0.06526021659374237,
                0.0009159579058177769,
                0.01046646386384964,
                -0.00894773006439209,
                0.019604234024882317,
                -0.054390665143728256,
                0.03280206769704819,
                0.05540098994970322,
                -0.027954190969467163,
                0.04814350977540016,
                -0.03357008844614029,
                -0.012098065577447414,
                -0.03602442890405655,
                -0.00455201743170619,
                0.029070867225527763,
                0.016536694020032883,
                -0.02422914281487465,
                0.03561811149120331,
                -0.059532903134822845,
                0.003548201872035861,
                -0.0030277546029537916,
                -0.03785541281104088,
                -0.000863592722453177,
                -0.015833554789423943,
                -0.02111981250345707,
                -0.009240970946848392,
                0.013548808172345161,
                -0.003741093911230564,
                0.009169992990791798,
                0.051975421607494354,
                0.024928849190473557,
                0.005237093660980463,
                -0.01487287413328886,
                0.09189178049564362,
                0.030593926087021828,
                -0.02048555761575699,
                -0.011364001780748367,
                2.0933162073470025e-34,
                0.0009687574929557741,
                0.012042692862451077,
                0.023223919793963432,
                0.025377770885825157,
                -0.030666494742035866,
                -0.004036127123981714,
                -0.05611336976289749,
                -0.056459940969944,
                -0.014459127560257912,
                -0.0013828439405187964,
                -0.0154371727257967,
                0.04144846275448799,
                -0.032429758459329605,
                -0.021710792556405067,
                -0.044087860733270645,
                -0.007328622974455357,
                0.03215313330292702,
                -0.034473080188035965,
                0.0023342426866292953,
                -0.0351782850921154,
                0.004826722666621208,
                0.08814729750156403,
                0.010461713187396526,
                -0.022692641243338585,
                0.03410640358924866,
                0.0006709283916279674,
                -0.04175373539328575,
                0.027976658195257187,
                -0.06332843750715256,
                0.003141018794849515,
                0.006557039450854063,
                -0.0008938473765738308,
                -0.02668008767068386,
                0.008617936633527279,
                -0.027675872668623924,
                -0.011895079165697098,
                -0.028874533250927925,
                0.015106682665646076,
                0.005392071790993214,
                0.06308767199516296,
                -0.009731949307024479,
                0.017042234539985657,
                -0.04717276245355606,
                0.008194206282496452,
                -0.018709301948547363,
                1.5794033743077307e-06,
                -0.010958844795823097,
                -0.05075272172689438,
                -0.014219962060451508,
                0.02755405381321907,
                -0.0017895807977765799,
                -0.027925977483391762,
                0.014933274127542973,
                -0.014323794282972813,
                0.03629346191883087,
                -0.028112303465604782,
                -0.0023821766953915358,
                0.05075743794441223,
                0.02269792929291725,
                -0.013750510290265083,
                -0.014773789793252945,
                0.009048683568835258,
                0.031202806159853935,
                -0.040057290345430374,
                -0.04793665558099747,
                -0.0353579968214035,
                -0.06672374904155731,
                0.02592499740421772,
                0.013477771542966366,
                0.04320117458701134,
                0.01506118569523096,
                -0.014244376681745052,
                0.010112116113305092,
                -0.006815528497099876,
                -0.003425031900405884,
                0.0641879066824913,
                0.0017269832314923406,
                0.06631142646074295,
                -0.02100192941725254,
                -0.020786527544260025,
                -0.03588031232357025,
                0.026877224445343018,
                0.052119847387075424,
                0.010762316174805164,
                0.03229764103889465,
                0.03333161026239395,
                -0.011663542129099369,
                0.013256918638944626,
                0.01285613514482975,
                -0.01204211451113224,
                -0.003212213749065995,
                -0.016503343358635902,
                0.063993439078331,
                -0.011550581082701683,
                0.026922255754470825,
                0.050700828433036804,
                0.023786962032318115,
                -0.028529206290841103,
                -0.0040442761965096,
                0.004617385566234589,
                0.0370633564889431,
                0.042881812900304794,
                -0.03362949937582016,
                0.010659352876245975,
                0.029675977304577827,
                -0.003920647315680981,
                0.012352794408798218,
                0.029279077425599098,
                -0.017639048397541046,
                0.05486152693629265,
                -0.07374124974012375,
                -0.061319563537836075,
                -0.007076613139361143,
                0.034884680062532425,
                0.02741987071931362,
                0.039504051208496094,
                -0.04626811668276787,
                -0.028605062514543533,
                -0.01368231512606144,
                -0.01824882999062538,
                0.06638766080141068,
                -0.014455558732151985,
                0.008477223105728626,
                0.013126272708177567,
                0.044247206300497055,
                0.03621355816721916,
                -0.004499540664255619,
                -0.027098940685391426,
                0.03194458782672882,
                -0.021553225815296173,
                0.018847553059458733,
                0.02006850391626358,
                -0.011767718009650707,
                -0.05660727992653847,
                0.026403415948152542,
                -0.025827651843428612,
                -0.014516906812787056,
                -0.06039521098136902,
                0.01586921699345112,
                0.01866324432194233,
                0.03975877910852432,
                0.01987006515264511,
                -0.0024134169798344374,
                0.022046174854040146,
                -0.04235612228512764,
                -0.012180468998849392,
                0.0508776530623436,
                0.008763156831264496,
                0.020686103031039238,
                -0.08686791360378265,
                -0.042837221175432205,
                -0.0068459645844995975,
                -0.0004194775829091668,
                -0.06433489918708801,
                -0.004858850035816431,
                -0.062297672033309937,
                -0.03916790708899498,
                -0.038660429418087006,
                0.0350983552634716,
                0.020315147936344147,
                -0.021245621144771576,
                -0.025424519553780556,
                -0.029138658195734024,
                0.038518354296684265,
                0.0012653326848521829,
                0.05289700627326965,
                0.06802646070718765,
                0.018794037401676178,
                -0.002832732629030943,
                -0.008571683429181576,
                0.039024338126182556,
                0.0016230348264798522,
                0.04545942321419716,
                0.05202702432870865,
                0.020866312086582184,
                -0.014118971303105354,
                0.07054658979177475,
                0.09665969014167786,
                0.021759919822216034,
                0.003381041344255209,
                -0.028919439762830734,
                -0.0038006710819900036,
                0.009976518340408802,
                -0.04079904407262802,
                0.08983181416988373,
                0.030761728063225746,
                -0.06113506481051445,
                0.02991827018558979,
                -0.034722886979579926,
                -0.055116817355155945,
                -0.03144057095050812,
                -0.05485033616423607,
                -0.1002948060631752,
                0.02252550795674324,
                0.02910500206053257,
                -0.0051710414700210094,
                0.04398307576775551,
                0.010293456725776196,
                0.009699700400233269,
                -0.06837493926286697,
                0.035433389246463776,
                0.006922626867890358,
                -0.007131305988878012,
                -0.012334629893302917,
                0.036615654826164246,
                -0.04161829873919487,
                -0.00574222719296813,
                0.02643929235637188,
                -0.0057123275473713875,
                -0.0002255925937788561,
                0.023883072659373283,
                -0.01867714896798134,
                -0.06370676308870316,
                -0.002398265525698662,
                0.022266950458288193,
                -0.043317120522260666,
                0.01741643063724041,
                -0.018943237140774727,
                -0.04427545145153999,
                -0.025362197309732437,
                -0.06693703681230545,
                0.05340110510587692,
                0.014076829887926579,
                0.020217351615428925,
                0.039068374782800674,
                -0.012024468742311,
                0.08983074873685837,
                0.019338667392730713,
                0.05489873141050339,
                -0.043378569185733795,
                0.05235936492681503,
                -0.03746575489640236,
                0.04788295924663544,
                -0.02045324817299843,
                -0.0005452674231491983,
                0.004976551979780197,
                -0.06580183655023575,
                -0.025623753666877747,
                -0.01013600267469883,
                0.002656741766259074,
                -0.0459822341799736,
                0.035695772618055344,
                0.032558709383010864,
                0.009877191856503487,
                0.03631191700696945,
                -0.032324668020009995,
                0.03442604839801788,
                0.060827139765024185,
                0.0650598481297493,
                -0.008144285529851913,
                -0.07287779450416565,
                -0.028864853084087372,
                -0.07388399541378021,
                0.00769988214597106,
                0.006168215535581112,
                0.027402758598327637,
                0.006406757049262524,
                -0.03474629297852516,
                0.004143272060900927,
                -0.00014114346413407475,
                0.023302320390939713,
                -0.03727567195892334,
                -0.017444508150219917,
                0.03928712382912636,
                0.009889035485684872,
                -0.018097886815667152,
                -0.03565811738371849,
                -0.06254067271947861,
                0.00653851218521595,
                -0.0625598356127739,
                0.015117715112864971,
                -0.00513182720169425,
                -0.03799683600664139,
                0.02825600653886795,
                -0.004335254896432161,
                0.058969639241695404,
                -0.012005534023046494,
                0.03194621205329895,
                0.0018619948532432318,
                -0.03774997964501381,
                -0.023950092494487762,
                0.032111186534166336,
                0.004665702115744352,
                0.027874117717146873,
                -0.013124820776283741,
                -0.02393338270485401,
                -0.010630288161337376,
                -0.06145024672150612,
                -0.009370767511427402,
                0.0076498775742948055,
                -0.0003004345344379544,
                0.04092361032962799,
                0.019277872517704964,
                -0.013536632992327213,
                0.011706876568496227,
                0.025239763781428337,
                0.00015841487038414925,
                0.07784615457057953,
                -0.006202802527695894,
                -0.05126291140913963,
                -0.014095284044742584,
                -0.03259889408946037,
                -0.0505044162273407,
                0.0188958328217268,
                -0.007806481793522835,
                -0.04611850902438164,
                -0.037754498422145844,
                -0.003928082995116711,
                -0.036688514053821564,
                -0.030760718509554863,
                0.03681519255042076,
                -0.017351184040308,
                -0.010179305449128151,
                0.015174999833106995,
                -0.03790893778204918,
                -0.027377068996429443,
                -0.02907060831785202,
                0.06907990574836731,
                -0.010575651191174984,
                -0.03420474752783775,
                -0.021026162430644035,
                0.01033790037035942,
                -0.01723596453666687,
                0.04259783402085304,
                0.02323882095515728,
                -0.008692755363881588,
                -0.007802687119692564,
                0.013298671692609787,
                -0.0580252967774868,
                0.010852308012545109,
                0.11570795625448227,
                0.035945992916822433,
                0.03533170372247696,
                0.024474242702126503,
                0.054844021797180176,
                0.06189476326107979,
                -0.007920093834400177,
                -0.07021868973970413,
                0.009943738579750061,
                -0.001894280780106783,
                -0.05366973206400871,
                -0.031800106167793274,
                -0.011546020396053791,
                -0.04393176734447479,
                -0.028923360630869865,
                -0.05558311939239502,
                0.07009487599134445,
                -0.005553793162107468,
                -0.07722575217485428,
                -0.0317048616707325,
                0.02513415738940239,
                -0.003734356025233865,
                -0.00857983436435461,
                0.026528140529990196,
                -0.021793000400066376,
                -0.018092775717377663,
                -0.010649608448147774,
                0.008409394882619381,
                0.06426650285720825,
                -0.01737106591463089,
                0.006603740621358156,
                0.04410705715417862,
                0.011476625688374043,
                -0.028464235365390778,
                -0.02665984258055687,
                0.01967942714691162,
                -0.009954634122550488,
                -0.023632092401385307,
                -0.0809670239686966,
                0.041256073862314224,
                -0.07142569869756699,
                -0.021867236122488976,
                0.010045476257801056,
                0.038927167654037476,
                -0.0036273840814828873,
                -0.025917159393429756,
                -0.002344459528103471,
                0.041782841086387634,
                0.03361687809228897,
                0.0037350845523178577,
                0.035501208156347275,
                0.0013079452328383923,
                0.026061687618494034,
                -0.02654322236776352,
                0.002754452871158719,
                -0.025913074612617493,
                0.0905398428440094,
                0.013083715923130512,
                -0.046510662883520126,
                -0.021787358447909355,
                -0.059646766632795334,
                0.05165519937872887,
                0.0002658730954863131,
                0.06015722081065178,
                -0.005464085843414068,
                -0.02176666259765625,
                0.008838178589940071,
                -0.04500093311071396,
                0.014508459717035294,
                0.035165801644325256,
                0.0672004297375679,
                -0.0046452805399894714,
                -0.02049137093126774,
                -0.03936716914176941,
                0.03897060081362724,
                -0.0024390181060880423,
                -0.050700992345809937,
                0.007384615950286388,
                0.029725361615419388,
                -0.0028659324161708355,
                0.011716518551111221,
                0.03465733304619789,
                -0.030408581718802452,
                0.03769781067967415,
                0.02147136814892292,
                -0.07242247462272644,
                -0.1130378395318985,
                0.10139480233192444,
                -0.0002643224142957479,
                -0.01633380725979805,
                0.020000124350190163,
                -0.018636498600244522,
                -0.03875790163874626,
                0.015343742445111275,
                0.028815345838665962,
                0.05250316113233566,
                -0.016205498948693275,
                -0.029919670894742012,
                -0.019298654049634933,
                0.021900063380599022,
                0.009892947971820831,
                0.024949613958597183,
                0.013411949388682842,
                0.009738017804920673,
                -0.05636338144540787,
                -0.003069524886086583,
                -0.011940637603402138,
                0.036601774394512177,
                0.07944391667842865,
                -0.0006506570498459041,
                -0.007780954707413912,
                0.007045424543321133,
                -0.022432245314121246,
                -0.013460520654916763,
                0.0376250296831131,
                -0.012037941254675388,
                0.03702325001358986,
                -0.011706451885402203,
                -0.005720728542655706,
                -0.005989846773445606,
                -0.006050844211131334,
                -0.017537062987685204,
                0.024035600945353508,
                0.03406202420592308,
                0.0308921467512846,
                -0.05957666039466858,
                -0.0065611423924565315,
                0.02946937456727028,
                -0.005561609286814928,
                -0.07227843254804611,
                0.017441321164369583,
                0.017872003838419914,
                0.04554932564496994,
                0.0575677864253521,
                -0.04996383935213089,
                0.03790842741727829,
                -0.02068960852921009,
                -0.04783764109015465,
                -0.04252669960260391,
                -0.026187609881162643,
                -0.007366735953837633,
                0.11035604774951935,
                0.05158543214201927,
                -0.062202487140893936,
                -0.003280913457274437,
                0.00629988731816411,
                0.02063007466495037,
                -0.02505405806005001,
                -0.02055988647043705,
                0.003716150764375925,
                -0.017414910718798637,
                -0.009059886448085308,
                -0.032766930758953094,
                -0.0180092491209507,
                0.03440511226654053,
                -0.002492978936061263,
                0.020398695021867752,
                0.0005833875038661063,
                -0.046535465866327286,
                -0.005381048657000065,
                -0.04106775298714638,
                0.0020483434200286865,
                0.011063496582210064,
                -0.01395981851965189,
                -0.009867100045084953,
                -0.024272818118333817,
                -0.025807591155171394,
                0.037959907203912735,
                0.01589127816259861,
                0.029409293085336685,
                0.019559649750590324,
                0.029271120205521584,
                -0.025941703468561172,
                -0.020338889211416245,
                -0.02389209344983101,
                -0.006293471436947584,
                -0.0385342612862587,
                0.012163415551185608,
                0.04880816489458084,
                0.029333556070923805,
                -0.05002131313085556,
                0.026772523298859596,
                0.032160185277462006,
                0.01622369885444641,
                -0.05197734013199806,
                0.02834826521575451,
                -0.0065613738261163235,
                0.02370915561914444,
                0.00580845819786191,
                -0.019810983911156654,
                0.002011182950809598,
                0.03441238775849342,
                -0.01822863146662712,
                0.0008762802463024855,
                -0.11453971266746521,
                0.03863832727074623,
                0.04897517338395119,
                -0.04498550295829773,
                0.0043292236514389515,
                -0.004176414106041193,
                -0.06387865543365479,
                0.0225162785500288,
                -0.0157046876847744,
                0.03904881328344345,
                -0.010915658436715603,
                0.03044082410633564,
                0.024389732629060745,
                0.015184208750724792,
                -0.013818453997373581,
                -0.029709188267588615,
                0.03283203765749931,
                -0.0010017678141593933,
                -0.05462246760725975,
                -0.08362314105033875,
                0.0004017410974483937,
                -0.012019303627312183,
                0.05895601212978363,
                0.01846722885966301,
                0.05033509433269501,
                -0.01748531125485897,
                0.007991517893970013,
                -0.08296467363834381,
                0.01136385090649128,
                0.029378298670053482,
                0.021909987553954124,
                -0.0019219891401007771,
                -0.015631917864084244,
                -0.02405288629233837,
                -0.03945637494325638,
                0.038647111505270004,
                0.06044092774391174,
                -0.018902605399489403,
                0.0014518426032736897,
                -0.04044605791568756,
                -0.0044359005987644196,
                -0.02990664914250374,
                -6.320039499724275e-33,
                -0.006318647414445877,
                -0.04983864724636078,
                -0.01232491247355938,
                0.004542105365544558,
                -0.009111040271818638,
                -0.024363763630390167,
                0.006764515768736601,
                0.025183487683534622,
                -0.003965289331972599,
                -0.03551172465085983,
                0.007252977229654789,
                0.023618752136826515,
                0.02502952702343464,
                -0.0017037722282111645,
                0.049422889947891235,
                -0.0497126504778862,
                -0.026916323229670525,
                0.028821276500821114,
                0.009057682938873768,
                -0.07067383080720901,
                -0.011781884357333183,
                0.0254360418766737,
                0.02533375844359398,
                0.03974280133843422,
                -0.014165662229061127,
                0.034501899033784866,
                -0.015113464556634426,
                -0.026227260008454323,
                0.007173924706876278,
                0.0010279130656272173,
                0.04205146059393883,
                -0.04060228541493416,
                -0.00767648546025157,
                -0.08909089118242264,
                0.019607001915574074,
                0.08281957358121872,
                0.0003744048299267888,
                -0.05282098799943924,
                -0.012582524679601192,
                0.00451723812147975,
                -0.013054207898676395,
                -0.04757199063897133,
                0.01351243071258068,
                -0.00787467509508133,
                0.006687529385089874,
                -0.04778727516531944,
                0.019221657887101173,
                0.007440431043505669,
                -0.011105386540293694,
                -0.05257977545261383,
                -0.022352177649736404,
                0.011444670148193836,
                -0.03917638584971428,
                0.044410817325115204,
                0.035236991941928864,
                0.031857382506132126,
                -0.007331836502999067,
                -0.02748182788491249,
                -0.010855920612812042,
                -0.0104150902479887,
                -0.07305029034614563,
                0.018207261338829994,
                -0.029229961335659027,
                0.007357046473771334,
                -0.029460720717906952,
                -0.00316659570671618,
                -0.14943991601467133,
                -0.01702870987355709,
                0.015369119122624397,
                -0.049651481211185455,
                -0.06251171231269836,
                0.053117748349905014,
                -0.07505568116903305,
                0.016996342688798904,
                -0.07703391462564468,
                -0.03193436190485954,
                -0.0655866265296936,
                0.022316720336675644,
                0.024632377550005913,
                0.04280369356274605,
                -0.022943558171391487,
                0.04621698334813118,
                0.005010900087654591,
                -0.02647988870739937,
                -0.02084180898964405,
                -0.03216300904750824,
                -0.021287094801664352,
                0.0380040779709816,
                0.005487295798957348,
                0.0254130270332098,
                -0.017090927809476852,
                0.0849800556898117,
                0.014472625218331814,
                0.03409063071012497,
                -0.01633620820939541,
                -0.030854810029268265,
                0.050066787749528885,
                -0.03643375262618065,
                -0.02593602053821087,
                0.008257937617599964,
                0.02908659167587757,
                0.0381782129406929,
                -0.03939342871308327,
                0.003591355634853244,
                -0.011463048867881298,
                -0.018659358844161034,
                0.032993413507938385,
                0.0012276009656488895,
                0.005439169704914093,
                0.023043839260935783,
                0.02761496603488922,
                0.040243182331323624,
                0.008655906654894352,
                0.011845074594020844,
                -0.024935346096754074,
                -0.022303320467472076,
                0.023739581927657127,
                -0.02498374506831169,
                0.025506047531962395,
                -0.0340600460767746,
                0.03205258399248123,
                0.04789687320590019,
                -0.034630969166755676,
                -0.020161187276244164,
                0.0022472927812486887,
                0.03955801948904991,
                0.052416302263736725,
                0.029831819236278534,
                0.04767926037311554,
                -0.041731685400009155,
                0.006502946373075247,
                -0.00241935346275568,
                2.192740282680461e-07,
                0.04167696833610535,
                -4.323451139498502e-05,
                0.014747394248843193,
                0.03621592000126839,
                0.0412418507039547,
                0.04606195539236069,
                -0.0025384086184203625,
                0.023294249549508095,
                0.022237354889512062,
                0.015131063759326935,
                0.09196408838033676,
                -0.03539543226361275,
                -0.02043326012790203,
                -0.005360068287700415,
                0.04981934279203415,
                -0.11458021402359009,
                -0.03946564346551895,
                -0.008845911361277103,
                -0.0021401362027972937,
                -0.051053840667009354,
                0.014878050424158573,
                0.0067171067930758,
                0.016108574345707893,
                -0.025825979188084602,
                -0.0031356194522231817,
                0.019759347662329674,
                0.013935385271906853,
                -0.03637861832976341,
                0.059862636029720306,
                -0.023178059607744217,
                0.03299171105027199,
                0.07139092683792114,
                0.012941557914018631,
                0.021018005907535553,
                -0.03968076780438423,
                -0.0993022546172142,
                0.009980743750929832,
                0.07304603606462479,
                0.022568967193365097,
                0.026904303580522537,
                0.01085280254483223,
                -0.03542657569050789,
                -0.002634833799675107,
                0.019289251416921616,
                -0.0020404455717653036,
                -0.018506472930312157,
                -0.0294511578977108,
                0.026916196569800377,
                -0.04885318875312805,
                0.03272230550646782,
                0.030977899208664894,
                0.02894510142505169,
                0.005070168524980545,
                0.07076814025640488,
                -0.01010663527995348,
                0.03190801292657852,
                0.029093969613313675,
                0.036641206592321396,
                0.03960471227765083,
                0.031208690255880356,
                0.015321377664804459,
                -0.04191719740629196,
                0.02189994789659977,
                0.07352735102176666,
                -0.01569543033838272,
                -0.05201105400919914,
                -0.042150113731622696,
                6.660387725957544e-35,
                -0.0052326275035738945,
                -0.056618522852659225,
                -0.07091236859560013,
                -0.06383848935365677,
                0.016054442152380943,
                0.00381953245960176,
                -0.03170265257358551,
                0.006257542874664068,
                0.030094590038061142,
                0.05609412118792534,
                -0.05123846232891083,
                0.0013806852512061596,
                -0.041705332696437836,
                0.010039466433227062,
                -0.01748465560376644,
                -0.032834332436323166,
                -0.01487213745713234,
                0.029189731925725937,
                0.04582200199365616,
                0.014160512946546078,
                0.02890627644956112,
                0.061340004205703735,
                -0.04875558242201805,
                0.02641119435429573,
                0.08122783154249191,
                0.02021319605410099,
                -0.05742882192134857,
                0.04330388084053993,
                -0.001912656705826521,
                -0.08445122092962265,
                -0.014959150925278664,
                -0.023357519879937172,
                -0.0018411863129585981,
                -0.0062503996305167675,
                0.02444123476743698,
                0.045134201645851135,
                -0.028047356754541397,
                0.036311641335487366,
                -0.009663954377174377,
                -0.03524214029312134,
                -0.01618739776313305,
                0.05027299001812935,
                0.028042461723089218,
                0.0021771318279206753,
                -0.025968313217163086,
                1.638967319195217e-06,
                -0.01486610621213913,
                -0.01527273841202259,
                -0.010185261256992817,
                -0.030645666643977165,
                -0.00224861316382885,
                -0.012706677429378033,
                0.004757400136440992,
                0.00414317287504673,
                0.009486319497227669,
                -0.00832977332174778,
                -0.002236629370599985,
                0.024784782901406288,
                0.007126952521502972,
                0.008076234720647335,
                0.001263646176084876,
                0.012077049352228642,
                -0.015870127826929092,
                -0.042291682213544846,
                -0.015544138848781586,
                -0.07076264917850494,
                0.03644851967692375,
                -0.004553884267807007,
                0.062027882784605026,
                0.06598346680402756,
                0.034426067024469376,
                0.02829224243760109,
                -0.006960559170693159,
                0.0051947664469480515,
                -0.013478325679898262,
                0.002011946402490139,
                -0.00048705789959058166,
                -0.0379474051296711,
                -0.03995826840400696,
                -0.006642387714236975,
                0.009598593227565289,
                0.06306425482034683,
                0.004629203118383884,
                0.014934636652469635,
                0.11030395328998566,
                -0.02583121508359909,
                -0.10814683884382248,
                0.017700674012303352,
                -0.029804712161421776,
                -0.037131018936634064,
                -0.027955738827586174,
                -0.04348355531692505,
                0.06197565421462059,
                0.008351597934961319,
                0.0166059210896492,
                0.013112296350300312,
                0.06955201178789139,
                -0.0099470941349864,
                -0.020801417529582977,
                -0.00024696430773474276,
                0.008984153158962727,
                -0.03797128424048424,
                -0.020699914544820786,
                0.027622926980257034,
                -0.002682145219296217,
                0.0035706711933016777,
                -0.013248905539512634,
                0.013504603877663612,
                0.028981268405914307,
                0.0801062062382698,
                -0.052183281630277634,
                0.02012396603822708,
                -0.022423816844820976,
                0.08284614235162735,
                0.0291817057877779,
                0.01721205562353134,
                0.020698774605989456,
                -0.014023425988852978,
                -0.020356275141239166,
                -0.015315753407776356,
                0.03192494064569473,
                -0.018192822113633156,
                0.00390777038410306,
                0.02226502262055874,
                -0.004757862072438002,
                0.027142060920596123,
                0.019501272588968277,
                0.0004786882200278342,
                0.023628363385796547,
                0.015294416807591915,
                0.07731755077838898,
                0.03609815239906311,
                -0.004591681994497776,
                0.004719378426671028,
                -0.0269469004124403,
                0.029521577060222626,
                -0.03413918614387512,
                -0.05043007433414459,
                0.024320172145962715,
                0.015705034136772156,
                0.03416560962796211,
                0.02582535333931446,
                -0.0146609116345644,
                -0.018378611654043198,
                0.005913281813263893,
                0.025859763845801353,
                0.04744262993335724,
                0.0029821922071278095,
                0.015701590105891228,
                0.032109446823596954,
                -0.03949164226651192,
                -0.0018928885692730546,
                0.013513880781829357,
                0.0055493987165391445,
                0.021344559267163277,
                -0.022577982395887375,
                -0.03068484738469124,
                -0.008267912082374096,
                -0.04953935369849205,
                -0.01343491766601801,
                -0.010901255533099174,
                -0.04783950746059418,
                -0.006381954997777939,
                -0.1005125418305397,
                0.021101145073771477,
                -0.009493446908891201,
                0.049770236015319824,
                0.01156308688223362,
                -0.05532802641391754,
                -0.040508873760700226,
                -0.02291668951511383,
                0.05542493611574173,
                -0.0010332285892218351,
                -0.029833832755684853,
                0.011759166605770588,
                -0.024676505476236343,
                0.03564314916729927,
                -0.007784784771502018,
                0.012157239019870758,
                -0.02247677743434906,
                -0.041814908385276794,
                -0.05401305481791496,
                0.020189227536320686,
                -0.017137732356786728,
                0.04244246706366539,
                0.026795629411935806,
                -0.022722385823726654,
                0.056495606899261475,
                0.02976355515420437,
                -0.06986020505428314,
                0.06535167247056961,
                -0.03602389618754387,
                -0.09575287252664566,
                0.017544690519571304,
                0.000505206233356148,
                0.048014022409915924,
                0.036174923181533813,
                0.05569709837436676,
                0.0021714188624173403,
                -0.0485181026160717,
                -0.051228925585746765,
                -0.020118610933423042,
                0.021884361281991005,
                -0.09287351369857788,
                -0.017046453431248665,
                0.007345796562731266,
                0.028506681323051453,
                0.0019889178220182657,
                0.03220833092927933,
                -0.012636532075703144,
                -0.006066635251045227,
                -0.010459206998348236,
                -0.02379816211760044,
                0.03609394654631615,
                0.04255680367350578,
                -0.00608700979501009,
                0.001406000112183392,
                0.029180049896240234,
                -0.022275567054748535,
                -0.04502275213599205,
                -0.009919969365000725,
                -0.06725481152534485,
                0.002944914624094963,
                -0.011658287607133389,
                -0.003299606731161475,
                -0.012125324457883835,
                0.056842461228370667,
                0.031084105372428894,
                0.005266319029033184,
                -0.05778707563877106,
                0.06334307789802551,
                -0.0476553849875927,
                -0.010425220243632793,
                0.03368670120835304,
                -0.011511704884469509,
                0.02617519721388817,
                0.002408315660431981,
                -0.011118661612272263,
                0.02293170429766178,
                -0.004539547022432089,
                0.01921900361776352,
                -0.012732762843370438,
                -0.0102279894053936,
                0.0015059754950925708,
                0.030752193182706833,
                -0.02543943002820015,
                -0.026180630549788475,
                0.07883670926094055,
                0.0529710128903389,
                0.044323861598968506,
                -0.07302630692720413,
                -0.007023388985544443,
                0.03390716388821602,
                0.01861533895134926,
                0.03579697757959366,
                0.0025882532354444265,
                0.05316493660211563,
                -0.0020701077301055193,
                -0.029619546607136726,
                0.0007668040925636888,
                -0.014413654804229736,
                -0.021326487883925438,
                -0.009600511752068996,
                -0.032811034470796585,
                -0.030605679377913475,
                0.0180820319801569,
                -0.0307077057659626,
                -0.010082202963531017,
                0.0459706149995327,
                0.04806593805551529,
                -0.008018703199923038,
                0.01934804394841194,
                -0.04905727505683899,
                0.040137529373168945,
                -0.006942743435502052,
                0.06339506804943085,
                -0.015186167322099209,
                -0.022346531972289085,
                -0.033472221344709396,
                0.0005252800183370709,
                -0.01145006064325571,
                0.0010128209833055735,
                0.031163528561592102,
                -0.025218239054083824,
                -0.005832011811435223,
                -0.02002793736755848,
                -0.009731409139931202,
                -0.04374461621046066,
                0.004573562182486057,
                -0.035561319440603256,
                0.019333506003022194,
                0.011997176334261894,
                -0.035089969635009766,
                -0.02039477787911892,
                -0.025793002918362617,
                0.02141694165766239,
                0.03452230244874954,
                0.013457056134939194,
                0.0389501228928566,
                -0.045805931091308594,
                0.09645926207304001,
                -0.050948962569236755,
                -0.022318894043564796,
                0.021731972694396973,
                -0.06713809818029404,
                0.01937142014503479,
                -0.0014818694908171892,
                -0.05638471618294716,
                0.023895569145679474,
                0.013356334529817104,
                -0.01087872963398695,
                -0.01009962148964405,
                -0.02140086516737938,
                0.014317591674625874,
                -0.025323381647467613,
                -0.01622236892580986,
                0.0015276385238394141,
                0.049479637295007706,
                -0.005893860012292862,
                0.01602695882320404,
                0.006297999061644077,
                -0.0391167551279068,
                -0.014104905538260937,
                0.032587163150310516,
                4.696293399319984e-05,
                0.0049633607268333435,
                -0.008639508858323097,
                -0.028564387932419777,
                -0.018955202773213387,
                -0.013917294330894947,
                0.10858314484357834,
                0.024248965084552765,
                0.04607237130403519,
                -0.06517047435045242,
                -0.04280156269669533,
                -0.010044378228485584,
                0.058996982872486115,
                -0.0016921713249757886,
                0.032772935926914215,
                -0.02932857535779476,
                -0.09485678374767303,
                -0.0014275164576247334,
                -0.00654728664085269,
                0.009080285206437111,
                0.03679460287094116,
                -0.07353847473859787,
                0.013182474300265312,
                -0.028909411281347275,
                -0.12873919308185577,
                -0.01612788625061512,
                -0.026835031807422638,
                -0.015168171375989914,
                -0.03770320490002632,
                -0.006427873857319355,
                0.03970035910606384,
                0.024508990347385406,
                0.004170121159404516,
                -0.10082561522722244,
                0.05430539697408676,
                -0.032064083963632584,
                0.020131045952439308,
                0.010183430276811123,
                0.024113288149237633,
                -0.005455268081277609,
                -0.0009330716566182673,
                -0.02275385707616806,
                -0.028565770015120506,
                -0.01830473355948925,
                -0.09226200729608536,
                0.026255059987306595,
                -0.059074100106954575,
                0.0007623397395946085,
                0.0017677271971479058,
                -0.05198495462536812,
                0.006916954182088375,
                -0.01596902310848236,
                0.020567407831549644,
                0.04342266172170639,
                0.035521794110536575,
                -0.027886193245649338,
                0.009059138596057892,
                -0.011247294023633003,
                0.01872549206018448,
                -0.014170977286994457,
                0.03828112408518791,
                -0.02413034625351429,
                -0.04872700572013855,
                -0.020066339522600174,
                -0.023593956604599953,
                -0.01880250871181488,
                -0.006702111102640629,
                0.01050226204097271,
                0.061216194182634354,
                0.05863218382000923,
                0.00851448718458414,
                0.016553279012441635,
                -0.02728521265089512,
                -0.024305062368512154,
                -0.011360817588865757,
                0.06141176447272301,
                0.030075913295149803,
                0.04521144926548004,
                0.004209319595247507,
                -0.04866418614983559,
                0.0539260171353817,
                0.020892521366477013,
                -0.0006630720454268157,
                0.07078340649604797,
                -0.020325491204857826,
                -0.01221301406621933,
                0.03477352857589722,
                0.047288503497838974,
                -0.03582088649272919,
                -0.018354445695877075,
                0.019440235570073128,
                -0.04280278831720352,
                0.05310794338583946,
                0.048043008893728256,
                -0.015821412205696106,
                -0.008356025442481041,
                -0.010251929052174091,
                -0.009711941704154015,
                0.02221020497381687,
                -0.03663645684719086,
                -0.005562840029597282,
                0.019414354115724564,
                -0.00228405580855906,
                -0.02587139420211315,
                -0.00846758671104908,
                -0.009329207241535187,
                -0.06895191222429276,
                0.043873269110918045,
                0.012772267684340477,
                -0.020297570154070854,
                0.0009389851475134492,
                0.058360688388347626,
                -0.006320544984191656,
                0.04332435131072998,
                0.025054672732949257,
                0.019600896164774895,
                0.010767015628516674,
                -0.009655063040554523,
                -0.030797671526670456,
                -0.03698127716779709,
                -0.0003455617988947779,
                -0.04654020071029663,
                -0.05551815405488014,
                0.006395305972546339,
                -0.07185860723257065,
                -0.08641849458217621,
                -0.02584923431277275,
                -0.02060900628566742,
                -0.03524410352110863,
                0.06335548311471939,
                0.022802432999014854,
                -0.06146132946014404,
                0.03748881071805954,
                0.001048867590725422,
                0.0003452901728451252,
                -0.029686933383345604,
                0.0015891867224127054,
                0.011261595413088799,
                -0.12361694127321243,
                0.09907536208629608,
                -0.015814634039998055,
                0.07756543904542923,
                -0.05290626734495163,
                -0.04379405826330185,
                0.08405847102403641,
                0.007509689312428236,
                0.06833197921514511,
                0.05570073053240776,
                0.049131594598293304,
                -0.06233290955424309,
                -0.006399358622729778,
                0.02075187861919403,
                -0.061778709292411804,
                -0.043404899537563324,
                0.04883495345711708,
                -0.027849512174725533,
                0.008415425196290016,
                -0.10838291049003601,
                -0.014229154214262962,
                -0.03465565666556358,
                0.023654762655496597,
                0.035607848316431046,
                0.021391402930021286,
                -0.06257914751768112,
                -0.07347700744867325,
                0.0010186004219576716,
                -0.007940204814076424,
                0.02176078036427498,
                0.0110465781763196,
                -0.003834275295957923,
                -0.01146497018635273,
                0.009626209735870361,
                -0.027975620701909065,
                0.04357268288731575,
                -0.009055032394826412,
                -0.02292625792324543,
                0.0008220106246881187,
                0.014673453755676746,
                -0.006273969076573849,
                -0.03000226803123951,
                0.07761809229850769,
                -0.014269746840000153,
                -0.005939378868788481,
                -0.009681976400315762,
                -0.03273385390639305,
                -0.041955746710300446,
                0.08473185449838638,
                0.0052607711404562,
                0.02244538627564907,
                -0.03134991601109505,
                -0.05173353850841522,
                0.007729211822152138,
                0.04141753166913986,
                0.0013935096794739366,
                0.025212187319993973,
                0.016213931143283844,
                0.01600266434252262,
                -0.013789953663945198,
                0.08070940524339676,
                0.01125906128436327,
                0.02128192037343979,
                0.01607625000178814,
                -0.05853676795959473,
                -0.0031714974902570248,
                0.03393382951617241,
                -0.026660488918423653,
                -0.023370282724499702,
                0.04662254825234413,
                -0.03508946672081947,
                0.04329638183116913,
                -0.021469857543706894,
                0.01018132921308279,
                -0.039304882287979126,
                -0.004770022351294756,
                0.003462427295744419,
                -0.0027506377082318068,
                0.019748644903302193,
                -0.03809981420636177,
                -0.07565675675868988,
                -0.04293769225478172,
                -0.022431811317801476,
                -0.015199639834463596,
                0.052405066788196564,
                0.0036428922321647406,
                -0.04467697814106941,
                -0.0016285397578030825,
                0.0022238618694245815,
                -0.020325917750597,
                -0.024627575650811195,
                0.006289171986281872,
                0.036443501710891724,
                -0.025692375376820564,
                0.062399424612522125,
                0.017903342843055725,
                -0.0010201429249718785,
                0.02832670323550701,
                -0.015038578771054745,
                0.002896923804655671,
                -0.04762955754995346,
                -0.028533879667520523,
                0.04609544202685356,
                0.015876227989792824,
                -6.47770708763213e-33,
                -0.035134315490722656,
                -0.027881374582648277,
                3.398969056433998e-05,
                0.020460912957787514,
                -0.05884293466806412,
                -0.010417995043098927,
                0.005178375169634819,
                0.05032394453883171,
                -0.018546877428889275,
                -0.02581356279551983,
                -0.03361663594841957,
                0.004088732413947582,
                0.028215236961841583,
                -0.03256092965602875,
                0.007610120344907045,
                0.042123593389987946,
                0.015261724591255188,
                0.00807900633662939,
                -0.011718166060745716,
                -0.04940712824463844,
                0.01098368689417839,
                0.008438628166913986,
                -0.01857183501124382,
                -0.01334451138973236,
                0.02200867235660553,
                0.041989948600530624,
                -0.03469975292682648,
                0.02500966191291809,
                0.07031906396150589,
                0.024944303557276726,
                0.004321023356169462,
                -0.025449298322200775,
                0.00898592360317707,
                -0.0017973929643630981,
                -0.017708227038383484,
                0.03762418031692505,
                -0.04602682590484619,
                -0.022053901106119156,
                0.010584559291601181,
                -0.001302411314100027,
                0.015319349244236946,
                -0.02666316367685795,
                -0.010469857603311539,
                0.008457840420305729,
                -0.015446373261511326,
                -0.057154931128025055,
                -0.03667110204696655,
                -0.004939579870551825,
                -0.04767715558409691,
                0.05939915403723717,
                0.010912925004959106,
                -0.019353365525603294,
                -0.008775930851697922,
                0.041595324873924255,
                0.04103131592273712,
                0.03581124171614647,
                -0.008252585306763649,
                -0.050526052713394165,
                0.028150945901870728,
                0.005116651766002178,
                0.01672646775841713,
                0.005876671988517046,
                0.014318704605102539,
                -0.05679118260741234,
                0.005686478223651648,
                0.02892894856631756,
                -0.046819716691970825,
                0.01966877095401287,
                0.028880534693598747,
                -0.03215423598885536,
                0.004065361339598894,
                0.11593583226203918,
                0.03927475959062576,
                0.03691919147968292,
                -0.11249693483114243,
                -0.03765009716153145,
                -0.011234203353524208,
                0.03875388205051422,
                -0.0663943886756897,
                0.006884906906634569,
                0.001296319649554789,
                -0.009668536484241486,
                -0.018239976838231087,
                0.025071900337934494,
                -0.02766578644514084,
                -0.02425811067223549,
                -0.009252379648387432,
                0.00949397124350071,
                0.030385350808501244,
                -0.0038700897712260485,
                -0.013585119508206844,
                0.04788047820329666,
                0.00324031594209373,
                -0.0052359336987137794,
                0.08338647335767746,
                -0.10860967636108398,
                0.07985417544841766,
                0.013442589901387691,
                0.011391600593924522,
                0.013203163631260395,
                -0.010740532539784908,
                -0.009509705938398838,
                -0.007751854602247477,
                0.05411909893155098,
                -0.004498359747231007,
                0.0017022001557052135,
                -0.03183229640126228,
                -0.021376097574830055,
                -0.01203885953873396,
                0.03134109079837799,
                0.017214924097061157,
                -0.006012327037751675,
                0.05643075332045555,
                -0.03668607771396637,
                -0.01714727096259594,
                0.014149784110486507,
                0.040852323174476624,
                0.01672825962305069,
                -0.03852057084441185,
                -0.0692935660481453,
                0.03922629356384277,
                -0.058515843003988266,
                -0.037833333015441895,
                0.05684512481093407,
                -0.01680707558989525,
                -0.011786352843046188,
                -0.024685680866241455,
                0.0014297447632998228,
                -0.0041953385807573795,
                0.0415269210934639,
                4.475860987440683e-05,
                -0.005227640736848116,
                2.214351013662963e-07,
                0.009214525111019611,
                0.04313858598470688,
                0.016265591606497765,
                0.04320989549160004,
                0.022096047177910805,
                0.029833486303687096,
                -0.0347747728228569,
                0.029412461444735527,
                -0.03655619919300079,
                -0.012878433801233768,
                0.005593341775238514,
                -0.02941417135298252,
                0.008143086917698383,
                -0.010444200597703457,
                0.028176171705126762,
                -0.04194145277142525,
                0.0648241713643074,
                -0.013034351170063019,
                0.006895583122968674,
                -0.02925027906894684,
                0.012409653514623642,
                -0.013717773370444775,
                -0.030580539256334305,
                -0.010482221841812134,
                -0.009178079664707184,
                -0.09680872410535812,
                0.0210524033755064,
                -0.06827865540981293,
                0.028566226363182068,
                -0.02830074541270733,
                0.06407163292169571,
                0.03416748717427254,
                0.04118295758962631,
                0.018699489533901215,
                0.0225412268191576,
                -0.026759134605526924,
                0.05283598229289055,
                0.09923240542411804,
                -0.009472407400608063,
                0.02187279239296913,
                0.000525890733115375,
                -0.08198940008878708,
                0.010576053522527218,
                -0.03470758721232414,
                0.020340261980891228,
                0.08234352618455887,
                0.01893753744661808,
                0.04037191718816757,
                -0.08232998102903366,
                -0.026987066492438316,
                0.03318357095122337,
                0.002063957741484046,
                -0.0009096462745219469,
                0.018979961052536964,
                0.017028294503688812,
                -0.0015062439488247037,
                0.02931714989244938,
                0.045075949281454086,
                0.06668192148208618,
                0.009405485354363918,
                -0.021465130150318146,
                -0.010003868490457535,
                0.02057834155857563,
                0.045071735978126526,
                0.005425779148936272,
                -0.012238229624927044,
                -0.017740938812494278,
                1.6242936169857992e-34,
                -0.02392716333270073,
                -0.06499660760164261,
                0.008036894723773003,
                0.026274869218468666,
                -0.009013825096189976,
                -0.0006149688269942999,
                0.012416182085871696,
                0.0007365191704593599,
                -0.0043859598226845264,
                -0.028993096202611923,
                0.003416393417865038,
                -0.01152161043137312,
                -0.060850631445646286,
                0.013534942641854286,
                0.003090872894972563,
                -0.007365204393863678,
                0.005237753503024578,
                0.02817389741539955,
                0.05306927114725113,
                -0.003755077952519059,
                0.012820382602512836,
                0.0371059775352478,
                -0.042408645153045654,
                0.014352620579302311,
                0.08943566679954529,
                0.02150130458176136,
                -0.03355603665113449,
                0.03228073567152023,
                0.008903507143259048,
                -0.09115787595510483,
                -0.016812128946185112,
                -0.01778433471918106,
                -0.0019596877973526716,
                0.01928149163722992,
                0.028709564357995987,
                0.013439010828733444,
                0.029824722558259964,
                0.03144427761435509,
                -0.005510243587195873,
                -0.04612082242965698,
                -0.021641599014401436,
                0.07781687378883362,
                0.05515315756201744,
                0.05559376999735832,
                -0.017548952251672745,
                1.5027444533188827e-06,
                -0.024258671328425407,
                -0.024206001311540604,
                0.002544407732784748,
                -0.03139090910553932,
                0.016237182542681694,
                -0.0016747640911489725,
                0.029192062094807625,
                -0.030934957787394524,
                0.013338594697415829,
                -0.010205289348959923,
                -0.030264658853411674,
                0.03114880807697773,
                -0.02402626723051071,
                -0.00954705011099577,
                0.015537823550403118,
                -0.0029900732915848494,
                -0.009305451065301895,
                0.005219290964305401,
                -0.02331625111401081,
                -0.033291496336460114,
                0.004678883124142885,
                -0.0012789180036634207,
                0.062137797474861145,
                0.04791058227419853,
                0.08968095481395721,
                0.00506517244502902,
                -0.00897369533777237,
                0.02278418280184269,
                -0.0032252559904009104,
                -0.018709899857640266,
                0.011827008798718452,
                -0.014204894192516804,
                -0.04562673345208168,
                0.0036537591367959976,
                0.030222149565815926,
                0.08810316771268845,
                0.010428318753838539,
                0.008841021917760372,
                0.10491100698709488,
                -0.039471473544836044,
                -0.13756076991558075,
                0.031506702303886414,
                -0.03853389993309975,
                0.0027795275673270226,
                -0.0031251232139766216,
                -0.010532998479902744,
                0.027388805523514748,
                -0.017828745767474174,
                0.009453405626118183,
                -0.014762448146939278,
                0.050700873136520386,
                -0.018282754346728325,
                0.00275227427482605,
                0.009107734076678753,
                0.009792429395020008,
                -0.04548685997724533,
                -0.014578941278159618,
                -0.015032225288450718,
                -0.002154723508283496,
                0.01690814085304737,
                -0.005880062468349934,
                0.0293580275028944,
                0.02261011116206646,
                0.07243280857801437,
                -0.027790356427431107,
                0.03727896511554718,
                0.011853460222482681,
                0.07441824674606323,
                0.021191800013184547,
                -0.0018752936739474535,
                0.028807716444134712,
                0.005940348841249943,
                -0.000954873044975102,
                0.0021149825770407915,
                0.005664807744324207,
                -0.0077894385904073715,
                0.005229049362242222,
                0.0005823575775139034,
                -0.002924806671217084,
                0.0426919050514698,
                -0.00948002003133297,
                -0.009923440404236317,
                -0.006180023308843374,
                -0.00664373068138957,
                0.08655929565429688,
                0.048849936574697495,
                0.0019584104884415865,
                0.01598629727959633,
                -0.011753900907933712,
                0.045987822115421295,
                -0.008614067919552326,
                -0.04992496222257614,
                -0.0052200667560100555,
                0.03150428086519241,
                -0.009274216368794441,
                0.023198511451482773,
                0.0013490843120962381,
                -0.0012309268349781632,
                -0.0017631420632824302,
                0.017040159553289413,
                0.07564700394868851,
                -0.02924801968038082,
                0.0006309088785201311,
                0.10200992971658707,
                -0.010316627100110054,
                0.004081080202013254,
                3.012884917552583e-05,
                0.035980984568595886,
                0.05397838354110718,
                0.01602129265666008,
                -0.016299709677696228,
                0.006045856513082981,
                -0.055961284786462784,
                -0.011576507240533829,
                -0.009683283045887947,
                -0.010374077595770359,
                -0.021130135282874107,
                -0.07548436522483826,
                0.003571245353668928,
                -0.025755684822797775,
                0.016573632135987282,
                0.015483432449400425,
                -0.0509454570710659,
                0.0035006492398679256,
                -0.02729267068207264,
                0.043505001813173294,
                -0.02263585850596428,
                -0.03369826450943947,
                -0.0023239979054778814,
                -0.008919226005673409,
                0.029232360422611237,
                0.005173065233975649,
                -0.001954066101461649,
                -0.0498836450278759,
                -0.03870509937405586,
                -0.029643161222338676,
                0.02497824653983116,
                0.0004200679832138121,
                0.027182502672076225,
                0.01569308154284954,
                -0.03421425819396973,
                0.022944379597902298,
                -0.003361508948728442,
                -0.0602448433637619,
                0.06373055279254913,
                -0.0302107036113739,
                -0.11030305922031403,
                -0.0048241568729281425,
                0.0024968383368104696,
                0.04291925206780434,
                0.02655043825507164,
                0.06386188417673111,
                -0.014870043843984604,
                -0.02351856790482998,
                -0.07064318656921387,
                0.004306814633309841,
                0.04509161040186882,
                -0.0608859658241272,
                -0.01434207521378994,
                0.021543610841035843,
                0.03564303368330002,
                0.020866407081484795,
                0.020842518657445908,
                -0.02116103284060955,
                -0.02430083230137825,
                -0.004592514596879482,
                -0.012706144712865353,
                0.07684309035539627,
                0.014821012504398823,
                -0.02566756308078766,
                0.02557205781340599,
                0.06235693022608757,
                -0.02764715999364853,
                -0.09948092699050903,
                0.0075980257242918015,
                -0.06748605519533157,
                0.004529828205704689,
                0.021739235147833824,
                -0.025575019419193268,
                0.0015349106397479773,
                0.011165139265358448,
                0.019395736977458,
                0.017279740422964096,
                -0.034166369587183,
                0.02307811751961708,
                -0.02888779155910015,
                -0.0067612119019031525,
                0.030373506247997284,
                -0.0037110180128365755,
                0.01762581802904606,
                0.00027521137963049114,
                -0.02352764829993248,
                0.0008120425045490265,
                -0.006349225994199514,
                -0.000636683835182339,
                -0.003758466336876154,
                -0.009503363631665707,
                -0.018734006211161613,
                0.04777197167277336,
                -0.008956286124885082,
                -0.020538726821541786,
                0.08861468732357025,
                0.06378362327814102,
                0.03712354600429535,
                -0.004998490214347839,
                -0.016956575214862823,
                -0.0024728027638047934,
                -0.009409051388502121,
                0.04626479744911194,
                -0.036962926387786865,
                0.05431445688009262,
                -0.03185950592160225,
                -0.020125195384025574,
                -0.010817667469382286,
                0.01246189046651125,
                0.029472317546606064,
                0.009092668071389198,
                -0.027712618932127953,
                -0.03976314514875412,
                0.02148088626563549,
                -0.03560684248805046,
                -0.047044429928064346,
                0.040355246514081955,
                0.029528608545660973,
                0.029838895425200462,
                -0.013809124939143658,
                -0.019946018233895302,
                0.031235815957188606,
                -0.028271907940506935,
                0.036139585077762604,
                -0.018050063401460648,
                0.005206072237342596,
                -0.002563151065260172,
                0.014385191723704338,
                -0.022761275991797447,
                -0.004063188098371029,
                0.018651925027370453,
                -0.012908771634101868,
                -0.0005161648150533438,
                -0.03704916313290596,
                -0.020822927355766296,
                -0.0683143138885498,
                0.0020868524443358183,
                -0.016916174441576004,
                0.01763727143406868,
                0.028741678223013878,
                0.026120759546756744,
                -0.004281468689441681,
                -0.005843066144734621,
                0.019856015220284462,
                0.04452507570385933,
                -0.01282126922160387,
                0.03570054471492767,
                -0.06772593408823013,
                0.14811450242996216,
                -0.044075146317481995,
                0.007480201311409473,
                0.0006322025437839329,
                -0.0668216347694397,
                0.011651682667434216,
                0.004864964634180069,
                -0.020165162160992622,
                0.03994995355606079,
                0.03925102576613426,
                -0.0042642513290047646,
                -0.0016158809885382652,
                -0.0028090865816920996,
                -0.010456194169819355,
                -0.002367324661463499,
                -0.01828754134476185,
                0.00885412935167551,
                0.061657119542360306,
                -0.025322947651147842,
                0.020840976387262344,
                -0.028009431436657906,
                -0.05322008207440376,
                0.010589983314275742,
                0.0413806177675724,
                0.05120869353413582,
                0.008071101270616055,
                -0.007671094499528408,
                -0.019429165869951248,
                0.0352601557970047,
                -0.0020438001956790686,
                0.05726442113518715,
                0.013758975081145763,
                0.010233655571937561,
                -0.06505849212408066,
                -0.04554542526602745,
                -0.02980930730700493,
                0.04898441210389137,
                -0.010146357119083405,
                0.051600903272628784,
                -0.06179806962609291,
                -0.052348580211400986,
                0.013992704451084137,
                -0.03218076378107071,
                0.020394019782543182,
                0.02346699871122837,
                -0.06547416001558304,
                -0.009821131825447083,
                -0.03569295257329941,
                -0.13137389719486237,
                -0.0031832589302212,
                -0.03238298371434212,
                -0.03153001889586449,
                -0.04964027553796768,
                0.012663577683269978,
                0.028664829209446907,
                0.02775745838880539,
                0.031525708734989166,
                -0.03220900148153305,
                0.03289236128330231,
                0.01683220826089382,
                0.027906861156225204,
                0.03193750977516174,
                0.03136911615729332,
                -0.004642515443265438,
                0.007213315926492214,
                -0.053079474717378616,
                0.004148732405155897,
                0.019572926685214043,
                -0.063284732401371,
                0.01114953588694334,
                -0.04892478883266449,
                -0.030298570170998573,
                -0.00044496971531771123,
                -0.06621226668357849,
                0.002749077742919326,
                -0.01343902014195919,
                0.048560887575149536,
                0.021198280155658722,
                0.048771873116493225,
                -0.03599419817328453,
                0.0057337661273777485,
                0.009887425228953362,
                0.014776825904846191,
                0.014084096997976303,
                0.036840733140707016,
                -0.04511147737503052,
                -0.04493017867207527,
                -0.013574500568211079,
                0.008346745744347572,
                -0.018853172659873962,
                -0.015105212107300758,
                -0.011128788813948631,
                0.01918630674481392,
                0.07010512053966522,
                0.01792251504957676,
                -0.025990892201662064,
                -0.02990584820508957,
                -0.04099308326840401,
                -0.05613857880234718,
                0.03312128782272339,
                0.02954707108438015,
                0.042406562715768814,
                0.000991281820461154,
                -0.04429607838392258,
                0.018615631386637688,
                0.043364785611629486,
                0.016216617077589035,
                0.06497608870267868,
                -0.04051780328154564,
                -0.016369590535759926,
                0.02954244427382946,
                0.02994082123041153,
                -0.06414277106523514,
                -0.05192714184522629,
                0.03953917697072029,
                -0.01729894056916237,
                0.024270012974739075,
                0.06190180778503418,
                0.01804567687213421,
                -0.008492991328239441,
                0.030977919697761536,
                0.0037084727082401514,
                -0.06687548011541367,
                -0.03562728688120842,
                -0.028566677123308182,
                0.015846626833081245,
                -0.019459547474980354,
                -0.033825311809778214,
                -0.002619563601911068,
                0.03516901656985283,
                -0.05878780409693718,
                0.054750971496105194,
                0.0006101186736486852,
                -0.031814705580472946,
                0.026990456506609917,
                0.029701733961701393,
                0.003185539273545146,
                0.022943735122680664,
                0.01461164839565754,
                0.014822534285485744,
                0.030346473678946495,
                -0.008262942545115948,
                -0.040577661246061325,
                -0.05762205272912979,
                0.006542000453919172,
                -0.0586378313601017,
                0.02339513786137104,
                -0.012307762168347836,
                -0.08864758163690567,
                -0.07513608783483505,
                -0.00011689822713378817,
                -0.010955115780234337,
                -0.053393349051475525,
                0.029782667756080627,
                0.010577485896646976,
                -0.04205263778567314,
                0.07104633748531342,
                -0.002287892624735832,
                0.022412508726119995,
                -0.012851283885538578,
                -0.019275866448879242,
                0.05586699768900871,
                -0.13582107424736023,
                0.05054124444723129,
                -0.010703439824283123,
                0.09726331382989883,
                -0.057627614587545395,
                -0.02853476256132126,
                0.05015247315168381,
                -0.010033379308879375,
                0.03715403750538826,
                0.05159702152013779,
                0.04355259984731674,
                -0.04075862467288971,
                0.005869013257324696,
                0.014550627209246159,
                -0.0968172624707222,
                -0.057895954698324203,
                0.05387657880783081,
                -0.028974004089832306,
                -0.02593744918704033,
                -0.08691650629043579,
                -0.026042528450489044,
                -0.037897989153862,
                0.04426870495080948,
                0.028055332601070404,
                0.022244006395339966,
                -0.02820119820535183,
                -0.03548610210418701,
                0.0047474997118115425,
                0.017833909019827843,
                0.016037706285715103,
                -0.0011887599248439074,
                0.00598182762041688,
                0.040339257568120956,
                -0.01368619967252016,
                -0.009016011841595173,
                0.04278485104441643,
                -0.013615465722978115,
                -0.0385756641626358,
                0.005248690024018288,
                0.014897646382451057,
                -0.039727188646793365,
                -0.06514757871627808,
                0.08302658051252365,
                0.01526073832064867,
                0.02278311923146248,
                -0.006426510401070118,
                -0.01356667559593916,
                -0.06790265440940857,
                0.06569284945726395,
                0.016752714291214943,
                0.03135012090206146,
                0.000777163018938154,
                -0.046810321509838104,
                0.02096227928996086,
                0.07730310410261154,
                -0.002490581478923559,
                0.03670106083154678,
                0.019097138196229935,
                -0.00600597495213151,
                -0.01800587959587574,
                0.11051198840141296,
                -0.007594706956297159,
                0.050365667790174484,
                0.013796214014291763,
                -0.07934096455574036,
                0.02202157862484455,
                0.027334239333868027,
                -0.05375078693032265,
                -0.047221895307302475,
                0.048455026000738144,
                -0.028901947662234306,
                0.05136483907699585,
                -0.024811265990138054,
                -0.005307394545525312,
                -0.037736158818006516,
                -0.014615282416343689,
                0.0029780841432511806,
                0.005844283849000931,
                0.02279272861778736,
                0.022785501554608345,
                -0.10147178918123245,
                -0.0348171591758728,
                -0.007898041978478432,
                -0.03375696390867233,
                0.08726649731397629,
                0.0022942645009607077,
                -0.060814712196588516,
                0.008046377450227737,
                0.012653973884880543,
                0.017244745045900345,
                -0.05342814326286316,
                -0.009562467224895954,
                0.006249184720218182,
                -0.004096814896911383,
                0.056249313056468964,
                -0.014125038869678974,
                0.01655614748597145,
                0.003649455960839987,
                -0.06540664285421371,
                0.012342375703155994,
                -0.023816680535674095,
                -0.03363434597849846,
                0.03918880224227905,
                0.006198334973305464,
                -6.469024592483368e-33,
                -0.030679043382406235,
                -0.030353639274835587,
                -0.010800332762300968,
                0.02152368053793907,
                -0.06020120158791542,
                0.005344487726688385,
                -0.01783549040555954,
                0.007186711300164461,
                -0.04220428317785263,
                -0.00144868902862072,
                -0.012147033587098122,
                -0.015840616077184677,
                0.03901638090610504,
                -0.043339770287275314,
                -0.012887614779174328,
                0.012787971645593643,
                0.01302396971732378,
                0.007301562000066042,
                -0.014347793534398079,
                -0.06297638267278671,
                0.024944623932242393,
                0.010304590687155724,
                -0.026910988613963127,
                0.04324568808078766,
                0.014149154536426067,
                0.009320731274783611,
                -0.005286617204546928,
                0.022584768012166023,
                0.05308350920677185,
                0.014844965189695358,
                0.026556381955742836,
                -0.0035348436795175076,
                0.03297847509384155,
                0.06465442478656769,
                -0.027270806953310966,
                0.04614865407347679,
                -0.09249015897512436,
                -0.013052953407168388,
                0.003721319604665041,
                -0.00648883730173111,
                -0.004338862374424934,
                -0.03329906985163689,
                -0.04129404202103615,
                0.00932338833808899,
                -0.06644761562347412,
                -0.03661549836397171,
                -0.03382685407996178,
                -0.027520088478922844,
                -0.06266030669212341,
                0.0034805452451109886,
                0.024206988513469696,
                -0.03655865043401718,
                0.024883799254894257,
                0.045840200036764145,
                0.018988830968737602,
                0.037386324256658554,
                0.014588942751288414,
                -0.003945429343730211,
                0.033384472131729126,
                0.0035466253757476807,
                -0.004537477623671293,
                0.02011057361960411,
                0.014444665051996708,
                -0.031158294528722763,
                0.04410644248127937,
                -0.009109199047088623,
                -0.04084763675928116,
                0.03156564012169838,
                0.017603639513254166,
                -0.018371786922216415,
                -0.0013106317492201924,
                0.07393720746040344,
                0.0175358634442091,
                0.011325587518513203,
                -0.08564456552267075,
                -0.027180053293704987,
                0.007775704842060804,
                0.03734579682350159,
                -0.026613503694534302,
                0.009412919171154499,
                0.016759265214204788,
                -0.022435262799263,
                0.0005182911991141737,
                0.009306876920163631,
                -0.058128006756305695,
                -0.020147081464529037,
                -0.02637980319559574,
                -0.01054435409605503,
                0.04219278320670128,
                0.010688981041312218,
                0.024508200585842133,
                0.023663856089115143,
                0.009459960274398327,
                -0.006139600183814764,
                0.04032178595662117,
                -0.041449613869190216,
                0.07537514716386795,
                -0.00530517753213644,
                -0.006561486981809139,
                0.008544763550162315,
                0.015871476382017136,
                -0.03722603991627693,
                0.022203896194696426,
                0.03462355211377144,
                8.462099503958598e-05,
                -0.011493833735585213,
                -0.0377516970038414,
                -0.03135869279503822,
                -0.009381267242133617,
                0.03325681760907173,
                0.006985986605286598,
                0.013190299272537231,
                0.024330811575055122,
                -0.018454907462000847,
                -0.05410926789045334,
                0.02069244720041752,
                0.04743700847029686,
                0.009745825082063675,
                -0.04975024610757828,
                -0.03480266407132149,
                0.07936529815196991,
                -0.01842023804783821,
                -0.021547911688685417,
                0.05104973167181015,
                0.0049426923505961895,
                -0.0018611254636198282,
                -0.012782528065145016,
                0.03308062627911568,
                0.003990617580711842,
                0.051803652197122574,
                0.01003300305455923,
                -0.0233412217348814,
                2.1299258889939665e-07,
                0.022153666242957115,
                0.04364145174622536,
                0.013781093992292881,
                0.016962865367531776,
                0.021435217931866646,
                -0.0007673749350942671,
                -0.04657386243343353,
                0.026150325313210487,
                -0.04148977994918823,
                -0.0009520733146928251,
                -0.017415326088666916,
                -0.024916362017393112,
                0.006580003071576357,
                -0.05605460703372955,
                0.035288020968437195,
                -0.03700140118598938,
                0.050253693014383316,
                -0.037060145288705826,
                0.0018053428502753377,
                -0.015510449185967445,
                0.01756911352276802,
                -0.022790389135479927,
                -0.061547767370939255,
                -0.0025858983863145113,
                0.00647442601621151,
                -0.05292883515357971,
                0.012141205370426178,
                -0.09706564247608185,
                -0.0034442937467247248,
                -0.008353231474757195,
                0.033586014062166214,
                0.009831737726926804,
                0.06804044544696808,
                0.011832263320684433,
                0.013370105065405369,
                -0.005283675156533718,
                0.030772574245929718,
                0.06790877133607864,
                -0.02997533418238163,
                0.017921509221196175,
                -0.012954555451869965,
                -0.08990520983934402,
                0.0108222346752882,
                -0.06217743828892708,
                -0.008205645717680454,
                0.05286822095513344,
                0.011604495346546173,
                0.032949794083833694,
                -0.072644904255867,
                -0.027066677808761597,
                0.017230357974767685,
                0.0008530586492270231,
                -0.014447202906012535,
                -0.0026854840107262135,
                0.033447347581386566,
                -0.008496462367475033,
                0.03628069534897804,
                0.036335065960884094,
                0.04392864927649498,
                -0.03104524314403534,
                -0.01388030406087637,
                -0.01967407949268818,
                -0.001705807400867343,
                0.021118074655532837,
                0.01855100318789482,
                -0.010935183614492416,
                -0.025113193318247795,
                1.7651442429194546e-34,
                -0.019263293594121933,
                -0.060657400637865067,
                -0.011977859772741795,
                0.031898632645606995,
                0.011958492919802666,
                -0.02205563522875309,
                0.012766828760504723,
                -0.012992550618946552,
                -0.015722885727882385,
                -0.038756176829338074,
                -0.005084170959889889,
                0.028575608506798744,
                0.055606499314308167,
                0.00532152596861124,
                0.04766371473670006,
                -0.07199744880199432,
                0.01477876678109169,
                -0.015843460336327553,
                0.018247559666633606,
                0.005532010458409786,
                -0.007315287832170725,
                0.036909718066453934,
                -0.019346607849001884,
                0.0030987823847681284,
                0.026646655052900314,
                0.007635476067662239,
                0.0546007975935936,
                0.0010978311765938997,
                -0.0022576646879315376,
                -0.04301063343882561,
                0.007145163137465715,
                0.012089140713214874,
                0.043035898357629776,
                -0.007918618619441986,
                0.004814911633729935,
                0.0101393423974514,
                -0.02178097888827324,
                -0.004258804023265839,
                -0.011789064854383469,
                -0.0036139418371021748,
                0.048546917736530304,
                0.010978366248309612,
                -0.00032257509883493185,
                0.0014601872535422444,
                0.028256731107831,
                1.7619978507354972e-06,
                -0.01633819378912449,
                -0.037994492799043655,
                -0.00239093997515738,
                -0.05523185431957245,
                0.07076789438724518,
                0.02540346421301365,
                -0.03025927022099495,
                -0.0041429148986935616,
                0.01660146191716194,
                0.005698796361684799,
                -0.018563436344265938,
                0.024598337709903717,
                -0.00179840880446136,
                -0.010852647945284843,
                0.0023555706720799208,
                -0.007049261592328548,
                0.004727689549326897,
                -0.059780072420835495,
                -0.0403793565928936,
                -0.06570901721715927,
                -0.03988688439130783,
                0.010308273136615753,
                0.019586103036999702,
                0.0004651982453651726,
                -0.0020785967353731394,
                -0.03242846950888634,
                -0.015428995713591576,
                -0.012264600023627281,
                -0.03738166019320488,
                0.0404592864215374,
                -0.01538259256631136,
                0.045799411833286285,
                0.011590586975216866,
                -0.0037334272637963295,
                -0.048589568585157394,
                0.022610124200582504,
                0.012686262838542461,
                0.01385373342782259,
                0.03492658585309982,
                -0.0214088037610054,
                0.018071765080094337,
                0.02872292324900627,
                0.016022589057683945,
                -0.06836763769388199,
                -0.03503333777189255,
                -0.008996671997010708,
                -0.002122305566444993,
                -0.00042835852946154773,
                0.02174648642539978,
                0.07410269230604172,
                -0.12128325551748276,
                -0.00013723759911954403,
                -0.026475943624973297,
                -0.00625311816111207,
                0.08219145238399506,
                0.051079556345939636,
                -0.04116227850317955,
                0.005383067298680544,
                0.04813329130411148,
                0.017811689525842667,
                -0.02222665399312973,
                0.02078869566321373,
                0.055108048021793365,
                -0.00632094219326973,
                0.10477793961763382,
                0.00738149369135499,
                -0.0284729041159153,
                -0.0499824583530426,
                0.044708214700222015,
                -0.0020330066327005625,
                0.06728904694318771,
                0.004320709966123104,
                0.049627870321273804,
                -0.05596403405070305,
                0.058694999665021896,
                0.018378742039203644,
                -0.013685786165297031,
                -0.014329968951642513,
                0.0627855658531189,
                0.019681651145219803,
                -0.01510531920939684,
                -0.042373836040496826,
                -0.007134183309972286,
                -0.02038208208978176,
                0.05527752265334129,
                -0.013032198883593082,
                0.036617305129766464,
                0.012208492495119572,
                -0.032872918993234634,
                0.018093815073370934,
                -0.0914384201169014,
                -0.03201531618833542,
                0.030252503231167793,
                0.017873873934149742,
                0.06554292142391205,
                0.00961911678314209,
                -0.018481900915503502,
                0.016237588599324226,
                0.017204489558935165,
                0.009680377319455147,
                0.04326241463422775,
                -0.0010951176518574357,
                0.021523285657167435,
                -0.05659983679652214,
                -0.03782885521650314,
                -0.018191523849964142,
                0.0009588886168785393,
                -0.03115883655846119,
                -0.024393893778324127,
                -0.04421009495854378,
                -0.08145192265510559,
                -0.0067380210384726524,
                0.06788642704486847,
                -0.010051502846181393,
                -0.03228078782558441,
                0.004348047077655792,
                -0.010025737807154655,
                0.018183713778853416,
                -0.012540636584162712,
                -0.018444832414388657,
                0.019760780036449432,
                0.06101318821310997,
                -0.010932878591120243,
                -0.044434502720832825,
                -0.042283378541469574,
                0.056116677820682526,
                0.019283518195152283,
                0.056137170642614365,
                -0.008732267655432224,
                0.030057545751333237,
                0.022040775045752525,
                -0.0024211397394537926,
                0.0066330041736364365,
                -0.047459956258535385,
                -0.055280521512031555,
                -0.002301151165738702,
                2.534948907850776e-05,
                -0.028210440650582314,
                0.0209338441491127,
                -0.0029077641665935516,
                0.026125220581889153,
                -0.012363762594759464,
                -0.0337333083152771,
                -0.037270575761795044,
                -0.05118458345532417,
                -0.06224599480628967,
                -0.018770849332213402,
                0.018710222095251083,
                0.011805346235632896,
                0.02303982898592949,
                0.011497206054627895,
                -0.006163303274661303,
                -0.05348706990480423,
                -0.02045772783458233,
                -0.026912368834018707,
                -0.02822965942323208,
                -0.014555255882441998,
                -0.09992916882038116,
                0.008758028969168663,
                -0.022100653499364853,
                0.020407896488904953,
                0.002085293410345912,
                -0.07413054257631302,
                -0.008854448795318604,
                -0.02539111115038395,
                0.004741771146655083,
                -0.07085899263620377,
                0.1180565357208252,
                0.084397092461586,
                -0.02864200621843338,
                0.012731915339827538,
                0.04749487340450287,
                -0.040738336741924286,
                -0.04214835539460182,
                -0.018936794251203537,
                -0.01250095572322607,
                -0.01225767657160759,
                0.03754796087741852,
                0.025511877611279488,
                -0.03651677817106247,
                -0.017516758292913437,
                0.014431764371693134,
                0.024748705327510834,
                -0.026644745841622353,
                0.045331843197345734,
                -0.009512149728834629,
                -0.012623302638530731,
                -0.01212447788566351,
                0.011359191499650478,
                0.026700500398874283,
                -0.10471374541521072,
                0.01586003042757511,
                0.025388874113559723,
                -0.024780886247754097,
                -0.006134344264864922,
                0.015477145090699196,
                0.06120402738451958,
                -0.001293480396270752,
                0.019839100539684296,
                0.031943049281835556,
                0.025728460401296616,
                0.14487579464912415,
                0.015388751402497292,
                -0.019298654049634933,
                -0.06407520920038223,
                0.002494213404133916,
                -0.029034527018666267,
                -0.030531207099556923,
                0.016992533579468727,
                0.007642694748938084,
                0.01487770676612854,
                0.04999973997473717,
                -0.04582970216870308,
                -0.010307008400559425,
                -0.037193663418293,
                -0.04847138375043869,
                -0.010856406763195992,
                0.0435214564204216,
                -0.007190884090960026,
                0.05206427350640297,
                -0.04249457269906998,
                -0.008313685655593872,
                0.001438691164366901,
                0.009773214347660542,
                -0.010325778275728226,
                -0.0037516290321946144,
                -0.027863098308444023,
                0.003641162533313036,
                0.04774143174290657,
                0.04951273277401924,
                -0.04402162507176399,
                -0.02872488833963871,
                -0.019232962280511856,
                -0.031507592648267746,
                0.007239336147904396,
                0.02179465815424919,
                0.0027172209229320288,
                0.02528110332787037,
                0.005188407376408577,
                0.01742827519774437,
                -0.0014290313702076674,
                -0.09779041260480881,
                -0.0011002309620380402,
                -0.006129339803010225,
                0.020381540060043335,
                -0.013999720104038715,
                -0.0008940074476413429,
                -0.022473188117146492,
                -0.008263695053756237,
                0.03356098383665085,
                0.034817907959222794,
                0.032811474055051804,
                -0.003870998742058873,
                -0.07461461424827576,
                -0.005473469849675894,
                0.00027970867813564837,
                -0.04791794344782829,
                0.053567759692668915,
                -0.01787511818110943,
                0.01714625023305416,
                -0.02659471519291401,
                -0.01394903939217329,
                0.07168275117874146,
                -0.01843564584851265,
                0.04278343915939331,
                -0.016885176301002502,
                -0.016410812735557556,
                0.013473079539835453,
                -0.044636040925979614,
                0.03570786491036415,
                0.0036215486470609903,
                0.09179000556468964,
                0.027414068579673767,
                0.029492337256669998,
                -0.021708793938159943,
                0.020843705162405968,
                -0.0085298428311944,
                0.0240811537951231,
                0.03362131118774414,
                -0.011635664850473404,
                0.00368271442130208,
                -0.022365741431713104,
                -0.03044631890952587,
                -0.03080131486058235,
                0.053248871117830276,
                0.015353710390627384,
                0.007866607047617435,
                0.01510919164866209,
                0.020324215292930603,
                0.02584138512611389,
                0.04439915344119072,
                -0.0008176156552508473,
                0.024013780057430267,
                0.08112094551324844,
                -0.034570593386888504,
                -0.004731996916234493,
                -0.045040033757686615,
                -0.04280204325914383,
                0.055397648364305496,
                -0.07856353372335434,
                0.09502433985471725,
                -0.0003558482276275754,
                -0.03226007893681526,
                -0.051005832850933075,
                0.0059464192017912865,
                -0.0019751235377043486,
                -0.017936263233423233,
                0.00895449984818697,
                -0.04933712258934975,
                -0.03292667493224144,
                -0.026617998257279396,
                -0.0396299809217453,
                0.07273585349321365,
                -0.07557905465364456,
                -0.019791515544056892,
                -0.027898672968149185,
                0.045350100845098495,
                -0.01162702776491642,
                -0.03469443321228027,
                0.012251228094100952,
                0.0012430615024641156,
                -0.08429154008626938,
                -0.09276486933231354,
                -0.004600296262651682,
                -0.1133226826786995,
                -0.01812412217259407,
                -0.0015573989367112517,
                0.05563964694738388,
                0.07376622408628464,
                0.04639020189642906,
                -0.0024326264392584562,
                0.04447992146015167,
                0.038151830434799194,
                0.005132167134433985,
                -0.0011202329769730568,
                -0.060530614107847214,
                -0.002627098001539707,
                -0.06304435431957245,
                0.04230659827589989,
                0.013817058876156807,
                0.0022585741244256496,
                0.03964505344629288,
                0.0016959395725280046,
                -0.07294786721467972,
                -0.02649194747209549,
                0.023699652403593063,
                -0.06271025538444519,
                0.02701426111161709,
                -0.01605207286775112,
                0.04519405588507652,
                -0.01989256590604782,
                -0.03370446711778641,
                -0.01454190257936716,
                -0.037323109805583954,
                0.017742879688739777,
                0.04034093767404556,
                0.005312452092766762,
                -0.05759846419095993,
                0.06009600684046745,
                -0.02052083984017372,
                -0.08059860020875931,
                -0.030026400461792946,
                -0.015509391203522682,
                0.023965895175933838,
                0.0024919076822698116,
                0.04573177173733711,
                -0.06205850467085838,
                0.06264515221118927,
                -0.002606884343549609,
                -0.04034688323736191,
                -0.0538550429046154,
                0.07978703081607819,
                0.017362574115395546,
                0.0019913448486477137,
                -0.02987724170088768,
                0.01503219548612833,
                -0.00869221705943346,
                -0.0027428255416452885,
                0.04277695715427399,
                0.024286339059472084,
                -0.020279478281736374,
                -0.01991896703839302,
                0.006877405568957329,
                0.04557512700557709,
                -0.01943868398666382,
                0.053234975785017014,
                0.027845246717333794,
                0.0045515066012740135,
                -0.016823483631014824,
                0.0026250171940773726,
                0.027056146413087845,
                0.02470482885837555,
                0.04342865198850632,
                0.016461802646517754,
                -0.00037671069731004536,
                0.020001379773020744,
                -0.02285601943731308,
                0.00808371789753437,
                0.001037220237776637,
                -0.021388474851846695,
                0.0483650341629982,
                -0.019000981003046036,
                0.027238545939326286,
                0.0115544768050313,
                -0.06341953575611115,
                -0.023247171193361282,
                0.03739215433597565,
                0.04802211746573448,
                0.014069863595068455,
                -0.05019082501530647,
                0.0044971732422709465,
                -0.046449389308691025,
                0.01811106875538826,
                -0.04978620633482933,
                -0.018331006169319153,
                0.08020427823066711,
                -0.007504476234316826,
                0.022795425727963448,
                0.0036930686328560114,
                0.07694564014673233,
                0.0080094113945961,
                0.0001186835506814532,
                -0.06355664879083633,
                0.012289639562368393,
                -0.0013209665194153786,
                0.007950203493237495,
                0.03724446892738342,
                0.04152540862560272,
                -0.03815427049994469,
                0.08269467949867249,
                -0.09602746367454529,
                -0.06726904213428497,
                -0.0828355997800827,
                -0.03712748363614082,
                0.01818915084004402,
                0.014030557125806808,
                -0.005841810721904039,
                -0.012242167256772518,
                -0.03520994260907173,
                -0.020541703328490257,
                0.029370538890361786,
                -0.05132240056991577,
                -0.019584381952881813,
                -0.026321718469262123,
                0.017454806715250015,
                -0.019057974219322205,
                -0.03878023102879524,
                0.00804236438125372,
                -0.0005887603620067239,
                -0.03321389481425285,
                -0.03126087784767151,
                0.019876262173056602,
                -0.006576495245099068,
                0.010659357532858849,
                -0.019161468371748924,
                0.0548117570579052,
                -0.02767302840948105,
                -0.005752130877226591,
                0.06597957015037537,
                -0.05738777294754982,
                0.00790411327034235,
                -0.04796566441655159,
                -0.03465792536735535,
                0.01282565388828516,
                0.018020447343587875,
                -0.04019198194146156,
                0.013405120931565762,
                -0.027590589597821236,
                -0.017984192818403244,
                -0.015430507250130177,
                0.048574790358543396,
                -0.00030371887260116637,
                0.011401090770959854,
                -0.010891051031649113,
                0.05060471594333649,
                0.005538508761674166,
                -0.010761208832263947,
                -0.027371669188141823,
                0.04340822994709015,
                0.020483529195189476,
                0.02433115616440773,
                -0.036939382553100586,
                -0.041024621576070786,
                -0.02920885942876339,
                -0.018215980380773544,
                0.028799381107091904,
                0.026437124237418175,
                0.07084208726882935,
                -0.00038836232852190733,
                0.008620116859674454,
                -0.021910410374403,
                0.01727723516523838,
                -0.02692093513906002,
                -0.006642615422606468,
                0.039787955582141876,
                -0.03864956647157669,
                -0.02271270379424095,
                -0.06990038603544235,
                -0.00013631470210384578,
                -0.004768481478095055,
                0.024922948330640793,
                0.02740032784640789,
                -0.013052484951913357,
                0.004278144799172878,
                -0.007851839996874332,
                -0.021509837359189987,
                0.002098033670336008,
                0.0031826039776206017,
                0.046464014798402786,
                -0.02581247128546238,
                0.004895768593996763,
                0.018542110919952393,
                0.035886239260435104,
                0.03339832276105881,
                0.05994275584816933,
                -0.02120034024119377,
                -0.01179944071918726,
                -0.03686274215579033,
                0.009937679395079613,
                -0.040822919458150864,
                -5.780393553148779e-33,
                -0.008369330316781998,
                -0.0775999054312706,
                0.016502991318702698,
                0.029982808977365494,
                -0.02414591982960701,
                0.03603515028953552,
                -0.009928743354976177,
                0.01169110368937254,
                -0.012805183418095112,
                -0.026093000546097755,
                -0.02452816441655159,
                0.03833550587296486,
                0.0189137551933527,
                -0.026208845898509026,
                0.053673308342695236,
                0.005017874296754599,
                0.04063088819384575,
                0.024697119370102882,
                0.010012146085500717,
                -0.058432891964912415,
                -0.01785772666335106,
                0.016897333785891533,
                0.015902921557426453,
                -0.003666570410132408,
                0.05946313589811325,
                0.046857867389917374,
                -0.031133543699979782,
                0.035891298204660416,
                -0.015292681753635406,
                -0.016878994181752205,
                -0.01408512145280838,
                -0.04747068136930466,
                -0.0362519770860672,
                0.005376547574996948,
                0.036992624402046204,
                0.013638033531606197,
                -0.02404850907623768,
                -0.05737272650003433,
                0.08045671135187149,
                0.023126835003495216,
                -0.05497095361351967,
                -0.04690682142972946,
                0.032429084181785583,
                0.02563376910984516,
                -0.003946950193494558,
                -0.0385771207511425,
                -0.02623722516000271,
                -0.0033846674486994743,
                -0.01024063490331173,
                0.008279172703623772,
                -0.053636547178030014,
                0.007926125079393387,
                -0.03708597645163536,
                0.015488755889236927,
                0.01456311997026205,
                0.0675438791513443,
                -0.019140319898724556,
                -0.09370949119329453,
                0.010854462161660194,
                0.008124250918626785,
                0.05091629549860954,
                0.0018534064292907715,
                -0.029050176963210106,
                0.014051004312932491,
                0.004232500214129686,
                0.02197130396962166,
                -0.025196705013513565,
                -0.015980638563632965,
                -0.009152264334261417,
                0.020197639241814613,
                0.0031232100445777178,
                0.047509655356407166,
                -0.005388540681451559,
                0.03193233907222748,
                -0.0802999958395958,
                -0.002792731858789921,
                0.008753985166549683,
                0.03898622468113899,
                0.06685483455657959,
                0.006569602992385626,
                0.036795634776353836,
                -0.03269469738006592,
                -0.01420719176530838,
                -0.00465700076892972,
                0.013467908836901188,
                -0.04738524183630943,
                -0.03588096424937248,
                0.08152756094932556,
                -0.015610337257385254,
                0.040946297347545624,
                -0.020503731444478035,
                -0.013649511151015759,
                -0.030905073508620262,
                -0.003366931574419141,
                0.048721883445978165,
                -0.03184128925204277,
                0.018281321972608566,
                -0.013776983134448528,
                -0.029522458091378212,
                -0.026764467358589172,
                -0.01190666202455759,
                0.049071431159973145,
                0.02112746797502041,
                0.06030198186635971,
                0.034415557980537415,
                -0.011526529677212238,
                7.530347647843882e-05,
                0.015301973558962345,
                -0.035100795328617096,
                0.033489421010017395,
                0.013948817737400532,
                0.003214192111045122,
                -0.003302003024145961,
                0.020901761949062347,
                0.025646870955824852,
                0.018827756866812706,
                0.0016788170905783772,
                -0.03543386235833168,
                -0.03353733569383621,
                0.005843611899763346,
                0.01747290790081024,
                0.0621202327311039,
                -0.01538111548870802,
                0.01646820642054081,
                -0.04606017470359802,
                -0.0267561674118042,
                0.04202772676944733,
                -0.03744615614414215,
                0.018004558980464935,
                -0.00010325102630304173,
                -0.01776530221104622,
                0.016162674874067307,
                2.259496341139311e-07,
                -0.006258252542465925,
                -0.050857216119766235,
                0.038375385105609894,
                -0.06583222001791,
                0.0237218476831913,
                -0.013599211350083351,
                0.014033980667591095,
                0.0192188061773777,
                -0.02906007133424282,
                -0.031744882464408875,
                0.02545126900076866,
                -0.07034415006637573,
                0.05157657340168953,
                -0.01945682428777218,
                0.030635522678494453,
                -0.030910726636648178,
                -0.09055298566818237,
                -0.008194556459784508,
                0.008198798634111881,
                -0.023349696770310402,
                -0.014006420969963074,
                0.028758704662322998,
                0.012267710641026497,
                -0.042827557772397995,
                -0.008400682359933853,
                -0.07822523266077042,
                -0.0020639721769839525,
                -0.005739567801356316,
                -0.024214264005422592,
                -0.06229131668806076,
                0.06977573037147522,
                0.035125695168972015,
                0.023258250206708908,
                0.0414603129029274,
                0.002224238123744726,
                -0.01671735942363739,
                0.009519780054688454,
                0.024752754718065262,
                0.009938995353877544,
                0.02314480021595955,
                -0.04478010907769203,
                -0.08154045790433884,
                -0.0006939253653399646,
                0.0216220635920763,
                0.0398949570953846,
                0.08967068046331406,
                0.026073290035128593,
                -0.003383977571502328,
                0.02019312232732773,
                -0.042462047189474106,
                0.01469821110367775,
                0.028412742540240288,
                -0.0009989605750888586,
                0.07461552321910858,
                0.015121198259294033,
                -0.0011795838363468647,
                0.01393839344382286,
                0.05662257596850395,
                0.05049312487244606,
                0.036106642335653305,
                -0.009348921477794647,
                0.015431415289640427,
                0.017784597352147102,
                0.035008836537599564,
                -0.02765016444027424,
                -0.05701563507318497,
                0.020368529483675957,
                8.542879939839531e-35,
                -0.023381005972623825,
                -0.01878003589808941,
                -0.030061867088079453,
                -0.046231210231781006,
                0.00817139446735382,
                0.01573154330253601,
                -0.010288940742611885,
                -0.016347959637641907,
                0.02703448385000229,
                0.039591092616319656,
                -0.013225738890469074,
                0.03783424198627472,
                -0.030108259990811348,
                0.01849617436528206,
                0.03832891955971718,
                -0.06316224485635757,
                0.01867191679775715,
                0.07463271915912628,
                0.020971987396478653,
                -0.08362531661987305,
                -0.021969081833958626,
                0.10489542037248611,
                -0.014469954185187817,
                0.020191336050629616,
                0.053773704916238785,
                0.016785478219389915,
                0.03641199320554733,
                0.0073065138421952724,
                0.0014666827628389,
                0.03576269373297691,
                0.016653476282954216,
                -0.004310412332415581,
                0.04300713911652565,
                -0.01449019368737936,
                0.029008835554122925,
                -0.051902178674936295,
                0.009095339104533195,
                -0.03418094664812088,
                -0.022498376667499542,
                0.019822755828499794,
                0.011314150877296925,
                0.013128497637808323,
                -0.007866640575230122,
                -0.0010013345163315535,
                0.015702543780207634,
                1.4200386431184597e-06,
                -0.022536981850862503,
                -0.006226188503205776,
                -0.004425340797752142,
                -0.015079496428370476,
                -0.004239243920892477,
                -0.009870647452771664,
                0.009090534411370754,
                -0.051474232226610184,
                0.024359727278351784,
                -0.010856366716325283,
                -0.005886657629162073,
                0.033704206347465515,
                0.03498154878616333,
                0.0009515798883512616,
                0.02858356013894081,
                0.007645375095307827,
                0.005064936820417643,
                -0.08310023695230484,
                -0.012354079633951187,
                0.014487748965620995,
                -0.0012422860600054264,
                0.01012592762708664,
                0.006134048104286194,
                0.02961304597556591,
                0.02006549760699272,
                0.04122750461101532,
                -0.05369442701339722,
                -0.006867008283734322,
                -0.012743422761559486,
                -0.013343246653676033,
                0.0002555774699430913,
                -0.008020926266908646,
                -0.010426957160234451,
                0.049818914383649826,
                -0.003959009423851967,
                -0.0028272138442844152,
                0.023476218804717064,
                0.00404678750783205,
                0.07957474887371063,
                -0.009196607396006584,
                -0.03305531665682793,
                0.009627655148506165,
                0.03666296601295471,
                -0.04495818540453911,
                -0.0023504882119596004,
                0.033890847116708755,
                0.03687477856874466,
                -0.02694476582109928,
                0.016663867980241776,
                0.017826467752456665,
                -0.005938197951763868,
                -0.02465873770415783,
                0.005971753969788551,
                -0.0672038272023201,
                0.008095668628811836,
                0.07955572754144669,
                -0.01250291708856821,
                0.0037979991175234318,
                0.03858613967895508,
                -0.008178330026566982,
                -0.009213204495608807,
                0.024017686024308205,
                0.01782717928290367,
                0.03318703547120094,
                -0.07487119734287262,
                -0.033585138618946075,
                0.03799513354897499,
                -0.0045984056778252125,
                0.0237287450581789,
                0.0324685238301754,
                -0.002941684564575553,
                0.0064176837913692,
                -0.006491932086646557,
                -0.023489614948630333,
                0.032292626798152924,
                0.013792479410767555,
                0.019659435376524925,
                -0.022832894697785378,
                0.013037160038948059,
                -0.017050420865416527,
                0.01390425581485033,
                0.04433838650584221,
                0.0019326240289956331,
                0.013004450127482414,
                0.03548285365104675,
                0.014420680701732635,
                0.008989275433123112,
                -0.09111342579126358,
                -0.0044629210606217384,
                0.017542222514748573,
                -0.031069865450263023,
                -0.05679776147007942,
                0.0325617641210556,
                0.01790361851453781,
                0.05323201045393944,
                -0.026916947215795517,
                0.0010622793342918158,
                0.021835971623659134,
                0.003619822906330228,
                -0.003167478134855628,
                0.03399907797574997,
                -0.04482126608490944,
                -0.004851081874221563,
                -0.13772320747375488,
                -0.03701066970825195,
                0.028148071840405464,
                -0.026493912562727928,
                -0.0044347005896270275,
                0.00028792378725484014,
                -0.02820996195077896,
                -0.026538370177149773,
                -0.017229128628969193,
                0.050016775727272034,
                0.02428697608411312,
                -0.03669590502977371,
                -0.06410675495862961,
                0.01027169544249773,
                -0.055455002933740616,
                0.019664471969008446,
                -0.008190932683646679,
                0.040719326585531235,
                0.048343218863010406,
                -0.0021899419371038675,
                -0.026048894971609116,
                -0.011642040684819221,
                0.0031614156905561686,
                0.0023034752812236547,
                0.03597197309136391,
                -0.02050260826945305,
                0.022642094641923904,
                0.006110294256359339,
                0.019903261214494705,
                -0.018011795356869698,
                -0.08752008527517319,
                0.04261041805148125,
                0.03723125532269478,
                0.003952225670218468,
                -0.011192730627954006,
                0.0649353414773941,
                -0.05590498074889183,
                0.05954645946621895,
                -0.09693814814090729,
                -0.03046705387532711,
                -0.020399803295731544,
                0.003987774718552828,
                -0.051020316779613495,
                -0.0779096707701683,
                0.004735531751066446,
                -0.0030797107610851526,
                0.00836306344717741,
                0.0004850747645832598,
                -0.010817861184477806,
                -0.020098412409424782,
                -0.05452003702521324,
                0.004028068855404854,
                0.011727276258170605,
                -0.009173702448606491,
                -0.020530875772237778,
                -0.013032540678977966,
                0.03213478997349739,
                -0.016950860619544983,
                0.01602610945701599,
                -0.05445725470781326,
                -0.027185868471860886,
                0.02351422980427742,
                -0.005990111269056797,
                -0.044872209429740906,
                0.09672942012548447,
                0.0691508799791336,
                -0.015602169558405876,
                0.009397472254931927,
                0.0007323760655708611,
                -0.030779700726270676,
                2.1405321604106575e-05,
                -0.004559698980301619,
                -0.04878972843289375,
                0.012670950032770634,
                0.04147240146994591,
                0.040118683129549026,
                -0.04435072839260101,
                -0.027477560564875603,
                0.047713086009025574,
                0.04636312648653984,
                -0.06603715568780899,
                0.058488622307777405,
                -0.043800339102745056,
                0.03600119426846504,
                -0.007412999402731657,
                -0.026611452922225,
                -0.031073149293661118,
                -0.021923961117863655,
                0.008583382703363895,
                -0.028350329026579857,
                -0.039329785853624344,
                -0.01448901928961277,
                -0.010797816328704357,
                0.012330003082752228,
                -0.0016473557334393263,
                -0.011920559220016003,
                -0.01642392948269844,
                0.05317849665880203,
                0.08578718453645706,
                -0.07323088496923447,
                -0.0010579820955172181,
                -0.09119904041290283,
                0.0057811858132481575,
                -0.07998568564653397,
                -0.0043799676932394505,
                -0.0005936097004450858,
                0.03470168262720108,
                -0.02881188690662384,
                -0.02906564623117447,
                -0.02512068673968315,
                -0.016081053763628006,
                0.016259782016277313,
                -0.025660427287220955,
                0.004593702498823404,
                0.031987279653549194,
                0.0266253724694252,
                -0.015530533157289028,
                -0.06474818289279938,
                -0.04779105260968208,
                0.06463894248008728,
                -0.038966916501522064,
                -0.023361044004559517,
                0.03460526466369629,
                -0.03874332085251808,
                0.035567231476306915,
                -0.021820994094014168,
                0.02616565302014351,
                -0.00676770368590951,
                0.029583510011434555,
                -0.004887798335403204,
                -0.006838128436356783,
                -0.030543915927410126,
                -0.02993847243487835,
                -0.006405159831047058,
                0.09457337856292725,
                -0.004053794778883457,
                -0.014983417466282845,
                0.01568787172436714,
                -0.1053234338760376,
                0.03434557095170021,
                0.016788300126791,
                0.025389058515429497,
                0.033000167459249496,
                0.009512062184512615,
                -0.022360362112522125,
                -0.01225024089217186,
                0.0043783411383628845,
                0.011957275681197643,
                0.02112305723130703,
                -0.04589229077100754,
                -0.0741591602563858,
                0.06959156692028046,
                -0.003400020068511367,
                -0.024628713726997375,
                0.020944785326719284,
                -0.0716618150472641,
                0.018260406330227852,
                -0.02100302465260029,
                -0.07008897513151169,
                0.093829445540905,
                -0.002191206905990839,
                0.08149212598800659,
                0.0019674082286655903,
                -0.02847541682422161,
                0.015350433997809887,
                -0.01966562122106552,
                -0.004288830794394016,
                -0.008261299692094326,
                0.0877438560128212,
                0.007109228987246752,
                -0.057407036423683167,
                -0.003014659509062767,
                0.0037930828984826803,
                0.012967996299266815,
                0.02751876413822174,
                0.003715332830324769,
                -0.016243062913417816,
                -0.04066670313477516,
                -0.027835829183459282,
                -0.11668507009744644,
                0.005922069773077965,
                0.07171034067869186,
                -0.007174168713390827,
                0.003459483152255416,
                0.005686533637344837,
                0.023485710844397545,
                0.014073437079787254,
                -0.026566850021481514,
                -0.036293745040893555,
                0.01577778346836567,
                0.11117725819349289,
                -0.03830025717616081,
                -0.005972984712570906,
                0.017281463369727135,
                -0.03287408873438835,
                0.07522176206111908,
                -0.023520497605204582,
                0.03790272772312164,
                -0.022809917107224464,
                -0.07412165403366089,
                -0.08470628410577774,
                0.022107619792222977,
                -0.015225393697619438,
                -0.017972886562347412,
                -0.0055771479383111,
                -0.047932080924510956,
                0.02535657212138176,
                -0.056504637002944946,
                -0.06681928038597107,
                0.06479625403881073,
                -0.08276813477277756,
                0.013492162339389324,
                -0.010606358759105206,
                0.05897391960024834,
                -0.009596726857125759,
                0.01243402250111103,
                -0.021756676957011223,
                -0.029112229123711586,
                -0.03819021210074425,
                0.013048572465777397,
                0.0033763046376407146,
                -0.021878471598029137,
                0.0005783565575256944,
                -0.00920790620148182,
                0.058097150176763535,
                -0.03537897765636444,
                -0.017506394535303116,
                0.0016902495408430696,
                0.018626291304826736,
                0.023961346596479416,
                -0.045592330396175385,
                0.005986809730529785,
                0.025298431515693665,
                0.012148605659604073,
                -0.06281520426273346,
                0.03752996399998665,
                -0.0020368793047964573,
                -0.015745896846055984,
                0.009524852968752384,
                0.015837276354432106,
                -0.0661126896739006,
                -0.03468906879425049,
                -0.0009086458012461662,
                -0.009331703186035156,
                0.08060260117053986,
                0.016774216666817665,
                -0.0034284295979887247,
                -0.03640579432249069,
                -0.043382011353969574,
                -0.013126448728144169,
                -0.01674930937588215,
                0.041491568088531494,
                0.016696644946932793,
                0.02635291963815689,
                -0.038357775658369064,
                0.035305481404066086,
                0.025491230189800262,
                -0.03262060135602951,
                0.03752853348851204,
                -0.027962982654571533,
                -0.0065379636362195015,
                0.025928936898708344,
                -0.010407975874841213,
                0.007820932194590569,
                -0.07121592015028,
                0.02255244553089142,
                -0.004243507515639067,
                -0.007528373505920172,
                -0.007319308817386627,
                -0.019131097942590714,
                0.007903656922280788,
                0.057943280786275864,
                0.03808191046118736,
                0.008136359043419361,
                0.020233796909451485,
                0.020519286394119263,
                0.03530346229672432,
                -0.017352301627397537,
                -0.0012105046771466732,
                0.00917962845414877,
                0.04733562842011452,
                -0.043287746608257294,
                0.005178678315132856,
                0.008000081405043602,
                0.02785434015095234,
                -0.06600276380777359,
                0.030427441000938416,
                -0.03165115416049957,
                -0.001939446316100657,
                0.0750090628862381,
                0.010782160796225071,
                0.012346997857093811,
                0.009787793271243572,
                0.00404623057693243,
                0.014103627763688564,
                0.009534049779176712,
                0.054720453917980194,
                0.0016483418876305223,
                0.050646111369132996,
                0.03597639873623848,
                -0.01251701358705759,
                0.010550801642239094,
                -0.03796524181962013,
                0.043484415858983994,
                0.07200675457715988,
                -0.03190687298774719,
                -0.0013594739139080048,
                -0.02054811827838421,
                -0.013376413844525814,
                -0.006855851970613003,
                0.038385625928640366,
                0.08833275735378265,
                0.07413073629140854,
                0.03893137350678444,
                0.10526350885629654,
                -0.07669523358345032,
                0.07113997638225555,
                -0.01626402884721756,
                -0.02425064519047737,
                0.01097463071346283,
                0.013025976717472076,
                0.05296088382601738,
                0.013974922709167004,
                0.05097540095448494,
                0.007477029226720333,
                -0.06431522220373154,
                0.013753923587501049,
                -0.14357024431228638,
                -0.034857816994190216,
                -0.008360334672033787,
                -0.027329066768288612,
                -0.023825613781809807,
                -0.008738820441067219,
                0.02204054221510887,
                -0.026964949443936348,
                -0.0008140093414112926,
                0.05560462176799774,
                -0.022920580580830574,
                -0.02260735258460045,
                -0.07993160933256149,
                -0.009215160273015499,
                0.027317216619849205,
                -0.03707494959235191,
                0.029372088611125946,
                0.0071197450160980225,
                0.015362541191279888,
                -0.025973433628678322,
                -0.009874909184873104,
                0.02113751694560051,
                -0.022523846477270126,
                -0.004842701368033886,
                0.005101796239614487,
                -0.0038047439884394407,
                -0.015710817649960518,
                -0.003888467326760292,
                -0.028328217566013336,
                -0.008987684734165668,
                0.001492587267421186,
                -0.01065229531377554,
                0.0006460367585532367,
                0.03246583044528961,
                -0.06643807142972946,
                -0.028361262753605843,
                0.022023016586899757,
                0.010103774257004261,
                0.010739698074758053,
                -0.011067730374634266,
                0.04451053589582443,
                -0.00011228212679270655,
                0.029325680807232857,
                0.0011355762835592031,
                0.0019912137649953365,
                0.00680172024294734,
                -0.011879293248057365,
                -0.01997908018529415,
                0.06844031810760498,
                0.04095699265599251,
                0.0009205501992255449,
                -0.01293843612074852,
                -0.04224167391657829,
                -0.028498945757746696,
                0.016900790855288506,
                0.034400343894958496,
                0.024551711976528168,
                0.05383625999093056,
                -0.010017125867307186,
                0.011663822457194328,
                0.012620842084288597,
                0.01974469982087612,
                -0.013662828132510185,
                -0.0002670027897693217,
                0.022541912272572517,
                -0.006511946674436331,
                0.00746299559250474,
                -0.025497008115053177,
                0.021884212270379066,
                -0.022808415815234184,
                0.028609436005353928,
                0.048383694142103195,
                0.019605018198490143,
                0.002902766689658165,
                -0.0329689159989357,
                -0.05431143939495087,
                0.037290990352630615,
                -0.06183667853474617,
                0.008147845976054668,
                0.0020761804189532995,
                -0.006965838838368654,
                0.002108984626829624,
                0.04452960193157196,
                0.031308211386203766,
                0.008278417401015759,
                0.013353231362998486,
                0.02028466947376728,
                -0.025212576612830162,
                0.009373228065669537,
                -0.0031616445630788803,
                -5.6965032967763925e-33,
                -0.01619778200984001,
                -0.07929911464452744,
                0.001149525516666472,
                0.04456416517496109,
                -0.04333062469959259,
                -0.022607242688536644,
                -0.006713886745274067,
                0.03018755279481411,
                -0.006680394522845745,
                -0.007201570551842451,
                -0.009108902886509895,
                0.02524493634700775,
                0.03771322965621948,
                -0.012333659455180168,
                0.04652177542448044,
                -0.006158826872706413,
                0.0038633488584309816,
                0.049131184816360474,
                0.0010830003302544355,
                -0.034918349236249924,
                0.013725212775170803,
                0.002324556466192007,
                0.01799727976322174,
                -0.053868427872657776,
                0.020958775654435158,
                0.02699309214949608,
                -0.04923177883028984,
                0.037696901708841324,
                -0.011968634091317654,
                -0.013831857591867447,
                -0.00695898849517107,
                -0.07005845755338669,
                0.011042023077607155,
                -0.013779807835817337,
                -0.000963269267231226,
                0.021447641775012016,
                -0.02128848060965538,
                -0.04190573841333389,
                0.018387386575341225,
                -0.024156302213668823,
                -0.056159768253564835,
                -0.04592875391244888,
                -0.01209012046456337,
                0.0013358320575207472,
                -0.004262263886630535,
                -0.047620490193367004,
                -0.022976038977503777,
                -0.02799084223806858,
                0.007080909330397844,
                -0.0352129265666008,
                -0.03854488581418991,
                0.014935401268303394,
                -0.02451600693166256,
                0.018067847937345505,
                0.015362788923084736,
                -0.07336367666721344,
                -0.003094855695962906,
                -0.03091948851943016,
                -0.001714991987682879,
                -0.048789314925670624,
                -0.04345337674021721,
                -0.00650144275277853,
                -0.051611848175525665,
                -0.02753017470240593,
                -0.06439769268035889,
                0.013960289768874645,
                -0.02420654334127903,
                0.04073825478553772,
                -0.03560883551836014,
                -0.002959753619506955,
                -0.008996620774269104,
                0.11861618608236313,
                -0.015529216267168522,
                0.07061409205198288,
                -0.06499223411083221,
                -0.038040466606616974,
                -0.039175793528556824,
                0.026736155152320862,
                0.05208556726574898,
                0.03733483701944351,
                0.0033645436633378267,
                -0.004797857254743576,
                -0.035489752888679504,
                -0.021641800180077553,
                0.025666669011116028,
                0.001197278150357306,
                -0.01990848034620285,
                0.037469491362571716,
                -0.028284359723329544,
                0.03539920598268509,
                -0.0068866484798491,
                0.0020003507379442453,
                -0.00920450035482645,
                0.022043967619538307,
                0.0919465497136116,
                -0.039944857358932495,
                0.017086602747440338,
                0.015378877520561218,
                -0.01150595024228096,
                0.022718073800206184,
                -0.05838420242071152,
                0.01141391508281231,
                -0.018888818100094795,
                0.06896249204874039,
                0.009228167124092579,
                -0.005535944364964962,
                0.02150033786892891,
                0.04977863281965256,
                -0.003039641072973609,
                -0.0019193080952391028,
                0.031111354008316994,
                -0.0012654539896175265,
                -0.014479557983577251,
                0.022621450945734978,
                -0.015599104575812817,
                0.024286068975925446,
                0.013702587224543095,
                -0.05682343244552612,
                0.01748689077794552,
                0.025762304663658142,
                0.01932956464588642,
                -0.019937466830015182,
                -0.06035735458135605,
                0.006526426412165165,
                -0.01007723156362772,
                0.01028462965041399,
                0.052764300256967545,
                -0.03443797677755356,
                0.017611505463719368,
                0.011160789057612419,
                0.01005041878670454,
                -0.003885037964209914,
                1.9832498310279334e-07,
                -0.0394023172557354,
                -0.042362771928310394,
                0.02438650280237198,
                0.02060828171670437,
                0.05590672418475151,
                0.005595724564045668,
                -0.00392940454185009,
                0.046454913914203644,
                0.0500498041510582,
                -0.05748901888728142,
                0.06424158811569214,
                -0.032981786876916885,
                0.018733827397227287,
                -0.031478092074394226,
                0.0294977817684412,
                -0.07890304923057556,
                0.009456240572035313,
                -0.0354163758456707,
                -0.036054402589797974,
                -0.026154402643442154,
                -0.011381658725440502,
                0.013273107819259167,
                0.020360354334115982,
                -0.019605860114097595,
                -0.0206799004226923,
                -0.011947140097618103,
                -0.007822058163583279,
                0.005029971245676279,
                -0.04943056032061577,
                -0.05500558763742447,
                0.039751216769218445,
                0.06848510354757309,
                -0.005490349140018225,
                0.0693482831120491,
                0.03803472965955734,
                0.0021555942948907614,
                0.031172072514891624,
                0.021969852969050407,
                0.0036804815754294395,
                0.05788372829556465,
                -0.03827885910868645,
                -0.09067878872156143,
                0.032649584114551544,
                -0.017059536650776863,
                0.05896306782960892,
                0.05754043534398079,
                0.021678799763321877,
                0.08839640766382217,
                -0.06628976762294769,
                -0.00026695019914768636,
                -0.030039004981517792,
                0.027263019233942032,
                0.012567047961056232,
                0.055867116898298264,
                -0.0001805092761060223,
                -0.013922974467277527,
                0.00010565537377260625,
                0.03756685182452202,
                0.06889168918132782,
                0.010335871949791908,
                0.0004105946864001453,
                -0.025194356217980385,
                0.037233445793390274,
                -0.00748368538916111,
                0.017569124698638916,
                -0.005873106420040131,
                -0.03624916076660156,
                1.0992393205593505e-34,
                0.018409021198749542,
                -0.018244152888655663,
                -0.036845140159130096,
                -0.042204804718494415,
                0.014404796995222569,
                0.022164227440953255,
                -0.05010755732655525,
                -0.04044624790549278,
                0.04640597850084305,
                -0.01914147473871708,
                -0.0012761189136654139,
                0.011105923913419247,
                0.0036130943335592747,
                0.001912688254378736,
                -0.04157348722219467,
                -0.005434755235910416,
                0.02146986685693264,
                0.0165320485830307,
                0.033864013850688934,
                0.06179722771048546,
                -0.00976502988487482,
                -0.0010544431861490011,
                -0.08579374849796295,
                0.0017925708089023829,
                0.06652098894119263,
                0.0026101525872945786,
                -0.026774007827043533,
                -0.005546935368329287,
                0.060473233461380005,
                -0.09224682301282883,
                -0.023634037002921104,
                -0.02659001760184765,
                0.01084019523113966,
                0.03904199227690697,
                0.008733334019780159,
                0.021897416561841965,
                -0.017276018857955933,
                -0.00419034855440259,
                0.02431878075003624,
                0.003245951607823372,
                0.0065485336817801,
                0.026116233319044113,
                0.01739071123301983,
                0.0669512152671814,
                0.006794042885303497,
                1.6144776964210905e-06,
                -0.03300827741622925,
                -0.0014099882682785392,
                -0.026721548289060593,
                -0.03682192042469978,
                0.041758447885513306,
                0.06234351545572281,
                0.011324550956487656,
                -0.06424586474895477,
                -0.018993550911545753,
                0.011515473946928978,
                -0.021047238260507584,
                0.023770930245518684,
                -0.053682759404182434,
                -0.00017821481742430478,
                0.007653559558093548,
                0.012412953190505505,
                -0.016701802611351013,
                -0.06107775494456291,
                -0.059766992926597595,
                -0.011429933831095695,
                0.05850755050778389,
                0.03664785996079445,
                0.03677226975560188,
                0.02406405285000801,
                0.03075292706489563,
                -0.027702823281288147,
                -0.00727872597053647,
                0.004814131185412407,
                0.001474820775911212,
                -0.012390916235744953,
                -0.023971527814865112,
                -0.000410248787375167,
                0.026403410360217094,
                0.03673192113637924,
                0.027501195669174194,
                0.09536668658256531,
                0.024353021755814552,
                0.01400009449571371,
                0.06413207948207855,
                -0.07868236303329468,
                0.001444595749489963,
                -0.002099667675793171,
                0.0018851177301257849,
                0.02009519189596176,
                -0.016881858929991722,
                0.07035039365291595,
                0.014778160490095615,
                -0.04178488254547119,
                0.044225867837667465,
                0.09496095031499863,
                -0.0029977322556078434,
                0.007198356091976166,
                0.003629526123404503,
                -0.06027361750602722,
                -0.004560172092169523,
                0.04884440079331398,
                0.008745869621634483,
                0.008862778544425964,
                0.04280579462647438,
                0.020281454548239708,
                0.016644520685076714,
                0.07324013113975525,
                0.007089370861649513,
                0.04547930136322975,
                -0.038699179887771606,
                -0.0956517681479454,
                0.05149279162287712,
                -0.018859300762414932,
                0.0038181075360625982,
                0.02257731556892395,
                -0.021238669753074646,
                0.006884864065796137,
                -0.06756063550710678,
                0.042837534099817276,
                0.04029107466340065,
                0.015207579359412193,
                0.025510191917419434,
                0.01312255859375,
                -0.06155741587281227,
                -0.0346914604306221,
                -0.05011922866106033,
                -0.07634330540895462,
                -0.02476317249238491,
                -0.0151794059202075,
                0.05267581343650818,
                0.014865157194435596,
                -0.0015493292594328523,
                -0.04232707992196083,
                0.006074545904994011,
                0.04480692371726036,
                0.09621085971593857,
                -0.08825063705444336,
                -0.02834322303533554,
                0.023615790531039238,
                0.01315193809568882,
                0.004197635687887669,
                -0.005795558448880911,
                0.004654056392610073,
                0.01775149628520012,
                -0.012970712967216969,
                -0.002240019151940942,
                -0.027180267497897148,
                0.04321926459670067,
                -0.03831809386610985,
                -0.01404837891459465,
                -0.005656132940202951,
                -0.026504959911108017,
                0.030589241534471512,
                -0.01771681383252144,
                -0.0048215813003480434,
                -0.029303371906280518,
                0.04150541499257088,
                -0.041901666671037674,
                -0.03496425226330757,
                -0.04072568193078041,
                -0.0006494575063697994,
                -0.03482722491025925,
                -0.017376869916915894,
                0.009307144209742546,
                0.1024869754910469,
                0.0510878786444664,
                -0.035009704530239105,
                0.019203584641218185,
                -0.010712508112192154,
                -0.007534605450928211,
                0.04309995844960213,
                -0.04062988609075546,
                -0.008913980796933174,
                0.020102806389331818,
                -0.036665208637714386,
                0.04240608960390091,
                -0.03126659244298935,
                -0.002057543955743313,
                0.021848885342478752,
                -0.027661262080073357,
                -0.038737181574106216,
                0.019053678959608078,
                -0.0274661835283041,
                -0.01610642485320568,
                -0.060244038701057434,
                0.08566320687532425,
                -0.08253391832113266,
                -0.03860367089509964,
                0.005483748856931925,
                0.03233750909566879,
                0.014232066459953785,
                -0.019015023484826088,
                0.028689008206129074,
                -0.010476302355527878,
                0.03606327623128891,
                0.024267861619591713,
                -0.03869790956377983,
                0.016958680003881454,
                -0.0024994038976728916,
                0.005179681349545717,
                -0.034466877579689026,
                0.012566852383315563,
                -0.04592148959636688,
                0.03670252859592438,
                0.027247268706560135,
                -0.006467503495514393,
                0.035617537796497345,
                -0.0003196118923369795,
                0.09218454360961914,
                -0.026817699894309044,
                -0.006467773579061031,
                0.039327677339315414,
                0.039647091180086136,
                0.12254384905099869,
                -0.033255480229854584,
                0.05394264683127403,
                0.0654839500784874,
                -0.03412969037890434,
                -0.03891262412071228,
                0.047934163361787796,
                -0.012948739342391491,
                -0.018874621018767357,
                -0.005271576344966888,
                0.002553413389250636,
                -0.02931281365454197,
                0.0018593198619782925,
                0.015260557644069195,
                -0.03080168180167675,
                -0.044680867344141006,
                0.013458024710416794,
                0.012274306267499924,
                0.10740365833044052,
                -0.012801441363990307,
                -0.0583711676299572,
                -0.04477905109524727,
                0.05289312079548836,
                -0.006480928044766188,
                0.005737334489822388,
                0.009755443781614304,
                0.029426757246255875,
                -0.038961976766586304,
                -0.015021626837551594,
                -0.06731058657169342,
                0.0034535012673586607,
                0.015196319669485092,
                -0.008406471461057663,
                -0.007421919610351324,
                0.016428282484412193,
                -0.011475542560219765,
                -0.040525566786527634,
                -0.03642686456441879,
                0.0018055099062621593,
                0.07664533704519272,
                -0.002057618461549282,
                0.034135978668928146,
                -0.010707336477935314,
                0.0013803839683532715,
                -0.0005140639841556549,
                0.008469204418361187,
                -0.0216581542044878,
                -0.038454506546258926,
                -0.0548209547996521,
                0.0139786247164011,
                -0.019874323159456253,
                -0.03236046060919762,
                -0.06189175322651863,
                -0.011687192134559155,
                0.05432303249835968,
                -0.030163351446390152,
                -0.01989884115755558,
                0.017384599894285202,
                0.006419819314032793,
                -0.01519081462174654,
                0.004992242436856031,
                0.01997576653957367,
                -0.051865119487047195,
                0.0631810799241066,
                -0.05931324139237404,
                -0.03255445137619972,
                -0.03669561445713043,
                -0.01031799241900444,
                0.02392672561109066,
                0.04202280938625336,
                -0.02590002678334713,
                -0.02683129534125328,
                -0.011275638826191425,
                0.051952339708805084,
                0.011431791819632053,
                -0.007963377982378006,
                -0.00823141634464264,
                -0.02392200194299221,
                0.05354158580303192,
                -0.018255075439810753,
                -0.0435306690633297,
                -0.017947515472769737,
                0.05926864966750145,
                -0.030205978080630302,
                0.018913254141807556,
                0.0003384235897101462,
                0.017092779278755188,
                0.004039242397993803,
                -0.025110235437750816,
                0.025428609922528267,
                -0.054847341030836105,
                0.017675301060080528,
                -0.02352033369243145,
                0.04870627075433731,
                0.036263734102249146,
                0.024219144135713577,
                -0.006612739991396666,
                0.00806906446814537,
                -0.038756877183914185,
                -0.011442292481660843,
                -0.014418727718293667,
                -0.03406853601336479,
                0.059095848351716995,
                0.022816117852926254,
                -0.062188517302274704,
                -0.07011088728904724,
                0.015424998477101326,
                -0.020320020616054535,
                0.03203153982758522,
                0.020891329273581505,
                0.03970547765493393,
                0.01487386878579855,
                0.042558569461107254,
                0.011611755937337875,
                -0.020150911062955856,
                0.0014218358555808663,
                0.028900345787405968,
                0.018795985728502274,
                0.026924867182970047,
                -0.009867039509117603,
                0.020747356116771698,
                0.005639736540615559,
                -0.014097202569246292,
                -0.009903899393975735,
                -0.00046871521044522524,
                -0.045110393315553665,
                -0.06365536153316498,
                0.023537127301096916,
                0.01656673476099968,
                -0.030818670988082886,
                0.01330389454960823,
                -0.023005370050668716,
                -0.035211026668548584,
                -0.016737325116991997,
                -0.022278765216469765,
                0.02699362114071846,
                -0.06920251995325089,
                -0.01287247333675623,
                -0.008054869249463081,
                -0.008104657754302025,
                0.07417676597833633,
                -0.021509045735001564,
                -0.007479543797671795,
                -0.0010609830496832728,
                0.044328562915325165,
                0.05209273844957352,
                -0.026204530149698257,
                -0.010969710536301136,
                0.03733039274811745,
                -0.0009918005671352148,
                -0.005870552267879248,
                0.01666274480521679,
                0.027663325890898705,
                0.07180526852607727,
                0.007130072917789221,
                -0.05202643200755119,
                -0.008993991650640965,
                -0.004436752758920193,
                0.01899961195886135,
                0.025929881259799004,
                -0.05179102346301079,
                0.04890106990933418,
                0.05156347528100014,
                0.06646785140037537,
                -0.021110614761710167,
                0.025465676560997963,
                0.05407821387052536,
                0.03398916497826576,
                -0.003577858442440629,
                -0.022832287475466728,
                0.07559250295162201,
                -0.018391812220215797,
                -0.011193324811756611,
                -0.005117182154208422,
                -0.030907830223441124,
                0.03702489286661148,
                -0.008998955599963665,
                0.03445882350206375,
                -0.07166876643896103,
                0.05460695922374725,
                -0.04131012409925461,
                0.012465296313166618,
                -0.020630907267332077,
                -0.04121531546115875,
                0.03632712736725807,
                0.027932308614253998,
                -0.0009121168404817581,
                0.030017832294106483,
                -0.1000341922044754,
                -0.01903090626001358,
                0.05789295583963394,
                0.003872605273500085,
                0.009410376660525799,
                0.05594408139586449,
                -0.034258320927619934,
                0.041863035410642624,
                -0.007668588776141405,
                0.000509180361405015,
                -0.041155267506837845,
                -0.010172579437494278,
                0.035048678517341614,
                -0.039087213575839996,
                -0.0017474038759246469,
                0.03608269616961479,
                -0.03472767025232315,
                0.022574517875909805,
                -0.0693080723285675,
                -0.04696444049477577,
                0.03864719346165657,
                -0.005841175559908152,
                -0.037843503057956696,
                -0.028829628601670265,
                -0.05917804688215256,
                -0.0270294900983572,
                -0.008315605111420155,
                0.019007608294487,
                -0.03081604838371277,
                0.05551806464791298,
                0.03893424570560455,
                0.027485636994242668,
                0.0018910554936155677,
                0.0056551541201770306,
                -0.0022978626657277346,
                0.04027523845434189,
                0.020650876685976982,
                -0.006490345578640699,
                0.008382017724215984,
                -0.024192729964852333,
                0.05499735102057457,
                -0.06150701269507408,
                -0.008517112582921982,
                0.04574521258473396,
                -0.03398820385336876,
                0.023536426946520805,
                -0.016163742169737816,
                -0.019732041284441948,
                -0.003042957978323102,
                -0.04452575370669365,
                -0.02252432517707348,
                0.021319329738616943,
                -0.002563052112236619,
                -0.020173747092485428,
                0.030018629506230354,
                0.006659786682575941,
                -0.00831631850451231,
                -0.012292139232158661,
                -0.031146761029958725,
                -0.014947167597711086,
                0.012156092561781406,
                -0.05212938040494919,
                0.027970828115940094,
                0.020160051062703133,
                0.018281148746609688,
                -0.05658942833542824,
                0.05132249742746353,
                0.019482795149087906,
                0.03438379243016243,
                0.02070704661309719,
                0.01779426634311676,
                0.02253660187125206,
                0.017909882590174675,
                0.02206854335963726,
                -0.052132923156023026,
                -0.05556613206863403,
                -0.024421654641628265,
                -0.054763492196798325,
                -0.0413176491856575,
                -0.01888354681432247,
                -0.026175832375884056,
                -0.010114455595612526,
                0.061387818306684494,
                0.04651416838169098,
                0.045008521527051926,
                0.014970044605433941,
                -0.05561666935682297,
                0.03942456096410751,
                -0.0125535624101758,
                -0.001236828975379467,
                0.0004131086461711675,
                -0.05334915965795517,
                -0.00610946724191308,
                0.006691043730825186,
                -0.025748394429683685,
                -0.04304509982466698,
                -0.03638417273759842,
                0.004776915535330772,
                -0.03853756561875343,
                0.007549479603767395,
                -0.007617585826665163,
                0.0018863854929804802,
                0.01866821013391018,
                0.004318656865507364,
                0.057717613875865936,
                -0.0721999779343605,
                0.08181862533092499,
                -0.007167555391788483,
                0.03587289899587631,
                0.005605536513030529,
                0.01850871369242668,
                0.01192138995975256,
                0.04492118954658508,
                -0.0030574349220842123,
                -0.0007001602207310498,
                -0.01983456127345562,
                0.1082981675863266,
                -0.008704095147550106,
                -0.05181124433875084,
                0.09890823811292648,
                0.00603746622800827,
                -0.0015312329633161426,
                0.0048447600565850735,
                -0.008208991028368473,
                -0.05054391548037529,
                -0.02028951235115528,
                -0.03254317864775658,
                -0.00465809553861618,
                0.041614457964897156,
                -0.004248036537319422,
                -0.041646137833595276,
                0.04317092150449753,
                -0.019831174984574318,
                -0.051941849291324615,
                0.004199974238872528,
                0.002676306525245309,
                -0.01846780814230442,
                -0.013283544220030308,
                0.00021380027465056628,
                -0.018017195165157318,
                -0.013737272471189499,
                0.04804663360118866,
                -0.02190893329679966,
                -0.008653150871396065,
                0.00344213773496449,
                0.10343750566244125,
                0.008952517062425613,
                -0.03008555807173252,
                -0.010364928282797337,
                -0.06475071609020233,
                -0.039758071303367615,
                -0.037005048245191574,
                0.00495489314198494,
                0.004547854419797659,
                0.043751075863838196,
                0.015787219628691673,
                0.02571866102516651,
                -0.023153390735387802,
                -0.040265701711177826,
                -0.013352196663618088,
                0.013504058122634888,
                -0.019922442734241486,
                0.012831861153244972,
                0.03615517541766167,
                -6.628826437321935e-33,
                -0.0011219884036108851,
                -0.05368447303771973,
                0.024523047730326653,
                -0.04443051666021347,
                -0.04261896014213562,
                -0.014590596780180931,
                -0.06240754574537277,
                0.03676793351769447,
                -0.03771009296178818,
                -0.01897088624536991,
                0.0019621802493929863,
                -0.013668494299054146,
                0.005372219253331423,
                0.03766863793134689,
                0.06895589828491211,
                0.007608955260366201,
                -0.019733289256691933,
                0.011097959242761135,
                -0.029372211545705795,
                -0.018514757975935936,
                0.001640734146349132,
                0.008085641078650951,
                0.03560563549399376,
                -0.008863776922225952,
                -0.005736622028052807,
                0.0416494682431221,
                0.0262326467782259,
                -0.02536144107580185,
                0.033362966030836105,
                -0.022426092997193336,
                -0.029964135959744453,
                0.015524848364293575,
                -0.026025280356407166,
                -0.032320912927389145,
                0.042962849140167236,
                0.02309497259557247,
                -0.03297589346766472,
                -0.007505193818360567,
                -0.08549167960882187,
                0.0030501503497362137,
                0.018950127065181732,
                -0.03814064711332321,
                0.036709703505039215,
                -0.03341679647564888,
                -0.0503738634288311,
                -0.022249333560466766,
                -0.031097102910280228,
                0.013371646404266357,
                0.009626886807382107,
                -0.03671225160360336,
                -0.03328564763069153,
                0.0074860332533717155,
                -0.0037959932815283537,
                0.08825495839118958,
                -0.010589134879410267,
                -0.029743380844593048,
                0.029629454016685486,
                -0.00659590307623148,
                -0.011787430383265018,
                -0.03153995797038078,
                -0.001548207481391728,
                -0.0779060423374176,
                0.041136790066957474,
                -0.012765567749738693,
                -0.029052328318357468,
                0.0023833750747144222,
                -0.042546749114990234,
                0.06985288113355637,
                0.017262103036046028,
                0.00989779457449913,
                0.08016882836818695,
                -0.0012222370132803917,
                -0.02162815071642399,
                -0.02403382770717144,
                -0.09331516176462173,
                -0.010590273886919022,
                -0.0051250276155769825,
                0.015176268294453621,
                0.07887890189886093,
                -0.011804377660155296,
                -0.101117342710495,
                0.03963536024093628,
                -0.043763238936662674,
                -0.014201022684574127,
                0.00889632198959589,
                0.0615369975566864,
                0.028607098385691643,
                -0.027869854122400284,
                -0.025326600298285484,
                -0.03675661236047745,
                0.011099837720394135,
                0.08823399990797043,
                0.022151881828904152,
                0.01347190048545599,
                0.026590241119265556,
                -0.015983624383807182,
                0.027006516233086586,
                -0.025150539353489876,
                0.028168555349111557,
                -0.017101915553212166,
                -0.04608131945133209,
                0.06903650611639023,
                -0.017801988869905472,
                0.018761154264211655,
                -0.08133295178413391,
                0.0026779579930007458,
                -0.04904431849718094,
                -0.033016808331012726,
                -0.036817245185375214,
                0.02033294178545475,
                0.057220540940761566,
                -0.04305903613567352,
                0.05013246461749077,
                -0.045406050980091095,
                0.02057523839175701,
                -0.04969673603773117,
                0.03388845548033714,
                -0.014678427018225193,
                0.026261501014232635,
                -0.000542697322089225,
                0.02371910773217678,
                -0.0009538243757560849,
                -0.00823240727186203,
                0.01964728720486164,
                -0.02625165320932865,
                0.012316405773162842,
                0.005362361669540405,
                0.022908233106136322,
                0.0272707249969244,
                -0.02265435829758644,
                0.030383897945284843,
                -0.028875380754470825,
                2.3744388499835622e-07,
                -0.040592435747385025,
                0.011051272042095661,
                0.005149161908775568,
                0.05160894989967346,
                0.04115372896194458,
                0.004393294453620911,
                0.008498110808432102,
                0.046109043061733246,
                -0.0169814582914114,
                -0.017540276050567627,
                0.004659093916416168,
                -0.018226968124508858,
                0.03107266128063202,
                -0.021992528811097145,
                -0.026484858244657516,
                -0.13019835948944092,
                -0.00646175816655159,
                -0.034803226590156555,
                0.013836290687322617,
                0.04322974756360054,
                -0.03550288453698158,
                0.014755082316696644,
                -0.03244999796152115,
                -0.0325772687792778,
                -0.005917140748351812,
                0.09480226039886475,
                0.013737145811319351,
                -0.04943663254380226,
                -0.00031749485060572624,
                0.04916619136929512,
                -0.08884242177009583,
                -0.009990043006837368,
                0.03417349234223366,
                0.0035438397899270058,
                -0.012041679583489895,
                -0.054374221712350845,
                0.05519545450806618,
                0.021916747093200684,
                0.014295895583927631,
                0.016906894743442535,
                -0.03446491062641144,
                0.010544060729444027,
                0.009312637150287628,
                -0.036542534828186035,
                -0.007315302267670631,
                0.0006464184843935072,
                -0.028126604855060577,
                0.03381321206688881,
                -0.05924556031823158,
                0.011654487811028957,
                -0.0009985368233174086,
                -0.02359769120812416,
                0.03596504032611847,
                0.01991269551217556,
                0.016246657818555832,
                -0.01789665035903454,
                0.04806951433420181,
                0.014753236435353756,
                0.05331309512257576,
                -0.014126455411314964,
                0.015150327235460281,
                -0.027543365955352783,
                0.009005389176309109,
                -0.02178717590868473,
                0.0072622899897396564,
                0.05914515256881714,
                -0.02433750033378601,
                1.9355910385830565e-34,
                0.01731245592236519,
                -0.02962048538029194,
                0.005215456243604422,
                0.03357160463929176,
                0.03118308074772358,
                -0.010497793555259705,
                0.049607619643211365,
                -0.03831876069307327,
                0.04399031028151512,
                0.03782123327255249,
                -0.03869327902793884,
                -0.009007181040942669,
                -0.0410442091524601,
                -0.0013758641434833407,
                -0.0675213485956192,
                0.02238035947084427,
                0.02493775077164173,
                0.00514660170301795,
                0.0484762042760849,
                0.052485618740320206,
                -0.024791864678263664,
                0.025989901274442673,
                -0.028661081567406654,
                -0.029965970665216446,
                0.030045295134186745,
                -0.0010221680859103799,
                -0.06777430325746536,
                0.023825151845812798,
                0.03697909042239189,
                -0.09222668409347534,
                -0.051968976855278015,
                -0.002394045703113079,
                -0.014395181089639664,
                -0.0389225110411644,
                -0.012304648756980896,
                0.013566754758358002,
                -0.04134853929281235,
                -0.00721856439486146,
                0.022884957492351532,
                0.03802787885069847,
                -0.0017235196428373456,
                0.01326453685760498,
                0.017067262902855873,
                0.07884638756513596,
                -0.005675874184817076,
                1.8132371906176559e-06,
                0.0033920053392648697,
                -0.016939161345362663,
                -0.037315670400857925,
                -0.04689173772931099,
                0.004018419422209263,
                0.007461452390998602,
                -0.033904172480106354,
                -0.018028320744633675,
                -0.0054968660697340965,
                0.02399950660765171,
                0.03191225975751877,
                0.028266511857509613,
                -0.04013822227716446,
                0.042108580470085144,
                0.031994666904211044,
                0.01514460425823927,
                0.004306396935135126,
                -0.0452020987868309,
                -0.03296009078621864,
                -0.025172743946313858,
                -0.018808605149388313,
                -0.015209577046334743,
                0.01629742421209812,
                0.0029629028867930174,
                0.04401855915784836,
                -0.023176124319434166,
                0.002456847345456481,
                0.01709650084376335,
                0.03910475969314575,
                0.06894151866436005,
                0.02293897233903408,
                0.02647067978978157,
                0.057134658098220825,
                -0.002790467580780387,
                -0.014212431386113167,
                0.018610509112477303,
                0.03381282463669777,
                -0.019910559058189392,
                0.02054017223417759,
                -0.06221405044198036,
                0.02227589301764965,
                0.04001028090715408,
                -0.0471864752471447,
                0.036383725702762604,
                2.8250035029486753e-05,
                0.03683700039982796,
                -0.016018005087971687,
                -0.03143836185336113,
                -0.0050706868059933186,
                0.1034870371222496,
                -0.03270607814192772,
                0.02309309132397175,
                -0.03753121197223663,
                0.016687342897057533,
                0.04832612723112106,
                0.05191720277070999,
                0.011922627687454224,
                -0.010556137189269066,
                0.04762572422623634,
                0.00475308857858181,
                -0.017376290634274483,
                0.033849120140075684,
                -0.017859650775790215,
                0.015965228900313377,
                -0.0001501839142292738,
                -0.0477093942463398,
                0.02868352271616459,
                0.04384869709610939,
                -0.011025210842490196,
                0.008569322526454926,
                -0.043998658657073975,
                -0.014150509610772133,
                -0.012538041919469833,
                -0.013804856687784195,
                0.08016934990882874,
                -0.04963230714201927,
                0.023527519777417183,
                -0.05176714435219765,
                -0.038237065076828,
                -0.024128928780555725,
                -0.019599955528974533,
                -0.09706926345825195,
                -0.05771079286932945,
                0.026248781010508537,
                0.025804931297898293,
                0.022060170769691467,
                0.009637032635509968,
                -0.05683283880352974,
                0.00021792079496663064,
                -0.020076751708984375,
                0.017565438523888588,
                -0.058270059525966644,
                0.002639912301674485,
                -0.005186085589230061,
                0.030758164823055267,
                -5.553408118430525e-05,
                0.005444927606731653,
                0.008932308293879032,
                0.01595056988298893,
                0.004366728011518717,
                -0.006572662387043238,
                -0.05026727542281151,
                0.03136651590466499,
                -0.06304402649402618,
                -0.011024832725524902,
                0.012339766137301922,
                -0.040517568588256836,
                0.021029269322752953,
                -0.02834179624915123,
                -0.01742120087146759,
                -0.07190173864364624,
                0.015446572564542294,
                0.027847427874803543,
                -0.038795799016952515,
                -0.042494695633649826,
                -0.002028856659308076,
                -0.0038940960075706244,
                -0.020917944610118866,
                -0.008013701997697353,
                0.11705593019723892,
                0.01712009869515896,
                -0.015280530788004398,
                0.021633239462971687,
                -0.004978706128895283,
                -0.014407707378268242,
                0.0020323870703577995,
                -0.06864194571971893,
                -0.005924524739384651,
                0.055240582674741745,
                -0.008547758683562279,
                0.02165018953382969,
                -0.01364039070904255,
                -0.02044578455388546,
                -0.02620873600244522,
                -0.0009059946169145405,
                -0.052377332001924515,
                0.010772733017802238,
                -0.04859793186187744,
                0.03958640992641449,
                -0.019352365285158157,
                0.09441200643777847,
                -0.008952362462878227,
                -0.02939932979643345,
                0.0065936436876654625,
                0.014388490468263626,
                0.03330858051776886,
                -0.036758117377758026,
                0.001883403630927205,
                -0.022700263187289238,
                -0.0009878219570964575,
                0.05398024618625641,
                -0.019321350380778313,
                -0.012215902097523212,
                -0.020122189074754715,
                0.026931636035442352,
                -0.008720972575247288,
                -0.0076911007054150105,
                -0.08342182636260986,
                -0.015330945141613483,
                0.06953874230384827,
                -0.019655566662549973,
                -0.018425220623612404,
                0.042665477842092514,
                0.08825453370809555,
                -0.02168010175228119,
                0.02513810247182846,
                0.038755256682634354,
                -0.04326196759939194,
                0.12685617804527283,
                -0.020006977021694183,
                0.046605054289102554,
                0.001730955671519041,
                -0.06185120344161987,
                -0.05180341377854347,
                0.024818673729896545,
                -0.021705854684114456,
                -0.008950401097536087,
                0.03635436296463013,
                -0.015238743275403976,
                -0.019694872200489044,
                -0.0005000947276130319,
                0.020348934456706047,
                -0.025753997266292572,
                -0.034820687025785446,
                -0.023772964254021645,
                -0.011905067600309849,
                0.09324464946985245,
                -0.059789981693029404,
                0.00026059290394186974,
                -0.05140271782875061,
                0.037514183670282364,
                -0.014644171111285686,
                0.025554310530424118,
                -0.006285456474870443,
                0.036258406937122345,
                -0.0032151835039258003,
                -0.006871468387544155,
                -0.01981830596923828,
                0.002995326416566968,
                0.0005401435191743076,
                -0.04732364043593407,
                0.02548351138830185,
                -0.010003799572587013,
                0.009625140577554703,
                -0.01642226241528988,
                -0.0430573970079422,
                0.01122589036822319,
                0.05937157943844795,
                -0.025761084631085396,
                -0.03201598301529884,
                -0.003387116827070713,
                0.00027904327725991607,
                -8.625225746072829e-05,
                0.05227081850171089,
                -0.0035339798778295517,
                -0.04536420479416847,
                -0.0635908991098404,
                0.03456408530473709,
                0.046565137803554535,
                -0.03015807457268238,
                -0.07999855279922485,
                -0.015114586800336838,
                0.04089484363794327,
                -0.07120171934366226,
                -0.01656481623649597,
                -0.009466986171901226,
                0.01855168491601944,
                -0.010416915640234947,
                -0.010220376774668694,
                0.059182308614254,
                -0.030898306518793106,
                0.04069937393069267,
                -0.04839937016367912,
                -0.0185100007802248,
                -0.029055671766400337,
                0.02866450883448124,
                0.059858065098524094,
                -0.0074776578694581985,
                -0.028197959065437317,
                -0.04125283285975456,
                0.017100682482123375,
                0.029789773747324944,
                -0.0101581746712327,
                -0.02929239720106125,
                -0.000812255369964987,
                -0.016771016642451286,
                0.06192724406719208,
                0.008072112686932087,
                -0.03288997337222099,
                -0.01748782768845558,
                0.055467016994953156,
                0.0020453573670238256,
                0.054635949432849884,
                0.00885526929050684,
                0.06702568382024765,
                0.03361111134290695,
                0.02167860046029091,
                0.05077679827809334,
                -0.06978869438171387,
                0.02250366099178791,
                0.017073756083846092,
                0.00870773196220398,
                0.019153187051415443,
                0.04214455932378769,
                -0.034228090196847916,
                0.017859017476439476,
                0.0025089685805141926,
                -0.035124219954013824,
                0.015771273523569107,
                -0.07350513339042664,
                -0.006529687438160181,
                -0.040254849940538406,
                -0.008631314150989056,
                -0.047431521117687225,
                0.010610402561724186,
                -0.022772295400500298,
                0.05980779603123665,
                0.0003295842616353184,
                -0.034432362765073776,
                0.022940659895539284,
                -0.009568089619278908,
                -0.0555015504360199,
                0.0169607475399971,
                0.021557124331593513,
                -0.04343782737851143,
                0.0178240817040205,
                0.04262698441743851,
                -0.001318321330472827,
                -0.019408607855439186,
                0.021469445899128914,
                -0.0590042844414711,
                -0.039276521652936935,
                0.017675653100013733,
                -0.052749115973711014,
                -0.054117780178785324,
                0.0065703922882676125,
                0.00017569769988767803,
                -0.011470898985862732,
                0.016716329380869865,
                -0.03223191946744919,
                -0.019472889602184296,
                0.02439800649881363,
                -0.07976759970188141,
                0.02060842700302601,
                -0.013167564757168293,
                0.0058333994820714,
                -0.03112744353711605,
                -0.01691853627562523,
                0.04267924651503563,
                -0.009373415261507034,
                -0.02889581397175789,
                -0.010098544880747795,
                0.04934791848063469,
                0.014243797399103642,
                -0.05451987683773041,
                -0.0008718037861399353,
                0.08137605339288712,
                0.005617509596049786,
                -0.016659095883369446,
                0.013947326689958572,
                0.023986751213669777,
                0.0659882053732872,
                -0.0051118661649525166,
                -0.037214990705251694,
                -0.05853104218840599,
                0.010576408356428146,
                0.013030661270022392,
                0.05060628429055214,
                -0.025385262444615364,
                -0.021999401971697807,
                0.02789919078350067,
                0.031853172928094864,
                -0.06748878955841064,
                0.03180453181266785,
                0.048603083938360214,
                0.01761099137365818,
                -0.027283035218715668,
                -0.03733847662806511,
                0.07839903980493546,
                -0.009536486119031906,
                -0.020288953557610512,
                -0.006491233129054308,
                -0.033092059195041656,
                0.01774829812347889,
                -9.045939077623188e-05,
                0.014241309836506844,
                -0.06004518270492554,
                0.033783990889787674,
                -0.046795543283224106,
                0.05427403002977371,
                -0.042136579751968384,
                0.010091369040310383,
                0.03129572793841362,
                -0.005602382589131594,
                0.011647572740912437,
                0.014893445186316967,
                -0.0687035471200943,
                -0.004603721667081118,
                0.040030594915151596,
                -0.005926128011196852,
                0.00852087140083313,
                0.02617776393890381,
                -0.027057398110628128,
                -0.007990296930074692,
                0.054222472012043,
                0.04874841123819351,
                -0.008778118528425694,
                -0.026332275941967964,
                -0.006068000569939613,
                -0.05103988200426102,
                -0.011224848218262196,
                0.03528497368097305,
                -0.06742189079523087,
                -0.005061059724539518,
                -0.029499322175979614,
                -0.016570286825299263,
                0.027321619912981987,
                0.025975005701184273,
                -0.036598458886146545,
                0.036076631397008896,
                -0.1041383147239685,
                -0.008941690437495708,
                0.04375792667269707,
                0.07023151963949203,
                -0.04073997214436531,
                0.0406104139983654,
                0.08084172755479813,
                0.018263984471559525,
                0.023987267166376114,
                0.050499431788921356,
                0.006479897070676088,
                0.06535875052213669,
                0.01904652826488018,
                -0.02781052514910698,
                -0.03005068004131317,
                -0.018831610679626465,
                0.05356916785240173,
                -0.014972314238548279,
                0.055954694747924805,
                0.03750182315707207,
                0.012062338180840015,
                0.04354628548026085,
                -0.006694277748465538,
                0.036912981420755386,
                -0.01571289636194706,
                -0.03764033317565918,
                0.03103061579167843,
                0.012431873008608818,
                -0.021209685131907463,
                0.006115230266004801,
                0.03826048597693443,
                0.024670906364917755,
                -0.004860742948949337,
                0.01180699560791254,
                -0.012566468678414822,
                -0.0035670537035912275,
                -0.042597077786922455,
                -0.035166557878255844,
                0.0159683208912611,
                0.02382804825901985,
                -0.00975924264639616,
                -0.11410588771104813,
                0.043456777930259705,
                0.03712629899382591,
                -0.01222856156527996,
                0.031959060579538345,
                0.01560164988040924,
                0.06683211773633957,
                -0.005746630020439625,
                0.0022799563594162464,
                -0.056805677711963654,
                0.005048776976764202,
                -0.09447786211967468,
                -0.02108560875058174,
                -0.0329122394323349,
                -0.04354666918516159,
                -0.010722731240093708,
                -0.007750681135803461,
                0.05238039419054985,
                0.057987987995147705,
                0.03315979614853859,
                0.003084456780925393,
                0.00688701868057251,
                0.019946008920669556,
                0.014620166271924973,
                0.007275269366800785,
                0.020950961858034134,
                -0.014651359058916569,
                0.019351979717612267,
                -0.030960598960518837,
                0.04582732543349266,
                -0.004272582940757275,
                -0.007964471355080605,
                0.00441731745377183,
                0.009771143086254597,
                0.008339349180459976,
                0.03276871517300606,
                0.00384512054733932,
                -0.013112147338688374,
                0.02798394113779068,
                0.043832823634147644,
                -0.08706287294626236,
                0.048988375812768936,
                -0.04260868951678276,
                0.010500632226467133,
                0.010274415835738182,
                0.0025179702788591385,
                0.02397635206580162,
                -0.02619173564016819,
                -0.046525318175554276,
                -0.0212048701941967,
                0.005584001075476408,
                0.09497695416212082,
                0.0004887529648840427,
                -0.03277534991502762,
                0.032685860991477966,
                0.003785537788644433,
                0.0003626037505455315,
                -0.006705847103148699,
                -0.034451499581336975,
                -0.023138079792261124,
                0.003320074174553156,
                0.006985834334045649,
                -0.03910741209983826,
                -0.008261249400675297,
                -0.018015094101428986,
                -0.005140458699315786,
                -0.02309664897620678,
                -0.05263553932309151,
                0.017381560057401657,
                -0.023757200688123703,
                0.01904413290321827,
                -0.018783407285809517,
                0.04436672106385231,
                0.006143295206129551,
                0.013023309409618378,
                0.03536486625671387,
                0.036934781819581985,
                -0.02372600883245468,
                -0.014269299805164337,
                -0.02906845323741436,
                0.07316207885742188,
                0.029645951464772224,
                -0.007862056605517864,
                -0.011396946385502815,
                0.004415173549205065,
                -0.023985886946320534,
                -0.01696195639669895,
                0.02999754250049591,
                -0.023016588762402534,
                0.031905412673950195,
                0.040970705449581146,
                0.0035217690747231245,
                -0.050710733979940414,
                -0.03548857569694519,
                -0.0013777983840554953,
                0.003865061094984412,
                0.06095804646611214,
                0.06109317019581795,
                0.026996100321412086,
                -5.6909656163580656e-33,
                0.03257758170366287,
                -0.004653740208595991,
                0.04263854771852493,
                -0.06017204746603966,
                -0.020495915785431862,
                0.03883155807852745,
                -0.015101769007742405,
                0.014361940324306488,
                0.00734437070786953,
                -0.010505595244467258,
                0.03399566188454628,
                0.010476856492459774,
                0.009045538492500782,
                0.030660968273878098,
                0.03777996078133583,
                0.006458699237555265,
                0.01847698539495468,
                -0.0028465422801673412,
                -0.04694525897502899,
                -0.04481804743409157,
                -0.03972950205206871,
                0.04425109177827835,
                0.06103115528821945,
                0.05609172582626343,
                -0.05414361506700516,
                0.03137487545609474,
                0.016456041485071182,
                -0.0003111821133643389,
                -0.02464441955089569,
                -0.01601267047226429,
                -0.017973802983760834,
                0.017284030094742775,
                -0.017328564077615738,
                -0.051451895385980606,
                0.04685967043042183,
                -0.012757194228470325,
                -0.07581144571304321,
                0.01742069236934185,
                -0.09416095167398453,
                -0.0009865607134997845,
                -0.04796139895915985,
                -0.04667983576655388,
                0.0620197057723999,
                -0.0175252053886652,
                -0.07112329453229904,
                -0.05272547900676727,
                -0.025083016604185104,
                0.026094747707247734,
                0.008405332453548908,
                -0.01797400414943695,
                -0.03196049481630325,
                0.007149671204388142,
                -0.026728887110948563,
                0.05158432573080063,
                -0.052769239991903305,
                -0.044350139796733856,
                0.030391927808523178,
                -0.04966426268219948,
                -0.010064799338579178,
                -0.0295366533100605,
                0.0378907136619091,
                -0.019883843138813972,
                0.025484824553132057,
                -0.043885935097932816,
                -0.03042897768318653,
                0.003548445412889123,
                -0.052287906408309937,
                0.014499607495963573,
                0.036218076944351196,
                0.030286606401205063,
                0.04869839921593666,
                -0.016624916344881058,
                -0.038538914173841476,
                -0.07047984004020691,
                -0.035092346370220184,
                0.015143471769988537,
                -0.041828300803899765,
                0.019740233197808266,
                -0.002127069514244795,
                0.044859375804662704,
                -0.08465848118066788,
                0.026795117184519768,
                -0.022864829748868942,
                0.0007082625525072217,
                -0.02497485838830471,
                0.04061412811279297,
                0.0011535531375557184,
                -0.027129150927066803,
                -0.007434154395014048,
                -0.012024955824017525,
                -0.029166631400585175,
                0.10232603549957275,
                -0.020041313022375107,
                0.0338069386780262,
                0.10719023644924164,
                -0.005626704078167677,
                0.012238796800374985,
                -0.012870228849351406,
                0.013910428620874882,
                0.014310079626739025,
                -0.057513825595378876,
                0.004835100844502449,
                0.021056242287158966,
                -0.0003789807960856706,
                -0.045240189880132675,
                -0.02282942458987236,
                -0.038111861795186996,
                -0.0024962483439594507,
                -0.004919738043099642,
                0.04060786962509155,
                0.027277551591396332,
                -0.012990997172892094,
                0.018952077254652977,
                -0.04083383083343506,
                0.03888985142111778,
                -0.03203907981514931,
                0.017130177468061447,
                0.04652547836303711,
                -0.03039676509797573,
                -0.056923624128103256,
                0.025735225528478622,
                0.04959666356444359,
                0.046227291226387024,
                0.0049706026911735535,
                0.018829630687832832,
                0.007947342470288277,
                0.03646490350365639,
                -0.02737954817712307,
                0.007459520362317562,
                -0.02290639467537403,
                0.03464234992861748,
                0.03886046260595322,
                2.1470282263180707e-07,
                -0.03752083703875542,
                0.02117193676531315,
                0.039978448301553726,
                -0.01576664112508297,
                0.012019394896924496,
                0.012257016263902187,
                0.013971062377095222,
                0.030617687851190567,
                -0.0011209594085812569,
                0.026910055428743362,
                0.02467251941561699,
                -0.0073392027989029884,
                -0.0013986409176141024,
                -0.04901277273893356,
                0.041276175528764725,
                -0.1482013612985611,
                0.0053329248912632465,
                -0.022769611328840256,
                -0.032539285719394684,
                0.02773144282400608,
                -0.02361324615776539,
                0.004771955776959658,
                0.015005689114332199,
                -0.03552919626235962,
                0.019713398069143295,
                0.054023243486881256,
                0.005400285590440035,
                0.023755062371492386,
                0.0055431099608540535,
                0.028487103059887886,
                -0.08104776591062546,
                0.045196782797575,
                0.01707729510962963,
                0.049156300723552704,
                -0.036958854645490646,
                -0.0260222926735878,
                0.024693166837096214,
                0.0577285997569561,
                -0.01381054986268282,
                0.036940619349479675,
                -0.019123995676636696,
                0.01956854946911335,
                -0.02798164263367653,
                0.03206324577331543,
                0.023938387632369995,
                -0.0787711963057518,
                -0.03013170324265957,
                0.025845974683761597,
                -0.08305062353610992,
                0.022828400135040283,
                -0.061363548040390015,
                0.0016560893272981048,
                0.029448959976434708,
                0.025090960785746574,
                0.03517763689160347,
                -0.004142600577324629,
                0.00029993796488270164,
                0.03718206658959389,
                0.0769490897655487,
                0.016255520284175873,
                0.020391734316945076,
                -0.03054114431142807,
                -0.004651527851819992,
                0.015943219885230064,
                -0.017871852964162827,
                -0.01579364947974682,
                -0.036510977894067764,
                1.4377490919950332e-34,
                -0.0358821339905262,
                0.004036261234432459,
                -0.0014895274071022868,
                0.03923399746417999,
                0.009484807960689068,
                -0.03146888315677643,
                0.035229723900556564,
                -0.05609967187047005,
                0.023377912119030952,
                0.03327830508351326,
                -0.03916815295815468,
                -0.0080350860953331,
                -0.007688079960644245,
                0.00027517040143720806,
                -0.03437340259552002,
                -0.019590405747294426,
                0.01588735170662403,
                0.007375944871455431,
                0.019273029640316963,
                -0.014901353977620602,
                0.04001830518245697,
                0.06369148194789886,
                -0.009074518457055092,
                -0.021520573645830154,
                0.014147317968308926,
                0.043249085545539856,
                -0.0205172561109066,
                0.05575926601886749,
                -0.05371451750397682,
                -0.03955044969916344,
                0.021489320322871208,
                0.012859667651355267,
                -0.025146158412098885,
                -0.006218478083610535,
                -0.002396725118160248,
                0.011051144450902939,
                -0.05981399491429329,
                0.013683730736374855,
                -0.009091364219784737,
                0.06573864817619324,
                -0.055386729538440704,
                0.046146053820848465,
                -0.02643194980919361,
                0.048244595527648926,
                0.012231934815645218,
                1.3938879419583827e-06,
                -0.014550664462149143,
                0.04903184622526169,
                -0.014482860453426838,
                0.03832494840025902,
                -0.056910544633865356,
                -0.026060331612825394,
                0.007442944683134556,
                0.01739320158958435,
                0.056577954441308975,
                -0.0017328830435872078,
                0.003109418088570237,
                0.0325191430747509,
                0.03681209683418274,
                0.01503712311387062,
                0.012272155843675137,
                -0.007773390971124172,
                0.040973059833049774,
                -0.03994840011000633,
                -0.006685840897262096,
                0.006895397789776325,
                0.005569586530327797,
                0.019397886469960213,
                0.008615178056061268,
                0.029759783297777176,
                -0.0016887079691514373,
                -0.03439604118466377,
                -0.031358752399683,
                -0.039701901376247406,
                -0.016132201999425888,
                -0.013331165537238121,
                0.011426501907408237,
                0.00988093949854374,
                -0.053832534700632095,
                -0.010904176160693169,
                0.006794303189963102,
                0.03059861995279789,
                0.013837823644280434,
                0.03800516203045845,
                0.04310249537229538,
                -0.004297565668821335,
                0.01370987482368946,
                -0.0023664114996790886,
                -0.017571043223142624,
                -0.013948457315564156,
                -0.044884104281663895,
                0.003572929883375764,
                0.0688563659787178,
                0.005205587018281221,
                0.01670732907950878,
                0.07501520216464996,
                0.015062850899994373,
                -0.00823150109499693,
                -0.019670406356453896,
                0.021004274487495422,
                -0.008561582304537296,
                0.06325801461935043,
                -0.01769173890352249,
                -0.03036140836775303,
                0.013033559545874596,
                -0.003817772027105093,
                -0.013296159915626049,
                -0.01009830180555582,
                -0.011379795148968697,
                0.06488833576440811,
                -0.0759577676653862,
                0.014680318534374237,
                0.014655128121376038,
                0.02423759177327156,
                -0.021168312057852745,
                0.05418263375759125,
                -0.004569461103528738,
                -0.0696861669421196,
                -0.016995204612612724,
                -0.0178268663585186,
                0.12574724853038788,
                -0.027830634266138077,
                0.021939054131507874,
                0.04855218157172203,
                -0.03622635826468468,
                0.055629465728998184,
                0.02982591837644577,
                -0.01636119931936264,
                0.026115508750081062,
                -0.05330023542046547,
                0.02514190971851349,
                -0.04952835291624069,
                0.008911846205592155,
                -0.029558269307017326,
                0.03488808870315552,
                0.0633317157626152,
                -0.03523702174425125,
                -0.05417155101895332,
                0.0017874703044071794,
                -0.005882049910724163,
                0.03571745753288269,
                -0.024361412972211838,
                -0.0060768392868340015,
                -0.014402815140783787,
                -0.028404874727129936,
                -0.008651075884699821,
                0.1117430105805397,
                -0.0067992135882377625,
                -0.00478593073785305,
                -0.06844333559274673,
                0.0037739707622677088,
                -0.00964429508894682,
                -0.028780456632375717,
                0.04516660422086716,
                -0.014178724028170109,
                -0.02688315138220787,
                -0.02919873222708702,
                -0.03156450018286705,
                -0.017799295485019684,
                0.004686407744884491,
                -0.010131836868822575,
                0.008719982579350471,
                -0.0020134553778916597,
                0.0016566371778026223,
                -0.03283284604549408,
                -0.02937023714184761,
                0.041687916964292526,
                0.015626536682248116,
                0.02344914898276329,
                0.022475916892290115,
                -0.037202876061201096,
                0.017289962619543076,
                0.027077917009592056,
                0.03842104226350784,
                -0.0033054258674383163,
                -0.004776457324624062,
                0.0510898120701313,
                0.03982469066977501,
                -0.01829715073108673,
                0.03029542788863182,
                -0.05742291361093521,
                -0.01135117094963789,
                0.006370114162564278,
                -0.04828263074159622,
                0.06767100840806961,
                0.04983878135681152,
                0.0016083359951153398,
                -0.04090825840830803,
                -0.019146155565977097,
                -0.04440601170063019,
                0.004707094747573137,
                -0.0233597494661808,
                -0.01599966548383236,
                -0.026872383430600166,
                0.03426780179142952,
                -0.02194511704146862,
                0.029707875102758408,
                -0.0472237803041935,
                0.030833588913083076,
                -0.03267403319478035,
                0.026401061564683914,
                -0.019825061783194542,
                -0.030336543917655945,
                0.06668352335691452,
                0.04572425037622452,
                -0.01452022884041071,
                -0.009127235971391201,
                0.03397064656019211,
                -0.050119590014219284,
                0.019177895039319992,
                -0.005843766499310732,
                0.011734550818800926,
                -0.01713653653860092,
                0.014064447954297066,
                0.05692532658576965,
                -0.032850947231054306,
                0.01253767404705286,
                -0.0717758759856224,
                -0.075342558324337,
                -0.014552884735167027,
                -0.0295538492500782,
                -0.03979690745472908,
                -0.010330090299248695,
                0.051971860229969025,
                -0.003667951561510563,
                -0.010057007893919945,
                0.018870297819375992,
                -0.008522257208824158,
                0.013680401258170605,
                -0.0016761712031438947,
                0.017278417944908142,
                -0.03934698924422264,
                0.10642750561237335,
                -0.02511606179177761,
                0.0139096574857831,
                -0.04510810598731041,
                -0.05143125355243683,
                -0.013579258695244789,
                -0.025459837168455124,
                0.02200975827872753,
                0.002198441419750452,
                -0.04147421196103096,
                0.0011513268109411001,
                0.021704234182834625,
                0.03458248823881149,
                0.05496390163898468,
                0.003248470136895776,
                0.0023738103918731213,
                0.060940466821193695,
                0.015060774981975555,
                -0.04955181106925011,
                -0.005512228701263666,
                0.016107171773910522,
                0.0003778394602704793,
                0.049102090299129486,
                -0.03472265601158142,
                0.004587091039866209,
                0.0012345985742285848,
                0.03891969472169876,
                0.0034663185942918062,
                -0.00686968769878149,
                -0.032402440905570984,
                -0.010318831540644169,
                -0.014362658374011517,
                -0.003622491843998432,
                -0.01971276104450226,
                -0.05738198012113571,
                -0.017713317647576332,
                0.01728345826268196,
                -0.05550865828990936,
                -0.017465665936470032,
                -0.02452942356467247,
                -0.05224306508898735,
                0.042786259204149246,
                0.0030540453735738993,
                0.05476386845111847,
                -0.026584025472402573,
                0.057465262711048126,
                0.04286792501807213,
                -0.04105987772345543,
                -0.004045035690069199,
                0.01480627991259098,
                -0.0044175912626087666,
                -0.019969865679740906,
                -0.008612141944468021,
                0.004428150597959757,
                0.05255791172385216,
                -0.00372250541113317,
                0.007230932358652353,
                0.05177201330661774,
                -0.0015525718918070197,
                0.005430694203823805,
                -0.03454625606536865,
                -0.0021835335064679384,
                -0.02796807326376438,
                -0.03103812225162983,
                -0.017681969329714775,
                0.001200188067741692,
                -0.02532201074063778,
                -0.018997658044099808,
                0.026784608140587807,
                -0.006461331155151129,
                0.0012584120268002152,
                -0.015297002159059048,
                -0.0429721437394619,
                0.026373622938990593,
                0.05084885284304619,
                0.007732884027063847,
                0.04751845449209213,
                0.022551165893673897,
                0.017598558217287064,
                0.028660761192440987,
                0.00715789245441556,
                -0.014101898297667503,
                -0.012897682376205921,
                -0.01736576110124588,
                -0.02867826446890831,
                0.011136109009385109,
                -0.04082217440009117,
                -0.04293219372630119,
                0.021155336871743202,
                -0.01877979375422001,
                0.008106269873678684,
                -0.0168888159096241,
                0.021322431042790413,
                0.004424838814884424,
                -0.0568002387881279,
                -0.029441645368933678,
                -0.11467786878347397,
                -0.04054158926010132,
                0.05256829038262367,
                0.005804145708680153,
                -0.03231145814061165,
                0.007979796268045902,
                0.024251792579889297,
                0.017326826229691505,
                0.02664552815258503,
                0.00847382191568613,
                0.07764927297830582,
                0.03735174238681793,
                -0.038361500948667526,
                -0.036790214478969574,
                0.04884141683578491,
                0.007412431295961142,
                0.020183779299259186,
                -0.09752138704061508,
                0.026989655569195747,
                -0.010757620446383953,
                -0.027893105521798134,
                -0.021058687940239906,
                -0.0065874140709638596,
                -0.006854694802314043,
                -0.04712981358170509,
                -0.02393210120499134,
                -0.0345233790576458,
                0.0032324467319995165,
                -0.03749614953994751,
                -0.05731848627328873,
                -0.002976294606924057,
                -0.032010532915592194,
                -0.036275025457143784,
                0.06368203461170197,
                -0.030739033594727516,
                -0.029083283618092537,
                -0.009852873161435127,
                0.03447343781590462,
                -0.10029958188533783,
                0.04543488472700119,
                -0.05762706696987152,
                0.05837108567357063,
                -0.05733606964349747,
                -0.03874786198139191,
                0.06743288040161133,
                -0.034240853041410446,
                -0.029909824952483177,
                -0.00928126834332943,
                0.023715149611234665,
                0.028488043695688248,
                0.04860474914312363,
                -0.026642078533768654,
                0.0692804679274559,
                0.045960597693920135,
                0.05913877859711647,
                -0.03919567912817001,
                -0.00819754134863615,
                -0.039799775928258896,
                0.05284430831670761,
                0.034689322113990784,
                -0.05663134157657623,
                0.02901911735534668,
                -0.027226561680436134,
                -0.006785666570067406,
                0.039947375655174255,
                0.06331297755241394,
                0.02293635718524456,
                0.04401693493127823,
                -0.013582290150225163,
                -0.022469596937298775,
                0.04130706563591957,
                0.0647222027182579,
                0.0707765594124794,
                0.0324186310172081,
                -0.020838109776377678,
                -0.04452424868941307,
                0.07842271029949188,
                0.027959490194916725,
                -0.026846347376704216,
                0.021619856357574463,
                0.03681538999080658,
                -0.018517758697271347,
                0.0060572330839931965,
                0.043618347495794296,
                0.00924159586429596,
                0.014203622937202454,
                0.03756258264183998,
                -0.04364830628037453,
                0.007854344323277473,
                0.061342447996139526,
                -0.06117015331983566,
                -0.02798147313296795,
                -0.0726277232170105,
                -0.06621278077363968,
                -0.036325156688690186,
                0.0021081874147057533,
                0.004597373306751251,
                0.023334087803959846,
                -0.05907164514064789,
                0.01926778443157673,
                -0.04849216341972351,
                0.0363997183740139,
                0.023923130705952644,
                -0.026742976158857346,
                0.0387246310710907,
                0.02843151055276394,
                -0.04988246411085129,
                -0.009378296323120594,
                -0.07909426093101501,
                0.042140912264585495,
                0.07164695113897324,
                0.0049584596417844296,
                -0.006915086880326271,
                -0.008925122208893299,
                -0.00016918023175094277,
                0.004156220238655806,
                -0.03588155284523964,
                0.004198814742267132,
                -0.05257679522037506,
                -0.0417926087975502,
                0.009181146509945393,
                -0.06145616993308067,
                0.004854760132730007,
                -0.031069565564393997,
                -0.028810249641537666,
                0.09083788841962814,
                0.008008904755115509,
                -0.054981593042612076,
                -0.008348364382982254,
                0.02726076729595661,
                0.008033517748117447,
                0.006822721101343632,
                0.048020295798778534,
                0.01171592716127634,
                0.013181040063500404,
                0.08639834076166153,
                -0.010437428019940853,
                0.015540877357125282,
                -0.009724341332912445,
                -0.06090881675481796,
                0.02104378677904606,
                -0.02769746631383896,
                0.09259133785963058,
                0.09351694583892822,
                0.04306744411587715,
                -0.027447020635008812,
                0.012802346609532833,
                0.01087853405624628,
                0.03821207955479622,
                -0.01864621788263321,
                0.012110146693885326,
                0.00246088532730937,
                0.033670008182525635,
                -0.04885294660925865,
                -0.005238409154117107,
                -0.03376215323805809,
                -0.00403480464592576,
                -0.007780299987643957,
                0.014451631344854832,
                -0.009673628024756908,
                -0.06047065556049347,
                0.005681734066456556,
                -0.005833454895764589,
                0.003353322623297572,
                0.0027980359736829996,
                -0.03526093065738678,
                0.0507662333548069,
                -0.019518917426466942,
                -0.025841593742370605,
                0.01165283378213644,
                0.0091526098549366,
                0.003943305462598801,
                -0.007348339539021254,
                -0.014208275824785233,
                0.006409974303096533,
                -0.03859487920999527,
                -0.048683296889066696,
                -0.03636686131358147,
                0.012945085763931274,
                0.01977844350039959,
                0.041439007967710495,
                -0.0063257040455937386,
                -0.006595737300813198,
                -0.008718842640519142,
                0.06287538260221481,
                -0.0370744951069355,
                0.02639070898294449,
                -0.012541755102574825,
                0.017459550872445107,
                0.00731604965403676,
                0.04321606084704399,
                0.018652860075235367,
                0.030733734369277954,
                -0.015323667787015438,
                0.027732575312256813,
                -0.016111884266138077,
                -0.035326071083545685,
                -0.011608481407165527,
                -0.05110560730099678,
                -0.07181241363286972,
                -0.035906001925468445,
                -0.0007445542723871768,
                -0.02839033119380474,
                0.006570808123797178,
                -0.04716985300183296,
                0.02409636601805687,
                -0.01948866993188858,
                0.0151241235435009,
                0.02013607881963253,
                -0.010847319848835468,
                -0.007989209145307541,
                -0.0793289765715599,
                0.03957970812916756,
                -0.024356570094823837,
                0.019227758049964905,
                -0.016963277012109756,
                -0.003627372905611992,
                -0.008417258970439434,
                -0.022268159314990044,
                0.040354546159505844,
                0.036793507635593414,
                -0.022685518488287926,
                -0.016272513195872307,
                -0.059428926557302475,
                -0.06436072289943695,
                0.02376120164990425,
                0.004749595187604427,
                -0.001971177291125059,
                -0.009875076822936535,
                -0.008038993924856186,
                -0.033685386180877686,
                0.042806122452020645,
                -0.054896168410778046,
                0.011849912814795971,
                -0.05539418011903763,
                0.017961308360099792,
                -0.000652078480925411,
                -0.05239240452647209,
                -5.113379120241842e-33,
                -0.0034519254695624113,
                -5.9518595662666485e-05,
                0.0035258326679468155,
                0.050392039120197296,
                -0.05931704863905907,
                -0.06179025396704674,
                -0.014403507113456726,
                0.05200638994574547,
                -0.02341475710272789,
                -0.021853944286704063,
                -0.015199938789010048,
                0.002878067083656788,
                0.023497674614191055,
                -0.028992120176553726,
                0.07458257675170898,
                -0.06717625260353088,
                -0.04658375680446625,
                0.01801682822406292,
                0.0003509073576424271,
                -0.05996106192469597,
                -0.01437170710414648,
                0.0106238704174757,
                0.030126862227916718,
                -0.07133416831493378,
                0.039507973939180374,
                0.01197938621044159,
                -0.025245999917387962,
                -0.02741374261677265,
                -0.032835498452186584,
                -0.013408238068223,
                0.009877333417534828,
                -0.03120267204940319,
                0.01337676215916872,
                0.016638677567243576,
                0.0251182708889246,
                0.08685591071844101,
                -0.0007098013302311301,
                -0.04407116770744324,
                0.02339928224682808,
                -0.08576607704162598,
                -0.07194817811250687,
                -0.038027506321668625,
                0.04590711370110512,
                -0.020930524915456772,
                -0.025891797617077827,
                -0.038543038070201874,
                -0.01647159457206726,
                0.0027077191043645144,
                0.022122424095869064,
                -0.031280267983675,
                -0.059620391577482224,
                -0.024144485592842102,
                -0.006663705687969923,
                -0.014589299447834492,
                0.014859011396765709,
                0.04477284848690033,
                0.00119694578461349,
                -0.042057424783706665,
                -0.0391121469438076,
                0.01843300834298134,
                -0.032816123217344284,
                -0.009586207568645477,
                -0.01855372078716755,
                0.03031645342707634,
                0.02079339139163494,
                0.012751382775604725,
                -0.07408908009529114,
                0.03119312785565853,
                0.008517742156982422,
                -0.03558029979467392,
                0.031594038009643555,
                0.04716184362769127,
                0.01306583546102047,
                -0.008170810528099537,
                -0.08367381989955902,
                -0.04804569482803345,
                -0.04208393767476082,
                0.0041426015086472034,
                0.02301994524896145,
                0.08602670580148697,
                -0.013035285286605358,
                0.019162964075803757,
                -0.02287929691374302,
                -0.014814982190728188,
                0.009257830679416656,
                0.04196854680776596,
                0.010781695134937763,
                -0.00745136896148324,
                -0.0010730925714597106,
                -0.010221500881016254,
                -0.05157483369112015,
                0.01901141367852688,
                -0.005341189447790384,
                0.07297942042350769,
                0.06697331368923187,
                -0.03298262134194374,
                0.08395080268383026,
                -0.023271366953849792,
                0.02000938542187214,
                -0.01723937690258026,
                -0.02809174917638302,
                0.00729076424613595,
                -0.059101253747940063,
                0.014478218741714954,
                0.036233752965927124,
                0.0057545434683561325,
                0.0008489174651913345,
                0.033364586532115936,
                -0.022079642862081528,
                0.0009180884226225317,
                0.019461678341031075,
                0.05178695544600487,
                0.03538432717323303,
                -0.021986080333590508,
                0.02147061750292778,
                -0.014253645204007626,
                0.037054773420095444,
                0.014763862825930119,
                0.039581988006830215,
                -0.03130604699254036,
                -0.0031816288828849792,
                0.06996198743581772,
                -0.0006332284538075328,
                0.021699706092476845,
                0.02645401656627655,
                0.012388188391923904,
                0.022748272866010666,
                0.052790988236665726,
                0.00337511720135808,
                -0.045571260154247284,
                0.023172441869974136,
                -0.037470780313014984,
                1.9552312835457997e-07,
                -0.05654558539390564,
                0.0007193502387963235,
                0.004533620551228523,
                0.07767632603645325,
                0.02613772824406624,
                0.0643918365240097,
                -0.013490929268300533,
                0.08325054496526718,
                0.044938620179891586,
                -0.016959311440587044,
                0.06878314167261124,
                -0.03600258007645607,
                -0.027473822236061096,
                0.012507671490311623,
                0.09677955508232117,
                -0.04261109605431557,
                -0.010915028862655163,
                0.016021303832530975,
                0.024368178099393845,
                -0.018619470298290253,
                0.017682025209069252,
                -0.007106385659426451,
                0.005347194615751505,
                0.02168995700776577,
                -0.01373173389583826,
                0.006895884871482849,
                -0.010332208126783371,
                0.003556092968210578,
                0.054868344217538834,
                -0.06481313705444336,
                0.020134342834353447,
                0.055621493607759476,
                -0.0008505783625878394,
                0.019137902185320854,
                -0.004898545332252979,
                -0.07037802040576935,
                -0.05152146518230438,
                0.05521992966532707,
                -0.007114144507795572,
                0.06705506891012192,
                -0.021321162581443787,
                -0.044427402317523956,
                -0.02620905078947544,
                0.02986006811261177,
                0.022853532806038857,
                -0.05833703652024269,
                0.0050690993666648865,
                0.06311686336994171,
                -0.066169872879982,
                -0.020429791882634163,
                0.02533429116010666,
                0.031253717839717865,
                0.01056061964482069,
                0.0220674816519022,
                -0.00045543117448687553,
                0.04201951622962952,
                0.015268809162080288,
                0.01102551817893982,
                0.04503795877099037,
                -0.013429732993245125,
                0.02650989033281803,
                -0.06687314063310623,
                0.032399024814367294,
                0.07403726875782013,
                -0.08255405724048615,
                -0.05794006586074829,
                -0.022608179599046707,
                1.0518199473238015e-34,
                -0.015301336534321308,
                0.0012127335648983717,
                -0.03372044116258621,
                -0.0699702799320221,
                0.025784887373447418,
                -0.0010525183752179146,
                0.024176737293601036,
                0.03874281048774719,
                0.03910301625728607,
                0.038091883063316345,
                -0.03763046860694885,
                -0.013218097388744354,
                -0.032588012516498566,
                0.009174229577183723,
                0.013777241110801697,
                -0.021861014887690544,
                -0.0020568009931594133,
                0.03596365079283714,
                0.03856424614787102,
                -0.02025892771780491,
                0.018590493127703667,
                0.03979582339525223,
                -0.057669684290885925,
                0.015835119411349297,
                0.06893960386514664,
                0.024915581569075584,
                -0.04642055183649063,
                0.03728625923395157,
                0.01416492834687233,
                -0.10211968421936035,
                -0.017510248348116875,
                -0.02023492008447647,
                -0.0072523667477071285,
                0.012087010778486729,
                0.01863669604063034,
                0.03368518501520157,
                0.01257542334496975,
                0.03303255885839462,
                -0.012917665764689445,
                -0.03255284205079079,
                -0.011800290085375309,
                0.07152483612298965,
                0.047364477068185806,
                0.02735712379217148,
                -0.036299820989370346,
                1.487282247580879e-06,
                -0.03898259252309799,
                -0.0167769193649292,
                0.002911565126851201,
                -0.03239075466990471,
                0.023967700079083443,
                -0.0006809934857301414,
                0.022668927907943726,
                -0.038791097700595856,
                0.006746059283614159,
                -0.019280342385172844,
                -0.04240674525499344,
                0.018025854602456093,
                -0.004590282682329416,
                -0.003405627328902483,
                0.004440057557076216,
                0.0025289433542639017,
                -0.034443773329257965,
                0.0068367826752364635,
                -0.02105037309229374,
                -0.02957128919661045,
                0.018703872337937355,
                -0.008531455881893635,
                0.03637704998254776,
                0.0572039969265461,
                0.07047055661678314,
                0.025561567395925522,
                -0.00372556084766984,
                0.012954466976225376,
                -0.004290001001209021,
                -0.030514242127537727,
                -0.00787544809281826,
                -0.043754998594522476,
                -0.057083770632743835,
                0.020660502836108208,
                0.020979277789592743,
                0.11368685215711594,
                0.013097604736685753,
                -0.002313044387847185,
                0.10173512250185013,
                -0.022438708692789078,
                -0.10906244814395905,
                0.02329547517001629,
                -0.00964900478720665,
                -0.019197707995772362,
                -0.008769448846578598,
                -0.011595362797379494,
                0.022715572267770767,
                -0.002302594017237425,
                0.008397845551371574,
                0.00935693085193634,
                0.05940724164247513,
                -0.009615588933229446,
                -0.012240912765264511,
                0.00815166812390089,
                -0.006070559844374657,
                -0.06540780514478683,
                -0.021156219765543938,
                0.006227327510714531,
                0.012133569456636906,
                0.025853022933006287,
                -0.005705888383090496,
                0.011952811852097511,
                0.0338052473962307,
                0.07223843783140182,
                -0.054065607488155365,
                -0.018208689987659454,
                0.006947079673409462,
                0.07112637907266617,
                0.025621842592954636,
                -0.0071526747196912766,
                0.007293746341019869,
                -0.010110709816217422,
                -0.012910490855574608,
                0.00860139075666666,
                0.020376937463879585,
                -0.007477326784282923,
                -0.003841627622023225,
                0.012662299908697605,
                -0.0229932963848114,
                0.02040998823940754,
                0.00987963005900383,
                -0.01164416316896677,
                0.013870245777070522,
                0.013774063438177109,
                0.08568327128887177,
                0.06140356510877609,
                0.0009794228244572878,
                0.009070737287402153,
                -0.010984241962432861,
                0.026260120794177055,
                -0.010640258900821209,
                -0.05098354071378708,
                -0.002936017932370305,
                0.021126873791217804,
                -0.02820046804845333,
                0.01882086507976055,
                -0.016348252072930336,
                -0.00945848785340786,
                -0.002359583042562008,
                0.040448155254125595,
                0.09371146559715271,
                -0.02345314435660839,
                0.03547882288694382,
                0.03117845207452774,
                -0.03087811917066574,
                0.007939348928630352,
                0.01427213940769434,
                0.025763273239135742,
                0.04745739698410034,
                0.0076409983448684216,
                -0.02604532614350319,
                0.008982834406197071,
                -0.04700823500752449,
                -0.009590884670615196,
                0.026451753452420235,
                -0.032489627599716187,
                -0.006186866667121649,
                -0.09224048256874084,
                0.0078070810995996,
                -0.02082108147442341,
                0.028611553832888603,
                -0.01669512502849102,
                -0.04324265196919441,
                -0.007016430608928204,
                -0.008042048662900925,
                0.04546905308961868,
                -0.00736389821395278,
                -0.051679499447345734,
                0.006885202135890722,
                -0.022065753117203712,
                0.019659044221043587,
                0.001174904522486031,
                0.016960909590125084,
                -0.019309137016534805,
                -0.044819772243499756,
                -0.04724665731191635,
                0.03418819233775139,
                -0.005320047028362751,
                0.02644096128642559,
                0.013007006607949734,
                -0.04282689467072487,
                0.08190422505140305,
                0.01766720414161682,
                -0.06675024330615997,
                0.05313671752810478,
                -0.008386916480958462,
                -0.11134610325098038,
                0.0014226788189262152,
                -0.0068471916019916534,
                0.04215163365006447,
                0.03555958345532417,
                0.030703691765666008,
                0.0165981724858284,
                -0.03212776780128479,
                -0.04932481795549393,
                -0.0033040319103747606,
                0.0256031583994627,
                -0.07973729819059372,
                -0.009920148178935051,
                0.019774502143263817,
                0.03329828381538391,
                0.0017850878648459911,
                0.024825099855661392,
                -0.0027342706453055143,
                -0.034074753522872925,
                -0.0070686968974769115,
                -0.003444637404754758,
                0.06870437413454056,
                0.04190024733543396,
                -0.019334465265274048,
                -0.0017579052364453673,
                0.04567427933216095,
                -0.035021740943193436,
                -0.06228005141019821,
                0.0007463018991984427,
                -0.06759840995073318,
                -0.006623488385230303,
                0.011898307129740715,
                -0.033920977264642715,
                -0.014715460129082203,
                0.03130621835589409,
                0.01644403301179409,
                0.022271327674388885,
                -0.059728365391492844,
                0.034889690577983856,
                -0.016831079497933388,
                0.014502697624266148,
                -0.0026060431264340878,
                0.0020100255496799946,
                0.010086966678500175,
                -0.014433331787586212,
                -0.018262743949890137,
                0.021452011540532112,
                -0.00523713231086731,
                0.014535140246152878,
                -0.017498794943094254,
                -0.015932384878396988,
                -0.008523844182491302,
                0.03410007059574127,
                0.012728729285299778,
                -0.018024984747171402,
                0.05673019587993622,
                0.05705748870968819,
                0.05749942362308502,
                -0.04179566726088524,
                -0.007714956998825073,
                0.0036338495556265116,
                0.010785965248942375,
                0.03569994866847992,
                -0.01916635036468506,
                0.03460995852947235,
                -0.03537096828222275,
                -0.046299759298563004,
                -0.0064341723918914795,
                -0.007675392087548971,
                0.024433517828583717,
                -0.0006735741626471281,
                -0.021069006994366646,
                -0.033099427819252014,
                0.010536332614719868,
                -0.011696990579366684,
                -0.019298896193504333,
                0.05127055197954178,
                0.021739186719059944,
                -0.0015139083843678236,
                -0.004908840637654066,
                -0.03330491483211517,
                0.03727543354034424,
                -0.007228869944810867,
                0.0691835805773735,
                0.0008960803970694542,
                -0.01709664985537529,
                0.010354702360928059,
                0.022807415574789047,
                -0.005021251738071442,
                -0.00892501138150692,
                0.02391396462917328,
                -0.0055941431783139706,
                0.0022205053828656673,
                -0.018440118059515953,
                -0.0423089899122715,
                -0.052784815430641174,
                0.0085628442466259,
                -0.008527769707143307,
                0.019866976886987686,
                0.02605997584760189,
                0.04605685919523239,
                -0.017425445839762688,
                -0.011916905641555786,
                0.018139636144042015,
                0.02780650183558464,
                -0.014171594753861427,
                0.055254608392715454,
                -0.06272436678409576,
                0.09324657917022705,
                -0.036040857434272766,
                0.009598352946341038,
                0.014664893969893456,
                -0.044732727110385895,
                0.018801720812916756,
                0.008756198920309544,
                -0.03613266348838806,
                0.04715526103973389,
                0.014636627398431301,
                0.027008797973394394,
                -0.012218671850860119,
                0.011101564392447472,
                -0.008743456564843655,
                -0.006426154635846615,
                0.01416341308504343,
                0.02571648359298706,
                0.03668311983346939,
                -0.04115617275238037,
                0.015318772755563259,
                0.0012632525758817792,
                -0.07242557406425476,
                0.0003217532648704946,
                0.026502685621380806,
                0.03679278865456581,
                0.00739141833037138,
                -0.014459479600191116,
                0.017162710428237915,
                0.020219212397933006,
                0.004044021014124155,
                0.08851073682308197,
                0.03212341293692589,
                0.008750568144023418,
                -0.06366971135139465,
                -0.040708936750888824,
                -0.046868037432432175,
                0.05762869864702225,
                -0.011217976920306683,
                0.03702690079808235,
                -0.039837706834077835,
                -0.07612964510917664,
                0.009865101426839828,
                -0.03311263024806976,
                0.005207541864365339,
                -0.00187766645103693,
                -0.03794609010219574,
                0.023766813799738884,
                -0.04718389734625816,
                -0.10140901803970337,
                0.00012363721907604486,
                -0.056139957159757614,
                -0.03782402351498604,
                -0.048238661140203476,
                0.010606301948428154,
                0.005821231286972761,
                0.015010609291493893,
                0.014872318133711815,
                -0.07397497445344925,
                0.04414033889770508,
                -0.022376298904418945,
                0.04766647890210152,
                0.01762048713862896,
                0.008556063286960125,
                -0.010266466997563839,
                0.021674491465091705,
                -0.052946243435144424,
                -0.01802833378314972,
                0.020670363679528236,
                -0.10778400301933289,
                0.022361982613801956,
                -0.06643523275852203,
                -0.013596881181001663,
                0.003060403745621443,
                -0.06501029431819916,
                0.013997533358633518,
                -0.01486490573734045,
                0.035907477140426636,
                0.02279222384095192,
                0.04949967935681343,
                -0.044306110590696335,
                -0.005391113460063934,
                -0.02833501063287258,
                0.030273407697677612,
                -0.0006374013610184193,
                0.047415636479854584,
                -0.029696794226765633,
                -0.04742737114429474,
                -0.016525935381650925,
                0.0026593455113470554,
                -0.0029472270980477333,
                -0.011691981926560402,
                -0.010596119798719883,
                0.05135788768529892,
                0.08035746961832047,
                0.022369451820850372,
                -0.002724036341533065,
                -0.036410704255104065,
                -0.02630612440407276,
                -0.04228726029396057,
                0.04175492376089096,
                0.021011274307966232,
                0.049905695021152496,
                0.02246716059744358,
                -0.035707201808691025,
                0.046661779284477234,
                0.017306679859757423,
                -0.005214848555624485,
                0.09767274558544159,
                -0.03718584403395653,
                -0.0038076089695096016,
                0.03416011482477188,
                0.03211314231157303,
                -0.030127761885523796,
                -0.039106711745262146,
                0.027801748365163803,
                -0.01526966318488121,
                0.04992718994617462,
                0.06203727051615715,
                -0.005067198537290096,
                -0.002769764745607972,
                -0.023929648101329803,
                0.025263745337724686,
                -0.02236691117286682,
                -0.04000576585531235,
                -0.03770889714360237,
                0.026692820712924004,
                -0.006569593213498592,
                -0.04095727950334549,
                -0.004084858112037182,
                -5.6296274124179035e-05,
                -0.05908999964594841,
                0.03338704630732536,
                0.0019511388381943107,
                -0.014752646908164024,
                0.028273360803723335,
                0.04447610303759575,
                -0.005322507116943598,
                0.025434590876102448,
                0.004170066211372614,
                0.011939717456698418,
                0.025922125205397606,
                -0.02237013354897499,
                -0.018384579569101334,
                -0.06575184315443039,
                -0.028934026136994362,
                -0.05652376264333725,
                0.00045428203884512186,
                -0.005923575256019831,
                -0.06385120749473572,
                -0.0740521103143692,
                0.008386984467506409,
                -0.031061455607414246,
                -0.03919895365834236,
                0.0678473636507988,
                0.017580680549144745,
                -0.0744057297706604,
                0.07284881174564362,
                0.00664808414876461,
                0.01716904528439045,
                -0.02234787493944168,
                -0.011655540205538273,
                0.02417113073170185,
                -0.10572808235883713,
                0.06618868559598923,
                0.00041342031909152865,
                0.09065708518028259,
                -0.05193837732076645,
                -0.037012357264757156,
                0.06380690634250641,
                -0.017145562916994095,
                0.03722721338272095,
                0.05643722414970398,
                0.04554802179336548,
                -0.05208718031644821,
                0.007117369212210178,
                0.007427385542541742,
                -0.11530692875385284,
                -0.0911577120423317,
                0.05802657827734947,
                -0.037226129323244095,
                -0.0016753494273871183,
                -0.10790359973907471,
                -0.015002845786511898,
                -0.03524092957377434,
                0.037652354687452316,
                0.030899908393621445,
                0.04173807054758072,
                -0.03614380583167076,
                -0.025278078392148018,
                0.02014973573386669,
                0.0037471833638846874,
                0.018635351210832596,
                -0.01370286475867033,
                0.007755194325000048,
                -0.020993446931242943,
                0.006983392406255007,
                -0.007701433263719082,
                0.02899296209216118,
                -0.012999110855162144,
                -0.04293379560112953,
                0.005090067628771067,
                0.024934878572821617,
                -0.032399360090494156,
                -0.052985548973083496,
                0.0784040093421936,
                0.009164809249341488,
                0.046726297587156296,
                -0.010960246436297894,
                -0.016014184802770615,
                -0.050156209617853165,
                0.09424572438001633,
                0.009751519188284874,
                0.013504281640052795,
                -0.020292967557907104,
                -0.07261147350072861,
                0.02499527297914028,
                0.06563487648963928,
                0.010650735348463058,
                0.023810237646102905,
                0.004893768113106489,
                0.002105266787111759,
                -0.017187301069498062,
                0.09162977337837219,
                -0.0035083023831248283,
                0.08250909298658371,
                0.018493352457880974,
                -0.07339939475059509,
                -0.001053220359608531,
                -0.011458273977041245,
                -0.024278225377202034,
                -0.028478339314460754,
                0.04981158301234245,
                -0.03445856645703316,
                0.03624899685382843,
                -0.02124582976102829,
                -0.01171907503157854,
                -0.031881578266620636,
                0.002757689915597439,
                -0.003229032503440976,
                -0.013725980184972286,
                0.02507627382874489,
                0.001482582651078701,
                -0.09025386720895767,
                -0.04027700796723366,
                -0.025845099240541458,
                -0.01617128774523735,
                0.05924447625875473,
                -0.01281508058309555,
                -0.05157732218503952,
                -0.010739427991211414,
                0.007985684089362621,
                0.006290676072239876,
                -0.04022712633013725,
                0.0025319817941635847,
                0.01588732749223709,
                -0.014342484064400196,
                0.06994058191776276,
                -0.005506791640073061,
                -0.005311234388500452,
                0.0183490552008152,
                -0.04738380014896393,
                0.006994950585067272,
                -0.04023663327097893,
                -0.014726084657013416,
                0.04363406449556351,
                0.010599855333566666,
                -6.181378186101278e-33,
                -0.037325724959373474,
                -0.0029450857546180487,
                0.0007288993801921606,
                0.007108150981366634,
                -0.06390313804149628,
                -0.049997203052043915,
                -0.00877311360090971,
                0.00986258964985609,
                -0.048919204622507095,
                -0.014433261938393116,
                -0.03112458810210228,
                -0.004297732375562191,
                0.03245898336172104,
                -0.02682497166097164,
                -0.00041642264113761485,
                0.02549627050757408,
                -0.0006669863360002637,
                2.4912091248552315e-05,
                -0.001718378160148859,
                -0.05854175612330437,
                -0.010425599291920662,
                0.002320116851478815,
                -0.0023038736544549465,
                0.019175482913851738,
                0.016916655004024506,
                0.022314881905913353,
                -0.015452436171472073,
                0.02704109624028206,
                0.0509333610534668,
                0.020608285441994667,
                0.01578431762754917,
                -0.025442015379667282,
                0.03290076181292534,
                0.035774655640125275,
                -0.021931618452072144,
                0.04415878280997276,
                -0.058460064232349396,
                -0.014658571220934391,
                0.009341707453131676,
                0.005221379920840263,
                0.03176328167319298,
                -0.029152804985642433,
                -0.0308014377951622,
                -0.00018720986554399133,
                -0.05257941037416458,
                -0.04651422053575516,
                -0.03947732225060463,
                -0.0193079374730587,
                -0.04740332439541817,
                0.08142806589603424,
                0.014069739729166031,
                -0.041111890226602554,
                0.015063933096826077,
                0.06821531802415848,
                0.035725321620702744,
                0.057688575237989426,
                0.018216485157608986,
                -0.036689914762973785,
                0.013038799166679382,
                -0.004284307360649109,
                0.03475116938352585,
                0.0035746118519455194,
                0.03298904374241829,
                -0.008937171660363674,
                0.036952290683984756,
                0.0036523027811199427,
                -0.01921551115810871,
                0.03548350930213928,
                0.03238598629832268,
                -0.012109455652534962,
                0.003256634809076786,
                0.08474244177341461,
                0.01655639335513115,
                0.039253637194633484,
                -0.095138318836689,
                -0.011783026158809662,
                -0.017685238271951675,
                0.033465296030044556,
                -0.06023009493947029,
                0.027409669011831284,
                -0.0042640212923288345,
                -0.0418495275080204,
                0.0035707280039787292,
                0.008269388228654861,
                -0.052692342549562454,
                -0.005703100468963385,
                -0.012464444153010845,
                -0.00448181014508009,
                0.037511687725782394,
                -0.003656513523310423,
                0.03048880398273468,
                0.055605169385671616,
                0.008447166532278061,
                0.001944473828189075,
                0.030533723533153534,
                -0.10157852619886398,
                0.07585237920284271,
                2.63986785284942e-05,
                0.006565671879798174,
                0.03452421352267265,
                0.0070098405703902245,
                -0.03397533670067787,
                0.013880922459065914,
                0.03873995319008827,
                -0.004159156233072281,
                0.010035274550318718,
                -0.014713743701577187,
                -0.021929236128926277,
                -0.020596634596586227,
                0.029293028637766838,
                0.008091632276773453,
                0.0027541450690478086,
                0.04532128572463989,
                0.001254972186870873,
                -0.0364232175052166,
                0.015769705176353455,
                0.0394379086792469,
                0.007888016290962696,
                -0.04050014540553093,
                -0.05561487004160881,
                0.05708472058176994,
                -0.03668993338942528,
                0.0009015711257234216,
                0.03854651749134064,
                -0.0035090772435069084,
                -0.007585484068840742,
                -0.0008590790675953031,
                0.04611176997423172,
                0.013085883110761642,
                0.04447295144200325,
                0.015174297615885735,
                -0.02270609699189663,
                2.0809775946872833e-07,
                0.021625643596053123,
                0.03149520605802536,
                0.008925771340727806,
                0.019882526248693466,
                0.03251902759075165,
                -0.013864611275494099,
                -0.054224226623773575,
                0.026066815480589867,
                -0.038833942264318466,
                0.020217109471559525,
                0.0030135686974972486,
                -0.0384669229388237,
                0.019452499225735664,
                -0.029808716848492622,
                0.018601346760988235,
                -0.056258127093315125,
                0.038197796791791916,
                -0.020099755376577377,
                0.010669809766113758,
                -0.016349904239177704,
                0.0011060470715165138,
                0.005889526102691889,
                -0.054627954959869385,
                -0.01308735366910696,
                0.00385827524587512,
                -0.0664336085319519,
                0.021236000582575798,
                -0.0787234753370285,
                -0.002040266990661621,
                -0.03181343153119087,
                0.05719592049717903,
                0.011609829030930996,
                0.05540362745523453,
                -0.006703609135001898,
                0.02367902174592018,
                0.0015510640805587173,
                0.03294751048088074,
                0.07562104612588882,
                -0.021514447405934334,
                0.039432819932699203,
                -0.00674789771437645,
                -0.09733832627534866,
                -0.0012719329679384828,
                -0.04392022266983986,
                -0.0030310058500617743,
                0.07976756244897842,
                0.013627183623611927,
                0.041147395968437195,
                -0.08730034530162811,
                -0.04862920567393303,
                0.024647671729326248,
                -0.0007315372349694371,
                0.0004663022409658879,
                -0.015887591987848282,
                0.02710079587996006,
                -0.00017776424647308886,
                0.030341051518917084,
                0.03423211723566055,
                0.05473782494664192,
                -0.043669648468494415,
                -0.03245866298675537,
                -0.024289805442094803,
                -0.00011090902262367308,
                0.018500743433833122,
                0.01992831379175186,
                -0.0283642765134573,
                -0.015847237780690193,
                1.7113163591732607e-34,
                -0.01826300285756588,
                -0.0534939207136631,
                0.023885587230324745,
                0.04462054744362831,
                0.004964995663613081,
                -0.0004882826469838619,
                0.03594392538070679,
                -0.015480563044548035,
                -0.01335109118372202,
                -0.03838206082582474,
                -0.00737037044018507,
                -0.03685125708580017,
                -0.01657501608133316,
                0.003314658999443054,
                -0.07932716608047485,
                0.008194558322429657,
                0.031215304508805275,
                0.0003529594396241009,
                0.049774862825870514,
                0.06881198287010193,
                -0.021201174706220627,
                0.034332290291786194,
                -0.04272540658712387,
                -0.009486406110227108,
                0.053008876740932465,
                0.009733002632856369,
                -0.011781317181885242,
                -0.014540028758347034,
                0.0419248603284359,
                -0.09979690611362457,
                -0.05908474326133728,
                -0.06343039870262146,
                -0.0021985541097819805,
                0.007416986394673586,
                0.016077732667326927,
                0.053343113511800766,
                -0.020044637843966484,
                -0.004571474622935057,
                0.03848188742995262,
                0.010049867443740368,
                0.017328554764389992,
                0.02129318378865719,
                0.016213662922382355,
                0.04244451969861984,
                0.005176773760467768,
                2.0508728084678296e-06,
                -0.05023736506700516,
                -0.014448721893131733,
                -0.017635855823755264,
                -0.05302052199840546,
                0.027526816353201866,
                -0.00045846306602470577,
                -0.01301429234445095,
                -0.06011107563972473,
                -0.04578414931893349,
                0.019643252715468407,
                0.01598517782986164,
                0.03720696270465851,
                -0.08054251223802567,
                -0.01806720532476902,
                0.01781182922422886,
                0.005921225994825363,
                0.002345501910895109,
                -0.0859333947300911,
                -0.047574594616889954,
                0.049122318625450134,
                0.00417691795155406,
                0.0346580445766449,
                -0.01360210869461298,
                -0.037392955273389816,
                0.04845576733350754,
                -0.01744878850877285,
                0.011655253358185291,
                0.0068984427489340305,
                0.014126528054475784,
                0.030006660148501396,
                0.014358499087393284,
                0.05852549150586128,
                0.06005492061376572,
                0.006283771712332964,
                0.0337834395468235,
                0.00847979448735714,
                0.008189388550817966,
                0.02546057105064392,
                0.07645092904567719,
                -0.08149553090333939,
                0.053298644721508026,
                0.029787158593535423,
                -0.013233895413577557,
                0.041894715279340744,
                -0.013745737262070179,
                0.03614375367760658,
                0.03497995063662529,
                -0.021407874301075935,
                0.04741082713007927,
                0.06522899121046066,
                -0.04334568604826927,
                0.04029965028166771,
                -0.025037720799446106,
                0.006739766336977482,
                0.025639913976192474,
                0.058155521750450134,
                0.011697249487042427,
                -0.02022063359618187,
                0.0376497246325016,
                0.008689581416547298,
                -0.009797167032957077,
                0.05613671988248825,
                -0.0020110642071813345,
                0.005759708117693663,
                -0.03499922156333923,
                -0.05434751883149147,
                0.053160715848207474,
                0.03691635653376579,
                0.02721245028078556,
                -0.018649259582161903,
                -0.009115146473050117,
                0.0031842361204326153,
                -0.028915459290146828,
                0.04424672946333885,
                0.05836746469140053,
                0.0022822332102805376,
                0.02241927571594715,
                -0.00294851534999907,
                0.0023822165094316006,
                -0.03406437858939171,
                -0.03968391567468643,
                -0.08272931724786758,
                -0.051064878702163696,
                0.02427133359014988,
                0.054543279111385345,
                0.05930677428841591,
                0.02924846112728119,
                -0.05288175866007805,
                0.018463486805558205,
                0.011536616832017899,
                0.05457199737429619,
                -0.06434034556150436,
                -0.03381482884287834,
                0.05298803746700287,
                0.06667397171258926,
                -0.020712506026029587,
                -0.0028974812012165785,
                0.024423526600003242,
                -0.017031995579600334,
                -0.0006800033152103424,
                0.034394603222608566,
                -0.022414501756429672,
                0.01962115429341793,
                -0.02179063856601715,
                0.001119128311984241,
                0.011077017523348331,
                -0.016020679846405983,
                0.016928518190979958,
                -0.000526859366800636,
                0.009035457856953144,
                -0.051925692707300186,
                0.026032941415905952,
                -0.026261793449521065,
                -0.0279846228659153,
                -0.04606979340314865,
                0.023054255172610283,
                -0.03609849885106087,
                -0.04772422835230827,
                0.0008687516092322767,
                0.09490667283535004,
                0.027759741991758347,
                -0.022819919511675835,
                -0.008776867762207985,
                -0.041007593274116516,
                0.015276465564966202,
                0.03868231177330017,
                -0.061055563390254974,
                -0.00535820797085762,
                0.06716826558113098,
                -0.03072492964565754,
                0.016085583716630936,
                -0.020954083651304245,
                -0.04445428028702736,
                -0.04261691868305206,
                -0.02422439120709896,
                -0.053983092308044434,
                0.012899758294224739,
                -0.018810251727700233,
                -0.003841618774458766,
                -0.014876110479235649,
                0.10533250868320465,
                -0.06264746189117432,
                -0.032226599752902985,
                0.00411494355648756,
                0.03012348897755146,
                0.008455021306872368,
                -0.11496981978416443,
                0.02057122252881527,
                -0.003961794078350067,
                -0.036941833794116974,
                0.03975575417280197,
                -0.009650425054132938,
                -0.01957075670361519,
                -0.002018721541389823,
                0.007646224927157164,
                -0.006599840242415667,
                -0.018569808453321457,
                -0.11304377019405365,
                0.028772978112101555,
                0.04482211545109749,
                -0.026141973212361336,
                0.01826297864317894,
                0.030839627608656883,
                0.0609561912715435,
                -0.03779829293489456,
                -0.03322606906294823,
                0.029899291694164276,
                0.009741021320223808,
                0.12083198875188828,
                -0.04547933116555214,
                0.06424146145582199,
                0.010034061968326569,
                -0.03346625715494156,
                -0.10449231415987015,
                0.02890401892364025,
                -0.017898457124829292,
                0.0074194082990288734,
                0.06207598000764847,
                0.004061626270413399,
                -0.014097385108470917,
                -0.0014041990507394075,
                0.031052980571985245,
                -0.022386906668543816,
                -0.042861949652433395,
                0.006362789310514927,
                0.010996024124324322,
                0.06080808490514755,
                -0.024130698293447495,
                -0.02620464563369751,
                -0.022796805948019028,
                0.03667369857430458,
                0.0025993145536631346,
                0.026451319456100464,
                -0.02241837978363037,
                -0.0011822498636320233,
                -0.0035132230259478092,
                -0.0159650519490242,
                -0.025551702827215195,
                0.010270954109728336,
                -0.021882349625229836,
                -0.027589930221438408,
                0.03832511603832245,
                -0.02828664891421795,
                -0.004409033339470625,
                -0.024773459881544113,
                -0.03245682641863823,
                0.024719974026083946,
                0.02447105199098587,
                -0.0073642670176923275,
                0.020662041381001472,
                -0.006280495319515467,
                -0.028368132188916206,
                -0.0010135131888091564,
                0.04210059344768524,
                -0.027261773124337196,
                -0.04116883873939514,
                -0.06944476068019867,
                0.055953249335289,
                0.012194007635116577,
                -0.0009639711934141815,
                -0.05441855639219284,
                -0.01644933968782425,
                0.03143205866217613,
                -0.06711354851722717,
                -0.05244152247905731,
                0.011629830114543438,
                -0.013956636190414429,
                -0.03160460293292999,
                -0.00983649492263794,
                0.020093917846679688,
                -0.027014058083295822,
                0.053573474287986755,
                -0.026348846033215523,
                -0.009582108817994595,
                -0.009560828097164631,
                -0.00031194344046525657,
                0.06992793083190918,
                0.031904835253953934,
                -0.022596081718802452,
                -0.03892432898283005,
                0.02100004442036152,
                0.010827349498867989,
                0.00598536292091012,
                -0.030110666528344154,
                -0.007403758820146322,
                -0.03696449473500252,
                0.054421767592430115,
                -0.03748113662004471,
                -0.04253160208463669,
                0.006563362665474415,
                0.05068180710077286,
                -0.02207084186375141,
                -0.017353493720293045,
                -0.003040001727640629,
                0.062376003712415695,
                0.0069121792912483215,
                -0.01717907376587391,
                0.05812815949320793,
                -0.03435572609305382,
                0.00025295844534412026,
                -0.0161474347114563,
                -0.008874270133674145,
                0.049952130764722824,
                0.03798952326178551,
                0.0006799320108257234,
                0.004669926129281521,
                -0.029815256595611572,
                -0.029083266854286194,
                -0.02759464457631111,
                -0.052015043795108795,
                0.017839554697275162,
                -0.0022434054408222437,
                -0.013496818020939827,
                -0.05804898589849472,
                0.027194427326321602,
                -0.010887043550610542,
                0.03708073869347572,
                0.005994778126478195,
                -0.0027392662595957518,
                0.04231886938214302,
                0.013252545148134232,
                -0.018126297742128372,
                -0.0033896109089255333,
                0.013300508260726929,
                0.00985020212829113,
                0.025406090542674065,
                0.0577717125415802,
                0.024337591603398323,
                -0.008141852915287018,
                0.0074375588446855545,
                0.01877426914870739,
                -0.014698564074933529,
                -0.004904686473309994,
                -0.08238186687231064,
                -0.0762549340724945,
                0.005705391522496939,
                -0.027688078582286835,
                -0.015485993586480618,
                0.03154872730374336,
                -0.008182735182344913,
                -0.05432841554284096,
                0.00230246945284307,
                -0.06343507021665573,
                0.033013951033353806,
                -0.022082271054387093,
                0.0016432648990303278,
                -0.014443268068134785,
                -0.005600922740995884,
                0.032873302698135376,
                -0.05359342321753502,
                -0.024783750995993614,
                0.004780496004968882,
                0.04124421998858452,
                0.013620979152619839,
                -0.038212791085243225,
                -0.04325305297970772,
                0.07302837818861008,
                0.0009343738784082234,
                -0.019968515262007713,
                -0.02443954534828663,
                0.024621648713946342,
                0.07714539021253586,
                0.003355527762323618,
                -0.034779056906700134,
                -0.025089286267757416,
                0.0389857217669487,
                0.01774459518492222,
                0.06639578938484192,
                -0.018561286851763725,
                0.02287350222468376,
                0.037205617874860764,
                0.060926653444767,
                -0.03833305835723877,
                0.01759009249508381,
                0.047838930040597916,
                -0.010569619946181774,
                0.0010318218264728785,
                -0.03170333057641983,
                0.10788894444704056,
                -0.02164539135992527,
                -0.000545381277333945,
                0.01828945428133011,
                -0.006042133085429668,
                0.05628438666462898,
                0.007167545147240162,
                0.010313427075743675,
                -0.05648284777998924,
                0.010233794339001179,
                -0.02125047706067562,
                0.019328806549310684,
                -0.045938845723867416,
                -0.03919787332415581,
                0.03884366154670715,
                -0.032656364142894745,
                -0.006518029607832432,
                0.03181646391749382,
                -0.04767889156937599,
                0.03274351358413696,
                0.06402094662189484,
                0.007116675842553377,
                -0.007339539006352425,
                0.005725801922380924,
                -0.010834027081727982,
                0.01268639788031578,
                0.018278930336236954,
                0.007444597315043211,
                0.0022697162348777056,
                0.021906187757849693,
                0.037823550403118134,
                -0.028892122209072113,
                -0.044468119740486145,
                0.010067545808851719,
                -0.054135408252477646,
                0.00822718720883131,
                -0.07501734048128128,
                -0.006321273278445005,
                0.02992868423461914,
                -0.005669264122843742,
                -0.011866016313433647,
                -0.007538811303675175,
                -0.11492093652486801,
                -0.019823038950562477,
                -0.0007617110968567431,
                0.05133528262376785,
                -0.06644307076931,
                0.07207271456718445,
                0.032823629677295685,
                0.03604476898908615,
                0.004830424673855305,
                0.039755742996931076,
                0.00655582919716835,
                0.061099033802747726,
                -0.0038201562128961086,
                0.011237683705985546,
                0.008282584138214588,
                -0.018811726942658424,
                0.010024708695709705,
                -0.03382425010204315,
                0.03365083783864975,
                0.053641725331544876,
                -0.024829426780343056,
                0.04708762466907501,
                0.02689392678439617,
                -0.004411522764712572,
                -0.002805946161970496,
                -0.01821073517203331,
                0.000906671688426286,
                -0.021565616130828857,
                -0.021145423874258995,
                -0.01079342421144247,
                0.04408646002411842,
                0.0050343796610832214,
                0.008884888142347336,
                -0.008963328786194324,
                -0.01429791934788227,
                -0.0197148434817791,
                -0.04333818703889847,
                -0.0028520275373011827,
                0.0062950062565505505,
                0.039439789950847626,
                -0.03443879261612892,
                -0.04807544872164726,
                0.0541766956448555,
                0.018029341474175453,
                -0.0030368389561772346,
                0.005180715583264828,
                0.024708708748221397,
                0.01844296045601368,
                -0.0106425816193223,
                0.01816195249557495,
                -0.07391250878572464,
                -0.01754738949239254,
                -0.03087136149406433,
                -0.04914958402514458,
                -0.04098249226808548,
                -0.06553101539611816,
                -0.030156245455145836,
                0.004806310869753361,
                0.059374988079071045,
                0.024992771446704865,
                0.029562192037701607,
                0.004892531782388687,
                0.002988729625940323,
                0.020644094794988632,
                0.02947092615067959,
                0.024829749017953873,
                0.06724236160516739,
                -0.035025905817747116,
                -0.022214801982045174,
                0.012646673247218132,
                0.035953257232904434,
                -0.02566155418753624,
                -0.035464201122522354,
                0.017658639699220657,
                0.0072099887765944,
                0.00983349233865738,
                -0.0006778680835850537,
                -0.0007978875655680895,
                0.01766493357717991,
                0.01637873612344265,
                0.06932146102190018,
                -0.06518493592739105,
                0.0017691974062472582,
                -0.06176212430000305,
                -0.006694388575851917,
                0.021058738231658936,
                0.004857403226196766,
                0.027737461030483246,
                0.019312793388962746,
                0.009624680504202843,
                0.0009779245592653751,
                -0.029751785099506378,
                0.05346846580505371,
                -0.0071416269056499004,
                -0.04379104822874069,
                0.07299738377332687,
                0.0030784299597144127,
                -0.016055390238761902,
                0.01401692908257246,
                -0.027254413813352585,
                -0.029963256791234016,
                -0.007601976860314608,
                -0.001735934754833579,
                -0.005612875800579786,
                0.015120056457817554,
                -0.022046642377972603,
                -0.028394391760230064,
                0.008324217982590199,
                -0.005196384619921446,
                -0.02510753646492958,
                -0.01630508340895176,
                0.008610543794929981,
                -0.02218102663755417,
                0.04571254923939705,
                0.018089033663272858,
                0.015099340118467808,
                -0.0059597305953502655,
                0.03625042736530304,
                -0.02103201299905777,
                -0.00770405400544405,
                -0.005735128186643124,
                0.05120404437184334,
                0.01414494588971138,
                -0.003703110618516803,
                -0.022342907264828682,
                -0.03523479402065277,
                -0.027726979926228523,
                -0.021128995344042778,
                0.008483268320560455,
                -0.00808438565582037,
                0.05614004656672478,
                0.0143426563590765,
                0.0223250575363636,
                -0.04707222431898117,
                -0.024903034791350365,
                0.008183161728084087,
                0.03301595523953438,
                -0.0003470101801212877,
                0.030104774981737137,
                0.003457611659541726,
                -7.198302757101454e-33,
                0.026321059092879295,
                -0.039965223520994186,
                0.00010492283763596788,
                -0.08323731273412704,
                -0.03443983569741249,
                0.006994680501520634,
                -0.043240055441856384,
                0.0385698638856411,
                0.012967739254236221,
                -0.009518982842564583,
                0.008800892159342766,
                -0.022940952330827713,
                0.03171882405877113,
                0.057188574224710464,
                0.04681925103068352,
                -0.010930481366813183,
                0.009966114535927773,
                -0.009954015724360943,
                -0.046469200402498245,
                -0.02375009097158909,
                -0.01165890321135521,
                0.03406380116939545,
                0.03161436319351196,
                0.08386724442243576,
                -0.03223445266485214,
                0.03148708492517471,
                0.006678428500890732,
                -0.015097795985639095,
                -0.014930112287402153,
                -0.01326088048517704,
                -0.008642896078526974,
                0.034626610577106476,
                -0.029329175129532814,
                -0.012024247087538242,
                0.05064896494150162,
                0.016455166041851044,
                -0.03199014812707901,
                -0.00445997528731823,
                -0.08030718564987183,
                -0.032795943319797516,
                -0.03723868727684021,
                -0.04188859462738037,
                0.051887817680835724,
                -0.03629254549741745,
                -0.0839151069521904,
                -0.05964106321334839,
                -0.031754788011312485,
                0.000995315727777779,
                -0.00614954624325037,
                -0.05331378057599068,
                -0.05468224734067917,
                0.01710367389023304,
                -0.03329242020845413,
                0.05925020948052406,
                -0.030206430703401566,
                -0.025399282574653625,
                0.036359455436468124,
                -0.010465668514370918,
                0.006581807509064674,
                -0.030394740402698517,
                -0.0010257611284032464,
                -0.05213318020105362,
                0.04244549572467804,
                -0.06785944104194641,
                -0.032158657908439636,
                0.021071987226605415,
                -0.017228057608008385,
                0.04164402186870575,
                -8.147917833412066e-05,
                0.03304961696267128,
                0.04386548325419426,
                -0.003874558489769697,
                -0.028340822085738182,
                -0.08277396857738495,
                -0.06891737878322601,
                -0.006280460394918919,
                -0.03492097556591034,
                0.01642276905477047,
                -0.008953026495873928,
                0.030736874788999557,
                -0.069803886115551,
                0.024223165586590767,
                -0.02906348742544651,
                -0.0016350731020793319,
                -0.045761290937662125,
                0.06206997111439705,
                0.002138972282409668,
                -0.034103672951459885,
                -0.011472931131720543,
                -0.03186270594596863,
                -0.03458184748888016,
                0.1318308711051941,
                -0.005469546187669039,
                0.020656771957874298,
                0.05114947631955147,
                -0.0056325513869524,
                0.032741256058216095,
                -0.013345634564757347,
                0.00798998773097992,
                0.006110830698162317,
                -0.013933693990111351,
                0.062261104583740234,
                0.014869051054120064,
                0.03743664547801018,
                -0.05179344490170479,
                -0.016231928020715714,
                -0.047113656997680664,
                -0.03595306724309921,
                -0.03796287253499031,
                0.03782160207629204,
                0.032112304121255875,
                -0.044014882296323776,
                0.028728174045681953,
                -0.006056183949112892,
                0.033601902425289154,
                -0.004482136107981205,
                0.029917562380433083,
                0.00491274893283844,
                -0.023696336895227432,
                0.0063455309718847275,
                0.011926512233912945,
                0.06025778502225876,
                -0.011152447201311588,
                0.03028537705540657,
                -0.009470597840845585,
                0.02697419747710228,
                0.03443237021565437,
                -0.03490692749619484,
                0.0007484653033316135,
                -0.006377083249390125,
                0.03656454011797905,
                0.019060855731368065,
                2.600141897346475e-07,
                -0.03258039802312851,
                0.012365873903036118,
                0.028313010931015015,
                -0.00453123589977622,
                0.01943942904472351,
                0.015588072128593922,
                0.013507279567420483,
                0.027532530948519707,
                -0.009259370155632496,
                0.022923247888684273,
                -0.005744289141148329,
                -0.023823285475373268,
                0.03897255286574364,
                -0.07083597034215927,
                0.04554566368460655,
                -0.13668283820152283,
                -0.01683257892727852,
                -0.030447278171777725,
                -0.013288713060319424,
                0.04146161675453186,
                -0.03603033721446991,
                -0.01695653796195984,
                -0.014065686613321304,
                -0.04629897698760033,
                0.012997078709304333,
                0.0841071605682373,
                0.014349095523357391,
                -0.02182966098189354,
                -0.00956354197114706,
                0.05968823656439781,
                -0.09403406083583832,
                0.016209129244089127,
                0.0497027188539505,
                0.04831983521580696,
                -0.00697699747979641,
                -0.03498224541544914,
                0.07676232606172562,
                0.02440491132438183,
                0.01480394508689642,
                0.00753513490781188,
                -0.039436306804418564,
                -0.005179176572710276,
                0.007637327536940575,
                -0.007169374730437994,
                0.014266245067119598,
                -0.05045629292726517,
                -0.03455546870827675,
                0.04642457142472267,
                -0.10824178904294968,
                0.014740199781954288,
                -0.04486421123147011,
                -0.014080184511840343,
                0.014739513397216797,
                0.015115943737328053,
                0.03715788945555687,
                0.014642827212810516,
                0.04079792648553848,
                0.0182964950799942,
                0.05746523290872574,
                0.013381506316363811,
                0.016499478369951248,
                -0.03810867667198181,
                -0.00021311388991307467,
                0.011169617995619774,
                0.035165149718523026,
                -0.01107279397547245,
                -0.027961039915680885,
                1.8434475491298255e-34,
                -0.0029062938410788774,
                -0.004879959858953953,
                -0.0007389443344436586,
                0.026091033592820168,
                0.018017955124378204,
                -0.03408228978514671,
                0.0326843187212944,
                -0.06149273365736008,
                0.00037541124038398266,
                -0.0020012876484543085,
                -0.0341515988111496,
                -0.06157837063074112,
                0.05675777420401573,
                0.010879166424274445,
                -0.013850761577486992,
                -0.049417100846767426,
                0.008250500075519085,
                0.025873668491840363,
                -0.011835879646241665,
                -0.014981229789555073,
                0.0006036106497049332,
                0.04563203454017639,
                -0.07181807607412338,
                -0.0022155458573251963,
                0.01024358905851841,
                -0.020208675414323807,
                0.021242139860987663,
                0.04086099565029144,
                -0.030578432604670525,
                -0.014501410536468029,
                -0.0352313369512558,
                -0.006609863601624966,
                -0.02732028253376484,
                0.04993186891078949,
                0.025468092411756516,
                0.03708131983876228,
                -0.011448729783296585,
                0.021997027099132538,
                -0.029711619019508362,
                -0.024991082027554512,
                -0.016229141503572464,
                0.06636103242635727,
                0.007542118895798922,
                -0.005094248801469803,
                -0.02854275144636631,
                2.555102810219978e-06,
                -0.031535979360342026,
                -0.012273991480469704,
                0.0014942479319870472,
                -0.027745109051465988,
                0.018369901925325394,
                -0.03139493986964226,
                -0.040431875735521317,
                -0.00804661400616169,
                -0.000598676735535264,
                -0.017684755846858025,
                0.09964726865291595,
                0.015633748844265938,
                -0.021095585078001022,
                -0.005698641762137413,
                0.012236353009939194,
                -0.0056905848905444145,
                -0.08067579567432404,
                -0.056067612022161484,
                -0.037811290472745895,
                0.09771892428398132,
                0.03354218229651451,
                -0.00064184854272753,
                0.04149385169148445,
                -0.02938188798725605,
                -0.07057157158851624,
                -0.023134909570217133,
                0.02831316366791725,
                0.003222986124455929,
                -0.02288161776959896,
                -0.01623200997710228,
                0.050364017486572266,
                0.06507038325071335,
                0.011908191256225109,
                0.037862129509449005,
                -0.012971761636435986,
                -0.05560356006026268,
                -0.024084139615297318,
                0.039903268218040466,
                0.1008591577410698,
                -0.017704689875245094,
                -0.06996022164821625,
                -0.017345217987895012,
                0.029544804245233536,
                -0.040894754230976105,
                0.006406994070857763,
                0.01006736233830452,
                0.06341469287872314,
                0.019925348460674286,
                0.038966722786426544,
                -0.020585134625434875,
                -0.030239680781960487,
                0.00037964750663377345,
                -0.06520421802997589,
                -0.007322570309042931,
                0.013573369942605495,
                -0.06063058227300644,
                -0.02027600258588791,
                0.036538638174533844,
                0.019910113885998726,
                -0.05824478343129158,
                -0.023143909871578217,
                -0.0406264029443264,
                -0.06292601674795151,
                0.023376448079943657,
                -0.040448956191539764,
                -0.08493482321500778,
                0.02546185441315174,
                0.01367967203259468,
                0.04720660299062729,
                -0.033812265843153,
                0.018076838925480843,
                0.014530790969729424,
                0.004654807969927788,
                0.01362911518663168,
                0.02302391827106476,
                0.02138681896030903,
                0.01880609802901745,
                -0.02204377017915249,
                0.033688899129629135,
                -0.017309071496129036,
                0.0024255148600786924,
                -0.03162148594856262,
                -0.018314843997359276,
                -0.025327714160084724,
                -0.0021775870118290186,
                0.029514428228139877,
                0.044940389692783356,
                -0.028157051652669907,
                0.022419864311814308,
                0.044208824634552,
                -0.023698223754763603,
                -0.03024880774319172,
                -0.0055715893395245075,
                -0.024079887196421623,
                0.0056035504676401615,
                -0.00048064684960991144,
                0.04735320061445236,
                0.012883585877716541,
                -0.017916714772582054,
                0.04761062189936638,
                0.006572095677256584,
                -0.05954509600996971,
                -0.017133697867393494,
                0.06811517477035522,
                -0.026851709932088852,
                0.014562915079295635,
                0.005883349571377039,
                -0.029558440670371056,
                -0.058409854769706726,
                -0.011316034011542797,
                -0.014694610610604286,
                0.05345883220434189,
                -0.07883938401937485,
                -0.009570504538714886,
                0.006832377053797245,
                0.0548272579908371,
                -0.00584429083392024,
                0.004702754784375429,
                -0.030986621975898743,
                0.02051665261387825,
                0.0368286669254303,
                -0.029611600562930107,
                -0.000701697135809809,
                -0.04475864768028259,
                0.045053720474243164,
                -0.017284510657191277,
                0.001692934543825686,
                0.0401131734251976,
                -0.04688767343759537,
                0.04034018889069557,
                -0.01001549419015646,
                -0.03079410083591938,
                0.06933322548866272,
                -0.02653253823518753,
                0.007323435507714748,
                0.008198115043342113,
                -0.006754787173122168,
                -0.0227024145424366,
                -0.0010206513106822968,
                0.009565304964780807,
                -0.01966155134141445,
                -0.001443781889975071,
                -0.015040664002299309,
                -0.026741275563836098,
                -0.03299352526664734,
                0.009795811958611012,
                -0.10014766454696655,
                -0.01257585920393467,
                -0.0268255565315485,
                -0.0010291525395587087,
                0.04448835179209709,
                -0.023076267912983894,
                0.029208384454250336,
                -0.01835624873638153,
                -0.02737543173134327,
                0.04745042324066162,
                0.013223867863416672,
                -0.024406176060438156,
                0.024460403248667717,
                -0.029047880321741104,
                0.015942875295877457,
                0.05104154348373413,
                -0.04168457165360451,
                -0.03201413154602051,
                -0.038548026233911514,
                -0.014562159776687622,
                0.006246692035347223,
                0.013983429409563541,
                -0.02496793307363987,
                -0.023555047810077667,
                0.008500105701386929,
                -0.03950883820652962,
                -0.01169322244822979,
                -0.004072686191648245,
                0.06487055867910385,
                -0.013363109901547432,
                -0.01919875107705593,
                0.029673738405108452,
                0.0004980151425115764,
                0.06100807338953018,
                0.025179347023367882,
                -0.003848398569971323,
                0.031443823128938675,
                -0.05049509555101395,
                0.07411789894104004,
                0.015900535508990288,
                0.06829550117254257,
                0.043380673974752426,
                0.006062245927751064,
                0.015865672379732132,
                -0.03051949478685856,
                -0.012884988449513912,
                0.04318904131650925,
                0.008984219282865524,
                0.04885406792163849,
                0.006179159507155418,
                0.02241433970630169,
                0.022851109504699707,
                -0.023616895079612732,
                -0.01100480929017067,
                0.00033490557689219713,
                -0.001980222063139081,
                -0.05932183936238289,
                -0.007490870542824268,
                -0.04853082448244095,
                -0.04582955315709114,
                -0.05853335186839104,
                0.04676163196563721,
                -0.02169809676706791,
                0.12088920176029205,
                0.05998200923204422,
                0.05637925863265991,
                0.011248456314206123,
                -0.0012793764472007751,
                -0.016613280400633812,
                -0.022589145228266716,
                -0.027029283344745636,
                0.058880116790533066,
                0.0058176759630441666,
                -0.01715196669101715,
                0.019608261063694954,
                -0.009680149145424366,
                -0.011728467419743538,
                0.03375650569796562,
                -0.04765543341636658,
                0.02729073353111744,
                -0.003911315463483334,
                0.01940719224512577,
                -0.02833503857254982,
                0.04552425071597099,
                -0.03558725863695145,
                0.044880155473947525,
                -0.011369177140295506,
                -0.02930598519742489,
                0.016469284892082214,
                0.053369250148534775,
                -3.556079536792822e-05,
                -0.02527344971895218,
                -0.0022639550734311342,
                0.006943520158529282,
                -0.032100655138492584,
                -0.10039659589529037,
                -0.03664884716272354,
                0.0033867049496620893,
                -0.007024490740150213,
                0.04425909370183945,
                0.022812774404883385,
                -0.04839106276631355,
                -0.00821953359991312,
                0.00020230727386660874,
                0.056040335446596146,
                -0.04066165164113045,
                -0.0732366144657135,
                -0.02240617573261261,
                0.03425706923007965,
                -0.008401059545576572,
                0.0036260061897337437,
                0.015435157343745232,
                0.01943126693367958,
                0.023830387741327286,
                0.0037895389832556248,
                -0.06082948297262192,
                0.042818568646907806,
                0.03592602163553238,
                -0.05411148816347122,
                0.02363792434334755,
                -0.03829287737607956,
                0.015320784412324429,
                0.008634216152131557,
                -0.026483865454792976,
                -0.04672009497880936,
                -0.007045601960271597,
                -0.03715115413069725,
                0.02000475488603115,
                0.017223356291651726,
                0.012642846442759037,
                0.03600767254829407,
                0.01806669682264328,
                -0.05049905180931091,
                -0.013940398581326008,
                -0.01205367874354124,
                -0.05873200297355652,
                -0.060326844453811646,
                0.010573067702353,
                0.03871346637606621,
                0.022886822000145912,
                0.036512576043605804,
                -0.03646517172455788,
                -0.016096705570816994,
                0.06332389265298843,
                0.02469645068049431,
                -0.03404848277568817,
                0.006596366874873638,
                -0.028360871598124504,
                0.013556242920458317,
                0.03332194685935974,
                0.1055711954832077,
                -0.044760920107364655,
                0.031238142400979996,
                0.014171181246638298,
                -0.004420377779752016,
                0.010658208280801773,
                -0.020115002989768982,
                0.03542657569050789,
                -0.025108104571700096,
                0.016480589285492897,
                -0.06653469055891037,
                -0.0070596481673419476,
                -0.023602286353707314,
                -0.07414205372333527,
                0.000858678191434592,
                0.03214120492339134,
                0.00712853716686368,
                0.0026685793418437243,
                -0.005447356030344963,
                -0.007596869021654129,
                -0.052215468138456345,
                0.013127362355589867,
                -0.03691647946834564,
                -0.012775522656738758,
                -0.018342826515436172,
                0.016863755881786346,
                0.05420609563589096,
                0.011440309695899487,
                -0.030473923310637474,
                0.009219592437148094,
                0.05153566598892212,
                0.009073115885257721,
                0.0043995436280965805,
                -0.038033489137887955,
                0.04410421475768089,
                -0.03231808915734291,
                0.04682755470275879,
                0.019544081762433052,
                -0.033130303025245667,
                -0.004178732167929411,
                0.015114941634237766,
                -0.02391621470451355,
                0.021325303241610527,
                -0.026530342176556587,
                -0.05016443878412247,
                -0.0011887935688719153,
                0.015133902430534363,
                0.07639138400554657,
                0.006150298751890659,
                0.0035159988328814507,
                -0.023948241025209427,
                -0.018851744011044502,
                0.05564821511507034,
                -5.1893057388952e-05,
                0.0235042292624712,
                -0.047260578721761703,
                0.00827791541814804,
                -0.03749740123748779,
                -0.017158368602395058,
                0.011279694736003876,
                -0.04227771610021591,
                -0.011795657686889172,
                0.05193231254816055,
                0.017840759828686714,
                0.01022680476307869,
                -0.047292064875364304,
                0.021948905661702156,
                0.0015451691579073668,
                0.0012686406262218952,
                -0.02485821396112442,
                0.017263706773519516,
                0.05137266963720322,
                -0.027404552325606346,
                0.0045216563157737255,
                0.02426166459918022,
                -0.013217901811003685,
                -0.022005539387464523,
                0.036117635667324066,
                0.015279353596270084,
                0.04164918139576912,
                -0.1038929894566536,
                0.0428408645093441,
                0.012405979447066784,
                -0.02741674706339836,
                -0.06679181009531021,
                -0.006150282919406891,
                -0.034245528280735016,
                0.013834343291819096,
                -0.02472633868455887,
                0.013200582005083561,
                0.014881031587719917,
                0.03396327421069145,
                -0.03835783526301384,
                0.03042864240705967,
                0.0037796692922711372,
                0.02535703405737877,
                0.03972017019987106,
                0.031525708734989166,
                0.005121220834553242,
                -0.020016346126794815,
                -0.006022298708558083,
                0.004896314349025488,
                -0.03485509753227234,
                0.06015051528811455,
                -0.12882407009601593,
                0.013080421835184097,
                0.07826285064220428,
                -0.04471467807888985,
                0.0030083043966442347,
                -0.0012268915306776762,
                -0.023887360468506813,
                0.026228465139865875,
                -0.03196980059146881,
                -0.021452078595757484,
                0.03676348924636841,
                0.020388705655932426,
                0.0022448848467320204,
                0.018105465918779373,
                -0.02744997665286064,
                0.026427557691931725,
                -0.11972766369581223,
                0.054856300354003906,
                -0.07091560959815979,
                -0.03227805718779564,
                -0.04924068599939346,
                -0.004258777480572462,
                -0.042406026273965836,
                0.02592087723314762,
                0.03977546468377113,
                -0.030910829082131386,
                0.02956520952284336,
                -0.006927797105163336,
                0.0023209203500300646,
                -0.04767374321818352,
                0.021032867953181267,
                0.010622506961226463,
                -0.024218451231718063,
                0.0541645884513855,
                -0.03039504401385784,
                -0.0724230706691742,
                -0.028474511578679085,
                -0.004850459285080433,
                0.015921754762530327,
                0.023574652150273323,
                -0.045426517724990845,
                0.030878199264407158,
                -0.037692874670028687,
                0.042931053787469864,
                -0.03807946667075157,
                -0.04146295040845871,
                0.04498238116502762,
                -0.02768215909600258,
                0.07352088391780853,
                0.004623675253242254,
                -0.023575887084007263,
                0.07665649801492691,
                -0.029413260519504547,
                0.06270478665828705,
                -0.02184869907796383,
                -0.018059702590107918,
                -0.004451603163033724,
                0.013722574338316917,
                -0.03076496161520481,
                0.00749425170943141,
                0.0010578843066468835,
                -0.02832423709332943,
                0.0088774049654603,
                -0.06876825541257858,
                0.006114056799560785,
                0.023886872455477715,
                0.003758794628083706,
                0.03152685984969139,
                -0.020548898726701736,
                0.010208556428551674,
                0.01809599995613098,
                0.016213277354836464,
                -0.03166399896144867,
                -0.012841048650443554,
                -0.007374911569058895,
                -0.0005361404619179666,
                0.04328528419137001,
                -0.020565306767821312,
                0.037263479083776474,
                0.019875146448612213,
                -0.024200769141316414,
                -0.017994584515690804,
                -0.005758223589509726,
                0.039807867258787155,
                -0.022282378748059273,
                -0.005325307138264179,
                0.03305910900235176,
                0.0029889680445194244,
                0.05069926008582115,
                -0.0151669355109334,
                0.0012224543606862426,
                0.013142864219844341,
                -0.021101854741573334,
                0.02896329201757908,
                0.053065430372953415,
                -0.0814768373966217,
                0.0031950874254107475,
                -0.008654692210257053,
                -0.01876772753894329,
                -0.0211834404617548,
                -0.0023713274858891964,
                -0.020991923287510872,
                -0.009020186960697174,
                -0.04311630129814148,
                -0.014414400793612003,
                -0.01756543666124344,
                0.010165167972445488,
                0.026184827089309692,
                -0.007031919434666634,
                -0.0028429210651665926,
                0.052768219262361526,
                -0.018587492406368256,
                -0.011205201968550682,
                0.03378996625542641,
                0.004979111719876528,
                0.05440136045217514,
                0.13000471889972687,
                -0.001587408478371799,
                -0.029564861208200455,
                -0.038347676396369934,
                -9.031034366567714e-33,
                0.0005426176940090954,
                0.0020679139997810125,
                -0.007567794062197208,
                0.03332137688994408,
                -0.07021629810333252,
                -0.022688448429107666,
                -0.02903899736702442,
                0.036100730299949646,
                -0.01609700173139572,
                -0.020175790414214134,
                -0.023381084203720093,
                -0.009742430411279202,
                0.0353558212518692,
                0.005965929012745619,
                0.022641528397798538,
                -0.038764793425798416,
                0.0031297854147851467,
                -0.013848853297531605,
                0.00965674128383398,
                0.004055351950228214,
                -0.02068583481013775,
                0.013937972486019135,
                0.007280072662979364,
                -0.09367085248231888,
                0.03312778100371361,
                -0.017287127673625946,
                -0.057402659207582474,
                0.031617533415555954,
                0.009667620994150639,
                -0.03867361322045326,
                -0.04998094588518143,
                0.039972998201847076,
                -0.011128595098853111,
                0.03130379691720009,
                0.03752617910504341,
                -0.005527045112103224,
                0.022659048438072205,
                -0.019391223788261414,
                0.0027507152408361435,
                -0.036493659019470215,
                -0.07428519427776337,
                -0.028971804305911064,
                0.012767830863595009,
                0.03043072298169136,
                0.006062168627977371,
                0.08034884929656982,
                -0.011192725971341133,
                -0.0316697433590889,
                0.044212207198143005,
                -0.08468606323003769,
                -0.05835426226258278,
                0.054058317095041275,
                0.00939823966473341,
                -0.013540074229240417,
                -0.010631086304783821,
                0.07680489867925644,
                -0.005911539308726788,
                0.06834428757429123,
                -0.012645232491195202,
                0.01986027881503105,
                -0.08330713957548141,
                0.050659213215112686,
                -0.05154813826084137,
                -0.034118570387363434,
                0.03250953182578087,
                0.019081002101302147,
                0.05275687202811241,
                0.0394476018846035,
                -0.049163248389959335,
                0.08968256413936615,
                -0.011655515991151333,
                0.020176392048597336,
                -0.01644197478890419,
                0.006136851850897074,
                0.021083077415823936,
                -0.07721033692359924,
                0.039004214107990265,
                -0.009842850267887115,
                -0.0716867595911026,
                0.010841998271644115,
                -0.0012937509454786777,
                0.00521552050486207,
                -0.03905269503593445,
                -0.03739800304174423,
                0.045716293156147,
                0.11540904641151428,
                -0.03701930493116379,
                -0.003483022563159466,
                -0.03662871569395065,
                0.026998231187462807,
                -0.031317442655563354,
                0.038197822868824005,
                -0.0029084610287100077,
                0.041735634207725525,
                0.06096932664513588,
                0.03671509772539139,
                -0.006105554755777121,
                0.03656116873025894,
                0.02202666364610195,
                0.028502611443400383,
                0.013177046552300453,
                -0.028550511226058006,
                -0.05033053085207939,
                0.06385400146245956,
                -0.006327483337372541,
                -0.01468905620276928,
                0.08342713117599487,
                0.043481484055519104,
                -0.07988177239894867,
                -0.04008599743247032,
                -0.030864320695400238,
                -0.036039937287569046,
                0.02090693637728691,
                -0.024923287332057953,
                -0.013532687909901142,
                0.036962684243917465,
                0.01414275262504816,
                -0.029531329870224,
                -0.022076424211263657,
                -0.04342202842235565,
                -0.040174707770347595,
                -0.010476506315171719,
                -0.035007089376449585,
                0.01006240863353014,
                -0.027162382379174232,
                0.0006262384122237563,
                0.00707148527726531,
                0.057229094207286835,
                -0.0011903070844709873,
                -0.07092873752117157,
                -0.007060414180159569,
                -0.07242115586996078,
                3.3397955689906667e-07,
                0.007719383109360933,
                0.02326505444943905,
                -0.02293921634554863,
                -0.009169281460344791,
                0.013755016960203648,
                0.06573910266160965,
                -0.0018482232699170709,
                0.038048338145017624,
                -0.037355806678533554,
                0.0012271124869585037,
                0.0018148241797462106,
                -0.016118954867124557,
                0.01929219253361225,
                0.008489691652357578,
                0.012507044710218906,
                0.0015913508832454681,
                0.013821546919643879,
                -0.04753171280026436,
                0.025789370760321617,
                0.0289866104722023,
                0.041322529315948486,
                0.011878556571900845,
                -0.04471158981323242,
                -0.02967767044901848,
                0.03755253925919533,
                0.031165344640612602,
                -0.0028100686613470316,
                -0.00738695589825511,
                -0.041096169501543045,
                -0.018049033358693123,
                -0.005348613020032644,
                0.0013399373274296522,
                0.002918217796832323,
                -0.019825169816613197,
                0.04282514378428459,
                -0.04000866040587425,
                0.0513414591550827,
                -0.05516694858670235,
                0.01498472224920988,
                0.03869437426328659,
                -0.04686684533953667,
                -0.020062314346432686,
                -0.005272120237350464,
                0.03427767753601074,
                0.00913956668227911,
                0.06333187222480774,
                0.03508157283067703,
                -0.02124781720340252,
                0.009163747541606426,
                0.006782290060073137,
                -0.03995692357420921,
                -0.005758495535701513,
                0.01158071868121624,
                0.0169520266354084,
                -0.006526315119117498,
                0.012477087788283825,
                0.018160399049520493,
                -0.05616749823093414,
                -0.0059461225755512714,
                0.0022034102585166693,
                -0.0021703459788113832,
                0.006051850039511919,
                -0.011694279499351978,
                0.14547507464885712,
                0.030893338844180107,
                0.028595397248864174,
                0.008768358267843723,
                2.2710929679308246e-34,
                0.07229670882225037,
                0.029117126017808914,
                0.02542896941304207,
                -0.015226487070322037,
                -0.024092892184853554,
                0.03247066214680672,
                -0.016101200133562088,
                -0.015247032046318054,
                0.011183751747012138,
                -0.044390346854925156,
                -0.02484727092087269,
                0.01917007565498352,
                0.039980851113796234,
                -0.00024471007054671645,
                -0.012912552803754807,
                -0.07609084993600845,
                0.01595584861934185,
                0.05753355100750923,
                0.017857322469353676,
                0.00896444171667099,
                -0.022886667400598526,
                0.07179662585258484,
                -0.003995331935584545,
                -0.023583265021443367,
                0.00784606859087944,
                0.03428291529417038,
                0.013777590356767178,
                -0.01688779704272747,
                -0.0011714831925928593,
                0.11804505437612534,
                -0.020160282030701637,
                0.024496791884303093,
                0.0302774365991354,
                -0.0569840669631958,
                0.030014239251613617,
                -0.03858070448040962,
                0.01260604802519083,
                0.034813329577445984,
                -0.016438914462924004,
                -0.026134658604860306,
                0.02374006249010563,
                0.019137946888804436,
                0.03587687388062477,
                -0.00965381320565939,
                0.012576504610478878,
                1.4793497484788531e-06,
                0.017661405727267265,
                -3.2582884159637615e-05,
                -0.04179806634783745,
                -0.0065354774706065655,
                -0.020030919462442398,
                -0.06013486161828041,
                0.010612416081130505,
                -0.026545405387878418,
                0.03244475647807121,
                -0.022629011422395706,
                -0.00653100898489356,
                0.0244545079767704,
                -0.009008489549160004,
                -0.0162611473351717,
                0.05252924934029579,
                0.006577715743333101,
                0.01852172054350376,
                -0.014222831465303898,
                -0.05266042426228523,
                -0.006011137273162603,
                0.04647873714566231,
                -0.01759953796863556,
                0.005698958411812782,
                0.004551650956273079,
                -0.026097983121871948,
                0.025973135605454445,
                0.006014014594256878,
                0.015035642310976982,
                -0.028222136199474335,
                -0.027689272537827492,
                0.013350626453757286,
                0.0656290203332901,
                0.031947843730449677,
                -0.018401851877570152,
                0.006592421792447567,
                0.017755378037691116,
                0.007470147218555212,
                -0.015693379566073418,
                0.08202763646841049,
                0.02690323069691658,
                0.10220558196306229,
                0.04452728107571602,
                0.09945356100797653,
                -0.030962485820055008,
                -0.025687213987112045,
                0.06354968249797821,
                0.04267652705311775,
                -0.03430306911468506,
                0.02799869328737259,
                -0.040503986179828644,
                -0.019379908218979836,
                -0.01652654819190502,
                0.02381458692252636,
                0.005306856241077185,
                0.024112164974212646,
                0.07493619620800018,
                -0.048398688435554504,
                0.03285422921180725,
                0.030678415670990944,
                -0.02137158438563347,
                -0.013147583231329918,
                0.0018420660635456443,
                -0.015032448805868626,
                -0.026657653972506523,
                -0.010699751786887646,
                0.0032088197767734528,
                0.0020213962998241186,
                -0.004179462790489197,
                0.030944624915719032,
                -0.0004429731925483793,
                -0.033760394901037216,
                0.01733659952878952,
                0.01999041996896267,
                0.01841256022453308,
                0.015346243046224117,
                -0.021504955366253853,
                -0.0005832287133671343,
                -0.00213804142549634,
                0.01866825297474861,
                0.004741733428090811,
                0.01739170402288437,
                0.029961377382278442,
                -0.024053974077105522,
                0.011767758056521416,
                0.045627862215042114,
                -0.0529632642865181,
                0.01918598636984825,
                -0.04404046759009361,
                0.002791022416204214,
                -0.03535940870642662,
                -0.016249872744083405,
                -0.07176359742879868,
                0.0049656834453344345,
                0.03100360743701458,
                -0.010105043649673462,
                -0.009036168456077576,
                0.04846611246466637,
                0.015004848130047321,
                0.006251155864447355,
                -0.0025850895326584578,
                0.037776339799165726,
                -0.04911736026406288,
                -0.026525110006332397,
                -0.01195867732167244,
                -0.04350628703832626,
                -0.01796967349946499,
                -0.03212626650929451,
                -0.01998376101255417,
                -0.019420785829424858,
                -0.032002128660678864,
                -0.02175462432205677,
                -0.012597320601344109,
                -0.07275723665952682,
                0.025550810620188713,
                -0.013231564313173294,
                0.006347029935568571,
                0.00727876229211688,
                -0.030909882858395576,
                -0.012052030302584171,
                -0.027669930830597878,
                0.030285917222499847,
                0.03164834529161453,
                -0.05101172998547554,
                -0.03603740036487579,
                -0.016107192263007164,
                -0.01034277118742466,
                -0.00831566657871008,
                0.0826745480298996,
                0.008120696991682053,
                -0.031242333352565765,
                0.027122166007757187,
                -0.013427146710455418,
                0.0015841813292354345,
                -0.057767171412706375,
                0.020139729604125023,
                -0.008092445321381092,
                0.017733367159962654,
                -0.03730239346623421,
                0.039037227630615234,
                0.015425506979227066,
                0.0393093004822731,
                -0.04229852184653282,
                0.027791151776909828,
                0.005169151816517115,
                -0.02037692442536354,
                -0.0014647862408310175,
                -0.02546357922255993,
                -0.016369566321372986,
                0.02294454723596573,
                -0.008789067156612873,
                -0.03531195968389511,
                -5.228156442171894e-05,
                0.004031084943562746,
                -0.029983554035425186,
                -0.04404842481017113,
                0.04433976113796234,
                0.021483164280653,
                -0.0824662372469902,
                -0.003546513617038727,
                -0.00407106289640069,
                -0.0006744625279679894,
                0.04041501134634018,
                -0.007564799394458532,
                -0.0018558005103841424,
                0.01910129375755787,
                -0.0669054165482521,
                -0.027885975316166878,
                -0.02196134440600872,
                -0.005945318844169378,
                -0.04141072928905487,
                -0.011864935047924519,
                -0.025538213551044464,
                -0.024644605815410614,
                -0.023835768923163414,
                0.041872650384902954,
                0.013036292977631092,
                0.019648142158985138,
                -0.01876997947692871,
                0.034816429018974304,
                -0.037421997636556625,
                0.0023283837363123894,
                0.03218212351202965,
                0.04667290672659874,
                -0.03109431080520153,
                0.06731577962636948,
                0.02241358533501625,
                -0.05734337121248245,
                -0.007567303255200386,
                -0.02796686254441738,
                0.010373841039836407,
                -0.04271288961172104,
                0.02459963597357273,
                -0.03432008624076843,
                0.050734296441078186,
                0.011243495158851147,
                -0.02224167436361313,
                -0.0015924321487545967,
                -0.012010805308818817,
                -0.012495229952037334,
                0.02821674756705761,
                0.062130484730005264,
                0.04037545993924141,
                -0.028274521231651306,
                -0.03114932030439377,
                -0.14928291738033295,
                -0.027408262714743614,
                -0.052971795201301575,
                -0.009656424634158611,
                -0.015491758473217487,
                -0.02721465192735195,
                -0.009084880352020264,
                0.021914010867476463,
                -0.019861312583088875,
                -0.015120862051844597,
                -0.007090498227626085,
                -0.03906562551856041,
                0.028248256072402,
                0.0031477492302656174,
                0.026664556935429573,
                0.012087607756257057,
                -0.050056252628564835,
                0.001026405836455524,
                0.026319729164242744,
                -0.019681360572576523,
                -0.01669599860906601,
                -0.017508020624518394,
                0.005957101006060839,
                0.03360353410243988,
                -0.015771692618727684,
                0.021305231377482414,
                -0.03127413988113403,
                -0.02620856463909149,
                -0.08769964426755905,
                -0.03600944206118584,
                -0.03009732812643051,
                -0.00544129079207778,
                -0.030921481549739838,
                0.008385536260902882,
                -0.009392987005412579,
                0.0031692469492554665,
                -0.062392473220825195,
                -0.07993899285793304,
                -0.0027760437224060297,
                -0.032859306782484055,
                -0.034625258296728134,
                -0.02554498240351677,
                0.016015278175473213,
                0.005674872547388077,
                0.012049240060150623,
                0.010992410592734814,
                0.017870964482426643,
                0.06029033660888672,
                -0.0597260445356369,
                0.00018674565944820642,
                -0.07895539700984955,
                -0.0024885828606784344,
                -0.0010806594509631395,
                0.004949802067130804,
                -0.010794499889016151,
                0.010939622297883034,
                -4.957897181157023e-05,
                -0.04532258212566376,
                -0.011356886476278305,
                0.0020965926814824343,
                0.005969546269625425,
                -0.011394576169550419,
                -0.006655268371105194,
                0.020372135564684868,
                0.007752369623631239,
                0.039115674793720245,
                0.012111425399780273,
                0.04520851746201515,
                0.036560818552970886,
                -0.03986958786845207,
                -0.022064615041017532,
                0.01467604748904705,
                0.013386482372879982,
                0.043188802897930145,
                0.03830165043473244,
                0.039033930748701096,
                4.6291129365272354e-06,
                0.0796344205737114,
                -0.0061252061277627945,
                0.03995617479085922,
                0.04047808423638344,
                0.03622254729270935,
                0.0017787767574191093,
                0.04777253791689873,
                0.00418451614677906,
                0.04381459578871727,
                0.036088451743125916,
                -0.05022353678941727,
                0.012866481207311153,
                0.005830058827996254,
                0.0018949779914692044,
                0.026259128004312515,
                -0.020918719470500946,
                0.009476042352616787,
                0.03967947140336037,
                0.025526167824864388,
                0.009763302281498909,
                -0.02263052389025688,
                -0.09073257446289062,
                -0.041762180626392365,
                0.02824096754193306,
                0.018663322553038597,
                -0.04844385012984276,
                -0.006427502725273371,
                -0.019751571118831635,
                -0.05093790963292122,
                -0.015915023162961006,
                -0.026971587911248207,
                0.039257265627384186,
                -0.11444595456123352,
                0.0006548363016918302,
                -0.01551217120140791,
                0.06175477057695389,
                0.007837978191673756,
                -0.014201746322214603,
                -0.014092906378209591,
                0.026637742295861244,
                -0.06016858294606209,
                -0.009772657416760921,
                0.010650397278368473,
                -0.050915129482746124,
                -0.06595224142074585,
                0.004250415600836277,
                -0.014131713658571243,
                -0.049611181020736694,
                -0.010237970389425755,
                -0.0036683683283627033,
                0.03375823423266411,
                -0.01003183238208294,
                -0.04045029729604721,
                0.001191010233014822,
                -0.049683891236782074,
                -0.0057573686353862286,
                0.005293478723615408,
                0.052350834012031555,
                -0.009001671336591244,
                -0.07375886291265488,
                -0.002671560039743781,
                0.02736622281372547,
                0.006934115197509527,
                -0.025632541626691818,
                -0.012414836324751377,
                -0.11122254282236099,
                0.01821582205593586,
                -0.03587612882256508,
                -0.008910618722438812,
                -0.05514386296272278,
                -0.011532056145370007,
                -0.008042006753385067,
                -0.02528993785381317,
                0.0835910215973854,
                0.03552146628499031,
                0.022778654471039772,
                -0.042551189661026,
                0.051119543612003326,
                0.010955125093460083,
                0.04084838926792145,
                -0.005407346878200769,
                0.010812605731189251,
                0.0054295058362185955,
                0.06354498863220215,
                0.011817342601716518,
                7.535015174653381e-05,
                0.025913244113326073,
                0.04855288565158844,
                -0.02568790502846241,
                0.02766330912709236,
                0.026436081156134605,
                -0.03418133035302162,
                -0.01666148193180561,
                0.02324974723160267,
                -0.034781914204359055,
                -0.006545403506606817,
                -0.011844235472381115,
                0.029078947380185127,
                0.051610834896564484,
                -0.06592956930398941,
                -0.004021862987428904,
                -0.049643535166978836,
                0.04533812776207924,
                0.01779753342270851,
                0.05535157397389412,
                0.03763043135404587,
                0.01338883861899376,
                -0.04719747602939606,
                0.014913966879248619,
                -0.0026784148067235947,
                -0.030689993873238564,
                0.02024983987212181,
                0.018184663727879524,
                0.015636688098311424,
                -0.01872214302420616,
                -0.0406409353017807,
                -0.0039029614999890327,
                0.02528783120214939,
                0.004020586609840393,
                -0.05695859342813492,
                0.004629015456885099,
                0.0476861372590065,
                -0.015308828093111515,
                -0.011252355761826038,
                -0.0042526498436927795,
                0.06447786837816238,
                0.07154854387044907,
                -0.020117944106459618,
                -0.05233996361494064,
                -0.056619856506586075,
                -0.00784875638782978,
                0.0017048611771315336,
                0.06435675173997879,
                0.010065041482448578,
                0.04131326451897621,
                -0.00807285588234663,
                -0.01650889590382576,
                0.014046390540897846,
                0.051806140691041946,
                -0.0019892845302820206,
                -0.06015114113688469,
                0.12520962953567505,
                0.0113117890432477,
                -0.035208526998758316,
                0.028531035408377647,
                0.03427691385149956,
                0.015360217541456223,
                -0.024313190951943398,
                -0.02288678102195263,
                -0.0066442969255149364,
                -0.024766067042946815,
                -0.07745004445314407,
                -0.019251134246587753,
                -0.005191076546907425,
                -0.061347596347332,
                -0.03011091984808445,
                -0.009911640547215939,
                0.010401605628430843,
                0.036096327006816864,
                0.01079076062887907,
                -0.05620798096060753,
                -0.025774754583835602,
                -0.034834228456020355,
                0.048474498093128204,
                0.05341701954603195,
                0.0583958774805069,
                0.015756091102957726,
                0.018484214320778847,
                -0.017219465225934982,
                0.019784502685070038,
                0.037688247859478,
                -0.047124866396188736,
                -0.009123606607317924,
                -0.00727011077105999,
                -0.012718763202428818,
                -0.0021109385415911674,
                0.017054257914423943,
                0.02464417926967144,
                0.009649094194173813,
                -0.004013278055936098,
                -0.04402359575033188,
                0.006245019380003214,
                -0.02526535466313362,
                -0.08146718144416809,
                -0.015093035064637661,
                -0.006400056648999453,
                -0.014525104314088821,
                0.0037429535295814276,
                0.005758140701800585,
                0.0664733499288559,
                -0.01948121190071106,
                0.03435038775205612,
                -0.0009785782312974334,
                -0.018284140154719353,
                0.018484460189938545,
                0.00843665562570095,
                -0.03349243104457855,
                -0.06169895827770233,
                -0.0066485474817454815,
                0.07243320345878601,
                0.0024110714439302683,
                -0.0007953145541250706,
                -0.04947848245501518,
                -0.03535810858011246,
                0.036843568086624146,
                0.01027101930230856,
                0.013986412435770035,
                -0.002654578536748886,
                0.03686567768454552,
                0.010679030790925026,
                0.019895192235708237,
                0.009754606522619724,
                0.0023686494678258896,
                0.017716145142912865,
                0.02800792269408703,
                0.04032573476433754,
                -0.03581465408205986,
                0.001925186486914754,
                -0.026771515607833862,
                0.008938769809901714,
                -0.04536852240562439,
                -0.011908991262316704,
                0.03741208463907242,
                -0.027111917734146118,
                -0.016626890748739243,
                0.033211689442396164,
                -0.0184096060693264,
                0.03652438521385193,
                -0.018692974001169205,
                0.04269906505942345,
                -0.053015656769275665,
                0.055288489907979965,
                0.028438750654459,
                0.02074315957725048,
                -0.0033624202478677034,
                0.039948172867298126,
                0.08513669669628143,
                0.01901542954146862,
                0.023584775626659393,
                -6.504219628030957e-33,
                -0.014693014323711395,
                -0.038037195801734924,
                0.025288555771112442,
                -0.01684129610657692,
                0.04158514738082886,
                -0.03436323255300522,
                0.01223333552479744,
                0.04595623165369034,
                0.016123395413160324,
                0.0015394361689686775,
                -0.008051584474742413,
                0.050938792526721954,
                0.036475811153650284,
                0.001533807022497058,
                0.018707359209656715,
                0.04410386085510254,
                0.006847045384347439,
                0.03014270029962063,
                0.010106484405696392,
                -0.0024114008992910385,
                -0.07784788310527802,
                -0.05104810371994972,
                0.02345290035009384,
                -0.09941457211971283,
                0.023464256897568703,
                0.0542202889919281,
                -0.004064583219587803,
                -0.01779869571328163,
                -0.10005932301282883,
                -0.021892430260777473,
                0.031140312552452087,
                0.00019893478020094335,
                -0.005564737133681774,
                0.013126618228852749,
                0.0149758942425251,
                -0.027261817827820778,
                -0.01603885367512703,
                0.0029553209897130728,
                0.0011975206434726715,
                -0.011704281903803349,
                -0.09850803762674332,
                -0.05376667529344559,
                0.03787989914417267,
                -0.005237862467765808,
                -0.0019435406429693103,
                -0.02639675885438919,
                -0.0070672123692929745,
                0.0012093463446944952,
                0.03555014729499817,
                -0.03738796338438988,
                -0.02770569548010826,
                0.056460559368133545,
                -0.0659821406006813,
                0.04404006153345108,
                0.01874292455613613,
                0.013109260238707066,
                -0.0167198795825243,
                -0.07944191247224808,
                0.034539446234703064,
                -0.018331753090023994,
                0.01715933345258236,
                -0.02645708993077278,
                -0.016367385163903236,
                -0.06136627867817879,
                -0.064796581864357,
                0.01532225962728262,
                0.06693854182958603,
                0.006142504513263702,
                -0.016304004937410355,
                -0.04762782156467438,
                -0.02133728563785553,
                0.09752324223518372,
                0.06286779046058655,
                0.025503745302557945,
                -0.05275651812553406,
                -0.0017187726916745305,
                -0.03509577363729477,
                0.009822530671954155,
                0.12381979078054428,
                0.058650072664022446,
                0.012538529932498932,
                -0.014732560142874718,
                -0.002144366269931197,
                0.004534550476819277,
                -0.054630495607852936,
                -0.106949083507061,
                -0.021882330998778343,
                0.0523802787065506,
                -0.005885346792638302,
                0.0043944562785327435,
                0.03501853719353676,
                0.002558918436989188,
                -0.002680318895727396,
                0.025931408628821373,
                0.12530609965324402,
                -0.06088504567742348,
                0.014470640569925308,
                0.03482161834836006,
                -0.039991170167922974,
                -0.00385510572232306,
                -0.03202957659959793,
                0.00825272873044014,
                0.004105167463421822,
                -0.028001824393868446,
                0.01075581181794405,
                -0.01572301983833313,
                0.01006558071821928,
                -0.02325032278895378,
                -0.04849575459957123,
                -0.015911757946014404,
                0.018599743023514748,
                -0.005174481775611639,
                0.04132286459207535,
                0.01586829498410225,
                0.0344165675342083,
                0.007228030823171139,
                0.014690404757857323,
                -0.023664742708206177,
                -0.01675967127084732,
                -0.0659695565700531,
                -0.02913922443985939,
                -0.007982098497450352,
                -0.021452344954013824,
                -0.023085391148924828,
                -0.022798940539360046,
                0.016853459179401398,
                0.06351698935031891,
                -0.032861463725566864,
                -0.06586340814828873,
                0.0331508070230484,
                0.034853823482990265,
                0.003609420033171773,
                2.1653735871041135e-07,
                0.01877477578818798,
                0.06426983326673508,
                -0.009624608792364597,
                -0.03929493948817253,
                0.007923740893602371,
                0.0020894790068268776,
                0.017761996015906334,
                0.036895398050546646,
                0.030461369082331657,
                -0.08859007805585861,
                0.026958957314491272,
                -0.01759282499551773,
                0.02968679368495941,
                0.019573001191020012,
                0.02815955877304077,
                -0.04543927311897278,
                -0.03400159627199173,
                0.010482612065970898,
                -0.009580185636878014,
                0.010493544861674309,
                0.005505934823304415,
                0.044229526072740555,
                -0.0005905988509766757,
                -0.008751573972404003,
                -0.00932979118078947,
                -0.08927220851182938,
                0.0034371239598840475,
                0.0022747651673853397,
                0.030644530430436134,
                -0.018338950350880623,
                0.1003929153084755,
                0.0072922902181744576,
                0.007746411953121424,
                0.12372291833162308,
                0.00941479206085205,
                0.006488686893135309,
                -0.028827209025621414,
                0.10133353620767593,
                -0.0431688018143177,
                0.0707893893122673,
                -0.012157617136836052,
                -0.01839754916727543,
                0.02027859166264534,
                0.05751536414027214,
                0.02186458185315132,
                0.04984641075134277,
                -0.003865419188514352,
                0.06925786286592484,
                -0.08666497468948364,
                -0.024534625932574272,
                -0.018474843353033066,
                0.07485659420490265,
                -0.040652353316545486,
                0.026592694222927094,
                0.01850012317299843,
                0.002522153314203024,
                -0.0014843024546280503,
                -0.011355833150446415,
                0.018945563584566116,
                0.01978655345737934,
                -0.04277382418513298,
                -0.004718958865851164,
                0.020976286381483078,
                0.006486071739345789,
                0.03901524469256401,
                -0.05416085571050644,
                0.0025075783487409353,
                1.3232822301357176e-34,
                0.0016850406536832452,
                0.018667899072170258,
                -0.006184015888720751,
                0.022680144757032394,
                0.04263899475336075,
                0.01639622636139393,
                -0.0435054637491703,
                -0.02623032033443451,
                0.033967915922403336,
                -0.038369838148355484,
                -0.010548771359026432,
                -0.020522749051451683,
                -0.08300291746854782,
                -0.014390185475349426,
                -0.007220491301268339,
                0.0012624337105080485,
                -0.01064298115670681,
                0.04807252809405327,
                -0.005332937464118004,
                -0.040928326547145844,
                0.06398428976535797,
                0.012481553480029106,
                0.013529801741242409,
                0.04138026759028435,
                0.009469810873270035,
                -0.013182993046939373,
                0.0013851186959072948,
                0.008253938518464565,
                -0.027278248220682144,
                -0.09998324513435364,
                0.007266274653375149,
                0.028311574831604958,
                -0.02564246580004692,
                0.006941534578800201,
                -0.015466064214706421,
                -0.018278881907463074,
                -0.007804470136761665,
                -0.0022389348596334457,
                0.01583830453455448,
                -0.04433661699295044,
                -0.06585299223661423,
                0.04863094165921211,
                0.011624172329902649,
                0.045741550624370575,
                -0.011809696443378925,
                1.512010499027383e-06,
                -0.03495534509420395,
                -0.03667517378926277,
                0.008439413271844387,
                -0.012001105584204197,
                0.03179532289505005,
                -0.004557546228170395,
                -0.01840784028172493,
                0.00019683042773976922,
                0.01453553605824709,
                -0.0029180487617850304,
                0.016493773087859154,
                -0.033124666661024094,
                -0.02260335348546505,
                0.0687447115778923,
                0.05986422672867775,
                -0.011066103354096413,
                0.017026793211698532,
                -0.03997953236103058,
                -0.03357553109526634,
                -0.10628428310155869,
                -0.03681367263197899,
                0.04048066586256027,
                0.09039024263620377,
                0.08019383251667023,
                0.007626613136380911,
                -0.023673977702856064,
                0.006879289168864489,
                -0.0070624155923724174,
                0.062465600669384,
                0.02777133882045746,
                -0.011692563071846962,
                0.09499213099479675,
                -0.04156169295310974,
                0.010518543422222137,
                0.013702372089028358,
                0.13252341747283936,
                0.026068301871418953,
                0.04559146612882614,
                0.0438760370016098,
                -0.00730432802811265,
                -0.02863546647131443,
                -0.009924795478582382,
                -0.005169322248548269,
                -0.01599530689418316,
                0.012467872351408005,
                -0.025640875101089478,
                0.029165854677557945,
                0.005636922083795071,
                -0.034510720521211624,
                -0.007605898194015026,
                0.04223572835326195,
                -0.014066425152122974,
                -0.07701389491558075,
                -0.026698535308241844,
                0.004111465997993946,
                0.006793138105422258,
                0.012402046471834183,
                0.03904099762439728,
                0.02937985584139824,
                0.007136342581361532,
                -0.007766622118651867,
                -0.03299469128251076,
                0.015785902738571167,
                0.08998830616474152,
                -0.003888437757268548,
                0.03737565875053406,
                0.0028338099364191294,
                -0.02045072242617607,
                0.024908749386668205,
                0.019918037578463554,
                0.030203860253095627,
                -0.041608601808547974,
                0.07063352316617966,
                -0.0031725114677101374,
                0.001444352208636701,
                0.04267139732837677,
                0.016135752201080322,
                0.03303203359246254,
                0.010109616443514824,
                -0.0027385104913264513,
                0.014401936903595924,
                -0.07602427899837494,
                0.06589755415916443,
                0.0173539686948061,
                0.006194795481860638,
                0.1116969883441925,
                -0.010003176517784595,
                -0.01553378812968731,
                0.0028853456024080515,
                0.02365674264729023,
                -0.09695550799369812,
                -0.061200954020023346,
                0.009279805235564709,
                0.04955655708909035,
                0.03199772909283638,
                -0.024246400222182274,
                -0.017923612147569656,
                0.015987245365977287,
                0.011254229582846165,
                0.03780506178736687,
                0.08516325801610947,
                -0.0022523084189742804,
                -0.006977190263569355,
                0.012851466424763203,
                -0.027497418224811554,
                -0.029052816331386566,
                -0.004833974875509739,
                0.024023309350013733,
                0.00751923955976963,
                -0.016670789569616318,
                -0.0461447648704052,
                0.03685474023222923,
                0.006552746053785086,
                -0.023166559636592865,
                0.026155555620789528,
                -0.015376538969576359,
                -0.047154419124126434,
                0.01551970187574625,
                -0.0026689518708735704,
                0.03199877589941025,
                0.041318293660879135,
                -0.01489240676164627,
                -0.04779059812426567,
                -0.01676642708480358,
                -0.06507319957017899,
                0.033315159380435944,
                0.02473955973982811,
                0.03984009474515915,
                0.0015784450806677341,
                -0.008212286978960037,
                0.06075933203101158,
                0.02814496122300625,
                -0.005370978266000748,
                -0.03188762068748474,
                0.0013382832985371351,
                0.004025917965918779,
                0.021889349445700645,
                -0.04645621031522751,
                -0.028814569115638733,
                -0.03716123476624489,
                -0.0588214136660099,
                0.043828144669532776,
                -0.10437672585248947,
                -0.020459629595279694,
                0.023593377321958542,
                -0.010502304881811142,
                -0.022156430408358574,
                0.025891413912177086,
                -0.06440477818250656,
                -0.013304660096764565,
                -0.007884850725531578,
                0.003982430789619684,
                -0.015170675702393055,
                -0.008735301904380322,
                -0.0052231959998607635,
                0.02127118781208992,
                0.03601361811161041,
                -0.030179880559444427,
                -0.015463483519852161,
                -0.018392715603113174,
                0.035890597850084305,
                0.03504226729273796,
                -0.015866780653595924,
                -0.010537509806454182,
                0.04423838108778,
                -0.045197200030088425,
                0.012542963959276676,
                0.003968085628002882,
                -0.030235765501856804,
                0.00944173987954855,
                -0.021660327911376953,
                -0.0142331812530756,
                0.025053508579730988,
                0.05971650406718254,
                0.08461631834506989,
                0.01721080020070076,
                0.031396180391311646,
                0.010397913865745068,
                0.006436270195990801,
                0.011141869239509106,
                0.001573741901665926,
                0.022497231140732765,
                -0.036099471151828766,
                0.008352702483534813,
                -0.015287261456251144,
                -0.04075107350945473,
                0.06389452517032623,
                -0.01195803377777338,
                0.03809719532728195,
                0.10324624925851822,
                -0.031390462070703506,
                0.0021804231218993664,
                0.010791601613163948,
                -0.005043596960604191,
                -0.02083595097064972,
                0.03710372745990753,
                -0.012528196908533573,
                0.01200195774435997,
                0.01909964345395565,
                -0.0058666192926466465,
                -0.0061392150819301605,
                -0.02152591571211815,
                0.04002082347869873,
                0.03564160317182541,
                -0.004920927807688713,
                -0.0015866803005337715,
                -0.09086228907108307,
                -0.014894514344632626,
                -0.014078104868531227,
                -0.029242224991321564,
                0.02383851259946823,
                -0.01314201857894659,
                -0.04878015071153641,
                0.03374839574098587,
                0.04183909296989441,
                0.0020045237615704536,
                0.0050341179594397545,
                0.01617979258298874,
                -0.02077886089682579,
                0.004144947975873947,
                0.029550254344940186,
                0.021298740059137344,
                0.06140291318297386,
                0.04252627491950989,
                0.052043572068214417,
                -0.04043412581086159,
                -0.08652389049530029,
                0.01924368366599083,
                -0.051187023520469666,
                0.02510564588010311,
                -0.011492254212498665,
                0.028890158981084824,
                0.11246375739574432,
                0.054078999906778336,
                0.03295557573437691,
                0.018812375143170357,
                -0.02686155214905739,
                0.00753017095848918,
                -0.02657836675643921,
                0.020606977865099907,
                -0.017084989696741104,
                -0.024848386645317078,
                0.03854003921151161,
                -0.046296779066324234,
                0.028828591108322144,
                0.03482487052679062,
                -0.039021436125040054,
                0.001892101252451539,
                0.018475521355867386,
                0.03343389183282852,
                0.02150725945830345,
                -0.05830290541052818,
                0.018847499042749405,
                -0.06374838948249817,
                0.09816852957010269,
                0.007328586187213659,
                0.015961891040205956,
                -0.009908247739076614,
                -0.0005417423672042787,
                0.006310519762337208,
                -0.013913963921368122,
                -0.0029336062725633383,
                -0.008485035039484501,
                -0.02063916251063347,
                0.006882365792989731,
                -0.013794984668493271,
                -0.0009786342270672321,
                -0.042498279362916946,
                -0.014396131969988346,
                0.010930278338491917,
                -0.013035116717219353,
                -0.0035545111168175936,
                0.09063992649316788,
                0.015660258010029793,
                0.05312052369117737,
                -0.01826660893857479,
                0.026497164741158485,
                0.01868065819144249,
                0.019365472719073296,
                -0.004800889641046524,
                -0.0007038179901428521,
                0.0012551270192489028,
                -0.01535727921873331,
                -0.056403934955596924,
                0.08457618206739426,
                0.03050941601395607,
                -0.0318928025662899,
                0.003584824735298753,
                -0.070810467004776,
                -0.015738595277071,
                -0.022348016500473022,
                -0.022228021174669266,
                -0.036890435963869095,
                -0.02498645894229412,
                -0.06743603199720383,
                0.0016328656347468495,
                0.06347880512475967,
                -0.009670454077422619,
                -0.028428692370653152,
                0.04144420474767685,
                0.02799595706164837,
                -0.01277676410973072,
                -0.06344692409038544,
                -0.015997864305973053,
                -0.012572744861245155,
                -0.02226770482957363,
                -0.030931977555155754,
                -0.020325789228081703,
                -0.010938488878309727,
                0.07457653433084488,
                0.02450425922870636,
                -0.0067607867531478405,
                0.04427598789334297,
                -0.042570341378450394,
                0.012210050597786903,
                -0.023542655631899834,
                -0.015322373248636723,
                0.006946901325136423,
                -0.0034181152004748583,
                -0.02337935008108616,
                0.015235348604619503,
                -0.03765960410237312,
                0.041074238717556,
                -0.0676572322845459,
                -0.043273452669382095,
                -0.03604757785797119,
                0.02146144211292267,
                -0.0711103081703186,
                -0.03197875991463661,
                0.037363629788160324,
                0.038197197020053864,
                0.0030320766381919384,
                0.052748244255781174,
                0.01062860619276762,
                0.029690565541386604,
                -0.041012704372406006,
                -0.01747123897075653,
                -0.007167351897805929,
                -0.022775286808609962,
                -0.053418710827827454,
                -0.0591469407081604,
                0.0005273298593237996,
                0.01430721115320921,
                -0.02433980442583561,
                -0.02072777785360813,
                -0.0081255454570055,
                -0.07566948235034943,
                0.05708440765738487,
                0.04011550173163414,
                0.03619648888707161,
                -0.0397920124232769,
                0.016425015404820442,
                -0.06253720074892044,
                0.035711321979761124,
                -0.022183777764439583,
                -0.002264752285555005,
                0.024679500609636307,
                0.01775060035288334,
                0.06746292114257812,
                0.01052939984947443,
                0.020033663138747215,
                0.0014262543991208076,
                -0.014591977000236511,
                0.02124691940844059,
                -0.030693264678120613,
                0.04158305004239082,
                -0.032197751104831696,
                -0.09718422591686249,
                0.007676189299672842,
                -0.040314942598342896,
                0.02295800670981407,
                0.06422208994626999,
                -0.05188781023025513,
                0.05336654186248779,
                -0.025718698278069496,
                0.029943140223622322,
                -0.07332423329353333,
                0.005358356982469559,
                -0.023139510303735733,
                -0.06809718906879425,
                -0.045166585594415665,
                -0.036101147532463074,
                0.017374010756611824,
                0.0057495106011629105,
                -0.011051363311707973,
                0.006853195372968912,
                0.015232563950121403,
                0.018764739856123924,
                -0.057596299797296524,
                -0.025984466075897217,
                0.014283008873462677,
                0.04227204620838165,
                -0.04920070990920067,
                0.041085418313741684,
                0.014253058470785618,
                0.008483008481562138,
                0.028500864282250404,
                -0.04978537559509277,
                -0.012100563384592533,
                -0.02617277204990387,
                0.04257730394601822,
                -0.04710526391863823,
                -0.015000447630882263,
                0.0014923475682735443,
                -0.033841654658317566,
                0.03186653554439545,
                0.0064412378706038,
                0.08596048504114151,
                0.03397063538432121,
                -0.008990006521344185,
                -0.021739019080996513,
                0.007480967324227095,
                -0.004125900100916624,
                -0.010848007164895535,
                -0.06443453580141068,
                -0.010257722809910774,
                -0.01206764206290245,
                0.005851098336279392,
                0.0034400545991957188,
                0.04527962580323219,
                -0.010016878135502338,
                -0.0580599382519722,
                0.004323218949139118,
                -0.037969935685396194,
                0.007003105711191893,
                0.03759291023015976,
                0.04741988331079483,
                0.03531298413872719,
                -0.031260520219802856,
                0.025845881551504135,
                -0.09340570867061615,
                -0.006031367927789688,
                -0.016318539157509804,
                -0.0026718962471932173,
                0.017193498089909554,
                -0.03562662750482559,
                -0.023846426978707314,
                -0.0487271212041378,
                0.004373034927994013,
                0.03733432665467262,
                0.03126082941889763,
                0.00985382404178381,
                -0.07192352414131165,
                0.013164272531867027,
                0.02075289376080036,
                -0.03729306533932686,
                -0.02105034328997135,
                0.015010458417236805,
                0.03071187064051628,
                -0.013484048657119274,
                0.019111771136522293,
                0.01711258664727211,
                0.0033916980028152466,
                0.0023449896834790707,
                -0.02606915682554245,
                -0.02612096630036831,
                -0.07177619636058807,
                -0.031684841960668564,
                0.0022706997115164995,
                -0.006500553339719772,
                -0.01928284391760826,
                0.0036573053803294897,
                0.04261414706707001,
                0.04455294460058212,
                -0.011783107183873653,
                -0.02553297020494938,
                0.014016455039381981,
                0.03694884851574898,
                0.017494218423962593,
                -0.015147759579122066,
                -0.008217649534344673,
                0.01852472312748432,
                0.002115366980433464,
                0.003613569075241685,
                0.07700607180595398,
                -0.000965425162576139,
                -0.019568514078855515,
                0.04703272506594658,
                0.020254293456673622,
                0.03889410197734833,
                0.02073044143617153,
                -0.02147897332906723,
                0.08863566070795059,
                -0.01015110220760107,
                -0.025841275230050087,
                0.014402462169528008,
                -0.011713012121617794,
                0.025871992111206055,
                -0.008199753239750862,
                0.006705889478325844,
                -0.020432664081454277,
                0.03391549363732338,
                -0.009815499186515808,
                -0.016153866425156593,
                0.06707121431827545,
                0.018238995224237442,
                -0.046549443155527115,
                -0.09332616627216339,
                0.00033977770362980664,
                -0.012981096282601357,
                -0.020757686346769333,
                -0.003962310031056404,
                0.010398616082966328,
                0.031017452478408813,
                -0.0463160015642643,
                -0.029084045439958572,
                0.002379379002377391,
                -0.011639951728284359,
                -0.0046300990507006645,
                0.06331321597099304,
                -0.025082184001803398,
                -0.030169017612934113,
                -0.0365324392914772,
                0.03423847258090973,
                -0.05388821288943291,
                0.04152197763323784,
                -0.0464022271335125,
                -0.015475853346288204,
                0.006177987437695265,
                0.0029626386240124702,
                -6.726281531160826e-33,
                -0.033694639801979065,
                0.0064465561881661415,
                -0.002131915418431163,
                0.013102522119879723,
                -0.05384688079357147,
                0.007478650659322739,
                -0.03802124410867691,
                -0.014276580885052681,
                0.0559491366147995,
                -0.031463731080293655,
                0.0073734670877456665,
                0.029128873720765114,
                0.027822036296129227,
                -0.002592330565676093,
                -0.005237566772848368,
                -0.04738278314471245,
                -0.009796544909477234,
                -0.019887592643499374,
                -0.029781213030219078,
                -0.005515397991985083,
                -0.05149802193045616,
                0.06600408256053925,
                -0.056725695729255676,
                0.02650686539709568,
                0.03411917760968208,
                0.026551460847258568,
                -0.02690289169549942,
                0.011475672945380211,
                0.004751477390527725,
                0.05559300258755684,
                -0.01850651390850544,
                -0.028947819024324417,
                0.011850949376821518,
                -0.012616127729415894,
                -0.006097869016230106,
                0.06850656121969223,
                -0.034666404128074646,
                0.029041709378361702,
                -0.00904885958880186,
                0.008798138238489628,
                0.008568203076720238,
                -0.07145823538303375,
                0.05480847880244255,
                0.017322557047009468,
                -0.027184754610061646,
                0.009057976305484772,
                -0.04802735149860382,
                -0.03697125241160393,
                -0.021365636959671974,
                -0.05057799443602562,
                0.0048120031133294106,
                -0.008380671963095665,
                0.03234323859214783,
                0.03101605921983719,
                0.006776659749448299,
                -0.053299158811569214,
                0.025093240663409233,
                0.06333284825086594,
                0.0415470190346241,
                -0.015663469210267067,
                -0.012824446894228458,
                0.010213897563517094,
                0.003201795509085059,
                -0.024184003472328186,
                0.01756376028060913,
                0.0353817380964756,
                -0.10868514329195023,
                0.03581782057881355,
                0.005461792461574078,
                0.006454817950725555,
                0.007453104946762323,
                0.08120524138212204,
                -0.056913670152425766,
                -0.005195959471166134,
                -0.03730779141187668,
                -0.008178130723536015,
                0.005113554652780294,
                0.0723186582326889,
                -0.05976948142051697,
                -0.03372121602296829,
                0.036624256521463394,
                -0.02737267315387726,
                -0.01370279025286436,
                -0.03037978522479534,
                0.04495963454246521,
                0.07389401644468307,
                0.00022453944256994873,
                0.03384455293416977,
                0.0028423930052667856,
                0.024434329941868782,
                -0.03930017352104187,
                -0.016720907762646675,
                -0.02440544217824936,
                -0.0014675200218334794,
                0.03499739617109299,
                -0.03126974776387215,
                0.052604567259550095,
                -0.002919744700193405,
                0.007278297562152147,
                -0.05648861825466156,
                -0.003890670370310545,
                -0.020196905359625816,
                0.009720575995743275,
                -0.042286332696676254,
                0.026007073000073433,
                0.009002340957522392,
                -0.003859020536765456,
                -0.04015574976801872,
                -0.0017339711776003242,
                -0.0003673464816529304,
                0.026806356385350227,
                -0.020581986755132675,
                0.0029398866463452578,
                0.07528325170278549,
                -0.026014626026153564,
                -0.007924285717308521,
                0.016786323860287666,
                -0.08664791285991669,
                -0.02623753622174263,
                -0.02139730006456375,
                -0.001977636246010661,
                0.02542988769710064,
                0.010879366658627987,
                0.03116261214017868,
                0.01523567270487547,
                -0.002299730898812413,
                0.009251336567103863,
                -0.0010214196518063545,
                0.021263113245368004,
                -0.013217023573815823,
                -0.009402775205671787,
                -0.023257095366716385,
                2.1775727532258315e-07,
                0.03455888479948044,
                0.01617899350821972,
                0.010584239847958088,
                0.0403989814221859,
                0.029172396287322044,
                -0.03434747830033302,
                -0.09806886315345764,
                -0.010498397052288055,
                0.02088097482919693,
                -0.0177539624273777,
                0.019965259358286858,
                -0.00801820307970047,
                0.035170163959264755,
                -0.07209532707929611,
                -0.04292261227965355,
                -0.045884571969509125,
                0.04947897419333458,
                0.030207140371203423,
                0.005375016015022993,
                -0.016994420439004898,
                -0.02160952053964138,
                0.00026311250985600054,
                -0.038184668868780136,
                -0.036902327090501785,
                0.013807429932057858,
                0.03440890461206436,
                0.012310672551393509,
                -0.007393627427518368,
                0.04492597654461861,
                -0.02639508619904518,
                0.07811331748962402,
                0.013114889152348042,
                0.022696271538734436,
                0.01765063963830471,
                0.026908960193395615,
                0.011766679584980011,
                -0.039222609251737595,
                0.06847356259822845,
                0.051455236971378326,
                -0.03308679908514023,
                -0.0002104198792949319,
                -0.06112833693623543,
                -0.011928163468837738,
                0.05748995020985603,
                -0.0033924730960279703,
                0.03332974389195442,
                0.013159145601093769,
                -0.009854506701231003,
                -0.020695650950074196,
                -0.06889931112527847,
                0.04009237885475159,
                0.028390975669026375,
                -0.01953204534947872,
                -0.07054746150970459,
                0.024404633790254593,
                -0.026714220643043518,
                0.024476541206240654,
                0.02223353274166584,
                0.0167399849742651,
                0.04776347801089287,
                -0.020145567134022713,
                -0.01873619109392166,
                0.009609800763428211,
                0.1057824045419693,
                -0.003083307296037674,
                -0.011939780786633492,
                -0.013711118139326572,
                2.1590763919033745e-34,
                -0.00042009615572169423,
                -0.04900914058089256,
                -0.03830203041434288,
                -0.011759980581700802,
                -0.04799744859337807,
                -0.005681032780557871,
                0.06849469244480133,
                -0.0264132060110569,
                -0.004677400924265385,
                -0.10437051951885223,
                0.012508118525147438
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\x832D\\xbdh\\x94\\x89\\xac\\xc3\\x80!\\xd2\\xaf\\x92\\x88a'",
        "id__": "0a8ed1dd-5c8f-4132-8cea-42d2949ab209"
    },
    {
        "Certificates": {
            "name": [
                "Dangerous Goods Safety Advisor",
                "Tools for data science",
                "CS1301xI: Computing in Python",
                "I: Fundamentals and Procedural",
                "Programming",
                "Hands On Essentials - Data",
                "Applications",
                "Data Sharing"
            ],
            "rep": [
                -0.0016010451363399625,
                0.01772366650402546,
                -0.011438813991844654,
                -0.00644967844709754,
                0.035296306014060974,
                0.010369762778282166,
                0.05854962021112442,
                -0.01125572994351387,
                0.049264609813690186,
                -0.0021301363594830036,
                0.017344441264867783,
                0.020046817138791084,
                -0.0026423544622957706,
                0.058385953307151794,
                0.029470035806298256,
                0.019039051607251167,
                0.0555369108915329,
                -0.005475714802742004,
                -0.034720949828624725,
                -0.0010839900933206081,
                0.038132444024086,
                0.008801024407148361,
                -0.04081520810723305,
                0.06191549450159073,
                -0.04026629775762558,
                -0.048077717423439026,
                0.02832629345357418,
                0.021113095805048943,
                0.044241491705179214,
                -0.07186534255743027,
                0.07189960777759552,
                -0.005809127353131771,
                -0.011067618615925312,
                -0.015473361127078533,
                1.493985791967134e-06,
                -0.003098855260759592,
                0.00040493032429367304,
                -0.000898392463568598,
                -0.03272918239235878,
                -0.020026180893182755,
                0.01642031967639923,
                -0.025450265035033226,
                -0.00021306717826519161,
                0.056307900696992874,
                0.007126017939299345,
                -0.040532488375902176,
                0.032641034573316574,
                0.05832625553011894,
                0.053238678723573685,
                0.007539091631770134,
                -0.022485997527837753,
                0.04924411326646805,
                0.015953226014971733,
                0.00011794450256275013,
                0.016319705173373222,
                -0.03684127703309059,
                0.0036096132826060057,
                0.025558605790138245,
                0.061477985233068466,
                0.03205399215221405,
                -0.013794876635074615,
                -0.00882669072598219,
                -0.03136097267270088,
                0.007640611845999956,
                0.04443783685564995,
                -0.0007499494822695851,
                0.01603095792233944,
                -0.02334248088300228,
                -0.03472199663519859,
                0.05539510399103165,
                0.026576191186904907,
                0.027803976088762283,
                0.0032770053949207067,
                0.056621670722961426,
                0.00641655083745718,
                -0.011191293597221375,
                -0.007652678526937962,
                -0.020453695207834244,
                -0.018084784969687462,
                -0.02508014254271984,
                -0.008418677374720573,
                0.060769617557525635,
                -0.03268153965473175,
                0.01210031658411026,
                0.0848771259188652,
                0.0414731539785862,
                -0.015835974365472794,
                0.0030826032161712646,
                0.04596082493662834,
                -0.0216709952801466,
                -0.02107933722436428,
                -0.0008173825335688889,
                -0.013726034201681614,
                -0.006691582966595888,
                -0.06637176871299744,
                -0.01628190465271473,
                0.01955222897231579,
                -0.02755020000040531,
                0.06260451674461365,
                -0.07392851263284683,
                -0.005537176970392466,
                0.012514479458332062,
                -0.0009495627600699663,
                0.016253316774964333,
                0.023460933938622475,
                0.024873927235603333,
                0.009019766002893448,
                0.08365112543106079,
                -0.026392782106995583,
                0.0845741555094719,
                0.006627536378800869,
                0.010020775720477104,
                -0.03511959686875343,
                -0.014973524957895279,
                0.049423012882471085,
                0.006994486320763826,
                -0.025852825492620468,
                -0.017663823440670967,
                -0.078182652592659,
                0.01372747402638197,
                0.06433413922786713,
                0.05217992514371872,
                -0.04959689825773239,
                0.019749747589230537,
                -0.0044787246733903885,
                -0.03260064870119095,
                -0.062135834246873856,
                0.03587556257843971,
                -0.0008136743563227355,
                0.031035911291837692,
                0.004796536173671484,
                0.03893408551812172,
                -0.024184295907616615,
                -0.013818413950502872,
                -0.01704971306025982,
                0.09791529923677444,
                -0.05387436971068382,
                -0.0069859446957707405,
                -0.07231508940458298,
                0.0012719467049464583,
                -0.009967561811208725,
                0.024746665731072426,
                -0.008863194845616817,
                0.03402889892458916,
                -0.02445104718208313,
                -0.042708903551101685,
                -0.00793389417231083,
                -0.040813151746988297,
                -0.010062025859951973,
                0.008840206079185009,
                -0.026240993291139603,
                -0.020565325394272804,
                -0.014587881043553352,
                -0.0012848295737057924,
                0.011587955988943577,
                0.017885737121105194,
                0.007824810221791267,
                -0.055313609540462494,
                0.003089125733822584,
                0.021312875673174858,
                0.0013188807060942054,
                0.029204245656728745,
                0.005745391361415386,
                0.007070316933095455,
                -0.07018262147903442,
                -0.0019279030384495854,
                0.00392281636595726,
                -0.0067047858610749245,
                -0.024988384917378426,
                -0.031573206186294556,
                -0.020550772547721863,
                0.015456810593605042,
                -0.03240609169006348,
                0.04453447461128235,
                0.03342815488576889,
                -0.06726448982954025,
                0.025745078921318054,
                -0.009771388955414295,
                -0.013156991451978683,
                0.06365359574556351,
                -0.034115616232156754,
                -0.08824627101421356,
                -0.006368924397975206,
                -0.004087485373020172,
                -0.06512241810560226,
                0.011079604737460613,
                -0.04388157278299332,
                0.018839428201317787,
                -0.023950884118676186,
                -0.01154057402163744,
                0.004068469163030386,
                -0.014117619022727013,
                0.02241411991417408,
                -0.013639075681567192,
                -0.007499347440898418,
                0.024994488805532455,
                -0.032300692051649094,
                -0.03550988435745239,
                -0.007854063995182514,
                0.0020444614347070456,
                -0.07078467309474945,
                0.03046499937772751,
                0.03880785405635834,
                0.1162029430270195,
                -0.03857412561774254,
                0.004888318013399839,
                -0.09009551256895065,
                -0.062409281730651855,
                -0.05942509323358536,
                -0.025848278775811195,
                -0.06633824110031128,
                -0.008201027289032936,
                0.046757906675338745,
                -0.027254659682512283,
                -0.019758863374590874,
                4.991840978618711e-05,
                0.06988628208637238,
                0.017495082691311836,
                -0.01955568976700306,
                0.06391461938619614,
                0.014325170777738094,
                0.016452746465802193,
                0.0011762694921344519,
                0.029437363147735596,
                0.023142749443650246,
                -0.048715900629758835,
                -0.016877148300409317,
                0.01587829738855362,
                0.010335296392440796,
                -0.004064018838107586,
                0.03981345146894455,
                0.01831759512424469,
                0.02796819806098938,
                -0.004932302515953779,
                -0.04199795052409172,
                0.019253801554441452,
                0.05853990092873573,
                0.08010700345039368,
                0.06618021428585052,
                0.004000735003501177,
                0.017806993797421455,
                0.01030096784234047,
                0.009964276105165482,
                -0.002429752377793193,
                -0.024105772376060486,
                0.014145917259156704,
                -0.0836474671959877,
                0.008290679194033146,
                -0.021302450448274612,
                0.026739902794361115,
                -0.015815502032637596,
                0.002128343563526869,
                0.03408906236290932,
                0.03939157351851463,
                -0.037806179374456406,
                -0.02840830571949482,
                -0.017802203074097633,
                0.003454843070358038,
                0.011254373006522655,
                0.03173834830522537,
                -0.055222343653440475,
                -0.0339474156498909,
                0.05134935304522514,
                -0.03944657742977142,
                0.03631789982318878,
                -0.010675393044948578,
                0.050424724817276,
                -0.0018200380727648735,
                0.0179244726896286,
                -0.022188102826476097,
                -0.03179645538330078,
                -0.010240327566862106,
                -0.025226235389709473,
                -0.007649296894669533,
                -0.00855114497244358,
                0.002203812589868903,
                0.02945037931203842,
                -0.026743194088339806,
                -0.0018610568949952722,
                -0.014536657370626926,
                0.026661643758416176,
                0.00821946281939745,
                0.022816205397248268,
                0.027421290054917336,
                0.01937074586749077,
                0.021913103759288788,
                0.0006134887225925922,
                -0.02852637507021427,
                -0.05324696749448776,
                0.03745933249592781,
                -0.013936923816800117,
                0.016735725104808807,
                0.01573937013745308,
                -0.03361867740750313,
                -0.030818728730082512,
                -0.01653004251420498,
                0.004946944769471884,
                0.009661766700446606,
                0.0025586171541363,
                0.023370511829853058,
                -0.010104147717356682,
                -0.010198072530329227,
                -0.03263187035918236,
                0.007344584446400404,
                -0.01098084356635809,
                -0.018177514895796776,
                0.08052554726600647,
                0.010553969070315361,
                -0.0644860491156578,
                -0.007241099141538143,
                -0.0066111208871006966,
                0.023778580129146576,
                0.011748398654162884,
                0.006919212639331818,
                0.030535370111465454,
                -0.021139446645975113,
                0.015283724293112755,
                -0.016340455040335655,
                0.03497444465756416,
                0.049639880657196045,
                0.026420289650559425,
                -0.015140281990170479,
                -0.011897456832230091,
                -0.019005462527275085,
                -0.004816427826881409,
                -0.009761914610862732,
                -0.06010005995631218,
                -0.0038309514056891203,
                -0.03379897028207779,
                -0.07724222540855408,
                0.006259756162762642,
                0.004378572106361389,
                0.02064034529030323,
                0.0012071197852492332,
                0.014737223275005817,
                -0.011107385158538818,
                -0.01885480433702469,
                -0.07720120996236801,
                -0.02424471080303192,
                0.005521970335394144,
                -0.016572292894124985,
                -0.054928284138441086,
                -0.006887615658342838,
                -0.0643504410982132,
                -0.007218453101813793,
                -0.013708802871406078,
                -0.004385927692055702,
                0.05976039916276932,
                -0.06393653154373169,
                0.04015820100903511,
                -0.0064789229072630405,
                0.0020449336152523756,
                -0.009050842374563217,
                -0.014970310032367706,
                0.0022088363766670227,
                0.0017508006421849132,
                0.0310655627399683,
                -0.1170397400856018,
                -0.012014183215796947,
                -0.02705805003643036,
                0.015283175744116306,
                0.0385759212076664,
                -0.0645674392580986,
                -0.08722929656505585,
                -0.05305611714720726,
                0.016642976552248,
                -0.010002250783145428,
                0.03257433697581291,
                0.015140930190682411,
                0.019161926582455635,
                -0.029068995267152786,
                0.04066229611635208,
                0.012544508092105389,
                -0.021742507815361023,
                -0.019730310887098312,
                0.0092546958476305,
                0.03321544826030731,
                0.04835887998342514,
                0.0009897940326482058,
                -0.047754060477018356,
                0.007085131015628576,
                0.018797842785716057,
                0.03470541909337044,
                5.9211910411249846e-05,
                0.008589528501033783,
                -0.008567552082240582,
                -0.03505140542984009,
                0.006430621724575758,
                0.019471632316708565,
                0.05747600272297859,
                0.02264804020524025,
                -0.05089346319437027,
                -0.06713879853487015,
                0.046426404267549515,
                0.022513413801789284,
                -0.05050431191921234,
                0.0578833632171154,
                -0.011067300103604794,
                -0.02906743809580803,
                0.029147503897547722,
                0.044650789350271225,
                -0.016576537862420082,
                -0.015126355923712254,
                0.011640104465186596,
                -0.005000903736799955,
                -0.026124656200408936,
                0.0935923159122467,
                -0.07637655735015869,
                -0.03052869811654091,
                -0.012457589618861675,
                -0.03877701610326767,
                -0.042900025844573975,
                -0.040198009461164474,
                -0.0002735672751441598,
                0.018459150567650795,
                -0.048601385205984116,
                -0.020967582240700722,
                -0.005602943245321512,
                0.060322001576423645,
                0.008816124871373177,
                -0.035288963466882706,
                0.029452690854668617,
                0.0013353886315599084,
                0.02797798439860344,
                -0.001115598832257092,
                -0.04339886084198952,
                0.09779544919729233,
                0.06436070799827576,
                0.0011297482997179031,
                0.0010033096186816692,
                -0.0007505215471610427,
                0.006934234406799078,
                -0.0465744324028492,
                0.033925607800483704,
                0.035258155316114426,
                0.022977273911237717,
                0.043556854128837585,
                -0.033279817551374435,
                -0.03723081946372986,
                -0.020171673968434334,
                0.018272582441568375,
                0.0018414851510897279,
                0.0262086670845747,
                0.0004483793454710394,
                -0.036891818046569824,
                -0.04726194962859154,
                0.04351380839943886,
                0.027556007727980614,
                0.005871247500181198,
                0.00954277254641056,
                0.05376564338803291,
                0.014147534966468811,
                0.06506410986185074,
                0.005093790125101805,
                0.018430516123771667,
                -0.03569858521223068,
                0.04835304617881775,
                -0.00389101798646152,
                -0.04068387672305107,
                0.03370333090424538,
                0.097859226167202,
                0.04589765518903732,
                -0.028666986152529716,
                -0.032971616834402084,
                0.016463324427604675,
                0.029471704736351967,
                0.038529857993125916,
                -0.07242093980312347,
                -0.04785370081663132,
                -0.02686159871518612,
                -0.01846081018447876,
                0.018342765048146248,
                -0.017613453790545464,
                -0.012099687941372395,
                0.04864814132452011,
                -0.022688625380396843,
                0.03772028163075447,
                -0.05057080462574959,
                0.0014253585832193494,
                0.03222762793302536,
                -0.0043114968575537205,
                -0.009503315202891827,
                -0.0032249793875962496,
                0.10000552237033844,
                -0.008888221345841885,
                0.06329721212387085,
                0.07846755534410477,
                -0.006655602250248194,
                -0.031233226880431175,
                0.06907466053962708,
                0.034924622625112534,
                -0.09477242082357407,
                -0.015188498422503471,
                0.012488906271755695,
                0.03372293338179588,
                0.07872324436903,
                0.002113054506480694,
                -0.03122454136610031,
                -0.026454243808984756,
                -0.004558497574180365,
                0.03183920308947563,
                0.02963637374341488,
                0.03751995787024498,
                -0.0068402462638914585,
                0.002125843893736601,
                0.0017052179900929332,
                0.018571367487311363,
                0.03912694752216339,
                -0.015168766491115093,
                0.007245946675539017,
                -0.029391134157776833,
                0.03240136429667473,
                -0.001989802811294794,
                -0.10851553082466125,
                -0.03531515225768089,
                0.02826574258506298,
                -0.024044141173362732,
                0.005311375949531794,
                -0.016241390258073807,
                -0.0428294874727726,
                0.03398040682077408,
                -0.062112852931022644,
                0.04746828228235245,
                -0.04242464527487755,
                -0.007417493034154177,
                0.0003709526499733329,
                0.02336471900343895,
                -0.0075911590829491615,
                -0.053322479128837585,
                0.08143658190965652,
                -0.0045343744568526745,
                -0.03371661901473999,
                -0.02388591133058071,
                -0.01124961581081152,
                -0.0675986260175705,
                -0.019287243485450745,
                0.026559315621852875,
                0.040506813675165176,
                0.006432872265577316,
                -0.035848163068294525,
                -0.06210706755518913,
                -0.0626499280333519,
                0.025192413479089737,
                -0.02840355411171913,
                -0.0049689519219100475,
                0.019332148134708405,
                -0.01963495835661888,
                -0.02516290172934532,
                0.009554549120366573,
                -0.014662198722362518,
                0.039977144449949265,
                -0.033002886921167374,
                -0.01511249877512455,
                0.02196642942726612,
                -0.035598233342170715,
                -5.332958893581553e-33,
                -0.011470921337604523,
                -0.04933447390794754,
                -0.027090007439255714,
                -0.014075868763029575,
                0.025351954624056816,
                -0.038127683103084564,
                0.021506400778889656,
                0.02091020531952381,
                0.029141798615455627,
                0.01542501337826252,
                0.012805541045963764,
                0.02183167263865471,
                0.03828725591301918,
                0.0025872760452330112,
                0.0405593104660511,
                -0.0774923786520958,
                0.002661722246557474,
                0.023071007803082466,
                -0.00692748324945569,
                -0.025210285559296608,
                -0.021548409014940262,
                0.01602133736014366,
                0.011023178696632385,
                -0.052317000925540924,
                0.014006520621478558,
                -0.0036314157769083977,
                -0.02508496306836605,
                -0.0022899028845131397,
                -0.02206580713391304,
                0.013013841584324837,
                0.002214140724390745,
                0.00036266562528908253,
                0.02135450206696987,
                -0.0125672472640872,
                -0.035792455077171326,
                0.037851568311452866,
                -0.01937835104763508,
                -0.017226701602339745,
                -0.009061899967491627,
                -0.03964637964963913,
                -0.0896342471241951,
                -0.0581122450530529,
                0.006714369170367718,
                -0.0028876462019979954,
                -0.015554078854620457,
                0.0013758237473666668,
                -0.010373663157224655,
                -0.015006093308329582,
                -0.009847713634371758,
                -0.026436274871230125,
                -0.01920180208981037,
                -0.0342712327837944,
                -0.014461582526564598,
                -0.004650842864066362,
                0.04879535734653473,
                0.0799548551440239,
                0.051455993205308914,
                -0.029641835018992424,
                0.02806803025305271,
                -0.04159568250179291,
                -0.029630355536937714,
                -0.0380244180560112,
                -0.00549372099339962,
                -0.0024505567271262407,
                -0.0453876294195652,
                0.01565856672823429,
                -0.0996880754828453,
                0.021189799532294273,
                0.022236723452806473,
                -0.0171829666942358,
                -0.05038667470216751,
                0.0893993005156517,
                -0.00906841829419136,
                -0.021421512588858604,
                -0.029575731605291367,
                -0.007312171161174774,
                -0.04325098171830177,
                0.026767363771796227,
                -0.008613236248493195,
                0.050280798226594925,
                -0.06727307289838791,
                -0.033008210361003876,
                -0.024301690980792046,
                -0.02612265758216381,
                -0.005304398946464062,
                0.04304857179522514,
                -0.03336745873093605,
                0.010826527141034603,
                0.03407403454184532,
                0.0131352748721838,
                -0.05019884929060936,
                0.012377655133605003,
                -0.054716806858778,
                0.03517747297883034,
                0.08789198100566864,
                -0.03690343722701073,
                0.06528238952159882,
                -0.018580429255962372,
                0.01820417121052742,
                0.010006302036345005,
                -0.027905717492103577,
                0.036972951143980026,
                -0.03303265944123268,
                0.0041019427590072155,
                0.004959908314049244,
                -0.022771388292312622,
                0.024240445345640182,
                0.0015218043699860573,
                -0.01675296388566494,
                0.019324900582432747,
                0.031686872243881226,
                0.04258079081773758,
                -0.004257113207131624,
                -0.02523830160498619,
                -0.021107114851474762,
                -0.019672656431794167,
                0.01584543287754059,
                0.03020867146551609,
                0.007541878614574671,
                -0.0063854483887553215,
                0.07084431499242783,
                0.00011420420923968777,
                0.01625458151102066,
                -0.03150501474738121,
                9.277088975068182e-05,
                -0.007119154557585716,
                0.06269316375255585,
                0.000989515334367752,
                0.03832446038722992,
                -0.04637305438518524,
                0.017330611124634743,
                -0.002266831696033478,
                2.0152722868260753e-07,
                0.0006350610055960715,
                0.016801506280899048,
                0.03734653815627098,
                0.06192859262228012,
                0.04248388484120369,
                0.01876303367316723,
                -0.01297328807413578,
                0.04794353246688843,
                -0.04510555416345596,
                -0.03586461395025253,
                0.04776107892394066,
                -0.020073534920811653,
                0.01044655591249466,
                0.016406001523137093,
                0.0014467048458755016,
                -0.10298316180706024,
                0.00555242458358407,
                -0.001195497578009963,
                -0.03638777881860733,
                -0.009332395158708096,
                0.06828603148460388,
                0.00875451136380434,
                0.001481260871514678,
                -0.06540460139513016,
                -0.0007815135759301484,
                -0.035184044390916824,
                0.01038592029362917,
                -0.05589563027024269,
                0.056713204830884933,
                -0.04001472890377045,
                0.07377287745475769,
                0.06749732792377472,
                0.01822753995656967,
                0.05649930238723755,
                0.019883599132299423,
                -0.06994463503360748,
                -0.012036211788654327,
                0.041984762996435165,
                0.0047395420260727406,
                -0.005873860325664282,
                -0.010470996610820293,
                -0.10339175164699554,
                -0.017948176711797714,
                0.03281005471944809,
                0.03528239205479622,
                0.0014469586312770844,
                0.03179805725812912,
                0.08784512430429459,
                -0.10274496674537659,
                0.024781329557299614,
                0.019692925736308098,
                -0.018452316522598267,
                -0.014799719676375389,
                0.004261196590960026,
                0.006252040155231953,
                0.015605472028255463,
                0.017943594604730606,
                0.050082188099622726,
                0.051913607865571976,
                -0.011285954155027866,
                -0.010542399249970913,
                -0.034014347940683365,
                0.02514970302581787,
                0.08725709468126297,
                -0.0459253154695034,
                -0.0999470129609108,
                -0.010148894041776657,
                1.523836719242866e-34,
                0.009582501836121082,
                -0.05342898145318031,
                -0.013156847096979618,
                -0.03349687159061432,
                0.04158349707722664,
                -0.0039815413765609264,
                0.017153752967715263,
                -0.005433726590126753,
                -0.021141517907381058,
                -0.041185345500707626,
                -0.02961812913417816,
                -0.03928224742412567,
                0.07413110136985779,
                -0.05259622633457184,
                -0.013744620606303215,
                -0.02818748541176319,
                0.02435373142361641,
                0.06673984229564667,
                0.0333755686879158,
                -0.018672477453947067,
                0.042300838977098465,
                0.057260699570178986,
                0.02257593534886837,
                0.01109377108514309,
                0.09102588891983032,
                -0.022193165495991707,
                -0.08377671986818314,
                0.048220906406641006,
                0.045376256108284,
                -0.010475259274244308,
                0.02203560434281826,
                -0.020799703896045685,
                -0.021893711760640144,
                -0.01603543758392334,
                0.000753602187614888,
                -0.008190520107746124,
                -0.03114277310669422,
                -0.034380652010440826,
                0.0002007683797273785,
                0.048349276185035706,
                -0.042043790221214294,
                0.04990043491125107,
                0.06802058964967728,
                -0.02275833487510681,
                0.060536742210388184,
                1.5222526599245612e-06,
                -0.050958823412656784,
                -0.006936065386980772,
                0.028817053884267807,
                -0.02188759669661522,
                0.0187673419713974,
                0.05828017741441727,
                -0.007959111593663692,
                0.0105504235252738,
                -0.015156787820160389,
                -0.007776518817991018,
                -0.02863209694623947,
                0.08679085969924927,
                -0.0764731913805008,
                0.050940219312906265,
                -0.04214183986186981,
                0.016423242166638374,
                0.027753103524446487,
                0.0029485365375876427,
                0.004082888830453157,
                0.012766541913151741,
                0.08341581374406815,
                0.04252118989825249,
                0.06995606422424316,
                0.04080409184098244,
                0.021096237003803253,
                0.04474078491330147,
                -0.013484731316566467,
                -0.03841603174805641,
                -0.017965763807296753,
                0.013624349609017372,
                -0.01932317018508911,
                -0.010884210467338562,
                -0.04893402010202408,
                -0.021692363545298576,
                0.028683703392744064,
                0.06783894449472427,
                -0.04640242084860802,
                0.006042744964361191,
                0.013153786770999432,
                -0.014263150282204151,
                0.08447607606649399,
                -0.03880872577428818,
                -0.0155814029276371,
                0.013377252966165543,
                -0.03262324631214142,
                -0.036926425993442535,
                -0.04282337799668312,
                -0.049840234220027924,
                -0.0032261500600725412,
                -0.0019630277529358864,
                -0.009770575910806656,
                -0.0040078130550682545,
                -0.024673843756318092,
                0.0532209537923336,
                -0.011022264137864113,
                0.03698843717575073,
                -0.019064083695411682,
                -0.016055580228567123,
                -0.013514449819922447,
                -0.042865585535764694,
                -0.0041002389043569565,
                0.011577758006751537,
                -0.022091638296842575,
                0.03824715316295624,
                -0.031467732042074203,
                0.06425413489341736,
                -0.005868596490472555,
                0.018300138413906097,
                -0.02469044364988804,
                0.00739244231954217,
                -0.04369628056883812,
                0.0030311676673591137,
                0.046614713966846466,
                0.04284822195768356,
                0.01273457333445549,
                -0.02613525278866291,
                -0.012249884195625782,
                0.005243903025984764,
                -0.025774938985705376,
                -0.0002645804197527468,
                0.0419328510761261,
                -0.05103675648570061,
                0.009751321747899055,
                -0.02798077091574669,
                0.02447356842458248,
                -0.0261014886200428,
                -0.00995806884020567,
                0.026548489928245544,
                0.0666843131184578,
                -0.06638734042644501,
                -0.029653871431946754,
                -0.020325662568211555,
                0.028838135302066803,
                -0.033874548971652985,
                -0.025265749543905258,
                -0.02163233421742916,
                0.03760988265275955,
                0.019402263686060905,
                -0.004251253791153431,
                -0.029051240533590317,
                0.028326112776994705,
                0.029731636866927147,
                0.015640107914805412,
                -0.07598625868558884,
                -0.01753072254359722,
                0.06950227171182632,
                -0.04461516812443733,
                -0.07154002785682678,
                -0.038556601852178574,
                0.01661858521401882,
                0.023100540041923523,
                -0.04082101210951805,
                -0.00887563917785883,
                0.01282696146517992,
                -0.0087394705042243,
                0.02211879752576351,
                0.05451967939734459,
                -0.0032021929509937763,
                -0.016751442104578018,
                0.0076170554384589195,
                0.02197185717523098,
                0.010474077425897121,
                -0.03372923657298088,
                -0.10401584208011627,
                0.017087044194340706,
                -0.018120435997843742,
                -0.018193352967500687,
                0.023626411333680153,
                -0.009145219810307026,
                -0.015528743155300617,
                0.0017661507008597255,
                -0.05944894626736641,
                0.04511072486639023,
                -0.058288298547267914,
                0.012907709926366806,
                -0.04629967734217644,
                -0.009708765894174576,
                -0.004818354733288288,
                0.044262513518333435,
                0.06535737216472626,
                -0.004147599916905165,
                0.0754299983382225,
                0.02491818740963936,
                -0.030024686828255653,
                0.0029015731997787952,
                0.011953277513384819,
                -0.07477973401546478,
                0.021285980939865112,
                0.07606177031993866,
                0.007324826437979937,
                0.010991862043738365,
                -0.055475033819675446,
                0.0430985726416111,
                0.0019737316761165857,
                -0.029923202469944954,
                -0.03664916753768921,
                -0.007453779224306345,
                -0.02886202745139599,
                -0.01484739501029253,
                0.06047866493463516,
                -0.04366060346364975,
                0.033817362040281296,
                -0.03247513994574547,
                -0.006573110353201628,
                -0.07553379982709885,
                -0.06664621084928513,
                -0.0026199037674814463,
                0.011450610123574734,
                0.022914987057447433,
                -0.010531838051974773,
                -0.09944847971200943,
                -0.05139860883355141,
                -0.01702970266342163,
                -0.012273899279534817,
                0.01659798063337803,
                -0.0016079238848760724,
                -0.007854973897337914,
                -0.04029754176735878,
                -0.02503790333867073,
                -0.0017714877612888813,
                0.005695336032658815,
                0.0021290730219334364,
                -0.012966200709342957,
                0.060643259435892105,
                0.051258642226457596,
                0.01059612724930048,
                -0.024014512076973915,
                0.01832592859864235,
                -3.762076812563464e-05,
                0.020472116768360138,
                0.033729057759046555,
                0.005169895943254232,
                0.013133203610777855,
                0.0542064793407917,
                0.07403906434774399,
                -0.020229637622833252,
                0.004088511224836111,
                0.048040006309747696,
                0.040389735251665115,
                -0.019920822232961655,
                0.035910896956920624,
                0.15790428221225739,
                0.016337957233190536,
                0.033332571387290955,
                -0.09584809839725494,
                0.002195159438997507,
                0.01556798629462719,
                -0.010324246250092983,
                0.0006534295389428735,
                0.03415311872959137,
                0.0369022972881794,
                -0.035568732768297195,
                -0.024342305958271027,
                -0.01328343991190195,
                -0.07956192642450333,
                0.05187917873263359,
                -0.010631681419909,
                0.036983124911785126,
                0.03422284498810768,
                0.05697065219283104,
                -0.008417336270213127,
                -0.060322873294353485,
                0.012568863108754158,
                0.013006593100726604,
                0.024980578571558,
                0.009568391367793083,
                -0.022525323554873466,
                0.031222188845276833,
                -0.03202015161514282,
                -0.03258867189288139,
                -0.00472357077524066,
                0.03596746549010277,
                -0.01058926060795784,
                -0.01002165861427784,
                0.06629329174757004,
                0.007856485433876514,
                -0.016708066686987877,
                0.021152643486857414,
                -0.03962285444140434,
                -0.02027117647230625,
                0.011138967238366604,
                -0.02437678910791874,
                -0.040985576808452606,
                -0.02465837448835373,
                -0.0014065896393731236,
                0.008232719264924526,
                -0.005525804590433836,
                -0.03038402833044529,
                -0.0033812024630606174,
                -0.04266957938671112,
                -0.0036470205523073673,
                0.00018749221635516733,
                -0.05338482931256294,
                0.012338424101471901,
                -0.003219502279534936,
                -0.03533720225095749,
                0.01843818463385105,
                0.03925114497542381,
                0.009405978955328465,
                -0.0027532721869647503,
                0.0237770676612854,
                0.01738078147172928,
                0.022158967331051826,
                -0.03334105387330055,
                0.03398478031158447,
                0.040424130856990814,
                -0.01872175745666027,
                -0.011354692280292511,
                0.0031588946003466845,
                -0.014451720751821995,
                0.07153944671154022,
                0.06898237764835358,
                -0.046220678836107254,
                0.04787880927324295,
                -0.026932615786790848,
                0.05069321021437645,
                -0.004041427280753851,
                0.05525767803192139,
                0.06371507793664932,
                0.028788158670067787,
                0.008296960033476353,
                0.01723482459783554,
                0.006771791726350784,
                0.04344390332698822,
                0.018307512626051903,
                0.04666472226381302,
                -0.03213713690638542,
                0.039752911776304245,
                -0.036052409559488297,
                0.009235091507434845,
                0.02793232910335064,
                -0.08993984758853912,
                -0.04452439770102501,
                0.0006233399617485702,
                -0.041199032217264175,
                -0.0002853290643543005,
                -0.02716575376689434,
                0.031166834756731987,
                0.04791625216603279,
                -0.03213285654783249,
                0.07119312882423401,
                -0.046386267989873886,
                -0.042358048260211945,
                -0.02344101294875145,
                0.0016757616540417075,
                0.03396250680088997,
                0.029887918382883072,
                -0.03560788184404373,
                0.00022182597604114562,
                0.0522984080016613,
                -0.05182945355772972,
                0.034742262214422226,
                -0.026497457176446915,
                -0.01787675730884075,
                0.02963867597281933,
                0.0700494721531868,
                -0.008084198459982872,
                0.010181141085922718,
                -0.006586187984794378,
                0.04361159726977348,
                0.011378660798072815,
                -0.03520682826638222,
                -0.03474666178226471,
                0.009868999943137169,
                -0.05204210430383682,
                -0.00946213398128748,
                0.009286210872232914,
                -0.06555335968732834,
                -0.03666096553206444,
                -0.08185901492834091,
                -0.028592988848686218,
                -0.011216609738767147,
                -0.0633574053645134,
                0.025048689916729927,
                -0.016529859974980354,
                -0.012058738619089127,
                0.01373713556677103,
                0.046205785125494,
                -0.004476741887629032,
                0.03803004324436188,
                -0.05311752110719681,
                -0.014689049683511257,
                -0.048655662685632706,
                0.006528676953166723,
                0.008284756913781166,
                0.006537984125316143,
                -0.05455208942294121,
                0.01785925030708313,
                0.005831618327647448,
                0.024780871346592903,
                -0.037715714424848557,
                -0.0878816619515419,
                -0.004282990470528603,
                -0.037435464560985565,
                0.05295027792453766,
                -0.012010887265205383,
                0.00967603363096714,
                -0.016241705045104027,
                -0.09274757653474808,
                0.08479458093643188,
                -0.02805209904909134,
                -0.036170098930597305,
                -0.015315060503780842,
                0.023781588301062584,
                0.022471871227025986,
                0.004638073034584522,
                -0.025614896789193153,
                -0.024497611448168755,
                -0.03363015502691269,
                -0.015553242526948452,
                -0.0041281795129179955,
                0.035632357001304626,
                0.01373615488409996,
                -0.015459349378943443,
                0.008048339746892452,
                0.03500388562679291,
                0.037987034767866135,
                -0.007375100161880255,
                -0.06786404550075531,
                0.02933206409215927,
                0.04072786867618561,
                -0.021588031202554703,
                -0.018368348479270935,
                0.054287899285554886,
                -0.009541541337966919,
                0.01833900436758995,
                0.044554613530635834,
                0.035890962928533554,
                -0.03110397234559059,
                -0.0021269668359309435,
                -0.07464778423309326,
                -0.01850893348455429,
                0.06797555088996887,
                0.018830591812729836,
                0.017414646223187447,
                0.006019182503223419,
                -0.004592812620103359,
                -0.022114647552371025,
                0.008620182983577251,
                0.044678788632154465,
                -0.015917515382170677,
                0.05864155665040016,
                -0.03777850791811943,
                -0.027942227199673653,
                -0.014004924334585667,
                0.010079978033900261,
                0.0753522515296936,
                -0.020659230649471283,
                0.0041719903238117695,
                -0.031443044543266296,
                -0.02524694614112377,
                -0.017803946509957314,
                -0.06380444020032883,
                -0.016818521544337273,
                0.02690550498664379,
                0.07278753817081451,
                0.010498466901481152,
                -0.0342378169298172,
                -0.020893439650535583,
                0.0487634502351284,
                -0.007736866362392902,
                0.02889186516404152,
                -0.031953949481248856,
                -0.06846694648265839,
                0.0002249293029308319,
                0.026933688670396805,
                0.033661916851997375,
                -0.038451194763183594,
                -0.01735902950167656,
                0.008023623377084732,
                -0.024626996368169785,
                0.07490045577287674,
                -0.03733734413981438,
                -0.0353550910949707,
                -0.06233937293291092,
                -0.0422762893140316,
                -0.0037180136423557997,
                -0.0344838909804821,
                0.04004867747426033,
                0.042527757585048676,
                -0.013216164894402027,
                0.012601875700056553,
                -0.012315239757299423,
                -0.013941274024546146,
                0.014922795817255974,
                -0.02644617110490799,
                0.049551934003829956,
                0.003030964871868491,
                -0.07223282009363174,
                0.0025212920736521482,
                0.02654884196817875,
                -0.003604837693274021,
                -0.039270564913749695,
                0.028291955590248108,
                0.01542813703417778,
                0.019600676372647285,
                0.00703740818426013,
                0.004847212228924036,
                -0.0027574594132602215,
                0.0045515792444348335,
                -0.059338703751564026,
                -0.032824043184518814,
                0.067864328622818,
                0.000734536093659699,
                0.012027593329548836,
                0.03433654457330704,
                0.024632833898067474,
                -0.011072673834860325,
                -0.044643837958574295,
                -0.008651472628116608,
                0.03727103769779205,
                0.03930874168872833,
                0.0014157630503177643,
                0.007318486925214529,
                -0.008611857891082764,
                0.0010498685296624899,
                0.0038844647351652384,
                -0.008049208670854568,
                -0.0009062623139470816,
                -0.0028715268708765507,
                -0.0360538512468338,
                0.0017270230455324054,
                0.0013246933231130242,
                -0.02103150635957718,
                -0.03046264499425888,
                -0.004291643854230642,
                -0.023676473647356033,
                0.028735926374793053,
                0.019113460555672646,
                0.06321841478347778,
                -0.039523083716630936,
                -0.04347248002886772,
                -0.046083200722932816,
                0.0037728340830653906,
                -0.003913452383130789,
                0.00685425428673625,
                -0.016648778691887856,
                -0.0015017195837572217,
                -0.09160291403532028,
                -0.05079524591565132,
                0.015239166095852852,
                -0.01458485797047615,
                -0.02527580037713051,
                0.0036825533024966717,
                -0.04010775685310364,
                -0.04741372540593147,
                -0.0329977311193943,
                -0.04075794667005539,
                0.008136680349707603,
                -0.03555911406874657,
                -9.470401710132137e-05,
                -0.021610135212540627,
                0.012840905226767063,
                0.009757861495018005,
                0.028335388749837875,
                0.04571031406521797,
                0.03664104640483856,
                -0.0806986466050148,
                -0.058393724262714386,
                -0.0010977693600580096,
                -5.448036851791178e-33,
                -0.028699519112706184,
                -0.02923976257443428,
                -0.032806430011987686,
                0.06887287646532059,
                -0.0024591099936515093,
                -0.03987469524145126,
                -0.022447364404797554,
                -0.005536429118365049,
                0.01021250057965517,
                0.009601126424968243,
                0.027279814705252647,
                0.010544421151280403,
                0.02323225699365139,
                -0.07205794006586075,
                0.020910175517201424,
                0.04716229438781738,
                0.0054524969309568405,
                0.06445715576410294,
                -0.032812438905239105,
                -0.04884926229715347,
                -0.02713117003440857,
                -0.005740939639508724,
                0.04917619749903679,
                -0.06080421805381775,
                -0.016304459422826767,
                0.042020414024591446,
                0.036562468856573105,
                0.006173965986818075,
                -0.03166719898581505,
                -0.011561181396245956,
                0.051351793110370636,
                0.04803764447569847,
                -0.030177883803844452,
                0.02796488255262375,
                -0.04001211002469063,
                0.03965802863240242,
                -0.07750360667705536,
                0.007313844282180071,
                0.043478693813085556,
                0.007410693913698196,
                0.04008829966187477,
                0.01935208961367607,
                -0.004470960237085819,
                -0.0009653469314798713,
                -0.011591898277401924,
                0.008719556033611298,
                0.03450528532266617,
                -0.002343526342883706,
                -0.01347051840275526,
                -0.04239186644554138,
                -0.008967258967459202,
                0.033893708139657974,
                -0.0725519061088562,
                0.03355834633111954,
                0.009835639968514442,
                0.050078120082616806,
                -0.01616373471915722,
                -0.03636081516742706,
                0.03064226731657982,
                0.07023289799690247,
                0.08712344616651535,
                -0.01623939350247383,
                0.05101333186030388,
                6.653826858382672e-05,
                0.061262741684913635,
                0.014092284254729748,
                0.02723618783056736,
                -0.039622288197278976,
                0.03533613309264183,
                0.013181968592107296,
                0.007771621458232403,
                0.046764928847551346,
                0.004872300196439028,
                0.012560888193547726,
                -0.0585637204349041,
                0.03041217289865017,
                -0.05394800379872322,
                0.04479195550084114,
                0.028142984956502914,
                -0.04522176459431648,
                0.01457855012267828,
                0.03663081303238869,
                0.036034535616636276,
                -0.012496324256062508,
                -0.007867708802223206,
                -0.005527135916054249,
                -0.05598600208759308,
                -0.05810141935944557,
                -0.009703949093818665,
                -0.017894579097628593,
                -0.04648910090327263,
                0.04992126300930977,
                -0.003882611868903041,
                0.035527363419532776,
                0.03721890598535538,
                -0.0010763690806925297,
                0.021046537905931473,
                0.012853153981268406,
                0.0008834741893224418,
                0.003216152312234044,
                0.02219494991004467,
                0.022474737837910652,
                -0.026702966541051865,
                0.044881388545036316,
                0.041906919330358505,
                -0.03053584322333336,
                -0.054665952920913696,
                -0.030865099281072617,
                -0.01964818686246872,
                -0.005482630338519812,
                -0.011705907061696053,
                0.012512841261923313,
                -0.06128639355301857,
                -0.04633135721087456,
                0.021516595035791397,
                0.024009820073843002,
                0.026938138529658318,
                0.005763399414718151,
                0.010625028982758522,
                0.004402737133204937,
                -0.0036658986937254667,
                0.021186627447605133,
                -0.009913145564496517,
                -0.05679520219564438,
                0.016834253445267677,
                -0.03744291514158249,
                -0.005253258161246777,
                -0.041773777455091476,
                0.02360009029507637,
                -0.005502247717231512,
                0.0223111379891634,
                0.00216868007555604,
                2.2010682698692108e-07,
                -0.0023119288962334394,
                0.11216728389263153,
                0.018560515716671944,
                0.052365999668836594,
                0.005846061743795872,
                -0.04600830003619194,
                0.022097600623965263,
                -0.026643777266144753,
                -0.0038366024382412434,
                0.00274178315885365,
                -0.016635674983263016,
                0.017153725028038025,
                0.028831493109464645,
                0.05003592371940613,
                -0.0056282454170286655,
                -0.08754479885101318,
                0.041239313781261444,
                -0.012262508273124695,
                -0.07862693071365356,
                0.0008780281059443951,
                0.030205894261598587,
                0.01851033605635166,
                0.05253632739186287,
                0.05803942307829857,
                -0.019361091777682304,
                0.0031333027873188257,
                -0.033932559192180634,
                -0.04786423593759537,
                0.023715097457170486,
                0.011675040237605572,
                -0.02196272648870945,
                -0.021067434921860695,
                0.011175044812262058,
                0.10406550765037537,
                -0.014191673137247562,
                -0.04524410143494606,
                -0.029857855290174484,
                -0.020312625914812088,
                0.016570793464779854,
                0.03035060502588749,
                0.0436781607568264,
                -0.0027760444208979607,
                -0.00951311830431223,
                -0.02349744737148285,
                0.024365626275539398,
                0.0007171869510784745,
                0.005245302803814411,
                0.036951061338186264,
                0.0030091016087681055,
                -0.024456337094306946,
                -0.002791940001770854,
                -0.03713182732462883,
                0.017849741503596306,
                -0.0038009854033589363,
                -0.03876683861017227,
                0.012367627583444118,
                0.024022240191698074,
                0.042858291417360306,
                -0.06426280736923218,
                0.04777304828166962,
                -0.0031285518780350685,
                -0.07510413229465485,
                0.009678849019110203,
                -0.015284442342817783,
                -0.003062756732106209,
                0.02632182091474533,
                0.01210180763155222,
                1.7433585673433752e-34,
                0.013935429975390434,
                0.03571402654051781,
                -0.004817024804651737,
                -0.0023567441385239363,
                0.02595807984471321,
                -0.037516046315431595,
                0.047129709273576736,
                -0.0062326532788574696,
                0.02181469090282917,
                -0.020214851945638657,
                -0.013857214711606503,
                -0.017440009862184525,
                -0.0016682102577760816,
                -0.00645030802115798,
                0.027667943388223648,
                -0.011876621283590794,
                0.04045672342181206,
                0.04775961861014366,
                0.00914598535746336,
                -0.014956396073102951,
                -0.03196282312273979,
                0.031271595507860184,
                -0.015499749220907688,
                0.023806793615221977,
                0.08011333644390106,
                -0.03256628289818764,
                -0.034235935658216476,
                0.047582559287548065,
                -0.017642917111516,
                -0.02463797852396965,
                -0.005931005347520113,
                -0.0474638007581234,
                0.012333503924310207,
                -0.023573288694024086,
                -0.014380290172994137,
                0.00713580334559083,
                -0.032868433743715286,
                -0.0022448322270065546,
                -0.01235220767557621,
                -0.013025566004216671,
                -0.006679306272417307,
                0.02818542718887329,
                0.004429555498063564,
                0.03542175889015198,
                0.034400369971990585,
                1.5560802921754657e-06,
                -0.026622209697961807,
                -0.012114662677049637,
                0.05557769164443016,
                -0.05076497048139572,
                0.09073925763368607,
                0.07722097635269165,
                0.02360943891108036,
                0.049014732241630554,
                -0.01545710489153862,
                -0.0326421894133091,
                0.04028981924057007,
                -0.01944606564939022,
                -0.040585510432720184,
                0.06960166245698929,
                -0.008336232975125313,
                0.011976787820458412,
                0.02544216997921467,
                -0.01120363362133503,
                0.030576206743717194,
                0.000666678708512336,
                0.0023913835175335407,
                0.016166478395462036,
                0.046484578400850296,
                0.05892033502459526,
                -0.026401611045002937,
                0.05395307019352913,
                -0.022165266796946526,
                -0.004439673386514187,
                -0.01573077403008938,
                0.043580520898103714,
                0.021756721660494804,
                -0.05401584878563881,
                -0.030305854976177216,
                -0.05877291038632393,
                -0.039589568972587585,
                0.03233189135789871,
                -0.0929485335946083,
                -0.013535833917558193,
                0.020865045487880707,
                -0.05819208174943924,
                -0.12090474367141724,
                -0.06522612273693085,
                -0.0031468793749809265,
                0.0030656896997243166,
                -0.05909772589802742,
                -0.033630747348070145,
                -0.031372662633657455,
                -0.02387222647666931,
                -0.010558782145380974,
                2.6380581402918324e-05,
                0.032846394926309586,
                0.007864833809435368,
                -0.017520848661661148,
                -0.018357420340180397,
                0.04897318035364151,
                0.0359804704785347,
                -0.008402610197663307,
                0.09375394135713577,
                0.03938082605600357,
                -0.09211302548646927,
                -0.049102988094091415,
                -0.022775186225771904,
                -0.0010872776620090008,
                -0.005003304686397314,
                -0.004458181094378233,
                0.0024876210372895002,
                0.02805742248892784,
                0.04702695086598396,
                0.037402696907520294,
                -0.009805957786738873,
                -0.003118606749922037,
                -0.09363912045955658,
                0.059671368449926376,
                0.033826977014541626,
                0.030156614258885384,
                0.005306704435497522,
                -0.02017553336918354,
                -0.029923446476459503,
                -0.003787058172747493,
                0.03426388278603554,
                0.016376126557588577,
                0.0006999884499236941,
                0.07062168419361115,
                -0.034716758877038956,
                0.050171345472335815,
                0.003944288939237595,
                0.009170710109174252,
                0.028066962957382202,
                0.03771092742681503,
                -0.031041821464896202,
                -0.02080395817756653,
                0.023889392614364624,
                -0.03935316577553749,
                0.001404414651915431,
                -0.007453482132405043,
                -0.03503675386309624,
                0.0063692317344248295,
                0.020298659801483154,
                -0.040051110088825226,
                0.05037892609834671,
                0.030663739889860153,
                -0.012338167987763882,
                0.004855093080550432,
                0.03708883374929428,
                -0.03276301920413971,
                0.010408899746835232,
                -0.033457569777965546,
                -0.041766583919525146,
                -0.0632050409913063,
                -0.006976521573960781,
                0.028462838381528854,
                0.019577419385313988,
                0.006706830579787493,
                0.004302783869206905,
                0.021184777840971947,
                -0.005221028812229633,
                0.02124951034784317,
                -0.03300868719816208,
                -0.027544256299734116,
                -0.009756885468959808,
                0.02697918936610222,
                -0.0018689143471419811,
                -0.06388897448778152,
                -0.030004026368260384,
                9.680686343926936e-05,
                0.01850268803536892,
                -0.03224124386906624,
                0.011434483341872692,
                -0.06069100648164749,
                0.0028472093399614096,
                0.0039022909477353096,
                -0.024752233177423477,
                0.024124255403876305,
                0.02814856357872486,
                -0.014047520235180855,
                -0.08472589403390884,
                0.046306051313877106,
                0.014721140265464783,
                -0.019367268308997154,
                -0.05468229949474335,
                -0.004014258738607168,
                -0.03785192221403122,
                -0.015524858608841896,
                0.0369529165327549,
                0.03767824172973633,
                0.04655515402555466,
                -0.02122361958026886,
                0.006311746779829264,
                0.09347036480903625,
                0.00809041690081358,
                -0.032110292464494705,
                0.0034481913316994905,
                0.06546813249588013,
                -0.0016863684868440032,
                -0.013315466232597828,
                0.0014794262824580073,
                0.03575504571199417,
                0.008075272664427757,
                0.015448175370693207,
                0.024461720138788223,
                -0.027011960744857788,
                0.0035908238496631384,
                -0.044299181550741196,
                0.030711306259036064,
                -0.07680079340934753,
                -0.044127997010946274,
                -0.04473383352160454,
                0.02697835862636566,
                0.08498039096593857,
                0.038354601711034775,
                -0.04237321764230728,
                -0.04131876304745674,
                -0.02502558007836342,
                -0.008075783029198647,
                -0.007664828095585108,
                0.018593696877360344,
                -0.005309422500431538,
                -0.01090842392295599,
                -0.03731459006667137,
                0.002231110353022814,
                -0.0003837664262391627,
                0.008396975696086884,
                -0.00279424455948174,
                0.05563634634017944,
                -0.0009560178150422871,
                0.018799619749188423,
                0.06448589265346527,
                0.002997308736667037,
                0.0073469518683850765,
                0.02246820740401745,
                -0.02875996008515358,
                -0.012416241690516472,
                0.01362859457731247,
                0.0056097679771482944,
                0.03576136380434036,
                0.003930580802261829,
                -0.005068107042461634,
                0.03621731325984001,
                0.04000249132514,
                0.015461345203220844,
                -0.06885138899087906,
                -0.034981172531843185,
                0.031744495034217834,
                0.04711661487817764,
                -0.004310211166739464,
                -0.04699578881263733,
                0.039907149970531464,
                0.03652222827076912,
                -0.009056894108653069,
                0.06524571031332016,
                0.018731962889432907,
                -0.004890517331659794,
                -0.03819674998521805,
                0.01769568957388401,
                -0.028491834178566933,
                0.01757114753127098,
                0.0592213049530983,
                0.0005191611708141863,
                0.04736669734120369,
                0.04341072589159012,
                0.07706018537282944,
                -0.0036505761090666056,
                0.040493641048669815,
                -0.035070519894361496,
                -0.0649552121758461,
                -0.021426411345601082,
                -0.03762684017419815,
                0.021986356005072594,
                -0.0003257692151237279,
                0.029661696404218674,
                0.039701659232378006,
                0.03218106925487518,
                0.02708132192492485,
                -0.009373147040605545,
                -0.04058057814836502,
                -0.00783286802470684,
                0.041162144392728806,
                -0.019807850942015648,
                0.0035032781306654215,
                0.012646560557186604,
                0.03702979162335396,
                0.0005609110230579972,
                -0.05829361453652382,
                -0.025927163660526276,
                -0.00759548507630825,
                0.016658222302794456,
                -0.017755813896656036,
                -0.007411751430481672,
                -0.036829184740781784,
                -0.020367251709103584,
                0.041806451976299286,
                0.02765326201915741,
                -0.010254401713609695,
                -0.04004402458667755,
                0.00442383112385869,
                -0.015888582915067673,
                0.0005166015471331775,
                0.003934314474463463,
                0.008804052136838436,
                0.034992117434740067,
                -0.03759627789258957,
                -0.013472274877130985,
                0.021427402272820473,
                -0.04523715376853943,
                0.0199267640709877,
                0.010347150266170502,
                0.044369667768478394,
                0.0011956156231462955,
                0.018602941185235977,
                0.0054753487929701805,
                -0.027462219819426537,
                0.08052770793437958,
                -0.03575857728719711,
                -0.027416158467531204,
                0.05937507376074791,
                -0.036017220467329025,
                -0.033454690128564835,
                -0.03620051592588425,
                0.01860758475959301,
                0.061369068920612335,
                -0.019787967205047607,
                -0.08553832024335861,
                0.01010464783757925,
                -0.0028242531698197126,
                0.03303281590342522,
                -0.005089960526674986,
                0.01233048364520073,
                0.03879518061876297,
                -0.029743414372205734,
                -0.018473420292139053,
                -0.05350135639309883,
                -0.087697833776474,
                -0.0150084113702178,
                0.037866540253162384,
                -0.007715336512774229,
                -0.009141254238784313,
                -0.014469265006482601,
                -0.02125786989927292,
                -0.011672604829072952,
                0.06112796068191528,
                0.053764890879392624,
                -0.04518255591392517,
                -0.018149033188819885,
                0.0436408631503582,
                0.012377600185573101,
                0.01707984134554863,
                -0.0021049685310572386,
                0.004395006224513054,
                0.046906035393476486,
                -0.008310395292937756,
                -0.024600302800536156,
                0.012882749550044537,
                -0.037290412932634354,
                0.03397778049111366,
                0.008822479285299778,
                0.10572024434804916,
                0.03478904068470001,
                0.019889594987034798,
                0.016983171924948692,
                0.01087709330022335,
                -0.0073584821075201035,
                -0.05099670961499214,
                -0.0021466149482876062,
                -0.04886864870786667,
                -0.006038850639015436,
                0.021692348644137383,
                0.014337827451527119,
                0.03515607863664627,
                0.049310870468616486,
                -0.03619939833879471,
                0.0061333137564361095,
                0.023347577080130577,
                -0.037766896188259125,
                0.0016994406469166279,
                0.01963021047413349,
                -0.04633818566799164,
                -0.04663356766104698,
                -0.0568474642932415,
                -0.02867855876684189,
                0.048075053840875626,
                -0.05055494233965874,
                -0.004230403807014227,
                -0.016799496486783028,
                -0.03854987025260925,
                0.04610208421945572,
                0.01420846488326788,
                -0.025949817150831223,
                0.04435137286782265,
                0.0013302176957949996,
                0.014431152492761612,
                -0.05137518793344498,
                -0.010113591328263283,
                0.02517710067331791,
                -0.02509966865181923,
                0.05547522380948067,
                -0.010193053632974625,
                0.017465967684984207,
                -0.05369717627763748,
                -0.05803048983216286,
                -0.01661951094865799,
                -0.013251964934170246,
                -0.02190656028687954,
                0.07246390730142593,
                -0.021056292578577995,
                0.04364543408155441,
                0.018603388220071793,
                0.05146535113453865,
                0.022570867091417313,
                0.019155533984303474,
                0.07717780768871307,
                -0.0631403923034668,
                0.019488859921693802,
                0.05451164022088051,
                -0.05890731140971184,
                -0.013578356243669987,
                0.024652931839227676,
                0.02146981842815876,
                -0.003779083024710417,
                -0.05042407289147377,
                -0.020246027037501335,
                0.06220947951078415,
                -0.04476458206772804,
                -0.006964658387005329,
                0.005011063069105148,
                -0.03611741214990616,
                0.0005154922837391496,
                -0.027460547164082527,
                0.05832212045788765,
                0.004940265789628029,
                0.005603453144431114,
                -0.008275868371129036,
                -0.01615007407963276,
                0.017883233726024628,
                0.002311558462679386,
                -0.004733376204967499,
                0.038352057337760925,
                -0.052424848079681396,
                -0.04043108969926834,
                -0.03438689187169075,
                0.011851188726723194,
                -0.025604326277971268,
                -0.013580484315752983,
                0.029720725491642952,
                -0.036440409719944,
                -0.005261426791548729,
                0.06652049720287323,
                0.05878471955657005,
                0.01250817347317934,
                -0.042183712124824524,
                -0.060700759291648865,
                -0.030554715543985367,
                -0.01895879954099655,
                -0.15538530051708221,
                -0.051997728645801544,
                -0.008060913532972336,
                0.07336880266666412,
                -0.013850969262421131,
                -0.030395736917853355,
                -0.01196272112429142,
                0.03089742362499237,
                -0.07571084797382355,
                -0.011441992595791817,
                -0.04305494949221611,
                -0.018686851486563683,
                0.036816954612731934,
                0.053910765796899796,
                0.02734091877937317,
                -0.04693221673369408,
                -0.010079185478389263,
                0.04317373037338257,
                -0.03214987367391586,
                0.06458328664302826,
                -0.0029478948563337326,
                0.046984024345874786,
                -0.0717393085360527,
                -0.05435938388109207,
                0.004613787867128849,
                -0.03594948351383209,
                -0.020438367500901222,
                -0.022424176335334778,
                0.01785135827958584,
                0.02361389249563217,
                -0.011298781260848045,
                -0.02393125183880329,
                -0.01866188272833824,
                0.0920037031173706,
                0.011383135803043842,
                0.055926788598299026,
                -0.036320120096206665,
                0.004474147688597441,
                0.024881362915039062,
                0.034207336604595184,
                0.003143793437629938,
                -0.026372021064162254,
                -0.04126792773604393,
                -0.044503092765808105,
                0.003228956600651145,
                -0.03238850086927414,
                0.03787243738770485,
                0.04560799524188042,
                0.059232067316770554,
                0.004559395369142294,
                0.09173494577407837,
                -0.016036177054047585,
                0.04966461658477783,
                0.052820030599832535,
                -0.03338707983493805,
                -0.029984014108777046,
                0.016404159367084503,
                0.02623371034860611,
                0.06643874943256378,
                -0.015860825777053833,
                0.029218347743153572,
                -0.021022630855441093,
                0.012681763619184494,
                0.008235156536102295,
                0.020127935335040092,
                -0.07070425897836685,
                -0.011483728885650635,
                -0.0075551411136984825,
                -0.0010360119631513953,
                -0.027338458225131035,
                0.05080822855234146,
                -0.005295249167829752,
                -0.06721191108226776,
                -0.00505090644583106,
                -0.02797827497124672,
                0.03456263989210129,
                -0.018734993413090706,
                0.00885158684104681,
                -0.06848783791065216,
                0.02199552208185196,
                -0.017992833629250526,
                0.025643689557909966,
                0.01738775707781315,
                -0.08184895664453506,
                -0.01366415899246931,
                0.05608684569597244,
                -0.03403039649128914,
                -0.028563078492879868,
                -0.009537002071738243,
                -0.044466763734817505,
                0.007523094303905964,
                0.02143271081149578,
                0.05267426371574402,
                0.017086796462535858,
                -0.045581888407468796,
                0.031534262001514435,
                0.022859208285808563,
                0.03364435210824013,
                -0.02142687700688839,
                0.039065781980752945,
                -0.018848644569516182,
                -0.006474039983004332,
                0.03328968957066536,
                0.019770462065935135,
                0.03725752234458923,
                -0.014704244211316109,
                -0.0015954530099406838,
                -0.007485757116228342,
                -5.5394175785721946e-33,
                -0.00029227984487079084,
                -0.0318138524889946,
                -0.003766039153560996,
                0.040907323360443115,
                -0.006820515263825655,
                -0.03695095330476761,
                0.02877999097108841,
                0.024561097845435143,
                -0.002840921748429537,
                -0.01064703706651926,
                -0.004752029664814472,
                0.05637809634208679,
                0.04191874340176582,
                -0.03136051446199417,
                -0.03688492998480797,
                0.014292160980403423,
                0.0034119682386517525,
                0.03660568967461586,
                -0.012724236585199833,
                0.014707272872328758,
                0.04076363146305084,
                0.021629612892866135,
                0.03065304271876812,
                -0.06125054880976677,
                -0.0013264936860650778,
                0.033420417457818985,
                -0.003163133515045047,
                -0.0402861051261425,
                -0.020842760801315308,
                0.0070789349265396595,
                -0.009534844197332859,
                0.029955342411994934,
                -0.026488060131669044,
                -0.017206942662596703,
                0.0407755970954895,
                0.02229009009897709,
                -0.06653410941362381,
                -0.011141016148030758,
                -0.015341177582740784,
                -0.021408643573522568,
                0.06021231785416603,
                -0.03276677057147026,
                0.045776523649692535,
                -0.009882471524178982,
                -0.012996792793273926,
                0.0007896196329966187,
                -0.01828904077410698,
                0.0035928015131503344,
                -0.002530470723286271,
                -0.03158405050635338,
                -0.030702611431479454,
                -0.005921515170484781,
                -0.016326583921909332,
                0.058782003819942474,
                -0.0027877925895154476,
                -0.05304805934429169,
                -0.07999983429908752,
                -0.01834103651344776,
                0.04350252076983452,
                0.03504335507750511,
                0.02845051884651184,
                0.023388870060443878,
                -0.02631985954940319,
                -0.003507845336571336,
                0.0064726946875452995,
                -0.018858561292290688,
                0.08872736990451813,
                0.002803354524075985,
                -0.023059939965605736,
                -0.026614628732204437,
                -0.017743412405252457,
                -0.006184851750731468,
                0.009845253080129623,
                0.027797268703579903,
                -0.03598768636584282,
                -0.05986335873603821,
                -0.07916854321956635,
                -0.000932548486161977,
                0.022692270576953888,
                -0.019537055864930153,
                0.02391623891890049,
                -0.06358864903450012,
                -0.021236944943666458,
                -0.04272696003317833,
                -0.0008375521865673363,
                0.03708750382065773,
                -0.04216130077838898,
                -0.0023656561970710754,
                -0.03236310929059982,
                -0.02354944683611393,
                -0.054636400192976,
                0.11197812855243683,
                -0.025190310552716255,
                0.003982014022767544,
                0.056765712797641754,
                0.002350970171391964,
                0.031143277883529663,
                0.02304934523999691,
                0.03178204968571663,
                0.026900075376033783,
                0.009063630364835262,
                -0.003690966172143817,
                -0.05685139074921608,
                -0.03625237196683884,
                0.0595274344086647,
                -0.00902935117483139,
                -0.054874975234270096,
                0.052992843091487885,
                -0.04351082071661949,
                -0.0007771126693114638,
                -0.03156783804297447,
                0.014160537160933018,
                -0.03619909659028053,
                0.04172905907034874,
                0.034439973533153534,
                0.015183566138148308,
                0.006361866369843483,
                -0.02905261516571045,
                -0.01941128447651863,
                -0.020153000950813293,
                -0.012840417213737965,
                -0.020851513370871544,
                -0.01144997589290142,
                0.018469691276550293,
                -0.03919084370136261,
                -0.027129454538226128,
                -0.055434804409742355,
                -0.028731228783726692,
                0.011779428459703922,
                0.01876509189605713,
                -0.003595271846279502,
                0.008555077947676182,
                2.262983116452233e-07,
                0.017234493046998978,
                0.07409069687128067,
                0.01729530841112137,
                0.07580713927745819,
                -0.006606671959161758,
                -0.05269442871212959,
                -0.035139795392751694,
                0.022700130939483643,
                0.018372327089309692,
                -0.03669067099690437,
                0.01750054582953453,
                -0.0237963255494833,
                0.04177875071763992,
                -0.008851241320371628,
                0.012535330839455128,
                -0.006810528226196766,
                -0.00910148210823536,
                -0.024453144520521164,
                -0.005069655831903219,
                0.0011530191404744983,
                0.03931839019060135,
                0.00029587862081825733,
                0.04818902537226677,
                -0.009501693770289421,
                0.029494203627109528,
                -0.05974128842353821,
                -0.011860273778438568,
                -0.019261997193098068,
                0.091236911714077,
                -0.011940858326852322,
                -0.009596495889127254,
                0.044242989271879196,
                0.03080950677394867,
                0.011286390945315361,
                0.0005236510187387466,
                -0.028562115505337715,
                0.023932864889502525,
                0.09344266355037689,
                0.019796255975961685,
                0.050070442259311676,
                -0.023991184309124947,
                -0.056196946650743484,
                -0.004601588472723961,
                -0.014398133382201195,
                0.03392257168889046,
                0.02463587187230587,
                0.0023771279957145452,
                -0.03017939254641533,
                -0.002984554972499609,
                -0.003259442513808608,
                -0.0838867723941803,
                0.0029557603411376476,
                0.05823909118771553,
                0.02271702140569687,
                -0.0427568145096302,
                0.02975114993751049,
                0.012050148099660873,
                -0.02120780572295189,
                -0.028391033411026,
                0.00590506149455905,
                -0.01519647054374218,
                -0.1042279452085495,
                0.012845016084611416,
                -0.009134533815085888,
                0.06599036604166031,
                -0.030306724831461906,
                0.008999495767056942,
                1.9917440822976006e-34,
                0.034145668148994446,
                -0.02408108115196228,
                0.05412017181515694,
                0.004681621678173542,
                -0.047418951988220215,
                0.027195710688829422,
                0.04924008622765541,
                -0.051856689155101776,
                0.034338969737291336,
                -0.013011885806918144,
                -0.039432164281606674,
                -0.0037013674154877663,
                0.015113970264792442,
                -0.06175488606095314,
                0.006908487994223833,
                -0.04687563702464104,
                -0.01741066575050354,
                3.453184763202444e-05,
                -0.001357586937956512,
                -0.13407853245735168,
                0.031620293855667114,
                0.03190965950489044,
                -0.05893082544207573,
                0.012800833210349083,
                0.032090235501527786,
                -0.014164227060973644,
                -0.08830603957176208,
                -0.007184209767729044,
                -0.010743802413344383,
                -0.05990607663989067,
                -0.020563026890158653,
                -0.008374347351491451,
                -0.024971667677164078,
                -0.011279928497970104,
                -0.020819544792175293,
                0.012816046364605427,
                -0.028639215975999832,
                -0.03674187883734703,
                -0.00872455257922411,
                -0.0326157808303833,
                -0.01894375868141651,
                -0.015870044007897377,
                0.01709236204624176,
                0.02473404072225094,
                -0.0034311392810195684,
                1.4313467318061157e-06,
                -0.021320423111319542,
                0.005122646689414978,
                0.02328437939286232,
                0.0081275450065732,
                0.035742152482271194,
                0.04001181200146675,
                0.011766483075916767,
                -0.01311489474028349,
                -0.05919181928038597,
                0.014945661649107933,
                0.041127949953079224,
                0.0173775814473629,
                0.01790674217045307,
                -0.012579226866364479,
                -0.01479406002908945,
                0.0047389850951731205,
                -0.04578649625182152,
                0.01999880000948906,
                0.005731426645070314,
                0.011698474176228046,
                0.023934872820973396,
                0.0001774644188117236,
                0.01909530535340309,
                0.04848776385188103,
                0.04015851020812988,
                0.010017594322562218,
                0.0011659786105155945,
                0.05151694267988205,
                -0.03333691507577896,
                0.08714747428894043,
                0.0005787068512290716,
                0.008467262610793114,
                -0.02331341616809368,
                -0.008817323483526707,
                0.041717033833265305,
                0.03879985585808754,
                -0.0025316064711660147,
                -0.013890945352613926,
                0.06484169512987137,
                -0.05482374131679535,
                -0.019409090280532837,
                -0.06444457918405533,
                0.03130156919360161,
                -0.010521044954657555,
                -0.010694651864469051,
                0.015184453688561916,
                -0.01126785483211279,
                -0.044264599680900574,
                -0.004586459137499332,
                0.03341175615787506,
                -0.014401081949472427,
                0.048728954046964645,
                -0.008938265033066273,
                -0.02460869960486889,
                0.017150381579995155,
                0.02602412737905979,
                -0.012260327115654945,
                0.0023837198968976736,
                0.053671397268772125,
                0.0328579843044281,
                -0.04603958874940872,
                -0.0010417315643280745,
                0.06901933252811432,
                0.0165137629956007,
                0.027152124792337418,
                0.05469786375761032,
                0.008977960795164108,
                0.04047934338450432,
                0.013793478719890118,
                0.03493060916662216,
                -0.047298941761255264,
                0.020557045936584473,
                0.002215734915807843,
                -0.034061651676893234,
                0.07781296968460083,
                -0.011848809197545052,
                -0.02069440484046936,
                -0.037947218865156174,
                0.06420225650072098,
                0.044822968542575836,
                0.026123592630028725,
                0.0073289512656629086,
                0.014971619471907616,
                0.01893315277993679,
                -0.006772765424102545,
                -0.024542946368455887,
                -0.03794188052415848,
                -0.04118940606713295,
                0.0015158295864239335,
                -0.0010458503384143114,
                -0.0724426880478859,
                -0.055416420102119446,
                0.022607535123825073,
                -0.033159323036670685,
                -0.05000967159867287,
                -0.017902646213769913,
                -0.010387564077973366,
                -0.017063625156879425,
                -0.0023594966623932123,
                -0.019535265862941742,
                0.013788186945021152,
                -0.028171822428703308,
                0.06326182186603546,
                -0.006641186308115721,
                -0.017368992790579796,
                0.05797838419675827,
                -0.08134086430072784,
                -0.03077489696443081,
                -0.05699456110596657,
                -0.03815102577209473,
                -0.026850635185837746,
                -0.0005258108722046018,
                0.04775131493806839,
                0.007783317007124424,
                -0.005587839055806398,
                0.04418426379561424,
                0.005916041322052479,
                -0.020614823326468468,
                0.00888870470225811,
                -0.013147236779332161,
                0.057782962918281555,
                0.03913583979010582,
                -0.0025247102603316307,
                -0.038132499903440475,
                -0.019121339544653893,
                -0.05505739897489548,
                -0.0292573980987072,
                0.056303299963474274,
                -0.03400711342692375,
                -0.009517849422991276,
                0.040781185030937195,
                0.026358217000961304,
                -0.03061954863369465,
                0.006704032886773348,
                0.0070246923714876175,
                0.01054652500897646,
                0.06803496181964874,
                -0.04190574213862419,
                0.027196170762181282,
                -0.022098982706665993,
                0.02222423255443573,
                -0.020429836586117744,
                0.016970738768577576,
                0.04900572821497917,
                0.0394904799759388,
                0.07792271673679352,
                0.041015129536390305,
                0.012501506134867668,
                -0.00016928420518524945,
                -0.0564638152718544,
                -0.0036838797386735678,
                -0.10914517194032669,
                0.010629883967339993,
                -0.04214269667863846,
                -0.04855736717581749,
                -0.00766441086307168,
                0.034057799726724625,
                -0.01719016022980213,
                -0.03290623798966408,
                0.035339780151844025,
                0.048258569091558456,
                0.048628583550453186,
                -0.10533711314201355,
                0.004442474339157343,
                0.010548895224928856,
                -0.01146862655878067,
                0.011021167039871216,
                0.07658632099628448,
                0.051506150513887405,
                -0.01578817516565323,
                -0.022451989352703094,
                0.020461788401007652,
                0.008441895246505737,
                -0.012973461300134659,
                0.022861283272504807,
                0.04300498962402344,
                -0.0038430332206189632,
                0.03734742850065231,
                0.004432459361851215,
                -0.017706505954265594,
                -0.03840014338493347,
                0.031064361333847046,
                -0.004672908689826727,
                -0.05884307995438576,
                0.013219719752669334,
                -0.03203620761632919,
                -0.0036084880121052265,
                -0.058627594262361526,
                0.023315967991948128,
                -0.010608258657157421,
                -0.05030869320034981,
                0.0034026254434138536,
                -0.0052194744348526,
                -0.002436336362734437,
                0.021145779639482498,
                -0.013797702267765999,
                0.011116841807961464,
                0.03331923857331276,
                0.011314786970615387,
                0.02435161918401718,
                -0.022724812850356102,
                0.009811020456254482,
                0.06239955499768257,
                0.03447623923420906,
                -0.09748817980289459,
                0.009556491859257221,
                -0.07690925896167755,
                0.01632818579673767,
                -0.004600441083312035,
                0.09385818243026733,
                -0.03256048634648323,
                0.0062922085635364056,
                0.00158713071141392,
                0.05602114647626877,
                0.0005360085051506758,
                0.01867019571363926,
                -0.003988179378211498,
                -0.024142445996403694,
                0.0016715829260647297,
                0.0423772931098938,
                0.07197535037994385,
                0.0119093619287014,
                0.00152937276288867,
                -0.050787609070539474,
                -0.004503768403083086,
                0.04587392136454582,
                -0.0739029198884964,
                -0.056343890726566315,
                -0.011329293251037598,
                0.039932411164045334,
                -0.013993826694786549,
                0.034025248140096664,
                -0.014948934316635132,
                -0.07632581144571304,
                -0.008298352360725403,
                -0.020342398434877396,
                0.004850609228014946,
                0.000451145984698087,
                0.0014980252599343657,
                0.01940229721367359,
                0.026415228843688965,
                0.035495854914188385,
                0.0034916072618216276,
                -0.026539135724306107,
                0.014193637296557426,
                0.022344280034303665,
                -0.06642886996269226,
                -0.02245262637734413,
                -0.00839197263121605,
                -0.0024755632039159536,
                -1.0255204870190937e-05,
                0.04365090653300285,
                0.010478013195097446,
                -0.020699195563793182,
                -0.0241676177829504,
                0.013896006159484386,
                0.006719420664012432,
                -0.006889989599585533,
                -0.02029133215546608,
                0.010906078852713108,
                -0.04614342749118805,
                0.047306858003139496,
                -0.01335575245320797,
                -0.026825161650776863,
                0.07320930063724518,
                -0.0363757461309433,
                -0.027633823454380035,
                -0.019563129171729088,
                -0.009557513520121574,
                0.016323160380125046,
                0.003919070586562157,
                0.03103845752775669,
                -0.07147292047739029,
                0.003298060270026326,
                0.09030675143003464,
                0.0064231776632368565,
                -0.006532728206366301,
                0.019305163994431496,
                0.06759224832057953,
                0.022517330944538116,
                0.014958921819925308,
                -0.014776909723877907,
                -0.015187571756541729,
                0.03573128208518028,
                0.027184983715415,
                -0.02244001440703869,
                -0.03489234670996666,
                0.047570426017045975,
                -0.026317689567804337,
                0.009212152101099491,
                -0.026651032269001007,
                -0.04290251061320305,
                -0.01898539811372757,
                0.058149367570877075,
                0.024106692522764206,
                0.0020700516179203987,
                0.07671833038330078,
                0.014153893105685711,
                -0.009521358646452427,
                0.07025172561407089,
                0.0450584851205349,
                -0.011918335221707821,
                -0.04826382175087929,
                -0.028323743492364883,
                0.002403725404292345,
                -0.030124139040708542,
                -0.06071031093597412,
                -0.027989868074655533,
                0.027976734563708305,
                0.07068610936403275,
                0.019954247400164604,
                0.012857230380177498,
                -0.009924606420099735,
                0.03565791994333267,
                -0.0010249646147713065,
                0.03575458750128746,
                -0.034051358699798584,
                0.017414191737771034,
                -0.0006982475169934332,
                0.014655903913080692,
                0.05798147991299629,
                0.01892322301864624,
                0.018483640626072884,
                -0.032638031989336014,
                -0.0339558944106102,
                0.03718442842364311,
                -0.022079527378082275,
                0.03998717665672302,
                -0.01497784722596407,
                0.03349481150507927,
                0.036002639681100845,
                0.04601792246103287,
                0.027205070480704308,
                -0.0306929349899292,
                0.018543576821684837,
                0.011690713465213776,
                0.0186447910964489,
                -0.08031824231147766,
                -0.024653010070323944,
                -0.001977026229724288,
                -0.006466089282184839,
                0.02400592900812626,
                0.006779772229492664,
                0.04067835584282875,
                -0.003674738807603717,
                0.00481686694547534,
                -0.007325555197894573,
                0.09103579074144363,
                0.011472891084849834,
                -0.009480469860136509,
                -0.038391441106796265,
                -0.014256538823246956,
                0.0076232170686125755,
                -0.034595973789691925,
                0.062012944370508194,
                0.026219386607408524,
                -0.07886894792318344,
                -0.0800589844584465,
                0.011017903685569763,
                -0.028579536825418472,
                0.07815486937761307,
                -0.06237388774752617,
                0.02375802770256996,
                -0.038300130516290665,
                0.04684609919786453,
                0.019096992909908295,
                -0.0021397576201707125,
                -0.020405834540724754,
                -0.03950538486242294,
                0.008282899856567383,
                -0.02630743756890297,
                -0.03113739565014839,
                -0.008638637140393257,
                -0.03424571081995964,
                -0.018908128142356873,
                0.02400991879403591,
                0.03967953100800514,
                0.012019770219922066,
                -0.002218312118202448,
                -0.05692066624760628,
                0.02654002048075199,
                0.013021518476307392,
                -0.029548345133662224,
                0.022394774481654167,
                -0.02801072970032692,
                -0.013898530974984169,
                -0.0037304211873561144,
                0.006640717387199402,
                -0.022732635959982872,
                -0.011284072883427143,
                -0.04121515154838562,
                0.023015450686216354,
                0.04434477537870407,
                -0.036738406866788864,
                0.006744631100445986,
                0.012997918762266636,
                -0.026229310780763626,
                -0.07541166990995407,
                0.06697096675634384,
                -0.0691308006644249,
                -0.02984446845948696,
                -0.0664391741156578,
                -0.010014493018388748,
                -0.0069920457899570465,
                -0.035852812230587006,
                -0.016018420457839966,
                0.002703059697523713,
                0.1035708338022232,
                0.028510896489024162,
                -0.03423603996634483,
                -0.03198681026697159,
                -0.00032623339211568236,
                -0.0844254195690155,
                -0.005872104316949844,
                -0.013402346521615982,
                0.009877034462988377,
                0.017187748104333878,
                -0.09130823612213135,
                -0.04120814800262451,
                -0.057479653507471085,
                -0.022492889314889908,
                -0.03607115149497986,
                -0.04229969158768654,
                -0.005839591845870018,
                0.04142211377620697,
                0.11181484162807465,
                0.037077561020851135,
                0.026899047195911407,
                -0.04042733833193779,
                0.07770508527755737,
                -0.058757320046424866,
                -0.08087942004203796,
                -0.030916204676032066,
                -0.031268466264009476,
                -0.04419757425785065,
                -0.06275481730699539,
                0.03477899357676506,
                -0.019594410434365273,
                0.034726571291685104,
                0.014462445862591267,
                0.03334308788180351,
                -0.0043254815973341465,
                -0.030424939468503,
                -0.008248154073953629,
                0.04100656136870384,
                0.026437882333993912,
                0.03998371958732605,
                0.034741103649139404,
                0.02612229809165001,
                -0.0028902299236506224,
                0.02802087739109993,
                0.025011302903294563,
                -0.016219785436987877,
                0.009926116093993187,
                -0.0019863571505993605,
                0.029942411929368973,
                0.006444716360419989,
                -0.011907150968909264,
                -0.007855262607336044,
                0.034325744956731796,
                0.07000532746315002,
                0.06433034688234329,
                0.010736125521361828,
                0.04425722733139992,
                0.01688433438539505,
                0.00798227172344923,
                0.012212307192385197,
                -0.0032613463699817657,
                0.06925413012504578,
                -0.03699920326471329,
                0.062046319246292114,
                0.040506474673748016,
                -0.0279533751308918,
                0.0032291957177221775,
                0.01567123830318451,
                -0.017987797036767006,
                -0.026894206181168556,
                -9.825073357205838e-05,
                -0.06335514038801193,
                -0.05548768863081932,
                -0.008804408833384514,
                0.03272661194205284,
                -0.0016498287441208959,
                -0.05088981240987778,
                0.0011459703091531992,
                0.030836893245577812,
                0.0022812793031334877,
                -0.010622075758874416,
                -0.032924309372901917,
                -0.024874715134501457,
                -0.024382460862398148,
                0.01607215590775013,
                0.00692053372040391,
                -0.04095658287405968,
                0.028862930834293365,
                -0.028423544019460678,
                -0.031201649457216263,
                -0.01056737918406725,
                0.055706750601530075,
                -0.004895787686109543,
                0.0421629436314106,
                0.012393467128276825,
                0.0659610852599144,
                0.03976595774292946,
                0.011869724839925766,
                -0.02457837574183941,
                -0.016370389610528946,
                -0.024743761867284775,
                -0.008027606643736362,
                0.01421693991869688,
                0.010657194070518017,
                0.05455373227596283,
                0.00390240247361362,
                0.010635611601173878,
                0.00840673502534628,
                -0.0002523966250009835,
                0.022185761481523514,
                0.01978246495127678,
                -0.027539584785699844,
                -0.01329865213483572,
                -5.8533822014432425e-33,
                -0.04623814672231674,
                0.003505513072013855,
                -0.0036869493778795004,
                0.07012975960969925,
                -0.051402751356363297,
                -0.0661080852150917,
                0.025943012908101082,
                0.007116215769201517,
                -0.026576682925224304,
                -0.009901049546897411,
                0.0011146742617711425,
                0.007113731931895018,
                0.002392204012721777,
                0.0120905926451087,
                0.03315639868378639,
                0.015783162787556648,
                0.02944147028028965,
                0.02879144996404648,
                -0.05155400559306145,
                -0.05566267669200897,
                -0.08172343671321869,
                0.007881321012973785,
                0.05855340510606766,
                -0.028778953477740288,
                0.000834931677673012,
                -0.019505510106682777,
                0.0076425569131970406,
                -0.03576769307255745,
                -0.029144195839762688,
                -0.0441497378051281,
                -0.02118675783276558,
                0.007317816372960806,
                -0.011675459332764149,
                0.03589095175266266,
                0.008997044526040554,
                0.021891172975301743,
                -0.0681554526090622,
                -0.07189387828111649,
                0.022232862189412117,
                0.03218158707022667,
                0.014825328253209591,
                -0.11470696330070496,
                -0.030708523467183113,
                -0.05360585078597069,
                -0.032308679074048996,
                -0.003076924942433834,
                0.002753450768068433,
                0.010754882358014584,
                0.062148239463567734,
                -0.04597852751612663,
                -0.06009308993816376,
                -0.02201589196920395,
                -0.04129766300320625,
                0.031958840787410736,
                -0.04117461293935776,
                -0.03905806317925453,
                0.0018631108105182648,
                -0.06522523611783981,
                -0.0019524692324921489,
                0.05044799670577049,
                0.04146118462085724,
                0.008798387832939625,
                -0.02644413523375988,
                -0.04165903106331825,
                0.024178393185138702,
                0.015662474557757378,
                0.06216757372021675,
                0.04329345375299454,
                -0.01377992145717144,
                0.03098500706255436,
                -0.015809260308742523,
                0.019556542858481407,
                -0.04752083122730255,
                -0.036998093128204346,
                0.045558732002973557,
                0.0641576275229454,
                -0.008430730551481247,
                -0.008362645283341408,
                0.07085024565458298,
                0.07109889388084412,
                -0.03749698027968407,
                0.022695953026413918,
                -0.009426355361938477,
                -0.03958535194396973,
                0.008942922577261925,
                -0.036169059574604034,
                0.0055930581875145435,
                0.011243722401559353,
                -0.02031523734331131,
                -0.022080352529883385,
                0.031300246715545654,
                -0.009983975440263748,
                -0.02786564640700817,
                0.009531513787806034,
                0.0008291823323816061,
                -0.02278539352118969,
                0.04931017383933067,
                0.04984964802861214,
                0.020937733352184296,
                0.014834127388894558,
                -0.03991697356104851,
                -0.001069625373929739,
                -0.05954683572053909,
                -0.003627799218520522,
                0.0129468385130167,
                0.044502902776002884,
                -0.044234540313482285,
                0.0564890056848526,
                0.03499068692326546,
                -0.013173257000744343,
                0.002868795767426491,
                -0.03722141310572624,
                0.03294854238629341,
                0.045471105724573135,
                0.03156145289540291,
                -0.007764996960759163,
                -0.01007917057722807,
                -0.010213658213615417,
                -0.015282944776117802,
                0.014564994722604752,
                -0.01805904507637024,
                0.015114977955818176,
                -0.017669206485152245,
                0.018729686737060547,
                0.06552889943122864,
                -0.03613131120800972,
                -0.02324136160314083,
                -0.03808198869228363,
                -0.0034315469674766064,
                -0.005723481997847557,
                -0.031094469130039215,
                0.02428595721721649,
                2.233217344382865e-07,
                0.0014221977908164263,
                0.008775570429861546,
                0.04942522570490837,
                0.02890111319720745,
                -0.008466589264571667,
                -0.08021542429924011,
                -0.032668814063072205,
                0.03323221206665039,
                -0.024213289842009544,
                0.04284736514091492,
                0.07318906486034393,
                0.008414439857006073,
                -0.025224260985851288,
                -0.025401558727025986,
                -0.044225890189409256,
                -0.050034262239933014,
                0.04365970566868782,
                -0.02475750260055065,
                0.012918681837618351,
                -0.023843636736273766,
                -0.027608096599578857,
                0.03543209657073021,
                0.018600113689899445,
                0.017669305205345154,
                0.002390447538346052,
                0.029108893126249313,
                0.012395699508488178,
                -0.010866538621485233,
                0.06232355162501335,
                0.039524827152490616,
                -0.011607896536588669,
                -0.008682049810886383,
                0.0008167377091012895,
                -0.0020752535201609135,
                0.008100796490907669,
                -0.006006699521094561,
                0.058529648929834366,
                0.01960025355219841,
                0.019461676478385925,
                0.055135346949100494,
                -0.04724031314253807,
                -0.07269906252622604,
                0.005911806132644415,
                0.0221381988376379,
                0.02057194709777832,
                0.025214437395334244,
                0.03150944039225578,
                0.08462227880954742,
                -0.10390723496675491,
                -6.584307266166434e-05,
                -0.027596239000558853,
                -0.006155629176646471,
                -0.005816184915602207,
                -0.0056307255290448666,
                -0.006654410157352686,
                -0.014392055571079254,
                0.022573018446564674,
                0.040768373757600784,
                0.04700048640370369,
                0.07579100877046585,
                -0.03449959307909012,
                -0.005739101208746433,
                0.01911383680999279,
                -0.01581837236881256,
                0.06002135947346687,
                -0.017968719825148582,
                -0.03881295025348663,
                1.6741103558485525e-34,
                0.042208947241306305,
                -0.03194243460893631,
                0.029872814193367958,
                0.04872579500079155,
                -0.03312353789806366,
                -0.006956482771784067,
                -0.0323951430618763,
                -0.0224804375320673,
                0.03475550562143326,
                0.018590426072478294,
                -0.026345793157815933,
                0.04116611182689667,
                -0.005031062755733728,
                -0.05093121528625488,
                0.012422843836247921,
                0.010867328383028507,
                0.06284096837043762,
                -0.042592838406562805,
                -0.028542686253786087,
                -0.02761407010257244,
                0.023098479956388474,
                0.06382384151220322,
                0.010822718963027,
                0.03916795551776886,
                0.08358152955770493,
                0.008632516488432884,
                -0.06822612881660461,
                0.04787711799144745,
                -0.02788507007062435,
                -0.05285138636827469,
                -0.002769747283309698,
                -0.010317242704331875,
                0.03234555199742317,
                -0.029554473236203194,
                -0.0023720383178442717,
                -0.01247328333556652,
                -0.05274760350584984,
                -0.02546151913702488,
                -0.03548287972807884,
                -0.02378072403371334,
                0.07091763615608215,
                -0.01821587234735489,
                -0.019800031557679176,
                0.014674235135316849,
                -0.008992759510874748,
                1.745319195833872e-06,
                -0.01819373108446598,
                -0.014538083225488663,
                0.013218877837061882,
                -0.01615850254893303,
                0.015519504435360432,
                0.02350686490535736,
                0.040816955268383026,
                -0.021497955545783043,
                0.044735100120306015,
                -0.018398122861981392,
                -0.026035750284790993,
                0.07563774287700653,
                -0.08483407646417618,
                0.020192328840494156,
                0.02078304812312126,
                0.007596279960125685,
                0.0037156296893954277,
                -0.030643394216895103,
                -0.0074392459355294704,
                0.0019722923170775175,
                -0.01647626981139183,
                0.0004220535629428923,
                0.03854045271873474,
                0.09984932094812393,
                0.025460941717028618,
                0.00662009883671999,
                -0.03888444975018501,
                0.006163341924548149,
                0.012450200505554676,
                0.03023524023592472,
                0.01586197502911091,
                -0.02536405622959137,
                -0.025103578343987465,
                0.009638614021241665,
                -0.014873737469315529,
                0.01986120454967022,
                -0.001048633479513228,
                0.015647565945982933,
                0.06834392249584198,
                -0.04079733043909073,
                -0.061532724648714066,
                -0.02641117200255394,
                0.033023808151483536,
                0.00875790324062109,
                -0.034689243882894516,
                -0.0013950415886938572,
                -0.04787444695830345,
                -0.029047919437289238,
                -0.00546054495498538,
                0.03575977683067322,
                0.022544045001268387,
                -0.005298995412886143,
                -0.04737594723701477,
                -0.03987370431423187,
                0.01526729203760624,
                0.0010922730434685946,
                -0.02430824004113674,
                0.016642747446894646,
                0.05203067883849144,
                0.023637529462575912,
                -0.001552437897771597,
                -0.020588599145412445,
                0.06168070062994957,
                -0.011273100972175598,
                -0.00026056013302877545,
                -0.017040450125932693,
                0.006440190132707357,
                0.01950678788125515,
                0.034857213497161865,
                0.025044094771146774,
                -0.003424386028200388,
                0.0015802942216396332,
                0.01479082740843296,
                -0.02276424877345562,
                0.07932671159505844,
                -0.07398096472024918,
                -0.0399586483836174,
                -0.051489461213350296,
                0.01465650089085102,
                0.05491726100444794,
                0.028909457847476006,
                -0.020281599834561348,
                0.03946401923894882,
                0.003947432152926922,
                0.038129549473524094,
                -0.06825294345617294,
                -0.01680676080286503,
                -0.006124102510511875,
                0.042683787643909454,
                0.014184252358973026,
                -0.03888235613703728,
                -0.037348080426454544,
                -0.014478255994617939,
                -0.004506199155002832,
                -0.013887112028896809,
                -0.0014301028568297625,
                -0.009186513721942902,
                -0.034888360649347305,
                -0.029846690595149994,
                0.011592723429203033,
                0.056661538779735565,
                -0.05525965988636017,
                0.07347160577774048,
                -0.03929857909679413,
                -0.02854253351688385,
                0.013205548748373985,
                -0.038880638778209686,
                -0.013333318755030632,
                -0.0693715363740921,
                -0.024912036955356598,
                0.03253187611699104,
                0.005244947504252195,
                0.01718798093497753,
                0.003852050518617034,
                0.03466770797967911,
                -0.018432950600981712,
                0.026233237236738205,
                -0.05758173391222954,
                -0.019169988110661507,
                0.08534456044435501,
                0.022762887179851532,
                0.01370440237224102,
                -0.023538863286376,
                -0.017565200105309486,
                -0.0050742169842123985,
                -0.0029000246431678534,
                -0.026758674532175064,
                0.08709818869829178,
                -0.022552430629730225,
                -0.027638163417577744,
                -0.014589200727641582,
                -0.00032460171496495605,
                0.018183397129178047,
                0.0090918755158782,
                -0.018427634611725807,
                -0.030446572229266167,
                -0.008275782689452171,
                -0.030733542516827583,
                0.055189575999975204,
                0.0073440526612102985,
                0.0016611445462331176,
                0.019148623570799828,
                0.04979358986020088,
                0.013089258223772049,
                -0.013978318311274052,
                0.0071048494428396225,
                -0.07630279660224915,
                -0.018125221133232117,
                0.0649445503950119,
                -0.011022234335541725,
                0.029162758961319923,
                -0.05453217402100563,
                0.03017202764749527,
                -0.018439538776874542,
                -0.024473566561937332,
                0.03206801414489746,
                0.0024090020451694727,
                -0.012937314808368683,
                -0.00300060142762959,
                0.0414467416703701,
                0.004327225964516401,
                0.019577346742153168,
                0.007026804145425558,
                -0.017727119848132133,
                -0.05856155604124069,
                -0.04000305384397507,
                -0.04332743212580681,
                -0.03108259104192257,
                0.1023954525589943,
                -0.004256566986441612,
                -0.05677761137485504,
                0.025771284475922585,
                -0.007785128895193338,
                0.020276637747883797,
                -0.042604487389326096,
                0.0050813318230211735,
                0.009736643172800541,
                -0.024934493005275726,
                0.016636019572615623,
                -0.0028534068260341883,
                -0.01974869705736637,
                0.004634397104382515,
                0.035279639065265656,
                7.506419933633879e-05,
                0.0053945267572999,
                -0.035909224301576614,
                0.021414978429675102,
                0.019146086648106575,
                0.014985799789428711,
                -0.03636394813656807,
                -0.09483655542135239,
                -0.03683333471417427,
                0.02011018432676792,
                -0.05059250444173813,
                -0.0029344975482672453,
                -0.023572009056806564,
                0.0365419015288353,
                0.04625045508146286,
                0.01017048116773367,
                0.03814753517508507,
                -0.032267894595861435,
                0.022103160619735718,
                -0.00319674052298069,
                0.04464387893676758,
                -0.06326019018888474,
                -0.044924236834049225,
                -0.012361162342131138,
                0.030856462195515633,
                0.02364473231136799,
                0.1043463945388794,
                -0.006588328629732132,
                -0.003937187138944864,
                0.029853837564587593,
                0.018059048801660538,
                -0.05042724683880806,
                -0.07261829078197479,
                0.019842782989144325,
                0.03778736665844917,
                0.04127829149365425,
                0.08127486705780029,
                0.023157017305493355,
                0.003648428712040186,
                0.01801695115864277,
                -0.05979713797569275,
                -0.030160561203956604,
                -0.0215165838599205,
                -0.046195778995752335,
                0.006834619678556919,
                -0.036846987903118134,
                0.06270243227481842,
                0.009709070436656475,
                -0.005500562954694033,
                -0.05213071405887604,
                -0.06959085911512375,
                -0.0037816138938069344,
                -0.017059575766324997,
                0.011516205966472626,
                -0.006608293391764164,
                -0.008694402873516083,
                -0.02439871057868004,
                0.010034700855612755,
                -0.016615591943264008,
                -0.08079294860363007,
                -0.03381560370326042,
                0.02759825997054577,
                0.003206222550943494,
                -0.04360884055495262,
                -0.044448625296354294,
                -0.05242534726858139,
                -0.003920382354408503,
                0.010638095438480377,
                0.030231811106204987,
                -0.06242423877120018,
                -0.037508342415094376,
                0.039977118372917175,
                -0.03338272497057915,
                -0.0012949066003784537,
                0.0036014560610055923,
                -0.023757420480251312,
                0.002897667931392789,
                -0.04146699234843254,
                -0.009362731128931046,
                -0.005321154370903969,
                -0.02433967776596546,
                0.033033810555934906,
                0.01848788931965828,
                -0.004064759239554405,
                0.04770040884613991,
                -0.021271998062729836,
                0.006223278120160103,
                -0.011661270633339882,
                0.07850199192762375,
                -0.045213889330625534,
                -0.012619195505976677,
                0.06685592234134674,
                0.04619203135371208,
                0.02203284576535225,
                0.01663530059158802,
                0.040850576013326645,
                0.01099957525730133,
                0.02322363294661045,
                -0.05507948622107506,
                -0.0003920646267943084,
                -0.005684795323759317,
                0.07539614289999008,
                -0.021219119429588318,
                -0.02459547109901905,
                0.061591774225234985,
                -0.02093946933746338,
                0.01450484711676836,
                0.0007233432261273265,
                -0.046217650175094604,
                0.006252145394682884,
                0.04327218979597092,
                -0.04382086172699928,
                0.016285886988043785,
                -0.02197224274277687,
                -0.054500117897987366,
                0.02300339937210083,
                -0.04213417321443558,
                0.06305823475122452,
                -0.04468519613146782,
                -0.022369351238012314,
                -0.041720353066921234,
                0.03134577348828316,
                -0.01071993913501501,
                -0.030650654807686806,
                -0.0002448403974995017,
                0.022757638245821,
                -0.0036180815659463406,
                -0.013043402694165707,
                0.04999421536922455,
                0.03655952587723732,
                -0.005466416012495756,
                -0.030078470706939697,
                0.059935133904218674,
                0.0615682378411293,
                0.03034735843539238,
                -0.020712537690997124,
                -0.004536191467195749,
                -0.021799126639962196,
                -0.06504261493682861,
                -0.036247000098228455,
                -0.05937080830335617,
                -0.04975138232111931,
                -0.02005791664123535,
                -0.002402989659458399,
                0.026852242648601532,
                0.03791369870305061,
                -0.022899549454450607,
                0.040926482528448105,
                0.056251268833875656,
                -0.0034098417963832617,
                -0.05004093423485756,
                0.018794164061546326,
                0.027248844504356384,
                0.04121651500463486,
                -0.04777452349662781,
                0.006344239227473736,
                0.035824622958898544,
                0.01633443683385849,
                0.03350945934653282,
                0.01611650176346302,
                -0.07611825317144394,
                0.03153113275766373,
                0.047340720891952515,
                -0.10717855393886566,
                -0.0011379705974832177,
                -0.006201907992362976,
                0.0440935455262661,
                -0.0347936637699604,
                0.008006039075553417,
                0.018156301230192184,
                -0.020541658625006676,
                0.04503418877720833,
                0.01484132930636406,
                -0.023976972326636314,
                -0.06789854913949966,
                -0.059046123176813126,
                0.014082332141697407,
                0.012940510176122189,
                -0.007562147919088602,
                0.05597155913710594,
                -0.02995866723358631,
                0.033149611204862595,
                -0.0029855782631784678,
                -0.013438129797577858,
                0.014666244387626648,
                0.04239777848124504,
                0.054677460342645645,
                -0.026108693331480026,
                0.09486174583435059,
                -0.023930810391902924,
                -0.023532558232545853,
                -0.004935692995786667,
                0.033064454793930054,
                0.025686923414468765,
                0.03895876184105873,
                -0.04119286313652992,
                -0.010980664752423763,
                -0.0057327342219650745,
                -0.04409823939204216,
                0.0051926858723163605,
                -0.0008772471337579191,
                -0.05974047631025314,
                -0.02300027571618557,
                0.004978231154382229,
                0.030889201909303665,
                -0.017157118767499924,
                0.026688890531659126,
                0.0006162506178952754,
                0.006251879036426544,
                0.045270681381225586,
                0.0036424282006919384,
                -0.02275543473660946,
                -0.04233400523662567,
                -0.009092103689908981,
                -0.08496411144733429,
                0.011922478675842285,
                0.013704784214496613,
                -0.058739542961120605,
                -0.06827838718891144,
                0.02126176469027996,
                0.01764875091612339,
                -0.03647395595908165,
                0.05504525825381279,
                0.07566355168819427,
                0.02669546939432621,
                -0.001228907029144466,
                -0.08735215663909912,
                0.08635186403989792,
                0.044615715742111206,
                -0.1071571558713913,
                -0.08343853801488876,
                0.03632484748959541,
                0.07956259697675705,
                -0.031785473227500916,
                -0.03903880715370178,
                -0.04797978326678276,
                -0.011182503774762154,
                -0.0349428728222847,
                -0.0075700730085372925,
                -0.004576453473418951,
                -0.013621898368000984,
                0.00615599425509572,
                0.06355030834674835,
                0.03410696983337402,
                -0.06099306046962738,
                -0.0064410981722176075,
                0.03512294963002205,
                -0.1227937862277031,
                0.04400978237390518,
                -0.02759850025177002,
                0.04255499690771103,
                -0.04740668088197708,
                -0.020983032882213593,
                0.03295615687966347,
                -0.05799080803990364,
                -0.04024241492152214,
                -0.0453995056450367,
                0.037800952792167664,
                -0.01254996657371521,
                -0.06158863380551338,
                0.031048420816659927,
                0.0028672160115092993,
                0.005738923791795969,
                -0.03753761574625969,
                0.02511785365641117,
                -0.0014825804391875863,
                -0.0034956266172230244,
                0.009154988452792168,
                0.017566794529557228,
                -0.01673976145684719,
                -0.0031239769887179136,
                -0.009966185316443443,
                0.004448646679520607,
                0.016370607540011406,
                0.03565211221575737,
                -0.03965829685330391,
                -0.012938490137457848,
                0.00031814389512874186,
                -0.02919997274875641,
                0.04088401794433594,
                0.005024997983127832,
                0.03809539973735809,
                0.02658192813396454,
                0.008513371460139751,
                -0.04301908612251282,
                0.01412458810955286,
                -0.02645840123295784,
                0.028752649202942848,
                0.019804418087005615,
                0.03897693380713463,
                -0.010099154897034168,
                0.045281101018190384,
                -0.02074907347559929,
                -0.004076206590980291,
                -0.018468737602233887,
                -0.08062449842691422,
                0.05381738394498825,
                0.06175834685564041,
                0.028008168563246727,
                -0.024570273235440254,
                -0.05731053277850151,
                -0.009219483472406864,
                0.011786606162786484,
                -0.022297097370028496,
                0.001051058410666883,
                -0.0007363996119238436,
                0.008572082035243511,
                -0.07720312476158142,
                0.0546693429350853,
                0.004355160985141993,
                -0.03878531977534294,
                0.08059415221214294,
                -0.04981038346886635,
                0.01342718955129385,
                -0.008413022384047508,
                -0.028365394100546837,
                -0.021368853747844696,
                0.07738807052373886,
                0.007805320899933577,
                0.013165293261408806,
                0.004920943174511194,
                0.015083770267665386,
                0.0188926849514246,
                -0.01989588886499405,
                -0.05705598369240761,
                0.029015682637691498,
                0.01874307170510292,
                0.004567728843539953,
                0.043809227645397186,
                0.039814725518226624,
                0.008334824815392494,
                0.06082065403461456,
                -0.028005974367260933,
                -0.002510328544303775,
                0.026758788153529167,
                0.011857748962938786,
                0.018741639330983162,
                -6.535055783588903e-33,
                -0.021765684708952904,
                -0.031765587627887726,
                0.008898875676095486,
                0.056844450533390045,
                0.001877133152447641,
                -0.02787458710372448,
                0.020946769043803215,
                0.05867261812090874,
                -0.026228200644254684,
                -0.029884114861488342,
                -0.0032003126107156277,
                -0.013005462475121021,
                0.033312246203422546,
                -0.010969629511237144,
                0.02950507588684559,
                -0.007192197255790234,
                0.01992103084921837,
                0.05060361325740814,
                -0.03132672980427742,
                -0.03539600595831871,
                0.025552723556756973,
                -0.005999767687171698,
                0.02194143645465374,
                0.004494699649512768,
                0.003158342558890581,
                -0.008759275078773499,
                -0.015902811661362648,
                0.011480050161480904,
                0.03291819989681244,
                -0.012630672194063663,
                0.02539028227329254,
                0.011207416653633118,
                -0.027562804520130157,
                0.020350299775600433,
                -0.00862384308129549,
                0.024580184370279312,
                -0.05853191763162613,
                -0.04249076545238495,
                0.005498263984918594,
                0.029451321810483932,
                0.00714042829349637,
                -0.026869244873523712,
                0.05382651835680008,
                -0.020842067897319794,
                -0.01508590579032898,
                -0.022955412045121193,
                0.025417283177375793,
                -0.030484767630696297,
                0.06922908127307892,
                0.024390069767832756,
                -0.05661715567111969,
                -0.015024248510599136,
                -0.0638057217001915,
                -0.028905276209115982,
                0.01818947121500969,
                -0.06810054928064346,
                -0.0034528234973549843,
                -0.03958399221301079,
                0.0012250399449840188,
                0.045812301337718964,
                0.03246992453932762,
                0.034603118896484375,
                0.03110012598335743,
                0.0075684660114347935,
                0.002110360423102975,
                -0.012262203730642796,
                0.010810477659106255,
                0.022630790248513222,
                0.006608161143958569,
                0.052484285086393356,
                -0.024546809494495392,
                0.018609710037708282,
                0.006930041592568159,
                -0.010666548274457455,
                0.030519457533955574,
                -0.01857537403702736,
                0.002965187653899193,
                -0.026692036539316177,
                0.033999837934970856,
                -0.06766694784164429,
                -0.04218188300728798,
                -0.0012337126536294818,
                0.0073676807805895805,
                -0.040110792964696884,
                0.020750269293785095,
                0.0062780012376606464,
                -0.03151313215494156,
                0.009466399438679218,
                -0.007989813573658466,
                0.03273847699165344,
                -0.012341879308223724,
                0.012709894217550755,
                -0.053178753703832626,
                -0.007996146567165852,
                0.014520896598696709,
                0.007516003679484129,
                -0.0002990345819853246,
                0.026122551411390305,
                -0.027852464467287064,
                -0.01095323171466589,
                -0.020905984565615654,
                -0.013420652598142624,
                -0.044337764382362366,
                -0.003321563359349966,
                0.05192461237311363,
                0.018761372193694115,
                -0.045602947473526,
                0.03985948488116264,
                -0.02129346691071987,
                -0.015596864745020866,
                0.03560139611363411,
                0.04275698587298393,
                0.016515465453267097,
                0.06290329992771149,
                0.02752760611474514,
                -0.029355378821492195,
                0.012276728637516499,
                -0.06136235594749451,
                -0.043851498514413834,
                -0.027534926310181618,
                -0.03200928494334221,
                -8.194005204131827e-05,
                -0.06459398567676544,
                0.017546026036143303,
                -0.032658305019140244,
                -0.053304847329854965,
                -0.0446833074092865,
                0.007421818096190691,
                -0.002072829520329833,
                -0.03889811411499977,
                -0.037508584558963776,
                0.046078164130449295,
                2.3252491132552677e-07,
                0.013460073620080948,
                0.03435589373111725,
                0.04005187749862671,
                0.07001034915447235,
                -0.012130778282880783,
                -0.010421698912978172,
                -0.027987288311123848,
                0.0353890061378479,
                0.03732383996248245,
                -0.0046231490559875965,
                -0.02924051694571972,
                0.009694150649011135,
                0.005865880753844976,
                -0.0054031722247600555,
                0.011403106153011322,
                0.01725054159760475,
                0.0009793166536837816,
                0.037201765924692154,
                -0.028129691258072853,
                0.020014885812997818,
                0.09789008647203445,
                0.03520926088094711,
                0.007556522265076637,
                -0.006675571668893099,
                0.01953568309545517,
                -0.06392030417919159,
                0.021832693368196487,
                0.01577807031571865,
                0.07228095084428787,
                0.0033872846979647875,
                -0.09229370206594467,
                0.011781643144786358,
                0.003801588434726,
                0.05625750869512558,
                -0.007256637327373028,
                -0.05903063714504242,
                0.037991706281900406,
                0.04226537048816681,
                0.01666014827787876,
                0.09793208539485931,
                0.022833729162812233,
                -0.0328638032078743,
                -0.028805091977119446,
                0.010331236757338047,
                0.03384470194578171,
                0.021339217200875282,
                0.024803679436445236,
                0.04521036148071289,
                -0.020656628534197807,
                -0.0449397899210453,
                -0.050555270165205,
                -0.014335162937641144,
                0.05518397316336632,
                0.0056387255899608135,
                -0.0019612517207860947,
                -0.0015986711950972676,
                -0.04278487339615822,
                0.09504544734954834,
                -0.00928522553294897,
                0.038722358644008636,
                -0.015148072503507137,
                -0.06135477498173714,
                -0.018105126917362213,
                0.0492081381380558,
                0.07701031118631363,
                0.04147785156965256,
                0.013055343180894852,
                1.1550134280578727e-34,
                0.013181544840335846,
                -0.037429165095090866,
                0.02439919486641884,
                -0.02469414286315441,
                -0.014574418775737286,
                0.022445237264037132,
                -0.009183491580188274,
                -0.017843125388026237,
                -0.008597702719271183,
                0.043044209480285645,
                -0.027432698756456375,
                -0.026539525017142296,
                0.04217882826924324,
                -0.03540867939591408,
                -0.002780284034088254,
                0.018909482285380363,
                0.021415166556835175,
                0.0004226444289088249,
                0.013417091220617294,
                -0.05378938093781471,
                0.023133689537644386,
                0.04924942925572395,
                -0.020075716078281403,
                0.07060215622186661,
                0.08514893054962158,
                0.022953759878873825,
                -0.05369284376502037,
                0.022054918110370636,
                0.048542771488428116,
                -0.1472969502210617,
                0.0039190687239170074,
                0.005253227427601814,
                -0.028105953708291054,
                -0.005607851780951023,
                -0.004530403297394514,
                -0.00519721070304513,
                0.012150310911238194,
                -0.029517367482185364,
                0.0200785081833601,
                -0.006311079487204552,
                -0.051845621317625046,
                0.05027829855680466,
                0.04094333574175835,
                0.039837662130594254,
                0.014889040030539036,
                1.7544540469316416e-06,
                -0.014413908123970032,
                -0.015839461237192154,
                -0.004136768635362387,
                -0.07794758677482605,
                0.00840958021581173,
                0.02201075293123722,
                0.002382084494456649,
                0.016545157879590988,
                -0.007220636587589979,
                0.010368703864514828,
                -0.05067495256662369,
                0.04694930836558342,
                0.01357867568731308,
                -0.0007075475296005607,
                -0.020524531602859497,
                0.0030803747940808535,
                0.028423679992556572,
                0.004891541786491871,
                -0.03423354774713516,
                0.0995251014828682,
                0.08598847687244415,
                -0.0045211403630673885,
                0.06881600618362427,
                0.13524271547794342,
                0.04138234630227089,
                0.0003212295414414257,
                0.046530891209840775,
                0.011345034465193748,
                0.010600075125694275,
                0.053513675928115845,
                0.027161041274666786,
                -0.04881124943494797,
                -0.014676405116915703,
                0.03621189668774605,
                0.06673415750265121,
                0.09346877783536911,
                -0.0491226501762867,
                0.016024796292185783,
                0.012101825326681137,
                -0.03059258684515953,
                -0.026164323091506958,
                -0.07444783300161362,
                -0.02397749572992325,
                -0.011173193342983723,
                0.01776016876101494,
                -0.07718292623758316,
                -0.00529952347278595,
                -0.0036944434978067875,
                0.028540827333927155,
                0.06670159101486206,
                -0.00036081462167203426,
                -0.029914136976003647,
                -0.0219729021191597,
                0.03134062886238098,
                0.011685105971992016,
                0.024675846099853516,
                -0.012403042055666447,
                -0.04695278778672218,
                0.0026080289389938116,
                0.049084823578596115,
                -0.04729226604104042,
                0.0006978701567277312,
                0.03899313509464264,
                0.04154767468571663,
                -0.011886210180819035,
                0.038117095828056335,
                0.010133636184036732,
                -0.02905035950243473,
                0.04058350250124931,
                0.06297588348388672,
                -0.09799976646900177,
                0.00026594509836286306,
                -0.013359524309635162,
                0.0016660802066326141,
                0.041926637291908264,
                -0.020934486761689186,
                -0.02330997958779335,
                -0.0006230999133549631,
                0.04861854389309883,
                -0.0026285885833203793,
                0.010763203725218773,
                0.023129139095544815,
                -0.05930880084633827,
                0.031194578856229782,
                0.02379859983921051,
                0.037292372435331345,
                0.005694912746548653,
                -0.01316879503428936,
                -0.00024064676836133003,
                -0.008315137587487698,
                -0.015109655447304249,
                -0.04501445218920708,
                0.053786423057317734,
                0.021043576300144196,
                -0.08179265260696411,
                -0.012618932873010635,
                -0.015972763299942017,
                0.020888783037662506,
                -0.010769468732178211,
                0.04379298537969589,
                -0.015892304480075836,
                0.022877534851431847,
                -0.006206905469298363,
                -0.09006946533918381,
                -0.00783752091228962,
                0.06507862359285355,
                -0.03195783123373985,
                -0.010909626260399818,
                0.0010047715622931719,
                0.020659189671278,
                -0.0010394802084192634,
                -0.001134799444116652,
                0.013583410531282425,
                0.015486652962863445,
                -0.00413816561922431,
                -0.0022460001055151224,
                0.031200146302580833,
                -0.015654223039746284,
                0.004071165807545185,
                0.03235481679439545,
                0.05026254430413246,
                -0.0028153378516435623,
                -0.0025457623414695263,
                -0.02285183034837246,
                0.007222726475447416,
                -0.023542117327451706,
                -0.023802094161510468,
                0.04437548667192459,
                0.01384830567985773,
                0.014023283496499062,
                0.006876884028315544,
                -0.06518623977899551,
                0.012060492299497128,
                -0.06618928164243698,
                -0.005526569206267595,
                -0.06952275335788727,
                -0.017241409048438072,
                -0.04647647961974144,
                0.08873400092124939,
                0.05416397377848625,
                0.006041467655450106,
                0.02378981187939644,
                -0.04939700663089752,
                -0.08385494351387024,
                0.05689502879977226,
                0.06060316041111946,
                -0.15660984814167023,
                0.0039381724782288074,
                0.0476791188120842,
                0.03554911911487579,
                -0.006121195387095213,
                -0.06740234047174454,
                0.026513155549764633,
                -0.04827350378036499,
                -0.0367882177233696,
                -0.025307700037956238,
                -0.011947356164455414,
                -0.03763226419687271,
                0.025188030675053596,
                0.00200978247448802,
                -0.016692601144313812,
                0.06025693565607071,
                -0.017264585942029953,
                0.017900871112942696,
                -0.020552251487970352,
                -0.0075280736200511456,
                0.03354001045227051,
                0.014522964134812355,
                0.056452035903930664,
                -0.01578461192548275,
                -0.022939853370189667,
                -0.006179428659379482,
                -0.026647822931408882,
                0.035140715539455414,
                -0.00981244444847107,
                -0.031692083925008774,
                -0.009193920530378819,
                0.034960467368364334,
                -0.04014928266406059,
                -0.023918870836496353,
                0.011365469545125961,
                0.02885262295603752,
                0.009821771644055843,
                -0.033247195184230804,
                0.03368892893195152,
                0.007920699194073677,
                0.02804110199213028,
                0.003926900681108236,
                0.01468626782298088,
                -9.237283666152507e-05,
                0.014364887960255146,
                0.025011282414197922,
                0.016223078593611717,
                0.02389146201312542,
                0.03839241713285446,
                0.017809387296438217,
                -0.019984450191259384,
                -0.002671747701242566,
                0.045145995914936066,
                0.07317356020212173,
                -0.018323153257369995,
                0.11250681430101395,
                0.10128578543663025,
                -0.0012302292743697762,
                -0.035189684480428696,
                0.0158266332000494,
                0.010045190341770649,
                0.037437599152326584,
                -0.021068306639790535,
                -0.051746614277362823,
                -0.01332602184265852,
                -0.018764106556773186,
                0.006575146224349737,
                0.008181439712643623,
                0.03170359507203102,
                0.0015814492944628,
                0.02562888152897358,
                -0.006966689135879278,
                0.009731825441122055,
                0.01950908452272415,
                0.003975034225732088,
                -0.014174330048263073,
                0.004902435466647148,
                -0.03285510465502739,
                0.01337417308241129,
                0.012745446525514126,
                -0.06371986865997314,
                0.038377370685338974,
                -0.03720331937074661,
                -0.026969781145453453,
                -0.031833551824092865,
                0.09588528424501419,
                -0.020718974992632866,
                -0.02588103711605072,
                0.015893053263425827,
                -0.013052851893007755,
                0.005503633059561253,
                0.03731570765376091,
                -0.022380303591489792,
                -0.01159591693431139,
                0.004125398118048906,
                0.00354583328589797,
                -0.005718259606510401,
                -0.057421185076236725,
                0.01937713287770748,
                0.013992185704410076,
                0.013855140656232834,
                -0.047815315425395966,
                0.010580082423985004,
                0.022257044911384583,
                0.001376539352349937,
                0.0323006696999073,
                0.0009741057874634862,
                0.029814938083291054,
                -0.0954209491610527,
                -0.003198113990947604,
                0.018694257363677025,
                0.02505151368677616,
                -0.031078606843948364,
                -0.022330956533551216,
                -0.044879935681819916,
                -0.06103268638253212,
                0.018707912415266037,
                -0.04879003018140793,
                -0.01854749396443367,
                -0.019974570721387863,
                -0.04368632659316063,
                -0.04412080720067024,
                -0.012269391678273678,
                0.0015753243351355195,
                0.0760817900300026,
                0.042515579611063004,
                -0.014600633643567562,
                -0.001033725799061358,
                0.022222094237804413,
                0.0052877445705235004,
                0.02767179347574711,
                0.030070502310991287,
                0.03412728011608124,
                0.01085670106112957,
                -0.0020793781150132418,
                -0.0033147616777569056,
                0.03937484323978424,
                -0.028729449957609177,
                0.015370135195553303,
                0.008372297510504723,
                -0.0061904070898890495,
                -0.008723808452486992,
                -0.023218462243676186,
                -0.013989966362714767,
                -0.0026133887004107237,
                -0.06895604729652405,
                0.029688935726881027,
                0.02302074432373047,
                -0.027619292959570885,
                0.014234605245292187,
                -0.0053914631716907024,
                -0.019115298986434937,
                -0.010313025675714016,
                -0.012154605239629745,
                0.030690738931298256,
                -0.047501854598522186,
                -0.1109091117978096,
                -0.04533495754003525,
                -0.002106251660734415,
                -0.01504832785576582,
                -0.033787213265895844,
                -0.01441090926527977,
                0.019417831674218178,
                0.031895413994789124,
                -0.046698592603206635,
                0.002635420998558402,
                -0.012272744439542294,
                -0.032100699841976166,
                -0.01602928154170513,
                0.08078756928443909,
                0.06788124144077301,
                0.01498616673052311,
                0.01279980968683958,
                0.018155336380004883,
                0.009093619883060455,
                0.004788278602063656,
                -0.04211472347378731,
                -0.03560912609100342,
                -0.01419911254197359,
                -0.01810162141919136,
                -0.00201960327103734,
                7.286947220563889e-05,
                -0.019528403878211975,
                0.00569316279143095,
                0.04243648797273636,
                0.008230841718614101,
                0.037697989493608475,
                -0.060267124325037,
                0.014014221727848053,
                -0.015942338854074478,
                -0.0023858859203755856,
                -0.0015937337884679437,
                -0.013311775401234627,
                0.002408002270385623,
                -0.036609306931495667,
                -0.014869513921439648,
                0.023259561508893967,
                -0.08710002899169922,
                -0.0004891813732683659,
                -0.01268737018108368,
                -0.045693691819906235,
                0.036713726818561554,
                0.02628839761018753,
                0.0822780430316925,
                -0.004895961843430996,
                -0.03988766670227051,
                -0.023293105885386467,
                0.020545821636915207,
                0.041618604212999344,
                0.0020373391453176737,
                -0.02837488427758217,
                -0.08257920295000076,
                -0.030680598691105843,
                0.0047297608107328415,
                -0.056624360382556915,
                -0.009778551757335663,
                -0.019309375435113907,
                -0.022392965853214264,
                0.03653115779161453,
                0.05133119225502014,
                -0.0021342330146580935,
                -0.06897532939910889,
                0.012577350251376629,
                -0.01716560870409012,
                -0.013519173488020897,
                0.045857083052396774,
                0.004150813911110163,
                -0.056390680372714996,
                -0.027722766622900963,
                -0.017907215282320976,
                -0.00744766928255558,
                -0.04359160736203194,
                -0.028320187702775,
                0.022698871791362762,
                -0.000662295613437891,
                0.013818658888339996,
                0.005626684986054897,
                0.09845960885286331,
                -0.01362781785428524,
                0.00865884218364954,
                0.0033754087053239346,
                -0.010786901228129864,
                -0.027662333101034164,
                0.059114668518304825,
                -0.03170938789844513,
                0.007154969964176416,
                0.06532680988311768,
                0.011102265678346157,
                -0.001660783775150776,
                0.003275418421253562,
                0.03939627856016159,
                0.002884997520595789,
                0.03951169177889824,
                0.007214076817035675,
                -0.023505322635173798,
                0.0420827642083168,
                -0.008313621394336224,
                -0.01197957992553711,
                0.0008706209482625127,
                -0.062134578824043274,
                0.0736016258597374,
                0.053813379257917404,
                -0.007606577128171921,
                -0.013720200397074223,
                0.0057876440696418285,
                0.03221794217824936,
                -0.06187339127063751,
                0.019010500982403755,
                0.014383808709681034,
                0.0098754046484828,
                -0.06506708264350891,
                -0.04502470791339874,
                0.011067287996411324,
                -0.01938600465655327,
                -0.017271559685468674,
                0.008248182944953442,
                -0.11616148054599762,
                -0.011625643819570541,
                0.07679104804992676,
                0.07012884318828583,
                0.05071491748094559,
                -0.030643055215477943,
                -0.0026913892943412066,
                0.007156660780310631,
                -0.09409510344266891,
                0.05527767539024353,
                -0.09381905943155289,
                -0.048945002257823944,
                -0.04849795997142792,
                -0.05207265168428421,
                -0.00864411611109972,
                -0.014608141034841537,
                -0.0042046247981488705,
                0.05359965190291405,
                -0.021182648837566376,
                -0.028918655589222908,
                -0.02346121147274971,
                -0.0300745889544487,
                0.007647475693374872,
                0.02611023746430874,
                0.06445557624101639,
                -0.0008543292642571032,
                -0.06135997921228409,
                -0.010189024731516838,
                0.037093423306941986,
                0.0454171746969223,
                -0.011560121551156044,
                -0.007820628583431244,
                -0.0132365757599473,
                0.005850616842508316,
                0.00775617640465498,
                -0.00599911343306303,
                0.025742776691913605,
                0.04706675931811333,
                0.04475801810622215,
                -0.01564866118133068,
                -0.009129943326115608,
                -0.018579212948679924,
                0.01879359409213066,
                0.027281731367111206,
                0.0006467885687015951,
                0.00858777854591608,
                -0.04900344833731651,
                0.019436465576291084,
                0.06048155948519707,
                0.029684584587812424,
                0.020167935639619827,
                -0.010114790871739388,
                -0.007963115349411964,
                -0.014938176609575748,
                0.0088458601385355,
                0.011389716528356075,
                -0.04057413712143898,
                -0.05063376575708389,
                0.019838331267237663,
                -0.03770523890852928,
                -0.02018764242529869,
                -0.04290441796183586,
                -0.02933843433856964,
                0.02639114111661911,
                -0.04868007078766823,
                0.03134559467434883,
                -0.03298319876194,
                0.046501196920871735,
                -0.018441831693053246,
                0.013717365451157093,
                -0.018878279253840446,
                0.03355390578508377,
                0.029887856915593147,
                0.010752595961093903,
                -0.01648637279868126,
                -0.007840988226234913,
                0.026708930730819702,
                -0.04175828769803047,
                0.07771655172109604,
                0.052791766822338104,
                -0.018169330433011055,
                -0.03793123736977577,
                -0.0018092682585120201,
                -0.026245424523949623,
                -0.013033853843808174,
                -0.013275825418531895,
                -0.004009430296719074,
                -0.031713955104351044,
                0.020834192633628845,
                -0.013484400697052479,
                -0.03280377760529518,
                0.009501301683485508,
                0.00896783173084259,
                0.036341965198516846,
                0.017497822642326355,
                -0.04211576655507088,
                -0.008180154487490654,
                0.04043807089328766,
                -6.631870233006596e-33,
                -0.0073956591077148914,
                0.006553960498422384,
                -0.006347751244902611,
                0.0382237583398819,
                -0.00946298148483038,
                0.027825720608234406,
                0.013185114599764347,
                -0.007932212203741074,
                -0.03418552130460739,
                -0.059730567038059235,
                0.003068056423217058,
                0.007736251689493656,
                0.028374051675200462,
                0.02441129833459854,
                0.06689244508743286,
                -0.019490893930196762,
                0.029463963583111763,
                0.02945515140891075,
                -0.05839558318257332,
                -0.047892045229673386,
                -0.03567640855908394,
                0.010618001222610474,
                0.05072716623544693,
                -0.013584035448729992,
                -0.006620130036026239,
                -0.027039900422096252,
                -0.0033025271259248257,
                -0.009186729788780212,
                -0.0030570318922400475,
                0.016900578513741493,
                -0.010109237395226955,
                -0.03987428918480873,
                -0.000876404985319823,
                0.013228065334260464,
                0.00402340991422534,
                0.0036419774405658245,
                -0.09906252473592758,
                -0.024912545457482338,
                -0.0018883777083829045,
                0.01845991052687168,
                0.032323695719242096,
                0.004892663564532995,
                -0.011599602177739143,
                -0.02494840882718563,
                0.0054390328004956245,
                -5.422999947768403e-06,
                0.0006246354896575212,
                -0.025327200070023537,
                -0.015123763121664524,
                -0.03178301081061363,
                0.0283826757222414,
                -0.013442609459161758,
                -0.033275164663791656,
                0.025509783998131752,
                0.06621912121772766,
                0.013758826069533825,
                -0.01095858309417963,
                -0.036221373826265335,
                -0.02018061839044094,
                0.043696966022253036,
                0.07782469689846039,
                0.01101663988083601,
                0.028895940631628036,
                -0.047887884080410004,
                0.04604509472846985,
                0.022726301103830338,
                -0.027460256591439247,
                -0.016016224399209023,
                -0.02442539855837822,
                -0.003813261166214943,
                -0.019352048635482788,
                0.03449348732829094,
                -0.06487325578927994,
                0.03872523084282875,
                0.0094544542953372,
                -0.00971795991063118,
                -0.013053474947810173,
                0.025026623159646988,
                0.030598502606153488,
                0.01360141672194004,
                -0.058898940682411194,
                -0.0016093810554593801,
                -0.04292250797152519,
                -0.003466846887022257,
                0.011369708925485611,
                0.03512384742498398,
                -0.007642294280230999,
                -0.01880631037056446,
                0.0040176743641495705,
                0.018397390842437744,
                -0.0390576608479023,
                0.06739411503076553,
                -0.03562014549970627,
                0.01970573700964451,
                -0.011187715455889702,
                -0.01713971048593521,
                0.08228345960378647,
                -0.027582498267292976,
                0.017635656520724297,
                -0.025901485234498978,
                -0.04618058353662491,
                0.019739551469683647,
                0.011382699944078922,
                0.005359838251024485,
                -0.0002737768809311092,
                0.0151528250426054,
                -0.01364129688590765,
                0.05103893205523491,
                -0.017422620207071304,
                0.00540179293602705,
                0.028511924669146538,
                -0.007159113418310881,
                -0.030699903145432472,
                -0.043738845735788345,
                0.038425665348768234,
                -0.0012892697704955935,
                0.024316752329468727,
                0.06410187482833862,
                -0.01326949242502451,
                -0.02537567727267742,
                0.023682765662670135,
                -0.04360387474298477,
                0.016965217888355255,
                -0.032461244612932205,
                0.017485229298472404,
                -0.028182895854115486,
                -0.037790074944496155,
                -0.056341368705034256,
                0.06589578092098236,
                0.013347635045647621,
                0.0019463858334347606,
                -0.013088682666420937,
                2.441794890728488e-07,
                0.0012328540906310081,
                0.04578674957156181,
                0.04964464157819748,
                0.010761821642518044,
                -0.04635947570204735,
                -0.04004855453968048,
                -0.0180667731910944,
                -0.016253866255283356,
                -0.046774715185165405,
                0.004410566762089729,
                0.07549300789833069,
                -0.042233943939208984,
                0.03280988708138466,
                0.053768496960401535,
                -0.0042297192849218845,
                -0.06602194160223007,
                0.02848297916352749,
                -0.043054480105638504,
                -0.04905575141310692,
                -0.033689942210912704,
                -0.004051785916090012,
                0.04838007688522339,
                0.004489059094339609,
                -0.007198316976428032,
                0.01811027340590954,
                0.009836755692958832,
                -0.011786481365561485,
                -0.09169510751962662,
                0.07433061301708221,
                -0.03700251132249832,
                0.04045681282877922,
                0.028261501342058182,
                0.007992258295416832,
                0.01649155654013157,
                -0.027830149978399277,
                -0.03252209350466728,
                0.03133952245116234,
                0.015060623176395893,
                0.008788005448877811,
                0.05604564771056175,
                -0.042812034487724304,
                -0.03173362463712692,
                -0.026124240830540657,
                -0.04557611420750618,
                0.03897814452648163,
                0.009017753414809704,
                0.02957061305642128,
                0.06649792194366455,
                -0.08382917195558548,
                0.005925838835537434,
                -0.0013650101609528065,
                -0.01003437303006649,
                0.039983585476875305,
                -0.048643745481967926,
                -0.001356983557343483,
                -0.017763158306479454,
                0.008312497287988663,
                0.019476035609841347,
                0.047999076545238495,
                0.06046507507562637,
                0.011323832906782627,
                -0.043877940624952316,
                0.015636183321475983,
                -0.03490805625915527,
                -0.00467786705121398,
                -0.025763748213648796,
                -0.008704203180968761,
                1.7678575226534425e-34,
                -0.025665367022156715,
                -0.01612199656665325,
                0.033454347401857376,
                -0.004957037046551704,
                -0.006567696109414101,
                -0.02281584031879902,
                0.07066948711872101,
                -0.006311086472123861,
                -0.017406310886144638,
                -0.03246770799160004,
                -0.03205052763223648,
                0.021540049463510513,
                -0.0738164409995079,
                -0.019531890749931335,
                0.011340100318193436,
                -0.02708781324326992,
                0.013425063341856003,
                -0.0012166656088083982,
                0.020738257095217705,
                -0.009335419163107872,
                -0.012274748645722866,
                0.06994151324033737,
                -0.06637268513441086,
                0.06964185833930969,
                -0.025204401463270187,
                0.016792353242635727,
                -0.03263390064239502,
                0.011535090394318104,
                -0.009349221363663673,
                -0.08834608644247055,
                -0.010574685409665108,
                -0.012117203325033188,
                0.02863839454948902,
                -0.020668059587478638,
                -0.00021500028378795832,
                0.0011603233870118856,
                -0.05416729301214218,
                0.002438749885186553,
                -0.00822499766945839,
                -0.02491447515785694,
                -0.011978880502283573,
                -0.001113112666644156,
                -0.005242026410996914,
                -0.013578073121607304,
                -0.029472660273313522,
                2.0773975393240107e-06,
                -0.008394600823521614,
                -0.00669049983844161,
                0.00659477012231946,
                -0.006733939051628113,
                0.05035591870546341,
                0.06147739663720131,
                0.019831258803606033,
                0.005810143426060677,
                0.00211484357714653,
                -0.01731753721833229,
                -0.00829082727432251,
                0.05624224990606308,
                -0.06506983190774918,
                0.015098032541573048,
                0.013087674044072628,
                0.00816391035914421,
                -0.03376832231879234,
                -0.017413802444934845,
                -0.038489192724227905,
                0.03353593498468399,
                -0.06501296162605286,
                0.014966747723519802,
                0.00340932235121727,
                0.06623074412345886,
                0.05474363639950752,
                0.004569650162011385,
                -0.04520107060670853,
                -0.01650700345635414,
                -0.02017265371978283,
                0.08993100374937057,
                -0.0030735963955521584,
                0.007520012557506561,
                -0.07234368473291397,
                -0.010615117847919464,
                0.0022388556972146034,
                0.03126046434044838,
                0.038005486130714417,
                0.0026952268090099096,
                0.03883744031190872,
                -0.03856487199664116,
                -0.0029829901177436113,
                -0.019376413896679878,
                0.016479479148983955,
                -0.00442991778254509,
                -0.02113129012286663,
                -0.06799837201833725,
                -0.011625943705439568,
                -0.01842351257801056,
                0.005608085077255964,
                0.03948814049363136,
                0.0011844189139083028,
                0.007217746693640947,
                0.006808574311435223,
                0.03509745001792908,
                -0.037010062485933304,
                -0.0038028848357498646,
                -0.029746970161795616,
                0.02344099059700966,
                0.004433783236891031,
                0.0008171153021976352,
                -0.0349992997944355,
                -0.023817680776119232,
                0.011253926903009415,
                -0.011092477478086948,
                -0.039731286466121674,
                0.06082532927393913,
                0.0032156980596482754,
                0.011130912229418755,
                0.04005562886595726,
                0.005230748560279608,
                0.0038704657927155495,
                0.04382137954235077,
                -0.05259636044502258,
                -0.0390508770942688,
                0.053367000073194504,
                -0.03826585039496422,
                0.012395023368299007,
                -0.0542544424533844,
                0.08625061810016632,
                0.019255176186561584,
                0.010916261002421379,
                -0.06911054998636246,
                0.018802130594849586,
                0.006556985434144735,
                -0.013696723617613316,
                0.11445122212171555,
                0.04754713550209999,
                0.006932467687875032,
                0.01753234677016735,
                -0.021674804389476776,
                0.003850828157737851,
                -0.03398682549595833,
                0.003939488902688026,
                0.03627911955118179,
                -0.03405323252081871,
                -0.016566354781389236,
                -0.006927925162017345,
                -0.01252429187297821,
                -0.06407123059034348,
                0.027169611304998398,
                -0.011655112728476524,
                0.027169451117515564,
                0.01801726222038269,
                -0.09996242821216583,
                -0.013545120134949684,
                0.09089067578315735,
                -0.03489719331264496,
                -0.10138725489377975,
                0.020454375073313713,
                -0.034734297543764114,
                0.0003470677474979311,
                0.02728179283440113,
                0.004092575516551733,
                0.00877035316079855,
                -0.010479973629117012,
                0.0007675353554077446,
                0.024782592430710793,
                0.031588539481163025,
                0.019939225167036057,
                0.07239608466625214,
                0.01716862991452217,
                0.05130092054605484,
                -0.033246029168367386,
                -0.045654673129320145,
                0.05040980502963066,
                -0.01231406070291996,
                -0.053207166492938995,
                0.07397931069135666,
                -0.026409542188048363,
                -0.02631497010588646,
                -0.022193333134055138,
                0.008351483382284641,
                0.0145336352288723,
                -0.06735659390687943,
                -0.013843683525919914,
                -0.08474013954401016,
                -0.007483620196580887,
                -0.01354975439608097,
                0.04340522736310959,
                0.03508450835943222,
                0.04010147601366043,
                0.04308772459626198,
                -0.04744795709848404,
                -0.0143400514498353,
                -0.04144060239195824,
                0.012283113785088062,
                -0.19022338092327118,
                -0.013629816472530365,
                0.062395885586738586,
                -0.014589682221412659,
                0.02540913224220276,
                -0.05119049921631813,
                0.04891252517700195,
                -0.04353697970509529,
                0.016395125538110733,
                -0.008323708549141884,
                0.0036183421034365892,
                -0.04835641384124756,
                0.05234326794743538,
                -0.0018423503497615457,
                -0.01502782478928566,
                0.006326399743556976,
                -0.04397470876574516,
                -0.0183817520737648,
                0.011056567542254925,
                -0.03669612854719162,
                -0.047005824744701385,
                0.0005373957101255655,
                0.0772283524274826,
                -0.039358675479888916,
                0.013005523011088371,
                0.061941079795360565,
                0.013970172964036465,
                -0.04778342321515083,
                -0.023610910400748253,
                0.057470981031656265,
                0.00293564610183239,
                0.03292855620384216,
                0.023545075207948685,
                -0.013893162831664085,
                0.03777718544006348,
                0.004499491769820452,
                0.01327443029731512,
                -0.00583305349573493,
                0.08071336150169373,
                -0.0138684818521142,
                -0.003234516130760312,
                0.009625177830457687,
                -0.002160568255931139,
                0.024934258311986923,
                -0.03741592913866043,
                0.06341356039047241,
                -0.020447274670004845,
                -0.045156970620155334,
                0.03655829653143883,
                -0.005885641556233168,
                0.014399568550288677,
                0.04166685789823532,
                0.031361497938632965,
                0.04854409396648407,
                -0.010559316724538803,
                0.11954779177904129,
                0.04318591207265854,
                0.006778068840503693,
                -0.01128738559782505,
                -0.00885758176445961,
                0.005668853875249624,
                0.010409733280539513,
                0.047117654234170914,
                0.029012246057391167,
                0.013514372520148754,
                0.016606999561190605,
                0.00362500362098217,
                0.012241284362971783,
                -0.006597988307476044,
                -0.01806606724858284,
                0.025773441419005394,
                0.05163263529539108,
                0.02045929618179798,
                0.0355517715215683,
                0.006366964429616928,
                -0.108589768409729,
                -0.02696121856570244,
                -0.04673116281628609,
                -0.01161070168018341,
                -0.016036057844758034,
                -0.049084484577178955,
                0.009200314059853554,
                -0.01604693941771984,
                -0.012495264410972595,
                0.024739407002925873,
                0.04322962462902069,
                -0.056112002581357956,
                0.028987707570195198,
                0.008123225532472134,
                -0.0265410915017128,
                0.015466399490833282,
                0.04641847684979439,
                -0.03431949019432068,
                -0.020363699644804,
                0.00463517801836133,
                -0.030122842639684677,
                -0.05627982318401337,
                -0.06163215637207031,
                0.01162736862897873,
                0.010122315026819706,
                0.035337723791599274,
                -0.04857231676578522,
                -0.010891424492001534,
                0.008639918640255928,
                -0.026801697909832,
                -0.051616981625556946,
                -0.08404062688350677,
                -0.01867935247719288,
                0.006246922537684441,
                0.021449614316225052,
                0.010561326518654823,
                0.04543663188815117,
                -0.005591711029410362,
                -0.012488586828112602,
                -0.007697264663875103,
                -0.04024557024240494,
                0.05518409609794617,
                -0.02391194924712181,
                0.09801463782787323,
                -0.03985109552741051,
                0.0411432683467865,
                0.02829200215637684,
                -0.002745772013440728,
                -0.035517044365406036,
                0.05498187988996506,
                0.06559057533740997,
                -0.03642024099826813,
                0.016271736472845078,
                -0.005516873672604561,
                0.05013860762119293,
                -0.0010740924626588821,
                0.04594358429312706,
                0.016548458486795425,
                0.004546764772385359,
                -0.009001550264656544,
                -0.04014263674616814,
                0.02009260468184948,
                0.03530611842870712,
                0.035908184945583344,
                -0.004905009642243385,
                0.0028680001851171255,
                0.02168538048863411,
                -0.026090431958436966,
                0.006197373848408461,
                -0.008803436532616615,
                -0.07122069597244263,
                0.035514168441295624,
                0.04629116877913475,
                -0.04010067507624626,
                0.013248490169644356,
                -0.004171738866716623,
                0.00016579254588577896,
                0.07112683355808258,
                -0.06222495809197426,
                0.04480419680476189,
                -0.019033802673220634,
                -0.03832468017935753,
                0.0007134219631552696,
                -0.012634209357202053,
                -0.016190866008400917,
                0.022226285189390182,
                -0.003890605177730322,
                -0.11720176041126251,
                0.06592554599046707,
                -0.06313874572515488,
                -0.01005367748439312,
                0.01735851727426052,
                -0.06437235325574875,
                0.03696087747812271,
                0.02777235582470894,
                0.06592569500207901,
                -0.03327883407473564,
                -0.00493931258097291,
                0.012302436865866184,
                -0.040272727608680725,
                0.02928350307047367,
                -0.012767227366566658,
                -0.05891290307044983,
                -0.020361728966236115,
                -0.0023707482032477856,
                -0.020037110894918442,
                -0.002991844667121768,
                0.012999095022678375,
                -0.016033340245485306,
                0.033622778952121735,
                0.05364822596311569,
                -0.07486597448587418,
                -0.051269177347421646,
                0.03631220757961273,
                0.0055804005824029446,
                0.03487151488661766,
                0.03870638832449913,
                -0.0031080180779099464,
                -0.0047646150924265385,
                -0.025055401027202606,
                -0.01713535562157631,
                0.013207022100687027,
                -0.08534932881593704,
                0.027918552979826927,
                0.036012113094329834,
                -0.06268631666898727,
                0.020335927605628967,
                -0.027723785489797592,
                0.01994805596768856,
                0.000432050961535424,
                0.003321960801258683,
                -0.005977672059088945,
                -0.008277659304440022,
                0.028850531205534935,
                0.024075519293546677,
                -0.013911563903093338,
                0.0007835472351871431,
                0.025364559143781662,
                0.018216950818896294,
                0.008767752908170223,
                -0.056800346821546555,
                -0.014391585253179073,
                -0.009208823554217815,
                0.04522315785288811,
                0.019633527845144272,
                0.023087162524461746,
                -0.030490992590785027,
                0.01081764604896307,
                -0.022591598331928253,
                -0.043749552220106125,
                0.017550699412822723,
                -0.0026773354038596153,
                -0.007373508531600237,
                -0.04455334320664406,
                0.01579335704445839,
                -0.008914594538509846,
                0.02766958810389042,
                -0.007417287211865187,
                -0.08834082633256912,
                -0.016649730503559113,
                -0.017823783680796623,
                0.02710503526031971,
                0.02274574153125286,
                -0.03433443233370781,
                0.005444555543363094,
                0.04325500875711441,
                0.004695245064795017,
                -0.01816466636955738,
                -0.0012563286582008004,
                -0.05507832393050194,
                0.014881483279168606,
                0.04404882341623306,
                0.014984729699790478,
                -0.004210314713418484,
                -0.029528163373470306,
                0.017979837954044342,
                -0.010447467677295208,
                0.01920553669333458,
                -0.032540030777454376,
                -0.061162032186985016,
                -0.019990798085927963,
                0.022531991824507713,
                -0.0029546967707574368,
                0.0031987004913389683,
                -0.013661901466548443,
                0.0436212420463562,
                0.043068140745162964,
                0.005716444458812475,
                0.041657015681266785,
                0.04750876873731613,
                0.0076008569449186325,
                0.003305129474028945,
                -0.021205106750130653,
                0.03390331193804741,
                0.04435884952545166,
                0.033035654574632645,
                0.016603359952569008,
                -0.055228061974048615,
                0.014343183487653732,
                -0.04134324938058853,
                -0.028236668556928635,
                -0.05465918406844139,
                0.022001871839165688,
                -0.007963662967085838,
                0.04772457852959633,
                0.04025442153215408,
                -0.037950098514556885,
                -0.015735222026705742,
                0.028477732092142105,
                -0.09974439442157745,
                0.00665108859539032,
                -0.009591914713382721,
                0.015598871745169163,
                -0.0939185619354248,
                -0.09887661039829254,
                0.009438172914087772,
                -0.047018036246299744,
                0.018704572692513466,
                0.02820361591875553,
                -0.005136535968631506,
                -0.02137674391269684,
                0.03386256843805313,
                -0.01922367513179779,
                0.015764081850647926,
                -0.04641116410493851,
                0.02597840130329132,
                0.008399526588618755,
                -0.028311356902122498,
                0.03762461990118027,
                0.058333493769168854,
                0.04346797242760658,
                -0.01744990237057209,
                0.026854971423745155,
                -0.019341232255101204,
                0.025986183434724808,
                -0.040498655289411545,
                0.0023755694273859262,
                -0.08403153717517853,
                0.02360869199037552,
                0.007135413121432066,
                0.03413178399205208,
                0.007432842161506414,
                0.02995728701353073,
                0.02092668041586876,
                -0.01951647363603115,
                0.0433923713862896,
                -0.0137149877846241,
                -0.001998357940465212,
                -0.006633058190345764,
                0.01922999694943428,
                -0.02358364686369896,
                0.03801855444908142,
                -0.03420061245560646,
                0.027561187744140625,
                0.04174232855439186,
                0.042443517595529556,
                -0.032587047666311264,
                0.01816515624523163,
                0.052611321210861206,
                0.05268020182847977,
                0.04345234856009483,
                -0.06602604687213898,
                -0.07966875284910202,
                0.016051283106207848,
                0.04511123150587082,
                -0.027879886329174042,
                0.02398866042494774,
                0.0328829288482666,
                0.0061599514447152615,
                -0.03747520223259926,
                0.0236689280718565,
                -0.010252565145492554,
                -0.010214150883257389,
                0.020035607740283012,
                -0.10900604724884033,
                -0.024836666882038116,
                0.02368299476802349,
                0.009502445347607136,
                -0.03868493065237999,
                0.06662874668836594,
                -0.021383782848715782,
                0.05538313463330269,
                0.014978117309510708,
                0.015091227367520332,
                0.003992828074842691,
                -0.018134234473109245,
                -0.03472715988755226,
                -0.006450704298913479,
                0.037750210613012314,
                -0.00996679812669754,
                0.01592991314828396,
                0.0389699749648571,
                -0.004518358968198299,
                0.0077428752556443214,
                -0.03429112583398819,
                0.009763221256434917,
                -0.11443127691745758,
                0.024657201021909714,
                -0.02927805297076702,
                -8.155771558521009e-33,
                -0.0050175990909338,
                0.0067764995619654655,
                -0.0045744129456579685,
                0.02387143112719059,
                -0.019215049222111702,
                -0.03317660838365555,
                0.04680117964744568,
                0.005599361844360828,
                -0.04666397348046303,
                -0.02096513845026493,
                -0.04649825766682625,
                0.02219207026064396,
                0.04013480246067047,
                -0.004582778085023165,
                -0.010257194750010967,
                0.03934456408023834,
                -0.018648134544491768,
                0.021825294941663742,
                -0.018626898527145386,
                -0.04800053685903549,
                -0.11047723889350891,
                0.004206846933811903,
                0.01381823793053627,
                0.07671064138412476,
                0.010691811330616474,
                -0.028466375544667244,
                0.0015834829537197948,
                -0.0045346785336732864,
                -0.010503111407160759,
                0.01875072903931141,
                0.009197372011840343,
                -0.00509283971041441,
                -0.015445764176547527,
                -0.002329757437109947,
                0.004287337884306908,
                0.013599127531051636,
                -0.041155099868774414,
                -0.007623229641467333,
                0.01924588344991207,
                0.007720523979514837,
                -0.007600537966936827,
                -0.02368609979748726,
                0.005220893770456314,
                -0.04626724496483803,
                -0.0132217388600111,
                -0.02648412436246872,
                -0.007773125544190407,
                -0.011454446241259575,
                0.033903177827596664,
                0.07172317057847977,
                0.01203258614987135,
                -0.010895716957747936,
                -0.018559815362095833,
                0.06353609263896942,
                -0.013174736872315407,
                -0.030624618753790855,
                -0.0012296960921958089,
                0.00887067150324583,
                -0.016557816416025162,
                -0.03787277638912201,
                -0.05608656629920006,
                -0.0006778030656278133,
                0.010923011228442192,
                -0.03645545244216919,
                -0.010986658744513988,
                -0.03631093353033066,
                -0.10484111309051514,
                -0.011963351629674435,
                0.011044170707464218,
                -0.034290507435798645,
                -0.024347402155399323,
                0.06388296186923981,
                -0.015104304999113083,
                -0.027639193460345268,
                0.03685802221298218,
                -0.06718547642230988,
                0.012503953650593758,
                -0.0048866369761526585,
                0.08159846812486649,
                -0.03912803530693054,
                -0.032445117831230164,
                -0.0431617796421051,
                0.036538880318403244,
                0.004016932565718889,
                -0.0024254850577563047,
                0.035321202129125595,
                -0.042660996317863464,
                -0.02423747070133686,
                0.022638725116848946,
                -0.005500748287886381,
                0.02517162822186947,
                0.03334131836891174,
                -0.06051614135503769,
                -0.0031209581065922976,
                -0.019446106627583504,
                -0.015557907521724701,
                0.019272441044449806,
                0.013412827625870705,
                0.007595183793455362,
                0.020029958337545395,
                0.018033573403954506,
                0.026649050414562225,
                -0.05909271910786629,
                0.06005539000034332,
                0.017137715592980385,
                0.01833338476717472,
                -0.04895567148923874,
                0.02359251119196415,
                -0.03246500715613365,
                0.0016243845457211137,
                0.015228044241666794,
                0.034064698964357376,
                0.00011407622514525428,
                0.05912342295050621,
                0.004548101220279932,
                0.04984862357378006,
                9.969035454560071e-05,
                0.039063483476638794,
                -0.022712495177984238,
                -0.019265856593847275,
                0.013146957382559776,
                -0.0456341877579689,
                -0.026679852977395058,
                0.021998688578605652,
                -0.04110211133956909,
                -0.0019200315000489354,
                0.013788674958050251,
                0.027514250949025154,
                0.02358000911772251,
                -0.027127722278237343,
                -0.021679174154996872,
                -0.0031264140270650387,
                2.6906926109404594e-07,
                -0.006133880000561476,
                0.0030832933261990547,
                -0.013055139221251011,
                0.07324725389480591,
                0.0001745547924656421,
                -0.04570361226797104,
                -0.020476294681429863,
                0.059337109327316284,
                -0.0017887242138385773,
                0.05126379430294037,
                -0.006064011715352535,
                -0.017698416486382484,
                0.016833532601594925,
                -0.0368780791759491,
                -0.00879279337823391,
                0.009292325004935265,
                -0.03972683474421501,
                0.022936595603823662,
                -0.02473914436995983,
                0.04926668852567673,
                0.048256225883960724,
                0.06852150708436966,
                0.06378620117902756,
                0.005640667397528887,
                -0.033232010900974274,
                -0.026442991569638252,
                0.0027988310903310776,
                0.011360607109963894,
                0.08640674501657486,
                0.003151403507217765,
                -0.031182769685983658,
                0.06495913863182068,
                0.0011525641893967986,
                0.03186286240816116,
                -0.015553073026239872,
                -0.028442971408367157,
                0.04996452108025551,
                0.02930397540330887,
                -0.015511988662183285,
                0.039415065199136734,
                -0.006682011764496565,
                0.0049454402178525925,
                -0.008788595907390118,
                0.0073233614675700665,
                0.058665383607149124,
                0.02069060318171978,
                0.007819194346666336,
                -0.011770360171794891,
                -0.01384198758751154,
                -0.012768534943461418,
                -0.020627425983548164,
                0.007966031320393085,
                0.03935714438557625,
                0.012490776367485523,
                0.036240458488464355,
                -0.01455759909003973,
                -0.006377777084708214,
                0.04544590786099434,
                0.005860520992428064,
                0.0034212195314466953,
                0.017347553744912148,
                -0.01574545167386532,
                0.008785130456089973,
                -0.007610142696648836,
                0.04962240159511566,
                0.047420427203178406,
                -0.0649765357375145,
                1.6251080830423504e-34,
                -0.0007369937957264483,
                -0.025513365864753723,
                0.006315845996141434,
                0.016569342464208603,
                -0.018025226891040802,
                -0.0346931628882885,
                -0.021453581750392914,
                -0.013262146152555943,
                -0.023883435875177383,
                -0.01823134906589985,
                -0.015166090801358223,
                -0.01774263009428978,
                0.06535341590642929,
                -0.017353251576423645,
                0.02166619896888733,
                -0.02071107178926468,
                -0.030504364520311356,
                0.0829583927989006,
                -0.0005869284505024552,
                -0.05039370432496071,
                0.019026724621653557,
                -0.0038944606203585863,
                -0.007485167123377323,
                -0.008454891853034496,
                0.06325314939022064,
                -0.005497652105987072,
                -0.008169167675077915,
                0.024797337129712105,
                -0.01189744658768177,
                -0.04222794622182846,
                -0.019719235599040985,
                0.011628303676843643,
                -0.01610058918595314,
                0.0472734235227108,
                -0.03822796046733856,
                -0.011786632239818573,
                -0.0072105322033166885,
                -0.012253479100763798,
                -0.012717094272375107,
                -0.025511253625154495,
                0.003980766981840134,
                0.0018690187716856599,
                0.03748277202248573,
                0.005036785267293453,
                0.016178349032998085,
                1.5016473753348691e-06,
                0.02979539893567562,
                -0.03274374082684517,
                0.04929007217288017,
                -0.08581219613552094,
                -0.01241882611066103,
                0.007792567368596792,
                0.014005636796355247,
                0.057069990783929825,
                0.010795198380947113,
                -0.0266434196382761,
                -0.015641121193766594,
                0.030400576069951057,
                -0.051261063665151596,
                0.048058029264211655,
                -0.041385527700185776,
                0.0038421719800680876,
                -0.001868463703431189,
                -0.03479798510670662,
                -0.010957249440252781,
                0.02614516206085682,
                -0.01008267980068922,
                0.03494122624397278,
                0.05810375139117241,
                0.05318019166588783,
                0.0535728745162487,
                0.02510819025337696,
                0.008218417875468731,
                -0.014809933491051197,
                0.053258925676345825,
                0.0391271710395813,
                0.0022388475481420755,
                0.013091008178889751,
                -0.013391925022006035,
                -0.009102907963097095,
                0.0066724056378006935,
                0.10275658965110779,
                -0.009140824899077415,
                0.00778121966868639,
                0.03986544534564018,
                -0.013136357069015503,
                0.004069708287715912,
                -0.02021896280348301,
                0.01671685464680195,
                -0.011306597851216793,
                0.02520829252898693,
                -0.03923022747039795,
                -0.0006263302639126778,
                -0.008452173322439194,
                0.01573818176984787,
                0.011299604550004005,
                0.014202590100467205,
                -0.004031809978187084,
                -0.034627556800842285,
                -0.08158107101917267,
                -0.008113943971693516,
                0.036861319094896317,
                -0.04631856083869934,
                0.023822711780667305,
                0.00406985729932785,
                0.029731038957834244,
                -0.0055515356361866,
                -0.07749465107917786,
                -0.02167377434670925,
                -0.03414483740925789,
                0.03605503588914871,
                0.021473253145813942,
                -0.05724198371171951,
                0.00954761728644371,
                0.04129326716065407,
                0.10078314691781998,
                -0.06478458642959595,
                -0.02806526981294155,
                0.029999373480677605,
                0.027979765087366104,
                -0.047691721469163895,
                -0.020126132294535637,
                0.035891093313694,
                0.026879791170358658,
                0.10147725045681,
                0.00029903886024840176,
                0.026761826127767563,
                0.0023057234939187765,
                0.021341687068343163,
                -0.020673872902989388,
                0.04307694360613823,
                0.03646352142095566,
                -0.0053200083784759045,
                0.028595462441444397,
                -0.0030007250607013702,
                -0.019648239016532898,
                -0.08614608645439148,
                -0.016584817320108414,
                0.006431780755519867,
                0.032309841364622116,
                -0.0486530065536499,
                0.0026090708561241627,
                -0.06906770914793015,
                0.03849031403660774,
                -0.001109530683606863,
                0.01588449627161026,
                0.02458409033715725,
                0.005895119160413742,
                0.046177685260772705,
                -0.049103979021310806,
                -0.06276056170463562,
                0.09036191552877426,
                -0.013692300766706467,
                -0.07012709975242615,
                -0.00785271916538477,
                0.04404481500387192,
                0.01294128131121397,
                -0.03725382685661316,
                0.011388206854462624,
                -0.025928156450390816,
                0.013051922433078289,
                -0.013128604739904404,
                0.07675053179264069,
                -0.0345093309879303,
                0.015948539599776268,
                0.03218730911612511,
                0.055024631321430206,
                0.021980086341500282,
                -0.04429541155695915,
                -0.015636593103408813,
                -0.04286719113588333,
                -0.0033757351338863373,
                0.03979972004890442,
                0.024033870548009872,
                -0.03638308867812157,
                0.048078663647174835,
                0.02124473825097084,
                0.03305428475141525,
                0.06597499549388885,
                0.005593891721218824,
                0.006572520826011896,
                -0.08323248475790024,
                0.0709499940276146,
                -0.028233464807271957,
                -0.017983410507440567,
                0.05624557286500931,
                0.043485794216394424,
                0.06193853169679642,
                0.004953667987138033,
                -0.09132770448923111,
                0.059378042817115784,
                -0.048666056245565414,
                -0.09638240933418274,
                0.0382252112030983,
                0.05294713377952576,
                0.03867708891630173,
                -0.0580405592918396,
                0.02238290011882782,
                0.002643218031153083,
                -0.003102367976680398,
                0.04160378873348236,
                -0.00929280649870634,
                -0.0688711628317833,
                -0.008783351629972458,
                0.050582755357027054,
                -0.037455711513757706,
                0.01770767755806446,
                0.053815122693777084,
                -0.008481556549668312,
                -0.02075391635298729,
                -0.03328844532370567,
                -0.011818477883934975,
                -0.030726775527000427,
                0.02097921073436737,
                0.060415588319301605,
                -0.02516469731926918,
                0.020363902673125267,
                0.053589411079883575,
                -0.02451821230351925,
                0.04117715358734131,
                -0.067542664706707,
                0.03609713166952133,
                -0.009643150493502617,
                0.021943876519799232,
                -0.015940222889184952,
                0.004062901251018047,
                0.03893697261810303,
                0.02211339771747589,
                -0.0068393307738006115,
                -0.05337243527173996,
                0.05046983063220978,
                -0.02014273591339588,
                0.05965922400355339,
                0.012424007058143616,
                -0.0009689187281765044,
                0.034222643822431564,
                0.02511974796652794,
                0.01165126170963049,
                0.038649242371320724,
                0.018680566921830177,
                0.012576781213283539,
                0.008275531232357025,
                0.00995931401848793,
                -0.01417516265064478,
                0.02149428427219391,
                0.0022829261142760515,
                0.028860513120889664,
                0.03932056203484535,
                0.03960943967103958,
                -0.061460334807634354,
                -0.0737786814570427,
                0.06197879835963249,
                -0.0006765110301785171,
                -0.013223611749708652,
                -0.011092079803347588,
                -0.024191243574023247,
                0.027615170925855637,
                -0.0016800765879452229,
                0.04723550006747246,
                0.018742498010396957,
                0.011426785960793495,
                0.004840540699660778,
                -0.013703024946153164,
                -0.02683354914188385,
                0.0011170919751748443,
                0.04774956405162811,
                -0.03730328753590584,
                0.006566546857357025,
                0.059655819088220596,
                -0.01339635532349348,
                0.028638117015361786,
                0.01738882064819336,
                0.019187185913324356,
                0.03175702691078186,
                -0.01975702866911888,
                -0.018841540440917015,
                0.02292068675160408,
                0.038866497576236725,
                0.013170990161597729,
                0.017801443114876747,
                0.007827941328287125,
                0.0020495012868195772,
                0.033744532614946365,
                0.017548078671097755,
                -0.01933867298066616,
                -0.02535433880984783,
                -0.06875385344028473,
                0.008545594289898872,
                -0.010045384056866169,
                0.013031556271016598,
                0.029113300144672394,
                0.059560805559158325,
                0.01709115505218506,
                -0.00031649411539547145,
                -0.04835693538188934,
                0.055072490125894547,
                -0.0032877433113753796,
                -0.005655486602336168,
                -0.0023447636049240828,
                -0.039734989404678345,
                -0.07121232897043228,
                -0.035264842212200165,
                0.004715860355645418,
                0.03693271428346634,
                0.0026554118376225233,
                -0.004380192141979933,
                0.003266241867095232,
                -0.04286151006817818,
                -0.00975830014795065,
                -0.047734297811985016,
                -0.036832295358181,
                -0.03971599042415619,
                -0.043581463396549225,
                0.023963266983628273,
                -0.04022899642586708,
                0.006107474211603403,
                0.011195385828614235,
                0.025101877748966217,
                -0.02049775794148445,
                0.05651990696787834,
                -0.00835762545466423,
                0.01885232701897621,
                -0.037405502051115036,
                0.043929122388362885,
                0.027287831529974937,
                0.007555393036454916,
                0.009079343639314175,
                -0.05550411343574524,
                -0.06835741549730301,
                0.006469303742051125,
                0.029965024441480637,
                0.014382399618625641,
                0.004415987990796566,
                0.018730761483311653,
                -0.0212521031498909,
                0.0247805118560791,
                0.04506823420524597,
                -0.09482105821371078,
                -0.023626424372196198,
                0.051004599779844284,
                -0.02251984179019928,
                0.0034459582529962063,
                -0.032304324209690094,
                -0.019370481371879578,
                0.013928879983723164,
                -0.03393350541591644,
                0.06139427050948143,
                -0.0395706482231617,
                -0.052442166954278946,
                -0.030846944078803062,
                -0.04975559189915657,
                0.01635919138789177,
                -0.051752641797065735,
                0.0021943727042526007,
                0.017568156123161316,
                -0.003999416250735521,
                -0.016882430762052536,
                -0.0009936441201716661,
                -0.02281215786933899,
                0.07443246245384216,
                -0.009050071239471436,
                0.05511079728603363,
                0.009308253414928913,
                0.026010900735855103,
                0.014509202912449837,
                0.037299834191799164,
                -0.0816645398736,
                -0.06554067134857178,
                -0.016247635707259178,
                -0.08117641508579254,
                0.007317705545574427,
                -0.06231261417269707,
                0.007720734924077988,
                0.038829222321510315,
                0.0231646541506052,
                0.04367442429065704,
                0.010391050018370152,
                0.03357434272766113,
                0.03065096214413643,
                -0.025709277018904686,
                -0.011917494237422943,
                0.023291965946555138,
                -0.012357956729829311,
                0.019453147426247597,
                -0.038333918899297714,
                0.005090103019028902,
                -0.0476280152797699,
                -0.03014112263917923,
                -0.0016118393978103995,
                -0.0690511167049408,
                -0.005187655333429575,
                -0.004872030112892389,
                -0.05882193148136139,
                0.0021190086845308542,
                0.010171230882406235,
                0.003386624390259385,
                -0.052748579531908035,
                -0.06981821358203888,
                -0.04514080286026001,
                0.024222109466791153,
                -0.03493322804570198,
                -0.005880868062376976,
                0.03804556280374527,
                -0.052409909665584564,
                -0.009220552630722523,
                0.03799603134393692,
                -0.02965843491256237,
                -0.04953337460756302,
                -0.044810280203819275,
                -0.010323039256036282,
                0.018290333449840546,
                0.023991333320736885,
                0.026476556435227394,
                0.0023722804617136717,
                -0.015408226288855076,
                -0.09101886302232742,
                -0.000643272593151778,
                0.016182227060198784,
                0.019320594146847725,
                0.023148860782384872,
                -0.04001297801733017,
                -0.051912572234869,
                0.0031467934604734182,
                -0.023415200412273407,
                -0.02293185144662857,
                0.016951464116573334,
                -0.01632060669362545,
                -0.02126762829720974,
                -0.006203196942806244,
                0.042982541024684906,
                0.07019607722759247,
                0.04319596663117409,
                -0.0474514365196228,
                0.0003900883602909744,
                -0.02090275287628174,
                0.021366210654377937,
                0.02972467429935932,
                0.008187542669475079,
                0.06161557137966156,
                0.06441705673933029,
                -0.0012583554489538074,
                0.006397359073162079,
                0.01959078572690487,
                0.02237614057958126,
                0.03465349227190018,
                -0.0010912192519754171,
                0.010779369622468948,
                0.02428285963833332,
                0.0068898587487638,
                0.0007760761654935777,
                -0.03345300629734993,
                0.017434317618608475,
                0.010732671245932579,
                -0.013775929808616638,
                0.020576627925038338,
                -0.006849769502878189,
                0.011336282826960087,
                -0.04084880277514458,
                -0.014321844093501568,
                -0.0955173596739769,
                -0.02542109414935112,
                -0.007072833366692066,
                -0.012739893980324268,
                -0.017163917422294617,
                -0.027654018253087997,
                0.021835651248693466,
                -0.010917709209024906,
                -0.023585114628076553,
                -0.044023070484399796,
                -0.011799963191151619,
                0.03964072838425636,
                0.003252596827223897,
                0.028358343988656998,
                -0.010499434545636177,
                0.03861531987786293,
                -0.021651126444339752,
                0.023798923939466476,
                0.052639443427324295,
                -0.04090218245983124,
                -0.021545030176639557,
                -0.018243322148919106,
                -0.01738334260880947,
                0.022796865552663803,
                0.0016844307538121939,
                -0.015276369638741016,
                -0.03168410807847977,
                0.028744708746671677,
                -0.11450926214456558,
                -0.028770115226507187,
                0.03293361887335777,
                -0.017202425748109818,
                0.026167752221226692,
                -0.03220822289586067,
                0.06916085630655289,
                -0.061013199388980865,
                -0.03171149641275406,
                0.06029867008328438,
                0.010474449023604393,
                0.028534775599837303,
                -0.035800203680992126,
                -0.005042861215770245,
                0.03307240456342697,
                -0.016069523990154266,
                0.04083842784166336,
                -0.017881125211715698,
                0.08070437610149384,
                -0.039727017283439636,
                -0.04564239829778671,
                0.018408581614494324,
                0.02425403520464897,
                0.0036404116544872522,
                -0.03187033534049988,
                0.022178826853632927,
                -0.03350372985005379,
                -0.027111032977700233,
                0.021406549960374832,
                0.02766716666519642,
                -0.021231189370155334,
                0.009817995131015778,
                0.0038160220719873905,
                -0.01762493886053562,
                0.07623010873794556,
                0.03096378594636917,
                -0.010682864114642143,
                -0.07284107804298401,
                0.046017322689294815,
                0.02144140936434269,
                -0.07639637589454651,
                0.08134014159440994,
                -0.07846834510564804,
                -0.08199482411146164,
                0.01579645648598671,
                -0.022562777623534203,
                0.030360830947756767,
                -0.04730403423309326,
                -0.0008649157243780792,
                0.011457534506917,
                -0.008216933347284794,
                -0.02415953017771244,
                0.0031402630265802145,
                0.007827728055417538,
                -0.0013167258584871888,
                -0.03628413379192352,
                -0.09224312752485275,
                -0.044323064386844635,
                -0.027274727821350098,
                0.06254810839891434,
                0.04945231229066849,
                0.006200764328241348,
                -0.026299219578504562,
                -0.012041715905070305,
                0.0225369855761528,
                0.031336020678281784,
                -0.03238559886813164,
                0.011071417480707169,
                -0.003656231565400958,
                0.011215634644031525,
                0.04587830603122711,
                -0.007294298615306616,
                -0.005464326124638319,
                -0.001183502608910203,
                -0.016780411824584007,
                -0.013076313771307468,
                0.012781059369444847,
                -0.009669340215623379,
                0.014378031715750694,
                -5.876075854569836e-33,
                -0.003600932192057371,
                0.01175067387521267,
                -0.0022382372990250587,
                0.024665970355272293,
                0.011783920228481293,
                0.015842536464333534,
                0.012019150890409946,
                -0.024168990552425385,
                -0.0628623515367508,
                -0.024253087118268013,
                -0.020739829167723656,
                0.05355406180024147,
                0.012209744192659855,
                0.02517986297607422,
                -0.008430508896708488,
                -0.04667681083083153,
                0.02285776659846306,
                0.01302299927920103,
                -0.007831552997231483,
                -0.03964672237634659,
                0.017381122335791588,
                0.04845418408513069,
                0.01384189072996378,
                0.005350587423890829,
                0.02646082267165184,
                -0.047933127731084824,
                -0.04129692167043686,
                0.016360364854335785,
                -0.024244695901870728,
                -0.02829527109861374,
                0.01713477075099945,
                -0.04106200486421585,
                -0.0034455934073776007,
                -0.019000625237822533,
                -0.026580065488815308,
                0.05621824413537979,
                -0.035234760493040085,
                -0.03314218670129776,
                0.0023179412819445133,
                -0.02940785139799118,
                -0.013012166135013103,
                0.03491251915693283,
                0.03425626456737518,
                -0.06680618226528168,
                0.037602122873067856,
                0.009638172574341297,
                -0.03169570118188858,
                0.030414504930377007,
                -0.013636982068419456,
                0.06642885506153107,
                0.04237735643982887,
                -0.023468632251024246,
                0.008355014026165009,
                0.060855116695165634,
                0.08263309299945831,
                -0.019746458157896996,
                0.00041613541543483734,
                -0.04352400451898575,
                -0.03815996274352074,
                0.03191419318318367,
                0.010828355327248573,
                0.0029482280369848013,
                -0.018886178731918335,
                0.006032082252204418,
                0.05199991166591644,
                0.029334930703043938,
                0.0039527276530861855,
                -0.03551262989640236,
                -0.015270784497261047,
                0.02643350511789322,
                -0.058352768421173096,
                0.05819287151098251,
                0.0013721216237172484,
                0.030932273715734482,
                -0.049246422946453094,
                -0.04260946437716484,
                -0.011191802099347115,
                -0.011855341494083405,
                -0.025865845382213593,
                -0.07329730689525604,
                0.013936241157352924,
                0.010029179975390434,
                -0.048260025680065155,
                -0.018831361085176468,
                0.0013617515796795487,
                0.02600657381117344,
                -0.01707644574344158,
                0.006308247335255146,
                -0.03210078924894333,
                0.015926901251077652,
                -0.024921946227550507,
                0.10415536165237427,
                0.01970304735004902,
                -0.027250537648797035,
                -0.04193953052163124,
                -0.009605476632714272,
                0.08670759201049805,
                -0.06483379006385803,
                0.01223829947412014,
                -0.060444679111242294,
                -0.05673246830701828,
                0.05037723109126091,
                0.018595628440380096,
                0.057905398309230804,
                -0.015572273172438145,
                0.028063248842954636,
                0.07046609371900558,
                -0.01775117963552475,
                -0.03739851713180542,
                0.012816651724278927,
                -0.025257159024477005,
                0.032072119414806366,
                -0.008365849032998085,
                -0.06507598608732224,
                0.03010374866425991,
                0.01428541261702776,
                0.008874166756868362,
                0.01177101768553257,
                0.024844789877533913,
                0.005656722467392683,
                -0.0396156907081604,
                -0.04034121334552765,
                -0.03936037793755531,
                -0.012381373904645443,
                0.023841429501771927,
                0.008499658666551113,
                -0.023880405351519585,
                0.004216563422232866,
                0.0625983327627182,
                0.00018311278836335987,
                0.008314168080687523,
                -0.02919706515967846,
                2.0594652028194105e-07,
                0.01781133934855461,
                -0.0217873714864254,
                -0.021897200495004654,
                0.027127357199788094,
                0.006661855615675449,
                -0.010007645934820175,
                0.035000029951334,
                0.07150272279977798,
                -0.022414477542042732,
                0.01831299252808094,
                -9.116503497352824e-05,
                -0.022139932960271835,
                0.017410844564437866,
                0.029894590377807617,
                -0.02459886111319065,
                -0.06328524649143219,
                -0.037940867245197296,
                -0.026363419368863106,
                -0.020486030727624893,
                -0.06448754668235779,
                0.021777058020234108,
                -0.0032059133518487215,
                0.060997433960437775,
                0.00864538736641407,
                0.032587818801403046,
                0.014178654178977013,
                0.0012952678371220827,
                -0.05428560450673103,
                0.02711225301027298,
                -0.0215420164167881,
                0.03869355469942093,
                0.028895098716020584,
                -0.0038469042629003525,
                0.07453776895999908,
                -0.04130792245268822,
                -0.07888934761285782,
                -0.04009231552481651,
                0.002614137949422002,
                -0.004902173299342394,
                0.024615701287984848,
                0.016475021839141846,
                -0.09649845957756042,
                -0.07721356302499771,
                0.03161418437957764,
                0.010917095467448235,
                -0.0008879723609425128,
                -0.024192294105887413,
                0.08693581819534302,
                0.0713358074426651,
                -0.07133440673351288,
                0.04232701659202576,
                0.032140642404556274,
                0.022236686199903488,
                -0.009334275498986244,
                -0.015399303287267685,
                0.03629646077752113,
                0.002991654444485903,
                -0.001210851245559752,
                0.03343676030635834,
                -0.03999779745936394,
                0.015557503327727318,
                -0.03546065837144852,
                -0.01869109645485878,
                -0.036894477903842926,
                -0.011447147466242313,
                0.017091060057282448,
                0.02553548291325569,
                5.540604804907651e-35,
                -0.04706225171685219,
                0.013340767472982407,
                0.004191946238279343,
                -0.0069832936860620975,
                -0.01365385390818119,
                0.0008124251035042107,
                0.0754886269569397,
                -0.023742010816931725,
                0.009360456839203835,
                0.015362195670604706,
                -0.026355857029557228
            ]
        },
        "Education": {
            "name": "MSc/Entrepreneurship/Entrepreneurial Studies",
            "rep": [
                -0.017337771132588387,
                -0.05913116782903671,
                -0.027779972180724144,
                -0.01711931638419628,
                0.049953341484069824,
                0.028528280556201935,
                -0.001671061385422945,
                0.02718712016940117,
                0.04475697502493858,
                -0.008925561793148518,
                0.03521975502371788,
                0.04370126873254776,
                0.018576115369796753,
                0.061913032084703445,
                0.052282556891441345,
                0.08710024505853653,
                0.01115722581744194,
                0.007691203150898218,
                -0.035095859318971634,
                -0.013781910762190819,
                -0.05166957899928093,
                0.020190807059407234,
                -0.03765369951725006,
                0.03132778778672218,
                0.053560346364974976,
                -0.03862965479493141,
                0.013574131764471531,
                0.02450699359178543,
                0.0020944036077708006,
                0.013805621303617954,
                -0.004926763474941254,
                -0.010308697819709778,
                -0.018553532660007477,
                0.004010865930467844,
                9.630610975364107e-07,
                -0.045932698994874954,
                -0.04469817504286766,
                0.001152845099568367,
                0.019435876980423927,
                -0.034849170595407486,
                0.04063073545694351,
                0.10422565788030624,
                0.02160392329096794,
                0.04600441828370094,
                -0.007751776371151209,
                0.04666922613978386,
                0.006163447629660368,
                0.03754524886608124,
                -0.027315868064761162,
                0.010526862926781178,
                0.007544609718024731,
                -0.09317049384117126,
                -0.0016544614918529987,
                -0.054865799844264984,
                -0.04923751950263977,
                -0.0648321732878685,
                0.01945425383746624,
                0.0399647057056427,
                0.0077503751963377,
                0.01520129106938839,
                -0.007193506229668856,
                -0.036903537809848785,
                -0.06648147106170654,
                -0.012718845158815384,
                0.04055627062916756,
                0.034445635974407196,
                0.07867497950792313,
                -0.040494728833436966,
                -0.051878783851861954,
                -0.012248126789927483,
                0.030383389443159103,
                -0.0016194144263863564,
                -0.014240910299122334,
                0.004821790847927332,
                0.03848825395107269,
                0.0021767509169876575,
                0.011743423528969288,
                -0.006974153220653534,
                -0.019619809463620186,
                0.03390065208077431,
                0.00026292609982192516,
                0.031002098694443703,
                -0.015407784841954708,
                0.0386410728096962,
                -0.015546591021120548,
                0.005744632333517075,
                0.004641850478947163,
                0.014368262141942978,
                0.053167250007390976,
                -0.040930233895778656,
                0.06943505257368088,
                0.019857149571180344,
                -0.004607531242072582,
                -0.009431056678295135,
                0.007021999917924404,
                -0.05461039021611214,
                0.05688801780343056,
                0.014213966205716133,
                -0.02711394801735878,
                0.04702315106987953,
                -0.02799847163259983,
                -0.013253162615001202,
                0.03785504028201103,
                0.031120464205741882,
                -0.0239760372787714,
                0.02217787317931652,
                -0.011145487427711487,
                0.002164755016565323,
                -0.006402390077710152,
                0.013294451870024204,
                -0.042095646262168884,
                0.00674012815579772,
                -0.04880032315850258,
                -0.0016688770847395062,
                -0.05512605234980583,
                0.057962387800216675,
                -0.03098437562584877,
                -0.006116630509495735,
                0.011151328682899475,
                0.004249167628586292,
                -0.03163471817970276,
                0.014537883922457695,
                0.003637251676991582,
                0.00722908740863204,
                -0.006413618102669716,
                0.0003735623031388968,
                -0.018606355413794518,
                0.05164043977856636,
                0.013957522809505463,
                0.04470442607998848,
                0.004667454864829779,
                -0.028136732056736946,
                -0.011714641004800797,
                0.0005001534009352326,
                0.015344231389462948,
                -0.011773874051868916,
                -0.016037605702877045,
                0.012043528258800507,
                -0.05147745460271835,
                -0.00447319308295846,
                0.01727944053709507,
                -0.035935178399086,
                0.03208915889263153,
                0.03534309193491936,
                -0.004097940865904093,
                -0.02743522636592388,
                -0.010418188758194447,
                0.006025365088135004,
                0.04562428221106529,
                -0.017803514376282692,
                -0.022009622305631638,
                -0.05295203998684883,
                0.058005161583423615,
                -0.06502941250801086,
                0.013775202445685863,
                0.005069643259048462,
                0.10809159278869629,
                -0.017762798815965652,
                -0.003174480516463518,
                0.0516333244740963,
                0.005988926161080599,
                0.0083469832316041,
                0.03007109835743904,
                0.015366020612418652,
                -0.01716627925634384,
                -0.0186662208288908,
                -0.009579746052622795,
                0.018118226900696754,
                -0.08511842787265778,
                -0.008279046975076199,
                -0.049926940351724625,
                -0.05556214973330498,
                -0.008752506226301193,
                0.1088818684220314,
                0.0224627498537302,
                0.003752959193661809,
                0.010899605229496956,
                0.0256553553044796,
                -0.04897972196340561,
                0.005432880017906427,
                -0.03588845953345299,
                -0.09359390288591385,
                0.038326554000377655,
                -0.02049962617456913,
                0.02820626273751259,
                0.03720590099692345,
                0.01265947800129652,
                -0.003265485167503357,
                -0.016039913520216942,
                -0.017839640378952026,
                0.009527008049190044,
                0.060730308294296265,
                -0.10247476398944855,
                -0.008610105141997337,
                -0.00037184852408245206,
                -0.016877539455890656,
                -0.007123538292944431,
                -0.029787471517920494,
                -0.021335577592253685,
                -0.007859316654503345,
                -0.02749027870595455,
                0.05615439638495445,
                0.001659151166677475,
                0.0015530174132436514,
                0.014426779933273792,
                -0.0013846956426277757,
                0.0339677557349205,
                -0.064821258187294,
                -0.10124268382787704,
                0.0826629027724266,
                -0.02014913409948349,
                -0.023111678659915924,
                0.05952325835824013,
                -0.023913657292723656,
                -0.006851688493043184,
                -0.010824749246239662,
                -0.018144026398658752,
                -0.060775917023420334,
                -0.001043100724928081,
                0.007864536717534065,
                -0.02378508448600769,
                -0.06883005797863007,
                0.07992833852767944,
                -0.016696223989129066,
                -0.05210212990641594,
                0.0008480887627229095,
                0.03644045069813728,
                -0.013206101022660732,
                0.01564497873187065,
                0.09972838312387466,
                -0.0022799852304160595,
                -0.022493306547403336,
                0.04477907344698906,
                0.019796542823314667,
                -0.008618088439106941,
                -0.009739202447235584,
                0.08644531667232513,
                -0.05735671892762184,
                0.006941678002476692,
                -0.018348831683397293,
                -0.03520287945866585,
                0.027499765157699585,
                0.0076721361838281155,
                0.02737298421561718,
                -0.008029665797948837,
                0.021726513281464577,
                -0.013845493085682392,
                0.02214759588241577,
                0.013317897915840149,
                -0.017415784299373627,
                0.004681907594203949,
                -0.00553832808509469,
                -0.0011071021435782313,
                -0.02207481488585472,
                0.01957029104232788,
                0.013006743043661118,
                -0.07608882337808609,
                -0.012372555211186409,
                -0.053096700459718704,
                0.005030021537095308,
                -0.004603726789355278,
                -0.03498462960124016,
                0.049650825560092926,
                -0.012719960883259773,
                0.0011408758582547307,
                -0.03372611850500107,
                0.006731113884598017,
                -0.03248808532953262,
                -0.02891514077782631,
                0.04134400933980942,
                -0.01197059080004692,
                0.025967638939619064,
                0.04361194744706154,
                -0.013873799704015255,
                0.014226268045604229,
                0.006686373148113489,
                0.005998183507472277,
                0.08004036545753479,
                -0.07356385141611099,
                0.03216935694217682,
                0.005398046690970659,
                0.03154689446091652,
                -0.03466576337814331,
                -0.019422240555286407,
                -0.004340948071330786,
                -0.03410670533776283,
                0.029557378962635994,
                -0.019003188237547874,
                -0.0018195235170423985,
                -0.04284757003188133,
                -0.0086830984801054,
                0.009627738036215305,
                0.020537685602903366,
                -0.04418622702360153,
                -0.030665000900626183,
                0.05460572615265846,
                -0.050848301500082016,
                -0.023300550878047943,
                -0.020390015095472336,
                0.058640968054533005,
                -0.006939447019249201,
                -0.05117882043123245,
                0.04997288063168526,
                0.03848623111844063,
                -0.03389522805809975,
                0.07980240136384964,
                -0.01378191914409399,
                0.024918779730796814,
                -0.04486541450023651,
                -0.03241287171840668,
                -0.026968389749526978,
                0.026682045310735703,
                0.023791678249835968,
                -0.0061192079447209835,
                0.044655412435531616,
                -0.054495953023433685,
                -0.0018659398192539811,
                0.05008703097701073,
                0.016239741817116737,
                0.06376050412654877,
                0.003181352512910962,
                0.009655086323618889,
                -0.029761575162410736,
                -0.036477070301771164,
                0.008512122556567192,
                0.01772543229162693,
                -0.039457742124795914,
                0.01618492417037487,
                0.019767913967370987,
                -0.09048788994550705,
                -0.006520232651382685,
                -0.002980228513479233,
                -0.047776248306035995,
                -0.044399093836545944,
                -0.023898281157016754,
                -0.050339940935373306,
                -0.02531266026198864,
                -0.05236273258924484,
                -0.018483351916074753,
                -0.03415442258119583,
                0.035412196069955826,
                0.03605501726269722,
                -0.037724681198596954,
                -0.04822654277086258,
                0.04124852269887924,
                0.012587938457727432,
                0.004601196385920048,
                0.050288766622543335,
                -0.04932757467031479,
                -0.029127296060323715,
                0.02785278484225273,
                -0.0041638812981545925,
                0.014832286164164543,
                0.019118545576930046,
                -0.0400276742875576,
                0.03123529441654682,
                0.05702219530940056,
                0.01634017750620842,
                0.017398051917552948,
                -0.03781234100461006,
                -0.005610212683677673,
                -0.03568146005272865,
                -0.09565544128417969,
                -0.02120152860879898,
                0.04655788093805313,
                0.011540405452251434,
                0.04361869394779205,
                -0.03556148707866669,
                0.020645782351493835,
                -0.0038126390427351,
                0.004353079479187727,
                0.03196972608566284,
                -0.10336911678314209,
                0.009582159109413624,
                -0.026701828464865685,
                0.03195040300488472,
                -0.013001658953726292,
                -0.036794401705265045,
                -0.04429267719388008,
                0.058824941515922546,
                0.06123986840248108,
                -0.03145569562911987,
                0.05651276931166649,
                -0.039763499051332474,
                0.047144126147031784,
                -0.024193909019231796,
                0.036744195967912674,
                0.005889493972063065,
                -0.009791182354092598,
                0.01616065576672554,
                0.0009299627272412181,
                -0.048259299248456955,
                -0.05959196761250496,
                0.0451962873339653,
                0.0029903899412602186,
                -0.019426874816417694,
                0.007324156351387501,
                -0.049020592123270035,
                0.016521519050002098,
                0.009665428660809994,
                0.05807171389460564,
                0.026262681931257248,
                -0.008626559749245644,
                -0.0027775317430496216,
                -0.022361287847161293,
                -0.02143622189760208,
                -0.00477971974760294,
                -0.011983309872448444,
                -0.0947045385837555,
                0.059933874756097794,
                -0.04170802980661392,
                -0.015240388922393322,
                -0.004655053373426199,
                0.0028103606309741735,
                -0.008956287056207657,
                -0.0680345967411995,
                -0.008064130321145058,
                0.04145646095275879,
                -0.032747335731983185,
                -0.06884871423244476,
                0.022883247584104538,
                0.0007362602627836168,
                -0.012711012735962868,
                0.012286379933357239,
                0.05960216745734215,
                -0.009243683889508247,
                -0.015438434667885303,
                0.011138536036014557,
                0.008107341825962067,
                -0.04484261944890022,
                -0.002721283119171858,
                0.0008334285812452435,
                -0.0029036321211606264,
                0.002526654163375497,
                0.010772229172289371,
                0.003955346066504717,
                -0.01629548892378807,
                -0.014081134460866451,
                -0.044929806143045425,
                0.009723725728690624,
                -0.028658917173743248,
                0.06321034580469131,
                -0.0015052399830892682,
                -0.008328663185238838,
                0.063435398042202,
                0.03635141998529434,
                -0.03441469371318817,
                0.015473423525691032,
                -0.04168722778558731,
                -0.04847627878189087,
                0.03928849473595619,
                -0.07051870971918106,
                0.041459858417510986,
                -0.04955245926976204,
                -0.043085310608148575,
                -0.08766390383243561,
                -0.12014140188694,
                -0.07510847598314285,
                -0.02957165241241455,
                0.0009569921530783176,
                0.007857164368033409,
                0.01799912564456463,
                0.043715283274650574,
                -0.003085937350988388,
                0.025438053533434868,
                0.045857030898332596,
                -0.03323313966393471,
                0.04640292748808861,
                0.051059987396001816,
                0.0323876291513443,
                -0.011373687535524368,
                -0.005269874818623066,
                -0.030572159215807915,
                -0.01571165770292282,
                0.01730453036725521,
                0.00983717106282711,
                0.04161757603287697,
                -0.008112872950732708,
                -0.05886361747980118,
                0.006327388342469931,
                0.004678265657275915,
                0.04188378155231476,
                0.03524544835090637,
                -0.02979777194559574,
                -0.006737901829183102,
                0.030904361978173256,
                0.06391919404268265,
                -0.008908768184483051,
                0.006859422195702791,
                -0.05354020372033119,
                0.08682217448949814,
                0.0009831397328525782,
                -0.0011783952359110117,
                -0.057585135102272034,
                -0.005445195361971855,
                -0.022413458675146103,
                -0.024151373654603958,
                0.012959790416061878,
                -0.06901643425226212,
                0.03356625512242317,
                -0.05177755281329155,
                -0.005934146232903004,
                -0.009766440838575363,
                -0.006661614868789911,
                -0.030074773356318474,
                -0.02769283577799797,
                -0.010380605235695839,
                0.006685918662697077,
                -0.026325570419430733,
                -0.010080674663186073,
                0.03248981386423111,
                0.0013006811495870352,
                0.050266098231077194,
                0.03537040576338768,
                0.03647846728563309,
                -0.02540581300854683,
                0.006216001696884632,
                -0.049106430262327194,
                0.0453592911362648,
                0.02712106704711914,
                0.04450501501560211,
                -0.030206002295017242,
                0.05981660261750221,
                -0.028290124610066414,
                3.963673589169048e-05,
                -0.020664706826210022,
                0.005595169495791197,
                0.008500806987285614,
                0.03940865024924278,
                -0.00366358645260334,
                0.06151117384433746,
                -0.008741558529436588,
                -0.014335871674120426,
                0.011641060002148151,
                -0.0226654764264822,
                0.002813281025737524,
                0.03153914213180542,
                0.026791147887706757,
                0.03798497095704079,
                0.07537173479795456,
                -0.0048573375679552555,
                0.006369241513311863,
                0.050990231335163116,
                0.007256161421537399,
                -0.027678437530994415,
                -0.04292410612106323,
                0.035091955214738846,
                0.05163317546248436,
                0.024400625377893448,
                0.017811208963394165,
                -0.030938943848013878,
                -0.0019680100958794355,
                0.04036179557442665,
                0.023465527221560478,
                -0.009713732637465,
                -4.1640451070719624e-33,
                0.009953416883945465,
                -0.007877010852098465,
                0.03710111603140831,
                -0.0852983295917511,
                0.013184362091124058,
                -0.028297634795308113,
                0.03806976601481438,
                0.028270354494452477,
                -0.04010433331131935,
                -0.030876722186803818,
                -0.02735505811870098,
                0.0018098782747983932,
                0.005683851428329945,
                -0.006474240217357874,
                0.032992079854011536,
                0.048783864825963974,
                0.018449604511260986,
                0.03524063155055046,
                0.047602083534002304,
                -0.054889656603336334,
                -0.03739931061863899,
                -0.012591863051056862,
                0.024680936709046364,
                0.03320906311273575,
                0.0052968580275774,
                0.01349498052150011,
                -0.0013019046746194363,
                -0.007633599918335676,
                0.004053139127790928,
                -0.01311904564499855,
                0.025003377348184586,
                -0.05883253365755081,
                0.012102697044610977,
                -0.003362714545801282,
                0.021541647613048553,
                -0.04890351742506027,
                -0.0017581303836777806,
                0.013941793702542782,
                -0.0061570219695568085,
                0.008608962409198284,
                -0.002907677786424756,
                0.011362479999661446,
                -0.0016205767169594765,
                -0.04136188328266144,
                -0.03117428347468376,
                -0.051714226603507996,
                -0.03301459550857544,
                0.04006848856806755,
                0.016527917236089706,
                -0.016749776899814606,
                -0.030730031430721283,
                -0.022260114550590515,
                -0.029032576829195023,
                0.0513896569609642,
                0.011545608751475811,
                -0.07414120435714722,
                0.036951132118701935,
                -0.0006314608617685735,
                0.04286057502031326,
                -0.00261119962669909,
                -0.04012447968125343,
                0.0667966902256012,
                0.032989777624607086,
                0.002184564247727394,
                -0.03545291721820831,
                0.0021703459788113832,
                -0.05468651279807091,
                0.02761394903063774,
                0.06170409917831421,
                0.01793668605387211,
                -0.03353722393512726,
                0.06295274943113327,
                -0.05622495710849762,
                0.009963897056877613,
                -0.09067302197217941,
                0.0041847373358905315,
                0.01172496285289526,
                -0.05563649907708168,
                0.04441488906741142,
                0.007046232465654612,
                -0.040727242827415466,
                0.028249019756913185,
                0.05438321456313133,
                0.01871551387012005,
                -0.0004238033143337816,
                -0.07775142043828964,
                0.013682951219379902,
                0.09038832783699036,
                0.04829747974872589,
                -0.024019837379455566,
                -0.00903698056936264,
                0.05247228592634201,
                -0.017802931368350983,
                0.002907321322709322,
                -0.017552323639392853,
                -0.05754947289824486,
                0.0004063423548359424,
                0.03697648271918297,
                0.019623147323727608,
                0.01870805211365223,
                -0.05698033422231674,
                -0.02123894728720188,
                -0.03798413276672363,
                0.026684805750846863,
                0.009342193603515625,
                0.05933003127574921,
                -0.040790118277072906,
                -0.02913207747042179,
                0.009603939950466156,
                0.00244931410998106,
                0.037463728338479996,
                0.030931562185287476,
                -0.009582494385540485,
                0.001940229907631874,
                0.01653316430747509,
                0.027333596721291542,
                0.00884308386594057,
                -0.006902678869664669,
                0.01377053651958704,
                0.010468408465385437,
                0.06825398653745651,
                -0.11169060319662094,
                0.03013554960489273,
                0.006393562536686659,
                -0.02222016453742981,
                0.008206053636968136,
                0.03751964122056961,
                0.02502329833805561,
                0.023698953911662102,
                -0.008096123114228249,
                0.015485920011997223,
                0.00578512204810977,
                1.6652006706863176e-07,
                -0.019576769322156906,
                0.09426046907901764,
                0.023106008768081665,
                0.01367135252803564,
                -0.017549019306898117,
                0.0016196465585380793,
                0.027315843850374222,
                -0.009710393846035004,
                -0.06680026650428772,
                0.05647577717900276,
                -0.04704154282808304,
                -0.03594089671969414,
                -0.02219199948012829,
                -0.07434156537055969,
                -0.006867693271487951,
                -0.04304777830839157,
                -0.017306827008724213,
                0.06115039810538292,
                -0.05044548213481903,
                0.02029057778418064,
                -0.03353727608919144,
                0.017574630677700043,
                0.039229005575180054,
                0.008185967803001404,
                -0.02652818150818348,
                -0.016243871301412582,
                -0.015341076999902725,
                0.014375914819538593,
                0.07450084388256073,
                0.015685230493545532,
                -0.03188614919781685,
                0.06446463614702225,
                0.023240726441144943,
                -0.02113940753042698,
                0.01682177372276783,
                0.0008876918582245708,
                0.010759891010820866,
                0.008646534755825996,
                0.003898009890690446,
                0.05892283841967583,
                0.01337390299886465,
                -0.00897249300032854,
                -0.004895272199064493,
                -0.0020480791572481394,
                0.021983472630381584,
                0.04492010921239853,
                -0.01082848384976387,
                0.030371205881237984,
                -0.06975827366113663,
                0.0004901501815766096,
                -0.029709739610552788,
                0.027995118871331215,
                0.011368732899427414,
                -0.01888708584010601,
                0.030444249510765076,
                -0.03402961418032646,
                -0.01368445809930563,
                0.029070494696497917,
                0.06748782098293304,
                -0.0613531619310379,
                0.03497377410531044,
                -0.0166975948959589,
                0.003983055707067251,
                0.0015810952754691243,
                0.08247783780097961,
                -0.03583771362900734,
                -0.008507351391017437,
                1.2428466184989285e-34,
                -0.018112942576408386,
                -0.013483075425028801,
                0.006240454968065023,
                0.04920715466141701,
                -0.0033692854922264814,
                -0.048755452036857605,
                -0.017126651480793953,
                0.022510873153805733,
                -0.016140226274728775,
                -0.0025571982841938734,
                -0.022325925529003143
            ]
        },
        "Experience": {
            "name": "My aim is to connect people with rewarding work, simple really. Our focus is on energy and digital professionals who play an ever- growing role in the modern economy, we want to ensure they are supported in their journey. We use technology to make it as easy as possible to support this community. We are a B_Corp organisation as we want to be the change we see in the world, fairer more inclusive with a community, not corporate focus. My aim is to grow a diverse motivated team that develops great tech and plays its part in the Scottish digital economy and the communities in which we operate. Go team Nudge! Nudge is available now at www.nudgeexchange.com Experience",
            "rep": [
                0.05065951868891716,
                0.007171915378421545,
                -0.023951606824994087,
                -0.024681981652975082,
                0.009180442430078983,
                -0.00787061732262373,
                -0.016545550897717476,
                -0.0029238595161587,
                -0.010144848376512527,
                0.03231979161500931,
                0.041639022529125214,
                0.043137431144714355,
                0.0031656043138355017,
                0.10028301924467087,
                0.01556473970413208,
                -0.003596476512029767,
                0.011785153299570084,
                0.005924110300838947,
                -0.007627131417393684,
                0.010012115351855755,
                0.019891776144504547,
                0.01592925377190113,
                -0.014135573990643024,
                0.027353055775165558,
                -0.04832635074853897,
                0.013638598844408989,
                -0.002725240308791399,
                0.07391081005334854,
                0.029896102845668793,
                0.03209902346134186,
                0.040813036262989044,
                -0.03328637033700943,
                -0.038365453481674194,
                -0.004545116331428289,
                2.2910169263923308e-06,
                -0.0225522480905056,
                -0.020884551107883453,
                -0.025048457086086273,
                -0.06929856538772583,
                -0.06025393679738045,
                0.044194191694259644,
                0.03342277184128761,
                -0.012279548682272434,
                0.03823469206690788,
                0.02104867249727249,
                0.05946408957242966,
                0.055584654211997986,
                -0.04395162686705589,
                -0.01346744503825903,
                0.05876703932881355,
                -0.011269986629486084,
                0.0018542241305112839,
                -0.048613209277391434,
                -0.022526726126670837,
                -0.0435214601457119,
                -0.011834291741251945,
                0.0143800163641572,
                -0.004018132574856281,
                0.04768933728337288,
                0.015178781002759933,
                0.034589748829603195,
                0.007099423557519913,
                -0.06505080312490463,
                -0.01977282389998436,
                0.10327878594398499,
                0.0591631680727005,
                0.03114326298236847,
                -0.06879052519798279,
                -0.019016841426491737,
                -0.03798673674464226,
                0.06166551262140274,
                -0.0032064164988696575,
                -0.01985841989517212,
                0.007228029891848564,
                0.019203882664442062,
                0.008808224461972713,
                0.002976219169795513,
                0.018780257552862167,
                0.003359811846166849,
                0.02902359515428543,
                -0.042760372161865234,
                0.07850009202957153,
                -0.021591302007436752,
                0.004236480221152306,
                0.0338570661842823,
                0.0032439734786748886,
                -0.012501170858740807,
                0.03660587593913078,
                0.051134973764419556,
                0.07089149206876755,
                0.10331746935844421,
                -0.0071909306570887566,
                -0.0026236155536025763,
                0.011094294488430023,
                -0.030457844957709312,
                -0.013193146325647831,
                0.053057003766298294,
                0.02838154137134552,
                0.014616740867495537,
                0.010985697619616985,
                0.03643661364912987,
                0.0407288558781147,
                0.028084704652428627,
                0.039753757417201996,
                0.08281905204057693,
                -0.01814761571586132,
                0.016487453132867813,
                -0.06317703425884247,
                -0.07955923676490784,
                0.0028360094875097275,
                -0.004403168335556984,
                0.021562041714787483,
                -0.04007004573941231,
                0.055075377225875854,
                -0.027960404753684998,
                -0.03463631123304367,
                -0.000842271139845252,
                -0.002225817646831274,
                0.005511550232768059,
                0.011160733178257942,
                0.021739628165960312,
                0.042963478714227676,
                0.01749941147863865,
                -0.016329461708664894,
                0.03789353743195534,
                0.04199022054672241,
                -0.07016190141439438,
                0.013363519683480263,
                -0.04280860722064972,
                -0.023031454533338547,
                0.00288155535236001,
                0.026940876618027687,
                -0.007211003452539444,
                -0.013723588548600674,
                0.048531439155340195,
                0.010087423957884312,
                0.017739957198500633,
                -0.06449271738529205,
                0.0011957604438066483,
                -0.03498128429055214,
                0.011783784255385399,
                0.0034192062448710203,
                -0.03427102416753769,
                0.022930625826120377,
                0.00031765137100592256,
                -0.04462319612503052,
                -0.006905219051986933,
                0.020693296566605568,
                0.0022575268521904945,
                -0.015583989210426807,
                0.027585335075855255,
                -0.018444878980517387,
                -0.045619938522577286,
                0.010159255005419254,
                0.010256566107273102,
                0.02721160463988781,
                0.03379465267062187,
                0.008485119789838791,
                -0.04213669151067734,
                0.04754623398184776,
                0.069181889295578,
                -0.0006728381267748773,
                -0.039294108748435974,
                0.03809209167957306,
                -0.048054289072752,
                -0.007483960594981909,
                0.0986933559179306,
                0.0508468858897686,
                -0.06152792647480965,
                -0.013951989822089672,
                -0.06323518604040146,
                -0.01204710640013218,
                0.004230019636452198,
                0.06078863888978958,
                0.026840845122933388,
                -0.026432571932673454,
                0.06997844576835632,
                -0.03691471368074417,
                -0.05175662040710449,
                0.0008264630450867116,
                -0.04641971364617348,
                -0.17836779356002808,
                0.02123667486011982,
                0.02292141132056713,
                -0.0032372234854847193,
                0.031232595443725586,
                0.004174989648163319,
                0.037991926074028015,
                -0.03427978232502937,
                -0.006200559437274933,
                -0.05275129899382591,
                0.003802870400249958,
                -0.043050650507211685,
                0.07233387231826782,
                0.04065390303730965,
                -0.03738632798194885,
                -0.023777170106768608,
                -0.058076150715351105,
                -0.055693287402391434,
                -0.04141094163060188,
                0.040039341896772385,
                -0.040320124477148056,
                0.004228461999446154,
                0.04292544350028038,
                0.016631051898002625,
                0.03683297708630562,
                -0.07294415682554245,
                -0.0548323392868042,
                -0.022789496928453445,
                0.06668671220541,
                0.07549171894788742,
                0.012959454208612442,
                -0.04269436374306679,
                0.011833113618195057,
                -0.024942846968770027,
                -0.05337408557534218,
                -0.008577532134950161,
                -0.016654379665851593,
                0.012316963635385036,
                0.03436803072690964,
                -0.021984290331602097,
                0.03344815596938133,
                0.013887999579310417,
                -0.03333165869116783,
                0.023245755583047867,
                -0.00808051135390997,
                -0.02278614044189453,
                0.025594307109713554,
                -0.02042149007320404,
                0.11145631223917007,
                -0.016184212639927864,
                0.020541217178106308,
                0.045237571001052856,
                -0.010327113792300224,
                0.018241483718156815,
                -0.01944703795015812,
                0.09787902235984802,
                0.1115279272198677,
                0.0385001040995121,
                -0.05500872805714607,
                0.018750859424471855,
                0.0060781147330999374,
                -0.002331889234483242,
                0.03312262147665024,
                -0.017349129542708397,
                0.01970239356160164,
                -0.04037284478545189,
                0.014721735380589962,
                -0.057864610105752945,
                -0.0017164808232337236,
                -0.030741851776838303,
                0.014367356896400452,
                0.021577028557658195,
                0.04294104874134064,
                0.00494050420820713,
                -0.02368975058197975,
                -0.05814877897500992,
                0.002469635335728526,
                -0.04979864880442619,
                0.027083061635494232,
                -0.004390900023281574,
                -0.00907363835722208,
                -0.011256666854023933,
                0.04228651896119118,
                -0.011538628488779068,
                -0.052305981516838074,
                0.034601833671331406,
                -0.039466001093387604,
                -0.02214900031685829,
                -0.0009572276030667126,
                0.013472598977386951,
                0.013471136800944805,
                0.02826465107500553,
                -0.006593037862330675,
                -0.008316745981574059,
                -0.0020053640473634005,
                0.006346555892378092,
                0.03332878276705742,
                -0.031101282685995102,
                -0.0029314763378351927,
                0.06150706484913826,
                -0.009758583270013332,
                -0.033880557864904404,
                0.044144801795482635,
                0.006434409413486719,
                -0.030911162495613098,
                0.07185699790716171,
                -0.047626785933971405,
                -0.07481425255537033,
                -0.01779189333319664,
                0.006994010880589485,
                -0.04074464365839958,
                0.0035004878882318735,
                -0.0019786423072218895,
                0.014964640140533447,
                0.02217056043446064,
                0.00014004693366587162,
                -0.05340619385242462,
                0.03297318518161774,
                0.018492991104722023,
                -0.06559789180755615,
                -0.04391723498702049,
                0.01904679276049137,
                0.011264368891716003,
                -0.06079378351569176,
                0.021183378994464874,
                0.0010948383715003729,
                -0.03852703422307968,
                0.030784957110881805,
                -0.006023438181728125,
                0.011087918654084206,
                0.023604854941368103,
                -0.012457821518182755,
                0.07510823011398315,
                0.0073790703900158405,
                0.02535485476255417,
                -0.07957757264375687,
                -0.009545808658003807,
                0.02791607193648815,
                0.030192475765943527,
                0.010024616494774818,
                -0.0014315764419734478,
                0.00019749159400817007,
                -0.008385931141674519,
                -0.006294554565101862,
                0.002974523464217782,
                -0.08694060891866684,
                -0.0017608348280191422,
                0.061581093817949295,
                -0.1020108237862587,
                0.02181507647037506,
                -0.006557983811944723,
                -0.0024514556862413883,
                0.04369935020804405,
                -0.04729968309402466,
                -0.04998863488435745,
                -0.026921305805444717,
                -0.02510681562125683,
                -0.005674839485436678,
                -0.01895476132631302,
                -0.032421063631772995,
                0.013784622773528099,
                -0.024930942803621292,
                0.0031664094422012568,
                -0.037375327199697495,
                -0.008711595088243484,
                -0.0029065387789160013,
                0.0419180691242218,
                -0.021048059687018394,
                -0.023702073842287064,
                0.02626209706068039,
                0.008456090465188026,
                -0.0010344490874558687,
                -0.006536954548209906,
                0.04786089435219765,
                -0.043988995254039764,
                -0.029911084100604057,
                0.04562296345829964,
                0.027615847066044807,
                0.010605289600789547,
                -0.06580006331205368,
                0.0013348242500796914,
                -0.046671997755765915,
                -0.014239427633583546,
                -0.032127879559993744,
                -0.04278157278895378,
                0.07035589963197708,
                -0.010458951815962791,
                0.0011826347326859832,
                -0.029918964952230453,
                0.06667576730251312,
                0.04095858335494995,
                -0.04679744690656662,
                -0.012927133589982986,
                -0.02462407946586609,
                0.03349878266453743,
                0.019323496147990227,
                0.014418973587453365,
                0.011279302649199963,
                0.004082893952727318,
                0.054379381239414215,
                -0.07157661020755768,
                -0.003780683735385537,
                -0.006776961963623762,
                0.047585926949977875,
                0.005691098514944315,
                -0.011663696728646755,
                -0.053849466145038605,
                0.004952792078256607,
                0.02233954705297947,
                0.014814997091889381,
                0.011223375797271729,
                0.006523354910314083,
                0.02514469251036644,
                -0.0132609186694026,
                0.008707857690751553,
                -0.029997311532497406,
                0.011109820567071438,
                -0.02622150257229805,
                0.0034230616874992847,
                0.03395827114582062,
                0.03579434007406235,
                -0.018421918153762817,
                -0.030037054792046547,
                -0.0441833920776844,
                0.0358174704015255,
                0.10825145989656448,
                0.018869085237383842,
                0.03313842788338661,
                -0.017773941159248352,
                -0.03743492811918259,
                -0.05708462372422218,
                0.031134692952036858,
                -0.004114064387977123,
                -0.014185844920575619,
                -0.043533723801374435,
                0.009641616605222225,
                0.027055079117417336,
                0.058568522334098816,
                -0.08065866678953171,
                0.0578332357108593,
                -0.012320032343268394,
                -0.0400376059114933,
                0.015244255773723125,
                0.0039260732010006905,
                -0.04077041521668434,
                0.056615542620420456,
                0.029675317928195,
                0.057647474110126495,
                -0.060216572135686874,
                -0.03366684913635254,
                0.02264072559773922,
                0.010973580181598663,
                0.021771559491753578,
                0.008180221542716026,
                -0.035497575998306274,
                0.02860090509057045,
                -0.0048566823825240135,
                0.015082817524671555,
                0.022014591842889786,
                -0.05256110057234764,
                0.027436308562755585,
                -0.04792495444417,
                0.0028169318102300167,
                -0.04546526074409485,
                -0.03995645418763161,
                0.01939389854669571,
                0.011428306810557842,
                -0.03366651386022568,
                -0.021302364766597748,
                0.06934977322816849,
                -0.04221503064036369,
                -0.0299408957362175,
                -0.04414103925228119,
                -0.026480618864297867,
                0.01389725785702467,
                -0.06237321346998215,
                -0.0920208990573883,
                0.018543483689427376,
                -0.03138761967420578,
                0.05683262273669243,
                0.032840125262737274,
                0.01673145592212677,
                0.03896350413560867,
                0.06783941388130188,
                -0.011966532096266747,
                0.06894183158874512,
                -0.023481804877519608,
                0.02303033322095871,
                -0.06300346553325653,
                0.04731767624616623,
                -0.037844207137823105,
                -0.03791940212249756,
                -0.02429664321243763,
                0.010151536203920841,
                0.021923663094639778,
                0.007553688250482082,
                0.04713619127869606,
                -0.01528224442154169,
                0.008163073100149632,
                0.0074837650172412395,
                -0.0037346482276916504,
                -0.006453615613281727,
                -0.012951546348631382,
                -0.007561846170574427,
                -0.0005473135388456285,
                0.028024591505527496,
                0.015496855601668358,
                -0.036386262625455856,
                -0.06357087194919586,
                0.0031488738022744656,
                -0.04613381251692772,
                0.031968388706445694,
                0.013712353073060513,
                -0.07079848647117615,
                0.006207291968166828,
                -0.06533463299274445,
                0.049424491822719574,
                -0.08636494725942612,
                0.04552777111530304,
                -0.023124322295188904,
                0.007863560691475868,
                -0.021593457087874413,
                0.044929392635822296,
                -0.013433781452476978,
                -0.010303939692676067,
                -0.032276563346385956,
                -0.028175778687000275,
                -0.007225855253636837,
                0.007552749942988157,
                -0.00011848114809254184,
                -0.0063592055812478065,
                0.005660438910126686,
                -0.0964617058634758,
                0.050328999757766724,
                -0.00798743311315775,
                -0.022577038034796715,
                0.021457990631461143,
                0.001133567187935114,
                -0.02971968986093998,
                0.016628233715891838,
                0.0228735338896513,
                -0.032555270940065384,
                0.023240773007273674,
                -0.015740778297185898,
                -0.005521210841834545,
                -0.0008110435446724296,
                -0.009369205683469772,
                -0.053597625344991684,
                0.02283821813762188,
                -0.08727584779262543,
                -0.014210572466254234,
                -0.009966559708118439,
                0.022168882191181183,
                -0.050040461122989655,
                0.023476356640458107,
                0.02278907783329487,
                0.0352049246430397,
                -0.007289103697985411,
                0.020790187641978264,
                -0.03355655446648598,
                0.02671658992767334,
                0.011498664505779743,
                0.01082361675798893,
                -0.003024005563929677,
                -0.007644627243280411,
                0.009237190708518028,
                -0.014289440587162971,
                0.010744714178144932,
                -0.07674527913331985,
                0.01581566594541073,
                -0.0345064178109169,
                -0.07284872978925705,
                0.03798622265458107,
                -0.00987333059310913,
                -6.672881026854877e-33,
                0.00792632345110178,
                -0.03585188835859299,
                0.044635605067014694,
                0.04413146898150444,
                -0.04784076288342476,
                -0.002759877359494567,
                0.017890503630042076,
                -0.020275862887501717,
                -0.03725588694214821,
                -0.03096645511686802,
                -0.005138597451150417,
                0.020163070410490036,
                0.0183835681527853,
                0.0013489529956132174,
                -0.010623959824442863,
                0.007347920909523964,
                0.07242365181446075,
                0.015227293595671654,
                0.01795957051217556,
                -0.00786896888166666,
                -0.028637483716011047,
                -0.014299469999969006,
                0.007016915362328291,
                -0.018695993348956108,
                0.07735227793455124,
                0.024914748966693878,
                -0.07487742602825165,
                0.06314089149236679,
                0.04614654928445816,
                -0.014433645643293858,
                0.009667805396020412,
                -0.009360761381685734,
                -0.013931733556091785,
                0.00454984325915575,
                0.025492075830698013,
                0.06075979024171829,
                -0.055655352771282196,
                -0.023561103269457817,
                0.005286182276904583,
                -0.016647053882479668,
                -0.05257740989327431,
                -0.006671769078820944,
                0.03638068586587906,
                -0.04628109186887741,
                -0.03819289058446884,
                0.006769587751477957,
                0.0013056762982159853,
                -0.0030755051411688328,
                0.05802588164806366,
                0.026515593752264977,
                -0.028905602172017097,
                -0.028673898428678513,
                -0.03193357586860657,
                0.038308821618556976,
                -0.028534702956676483,
                0.01566355489194393,
                0.00439012236893177,
                -0.015087800100445747,
                -0.09490091353654861,
                -0.010757017880678177,
                -0.022043196484446526,
                -0.014653886668384075,
                0.0028186303097754717,
                0.021849244832992554,
                -0.019793519750237465,
                0.03442443907260895,
                -0.01874518021941185,
                0.008984267711639404,
                -0.0034613236784934998,
                0.023173458874225616,
                -0.012808391824364662,
                0.04321109876036644,
                0.04185713455080986,
                -0.04209397733211517,
                -0.05047667399048805,
                -0.029454631730914116,
                -0.00780528737232089,
                0.024092596024274826,
                0.08608463406562805,
                -0.02079412341117859,
                -0.009048903360962868,
                0.0045273383148014545,
                0.008545379154384136,
                0.014089786447584629,
                0.054917287081480026,
                -0.03480013832449913,
                -0.024768996983766556,
                -0.047940246760845184,
                0.02802402339875698,
                0.03212231770157814,
                -0.04549003764986992,
                0.04947412759065628,
                -0.040493082255125046,
                -0.002104369690641761,
                0.046031542122364044,
                -0.018670279532670975,
                0.05222320184111595,
                -0.00514341052621603,
                0.020993852987885475,
                -0.052726149559020996,
                0.007470854092389345,
                0.020146314054727554,
                -0.06249748915433884,
                -0.001127516501583159,
                -0.0011458639055490494,
                0.002343192230910063,
                -0.032969046384096146,
                -0.01538645289838314,
                -0.029541146010160446,
                0.014768983237445354,
                0.0022572677116841078,
                0.05374838039278984,
                -0.010020524263381958,
                -0.09747547656297684,
                0.014235399663448334,
                -0.008532566949725151,
                0.03613315522670746,
                0.04697059467434883,
                0.011251307092607021,
                0.03436780720949173,
                0.04750727862119675,
                0.019541973248124123,
                -0.007633539382368326,
                -0.002220564056187868,
                -0.013080007396638393,
                0.01614411547780037,
                0.0072123208083212376,
                0.03170744329690933,
                0.0008794754976406693,
                -0.019574444741010666,
                -0.05235571041703224,
                -0.008745669387280941,
                2.9017186875535117e-07,
                0.007773019373416901,
                0.02389305830001831,
                0.0021139022428542376,
                0.0985940545797348,
                -0.009301629848778248,
                -0.0009488899959251285,
                0.025701535865664482,
                -0.017809461802244186,
                -0.028600778430700302,
                0.0020605199970304966,
                0.000586933281738311,
                -0.0381602942943573,
                -0.021770969033241272,
                -0.008865907788276672,
                -0.028290413320064545,
                -0.05523531511425972,
                0.03355012834072113,
                0.025137947872281075,
                -0.01976182498037815,
                0.019443580880761147,
                0.05347329005599022,
                -0.025520844385027885,
                0.043595090508461,
                0.004551152233034372,
                -0.01452463585883379,
                -0.0013878901954740286,
                0.007477857638150454,
                -0.06357959657907486,
                0.0392468087375164,
                0.03128909692168236,
                -0.014892887324094772,
                -0.0437115877866745,
                0.010908789932727814,
                -0.06297627091407776,
                0.0015343036502599716,
                0.0021087820641696453,
                -0.04128508269786835,
                0.034989435225725174,
                -0.0009907427011057734,
                0.015068025328218937,
                -0.026437310501933098,
                -0.020938212051987648,
                0.03815873712301254,
                -0.005558929871767759,
                -0.013755093328654766,
                0.00525345653295517,
                4.103287574253045e-05,
                -0.008139427751302719,
                -0.012575211934745312,
                -0.01760508492588997,
                -0.015746507793664932,
                0.008579227142035961,
                -0.031176339834928513,
                -0.018403051421046257,
                0.051169052720069885,
                -0.009965888224542141,
                -0.043871812522411346,
                0.03750288113951683,
                0.05878099799156189,
                0.016821786761283875,
                -0.017239250242710114,
                -0.0004248930199537426,
                -0.01688656583428383,
                -0.0007109603611752391,
                -0.057002872228622437,
                -0.02666819840669632,
                -0.024252699688076973,
                2.406613920844951e-34,
                -0.02091806009411812,
                -0.008259001187980175,
                -0.038692329078912735,
                0.006681506987661123,
                0.023542078211903572,
                -0.0279177725315094,
                -0.025824222713708878,
                -0.0016017949674278498,
                0.0379224494099617,
                -0.025472739711403847,
                -0.032829511910676956
            ]
        },
        "Freelance": "Open to both",
        "Id": "0b7424f4-ca5f-4b49-910c-e048cb1cd5f9",
        "Industry": {
            "name": "\tBioenergy",
            "rep": [
                0.07769370079040527,
                -0.005833006463944912,
                -0.01947455294430256,
                0.01913745515048504,
                -0.0045487890020012856,
                0.015439243987202644,
                -0.04848156496882439,
                0.05133933201432228,
                -0.03718943148851395,
                -0.020587919279932976,
                0.012728930450975895,
                0.027028651908040047,
                -0.0034611027222126722,
                0.031154338270425797,
                0.04978047311306,
                0.013662262819707394,
                0.08140690624713898,
                0.033506494015455246,
                -0.028240883722901344,
                -0.02238653600215912,
                -0.06964445114135742,
                -0.006685284432023764,
                0.0010355490958318114,
                0.04251566529273987,
                0.02488165907561779,
                0.040067318826913834,
                -0.01799609512090683,
                0.09161915630102158,
                0.026532072573900223,
                -0.01134824100881815,
                0.02127683535218239,
                -0.021407829597592354,
                -0.002684325445443392,
                0.00869807694107294,
                1.5375848079202115e-06,
                -0.06089668348431587,
                -0.04463314637541771,
                -0.021655479446053505,
                0.007336725015193224,
                -0.019927019253373146,
                0.04831172898411751,
                0.034228358417749405,
                -0.0020625467877835035,
                0.002090683439746499,
                0.05299313738942146,
                -0.05116841197013855,
                0.04753710329532623,
                -0.043239936232566833,
                -0.009483976289629936,
                -0.04148607328534126,
                -0.03434760123491287,
                -0.0033769700676202774,
                -0.016328485682606697,
                -0.025654282420873642,
                -0.04874476045370102,
                0.13100239634513855,
                -0.00514557771384716,
                -0.0376138836145401,
                -0.0032449616119265556,
                0.03564808517694473,
                -0.016830720007419586,
                0.06566894799470901,
                -0.03044041059911251,
                0.017063304781913757,
                0.07950837165117264,
                -0.01751537248492241,
                0.06326387077569962,
                -0.028281664475798607,
                0.041217781603336334,
                0.017141619697213173,
                0.014585047028958797,
                0.0051641506142914295,
                0.02582569234073162,
                0.044499967247247696,
                -0.049750424921512604,
                0.030383842065930367,
                0.058106061071157455,
                0.01475544273853302,
                -0.017628243193030357,
                0.0355837419629097,
                0.0015702785458415747,
                -0.029105110093951225,
                0.01595817692577839,
                -0.0025663282722234726,
                -0.009008453227579594,
                0.039504535496234894,
                -0.018601926043629646,
                -0.009344013407826424,
                0.04310806095600128,
                0.02630685456097126,
                0.05357867479324341,
                0.010644734837114811,
                0.008856864646077156,
                0.014530546963214874,
                0.07876280695199966,
                0.015942832455039024,
                -0.03868340700864792,
                0.09380640834569931,
                0.0008291350677609444,
                -0.015137210488319397,
                0.010731657035648823,
                -0.013618195429444313,
                0.04818231612443924,
                0.013922009617090225,
                -0.01977931708097458,
                -0.07702916115522385,
                -0.04054061695933342,
                -0.03187471628189087,
                -0.014399915933609009,
                -0.04717715457081795,
                0.012753316201269627,
                -0.013910778798162937,
                0.016896726563572884,
                0.006832195445895195,
                -0.0031564240343868732,
                -0.012732420116662979,
                -0.005643132142722607,
                -0.05255484953522682,
                -0.03998179733753204,
                -0.011227678507566452,
                0.01128085795789957,
                -0.0019573059398680925,
                0.03902939707040787,
                -5.530627822736278e-05,
                0.016656704246997833,
                0.05514170229434967,
                0.05911595746874809,
                0.027845323085784912,
                0.012282361276447773,
                -0.00695137120783329,
                -0.017577217891812325,
                -0.026996254920959473,
                -0.02245244011282921,
                0.0018740928499028087,
                -0.07004115730524063,
                0.05290386453270912,
                0.009772643446922302,
                -0.02403217926621437,
                -0.010829712264239788,
                -0.06015980243682861,
                0.0043336497619748116,
                -0.006039682310074568,
                -0.05556667968630791,
                0.022454552352428436,
                -0.004946937318891287,
                0.007883964106440544,
                0.039817970246076584,
                0.011738952249288559,
                0.030646145343780518,
                0.00043363607255741954,
                0.037956539541482925,
                0.0015394212678074837,
                -0.05766843259334564,
                0.014932656660676003,
                0.038172364234924316,
                0.01400343980640173,
                -0.04836254566907883,
                -0.03595392033457756,
                0.045366570353507996,
                0.0059347315691411495,
                -0.05416341871023178,
                0.016924792900681496,
                -0.03175705298781395,
                -0.016456445679068565,
                0.02911885641515255,
                -0.06023884937167168,
                -0.008503403514623642,
                -0.03783734142780304,
                -0.12460191547870636,
                -0.002340675098821521,
                0.03658510372042656,
                -0.06697295606136322,
                -0.04154273122549057,
                0.07161188125610352,
                0.007041345816105604,
                0.04506832733750343,
                0.040407050400972366,
                -0.02166597917675972,
                0.023374594748020172,
                -0.02258004993200302,
                0.011356103233993053,
                -0.07561272382736206,
                0.024082666262984276,
                -0.019536346197128296,
                -0.03744443878531456,
                -0.03163335099816322,
                0.050437428057193756,
                0.06156396493315697,
                0.03655551001429558,
                -0.034061044454574585,
                -0.004823030438274145,
                0.017098579555749893,
                -0.0005987699260003865,
                -0.005984135437756777,
                0.01771811582148075,
                0.04090520739555359,
                0.005830228328704834,
                0.04753405228257179,
                0.02659468911588192,
                -0.023265007883310318,
                0.019697412848472595,
                -0.0027878570836037397,
                -0.01602776348590851,
                -0.006665061693638563,
                -0.014461761340498924,
                0.017532004043459892,
                -0.016791386529803276,
                -0.0030751482117921114,
                -0.08955687284469604,
                -0.030050143599510193,
                -0.013572483323514462,
                -0.012628640979528427,
                -0.01763373240828514,
                0.043742477893829346,
                0.006689457688480616,
                -0.016887938603758812,
                -0.012522051110863686,
                -0.009399570524692535,
                -0.0898999571800232,
                0.0318705253303051,
                0.00029263622127473354,
                0.015905065461993217,
                0.019502010196447372,
                -0.009124604985117912,
                0.009242100641131401,
                0.0484689436852932,
                -0.05914345011115074,
                -0.012369616888463497,
                0.04590630158782005,
                0.006699858233332634,
                0.014293249696493149,
                0.0013552872696891427,
                0.06011122465133667,
                0.0074019418098032475,
                -0.0334511399269104,
                0.03297780454158783,
                0.053606048226356506,
                -0.001615356421098113,
                0.006846006028354168,
                0.023095199838280678,
                -0.03258242830634117,
                0.05139971897006035,
                0.061979036778211594,
                0.02085976116359234,
                0.037851978093385696,
                -2.213187144661788e-05,
                0.03687278553843498,
                0.005909954663366079,
                0.044575128704309464,
                0.021897345781326294,
                -0.0018799907993525267,
                0.010550325736403465,
                0.021379277110099792,
                0.047089386731386185,
                0.06397800147533417,
                -0.057579852640628815,
                0.00952207576483488,
                0.03376948833465576,
                -0.026998037472367287,
                0.023148512467741966,
                0.06551330536603928,
                -0.06952476501464844,
                -0.0489029735326767,
                0.02252940647304058,
                -0.004206588491797447,
                -0.03214092552661896,
                0.1049807220697403,
                -0.03526860475540161,
                -0.011357957497239113,
                -0.014195509254932404,
                0.010462685488164425,
                -0.00888054445385933,
                0.020822525024414062,
                0.02537834271788597,
                -0.002496645785868168,
                0.007408022414892912,
                0.04006671905517578,
                0.016252389177680016,
                -0.05577724799513817,
                -0.0023403975646942854,
                0.010694053955376148,
                0.026945561170578003,
                -0.01403158251196146,
                0.01039139460772276,
                -0.04473794996738434,
                0.09322294592857361,
                0.010355946607887745,
                -0.01117311604321003,
                -0.05787530168890953,
                0.009537608362734318,
                0.029031189158558846,
                -0.01880517601966858,
                -0.022024763748049736,
                -0.020509744063019753,
                -0.028751859441399574,
                0.003563511185348034,
                -0.015366989187896252,
                -0.024555208161473274,
                0.03575458750128746,
                -8.716567390365526e-05,
                -0.06576184928417206,
                0.05066289007663727,
                -0.047915246337652206,
                0.006820580922067165,
                0.01896158792078495,
                0.04890276491641998,
                0.008323414251208305,
                -0.057588960975408554,
                0.03772023320198059,
                -0.02950998581945896,
                0.021192850545048714,
                0.06090490520000458,
                0.039101749658584595,
                0.08194531500339508,
                0.012990353628993034,
                -0.0539543516933918,
                0.008180194534361362,
                -0.015444534830749035,
                0.03473031148314476,
                0.03909827396273613,
                0.006235014647245407,
                -0.018847433850169182,
                0.03096625953912735,
                0.006653914228081703,
                0.006519581191241741,
                -0.015995508059859276,
                -0.04780945926904678,
                0.06551871448755264,
                0.002322838641703129,
                -0.009697690606117249,
                0.013824456371366978,
                0.009402007795870304,
                -0.004721926990896463,
                0.007086333818733692,
                -0.030218807980418205,
                -0.018610075116157532,
                0.03329897299408913,
                -0.057255085557699203,
                -0.010545577853918076,
                0.014981837943196297,
                0.008987796492874622,
                -0.028315510600805283,
                -0.0556100457906723,
                -0.00898350216448307,
                0.0014228041982278228,
                0.020228853449225426,
                -0.00038289622170850635,
                -0.008412213064730167,
                -0.048079073429107666,
                -0.024836454540491104,
                0.08007044345140457,
                0.0030154462438076735,
                -0.020198479294776917,
                -0.03640724718570709,
                -0.003784773638471961,
                0.09388966113328934,
                -0.048181720077991486,
                -0.037024401128292084,
                -0.0024826000444591045,
                0.054888732731342316,
                -0.013866299763321877,
                0.017104703933000565,
                -0.06292162835597992,
                -0.03887171670794487,
                0.01196576002985239,
                0.054114095866680145,
                0.0074407923966646194,
                0.035422664135694504,
                0.028539272025227547,
                -0.04632601514458656,
                0.046682488173246384,
                0.01371600478887558,
                -0.05517718568444252,
                0.02196544036269188,
                -0.030467767268419266,
                0.04690352454781532,
                0.012439625337719917,
                0.016115494072437286,
                0.0009424760937690735,
                -0.019540198147296906,
                0.03648639842867851,
                -0.014956001192331314,
                -0.0365801677107811,
                0.017721425741910934,
                -0.0044959532096982,
                0.029935425147414207,
                0.021467993035912514,
                -0.03129544109106064,
                0.019467812031507492,
                0.0006497706635855138,
                -0.021132202818989754,
                0.027404818683862686,
                -0.06282279640436172,
                -0.0013861183542758226,
                0.017331337556242943,
                -0.03439303860068321,
                -0.03794718161225319,
                0.021274756640195847,
                -0.03805985301733017,
                0.0180681012570858,
                -0.004678746685385704,
                0.06111002713441849,
                0.012248153798282146,
                0.004953976254910231,
                -0.050508759915828705,
                -0.007437019143253565,
                0.10018204152584076,
                -0.023089533671736717,
                0.05350589379668236,
                -0.004263975191861391,
                0.055832937359809875,
                -0.050054002553224564,
                0.01928599923849106,
                -0.09453115612268448,
                -0.050704892724752426,
                0.0011249794624745846,
                -0.008744113147258759,
                -0.023815443739295006,
                -0.02101295441389084,
                -0.01132354699075222,
                -0.006181152071803808,
                0.048147138208150864,
                0.01996776834130287,
                -0.01747455634176731,
                0.011586232110857964,
                0.022934088483452797,
                0.006087134126573801,
                0.005405483301728964,
                0.022843658924102783,
                0.0096298111602664,
                -0.019791871309280396,
                -0.10727641731500626,
                -0.06321223080158234,
                0.01206936500966549,
                -0.05301312729716301,
                -0.018569137901067734,
                -0.03437361121177673,
                -0.019715895876288414,
                -0.022831348702311516,
                -0.013348699547350407,
                0.011511293239891529,
                -0.02553260140120983,
                -0.006747421342879534,
                -0.05695614218711853,
                -0.006551980506628752,
                0.11403977125883102,
                -0.011844796128571033,
                -0.018202178180217743,
                -0.048575613647699356,
                -0.0033093474339693785,
                -0.009251273237168789,
                -0.058267559856176376,
                -0.08918949216604233,
                0.051428619772195816,
                0.035449475049972534,
                0.026792801916599274,
                0.008311818353831768,
                0.011006140150129795,
                -0.009304545819759369,
                -0.025664810091257095,
                0.04331878200173378,
                0.021486669778823853,
                -0.052800290286540985,
                0.036620549857616425,
                0.06344719976186752,
                0.03260781615972519,
                0.0785449892282486,
                -0.06519021838903427,
                0.009291796945035458,
                0.0317382775247097,
                0.06067441403865814,
                -0.02267174981534481,
                0.019909238442778587,
                0.03209073096513748,
                -0.008203578181564808,
                -0.04983457550406456,
                0.015729399397969246,
                -0.05660279467701912,
                0.00982267688959837,
                0.004732244648039341,
                0.02710556797683239,
                -0.0019691381603479385,
                -0.03461930900812149,
                -0.013243140652775764,
                0.008360711857676506,
                -0.02463115192949772,
                0.008834119886159897,
                0.0062013347633183,
                -0.060583170503377914,
                0.001557788928039372,
                0.009264425374567509,
                -0.01744890958070755,
                0.024082748219370842,
                0.05046088621020317,
                0.002304146299138665,
                -0.03281452879309654,
                -0.0584053136408329,
                0.031578581780195236,
                0.01385329756885767,
                0.02551659755408764,
                -0.045895759016275406,
                0.003613230772316456,
                -0.005691687110811472,
                -0.05542095750570297,
                -0.023278292268514633,
                -0.021594593301415443,
                -0.010941535234451294,
                -0.004511886741966009,
                0.0017512498889118433,
                -0.019126253202557564,
                0.019824013113975525,
                0.04382014274597168,
                -0.012571061961352825,
                0.02608003094792366,
                0.04507562890648842,
                0.015816397964954376,
                -0.020512280985713005,
                0.014496739953756332,
                0.023477958515286446,
                0.01921743154525757,
                -0.017986971884965897,
                0.020543411374092102,
                0.016032934188842773,
                -0.08244277536869049,
                0.03508901596069336,
                -0.013675076887011528,
                0.04749632254242897,
                0.010756395757198334,
                -0.03167496249079704,
                0.06267333030700684,
                0.04212302342057228,
                0.02850954234600067,
                -0.018061455339193344,
                0.008534114807844162,
                -0.04977341741323471,
                0.0028718828689306974,
                0.0007572273607365787,
                0.019584106281399727,
                -0.0008962757419794798,
                0.03977850824594498,
                -0.006383167114108801,
                0.00454685278236866,
                0.057262957096099854,
                0.02342314086854458,
                -0.0018235960742458701,
                -0.0003558093449100852,
                -0.00466950936242938,
                -0.04850911349058151,
                -0.0007797002326697111,
                -0.09281454980373383,
                0.02172764018177986,
                0.05633847415447235,
                -0.05390535667538643,
                -0.04697959125041962,
                -0.012667600065469742,
                -6.420902792496581e-33,
                -0.012131985276937485,
                -0.017671091482043266,
                -0.009745354764163494,
                0.04155667498707771,
                -0.09108739346265793,
                -0.030104652047157288,
                -0.0017417395720258355,
                -0.021627113223075867,
                -0.03824084252119064,
                0.015900075435638428,
                0.023683443665504456,
                -0.03751282021403313,
                0.0065560974180698395,
                0.007411599159240723,
                0.013182380236685276,
                0.011159227229654789,
                -0.040675949305295944,
                0.0002860048261936754,
                0.019105257466435432,
                -0.026228828355669975,
                0.03472357615828514,
                0.007919690571725368,
                -0.01500053983181715,
                0.027503879740834236,
                0.014116683043539524,
                -0.0024301561061292887,
                -0.08145906776189804,
                0.011092397384345531,
                -0.061564601957798004,
                -0.038666825741529465,
                0.010988161899149418,
                0.002816195832565427,
                -0.0011817787308245897,
                -0.03484005108475685,
                -0.01473347656428814,
                0.0034920922480523586,
                -0.0003528632514644414,
                -0.019579291343688965,
                -0.046635594218969345,
                -0.011906168423593044,
                -0.1035294383764267,
                0.010019169189035892,
                0.04169116169214249,
                -0.013178803026676178,
                -0.04840471222996712,
                -0.02076629363000393,
                -0.008423126302659512,
                -0.06023407727479935,
                0.019939523190259933,
                -0.01282249391078949,
                0.004356296733021736,
                -0.03120281733572483,
                0.004363956395536661,
                0.025432510301470757,
                -0.014406229369342327,
                0.07795801013708115,
                -0.033694297075271606,
                0.05381428450345993,
                -0.02899416908621788,
                0.005351916886866093,
                0.05075303092598915,
                0.04992566257715225,
                0.007526209577918053,
                0.009534782730042934,
                -0.018722692504525185,
                0.02302587404847145,
                -0.029871823266148567,
                -0.03228195011615753,
                -0.033368825912475586,
                0.06091485917568207,
                -0.01526084914803505,
                0.040650565177202225,
                0.02370731718838215,
                -0.032258130609989166,
                -0.024791037663817406,
                -0.03734389692544937,
                -0.030927574262022972,
                0.03167693689465523,
                0.028962375596165657,
                0.004544598050415516,
                0.011908133514225483,
                -0.052895769476890564,
                0.09625892341136932,
                -0.019703492522239685,
                -0.008725300431251526,
                0.03086993470788002,
                -0.035729505121707916,
                0.06961160153150558,
                -0.00495481351390481,
                -0.016529114916920662,
                0.008206481114029884,
                0.0169337410479784,
                0.011388266459107399,
                -0.00430207559838891,
                0.023754162713885307,
                0.09624017030000687,
                -0.009780946187675,
                -0.048601869493722916,
                -6.387287430698052e-05,
                0.019817287102341652,
                0.025031518191099167,
                0.004822661634534597,
                -0.03366808593273163,
                0.07602503895759583,
                -0.007873127236962318,
                -0.0445832833647728,
                -0.020430931821465492,
                -0.03645617887377739,
                -0.002720061456784606,
                0.024212639778852463,
                -0.014820178039371967,
                -0.002126721665263176,
                0.01571919396519661,
                -0.03001222014427185,
                0.014060198329389095,
                -0.017557885497808456,
                0.008331090211868286,
                0.005033435765653849,
                -0.049245674163103104,
                0.06726126372814178,
                0.03177809342741966,
                0.04008544981479645,
                -0.027664141729474068,
                0.05288619548082352,
                -0.030269140377640724,
                0.007721554022282362,
                -0.014915388077497482,
                0.0468422994017601,
                -0.10608953982591629,
                0.0062406244687736034,
                0.011674268171191216,
                0.029114479199051857,
                2.1519096549127426e-07,
                -0.04702235758304596,
                -0.012728576548397541,
                -0.03053017519414425,
                -0.061577245593070984,
                -0.03023367002606392,
                0.024434782564640045,
                0.024789879098534584,
                0.029730459675192833,
                -0.04622102528810501,
                -0.028831299394369125,
                -0.023384355008602142,
                -0.03911999613046646,
                -0.003811078378930688,
                -0.02921210415661335,
                -0.002861558925360441,
                -0.036314867436885834,
                0.0001032764557749033,
                0.00041878342744894326,
                0.03903009742498398,
                -0.027808062732219696,
                0.0036603393964469433,
                -0.019828153774142265,
                -0.07310666888952255,
                -0.022767672315239906,
                -0.03108791820704937,
                0.036508798599243164,
                0.027127310633659363,
                -0.01977595128118992,
                0.05279792845249176,
                0.006552015896886587,
                0.022842245176434517,
                0.014237419702112675,
                0.03064526803791523,
                -0.07125736773014069,
                -0.017559127882122993,
                -0.03461310639977455,
                0.008013002574443817,
                0.030612988397479057,
                -0.03832947462797165,
                0.028534051030874252,
                0.005695690866559744,
                0.047107845544815063,
                0.002615922363474965,
                0.04485827684402466,
                -0.07261447608470917,
                0.039848022162914276,
                0.022247688844799995,
                -0.008642110042273998,
                -0.04482226446270943,
                -0.0029399569611996412,
                -0.03766849637031555,
                -0.005484469700604677,
                -0.007469597272574902,
                -0.0013865387300029397,
                -0.023116527125239372,
                -0.020296502858400345,
                -0.013992149382829666,
                0.01953285001218319,
                0.02892795391380787,
                0.03192707523703575,
                -0.023083489388227463,
                0.06276760250329971,
                -0.03892151638865471,
                0.08101075142621994,
                0.04617752134799957,
                -0.008770817890763283,
                0.01197048556059599,
                9.147847418724e-35,
                0.016436807811260223,
                -0.023830043151974678,
                -0.10275816917419434,
                -0.012594128958880901,
                -0.024151314049959183,
                0.006061450112611055,
                -0.049334924668073654,
                0.021394625306129456,
                -0.051190491765737534,
                -0.06057390198111534,
                -0.004399037454277277
            ]
        },
        "Location": "Glasgow, Scotland, United Kingdom",
        "Remuneration": 0,
        "Services Required": {
            "name": "Founder",
            "rep": [
                0.003924353513866663,
                0.07659849524497986,
                -0.0028219774831086397,
                0.009004507213830948,
                -0.008119435049593449,
                -0.009709787555038929,
                0.0004883158253505826,
                0.006515571381896734,
                -0.027424247935414314,
                0.032803721725940704,
                0.03142417594790459,
                -0.009872103109955788,
                0.005266138818114996,
                0.028518864884972572,
                -0.021683070808649063,
                0.043470922857522964,
                -0.024339236319065094,
                0.06066643446683884,
                -0.03083062916994095,
                -0.021933702751994133,
                0.03409456089138985,
                0.04049016162753105,
                0.02110627479851246,
                -0.0012270602164790034,
                0.0009160537156276405,
                -0.0004736749979201704,
                0.034546297043561935,
                0.0367453508079052,
                -0.044234249740839005,
                0.014721540734171867,
                0.013329983688890934,
                0.0021660346537828445,
                -0.016179243102669716,
                0.019668255001306534,
                1.960125018740655e-06,
                0.01770991086959839,
                0.027725577354431152,
                -0.035426754504442215,
                -0.00892742071300745,
                -0.013746114447712898,
                -0.013251936994493008,
                0.06285420060157776,
                -0.0333070307970047,
                0.013570327311754227,
                -0.038174230605363846,
                0.00252148206345737,
                0.02322615310549736,
                0.04102025926113129,
                -0.02669968642294407,
                0.021234674379229546,
                -0.0034483543131500483,
                -0.06244472414255142,
                0.0062582893297076225,
                -0.03724139183759689,
                -0.003913057968020439,
                2.0488381778704934e-05,
                -0.008700385689735413,
                0.08751320093870163,
                -0.07904678583145142,
                -0.023267000913619995,
                -0.018626917153596878,
                0.03949914872646332,
                -0.0015942830359563231,
                -0.015941841527819633,
                0.041756436228752136,
                0.003732716664671898,
                0.07430285960435867,
                -0.034651536494493484,
                -0.006439961958676577,
                0.005204865243285894,
                0.1012565940618515,
                0.03357303515076637,
                0.04286447912454605,
                0.11668947339057922,
                0.019801046699285507,
                -0.00531800277531147,
                0.02864711545407772,
                -0.00833872240036726,
                -0.031475406140089035,
                0.011936325579881668,
                0.0455893911421299,
                0.03393714129924774,
                -0.023378580808639526,
                0.020591838285326958,
                -0.03271108865737915,
                0.03345933184027672,
                -0.011012072674930096,
                -0.01657092198729515,
                0.004530887585133314,
                0.023766392841935158,
                0.06827519088983536,
                -0.03514840826392174,
                0.009098946116864681,
                0.024591242894530296,
                0.01538554672151804,
                -0.026378903537988663,
                -0.020142080262303352,
                0.0433942936360836,
                -0.039363376796245575,
                -0.03229685127735138,
                -0.08466623723506927,
                -0.00733441486954689,
                0.0037612507585436106,
                0.04209105670452118,
                -0.008801618590950966,
                0.051902979612350464,
                -0.00475966464728117,
                0.008827527984976768,
                0.014279332011938095,
                0.08242696523666382,
                -0.03399625048041344,
                0.02739042229950428,
                7.288496271939948e-05,
                0.035444214940071106,
                0.031675443053245544,
                -0.017452998086810112,
                -0.05850239843130112,
                0.04873435199260712,
                0.04485481232404709,
                -0.018785536289215088,
                -0.04772467166185379,
                0.018594488501548767,
                0.03519713878631592,
                0.03932533785700798,
                -0.023041829466819763,
                -0.034373022615909576,
                -0.0394272580742836,
                0.007390976883471012,
                0.02870924025774002,
                -0.03270559012889862,
                -0.027065858244895935,
                0.03194468095898628,
                -0.01816668175160885,
                -0.01674887351691723,
                0.020612778142094612,
                -0.12672273814678192,
                -0.029591666534543037,
                -0.038985565304756165,
                0.0037531054113060236,
                -0.03618105500936508,
                -1.1797599654528312e-05,
                -0.036606043577194214,
                -0.08331099897623062,
                -0.015531234443187714,
                0.007327938452363014,
                -0.012986590154469013,
                0.011915660463273525,
                -0.09002309292554855,
                0.00897346343845129,
                -0.011055327020585537,
                -0.0201541930437088,
                -0.0702870562672615,
                -0.027473295107483864,
                0.0336335189640522,
                0.06685864180326462,
                0.020833052694797516,
                0.0003060565795749426,
                -0.05686791241168976,
                0.006518635433167219,
                -0.029322125017642975,
                -0.028727276250720024,
                0.023104345425963402,
                -0.0349121168255806,
                0.008369793184101582,
                -0.0038928089197725058,
                -0.030410083010792732,
                0.1038297638297081,
                0.008121715858578682,
                -0.13051362335681915,
                0.007634328678250313,
                -0.04832558333873749,
                0.011668491177260876,
                -0.03480321168899536,
                0.10115338861942291,
                -0.006036003120243549,
                0.0038767121732234955,
                0.009122626855969429,
                -0.00515024596825242,
                -0.004149931482970715,
                0.0017078084638342261,
                0.023671474307775497,
                -0.18213149905204773,
                0.01227182149887085,
                -0.08182734996080399,
                -0.002686805324628949,
                -0.004487286321818829,
                -0.00907125510275364,
                0.03636475279927254,
                -0.009552694857120514,
                -0.0013614442432299256,
                0.03507842496037483,
                0.0102672865614295,
                -0.05146046727895737,
                0.055311791598796844,
                0.032460182905197144,
                -0.0012966236099600792,
                0.016052449122071266,
                0.03467670828104019,
                -0.039722029119729996,
                -0.029975896701216698,
                0.0006000013672746718,
                0.007900673896074295,
                0.006524081341922283,
                0.032921865582466125,
                -0.03410278260707855,
                0.004584462847560644,
                0.07379616796970367,
                -0.018621256574988365,
                -0.051853328943252563,
                -0.03467041626572609,
                0.02797541208565235,
                0.041778430342674255,
                -0.04036330804228783,
                0.0014049520250409842,
                -0.018756812438368797,
                0.021385423839092255,
                0.019204989075660706,
                0.036145344376564026,
                -0.024748288094997406,
                0.09691833704710007,
                -0.01594444364309311,
                0.003472591284662485,
                0.046565912663936615,
                -0.00498694833368063,
                -0.05516836419701576,
                -0.07274065911769867,
                0.00322817824780941,
                -0.006951752118766308,
                0.007869314402341843,
                0.03977980464696884,
                -0.02339646779000759,
                -0.0034235871862620115,
                0.007206397596746683,
                0.0088274534791708,
                -0.007475786842405796,
                0.0062602064572274685,
                0.05182787403464317,
                -0.061691876500844955,
                0.027486803010106087,
                -0.07969403266906738,
                0.01995350793004036,
                0.049830060452222824,
                0.03272133693099022,
                -0.041888926178216934,
                0.06752829253673553,
                -0.01160153467208147,
                0.018889406695961952,
                -0.03701336681842804,
                -0.003394004888832569,
                -0.029244516044855118,
                -0.01252280455082655,
                -0.03697318211197853,
                0.057730838656425476,
                -0.00011371848813723773,
                0.0052199214696884155,
                0.00013723396114073694,
                -0.045296307653188705,
                0.00015577218437101692,
                -0.0087830675765872,
                -0.034942276775836945,
                -0.05480771139264107,
                -0.06152975559234619,
                -0.007735375314950943,
                -0.017999162897467613,
                0.02504141442477703,
                0.001522770500741899,
                -0.000517258420586586,
                -0.07532227039337158,
                -0.0021199784241616726,
                -0.046071190387010574,
                0.0006716790958307683,
                -0.01471535675227642,
                0.041991014033555984,
                -0.0008734901784919202,
                0.05014285445213318,
                -0.023061223328113556,
                0.01343569066375494,
                -0.014605994336307049,
                0.0010169640881940722,
                0.019447702914476395,
                0.00462490925565362,
                -0.05580534785985947,
                -0.030520452186465263,
                -0.013704423792660236,
                0.020013099536299706,
                -0.031734857708215714,
                0.06710562855005264,
                -0.01965794526040554,
                0.01416687574237585,
                -0.017627514898777008,
                -0.04035447537899017,
                -0.019747383892536163,
                0.02169131301343441,
                0.06101443991065025,
                0.040263090282678604,
                -0.009461953304708004,
                -0.032181516289711,
                -0.023096414282917976,
                0.01394291128963232,
                0.08442878723144531,
                -0.04532159864902496,
                -0.02683687023818493,
                0.05481358990073204,
                0.005753127858042717,
                -0.043955039232969284,
                -0.026375241577625275,
                0.036922965198755264,
                0.016880657523870468,
                0.00703116599470377,
                0.0030776115600019693,
                0.00763656897470355,
                0.04774424806237221,
                0.006245863623917103,
                -0.030002258718013763,
                0.026140624657273293,
                -0.005740605294704437,
                0.030730318278074265,
                -0.031216038390994072,
                -0.009493455290794373,
                0.098580501973629,
                -0.016585933044552803,
                0.007549252361059189,
                -0.008962971158325672,
                0.01647261157631874,
                0.02365773171186447,
                0.0984283983707428,
                -0.019880065694451332,
                -0.0178645309060812,
                -0.03225239738821983,
                -0.002312726341187954,
                0.013104798272252083,
                0.057957474142313004,
                0.0030216665472835302,
                0.0055615524761378765,
                -0.000277962681138888,
                0.05113106593489647,
                -0.05323057994246483,
                -0.06243916228413582,
                0.018212374299764633,
                0.018823327496647835,
                0.020442582666873932,
                -0.011675120331346989,
                -0.006278678774833679,
                -0.04291877523064613,
                -0.030628494918346405,
                -0.026563851162791252,
                -0.0012657383922487497,
                0.029663244262337685,
                -0.04391948878765106,
                -0.03069736622273922,
                0.006544768810272217,
                -0.009450811892747879,
                -0.00420659314841032,
                -0.0071156760677695274,
                -0.0011004467960447073,
                0.017242342233657837,
                -0.028567219153046608,
                -0.02758103236556053,
                0.010187570005655289,
                0.017423365265130997,
                -0.03800377622246742,
                -0.056333787739276886,
                0.052944839000701904,
                -0.03385782614350319,
                0.056589167565107346,
                -0.03292341157793999,
                0.03105122782289982,
                0.04105052724480629,
                -0.011406638659536839,
                -0.019240280613303185,
                0.0022612272296100855,
                0.05658746138215065,
                0.0023340783081948757,
                0.019088223576545715,
                0.024383874610066414,
                0.015454074367880821,
                0.04478909820318222,
                0.014162982814013958,
                0.02786874957382679,
                0.03273366391658783,
                0.024378476664423943,
                -0.028174350038170815,
                0.016626544296741486,
                -0.03206433728337288,
                -0.018025964498519897,
                0.005384307820349932,
                -0.0351920910179615,
                0.0007363528711721301,
                0.007852040231227875,
                0.004615836776793003,
                -0.0025015107821673155,
                -0.07802169770002365,
                0.019592398777604103,
                0.005213549826294184,
                -0.01657090149819851,
                -0.05709030106663704,
                -0.02195148728787899,
                0.018968675285577774,
                -0.03367021307349205,
                0.01551117654889822,
                0.029033605009317398,
                0.034430261701345444,
                0.031140044331550598,
                -0.019233202561736107,
                -0.04412948340177536,
                -0.0752059817314148,
                0.07832279056310654,
                -0.03172297775745392,
                0.021101241931319237,
                0.04371321201324463,
                -0.01805930957198143,
                -0.021453164517879486,
                -0.029748963192105293,
                -0.0019277898827567697,
                -0.009809082373976707,
                -0.07773333042860031,
                -0.022163763642311096,
                0.01386972889304161,
                0.045626211911439896,
                -0.06555663794279099,
                0.04176447167992592,
                -0.01251364778727293,
                -0.009338164702057838,
                0.056416627019643784,
                0.04447900131344795,
                0.01342753041535616,
                0.0037832632660865784,
                0.05472632870078087,
                0.02174893207848072,
                -0.007245384156703949,
                0.012964236550033092,
                -0.02367791347205639,
                -0.02656138688325882,
                0.03549972176551819,
                0.04013093560934067,
                -0.10894016176462173,
                -0.07344707101583481,
                0.05255128815770149,
                0.010969786904752254,
                -0.045251861214637756,
                0.02272648736834526,
                0.03231535479426384,
                -0.05098177492618561,
                -0.00464523583650589,
                -0.025352023541927338,
                -0.04542165249586105,
                -0.008819088339805603,
                0.015174955129623413,
                -0.04021706059575081,
                -0.010120835155248642,
                -0.03901979699730873,
                -0.0742134377360344,
                -0.007937616668641567,
                -0.007374724838882685,
                -0.0009112931438721716,
                -0.015514831058681011,
                0.04827470704913139,
                -0.04562998563051224,
                -0.0030523736495524645,
                0.0038575506769120693,
                -0.029730940237641335,
                0.025432828813791275,
                0.029236190021038055,
                -0.027699749916791916,
                0.053764067590236664,
                0.0014881712850183249,
                -0.028812509030103683,
                -0.039540618658065796,
                0.025960834696888924,
                -0.04896026849746704,
                -0.022069156169891357,
                -0.0029395963065326214,
                0.016752149909734726,
                -0.028653357177972794,
                -0.038894832134246826,
                0.0016353640239685774,
                -0.02165803126990795,
                -0.002766588469967246,
                0.07398245483636856,
                0.03781944885849953,
                0.009150922298431396,
                0.05040579289197922,
                -0.025872627273201942,
                0.020862732082605362,
                0.03302258998155594,
                -0.003811539150774479,
                0.02291749231517315,
                0.006429347209632397,
                -0.015502488240599632,
                0.002995045157149434,
                0.013672024011611938,
                -0.019381871446967125,
                0.011483491398394108,
                -0.008055920712649822,
                0.023549512028694153,
                0.08101571351289749,
                -0.013770685531198978,
                -0.05996611714363098,
                -0.008741355501115322,
                -0.03731938451528549,
                -0.004374320153146982,
                0.023355059325695038,
                0.033795855939388275,
                0.07850933074951172,
                0.002076584380120039,
                0.0076098693534731865,
                -0.020085981115698814,
                -0.010648136027157307,
                -0.006958991289138794,
                -0.0019518232438713312,
                -0.02346744015812874,
                -0.02482929453253746,
                -0.032210689038038254,
                -0.06515873968601227,
                0.03251562640070915,
                -0.023411786183714867,
                -0.022857241332530975,
                -0.016032859683036804,
                0.06834106147289276,
                -0.010420884937047958,
                0.004842379130423069,
                -0.02282843552529812,
                0.013404382392764091,
                -0.000819791981484741,
                -0.03728650137782097,
                0.0023922764230519533,
                0.040929246693849564,
                0.017674211412668228,
                0.03164193406701088,
                0.012918236665427685,
                0.014433653093874454,
                0.03101733699440956,
                -0.02255871333181858,
                0.011786156333982944,
                -0.04263996332883835,
                0.032793037593364716,
                -0.0083151925355196,
                -0.07890674471855164,
                0.01002985704690218,
                -0.007906116545200348,
                -0.07052253186702728,
                0.08084265142679214,
                0.002357283141463995,
                0.0030447363387793303,
                0.03698902577161789,
                0.013297847472131252,
                0.013907513581216335,
                0.029591387137770653,
                -0.038549602031707764,
                0.03065403923392296,
                -0.028557797893881798,
                -0.021827245131134987,
                -0.08714974671602249,
                -0.037061657756567,
                0.02774125523865223,
                -7.831660582692441e-33,
                0.027827410027384758,
                0.04046449810266495,
                0.011522430926561356,
                -0.009835735894739628,
                -0.01776314340531826,
                -0.013034069910645485,
                0.005516435019671917,
                0.04569673165678978,
                -0.06354694068431854,
                -0.04033233970403671,
                -0.037355825304985046,
                -0.03682122379541397,
                0.02509165368974209,
                -0.0022123565431684256,
                -0.009253687225282192,
                -0.028106315061450005,
                -0.015177392400801182,
                -0.004829610697925091,
                -0.0014089263277128339,
                0.03203501179814339,
                -0.026423241943120956,
                -0.013473935425281525,
                -0.031930603086948395,
                0.04691532254219055,
                -0.005076071247458458,
                0.0015521578025072813,
                0.005377636756747961,
                0.036014754325151443,
                0.004614861216396093,
                -0.02841787599027157,
                -0.017640646547079086,
                -0.03395562618970871,
                -0.017410993576049805,
                0.008843839168548584,
                -0.0057350145652890205,
                -0.03362138941884041,
                0.0046957703307271,
                0.09199100732803345,
                0.01059802807867527,
                0.0008375751203857362,
                -0.0805952399969101,
                0.046627145260572433,
                -0.02501404844224453,
                0.0048769754357635975,
                0.02223334275186062,
                -0.005041054449975491,
                -0.005040483083575964,
                0.02548934519290924,
                0.011260774917900562,
                -0.011071758344769478,
                -0.018239345401525497,
                0.010103834792971611,
                -0.029971245676279068,
                -0.01993546076118946,
                0.019677715376019478,
                -0.019711166620254517,
                -0.020019667223095894,
                -0.03130623698234558,
                0.05999884381890297,
                -0.035243649035692215,
                0.011416886001825333,
                -0.0258963443338871,
                -0.011902653612196445,
                0.08400891721248627,
                -0.05456262826919556,
                -0.00872363056987524,
                0.08116628229618073,
                0.08533161133527756,
                0.0028924052603542805,
                0.0796617642045021,
                -0.038115791976451874,
                0.03557911142706871,
                0.010912049561738968,
                0.074427530169487,
                -0.04738440364599228,
                0.013654513284564018,
                -0.029381301254034042,
                0.0066963136196136475,
                0.030379215255379677,
                -0.0424603670835495,
                0.006026195362210274,
                -0.011285192333161831,
                0.009856085292994976,
                -0.01646275445818901,
                -0.01394105888903141,
                -0.06862641125917435,
                -0.01153558399528265,
                0.02231265977025032,
                -0.03065258078277111,
                0.008453070186078548,
                -0.0019192112376913428,
                -0.021127089858055115,
                0.01971992291510105,
                -0.022777598351240158,
                -0.020085657015442848,
                -0.04945766180753708,
                0.0011042029364034534,
                0.024719977751374245,
                0.036131102591753006,
                -0.027579180896282196,
                -0.027965256944298744,
                0.049483783543109894,
                -0.04414945840835571,
                0.10373177379369736,
                0.003077612491324544,
                0.004162202123552561,
                0.016065236181020737,
                -0.023267069831490517,
                0.018031414598226547,
                -0.0279147457331419,
                -0.0028820782899856567,
                0.0003333865897729993,
                0.029113825410604477,
                -0.050077781081199646,
                0.004430476576089859,
                0.013744995929300785,
                -0.01751568168401718,
                0.011429852806031704,
                0.0339629128575325,
                -0.03559073060750961,
                0.01836840622127056,
                -0.00435632886365056,
                0.006002048030495644,
                4.7815679863560945e-05,
                -0.03747190535068512,
                -0.0019116809125989676,
                0.013044449500739574,
                0.052232109010219574,
                0.037385717034339905,
                -0.01883372664451599,
                -0.0061648390255868435,
                -0.05527720972895622,
                2.5835262817963667e-07,
                0.007831153459846973,
                0.012710976414382458,
                0.009739313274621964,
                -0.0898110419511795,
                -0.010140364989638329,
                0.005867327563464642,
                0.061108238995075226,
                0.009962933138012886,
                -0.06812173128128052,
                -0.03505394235253334,
                0.0196105744689703,
                0.018467234447598457,
                -0.01207845751196146,
                -0.03220897540450096,
                0.00035025173565372825,
                -0.09549298882484436,
                0.00824467558413744,
                0.01218326948583126,
                0.03139032423496246,
                -0.023672619834542274,
                0.06634857505559921,
                -0.011199939996004105,
                0.02245209366083145,
                -0.017658717930316925,
                0.024095568805933,
                0.005784910172224045,
                0.04942571744322777,
                -0.01707974448800087,
                -0.004862857982516289,
                -0.005767785012722015,
                -0.05406275391578674,
                -0.021517286077141762,
                -0.04461413994431496,
                -0.06480187922716141,
                0.03059713914990425,
                -0.006190704181790352,
                -0.00031930944533087313,
                -0.012767205014824867,
                0.022831248119473457,
                0.08516994118690491,
                -0.04184316471219063,
                -0.008350562304258347,
                -0.00555624533444643,
                0.030053574591875076,
                -0.003162741893902421,
                0.09915921837091446,
                -0.0005637260037474334,
                0.04597488418221474,
                -0.08870269358158112,
                0.0042203264310956,
                -0.022041358053684235,
                0.014440644532442093,
                0.06242848187685013,
                0.015651727095246315,
                -0.013352600857615471,
                -0.01859448105096817,
                -0.014374981634318829,
                0.012291629798710346,
                0.03943371772766113,
                -0.051456328481435776,
                -0.014998353086411953,
                0.008414641954004765,
                0.017236795276403427,
                0.04832881689071655,
                0.04892854765057564,
                0.037528976798057556,
                0.034101542085409164,
                1.2347863317955118e-34,
                -0.008156649768352509,
                -0.013489686883985996,
                0.03020278736948967,
                0.04980161413550377,
                0.024728400632739067,
                -0.0055993665009737015,
                -0.03969408571720123,
                -0.0021096470300108194,
                -0.02406333014369011,
                -0.013031410053372383,
                0.00857425294816494
            ]
        },
        "Skills": {
            "name": [
                "Microsoft Power BI",
                "Pandas (Software)",
                "SQL"
            ],
            "rep": [
                -0.07438284903764725,
                0.04029863700270653,
                -0.038986556231975555,
                -0.05620068311691284,
                -0.02454148232936859,
                0.030031394213438034,
                0.007698372006416321,
                -0.018918296322226524,
                0.011085529811680317,
                0.0496254526078701,
                0.047289106994867325,
                -0.016394633799791336,
                0.019066236913204193,
                0.12347874045372009,
                0.03700907155871391,
                -0.019221540540456772,
                0.04368475079536438,
                0.06382524967193604,
                0.012164304032921791,
                0.03276154771447182,
                -0.0028340087737888098,
                -0.002924501895904541,
                -0.009334844537079334,
                -0.014489119872450829,
                -0.009743481874465942,
                0.023746773600578308,
                -0.003850323148071766,
                -0.014674639329314232,
                -0.02378527633845806,
                -0.032337576150894165,
                0.024345826357603073,
                0.047239359468221664,
                -0.007373608183115721,
                0.02160230092704296,
                1.269154267902195e-06,
                -0.039990007877349854,
                -0.03694785758852959,
                0.03699321672320366,
                -0.029148109257221222,
                -0.034499019384384155,
                -0.014784871600568295,
                0.02349574863910675,
                0.06600333005189896,
                0.041956640779972076,
                -0.039242684841156006,
                -0.0018132542027160525,
                -0.009877490811049938,
                -0.06230068579316139,
                -0.003432288533076644,
                -0.046746592968702316,
                -0.012437970377504826,
                0.023763444274663925,
                -0.010217527858912945,
                -0.033340584486722946,
                0.029794776812195778,
                0.0009732703329063952,
                0.01407180167734623,
                -0.018214823678135872,
                0.03818429633975029,
                -0.039451465010643005,
                0.037294842302799225,
                0.007895777001976967,
                -0.01875540241599083,
                -0.0030231052078306675,
                0.01105217169970274,
                0.012476139701902866,
                0.07483480125665665,
                -0.008537177927792072,
                -0.004438555333763361,
                -0.016154028475284576,
                0.028822416439652443,
                0.01720595546066761,
                0.0007087709382176399,
                0.024324651807546616,
                -0.017185505479574203,
                -0.0029813838191330433,
                7.602848927490413e-05,
                -0.015546322800219059,
                0.0066533880308270454,
                -0.018958378583192825,
                -0.044579263776540756,
                0.01651901565492153,
                -0.033923059701919556,
                -0.029693586751818657,
                -0.05432986095547676,
                0.01901404559612274,
                -0.025008654221892357,
                -0.0007211436750367284,
                0.03496209532022476,
                -0.004718235228210688,
                0.040536265820264816,
                0.006267623044550419,
                -0.006794555112719536,
                -0.006047939881682396,
                -0.03350139409303665,
                -0.004602885339409113,
                0.04136607050895691,
                0.051649510860443115,
                0.012851983308792114,
                -0.04674427583813667,
                0.04846510663628578,
                -0.008545415475964546,
                0.0032383969519287348,
                0.048402637243270874,
                0.07045767456293106,
                0.026503805071115494,
                -0.008352737873792648,
                -0.03959605470299721,
                0.026547500863671303,
                -0.032763056457042694,
                -0.01858399622142315,
                -0.0065762316808104515,
                -0.003772588912397623,
                0.006681532133370638,
                -0.023649264127016068,
                0.02569578029215336,
                -0.019258463755249977,
                0.0014646409545093775,
                -0.09088043123483658,
                0.0034712308552116156,
                0.019928816705942154,
                0.013238375075161457,
                0.019505299627780914,
                0.0075385719537734985,
                -0.043956954032182693,
                -0.0035096958745270967,
                -0.006431019864976406,
                0.022307375445961952,
                -0.045291878283023834,
                -0.09476328641176224,
                -0.004850196652114391,
                -0.028531735762953758,
                -0.0002941827697213739,
                0.0003716900246217847,
                0.014609993435442448,
                -0.05466524884104729,
                0.04366957023739815,
                0.015198934823274612,
                -0.07789097726345062,
                -0.05569463223218918,
                0.010810984298586845,
                -0.029038134962320328,
                -0.0025003503542393446,
                0.020689042285084724,
                0.06623177230358124,
                0.0037776234094053507,
                -0.03851425647735596,
                -0.03504106029868126,
                -0.00672704866155982,
                0.007616427727043629,
                -0.02377145178616047,
                -0.005753337871283293,
                -0.05526432767510414,
                0.05057254061102867,
                -0.014984283596277237,
                -0.036111678928136826,
                0.03926043584942818,
                0.024676481261849403,
                -0.029017973691225052,
                -0.03835246339440346,
                -0.024503588676452637,
                -2.5730334527906962e-05,
                0.004465090110898018,
                0.033353764563798904,
                0.04979202151298523,
                -0.01564124971628189,
                -0.033350758254528046,
                0.025118716061115265,
                -0.03316042199730873,
                -0.037120234221220016,
                -0.09557025879621506,
                -0.027421897277235985,
                0.030089134350419044,
                0.05092471092939377,
                0.05633920803666115,
                -0.06720473617315292,
                0.059491369873285294,
                0.02437550574541092,
                -0.023467697203159332,
                0.010485456325113773,
                -0.025242779403924942,
                -0.14158318936824799,
                0.057511504739522934,
                0.010860523208975792,
                -0.01818207837641239,
                -0.015022960491478443,
                0.0014645259361714125,
                0.014300115406513214,
                -0.012608679942786694,
                0.01288070809096098,
                0.01489745732396841,
                -0.02069859206676483,
                -0.04908017814159393,
                0.04119467735290527,
                0.041748251765966415,
                0.023710740730166435,
                0.06842979043722153,
                0.05097954347729683,
                0.030474454164505005,
                -0.012364999391138554,
                -0.024149274453520775,
                -0.0004951153532601893,
                0.010418862104415894,
                0.0006190314888954163,
                0.031172921881079674,
                -0.06658186763525009,
                0.06934881210327148,
                -0.040636226534843445,
                0.006406685803085566,
                0.009442987851798534,
                0.02681335061788559,
                -0.015788117423653603,
                0.001496588229201734,
                0.007245906628668308,
                -0.01062763761729002,
                0.04249732941389084,
                -0.0029630661010742188,
                -0.049630455672740936,
                -0.017615288496017456,
                0.0433388277888298,
                -0.05020664259791374,
                -0.0056428732350468636,
                0.004524773918092251,
                -0.007795841433107853,
                0.02280615083873272,
                0.07769787311553955,
                0.02305159904062748,
                0.006865190342068672,
                0.053620677441358566,
                0.03328321501612663,
                -0.02666202373802662,
                0.01018341165035963,
                -0.012609553523361683,
                -0.01552224438637495,
                0.007850546389818192,
                0.06242496520280838,
                0.07311030477285385,
                0.0500401146709919,
                0.02300059050321579,
                0.032816916704177856,
                0.03423435986042023,
                -0.031278230249881744,
                -0.0027121403254568577,
                0.016011005267500877,
                0.00876394659280777,
                -0.04747900739312172,
                -0.0449143685400486,
                -0.019421426579356194,
                0.03219420462846756,
                -0.034694913774728775,
                0.04433540627360344,
                -0.00304274819791317,
                -0.0046642557717859745,
                -0.010249637067317963,
                0.06477847695350647,
                -0.01941877417266369,
                -0.10741791129112244,
                -0.004329187795519829,
                0.019663192331790924,
                0.08863158524036407,
                0.019132357090711594,
                -0.010402055457234383,
                -0.010290917940437794,
                -0.03371306136250496,
                -0.11653989553451538,
                -0.016642948612570763,
                0.05908219888806343,
                0.027202507480978966,
                -0.006447551306337118,
                0.04234452173113823,
                -0.014186867512762547,
                -0.013359342701733112,
                0.025375066325068474,
                -0.01097142230719328,
                0.012745658867061138,
                -0.03733404725790024,
                -0.027324780821800232,
                -0.03825300186872482,
                -0.028591234236955643,
                0.024087773635983467,
                0.007818196900188923,
                0.05719126760959625,
                0.01422868948429823,
                -0.006613674107939005,
                -0.02915966510772705,
                0.010409334674477577,
                0.04649726301431656,
                -0.0149503443390131,
                0.015996940433979034,
                -0.016418496146798134,
                -0.010351988486945629,
                0.03491748496890068,
                -0.01586463674902916,
                0.06463941186666489,
                -0.022952735424041748,
                -0.02213398925960064,
                0.06932932138442993,
                0.010340477339923382,
                -4.449590778676793e-05,
                -0.02607695758342743,
                -0.018692318350076675,
                -0.02669566310942173,
                -0.037678804248571396,
                0.012925725430250168,
                -0.001332456129603088,
                0.07754053920507431,
                0.06856190413236618,
                -0.09416069835424423,
                0.039203889667987823,
                -0.03346521779894829,
                0.02285309135913849,
                0.004210364073514938,
                0.02879238687455654,
                -0.008032586425542831,
                0.004492124542593956,
                -0.03184209391474724,
                0.01993671804666519,
                0.019544309005141258,
                0.0012123315827921033,
                0.011242064647376537,
                0.02286999672651291,
                -0.004666054621338844,
                -0.04256238043308258,
                -0.04703298956155777,
                -0.007287115789949894,
                -0.025210808962583542,
                -0.05140694975852966,
                0.03029855526983738,
                0.02167317271232605,
                0.017170386388897896,
                0.005582485347986221,
                -0.08313930779695511,
                0.011910034343600273,
                0.03674014285206795,
                -0.06410461664199829,
                0.009485087357461452,
                -0.03564276546239853,
                -0.029402829706668854,
                -0.012587704695761204,
                -0.028488589450716972,
                0.004060849547386169,
                0.014954190701246262,
                -0.020889146253466606,
                0.02220696024596691,
                0.026860790327191353,
                0.0064904638566076756,
                0.04804231971502304,
                -0.0006127172964625061,
                -0.03431523218750954,
                0.02861812710762024,
                0.07421399652957916,
                0.025940872728824615,
                0.008454267866909504,
                -0.01952226646244526,
                0.06807971000671387,
                -0.04041006416082382,
                0.033520132303237915,
                0.041775621473789215,
                0.007487159688025713,
                -0.010996059514582157,
                -0.06430567800998688,
                -0.01060023345053196,
                -0.057964690029621124,
                0.00024994846899062395,
                -0.058329056948423386,
                0.012896775268018246,
                -0.02139616385102272,
                -0.034850407391786575,
                0.042268306016922,
                0.004491145256906748,
                -0.03161356970667839,
                0.006453316193073988,
                0.03551064059138298,
                0.009346558712422848,
                0.05244198814034462,
                -0.0546591617166996,
                -0.02684859000146389,
                0.007348039653152227,
                -0.06198425963521004,
                0.014743741601705551,
                -0.00904152076691389,
                -0.027157725766301155,
                0.03416362777352333,
                0.017772113904356956,
                0.03516938164830208,
                -0.04491378739476204,
                -0.0768093392252922,
                -0.02267080917954445,
                -0.061731960624456406,
                0.024352889508008957,
                -0.028417441993951797,
                0.02168458141386509,
                -0.11479510366916656,
                0.005651769228279591,
                0.03898592293262482,
                -0.035958290100097656,
                -0.07482799887657166,
                -0.0075041684322059155,
                0.02372683212161064,
                -0.021350407972931862,
                0.016704225912690163,
                -0.06920146942138672,
                -0.026535216718912125,
                0.004017950501292944,
                -0.01781134307384491,
                -0.03356684371829033,
                0.026700884103775024,
                0.05767082795500755,
                -0.05592089891433716,
                0.01648031920194626,
                -0.02516743168234825,
                0.004623800981789827,
                -0.016870904713869095,
                -0.050062984228134155,
                0.09353110939264297,
                0.02577791176736355,
                -0.0030896058306097984,
                -0.003637889865785837,
                0.0414777509868145,
                0.01739216223359108,
                0.017209606245160103,
                0.02856338769197464,
                0.043650709092617035,
                0.0056950743310153484,
                0.0007675582892261446,
                -0.05608521029353142,
                0.06076661869883537,
                0.03207791969180107,
                0.012479511089622974,
                0.027812791988253593,
                0.000272033445071429,
                0.007660830393433571,
                0.007329618092626333,
                0.032871995121240616,
                -0.029793668538331985,
                0.0134640634059906,
                0.04503205046057701,
                0.020316775888204575,
                -0.03968067094683647,
                -0.020239640027284622,
                -0.03452380374073982,
                0.04971591383218765,
                0.0430837906897068,
                -0.00168816396035254,
                -0.0008199618896469474,
                0.025031432509422302,
                0.003127772593870759,
                -0.0475948192179203,
                -0.030806466937065125,
                0.054605670273303986,
                0.08641017973423004,
                -0.013158218003809452,
                -0.016307804733514786,
                -0.004745589569211006,
                0.06843116879463196,
                -0.05403006449341774,
                0.010527314618229866,
                0.017670320346951485,
                -0.019379062578082085,
                0.032784849405288696,
                0.03461851924657822,
                0.02568603865802288,
                -0.061420243233442307,
                -0.017934652045369148,
                0.016100911423563957,
                -0.012254721485078335,
                0.07489002496004105,
                -0.06280165910720825,
                -0.04346563294529915,
                -0.05490637198090553,
                -0.00536586157977581,
                -0.014869487844407558,
                -0.005534762516617775,
                0.03379437327384949,
                -0.006687113083899021,
                0.027454443275928497,
                -0.015858706086874008,
                0.015685075893998146,
                0.015467504039406776,
                0.00340472930110991,
                0.022353805601596832,
                0.0012475310359150171,
                0.07206985354423523,
                -0.029199006035923958,
                -0.08615433424711227,
                0.014877465553581715,
                -0.03196774423122406,
                0.00999207142740488,
                -0.028447475284337997,
                -0.012773293070495129,
                0.002766515826806426,
                -0.033356502652168274,
                -0.006469772197306156,
                -0.003918598871678114,
                0.01898203045129776,
                -0.12698589265346527,
                -0.03447641059756279,
                0.010643525049090385,
                0.0024235264863818884,
                0.08188940584659576,
                -0.02294016256928444,
                -0.028494052588939667,
                0.015253527089953423,
                -0.05544402077794075,
                -0.010005222633481026,
                0.06269571185112,
                -0.004617787431925535,
                0.021706294268369675,
                -0.011959959752857685,
                -0.0319969616830349,
                -0.012271958403289318,
                0.0184075515717268,
                0.0011156067484989762,
                0.024219423532485962,
                0.024411989375948906,
                -0.030547332018613815,
                -0.027056507766246796,
                0.06104220822453499,
                -0.037374742329120636,
                -0.03957724943757057,
                -0.026818133890628815,
                0.006160289514809847,
                0.026225093752145767,
                -0.027079805731773376,
                0.06276916712522507,
                0.023838156834244728,
                0.014781891368329525,
                -0.07210355252027512,
                -0.001537122530862689,
                0.04310092702507973,
                0.03190067037940025,
                -0.009284386411309242,
                -0.06170421838760376,
                -0.006656022742390633,
                -0.04184667766094208,
                -0.03444836288690567,
                0.010418920777738094,
                -0.016513828188180923,
                -0.001126506133005023,
                0.03800426051020622,
                -0.06513159722089767,
                0.001213960931636393,
                0.04675617069005966,
                -0.03398095816373825,
                0.028207682073116302,
                -0.015881719067692757,
                -0.012629643082618713,
                0.004571942612528801,
                -0.012377263978123665,
                0.0004213092033751309,
                0.011333306320011616,
                0.03399990126490593,
                -0.030496200546622276,
                0.011621464043855667,
                -0.014981473796069622,
                -5.08645221808435e-33,
                -0.0316738523542881,
                -0.015904776751995087,
                -0.023850543424487114,
                -0.023021727800369263,
                -0.009682397358119488,
                -0.00048718880861997604,
                0.01164241787046194,
                -0.05188431218266487,
                -0.03325534239411354,
                -0.01744019240140915,
                -0.031353652477264404,
                0.018407177180051804,
                0.03492772579193115,
                -0.00606162566691637,
                0.020521005615592003,
                0.0397302471101284,
                -0.00020147858595009893,
                0.012149153277277946,
                -0.005189632531255484,
                -0.02279965952038765,
                -0.02148972824215889,
                0.009578154422342777,
                -0.032585907727479935,
                -0.06505987793207169,
                0.023385949432849884,
                0.037738874554634094,
                -0.02807648293673992,
                0.029672468081116676,
                -0.020012248307466507,
                0.00475569162517786,
                0.056347738951444626,
                -0.055849589407444,
                -0.007084980141371489,
                -0.02063228189945221,
                -0.03358147293329239,
                -0.004275632090866566,
                -0.05282671004533768,
                -0.012253317050635815,
                -0.022085679695010185,
                -0.026425188407301903,
                0.007087092846632004,
                0.03033442050218582,
                0.039114974439144135,
                0.005612964276224375,
                -0.0017152728978544474,
                -0.022403473034501076,
                0.030303191393613815,
                0.001253415597602725,
                -0.022120973095297813,
                -0.02508905902504921,
                0.02308170683681965,
                -0.003770926734432578,
                -0.03435551002621651,
                0.10317541658878326,
                0.024878863245248795,
                0.01484673097729683,
                -0.020988116040825844,
                0.030024245381355286,
                -0.027923936024308205,
                0.0288113784044981,
                0.05504734069108963,
                -0.016868513077497482,
                -0.0029326574876904488,
                0.023592310026288033,
                0.05710546299815178,
                -0.024688882753252983,
                0.009803896769881248,
                -0.03280938044190407,
                0.04224593564867973,
                0.04884584620594978,
                -0.021438343450427055,
                0.06017721816897392,
                0.061908312141895294,
                0.017571143805980682,
                -0.10021915286779404,
                -0.03979438543319702,
                -0.07823049277067184,
                0.05361121520400047,
                0.040532901883125305,
                -0.058837540447711945,
                -0.001408085459843278,
                -0.0637269914150238,
                -0.01304203737527132,
                0.02589867264032364,
                0.039666809141635895,
                -0.057634949684143066,
                -0.041955772787332535,
                -0.002639749785885215,
                9.642930672271177e-05,
                0.012465305626392365,
                -0.0006077589350752532,
                0.10026445239782333,
                -0.01676175743341446,
                -0.002736738184466958,
                -0.004060275852680206,
                0.03472936153411865,
                0.02732970006763935,
                0.020980089902877808,
                0.025582842528820038,
                -0.02705167792737484,
                -0.01569698192179203,
                0.021169133484363556,
                -0.00524490000680089,
                0.052812088280916214,
                0.0055945999920368195,
                0.017080597579479218,
                0.005559223238378763,
                -0.012652269564568996,
                -0.04677506163716316,
                0.005935885943472385,
                -0.009362519718706608,
                0.04553179442882538,
                -0.032287705689668655,
                -0.0004198727256152779,
                -0.0013715771492570639,
                0.020795421674847603,
                0.032998401671648026,
                0.023598233237862587,
                0.009407581761479378,
                0.0036787833087146282,
                0.005439912900328636,
                0.028550514951348305,
                -0.010123027488589287,
                -0.009377609007060528,
                -0.0145055977627635,
                -0.04613833874464035,
                0.018450867384672165,
                -0.03147290647029877,
                -0.004133638925850391,
                0.04150823876261711,
                -0.012777965515851974,
                -0.023634256795048714,
                1.9229044312396582e-07,
                0.033020615577697754,
                0.005287348758429289,
                -0.027290955185890198,
                0.09005136042833328,
                0.017643913626670837,
                -0.03391716629266739,
                0.03393477573990822,
                -0.006586129777133465,
                0.036329008638858795,
                0.10116811841726303,
                0.044427596032619476,
                -0.03654225170612335,
                0.03470464050769806,
                0.04894736036658287,
                -0.00830682273954153,
                -0.08320115506649017,
                0.052862390875816345,
                -0.01708637736737728,
                -0.06929833441972733,
                -0.05207531154155731,
                0.0028024022467434406,
                0.0526881068944931,
                0.046611472964286804,
                0.02818216197192669,
                0.058740533888339996,
                -0.10515756905078888,
                -0.03815745562314987,
                -0.05478779599070549,
                0.006914729718118906,
                0.025129741057753563,
                0.02956743724644184,
                0.02704073116183281,
                -0.016928458586335182,
                0.028252337127923965,
                -0.027756910771131516,
                0.0480445995926857,
                -0.035016681998968124,
                0.03963533043861389,
                -0.01067339163273573,
                -0.0048958538100123405,
                0.03876369819045067,
                -0.06191844493150711,
                0.018718626350164413,
                -0.03888847678899765,
                0.047120604664087296,
                -0.009713363833725452,
                0.005015792325139046,
                0.0636640191078186,
                -0.017522325739264488,
                -0.03855360671877861,
                0.04099297150969505,
                0.021271025761961937,
                0.010901957750320435,
                -0.006407976150512695,
                -0.03449712321162224,
                -0.019595030695199966,
                0.015940571203827858,
                0.06420748680830002,
                -0.0006271922611631453,
                0.021170679479837418,
                -0.006770544685423374,
                -0.0019188903970643878,
                0.017212029546499252,
                0.019524578005075455,
                -0.00519189890474081,
                0.06336846202611923,
                0.03871743753552437,
                1.4414990796862746e-34,
                -0.016584277153015137,
                0.029619984328746796,
                0.011356504634022713,
                -0.027507731691002846,
                0.04675184562802315,
                -0.02232593484222889,
                -0.03057774342596531,
                0.009000957943499088,
                6.83784019201994e-05,
                -0.04562555253505707,
                -0.014522400684654713,
                -0.013974770903587341,
                0.03199808672070503,
                -0.007955320179462433,
                -0.023231204599142075,
                0.03378401696681976,
                0.05431630462408066,
                0.10062886774539948,
                0.01940283551812172,
                0.003698088228702545,
                -0.015382133424282074,
                0.03169111907482147,
                0.007968268357217312,
                0.04895693063735962,
                0.08724398910999298,
                -0.03945203498005867,
                -0.041129302233457565,
                0.017021728679537773,
                -0.0050453138537704945,
                -0.007158434484153986,
                0.036385200917720795,
                -0.016710814088582993,
                -0.019451605156064034,
                -0.00868892204016447,
                0.015841228887438774,
                -0.05982773378491402,
                -0.05192946270108223,
                -0.02202189527451992,
                -0.0605868436396122,
                -0.00046619647764600813,
                -0.021140562370419502,
                0.054405633360147476,
                0.013858567923307419,
                0.01872141845524311,
                0.03858751803636551,
                1.4133493095869198e-06,
                -0.08406884223222733,
                0.0034522125497460365,
                -0.026917478069663048,
                0.04412929341197014,
                0.020986955612897873,
                0.06722140312194824,
                -0.001699480228126049,
                0.028864681720733643,
                -0.023307062685489655,
                0.011386146768927574,
                -0.07311389595270157,
                0.015810100361704826,
                -0.0019085771637037396,
                0.08927861601114273,
                0.025257745757699013,
                -0.005626237019896507,
                -0.016043709591031075,
                -0.044898055493831635,
                0.011716742999851704,
                -0.0676652193069458,
                -0.03620552644133568,
                0.04085071012377739,
                0.034967854619026184,
                -0.042391445487737656,
                -0.04685940220952034,
                0.01803489215672016,
                -0.002023138105869293,
                -0.04213324561715126,
                0.02231227420270443,
                0.025796061381697655,
                0.02445332705974579,
                0.009541346691548824,
                -0.06970571726560593,
                -0.024336665868759155,
                0.02278285287320614,
                0.018419301137328148,
                -0.06110677123069763,
                0.013054726645350456,
                6.639262574026361e-05,
                0.01262760441750288,
                0.006932345684617758,
                -0.01970052532851696,
                -0.017969081178307533,
                0.015706034377217293,
                -0.03990421071648598,
                0.04918446019291878,
                0.031505946069955826,
                -0.042878177016973495,
                0.04688551276922226,
                -0.029357587918639183,
                -0.02299971878528595,
                -0.03214890882372856,
                -0.047596853226423264,
                0.01213920395821333,
                -0.02448701485991478,
                0.018592486158013344,
                -0.042976126074790955,
                0.03545022010803223,
                0.039660919457674026,
                -0.0559963621199131,
                -0.0055472771637141705,
                -0.0021550257224589586,
                -0.07137776166200638,
                0.028254859149456024,
                -0.0035345260985195637,
                -0.054821521043777466,
                -0.006341538857668638,
                0.018078109249472618,
                0.006022936198860407,
                0.01011398620903492,
                0.029301149770617485,
                -0.02269471064209938,
                0.01910216733813286,
                -0.005386121571063995,
                0.043871644884347916,
                -0.0010946121765300632,
                0.003824033308774233,
                -0.04145313799381256,
                -0.007977770641446114,
                0.03707372024655342,
                -0.0022764895111322403,
                -0.00804189033806324,
                0.007484424859285355,
                -0.041526779532432556,
                -0.018110373988747597,
                -0.006390913389623165,
                0.05599521845579147,
                0.03997601196169853,
                0.051437053829431534,
                -0.07298260182142258,
                0.05206136405467987,
                -0.04480404779314995,
                0.03255625441670418,
                -0.009725459851324558,
                0.05346233770251274,
                -0.005758862476795912,
                0.04198979213833809,
                0.06254007667303085,
                -0.014171778224408627,
                0.005810434464365244,
                -0.025974353775382042,
                0.06287214159965515,
                0.0015574098797515035,
                -0.015542169101536274,
                0.02683473564684391,
                -0.047315847128629684,
                -0.07686662673950195,
                -0.0717788115143776,
                -0.011291875503957272,
                0.004685120191425085,
                0.029497135430574417,
                0.008641510270535946,
                0.012555976398289204,
                0.014594861306250095,
                -0.01105376798659563,
                0.02710491232573986,
                0.05391925945878029,
                -0.020431427285075188,
                0.009014020673930645,
                0.01746464893221855,
                -0.04092685878276825,
                -0.025039201602339745,
                -0.05599800869822502,
                -0.029450757429003716,
                0.045417848974466324,
                0.01665242575109005,
                -0.043376624584198,
                0.05438488349318504,
                -0.03431633859872818,
                -0.043059010058641434,
                0.026051010936498642,
                -0.03408006578683853,
                0.07398657500743866,
                -0.02199012041091919,
                -0.009655885398387909,
                -0.033019691705703735,
                0.0032329063396900892,
                0.00981499720364809,
                -0.003939403686672449,
                0.035290028899908066,
                -0.0055396538227796555,
                0.04246150329709053,
                -0.0059608761221170425,
                -0.02456071972846985,
                0.008323176763951778,
                0.03611768037080765,
                -0.05379284545779228,
                0.07038949429988861,
                0.03659345209598541,
                -0.03139851987361908,
                0.01718117482960224,
                -0.06987199187278748,
                0.0315028540790081,
                -0.002412395551800728,
                -0.049828946590423584,
                -0.006066224072128534,
                -0.06602534651756287,
                0.03436101973056793,
                0.0003715040802489966,
                0.05604787915945053,
                0.020674338564276695,
                0.020985770970582962,
                -0.02987486869096756,
                0.010135824792087078,
                -0.022990167140960693,
                -0.08659033477306366,
                -0.005756951402872801,
                0.04536157473921776,
                0.0423114150762558,
                0.006953548174351454,
                -0.05931801721453667,
                0.03979005664587021,
                -0.032530058175325394,
                0.0012361228000372648,
                -0.032514046877622604,
                -0.0021480212453752756,
                -0.014999941922724247,
                -0.03011382557451725,
                -0.05653563141822815,
                0.005965829826891422,
                -0.010440494865179062,
                -0.01937573403120041,
                -0.023140765726566315,
                0.02924574725329876,
                0.04334568232297897,
                -0.019895410165190697,
                0.0721154510974884,
                0.004175441339612007,
                0.0054631405510008335,
                -0.00828141625970602,
                -0.02461060881614685,
                -0.021804705262184143,
                0.011960608884692192,
                0.019582685083150864,
                0.04417673498392105,
                -0.007291421294212341,
                -0.021824749186635017,
                0.05270710214972496,
                0.05672450363636017,
                -0.016024509444832802,
                0.00786091759800911,
                0.07206086814403534,
                0.029930071905255318,
                0.007889842614531517,
                0.04877161607146263,
                -0.06657829880714417,
                -0.0011302782222628593,
                0.042511504143476486,
                0.010080048814415932,
                0.0370631217956543,
                0.0103376479819417,
                -0.05898099020123482,
                -0.04959313943982124,
                0.014780998229980469,
                -0.014340621419250965,
                0.0269746333360672,
                -0.02676546759903431,
                0.05100042745471001,
                -0.012788469903171062,
                0.036386098712682724,
                0.014580784365534782,
                -0.052998192608356476,
                0.021626142784953117,
                -0.029446352273225784,
                0.014741149730980396,
                0.02128422074019909,
                -0.0427577830851078,
                0.040792662650346756,
                -0.021449442952871323,
                0.0014624114846810699,
                -0.010362916626036167,
                0.03522401675581932,
                -0.002877849154174328,
                0.01304341945797205,
                0.03359507769346237,
                0.003241701750084758,
                -0.008225486613810062,
                -0.02666943147778511,
                -0.038515135645866394,
                -0.02784138172864914,
                0.014148260466754436,
                -0.031053027138113976,
                -0.07985548675060272,
                -0.03871920704841614,
                0.06127629429101944,
                -0.014856033958494663,
                0.009254018776118755,
                -0.015576906502246857,
                0.04927081987261772,
                0.015461932867765427,
                0.009425326250493526,
                0.006257993634790182,
                -0.10036469995975494,
                0.02805621176958084,
                0.01648705080151558,
                -0.016857990995049477,
                -0.017796888947486877,
                0.008390090428292751,
                -0.00868306402117014,
                0.00661039212718606,
                0.012252549640834332,
                -0.0033216262236237526,
                -0.0010465446393936872,
                0.013049617409706116,
                0.06954725086688995,
                0.0011575096286833286,
                -0.01509011723101139,
                -0.008420579135417938,
                0.039031267166137695,
                -0.009911719709634781,
                0.07646592706441879,
                0.06575682014226913,
                0.01979532279074192,
                0.03633950278162956,
                -0.02261922135949135,
                0.013778705149888992,
                -0.049058180302381516,
                -0.0026986426673829556,
                0.025324342772364616,
                0.004211374558508396,
                -0.027565134689211845,
                -0.05207856371998787,
                0.024045879021286964,
                -0.021237868815660477,
                -0.005522535182535648,
                0.05945451185107231,
                -0.03943534195423126,
                0.006291946861892939,
                -0.007275490555912256,
                0.005259260535240173,
                -0.002575166989117861,
                -0.0496077835559845,
                -0.012187229469418526,
                0.03536374866962433,
                0.013651897199451923,
                -0.01615724340081215,
                -0.016965022310614586,
                -0.027792902663350105,
                0.03229125216603279,
                0.058471545577049255,
                0.018309500068426132,
                -0.0778544694185257,
                -0.016537783667445183,
                0.04879378154873848,
                0.010136271826922894,
                0.005581505596637726,
                0.054521653801202774,
                -0.011106396093964577,
                -0.006971061695367098,
                -0.02455904893577099,
                -0.06306041032075882,
                -0.04208066314458847,
                -0.046537116169929504,
                -0.02119019441306591,
                0.04667389392852783,
                0.06816370785236359,
                0.018707381561398506,
                0.012194698676466942,
                -0.006390237249433994,
                0.01937110908329487,
                -0.0028064572252333164,
                -0.009720072150230408,
                -0.0028500317130237818,
                -0.07922732084989548,
                -0.017745183780789375,
                0.034406889230012894,
                -0.02145436964929104,
                -0.03148052468895912,
                0.05195710435509682,
                -0.009445098228752613,
                0.0052020130679011345,
                -0.05266494303941727,
                0.005866359919309616,
                -0.0016720278654247522,
                -0.0277413222938776,
                -0.060569535940885544,
                0.04105652496218681,
                0.0879138857126236,
                0.0043026236817240715,
                0.02967832237482071,
                0.012800625525414944,
                -0.005147500429302454,
                -0.023016609251499176,
                -0.07757099717855453,
                0.04030920937657356,
                -0.014146655797958374,
                -0.07320685684680939,
                -0.0005471128388307989,
                0.02640175260603428,
                0.019082240760326385,
                -0.04090441018342972,
                -0.03126794844865799,
                0.01788114197552204,
                0.02318381890654564,
                0.05246317386627197,
                -0.024104269221425056,
                0.04924984648823738,
                -0.04427230730652809,
                -0.0806586742401123,
                -0.0390373170375824,
                -0.044523775577545166,
                0.03469789773225784,
                0.0320952869951725,
                0.008360032923519611,
                0.01686372607946396,
                0.02697828598320484,
                0.028881937265396118,
                0.008785119280219078,
                -0.03956735134124756,
                0.0031260885298252106,
                -0.07437985390424728,
                -0.0242486409842968,
                0.0519220195710659,
                -0.026232948526740074,
                -0.031939998269081116,
                -0.011442841961979866,
                0.00020324302022345364,
                -0.006065795663744211,
                -0.02914014458656311,
                0.07325483113527298,
                0.019540568813681602,
                -0.039038319140672684,
                -0.03513545170426369,
                0.0049496968276798725,
                -0.012842250987887383,
                0.003949601203203201,
                0.004216786008328199,
                -0.019536105915904045,
                -0.0022659925743937492,
                -0.03155256807804108,
                -0.0019088896224275231,
                -0.013492319732904434,
                -0.021760720759630203,
                0.03522668406367302,
                0.032878629863262177,
                0.009443758986890316,
                0.02680860087275505,
                -0.01064237765967846,
                -0.006324687972664833,
                -0.013671974651515484,
                0.005894367583096027,
                -6.555268191732466e-05,
                -0.050346169620752335,
                -0.027900507673621178,
                -0.05795934796333313,
                0.042530279606580734,
                0.0473397895693779,
                -0.00324821169488132,
                -0.020613832399249077,
                0.012530759908258915,
                -0.002262243302538991,
                0.0072003076784312725,
                -0.030906619504094124,
                -0.03202994167804718,
                0.08550361543893814,
                0.06798714399337769,
                0.02383727952837944,
                -0.06264667958021164,
                0.01022992841899395,
                0.06881316006183624,
                -0.03531171381473541,
                0.020916571840643883,
                -0.01865360699594021,
                0.022561123594641685,
                0.01081535778939724,
                -0.006705144420266151,
                0.020059708505868912,
                -0.10246137529611588,
                0.0003121582558378577,
                0.062096625566482544,
                -0.047453831881284714,
                0.13009408116340637,
                0.040743157267570496,
                -0.00634375587105751,
                -0.09149890393018723,
                -0.07513920962810516,
                0.021803025156259537,
                -0.04696473479270935,
                0.011475912295281887,
                -0.02944183722138405,
                -0.038259122520685196,
                0.011648446321487427,
                0.048947401344776154,
                -0.005493745673447847,
                0.07330754399299622,
                0.06944466382265091,
                -0.013505902141332626,
                -0.002360992133617401,
                -0.09812553972005844,
                -0.017363285645842552,
                0.052560582756996155,
                -0.021117690950632095,
                -0.03533222898840904,
                0.01700482703745365,
                -0.01492034550756216,
                0.02054804563522339,
                0.025037482380867004,
                0.002073256066069007,
                0.04611266776919365,
                0.05205755680799484,
                -0.049963440746068954,
                -0.004256503190845251,
                0.028532763943076134,
                0.03630191087722778,
                0.04799862578511238,
                -0.015230393037199974,
                -0.03319673612713814,
                -0.006880246568471193,
                -0.040258318185806274,
                -0.02039915882050991,
                0.03380534052848816,
                0.05108199268579483,
                0.007682862691581249,
                -0.026544982567429543,
                -0.006581044755876064,
                0.017728347331285477,
                0.01946403831243515,
                0.023376589640975,
                0.0012359926477074623,
                -0.0037477307487279177,
                -0.0798974558711052,
                0.0036083925515413284,
                -0.04082367941737175,
                -0.09341853111982346,
                -0.0361415259540081,
                -0.02822069823741913,
                -0.025193212553858757,
                0.04046132043004036,
                0.02909841388463974,
                0.05753427743911743,
                -0.09873255342245102,
                0.007552148774266243,
                -0.0632002130150795,
                0.00791911594569683,
                0.01262770313769579,
                0.039112236350774765,
                -0.0027533071115612984,
                -0.012972962111234665,
                -0.052642110735177994,
                0.011617831885814667,
                0.030614491552114487,
                -0.055309467017650604,
                0.010641111992299557,
                -0.023439807817339897,
                -0.002892234828323126,
                -0.008933504112064838,
                -0.019615886732935905,
                -0.06537159532308578,
                0.05088677629828453,
                0.04271567240357399,
                -0.01920524798333645,
                -0.0565466471016407,
                -0.015578537248075008,
                -0.04781775921583176,
                0.05616609752178192,
                -0.02694803476333618,
                0.0636729896068573,
                -0.01636062003672123,
                0.03206569328904152,
                -0.03779924660921097,
                -4.2046643143646265e-33,
                0.02581596001982689,
                -0.06749703735113144,
                -0.006671339273452759,
                0.012998420745134354,
                -1.741446612868458e-05,
                -0.01551539171487093,
                0.004910742864012718,
                0.015529387630522251,
                0.02640881948173046,
                0.011980612762272358,
                0.005346592515707016,
                -0.005386881530284882,
                0.007430105470120907,
                -0.0466417595744133,
                -0.006864737719297409,
                0.013121983967721462,
                -0.03845243155956268,
                -0.007614886853843927,
                0.022423412650823593,
                -0.06295185536146164,
                0.03774462267756462,
                0.008166498504579067,
                0.007884376682341099,
                0.05559703707695007,
                -0.030335836112499237,
                0.004709657747298479,
                -0.059905942529439926,
                -0.007686087861657143,
                -0.03467473387718201,
                -0.019504936411976814,
                0.03000391647219658,
                0.0019297031685709953,
                0.021853206679224968,
                0.028774447739124298,
                -0.01349719800055027,
                0.011999235488474369,
                -0.039083339273929596,
                0.009269517846405506,
                -0.025973787531256676,
                -0.00878342054784298,
                0.059603769332170486,
                0.027649499475955963,
                0.05204019695520401,
                0.01809852384030819,
                -0.014856579713523388,
                0.05876140668988228,
                0.012180536985397339,
                -0.028513245284557343,
                -0.035046931356191635,
                -0.00686762435361743,
                0.008540450595319271,
                -0.012028579600155354,
                0.01620027981698513,
                0.03163064271211624,
                0.019108641892671585,
                0.019205156713724136,
                -0.054293982684612274,
                0.00830828957259655,
                0.05980473384261131,
                0.0400068573653698,
                0.10292451828718185,
                0.03323470056056976,
                0.006877024192363024,
                0.01670444756746292,
                0.037976235151290894,
                -0.023010918870568275,
                0.033619463443756104,
                -0.027953073382377625,
                -0.022269506007432938,
                -0.08152412623167038,
                -0.005235082935541868,
                -0.0013314440147951245,
                -0.011171179823577404,
                -0.0237574502825737,
                -0.026872526854276657,
                -0.05648260936141014,
                -0.04375484213232994,
                0.04986456409096718,
                0.04346726834774017,
                -0.03795715793967247,
                -0.012042395770549774,
                0.01583782769739628,
                0.024944040924310684,
                0.04005163162946701,
                -0.0015659610508009791,
                0.05045153200626373,
                -0.02709696628153324,
                0.007320971228182316,
                0.006880607455968857,
                0.021529344841837883,
                -0.03763344883918762,
                0.027616683393716812,
                -0.04569830000400543,
                -0.026294250041246414,
                0.01787496730685234,
                0.0026867049746215343,
                -0.03448648378252983,
                0.046140410006046295,
                0.00802392978221178,
                -0.051625750958919525,
                0.003831164911389351,
                0.011623271740972996,
                -0.02497822977602482,
                0.02605564333498478,
                0.05318812280893326,
                -0.01910877227783203,
                0.028725218027830124,
                0.002191852778196335,
                -0.05149860680103302,
                -0.000878729042597115,
                0.008944492787122726,
                0.021666279062628746,
                0.01438343059271574,
                0.037726275622844696,
                -0.03687148541212082,
                -0.004020290914922953,
                -0.013408632017672062,
                -0.01485427375882864,
                -0.0025528266560286283,
                -0.016120653599500656,
                -0.03475772961974144,
                0.04513461887836456,
                0.0072231171652674675,
                -0.06760882586240768,
                0.0037383786402642727,
                -0.03951270878314972,
                -0.033987145870923996,
                0.006051792297512293,
                -0.04169638454914093,
                0.0343393050134182,
                -0.003081953153014183,
                -0.013224903494119644,
                2.023495540015574e-07,
                0.000526252668350935,
                0.07705207169055939,
                0.03566012904047966,
                0.0810408815741539,
                0.014397925697267056,
                0.005886247847229242,
                -0.02660137601196766,
                0.007039559073746204,
                0.013205321505665779,
                0.006532902829349041,
                0.07046851515769958,
                -0.032655272632837296,
                -0.008677001111209393,
                0.013357474468648434,
                -0.02188688889145851,
                0.04016358405351639,
                -0.010834791697561741,
                0.013960294425487518,
                -0.027770983055233955,
                -0.03071371279656887,
                -0.03679482638835907,
                0.01662304997444153,
                0.05448390170931816,
                0.03293566405773163,
                0.0720868706703186,
                -0.041562531143426895,
                -0.014981168322265148,
                -0.0060051921755075455,
                0.036721255630254745,
                -0.014912690967321396,
                0.0027962769381701946,
                -0.02644312009215355,
                0.017843768000602722,
                0.03091030940413475,
                -0.04097342491149902,
                0.017062706872820854,
                -0.03020075336098671,
                0.047453224658966064,
                -0.016704726964235306,
                0.036910247057676315,
                0.02375739812850952,
                -0.021324163302779198,
                0.011274856515228748,
                -0.09454672783613205,
                0.013889705762267113,
                0.07083550095558167,
                0.037856169044971466,
                -0.01364536490291357,
                0.016685010865330696,
                0.037030722945928574,
                0.00010111360461451113,
                0.026592789217829704,
                0.02841220423579216,
                0.040758270770311356,
                -0.06411848962306976,
                0.013347432017326355,
                0.0514138862490654,
                0.03959076106548309,
                -0.0236473698168993,
                -0.02028154395520687,
                0.012829504907131195,
                -0.05465248227119446,
                -0.03296497464179993,
                0.025633664801716805,
                0.06524426490068436,
                0.04154684767127037,
                0.012089025229215622,
                1.0376124220963093e-34,
                -0.011513414792716503,
                0.007034712936729193,
                -0.03123009391129017,
                -0.07825815677642822,
                0.029330529272556305,
                -0.034780971705913544,
                -0.021630344912409782,
                -0.010160719975829124,
                0.01627427525818348,
                -0.0035124795977026224,
                0.03136232867836952,
                -0.0053420220501720905,
                0.028125286102294922,
                -0.012849277816712856,
                -0.006173610687255859,
                -0.01855514943599701,
                0.020746838301420212,
                -0.018386032432317734,
                0.007901860401034355,
                0.03711752966046333,
                -0.012818889692425728,
                0.07957416027784348,
                -0.01989876478910446,
                -0.014163455925881863,
                0.056728389114141464,
                0.04826056957244873,
                -0.04963521659374237,
                0.041758205741643906,
                0.0005465106223709881,
                -0.049696121364831924,
                0.004044407047331333,
                -0.02666505053639412,
                0.02456717938184738,
                0.00700667779892683,
                -0.03968951851129532,
                0.013540118932723999,
                -0.010215222835540771,
                -0.011597087606787682,
                -0.0008026803261600435,
                -0.027937177568674088,
                0.005073429085314274,
                0.003755181096494198,
                0.0073798643425107,
                0.006188923493027687,
                -0.001316727022640407,
                1.8255517488796613e-06,
                -0.045250121504068375,
                -0.016163939610123634,
                -0.013009189628064632,
                -0.032121673226356506,
                0.029884107410907745,
                -0.0012212233850732446,
                -0.017043370753526688,
                -0.0022914945147931576,
                0.03422138839960098,
                -0.021877193823456764,
                0.019783413037657738,
                0.04839169606566429,
                -0.08476381748914719,
                0.004469892010092735,
                0.007110723294317722,
                0.0039506880566477776,
                -0.03281288221478462,
                -0.07835542410612106,
                -0.004885661881417036,
                -0.013438028283417225,
                -0.04302488639950752,
                0.009216465055942535,
                0.08273185789585114,
                0.009104208089411259,
                -0.014859176240861416,
                -0.023854484781622887,
                -0.03224584087729454,
                0.0025462994817644358,
                -0.01142499316483736,
                0.015429941937327385,
                -0.00853362213820219,
                0.03845665976405144,
                -0.0008910649921745062,
                0.01083050575107336,
                -0.004191489424556494,
                0.051118966192007065,
                -0.011194872669875622,
                0.027255628257989883,
                0.06723538786172867,
                -0.04381445795297623,
                -0.0340811125934124,
                -0.008325561881065369,
                0.020595578476786613,
                0.042820002883672714,
                -0.03173356503248215,
                -0.03475699573755264,
                0.020226286724209785,
                0.011614270508289337,
                0.015398790128529072,
                -0.0604679174721241,
                -0.05424340069293976,
                -0.013593882322311401,
                -0.006690345238894224,
                7.147411815822124e-05,
                -0.008484668098390102,
                0.04234270751476288,
                -0.01526094600558281,
                0.04758324846625328,
                0.00021721688972320408,
                -0.02141675166785717,
                0.009165080264210701,
                -0.037533678114414215,
                0.06231001019477844,
                0.011397713795304298,
                -0.05468084290623665,
                -0.04183190315961838,
                0.0033238446339964867,
                0.04028021916747093,
                0.021578731015324593,
                0.0314117893576622,
                -0.008928370662033558,
                0.028794871643185616,
                0.0655997097492218,
                -0.0751434937119484,
                0.0008047380833886564,
                -0.02744634822010994,
                0.03593072295188904,
                -0.022513018921017647,
                0.032689306885004044,
                0.006369112990796566,
                0.008638858795166016,
                -0.06755368411540985,
                0.049864642322063446,
                -0.006875553168356419,
                0.01638040505349636,
                0.015306683257222176,
                0.03786427527666092,
                0.011308646760880947,
                0.007853691466152668,
                -0.04135314002633095,
                -0.080685555934906,
                -0.03665303811430931,
                0.02090209722518921,
                0.0031530840788036585,
                -0.028195682913064957,
                -0.023242291063070297,
                -0.004373203497380018,
                0.0406704843044281,
                -0.03126632049679756,
                0.019679022952914238,
                0.01978304609656334,
                -0.014251689426600933,
                0.03653540089726448,
                -0.09804096072912216,
                -0.036746133118867874,
                -0.01682446151971817,
                0.009836587123572826,
                -0.0657792016863823,
                0.015190784819424152,
                0.021016925573349,
                0.011701170355081558,
                0.0007399946334771812,
                0.04119567200541496,
                -0.004035486839711666,
                0.0018774938071146607,
                -0.007775506004691124,
                0.01339979562908411,
                -0.04302416369318962,
                0.011533099226653576,
                0.056789517402648926,
                0.010608398355543613,
                0.07947573810815811,
                -0.024251239374279976,
                -0.029550796374678612,
                0.007536860182881355,
                -0.01781734637916088,
                0.0065836659632623196,
                -0.003906543832272291,
                0.009377281181514263,
                0.0016244128346443176,
                0.025123830884695053,
                -0.10097004473209381,
                0.06414345651865005,
                -0.045242540538311005,
                -0.0023239701986312866,
                -0.07057413458824158,
                0.0368979349732399,
                -0.024905668571591377,
                0.006509376689791679,
                0.04357268288731575,
                -0.03172106668353081,
                0.0476318821310997,
                0.03278786316514015,
                -0.006054242141544819,
                -0.011462233029305935,
                -0.023434562608599663,
                -0.1662672609090805,
                -0.00023445927945431322,
                0.03436404839158058,
                0.016298215836286545,
                0.01706080324947834,
                0.019686194136738777,
                -0.06694740056991577,
                -0.04076531156897545,
                -0.035352613776922226,
                0.01186231430619955,
                -0.0004928404814563692,
                0.007562804501503706,
                0.05366818979382515,
                0.0005975886015221477,
                0.004680044949054718,
                0.0698775053024292,
                -0.009379750117659569,
                -0.02529413253068924,
                0.002513984451070428,
                -0.02194930426776409,
                -0.00728513253852725,
                -0.03657783195376396,
                0.07381775975227356,
                -0.030230844393372536,
                -0.005236870143562555,
                0.03979601711034775,
                -0.0013472039718180895,
                -0.0005097131943330169,
                -0.07656064629554749,
                0.026832183822989464,
                0.005062831100076437,
                -6.040339940227568e-05,
                0.00855264812707901,
                0.03396562486886978,
                0.08971148729324341,
                0.026154229417443275,
                -0.0013106566620990634,
                0.0271446593105793,
                0.04690432921051979,
                -0.0077644395641982555,
                0.03617998585104942,
                -0.011103746481239796,
                -0.03725827857851982,
                -0.022292491048574448,
                -0.016888177022337914,
                -0.017378412187099457,
                0.0018097086576744914,
                -0.006847609765827656,
                0.025435658171772957,
                0.0019395726267248392,
                0.043448660522699356,
                0.044425733387470245,
                0.05055982246994972,
                -0.026249272748827934,
                0.002997586503624916,
                0.10728831589221954,
                0.01886644959449768,
                0.001367121934890747,
                0.013076886534690857,
                -0.015063675120472908,
                -0.07008322328329086,
                0.0213274247944355,
                -0.00736019155010581,
                0.10364416986703873,
                0.008928078226745129,
                -0.009098362177610397,
                -0.014359638094902039,
                0.036391254514455795,
                -0.000319785118335858,
                -0.021401451900601387,
                0.010911747813224792,
                0.017097430303692818,
                0.0045875608921051025,
                0.05967779457569122,
                -0.011769105680286884,
                -0.10143430531024933,
                0.0449015349149704,
                -0.07302126288414001,
                0.03562382236123085,
                0.006508040241897106,
                -0.021804066374897957,
                0.04551628977060318,
                -0.059587445110082626,
                -0.014748130924999714,
                -0.017941603437066078,
                0.0034238880034536123,
                -0.04542243108153343,
                -0.02220320701599121,
                0.013626187108457088,
                0.006740652956068516,
                -0.007459873799234629,
                0.015398596413433552,
                -0.014326414093375206,
                -0.02450646087527275,
                -0.01071117538958788,
                -0.07375426590442657,
                -0.03416072577238083,
                -0.01000391785055399,
                0.017658717930316925,
                0.008866663090884686,
                0.02136119082570076,
                -0.03849056735634804,
                -0.0053889076225459576,
                0.001992557430639863,
                0.024621568620204926,
                -0.03565181791782379,
                -0.06511350721120834,
                0.024415520951151848,
                -0.024837996810674667,
                -0.008932939730584621,
                0.01349728461354971,
                0.008454700000584126,
                -0.012744375504553318,
                0.0009520649327896535,
                0.004018300212919712,
                -0.062341511249542236,
                0.008346231654286385,
                0.01722470298409462,
                -0.014262581244111061,
                0.01310756430029869,
                -0.006128054112195969,
                -0.0073585473001003265,
                -0.01949779875576496,
                0.0326198935508728,
                0.047103576362133026,
                0.06493128091096878,
                -0.03417225182056427,
                0.0540294349193573,
                -0.0202468391507864,
                0.048245444893836975,
                0.01799681782722473,
                0.0034879709128290415,
                0.03744848445057869,
                0.03629397228360176,
                -0.023487329483032227,
                -0.06012806296348572,
                0.021606162190437317,
                -0.0381150096654892,
                0.06801345199346542,
                0.024980301037430763,
                0.060864731669425964,
                -0.025985654443502426,
                0.018130386248230934,
                0.009048907086253166,
                0.004759689327329397,
                -0.05889667198061943,
                0.05639144778251648,
                0.008270769380033016,
                -0.036739129573106766,
                0.01953868195414543,
                -0.08946864306926727,
                -0.04100614786148071,
                0.06487316638231277,
                -0.09046707302331924,
                0.024620549753308296,
                -0.0177835114300251,
                0.02235046774148941,
                0.006155943498015404,
                -0.0021159236785024405,
                -0.029432376846671104,
                0.00646976288408041,
                -0.029574425891041756,
                -0.02470296248793602,
                -0.05663508176803589,
                -0.027082279324531555,
                0.053902070969343185,
                0.017069756984710693,
                0.03643947094678879,
                -0.024920940399169922,
                -0.01119141187518835,
                0.06187735125422478,
                -0.007869775407016277,
                -0.005075219087302685,
                0.01963183842599392,
                -0.07452483475208282,
                -0.012441110797226429,
                0.05155568569898605,
                -0.00031982053769752383,
                -0.0125289810821414,
                -0.0057894024066627026,
                -0.004324241541326046,
                -0.013762356713414192,
                -0.013998834416270256,
                -0.03416064754128456,
                0.008736160583794117,
                0.020011555403470993,
                0.03799897059798241,
                -0.020579706877470016,
                0.014025615528225899,
                -0.08707353472709656,
                0.017467141151428223,
                0.04609401896595955,
                0.007386897224932909,
                -0.010812138207256794,
                0.06331128627061844,
                0.013591940514743328,
                0.03477516025304794,
                -0.07062964886426926,
                0.03380999341607094,
                0.007036334369331598,
                -0.10249175876379013,
                -0.0001559102674946189,
                -0.03514907509088516,
                0.0004370728274807334,
                -0.004371036309748888,
                -0.02636345475912094,
                -0.030576521530747414,
                -0.03477652370929718,
                0.002028613118454814,
                -0.012023307383060455,
                -0.02152877300977707,
                0.006951319519430399,
                0.026937400922179222,
                -0.024505410343408585,
                -0.059891775250434875,
                0.021783476695418358,
                0.08791042864322662,
                0.02041061781346798,
                -0.03608682006597519,
                0.03704863786697388,
                0.03032664768397808,
                0.03796859830617905,
                -0.017887376248836517,
                -0.00863706972450018,
                -0.07423720508813858,
                -0.03198103606700897,
                0.0496070459485054,
                -0.04617661237716675,
                -0.052156925201416016,
                0.029021266847848892,
                -0.036591608077287674,
                -0.0039233192801475525,
                -0.030966129153966904,
                0.06563720107078552,
                0.002475867047905922,
                0.003355710068717599,
                0.014143580570816994,
                -0.027145903557538986,
                -0.01828242838382721,
                0.07074987143278122,
                0.004366513341665268,
                0.04865424707531929,
                -0.007743195164948702,
                0.005711537320166826,
                0.008812788873910904,
                0.008446349762380123,
                0.025072826072573662,
                0.02091307006776333,
                -0.01791439577937126,
                -5.835696720168926e-05,
                -0.04401354119181633,
                -0.0039049736224114895,
                -0.0063950661569833755,
                -0.04728248715400696,
                0.013116906397044659,
                0.027983548119664192,
                -0.0367036871612072,
                -0.0866943746805191,
                0.016156373545527458,
                -0.0010128803551197052,
                0.03825047239661217,
                -0.013794240541756153,
                0.004867624957114458,
                0.011621727608144283,
                0.06165746971964836,
                -0.0481293611228466,
                -0.05160517990589142,
                -0.08847317844629288,
                0.02895386330783367,
                0.015269267372786999,
                -0.012018692679703236,
                0.013794278725981712,
                -0.011281336657702923,
                0.036963533610105515,
                -0.012305276468396187,
                -0.11809446662664413,
                -0.041694313287734985,
                -0.007689966820180416,
                -0.010703637264668941,
                0.016857899725437164,
                0.020805099979043007,
                -0.07184335589408875,
                0.01637737825512886,
                -0.02457047998905182,
                -0.0723109096288681,
                -0.012734576128423214,
                -0.024574020877480507,
                -0.012293730862438679,
                -0.05186256021261215,
                -0.026320509612560272,
                -0.01979862153530121,
                -0.021566117182374,
                0.02452458254992962,
                -0.01629909873008728,
                0.005402448121458292,
                -0.09044450521469116,
                -0.08123116195201874,
                0.04293353110551834,
                0.030660271644592285,
                -0.0007407426019199193,
                0.010058614425361156,
                0.05480637028813362,
                -0.054555606096982956,
                -0.000547423551324755,
                0.01932656019926071,
                -0.06354543566703796,
                -0.02238713763654232,
                0.017795542255043983,
                -0.014148651622235775,
                0.007509728893637657,
                0.009838581085205078,
                -0.022131837904453278,
                -0.05374297499656677,
                0.01917012594640255,
                -0.025526799261569977,
                0.011009852401912212,
                -0.005818546749651432,
                0.04441457241773605,
                -0.023897886276245117,
                -0.04094604775309563,
                0.027887612581253052,
                -0.020463794469833374,
                -0.0020382367074489594,
                -0.00798135157674551,
                0.08968525379896164,
                -0.0029763029888272285,
                -0.016347112134099007,
                -0.021213211119174957,
                0.026931587606668472,
                0.03831634297966957,
                0.01836514286696911,
                0.01621132157742977,
                -0.026140019297599792,
                0.08000399172306061,
                -0.002508101984858513,
                -0.014696222729980946,
                -0.04181024804711342,
                -0.04464178532361984,
                0.007898692041635513,
                0.004216819070279598,
                -0.021717004477977753,
                0.008035835810005665,
                0.047954268753528595,
                0.0550859160721302,
                -0.020770514383912086,
                0.03209104388952255,
                -0.03406188264489174,
                -0.002238177228718996,
                0.02455553412437439,
                -0.008287372067570686,
                0.010336926206946373,
                -0.025275852531194687,
                -0.007919380441308022,
                -0.013956109993159771,
                0.06871755421161652,
                -0.0017575122183188796,
                -0.01311936229467392,
                -0.0011262049665674567,
                -0.009036567993462086,
                0.019707856699824333,
                0.03734254091978073,
                0.008903436362743378,
                0.06868128478527069,
                0.0057417359203100204,
                -0.027402298524975777,
                0.020377187058329582,
                0.0614314004778862,
                0.01699393428862095,
                0.05173397809267044,
                -0.03459388390183449,
                -0.020455017685890198,
                -0.06821788102388382,
                -0.03759937733411789,
                0.029077380895614624,
                -7.472541181044478e-33,
                -0.02556130476295948,
                -0.02636799030005932,
                0.03476351127028465,
                0.01205107569694519,
                0.0035283281467854977,
                -0.04101454094052315,
                -0.030517494305968285,
                0.03022840805351734,
                -0.07043799757957458,
                -0.002147109480574727,
                -0.014865864999592304,
                0.03134715184569359,
                0.037140484899282455,
                -0.028177130967378616,
                0.05375078320503235,
                -0.010871948674321175,
                -0.008482113480567932,
                -0.008055396378040314,
                0.044402409344911575,
                -0.07076346129179001,
                0.04727773368358612,
                0.006068275775760412,
                0.02221517451107502,
                0.08305000513792038,
                0.015599037520587444,
                0.022359786555171013,
                -0.03514758124947548,
                0.002927066059783101,
                0.03147244080901146,
                -0.018418310210108757,
                0.018680356442928314,
                -0.030680391937494278,
                0.0036500405985862017,
                0.03610091656446457,
                0.02758101373910904,
                -0.03230771794915199,
                -0.0003020215081050992,
                0.00018105765047948807,
                -0.030191408470273018,
                0.0063392044976353645,
                -0.0050234487280249596,
                -0.0086752874776721,
                0.08128707855939865,
                -0.031694404780864716,
                -0.06919881701469421,
                -0.020100442692637444,
                0.002078985795378685,
                -0.024970905855298042,
                0.00640234537422657,
                -0.06964835524559021,
                0.046875596046447754,
                0.010684959590435028,
                -0.0023234363179653883,
                0.06238822638988495,
                0.034485090523958206,
                0.002945921616628766,
                -0.028851067647337914,
                -0.007351606152951717,
                -0.009252344258129597,
                0.019616585224866867,
                0.013401221483945847,
                0.09112195670604706,
                -0.019294990226626396,
                0.02645432949066162,
                0.006057340651750565,
                -0.03572210296988487,
                -0.023793241009116173,
                -0.016021981835365295,
                -0.0041596353985369205,
                0.0967114120721817,
                -0.041916586458683014,
                0.09080276638269424,
                -0.036245185881853104,
                0.008834544569253922,
                -0.017865313217043877,
                -0.07734567672014236,
                -0.052420634776353836,
                0.019196877256035805,
                -0.0027936415281146765,
                -0.07472225278615952,
                -0.054207075387239456,
                0.03560445085167885,
                -0.013394871726632118,
                -0.004124454688280821,
                0.007540114689618349,
                0.04931694269180298,
                -0.02302720583975315,
                0.026445411145687103,
                -0.01567177288234234,
                0.023215247318148613,
                0.007362307980656624,
                0.020599501207470894,
                -0.03074900433421135,
                -0.011177275329828262,
                -0.04062104970216751,
                -0.030230233445763588,
                -0.013376541435718536,
                -0.054799456149339676,
                -0.006807806435972452,
                -0.022271404042840004,
                0.03846577927470207,
                0.02321610040962696,
                -0.0039104875177145,
                0.0019823145121335983,
                0.001269495114684105,
                0.03182607889175415,
                -0.005868949461728334,
                -0.014834449626505375,
                -0.056358702480793,
                0.01297137513756752,
                0.030498137697577477,
                0.02996586449444294,
                0.03199531137943268,
                0.004430112894624472,
                -0.020940739661455154,
                0.018420737236738205,
                0.020823942497372627,
                -0.003832718124613166,
                0.00916349608451128,
                -0.02083945833146572,
                -0.020768845453858376,
                0.014807423576712608,
                -0.013265402056276798,
                -0.009461838752031326,
                -0.018250668421387672,
                -0.012767847627401352,
                0.04348216950893402,
                -0.05230986699461937,
                -0.030946815386414528,
                -0.018820127472281456,
                -0.03620455041527748,
                0.0651359036564827,
                2.4575382440161775e-07,
                0.026435386389493942,
                0.018856458365917206,
                -0.00819308590143919,
                0.05445756018161774,
                -0.004354037810117006,
                0.008617674000561237,
                0.05524424836039543,
                0.02206272818148136,
                0.028083648532629013,
                0.025102214887738228,
                0.035712070763111115,
                -0.023481104522943497,
                0.03455929085612297,
                -0.030604111030697823,
                0.006396214943379164,
                -0.018882378935813904,
                -0.024954460561275482,
                0.008765004575252533,
                -0.027691572904586792,
                -0.02742118574678898,
                0.029323074966669083,
                0.04559098556637764,
                0.023960568010807037,
                0.019511176273226738,
                0.022060517221689224,
                -0.061568476259708405,
                -0.0021889894269406796,
                -0.01407494954764843,
                -0.008783334866166115,
                0.04894174262881279,
                0.07626546174287796,
                -0.04426463320851326,
                -0.01455496996641159,
                0.047475624829530716,
                -0.034261494874954224,
                -0.015248673968017101,
                -0.010158788412809372,
                0.04515830799937248,
                0.0028609870932996273,
                0.045836418867111206,
                0.009439464658498764,
                -0.10526353865861893,
                0.0037021285388618708,
                0.021548405289649963,
                0.048736050724983215,
                0.07708051800727844,
                0.012325592339038849,
                0.08901631832122803,
                0.011494291946291924,
                -0.07746272534132004,
                0.033689454197883606,
                0.012251914478838444,
                0.026038460433483124,
                -0.014089097268879414,
                0.004094499628990889,
                0.024522094056010246,
                0.03555403649806976,
                0.04174300655722618,
                0.0005251516122370958,
                0.1021745502948761,
                -0.01413592230528593,
                -0.01100191380828619,
                0.05059381574392319,
                0.028204765170812607,
                0.027280641719698906,
                0.05150318145751953,
                -0.020858904346823692,
                7.143986561063314e-35,
                0.009104020893573761,
                0.024348635226488113,
                0.0027585343923419714,
                -0.030604222789406776,
                0.023297762498259544,
                -0.00994917843490839,
                -0.02792181633412838,
                -0.05039627477526665,
                -0.02461598999798298,
                0.030661627650260925,
                -0.027690712362527847
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "On location",
        "hash__": "b\"\\x0ba:\\xabE\\xd6\\x91\\x8a\\xa0EE'\\xb4\\xec5\\xd7\"",
        "id__": "0b7424f4-ca5f-4b49-910c-e048cb1cd5f9"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "MSc/Civil Engineering",
            "rep": [
                -0.029341787099838257,
                -0.003950628452003002,
                -0.022810140624642372,
                0.012708506546914577,
                0.04588719457387924,
                -0.00966254249215126,
                0.0023210386279970407,
                -0.01908760331571102,
                0.041292786598205566,
                -0.006954560987651348,
                0.0534822903573513,
                0.004708362743258476,
                0.008357790298759937,
                0.052899070084095,
                0.022381462156772614,
                -0.0056143878027796745,
                -0.03334122151136398,
                -0.02150428667664528,
                -0.1056578978896141,
                -0.022893793880939484,
                -0.03627202287316322,
                -0.005996856838464737,
                -0.014686026610434055,
                0.025444185361266136,
                -5.758756287832512e-06,
                -0.0026515277568250895,
                -0.02535715326666832,
                0.004651546012610197,
                0.020699404180049896,
                0.040868259966373444,
                0.05340639129281044,
                0.01815081015229225,
                -0.02499333769083023,
                -0.00111748359631747,
                1.2728986575893941e-06,
                -0.000746128789614886,
                -0.010695372708141804,
                -0.00958687998354435,
                0.05003136768937111,
                -0.03721409663558006,
                0.02600167877972126,
                0.042578697204589844,
                0.003088726894930005,
                0.011602358892560005,
                0.003382817143574357,
                0.11047077178955078,
                0.025184089317917824,
                0.01683143340051174,
                -0.0030453470535576344,
                -0.02729862369596958,
                0.01875085011124611,
                -0.09452175348997116,
                -0.04358737915754318,
                -0.04859798774123192,
                0.0031120788771659136,
                -0.06308305263519287,
                0.02764386683702469,
                0.018878500908613205,
                0.05521634593605995,
                0.08847741037607193,
                -0.027174485847353935,
                0.0019909688271582127,
                -0.015097081661224365,
                0.018949352204799652,
                0.04014815762639046,
                0.03118748404085636,
                0.05780388042330742,
                0.005630779545754194,
                -0.022459324449300766,
                0.007598293479532003,
                0.04874808341264725,
                0.0010496077593415976,
                -0.0021926304325461388,
                -0.010508326813578606,
                -0.013835838064551353,
                0.005084177013486624,
                0.01735147461295128,
                -0.011688721366226673,
                -0.02328140288591385,
                -0.0105161527171731,
                -0.01033020205795765,
                -0.002249258104711771,
                0.020553195849061012,
                0.0026076517533510923,
                -0.0680709183216095,
                0.0469975583255291,
                -0.03328294679522514,
                0.024265430867671967,
                0.025796083733439445,
                0.011015388183295727,
                0.044128239154815674,
                0.011535434052348137,
                -0.013015578500926495,
                0.030491624027490616,
                -0.01720157265663147,
                -0.04380914941430092,
                0.05559141933917999,
                -0.005931452382355928,
                0.0031587588600814342,
                0.05236392468214035,
                -0.028636448085308075,
                0.015302339568734169,
                0.003218780504539609,
                0.058086223900318146,
                -0.01615920476615429,
                0.03195249289274216,
                0.019568143412470818,
                0.06852718442678452,
                0.01946037821471691,
                -0.028368011116981506,
                -0.03694411367177963,
                0.0063252742402255535,
                0.020671650767326355,
                -0.016576992347836494,
                -0.029093731194734573,
                0.08057262748479843,
                -0.007653800304979086,
                -0.006810558028519154,
                0.006906889844685793,
                -0.008220229297876358,
                0.030387593433260918,
                0.004079489037394524,
                -0.04276050254702568,
                0.013650543056428432,
                0.01284857653081417,
                -0.01924404874444008,
                -0.01709609106183052,
                0.051453251391649246,
                0.04216412454843521,
                0.03388703241944313,
                -0.027503205463290215,
                -0.03575482591986656,
                -0.02358759380877018,
                0.0013783668400719762,
                0.010837187059223652,
                -0.037113334983587265,
                -0.0604519248008728,
                0.03804324194788933,
                -0.04204899072647095,
                -0.06694430857896805,
                -0.0037476024590432644,
                -0.008341226726770401,
                0.02134971134364605,
                0.042893946170806885,
                -0.0534946545958519,
                -0.003906353376805782,
                -0.008054133504629135,
                -0.01969153620302677,
                0.008890076540410519,
                -0.05841570347547531,
                -0.012730667367577553,
                -0.04837542772293091,
                0.007323149591684341,
                -0.05776151269674301,
                0.058786790817976,
                0.048071082681417465,
                0.03932895511388779,
                0.0071349297650158405,
                -0.006719035096466541,
                0.09650936722755432,
                -0.06474409997463226,
                0.01354353316128254,
                -0.012946544215083122,
                0.016429930925369263,
                -0.02322065271437168,
                0.016839250922203064,
                -0.005722543224692345,
                -0.04602733626961708,
                -0.022001881152391434,
                -0.011232562363147736,
                -0.027191273868083954,
                -0.04876067116856575,
                0.031668972223997116,
                0.022029079496860504,
                0.012059676460921764,
                -0.027003958821296692,
                -0.0031686637084931135,
                0.0227922685444355,
                -0.018168171867728233,
                0.03745291382074356,
                0.014369737356901169,
                -0.10093360394239426,
                0.017822034657001495,
                -0.014311951585114002,
                0.024485021829605103,
                0.0015756069915369153,
                -0.034007761627435684,
                0.00396723672747612,
                -0.03838105872273445,
                0.01575920544564724,
                0.015383314341306686,
                0.025679679587483406,
                -0.0590120293200016,
                -0.004920594394207001,
                0.02525137923657894,
                0.00387765746563673,
                0.038485921919345856,
                -0.04720580205321312,
                0.0048333508893847466,
                0.019978927448391914,
                0.0026673690881580114,
                0.028310736641287804,
                -0.038936540484428406,
                0.002438355702906847,
                -0.0143543416634202,
                -0.06334702670574188,
                -0.021648094058036804,
                -0.04594855755567551,
                -0.04150582104921341,
                0.07896547764539719,
                -0.00232169171795249,
                -0.017378969117999077,
                0.07786095887422562,
                0.042547792196273804,
                -0.038996901363134384,
                -0.05984757840633392,
                0.012951706536114216,
                -0.0366334393620491,
                -0.01964540220797062,
                -0.03027356043457985,
                0.0033110491931438446,
                -0.0365537628531456,
                -0.012267174199223518,
                0.008382185362279415,
                -0.025223439559340477,
                0.03859210014343262,
                0.05667634680867195,
                -0.03962140902876854,
                -0.014015558175742626,
                0.029491020366549492,
                0.008318488486111164,
                -0.0006535524153150618,
                0.022992312908172607,
                -0.036409758031368256,
                -0.036114685237407684,
                -0.00020447888527996838,
                0.04419675096869469,
                0.013495349325239658,
                0.00522697763517499,
                -0.03436273708939552,
                0.01808837614953518,
                0.028755012899637222,
                -0.00455897394567728,
                -0.007606504950672388,
                0.020619243383407593,
                0.03156096860766411,
                0.032416533678770065,
                0.031093185767531395,
                0.05025547742843628,
                -0.02938545122742653,
                -0.03969312459230423,
                -0.044749315828084946,
                0.002369773341342807,
                0.012534372508525848,
                0.03331395983695984,
                0.01951763592660427,
                -0.070119209587574,
                0.03649289533495903,
                -0.03164050728082657,
                -0.0651172399520874,
                -0.01910284534096718,
                -0.04966075345873833,
                0.005164212081581354,
                -0.032782379537820816,
                0.03354606777429581,
                0.024811986833810806,
                -0.021272283047437668,
                -0.01776629500091076,
                -0.04181528091430664,
                0.01044729258865118,
                -0.005665304139256477,
                0.05617348477244377,
                0.029147544875741005,
                -0.03669561818242073,
                -0.02710959129035473,
                0.015901600942015648,
                -0.009037512354552746,
                0.02168176881968975,
                -0.0680260956287384,
                0.02440032549202442,
                -0.007449210621416569,
                0.06445860117673874,
                -0.04086718708276749,
                -0.01924152486026287,
                -0.02583705261349678,
                0.0350220687687397,
                0.042996689677238464,
                -0.036395926028490067,
                0.005915237125009298,
                0.03142557293176651,
                -0.0003188435221090913,
                -0.01286004763096571,
                -0.021568823605775833,
                -0.058473121374845505,
                -0.033470507711172104,
                0.08201518654823303,
                0.019575240090489388,
                -0.01844053342938423,
                0.007956488057971,
                0.0360737219452858,
                -0.009152893908321857,
                -0.01858603209257126,
                0.015208594501018524,
                0.022688286378979683,
                -0.026439832523465157,
                0.030844220891594887,
                -0.007300656288862228,
                0.02265818603336811,
                -0.028678404167294502,
                -0.04773156717419624,
                -0.053334612399339676,
                0.02506278082728386,
                0.061466965824365616,
                0.03798878937959671,
                0.08493965119123459,
                -0.051778946071863174,
                0.014693434350192547,
                0.010617094114422798,
                0.06086152046918869,
                0.02571309357881546,
                0.019572759047150612,
                -0.004205091390758753,
                -0.044653523713350296,
                -0.013344330713152885,
                0.023337488994002342,
                -0.0037095348816365004,
                -0.014409460127353668,
                -0.02723870426416397,
                -0.009790231473743916,
                -0.08011240512132645,
                0.00014034518972039223,
                -0.00022335279209073633,
                -0.02708284556865692,
                -0.021225670352578163,
                -0.0016540797660127282,
                -0.08409938216209412,
                -0.01556574646383524,
                -0.09540558606386185,
                -0.04293056204915047,
                -0.04022100940346718,
                0.021566376090049744,
                -0.026901567354798317,
                -0.04589679464697838,
                -0.007202158682048321,
                0.026563940569758415,
                0.023755714297294617,
                0.041033707559108734,
                0.023636499419808388,
                0.0022766271140426397,
                0.022702975198626518,
                0.049154896289110184,
                0.011724364943802357,
                -0.033511411398649216,
                0.040190935134887695,
                -0.013379001058638096,
                0.04150375351309776,
                0.042363204061985016,
                0.10744136571884155,
                0.008204171434044838,
                -0.019810901954770088,
                -0.04902077466249466,
                -0.038620177656412125,
                -0.0304949339479208,
                -0.03668433055281639,
                -0.01600983366370201,
                -0.008793588727712631,
                0.051145777106285095,
                -0.010921750217676163,
                -0.013225527480244637,
                -0.0025998123455792665,
                -0.010947114787995815,
                -0.013044601306319237,
                -0.039676930755376816,
                -0.01805727928876877,
                -0.026175817474722862,
                0.025223810225725174,
                -0.013512128964066505,
                0.016961155459284782,
                0.01941906288266182,
                0.001330324332229793,
                -0.016855884343385696,
                0.020421115681529045,
                0.029728900641202927,
                0.008060195483267307,
                -0.0008161516743712127,
                0.006553440820425749,
                0.021393870934844017,
                0.0011805694084614515,
                0.023013776168227196,
                0.003029629122465849,
                0.00817629974335432,
                -0.05088647082448006,
                -0.01436434406787157,
                0.04162183403968811,
                0.017516732215881348,
                0.0270394217222929,
                -0.09128114581108093,
                -0.01823982037603855,
                0.05178453400731087,
                0.014779409393668175,
                0.05705377086997032,
                -0.004551708232611418,
                -0.022135725244879723,
                0.01914132945239544,
                -0.02168402075767517,
                0.03203129023313522,
                0.022355295717716217,
                -0.03781398385763168,
                -0.04175490140914917,
                -0.02307380922138691,
                0.058223091065883636,
                -0.009895906783640385,
                0.03350136801600456,
                -0.018501250073313713,
                -0.011596710421144962,
                -0.021664278581738472,
                -0.025986256077885628,
                0.016485439613461494,
                -0.03056974709033966,
                -0.07947491854429245,
                0.01561336126178503,
                0.024443313479423523,
                0.06343574076890945,
                -0.052270084619522095,
                0.07338762283325195,
                -0.02304842136800289,
                -0.03819041699171066,
                0.02362656593322754,
                -0.0814223513007164,
                -0.0644676610827446,
                -0.008197243325412273,
                -0.05381353944540024,
                -0.041322458535432816,
                -0.00010530704457778484,
                0.023982198908925056,
                -0.029000667855143547,
                0.02157936617732048,
                0.005869670305401087,
                -0.016890069469809532,
                0.07134585827589035,
                0.019670143723487854,
                0.0542168952524662,
                -0.031252529472112656,
                -0.0019433031557127833,
                0.02680790238082409,
                0.07549913972616196,
                -0.054271064698696136,
                -0.04456130042672157,
                -0.033595386892557144,
                -0.01700936257839203,
                0.10566451400518417,
                -1.5336119759012945e-05,
                0.011768791824579239,
                -0.005049221217632294,
                -0.04423154890537262,
                -0.057100579142570496,
                -0.11491783708333969,
                0.014803517609834671,
                -0.0177477914839983,
                0.006737003102898598,
                0.04649332910776138,
                0.013523602858185768,
                0.001009770086966455,
                0.003978320863097906,
                -0.013140808790922165,
                -0.018710380420088768,
                -0.030245466157794,
                0.02547667734324932,
                0.00856379047036171,
                -0.015463749878108501,
                -0.03721298649907112,
                -0.010600747540593147,
                -0.039425697177648544,
                0.01730768010020256,
                0.014027693308889866,
                -0.013108976185321808,
                0.037055131047964096,
                0.038235824555158615,
                -0.018784547224640846,
                0.0214886162430048,
                0.0024935798719525337,
                -0.002688179723918438,
                0.06857512891292572,
                -0.016221104189753532,
                -0.009311309084296227,
                0.05372375249862671,
                0.04354619234800339,
                0.02560306526720524,
                0.05750126391649246,
                -0.021925507113337517,
                0.04446303844451904,
                -0.06592195481061935,
                -0.006701115984469652,
                -0.053748197853565216,
                0.052545566111803055,
                0.02052009291946888,
                -0.011480622924864292,
                0.04293956235051155,
                -0.05971334129571915,
                0.011121246963739395,
                -0.025036262348294258,
                0.021398037672042847,
                -0.012569396756589413,
                -0.038953185081481934,
                0.0241540614515543,
                -0.0009821467101573944,
                0.022101763635873795,
                -0.03343712165951729,
                0.009230418130755424,
                0.024475589394569397,
                0.01378619484603405,
                0.0056845215149223804,
                0.011105452664196491,
                0.04009131342172623,
                -0.010748241096735,
                -0.005387814715504646,
                0.027728212997317314,
                0.03633918613195419,
                0.034469496458768845,
                0.05008164793252945,
                0.0466611348092556,
                -0.05518938973546028,
                -0.0025483444333076477,
                -0.025322256609797478,
                -0.02827807515859604,
                -0.030440058559179306,
                -0.010555699467658997,
                0.013582661747932434,
                0.019313128665089607,
                0.010656499303877354,
                0.029693005606532097,
                -0.03794453665614128,
                0.015661152079701424,
                0.004388722591102123,
                0.009439066983759403,
                0.015814678743481636,
                0.04322937875986099,
                0.07146161049604416,
                0.002945933025330305,
                0.05388636887073517,
                -0.029506860300898552,
                0.02905242145061493,
                0.05489250272512436,
                0.01480676606297493,
                0.006842016242444515,
                -0.05642472952604294,
                0.006003003101795912,
                0.04049079120159149,
                0.006506177131086588,
                -0.004791100509464741,
                0.0084774075075984,
                0.0013630370376631618,
                0.02584882266819477,
                0.060981348156929016,
                0.02465907111763954,
                -4.797514971968262e-33,
                -0.02211766317486763,
                -0.03243231400847435,
                0.043623264878988266,
                0.0479922853410244,
                -0.0305784922093153,
                -0.045613113790750504,
                0.02401134744286537,
                -0.026540111750364304,
                -0.049751393496990204,
                -0.04584508016705513,
                -0.027744747698307037,
                0.03846736624836922,
                0.005692829377949238,
                0.005825321190059185,
                0.07916232198476791,
                0.08278433233499527,
                0.024969544261693954,
                0.04526638239622116,
                0.003525523701682687,
                -0.03112519159913063,
                -0.04074118286371231,
                -0.02992134727537632,
                0.014521386474370956,
                -0.029483044520020485,
                0.002108947141095996,
                0.019446026533842087,
                -0.0021330169402062893,
                -0.058912038803100586,
                -0.010116666555404663,
                -0.011178318411111832,
                0.030139660462737083,
                -0.019347142428159714,
                0.032874695956707,
                0.025952817872166634,
                -0.016022972762584686,
                -0.020728206261992455,
                -0.0090571204200387,
                -0.006858556531369686,
                -0.05457198992371559,
                0.014368729665875435,
                -0.006774470210075378,
                -0.026381542906165123,
                -0.022332843393087387,
                0.0017328296089544892,
                -0.0575207844376564,
                -0.07617581635713577,
                -0.017476994544267654,
                0.03847436606884003,
                0.046893615275621414,
                -0.053372692316770554,
                -0.03412462770938873,
                -0.01853015460073948,
                -0.043825890868902206,
                0.03237997367978096,
                -0.05655403807759285,
                -0.06463269889354706,
                0.03481637313961983,
                0.06418713182210922,
                0.00034535827580839396,
                0.006436982192099094,
                -0.0079988744109869,
                0.04725370556116104,
                0.08191024512052536,
                -0.03757401183247566,
                -0.03445212170481682,
                -0.01018738653510809,
                -0.0031473361887037754,
                0.020854629576206207,
                0.04355461522936821,
                -0.09397673606872559,
                -0.0005118830013088882,
                0.08281754702329636,
                -0.03869633004069328,
                -0.010356055572628975,
                -0.013738731853663921,
                -0.06849873811006546,
                -0.023740198463201523,
                -0.034682709723711014,
                0.04126473143696785,
                0.062160421162843704,
                -0.03482002764940262,
                0.005342115182429552,
                0.05814404413104057,
                0.016923528164625168,
                -0.038148071616888046,
                -0.048639729619026184,
                0.025679543614387512,
                0.09098131209611893,
                0.02491787075996399,
                0.015047857537865639,
                0.021569738164544106,
                0.029910072684288025,
                -0.056496307253837585,
                -0.004281972069293261,
                0.04879648983478546,
                0.0003618832561187446,
                -0.05716100335121155,
                -0.017455419525504112,
                0.00788944773375988,
                0.11449826508760452,
                -0.05449682101607323,
                0.019809748977422714,
                -0.017981110140681267,
                -0.010017603635787964,
                -0.006096420343965292,
                0.03306575492024422,
                -0.0439542680978775,
                0.00506186205893755,
                -0.015822304412722588,
                -0.0017967000603675842,
                -0.007062367629259825,
                -0.011984133161604404,
                -0.02575751580297947,
                0.008534712716937065,
                0.026178862899541855,
                -0.02238503471016884,
                -0.00410107197239995,
                -0.1297226995229721,
                -0.017810313031077385,
                0.02379385009407997,
                0.03677985817193985,
                -0.07045028358697891,
                -0.004061754327267408,
                -0.005525293760001659,
                0.02778233215212822,
                -0.01787971518933773,
                0.07175081223249435,
                -0.06629473716020584,
                -0.013703796081244946,
                -0.0014007241697981954,
                0.0185264740139246,
                0.015139692462980747,
                1.8306539573131886e-07,
                -0.024760134518146515,
                0.07030458748340607,
                0.02448781207203865,
                0.036275941878557205,
                -0.0048224786296486855,
                -0.01333982590585947,
                -0.030184471979737282,
                0.05539786070585251,
                0.011886043474078178,
                0.06804349273443222,
                -0.0335577018558979,
                -0.02159249782562256,
                0.010541395284235477,
                -0.08524040877819061,
                -0.00401735957711935,
                0.02494615688920021,
                0.015991568565368652,
                -0.010147823952138424,
                -0.0439130961894989,
                -0.02896423451602459,
                -0.02769741229712963,
                0.009677303023636341,
                -0.03472742810845375,
                0.00223391130566597,
                -0.0010686626192182302,
                0.008869078941643238,
                -0.03217003494501114,
                0.02077202871441841,
                0.08569567650556564,
                0.027807554230093956,
                0.03402203321456909,
                -0.026856286451220512,
                0.039934929460287094,
                0.026473212987184525,
                0.008963098749518394,
                -0.02235044725239277,
                0.08107805252075195,
                0.008401262573897839,
                0.0078032552264630795,
                0.03317827358841896,
                -0.029406627640128136,
                -0.028830796480178833,
                0.03085489571094513,
                0.02540678344666958,
                -0.01754099875688553,
                0.04563741385936737,
                0.03349350020289421,
                -0.011165596544742584,
                -0.08001133799552917,
                -0.022223180159926414,
                -0.030596140772104263,
                0.07650110125541687,
                -0.021954316645860672,
                -0.06927873194217682,
                0.03335491567850113,
                -0.010295169427990913,
                -0.010858077555894852,
                0.045812129974365234,
                0.045074980705976486,
                -0.009485086426138878,
                -0.015712909400463104,
                -0.03610263392329216,
                -0.012379986234009266,
                0.011328623630106449,
                0.09115976840257645,
                -0.040817566215991974,
                -0.040313538163900375,
                1.0805519450349013e-34,
                -0.02646690048277378,
                -0.02196083962917328,
                -0.025514960289001465,
                0.039830803871154785,
                -0.004564432427287102,
                -0.008370328694581985,
                -0.002711457898840308,
                0.02699114941060543,
                -0.015272337943315506,
                -0.02452825754880905,
                -0.01469070091843605
            ]
        },
        "Experience": {
            "name": "I received a Bachelor of Science in Mechanical Engineering from the University of Texas Rio Grande Valley in 2016, where I also received a Master of Science in Civil Engineering in 2019. In 2018, I graduated from the Water Leadership Institute of the Water Environment Federation.\n",
            "rep": [
                -0.045631974935531616,
                0.05637006461620331,
                -0.04582995921373367,
                0.0016026990488171577,
                0.013714464381337166,
                -0.04429292678833008,
                -0.01567206345498562,
                -0.010976848192512989,
                -0.016691621392965317,
                -0.028172874823212624,
                0.034342795610427856,
                0.020105397328734398,
                0.0036858178209513426,
                0.035249266773462296,
                0.034677136689424515,
                0.01811097376048565,
                -0.01438173558562994,
                -0.002714655129238963,
                -0.042375873774290085,
                -0.04503527656197548,
                -0.013947181403636932,
                0.004062888212502003,
                0.0017129350453615189,
                0.032378412783145905,
                0.0034789112396538258,
                0.028797365725040436,
                -0.021630283445119858,
                0.03794938698410988,
                0.03714866563677788,
                0.012525872327387333,
                0.022809650748968124,
                0.05348198860883713,
                -0.030565932393074036,
                0.040169719606637955,
                1.8903549516835483e-06,
                0.014227512292563915,
                -0.005816732533276081,
                0.0019093782175332308,
                0.056881602853536606,
                -0.018606780096888542,
                0.004088158253580332,
                0.010011413134634495,
                -0.021297141909599304,
                0.015107128769159317,
                -0.010642418637871742,
                0.07095860689878464,
                0.002307977993041277,
                -0.02427910640835762,
                -0.03908750042319298,
                -0.06155729293823242,
                -0.009816896170377731,
                -0.008457564748823643,
                -0.015262596309185028,
                -0.015736861154437065,
                0.0018309798324480653,
                0.02023956924676895,
                0.017138613387942314,
                0.025651751086115837,
                0.07926182448863983,
                0.021924085915088654,
                -0.021556280553340912,
                0.07063968479633331,
                -0.033878445625305176,
                0.010417349636554718,
                0.03674958646297455,
                0.03881692513823509,
                0.030059412121772766,
                0.017501819878816605,
                0.0037696114741265774,
                0.06335435062646866,
                0.04576021060347557,
                -0.014696933329105377,
                0.022786153480410576,
                0.012072846293449402,
                -0.010358347557485104,
                0.05501079559326172,
                0.020031951367855072,
                -0.024408796802163124,
                -0.004160750191658735,
                -0.017665842548012733,
                -0.08373339474201202,
                -0.013084777630865574,
                -0.01235702633857727,
                0.014439726248383522,
                -0.02931874990463257,
                0.056412599980831146,
                -0.04170072078704834,
                0.0056829252280294895,
                0.005183297209441662,
                0.03487446531653404,
                0.08334426581859589,
                0.003646181197836995,
                0.033947039395570755,
                0.011113030835986137,
                0.008397907018661499,
                -0.05661281570792198,
                0.03531434014439583,
                -0.04940008744597435,
                -0.01720757968723774,
                0.006962787825614214,
                -0.011085272766649723,
                0.06422843039035797,
                0.0682486891746521,
                0.04340691864490509,
                -0.05128168687224388,
                0.06413773447275162,
                0.01459885947406292,
                0.04037611186504364,
                0.002654346637427807,
                -0.024628231301903725,
                -0.09831191599369049,
                0.004959107376635075,
                0.03817863389849663,
                -0.021767256781458855,
                0.031924884766340256,
                0.035956088453531265,
                0.014940074644982815,
                -0.01920156180858612,
                0.0005419800872914493,
                -0.01105848141014576,
                -0.022348051890730858,
                0.041839368641376495,
                -0.01620679721236229,
                0.014843659475445747,
                0.0050732288509607315,
                0.022984463721513748,
                -0.007677740417420864,
                0.03075869008898735,
                0.027451680973172188,
                0.021432463079690933,
                -0.03449222445487976,
                -0.014000723138451576,
                -0.022337503731250763,
                -0.010447436012327671,
                0.005444156937301159,
                -0.03150663897395134,
                -0.03661549836397171,
                -0.00018906962941400707,
                0.013334293849766254,
                -0.059314992278814316,
                -0.01363771315664053,
                -0.010797124356031418,
                -0.015389444306492805,
                0.07371468096971512,
                0.020390434190630913,
                -0.016368968412280083,
                0.020052101463079453,
                -0.0017900685779750347,
                0.022151656448841095,
                -0.04880630597472191,
                -0.007444159593433142,
                -0.034290388226509094,
                0.020848281681537628,
                -0.0009937015129253268,
                0.04445367306470871,
                -0.004658212419599295,
                0.02266707271337509,
                0.026834430173039436,
                -0.001896982779726386,
                0.09850291162729263,
                -0.02620534785091877,
                -0.017063578590750694,
                -0.07138027250766754,
                0.019368203356862068,
                -0.02009027637541294,
                -0.019277531653642654,
                0.02931521087884903,
                -0.06506894528865814,
                -0.021403292194008827,
                0.026535993441939354,
                0.030426152050495148,
                -0.04987426474690437,
                0.03155811131000519,
                0.09314259141683578,
                0.024218695238232613,
                -0.005810487549751997,
                0.015996981412172318,
                -0.026460273191332817,
                0.032038621604442596,
                0.041225869208574295,
                0.06941027194261551,
                -0.13785704970359802,
                0.03794034942984581,
                -0.06765546649694443,
                0.024328595027327538,
                -0.006878599524497986,
                -0.015343326143920422,
                0.03180273622274399,
                -0.03824961557984352,
                -0.003225413616746664,
                0.02386886067688465,
                0.0301123708486557,
                -0.07398685067892075,
                0.032725781202316284,
                0.016955304890871048,
                0.006514234002679586,
                0.050566673278808594,
                -0.01688198931515217,
                -0.018433939665555954,
                -0.001270224223844707,
                0.005011578090488911,
                0.013506244868040085,
                -0.03689754381775856,
                0.032495271414518356,
                -0.03234674781560898,
                -0.03397459536790848,
                0.01602347381412983,
                -0.011011235415935516,
                -0.03756023570895195,
                0.03188767656683922,
                -0.016160540282726288,
                0.01909690909087658,
                0.050564028322696686,
                0.06157374754548073,
                -0.020551340654492378,
                -0.016481788828969002,
                0.00856257788836956,
                -0.018129868432879448,
                0.0030135742854326963,
                0.03352651000022888,
                0.0064397454261779785,
                -0.02832537144422531,
                -0.009053775109350681,
                0.00048675303696654737,
                -0.029237812384963036,
                0.022545119747519493,
                0.07201457768678665,
                -0.015831248834729195,
                -0.004889163188636303,
                0.04145732894539833,
                0.028373464941978455,
                -0.015921730548143387,
                0.0385591983795166,
                -0.03970816731452942,
                -0.04393210634589195,
                0.027254028245806694,
                0.029671577736735344,
                0.06774108111858368,
                0.030787238851189613,
                -0.03854912146925926,
                0.018167925998568535,
                -0.00549264857545495,
                -0.010728101246058941,
                -0.004865333903580904,
                0.05734826251864433,
                0.02358732558786869,
                0.002779340837150812,
                0.007260824553668499,
                0.06678790599107742,
                -0.02524133212864399,
                0.020358692854642868,
                -0.05489505082368851,
                0.02112044394016266,
                0.02466217614710331,
                0.03610597923398018,
                -0.0649203211069107,
                -0.04810619354248047,
                0.002260169479995966,
                -0.05551966279745102,
                -0.039440423250198364,
                -0.026633931323885918,
                -0.06505481898784637,
                -0.045183636248111725,
                -0.004927921574562788,
                -0.00711151072755456,
                -0.002894243923947215,
                -0.005957220681011677,
                0.015378548763692379,
                -0.011142956092953682,
                -4.2584211769280955e-05,
                0.011053824797272682,
                0.04333939030766487,
                0.003987686242908239,
                -0.024164976552128792,
                0.018944894894957542,
                0.02559920772910118,
                0.00020497619698289782,
                0.041527293622493744,
                -0.08817104250192642,
                0.02763907052576542,
                0.0010597057407721877,
                0.05377417802810669,
                -0.07770198583602905,
                -0.017071792855858803,
                -0.03163863345980644,
                0.05299311503767967,
                0.03356311842799187,
                -0.06328932195901871,
                -0.05165461078286171,
                0.03631282597780228,
                0.0009160481858998537,
                -0.026559602469205856,
                0.04026756063103676,
                -0.0352625772356987,
                -0.03828614950180054,
                0.04052457958459854,
                -0.0021135606802999973,
                -0.0217068362981081,
                -0.007236385717988014,
                0.059294480830430984,
                0.007798568345606327,
                0.017675478011369705,
                0.016476847231388092,
                0.017025332897901535,
                -0.020549355074763298,
                0.02150106616318226,
                0.0212868582457304,
                0.04055090621113777,
                -0.0352465882897377,
                -0.09304846823215485,
                -0.05175681412220001,
                0.029922891408205032,
                0.03317584469914436,
                -0.007157860789448023,
                0.09540601819753647,
                -0.030135752633213997,
                0.03048083558678627,
                0.007912633940577507,
                0.08061264455318451,
                -0.006723967380821705,
                -0.005319620482623577,
                0.04292473569512367,
                -0.02357625961303711,
                -0.029023608192801476,
                0.0020079640671610832,
                0.0219431109726429,
                -0.02481340989470482,
                -0.008149286732077599,
                -0.0032520978711545467,
                -0.01997186616063118,
                0.012441481463611126,
                0.024695122614502907,
                -0.01846260391175747,
                0.01383180357515812,
                0.03919769451022148,
                -0.0943060889840126,
                0.005593054927885532,
                -0.06576748937368393,
                -0.036592576652765274,
                0.0036247032694518566,
                0.008340083993971348,
                -0.037160277366638184,
                -0.0547628179192543,
                0.061646196991205215,
                -0.024080907925963402,
                0.0030077416449785233,
                0.0605064257979393,
                -0.007349323946982622,
                -0.014181616716086864,
                -0.05126475915312767,
                0.06311679631471634,
                -0.009487667120993137,
                -0.06523100286722183,
                -0.007600888144224882,
                -0.030682815238833427,
                0.0927945077419281,
                0.038172535598278046,
                0.05701512098312378,
                -0.017062058672308922,
                0.03673720359802246,
                -0.0343896709382534,
                -0.04356704279780388,
                0.06386077404022217,
                -0.02865387126803398,
                -0.01170493382960558,
                0.01066798809915781,
                0.075135737657547,
                0.018499180674552917,
                -0.01374556589871645,
                -0.017738522961735725,
                -0.04229550436139107,
                4.131737114221323e-06,
                -0.07249846309423447,
                -0.046879857778549194,
                -0.005964916665107012,
                0.052058808505535126,
                0.024914709851145744,
                0.01581261120736599,
                0.0773250162601471,
                -0.011055978015065193,
                0.022858694195747375,
                -0.001354161067865789,
                0.019481051713228226,
                -0.008490337058901787,
                -0.031939465552568436,
                -0.03328300639986992,
                0.02003598026931286,
                -0.0351669043302536,
                -0.007178332656621933,
                0.007135789841413498,
                0.008604087866842747,
                -0.01533313188701868,
                -0.04761100187897682,
                -0.03879363462328911,
                -0.004094121977686882,
                0.038409799337387085,
                -0.10665059834718704,
                -0.012162461876869202,
                0.018235715106129646,
                0.03555354103446007,
                0.04621793329715729,
                0.036206357181072235,
                -0.06813765317201614,
                0.02367286942899227,
                0.030955325812101364,
                -0.07113543897867203,
                0.0848025232553482,
                -0.028497809544205666,
                -0.05120525881648064,
                0.010815033689141273,
                0.030283894389867783,
                -0.009698833338916302,
                0.048521846532821655,
                -0.03354451432824135,
                -0.05841318890452385,
                -0.06812553852796555,
                0.003364482894539833,
                -0.0026817042380571365,
                0.01977752149105072,
                -0.0792098119854927,
                0.024890992790460587,
                0.02147412858903408,
                0.09331589937210083,
                -0.06397358328104019,
                0.06906585395336151,
                -0.05639344081282616,
                -0.014357262291014194,
                0.003669796045869589,
                -0.026030320674180984,
                -0.08415385335683823,
                -0.013834348879754543,
                -0.04208286851644516,
                -0.05842157080769539,
                0.0024993806146085262,
                0.026173057034611702,
                -0.03763763979077339,
                -0.052091822028160095,
                -0.007381025236099958,
                -0.01219791267067194,
                0.033425502479076385,
                0.025502894073724747,
                0.030469682067632675,
                -0.00832931138575077,
                -0.005636134650558233,
                0.07264409214258194,
                0.08759291470050812,
                -0.0035733405966311693,
                -0.03285888582468033,
                -0.009820539504289627,
                -0.05948805809020996,
                0.07357466965913773,
                0.08347854018211365,
                -0.037872396409511566,
                0.008000856265425682,
                -0.05364621803164482,
                -0.0396631583571434,
                -0.02179746888577938,
                -0.007021546363830566,
                -0.03235819190740585,
                0.02278439886868,
                0.03364378958940506,
                0.024433230981230736,
                -0.016834305599331856,
                -0.007904534228146076,
                0.02273685485124588,
                0.03024118021130562,
                0.02447347156703472,
                -0.03109585866332054,
                -0.028157055377960205,
                -0.01380907092243433,
                -0.03552009165287018,
                -0.0018182663479819894,
                -0.012310356833040714,
                0.012263675220310688,
                -0.04036850482225418,
                0.00485156150534749,
                0.04383973032236099,
                0.014312076382339,
                0.0015650802524760365,
                -0.007363390643149614,
                0.03481468930840492,
                0.04342815652489662,
                0.04396630451083183,
                -0.032094936817884445,
                0.01776852272450924,
                0.0224752239882946,
                0.03302362188696861,
                -0.0023120262194424868,
                0.017824430018663406,
                0.0396227203309536,
                0.013881624676287174,
                -0.054032064974308014,
                -0.029186159372329712,
                -0.02433685213327408,
                0.06563279777765274,
                0.04190034419298172,
                -0.023793555796146393,
                0.07884786278009415,
                -0.07453630119562149,
                0.029948195442557335,
                -0.054404258728027344,
                -0.0010568685829639435,
                0.02182934246957302,
                0.012140418402850628,
                0.04172147810459137,
                -0.026511041447520256,
                -0.03375987708568573,
                -0.012688592076301575,
                -0.008087418042123318,
                0.011752299964427948,
                -0.013157540932297707,
                0.011532511562108994,
                -0.055159468203783035,
                0.01619805581867695,
                -0.015945205464959145,
                -0.011434628628194332,
                0.02778911218047142,
                0.038833633065223694,
                0.008635926991701126,
                0.017588652670383453,
                0.009153141640126705,
                -0.021690495312213898,
                0.021979158744215965,
                -0.035571448504924774,
                -0.025961698964238167,
                -0.029630761593580246,
                0.004026627633720636,
                0.026784418150782585,
                -0.02585168182849884,
                0.012212030589580536,
                0.037886835634708405,
                -0.01339351013302803,
                -0.0018885317258536816,
                0.03119473159313202,
                0.04657736048102379,
                -0.025739096105098724,
                0.026634003967046738,
                0.0616469644010067,
                0.019643401727080345,
                -0.004273151978850365,
                -0.031568776816129684,
                0.039359137415885925,
                0.03096753917634487,
                0.01656346023082733,
                -0.009623968973755836,
                -0.04677334055304527,
                -0.0012416300596669316,
                0.04342522844672203,
                -0.030161207541823387,
                -0.06032944098114967,
                0.0009133215062320232,
                -0.02571413852274418,
                0.024497374892234802,
                0.03953317925333977,
                0.005664624739438295,
                -5.6826772791588146e-33,
                -0.0015017226105555892,
                -0.02090553566813469,
                -0.025941666215658188,
                -0.042396679520606995,
                -0.054005615413188934,
                -0.04581882804632187,
                0.029488351196050644,
                0.016160117462277412,
                -0.05208686739206314,
                -0.012620680965483189,
                -0.04475121945142746,
                0.011120637878775597,
                0.022381573915481567,
                0.018082166090607643,
                0.07438968122005463,
                0.0345362164080143,
                0.03724096715450287,
                0.0019375929841771722,
                0.006032290402799845,
                0.011162112466990948,
                -0.04896187409758568,
                0.017886625602841377,
                -0.008470173925161362,
                -0.04386169835925102,
                0.0026536141522228718,
                0.0045696101151406765,
                -0.035690635442733765,
                0.037921611219644547,
                -0.022800443693995476,
                -0.05695850029587746,
                0.008784820325672626,
                0.029099516570568085,
                -0.011870517395436764,
                -0.02285713516175747,
                -0.037909265607595444,
                -0.019352639093995094,
                -0.06022436544299126,
                0.0012230414431542158,
                -0.02980611100792885,
                -0.018599392846226692,
                -0.006535916589200497,
                0.0018903047312051058,
                0.008500778116285801,
                -0.016515130177140236,
                -0.04140469431877136,
                -0.033186960965394974,
                -0.006878864485770464,
                0.03567185997962952,
                0.06108756363391876,
                -0.0392921045422554,
                -0.057869236916303635,
                -0.04159938171505928,
                -0.018658924847841263,
                0.031491510570049286,
                -0.00837960559874773,
                -0.005475162994116545,
                0.020739838480949402,
                0.04124646261334419,
                -0.004652346484363079,
                0.02670077234506607,
                -0.016347423195838928,
                0.004177649039775133,
                0.0783410370349884,
                -0.06323052197694778,
                -0.011390243656933308,
                0.0034727819729596376,
                0.056065499782562256,
                0.0854213535785675,
                0.009306476451456547,
                -0.05651674047112465,
                -0.0029230897780507803,
                0.060551393777132034,
                -0.029720570892095566,
                -0.024726057425141335,
                -0.04051464796066284,
                -0.059481192380189896,
                -0.05773244798183441,
                0.0011459110537543893,
                -0.00465834466740489,
                0.029415009543299675,
                -0.05680827423930168,
                -0.015252896584570408,
                0.020800165832042694,
                -0.010647254064679146,
                0.010414325632154942,
                -0.07119698077440262,
                0.021549073979258537,
                0.06778409332036972,
                -0.026205331087112427,
                -0.0009064939804375172,
                0.003030885010957718,
                -0.07051800191402435,
                -0.0299373846501112,
                -0.013878535479307175,
                0.02188451960682869,
                0.033941350877285004,
                -0.06277995556592941,
                -0.028148164972662926,
                -0.007607422769069672,
                0.07702209055423737,
                -0.022032802924513817,
                0.04463295638561249,
                -0.08206924796104431,
                0.05331159755587578,
                0.015353609807789326,
                0.028840364888310432,
                -0.027999067679047585,
                -0.01877005025744438,
                -0.016690434888005257,
                -0.009870847687125206,
                -0.03177659213542938,
                -0.01217455230653286,
                -0.009084750898182392,
                -0.012544593773782253,
                -0.010076826438307762,
                0.008114878088235855,
                -0.004645721521228552,
                -0.07045188546180725,
                -0.02189776487648487,
                0.05379258468747139,
                0.04499620571732521,
                -0.027345066890120506,
                -0.02298429049551487,
                0.037403520196676254,
                -0.014429342932999134,
                -0.02935594879090786,
                0.007499733939766884,
                -0.021586844697594643,
                -0.0008815845940262079,
                0.014356391504406929,
                0.016900653019547462,
                0.017017651349306107,
                2.5014878701767884e-07,
                -0.028181396424770355,
                0.03544975817203522,
                0.01000895630568266,
                0.04707082733511925,
                -0.0022501121275126934,
                -0.019021978601813316,
                -0.03848312422633171,
                0.007613236550241709,
                0.005236778408288956,
                0.020997870713472366,
                0.010733445174992085,
                -0.006232320796698332,
                0.01300609391182661,
                -0.04851865768432617,
                -0.028068048879504204,
                -0.08711563795804977,
                0.042986221611499786,
                -0.037261899560689926,
                -0.002073870738968253,
                -0.04593394696712494,
                0.011858264915645123,
                0.01079885009676218,
                0.0031200763769447803,
                0.0318605974316597,
                -0.011612300761044025,
                0.008420126512646675,
                -0.012112937867641449,
                0.00991029106080532,
                0.08270897716283798,
                0.023262877017259598,
                0.02664545550942421,
                -0.0718599483370781,
                0.01805124059319496,
                0.01309044286608696,
                0.005602505523711443,
                -0.02190951444208622,
                0.03856753930449486,
                -0.024992186576128006,
                -0.012455329298973083,
                0.013464733026921749,
                -0.06854669749736786,
                0.007606824394315481,
                0.03645117208361626,
                -0.007828054018318653,
                -0.024678977206349373,
                0.025475967675447464,
                0.023690540343523026,
                -0.023722868412733078,
                -0.06827516853809357,
                -0.015256283804774284,
                -0.032635025680065155,
                0.06251225620508194,
                0.011954952962696552,
                -0.01695670187473297,
                0.013294089585542679,
                -0.003961259964853525,
                -0.03897908702492714,
                0.03894453123211861,
                0.03277512639760971,
                -0.01810801587998867,
                -0.023157654330134392,
                -0.03797655180096626,
                0.0004453824076335877,
                -0.01431170292198658,
                0.09418807923793793,
                -0.05501246824860573,
                -0.020627034828066826,
                2.2598360024005185e-34,
                -0.0430985763669014,
                0.009897265583276749,
                0.03141215071082115,
                0.028371592983603477,
                0.022788817062973976,
                -0.016752898693084717,
                -0.0009411227656528354,
                0.02376594766974449,
                -0.005352032836526632,
                -0.0074571361765265465,
                -0.02042955718934536
            ]
        },
        "Freelance": "Open to both",
        "Id": "894d8a45-3264-490f-9896-c895d7e81135",
        "Industry": {
            "name": "\tSustainable Energy",
            "rep": [
                -0.009083733893930912,
                0.038073524832725525,
                -0.015671029686927795,
                0.016159774735569954,
                0.016309399157762527,
                -0.009580503217875957,
                -0.020029155537486076,
                0.027255535125732422,
                -0.05782391130924225,
                -0.013792426325380802,
                0.03574734926223755,
                0.09273739159107208,
                0.00023140740813687444,
                0.06929219514131546,
                0.03976467251777649,
                -0.0041216653771698475,
                0.06114097684621811,
                0.042868904769420624,
                -0.015458503738045692,
                -0.002585943788290024,
                -0.03590669855475426,
                -0.03563481941819191,
                0.02199021726846695,
                0.005610962398350239,
                -0.031294677406549454,
                0.012139813974499702,
                -0.010262511670589447,
                0.032747913151979446,
                0.02888093888759613,
                0.005483520217239857,
                0.04242796078324318,
                0.011706605553627014,
                0.01458992250263691,
                0.007523464970290661,
                1.547105625832046e-06,
                -0.022880304604768753,
                -0.052944064140319824,
                -0.030578838661313057,
                0.037655048072338104,
                -0.024561187252402306,
                0.07470433413982391,
                0.01733347401022911,
                -0.03447471559047699,
                0.014014018699526787,
                0.016945239156484604,
                -0.037194330245256424,
                0.03286729380488396,
                -0.050646718591451645,
                -0.0024479138664901257,
                -0.04246026650071144,
                -0.025582417845726013,
                -0.015890007838606834,
                -0.06707383692264557,
                -0.02464359626173973,
                -0.02623760513961315,
                0.13031399250030518,
                0.011265627108514309,
                -0.022112950682640076,
                -0.07976244390010834,
                0.000631072383839637,
                -0.02640654891729355,
                0.06538725644350052,
                -0.03284405916929245,
                0.001337213208898902,
                0.08874557167291641,
                -0.009215609170496464,
                0.002423627069219947,
                0.002978087402880192,
                0.028840862214565277,
                -0.003733486868441105,
                0.021013304591178894,
                0.0034927865490317345,
                0.006886893417686224,
                0.04492988437414169,
                -0.07659520953893661,
                0.01664223149418831,
                0.02141842059791088,
                -0.012988857924938202,
                0.027039693668484688,
                0.008495934307575226,
                0.0020141226705163717,
                0.005470732692629099,
                -0.02217055857181549,
                0.016070561483502388,
                -0.01742827519774437,
                0.04714055731892586,
                -0.04564420133829117,
                0.012408718466758728,
                0.04417646303772926,
                0.04426463320851326,
                0.0228803139179945,
                0.026243267580866814,
                -0.008724075742065907,
                -0.022684987634420395,
                0.051905836910009384,
                0.020587287843227386,
                0.00012275968038011342,
                0.02301235869526863,
                -0.008269227109849453,
                -0.022790061309933662,
                0.008832325227558613,
                0.011344795115292072,
                0.07143335789442062,
                0.02104988880455494,
                0.00037788963527418673,
                -0.05618339776992798,
                -0.04978972673416138,
                -0.08240329474210739,
                -0.00995404738932848,
                -0.01324146892875433,
                -0.006213456857949495,
                -0.024450013414025307,
                0.004695270210504532,
                -0.01931365579366684,
                -0.017392918467521667,
                -0.04375757277011871,
                0.014251478016376495,
                -0.013504330068826675,
                -0.04414144903421402,
                0.015038875862956047,
                -0.0011774711310863495,
                0.019164325669407845,
                0.04689398780465126,
                0.013984113931655884,
                0.04671061411499977,
                0.08741701394319534,
                -0.042737215757369995,
                0.010036605410277843,
                0.008007669821381569,
                0.006470512133091688,
                0.02205064333975315,
                -0.016591060906648636,
                -0.03508823737502098,
                0.028619341552257538,
                -0.02844567596912384,
                0.051787763833999634,
                0.017366744577884674,
                -0.02119823731482029,
                -0.07423513382673264,
                -0.05143095180392265,
                0.053956288844347,
                0.004218238405883312,
                -0.04656190797686577,
                0.018072225153446198,
                -0.04660283774137497,
                -0.019263053312897682,
                0.02657220885157585,
                0.01999816671013832,
                -0.009729242883622646,
                -0.012809768319129944,
                0.012833052314817905,
                -0.019840136170387268,
                -0.07105091959238052,
                0.014644669368863106,
                0.05974172055721283,
                0.01843593269586563,
                -0.056810494512319565,
                0.02284478396177292,
                0.051607806235551834,
                0.004658916965126991,
                -0.004109499044716358,
                -0.008362646214663982,
                -0.04544819891452789,
                -0.01611258275806904,
                0.04198528826236725,
                -0.0032323501072824,
                0.013715953566133976,
                -0.011472651734948158,
                -0.04722507670521736,
                -0.03797263279557228,
                -0.012016722932457924,
                -0.025766735896468163,
                -0.035527393221855164,
                0.06003441661596298,
                0.03978167474269867,
                -0.005175201687961817,
                -0.04725498706102371,
                -0.010639534331858158,
                -0.024509135633707047,
                0.020540865138173103,
                -0.043002646416425705,
                -0.07270119339227676,
                0.019593242555856705,
                0.003490857081487775,
                -0.007973326370120049,
                0.00668669119477272,
                0.007656000554561615,
                0.015055757947266102,
                0.037860721349716187,
                -0.030067047104239464,
                -0.018952403217554092,
                0.00904599204659462,
                0.0016384097980335355,
                0.037334661930799484,
                0.011754670180380344,
                0.011876136995851994,
                0.016680581495165825,
                0.0934290736913681,
                -0.00224958430044353,
                -0.016037188470363617,
                -0.014284682460129261,
                -0.0044863405637443066,
                0.04282039403915405,
                -0.004673441406339407,
                -0.032334957271814346,
                0.03491807356476784,
                0.004120926838368177,
                -0.029119523242115974,
                -0.1001255214214325,
                -0.07745342701673508,
                -0.008108308538794518,
                -0.024392573162913322,
                0.027202561497688293,
                0.031904663890600204,
                -0.012293723411858082,
                -0.012281049974262714,
                0.0191777553409338,
                0.014999603852629662,
                -0.07072506099939346,
                -0.0070690494030714035,
                -0.008136100135743618,
                0.04874948039650917,
                0.012037151493132114,
                0.013423770666122437,
                0.0568084679543972,
                0.060395073145627975,
                -0.05241144448518753,
                0.001890901941806078,
                0.023766454309225082,
                -0.0056129456497728825,
                0.008918252773582935,
                0.005446908995509148,
                0.028225500136613846,
                -0.030319074168801308,
                -0.06209259107708931,
                0.022999750450253487,
                0.03599751368165016,
                0.0662248283624649,
                -0.0005802469095215201,
                -0.07199303060770035,
                0.041877321898937225,
                0.006712033413350582,
                0.05645349249243736,
                0.021615808829665184,
                0.008048863150179386,
                -0.007725509814918041,
                -0.02937827631831169,
                -0.024997731670737267,
                0.020045092329382896,
                0.004665885120630264,
                -0.007194833364337683,
                -0.00170053297188133,
                0.049474235624074936,
                0.042838629335165024,
                0.037235867232084274,
                -0.04474210739135742,
                -0.013512460514903069,
                0.021442025899887085,
                -0.04661119729280472,
                0.018684234470129013,
                0.05593894422054291,
                -0.06725432723760605,
                -0.05972212925553322,
                0.00344665115699172,
                0.00761180417612195,
                -0.015383384190499783,
                0.08856562525033951,
                -0.008272615261375904,
                -0.006791208870708942,
                -0.009683472104370594,
                0.004542888607829809,
                -0.018773099407553673,
                0.0019249734468758106,
                -0.002192906104028225,
                0.013184246607124805,
                0.010347824543714523,
                0.05549756810069084,
                0.03596369922161102,
                -0.05134125426411629,
                -0.01282177772372961,
                -0.014895697124302387,
                -0.009442494250833988,
                -0.04534398764371872,
                0.019597649574279785,
                0.007289342116564512,
                0.07589197903871536,
                -0.012937615625560284,
                -0.011732588522136211,
                -0.0616404190659523,
                -0.003659802721813321,
                0.03999359533190727,
                -0.018166258931159973,
                0.01328863762319088,
                -0.005428964272141457,
                -0.05135120451450348,
                0.016588622704148293,
                -0.007343548350036144,
                -0.02811254933476448,
                0.031095704063773155,
                0.03041004203259945,
                -0.028107276186347008,
                -0.014972707256674767,
                -0.03823301941156387,
                -0.005672657862305641,
                0.04493090882897377,
                0.012810183688998222,
                0.00354515528306365,
                -0.012903058901429176,
                0.010904631577432156,
                -0.011802426539361477,
                0.04097313806414604,
                0.055457811802625656,
                0.08795733004808426,
                0.07783933728933334,
                0.0005128653137944639,
                -0.06343846768140793,
                -0.015249405987560749,
                -0.01332930475473404,
                0.031523630023002625,
                0.05044938996434212,
                0.05686939135193825,
                0.006280809640884399,
                0.013583767227828503,
                -0.036491334438323975,
                -0.010500365868210793,
                -0.011556350626051426,
                -0.0335676446557045,
                0.032706573605537415,
                -0.00020535633666440845,
                -0.046487484127283096,
                0.019747557118535042,
                0.005819294136017561,
                0.045718006789684296,
                0.031125398352742195,
                -0.03721045330166817,
                -0.013781225308775902,
                0.0338214710354805,
                -0.02251538448035717,
                0.0074572996236383915,
                0.029742563143372536,
                -0.022061234340071678,
                -0.059741728007793427,
                -0.044485222548246384,
                -0.02989943139255047,
                -0.021593615412712097,
                -0.002479252405464649,
                0.03165239840745926,
                0.008751318790018559,
                -0.04299745708703995,
                -0.009865501895546913,
                0.05427803844213486,
                0.07144445925951004,
                -0.001052092993631959,
                -0.02181975357234478,
                0.0032849092967808247,
                0.05398013815283775,
                -0.026998410001397133,
                -0.03198760747909546,
                -0.024424219503998756,
                0.04511137306690216,
                0.013665424659848213,
                0.021495994180440903,
                -0.013342230580747128,
                -0.019276194274425507,
                -0.0018128145020455122,
                0.06263849139213562,
                0.042428258806467056,
                0.017820248380303383,
                0.007507422007620335,
                -0.028240591287612915,
                0.00577819999307394,
                0.011994034051895142,
                0.008848336525261402,
                0.04583434760570526,
                -0.01922876574099064,
                0.07851526141166687,
                0.030927693471312523,
                -0.0035480817314237356,
                0.03169071674346924,
                -0.030887676402926445,
                0.03092089481651783,
                0.0046529765240848064,
                0.0022020728792995214,
                0.0612199604511261,
                -0.006924119777977467,
                0.011833040975034237,
                -0.008094285614788532,
                -0.032888468354940414,
                0.06449563056230545,
                0.006392187904566526,
                -0.0006460032309405506,
                0.013507540337741375,
                -0.04207064211368561,
                0.04881390184164047,
                -0.0004330565279815346,
                -0.0499471053481102,
                -0.058363255113363266,
                0.006247167009860277,
                -0.018813196569681168,
                0.02080240473151207,
                -0.008798141032457352,
                0.07278166711330414,
                0.04638329893350601,
                -0.01563868671655655,
                -0.050049953162670135,
                -0.02308025024831295,
                0.12467830628156662,
                -0.005487273912876844,
                0.04078531637787819,
                -0.021181611344218254,
                -0.001911256229504943,
                -0.07820943742990494,
                0.016170013695955276,
                -0.07610563933849335,
                -0.059101663529872894,
                -0.04096736013889313,
                -0.012169213965535164,
                0.016123156994581223,
                0.019240660592913628,
                -0.0296695027500391,
                0.019289154559373856,
                0.07685478776693344,
                0.042069703340530396,
                -0.036151837557554245,
                0.004481784533709288,
                -0.00853752437978983,
                -0.006662307772785425,
                0.02354137971997261,
                0.022436127066612244,
                0.01615080051124096,
                0.02600867673754692,
                -0.06434180587530136,
                -0.05550254508852959,
                -0.003969020210206509,
                0.04290509223937988,
                0.015033737756311893,
                -0.00642425287514925,
                0.005631044041365385,
                -0.01912969909608364,
                0.019338447600603104,
                0.00841097254306078,
                -0.015290537849068642,
                0.004762709140777588,
                -0.03749711066484451,
                -0.01251709833741188,
                0.0514899343252182,
                0.027074886485934258,
                -0.02705620788037777,
                -0.07439998537302017,
                -0.01690717786550522,
                0.025234365835785866,
                -0.022826116532087326,
                -0.018952859565615654,
                0.04128430783748627,
                0.05942929908633232,
                0.011613189242780209,
                -0.05037922412157059,
                -0.006616543512791395,
                -0.02983524091541767,
                0.019807519391179085,
                0.031060466542840004,
                0.03031183034181595,
                -0.046340469270944595,
                0.036233529448509216,
                0.07968494296073914,
                -0.0494210384786129,
                0.010573194362223148,
                -0.09850665926933289,
                -0.017873568460345268,
                0.0481794998049736,
                0.002732204971835017,
                -0.006876904517412186,
                0.009256335906684399,
                0.04839353635907173,
                0.015488764271140099,
                -0.07424772530794144,
                0.003913873340934515,
                -0.027662817388772964,
                0.027112407609820366,
                0.02559332363307476,
                -0.004089346621185541,
                0.018955742940306664,
                -0.05185534432530403,
                -0.004143248777836561,
                0.008842860348522663,
                -0.0027031521312892437,
                0.06416093558073044,
                0.024571243673563004,
                -0.04370952025055885,
                0.03779181092977524,
                -0.024342553690075874,
                -0.010732217691838741,
                0.0015744599513709545,
                0.10083652287721634,
                -0.0008678114973008633,
                0.025880133733153343,
                0.024117998778820038,
                0.03732882812619209,
                -0.006109122186899185,
                -0.012058566324412823,
                -0.05379200354218483,
                0.018819648772478104,
                -0.019139520823955536,
                -0.012816128320991993,
                0.006286364980041981,
                -0.009121134877204895,
                0.005505973473191261,
                0.025249836966395378,
                0.016728969290852547,
                0.023078031837940216,
                -0.0031301032286137342,
                0.05066267400979996,
                0.023720405995845795,
                -0.06251028180122375,
                0.023237790912389755,
                0.03878963738679886,
                -0.007248976267874241,
                0.04037141054868698,
                -0.0014739015605300665,
                -0.009305242449045181,
                -0.01972191035747528,
                0.021983347833156586,
                0.005625605117529631,
                -0.03856164589524269,
                -0.0051238080486655235,
                -0.00363778043538332,
                0.03810630738735199,
                -0.02402576059103012,
                -0.02400367334485054,
                0.035353705286979675,
                0.0290096215903759,
                -0.034149035811424255,
                -0.02391251176595688,
                0.0007502874941565096,
                -0.055246781557798386,
                0.0626416727900505,
                -0.05153007060289383,
                -0.006561846937984228,
                -0.0067425500601530075,
                0.04137112572789192,
                -0.03321593627333641,
                0.011129073798656464,
                -0.01596672832965851,
                0.0036829691380262375,
                0.02400643192231655,
                0.001553230918943882,
                0.01872585341334343,
                -0.01018090732395649,
                0.0056785037741065025,
                -0.05360604077577591,
                0.03226953372359276,
                0.00609384011477232,
                -0.049215979874134064,
                -0.011936724185943604,
                0.02504342421889305,
                -6.919232316692581e-33,
                -0.01055220142006874,
                -0.028905589133501053,
                0.011552009731531143,
                -0.035938773304224014,
                -0.08981624245643616,
                -0.02369016967713833,
                0.0015972575638443232,
                -0.02198498323559761,
                -0.06789838522672653,
                -0.006270504556596279,
                -0.016167178750038147,
                -0.04358532652258873,
                0.019743289798498154,
                0.02715451829135418,
                -0.012898282147943974,
                0.007241751067340374,
                -0.010505731217563152,
                0.023910244926810265,
                0.008155900053679943,
                -0.053812284022569656,
                0.06480291485786438,
                0.006481527350842953,
                0.023781443014740944,
                0.06222369149327278,
                0.017935100942850113,
                0.025695161893963814,
                -0.03894876688718796,
                -0.013668722473084927,
                -0.0022952042054384947,
                -0.04934441298246384,
                -0.01132755633443594,
                0.027039343491196632,
                0.01631452888250351,
                -0.07758847624063492,
                -0.002268532058224082,
                0.05744269862771034,
                -0.01914057321846485,
                -0.046996064484119415,
                -0.06545784324407578,
                -0.013552125543355942,
                -0.06078390032052994,
                -0.02840188704431057,
                0.008142219856381416,
                -0.017579086124897003,
                -0.04035786911845207,
                -0.011887576431035995,
                -0.010890666395425797,
                -0.050445638597011566,
                -0.003753634635359049,
                -0.02900988794863224,
                -0.013198062777519226,
                -0.030828023329377174,
                -0.023021681234240532,
                0.09389407187700272,
                -0.06055804714560509,
                0.040256816893815994,
                0.006331847980618477,
                0.03653721511363983,
                -0.025438567623496056,
                -0.0073036691173911095,
                0.00237235683016479,
                0.02824953943490982,
                0.00299791875295341,
                -0.015045525506138802,
                -0.046893611550331116,
                0.023325975984334946,
                -0.057212647050619125,
                -0.01949390396475792,
                -0.03537595272064209,
                0.0029788764659315348,
                0.0048802937380969524,
                0.015065781772136688,
                -0.00712051335722208,
                -0.04595794528722763,
                -0.04518596827983856,
                -0.08107273280620575,
                0.024578621610999107,
                -0.020990535616874695,
                0.08476556837558746,
                0.03529996797442436,
                -0.020294012501835823,
                -0.014141503721475601,
                0.10015013068914413,
                -0.02267410233616829,
                -0.07603869587182999,
                0.015001630410552025,
                -0.017681894823908806,
                0.06254933774471283,
                0.014551089145243168,
                -0.003323413198813796,
                -0.015623614192008972,
                -0.02113582380115986,
                -0.015144290402531624,
                0.025137024000287056,
                0.050259850919246674,
                0.13039182126522064,
                0.017806505784392357,
                -0.054206691682338715,
                0.022015094757080078,
                0.037855517119169235,
                0.0022141942754387856,
                0.011829360388219357,
                -0.049717411398887634,
                0.035703256726264954,
                -0.029194587841629982,
                -0.02046392485499382,
                -0.04006502777338028,
                -0.021960502490401268,
                -0.006147600710391998,
                0.01830133982002735,
                -0.004283735062927008,
                -0.042950231581926346,
                0.013318446464836597,
                -0.07616476714611053,
                -0.01879272237420082,
                -0.032710183411836624,
                0.020569393411278725,
                0.0134686678647995,
                -0.0650092363357544,
                0.007988185621798038,
                0.0339062437415123,
                0.03916749358177185,
                -0.03974032774567604,
                0.08308639377355576,
                0.029776547104120255,
                0.01126040332019329,
                0.010984794236719608,
                -0.03296374902129173,
                -0.07014472782611847,
                -0.001092461752705276,
                -0.014240778982639313,
                0.028553925454616547,
                2.1919088055710745e-07,
                -0.060513295233249664,
                -0.02193266712129116,
                -0.05472876876592636,
                0.004050736781209707,
                0.005572374910116196,
                0.05185122787952423,
                0.0035121655091643333,
                0.04160397872328758,
                -0.04941454902291298,
                -0.017331130802631378,
                -0.004200952593237162,
                -0.024114999920129776,
                -0.0005276398733258247,
                -0.01254240982234478,
                0.0008600149885751307,
                -0.09428340941667557,
                -0.00611383281648159,
                -0.020937955006957054,
                0.015116307884454727,
                -0.014429084025323391,
                -0.0011435927590355277,
                0.023898759856820107,
                -0.027658218517899513,
                -0.011609552428126335,
                0.014872600324451923,
                0.008757264353334904,
                0.0034526153467595577,
                -0.06790626049041748,
                0.09330731630325317,
                -0.004350307863205671,
                0.04184575006365776,
                -0.04399557784199715,
                0.03571246191859245,
                -0.07383373379707336,
                0.022626006975769997,
                -0.04539305716753006,
                -0.0015063302125781775,
                0.04439213126897812,
                0.015956956893205643,
                -0.010832376778125763,
                -0.01540918555110693,
                -0.01182110421359539,
                -0.005884708371013403,
                -0.005855627357959747,
                -0.03298116847872734,
                0.00802675448358059,
                0.02318318746984005,
                0.08331527560949326,
                -0.06359582394361496,
                0.009886318817734718,
                -0.04264185577630997,
                0.0012665296671912074,
                -0.0316990464925766,
                0.03557854890823364,
                0.006304527632892132,
                -0.02584882080554962,
                -0.020990954712033272,
                0.05515848845243454,
                0.06916865706443787,
                -0.035741593688726425,
                -0.025288837030529976,
                0.05218639224767685,
                -0.06409072875976562,
                0.06082821637392044,
                0.039353881031274796,
                0.041787248104810715,
                -0.025633664801716805,
                1.1111874629926157e-34,
                0.006772750988602638,
                -0.03433190658688545,
                -0.07306572794914246,
                0.021479137241840363,
                -0.018324337899684906,
                0.02161397971212864,
                -0.005992808844894171,
                0.0015536920400336385,
                -0.026237566024065018,
                -0.03408069163560867,
                -0.0024031423963606358
            ]
        },
        "Location": "Albuquerque, New Mexico",
        "Remuneration": 45.67307692307692,
        "Services Required": {
            "name": "Facility Wet Mechanical Engineer, Intel Corporation                                 ",
            "rep": [
                -0.02144433557987213,
                -0.03557782992720604,
                -0.049093104898929596,
                -0.002739192685112357,
                0.011167634278535843,
                -0.0038804528303444386,
                0.06809485703706741,
                0.03509986773133278,
                -0.003263408550992608,
                0.014197461307048798,
                0.008071119897067547,
                0.0021476270630955696,
                0.04056468978524208,
                0.015304313972592354,
                0.04592999815940857,
                0.023880524560809135,
                0.02261698991060257,
                0.02759830839931965,
                -0.044851385056972504,
                -0.006895328406244516,
                0.001748761278577149,
                1.330451686953893e-05,
                -0.015326728112995625,
                0.003983004949986935,
                -0.0546150878071785,
                0.020305756479501724,
                0.002805706113576889,
                0.018894482403993607,
                0.027498675510287285,
                0.053130947053432465,
                0.00699948612600565,
                -0.01027570478618145,
                0.011478828266263008,
                -0.009248494170606136,
                1.45805393003684e-06,
                0.0308967474848032,
                0.05753316357731819,
                -0.007043730467557907,
                -0.009136841632425785,
                0.024595877155661583,
                -0.03948737308382988,
                -0.04175739362835884,
                -0.04564039781689644,
                0.03935373201966286,
                0.013545027002692223,
                0.01516181230545044,
                0.041957445442676544,
                -0.007037238217890263,
                -0.023152263835072517,
                -0.02863619662821293,
                0.0034472839906811714,
                0.04229944571852684,
                -0.003989723976701498,
                -0.0007500500651076436,
                0.0006299095693975687,
                -0.020689649507403374,
                -0.006228005047887564,
                -0.000265995942754671,
                0.055576033890247345,
                0.005514573771506548,
                -0.006519604008644819,
                0.007027275860309601,
                0.011068514548242092,
                -0.018884360790252686,
                0.028595998883247375,
                0.07485257834196091,
                0.040807828307151794,
                -0.006891262251883745,
                0.0257504191249609,
                0.0030159540474414825,
                0.03444914147257805,
                0.022864731028676033,
                -0.045082855969667435,
                0.045568786561489105,
                -0.03629852831363678,
                -0.013943733647465706,
                0.0448879711329937,
                0.005162688437849283,
                -0.04334934800863266,
                -0.03108298033475876,
                -0.04182596504688263,
                0.012918571010231972,
                0.004191468469798565,
                -0.02016030251979828,
                0.0169529989361763,
                0.06904954463243484,
                -0.010924722999334335,
                -0.0014186771586537361,
                0.014707470312714577,
                0.02789272367954254,
                0.02843034453690052,
                -0.011292959563434124,
                0.025411007925868034,
                -0.0036104898899793625,
                -0.015279176644980907,
                -0.008530894294381142,
                -0.02545684389770031,
                -0.010318170301616192,
                -0.02516167052090168,
                -0.047017719596624374,
                0.0131406020373106,
                0.022579513490200043,
                0.05693044513463974,
                0.03586148843169212,
                -0.06707282364368439,
                -0.005980130285024643,
                -0.002175510860979557,
                0.015081491321325302,
                -0.010112147778272629,
                0.006538877729326487,
                -0.023486733436584473,
                -0.045957405120134354,
                -0.01595301926136017,
                -0.013263460248708725,
                0.014965809881687164,
                0.011945324949920177,
                0.009093811735510826,
                0.018843695521354675,
                -0.0010485801612958312,
                0.0017262567998841405,
                0.04659697040915489,
                -0.006882904097437859,
                -0.00021011976059526205,
                -0.004741612821817398,
                -0.026596782729029655,
                -0.014180521480739117,
                -0.030080854892730713,
                0.02133013866841793,
                0.0070869955234229565,
                -0.03533253073692322,
                -0.03769829869270325,
                -0.011990411207079887,
                -0.020476333796977997,
                -0.02935054339468479,
                -0.016936060041189194,
                0.06887530535459518,
                -0.02083016373217106,
                0.021519389003515244,
                -0.05526129901409149,
                0.025603696703910828,
                0.023668363690376282,
                -0.013895724900066853,
                -0.04715267941355705,
                0.07101573795080185,
                0.03655038774013519,
                -0.050541531294584274,
                0.002461739582940936,
                0.015005397610366344,
                0.028607778251171112,
                0.014270042069256306,
                0.00955404993146658,
                0.001853987923823297,
                -0.0035847278777509928,
                -0.002900391584262252,
                0.032637305557727814,
                0.019848646596074104,
                0.04595508426427841,
                -0.044712092727422714,
                -0.006963580381125212,
                0.06445303559303284,
                0.02207477204501629,
                -0.04912034794688225,
                -0.017953194677829742,
                0.030576901510357857,
                -0.021170882508158684,
                -0.008344556204974651,
                0.022009188309311867,
                -0.007570226211100817,
                -0.055177394300699234,
                0.005716314539313316,
                0.011623245663940907,
                -0.02408248744904995,
                0.014426520094275475,
                0.07630845159292221,
                0.01130822766572237,
                -0.004708824213594198,
                -0.00621472904458642,
                -0.014281822368502617,
                0.030591314658522606,
                0.05948096886277199,
                0.0013399765593931079,
                -0.1396757960319519,
                0.02114076353609562,
                0.0230737142264843,
                -0.0028820147272199392,
                -0.028823306784033775,
                -0.013986505568027496,
                -0.000680675613693893,
                -0.06148652359843254,
                -0.015167455188930035,
                -0.0071541243232786655,
                -0.009353392757475376,
                -0.06894417852163315,
                -0.005124874413013458,
                0.05271998420357704,
                0.010782068595290184,
                0.013311662711203098,
                -0.0031885486096143723,
                0.014903703704476357,
                0.023919017985463142,
                -0.025947678834199905,
                -0.013219237327575684,
                0.02339920401573181,
                0.09370766580104828,
                -0.029736783355474472,
                -0.058375559747219086,
                -0.03164282813668251,
                -0.016968000680208206,
                -0.054899267852306366,
                0.006804966367781162,
                0.034842636436223984,
                0.019348109140992165,
                0.04389682039618492,
                0.029151231050491333,
                -0.011049550957977772,
                -0.027869198471307755,
                -0.005005439277738333,
                0.031124336645007133,
                -0.02728082612156868,
                0.015079660341143608,
                -0.03848165646195412,
                -0.0061687882989645,
                -0.016595562919974327,
                -0.02036680281162262,
                -0.01420161034911871,
                0.0072805690579116344,
                0.03924577683210373,
                -0.013561245985329151,
                -0.036767151206731796,
                0.022482169792056084,
                0.010990411043167114,
                -0.019631195813417435,
                0.019893981516361237,
                -0.007236402947455645,
                0.00220124120824039,
                0.049889951944351196,
                0.006196300499141216,
                0.09740994870662689,
                -0.017085613682866096,
                -0.008146066218614578,
                -0.015576954931020737,
                -0.06286393105983734,
                -0.0024883367586880922,
                0.022156154736876488,
                0.04156653210520744,
                -0.02145073376595974,
                -0.03294185921549797,
                0.05506760627031326,
                0.036175861954689026,
                0.03553913161158562,
                0.006768064573407173,
                -0.053515058010816574,
                0.0446062907576561,
                0.03710493445396423,
                0.03503025323152542,
                -0.004675958305597305,
                0.023082634434103966,
                0.04167681932449341,
                -0.04302357882261276,
                -0.026404714211821556,
                0.018371522426605225,
                -0.0760980099439621,
                -0.03139105066657066,
                -0.011166865937411785,
                0.026241755113005638,
                0.013137624599039555,
                0.00839447695761919,
                -0.08814824372529984,
                -0.022410130128264427,
                0.04739841818809509,
                0.017294932156801224,
                0.02726290188729763,
                0.039184607565402985,
                -0.013796413317322731,
                0.0011611406225711107,
                0.02077309787273407,
                -0.04227181896567345,
                0.009692658670246601,
                -0.07706284523010254,
                0.016693370416760445,
                0.04648330435156822,
                0.003191048977896571,
                0.007787566632032394,
                -0.018238622695207596,
                0.0050211683847010136,
                0.008426700718700886,
                0.06355731934309006,
                -0.053123150020837784,
                -0.09547827392816544,
                0.043319351971149445,
                -0.00516594247892499,
                -0.02378552407026291,
                0.004435084760189056,
                -0.028426868841052055,
                -0.023278281092643738,
                0.006976483855396509,
                -0.006417997647076845,
                0.045166898518800735,
                -0.007610984146595001,
                0.0869489535689354,
                -0.019571350887417793,
                0.008455091156065464,
                0.003973355516791344,
                -0.006095138844102621,
                0.04069923236966133,
                0.0543479323387146,
                0.0009184718364849687,
                0.00974415335804224,
                -0.029510589316487312,
                -0.029850497841835022,
                -0.02027558907866478,
                0.040821950882673264,
                0.038150664418935776,
                0.048931777477264404,
                -0.0022417395375669003,
                0.005899488460272551,
                0.012000267393887043,
                0.023795856162905693,
                -0.010573716834187508,
                0.04965479299426079,
                -0.01229641493409872,
                0.011970874853432178,
                0.011840425431728363,
                -0.008750678040087223,
                0.014747954905033112,
                0.01850355975329876,
                -0.05621606111526489,
                0.025627698749303818,
                -0.01248934306204319,
                -0.10880777984857559,
                -0.0016668071039021015,
                0.047773122787475586,
                -0.07765398174524307,
                0.021070925518870354,
                0.08530335873365402,
                -0.07322408258914948,
                0.024929536506533623,
                -0.1090640127658844,
                -0.058021094650030136,
                0.033180516213178635,
                -0.03240007162094116,
                -0.05137917399406433,
                -0.02954121120274067,
                0.032107532024383545,
                0.021810002624988556,
                -0.01700207032263279,
                0.0713694766163826,
                -0.025011520832777023,
                -0.007608363870531321,
                -0.028410036116838455,
                0.09262257814407349,
                0.017931804060935974,
                -0.014374787919223309,
                -0.013999567367136478,
                -0.04947863146662712,
                0.026770809665322304,
                0.010660669766366482,
                0.043314117938280106,
                -0.007934248074889183,
                0.07790371030569077,
                -0.02927239052951336,
                -0.0036498326808214188,
                0.06917295604944229,
                -0.017620490863919258,
                -0.044970668852329254,
                -0.011219422332942486,
                0.053612325340509415,
                -0.03528141602873802,
                -0.023683950304985046,
                -0.042418841272592545,
                0.00781753845512867,
                0.04116242378950119,
                -0.07629453390836716,
                -0.04721308872103691,
                -0.009395007975399494,
                0.017551297321915627,
                0.0033483088482171297,
                0.10052736848592758,
                0.015929019078612328,
                -0.005695497617125511,
                0.01792658492922783,
                -0.022275716066360474,
                0.034016210585832596,
                0.037399787455797195,
                0.011536098085343838,
                -0.02606007643043995,
                -0.023788873106241226,
                -0.022912602871656418,
                -0.0029042954556643963,
                0.04093632474541664,
                0.013817967846989632,
                0.029383298009634018,
                -0.08882617205381393,
                -0.0747690498828888,
                0.01380535401403904,
                0.030120039358735085,
                0.017637399956583977,
                -0.030960213392972946,
                0.0147099569439888,
                0.041851114481687546,
                0.008723750710487366,
                0.043732900172472,
                -0.08805204927921295,
                0.04655054211616516,
                0.026799313724040985,
                -0.06147853285074234,
                0.09845344722270966,
                -0.025228235870599747,
                -0.045312393456697464,
                0.03953200951218605,
                0.06507639586925507,
                -0.0265303086489439,
                0.046436991542577744,
                -0.020355042070150375,
                -0.055156160145998,
                -0.035637661814689636,
                -0.01690812222659588,
                -0.033936530351638794,
                -0.014851173385977745,
                -0.049631230533123016,
                0.05312294512987137,
                0.021125268191099167,
                0.05563681572675705,
                -0.045043863356113434,
                0.08736468106508255,
                -0.02048339694738388,
                -0.025255082175135612,
                0.010936464183032513,
                0.012073823250830173,
                -0.031185537576675415,
                -0.07880890369415283,
                -0.02289796993136406,
                -0.019273918122053146,
                0.05279143527150154,
                0.004551517311483622,
                -0.04028230905532837,
                -0.05350946635007858,
                -0.051946017891168594,
                0.008639086037874222,
                0.010741847567260265,
                -0.059677768498659134,
                0.040416792035102844,
                0.03735573589801788,
                0.0019410161767154932,
                -0.037657689303159714,
                0.04763466492295265,
                -0.027806680649518967,
                -0.025060370564460754,
                -0.004150300286710262,
                -0.032521508634090424,
                0.09765342622995377,
                0.02194317616522312,
                -0.018499795347452164,
                -0.031176801770925522,
                -0.005466898903250694,
                -0.030873434618115425,
                0.009378512389957905,
                0.03702833876013756,
                -0.04352101311087608,
                0.005592499393969774,
                0.07645665109157562,
                0.0342429056763649,
                -0.012309771962463856,
                -0.013263030909001827,
                0.03418378904461861,
                0.00384110352024436,
                -0.0503523088991642,
                -0.07709541916847229,
                -0.008315243758261204,
                -0.06438863277435303,
                -0.021086672320961952,
                0.005395035725086927,
                -0.0449225977063179,
                -0.005584347061812878,
                -0.022269291803240776,
                0.042518164962530136,
                -0.01791577972471714,
                0.017939526587724686,
                -0.07897177338600159,
                0.022326087579131126,
                0.048854533582925797,
                0.020462527871131897,
                0.019592314958572388,
                -0.009850085712969303,
                -0.013563769869506359,
                0.02824592962861061,
                0.04972761496901512,
                -0.025250650942325592,
                -0.00998002290725708,
                0.04491811245679855,
                0.03175366297364235,
                -0.09804850071668625,
                -0.016792144626379013,
                -0.04187750071287155,
                0.06983209401369095,
                0.015233108773827553,
                0.017276927828788757,
                -0.011441802605986595,
                -0.03862825781106949,
                0.03922500088810921,
                -0.0561068169772625,
                0.02629425749182701,
                0.034176234155893326,
                0.02918834425508976,
                -0.009519522078335285,
                0.03668886050581932,
                -0.02096916362643242,
                -0.00408050324767828,
                -0.027980457991361618,
                0.008856276981532574,
                -0.016792908310890198,
                0.03183286637067795,
                -0.044802360236644745,
                -0.01890004239976406,
                0.004311102442443371,
                0.030080538243055344,
                0.003526991931721568,
                0.02563810721039772,
                -0.01599280722439289,
                -0.0005835536867380142,
                0.008011207915842533,
                -0.03178133815526962,
                0.0169406458735466,
                -0.0053039840422570705,
                0.0033812501933425665,
                -0.06284363567829132,
                0.03074820339679718,
                0.04777609929442406,
                -0.028435254469513893,
                0.013996293768286705,
                -0.037808608263731,
                -0.015778321772813797,
                -0.03501099720597267,
                0.02347654663026333,
                -0.0196225568652153,
                0.0773959755897522,
                0.04638318344950676,
                0.07148949801921844,
                0.015506365336477757,
                -0.024937398731708527,
                -0.0036394200287759304,
                0.019285880029201508,
                0.00016246076847892255,
                0.010468430817127228,
                0.011805802583694458,
                -0.05185045301914215,
                -0.005992998369038105,
                0.03354654088616371,
                0.033386945724487305,
                -0.04846768081188202,
                0.0029976118821650743,
                -0.025692705065011978,
                -0.021335257217288017,
                0.03337410092353821,
                -0.04185036942362785,
                -5.29989444154483e-33,
                -0.04388590157032013,
                0.013098725117743015,
                0.006216875743120909,
                0.031032541766762733,
                -0.07580284774303436,
                0.038914069533348083,
                0.05107908323407173,
                0.011007682420313358,
                -0.050649404525756836,
                -0.023877443745732307,
                -0.0075501189567148685,
                0.008619765751063824,
                0.02826806902885437,
                0.0061265560798347,
                -0.011454673483967781,
                0.04896996170282364,
                0.030371887609362602,
                0.005590504501014948,
                -0.018200330436229706,
                0.002722952514886856,
                -0.03359927609562874,
                0.019046777859330177,
                0.003408913966268301,
                -0.04433630406856537,
                0.0034390417858958244,
                -0.05066221207380295,
                -0.037535518407821655,
                0.007574227172881365,
                -0.018665550276637077,
                -0.06974929571151733,
                0.005642539821565151,
                0.00676762918010354,
                -0.011005868203938007,
                0.01803702488541603,
                -0.023634739220142365,
                0.02475297637283802,
                -0.07052213698625565,
                0.004595557227730751,
                -0.06978364288806915,
                0.029913142323493958,
                0.017231902107596397,
                -0.030814271420240402,
                0.009264016523957253,
                -0.026463208720088005,
                -0.014262902550399303,
                -0.028543639928102493,
                0.014082771725952625,
                0.037375252693891525,
                0.05153897404670715,
                -0.015240575186908245,
                -0.03089800477027893,
                0.004735857713967562,
                0.019547825679183006,
                0.017658289521932602,
                -0.035285551100969315,
                0.006341294851154089,
                -0.003893122309818864,
                -0.025033337995409966,
                -0.02712005190551281,
                -0.009392600506544113,
                -0.04987163841724396,
                0.06972097605466843,
                0.06334530562162399,
                -0.06358201056718826,
                0.008044796995818615,
                -0.00895207840949297,
                0.06576617807149887,
                0.04383770003914833,
                0.04702650010585785,
                -0.060871753841638565,
                -0.014591826125979424,
                0.0323011577129364,
                -0.04792037978768349,
                -0.06919097155332565,
                0.013837086968123913,
                -0.07894133776426315,
                0.030079767107963562,
                -0.022387400269508362,
                0.027234790846705437,
                0.01761208474636078,
                -0.05358220264315605,
                -0.015471153892576694,
                0.005691055208444595,
                -0.040707409381866455,
                0.05461056903004646,
                0.04049839824438095,
                0.0010119237704202533,
                -0.002066610846668482,
                0.04536634683609009,
                0.023584960028529167,
                0.028280356898903847,
                0.007497126702219248,
                0.001119020744226873,
                0.0289255753159523,
                0.13235585391521454,
                -0.0008863151306286454,
                0.05699019879102707,
                0.009378882125020027,
                -0.02126525156199932,
                0.049454767256975174,
                -0.06901342421770096,
                -0.012981673702597618,
                -0.0648089200258255,
                0.016278715804219246,
                0.024295128881931305,
                0.006248794961720705,
                -0.041106484830379486,
                -0.00946098007261753,
                -0.0178030077368021,
                0.014805292710661888,
                -0.025722917169332504,
                -0.013185707852244377,
                -0.023056861013174057,
                0.029235295951366425,
                -0.016349468380212784,
                -0.00403471477329731,
                0.023154864087700844,
                -0.0857522264122963,
                -0.001189837814308703,
                -0.008012349717319012,
                0.005342324264347553,
                0.0024314725305885077,
                -0.04624708741903305,
                0.029967406764626503,
                0.010141472332179546,
                0.031859397888183594,
                0.02082492597401142,
                -0.011126006953418255,
                -0.05504932999610901,
                -0.043239228427410126,
                -0.01931186579167843,
                0.05279318988323212,
                2.1227147328772844e-07,
                -0.05079143866896629,
                0.03651752695441246,
                0.016877556219697,
                0.05176328867673874,
                -0.02851637452840805,
                -0.040632784366607666,
                -0.011265017092227936,
                -0.03568985313177109,
                -0.0029989455360919237,
                0.013188938610255718,
                0.014508740045130253,
                -0.011057551018893719,
                0.0208792332559824,
                -0.07739449292421341,
                -0.0016914186999201775,
                -0.07781103998422623,
                0.08109330385923386,
                0.000882499385625124,
                -0.013793986290693283,
                -0.003889799350872636,
                0.057950444519519806,
                0.017297063022851944,
                0.016314834356307983,
                0.02621692232787609,
                -0.012102369219064713,
                -0.07330228388309479,
                0.03217433765530586,
                -0.004660166334360838,
                0.0627439022064209,
                0.03707074001431465,
                0.014284322038292885,
                0.015175926499068737,
                0.001660614158026874,
                0.0803421288728714,
                0.0042185187339782715,
                -0.04219721257686615,
                0.04028553143143654,
                0.04990212991833687,
                -0.02237897925078869,
                0.022022005170583725,
                -0.03147696703672409,
                0.026915576308965683,
                0.00427048746496439,
                0.01949900947511196,
                -0.018068162724375725,
                0.022143157199025154,
                0.01940331608057022,
                -0.006439319346100092,
                -0.04974046349525452,
                -0.026501303538680077,
                -0.04598252475261688,
                0.055158957839012146,
                0.04759761691093445,
                -0.013301907107234001,
                0.0272971224039793,
                -0.01988762430846691,
                -0.054954152554273605,
                0.03005368262529373,
                -0.001709403470158577,
                -0.015189356170594692,
                -0.06277605891227722,
                -0.04933656379580498,
                0.03811487928032875,
                -0.02398744970560074,
                0.03882556036114693,
                -0.018001440912485123,
                -0.05301424115896225,
                1.8822280720361624e-34,
                -0.016746941953897476,
                0.005175233352929354,
                -0.0019880528561770916,
                0.08074932545423508,
                0.03009878657758236,
                -0.033504121005535126,
                -0.006723608821630478,
                0.0023385051172226667,
                -0.022195717319846153,
                -0.01655951514840126,
                -0.02689870074391365
            ]
        },
        "Skills": {
            "name": [],
            "rep": []
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "On location",
        "hash__": "b'\\x1b#?\\xb4\\xc9\\x1a\\x05 3\\\\\\x13W\\x86P\\xde\\xa2'",
        "id__": "894d8a45-3264-490f-9896-c895d7e81135"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "BSc/BEng (Hons) Mechanical",
            "rep": [
                -0.05426754429936409,
                -0.07635519653558731,
                -0.0175965316593647,
                0.010851495899260044,
                0.04483082517981529,
                0.002921473002061248,
                -0.015505782328546047,
                0.033586081117391586,
                0.03291890025138855,
                -0.03081931546330452,
                0.040484704077243805,
                0.004770648665726185,
                0.007970860227942467,
                0.036568351089954376,
                0.03334808722138405,
                0.01544825080782175,
                0.001040811650454998,
                -0.012353473342955112,
                -0.05299552530050278,
                -0.038734227418899536,
                -0.06772071123123169,
                -0.007406813092529774,
                -0.03671715781092644,
                0.04663228988647461,
                0.00366565166041255,
                -0.019595036283135414,
                0.016998261213302612,
                0.013115650042891502,
                0.04120246320962906,
                0.01842033490538597,
                0.002117979573085904,
                0.020601747557520866,
                0.03364892676472664,
                0.0287579707801342,
                1.1704327107509016e-06,
                0.0030582977924495935,
                0.009135943837463856,
                0.03047594055533409,
                -0.00221108621917665,
                -0.041279662400484085,
                -0.012961024418473244,
                0.04112982749938965,
                0.008844038471579552,
                0.010319272987544537,
                -0.0017437383066862822,
                0.06063354015350342,
                0.0006757914670743048,
                -0.01703707128763199,
                -0.007027905900031328,
                0.012670147232711315,
                0.023696158081293106,
                -0.02142859436571598,
                -0.02969902753829956,
                -0.023587336763739586,
                -0.003258834592998028,
                -0.07587020099163055,
                0.008355384692549706,
                0.0008289645775221288,
                0.04357615485787392,
                0.07564650475978851,
                -0.05053859204053879,
                -0.01949366368353367,
                -0.009366658516228199,
                -0.013542057015001774,
                0.06602483987808228,
                0.07326419651508331,
                0.029701603576540947,
                0.0034598505590111017,
                -0.002266926923766732,
                -0.003542948979884386,
                0.024114646017551422,
                -0.06002578139305115,
                -0.0031143014784902334,
                -0.006793865002691746,
                0.004524970427155495,
                -0.01884077861905098,
                -0.02045457623898983,
                0.010702891275286674,
                -0.01378640253096819,
                0.018111227080225945,
                -0.015360898338258266,
                0.026117028668522835,
                -0.016432562842965126,
                -0.006482573226094246,
                0.0032786752562969923,
                -0.0008125043241307139,
                0.0029814587906003,
                0.001549946959130466,
                0.0024131096433848143,
                0.009881556034088135,
                0.041262127459049225,
                0.02005959115922451,
                -0.034777525812387466,
                0.004492089152336121,
                -0.018189020454883575,
                -0.03645027428865433,
                0.060039713978767395,
                0.033109404146671295,
                0.0034427684731781483,
                0.044832415878772736,
                -0.04692018777132034,
                -0.010712195187807083,
                -0.0011078000534325838,
                0.025301190093159676,
                -0.033904459327459335,
                -0.0012671630829572678,
                0.024540750309824944,
                0.025586117058992386,
                -0.024859029799699783,
                0.01343383640050888,
                -0.04399364814162254,
                -0.03174911439418793,
                -0.0030255108140408993,
                -0.044394221156835556,
                -0.019008785486221313,
                0.06376238167285919,
                -0.013161007314920425,
                -0.004127567168325186,
                -0.021964535117149353,
                -0.017468728125095367,
                0.05782945826649666,
                0.018078435212373734,
                -0.031583577394485474,
                0.017899982631206512,
                -0.002945074811577797,
                0.012944231741130352,
                -0.0027345847338438034,
                0.03964252024888992,
                0.04396456852555275,
                0.02568562887609005,
                -0.028965631499886513,
                -0.030701782554388046,
                -0.021478602662682533,
                -0.00931868888437748,
                -0.009759902022778988,
                0.004955308511853218,
                -0.05097857117652893,
                0.06531369686126709,
                -0.008497531525790691,
                -0.023496823385357857,
                -0.008837250992655754,
                -0.03470643237233162,
                0.030970459803938866,
                0.05919555202126503,
                -0.008921178057789803,
                0.016053952276706696,
                0.017327530309557915,
                -0.004172235727310181,
                0.01827126182615757,
                -0.02564968913793564,
                -0.004409697372466326,
                -0.020357180386781693,
                0.020322775468230247,
                -0.058296460658311844,
                -0.008820784278213978,
                0.004883121233433485,
                0.05168459936976433,
                0.0018228633562102914,
                -0.035288650542497635,
                0.06378169357776642,
                -0.04463966190814972,
                0.0005541834398172796,
                -0.011296423152089119,
                -0.011771906167268753,
                -0.025345556437969208,
                -0.02207767590880394,
                -0.004818474408239126,
                -0.036539893597364426,
                0.005848426837474108,
                0.018450196832418442,
                -0.025296082720160484,
                -0.05476084724068642,
                -0.011817622929811478,
                0.05824802815914154,
                -0.0038039060309529305,
                -0.0008847020217217505,
                0.07485941797494888,
                0.01065866556018591,
                -0.04130038619041443,
                -0.01231779158115387,
                0.04253954812884331,
                -0.06900548934936523,
                0.01996500976383686,
                0.0037478075828403234,
                -0.02160419151186943,
                0.008896398358047009,
                -0.021957190707325935,
                0.007310533430427313,
                -0.030499720945954323,
                -0.012949656695127487,
                0.007146812509745359,
                0.03647309169173241,
                -0.07767148315906525,
                -0.01305070985108614,
                0.04830028489232063,
                0.00213296664878726,
                0.024743881076574326,
                0.011324756778776646,
                0.013034560717642307,
                0.016328884288668633,
                0.003012672998011112,
                0.02797091193497181,
                -0.009438985027372837,
                -0.010520772077143192,
                0.022779611870646477,
                -0.06802675127983093,
                -0.039986081421375275,
                -0.047685135155916214,
                -0.05874866619706154,
                0.04935459420084953,
                -0.03934754431247711,
                -0.008545157499611378,
                0.06515786796808243,
                0.020253444090485573,
                -0.041828010231256485,
                -0.015643833205103874,
                -0.0025402638129889965,
                -0.032141707837581635,
                -0.06469669193029404,
                -0.05520305037498474,
                -0.041235603392124176,
                0.0003710893215611577,
                -0.005347031634300947,
                -0.017591599375009537,
                -0.030786089599132538,
                0.07509700208902359,
                0.05553346872329712,
                -0.01780664548277855,
                0.0007285815663635731,
                0.05348264053463936,
                -0.0035209066700190306,
                -0.005572053138166666,
                0.049028001725673676,
                -0.02149965427815914,
                0.02167205512523651,
                -0.036198053508996964,
                0.050610996782779694,
                -0.00036522504524327815,
                0.026695946231484413,
                -0.03722064942121506,
                0.004548870027065277,
                -0.02504967711865902,
                0.0056961881928145885,
                -0.03778592497110367,
                0.005156774539500475,
                0.01060745120048523,
                0.0028870911337435246,
                0.01813490502536297,
                0.04776790365576744,
                -0.015630550682544708,
                -0.04538238048553467,
                -0.02093350701034069,
                0.005339701659977436,
                0.05172377824783325,
                -0.025149671360850334,
                -0.01367249246686697,
                -0.049187205731868744,
                0.07528787851333618,
                -0.04736633971333504,
                -0.053919509053230286,
                0.05856351554393768,
                -0.05631661042571068,
                0.007232549600303173,
                0.011132410727441311,
                -0.007119579706341028,
                0.008258773013949394,
                -0.004078288096934557,
                -0.03696060553193092,
                -0.05541704222559929,
                0.03765806928277016,
                -0.023076828569173813,
                0.03858809173107147,
                0.03207944706082344,
                -0.005260128993541002,
                -0.016731245443224907,
                0.019281962886452675,
                0.03690268471837044,
                0.015026797540485859,
                -0.09001194685697556,
                0.024521103128790855,
                0.027248742058873177,
                0.05728120729327202,
                0.007081270217895508,
                -0.021158823743462563,
                -0.048823919147253036,
                0.0003111220139544457,
                0.05654926598072052,
                0.034122124314308167,
                -0.058829937130212784,
                0.021079326048493385,
                -0.02593335323035717,
                0.038510650396347046,
                0.0015098185976967216,
                -0.08490344882011414,
                -0.01143671479076147,
                0.03861825913190842,
                -0.011118114925920963,
                0.05574346333742142,
                0.03968796879053116,
                -0.0020316087175160646,
                -0.005972318351268768,
                0.03514876589179039,
                0.015768475830554962,
                0.028090106323361397,
                -0.002720366232097149,
                0.067009836435318,
                0.010248446837067604,
                0.030633972957730293,
                -0.017548654228448868,
                -0.08016759157180786,
                -0.03051655925810337,
                0.015796884894371033,
                0.02510582096874714,
                0.03240695968270302,
                0.06337380409240723,
                -0.05245988443493843,
                -0.05515328049659729,
                0.02708548493683338,
                -0.022192325443029404,
                0.01200263760983944,
                0.04518546164035797,
                0.028324265033006668,
                -0.01933564618229866,
                -0.026427676901221275,
                0.018538828939199448,
                -0.011873861774802208,
                -0.020565414801239967,
                0.01636071875691414,
                0.004408999811857939,
                -0.04332765191793442,
                -3.3904598240042105e-05,
                0.045633357018232346,
                -0.030590523034334183,
                -0.029208946973085403,
                0.08137644082307816,
                -0.04188176244497299,
                -0.011108102276921272,
                -0.07423705607652664,
                -0.0069260373711586,
                0.01771697960793972,
                -0.012195372022688389,
                -0.03024843893945217,
                -0.044337719678878784,
                -0.015998248010873795,
                0.03776013106107712,
                0.004901794716715813,
                0.018666714429855347,
                -0.015108936466276646,
                0.027547713369131088,
                0.023835057392716408,
                0.03760695829987526,
                0.010226861573755741,
                0.00023998993856366724,
                0.02538791485130787,
                -0.028060482814908028,
                0.03422805666923523,
                0.06888402253389359,
                0.03810488060116768,
                0.032103199511766434,
                0.019734730944037437,
                -0.06147639453411102,
                -0.016662880778312683,
                -0.038877278566360474,
                -0.03526235371828079,
                -0.031854771077632904,
                -0.026791103184223175,
                0.07093963027000427,
                -0.0051261503249406815,
                -0.013536378741264343,
                0.0027393100317567587,
                -0.03731628879904747,
                0.003880672622472048,
                -0.0494866780936718,
                -0.025206053629517555,
                -0.019299902021884918,
                0.006914038211107254,
                -0.012422035448253155,
                0.04960301145911217,
                -0.014261294156312943,
                0.006540839094668627,
                0.029844049364328384,
                -0.03151431307196617,
                0.028131859377026558,
                -0.0027527660131454468,
                0.0293387733399868,
                -0.0055959648452699184,
                0.017574554309248924,
                -0.0016403505578637123,
                -0.0005075616063550115,
                -0.02015933208167553,
                -0.0030402906704694033,
                -0.01698531210422516,
                -0.03835998848080635,
                0.03837576508522034,
                0.007627243176102638,
                -0.016987226903438568,
                -0.030349403619766235,
                0.0005420357338152826,
                -0.009117955341935158,
                0.003169445088133216,
                0.054176416248083115,
                -0.02646508254110813,
                -0.013454720377922058,
                0.007291358429938555,
                0.011294016614556313,
                -0.0025464126374572515,
                0.026903273537755013,
                -0.04168139398097992,
                -0.08264614641666412,
                -0.011633236892521381,
                0.0215600598603487,
                0.0063981711864471436,
                0.009444783441722393,
                -0.0270207691937685,
                -0.02831738069653511,
                -0.04329617694020271,
                -0.012902962975203991,
                -0.00015751663886476308,
                0.022330233827233315,
                -0.016545429825782776,
                0.015024338848888874,
                0.0028755709063261747,
                0.061344943940639496,
                -0.0663498044013977,
                0.03543214499950409,
                -0.04629511758685112,
                -0.026983944699168205,
                0.014992021024227142,
                -0.019523417577147484,
                -0.053514450788497925,
                0.00960819236934185,
                0.0036646733060479164,
                -0.06695198267698288,
                0.002374629955738783,
                0.043013740330934525,
                -0.026905208826065063,
                -0.019906288012862206,
                -0.0072470312006771564,
                -0.08006439357995987,
                0.039695072919130325,
                0.013933011330664158,
                0.007298097480088472,
                0.024383138865232468,
                -0.03935735672712326,
                0.10265189409255981,
                0.12559248507022858,
                -0.032368239015340805,
                -0.016068754717707634,
                -0.0012343855341896415,
                -0.05398251488804817,
                0.09518785774707794,
                -0.07391402870416641,
                -0.004183110781013966,
                -0.007120037451386452,
                -0.03332338482141495,
                -0.08336061239242554,
                -0.12278453260660172,
                0.0674196109175682,
                -0.007730142679065466,
                0.03452157601714134,
                0.06027261167764664,
                0.018704518675804138,
                -0.002095503732562065,
                -0.03559456393122673,
                -0.03675838187336922,
                -0.019227664917707443,
                0.02620060369372368,
                -0.015060936100780964,
                0.039703771471977234,
                -0.04229065775871277,
                -0.05295823886990547,
                -0.007420697715133429,
                -0.05218692496418953,
                -0.011616411618888378,
                -0.00018687828560359776,
                -0.00571766821667552,
                0.0149611821398139,
                0.09949080646038055,
                0.03412919119000435,
                0.021488256752490997,
                0.041517674922943115,
                0.004263108596205711,
                0.0046458677388727665,
                0.013719343580305576,
                -0.024441475048661232,
                0.020889248698949814,
                0.05784250795841217,
                0.003384664421901107,
                0.01617141254246235,
                -0.023734075948596,
                0.056014612317085266,
                -0.10234902799129486,
                0.0012887376360595226,
                -0.08374548703432083,
                0.09106273204088211,
                0.04053924232721329,
                -0.007051638327538967,
                0.035774096846580505,
                -0.046147871762514114,
                0.06546281278133392,
                -0.02849568799138069,
                0.009893452748656273,
                0.010736551135778427,
                -0.0629933625459671,
                0.02033744938671589,
                0.03100673295557499,
                -0.003945744596421719,
                0.028960810974240303,
                0.01191300805658102,
                0.008840757422149181,
                0.024527331814169884,
                0.055647656321525574,
                -0.013835028745234013,
                0.06213732436299324,
                -0.008897604420781136,
                -0.03312753885984421,
                0.032949283719062805,
                0.01178037654608488,
                0.04065236449241638,
                0.046362437307834625,
                -0.001176259364001453,
                -0.05053343251347542,
                0.026912815868854523,
                -0.020218100398778915,
                0.04474390670657158,
                -0.02540566399693489,
                0.003851624671369791,
                0.01810746267437935,
                -0.001224822597578168,
                0.011318267323076725,
                -0.03047236241400242,
                -0.037541549652814865,
                0.027451179921627045,
                0.01151448953896761,
                -0.009291503578424454,
                0.02502409927546978,
                0.07025393098592758,
                0.03267933055758476,
                0.022059950977563858,
                0.06457410752773285,
                -0.02326211705803871,
                0.0038392909336835146,
                0.021449243649840355,
                0.03537985309958458,
                0.013902927748858929,
                -0.05517065152525902,
                0.023075411096215248,
                -0.009424863383173943,
                0.029026109725236893,
                0.01108264084905386,
                0.028714114800095558,
                -0.017621582373976707,
                0.042926713824272156,
                0.056413065642118454,
                -0.011590130627155304,
                -4.275610173882552e-33,
                -0.029229022562503815,
                -0.03689253330230713,
                -0.013640484772622585,
                -0.027521446347236633,
                -0.03371387720108032,
                -0.04593541473150253,
                0.07466205209493637,
                -0.022513214498758316,
                -0.06165800988674164,
                -0.01822729967534542,
                -0.011248164810240269,
                0.02683946117758751,
                0.008811824023723602,
                0.01578722894191742,
                0.08158086985349655,
                0.024265790358185768,
                0.01270821038633585,
                0.07045727968215942,
                0.007983008399605751,
                -0.04177865386009216,
                -0.03383278474211693,
                0.005040689371526241,
                0.029927680268883705,
                0.04824278503656387,
                0.003222123719751835,
                -0.02693290449678898,
                -0.007036575116217136,
                -0.022581791505217552,
                -0.027409536764025688,
                -0.02120186761021614,
                0.025571882724761963,
                -0.0046392478980124,
                0.0314650721848011,
                0.0647764503955841,
                0.005858541000634432,
                0.0002193960826843977,
                -0.008207716979086399,
                -0.03252825513482094,
                -0.059809982776641846,
                0.04447490721940994,
                0.05518096312880516,
                -0.028822369873523712,
                0.00845518708229065,
                -0.002815680345520377,
                -0.09321695566177368,
                -0.10349228233098984,
                -0.00986007135361433,
                0.04489760845899582,
                0.05905035510659218,
                -0.011558224447071552,
                -0.04102126881480217,
                -0.051147446036338806,
                -0.0061773513443768024,
                0.03899984806776047,
                -0.032636482268571854,
                -0.01781756430864334,
                0.024346349760890007,
                -0.00877288542687893,
                -0.01804990880191326,
                0.02442191354930401,
                0.01757437363266945,
                0.06257939338684082,
                0.0616634339094162,
                -0.08498037606477737,
                -0.013296548277139664,
                -0.03257497400045395,
                0.0026209482457488775,
                -0.0330156572163105,
                0.03595108911395073,
                -0.06081555411219597,
                -0.06084449961781502,
                0.08772211521863937,
                -0.03099413402378559,
                -0.06861700862646103,
                -0.02578471042215824,
                -0.054134149104356766,
                -0.02642887271940708,
                -0.022010494023561478,
                0.02240523137152195,
                0.06521691381931305,
                -0.026907848194241524,
                0.03335203975439072,
                0.022098300978541374,
                -0.015498893335461617,
                0.004493423271924257,
                -0.001601974479854107,
                0.01704736053943634,
                0.049096353352069855,
                0.03175351396203041,
                -0.0026430203579366207,
                0.024570437148213387,
                -0.022513924166560173,
                -0.04348419979214668,
                -0.030466867610812187,
                0.10906064510345459,
                -0.015113933943212032,
                -0.0004296998667996377,
                -0.03607187047600746,
                0.017536453902721405,
                0.02061440981924534,
                -0.029179759323596954,
                0.032032180577516556,
                -0.007053236477077007,
                -0.0185119416564703,
                0.0027278857305645943,
                0.023098483681678772,
                -0.047141820192337036,
                -0.022123944014310837,
                0.027397509664297104,
                0.01660262420773506,
                -0.004501741379499435,
                -0.012455766089260578,
                -0.025919554755091667,
                0.006807899568229914,
                0.0356738306581974,
                -0.0015222997171804309,
                0.006632571574300528,
                -0.10112576186656952,
                -0.03207457810640335,
                0.030292775481939316,
                0.014945183880627155,
                -0.06432001292705536,
                -0.015705129131674767,
                -0.00994159746915102,
                0.01663777604699135,
                0.0011917691444978118,
                0.054186634719371796,
                -0.04074971005320549,
                0.019481321796774864,
                -0.01574227213859558,
                0.009176492691040039,
                0.02608577162027359,
                1.7296318333137606e-07,
                -0.010096290148794651,
                0.07489992678165436,
                0.04854460433125496,
                0.0682322084903717,
                -0.03432460501790047,
                -0.008589431643486023,
                -0.008069857954978943,
                0.030176952481269836,
                0.018564192578196526,
                0.1001214012503624,
                -0.04494043439626694,
                -0.022934861481189728,
                -0.02363746613264084,
                -0.1022299975156784,
                -0.037980448454618454,
                -0.014453141950070858,
                0.015410736203193665,
                0.03583840653300285,
                -0.04323680326342583,
                0.012517319992184639,
                -0.02212514355778694,
                0.030514579266309738,
                -0.016509566456079483,
                -0.0017946460284292698,
                -0.002579551422968507,
                0.011178216896951199,
                -0.010514391586184502,
                0.02073403261601925,
                0.06849110126495361,
                0.039130739867687225,
                0.05750076845288277,
                0.019319847226142883,
                0.012192011810839176,
                0.027232924476265907,
                0.0006228825659491122,
                -0.0042040362022817135,
                0.03449935093522072,
                -0.0016224551945924759,
                -0.0034972811117768288,
                0.05025208368897438,
                -0.05541344732046127,
                0.02936710976064205,
                0.03167884796857834,
                0.002837154548615217,
                0.006077051628381014,
                -0.020551348105072975,
                0.015808461233973503,
                -0.008455203846096992,
                -0.08103492110967636,
                -0.03411424905061722,
                -0.035601500421762466,
                0.05948493629693985,
                -0.0029697671998292208,
                -0.03685179352760315,
                0.01882021129131317,
                -0.00873928889632225,
                0.006845860276371241,
                0.017015235498547554,
                0.04414132982492447,
                -0.009701201692223549,
                -0.04669049382209778,
                -0.06171971186995506,
                0.013726253993809223,
                0.0312645323574543,
                0.08760770410299301,
                -0.09839225560426712,
                -0.03745916485786438,
                1.2811243420638004e-34,
                0.0011099508265033364,
                -0.0043359254486858845,
                0.012144594453275204,
                0.05000663548707962,
                0.005024253390729427,
                -0.02337668649852276,
                0.022510671988129616,
                0.010629937052726746,
                0.007061126176267862,
                -0.03746834769845009,
                -0.04614148288965225
            ]
        },
        "Experience": {
            "name": "I am a degree qualified Mechanical Engineer with 15+ years working industry experience in the Oil & Gas industry.  I have advanced my career through asset Mechanical Equipment Engineer roles, Senior Mechanical & Rotating Equipment roles and Mechanical Technical Authority driving and leading teams in successful execution of multidiscipline workscopes. \nI use a variety of skills, predominately focused on collaborative engineering techniques for leading and directing multidiscipline teams. \nI am a keen problem solver who manages delivery and time effectively, focusing on organisation, communication and project management. \nI act as nominated company contract sponsor for a variety of contracts with >£5mill annually capital spend. \nI sit and act on the company mentoring and student sponsorship committee for local University students, where I am a mentor to various students and Graduates.\n\n ",
            "rep": [
                0.03302823752164841,
                -0.0013567873975262046,
                0.00331094185821712,
                0.003790689632296562,
                0.0260362159460783,
                0.014084889553487301,
                -0.010398725047707558,
                0.02731904201209545,
                1.5564475688734092e-05,
                -0.003772037336602807,
                0.030263960361480713,
                -0.054375212639570236,
                -0.01510042604058981,
                0.0456850603222847,
                0.034774862229824066,
                -0.03240320459008217,
                0.014235910959541798,
                -0.004664011299610138,
                0.041940439492464066,
                -0.016693120822310448,
                0.003126493887975812,
                -0.010397164151072502,
                -0.022052422165870667,
                0.02633284591138363,
                -0.05849907547235489,
                0.004356087651103735,
                0.05145028978586197,
                0.025754090398550034,
                0.051451653242111206,
                0.059533003717660904,
                0.04941912740468979,
                0.020848559215664864,
                -0.03027847781777382,
                -0.042908214032649994,
                2.4448231670248788e-06,
                0.017724966630339622,
                -0.00797947309911251,
                -0.06436872482299805,
                0.001203017309308052,
                -0.008380523882806301,
                4.17104092775844e-06,
                0.03721928596496582,
                -0.006100794766098261,
                0.044966258108615875,
                -0.026604197919368744,
                0.05146852508187294,
                -0.007798928767442703,
                -0.028009114786982536,
                0.02412957139313221,
                0.0005778740742243826,
                0.0017942694248631597,
                0.009787725284695625,
                -0.0431065671145916,
                -0.052694883197546005,
                -0.029480881989002228,
                -0.027599358931183815,
                -0.023336419835686684,
                0.007061616983264685,
                0.02297142706811428,
                -0.004673111252486706,
                -0.018761426210403442,
                -0.011672969907522202,
                -0.0036782945971935987,
                -0.03931963071227074,
                0.13495518267154694,
                0.05342155694961548,
                0.04502014443278313,
                -0.04189513623714447,
                0.006689134053885937,
                -0.04213012382388115,
                0.05994498357176781,
                0.02065178193151951,
                -0.03994571790099144,
                -0.02615264244377613,
                0.020501840859651566,
                0.046760231256484985,
                0.04399442672729492,
                0.003629234852269292,
                -0.019342172890901566,
                0.01888478547334671,
                0.0038811268750578165,
                0.03436187282204628,
                -0.04251610487699509,
                -0.05624747276306152,
                0.013737852685153484,
                -0.03162907063961029,
                -0.04057303071022034,
                0.014343704096972942,
                0.02969680353999138,
                0.048221103847026825,
                0.05385850742459297,
                -0.033154308795928955,
                0.014628213830292225,
                0.02146473526954651,
                0.030938010662794113,
                -0.01763913780450821,
                0.050752341747283936,
                -0.002673251088708639,
                0.0356786772608757,
                0.03673282638192177,
                -0.04695277661085129,
                0.027664700523018837,
                0.0390593558549881,
                0.012629994191229343,
                -0.07355920970439911,
                -0.011270372197031975,
                -0.03437277674674988,
                0.004717621020972729,
                -0.05390011891722679,
                0.01791394129395485,
                -0.015290826559066772,
                0.006515376269817352,
                -0.040928371250629425,
                0.004058418329805136,
                -0.052581194788217545,
                0.0071157896891236305,
                -0.02323317900300026,
                -0.005984406918287277,
                0.013894162140786648,
                0.021169506013393402,
                0.06449326127767563,
                0.025888610631227493,
                0.046291690319776535,
                0.02091691456735134,
                -0.027574682608246803,
                -0.00014215651026461273,
                -0.015945469960570335,
                0.028242584317922592,
                -0.02307075448334217,
                -0.02280292473733425,
                0.0017017826903611422,
                0.00763927074149251,
                0.0037147027906030416,
                -0.009683300741016865,
                0.04090583324432373,
                0.05380745232105255,
                -0.027009082958102226,
                -0.008625837042927742,
                -0.014399259351193905,
                -0.023836560547351837,
                0.005500397179275751,
                0.0302976556122303,
                0.003198350314050913,
                0.011134457774460316,
                -0.02548414096236229,
                -0.03107050247490406,
                0.0007280518766492605,
                -0.0007376128924079239,
                0.04995477944612503,
                -0.06601192057132721,
                0.03841632604598999,
                -0.027424408122897148,
                0.0002700367767829448,
                -5.0044622184941545e-05,
                0.009273716248571873,
                0.03792437165975571,
                0.03503256291151047,
                -0.03712186962366104,
                0.021988647058606148,
                0.08532015979290009,
                0.0003186115063726902,
                0.0006286440184339881,
                -0.021033726632595062,
                0.045848604291677475,
                -0.06575196981430054,
                0.021013403311371803,
                0.10205747932195663,
                0.010879792273044586,
                0.01621711440384388,
                0.025194749236106873,
                0.020091988146305084,
                -0.02569327875971794,
                -0.012408875860273838,
                0.04284842684864998,
                0.01984800584614277,
                -0.06214537471532822,
                0.05475490912795067,
                -0.016885504126548767,
                -0.020245907828211784,
                -0.014688439667224884,
                -0.002167549915611744,
                -0.11921978741884232,
                0.007192473392933607,
                0.025413988158106804,
                0.0837014839053154,
                -0.000198199282749556,
                0.030647723004221916,
                0.07675020396709442,
                0.000997587339952588,
                0.002766996156424284,
                0.016343167051672935,
                0.04351335018873215,
                -0.06489914655685425,
                0.03890728950500488,
                0.003618850139901042,
                -0.011818294413387775,
                -0.058686256408691406,
                -0.03670922666788101,
                -0.012174869887530804,
                -0.059497520327568054,
                0.0160974133759737,
                0.04366462677717209,
                -0.017300652340054512,
                0.012409740127623081,
                0.01236654631793499,
                -0.02594866417348385,
                0.011320557445287704,
                -0.03919243812561035,
                -0.03249697759747505,
                0.04479244351387024,
                0.055334463715553284,
                -0.014542067423462868,
                0.05032256245613098,
                0.03826700896024704,
                -0.045662567019462585,
                -0.01281991321593523,
                -0.024333585053682327,
                0.018431544303894043,
                0.015221930108964443,
                0.032254550606012344,
                -0.0008907669689506292,
                0.027556389570236206,
                -0.030584298074245453,
                0.021723706275224686,
                0.027071906253695488,
                -0.014543330296874046,
                0.04324747994542122,
                -0.01439258549362421,
                0.006196254398673773,
                0.05759229138493538,
                -0.0051755765452980995,
                0.006622723303735256,
                0.024033144116401672,
                -0.04213089123368263,
                0.013261162675917149,
                0.00626051751896739,
                -0.03616555780172348,
                0.028512543067336082,
                0.0028952397406101227,
                -0.04692532494664192,
                -0.050855331122875214,
                -0.041517529636621475,
                0.017945796251296997,
                0.060917142778635025,
                0.005317562259733677,
                0.06871145218610764,
                0.011625482700765133,
                0.0138902822509408,
                -0.028010763227939606,
                -0.01691720075905323,
                -0.030215395614504814,
                0.0058251358568668365,
                -0.021726995706558228,
                0.05457257479429245,
                -0.027260469272732735,
                -0.04683930054306984,
                -0.07522138208150864,
                0.0009807789465412498,
                -0.10091710835695267,
                0.0311582051217556,
                -0.01569213531911373,
                0.019061002880334854,
                -0.03864756226539612,
                -0.009908437728881836,
                0.020020222291350365,
                -0.00924509484320879,
                0.0025735259987413883,
                -0.010598578490316868,
                -0.026915306225419044,
                0.030002491548657417,
                0.02128700166940689,
                0.0196719728410244,
                -0.011707508936524391,
                -0.019922295585274696,
                -0.029877131804823875,
                -0.030477171763777733,
                -0.05504084378480911,
                0.043902263045310974,
                -0.06081569567322731,
                0.023300113156437874,
                0.006178600713610649,
                0.00489345146343112,
                -0.01515006273984909,
                -0.0025068165268749,
                -0.010601731017231941,
                0.002004999667406082,
                0.06124363839626312,
                -0.062339600175619125,
                -0.034125011414289474,
                0.019453903660178185,
                0.01552276499569416,
                -0.05747469514608383,
                0.019975580275058746,
                -0.021685270592570305,
                -0.01781987026333809,
                0.01410615909844637,
                -0.025042824447155,
                -0.01158842071890831,
                -0.012825556099414825,
                0.031393155455589294,
                -0.013326003216207027,
                -0.004587518982589245,
                0.02897235006093979,
                -0.024367105215787888,
                0.0159943625330925,
                0.05863887444138527,
                -0.0011547631584107876,
                0.031062984839081764,
                -0.012771948240697384,
                -0.08954482525587082,
                -0.012136668898165226,
                0.039002928882837296,
                0.021872503682971,
                0.03912495821714401,
                0.0337659977376461,
                -0.024234529584646225,
                -0.03451896831393242,
                -0.021502409130334854,
                0.037265315651893616,
                0.024232110008597374,
                0.01999029703438282,
                0.0275986697524786,
                -0.02309839427471161,
                -0.03630411997437477,
                -0.010129502974450588,
                0.023986386135220528,
                -0.0686812698841095,
                -0.051436517387628555,
                0.054557111114263535,
                -0.07480666041374207,
                0.02861044369637966,
                0.029726384207606316,
                -0.05063950642943382,
                -0.03946220502257347,
                0.023736856877803802,
                -0.03534800931811333,
                0.010613767430186272,
                -0.06409534066915512,
                -0.001680288347415626,
                -0.0022072619758546352,
                -0.021705886349081993,
                0.0037194443866610527,
                -0.04855841398239136,
                0.0036994400434195995,
                0.014523415826261044,
                0.040250275284051895,
                0.011153779923915863,
                0.01704997569322586,
                -0.019835516810417175,
                0.008300285786390305,
                0.04428026080131531,
                0.029192281886935234,
                0.004182249773293734,
                0.02333315648138523,
                0.016451984643936157,
                0.0483531579375267,
                -0.06090438738465309,
                0.03170458599925041,
                0.062145452946424484,
                -0.03729609400033951,
                -0.0500771701335907,
                -0.0028545891400426626,
                0.012841285206377506,
                -0.002296625403687358,
                -0.03870039060711861,
                -0.009403768926858902,
                0.0266925897449255,
                -0.057382840663194656,
                0.038081761449575424,
                -0.02882421761751175,
                0.01005055382847786,
                -0.007099323906004429,
                -0.030260786414146423,
                -0.03892546519637108,
                0.008170620538294315,
                0.013042355887591839,
                0.03603010252118111,
                0.05121723189949989,
                0.06921125948429108,
                -0.015950532630085945,
                0.034841109067201614,
                -0.0823843777179718,
                0.06703826040029526,
                -0.043137792497873306,
                0.03341611474752426,
                -0.02129202149808407,
                0.02336769923567772,
                -0.037368666380643845,
                0.039280787110328674,
                0.028883378952741623,
                0.026564080268144608,
                0.049469493329524994,
                0.00981843937188387,
                -0.012030646204948425,
                -0.02484632469713688,
                -0.002583662746474147,
                -0.06703020632266998,
                -0.00846763513982296,
                0.02513693831861019,
                0.054456476122140884,
                0.04428904131054878,
                0.029468076303601265,
                -0.06731923669576645,
                0.013324297033250332,
                0.0016351202502846718,
                -0.003105774987488985,
                0.034345488995313644,
                -0.045155566185712814,
                -0.020972687751054764,
                0.022631080821156502,
                -0.048634227365255356,
                -0.02242054045200348,
                0.0375056117773056,
                0.042167920619249344,
                -0.056303683668375015,
                -0.10154251009225845,
                -0.024930942803621292,
                0.004105841275304556,
                0.02106061764061451,
                -0.12881559133529663,
                0.004191715270280838,
                0.026639657095074654,
                0.07725095748901367,
                -0.014728103764355183,
                0.0386880598962307,
                -0.03803473338484764,
                7.469442789442837e-05,
                -0.007533363066613674,
                0.016617609187960625,
                -0.038102421909570694,
                -0.019164275377988815,
                0.05976903811097145,
                0.06802432984113693,
                0.01663191430270672,
                0.037559542804956436,
                -0.00938386283814907,
                -0.05320991203188896,
                0.03293028846383095,
                0.09856175631284714,
                0.006944248452782631,
                -0.005627605132758617,
                0.09048694372177124,
                -0.004775879438966513,
                0.010793608613312244,
                0.03502337634563446,
                -0.08051732927560806,
                -0.03742537274956703,
                0.050501301884651184,
                0.012608504854142666,
                -0.03935983031988144,
                0.10111067444086075,
                -0.03501412272453308,
                -0.02385202795267105,
                -0.053858231753110886,
                -0.02725781686604023,
                -0.04443196952342987,
                -0.13817353546619415,
                0.010648822411894798,
                0.0036564271431416273,
                -0.023598946630954742,
                -0.010113796219229698,
                0.024762317538261414,
                0.028056751936674118,
                0.01466401293873787,
                -0.008944165892899036,
                -0.04087485373020172,
                0.00962310004979372,
                -0.01896657422184944,
                0.0732003003358841,
                -0.07657982409000397,
                0.03376558795571327,
                -0.03815765678882599,
                -0.04490594193339348,
                -0.01569313183426857,
                0.013549680821597576,
                0.012837842106819153,
                -0.0055204033851623535,
                0.04075242951512337,
                -0.07758175581693649,
                0.013001146726310253,
                0.0397818461060524,
                -0.025218147784471512,
                -0.015052569098770618,
                -0.04877853766083717,
                -0.004858859349042177,
                0.027479389682412148,
                0.06240873038768768,
                0.011028681881725788,
                -0.027687598019838333,
                -0.00964326597750187,
                0.0344504788517952,
                -0.03375105559825897,
                0.005495674442499876,
                -0.060456812381744385,
                -0.022786522284150124,
                0.05951904505491257,
                -0.017230406403541565,
                0.1212388202548027,
                -0.02141489088535309,
                -0.0016697136452421546,
                -0.03233109787106514,
                -0.0037793770898133516,
                0.02985421009361744,
                -0.02854085899889469,
                0.001571279251947999,
                -0.01681271754205227,
                -0.0411238968372345,
                -0.01718696765601635,
                -0.013967012986540794,
                -0.011054367758333683,
                -0.048031385987997055,
                0.001200889702886343,
                -0.011221513152122498,
                -0.09826292842626572,
                0.004674933385103941,
                0.04538898542523384,
                -0.020820321515202522,
                0.005377708002924919,
                0.005249118898063898,
                -0.04809892177581787,
                0.042124874889850616,
                0.014628634788095951,
                -0.04817824810743332,
                0.014679349958896637,
                -0.010124052874743938,
                0.01514091994613409,
                0.0061731417663395405,
                0.02997574768960476,
                -0.007686404976993799,
                -0.0005111646605655551,
                -0.0032862904481589794,
                -0.03357483074069023,
                -0.03910989686846733,
                0.06777888536453247,
                0.018065428361296654,
                -0.012234728783369064,
                0.0626503974199295,
                -0.0002403075632173568,
                -0.04100165516138077,
                0.0330410934984684,
                -0.0472467876970768,
                0.043105997145175934,
                -0.028779203072190285,
                -0.0024906089529395103,
                -0.026156963780522346,
                -0.027801619842648506,
                -0.023208225145936012,
                -0.009314333088696003,
                0.034668583422899246,
                -0.022441519424319267,
                0.020029807463288307,
                -0.06653955578804016,
                -0.01971013844013214,
                0.04508233070373535,
                -0.02402886003255844,
                -6.456904511042421e-33,
                0.02813415229320526,
                -0.032066453248262405,
                -0.004326933994889259,
                -0.027038684114813805,
                -0.027909422293305397,
                -0.00927062425762415,
                0.020793693140149117,
                -0.01740415394306183,
                -0.0052131833508610725,
                -0.04255381599068642,
                -0.0036037948448210955,
                0.025745388120412827,
                0.00473308190703392,
                -0.011729363352060318,
                0.027078259736299515,
                0.04343939572572708,
                0.04441598802804947,
                -0.010126923210918903,
                0.026766419410705566,
                -0.009327176958322525,
                -0.08280063420534134,
                -0.007408784702420235,
                -0.008379165083169937,
                -0.03617464005947113,
                0.06253115832805634,
                -0.0059058405458927155,
                -0.02955770678818226,
                0.048106104135513306,
                0.07514632493257523,
                -0.023388847708702087,
                -0.002125218976289034,
                -0.009903392754495144,
                -0.014861302450299263,
                -0.020142914727330208,
                0.018634753301739693,
                0.057173337787389755,
                -0.04443058744072914,
                0.01747085154056549,
                -0.009890247136354446,
                0.013293744064867496,
                -0.05048113688826561,
                -0.03895602002739906,
                0.03704539313912392,
                -0.022794021293520927,
                -0.010776454582810402,
                -0.02660960704088211,
                0.030690323561429977,
                0.03161725401878357,
                0.05953983962535858,
                -0.054774973541498184,
                -0.031031327322125435,
                -0.008122984319925308,
                -0.0503479540348053,
                0.05771446228027344,
                -0.026271721348166466,
                0.025764986872673035,
                0.002971597947180271,
                -0.0017520340625196695,
                0.016588328406214714,
                -0.03549114987254143,
                -0.06620710343122482,
                0.013639802113175392,
                0.0316741056740284,
                -0.026688238605856895,
                -0.03723549470305443,
                0.016756409779191017,
                -0.03341008350253105,
                0.029052995145320892,
                -0.0036186485085636377,
                -0.042316537350416183,
                -0.02794610895216465,
                0.019775208085775375,
                0.020923560485243797,
                -0.017536664381623268,
                -0.019071131944656372,
                0.006360241211950779,
                -0.02629712224006653,
                -0.00819820910692215,
                0.0828482136130333,
                0.048102688044309616,
                -0.0636160671710968,
                0.002536157378926873,
                0.019397348165512085,
                0.001727643539197743,
                -0.02310151979327202,
                -0.08082578331232071,
                -0.018571624532341957,
                -0.02647913619875908,
                -0.0006431961082853377,
                -0.012468937784433365,
                0.00932706706225872,
                -0.01659574918448925,
                -0.07321705669164658,
                0.012350535951554775,
                0.0774976834654808,
                -0.04184461757540703,
                0.03029533661901951,
                -0.013894676230847836,
                0.020376093685626984,
                0.06211582571268082,
                -0.05429033935070038,
                0.053631316870450974,
                -0.00999249704182148,
                -0.04501724988222122,
                0.025062065571546555,
                0.03204672038555145,
                -0.033696722239255905,
                -0.05022239685058594,
                -0.004972096998244524,
                0.013994432985782623,
                -0.004834748804569244,
                -0.03496594727039337,
                -0.01493566669523716,
                -0.011181431822478771,
                -0.019574476405978203,
                -0.0417400561273098,
                0.011368357576429844,
                -0.05419750139117241,
                -0.016783108934760094,
                0.04314152151346207,
                0.03919481113553047,
                -0.01295721996575594,
                -0.02882605604827404,
                0.009532910771667957,
                -0.021303676068782806,
                -0.00739317387342453,
                0.021256977692246437,
                -0.008076897822320461,
                0.05795508623123169,
                8.919326501199976e-05,
                0.01285491045564413,
                0.0014109676703810692,
                3.078512236243114e-07,
                -0.01830179989337921,
                0.03955202177166939,
                0.0030646519735455513,
                -0.05046188086271286,
                0.028341012075543404,
                -0.03179914131760597,
                -0.013314692303538322,
                0.015118976123631,
                -0.0354788564145565,
                0.027699675410985947,
                -0.0038133186753839254,
                -0.03324711322784424,
                -0.060553524643182755,
                -0.033734291791915894,
                0.0008666162029840052,
                -0.05654831603169441,
                0.008081401698291302,
                0.017050113528966904,
                -0.02836713194847107,
                0.01439561229199171,
                0.0632953941822052,
                0.005118534434586763,
                0.020533183589577675,
                0.011485924012959003,
                -0.005725082941353321,
                0.019761301577091217,
                -0.020848391577601433,
                -0.009020357392728329,
                0.04678519815206528,
                0.019883664324879646,
                -0.01763947121798992,
                -0.03934139013290405,
                0.005202030297368765,
                0.04192158579826355,
                0.010823528282344341,
                -0.024266233667731285,
                0.0071529787965118885,
                0.04141252487897873,
                0.027997909113764763,
                0.06866481155157089,
                -0.00961504876613617,
                0.030078094452619553,
                0.05451718717813492,
                0.005145491566509008,
                -0.020517585799098015,
                0.01959768868982792,
                -0.001964378170669079,
                0.03302457928657532,
                -0.06621115654706955,
                0.011458398774266243,
                -0.003045618999749422,
                0.11105776578187943,
                0.006403316743671894,
                -0.011063793674111366,
                0.03219931200146675,
                -0.0675443559885025,
                -0.034166280180215836,
                0.017831292003393173,
                0.026982417330145836,
                -0.034581296145915985,
                0.009658724069595337,
                -0.015252956189215183,
                0.03393411263823509,
                0.017977522686123848,
                0.035121310502290726,
                -0.018242793157696724,
                -0.02434067614376545,
                3.171655950073828e-34,
                -0.05808788165450096,
                0.009864169172942638,
                -0.017793236300349236,
                0.03630836680531502,
                0.04002172872424126,
                -0.035392876714468,
                -0.0693879947066307,
                0.014089489355683327,
                0.03365293890237808,
                -0.012306149117648602,
                -0.01552500855177641
            ]
        },
        "Freelance": "Staff",
        "Id": "d6eacfd3-67c0-4fa6-8675-0d7d7480d6a6",
        "Industry": {
            "name": "\tAlternative Energy",
            "rep": [
                -0.0007909733685664833,
                -0.025726739317178726,
                -0.01737358048558235,
                0.012125324457883835,
                0.009139682166278362,
                0.003327139187604189,
                -0.016655847430229187,
                0.04947609454393387,
                -0.04105972498655319,
                -0.00923708826303482,
                0.013031495735049248,
                0.08766910433769226,
                0.011091938242316246,
                0.047891851514577866,
                0.06713993102312088,
                -0.02204100973904133,
                0.06145849451422691,
                0.020100055262446404,
                -0.0033024782314896584,
                -0.002899695420637727,
                -0.03671940788626671,
                -0.03572896867990494,
                0.01975378952920437,
                0.018338600173592567,
                0.005644157528877258,
                0.010497485287487507,
                -0.030879618600010872,
                0.012448856607079506,
                0.045762792229652405,
                -0.028322188183665276,
                0.06186990812420845,
                -0.029894845560193062,
                -0.013474843464791775,
                0.02752089314162731,
                1.5327187838920509e-06,
                -0.030434975400567055,
                -0.06150487810373306,
                -0.03040459007024765,
                0.03688240796327591,
                -0.04937901720404625,
                0.048638761043548584,
                0.04535957798361778,
                -0.018418803811073303,
                0.03522108495235443,
                -0.009978423826396465,
                -0.0845140740275383,
                0.059080466628074646,
                -0.05535166710615158,
                -0.02814902924001217,
                -0.0274962130934,
                -0.026632040739059448,
                -0.0021843372378498316,
                -0.07713862508535385,
                -0.030457761138677597,
                -0.004680605139583349,
                0.07614794373512268,
                0.0012457565171644092,
                -0.0051797786727547646,
                -0.015866952016949654,
                0.04126123711466789,
                -0.012441921979188919,
                0.026533572003245354,
                -0.040967490524053574,
                0.021768169477581978,
                0.033536843955516815,
                0.020276322960853577,
                0.009145339950919151,
                0.0048447782173752785,
                -0.0031868149526417255,
                -0.008136062882840633,
                -0.002467913320288062,
                -0.008543959818780422,
                -0.006955547258257866,
                0.04337308183312416,
                -0.08522581309080124,
                0.07361935824155807,
                -0.00863977987319231,
                0.024444615468382835,
                0.0036695434246212244,
                0.02718518301844597,
                0.02951045148074627,
                0.002748778322711587,
                0.005768232513219118,
                0.0378694012761116,
                0.015824155882000923,
                0.05615543574094772,
                -0.040781643241643906,
                0.03513266518712044,
                0.04824380204081535,
                0.05683814361691475,
                0.059564221650362015,
                -0.007500175386667252,
                0.0037892861291766167,
                -0.02177763730287552,
                0.06618562340736389,
                -0.0015689665451645851,
                -0.009571174159646034,
                0.00568784587085247,
                0.024094412103295326,
                -0.03975224122405052,
                0.0806477889418602,
                0.020112870261073112,
                0.02009005658328533,
                0.010989564470946789,
                -0.028148021548986435,
                -0.09635908901691437,
                -0.035637639462947845,
                -0.03755336254835129,
                -0.04357287287712097,
                0.006462311837822199,
                0.005376879125833511,
                -0.014744679443538189,
                -0.015794051811099052,
                -0.015311998315155506,
                -0.04084743559360504,
                -0.03460606560111046,
                -0.02700860984623432,
                -0.0038760174065828323,
                -0.05398503318428993,
                -0.013064010068774223,
                0.043818991631269455,
                0.018653057515621185,
                0.04547329619526863,
                0.014746260829269886,
                0.026558849960565567,
                0.09963227808475494,
                -0.012006770819425583,
                0.014424564316868782,
                0.026651695370674133,
                -0.018767813220620155,
                0.000962162739597261,
                -0.011284745298326015,
                -0.03715777397155762,
                -0.0048041315749287605,
                -0.03061949834227562,
                0.027431009337306023,
                0.02995271049439907,
                -0.02513870783150196,
                -0.06155741959810257,
                -0.02600681222975254,
                0.03616555407643318,
                0.019300702959299088,
                -0.0653601661324501,
                0.010586653836071491,
                -0.08655087649822235,
                -0.011735621839761734,
                0.05687689408659935,
                0.052103664726018906,
                -0.0032123189885169268,
                -0.016532473266124725,
                0.028864415362477303,
                -0.03243368864059448,
                -0.03690234571695328,
                -0.011442022398114204,
                0.08938416093587875,
                0.03328953683376312,
                -0.05574570223689079,
                -0.044423025101423264,
                0.04659789055585861,
                -0.006481206975877285,
                -0.019511327147483826,
                0.011839820072054863,
                -0.00948019977658987,
                -0.02469130977988243,
                0.03225250542163849,
                -0.01210035104304552,
                0.03190207481384277,
                -0.01705395244061947,
                -0.10844852030277252,
                -0.037037335336208344,
                -0.017229201272130013,
                -0.029942329972982407,
                -0.026078132912516594,
                0.00870539527386427,
                0.023291153833270073,
                -0.005078070797026157,
                -0.029579918831586838,
                -0.030944472178816795,
                -0.025600727647542953,
                0.032959360629320145,
                -0.025046734139323235,
                -0.0851995050907135,
                0.04036809131503105,
                0.031728606671094894,
                -0.07315723598003387,
                0.017685789614915848,
                0.07153986394405365,
                0.022063154727220535,
                0.01573961041867733,
                -0.03266063332557678,
                -0.02213215082883835,
                -0.004507245030254126,
                0.033517543226480484,
                0.01799052208662033,
                -0.024690717458724976,
                0.02869323082268238,
                0.024576693773269653,
                -0.004838812630623579,
                -0.00291613326407969,
                0.042696207761764526,
                -0.020285071805119514,
                0.0002653979754541069,
                -0.018138254061341286,
                -0.024696607142686844,
                -0.04324907436966896,
                0.03224887698888779,
                -0.012656630016863346,
                -0.023088376969099045,
                -0.09202036261558533,
                -0.054104819893836975,
                0.018813591450452805,
                -0.030622899532318115,
                0.001525185420177877,
                0.01827242597937584,
                -0.0010562206152826548,
                0.0003987968375440687,
                0.02818143181502819,
                0.033228933811187744,
                -0.04763830080628395,
                -0.012247692793607712,
                -0.036917611956596375,
                0.034328632056713104,
                -0.014715087600052357,
                -0.01052259374409914,
                0.05072345584630966,
                0.04380178451538086,
                -0.028072651475667953,
                -0.022445064038038254,
                0.021642759442329407,
                -0.03050391376018524,
                0.030136503279209137,
                -0.0006569523247890174,
                0.03130123391747475,
                -0.0050712693482637405,
                -0.05825664475560188,
                0.02326926589012146,
                0.0731920450925827,
                0.03132709115743637,
                -0.01618519239127636,
                -0.04124032333493233,
                0.019467638805508614,
                0.016144126653671265,
                0.05298770219087601,
                0.01565176248550415,
                0.01749590039253235,
                0.006036288570612669,
                0.014842539094388485,
                -0.03937889263033867,
                0.0018244918901473284,
                0.042217276990413666,
                -0.03655637055635452,
                -0.003731438657268882,
                0.04703314229846001,
                0.03755750507116318,
                0.03276974335312843,
                -0.049237240105867386,
                0.028749367222189903,
                0.0201740600168705,
                -0.046615250408649445,
                0.03498946130275726,
                0.0700773224234581,
                -0.052720971405506134,
                -0.03694897145032883,
                -0.014339907094836235,
                -0.007590397726744413,
                -0.004630612209439278,
                0.0992838591337204,
                0.020549897104501724,
                0.001267355284653604,
                -0.0009711114107631147,
                -0.0016684267902746797,
                -0.012004418298602104,
                0.025287440046668053,
                0.01236728671938181,
                -0.01679103635251522,
                0.007433547172695398,
                0.03646189719438553,
                0.011850656010210514,
                -0.05385202169418335,
                -0.006022844463586807,
                0.011362887918949127,
                0.005939630791544914,
                -0.038069598376750946,
                0.01877736486494541,
                -0.002014181576669216,
                0.054694101214408875,
                -0.03583072870969772,
                0.03267902880907059,
                -0.023787416517734528,
                0.010879497975111008,
                0.08241482079029083,
                -0.010522141121327877,
                -0.0010534569155424833,
                0.011645114049315453,
                -0.05015100538730621,
                0.009654795750975609,
                0.011817341670393944,
                -0.05946854501962662,
                0.009498367086052895,
                -0.005447925068438053,
                -0.04172403737902641,
                0.005900400225073099,
                -0.06234845891594887,
                0.0012836094247177243,
                0.033991262316703796,
                -0.014262222684919834,
                0.025338828563690186,
                -0.018782805651426315,
                -0.003041040152311325,
                -0.02982323244214058,
                0.05154319480061531,
                0.0515720508992672,
                0.09406495839357376,
                0.03484128415584564,
                0.010362234897911549,
                -0.05397750809788704,
                -0.0051443264819681644,
                -0.02591679058969021,
                0.03851613029837608,
                0.04994462803006172,
                0.042997296899557114,
                0.010513734072446823,
                0.025217967107892036,
                -0.022497819736599922,
                -0.017094435170292854,
                -0.042308662086725235,
                -0.059621378779411316,
                0.02752181515097618,
                -0.0019803126342594624,
                -0.01955759897828102,
                0.015597399324178696,
                -0.015377135947346687,
                0.06748078018426895,
                0.04869074374437332,
                -0.031878747045993805,
                -0.00766772823408246,
                0.01062415074557066,
                -0.034339096397161484,
                0.021604793146252632,
                0.00902500655502081,
                0.00621853768825531,
                -0.051263950765132904,
                -0.04250584542751312,
                -0.016506239771842957,
                0.024325968697667122,
                0.004869920667260885,
                -0.0019632752519100904,
                0.01927419938147068,
                -0.03209108114242554,
                -0.002597480546683073,
                0.049713004380464554,
                0.07426851242780685,
                -0.028791919350624084,
                -0.019556909799575806,
                -0.008355504833161831,
                0.09888194501399994,
                -0.0633554458618164,
                -0.055470727384090424,
                -0.02675999142229557,
                0.058137692511081696,
                0.006846235599368811,
                0.03486160561442375,
                -0.014590742066502571,
                -0.05597173050045967,
                -0.0238205399364233,
                0.08238058537244797,
                0.012050328776240349,
                -0.005837442819029093,
                -0.013524564914405346,
                -0.029864029958844185,
                -0.03737438842654228,
                0.02099573239684105,
                -0.013201836496591568,
                -0.005447228904813528,
                -0.03279726207256317,
                0.07172916829586029,
                0.019097980111837387,
                -0.0022381125018000603,
                0.03625491261482239,
                -0.03349782153964043,
                0.03491275757551193,
                -0.037846796214580536,
                0.02066367119550705,
                0.04278666898608208,
                -0.0027069668285548687,
                0.015497805550694466,
                -0.014327505603432655,
                -0.04040466248989105,
                0.02842780202627182,
                0.00045397182111628354,
                -0.02228926680982113,
                0.047752827405929565,
                0.003381517482921481,
                0.05634398013353348,
                0.04087527468800545,
                -0.0495791956782341,
                -0.05590488389134407,
                0.007796153426170349,
                0.004307596478611231,
                0.03751648589968681,
                -0.017558880150318146,
                0.043535590171813965,
                -0.01121446955949068,
                -0.028166066855192184,
                -0.048543259501457214,
                -0.017827127128839493,
                0.06451349705457687,
                -0.016201959922909737,
                0.04229361563920975,
                -0.000288335228106007,
                0.0052739218808710575,
                -0.017695553600788116,
                0.012909185141324997,
                -0.08512270450592041,
                -0.030979923903942108,
                -0.013595640659332275,
                -0.012437455356121063,
                0.022567767649888992,
                0.002798686036840081,
                -0.0010511514265090227,
                -0.006791500840336084,
                0.04473786801099777,
                0.02829546481370926,
                -0.046409543603658676,
                0.030432522296905518,
                0.01988242194056511,
                -0.01312580332159996,
                0.0590410977602005,
                0.0005697417072951794,
                -0.018255867063999176,
                -0.0027125906199216843,
                -0.060627423226833344,
                -0.04026523604989052,
                0.008557894267141819,
                -0.0067693633027374744,
                -0.018332334235310555,
                0.005540274083614349,
                -0.02190149761736393,
                -0.04643254354596138,
                0.011648383922874928,
                -0.02215507999062538,
                -0.004741802345961332,
                0.019978046417236328,
                -0.010889855213463306,
                0.031172042712569237,
                0.05588027834892273,
                0.025288352742791176,
                0.0004333771939855069,
                -0.054449815303087234,
                0.006426052190363407,
                0.005452610552310944,
                0.026321735233068466,
                -0.08507347851991653,
                0.025681698694825172,
                0.025394465774297714,
                0.00535535579547286,
                -0.008574491366744041,
                -0.021147385239601135,
                -0.015384738333523273,
                -0.00458871852606535,
                0.019741332158446312,
                0.037238407880067825,
                -0.025784598663449287,
                0.0198135357350111,
                0.08462715148925781,
                -0.0718868151307106,
                -0.0284077487885952,
                -0.04857610538601875,
                -0.03148176521062851,
                0.027120402082800865,
                0.04104418307542801,
                0.0025134640745818615,
                0.015500906854867935,
                0.06043599173426628,
                0.014870154671370983,
                -0.08936440199613571,
                -0.0001662896975176409,
                -0.08140313625335693,
                0.017098193988204002,
                0.045084044337272644,
                -0.009331357665359974,
                -0.007847805507481098,
                -0.07848025858402252,
                0.033516187220811844,
                0.0040719457902014256,
                -0.019775208085775375,
                0.06303006410598755,
                0.02581133134663105,
                -0.012653789483010769,
                0.0059228022582829,
                -0.0019761479925364256,
                -0.013251892291009426,
                0.013592160306870937,
                0.11074280738830566,
                0.02942628227174282,
                0.058693449944257736,
                -0.03323657810688019,
                0.03637339919805527,
                0.007965507917106152,
                0.04967096820473671,
                -0.027385244145989418,
                0.02505423314869404,
                -0.004042661748826504,
                -0.027893612161278725,
                0.009325913153588772,
                0.004816672299057245,
                0.0036110524088144302,
                -0.007978472858667374,
                0.01887592300772667,
                -0.008095595054328442,
                0.002226774813607335,
                0.0369160920381546,
                -0.00037575679016299546,
                -0.044305961579084396,
                0.01588621363043785,
                0.08489889651536942,
                -0.033222492784261703,
                0.02083105593919754,
                0.03127877786755562,
                -0.014893065206706524,
                -0.006433725822716951,
                0.009578757919371128,
                0.013018893077969551,
                -0.05645005404949188,
                -0.021272145211696625,
                -0.021301403641700745,
                0.06019211933016777,
                0.009728839620947838,
                0.007295968011021614,
                0.04028401896357536,
                0.007551512215286493,
                -0.021103473380208015,
                -0.03951779007911682,
                0.009899908676743507,
                -0.059859100729227066,
                0.05347452312707901,
                -0.03559296578168869,
                0.018585367128252983,
                -0.004193476401269436,
                0.030276639387011528,
                -0.023918453603982925,
                0.015572136268019676,
                -0.03695965185761452,
                0.017742473632097244,
                0.02450558729469776,
                -0.013200183399021626,
                0.006457440555095673,
                -0.01911108009517193,
                0.04524755850434303,
                -0.041010912507772446,
                0.0337565541267395,
                0.019791752099990845,
                -0.030328238382935524,
                -0.03644800931215286,
                -0.017651481553912163,
                -6.95329961234735e-33,
                -0.015995629131793976,
                -0.02328186109662056,
                0.04560638591647148,
                -0.0754886046051979,
                -0.0671086460351944,
                -0.0018630572594702244,
                -0.0073596215806901455,
                -0.0181337408721447,
                -0.06915926933288574,
                -0.029102284461259842,
                -0.00042789458530023694,
                -0.03522785007953644,
                0.020881451666355133,
                0.0014909693272784352,
                -0.02815410867333412,
                0.032789964228868484,
                -0.0233820341527462,
                0.013958727940917015,
                -0.0021108188666403294,
                -0.06676199287176132,
                0.051191773265600204,
                -0.00258887792006135,
                0.015442698262631893,
                0.05115680769085884,
                0.05520319193601608,
                0.07365769147872925,
                -0.02806991897523403,
                -0.0690830796957016,
                -0.008980028331279755,
                -0.023217657580971718,
                0.0006978167803026736,
                0.004835990723222494,
                0.017030859366059303,
                -0.014842100441455841,
                -0.010061460547149181,
                0.02689126320183277,
                -0.018315522000193596,
                -0.0002331641298951581,
                -0.053529754281044006,
                0.0024262620136141777,
                -0.06932676583528519,
                -0.030122505500912666,
                0.00383744016289711,
                -0.012612700462341309,
                -0.02567557618021965,
                0.0037844276521354914,
                -0.03686950355768204,
                -0.061328787356615067,
                0.03233812749385834,
                0.05586278811097145,
                0.014265017583966255,
                -0.02789992466568947,
                -0.024057699367403984,
                0.07259214669466019,
                -0.0660380870103836,
                0.07859624922275543,
                -0.011519476771354675,
                0.02682245709002018,
                -0.026380937546491623,
                -0.0013971226289868355,
                0.027766142040491104,
                0.07388632744550705,
                0.008068984374403954,
                -0.02558034099638462,
                -0.062253065407276154,
                0.024475401267409325,
                -0.09272601455450058,
                -0.05731312930583954,
                -0.05016680806875229,
                0.027022501453757286,
                -0.011253010481595993,
                0.0312802754342556,
                -0.013853923417627811,
                -0.01710229367017746,
                -0.0062897419556975365,
                -0.04161041975021362,
                -0.00367233925499022,
                0.0177605040371418,
                0.053418856114149094,
                0.012610829435288906,
                0.029583735391497612,
                -0.019488217309117317,
                0.08249402791261673,
                0.012166731990873814,
                -0.030538862571120262,
                -0.003099689492955804,
                -0.01585347019135952,
                0.056179147213697433,
                0.02313290536403656,
                -0.0006819636910222471,
                -0.010239921510219574,
                0.01960138790309429,
                0.0030115710105746984,
                0.030045567080378532,
                0.06273864209651947,
                0.128684401512146,
                0.012344582006335258,
                -0.025530479848384857,
                0.008467377163469791,
                0.01994508132338524,
                -0.006651114672422409,
                0.0278590340167284,
                -0.04137552157044411,
                0.05941375717520714,
                -0.029734594747424126,
                -0.020231109112501144,
                -0.023553531616926193,
                -0.010162025690078735,
                -0.0073763527907431126,
                0.03137782961130142,
                -0.0010282698785886168,
                -0.03928855434060097,
                0.0076180677860975266,
                -0.04850849509239197,
                -0.001121191424317658,
                -0.003717720042914152,
                0.019185570999979973,
                0.03619137033820152,
                -0.06254422664642334,
                -0.004509597085416317,
                0.020114513114094734,
                -0.020706549286842346,
                -0.05644391104578972,
                0.020177926868200302,
                0.047790057957172394,
                0.012812781147658825,
                0.033121317625045776,
                -0.04342542216181755,
                -0.06320017576217651,
                -0.011319112032651901,
                -0.017369288951158524,
                0.028722353279590607,
                2.2221823314794165e-07,
                -0.045667458325624466,
                -0.01686744950711727,
                -0.02394670806825161,
                -0.011656606569886208,
                -0.02408522740006447,
                0.0110986502841115,
                -0.013695964589715004,
                0.03591800481081009,
                -0.02614792063832283,
                -0.02505837380886078,
                -0.010638237930834293,
                -0.027049869298934937,
                -7.93870713096112e-05,
                0.027206474915146828,
                0.016836397349834442,
                -0.08425641804933548,
                -0.02537626214325428,
                0.006373103708028793,
                0.03615199774503708,
                -0.047282952815294266,
                0.01160352397710085,
                0.01937495730817318,
                -0.00387465488165617,
                -0.02117622271180153,
                -0.0014347464311867952,
                0.018408162519335747,
                0.0031511939596384764,
                -0.025274738669395447,
                0.07010447978973389,
                -0.01646396704018116,
                0.008957336656749249,
                0.017612209543585777,
                0.03023195080459118,
                -0.07688508182764053,
                0.003775285091251135,
                -0.042245518416166306,
                -0.00355547945946455,
                0.0035091538447886705,
                0.003917147405445576,
                -0.026302313432097435,
                -0.04128793254494667,
                -0.013180792331695557,
                -0.0059650433249771595,
                0.023551419377326965,
                -0.046056244522333145,
                0.05471032112836838,
                0.0426289401948452,
                0.06945419311523438,
                -0.0385543592274189,
                -0.0029399129562079906,
                -0.03597792610526085,
                0.005850223824381828,
                -0.04148387908935547,
                -0.008924870751798153,
                0.0074158720672130585,
                -0.011136858724057674,
                -0.013318377546966076,
                0.06015491858124733,
                0.03168110549449921,
                0.026095343753695488,
                -0.03880600258708,
                0.0730171948671341,
                -0.0913766548037529,
                0.0590289905667305,
                0.01864974945783615,
                0.04199012368917465,
                -0.03203943744301796,
                1.0384307911614472e-34,
                -0.003202413907274604,
                -0.03375859931111336,
                -0.0795380249619484,
                0.029465366154909134,
                -0.021927863359451294,
                0.023187769576907158,
                -0.03536991402506828,
                -0.00963574554771185,
                -0.025755619630217552,
                -0.04690495878458023,
                -0.015684081241488457
            ]
        },
        "Location": "Aberdeen, UK",
        "Remuneration": 43.26923076923077,
        "Services Required": {
            "name": " Senior Mechanical Technical Authority and Rotating Equipment Eng",
            "rep": [
                0.004428948741406202,
                -0.08934688568115234,
                0.014053785242140293,
                0.03526325896382332,
                0.007038167677819729,
                0.02736336551606655,
                0.03575555607676506,
                0.04457167908549309,
                -0.06278425455093384,
                -0.008306178264319897,
                0.0324246808886528,
                0.030392274260520935,
                0.05591170862317085,
                0.004022936336696148,
                0.03730548545718193,
                -0.0541708804666996,
                -0.0005126601317897439,
                0.05152222514152527,
                -0.03239677473902702,
                -0.02222655899822712,
                -0.025293756276369095,
                0.02642078697681427,
                -0.025539696216583252,
                0.04648971930146217,
                -0.014979127794504166,
                0.02673977054655552,
                0.04474680498242378,
                -0.005429751239717007,
                0.06283034384250641,
                0.023541495203971863,
                0.08052080124616623,
                0.036448631435632706,
                0.06572914868593216,
                0.07952315360307693,
                1.6442016885775956e-06,
                -0.010925237089395523,
                0.038685884326696396,
                0.011445028707385063,
                -0.03615426644682884,
                0.0015599444741383195,
                -0.003497734433040023,
                0.0038807918317615986,
                0.0066810669377446175,
                -0.03256075829267502,
                -0.010613984428346157,
                -0.04316992685198784,
                0.008498994633555412,
                0.01389954425394535,
                -0.04901961237192154,
                0.006413996685296297,
                0.007805898319929838,
                0.03934294357895851,
                -0.03664211928844452,
                -0.036770064383745193,
                -0.04364074766635895,
                -0.10994641482830048,
                0.0047677853144705296,
                0.06205199286341667,
                -0.0020228945650160313,
                0.07186633348464966,
                -0.06555937975645065,
                0.001715411664918065,
                0.015856867656111717,
                -0.03888774290680885,
                0.048084575682878494,
                0.03974801301956177,
                0.0017796863103285432,
                -0.01899879053235054,
                0.023458486422896385,
                0.022249596193432808,
                0.02978181652724743,
                -0.0023382073268294334,
                0.004470766056329012,
                0.0536438524723053,
                -0.016270453110337257,
                0.03845185041427612,
                0.003723184345290065,
                0.050313565880060196,
                -0.07014238089323044,
                -0.008169722743332386,
                -0.03147208318114281,
                0.023590954020619392,
                -0.020070567727088928,
                -0.04303992912173271,
                -0.019420569762587547,
                -0.016042163595557213,
                -0.04123685508966446,
                0.031810566782951355,
                0.027886630967259407,
                0.013033075258135796,
                0.0701206624507904,
                -0.009985582903027534,
                0.03969907388091087,
                0.027939120307564735,
                -0.028170330449938774,
                -0.002697577001526952,
                0.02912558615207672,
                0.04569659009575844,
                0.02471013367176056,
                -0.034840989857912064,
                -0.07762479037046432,
                0.015526642091572285,
                0.031035875901579857,
                0.0299438014626503,
                -0.025311749428510666,
                0.02139592170715332,
                -0.02307608723640442,
                0.033795055001974106,
                -0.04746996983885765,
                0.0301959291100502,
                -0.049403294920921326,
                -0.027296675369143486,
                -0.007344283629208803,
                -0.04987943544983864,
                0.01536983996629715,
                0.014321803115308285,
                -0.01622283086180687,
                0.023833544924855232,
                0.03814764693379402,
                -0.014282386749982834,
                0.09018760174512863,
                0.06851951777935028,
                -0.021727606654167175,
                -0.02258569933474064,
                -0.0413561575114727,
                0.08008027076721191,
                0.002948119305074215,
                0.012775428593158722,
                0.054847415536642075,
                0.00469345273450017,
                -0.0038946617860347033,
                0.03924475982785225,
                0.010662838816642761,
                -0.04417566582560539,
                -0.020113559439778328,
                0.022734368219971657,
                -0.02318703569471836,
                0.028709784150123596,
                -0.03578757494688034,
                -0.014536947011947632,
                0.028716782107949257,
                -0.0014994671801105142,
                -0.025134025141596794,
                0.02694237045943737,
                0.040450192987918854,
                0.029420562088489532,
                0.049187008291482925,
                0.06777025759220123,
                0.031043576076626778,
                0.0026102166157215834,
                -0.04797107353806496,
                -0.029752502217888832,
                -0.033402781933546066,
                0.005715556908398867,
                0.021272961050271988,
                0.04012637957930565,
                0.05064668133854866,
                -0.04997602477669716,
                -0.05360819026827812,
                0.02275680936872959,
                -0.008223048411309719,
                -0.01334802433848381,
                -0.043536920100450516,
                0.017654919996857643,
                -0.052515555173158646,
                -0.04986172169446945,
                0.00952167995274067,
                0.006335234269499779,
                -0.054671160876750946,
                -0.00448598014190793,
                0.006789585575461388,
                0.02635923959314823,
                -0.015750540420413017,
                0.027402449399232864,
                -0.0008990083588287234,
                -0.10481655597686768,
                0.015472947619855404,
                0.007937413640320301,
                -0.004598179366439581,
                0.013739180751144886,
                0.03866281360387802,
                -0.07900618761777878,
                0.046938519924879074,
                -0.01090213842689991,
                -0.03995028883218765,
                -0.032057154923677444,
                -0.007050134241580963,
                -0.016868026927113533,
                -0.0724504217505455,
                -0.02717781439423561,
                -0.009494761005043983,
                0.00757237896323204,
                -0.04490609094500542,
                0.020553741604089737,
                0.06315424293279648,
                0.06133773922920227,
                -0.057329557836055756,
                -0.00638445932418108,
                0.026846304535865784,
                0.016066985204815865,
                -0.0030122362077236176,
                0.0018782552797347307,
                0.004774574190378189,
                -0.038224149495363235,
                0.020167188718914986,
                -0.019605305045843124,
                -0.05583127960562706,
                -0.0030531955417245626,
                -0.02045670710504055,
                0.046409185975790024,
                0.014413655735552311,
                0.033204320818185806,
                0.016632994636893272,
                0.0034856454003602266,
                -0.05189706012606621,
                0.021336514502763748,
                -0.04457054287195206,
                -0.008148587308824062,
                0.0013739989371970296,
                -0.0048605878837406635,
                -0.0034073360729962587,
                0.019511708989739418,
                -0.07014483958482742,
                -0.07584395259618759,
                0.0014956883387640119,
                0.0020173571538180113,
                0.009819095022976398,
                0.034181758761405945,
                -0.023979440331459045,
                0.036694616079330444,
                0.021782713010907173,
                -0.014100059866905212,
                0.01773080974817276,
                -0.003122050082311034,
                -0.011681433767080307,
                0.012638730928301811,
                0.013010144233703613,
                0.020079301670193672,
                0.004395921714603901,
                -0.04996363818645477,
                0.006405479274690151,
                -0.07462604343891144,
                0.019765980541706085,
                -0.016231687739491463,
                -0.05336455628275871,
                -0.006700130645185709,
                -0.035942401736974716,
                0.0002510757476557046,
                0.04316788166761398,
                0.03779122978448868,
                -0.021172448992729187,
                -0.02547132410109043,
                0.02010602504014969,
                0.014609046280384064,
                0.008872060105204582,
                0.036698538810014725,
                -0.07421553879976273,
                0.04730398580431938,
                -0.025841448456048965,
                -0.10091684013605118,
                0.03709390386939049,
                -0.06693483889102936,
                -0.021912379190325737,
                0.014138830825686455,
                0.021821150556206703,
                0.016874555498361588,
                -0.022895492613315582,
                0.000137775408802554,
                -0.0289528239518404,
                0.020693395286798477,
                -0.0008294002036564052,
                0.027578147128224373,
                0.05380994454026222,
                -0.01119280606508255,
                -0.009164416231215,
                -0.015218641608953476,
                0.012850594706833363,
                -0.022902337834239006,
                -0.06901141256093979,
                0.018992900848388672,
                0.02671942114830017,
                -0.048211295157670975,
                -0.0076494645327329636,
                0.00885798316448927,
                0.002867734059691429,
                -0.00118725816719234,
                0.06374255567789078,
                0.03244556114077568,
                -0.0969315767288208,
                0.0027196297887712717,
                -0.026156451553106308,
                -0.035528045147657394,
                0.023532750084996223,
                -0.03189132362604141,
                -0.026960166171193123,
                -0.0024322594981640577,
                -0.007347539067268372,
                0.06847108900547028,
                0.02501029148697853,
                0.05508575215935707,
                -0.02912754938006401,
                -0.02825143001973629,
                -0.029046302661299706,
                0.008880207315087318,
                -0.04789046198129654,
                0.015066529624164104,
                0.005515547934919596,
                0.025993546470999718,
                -0.01802593097090721,
                -0.02115141600370407,
                0.034700360149145126,
                0.04931320250034332,
                -0.03204744681715965,
                -0.03474221006035805,
                0.015754807740449905,
                -0.030699601396918297,
                -0.0024667258840054274,
                -0.002862169174477458,
                -0.018660452216863632,
                0.013809453696012497,
                0.002862191991880536,
                0.03937239944934845,
                -0.004001972731202841,
                0.01167065929621458,
                -0.026996638625860214,
                0.022045796737074852,
                -0.008455033414065838,
                -0.011467769742012024,
                -0.0678851380944252,
                -0.03756109997630119,
                0.006597617641091347,
                0.10749316215515137,
                -0.028328783810138702,
                0.01805124618113041,
                0.11283167451620102,
                -0.05203264206647873,
                -0.008278196677565575,
                -0.08457337319850922,
                0.03263929858803749,
                0.03879830241203308,
                -0.05619964003562927,
                -0.017822997644543648,
                -0.04237443953752518,
                0.02160687744617462,
                0.024195196107029915,
                -0.0019382560858502984,
                -0.0029500301461666822,
                -0.03660188242793083,
                -0.005927549209445715,
                -0.02194829098880291,
                0.060748811811208725,
                0.010818197391927242,
                -0.0184424240142107,
                0.0015260926447808743,
                -0.04495042562484741,
                -0.03074466437101364,
                0.0009278376237489283,
                0.005225778557360172,
                0.018555881455540657,
                0.02956320531666279,
                0.0008158846176229417,
                -0.025374559685587883,
                0.007517073303461075,
                -0.0541037917137146,
                -0.039924345910549164,
                -0.04102374240756035,
                0.03531293198466301,
                0.02245403453707695,
                -0.022566264495253563,
                -0.01899007149040699,
                -0.06127941980957985,
                0.031925544142723083,
                -0.03560803085565567,
                -0.008464018814265728,
                0.0014958154642954469,
                -0.017853502184152603,
                -0.0028368595521897078,
                0.02888285182416439,
                -0.01501489244401455,
                -0.00655036885291338,
                0.008458058349788189,
                0.026526305824518204,
                0.05531864985823631,
                -0.033993106335401535,
                0.02878667414188385,
                -0.011938321404159069,
                0.007138145621865988,
                0.023503093048930168,
                0.05250382050871849,
                0.005979413632303476,
                -0.021627917885780334,
                -0.021697431802749634,
                -0.1203056052327156,
                0.05856558680534363,
                -0.024591369554400444,
                -0.03315187618136406,
                -0.016300588846206665,
                0.036794211715459824,
                -0.01867995224893093,
                0.0343068391084671,
                0.052525341510772705,
                0.010291768237948418,
                -0.013871141709387302,
                0.016394279897212982,
                -0.0167046207934618,
                -0.05162758380174637,
                0.02020571567118168,
                -0.0031214056070894003,
                -0.024164466187357903,
                -0.06972073763608932,
                -0.03083830513060093,
                -0.03300006315112114,
                0.01619108021259308,
                -0.06460237503051758,
                -0.01942151039838791,
                -0.005077914334833622,
                -0.03313989192247391,
                -0.008706178516149521,
                -0.0077092573046684265,
                -0.026771657168865204,
                0.007513688877224922,
                0.005814922507852316,
                0.005947402212768793,
                -0.032455503940582275,
                0.06177004426717758,
                -0.03466400131583214,
                0.0038799764588475227,
                0.014848614111542702,
                -0.022704942151904106,
                0.011168309487402439,
                -0.019318144768476486,
                0.03652017563581467,
                -0.03248343616724014,
                0.04069782420992851,
                0.0397476889193058,
                -0.03627292811870575,
                -0.06435805559158325,
                0.031612273305654526,
                0.0264423880726099,
                -0.004936369601637125,
                0.029745934531092644,
                0.003853823058307171,
                0.020985575392842293,
                0.0015439606504514813,
                0.059292472898960114,
                0.06320632994174957,
                -0.03671174868941307,
                -0.014476906508207321,
                -0.07629600912332535,
                -0.0333368293941021,
                0.021099837496876717,
                -0.03394418954849243,
                0.03589210286736488,
                -0.009056100621819496,
                0.05329572409391403,
                0.02099941298365593,
                -0.13802267611026764,
                0.0759364515542984,
                -0.002349046291783452,
                0.03017740324139595,
                0.03640907630324364,
                0.040729813277721405,
                -0.03052041307091713,
                -0.027642197906970978,
                -0.006811048369854689,
                -0.007830354385077953,
                -0.006070195231586695,
                -0.03369167819619179,
                -0.02790343016386032,
                -0.04842623695731163,
                -0.0037948242388665676,
                0.0009567096130922437,
                -0.022328652441501617,
                0.010125720873475075,
                -0.041194915771484375,
                -0.04012294486165047,
                -0.0720924586057663,
                0.04511156305670738,
                -0.051321711391210556,
                0.02093714289367199,
                0.026632606983184814,
                -0.01779990829527378,
                -0.022381458431482315,
                -0.005244262982159853,
                0.017452238127589226,
                0.012036420404911041,
                0.07560309767723083,
                -0.019412769004702568,
                0.02616390585899353,
                0.052792616188526154,
                0.02756587788462639,
                -0.09863866865634918,
                -0.037740591913461685,
                0.022926844656467438,
                0.056279171258211136,
                0.048618316650390625,
                0.03365084528923035,
                0.05172296240925789,
                -0.05439305305480957,
                0.029116950929164886,
                -0.07029317319393158,
                0.0020105333533138037,
                0.04072573781013489,
                -0.022932276129722595,
                0.03886619955301285,
                0.020919257774949074,
                -0.003470327937975526,
                0.015154282562434673,
                -0.0016396056162193418,
                -0.00059795833658427,
                0.040322594344615936,
                0.01047529373317957,
                -0.0039427014999091625,
                0.013850296847522259,
                0.01753712072968483,
                -0.051695287227630615,
                0.026475466787815094,
                -0.0030910850036889315,
                0.038851164281368256,
                0.006123233586549759,
                0.014437740668654442,
                -0.046686071902513504,
                0.0013890557456761599,
                -0.00828429777175188,
                0.03152163699269295,
                -0.005060231313109398,
                -0.0008184737525880337,
                0.047396376729011536,
                -0.005349935032427311,
                0.037179119884967804,
                -0.019082721322774887,
                -0.02238353155553341,
                0.0056670960038900375,
                0.044095344841480255,
                -0.020630042999982834,
                0.03712232783436775,
                0.06926096230745316,
                -0.06631612777709961,
                -0.005063412245362997,
                0.005262530408799648,
                -0.002139429096132517,
                -0.02198820747435093,
                0.0069848704151809216,
                -0.0038704623002558947,
                0.0687316507101059,
                -0.032986849546432495,
                -0.0014299763133749366,
                -0.034872427582740784,
                0.021013222634792328,
                0.010166149586439133,
                0.04962744936347008,
                -0.0556819885969162,
                0.0258491188287735,
                0.06822449713945389,
                -0.011592439375817776,
                -4.940965323044906e-33,
                0.03198295086622238,
                -0.04255160689353943,
                -0.03859340772032738,
                0.017716454342007637,
                -0.01540829986333847,
                0.004139146767556667,
                0.004400108940899372,
                -0.015222285874187946,
                -0.07370205223560333,
                -0.029477791860699654,
                0.00217592716217041,
                0.007649647071957588,
                0.02324424311518669,
                0.028615431860089302,
                -0.0015204340452328324,
                0.011934476904571056,
                0.006255951710045338,
                0.007191621232777834,
                -0.006768082268536091,
                -0.014938879758119583,
                0.009047179482877254,
                -0.000648937129881233,
                0.08634376525878906,
                -0.05423552542924881,
                0.023485340178012848,
                -0.0009588272660039365,
                -0.04814740642905235,
                0.027449849992990494,
                -0.0175958052277565,
                -0.045764997601509094,
                -0.005979254376143217,
                0.009346029721200466,
                0.02810114063322544,
                0.047455083578825,
                -0.00018103305774275213,
                0.011012137867510319,
                -0.036741968244314194,
                -0.0357637032866478,
                -0.0297485813498497,
                0.0927378237247467,
                0.014542251825332642,
                0.010484442114830017,
                0.04086733236908913,
                -0.0024774381890892982,
                -0.042854271829128265,
                -0.03346748277544975,
                -0.029482178390026093,
                0.010653947480022907,
                0.039388131350278854,
                -0.005809057969599962,
                -0.015772046521306038,
                0.0034881136380136013,
                0.007908434607088566,
                -0.05681649595499039,
                -0.04165184125304222,
                0.05045332759618759,
                0.005421588663011789,
                -0.01155292708426714,
                0.011298476718366146,
                -0.03975469991564751,
                -0.037445250898599625,
                0.06432132422924042,
                0.04842432960867882,
                -0.07255299389362335,
                -0.026599818840622902,
                -0.010373060591518879,
                -0.04023747518658638,
                -0.002386675449088216,
                -0.01607559621334076,
                -0.03519005328416824,
                -0.05198195204138756,
                0.08804924786090851,
                -0.01891067437827587,
                -0.05017172545194626,
                0.025785906240344048,
                -0.06305576115846634,
                -0.011506023816764355,
                0.05006055533885956,
                0.009931040927767754,
                0.10157031565904617,
                -0.0012009702622890472,
                -0.04527194797992706,
                0.00928374845534563,
                -0.028432125225663185,
                0.009316660463809967,
                -0.011465211398899555,
                -0.029177187010645866,
                -0.0052140322513878345,
                0.04925185814499855,
                0.009947067126631737,
                0.029516911134123802,
                -0.011449739336967468,
                -0.06046207994222641,
                -0.004517131485044956,
                0.09271300584077835,
                -0.003838860196992755,
                0.04316943511366844,
                -0.062147028744220734,
                0.0017612758092582226,
                0.02391822263598442,
                -0.014634606428444386,
                0.033431898802518845,
                -0.021757876500487328,
                0.015608841553330421,
                -0.010831163264811039,
                -0.03766191750764847,
                -0.016040975227952003,
                0.01040154229849577,
                0.013626419007778168,
                0.060828737914562225,
                -0.008336063474416733,
                -0.04167535528540611,
                0.01735256239771843,
                -0.0032929214648902416,
                0.002747473306953907,
                0.03294612839818001,
                0.01887715421617031,
                0.0034875611308962107,
                0.0065682255662977695,
                -0.014470638707280159,
                -0.02533445693552494,
                -0.07426843047142029,
                -0.021741259843111038,
                0.03776206076145172,
                -0.02430194430053234,
                0.008871769532561302,
                0.09664613008499146,
                -0.02858000248670578,
                -0.027734404429793358,
                0.015583126805722713,
                -0.030112098902463913,
                -0.008170678280293941,
                2.2076173422647116e-07,
                0.014109315350651741,
                0.03934800252318382,
                0.047958098351955414,
                -0.005447616800665855,
                0.0287612434476614,
                -0.045005977153778076,
                -0.01773245818912983,
                0.007996276021003723,
                -0.016476592049002647,
                0.02823830395936966,
                0.015444083139300346,
                -0.05267999693751335,
                0.007154381833970547,
                -0.03813296929001808,
                0.003769742092117667,
                -0.056173861026763916,
                0.02308562770485878,
                0.04440068453550339,
                0.004359330516308546,
                0.010854130610823631,
                0.07761605083942413,
                0.01840994693338871,
                0.019636522978544235,
                0.025914929807186127,
                -0.04638563469052315,
                -0.027595341205596924,
                0.014586376026272774,
                -0.003113049315288663,
                0.049170371145009995,
                0.020901158452033997,
                0.07142514735460281,
                0.04058290645480156,
                0.027465136721730232,
                0.042588334530591965,
                0.002964984392747283,
                0.032754719257354736,
                0.045879192650318146,
                0.015374048613011837,
                0.024424893781542778,
                0.02571769990026951,
                -0.003984193317592144,
                0.015280327759683132,
                0.030382612720131874,
                0.001924959011375904,
                0.009046710096299648,
                0.015026947483420372,
                -0.015655867755413055,
                0.05498894676566124,
                -0.07469391822814941,
                0.014944692142307758,
                -0.005769664421677589,
                0.044429026544094086,
                0.03709091991186142,
                -0.013483487069606781,
                -0.0014730856055393815,
                -0.00878270622342825,
                -0.06809113174676895,
                -0.024440618231892586,
                -0.0029880597721785307,
                -0.043789397925138474,
                -0.06081252545118332,
                0.00762537494301796,
                0.01963549293577671,
                -0.0679551512002945,
                0.006555528845638037,
                0.0004949999274685979,
                -0.04832430183887482,
                2.3399571922573367e-34,
                -0.02947371080517769,
                -0.002975383773446083,
                0.004843030124902725,
                0.06303334981203079,
                0.014597669243812561,
                -0.014135300181806087,
                0.06967943161725998,
                -0.0010463056387379766,
                0.003210280789062381,
                -0.06084877625107765,
                -0.02963903173804283
            ]
        },
        "Skills": {
            "name": [
                "Time management, communication, project management"
            ],
            "rep": [
                0.050555337220430374,
                -0.015538032166659832,
                -0.039948686957359314,
                0.0143989073112607,
                0.00502781942486763,
                0.003497717436403036,
                0.02371709607541561,
                -0.010951973497867584,
                -0.09767717123031616,
                0.04937063157558441,
                0.02636171318590641,
                0.010084194131195545,
                0.02798670157790184,
                -0.0027921816799789667,
                -0.04253244027495384,
                -0.04812668263912201,
                0.026952888816595078,
                0.003268846543505788,
                -0.007168687414377928,
                0.019041288644075394,
                -0.01270135398954153,
                -0.01982649601995945,
                -0.011038987897336483,
                0.026510922238230705,
                -0.003562359604984522,
                -0.042091865092515945,
                -0.003852581139653921,
                -0.0098178805783391,
                0.025327010080218315,
                0.013825148344039917,
                0.02140755206346512,
                0.01131810899823904,
                -0.03827694058418274,
                -0.045128233730793,
                1.4831973658147035e-06,
                0.05362125486135483,
                0.005212641786783934,
                -0.020577847957611084,
                -0.041169896721839905,
                0.0666385069489479,
                0.03082626312971115,
                -0.050860390067100525,
                0.062373895198106766,
                0.049813080579042435,
                -0.036740828305482864,
                0.022160841152071953,
                0.030955689027905464,
                0.05731569603085518,
                0.00455307774245739,
                -0.0002479001705069095,
                -0.0012006510514765978,
                0.07990247011184692,
                -0.03225748613476753,
                -0.028716888278722763,
                0.00038395565934479237,
                -0.09166274219751358,
                -0.0038878314662724733,
                0.020605236291885376,
                -0.00575754139572382,
                0.032624855637550354,
                -0.0029454834293574095,
                -0.019721008837223053,
                -0.03263254091143608,
                -0.02774188108742237,
                0.05990573391318321,
                -0.0038628478068858385,
                -0.005750229116529226,
                -0.05064532160758972,
                -0.022218715399503708,
                -0.011403300799429417,
                0.05240844562649727,
                -0.004023646470159292,
                -0.009295682422816753,
                -0.0024762500543147326,
                -0.02465524710714817,
                -0.02516022138297558,
                0.003991908859461546,
                -0.01132259052246809,
                0.015982314944267273,
                -0.011163411661982536,
                -0.006966698914766312,
                0.04131969437003136,
                -0.029153432697057724,
                -0.01004990004003048,
                0.13014131784439087,
                -0.024632051587104797,
                -0.01030774973332882,
                -0.029682055115699768,
                -0.04478611797094345,
                0.034816887229681015,
                0.06068532541394234,
                -0.047308169305324554,
                0.008159986697137356,
                0.046897727996110916,
                -0.026646962389349937,
                -0.031818851828575134,
                -0.006241146009415388,
                -0.021543657407164574,
                0.005603987257927656,
                -0.008689193986356258,
                -0.04169401526451111,
                0.013744208961725235,
                0.046662211418151855,
                -0.003263382939621806,
                0.08245919644832611,
                0.041433945298194885,
                -0.02213859185576439,
                -0.05347852408885956,
                -0.033991750329732895,
                0.007702919654548168,
                -0.021494431421160698,
                -0.006735151633620262,
                -0.046136993914842606,
                0.0037715777289122343,
                -0.01645696721971035,
                0.017888331785798073,
                -0.03917229548096657,
                0.035258326679468155,
                0.01778959110379219,
                0.0053208498284220695,
                0.05905622988939285,
                0.004283958114683628,
                -0.07065264135599136,
                0.015732379630208015,
                -0.04143894463777542,
                0.026693066582083702,
                -0.049741633236408234,
                0.0206309761852026,
                -0.060756925493478775,
                0.02061568945646286,
                0.016640333458781242,
                -0.013315131887793541,
                -0.005548401270061731,
                0.003571094712242484,
                -0.009723223745822906,
                0.04948379099369049,
                -0.03638986498117447,
                0.05146337300539017,
                -0.06558599323034286,
                0.005343439523130655,
                -0.008042692206799984,
                -0.03435075655579567,
                -0.05706445872783661,
                -0.006986794527620077,
                -0.033215031027793884,
                -0.0006327808368951082,
                -0.08195017278194427,
                0.059275273233652115,
                0.007616750430315733,
                -0.03182244300842285,
                0.007154481019824743,
                -0.022984430193901062,
                0.018067702651023865,
                0.03360891714692116,
                0.08528275787830353,
                0.0716191753745079,
                0.052394360303878784,
                -0.04151344671845436,
                0.03029138222336769,
                0.0004250670608598739,
                -0.017507879063487053,
                0.02410762384533882,
                0.030855806544423103,
                0.031077364459633827,
                0.0020059598609805107,
                -0.010255038738250732,
                0.04428763687610626,
                -0.01409165095537901,
                -0.005792723502963781,
                -0.020575381815433502,
                -0.05523348227143288,
                0.059401124715805054,
                -0.004871411714702845,
                0.08421642333269119,
                0.04430157691240311,
                -0.07446246594190598,
                0.014588694088160992,
                -0.0024183951318264008,
                -0.050338033586740494,
                -0.006031849421560764,
                -0.0053793988190591335,
                -0.053335536271333694,
                0.033842217177152634,
                0.053452227264642715,
                0.10767916589975357,
                0.029872462153434753,
                -0.007683613337576389,
                0.03214174136519432,
                -0.07281588762998581,
                0.0021833351347595453,
                0.00044373280252330005,
                -0.01217335183173418,
                -0.04927153140306473,
                0.006145440507680178,
                -0.005826965905725956,
                -0.03530625253915787,
                -0.0235734935849905,
                0.024015456438064575,
                -0.05695929750800133,
                0.04830820485949516,
                -0.0053146821446716785,
                0.031527481973171234,
                0.012541437521576881,
                0.025399981066584587,
                -0.04504956677556038,
                -0.012141787447035313,
                -0.01235896535217762,
                -0.009218121878802776,
                0.027987530454993248,
                -0.00498748617246747,
                0.044753335416316986,
                -0.010058187879621983,
                -0.0053114877082407475,
                0.03131422773003578,
                0.05012262240052223,
                0.043272651731967926,
                -0.0008975145756267011,
                -0.007996913976967335,
                -0.016880903393030167,
                0.06062068045139313,
                -0.01781957969069481,
                0.04630427807569504,
                -0.016138354316353798,
                -0.0005393323954194784,
                0.046256259083747864,
                -0.0252420362085104,
                -0.028579024598002434,
                0.010816451162099838,
                0.022445688024163246,
                0.028119290247559547,
                -0.004772181622684002,
                0.025686470791697502,
                -0.0015476021217182279,
                -0.01604401133954525,
                0.00020438087813090533,
                0.025718923658132553,
                0.013516751118004322,
                0.007329327054321766,
                -0.014289112761616707,
                -0.01997370272874832,
                -0.03272073715925217,
                -0.04364754259586334,
                0.002603302476927638,
                -0.008770158514380455,
                0.03957468643784523,
                0.02461937628686428,
                0.047407422214746475,
                0.022615013644099236,
                0.03782655671238899,
                -0.01589418388903141,
                -0.04544469341635704,
                -0.008303266018629074,
                -0.012106799520552158,
                0.004371231887489557,
                0.0019372501410543919,
                0.029102878645062447,
                -0.03436785563826561,
                0.03602595254778862,
                -0.011210567317903042,
                0.06379171460866928,
                0.09006800502538681,
                -0.007979799062013626,
                -0.021188128739595413,
                -0.014560759998857975,
                0.02487247996032238,
                -0.05235132575035095,
                -0.01134183257818222,
                -0.05366706848144531,
                -0.07527140527963638,
                0.01745937578380108,
                0.02741427533328533,
                -0.01791050285100937,
                -0.005507327150553465,
                -0.008154569193720818,
                -0.029923273250460625,
                0.009283131919801235,
                -1.8059856301988475e-05,
                0.03655256703495979,
                -0.02574358507990837,
                0.04807761684060097,
                0.047452956438064575,
                -0.007638635113835335,
                -0.03485718369483948,
                -0.012190707959234715,
                -0.012625424191355705,
                -0.023320524021983147,
                0.022571634501218796,
                -0.11011433601379395,
                0.011933787725865841,
                0.007888836786150932,
                0.021902738139033318,
                -0.05513293668627739,
                0.03078739158809185,
                -0.02574504353106022,
                -0.04473103955388069,
                0.03455410897731781,
                0.07272919267416,
                -0.024265820160508156,
                -0.014132597483694553,
                0.03167659789323807,
                -0.009511077776551247,
                -0.04571600258350372,
                0.030484963208436966,
                -0.00527302036061883,
                0.005949151702225208,
                0.010329317301511765,
                0.011747900396585464,
                0.017609594389796257,
                -0.033881597220897675,
                0.01713566854596138,
                0.05020575225353241,
                0.04165783151984215,
                0.051562584936618805,
                0.06343283504247665,
                -0.03938697278499603,
                0.01174459233880043,
                0.027945008128881454,
                -0.0010102740488946438,
                0.010662331245839596,
                0.021495189517736435,
                -0.02085689827799797,
                0.02464187517762184,
                -0.046954285353422165,
                -0.001864238758571446,
                -0.0015312930336222053,
                0.017934884876012802,
                -0.036190908402204514,
                -0.027919871732592583,
                0.034247882664203644,
                -0.07279705256223679,
                -0.002917112549766898,
                0.0067389835603535175,
                -0.07878832519054413,
                -0.02337060123682022,
                -0.03554428741335869,
                0.07106351852416992,
                0.0013724552700296044,
                -0.07858891040086746,
                0.0009271815652027726,
                -0.016896400600671768,
                -0.03177940845489502,
                -0.027158930897712708,
                -0.006359282415360212,
                0.0036159884184598923,
                0.00017816820763982832,
                0.03045063652098179,
                -0.011442800052464008,
                -0.002647276036441326,
                -0.0590471476316452,
                -0.01405539084225893,
                -0.008250092156231403,
                -0.04175979271531105,
                -0.03593333438038826,
                -0.03263566642999649,
                0.020036490634083748,
                -0.010690034367144108,
                -0.02445487678050995,
                0.010561777278780937,
                -0.04602843150496483,
                -0.008969468995928764,
                -0.016540177166461945,
                -0.019662542268633842,
                0.04020553082227707,
                0.0049719009548425674,
                0.018198203295469284,
                -0.009896987117826939,
                0.006642460357397795,
                0.024440845474600792,
                -0.010620652697980404,
                0.0072983382269740105,
                -0.0027709135320037603,
                0.05491618812084198,
                -0.0218039620667696,
                0.010535268113017082,
                -0.0104013467207551,
                -0.003920330666005611,
                -0.005606179125607014,
                -0.10059364885091782,
                -0.09202437847852707,
                -0.015149939805269241,
                0.028253238648176193,
                0.02732948772609234,
                0.03712129220366478,
                -0.051585789769887924,
                -0.009969777427613735,
                -0.03313238546252251,
                -0.00612435070797801,
                -0.0043228259310126305,
                0.07126712054014206,
                -0.015306422486901283,
                0.0012631587451323867,
                -0.011733908206224442,
                -0.018924660980701447,
                0.03436560556292534,
                -0.033702440559864044,
                -0.017197785899043083,
                0.03187912330031395,
                -0.02884039655327797,
                0.05920155718922615,
                -0.002962198806926608,
                0.04448620229959488,
                -0.022927526384592056,
                0.03340412303805351,
                0.038322385400533676,
                -0.0092988396063447,
                0.001030524494126439,
                0.034288860857486725,
                0.014210156165063381,
                -0.004843548405915499,
                -0.0351254940032959,
                -0.0009065887425094843,
                0.03513886407017708,
                -0.021845880895853043,
                -0.018430570140480995,
                -0.022702261805534363,
                -0.06173408403992653,
                -0.014250250533223152,
                0.015536545775830746,
                -0.004452351946383715,
                -0.016526607796549797,
                0.03610558807849884,
                0.020436640828847885,
                0.0061292084865272045,
                -0.03435646370053291,
                0.020588288083672523,
                -0.02110852487385273,
                0.02522256039083004,
                0.07279140502214432,
                -0.0035707380156964064,
                -0.0476132370531559,
                -0.025490671396255493,
                -0.015839876607060432,
                0.07510997354984283,
                0.01564958691596985,
                0.02618073672056198,
                0.020791981369256973,
                -0.0089271767064929,
                0.0077096931636333466,
                0.036361921578645706,
                0.01200583390891552,
                -0.011939020827412605,
                0.031000621616840363,
                0.03814297169446945,
                0.02879667468369007,
                0.021114250645041466,
                -0.015351086854934692,
                0.04377373680472374,
                -0.0302536990493536,
                -0.07528086006641388,
                0.0640849620103836,
                0.0006190898711793125,
                -0.008941260166466236,
                -0.015960082411766052,
                -0.08884619176387787,
                0.0172922071069479,
                0.015852676704525948,
                -0.04982098191976547,
                -0.08431947231292725,
                0.019677583128213882,
                -0.03131474182009697,
                0.022007877007126808,
                0.029525676742196083,
                -0.07278024405241013,
                0.05309367924928665,
                -0.013069345615804195,
                -0.10264796763658524,
                0.010511670261621475,
                0.02756543830037117,
                0.006519910413771868,
                -0.016641899943351746,
                0.045516278594732285,
                -0.0006547864177264273,
                0.004259648732841015,
                -0.00998641550540924,
                0.027523480355739594,
                0.0189378522336483,
                0.01929268054664135,
                -0.03981321305036545,
                0.005484060849994421,
                -0.003198570106178522,
                0.022670907899737358,
                0.014014939777553082,
                0.013699386268854141,
                -0.05973353236913681,
                -0.03492425009608269,
                -0.002313855569809675,
                -0.007570208050310612,
                -0.0061333198100328445,
                -0.007065140642225742,
                -0.024975396692752838,
                0.01752416603267193,
                0.013719256967306137,
                0.023780928924679756,
                -0.03522389754652977,
                0.027783550322055817,
                -0.07193157076835632,
                0.02082190103828907,
                0.03884895518422127,
                0.005931399296969175,
                0.007396352011710405,
                -0.021098626777529716,
                0.056281086057424545,
                3.33806783601176e-05,
                -0.0452849380671978,
                0.01704411581158638,
                -0.037110649049282074,
                0.014810214750468731,
                0.03067721612751484,
                -0.019709186628460884,
                0.05604449287056923,
                -0.036249272525310516,
                -0.036283377557992935,
                0.04604469612240791,
                -0.05486170947551727,
                0.01402707677334547,
                -0.006188750267028809,
                -0.011074688285589218,
                0.023983972147107124,
                -0.027317609637975693,
                -0.011308406479656696,
                0.009785543195903301,
                -0.023675112053751945,
                0.048281051218509674,
                -0.028159907087683678,
                0.08044376224279404,
                0.016161149367690086,
                -0.0034804348833858967,
                -0.024785712361335754,
                0.020311487838625908,
                0.040317732840776443,
                -0.006146266590803862,
                -0.04564037173986435,
                -0.01767345331609249,
                -0.02284587360918522,
                0.008104344829916954,
                0.07879135012626648,
                -0.010742400772869587,
                -0.013157485984265804,
                0.015299160033464432,
                -0.0019474055152386427,
                -0.028829660266637802,
                0.026473762467503548,
                0.05161416530609131,
                -0.005388190504163504,
                -0.0028969489503651857,
                -0.012180016376078129,
                -0.007464871741831303,
                0.00570749631151557,
                0.05762874707579613,
                0.004020172171294689,
                -0.023183293640613556,
                0.009381094947457314,
                0.03517032042145729,
                0.019773466512560844,
                0.002496216678991914,
                -5.1091866461712374e-33,
                -0.04605648294091225,
                -0.08816595375537872,
                0.06755225360393524,
                0.026262246072292328,
                -0.03142887353897095,
                0.05746717005968094,
                0.0023842889349907637,
                0.06071566790342331,
                -0.0527067631483078,
                -0.050120625644922256,
                -0.006231827661395073,
                0.03748490288853645,
                0.024149101227521896,
                -0.02253962866961956,
                0.09800252318382263,
                0.031788427382707596,
                0.001580616575665772,
                -0.016336435452103615,
                0.004827417433261871,
                -0.05531981214880943,
                -0.02576747164130211,
                0.00414689676836133,
                0.054925188422203064,
                0.06984943151473999,
                -0.010890750214457512,
                -0.0016017052112147212,
                0.031690679490566254,
                0.0038400099147111177,
                0.06545440107584,
                0.02843506820499897,
                0.006278180051594973,
                -0.07527826726436615,
                0.012266168370842934,
                -0.024303356185555458,
                0.0063140918500721455,
                0.06356800347566605,
                -0.059289198368787766,
                -0.05938784033060074,
                -0.019290566444396973,
                -0.006870931945741177,
                0.005880711600184441,
                -0.022780219092965126,
                -0.0022480892948806286,
                0.024951927363872528,
                0.002967951586470008,
                -0.054238900542259216,
                -0.015269427560269833,
                0.03858575224876404,
                -0.04659850150346756,
                0.06130392849445343,
                -0.07568453997373581,
                0.009027070365846157,
                -0.0514565147459507,
                0.045614443719387054,
                0.01718948967754841,
                0.029661530628800392,
                -0.005856845527887344,
                -0.01315081212669611,
                -0.054646581411361694,
                0.0077655622735619545,
                -0.06667899340391159,
                0.0257559847086668,
                0.005183396395295858,
                -0.009262019768357277,
                -0.04339936375617981,
                -0.010649079456925392,
                -0.028323683887720108,
                0.03981572017073631,
                -0.02958063594996929,
                -0.06415913254022598,
                -0.018241675570607185,
                0.06291969865560532,
                -0.020413098856806755,
                -0.05167914181947708,
                -0.08887194097042084,
                -0.0599655918776989,
                -0.0027455936651676893,
                -0.04398929700255394,
                0.09891599416732788,
                0.011245963163673878,
                -0.008188838139176369,
                0.04716203734278679,
                0.0014742974890396,
                0.0025383958127349615,
                0.03570807725191116,
                0.0022591324523091316,
                -0.004632588941603899,
                0.055790975689888,
                -0.00021748988365288824,
                0.021431520581245422,
                0.027141980826854706,
                0.03493322804570198,
                0.029444992542266846,
                0.02499336749315262,
                0.06544894725084305,
                0.004604137036949396,
                0.017159225419163704,
                0.015616326592862606,
                -0.0011469455203041434,
                0.024345189332962036,
                -0.06561562418937683,
                0.0055451770313084126,
                -0.0883181169629097,
                0.02159224823117256,
                0.001566771068610251,
                -0.006299293600022793,
                -0.03269032761454582,
                -0.04170028865337372,
                0.03660266101360321,
                -0.022607270628213882,
                0.01513158529996872,
                -0.005236997734755278,
                0.0084172822535038,
                -0.043766770511865616,
                -0.03324516490101814,
                -0.022966524586081505,
                -0.011660581454634666,
                -0.01933160237967968,
                -0.003983760252594948,
                -0.05683998018503189,
                -0.017143819481134415,
                -0.0027698033954948187,
                0.024900425225496292,
                -0.06672805547714233,
                0.011405928991734982,
                -0.023262731730937958,
                -0.01183145958930254,
                0.05197301134467125,
                0.04265538230538368,
                0.0025320586282759905,
                -0.0033805002458393574,
                -0.022467417642474174,
                2.1043757669758634e-07,
                0.017204804345965385,
                0.05738939717411995,
                0.02867976389825344,
                0.07997246831655502,
                0.028872549533843994,
                -0.01216351892799139,
                -0.011946113780140877,
                0.009393170475959778,
                -0.03842339292168617,
                0.07785153388977051,
                0.08236836642026901,
                -0.02026904933154583,
                -1.1007148714270443e-05,
                -0.022118443623185158,
                0.06996537744998932,
                -0.13314439356327057,
                -0.059121593832969666,
                -0.05157905071973801,
                -0.008302465081214905,
                -0.023427288979291916,
                0.02017129212617874,
                -0.00033020289265550673,
                0.03499409928917885,
                -0.0012006079778075218,
                -0.05215511843562126,
                -0.0003207226109225303,
                0.0045255073346197605,
                0.013214916922152042,
                0.04003295302391052,
                0.0625685304403305,
                0.05996014177799225,
                -0.016544222831726074,
                -0.019348373636603355,
                0.05586731433868408,
                0.011615230701863766,
                -0.08338341116905212,
                0.04476384446024895,
                0.025217395275831223,
                0.016996033489704132,
                -0.006476874928921461,
                -0.011218167841434479,
                0.0072366041131317616,
                -0.051406607031822205,
                0.044601816684007645,
                -0.022017212584614754,
                -0.016392771154642105,
                -0.059560660272836685,
                0.02783120423555374,
                0.04500927776098251,
                0.013450266793370247,
                0.030691390857100487,
                0.03991661220788956,
                0.05886871740221977,
                -0.029818914830684662,
                0.0043608867563307285,
                -0.035211894661188126,
                -0.03724735602736473,
                0.011351396329700947,
                0.044600263237953186,
                0.07844120264053345,
                -0.04293679818511009,
                -0.04427241161465645,
                -0.0066335140727460384,
                -0.0018831496126949787,
                0.03287286311388016,
                0.03223317116498947,
                -0.03202620521187782,
                7.725176108076926e-35,
                0.0023665204644203186,
                -0.03471881523728371,
                -0.04353437200188637,
                0.03212891146540642,
                -0.017407003790140152,
                -0.00758193526417017,
                -0.04170772805809975,
                -0.024543410167098045,
                -0.005299512762576342,
                -0.0012321529211476445,
                -0.022574065253138542
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'8\\xff\\xfe2\\x1b\\x98\\xb4)L\\x7f\\xcb\\xcdv*\\xc9\\xa3'",
        "id__": "d6eacfd3-67c0-4fa6-8675-0d7d7480d6a6"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "",
            "rep": [
                -0.01250336691737175,
                0.061438772827386856,
                -0.006734526716172695,
                0.025237763300538063,
                0.014757898636162281,
                0.03317746892571449,
                -0.01709824614226818,
                -0.005254548974335194,
                -0.03517315536737442,
                0.02680721879005432,
                0.027776479721069336,
                0.0016695410013198853,
                -0.035905394703149796,
                0.08371193706989288,
                0.0020816551987081766,
                -0.0036474945954978466,
                -0.00808101985603571,
                0.007572959177196026,
                -0.04329586401581764,
                -0.026224762201309204,
                -0.021265223622322083,
                0.013262759894132614,
                -0.005541951861232519,
                -0.03117932379245758,
                0.005881241522729397,
                -0.0541393980383873,
                0.040238745510578156,
                -0.007921081967651844,
                0.017486119642853737,
                -0.041598841547966,
                0.018058065325021744,
                -0.00015737996727693826,
                -0.001450279843993485,
                -0.03499198332428932,
                3.953377017751336e-06,
                -0.0013549883151426911,
                0.006736484821885824,
                0.035783108323812485,
                -0.04817280173301697,
                0.04840705543756485,
                0.007813598960638046,
                0.03426700457930565,
                -0.017332350835204124,
                -0.03300396353006363,
                0.0231205802410841,
                0.0160819124430418,
                0.011691391468048096,
                -0.026407040655612946,
                -0.044467393308877945,
                0.028630157932639122,
                0.008412609808146954,
                -0.04301135614514351,
                -0.12538503110408783,
                -0.05047597363591194,
                0.05816846713423729,
                0.028868647292256355,
                -0.003383409231901169,
                -0.009581382386386395,
                0.016905196011066437,
                -0.00306876702234149,
                -0.007300361059606075,
                -0.0304871853441,
                -0.016616370528936386,
                0.04997849091887474,
                0.0009452405502088368,
                0.031931791454553604,
                0.03382967785000801,
                -0.03551234304904938,
                0.022719833999872208,
                -0.013534646481275558,
                -0.013018215075135231,
                -0.022502049803733826,
                -0.02794310636818409,
                0.12343361228704453,
                -0.03144539147615433,
                -0.015701577067375183,
                -0.033564116805791855,
                0.03753889352083206,
                0.029097864404320717,
                -0.0075263408944010735,
                -0.02213081531226635,
                -0.010136098600924015,
                0.037694983184337616,
                0.01747259683907032,
                -0.07048329710960388,
                0.041574276983737946,
                -0.03992348909378052,
                -1.9508279365254566e-05,
                -0.01863657496869564,
                0.0036532285157591105,
                0.004205846227705479,
                -0.06392322480678558,
                -0.02061384916305542,
                -0.009598635137081146,
                0.032927192747592926,
                0.0016774265095591545,
                -0.023945268243551254,
                -0.029483433812856674,
                0.019927263259887695,
                -0.006079182494431734,
                -0.05478913336992264,
                0.023929640650749207,
                -0.0070150429382920265,
                0.006951442454010248,
                -0.05057133734226227,
                0.006110988091677427,
                0.02058250829577446,
                -0.023523692041635513,
                0.02736492082476616,
                0.07505252212285995,
                -0.04204901307821274,
                0.03163759037852287,
                0.023439623415470123,
                0.021155521273612976,
                -0.003454080317169428,
                -0.015697283670306206,
                -0.01709374412894249,
                0.04316733777523041,
                0.022733589634299278,
                0.008051550947129726,
                0.09210117906332016,
                0.05982724204659462,
                -0.006791339255869389,
                -0.03160925954580307,
                0.027104921638965607,
                0.0061317854560911655,
                0.010315212421119213,
                -0.03920648992061615,
                -0.05529627576470375,
                0.0036144584883004427,
                -0.021970732137560844,
                0.023343071341514587,
                0.003332081949338317,
                -0.005414539482444525,
                0.0300702303647995,
                -0.011980688199400902,
                0.010207103565335274,
                -0.02246537245810032,
                0.039830293506383896,
                -0.0014929784229025245,
                -0.020150257274508476,
                0.027579449117183685,
                0.002707603620365262,
                -0.005354698281735182,
                -0.02411271072924137,
                0.010192600078880787,
                0.09005865454673767,
                -0.03506376966834068,
                0.0065099638886749744,
                0.003935432061553001,
                0.010230598039925098,
                0.05042262375354767,
                -0.01718307100236416,
                0.050957951694726944,
                0.042619846761226654,
                0.03845520690083504,
                -0.06702651083469391,
                -0.01923864334821701,
                0.002606004709377885,
                0.028123805299401283,
                0.053268007934093475,
                0.002303209388628602,
                -0.035049889236688614,
                -0.015614322386682034,
                -0.028400812298059464,
                -0.03784780204296112,
                0.03566346317529678,
                0.001885668491013348,
                -0.0038645542226731777,
                -0.06404998898506165,
                0.0010549839353188872,
                -0.00885031558573246,
                0.008581440895795822,
                0.012777621857821941,
                0.023975960910320282,
                0.02581288293004036,
                -0.10304789245128632,
                -0.02712412178516388,
                -0.005907086655497551,
                -0.0644407793879509,
                -0.03229215741157532,
                -0.06627113372087479,
                -0.008665882050991058,
                -0.006594209931790829,
                0.0484798364341259,
                0.01764615625143051,
                0.009447470307350159,
                0.015605838038027287,
                0.001336991903372109,
                0.0595303438603878,
                0.0556134432554245,
                -0.04280132055282593,
                0.030285101383924484,
                0.035216376185417175,
                -0.01916254125535488,
                -0.06644861400127411,
                0.005000254139304161,
                -0.03437502309679985,
                -0.01998600736260414,
                -0.019239002838730812,
                0.035958968102931976,
                -0.0642118975520134,
                -0.026285985484719276,
                -0.04305443912744522,
                -0.012780084274709225,
                0.0018684081733226776,
                0.021171214058995247,
                -0.05258452147245407,
                -0.023365097120404243,
                -0.015666605904698372,
                -0.06449753791093826,
                0.02580212615430355,
                0.022736266255378723,
                0.01733192428946495,
                -0.030923957005143166,
                -0.03914716839790344,
                0.043811142444610596,
                -0.013898184522986412,
                -0.049245189875364304,
                0.07740079611539841,
                0.026235133409500122,
                0.05054790899157524,
                0.011461357586085796,
                0.020236769691109657,
                0.06779534369707108,
                0.029156848788261414,
                -0.0023628000635653734,
                0.011973806656897068,
                0.04291291534900665,
                -0.030129238963127136,
                -0.02899443916976452,
                0.05344944819808006,
                0.05811570584774017,
                -0.04694709926843643,
                -0.05033904314041138,
                0.03681037202477455,
                0.06290840357542038,
                -0.010182899422943592,
                0.04336661845445633,
                -0.10038278996944427,
                -0.0408954918384552,
                0.05900667607784271,
                0.015469775535166264,
                0.018710315227508545,
                0.11131074279546738,
                -0.0007150724995881319,
                -0.004573279060423374,
                0.006786289159208536,
                -0.015809012576937675,
                0.009324673563241959,
                -0.027163337916135788,
                0.016815578565001488,
                -0.02251344732940197,
                0.029244842007756233,
                0.03637232631444931,
                0.07193945348262787,
                -0.012299098074436188,
                -0.009656124748289585,
                -0.0193575881421566,
                2.175197732867673e-05,
                0.012475758790969849,
                0.044392213225364685,
                -0.023234998807311058,
                -0.05611341819167137,
                0.024123501032590866,
                0.03691176697611809,
                -0.017150789499282837,
                -0.02631417289376259,
                -0.040450796484947205,
                -0.005599383730441332,
                0.051314495503902435,
                0.028135715052485466,
                0.034666381776332855,
                -0.010067619383335114,
                0.03729860112071037,
                -0.01594937965273857,
                -0.0234685055911541,
                -0.004213577136397362,
                -0.0482347272336483,
                -0.02087913453578949,
                -0.027857424691319466,
                0.08927794545888901,
                0.010641509667038918,
                -0.004202773794531822,
                0.027202021330595016,
                -0.005368836224079132,
                -0.019590962678194046,
                -0.02832445688545704,
                -0.055649660527706146,
                -0.02539868652820587,
                -0.02836921624839306,
                -0.026615502312779427,
                0.071470245718956,
                -0.051241930574178696,
                0.05324987322092056,
                0.003887547878548503,
                -0.03548124060034752,
                0.006939411628991365,
                0.022774415090680122,
                -0.009120232425630093,
                -0.051541924476623535,
                0.06765251606702805,
                -0.008341128006577492,
                -0.026966938748955727,
                -0.0160972960293293,
                -0.029533125460147858,
                -0.019868968054652214,
                0.00859929621219635,
                0.060439061373472214,
                -0.02311551570892334,
                0.00448680529370904,
                0.025432756170630455,
                0.008112108334898949,
                0.034015756100416183,
                -0.06135188415646553,
                -0.04630007594823837,
                -0.03193478286266327,
                -0.06767014414072037,
                0.00842998456209898,
                -0.006241044029593468,
                0.052949488162994385,
                -0.03604333475232124,
                -0.014186553657054901,
                0.00942138209939003,
                0.010135955177247524,
                0.08616578578948975,
                -0.057738397270441055,
                -0.02568899095058441,
                0.0010708898771554232,
                -0.014476461336016655,
                0.0036793805193156004,
                -0.0017947201849892735,
                -0.020894017070531845,
                -0.029539015144109726,
                0.019612127915024757,
                -0.03600529953837395,
                0.010122458450496197,
                0.007492783013731241,
                0.03685415908694267,
                0.016340676695108414,
                0.030536159873008728,
                -0.0466168075799942,
                -0.022464042529463768,
                -0.1031494066119194,
                -0.031066561117768288,
                -0.015180891379714012,
                -0.020436879247426987,
                0.001358519191853702,
                -0.063127301633358,
                0.011295993812382221,
                -0.036736778914928436,
                -0.030513843521475792,
                0.02684878744184971,
                0.008639818988740444,
                0.03397071734070778,
                0.005471780896186829,
                -0.0333276130259037,
                -0.01036415621638298,
                -0.04180051013827324,
                -0.040268488228321075,
                -0.054151054471731186,
                0.012869646772742271,
                0.06618642061948776,
                0.059644076973199844,
                -0.025526219978928566,
                0.0005774935707449913,
                0.029545694589614868,
                0.005512099247425795,
                -0.009801962412893772,
                -0.0659307986497879,
                -0.027822323143482208,
                0.020601699128746986,
                0.03394725173711777,
                0.07557912915945053,
                0.013692679814994335,
                0.011515580117702484,
                0.07788955420255661,
                0.011653480120003223,
                0.0665055513381958,
                -0.02443624846637249,
                -0.016621800139546394,
                -0.06951485574245453,
                -0.08817055821418762,
                -0.013690861873328686,
                0.011746413074433804,
                0.03695254027843475,
                0.015583361499011517,
                0.02966042421758175,
                -0.04149671643972397,
                0.021954426541924477,
                0.06375288218259811,
                0.016550829634070396,
                -0.016622504219412804,
                0.04531807452440262,
                0.016943901777267456,
                -0.05765059217810631,
                -0.010194423608481884,
                0.031080780550837517,
                0.005107048898935318,
                0.055147428065538406,
                0.028596973046660423,
                0.03635311499238014,
                -0.016057226806879044,
                0.012841739691793919,
                -0.01431287545710802,
                -0.037531837821006775,
                0.04440212994813919,
                0.0004841997579205781,
                0.029519900679588318,
                -0.06048693135380745,
                -0.053098440170288086,
                -0.02054908499121666,
                0.0295583363622427,
                0.015336153097450733,
                0.0033795111812651157,
                -0.01853654906153679,
                -0.056357868015766144,
                0.041306979954242706,
                0.03857751190662384,
                -0.04463161900639534,
                0.017224956303834915,
                -0.015480753034353256,
                0.09595205634832382,
                0.008647487498819828,
                -0.04171014949679375,
                -0.00011139697016915306,
                0.016000933945178986,
                0.05842599272727966,
                0.050819724798202515,
                -0.04454408958554268,
                -0.04044615477323532,
                0.039345063269138336,
                -0.031576961278915405,
                0.009894018992781639,
                -0.031009772792458534,
                -0.09126465022563934,
                0.03283938392996788,
                0.05852086469531059,
                -0.011089615523815155,
                -0.035230476409196854,
                -0.024147111922502518,
                -0.03197512403130531,
                0.03297773748636246,
                -0.03444661200046539,
                -0.028208263218402863,
                -0.007524717599153519,
                -0.018318410962820053,
                0.023612791672348976,
                0.025588609278202057,
                -0.051408395171165466,
                -0.0017879278166219592,
                -0.04118284955620766,
                0.016455762088298798,
                -0.07233195006847382,
                0.034944720566272736,
                -0.027807535603642464,
                0.04431791976094246,
                -0.02935546264052391,
                -0.005040796473622322,
                -0.04199744388461113,
                0.047499965876340866,
                -0.017872368916869164,
                0.04956725612282753,
                0.05600331723690033,
                0.018813494592905045,
                0.045953210443258286,
                0.04290954768657684,
                -0.05417734757065773,
                0.01149401068687439,
                -0.05444420874118805,
                -0.0327894352376461,
                -0.015426786616444588,
                -0.026156844571232796,
                0.021491097286343575,
                0.04103664308786392,
                -0.0008519602124579251,
                -0.05277550593018532,
                -0.04462529718875885,
                -0.061105795204639435,
                0.020359434187412262,
                0.03430336341261864,
                -0.01633555255830288,
                -0.016192030161619186,
                -0.034239284694194794,
                0.015339444391429424,
                0.027314649894833565,
                0.024318696931004524,
                -0.0031512542627751827,
                -0.004706866107881069,
                0.01429942436516285,
                -0.010600934736430645,
                -0.0014165134634822607,
                0.0033114859834313393,
                -0.026212375611066818,
                0.00033186323707923293,
                0.04606817662715912,
                -0.022524915635585785,
                -0.04993373155593872,
                0.0017128668259829283,
                -0.016752490773797035,
                0.01251006405800581,
                0.014586590230464935,
                0.07265426963567734,
                -0.0016377537976950407,
                0.01188112422823906,
                -0.057594817131757736,
                -0.027447110041975975,
                0.010108241811394691,
                -0.037495944648981094,
                0.014847256243228912,
                0.01594732515513897,
                -0.011699363589286804,
                0.023237043991684914,
                -0.08411238342523575,
                0.010783860459923744,
                0.05484925955533981,
                0.03383447602391243,
                -0.06735416501760483,
                0.024997036904096603,
                -0.006560595240443945,
                0.05912308022379875,
                0.00042185059282928705,
                0.0017148681217804551,
                0.057530973106622696,
                0.01949171908199787,
                0.06430315971374512,
                0.00849885679781437,
                -0.029345473274588585,
                -0.05449048802256584,
                0.0029640940483659506,
                -0.012674140743911266,
                -0.005498157348483801,
                0.08986900001764297,
                -0.005515096243470907,
                -0.0500158816576004,
                0.10746122896671295,
                -0.015796132385730743,
                -0.01628374494612217,
                -0.0069964975118637085,
                0.01894856058061123,
                -0.06770621985197067,
                -0.0190612580627203,
                0.00793536752462387,
                -0.009581093676388264,
                0.044688839465379715,
                -0.010958217084407806,
                -0.0020997945684939623,
                -0.05194688215851784,
                -0.027022937312722206,
                -0.04614638909697533,
                0.03908706083893776,
                0.059537459164857864,
                -0.027022406458854675,
                0.006158605217933655,
                -0.0768093541264534,
                -1.730158119350301e-32,
                0.03528092801570892,
                0.022059516981244087,
                -0.018010996282100677,
                -0.027064841240644455,
                -0.02653360366821289,
                -0.05503051355481148,
                0.04159459099173546,
                -0.02749549224972725,
                0.043656058609485626,
                -0.00019660104590002447,
                -0.034188613295555115,
                -0.061675556004047394,
                0.08859048783779144,
                0.030939098447561264,
                -0.0032329449895769358,
                -0.06554336100816727,
                0.03795536234974861,
                -0.005585714243352413,
                0.03275179862976074,
                -0.005317466799169779,
                -0.05043720453977585,
                -0.024948665872216225,
                -0.01248104777187109,
                -0.010867049917578697,
                -0.019292419776320457,
                0.017033228650689125,
                -0.05047468841075897,
                -0.058236826211214066,
                0.03986600413918495,
                -0.005010735709220171,
                -0.0396004356443882,
                0.032279692590236664,
                0.008718718774616718,
                0.04184601828455925,
                0.024127088487148285,
                0.0017391352448612452,
                0.043299317359924316,
                -0.011499935761094093,
                0.0025340374559164047,
                -0.028097141534090042,
                -0.08533523231744766,
                0.022075172513723373,
                0.015037428587675095,
                -0.09686551243066788,
                -0.005431173834949732,
                0.01971687562763691,
                0.02391023561358452,
                0.005778660532087088,
                0.03475715592503548,
                -0.0042331102304160595,
                -0.028772220015525818,
                0.015448767691850662,
                -0.01742612011730671,
                0.040681518614292145,
                -0.0020328962709754705,
                0.018227776512503624,
                0.004681279417127371,
                0.023332741111516953,
                0.03626178577542305,
                0.008099662140011787,
                0.018003972247242928,
                0.013520090840756893,
                0.05929096043109894,
                0.06149018183350563,
                -0.0018482997547835112,
                0.036634307354688644,
                -0.016218360513448715,
                0.055421456694602966,
                0.02500648982822895,
                -0.013119767419993877,
                -0.031167376786470413,
                0.0499003604054451,
                -0.002872346667572856,
                -0.02454444393515587,
                -0.01710682548582554,
                -0.05873878300189972,
                -0.024875743314623833,
                0.026413239538669586,
                0.06883824616670609,
                -0.017891239374876022,
                -0.036234691739082336,
                0.04582807049155235,
                0.04272537678480148,
                0.0017674289410933852,
                0.0018298695795238018,
                -0.06317424029111862,
                0.002034740522503853,
                -0.02380634844303131,
                -0.0019296574173495173,
                0.04175703227519989,
                -0.052683863788843155,
                0.04525110125541687,
                -0.060833532363176346,
                -0.02860703505575657,
                -0.022842764854431152,
                -0.07093805819749832,
                0.00788680836558342,
                -0.04167364910244942,
                0.002529716119170189,
                -0.01452034804970026,
                -0.0038260684814304113,
                -0.03441690281033516,
                0.0025128161069005728,
                0.043621644377708435,
                -0.014509053900837898,
                0.013221640139818192,
                0.011325109750032425,
                0.0009567454108037055,
                -0.015492920763790607,
                -0.003075995249673724,
                -0.02272666059434414,
                0.004478803835809231,
                0.03148900717496872,
                -0.023501772433519363,
                0.028348876163363457,
                0.012995311059057713,
                0.03040008619427681,
                -0.06870255619287491,
                0.0010267093311995268,
                0.0056553250178694725,
                0.09587330371141434,
                -0.0020150355994701385,
                0.0075897653587162495,
                0.039577189832925797,
                -0.03742733597755432,
                0.01763203553855419,
                0.0028627458959817886,
                -0.026773445308208466,
                0.011027363128960133,
                -0.07695167511701584,
                0.022407954558730125,
                -0.03295961022377014,
                4.5349463562160963e-07,
                -0.04426289722323418,
                -0.048142578452825546,
                0.018156467005610466,
                -0.0038795487489551306,
                0.028599794954061508,
                0.014348787255585194,
                -0.005038860719650984,
                0.037203967571258545,
                -0.0015346520813181996,
                -0.03904343396425247,
                0.0263198371976614,
                -0.06350988149642944,
                0.02455391176044941,
                -0.007087626960128546,
                -0.013716638088226318,
                0.03182315081357956,
                -0.02466454729437828,
                -0.032650403678417206,
                -0.0007668084581382573,
                -0.034429874271154404,
                0.09304381906986237,
                0.022653747349977493,
                0.03231470286846161,
                0.04472662881016731,
                -0.007484799716621637,
                -0.05654448643326759,
                0.014933639205992222,
                -0.03520038723945618,
                -0.023135541006922722,
                -0.010842367075383663,
                -0.07612742483615875,
                0.028194786980748177,
                -0.04507141187787056,
                -0.03395090252161026,
                -0.014637324027717113,
                0.020044617354869843,
                0.03170362859964371,
                0.00014150857168715447,
                0.01661676913499832,
                -0.02081580087542534,
                -0.07335412502288818,
                -0.06850661337375641,
                -0.024285418912768364,
                -0.018199952319264412,
                0.024370674043893814,
                -0.029325706884264946,
                0.0034502651542425156,
                0.004042342305183411,
                -0.02834298275411129,
                0.013160069473087788,
                -0.02183876931667328,
                0.00541174691170454,
                -0.005932594183832407,
                0.02486509643495083,
                -0.016246624290943146,
                -0.04698701947927475,
                0.009881804697215557,
                -0.004528544377535582,
                -0.01922021433711052,
                -0.016330735757946968,
                -0.023822886869311333,
                0.029560789465904236,
                -0.031946662813425064,
                0.02090991474688053,
                -0.003000350669026375,
                -0.044975150376558304,
                -0.017595702782273293,
                -1.2820022894070708e-34,
                0.05678420141339302,
                0.018224170431494713,
                -0.018970364704728127,
                0.004657310899347067,
                -0.03676501661539078,
                -0.022326216101646423,
                0.017543325200676918,
                -0.00906522199511528,
                -0.0019386187195777893,
                -0.05036444589495659,
                -0.019049443304538727
            ]
        },
        "Experience": {
            "name": "Experienced Mechanical Engineer with more than a decade of experience in design engineering, detailed designing, development and research. Successful in implementing new products and optimizing existing. Seeking to bring fresh ideas and effective advice to your department.",
            "rep": [
                0.020862998440861702,
                0.025656109675765038,
                -0.02210094779729843,
                -0.009459245018661022,
                0.031333424150943756,
                -0.03375603258609772,
                0.008672152645885944,
                0.00958285853266716,
                -0.054254062473773956,
                -0.05727693811058998,
                0.009986336342990398,
                -0.027532050386071205,
                0.008324660360813141,
                0.012271864339709282,
                0.02538907527923584,
                -0.0032564320135861635,
                -0.011678432114422321,
                0.0362127311527729,
                -0.04640937224030495,
                -0.018274949863553047,
                0.007446524687111378,
                0.017336824908852577,
                -0.05729090794920921,
                0.004126634914427996,
                -0.02988644689321518,
                0.01929699443280697,
                -0.025843700394034386,
                0.044049009680747986,
                0.041699573397636414,
                0.01255268044769764,
                0.047460585832595825,
                0.02403600886464119,
                -0.014488274231553078,
                -0.01943237893283367,
                1.9218366560380673e-06,
                0.027034228667616844,
                0.004242835100740194,
                -0.03229198232293129,
                0.021849099546670914,
                -0.03389061242341995,
                0.0014735412551090121,
                -0.0367572195827961,
                -0.037923455238342285,
                0.022215839475393295,
                0.011012536473572254,
                0.007072617765516043,
                0.003098946763202548,
                0.011057278141379356,
                -0.022652968764305115,
                0.010929558426141739,
                0.0017042814288288355,
                -0.005183536559343338,
                0.014837251976132393,
                -0.03991926461458206,
                -0.03796863928437233,
                -0.014090515673160553,
                0.007622131146490574,
                -0.025799676775932312,
                0.07175972312688828,
                0.0007786978967487812,
                0.0070063588209450245,
                -0.01125841960310936,
                0.002674311865121126,
                -0.0372336283326149,
                0.11729893088340759,
                0.05046636238694191,
                0.048644937574863434,
                0.030717777088284492,
                0.01015365682542324,
                0.024497224017977715,
                0.09566116333007812,
                -0.012884177267551422,
                -0.026949593797326088,
                -0.0034211750607937574,
                0.020983418449759483,
                0.03327927738428116,
                0.02703825756907463,
                -0.039044104516506195,
                -0.017032930627465248,
                0.004612149205058813,
                -0.05009288713335991,
                0.014877913519740105,
                -0.039393890649080276,
                -0.05490528419613838,
                0.04289277642965317,
                -0.0007472725119441748,
                -0.0336008258163929,
                0.011186095885932446,
                0.003854893147945404,
                -0.0035147308371961117,
                0.07168041169643402,
                0.027753103524446487,
                0.018399963155388832,
                -0.0005835045012645423,
                -0.004684471525251865,
                -0.014841213822364807,
                0.04698192700743675,
                -0.07937388867139816,
                0.02633654698729515,
                -0.00979501474648714,
                -0.02053399197757244,
                0.06322453171014786,
                0.07252303510904312,
                0.027405980974435806,
                -0.05816958099603653,
                -0.020791510120034218,
                -0.019291142001748085,
                -0.02683115191757679,
                -0.050010960549116135,
                0.0066480860114097595,
                -0.04357029125094414,
                -0.0011761540081351995,
                -0.035061392933130264,
                -0.04110003635287285,
                0.010521773248910904,
                0.009515920653939247,
                -0.017181236296892166,
                -0.0017123157158493996,
                0.01781533844769001,
                -0.06128598004579544,
                0.04522224888205528,
                0.03812864050269127,
                -0.005701251327991486,
                0.023944353684782982,
                -0.06131468713283539,
                0.01957531087100506,
                0.022081885486841202,
                0.03994302079081535,
                0.029886379837989807,
                -0.012584932148456573,
                -0.012937519699335098,
                0.020925967022776604,
                -0.01985577866435051,
                -0.016077110543847084,
                0.0033228308893740177,
                0.021830186247825623,
                -0.07526538521051407,
                0.020937643945217133,
                -0.03701837733387947,
                -0.0056103006936609745,
                0.012251150794327259,
                0.057661909610033035,
                -0.011503905057907104,
                0.03560933098196983,
                0.01596476137638092,
                -0.07535424083471298,
                0.006129579618573189,
                0.01168147474527359,
                0.040083147585392,
                -0.047710731625556946,
                0.02416614629328251,
                -0.032798103988170624,
                -0.01114113163203001,
                0.030840732157230377,
                0.039506085216999054,
                0.007475404534488916,
                0.03308999538421631,
                0.019690223038196564,
                -0.0723901018500328,
                0.08531828969717026,
                0.005596044939011335,
                0.015509409829974174,
                -0.019711636006832123,
                0.028199780732393265,
                -0.06696277111768723,
                0.018677622079849243,
                0.059718113392591476,
                0.014944732189178467,
                -0.024438651278614998,
                0.06000195071101189,
                0.004103001672774553,
                -0.021074457094073296,
                -0.002548319986090064,
                0.046209946274757385,
                -0.0012180613121017814,
                -0.07338495552539825,
                0.03798428177833557,
                -0.007982593961060047,
                0.04308817535638809,
                0.005747907795011997,
                -0.029970359057188034,
                -0.19642339646816254,
                0.020635532215237617,
                0.02058780938386917,
                0.030284332111477852,
                0.00997631810605526,
                0.009805073961615562,
                0.028995081782341003,
                -0.020621808245778084,
                0.023182163015007973,
                0.015351241454482079,
                0.01716889813542366,
                -0.05205007269978523,
                0.06171766296029091,
                0.020849445834755898,
                0.015445808880031109,
                -0.03702098876237869,
                -0.02709473855793476,
                -0.017417138442397118,
                -0.0065874382853507996,
                -0.046486690640449524,
                0.0354367271065712,
                -0.041097864508628845,
                0.029897406697273254,
                0.006300497800111771,
                -0.012817866168916225,
                -0.04024196043610573,
                -0.04140368849039078,
                -0.010950146242976189,
                0.04197824373841286,
                0.11764244735240936,
                0.004835875704884529,
                0.010448575951159,
                0.039433784782886505,
                -0.03544298931956291,
                -0.014502329751849174,
                -0.016134297475218773,
                0.009838183410465717,
                0.03377075493335724,
                0.03900843486189842,
                -0.004851912148296833,
                -0.019846588373184204,
                -0.007743444759398699,
                0.012643307447433472,
                0.020877908915281296,
                0.0017235255800187588,
                0.06889690458774567,
                -0.005100590642541647,
                -0.011026372201740742,
                0.04526783898472786,
                -0.007316244300454855,
                0.029210658743977547,
                0.010059629566967487,
                -0.058829374611377716,
                -0.03175884485244751,
                0.014110995456576347,
                0.008691382594406605,
                0.05677646026015282,
                0.005936726927757263,
                -0.08834465593099594,
                -0.03742999956011772,
                -0.02667832002043724,
                0.014860858209431171,
                0.0465669222176075,
                -0.022745555266737938,
                0.009814424440264702,
                -0.010296058841049671,
                -0.004781560506671667,
                0.016720084473490715,
                0.032031819224357605,
                -0.03342084214091301,
                -0.012253448367118835,
                0.013400525785982609,
                0.04972051829099655,
                -0.0352330319583416,
                0.03279228135943413,
                -0.03326889127492905,
                0.07078590244054794,
                -0.05112314969301224,
                -0.0375588983297348,
                0.016011398285627365,
                -0.005053333938121796,
                -0.032475776970386505,
                0.010473888367414474,
                0.015511119738221169,
                -0.01482668612152338,
                -0.00870282668620348,
                -0.0379488468170166,
                -0.043764252215623856,
                0.04832306504249573,
                0.018049005419015884,
                0.029733596369624138,
                0.00938402209430933,
                -0.014646106399595737,
                0.011162237264215946,
                -0.00204667029902339,
                0.03294352814555168,
                -0.0020913472399115562,
                -0.05861756578087807,
                0.013634390197694302,
                0.034996017813682556,
                -0.0510423481464386,
                -0.0007578136865049601,
                0.013779968954622746,
                -0.019763367250561714,
                -0.023089390248060226,
                0.06817267835140228,
                -0.005979511421173811,
                -0.05232050269842148,
                0.04600847512483597,
                -0.007258521392941475,
                -0.024680716916918755,
                0.04845947027206421,
                -0.0581040121614933,
                -0.030363932251930237,
                0.002848035655915737,
                0.009798787534236908,
                0.006761990953236818,
                -0.018930837512016296,
                0.07063630223274231,
                -0.011138266883790493,
                0.015395808033645153,
                0.029002316296100616,
                -0.021612852811813354,
                -0.005031581502407789,
                0.0762493908405304,
                0.04493488371372223,
                0.03718438372015953,
                -0.01490011066198349,
                -0.09269142150878906,
                0.012101330794394016,
                0.022392984479665756,
                0.02795799821615219,
                0.029558755457401276,
                0.08543845266103745,
                -0.03691962733864784,
                -0.031178858131170273,
                -0.015377676114439964,
                0.04510483890771866,
                0.05606956407427788,
                -0.011620852164924145,
                0.04442976415157318,
                -0.01324611809104681,
                0.01769680716097355,
                0.03568587079644203,
                0.013537747785449028,
                -0.044867873191833496,
                -0.03157132491469383,
                -0.0031644890550523996,
                -0.0787229984998703,
                -0.003896387293934822,
                0.03391432762145996,
                -0.018658846616744995,
                -0.010016504675149918,
                0.06014382839202881,
                -0.0627080425620079,
                0.02347027324140072,
                -0.08563461899757385,
                -0.04631149768829346,
                0.009133224375545979,
                -0.0051175919361412525,
                -0.045339107513427734,
                -0.07394452393054962,
                0.04890270531177521,
                0.018274690955877304,
                0.004075966775417328,
                0.015387353487312794,
                0.02487611584365368,
                -0.07285094261169434,
                0.02892206981778145,
                0.027918817475438118,
                0.044207047671079636,
                -0.008824003860354424,
                -0.008021158166229725,
                -0.009130881167948246,
                0.008834920823574066,
                -0.021030137315392494,
                0.0782635509967804,
                -0.02305961772799492,
                0.029543453827500343,
                -0.009354092180728912,
                0.029309023171663284,
                0.015529625117778778,
                -0.0035782738123089075,
                -0.06819144636392593,
                -0.0024957330897450447,
                0.03283901885151863,
                -0.009550274349749088,
                -0.0012581899063661695,
                -0.044302359223365784,
                -0.06236319988965988,
                -0.010063392110168934,
                -0.0136050283908844,
                -0.01650477759540081,
                -0.0020436120685189962,
                0.01890607364475727,
                0.021732917055487633,
                0.024589603766798973,
                0.04845527559518814,
                -0.016734769567847252,
                0.04214119166135788,
                -0.008410614915192127,
                0.0301238801330328,
                -0.024664346128702164,
                0.01372610405087471,
                -0.05248827114701271,
                -0.005402251146733761,
                0.007226790301501751,
                -0.009620282799005508,
                0.013152028433978558,
                -0.0045102122239768505,
                0.0499405711889267,
                -0.04571419209241867,
                -0.00042682341882027686,
                -0.0012003416195511818,
                0.0161210298538208,
                -0.06600009649991989,
                -0.0056945281103253365,
                -0.01494616363197565,
                0.04019409418106079,
                0.024070285260677338,
                0.039424486458301544,
                -0.08594788610935211,
                0.003541874000802636,
                0.02208441123366356,
                -0.04834382236003876,
                0.05575593188405037,
                -0.005491125863045454,
                0.016384772956371307,
                -0.01379380188882351,
                0.013859741389751434,
                -0.0022306886967271566,
                0.04802646115422249,
                0.016874931752681732,
                -0.08281012624502182,
                -0.054932985454797745,
                -0.00020086423319298774,
                -0.01933578960597515,
                0.04654710367321968,
                -0.0707528218626976,
                0.018327638506889343,
                0.03281225636601448,
                0.04552692919969559,
                -0.032001569867134094,
                0.09611999988555908,
                -0.06994432210922241,
                0.014649312011897564,
                -0.002511335536837578,
                -0.016772069036960602,
                -0.04585210606455803,
                -0.04639345034956932,
                0.03436942398548126,
                0.01445525512099266,
                0.020485371351242065,
                0.02516857534646988,
                -0.023861369118094444,
                -0.002910841489210725,
                -0.0013909809058532119,
                0.033062390983104706,
                -0.005579646211117506,
                0.045319631695747375,
                0.0038204386364668608,
                0.021841706708073616,
                0.033492039889097214,
                0.004373248666524887,
                -0.05448295176029205,
                0.013207611627876759,
                0.034346241503953934,
                -0.04973845183849335,
                0.003052919637411833,
                0.10709407925605774,
                -0.002537740394473076,
                -0.0075587378814816475,
                -0.04467575624585152,
                -0.037260282784700394,
                -0.04436715319752693,
                -0.05188814923167229,
                0.07334399968385696,
                0.006296220235526562,
                -0.019284743815660477,
                0.004757337272167206,
                0.027935689315199852,
                -0.02390218712389469,
                0.023028438910841942,
                -0.010957612656056881,
                0.01361082773655653,
                0.011161932721734047,
                -0.00791309867054224,
                0.01097450777888298,
                -0.09281264990568161,
                0.02859460562467575,
                -0.0008798310300335288,
                -0.06937042623758316,
                0.01967672072350979,
                0.006495400331914425,
                0.009274796582758427,
                -0.044811736792325974,
                0.0888160988688469,
                -0.08276940882205963,
                0.011077621020376682,
                0.060568105429410934,
                -0.05133984237909317,
                -0.0103232366964221,
                -0.030669905245304108,
                -0.0007211794145405293,
                0.035431694239377975,
                0.05661753937602043,
                0.007570459041744471,
                -0.004839950706809759,
                0.021576207131147385,
                0.004146140068769455,
                -0.08302294462919235,
                -0.0029572152998298407,
                -0.022954775020480156,
                0.03341883420944214,
                0.06932507455348969,
                0.013318343088030815,
                0.10792090743780136,
                -0.06647604703903198,
                0.00415033008903265,
                -0.05267845839262009,
                0.01183731947094202,
                0.03177877515554428,
                -5.586277438851539e-06,
                0.007595525123178959,
                0.004749057348817587,
                -0.033313460648059845,
                0.015314457006752491,
                -0.009631290100514889,
                0.009737936779856682,
                -0.028460552915930748,
                0.024124808609485626,
                -0.04526890069246292,
                -0.04248671606183052,
                -0.01656537503004074,
                -0.0037771554198116064,
                -0.03507140278816223,
                0.021376563236117363,
                0.021860869601368904,
                -0.025444479659199715,
                0.013593142852187157,
                -0.026076458394527435,
                0.016687680035829544,
                0.005796168930828571,
                0.017064036801457405,
                0.010510469786822796,
                0.013392796739935875,
                0.07206805795431137,
                -0.05113733187317848,
                -0.020194634795188904,
                -0.05359933525323868,
                -0.03950289264321327,
                -0.016025327146053314,
                0.05581240355968475,
                -0.013368116691708565,
                0.04350282624363899,
                0.08920754492282867,
                0.037803273648023605,
                0.011761190369725227,
                0.03396783769130707,
                -0.022507108747959137,
                0.04620036855340004,
                -0.011545653454959393,
                -0.0060884482227265835,
                0.004635267425328493,
                -0.0031558137852698565,
                -0.03433192893862724,
                0.019618071615695953,
                0.024104449898004532,
                -0.03155124932527542,
                0.01464866753667593,
                -0.05729473754763603,
                0.011263346299529076,
                0.04809245094656944,
                -0.0494459792971611,
                -5.304460502413805e-33,
                0.021318957209587097,
                -0.023327898234128952,
                -0.030970988795161247,
                -0.017239725217223167,
                -0.060419999063014984,
                -0.041912149637937546,
                0.04618873819708824,
                0.000616778910625726,
                -0.027668945491313934,
                -0.013132672756910324,
                -0.007090754806995392,
                0.002643825951963663,
                -0.0010914768790826201,
                0.005906809587031603,
                0.018368853256106377,
                0.041103191673755646,
                0.04688746854662895,
                -0.008500180207192898,
                0.008197867311537266,
                -0.009769735857844353,
                -0.09041579812765121,
                0.010289231315255165,
                -0.005000757519155741,
                -0.013978651724755764,
                0.02589133009314537,
                -0.02313678152859211,
                -0.0408208929002285,
                0.05944376811385155,
                0.021551016718149185,
                -0.03596435487270355,
                0.013556904159486294,
                0.0072915395721793175,
                -0.007344983518123627,
                -0.02861896902322769,
                0.0006648260168731213,
                -0.00593987200409174,
                -0.03263716399669647,
                0.014629087410867214,
                0.005444648675620556,
                0.006587582640349865,
                0.015428193844854832,
                0.007576188538223505,
                -0.004639843478798866,
                -0.013331876136362553,
                -0.011369897983968258,
                -0.02267303690314293,
                0.01301371119916439,
                -0.004894847981631756,
                0.060590047389268875,
                -0.0013051109854131937,
                -0.06813475489616394,
                -0.015682976692914963,
                -0.057045187801122665,
                0.017301466315984726,
                -0.03276543691754341,
                0.06123756617307663,
                -0.003394799306988716,
                -0.008240691386163235,
                -0.021308254450559616,
                -0.01992671750485897,
                -0.008402899838984013,
                -0.013066579587757587,
                0.033279772847890854,
                -0.0459442138671875,
                -0.039183683693408966,
                0.030798256397247314,
                0.018018553033471107,
                0.06973196566104889,
                -0.014092573896050453,
                -0.05605493113398552,
                -0.09001516550779343,
                0.07014770805835724,
                -0.011462277732789516,
                0.015786025673151016,
                -0.010641586035490036,
                -0.003950032871216536,
                -0.03344499692320824,
                0.028875332325696945,
                0.0352044403553009,
                0.030307386070489883,
                -0.03340178728103638,
                -0.02326306700706482,
                -0.025330960750579834,
                -0.02849848009645939,
                0.023500751703977585,
                -0.010322308167815208,
                -0.005640276707708836,
                -0.003323107725009322,
                0.003951033111661673,
                0.014584855176508427,
                0.005358041729778051,
                -0.0504227876663208,
                -0.05655533820390701,
                0.016758093610405922,
                0.11850737035274506,
                -0.04076704382896423,
                0.01763775944709778,
                -0.03363317996263504,
                -0.02807837352156639,
                0.003502852516248822,
                -0.04951375350356102,
                0.0617118775844574,
                -0.03783109411597252,
                0.023536503314971924,
                0.01976419799029827,
                -0.02255287952721119,
                -0.05505793169140816,
                0.0022436531726270914,
                -0.04582545533776283,
                0.009350496344268322,
                -0.013488865457475185,
                -0.027100566774606705,
                -0.0120352478697896,
                -0.011909238062798977,
                -0.058370765298604965,
                7.547811401309446e-05,
                0.03333615884184837,
                -0.05327843129634857,
                -0.011851822026073933,
                -0.007071542553603649,
                0.016191424801945686,
                -0.020693665370345116,
                0.0038604349829256535,
                0.018054727464914322,
                -0.01330704614520073,
                -0.0067978654988110065,
                0.04718632996082306,
                -0.06008568033576012,
                -0.0031400443986058235,
                -0.04036836698651314,
                0.001956168096512556,
                0.00568167120218277,
                2.519711586046469e-07,
                -0.0006971412221901119,
                -0.05085022374987602,
                -0.002380992518737912,
                0.041195955127477646,
                0.044209305197000504,
                -0.03098730556666851,
                -0.042462434619665146,
                0.013046116568148136,
                -0.0146360257640481,
                0.015115216374397278,
                0.04254744574427605,
                -0.023597555235028267,
                -0.013228890486061573,
                -0.08305895328521729,
                -0.058295875787734985,
                -0.04829198122024536,
                0.00937251653522253,
                -0.02165873348712921,
                -0.0050820242613554,
                0.03422205522656441,
                0.07328422367572784,
                0.018362607806921005,
                0.039764177054166794,
                0.0004898325423710048,
                -0.039395466446876526,
                0.04743439704179764,
                -0.017450332641601562,
                -0.032874949276447296,
                0.05891834944486618,
                0.052917782217264175,
                0.06778787076473236,
                -0.02360488288104534,
                0.0010703741572797298,
                0.03435518965125084,
                0.008414194919168949,
                -0.019539885222911835,
                0.037505701184272766,
                0.021411698311567307,
                0.013116038404405117,
                0.019263604655861855,
                0.034682609140872955,
                0.04128167778253555,
                0.020497780293226242,
                -0.00043726045987568796,
                -0.011576023884117603,
                -0.02226533554494381,
                0.022973233833909035,
                0.03159642219543457,
                -0.05935962498188019,
                0.03143809735774994,
                -0.02120869606733322,
                0.06325933337211609,
                0.034502964466810226,
                -0.03522157296538353,
                0.03632040694355965,
                -0.03787047043442726,
                -0.06372860074043274,
                0.007480305153876543,
                0.037831466645002365,
                -0.027696970850229263,
                -0.04851006716489792,
                -0.036195915192365646,
                0.022104259580373764,
                -0.012172340415418148,
                0.05623209476470947,
                0.005817694589495659,
                -0.019676709547638893,
                2.165088173070617e-34,
                -0.015379199758172035,
                0.023412294685840607,
                -0.011901686899363995,
                0.04611669108271599,
                0.04498663917183876,
                -0.04196250066161156,
                0.0014153046067804098,
                -0.03032059036195278,
                0.0188821479678154,
                -0.023004090413451195,
                -0.03183181956410408
            ]
        },
        "Freelance": "Open to both",
        "Id": "ea79febe-40ea-43c0-926c-9496aacaa98e",
        "Industry": {
            "name": "\tEngineering & Science",
            "rep": [
                -0.0017743173521012068,
                -0.06540191918611526,
                -0.057183925062417984,
                -0.01021968200802803,
                -0.0015464175958186388,
                0.01280168816447258,
                0.013760849833488464,
                0.025824731215834618,
                0.04748212546110153,
                0.014538892544806004,
                0.07163389772176743,
                -0.02353169396519661,
                0.007837912999093533,
                0.0468134805560112,
                0.03347809240221977,
                -0.04737117141485214,
                0.024343164637684822,
                0.0053009167313575745,
                -0.06869987398386002,
                -0.022259341552853584,
                -0.05149216204881668,
                0.016036516055464745,
                -0.0029042467940598726,
                -0.0036947294138371944,
                0.03511287644505501,
                0.02267715334892273,
                -0.009673421271145344,
                -0.02188161388039589,
                0.028720607981085777,
                -0.001588314538821578,
                0.055428676307201385,
                0.005989522207528353,
                -0.005354196298867464,
                0.0049379137344658375,
                1.2993297104912926e-06,
                -0.01576869934797287,
                -0.00576708372682333,
                0.02383703552186489,
                -0.0022687974851578474,
                -0.0018692055018618703,
                0.05417262390255928,
                0.001066653523594141,
                -0.017826486378908157,
                0.01946091279387474,
                -0.01068202592432499,
                0.036366671323776245,
                0.06042829155921936,
                -0.02791542187333107,
                -0.03251570090651512,
                -0.011414684355258942,
                0.004598107188940048,
                -0.023577813059091568,
                -0.0533662773668766,
                -0.04057376831769943,
                0.006178968586027622,
                -0.004947854205965996,
                0.010500216856598854,
                0.021099824458360672,
                0.06645642220973969,
                0.11665841937065125,
                -0.03664793074131012,
                0.009184550493955612,
                0.0010128330904990435,
                -0.01641770638525486,
                0.061953917145729065,
                0.06694300472736359,
                0.02779962867498398,
                0.005071847699582577,
                0.02688797563314438,
                0.048882175236940384,
                -0.005267103668302298,
                -0.03187001496553421,
                0.03142936900258064,
                0.0387137271463871,
                -0.05631473660469055,
                0.0008644514018669724,
                0.007649511564522982,
                -0.007274557836353779,
                -0.0034950587432831526,
                -0.026800289750099182,
                0.020549960434436798,
                -0.02407636120915413,
                -0.02598515711724758,
                0.04286003112792969,
                0.009501971304416656,
                0.04636073485016823,
                0.007049275562167168,
                0.0060906438156962395,
                -0.03664964437484741,
                0.04484967514872551,
                0.047846660017967224,
                -0.038602571934461594,
                -0.008565465919673443,
                0.052147820591926575,
                -0.005129795055836439,
                -0.036405809223651886,
                0.06214723363518715,
                -0.037100836634635925,
                0.009797950275242329,
                0.011255650781095028,
                -0.04623638466000557,
                0.013494366779923439,
                0.049839895218610764,
                0.06818942725658417,
                -0.02336544170975685,
                -0.012083678506314754,
                0.016029803082346916,
                0.010762610472738743,
                -0.002976007526740432,
                0.014918896369636059,
                -0.0616215355694294,
                -0.04933493584394455,
                0.028447167947888374,
                -0.021234361454844475,
                -0.014418636448681355,
                0.006654031574726105,
                -0.0271573755890131,
                -0.02996896207332611,
                -0.0005638709990307689,
                -0.018916169181466103,
                0.006914740428328514,
                -0.004927004221826792,
                -0.030801018700003624,
                0.01750366762280464,
                0.00013634675997309387,
                0.051058609038591385,
                -0.018595006316900253,
                0.03834829479455948,
                0.06489744782447815,
                0.05713330954313278,
                -0.04034727066755295,
                0.0097020473331213,
                -0.03492753952741623,
                -0.008715752512216568,
                -0.01896018162369728,
                0.010189753025770187,
                -0.022900084033608437,
                0.04345731809735298,
                -0.04867205396294594,
                -0.05859113112092018,
                0.00870323646813631,
                -0.0462886206805706,
                -0.006004113703966141,
                0.038837503641843796,
                -0.008635315112769604,
                0.012587402015924454,
                0.009399673901498318,
                -0.03136058896780014,
                0.007352481596171856,
                -0.04316581413149834,
                -0.012796744704246521,
                0.005841889884322882,
                0.0027215732261538506,
                -0.036414735019207,
                0.0891813412308693,
                0.08010143786668777,
                0.023114873096346855,
                -0.015704849734902382,
                -0.03315276280045509,
                0.030541645362973213,
                -0.023199571296572685,
                0.0056291306391358376,
                0.014752672985196114,
                -0.016788126900792122,
                0.0025534990709275007,
                -0.018654752522706985,
                -6.193467561388388e-05,
                -0.05598640814423561,
                -0.0917934700846672,
                -0.026601018384099007,
                -0.021686745807528496,
                -0.016171488910913467,
                -0.044810712337493896,
                0.08664409816265106,
                -0.019789692014455795,
                0.010372578166425228,
                0.04868040606379509,
                0.08399804681539536,
                -0.010954980738461018,
                0.0013931734720245004,
                0.010570270009338856,
                -0.09535917639732361,
                0.05067220330238342,
                0.041590310633182526,
                -0.018909789621829987,
                -0.0101022245362401,
                0.019323201850056648,
                0.01273751724511385,
                -0.05922035127878189,
                -0.01729491353034973,
                -0.0017075749346986413,
                0.018786583095788956,
                -0.028185132890939713,
                0.012809669598937035,
                0.032584551721811295,
                -3.3066320611396804e-05,
                0.04127120599150658,
                0.05619976669549942,
                0.012629766017198563,
                0.015644049271941185,
                -0.02323523908853531,
                0.02560052089393139,
                0.011489770375192165,
                0.025895219296216965,
                -0.03191575035452843,
                -0.03299576789140701,
                -0.002642235951498151,
                -0.021089790388941765,
                -0.031134791672229767,
                0.016075531020760536,
                0.005856629461050034,
                0.0019014630233868957,
                0.018873490393161774,
                0.042496997863054276,
                -0.03686288371682167,
                -0.047764651477336884,
                -0.004917530808597803,
                -0.01776270382106304,
                -0.01836264505982399,
                0.07179222255945206,
                -0.03291505202651024,
                -0.001267125946469605,
                -0.0006816671811975539,
                -0.038330309092998505,
                -0.07291236519813538,
                0.05226932838559151,
                0.005105580203235149,
                -0.013606888242065907,
                -0.0692729726433754,
                0.008071285672485828,
                0.02182641066610813,
                0.012057531625032425,
                -0.005548383109271526,
                -0.0031251709442585707,
                -0.037087056785821915,
                -0.013688976876437664,
                0.10410924255847931,
                0.06923938542604446,
                0.014982401393353939,
                -0.07639991492033005,
                -0.0013492621947079897,
                -0.009595651179552078,
                0.03280947357416153,
                -0.010513856075704098,
                0.0693952664732933,
                -0.00674893194809556,
                -0.0040186685509979725,
                0.026536034420132637,
                0.019472749903798103,
                -0.0024507390335202217,
                -0.019428933039307594,
                -0.041336141526699066,
                -0.003967972472310066,
                0.03499075025320053,
                0.044245488941669464,
                -0.013669931329786777,
                0.0035641281865537167,
                0.025144953280687332,
                -0.01206567045301199,
                -0.034417856484651566,
                0.05132358893752098,
                -0.04069953411817551,
                -0.03511369600892067,
                0.009065980091691017,
                -0.015663450583815575,
                -0.03660093992948532,
                0.011884779669344425,
                -0.003439027816057205,
                -0.06403208523988724,
                0.016917480155825615,
                -0.016081731766462326,
                0.020020799711346626,
                0.03123612515628338,
                0.025537483394145966,
                0.004139277618378401,
                0.04903271794319153,
                -0.00635261507704854,
                0.011376053094863892,
                -0.08072163909673691,
                0.03785659745335579,
                -0.008460483513772488,
                -0.02718336321413517,
                -0.05454104766249657,
                -0.019385183230042458,
                -0.017387347295880318,
                0.05038188025355339,
                0.09581398218870163,
                0.0092593003064394,
                -0.053333599120378494,
                0.05570715293288231,
                0.0364857017993927,
                -0.007456821855157614,
                0.021791968494653702,
                -0.0565451942384243,
                -0.036639828234910965,
                0.03200154006481171,
                0.01295300293713808,
                0.02939945086836815,
                0.0237658079713583,
                0.04532232508063316,
                -0.025542980059981346,
                -0.016188429668545723,
                0.03225584328174591,
                0.006099569145590067,
                -0.04577561840415001,
                0.07980228960514069,
                0.023080529645085335,
                0.015911584720015526,
                -0.03646751865744591,
                -0.006720623932778835,
                -0.022509191185235977,
                0.0036206527147442102,
                0.04420250654220581,
                0.06384111940860748,
                0.0012845927849411964,
                -0.021697869524359703,
                0.00047764135524630547,
                0.0024303242098540068,
                0.010023209266364574,
                0.04140721634030342,
                0.02861838787794113,
                0.03636392578482628,
                -0.005346886347979307,
                0.011524404399096966,
                0.0283814687281847,
                -0.025607867166399956,
                -0.031243568286299706,
                0.020761825144290924,
                -0.026624124497175217,
                -0.0887894555926323,
                0.023491082713007927,
                0.04883400723338127,
                0.0002653809788171202,
                0.014956270344555378,
                0.032600052654743195,
                -0.012761695310473442,
                -0.02906772308051586,
                -0.1098470613360405,
                -0.0720798522233963,
                -0.002630222588777542,
                -0.01889841817319393,
                -0.05489363148808479,
                -0.04373946785926819,
                -0.002363318344578147,
                0.05170441418886185,
                0.023372793570160866,
                0.006708999164402485,
                -0.011214704252779484,
                -0.03292061761021614,
                -0.02016380801796913,
                0.08121182024478912,
                0.003225380787625909,
                -0.021122446283698082,
                -0.0080596674233675,
                -0.02341657690703869,
                0.1013602688908577,
                0.025336887687444687,
                -0.0026257652789354324,
                -0.030554361641407013,
                -0.02798670344054699,
                0.007601641118526459,
                -0.006280527450144291,
                -0.02578720822930336,
                0.010721688158810139,
                -0.030866021290421486,
                0.01974581554532051,
                0.04880548268556595,
                0.03907543048262596,
                -0.02903064154088497,
                -0.0009675032342784107,
                -0.004987239371985197,
                0.020052462816238403,
                -0.06692034751176834,
                0.01737486943602562,
                -0.04406049847602844,
                0.06046206131577492,
                -0.008542458526790142,
                0.0024866245221346617,
                -0.02887776494026184,
                0.025312619283795357,
                0.01964651793241501,
                -0.009896640665829182,
                0.07627659291028976,
                -0.01639441028237343,
                -0.03395586460828781,
                0.013064821250736713,
                -0.0017192899249494076,
                -0.01622377149760723,
                -0.009655657224357128,
                0.01517992839217186,
                -0.0009206710383296013,
                -0.02817266434431076,
                -0.09267241507768631,
                -0.0008540258859284222,
                -0.019641930237412453,
                -0.0007602212717756629,
                -0.03888799250125885,
                -0.010008890181779861,
                0.017907794564962387,
                0.047803595662117004,
                0.0524168461561203,
                0.0015596344601362944,
                -0.020741410553455353,
                0.03174930438399315,
                -0.03815450891852379,
                -0.01056206226348877,
                0.04187600687146187,
                -0.005584597587585449,
                -0.061267998069524765,
                -0.05936271324753761,
                0.06823540478944778,
                0.012599026784300804,
                0.019565891474485397,
                -0.05452597141265869,
                -0.03842714801430702,
                0.0061697885394096375,
                -0.007233491167426109,
                0.01469630841165781,
                0.05801752582192421,
                -0.035308193415403366,
                0.0007446767413057387,
                0.009688337333500385,
                0.05383720248937607,
                -0.03592207282781601,
                0.050496481359004974,
                -0.06128356605768204,
                -0.031835924834012985,
                0.07156262546777725,
                -0.038054097443819046,
                -0.01356301549822092,
                -0.04346425458788872,
                -0.05686658248305321,
                -0.03063010610640049,
                0.02246243692934513,
                0.011242418549954891,
                -0.06800971925258636,
                -0.026293816044926643,
                0.006412680726498365,
                -0.027966275811195374,
                0.019357476383447647,
                0.015695231035351753,
                -0.0055010817013680935,
                0.008329937234520912,
                -0.011423695832490921,
                0.042729124426841736,
                0.08997506648302078,
                -0.01751033402979374,
                -0.029703937470912933,
                -0.030772864818572998,
                0.0036130899097770452,
                0.07050012797117233,
                0.0002166055783163756,
                -0.03286697715520859,
                -0.008572977036237717,
                -0.000593512027990073,
                -0.0702681690454483,
                -0.09880347549915314,
                0.008402476087212563,
                -0.007183195557445288,
                0.03428265079855919,
                0.055359479039907455,
                0.03472364693880081,
                0.001414939877577126,
                -0.008271502330899239,
                -0.02719271183013916,
                -0.05252493917942047,
                -0.014354700222611427,
                -0.034187838435173035,
                -0.008611141704022884,
                -0.058294765651226044,
                -0.06004806607961655,
                0.004532868042588234,
                -0.040676601231098175,
                0.038621366024017334,
                0.03232700005173683,
                -0.0019419651944190264,
                -0.025560732930898666,
                -0.003749529365450144,
                -0.06081440672278404,
                0.001982335466891527,
                0.010449839755892754,
                0.04206414520740509,
                0.0035561274271458387,
                0.00031803056481294334,
                0.009081798605620861,
                0.012773960828781128,
                0.06645696610212326,
                -0.008435937575995922,
                0.03942039981484413,
                -0.035196226090192795,
                0.02432766743004322,
                -0.046596709638834,
                -0.0017720973119139671,
                0.0040373667143285275,
                0.07339132577180862,
                -0.00046642113011330366,
                -0.032675981521606445,
                0.04841974005103111,
                -0.04371641203761101,
                0.027053670957684517,
                -0.010026565752923489,
                0.02269737236201763,
                0.01814666949212551,
                -0.027478838339447975,
                -0.02897261269390583,
                0.06065663322806358,
                -0.012787632644176483,
                0.008937472477555275,
                0.009660284034907818,
                0.048926178365945816,
                0.033044617623090744,
                0.041089706122875214,
                -0.019281571730971336,
                0.014472861774265766,
                -0.00469200499355793,
                0.007286329288035631,
                0.014715084806084633,
                0.0058217598125338554,
                0.018734531477093697,
                -6.466866034315899e-05,
                -0.015688244253396988,
                -0.0482611320912838,
                0.03710126131772995,
                -0.022588051855564117,
                0.020474163815379143,
                -0.02017272636294365,
                0.023963525891304016,
                0.030054882168769836,
                -0.018328415229916573,
                0.04008016735315323,
                0.002509585116058588,
                -0.029936660081148148,
                -0.003048679558560252,
                0.030600858852267265,
                -0.0019531305879354477,
                0.07794678211212158,
                0.04352230951189995,
                -0.0034578845370560884,
                0.01852659322321415,
                0.04253392294049263,
                -0.017582248896360397,
                -0.004543545190244913,
                0.029553107917308807,
                0.03787558153271675,
                0.002392122521996498,
                -0.029270343482494354,
                0.05650115758180618,
                0.037886254489421844,
                -0.009176854975521564,
                0.013886663131415844,
                0.01452728547155857,
                0.029345789924263954,
                -0.019008072093129158,
                0.018383849412202835,
                0.008360820822417736,
                -5.898599428357545e-33,
                -0.012992216274142265,
                -0.05055350437760353,
                0.033946070820093155,
                0.021838240325450897,
                -0.06032838299870491,
                -0.006668700370937586,
                0.005518296267837286,
                -0.010050260461866856,
                -0.07863125950098038,
                -0.032855767756700516,
                -0.035664014518260956,
                0.028676290065050125,
                0.012177946045994759,
                0.010619809851050377,
                0.09085848927497864,
                -0.004797806032001972,
                0.012364107184112072,
                0.04357704520225525,
                -0.03241569176316261,
                -0.029752900823950768,
                -0.013326914049685001,
                -0.00731003237888217,
                0.031149961054325104,
                -0.00952809676527977,
                0.004275968763977289,
                0.019867558032274246,
                0.0009520570747554302,
                -0.04570572078227997,
                -0.04534522071480751,
                -0.03596016764640808,
                0.029313793405890465,
                -0.0029952023178339005,
                0.009016580879688263,
                -0.07380249351263046,
                -0.009366876445710659,
                0.013480423018336296,
                -0.03198337182402611,
                -0.04767417162656784,
                -0.06673281639814377,
                -0.00622722040861845,
                -0.0557442232966423,
                -0.02033654786646366,
                0.0012247110716998577,
                -0.008437703363597393,
                -0.08251630514860153,
                -0.08836662769317627,
                -0.0020226319320499897,
                0.03445456176996231,
                0.06014007329940796,
                -0.04722597822546959,
                -0.05010077357292175,
                -0.015959786251187325,
                -0.0530838742852211,
                0.022117670625448227,
                0.026202257722616196,
                -0.01288970373570919,
                0.033160798251628876,
                0.020847586914896965,
                -0.006134416908025742,
                0.04026922956109047,
                0.005261702463030815,
                0.07015175372362137,
                0.04783659428358078,
                -0.03785121813416481,
                -0.044399816542863846,
                0.0017585697351023555,
                -0.051651906222105026,
                0.013455122709274292,
                0.030932970345020294,
                -0.06747494637966156,
                -0.028269382193684578,
                0.10485785454511642,
                -0.02750256471335888,
                -0.01648966409265995,
                -0.06180451065301895,
                -0.0795271098613739,
                -0.06387650221586227,
                0.004508204758167267,
                0.03768589347600937,
                0.03334031626582146,
                -0.05304616317152977,
                0.019716084003448486,
                0.028164973482489586,
                -0.020500879734754562,
                0.005080795381218195,
                0.017877187579870224,
                0.014887123368680477,
                0.0635659247636795,
                -0.010712355375289917,
                0.021326206624507904,
                -0.0015016476390883327,
                -0.0016375117702409625,
                -0.017584970220923424,
                0.007558851502835751,
                0.05878940224647522,
                0.03293522074818611,
                0.013192577287554741,
                -0.02235224097967148,
                0.013460843823850155,
                0.04769549146294594,
                -0.04170247167348862,
                -0.03598294407129288,
                -0.05927641689777374,
                0.06491481512784958,
                0.008507668040692806,
                -0.01312621496617794,
                -0.09682156890630722,
                -0.001383923809044063,
                0.01585647277534008,
                0.010230083018541336,
                0.018489768728613853,
                -0.03452018275856972,
                0.007088878657668829,
                -0.05044499412178993,
                -0.004053717479109764,
                0.024780362844467163,
                0.011229673400521278,
                -0.06925664842128754,
                -0.04792051762342453,
                0.03726189211010933,
                0.0154191255569458,
                0.00782549474388361,
                -0.00815290492027998,
                0.03136647120118141,
                0.012572133913636208,
                -0.030076339840888977,
                0.04221596196293831,
                -0.05594365671277046,
                -0.015350867994129658,
                -0.022234898060560226,
                -0.028064433485269547,
                -0.002707128180190921,
                1.989383662248656e-07,
                -0.003663399489596486,
                0.020726189017295837,
                0.02562667988240719,
                0.0788407176733017,
                0.003729171585291624,
                0.008759234100580215,
                -0.011377460323274136,
                0.02534625120460987,
                0.036834366619586945,
                0.026211975142359734,
                -0.03337661549448967,
                -0.012577319517731667,
                -0.009858987294137478,
                -0.055611297488212585,
                -0.014493854716420174,
                -0.04928743839263916,
                0.01638929359614849,
                0.019042158499360085,
                0.01651046983897686,
                -0.0061246068216860294,
                0.024325072765350342,
                0.021678773686289787,
                -0.024747753515839577,
                -0.005820015911012888,
                -0.06039511039853096,
                0.007074548862874508,
                0.016126533970236778,
                -0.024271320551633835,
                0.1279972940683365,
                0.06805207580327988,
                -0.015136363916099072,
                0.03435857966542244,
                0.03590838611125946,
                0.06845895946025848,
                -0.029281949624419212,
                -0.030865633860230446,
                0.05221743881702423,
                0.006936078891158104,
                0.004176529124379158,
                0.03560412675142288,
                -0.038102887570858,
                0.027268260717391968,
                0.0194871723651886,
                -0.04315545782446861,
                0.020147953182458878,
                0.04318975657224655,
                -0.010638458654284477,
                -0.03508364036679268,
                -0.04278896003961563,
                -0.025483710691332817,
                -0.04675222560763359,
                0.041356656700372696,
                0.008236278779804707,
                -0.05844304710626602,
                0.0077453237026929855,
                -0.007109657395631075,
                -0.004218420945107937,
                0.0360700786113739,
                0.016749609261751175,
                0.04077713191509247,
                -0.012798509560525417,
                -0.04603108763694763,
                0.006606091745197773,
                0.02246125042438507,
                0.08042347431182861,
                -0.02296208031475544,
                -0.0825435146689415,
                1.1378931104809894e-34,
                0.014905774034559727,
                -0.02790183201432228,
                -0.022027336061000824,
                0.06944898515939713,
                -0.015952391549944878,
                -0.03914716839790344,
                -0.012335644103586674,
                -0.001011217711493373,
                -0.0070094638504087925,
                0.0010940851643681526,
                -0.05692191421985626
            ]
        },
        "Location": "Aberdeen",
        "Remuneration": 37.25961538461539,
        "Services Required": {
            "name": "Mechanical Design Engineer",
            "rep": [
                -0.019102731719613075,
                -0.025611117482185364,
                -0.06807278096675873,
                0.00821107067167759,
                0.018527964130043983,
                0.01987912505865097,
                0.05026311054825783,
                0.028363686054944992,
                -0.04949510097503662,
                -0.005584163591265678,
                0.05574771389365196,
                0.0044981022365391254,
                -0.005978272296488285,
                0.02681652456521988,
                0.03457152470946312,
                -0.018603721633553505,
                -0.04239646717905998,
                0.019230766221880913,
                -0.050883058458566666,
                -0.0015296993078663945,
                -0.030622044578194618,
                -0.0064548105001449585,
                -0.03460012748837471,
                0.03336307406425476,
                -0.012321517802774906,
                0.02361530251801014,
                -0.00034436487476341426,
                -0.009077288210391998,
                0.04431352764368057,
                0.05693748593330383,
                0.030178435146808624,
                0.03824085369706154,
                -0.01900457963347435,
                0.030511753633618355,
                1.4604100897486205e-06,
                0.05131680145859718,
                0.07640158385038376,
                0.015030810609459877,
                0.020734617486596107,
                -0.02848656475543976,
                0.00825201440602541,
                -0.0509999580681324,
                -0.04652160778641701,
                0.015164373442530632,
                0.02475293166935444,
                -0.00682881660759449,
                0.017096851021051407,
                0.01839788258075714,
                -0.03390933573246002,
                -0.03916531428694725,
                0.004096092190593481,
                -0.023677213117480278,
                -0.02962040901184082,
                -0.030829792842268944,
                0.013412279076874256,
                0.009156144224107265,
                0.01787753216922283,
                0.031046848744153976,
                0.06291203945875168,
                0.05076368898153305,
                0.0024373396299779415,
                0.012444489635527134,
                0.0011595138348639011,
                -0.01458002533763647,
                0.07160349935293198,
                0.038436491042375565,
                0.07324983179569244,
                0.01062546856701374,
                0.015290516428649426,
                0.03044755756855011,
                0.07222141325473785,
                0.0017605292377993464,
                0.00049456802662462,
                0.034814123064279556,
                0.009742372669279575,
                0.03422550484538078,
                0.01582278311252594,
                -0.005367183592170477,
                -0.017640728503465652,
                -0.03737230971455574,
                -0.023068828508257866,
                -0.00953647866845131,
                -0.03106662631034851,
                0.007022467441856861,
                0.009719498455524445,
                -0.03431146219372749,
                -0.021880414336919785,
                0.0033401495311409235,
                0.0030292952433228493,
                -0.0026073434855788946,
                0.06971563398838043,
                -0.025633687153458595,
                0.03106035478413105,
                -0.020940057933330536,
                0.0023089859168976545,
                -0.005314575042575598,
                0.03569792956113815,
                -0.05913596227765083,
                -0.01921510510146618,
                -0.025965524837374687,
                -0.07787314057350159,
                0.05069149658083916,
                0.06767289340496063,
                0.011997958645224571,
                -0.06328367441892624,
                0.01718888059258461,
                -0.015547974035143852,
                0.039540115743875504,
                -0.016341743990778923,
                0.04573233798146248,
                -0.06782039254903793,
                -0.007450147997587919,
                -0.0048775146715343,
                -0.06353466957807541,
                0.018457375466823578,
                0.05227261781692505,
                -0.0023891334421932697,
                0.043261922895908356,
                0.023027505725622177,
                -0.01350646372884512,
                -0.005323891062289476,
                0.02629721723496914,
                -0.033174678683280945,
                0.01718655787408352,
                -0.027283737435936928,
                -0.0005915002548135817,
                -0.02841617725789547,
                0.03572008013725281,
                0.059570327401161194,
                0.06005999818444252,
                -0.018783725798130035,
                0.019142692908644676,
                -0.019810741767287254,
                -0.03674433380365372,
                -0.023280562832951546,
                0.0759657621383667,
                -0.04996609687805176,
                0.023148328065872192,
                -0.027868330478668213,
                -0.04811744764447212,
                0.0083913030102849,
                0.021728871390223503,
                -0.016964111477136612,
                0.04740592837333679,
                -0.0037309047766029835,
                -0.025976458564400673,
                -0.009943264536559582,
                0.034225091338157654,
                0.029856406152248383,
                -0.030508339405059814,
                -0.014357652515172958,
                0.015266716480255127,
                0.011244078166782856,
                0.013651532121002674,
                0.05530595779418945,
                0.01644357107579708,
                0.017333874478936195,
                -0.012876410037279129,
                -0.04342822730541229,
                0.06321366131305695,
                -0.010761900804936886,
                -0.008395702578127384,
                0.01472377311438322,
                0.0239856094121933,
                -0.047700729221105576,
                -0.007815660908818245,
                0.021343518048524857,
                -0.032446667551994324,
                -0.06885301321744919,
                -0.005314586218446493,
                -0.006163464393466711,
                -0.043129660189151764,
                -0.02437613159418106,
                0.09773930162191391,
                0.007941214367747307,
                -0.060529403388500214,
                0.06906219571828842,
                0.036609895527362823,
                0.010757089592516422,
                0.033424362540245056,
                0.009483620524406433,
                -0.1144726574420929,
                0.054270513355731964,
                -0.008854898624122143,
                -0.04745608940720558,
                -0.020691700279712677,
                -0.026812568306922913,
                -0.02066715992987156,
                -0.07159104198217392,
                -0.011579363606870174,
                0.054293762892484665,
                -0.010035003535449505,
                -0.008511899039149284,
                0.022639203816652298,
                0.047760866582393646,
                -0.011667396873235703,
                0.0025257489178329706,
                0.0019484487129375339,
                -0.01882931962609291,
                0.021738095209002495,
                -0.06816963106393814,
                0.01716652698814869,
                -0.06610847264528275,
                0.013235100544989109,
                -0.0012976799625903368,
                -0.04630971699953079,
                -0.06380871683359146,
                -0.05107857659459114,
                -0.033774953335523605,
                0.030494436621665955,
                0.044873859733343124,
                0.0018256355542689562,
                0.0015477396082133055,
                0.0344589427113533,
                -0.024647150188684464,
                -0.013297989033162594,
                0.0017831487348303199,
                0.007056847680360079,
                -0.05062134563922882,
                0.0029661874286830425,
                -0.001034833607263863,
                -0.02488393522799015,
                -0.00560627318918705,
                -0.036345068365335464,
                -0.039019931107759476,
                -0.03644496202468872,
                0.04180910438299179,
                0.00790585856884718,
                -0.01834219880402088,
                0.035905420780181885,
                -0.003921693190932274,
                0.004379205405712128,
                0.023994309827685356,
                -0.041135307401418686,
                0.008731947280466557,
                0.04540224373340607,
                0.0236459132283926,
                0.07080671191215515,
                -0.007585988845676184,
                -0.1251443326473236,
                -0.035601984709501266,
                -0.0008646545466035604,
                0.019643953070044518,
                -0.013202391564846039,
                0.01779494248330593,
                -0.03154633566737175,
                -0.030652908608317375,
                0.020873885601758957,
                0.03081595152616501,
                0.00727315666154027,
                -0.02429504692554474,
                -0.040129661560058594,
                0.01622690260410309,
                0.0284589696675539,
                0.035654276609420776,
                -0.010337901301681995,
                0.01745382882654667,
                0.04151226580142975,
                0.0032968611922115088,
                -0.0522291325032711,
                0.06455758213996887,
                -0.04177413508296013,
                0.0009355275542475283,
                0.019444042816758156,
                0.05983900651335716,
                0.016315145418047905,
                -0.04131177067756653,
                -0.042842477560043335,
                -0.041512880474328995,
                0.0374596044421196,
                -0.010441480204463005,
                0.0683714896440506,
                0.021618615835905075,
                -0.020213831216096878,
                0.026742564514279366,
                0.02700471132993698,
                0.0009456893894821405,
                0.012257405556738377,
                -0.07869018614292145,
                0.026602981612086296,
                0.05303157493472099,
                -0.010004815645515919,
                0.015854550525546074,
                -0.006999595556408167,
                -0.020815687254071236,
                -0.0150566091760993,
                0.07026414573192596,
                -0.04577762261033058,
                -0.028330475091934204,
                0.058713313192129135,
                -0.027286112308502197,
                -0.04618427902460098,
                0.014877057634294033,
                -0.06685683876276016,
                -0.02303239144384861,
                0.022257033735513687,
                0.018051235005259514,
                -0.022041095420718193,
                0.005402735900133848,
                0.06422992795705795,
                -0.0007691361242905259,
                -0.020615577697753906,
                0.04161738604307175,
                -0.00774777727201581,
                0.01551229041069746,
                0.02702418714761734,
                0.04178980365395546,
                0.0020490887109190226,
                -0.05114651843905449,
                -0.03438831865787506,
                -0.011636546812951565,
                0.04709925502538681,
                0.009338334202766418,
                0.03951886668801308,
                0.06429058313369751,
                -0.0430392362177372,
                0.017885388806462288,
                0.0457892082631588,
                0.0069724456407129765,
                0.06556108593940735,
                -0.003825163934379816,
                0.0655633881688118,
                -0.009101354517042637,
                0.010036691091954708,
                0.02513795904815197,
                0.05709543451666832,
                -0.012639571912586689,
                -0.0057968986220657825,
                0.0018328267615288496,
                -0.0691293254494667,
                -0.0055311559699475765,
                0.07808339595794678,
                -0.05219195783138275,
                0.0010777786374092102,
                0.09604626893997192,
                -0.03593938425183296,
                -0.008427337743341923,
                -0.09296803921461105,
                -0.03739519417285919,
                0.0032650844659656286,
                -0.036477912217378616,
                -0.03570205345749855,
                -0.04192746430635452,
                0.02499469928443432,
                -0.035933587700128555,
                0.01356271281838417,
                -0.005665221717208624,
                -0.0025059080217033625,
                -0.0012555861612781882,
                -0.013828227296471596,
                0.051138199865818024,
                0.011098121292889118,
                -0.016750693321228027,
                0.0150705361738801,
                -0.05080199986696243,
                0.019841397181153297,
                0.013967001810669899,
                0.0800759494304657,
                -0.027082273736596107,
                -0.010851775296032429,
                -0.025178130716085434,
                -0.01283103134483099,
                -0.01928877830505371,
                -0.03557593375444412,
                -0.03292524814605713,
                0.00046199781354516745,
                0.05717022716999054,
                0.03587586060166359,
                0.004553336184471846,
                0.012667425908148289,
                -0.05280344933271408,
                0.01772129535675049,
                -0.035765647888183594,
                -0.017643161118030548,
                -0.009446351788938046,
                0.012175027281045914,
                0.021044230088591576,
                0.054288312792778015,
                0.054049182683229446,
                -0.015565219335258007,
                0.019861703738570213,
                -0.07052595913410187,
                0.008183060213923454,
                -0.00399881461635232,
                0.016983898356556892,
                -0.05422274023294449,
                -0.014823198318481445,
                0.02527523972094059,
                0.014839349314570427,
                0.006297340616583824,
                -0.004625638015568256,
                0.004579522646963596,
                -0.060635313391685486,
                0.012864959426224232,
                -0.00042562204180285335,
                0.013336476869881153,
                -0.028880400583148003,
                0.017887728288769722,
                0.01472441665828228,
                0.04427722468972206,
                0.03631078451871872,
                0.03853680193424225,
                -0.09645401686429977,
                0.025273485109210014,
                0.0008751982823014259,
                -0.03740060701966286,
                0.09386420249938965,
                -0.01417925301939249,
                -0.03835029900074005,
                -0.020135315135121346,
                0.06783882528543472,
                0.006058542989194393,
                0.007676572538912296,
                -0.0229257233440876,
                -0.03760531544685364,
                -0.026747051626443863,
                -0.03526666760444641,
                -0.05457761511206627,
                0.0050861649215221405,
                -0.05817849189043045,
                0.042288754135370255,
                0.011339097283780575,
                0.05690073221921921,
                -0.03500733524560928,
                0.07204380631446838,
                -0.03463944047689438,
                -0.04916893318295479,
                -0.014995979145169258,
                -0.0210551880300045,
                -0.02716003730893135,
                -0.05569940432906151,
                -0.04743528366088867,
                -0.035402100533246994,
                0.028163058683276176,
                0.017098600044846535,
                -0.024162471294403076,
                -0.02652944251894951,
                0.006525212433189154,
                -0.0087985098361969,
                0.03292306885123253,
                0.022398367524147034,
                -0.0071297637186944485,
                0.005706809926778078,
                -0.026687735691666603,
                -0.030233699828386307,
                0.020168134942650795,
                0.02969096414744854,
                -0.05368048697710037,
                -0.052216511219739914,
                -0.006109439302235842,
                0.08922956138849258,
                0.006655698176473379,
                -0.006646537221968174,
                -0.06959115713834763,
                -0.04867644980549812,
                -0.01538052037358284,
                -0.09688296169042587,
                0.046362053602933884,
                0.004811395891010761,
                -0.005702587775886059,
                0.0759308934211731,
                0.03158705309033394,
                -0.016049306839704514,
                -0.021223315969109535,
                -0.03814387321472168,
                -0.04629441350698471,
                0.007546445354819298,
                -0.03846469521522522,
                0.003617089707404375,
                -0.04599476605653763,
                -0.03774471580982208,
                0.018437150865793228,
                -0.06933178752660751,
                0.005827880464494228,
                -0.02941187657415867,
                -0.017153138294816017,
                -0.011600258760154247,
                0.04107682406902313,
                -0.008368657901883125,
                0.0016642415430396795,
                0.06580372899770737,
                -0.030118657276034355,
                0.02736606076359749,
                0.016735056415200233,
                -0.015730883926153183,
                0.05631885677576065,
                0.04519103467464447,
                0.002537443768233061,
                -0.00789772067219019,
                0.02308310568332672,
                0.033372912555933,
                -0.0983704924583435,
                -0.025762367993593216,
                -0.07416293770074844,
                0.03578423336148262,
                0.04959795996546745,
                0.004524775315076113,
                0.08919017761945724,
                -0.09082041680812836,
                0.019232526421546936,
                -0.03619756922125816,
                -0.00969532411545515,
                0.02807052806019783,
                -0.004007674753665924,
                0.03782440721988678,
                0.03806184604763985,
                -0.015603050589561462,
                0.005906755104660988,
                -0.008973891846835613,
                0.01754370704293251,
                0.023542799055576324,
                0.04262134060263634,
                -0.006561631802469492,
                -0.03531400114297867,
                -0.02407905086874962,
                -0.05562485754489899,
                0.007646394427865744,
                0.012649445794522762,
                0.008773406967520714,
                0.0031164486426860094,
                0.01440675463527441,
                -0.03655407950282097,
                0.008639417588710785,
                -0.015093369409441948,
                0.04506658762693405,
                -0.04769500344991684,
                0.006304764188826084,
                0.056336838752031326,
                -0.05797016993165016,
                0.017531191930174828,
                -0.03488323837518692,
                -0.02556338720023632,
                -0.03247537463903427,
                0.006055417470633984,
                -0.03808046132326126,
                0.0635138601064682,
                0.07048998773097992,
                0.016050757840275764,
                0.004199402406811714,
                0.02837020717561245,
                -0.04546351358294487,
                0.011178083717823029,
                0.011555023491382599,
                0.010033654980361462,
                0.007210161071270704,
                -0.02349548228085041,
                -0.00791126862168312,
                0.0010310430079698563,
                0.011240401305258274,
                0.0356256477534771,
                -0.0028910585679113865,
                -0.0358499139547348,
                0.05146171525120735,
                0.0797414556145668,
                0.013673504814505577,
                -5.138461598294497e-33,
                -0.004056841600686312,
                -0.03092263452708721,
                0.015444761142134666,
                -0.014369440264999866,
                -0.06567949056625366,
                -0.022425806149840355,
                0.004305139649659395,
                0.03775235638022423,
                -0.048750437796115875,
                -0.02843545936048031,
                -0.020529046654701233,
                -0.022822795435786247,
                0.005192635580897331,
                0.02970898523926735,
                0.032595887780189514,
                0.02450796589255333,
                0.0369618758559227,
                0.02720763348042965,
                0.013264985755085945,
                -0.013058812357485294,
                -0.06507110595703125,
                0.009268280118703842,
                -0.01355950627475977,
                0.0261493269354105,
                0.009784435853362083,
                -0.02575044520199299,
                -0.018063023686408997,
                0.005960585083812475,
                -0.019498566165566444,
                -0.049654487520456314,
                0.011526721529662609,
                0.010518726892769337,
                -0.00963549129664898,
                0.028267785906791687,
                -0.025882121175527573,
                0.0005344640812836587,
                -0.05542067810893059,
                -0.009203312918543816,
                -0.06109757721424103,
                0.01964840292930603,
                0.012509572319686413,
                0.013482571579515934,
                0.030040401965379715,
                -0.000662204169202596,
                -0.06756612658500671,
                -0.03549768403172493,
                -0.00479067163541913,
                -0.008697841316461563,
                0.06628811359405518,
                -0.011161797679960728,
                -0.05776720494031906,
                0.0026393593288958073,
                -0.04738713428378105,
                0.004935903009027243,
                0.03835928812623024,
                0.02107742615044117,
                0.007320122793316841,
                -0.0020187036134302616,
                0.017837975174188614,
                -0.005964178591966629,
                0.006023479625582695,
                -0.005879048258066177,
                0.057305172085762024,
                -0.0658312737941742,
                0.012024031952023506,
                -0.001080491696484387,
                0.026105888187885284,
                0.04970740154385567,
                0.007804768159985542,
                -0.06251727789640427,
                -0.056222084909677505,
                0.08796975761651993,
                -0.033721644431352615,
                -0.021248074248433113,
                -0.04587920382618904,
                -0.035614028573036194,
                -0.004535962361842394,
                0.005574071314185858,
                -0.007901824079453945,
                0.06918839365243912,
                -0.040543489158153534,
                0.008585489355027676,
                -0.0036593053955584764,
                -0.033701200038194656,
                0.028250569477677345,
                -0.004586504772305489,
                0.011547648347914219,
                0.030470632016658783,
                0.0003092465631198138,
                0.025972481817007065,
                0.01605210453271866,
                -0.06331822276115417,
                -0.022197986021637917,
                -0.012487304396927357,
                0.11123950034379959,
                -0.03171776980161667,
                -0.024656543508172035,
                -0.018407756462693214,
                -0.011076089926064014,
                0.04156948998570442,
                -0.04471650347113609,
                0.031521767377853394,
                -0.06082679703831673,
                0.0024381340481340885,
                0.013129879720509052,
                -0.008649863302707672,
                -0.07439454644918442,
                -0.03673933073878288,
                -0.0285495575517416,
                0.022260721772909164,
                -0.03698398545384407,
                -0.02871568687260151,
                0.001725934911519289,
                0.0071662119589746,
                -0.007500385865569115,
                -0.03166937828063965,
                0.012047962285578251,
                -0.10433594137430191,
                0.0019342764280736446,
                0.0020728250965476036,
                -0.003937246277928352,
                0.022874852642416954,
                -0.037523724138736725,
                0.028185131028294563,
                -0.007596924901008606,
                -0.011690470390021801,
                0.0727897435426712,
                -0.018660595640540123,
                0.007589067332446575,
                -0.07562293857336044,
                -0.0005621824529953301,
                -0.02205040119588375,
                1.996339591414653e-07,
                -0.02980547957122326,
                0.03432825207710266,
                0.00431166123598814,
                0.01961498148739338,
                0.01372656598687172,
                0.024831676855683327,
                -0.037569861859083176,
                0.01636498048901558,
                0.020578041672706604,
                0.011650708504021168,
                0.03639000281691551,
                -0.02808486670255661,
                0.017142824828624725,
                -0.08018818497657776,
                0.024326270446181297,
                -0.005745376460254192,
                0.08589214086532593,
                0.0473906584084034,
                -0.0010544852120801806,
                0.025717798620462418,
                0.053450003266334534,
                -0.015392174012959003,
                -0.006654584780335426,
                -0.0010831719264388084,
                -0.0507148914039135,
                -0.004619434475898743,
                -0.003928730729967356,
                -0.00555464019998908,
                0.06577859818935394,
                -0.003565517719835043,
                0.05437014624476433,
                0.00027034434606321156,
                0.0065521798096597195,
                0.05199013277888298,
                0.018362831324338913,
                -0.03631868585944176,
                0.056051965802907944,
                0.022180477157235146,
                0.0016288679325953126,
                0.07013492286205292,
                0.0062446799129247665,
                0.021339165046811104,
                0.012216761708259583,
                0.052140749990940094,
                -0.0011242348700761795,
                0.0007739902357570827,
                0.0032786913216114044,
                0.031155874952673912,
                -0.061222516000270844,
                0.0013235132209956646,
                -0.022999215871095657,
                0.07188254594802856,
                0.04628172889351845,
                -0.034331485629081726,
                0.015560793690383434,
                -0.006813546176999807,
                -0.03416493162512779,
                0.013638707809150219,
                0.02481316588819027,
                0.019878994673490524,
                -0.06361544877290726,
                -0.03344409912824631,
                0.026941275224089622,
                0.013780840672552586,
                0.0631277933716774,
                -0.014079834334552288,
                -0.04131164401769638,
                1.2653474630013383e-34,
                -0.023111067712306976,
                0.04059630632400513,
                0.01250938605517149,
                0.07110239565372467,
                0.031865812838077545,
                -0.033907558768987656,
                0.01145984698086977,
                -0.00010253033542539924,
                0.029391596093773842,
                -0.002442574128508568,
                -0.034768521785736084
            ]
        },
        "Skills": {
            "name": [],
            "rep": []
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\x8f\\xbe\\x0c\\xde\\xcfc$\\x17O\\x15l\\x98(^;@'",
        "id__": "ea79febe-40ea-43c0-926c-9496aacaa98e"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "",
            "rep": [
                -0.01250336691737175,
                0.061438772827386856,
                -0.006734526716172695,
                0.025237763300538063,
                0.014757898636162281,
                0.03317746892571449,
                -0.01709824614226818,
                -0.005254548974335194,
                -0.03517315536737442,
                0.02680721879005432,
                0.027776479721069336,
                0.0016695410013198853,
                -0.035905394703149796,
                0.08371193706989288,
                0.0020816551987081766,
                -0.0036474945954978466,
                -0.00808101985603571,
                0.007572959177196026,
                -0.04329586401581764,
                -0.026224762201309204,
                -0.021265223622322083,
                0.013262759894132614,
                -0.005541951861232519,
                -0.03117932379245758,
                0.005881241522729397,
                -0.0541393980383873,
                0.040238745510578156,
                -0.007921081967651844,
                0.017486119642853737,
                -0.041598841547966,
                0.018058065325021744,
                -0.00015737996727693826,
                -0.001450279843993485,
                -0.03499198332428932,
                3.953377017751336e-06,
                -0.0013549883151426911,
                0.006736484821885824,
                0.035783108323812485,
                -0.04817280173301697,
                0.04840705543756485,
                0.007813598960638046,
                0.03426700457930565,
                -0.017332350835204124,
                -0.03300396353006363,
                0.0231205802410841,
                0.0160819124430418,
                0.011691391468048096,
                -0.026407040655612946,
                -0.044467393308877945,
                0.028630157932639122,
                0.008412609808146954,
                -0.04301135614514351,
                -0.12538503110408783,
                -0.05047597363591194,
                0.05816846713423729,
                0.028868647292256355,
                -0.003383409231901169,
                -0.009581382386386395,
                0.016905196011066437,
                -0.00306876702234149,
                -0.007300361059606075,
                -0.0304871853441,
                -0.016616370528936386,
                0.04997849091887474,
                0.0009452405502088368,
                0.031931791454553604,
                0.03382967785000801,
                -0.03551234304904938,
                0.022719833999872208,
                -0.013534646481275558,
                -0.013018215075135231,
                -0.022502049803733826,
                -0.02794310636818409,
                0.12343361228704453,
                -0.03144539147615433,
                -0.015701577067375183,
                -0.033564116805791855,
                0.03753889352083206,
                0.029097864404320717,
                -0.0075263408944010735,
                -0.02213081531226635,
                -0.010136098600924015,
                0.037694983184337616,
                0.01747259683907032,
                -0.07048329710960388,
                0.041574276983737946,
                -0.03992348909378052,
                -1.9508279365254566e-05,
                -0.01863657496869564,
                0.0036532285157591105,
                0.004205846227705479,
                -0.06392322480678558,
                -0.02061384916305542,
                -0.009598635137081146,
                0.032927192747592926,
                0.0016774265095591545,
                -0.023945268243551254,
                -0.029483433812856674,
                0.019927263259887695,
                -0.006079182494431734,
                -0.05478913336992264,
                0.023929640650749207,
                -0.0070150429382920265,
                0.006951442454010248,
                -0.05057133734226227,
                0.006110988091677427,
                0.02058250829577446,
                -0.023523692041635513,
                0.02736492082476616,
                0.07505252212285995,
                -0.04204901307821274,
                0.03163759037852287,
                0.023439623415470123,
                0.021155521273612976,
                -0.003454080317169428,
                -0.015697283670306206,
                -0.01709374412894249,
                0.04316733777523041,
                0.022733589634299278,
                0.008051550947129726,
                0.09210117906332016,
                0.05982724204659462,
                -0.006791339255869389,
                -0.03160925954580307,
                0.027104921638965607,
                0.0061317854560911655,
                0.010315212421119213,
                -0.03920648992061615,
                -0.05529627576470375,
                0.0036144584883004427,
                -0.021970732137560844,
                0.023343071341514587,
                0.003332081949338317,
                -0.005414539482444525,
                0.0300702303647995,
                -0.011980688199400902,
                0.010207103565335274,
                -0.02246537245810032,
                0.039830293506383896,
                -0.0014929784229025245,
                -0.020150257274508476,
                0.027579449117183685,
                0.002707603620365262,
                -0.005354698281735182,
                -0.02411271072924137,
                0.010192600078880787,
                0.09005865454673767,
                -0.03506376966834068,
                0.0065099638886749744,
                0.003935432061553001,
                0.010230598039925098,
                0.05042262375354767,
                -0.01718307100236416,
                0.050957951694726944,
                0.042619846761226654,
                0.03845520690083504,
                -0.06702651083469391,
                -0.01923864334821701,
                0.002606004709377885,
                0.028123805299401283,
                0.053268007934093475,
                0.002303209388628602,
                -0.035049889236688614,
                -0.015614322386682034,
                -0.028400812298059464,
                -0.03784780204296112,
                0.03566346317529678,
                0.001885668491013348,
                -0.0038645542226731777,
                -0.06404998898506165,
                0.0010549839353188872,
                -0.00885031558573246,
                0.008581440895795822,
                0.012777621857821941,
                0.023975960910320282,
                0.02581288293004036,
                -0.10304789245128632,
                -0.02712412178516388,
                -0.005907086655497551,
                -0.0644407793879509,
                -0.03229215741157532,
                -0.06627113372087479,
                -0.008665882050991058,
                -0.006594209931790829,
                0.0484798364341259,
                0.01764615625143051,
                0.009447470307350159,
                0.015605838038027287,
                0.001336991903372109,
                0.0595303438603878,
                0.0556134432554245,
                -0.04280132055282593,
                0.030285101383924484,
                0.035216376185417175,
                -0.01916254125535488,
                -0.06644861400127411,
                0.005000254139304161,
                -0.03437502309679985,
                -0.01998600736260414,
                -0.019239002838730812,
                0.035958968102931976,
                -0.0642118975520134,
                -0.026285985484719276,
                -0.04305443912744522,
                -0.012780084274709225,
                0.0018684081733226776,
                0.021171214058995247,
                -0.05258452147245407,
                -0.023365097120404243,
                -0.015666605904698372,
                -0.06449753791093826,
                0.02580212615430355,
                0.022736266255378723,
                0.01733192428946495,
                -0.030923957005143166,
                -0.03914716839790344,
                0.043811142444610596,
                -0.013898184522986412,
                -0.049245189875364304,
                0.07740079611539841,
                0.026235133409500122,
                0.05054790899157524,
                0.011461357586085796,
                0.020236769691109657,
                0.06779534369707108,
                0.029156848788261414,
                -0.0023628000635653734,
                0.011973806656897068,
                0.04291291534900665,
                -0.030129238963127136,
                -0.02899443916976452,
                0.05344944819808006,
                0.05811570584774017,
                -0.04694709926843643,
                -0.05033904314041138,
                0.03681037202477455,
                0.06290840357542038,
                -0.010182899422943592,
                0.04336661845445633,
                -0.10038278996944427,
                -0.0408954918384552,
                0.05900667607784271,
                0.015469775535166264,
                0.018710315227508545,
                0.11131074279546738,
                -0.0007150724995881319,
                -0.004573279060423374,
                0.006786289159208536,
                -0.015809012576937675,
                0.009324673563241959,
                -0.027163337916135788,
                0.016815578565001488,
                -0.02251344732940197,
                0.029244842007756233,
                0.03637232631444931,
                0.07193945348262787,
                -0.012299098074436188,
                -0.009656124748289585,
                -0.0193575881421566,
                2.175197732867673e-05,
                0.012475758790969849,
                0.044392213225364685,
                -0.023234998807311058,
                -0.05611341819167137,
                0.024123501032590866,
                0.03691176697611809,
                -0.017150789499282837,
                -0.02631417289376259,
                -0.040450796484947205,
                -0.005599383730441332,
                0.051314495503902435,
                0.028135715052485466,
                0.034666381776332855,
                -0.010067619383335114,
                0.03729860112071037,
                -0.01594937965273857,
                -0.0234685055911541,
                -0.004213577136397362,
                -0.0482347272336483,
                -0.02087913453578949,
                -0.027857424691319466,
                0.08927794545888901,
                0.010641509667038918,
                -0.004202773794531822,
                0.027202021330595016,
                -0.005368836224079132,
                -0.019590962678194046,
                -0.02832445688545704,
                -0.055649660527706146,
                -0.02539868652820587,
                -0.02836921624839306,
                -0.026615502312779427,
                0.071470245718956,
                -0.051241930574178696,
                0.05324987322092056,
                0.003887547878548503,
                -0.03548124060034752,
                0.006939411628991365,
                0.022774415090680122,
                -0.009120232425630093,
                -0.051541924476623535,
                0.06765251606702805,
                -0.008341128006577492,
                -0.026966938748955727,
                -0.0160972960293293,
                -0.029533125460147858,
                -0.019868968054652214,
                0.00859929621219635,
                0.060439061373472214,
                -0.02311551570892334,
                0.00448680529370904,
                0.025432756170630455,
                0.008112108334898949,
                0.034015756100416183,
                -0.06135188415646553,
                -0.04630007594823837,
                -0.03193478286266327,
                -0.06767014414072037,
                0.00842998456209898,
                -0.006241044029593468,
                0.052949488162994385,
                -0.03604333475232124,
                -0.014186553657054901,
                0.00942138209939003,
                0.010135955177247524,
                0.08616578578948975,
                -0.057738397270441055,
                -0.02568899095058441,
                0.0010708898771554232,
                -0.014476461336016655,
                0.0036793805193156004,
                -0.0017947201849892735,
                -0.020894017070531845,
                -0.029539015144109726,
                0.019612127915024757,
                -0.03600529953837395,
                0.010122458450496197,
                0.007492783013731241,
                0.03685415908694267,
                0.016340676695108414,
                0.030536159873008728,
                -0.0466168075799942,
                -0.022464042529463768,
                -0.1031494066119194,
                -0.031066561117768288,
                -0.015180891379714012,
                -0.020436879247426987,
                0.001358519191853702,
                -0.063127301633358,
                0.011295993812382221,
                -0.036736778914928436,
                -0.030513843521475792,
                0.02684878744184971,
                0.008639818988740444,
                0.03397071734070778,
                0.005471780896186829,
                -0.0333276130259037,
                -0.01036415621638298,
                -0.04180051013827324,
                -0.040268488228321075,
                -0.054151054471731186,
                0.012869646772742271,
                0.06618642061948776,
                0.059644076973199844,
                -0.025526219978928566,
                0.0005774935707449913,
                0.029545694589614868,
                0.005512099247425795,
                -0.009801962412893772,
                -0.0659307986497879,
                -0.027822323143482208,
                0.020601699128746986,
                0.03394725173711777,
                0.07557912915945053,
                0.013692679814994335,
                0.011515580117702484,
                0.07788955420255661,
                0.011653480120003223,
                0.0665055513381958,
                -0.02443624846637249,
                -0.016621800139546394,
                -0.06951485574245453,
                -0.08817055821418762,
                -0.013690861873328686,
                0.011746413074433804,
                0.03695254027843475,
                0.015583361499011517,
                0.02966042421758175,
                -0.04149671643972397,
                0.021954426541924477,
                0.06375288218259811,
                0.016550829634070396,
                -0.016622504219412804,
                0.04531807452440262,
                0.016943901777267456,
                -0.05765059217810631,
                -0.010194423608481884,
                0.031080780550837517,
                0.005107048898935318,
                0.055147428065538406,
                0.028596973046660423,
                0.03635311499238014,
                -0.016057226806879044,
                0.012841739691793919,
                -0.01431287545710802,
                -0.037531837821006775,
                0.04440212994813919,
                0.0004841997579205781,
                0.029519900679588318,
                -0.06048693135380745,
                -0.053098440170288086,
                -0.02054908499121666,
                0.0295583363622427,
                0.015336153097450733,
                0.0033795111812651157,
                -0.01853654906153679,
                -0.056357868015766144,
                0.041306979954242706,
                0.03857751190662384,
                -0.04463161900639534,
                0.017224956303834915,
                -0.015480753034353256,
                0.09595205634832382,
                0.008647487498819828,
                -0.04171014949679375,
                -0.00011139697016915306,
                0.016000933945178986,
                0.05842599272727966,
                0.050819724798202515,
                -0.04454408958554268,
                -0.04044615477323532,
                0.039345063269138336,
                -0.031576961278915405,
                0.009894018992781639,
                -0.031009772792458534,
                -0.09126465022563934,
                0.03283938392996788,
                0.05852086469531059,
                -0.011089615523815155,
                -0.035230476409196854,
                -0.024147111922502518,
                -0.03197512403130531,
                0.03297773748636246,
                -0.03444661200046539,
                -0.028208263218402863,
                -0.007524717599153519,
                -0.018318410962820053,
                0.023612791672348976,
                0.025588609278202057,
                -0.051408395171165466,
                -0.0017879278166219592,
                -0.04118284955620766,
                0.016455762088298798,
                -0.07233195006847382,
                0.034944720566272736,
                -0.027807535603642464,
                0.04431791976094246,
                -0.02935546264052391,
                -0.005040796473622322,
                -0.04199744388461113,
                0.047499965876340866,
                -0.017872368916869164,
                0.04956725612282753,
                0.05600331723690033,
                0.018813494592905045,
                0.045953210443258286,
                0.04290954768657684,
                -0.05417734757065773,
                0.01149401068687439,
                -0.05444420874118805,
                -0.0327894352376461,
                -0.015426786616444588,
                -0.026156844571232796,
                0.021491097286343575,
                0.04103664308786392,
                -0.0008519602124579251,
                -0.05277550593018532,
                -0.04462529718875885,
                -0.061105795204639435,
                0.020359434187412262,
                0.03430336341261864,
                -0.01633555255830288,
                -0.016192030161619186,
                -0.034239284694194794,
                0.015339444391429424,
                0.027314649894833565,
                0.024318696931004524,
                -0.0031512542627751827,
                -0.004706866107881069,
                0.01429942436516285,
                -0.010600934736430645,
                -0.0014165134634822607,
                0.0033114859834313393,
                -0.026212375611066818,
                0.00033186323707923293,
                0.04606817662715912,
                -0.022524915635585785,
                -0.04993373155593872,
                0.0017128668259829283,
                -0.016752490773797035,
                0.01251006405800581,
                0.014586590230464935,
                0.07265426963567734,
                -0.0016377537976950407,
                0.01188112422823906,
                -0.057594817131757736,
                -0.027447110041975975,
                0.010108241811394691,
                -0.037495944648981094,
                0.014847256243228912,
                0.01594732515513897,
                -0.011699363589286804,
                0.023237043991684914,
                -0.08411238342523575,
                0.010783860459923744,
                0.05484925955533981,
                0.03383447602391243,
                -0.06735416501760483,
                0.024997036904096603,
                -0.006560595240443945,
                0.05912308022379875,
                0.00042185059282928705,
                0.0017148681217804551,
                0.057530973106622696,
                0.01949171908199787,
                0.06430315971374512,
                0.00849885679781437,
                -0.029345473274588585,
                -0.05449048802256584,
                0.0029640940483659506,
                -0.012674140743911266,
                -0.005498157348483801,
                0.08986900001764297,
                -0.005515096243470907,
                -0.0500158816576004,
                0.10746122896671295,
                -0.015796132385730743,
                -0.01628374494612217,
                -0.0069964975118637085,
                0.01894856058061123,
                -0.06770621985197067,
                -0.0190612580627203,
                0.00793536752462387,
                -0.009581093676388264,
                0.044688839465379715,
                -0.010958217084407806,
                -0.0020997945684939623,
                -0.05194688215851784,
                -0.027022937312722206,
                -0.04614638909697533,
                0.03908706083893776,
                0.059537459164857864,
                -0.027022406458854675,
                0.006158605217933655,
                -0.0768093541264534,
                -1.730158119350301e-32,
                0.03528092801570892,
                0.022059516981244087,
                -0.018010996282100677,
                -0.027064841240644455,
                -0.02653360366821289,
                -0.05503051355481148,
                0.04159459099173546,
                -0.02749549224972725,
                0.043656058609485626,
                -0.00019660104590002447,
                -0.034188613295555115,
                -0.061675556004047394,
                0.08859048783779144,
                0.030939098447561264,
                -0.0032329449895769358,
                -0.06554336100816727,
                0.03795536234974861,
                -0.005585714243352413,
                0.03275179862976074,
                -0.005317466799169779,
                -0.05043720453977585,
                -0.024948665872216225,
                -0.01248104777187109,
                -0.010867049917578697,
                -0.019292419776320457,
                0.017033228650689125,
                -0.05047468841075897,
                -0.058236826211214066,
                0.03986600413918495,
                -0.005010735709220171,
                -0.0396004356443882,
                0.032279692590236664,
                0.008718718774616718,
                0.04184601828455925,
                0.024127088487148285,
                0.0017391352448612452,
                0.043299317359924316,
                -0.011499935761094093,
                0.0025340374559164047,
                -0.028097141534090042,
                -0.08533523231744766,
                0.022075172513723373,
                0.015037428587675095,
                -0.09686551243066788,
                -0.005431173834949732,
                0.01971687562763691,
                0.02391023561358452,
                0.005778660532087088,
                0.03475715592503548,
                -0.0042331102304160595,
                -0.028772220015525818,
                0.015448767691850662,
                -0.01742612011730671,
                0.040681518614292145,
                -0.0020328962709754705,
                0.018227776512503624,
                0.004681279417127371,
                0.023332741111516953,
                0.03626178577542305,
                0.008099662140011787,
                0.018003972247242928,
                0.013520090840756893,
                0.05929096043109894,
                0.06149018183350563,
                -0.0018482997547835112,
                0.036634307354688644,
                -0.016218360513448715,
                0.055421456694602966,
                0.02500648982822895,
                -0.013119767419993877,
                -0.031167376786470413,
                0.0499003604054451,
                -0.002872346667572856,
                -0.02454444393515587,
                -0.01710682548582554,
                -0.05873878300189972,
                -0.024875743314623833,
                0.026413239538669586,
                0.06883824616670609,
                -0.017891239374876022,
                -0.036234691739082336,
                0.04582807049155235,
                0.04272537678480148,
                0.0017674289410933852,
                0.0018298695795238018,
                -0.06317424029111862,
                0.002034740522503853,
                -0.02380634844303131,
                -0.0019296574173495173,
                0.04175703227519989,
                -0.052683863788843155,
                0.04525110125541687,
                -0.060833532363176346,
                -0.02860703505575657,
                -0.022842764854431152,
                -0.07093805819749832,
                0.00788680836558342,
                -0.04167364910244942,
                0.002529716119170189,
                -0.01452034804970026,
                -0.0038260684814304113,
                -0.03441690281033516,
                0.0025128161069005728,
                0.043621644377708435,
                -0.014509053900837898,
                0.013221640139818192,
                0.011325109750032425,
                0.0009567454108037055,
                -0.015492920763790607,
                -0.003075995249673724,
                -0.02272666059434414,
                0.004478803835809231,
                0.03148900717496872,
                -0.023501772433519363,
                0.028348876163363457,
                0.012995311059057713,
                0.03040008619427681,
                -0.06870255619287491,
                0.0010267093311995268,
                0.0056553250178694725,
                0.09587330371141434,
                -0.0020150355994701385,
                0.0075897653587162495,
                0.039577189832925797,
                -0.03742733597755432,
                0.01763203553855419,
                0.0028627458959817886,
                -0.026773445308208466,
                0.011027363128960133,
                -0.07695167511701584,
                0.022407954558730125,
                -0.03295961022377014,
                4.5349463562160963e-07,
                -0.04426289722323418,
                -0.048142578452825546,
                0.018156467005610466,
                -0.0038795487489551306,
                0.028599794954061508,
                0.014348787255585194,
                -0.005038860719650984,
                0.037203967571258545,
                -0.0015346520813181996,
                -0.03904343396425247,
                0.0263198371976614,
                -0.06350988149642944,
                0.02455391176044941,
                -0.007087626960128546,
                -0.013716638088226318,
                0.03182315081357956,
                -0.02466454729437828,
                -0.032650403678417206,
                -0.0007668084581382573,
                -0.034429874271154404,
                0.09304381906986237,
                0.022653747349977493,
                0.03231470286846161,
                0.04472662881016731,
                -0.007484799716621637,
                -0.05654448643326759,
                0.014933639205992222,
                -0.03520038723945618,
                -0.023135541006922722,
                -0.010842367075383663,
                -0.07612742483615875,
                0.028194786980748177,
                -0.04507141187787056,
                -0.03395090252161026,
                -0.014637324027717113,
                0.020044617354869843,
                0.03170362859964371,
                0.00014150857168715447,
                0.01661676913499832,
                -0.02081580087542534,
                -0.07335412502288818,
                -0.06850661337375641,
                -0.024285418912768364,
                -0.018199952319264412,
                0.024370674043893814,
                -0.029325706884264946,
                0.0034502651542425156,
                0.004042342305183411,
                -0.02834298275411129,
                0.013160069473087788,
                -0.02183876931667328,
                0.00541174691170454,
                -0.005932594183832407,
                0.02486509643495083,
                -0.016246624290943146,
                -0.04698701947927475,
                0.009881804697215557,
                -0.004528544377535582,
                -0.01922021433711052,
                -0.016330735757946968,
                -0.023822886869311333,
                0.029560789465904236,
                -0.031946662813425064,
                0.02090991474688053,
                -0.003000350669026375,
                -0.044975150376558304,
                -0.017595702782273293,
                -1.2820022894070708e-34,
                0.05678420141339302,
                0.018224170431494713,
                -0.018970364704728127,
                0.004657310899347067,
                -0.03676501661539078,
                -0.022326216101646423,
                0.017543325200676918,
                -0.00906522199511528,
                -0.0019386187195777893,
                -0.05036444589495659,
                -0.019049443304538727
            ]
        },
        "Experience": {
            "name": "Hello! I currently live in the UK after moving from Sweden this summer (originally from the US). \n\nAfter almost 20 years of diverse roles and experiences, I consider myself a \"Professional Generalist\". It means I can deliver value in any kind of environment, with any kind of stakeholder and respond to any kind of challenge. ",
            "rep": [
                0.035045236349105835,
                0.041678160429000854,
                -0.011548019014298916,
                -0.017518335953354836,
                0.017799127846956253,
                -0.05342229828238487,
                0.00362787046469748,
                -0.019775155931711197,
                -0.018998995423316956,
                0.021964101120829582,
                0.06303022801876068,
                -0.02245306596159935,
                -0.055162832140922546,
                0.053490739315748215,
                0.06042966991662979,
                -0.07938139140605927,
                0.049123719334602356,
                -0.01682705618441105,
                0.04025176912546158,
                -0.02782384492456913,
                -0.014963923953473568,
                0.03248044475913048,
                -0.018843984231352806,
                0.054410986602306366,
                0.012884038500487804,
                0.04826114699244499,
                0.023504404351115227,
                -0.014845096506178379,
                0.07603123784065247,
                0.11530446261167526,
                0.06535210460424423,
                -0.027439989149570465,
                -0.001413632184267044,
                0.0028246757574379444,
                1.5906214230199112e-06,
                0.03670103847980499,
                -0.0038702283054590225,
                -0.020580466836690903,
                0.04947596415877342,
                -0.0680696964263916,
                -0.011224803514778614,
                0.02776155434548855,
                -0.025237536057829857,
                0.04759439453482628,
                -0.02208017185330391,
                -0.06613681465387344,
                0.015519239008426666,
                -0.044474177062511444,
                -0.028679154813289642,
                0.010334420017898083,
                0.0007219377439469099,
                -0.0542769655585289,
                -0.020151576027274132,
                -0.022472502663731575,
                -0.10129661858081818,
                -0.024874798953533173,
                -0.017380235716700554,
                -0.020323723554611206,
                0.034096814692020416,
                0.018902920186519623,
                -0.017415132373571396,
                -0.008293265476822853,
                -0.012131989002227783,
                -0.01747737266123295,
                0.02880060113966465,
                0.04040263220667839,
                -0.023280005902051926,
                0.014940156601369381,
                -0.008814467117190361,
                -0.009683553129434586,
                0.09088084101676941,
                0.033034611493349075,
                -0.03977513685822487,
                0.03103976882994175,
                0.05625680088996887,
                -0.0029144745785743,
                0.035796504467725754,
                0.03961653262376785,
                -0.03975485637784004,
                0.011866103857755661,
                -0.03912991285324097,
                0.035269901156425476,
                -0.04186154529452324,
                -0.018431633710861206,
                -0.07832358032464981,
                -0.003954136744141579,
                -0.014348620548844337,
                0.043953992426395416,
                0.007225563284009695,
                -0.009012633934617043,
                -0.05912903696298599,
                0.059057194739580154,
                0.025189045816659927,
                0.01806010492146015,
                0.0043539199978113174,
                -0.03463966026902199,
                0.04834028705954552,
                0.029497180134058,
                -0.015325075015425682,
                0.02936076745390892,
                -0.01713358238339424,
                0.03099829889833927,
                0.00176859216298908,
                0.020021596923470497,
                0.04154709726572037,
                0.04328230023384094,
                -0.02616110071539879,
                0.024579279124736786,
                -0.05132860317826271,
                -0.047932498157024384,
                -0.0554003082215786,
                0.020839091390371323,
                0.03968854621052742,
                0.02310238778591156,
                0.010220397263765335,
                0.014024234376847744,
                -0.0016845714999362826,
                -0.03989981487393379,
                -0.019314374774694443,
                0.05615135282278061,
                -0.0018647792749106884,
                0.0145899448543787,
                0.02437550015747547,
                0.005095861852169037,
                0.010155481286346912,
                0.03737186640501022,
                -0.05901574715971947,
                0.0266323983669281,
                -0.03692047297954559,
                -0.07938983291387558,
                0.03437064588069916,
                -0.009098140522837639,
                -0.00941755436360836,
                -0.03152799978852272,
                0.017544526606798172,
                -0.005017847288399935,
                -0.043563682585954666,
                0.013952638953924179,
                -0.0004095104814041406,
                -0.008129984140396118,
                0.005194594152271748,
                -0.004328601993620396,
                -0.0030824923887848854,
                0.03187504783272743,
                0.009189857169985771,
                -0.02399025857448578,
                -0.0029916169587522745,
                -0.03607552498579025,
                -0.02007594332098961,
                -0.005646923091262579,
                0.014409503899514675,
                -0.0388626828789711,
                -0.01628766395151615,
                0.02960260957479477,
                0.024665910750627518,
                0.03960571438074112,
                0.016444992274045944,
                0.026112433522939682,
                -0.01605103723704815,
                0.006640386767685413,
                -0.0019257271196693182,
                -0.055394306778907776,
                0.03249309957027435,
                0.029046177864074707,
                -0.0749129056930542,
                -0.024318426847457886,
                0.08177618682384491,
                0.009720353409647942,
                0.015166623517870903,
                -0.022943682968616486,
                0.07730559259653091,
                -0.017028411850333214,
                -0.028131578117609024,
                0.0493975393474102,
                0.0533163957297802,
                -0.06324848532676697,
                0.032275568693876266,
                0.04765519127249718,
                0.05841365456581116,
                -0.0011199795408174396,
                -0.004150625318288803,
                -0.10729783773422241,
                0.00031284530996344984,
                -0.059161022305488586,
                0.046960026025772095,
                0.046836007386446,
                -0.03387562185525894,
                0.0596601776778698,
                0.012448204681277275,
                0.015033384785056114,
                0.036599792540073395,
                0.06299740821123123,
                -0.020331092178821564,
                0.07130822539329529,
                -0.0059176343493163586,
                -0.028494667261838913,
                -0.01872067339718342,
                -0.019404102116823196,
                -0.06568002700805664,
                -0.05320051312446594,
                -0.011145614087581635,
                0.010988623835146427,
                -0.051051609218120575,
                0.030112339183688164,
                -0.021585840731859207,
                -0.02754184789955616,
                -0.01593072898685932,
                -0.04364997521042824,
                -0.021984463557600975,
                0.052284929901361465,
                0.06300274282693863,
                0.0192593764513731,
                -0.0033953101374208927,
                0.0317838229238987,
                -0.030042504891753197,
                -0.029694879427552223,
                -0.012425734661519527,
                0.03412248566746712,
                0.05198834836483002,
                0.09811274707317352,
                0.019412748515605927,
                -0.036081742495298386,
                0.03922915458679199,
                -0.04605631157755852,
                -0.008989578112959862,
                0.0071168327704072,
                0.04858947917819023,
                -0.04475215822458267,
                -0.020193416625261307,
                0.05064995959401131,
                -0.031104115769267082,
                0.01781318336725235,
                0.004351646639406681,
                -0.045413218438625336,
                0.019196538254618645,
                0.022531183436512947,
                0.038623105734586716,
                0.08946148306131363,
                0.009704571217298508,
                -0.07998939603567123,
                0.011313391849398613,
                0.0019010911928489804,
                -0.015364689752459526,
                0.016584690660238266,
                -0.04758836328983307,
                0.061613406985998154,
                0.03253674879670143,
                -0.01684814877808094,
                -0.051581017673015594,
                -0.01223031710833311,
                0.017902754247188568,
                0.030590912327170372,
                -0.051890455186367035,
                0.01294119656085968,
                -0.04962152615189552,
                -0.021436134353280067,
                -0.013191279023885727,
                -0.012532884255051613,
                -0.060941535979509354,
                -0.007590921595692635,
                0.004328923299908638,
                0.006917507387697697,
                -0.008061299100518227,
                0.0323902852833271,
                0.018338238820433617,
                -0.004925733897835016,
                0.06015684828162193,
                -0.06493540108203888,
                0.003269945504143834,
                0.019857250154018402,
                0.00862154085189104,
                0.005858791526407003,
                -0.01711023785173893,
                -0.024893254041671753,
                0.004327442497014999,
                -0.04015031456947327,
                0.003007277613505721,
                0.02839580923318863,
                -0.01924406923353672,
                0.01061652135103941,
                0.030474737286567688,
                0.006524111144244671,
                -0.0011246161302551627,
                0.027501536533236504,
                0.025111354887485504,
                0.0035210212226957083,
                -0.0062671019695699215,
                0.018206289038062096,
                -0.012860702350735664,
                -0.05430791899561882,
                -0.05075353384017944,
                -0.013874554075300694,
                -0.010569869540631771,
                -0.03977431356906891,
                -0.03444024175405502,
                0.043386369943618774,
                -0.008428771048784256,
                -0.05682556331157684,
                -0.019666310399770737,
                -0.034420136362314224,
                -0.032386671751737595,
                0.004540655761957169,
                0.05463956668972969,
                0.0033905403688549995,
                -0.005196830257773399,
                -0.009636959992349148,
                0.022659022361040115,
                -0.014467387460172176,
                -0.05588135868310928,
                0.03402615711092949,
                0.010110878385603428,
                0.019628824666142464,
                0.007734285201877356,
                0.013059391640126705,
                0.012715308926999569,
                -0.003942310344427824,
                -0.065080426633358,
                -0.01976802945137024,
                0.06233809515833855,
                0.07418065518140793,
                0.008628401905298233,
                0.017528483644127846,
                -0.03362157195806503,
                0.012071412056684494,
                0.034736838191747665,
                0.04635700210928917,
                -0.04774104058742523,
                -0.0010204357095062733,
                0.044601403176784515,
                -0.05751040205359459,
                0.018936995416879654,
                -0.009610160253942013,
                -0.01656329073011875,
                -0.011195284314453602,
                -0.009542468003928661,
                -0.07078126817941666,
                -0.03402034938335419,
                -0.002341188257560134,
                -0.05801674723625183,
                -0.02536572515964508,
                -0.027346797287464142,
                -0.005762388464063406,
                -0.03910204768180847,
                0.02583054080605507,
                -0.007635738700628281,
                0.049418263137340546,
                0.02387685514986515,
                0.019147677347064018,
                -0.0244718287140131,
                0.009166641160845757,
                -0.0025662805419415236,
                0.09507390856742859,
                -0.0034776830580085516,
                0.019540969282388687,
                -0.00819334015250206,
                -0.02317187190055847,
                -0.022393442690372467,
                0.09418503940105438,
                -0.01482302788645029,
                -0.012118184007704258,
                -0.1487744152545929,
                0.011622770689427853,
                -0.039331063628196716,
                -0.021585853770375252,
                -0.014560321345925331,
                -0.03378310054540634,
                0.033927030861377716,
                -0.020803356543183327,
                -0.03783547878265381,
                -0.000899601262062788,
                -0.014253783039748669,
                -0.02845746837556362,
                -0.056918393820524216,
                -0.028787650167942047,
                -0.06638380885124207,
                -0.0431935153901577,
                -0.018595395609736443,
                0.010466122068464756,
                0.02294270135462284,
                0.014120093546807766,
                0.06755154579877853,
                -9.524333290755749e-05,
                0.07195237278938293,
                -0.0557049997150898,
                -0.0048342179507017136,
                -0.031207649037241936,
                0.01967289112508297,
                -0.03158261999487877,
                0.024058237671852112,
                0.01509354542940855,
                0.05813656374812126,
                -0.04231701046228409,
                0.003955789841711521,
                0.07759633660316467,
                -0.005210601259022951,
                0.009927853010594845,
                -0.0004353767726570368,
                -0.001545873936265707,
                -0.01732274703681469,
                0.011437950655817986,
                -0.012826036661863327,
                -0.08525949716567993,
                -0.023048795759677887,
                0.0031712830532342196,
                -0.02293449267745018,
                -0.07574906945228577,
                0.10526324063539505,
                -0.060032498091459274,
                -0.00964374840259552,
                0.003073515137657523,
                -0.02645309641957283,
                0.017658915370702744,
                0.026874812319874763,
                0.06316603720188141,
                -0.04782595857977867,
                -0.04809439182281494,
                0.01082539837807417,
                0.020559273660182953,
                -0.021272234618663788,
                -0.05668092891573906,
                0.0369228720664978,
                0.0388982817530632,
                0.05288535729050636,
                0.016400067135691643,
                -0.0011640185257419944,
                -0.007069629151374102,
                0.05176759883761406,
                0.024033743888139725,
                0.0036737858317792416,
                -0.04428990185260773,
                -0.01549446303397417,
                0.0106856944039464,
                0.03002202697098255,
                0.039142902940511703,
                0.004737992770969868,
                -0.0131586454808712,
                0.026982363313436508,
                0.033427998423576355,
                -0.01350487396121025,
                0.059410665184259415,
                0.010088659822940826,
                0.0625835582613945,
                0.03475263714790344,
                0.00013799678708892316,
                -0.023318324238061905,
                -0.011445741169154644,
                -0.046790383756160736,
                -0.0015815915539860725,
                -0.047366250306367874,
                -0.06354598701000214,
                0.06707912683486938,
                0.01320065837353468,
                -0.04587169364094734,
                0.030892938375473022,
                0.018212810158729553,
                -0.04909481853246689,
                -0.04206676781177521,
                -0.026072153821587563,
                -0.0011107095051556826,
                -0.006808973848819733,
                0.0200172308832407,
                0.023536885157227516,
                0.017194824293255806,
                0.08487685769796371,
                0.028595445677638054,
                0.0607653371989727,
                0.023740051314234734,
                0.0009543911437503994,
                0.03926856070756912,
                -0.035908740013837814,
                0.007688687648624182,
                -0.05581212416291237,
                -0.05524077266454697,
                0.018019933253526688,
                -0.04932968318462372,
                0.01326584629714489,
                0.01667340099811554,
                0.035873960703611374,
                0.014753689989447594,
                0.006031700875610113,
                -0.023708147928118706,
                -0.01433643326163292,
                -0.028436867520213127,
                -0.01629907637834549,
                0.004407728090882301,
                -0.04022837057709694,
                0.00921302568167448,
                0.03478584066033363,
                -0.03260812163352966,
                -0.05526779219508171,
                0.0389808751642704,
                -0.04941374436020851,
                0.03173447400331497,
                0.05343405157327652,
                -0.014950301498174667,
                0.03933330625295639,
                -0.04592170938849449,
                -0.0019344065804034472,
                0.008523175492882729,
                -0.022180605679750443,
                -0.017427125945687294,
                -0.006241165101528168,
                -0.021844221279025078,
                -0.028031539171934128,
                0.05583199858665466,
                0.005741391330957413,
                0.0032916127238422632,
                0.019303563982248306,
                -0.012498181313276291,
                0.02879980392754078,
                -0.008514299057424068,
                -0.009366613812744617,
                -0.006077546626329422,
                -0.09272845089435577,
                0.048958905041217804,
                -0.0018217941978946328,
                0.005828869994729757,
                -0.010084955021739006,
                -0.03921676427125931,
                0.004112335853278637,
                0.03210976719856262,
                0.011642459779977798,
                -0.013518656603991985,
                -0.000342451996402815,
                -0.014532925561070442,
                0.009947688318789005,
                -0.02068689465522766,
                0.0009674584143795073,
                -0.029229631647467613,
                0.04220925644040108,
                0.010630549862980843,
                -0.008906984701752663,
                -0.053823504596948624,
                0.010975510813295841,
                -0.009643456898629665,
                -0.009809142909944057,
                -0.0054898071102797985,
                0.014613057486712933,
                0.030520958825945854,
                0.030159393325448036,
                -0.020444881170988083,
                0.017527621239423752,
                -0.0006283162510953844,
                -0.0358436144888401,
                -0.04437914490699768,
                -0.013202494010329247,
                -0.008966253139078617,
                0.03788074478507042,
                0.03156657889485359,
                -0.057022225111722946,
                -0.008977663703262806,
                -0.02460470236837864,
                -0.01050025224685669,
                0.05966828390955925,
                -0.013110768981277943,
                -5.2446208604592426e-33,
                -0.02781391330063343,
                -0.025625567883253098,
                0.03163294494152069,
                -0.037071503698825836,
                -0.07735422253608704,
                -0.0326685756444931,
                0.06462714076042175,
                -0.02279440499842167,
                -0.023361049592494965,
                0.0011749811237677932,
                -0.028250716626644135,
                0.01202402263879776,
                0.0180654413998127,
                0.007732737809419632,
                -0.009882938116788864,
                0.025878725573420525,
                0.02568609081208706,
                0.06478403508663177,
                0.055867455899715424,
                -0.0006904582842253149,
                -0.03077222965657711,
                -0.021578066051006317,
                0.012790834531188011,
                -0.025628870353102684,
                0.03302409127354622,
                0.029236668720841408,
                -0.04367244616150856,
                0.0024894254747778177,
                0.04425607994198799,
                -0.04678596183657646,
                0.012676185928285122,
                0.013125850819051266,
                0.0009313963237218559,
                0.009359918534755707,
                -0.014061445370316505,
                0.020558204501867294,
                -0.03713880106806755,
                0.05271298065781593,
                0.020696096122264862,
                -0.028404168784618378,
                -0.0467933751642704,
                -0.019230490550398827,
                0.030285127460956573,
                -0.055910978466272354,
                -0.04417011886835098,
                0.008052781224250793,
                0.023512644693255424,
                -0.01031162217259407,
                0.004577701911330223,
                0.0044856262393295765,
                -0.04291443154215813,
                -0.053754474967718124,
                -0.07165566086769104,
                0.11636348813772202,
                -0.09416650235652924,
                -0.02025972679257393,
                -0.010653512552380562,
                0.022670868784189224,
                0.011440272442996502,
                -0.008501468226313591,
                0.03413137421011925,
                -0.04986284300684929,
                0.03967729210853577,
                0.026191582903265953,
                -0.009088174439966679,
                -0.009534601122140884,
                0.01799984648823738,
                0.10325349122285843,
                -0.06909115612506866,
                -0.04388516768813133,
                -0.012593108229339123,
                0.000570272037293762,
                -0.02563437819480896,
                0.029885729774832726,
                -0.07317637652158737,
                0.01351528987288475,
                0.011240464635193348,
                0.003443292109295726,
                0.07085905969142914,
                0.006414192263036966,
                -0.06742668151855469,
                -0.021523477509617805,
                0.053463153541088104,
                -0.023832131177186966,
                -0.00754960160702467,
                -0.044685978442430496,
                0.008242242969572544,
                -0.005012228153645992,
                0.017095010727643967,
                -0.022120723500847816,
                0.03070610575377941,
                0.02950885333120823,
                -0.00809950940310955,
                -0.010793411172926426,
                0.0800551325082779,
                -0.060071852058172226,
                -0.017832525074481964,
                -0.027101876214146614,
                -0.014597326517105103,
                0.04295092076063156,
                -0.010808197781443596,
                0.01293880119919777,
                -0.07740113884210587,
                -0.013665731064975262,
                -0.019707785919308662,
                0.058595214039087296,
                -0.03392496705055237,
                -0.027225174009799957,
                -0.055994898080825806,
                0.00941013265401125,
                0.005237461533397436,
                0.044395964592695236,
                -0.010902955196797848,
                0.0016659107059240341,
                0.02070366032421589,
                -0.03344680368900299,
                0.03091198019683361,
                0.04065863788127899,
                -0.0023506064899265766,
                0.044746868312358856,
                0.025230783969163895,
                -0.023254087194800377,
                -0.008513347245752811,
                0.045492932200431824,
                -0.010272026993334293,
                -0.011314896866679192,
                0.031123997643589973,
                -0.01278440747410059,
                -0.013858531601727009,
                0.008667152374982834,
                0.008641421794891357,
                -0.024932490661740303,
                2.1963592189422343e-07,
                0.0279534999281168,
                -0.048508595675230026,
                -0.033316489309072495,
                0.01870206743478775,
                0.017660876736044884,
                -0.07166480273008347,
                0.04513823613524437,
                -0.0090804323554039,
                -0.04532162845134735,
                0.012957988306879997,
                0.020249532535672188,
                -0.0011195397237315774,
                -0.031919825822114944,
                -0.02478596568107605,
                -0.03388340026140213,
                0.009182986803352833,
                -0.03625594824552536,
                0.038133855909109116,
                -0.02939007431268692,
                0.012997247278690338,
                -0.01236520241945982,
                -0.009804798290133476,
                -0.02089018188416958,
                0.01036633551120758,
                0.02376183308660984,
                -0.0010036459425464272,
                0.036020271480083466,
                -0.032472945749759674,
                0.032546449452638626,
                0.058399446308612823,
                0.04175441339612007,
                -0.02939625270664692,
                0.04631936550140381,
                0.004916542209684849,
                0.030973708257079124,
                -0.01960083283483982,
                0.013205425813794136,
                0.08482035249471664,
                -0.006015175022184849,
                0.06769271939992905,
                -0.0333208404481411,
                0.005600161850452423,
                0.0322396494448185,
                -0.026631301268935204,
                0.00011361395445419475,
                -0.027727892622351646,
                -0.0066494508646428585,
                0.018457312136888504,
                -0.04005766659975052,
                -0.02977309562265873,
                -0.003029624233022332,
                -0.013807184062898159,
                -0.019239449873566628,
                0.016394199803471565,
                0.022302985191345215,
                -0.012582390569150448,
                -0.007105675060302019,
                0.0450841523706913,
                0.08211356401443481,
                -0.022852260619401932,
                -0.01830723136663437,
                0.007914447225630283,
                -0.019748179242014885,
                -0.024306077510118484,
                0.04669532924890518,
                0.039668355137109756,
                0.005185550078749657,
                1.6265591986760168e-34,
                -0.009050906635820866,
                0.03463218733668327,
                -0.04850281774997711,
                0.033594440668821335,
                0.07133560627698898,
                -0.019304610788822174,
                -0.02315874770283699,
                0.05182791128754616,
                0.044584695249795914,
                0.03368141129612923,
                -0.010370318777859211
            ]
        },
        "Freelance": "Staff",
        "Id": "fdb54ac6-6f9a-46d4-b3c7-e24a1ece1dc4",
        "Industry": {
            "name": "\tBusiness",
            "rep": [
                0.043107323348522186,
                0.041932884603738785,
                -0.007384799420833588,
                0.03133811429142952,
                0.01970391906797886,
                -0.0036362381651997566,
                0.05262527987360954,
                -0.004365634638816118,
                -0.039470698684453964,
                0.020421190187335014,
                0.051629818975925446,
                -0.02408663183450699,
                0.02999422885477543,
                0.08117306232452393,
                0.01334631908684969,
                0.022682562470436096,
                -0.0024797176010906696,
                0.01849457062780857,
                0.01242954470217228,
                -0.015798307955265045,
                -0.02742321789264679,
                0.030465908348560333,
                0.05463472381234169,
                -0.028198128566145897,
                0.02104838751256466,
                -0.009543216787278652,
                0.04667770117521286,
                0.0037125470116734505,
                -0.01776972971856594,
                0.019273880869150162,
                0.06287536025047302,
                -0.015326930209994316,
                0.018795233219861984,
                -0.021578602492809296,
                2.077298631775193e-06,
                -0.026611952111124992,
                -0.0400216169655323,
                0.009770991280674934,
                -0.08688123524188995,
                -0.014089783653616905,
                0.0028935412410646677,
                0.05636492371559143,
                -0.03369859606027603,
                0.04594637453556061,
                -0.02204824611544609,
                0.012847892940044403,
                0.013829309493303299,
                0.01775244250893593,
                -0.03140269219875336,
                0.01967070810496807,
                -0.007183928042650223,
                -0.04386594519019127,
                -0.1089373454451561,
                -0.04829937219619751,
                -0.0770169198513031,
                -0.04506230726838112,
                0.0465955026447773,
                0.013136080466210842,
                -0.009590242058038712,
                0.01163342036306858,
                0.005412649363279343,
                -0.03132571280002594,
                -0.015676310285925865,
                0.033974528312683105,
                0.04096580296754837,
                0.041222840547561646,
                0.08266788721084595,
                -0.021341724321246147,
                0.02427002042531967,
                -0.0015009143389761448,
                0.006424320861697197,
                0.031750962138175964,
                -0.008059381507337093,
                0.09565281867980957,
                0.0022320891730487347,
                -0.0860120952129364,
                -0.01965969242155552,
                0.04132649302482605,
                0.014047067612409592,
                0.006893696263432503,
                -0.02979961782693863,
                0.021641826257109642,
                0.0019229177851229906,
                0.08527813106775284,
                0.001888760831207037,
                0.0044697304256260395,
                -0.03033641166985035,
                0.010241765528917313,
                -0.022100625559687614,
                -0.009258787147700787,
                0.025856759399175644,
                -0.0209158044308424,
                0.03013797104358673,
                0.012253062799572945,
                0.009570572525262833,
                -0.03143993020057678,
                0.017288485541939735,
                0.10513869673013687,
                -0.0186112429946661,
                -0.03476894646883011,
                -0.0515577532351017,
                -0.002859254367649555,
                0.013888456858694553,
                0.025807827711105347,
                -0.007817711681127548,
                0.011807029135525227,
                0.021564124152064323,
                -0.024972446262836456,
                -0.017075713723897934,
                0.0754738375544548,
                -0.02112860046327114,
                0.0005567100015468895,
                -0.06857258826494217,
                0.042555149644613266,
                -0.04945015534758568,
                -0.01092981081455946,
                -0.023148978129029274,
                0.05002657696604729,
                -0.04332387447357178,
                0.03944888710975647,
                0.056029774248600006,
                0.029562469571828842,
                -0.009706632234156132,
                0.023172728717327118,
                0.024552389979362488,
                0.002022320404648781,
                -0.05924983322620392,
                0.030616454780101776,
                0.02830566093325615,
                0.013042998500168324,
                -0.0008048537420108914,
                -0.0006195840542204678,
                -0.002275100676342845,
                -0.022134611383080482,
                0.03921809792518616,
                -0.014871159568428993,
                0.002598281716927886,
                0.026318706572055817,
                -0.07985575497150421,
                -0.04173915460705757,
                -0.037058718502521515,
                -0.05809018015861511,
                -0.02994328923523426,
                0.024781228974461555,
                -0.02037348784506321,
                -0.004169674124568701,
                0.01704566553235054,
                0.05270786210894585,
                0.005074852611869574,
                -0.013558430597186089,
                -0.009171097539365292,
                0.010182513855397701,
                -0.00787291955202818,
                -0.023794053122401237,
                0.0852428749203682,
                0.03535729646682739,
                0.0957665964961052,
                -0.022520793601870537,
                -0.0020210030488669872,
                -0.011496715247631073,
                0.02251202054321766,
                0.016831664368510246,
                0.005957904737442732,
                0.009586905129253864,
                -0.03636515885591507,
                -0.012218842282891273,
                0.06791388988494873,
                0.0321865975856781,
                -0.06582581996917725,
                -0.010881168767809868,
                -0.0044505055993795395,
                -0.05956219509243965,
                -0.06468488276004791,
                0.1075667291879654,
                0.014296788722276688,
                -0.047451529651880264,
                -0.004842291120439768,
                0.027141768485307693,
                -0.07672885060310364,
                0.008428610861301422,
                -0.032659221440553665,
                -0.1363135278224945,
                -0.009255154058337212,
                -0.001253583119250834,
                0.0020028913859277964,
                0.01258697360754013,
                0.033433184027671814,
                0.006835915148258209,
                -0.04337867349386215,
                0.003463281784206629,
                0.0036154838744550943,
                -0.0008185029728338122,
                0.013840720057487488,
                0.02082054503262043,
                -0.002762546995654702,
                -0.03024515137076378,
                -0.024322429671883583,
                0.05369587615132332,
                -0.04874667897820473,
                -0.02007445879280567,
                0.0012660769280046225,
                -0.03997759893536568,
                -0.015860553830862045,
                0.08575406670570374,
                -0.030645158141851425,
                0.06206098571419716,
                0.04407645761966705,
                0.021415891125798225,
                -0.06366708129644394,
                -0.008666434325277805,
                0.020269114524126053,
                0.030221261084079742,
                0.0026773586869239807,
                0.03447911888360977,
                -0.003316800808534026,
                -0.013421344570815563,
                0.013475045561790466,
                -0.008642790839076042,
                -0.04215981066226959,
                0.022595079615712166,
                -0.018725521862506866,
                0.0423554889857769,
                0.04455750435590744,
                -0.049621935933828354,
                0.018544290214776993,
                -0.05648425593972206,
                -0.015843763947486877,
                0.027754714712500572,
                0.0028636876959353685,
                -0.005218257661908865,
                0.04400593787431717,
                0.025157148018479347,
                -0.0010876989690586925,
                0.011288043111562729,
                0.02484370768070221,
                0.0038867532275617123,
                0.11951575428247452,
                0.04289862513542175,
                0.054723452776670456,
                0.0012507227947935462,
                0.0028947042301297188,
                -0.022893168032169342,
                0.009226279333233833,
                -0.011023249477148056,
                0.07029898464679718,
                0.01945492997765541,
                -0.017067477107048035,
                0.021663257852196693,
                -0.040564749389886856,
                0.021006472408771515,
                -0.04554687440395355,
                -0.023189324885606766,
                0.012650547549128532,
                0.007587475702166557,
                0.0161401629447937,
                -0.0567270889878273,
                -0.05327456071972847,
                0.04769948869943619,
                0.0064982641488313675,
                -0.004544309340417385,
                0.03478100523352623,
                -0.03953041508793831,
                0.0037321005947887897,
                0.033888258039951324,
                0.030335500836372375,
                -0.019627133384346962,
                0.062427300959825516,
                -0.040479160845279694,
                -0.01684439927339554,
                -0.03679591044783592,
                -0.016245564445853233,
                0.029107170179486275,
                0.038823530077934265,
                -0.005089794751256704,
                -0.0026451495941728354,
                -0.021543927490711212,
                0.0034107747487723827,
                0.005453832447528839,
                -0.03515035659074783,
                -0.010635974816977978,
                0.00613396568223834,
                -0.03280395641922951,
                -0.028235211968421936,
                0.030427254736423492,
                0.001913390588015318,
                -0.019625745713710785,
                0.03859570622444153,
                -0.008711477741599083,
                -0.0589090958237648,
                -0.05156451463699341,
                -0.059981655329465866,
                -0.017016882076859474,
                0.015381486155092716,
                -0.04763094335794449,
                -0.00037242964026518166,
                -0.0073051126673817635,
                0.007578738499432802,
                -0.03397774696350098,
                -0.009085933677852154,
                -0.019110718742012978,
                0.0028844443149864674,
                -0.018102452158927917,
                0.04205754026770592,
                -0.04193631187081337,
                -0.05091720446944237,
                0.034470800310373306,
                -0.01321905292570591,
                -0.019433990120887756,
                -0.014806805178523064,
                -0.009398473426699638,
                -0.010450645349919796,
                0.014110884629189968,
                0.013367303647100925,
                0.051350824534893036,
                0.030998682603240013,
                0.03162562847137451,
                -0.04717695340514183,
                -0.02399585023522377,
                -0.019342441111803055,
                0.14043225347995758,
                0.02841510996222496,
                0.03177206590771675,
                -0.040888626128435135,
                0.00505260331556201,
                0.004542283248156309,
                0.01810428500175476,
                0.036609139293432236,
                0.01213595736771822,
                0.022131886333227158,
                -0.07659167051315308,
                -0.029830465093255043,
                0.014486575499176979,
                -0.04905683547258377,
                -0.033782780170440674,
                -0.0008294355939142406,
                0.048848435282707214,
                -0.05252404510974884,
                0.0019834255799651146,
                -0.03523968905210495,
                0.003321596886962652,
                0.022522641345858574,
                -0.002701063407585025,
                -0.05457733944058418,
                -0.0003585684753488749,
                -0.049212221056222916,
                -0.0017537608509883285,
                0.04137158393859863,
                0.030241359025239944,
                -0.06954644620418549,
                -0.017602290958166122,
                0.009187345393002033,
                0.03339080139994621,
                -0.0176054947078228,
                -0.018463987857103348,
                -0.0357913076877594,
                0.03261834755539894,
                -0.05818997696042061,
                0.04063328728079796,
                -0.005081491079181433,
                -0.024364959448575974,
                -0.07754458487033844,
                -0.0029603810980916023,
                -0.06378443539142609,
                0.01971299946308136,
                0.03199981153011322,
                -0.0021032688673585653,
                0.0653572529554367,
                0.05267040804028511,
                0.004108671098947525,
                0.033922288566827774,
                -0.0004220750997774303,
                0.06273698061704636,
                -0.05602861940860748,
                0.04189785197377205,
                -0.01610499434173107,
                0.07305702567100525,
                0.004189332481473684,
                -0.011047178879380226,
                -0.04040036350488663,
                0.010436678305268288,
                0.06511751562356949,
                -0.06615206599235535,
                0.03007216937839985,
                -0.01247754879295826,
                -0.0037727600429207087,
                0.04066726565361023,
                -0.006842961069196463,
                -0.04077908396720886,
                -0.01424800418317318,
                -0.0065261903218925,
                0.014763236977159977,
                -0.017544100061058998,
                -0.06882308423519135,
                0.04075204208493233,
                -0.009770478121936321,
                -0.03380289301276207,
                0.029453109949827194,
                0.0037457121070474386,
                0.009811930358409882,
                -0.018155988305807114,
                0.06516483426094055,
                0.005453921854496002,
                0.016714559867978096,
                0.04608318209648132,
                -0.03473823890089989,
                -0.0933055505156517,
                0.05831097066402435,
                0.041156962513923645,
                -0.0037696256767958403,
                0.005038077011704445,
                -0.06800302863121033,
                0.014744022861123085,
                -0.00642894534394145,
                0.027410531416535378,
                0.033773280680179596,
                -0.02021598257124424,
                0.002816571621224284,
                0.00372785865329206,
                0.02054906263947487,
                -0.08259611576795578,
                0.03403080627322197,
                -0.048062440007925034,
                -0.03447089344263077,
                0.025007197633385658,
                -0.023097408935427666,
                0.041366614401340485,
                0.04816016927361488,
                0.06054103001952171,
                0.046152353286743164,
                -0.04526788741350174,
                -0.024333730340003967,
                0.025184210389852524,
                -0.013612939044833183,
                0.027301087975502014,
                -0.03328938037157059,
                -0.005506835412234068,
                -0.04079677164554596,
                -0.04431997984647751,
                -0.05561143532395363,
                -0.016917185857892036,
                -0.010881861671805382,
                0.0878840833902359,
                -0.0012220618082210422,
                -0.013262162916362286,
                -0.054301708936691284,
                0.053733356297016144,
                0.00875532254576683,
                -0.0143484091386199,
                -0.08980163931846619,
                -0.003501849016174674,
                0.03475044295191765,
                -0.019051535055041313,
                0.01594349928200245,
                -0.09694977104663849,
                0.044139500707387924,
                -0.017393644899129868,
                -0.030267052352428436,
                -0.0782492384314537,
                0.0369136817753315,
                0.004419597797095776,
                0.004134670831263065,
                0.02743380330502987,
                -0.01914087124168873,
                -0.030142739415168762,
                0.007290435954928398,
                -0.04324782267212868,
                0.014672558754682541,
                -0.08116581290960312,
                0.02961362712085247,
                -0.02171393483877182,
                -0.0036746724508702755,
                0.024143800139427185,
                -0.007685943506658077,
                0.013898897916078568,
                0.025963423773646355,
                -0.004484840668737888,
                -0.02605167031288147,
                -0.0644858106970787,
                0.009010464884340763,
                -0.0043928856030106544,
                -0.02114139497280121,
                0.0021293654572218657,
                -0.024892523884773254,
                -0.030788525938987732,
                -0.0061631398275494576,
                -0.015382811427116394,
                0.04373703524470329,
                -0.008427205495536327,
                0.005141184199601412,
                -0.044904258102178574,
                -0.017618516460061073,
                -0.035153500735759735,
                -0.016388263553380966,
                -0.04097406566143036,
                -0.024109799414873123,
                -0.0397166833281517,
                -0.027244608849287033,
                -0.028096606954932213,
                -0.03952064737677574,
                0.0113622285425663,
                -0.012485519051551819,
                0.02529534325003624,
                0.01798711158335209,
                -0.02115781418979168,
                0.008425178937613964,
                -0.03670878708362579,
                -0.006065067835152149,
                0.03453351929783821,
                -0.017150655388832092,
                0.003995820879936218,
                0.016664618626236916,
                -0.010329598560929298,
                0.002444623736664653,
                -0.07383348792791367,
                0.0461965948343277,
                0.04765498638153076,
                -0.06808210909366608,
                -0.06310310959815979,
                0.004297349136322737,
                -0.04288017004728317,
                0.026796884834766388,
                -0.02367146499454975,
                0.032070010900497437,
                -0.015433019958436489,
                0.015225505456328392,
                -0.012278793379664421,
                0.0174541138112545,
                -0.01983826234936714,
                -0.03954020515084267,
                0.031825952231884,
                -0.03928292542695999,
                -0.03244517371058464,
                -0.01786704547703266,
                0.05752009525895119,
                -0.048951152712106705,
                0.03460917994379997,
                0.037704043090343475,
                0.09033746272325516,
                0.017036430537700653,
                0.006986812222748995,
                -0.03646613657474518,
                0.05150796100497246,
                0.002500561298802495,
                -0.011070375330746174,
                0.008487863466143608,
                -0.007171012461185455,
                0.003917864058166742,
                0.025904551148414612,
                -0.004786741454154253,
                0.047592464834451675,
                -0.026943635195493698,
                0.017456553876399994,
                -0.026506692171096802,
                0.008022048510611057,
                -0.032415971159935,
                -7.757941658532529e-33,
                0.004162705037742853,
                -0.048974744975566864,
                0.04740205034613609,
                -0.03678423538804054,
                -0.0424678660929203,
                -0.010753463953733444,
                0.04715084284543991,
                -0.00633653299883008,
                -0.05234893411397934,
                -0.03859454765915871,
                -0.03309621661901474,
                -0.01538621261715889,
                0.03804537653923035,
                0.02072739601135254,
                0.04949790984392166,
                -0.05120095983147621,
                0.002884926740080118,
                -0.005593177396804094,
                -0.006585961673408747,
                -0.005926439072936773,
                -0.00043908768566325307,
                0.008853564970195293,
                0.04930140823125839,
                0.024022215977311134,
                0.04630117863416672,
                0.0038889863062649965,
                -0.06131253018975258,
                -0.01374772284179926,
                0.030671333894133568,
                -0.016530821099877357,
                -0.034699708223342896,
                -0.0007988390862010419,
                -0.001130958553403616,
                -0.049286849796772,
                0.009472999721765518,
                0.028712686151266098,
                -0.012051170691847801,
                0.02195718325674534,
                0.007408580277115107,
                0.0030191249679774046,
                -0.06328973174095154,
                0.0020757648162543774,
                0.026350222527980804,
                -0.02657429128885269,
                0.014972880482673645,
                -0.024852657690644264,
                -0.002885672030970454,
                -0.010628570802509785,
                0.003699225839227438,
                0.0504644513130188,
                -0.04597003012895584,
                -0.02246486395597458,
                -0.02818801999092102,
                0.014962724409997463,
                0.044717561453580856,
                -0.0052110180258750916,
                -0.014039658010005951,
                0.0012782299891114235,
                -0.04644446074962616,
                -0.01094638742506504,
                -0.0039604161866009235,
                -0.005332679487764835,
                -0.055086132138967514,
                0.005616840906441212,
                -0.0058652074076235294,
                -0.004855348728597164,
                -0.061494145542383194,
                -0.0010060708737000823,
                0.01034580823034048,
                -0.047214411199092865,
                0.009236185811460018,
                0.12805604934692383,
                -0.027537241578102112,
                -0.05885322391986847,
                -0.06002924218773842,
                -0.016337646171450615,
                0.018203258514404297,
                -0.017624596133828163,
                0.11358987540006638,
                -0.018986234441399574,
                -0.024686278775334358,
                -0.008778524585068226,
                0.020798394456505775,
                -0.032043155282735825,
                0.028240785002708435,
                -0.0033068927004933357,
                -0.02626757323741913,
                0.11435230821371078,
                -0.04901719465851784,
                -0.0005565361934714019,
                -0.007099400274455547,
                0.06624225527048111,
                0.0049442448653280735,
                -0.004312728065997362,
                -0.09368827193975449,
                -0.016003362834453583,
                0.03984881937503815,
                -0.020475419238209724,
                0.04623953625559807,
                -0.04827049374580383,
                -0.010667560622096062,
                0.02670513466000557,
                -0.07344025373458862,
                0.015514499507844448,
                -0.025683162733912468,
                0.019963299855589867,
                0.0022495249286293983,
                -0.013644302263855934,
                -0.03712521865963936,
                -0.029388023540377617,
                -0.00319643784314394,
                0.005347774364054203,
                0.061332643032073975,
                -0.01713530346751213,
                0.008862693794071674,
                0.01986991986632347,
                0.029829109087586403,
                -0.024371176958084106,
                -0.009052049368619919,
                -0.014387938193976879,
                0.04196693375706673,
                -0.0014029095182195306,
                -0.0224741343408823,
                0.009641386568546295,
                -0.028648143634200096,
                0.009389504790306091,
                0.05511707067489624,
                -0.0010913925943896174,
                0.02503245137631893,
                -0.027150310575962067,
                -0.018018757924437523,
                -0.05082247778773308,
                2.560899758918822e-07,
                -0.02411649376153946,
                0.03350965678691864,
                -0.02777971886098385,
                0.05452302470803261,
                0.00205419072881341,
                0.025529658421874046,
                0.041349057108163834,
                0.026454269886016846,
                -0.036468639969825745,
                0.025527864694595337,
                0.06485771387815475,
                -0.028477178886532784,
                -0.01036851480603218,
                -0.013424916192889214,
                0.052302680909633636,
                -0.025464292615652084,
                0.02359195426106453,
                -0.005773442331701517,
                0.014330280013382435,
                -0.04745330289006233,
                0.015433297492563725,
                0.015408436767756939,
                0.03934541344642639,
                -0.0050926050171256065,
                0.0240897499024868,
                0.015986837446689606,
                0.023793432861566544,
                -0.06193820759654045,
                0.07279817759990692,
                0.003162792418152094,
                0.02637508139014244,
                0.0006602075300179422,
                -0.014937307685613632,
                -0.025475963950157166,
                -0.01127199362963438,
                -0.03593171387910843,
                0.020085176452994347,
                0.031085820868611336,
                0.047357477247714996,
                0.03642161935567856,
                -0.010101962834596634,
                -0.03720800578594208,
                -0.017909277230501175,
                0.022952135652303696,
                0.0056306892074644566,
                0.0035241711884737015,
                -0.004181977827101946,
                0.015338117256760597,
                -0.07761694490909576,
                0.0007475751917809248,
                -0.03916480019688606,
                0.022102510556578636,
                -0.005779046565294266,
                0.06774162501096725,
                0.008966433815658092,
                -0.0043969908729195595,
                0.045865241438150406,
                0.03279309347271919,
                0.066042460501194,
                0.026558320969343185,
                -0.01975245587527752,
                0.024849647656083107,
                -0.05083482339978218,
                -0.005519232712686062,
                0.060025446116924286,
                0.025227146223187447,
                -0.002740114228799939,
                1.0303531705081307e-34,
                -0.0004707284097094089,
                -0.032791346311569214,
                0.011109854094684124,
                0.015971139073371887,
                0.030575253069400787,
                -0.004537593573331833,
                -0.03133068233728409,
                0.012702742591500282,
                0.025055324658751488,
                0.0064957947470247746,
                -0.018579836934804916
            ]
        },
        "Location": "Edinburgh",
        "Remuneration": 44.47115384615385,
        "Services Required": {
            "name": "Corporate Strategy & Transformation Manager",
            "rep": [
                0.055553894490003586,
                -0.023693423718214035,
                0.0006163406069390476,
                -0.0006317197112366557,
                0.029480157420039177,
                0.01671147160232067,
                -0.034088134765625,
                -0.00998903438448906,
                -0.10348069667816162,
                0.011288623325526714,
                -0.03228640556335449,
                0.041118916124105453,
                0.011507504619657993,
                0.06481845676898956,
                0.008645550347864628,
                -0.02395695447921753,
                0.013668065890669823,
                0.009524194523692131,
                -0.07111015915870667,
                0.012510106898844242,
                -0.02649456076323986,
                0.003445211797952652,
                0.01198056060820818,
                0.006094810087233782,
                0.0018002524739131331,
                0.02659536898136139,
                -0.020375646650791168,
                0.06428212672472,
                0.014910545200109482,
                -0.024226125329732895,
                0.004030308686196804,
                -0.01540856622159481,
                -0.03371676057577133,
                -0.030146658420562744,
                1.3133286529409816e-06,
                -0.007580190431326628,
                -0.020742593333125114,
                -0.05449425056576729,
                0.029501182958483696,
                0.017415190115571022,
                0.04452037811279297,
                -0.05596025288105011,
                0.0018012244254350662,
                0.027002936229109764,
                -0.02469145879149437,
                0.06190478056669235,
                -0.006317220162600279,
                0.02239193394780159,
                0.044076282531023026,
                0.07006418704986572,
                -0.006501437164843082,
                -0.07430213689804077,
                -0.05564946308732033,
                -0.04025214910507202,
                -0.06419256329536438,
                -0.09932562708854675,
                0.0005397338536567986,
                -0.0536436103284359,
                0.008324227295815945,
                0.021730978041887283,
                -0.02479182370007038,
                0.05382288619875908,
                -0.021450676023960114,
                -0.0069394647143781185,
                0.07280878722667694,
                0.008212865330278873,
                0.07105103135108948,
                -0.04673539474606514,
                0.043254148215055466,
                -0.006434784736484289,
                0.046385258436203,
                0.012971247546374798,
                0.02418268658220768,
                0.05257367715239525,
                0.07592758536338806,
                -0.015623340383172035,
                0.02814863622188568,
                0.010989849455654621,
                0.0016280686249956489,
                -0.0009040373843163252,
                -0.03620358929038048,
                0.010970915667712688,
                -0.007774630095809698,
                -0.01906420662999153,
                -0.0421428382396698,
                -0.004943659994751215,
                -0.02743072621524334,
                -0.02619468793272972,
                0.015512696467339993,
                0.008997988887131214,
                0.0534050315618515,
                -0.002686850493773818,
                -0.004983384627848864,
                0.03386397287249565,
                -0.061146482825279236,
                0.005843436811119318,
                -0.010792110115289688,
                0.02272678352892399,
                -0.05615220591425896,
                -0.04786068946123123,
                0.004372702445834875,
                0.013222350738942623,
                0.04898879677057266,
                0.02348782867193222,
                -0.0006720506935380399,
                0.05790115147829056,
                -0.07117187231779099,
                -0.01941835694015026,
                -0.06388022005558014,
                -0.00663649570196867,
                -0.006118540186434984,
                0.003344919765368104,
                -0.06573639810085297,
                0.015873927623033524,
                -0.002127806656062603,
                0.011055197566747665,
                -0.0420873761177063,
                0.045868169516325,
                0.03058418445289135,
                0.07062104344367981,
                0.032045878469944,
                0.051887623965740204,
                0.004621206317096949,
                0.005901319906115532,
                -0.0245338287204504,
                0.035883501172065735,
                -0.016312625259160995,
                0.013680905103683472,
                0.023961398750543594,
                0.05355466157197952,
                0.008748902939260006,
                0.012920171022415161,
                -0.016725173220038414,
                -0.011333012953400612,
                0.022410836070775986,
                0.03917218744754791,
                -0.025002717971801758,
                0.021889474242925644,
                -0.07683759182691574,
                0.033074695616960526,
                -0.03828578442335129,
                0.005434912163764238,
                -0.022197116166353226,
                -0.02106269635260105,
                -0.023667627945542336,
                -0.03385445475578308,
                -0.03809962049126625,
                0.05667882785201073,
                0.021163810044527054,
                -0.014901073649525642,
                -0.004767931532114744,
                -0.01910441741347313,
                0.039790693670511246,
                0.026911165565252304,
                0.06761490553617477,
                0.023297879844903946,
                0.043693337589502335,
                0.001169303199276328,
                -0.02523154951632023,
                -0.003945092670619488,
                0.03379320353269577,
                -0.017987195402383804,
                0.00440426729619503,
                0.061581529676914215,
                -0.0716724544763565,
                0.03665661811828613,
                0.07976411283016205,
                0.009152873419225216,
                -0.0334094874560833,
                0.04029582068324089,
                -0.0010959076462313533,
                -0.00736444303765893,
                0.0013544716639444232,
                0.04687655344605446,
                -0.004485635086894035,
                -0.10804222524166107,
                0.07562324404716492,
                -0.008311509154736996,
                0.02327222190797329,
                -0.032367680221796036,
                -0.068386971950531,
                -0.07969214767217636,
                0.04043993353843689,
                -0.027347085997462273,
                0.02373405732214451,
                0.01296603586524725,
                -0.03296374902129173,
                0.0035570841282606125,
                -0.019312141463160515,
                0.010204682126641273,
                0.050696149468421936,
                0.04471062496304512,
                -0.08391419798135757,
                -0.013035261072218418,
                0.019346822053194046,
                0.0631403923034668,
                -0.05777746066451073,
                0.029296444728970528,
                -0.06901754438877106,
                -0.023486101999878883,
                0.004562398884445429,
                0.036767005920410156,
                -0.035269323736429214,
                0.00826574768871069,
                -0.0036016160156577826,
                -0.011108228005468845,
                0.01196859497576952,
                -0.031648728996515274,
                -0.08534831553697586,
                0.06124662980437279,
                0.07470839470624924,
                0.027303339913487434,
                -0.06594738364219666,
                0.010529967956244946,
                0.0056157344952225685,
                0.020250020548701286,
                -0.0004296659317333251,
                0.008909457363188267,
                -0.01382309477776289,
                0.04890139773488045,
                0.019979005679488182,
                0.0034752804785966873,
                0.06730519235134125,
                0.01967364177107811,
                0.03766072168946266,
                -0.029289642348885536,
                -0.0034003637265414,
                0.0057916659861803055,
                0.02019134908914566,
                0.03284772112965584,
                -0.010115277953445911,
                0.009615344926714897,
                0.017168495804071426,
                -0.014359131455421448,
                0.00072939955862239,
                0.03509566932916641,
                -0.0006497643189504743,
                0.00532770249992609,
                0.0193849578499794,
                -0.01236338634043932,
                -0.03973029553890228,
                0.001203241990879178,
                -0.009142742492258549,
                -0.03182098641991615,
                0.03382797911763191,
                -0.015929624438285828,
                -0.01974533125758171,
                0.01856585219502449,
                0.0037099244073033333,
                0.01429427694529295,
                -0.034037504345178604,
                -0.01561138778924942,
                0.028558075428009033,
                0.02261376939713955,
                -0.02106267213821411,
                0.0035013749729841948,
                -0.03976971283555031,
                0.0038680690340697765,
                -0.012115444988012314,
                0.03189712017774582,
                0.04476569965481758,
                -0.027465607970952988,
                -0.024847321212291718,
                0.026680339127779007,
                0.029161496087908745,
                -0.027662718668580055,
                -0.009975860826671124,
                -0.007614160887897015,
                -0.012393223121762276,
                0.000298393628327176,
                -0.007004454731941223,
                0.06148987263441086,
                0.04759852588176727,
                -0.00705295242369175,
                0.01409157458692789,
                -0.009496010839939117,
                0.011531004682183266,
                0.018805023282766342,
                -0.012067673727869987,
                0.03181269392371178,
                0.0487414225935936,
                -0.06928911805152893,
                -0.014158782549202442,
                0.03781607374548912,
                3.187290349160321e-05,
                -0.007532864343374968,
                -0.001482994295656681,
                -0.057798609137535095,
                -0.04826170206069946,
                0.0477675125002861,
                0.01915505714714527,
                -0.027429021894931793,
                0.0017134841764345765,
                -0.007721553556621075,
                -0.005421555135399103,
                6.486350321210921e-05,
                0.016446005553007126,
                -0.049450863152742386,
                -0.0003542583144735545,
                0.0404774434864521,
                -0.04123901203274727,
                -0.0537605881690979,
                0.04261446371674538,
                -0.04831452667713165,
                -0.008711067028343678,
                -0.024385826662182808,
                -0.019411718472838402,
                -0.004676838871091604,
                0.04319377616047859,
                -0.0013791953679174185,
                -0.004058851394802332,
                0.01857069320976734,
                0.010228294879198074,
                -0.10475493967533112,
                0.016499580815434456,
                0.03422749415040016,
                -0.039795033633708954,
                -0.023772580549120903,
                -0.014867780730128288,
                0.10011455416679382,
                0.017965354025363922,
                0.06411943584680557,
                -0.048804331570863724,
                -0.030231645330786705,
                0.015924300998449326,
                -0.0268220417201519,
                -0.06339449435472488,
                -0.013022621162235737,
                -0.010466931387782097,
                -0.07279264181852341,
                -0.03181811794638634,
                0.017170339822769165,
                -0.04130316525697708,
                -0.0755675733089447,
                -0.04902802035212517,
                -0.0006335339276120067,
                -0.0169536042958498,
                -0.042055144906044006,
                0.006065684370696545,
                -0.013962862081825733,
                -0.01821530982851982,
                0.05227430909872055,
                -0.03899851813912392,
                0.007687258999794722,
                0.018361682072281837,
                0.011393440887331963,
                0.07170756906270981,
                0.052839331328868866,
                -0.09922871738672256,
                -0.006188421510159969,
                -0.01611359976232052,
                0.043775077909231186,
                -0.002317423466593027,
                -0.029630370438098907,
                -0.009186769835650921,
                -0.012930940836668015,
                -0.027876321226358414,
                0.03761645406484604,
                0.01779489777982235,
                -0.02292288839817047,
                -0.019977515563368797,
                -0.0016734646633267403,
                -0.028867674991488457,
                -0.05779765546321869,
                -0.010717409662902355,
                -0.029641496017575264,
                -0.03904889523983002,
                0.0038596675731241703,
                0.03324019908905029,
                -0.016895899549126625,
                -0.02788030356168747,
                0.025423068553209305,
                -0.014966445975005627,
                -0.013421174138784409,
                -0.007700991351157427,
                0.011041088961064816,
                0.015558425337076187,
                0.006410758011043072,
                -0.05818118155002594,
                0.03939231485128403,
                0.07755781710147858,
                -0.042346153408288956,
                0.05851059779524803,
                -0.02168743498623371,
                0.05188220739364624,
                0.00949597917497158,
                0.0018845376325771213,
                -0.0024825443979352713,
                -0.005078917369246483,
                -0.01995244063436985,
                0.004048639442771673,
                -0.0033158608712255955,
                -0.01580025441944599,
                0.017362939193844795,
                -0.023191804066300392,
                -0.021393366158008575,
                -0.03902373090386391,
                0.010166057385504246,
                -0.04890162870287895,
                -0.03169942647218704,
                0.031943418085575104,
                -0.004557116888463497,
                0.023755867034196854,
                -0.030474456027150154,
                -0.00273918267339468,
                -0.15199114382266998,
                0.08096744120121002,
                -0.015772327780723572,
                -0.011386850848793983,
                -0.008866079151630402,
                0.03939191997051239,
                -0.013577683828771114,
                0.006566237658262253,
                -0.0095608439296484,
                -0.022136664018034935,
                -0.03874874860048294,
                -0.01805921643972397,
                0.07519693672657013,
                0.002982297446578741,
                -0.04261404648423195,
                0.04280683025717735,
                0.03779083490371704,
                0.009626267477869987,
                0.004002416040748358,
                0.013822171837091446,
                0.016588686034083366,
                0.0455348826944828,
                -0.008759657852351665,
                0.018084151670336723,
                -0.012787435203790665,
                -0.007384240627288818,
                0.03326636552810669,
                0.015277049504220486,
                0.01139114424586296,
                0.03547792509198189,
                -0.00548298517242074,
                0.013806906528770924,
                -0.019201887771487236,
                0.03936426341533661,
                -0.022636087611317635,
                0.023389631882309914,
                0.06472425162792206,
                0.020152876153588295,
                0.004193325061351061,
                -0.05626871436834335,
                -0.05151626095175743,
                -0.014779830351471901,
                0.0077737667597830296,
                -0.0902680903673172,
                -0.009774602018296719,
                0.051778052002191544,
                -0.03496307134628296,
                -0.02377983182668686,
                -0.03386852890253067,
                0.03927136957645416,
                -0.009987127967178822,
                -0.06609445810317993,
                -0.016106978058815002,
                0.0010379484156146646,
                -0.010463749058544636,
                0.016724934801459312,
                0.03259330987930298,
                0.0037213105242699385,
                0.04655267670750618,
                0.06107659637928009,
                0.04925333708524704,
                0.004253591876477003,
                -0.019312525168061256,
                0.053433772176504135,
                -0.04821671545505524,
                0.04470944032073021,
                -0.06302947551012039,
                -0.02945650927722454,
                -0.014666981063783169,
                -0.018432505428791046,
                -0.014558370225131512,
                0.020920371636748314,
                -0.019999917596578598,
                0.06059329956769943,
                0.004896873142570257,
                -0.035652045160532,
                0.009648826904594898,
                0.00035371616831980646,
                -0.00867783185094595,
                0.013023314997553825,
                -0.006878783460706472,
                0.04197702556848526,
                0.009897323325276375,
                0.012868659570813179,
                -0.05626361072063446,
                0.07894708216190338,
                -0.02468525618314743,
                0.012734213843941689,
                0.005969393067061901,
                -0.06771204620599747,
                -0.05701625719666481,
                -0.001879110001027584,
                0.02789439633488655,
                0.012127221561968327,
                0.021140823140740395,
                -0.019914543256163597,
                -0.0250050388276577,
                -0.013427164405584335,
                -0.03345928341150284,
                0.020595669746398926,
                0.0043080938048660755,
                0.026420118287205696,
                -0.010751175694167614,
                -0.005312090739607811,
                0.038638778030872345,
                0.017753371968865395,
                0.001790294423699379,
                0.03217466175556183,
                -0.05021397024393082,
                0.018019171431660652,
                -0.037738267332315445,
                -0.021905671805143356,
                0.002770285354927182,
                -0.0021061187144368887,
                -0.03893301263451576,
                0.0253913551568985,
                -0.019495058804750443,
                -0.03167666867375374,
                -0.003785662818700075,
                -0.00922117754817009,
                0.037434373050928116,
                0.0021444212179630995,
                0.016983719542622566,
                0.0031106462702155113,
                0.037876468151807785,
                0.028398731723427773,
                -0.03031204827129841,
                -0.09136892855167389,
                0.03303714096546173,
                -0.03753448277711868,
                0.04550711438059807,
                0.04349147528409958,
                0.055149998515844345,
                0.04590476304292679,
                -0.02575787715613842,
                -0.041966717690229416,
                0.044197287410497665,
                0.043416865170001984,
                -0.028385357931256294,
                0.00437190430238843,
                0.013177979737520218,
                -0.055766183882951736,
                0.003820999525487423,
                0.04394441470503807,
                -0.011321310885250568,
                -0.04212535545229912,
                0.01307120081037283,
                0.00973529927432537,
                0.005502170883119106,
                0.016798824071884155,
                -5.102948077246233e-33,
                -0.03363931551575661,
                -0.01593390665948391,
                0.040770091116428375,
                -0.017201879993081093,
                -0.054718129336833954,
                -0.04915519058704376,
                0.007745820563286543,
                0.03393037989735603,
                -0.03253853693604469,
                -0.027420364320278168,
                -0.014840862713754177,
                0.015387403778731823,
                0.013301519677042961,
                0.014982259832322598,
                0.006558975670486689,
                0.026604074984788895,
                0.008848978206515312,
                -0.014003394171595573,
                -0.02187729813158512,
                -0.060537222772836685,
                -0.02876420132815838,
                0.021968673914670944,
                0.05935746431350708,
                0.005318314302712679,
                -0.04736471548676491,
                0.03525872901082039,
                -0.00808296911418438,
                0.06513150781393051,
                0.04727168753743172,
                -0.03998425230383873,
                0.02500162273645401,
                -0.04426039382815361,
                -0.02384885959327221,
                -0.08184627443552017,
                0.036510251462459564,
                0.0448770634829998,
                -0.015699606388807297,
                0.004139194265007973,
                0.0005667805671691895,
                0.0501067154109478,
                -0.004189841449260712,
                -0.025590209290385246,
                0.0412118136882782,
                -0.007452850695699453,
                0.027790658175945282,
                -0.023283522576093674,
                -0.03837880864739418,
                -0.02010892704129219,
                -0.012243850156664848,
                0.02383706532418728,
                -0.08177424967288971,
                0.0009105494827963412,
                -0.040884699672460556,
                0.06639468669891357,
                -0.012292265892028809,
                -0.004545570816844702,
                -0.04551169276237488,
                -0.016187133267521858,
                -0.017453212291002274,
                -0.0341976024210453,
                -0.010119982995092869,
                0.02092931792140007,
                0.02275107055902481,
                0.08086604624986649,
                -0.011900465935468674,
                -0.006735907401889563,
                -0.0397225096821785,
                0.10110171139240265,
                -0.016095701605081558,
                0.02384903095662594,
                -0.022716239094734192,
                0.09457045793533325,
                -0.00781572237610817,
                -0.05090443044900894,
                -0.12211257219314575,
                0.002934287069365382,
                0.03638019040226936,
                0.018984831869602203,
                0.020260430872440338,
                0.032360076904296875,
                -0.02569582872092724,
                0.018840264528989792,
                0.03639565780758858,
                -0.02403036691248417,
                0.05904493108391762,
                -0.02170899510383606,
                -0.024724649265408516,
                0.05157994478940964,
                0.019849086180329323,
                0.015700379386544228,
                -0.03551645576953888,
                0.0972338393330574,
                -0.005642504896968603,
                0.003429545322433114,
                0.03534122183918953,
                -0.03509484604001045,
                -0.026141056790947914,
                0.018750935792922974,
                0.002278798958286643,
                -0.01325652189552784,
                -0.004485879093408585,
                0.0002506230375729501,
                0.005908358376473188,
                0.039518360048532486,
                0.011320188641548157,
                -0.005221870262175798,
                -0.0332409143447876,
                -0.051464516669511795,
                -0.05250989645719528,
                -0.03262270241975784,
                0.025527680292725563,
                0.013452901504933834,
                0.014262501150369644,
                -0.10544845461845398,
                -0.07485475391149521,
                -0.01915917359292507,
                -0.014008953236043453,
                -0.006895423401147127,
                0.006891080178320408,
                -0.0471329502761364,
                0.03241556137800217,
                0.05128331482410431,
                0.054738547652959824,
                0.011768425814807415,
                -0.031854018568992615,
                0.010529027320444584,
                0.05665215477347374,
                0.04912950471043587,
                0.034133054316043854,
                -0.028006043285131454,
                -0.009618054144084454,
                -0.023341139778494835,
                2.0473545703225682e-07,
                0.05874649062752724,
                -0.002792192157357931,
                0.010540247894823551,
                -0.026322174817323685,
                0.006631048396229744,
                -0.0014335376909002662,
                0.011038714088499546,
                -0.04891234263777733,
                -0.040821995586156845,
                -0.025379164144396782,
                0.044062383472919464,
                -0.03106907568871975,
                -0.006636091973632574,
                -0.0037573808804154396,
                0.046970874071121216,
                -0.06634052842855453,
                0.014502022415399551,
                0.0023064883425831795,
                -0.04059860110282898,
                -0.006442627869546413,
                0.036263007670640945,
                0.04378656670451164,
                0.03722082078456879,
                0.0006404499290511012,
                0.0013912993017584085,
                0.031222989782691002,
                0.025803200900554657,
                -0.029058219864964485,
                0.07055807113647461,
                0.025497764348983765,
                -0.029488936066627502,
                -0.030928634107112885,
                -0.02776220813393593,
                -0.04743298143148422,
                0.00908441748470068,
                0.01067657396197319,
                0.011994361877441406,
                0.023356258869171143,
                0.030650557950139046,
                0.007317400071769953,
                0.028258271515369415,
                -0.05342654511332512,
                -0.02554754912853241,
                -0.013932782225310802,
                -0.018226584419608116,
                -0.027483036741614342,
                0.008716102689504623,
                0.04800916835665703,
                -0.09824343025684357,
                0.00998840294778347,
                0.00463692145422101,
                0.04204744100570679,
                0.04163236916065216,
                0.037626367062330246,
                0.018729954957962036,
                0.006970355752855539,
                -0.007396704982966185,
                0.01659228280186653,
                0.05454215034842491,
                0.06332716345787048,
                -0.03964424133300781,
                0.0387527272105217,
                -0.026481162756681442,
                0.033964671194553375,
                0.02236654795706272,
                0.06000120937824249,
                0.04365568235516548,
                1.2814732021548306e-34,
                -0.016460055485367775,
                -0.029227543622255325,
                0.0070376708172261715,
                -0.028130749240517616,
                0.02166418544948101,
                -0.02464498206973076,
                -0.036467500030994415,
                0.029278958216309547,
                0.0011667282087728381,
                -0.08931327611207962,
                0.004170613829046488
            ]
        },
        "Skills": {
            "name": [
                "Analytical Thinking",
                "Communication Skills",
                "Data Analysis",
                "Emotional Intelligence",
                "Problem Solving"
            ],
            "rep": [
                -0.016347574070096016,
                0.009961150586605072,
                -0.029308879747986794,
                -0.004523003939539194,
                -0.045926205813884735,
                0.006656370125710964,
                -0.04545711725950241,
                -0.007617429364472628,
                -0.050382938235998154,
                0.06348618119955063,
                0.06943092495203018,
                0.024867355823516846,
                0.004165788181126118,
                0.08146107196807861,
                0.0023412699811160564,
                -0.002725136000663042,
                0.004063219763338566,
                0.0024587649386376143,
                -0.007981385104358196,
                0.025478824973106384,
                -0.05497591197490692,
                0.007468458265066147,
                -0.039597347378730774,
                -0.0028678136877715588,
                0.008429102599620819,
                -0.04711068421602249,
                0.01835915632545948,
                -0.024720415472984314,
                0.03224378451704979,
                -0.006652158219367266,
                -0.02326885610818863,
                0.013171962462365627,
                -0.044765323400497437,
                0.0020774328149855137,
                1.6031222003221046e-06,
                -0.03177095204591751,
                -0.03415052220225334,
                -0.019301075488328934,
                0.010951114818453789,
                0.027877889573574066,
                0.043521981686353683,
                0.03131745755672455,
                0.046321723610162735,
                0.04396411031484604,
                -0.04193598031997681,
                0.057689081877470016,
                0.02137674018740654,
                -0.057140398770570755,
                -0.02486693114042282,
                -0.00647404370829463,
                0.019823165610432625,
                -0.034265339374542236,
                -0.06888144463300705,
                -0.042269669473171234,
                0.004634970333427191,
                -0.05454820394515991,
                -0.00438801059499383,
                -0.015735670924186707,
                0.02789381705224514,
                -0.016894185915589333,
                -0.03312266245484352,
                0.015652064234018326,
                0.004301379434764385,
                -0.006582640111446381,
                0.021409746259450912,
                0.00033582630567252636,
                0.026259802281856537,
                0.01635049469769001,
                0.017057927325367928,
                -0.020077113062143326,
                0.03441031649708748,
                0.0340798981487751,
                -0.004483762197196484,
                0.011839210987091064,
                -0.0073614101856946945,
                -0.011676885187625885,
                -0.055890265852212906,
                -0.003863154212012887,
                -0.020521769300103188,
                -0.027128232643008232,
                0.008094065822660923,
                0.00496704364195466,
                -0.027055907994508743,
                0.038861412554979324,
                -0.009208629839122295,
                0.06818754971027374,
                -0.053648389875888824,
                -0.006312423851341009,
                0.003207616740837693,
                0.010273350402712822,
                0.006187277380377054,
                -0.015605220571160316,
                -0.018778372555971146,
                0.0499093271791935,
                0.02355855144560337,
                -0.03191080316901207,
                0.04923616722226143,
                -0.01039871945977211,
                -0.03634951263666153,
                0.02883809246122837,
                0.05410343408584595,
                0.04754059016704559,
                0.06316957622766495,
                0.00028163488605059683,
                -0.010580930858850479,
                -0.022018996998667717,
                -0.004610572010278702,
                -0.04317270591855049,
                -0.020418036729097366,
                0.075526162981987,
                -0.024225730448961258,
                -0.021227169781923294,
                -0.007803771179169416,
                0.016653090715408325,
                0.03591163828969002,
                -0.004975262098014355,
                -0.03482065722346306,
                -0.056917205452919006,
                -0.04100145772099495,
                -0.023727435618638992,
                -0.1450437307357788,
                0.023225095123052597,
                0.010135237127542496,
                0.015821097418665886,
                -0.04487735405564308,
                0.06251406669616699,
                -0.06719324737787247,
                0.021218229085206985,
                0.0034143924713134766,
                0.01357114315032959,
                -0.018158307299017906,
                -0.031247256323695183,
                -0.007248568348586559,
                0.0035385796800255775,
                0.02958884835243225,
                0.01899748481810093,
                -0.00023236872220877558,
                0.035509176552295685,
                -0.07607150077819824,
                -0.0498737171292305,
                0.06878247112035751,
                -0.05171651020646095,
                -0.000471544946776703,
                -0.019958699122071266,
                -0.030734360218048096,
                0.0034200269728899,
                0.010255356319248676,
                -0.02641388028860092,
                0.0036247887182980776,
                -0.022453024983406067,
                -0.007369603030383587,
                -0.011344656348228455,
                0.002906505484133959,
                -0.00665589515119791,
                0.04592901095747948,
                0.04960115998983383,
                0.03103739582002163,
                0.03226036950945854,
                0.01637454517185688,
                -0.04074117913842201,
                -0.031730808317661285,
                0.013184121809899807,
                0.044947072863578796,
                -0.015773355960845947,
                0.0026312198024243116,
                -0.009881699457764626,
                -0.000804040115326643,
                0.030377235263586044,
                -0.14623692631721497,
                -0.01621812768280506,
                -0.003922790288925171,
                0.0034989125560969114,
                -0.022421058267354965,
                0.1025797575712204,
                0.013768661767244339,
                0.028446432203054428,
                0.015750108286738396,
                0.046758897602558136,
                -0.017778435721993446,
                -0.01928853429853916,
                0.010318104177713394,
                -0.037979837507009506,
                6.618308543693274e-05,
                0.07197802513837814,
                0.021673759445548058,
                0.042258962988853455,
                -0.024498404935002327,
                -0.01069060992449522,
                -0.01509828306734562,
                -0.023410893976688385,
                -0.0034630070440471172,
                0.022584199905395508,
                -0.03144039958715439,
                0.00010872678103623912,
                -0.02369401976466179,
                -0.04607843980193138,
                0.055699437856674194,
                -0.021837320178747177,
                -0.022921094670891762,
                -0.003342011710628867,
                -0.026166528463363647,
                -0.03655669465661049,
                0.05687966197729111,
                -0.004222895950078964,
                -0.035036906599998474,
                -0.01031902339309454,
                -0.006325799040496349,
                -0.056070681661367416,
                -0.013830594718456268,
                -0.01065500546246767,
                0.06252460181713104,
                0.01211850717663765,
                -0.06229730695486069,
                0.000292770127998665,
                0.016637563705444336,
                -0.031451571732759476,
                0.01958208717405796,
                -0.0009031806839630008,
                -0.024866994470357895,
                0.03271761164069176,
                -0.0050826966762542725,
                0.1330319494009018,
                0.005489298142492771,
                -0.04566604271531105,
                0.01953422836959362,
                -0.024976560845971107,
                -5.7587691117078066e-05,
                0.0013954845489934087,
                -0.007662104442715645,
                -0.005103972274810076,
                0.0017297820886597037,
                0.07743733376264572,
                -0.03100784868001938,
                0.008075042627751827,
                -0.018488619476556778,
                0.03064187802374363,
                0.062173034995794296,
                0.08171885460615158,
                -0.023133138194680214,
                -0.12868274748325348,
                -0.010362283326685429,
                -0.07312025874853134,
                -0.004759744741022587,
                0.008854088373482227,
                0.03150543197989464,
                -0.04112154617905617,
                0.025715405121445656,
                0.015706682577729225,
                0.0062989965081214905,
                -0.051311250776052475,
                -0.03252077102661133,
                0.01588188298046589,
                0.03208503499627113,
                -0.014326104894280434,
                0.04238569736480713,
                -0.012355461716651917,
                -0.0347755141556263,
                0.00032200926216319203,
                -0.027798708528280258,
                0.009024384431540966,
                -0.03691454231739044,
                -0.01562693528831005,
                -0.0157611183822155,
                -0.0029745055362582207,
                0.030112996697425842,
                -0.06524177640676498,
                0.08350089937448502,
                0.02667056955397129,
                -0.025271251797676086,
                0.020432814955711365,
                0.03983223810791969,
                -0.003825167194008827,
                -0.0013278602855280042,
                -0.03242647275328636,
                0.012951250188052654,
                0.03749791905283928,
                -0.04005273059010506,
                -1.5292012903955765e-05,
                0.013073300942778587,
                0.004306488204747438,
                0.008573650382459164,
                0.02279496192932129,
                0.005934765562415123,
                -0.007867780514061451,
                -0.031888339668512344,
                0.005646353587508202,
                0.04869826138019562,
                0.009875422343611717,
                0.020321667194366455,
                -0.015319730155169964,
                -0.027695249766111374,
                -0.0414222776889801,
                0.01870315708220005,
                -0.0025923349894583225,
                0.05102366954088211,
                0.0034188812132924795,
                0.014341779984533787,
                0.015206662937998772,
                -0.011031221598386765,
                -0.02517358586192131,
                -0.018580276519060135,
                -0.036111604422330856,
                0.021844396367669106,
                -0.0031584566459059715,
                -0.02119266800582409,
                0.049432072788476944,
                0.06298334896564484,
                -0.04820685461163521,
                -0.05328637361526489,
                0.0320291742682457,
                -0.010961239226162434,
                -0.009261365048587322,
                0.032639943063259125,
                0.09840254485607147,
                -0.018648536875844002,
                -0.04417624697089195,
                -0.04867918789386749,
                -0.007140140049159527,
                0.005449899937957525,
                0.07923796027898788,
                0.021966444328427315,
                0.03416195139288902,
                0.01702505350112915,
                0.044158730655908585,
                0.03754151612520218,
                -0.024026954546570778,
                0.03320712223649025,
                -0.0025207940489053726,
                0.05051298439502716,
                -0.052481476217508316,
                -0.013788347132503986,
                -0.0071572610177099705,
                -0.002868195064365864,
                -0.03204731643199921,
                -0.03325338289141655,
                0.010569694451987743,
                -0.031298983842134476,
                0.0665316954255104,
                -0.02073122002184391,
                -0.01888817921280861,
                -0.003108441364020109,
                -0.022017933428287506,
                -0.036838315427303314,
                0.034247986972332,
                -0.002523009432479739,
                -0.011413302272558212,
                0.04819526895880699,
                -0.022095391526818275,
                -0.09378379583358765,
                -0.03762306645512581,
                0.06510715931653976,
                0.03433641418814659,
                -0.024670816957950592,
                -0.03615067899227142,
                0.030230646952986717,
                -0.028635462746024132,
                -0.01818229630589485,
                0.008278431370854378,
                0.041401416063308716,
                -0.06025595963001251,
                0.0304121021181345,
                0.040653474628925323,
                -0.023542871698737144,
                0.004481499549001455,
                0.01744387112557888,
                0.04171227663755417,
                -0.004201148636639118,
                0.049299634993076324,
                -0.014142242260277271,
                0.014984161593019962,
                -0.042993124574422836,
                0.00719978054985404,
                -0.03529032692313194,
                0.03911720961332321,
                0.014059551991522312,
                0.05593608319759369,
                0.006269875913858414,
                -0.05749766156077385,
                -0.02603694424033165,
                0.008367636241018772,
                0.05473259463906288,
                -0.08395165950059891,
                0.007044968195259571,
                0.017154714092612267,
                0.008694072254002094,
                -0.03969426080584526,
                -0.06403156369924545,
                -0.007826714776456356,
                -0.0621977373957634,
                0.030248310416936874,
                0.0219600610435009,
                0.0018758844817057252,
                0.002153669251129031,
                0.037604544311761856,
                0.007010023575276136,
                -0.032501522451639175,
                -0.055611103773117065,
                -0.001173794618807733,
                -0.005895123817026615,
                0.05242602899670601,
                0.014354481361806393,
                -0.03594936802983284,
                0.05453610420227051,
                0.009208576753735542,
                -0.008859758265316486,
                -0.1277146190404892,
                -0.0543183870613575,
                0.08765936642885208,
                -0.0400526337325573,
                -0.028592821210622787,
                -0.04025045037269592,
                0.074339359998703,
                0.031493425369262695,
                -0.017595771700143814,
                0.03497039154171944,
                -0.03375650942325592,
                -0.004696110729128122,
                -0.00213076313957572,
                0.021919207647442818,
                -0.018947524949908257,
                0.010492383502423763,
                0.02216666378080845,
                0.032841816544532776,
                -0.0004941751249134541,
                0.007256416603922844,
                -0.020562447607517242,
                0.0052408394403755665,
                0.00048352160956710577,
                0.026136813685297966,
                0.00734584080055356,
                0.007331238128244877,
                -0.0034740306437015533,
                -0.009912741370499134,
                0.013716431334614754,
                0.0010383474873378873,
                -0.05662070959806442,
                0.00215162499807775,
                0.06711635738611221,
                0.0008341807406395674,
                -0.003636131761595607,
                -0.02312166802585125,
                0.05470648780465126,
                0.059599801898002625,
                0.0001873570290626958,
                -0.043090417981147766,
                -0.05883632227778435,
                0.039835281670093536,
                -0.056882381439208984,
                0.018375029787421227,
                0.059607718139886856,
                0.05438930168747902,
                0.049793146550655365,
                0.0004105644766241312,
                -0.02408965304493904,
                0.025322748348116875,
                -0.05266646295785904,
                0.01638762280344963,
                -0.03174809366464615,
                -0.0037432180251926184,
                0.00297540333122015,
                0.01460634171962738,
                0.04519669711589813,
                -0.060181982815265656,
                0.003375791013240814,
                0.015369987115263939,
                -0.04927040636539459,
                0.0018939044093713164,
                -0.03430558368563652,
                -0.024120111018419266,
                -0.02651640959084034,
                0.026396751403808594,
                0.02627156488597393,
                -0.022665195167064667,
                0.05336923524737358,
                0.02014867216348648,
                0.01821860484778881,
                0.010981581173837185,
                -0.0995866060256958,
                -0.0028176154009997845,
                -0.04017411172389984,
                -0.00409661652520299,
                0.032610781490802765,
                -0.02130274474620819,
                0.006191678810864687,
                -0.039452314376831055,
                0.005293786525726318,
                0.04098021984100342,
                -0.044800564646720886,
                0.016897818073630333,
                -0.05521257221698761,
                0.010702653788030148,
                0.025082997977733612,
                -0.037153106182813644,
                0.018936824053525925,
                0.03238368779420853,
                -0.04005483165383339,
                0.0075796814635396,
                0.04001070559024811,
                0.037742722779512405,
                -0.03014414757490158,
                0.017862383276224136,
                0.006884112022817135,
                -0.014605541713535786,
                -0.05917989835143089,
                -0.0022679767571389675,
                0.0677337720990181,
                0.04286302626132965,
                0.09312309324741364,
                0.0013589792652055621,
                0.009155847132205963,
                0.016496041789650917,
                -0.018921837210655212,
                -0.050458282232284546,
                -0.08964462578296661,
                0.021380150690674782,
                0.030300242826342583,
                -0.05801461264491081,
                -0.024254510179162025,
                -0.04576938971877098,
                -0.014516427181661129,
                0.007305897306650877,
                0.02699923701584339,
                0.007143157999962568,
                -0.052243296056985855,
                0.03212315961718559,
                0.025191335007548332,
                0.02140045166015625,
                0.010589094832539558,
                -0.060791321098804474,
                -0.006106821354478598,
                -0.11294795572757721,
                -0.00433938205242157,
                -0.09248323738574982,
                -0.0010231317719444633,
                -0.013393497094511986,
                0.06546725332736969,
                -0.015932392328977585,
                0.02357962727546692,
                0.04364147037267685,
                0.06110745668411255,
                -0.08792988210916519,
                -0.016635870561003685,
                0.008593719452619553,
                0.029516754671931267,
                0.006899589207023382,
                0.0073585519567132,
                0.016162415966391563,
                0.018079262226819992,
                -0.010686165653169155,
                0.005057625938206911,
                0.011269104667007923,
                0.01922321319580078,
                -0.09823937714099884,
                -0.006487966515123844,
                -0.011323590762913227,
                -6.024667524063389e-33,
                0.00747388880699873,
                -0.021469034254550934,
                0.031136298552155495,
                0.00804283656179905,
                -0.038139212876558304,
                -0.0077742114663124084,
                -0.004238796886056662,
                0.03470783308148384,
                -0.04711060971021652,
                -0.02966695837676525,
                -0.019711507484316826,
                0.021115688607096672,
                0.007169377524405718,
                -0.03349247947335243,
                0.02919609844684601,
                0.015309643931686878,
                -0.01772957108914852,
                0.01507718488574028,
                -0.018660148605704308,
                -0.07776979357004166,
                0.030905576422810555,
                0.022469330579042435,
                0.018333541229367256,
                0.0966365784406662,
                -0.011963252909481525,
                -0.01888573355972767,
                0.031013747677206993,
                -0.012706123292446136,
                0.003538863966241479,
                -0.042149271816015244,
                0.025017211213707924,
                -0.06349392980337143,
                -0.0017407735576853156,
                0.008875015191733837,
                -0.010784587822854519,
                0.03707459196448326,
                0.02204037830233574,
                -0.0683586448431015,
                0.008375274017453194,
                -0.02659468539059162,
                -0.022996293380856514,
                -0.018418630585074425,
                0.010274967178702354,
                0.03528372198343277,
                0.00011236799764446914,
                -0.0670568197965622,
                0.016484657302498817,
                -0.022905491292476654,
                0.07431966811418533,
                0.013838568702340126,
                -0.028161386027932167,
                0.0020930073224008083,
                -0.06688764691352844,
                0.034693121910095215,
                0.02126002125442028,
                0.03628968074917793,
                -0.024787751957774162,
                -0.04265793040394783,
                -0.020948806777596474,
                0.060618817806243896,
                0.01680358126759529,
                0.016586126759648323,
                0.0011432291939854622,
                0.0037480539176613092,
                -0.0027858063112944365,
                0.0002472570340614766,
                0.027881521731615067,
                -0.027229497209191322,
                -0.051175303757190704,
                -0.05453740432858467,
                0.021552572026848793,
                0.06689278036355972,
                0.045593857765197754,
                -0.008339638821780682,
                -0.01708250120282173,
                -0.025041529908776283,
                -0.0236209649592638,
                -0.007917842827737331,
                0.058744654059410095,
                0.06981610506772995,
                0.002228800905868411,
                -0.006053986959159374,
                -0.03037750907242298,
                0.02136996015906334,
                0.011452893726527691,
                -0.034246187657117844,
                -0.03792513906955719,
                0.05237358435988426,
                -0.02645975537598133,
                -0.02262917347252369,
                -0.010921388864517212,
                0.08788077533245087,
                0.010192375630140305,
                0.02214781939983368,
                0.07048151642084122,
                -0.0299281757324934,
                0.0039687976241111755,
                0.024128993973135948,
                -0.00041258547571487725,
                -0.03675955906510353,
                -0.07339242845773697,
                0.059822410345077515,
                -0.07234153151512146,
                0.0477808341383934,
                0.0460713766515255,
                -0.015876833349466324,
                0.011034611612558365,
                0.002958605531603098,
                -0.05150356888771057,
                -0.046811170876026154,
                -0.013336633332073689,
                0.020956553518772125,
                0.021119587123394012,
                -0.011268404312431812,
                0.0010881662601605058,
                0.018463794142007828,
                0.017614735290408134,
                -0.028992122039198875,
                -0.020501550287008286,
                0.01255552563816309,
                -0.016084149479866028,
                0.06646024435758591,
                -0.01195475086569786,
                0.010836795903742313,
                0.02376727946102619,
                -0.02038346230983734,
                0.033642396330833435,
                -0.0019450112013146281,
                -0.01689482480287552,
                -0.09563775360584259,
                -0.012034096755087376,
                0.013438002206385136,
                2.3066930054937984e-07,
                -0.010248267091810703,
                0.012359747663140297,
                -0.042516015470027924,
                0.027918267995119095,
                0.02172192931175232,
                0.022757822647690773,
                -0.0609971284866333,
                -0.010475589893758297,
                0.04424331709742546,
                0.001598028582520783,
                0.035795729607343674,
                0.02916247956454754,
                0.02570524625480175,
                0.02584925852715969,
                0.03542579710483551,
                -0.05667196214199066,
                -0.02451322041451931,
                -0.021010324358940125,
                0.005945878103375435,
                -0.0008474371279589832,
                -0.02518974244594574,
                -0.03608814254403114,
                0.02204940654337406,
                -0.019844334572553635,
                -0.05054846778512001,
                -0.035144004970788956,
                0.018167780712246895,
                0.016662970185279846,
                0.11446230858564377,
                0.033698417246341705,
                0.03730814531445503,
                0.028774194419384003,
                -0.001092037302441895,
                0.04527807608246803,
                0.0025068670511245728,
                -0.0015130086103454232,
                0.0509452298283577,
                0.03030581958591938,
                0.05317690223455429,
                0.0524681881070137,
                -0.00578740518540144,
                0.007294767536222935,
                -0.017049893736839294,
                -0.030701734125614166,
                0.039076440036296844,
                -0.054390110075473785,
                -0.0044731334783136845,
                0.029828637838363647,
                -0.02318926900625229,
                0.014907343313097954,
                0.02416253834962845,
                0.04121499881148338,
                0.021321376785635948,
                0.022245250642299652,
                0.008424446918070316,
                -0.04933052137494087,
                0.014688389375805855,
                0.0021162829361855984,
                0.04046889767050743,
                0.031557146459817886,
                -0.022984974086284637,
                -0.007369291968643665,
                -0.02834133431315422,
                0.023455647751688957,
                0.003891703439876437,
                0.0385221429169178,
                -0.03899536654353142,
                9.732818866263769e-35,
                -0.0006922422908246517,
                -0.018000632524490356,
                -0.020441275089979172,
                -0.0248356182128191,
                0.03211119771003723,
                -0.0057219769805669785,
                0.02837236039340496,
                0.009723030030727386,
                0.005149793345481157,
                0.03164658322930336,
                -0.029630377888679504,
                0.07608431577682495,
                -0.029054611921310425,
                -0.0433245524764061,
                0.0035550142638385296,
                0.0249630156904459,
                0.012799827381968498,
                0.017896069213747978,
                -0.035326533019542694,
                -0.045116934925317764,
                0.03969773277640343,
                0.009293464943766594,
                -0.045286137610673904,
                0.05341057479381561,
                -0.02281148172914982,
                0.023877836763858795,
                -0.05561448633670807,
                0.030492516234517097,
                -0.01300433836877346,
                0.025169899687170982,
                -0.008404881693422794,
                0.01920594647526741,
                0.004923155531287193,
                -0.023013509809970856,
                -0.005749532021582127,
                0.0006532908300869167,
                -0.05551556125283241,
                -0.004547684453427792,
                -0.049189191311597824,
                0.010247965343296528,
                0.002431905595585704,
                0.010625352151691914,
                0.010358307510614395,
                -0.03470365330576897,
                -0.07799024879932404,
                1.4713222071804921e-06,
                0.031457822769880295,
                0.00362438359297812,
                -0.06291089951992035,
                -0.03055618330836296,
                0.02110467106103897,
                0.03393106907606125,
                -0.018586784601211548,
                0.033087730407714844,
                0.042295366525650024,
                -0.02721886895596981,
                -0.007030135486274958,
                0.031258948147296906,
                0.03476860374212265,
                0.052378904074430466,
                0.02225758135318756,
                0.021701594814658165,
                0.03707413002848625,
                0.004027143586426973,
                -0.03657115250825882,
                -0.016087274998426437,
                -0.06385236233472824,
                -0.023608488962054253,
                0.03153178468346596,
                -0.012831119820475578,
                0.031865183264017105,
                -0.0431387685239315,
                -0.03552977740764618,
                -0.024866411462426186,
                -0.030819585546851158,
                0.041876718401908875,
                -0.007117193192243576,
                0.01818121410906315,
                0.0024993526749312878,
                0.01910085789859295,
                -0.0031876261346042156,
                0.047344110906124115,
                -0.003360563889145851,
                -0.019980398938059807,
                0.001433966332115233,
                0.0020975905936211348,
                0.006934888660907745,
                -0.039884019643068314,
                -0.009808497503399849,
                0.008446224965155125,
                -0.01631884276866913,
                -0.023302532732486725,
                -0.036835044622421265,
                -0.03402400389313698,
                0.032113078981637955,
                0.11449617147445679,
                -0.006074727512896061,
                -0.03942219540476799,
                -0.046304233372211456,
                -0.017005935311317444,
                -0.012475751340389252,
                0.03265495225787163,
                -0.0641007125377655,
                -0.021596629172563553,
                0.023549580946564674,
                -0.022235458716750145,
                0.0025881945621222258,
                0.017867164686322212,
                0.004955778364092112,
                -0.006391352973878384,
                -0.03680817037820816,
                -0.026722360402345657,
                0.004026074428111315,
                0.035157352685928345,
                -0.021341245621442795,
                0.0481392927467823,
                0.020184962078928947,
                0.05596720799803734,
                0.048286277800798416,
                -0.007050625514239073,
                0.05065764859318733,
                -0.05645117163658142,
                -0.03161238133907318,
                -0.020585404708981514,
                0.05586596950888634,
                0.0327950045466423,
                -0.0102604441344738,
                -0.024276411160826683,
                0.00199587712995708,
                -0.0172371007502079,
                0.03006124310195446,
                -0.09233080595731735,
                -0.03187679499387741,
                -0.037221819162368774,
                -0.006495790556073189,
                -0.003575920592993498,
                0.05727824196219444,
                -0.009729300625622272,
                0.04050025716423988,
                0.0549505241215229,
                -0.03611356019973755,
                -0.02562963403761387,
                -0.009170804172754288,
                0.028999939560890198,
                0.04062739387154579,
                -0.0014368362026289105,
                0.0540066659450531,
                -0.026035869494080544,
                0.02731350064277649,
                -0.0278546791523695,
                -0.00952219683676958,
                -0.0017970012268051505,
                -0.07642792910337448,
                -0.008592618629336357,
                -0.006867901887744665,
                -0.024495666846632957,
                -0.012428359128534794,
                -0.01857731118798256,
                -0.05725368857383728,
                0.036859191954135895,
                -0.022505635395646095,
                0.01832464151084423,
                0.025889091193675995,
                0.023938212543725967,
                -0.024863632395863533,
                0.08879578113555908,
                0.0661868155002594,
                0.06347630172967911,
                0.017952261492609978,
                0.0016873972490429878,
                -0.06143895909190178,
                0.008716612122952938,
                -0.012430408969521523,
                0.02531559020280838,
                -0.01374694425612688,
                0.02752898447215557,
                -0.00844561867415905,
                0.027651792392134666,
                -0.022381190210580826,
                -0.004934150725603104,
                0.020523834973573685,
                0.024620626121759415,
                0.005183571018278599,
                -0.06234975531697273,
                0.03758823871612549,
                0.06144879758358002,
                0.02317694015800953,
                0.02764984779059887,
                0.0002027228329097852,
                -0.03648145869374275,
                0.04571760073304176,
                0.010648727416992188,
                -0.011049221269786358,
                0.041685961186885834,
                0.003440153319388628,
                0.08239039778709412,
                0.03552783280611038,
                0.014083820395171642,
                0.02029702626168728,
                -0.06793022155761719,
                0.011425279080867767,
                -0.0012675097677856684,
                0.0014719902537763119,
                0.0022009750828146935,
                -0.013750202022492886,
                -0.04402526095509529,
                0.010329373180866241,
                -0.0162709578871727,
                0.033032044768333435,
                -0.012279021553695202,
                -0.05564393475651741,
                -0.005059284158051014,
                0.010419115424156189,
                0.018766306340694427,
                0.014281127601861954,
                -0.025201700627803802,
                0.07120418548583984,
                -0.04047766327857971,
                -0.028523914515972137,
                0.019159864634275436,
                0.05403328314423561,
                0.028288258239626884,
                -0.04579315707087517,
                0.00990051869302988,
                -0.01021068450063467,
                0.037116266787052155,
                0.019110998138785362,
                0.015146102756261826,
                0.012700849212706089,
                -0.05490352585911751,
                0.02714419923722744,
                0.00811843667179346,
                0.07951533049345016,
                -0.016534574329853058,
                -0.01248978078365326,
                0.06022363156080246,
                -0.009900304488837719,
                -0.02605416253209114,
                -0.00433310167863965,
                0.06848666071891785,
                0.021890930831432343,
                -0.012893976643681526,
                0.02279374748468399,
                -0.02304728329181671,
                0.07102886587381363,
                0.014828776009380817,
                0.06034677103161812,
                0.007439081557095051,
                -0.027339879423379898,
                0.015912365168333054,
                -0.03810447081923485,
                -0.03107287362217903,
                0.019018419086933136,
                0.006327485200017691,
                0.027035372331738472,
                0.05903051793575287,
                -0.042115695774555206,
                0.046279408037662506,
                0.025520414113998413,
                0.03448716551065445,
                -0.02338152937591076,
                -0.02886028215289116,
                0.04061638563871384,
                0.00375837879255414,
                0.004858785308897495,
                0.0093245729804039,
                -0.053773242980241776,
                0.0006361639825627208,
                -0.009591569192707539,
                -0.07457374781370163,
                0.04811261221766472,
                0.03583276644349098,
                -0.03428003937005997,
                0.03516927361488342,
                0.014072698540985584,
                0.05753547325730324,
                -0.06501863151788712,
                0.007242039777338505,
                -0.0640009194612503,
                -0.0272066667675972,
                0.025779688730835915,
                0.02090596780180931,
                0.01741722598671913,
                -0.03988012671470642,
                -0.01721174828708172,
                -0.016856497153639793,
                0.014838648959994316,
                -0.004631871357560158,
                0.031594548374414444,
                -0.028587285429239273,
                0.061491936445236206,
                0.04881367087364197,
                -0.03352262079715729,
                -0.016250677406787872,
                -0.022412851452827454,
                0.012178492732346058,
                -0.018963869661092758,
                -0.01827910542488098,
                -0.002755007240921259,
                0.03845009207725525,
                0.026048827916383743,
                -0.009168282151222229,
                -0.012117764912545681,
                -0.004720745608210564,
                -0.07765088230371475,
                -0.017590494826436043,
                0.02017778344452381,
                -0.00607472937554121,
                0.02215898036956787,
                0.06977079063653946,
                0.027362478896975517,
                -0.04444628581404686,
                -0.03633620962500572,
                0.016642136499285698,
                0.011507827788591385,
                -0.020752130076289177,
                -0.008382360450923443,
                -0.04936743155121803,
                0.021156607195734978,
                -0.03359367325901985,
                -0.006397339981049299,
                0.052657801657915115,
                0.03013986349105835,
                0.04676538333296776,
                0.00730266934260726,
                0.012044242583215237,
                -0.018883615732192993,
                -0.04606940224766731,
                -0.04181195795536041,
                0.016607601195573807,
                0.02884005941450596,
                -0.003887176513671875,
                0.028255898505449295,
                -0.052093684673309326,
                -0.03826737776398659,
                0.010918213054537773,
                -0.019980505108833313,
                -0.044895950704813004,
                0.03560579568147659,
                0.05374535545706749,
                -0.07068707048892975,
                -0.024338964372873306,
                0.00037967736716382205,
                -0.09262508898973465,
                -0.03837859258055687,
                -0.09055433422327042,
                0.09123124927282333,
                0.00032422260846942663,
                -0.07046400010585785,
                -0.015389976091682911,
                -0.018657488748431206,
                0.0453033447265625,
                -0.025505270808935165,
                -0.03050423599779606,
                0.05626640468835831,
                0.007996993139386177,
                0.028533214703202248,
                0.04867935553193092,
                -0.007726762909442186,
                -0.057018205523490906,
                -0.012319750152528286,
                0.03187726065516472,
                -0.02482183277606964,
                -0.008251791819930077,
                -0.031421028077602386,
                0.024078089743852615,
                -0.021460482850670815,
                -0.016477029770612717,
                0.046825505793094635,
                0.00022893885034136474,
                -0.1130109578371048,
                -0.009030483663082123,
                0.021895213052630424,
                -0.019855156540870667,
                0.015878362581133842,
                0.02589115872979164,
                -0.0006673086900264025,
                0.0068122162483632565,
                0.005410573910921812,
                0.011421207338571548,
                0.01969362422823906,
                0.05303288251161575,
                0.02729674242436886,
                -0.06013839319348335,
                0.0418270081281662,
                -0.010692940093576908,
                0.03427143394947052,
                -0.013374769128859043,
                -0.015310274437069893,
                -0.04041950777173042,
                -0.04950503632426262,
                0.05154350399971008,
                -0.06988866627216339,
                0.031018128618597984,
                -0.01362645998597145,
                -0.005168860778212547,
                -0.052581194788217545,
                0.0014506264124065638,
                -0.007147225551307201,
                -0.02638431265950203,
                0.02521774172782898,
                0.03347257897257805,
                -0.0490744449198246,
                -0.09219346940517426,
                0.03751607984304428,
                -0.025730213150382042,
                0.028314048424363136,
                0.08509897440671921,
                -0.027517694979906082,
                0.029033653438091278,
                -0.015126665122807026,
                0.03987537696957588,
                -0.06017155200242996,
                -0.04669967293739319,
                0.027885109186172485,
                -0.03424551710486412,
                0.051521897315979004,
                0.02967715635895729,
                0.03609265014529228,
                0.002720243064686656,
                -0.040175050497055054,
                0.019041065126657486,
                0.00200579478405416,
                0.008799008093774319,
                0.007971076294779778,
                0.029568267986178398,
                -0.08786848932504654,
                0.0016550725558772683,
                -0.033739060163497925,
                0.01762189343571663,
                0.0313413143157959,
                -0.024665532633662224,
                0.0028983408119529486,
                -0.025731034576892853,
                -0.026974134147167206,
                -0.01407704595476389,
                0.02894612029194832,
                0.09426470845937729,
                0.09008458256721497,
                -0.0059239547699689865,
                -0.013351790606975555,
                0.007685196120291948,
                -0.005730622913688421,
                0.03141050785779953,
                0.0026380755007267,
                0.03458554670214653,
                -0.025849195197224617,
                -0.00446556368842721,
                0.07238222658634186,
                -0.022601919248700142,
                0.024511108174920082,
                -0.021961620077490807,
                0.035122524946928024,
                0.019106807187199593,
                0.0010479831835255027,
                0.005998259410262108,
                -0.01055343821644783,
                0.028164414688944817,
                -0.004228191450238228,
                -0.01909571886062622,
                0.03936983272433281,
                0.010831799358129501,
                -0.05523983761668205,
                -0.01288825087249279,
                -0.09210439026355743,
                -0.030091043561697006,
                -0.03079402633011341,
                -0.06441771984100342,
                -0.05286773294210434,
                -0.001097889500670135,
                -0.022391654551029205,
                0.004246070049703121,
                0.02415921911597252,
                -0.005392623599618673,
                0.016303755342960358,
                -0.06655144691467285,
                -0.06632976979017258,
                0.04349565505981445,
                -0.06019216030836105,
                0.03524552285671234,
                -0.007294640876352787,
                0.004975143354386091,
                0.0259743332862854,
                0.02121715247631073,
                0.05656002461910248,
                0.040519051253795624,
                -0.0045786709524691105,
                -0.008233622647821903,
                0.023339979350566864,
                -0.0026815158780664206,
                -0.013568056747317314,
                -0.008465188555419445,
                0.005707505624741316,
                0.01765831559896469,
                -0.03982904553413391,
                -0.07520069926977158,
                -0.002955968026071787,
                0.02858254499733448,
                -0.016306623816490173,
                -0.013218678534030914,
                -0.046112582087516785,
                0.004541995003819466,
                0.007215969264507294,
                0.04102354496717453,
                -0.05910741165280342,
                -0.031473979353904724,
                0.02259998396039009,
                -0.0016721426509320736,
                0.05027862638235092,
                -0.012262525968253613,
                0.012114325538277626,
                -0.021558107808232307,
                0.020232830196619034,
                -0.009221083484590054,
                -0.049806948751211166,
                0.01572086289525032,
                0.029388809576630592,
                0.04961390048265457,
                0.09471618384122849,
                0.00040417350828647614,
                -0.00905871856957674,
                0.061338551342487335,
                -0.031901054084300995,
                0.019694311544299126,
                -0.11312279850244522,
                -0.04349276423454285,
                0.020249804481863976,
                -0.036565523594617844,
                0.015260719694197178,
                -0.07270731776952744,
                -0.02967439964413643,
                0.016061415895819664,
                0.006184773985296488,
                0.05461464077234268,
                -0.010924309492111206,
                -0.005843565799295902,
                0.04988190531730652,
                -0.002738197334110737,
                -0.006018958054482937,
                -0.004273463040590286,
                -0.019597552716732025,
                -0.03854876756668091,
                -0.027663975954055786,
                -0.06590229272842407,
                0.030551305040717125,
                -0.026255732402205467,
                0.0652412548661232,
                0.005418301559984684,
                0.06442814320325851,
                0.0033642291091382504,
                0.058745674788951874,
                -0.035680558532476425,
                -0.011352404952049255,
                0.022614188492298126,
                0.025773445144295692,
                -0.0008630226366221905,
                -0.026241080835461617,
                0.03097761981189251,
                0.039794210344552994,
                0.00827575009316206,
                0.015451380982995033,
                -0.04103424400091171,
                -0.044657640159130096,
                0.019623715430498123,
                -0.011952171102166176,
                -0.005319918040186167,
                -5.676231162012493e-33,
                -0.05214029178023338,
                -0.07120674848556519,
                0.03443993255496025,
                0.046853210777044296,
                -0.04835974797606468,
                -0.013209430500864983,
                0.014518569223582745,
                0.06959967315196991,
                -0.014124863781034946,
                0.005847215186804533,
                0.0552908219397068,
                0.05555683746933937,
                -0.01757695898413658,
                0.010318038053810596,
                0.012157877907156944,
                -0.012526273727416992,
                0.008606914430856705,
                0.043492887169122696,
                -0.01328619010746479,
                -0.02978619560599327,
                -0.008752727881073952,
                0.000663871702272445,
                0.04751242697238922,
                0.008357616141438484,
                0.026882363483309746,
                -0.013666721060872078,
                0.010094063356518745,
                -0.03199657425284386,
                0.039092056453228,
                0.004035950638353825,
                0.04663599282503128,
                -0.04313371330499649,
                -0.0109038595110178,
                -0.05277429521083832,
                4.615035868482664e-05,
                0.047104161232709885,
                -0.01509176567196846,
                -0.021640751510858536,
                0.030792297795414925,
                -0.009647889994084835,
                -0.03648753464221954,
                -0.0507841631770134,
                -0.0005742994835600257,
                -0.007883316837251186,
                0.020441919565200806,
                -0.07599335163831711,
                0.009302702732384205,
                0.02988734096288681,
                0.01594359800219536,
                0.10065127909183502,
                -0.051874734461307526,
                0.013992403633892536,
                -0.07747253030538559,
                0.004160167183727026,
                -0.012138125486671925,
                0.023388896137475967,
                -0.03856873884797096,
                -0.05591210350394249,
                -0.0715552568435669,
                0.026597635820508003,
                -0.030286071822047234,
                0.06736849248409271,
                0.04172513633966446,
                -0.020274704322218895,
                -0.04290429502725601,
                0.05116994306445122,
                -0.043862100690603256,
                0.02469598688185215,
                0.03302546218037605,
                -0.041186682879924774,
                0.0037316172383725643,
                0.08513502776622772,
                -0.03643263876438141,
                0.002755632158368826,
                -0.08300627022981644,
                -0.059602290391922,
                -0.027621211484074593,
                -0.029431922361254692,
                0.02820163033902645,
                0.028542958199977875,
                -0.02533627115190029,
                0.0017759068869054317,
                -0.011349590495228767,
                -0.007954036816954613,
                -0.021550577133893967,
                0.007298578973859549,
                0.007978519424796104,
                -0.02474966272711754,
                -0.0020145364105701447,
                0.0035599502734839916,
                0.016757948324084282,
                0.018530743196606636,
                0.010454131290316582,
                0.030352281406521797,
                -0.00880418810993433,
                -0.036517299711704254,
                -0.028943682089447975,
                0.0408494807779789,
                -0.00949621107429266,
                -0.02747238613665104,
                -0.0502682700753212,
                -0.035075195133686066,
                -0.038434967398643494,
                0.03476358950138092,
                0.025512950494885445,
                0.0014198807766661048,
                -0.005878676660358906,
                0.0049827746115624905,
                -0.005927643273025751,
                -0.058869969099760056,
                0.0011198846623301506,
                0.04372313618659973,
                -0.03062828630208969,
                -0.00411347346380353,
                -0.05823087692260742,
                -0.006345513742417097,
                -0.04420622065663338,
                0.02593412436544895,
                -0.08678309619426727,
                -0.04879814013838768,
                0.024457747116684914,
                0.03456404060125351,
                -0.00743555324152112,
                -0.052120063453912735,
                -0.030623894184827805,
                -0.008043142035603523,
                -0.01488561276346445,
                -0.006631388794630766,
                0.021889247000217438,
                -0.024523384869098663,
                0.026252293959259987,
                -0.01674899272620678,
                2.1083897649987193e-07,
                -0.021567244082689285,
                0.03701392188668251,
                0.021750433370471,
                0.08512499928474426,
                0.006398637779057026,
                0.013471469283103943,
                0.003447718918323517,
                0.04369458556175232,
                0.016350800171494484,
                0.010463184677064419,
                -0.004326352383941412,
                0.01978624053299427,
                0.03539327532052994,
                -0.04278753697872162,
                0.019443577155470848,
                -0.049858927726745605,
                -0.04753202944993973,
                -0.08682998269796371,
                0.02324124239385128,
                -0.054758887737989426,
                -0.0044404021464288235,
                0.02057560719549656,
                -0.008401444181799889,
                0.004033084027469158,
                -0.003474005963653326,
                0.047408465296030045,
                0.013551714830100536,
                0.011574006639420986,
                0.02399376779794693,
                0.010587537661194801,
                0.010213492438197136,
                -0.02485942840576172,
                0.010128959082067013,
                0.10678967833518982,
                -0.024889549240469933,
                -0.05809003487229347,
                5.663805131916888e-05,
                0.043981608003377914,
                -0.02352346107363701,
                0.054700132459402084,
                0.0021829907782375813,
                0.059871453791856766,
                -0.0392284169793129,
                0.03474539890885353,
                -0.014151996932923794,
                -0.06749384105205536,
                -0.04176386445760727,
                0.01651412807404995,
                -0.007211804389953613,
                0.03382163494825363,
                0.003236358053982258,
                0.04012434557080269,
                0.013490376994013786,
                0.020758580416440964,
                -0.00575334532186389,
                -0.0046491497196257114,
                0.04089910537004471,
                0.008858608081936836,
                0.07933694869279861,
                0.036833297461271286,
                -0.004330608528107405,
                -0.08649568259716034,
                0.011247048154473305,
                -0.05346975103020668,
                -0.003394913859665394,
                0.03988707810640335,
                -0.01392442174255848,
                3.5397946076349634e-35,
                0.02564878575503826,
                0.013554459437727928,
                0.0011553135700523853,
                0.021435217931866646,
                0.05627429485321045,
                -0.04354919120669365,
                0.05378040671348572,
                0.016338583081960678,
                0.003816891461610794,
                0.025684280321002007,
                0.007374519482254982,
                0.02272585965692997,
                0.028537338599562645,
                -0.0700061097741127,
                0.04757436737418175,
                -0.01724594086408615,
                0.008640090934932232,
                0.037552498281002045,
                -0.014634529128670692,
                -0.059123553335666656,
                0.03738628700375557,
                0.10766196995973587,
                0.011411354877054691,
                0.0307154580950737,
                0.06598334014415741,
                -0.04488145187497139,
                -0.025683362036943436,
                0.010596704669296741,
                0.015122534707188606,
                -0.05226029083132744,
                0.0382034108042717,
                0.00794289167970419,
                -0.030808541923761368,
                0.02516014687716961,
                -0.008780263364315033,
                -0.013815856538712978,
                -0.03545674681663513,
                -0.004819370340555906,
                0.0005917723756283522,
                0.010499341413378716,
                -0.04748690128326416,
                0.05836502090096474,
                0.016464781016111374,
                -0.005916474387049675,
                0.01408438105136156,
                1.4304666819953127e-06,
                -0.053586844354867935,
                -0.0042876265943050385,
                0.02263689786195755,
                0.022672681137919426,
                0.0350063219666481,
                0.035849008709192276,
                0.06775464117527008,
                0.0036819041706621647,
                -0.01524345949292183,
                -0.024295523762702942,
                -0.011555890552699566,
                0.03125128895044327,
                -0.028203818947076797,
                0.02382732182741165,
                -0.015134267508983612,
                0.0026660433504730463,
                -0.014387696981430054,
                -0.05486314371228218,
                -0.017068060114979744,
                -0.015491504222154617,
                0.04652721434831619,
                0.02972332015633583,
                0.026065930724143982,
                0.0604100376367569,
                0.015419978648424149,
                -0.00547528313472867,
                0.018890945240855217,
                -0.008786864578723907,
                -0.0012855289969593287,
                0.00627658748999238,
                0.02443498559296131,
                0.026740707457065582,
                -0.050206273794174194,
                0.010958250612020493,
                0.0266592875123024,
                0.0706300139427185,
                0.06314389407634735,
                0.03286400064826012,
                0.03323918208479881,
                -0.04196014255285263,
                0.02738306298851967,
                -0.012406663969159126,
                -0.007867779582738876,
                -0.011812658049166203,
                -0.0450754314661026,
                -0.052147187292575836,
                0.05342567339539528,
                -0.044942427426576614,
                -0.02477727085351944,
                -0.04576830565929413,
                -0.0028535041492432356,
                -0.01691647805273533,
                -0.03703147545456886,
                -0.016567105427384377,
                0.004167705308645964,
                0.010194406844675541,
                -0.03792964667081833,
                -0.015143189579248428,
                0.013594207353889942,
                -0.021259333938360214,
                -0.018339144065976143,
                0.0539391003549099,
                -0.046472709625959396,
                0.03825407102704048,
                -0.0007885982049629092,
                -0.003618321381509304,
                0.02223948761820793,
                -0.029354553669691086,
                -0.0007728161872364581,
                0.015390921384096146,
                0.006174607202410698,
                0.04998001083731651,
                -0.011589011177420616,
                -0.017231175675988197,
                0.08986140042543411,
                -0.036721665412187576,
                0.019182436168193817,
                0.013230266980826855,
                0.04034852981567383,
                0.06495971977710724,
                0.035922784358263016,
                -0.04707665741443634,
                0.0007943965028971434,
                -0.020832937210798264,
                0.03211109712719917,
                -0.04440309479832649,
                -0.009511284530162811,
                0.0056567443534731865,
                0.06072760373353958,
                -0.0259848702698946,
                -0.021175391972064972,
                -0.08040498197078705,
                0.03310932219028473,
                -0.04171013459563255,
                -0.001886508660390973,
                0.03925672173500061,
                0.006131461821496487,
                -0.007434798404574394,
                -0.029346294701099396,
                0.0388253852725029,
                0.010012686252593994,
                -0.00026756341685540974,
                0.03426635265350342,
                -0.1409934014081955,
                -0.01766604371368885,
                0.04577065631747246,
                -0.07887119054794312,
                -0.06693050265312195,
                -0.02664363384246826,
                -0.008940115571022034,
                0.022484123706817627,
                -0.005740346387028694,
                0.04827428609132767,
                0.012782652862370014,
                -0.0014818375930190086,
                -0.021431798115372658,
                0.01391358207911253,
                0.0035486100241541862,
                0.04777933657169342,
                0.07600005716085434,
                0.04058995097875595,
                0.04463895782828331,
                0.0057425349950790405,
                -0.059116706252098083,
                -0.0058242990635335445,
                0.00334379356354475,
                -0.056804902851581573,
                0.04367022588849068,
                -0.017572840675711632,
                0.013132305815815926,
                0.047091465443372726,
                -0.040705714374780655,
                0.06425359845161438,
                -0.08765336126089096,
                -0.04907333850860596,
                -0.026616204530000687,
                0.014823599718511105,
                -0.03527016565203667,
                0.08220735192298889,
                0.00770642189309001,
                0.07139666378498077,
                0.017395637929439545,
                0.0023854209575802088,
                -0.06642865389585495,
                -0.01508618425577879,
                0.008793553337454796,
                -0.0495142862200737,
                0.038598984479904175,
                0.0060753365978598595,
                0.025101007893681526,
                -0.004833625163882971,
                -0.021790338680148125,
                -0.013446349650621414,
                -0.05519158020615578,
                -0.039354681968688965,
                -0.019165676087141037,
                0.0014838760253041983,
                -0.003494538366794586,
                -0.03505279868841171,
                -0.004554164130240679,
                -0.01205440890043974,
                0.03528403863310814,
                -0.07051223516464233,
                0.010240081697702408,
                -0.045584727078676224,
                -0.03237554803490639,
                -0.04111051559448242,
                0.06724700331687927,
                -0.036420632153749466,
                -0.03023510053753853,
                -0.050428345799446106,
                0.024483324959874153,
                -0.04619498923420906,
                -0.004091194365173578,
                -0.0011368189007043839,
                0.007775784004479647,
                2.93769444397185e-05,
                -0.03368976339697838,
                0.020861390978097916,
                -0.018582072108983994,
                0.049101632088422775,
                0.002432559384033084,
                0.012868172489106655,
                -0.08746468275785446,
                0.059211552143096924,
                0.004305210430175066,
                0.07247449457645416,
                -0.023675337433815002,
                0.003000547643750906,
                0.013080866076052189,
                0.026545343920588493,
                0.01903955265879631,
                0.017512131482362747,
                -0.024868177250027657,
                -6.263834075070918e-05,
                -0.019309809431433678,
                0.021002700552344322,
                0.004542168229818344,
                0.018761759623885155,
                0.009161644615232944,
                0.01506477128714323,
                0.14133694767951965,
                0.03398354351520538,
                -0.00014107739843893796,
                -0.0940837487578392,
                -4.157980947638862e-05,
                -0.005762742832303047,
                0.0014904047129675746,
                -0.012936355546116829,
                0.055887043476104736,
                -0.03454814851284027,
                -0.009538901038467884,
                -0.01174259465187788,
                -0.01270397286862135,
                -0.046938031911849976,
                -0.012506511993706226,
                -0.03589227795600891,
                0.04977964237332344,
                0.009536857716739178,
                0.053532496094703674,
                -0.019575579091906548,
                -0.020432939752936363,
                0.05834038183093071,
                -0.01705371029675007,
                -0.005776153411716223,
                -0.03730015829205513,
                -0.06315045058727264,
                -0.009460113011300564,
                0.02580905891954899,
                0.018433578312397003,
                -0.05901153385639191,
                0.04925907030701637,
                0.00887785293161869,
                -0.03729292377829552,
                0.021064693108201027,
                0.030149437487125397,
                -0.0013982460368424654,
                0.02442343905568123,
                -0.0377621203660965,
                -0.012492896057665348,
                0.04269658774137497,
                -0.020814785733819008,
                -0.018979307264089584,
                5.829131623613648e-05,
                0.050484612584114075,
                0.023062916472554207,
                0.0053147487342357635,
                -0.04297095537185669,
                -0.016216838732361794,
                1.113336838898249e-05,
                0.041503097862005234,
                0.06958222389221191,
                -0.05492781102657318,
                0.017948603257536888,
                0.011770477518439293,
                -0.026521865278482437,
                -0.03171290084719658,
                0.02320103533565998,
                -0.06584828346967697,
                0.02564685232937336,
                0.009168576449155807,
                0.019681734964251518,
                0.008812850341200829,
                -0.022457236424088478,
                0.03701057657599449,
                0.0059000831097364426,
                -0.02545084059238434,
                0.03773193061351776,
                -0.018511969596147537,
                0.009121366776525974,
                0.03884731978178024,
                0.07735057920217514,
                -0.06652038544416428,
                0.003801076440140605,
                0.020793717354536057,
                0.040704395622015,
                -0.02292047068476677,
                0.014317977242171764,
                0.06003071367740631,
                -0.01634170301258564,
                0.017643796280026436,
                -0.06466957181692123,
                0.0035832419525831938,
                -0.017754286527633667,
                0.05662345513701439,
                0.030366873368620872,
                -0.00837208516895771,
                -0.01677529886364937,
                0.002267129486426711,
                0.043386586010456085,
                -0.024758193641901016,
                -0.02681155875325203,
                0.05178216099739075,
                0.005860712844878435,
                -0.024317089468240738,
                0.006185461301356554,
                -0.07601689547300339,
                -0.01650308258831501,
                0.04305882379412651,
                -0.05348594859242439,
                0.04175088554620743,
                -0.04368661344051361,
                -0.004542717710137367,
                -0.05011780932545662,
                0.014817779883742332,
                0.006218943279236555,
                0.037662848830223083,
                -0.055234942585229874,
                -0.00906718522310257,
                0.03840097412467003,
                -0.06522020697593689,
                0.024021239951252937,
                0.0007930716965347528,
                -0.014273188076913357,
                0.0021663226652890444,
                0.06367427110671997,
                0.0021505134645849466,
                -0.015054015442728996,
                -0.008731339126825333,
                -0.01273223478347063,
                0.00874333456158638,
                -0.013249075040221214,
                -0.047943420708179474,
                -0.026719799265265465,
                -0.02347259223461151,
                0.015580089762806892,
                -0.024222848936915398,
                -0.03063935972750187,
                0.05217473581433296,
                -0.06605193018913269,
                0.010194738395512104,
                -0.0072062755934894085,
                0.03390389308333397,
                -0.018469423055648804,
                0.004141221754252911,
                0.0027686997782438993,
                0.048879291862249374,
                0.013095756992697716,
                0.022022699937224388,
                0.021122969686985016,
                -0.0005697906017303467,
                0.020496753975749016,
                -0.021989332512021065,
                -0.07615002989768982,
                -0.021222177892923355,
                0.03945169597864151,
                -0.043954964727163315,
                0.06438177824020386,
                0.004179676994681358,
                0.022525155916810036,
                -0.05569811165332794,
                -0.03884630277752876,
                -0.043743591755628586,
                -0.04222424700856209,
                0.047069478780031204,
                0.004034324549138546,
                -0.015816878527402878,
                -0.04390989989042282,
                -0.0222042053937912,
                0.05244629830121994,
                -0.03725336864590645,
                -0.020763501524925232,
                0.012557641603052616,
                0.003754859324544668,
                0.03820152208209038,
                0.02167048491537571,
                -0.009936668910086155,
                -0.0017547167371958494,
                0.01673697680234909,
                -0.021189862862229347,
                -0.021320942789316177,
                -0.005332895088940859,
                0.06997203081846237,
                -0.056379981338977814,
                0.017745114862918854,
                0.0317855104804039,
                -0.02770438976585865,
                0.009182394482195377,
                -0.028438979759812355,
                0.07149951159954071,
                0.07474836707115173,
                -0.04108382388949394,
                -0.03299502283334732,
                0.049320172518491745,
                -0.017801454290747643,
                0.050029393285512924,
                0.03086879663169384,
                0.023966915905475616,
                -0.01930537074804306,
                -0.018112601712346077,
                -0.013425875455141068,
                0.04781588166952133,
                0.0685635656118393,
                -0.00782505702227354,
                0.034401003271341324,
                0.031390201300382614,
                0.006620008964091539,
                -0.015590017661452293,
                0.02434062771499157,
                0.01867055706679821,
                0.03496875986456871,
                0.007913431152701378,
                0.006111496593803167,
                0.009240711107850075,
                -0.027618391439318657,
                -0.02736099250614643,
                0.003979508299380541,
                0.04798431321978569,
                0.03531475365161896,
                0.003058347851037979,
                -0.01889895647764206,
                -0.005531500093638897,
                0.01639745384454727,
                -0.021772248670458794,
                0.07456953078508377,
                0.06832368671894073,
                0.014393231831490993,
                -0.005673218052834272,
                -0.01491847075521946,
                0.022302793338894844,
                -0.019573383033275604,
                -0.08799826353788376,
                -0.07217685133218765,
                -0.02023373916745186,
                0.07013331353664398,
                0.05397903919219971,
                0.046345751732587814,
                -0.061089519411325455,
                -0.015667270869016647,
                0.004774864763021469,
                -0.0752498134970665,
                0.0005437834770418704,
                -0.04871756583452225,
                -0.016103222966194153,
                -0.05172426253557205,
                -0.016824057325720787,
                0.019321974366903305,
                -0.04982971400022507,
                0.07471093535423279,
                0.06757539510726929,
                0.03114989586174488,
                -0.05857829377055168,
                -0.07138276100158691,
                0.026649605482816696,
                -0.02410297468304634,
                0.010510040447115898,
                0.05721383914351463,
                0.0195999126881361,
                -0.02323872596025467,
                -0.015854600816965103,
                0.009795178659260273,
                0.0005555900279432535,
                -0.02234833501279354,
                0.04358656704425812,
                -0.062316760420799255,
                0.006138788536190987,
                0.006567393895238638,
                -0.05980732664465904,
                -0.06262863427400589,
                -0.057435959577560425,
                -0.04426323622465134,
                -0.0602094866335392,
                0.08206592500209808,
                0.04422267898917198,
                0.0029869847930967808,
                -0.016558457165956497,
                0.02721710316836834,
                -0.0002760376373771578,
                -0.05958741158246994,
                0.004978878423571587,
                0.09268263727426529,
                0.031503282487392426,
                0.018106965348124504,
                -0.014676365070044994,
                -0.030161738395690918,
                0.010591632686555386,
                0.01710841991007328,
                0.0245402529835701,
                -0.01898188330233097,
                0.030448265373706818,
                -0.04685335233807564,
                -0.07288500666618347,
                -0.04674367606639862,
                -0.02404061146080494,
                0.00740660447627306,
                0.04219313710927963,
                -0.010108754970133305,
                0.006559273228049278,
                -0.03786712884902954,
                0.07404381036758423,
                0.0074762506410479546,
                -0.026866506785154343,
                -0.020430367439985275,
                -0.018100574612617493,
                0.030689479783177376,
                -0.06098268926143646,
                -0.05389365553855896,
                -0.07729930430650711,
                0.00786235649138689,
                -0.029361853376030922,
                0.1012360006570816,
                0.05896681174635887,
                -0.03428409993648529,
                -0.036311402916908264,
                -0.007309772539883852,
                -0.005812454503029585,
                -0.013265760615468025,
                -0.04574183374643326,
                0.008119088597595692,
                0.05827533081173897,
                0.0336751826107502,
                -0.03919553756713867,
                -0.019727539271116257,
                0.017070788890123367,
                0.037755802273750305,
                -0.03585284948348999,
                0.03185660392045975,
                -0.04756692424416542,
                -0.01718304492533207,
                0.043151989579200745,
                -6.366035124304012e-33,
                -0.008149156346917152,
                -0.058263350278139114,
                0.000532248173840344,
                0.005304162390530109,
                -0.06620344519615173,
                -0.0034417500719428062,
                -0.03789563104510307,
                -0.0017466461285948753,
                -0.03661062940955162,
                -0.058848075568675995,
                -0.029903912916779518,
                0.014529873616993427,
                0.035839296877384186,
                0.005244638305157423,
                0.04762652888894081,
                -0.03261934965848923,
                0.0024752370081841946,
                0.025496143847703934,
                -0.015954140573740005,
                -0.06995266675949097,
                -0.004719429183751345,
                0.043565213680267334,
                0.0017970979679375887,
                -0.002429266693070531,
                0.01914350502192974,
                -0.03632299229502678,
                -0.046890098601579666,
                -0.04545675218105316,
                -0.01450591254979372,
                -0.028559686616063118,
                0.008005021139979362,
                -0.024028096348047256,
                -0.017793796956539154,
                -0.04130753129720688,
                0.010322720743715763,
                0.048065152019262314,
                0.0027806456200778484,
                -0.07236930727958679,
                0.01007211022078991,
                0.014622808434069157,
                0.014118090271949768,
                0.027544405311346054,
                0.002544545801356435,
                -0.010441947728395462,
                -0.005654176231473684,
                -0.010134022682905197,
                0.01200519036501646,
                -0.02429135888814926,
                0.0532560721039772,
                -0.0025378353893756866,
                -0.0014353191945701838,
                0.020272456109523773,
                -0.02754073403775692,
                0.06740136444568634,
                0.032323207706213,
                0.02493840456008911,
                -0.017073623836040497,
                0.012554079294204712,
                0.0034300172701478004,
                0.016095101833343506,
                0.08171284943819046,
                0.019174795597791672,
                0.016746729612350464,
                0.0002899259561672807,
                0.04618101194500923,
                0.0026348649989813566,
                -0.03542756661772728,
                -0.0037181973457336426,
                0.03188382089138031,
                -0.00738461222499609,
                -0.009545165114104748,
                0.045129287987947464,
                -0.01241736114025116,
                0.002950084861367941,
                -0.02909878082573414,
                -0.07844986021518707,
                -0.027998870238661766,
                0.025860169902443886,
                0.06430929899215698,
                -0.018018903210759163,
                -0.0670137107372284,
                -0.00897584855556488,
                -0.024282794445753098,
                -0.002900046994909644,
                -0.008249625563621521,
                -0.0017070049652829766,
                -0.030346238985657692,
                -0.008352094329893589,
                -0.009904775768518448,
                -0.024414507672190666,
                -0.014691128395497799,
                0.018522899597883224,
                0.012156585231423378,
                -0.021346285939216614,
                0.01612713932991028,
                -0.0509878545999527,
                0.016865696758031845,
                0.03543127700686455,
                -0.01679537072777748,
                -0.02149677462875843,
                -0.010366823524236679,
                -0.004339452832937241,
                0.0010617170482873917,
                0.03545214608311653,
                0.03345639631152153,
                -0.0005950613413006067,
                -0.030829070135951042,
                0.005696517415344715,
                -0.048756346106529236,
                -0.009936614893376827,
                0.045328814536333084,
                -0.010000482201576233,
                -0.01832742802798748,
                -0.04438326135277748,
                -0.030679157003760338,
                0.033304255455732346,
                0.0019434485584497452,
                -0.0029687245842069387,
                0.04134848341345787,
                0.01850605569779873,
                -0.030097143724560738,
                0.008381912484765053,
                -0.05123375728726387,
                -0.034180622547864914,
                -0.011005107313394547,
                -0.038347553461790085,
                0.022429566830396652,
                -0.01649177260696888,
                0.004941949155181646,
                -0.04444488510489464,
                -0.003875345690175891,
                -0.06418456882238388,
                2.2870975158184592e-07,
                -0.013201674446463585,
                0.02508583851158619,
                -0.04713485389947891,
                0.036907050758600235,
                0.02202674373984337,
                -0.02938939817249775,
                -0.06072835996747017,
                0.03755588084459305,
                -0.016424529254436493,
                -0.011389573104679585,
                0.060721736401319504,
                -0.04751997068524361,
                -0.015546999871730804,
                0.06267651170492172,
                0.07791703194379807,
                -0.03453880175948143,
                0.009558980353176594,
                -0.030064355581998825,
                -0.02900298871099949,
                0.009979268535971642,
                0.0163531806319952,
                0.014651539735496044,
                -0.0033314803149551153,
                -0.0019517061300575733,
                -0.03607027232646942,
                0.0032000094652175903,
                -0.023891549557447433,
                -0.03294753283262253,
                0.012559976428747177,
                0.013355864211916924,
                0.07674012333154678,
                -0.0007736134575679898,
                0.033807773143053055,
                0.059794798493385315,
                -0.022198861464858055,
                -0.0079879742115736,
                0.04849274829030037,
                -0.017999861389398575,
                0.039591893553733826,
                0.0692729726433754,
                0.00438068900257349,
                -0.020137980580329895,
                0.003166453680023551,
                -0.007486780174076557,
                0.056299228221178055,
                0.0038220833521336317,
                0.013232328929007053,
                0.017475023865699768,
                -0.029863815754652023,
                -0.026338381692767143,
                0.031025823205709457,
                -0.00907762348651886,
                0.030063988640904427,
                0.06847397238016129,
                -0.009941520169377327,
                0.01274135522544384,
                0.05094887316226959,
                0.0717475488781929,
                0.04808487743139267,
                0.037496261298656464,
                0.008722283877432346,
                -0.03205552324652672,
                0.021489888429641724,
                0.009818440303206444,
                0.02844727598130703,
                0.009768614545464516,
                -0.006846072617918253,
                1.1320003710756417e-34,
                0.004621664993464947,
                -0.014986402355134487,
                -0.02888713963329792,
                -0.06501513719558716,
                -0.00130427663680166,
                -0.018429182469844818,
                0.032331183552742004,
                0.02214192785322666,
                0.021402262151241302,
                0.0019706229213625193,
                -0.01148723904043436,
                0.012115138582885265,
                0.03481839969754219,
                -0.03011804260313511,
                -0.002983307233080268,
                0.014219713397324085,
                0.049988385289907455,
                0.02721451222896576,
                -0.016947124153375626,
                0.007550002541393042,
                0.051071666181087494,
                -0.0232480987906456,
                0.04167627915740013,
                -0.038477975875139236,
                0.027181897312402725,
                0.03289930149912834,
                -0.041762929409742355,
                -0.006228004116564989,
                0.017863145098090172,
                0.0955132469534874,
                0.02058674953877926,
                -0.039914585649967194,
                0.0365462563931942,
                -0.014978456310927868,
                -0.017156723886728287,
                -0.024279538542032242,
                -0.039359599351882935,
                0.006679017096757889,
                0.0024191790726035833,
                0.01748727262020111,
                -0.014394663274288177,
                0.00765441358089447,
                0.005118229426443577,
                -0.061004698276519775,
                -0.04772340878844261,
                1.4532565728586633e-06,
                -0.02146562933921814,
                0.005169786047190428,
                -0.03929010406136513,
                0.02393580973148346,
                -0.03383033722639084,
                0.03884510323405266,
                -0.021467551589012146,
                -0.009189842268824577,
                0.030898671597242355,
                -0.00482878927141428,
                0.030158018693327904,
                0.02211909368634224,
                0.045877065509557724,
                0.03632817789912224,
                -0.0016020536422729492,
                -0.0010232932399958372,
                0.03985031321644783,
                0.0012634486192837358,
                -0.03478545695543289,
                0.01973869279026985,
                0.013418136164546013,
                -0.03525061905384064,
                -0.0616966187953949,
                -0.02752443216741085,
                -0.05613546818494797,
                0.012640116736292839,
                -0.019869547337293625,
                -0.006655305158346891,
                -0.04655907675623894,
                -0.005745930131524801,
                -0.03213229775428772,
                -0.002656460739672184,
                -0.014372771605849266,
                -0.018501829355955124,
                -0.01706785522401333,
                0.023551613092422485,
                0.07929554581642151,
                -0.0418841578066349,
                0.07167292386293411,
                0.005657102447003126,
                0.00012734840856865048,
                -0.04920094832777977,
                0.00823246780782938,
                0.020355461165308952,
                -0.054505255073308945,
                0.008249849081039429,
                0.03920485079288483,
                -0.026236223056912422,
                0.059695594012737274,
                0.05420025810599327,
                0.035771168768405914,
                -0.00906580314040184,
                -0.0027411349583417177,
                0.038876209408044815,
                -0.016115011647343636,
                -0.024558037519454956,
                0.03003978170454502,
                -0.022685455158352852,
                0.024488545954227448,
                0.005453585181385279,
                -0.013104778714478016,
                0.007749573327600956,
                0.024804212152957916,
                -0.07407695800065994,
                -0.004731771070510149,
                -0.04751882329583168,
                0.0032699587754905224,
                0.01919776201248169,
                -0.01445893757045269,
                0.0024966381024569273,
                0.10319515317678452,
                0.03569431230425835,
                0.04085259139537811,
                0.001375695108436048,
                0.029631005600094795,
                0.002431933069601655,
                -0.02617882564663887,
                -0.009558016434311867,
                0.043997496366500854,
                0.042817872017621994,
                -0.029946543276309967,
                -0.0574176162481308,
                -0.0023060329258441925,
                -0.0731847956776619,
                0.005473439581692219,
                -0.046614162623882294,
                -0.009925927966833115,
                -0.015931056812405586,
                -0.004555984865874052,
                0.0015821941196918488,
                0.010702761821448803,
                -0.019321443513035774,
                0.004537397995591164,
                -0.003352558007463813,
                0.04231336712837219,
                -0.01984279789030552,
                -0.0011740302434191108,
                0.02580357901751995,
                0.020545659586787224,
                0.025432677939534187,
                0.06447463482618332,
                0.040181029587984085,
                -0.046112027019262314,
                -0.11075430363416672,
                0.03126842901110649,
                0.03166878595948219,
                -0.024871697649359703,
                0.03410113975405693,
                -0.013147990219295025,
                0.0024158419109880924,
                -0.012230261228978634,
                -0.0011048804735764861,
                -0.06739384680986404,
                0.007737352512776852,
                -0.029488611966371536,
                0.021588463336229324,
                -0.030078236013650894,
                -0.03186286613345146,
                -0.03926108404994011,
                0.017702078446745872,
                0.012875746935606003,
                0.02373497188091278,
                0.014843756332993507,
                0.012783697806298733,
                -0.041062142699956894,
                0.04831167310476303,
                0.009396425448358059,
                0.005253271199762821,
                -0.00660743797197938,
                -0.028819598257541656,
                -0.001250646193511784,
                0.032665569335222244,
                -0.05952894315123558,
                -0.033856481313705444,
                -0.011559786275029182,
                0.026013445109128952,
                -0.02964020147919655,
                -0.008294851519167423,
                0.017990995198488235,
                0.016461750492453575,
                0.06379041820764542,
                -0.026168836280703545,
                0.05171974375844002,
                -0.023963563144207,
                0.010133517906069756,
                0.010303229093551636,
                -0.012872523628175259,
                -0.01852695643901825,
                0.07393021881580353,
                0.011956899426877499,
                -0.006282983347773552,
                0.0018554313573986292,
                0.011007329449057579,
                -0.021325523033738136,
                -0.00019153108587488532,
                6.474660040112212e-05,
                -0.03756171464920044,
                -0.04062386974692345,
                -0.029051247984170914,
                -0.03769220784306526,
                -0.02064005844295025,
                0.012745043262839317,
                -0.07084694504737854,
                0.028993334621191025,
                -0.04198608547449112,
                -0.008088375441730022,
                -0.005874887574464083,
                0.03397883102297783,
                0.051151033490896225,
                -0.010449533350765705,
                0.02595522068440914,
                0.024285638704895973,
                -0.010055706836283207,
                -0.014337204396724701,
                0.023560289293527603,
                0.04244989529252052,
                -0.030593348667025566,
                0.016529830172657967,
                -0.004310264252126217,
                0.027567969635128975,
                0.022798361256718636,
                0.014229116030037403,
                -0.0024756339844316244,
                -0.04632619395852089,
                0.02977997064590454,
                -0.0009564241045154631,
                0.051796045154333115,
                -0.005119210109114647,
                -0.016734054312109947,
                -0.02669680491089821,
                -0.02280695177614689,
                -0.029064254835247993,
                0.005205606110394001,
                0.03988935798406601,
                0.02184789441525936,
                -0.014064913615584373,
                0.009495507925748825,
                0.0031353202648460865,
                0.012236195616424084,
                -0.01564846932888031,
                0.028680186718702316,
                0.08611227571964264,
                0.016594525426626205,
                -0.004469320643693209,
                -0.04889697581529617,
                -0.03227224946022034,
                0.02591390535235405,
                0.026852233335375786,
                0.016811348497867584,
                0.040629804134368896,
                -0.037429019808769226,
                0.0030006689485162497,
                0.010339700616896152,
                0.046155620366334915,
                -0.01294658798724413,
                0.004517254885286093,
                0.020797124132514,
                0.019937576726078987,
                -0.023845091462135315,
                0.02843589335680008,
                -0.04833950474858284,
                0.012853139080107212,
                -0.011760873720049858,
                -0.03025558590888977,
                -0.00914592482149601,
                0.01185599621385336,
                -0.011950007639825344,
                -0.010332016274333,
                -0.015022172592580318,
                0.026190267875790596,
                -0.024862239137291908,
                -0.02774118073284626,
                -0.0879211351275444,
                0.018201809376478195,
                0.03892894461750984,
                -0.0028249798342585564,
                -0.0026297862641513348,
                -0.0009251800365746021,
                -0.021026751026511192,
                0.02917209453880787,
                0.011811953969299793,
                -0.025778625160455704,
                0.0019114179303869605,
                -0.024627424776554108,
                -0.011884015053510666,
                0.027773825451731682,
                -0.018245171755552292,
                -0.012980720028281212,
                0.00020370855054352432,
                -0.02242129109799862,
                0.021385932341217995,
                -0.005199497565627098,
                -0.003097302047535777,
                0.046462927013635635,
                -0.060037944465875626,
                -0.046416278928518295,
                0.032591260969638824,
                0.016758622601628304,
                -0.10614024102687836,
                0.009383941069245338,
                0.015618427656590939,
                -0.012115880846977234,
                0.06418487429618835,
                0.020490719005465508,
                0.024694757536053658,
                -0.04729420691728592,
                0.022117363288998604,
                -0.01614297926425934,
                0.03634397312998772,
                0.009064382873475552,
                0.05411212518811226,
                0.008399447426199913,
                0.01524460781365633,
                -0.04201950132846832,
                0.059605419635772705,
                0.0023132001515477896,
                0.004858547355979681,
                0.018776455894112587,
                0.06994214653968811,
                0.05519385635852814,
                -0.048155225813388824,
                -0.012550560757517815,
                -0.007114865817129612,
                -0.016429372131824493,
                0.0454540029168129,
                -0.00032494214246980846,
                -0.013942059129476547,
                -0.02047009952366352,
                -0.005542254541069269,
                0.014883951283991337,
                -0.02040751650929451,
                -0.014665832743048668,
                0.040435098111629486,
                0.0567956306040287,
                -0.06781948357820511,
                -0.032298218458890915,
                -0.07679218798875809,
                -0.002975917188450694,
                -0.06382590532302856,
                -0.036813464015722275,
                -0.029028618708252907,
                -0.035843007266521454,
                -0.04025822505354881,
                -0.04121096059679985,
                -0.04602520540356636,
                0.05514969304203987,
                -0.005515091121196747,
                -0.034579407423734665,
                0.05001899227499962,
                -0.03457695245742798,
                0.0003894261899404228,
                0.0009597358293831348,
                -0.00828792154788971,
                -0.052868831902742386,
                -0.05821775272488594,
                0.055664438754320145,
                0.01602249965071678,
                -0.024279838427901268,
                -0.007328117731958628,
                0.06163311377167702,
                -0.03430071473121643,
                0.05630364641547203,
                0.013833208940923214,
                0.041831307113170624,
                -0.062148965895175934,
                0.028699222952127457,
                0.05785991996526718,
                -0.02150360681116581,
                -0.0074253324419260025,
                0.04194234684109688,
                0.04643630608916283,
                0.026288753375411034,
                0.022820593789219856,
                -0.019268423318862915,
                -0.004967440385371447,
                -0.02803572453558445,
                0.01486431434750557,
                0.006788125727325678,
                0.06904017180204391,
                0.026058031246066093,
                0.021711720153689384,
                0.010062659159302711,
                -0.02804170362651348,
                -0.017269548028707504,
                -0.014046472497284412,
                0.037806056439876556,
                0.030718108639121056,
                0.0008777915500104427,
                0.02813931554555893,
                0.009442716836929321,
                -0.03974464535713196,
                -0.04308333992958069,
                0.0057990369386971,
                -0.011694268323481083,
                0.020823802798986435,
                0.026527810841798782,
                0.020812468603253365,
                -0.11971617490053177,
                0.0643157809972763,
                0.047462597489356995,
                0.058747053146362305,
                -0.025933070108294487,
                -0.013814643956720829,
                0.040038399398326874,
                0.01597832888364792,
                -0.008459036238491535,
                0.007172300014644861,
                0.021138783544301987,
                0.030269641429185867,
                -0.043905485421419144,
                -0.007188121322542429,
                0.021578066051006317,
                0.03252973034977913,
                -0.045569855719804764,
                -0.022870421409606934,
                -0.008571590296924114,
                0.0709676593542099,
                -0.023303914815187454,
                0.021685129031538963,
                0.06650307029485703,
                -0.08827874809503555,
                0.017001664265990257,
                -0.04921264201402664,
                0.026778260245919228,
                0.041228607296943665,
                -0.0022883599158376455,
                0.04385540634393692,
                -0.01192670688033104,
                -0.02918129973113537,
                -0.0029444291722029448,
                -0.0179546307772398,
                0.024255668744444847,
                0.014965422451496124,
                0.01073712669312954,
                -0.017793968319892883,
                -0.051479972898960114,
                0.005967693403363228,
                0.004930566065013409,
                0.009257459081709385,
                0.010118581354618073,
                -0.07725124061107635,
                0.04654381051659584,
                0.051581624895334244,
                -0.03715606406331062,
                -0.011762214824557304,
                -0.036238059401512146,
                0.0025915743317455053,
                0.06791166961193085,
                -0.009025201201438904,
                -0.11892605572938919,
                -0.048713911324739456,
                0.016285715624690056,
                0.0030990405939519405,
                -0.006268313154578209,
                0.0637626051902771,
                -0.0020815401803702116,
                -0.031453993171453476,
                -0.011636437848210335,
                -0.015673024579882622,
                -0.03576071560382843,
                -0.004856312181800604,
                -0.014404709450900555,
                0.09096286445856094,
                0.011716224253177643,
                0.0532347597181797,
                0.039117492735385895,
                0.026984116062521935,
                -0.017565913498401642,
                0.014055799692869186,
                -0.04886597767472267,
                0.017577311024069786,
                -0.052971888333559036,
                -0.040270570665597916,
                0.026570860296487808,
                -0.01838543824851513,
                0.054864611476659775,
                -0.012346409261226654,
                0.04545889049768448,
                -0.019236747175455093,
                0.07822778820991516,
                0.006128503009676933,
                -0.019885659217834473,
                -0.004210867453366518,
                -0.02673257142305374,
                -0.032366495579481125,
                0.014097064733505249,
                0.01536878664046526,
                -0.01906396821141243,
                -0.020637333393096924,
                -0.04210279509425163,
                -0.025753144174814224,
                -0.034304261207580566,
                -0.024949297308921814,
                -0.011889789253473282,
                -0.047656815499067307,
                -0.02466484345495701,
                0.05516604334115982,
                -0.03257004916667938,
                -0.08558788150548935,
                0.029339047148823738,
                -0.029280219227075577,
                -0.01555590145289898,
                0.009756438434123993,
                -0.08513394743204117,
                0.01050884835422039,
                -0.05761410668492317,
                0.01627478189766407,
                -0.022466449066996574,
                -0.05583483353257179,
                -0.015181058086454868,
                0.02626211568713188,
                0.03534264862537384,
                0.08654212951660156,
                0.004913908429443836,
                -0.0328090526163578,
                0.048680756241083145,
                0.010013728402554989,
                -0.01447077002376318,
                -0.01204996183514595,
                0.014596412889659405,
                0.07355501502752304,
                -0.08336980640888214,
                0.0028155772015452385,
                -0.05223250016570091,
                -0.01334238238632679,
                0.005475869867950678,
                -0.02284371294081211,
                0.018769288435578346,
                0.021974025294184685,
                -0.0050423769280314445,
                0.023836972191929817,
                0.015666160732507706,
                0.012300181202590466,
                -0.011630537919700146,
                -0.015243123285472393,
                -0.08782949298620224,
                0.045221321284770966,
                -0.11251851916313171,
                -0.007754613645374775,
                -0.03649596869945526,
                0.07469256222248077,
                0.018871687352657318,
                0.01803535968065262,
                0.0255915354937315,
                0.056910764425992966,
                -0.07272693514823914,
                0.0090870913118124,
                0.05175590515136719,
                0.01694931834936142,
                -0.017207661643624306,
                -0.022915128618478775,
                -0.0520472526550293,
                0.031015589833259583,
                -0.008971151895821095,
                0.004696054384112358,
                0.0017825487302616239,
                -0.04415575787425041,
                -0.11970879137516022,
                -0.002294067991897464,
                -0.002136851893737912,
                -5.218502478009925e-33,
                -0.030467815697193146,
                -0.02287454903125763,
                -0.03182384371757507,
                0.026082616299390793,
                -0.029878390952944756,
                -0.01325106993317604,
                0.03387964516878128,
                0.045186638832092285,
                -0.010201936587691307,
                0.016729457303881645,
                -0.002204898279160261,
                0.032442811876535416,
                -0.013861083425581455,
                -0.04037163779139519,
                -0.016567477956414223,
                0.01200634054839611,
                -0.04599643498659134,
                0.014401547610759735,
                -0.029755380004644394,
                -0.03342004120349884,
                0.0069142961874604225,
                0.024348611012101173,
                0.03692034259438515,
                -0.05523771047592163,
                0.010543586686253548,
                0.009843346662819386,
                0.04539073258638382,
                -0.03143918514251709,
                0.08452709764242172,
                0.01193070039153099,
                0.035127781331539154,
                -0.007711628917604685,
                -0.009747399017214775,
                -0.02834479883313179,
                0.033290937542915344,
                0.03255142644047737,
                0.01229176577180624,
                -0.0422985665500164,
                -0.0007435654406435788,
                0.00263961055316031,
                -0.07228231430053711,
                -0.0788642168045044,
                -0.010274199768900871,
                0.016520772129297256,
                -0.011836784891784191,
                -0.09837896376848221,
                0.018243519589304924,
                0.0022545885294675827,
                0.05495476722717285,
                -0.027571674436330795,
                -0.017928630113601685,
                -0.018945738673210144,
                -0.05181724578142166,
                0.010503730736672878,
                -0.03829047456383705,
                -0.011887287721037865,
                -0.02021382376551628,
                0.013037378899753094,
                -0.02049339935183525,
                -0.008138014003634453,
                -0.09185272455215454,
                -0.002414907794445753,
                -0.02168511040508747,
                -0.03684375807642937,
                -0.013473479077219963,
                0.043001025915145874,
                0.01784610189497471,
                0.02168283984065056,
                0.029019903391599655,
                0.010338332504034042,
                0.05570710077881813,
                0.07862263917922974,
                0.0719376802444458,
                -0.06262500584125519,
                -0.12263185530900955,
                -0.042381662875413895,
                -0.011559700593352318,
                0.02674916572868824,
                0.07595731317996979,
                0.027241619303822517,
                0.0054750037379562855,
                -0.020892852917313576,
                -0.02319466508924961,
                0.023218270391225815,
                0.0008354346500709653,
                -0.014482326805591583,
                0.0018917365232482553,
                0.01308561209589243,
                0.006971781142055988,
                -0.07001087069511414,
                0.029664769768714905,
                -0.004273764323443174,
                -0.00784314889460802,
                0.016969650983810425,
                0.043305568397045135,
                0.011468321084976196,
                -0.039730072021484375,
                0.04492674022912979,
                0.006560961715877056,
                -0.021731533110141754,
                0.0523918978869915,
                0.015533546917140484,
                -0.00289342924952507,
                0.0042806752026081085,
                0.0686560794711113,
                -0.0383189357817173,
                -0.011631862260401249,
                0.04411964491009712,
                -0.028509071096777916,
                -0.043454211205244064,
                -0.009663800708949566,
                0.03574497252702713,
                0.04651341959834099,
                -0.02087293192744255,
                -0.03839263692498207,
                -0.007318347692489624,
                0.01925504580140114,
                -0.056973062455654144,
                -0.03999003395438194,
                0.05785377323627472,
                0.03430002182722092,
                0.00912440475076437,
                -0.004027578979730606,
                -0.01845249906182289,
                -0.05808675289154053,
                0.007722548674792051,
                -0.006582421250641346,
                -0.04359586164355278,
                -0.09590114653110504,
                -0.011219938285648823,
                0.02509194426238537,
                0.05428926274180412,
                2.1731716515205335e-07,
                -0.07823111116886139,
                0.03698815777897835,
                0.012276716530323029,
                -0.010187807492911816,
                0.00018408556934446096,
                0.020798521116375923,
                -0.02351706102490425,
                0.015067409723997116,
                0.08025841414928436,
                0.019039146602153778,
                7.497629849240184e-05,
                0.010700546205043793,
                0.039891984313726425,
                -0.04446084424853325,
                0.012212068773806095,
                -0.03999142721295357,
                0.05416574329137802,
                -0.012287255376577377,
                0.0029963890556246042,
                -0.020126517862081528,
                0.01709875464439392,
                0.057514362037181854,
                0.01859697699546814,
                0.013200179673731327,
                -0.005839134566485882,
                0.01691019907593727,
                -0.017126284539699554,
                0.010200733318924904,
                0.04640812799334526,
                -0.030106419697403908,
                0.05410920828580856,
                -0.017288003116846085,
                -1.6605201381025836e-05,
                0.06795451045036316,
                -0.030361898243427277,
                0.0018953371327370405,
                0.019118856638669968,
                0.06547451764345169,
                -0.02089260332286358,
                0.03840083256363869,
                0.06176605448126793,
                0.05673931539058685,
                -0.01035426277667284,
                0.09054408222436905,
                0.05027053877711296,
                -0.035420872271060944,
                -0.05433375760912895,
                0.03272873908281326,
                -0.07293818145990372,
                0.007690390571951866,
                -0.007254313677549362,
                0.04619491100311279,
                -0.014406273141503334,
                0.06724005937576294,
                0.0029999185353517532,
                -0.07957948744297028,
                0.046927500516176224,
                -0.004426049068570137,
                0.060753945261240005,
                -0.009939207695424557,
                0.0020459205843508244,
                -0.05017571896314621,
                -0.02913106605410576,
                0.04460625723004341,
                -0.0017062308033928275,
                -0.004391965921968222,
                -0.00671374099329114,
                5.992597306066946e-35,
                0.009737750515341759,
                0.01279823575168848,
                0.0072032795287668705,
                -0.02691052481532097,
                0.0653710588812828,
                -0.023208608850836754,
                0.0031783340964466333,
                0.014374577440321445,
                0.002840227447450161,
                -0.01909242570400238,
                0.024775806814432144,
                0.032467592507600784,
                -0.06776800006628036,
                -0.013221646659076214,
                0.0022721022833138704,
                -0.049086276441812515,
                0.02653006836771965,
                -0.017886431887745857,
                0.021437736228108406,
                -0.05896434187889099,
                0.03658934310078621,
                0.11515730619430542,
                0.024856409057974815,
                0.010829627513885498,
                0.04418177157640457,
                -0.009737039916217327,
                -0.043893877416849136,
                0.0021841763518750668,
                0.0035959084052592516,
                0.0278550423681736,
                -0.0017374176532030106,
                -0.004975695163011551,
                0.0017367203254252672,
                -0.03174789994955063,
                -0.013686458580195904,
                -0.045376040041446686,
                -0.08624924719333649,
                0.0068021854385733604,
                -0.027944350615143776,
                -0.0052631753496825695,
                0.004293961450457573,
                0.021001579239964485,
                -0.026406483724713326,
                -0.009780834428966045,
                0.0008355132886208594,
                1.8760975990517181e-06,
                -0.022976886481046677,
                -0.028813425451517105,
                -0.006170356180518866,
                -0.017850158736109734,
                -0.011390529572963715,
                -0.011635848321020603,
                0.026770833879709244,
                -0.0011497060768306255,
                0.05853499099612236,
                -0.0366026945412159,
                -0.0036922707222402096,
                0.02895563282072544,
                0.04689301550388336,
                0.02032303996384144,
                -0.016534917056560516,
                0.005482177250087261,
                -0.0008461525430902839,
                -0.046579014509916306,
                -0.03587963059544563,
                0.019046638160943985,
                -0.08084482699632645,
                0.03394577279686928,
                0.05817358195781708,
                0.09921465069055557,
                -0.05218799039721489,
                -0.015546188689768314,
                -0.002443843986839056,
                0.011974210850894451,
                0.010805835947394371,
                0.025905130431056023,
                0.010197640396654606,
                0.045214828103780746,
                -0.007852209731936455,
                0.02580093964934349,
                -0.012405019253492355,
                0.01634126715362072,
                0.0423383004963398,
                0.001824515056796372,
                0.06485553085803986,
                -0.032386425882577896,
                0.03322266787290573,
                -0.03257744759321213,
                0.04479197785258293,
                -0.049393340945243835,
                -0.029934292659163475,
                -0.022974230349063873,
                0.017940852791070938,
                -0.023214207962155342,
                -0.000778638874180615,
                0.010267410427331924,
                0.06292524933815002,
                -0.04300277680158615,
                -0.036436937749385834,
                0.00020334243890829384,
                -0.012533290311694145,
                -0.034055162221193314,
                -0.04181673377752304,
                0.039189714938402176,
                0.030962729826569557,
                -0.016004111617803574,
                -0.0016948458505794406,
                0.04350253567099571,
                -0.026937784627079964,
                -0.02999882586300373,
                0.009041782468557358,
                0.03148313984274864,
                0.031581852585077286,
                0.0927826389670372,
                0.03467053547501564,
                -0.00891034584492445,
                -0.005405801348388195,
                -0.041180528700351715,
                -0.023125695064663887,
                -0.053053684532642365,
                0.10039795935153961,
                -0.07356784492731094,
                -0.027961211279034615,
                -0.0710625946521759,
                0.0775146633386612,
                0.04323574900627136,
                0.0059621287509799,
                -0.008892577141523361,
                -0.015000627376139164,
                0.0023787766695022583,
                -0.009042073972523212,
                -0.0737602710723877,
                0.011285070329904556,
                -0.037325549870729446,
                0.02520807832479477,
                -0.020535122603178024,
                0.0503324419260025,
                -0.06470542401075363,
                0.022688020020723343,
                -0.02072146348655224,
                0.028545504435896873,
                -0.009077037684619427,
                -0.01389201171696186,
                -0.03838874027132988,
                -0.045912668108940125,
                0.00643161078915,
                0.037152644246816635,
                0.004827949218451977,
                0.03271690756082535,
                -0.07702614367008209,
                -0.050681717693805695,
                0.05374465882778168,
                -0.07997696101665497,
                -0.03920034319162369,
                -0.03746945410966873,
                -0.04750559478998184,
                -0.0260910764336586,
                0.021492300555109978,
                -0.004873240366578102,
                0.009699555113911629,
                -0.022231953218579292,
                0.005588459316641092,
                0.001786601496860385,
                0.001498950645327568,
                0.012805289588868618,
                0.10269051790237427,
                0.07327909022569656,
                0.025520017370581627,
                0.030808553099632263,
                0.009432004764676094,
                -0.032492496073246,
                -0.017671354115009308,
                0.009052643552422523,
                0.04756033420562744,
                -0.034111637622117996,
                0.03951746225357056,
                0.004199380986392498,
                0.06551581621170044,
                -0.010412333533167839,
                -0.028869735077023506,
                0.022864550352096558,
                -0.02341250702738762,
                -0.00520532950758934,
                -0.04228944331407547,
                0.08377554267644882,
                0.02516373246908188,
                0.05221688002347946,
                0.010455640964210033,
                0.028631940484046936,
                -0.062119998037815094,
                -0.01804204471409321,
                -0.011962809599936008,
                -0.019513459876179695,
                0.012931005097925663,
                0.07803923636674881,
                -0.02147301472723484,
                0.031650129705667496,
                -0.037120793014764786,
                0.004306819289922714,
                -0.04418404772877693,
                -0.011961538344621658,
                -0.019027676433324814,
                0.027835017070174217,
                -0.022566597908735275,
                0.00011260818428127095,
                0.026179194450378418,
                0.01590817980468273,
                0.027780607342720032,
                -0.030671048909425735,
                -0.02815118059515953,
                0.02069707028567791,
                -0.005999724846333265,
                -0.03388507291674614,
                0.08217942714691162,
                0.013366324827075005,
                -0.005228162277489901,
                -0.012113960459828377,
                -0.04845067858695984,
                -0.042076073586940765,
                0.04834846034646034,
                -0.07719330489635468,
                0.0230291448533535,
                0.010994747281074524,
                -0.0011732804123312235,
                0.014200909063220024,
                -0.009845578111708164,
                -0.02738756686449051,
                -0.004708583001047373,
                -0.015423009172081947,
                0.006092783994972706,
                -0.009641360491514206,
                -0.03026055172085762,
                0.0673837661743164,
                0.04386858269572258,
                -0.007338047958910465,
                -0.034747179597616196,
                -0.004757953342050314,
                -0.02238466776907444,
                -0.008185234852135181,
                -0.016504254192113876,
                -0.01755577325820923,
                -0.012381475418806076,
                0.056571561843156815,
                0.014235944487154484,
                -0.015113855712115765,
                -0.014633764512836933,
                0.04826686158776283,
                0.052676767110824585,
                -0.002115850569680333,
                0.002579128835350275,
                -0.11395872384309769,
                -0.02447739988565445,
                -0.019040396437048912,
                -0.008675126358866692,
                -0.0001522743987152353,
                0.08211272954940796,
                -0.050646234303712845,
                -0.000815939623862505,
                0.005604587960988283,
                0.016449904069304466,
                -0.038910310715436935,
                -0.0936003029346466,
                0.003955323714762926,
                0.03844257816672325,
                0.00131359719671309,
                0.044521741569042206,
                -0.03545909374952316,
                -0.024222327396273613,
                0.01856415718793869,
                -0.06586134433746338,
                0.006721499375998974,
                -0.023112161085009575,
                -0.014187978580594063,
                -0.02598216012120247,
                -0.020786644890904427,
                0.05654625967144966,
                -0.04216907545924187,
                0.021491792052984238,
                0.04190145805478096,
                -0.014250271022319794,
                0.006820519454777241,
                0.04145557060837746,
                0.009691913612186909,
                0.025005511939525604,
                -0.011325690895318985,
                -0.029084162786602974,
                0.022417433559894562,
                -0.05007712170481682,
                -0.0002247102529508993,
                -0.022000109776854515,
                0.022101137787103653,
                0.04059673845767975,
                -0.005932316184043884,
                -0.027951980009675026,
                -0.023943109437823296,
                -0.022730590775609016,
                -0.014653511345386505,
                0.00929765123873949,
                -0.04280248284339905,
                -0.024264205247163773,
                0.013181780464947224,
                -0.049963537603616714,
                0.025530999526381493,
                0.028397556394338608,
                -0.015637394040822983,
                0.023770852014422417,
                -0.03049054555594921,
                0.003508029505610466,
                0.029367413371801376,
                0.022606872022151947,
                0.016452090814709663,
                -0.010701301507651806,
                -0.016477394849061966,
                0.0008846980636008084,
                -0.025601835921406746,
                -0.011517098173499107,
                0.015605689957737923,
                0.05322905257344246,
                -0.06822048127651215,
                -0.019616959616541862,
                0.06200367584824562,
                0.01710534654557705,
                -0.00961703434586525,
                0.008149110712110996,
                0.069522924721241,
                -0.03800755366683006,
                -0.01717914640903473,
                -0.08801282942295074,
                -0.03587092086672783,
                0.029216663911938667,
                0.05204860493540764,
                -0.010505144484341145,
                -0.003371603088453412,
                0.06579366326332092,
                0.05888098105788231,
                0.013452555052936077,
                -0.05640421435236931,
                0.012664354406297207,
                0.007609629537910223,
                0.028902316465973854,
                -0.05432429537177086,
                0.044272538274526596,
                -0.04768385365605354,
                -0.03488019108772278,
                0.01157392468303442,
                -0.03681930527091026,
                0.06474901735782623,
                -0.020399093627929688,
                -0.028796272352337837,
                -0.03501347452402115,
                -0.007723615504801273,
                -0.007327598053961992,
                -0.05689500272274017,
                -0.023755282163619995,
                0.00217245239764452,
                0.018313858658075333,
                -0.020291754975914955,
                -0.00030788392177782953,
                0.014909796416759491,
                -0.05524434521794319,
                -0.012913782149553299,
                0.048740729689598083,
                0.010399787686765194,
                -0.0029786680825054646,
                -0.04650929570198059,
                -0.010337857529520988,
                -0.022915862500667572,
                -0.02620343305170536,
                0.042169179767370224,
                0.029211167246103287,
                -0.043492790311574936,
                0.04539741203188896,
                0.046655066311359406,
                0.08642839640378952,
                0.025200320407748222,
                0.006077614147216082,
                0.0675462856888771,
                0.034944694489240646,
                0.018035126850008965,
                -0.057437215000391006,
                -0.0004293161618988961,
                -0.013775119557976723,
                0.012481874786317348,
                -0.032828234136104584,
                0.03963354602456093,
                0.06305532157421112,
                0.05556727200746536,
                0.014031585305929184,
                -0.08329372107982635,
                -0.07420904189348221,
                -0.010751914232969284,
                0.042945586144924164,
                -0.06303253769874573,
                0.03815891221165657,
                0.04025372117757797,
                -0.01594679243862629,
                -0.011381621472537518,
                -0.015517976135015488,
                -0.02973923087120056,
                -0.05714961141347885,
                0.03227263689041138,
                0.02460552752017975,
                0.010956530459225178,
                -0.07258697599172592,
                0.017323359847068787,
                -0.013076769188046455,
                -0.004114265087991953,
                -0.03771959990262985,
                0.02706534042954445,
                -0.023314280435442924,
                0.043974023312330246,
                0.007075380999594927,
                0.057694870978593826,
                0.014539258554577827,
                0.012951791286468506,
                -0.01784341037273407,
                -0.04456721618771553,
                0.026798062026500702,
                0.023623397573828697,
                0.02006906270980835,
                -0.03740471974015236,
                0.00995485670864582,
                0.06704730540513992,
                0.015689102932810783,
                -0.027213875204324722,
                -0.014439905062317848,
                -0.0301378034055233,
                -0.002798306057229638,
                -0.01701492816209793,
                0.014710885472595692,
                -0.051541004329919815,
                -0.025532305240631104,
                0.043402958661317825,
                0.02707776613533497,
                0.005388728808611631,
                0.06197498366236687,
                -0.009509673342108727,
                0.06538631021976471,
                0.030943065881729126,
                -0.00038681854493916035,
                -0.009821782819926739,
                0.04398169741034508,
                -0.007649112492799759,
                0.00016815069830045104,
                0.03358417749404907,
                0.05090531334280968,
                -0.030070584267377853,
                0.007551048882305622,
                0.06863615661859512,
                0.048314861953258514,
                -0.024295520037412643,
                0.0006606192328035831,
                0.06297087669372559,
                0.11898891627788544,
                0.025409463793039322,
                -0.032410476356744766,
                0.004340615123510361,
                0.05261604115366936,
                -0.015792954713106155,
                -0.01817476749420166,
                0.057506341487169266,
                0.06707929074764252,
                -0.031092654913663864,
                0.04788171872496605,
                -0.06296110898256302,
                0.00781666487455368,
                -0.02498745545744896,
                -0.030908236280083656,
                -0.04322129115462303,
                -0.02458290383219719,
                -0.017602309584617615,
                0.049867819994688034,
                0.05017497390508652,
                -0.05422811210155487,
                -0.0024417468812316656,
                0.012784935534000397,
                -0.08992230892181396,
                -0.04612156003713608,
                -0.000824551738332957,
                0.013132283464074135,
                -0.013717327266931534,
                -0.016202179715037346,
                0.022031277418136597,
                -0.02654346264898777,
                0.04340696334838867,
                0.028753984719514847,
                0.0006492888205684721,
                0.0007233198848553002,
                -0.11233104765415192,
                0.060106076300144196,
                0.012300175614655018,
                0.009345571510493755,
                -0.014088662341237068,
                0.030096963047981262,
                -0.03048848733305931,
                -0.03091888315975666,
                0.01018492691218853,
                0.013833966106176376,
                -0.06743200868368149,
                0.009977609850466251,
                -0.020953267812728882,
                -0.016550689935684204,
                0.01771990768611431,
                0.016543367877602577,
                0.007805919740349054,
                0.022163324058055878,
                -0.02440885454416275,
                -0.005121185909956694,
                0.05295119807124138,
                0.02368895523250103,
                -0.04427338019013405,
                0.0146440789103508,
                0.02162192575633526,
                0.001649849466048181,
                -0.03237638622522354,
                0.0012853327207267284,
                0.044416964054107666,
                0.01180348265916109,
                0.05968324840068817,
                0.001928497920744121,
                0.004816235508769751,
                -0.033352263271808624,
                -0.03678616136312485,
                -0.02252713032066822,
                -0.14617964625358582,
                0.03754333779215813,
                0.047078702598810196,
                -0.0245203897356987,
                -0.037315674126148224,
                -0.06142907962203026,
                0.002195795765146613,
                -0.005912520922720432,
                0.023224622011184692,
                -0.011701316572725773,
                -0.029074255377054214,
                0.029417164623737335,
                0.05155697837471962,
                0.04777737706899643,
                0.017846768721938133,
                -0.04965067282319069,
                0.03900248557329178,
                -0.08938410878181458,
                0.01730806566774845,
                -0.029197709634900093,
                0.012135322205722332,
                -0.023290343582630157,
                0.06454692780971527,
                0.00876291561871767,
                0.03642481192946434,
                0.026698442175984383,
                0.029393281787633896,
                -0.05019116401672363,
                -0.001110797980800271,
                -0.057374127209186554,
                0.017229516059160233,
                0.045735567808151245,
                0.03090391494333744,
                -0.021445227786898613,
                0.003316937480121851,
                0.025400223210453987,
                -0.03871387243270874,
                -0.00548592722043395,
                -0.0026974393986165524,
                -0.012470480054616928,
                0.003939979709684849,
                0.01590561680495739,
                -6.539447724357162e-33,
                -0.026680197566747665,
                -0.07499188184738159,
                0.02822151966392994,
                0.03113093040883541,
                -0.05787208303809166,
                -0.029542040079832077,
                -0.00273722130805254,
                0.02779264748096466,
                -0.029124664142727852,
                -0.01758883334696293,
                -0.03677072003483772,
                0.02484722249209881,
                0.0478646419942379,
                -0.04795188829302788,
                0.005366095341742039,
                0.016267001628875732,
                -0.01157291978597641,
                0.02250383049249649,
                -0.0028994556050747633,
                -0.05866360664367676,
                0.01155376248061657,
                0.02790031209588051,
                0.027686750516295433,
                -0.015636418014764786,
                0.01302953902631998,
                0.011428026482462883,
                -0.02324429526925087,
                3.203025698894635e-06,
                -0.018823694437742233,
                -0.014504625461995602,
                0.008173258975148201,
                -0.04727083072066307,
                -0.019947532564401627,
                -0.0327533483505249,
                -0.007785412482917309,
                0.06257417798042297,
                -0.03325365111231804,
                -0.057815760374069214,
                0.021408019587397575,
                0.02422233670949936,
                -0.03887766972184181,
                -0.031690049916505814,
                -0.018189894035458565,
                0.008136384189128876,
                0.03389425203204155,
                -0.03215619549155235,
                0.006693946197628975,
                -0.02446504682302475,
                0.04894183203577995,
                0.07032027095556259,
                -0.04804154112935066,
                0.0005808087880723178,
                -0.0572701133787632,
                -0.04122919216752052,
                0.05072773993015289,
                -0.008987975306808949,
                -0.022899260744452477,
                -0.049905430525541306,
                -0.021569866687059402,
                0.06260017305612564,
                0.009641166776418686,
                0.023736506700515747,
                -0.026774471625685692,
                -0.045274391770362854,
                -0.016516713425517082,
                -0.029188450425863266,
                -0.051736414432525635,
                -0.05070893093943596,
                -0.03582175821065903,
                -0.05249838903546333,
                -0.013294131495058537,
                0.050512995570898056,
                0.0388486422598362,
                -0.028146978467702866,
                0.014686803333461285,
                -0.0675518661737442,
                -0.0032706393394619226,
                -0.028713488951325417,
                0.0456509068608284,
                0.051865071058273315,
                0.009759128093719482,
                0.024306530132889748,
                -0.03110266849398613,
                0.0016210833564400673,
                -0.034379322081804276,
                -0.012180708348751068,
                -0.03192632272839546,
                0.004261781927198172,
                -0.013364871963858604,
                0.010479938238859177,
                -0.03843430057168007,
                0.00669277785345912,
                -0.0013704290613532066,
                0.009155452251434326,
                0.011268554255366325,
                -0.02250836044549942,
                0.03303062170743942,
                0.024821218103170395,
                -0.02743426524102688,
                -0.017379656434059143,
                -0.07744622975587845,
                0.012085486203432083,
                -0.0526273213326931,
                0.005914843641221523,
                0.041820790618658066,
                0.006756295450031757,
                -0.004159917123615742,
                0.032079942524433136,
                -0.02425464428961277,
                0.008924822323024273,
                -0.007162965834140778,
                0.03174176812171936,
                0.0396488793194294,
                0.007202833890914917,
                0.006260693538933992,
                -0.0164334699511528,
                -0.0052152350544929504,
                -0.06698425114154816,
                -0.02377455122768879,
                -0.020960118621587753,
                -0.027075760066509247,
                0.07668926566839218,
                -0.07050437480211258,
                0.022829728201031685,
                0.03327829763293266,
                -0.02212144434452057,
                0.009619265794754028,
                0.03999971225857735,
                -0.0073533011600375175,
                -0.037407584488391876,
                -0.018156198784708977,
                -0.01716722548007965,
                2.460689074723632e-07,
                -0.03736679255962372,
                0.012830965220928192,
                0.02430461160838604,
                0.032526060938835144,
                0.01785123161971569,
                0.0009780296823009849,
                -0.09019207209348679,
                0.018224848434329033,
                0.04112778976559639,
                -0.017619602382183075,
                0.03283558785915375,
                0.016711661592125893,
                0.011209219694137573,
                0.028912032023072243,
                0.06659476459026337,
                -8.079369581537321e-05,
                0.006971635855734348,
                -0.03267773240804672,
                0.016013234853744507,
                -0.003509989706799388,
                -0.00462255347520113,
                -0.0177027378231287,
                0.04659067094326019,
                0.0053435214795172215,
                -0.025744318962097168,
                -0.030916977673768997,
                0.02776399999856949,
                -0.015598452650010586,
                0.05905797705054283,
                -0.019653325900435448,
                0.005773460492491722,
                0.014338056556880474,
                0.0027076273690909147,
                0.023125242441892624,
                0.016312461346387863,
                -0.04097631201148033,
                0.023963935673236847,
                0.04287198185920715,
                0.05495915189385414,
                0.06332185864448547,
                -0.009821767918765545,
                0.033132605254650116,
                -0.015081369318068027,
                0.026882542297244072,
                0.015093596652150154,
                -0.04780874028801918,
                -0.02009948529303074,
                0.02953222766518593,
                0.0028504778165370226,
                -0.03800513595342636,
                0.003076049266383052,
                0.018094873055815697,
                0.01324569620192051,
                0.07965172082185745,
                0.014025053009390831,
                -0.011827533133327961,
                0.011299273930490017,
                -0.009170819073915482,
                0.02962176874279976,
                0.05369134247303009,
                -0.029537124559283257,
                0.008821608498692513,
                0.022385437041521072,
                0.018886296078562737,
                0.012488869950175285,
                0.02041514404118061,
                -0.06830290704965591,
                1.3750643634106222e-34,
                -0.008224100805819035,
                -0.021484550088644028,
                -0.0033263382501900196,
                -0.023781705647706985,
                0.01194226834923029,
                -0.0248770359903574,
                -0.03662159666419029,
                -0.020235702395439148,
                0.04618818685412407,
                0.04025492072105408,
                -0.028166279196739197
            ]
        },
        "Values": {
            "name": [
                "Authenticity",
                "Integrity",
                "Compassion",
                "Empathy ",
                "Humour"
            ],
            "rep": [
                0.031614601612091064,
                0.09296854585409164,
                -0.010229724459350109,
                0.023110881447792053,
                -0.021112844347953796,
                -0.0006606389069929719,
                -0.06667894870042801,
                -0.03659415245056152,
                -0.08473698794841766,
                0.04956032335758209,
                0.05173538997769356,
                0.0023476381320506334,
                0.04173034057021141,
                0.05441047251224518,
                -0.02013874053955078,
                0.011891343630850315,
                0.008131644688546658,
                0.027625031769275665,
                0.01587858982384205,
                -0.0075881327502429485,
                0.01042976789176464,
                0.0008613692480139434,
                0.05622463673353195,
                0.008649056777358055,
                -0.030827803537249565,
                -0.027793588116765022,
                0.00020865148690063506,
                0.026930274441838264,
                -0.03489648923277855,
                0.036142125725746155,
                0.0029600621201097965,
                0.019419070333242416,
                -0.028864383697509766,
                -0.019669411703944206,
                1.7597917576495092e-06,
                -0.026915358379483223,
                0.01801290549337864,
                -0.022402934730052948,
                -0.04974954575300217,
                0.027479473501443863,
                0.03673296421766281,
                0.05736703798174858,
                -0.02896232344210148,
                -0.016218960285186768,
                -0.017333079129457474,
                0.049475546926259995,
                0.05257410183548927,
                0.026694107800722122,
                0.013947417959570885,
                -0.015002254396677017,
                -0.0008572181686758995,
                0.01886136457324028,
                -0.05235304683446884,
                -0.004024901427328587,
                -0.014610531739890575,
                0.046099770814180374,
                0.017136814072728157,
                -0.07048133760690689,
                -0.029219159856438637,
                0.024930063635110855,
                0.012395516969263554,
                -0.01149700302630663,
                0.002407731721177697,
                -0.023869378492236137,
                0.044650427997112274,
                -0.017850084230303764,
                -0.02399255335330963,
                -0.04581030085682869,
                0.041562337428331375,
                -0.02892463654279709,
                0.0013666602317243814,
                0.0404014065861702,
                -0.01167626865208149,
                0.0637364462018013,
                -0.04355490580201149,
                0.001608787220902741,
                0.023397115990519524,
                0.0006694434559904039,
                0.02028447389602661,
                -0.005841842852532864,
                -0.06403060257434845,
                0.09855080395936966,
                -0.02221774309873581,
                0.05887826159596443,
                0.053354620933532715,
                0.02361435443162918,
                0.013570859096944332,
                0.021502550691366196,
                -0.037559814751148224,
                -0.004507157951593399,
                -0.02216438204050064,
                -0.04806660860776901,
                0.00045882954145781696,
                0.018206901848316193,
                0.04206825792789459,
                0.005243874620646238,
                0.015046532265841961,
                0.1111614778637886,
                -0.02165061980485916,
                0.007297425530850887,
                -0.05638866871595383,
                0.003924672957509756,
                0.02970627322793007,
                -0.016531653702259064,
                0.037876714020967484,
                0.03170759230852127,
                0.026287131011486053,
                0.004013765603303909,
                -0.02776475064456463,
                0.03351818397641182,
                0.00022262123820837587,
                0.0328475646674633,
                -0.002172144129872322,
                0.013545838184654713,
                0.06559715420007706,
                -0.03366762772202492,
                -0.058370910584926605,
                0.022445330396294594,
                -0.02894667163491249,
                0.039076294749975204,
                -0.08868324756622314,
                0.014017363078892231,
                -0.05503854900598526,
                -0.01735408790409565,
                -0.023582464084029198,
                -0.004862878937274218,
                -0.022600356489419937,
                -0.003029415151104331,
                -0.006689131259918213,
                -0.03567890077829361,
                -3.838779230136424e-05,
                -0.013040008023381233,
                0.019933274015784264,
                0.01076602004468441,
                0.0382661335170269,
                -0.07804574817419052,
                0.013423705473542213,
                -0.05441241338849068,
                -0.031732480973005295,
                -0.0011550621129572392,
                0.007287228014320135,
                -0.041449811309576035,
                -0.02566704712808132,
                -0.03203864395618439,
                -0.028962276875972748,
                -0.02604098618030548,
                0.017848122864961624,
                -0.07201756536960602,
                0.0016066125826910138,
                -0.040694382041692734,
                0.016387561336159706,
                -0.0020007251296192408,
                0.031469933688640594,
                0.018175309523940086,
                0.03272112458944321,
                0.03807458654046059,
                0.008172625675797462,
                -0.07486522942781448,
                -0.019144665449857712,
                0.01789696142077446,
                0.043438903987407684,
                0.04281958192586899,
                0.02149110846221447,
                -0.04485687240958214,
                -0.0344226248562336,
                -0.008750956505537033,
                0.05244406312704086,
                0.00901193730533123,
                -0.010530754923820496,
                -0.02920219674706459,
                -0.018233880400657654,
                -0.006127084605395794,
                -0.02180548571050167,
                0.04144561290740967,
                0.019391510635614395,
                0.007188852876424789,
                -0.03548359125852585,
                -0.10795458406209946,
                -0.036935340613126755,
                0.06212181970477104,
                0.016560353338718414,
                -0.0695238783955574,
                -0.009613486006855965,
                0.007860345765948296,
                0.08169609308242798,
                0.04537854716181755,
                0.041675813496112823,
                0.019260719418525696,
                -0.031182706356048584,
                -0.030777525156736374,
                -0.020243460312485695,
                -0.014893471263349056,
                -0.04832184687256813,
                0.0068948278203606606,
                -0.053897276520729065,
                -0.022251058369874954,
                0.04074730724096298,
                -0.08321958780288696,
                -0.004654372110962868,
                -0.006064033135771751,
                -0.02461419813334942,
                -0.04258925840258598,
                0.08141247183084488,
                0.015779750421643257,
                -0.045620206743478775,
                0.03968743234872818,
                0.12120652943849564,
                -0.006890011020004749,
                -0.04257674887776375,
                0.03645898029208183,
                -0.04744061827659607,
                -0.009602227248251438,
                0.032439298927783966,
                0.004329758696258068,
                0.0011493643978610635,
                -0.022584183141589165,
                0.008238478563725948,
                0.02296244166791439,
                -0.02721690945327282,
                0.055676087737083435,
                -0.01026634220033884,
                0.016998324543237686,
                0.01621774025261402,
                -0.032022081315517426,
                -0.000663252139929682,
                -0.05840674415230751,
                0.015160179696977139,
                0.010469283908605576,
                0.0034573618322610855,
                0.013353056274354458,
                -0.007639809045940638,
                0.022988425567746162,
                -0.004721714183688164,
                0.034443024545907974,
                0.016315177083015442,
                0.012982563115656376,
                0.060639701783657074,
                0.1204913929104805,
                0.011804226785898209,
                -0.12312711775302887,
                -0.017496073618531227,
                0.04093361273407936,
                0.03528863191604614,
                -0.003512698458507657,
                0.01897437870502472,
                0.023746272549033165,
                -0.004052496515214443,
                -0.062411822378635406,
                -0.009347151964902878,
                -0.03496762737631798,
                0.023048505187034607,
                -0.0006388538749888539,
                0.021227218210697174,
                -0.03935637325048447,
                0.02332170121371746,
                0.0010429461253806949,
                0.004122184589505196,
                0.035676900297403336,
                0.0070320433005690575,
                -0.008177160285413265,
                0.019947178661823273,
                -0.018364347517490387,
                0.05073309317231178,
                -0.015050428919494152,
                0.038947831839323044,
                -0.06466376781463623,
                0.015296418219804764,
                -0.03691427409648895,
                0.007052309345453978,
                -0.006726060062646866,
                -0.0006556729204021394,
                -0.02461237646639347,
                -0.011795707046985626,
                -0.012331882491707802,
                0.023612678050994873,
                -0.057661328464746475,
                -0.09185225516557693,
                -0.03161108121275902,
                -0.02338513173162937,
                -0.007410649210214615,
                0.01708204112946987,
                0.00582674378529191,
                -0.034969575703144073,
                0.01997166872024536,
                0.031429581344127655,
                0.01870284602046013,
                0.001317522139288485,
                0.05308511108160019,
                0.020866379141807556,
                -0.06892658770084381,
                -0.061754561960697174,
                -0.014886495657265186,
                0.03778461739420891,
                -0.049440331757068634,
                0.03825484961271286,
                0.006873337086290121,
                -0.004606854636222124,
                -0.052096690982580185,
                0.006763265002518892,
                -0.0412374809384346,
                -0.009797053411602974,
                0.01156923733651638,
                0.0442049503326416,
                -0.061761680990457535,
                -0.012902489863336086,
                0.01994553580880165,
                0.049528006464242935,
                -0.053513772785663605,
                0.033502232283353806,
                -0.011508380994200706,
                -0.02545464225113392,
                0.018174922093749046,
                0.02681681700050831,
                0.012865501455962658,
                0.04133132845163345,
                -0.01739281415939331,
                0.06047598645091057,
                0.0003648892743512988,
                -0.003950962796807289,
                0.07810553163290024,
                -0.018598727881908417,
                -0.0051161181181669235,
                -0.008074377663433552,
                -0.047434139996767044,
                0.021738450974225998,
                0.05145397037267685,
                0.02926045097410679,
                0.08342552930116653,
                0.031614601612091064,
                0.010691067203879356,
                0.015000482089817524,
                0.07974730432033539,
                0.011716794222593307,
                0.010149091482162476,
                -0.08563152700662613,
                0.06539160013198853,
                -0.05001875013113022,
                -0.06032480299472809,
                0.004396628588438034,
                0.018543310463428497,
                -0.005018841940909624,
                0.005691347643733025,
                -0.010081951506435871,
                -0.036189742386341095,
                -0.001736486330628395,
                -0.034611016511917114,
                -0.046260908246040344,
                0.02116616815328598,
                0.011961937882006168,
                -0.0015345829306170344,
                0.0131759038195014,
                0.03874608874320984,
                -0.012823272496461868,
                -0.013333802111446857,
                0.00550055829808116,
                -0.015342962928116322,
                -0.019430600106716156,
                -0.0006741489050909877,
                0.06112036108970642,
                -0.04865071177482605,
                -0.02511921152472496,
                -0.00046355268568731844,
                -0.01660703308880329,
                0.02290729433298111,
                -0.007312570232897997,
                0.015426493249833584,
                0.02212473563849926,
                0.021454058587551117,
                0.00694418977946043,
                0.012448572553694248,
                0.040032271295785904,
                0.06103288754820824,
                -0.000179666283656843,
                0.03665124624967575,
                -0.013589405454695225,
                0.06613530218601227,
                0.006150709930807352,
                -0.014167671091854572,
                -0.02222137525677681,
                -0.01729816012084484,
                0.026920199394226074,
                -0.039606910198926926,
                0.03145577758550644,
                0.00695035932585597,
                0.01362632866948843,
                -0.03798559308052063,
                0.000902417756151408,
                0.01526808924973011,
                0.013485532253980637,
                0.025089604780077934,
                0.017878545448184013,
                -0.06883183866739273,
                -0.02912621572613716,
                0.05804838240146637,
                0.030967090278863907,
                0.015684904530644417,
                0.03583097085356712,
                0.03793678060173988,
                0.016240395605564117,
                0.028650673106312752,
                0.020622767508029938,
                0.06420224905014038,
                -0.040637001395225525,
                0.036876462399959564,
                -0.04481169953942299,
                -0.0011848428985103965,
                -0.0186560470610857,
                0.03491702303290367,
                -0.006604273337870836,
                -0.09473363310098648,
                -0.09922251850366592,
                0.04960191994905472,
                -0.01161053404211998,
                0.011499250307679176,
                -0.004729718901216984,
                -0.0357043594121933,
                -0.02760252356529236,
                -0.05031517148017883,
                0.011750143021345139,
                -0.012966874986886978,
                -0.002888277405872941,
                -0.04964756593108177,
                -0.02288425900042057,
                0.018093934282660484,
                -0.02255477011203766,
                0.007085083518177271,
                -0.0223096776753664,
                0.04952692240476608,
                0.01153147779405117,
                0.01097900327295065,
                -0.008386636152863503,
                -0.025175610557198524,
                -0.010168655775487423,
                0.03930561617016792,
                0.02639164961874485,
                -0.09039104729890823,
                0.06368223577737808,
                0.02668127790093422,
                -0.05563616380095482,
                -0.047924723476171494,
                0.01229150127619505,
                -0.04734693467617035,
                0.024550512433052063,
                0.011191286146640778,
                -0.040922828018665314,
                -0.06604424864053726,
                -0.039783552289009094,
                -0.0313635990023613,
                0.010531865060329437,
                -0.04822741821408272,
                0.009284251369535923,
                -0.020386915653944016,
                0.06787429749965668,
                -0.048648711293935776,
                -0.014728887006640434,
                0.0149356285110116,
                -0.007281553465873003,
                0.0012083998881280422,
                -0.016184533014893532,
                0.0332099087536335,
                0.01374796126037836,
                0.03914957121014595,
                0.01841762848198414,
                0.021874485537409782,
                0.002510056598111987,
                -0.04279841482639313,
                -0.06653906404972076,
                -0.023163480684161186,
                -0.02252635732293129,
                -0.011530476622283459,
                0.00329603417776525,
                -0.03277517482638359,
                -0.00246345903724432,
                0.03615199774503708,
                0.0305327195674181,
                0.03922710195183754,
                -0.0563044436275959,
                0.01742624305188656,
                0.0014014319749549031,
                -0.007135334424674511,
                0.007660660892724991,
                0.013392281718552113,
                0.005617477465420961,
                0.088541179895401,
                -0.00018252950394526124,
                -0.01168611366301775,
                0.043073076754808426,
                0.0003230220463592559,
                0.012100511230528355,
                -0.04216299206018448,
                -0.02328183501958847,
                0.036855511367321014,
                -0.008442895486950874,
                -0.035826172679662704,
                -0.026156308129429817,
                0.08046075701713562,
                -0.05869030952453613,
                -0.07017208635807037,
                -0.01624574325978756,
                0.043250974267721176,
                -0.023518795147538185,
                0.024800969287753105,
                0.005231587681919336,
                0.057480745017528534,
                -0.0076096621342003345,
                0.021076491102576256,
                0.02040443941950798,
                -0.006407138425856829,
                0.033703070133924484,
                -0.010999638587236404,
                0.03082030452787876,
                -0.007872684858739376,
                -0.026999514549970627,
                -0.015483515337109566,
                -0.02865396998822689,
                0.007558798883110285,
                -0.015702802687883377,
                -0.06783821433782578,
                0.0005674886633642018,
                -0.0009741191752254963,
                0.026128895580768585,
                0.005874053109437227,
                0.058539681136608124,
                0.03424998000264168,
                0.02054351195693016,
                -0.01811075396835804,
                0.014838065020740032,
                -0.017811529338359833,
                0.01709209382534027,
                -0.035577442497015,
                -0.12401746213436127,
                -0.03627995401620865,
                -0.06460131704807281,
                0.00687286676838994,
                -0.027799224480986595,
                0.0736607015132904,
                -0.02690793201327324,
                0.04254959896206856,
                -0.048786308616399765,
                0.0020000434014946222,
                -0.046147413551807404,
                -0.016303546726703644,
                0.04270026832818985,
                0.01294515561312437,
                0.055951811373233795,
                0.013586006127297878,
                0.02734369784593582,
                0.01287700142711401,
                0.04004521667957306,
                0.051898203790187836,
                -0.013701880350708961,
                -0.07558493316173553,
                -0.056476157158613205,
                -0.030394913628697395,
                0.04010601341724396,
                -7.955635563769852e-33,
                0.025870203971862793,
                -0.022096216678619385,
                -0.027978956699371338,
                -0.012555499561131,
                -0.04045840725302696,
                0.043180983513593674,
                -0.02221677452325821,
                0.03513480722904205,
                -0.060789041221141815,
                -0.026855453848838806,
                -0.017512578517198563,
                -0.018950358033180237,
                0.02628067322075367,
                0.008337920531630516,
                0.0021215728484094143,
                0.01581025868654251,
                -0.003835925366729498,
                0.04275305196642876,
                -0.025420192629098892,
                -0.025246141478419304,
                -0.0024012760259211063,
                0.010570450685918331,
                3.4903368941741064e-05,
                0.00185650703497231,
                0.03738334774971008,
                -0.08284541219472885,
                -0.058330707252025604,
                -0.041978977620601654,
                0.019974157214164734,
                0.006581182591617107,
                -0.0015423611039295793,
                -0.046695251017808914,
                -0.043345365673303604,
                -0.031131232157349586,
                -0.0005122387083247304,
                0.013903642073273659,
                -0.002425128361210227,
                -0.027884459123015404,
                -0.009127028286457062,
                0.00804685615003109,
                -0.03767145797610283,
                -0.04919800907373428,
                -0.005129558965563774,
                -0.03092622198164463,
                -0.05322491377592087,
                -0.025482483208179474,
                0.008266226388514042,
                0.02456451579928398,
                0.0016014246502891183,
                1.8467422705725767e-05,
                -0.03489046171307564,
                -0.009875806979835033,
                -0.010293773375451565,
                -0.02317916974425316,
                0.06054091081023216,
                -0.028661923483014107,
                -0.014535627327859402,
                -0.01994418352842331,
                -0.03335884213447571,
                -0.018729975447058678,
                -0.013963158242404461,
                -0.0398407056927681,
                -0.024679992347955704,
                0.023018397390842438,
                -0.012966063804924488,
                -0.021009936928749084,
                -0.07421258836984634,
                -0.0003257426433265209,
                0.026803847402334213,
                0.036528076976537704,
                0.03336236998438835,
                0.1066041886806488,
                0.04908603057265282,
                0.052685048431158066,
                -0.07610984146595001,
                -0.015113829635083675,
                0.034569211304187775,
                0.02148185484111309,
                -0.010956529527902603,
                -0.008942550048232079,
                0.008786800317466259,
                0.024033496156334877,
                0.006668172311037779,
                0.021131934598088264,
                -0.022375933825969696,
                -0.04927393049001694,
                -0.015909995883703232,
                0.029793916270136833,
                -0.03245121240615845,
                -0.03447088226675987,
                -0.030911138281226158,
                0.04074067622423172,
                0.026556817814707756,
                -0.032989293336868286,
                -0.022948507219552994,
                -0.08686207979917526,
                -0.024938423186540604,
                0.03869767487049103,
                0.016146112233400345,
                -0.022852664813399315,
                0.03915941342711449,
                0.05042093247175217,
                -0.0037705092690885067,
                0.0236955713480711,
                0.01171535812318325,
                -0.008005622774362564,
                0.001573339686729014,
                0.050055015832185745,
                -0.027914706617593765,
                -0.007183315698057413,
                0.009017356671392918,
                0.02594015933573246,
                0.03839316964149475,
                -0.06306162476539612,
                -0.010268730111420155,
                -0.02039446122944355,
                -0.005351734347641468,
                0.011909467168152332,
                -0.019084472209215164,
                0.0439581423997879,
                0.03879239410161972,
                0.052565693855285645,
                -0.03021208755671978,
                -0.009914489462971687,
                -0.05447259545326233,
                0.048277854919433594,
                0.0111907459795475,
                -0.01485997624695301,
                0.01057035755366087,
                0.00916708167642355,
                -0.011564283631742,
                -0.041506603360176086,
                2.580995328571589e-07,
                -0.08215496689081192,
                0.006933196913450956,
                -0.008294793777167797,
                -0.009046362712979317,
                0.02735762670636177,
                0.05571700632572174,
                -0.009073144756257534,
                0.030321164056658745,
                -0.03409421443939209,
                -0.062385935336351395,
                0.034035518765449524,
                -0.004681736696511507,
                0.009025943465530872,
                -0.004529158119112253,
                0.04882664978504181,
                -0.03429688140749931,
                -0.004328321665525436,
                -0.03078809566795826,
                0.026448776945471764,
                -0.017473265528678894,
                0.03068307600915432,
                0.021907366812229156,
                0.018185768276453018,
                -0.022411145269870758,
                0.02618899941444397,
                0.007635812275111675,
                0.015610077418386936,
                0.003427485004067421,
                0.038985978811979294,
                -0.011814498342573643,
                0.03300020471215248,
                -0.001827445812523365,
                0.0021136419381946325,
                -0.03137935698032379,
                -0.00732024572789669,
                0.021794382482767105,
                0.06095348298549652,
                0.05320766568183899,
                0.010271476581692696,
                0.08452888578176498,
                -0.043927714228630066,
                -0.020506419241428375,
                0.03604854643344879,
                -0.027007173746824265,
                0.03888193517923355,
                0.0626891627907753,
                -0.019277088344097137,
                -0.06516396254301071,
                -0.10264146327972412,
                -0.008927632123231888,
                0.03943530097603798,
                0.017497478052973747,
                -0.029998358339071274,
                0.055175598710775375,
                0.018692489713430405,
                0.013602281920611858,
                0.005325973499566317,
                0.04205714538693428,
                0.029471557587385178,
                -0.08568445593118668,
                0.003930675331503153,
                0.08530381321907043,
                0.02478879503905773,
                -0.031511880457401276,
                0.030639953911304474,
                -0.007140910718590021,
                -0.025153597816824913,
                8.304565105393991e-35,
                -0.010867771692574024,
                -0.047325316816568375,
                0.027290981262922287,
                -0.00973482895642519,
                0.05212569236755371,
                0.011960962787270546,
                -0.005469610448926687,
                0.0006482623284682631,
                0.03915612772107124,
                0.019895119592547417,
                -0.0045553394593298435,
                0.007220093160867691,
                0.12690812349319458,
                -0.013330596499145031,
                -0.009178786538541317,
                -0.05898209661245346,
                -0.009154231287539005,
                -0.023061420768499374,
                -0.014431523159146309,
                -0.08194530755281448,
                0.022797027602791786,
                0.05601118132472038,
                -0.037067823112010956,
                0.018782606348395348,
                0.00963249709457159,
                -0.012924671173095703,
                -0.001317267189733684,
                0.012269774451851845,
                0.012224575504660606,
                0.037114500999450684,
                -0.0038826619274914265,
                -0.003940482158213854,
                0.018927721306681633,
                0.03301624953746796,
                -0.02350311167538166,
                -0.02588529698550701,
                -0.03591611981391907,
                0.027589676901698112,
                -0.00884229689836502,
                -0.022075248882174492,
                -0.009637098759412766,
                0.003521398873999715,
                0.010663026012480259,
                -0.011588972061872482,
                -0.02570822834968567,
                1.9046581201109802e-06,
                -0.029168391600251198,
                -0.015016445890069008,
                -0.007111200597137213,
                -0.049777280539274216,
                0.012773741967976093,
                0.007624432910233736,
                0.001422704430297017,
                -0.015381290577352047,
                0.005473981145769358,
                -0.03884754702448845,
                0.06750884652137756,
                0.03648354113101959,
                0.028269104659557343,
                -0.0009251724695786834,
                -0.014358358457684517,
                0.005042159929871559,
                0.06588180363178253,
                -0.02864660695195198,
                -0.019321197643876076,
                -0.01558055728673935,
                -0.003677650121971965,
                0.00537432124838233,
                -0.031251899898052216,
                -0.09369475394487381,
                0.037889547646045685,
                0.02838130295276642,
                -0.02475082315504551,
                -0.014498879201710224,
                -0.027326690033078194,
                0.0022591922897845507,
                -0.025624530389904976,
                -0.0024178256280720234,
                -0.005925825797021389,
                0.04400869458913803,
                -0.02564220316708088,
                0.021079346537590027,
                0.08640003204345703,
                -0.016894478350877762,
                0.0903797298669815,
                -0.03046954795718193,
                -0.06486929953098297,
                0.002105571096763015,
                0.03356126323342323,
                0.0019168260041624308,
                -0.00980145949870348,
                -0.005381995812058449,
                0.07332955300807953,
                -0.016969598829746246,
                0.04156991094350815,
                0.03207574784755707,
                -0.025060057640075684,
                -0.006022876128554344,
                -0.014646865427494049,
                -0.012717870064079762,
                0.014178866520524025,
                -0.029142368584871292,
                -0.04944000765681267,
                -0.045092564076185226,
                0.011200613342225552,
                0.041857779026031494,
                -0.028129398822784424,
                0.015408728271722794,
                0.04585038125514984,
                0.014360136352479458,
                -0.011638130992650986,
                -0.07877056300640106,
                0.026181602850556374,
                0.06631720811128616,
                0.0025787882041186094,
                0.028745556250214577,
                0.06589121371507645,
                0.028424406424164772,
                0.04087516665458679,
                -0.015823477879166603,
                0.04492426663637161,
                0.02272605337202549,
                0.02113666944205761,
                0.03347652032971382,
                0.058021146804094315,
                0.04927325248718262,
                0.015626585111021996,
                -0.042588282376527786,
                0.018813099712133408,
                -0.08500173687934875,
                0.03245951607823372,
                -0.06949996948242188,
                0.01761537976562977,
                -0.05397912859916687,
                -0.02156383916735649,
                -0.007821719162166119,
                -0.01260621752589941,
                -0.034002114087343216,
                0.016369309276342392,
                0.0032701261807233095,
                -0.025297846645116806,
                -0.0007633428322151303,
                -0.03427473455667496,
                -0.0017074086936190724,
                0.026016822084784508,
                0.04410139098763466,
                0.02328268438577652,
                0.015730401501059532,
                -0.027279367670416832,
                -0.05595480650663376,
                0.027094727382063866,
                0.006204534787684679,
                -0.06038930267095566,
                -0.024015070870518684,
                0.0016057631000876427,
                -0.016907161101698875,
                -0.018936043605208397,
                0.004777940921485424,
                -0.09367158263921738,
                0.0029072295874357224,
                -0.02582210674881935,
                -0.00858122855424881,
                0.004061397165060043,
                0.009909517131745815,
                0.014093874953687191,
                0.019935742020606995,
                0.049665309488773346,
                -0.00194942532107234,
                -0.04473806917667389,
                0.0010550370207056403,
                -0.034887298941612244,
                0.049838315695524216,
                -0.008260553702712059,
                -0.023401647806167603,
                -0.033540256321430206,
                -0.04671259969472885,
                0.02669326588511467,
                0.04550599679350853,
                0.01577076129615307,
                0.03138255700469017,
                -0.024317070841789246,
                -0.01378683652728796,
                0.024574335664510727,
                -0.031762365251779556,
                0.015957089141011238,
                0.03135454282164574,
                0.024010341614484787,
                -0.06998465210199356,
                -0.0525558777153492,
                -0.08357774466276169,
                -0.002202659612521529,
                -0.029558852314949036,
                -0.09003203362226486,
                -0.008859578520059586,
                0.015714796259999275,
                0.10593590140342712,
                0.019590085372328758,
                -0.0064702825620770454,
                0.05818169564008713,
                -0.02005760185420513,
                -0.0213478971272707,
                -0.014563341625034809,
                -0.021698787808418274,
                -0.02933651953935623,
                0.01829668879508972,
                -0.01258730050176382,
                -0.012515884824097157,
                0.032023921608924866,
                -0.068564772605896,
                -0.00883675366640091,
                -0.015206188894808292,
                0.002161479787901044,
                -0.029089227318763733,
                0.11086267977952957,
                0.0625510960817337,
                -0.02340935356914997,
                0.01909479685127735,
                0.04548031464219093,
                -0.02315167337656021,
                0.012297780252993107,
                0.007010817993432283,
                -0.01637658290565014,
                -0.014229003340005875,
                0.009078770875930786,
                -0.0019424670608714223,
                -0.01831245608627796,
                0.029145771637558937,
                0.03837144374847412,
                0.03194543719291687,
                -0.0352340042591095,
                0.029419371858239174,
                -0.02750684879720211,
                0.03316861391067505,
                -0.029776033014059067,
                -0.01170995645225048,
                0.005520451348274946,
                -0.0434754453599453,
                -0.0067659057676792145,
                -0.0015450486680492759,
                0.007180936634540558,
                -0.008180730976164341,
                -0.01234677154570818,
                0.016015321016311646,
                -0.010429020039737225,
                0.0413702018558979,
                0.0547175258398056,
                -0.02745622955262661,
                0.07980308681726456,
                0.12012399733066559,
                -0.012766983360052109,
                -0.09579169750213623,
                0.013409803621470928,
                -0.011241384781897068,
                0.024030352011322975,
                0.033421412110328674,
                0.020232992246747017,
                0.022167135030031204,
                -0.03386034816503525,
                -0.02882199175655842,
                0.0006805077427998185,
                -0.017736809328198433,
                -0.0003824306186288595,
                -0.020421642810106277,
                0.027861792594194412,
                -0.06452693790197372,
                0.032144736498594284,
                -0.006682137027382851,
                -0.10204853862524033,
                0.043787576258182526,
                -0.02279161661863327,
                0.01098623126745224,
                0.01804424822330475,
                -0.051321130245923996,
                0.02022491581737995,
                0.019175268709659576,
                0.06943424791097641,
                -0.015340046025812626,
                0.015236986801028252,
                -0.03224444389343262,
                0.014622892253100872,
                -0.010191423818469048,
                0.023221388459205627,
                -0.019258376210927963,
                0.02428850531578064,
                -0.016745200380682945,
                0.02513529360294342,
                -0.013556771911680698,
                -0.09459414333105087,
                0.014304611831903458,
                -0.027784883975982666,
                -0.0003780092520173639,
                0.02807721123099327,
                0.010516773909330368,
                -0.03449785336852074,
                0.0014931996120139956,
                0.019069146364927292,
                0.014175626449286938,
                -0.014479906298220158,
                0.025388918817043304,
                0.013695724308490753,
                -0.09060432016849518,
                -0.05623405799269676,
                -0.004683947190642357,
                0.016608543694019318,
                -0.06757647544145584,
                0.04928380250930786,
                -0.0015349802561104298,
                -0.0352654792368412,
                -0.004984854254871607,
                0.03481748327612877,
                -0.005224144086241722,
                0.0006253898027352989,
                0.014763753861188889,
                0.012924113310873508,
                -0.02882426418364048,
                -0.009911599569022655,
                0.06083396449685097,
                0.054520267993211746,
                -0.014454690739512444,
                -0.014201796613633633,
                0.030252138152718544,
                0.002702887635678053,
                0.013129477389156818,
                0.02389155887067318,
                0.06107516959309578,
                0.024718057364225388,
                -0.014814273454248905,
                0.037511661648750305,
                -0.04343470185995102,
                0.025218352675437927,
                0.08449586480855942,
                0.006593199912458658,
                0.00561861414462328,
                -0.003655750770121813,
                -0.029582418501377106,
                0.05010204389691353,
                0.047242965549230576,
                0.00829891487956047,
                0.06558775901794434,
                0.057202138006687164,
                -0.018615806475281715,
                -0.01474888063967228,
                0.09801466017961502,
                -0.004405722953379154,
                -0.012374136596918106,
                -0.03574983775615692,
                0.06842835247516632,
                -0.032656602561473846,
                -0.03139021620154381,
                -0.016018657013773918,
                -0.004041306208819151,
                0.00010336016566725448,
                -0.03363349288702011,
                -0.035849861800670624,
                -0.026920774951577187,
                0.013700272887945175,
                -0.04902292788028717,
                -0.011631963774561882,
                0.045735906809568405,
                -0.02167317271232605,
                -0.013853389769792557,
                0.019180065020918846,
                -0.012868771329522133,
                -0.03332202136516571,
                -0.014870701357722282,
                0.02800414152443409,
                -0.020142661407589912,
                -0.04077429696917534,
                0.002617558231577277,
                0.05052110552787781,
                -0.028406426310539246,
                -0.030504144728183746,
                0.020153062418103218,
                0.03529304638504982,
                0.036542393267154694,
                0.046495188027620316,
                0.008136283606290817,
                0.06548294425010681,
                0.04180089011788368,
                -0.03921842202544212,
                -0.0125245600938797,
                0.013560083694756031,
                0.04508731886744499,
                -0.040814027190208435,
                0.03672853112220764,
                -0.018213670700788498,
                0.08369408547878265,
                0.006480259355157614,
                -0.00679631857201457,
                0.031648922711610794,
                -0.029586002230644226,
                0.022843938320875168,
                -0.0777575746178627,
                0.020773056894540787,
                0.015500608831644058,
                0.008420449681580067,
                -0.052052803337574005,
                -0.03919024392962456,
                -0.009989743120968342,
                0.013841425999999046,
                0.00564642995595932,
                0.03099077381193638,
                -0.010737105272710323,
                -0.10529940575361252,
                0.052450649440288544,
                0.03143619745969772,
                -0.0016658380627632141,
                0.04526105523109436,
                0.045505814254283905,
                0.00963950902223587,
                0.017566440626978874,
                0.014724655076861382,
                0.04362710565328598,
                -0.014561167918145657,
                0.0531272254884243,
                -0.02125772275030613,
                -0.004914813209325075,
                0.07956857979297638,
                0.02550799958407879,
                -0.010589826852083206,
                -0.08616721630096436,
                -0.07216759026050568,
                -0.008622650988399982,
                -0.04439163953065872,
                0.024330895394086838,
                0.07451290637254715,
                -0.07755611836910248,
                -0.009674042463302612,
                -0.05114815756678581,
                0.021345634013414383,
                0.033276136964559555,
                0.006675639655441046,
                -0.03409911319613457,
                -0.003243678016588092,
                -0.01852993667125702,
                -0.033138781785964966,
                0.004415385890752077,
                -0.0325806587934494,
                0.05296918749809265,
                0.007781881373375654,
                -0.023828601464629173,
                0.006238799076527357,
                -0.014587691985070705,
                -0.04961219057440758,
                -0.014801996760070324,
                -0.031071117147803307,
                0.006913674529641867,
                0.0060755787417292595,
                0.004400583915412426,
                0.012551105581223965,
                -0.04504134878516197,
                -0.01753273978829384,
                -0.005915840156376362,
                0.01429878268390894,
                0.015161433257162571,
                -0.0490596704185009,
                -0.09617709368467331,
                -0.0029389469418674707,
                -0.02974698133766651,
                -0.0527971051633358,
                -0.01324068009853363,
                0.012258083559572697,
                0.041769228875637054,
                0.06034591421484947,
                -0.05356547236442566,
                0.020533649250864983,
                0.010080129839479923,
                -0.008590023033320904,
                0.07229235023260117,
                0.026651525869965553,
                0.07370408624410629,
                0.04539267346262932,
                0.0417725034058094,
                0.023070499300956726,
                -0.014040893875062466,
                0.022360896691679955,
                -0.06059574335813522,
                -0.030522236600518227,
                -0.05901522934436798,
                0.002027760725468397,
                0.012804615311324596,
                -0.05678028240799904,
                -0.016203423961997032,
                -0.009501469321548939,
                -0.007921099662780762,
                0.0299840085208416,
                0.022945616394281387,
                -0.050352733582258224,
                -0.0289726871997118,
                -0.0063408794812858105,
                -0.008745433762669563,
                0.00693872943520546,
                0.02275679260492325,
                -0.003082881448790431,
                0.04974944144487381,
                0.005502148065716028,
                -0.035423338413238525,
                0.007849366404116154,
                -0.016216689720749855,
                0.02941657416522503,
                -0.01654219627380371,
                0.029985910281538963,
                0.03193316236138344,
                -0.02629327028989792,
                -0.03874688223004341,
                -0.023050373420119286,
                0.03987140208482742,
                -0.08108989894390106,
                -0.008935811929404736,
                -0.06471994519233704,
                0.019271038472652435,
                -0.012750890105962753,
                0.011098715476691723,
                0.00971289537847042,
                0.051782697439193726,
                -0.004732117522507906,
                0.034985847771167755,
                0.0035870722495019436,
                0.04137001186609268,
                0.007082048337906599,
                -0.002556182211264968,
                0.019234756007790565,
                -0.0074669551104307175,
                -0.04413546994328499,
                0.009106332436203957,
                -0.003549985121935606,
                0.024007193744182587,
                -0.08024661988019943,
                -0.006893946323543787,
                -0.04866635799407959,
                0.02722732163965702,
                0.018889285624027252,
                0.019977809861302376,
                0.030637027695775032,
                0.03140416741371155,
                -0.010239674709737301,
                0.013699902221560478,
                0.019478995352983475,
                -0.021716495975852013,
                -0.011832987889647484,
                0.005819641053676605,
                -0.09570764750242233,
                0.011961414478719234,
                -0.0903700515627861,
                0.015233726240694523,
                -0.007250992115586996,
                0.05424884334206581,
                -0.018980396911501884,
                0.09275531023740768,
                -0.02347164787352085,
                -0.010834748856723309,
                -0.07285503298044205,
                0.029177634045481682,
                0.023623919114470482,
                0.012300928123295307,
                0.054637882858514786,
                -0.02472241409122944,
                -0.048754896968603134,
                0.02865642122924328,
                0.04946945607662201,
                0.035720083862543106,
                -0.011923598125576973,
                -0.083652563393116,
                -0.11151722073554993,
                -0.03717861324548721,
                0.02313348837196827,
                -7.601988088324967e-33,
                -0.0033825754653662443,
                -0.019693512469530106,
                -0.010365263558924198,
                0.021640779450535774,
                -0.08544564992189407,
                0.008891357108950615,
                0.016198091208934784,
                0.03150733560323715,
                -0.05716391280293465,
                -0.042422883212566376,
                -0.020727336406707764,
                -0.006449337583035231,
                0.016388677060604095,
                0.0007731726509518921,
                0.03495887294411659,
                -0.016696758568286896,
                -0.026147156953811646,
                0.02142287790775299,
                -0.02785431034862995,
                -0.034589264541864395,
                -0.0027886752504855394,
                -0.007213981356471777,
                0.02757444977760315,
                0.020380433648824692,
                0.049127668142318726,
                -0.0015857412945479155,
                -0.03266015276312828,
                -0.017298739403486252,
                -0.0029248904902487993,
                -0.007535844575613737,
                -0.012015796266496181,
                -0.023495672270655632,
                -0.013745570555329323,
                -0.04836667329072952,
                0.032538630068302155,
                0.07324875146150589,
                -0.026432819664478302,
                -0.004953879863023758,
                -0.006779173854738474,
                -0.010079947300255299,
                -0.058148905634880066,
                -0.08312271535396576,
                0.02114173024892807,
                -0.017910398542881012,
                -0.007074172608554363,
                -0.051755521446466446,
                0.008552821353077888,
                0.024593845009803772,
                0.02507046051323414,
                0.03700904920697212,
                0.006287120748311281,
                -0.011465050280094147,
                -0.04852145165205002,
                0.0053646015003323555,
                -0.006547981407493353,
                -0.011218507774174213,
                0.0013459421461448073,
                0.03865598142147064,
                -0.03333544731140137,
                -0.006636158563196659,
                -0.05561434477567673,
                -0.004618796054273844,
                -0.033339451998472214,
                -0.012015814892947674,
                0.015362735837697983,
                0.015058137476444244,
                -0.042604126036167145,
                0.04490929841995239,
                0.04010569676756859,
                0.015132279135286808,
                0.01397655438631773,
                0.0737074539065361,
                0.05618048086762428,
                0.008138592354953289,
                -0.11388938128948212,
                -0.04156568646430969,
                -0.00206947885453701,
                0.002496486296877265,
                0.049797821789979935,
                -0.014775686897337437,
                -0.01631035842001438,
                0.01229232270270586,
                -0.019243208691477776,
                0.004456475842744112,
                -0.014686793088912964,
                -0.09541141241788864,
                0.0049632638692855835,
                0.015554998070001602,
                -0.013045482337474823,
                -0.01945008896291256,
                -0.006241308059543371,
                0.03313110023736954,
                0.037043459713459015,
                0.015065093524754047,
                -0.005278264172375202,
                -0.008174476213753223,
                -0.02495860494673252,
                0.011951589956879616,
                0.009668194688856602,
                -0.012481773272156715,
                0.03355126082897186,
                0.05198721960186958,
                -0.006224299315363169,
                0.014744390733540058,
                0.010152355767786503,
                -0.017813319340348244,
                0.009866416454315186,
                0.027514729648828506,
                -0.03474545478820801,
                -0.0530754029750824,
                -0.008071103133261204,
                0.05008575692772865,
                0.02936948649585247,
                -0.005879739765077829,
                -0.03830396384000778,
                -0.002469847211614251,
                0.002607048721984029,
                -0.05130314454436302,
                0.0015151286497712135,
                0.03356805816292763,
                0.019099166616797447,
                0.028820108622312546,
                -0.05867042392492294,
                -0.014113236218690872,
                -0.03886804357171059,
                0.02233501523733139,
                -0.003902242984622717,
                -0.004959763493388891,
                -0.06286713480949402,
                -0.026405133306980133,
                -0.006286535412073135,
                -0.015326520428061485,
                2.587068479442678e-07,
                -0.06406529247760773,
                0.015865758061408997,
                -0.0018345164135098457,
                0.0021211036946624517,
                0.023841196671128273,
                0.043547023087739944,
                -0.006044283043593168,
                0.04338369891047478,
                -0.021255629137158394,
                0.007275091018527746,
                0.05577428266406059,
                -0.003802286460995674,
                0.011193761602044106,
                -0.01516739372164011,
                0.047506287693977356,
                -0.01258095819503069,
                0.027594290673732758,
                -0.05708960071206093,
                0.020830007269978523,
                -0.0349314883351326,
                -0.0041778311133384705,
                0.0036184368655085564,
                -0.018096555024385452,
                -0.020329684019088745,
                0.017045579850673676,
                0.005398617126047611,
                0.026505092158913612,
                -0.002547777257859707,
                0.03097790665924549,
                0.0043167187832295895,
                0.09024331718683243,
                -0.010001998394727707,
                -0.00432252325117588,
                0.011603238992393017,
                -0.005631972104310989,
                -0.020671190693974495,
                0.02951308712363243,
                0.06472813338041306,
                -0.005463019013404846,
                0.04563720151782036,
                -0.009643811732530594,
                -0.06421617418527603,
                0.03294874727725983,
                0.0075738392770290375,
                0.05432287976145744,
                0.06458047032356262,
                -0.024633420631289482,
                -0.04180772975087166,
                -0.09908565133810043,
                -0.017469340935349464,
                0.007942739874124527,
                0.05233721062541008,
                -0.026952769607305527,
                0.031525079160928726,
                0.014387144707143307,
                0.02329218201339245,
                0.030139192938804626,
                0.051891352981328964,
                0.060214344412088394,
                -0.036094535142183304,
                -0.0062032779678702354,
                0.03870505839586258,
                0.016362424939870834,
                -0.004636619705706835,
                -0.022693175822496414,
                -0.005176603328436613,
                -0.020189126953482628,
                1.3527310038943305e-34,
                -0.012983007356524467,
                -0.06621934473514557,
                -0.004657241981476545,
                0.04339743033051491,
                0.049671877175569534,
                -0.013110119849443436,
                0.018007660284638405,
                -0.01432633027434349,
                0.027006659656763077,
                0.01295783743262291,
                0.008708533830940723,
                0.012850969098508358,
                0.05237327888607979,
                0.01932525262236595,
                -0.0168131273239851,
                -0.01113608293235302,
                0.01707741618156433,
                -0.04604708030819893,
                -0.009176557883620262,
                -0.09038473665714264,
                0.020371129736304283,
                0.07391420006752014,
                0.019959406927227974,
                -0.015655023977160454,
                -0.06432592123746872,
                0.009272517636418343,
                -0.06038554757833481,
                0.007715407758951187,
                0.048235200345516205,
                -0.03500121086835861,
                0.005276329815387726,
                0.0006835333770141006,
                0.010999058373272419,
                0.03536390885710716,
                0.002277220832183957,
                0.017903491854667664,
                -0.0227033793926239,
                0.012494158931076527,
                -0.008660951629281044,
                -0.0012512030079960823,
                -0.04281676188111305,
                -0.004666597582399845,
                -0.002573309000581503,
                0.024463392794132233,
                -0.038205742835998535,
                1.845705924097274e-06,
                -0.03259728103876114,
                0.0017959314864128828,
                0.0002821233356371522,
                0.00739452475681901,
                -0.03610802814364433,
                0.10731521248817444,
                -0.10855171829462051,
                -0.02089931070804596,
                0.03271499276161194,
                0.016496891155838966,
                -0.0269638504832983,
                0.03261854872107506,
                0.02035786397755146,
                -0.04478462040424347,
                -0.01795228011906147,
                0.013357296586036682,
                0.021596809849143028,
                -0.07069254666566849,
                -0.04117463901638985,
                0.0027458341792225838,
                -0.007303955964744091,
                -0.01562462653964758,
                -0.12030516564846039,
                -0.02655474841594696,
                -0.07622601836919785,
                0.007078483700752258,
                0.030307689681649208,
                -0.03396094590425491,
                -0.006854275241494179,
                0.02181542105972767,
                -0.03457187861204147,
                -0.02491842955350876,
                -0.04349137470126152,
                0.038375869393348694,
                -0.00012129343667766079,
                0.005608704872429371,
                0.07081428170204163,
                0.021757211536169052,
                0.07448621839284897,
                -0.024544622749090195,
                0.0013727000914514065,
                0.00975718442350626,
                -0.018608490005135536,
                0.0002470046456437558,
                -0.0020111706107854843,
                0.04557284712791443,
                0.04907427728176117,
                -0.022407663986086845,
                0.019766079261898994,
                0.05254419893026352,
                -0.08970252424478531,
                0.0072953603230416775,
                0.01563793048262596,
                0.02595265954732895,
                -0.018668822944164276,
                -0.04350811988115311,
                -0.06281384825706482,
                -0.027745123952627182,
                0.02392372116446495,
                0.0033916314132511616,
                -0.022436808794736862,
                0.016452614217996597,
                0.03884478658437729,
                -0.0010016470914706588,
                -0.023867987096309662,
                -0.04037323221564293,
                0.02657092548906803,
                -0.010600460693240166,
                -0.026045698672533035,
                0.01779359020292759,
                0.00510555412620306,
                -0.02472178265452385,
                -0.006114564370363951,
                -0.01785515993833542,
                0.044829994440078735,
                0.0060649449005723,
                0.045001834630966187,
                0.02105931006371975,
                -0.010287717916071415,
                0.04574805125594139,
                0.05140335485339165,
                -0.04823242127895355,
                0.039925433695316315,
                -0.055296771228313446,
                0.028446000069379807,
                0.018967581912875175,
                -0.03160727396607399,
                0.012008772231638432,
                -0.00013291106733959168,
                0.04492669180035591,
                0.09295430034399033,
                -0.032557934522628784,
                -0.0399969108402729,
                0.0013646767474710941,
                -0.032108910381793976,
                0.005489295814186335,
                -0.0106562664732337,
                0.004252856131643057,
                0.0033473260700702667,
                0.04822416976094246,
                0.05222573131322861,
                0.016900377348065376,
                -0.017497079446911812,
                -0.04152320697903633,
                -0.008045715279877186,
                0.0230335034430027,
                -0.04121147468686104,
                -0.007984423078596592,
                -0.016889316961169243,
                -0.03426147997379303,
                -0.03652101010084152,
                0.043097831308841705,
                -0.042953815311193466,
                0.01607525534927845,
                -0.014778945595026016,
                -0.0019078709883615375,
                0.03600204735994339,
                -0.023951226845383644,
                -0.006027653347700834,
                0.03325007110834122,
                0.01698426529765129,
                0.007114427164196968,
                -0.02011800743639469,
                -0.029532352462410927,
                -0.046233903616666794,
                0.025672849267721176,
                -0.009140366688370705,
                -0.015987861901521683,
                -0.046871017664670944,
                -0.021483227610588074,
                -0.008888566866517067,
                0.010641449131071568,
                0.013314285315573215,
                -0.047489460557699203,
                -0.033680882304906845,
                -0.02318602055311203,
                -0.04998207837343216,
                -0.01854875683784485,
                -0.0014739108737558126,
                -0.007372643798589706,
                0.12142933905124664,
                -0.02009236067533493,
                -0.009477660059928894,
                -0.09414660185575485,
                -0.013391371816396713,
                -0.02498125657439232,
                -0.07003416866064072,
                -0.004979077726602554,
                0.017202021554112434,
                0.14771883189678192,
                0.013634229078888893,
                -0.027120910584926605,
                0.00229251547716558,
                0.0045239548198878765,
                -0.0015237805200740695,
                -0.013196847401559353,
                -0.003804513718932867,
                -0.06938860565423965,
                0.006980033591389656,
                -0.017505010589957237,
                -0.01280768308788538,
                0.02537185326218605,
                0.007424947340041399,
                0.026906833052635193,
                -0.046510398387908936,
                -0.013098091818392277,
                -0.03505609184503555,
                0.03508930653333664,
                0.044492561370134354,
                -0.016057182103395462,
                0.007625066675245762,
                0.011510386131703854,
                0.01222927775233984,
                0.04871295392513275,
                -0.01342677976936102,
                0.0040884437039494514,
                0.01962897926568985,
                -0.016068030148744583,
                0.007430911064147949,
                -0.00902597513049841,
                0.004464126192033291,
                0.005481150932610035,
                0.02403990551829338,
                -0.0494016595184803,
                0.005016033537685871,
                -0.029517069458961487,
                0.05490574240684509,
                0.038239024579524994,
                0.002950397552922368,
                0.03136555105447769,
                0.028076356276869774,
                -0.02019534260034561,
                -0.003948149736970663,
                0.05375922471284866,
                -0.010489355772733688,
                -0.0018435617676004767,
                0.023519622161984444,
                0.010286789387464523,
                0.033272210508584976,
                0.04398772493004799,
                0.0801473930478096,
                0.07130792737007141,
                0.007434676866978407,
                -0.002255985513329506,
                -0.1291394680738449,
                -0.02497345209121704,
                -0.0037386049516499043,
                -0.002090167487040162,
                0.030575072392821312,
                -0.021606050431728363,
                0.04594765603542328,
                0.003564240410923958,
                -0.047070156782865524,
                0.009858742356300354,
                0.006882662884891033,
                -0.01905878446996212,
                -0.004761229269206524,
                0.01741158403456211,
                -0.03301521763205528,
                0.03563088923692703,
                -0.08174661546945572,
                -0.02499622106552124,
                0.018400993198156357,
                -0.014903969131410122,
                -0.012456968426704407,
                -0.0035286343190819025,
                -0.03221416473388672,
                -0.010773696936666965,
                -0.047986842691898346,
                0.042037270963191986,
                -0.032350052148103714,
                -0.0013521212385967374,
                -0.03985210508108139,
                0.025327082723379135,
                0.012664848938584328,
                -0.005088025238364935,
                -0.018476365134119987,
                0.02581912651658058,
                -0.016121655702590942,
                0.02953016757965088,
                -0.019133245572447777,
                0.039216525852680206,
                0.021544629707932472,
                -0.02549879066646099,
                -0.02777586504817009,
                0.01101220864802599,
                0.033104218542575836,
                -0.04162083938717842,
                0.0055313087068498135,
                0.007444456219673157,
                -0.015779880806803703,
                -0.05290639400482178,
                0.04006865248084068,
                0.04865683987736702,
                -0.00969870574772358,
                -0.06342019885778427,
                0.015186889097094536,
                0.03386976197361946,
                -0.05765161290764809,
                0.0010131867602467537,
                -0.0020102187991142273,
                -0.07630521804094315,
                -0.008608435280621052,
                0.007339625619351864,
                -0.011922203935682774,
                -0.02165740728378296,
                0.027238143607974052,
                0.043599359691143036,
                -0.0009661513613536954,
                -0.04435228928923607,
                -0.05699503794312477,
                -0.007106178440153599,
                -0.01994788646697998,
                -0.033960334956645966,
                0.035640012472867966,
                0.005273986142128706,
                0.014437675476074219,
                -0.01533240545541048,
                -0.01153547503054142,
                0.03886687755584717,
                -0.038464318960905075,
                -0.027559958398342133,
                -0.03427910432219505,
                0.0362546481192112,
                -0.012761113233864307,
                0.04493815451860428,
                -0.022047290578484535,
                -0.009905043989419937,
                0.006747431121766567,
                0.033749453723430634,
                0.022893026471138,
                0.05852651223540306,
                0.028224442154169083,
                0.007835314609110355,
                0.02531903237104416,
                -0.023130720481276512,
                -0.027611279860138893,
                0.03355299308896065,
                0.005101797170937061,
                -0.002056185621768236,
                -0.006222066003829241,
                -0.04322171211242676,
                0.028660735115408897,
                -0.011037511751055717,
                0.004067027475684881,
                0.012314198538661003,
                -0.02706729993224144,
                -0.05173809453845024,
                -0.033461857587099075,
                -0.04288073629140854,
                -0.07006730884313583,
                -0.004161560442298651,
                0.012317261658608913,
                -0.02152945287525654,
                -0.028083186596632004,
                0.014104432426393032,
                0.04956744611263275,
                -0.012573356740176678,
                -0.028199758380651474,
                -0.002288339426741004,
                -0.08116338402032852,
                -0.04473649710416794,
                -0.02008305862545967,
                0.0216840673238039,
                -0.02330721542239189,
                0.0076001775451004505,
                0.04955832660198212,
                0.024855855852365494,
                -0.006268887780606747,
                0.12911203503608704,
                0.06508079916238785,
                0.03990944102406502,
                0.046024199575185776,
                -0.004722607787698507,
                0.03307114914059639,
                0.046368759125471115,
                0.08108711242675781,
                -0.0236483346670866,
                0.08569959551095963,
                -0.012844276614487171,
                0.004990800749510527,
                0.020423926413059235,
                -0.10101872682571411,
                -0.023643208667635918,
                0.010626599192619324,
                0.014613582752645016,
                -0.03430523723363876,
                -0.0006345832953229547,
                0.01938455179333687,
                0.013453380204737186,
                -0.01843353733420372,
                0.00047582355909980834,
                0.02257082238793373,
                0.0670270323753357,
                0.03086502104997635,
                0.006713246461004019,
                0.016925876960158348,
                0.011858507059514523,
                0.045181144028902054,
                0.04736714065074921,
                0.0038806216325610876,
                -0.0031937153544276953,
                0.004864898510277271,
                -0.01041258592158556,
                0.00841696560382843,
                0.023471230641007423,
                0.10790125280618668,
                0.06943889707326889,
                0.034262727946043015,
                -0.02576344832777977,
                0.017102399840950966,
                0.003355678403750062,
                0.022426651790738106,
                0.04830833151936531,
                -0.07584740966558456,
                -0.07498853653669357,
                0.051937349140644073,
                0.024416176602244377,
                0.011209458112716675,
                0.05547480285167694,
                -0.05045408755540848,
                -0.010907081887125969,
                -0.06129029393196106,
                0.037038885056972504,
                -0.06190287321805954,
                0.012358645908534527,
                -0.005061063915491104,
                0.012346308678388596,
                0.020431380718946457,
                -0.029198769479990005,
                0.01453746110200882,
                0.0006193555309437215,
                -0.016014564782381058,
                0.022853238508105278,
                -0.010706442408263683,
                0.054946865886449814,
                0.012180942110717297,
                -0.005238880403339863,
                -0.01967758685350418,
                0.0013951013097539544,
                -0.08263293653726578,
                0.008074230514466763,
                -0.021334383636713028,
                -0.08446360379457474,
                0.001485922490246594,
                -0.004957216326147318,
                -0.013955987989902496,
                -0.018404506146907806,
                -0.03228309750556946,
                -0.07006733864545822,
                0.03241077810525894,
                0.022414404898881912,
                -0.06489752978086472,
                -0.005112952087074518,
                0.060525279492139816,
                0.0006049468647688627,
                0.033689625561237335,
                0.054297927767038345,
                0.0006108062225393951,
                0.06679727137088776,
                -0.04379882290959358,
                -0.030169570818543434,
                0.05191437900066376,
                0.033583611249923706,
                0.04465356096625328,
                -0.002682171994820237,
                0.0211484432220459,
                0.0034768544137477875,
                0.00274845352396369,
                0.0034797322005033493,
                -0.012578199617564678,
                0.02754436433315277,
                -0.01172927487641573,
                0.027251562103629112,
                -0.038807231932878494,
                -0.03408004343509674,
                0.0005167161580175161,
                0.003044631564989686,
                0.02528242953121662,
                0.05084994062781334,
                0.01818607747554779,
                -0.05572854354977608,
                -0.020017696544528008,
                0.07595379650592804,
                -0.025308337062597275,
                -0.004255114123225212,
                -0.0071003069169819355,
                -0.04208417609333992,
                -0.01953055150806904,
                -0.002048570429906249,
                -0.01389442291110754,
                0.023362817242741585,
                -0.015782998874783516,
                0.01097481232136488,
                -0.03096938133239746,
                -0.004648104310035706,
                0.022489145398139954,
                -0.024469546973705292,
                -0.07385076582431793,
                -0.034406743943691254,
                -0.023642245680093765,
                -0.057013340294361115,
                -0.008231638930737972,
                -0.004865298513323069,
                -0.003845633938908577,
                -0.021327635273337364,
                0.03347429633140564,
                0.01072319783270359,
                0.005249174777418375,
                0.0008579874993301928,
                0.01495168823748827,
                -0.025389377027750015,
                0.04990646243095398,
                0.011262002401053905,
                -0.00017750603728927672,
                0.03510940819978714,
                -0.0006562892813235521,
                -0.04933057725429535,
                -0.07465231418609619,
                -0.020547688007354736,
                -0.012473867274820805,
                -0.03730517625808716,
                -0.025426700711250305,
                -0.007834164425730705,
                -8.401194645557553e-05,
                -0.00014590130012948066,
                -0.0015743388794362545,
                0.014611893333494663,
                0.01234329305589199,
                0.006301552522927523,
                -0.017193472012877464,
                0.00700313039124012,
                0.009560240432620049,
                -0.07830379903316498,
                0.007536205928772688,
                -0.12996141612529755,
                0.03750712797045708,
                -0.03341582790017128,
                0.013853375799953938,
                -0.023216119036078453,
                0.02652432955801487,
                -0.014142926782369614,
                0.05683833360671997,
                -0.006948052905499935,
                0.04395894706249237,
                -0.07841885089874268,
                -0.011176809668540955,
                0.03494485840201378,
                -0.018214132636785507,
                -0.021329240873456,
                -0.00398440845310688,
                -0.03268018364906311,
                0.00022254683426581323,
                0.020308326929807663,
                0.0020200060680508614,
                -0.01873585768043995,
                0.027341516688466072,
                -0.05430655553936958,
                -0.025386076420545578,
                0.020295727998018265,
                -7.157276534889818e-33,
                0.017460135743021965,
                0.035884514451026917,
                -0.04522696137428284,
                -0.016059303656220436,
                -0.08116036653518677,
                0.005727996118366718,
                0.02948019839823246,
                0.03436051681637764,
                -0.041826799511909485,
                -0.00826746504753828,
                -0.016909169033169746,
                -0.00124557560775429,
                0.023815248161554337,
                -0.014282872900366783,
                0.043828193098306656,
                -0.05027756467461586,
                -0.06168545037508011,
                0.036097604781389236,
                -0.025211485102772713,
                -0.023650547489523888,
                0.011530046351253986,
                0.03050215169787407,
                0.02695618011057377,
                0.042878251522779465,
                0.026344506070017815,
                0.0275281872600317,
                -0.0001227303728228435,
                0.0006093816482461989,
                0.090950146317482,
                -0.018766336143016815,
                0.002909367438405752,
                -0.010896136052906513,
                -0.011357187293469906,
                0.0077156140469014645,
                0.006991411559283733,
                0.01873878203332424,
                -0.0018272888846695423,
                0.0005022409604862332,
                0.025640612468123436,
                -0.018827881664037704,
                -0.07493017613887787,
                -0.03927460312843323,
                0.02203022688627243,
                0.03713032603263855,
                -0.017817646265029907,
                -0.0649508684873581,
                -0.00950339250266552,
                0.023633867502212524,
                0.020772887393832207,
                0.02444729208946228,
                -0.04070150852203369,
                -0.0061045121401548386,
                -0.05580604821443558,
                -0.0285834688693285,
                -0.014217627234756947,
                -0.04298796132206917,
                -0.03267005458474159,
                0.12258315086364746,
                -0.03580779209733009,
                0.002828875556588173,
                -0.04983733594417572,
                0.015481571666896343,
                -0.024378249421715736,
                -0.0061488826759159565,
                -0.007152367848902941,
                0.004549053031951189,
                -0.042226117104291916,
                -0.0018285744590684772,
                0.010062326677143574,
                0.02684430591762066,
                -0.005451797042042017,
                0.04067429155111313,
                0.06173258274793625,
                -0.015421614050865173,
                -0.12435360997915268,
                -0.025640757754445076,
                0.016165345907211304,
                0.0030800937674939632,
                -0.011404873803257942,
                0.02754501812160015,
                0.03208073228597641,
                0.022665785625576973,
                0.005311734043061733,
                0.022666912525892258,
                -0.053806155920028687,
                -0.02698439545929432,
                -0.0009638917981646955,
                0.08623965829610825,
                -0.01193237490952015,
                -0.015919502824544907,
                0.01559638511389494,
                -0.010827742516994476,
                0.006178863812237978,
                0.04137427359819412,
                0.028280461207032204,
                -0.0006295579951256514,
                0.04603765904903412,
                0.02342049404978752,
                0.025279900059103966,
                -0.033725474029779434,
                0.038750991225242615,
                0.06277406215667725,
                -0.041593343019485474,
                0.005038995295763016,
                0.06226169317960739,
                -0.03592763841152191,
                0.021173739805817604,
                -0.027044281363487244,
                -0.05594231188297272,
                -0.006713804788887501,
                -0.027587108314037323,
                0.0628940537571907,
                0.04188518226146698,
                -0.005692843813449144,
                -0.02262190170586109,
                0.00369266327470541,
                0.018303319811820984,
                -0.069603830575943,
                0.037679851055145264,
                -0.03897208347916603,
                0.026657110080122948,
                0.047398775815963745,
                -0.037035223096609116,
                0.01356776338070631,
                -0.03293518349528313,
                0.044101472944021225,
                0.02599448151886463,
                0.04708421602845192,
                0.0015876011457294226,
                -0.03659961000084877,
                -0.010153263807296753,
                0.002528128679841757,
                2.397077594196162e-07,
                -0.07774632424116135,
                -0.0018346781143918633,
                -0.04158779978752136,
                -0.0415651872754097,
                0.02618149109184742,
                0.04575963690876961,
                -0.02730693481862545,
                0.0621618926525116,
                0.02686607651412487,
                -0.002601314801722765,
                0.05916868895292282,
                -0.039452169090509415,
                0.028267553076148033,
                -0.007278571370989084,
                0.07352573424577713,
                -0.045724425464868546,
                0.021782739087939262,
                -0.03958345204591751,
                0.04402041435241699,
                -0.016462542116642,
                0.012777196243405342,
                0.004877476487308741,
                -0.03984332084655762,
                0.010069411247968674,
                0.04080933332443237,
                0.09057287126779556,
                0.013146691024303436,
                -0.07926946133375168,
                0.03876044228672981,
                0.01001356914639473,
                0.0011716652661561966,
                -0.009095772169530392,
                -0.03435322642326355,
                -0.056717827916145325,
                -0.022672535851597786,
                -0.01926879771053791,
                0.0008419972728006542,
                0.05702285096049309,
                -0.010780656710267067,
                0.003649106016382575,
                0.006551457103341818,
                -0.021396690979599953,
                -0.01284649409353733,
                0.02333720400929451,
                0.02621482126414776,
                -0.016804657876491547,
                -0.009663472883403301,
                0.018273524940013885,
                -0.02174556441605091,
                -0.002032550983130932,
                -0.03356902673840523,
                0.05396856740117073,
                -0.04908609762787819,
                0.07568398863077164,
                0.03089897520840168,
                0.01081724464893341,
                0.02700439654290676,
                0.017445826902985573,
                0.022213349118828773,
                -0.045309748500585556,
                0.004772164858877659,
                -0.029817838221788406,
                -0.01018062699586153,
                0.07649435102939606,
                0.006135092582553625,
                0.034296393394470215,
                -0.02304321527481079,
                7.010371653871947e-35,
                0.008757145144045353,
                -0.00912371464073658,
                0.026026686653494835,
                -0.016325922682881355,
                0.015736712142825127,
                0.0016544527607038617,
                -0.028755851089954376,
                0.03616193309426308,
                -0.005881049204617739,
                -0.03531075268983841,
                0.02094443142414093,
                0.0027629362884908915,
                -0.013554886914789677,
                -0.0074622537940740585,
                -0.0056210653856396675,
                -0.017226645722985268,
                0.04743518307805061,
                -0.037416961044073105,
                0.0008273339481092989,
                0.0034077027812600136,
                0.03743090108036995,
                0.04919927567243576,
                0.05524240806698799,
                -0.04498247057199478,
                -0.07537245005369186,
                -0.00764619093388319,
                -0.05992960184812546,
                0.01884591393172741,
                0.04522884637117386,
                -0.00196952186524868,
                0.009029877372086048,
                -0.009296375326812267,
                0.034904103726148605,
                0.03352748230099678,
                0.010215528309345245,
                0.012502368539571762,
                -0.049462009221315384,
                0.0215710811316967,
                -0.004379442427307367,
                -0.01098652370274067,
                -0.005142203066498041,
                0.004962374456226826,
                -0.01529515627771616,
                0.011495443992316723,
                -0.03716849535703659,
                1.904137093333702e-06,
                -0.02916780114173889,
                0.018895968794822693,
                -0.010603436268866062,
                0.014692459255456924,
                -0.027281535789370537,
                0.06356441229581833,
                -0.09713640809059143,
                0.011786331422626972,
                0.025023380294442177,
                -0.005199367180466652,
                -0.026096686720848083,
                0.027345972135663033,
                -0.00421809870749712,
                -0.022542022168636322,
                0.003091752063483,
                -0.010939694941043854,
                0.026298176497220993,
                -0.057942070066928864,
                -0.035179078578948975,
                0.03785208240151405,
                0.0479569286108017,
                -0.0044656964018940926,
                -0.02983962371945381,
                0.02290588989853859,
                -0.06295538693666458,
                -0.012190586887300014,
                0.00835444312542677,
                -0.022258704528212547,
                0.018971554934978485,
                0.03224032744765282,
                -0.002229811390861869,
                0.08272536098957062,
                -0.03591671958565712,
                0.030950404703617096,
                0.0003323761629872024,
                -0.020083215087652206,
                0.06030644103884697,
                0.020029693841934204,
                0.013503450900316238,
                0.008739247918128967,
                -0.01077077817171812,
                -0.012688545510172844,
                0.003253416158258915,
                0.006549533922225237,
                0.0061324178241193295,
                0.00535047659650445,
                0.030805613845586777,
                0.003201642306521535,
                0.05813524127006531,
                0.0611393116414547,
                -0.04562380164861679,
                -0.019235823303461075,
                0.017524922266602516,
                0.011423168703913689,
                0.007287373300641775,
                -0.028264107182621956,
                -0.024348407983779907,
                -0.02404516004025936,
                0.025256386026740074,
                0.006796552333980799,
                -0.012412249110639095,
                0.019687015563249588,
                0.04280087351799011,
                -0.009277677163481712,
                -0.02112208679318428,
                -0.012979722581803799,
                0.0452757403254509,
                0.021706439554691315,
                0.012582092545926571,
                0.03856860473752022,
                -0.005152765195816755,
                -0.04013793542981148,
                0.013111147098243237,
                -0.016804445534944534,
                0.037746984511613846,
                -0.021027853712439537,
                0.02628466673195362,
                0.01709810644388199,
                0.005580628756433725,
                0.06300569325685501,
                0.007873591966927052,
                -0.02520328387618065,
                0.0414385125041008,
                -0.023057717829942703,
                0.01861494965851307,
                -0.08870330452919006,
                -0.020300105214118958,
                -0.010968332178890705,
                -0.0036126214545220137,
                0.010683096945285797,
                0.0737905204296112,
                -0.03847146034240723,
                -0.016489973291754723,
                0.016473183408379555,
                -0.03405243158340454,
                -0.024000728502869606,
                -0.042385466396808624,
                0.026330703869462013,
                0.00666098203510046,
                0.03727634996175766,
                0.07221610099077225,
                0.003490813309326768,
                -0.024255644530057907,
                -0.04874382168054581,
                -0.0001276662660529837,
                0.042814888060092926,
                -0.018937967717647552,
                -0.006553350482136011,
                -0.009397882036864758,
                -0.006483621429651976,
                -0.023159658536314964,
                0.04955974593758583,
                0.013348281383514404,
                0.0026312884874641895,
                -0.025578977540135384,
                -0.01543433777987957,
                0.03981267288327217,
                -0.00701757799834013,
                -0.0060425144620239735,
                0.03525446355342865,
                0.016048168763518333,
                0.0189494751393795,
                -0.02791326306760311,
                -0.039161164313554764,
                -0.04308827593922615,
                0.019112655892968178,
                -0.0025561125949025154,
                0.01731245219707489,
                -0.03588198125362396,
                -0.014737570658326149,
                -0.027584096416831017,
                0.041329409927129745,
                -0.02413279004395008,
                -0.045639459043741226,
                0.024524355307221413,
                -0.019574182108044624,
                -0.037993546575307846,
                -0.004594820085912943,
                0.007556811440736055,
                -0.007528776302933693,
                0.13601309061050415,
                -0.027963649481534958,
                0.03202635049819946,
                -0.06696163862943649,
                -0.006392334587872028,
                -0.02802618220448494,
                -0.03956892341375351,
                -0.017895478755235672,
                0.04583815857768059,
                0.07256526499986649,
                0.01165151596069336,
                -0.03397327661514282,
                0.01868387870490551,
                -0.005012040492147207,
                -0.007466478273272514,
                -0.012854691594839096,
                0.0031014380510896444,
                -0.03632340207695961,
                -0.01894715055823326,
                -0.007160494569689035,
                -0.024008365347981453,
                0.02190444990992546,
                -0.07804238051176071,
                0.016640212386846542,
                -0.041993118822574615,
                0.016910383477807045,
                -0.04948655888438225,
                0.01754515804350376,
                0.03842935338616371,
                -0.011333229951560497,
                -0.015685604885220528,
                0.015432558953762054,
                -0.0015252222074195743,
                -0.0441378578543663,
                -0.000608748581726104,
                0.00026012706803157926,
                0.040885861963033676,
                0.009115473367273808,
                0.0022775994148105383,
                -0.007671070285141468,
                0.022953292354941368,
                0.012932167388498783,
                0.044837433844804764,
                -0.11308135837316513,
                0.040994368493556976,
                -0.022330082952976227,
                0.007383265998214483,
                0.0377519354224205,
                0.026201369240880013,
                -0.06575615704059601,
                0.021893061697483063,
                -0.027676915749907494,
                0.00032080590608529747,
                0.03329750895500183,
                -0.0036137390416115522,
                0.010303317569196224,
                0.02704036422073841,
                0.02118314616382122,
                0.012494759634137154,
                0.04820218309760094,
                0.10943171381950378,
                0.015477923676371574,
                -0.003780822502449155,
                0.011788356117904186,
                -0.15123867988586426,
                -0.02925630286335945,
                0.07137764245271683,
                0.01383436843752861,
                -0.014720052480697632,
                0.0017093855421990156,
                0.024341097101569176,
                0.06305932998657227,
                -0.04272972792387009,
                0.0037445512134581804,
                -0.026457546278834343,
                -0.0125178974121809,
                0.004716800060123205,
                0.042583197355270386,
                -0.01136862300336361,
                0.05293446034193039,
                -0.06213589757680893,
                -0.04578839987516403,
                0.018477413803339005,
                -0.04067569226026535,
                -0.006067040376365185,
                0.008894241414964199,
                -0.00860676635056734,
                -0.01238276157528162,
                -0.04944808781147003,
                0.014784940518438816,
                -0.05841367319226265,
                0.011069119907915592,
                -0.07608438283205032,
                -0.009429548867046833,
                0.019871197640895844,
                0.002333028009161353,
                0.0032462365925312042,
                0.003297694493085146,
                -0.022987553849816322,
                0.01787343993782997,
                -0.043699491769075394,
                0.07833965122699738,
                0.014979597181081772,
                -0.02745744399726391,
                0.005090288817882538,
                0.003882661461830139,
                0.0423138253390789,
                -0.058551523834466934,
                0.0023486209101974964,
                -0.011137308552861214,
                -0.0014173005474731326,
                -0.0159579049795866,
                -0.008666987530887127,
                0.0231211818754673,
                -0.008453506976366043,
                -0.056255556643009186,
                0.002826223848387599,
                0.03441571444272995,
                -0.07487214356660843,
                0.014686554670333862,
                -0.031413789838552475,
                -0.049992747604846954,
                0.014462381601333618,
                0.013429798185825348,
                -0.01985371671617031,
                -0.028970465064048767,
                0.060118068009614944,
                -0.001981545938178897,
                0.003963978495448828,
                -0.05545113980770111,
                0.016075382009148598,
                -0.009307460859417915,
                -0.014962957240641117,
                -0.022248223423957825,
                0.07044108957052231,
                -0.00229018647223711,
                -0.002752306405454874,
                0.0008868314325809479,
                -0.015352820977568626,
                0.025177884846925735,
                -0.06882642954587936,
                -0.023196108639240265,
                -0.02870715968310833,
                0.03898179531097412,
                -0.009720114059746265,
                0.028629126027226448,
                -0.005878088995814323,
                -0.004070097114890814,
                0.014441399835050106,
                0.0311376191675663,
                0.03310372307896614,
                0.03484902158379555,
                0.032608240842819214,
                0.05142902210354805,
                0.0009486075723543763,
                -0.021384909749031067,
                0.013213051483035088,
                0.016347073018550873,
                -0.03413721174001694,
                -0.04736656695604324,
                0.027905214577913284,
                -0.00877540372312069,
                -0.02917792834341526,
                0.002166059101000428,
                -0.01484019123017788,
                0.04584208130836487,
                -0.006409632507711649,
                -0.024568378925323486,
                -0.046146612614393234,
                -0.008961285464465618,
                -0.026572229340672493,
                0.021804993972182274,
                -0.0072792223654687405,
                -0.021699441596865654,
                -0.025111597031354904,
                0.022370316088199615,
                0.07243379950523376,
                -0.012226591818034649,
                -0.023824313655495644,
                0.0011304781073704362,
                -0.03129125013947487,
                -0.01265277061611414,
                0.011962237767875195,
                0.042172517627477646,
                -0.04774123802781105,
                0.007403555326163769,
                0.03464134782552719,
                0.06854746490716934,
                0.006809457205235958,
                0.08386962860822678,
                0.06464646011590958,
                0.03373301401734352,
                0.05393923074007034,
                -0.013602676801383495,
                -0.014917423948645592,
                0.01976572349667549,
                0.07000651955604553,
                -0.033030785620212555,
                0.0821656733751297,
                -0.0009129558457061648,
                -0.04141795635223389,
                0.02467939630150795,
                -0.08525263518095016,
                -0.04895509406924248,
                -0.016608688980340958,
                -0.023185662925243378,
                -0.02694890648126602,
                0.0032464417163282633,
                0.021161146461963654,
                0.01051926240324974,
                -0.017481425777077675,
                -0.03807636350393295,
                0.04127257689833641,
                -0.005051682237535715,
                0.010340943932533264,
                0.02479238249361515,
                0.035547759383916855,
                -0.031622469425201416,
                0.043930839747190475,
                0.057552520185709,
                0.03870336338877678,
                0.001789535628631711,
                -0.010995480231940746,
                0.007458291947841644,
                0.021744465455412865,
                0.020731182768940926,
                0.08750180900096893,
                0.07972823828458786,
                0.04412028193473816,
                -0.021846003830432892,
                0.027355877682566643,
                0.0233914777636528,
                0.06884650141000748,
                0.030790772289037704,
                -0.04825172200798988,
                -0.060633234679698944,
                0.019169514998793602,
                0.029446979984641075,
                -0.0005382325616665184,
                0.02807617001235485,
                -0.05676767975091934,
                -0.014386295340955257,
                -0.04264582693576813,
                0.08553539961576462,
                -0.020961077883839607,
                0.024174440652132034,
                -0.014901951886713505,
                0.03655099496245384,
                -0.021938549354672432,
                -0.024349920451641083,
                -0.015886344015598297,
                0.018284011632204056,
                0.009394291788339615,
                0.026772016659379005,
                -0.006701132282614708,
                0.017220256850123405,
                0.008028329350054264,
                0.009342755191028118,
                0.02379511669278145,
                0.02452344261109829,
                -0.03439197689294815,
                0.011326933279633522,
                0.04330170899629593,
                -0.07978224009275436,
                -0.016630450263619423,
                -0.03427598625421524,
                -0.02137579396367073,
                -0.007862364873290062,
                -0.014527267776429653,
                -0.08079342544078827,
                0.029294811189174652,
                0.019861528649926186,
                -0.06402458250522614,
                -0.01514082495123148,
                0.06847559660673141,
                0.012713420204818249,
                0.04038893058896065,
                0.0614628866314888,
                -0.04620424658060074,
                0.022886570543050766,
                -0.01783415488898754,
                -0.020651785656809807,
                0.009226015768945217,
                0.011208845302462578,
                0.011295572854578495,
                -0.010794448666274548,
                0.02625112608075142,
                0.022838221862912178,
                -0.02736978977918625,
                0.013295857235789299,
                -0.036534424871206284,
                0.00934553425759077,
                -0.06162310764193535,
                0.052295323461294174,
                -0.023310234770178795,
                0.005391964688897133,
                -0.008875946514308453,
                0.010765980929136276,
                0.029758233577013016,
                0.054168570786714554,
                0.026058536022901535,
                -0.06280539929866791,
                0.006498852279037237,
                0.01787669211626053,
                -0.010286809876561165,
                0.010089279152452946,
                0.02605060301721096,
                -0.010772272944450378,
                -0.00811227411031723,
                -0.024284789338707924,
                -0.05158509314060211,
                0.012103046290576458,
                -0.017119823023676872,
                -0.016326554119586945,
                -0.027354983612895012,
                0.03280937671661377,
                0.011829152703285217,
                -0.01842423900961876,
                -0.08229053020477295,
                -0.047034285962581635,
                0.007409334648400545,
                -0.062000688165426254,
                -0.0436837263405323,
                -0.06273078173398972,
                -0.048840899020433426,
                -0.046168003231287,
                0.015142965130507946,
                0.023240257054567337,
                -0.017075924202799797,
                -0.014996434561908245,
                -0.020381173118948936,
                -0.0036638143938034773,
                0.0492468923330307,
                0.040176089853048325,
                -0.001739630475640297,
                0.03112606331706047,
                -0.03309164196252823,
                -0.03576955944299698,
                -0.0689980536699295,
                -0.03861561417579651,
                0.027579668909311295,
                -0.010528060607612133,
                -0.016147268936038017,
                -0.0232956875115633,
                0.0004930379800498486,
                0.0019188926089555025,
                0.021961968392133713,
                0.03922893851995468,
                0.02147618867456913,
                -0.00891080778092146,
                -0.030425354838371277,
                0.014045230112969875,
                -0.0028291905764490366,
                -0.03600582107901573,
                -0.031745072454214096,
                -0.14951753616333008,
                0.03339085727930069,
                -0.02866305597126484,
                0.0185420960187912,
                -0.01947709359228611,
                0.05385139212012291,
                0.037085022777318954,
                0.010675759986042976,
                -0.007222667336463928,
                0.030151888728141785,
                -0.06549575179815292,
                0.0022741947323083878,
                0.002388800261542201,
                -0.011312334798276424,
                -0.05392275005578995,
                -0.01822451315820217,
                -0.03510085865855217,
                0.03726730868220329,
                0.011984751559793949,
                0.021997880190610886,
                0.004428317304700613,
                0.022722503170371056,
                -0.10608255863189697,
                -0.023414110764861107,
                0.0098866056650877,
                -7.448338487045017e-33,
                -0.00324943196028471,
                0.015700072050094604,
                -0.06641267240047455,
                -0.042725346982479095,
                -0.1043563038110733,
                -0.009071353822946548,
                -0.0031987670809030533,
                0.021457215771079063,
                -0.02322906255722046,
                0.034821685403585434,
                -0.024034975096583366,
                0.06172614172101021,
                0.019848952069878578,
                -0.020925477147102356,
                0.013581700623035431,
                -0.04502347111701965,
                -0.06201387941837311,
                0.03257405385375023,
                -0.023076485842466354,
                -0.020454417914152145,
                0.008977919816970825,
                0.034465279430150986,
                0.011704735457897186,
                0.047697216272354126,
                0.00591064291074872,
                0.00046962741180323064,
                -0.03169277310371399,
                -0.034593239426612854,
                0.06468082219362259,
                -0.008899572305381298,
                -0.03274383395910263,
                -0.00991634652018547,
                0.007324513979256153,
                0.028354624286293983,
                0.026601696386933327,
                0.03536717966198921,
                -0.006662593688815832,
                0.004487371072173119,
                0.028164157643914223,
                -0.02540634758770466,
                -0.1126171350479126,
                -0.042105335742235184,
                0.008006527088582516,
                0.0260213240981102,
                -0.015004301443696022,
                -0.07244989275932312,
                0.021080024540424347,
                -0.01643197238445282,
                0.03645971417427063,
                0.07272854447364807,
                -0.03512515500187874,
                -0.037261802703142166,
                -0.048883695155382156,
                -0.029598336666822433,
                0.02535119093954563,
                0.022203361615538597,
                -0.02626335807144642,
                0.10422404110431671,
                0.008248839527368546,
                0.02951892837882042,
                -0.0596650056540966,
                -0.004376252181828022,
                0.019852396100759506,
                -0.019689198583364487,
                0.022819137200713158,
                0.04286002367734909,
                -0.026201698929071426,
                -0.01381662581115961,
                -0.00715750502422452,
                0.0522109679877758,
                0.02878592535853386,
                0.03140020743012428,
                0.05505840852856636,
                0.018410658463835716,
                -0.13661547005176544,
                -0.04479597136378288,
                0.04483932629227638,
                0.005951173137873411,
                0.041234735399484634,
                0.045063238590955734,
                0.017980650067329407,
                -0.005090807564556599,
                0.016440169885754585,
                0.03313672915101051,
                -0.04762404412031174,
                -0.09009288996458054,
                -0.0067720492370426655,
                0.04326320067048073,
                -0.03157138451933861,
                -0.026337549090385437,
                0.015786387026309967,
                0.004809455946087837,
                -0.02656935155391693,
                0.00043129120604135096,
                0.010281452909111977,
                -0.03547239676117897,
                0.014947809278964996,
                0.05185476690530777,
                0.0213765986263752,
                -0.028263729065656662,
                0.02496761828660965,
                0.018115829676389694,
                -0.02816510573029518,
                0.01996961422264576,
                0.05950668826699257,
                -0.02808229625225067,
                -0.018480462953448296,
                -0.01266380026936531,
                -0.021115534007549286,
                -0.03510437160730362,
                -0.055654287338256836,
                0.04250681400299072,
                0.04452161863446236,
                -0.00020274783310014755,
                -0.036909069865942,
                -0.023680001497268677,
                -0.009053852409124374,
                -0.06731564551591873,
                0.02766609936952591,
                0.0007157365907914937,
                -0.002655237913131714,
                0.009302154183387756,
                -0.05567558482289314,
                0.03287911042571068,
                -0.05958516523241997,
                0.017100784927606583,
                -0.0014458015793934464,
                0.005461347289383411,
                0.01636979728937149,
                -0.03815649822354317,
                -0.014355292543768883,
                -0.005686062853783369,
                2.49258647500028e-07,
                -0.09959902614355087,
                0.0063092936761677265,
                -0.023469053208827972,
                -0.056776873767375946,
                0.032876964658498764,
                0.08251792937517166,
                -0.00869970303028822,
                0.07936786860227585,
                0.027557363733649254,
                -0.04351983964443207,
                0.04636299982666969,
                -0.05144086852669716,
                0.049830637872219086,
                -0.013922291807830334,
                0.037946902215480804,
                0.05140490084886551,
                0.028362050652503967,
                -0.03416235372424126,
                0.029526986181735992,
                -0.045857857912778854,
                0.021052831783890724,
                0.024036327376961708,
                -0.002523130737245083,
                0.0015612923307344317,
                0.03237581253051758,
                0.060279443860054016,
                -0.02188187837600708,
                -0.09914571791887283,
                0.006322021130472422,
                -0.031084571033716202,
                0.003827961627393961,
                -0.007774001453071833,
                -0.028452685102820396,
                -0.00923817791044712,
                -0.016370873898267746,
                -0.010875274427235126,
                0.009574304334819317,
                0.030406000092625618,
                0.02095768041908741,
                0.010390630923211575,
                0.0018480088328942657,
                0.022125618532299995,
                -0.021438516676425934,
                -0.004352618474513292,
                0.005724715068936348,
                -0.05033598467707634,
                -0.03519531711935997,
                0.014155884273350239,
                -0.01888144388794899,
                0.02358854189515114,
                -0.04776066541671753,
                0.039319656789302826,
                -0.05544988438487053,
                0.06896420568227768,
                0.008014111779630184,
                0.012539058923721313,
                0.031961485743522644,
                -0.0020793115254491568,
                0.039028335362672806,
                -0.09664949774742126,
                0.0039335074834525585,
                -0.04670286178588867,
                -0.020329931750893593,
                0.055636804550886154,
                0.015521839261054993,
                0.009095310233533382,
                -0.03341958671808243,
                6.796516629856237e-35,
                0.0029833039734512568,
                -0.0016459283651784062,
                0.018257079645991325,
                -0.012140556238591671,
                0.01300265733152628,
                0.0067862048745155334,
                0.02215537242591381,
                0.049163252115249634,
                0.05329412221908569,
                -0.025058835744857788,
                0.02060278132557869,
                0.04020407050848007,
                0.012135342694818974,
                -0.023110968992114067,
                -0.004768505226820707,
                0.004010733682662249,
                0.03313268721103668,
                -0.043691910803318024,
                0.03237582743167877,
                0.01687830686569214,
                0.01547370944172144,
                0.06439950317144394,
                0.0446636825799942,
                0.006561018992215395,
                -0.001926975790411234,
                -0.001212645904161036,
                -0.05750637128949165,
                0.002117146272212267,
                -0.019705401733517647,
                -0.04260540381073952,
                -0.06000542268157005,
                0.013594874180853367,
                0.0002220240276074037,
                0.045068513602018356,
                -0.06861554831266403,
                0.013859444297850132,
                0.008383275009691715,
                0.009618948213756084,
                0.046509552747011185,
                0.00037349711055867374,
                0.009363582357764244,
                0.04110105335712433,
                -0.03929893672466278,
                0.0048095532692968845,
                -0.03368546813726425,
                1.7338362567898002e-06,
                -0.04015502706170082,
                0.04838233068585396,
                -0.01748829334974289,
                -0.017882052809000015,
                0.020621581003069878,
                0.054732177406549454,
                0.00716590927913785,
                -0.032825175672769547,
                0.013180158101022243,
                0.0016031386330723763,
                0.05609901621937752,
                0.08387551456689835,
                0.002087128348648548,
                0.006754300091415644,
                0.005726035684347153,
                0.007330733351409435,
                -0.011178982444107533,
                -0.06085837259888649,
                -0.06510766595602036,
                0.02115413174033165,
                -0.045624375343322754,
                0.030921541154384613,
                0.006186910904943943,
                0.018467579036951065,
                -0.003190167946740985,
                -0.03046834096312523,
                0.00016190545284189284,
                -0.04156697914004326,
                0.0122446920722723,
                -0.03736552596092224,
                -0.002425753278657794,
                -0.004388513043522835,
                -0.0013692107750102878,
                0.06579625606536865,
                -0.028204942122101784,
                -0.005310256965458393,
                0.03682752326130867,
                0.0032325023785233498,
                0.11114109307527542,
                -0.026707623153924942,
                0.03380563110113144,
                -0.03901636227965355,
                0.018995441496372223,
                -0.024879373610019684,
                0.0013581854291260242,
                -0.0008489009342156351,
                0.07498972117900848,
                -0.02382599748671055,
                0.02998403273522854,
                0.07980527728796005,
                0.013825621455907822,
                0.006893187761306763,
                0.02906663902103901,
                -0.03907584026455879,
                0.002764275297522545,
                -0.03862229734659195,
                -0.032514020800590515,
                0.009074772708117962,
                0.06359519809484482,
                0.08055012673139572,
                0.004257071763277054,
                0.016344131901860237,
                0.032775066792964935,
                0.03563157469034195,
                -0.05500464141368866,
                -0.05753009021282196,
                0.03247137367725372,
                -0.0277163814753294,
                0.03646080940961838,
                0.002481787698343396,
                0.002600484760478139,
                0.0002212038671132177,
                -0.025189120322465897,
                -0.01934031955897808,
                0.03262463957071304,
                0.0008197776041924953,
                0.04397082328796387,
                0.026114018633961678,
                0.0008915156358852983,
                0.055507250130176544,
                -0.030767841264605522,
                -0.051981810480356216,
                0.04459613561630249,
                -0.008304277434945107,
                -0.013873797841370106,
                -0.034072425216436386,
                0.006490085739642382,
                -0.034438442438840866,
                -0.02121604233980179,
                0.03657587617635727,
                0.07452666759490967,
                -0.06069987267255783,
                -0.0110340416431427,
                -0.022095555439591408,
                -0.051644254475831985,
                -0.011371517553925514,
                1.3076778486720286e-05,
                0.007378987967967987,
                -0.0017836837796494365,
                0.03427136689424515,
                0.10327675938606262,
                0.05896368250250816,
                -0.035979051142930984,
                -0.10061278939247131,
                -0.016119632869958878,
                -0.07845865935087204,
                -0.01745111495256424,
                0.011023792438209057,
                -0.03837059810757637,
                -0.022789761424064636,
                0.0016489549307152629,
                0.015253216028213501,
                -0.042751334607601166,
                0.01881936565041542,
                -0.012072688899934292,
                0.003350366372615099,
                -0.003896287176758051,
                -0.030855314806103706,
                -0.006926245056092739,
                0.09186260402202606,
                0.007031938061118126,
                0.019342882558703423,
                0.010154560208320618,
                -0.02203923463821411,
                -0.03372539207339287,
                0.04155762493610382,
                0.04269271343946457,
                -0.020891830325126648,
                -0.03855973854660988,
                -0.03405291587114334,
                -0.025214076042175293,
                0.049772027879953384,
                -0.0014374663587659597,
                -0.03234335407614708,
                -0.009436403401196003,
                0.04472401738166809,
                -0.06714509427547455,
                -0.02880898118019104,
                -0.009290526621043682,
                -0.00573245482519269,
                0.01932980865240097,
                0.02777288481593132,
                0.02328195422887802,
                -0.02517741173505783,
                -0.0035228454507887363,
                -0.010887598618865013,
                -0.02677045203745365,
                0.02377774752676487,
                -0.0001741458399919793,
                -0.017519064247608185,
                0.022468894720077515,
                0.021781062707304955,
                0.06949771195650101,
                -0.03607255220413208,
                -0.03660395368933678,
                -0.04147257283329964,
                0.011261665262281895,
                0.015980208292603493,
                -0.015057864598929882,
                0.01433208491653204,
                0.019766876474022865,
                0.04486105963587761,
                -0.02697821892797947,
                0.04686013609170914,
                -0.013945132493972778,
                -0.01707938313484192,
                -0.018846694380044937,
                0.03675996512174606,
                0.008028499782085419,
                -0.052741359919309616,
                0.029876744374632835,
                0.03379596024751663,
                0.016293134540319443,
                0.001100105931982398,
                0.011639486998319626,
                -0.015157753601670265,
                0.007998217828571796,
                -0.007249319925904274,
                0.007350489497184753,
                0.013308905065059662,
                -0.04109476879239082,
                -0.006861370988190174,
                0.037938375025987625,
                0.0009702110546641052,
                0.030993124470114708,
                -0.014002026990056038,
                0.009209487587213516,
                0.06322014331817627,
                -0.029329033568501472,
                -0.09879281371831894,
                -0.06175971031188965,
                -0.004490857012569904,
                0.0021804673597216606,
                0.037421874701976776,
                -0.036402665078639984,
                -0.02681948058307171,
                0.0025170589797198772,
                -0.007301783189177513,
                -0.0038309707306325436,
                0.045471783727407455,
                0.0754859447479248,
                0.08485471457242966,
                0.02635841816663742,
                0.02082485519349575,
                -0.14673453569412231,
                -0.004168480634689331,
                -0.0015228524571284652,
                0.011198559775948524,
                0.012113664299249649,
                0.027663307264447212,
                -0.026527948677539825,
                0.007401895709335804,
                -0.06650693714618683,
                -0.07343124598264694,
                -0.04649585485458374,
                0.020196188241243362,
                0.0033732831943780184,
                0.06457248330116272,
                -0.012600554153323174,
                0.009083272889256477,
                -0.0655948594212532,
                0.0036259412299841642,
                0.07334838062524796,
                -0.008736352436244488,
                -0.02778630331158638,
                0.05609932169318199,
                0.01362669002264738,
                -0.001405570306815207,
                0.0035965018905699253,
                0.010166235268115997,
                -0.05128034949302673,
                0.03724750503897667,
                -0.08642840385437012,
                -0.0033515228424221277,
                0.021582648158073425,
                0.007331398315727711,
                0.015199176967144012,
                0.019761020317673683,
                -0.007651946507394314,
                -0.045640114694833755,
                0.01941802352666855,
                -0.026647349819540977,
                -0.05793719366192818,
                -0.01334562711417675,
                -0.036219578236341476,
                0.0041442746296525,
                -0.017496876418590546,
                -0.014809356071054935,
                -0.013875668868422508,
                0.025711143389344215,
                0.031140366569161415,
                0.019949715584516525,
                0.07849566638469696,
                0.017967287451028824,
                -0.014827251434326172,
                -0.07322413474321365,
                -0.02199946902692318,
                -0.013979249633848667,
                -0.05668013542890549,
                0.04247002303600311,
                -0.0007543505053035915,
                -0.03638951852917671,
                -0.0058311051689088345,
                0.052730415016412735,
                -0.022282982245087624,
                0.0036747504491358995,
                0.025951841846108437,
                0.074039027094841,
                -0.02612653188407421,
                -0.03948958218097687,
                0.05225899815559387,
                -0.0007470378768630326,
                -0.0743076503276825,
                -0.052252162247896194,
                0.009277564473450184,
                0.007070788647979498,
                0.03862684592604637,
                -0.02165558934211731,
                0.07636916637420654,
                0.04254025220870972,
                -0.004381605889648199,
                -0.044606685638427734,
                -0.034135110676288605,
                0.002620935207232833,
                0.054477669298648834,
                0.03383319824934006,
                -0.012017219327390194,
                0.0060499259270727634,
                -0.014048963785171509,
                0.003659989684820175,
                0.016259634867310524,
                0.01642913557589054,
                0.017891310155391693,
                0.05814362317323685,
                -0.01436526421457529,
                0.01930415816605091,
                0.02820795588195324,
                0.04000701382756233,
                -0.02873799577355385,
                -0.05963326245546341,
                0.03749440610408783,
                -0.03361457586288452,
                0.03167279064655304,
                -0.018423786386847496,
                0.00966599304229021,
                -0.010399321094155312,
                -0.02608313597738743,
                -0.03836590796709061,
                -0.023212328553199768,
                -0.050240736454725266,
                -0.035928674042224884,
                -0.07777167856693268,
                0.0284455344080925,
                -0.012911354191601276,
                0.005839209072291851,
                0.025799719616770744,
                0.011063722893595695,
                -0.017612984403967857,
                -0.045430079102516174,
                0.04348854348063469,
                0.0035609202459454536,
                -0.04562747851014137,
                -0.0009237839840352535,
                0.030788086354732513,
                0.024354299530386925,
                -0.04427419230341911,
                0.03511777147650719,
                -0.09116639196872711,
                0.06131213530898094,
                0.038371406495571136,
                0.07171253114938736,
                0.021337434649467468,
                0.019326696172356606,
                -0.002162353601306677,
                0.02279738150537014,
                0.05180453136563301,
                -0.0015036222757771611,
                -0.05438043549656868,
                0.026441819965839386,
                -0.00020691868849098682,
                0.10049770772457123,
                0.030973175540566444,
                -0.0355076789855957,
                -0.036416199058294296,
                -0.012143783271312714,
                0.019312936812639236,
                -0.013425174169242382,
                0.00754402345046401,
                0.0028065056540071964,
                0.011063038371503353,
                0.012650709599256516,
                0.013146619312465191,
                0.017645567655563354,
                -0.02882576547563076,
                -0.00617337878793478,
                0.05724194645881653,
                -0.022010503336787224,
                -0.007933823391795158,
                0.0410059429705143,
                0.04421122372150421,
                0.03776625543832779,
                -0.02954866737127304,
                -0.014278794638812542,
                0.011255999095737934,
                0.04238202050328255,
                0.06570956110954285,
                0.02395397610962391,
                0.047410864382982254,
                0.061899684369564056,
                0.005243664141744375,
                0.04560203477740288,
                0.059250492602586746,
                0.03963916748762131,
                -0.00879410095512867,
                -0.13271358609199524,
                -0.032327111810445786,
                -0.030167846009135246,
                0.022967195138335228,
                -0.02453554794192314,
                0.027095897123217583,
                -0.05434423312544823,
                -0.015364717692136765,
                -0.03125632926821709,
                0.021760666742920876,
                0.021570337936282158,
                0.009363239631056786,
                -0.0033667113166302443,
                0.013142047449946404,
                -0.005494510289281607,
                -0.03527389466762543,
                -0.02332456037402153,
                0.01807916909456253,
                0.0014374407473951578,
                0.03120359405875206,
                -0.008501564152538776,
                -0.030404454097151756,
                0.027247143909335136,
                -0.04649341106414795,
                0.0049737985245883465,
                -0.0021694202441722155,
                -0.10069814324378967,
                0.011464054696261883,
                0.047725800424814224,
                -0.0457129143178463,
                -0.0035641894210129976,
                0.00672096386551857,
                -0.006894298363476992,
                0.00824077520519495,
                -0.014688042923808098,
                -0.06821117550134659,
                -0.07199063897132874,
                0.023294935002923012,
                0.007020428776741028,
                -0.05291372165083885,
                0.03263940289616585,
                -0.009054882451891899,
                -0.019128531217575073,
                0.075966976583004,
                -0.015958087518811226,
                -0.07402477413415909,
                -0.01813940517604351,
                -0.042778562754392624,
                0.00745678786188364,
                -0.009284681640565395,
                0.0917312279343605,
                0.03528127819299698,
                0.03535452112555504,
                -0.06411014497280121,
                0.01953023485839367,
                -0.01027822308242321,
                -0.009213410317897797,
                0.055409450083971024,
                -0.01591898314654827,
                0.05004198104143143,
                -0.002694950671866536,
                0.04259713366627693,
                -0.0206612441688776,
                0.02626490779221058,
                -0.00689607672393322,
                0.01430109329521656,
                -0.005299507640302181,
                -0.0543082170188427,
                -0.043832626193761826,
                0.0004597507358994335,
                -0.01590040698647499,
                -0.018391232937574387,
                0.006301084533333778,
                -0.004815959837287664,
                -0.04163111746311188,
                0.030669208616018295,
                -0.02052251808345318,
                0.026977354660630226,
                -0.007740787696093321,
                0.03961077332496643,
                -0.05675657093524933,
                -0.033002033829689026,
                0.00964606087654829,
                -0.010114572942256927,
                -0.05202388018369675,
                -0.05670119449496269,
                0.04699777811765671,
                -0.062421489506959915,
                -0.015456796623766422,
                0.024959132075309753,
                -0.021952170878648758,
                -0.03466032072901726,
                0.039761483669281006,
                0.002908013528212905,
                -0.00826739426702261,
                -0.03766726702451706,
                -0.0007633846835233271,
                0.013667155057191849,
                0.027916941791772842,
                0.012496166862547398,
                -0.02846680022776127,
                0.04991665109992027,
                -0.030814064666628838,
                -0.05343828722834587,
                -0.08352234214544296,
                0.02922670729458332,
                -0.01922537572681904,
                0.00873173400759697,
                -0.07719346135854721,
                0.020652052015066147,
                -0.004036908037960529,
                -0.00836208462715149,
                -0.0067058587446808815,
                0.022665653377771378,
                0.022275837138295174,
                -0.031495314091444016,
                0.021564489230513573,
                0.0016288546612486243,
                0.004047983326017857,
                -0.050378814339637756,
                0.0504712350666523,
                -0.13741303980350494,
                0.039633382111787796,
                0.013695580884814262,
                5.79479310545139e-05,
                -0.04414476454257965,
                0.004820073954761028,
                0.014148435555398464,
                0.0637705847620964,
                -0.0018839073600247502,
                0.020113473758101463,
                -0.03375117480754852,
                0.04013917222619057,
                0.01752682961523533,
                -0.0009243008098565042,
                0.008880437351763248,
                -0.014929155819118023,
                0.00205690017901361,
                0.061081528663635254,
                0.04321638122200966,
                0.03304755315184593,
                -0.028872858732938766,
                0.023404693230986595,
                -0.08187361061573029,
                -0.008970925584435463,
                0.011738505214452744,
                -7.680713883735413e-33,
                -0.01055438444018364,
                -0.05885543301701546,
                -0.02671392820775509,
                0.04919451102614403,
                -0.04129250720143318,
                0.001533913309685886,
                -0.010497708804905415,
                0.0070849149487912655,
                -0.10891298204660416,
                -0.0379953496158123,
                0.011998924426734447,
                -0.008535074070096016,
                0.020011484622955322,
                -0.0388537235558033,
                0.01623932458460331,
                -0.05139365419745445,
                -0.03352084010839462,
                0.051140572875738144,
                0.01153088640421629,
                -0.03918144106864929,
                0.05597405135631561,
                0.037930287420749664,
                0.021929576992988586,
                -0.031174415722489357,
                0.04630278795957565,
                -0.018086599186062813,
                -0.03660041466355324,
                -0.04885247349739075,
                0.08785010874271393,
                -0.0014103908324614167,
                0.024908164516091347,
                0.006734773516654968,
                -0.004182160831987858,
                0.02622661553323269,
                0.0033310900907963514,
                0.024155620485544205,
                0.016273783519864082,
                -0.060966379940509796,
                0.024949830025434494,
                -0.017339812591671944,
                -0.051872141659259796,
                -0.033788952976465225,
                0.028481824323534966,
                -0.008226040750741959,
                -0.032168909907341,
                -0.0570957213640213,
                0.022611916065216064,
                0.0026762327179312706,
                0.0019339355640113354,
                -0.0079887630417943,
                0.014421339146792889,
                0.033691514283418655,
                -0.00619585020467639,
                -0.028140198439359665,
                0.009865817613899708,
                -0.020310884341597557,
                0.017072644084692,
                0.007995709776878357,
                -0.013874417170882225,
                0.023931531235575676,
                -0.06475599855184555,
                0.013505357317626476,
                -0.04046958312392235,
                -0.022004345431923866,
                0.02719990350306034,
                0.009877069853246212,
                -0.0628228485584259,
                -0.025977369397878647,
                0.027027813717722893,
                0.017138248309493065,
                0.0035325961653143167,
                0.0286432933062315,
                0.060529496520757675,
                -0.004152069799602032,
                -0.05036273971199989,
                -0.027193821966648102,
                0.002416216302663088,
                0.0007898909971117973,
                0.03294709697365761,
                0.04417331889271736,
                -0.008249635808169842,
                0.005871529225260019,
                -0.029496775940060616,
                0.038224805146455765,
                0.03040391206741333,
                0.012169856578111649,
                0.02371367998421192,
                -0.01848367042839527,
                -0.02222873829305172,
                0.02109329029917717,
                0.014221991412341595,
                0.005919137969613075,
                0.0003119689063169062,
                -0.018516920506954193,
                -0.025840815156698227,
                -0.017369111999869347,
                -0.016442174091935158,
                0.02931246906518936,
                0.03346221521496773,
                -0.05362468212842941,
                0.05280892923474312,
                0.032233450561761856,
                -0.014919625595211983,
                0.04084959998726845,
                0.009721544571220875,
                -0.04073215648531914,
                0.009177721105515957,
                0.04562736675143242,
                -0.037972986698150635,
                -0.014716468751430511,
                0.012294329702854156,
                0.020209329202771187,
                0.024024195969104767,
                -0.03329633176326752,
                -0.019351931288838387,
                0.021173996850848198,
                -0.012735484167933464,
                -0.08052877336740494,
                -0.005943333730101585,
                0.05643323063850403,
                0.009393149055540562,
                0.02214464545249939,
                -0.0026399055495858192,
                0.023627284914255142,
                -0.07485309988260269,
                -0.011862429790198803,
                0.0032682567834854126,
                -0.06395256519317627,
                -0.03082677535712719,
                -0.03652505204081535,
                -0.023617830127477646,
                -0.0012981909094378352,
                2.393042279891233e-07,
                -0.028876101598143578,
                0.03429490327835083,
                -0.007666959892958403,
                -0.0030701893847435713,
                0.06393133103847504,
                0.023620279505848885,
                -0.011717203073203564,
                0.014406413771212101,
                0.059401292353868484,
                -0.017116831615567207,
                0.013895112089812756,
                -0.034483879804611206,
                -0.03487544134259224,
                0.03417503461241722,
                0.051493387669324875,
                -0.00852211844176054,
                -0.016399385407567024,
                0.043011944741010666,
                -0.012663975358009338,
                -0.022838739678263664,
                0.011678121984004974,
                0.0012951462995260954,
                -0.016383852809667587,
                -0.013180136680603027,
                -0.0184694342315197,
                -0.03365916758775711,
                -0.008733879774808884,
                -0.0465184785425663,
                0.06946548074483871,
                -0.06023328751325607,
                0.028297385200858116,
                0.00923770759254694,
                -0.014675475656986237,
                -0.03545670211315155,
                -0.0015603203792124987,
                -0.025916745886206627,
                -0.033943455666303635,
                0.045611124485731125,
                -0.00468099070712924,
                0.02194870449602604,
                0.0011175620602443814,
                -0.016299882903695107,
                -0.028023451566696167,
                -0.011071057058870792,
                0.04801364243030548,
                0.10877138376235962,
                -0.04148178920149803,
                -0.03289160504937172,
                -0.06075723469257355,
                -0.017533760517835617,
                0.027093468233942986,
                0.06360244750976562,
                -0.026059648022055626,
                0.04581943154335022,
                0.05567055940628052,
                -0.004315888974815607,
                0.024221841245889664,
                0.01311201136559248,
                0.014847596175968647,
                -0.016918670386075974,
                -0.026623034849762917,
                0.0329265370965004,
                -0.02592046931385994,
                -0.014158535748720169,
                -0.005720173940062523,
                -0.03657107427716255,
                -0.017303144559264183,
                8.272338307876599e-35,
                0.01912672072649002,
                0.01740640215575695,
                0.011191077530384064,
                -0.05521852523088455,
                -0.0024108157958835363,
                -0.012530655600130558,
                0.02836492285132408,
                0.007242963183671236,
                0.050126656889915466,
                0.00680701294913888,
                0.005420831497758627
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'.\\xeb\\xc2t\\t\\x97\\xea\\xf9\\xd9\\x83\\xa7\\x86\\xa1\\xb9\\xab\\xab'",
        "id__": "fdb54ac6-6f9a-46d4-b3c7-e24a1ece1dc4"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "BSc/Marketing Management",
            "rep": [
                0.010091256350278854,
                -0.07410789281129837,
                -0.027304472401738167,
                -0.04118875041604042,
                0.06958185136318207,
                0.03330954536795616,
                -0.010881752707064152,
                -0.0023564957082271576,
                0.06467518210411072,
                -0.008726676926016808,
                0.03779245913028717,
                -0.021260052919387817,
                -0.00823337584733963,
                0.11567828804254532,
                0.035058096051216125,
                0.023029888048768044,
                -0.013428505510091782,
                -0.006951711606234312,
                -0.030668826773762703,
                -0.0139231625944376,
                -0.051324017345905304,
                0.012918258085846901,
                -0.025909142568707466,
                0.014654274098575115,
                0.00020348955877125263,
                -0.04337660223245621,
                0.019996702671051025,
                0.010193844325840473,
                0.005765952635556459,
                0.02371254935860634,
                0.01365671493113041,
                0.0014448072761297226,
                -0.0041139922104775906,
                -0.007395496126264334,
                1.1823979093605885e-06,
                -0.027147725224494934,
                -0.025631166994571686,
                0.017853721976280212,
                -0.022528281435370445,
                -0.04734865203499794,
                0.06905359774827957,
                0.10377718508243561,
                0.013431764207780361,
                0.043073222041130066,
                -0.0037653655745089054,
                0.10473555326461792,
                0.028232166543602943,
                0.012636426836252213,
                -0.014488176442682743,
                0.02409921959042549,
                -0.005068579688668251,
                -0.10254909098148346,
                -0.06739386916160583,
                -0.038533564656972885,
                -0.057654090225696564,
                -0.05547204613685608,
                0.03368170186877251,
                0.016672052443027496,
                -0.005993487313389778,
                0.06442965567111969,
                -0.003737468970939517,
                -0.02808079868555069,
                -0.04650981351733208,
                0.01418643444776535,
                0.04191124439239502,
                0.056174397468566895,
                0.08848970383405685,
                -0.0114933205768466,
                -0.03987259790301323,
                -0.04903615266084671,
                0.026192432269454002,
                0.0034483999479562044,
                -0.005562889389693737,
                0.030079711228609085,
                0.044321686029434204,
                -0.00796151626855135,
                0.0087693827226758,
                -0.011017969809472561,
                -0.014311086386442184,
                0.03768852725625038,
                -0.10042763501405716,
                0.04029196500778198,
                0.020211389288306236,
                0.019551951438188553,
                -0.04083942249417305,
                0.07541429996490479,
                -0.011065719649195671,
                0.010225966572761536,
                0.02425215393304825,
                -0.040124524384737015,
                -0.0005541797145269811,
                0.012944766320288181,
                -0.04558081924915314,
                -0.01601528376340866,
                -0.0020026254933327436,
                -0.028627751395106316,
                0.08319985121488571,
                -0.012153777293860912,
                -0.015518817119300365,
                -0.0037242069374769926,
                -0.029637306928634644,
                -0.011616422794759274,
                -0.020898472517728806,
                0.038409072905778885,
                0.018475806340575218,
                0.03138376772403717,
                0.04018319770693779,
                0.015595310367643833,
                0.0015110508538782597,
                0.04022548347711563,
                -0.05657362565398216,
                -0.003623347030952573,
                0.012063845992088318,
                -0.003999216482043266,
                -0.042627524584531784,
                0.058736152946949005,
                -0.03759013116359711,
                -0.017880048602819443,
                -0.03212587907910347,
                -0.011205485090613365,
                -0.0213021170347929,
                0.01959308236837387,
                -0.006709662266075611,
                0.018596243113279343,
                -0.0004123671678826213,
                0.0025971275754272938,
                -0.004151890520006418,
                -0.0002413768379483372,
                0.03972151130437851,
                0.0797581598162651,
                0.029736608266830444,
                -0.03983862325549126,
                -0.03532227873802185,
                -0.0021416901145130396,
                0.023357540369033813,
                -0.0006526221404783428,
                -0.028455764055252075,
                0.027688568457961082,
                -0.04811429977416992,
                -0.0025668907910585403,
                -0.008946018293499947,
                -0.04047732800245285,
                0.021548697724938393,
                0.05938613787293434,
                0.0009209439158439636,
                0.010116102173924446,
                -0.01519777998328209,
                -0.021927544847130775,
                -0.0069773271679878235,
                -0.007600652053952217,
                -0.03299270197749138,
                -0.04323863238096237,
                0.08914703875780106,
                -0.04377179965376854,
                0.039594218134880066,
                0.014987412840127945,
                0.08603651076555252,
                -0.002239150693640113,
                -0.03149575740098953,
                0.07683711498975754,
                -0.010388699360191822,
                0.010028396733105183,
                -0.020303668454289436,
                0.017069460824131966,
                -0.030168725177645683,
                -0.017842242494225502,
                0.015985611826181412,
                -0.054761916399002075,
                -0.058479856699705124,
                -0.028675898909568787,
                -0.03403725102543831,
                -0.04327753931283951,
                -0.009475803934037685,
                0.06643097847700119,
                0.037793878465890884,
                -0.06246696040034294,
                0.018194811418652534,
                -0.003289507469162345,
                -0.0204913467168808,
                -0.014282369054853916,
                -0.021063514053821564,
                -0.06090700626373291,
                0.01677255891263485,
                -0.024054773151874542,
                -0.021301856264472008,
                0.033906448632478714,
                -0.008061613887548447,
                -0.010633333586156368,
                -0.04194273799657822,
                0.019788669422268867,
                0.02057121880352497,
                0.03569648787379265,
                -0.045806415379047394,
                0.0019567878916859627,
                -0.0023381097707897425,
                -0.031718410551548004,
                -0.027746636420488358,
                -0.00417914055287838,
                -0.009409151040017605,
                -0.020175883546471596,
                -0.03341526910662651,
                0.05779166519641876,
                0.0007644030847586691,
                0.016781970858573914,
                -0.010898771695792675,
                -0.004245826508849859,
                0.05054597556591034,
                -0.059100158512592316,
                -0.009758103638887405,
                0.08257640898227692,
                -0.011468336917459965,
                -0.021320799365639687,
                0.03730125352740288,
                0.012728290632367134,
                -0.002143876627087593,
                -0.07443398237228394,
                -0.004025166388601065,
                -0.020128021016716957,
                -0.03432762995362282,
                -0.004882237873971462,
                -0.021504225209355354,
                -0.00018430629279464483,
                0.06543882936239243,
                -0.03171350434422493,
                -0.002936863573268056,
                0.027307670563459396,
                0.03729214519262314,
                -0.03017827309668064,
                0.01590758189558983,
                0.06540728360414505,
                -0.0013989561703056097,
                -0.0027283241506665945,
                0.028678545728325844,
                -0.0489826500415802,
                0.02656979113817215,
                -0.027822699397802353,
                0.07725875824689865,
                -0.01362986583262682,
                0.02878376469016075,
                0.006654354277998209,
                -0.03553193435072899,
                0.012190966866910458,
                -0.0009756174986250699,
                -0.016318876296281815,
                0.02024196833372116,
                0.02631411701440811,
                -0.016375070437788963,
                0.010894887149333954,
                0.01825021766126156,
                -0.04019264504313469,
                0.008196775801479816,
                0.02680938132107258,
                0.026472626253962517,
                0.00925186276435852,
                -0.009802044369280338,
                -0.01976817287504673,
                -0.11094121634960175,
                0.015021199360489845,
                5.985547250020318e-05,
                0.0030715561006218195,
                -0.028160052374005318,
                0.005647143814712763,
                -0.0023791007697582245,
                -0.006343709770590067,
                -0.012039079330861568,
                -0.025472652167081833,
                0.0202022697776556,
                0.007149945013225079,
                0.0069647738710045815,
                0.03363409638404846,
                0.01170662697404623,
                0.0461549386382103,
                0.025563620030879974,
                -0.003095378866419196,
                -0.010678054764866829,
                0.003514778334647417,
                -0.015619512647390366,
                0.03495943173766136,
                -0.07097502797842026,
                0.020792242139577866,
                0.05478281527757645,
                0.024846386164426804,
                -0.0273587666451931,
                -0.0051191565580666065,
                0.0038513720501214266,
                -0.014879531227052212,
                0.03223012387752533,
                -0.039836399257183075,
                -0.008604812435805798,
                0.0024613537825644016,
                -0.03698110580444336,
                0.020178545266389847,
                -0.018913256004452705,
                -0.04656321555376053,
                -0.03769635036587715,
                0.05988292023539543,
                0.007418299093842506,
                -0.002631699200719595,
                0.005922508891671896,
                -0.010712889023125172,
                -0.007333026733249426,
                -0.02576848678290844,
                0.06392757594585419,
                0.02508704364299774,
                -0.024128971621394157,
                0.027571775019168854,
                -0.03797980770468712,
                0.01226363517343998,
                -0.022966373711824417,
                -0.03222754970192909,
                -0.01667800545692444,
                0.01953752152621746,
                0.06300967186689377,
                -0.00859758723527193,
                0.061868879944086075,
                -0.054298777133226395,
                -0.07149302214384079,
                0.016178136691451073,
                0.0022967609111219645,
                0.03748784959316254,
                0.009920286014676094,
                0.023378251120448112,
                -0.04535685479640961,
                -0.024651458486914635,
                0.027921851724386215,
                0.03630044311285019,
                -0.04304490610957146,
                0.02514822967350483,
                -0.030931444838643074,
                -0.08033794909715652,
                -0.013179088942706585,
                -0.04262089356780052,
                -0.04287780821323395,
                -0.04870050027966499,
                -0.03453724831342697,
                -0.05203083157539368,
                -0.010062869638204575,
                -0.05200614780187607,
                0.003662432311102748,
                -0.03903624042868614,
                -0.00022972877195570618,
                0.026397934183478355,
                -0.03637252375483513,
                -0.028137145563960075,
                0.02631092257797718,
                -0.013404625467956066,
                0.049371637403964996,
                0.04476165398955345,
                -0.057431161403656006,
                0.00913221575319767,
                0.021355420351028442,
                -0.023464396595954895,
                0.01802222430706024,
                0.028933318331837654,
                -0.006510758772492409,
                0.014880775474011898,
                0.06654320657253265,
                0.05593361333012581,
                -0.009190677665174007,
                -0.07137694954872131,
                -0.06934439390897751,
                -0.0496445894241333,
                -0.09436552226543427,
                -0.022319944575428963,
                0.017240583896636963,
                0.005767773371189833,
                0.03461122140288353,
                -0.004172916989773512,
                0.03595799207687378,
                0.003505472093820572,
                -0.05780091509222984,
                0.03456737473607063,
                -0.0024922569282352924,
                0.02956271544098854,
                -0.014009397476911545,
                0.014747478067874908,
                -0.011390085332095623,
                -0.032802507281303406,
                -0.036961741745471954,
                -0.006840158253908157,
                0.056773506104946136,
                -0.0015920461155474186,
                0.05075767636299133,
                -0.025743858888745308,
                0.0188153013586998,
                -0.045642051845788956,
                0.01681731641292572,
                -0.009727615863084793,
                -0.006441567093133926,
                -0.009147075936198235,
                0.01639098860323429,
                0.003973441664129496,
                -0.04353809729218483,
                0.0855008065700531,
                -0.024099690839648247,
                0.025164175778627396,
                0.04357607662677765,
                -0.03252305090427399,
                0.021731462329626083,
                -0.009818220511078835,
                0.05003760755062103,
                0.024372948333621025,
                0.023136140778660774,
                0.028598861768841743,
                -0.008775461465120316,
                -0.06641586869955063,
                0.01910286396741867,
                0.0018256601179018617,
                -0.07595723867416382,
                0.018021464347839355,
                -0.02068937011063099,
                0.017136454582214355,
                0.015864787623286247,
                0.04463936761021614,
                0.01560815330594778,
                -0.0625007227063179,
                0.00631945114582777,
                0.034009821712970734,
                -0.02469901368021965,
                -0.07757598161697388,
                0.04591492563486099,
                -0.008563467301428318,
                0.0042899190448224545,
                0.015071001835167408,
                0.017200905829668045,
                -0.010113540105521679,
                0.01193168107420206,
                -0.005526396445930004,
                0.008920276537537575,
                -0.042756494134664536,
                -0.015831822529435158,
                -0.018526708707213402,
                -0.03220108896493912,
                0.007801194209605455,
                0.046780236065387726,
                -0.0027710404247045517,
                0.03507561981678009,
                -0.02565580978989601,
                -0.06369547545909882,
                0.04960254207253456,
                0.026859737932682037,
                0.059459611773490906,
                -0.02881995588541031,
                -0.0028263963758945465,
                0.06884937733411789,
                0.030110597610473633,
                -0.04084925726056099,
                0.00026780195184983313,
                -0.04053107276558876,
                -0.029871100559830666,
                0.08296716213226318,
                -0.0811820700764656,
                0.012871203944087029,
                -0.05129886791110039,
                -0.01728302799165249,
                -0.0670003667473793,
                -0.08587034791707993,
                0.007433188147842884,
                -0.03184933587908745,
                -0.02302042953670025,
                0.022431695833802223,
                0.013335463590919971,
                0.03182130306959152,
                0.02078717015683651,
                -0.002564376685768366,
                0.0009370321058668196,
                0.06922390311956406,
                0.045615341514348984,
                0.052139945328235626,
                -0.006654712371528149,
                -0.03444451466202736,
                -0.013266530819237232,
                -0.02496214769780636,
                -0.01679147779941559,
                -0.00020702746405731887,
                0.03306318819522858,
                0.04027722030878067,
                0.03558553010225296,
                0.05259215086698532,
                0.025905953720211983,
                -0.014883523806929588,
                -0.009173845872282982,
                0.06212359666824341,
                0.010886382311582565,
                -0.003484517801553011,
                0.041905149817466736,
                0.018022045493125916,
                -0.0007208992610685527,
                0.0005313087021932006,
                -0.07107102870941162,
                0.07097293436527252,
                -0.033017564564943314,
                -0.015903020277619362,
                -0.06886705756187439,
                0.004790273029357195,
                0.004429733380675316,
                0.05164245143532753,
                0.0013306529726833105,
                -0.054460760205984116,
                0.06342453509569168,
                -0.03721247985959053,
                -0.03690924495458603,
                -0.009443957358598709,
                -0.08547329902648926,
                0.02431439980864525,
                -0.023499492555856705,
                -0.012331224046647549,
                0.020853567868471146,
                0.012505044229328632,
                0.008222393691539764,
                0.034371983259916306,
                0.02059931308031082,
                0.04972554370760918,
                0.04082837700843811,
                0.029022471979260445,
                -0.020613493397831917,
                0.021583985537290573,
                -0.035258948802948,
                0.04030218347907066,
                0.020611360669136047,
                0.027920223772525787,
                -0.055067382752895355,
                0.02774561196565628,
                -0.028944965451955795,
                0.02012195996940136,
                -0.030789116397500038,
                -0.006140407174825668,
                -0.012124575674533844,
                0.054181188344955444,
                0.02646205574274063,
                0.039405662566423416,
                -0.03447827324271202,
                -0.01705784909427166,
                -0.006493818014860153,
                -0.013158545829355717,
                0.03794770687818527,
                0.0478452704846859,
                0.06960466504096985,
                0.06161030754446983,
                0.08544622361660004,
                -0.029211871325969696,
                -0.009181445464491844,
                0.0829213485121727,
                -0.0014087026938796043,
                0.0016264808364212513,
                -0.04088122770190239,
                0.0038582617416977882,
                0.0042161778546869755,
                0.028622014448046684,
                0.03958065062761307,
                -0.017249779775738716,
                0.02629929594695568,
                0.03341986611485481,
                0.05366259068250656,
                0.007890041917562485,
                -4.9206072302566027e-33,
                -0.04022185131907463,
                -0.023088844493031502,
                0.022477297112345695,
                -0.028886228799819946,
                -0.013071452267467976,
                -0.049998652189970016,
                0.02570229209959507,
                -0.004073627758771181,
                -0.025126036256551743,
                -0.017452165484428406,
                -0.04036165028810501,
                -0.02287997305393219,
                0.018543580546975136,
                0.03369186073541641,
                0.05534284561872482,
                0.03844884783029556,
                0.031322479248046875,
                0.0772683396935463,
                0.03323562815785408,
                -0.05227622017264366,
                0.003150408621877432,
                0.016549285501241684,
                -0.013350265100598335,
                0.042382922023534775,
                -0.01744016818702221,
                -0.03508549556136131,
                -0.014529773034155369,
                -0.03441490978002548,
                0.0009345629368908703,
                0.006912840995937586,
                0.044501855969429016,
                -0.02811071276664734,
                -0.0017928872257471085,
                0.046137019991874695,
                0.015039018355309963,
                -0.03559945896267891,
                0.016282597556710243,
                -0.0023132427595555782,
                -0.014958111569285393,
                -0.007529631722718477,
                0.0039058492984622717,
                0.026511946693062782,
                -0.016339823603630066,
                -0.0031369910575449467,
                -0.026021040976047516,
                -0.024021964520215988,
                -0.032581329345703125,
                0.005409407429397106,
                0.017508406192064285,
                0.02571387030184269,
                -0.026644578203558922,
                -0.03789963200688362,
                -0.018525492399930954,
                0.05569544807076454,
                -0.016410961747169495,
                -0.06988661736249924,
                0.008087698370218277,
                0.03028968535363674,
                0.0055160801857709885,
                -0.0011192038655281067,
                -0.011471320874989033,
                0.02499728463590145,
                0.03839169815182686,
                -0.01392355002462864,
                -0.0208901334553957,
                0.004632479511201382,
                -0.030864307656884193,
                0.05298460274934769,
                0.08220987766981125,
                -0.004462982527911663,
                -0.05926188826560974,
                0.0804852619767189,
                -0.05710643529891968,
                -0.022283941507339478,
                -0.09839348495006561,
                -0.05344618111848831,
                0.04809221625328064,
                -0.04435510188341141,
                0.028349468484520912,
                -0.01486116647720337,
                -0.08425430953502655,
                0.020031923428177834,
                0.0656772330403328,
                0.009846865199506283,
                0.04404258355498314,
                -0.0006078897858969867,
                0.02519175410270691,
                0.022188736125826836,
                0.03951697051525116,
                -0.010335329920053482,
                -0.011484014801681042,
                0.04508126899600029,
                -0.018135447055101395,
                0.0014451672323048115,
                0.018843160942196846,
                -0.0812486931681633,
                -0.017660511657595634,
                0.022034751251339912,
                0.02218022756278515,
                -0.004514953121542931,
                -0.05017361044883728,
                0.030615948140621185,
                -0.04485052451491356,
                -0.009667645208537579,
                -0.04665793478488922,
                0.04518161714076996,
                -0.01451075728982687,
                -0.024970058351755142,
                0.01091347448527813,
                0.0012725840788334608,
                0.038870956748723984,
                0.022733505815267563,
                0.016713151708245277,
                -0.024022702127695084,
                -0.011724104173481464,
                -0.003310395171865821,
                0.013915964402258396,
                -0.07094044983386993,
                -0.029346425086259842,
                0.027383897453546524,
                0.06216871738433838,
                -0.060900866985321045,
                0.014894512481987476,
                -0.02635839767754078,
                -0.019801074638962746,
                -0.007491008378565311,
                0.04867400974035263,
                -0.03076530247926712,
                0.008946574293076992,
                -0.03734757751226425,
                0.025335704907774925,
                -0.04770322144031525,
                1.7921988160196634e-07,
                0.026841415092349052,
                0.07049079984426498,
                -0.00391090614721179,
                0.04944697767496109,
                -0.006038336083292961,
                -0.009655669331550598,
                -0.030088208615779877,
                -0.004418121185153723,
                0.03202735632658005,
                0.011878234334290028,
                -0.02455618791282177,
                -0.04147057980298996,
                -0.06179230287671089,
                -0.08656502515077591,
                -0.03702755644917488,
                -0.0226291511207819,
                0.0210703294724226,
                -0.04602974280714989,
                -0.04935576021671295,
                -0.0006292822072282434,
                -0.08193960785865784,
                0.02195212058722973,
                0.03513139858841896,
                -0.024929383769631386,
                -0.0019273357465863228,
                -0.015635356307029724,
                -0.010385867208242416,
                0.009334993548691273,
                0.0593692921102047,
                0.01588859036564827,
                0.010026199743151665,
                0.01621253602206707,
                0.02717682346701622,
                -0.007302829064428806,
                0.019118420779705048,
                0.015673937276005745,
                0.039379268884658813,
                0.036438632756471634,
                0.010053597390651703,
                0.032745808362960815,
                -0.0199075136333704,
                0.036475419998168945,
                0.00010368163202656433,
                0.027821015566587448,
                0.00675937021151185,
                0.008363664150238037,
                -0.027750197798013687,
                -0.018936065956950188,
                -0.08534003794193268,
                -0.031435899436473846,
                -0.06383682787418365,
                0.046275779604911804,
                0.027301248162984848,
                -0.02383595146238804,
                0.009530110284686089,
                -0.004531919956207275,
                0.005474145524203777,
                0.049446623772382736,
                0.0936264619231224,
                -0.028237661346793175,
                0.0019953157752752304,
                -0.04562682285904884,
                -0.005906498525291681,
                0.034838058054447174,
                0.06887973099946976,
                -0.04041561484336853,
                -0.035150233656167984,
                8.979531895337485e-35,
                0.04558081924915314,
                -0.02612537331879139,
                0.026976479217410088,
                0.015219452790915966,
                -0.0016286534955725074,
                -0.044569872319698334,
                -0.02095247060060501,
                0.0036181584000587463,
                -0.006552909035235643,
                -0.02397375926375389,
                -0.0429719015955925
            ]
        },
        "Experience": {
            "name": "Experienced, confident, agile digital product leader with extensive private (including 10 years in FS),\nretail and public sector experience. Strong interpersonal and presentation skills who is hard\nworking, yet fun and friendly in nature.\n\nEnjoy working with people from any background/level/experience to achieve a shared goal and\nlearning new skills and technical solutions.\n\nWould like to learn more about potentially working within the Energy/Oil/Gas/Renewables sectors.",
            "rep": [
                0.0374031625688076,
                0.025323327630758286,
                -0.01997961848974228,
                -0.043407000601291656,
                0.07451281696557999,
                -0.010389627888798714,
                -0.061598289757966995,
                0.02297569252550602,
                -0.016561826691031456,
                -0.04559167101979256,
                -0.010641981847584248,
                0.0032733262050896883,
                0.007871443405747414,
                0.031959109008312225,
                0.08655140548944473,
                -0.006936227437108755,
                0.02120932750403881,
                0.003762872889637947,
                -0.010307639837265015,
                -0.00720968097448349,
                -0.0038684168830513954,
                0.021473942324519157,
                -0.048671405762434006,
                -0.009097463451325893,
                -0.015859583392739296,
                0.006909430027008057,
                -0.01579497754573822,
                0.05271390825510025,
                0.08729502558708191,
                0.044068437069654465,
                0.06335190683603287,
                -0.05661844089627266,
                0.022725805640220642,
                0.003170422278344631,
                1.6586342326263548e-06,
                0.026089437305927277,
                -0.051054276525974274,
                -0.014407647773623466,
                0.06956678628921509,
                -0.038590893149375916,
                -0.017424451187253,
                0.0242441575974226,
                -0.015741892158985138,
                0.027943463996052742,
                -0.029338974505662918,
                -0.03891713544726372,
                0.058964844793081284,
                -0.05792411416769028,
                0.043530821800231934,
                0.039687637239694595,
                -0.0024348117876797915,
                0.022386392578482628,
                -0.020405728369951248,
                -0.027166984975337982,
                -0.0780586525797844,
                0.02841869555413723,
                -0.02382902428507805,
                -0.04571688547730446,
                -0.010398673824965954,
                -0.016222599893808365,
                0.027020620182156563,
                -0.0172833651304245,
                -0.05335509777069092,
                -0.04956042766571045,
                0.02843531221151352,
                0.04306453466415405,
                -0.017244277521967888,
                -0.03429049253463745,
                0.024332791566848755,
                -0.007724079303443432,
                0.05762499198317528,
                -0.010832630097866058,
                -0.03715033456683159,
                -0.01351695042103529,
                -0.015190704725682735,
                0.018248923122882843,
                0.02363957092165947,
                0.024302450940012932,
                -0.015224131755530834,
                0.032181546092033386,
                -0.04280376434326172,
                0.022531947121024132,
                -0.02406183071434498,
                -0.06742693483829498,
                -0.0006042395834811032,
                0.06727048754692078,
                -0.028623634949326515,
                0.0022577934432774782,
                0.010948289185762405,
                -0.016270367428660393,
                0.02017097733914852,
                0.039828766137361526,
                0.007216396275907755,
                -0.017001725733280182,
                -0.029586490243673325,
                0.012723814696073532,
                0.051263824105262756,
                0.05308552086353302,
                0.03123766928911209,
                0.006311305798590183,
                -0.025446973741054535,
                0.007772723212838173,
                0.04818044602870941,
                0.003066457575187087,
                -0.02169273979961872,
                -0.024255312979221344,
                -0.0010622396366670728,
                -0.029744228348135948,
                -0.04359206184744835,
                -0.0392458438873291,
                -0.008871323429048061,
                -0.016738029196858406,
                -0.049342792481184006,
                -0.013784125447273254,
                -0.025252506136894226,
                0.030985530465841293,
                -0.025898700580000877,
                -0.02653351239860058,
                0.001548767788335681,
                0.010022580623626709,
                0.038423288613557816,
                -0.014882457442581654,
                0.026919350028038025,
                0.053422193974256516,
                -0.03234824538230896,
                0.060310930013656616,
                -0.00010887895768973976,
                0.011623933911323547,
                -0.026772772893309593,
                -0.01920256018638611,
                -0.006097879726439714,
                0.008517061360180378,
                -0.037403419613838196,
                -0.01324743777513504,
                0.004347601439803839,
                0.03287966549396515,
                -0.015198815613985062,
                -0.011508296243846416,
                -0.04321494325995445,
                0.02900003269314766,
                0.054210178554058075,
                0.019617022946476936,
                0.00968669168651104,
                0.06122058257460594,
                -0.028862325474619865,
                -0.05899298936128616,
                -0.00019974028691649437,
                0.007086422294378281,
                0.03899779170751572,
                -0.02093675173819065,
                0.0680253878235817,
                -0.022450104355812073,
                -0.019379394128918648,
                -0.015028956346213818,
                0.030660971999168396,
                0.016412008553743362,
                -0.010771126486361027,
                -0.007984298281371593,
                0.005789007060229778,
                0.019512558355927467,
                -0.00436223391443491,
                -0.037705231457948685,
                -0.010722941718995571,
                0.03313029184937477,
                -0.06322231143712997,
                0.03765846788883209,
                0.07945932447910309,
                0.02645960822701454,
                -0.025745924562215805,
                0.03443809226155281,
                0.05745227634906769,
                -0.03862525150179863,
                0.001556070172227919,
                0.012690907344222069,
                0.020288558676838875,
                -0.07390401512384415,
                0.04857401177287102,
                -0.05027949437499046,
                0.035822343081235886,
                0.055605098605155945,
                -0.03285330906510353,
                -0.13381686806678772,
                0.041673120111227036,
                0.020178791135549545,
                0.03040885180234909,
                0.01969147101044655,
                0.03516985848546028,
                0.10288160294294357,
                -0.005586800165474415,
                0.039665110409259796,
                0.01821720041334629,
                0.049374137073755264,
                -0.0426623597741127,
                0.04896460473537445,
                -0.0265952181071043,
                0.031948521733284,
                -0.0582464374601841,
                -0.017177334055304527,
                0.003029493847861886,
                -0.04321937635540962,
                -0.010055501945316792,
                0.022992294281721115,
                -0.08410988003015518,
                -0.039522361010313034,
                0.04176120460033417,
                -0.0006600713240914047,
                -0.07108866423368454,
                -0.04816586896777153,
                -0.025243429467082024,
                0.0066307284869253635,
                0.05603364109992981,
                -0.01564980298280716,
                0.022662239149212837,
                0.0184626542031765,
                -0.02910540997982025,
                -0.04862964525818825,
                -0.012347235344350338,
                -0.0006281401147134602,
                0.051772341132164,
                0.045392606407403946,
                -0.010069531388580799,
                0.025225777179002762,
                0.03012748993933201,
                0.018375130370259285,
                0.014468653127551079,
                -0.04129752144217491,
                -0.015094838105142117,
                -0.013329786248505116,
                -0.007696289103478193,
                0.05808534845709801,
                -0.013430874794721603,
                0.014971408993005753,
                0.00820247083902359,
                -0.060309819877147675,
                -0.04431838169693947,
                0.014882775954902172,
                0.05926063284277916,
                0.05988312140107155,
                0.01933671161532402,
                -0.03042280673980713,
                -0.03001529723405838,
                -0.0434299036860466,
                0.026865022256970406,
                0.04788869991898537,
                -0.04733174666762352,
                0.02488190308213234,
                -0.016245799139142036,
                0.004393052775412798,
                -0.015280823223292828,
                0.0056961458176374435,
                0.02885504625737667,
                0.020206404849886894,
                0.03357430547475815,
                0.061982106417417526,
                -0.027821872383356094,
                -0.0035054751206189394,
                -0.022765126079320908,
                0.015107546001672745,
                -0.1349542886018753,
                0.036255769431591034,
                -0.03375722095370293,
                -0.05928387492895126,
                -0.04720539599657059,
                -0.047333598136901855,
                -0.013395668938755989,
                0.014709889888763428,
                0.06430688500404358,
                0.006633227691054344,
                0.01300019584596157,
                0.03908723220229149,
                0.09285803884267807,
                0.02852589637041092,
                0.03235447779297829,
                -0.037623368203639984,
                -0.04176357761025429,
                -0.024933302775025368,
                0.03693413734436035,
                0.03523724153637886,
                -0.04123334959149361,
                0.01781369186937809,
                0.032489631325006485,
                -0.06891299039125443,
                -0.033179134130477905,
                0.008384636603295803,
                -0.0010629155440256,
                0.011481367982923985,
                -0.01928483135998249,
                -0.00738527299836278,
                -0.04477660357952118,
                0.03903926908969879,
                -0.0019723130390048027,
                0.01287991926074028,
                0.012097904458642006,
                -0.06982722133398056,
                -0.04650839790701866,
                0.03461569547653198,
                -0.00022720319975633174,
                0.007738224696367979,
                0.03159933164715767,
                0.022706851363182068,
                -0.020059749484062195,
                -0.006279598455876112,
                0.036279987543821335,
                0.015098122879862785,
                0.014428595080971718,
                0.058919116854667664,
                0.02960912138223648,
                0.010573547333478928,
                0.013468598015606403,
                -0.043724607676267624,
                -0.0050049121491611,
                0.016463717445731163,
                0.04108134284615517,
                0.04422635957598686,
                0.04487050697207451,
                -0.03472386673092842,
                -0.005153576377779245,
                -0.01207395363599062,
                0.04427751898765564,
                0.04602730646729469,
                0.010889065451920033,
                0.04861951246857643,
                0.02242644503712654,
                -0.065960593521595,
                0.0014792196452617645,
                -0.0012960765743628144,
                -0.09957720339298248,
                -0.012966659851372242,
                0.03256663680076599,
                -0.05027375370264053,
                0.0021232985891401768,
                0.0030277057085186243,
                -0.015163165517151356,
                -0.045697540044784546,
                -0.04334782809019089,
                -0.06088637188076973,
                0.02489783801138401,
                -0.06897369027137756,
                0.017046840861439705,
                -0.0266586821526289,
                -0.027645587921142578,
                0.005524929147213697,
                -0.03805543854832649,
                -0.00082031061174348,
                0.052307259291410446,
                0.0021010329946875572,
                0.05476550757884979,
                -0.00264408509247005,
                -0.05371207743883133,
                0.04828939214348793,
                0.06356634199619293,
                0.0769486129283905,
                0.0023208099883049726,
                -0.008329781703650951,
                -0.009821976535022259,
                0.05040834844112396,
                -0.0027695128228515387,
                -0.0023928668815642595,
                0.04289484769105911,
                0.027904411777853966,
                -0.03148934245109558,
                0.026350630447268486,
                -0.04629554599523544,
                -0.0003496694553177804,
                -0.014699157327413559,
                -0.006004988681524992,
                0.012955326586961746,
                -0.08543997257947922,
                -0.008030354976654053,
                -0.06070709228515625,
                -0.00203769956715405,
                -0.015472077764570713,
                -0.02475307509303093,
                -0.05319884046912193,
                -0.02501130849123001,
                0.012021454982459545,
                0.022449202835559845,
                0.06022273749113083,
                -0.003132230369374156,
                0.00693336920812726,
                0.0751892700791359,
                -0.02779894694685936,
                0.05168163776397705,
                -0.024161534383893013,
                0.012846214696764946,
                -0.02029768005013466,
                0.025642747059464455,
                -0.03151656314730644,
                -0.020951922982931137,
                0.007875570096075535,
                -0.008117735385894775,
                0.06602638214826584,
                -0.0392010360956192,
                -0.032150786370038986,
                0.02749890834093094,
                0.0011219383450224996,
                -0.055052850395441055,
                -0.04646935313940048,
                -0.04122880846261978,
                -0.008128452114760876,
                0.01630254276096821,
                0.01840018481016159,
                -0.03886236250400543,
                -0.008506773971021175,
                -0.03654655069112778,
                -0.047561969608068466,
                0.08716529607772827,
                0.019306620582938194,
                0.05046621337532997,
                0.025079354643821716,
                -0.05364932864904404,
                -0.02039182372391224,
                0.02918047085404396,
                0.04049259424209595,
                -0.0789649486541748,
                -0.05890080705285072,
                0.02143111266195774,
                0.010835360735654831,
                0.02516975812613964,
                -0.05077188462018967,
                0.032473694533109665,
                0.0018226279644295573,
                0.033837661147117615,
                -0.03343459591269493,
                0.05081479996442795,
                -0.017220381647348404,
                0.039663393050432205,
                0.035417020320892334,
                0.0279109887778759,
                -0.05734638124704361,
                -0.04322798550128937,
                0.00943148322403431,
                0.0493798591196537,
                0.02409888617694378,
                0.04233141988515854,
                -0.021518511697649956,
                0.04531621187925339,
                0.041607607156038284,
                0.02968907356262207,
                0.019066039472818375,
                -0.007732764817774296,
                -0.01708296127617359,
                0.014315452426671982,
                0.014012387953698635,
                -0.006153148133307695,
                0.004330940544605255,
                -0.007868942804634571,
                0.03925624489784241,
                -0.02471708133816719,
                -0.0032081063836812973,
                0.08184950798749924,
                -0.036125656217336655,
                -0.040244244039058685,
                -0.0205643642693758,
                -0.0367279127240181,
                -0.02337436005473137,
                -0.04256069287657738,
                -0.03891089931130409,
                0.01593933068215847,
                -0.03557624667882919,
                -0.030345357954502106,
                0.0190811138600111,
                -0.008599034510552883,
                0.017141366377472878,
                -0.002645879052579403,
                -0.0017162839649245143,
                0.024740658700466156,
                -0.054666705429553986,
                0.020937271416187286,
                -0.0791679322719574,
                0.05501892790198326,
                -0.018670525401830673,
                -0.028957361355423927,
                -0.004213118925690651,
                -0.013594194315373898,
                -0.005167183931916952,
                0.02459334023296833,
                0.0574653185904026,
                -0.06373056769371033,
                0.02299480140209198,
                -0.018676510080695152,
                -0.024761535227298737,
                0.008470828644931316,
                -0.031917471438646317,
                -0.004854554310441017,
                0.013182894326746464,
                0.040779005736112595,
                0.029647741466760635,
                -0.04267031326889992,
                -0.024816414341330528,
                0.04488012194633484,
                -0.041988905519247055,
                0.04479655623435974,
                0.0018964136252179742,
                0.05028938502073288,
                0.05195900797843933,
                -0.027195772156119347,
                0.04529062286019325,
                0.013704606331884861,
                -0.027802804484963417,
                -0.022224197164177895,
                -0.00541909784078598,
                0.029015235602855682,
                -0.044345587491989136,
                -0.019074834883213043,
                -0.022820545360445976,
                -0.006400538608431816,
                0.006196171045303345,
                -0.03113538958132267,
                -0.013747282326221466,
                -0.044763825833797455,
                -0.0052224863320589066,
                -0.06555961817502975,
                -0.056093186140060425,
                0.028334584087133408,
                0.010725954547524452,
                -0.007391243241727352,
                0.002538585104048252,
                0.03189005330204964,
                -0.03285509720444679,
                0.03193490579724312,
                0.032843172550201416,
                -0.012344411574304104,
                0.026500359177589417,
                -0.03600694611668587,
                0.009646576829254627,
                0.02500654198229313,
                0.04317615181207657,
                0.02233291231095791,
                0.010395681485533714,
                0.037476975470781326,
                -0.020169420167803764,
                -0.03580579161643982,
                -0.002812751568853855,
                0.003977183252573013,
                -0.0005395039333961904,
                0.02368737943470478,
                0.009781766682863235,
                -0.01609916053712368,
                0.04463064298033714,
                -0.01694507896900177,
                0.030020538717508316,
                -0.02293534018099308,
                0.01810183934867382,
                -0.05451477691531181,
                0.005633622407913208,
                -0.0111408531665802,
                0.016132952645421028,
                0.0495389923453331,
                -0.09383907914161682,
                0.03351972624659538,
                -0.08155558258295059,
                -0.04029186815023422,
                0.016876066103577614,
                -0.06350119411945343,
                -5.442817289531542e-33,
                -0.02066110633313656,
                -0.022516418248414993,
                0.019133668392896652,
                3.4725285331660416e-06,
                -0.02459833025932312,
                -0.010279583744704723,
                0.06381169706583023,
                -0.05093344300985336,
                -0.039414990693330765,
                -0.032519590109586716,
                -0.02472519502043724,
                0.025275345891714096,
                0.01349690556526184,
                -0.005848282482475042,
                -0.03216254711151123,
                0.09829463064670563,
                0.06870916485786438,
                -0.017278391867876053,
                -0.033300336450338364,
                -0.0019017118029296398,
                -0.005388159770518541,
                0.005774969235062599,
                0.026075266301631927,
                0.019862474873661995,
                0.027876971289515495,
                0.010149287059903145,
                -0.047292087227106094,
                0.05016122758388519,
                0.02868889458477497,
                0.01661957986652851,
                -0.002444844227284193,
                -0.02434726431965828,
                0.009365309961140156,
                0.002332760486751795,
                -0.0352320671081543,
                -0.005069899372756481,
                -0.05771864578127861,
                0.046783696860075,
                -0.0305084977298975,
                0.027066390961408615,
                0.016866737976670265,
                0.018272975459694862,
                0.04385463520884514,
                -0.037864409387111664,
                0.030544519424438477,
                -0.02186814695596695,
                0.014363188296556473,
                -0.0030449724290519953,
                0.0414414219558239,
                -0.027273066341876984,
                -0.017378129065036774,
                -0.051560837775468826,
                -0.061091143637895584,
                0.054292526096105576,
                -0.09023981541395187,
                0.01882955990731716,
                -0.003234750358387828,
                -0.00043592677684500813,
                -0.09591475874185562,
                0.010680635459721088,
                -0.013113236054778099,
                0.06076623126864433,
                0.06200651451945305,
                -0.011677920818328857,
                0.009151296690106392,
                0.027361635118722916,
                0.03966928645968437,
                0.06854315102100372,
                0.006585685070604086,
                -0.023687422275543213,
                -0.02608652599155903,
                0.008355953730642796,
                -0.08448716998100281,
                0.010992029681801796,
                -0.05853657424449921,
                -0.018352387472987175,
                -0.049050573259592056,
                -0.002871220465749502,
                0.037896499037742615,
                -0.003605940379202366,
                -0.00788192916661501,
                0.023327497765421867,
                0.050789229571819305,
                -0.00019799324218183756,
                0.010119859129190445,
                0.019675811752676964,
                -0.013496063649654388,
                0.0011590757640078664,
                0.0027940033469349146,
                0.021717263385653496,
                0.050354935228824615,
                0.012228122912347317,
                -0.02846680022776127,
                0.01563236117362976,
                0.112886443734169,
                0.0019984932150691748,
                0.0038831993006169796,
                -0.034830931574106216,
                -0.02141282521188259,
                -0.006908515002578497,
                -0.00027732315356843174,
                0.030871402472257614,
                -0.047771114856004715,
                0.002798022236675024,
                0.00021640407794620842,
                0.042234428226947784,
                -0.06050076335668564,
                0.008138082921504974,
                -0.018526162952184677,
                0.0033184997737407684,
                0.010777772404253483,
                0.018510861322283745,
                -0.021309301257133484,
                -0.017812561243772507,
                -0.019589923322200775,
                -0.025631887838244438,
                0.052328918129205704,
                0.00726779829710722,
                -0.0012082620523869991,
                0.053598690778017044,
                0.08005833625793457,
                -0.039498936384916306,
                -0.02907213754951954,
                0.01250059250742197,
                0.02057286538183689,
                -0.009897764772176743,
                -0.033913660794496536,
                -0.02980165369808674,
                -0.05376826226711273,
                -0.03794058412313461,
                0.009575428441166878,
                0.026527849957346916,
                2.418879034848942e-07,
                -0.02365812472999096,
                -0.02547813020646572,
                -0.013223342597484589,
                0.06010793149471283,
                0.0009668500861153007,
                -0.0365913100540638,
                0.0026964000426232815,
                -0.005317098461091518,
                -0.037763986736536026,
                0.019187504425644875,
                -0.018537692725658417,
                -0.0026055837515741587,
                -0.04861177131533623,
                -0.042607732117176056,
                -0.027717620134353638,
                -0.1083766296505928,
                0.028690170496702194,
                -0.003426949493587017,
                -0.039718855172395706,
                -0.04023205488920212,
                0.03609541431069374,
                -0.005462337750941515,
                0.013774708844721317,
                0.009582070633769035,
                0.016168881207704544,
                0.023232344537973404,
                0.026685763150453568,
                -0.027784189209342003,
                0.004403675906360149,
                0.07088758796453476,
                0.024360181763768196,
                -0.05161552131175995,
                0.02607208862900734,
                0.03268737718462944,
                0.011462521739304066,
                -0.008579774759709835,
                0.009798622690141201,
                0.039159707725048065,
                0.06176690384745598,
                -0.00713128037750721,
                0.013174178078770638,
                0.021036431193351746,
                0.04590054973959923,
                0.00404782360419631,
                -0.015340260230004787,
                -0.031582556664943695,
                -0.012877299450337887,
                0.06206779181957245,
                -0.06779728829860687,
                0.009786883369088173,
                -0.026907945051789284,
                0.007488075643777847,
                -0.0035829865373671055,
                -0.0425289049744606,
                0.021518301218748093,
                -0.05490522459149361,
                -0.021466009318828583,
                0.03238198161125183,
                0.03933480754494667,
                -0.03260551765561104,
                0.01635340228676796,
                0.003430427284911275,
                -0.028250642120838165,
                0.008061288855969906,
                0.062216635793447495,
                0.06451346725225449,
                -0.0020111871417611837,
                2.365590775122436e-34,
                -0.044868551194667816,
                0.018350722268223763,
                -0.029566429555416107,
                -0.02328445389866829,
                0.059136804193258286,
                -0.03819723427295685,
                -0.02193147875368595,
                0.0019059503683820367,
                0.016456320881843567,
                -0.035303350538015366,
                -0.01718958653509617
            ]
        },
        "Freelance": "Open to both",
        "Id": "2ba5095c-5bda-40b6-9424-ee405443a103",
        "Industry": {
            "name": "Product",
            "rep": [
                0.03312026709318161,
                0.01785636506974697,
                0.0011997424298897386,
                0.013727890327572823,
                -0.05276944860816002,
                -0.011578512378036976,
                0.045038457959890366,
                0.014829057268798351,
                -0.06597717106342316,
                -0.00592196686193347,
                0.02479446679353714,
                0.02035587839782238,
                -0.015353974886238575,
                0.15942633152008057,
                -0.009382911026477814,
                0.023042025044560432,
                -0.002230179961770773,
                0.048947110772132874,
                -0.052900027483701706,
                -0.0053581977263092995,
                0.018114333972334862,
                -0.00421964330598712,
                0.04242563620209694,
                0.010317392647266388,
                -0.016078215092420578,
                -0.05701306089758873,
                0.020522769540548325,
                -0.0202069953083992,
                -0.03182113170623779,
                -0.0381871722638607,
                0.022609151899814606,
                -0.005065415054559708,
                -0.006342576816678047,
                0.007450098171830177,
                1.9548672298697056e-06,
                -0.02297363430261612,
                0.018985668197274208,
                0.0173439159989357,
                -0.037534959614276886,
                0.03619051352143288,
                0.05978015065193176,
                0.05651121586561203,
                -0.027082044631242752,
                0.026813481003046036,
                -0.019189808517694473,
                -0.03152485191822052,
                -0.01558118499815464,
                0.025233037769794464,
                -0.05001973733305931,
                0.03852599486708641,
                -0.023146647959947586,
                -0.009883005172014236,
                0.018882568925619125,
                0.017557425424456596,
                0.04023383557796478,
                -0.00299867638386786,
                0.024387747049331665,
                -0.04034820944070816,
                0.05096377059817314,
                0.0007372600957751274,
                0.03164846450090408,
                -0.009297440759837627,
                -0.029205476865172386,
                0.030349303036928177,
                0.04116767272353172,
                0.011995607055723667,
                0.07826314121484756,
                -0.026834547519683838,
                -0.04302152991294861,
                0.060071367770433426,
                -0.004220956936478615,
                0.052079495042562485,
                -0.010890974663197994,
                0.05804403871297836,
                0.040615927428007126,
                -0.05443153902888298,
                -0.015159101225435734,
                -0.009146872907876968,
                0.003154029371216893,
                -0.030207520350813866,
                -0.04884493723511696,
                -0.009506044909358025,
                -0.00695539778098464,
                -0.012134428136050701,
                0.023259669542312622,
                0.08286499977111816,
                -0.017903296276926994,
                -0.014430014416575432,
                -0.015895606949925423,
                -0.04848314821720123,
                -0.065425343811512,
                -0.01648283377289772,
                -0.02678726613521576,
                0.024101650342345238,
                -0.0019205486169084907,
                0.005399053916335106,
                0.02442902885377407,
                -0.036952774971723557,
                -0.019438888877630234,
                -0.12856550514698029,
                -0.04396704584360123,
                0.021064644679427147,
                0.019722172990441322,
                0.016175897791981697,
                -0.04890531301498413,
                0.06931492686271667,
                0.028132157400250435,
                -0.06519192457199097,
                -0.05312563106417656,
                0.09812861680984497,
                -0.00894972775131464,
                0.010006303898990154,
                0.0017142160795629025,
                0.031288836151361465,
                0.026390958577394485,
                -0.06589101999998093,
                -0.026484733447432518,
                -0.02343243919312954,
                -0.041943613439798355,
                0.024179542437195778,
                0.002914775861427188,
                0.021104782819747925,
                -0.01370503380894661,
                0.0036834136117249727,
                -0.04402695968747139,
                0.0074339378625154495,
                -0.034512609243392944,
                0.014093161560595036,
                0.015911992639303207,
                -0.00493486225605011,
                0.04510090872645378,
                0.05124390870332718,
                0.008216517977416515,
                0.02754935622215271,
                0.017014699056744576,
                -0.025410152971744537,
                -0.02429397962987423,
                -0.028196411207318306,
                -0.052501335740089417,
                -0.0738389641046524,
                -0.030969737097620964,
                -0.05230046808719635,
                -0.0565062090754509,
                -0.00753673305734992,
                -0.011914875358343124,
                -0.011413762345910072,
                0.006524271797388792,
                -0.035507138818502426,
                0.00010700170241761953,
                -0.02317880466580391,
                0.01035222876816988,
                0.01766970567405224,
                -0.011653726920485497,
                0.03456690534949303,
                -0.037783846259117126,
                0.0014440972590819001,
                0.028508402407169342,
                -0.05071678012609482,
                -0.01874566823244095,
                0.019897907972335815,
                -0.02697940729558468,
                0.009981744922697544,
                -0.05098988115787506,
                -0.01996028423309326,
                -0.04074691981077194,
                -0.06641197949647903,
                -0.046376533806324005,
                0.017807161435484886,
                -0.0349188894033432,
                -0.023913703858852386,
                -0.05790097638964653,
                -0.023518262431025505,
                -0.030363814905285835,
                0.0864083394408226,
                0.061559904366731644,
                0.016471033915877342,
                -0.005683244671672583,
                -0.011020969599485397,
                -0.0677948072552681,
                0.02657383680343628,
                -0.04195664823055267,
                -0.12379150837659836,
                0.04217479005455971,
                -0.04330869019031525,
                0.011880460195243359,
                0.027094371616840363,
                0.022646203637123108,
                0.04628453031182289,
                -0.02179696597158909,
                0.006221302319318056,
                0.022032873705029488,
                -0.034346941858530045,
                -0.006414398550987244,
                0.037914324551820755,
                -0.006061671767383814,
                -0.035883549600839615,
                -0.027613043785095215,
                -0.03055356815457344,
                -0.023242613300681114,
                0.0016512534348294139,
                -0.00927747879177332,
                -0.04416311904788017,
                0.12265421450138092,
                0.030890071764588356,
                -0.06878243386745453,
                0.04270300269126892,
                0.0007085727411322296,
                0.015292460098862648,
                -0.09217019379138947,
                0.03243115544319153,
                -0.0630732923746109,
                0.032054025679826736,
                -0.02349299006164074,
                -0.015983201563358307,
                0.0019303192384541035,
                -0.054393161088228226,
                0.04120875149965286,
                0.05295807868242264,
                -0.05570477619767189,
                0.06175795570015907,
                -0.020608186721801758,
                0.03100806102156639,
                0.10230851918458939,
                -0.04955614358186722,
                -0.007819080725312233,
                -0.048826780170202255,
                0.02134857140481472,
                0.002117205411195755,
                0.006836386397480965,
                -0.005587671417742968,
                0.039866793900728226,
                0.04138021916151047,
                0.004658719524741173,
                -0.0511678121984005,
                -0.02450057677924633,
                0.06837458163499832,
                0.14154045283794403,
                -0.015588822774589062,
                0.00846550241112709,
                -0.053931377828121185,
                -0.04533262923359871,
                -0.032683998346328735,
                0.035358916968107224,
                -0.007805017288774252,
                0.05874844267964363,
                0.043862514197826385,
                0.0007076712208800018,
                0.01151964906603098,
                -0.0455201156437397,
                -0.012536736205220222,
                -0.04895171895623207,
                0.005728781223297119,
                0.03465965762734413,
                0.02144477888941765,
                -0.006035155151039362,
                -0.017547674477100372,
                -0.05573863536119461,
                -0.0030897173564881086,
                0.027957821264863014,
                0.024260709062218666,
                0.015500110574066639,
                -0.04121061787009239,
                0.0015986887738108635,
                -0.037015147507190704,
                0.01630747877061367,
                -0.009856886230409145,
                0.0336623415350914,
                -0.0058080777525901794,
                0.03140553459525108,
                -0.004542826674878597,
                0.019524285569787025,
                -0.028604401275515556,
                0.027729639783501625,
                0.021801277995109558,
                0.01700517348945141,
                -0.06081836670637131,
                0.008051482029259205,
                -0.01746511645615101,
                -0.034655481576919556,
                -0.04056566581130028,
                0.04259302839636803,
                -0.03382527083158493,
                0.05080766975879669,
                0.023651503026485443,
                0.04053116962313652,
                -0.010950149968266487,
                -0.0005899044335819781,
                0.02350979670882225,
                -0.08315929770469666,
                -0.014718830585479736,
                -0.012940369546413422,
                -0.006750027649104595,
                0.010687215253710747,
                0.05179354548454285,
                -0.0020552692003548145,
                -0.0036430892068892717,
                0.06201809644699097,
                -0.030979417264461517,
                0.00837194174528122,
                -0.035017628222703934,
                -0.011896572075784206,
                0.03449321538209915,
                -0.025206487625837326,
                -0.019753891974687576,
                -0.016832437366247177,
                -0.000852518598549068,
                0.0014821088407188654,
                0.017736876383423805,
                -0.00848561991006136,
                -0.04547160863876343,
                0.018165210261940956,
                0.003463775385171175,
                0.029703324660658836,
                0.004445071332156658,
                0.012881399132311344,
                -0.025132551789283752,
                0.03517882153391838,
                -0.03859230875968933,
                -0.0007792197866365314,
                0.05415203794836998,
                0.010083295404911041,
                -0.045065976679325104,
                -0.04142265394330025,
                -0.017064528539776802,
                0.01680579222738743,
                0.01295650377869606,
                -0.01026039756834507,
                0.012049803510308266,
                -0.011024003848433495,
                -0.027796698734164238,
                -0.007384146098047495,
                -0.06571092456579208,
                0.003695127321407199,
                0.019343912601470947,
                0.015773018822073936,
                0.016948474571108818,
                -0.033647261559963226,
                -0.03797353804111481,
                -0.020066535100340843,
                0.0366961732506752,
                -0.008713491261005402,
                -0.019390419125556946,
                0.005117152351886034,
                -0.0413154698908329,
                0.022992881014943123,
                -0.0744701400399208,
                0.018177082762122154,
                0.0320805124938488,
                0.0023605304304510355,
                0.051063623279333115,
                -0.039123665541410446,
                -0.017392942681908607,
                -0.010642590932548046,
                -0.027408931404352188,
                -0.010487143881618977,
                0.023072758689522743,
                -0.05623023957014084,
                -0.002945924876257777,
                -0.0425383597612381,
                -0.009247956797480583,
                -0.023550385609269142,
                0.054452549666166306,
                0.016659695655107498,
                0.006557398941367865,
                -0.027927419170737267,
                0.02066439390182495,
                0.03746308013796806,
                0.01916295848786831,
                -7.504221866838634e-05,
                -0.0027534193359315395,
                0.0022110147401690483,
                0.04245062544941902,
                0.03894583508372307,
                0.05579838901758194,
                -0.013056421652436256,
                0.0077755325473845005,
                0.00897219032049179,
                0.027594609186053276,
                -0.05029233545064926,
                -0.013727543875575066,
                0.017129570245742798,
                -0.015162660740315914,
                0.003582799807190895,
                -0.021653642877936363,
                -0.014466021209955215,
                0.011936122551560402,
                0.014528632164001465,
                -0.02749498002231121,
                -0.02777337282896042,
                -0.042082756757736206,
                0.004237831104546785,
                -0.0073840683326125145,
                -0.05143703520298004,
                0.0262839924544096,
                0.004537830129265785,
                0.008170777931809425,
                0.08876276016235352,
                0.0016340275760740042,
                -0.010876909829676151,
                0.02011280320584774,
                -0.004571494180709124,
                0.061516065150499344,
                -0.0049964492209255695,
                0.0074316635727882385,
                0.000697361072525382,
                -0.061383213847875595,
                0.08311481028795242,
                -0.012631972320377827,
                0.02778969146311283,
                -0.014336011372506618,
                0.04413018003106117,
                0.001238715136423707,
                0.010088780894875526,
                0.053510118275880814,
                0.010612519457936287,
                -0.01555607933551073,
                -0.031880591064691544,
                0.014692473225295544,
                0.0350048653781414,
                -0.024470405653119087,
                0.013074483722448349,
                0.015901479870080948,
                -0.014440812170505524,
                0.07342950999736786,
                -0.032629385590553284,
                0.004680463578552008,
                0.00644035404548049,
                0.010575687512755394,
                0.0028379273135215044,
                0.012637114152312279,
                -0.012318380177021027,
                -0.006187901832163334,
                -0.0037812963128089905,
                -0.004281685687601566,
                -0.01053585670888424,
                -0.02194969169795513,
                0.004614375531673431,
                -0.006972028873860836,
                0.0018040939467027783,
                -0.025665057823061943,
                -0.023569850251078606,
                0.04926101118326187,
                0.031057773157954216,
                -0.017588388174772263,
                -0.0250976774841547,
                0.033547937870025635,
                0.024288514629006386,
                0.04120003059506416,
                -0.05543326586484909,
                0.09822118282318115,
                -0.005609241314232349,
                -0.03947639837861061,
                0.06918603926897049,
                -0.020694736391305923,
                0.04543226957321167,
                0.011546376161277294,
                0.03329194709658623,
                0.048474568873643875,
                -0.014014841057360172,
                -0.030734166502952576,
                -0.039394479244947433,
                0.020190177485346794,
                -0.007575166877359152,
                -0.019887646660208702,
                0.05019345507025719,
                -0.06104043871164322,
                0.0766710638999939,
                -0.029098723083734512,
                -0.00871994998306036,
                -0.0026789610274136066,
                -0.016797376796603203,
                0.010697083547711372,
                0.031478967517614365,
                0.04103223979473114,
                0.01883764937520027,
                0.026076288893818855,
                0.037283021956682205,
                0.05241214483976364,
                0.04464863985776901,
                0.048913780599832535,
                -0.019792580977082253,
                0.026623932644724846,
                -0.050110120326280594,
                0.090913325548172,
                -0.015522788278758526,
                0.03652375936508179,
                0.012769742868840694,
                -0.00986871775239706,
                -0.01588510349392891,
                -0.004041140433400869,
                -0.01633138582110405,
                -0.04028162732720375,
                0.011203997768461704,
                0.011289358139038086,
                0.028491003438830376,
                0.005960016045719385,
                -0.04157723858952522,
                -0.09286018460988998,
                -0.04387443885207176,
                -0.02567216567695141,
                -0.0016331722727045417,
                0.024457165971398354,
                0.014830449596047401,
                0.03800203278660774,
                0.007922832854092121,
                -0.0486580990254879,
                0.0041455114260315895,
                0.02997538447380066,
                -0.002934075426310301,
                -0.007423808332532644,
                0.04545735567808151,
                0.024029670283198357,
                0.03490995615720749,
                -0.06057041138410568,
                0.00824445765465498,
                0.026312636211514473,
                -0.004429073538631201,
                -0.07069449871778488,
                -0.008343554101884365,
                0.0034909159876406193,
                -0.00605448242276907,
                -0.02636609598994255,
                0.031556759029626846,
                -0.04152007773518562,
                -0.005749143194407225,
                -0.007767964154481888,
                0.042525894939899445,
                -0.029316680505871773,
                -0.060102105140686035,
                0.04829605668783188,
                -0.017891786992549896,
                -0.017931673675775528,
                -0.02228415384888649,
                0.05576673895120621,
                -0.07080768048763275,
                0.0466073714196682,
                -0.005963880103081465,
                -0.006564665585756302,
                -0.019462237134575844,
                0.04199213907122612,
                -0.048933591693639755,
                -0.007630305830389261,
                -0.03741472586989403,
                -0.011200814507901669,
                0.07524611055850983,
                0.029047096148133278,
                -0.014821123331785202,
                0.03122231923043728,
                0.010099045000970364,
                -0.03032556362450123,
                0.0021296688355505466,
                0.03765011578798294,
                -0.03956642001867294,
                -0.033683646470308304,
                0.006416135001927614,
                -7.455467860282754e-33,
                0.015844209119677544,
                -0.03380945324897766,
                -0.0587599016726017,
                0.011753936298191547,
                -0.00233638659119606,
                0.030836524441838264,
                -0.004060111474245787,
                0.028554312884807587,
                0.0017484158743172884,
                0.012954039499163628,
                0.030027400702238083,
                -0.0172563586384058,
                0.018124517053365707,
                0.05511922389268875,
                0.014622665010392666,
                -0.07094669342041016,
                -0.018231792375445366,
                0.020436149090528488,
                0.027242759242653847,
                -0.0031362688168883324,
                0.0016273928340524435,
                0.006629771552979946,
                0.011717521585524082,
                0.11326895654201508,
                0.029239356517791748,
                -0.004752728622406721,
                0.013084819540381432,
                -0.022742565721273422,
                0.013550477102398872,
                0.029228296130895615,
                -0.00015058802091516554,
                0.02077185921370983,
                0.011889956891536713,
                0.022934501990675926,
                -0.012417352758347988,
                0.01766788214445114,
                -0.0209549181163311,
                0.05250312387943268,
                -0.04140911251306534,
                -0.009774758480489254,
                -0.14120449125766754,
                -0.00165239826310426,
                -0.0309654138982296,
                0.022820349782705307,
                -0.022196542471647263,
                0.02631472796201706,
                0.0559963658452034,
                -0.03841907158493996,
                -0.03990200161933899,
                -0.05858715996146202,
                -0.008410420268774033,
                0.022876640781760216,
                -0.01610858179628849,
                0.002196798799559474,
                0.014320515096187592,
                0.029242465272545815,
                0.007094958331435919,
                -0.032977212220430374,
                0.00880388356745243,
                0.006554065737873316,
                -0.06529192626476288,
                -0.019409995526075363,
                -0.03264925628900528,
                0.012936793267726898,
                0.004871230572462082,
                -0.04152645915746689,
                0.0015393390785902739,
                -0.00017930014291778207,
                0.012830439954996109,
                0.021931570023298264,
                0.00024338228104170412,
                0.10271095484495163,
                0.04941597208380699,
                -0.06755194067955017,
                -0.013571868650615215,
                -0.006473944056779146,
                0.029428141191601753,
                0.009701111353933811,
                0.06661566346883774,
                -0.021767018362879753,
                -0.06086455285549164,
                -0.002861959161236882,
                0.023301975801587105,
                -0.0021903563756495714,
                -0.00015367710147984326,
                0.025917064398527145,
                -0.04472440853714943,
                0.02846490405499935,
                -0.021819913759827614,
                0.040670063346624374,
                -0.04110238328576088,
                0.043906886130571365,
                0.0169557835906744,
                0.02067445032298565,
                -0.03205268457531929,
                -0.13188184797763824,
                0.01548185758292675,
                0.00869754794985056,
                0.0019568356219679117,
                -0.07296442240476608,
                0.010832496918737888,
                0.03177790343761444,
                -0.040395643562078476,
                0.02928083948791027,
                -0.011594343930482864,
                -0.043030474334955215,
                -0.010290962643921375,
                0.028141817077994347,
                -0.012058676220476627,
                -0.017269400879740715,
                0.006584507413208485,
                0.0201128963381052,
                0.03524085879325867,
                -0.08199939876794815,
                0.03383453190326691,
                0.005895373411476612,
                0.03953764587640762,
                0.06426969170570374,
                -0.006890962366014719,
                -0.007656531874090433,
                -0.0017410829896107316,
                0.054037030786275864,
                -0.01242008339613676,
                -0.04507014900445938,
                -0.0856294184923172,
                0.03425218537449837,
                0.029450884088873863,
                0.052980296313762665,
                0.011234903708100319,
                -0.0538501963019371,
                -0.021445168182253838,
                -0.03238509222865105,
                2.5882962972900714e-07,
                -0.019793448969721794,
                -0.014731946401298046,
                -0.04177498817443848,
                0.08282742649316788,
                0.03521471843123436,
                0.04253128916025162,
                0.008899428881704807,
                0.060012683272361755,
                0.0023366007953882217,
                -0.029991386458277702,
                0.02064051665365696,
                -0.01237747073173523,
                -0.016422156244516373,
                -0.033493783324956894,
                0.0520910918712616,
                -0.04500649869441986,
                0.01280555035918951,
                -0.02861432172358036,
                -0.013841282576322556,
                -0.011956808157265186,
                0.0676371157169342,
                -0.014161170460283756,
                0.008786682970821857,
                0.020178353413939476,
                0.03367675468325615,
                -0.028369160369038582,
                0.042029403150081635,
                -0.04602742940187454,
                0.030564947053790092,
                -0.023839911445975304,
                0.04213995859026909,
                0.028633136302232742,
                -0.06267794966697693,
                -0.04001591354608536,
                0.025524789467453957,
                -0.0445961058139801,
                -0.0005622103926725686,
                0.047898951917886734,
                0.01152693573385477,
                -0.010663830675184727,
                -0.039398595690727234,
                -0.007241064682602882,
                0.0023886896669864655,
                0.00683436868712306,
                0.010938495397567749,
                0.011695710010826588,
                -0.02728194184601307,
                0.027492135763168335,
                -0.09253432601690292,
                -0.0020555472001433372,
                -0.02327827922999859,
                -0.002312462078407407,
                -0.02097022905945778,
                0.053186580538749695,
                -0.020625120028853416,
                -0.02124439738690853,
                0.05473091080784798,
                0.032034385949373245,
                0.014125458896160126,
                0.009897246025502682,
                -0.03344309329986572,
                0.00944351963698864,
                0.025708191096782684,
                0.038930494338274,
                0.010485099628567696,
                0.011033698916435242,
                -0.054942842572927475,
                1.0744122829392588e-34,
                0.02852620929479599,
                0.013759407214820385,
                0.0026330214459449053,
                0.011529272422194481,
                -0.006726908031851053,
                -0.019673025235533714,
                0.023524418473243713,
                -0.026310840621590614,
                -0.01283300668001175,
                -0.02997680753469467,
                -0.04165782406926155
            ]
        },
        "Location": "Inverurie",
        "Remuneration": 28.846153846153847,
        "Services Required": {
            "name": " Product Owner",
            "rep": [
                0.021096333861351013,
                0.04088746756315231,
                0.005870024673640728,
                0.03455040603876114,
                -0.05920558422803879,
                -0.04265744239091873,
                0.0908103808760643,
                -0.03919775411486626,
                -0.02681591734290123,
                -0.004410688299685717,
                0.0027703214436769485,
                0.03921978175640106,
                -0.0419296994805336,
                0.07636000216007233,
                -0.008999415673315525,
                0.04510387033224106,
                -0.04875115305185318,
                0.04141784831881523,
                0.004572290927171707,
                0.00651143491268158,
                0.009321886114776134,
                0.003890030551701784,
                0.00323194800876081,
                0.031580850481987,
                -0.05043458566069603,
                -0.025110913440585136,
                0.06052159145474434,
                0.026912624016404152,
                0.012780270539224148,
                0.026172351092100143,
                0.0431208498775959,
                0.05170035362243652,
                -0.007009844295680523,
                0.02876010350883007,
                1.3603926163341384e-06,
                -0.00417401734739542,
                0.013293116353452206,
                -0.011327460408210754,
                0.01497192494571209,
                0.01992848329246044,
                0.03811277076601982,
                0.0670928955078125,
                -0.031114613637328148,
                0.014588574878871441,
                -0.001776928431354463,
                0.01598668284714222,
                0.01100646611303091,
                0.06999903917312622,
                -0.026249807327985764,
                0.013355551287531853,
                -0.027607083320617676,
                0.01834159903228283,
                0.035139020532369614,
                0.008936394937336445,
                0.0400223582983017,
                -0.0332387313246727,
                -0.004912532400339842,
                0.022875837981700897,
                0.025205768644809723,
                0.009185315109789371,
                -4.476049798540771e-05,
                0.05055433511734009,
                -0.005918765906244516,
                0.03584243729710579,
                0.08364920318126678,
                0.014192639850080013,
                0.08099399507045746,
                -0.015066983178257942,
                -0.030829323455691338,
                0.013636767864227295,
                0.06973198056221008,
                0.07731807231903076,
                -0.012844288721680641,
                0.039719533175230026,
                0.06441113352775574,
                -0.012468256056308746,
                0.05768969655036926,
                0.03197108581662178,
                0.0027737384662032127,
                -0.0024114635307341814,
                -0.030212843790650368,
                0.03370146080851555,
                -0.045167580246925354,
                0.006218321621417999,
                -0.03699184209108353,
                0.07219145447015762,
                0.006358052138239145,
                -0.026464059948921204,
                -0.055185675621032715,
                -0.01644131727516651,
                -0.0009766884613782167,
                -0.008389012888073921,
                -0.013721710070967674,
                -0.014657060615718365,
                0.01852615922689438,
                -0.008107125759124756,
                -0.00384814222343266,
                0.012976200319826603,
                -0.053440604358911514,
                -0.11288505792617798,
                -0.05861517786979675,
                0.01210572849959135,
                -0.041719429194927216,
                0.031266797333955765,
                -0.0131254643201828,
                0.06322646886110306,
                0.007456750143319368,
                0.04683781787753105,
                0.02640404738485813,
                0.05568581074476242,
                0.03390148654580116,
                -0.0020762961357831955,
                0.007550788577646017,
                -0.02290402539074421,
                0.006787905469536781,
                -0.05447237193584442,
                -0.02464699000120163,
                0.013675511814653873,
                0.006314036902040243,
                0.04511909931898117,
                -0.023180527612566948,
                0.03559646010398865,
                0.02006405219435692,
                0.009642131626605988,
                -0.025628747418522835,
                -0.016185583546757698,
                -0.0530310794711113,
                0.002281548921018839,
                0.0008364436798729002,
                0.01685188338160515,
                0.04046932980418205,
                -0.0037120587658137083,
                -0.002244912087917328,
                -0.029153838753700256,
                0.007998473010957241,
                -0.055203042924404144,
                0.0054791634902358055,
                -0.051191989332437515,
                -0.0640157163143158,
                0.0013270737836137414,
                -0.005987288895994425,
                0.01390200387686491,
                -0.09568950533866882,
                -0.0005902363918721676,
                0.026116160675883293,
                -0.028460970148444176,
                -0.020585672929883003,
                -0.05565454438328743,
                0.008321658708155155,
                0.004226400516927242,
                0.015003140084445477,
                -0.03261680528521538,
                -0.03362748771905899,
                0.026549717411398888,
                0.018763117492198944,
                -0.005526341497898102,
                -0.01843440718948841,
                -0.030510980635881424,
                0.006066836882382631,
                -0.011297988705337048,
                -0.013302458450198174,
                0.00024153021513484418,
                -0.037087906152009964,
                -0.025476260110735893,
                -0.08513697236776352,
                -0.04094545170664787,
                0.04884733632206917,
                -0.0009667829144746065,
                -0.011709378100931644,
                -0.0301870908588171,
                -0.026247229427099228,
                -0.01642562262713909,
                -0.00076995202107355,
                0.07836297154426575,
                0.058418288826942444,
                -0.013569478876888752,
                -0.014704317785799503,
                0.009884017519652843,
                -0.02392464131116867,
                0.01917615346610546,
                -0.036304134875535965,
                -0.10589554905891418,
                0.06397915631532669,
                -0.009185333736240864,
                0.0029719688463956118,
                -0.0013656115625053644,
                -0.03220469504594803,
                -0.0117452722042799,
                0.01996600069105625,
                0.02467857114970684,
                0.027195826172828674,
                -0.003075446467846632,
                -0.03663377836346626,
                0.044435858726501465,
                0.011688096448779106,
                0.020701326429843903,
                0.03474118560552597,
                0.009778836742043495,
                -0.029370944947004318,
                -0.04576632007956505,
                -0.01865430921316147,
                0.020149733871221542,
                0.0663967952132225,
                0.0865657702088356,
                -0.07131226360797882,
                -0.009192078374326229,
                -0.004095104057341814,
                0.022392570972442627,
                -0.08030612766742706,
                0.01763959415256977,
                0.015945831313729286,
                0.021287500858306885,
                -0.03228049725294113,
                0.006651022005826235,
                -0.017138700932264328,
                -0.04114220663905144,
                0.004321902524679899,
                0.052043382078409195,
                -0.10116562992334366,
                0.035940542817115784,
                0.026103224605321884,
                0.012524685822427273,
                0.08219035714864731,
                -0.03375716134905815,
                -0.047344908118247986,
                -0.06431468576192856,
                -0.0006499690352939069,
                0.01786702312529087,
                -0.004964421968907118,
                0.01753724366426468,
                0.02244093455374241,
                0.028632009401917458,
                0.01606733910739422,
                -0.04197884723544121,
                -0.018212245777249336,
                0.04558316618204117,
                0.06657867133617401,
                -0.02340783178806305,
                -0.004799758084118366,
                -0.01702057756483555,
                -0.01869962364435196,
                -0.03945021703839302,
                0.042999278753995895,
                -0.0027488269843161106,
                0.06253540515899658,
                0.018711727112531662,
                -0.01491017546504736,
                0.0047296867705881596,
                -0.02791482023894787,
                -0.03261628374457359,
                -0.02645200490951538,
                -0.017949001863598824,
                0.03582236170768738,
                0.03357330709695816,
                0.02631218358874321,
                0.0055143279023468494,
                -0.012131514959037304,
                0.01691736839711666,
                0.0023790074046701193,
                -0.00375730125233531,
                -0.008470073342323303,
                -0.03562859445810318,
                -0.04485895484685898,
                -0.012206867337226868,
                -0.0076899342238903046,
                0.05554189905524254,
                0.030246609821915627,
                -0.024169206619262695,
                0.028032472357153893,
                -0.007161635905504227,
                -0.016734974458813667,
                -0.055638909339904785,
                0.014345024712383747,
                -0.020024258643388748,
                0.055404987186193466,
                -0.032260678708553314,
                -0.030908985063433647,
                -0.0019318988779559731,
                -0.03617886081337929,
                -0.009882768616080284,
                0.01630641333758831,
                -0.029475832358002663,
                0.0010327064665034413,
                0.009323850274085999,
                -0.005586623214185238,
                -0.033640749752521515,
                0.018660355359315872,
                0.03352189436554909,
                -0.06335847079753876,
                -0.011863015592098236,
                -0.03894075006246567,
                -0.0008993990486487746,
                0.03542829677462578,
                0.07397045940160751,
                0.004772847983986139,
                -0.009609772823750973,
                -0.02099505253136158,
                -0.036668695509433746,
                -0.021783456206321716,
                0.03982270881533623,
                -0.001353260362520814,
                -0.04466581344604492,
                -0.018298374488949776,
                -0.015300493687391281,
                0.01789160817861557,
                -0.054542623460292816,
                0.01378683652728796,
                0.07001500576734543,
                -0.004782547242939472,
                -0.009208661504089832,
                0.0092043187469244,
                0.017135875299572945,
                -0.03222260996699333,
                -0.024551330134272575,
                0.0261567123234272,
                -0.03501265496015549,
                0.10765953361988068,
                0.014050101861357689,
                0.0035481874365359545,
                0.06805909425020218,
                -0.014484872110188007,
                0.0027735228650271893,
                0.017883216962218285,
                -0.007564565632492304,
                0.02009337954223156,
                0.02368954010307789,
                0.006048965733498335,
                -0.019748665392398834,
                0.038120876997709274,
                -0.0006341328262351453,
                0.001287965103983879,
                -0.07239522784948349,
                -0.011145250871777534,
                0.03647323325276375,
                -0.008604894392192364,
                0.04156121239066124,
                -0.010333656333386898,
                -0.03724168241024017,
                -0.054491620510816574,
                0.029756614938378334,
                -0.010482102632522583,
                -0.012477617710828781,
                0.025612052530050278,
                -0.014162947423756123,
                0.01658056303858757,
                -0.027220573276281357,
                0.023917267099022865,
                0.008014115504920483,
                0.025711260735988617,
                0.010336133651435375,
                0.03971929848194122,
                0.007483342662453651,
                0.02463613823056221,
                -0.012114835903048515,
                -0.0003957436128985137,
                -0.0051270052790641785,
                -0.03732931986451149,
                -0.053707417100667953,
                -0.02667962945997715,
                0.020156266167759895,
                -0.05055508390069008,
                -0.02233695611357689,
                0.04865763336420059,
                -0.009617882780730724,
                -0.016654212027788162,
                0.010668948292732239,
                -0.02665702812373638,
                0.015746669843792915,
                0.03906606510281563,
                -0.007642506621778011,
                -0.01093982718884945,
                0.01291546132415533,
                0.04686129465699196,
                0.03971194848418236,
                0.014983978122472763,
                0.0349004827439785,
                0.02907266840338707,
                0.0733637884259224,
                -0.07262397557497025,
                -0.0008699485333636403,
                0.01372216921299696,
                -0.006923660635948181,
                0.033183496445417404,
                -0.026012003421783447,
                -0.01240403950214386,
                -0.03902801498770714,
                -0.013404707424342632,
                -0.011539146304130554,
                0.020661117509007454,
                -0.04962872713804245,
                -0.00390626210719347,
                -0.024811089038848877,
                -0.04629617929458618,
                0.01017300970852375,
                -0.0012096187565475702,
                -0.08492390811443329,
                0.02207084558904171,
                -0.04622838273644447,
                -0.014555863104760647,
                0.052270907908678055,
                0.039475832134485245,
                0.050034500658512115,
                0.016610823571681976,
                -0.0207190103828907,
                -0.058805450797080994,
                -0.10725673288106918,
                0.13735011219978333,
                -0.0263617355376482,
                -0.013766755349934101,
                0.023446207866072655,
                -0.07235768437385559,
                -0.03242287039756775,
                -0.01663818769156933,
                0.04261355102062225,
                -0.037869133055210114,
                -0.08340141922235489,
                -0.030324604362249374,
                0.03171621635556221,
                0.044895853847265244,
                0.004130211193114519,
                0.03198898211121559,
                0.008374156430363655,
                0.006690123584121466,
                0.06164749339222908,
                -0.019517607986927032,
                0.027244044467806816,
                -0.021406162530183792,
                0.0012210507411509752,
                0.005429135635495186,
                0.03784669563174248,
                -0.03990492224693298,
                -0.020489053800702095,
                -0.032075416296720505,
                -0.00065854616696015,
                0.0109733697026968,
                0.015550958923995495,
                -0.039693452417850494,
                0.02370181307196617,
                0.01455541793256998,
                -0.05279112234711647,
                -0.008172345347702503,
                0.06683557480573654,
                -0.019989334046840668,
                -0.025142475962638855,
                -0.0563865527510643,
                0.040076691657304764,
                -0.01665775291621685,
                0.007992543280124664,
                -0.06775294244289398,
                0.0520922914147377,
                0.009039013646543026,
                0.026870477944612503,
                0.02695978432893753,
                0.043110836297273636,
                0.0434529185295105,
                -0.010442356579005718,
                -0.04296691715717316,
                0.05393141508102417,
                -0.023909833282232285,
                -0.013968982733786106,
                0.004911197815090418,
                0.013285219669342041,
                -0.030224621295928955,
                0.012324369512498379,
                -0.027895893901586533,
                -0.016909614205360413,
                0.05138010531663895,
                -0.04441632330417633,
                0.026928335428237915,
                -0.02591090090572834,
                0.04056495800614357,
                -0.018124554306268692,
                0.01378620509058237,
                0.00507699279114604,
                -0.001973014324903488,
                0.05011874809861183,
                0.028370970860123634,
                0.09105943143367767,
                0.017489081248641014,
                0.06379551440477371,
                -0.006557805929332972,
                0.013430614024400711,
                0.006973843090236187,
                0.08670025318861008,
                -0.002509712241590023,
                0.003620378440245986,
                0.018464470282197,
                0.006335441488772631,
                -0.04792965203523636,
                0.03725062310695648,
                -0.006006696727126837,
                -0.06784933060407639,
                -0.016813572496175766,
                -0.007741513196378946,
                -0.013342923484742641,
                0.0318208709359169,
                -0.004962680395692587,
                -0.03978801891207695,
                -0.030914220958948135,
                -0.0983872339129448,
                -0.012883130460977554,
                0.015990084037184715,
                -0.015410187654197216,
                0.027311423793435097,
                0.021177144721150398,
                -0.0007876195013523102,
                0.012122099287807941,
                -0.00883010309189558,
                0.02397768199443817,
                -0.02118043415248394,
                -0.003496495308354497,
                5.136967229191214e-05,
                0.032018471509218216,
                0.002922902349382639,
                0.055013496428728104,
                -0.01571647636592388,
                -0.022324364632368088,
                -0.016809547320008278,
                -0.006221563555300236,
                -0.0649104118347168,
                0.017977755516767502,
                -0.031708866357803345,
                0.044515836983919144,
                -0.06663034856319427,
                -0.040421973913908005,
                -0.05132457613945007,
                0.0021418859250843525,
                -0.029395392164587975,
                -0.025801844894886017,
                0.04192023724317551,
                0.05878636613488197,
                0.013528257608413696,
                -0.10580914467573166,
                0.03646336495876312,
                -0.046630099415779114,
                -0.009108280763030052,
                -0.008798967115581036,
                -0.07005877047777176,
                -0.009253661148250103,
                0.04075906425714493,
                -0.06643176078796387,
                0.014323203824460506,
                -0.041578564792871475,
                -0.0032355384901165962,
                0.0703892931342125,
                0.02224554307758808,
                -0.024400247260928154,
                0.024377016350626945,
                -0.03981448709964752,
                -0.016903899610042572,
                0.011596456170082092,
                -0.044433899223804474,
                -0.04704047739505768,
                -0.012004275806248188,
                -0.009383665397763252,
                -5.4033654950660384e-33,
                0.013098221272230148,
                -0.03918587788939476,
                -0.03619232028722763,
                -0.025010593235492706,
                0.01884348876774311,
                -0.03827998787164688,
                0.01324817631393671,
                0.0391659140586853,
                0.008616375736892223,
                0.01776980049908161,
                0.002893415279686451,
                -0.013814111240208149,
                0.013972993940114975,
                0.0697963759303093,
                0.029364854097366333,
                -0.011447560042142868,
                0.028795013204216957,
                0.018498292192816734,
                0.03303951397538185,
                0.017789898440241814,
                -0.03328888118267059,
                0.010493195615708828,
                0.002237140666693449,
                0.06996038556098938,
                0.002214660868048668,
                -0.0050616455264389515,
                0.02193099819123745,
                0.02604355476796627,
                -0.025236625224351883,
                -0.013185533694922924,
                0.0009386367164552212,
                -0.019007645547389984,
                0.00990279484540224,
                0.037968747317790985,
                -0.007530985400080681,
                0.04553603753447533,
                -0.027981730177998543,
                0.006305421236902475,
                -0.04211676865816116,
                -0.008434321731328964,
                -0.06278759241104126,
                0.013421154581010342,
                0.0035027528647333384,
                0.005740251857787371,
                -0.01226651482284069,
                0.013895155861973763,
                0.023897375911474228,
                0.014895271509885788,
                -0.025867920368909836,
                -0.044544003903865814,
                -0.01647956669330597,
                -0.009828513488173485,
                -0.026159781962633133,
                0.016613060608506203,
                0.05411818251013756,
                -0.007915964350104332,
                -0.029691049829125404,
                -0.10709574073553085,
                0.045796919614076614,
                -0.017309458926320076,
                -0.05328117311000824,
                -0.025316745042800903,
                -0.01966318115592003,
                0.09478124231100082,
                0.020300529897212982,
                -0.0018393390346318483,
                -0.007131722290068865,
                0.0769621804356575,
                -0.017568839713931084,
                0.003370822174474597,
                -0.03617742285132408,
                0.08781984448432922,
                0.005229304078966379,
                -0.02348560281097889,
                -0.03719603642821312,
                0.053692713379859924,
                0.05654868483543396,
                0.012496867217123508,
                0.05890520289540291,
                -0.060376428067684174,
                -0.03248395770788193,
                -0.018661770969629288,
                -0.004781436175107956,
                -0.030900338664650917,
                -0.011372452601790428,
                -0.05420007184147835,
                -0.01919509470462799,
                -0.004974076524376869,
                -0.005817053839564323,
                -0.017813218757510185,
                -0.04615209624171257,
                0.01562205608934164,
                0.028260910883545876,
                -0.03902168199419975,
                -0.06697245687246323,
                -0.10700921714305878,
                0.02356380596756935,
                0.007544920314103365,
                0.01838069222867489,
                -0.03655757009983063,
                -0.023577798157930374,
                0.018830502405762672,
                0.007906647399067879,
                0.03861526399850845,
                0.01558167114853859,
                -0.018937716260552406,
                0.0014450614107772708,
                -0.03943422809243202,
                0.0033172518014907837,
                -0.009215829893946648,
                -0.0015240181237459183,
                0.015047332271933556,
                0.019568640738725662,
                -0.006173559930175543,
                0.001058825640939176,
                -0.013841645792126656,
                -0.0026180963031947613,
                0.0315692164003849,
                0.017172811552882195,
                -0.020946651697158813,
                0.0013539252104237676,
                0.019952664151787758,
                -0.06273374706506729,
                -0.0562557652592659,
                -0.05219164118170738,
                0.01799667812883854,
                0.015436368994414806,
                0.046169474720954895,
                0.051614828407764435,
                -0.01810900680720806,
                -0.02217605896294117,
                -0.07557416707277298,
                2.044476161700004e-07,
                0.025063317269086838,
                -0.007457813248038292,
                0.04154571518301964,
                0.007782392203807831,
                0.007463257294148207,
                0.03187911957502365,
                0.06517394632101059,
                0.07654965668916702,
                -0.10200487822294235,
                -0.06804769486188889,
                0.06198853999376297,
                -0.012635884806513786,
                0.013024025596678257,
                -0.05078430846333504,
                0.04265962913632393,
                -0.0502738393843174,
                0.023749733343720436,
                0.05170061066746712,
                -0.01207813248038292,
                -0.019327087327837944,
                0.08786320686340332,
                -0.0751793384552002,
                0.02042989619076252,
                0.01887384243309498,
                0.032353002578020096,
                -0.012117810547351837,
                0.018748095259070396,
                -0.001678797765634954,
                0.07163055986166,
                -0.0077631971798837185,
                0.054787758737802505,
                -0.02814297005534172,
                -0.055440086871385574,
                -0.05830925330519676,
                0.014403103850781918,
                -0.004999707918614149,
                -0.0025964502710849047,
                0.005704962648451328,
                -0.0022804669570177794,
                0.0017961579142138362,
                0.023432625457644463,
                -0.06804636120796204,
                -0.013896726071834564,
                0.06299576908349991,
                -0.019173307344317436,
                0.004928391892462969,
                -0.0453440397977829,
                0.006027163937687874,
                -0.012234996072947979,
                -0.03360244259238243,
                -0.03477668762207031,
                0.013295755721628666,
                0.06673360615968704,
                0.03857020288705826,
                -0.001118053332902491,
                -0.06091852858662605,
                -0.006935172248631716,
                0.015915121883153915,
                0.044782280921936035,
                -0.0049092816188931465,
                -0.04271657019853592,
                0.037075549364089966,
                -0.01343408040702343,
                0.018661152571439743,
                0.028990749269723892,
                0.007405832875519991,
                0.043422356247901917,
                1.115661688365433e-34,
                -0.03159862011671066,
                0.0189430620521307,
                -0.03866271302103996,
                -0.014911986887454987,
                0.02189723215997219,
                0.01825554482638836,
                0.01761130802333355,
                0.007636391557753086,
                -0.02844083681702614,
                0.010417481884360313,
                -0.010337290354073048
            ]
        },
        "Skills": {
            "name": [
                "Digital Product Management",
                "Integrating Digital Solutions",
                "Mentoring/ Development",
                "Concepts Design",
                "Project Management",
                "Problem Solving",
                "Project planning",
                "SharePoint, SAP, MAximo, JIRA",
                "Quality Testing"
            ],
            "rep": [
                0.024861188605427742,
                -0.008219686336815357,
                -0.03466295823454857,
                -0.071028932929039,
                -0.005105708725750446,
                0.003110841615125537,
                0.10324706882238388,
                -0.005400205031037331,
                -0.061234984546899796,
                -0.006717446725815535,
                -0.012674685567617416,
                0.042752139270305634,
                -0.02888951264321804,
                0.10327459871768951,
                -0.01063341274857521,
                0.010926038026809692,
                0.023749522864818573,
                0.03988640010356903,
                -0.04981198534369469,
                0.029275598004460335,
                0.028040971606969833,
                -0.015031578950583935,
                -0.0007131965248845518,
                -0.04317158833146095,
                -0.016332564875483513,
                -0.0531645342707634,
                -0.055908288806676865,
                0.011247223243117332,
                -0.009372396394610405,
                -0.008130147121846676,
                0.0011644127080217004,
                0.014159677550196648,
                0.029411930590867996,
                0.014007586985826492,
                1.1414412028898369e-06,
                -0.013689043931663036,
                -0.04274380952119827,
                -0.014261365868151188,
                -0.03782976046204567,
                0.060692112892866135,
                0.05749024078249931,
                -0.0003004663158208132,
                -0.01636405847966671,
                -0.00538330664858222,
                0.038870710879564285,
                -0.016934508457779884,
                0.007192598190158606,
                0.052254483103752136,
                -0.018328530713915825,
                -0.03272382915019989,
                -0.007520969957113266,
                0.027047675102949142,
                -0.0017928298329934478,
                0.00956047885119915,
                -0.08246922492980957,
                0.0457012839615345,
                0.03426937386393547,
                0.07035528868436813,
                0.08330298960208893,
                -0.009228777140378952,
                0.028226349502801895,
                0.02542676031589508,
                -0.042653974145650864,
                0.058251868933439255,
                0.04435170814394951,
                0.01646682247519493,
                0.09072483330965042,
                -0.04262705519795418,
                -0.047950856387615204,
                -0.0016579655930399895,
                0.04406347870826721,
                0.02370527572929859,
                0.021127620711922646,
                -0.04949570074677467,
                0.02268199622631073,
                -0.030757082626223564,
                -0.005090279038995504,
                -0.01908065378665924,
                0.007993161678314209,
                0.010205386206507683,
                -0.05030592530965805,
                0.004431126639246941,
                -0.01847248338162899,
                0.01072081457823515,
                -0.006304611451923847,
                0.020401405170559883,
                0.008850162848830223,
                -0.04285687208175659,
                -0.05539466068148613,
                -0.0154155557975173,
                -0.012475374154746532,
                -0.02856578305363655,
                0.011456497944891453,
                0.03344380110502243,
                0.01071064081043005,
                -0.032033249735832214,
                -0.009328150190412998,
                -0.023075083270668983,
                0.01574809104204178,
                -0.03592485189437866,
                5.451025572256185e-06,
                -0.053652673959732056,
                0.016827203333377838,
                0.04941089078783989,
                0.057756878435611725,
                0.03776530548930168,
                -0.05414533615112305,
                0.010473075322806835,
                -0.027820274233818054,
                0.06502477824687958,
                -0.023355592042207718,
                -0.04406874254345894,
                -0.022070150822401047,
                0.0603080578148365,
                0.009745567105710506,
                -0.08733464777469635,
                0.014490056782960892,
                -0.01684504561126232,
                -0.012417138554155827,
                0.027388930320739746,
                0.048813771456480026,
                0.008938789367675781,
                -0.024173224344849586,
                -0.041614729911088943,
                -0.050089962780475616,
                -0.007794471923261881,
                -0.06936440616846085,
                0.004521425813436508,
                -0.02967885136604309,
                0.05840160697698593,
                0.06563498824834824,
                0.022207165136933327,
                0.05805744603276253,
                0.01684073731303215,
                0.0025244830176234245,
                -0.022477449849247932,
                -0.008065026253461838,
                0.019543128088116646,
                -0.07574636489152908,
                -0.05257699638605118,
                0.0319722518324852,
                0.015295288525521755,
                -0.027244146913290024,
                0.012580398470163345,
                0.015897903591394424,
                -0.03351717069745064,
                -0.018028324469923973,
                0.03709960728883743,
                -0.014350770972669125,
                0.00657091848552227,
                0.03892718628048897,
                -0.013302300125360489,
                -0.01183468010276556,
                0.041454024612903595,
                0.026992836967110634,
                0.015407837927341461,
                0.017805511131882668,
                -0.04710204899311066,
                -0.04001608118414879,
                -0.010704242624342442,
                -0.0010925011010840535,
                0.043592024594545364,
                0.0019205593271180987,
                0.04904962703585625,
                -0.07400324195623398,
                -0.04649613797664642,
                -0.0019026227528229356,
                0.035427335649728775,
                -0.04529571533203125,
                -0.028613731265068054,
                -0.0035975140053778887,
                -0.03435909003019333,
                -0.0026987059973180294,
                0.04373333230614662,
                0.038305699825286865,
                -0.06668836623430252,
                0.08575014770030975,
                -0.030818430706858635,
                -0.027313409373164177,
                0.04773465916514397,
                -0.04108869284391403,
                -0.034783609211444855,
                0.06898856908082962,
                0.022300660610198975,
                0.08055950701236725,
                0.023877380415797234,
                -0.07752881944179535,
                -0.010040794499218464,
                -0.026645809412002563,
                -0.004023033194243908,
                -0.016053324565291405,
                -0.01990932784974575,
                0.0355399064719677,
                0.030122242867946625,
                -0.014507518149912357,
                0.012403115630149841,
                0.013716253452003002,
                -0.018843991681933403,
                -0.051521364599466324,
                0.0134601891040802,
                -0.03292498737573624,
                -0.012479585595428944,
                0.08128458261489868,
                0.032630231231451035,
                -0.008147787302732468,
                -0.04719392582774162,
                -0.04249539598822594,
                0.032665058970451355,
                -0.023560838773846626,
                0.04421672224998474,
                0.02909972332417965,
                -0.03655533865094185,
                -0.05151619017124176,
                -0.006722578778862953,
                -0.016984134912490845,
                -0.048903025686740875,
                0.024047603830695152,
                0.036742549389600754,
                -0.05135541409254074,
                0.023734288290143013,
                -0.02226995676755905,
                0.03847178816795349,
                0.0648365467786789,
                -0.011912284418940544,
                0.031141042709350586,
                -0.028279297053813934,
                -0.0274351816624403,
                0.02640524134039879,
                -0.020938105881214142,
                -0.006976858247071505,
                0.0201429296284914,
                -0.009225364774465561,
                0.05076949670910835,
                -0.021718308329582214,
                -0.05210399627685547,
                0.0030861208215355873,
                0.11327017098665237,
                0.09122525155544281,
                0.08194632083177567,
                0.01065381895750761,
                -0.02147161029279232,
                -0.08814778178930283,
                -0.001250504283234477,
                -0.036365263164043427,
                0.03340626507997513,
                0.01623539999127388,
                0.01319380383938551,
                0.056238722056150436,
                0.00963246263563633,
                0.005228954833000898,
                -0.01788829080760479,
                0.0017218280117958784,
                0.05309648811817169,
                0.054459184408187866,
                -0.021773243322968483,
                0.010529261082410812,
                -0.0205715149641037,
                0.011432596482336521,
                0.036699384450912476,
                0.035284072160720825,
                0.010958974249660969,
                -0.02014688216149807,
                0.027054226025938988,
                -0.029779527336359024,
                -0.030209248885512352,
                0.022835098206996918,
                0.028535950928926468,
                0.07836207747459412,
                -0.009019204415380955,
                0.03004498779773712,
                0.020116370171308517,
                -0.024374455213546753,
                -0.034106675535440445,
                0.017001427710056305,
                0.007767729926854372,
                -0.04562239721417427,
                0.03983478248119354,
                0.023750903084874153,
                -0.06414152681827545,
                0.014426571317017078,
                0.04635360464453697,
                -0.03471071645617485,
                0.022950543090701103,
                0.030506150797009468,
                0.013172134757041931,
                -0.037485744804143906,
                0.0012860490242019296,
                -0.019094310700893402,
                -0.10599878430366516,
                0.06830372661352158,
                -0.0926985815167427,
                -0.013486964628100395,
                0.02002185396850109,
                0.062327656894922256,
                -0.024540798738598824,
                -0.05398865416646004,
                -0.021210046485066414,
                -0.032755184918642044,
                -0.07142964005470276,
                -0.06194925308227539,
                0.01032149139791727,
                -0.06880281865596771,
                -0.01705348491668701,
                -0.025206441059708595,
                0.0394459068775177,
                0.04229309409856796,
                0.037411414086818695,
                0.05794859305024147,
                -0.0031507962848991156,
                -0.020015724003314972,
                -0.005989664699882269,
                0.0009070496307685971,
                -0.008828861638903618,
                -0.03737170621752739,
                0.013071917928755283,
                0.033214449882507324,
                0.03999873623251915,
                -0.007577005308121443,
                -0.02415446750819683,
                0.09591054916381836,
                -0.024363989010453224,
                -0.011668204329907894,
                -0.017076248303055763,
                -0.022013995796442032,
                0.03954380005598068,
                -0.007424088194966316,
                -0.05545257031917572,
                -0.009828684851527214,
                0.02123369090259075,
                -0.04142919182777405,
                0.014965225011110306,
                -0.011163171380758286,
                -0.00607380410656333,
                -0.038332633674144745,
                0.019624553620815277,
                0.05099411681294441,
                -0.011126617901027203,
                -0.038243234157562256,
                -0.020015638321638107,
                -0.0015499003930017352,
                0.00525314686819911,
                0.01744142547249794,
                0.05559452995657921,
                0.05762844532728195,
                0.023129751905798912,
                -0.04993096739053726,
                -0.02379595674574375,
                0.017754264175891876,
                -0.008908958174288273,
                0.04247511550784111,
                0.009170434437692165,
                0.03061366267502308,
                0.029456481337547302,
                0.002653656294569373,
                -0.01168888621032238,
                -0.013609726913273335,
                -0.054849088191986084,
                0.005228955298662186,
                -0.0465812124311924,
                0.004411999136209488,
                -0.035531822592020035,
                0.003066658042371273,
                -0.04087351635098457,
                -0.046851471066474915,
                -0.012832427397370338,
                -0.005253778304904699,
                0.028901707381010056,
                0.026350166648626328,
                0.050315339118242264,
                0.01757768541574478,
                -0.03572550788521767,
                -0.01535029336810112,
                0.048067789524793625,
                -0.007196286227554083,
                0.02652924694120884,
                -0.03463588282465935,
                -0.0053419205360114574,
                0.07068101316690445,
                -0.07713305950164795,
                0.009592312388122082,
                -0.01527246180921793,
                -0.04943275451660156,
                0.033679839223623276,
                -0.005400671623647213,
                0.015012923628091812,
                -0.013554566539824009,
                0.007832246832549572,
                0.004106319043785334,
                0.011996263638138771,
                -0.04751424863934517,
                -0.001288844272494316,
                0.0075712851248681545,
                -0.035279180854558945,
                0.038938865065574646,
                0.01850869506597519,
                0.0024007544852793217,
                0.06640496104955673,
                -0.028958860784769058,
                -0.015900498256087303,
                0.00870872475206852,
                0.01422497071325779,
                -0.029028259217739105,
                -0.010502043180167675,
                -0.03870569169521332,
                0.0016061108326539397,
                -0.06231831759214401,
                0.11512836813926697,
                0.019834864884614944,
                0.01132434606552124,
                -0.004783890675753355,
                -0.019975710660219193,
                0.020002130419015884,
                0.008289188146591187,
                -0.006344761699438095,
                -0.04520967975258827,
                0.030105626210570335,
                0.005368952173739672,
                0.04974399507045746,
                0.028589479625225067,
                0.032620158046483994,
                0.006390425842255354,
                -0.014465202577412128,
                0.010143491439521313,
                0.028901005163788795,
                -0.0033431260380893946,
                -0.026457061991095543,
                -0.044639550149440765,
                -0.025040172040462494,
                0.00802986603230238,
                0.002627725712954998,
                -0.01643073931336403,
                -0.020550936460494995,
                0.015619597397744656,
                0.03840802237391472,
                0.0615164190530777,
                0.039064809679985046,
                0.006494669709354639,
                -0.0287998728454113,
                -0.02322394773364067,
                -0.044526711106300354,
                -0.014965147711336613,
                -0.005765637382864952,
                0.020553534850478172,
                0.032883599400520325,
                -0.0802496001124382,
                0.013606601394712925,
                0.0018380515975877643,
                -0.06787068396806717,
                -0.10271953046321869,
                0.05309430509805679,
                0.003579274518415332,
                0.016275763511657715,
                0.038742274045944214,
                -0.006192212458699942,
                -0.004694974049925804,
                0.006026239600032568,
                -0.013199882581830025,
                -0.012352190911769867,
                -0.01556597650051117,
                0.004500685725361109,
                0.042582202702760696,
                0.019881615415215492,
                -0.06219915300607681,
                0.01264386996626854,
                -0.022569892928004265,
                -0.021887265145778656,
                0.030283093452453613,
                0.05002281814813614,
                -0.002183543983846903,
                -0.01152893714606762,
                0.0430036224424839,
                -0.024990566074848175,
                -0.014799664728343487,
                -0.036179330199956894,
                0.010199472308158875,
                0.07978174090385437,
                0.05735023319721222,
                0.03744914382696152,
                0.02868882566690445,
                0.007612991146743298,
                -0.019534090533852577,
                -0.06248264014720917,
                0.007904481142759323,
                0.05176732689142227,
                0.03323807194828987,
                0.013800224289298058,
                0.04160940647125244,
                -0.029765473678708076,
                0.007431719917804003,
                0.02950461208820343,
                -0.010921724140644073,
                -0.06429297477006912,
                0.014062228612601757,
                -0.009293845854699612,
                0.02338447794318199,
                -0.029352210462093353,
                -0.011873838491737843,
                -0.044364821165800095,
                -0.03408107906579971,
                0.0002489650796633214,
                -0.00394001230597496,
                0.015789687633514404,
                -0.02482270635664463,
                0.005476478952914476,
                -0.023566925898194313,
                0.0035000930074602365,
                -0.011592833325266838,
                0.016664627939462662,
                -0.008996020071208477,
                0.018839938566088676,
                -0.041673608124256134,
                -0.012057460844516754,
                0.05580665171146393,
                -0.04461866617202759,
                0.028889324516057968,
                0.018647847697138786,
                0.029523475095629692,
                0.0379774384200573,
                0.0022290274500846863,
                -0.03899328410625458,
                -0.018934795632958412,
                0.004791826941072941,
                0.03777271509170532,
                -0.05050618574023247,
                0.05179132893681526,
                -0.071198470890522,
                0.01999659277498722,
                -0.027220480144023895,
                -0.013669617474079132,
                0.006894044112414122,
                -0.07175405323505402,
                -0.04501432925462723,
                -0.09282393008470535,
                -0.02306322380900383,
                -0.0533735565841198,
                0.045813169330358505,
                -0.02607748471200466,
                0.02106235735118389,
                -0.005539166275411844,
                -0.018416086211800575,
                -0.0651230439543724,
                -0.0014776404714211822,
                -0.022402873262763023,
                0.005344923585653305,
                0.05203120410442352,
                -0.039117809385061264,
                -0.020673474296927452,
                0.004104937426745892,
                0.03119880147278309,
                0.028530141338706017,
                0.018089141696691513,
                -0.04566660150885582,
                -0.03229007124900818,
                0.006049607414752245,
                -0.050719015300273895,
                -4.9392909782789536e-33,
                0.013906669802963734,
                -0.028299076482653618,
                -0.06656619161367416,
                0.02459653839468956,
                0.009052969515323639,
                -0.01257261447608471,
                0.029626695439219475,
                -2.004190355364699e-05,
                0.053917109966278076,
                0.011261746287345886,
                0.020973065868020058,
                -0.009569579735398293,
                0.0167348962277174,
                0.0061399610713124275,
                0.03149621933698654,
                -0.0063431947492063046,
                0.04112331569194794,
                0.00489408802241087,
                -0.00026739638997241855,
                -0.0017244284972548485,
                -0.01944585144519806,
                0.04262453690171242,
                -0.010303296148777008,
                0.05803797394037247,
                0.007831847295165062,
                -0.004462858662009239,
                0.03314685449004173,
                -0.005484115332365036,
                0.01109434012323618,
                0.05586448311805725,
                0.0039738803170621395,
                -0.03499661386013031,
                -0.0058081443421542645,
                0.00033616391010582447,
                -0.010419173166155815,
                0.04699121043086052,
                -0.06663880497217178,
                0.04444267228245735,
                -0.0018911275546997786,
                0.015398900955915451,
                0.0740034431219101,
                -0.003611123189330101,
                0.040425293147563934,
                -0.033568304032087326,
                -0.008657978847622871,
                0.07427889108657837,
                0.03361005336046219,
                -0.001074915169738233,
                -0.07236555218696594,
                -0.06999918073415756,
                -0.014479968696832657,
                -0.027225656434893608,
                0.024094082415103912,
                0.06274621933698654,
                0.05277891457080841,
                -0.015973752364516258,
                -0.0017841062508523464,
                -0.038820721209049225,
                -0.06010974943637848,
                0.021710647270083427,
                -0.03250672668218613,
                -0.015858568251132965,
                0.008875545114278793,
                -0.03889358416199684,
                0.05396027863025665,
                0.02102753147482872,
                -0.04079543054103851,
                0.004399387165904045,
                0.04856128245592117,
                0.020599335432052612,
                -0.03199717402458191,
                0.08588070422410965,
                0.004459497984498739,
                -0.03010125458240509,
                0.010656221769750118,
                0.05361321568489075,
                0.07806649059057236,
                0.011196674779057503,
                -0.001633404870517552,
                -0.03002399019896984,
                -0.028364256024360657,
                0.02721959725022316,
                0.03780743107199669,
                -0.008616471663117409,
                0.08282604068517685,
                0.0027523189783096313,
                -0.02398981712758541,
                -0.029586289077997208,
                -0.0129556218162179,
                0.03696346655488014,
                -0.09381517767906189,
                0.014132232405245304,
                0.010960141196846962,
                -0.03549199551343918,
                -0.07308494299650192,
                -0.07547472417354584,
                0.026032570749521255,
                0.022759607061743736,
                0.003474797587841749,
                -0.05828666314482689,
                0.005891785491257906,
                -0.038514018058776855,
                -0.01332134660333395,
                0.01482686959207058,
                0.009843919426202774,
                -0.02589256688952446,
                -0.02356814034283161,
                0.006094660144299269,
                -0.01848074235022068,
                0.053376611322164536,
                0.02613978460431099,
                -0.017140038311481476,
                -0.048984695225954056,
                -0.05006350949406624,
                0.018674025312066078,
                -0.03201594948768616,
                0.025670194998383522,
                -0.046553511172533035,
                -0.020223697647452354,
                -0.03682307153940201,
                -0.008409970439970493,
                0.023488299921154976,
                -0.05580345168709755,
                -0.04916049912571907,
                0.0012788877356797457,
                0.03832859918475151,
                -0.024425020441412926,
                -0.0009856016840785742,
                0.03798366338014603,
                -0.025529304519295692,
                -0.028860531747341156,
                0.014647535979747772,
                1.7735362689563772e-07,
                0.013800126500427723,
                0.01461087167263031,
                0.036640364676713943,
                0.06682285666465759,
                0.05667727068066597,
                -0.007850714959204197,
                -0.008992241695523262,
                -0.012572389096021652,
                -0.0020876172930002213,
                -0.03516625240445137,
                0.056387148797512054,
                -0.019529102370142937,
                -0.012417664751410484,
                -0.010685727000236511,
                0.019981853663921356,
                -0.0831863284111023,
                0.04858248308300972,
                0.033872831612825394,
                -0.04657246544957161,
                -0.010987886227667332,
                0.010985634289681911,
                -0.008092805743217468,
                0.02515582926571369,
                0.014408920891582966,
                0.021095404401421547,
                0.02322213724255562,
                -0.020575840026140213,
                0.02699422277510166,
                0.10920397192239761,
                -0.03664546459913254,
                0.027821915224194527,
                -0.012763941660523415,
                -0.07219825685024261,
                -0.029236698523163795,
                0.020430730655789375,
                0.004553757142275572,
                -0.01869194395840168,
                0.040078263729810715,
                0.03426177799701691,
                0.01031073834747076,
                0.013929887674748898,
                -0.043659042567014694,
                -0.030809778720140457,
                0.05724809318780899,
                -0.015942836180329323,
                -0.01971682533621788,
                0.0127347931265831,
                0.007533047813922167,
                -0.026854025200009346,
                -0.04471524432301521,
                -0.011956154368817806,
                0.00878625363111496,
                0.042401064187288284,
                0.031233428046107292,
                -0.04203450307250023,
                -0.03140503540635109,
                0.011926563456654549,
                -0.010769101791083813,
                0.04438316076993942,
                0.060150474309921265,
                -0.009058204479515553,
                0.00019226642325520515,
                0.015034478157758713,
                0.005958419758826494,
                0.000759816961362958,
                0.00533435819670558,
                -0.0025589801371097565,
                8.328619117725173e-35,
                -0.015905072912573814,
                -0.0007497972110286355,
                -0.015457718633115292,
                0.00480140233412385,
                -0.003330247476696968,
                -0.003827077802270651,
                0.011419336311519146,
                -0.03307931870222092,
                0.01582053117454052,
                -0.03343162313103676,
                -0.03476345166563988,
                0.005421116948127747,
                0.057480379939079285,
                -0.017732447013258934,
                -0.03886590525507927,
                0.011803586967289448,
                0.003307001432403922,
                0.049820877611637115,
                -0.024937590584158897,
                -0.036760058254003525,
                -0.02611669898033142,
                0.04631698504090309,
                0.0635005384683609,
                0.004885999485850334,
                0.12358049303293228,
                0.007738423999398947,
                -0.015623858198523521,
                0.02491643652319908,
                0.03140247240662575,
                -0.0522763654589653,
                -0.004334807861596346,
                0.010290422476828098,
                -0.01810517907142639,
                0.005464726127684116,
                -0.02807198092341423,
                -0.04295545816421509,
                -0.029349396005272865,
                -0.04062628000974655,
                0.01729425974190235,
                0.012119567953050137,
                0.05011346563696861,
                -0.019129354506731033,
                -0.006556377746164799,
                0.010173988528549671,
                0.07190203666687012,
                1.6384608443331672e-06,
                0.006425222381949425,
                -0.0389091856777668,
                0.028354952111840248,
                -0.045079369097948074,
                0.018458621576428413,
                0.0083348723128438,
                -0.035508837550878525,
                -0.013065097853541374,
                -0.013170641846954823,
                -0.0007048387196846306,
                0.005540517158806324,
                0.017543859779834747,
                -0.019556742161512375,
                0.03914549574255943,
                -0.00670292554423213,
                0.01064032968133688,
                0.015343370847404003,
                0.028908200562000275,
                0.025121763348579407,
                -0.10792174190282822,
                0.055674679577350616,
                0.04831384867429733,
                0.09580574929714203,
                0.16343195736408234,
                0.0032175169326364994,
                0.006413130089640617,
                0.04245144873857498,
                0.015193271450698376,
                -0.028255388140678406,
                0.04819973558187485,
                0.01648648828268051,
                0.021722720935940742,
                -0.010919992811977863,
                -0.07186462730169296,
                -0.0002809908473864198,
                -0.01943880319595337,
                -0.00044827774399891496,
                0.00879481341689825,
                -0.0549587607383728,
                -0.04268571734428406,
                -0.0160015020519495,
                -0.023469889536499977,
                -0.08065662533044815,
                0.016849905252456665,
                -0.012034045532345772,
                -0.04735850170254707,
                -0.04699072614312172,
                -0.006111248396337032,
                0.022711660712957382,
                -0.0429873913526535,
                0.1133812740445137,
                0.03334375470876694,
                -0.007638794835656881,
                0.000680532946716994,
                -0.0030168043449521065,
                0.024381913244724274,
                -0.03822722285985947,
                0.01834334060549736,
                0.04283348098397255,
                -0.020496636629104614,
                -0.014287305064499378,
                -0.026745060458779335,
                -0.05166519433259964,
                -0.03024968132376671,
                -0.013586035929620266,
                0.04229767620563507,
                -0.007003019098192453,
                0.03003537841141224,
                0.022094449028372765,
                0.05205820873379707,
                -0.010060928761959076,
                -0.09440640360116959,
                -0.025447513908147812,
                -0.05566781759262085,
                0.06536459177732468,
                0.014626849442720413,
                -0.0273764505982399,
                -0.016012320294976234,
                0.08355315774679184,
                -0.040278855711221695,
                -0.08093941956758499,
                0.03090343251824379,
                0.012763174250721931,
                -0.005861126352101564,
                -0.0288326907902956,
                0.04200013354420662,
                0.03508290648460388,
                0.03241969272494316,
                -0.05435894802212715,
                -0.01827126368880272,
                0.06390982121229172,
                0.020676085725426674,
                -0.021020764485001564,
                -0.02782858908176422,
                0.009355453774333,
                0.04433178901672363,
                -0.017726674675941467,
                0.009755064733326435,
                -0.025006026029586792,
                0.001721423352137208,
                0.0043218014761805534,
                -0.017182588577270508,
                0.04010554403066635,
                -0.0024611535482108593,
                -0.036603204905986786,
                -0.010114346630871296,
                -0.04065726324915886,
                -0.07478544116020203,
                0.010808747261762619,
                -0.011498762294650078,
                -0.0013021985068917274,
                0.01103175338357687,
                0.039489567279815674,
                0.0027757291682064533,
                0.013199773617088795,
                0.0038921725936233997,
                0.04103751853108406,
                -0.026810305193066597,
                -0.010501629672944546,
                0.05010814219713211,
                0.00016963007510639727,
                0.06055568903684616,
                0.035419754683971405,
                -0.024149000644683838,
                -0.07385793328285217,
                -0.03720110282301903,
                0.002874289872124791,
                0.00024975347332656384,
                0.015025248751044273,
                0.017864732071757317,
                0.005507438909262419,
                0.011707176454365253,
                -0.01934891939163208,
                -0.012028497643768787,
                0.0004071465809829533,
                -0.021455902606248856,
                0.004506544675678015,
                0.029336674138903618,
                0.03157253935933113,
                0.023296693339943886,
                -0.05052458867430687,
                0.05760466307401657,
                -0.022033831104636192,
                0.004809886682778597,
                0.03853131830692291,
                -0.0048991902731359005,
                -0.030416570603847504,
                -0.022028416395187378,
                0.04384177178144455,
                0.015186524018645287,
                0.013528675772249699,
                0.0391433946788311,
                0.05450000986456871,
                -0.025415930896997452,
                -0.006650236435234547,
                -0.02177252806723118,
                -0.01431663241237402,
                0.03973452374339104,
                0.03961761295795441,
                -0.009797505103051662,
                -0.014651170931756496,
                0.006337116938084364,
                -0.041832275688648224,
                -0.040004946291446686,
                0.0031965654343366623,
                0.012937003746628761,
                -0.05359581857919693,
                0.0413651242852211,
                0.018519561737775803,
                0.03795478120446205,
                -0.038451530039310455,
                0.006510018836706877,
                -0.020453592762351036,
                0.015468994155526161,
                0.015473497100174427,
                0.05990798771381378,
                0.017206307500600815,
                -0.027622250840067863,
                0.03012457676231861,
                -0.016561947762966156,
                0.005697572138160467,
                0.029452063143253326,
                0.04485975205898285,
                -0.022061238065361977,
                0.01591399870812893,
                -0.055286724120378494,
                0.10968335717916489,
                0.031086139380931854,
                -0.03216100484132767,
                0.01185686606913805,
                -0.008385258726775646,
                0.01611870713531971,
                0.018426235765218735,
                -0.015479416586458683,
                0.05127592012286186,
                -0.011010680347681046,
                0.014087177813053131,
                0.007203533314168453,
                -0.008105806075036526,
                -0.06097410246729851,
                -0.027231698855757713,
                0.06240611523389816,
                0.14986412227153778,
                0.0869511291384697,
                -0.04194243624806404,
                0.015230484306812286,
                -0.032583583146333694,
                0.008051096461713314,
                -0.01983945444226265,
                0.051169801503419876,
                -0.021453581750392914,
                0.01490745972841978,
                0.046857137233018875,
                0.019808409735560417,
                0.007426079828292131,
                -0.0140446862205863,
                0.013178272172808647,
                0.03358782082796097,
                0.0146418996155262,
                0.0016085648676380515,
                0.017446031793951988,
                0.007648524362593889,
                0.0487208217382431,
                0.004569810815155506,
                0.0005209592054598033,
                -0.015292485244572163,
                -0.0006132156122475863,
                -0.001638309913687408,
                -0.002400064840912819,
                -0.0477544441819191,
                -0.001669713412411511,
                0.033064939081668854,
                0.04013408347964287,
                -0.03553594648838043,
                0.05048605054616928,
                0.007592370267957449,
                0.03418204188346863,
                -0.02424059994518757,
                0.020303431898355484,
                0.03406870737671852,
                0.0022674179635941982,
                -0.013316073454916477,
                0.032673515379428864,
                -0.05714193731546402,
                0.002770001068711281,
                0.04474077746272087,
                -0.011093776673078537,
                -0.017032204195857048,
                -0.0012362516717985272,
                -0.042439818382263184,
                0.0018325971905142069,
                -0.003133549587801099,
                -0.03493257984519005,
                -0.050953496247529984,
                -0.016790157184004784,
                -0.016068575903773308,
                0.008968639187514782,
                0.036062613129615784,
                -0.0036089958157390356,
                0.0029317389708012342,
                -0.03562059998512268,
                -0.06307822465896606,
                0.011874716728925705,
                -0.03543848171830177,
                -0.01337624341249466,
                0.008099626749753952,
                -0.0664689913392067,
                -0.026945291087031364,
                0.017203019931912422,
                0.04094682261347771,
                0.05296526476740837,
                0.07628025859594345,
                0.04216974973678589,
                -0.03831486776471138,
                -0.02038266323506832,
                0.026772957295179367,
                -0.023058215156197548,
                0.019511204212903976,
                0.013650025241076946,
                -0.011174888350069523,
                -0.019149942323565483,
                0.0339045487344265,
                -0.047350600361824036,
                0.014706745743751526,
                0.05307759717106819,
                0.009968348778784275,
                -0.015335021540522575,
                0.04283549264073372,
                -0.03902382776141167,
                0.008446017280220985,
                -0.00048117933329194784,
                -0.04561256989836693,
                -0.017577335238456726,
                -0.04801278933882713,
                -0.07863524556159973,
                0.01514949556440115,
                -0.01795726828277111,
                0.03163333237171173,
                -0.0215679369866848,
                -0.030647775158286095,
                0.03909813240170479,
                -0.04121765121817589,
                0.04379308223724365,
                -0.003676808439195156,
                -0.006059880368411541,
                -0.022910844534635544,
                -0.01896740309894085,
                0.02101288177073002,
                0.05760238319635391,
                -0.003108344040811062,
                0.014847900718450546,
                -0.02013833448290825,
                -0.0011577792465686798,
                -0.03536199405789375,
                0.023797979578375816,
                0.05861994996666908,
                -0.018392279744148254,
                -0.013231532648205757,
                -0.04653581976890564,
                -0.019844194874167442,
                -0.012765111401677132,
                -0.06003956496715546,
                0.03776192292571068,
                0.0057565681636333466,
                0.06437072902917862,
                -0.0029438987839967012,
                -0.029652461409568787,
                0.03576086461544037,
                0.01869197003543377,
                -0.06641591340303421,
                -0.014271397143602371,
                0.04213578999042511,
                0.02075435407459736,
                -0.01669851876795292,
                -0.0015086838975548744,
                -0.01648775115609169,
                0.014889298938214779,
                0.03168170899152756,
                -0.0296385046094656,
                -0.004998695570975542,
                0.0035360250622034073,
                -0.02447347715497017,
                0.02182893641293049,
                -0.07013130187988281,
                0.038578808307647705,
                0.022460421547293663,
                -0.05609249696135521,
                0.02737952023744583,
                -0.014764731749892235,
                -0.013616577722132206,
                -0.02894400991499424,
                -0.0021922816522419453,
                -0.040131598711013794,
                -0.00750392721965909,
                0.011624638922512531,
                0.04158857837319374,
                0.10717830806970596,
                -0.07078306376934052,
                0.06553808599710464,
                0.01613588072359562,
                0.026629945263266563,
                0.00713863130658865,
                0.0032663773745298386,
                -0.018681924790143967,
                0.012089965865015984,
                -0.006701793987303972,
                -0.03485755994915962,
                0.03055376186966896,
                -0.057285867631435394,
                0.04090665280818939,
                -0.058864519000053406,
                0.09314298629760742,
                0.022965384647250175,
                0.01316123828291893,
                -0.001385066076181829,
                -0.03975736349821091,
                -0.005002330057322979,
                0.02702316641807556,
                -0.029041586443781853,
                -0.08232129365205765,
                0.00605813367292285,
                -0.01066026370972395,
                0.013951231725513935,
                0.026346486061811447,
                -0.014273320324718952,
                0.010764258913695812,
                -0.013545711524784565,
                0.035784848034381866,
                -0.004745032638311386,
                0.04312915727496147,
                -0.04065863788127899,
                -0.03155384585261345,
                0.008828659541904926,
                -0.016887210309505463,
                -0.016141517087817192,
                0.019831640645861626,
                -0.03727531060576439,
                -0.008656937628984451,
                0.009028964675962925,
                0.07165738195180893,
                -0.018132532015442848,
                0.012373632751405239,
                0.01184146385639906,
                -0.04201328009366989,
                0.013266109861433506,
                -0.03312835842370987,
                -0.02721095271408558,
                0.06958651542663574,
                0.04676708206534386,
                -0.022733282297849655,
                0.016507549211382866,
                0.004508381709456444,
                -0.03870885446667671,
                -0.057842765003442764,
                -0.012922941707074642,
                0.041448742151260376,
                0.009909756481647491,
                0.0581047460436821,
                -0.059119150042533875,
                -0.05693778023123741,
                -0.014656046405434608,
                0.01513229962438345,
                -0.04531640186905861,
                -0.034866634756326675,
                0.02679942362010479,
                0.02292477712035179,
                0.027653781697154045,
                -0.07153470069169998,
                0.0004014235455542803,
                -0.0002527284959796816,
                -0.020986981689929962,
                0.007293024566024542,
                0.04083149880170822,
                0.00214417465031147,
                -0.007452479563653469,
                0.04543917998671532,
                0.0036112675443291664,
                -0.026187987998127937,
                -0.019255390390753746,
                -0.0432923324406147,
                0.03162343427538872,
                0.027071751654148102,
                -0.02090105041861534,
                0.034810177981853485,
                0.0038321760948747396,
                0.016680775210261345,
                0.007083313073962927,
                0.015717895701527596,
                0.012565049342811108,
                0.019503729417920113,
                0.0383889265358448,
                -0.01442032027989626,
                -0.01788918673992157,
                -0.019127536565065384,
                -0.0597400926053524,
                -0.03207623213529587,
                0.0013019947800785303,
                0.004916785284876823,
                -0.014992243610322475,
                0.027594156563282013,
                -0.02842683345079422,
                -0.020332053303718567,
                -0.02699046954512596,
                -0.009460247121751308,
                -0.006375646218657494,
                -0.005338866729289293,
                -0.0060578896664083,
                -0.0036910071503371,
                0.03384499251842499,
                -0.023253608494997025,
                0.013275275006890297,
                -0.03827580809593201,
                0.027651149779558182,
                -0.02131091058254242,
                0.04228150472044945,
                0.017074529081583023,
                7.675223605474457e-05,
                -0.02466406859457493,
                -0.10108965635299683,
                -0.0131178367882967,
                0.06797277927398682,
                0.030533989891409874,
                0.05696219950914383,
                -0.0032259987201541662,
                0.0384049154818058,
                0.000821843626908958,
                0.010065671056509018,
                0.013505210168659687,
                0.006925920024514198,
                0.05467462167143822,
                -0.02226213738322258,
                0.023083174601197243,
                0.023620352149009705,
                -0.05449620634317398,
                -0.033393193036317825,
                -0.20023129880428314,
                -0.037581343203783035,
                -0.0804726853966713,
                -0.0405036062002182,
                0.051860835403203964,
                0.044909730553627014,
                -0.01756575144827366,
                0.026792746037244797,
                -0.017016198486089706,
                -0.03272588178515434,
                -0.04602457955479622,
                -0.009781389497220516,
                -0.06130143627524376,
                0.025263933464884758,
                0.008716704323887825,
                -0.021072816103696823,
                -0.015559244900941849,
                -0.0429881289601326,
                0.044599562883377075,
                -0.0006341738626360893,
                0.04745632782578468,
                -0.010874695144593716,
                -0.03157883882522583,
                -0.012305937707424164,
                -0.014297141693532467,
                -6.556811245286746e-33,
                -0.017402227967977524,
                -0.015067938715219498,
                -0.023283693939447403,
                -0.00797249935567379,
                -0.01496419869363308,
                0.005501375067979097,
                0.0805012509226799,
                0.002953548217192292,
                -0.014986677095293999,
                -0.05332377552986145,
                -0.013983461074531078,
                -0.007405376061797142,
                0.028051545843482018,
                0.013605493120849133,
                -0.028992461040616035,
                -0.04012312367558479,
                0.010531466454267502,
                0.008694162592291832,
                0.0010244158329442143,
                -0.02401159331202507,
                -0.016255132853984833,
                0.0026269424706697464,
                -0.05935521796345711,
                -0.047240667045116425,
                0.009957247413694859,
                0.030298827216029167,
                0.004287082701921463,
                -0.025565186515450478,
                0.008550350554287434,
                0.027799660339951515,
                -0.0002751629799604416,
                0.03621267154812813,
                -0.026309438049793243,
                -0.018689274787902832,
                -0.012352077290415764,
                0.04618509113788605,
                -0.08882708102464676,
                -0.011450174264609814,
                0.016614768654108047,
                0.025968367233872414,
                0.09531721472740173,
                -0.03970097750425339,
                0.02882855385541916,
                -0.028114771470427513,
                0.011146653443574905,
                0.020168880000710487,
                0.006808750797063112,
                0.037247151136398315,
                -0.004349063150584698,
                -0.052450086921453476,
                -0.05449141189455986,
                -0.021212486550211906,
                -0.008122853934764862,
                0.05667119845747948,
                0.008121701888740063,
                -0.007470156531780958,
                -0.01644514501094818,
                -0.08950590342283249,
                -0.03367406502366066,
                0.012486427091062069,
                0.04116445407271385,
                0.0017387906555086374,
                0.03885980695486069,
                -0.06663897633552551,
                0.012252124026417732,
                0.0023285681381821632,
                -0.014513031579554081,
                -0.08110332489013672,
                -0.008694250136613846,
                -0.03585314378142357,
                -0.024755755439400673,
                -0.01587073691189289,
                0.026112310588359833,
                -0.024157464504241943,
                -0.0017228337237611413,
                -0.01764526776969433,
                0.021791864186525345,
                0.0028672039043158293,
                0.034209638833999634,
                0.0036670726258307695,
                0.010797139257192612,
                0.045443449169397354,
                -0.004289815202355385,
                -0.008458097465336323,
                0.05373690649867058,
                0.01846187375485897,
                -0.019395580515265465,
                -0.07262168079614639,
                -0.008084113709628582,
                0.012200539000332355,
                -0.024968033656477928,
                -0.0005792703595943749,
                0.008359651081264019,
                -0.05907529965043068,
                0.005737309344112873,
                -0.028119947761297226,
                0.05271421745419502,
                0.02057461626827717,
                0.018392840400338173,
                0.013571502640843391,
                0.011403784155845642,
                -0.025527818128466606,
                -0.013099904172122478,
                0.015132133848965168,
                0.005365318153053522,
                -0.021552909165620804,
                -0.08379513770341873,
                0.04649447649717331,
                -0.04234026372432709,
                0.008974584750831127,
                0.01139475405216217,
                -0.024700911715626717,
                -0.006410904228687286,
                -0.03214089944958687,
                0.03756450116634369,
                0.011400559917092323,
                0.020154530182480812,
                -0.07817374169826508,
                -0.07831577211618423,
                -0.05373380705714226,
                0.015922091901302338,
                0.05808830261230469,
                -0.002207281067967415,
                0.005093094892799854,
                0.0034788325428962708,
                0.017548399046063423,
                -0.009451058693230152,
                -0.0672716498374939,
                0.018494652584195137,
                -0.01658450812101364,
                -0.028226912021636963,
                0.05123196542263031,
                2.2770844054775807e-07,
                0.00618373928591609,
                -0.015600758604705334,
                0.023757144808769226,
                0.060275252908468246,
                0.021229051053524017,
                -0.03926701843738556,
                0.005368965677917004,
                0.03825632855296135,
                0.0286057498306036,
                -0.010211043059825897,
                -0.010801826603710651,
                -0.014501668512821198,
                0.0013944640522822738,
                -0.012610337696969509,
                0.04318033158779144,
                -0.08694668859243393,
                0.07438080757856369,
                -0.037982672452926636,
                0.007196394726634026,
                -0.01888527348637581,
                0.051820773631334305,
                -0.023166272789239883,
                0.021642103791236877,
                -0.0008092329953797162,
                0.010174248367547989,
                0.0006275989580899477,
                -0.0004479059425648302,
                0.061155278235673904,
                0.10141365230083466,
                -0.030608035624027252,
                -0.0075627281330525875,
                -0.023494405671954155,
                -0.012437106110155582,
                0.02988157980144024,
                0.028355319052934647,
                -0.005510320421308279,
                0.004323003347963095,
                0.044670470058918,
                -0.0018010359490290284,
                -0.02374873124063015,
                -0.025817643851041794,
                -0.02413361519575119,
                -0.01205067802220583,
                0.003665710799396038,
                0.031692955642938614,
                -0.0262939240783453,
                -0.008232373744249344,
                -0.02128349058330059,
                -0.03280795365571976,
                -0.06904052942991257,
                -0.00028693146305158734,
                0.02850363776087761,
                0.03932962566614151,
                0.0038624070584774017,
                -0.031245363876223564,
                0.004234698601067066,
                0.03476893901824951,
                0.024069860577583313,
                0.027385899797081947,
                0.09361110627651215,
                0.007632284425199032,
                -0.017927074804902077,
                0.008119606412947178,
                -0.023094579577445984,
                0.07923278212547302,
                -0.0331885851919651,
                -0.02104017324745655,
                1.529651168887739e-34,
                -0.029740620404481888,
                -0.0187732744961977,
                0.03340771421790123,
                0.016809780150651932,
                -0.015087488107383251,
                0.025957418605685234,
                -0.04538628086447716,
                -0.032155971974134445,
                0.05907232686877251,
                -0.02273935079574585,
                -0.0004948877030983567,
                0.0749540701508522,
                -0.0051003023982048035,
                -0.009712602011859417,
                -0.0055245631374418736,
                0.027490152046084404,
                0.032103803008794785,
                0.0013100301148369908,
                -0.00992104597389698,
                -0.02767534740269184,
                0.011912009678781033,
                0.020368458703160286,
                -0.07911499589681625,
                0.010762895457446575,
                0.009845082648098469,
                -0.01822984404861927,
                -0.10062417387962341,
                0.04344593733549118,
                0.004571087192744017,
                -0.01117327343672514,
                -0.0022876509465277195,
                0.02607780508697033,
                0.028112035244703293,
                -0.013159598223865032,
                0.020791789516806602,
                -0.050285205245018005,
                -0.05224289000034332,
                0.0020805285312235355,
                0.005295799113810062,
                -0.00592084089294076,
                0.06501229107379913,
                0.03140610083937645,
                -0.013392938300967216,
                0.0005521484417840838,
                -0.03489916771650314,
                1.6016762174331234e-06,
                0.04709938168525696,
                0.007125897333025932,
                -0.023036310449242592,
                -0.013642262667417526,
                -0.024248089641332626,
                0.0576244555413723,
                -0.005968089681118727,
                0.004113367293030024,
                0.019726822152733803,
                -0.017922306433320045,
                -0.08551327139139175,
                -0.021802781149744987,
                0.0005829226574860513,
                0.058755118399858475,
                -0.014962411485612392,
                0.01650584302842617,
                0.0159708671271801,
                -0.04297425597906113,
                -0.02325921133160591,
                -0.058299947530031204,
                -0.007645503617823124,
                -0.04176671430468559,
                0.021177927032113075,
                0.0512019582092762,
                0.0014252109685912728,
                -0.03474893793463707,
                -0.034749504178762436,
                -0.021645870059728622,
                -0.047146763652563095,
                0.10850797593593597,
                0.004714961163699627,
                0.09257396310567856,
                -0.025988060981035233,
                0.00449569383636117,
                -0.05387493595480919,
                0.07278048247098923,
                0.07458425313234329,
                -0.02630641497671604,
                0.026397302746772766,
                0.02067038044333458,
                0.054858848452568054,
                -0.037684157490730286,
                -0.021946441382169724,
                -0.006489262450486422,
                0.02049541287124157,
                0.02152305096387863,
                -0.003823921550065279,
                -0.0643872618675232,
                0.01988442987203598,
                0.1007082462310791,
                -0.024365436285734177,
                -0.03076573647558689,
                -0.0295745637267828,
                -0.031490981578826904,
                0.06542538851499557,
                0.0558030940592289,
                -0.014406543225049973,
                0.0004777820431627333,
                0.039784763008356094,
                0.0013982208911329508,
                -0.019979335367679596,
                -0.010139966383576393,
                0.04950445517897606,
                -0.03118238039314747,
                0.08756875991821289,
                -0.052702438086271286,
                -0.017744489014148712,
                -0.012630010955035686,
                -0.008951505646109581,
                -0.00032762790215201676,
                -0.003984461072832346,
                0.030142296105623245,
                -0.016901841387152672,
                -0.031139614060521126,
                0.03105703741312027,
                -0.039998818188905716,
                0.010006648488342762,
                -0.04602440446615219,
                0.04357950761914253,
                0.010319211520254612,
                -0.028481561690568924,
                -0.01682121306657791,
                0.002215468790382147,
                0.00010445321095176041,
                0.04440890625119209,
                -0.010073494166135788,
                0.00465261097997427,
                -0.012893924489617348,
                0.0481996089220047,
                0.004178754985332489,
                0.033062346279621124,
                -0.04288007691502571,
                0.0072061424143612385,
                -0.01182521041482687,
                -0.04101800173521042,
                0.0042409636080265045,
                0.014878210611641407,
                -0.009336574003100395,
                0.018347302451729774,
                0.009683121927082539,
                0.09503981471061707,
                -0.04710138961672783,
                0.01975570060312748,
                -0.08233719319105148,
                -0.011641436256468296,
                0.01631751097738743,
                0.029586786404252052,
                -0.014507615007460117,
                -0.03749500587582588,
                -0.048206958919763565,
                -0.03724057227373123,
                0.00020279882301110774,
                0.026079950854182243,
                0.014643077738583088,
                -0.00015609839465469122,
                0.014707285910844803,
                0.0067216320894658566,
                -0.0032807402312755585,
                -0.008168049156665802,
                0.062247227877378464,
                0.031669940799474716,
                0.019309643656015396,
                0.013485646806657314,
                -0.022874850779771805,
                0.00041981262620538473,
                -0.021558621898293495,
                0.0007021429482847452,
                -0.0002093857037834823,
                0.02822006866335869,
                -0.020387645810842514,
                0.04165404662489891,
                0.02888377010822296,
                0.0021285677794367075,
                -0.019452882930636406,
                0.06611519306898117,
                -0.019250858575105667,
                -0.003608443308621645,
                -0.045256588608026505,
                0.08122588694095612,
                0.0045336405746638775,
                0.015620454214513302,
                0.04394301027059555,
                0.038553956896066666,
                0.011416278779506683,
                0.003959652967751026,
                -0.0016430693212896585,
                -0.07326897978782654,
                0.0021454005036503077,
                0.008077289909124374,
                0.022394703701138496,
                0.014984878711402416,
                -0.07216012477874756,
                0.0621027946472168,
                -0.03055136278271675,
                -0.02092023752629757,
                0.032333821058273315,
                0.0413697324693203,
                -0.07948615401983261,
                -0.02077861689031124,
                0.0023531208280473948,
                0.01147816888988018,
                -0.008916756138205528,
                0.022604435682296753,
                -0.002946219639852643,
                -0.048599258065223694,
                0.028335873037576675,
                -0.007066885009407997,
                0.028065629303455353,
                0.0357825942337513,
                0.008528977632522583,
                0.021474024280905724,
                -0.0113364327698946,
                -0.042211469262838364,
                0.025246204808354378,
                -0.014584640972316265,
                0.012005608528852463,
                -0.012213816866278648,
                -0.03205883502960205,
                0.0319998599588871,
                0.010028459131717682,
                -0.002494452754035592,
                0.011529151350259781,
                -0.011927979998290539,
                0.017408056184649467,
                -0.0006765515427105129,
                -0.03585447743535042,
                0.067440927028656,
                -0.06864158809185028,
                0.04266606643795967,
                0.019052764400839806,
                -0.02574816532433033,
                0.001963270828127861,
                -0.013834463432431221,
                -0.020802386105060577,
                0.05699705332517624,
                -0.023213349282741547,
                0.024077042937278748,
                0.013608712702989578,
                0.03335004299879074,
                0.017254583537578583,
                0.025834474712610245,
                -0.03421001136302948,
                -0.020890431478619576,
                0.004296103026717901,
                0.011164090596139431,
                -0.050986651331186295,
                -0.0646199956536293,
                0.039214782416820526,
                0.037182316184043884,
                0.07276856899261475,
                0.0007312059751711786,
                0.03695778176188469,
                0.04006481170654297,
                -0.015629597008228302,
                -0.04231085255742073,
                -0.04440411180257797,
                0.012979365885257721,
                0.007796576712280512,
                0.01199094858020544,
                -0.01630166731774807,
                -0.043919846415519714,
                0.013979419134557247,
                -0.009924774058163166,
                0.011244979687035084,
                0.05046246573328972,
                -0.034232720732688904,
                -0.00452459417283535,
                -0.016112281009554863,
                -0.01435929723083973,
                0.06825274974107742,
                -0.04470526799559593,
                0.01409224048256874,
                0.02862217277288437,
                -0.021407976746559143,
                0.001211338909342885,
                0.021773958578705788,
                -0.0016702467110008001,
                -0.021142367273569107,
                -0.016985762864351273,
                -0.043023716658353806,
                -0.0020969912875443697,
                -0.05343635007739067,
                0.016841894015669823,
                -0.045002151280641556,
                0.00023084164422471076,
                0.05321834981441498,
                0.0483085922896862,
                -0.013508474454283714,
                0.004126736428588629,
                0.025855068117380142,
                0.050545793026685715,
                0.014365107752382755,
                -0.026425369083881378,
                -0.01829870231449604,
                -0.0074386089108884335,
                0.030791915953159332,
                -0.012890253216028214,
                -0.02696836367249489,
                -0.014071842655539513,
                0.028531769290566444,
                -0.008695135824382305,
                -0.027443496510386467,
                -0.03134312853217125,
                -0.032986920326948166,
                0.03191152215003967,
                -0.015955070033669472,
                -0.04124823212623596,
                0.02480284683406353,
                0.0012343835551291704,
                0.02698315680027008,
                -0.0007274913368746638,
                0.0037578053306788206,
                0.015662353485822678,
                -0.04631361365318298,
                -0.021711429581046104,
                0.021562522277235985,
                0.01904134266078472,
                0.019582640379667282,
                0.023645048961043358,
                -0.006654915399849415,
                0.046377651393413544,
                -0.005407767370343208,
                -0.03196333721280098,
                0.04438050463795662,
                -0.039878860116004944,
                -0.020871713757514954,
                -0.0072710239328444,
                -0.0032137625385075808,
                -0.017070673406124115,
                -0.004720418713986874,
                -0.03990578278899193,
                -0.06526067107915878,
                0.0009359956020489335,
                0.014455382712185383,
                -0.09093210101127625,
                0.004949996713548899,
                -0.0013575004413723946,
                -0.054463762789964676,
                -0.06010263040661812,
                -0.002062843879684806,
                0.01337595097720623,
                0.010822498239576817,
                -0.02603500336408615,
                -0.0556066669523716,
                -0.0040560332126915455,
                0.021828096359968185,
                0.02643226832151413,
                -0.0520879402756691,
                0.04861903563141823,
                0.006820680107921362,
                0.022254040464758873,
                -0.016694549471139908,
                -0.0032293337862938643,
                -0.03260131552815437,
                -0.033178411424160004,
                -0.008412523195147514,
                0.0038166099693626165,
                -0.029564039781689644,
                -0.012431560084223747,
                0.026722734794020653,
                -0.01168336346745491,
                -0.05429457500576973,
                -0.056830454617738724,
                0.02275489829480648,
                -0.09148067981004715,
                -0.016114553436636925,
                0.024474255740642548,
                -0.029043860733509064,
                -0.048964932560920715,
                -0.011555276811122894,
                -0.014081793837249279,
                0.03961106389760971,
                0.014902344904839993,
                0.021989475935697556,
                -0.00020534498617053032,
                0.0550362654030323,
                0.052486009895801544,
                -0.08943381905555725,
                0.011101708747446537,
                0.007152362260967493,
                -0.05316229537129402,
                0.01697256974875927,
                -0.03639410436153412,
                -0.036378223448991776,
                -0.01220290083438158,
                0.014777845703065395,
                -0.028594618663191795,
                0.017867058515548706,
                -0.02410968579351902,
                0.03567667677998543,
                0.002587421564385295,
                0.025987723842263222,
                -0.019569993019104004,
                0.024433840066194534,
                0.02962845005095005,
                0.0352342464029789,
                -0.0242143701761961,
                -0.001287359744310379,
                0.06145330145955086,
                -0.05584755167365074,
                0.02691192366182804,
                -0.017272481694817543,
                -0.01939399167895317,
                -0.01870972104370594,
                0.04554895684123039,
                0.03267952799797058,
                -0.008651236072182655,
                -0.03437773883342743,
                0.007956082001328468,
                -0.025448692962527275,
                -0.014989348128437996,
                0.019460901618003845,
                -0.010455298237502575,
                0.003213019808754325,
                0.040311601012945175,
                -0.08517822623252869,
                0.016361040994524956,
                0.020432893186807632,
                0.02326156012713909,
                -0.02963550016283989,
                -0.10782968997955322,
                -0.020253805443644524,
                0.00922443438321352,
                0.04468206316232681,
                -0.03829243406653404,
                0.05120140314102173,
                0.038147568702697754,
                -0.006293041631579399,
                -0.008802921511232853,
                0.03895370662212372,
                -0.036581851541996,
                0.041158851236104965,
                0.04576176404953003,
                -0.0005374687607400119,
                -0.0012683890527114272,
                -0.0037809426430612803,
                0.07416892796754837,
                0.0246810931712389,
                0.017188679426908493,
                0.1003388985991478,
                0.031128957867622375,
                -0.06003175303339958,
                0.005523595958948135,
                0.1386900246143341,
                0.009756028652191162,
                0.02487535960972309,
                0.08805234730243683,
                -0.003992287442088127,
                -0.02030465193092823,
                -0.017958424985408783,
                -0.10193950682878494,
                0.007961641065776348,
                -0.010098212398588657,
                0.011716158129274845,
                0.01674959994852543,
                0.05708075687289238,
                -0.01886921562254429,
                -0.07130002230405807,
                -0.061796896159648895,
                -0.02845340594649315,
                -0.019312063232064247,
                -0.053072910755872726,
                -0.025069916620850563,
                0.036721810698509216,
                -0.040252767503261566,
                0.0016730959760025144,
                0.014570784755051136,
                0.03511666879057884,
                0.03134135156869888,
                0.01494070515036583,
                -0.09830288589000702,
                0.04135686159133911,
                -0.0029829381965100765,
                0.022042669355869293,
                -0.035056088119745255,
                0.05321711301803589,
                0.03448982164263725,
                -0.012261036783456802,
                0.03174041584134102,
                0.035701680928468704,
                0.006567203439772129,
                -0.03625086322426796,
                -0.005582757294178009,
                0.013551818206906319,
                -0.00760548934340477,
                -0.03398580849170685,
                -0.005052172113209963,
                -0.004385761916637421,
                -0.05024038627743721,
                -0.010455112904310226,
                -0.000630135415121913,
                0.03463459014892578,
                -0.044661495834589005,
                -0.037199947983026505,
                -0.0007389363599941134,
                0.027769161388278008,
                0.021748792380094528,
                0.007019167300313711,
                -0.014749533496797085,
                -0.020602747797966003,
                0.012343098409473896,
                -0.0852084681391716,
                0.047335974872112274,
                0.028835801407694817,
                0.009001937694847584,
                -0.03183934837579727,
                0.021153489127755165,
                -0.01751633733510971,
                -0.11562840640544891,
                -0.0063673038966953754,
                0.004305648617446423,
                -0.0019022416090592742,
                0.03972917050123215,
                -0.009246814996004105,
                0.010413680225610733,
                0.023469578474760056,
                -0.027421075850725174,
                0.02295515686273575,
                -0.13017356395721436,
                -0.05306194722652435,
                0.0996415838599205,
                -0.0282082911580801,
                0.0043511101976037025,
                -0.0241253599524498,
                -0.03288380429148674,
                0.02385534904897213,
                0.04535624384880066,
                -0.023133771494030952,
                -0.03357470780611038,
                -0.014481537975370884,
                0.012315075844526291,
                0.0055159917101264,
                -0.0028179611545056105,
                0.012547156773507595,
                -0.03308647498488426,
                -0.019750842824578285,
                0.028634674847126007,
                -0.03613393008708954,
                0.047162044793367386,
                0.013497223146259785,
                0.03720863163471222,
                0.08568519353866577,
                0.05511709675192833,
                -0.0022928190883249044,
                0.04346336051821709,
                0.017630692571401596,
                0.009045114740729332,
                -0.04038858041167259,
                0.0016702989814803004,
                -0.026008429005742073,
                -0.0024696115870028734,
                -0.008244194090366364,
                -0.0032116249203681946,
                0.020220980048179626,
                0.014180825091898441,
                -0.05039026588201523,
                -0.056388936936855316,
                0.016406608745455742,
                0.028690703213214874,
                0.009589629247784615,
                -4.819790957258329e-33,
                0.013653726316988468,
                -0.029981480911374092,
                0.017129315063357353,
                -0.024193400517106056,
                -0.04466654360294342,
                -0.008398949168622494,
                0.04422532767057419,
                0.001999315107241273,
                -0.003689295845106244,
                -0.013835650868713856,
                0.010370311327278614,
                0.03205104544758797,
                -0.016973281279206276,
                0.0197580736130476,
                0.04532179608941078,
                0.06959967315196991,
                0.0055555724538862705,
                -0.02002655155956745,
                0.025070494040846825,
                -0.05335584655404091,
                -0.06197509542107582,
                0.04514482989907265,
                0.0316966287791729,
                0.05392314866185188,
                0.0415312685072422,
                -0.05116342753171921,
                -0.030896730720996857,
                0.04385656490921974,
                0.034066762775182724,
                -0.029012281447649002,
                -0.014535760506987572,
                -0.028539307415485382,
                -0.017470134422183037,
                -0.0009460884029977024,
                -0.005199158564209938,
                0.019528066739439964,
                -0.04695414379239082,
                0.010189272463321686,
                -0.008968948386609554,
                0.026387380436062813,
                -0.08540176600217819,
                0.009009198285639286,
                -0.016852978616952896,
                -0.001572915236465633,
                0.04982374235987663,
                -0.029688924551010132,
                0.007833732292056084,
                0.022859010845422745,
                0.01732673868536949,
                0.07178892940282822,
                -0.010078182443976402,
                -0.020139863714575768,
                -0.03342382609844208,
                -0.01979021541774273,
                0.017284227535128593,
                0.02300032041966915,
                -0.017815476283431053,
                -0.04646281898021698,
                0.0641515925526619,
                -0.031938280910253525,
                0.00040958463796414435,
                0.060034602880477905,
                -0.012493040412664413,
                0.0327797532081604,
                -0.054571278393268585,
                0.026014260947704315,
                -0.06620626151561737,
                -0.025452490895986557,
                0.03420389071106911,
                0.029871148988604546,
                0.011353940702974796,
                0.0019418563460931182,
                0.002150156069546938,
                -0.05380083620548248,
                -0.07727589458227158,
                0.03450564667582512,
                -0.001166794914752245,
                -0.0560113824903965,
                0.08859196305274963,
                0.04030225798487663,
                -0.11096251010894775,
                0.01630602404475212,
                -0.00330373621545732,
                0.0025721483398228884,
                -0.02239479124546051,
                -0.07873856276273727,
                0.006830411031842232,
                -0.0236821211874485,
                0.015726786106824875,
                -0.016426822170615196,
                -0.04264356568455696,
                0.006758908275514841,
                -0.07548104971647263,
                0.02989262156188488,
                0.027672115713357925,
                -0.029310954734683037,
                0.0035500649828463793,
                0.010264240205287933,
                0.004879540763795376,
                0.006620480678975582,
                -0.032942116260528564,
                -0.04505421593785286,
                -0.014514556154608727,
                -0.05585605278611183,
                -0.04074365645647049,
                0.013987135142087936,
                -0.0029984069988131523,
                -0.02576371841132641,
                0.0004743883619084954,
                -0.008923130109906197,
                0.020420117303729057,
                0.05469493940472603,
                -0.01153572928160429,
                -0.004708703141659498,
                -0.05013606697320938,
                -0.040767595171928406,
                -0.01952952891588211,
                0.06289713084697723,
                -0.029415560886263847,
                -0.008492890745401382,
                0.033813461661338806,
                0.03296375274658203,
                0.009616720490157604,
                -0.06288276612758636,
                -0.036255400627851486,
                -0.038896676152944565,
                0.002802356844767928,
                0.03397979214787483,
                0.030890561640262604,
                -0.000983191654086113,
                0.015914540737867355,
                0.026328125968575478,
                2.049137179938043e-07,
                -0.028722498565912247,
                0.0513080395758152,
                0.03253050521016121,
                -0.07633117586374283,
                0.03253082558512688,
                -0.048753127455711365,
                0.010750815272331238,
                0.06893955171108246,
                -0.017434587702155113,
                0.04451894015073776,
                0.009427826851606369,
                -0.012085149064660072,
                0.03247964382171631,
                0.004869524855166674,
                -0.0004213562933728099,
                -0.07028599083423615,
                -0.057867731899023056,
                -0.023464059457182884,
                -0.04471954330801964,
                0.0025399206206202507,
                0.058143727481365204,
                0.016857467591762543,
                0.012829122133553028,
                0.013098957017064095,
                0.008786705322563648,
                0.041799645870923996,
                -0.010284334421157837,
                0.060337215662002563,
                0.020267479121685028,
                0.031074421480298042,
                -0.03650452941656113,
                7.748037023702636e-05,
                0.0018485260661691427,
                0.05954836681485176,
                -0.005439722444862127,
                -0.014967755414545536,
                0.0010006199590861797,
                0.03328961506485939,
                0.010718544945120811,
                0.11651498824357986,
                0.009372424334287643,
                0.05311894044280052,
                -0.019496716558933258,
                0.06531774252653122,
                -0.009102477692067623,
                -0.010654952377080917,
                -0.006216833367943764,
                -0.020834358409047127,
                -0.04468832537531853,
                -0.023074131458997726,
                -0.012478078715503216,
                0.03940756618976593,
                0.040546391159296036,
                0.04474757984280586,
                0.01772106997668743,
                -0.053941886872053146,
                -0.019961820915341377,
                0.03545207157731056,
                0.04903086647391319,
                -0.035189416259527206,
                -0.009652103297412395,
                -0.007641738746315241,
                -0.0025539679918438196,
                0.039550621062517166,
                -0.043766140937805176,
                0.025890681892633438,
                0.0060875434428453445,
                6.855103084630227e-35,
                -0.04849719628691673,
                0.0171346515417099,
                0.017517926171422005,
                -0.025610703974962234,
                0.007968953810632229,
                -0.016055265441536903,
                -0.024336598813533783,
                0.0014747765380889177,
                0.014916264452040195,
                0.07259676605463028,
                -0.007968022488057613,
                0.008266055025160313,
                -0.006095334887504578,
                -0.052518319338560104,
                0.05084041506052017,
                -0.040198713541030884,
                -0.042081911116838455,
                0.025767942890524864,
                -0.0226090457290411,
                -0.15309357643127441,
                0.038734469562768936,
                0.04333360493183136,
                -0.01698993146419525,
                0.028295783326029778,
                0.11446275562047958,
                0.014344839379191399,
                -0.0419943630695343,
                -0.014634894207119942,
                0.011605619452893734,
                -0.011234387755393982,
                0.010271010920405388,
                -0.005637891124933958,
                -0.00435812771320343,
                0.0005555706447921693,
                0.014496246352791786,
                -0.0384029783308506,
                -0.02300039678812027,
                -0.03414051979780197,
                0.009416094049811363,
                -0.009541496634483337,
                -0.051084768027067184,
                0.01999393291771412,
                0.08645620197057724,
                -0.039481520652770996,
                -0.002072420436888933,
                1.619106910766277e-06,
                -0.0008957639220170677,
                -0.004534970037639141,
                0.000979893491603434,
                -0.009033148176968098,
                0.048339325934648514,
                0.021634336560964584,
                -0.0014136696700006723,
                0.009283042512834072,
                -0.025332003831863403,
                0.006656319368630648,
                0.050692349672317505,
                -0.027652235701680183,
                0.09908217936754227,
                -0.0017152030486613512,
                -0.05500262230634689,
                -0.0015277587808668613,
                -0.07513990253210068,
                -0.012646013870835304,
                -0.004089475143700838,
                0.017397038638591766,
                0.061617281287908554,
                -0.030342111364006996,
                0.028932729735970497,
                0.06509624421596527,
                -0.021572984755039215,
                0.021368421614170074,
                0.02862512320280075,
                -0.01994362659752369,
                0.011495798826217651,
                0.12477488070726395,
                -0.004755686037242413,
                0.039661213755607605,
                -0.03584225848317146,
                0.02911333180963993,
                0.027174988761544228,
                0.0859425812959671,
                0.054665546864271164,
                -0.026462366804480553,
                0.020808523520827293,
                -0.02693692222237587,
                -0.0508837029337883,
                -0.030275674536824226,
                -0.04563502222299576,
                -0.004972737282514572,
                -0.0340767540037632,
                -0.0506637878715992,
                0.002932775765657425,
                -0.0419197753071785,
                0.024375487118959427,
                -0.03418441861867905,
                0.004132018890231848,
                -0.012687301263213158,
                0.010230619460344315,
                -0.05206787958741188,
                0.008260334841907024,
                -0.025190921500325203,
                -0.0003679636283777654,
                0.0002199843293055892,
                -0.014438634738326073,
                -0.0017239585286006331,
                -0.020869966596364975,
                0.061322540044784546,
                0.021807650104165077,
                0.014568239450454712,
                -0.04092424362897873,
                0.09947846829891205,
                -0.0021917885169386864,
                0.0618441142141819,
                0.04133500158786774,
                0.013816051185131073,
                0.04124218970537186,
                -0.0038269353099167347,
                -0.011097932234406471,
                -0.03497716039419174,
                0.09553839266300201,
                -0.04320015385746956,
                0.009569709189236164,
                -0.05878158286213875,
                0.06718319654464722,
                0.01776936464011669,
                -0.02754802256822586,
                -0.030007891356945038,
                0.034916896373033524,
                -0.004686893429607153,
                0.0058522881008684635,
                -0.03351398557424545,
                0.017468556761741638,
                -0.003362306859344244,
                -0.032438650727272034,
                -0.011709289625287056,
                0.010237431153655052,
                -0.018809428438544273,
                0.012001274153590202,
                0.006856809835880995,
                -0.0537131130695343,
                0.03575501963496208,
                -0.0389554463326931,
                -0.06715340167284012,
                -0.006147401873022318,
                0.0010748229688033462,
                -0.034382108598947525,
                -0.01837681420147419,
                -0.004520230460911989,
                -0.07617022097110748,
                -0.037651967257261276,
                0.036413099616765976,
                -0.025392968207597733,
                -0.04664822295308113,
                -0.03324810042977333,
                -0.05418167635798454,
                -0.03816690295934677,
                0.0002780395734589547,
                0.028335999697446823,
                -0.012194721028208733,
                -0.03720931336283684,
                -0.011191857978701591,
                0.04050648212432861,
                0.012838599272072315,
                0.03986124321818352,
                0.02756616659462452,
                0.045387692749500275,
                -0.031798526644706726,
                0.0628427192568779,
                0.007425796706229448,
                -0.00933072343468666,
                -0.006162988021969795,
                -0.006722235586494207,
                -0.03493228927254677,
                -0.015983590856194496,
                -0.021375427022576332,
                -0.006426069885492325,
                -0.024309402331709862,
                0.008852059952914715,
                -0.05070760101079941,
                -0.001379673252813518,
                -0.06403328478336334,
                -0.022984905168414116,
                -0.03801974654197693,
                0.14230355620384216,
                0.03145385533571243,
                0.004487759433686733,
                0.01949317753314972,
                0.02789844013750553,
                -0.08178266882896423,
                -0.006320467218756676,
                0.03458061441779137,
                -0.08099313080310822,
                0.02941349893808365,
                0.005384249147027731,
                0.02732383832335472,
                0.02034599892795086,
                -0.04511246457695961,
                0.002030604286119342,
                -0.056309062987565994,
                -0.020363427698612213,
                -0.003502665553241968,
                0.009622829034924507,
                -0.011738860048353672,
                -0.02099420502781868,
                -0.0029648621566593647,
                0.02640904113650322,
                0.059764835983514786,
                -0.028863651677966118,
                -0.04050028696656227,
                -0.02377106063067913,
                -0.026303177699446678,
                -0.02185538038611412,
                0.053649310022592545,
                0.06521030515432358,
                -0.008402797393500805,
                0.022856561467051506,
                -0.023281486704945564,
                -0.01669549196958542,
                -0.05288456380367279,
                0.019398530945181847,
                0.06753146648406982,
                -0.00046124382060952485,
                -0.053491394966840744,
                0.023738697171211243,
                -0.017613589763641357,
                -0.018704703077673912,
                0.02270055003464222,
                -0.0007715524407103658,
                -0.09718307852745056,
                0.03827232867479324,
                -0.018281666561961174,
                0.020315922796726227,
                0.040344901382923126,
                0.0032945757266134024,
                0.07749269157648087,
                0.06371597200632095,
                0.03536740690469742,
                -0.009070205502212048,
                0.03188711404800415,
                0.0555376298725605,
                0.002700667129829526,
                0.03589988872408867,
                0.011438988149166107,
                -0.04624538496136665,
                0.01343393512070179,
                -0.013999870978295803,
                0.011734005995094776,
                0.057069309055805206,
                -0.004298155661672354,
                -0.07150822877883911,
                0.030532127246260643,
                -0.033412910997867584,
                0.012441052123904228,
                -0.017607618123292923,
                0.014926150441169739,
                0.01258978433907032,
                0.045742638409137726,
                0.0027694555465132,
                0.03647288680076599,
                -0.016822796314954758,
                -0.03246738761663437,
                -0.020601574331521988,
                -0.005287674255669117,
                -0.012887539342045784,
                0.03577812388539314,
                -0.04482593014836311,
                0.0024372295010834932,
                0.043953027576208115,
                0.0035516591742634773,
                -0.032620299607515335,
                0.045526180416345596,
                -0.013073759153485298,
                0.012961662374436855,
                0.011789287440478802,
                0.01488874014467001,
                -0.028866948559880257,
                0.03468812629580498,
                -0.010687457397580147,
                -0.0031768258195370436,
                0.02374192699790001,
                -0.019673366099596024,
                0.027471935376524925,
                -0.00544056436046958,
                -0.010249066166579723,
                0.028196653351187706,
                0.008260544389486313,
                0.030703969299793243,
                0.02079867385327816,
                -0.00010611485049594194,
                -0.013165397569537163,
                0.06990080326795578,
                -0.05290502309799194,
                0.025555307045578957,
                0.010532449930906296,
                0.011083701625466347,
                0.009690937586128712,
                0.044736750423908234,
                -0.04008040577173233,
                -0.010785051621496677,
                0.054641034454107285,
                -0.017384013161063194,
                -0.02802475169301033,
                0.024981191381812096,
                -0.016745751723647118,
                0.03978288546204567,
                -0.04331972822546959,
                -0.009523124434053898,
                -0.036415617913007736,
                -0.009021759033203125,
                0.03840130195021629,
                -0.017908576875925064,
                -0.046298738569021225,
                -0.014860458672046661,
                -0.0004229297337587923,
                -0.027466878294944763,
                0.013289605267345905,
                0.06548315286636353,
                0.04750266671180725,
                0.009867282584309578,
                0.0024412977509200573,
                -0.005334876943379641,
                0.011437826789915562,
                0.009199955500662327,
                0.06451668590307236,
                0.027045462280511856,
                0.009794366545975208,
                -0.052196066826581955,
                -0.011634277179837227,
                0.04940859600901604,
                0.08513730019330978,
                -0.028213610872626305,
                0.009564888663589954,
                0.0017463101539760828,
                -7.207215094240382e-05,
                0.02261229231953621,
                -0.013275046832859516,
                -0.011627503670752048,
                0.025144493207335472,
                0.027148444205522537,
                -0.02369270659983158,
                -0.0006095886928960681,
                0.11955904960632324,
                0.0031151503790169954,
                -0.025914959609508514,
                0.024396385997533798,
                0.021812282502651215,
                -0.012021336704492569,
                -0.002663566032424569,
                -0.00913744792342186,
                -0.0466601699590683,
                -0.022521862760186195,
                -0.046705298125743866,
                -0.0988885834813118,
                0.12226549535989761,
                0.022952154278755188,
                0.02571776509284973,
                0.020230945199728012,
                0.0453132726252079,
                -0.04548637196421623,
                0.03332415595650673,
                0.015128262341022491,
                5.48178686585743e-05,
                -0.004701424855738878,
                -0.0017700514290481806,
                -0.0016423397464677691,
                0.02373388223350048,
                -0.0010693236254155636,
                0.00314764934591949,
                -0.025968702509999275,
                -0.009911682456731796,
                -0.010965239256620407,
                0.0018563587218523026,
                -0.04605383798480034,
                -0.03966597467660904,
                -0.017874790355563164,
                0.014105668291449547,
                0.06718483567237854,
                0.023224402219057083,
                -0.020594781264662743,
                -0.008649716153740883,
                0.02559267170727253,
                0.03152327612042427,
                -0.0091480677947402,
                0.03353462368249893,
                0.023175330832600594,
                0.0036740328650921583,
                0.0004462636134121567,
                -0.0083767706528306,
                0.006725376006215811,
                -0.004610795062035322,
                0.0009531966061331332,
                -0.04519817233085632,
                0.08670710027217865,
                -0.031040579080581665,
                0.012856990098953247,
                -0.017223570495843887,
                -0.0101179713383317,
                -0.01892535760998726,
                0.031118301674723625,
                0.024248156696558,
                -0.005248700268566608,
                -0.13916437327861786,
                -0.02101505734026432,
                0.010690553113818169,
                -0.01174998376518488,
                0.01766045391559601,
                -0.04103793203830719,
                0.045964565128088,
                0.028566770255565643,
                0.0330359973013401,
                -0.00422699237242341,
                -0.003780623897910118,
                -0.01452291663736105,
                -0.005031104199588299,
                0.005795036442577839,
                -0.08940040320158005,
                0.0328206904232502,
                -0.01738942414522171,
                -0.0023062746040523052,
                -0.07193472236394882,
                -0.04727056995034218,
                -0.051933981478214264,
                0.0126798115670681,
                -0.016819007694721222,
                -0.04178176820278168,
                -0.004206857644021511,
                -0.026185648515820503,
                -0.02300134114921093,
                0.033363938331604004,
                -0.020950540900230408,
                0.006921885069459677,
                0.031140198931097984,
                0.015053929761052132,
                -0.027390465140342712,
                0.01763305254280567,
                -0.061191461980342865,
                -0.055453453212976456,
                0.023551881313323975,
                0.020193874835968018,
                0.00028756281244568527,
                -0.010408337228000164,
                0.004850250668823719,
                0.004826018121093512,
                0.03740517050027847,
                -0.018589938059449196,
                0.012540485709905624,
                -0.05202345922589302,
                -0.002172060776501894,
                0.028996825218200684,
                -0.027607137337327003,
                0.010187721811234951,
                -0.0018880072748288512,
                0.04247717559337616,
                -0.009456687606871128,
                -0.012007945217192173,
                -0.05435791611671448,
                0.008813656866550446,
                -0.03999542072415352,
                -0.022014958783984184,
                0.02831856720149517,
                0.016092738136649132,
                -0.04834980145096779,
                -0.043331462889909744,
                -0.009070971049368382,
                -0.004715421702712774,
                -0.008966373279690742,
                -0.010015892796218395,
                -0.007906084880232811,
                -0.020997483283281326,
                0.038208018988370895,
                0.020845653489232063,
                0.03813068941235542,
                -0.01977795735001564,
                -0.02002543956041336,
                0.038354650139808655,
                0.06741248816251755,
                0.022359665483236313,
                -0.04347023367881775,
                -0.034882668405771255,
                -0.01614455133676529,
                0.03656601905822754,
                0.003547210246324539,
                -0.018548794090747833,
                0.04381655529141426,
                0.06723619997501373,
                0.0006193777662701905,
                -0.027915310114622116,
                -0.029989782720804214,
                0.04037449136376381,
                -0.00898401252925396,
                0.0552511066198349,
                0.07984719425439835,
                -0.009754069149494171,
                0.024254467338323593,
                -0.0040083215571939945,
                0.032820165157318115,
                0.03157360851764679,
                -0.03705746680498123,
                0.013625475578010082,
                -0.03703382611274719,
                -0.007121375296264887,
                -0.0209489818662405,
                0.009543398395180702,
                -0.03787856176495552,
                0.006777293514460325,
                0.03935879096388817,
                0.02368747629225254,
                0.06190873309969902,
                0.04450221359729767,
                -0.011412016116082668,
                0.004779710900038481,
                0.012564931996166706,
                0.02357388660311699,
                0.012499996460974216,
                -0.03702995181083679,
                0.01795746199786663,
                0.013868371024727821,
                0.04391353204846382,
                -0.001929528429172933,
                -0.010033033788204193,
                0.012172762304544449,
                0.05267699807882309,
                0.0072411587461829185,
                -0.1009945198893547,
                -0.017868515104055405,
                0.013758924789726734,
                -0.038941770792007446,
                0.046162404119968414,
                -0.053928192704916,
                0.00519856670871377,
                0.014322622679173946,
                -0.030046161264181137,
                0.04013088718056679,
                -0.004985686391592026,
                0.00359697756357491,
                0.0072602760046720505,
                0.037153564393520355,
                0.026332223787903786,
                -0.07771062105894089,
                -0.0007971976301632822,
                -0.1469462811946869,
                -0.057550109922885895,
                -0.03420224413275719,
                -0.019656281918287277,
                -0.018302462995052338,
                0.08654750138521194,
                0.03737558424472809,
                0.015316407196223736,
                -0.01090236660093069,
                -0.02320847101509571,
                -0.08756933361291885,
                0.01913665607571602,
                0.08630287647247314,
                -0.01766211912035942,
                0.047057315707206726,
                0.03775514289736748,
                0.04071962833404541,
                0.015241007320582867,
                -0.014772423543035984,
                -0.03681715205311775,
                0.02121053822338581,
                -0.026967432349920273,
                -0.07686744630336761,
                0.0008125028107315302,
                -0.00497479410842061,
                -6.197013730335153e-33,
                -0.023447323590517044,
                0.05016964673995972,
                0.017154166474938393,
                0.010166044346988201,
                -0.022668028250336647,
                -0.004344696179032326,
                0.02053198032081127,
                0.026181470602750778,
                -0.03365711122751236,
                -0.027628062292933464,
                -0.027576547116041183,
                -0.06198804825544357,
                -0.0005138508859090507,
                0.0033865992445498705,
                -0.0032125927973538637,
                -0.0024465005844831467,
                0.04840517416596413,
                0.037839487195014954,
                -0.04452969506382942,
                -0.006596715655177832,
                -0.017872033640742302,
                0.0006166259408928454,
                -0.016039812937378883,
                -0.0351928174495697,
                -0.02376326359808445,
                -0.03112088143825531,
                -0.022634677588939667,
                0.0074759055860340595,
                0.023216433823108673,
                -0.004779776558279991,
                -0.021553993225097656,
                0.02194354683160782,
                -0.0102379834279418,
                -0.028550367802381516,
                0.0028236787766218185,
                0.007523929700255394,
                -0.01730632595717907,
                -0.0556720606982708,
                0.0016529810382053256,
                0.03776829317212105,
                0.0305505208671093,
                -0.027459831908345222,
                -0.022337058559060097,
                -0.006817182060331106,
                -0.020182669162750244,
                -0.01961628720164299,
                -0.013635304756462574,
                -0.02384015917778015,
                0.019477980211377144,
                0.046088822185993195,
                -0.03893129527568817,
                -0.0001307780185015872,
                -0.016577549278736115,
                0.05626089498400688,
                0.03237364441156387,
                -0.11279632151126862,
                -0.005455944687128067,
                -0.06463706493377686,
                -0.039985541254282,
                0.030672414228320122,
                0.034197159111499786,
                -0.022778375074267387,
                0.008933713659644127,
                -0.0042735678143799305,
                0.006489759776741266,
                0.0008634005207568407,
                -0.029859310016036034,
                0.06996450573205948,
                0.00897231139242649,
                0.024028709158301353,
                -0.0005571647197939456,
                0.11280377209186554,
                0.043053265661001205,
                -0.009917770512402058,
                -0.021983196958899498,
                -0.02434707246720791,
                0.010265391319990158,
                -0.01377181801944971,
                -0.025869198143482208,
                0.06766986101865768,
                -0.03649985045194626,
                0.044278502464294434,
                -0.01290752924978733,
                -0.004825645592063665,
                0.009717248380184174,
                -0.07778450846672058,
                -0.03182515874505043,
                -0.014709141105413437,
                0.004705807194113731,
                -0.01639643870294094,
                0.0028200880624353886,
                -0.019352387636899948,
                -0.02470499649643898,
                -0.014136002399027348,
                0.03425128012895584,
                -0.03636835515499115,
                0.01391203049570322,
                -0.001368361059576273,
                0.01204584538936615,
                -0.045306798070669174,
                -0.03381132334470749,
                -0.007841644808650017,
                -0.021225301548838615,
                0.08541250973939896,
                -0.03854694962501526,
                0.013995302841067314,
                0.013159476220607758,
                -0.01986939273774624,
                -0.0030910614877939224,
                -0.011663848534226418,
                -0.011988354846835136,
                -0.041856467723846436,
                0.026363803073763847,
                -0.08407262712717056,
                -0.034121740609407425,
                -0.042436618357896805,
                -0.0005436918581835926,
                -0.055161092430353165,
                -0.017493246123194695,
                -0.025840280577540398,
                -0.0044202422723174095,
                0.013413963839411736,
                -0.05345005914568901,
                0.01861472986638546,
                0.04346533119678497,
                -0.02114482782781124,
                0.011290565133094788,
                -0.02580750547349453,
                0.10423821955919266,
                -0.021546462550759315,
                -0.03354528173804283,
                -0.00933934934437275,
                2.3721317177205492e-07,
                -0.0013811084209010005,
                -0.012572810985147953,
                -0.012404766865074635,
                0.06106375902891159,
                0.014172779396176338,
                -0.0022059089969843626,
                0.0017193048261106014,
                0.021535398438572884,
                -0.02210415154695511,
                -0.0075128525495529175,
                0.03271052613854408,
                0.02393122762441635,
                -0.044755738228559494,
                -0.009245301596820354,
                -0.027062419801950455,
                -0.06648869067430496,
                0.06644094735383987,
                0.011196144856512547,
                -0.027527490630745888,
                0.00026122486451640725,
                0.02709188126027584,
                -0.013326971791684628,
                0.0382751040160656,
                -0.014153270050883293,
                -0.04456177353858948,
                0.019546790048480034,
                -0.02684558928012848,
                -0.029219184070825577,
                0.07165249437093735,
                0.04276714473962784,
                0.04084501042962074,
                -0.005599362310022116,
                0.021586447954177856,
                0.04354378581047058,
                0.03574970364570618,
                -0.04502319172024727,
                0.049278005957603455,
                0.001661650836467743,
                0.025079915300011635,
                0.06457983702421188,
                -0.016827654093503952,
                0.001498851808719337,
                -0.0031480754259973764,
                -0.036544520407915115,
                0.03707122057676315,
                -0.0242985300719738,
                0.0002709861728362739,
                0.05968986079096794,
                -0.06216008588671684,
                -0.003253357019275427,
                0.019756676629185677,
                0.021405110135674477,
                -0.002444707090035081,
                0.03176277503371239,
                -0.01012788712978363,
                -0.005253504496067762,
                0.01673981361091137,
                0.040487196296453476,
                0.054660819470882416,
                0.024917541071772575,
                0.011003896594047546,
                -0.00023349703405983746,
                0.017293402925133705,
                -0.019953159615397453,
                0.021984703838825226,
                -0.003058522939682007,
                -0.026937071233987808,
                1.2630014104576122e-34,
                -0.013859808444976807,
                -0.045229073613882065,
                0.016194120049476624,
                0.04412763565778732,
                -0.011627824045717716,
                -0.013277987949550152,
                0.02350718341767788,
                -0.028452182188630104,
                0.016586458310484886,
                0.009435303509235382,
                -0.015591513365507126,
                0.03208990395069122,
                0.06187519058585167,
                -0.033957935869693756,
                -0.02028234489262104,
                0.01045618299394846,
                0.01858975924551487,
                -0.012608196586370468,
                -0.028463374823331833,
                -0.10083688050508499,
                0.05331563204526901,
                0.049518734216690063,
                0.035046063363552094,
                -0.026278076693415642,
                0.04499281570315361,
                -0.01669459231197834,
                -0.0766165554523468,
                0.018337495625019073,
                -0.03007485903799534,
                -0.007751181721687317,
                0.014431130141019821,
                0.001312998472712934,
                0.009494315832853317,
                -0.005465133115649223,
                0.02681160904467106,
                -0.03357281535863876,
                -0.0011126691242679954,
                -0.0328974574804306,
                0.0030853666830807924,
                0.03248082846403122,
                -0.026725338771939278,
                0.0028203825931996107,
                0.02342466078698635,
                -0.03421087935566902,
                -0.025930063799023628,
                1.6188499785130261e-06,
                0.0481349378824234,
                0.007736665196716785,
                -0.040277719497680664,
                -0.025562895461916924,
                0.06614158302545547,
                0.010857929475605488,
                -0.0196718480437994,
                0.023414524272084236,
                0.014774019829928875,
                -0.002179560484364629,
                0.001229269546456635,
                0.0506124310195446,
                0.04177908971905708,
                -0.007803361862897873,
                -0.07237352430820465,
                0.026359014213085175,
                0.025475427508354187,
                -0.08939142525196075,
                -0.04713260382413864,
                -0.008149327710270882,
                -0.07104215025901794,
                0.0022029471583664417,
                -0.014129690825939178,
                0.02886030822992325,
                0.04152737185359001,
                -0.0003251821326557547,
                -0.014835312962532043,
                0.00329066370613873,
                0.016459403559565544,
                0.04105055332183838,
                0.009437876753509045,
                0.06486502289772034,
                -0.021058574318885803,
                -0.0009955663699656725,
                -0.03341180086135864,
                0.025474267080426216,
                0.03610611334443092,
                -0.014535283669829369,
                -0.02333618700504303,
                0.001326852012425661,
                -0.0549575537443161,
                -0.008986681699752808,
                -0.004378207493573427,
                -0.009659390896558762,
                0.0005670859245583415,
                -0.028494009748101234,
                0.02739514224231243,
                -0.021296696737408638,
                0.007458865642547607,
                0.016446029767394066,
                0.015550973825156689,
                -0.03943731635808945,
                0.01710379868745804,
                -0.02778109908103943,
                0.04754886403679848,
                0.00734592555090785,
                -0.0139584606513381,
                0.02486604079604149,
                0.03627762943506241,
                -0.052640389651060104,
                -0.008157050237059593,
                0.002101612975820899,
                -0.013848965056240559,
                0.02340875379741192,
                -0.004960120655596256,
                -0.0376274399459362,
                0.01027617882937193,
                0.012589155696332455,
                0.05053647607564926,
                0.07906399667263031,
                0.04246358573436737,
                -0.03765489533543587,
                -0.035069458186626434,
                -0.03505373373627663,
                0.005048596765846014,
                -0.060440339148044586,
                0.010660831816494465,
                -0.01702333614230156,
                0.04485201835632324,
                0.0040457285940647125,
                0.01246770191937685,
                -0.04728653281927109,
                0.030319031327962875,
                4.619492756319232e-05,
                0.03352048620581627,
                0.040645960718393326,
                0.021410075947642326,
                -0.04265008121728897,
                0.01449158601462841,
                -0.04215133935213089,
                -0.008729378692805767,
                -0.051169101148843765,
                0.017516251653432846,
                -0.06499838829040527,
                0.07334113866090775,
                0.024316953495144844,
                -0.03740505874156952,
                -0.010935399681329727,
                -0.0017754699802026153,
                -0.008750551380217075,
                0.03963710367679596,
                -0.005113689694553614,
                0.015407465398311615,
                -0.06250516325235367,
                -0.002340370789170265,
                0.025970689952373505,
                0.020397672429680824,
                -0.07091300934553146,
                -0.025478340685367584,
                -0.039433542639017105,
                -0.02791212871670723,
                -0.08791300654411316,
                0.03244173899292946,
                0.010494432412087917,
                -0.008606615476310253,
                -0.01108805276453495,
                -0.026348570361733437,
                0.0032997371163219213,
                0.02923842892050743,
                0.05809006467461586,
                0.07125724852085114,
                -0.015773704275488853,
                -0.012094917707145214,
                -0.019805606454610825,
                0.04231508821249008,
                -0.01120375469326973,
                0.0425398126244545,
                0.07270161807537079,
                0.04446524381637573,
                -0.05319051444530487,
                0.003275379305705428,
                0.08606723695993423,
                -0.011635130271315575,
                -0.012365027330815792,
                -0.04815021902322769,
                -0.08658202737569809,
                0.040572620928287506,
                0.011457199230790138,
                0.09409988671541214,
                0.044868215918540955,
                -0.07586777210235596,
                0.040171995759010315,
                -0.01387398224323988,
                -0.06487317383289337,
                0.009271028451621532,
                -0.026707753539085388,
                -0.07976903766393661,
                0.032165154814720154,
                0.08330924808979034,
                0.06508784741163254,
                0.012809433043003082,
                -0.05661197379231453,
                0.02159484662115574,
                -0.0428452342748642,
                0.002965444466099143,
                0.021510504186153412,
                -0.026458872482180595,
                -0.09469558298587799,
                -0.0061955624260008335,
                0.027799034491181374,
                -0.006169733591377735,
                0.015055738389492035,
                -0.017696449533104897,
                -0.023975493386387825,
                0.017691656947135925,
                0.0014349832199513912,
                0.02082582749426365,
                0.02499569021165371,
                0.0016688610194250941,
                -0.04762475565075874,
                -0.08561155200004578,
                -0.051220815628767014,
                -0.001639181631617248,
                -0.060854990035295486,
                -0.0359518863260746,
                0.027073487639427185,
                -0.01789778470993042,
                -0.0036183062475174665,
                0.04557343199849129,
                -0.0190154816955328,
                0.043245088309049606,
                -0.001039331778883934,
                0.010260420851409435,
                -0.051386747509241104,
                0.03226708993315697,
                -0.03088502585887909,
                0.010440931655466557,
                0.013390600681304932,
                0.02788827195763588,
                0.010765588842332363,
                -0.03156760707497597,
                0.021942149847745895,
                -0.014106826856732368,
                0.018409602344036102,
                0.005541260354220867,
                0.021742982789874077,
                0.006801282055675983,
                0.054584018886089325,
                -0.03338349610567093,
                -0.01883942075073719,
                -0.008016201667487621,
                0.0649528056383133,
                0.042243264615535736,
                0.00272623379714787,
                -0.04431147500872612,
                -0.021241653710603714,
                -0.07740683853626251,
                -0.04580696299672127,
                -0.014613903127610683,
                0.066911980509758,
                0.01774921640753746,
                0.008431977592408657,
                0.025250902399420738,
                0.03736865147948265,
                -0.04562128335237503,
                -0.04781639575958252,
                -0.04243797808885574,
                0.0360802486538887,
                0.019917864352464676,
                0.016182631254196167,
                0.07044365257024765,
                -0.06826318800449371,
                0.012536733411252499,
                -0.020833538845181465,
                -0.0035467962734401226,
                0.07014694809913635,
                0.012572251260280609,
                -0.0023792805150151253,
                -0.023373600095510483,
                0.024487784132361412,
                -0.017939167097210884,
                -0.018838083371520042,
                -0.024105912074446678,
                -0.053793199360370636,
                0.01819700561463833,
                0.011940224096179008,
                -0.008542927913367748,
                0.024392658844590187,
                -0.018336383625864983,
                -0.048880547285079956,
                0.0016523398226127028,
                -0.026773253455758095,
                0.03667958080768585,
                -0.02598920464515686,
                0.021548504009842873,
                0.015564091503620148,
                0.01887640729546547,
                -0.06281020492315292,
                0.03520873561501503,
                0.021395083516836166,
                -0.010491868481040001,
                0.017458777874708176,
                -0.07976725697517395,
                -0.04739035293459892,
                0.022630475461483,
                -0.032766666263341904,
                -0.06921432912349701,
                0.035192664712667465,
                0.02413882128894329,
                -0.025476617738604546,
                -0.0032878059428185225,
                0.060035884380340576,
                -0.07879284024238586,
                -0.02534930221736431,
                0.013055738992989063,
                0.01510667521506548,
                -0.04479428380727768,
                0.023537542670965195,
                -0.04534991458058357,
                0.016122836619615555,
                0.010075419209897518,
                0.06572342664003372,
                0.015683025121688843,
                -0.0116981016471982,
                0.0006045311456546187,
                0.028536537662148476,
                0.03340316563844681,
                0.018637806177139282,
                0.05668367072939873,
                -0.03120187483727932,
                0.05251002311706543,
                0.0028838959988206625,
                -0.008729325607419014,
                0.032442279160022736,
                0.06518550217151642,
                -0.017443211749196053,
                0.015921136364340782,
                -0.003489085705950856,
                0.011221285909414291,
                0.026404349133372307,
                0.015351473353803158,
                -0.05357914790511131,
                -0.03961214795708656,
                0.0541045106947422,
                -0.03432970866560936,
                -0.014056125655770302,
                -0.035513442009687424,
                -0.028187820687890053,
                -0.022480757907032967,
                0.0026878167409449816,
                -0.014175615273416042,
                0.004432894755154848,
                -0.04462350904941559,
                -0.030063116922974586,
                0.005682086106389761,
                -0.021673325449228287,
                -0.019065886735916138,
                -0.04026348516345024,
                0.007182043511420488,
                -0.03583916276693344,
                0.01453458797186613,
                0.020068982616066933,
                0.06485619395971298,
                -0.003765863599255681,
                0.02687539905309677,
                0.03842553868889809,
                0.01611078344285488,
                -0.010808569379150867,
                -0.006687352433800697,
                0.00011804432870121673,
                -0.043246038258075714,
                -0.06306514143943787,
                0.003976167645305395,
                -0.0021474298555403948,
                -0.023565536364912987,
                -0.07313240319490433,
                -0.010487128980457783,
                0.04743654653429985,
                -0.029853202402591705,
                -0.023612452670931816,
                -0.017249951139092445,
                0.030375590547919273,
                0.019182216376066208,
                0.02692795731127262,
                0.0220890324562788,
                -0.044896841049194336,
                0.011935306712985039,
                0.006669218186289072,
                -0.00816288497298956,
                0.028048064559698105,
                0.0022477207239717245,
                0.008900834247469902,
                -0.009098327718675137,
                -0.04157411679625511,
                0.005249375943094492,
                0.00026583511498756707,
                0.022044610232114792,
                0.019007958471775055,
                -0.030355727300047874,
                -0.0038499110378324986,
                0.012819617055356503,
                -0.024790218099951744,
                -0.005804451182484627,
                0.06471829861402512,
                0.021019039675593376,
                -0.02534462697803974,
                0.003423877526074648,
                0.006086477544158697,
                0.033601969480514526,
                0.03768696263432503,
                -0.03550628200173378,
                -0.06588364392518997,
                0.011284738779067993,
                0.0450906828045845,
                -0.00355461030267179,
                0.003028011880815029,
                -0.012440393678843975,
                0.03231918066740036,
                -0.008109715767204762,
                -0.04561762139201164,
                -0.055519949644804,
                0.08596912026405334,
                0.006486996542662382,
                -0.0056798397563397884,
                0.01734462007880211,
                -0.012870199047029018,
                0.011075001209974289,
                -0.0157296285033226,
                -0.013922193087637424,
                0.04533941671252251,
                0.004672557581216097,
                -0.0364333875477314,
                0.022885244339704514,
                -0.04902627691626549,
                -0.04624570533633232,
                0.01805914007127285,
                0.048080507665872574,
                0.024172717705368996,
                -0.036997899413108826,
                0.06061722710728645,
                -0.007688992191106081,
                -0.00767663074657321,
                0.02907201275229454,
                -0.013156705535948277,
                -0.010987523943185806,
                -0.032444264739751816,
                0.017381714656949043,
                -0.0033689767587929964,
                0.051930539309978485,
                0.055339664220809937,
                0.05642728507518768,
                0.006968607660382986,
                -0.019806986674666405,
                0.0893481895327568,
                0.0019006725633516908,
                0.013196422718465328,
                0.09992476552724838,
                0.052002761512994766,
                0.02391895465552807,
                -0.008022070862352848,
                0.026253538206219673,
                0.0014587721088901162,
                -0.02504795975983143,
                -0.11165529489517212,
                0.07154464721679688,
                0.035397548228502274,
                0.007495129480957985,
                -0.03778716176748276,
                -0.022537117823958397,
                -0.004786525387316942,
                0.022658657282590866,
                -0.03420066833496094,
                -0.05252423882484436,
                0.010848330333828926,
                -0.03013613261282444,
                0.06678637117147446,
                0.02879154495894909,
                -0.11949280649423599,
                0.0017202653689309955,
                -0.027221666648983955,
                -0.036213457584381104,
                -0.04383708909153938,
                0.012198150157928467,
                0.010660061612725258,
                -0.043194256722927094,
                0.05244578421115875,
                -0.03007497638463974,
                -0.018892107531428337,
                0.007015611510723829,
                0.023828735575079918,
                0.00932789221405983,
                0.03900428116321564,
                -0.06713448464870453,
                -0.0037923746276646852,
                0.002559267682954669,
                -0.011990874074399471,
                0.030987003818154335,
                -0.004278199747204781,
                -0.08088298887014389,
                -0.017404887825250626,
                0.0020667766220867634,
                0.02685476280748844,
                -0.0035527674481272697,
                -0.0015452944207936525,
                -0.0005717036547139287,
                0.0117404256016016,
                -0.01587965153157711,
                0.01438447367399931,
                0.006761892233043909,
                0.007735620252788067,
                -0.01636294089257717,
                0.034440185874700546,
                -0.00965727400034666,
                0.039973724633455276,
                -0.04874696210026741,
                0.008966405875980854,
                0.04138055443763733,
                -0.01618286408483982,
                -0.049418941140174866,
                0.0020089754834771156,
                -0.017978079617023468,
                -0.006600161083042622,
                0.008763769641518593,
                -0.03276795148849487,
                0.011085518635809422,
                -0.0145194036886096,
                -0.029460875317454338,
                0.030200297012925148,
                -0.08226223289966583,
                0.06653930246829987,
                0.04606220871210098,
                0.012544872239232063,
                0.04267186298966408,
                -0.02284502424299717,
                -0.03974508121609688,
                0.023163817822933197,
                -0.027218418195843697,
                0.0013504724483937025,
                -0.0218153428286314,
                0.05729028582572937,
                -0.017108609899878502,
                0.02740415558218956,
                -0.020335279405117035,
                -0.021483322605490685,
                0.023842262104153633,
                -0.0032622963190078735,
                -0.06152493506669998,
                -0.11012791842222214,
                0.010893156751990318,
                -0.008346738293766975,
                0.08030878007411957,
                0.004160693846642971,
                0.05471495911478996,
                -0.02648613229393959,
                0.002679153112694621,
                -0.050316065549850464,
                0.05267136171460152,
                0.007847779430449009,
                -0.0015847011236473918,
                0.014018921181559563,
                -0.06598485261201859,
                -0.02351682260632515,
                -0.007140382658690214,
                0.010879343375563622,
                0.032408151775598526,
                -0.010992305353283882,
                -0.03648783266544342,
                0.0010503977537155151,
                0.02866598218679428,
                -0.027972163632512093,
                -5.683616205271534e-33,
                -0.05298333615064621,
                -0.0688483789563179,
                0.03901948407292366,
                0.029863623902201653,
                0.009182602167129517,
                0.0015202785143628716,
                0.024961672723293304,
                0.04113603010773659,
                -0.01216693501919508,
                -0.05290563404560089,
                -0.001556790666654706,
                0.004131094552576542,
                0.02193627879023552,
                -0.008446070365607738,
                0.10536891222000122,
                0.04360052943229675,
                0.05317624285817146,
                0.023999571800231934,
                0.02665325254201889,
                -0.038025062531232834,
                -0.0232936330139637,
                0.022868050262331963,
                0.03162243589758873,
                0.06746840476989746,
                -0.014119207859039307,
                0.012679269537329674,
                0.020421721041202545,
                0.023443952202796936,
                0.0699121430516243,
                0.006362713873386383,
                0.00874320324510336,
                -0.04861536622047424,
                0.005716544575989246,
                -0.012018447741866112,
                -0.0006204135715961456,
                0.039036825299263,
                -0.008112738840281963,
                -0.023955106735229492,
                -0.03183998167514801,
                -0.016718586906790733,
                0.02718801237642765,
                0.022625381126999855,
                -0.015232361853122711,
                0.015149010345339775,
                -0.018174713477492332,
                -0.002921925624832511,
                0.009503892622888088,
                -0.0016216172371059656,
                0.00025291703059338033,
                0.06089138984680176,
                -0.04452301189303398,
                0.009181701578199863,
                -0.018199682235717773,
                0.03272561356425285,
                0.022421590983867645,
                -0.008497750386595726,
                -0.01080982107669115,
                0.04400359094142914,
                0.008268051780760288,
                0.010663234628736973,
                -0.07613397389650345,
                0.012673260644078255,
                0.0016278792172670364,
                0.006986299064010382,
                -0.04050757363438606,
                0.0029265566263347864,
                -0.044312044978141785,
                0.04325136914849281,
                -0.02874043583869934,
                -0.033437762409448624,
                -0.04565020278096199,
                0.08388998359441757,
                0.027731552720069885,
                -0.03751244768500328,
                -0.08409304171800613,
                0.010290783829987049,
                0.0035743515472859144,
                0.006550181191414595,
                0.07556717842817307,
                0.03304038196802139,
                -0.017003480345010757,
                0.07438560575246811,
                0.03386974334716797,
                -0.00885019265115261,
                0.017095545306801796,
                -0.06957782059907913,
                -0.01700502261519432,
                0.07555899769067764,
                0.006883630994707346,
                0.02523934841156006,
                -0.03240320459008217,
                0.053565338253974915,
                0.010533110238611698,
                0.02294366993010044,
                0.03587792441248894,
                0.03026638552546501,
                -0.016939710825681686,
                0.02424035780131817,
                -0.025388101115822792,
                0.028210468590259552,
                -0.05083364620804787,
                0.0351799875497818,
                -0.06888963282108307,
                -0.004754296038299799,
                -0.011278503574430943,
                -0.005466071888804436,
                -0.039434220641851425,
                -0.05520511046051979,
                -0.00544803636148572,
                0.0366014800965786,
                0.012286779470741749,
                -0.005768319591879845,
                -0.041899777948856354,
                -0.03406507894396782,
                -0.025926128029823303,
                -0.046543918550014496,
                0.023737406358122826,
                -0.03423314169049263,
                0.003670712234452367,
                -0.04141538217663765,
                -0.028419509530067444,
                0.017367471009492874,
                -0.027355602011084557,
                -0.022332211956381798,
                0.014980842359364033,
                -0.04135431349277496,
                0.006670400500297546,
                0.019313303753733635,
                0.04674111679196358,
                -0.01393807865679264,
                -0.01284155435860157,
                0.020780909806489944,
                2.2358189255555772e-07,
                -0.00642681960016489,
                0.03819578140974045,
                0.040856897830963135,
                0.04203781485557556,
                0.021065838634967804,
                0.03403599187731743,
                -0.022078268229961395,
                -0.031434327363967896,
                -0.017998313531279564,
                0.006782860960811377,
                0.049865201115608215,
                -0.029474565759301186,
                -0.001676215440966189,
                0.03722574561834335,
                0.04511256143450737,
                -0.12294664978981018,
                -0.014730163849890232,
                -0.008261250331997871,
                -0.06514047831296921,
                -0.03310244902968407,
                0.006858536507934332,
                0.03329465538263321,
                0.05817826837301254,
                -0.00011494427599245682,
                -0.005012588109821081,
                -0.016877438873052597,
                -0.010617809370160103,
                0.01905280165374279,
                0.03127400949597359,
                0.02953376993536949,
                0.02809394896030426,
                -0.01801225170493126,
                -0.039969246834516525,
                0.03358609601855278,
                0.03840290382504463,
                -0.08022067695856094,
                0.0517033189535141,
                0.022783836349844933,
                0.025440439581871033,
                0.02000313624739647,
                0.012695005163550377,
                -0.021539079025387764,
                -0.03278366103768349,
                0.05888742208480835,
                -0.06474548578262329,
                0.0025522587820887566,
                -0.0021004751324653625,
                0.06127573549747467,
                0.028266219422221184,
                -0.0023947099689394236,
                0.03980524092912674,
                0.042772747576236725,
                0.0537005178630352,
                0.00883602723479271,
                -0.013674202375113964,
                -0.029283246025443077,
                -0.04297828674316406,
                0.010521719232201576,
                0.054890040308237076,
                0.029747098684310913,
                -0.04167797788977623,
                -0.007549756206572056,
                -0.018549760803580284,
                0.008701415732502937,
                -0.003312797052785754,
                0.03573498874902725,
                -0.008067155256867409,
                4.942453241753658e-35,
                -0.030651070177555084,
                -0.05562421306967735,
                -0.05893056094646454,
                -0.04055287688970566,
                -0.03378883749246597,
                0.013660525903105736,
                -0.08730491995811462,
                -0.0156480073928833,
                -0.010841675102710724,
                0.018977338448166847,
                -0.025762176141142845,
                0.032467592507600784,
                -0.06776800006628036,
                -0.013221646659076214,
                0.0022721022833138704,
                -0.049086276441812515,
                0.02653006836771965,
                -0.017886431887745857,
                0.021437736228108406,
                -0.05896434187889099,
                0.03658934310078621,
                0.11515730619430542,
                0.024856409057974815,
                0.010829627513885498,
                0.04418177157640457,
                -0.009737039916217327,
                -0.043893877416849136,
                0.0021841763518750668,
                0.0035959084052592516,
                0.0278550423681736,
                -0.0017374176532030106,
                -0.004975695163011551,
                0.0017367203254252672,
                -0.03174789994955063,
                -0.013686458580195904,
                -0.045376040041446686,
                -0.08624924719333649,
                0.0068021854385733604,
                -0.027944350615143776,
                -0.0052631753496825695,
                0.004293961450457573,
                0.021001579239964485,
                -0.026406483724713326,
                -0.009780834428966045,
                0.0008355132886208594,
                1.8760975990517181e-06,
                -0.022976886481046677,
                -0.028813425451517105,
                -0.006170356180518866,
                -0.017850158736109734,
                -0.011390529572963715,
                -0.011635848321020603,
                0.026770833879709244,
                -0.0011497060768306255,
                0.05853499099612236,
                -0.0366026945412159,
                -0.0036922707222402096,
                0.02895563282072544,
                0.04689301550388336,
                0.02032303996384144,
                -0.016534917056560516,
                0.005482177250087261,
                -0.0008461525430902839,
                -0.046579014509916306,
                -0.03587963059544563,
                0.019046638160943985,
                -0.08084482699632645,
                0.03394577279686928,
                0.05817358195781708,
                0.09921465069055557,
                -0.05218799039721489,
                -0.015546188689768314,
                -0.002443843986839056,
                0.011974210850894451,
                0.010805835947394371,
                0.025905130431056023,
                0.010197640396654606,
                0.045214828103780746,
                -0.007852209731936455,
                0.02580093964934349,
                -0.012405019253492355,
                0.01634126715362072,
                0.0423383004963398,
                0.001824515056796372,
                0.06485553085803986,
                -0.032386425882577896,
                0.03322266787290573,
                -0.03257744759321213,
                0.04479197785258293,
                -0.049393340945243835,
                -0.029934292659163475,
                -0.022974230349063873,
                0.017940852791070938,
                -0.023214207962155342,
                -0.000778638874180615,
                0.010267410427331924,
                0.06292524933815002,
                -0.04300277680158615,
                -0.036436937749385834,
                0.00020334243890829384,
                -0.012533290311694145,
                -0.034055162221193314,
                -0.04181673377752304,
                0.039189714938402176,
                0.030962729826569557,
                -0.016004111617803574,
                -0.0016948458505794406,
                0.04350253567099571,
                -0.026937784627079964,
                -0.02999882586300373,
                0.009041782468557358,
                0.03148313984274864,
                0.031581852585077286,
                0.0927826389670372,
                0.03467053547501564,
                -0.00891034584492445,
                -0.005405801348388195,
                -0.041180528700351715,
                -0.023125695064663887,
                -0.053053684532642365,
                0.10039795935153961,
                -0.07356784492731094,
                -0.027961211279034615,
                -0.0710625946521759,
                0.0775146633386612,
                0.04323574900627136,
                0.0059621287509799,
                -0.008892577141523361,
                -0.015000627376139164,
                0.0023787766695022583,
                -0.009042073972523212,
                -0.0737602710723877,
                0.011285070329904556,
                -0.037325549870729446,
                0.02520807832479477,
                -0.020535122603178024,
                0.0503324419260025,
                -0.06470542401075363,
                0.022688020020723343,
                -0.02072146348655224,
                0.028545504435896873,
                -0.009077037684619427,
                -0.01389201171696186,
                -0.03838874027132988,
                -0.045912668108940125,
                0.00643161078915,
                0.037152644246816635,
                0.004827949218451977,
                0.03271690756082535,
                -0.07702614367008209,
                -0.050681717693805695,
                0.05374465882778168,
                -0.07997696101665497,
                -0.03920034319162369,
                -0.03746945410966873,
                -0.04750559478998184,
                -0.0260910764336586,
                0.021492300555109978,
                -0.004873240366578102,
                0.009699555113911629,
                -0.022231953218579292,
                0.005588459316641092,
                0.001786601496860385,
                0.001498950645327568,
                0.012805289588868618,
                0.10269051790237427,
                0.07327909022569656,
                0.025520017370581627,
                0.030808553099632263,
                0.009432004764676094,
                -0.032492496073246,
                -0.017671354115009308,
                0.009052643552422523,
                0.04756033420562744,
                -0.034111637622117996,
                0.03951746225357056,
                0.004199380986392498,
                0.06551581621170044,
                -0.010412333533167839,
                -0.028869735077023506,
                0.022864550352096558,
                -0.02341250702738762,
                -0.00520532950758934,
                -0.04228944331407547,
                0.08377554267644882,
                0.02516373246908188,
                0.05221688002347946,
                0.010455640964210033,
                0.028631940484046936,
                -0.062119998037815094,
                -0.01804204471409321,
                -0.011962809599936008,
                -0.019513459876179695,
                0.012931005097925663,
                0.07803923636674881,
                -0.02147301472723484,
                0.031650129705667496,
                -0.037120793014764786,
                0.004306819289922714,
                -0.04418404772877693,
                -0.011961538344621658,
                -0.019027676433324814,
                0.027835017070174217,
                -0.022566597908735275,
                0.00011260818428127095,
                0.026179194450378418,
                0.01590817980468273,
                0.027780607342720032,
                -0.030671048909425735,
                -0.02815118059515953,
                0.02069707028567791,
                -0.005999724846333265,
                -0.03388507291674614,
                0.08217942714691162,
                0.013366324827075005,
                -0.005228162277489901,
                -0.012113960459828377,
                -0.04845067858695984,
                -0.042076073586940765,
                0.04834846034646034,
                -0.07719330489635468,
                0.0230291448533535,
                0.010994747281074524,
                -0.0011732804123312235,
                0.014200909063220024,
                -0.009845578111708164,
                -0.02738756686449051,
                -0.004708583001047373,
                -0.015423009172081947,
                0.006092783994972706,
                -0.009641360491514206,
                -0.03026055172085762,
                0.0673837661743164,
                0.04386858269572258,
                -0.007338047958910465,
                -0.034747179597616196,
                -0.004757953342050314,
                -0.02238466776907444,
                -0.008185234852135181,
                -0.016504254192113876,
                -0.01755577325820923,
                -0.012381475418806076,
                0.056571561843156815,
                0.014235944487154484,
                -0.015113855712115765,
                -0.014633764512836933,
                0.04826686158776283,
                0.052676767110824585,
                -0.002115850569680333,
                0.002579128835350275,
                -0.11395872384309769,
                -0.02447739988565445,
                -0.019040396437048912,
                -0.008675126358866692,
                -0.0001522743987152353,
                0.08211272954940796,
                -0.050646234303712845,
                -0.000815939623862505,
                0.005604587960988283,
                0.016449904069304466,
                -0.038910310715436935,
                -0.0936003029346466,
                0.003955323714762926,
                0.03844257816672325,
                0.00131359719671309,
                0.044521741569042206,
                -0.03545909374952316,
                -0.024222327396273613,
                0.01856415718793869,
                -0.06586134433746338,
                0.006721499375998974,
                -0.023112161085009575,
                -0.014187978580594063,
                -0.02598216012120247,
                -0.020786644890904427,
                0.05654625967144966,
                -0.04216907545924187,
                0.021491792052984238,
                0.04190145805478096,
                -0.014250271022319794,
                0.006820519454777241,
                0.04145557060837746,
                0.009691913612186909,
                0.025005511939525604,
                -0.011325690895318985,
                -0.029084162786602974,
                0.022417433559894562,
                -0.05007712170481682,
                -0.0002247102529508993,
                -0.022000109776854515,
                0.022101137787103653,
                0.04059673845767975,
                -0.005932316184043884,
                -0.027951980009675026,
                -0.023943109437823296,
                -0.022730590775609016,
                -0.014653511345386505,
                0.00929765123873949,
                -0.04280248284339905,
                -0.024264205247163773,
                0.013181780464947224,
                -0.049963537603616714,
                0.025530999526381493,
                0.028397556394338608,
                -0.015637394040822983,
                0.023770852014422417,
                -0.03049054555594921,
                0.003508029505610466,
                0.029367413371801376,
                0.022606872022151947,
                0.016452090814709663,
                -0.010701301507651806,
                -0.016477394849061966,
                0.0008846980636008084,
                -0.025601835921406746,
                -0.011517098173499107,
                0.015605689957737923,
                0.05322905257344246,
                -0.06822048127651215,
                -0.019616959616541862,
                0.06200367584824562,
                0.01710534654557705,
                -0.00961703434586525,
                0.008149110712110996,
                0.069522924721241,
                -0.03800755366683006,
                -0.01717914640903473,
                -0.08801282942295074,
                -0.03587092086672783,
                0.029216663911938667,
                0.05204860493540764,
                -0.010505144484341145,
                -0.003371603088453412,
                0.06579366326332092,
                0.05888098105788231,
                0.013452555052936077,
                -0.05640421435236931,
                0.012664354406297207,
                0.007609629537910223,
                0.028902316465973854,
                -0.05432429537177086,
                0.044272538274526596,
                -0.04768385365605354,
                -0.03488019108772278,
                0.01157392468303442,
                -0.03681930527091026,
                0.06474901735782623,
                -0.020399093627929688,
                -0.028796272352337837,
                -0.03501347452402115,
                -0.007723615504801273,
                -0.007327598053961992,
                -0.05689500272274017,
                -0.023755282163619995,
                0.00217245239764452,
                0.018313858658075333,
                -0.020291754975914955,
                -0.00030788392177782953,
                0.014909796416759491,
                -0.05524434521794319,
                -0.012913782149553299,
                0.048740729689598083,
                0.010399787686765194,
                -0.0029786680825054646,
                -0.04650929570198059,
                -0.010337857529520988,
                -0.022915862500667572,
                -0.02620343305170536,
                0.042169179767370224,
                0.029211167246103287,
                -0.043492790311574936,
                0.04539741203188896,
                0.046655066311359406,
                0.08642839640378952,
                0.025200320407748222,
                0.006077614147216082,
                0.0675462856888771,
                0.034944694489240646,
                0.018035126850008965,
                -0.057437215000391006,
                -0.0004293161618988961,
                -0.013775119557976723,
                0.012481874786317348,
                -0.032828234136104584,
                0.03963354602456093,
                0.06305532157421112,
                0.05556727200746536,
                0.014031585305929184,
                -0.08329372107982635,
                -0.07420904189348221,
                -0.010751914232969284,
                0.042945586144924164,
                -0.06303253769874573,
                0.03815891221165657,
                0.04025372117757797,
                -0.01594679243862629,
                -0.011381621472537518,
                -0.015517976135015488,
                -0.02973923087120056,
                -0.05714961141347885,
                0.03227263689041138,
                0.02460552752017975,
                0.010956530459225178,
                -0.07258697599172592,
                0.017323359847068787,
                -0.013076769188046455,
                -0.004114265087991953,
                -0.03771959990262985,
                0.02706534042954445,
                -0.023314280435442924,
                0.043974023312330246,
                0.007075380999594927,
                0.057694870978593826,
                0.014539258554577827,
                0.012951791286468506,
                -0.01784341037273407,
                -0.04456721618771553,
                0.026798062026500702,
                0.023623397573828697,
                0.02006906270980835,
                -0.03740471974015236,
                0.00995485670864582,
                0.06704730540513992,
                0.015689102932810783,
                -0.027213875204324722,
                -0.014439905062317848,
                -0.0301378034055233,
                -0.002798306057229638,
                -0.01701492816209793,
                0.014710885472595692,
                -0.051541004329919815,
                -0.025532305240631104,
                0.043402958661317825,
                0.02707776613533497,
                0.005388728808611631,
                0.06197498366236687,
                -0.009509673342108727,
                0.06538631021976471,
                0.030943065881729126,
                -0.00038681854493916035,
                -0.009821782819926739,
                0.04398169741034508,
                -0.007649112492799759,
                0.00016815069830045104,
                0.03358417749404907,
                0.05090531334280968,
                -0.030070584267377853,
                0.007551048882305622,
                0.06863615661859512,
                0.048314861953258514,
                -0.024295520037412643,
                0.0006606192328035831,
                0.06297087669372559,
                0.11898891627788544,
                0.025409463793039322,
                -0.032410476356744766,
                0.004340615123510361,
                0.05261604115366936,
                -0.015792954713106155,
                -0.01817476749420166,
                0.057506341487169266,
                0.06707929074764252,
                -0.031092654913663864,
                0.04788171872496605,
                -0.06296110898256302,
                0.00781666487455368,
                -0.02498745545744896,
                -0.030908236280083656,
                -0.04322129115462303,
                -0.02458290383219719,
                -0.017602309584617615,
                0.049867819994688034,
                0.05017497390508652,
                -0.05422811210155487,
                -0.0024417468812316656,
                0.012784935534000397,
                -0.08992230892181396,
                -0.04612156003713608,
                -0.000824551738332957,
                0.013132283464074135,
                -0.013717327266931534,
                -0.016202179715037346,
                0.022031277418136597,
                -0.02654346264898777,
                0.04340696334838867,
                0.028753984719514847,
                0.0006492888205684721,
                0.0007233198848553002,
                -0.11233104765415192,
                0.060106076300144196,
                0.012300175614655018,
                0.009345571510493755,
                -0.014088662341237068,
                0.030096963047981262,
                -0.03048848733305931,
                -0.03091888315975666,
                0.01018492691218853,
                0.013833966106176376,
                -0.06743200868368149,
                0.009977609850466251,
                -0.020953267812728882,
                -0.016550689935684204,
                0.01771990768611431,
                0.016543367877602577,
                0.007805919740349054,
                0.022163324058055878,
                -0.02440885454416275,
                -0.005121185909956694,
                0.05295119807124138,
                0.02368895523250103,
                -0.04427338019013405,
                0.0146440789103508,
                0.02162192575633526,
                0.001649849466048181,
                -0.03237638622522354,
                0.0012853327207267284,
                0.044416964054107666,
                0.01180348265916109,
                0.05968324840068817,
                0.001928497920744121,
                0.004816235508769751,
                -0.033352263271808624,
                -0.03678616136312485,
                -0.02252713032066822,
                -0.14617964625358582,
                0.03754333779215813,
                0.047078702598810196,
                -0.0245203897356987,
                -0.037315674126148224,
                -0.06142907962203026,
                0.002195795765146613,
                -0.005912520922720432,
                0.023224622011184692,
                -0.011701316572725773,
                -0.029074255377054214,
                0.029417164623737335,
                0.05155697837471962,
                0.04777737706899643,
                0.017846768721938133,
                -0.04965067282319069,
                0.03900248557329178,
                -0.08938410878181458,
                0.01730806566774845,
                -0.029197709634900093,
                0.012135322205722332,
                -0.023290343582630157,
                0.06454692780971527,
                0.00876291561871767,
                0.03642481192946434,
                0.026698442175984383,
                0.029393281787633896,
                -0.05019116401672363,
                -0.001110797980800271,
                -0.057374127209186554,
                0.017229516059160233,
                0.045735567808151245,
                0.03090391494333744,
                -0.021445227786898613,
                0.003316937480121851,
                0.025400223210453987,
                -0.03871387243270874,
                -0.00548592722043395,
                -0.0026974393986165524,
                -0.012470480054616928,
                0.003939979709684849,
                0.01590561680495739,
                -6.539447724357162e-33,
                -0.026680197566747665,
                -0.07499188184738159,
                0.02822151966392994,
                0.03113093040883541,
                -0.05787208303809166,
                -0.029542040079832077,
                -0.00273722130805254,
                0.02779264748096466,
                -0.029124664142727852,
                -0.01758883334696293,
                -0.03677072003483772,
                0.02484722249209881,
                0.0478646419942379,
                -0.04795188829302788,
                0.005366095341742039,
                0.016267001628875732,
                -0.01157291978597641,
                0.02250383049249649,
                -0.0028994556050747633,
                -0.05866360664367676,
                0.01155376248061657,
                0.02790031209588051,
                0.027686750516295433,
                -0.015636418014764786,
                0.01302953902631998,
                0.011428026482462883,
                -0.02324429526925087,
                3.203025698894635e-06,
                -0.018823694437742233,
                -0.014504625461995602,
                0.008173258975148201,
                -0.04727083072066307,
                -0.019947532564401627,
                -0.0327533483505249,
                -0.007785412482917309,
                0.06257417798042297,
                -0.03325365111231804,
                -0.057815760374069214,
                0.021408019587397575,
                0.02422233670949936,
                -0.03887766972184181,
                -0.031690049916505814,
                -0.018189894035458565,
                0.008136384189128876,
                0.03389425203204155,
                -0.03215619549155235,
                0.006693946197628975,
                -0.02446504682302475,
                0.04894183203577995,
                0.07032027095556259,
                -0.04804154112935066,
                0.0005808087880723178,
                -0.0572701133787632,
                -0.04122919216752052,
                0.05072773993015289,
                -0.008987975306808949,
                -0.022899260744452477,
                -0.049905430525541306,
                -0.021569866687059402,
                0.06260017305612564,
                0.009641166776418686,
                0.023736506700515747,
                -0.026774471625685692,
                -0.045274391770362854,
                -0.016516713425517082,
                -0.029188450425863266,
                -0.051736414432525635,
                -0.05070893093943596,
                -0.03582175821065903,
                -0.05249838903546333,
                -0.013294131495058537,
                0.050512995570898056,
                0.0388486422598362,
                -0.028146978467702866,
                0.014686803333461285,
                -0.0675518661737442,
                -0.0032706393394619226,
                -0.028713488951325417,
                0.0456509068608284,
                0.051865071058273315,
                0.009759128093719482,
                0.024306530132889748,
                -0.03110266849398613,
                0.0016210833564400673,
                -0.034379322081804276,
                -0.012180708348751068,
                -0.03192632272839546,
                0.004261781927198172,
                -0.013364871963858604,
                0.010479938238859177,
                -0.03843430057168007,
                0.00669277785345912,
                -0.0013704290613532066,
                0.009155452251434326,
                0.011268554255366325,
                -0.02250836044549942,
                0.03303062170743942,
                0.024821218103170395,
                -0.02743426524102688,
                -0.017379656434059143,
                -0.07744622975587845,
                0.012085486203432083,
                -0.0526273213326931,
                0.005914843641221523,
                0.041820790618658066,
                0.006756295450031757,
                -0.004159917123615742,
                0.032079942524433136,
                -0.02425464428961277,
                0.008924822323024273,
                -0.007162965834140778,
                0.03174176812171936,
                0.0396488793194294,
                0.007202833890914917,
                0.006260693538933992,
                -0.0164334699511528,
                -0.0052152350544929504,
                -0.06698425114154816,
                -0.02377455122768879,
                -0.020960118621587753,
                -0.027075760066509247,
                0.07668926566839218,
                -0.07050437480211258,
                0.022829728201031685,
                0.03327829763293266,
                -0.02212144434452057,
                0.009619265794754028,
                0.03999971225857735,
                -0.0073533011600375175,
                -0.037407584488391876,
                -0.018156198784708977,
                -0.01716722548007965,
                2.460689074723632e-07,
                -0.03736679255962372,
                0.012830965220928192,
                0.02430461160838604,
                0.032526060938835144,
                0.01785123161971569,
                0.0009780296823009849,
                -0.09019207209348679,
                0.018224848434329033,
                0.04112778976559639,
                -0.017619602382183075,
                0.03283558785915375,
                0.016711661592125893,
                0.011209219694137573,
                0.028912032023072243,
                0.06659476459026337,
                -8.079369581537321e-05,
                0.006971635855734348,
                -0.03267773240804672,
                0.016013234853744507,
                -0.003509989706799388,
                -0.00462255347520113,
                -0.0177027378231287,
                0.04659067094326019,
                0.0053435214795172215,
                -0.025744318962097168,
                -0.030916977673768997,
                0.02776399999856949,
                -0.015598452650010586,
                0.05905797705054283,
                -0.019653325900435448,
                0.005773460492491722,
                0.014338056556880474,
                0.0027076273690909147,
                0.023125242441892624,
                0.016312461346387863,
                -0.04097631201148033,
                0.023963935673236847,
                0.04287198185920715,
                0.05495915189385414,
                0.06332185864448547,
                -0.009821767918765545,
                0.033132605254650116,
                -0.015081369318068027,
                0.026882542297244072,
                0.015093596652150154,
                -0.04780874028801918,
                -0.02009948529303074,
                0.02953222766518593,
                0.0028504778165370226,
                -0.03800513595342636,
                0.003076049266383052,
                0.018094873055815697,
                0.01324569620192051,
                0.07965172082185745,
                0.014025053009390831,
                -0.011827533133327961,
                0.011299273930490017,
                -0.009170819073915482,
                0.02962176874279976,
                0.05369134247303009,
                -0.029537124559283257,
                0.008821608498692513,
                0.022385437041521072,
                0.018886296078562737,
                0.012488869950175285,
                0.02041514404118061,
                -0.06830290704965591,
                1.3750643634106222e-34,
                -0.008224100805819035,
                -0.021484550088644028,
                -0.0033263382501900196,
                -0.023781705647706985,
                0.01194226834923029,
                -0.0248770359903574,
                -0.03662159666419029,
                -0.020235702395439148,
                0.04618818685412407,
                0.04025492072105408,
                -0.028166279196739197,
                0.018381668254733086,
                0.04342448338866234,
                -0.02211470901966095,
                0.0002537981199566275,
                -0.028149791061878204,
                -0.03528537228703499,
                -0.02143491618335247,
                -0.03770109638571739,
                -0.07121681421995163,
                0.014904573559761047,
                0.07827585935592651,
                0.008876209147274494,
                -0.011785712093114853,
                0.0785800889134407,
                -0.02495887316763401,
                -0.06208249181509018,
                -0.0239462498575449,
                -0.01172337681055069,
                -0.045668452978134155,
                -0.0007684384472668171,
                -0.013999239541590214,
                -0.009838398545980453,
                -0.022307055070996284,
                0.007326734717935324,
                -0.05682133510708809,
                0.01295365858823061,
                -0.08935309201478958,
                0.04353606328368187,
                1.2307614269957412e-05,
                -0.04255374148488045,
                0.0035187460016459227,
                0.041875798255205154,
                -0.029868463054299355,
                -0.023357100784778595,
                1.4369124983204529e-06,
                0.019013626500964165,
                -0.03617720305919647,
                -0.04301628842949867,
                -0.0036938649136573076,
                0.036795880645513535,
                -0.0021527966018766165,
                -0.034781280905008316,
                0.02756001241505146,
                0.011234987527132034,
                -0.01468648575246334,
                0.03969518095254898,
                0.013459809124469757,
                0.02452702634036541,
                -0.023582858964800835,
                -0.04860484600067139,
                0.008050497621297836,
                -0.04432109370827675,
                -0.07109643518924713,
                -0.03259364515542984,
                -0.03783269226551056,
                -0.061570607125759125,
                0.004137007053941488,
                -0.013402892276644707,
                0.07315381616353989,
                0.04108189046382904,
                0.00936615839600563,
                0.00847452413290739,
                0.011047097854316235,
                0.010304943658411503,
                0.06980829685926437,
                -0.023429693654179573,
                0.0070030223578214645,
                -0.03207855299115181,
                -0.014616346918046474,
                -0.0008015964995138347,
                0.03526180237531662,
                0.045216891914606094,
                0.01304188184440136,
                -0.011576447635889053,
                -0.042222585529088974,
                -0.05912785604596138,
                -0.028323939070105553,
                -0.01912451907992363,
                -0.014641087502241135,
                -0.02517743408679962,
                -0.025657497346401215,
                -0.02679637260735035,
                -0.032745007425546646,
                0.0023847799748182297,
                0.00731880497187376,
                0.08119990676641464,
                -0.021583251655101776,
                -0.004128229804337025,
                -0.014449569396674633,
                0.05153907090425491,
                0.01366428192704916,
                -0.053345486521720886,
                0.0595361702144146,
                0.0398227721452713,
                -0.06719454377889633,
                -0.001547417021356523,
                0.04524994269013405,
                0.023000221699476242,
                0.01536513864994049,
                0.0164086502045393,
                0.012478865683078766,
                0.019286878407001495,
                0.018400276079773903,
                0.04140026122331619,
                0.036578770726919174,
                0.049046315252780914,
                -0.045944664627313614,
                -0.03168604522943497,
                -0.043886952102184296,
                0.051064953207969666,
                -0.03431925177574158,
                0.014559958130121231,
                -0.04901973158121109,
                0.041744962334632874,
                -0.07681068778038025,
                0.005059470888227224,
                -0.038472309708595276,
                0.01987254060804844,
                0.03361697122454643,
                0.028311144560575485,
                0.05047903209924698,
                0.022452330216765404,
                -0.006431478541344404,
                -0.010791643522679806,
                -0.043553225696086884,
                -0.0347747839987278,
                -0.08254142850637436,
                0.01580962911248207,
                -0.04723503813147545,
                0.03107295371592045,
                0.022013243287801743,
                -0.015478494577109814,
                0.01507567334920168,
                0.002658535959199071,
                -0.01571653038263321,
                0.07056169956922531,
                0.008661343716084957,
                -0.001183400279842317,
                -0.03572479635477066,
                -0.02633517235517502,
                0.013008923269808292,
                -0.007416379172354937,
                -0.026858339086174965,
                -0.019039155915379524,
                -0.053856849670410156,
                -0.019512338563799858,
                -0.05737156793475151,
                0.07906483113765717,
                0.024721192196011543,
                -0.00455087935552001,
                -0.017373278737068176,
                -0.006985718384385109,
                0.02213764376938343,
                0.021254317834973335,
                0.0379166454076767,
                0.05226261168718338,
                -0.021601950749754906,
                0.033733513206243515,
                -0.0054010068997740746,
                0.07998247444629669,
                -0.03115374781191349,
                0.049386922270059586,
                0.032090745866298676,
                0.021933268755674362,
                -0.04458864405751228,
                -0.01995125040411949,
                0.07166165113449097,
                0.014137308113276958,
                0.005046493839472532,
                -0.009776072576642036,
                -0.10594573616981506,
                0.02753603830933571,
                0.01231884490698576,
                0.07688787579536438,
                0.028871934860944748,
                -0.031878866255283356,
                0.07214327901601791,
                -0.012451691552996635,
                -0.03544757887721062,
                0.05129478871822357,
                -0.020855534821748734,
                -0.09225448966026306,
                0.016116827726364136,
                0.08798322081565857,
                0.0279056616127491,
                -0.016856973990797997,
                -0.04768780618906021,
                0.03098401241004467,
                -0.0290197916328907,
                0.01688307709991932,
                -0.0037592705339193344,
                -0.01565156877040863,
                -0.06345005333423615,
                -0.016168484464287758,
                0.012219676747918129,
                0.0022585063707083464,
                -0.01674206182360649,
                -0.03148508816957474,
                -0.027053192257881165,
                0.009654225781559944,
                0.01036893855780363,
                0.03604720160365105,
                0.016900403425097466,
                0.008141322061419487,
                -0.004808994475752115,
                -0.05328560248017311,
                -0.04792408272624016,
                0.014484643936157227,
                -0.04578588157892227,
                -0.012879825197160244,
                0.05093352124094963,
                -0.031228890642523766,
                0.03563545644283295,
                0.020626960322260857,
                -0.0374600887298584,
                0.004298259504139423,
                -0.005996564403176308,
                -0.013273987919092178,
                -0.059240322560071945,
                -0.007461812812834978,
                -0.03576963022351265,
                0.041505180299282074,
                0.024989254772663116,
                0.04313226789236069,
                -0.00042551246588118374,
                -0.020190706476569176,
                0.026194442063570023,
                0.008442944847047329,
                0.06793728470802307,
                0.04043760523200035,
                0.003044100245460868,
                0.016256170347332954,
                0.03098052181303501,
                -0.03358624875545502,
                0.006980253849178553,
                -0.01513474527746439,
                0.07201291620731354,
                0.023013725876808167,
                -0.008072043769061565,
                -0.053983818739652634,
                0.012192582711577415,
                -0.06001860648393631,
                -0.01901552453637123,
                -0.018501562997698784,
                0.04456888511776924,
                0.018106114119291306,
                0.00743673462420702,
                0.04507134109735489,
                0.03825458511710167,
                -0.04113221913576126,
                -0.06699598580598831,
                -0.05265532061457634,
                0.02685425989329815,
                0.02951463684439659,
                0.026391131803393364,
                0.06401395052671432,
                -0.04869027063250542,
                0.04660070687532425,
                -0.03446199372410774,
                -0.021871043369174004,
                0.044725142419338226,
                0.0076128472574055195,
                -0.03447727486491203,
                -0.028465643525123596,
                0.04743253439664841,
                -0.013559823855757713,
                -0.011470346711575985,
                -0.0752042755484581,
                -0.05738311633467674,
                -0.0032957205548882484,
                -0.00662927096709609,
                -0.009384936653077602,
                0.014308488927781582,
                -0.00736579392105341,
                -0.061073850840330124,
                0.029809610918164253,
                0.020504120737314224,
                -0.04711781442165375,
                -0.04027309641242027,
                0.004756253678351641,
                0.05716371163725853,
                -0.006153677124530077,
                -0.05027958005666733,
                0.018229225650429726,
                0.01189452689141035,
                -0.022988449782133102,
                0.05789998173713684,
                -0.08194150030612946,
                -0.0432591438293457,
                0.05398405343294144,
                -0.022348619997501373,
                -0.04621214047074318,
                0.021480850875377655,
                -0.014655496925115585,
                -0.008688520640134811,
                0.014122555032372475,
                0.041706692427396774,
                -0.04078848659992218,
                -0.04124169424176216,
                -0.015564821660518646,
                0.03241752088069916,
                -0.055023472756147385,
                -0.007311966735869646,
                -0.012447609566152096,
                -0.015268152579665184,
                0.04017074033617973,
                0.05627485364675522,
                0.005186060443520546,
                0.021001296117901802,
                0.024798540398478508,
                -0.012603207491338253,
                0.026945026591420174,
                -0.016704389825463295,
                0.02431425265967846,
                -0.004533759318292141,
                0.005060424096882343,
                -0.0013363620964810252,
                -0.012731998227536678,
                0.030046530067920685,
                0.10519442707300186,
                -0.01724623702466488,
                -0.016490453854203224,
                -0.00471844058483839,
                -0.0008720833575353026,
                -0.003979124128818512,
                -0.0023380008060485125,
                -0.006422265898436308,
                -0.027971042320132256,
                0.02359972707927227,
                0.002059894148260355,
                -0.003135612001642585,
                -0.059621647000312805,
                -0.003386831609532237,
                -0.04708174616098404,
                0.006041050888597965,
                -0.00781407579779625,
                0.022622184827923775,
                -0.023050934076309204,
                -0.036088019609451294,
                -0.0069342972710728645,
                -0.0222203079611063,
                -0.05292963236570358,
                -0.07388070225715637,
                0.008084242232143879,
                -0.0032663382589817047,
                0.022661756724119186,
                0.05938908830285072,
                0.036640554666519165,
                0.005769689101725817,
                0.03140047565102577,
                0.040667835623025894,
                0.020386068150401115,
                -0.026816992089152336,
                0.009285848587751389,
                0.014884321950376034,
                0.0049662720412015915,
                -0.00959024578332901,
                0.020787635818123817,
                -0.00047966212150640786,
                0.048997968435287476,
                -0.052171554416418076,
                -0.018547020852565765,
                0.04755616933107376,
                0.03236890956759453,
                -0.02006680704653263,
                0.0019144825637340546,
                0.0411495678126812,
                0.02185119315981865,
                0.001079495414160192,
                0.04211867228150368,
                0.008876983076334,
                0.06062145531177521,
                0.011522388085722923,
                -0.016338547691702843,
                0.036765292286872864,
                -0.013799235224723816,
                0.010516935959458351,
                -0.0059020849876105785,
                -0.028935691341757774,
                0.005395206622779369,
                0.023256974294781685,
                0.000181438255822286,
                0.04596683755517006,
                0.005332972388714552,
                -0.0029211558867245913,
                -0.018442373722791672,
                -0.04394511133432388,
                -0.04216846823692322,
                0.021886341273784637,
                0.034599144011735916,
                -0.03377087041735649,
                -0.0010448761750012636,
                0.0058629210107028484,
                -0.011810259893536568,
                0.023202411830425262,
                -0.04670218378305435,
                -0.09506303817033768,
                -0.00118580786511302,
                0.02354545332491398,
                0.0004384397470857948,
                0.0050393943674862385,
                -0.055773161351680756,
                0.011171308346092701,
                -0.025227850303053856,
                0.011662562377750874,
                -0.04809308424592018,
                0.06822659820318222,
                0.03390297293663025,
                0.022708872333168983,
                0.033623527735471725,
                -0.005142939276993275,
                0.0395515002310276,
                0.016879718750715256,
                -0.04009220376610756,
                0.012915986590087414,
                0.04107381030917168,
                -0.02863655425608158,
                -0.007769130636006594,
                0.0022475363221019506,
                -0.044304050505161285,
                -0.017063964158296585,
                0.07531170547008514,
                0.021332761272788048,
                -0.04398735240101814,
                0.08693947643041611,
                -0.018194254487752914,
                0.03630317375063896,
                0.06584073603153229,
                -0.026286110281944275,
                -0.03476852551102638,
                -0.03224775195121765,
                -0.008796109817922115,
                -0.010858225636184216,
                0.03873565420508385,
                -0.024487711489200592,
                0.039673514664173126,
                0.01287868246436119,
                -0.03213614597916603,
                0.1047421246767044,
                -0.0062060668133199215,
                0.02228614315390587,
                0.08156295120716095,
                0.06054959446191788,
                0.03636985644698143,
                -0.011603445746004581,
                0.017638351768255234,
                0.0297636017203331,
                0.0022260951809585094,
                -0.06320618838071823,
                0.08099260181188583,
                0.03874879330396652,
                0.008173505775630474,
                -0.020939527079463005,
                -0.021600160747766495,
                -0.050490718334913254,
                0.02538876049220562,
                -0.009930039756000042,
                -0.042460180819034576,
                -0.02612229809165001,
                0.020319607108831406,
                0.050308145582675934,
                0.028678467497229576,
                -0.08005814999341965,
                0.0047905342653393745,
                -0.014343569986522198,
                -0.00952561292797327,
                -0.07032813131809235,
                0.031121941283345222,
                -0.0312684066593647,
                -0.04321209341287613,
                0.0790991559624672,
                0.0005559456767514348,
                -0.008024114184081554,
                0.0202309712767601,
                0.013628985732793808,
                0.024809718132019043,
                0.04862258955836296,
                -0.06140967085957527,
                5.116613124300784e-07,
                -0.013810032978653908,
                0.05833302438259125,
                0.04303446412086487,
                -0.0014689478557556868,
                -0.022397534921765327,
                -0.027230240404605865,
                0.01278624776750803,
                0.0070427036844193935,
                -0.005921420641243458,
                -0.02069525606930256,
                0.014806880615651608,
                -0.009315183386206627,
                -0.024082042276859283,
                0.029527178034186363,
                0.002971985610201955,
                -0.0153696583583951,
                -0.01158110424876213,
                0.04754077270627022,
                0.006252162158489227,
                0.06512556970119476,
                -0.04843587800860405,
                0.0408853217959404,
                0.03945317491889,
                0.0024161115288734436,
                -0.04167003929615021,
                -0.0003716901410371065,
                0.023042138665914536,
                0.0355600081384182,
                -0.00348807405680418,
                -0.02571670524775982,
                -0.0017987793544307351,
                -0.01783517748117447,
                -0.05015236511826515,
                0.03273063153028488,
                -0.11448771506547928,
                0.03204658627510071,
                0.02465820126235485,
                -0.012425638735294342,
                0.03490462154150009,
                -0.05372995883226395,
                -0.051986195147037506,
                0.03212038427591324,
                -0.010376612655818462,
                0.0020917453803122044,
                -0.02392280474305153,
                0.02159137651324272,
                -0.012871216051280499,
                0.04505529999732971,
                -0.008972964249551296,
                -0.0407433845102787,
                0.026874685660004616,
                -0.01707059144973755,
                -0.04289698600769043,
                -0.07313603907823563,
                -0.015439963899552822,
                -0.03278249874711037,
                0.061953868716955185,
                0.008725026622414589,
                0.07767082005739212,
                -0.03863587975502014,
                0.011394767090678215,
                -0.06683602929115295,
                0.03543948754668236,
                0.007388889789581299,
                -0.01794370263814926,
                0.01555855292826891,
                -0.0270677600055933,
                0.004904980305582285,
                -0.03895088657736778,
                0.01741931028664112,
                0.027129368856549263,
                0.01272666547447443,
                -0.027661878615617752,
                -0.0085368063300848,
                -0.0028360725846141577,
                -0.012156789191067219,
                -5.7996158245206004e-33,
                -0.018760599195957184,
                -0.06005694717168808,
                0.04618094488978386,
                -0.019997991621494293,
                0.017179260030388832,
                0.01016143150627613,
                0.02318839728832245,
                0.02769542671740055,
                -0.031070837751030922,
                -0.04991735890507698,
                -0.02732165716588497,
                -0.01630125567317009,
                0.01909058354794979,
                -0.01213186327368021,
                0.07594404369592667,
                0.024513034150004387,
                -0.0041966973803937435,
                0.012938673608005047,
                -0.019276781007647514,
                -0.07099892199039459,
                -0.035330988466739655,
                0.020359810441732407,
                0.026550903916358948,
                0.014803526923060417,
                -0.054252371191978455,
                0.026249652728438377,
                0.029017260298132896,
                0.06147930026054382,
                0.05483770743012428,
                -0.01069810800254345,
                -0.00011006929707946256,
                -0.039949435740709305,
                0.008216089569032192,
                0.010065020993351936,
                -0.01729373075067997,
                0.01258085761219263,
                -0.006233801133930683,
                -0.04761926829814911,
                -0.0145350256934762,
                0.010612682439386845,
                0.0014509680913761258,
                -0.010508653707802296,
                -0.01878969371318817,
                0.014299605041742325,
                -0.02115880325436592,
                -0.022116323933005333,
                0.005309644620865583,
                0.021402087062597275,
                0.02188374660909176,
                0.08472026884555817,
                -0.05145663768053055,
                0.0029975997749716043,
                -0.012900467962026596,
                0.03436576947569847,
                0.05238746851682663,
                0.027985617518424988,
                0.0012244326062500477,
                0.010005075484514236,
                0.05159251391887665,
                0.0046880668960511684,
                -0.07426667213439941,
                0.005344732198864222,
                0.02296978235244751,
                -0.02481384202837944,
                -0.032248686999082565,
                0.029478931799530983,
                0.0015373342903330922,
                0.025638211518526077,
                -0.02950887382030487,
                -0.06118450686335564,
                -0.06846699863672256,
                0.07695727050304413,
                0.022583000361919403,
                -0.026749063283205032,
                -0.041191816329956055,
                0.024781104177236557,
                -2.0213553852954647e-06,
                -0.011339353397488594,
                0.056800637394189835,
                0.06813651323318481,
                0.007526312954723835,
                0.04368523135781288,
                0.027882441878318787,
                0.0031977901235222816,
                0.003537018084898591,
                -0.05935481935739517,
                -0.024630526080727577,
                0.056784167885780334,
                -0.006988100707530975,
                0.02706584520637989,
                -0.005242191720753908,
                0.09492655098438263,
                0.00848541222512722,
                0.015774153172969818,
                0.0843505933880806,
                0.054083451628685,
                -0.02955676056444645,
                0.004859131760895252,
                -0.02121049165725708,
                0.054533522576093674,
                -0.07188640534877777,
                0.0011707431403920054,
                -0.04425932839512825,
                -0.01507400069385767,
                -0.02209923043847084,
                -0.006570127326995134,
                -0.07130571454763412,
                -0.014019584283232689,
                0.018057357519865036,
                0.00016457615129183978,
                -0.00914026703685522,
                -0.015028226189315319,
                -0.016202786937355995,
                -0.01722576469182968,
                0.021730825304985046,
                -0.03154217079281807,
                -0.020697062835097313,
                -0.055902149528265,
                0.0009518328006379306,
                -0.05509261414408684,
                0.030559087172150612,
                -0.03184123709797859,
                0.005023419391363859,
                -0.00843000691384077,
                0.0026174657978117466,
                -0.049956995993852615,
                0.016971804201602936,
                -0.010298626497387886,
                0.018447017297148705,
                -0.022406063973903656,
                -0.039645567536354065,
                0.0340239554643631,
                2.1647915104949789e-07,
                0.004513493273407221,
                0.04187800735235214,
                0.03138001263141632,
                0.06681762635707855,
                -0.016398699954152107,
                -0.016090117394924164,
                0.010613135062158108,
                -0.013358591124415398,
                -0.05980067700147629,
                0.00499221496284008,
                0.04409957304596901,
                -0.02924412302672863,
                0.005370138678699732,
                0.03044428490102291,
                -0.006771337240934372,
                -0.10612892359495163,
                0.008617154322564602,
                -0.008432581089437008,
                -0.045469533652067184,
                -0.031189503148198128,
                0.025279663503170013,
                0.048175957053899765,
                0.03577645123004913,
                0.002884132554754615,
                0.006815695203840733,
                -0.00429369043558836,
                -0.03566289320588112,
                -0.018164584413170815,
                0.0372348390519619,
                0.031806230545043945,
                0.03571445867419243,
                -0.050709910690784454,
                -0.005435043480247259,
                0.04810056835412979,
                0.019932035356760025,
                -0.06322953850030899,
                0.06796323508024216,
                0.019229533150792122,
                0.03335978090763092,
                -0.021231940016150475,
                -0.010635502636432648,
                -0.02659248746931553,
                -0.03857014328241348,
                0.0380764976143837,
                -0.04700188338756561,
                0.01676822267472744,
                0.008405045606195927,
                0.0894942432641983,
                0.02624487690627575,
                -0.006926913280040026,
                0.015908414497971535,
                0.04142490774393082,
                0.08245833218097687,
                0.029717467725276947,
                -0.012104739435017109,
                -0.026207217946648598,
                -0.008439486846327782,
                0.02549990825355053,
                0.044780243188142776,
                0.04797338321805,
                -0.03895469382405281,
                -0.024410586804151535,
                -0.03282798454165459,
                0.032225944101810455,
                0.02239333838224411,
                0.013226312585175037,
                -0.015676699578762054,
                8.508621855941494e-35,
                -0.04773496091365814,
                -0.05565410852432251,
                -0.04727388173341751,
                -0.007554277777671814,
                -0.043871503323316574,
                0.03051469288766384,
                -0.051838431507349014,
                -0.03289838507771492,
                0.003973967861384153,
                -0.0025981173384934664,
                -0.03369089215993881,
                0.03978630155324936,
                -0.05370776355266571,
                -0.009204953908920288,
                -0.05780911445617676,
                0.033155590295791626,
                -0.019530639052391052,
                -0.01615418866276741,
                0.010242709890007973,
                -0.020741460844874382,
                0.027474796399474144,
                -0.03965871408581734,
                0.09070616960525513,
                0.05030173435807228,
                0.15141268074512482,
                -0.0061098928563296795,
                -0.01160773541778326,
                0.017942428588867188,
                -0.0011134117376059294,
                -0.06638815999031067,
                -0.006923402193933725,
                0.02187107875943184,
                0.006162608042359352,
                0.02022138424217701,
                0.044611603021621704,
                0.009389455430209637,
                -0.02480361983180046,
                -0.0506390705704689,
                0.047834962606430054,
                -0.02398798242211342,
                -0.005379538983106613,
                -0.0022120014764368534,
                -0.027253972366452217,
                -0.02519133873283863,
                0.029920335859060287,
                1.399716211381019e-06,
                -0.02119603008031845,
                -0.05283796414732933,
                0.017882777377963066,
                0.0003294490452390164,
                0.04805782437324524,
                -0.025904672220349312,
                0.031047755852341652,
                0.004884812980890274,
                0.051810428500175476,
                -0.016815034672617912,
                0.07235682755708694,
                0.020793816074728966,
                -0.008789502084255219,
                -0.026796452701091766,
                -0.007304667495191097,
                -0.014459410682320595,
                -0.013228142634034157,
                -0.021821752190589905,
                -0.03724377974867821,
                -0.07662356644868851,
                -0.04699843004345894,
                0.0023255872074514627,
                0.0299399271607399,
                0.07012058049440384,
                -0.013848424889147282,
                0.0026071215979754925,
                -0.05301176756620407,
                0.004048540256917477,
                -0.006594337988644838,
                0.05448917672038078,
                0.00409688288345933,
                0.008103753440082073,
                -0.03577209636569023,
                -0.0552411749958992,
                -0.03840412199497223,
                0.049838338047266006,
                -0.02874833717942238,
                -0.015179400332272053,
                0.0008091598283499479,
                -0.0021863800939172506,
                -0.011956308037042618,
                0.04635442793369293,
                -0.032203588634729385,
                -0.014816869981586933,
                0.030329719185829163,
                0.034477997571229935,
                0.02746913954615593,
                -0.04526381194591522,
                -0.03828822821378708,
                -0.029998186975717545,
                -0.003437500447034836,
                -0.0011479001259431243,
                -0.008858253248035908,
                0.06061996519565582,
                -0.05656211078166962,
                -0.003478327998891473,
                -0.02987154759466648,
                0.0012431489303708076,
                -0.011926871724426746,
                -0.06418955326080322,
                -0.025099415332078934,
                -0.015408015809953213,
                0.039823971688747406,
                0.02385815419256687,
                -0.06642252951860428,
                -0.027994612231850624,
                -0.012038365937769413,
                0.04859878867864609,
                -0.0006617079488933086,
                0.068547822535038,
                0.005948923993855715,
                0.027501026168465614,
                0.01791396178305149,
                -0.012609700672328472,
                -0.016022512689232826,
                0.020822349935770035,
                0.0024780340027064085,
                0.00566140515729785,
                0.06310652941465378,
                -0.09622505307197571,
                -0.004504776559770107,
                -0.06244644895195961,
                0.053562842309474945,
                -0.006555131170898676,
                0.059232354164123535,
                0.03245311602950096,
                0.0067384191788733006,
                0.010149992071092129,
                0.0038300571031868458,
                -0.023377621546387672,
                -0.025713078677654266,
                -0.03318776562809944,
                0.0517682209610939,
                -0.03805951774120331,
                -0.07783371955156326,
                0.02562817931175232,
                0.03001577965915203,
                0.0009200094500556588,
                -0.009182697162032127,
                0.013676857575774193,
                0.0036746165715157986,
                0.0003428230120334774,
                0.01604493334889412,
                -0.055556658655405045,
                0.01338039431720972,
                -0.0014794935705140233,
                -0.06026007607579231,
                -0.09181845933198929,
                0.010757519863545895,
                -0.05320797115564346,
                -0.0015137465670704842,
                -0.0712037980556488,
                0.07517994940280914,
                0.019606702029705048,
                0.00012944014451932162,
                0.01935376413166523,
                -0.020623087882995605,
                0.06568209826946259,
                -0.04748249053955078,
                0.03007468208670616,
                0.0019472034182399511,
                0.061308931559324265,
                -0.005839669611304998,
                -0.03164749592542648,
                0.016356008127331734,
                -0.029870187863707542,
                0.010995909571647644,
                0.001465050969272852,
                0.047273606061935425,
                -0.12312836945056915,
                -0.0023633413948118687,
                0.017110345885157585,
                0.06058591604232788,
                -0.02696986123919487,
                -0.033833395689725876,
                -0.02385321632027626,
                0.028114384040236473,
                0.013208024203777313,
                0.058487385511398315,
                0.013194060884416103,
                -0.0005843625403940678,
                0.03695206344127655,
                -0.044059500098228455,
                0.028261955827474594,
                0.010653178207576275,
                0.005682486109435558,
                -0.10751049965620041,
                0.06525231897830963,
                0.0049666594713926315,
                0.047789137810468674,
                0.013051134534180164,
                -0.034716326743364334,
                0.02854846604168415,
                0.00439996924251318,
                -0.016462601721286774,
                -0.030567623674869537,
                0.02652556635439396,
                -0.14490251243114471,
                0.05252501368522644,
                0.03476279601454735,
                0.0002570074866525829,
                -0.01834764890372753,
                -0.013040009886026382,
                -0.016086816787719727,
                0.016034776344895363,
                -0.03740610182285309,
                0.03157573565840721,
                -0.029056387022137642,
                -0.013024328276515007,
                0.013259783387184143,
                -0.05661481246352196,
                0.0030871599446982145,
                0.0024028723128139973,
                -0.14629043638706207,
                0.023701384663581848,
                0.052568163722753525,
                -0.0045289210975170135,
                -0.04949892312288284,
                -0.003741588443517685,
                0.01615639589726925,
                0.022472092881798744,
                0.01173877902328968,
                0.017028136178851128,
                -0.040497031062841415,
                0.04774439334869385,
                -0.0675891637802124,
                0.04120456054806709,
                0.03633079677820206,
                0.02042161487042904,
                -0.005189438350498676,
                0.026306701824069023,
                -0.011555862613022327,
                0.013941744342446327,
                0.044774193316698074,
                0.039920371025800705,
                0.04785538837313652,
                -0.0030188467353582382,
                0.04636303335428238,
                0.05980175361037254,
                0.0018660923233255744,
                0.08637730032205582,
                0.061278022825717926,
                -0.012290647253394127,
                -0.019154909998178482,
                -0.0005075447261333466,
                -0.0256581399589777,
                0.02488728053867817,
                -0.024851223453879356,
                -0.006773241795599461,
                0.023708559572696686,
                0.047714963555336,
                0.01828690432012081,
                0.0034216088242828846,
                0.02090870775282383,
                -0.017730198800563812,
                0.03071269765496254,
                -0.03063640557229519,
                0.016515515744686127,
                0.02215559408068657,
                0.06480327993631363,
                0.016640448942780495,
                -0.0344320610165596,
                0.05402863398194313,
                -0.04866849631071091,
                0.032616473734378815,
                0.03993929550051689,
                -0.039625588804483414,
                0.01222455594688654,
                -0.019859815016388893,
                -0.025217683985829353,
                -0.03992500901222229,
                0.013748855330049992,
                -0.06951802968978882,
                0.005301266442984343,
                0.031022917479276657,
                0.003013452747836709,
                0.04006139189004898,
                0.020697375759482384,
                -0.0019344976171851158,
                -0.02742612734436989,
                -0.006815753411501646,
                0.05103897303342819,
                -0.0014495525974780321,
                -0.004135229624807835,
                -0.012292871251702309,
                0.009178424254059792,
                0.03109251894056797,
                -0.026441214606165886,
                0.020024601370096207,
                -0.021717162802815437,
                0.010945248417556286,
                0.04246272146701813,
                -0.07075804471969604,
                0.002993544563651085,
                0.022782200947403908,
                -0.04610171169042587,
                0.009123986586928368,
                0.0003771123301703483,
                0.018856121227145195,
                -0.006856550928205252,
                0.02546215057373047,
                0.04764866828918457,
                -0.014454497024416924,
                -0.02712913788855076,
                0.0073737273924052715,
                -0.02192125841975212,
                0.012289158999919891,
                0.0027957966085523367,
                -0.02244226075708866,
                -0.015950927510857582,
                0.04413516819477081,
                0.02440887875854969,
                -0.0216736551374197,
                0.08070405572652817,
                -0.026693250983953476,
                0.07092847675085068,
                0.03402974084019661,
                0.04804972931742668,
                0.014948165975511074,
                0.011259445920586586,
                -0.0025136421900242567,
                0.047000203281641006,
                0.032859284430742264,
                -0.0070059034042060375,
                0.016888407990336418,
                0.004846080671995878,
                0.03777434304356575,
                -0.051693402230739594,
                3.1098340969037963e-06,
                1.5419393093907274e-05,
                0.015041586011648178,
                -0.06272716820240021,
                -0.06997621804475784,
                -0.022071093320846558,
                -0.04643616825342178,
                -0.004741777200251818,
                -0.061274927109479904,
                -0.04701843112707138,
                0.030566204339265823,
                -0.026124117895960808,
                0.029867036268115044,
                0.01389170903712511,
                -0.09263287484645844,
                0.022259460762143135,
                -0.008743791840970516,
                -0.03440850228071213,
                -0.03316381201148033,
                0.006932409480214119,
                -0.0033910395577549934,
                0.023754477500915527,
                0.036623768508434296,
                0.037966493517160416,
                0.065340556204319,
                -0.0010060409549623728,
                0.04421287029981613,
                -0.021896595135331154,
                0.02940305508673191,
                -0.013255877420306206,
                -0.022049907594919205,
                -0.006061824969947338,
                0.005561783444136381,
                0.013157029636204243,
                -0.025515863671898842,
                0.033095382153987885,
                0.029707055538892746,
                -0.04453487694263458,
                -0.02929878421127796,
                -0.024653587490320206,
                -0.016275806352496147,
                -0.04842852056026459,
                0.0012335084611549973,
                0.026898693293333054,
                -0.04757967218756676,
                0.05415190011262894,
                -0.03253161907196045,
                -0.0145436841994524,
                -0.0015788321616128087,
                0.019692396745085716,
                -0.04338729754090309,
                -0.024417635053396225,
                0.05147081986069679,
                -0.008323702029883862,
                -0.009860213845968246,
                -0.03985978290438652,
                0.04692333564162254,
                0.018388861790299416,
                0.012917986139655113,
                0.0435478575527668,
                -0.011927921324968338,
                0.01253883633762598,
                -0.000555977807380259,
                -0.021532559767365456,
                -0.0317055881023407,
                -0.002276166807860136,
                0.028010377660393715,
                -0.047602906823158264,
                0.0038476090412586927,
                0.04186955466866493,
                0.007088678423315287,
                0.022716224193572998,
                -0.05359961465001106,
                0.005816691089421511,
                0.0006509799277409911,
                -0.013778811320662498,
                -0.005366882309317589,
                0.008273123763501644,
                -0.00018283596727997065,
                0.020485516637563705,
                0.005521269980818033,
                -0.0008259390597231686,
                -0.05943036451935768,
                0.047732315957546234,
                0.02293972112238407,
                -0.04319559410214424,
                -0.018894024193286896,
                0.06778749823570251,
                0.014519158750772476,
                -0.04091756418347359,
                -0.019083507359027863,
                -0.019797589629888535,
                -0.00508622732013464,
                -0.01684032939374447,
                0.03648035228252411,
                -0.008185948245227337,
                0.04935843124985695,
                0.0702039822936058,
                0.009296073578298092,
                -0.01796800084412098,
                -0.0011825419496744871,
                0.02777703106403351,
                0.04180140048265457,
                -0.004838067106902599,
                -0.04262624680995941,
                0.015217711217701435,
                0.012229705229401588,
                -0.009431934915482998,
                -0.056286416947841644,
                0.009625407867133617,
                0.016285700723528862,
                0.053342290222644806,
                0.053534604609012604,
                0.048616208136081696,
                -0.03917865827679634,
                -0.0546986423432827,
                -0.013813713565468788,
                -0.06785103678703308,
                0.058669742196798325,
                0.010199411772191525,
                0.004730048589408398,
                0.049754638224840164,
                -0.03645052760839462,
                -0.043674033135175705,
                0.07874216884374619,
                -0.08995379507541656,
                -0.0021660970523953438,
                0.01820479705929756,
                -0.037432387471199036,
                0.060206469148397446,
                -0.05860209837555885,
                0.0026258069556206465,
                -0.03569687902927399,
                -0.16471083462238312,
                0.02619868703186512,
                0.008920250460505486,
                -0.042995527386665344,
                0.06535466760396957,
                0.03439752012491226,
                -0.06020532548427582,
                0.00734378956258297,
                0.009612049907445908,
                0.020999500527977943,
                0.03251500800251961,
                -0.025772202759981155,
                0.01657208241522312,
                -0.05234050005674362,
                0.02647700533270836,
                -0.01105508953332901,
                -0.03155039623379707,
                0.04066846892237663,
                -0.046076081693172455,
                0.02777126245200634,
                -0.0041998764500021935,
                0.07319516688585281,
                0.014434386976063251,
                0.043532248586416245,
                0.015501948073506355,
                -0.008630609139800072,
                0.04781196638941765,
                -0.017346980050206184,
                0.015015952289104462,
                0.018395904451608658,
                -0.011311255395412445,
                0.0046437084674835205,
                -0.008828123100101948,
                0.005357715301215649,
                0.027015546336770058,
                -0.008042668923735619,
                0.04926791414618492,
                -0.019401146098971367,
                -0.01639733649790287,
                -0.0384373664855957,
                -0.026114571839571,
                0.011903438717126846,
                0.03804368898272514,
                0.012887269258499146,
                -0.024515580385923386,
                0.019540350884199142,
                -0.03929368779063225,
                -0.05977865681052208,
                0.02275914140045643,
                0.016011400148272514,
                -0.005268212873488665,
                0.030969709157943726,
                -0.04846840724349022,
                0.025552887469530106,
                0.03879217430949211,
                0.004547824617475271,
                0.026776893064379692,
                -0.0773884505033493,
                0.02871483750641346,
                0.006726439110934734,
                0.022196954116225243,
                0.05726487934589386,
                -0.015219165943562984,
                -0.009431564249098301,
                0.02910294197499752,
                -0.018680425360798836,
                0.023870710283517838,
                0.016278667375445366,
                -0.018050119280815125,
                -0.03127264976501465,
                -0.01049417071044445,
                -0.05144210904836655,
                0.046696119010448456,
                0.03692430630326271,
                0.005591200198978186,
                -0.05158160626888275,
                -0.0567178949713707,
                -0.03301841393113136,
                -0.052857138216495514,
                0.038120098412036896,
                0.05155482515692711,
                -0.010937764309346676,
                -0.006321485619992018,
                -0.022484000772237778,
                -0.07789105176925659,
                0.009431595914065838,
                0.011678634211421013,
                0.0022904055658727884,
                -0.01972769945859909,
                0.0030734192114323378,
                -0.013523617759346962,
                0.004626918118447065,
                0.022319016978144646,
                0.06340990215539932,
                -0.01045091263949871,
                -0.02832571044564247,
                -0.02493867091834545,
                0.0031641838140785694,
                -0.009494823403656483,
                -5.0583105159836795e-33,
                0.007094329688698053,
                0.038917697966098785,
                0.03153251111507416,
                0.024198586121201515,
                0.046095170080661774,
                0.013896861113607883,
                0.011110913939774036,
                -0.024053780362010002,
                -0.031527917832136154,
                -0.02802862599492073,
                0.0018833326175808907,
                -0.02446972019970417,
                0.021995190531015396,
                -0.009025883860886097,
                0.02323763445019722,
                0.06807668507099152,
                -0.00808852631598711,
                0.056166622787714005,
                -0.035758502781391144,
                -0.08529914170503616,
                -0.02604685351252556,
                -0.00845298171043396,
                0.04883432015776634,
                0.08822009712457657,
                0.013709815219044685,
                0.013474586419761181,
                -0.0029815149027854204,
                0.02899770811200142,
                0.004955283831804991,
                -0.024951590225100517,
                -0.007886284962296486,
                -0.04687489569187164,
                -0.007011242210865021,
                -0.02988075464963913,
                0.003213610267266631,
                0.012458768673241138,
                -0.018069826066493988,
                -0.018269332125782967,
                0.02096329629421234,
                -0.019541721791028976,
                0.014351952821016312,
                0.002780491253361106,
                0.03144601732492447,
                -0.05376218631863594,
                0.006382648833096027,
                0.024233322590589523,
                -0.0007116120541468263,
                0.011218632571399212,
                -0.01998310536146164,
                -0.04341738298535347,
                -0.03153076767921448,
                -0.035117946565151215,
                -0.010602543130517006,
                0.08461607247591019,
                -0.009143461473286152,
                0.006559483241289854,
                -0.03904043138027191,
                -0.04217222332954407,
                0.027487529441714287,
                -0.018327860161662102,
                0.02043827995657921,
                0.042294785380363464,
                -0.027969924733042717,
                -0.0031150062568485737,
                -0.007348182611167431,
                -0.03545945882797241,
                0.0054525756277143955,
                -0.038130149245262146,
                0.1063489243388176,
                0.0024928520433604717,
                -0.05018230527639389,
                0.07096037268638611,
                -0.01685306429862976,
                0.0030276423785835505,
                -0.07958857715129852,
                -0.005134198814630508,
                0.01855696737766266,
                0.018805889412760735,
                0.004717076197266579,
                -0.019633136689662933,
                0.00861278921365738,
                -0.0078659662976861,
                0.006517878733575344,
                0.016905751079320908,
                0.035162847489118576,
                0.011110572144389153,
                -0.05222625657916069,
                0.0351325124502182,
                0.03450712189078331,
                0.011255811899900436,
                0.03642309829592705,
                0.10383589565753937,
                0.016576042398810387,
                0.01858665980398655,
                -0.005183808505535126,
                -0.01905399188399315,
                -0.01058334019035101,
                0.0008091413765214384,
                0.025562414899468422,
                -4.5346627302933484e-05,
                -0.055646251887083054,
                0.0400911346077919,
                -0.04066061973571777,
                0.011152781546115875,
                -0.039366189390420914,
                0.0008891299366950989,
                0.028406983241438866,
                -0.04684104397892952,
                -0.02716517262160778,
                0.05187744274735451,
                -0.01724572479724884,
                0.021998262032866478,
                0.007045946083962917,
                -0.05592064931988716,
                0.003949914127588272,
                -0.02370399981737137,
                -0.0037127253599464893,
                -0.04825583100318909,
                -0.002396551426500082,
                -0.008984096348285675,
                0.01167948730289936,
                -0.02138243243098259,
                -0.019338825717568398,
                -0.03769084811210632,
                -0.0007938857888802886,
                -0.0036519349087029696,
                -0.032713405787944794,
                0.05879625678062439,
                0.005032541695982218,
                -0.008262170478701591,
                0.018989820033311844,
                0.013831773772835732,
                2.0466566752475046e-07,
                -0.012899301946163177,
                -0.0024464549496769905,
                0.012440919876098633,
                0.013806606642901897,
                0.016426246613264084,
                0.034877464175224304,
                0.049359943717718124,
                -0.011577568016946316,
                -0.02320588193833828,
                0.08705733716487885,
                -0.03409220278263092,
                -0.061146099120378494,
                0.00402086554095149,
                -0.03521440923213959,
                -0.012860476970672607,
                -0.09227406233549118,
                -0.004042415414005518,
                -0.02003292366862297,
                -0.061476029455661774,
                -0.018277043476700783,
                -0.0178229957818985,
                0.03121885098516941,
                0.022709857672452927,
                -0.0012232863809913397,
                -0.007477783132344484,
                -0.004246138501912355,
                0.05236217379570007,
                0.02626246213912964,
                -0.014023927040398121,
                0.047039907425642014,
                0.0155874565243721,
                -0.040937457233667374,
                -0.03053996153175831,
                -0.020052025094628334,
                -0.00676024379208684,
                -0.018970301374793053,
                -0.003254661802202463,
                -0.007541308645159006,
                0.004416251089423895,
                0.035001080483198166,
                0.06980746984481812,
                -0.08309908211231232,
                -0.02422080934047699,
                -0.04276922717690468,
                -0.0013551958836615086,
                -0.031107818707823753,
                -0.05401930212974548,
                0.041105229407548904,
                -0.030987387523055077,
                -0.025041863322257996,
                -0.008676085621118546,
                0.010436488315463066,
                -0.048890456557273865,
                0.02268691547214985,
                -0.029567882418632507,
                -0.03630809485912323,
                0.012150619179010391,
                0.036493461579084396,
                0.014974979683756828,
                0.04482510685920715,
                -0.02455022931098938,
                0.006840952672064304,
                0.0032092612236738205,
                0.04273683950304985,
                0.05982854589819908,
                0.004991495981812477,
                -0.0006360732950270176,
                1.656802234682026e-34,
                -0.007095645647495985,
                0.012003627605736256,
                -0.002148187253624201,
                0.031717702746391296,
                -0.025429410859942436,
                -0.01310826651751995,
                -0.0039787753485143185,
                -0.012239532545208931,
                -0.009344158694148064,
                0.036279190331697464,
                -0.014577801339328289,
                0.01957889087498188,
                0.021542662754654884,
                -0.05578429624438286,
                -0.006331127602607012,
                -0.008343815803527832,
                -0.01164149958640337,
                0.0031266948208212852,
                0.025021668523550034,
                -0.04727873206138611,
                0.02717103250324726,
                0.07873215526342392,
                -0.02824871987104416,
                0.02197973057627678,
                0.061269085854291916,
                -0.005690812133252621,
                -0.01080081146210432,
                0.019272485747933388,
                0.012247405014932156,
                -0.07829879224300385,
                0.03848039731383324,
                -0.009572983719408512,
                -0.004574237857013941,
                -0.011711827479302883,
                -0.020700130611658096,
                -0.03985205292701721,
                -0.007808656431734562,
                -0.0020213513635098934,
                -0.02138698473572731,
                0.011356727220118046,
                -0.025965377688407898,
                -0.012513512745499611,
                -0.004308751318603754,
                -0.07084693759679794,
                0.009997401386499405,
                1.5152802461670944e-06,
                -0.05703417584300041,
                0.01979900896549225,
                0.018757028505206108,
                -0.013322126120328903,
                0.0451599545776844,
                0.03498765826225281,
                0.045533135533332825,
                -0.03562789782881737,
                0.04561149328947067,
                0.005393859930336475,
                0.02142648585140705,
                0.02224225550889969,
                0.0017095445655286312,
                0.03180458024144173,
                0.01871560886502266,
                0.010834516026079655,
                0.06882703304290771,
                -0.04406695067882538,
                -0.013077100738883018,
                -0.036631468683481216,
                0.00016284038429148495,
                -0.015587372705340385,
                0.0308204535394907,
                0.09911277145147324,
                -0.0052415430545806885,
                0.006138960365206003,
                -0.020094960927963257,
                0.055918894708156586,
                0.012231731787323952,
                -0.04148499667644501,
                0.00902226846665144,
                0.01354537345468998,
                -0.01301810797303915,
                -0.003112462582066655,
                0.001177023397758603,
                -0.0015230061253532767,
                0.00492184329777956,
                -0.025102322921156883,
                0.027735082432627678,
                -0.0025783032178878784,
                -0.018435388803482056,
                0.024261539801955223,
                -0.014010081067681313,
                -0.010016974993050098,
                -0.023998480290174484,
                -0.08592073619365692,
                -0.004065115936100483,
                -0.012372623197734356,
                -0.023283809423446655,
                0.03436630591750145,
                -0.04373089224100113,
                0.0018236817559227347,
                -0.04763725772500038,
                -0.03662600368261337,
                0.059063807129859924,
                -0.017233533784747124,
                -0.004629936069250107,
                -0.027705306187272072,
                0.0033419947139918804,
                -0.022009866312146187,
                -0.047973696142435074,
                0.02388288639485836,
                -0.029231373220682144,
                0.0739908292889595,
                -0.012078199535608292,
                -0.03685162216424942,
                -0.011049331165850163,
                0.0017482549883425236,
                0.0010236123343929648,
                -0.020398734137415886,
                0.05087854340672493,
                0.055202286690473557,
                0.03930898383259773,
                -0.04056698828935623,
                0.054576825350522995,
                -0.021000556647777557,
                0.02882092073559761,
                0.030283402651548386,
                0.015409248881042004,
                0.05506141483783722,
                -0.02778860181570053,
                -0.02069615200161934,
                -0.02398543246090412,
                -0.019735749810934067,
                0.07446972280740738,
                -0.03359906002879143,
                0.016896598041057587,
                -0.01481730304658413,
                0.0316292904317379,
                -0.03588278964161873,
                -0.043915845453739166,
                -0.05758289992809296,
                0.002541968133300543,
                -0.010170347057282925,
                0.026194948703050613,
                0.02674817480146885,
                -0.0001781800965545699,
                -0.019853372126817703,
                0.0322757251560688,
                0.02578268013894558,
                0.02429058775305748,
                -0.0005739025655202568,
                0.04376000165939331,
                -0.045986540615558624,
                -0.040728285908699036,
                0.0018973039695993066,
                -0.03460617735981941,
                -0.013348457403481007,
                0.0075120567344129086,
                -0.06559374928474426,
                -0.023371325805783272,
                -0.009587916545569897,
                0.03446575626730919,
                3.447226481512189e-05,
                0.020036445930600166,
                0.013706648722290993,
                -0.015255515463650227,
                0.014253508299589157,
                0.021204296499490738,
                -0.008815362118184566,
                0.029550623148679733,
                0.05847947672009468,
                -0.01211723405867815,
                -0.02706693299114704,
                0.0044163865968585014,
                0.02704635076224804,
                0.002137908712029457,
                0.030794041231274605,
                0.0010666385060176253,
                -0.020311377942562103,
                0.08247024565935135,
                0.012545579113066196,
                -0.003291590139269829,
                -0.04644851014018059,
                0.01534148771315813,
                -0.014691777527332306,
                0.04174899309873581,
                -0.01227317564189434,
                0.06763675808906555,
                -0.010562960058450699,
                -0.039988335222005844,
                0.08059876412153244,
                -0.05822144076228142,
                -0.03886675834655762,
                0.03326175734400749,
                -0.028652817010879517,
                -0.035792700946331024,
                0.020040109753608704,
                -0.008943178690969944,
                0.01993943750858307,
                0.040007561445236206,
                -0.0010536176851019263,
                -0.00376533973030746,
                -0.027554042637348175,
                -0.05946599692106247,
                -0.03774983435869217,
                -0.0023445344995707273,
                0.00793133769184351,
                -0.009259317070245743,
                0.00427308538928628,
                0.00871642492711544,
                -0.008289133198559284,
                -0.055208880454301834,
                0.004056967329233885,
                -0.008865010924637318,
                -0.03940998762845993,
                -0.0471455380320549,
                0.1238006129860878,
                0.008521909825503826,
                -0.01695127785205841,
                -0.019957520067691803,
                0.043621454387903214,
                -0.012847714126110077,
                -0.052970584481954575,
                -0.01084402296692133,
                0.016812002286314964,
                -0.008819539099931717,
                -0.031618088483810425,
                0.03918105736374855,
                -0.03460241109132767,
                0.0038944529369473457,
                0.0018658702028915286,
                0.005505425855517387,
                -0.050204209983348846,
                0.0777285024523735,
                0.013956592418253422,
                -0.02323225885629654,
                -0.025390593335032463,
                0.016320817172527313,
                0.02836160734295845,
                -0.011898782104253769,
                0.003618116956204176,
                0.006719355005770922,
                0.009221885353326797,
                0.005485066678375006,
                0.012144312262535095,
                -0.015533008612692356,
                -0.027670269832015038,
                0.031236330047249794,
                0.009076467715203762,
                0.0068173594772815704,
                0.10852088034152985,
                0.016992703080177307,
                -0.0021714982576668262,
                -0.09284768253564835,
                -0.05036593973636627,
                0.02776096761226654,
                -0.04504967853426933,
                0.01324912253767252,
                0.019667131826281548,
                -0.016836468130350113,
                -0.05614982172846794,
                0.0028432398103177547,
                -0.007743344642221928,
                -0.04824545979499817,
                -0.05172808840870857,
                -0.017475927248597145,
                0.03646618500351906,
                -0.02923360839486122,
                0.052049558609724045,
                -0.03677015006542206,
                -0.017391923815011978,
                0.04556269571185112,
                -0.039070628583431244,
                -0.027881743386387825,
                0.007698333356529474,
                -0.04880255460739136,
                0.040667083114385605,
                0.0018059195717796683,
                0.09446791559457779,
                -0.05360892042517662,
                0.025972826406359673,
                0.001171387149952352,
                0.0012007324257865548,
                0.003228664631024003,
                0.017017491161823273,
                -0.026138868182897568,
                -0.023101935163140297,
                -0.0036374281626194715,
                0.015346849337220192,
                -0.024865619838237762,
                -0.07239270210266113,
                0.05962571129202843,
                -0.030069248750805855,
                -0.007652323693037033,
                0.022163694724440575,
                0.007917940616607666,
                -0.014064253307878971,
                -0.0020850684959441423,
                0.005997685249894857,
                0.05709872767329216,
                0.03491714224219322,
                0.042529404163360596,
                -0.07780430465936661,
                0.030452515929937363,
                0.009619505144655704,
                -0.027802718803286552,
                0.07096666097640991,
                -0.08489713072776794,
                0.049382809549570084,
                0.021056119352579117,
                -0.05747681483626366,
                0.05410255119204521,
                0.003551639150828123,
                -0.0287453755736351,
                -0.03643612563610077,
                0.06265434622764587,
                0.030262541025877,
                -0.01193875540047884,
                0.038559362292289734,
                0.012177808210253716,
                0.10012941062450409,
                -0.04037618637084961,
                0.023550407961010933,
                -0.008622344583272934,
                -0.018063610419631004,
                -0.011570530943572521,
                0.00037657038774341345,
                0.12837041914463043,
                -0.02157462388277054,
                0.011296700686216354,
                0.06667794287204742,
                0.023549219593405724,
                -0.0031741270795464516,
                0.05056779459118843,
                0.008254160173237324,
                0.029761824756860733,
                -0.006091090850532055,
                -0.01110459491610527,
                0.0361739806830883,
                -0.04627736657857895,
                0.021692203357815742,
                0.0399879589676857,
                -0.03762536495923996,
                -0.05208148807287216,
                0.0010656954254955053,
                -0.05202476307749748,
                -0.015721021220088005,
                0.02657824009656906,
                -0.002670220797881484,
                0.0599011667072773,
                -0.026170380413532257,
                -0.042354535311460495,
                -0.04578829184174538,
                0.016811657696962357,
                0.008081761188805103,
                -0.006107705179601908,
                -0.014353467151522636,
                0.049717653542757034,
                -0.0006539463647641242,
                -0.02625606767833233,
                -0.04990902170538902,
                0.0658353865146637,
                0.008597428910434246,
                0.02690490148961544,
                -0.028104735538363457,
                0.03851659223437309,
                0.004009424243122339,
                -0.02066783420741558,
                -0.014075747691094875,
                -0.012597046792507172,
                -0.054161299020051956,
                -0.012710516341030598,
                0.06043113023042679,
                -0.0749400332570076,
                -0.005742288194596767,
                -0.01639334298670292,
                0.001367106451652944,
                0.04115193709731102,
                -0.04625765234231949,
                0.004405554849654436,
                -0.008232176303863525,
                -0.00032115535577759147,
                0.0037951769772917032,
                0.011691063642501831,
                0.0029309208039194345,
                0.0024027929175645113,
                -0.01870083622634411,
                -0.011310538277029991,
                0.016459746286273003,
                0.0747302770614624,
                0.027505429461598396,
                0.047442369163036346,
                -0.05078132078051567,
                0.0046714432537555695,
                0.04077525809407234,
                -0.08551223576068878,
                0.02987329289317131,
                0.009231096133589745,
                0.02150912955403328,
                -0.004666621796786785,
                -0.006524403113871813,
                0.011036746203899384,
                -0.014374606311321259,
                0.06192826107144356,
                -0.01687830314040184,
                0.009477147832512856,
                -0.07122159749269485,
                0.01695318892598152,
                0.013757620938122272,
                -0.020371485501527786,
                0.1008417010307312,
                0.01773606240749359,
                0.021306386217474937,
                0.023943044245243073,
                0.001096423831768334,
                -0.05023551732301712,
                -0.10365394502878189,
                0.07825332134962082,
                -0.04093697667121887,
                -0.03237265720963478,
                0.08336690813302994,
                0.004973241128027439,
                -0.013401255942881107,
                -0.04933011904358864,
                -0.007645170204341412,
                -0.06467579305171967,
                0.0019453210989013314,
                -0.005015284288674593,
                0.04467855021357536,
                0.03925172984600067,
                -0.055164847522974014,
                -0.012013666331768036,
                -0.014565999619662762,
                0.018702922388911247,
                0.05084787681698799,
                -0.0020481145475059748,
                -0.06878411024808884,
                0.008967568166553974,
                -0.03563762456178665,
                -0.014364082366228104,
                0.028732450678944588,
                0.020839732140302658,
                0.008685847744345665,
                0.06010572612285614,
                -0.0226158257573843,
                -0.04780620336532593,
                -0.013223327696323395,
                0.013960129581391811,
                -0.04322760924696922,
                -0.043219856917858124,
                0.05089927464723587,
                -0.0210755355656147,
                0.016837093979120255,
                -0.06905309110879898,
                0.0034954682923853397,
                0.03763700649142265,
                0.06477896124124527,
                -0.04707494005560875,
                -0.0610782727599144,
                -0.047197554260492325,
                -0.008198145776987076,
                0.014462782070040703,
                0.035805594176054,
                0.07856657356023788,
                0.07864134758710861,
                -0.0695481151342392,
                0.03592175617814064,
                -0.02486901916563511,
                0.0061886487528681755,
                -0.03331642597913742,
                -0.017099786549806595,
                0.035658225417137146,
                -0.006419125944375992,
                0.03630917891860008,
                0.07705916464328766,
                0.0379524864256382,
                -0.03237582743167877,
                -0.04739348590373993,
                0.04988594725728035,
                -0.06966286152601242,
                -0.05470630154013634,
                -0.009662537835538387,
                -0.009453793987631798,
                -0.01897471770644188,
                -0.01979191042482853,
                -0.030418090522289276,
                -0.04484354332089424,
                0.0013664112193509936,
                0.019591184332966805,
                -0.004006348084658384,
                -0.006733312271535397,
                0.03857358917593956,
                -0.03647709637880325,
                0.00819635670632124,
                0.036618828773498535,
                0.012369333766400814,
                0.030870942398905754,
                0.0116334930062294,
                0.0005143929738551378,
                0.010261924006044865,
                0.049023229628801346,
                -0.03609864041209221,
                0.00033715402241796255,
                -0.015744170174002647,
                -0.005616219714283943,
                -0.018935373052954674,
                -0.04617450386285782,
                -0.0012583998031914234,
                -0.07929873466491699,
                0.05254686623811722,
                -0.02967062219977379,
                0.011579208076000214,
                -0.03106055036187172,
                -0.028773361817002296,
                0.02881237119436264,
                -0.01257464662194252,
                -0.01118212565779686,
                -0.06780780851840973,
                -0.031325116753578186,
                0.08414024114608765,
                0.019194113090634346,
                0.01787947304546833,
                0.003322203876450658,
                -0.00118815538007766,
                0.022489387542009354,
                0.007929487153887749,
                0.0383780375123024,
                -0.009724177420139313,
                -0.02068011276423931,
                -0.043374933302402496,
                -0.05485056713223457,
                -0.0024346504360437393,
                -0.007388621103018522,
                -0.006471001077443361,
                0.03283611312508583,
                0.0003496205317787826,
                0.002720025833696127,
                -0.015108650550246239,
                0.02887939289212227,
                -0.015150001272559166,
                0.036106012761592865,
                -0.013584095984697342,
                -0.05598969757556915,
                0.01556851714849472,
                -0.05082172527909279,
                -0.08796129375696182,
                -0.10140485316514969,
                0.05656557157635689,
                -0.026572519913315773,
                0.08259705454111099,
                0.023259026929736137,
                -0.002509081270545721,
                -0.0343489870429039,
                -0.018003102391958237,
                -0.023005755618214607,
                -0.016485990956425667,
                0.030376411974430084,
                0.07064565271139145,
                0.058029018342494965,
                0.0058063967153429985,
                -0.007503695785999298,
                0.03781493753194809,
                0.030757218599319458,
                0.02469763532280922,
                0.01701994426548481,
                -0.11681245267391205,
                0.011221583932638168,
                0.0010918122716248035,
                0.026946531608700752,
                -6.058144133806869e-33,
                -0.02701019123196602,
                -0.061208199709653854,
                -0.007207661401480436,
                -0.027756473049521446,
                0.01134747639298439,
                -0.010502145625650883,
                -0.010798647999763489,
                0.024378450587391853,
                0.01701550930738449,
                -0.007489110343158245,
                -0.008815851993858814,
                0.012662859633564949,
                0.02686225436627865,
                -0.0020534053910523653,
                0.02333408035337925,
                -0.04334259033203125,
                0.07633408159017563,
                0.034058742225170135,
                -0.03959780931472778,
                -0.03740919008851051,
                -0.06769229471683502,
                -0.03137776255607605,
                0.006949036847800016,
                0.0416482649743557,
                -0.03069225884974003,
                0.009855547919869423,
                -0.030645281076431274,
                -0.020789286121726036,
                -0.011953581124544144,
                0.03594557195901871,
                0.058882929384708405,
                0.023752277716994286,
                0.008791922591626644,
                -0.03426530584692955,
                0.019607938826084137,
                -0.020464736968278885,
                -0.0108633516356349,
                -0.03212271258234978,
                -0.01564004458487034,
                0.013691016472876072,
                -0.02871694043278694,
                0.018093153834342957,
                0.016596756875514984,
                -0.021793656051158905,
                -0.005332950968295336,
                -0.003540734527632594,
                0.013528822921216488,
                -0.02588013745844364,
                -0.007093071937561035,
                -0.05732204020023346,
                0.029336903244256973,
                0.004906020127236843,
                -0.032453082501888275,
                -0.0070946430787444115,
                -0.007078640162944794,
                0.04231063649058342,
                -0.01003730297088623,
                -0.06709633767604828,
                -0.008399615064263344,
                -0.02691265381872654,
                0.04099968820810318,
                -0.01899603381752968,
                0.006677168887108564,
                -0.011251261457800865,
                -0.041744165122509,
                0.03198913484811783,
                -0.08749299496412277,
                0.028347022831439972,
                0.07974860072135925,
                0.007212041411548853,
                0.05176488310098648,
                0.03356334567070007,
                0.030666686594486237,
                0.0480743907392025,
                -0.07127209007740021,
                -0.036166246980428696,
                0.004472731146961451,
                0.052514612674713135,
                -0.014221057295799255,
                0.015885939821600914,
                -0.047336429357528687,
                -0.03521377965807915,
                -0.0324326790869236,
                -0.033441346138715744,
                -0.023693587630987167,
                0.004575698170810938,
                -0.014115547761321068,
                -0.010139654390513897,
                0.016096286475658417,
                0.016230382025241852,
                -0.035553768277168274,
                -0.005261403974145651,
                0.01876678504049778,
                -0.0060025714337825775,
                0.04197755455970764,
                -0.07289990782737732,
                -0.013406478799879551,
                0.02994965948164463,
                -0.04886516183614731,
                -0.0033482189755886793,
                0.0069408598355948925,
                0.028904225677251816,
                -0.0052110725082457066,
                0.024804217740893364,
                0.008503284305334091,
                -0.021642914041876793,
                -0.0009041787125170231,
                -0.016180139034986496,
                -0.048098452389240265,
                0.048882946372032166,
                0.04281957447528839,
                0.0387854240834713,
                -0.02299506589770317,
                -0.05313168466091156,
                -0.024661293253302574,
                0.02938576601445675,
                0.04562105983495712,
                0.044089000672101974,
                -0.01699313521385193,
                -0.03273196890950203,
                -0.041491586714982986,
                0.04507942870259285,
                -0.01877916045486927,
                0.013837764970958233,
                -0.04109285771846771,
                -0.013731761835515499,
                0.0025370074436068535,
                0.06311821937561035,
                0.004291200079023838,
                -0.056307099759578705,
                -0.01387945469468832,
                0.01810905896127224,
                2.2068489613502607e-07,
                -0.013908341526985168,
                0.04154499992728233,
                0.0065212007611989975,
                0.0564584955573082,
                0.020491916686296463,
                0.0016710947966203094,
                -0.028493566438555717,
                0.0007736391853541136,
                0.0032433257438242435,
                -0.016356708481907845,
                0.021882496774196625,
                -0.03816208243370056,
                -0.0016197265358641744,
                0.030573029071092606,
                -0.0025642362888902426,
                -0.015475878491997719,
                -0.028354188427329063,
                0.01731298305094242,
                -0.04412078484892845,
                -0.01288228202611208,
                -0.0036601468455046415,
                -0.0059403665363788605,
                -0.04262356832623482,
                -0.008041191846132278,
                0.021417951211333275,
                -0.056306399405002594,
                -0.008645491674542427,
                0.00914023071527481,
                0.008510483428835869,
                0.02732541784644127,
                0.09358468651771545,
                0.007738297339528799,
                0.055018793791532516,
                0.03677145764231682,
                -0.008907970041036606,
                -0.048017725348472595,
                0.023091856390237808,
                -0.0022887701634317636,
                0.02555142715573311,
                -0.0077632879838347435,
                0.007059269119054079,
                -0.04074728861451149,
                0.05887703597545624,
                0.03574590012431145,
                0.0527024082839489,
                0.01422099769115448,
                0.03326328843832016,
                -0.05634442716836929,
                -0.04837167263031006,
                -0.0799565464258194,
                0.05354446917772293,
                0.012412910349667072,
                -0.021887511014938354,
                0.03947632014751434,
                -0.010750425048172474,
                0.027370084077119827,
                0.04557519033551216,
                0.06956185400485992,
                0.04437481611967087,
                0.028010308742523193,
                -0.0002690954424906522,
                -0.059106647968292236,
                0.03572990372776985,
                -0.04302329942584038,
                -0.01596236601471901,
                -0.03758341819047928,
                -0.04726594686508179,
                9.881966599686558e-35,
                0.04308241233229637,
                -0.0007826684741303325,
                0.04336441308259964,
                -0.013001903891563416,
                0.024265343323349953,
                -0.008647864684462547,
                -0.0008192599634639919,
                -0.009456822648644447,
                0.007858633063733578,
                0.04064386710524559,
                0.0047498419880867004
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'P|\\xa2C\\x92\\xe1\\x90\\x13\\xf4\\x7f\\x06\\x10\\xab\\x96g\\xf4'",
        "id__": "2ba5095c-5bda-40b6-9424-ee405443a103"
    },
    {
        "Certificates": {
            "name": [
                "Dangerous Goods Safety Advisor",
                "Tools for data science",
                "CS1301xI: Computing in Python",
                "I: Fundamentals and Procedural",
                "Programming",
                "Hands On Essentials - Data",
                "Applications",
                "Data Sharing"
            ],
            "rep": [
                -0.0016010451363399625,
                0.01772366650402546,
                -0.011438813991844654,
                -0.00644967844709754,
                0.035296306014060974,
                0.010369762778282166,
                0.05854962021112442,
                -0.01125572994351387,
                0.049264609813690186,
                -0.0021301363594830036,
                0.017344441264867783,
                0.020046817138791084,
                -0.0026423544622957706,
                0.058385953307151794,
                0.029470035806298256,
                0.019039051607251167,
                0.0555369108915329,
                -0.005475714802742004,
                -0.034720949828624725,
                -0.0010839900933206081,
                0.038132444024086,
                0.008801024407148361,
                -0.04081520810723305,
                0.06191549450159073,
                -0.04026629775762558,
                -0.048077717423439026,
                0.02832629345357418,
                0.021113095805048943,
                0.044241491705179214,
                -0.07186534255743027,
                0.07189960777759552,
                -0.005809127353131771,
                -0.011067618615925312,
                -0.015473361127078533,
                1.493985791967134e-06,
                -0.003098855260759592,
                0.00040493032429367304,
                -0.000898392463568598,
                -0.03272918239235878,
                -0.020026180893182755,
                0.01642031967639923,
                -0.025450265035033226,
                -0.00021306717826519161,
                0.056307900696992874,
                0.007126017939299345,
                -0.040532488375902176,
                0.032641034573316574,
                0.05832625553011894,
                0.053238678723573685,
                0.007539091631770134,
                -0.022485997527837753,
                0.04924411326646805,
                0.015953226014971733,
                0.00011794450256275013,
                0.016319705173373222,
                -0.03684127703309059,
                0.0036096132826060057,
                0.025558605790138245,
                0.061477985233068466,
                0.03205399215221405,
                -0.013794876635074615,
                -0.00882669072598219,
                -0.03136097267270088,
                0.007640611845999956,
                0.04443783685564995,
                -0.0007499494822695851,
                0.01603095792233944,
                -0.02334248088300228,
                -0.03472199663519859,
                0.05539510399103165,
                0.026576191186904907,
                0.027803976088762283,
                0.0032770053949207067,
                0.056621670722961426,
                0.00641655083745718,
                -0.011191293597221375,
                -0.007652678526937962,
                -0.020453695207834244,
                -0.018084784969687462,
                -0.02508014254271984,
                -0.008418677374720573,
                0.060769617557525635,
                -0.03268153965473175,
                0.01210031658411026,
                0.0848771259188652,
                0.0414731539785862,
                -0.015835974365472794,
                0.0030826032161712646,
                0.04596082493662834,
                -0.0216709952801466,
                -0.02107933722436428,
                -0.0008173825335688889,
                -0.013726034201681614,
                -0.006691582966595888,
                -0.06637176871299744,
                -0.01628190465271473,
                0.01955222897231579,
                -0.02755020000040531,
                0.06260451674461365,
                -0.07392851263284683,
                -0.005537176970392466,
                0.012514479458332062,
                -0.0009495627600699663,
                0.016253316774964333,
                0.023460933938622475,
                0.024873927235603333,
                0.009019766002893448,
                0.08365112543106079,
                -0.026392782106995583,
                0.0845741555094719,
                0.006627536378800869,
                0.010020775720477104,
                -0.03511959686875343,
                -0.014973524957895279,
                0.049423012882471085,
                0.006994486320763826,
                -0.025852825492620468,
                -0.017663823440670967,
                -0.078182652592659,
                0.01372747402638197,
                0.06433413922786713,
                0.05217992514371872,
                -0.04959689825773239,
                0.019749747589230537,
                -0.0044787246733903885,
                -0.03260064870119095,
                -0.062135834246873856,
                0.03587556257843971,
                -0.0008136743563227355,
                0.031035911291837692,
                0.004796536173671484,
                0.03893408551812172,
                -0.024184295907616615,
                -0.013818413950502872,
                -0.01704971306025982,
                0.09791529923677444,
                -0.05387436971068382,
                -0.0069859446957707405,
                -0.07231508940458298,
                0.0012719467049464583,
                -0.009967561811208725,
                0.024746665731072426,
                -0.008863194845616817,
                0.03402889892458916,
                -0.02445104718208313,
                -0.042708903551101685,
                -0.00793389417231083,
                -0.040813151746988297,
                -0.010062025859951973,
                0.008840206079185009,
                -0.026240993291139603,
                -0.020565325394272804,
                -0.014587881043553352,
                -0.0012848295737057924,
                0.011587955988943577,
                0.017885737121105194,
                0.007824810221791267,
                -0.055313609540462494,
                0.003089125733822584,
                0.021312875673174858,
                0.0013188807060942054,
                0.029204245656728745,
                0.005745391361415386,
                0.007070316933095455,
                -0.07018262147903442,
                -0.0019279030384495854,
                0.00392281636595726,
                -0.0067047858610749245,
                -0.024988384917378426,
                -0.031573206186294556,
                -0.020550772547721863,
                0.015456810593605042,
                -0.03240609169006348,
                0.04453447461128235,
                0.03342815488576889,
                -0.06726448982954025,
                0.025745078921318054,
                -0.009771388955414295,
                -0.013156991451978683,
                0.06365359574556351,
                -0.034115616232156754,
                -0.08824627101421356,
                -0.006368924397975206,
                -0.004087485373020172,
                -0.06512241810560226,
                0.011079604737460613,
                -0.04388157278299332,
                0.018839428201317787,
                -0.023950884118676186,
                -0.01154057402163744,
                0.004068469163030386,
                -0.014117619022727013,
                0.02241411991417408,
                -0.013639075681567192,
                -0.007499347440898418,
                0.024994488805532455,
                -0.032300692051649094,
                -0.03550988435745239,
                -0.007854063995182514,
                0.0020444614347070456,
                -0.07078467309474945,
                0.03046499937772751,
                0.03880785405635834,
                0.1162029430270195,
                -0.03857412561774254,
                0.004888318013399839,
                -0.09009551256895065,
                -0.062409281730651855,
                -0.05942509323358536,
                -0.025848278775811195,
                -0.06633824110031128,
                -0.008201027289032936,
                0.046757906675338745,
                -0.027254659682512283,
                -0.019758863374590874,
                4.991840978618711e-05,
                0.06988628208637238,
                0.017495082691311836,
                -0.01955568976700306,
                0.06391461938619614,
                0.014325170777738094,
                0.016452746465802193,
                0.0011762694921344519,
                0.029437363147735596,
                0.023142749443650246,
                -0.048715900629758835,
                -0.016877148300409317,
                0.01587829738855362,
                0.010335296392440796,
                -0.004064018838107586,
                0.03981345146894455,
                0.01831759512424469,
                0.02796819806098938,
                -0.004932302515953779,
                -0.04199795052409172,
                0.019253801554441452,
                0.05853990092873573,
                0.08010700345039368,
                0.06618021428585052,
                0.004000735003501177,
                0.017806993797421455,
                0.01030096784234047,
                0.009964276105165482,
                -0.002429752377793193,
                -0.024105772376060486,
                0.014145917259156704,
                -0.0836474671959877,
                0.008290679194033146,
                -0.021302450448274612,
                0.026739902794361115,
                -0.015815502032637596,
                0.002128343563526869,
                0.03408906236290932,
                0.03939157351851463,
                -0.037806179374456406,
                -0.02840830571949482,
                -0.017802203074097633,
                0.003454843070358038,
                0.011254373006522655,
                0.03173834830522537,
                -0.055222343653440475,
                -0.0339474156498909,
                0.05134935304522514,
                -0.03944657742977142,
                0.03631789982318878,
                -0.010675393044948578,
                0.050424724817276,
                -0.0018200380727648735,
                0.0179244726896286,
                -0.022188102826476097,
                -0.03179645538330078,
                -0.010240327566862106,
                -0.025226235389709473,
                -0.007649296894669533,
                -0.00855114497244358,
                0.002203812589868903,
                0.02945037931203842,
                -0.026743194088339806,
                -0.0018610568949952722,
                -0.014536657370626926,
                0.026661643758416176,
                0.00821946281939745,
                0.022816205397248268,
                0.027421290054917336,
                0.01937074586749077,
                0.021913103759288788,
                0.0006134887225925922,
                -0.02852637507021427,
                -0.05324696749448776,
                0.03745933249592781,
                -0.013936923816800117,
                0.016735725104808807,
                0.01573937013745308,
                -0.03361867740750313,
                -0.030818728730082512,
                -0.01653004251420498,
                0.004946944769471884,
                0.009661766700446606,
                0.0025586171541363,
                0.023370511829853058,
                -0.010104147717356682,
                -0.010198072530329227,
                -0.03263187035918236,
                0.007344584446400404,
                -0.01098084356635809,
                -0.018177514895796776,
                0.08052554726600647,
                0.010553969070315361,
                -0.0644860491156578,
                -0.007241099141538143,
                -0.0066111208871006966,
                0.023778580129146576,
                0.011748398654162884,
                0.006919212639331818,
                0.030535370111465454,
                -0.021139446645975113,
                0.015283724293112755,
                -0.016340455040335655,
                0.03497444465756416,
                0.049639880657196045,
                0.026420289650559425,
                -0.015140281990170479,
                -0.011897456832230091,
                -0.019005462527275085,
                -0.004816427826881409,
                -0.009761914610862732,
                -0.06010005995631218,
                -0.0038309514056891203,
                -0.03379897028207779,
                -0.07724222540855408,
                0.006259756162762642,
                0.004378572106361389,
                0.02064034529030323,
                0.0012071197852492332,
                0.014737223275005817,
                -0.011107385158538818,
                -0.01885480433702469,
                -0.07720120996236801,
                -0.02424471080303192,
                0.005521970335394144,
                -0.016572292894124985,
                -0.054928284138441086,
                -0.006887615658342838,
                -0.0643504410982132,
                -0.007218453101813793,
                -0.013708802871406078,
                -0.004385927692055702,
                0.05976039916276932,
                -0.06393653154373169,
                0.04015820100903511,
                -0.0064789229072630405,
                0.0020449336152523756,
                -0.009050842374563217,
                -0.014970310032367706,
                0.0022088363766670227,
                0.0017508006421849132,
                0.0310655627399683,
                -0.1170397400856018,
                -0.012014183215796947,
                -0.02705805003643036,
                0.015283175744116306,
                0.0385759212076664,
                -0.0645674392580986,
                -0.08722929656505585,
                -0.05305611714720726,
                0.016642976552248,
                -0.010002250783145428,
                0.03257433697581291,
                0.015140930190682411,
                0.019161926582455635,
                -0.029068995267152786,
                0.04066229611635208,
                0.012544508092105389,
                -0.021742507815361023,
                -0.019730310887098312,
                0.0092546958476305,
                0.03321544826030731,
                0.04835887998342514,
                0.0009897940326482058,
                -0.047754060477018356,
                0.007085131015628576,
                0.018797842785716057,
                0.03470541909337044,
                5.9211910411249846e-05,
                0.008589528501033783,
                -0.008567552082240582,
                -0.03505140542984009,
                0.006430621724575758,
                0.019471632316708565,
                0.05747600272297859,
                0.02264804020524025,
                -0.05089346319437027,
                -0.06713879853487015,
                0.046426404267549515,
                0.022513413801789284,
                -0.05050431191921234,
                0.0578833632171154,
                -0.011067300103604794,
                -0.02906743809580803,
                0.029147503897547722,
                0.044650789350271225,
                -0.016576537862420082,
                -0.015126355923712254,
                0.011640104465186596,
                -0.005000903736799955,
                -0.026124656200408936,
                0.0935923159122467,
                -0.07637655735015869,
                -0.03052869811654091,
                -0.012457589618861675,
                -0.03877701610326767,
                -0.042900025844573975,
                -0.040198009461164474,
                -0.0002735672751441598,
                0.018459150567650795,
                -0.048601385205984116,
                -0.020967582240700722,
                -0.005602943245321512,
                0.060322001576423645,
                0.008816124871373177,
                -0.035288963466882706,
                0.029452690854668617,
                0.0013353886315599084,
                0.02797798439860344,
                -0.001115598832257092,
                -0.04339886084198952,
                0.09779544919729233,
                0.06436070799827576,
                0.0011297482997179031,
                0.0010033096186816692,
                -0.0007505215471610427,
                0.006934234406799078,
                -0.0465744324028492,
                0.033925607800483704,
                0.035258155316114426,
                0.022977273911237717,
                0.043556854128837585,
                -0.033279817551374435,
                -0.03723081946372986,
                -0.020171673968434334,
                0.018272582441568375,
                0.0018414851510897279,
                0.0262086670845747,
                0.0004483793454710394,
                -0.036891818046569824,
                -0.04726194962859154,
                0.04351380839943886,
                0.027556007727980614,
                0.005871247500181198,
                0.00954277254641056,
                0.05376564338803291,
                0.014147534966468811,
                0.06506410986185074,
                0.005093790125101805,
                0.018430516123771667,
                -0.03569858521223068,
                0.04835304617881775,
                -0.00389101798646152,
                -0.04068387672305107,
                0.03370333090424538,
                0.097859226167202,
                0.04589765518903732,
                -0.028666986152529716,
                -0.032971616834402084,
                0.016463324427604675,
                0.029471704736351967,
                0.038529857993125916,
                -0.07242093980312347,
                -0.04785370081663132,
                -0.02686159871518612,
                -0.01846081018447876,
                0.018342765048146248,
                -0.017613453790545464,
                -0.012099687941372395,
                0.04864814132452011,
                -0.022688625380396843,
                0.03772028163075447,
                -0.05057080462574959,
                0.0014253585832193494,
                0.03222762793302536,
                -0.0043114968575537205,
                -0.009503315202891827,
                -0.0032249793875962496,
                0.10000552237033844,
                -0.008888221345841885,
                0.06329721212387085,
                0.07846755534410477,
                -0.006655602250248194,
                -0.031233226880431175,
                0.06907466053962708,
                0.034924622625112534,
                -0.09477242082357407,
                -0.015188498422503471,
                0.012488906271755695,
                0.03372293338179588,
                0.07872324436903,
                0.002113054506480694,
                -0.03122454136610031,
                -0.026454243808984756,
                -0.004558497574180365,
                0.03183920308947563,
                0.02963637374341488,
                0.03751995787024498,
                -0.0068402462638914585,
                0.002125843893736601,
                0.0017052179900929332,
                0.018571367487311363,
                0.03912694752216339,
                -0.015168766491115093,
                0.007245946675539017,
                -0.029391134157776833,
                0.03240136429667473,
                -0.001989802811294794,
                -0.10851553082466125,
                -0.03531515225768089,
                0.02826574258506298,
                -0.024044141173362732,
                0.005311375949531794,
                -0.016241390258073807,
                -0.0428294874727726,
                0.03398040682077408,
                -0.062112852931022644,
                0.04746828228235245,
                -0.04242464527487755,
                -0.007417493034154177,
                0.0003709526499733329,
                0.02336471900343895,
                -0.0075911590829491615,
                -0.053322479128837585,
                0.08143658190965652,
                -0.0045343744568526745,
                -0.03371661901473999,
                -0.02388591133058071,
                -0.01124961581081152,
                -0.0675986260175705,
                -0.019287243485450745,
                0.026559315621852875,
                0.040506813675165176,
                0.006432872265577316,
                -0.035848163068294525,
                -0.06210706755518913,
                -0.0626499280333519,
                0.025192413479089737,
                -0.02840355411171913,
                -0.0049689519219100475,
                0.019332148134708405,
                -0.01963495835661888,
                -0.02516290172934532,
                0.009554549120366573,
                -0.014662198722362518,
                0.039977144449949265,
                -0.033002886921167374,
                -0.01511249877512455,
                0.02196642942726612,
                -0.035598233342170715,
                -5.332958893581553e-33,
                -0.011470921337604523,
                -0.04933447390794754,
                -0.027090007439255714,
                -0.014075868763029575,
                0.025351954624056816,
                -0.038127683103084564,
                0.021506400778889656,
                0.02091020531952381,
                0.029141798615455627,
                0.01542501337826252,
                0.012805541045963764,
                0.02183167263865471,
                0.03828725591301918,
                0.0025872760452330112,
                0.0405593104660511,
                -0.0774923786520958,
                0.002661722246557474,
                0.023071007803082466,
                -0.00692748324945569,
                -0.025210285559296608,
                -0.021548409014940262,
                0.01602133736014366,
                0.011023178696632385,
                -0.052317000925540924,
                0.014006520621478558,
                -0.0036314157769083977,
                -0.02508496306836605,
                -0.0022899028845131397,
                -0.02206580713391304,
                0.013013841584324837,
                0.002214140724390745,
                0.00036266562528908253,
                0.02135450206696987,
                -0.0125672472640872,
                -0.035792455077171326,
                0.037851568311452866,
                -0.01937835104763508,
                -0.017226701602339745,
                -0.009061899967491627,
                -0.03964637964963913,
                -0.0896342471241951,
                -0.0581122450530529,
                0.006714369170367718,
                -0.0028876462019979954,
                -0.015554078854620457,
                0.0013758237473666668,
                -0.010373663157224655,
                -0.015006093308329582,
                -0.009847713634371758,
                -0.026436274871230125,
                -0.01920180208981037,
                -0.0342712327837944,
                -0.014461582526564598,
                -0.004650842864066362,
                0.04879535734653473,
                0.0799548551440239,
                0.051455993205308914,
                -0.029641835018992424,
                0.02806803025305271,
                -0.04159568250179291,
                -0.029630355536937714,
                -0.0380244180560112,
                -0.00549372099339962,
                -0.0024505567271262407,
                -0.0453876294195652,
                0.01565856672823429,
                -0.0996880754828453,
                0.021189799532294273,
                0.022236723452806473,
                -0.0171829666942358,
                -0.05038667470216751,
                0.0893993005156517,
                -0.00906841829419136,
                -0.021421512588858604,
                -0.029575731605291367,
                -0.007312171161174774,
                -0.04325098171830177,
                0.026767363771796227,
                -0.008613236248493195,
                0.050280798226594925,
                -0.06727307289838791,
                -0.033008210361003876,
                -0.024301690980792046,
                -0.02612265758216381,
                -0.005304398946464062,
                0.04304857179522514,
                -0.03336745873093605,
                0.010826527141034603,
                0.03407403454184532,
                0.0131352748721838,
                -0.05019884929060936,
                0.012377655133605003,
                -0.054716806858778,
                0.03517747297883034,
                0.08789198100566864,
                -0.03690343722701073,
                0.06528238952159882,
                -0.018580429255962372,
                0.01820417121052742,
                0.010006302036345005,
                -0.027905717492103577,
                0.036972951143980026,
                -0.03303265944123268,
                0.0041019427590072155,
                0.004959908314049244,
                -0.022771388292312622,
                0.024240445345640182,
                0.0015218043699860573,
                -0.01675296388566494,
                0.019324900582432747,
                0.031686872243881226,
                0.04258079081773758,
                -0.004257113207131624,
                -0.02523830160498619,
                -0.021107114851474762,
                -0.019672656431794167,
                0.01584543287754059,
                0.03020867146551609,
                0.007541878614574671,
                -0.0063854483887553215,
                0.07084431499242783,
                0.00011420420923968777,
                0.01625458151102066,
                -0.03150501474738121,
                9.277088975068182e-05,
                -0.007119154557585716,
                0.06269316375255585,
                0.000989515334367752,
                0.03832446038722992,
                -0.04637305438518524,
                0.017330611124634743,
                -0.002266831696033478,
                2.0152722868260753e-07,
                0.0006350610055960715,
                0.016801506280899048,
                0.03734653815627098,
                0.06192859262228012,
                0.04248388484120369,
                0.01876303367316723,
                -0.01297328807413578,
                0.04794353246688843,
                -0.04510555416345596,
                -0.03586461395025253,
                0.04776107892394066,
                -0.020073534920811653,
                0.01044655591249466,
                0.016406001523137093,
                0.0014467048458755016,
                -0.10298316180706024,
                0.00555242458358407,
                -0.001195497578009963,
                -0.03638777881860733,
                -0.009332395158708096,
                0.06828603148460388,
                0.00875451136380434,
                0.001481260871514678,
                -0.06540460139513016,
                -0.0007815135759301484,
                -0.035184044390916824,
                0.01038592029362917,
                -0.05589563027024269,
                0.056713204830884933,
                -0.04001472890377045,
                0.07377287745475769,
                0.06749732792377472,
                0.01822753995656967,
                0.05649930238723755,
                0.019883599132299423,
                -0.06994463503360748,
                -0.012036211788654327,
                0.041984762996435165,
                0.0047395420260727406,
                -0.005873860325664282,
                -0.010470996610820293,
                -0.10339175164699554,
                -0.017948176711797714,
                0.03281005471944809,
                0.03528239205479622,
                0.0014469586312770844,
                0.03179805725812912,
                0.08784512430429459,
                -0.10274496674537659,
                0.024781329557299614,
                0.019692925736308098,
                -0.018452316522598267,
                -0.014799719676375389,
                0.004261196590960026,
                0.006252040155231953,
                0.015605472028255463,
                0.017943594604730606,
                0.050082188099622726,
                0.051913607865571976,
                -0.011285954155027866,
                -0.010542399249970913,
                -0.034014347940683365,
                0.02514970302581787,
                0.08725709468126297,
                -0.0459253154695034,
                -0.0999470129609108,
                -0.010148894041776657,
                1.523836719242866e-34,
                0.009582501836121082,
                -0.05342898145318031,
                -0.013156847096979618,
                -0.03349687159061432,
                0.04158349707722664,
                -0.0039815413765609264,
                0.017153752967715263,
                -0.005433726590126753,
                -0.021141517907381058,
                -0.041185345500707626,
                -0.02961812913417816,
                -0.03928224742412567,
                0.07413110136985779,
                -0.05259622633457184,
                -0.013744620606303215,
                -0.02818748541176319,
                0.02435373142361641,
                0.06673984229564667,
                0.0333755686879158,
                -0.018672477453947067,
                0.042300838977098465,
                0.057260699570178986,
                0.02257593534886837,
                0.01109377108514309,
                0.09102588891983032,
                -0.022193165495991707,
                -0.08377671986818314,
                0.048220906406641006,
                0.045376256108284,
                -0.010475259274244308,
                0.02203560434281826,
                -0.020799703896045685,
                -0.021893711760640144,
                -0.01603543758392334,
                0.000753602187614888,
                -0.008190520107746124,
                -0.03114277310669422,
                -0.034380652010440826,
                0.0002007683797273785,
                0.048349276185035706,
                -0.042043790221214294,
                0.04990043491125107,
                0.06802058964967728,
                -0.02275833487510681,
                0.060536742210388184,
                1.5222526599245612e-06,
                -0.050958823412656784,
                -0.006936065386980772,
                0.028817053884267807,
                -0.02188759669661522,
                0.0187673419713974,
                0.05828017741441727,
                -0.007959111593663692,
                0.0105504235252738,
                -0.015156787820160389,
                -0.007776518817991018,
                -0.02863209694623947,
                0.08679085969924927,
                -0.0764731913805008,
                0.050940219312906265,
                -0.04214183986186981,
                0.016423242166638374,
                0.027753103524446487,
                0.0029485365375876427,
                0.004082888830453157,
                0.012766541913151741,
                0.08341581374406815,
                0.04252118989825249,
                0.06995606422424316,
                0.04080409184098244,
                0.021096237003803253,
                0.04474078491330147,
                -0.013484731316566467,
                -0.03841603174805641,
                -0.017965763807296753,
                0.013624349609017372,
                -0.01932317018508911,
                -0.010884210467338562,
                -0.04893402010202408,
                -0.021692363545298576,
                0.028683703392744064,
                0.06783894449472427,
                -0.04640242084860802,
                0.006042744964361191,
                0.013153786770999432,
                -0.014263150282204151,
                0.08447607606649399,
                -0.03880872577428818,
                -0.0155814029276371,
                0.013377252966165543,
                -0.03262324631214142,
                -0.036926425993442535,
                -0.04282337799668312,
                -0.049840234220027924,
                -0.0032261500600725412,
                -0.0019630277529358864,
                -0.009770575910806656,
                -0.0040078130550682545,
                -0.024673843756318092,
                0.0532209537923336,
                -0.011022264137864113,
                0.03698843717575073,
                -0.019064083695411682,
                -0.016055580228567123,
                -0.013514449819922447,
                -0.042865585535764694,
                -0.0041002389043569565,
                0.011577758006751537,
                -0.022091638296842575,
                0.03824715316295624,
                -0.031467732042074203,
                0.06425413489341736,
                -0.005868596490472555,
                0.018300138413906097,
                -0.02469044364988804,
                0.00739244231954217,
                -0.04369628056883812,
                0.0030311676673591137,
                0.046614713966846466,
                0.04284822195768356,
                0.01273457333445549,
                -0.02613525278866291,
                -0.012249884195625782,
                0.005243903025984764,
                -0.025774938985705376,
                -0.0002645804197527468,
                0.0419328510761261,
                -0.05103675648570061,
                0.009751321747899055,
                -0.02798077091574669,
                0.02447356842458248,
                -0.0261014886200428,
                -0.00995806884020567,
                0.026548489928245544,
                0.0666843131184578,
                -0.06638734042644501,
                -0.029653871431946754,
                -0.020325662568211555,
                0.028838135302066803,
                -0.033874548971652985,
                -0.025265749543905258,
                -0.02163233421742916,
                0.03760988265275955,
                0.019402263686060905,
                -0.004251253791153431,
                -0.029051240533590317,
                0.028326112776994705,
                0.029731636866927147,
                0.015640107914805412,
                -0.07598625868558884,
                -0.01753072254359722,
                0.06950227171182632,
                -0.04461516812443733,
                -0.07154002785682678,
                -0.038556601852178574,
                0.01661858521401882,
                0.023100540041923523,
                -0.04082101210951805,
                -0.00887563917785883,
                0.01282696146517992,
                -0.0087394705042243,
                0.02211879752576351,
                0.05451967939734459,
                -0.0032021929509937763,
                -0.016751442104578018,
                0.0076170554384589195,
                0.02197185717523098,
                0.010474077425897121,
                -0.03372923657298088,
                -0.10401584208011627,
                0.017087044194340706,
                -0.018120435997843742,
                -0.018193352967500687,
                0.023626411333680153,
                -0.009145219810307026,
                -0.015528743155300617,
                0.0017661507008597255,
                -0.05944894626736641,
                0.04511072486639023,
                -0.058288298547267914,
                0.012907709926366806,
                -0.04629967734217644,
                -0.009708765894174576,
                -0.004818354733288288,
                0.044262513518333435,
                0.06535737216472626,
                -0.004147599916905165,
                0.0754299983382225,
                0.02491818740963936,
                -0.030024686828255653,
                0.0029015731997787952,
                0.011953277513384819,
                -0.07477973401546478,
                0.021285980939865112,
                0.07606177031993866,
                0.007324826437979937,
                0.010991862043738365,
                -0.055475033819675446,
                0.0430985726416111,
                0.0019737316761165857,
                -0.029923202469944954,
                -0.03664916753768921,
                -0.007453779224306345,
                -0.02886202745139599,
                -0.01484739501029253,
                0.06047866493463516,
                -0.04366060346364975,
                0.033817362040281296,
                -0.03247513994574547,
                -0.006573110353201628,
                -0.07553379982709885,
                -0.06664621084928513,
                -0.0026199037674814463,
                0.011450610123574734,
                0.022914987057447433,
                -0.010531838051974773,
                -0.09944847971200943,
                -0.05139860883355141,
                -0.01702970266342163,
                -0.012273899279534817,
                0.01659798063337803,
                -0.0016079238848760724,
                -0.007854973897337914,
                -0.04029754176735878,
                -0.02503790333867073,
                -0.0017714877612888813,
                0.005695336032658815,
                0.0021290730219334364,
                -0.012966200709342957,
                0.060643259435892105,
                0.051258642226457596,
                0.01059612724930048,
                -0.024014512076973915,
                0.01832592859864235,
                -3.762076812563464e-05,
                0.020472116768360138,
                0.033729057759046555,
                0.005169895943254232,
                0.013133203610777855,
                0.0542064793407917,
                0.07403906434774399,
                -0.020229637622833252,
                0.004088511224836111,
                0.048040006309747696,
                0.040389735251665115,
                -0.019920822232961655,
                0.035910896956920624,
                0.15790428221225739,
                0.016337957233190536,
                0.033332571387290955,
                -0.09584809839725494,
                0.002195159438997507,
                0.01556798629462719,
                -0.010324246250092983,
                0.0006534295389428735,
                0.03415311872959137,
                0.0369022972881794,
                -0.035568732768297195,
                -0.024342305958271027,
                -0.01328343991190195,
                -0.07956192642450333,
                0.05187917873263359,
                -0.010631681419909,
                0.036983124911785126,
                0.03422284498810768,
                0.05697065219283104,
                -0.008417336270213127,
                -0.060322873294353485,
                0.012568863108754158,
                0.013006593100726604,
                0.024980578571558,
                0.009568391367793083,
                -0.022525323554873466,
                0.031222188845276833,
                -0.03202015161514282,
                -0.03258867189288139,
                -0.00472357077524066,
                0.03596746549010277,
                -0.01058926060795784,
                -0.01002165861427784,
                0.06629329174757004,
                0.007856485433876514,
                -0.016708066686987877,
                0.021152643486857414,
                -0.03962285444140434,
                -0.02027117647230625,
                0.011138967238366604,
                -0.02437678910791874,
                -0.040985576808452606,
                -0.02465837448835373,
                -0.0014065896393731236,
                0.008232719264924526,
                -0.005525804590433836,
                -0.03038402833044529,
                -0.0033812024630606174,
                -0.04266957938671112,
                -0.0036470205523073673,
                0.00018749221635516733,
                -0.05338482931256294,
                0.012338424101471901,
                -0.003219502279534936,
                -0.03533720225095749,
                0.01843818463385105,
                0.03925114497542381,
                0.009405978955328465,
                -0.0027532721869647503,
                0.0237770676612854,
                0.01738078147172928,
                0.022158967331051826,
                -0.03334105387330055,
                0.03398478031158447,
                0.040424130856990814,
                -0.01872175745666027,
                -0.011354692280292511,
                0.0031588946003466845,
                -0.014451720751821995,
                0.07153944671154022,
                0.06898237764835358,
                -0.046220678836107254,
                0.04787880927324295,
                -0.026932615786790848,
                0.05069321021437645,
                -0.004041427280753851,
                0.05525767803192139,
                0.06371507793664932,
                0.028788158670067787,
                0.008296960033476353,
                0.01723482459783554,
                0.006771791726350784,
                0.04344390332698822,
                0.018307512626051903,
                0.04666472226381302,
                -0.03213713690638542,
                0.039752911776304245,
                -0.036052409559488297,
                0.009235091507434845,
                0.02793232910335064,
                -0.08993984758853912,
                -0.04452439770102501,
                0.0006233399617485702,
                -0.041199032217264175,
                -0.0002853290643543005,
                -0.02716575376689434,
                0.031166834756731987,
                0.04791625216603279,
                -0.03213285654783249,
                0.07119312882423401,
                -0.046386267989873886,
                -0.042358048260211945,
                -0.02344101294875145,
                0.0016757616540417075,
                0.03396250680088997,
                0.029887918382883072,
                -0.03560788184404373,
                0.00022182597604114562,
                0.0522984080016613,
                -0.05182945355772972,
                0.034742262214422226,
                -0.026497457176446915,
                -0.01787675730884075,
                0.02963867597281933,
                0.0700494721531868,
                -0.008084198459982872,
                0.010181141085922718,
                -0.006586187984794378,
                0.04361159726977348,
                0.011378660798072815,
                -0.03520682826638222,
                -0.03474666178226471,
                0.009868999943137169,
                -0.05204210430383682,
                -0.00946213398128748,
                0.009286210872232914,
                -0.06555335968732834,
                -0.03666096553206444,
                -0.08185901492834091,
                -0.028592988848686218,
                -0.011216609738767147,
                -0.0633574053645134,
                0.025048689916729927,
                -0.016529859974980354,
                -0.012058738619089127,
                0.01373713556677103,
                0.046205785125494,
                -0.004476741887629032,
                0.03803004324436188,
                -0.05311752110719681,
                -0.014689049683511257,
                -0.048655662685632706,
                0.006528676953166723,
                0.008284756913781166,
                0.006537984125316143,
                -0.05455208942294121,
                0.01785925030708313,
                0.005831618327647448,
                0.024780871346592903,
                -0.037715714424848557,
                -0.0878816619515419,
                -0.004282990470528603,
                -0.037435464560985565,
                0.05295027792453766,
                -0.012010887265205383,
                0.00967603363096714,
                -0.016241705045104027,
                -0.09274757653474808,
                0.08479458093643188,
                -0.02805209904909134,
                -0.036170098930597305,
                -0.015315060503780842,
                0.023781588301062584,
                0.022471871227025986,
                0.004638073034584522,
                -0.025614896789193153,
                -0.024497611448168755,
                -0.03363015502691269,
                -0.015553242526948452,
                -0.0041281795129179955,
                0.035632357001304626,
                0.01373615488409996,
                -0.015459349378943443,
                0.008048339746892452,
                0.03500388562679291,
                0.037987034767866135,
                -0.007375100161880255,
                -0.06786404550075531,
                0.02933206409215927,
                0.04072786867618561,
                -0.021588031202554703,
                -0.018368348479270935,
                0.054287899285554886,
                -0.009541541337966919,
                0.01833900436758995,
                0.044554613530635834,
                0.035890962928533554,
                -0.03110397234559059,
                -0.0021269668359309435,
                -0.07464778423309326,
                -0.01850893348455429,
                0.06797555088996887,
                0.018830591812729836,
                0.017414646223187447,
                0.006019182503223419,
                -0.004592812620103359,
                -0.022114647552371025,
                0.008620182983577251,
                0.044678788632154465,
                -0.015917515382170677,
                0.05864155665040016,
                -0.03777850791811943,
                -0.027942227199673653,
                -0.014004924334585667,
                0.010079978033900261,
                0.0753522515296936,
                -0.020659230649471283,
                0.0041719903238117695,
                -0.031443044543266296,
                -0.02524694614112377,
                -0.017803946509957314,
                -0.06380444020032883,
                -0.016818521544337273,
                0.02690550498664379,
                0.07278753817081451,
                0.010498466901481152,
                -0.0342378169298172,
                -0.020893439650535583,
                0.0487634502351284,
                -0.007736866362392902,
                0.02889186516404152,
                -0.031953949481248856,
                -0.06846694648265839,
                0.0002249293029308319,
                0.026933688670396805,
                0.033661916851997375,
                -0.038451194763183594,
                -0.01735902950167656,
                0.008023623377084732,
                -0.024626996368169785,
                0.07490045577287674,
                -0.03733734413981438,
                -0.0353550910949707,
                -0.06233937293291092,
                -0.0422762893140316,
                -0.0037180136423557997,
                -0.0344838909804821,
                0.04004867747426033,
                0.042527757585048676,
                -0.013216164894402027,
                0.012601875700056553,
                -0.012315239757299423,
                -0.013941274024546146,
                0.014922795817255974,
                -0.02644617110490799,
                0.049551934003829956,
                0.003030964871868491,
                -0.07223282009363174,
                0.0025212920736521482,
                0.02654884196817875,
                -0.003604837693274021,
                -0.039270564913749695,
                0.028291955590248108,
                0.01542813703417778,
                0.019600676372647285,
                0.00703740818426013,
                0.004847212228924036,
                -0.0027574594132602215,
                0.0045515792444348335,
                -0.059338703751564026,
                -0.032824043184518814,
                0.067864328622818,
                0.000734536093659699,
                0.012027593329548836,
                0.03433654457330704,
                0.024632833898067474,
                -0.011072673834860325,
                -0.044643837958574295,
                -0.008651472628116608,
                0.03727103769779205,
                0.03930874168872833,
                0.0014157630503177643,
                0.007318486925214529,
                -0.008611857891082764,
                0.0010498685296624899,
                0.0038844647351652384,
                -0.008049208670854568,
                -0.0009062623139470816,
                -0.0028715268708765507,
                -0.0360538512468338,
                0.0017270230455324054,
                0.0013246933231130242,
                -0.02103150635957718,
                -0.03046264499425888,
                -0.004291643854230642,
                -0.023676473647356033,
                0.028735926374793053,
                0.019113460555672646,
                0.06321841478347778,
                -0.039523083716630936,
                -0.04347248002886772,
                -0.046083200722932816,
                0.0037728340830653906,
                -0.003913452383130789,
                0.00685425428673625,
                -0.016648778691887856,
                -0.0015017195837572217,
                -0.09160291403532028,
                -0.05079524591565132,
                0.015239166095852852,
                -0.01458485797047615,
                -0.02527580037713051,
                0.0036825533024966717,
                -0.04010775685310364,
                -0.04741372540593147,
                -0.0329977311193943,
                -0.04075794667005539,
                0.008136680349707603,
                -0.03555911406874657,
                -9.470401710132137e-05,
                -0.021610135212540627,
                0.012840905226767063,
                0.009757861495018005,
                0.028335388749837875,
                0.04571031406521797,
                0.03664104640483856,
                -0.0806986466050148,
                -0.058393724262714386,
                -0.0010977693600580096,
                -5.448036851791178e-33,
                -0.028699519112706184,
                -0.02923976257443428,
                -0.032806430011987686,
                0.06887287646532059,
                -0.0024591099936515093,
                -0.03987469524145126,
                -0.022447364404797554,
                -0.005536429118365049,
                0.01021250057965517,
                0.009601126424968243,
                0.027279814705252647,
                0.010544421151280403,
                0.02323225699365139,
                -0.07205794006586075,
                0.020910175517201424,
                0.04716229438781738,
                0.0054524969309568405,
                0.06445715576410294,
                -0.032812438905239105,
                -0.04884926229715347,
                -0.02713117003440857,
                -0.005740939639508724,
                0.04917619749903679,
                -0.06080421805381775,
                -0.016304459422826767,
                0.042020414024591446,
                0.036562468856573105,
                0.006173965986818075,
                -0.03166719898581505,
                -0.011561181396245956,
                0.051351793110370636,
                0.04803764447569847,
                -0.030177883803844452,
                0.02796488255262375,
                -0.04001211002469063,
                0.03965802863240242,
                -0.07750360667705536,
                0.007313844282180071,
                0.043478693813085556,
                0.007410693913698196,
                0.04008829966187477,
                0.01935208961367607,
                -0.004470960237085819,
                -0.0009653469314798713,
                -0.011591898277401924,
                0.008719556033611298,
                0.03450528532266617,
                -0.002343526342883706,
                -0.01347051840275526,
                -0.04239186644554138,
                -0.008967258967459202,
                0.033893708139657974,
                -0.0725519061088562,
                0.03355834633111954,
                0.009835639968514442,
                0.050078120082616806,
                -0.01616373471915722,
                -0.03636081516742706,
                0.03064226731657982,
                0.07023289799690247,
                0.08712344616651535,
                -0.01623939350247383,
                0.05101333186030388,
                6.653826858382672e-05,
                0.061262741684913635,
                0.014092284254729748,
                0.02723618783056736,
                -0.039622288197278976,
                0.03533613309264183,
                0.013181968592107296,
                0.007771621458232403,
                0.046764928847551346,
                0.004872300196439028,
                0.012560888193547726,
                -0.0585637204349041,
                0.03041217289865017,
                -0.05394800379872322,
                0.04479195550084114,
                0.028142984956502914,
                -0.04522176459431648,
                0.01457855012267828,
                0.03663081303238869,
                0.036034535616636276,
                -0.012496324256062508,
                -0.007867708802223206,
                -0.005527135916054249,
                -0.05598600208759308,
                -0.05810141935944557,
                -0.009703949093818665,
                -0.017894579097628593,
                -0.04648910090327263,
                0.04992126300930977,
                -0.003882611868903041,
                0.035527363419532776,
                0.03721890598535538,
                -0.0010763690806925297,
                0.021046537905931473,
                0.012853153981268406,
                0.0008834741893224418,
                0.003216152312234044,
                0.02219494991004467,
                0.022474737837910652,
                -0.026702966541051865,
                0.044881388545036316,
                0.041906919330358505,
                -0.03053584322333336,
                -0.054665952920913696,
                -0.030865099281072617,
                -0.01964818686246872,
                -0.005482630338519812,
                -0.011705907061696053,
                0.012512841261923313,
                -0.06128639355301857,
                -0.04633135721087456,
                0.021516595035791397,
                0.024009820073843002,
                0.026938138529658318,
                0.005763399414718151,
                0.010625028982758522,
                0.004402737133204937,
                -0.0036658986937254667,
                0.021186627447605133,
                -0.009913145564496517,
                -0.05679520219564438,
                0.016834253445267677,
                -0.03744291514158249,
                -0.005253258161246777,
                -0.041773777455091476,
                0.02360009029507637,
                -0.005502247717231512,
                0.0223111379891634,
                0.00216868007555604,
                2.2010682698692108e-07,
                -0.0023119288962334394,
                0.11216728389263153,
                0.018560515716671944,
                0.052365999668836594,
                0.005846061743795872,
                -0.04600830003619194,
                0.022097600623965263,
                -0.026643777266144753,
                -0.0038366024382412434,
                0.00274178315885365,
                -0.016635674983263016,
                0.017153725028038025,
                0.028831493109464645,
                0.05003592371940613,
                -0.0056282454170286655,
                -0.08754479885101318,
                0.041239313781261444,
                -0.012262508273124695,
                -0.07862693071365356,
                0.0008780281059443951,
                0.030205894261598587,
                0.01851033605635166,
                0.05253632739186287,
                0.05803942307829857,
                -0.019361091777682304,
                0.0031333027873188257,
                -0.033932559192180634,
                -0.04786423593759537,
                0.023715097457170486,
                0.011675040237605572,
                -0.02196272648870945,
                -0.021067434921860695,
                0.011175044812262058,
                0.10406550765037537,
                -0.014191673137247562,
                -0.04524410143494606,
                -0.029857855290174484,
                -0.020312625914812088,
                0.016570793464779854,
                0.03035060502588749,
                0.0436781607568264,
                -0.0027760444208979607,
                -0.00951311830431223,
                -0.02349744737148285,
                0.024365626275539398,
                0.0007171869510784745,
                0.005245302803814411,
                0.036951061338186264,
                0.0030091016087681055,
                -0.024456337094306946,
                -0.002791940001770854,
                -0.03713182732462883,
                0.017849741503596306,
                -0.0038009854033589363,
                -0.03876683861017227,
                0.012367627583444118,
                0.024022240191698074,
                0.042858291417360306,
                -0.06426280736923218,
                0.04777304828166962,
                -0.0031285518780350685,
                -0.07510413229465485,
                0.009678849019110203,
                -0.015284442342817783,
                -0.003062756732106209,
                0.02632182091474533,
                0.01210180763155222,
                1.7433585673433752e-34,
                0.013935429975390434,
                0.03571402654051781,
                -0.004817024804651737,
                -0.0023567441385239363,
                0.02595807984471321,
                -0.037516046315431595,
                0.047129709273576736,
                -0.0062326532788574696,
                0.02181469090282917,
                -0.020214851945638657,
                -0.013857214711606503,
                -0.017440009862184525,
                -0.0016682102577760816,
                -0.00645030802115798,
                0.027667943388223648,
                -0.011876621283590794,
                0.04045672342181206,
                0.04775961861014366,
                0.00914598535746336,
                -0.014956396073102951,
                -0.03196282312273979,
                0.031271595507860184,
                -0.015499749220907688,
                0.023806793615221977,
                0.08011333644390106,
                -0.03256628289818764,
                -0.034235935658216476,
                0.047582559287548065,
                -0.017642917111516,
                -0.02463797852396965,
                -0.005931005347520113,
                -0.0474638007581234,
                0.012333503924310207,
                -0.023573288694024086,
                -0.014380290172994137,
                0.00713580334559083,
                -0.032868433743715286,
                -0.0022448322270065546,
                -0.01235220767557621,
                -0.013025566004216671,
                -0.006679306272417307,
                0.02818542718887329,
                0.004429555498063564,
                0.03542175889015198,
                0.034400369971990585,
                1.5560802921754657e-06,
                -0.026622209697961807,
                -0.012114662677049637,
                0.05557769164443016,
                -0.05076497048139572,
                0.09073925763368607,
                0.07722097635269165,
                0.02360943891108036,
                0.049014732241630554,
                -0.01545710489153862,
                -0.0326421894133091,
                0.04028981924057007,
                -0.01944606564939022,
                -0.040585510432720184,
                0.06960166245698929,
                -0.008336232975125313,
                0.011976787820458412,
                0.02544216997921467,
                -0.01120363362133503,
                0.030576206743717194,
                0.000666678708512336,
                0.0023913835175335407,
                0.016166478395462036,
                0.046484578400850296,
                0.05892033502459526,
                -0.026401611045002937,
                0.05395307019352913,
                -0.022165266796946526,
                -0.004439673386514187,
                -0.01573077403008938,
                0.043580520898103714,
                0.021756721660494804,
                -0.05401584878563881,
                -0.030305854976177216,
                -0.05877291038632393,
                -0.039589568972587585,
                0.03233189135789871,
                -0.0929485335946083,
                -0.013535833917558193,
                0.020865045487880707,
                -0.05819208174943924,
                -0.12090474367141724,
                -0.06522612273693085,
                -0.0031468793749809265,
                0.0030656896997243166,
                -0.05909772589802742,
                -0.033630747348070145,
                -0.031372662633657455,
                -0.02387222647666931,
                -0.010558782145380974,
                2.6380581402918324e-05,
                0.032846394926309586,
                0.007864833809435368,
                -0.017520848661661148,
                -0.018357420340180397,
                0.04897318035364151,
                0.0359804704785347,
                -0.008402610197663307,
                0.09375394135713577,
                0.03938082605600357,
                -0.09211302548646927,
                -0.049102988094091415,
                -0.022775186225771904,
                -0.0010872776620090008,
                -0.005003304686397314,
                -0.004458181094378233,
                0.0024876210372895002,
                0.02805742248892784,
                0.04702695086598396,
                0.037402696907520294,
                -0.009805957786738873,
                -0.003118606749922037,
                -0.09363912045955658,
                0.059671368449926376,
                0.033826977014541626,
                0.030156614258885384,
                0.005306704435497522,
                -0.02017553336918354,
                -0.029923446476459503,
                -0.003787058172747493,
                0.03426388278603554,
                0.016376126557588577,
                0.0006999884499236941,
                0.07062168419361115,
                -0.034716758877038956,
                0.050171345472335815,
                0.003944288939237595,
                0.009170710109174252,
                0.028066962957382202,
                0.03771092742681503,
                -0.031041821464896202,
                -0.02080395817756653,
                0.023889392614364624,
                -0.03935316577553749,
                0.001404414651915431,
                -0.007453482132405043,
                -0.03503675386309624,
                0.0063692317344248295,
                0.020298659801483154,
                -0.040051110088825226,
                0.05037892609834671,
                0.030663739889860153,
                -0.012338167987763882,
                0.004855093080550432,
                0.03708883374929428,
                -0.03276301920413971,
                0.010408899746835232,
                -0.033457569777965546,
                -0.041766583919525146,
                -0.0632050409913063,
                -0.006976521573960781,
                0.028462838381528854,
                0.019577419385313988,
                0.006706830579787493,
                0.004302783869206905,
                0.021184777840971947,
                -0.005221028812229633,
                0.02124951034784317,
                -0.03300868719816208,
                -0.027544256299734116,
                -0.009756885468959808,
                0.02697918936610222,
                -0.0018689143471419811,
                -0.06388897448778152,
                -0.030004026368260384,
                9.680686343926936e-05,
                0.01850268803536892,
                -0.03224124386906624,
                0.011434483341872692,
                -0.06069100648164749,
                0.0028472093399614096,
                0.0039022909477353096,
                -0.024752233177423477,
                0.024124255403876305,
                0.02814856357872486,
                -0.014047520235180855,
                -0.08472589403390884,
                0.046306051313877106,
                0.014721140265464783,
                -0.019367268308997154,
                -0.05468229949474335,
                -0.004014258738607168,
                -0.03785192221403122,
                -0.015524858608841896,
                0.0369529165327549,
                0.03767824172973633,
                0.04655515402555466,
                -0.02122361958026886,
                0.006311746779829264,
                0.09347036480903625,
                0.00809041690081358,
                -0.032110292464494705,
                0.0034481913316994905,
                0.06546813249588013,
                -0.0016863684868440032,
                -0.013315466232597828,
                0.0014794262824580073,
                0.03575504571199417,
                0.008075272664427757,
                0.015448175370693207,
                0.024461720138788223,
                -0.027011960744857788,
                0.0035908238496631384,
                -0.044299181550741196,
                0.030711306259036064,
                -0.07680079340934753,
                -0.044127997010946274,
                -0.04473383352160454,
                0.02697835862636566,
                0.08498039096593857,
                0.038354601711034775,
                -0.04237321764230728,
                -0.04131876304745674,
                -0.02502558007836342,
                -0.008075783029198647,
                -0.007664828095585108,
                0.018593696877360344,
                -0.005309422500431538,
                -0.01090842392295599,
                -0.03731459006667137,
                0.002231110353022814,
                -0.0003837664262391627,
                0.008396975696086884,
                -0.00279424455948174,
                0.05563634634017944,
                -0.0009560178150422871,
                0.018799619749188423,
                0.06448589265346527,
                0.002997308736667037,
                0.0073469518683850765,
                0.02246820740401745,
                -0.02875996008515358,
                -0.012416241690516472,
                0.01362859457731247,
                0.0056097679771482944,
                0.03576136380434036,
                0.003930580802261829,
                -0.005068107042461634,
                0.03621731325984001,
                0.04000249132514,
                0.015461345203220844,
                -0.06885138899087906,
                -0.034981172531843185,
                0.031744495034217834,
                0.04711661487817764,
                -0.004310211166739464,
                -0.04699578881263733,
                0.039907149970531464,
                0.03652222827076912,
                -0.009056894108653069,
                0.06524571031332016,
                0.018731962889432907,
                -0.004890517331659794,
                -0.03819674998521805,
                0.01769568957388401,
                -0.028491834178566933,
                0.01757114753127098,
                0.0592213049530983,
                0.0005191611708141863,
                0.04736669734120369,
                0.04341072589159012,
                0.07706018537282944,
                -0.0036505761090666056,
                0.040493641048669815,
                -0.035070519894361496,
                -0.0649552121758461,
                -0.021426411345601082,
                -0.03762684017419815,
                0.021986356005072594,
                -0.0003257692151237279,
                0.029661696404218674,
                0.039701659232378006,
                0.03218106925487518,
                0.02708132192492485,
                -0.009373147040605545,
                -0.04058057814836502,
                -0.00783286802470684,
                0.041162144392728806,
                -0.019807850942015648,
                0.0035032781306654215,
                0.012646560557186604,
                0.03702979162335396,
                0.0005609110230579972,
                -0.05829361453652382,
                -0.025927163660526276,
                -0.00759548507630825,
                0.016658222302794456,
                -0.017755813896656036,
                -0.007411751430481672,
                -0.036829184740781784,
                -0.020367251709103584,
                0.041806451976299286,
                0.02765326201915741,
                -0.010254401713609695,
                -0.04004402458667755,
                0.00442383112385869,
                -0.015888582915067673,
                0.0005166015471331775,
                0.003934314474463463,
                0.008804052136838436,
                0.034992117434740067,
                -0.03759627789258957,
                -0.013472274877130985,
                0.021427402272820473,
                -0.04523715376853943,
                0.0199267640709877,
                0.010347150266170502,
                0.044369667768478394,
                0.0011956156231462955,
                0.018602941185235977,
                0.0054753487929701805,
                -0.027462219819426537,
                0.08052770793437958,
                -0.03575857728719711,
                -0.027416158467531204,
                0.05937507376074791,
                -0.036017220467329025,
                -0.033454690128564835,
                -0.03620051592588425,
                0.01860758475959301,
                0.061369068920612335,
                -0.019787967205047607,
                -0.08553832024335861,
                0.01010464783757925,
                -0.0028242531698197126,
                0.03303281590342522,
                -0.005089960526674986,
                0.01233048364520073,
                0.03879518061876297,
                -0.029743414372205734,
                -0.018473420292139053,
                -0.05350135639309883,
                -0.087697833776474,
                -0.0150084113702178,
                0.037866540253162384,
                -0.007715336512774229,
                -0.009141254238784313,
                -0.014469265006482601,
                -0.02125786989927292,
                -0.011672604829072952,
                0.06112796068191528,
                0.053764890879392624,
                -0.04518255591392517,
                -0.018149033188819885,
                0.0436408631503582,
                0.012377600185573101,
                0.01707984134554863,
                -0.0021049685310572386,
                0.004395006224513054,
                0.046906035393476486,
                -0.008310395292937756,
                -0.024600302800536156,
                0.012882749550044537,
                -0.037290412932634354,
                0.03397778049111366,
                0.008822479285299778,
                0.10572024434804916,
                0.03478904068470001,
                0.019889594987034798,
                0.016983171924948692,
                0.01087709330022335,
                -0.0073584821075201035,
                -0.05099670961499214,
                -0.0021466149482876062,
                -0.04886864870786667,
                -0.006038850639015436,
                0.021692348644137383,
                0.014337827451527119,
                0.03515607863664627,
                0.049310870468616486,
                -0.03619939833879471,
                0.0061333137564361095,
                0.023347577080130577,
                -0.037766896188259125,
                0.0016994406469166279,
                0.01963021047413349,
                -0.04633818566799164,
                -0.04663356766104698,
                -0.0568474642932415,
                -0.02867855876684189,
                0.048075053840875626,
                -0.05055494233965874,
                -0.004230403807014227,
                -0.016799496486783028,
                -0.03854987025260925,
                0.04610208421945572,
                0.01420846488326788,
                -0.025949817150831223,
                0.04435137286782265,
                0.0013302176957949996,
                0.014431152492761612,
                -0.05137518793344498,
                -0.010113591328263283,
                0.02517710067331791,
                -0.02509966865181923,
                0.05547522380948067,
                -0.010193053632974625,
                0.017465967684984207,
                -0.05369717627763748,
                -0.05803048983216286,
                -0.01661951094865799,
                -0.013251964934170246,
                -0.02190656028687954,
                0.07246390730142593,
                -0.021056292578577995,
                0.04364543408155441,
                0.018603388220071793,
                0.05146535113453865,
                0.022570867091417313,
                0.019155533984303474,
                0.07717780768871307,
                -0.0631403923034668,
                0.019488859921693802,
                0.05451164022088051,
                -0.05890731140971184,
                -0.013578356243669987,
                0.024652931839227676,
                0.02146981842815876,
                -0.003779083024710417,
                -0.05042407289147377,
                -0.020246027037501335,
                0.06220947951078415,
                -0.04476458206772804,
                -0.006964658387005329,
                0.005011063069105148,
                -0.03611741214990616,
                0.0005154922837391496,
                -0.027460547164082527,
                0.05832212045788765,
                0.004940265789628029,
                0.005603453144431114,
                -0.008275868371129036,
                -0.01615007407963276,
                0.017883233726024628,
                0.002311558462679386,
                -0.004733376204967499,
                0.038352057337760925,
                -0.052424848079681396,
                -0.04043108969926834,
                -0.03438689187169075,
                0.011851188726723194,
                -0.025604326277971268,
                -0.013580484315752983,
                0.029720725491642952,
                -0.036440409719944,
                -0.005261426791548729,
                0.06652049720287323,
                0.05878471955657005,
                0.01250817347317934,
                -0.042183712124824524,
                -0.060700759291648865,
                -0.030554715543985367,
                -0.01895879954099655,
                -0.15538530051708221,
                -0.051997728645801544,
                -0.008060913532972336,
                0.07336880266666412,
                -0.013850969262421131,
                -0.030395736917853355,
                -0.01196272112429142,
                0.03089742362499237,
                -0.07571084797382355,
                -0.011441992595791817,
                -0.04305494949221611,
                -0.018686851486563683,
                0.036816954612731934,
                0.053910765796899796,
                0.02734091877937317,
                -0.04693221673369408,
                -0.010079185478389263,
                0.04317373037338257,
                -0.03214987367391586,
                0.06458328664302826,
                -0.0029478948563337326,
                0.046984024345874786,
                -0.0717393085360527,
                -0.05435938388109207,
                0.004613787867128849,
                -0.03594948351383209,
                -0.020438367500901222,
                -0.022424176335334778,
                0.01785135827958584,
                0.02361389249563217,
                -0.011298781260848045,
                -0.02393125183880329,
                -0.01866188272833824,
                0.0920037031173706,
                0.011383135803043842,
                0.055926788598299026,
                -0.036320120096206665,
                0.004474147688597441,
                0.024881362915039062,
                0.034207336604595184,
                0.003143793437629938,
                -0.026372021064162254,
                -0.04126792773604393,
                -0.044503092765808105,
                0.003228956600651145,
                -0.03238850086927414,
                0.03787243738770485,
                0.04560799524188042,
                0.059232067316770554,
                0.004559395369142294,
                0.09173494577407837,
                -0.016036177054047585,
                0.04966461658477783,
                0.052820030599832535,
                -0.03338707983493805,
                -0.029984014108777046,
                0.016404159367084503,
                0.02623371034860611,
                0.06643874943256378,
                -0.015860825777053833,
                0.029218347743153572,
                -0.021022630855441093,
                0.012681763619184494,
                0.008235156536102295,
                0.020127935335040092,
                -0.07070425897836685,
                -0.011483728885650635,
                -0.0075551411136984825,
                -0.0010360119631513953,
                -0.027338458225131035,
                0.05080822855234146,
                -0.005295249167829752,
                -0.06721191108226776,
                -0.00505090644583106,
                -0.02797827497124672,
                0.03456263989210129,
                -0.018734993413090706,
                0.00885158684104681,
                -0.06848783791065216,
                0.02199552208185196,
                -0.017992833629250526,
                0.025643689557909966,
                0.01738775707781315,
                -0.08184895664453506,
                -0.01366415899246931,
                0.05608684569597244,
                -0.03403039649128914,
                -0.028563078492879868,
                -0.009537002071738243,
                -0.044466763734817505,
                0.007523094303905964,
                0.02143271081149578,
                0.05267426371574402,
                0.017086796462535858,
                -0.045581888407468796,
                0.031534262001514435,
                0.022859208285808563,
                0.03364435210824013,
                -0.02142687700688839,
                0.039065781980752945,
                -0.018848644569516182,
                -0.006474039983004332,
                0.03328968957066536,
                0.019770462065935135,
                0.03725752234458923,
                -0.014704244211316109,
                -0.0015954530099406838,
                -0.007485757116228342,
                -5.5394175785721946e-33,
                -0.00029227984487079084,
                -0.0318138524889946,
                -0.003766039153560996,
                0.040907323360443115,
                -0.006820515263825655,
                -0.03695095330476761,
                0.02877999097108841,
                0.024561097845435143,
                -0.002840921748429537,
                -0.01064703706651926,
                -0.004752029664814472,
                0.05637809634208679,
                0.04191874340176582,
                -0.03136051446199417,
                -0.03688492998480797,
                0.014292160980403423,
                0.0034119682386517525,
                0.03660568967461586,
                -0.012724236585199833,
                0.014707272872328758,
                0.04076363146305084,
                0.021629612892866135,
                0.03065304271876812,
                -0.06125054880976677,
                -0.0013264936860650778,
                0.033420417457818985,
                -0.003163133515045047,
                -0.0402861051261425,
                -0.020842760801315308,
                0.0070789349265396595,
                -0.009534844197332859,
                0.029955342411994934,
                -0.026488060131669044,
                -0.017206942662596703,
                0.0407755970954895,
                0.02229009009897709,
                -0.06653410941362381,
                -0.011141016148030758,
                -0.015341177582740784,
                -0.021408643573522568,
                0.06021231785416603,
                -0.03276677057147026,
                0.045776523649692535,
                -0.009882471524178982,
                -0.012996792793273926,
                0.0007896196329966187,
                -0.01828904077410698,
                0.0035928015131503344,
                -0.002530470723286271,
                -0.03158405050635338,
                -0.030702611431479454,
                -0.005921515170484781,
                -0.016326583921909332,
                0.058782003819942474,
                -0.0027877925895154476,
                -0.05304805934429169,
                -0.07999983429908752,
                -0.01834103651344776,
                0.04350252076983452,
                0.03504335507750511,
                0.02845051884651184,
                0.023388870060443878,
                -0.02631985954940319,
                -0.003507845336571336,
                0.0064726946875452995,
                -0.018858561292290688,
                0.08872736990451813,
                0.002803354524075985,
                -0.023059939965605736,
                -0.026614628732204437,
                -0.017743412405252457,
                -0.006184851750731468,
                0.009845253080129623,
                0.027797268703579903,
                -0.03598768636584282,
                -0.05986335873603821,
                -0.07916854321956635,
                -0.000932548486161977,
                0.022692270576953888,
                -0.019537055864930153,
                0.02391623891890049,
                -0.06358864903450012,
                -0.021236944943666458,
                -0.04272696003317833,
                -0.0008375521865673363,
                0.03708750382065773,
                -0.04216130077838898,
                -0.0023656561970710754,
                -0.03236310929059982,
                -0.02354944683611393,
                -0.054636400192976,
                0.11197812855243683,
                -0.025190310552716255,
                0.003982014022767544,
                0.056765712797641754,
                0.002350970171391964,
                0.031143277883529663,
                0.02304934523999691,
                0.03178204968571663,
                0.026900075376033783,
                0.009063630364835262,
                -0.003690966172143817,
                -0.05685139074921608,
                -0.03625237196683884,
                0.0595274344086647,
                -0.00902935117483139,
                -0.054874975234270096,
                0.052992843091487885,
                -0.04351082071661949,
                -0.0007771126693114638,
                -0.03156783804297447,
                0.014160537160933018,
                -0.03619909659028053,
                0.04172905907034874,
                0.034439973533153534,
                0.015183566138148308,
                0.006361866369843483,
                -0.02905261516571045,
                -0.01941128447651863,
                -0.020153000950813293,
                -0.012840417213737965,
                -0.020851513370871544,
                -0.01144997589290142,
                0.018469691276550293,
                -0.03919084370136261,
                -0.027129454538226128,
                -0.055434804409742355,
                -0.028731228783726692,
                0.011779428459703922,
                0.01876509189605713,
                -0.003595271846279502,
                0.008555077947676182,
                2.262983116452233e-07,
                0.017234493046998978,
                0.07409069687128067,
                0.01729530841112137,
                0.07580713927745819,
                -0.006606671959161758,
                -0.05269442871212959,
                -0.035139795392751694,
                0.022700130939483643,
                0.018372327089309692,
                -0.03669067099690437,
                0.01750054582953453,
                -0.0237963255494833,
                0.04177875071763992,
                -0.008851241320371628,
                0.012535330839455128,
                -0.006810528226196766,
                -0.00910148210823536,
                -0.024453144520521164,
                -0.005069655831903219,
                0.0011530191404744983,
                0.03931839019060135,
                0.00029587862081825733,
                0.04818902537226677,
                -0.009501693770289421,
                0.029494203627109528,
                -0.05974128842353821,
                -0.011860273778438568,
                -0.019261997193098068,
                0.091236911714077,
                -0.011940858326852322,
                -0.009596495889127254,
                0.044242989271879196,
                0.03080950677394867,
                0.011286390945315361,
                0.0005236510187387466,
                -0.028562115505337715,
                0.023932864889502525,
                0.09344266355037689,
                0.019796255975961685,
                0.050070442259311676,
                -0.023991184309124947,
                -0.056196946650743484,
                -0.004601588472723961,
                -0.014398133382201195,
                0.03392257168889046,
                0.02463587187230587,
                0.0023771279957145452,
                -0.03017939254641533,
                -0.002984554972499609,
                -0.003259442513808608,
                -0.0838867723941803,
                0.0029557603411376476,
                0.05823909118771553,
                0.02271702140569687,
                -0.0427568145096302,
                0.02975114993751049,
                0.012050148099660873,
                -0.02120780572295189,
                -0.028391033411026,
                0.00590506149455905,
                -0.01519647054374218,
                -0.1042279452085495,
                0.012845016084611416,
                -0.009134533815085888,
                0.06599036604166031,
                -0.030306724831461906,
                0.008999495767056942,
                1.9917440822976006e-34,
                0.034145668148994446,
                -0.02408108115196228,
                0.05412017181515694,
                0.004681621678173542,
                -0.047418951988220215,
                0.027195710688829422,
                0.04924008622765541,
                -0.051856689155101776,
                0.034338969737291336,
                -0.013011885806918144,
                -0.039432164281606674,
                -0.0037013674154877663,
                0.015113970264792442,
                -0.06175488606095314,
                0.006908487994223833,
                -0.04687563702464104,
                -0.01741066575050354,
                3.453184763202444e-05,
                -0.001357586937956512,
                -0.13407853245735168,
                0.031620293855667114,
                0.03190965950489044,
                -0.05893082544207573,
                0.012800833210349083,
                0.032090235501527786,
                -0.014164227060973644,
                -0.08830603957176208,
                -0.007184209767729044,
                -0.010743802413344383,
                -0.05990607663989067,
                -0.020563026890158653,
                -0.008374347351491451,
                -0.024971667677164078,
                -0.011279928497970104,
                -0.020819544792175293,
                0.012816046364605427,
                -0.028639215975999832,
                -0.03674187883734703,
                -0.00872455257922411,
                -0.0326157808303833,
                -0.01894375868141651,
                -0.015870044007897377,
                0.01709236204624176,
                0.02473404072225094,
                -0.0034311392810195684,
                1.4313467318061157e-06,
                -0.021320423111319542,
                0.005122646689414978,
                0.02328437939286232,
                0.0081275450065732,
                0.035742152482271194,
                0.04001181200146675,
                0.011766483075916767,
                -0.01311489474028349,
                -0.05919181928038597,
                0.014945661649107933,
                0.041127949953079224,
                0.0173775814473629,
                0.01790674217045307,
                -0.012579226866364479,
                -0.01479406002908945,
                0.0047389850951731205,
                -0.04578649625182152,
                0.01999880000948906,
                0.005731426645070314,
                0.011698474176228046,
                0.023934872820973396,
                0.0001774644188117236,
                0.01909530535340309,
                0.04848776385188103,
                0.04015851020812988,
                0.010017594322562218,
                0.0011659786105155945,
                0.05151694267988205,
                -0.03333691507577896,
                0.08714747428894043,
                0.0005787068512290716,
                0.008467262610793114,
                -0.02331341616809368,
                -0.008817323483526707,
                0.041717033833265305,
                0.03879985585808754,
                -0.0025316064711660147,
                -0.013890945352613926,
                0.06484169512987137,
                -0.05482374131679535,
                -0.019409090280532837,
                -0.06444457918405533,
                0.03130156919360161,
                -0.010521044954657555,
                -0.010694651864469051,
                0.015184453688561916,
                -0.01126785483211279,
                -0.044264599680900574,
                -0.004586459137499332,
                0.03341175615787506,
                -0.014401081949472427,
                0.048728954046964645,
                -0.008938265033066273,
                -0.02460869960486889,
                0.017150381579995155,
                0.02602412737905979,
                -0.012260327115654945,
                0.0023837198968976736,
                0.053671397268772125,
                0.0328579843044281,
                -0.04603958874940872,
                -0.0010417315643280745,
                0.06901933252811432,
                0.0165137629956007,
                0.027152124792337418,
                0.05469786375761032,
                0.008977960795164108,
                0.04047934338450432,
                0.013793478719890118,
                0.03493060916662216,
                -0.047298941761255264,
                0.020557045936584473,
                0.002215734915807843,
                -0.034061651676893234,
                0.07781296968460083,
                -0.011848809197545052,
                -0.02069440484046936,
                -0.037947218865156174,
                0.06420225650072098,
                0.044822968542575836,
                0.026123592630028725,
                0.0073289512656629086,
                0.014971619471907616,
                0.01893315277993679,
                -0.006772765424102545,
                -0.024542946368455887,
                -0.03794188052415848,
                -0.04118940606713295,
                0.0015158295864239335,
                -0.0010458503384143114,
                -0.0724426880478859,
                -0.055416420102119446,
                0.022607535123825073,
                -0.033159323036670685,
                -0.05000967159867287,
                -0.017902646213769913,
                -0.010387564077973366,
                -0.017063625156879425,
                -0.0023594966623932123,
                -0.019535265862941742,
                0.013788186945021152,
                -0.028171822428703308,
                0.06326182186603546,
                -0.006641186308115721,
                -0.017368992790579796,
                0.05797838419675827,
                -0.08134086430072784,
                -0.03077489696443081,
                -0.05699456110596657,
                -0.03815102577209473,
                -0.026850635185837746,
                -0.0005258108722046018,
                0.04775131493806839,
                0.007783317007124424,
                -0.005587839055806398,
                0.04418426379561424,
                0.005916041322052479,
                -0.020614823326468468,
                0.00888870470225811,
                -0.013147236779332161,
                0.057782962918281555,
                0.03913583979010582,
                -0.0025247102603316307,
                -0.038132499903440475,
                -0.019121339544653893,
                -0.05505739897489548,
                -0.0292573980987072,
                0.056303299963474274,
                -0.03400711342692375,
                -0.009517849422991276,
                0.040781185030937195,
                0.026358217000961304,
                -0.03061954863369465,
                0.006704032886773348,
                0.0070246923714876175,
                0.01054652500897646,
                0.06803496181964874,
                -0.04190574213862419,
                0.027196170762181282,
                -0.022098982706665993,
                0.02222423255443573,
                -0.020429836586117744,
                0.016970738768577576,
                0.04900572821497917,
                0.0394904799759388,
                0.07792271673679352,
                0.041015129536390305,
                0.012501506134867668,
                -0.00016928420518524945,
                -0.0564638152718544,
                -0.0036838797386735678,
                -0.10914517194032669,
                0.010629883967339993,
                -0.04214269667863846,
                -0.04855736717581749,
                -0.00766441086307168,
                0.034057799726724625,
                -0.01719016022980213,
                -0.03290623798966408,
                0.035339780151844025,
                0.048258569091558456,
                0.048628583550453186,
                -0.10533711314201355,
                0.004442474339157343,
                0.010548895224928856,
                -0.01146862655878067,
                0.011021167039871216,
                0.07658632099628448,
                0.051506150513887405,
                -0.01578817516565323,
                -0.022451989352703094,
                0.020461788401007652,
                0.008441895246505737,
                -0.012973461300134659,
                0.022861283272504807,
                0.04300498962402344,
                -0.0038430332206189632,
                0.03734742850065231,
                0.004432459361851215,
                -0.017706505954265594,
                -0.03840014338493347,
                0.031064361333847046,
                -0.004672908689826727,
                -0.05884307995438576,
                0.013219719752669334,
                -0.03203620761632919,
                -0.0036084880121052265,
                -0.058627594262361526,
                0.023315967991948128,
                -0.010608258657157421,
                -0.05030869320034981,
                0.0034026254434138536,
                -0.0052194744348526,
                -0.002436336362734437,
                0.021145779639482498,
                -0.013797702267765999,
                0.011116841807961464,
                0.03331923857331276,
                0.011314786970615387,
                0.02435161918401718,
                -0.022724812850356102,
                0.009811020456254482,
                0.06239955499768257,
                0.03447623923420906,
                -0.09748817980289459,
                0.009556491859257221,
                -0.07690925896167755,
                0.01632818579673767,
                -0.004600441083312035,
                0.09385818243026733,
                -0.03256048634648323,
                0.0062922085635364056,
                0.00158713071141392,
                0.05602114647626877,
                0.0005360085051506758,
                0.01867019571363926,
                -0.003988179378211498,
                -0.024142445996403694,
                0.0016715829260647297,
                0.0423772931098938,
                0.07197535037994385,
                0.0119093619287014,
                0.00152937276288867,
                -0.050787609070539474,
                -0.004503768403083086,
                0.04587392136454582,
                -0.0739029198884964,
                -0.056343890726566315,
                -0.011329293251037598,
                0.039932411164045334,
                -0.013993826694786549,
                0.034025248140096664,
                -0.014948934316635132,
                -0.07632581144571304,
                -0.008298352360725403,
                -0.020342398434877396,
                0.004850609228014946,
                0.000451145984698087,
                0.0014980252599343657,
                0.01940229721367359,
                0.026415228843688965,
                0.035495854914188385,
                0.0034916072618216276,
                -0.026539135724306107,
                0.014193637296557426,
                0.022344280034303665,
                -0.06642886996269226,
                -0.02245262637734413,
                -0.00839197263121605,
                -0.0024755632039159536,
                -1.0255204870190937e-05,
                0.04365090653300285,
                0.010478013195097446,
                -0.020699195563793182,
                -0.0241676177829504,
                0.013896006159484386,
                0.006719420664012432,
                -0.006889989599585533,
                -0.02029133215546608,
                0.010906078852713108,
                -0.04614342749118805,
                0.047306858003139496,
                -0.01335575245320797,
                -0.026825161650776863,
                0.07320930063724518,
                -0.0363757461309433,
                -0.027633823454380035,
                -0.019563129171729088,
                -0.009557513520121574,
                0.016323160380125046,
                0.003919070586562157,
                0.03103845752775669,
                -0.07147292047739029,
                0.003298060270026326,
                0.09030675143003464,
                0.0064231776632368565,
                -0.006532728206366301,
                0.019305163994431496,
                0.06759224832057953,
                0.022517330944538116,
                0.014958921819925308,
                -0.014776909723877907,
                -0.015187571756541729,
                0.03573128208518028,
                0.027184983715415,
                -0.02244001440703869,
                -0.03489234670996666,
                0.047570426017045975,
                -0.026317689567804337,
                0.009212152101099491,
                -0.026651032269001007,
                -0.04290251061320305,
                -0.01898539811372757,
                0.058149367570877075,
                0.024106692522764206,
                0.0020700516179203987,
                0.07671833038330078,
                0.014153893105685711,
                -0.009521358646452427,
                0.07025172561407089,
                0.0450584851205349,
                -0.011918335221707821,
                -0.04826382175087929,
                -0.028323743492364883,
                0.002403725404292345,
                -0.030124139040708542,
                -0.06071031093597412,
                -0.027989868074655533,
                0.027976734563708305,
                0.07068610936403275,
                0.019954247400164604,
                0.012857230380177498,
                -0.009924606420099735,
                0.03565791994333267,
                -0.0010249646147713065,
                0.03575458750128746,
                -0.034051358699798584,
                0.017414191737771034,
                -0.0006982475169934332,
                0.014655903913080692,
                0.05798147991299629,
                0.01892322301864624,
                0.018483640626072884,
                -0.032638031989336014,
                -0.0339558944106102,
                0.03718442842364311,
                -0.022079527378082275,
                0.03998717665672302,
                -0.01497784722596407,
                0.03349481150507927,
                0.036002639681100845,
                0.04601792246103287,
                0.027205070480704308,
                -0.0306929349899292,
                0.018543576821684837,
                0.011690713465213776,
                0.0186447910964489,
                -0.08031824231147766,
                -0.024653010070323944,
                -0.001977026229724288,
                -0.006466089282184839,
                0.02400592900812626,
                0.006779772229492664,
                0.04067835584282875,
                -0.003674738807603717,
                0.00481686694547534,
                -0.007325555197894573,
                0.09103579074144363,
                0.011472891084849834,
                -0.009480469860136509,
                -0.038391441106796265,
                -0.014256538823246956,
                0.0076232170686125755,
                -0.034595973789691925,
                0.062012944370508194,
                0.026219386607408524,
                -0.07886894792318344,
                -0.0800589844584465,
                0.011017903685569763,
                -0.028579536825418472,
                0.07815486937761307,
                -0.06237388774752617,
                0.02375802770256996,
                -0.038300130516290665,
                0.04684609919786453,
                0.019096992909908295,
                -0.0021397576201707125,
                -0.020405834540724754,
                -0.03950538486242294,
                0.008282899856567383,
                -0.02630743756890297,
                -0.03113739565014839,
                -0.008638637140393257,
                -0.03424571081995964,
                -0.018908128142356873,
                0.02400991879403591,
                0.03967953100800514,
                0.012019770219922066,
                -0.002218312118202448,
                -0.05692066624760628,
                0.02654002048075199,
                0.013021518476307392,
                -0.029548345133662224,
                0.022394774481654167,
                -0.02801072970032692,
                -0.013898530974984169,
                -0.0037304211873561144,
                0.006640717387199402,
                -0.022732635959982872,
                -0.011284072883427143,
                -0.04121515154838562,
                0.023015450686216354,
                0.04434477537870407,
                -0.036738406866788864,
                0.006744631100445986,
                0.012997918762266636,
                -0.026229310780763626,
                -0.07541166990995407,
                0.06697096675634384,
                -0.0691308006644249,
                -0.02984446845948696,
                -0.0664391741156578,
                -0.010014493018388748,
                -0.0069920457899570465,
                -0.035852812230587006,
                -0.016018420457839966,
                0.002703059697523713,
                0.1035708338022232,
                0.028510896489024162,
                -0.03423603996634483,
                -0.03198681026697159,
                -0.00032623339211568236,
                -0.0844254195690155,
                -0.005872104316949844,
                -0.013402346521615982,
                0.009877034462988377,
                0.017187748104333878,
                -0.09130823612213135,
                -0.04120814800262451,
                -0.057479653507471085,
                -0.022492889314889908,
                -0.03607115149497986,
                -0.04229969158768654,
                -0.005839591845870018,
                0.04142211377620697,
                0.11181484162807465,
                0.037077561020851135,
                0.026899047195911407,
                -0.04042733833193779,
                0.07770508527755737,
                -0.058757320046424866,
                -0.08087942004203796,
                -0.030916204676032066,
                -0.031268466264009476,
                -0.04419757425785065,
                -0.06275481730699539,
                0.03477899357676506,
                -0.019594410434365273,
                0.034726571291685104,
                0.014462445862591267,
                0.03334308788180351,
                -0.0043254815973341465,
                -0.030424939468503,
                -0.008248154073953629,
                0.04100656136870384,
                0.026437882333993912,
                0.03998371958732605,
                0.034741103649139404,
                0.02612229809165001,
                -0.0028902299236506224,
                0.02802087739109993,
                0.025011302903294563,
                -0.016219785436987877,
                0.009926116093993187,
                -0.0019863571505993605,
                0.029942411929368973,
                0.006444716360419989,
                -0.011907150968909264,
                -0.007855262607336044,
                0.034325744956731796,
                0.07000532746315002,
                0.06433034688234329,
                0.010736125521361828,
                0.04425722733139992,
                0.01688433438539505,
                0.00798227172344923,
                0.012212307192385197,
                -0.0032613463699817657,
                0.06925413012504578,
                -0.03699920326471329,
                0.062046319246292114,
                0.040506474673748016,
                -0.0279533751308918,
                0.0032291957177221775,
                0.01567123830318451,
                -0.017987797036767006,
                -0.026894206181168556,
                -9.825073357205838e-05,
                -0.06335514038801193,
                -0.05548768863081932,
                -0.008804408833384514,
                0.03272661194205284,
                -0.0016498287441208959,
                -0.05088981240987778,
                0.0011459703091531992,
                0.030836893245577812,
                0.0022812793031334877,
                -0.010622075758874416,
                -0.032924309372901917,
                -0.024874715134501457,
                -0.024382460862398148,
                0.01607215590775013,
                0.00692053372040391,
                -0.04095658287405968,
                0.028862930834293365,
                -0.028423544019460678,
                -0.031201649457216263,
                -0.01056737918406725,
                0.055706750601530075,
                -0.004895787686109543,
                0.0421629436314106,
                0.012393467128276825,
                0.0659610852599144,
                0.03976595774292946,
                0.011869724839925766,
                -0.02457837574183941,
                -0.016370389610528946,
                -0.024743761867284775,
                -0.008027606643736362,
                0.01421693991869688,
                0.010657194070518017,
                0.05455373227596283,
                0.00390240247361362,
                0.010635611601173878,
                0.00840673502534628,
                -0.0002523966250009835,
                0.022185761481523514,
                0.01978246495127678,
                -0.027539584785699844,
                -0.01329865213483572,
                -5.8533822014432425e-33,
                -0.04623814672231674,
                0.003505513072013855,
                -0.0036869493778795004,
                0.07012975960969925,
                -0.051402751356363297,
                -0.0661080852150917,
                0.025943012908101082,
                0.007116215769201517,
                -0.026576682925224304,
                -0.009901049546897411,
                0.0011146742617711425,
                0.007113731931895018,
                0.002392204012721777,
                0.0120905926451087,
                0.03315639868378639,
                0.015783162787556648,
                0.02944147028028965,
                0.02879144996404648,
                -0.05155400559306145,
                -0.05566267669200897,
                -0.08172343671321869,
                0.007881321012973785,
                0.05855340510606766,
                -0.028778953477740288,
                0.000834931677673012,
                -0.019505510106682777,
                0.0076425569131970406,
                -0.03576769307255745,
                -0.029144195839762688,
                -0.0441497378051281,
                -0.02118675783276558,
                0.007317816372960806,
                -0.011675459332764149,
                0.03589095175266266,
                0.008997044526040554,
                0.021891172975301743,
                -0.0681554526090622,
                -0.07189387828111649,
                0.022232862189412117,
                0.03218158707022667,
                0.014825328253209591,
                -0.11470696330070496,
                -0.030708523467183113,
                -0.05360585078597069,
                -0.032308679074048996,
                -0.003076924942433834,
                0.002753450768068433,
                0.010754882358014584,
                0.062148239463567734,
                -0.04597852751612663,
                -0.06009308993816376,
                -0.02201589196920395,
                -0.04129766300320625,
                0.031958840787410736,
                -0.04117461293935776,
                -0.03905806317925453,
                0.0018631108105182648,
                -0.06522523611783981,
                -0.0019524692324921489,
                0.05044799670577049,
                0.04146118462085724,
                0.008798387832939625,
                -0.02644413523375988,
                -0.04165903106331825,
                0.024178393185138702,
                0.015662474557757378,
                0.06216757372021675,
                0.04329345375299454,
                -0.01377992145717144,
                0.03098500706255436,
                -0.015809260308742523,
                0.019556542858481407,
                -0.04752083122730255,
                -0.036998093128204346,
                0.045558732002973557,
                0.0641576275229454,
                -0.008430730551481247,
                -0.008362645283341408,
                0.07085024565458298,
                0.07109889388084412,
                -0.03749698027968407,
                0.022695953026413918,
                -0.009426355361938477,
                -0.03958535194396973,
                0.008942922577261925,
                -0.036169059574604034,
                0.0055930581875145435,
                0.011243722401559353,
                -0.02031523734331131,
                -0.022080352529883385,
                0.031300246715545654,
                -0.009983975440263748,
                -0.02786564640700817,
                0.009531513787806034,
                0.0008291823323816061,
                -0.02278539352118969,
                0.04931017383933067,
                0.04984964802861214,
                0.020937733352184296,
                0.014834127388894558,
                -0.03991697356104851,
                -0.001069625373929739,
                -0.05954683572053909,
                -0.003627799218520522,
                0.0129468385130167,
                0.044502902776002884,
                -0.044234540313482285,
                0.0564890056848526,
                0.03499068692326546,
                -0.013173257000744343,
                0.002868795767426491,
                -0.03722141310572624,
                0.03294854238629341,
                0.045471105724573135,
                0.03156145289540291,
                -0.007764996960759163,
                -0.01007917057722807,
                -0.010213658213615417,
                -0.015282944776117802,
                0.014564994722604752,
                -0.01805904507637024,
                0.015114977955818176,
                -0.017669206485152245,
                0.018729686737060547,
                0.06552889943122864,
                -0.03613131120800972,
                -0.02324136160314083,
                -0.03808198869228363,
                -0.0034315469674766064,
                -0.005723481997847557,
                -0.031094469130039215,
                0.02428595721721649,
                2.233217344382865e-07,
                0.0014221977908164263,
                0.008775570429861546,
                0.04942522570490837,
                0.02890111319720745,
                -0.008466589264571667,
                -0.08021542429924011,
                -0.032668814063072205,
                0.03323221206665039,
                -0.024213289842009544,
                0.04284736514091492,
                0.07318906486034393,
                0.008414439857006073,
                -0.025224260985851288,
                -0.025401558727025986,
                -0.044225890189409256,
                -0.050034262239933014,
                0.04365970566868782,
                -0.02475750260055065,
                0.012918681837618351,
                -0.023843636736273766,
                -0.027608096599578857,
                0.03543209657073021,
                0.018600113689899445,
                0.017669305205345154,
                0.002390447538346052,
                0.029108893126249313,
                0.012395699508488178,
                -0.010866538621485233,
                0.06232355162501335,
                0.039524827152490616,
                -0.011607896536588669,
                -0.008682049810886383,
                0.0008167377091012895,
                -0.0020752535201609135,
                0.008100796490907669,
                -0.006006699521094561,
                0.058529648929834366,
                0.01960025355219841,
                0.019461676478385925,
                0.055135346949100494,
                -0.04724031314253807,
                -0.07269906252622604,
                0.005911806132644415,
                0.0221381988376379,
                0.02057194709777832,
                0.025214437395334244,
                0.03150944039225578,
                0.08462227880954742,
                -0.10390723496675491,
                -6.584307266166434e-05,
                -0.027596239000558853,
                -0.006155629176646471,
                -0.005816184915602207,
                -0.0056307255290448666,
                -0.006654410157352686,
                -0.014392055571079254,
                0.022573018446564674,
                0.040768373757600784,
                0.04700048640370369,
                0.07579100877046585,
                -0.03449959307909012,
                -0.005739101208746433,
                0.01911383680999279,
                -0.01581837236881256,
                0.06002135947346687,
                -0.017968719825148582,
                -0.03881295025348663,
                1.6741103558485525e-34,
                0.042208947241306305,
                -0.03194243460893631,
                0.029872814193367958,
                0.04872579500079155,
                -0.03312353789806366,
                -0.006956482771784067,
                -0.0323951430618763,
                -0.0224804375320673,
                0.03475550562143326,
                0.018590426072478294,
                -0.026345793157815933,
                0.04116611182689667,
                -0.005031062755733728,
                -0.05093121528625488,
                0.012422843836247921,
                0.010867328383028507,
                0.06284096837043762,
                -0.042592838406562805,
                -0.028542686253786087,
                -0.02761407010257244,
                0.023098479956388474,
                0.06382384151220322,
                0.010822718963027,
                0.03916795551776886,
                0.08358152955770493,
                0.008632516488432884,
                -0.06822612881660461,
                0.04787711799144745,
                -0.02788507007062435,
                -0.05285138636827469,
                -0.002769747283309698,
                -0.010317242704331875,
                0.03234555199742317,
                -0.029554473236203194,
                -0.0023720383178442717,
                -0.01247328333556652,
                -0.05274760350584984,
                -0.02546151913702488,
                -0.03548287972807884,
                -0.02378072403371334,
                0.07091763615608215,
                -0.01821587234735489,
                -0.019800031557679176,
                0.014674235135316849,
                -0.008992759510874748,
                1.745319195833872e-06,
                -0.01819373108446598,
                -0.014538083225488663,
                0.013218877837061882,
                -0.01615850254893303,
                0.015519504435360432,
                0.02350686490535736,
                0.040816955268383026,
                -0.021497955545783043,
                0.044735100120306015,
                -0.018398122861981392,
                -0.026035750284790993,
                0.07563774287700653,
                -0.08483407646417618,
                0.020192328840494156,
                0.02078304812312126,
                0.007596279960125685,
                0.0037156296893954277,
                -0.030643394216895103,
                -0.0074392459355294704,
                0.0019722923170775175,
                -0.01647626981139183,
                0.0004220535629428923,
                0.03854045271873474,
                0.09984932094812393,
                0.025460941717028618,
                0.00662009883671999,
                -0.03888444975018501,
                0.006163341924548149,
                0.012450200505554676,
                0.03023524023592472,
                0.01586197502911091,
                -0.02536405622959137,
                -0.025103578343987465,
                0.009638614021241665,
                -0.014873737469315529,
                0.01986120454967022,
                -0.001048633479513228,
                0.015647565945982933,
                0.06834392249584198,
                -0.04079733043909073,
                -0.061532724648714066,
                -0.02641117200255394,
                0.033023808151483536,
                0.00875790324062109,
                -0.034689243882894516,
                -0.0013950415886938572,
                -0.04787444695830345,
                -0.029047919437289238,
                -0.00546054495498538,
                0.03575977683067322,
                0.022544045001268387,
                -0.005298995412886143,
                -0.04737594723701477,
                -0.03987370431423187,
                0.01526729203760624,
                0.0010922730434685946,
                -0.02430824004113674,
                0.016642747446894646,
                0.05203067883849144,
                0.023637529462575912,
                -0.001552437897771597,
                -0.020588599145412445,
                0.06168070062994957,
                -0.011273100972175598,
                -0.00026056013302877545,
                -0.017040450125932693,
                0.006440190132707357,
                0.01950678788125515,
                0.034857213497161865,
                0.025044094771146774,
                -0.003424386028200388,
                0.0015802942216396332,
                0.01479082740843296,
                -0.02276424877345562,
                0.07932671159505844,
                -0.07398096472024918,
                -0.0399586483836174,
                -0.051489461213350296,
                0.01465650089085102,
                0.05491726100444794,
                0.028909457847476006,
                -0.020281599834561348,
                0.03946401923894882,
                0.003947432152926922,
                0.038129549473524094,
                -0.06825294345617294,
                -0.01680676080286503,
                -0.006124102510511875,
                0.042683787643909454,
                0.014184252358973026,
                -0.03888235613703728,
                -0.037348080426454544,
                -0.014478255994617939,
                -0.004506199155002832,
                -0.013887112028896809,
                -0.0014301028568297625,
                -0.009186513721942902,
                -0.034888360649347305,
                -0.029846690595149994,
                0.011592723429203033,
                0.056661538779735565,
                -0.05525965988636017,
                0.07347160577774048,
                -0.03929857909679413,
                -0.02854253351688385,
                0.013205548748373985,
                -0.038880638778209686,
                -0.013333318755030632,
                -0.0693715363740921,
                -0.024912036955356598,
                0.03253187611699104,
                0.005244947504252195,
                0.01718798093497753,
                0.003852050518617034,
                0.03466770797967911,
                -0.018432950600981712,
                0.026233237236738205,
                -0.05758173391222954,
                -0.019169988110661507,
                0.08534456044435501,
                0.022762887179851532,
                0.01370440237224102,
                -0.023538863286376,
                -0.017565200105309486,
                -0.0050742169842123985,
                -0.0029000246431678534,
                -0.026758674532175064,
                0.08709818869829178,
                -0.022552430629730225,
                -0.027638163417577744,
                -0.014589200727641582,
                -0.00032460171496495605,
                0.018183397129178047,
                0.0090918755158782,
                -0.018427634611725807,
                -0.030446572229266167,
                -0.008275782689452171,
                -0.030733542516827583,
                0.055189575999975204,
                0.0073440526612102985,
                0.0016611445462331176,
                0.019148623570799828,
                0.04979358986020088,
                0.013089258223772049,
                -0.013978318311274052,
                0.0071048494428396225,
                -0.07630279660224915,
                -0.018125221133232117,
                0.0649445503950119,
                -0.011022234335541725,
                0.029162758961319923,
                -0.05453217402100563,
                0.03017202764749527,
                -0.018439538776874542,
                -0.024473566561937332,
                0.03206801414489746,
                0.0024090020451694727,
                -0.012937314808368683,
                -0.00300060142762959,
                0.0414467416703701,
                0.004327225964516401,
                0.019577346742153168,
                0.007026804145425558,
                -0.017727119848132133,
                -0.05856155604124069,
                -0.04000305384397507,
                -0.04332743212580681,
                -0.03108259104192257,
                0.1023954525589943,
                -0.004256566986441612,
                -0.05677761137485504,
                0.025771284475922585,
                -0.007785128895193338,
                0.020276637747883797,
                -0.042604487389326096,
                0.0050813318230211735,
                0.009736643172800541,
                -0.024934493005275726,
                0.016636019572615623,
                -0.0028534068260341883,
                -0.01974869705736637,
                0.004634397104382515,
                0.035279639065265656,
                7.506419933633879e-05,
                0.0053945267572999,
                -0.035909224301576614,
                0.021414978429675102,
                0.019146086648106575,
                0.014985799789428711,
                -0.03636394813656807,
                -0.09483655542135239,
                -0.03683333471417427,
                0.02011018432676792,
                -0.05059250444173813,
                -0.0029344975482672453,
                -0.023572009056806564,
                0.0365419015288353,
                0.04625045508146286,
                0.01017048116773367,
                0.03814753517508507,
                -0.032267894595861435,
                0.022103160619735718,
                -0.00319674052298069,
                0.04464387893676758,
                -0.06326019018888474,
                -0.044924236834049225,
                -0.012361162342131138,
                0.030856462195515633,
                0.02364473231136799,
                0.1043463945388794,
                -0.006588328629732132,
                -0.003937187138944864,
                0.029853837564587593,
                0.018059048801660538,
                -0.05042724683880806,
                -0.07261829078197479,
                0.019842782989144325,
                0.03778736665844917,
                0.04127829149365425,
                0.08127486705780029,
                0.023157017305493355,
                0.003648428712040186,
                0.01801695115864277,
                -0.05979713797569275,
                -0.030160561203956604,
                -0.0215165838599205,
                -0.046195778995752335,
                0.006834619678556919,
                -0.036846987903118134,
                0.06270243227481842,
                0.009709070436656475,
                -0.005500562954694033,
                -0.05213071405887604,
                -0.06959085911512375,
                -0.0037816138938069344,
                -0.017059575766324997,
                0.011516205966472626,
                -0.006608293391764164,
                -0.008694402873516083,
                -0.02439871057868004,
                0.010034700855612755,
                -0.016615591943264008,
                -0.08079294860363007,
                -0.03381560370326042,
                0.02759825997054577,
                0.003206222550943494,
                -0.04360884055495262,
                -0.044448625296354294,
                -0.05242534726858139,
                -0.003920382354408503,
                0.010638095438480377,
                0.030231811106204987,
                -0.06242423877120018,
                -0.037508342415094376,
                0.039977118372917175,
                -0.03338272497057915,
                -0.0012949066003784537,
                0.0036014560610055923,
                -0.023757420480251312,
                0.002897667931392789,
                -0.04146699234843254,
                -0.009362731128931046,
                -0.005321154370903969,
                -0.02433967776596546,
                0.033033810555934906,
                0.01848788931965828,
                -0.004064759239554405,
                0.04770040884613991,
                -0.021271998062729836,
                0.006223278120160103,
                -0.011661270633339882,
                0.07850199192762375,
                -0.045213889330625534,
                -0.012619195505976677,
                0.06685592234134674,
                0.04619203135371208,
                0.02203284576535225,
                0.01663530059158802,
                0.040850576013326645,
                0.01099957525730133,
                0.02322363294661045,
                -0.05507948622107506,
                -0.0003920646267943084,
                -0.005684795323759317,
                0.07539614289999008,
                -0.021219119429588318,
                -0.02459547109901905,
                0.061591774225234985,
                -0.02093946933746338,
                0.01450484711676836,
                0.0007233432261273265,
                -0.046217650175094604,
                0.006252145394682884,
                0.04327218979597092,
                -0.04382086172699928,
                0.016285886988043785,
                -0.02197224274277687,
                -0.054500117897987366,
                0.02300339937210083,
                -0.04213417321443558,
                0.06305823475122452,
                -0.04468519613146782,
                -0.022369351238012314,
                -0.041720353066921234,
                0.03134577348828316,
                -0.01071993913501501,
                -0.030650654807686806,
                -0.0002448403974995017,
                0.022757638245821,
                -0.0036180815659463406,
                -0.013043402694165707,
                0.04999421536922455,
                0.03655952587723732,
                -0.005466416012495756,
                -0.030078470706939697,
                0.059935133904218674,
                0.0615682378411293,
                0.03034735843539238,
                -0.020712537690997124,
                -0.004536191467195749,
                -0.021799126639962196,
                -0.06504261493682861,
                -0.036247000098228455,
                -0.05937080830335617,
                -0.04975138232111931,
                -0.02005791664123535,
                -0.002402989659458399,
                0.026852242648601532,
                0.03791369870305061,
                -0.022899549454450607,
                0.040926482528448105,
                0.056251268833875656,
                -0.0034098417963832617,
                -0.05004093423485756,
                0.018794164061546326,
                0.027248844504356384,
                0.04121651500463486,
                -0.04777452349662781,
                0.006344239227473736,
                0.035824622958898544,
                0.01633443683385849,
                0.03350945934653282,
                0.01611650176346302,
                -0.07611825317144394,
                0.03153113275766373,
                0.047340720891952515,
                -0.10717855393886566,
                -0.0011379705974832177,
                -0.006201907992362976,
                0.0440935455262661,
                -0.0347936637699604,
                0.008006039075553417,
                0.018156301230192184,
                -0.020541658625006676,
                0.04503418877720833,
                0.01484132930636406,
                -0.023976972326636314,
                -0.06789854913949966,
                -0.059046123176813126,
                0.014082332141697407,
                0.012940510176122189,
                -0.007562147919088602,
                0.05597155913710594,
                -0.02995866723358631,
                0.033149611204862595,
                -0.0029855782631784678,
                -0.013438129797577858,
                0.014666244387626648,
                0.04239777848124504,
                0.054677460342645645,
                -0.026108693331480026,
                0.09486174583435059,
                -0.023930810391902924,
                -0.023532558232545853,
                -0.004935692995786667,
                0.033064454793930054,
                0.025686923414468765,
                0.03895876184105873,
                -0.04119286313652992,
                -0.010980664752423763,
                -0.0057327342219650745,
                -0.04409823939204216,
                0.0051926858723163605,
                -0.0008772471337579191,
                -0.05974047631025314,
                -0.02300027571618557,
                0.004978231154382229,
                0.030889201909303665,
                -0.017157118767499924,
                0.026688890531659126,
                0.0006162506178952754,
                0.006251879036426544,
                0.045270681381225586,
                0.0036424282006919384,
                -0.02275543473660946,
                -0.04233400523662567,
                -0.009092103689908981,
                -0.08496411144733429,
                0.011922478675842285,
                0.013704784214496613,
                -0.058739542961120605,
                -0.06827838718891144,
                0.02126176469027996,
                0.01764875091612339,
                -0.03647395595908165,
                0.05504525825381279,
                0.07566355168819427,
                0.02669546939432621,
                -0.001228907029144466,
                -0.08735215663909912,
                0.08635186403989792,
                0.044615715742111206,
                -0.1071571558713913,
                -0.08343853801488876,
                0.03632484748959541,
                0.07956259697675705,
                -0.031785473227500916,
                -0.03903880715370178,
                -0.04797978326678276,
                -0.011182503774762154,
                -0.0349428728222847,
                -0.0075700730085372925,
                -0.004576453473418951,
                -0.013621898368000984,
                0.00615599425509572,
                0.06355030834674835,
                0.03410696983337402,
                -0.06099306046962738,
                -0.0064410981722176075,
                0.03512294963002205,
                -0.1227937862277031,
                0.04400978237390518,
                -0.02759850025177002,
                0.04255499690771103,
                -0.04740668088197708,
                -0.020983032882213593,
                0.03295615687966347,
                -0.05799080803990364,
                -0.04024241492152214,
                -0.0453995056450367,
                0.037800952792167664,
                -0.01254996657371521,
                -0.06158863380551338,
                0.031048420816659927,
                0.0028672160115092993,
                0.005738923791795969,
                -0.03753761574625969,
                0.02511785365641117,
                -0.0014825804391875863,
                -0.0034956266172230244,
                0.009154988452792168,
                0.017566794529557228,
                -0.01673976145684719,
                -0.0031239769887179136,
                -0.009966185316443443,
                0.004448646679520607,
                0.016370607540011406,
                0.03565211221575737,
                -0.03965829685330391,
                -0.012938490137457848,
                0.00031814389512874186,
                -0.02919997274875641,
                0.04088401794433594,
                0.005024997983127832,
                0.03809539973735809,
                0.02658192813396454,
                0.008513371460139751,
                -0.04301908612251282,
                0.01412458810955286,
                -0.02645840123295784,
                0.028752649202942848,
                0.019804418087005615,
                0.03897693380713463,
                -0.010099154897034168,
                0.045281101018190384,
                -0.02074907347559929,
                -0.004076206590980291,
                -0.018468737602233887,
                -0.08062449842691422,
                0.05381738394498825,
                0.06175834685564041,
                0.028008168563246727,
                -0.024570273235440254,
                -0.05731053277850151,
                -0.009219483472406864,
                0.011786606162786484,
                -0.022297097370028496,
                0.001051058410666883,
                -0.0007363996119238436,
                0.008572082035243511,
                -0.07720312476158142,
                0.0546693429350853,
                0.004355160985141993,
                -0.03878531977534294,
                0.08059415221214294,
                -0.04981038346886635,
                0.01342718955129385,
                -0.008413022384047508,
                -0.028365394100546837,
                -0.021368853747844696,
                0.07738807052373886,
                0.007805320899933577,
                0.013165293261408806,
                0.004920943174511194,
                0.015083770267665386,
                0.0188926849514246,
                -0.01989588886499405,
                -0.05705598369240761,
                0.029015682637691498,
                0.01874307170510292,
                0.004567728843539953,
                0.043809227645397186,
                0.039814725518226624,
                0.008334824815392494,
                0.06082065403461456,
                -0.028005974367260933,
                -0.002510328544303775,
                0.026758788153529167,
                0.011857748962938786,
                0.018741639330983162,
                -6.535055783588903e-33,
                -0.021765684708952904,
                -0.031765587627887726,
                0.008898875676095486,
                0.056844450533390045,
                0.001877133152447641,
                -0.02787458710372448,
                0.020946769043803215,
                0.05867261812090874,
                -0.026228200644254684,
                -0.029884114861488342,
                -0.0032003126107156277,
                -0.013005462475121021,
                0.033312246203422546,
                -0.010969629511237144,
                0.02950507588684559,
                -0.007192197255790234,
                0.01992103084921837,
                0.05060361325740814,
                -0.03132672980427742,
                -0.03539600595831871,
                0.025552723556756973,
                -0.005999767687171698,
                0.02194143645465374,
                0.004494699649512768,
                0.003158342558890581,
                -0.008759275078773499,
                -0.015902811661362648,
                0.011480050161480904,
                0.03291819989681244,
                -0.012630672194063663,
                0.02539028227329254,
                0.011207416653633118,
                -0.027562804520130157,
                0.020350299775600433,
                -0.00862384308129549,
                0.024580184370279312,
                -0.05853191763162613,
                -0.04249076545238495,
                0.005498263984918594,
                0.029451321810483932,
                0.00714042829349637,
                -0.026869244873523712,
                0.05382651835680008,
                -0.020842067897319794,
                -0.01508590579032898,
                -0.022955412045121193,
                0.025417283177375793,
                -0.030484767630696297,
                0.06922908127307892,
                0.024390069767832756,
                -0.05661715567111969,
                -0.015024248510599136,
                -0.0638057217001915,
                -0.028905276209115982,
                0.01818947121500969,
                -0.06810054928064346,
                -0.0034528234973549843,
                -0.03958399221301079,
                0.0012250399449840188,
                0.045812301337718964,
                0.03246992453932762,
                0.034603118896484375,
                0.03110012598335743,
                0.0075684660114347935,
                0.002110360423102975,
                -0.012262203730642796,
                0.010810477659106255,
                0.022630790248513222,
                0.006608161143958569,
                0.052484285086393356,
                -0.024546809494495392,
                0.018609710037708282,
                0.006930041592568159,
                -0.010666548274457455,
                0.030519457533955574,
                -0.01857537403702736,
                0.002965187653899193,
                -0.026692036539316177,
                0.033999837934970856,
                -0.06766694784164429,
                -0.04218188300728798,
                -0.0012337126536294818,
                0.0073676807805895805,
                -0.040110792964696884,
                0.020750269293785095,
                0.0062780012376606464,
                -0.03151313215494156,
                0.009466399438679218,
                -0.007989813573658466,
                0.03273847699165344,
                -0.012341879308223724,
                0.012709894217550755,
                -0.053178753703832626,
                -0.007996146567165852,
                0.014520896598696709,
                0.007516003679484129,
                -0.0002990345819853246,
                0.026122551411390305,
                -0.027852464467287064,
                -0.01095323171466589,
                -0.020905984565615654,
                -0.013420652598142624,
                -0.044337764382362366,
                -0.003321563359349966,
                0.05192461237311363,
                0.018761372193694115,
                -0.045602947473526,
                0.03985948488116264,
                -0.02129346691071987,
                -0.015596864745020866,
                0.03560139611363411,
                0.04275698587298393,
                0.016515465453267097,
                0.06290329992771149,
                0.02752760611474514,
                -0.029355378821492195,
                0.012276728637516499,
                -0.06136235594749451,
                -0.043851498514413834,
                -0.027534926310181618,
                -0.03200928494334221,
                -8.194005204131827e-05,
                -0.06459398567676544,
                0.017546026036143303,
                -0.032658305019140244,
                -0.053304847329854965,
                -0.0446833074092865,
                0.007421818096190691,
                -0.002072829520329833,
                -0.03889811411499977,
                -0.037508584558963776,
                0.046078164130449295,
                2.3252491132552677e-07,
                0.013460073620080948,
                0.03435589373111725,
                0.04005187749862671,
                0.07001034915447235,
                -0.012130778282880783,
                -0.010421698912978172,
                -0.027987288311123848,
                0.0353890061378479,
                0.03732383996248245,
                -0.0046231490559875965,
                -0.02924051694571972,
                0.009694150649011135,
                0.005865880753844976,
                -0.0054031722247600555,
                0.011403106153011322,
                0.01725054159760475,
                0.0009793166536837816,
                0.037201765924692154,
                -0.028129691258072853,
                0.020014885812997818,
                0.09789008647203445,
                0.03520926088094711,
                0.007556522265076637,
                -0.006675571668893099,
                0.01953568309545517,
                -0.06392030417919159,
                0.021832693368196487,
                0.01577807031571865,
                0.07228095084428787,
                0.0033872846979647875,
                -0.09229370206594467,
                0.011781643144786358,
                0.003801588434726,
                0.05625750869512558,
                -0.007256637327373028,
                -0.05903063714504242,
                0.037991706281900406,
                0.04226537048816681,
                0.01666014827787876,
                0.09793208539485931,
                0.022833729162812233,
                -0.0328638032078743,
                -0.028805091977119446,
                0.010331236757338047,
                0.03384470194578171,
                0.021339217200875282,
                0.024803679436445236,
                0.04521036148071289,
                -0.020656628534197807,
                -0.0449397899210453,
                -0.050555270165205,
                -0.014335162937641144,
                0.05518397316336632,
                0.0056387255899608135,
                -0.0019612517207860947,
                -0.0015986711950972676,
                -0.04278487339615822,
                0.09504544734954834,
                -0.00928522553294897,
                0.038722358644008636,
                -0.015148072503507137,
                -0.06135477498173714,
                -0.018105126917362213,
                0.0492081381380558,
                0.07701031118631363,
                0.04147785156965256,
                0.013055343180894852,
                1.1550134280578727e-34,
                0.013181544840335846,
                -0.037429165095090866,
                0.02439919486641884,
                -0.02469414286315441,
                -0.014574418775737286,
                0.022445237264037132,
                -0.009183491580188274,
                -0.017843125388026237,
                -0.008597702719271183,
                0.043044209480285645,
                -0.027432698756456375,
                -0.026539525017142296,
                0.04217882826924324,
                -0.03540867939591408,
                -0.002780284034088254,
                0.018909482285380363,
                0.021415166556835175,
                0.0004226444289088249,
                0.013417091220617294,
                -0.05378938093781471,
                0.023133689537644386,
                0.04924942925572395,
                -0.020075716078281403,
                0.07060215622186661,
                0.08514893054962158,
                0.022953759878873825,
                -0.05369284376502037,
                0.022054918110370636,
                0.048542771488428116,
                -0.1472969502210617,
                0.0039190687239170074,
                0.005253227427601814,
                -0.028105953708291054,
                -0.005607851780951023,
                -0.004530403297394514,
                -0.00519721070304513,
                0.012150310911238194,
                -0.029517367482185364,
                0.0200785081833601,
                -0.006311079487204552,
                -0.051845621317625046,
                0.05027829855680466,
                0.04094333574175835,
                0.039837662130594254,
                0.014889040030539036,
                1.7544540469316416e-06,
                -0.014413908123970032,
                -0.015839461237192154,
                -0.004136768635362387,
                -0.07794758677482605,
                0.00840958021581173,
                0.02201075293123722,
                0.002382084494456649,
                0.016545157879590988,
                -0.007220636587589979,
                0.010368703864514828,
                -0.05067495256662369,
                0.04694930836558342,
                0.01357867568731308,
                -0.0007075475296005607,
                -0.020524531602859497,
                0.0030803747940808535,
                0.028423679992556572,
                0.004891541786491871,
                -0.03423354774713516,
                0.0995251014828682,
                0.08598847687244415,
                -0.0045211403630673885,
                0.06881600618362427,
                0.13524271547794342,
                0.04138234630227089,
                0.0003212295414414257,
                0.046530891209840775,
                0.011345034465193748,
                0.010600075125694275,
                0.053513675928115845,
                0.027161041274666786,
                -0.04881124943494797,
                -0.014676405116915703,
                0.03621189668774605,
                0.06673415750265121,
                0.09346877783536911,
                -0.0491226501762867,
                0.016024796292185783,
                0.012101825326681137,
                -0.03059258684515953,
                -0.026164323091506958,
                -0.07444783300161362,
                -0.02397749572992325,
                -0.011173193342983723,
                0.01776016876101494,
                -0.07718292623758316,
                -0.00529952347278595,
                -0.0036944434978067875,
                0.028540827333927155,
                0.06670159101486206,
                -0.00036081462167203426,
                -0.029914136976003647,
                -0.0219729021191597,
                0.03134062886238098,
                0.011685105971992016,
                0.024675846099853516,
                -0.012403042055666447,
                -0.04695278778672218,
                0.0026080289389938116,
                0.049084823578596115,
                -0.04729226604104042,
                0.0006978701567277312,
                0.03899313509464264,
                0.04154767468571663,
                -0.011886210180819035,
                0.038117095828056335,
                0.010133636184036732,
                -0.02905035950243473,
                0.04058350250124931,
                0.06297588348388672,
                -0.09799976646900177,
                0.00026594509836286306,
                -0.013359524309635162,
                0.0016660802066326141,
                0.041926637291908264,
                -0.020934486761689186,
                -0.02330997958779335,
                -0.0006230999133549631,
                0.04861854389309883,
                -0.0026285885833203793,
                0.010763203725218773,
                0.023129139095544815,
                -0.05930880084633827,
                0.031194578856229782,
                0.02379859983921051,
                0.037292372435331345,
                0.005694912746548653,
                -0.01316879503428936,
                -0.00024064676836133003,
                -0.008315137587487698,
                -0.015109655447304249,
                -0.04501445218920708,
                0.053786423057317734,
                0.021043576300144196,
                -0.08179265260696411,
                -0.012618932873010635,
                -0.015972763299942017,
                0.020888783037662506,
                -0.010769468732178211,
                0.04379298537969589,
                -0.015892304480075836,
                0.022877534851431847,
                -0.006206905469298363,
                -0.09006946533918381,
                -0.00783752091228962,
                0.06507862359285355,
                -0.03195783123373985,
                -0.010909626260399818,
                0.0010047715622931719,
                0.020659189671278,
                -0.0010394802084192634,
                -0.001134799444116652,
                0.013583410531282425,
                0.015486652962863445,
                -0.00413816561922431,
                -0.0022460001055151224,
                0.031200146302580833,
                -0.015654223039746284,
                0.004071165807545185,
                0.03235481679439545,
                0.05026254430413246,
                -0.0028153378516435623,
                -0.0025457623414695263,
                -0.02285183034837246,
                0.007222726475447416,
                -0.023542117327451706,
                -0.023802094161510468,
                0.04437548667192459,
                0.01384830567985773,
                0.014023283496499062,
                0.006876884028315544,
                -0.06518623977899551,
                0.012060492299497128,
                -0.06618928164243698,
                -0.005526569206267595,
                -0.06952275335788727,
                -0.017241409048438072,
                -0.04647647961974144,
                0.08873400092124939,
                0.05416397377848625,
                0.006041467655450106,
                0.02378981187939644,
                -0.04939700663089752,
                -0.08385494351387024,
                0.05689502879977226,
                0.06060316041111946,
                -0.15660984814167023,
                0.0039381724782288074,
                0.0476791188120842,
                0.03554911911487579,
                -0.006121195387095213,
                -0.06740234047174454,
                0.026513155549764633,
                -0.04827350378036499,
                -0.0367882177233696,
                -0.025307700037956238,
                -0.011947356164455414,
                -0.03763226419687271,
                0.025188030675053596,
                0.00200978247448802,
                -0.016692601144313812,
                0.06025693565607071,
                -0.017264585942029953,
                0.017900871112942696,
                -0.020552251487970352,
                -0.0075280736200511456,
                0.03354001045227051,
                0.014522964134812355,
                0.056452035903930664,
                -0.01578461192548275,
                -0.022939853370189667,
                -0.006179428659379482,
                -0.026647822931408882,
                0.035140715539455414,
                -0.00981244444847107,
                -0.031692083925008774,
                -0.009193920530378819,
                0.034960467368364334,
                -0.04014928266406059,
                -0.023918870836496353,
                0.011365469545125961,
                0.02885262295603752,
                0.009821771644055843,
                -0.033247195184230804,
                0.03368892893195152,
                0.007920699194073677,
                0.02804110199213028,
                0.003926900681108236,
                0.01468626782298088,
                -9.237283666152507e-05,
                0.014364887960255146,
                0.025011282414197922,
                0.016223078593611717,
                0.02389146201312542,
                0.03839241713285446,
                0.017809387296438217,
                -0.019984450191259384,
                -0.002671747701242566,
                0.045145995914936066,
                0.07317356020212173,
                -0.018323153257369995,
                0.11250681430101395,
                0.10128578543663025,
                -0.0012302292743697762,
                -0.035189684480428696,
                0.0158266332000494,
                0.010045190341770649,
                0.037437599152326584,
                -0.021068306639790535,
                -0.051746614277362823,
                -0.01332602184265852,
                -0.018764106556773186,
                0.006575146224349737,
                0.008181439712643623,
                0.03170359507203102,
                0.0015814492944628,
                0.02562888152897358,
                -0.006966689135879278,
                0.009731825441122055,
                0.01950908452272415,
                0.003975034225732088,
                -0.014174330048263073,
                0.004902435466647148,
                -0.03285510465502739,
                0.01337417308241129,
                0.012745446525514126,
                -0.06371986865997314,
                0.038377370685338974,
                -0.03720331937074661,
                -0.026969781145453453,
                -0.031833551824092865,
                0.09588528424501419,
                -0.020718974992632866,
                -0.02588103711605072,
                0.015893053263425827,
                -0.013052851893007755,
                0.005503633059561253,
                0.03731570765376091,
                -0.022380303591489792,
                -0.01159591693431139,
                0.004125398118048906,
                0.00354583328589797,
                -0.005718259606510401,
                -0.057421185076236725,
                0.01937713287770748,
                0.013992185704410076,
                0.013855140656232834,
                -0.047815315425395966,
                0.010580082423985004,
                0.022257044911384583,
                0.001376539352349937,
                0.0323006696999073,
                0.0009741057874634862,
                0.029814938083291054,
                -0.0954209491610527,
                -0.003198113990947604,
                0.018694257363677025,
                0.02505151368677616,
                -0.031078606843948364,
                -0.022330956533551216,
                -0.044879935681819916,
                -0.06103268638253212,
                0.018707912415266037,
                -0.04879003018140793,
                -0.01854749396443367,
                -0.019974570721387863,
                -0.04368632659316063,
                -0.04412080720067024,
                -0.012269391678273678,
                0.0015753243351355195,
                0.0760817900300026,
                0.042515579611063004,
                -0.014600633643567562,
                -0.001033725799061358,
                0.022222094237804413,
                0.0052877445705235004,
                0.02767179347574711,
                0.030070502310991287,
                0.03412728011608124,
                0.01085670106112957,
                -0.0020793781150132418,
                -0.0033147616777569056,
                0.03937484323978424,
                -0.028729449957609177,
                0.015370135195553303,
                0.008372297510504723,
                -0.0061904070898890495,
                -0.008723808452486992,
                -0.023218462243676186,
                -0.013989966362714767,
                -0.0026133887004107237,
                -0.06895604729652405,
                0.029688935726881027,
                0.02302074432373047,
                -0.027619292959570885,
                0.014234605245292187,
                -0.0053914631716907024,
                -0.019115298986434937,
                -0.010313025675714016,
                -0.012154605239629745,
                0.030690738931298256,
                -0.047501854598522186,
                -0.1109091117978096,
                -0.04533495754003525,
                -0.002106251660734415,
                -0.01504832785576582,
                -0.033787213265895844,
                -0.01441090926527977,
                0.019417831674218178,
                0.031895413994789124,
                -0.046698592603206635,
                0.002635420998558402,
                -0.012272744439542294,
                -0.032100699841976166,
                -0.01602928154170513,
                0.08078756928443909,
                0.06788124144077301,
                0.01498616673052311,
                0.01279980968683958,
                0.018155336380004883,
                0.009093619883060455,
                0.004788278602063656,
                -0.04211472347378731,
                -0.03560912609100342,
                -0.01419911254197359,
                -0.01810162141919136,
                -0.00201960327103734,
                7.286947220563889e-05,
                -0.019528403878211975,
                0.00569316279143095,
                0.04243648797273636,
                0.008230841718614101,
                0.037697989493608475,
                -0.060267124325037,
                0.014014221727848053,
                -0.015942338854074478,
                -0.0023858859203755856,
                -0.0015937337884679437,
                -0.013311775401234627,
                0.002408002270385623,
                -0.036609306931495667,
                -0.014869513921439648,
                0.023259561508893967,
                -0.08710002899169922,
                -0.0004891813732683659,
                -0.01268737018108368,
                -0.045693691819906235,
                0.036713726818561554,
                0.02628839761018753,
                0.0822780430316925,
                -0.004895961843430996,
                -0.03988766670227051,
                -0.023293105885386467,
                0.020545821636915207,
                0.041618604212999344,
                0.0020373391453176737,
                -0.02837488427758217,
                -0.08257920295000076,
                -0.030680598691105843,
                0.0047297608107328415,
                -0.056624360382556915,
                -0.009778551757335663,
                -0.019309375435113907,
                -0.022392965853214264,
                0.03653115779161453,
                0.05133119225502014,
                -0.0021342330146580935,
                -0.06897532939910889,
                0.012577350251376629,
                -0.01716560870409012,
                -0.013519173488020897,
                0.045857083052396774,
                0.004150813911110163,
                -0.056390680372714996,
                -0.027722766622900963,
                -0.017907215282320976,
                -0.00744766928255558,
                -0.04359160736203194,
                -0.028320187702775,
                0.022698871791362762,
                -0.000662295613437891,
                0.013818658888339996,
                0.005626684986054897,
                0.09845960885286331,
                -0.01362781785428524,
                0.00865884218364954,
                0.0033754087053239346,
                -0.010786901228129864,
                -0.027662333101034164,
                0.059114668518304825,
                -0.03170938789844513,
                0.007154969964176416,
                0.06532680988311768,
                0.011102265678346157,
                -0.001660783775150776,
                0.003275418421253562,
                0.03939627856016159,
                0.002884997520595789,
                0.03951169177889824,
                0.007214076817035675,
                -0.023505322635173798,
                0.0420827642083168,
                -0.008313621394336224,
                -0.01197957992553711,
                0.0008706209482625127,
                -0.062134578824043274,
                0.0736016258597374,
                0.053813379257917404,
                -0.007606577128171921,
                -0.013720200397074223,
                0.0057876440696418285,
                0.03221794217824936,
                -0.06187339127063751,
                0.019010500982403755,
                0.014383808709681034,
                0.0098754046484828,
                -0.06506708264350891,
                -0.04502470791339874,
                0.011067287996411324,
                -0.01938600465655327,
                -0.017271559685468674,
                0.008248182944953442,
                -0.11616148054599762,
                -0.011625643819570541,
                0.07679104804992676,
                0.07012884318828583,
                0.05071491748094559,
                -0.030643055215477943,
                -0.0026913892943412066,
                0.007156660780310631,
                -0.09409510344266891,
                0.05527767539024353,
                -0.09381905943155289,
                -0.048945002257823944,
                -0.04849795997142792,
                -0.05207265168428421,
                -0.00864411611109972,
                -0.014608141034841537,
                -0.0042046247981488705,
                0.05359965190291405,
                -0.021182648837566376,
                -0.028918655589222908,
                -0.02346121147274971,
                -0.0300745889544487,
                0.007647475693374872,
                0.02611023746430874,
                0.06445557624101639,
                -0.0008543292642571032,
                -0.06135997921228409,
                -0.010189024731516838,
                0.037093423306941986,
                0.0454171746969223,
                -0.011560121551156044,
                -0.007820628583431244,
                -0.0132365757599473,
                0.005850616842508316,
                0.00775617640465498,
                -0.00599911343306303,
                0.025742776691913605,
                0.04706675931811333,
                0.04475801810622215,
                -0.01564866118133068,
                -0.009129943326115608,
                -0.018579212948679924,
                0.01879359409213066,
                0.027281731367111206,
                0.0006467885687015951,
                0.00858777854591608,
                -0.04900344833731651,
                0.019436465576291084,
                0.06048155948519707,
                0.029684584587812424,
                0.020167935639619827,
                -0.010114790871739388,
                -0.007963115349411964,
                -0.014938176609575748,
                0.0088458601385355,
                0.011389716528356075,
                -0.04057413712143898,
                -0.05063376575708389,
                0.019838331267237663,
                -0.03770523890852928,
                -0.02018764242529869,
                -0.04290441796183586,
                -0.02933843433856964,
                0.02639114111661911,
                -0.04868007078766823,
                0.03134559467434883,
                -0.03298319876194,
                0.046501196920871735,
                -0.018441831693053246,
                0.013717365451157093,
                -0.018878279253840446,
                0.03355390578508377,
                0.029887856915593147,
                0.010752595961093903,
                -0.01648637279868126,
                -0.007840988226234913,
                0.026708930730819702,
                -0.04175828769803047,
                0.07771655172109604,
                0.052791766822338104,
                -0.018169330433011055,
                -0.03793123736977577,
                -0.0018092682585120201,
                -0.026245424523949623,
                -0.013033853843808174,
                -0.013275825418531895,
                -0.004009430296719074,
                -0.031713955104351044,
                0.020834192633628845,
                -0.013484400697052479,
                -0.03280377760529518,
                0.009501301683485508,
                0.00896783173084259,
                0.036341965198516846,
                0.017497822642326355,
                -0.04211576655507088,
                -0.008180154487490654,
                0.04043807089328766,
                -6.631870233006596e-33,
                -0.0073956591077148914,
                0.006553960498422384,
                -0.006347751244902611,
                0.0382237583398819,
                -0.00946298148483038,
                0.027825720608234406,
                0.013185114599764347,
                -0.007932212203741074,
                -0.03418552130460739,
                -0.059730567038059235,
                0.003068056423217058,
                0.007736251689493656,
                0.028374051675200462,
                0.02441129833459854,
                0.06689244508743286,
                -0.019490893930196762,
                0.029463963583111763,
                0.02945515140891075,
                -0.05839558318257332,
                -0.047892045229673386,
                -0.03567640855908394,
                0.010618001222610474,
                0.05072716623544693,
                -0.013584035448729992,
                -0.006620130036026239,
                -0.027039900422096252,
                -0.0033025271259248257,
                -0.009186729788780212,
                -0.0030570318922400475,
                0.016900578513741493,
                -0.010109237395226955,
                -0.03987428918480873,
                -0.000876404985319823,
                0.013228065334260464,
                0.00402340991422534,
                0.0036419774405658245,
                -0.09906252473592758,
                -0.024912545457482338,
                -0.0018883777083829045,
                0.01845991052687168,
                0.032323695719242096,
                0.004892663564532995,
                -0.011599602177739143,
                -0.02494840882718563,
                0.0054390328004956245,
                -5.422999947768403e-06,
                0.0006246354896575212,
                -0.025327200070023537,
                -0.015123763121664524,
                -0.03178301081061363,
                0.0283826757222414,
                -0.013442609459161758,
                -0.033275164663791656,
                0.025509783998131752,
                0.06621912121772766,
                0.013758826069533825,
                -0.01095858309417963,
                -0.036221373826265335,
                -0.02018061839044094,
                0.043696966022253036,
                0.07782469689846039,
                0.01101663988083601,
                0.028895940631628036,
                -0.047887884080410004,
                0.04604509472846985,
                0.022726301103830338,
                -0.027460256591439247,
                -0.016016224399209023,
                -0.02442539855837822,
                -0.003813261166214943,
                -0.019352048635482788,
                0.03449348732829094,
                -0.06487325578927994,
                0.03872523084282875,
                0.0094544542953372,
                -0.00971795991063118,
                -0.013053474947810173,
                0.025026623159646988,
                0.030598502606153488,
                0.01360141672194004,
                -0.058898940682411194,
                -0.0016093810554593801,
                -0.04292250797152519,
                -0.003466846887022257,
                0.011369708925485611,
                0.03512384742498398,
                -0.007642294280230999,
                -0.01880631037056446,
                0.0040176743641495705,
                0.018397390842437744,
                -0.0390576608479023,
                0.06739411503076553,
                -0.03562014549970627,
                0.01970573700964451,
                -0.011187715455889702,
                -0.01713971048593521,
                0.08228345960378647,
                -0.027582498267292976,
                0.017635656520724297,
                -0.025901485234498978,
                -0.04618058353662491,
                0.019739551469683647,
                0.011382699944078922,
                0.005359838251024485,
                -0.0002737768809311092,
                0.0151528250426054,
                -0.01364129688590765,
                0.05103893205523491,
                -0.017422620207071304,
                0.00540179293602705,
                0.028511924669146538,
                -0.007159113418310881,
                -0.030699903145432472,
                -0.043738845735788345,
                0.038425665348768234,
                -0.0012892697704955935,
                0.024316752329468727,
                0.06410187482833862,
                -0.01326949242502451,
                -0.02537567727267742,
                0.023682765662670135,
                -0.04360387474298477,
                0.016965217888355255,
                -0.032461244612932205,
                0.017485229298472404,
                -0.028182895854115486,
                -0.037790074944496155,
                -0.056341368705034256,
                0.06589578092098236,
                0.013347635045647621,
                0.0019463858334347606,
                -0.013088682666420937,
                2.441794890728488e-07,
                0.0012328540906310081,
                0.04578674957156181,
                0.04964464157819748,
                0.010761821642518044,
                -0.04635947570204735,
                -0.04004855453968048,
                -0.0180667731910944,
                -0.016253866255283356,
                -0.046774715185165405,
                0.004410566762089729,
                0.07549300789833069,
                -0.042233943939208984,
                0.03280988708138466,
                0.053768496960401535,
                -0.0042297192849218845,
                -0.06602194160223007,
                0.02848297916352749,
                -0.043054480105638504,
                -0.04905575141310692,
                -0.033689942210912704,
                -0.004051785916090012,
                0.04838007688522339,
                0.004489059094339609,
                -0.007198316976428032,
                0.01811027340590954,
                0.009836755692958832,
                -0.011786481365561485,
                -0.09169510751962662,
                0.07433061301708221,
                -0.03700251132249832,
                0.04045681282877922,
                0.028261501342058182,
                0.007992258295416832,
                0.01649155654013157,
                -0.027830149978399277,
                -0.03252209350466728,
                0.03133952245116234,
                0.015060623176395893,
                0.008788005448877811,
                0.05604564771056175,
                -0.042812034487724304,
                -0.03173362463712692,
                -0.026124240830540657,
                -0.04557611420750618,
                0.03897814452648163,
                0.009017753414809704,
                0.02957061305642128,
                0.06649792194366455,
                -0.08382917195558548,
                0.005925838835537434,
                -0.0013650101609528065,
                -0.01003437303006649,
                0.039983585476875305,
                -0.048643745481967926,
                -0.001356983557343483,
                -0.017763158306479454,
                0.008312497287988663,
                0.019476035609841347,
                0.047999076545238495,
                0.06046507507562637,
                0.011323832906782627,
                -0.043877940624952316,
                0.015636183321475983,
                -0.03490805625915527,
                -0.00467786705121398,
                -0.025763748213648796,
                -0.008704203180968761,
                1.7678575226534425e-34,
                -0.025665367022156715,
                -0.01612199656665325,
                0.033454347401857376,
                -0.004957037046551704,
                -0.006567696109414101,
                -0.02281584031879902,
                0.07066948711872101,
                -0.006311086472123861,
                -0.017406310886144638,
                -0.03246770799160004,
                -0.03205052763223648,
                0.021540049463510513,
                -0.0738164409995079,
                -0.019531890749931335,
                0.011340100318193436,
                -0.02708781324326992,
                0.013425063341856003,
                -0.0012166656088083982,
                0.020738257095217705,
                -0.009335419163107872,
                -0.012274748645722866,
                0.06994151324033737,
                -0.06637268513441086,
                0.06964185833930969,
                -0.025204401463270187,
                0.016792353242635727,
                -0.03263390064239502,
                0.011535090394318104,
                -0.009349221363663673,
                -0.08834608644247055,
                -0.010574685409665108,
                -0.012117203325033188,
                0.02863839454948902,
                -0.020668059587478638,
                -0.00021500028378795832,
                0.0011603233870118856,
                -0.05416729301214218,
                0.002438749885186553,
                -0.00822499766945839,
                -0.02491447515785694,
                -0.011978880502283573,
                -0.001113112666644156,
                -0.005242026410996914,
                -0.013578073121607304,
                -0.029472660273313522,
                2.0773975393240107e-06,
                -0.008394600823521614,
                -0.00669049983844161,
                0.00659477012231946,
                -0.006733939051628113,
                0.05035591870546341,
                0.06147739663720131,
                0.019831258803606033,
                0.005810143426060677,
                0.00211484357714653,
                -0.01731753721833229,
                -0.00829082727432251,
                0.05624224990606308,
                -0.06506983190774918,
                0.015098032541573048,
                0.013087674044072628,
                0.00816391035914421,
                -0.03376832231879234,
                -0.017413802444934845,
                -0.038489192724227905,
                0.03353593498468399,
                -0.06501296162605286,
                0.014966747723519802,
                0.00340932235121727,
                0.06623074412345886,
                0.05474363639950752,
                0.004569650162011385,
                -0.04520107060670853,
                -0.01650700345635414,
                -0.02017265371978283,
                0.08993100374937057,
                -0.0030735963955521584,
                0.007520012557506561,
                -0.07234368473291397,
                -0.010615117847919464,
                0.0022388556972146034,
                0.03126046434044838,
                0.038005486130714417,
                0.0026952268090099096,
                0.03883744031190872,
                -0.03856487199664116,
                -0.0029829901177436113,
                -0.019376413896679878,
                0.016479479148983955,
                -0.00442991778254509,
                -0.02113129012286663,
                -0.06799837201833725,
                -0.011625943705439568,
                -0.01842351257801056,
                0.005608085077255964,
                0.03948814049363136,
                0.0011844189139083028,
                0.007217746693640947,
                0.006808574311435223,
                0.03509745001792908,
                -0.037010062485933304,
                -0.0038028848357498646,
                -0.029746970161795616,
                0.02344099059700966,
                0.004433783236891031,
                0.0008171153021976352,
                -0.0349992997944355,
                -0.023817680776119232,
                0.011253926903009415,
                -0.011092477478086948,
                -0.039731286466121674,
                0.06082532927393913,
                0.0032156980596482754,
                0.011130912229418755,
                0.04005562886595726,
                0.005230748560279608,
                0.0038704657927155495,
                0.04382137954235077,
                -0.05259636044502258,
                -0.0390508770942688,
                0.053367000073194504,
                -0.03826585039496422,
                0.012395023368299007,
                -0.0542544424533844,
                0.08625061810016632,
                0.019255176186561584,
                0.010916261002421379,
                -0.06911054998636246,
                0.018802130594849586,
                0.006556985434144735,
                -0.013696723617613316,
                0.11445122212171555,
                0.04754713550209999,
                0.006932467687875032,
                0.01753234677016735,
                -0.021674804389476776,
                0.003850828157737851,
                -0.03398682549595833,
                0.003939488902688026,
                0.03627911955118179,
                -0.03405323252081871,
                -0.016566354781389236,
                -0.006927925162017345,
                -0.01252429187297821,
                -0.06407123059034348,
                0.027169611304998398,
                -0.011655112728476524,
                0.027169451117515564,
                0.01801726222038269,
                -0.09996242821216583,
                -0.013545120134949684,
                0.09089067578315735,
                -0.03489719331264496,
                -0.10138725489377975,
                0.020454375073313713,
                -0.034734297543764114,
                0.0003470677474979311,
                0.02728179283440113,
                0.004092575516551733,
                0.00877035316079855,
                -0.010479973629117012,
                0.0007675353554077446,
                0.024782592430710793,
                0.031588539481163025,
                0.019939225167036057,
                0.07239608466625214,
                0.01716862991452217,
                0.05130092054605484,
                -0.033246029168367386,
                -0.045654673129320145,
                0.05040980502963066,
                -0.01231406070291996,
                -0.053207166492938995,
                0.07397931069135666,
                -0.026409542188048363,
                -0.02631497010588646,
                -0.022193333134055138,
                0.008351483382284641,
                0.0145336352288723,
                -0.06735659390687943,
                -0.013843683525919914,
                -0.08474013954401016,
                -0.007483620196580887,
                -0.01354975439608097,
                0.04340522736310959,
                0.03508450835943222,
                0.04010147601366043,
                0.04308772459626198,
                -0.04744795709848404,
                -0.0143400514498353,
                -0.04144060239195824,
                0.012283113785088062,
                -0.19022338092327118,
                -0.013629816472530365,
                0.062395885586738586,
                -0.014589682221412659,
                0.02540913224220276,
                -0.05119049921631813,
                0.04891252517700195,
                -0.04353697970509529,
                0.016395125538110733,
                -0.008323708549141884,
                0.0036183421034365892,
                -0.04835641384124756,
                0.05234326794743538,
                -0.0018423503497615457,
                -0.01502782478928566,
                0.006326399743556976,
                -0.04397470876574516,
                -0.0183817520737648,
                0.011056567542254925,
                -0.03669612854719162,
                -0.047005824744701385,
                0.0005373957101255655,
                0.0772283524274826,
                -0.039358675479888916,
                0.013005523011088371,
                0.061941079795360565,
                0.013970172964036465,
                -0.04778342321515083,
                -0.023610910400748253,
                0.057470981031656265,
                0.00293564610183239,
                0.03292855620384216,
                0.023545075207948685,
                -0.013893162831664085,
                0.03777718544006348,
                0.004499491769820452,
                0.01327443029731512,
                -0.00583305349573493,
                0.08071336150169373,
                -0.0138684818521142,
                -0.003234516130760312,
                0.009625177830457687,
                -0.002160568255931139,
                0.024934258311986923,
                -0.03741592913866043,
                0.06341356039047241,
                -0.020447274670004845,
                -0.045156970620155334,
                0.03655829653143883,
                -0.005885641556233168,
                0.014399568550288677,
                0.04166685789823532,
                0.031361497938632965,
                0.04854409396648407,
                -0.010559316724538803,
                0.11954779177904129,
                0.04318591207265854,
                0.006778068840503693,
                -0.01128738559782505,
                -0.00885758176445961,
                0.005668853875249624,
                0.010409733280539513,
                0.047117654234170914,
                0.029012246057391167,
                0.013514372520148754,
                0.016606999561190605,
                0.00362500362098217,
                0.012241284362971783,
                -0.006597988307476044,
                -0.01806606724858284,
                0.025773441419005394,
                0.05163263529539108,
                0.02045929618179798,
                0.0355517715215683,
                0.006366964429616928,
                -0.108589768409729,
                -0.02696121856570244,
                -0.04673116281628609,
                -0.01161070168018341,
                -0.016036057844758034,
                -0.049084484577178955,
                0.009200314059853554,
                -0.01604693941771984,
                -0.012495264410972595,
                0.024739407002925873,
                0.04322962462902069,
                -0.056112002581357956,
                0.028987707570195198,
                0.008123225532472134,
                -0.0265410915017128,
                0.015466399490833282,
                0.04641847684979439,
                -0.03431949019432068,
                -0.020363699644804,
                0.00463517801836133,
                -0.030122842639684677,
                -0.05627982318401337,
                -0.06163215637207031,
                0.01162736862897873,
                0.010122315026819706,
                0.035337723791599274,
                -0.04857231676578522,
                -0.010891424492001534,
                0.008639918640255928,
                -0.026801697909832,
                -0.051616981625556946,
                -0.08404062688350677,
                -0.01867935247719288,
                0.006246922537684441,
                0.021449614316225052,
                0.010561326518654823,
                0.04543663188815117,
                -0.005591711029410362,
                -0.012488586828112602,
                -0.007697264663875103,
                -0.04024557024240494,
                0.05518409609794617,
                -0.02391194924712181,
                0.09801463782787323,
                -0.03985109552741051,
                0.0411432683467865,
                0.02829200215637684,
                -0.002745772013440728,
                -0.035517044365406036,
                0.05498187988996506,
                0.06559057533740997,
                -0.03642024099826813,
                0.016271736472845078,
                -0.005516873672604561,
                0.05013860762119293,
                -0.0010740924626588821,
                0.04594358429312706,
                0.016548458486795425,
                0.004546764772385359,
                -0.009001550264656544,
                -0.04014263674616814,
                0.02009260468184948,
                0.03530611842870712,
                0.035908184945583344,
                -0.004905009642243385,
                0.0028680001851171255,
                0.02168538048863411,
                -0.026090431958436966,
                0.006197373848408461,
                -0.008803436532616615,
                -0.07122069597244263,
                0.035514168441295624,
                0.04629116877913475,
                -0.04010067507624626,
                0.013248490169644356,
                -0.004171738866716623,
                0.00016579254588577896,
                0.07112683355808258,
                -0.06222495809197426,
                0.04480419680476189,
                -0.019033802673220634,
                -0.03832468017935753,
                0.0007134219631552696,
                -0.012634209357202053,
                -0.016190866008400917,
                0.022226285189390182,
                -0.003890605177730322,
                -0.11720176041126251,
                0.06592554599046707,
                -0.06313874572515488,
                -0.01005367748439312,
                0.01735851727426052,
                -0.06437235325574875,
                0.03696087747812271,
                0.02777235582470894,
                0.06592569500207901,
                -0.03327883407473564,
                -0.00493931258097291,
                0.012302436865866184,
                -0.040272727608680725,
                0.02928350307047367,
                -0.012767227366566658,
                -0.05891290307044983,
                -0.020361728966236115,
                -0.0023707482032477856,
                -0.020037110894918442,
                -0.002991844667121768,
                0.012999095022678375,
                -0.016033340245485306,
                0.033622778952121735,
                0.05364822596311569,
                -0.07486597448587418,
                -0.051269177347421646,
                0.03631220757961273,
                0.0055804005824029446,
                0.03487151488661766,
                0.03870638832449913,
                -0.0031080180779099464,
                -0.0047646150924265385,
                -0.025055401027202606,
                -0.01713535562157631,
                0.013207022100687027,
                -0.08534932881593704,
                0.027918552979826927,
                0.036012113094329834,
                -0.06268631666898727,
                0.020335927605628967,
                -0.027723785489797592,
                0.01994805596768856,
                0.000432050961535424,
                0.003321960801258683,
                -0.005977672059088945,
                -0.008277659304440022,
                0.028850531205534935,
                0.024075519293546677,
                -0.013911563903093338,
                0.0007835472351871431,
                0.025364559143781662,
                0.018216950818896294,
                0.008767752908170223,
                -0.056800346821546555,
                -0.014391585253179073,
                -0.009208823554217815,
                0.04522315785288811,
                0.019633527845144272,
                0.023087162524461746,
                -0.030490992590785027,
                0.01081764604896307,
                -0.022591598331928253,
                -0.043749552220106125,
                0.017550699412822723,
                -0.0026773354038596153,
                -0.007373508531600237,
                -0.04455334320664406,
                0.01579335704445839,
                -0.008914594538509846,
                0.02766958810389042,
                -0.007417287211865187,
                -0.08834082633256912,
                -0.016649730503559113,
                -0.017823783680796623,
                0.02710503526031971,
                0.02274574153125286,
                -0.03433443233370781,
                0.005444555543363094,
                0.04325500875711441,
                0.004695245064795017,
                -0.01816466636955738,
                -0.0012563286582008004,
                -0.05507832393050194,
                0.014881483279168606,
                0.04404882341623306,
                0.014984729699790478,
                -0.004210314713418484,
                -0.029528163373470306,
                0.017979837954044342,
                -0.010447467677295208,
                0.01920553669333458,
                -0.032540030777454376,
                -0.061162032186985016,
                -0.019990798085927963,
                0.022531991824507713,
                -0.0029546967707574368,
                0.0031987004913389683,
                -0.013661901466548443,
                0.0436212420463562,
                0.043068140745162964,
                0.005716444458812475,
                0.041657015681266785,
                0.04750876873731613,
                0.0076008569449186325,
                0.003305129474028945,
                -0.021205106750130653,
                0.03390331193804741,
                0.04435884952545166,
                0.033035654574632645,
                0.016603359952569008,
                -0.055228061974048615,
                0.014343183487653732,
                -0.04134324938058853,
                -0.028236668556928635,
                -0.05465918406844139,
                0.022001871839165688,
                -0.007963662967085838,
                0.04772457852959633,
                0.04025442153215408,
                -0.037950098514556885,
                -0.015735222026705742,
                0.028477732092142105,
                -0.09974439442157745,
                0.00665108859539032,
                -0.009591914713382721,
                0.015598871745169163,
                -0.0939185619354248,
                -0.09887661039829254,
                0.009438172914087772,
                -0.047018036246299744,
                0.018704572692513466,
                0.02820361591875553,
                -0.005136535968631506,
                -0.02137674391269684,
                0.03386256843805313,
                -0.01922367513179779,
                0.015764081850647926,
                -0.04641116410493851,
                0.02597840130329132,
                0.008399526588618755,
                -0.028311356902122498,
                0.03762461990118027,
                0.058333493769168854,
                0.04346797242760658,
                -0.01744990237057209,
                0.026854971423745155,
                -0.019341232255101204,
                0.025986183434724808,
                -0.040498655289411545,
                0.0023755694273859262,
                -0.08403153717517853,
                0.02360869199037552,
                0.007135413121432066,
                0.03413178399205208,
                0.007432842161506414,
                0.02995728701353073,
                0.02092668041586876,
                -0.01951647363603115,
                0.0433923713862896,
                -0.0137149877846241,
                -0.001998357940465212,
                -0.006633058190345764,
                0.01922999694943428,
                -0.02358364686369896,
                0.03801855444908142,
                -0.03420061245560646,
                0.027561187744140625,
                0.04174232855439186,
                0.042443517595529556,
                -0.032587047666311264,
                0.01816515624523163,
                0.052611321210861206,
                0.05268020182847977,
                0.04345234856009483,
                -0.06602604687213898,
                -0.07966875284910202,
                0.016051283106207848,
                0.04511123150587082,
                -0.027879886329174042,
                0.02398866042494774,
                0.0328829288482666,
                0.0061599514447152615,
                -0.03747520223259926,
                0.0236689280718565,
                -0.010252565145492554,
                -0.010214150883257389,
                0.020035607740283012,
                -0.10900604724884033,
                -0.024836666882038116,
                0.02368299476802349,
                0.009502445347607136,
                -0.03868493065237999,
                0.06662874668836594,
                -0.021383782848715782,
                0.05538313463330269,
                0.014978117309510708,
                0.015091227367520332,
                0.003992828074842691,
                -0.018134234473109245,
                -0.03472715988755226,
                -0.006450704298913479,
                0.037750210613012314,
                -0.00996679812669754,
                0.01592991314828396,
                0.0389699749648571,
                -0.004518358968198299,
                0.0077428752556443214,
                -0.03429112583398819,
                0.009763221256434917,
                -0.11443127691745758,
                0.024657201021909714,
                -0.02927805297076702,
                -8.155771558521009e-33,
                -0.0050175990909338,
                0.0067764995619654655,
                -0.0045744129456579685,
                0.02387143112719059,
                -0.019215049222111702,
                -0.03317660838365555,
                0.04680117964744568,
                0.005599361844360828,
                -0.04666397348046303,
                -0.02096513845026493,
                -0.04649825766682625,
                0.02219207026064396,
                0.04013480246067047,
                -0.004582778085023165,
                -0.010257194750010967,
                0.03934456408023834,
                -0.018648134544491768,
                0.021825294941663742,
                -0.018626898527145386,
                -0.04800053685903549,
                -0.11047723889350891,
                0.004206846933811903,
                0.01381823793053627,
                0.07671064138412476,
                0.010691811330616474,
                -0.028466375544667244,
                0.0015834829537197948,
                -0.0045346785336732864,
                -0.010503111407160759,
                0.01875072903931141,
                0.009197372011840343,
                -0.00509283971041441,
                -0.015445764176547527,
                -0.002329757437109947,
                0.004287337884306908,
                0.013599127531051636,
                -0.041155099868774414,
                -0.007623229641467333,
                0.01924588344991207,
                0.007720523979514837,
                -0.007600537966936827,
                -0.02368609979748726,
                0.005220893770456314,
                -0.04626724496483803,
                -0.0132217388600111,
                -0.02648412436246872,
                -0.007773125544190407,
                -0.011454446241259575,
                0.033903177827596664,
                0.07172317057847977,
                0.01203258614987135,
                -0.010895716957747936,
                -0.018559815362095833,
                0.06353609263896942,
                -0.013174736872315407,
                -0.030624618753790855,
                -0.0012296960921958089,
                0.00887067150324583,
                -0.016557816416025162,
                -0.03787277638912201,
                -0.05608656629920006,
                -0.0006778030656278133,
                0.010923011228442192,
                -0.03645545244216919,
                -0.010986658744513988,
                -0.03631093353033066,
                -0.10484111309051514,
                -0.011963351629674435,
                0.011044170707464218,
                -0.034290507435798645,
                -0.024347402155399323,
                0.06388296186923981,
                -0.015104304999113083,
                -0.027639193460345268,
                0.03685802221298218,
                -0.06718547642230988,
                0.012503953650593758,
                -0.0048866369761526585,
                0.08159846812486649,
                -0.03912803530693054,
                -0.032445117831230164,
                -0.0431617796421051,
                0.036538880318403244,
                0.004016932565718889,
                -0.0024254850577563047,
                0.035321202129125595,
                -0.042660996317863464,
                -0.02423747070133686,
                0.022638725116848946,
                -0.005500748287886381,
                0.02517162822186947,
                0.03334131836891174,
                -0.06051614135503769,
                -0.0031209581065922976,
                -0.019446106627583504,
                -0.015557907521724701,
                0.019272441044449806,
                0.013412827625870705,
                0.007595183793455362,
                0.020029958337545395,
                0.018033573403954506,
                0.026649050414562225,
                -0.05909271910786629,
                0.06005539000034332,
                0.017137715592980385,
                0.01833338476717472,
                -0.04895567148923874,
                0.02359251119196415,
                -0.03246500715613365,
                0.0016243845457211137,
                0.015228044241666794,
                0.034064698964357376,
                0.00011407622514525428,
                0.05912342295050621,
                0.004548101220279932,
                0.04984862357378006,
                9.969035454560071e-05,
                0.039063483476638794,
                -0.022712495177984238,
                -0.019265856593847275,
                0.013146957382559776,
                -0.0456341877579689,
                -0.026679852977395058,
                0.021998688578605652,
                -0.04110211133956909,
                -0.0019200315000489354,
                0.013788674958050251,
                0.027514250949025154,
                0.02358000911772251,
                -0.027127722278237343,
                -0.021679174154996872,
                -0.0031264140270650387,
                2.6906926109404594e-07,
                -0.006133880000561476,
                0.0030832933261990547,
                -0.013055139221251011,
                0.07324725389480591,
                0.0001745547924656421,
                -0.04570361226797104,
                -0.020476294681429863,
                0.059337109327316284,
                -0.0017887242138385773,
                0.05126379430294037,
                -0.006064011715352535,
                -0.017698416486382484,
                0.016833532601594925,
                -0.0368780791759491,
                -0.00879279337823391,
                0.009292325004935265,
                -0.03972683474421501,
                0.022936595603823662,
                -0.02473914436995983,
                0.04926668852567673,
                0.048256225883960724,
                0.06852150708436966,
                0.06378620117902756,
                0.005640667397528887,
                -0.033232010900974274,
                -0.026442991569638252,
                0.0027988310903310776,
                0.011360607109963894,
                0.08640674501657486,
                0.003151403507217765,
                -0.031182769685983658,
                0.06495913863182068,
                0.0011525641893967986,
                0.03186286240816116,
                -0.015553073026239872,
                -0.028442971408367157,
                0.04996452108025551,
                0.02930397540330887,
                -0.015511988662183285,
                0.039415065199136734,
                -0.006682011764496565,
                0.0049454402178525925,
                -0.008788595907390118,
                0.0073233614675700665,
                0.058665383607149124,
                0.02069060318171978,
                0.007819194346666336,
                -0.011770360171794891,
                -0.01384198758751154,
                -0.012768534943461418,
                -0.020627425983548164,
                0.007966031320393085,
                0.03935714438557625,
                0.012490776367485523,
                0.036240458488464355,
                -0.01455759909003973,
                -0.006377777084708214,
                0.04544590786099434,
                0.005860520992428064,
                0.0034212195314466953,
                0.017347553744912148,
                -0.01574545167386532,
                0.008785130456089973,
                -0.007610142696648836,
                0.04962240159511566,
                0.047420427203178406,
                -0.0649765357375145,
                1.6251080830423504e-34,
                -0.0007369937957264483,
                -0.025513365864753723,
                0.006315845996141434,
                0.016569342464208603,
                -0.018025226891040802,
                -0.0346931628882885,
                -0.021453581750392914,
                -0.013262146152555943,
                -0.023883435875177383,
                -0.01823134906589985,
                -0.015166090801358223,
                -0.01774263009428978,
                0.06535341590642929,
                -0.017353251576423645,
                0.02166619896888733,
                -0.02071107178926468,
                -0.030504364520311356,
                0.0829583927989006,
                -0.0005869284505024552,
                -0.05039370432496071,
                0.019026724621653557,
                -0.0038944606203585863,
                -0.007485167123377323,
                -0.008454891853034496,
                0.06325314939022064,
                -0.005497652105987072,
                -0.008169167675077915,
                0.024797337129712105,
                -0.01189744658768177,
                -0.04222794622182846,
                -0.019719235599040985,
                0.011628303676843643,
                -0.01610058918595314,
                0.0472734235227108,
                -0.03822796046733856,
                -0.011786632239818573,
                -0.0072105322033166885,
                -0.012253479100763798,
                -0.012717094272375107,
                -0.025511253625154495,
                0.003980766981840134,
                0.0018690187716856599,
                0.03748277202248573,
                0.005036785267293453,
                0.016178349032998085,
                1.5016473753348691e-06,
                0.02979539893567562,
                -0.03274374082684517,
                0.04929007217288017,
                -0.08581219613552094,
                -0.01241882611066103,
                0.007792567368596792,
                0.014005636796355247,
                0.057069990783929825,
                0.010795198380947113,
                -0.0266434196382761,
                -0.015641121193766594,
                0.030400576069951057,
                -0.051261063665151596,
                0.048058029264211655,
                -0.041385527700185776,
                0.0038421719800680876,
                -0.001868463703431189,
                -0.03479798510670662,
                -0.010957249440252781,
                0.02614516206085682,
                -0.01008267980068922,
                0.03494122624397278,
                0.05810375139117241,
                0.05318019166588783,
                0.0535728745162487,
                0.02510819025337696,
                0.008218417875468731,
                -0.014809933491051197,
                0.053258925676345825,
                0.0391271710395813,
                0.0022388475481420755,
                0.013091008178889751,
                -0.013391925022006035,
                -0.009102907963097095,
                0.0066724056378006935,
                0.10275658965110779,
                -0.009140824899077415,
                0.00778121966868639,
                0.03986544534564018,
                -0.013136357069015503,
                0.004069708287715912,
                -0.02021896280348301,
                0.01671685464680195,
                -0.011306597851216793,
                0.02520829252898693,
                -0.03923022747039795,
                -0.0006263302639126778,
                -0.008452173322439194,
                0.01573818176984787,
                0.011299604550004005,
                0.014202590100467205,
                -0.004031809978187084,
                -0.034627556800842285,
                -0.08158107101917267,
                -0.008113943971693516,
                0.036861319094896317,
                -0.04631856083869934,
                0.023822711780667305,
                0.00406985729932785,
                0.029731038957834244,
                -0.0055515356361866,
                -0.07749465107917786,
                -0.02167377434670925,
                -0.03414483740925789,
                0.03605503588914871,
                0.021473253145813942,
                -0.05724198371171951,
                0.00954761728644371,
                0.04129326716065407,
                0.10078314691781998,
                -0.06478458642959595,
                -0.02806526981294155,
                0.029999373480677605,
                0.027979765087366104,
                -0.047691721469163895,
                -0.020126132294535637,
                0.035891093313694,
                0.026879791170358658,
                0.10147725045681,
                0.00029903886024840176,
                0.026761826127767563,
                0.0023057234939187765,
                0.021341687068343163,
                -0.020673872902989388,
                0.04307694360613823,
                0.03646352142095566,
                -0.0053200083784759045,
                0.028595462441444397,
                -0.0030007250607013702,
                -0.019648239016532898,
                -0.08614608645439148,
                -0.016584817320108414,
                0.006431780755519867,
                0.032309841364622116,
                -0.0486530065536499,
                0.0026090708561241627,
                -0.06906770914793015,
                0.03849031403660774,
                -0.001109530683606863,
                0.01588449627161026,
                0.02458409033715725,
                0.005895119160413742,
                0.046177685260772705,
                -0.049103979021310806,
                -0.06276056170463562,
                0.09036191552877426,
                -0.013692300766706467,
                -0.07012709975242615,
                -0.00785271916538477,
                0.04404481500387192,
                0.01294128131121397,
                -0.03725382685661316,
                0.011388206854462624,
                -0.025928156450390816,
                0.013051922433078289,
                -0.013128604739904404,
                0.07675053179264069,
                -0.0345093309879303,
                0.015948539599776268,
                0.03218730911612511,
                0.055024631321430206,
                0.021980086341500282,
                -0.04429541155695915,
                -0.015636593103408813,
                -0.04286719113588333,
                -0.0033757351338863373,
                0.03979972004890442,
                0.024033870548009872,
                -0.03638308867812157,
                0.048078663647174835,
                0.02124473825097084,
                0.03305428475141525,
                0.06597499549388885,
                0.005593891721218824,
                0.006572520826011896,
                -0.08323248475790024,
                0.0709499940276146,
                -0.028233464807271957,
                -0.017983410507440567,
                0.05624557286500931,
                0.043485794216394424,
                0.06193853169679642,
                0.004953667987138033,
                -0.09132770448923111,
                0.059378042817115784,
                -0.048666056245565414,
                -0.09638240933418274,
                0.0382252112030983,
                0.05294713377952576,
                0.03867708891630173,
                -0.0580405592918396,
                0.02238290011882782,
                0.002643218031153083,
                -0.003102367976680398,
                0.04160378873348236,
                -0.00929280649870634,
                -0.0688711628317833,
                -0.008783351629972458,
                0.050582755357027054,
                -0.037455711513757706,
                0.01770767755806446,
                0.053815122693777084,
                -0.008481556549668312,
                -0.02075391635298729,
                -0.03328844532370567,
                -0.011818477883934975,
                -0.030726775527000427,
                0.02097921073436737,
                0.060415588319301605,
                -0.02516469731926918,
                0.020363902673125267,
                0.053589411079883575,
                -0.02451821230351925,
                0.04117715358734131,
                -0.067542664706707,
                0.03609713166952133,
                -0.009643150493502617,
                0.021943876519799232,
                -0.015940222889184952,
                0.004062901251018047,
                0.03893697261810303,
                0.02211339771747589,
                -0.0068393307738006115,
                -0.05337243527173996,
                0.05046983063220978,
                -0.02014273591339588,
                0.05965922400355339,
                0.012424007058143616,
                -0.0009689187281765044,
                0.034222643822431564,
                0.02511974796652794,
                0.01165126170963049,
                0.038649242371320724,
                0.018680566921830177,
                0.012576781213283539,
                0.008275531232357025,
                0.00995931401848793,
                -0.01417516265064478,
                0.02149428427219391,
                0.0022829261142760515,
                0.028860513120889664,
                0.03932056203484535,
                0.03960943967103958,
                -0.061460334807634354,
                -0.0737786814570427,
                0.06197879835963249,
                -0.0006765110301785171,
                -0.013223611749708652,
                -0.011092079803347588,
                -0.024191243574023247,
                0.027615170925855637,
                -0.0016800765879452229,
                0.04723550006747246,
                0.018742498010396957,
                0.011426785960793495,
                0.004840540699660778,
                -0.013703024946153164,
                -0.02683354914188385,
                0.0011170919751748443,
                0.04774956405162811,
                -0.03730328753590584,
                0.006566546857357025,
                0.059655819088220596,
                -0.01339635532349348,
                0.028638117015361786,
                0.01738882064819336,
                0.019187185913324356,
                0.03175702691078186,
                -0.01975702866911888,
                -0.018841540440917015,
                0.02292068675160408,
                0.038866497576236725,
                0.013170990161597729,
                0.017801443114876747,
                0.007827941328287125,
                0.0020495012868195772,
                0.033744532614946365,
                0.017548078671097755,
                -0.01933867298066616,
                -0.02535433880984783,
                -0.06875385344028473,
                0.008545594289898872,
                -0.010045384056866169,
                0.013031556271016598,
                0.029113300144672394,
                0.059560805559158325,
                0.01709115505218506,
                -0.00031649411539547145,
                -0.04835693538188934,
                0.055072490125894547,
                -0.0032877433113753796,
                -0.005655486602336168,
                -0.0023447636049240828,
                -0.039734989404678345,
                -0.07121232897043228,
                -0.035264842212200165,
                0.004715860355645418,
                0.03693271428346634,
                0.0026554118376225233,
                -0.004380192141979933,
                0.003266241867095232,
                -0.04286151006817818,
                -0.00975830014795065,
                -0.047734297811985016,
                -0.036832295358181,
                -0.03971599042415619,
                -0.043581463396549225,
                0.023963266983628273,
                -0.04022899642586708,
                0.006107474211603403,
                0.011195385828614235,
                0.025101877748966217,
                -0.02049775794148445,
                0.05651990696787834,
                -0.00835762545466423,
                0.01885232701897621,
                -0.037405502051115036,
                0.043929122388362885,
                0.027287831529974937,
                0.007555393036454916,
                0.009079343639314175,
                -0.05550411343574524,
                -0.06835741549730301,
                0.006469303742051125,
                0.029965024441480637,
                0.014382399618625641,
                0.004415987990796566,
                0.018730761483311653,
                -0.0212521031498909,
                0.0247805118560791,
                0.04506823420524597,
                -0.09482105821371078,
                -0.023626424372196198,
                0.051004599779844284,
                -0.02251984179019928,
                0.0034459582529962063,
                -0.032304324209690094,
                -0.019370481371879578,
                0.013928879983723164,
                -0.03393350541591644,
                0.06139427050948143,
                -0.0395706482231617,
                -0.052442166954278946,
                -0.030846944078803062,
                -0.04975559189915657,
                0.01635919138789177,
                -0.051752641797065735,
                0.0021943727042526007,
                0.017568156123161316,
                -0.003999416250735521,
                -0.016882430762052536,
                -0.0009936441201716661,
                -0.02281215786933899,
                0.07443246245384216,
                -0.009050071239471436,
                0.05511079728603363,
                0.009308253414928913,
                0.026010900735855103,
                0.014509202912449837,
                0.037299834191799164,
                -0.0816645398736,
                -0.06554067134857178,
                -0.016247635707259178,
                -0.08117641508579254,
                0.007317705545574427,
                -0.06231261417269707,
                0.007720734924077988,
                0.038829222321510315,
                0.0231646541506052,
                0.04367442429065704,
                0.010391050018370152,
                0.03357434272766113,
                0.03065096214413643,
                -0.025709277018904686,
                -0.011917494237422943,
                0.023291965946555138,
                -0.012357956729829311,
                0.019453147426247597,
                -0.038333918899297714,
                0.005090103019028902,
                -0.0476280152797699,
                -0.03014112263917923,
                -0.0016118393978103995,
                -0.0690511167049408,
                -0.005187655333429575,
                -0.004872030112892389,
                -0.05882193148136139,
                0.0021190086845308542,
                0.010171230882406235,
                0.003386624390259385,
                -0.052748579531908035,
                -0.06981821358203888,
                -0.04514080286026001,
                0.024222109466791153,
                -0.03493322804570198,
                -0.005880868062376976,
                0.03804556280374527,
                -0.052409909665584564,
                -0.009220552630722523,
                0.03799603134393692,
                -0.02965843491256237,
                -0.04953337460756302,
                -0.044810280203819275,
                -0.010323039256036282,
                0.018290333449840546,
                0.023991333320736885,
                0.026476556435227394,
                0.0023722804617136717,
                -0.015408226288855076,
                -0.09101886302232742,
                -0.000643272593151778,
                0.016182227060198784,
                0.019320594146847725,
                0.023148860782384872,
                -0.04001297801733017,
                -0.051912572234869,
                0.0031467934604734182,
                -0.023415200412273407,
                -0.02293185144662857,
                0.016951464116573334,
                -0.01632060669362545,
                -0.02126762829720974,
                -0.006203196942806244,
                0.042982541024684906,
                0.07019607722759247,
                0.04319596663117409,
                -0.0474514365196228,
                0.0003900883602909744,
                -0.02090275287628174,
                0.021366210654377937,
                0.02972467429935932,
                0.008187542669475079,
                0.06161557137966156,
                0.06441705673933029,
                -0.0012583554489538074,
                0.006397359073162079,
                0.01959078572690487,
                0.02237614057958126,
                0.03465349227190018,
                -0.0010912192519754171,
                0.010779369622468948,
                0.02428285963833332,
                0.0068898587487638,
                0.0007760761654935777,
                -0.03345300629734993,
                0.017434317618608475,
                0.010732671245932579,
                -0.013775929808616638,
                0.020576627925038338,
                -0.006849769502878189,
                0.011336282826960087,
                -0.04084880277514458,
                -0.014321844093501568,
                -0.0955173596739769,
                -0.02542109414935112,
                -0.007072833366692066,
                -0.012739893980324268,
                -0.017163917422294617,
                -0.027654018253087997,
                0.021835651248693466,
                -0.010917709209024906,
                -0.023585114628076553,
                -0.044023070484399796,
                -0.011799963191151619,
                0.03964072838425636,
                0.003252596827223897,
                0.028358343988656998,
                -0.010499434545636177,
                0.03861531987786293,
                -0.021651126444339752,
                0.023798923939466476,
                0.052639443427324295,
                -0.04090218245983124,
                -0.021545030176639557,
                -0.018243322148919106,
                -0.01738334260880947,
                0.022796865552663803,
                0.0016844307538121939,
                -0.015276369638741016,
                -0.03168410807847977,
                0.028744708746671677,
                -0.11450926214456558,
                -0.028770115226507187,
                0.03293361887335777,
                -0.017202425748109818,
                0.026167752221226692,
                -0.03220822289586067,
                0.06916085630655289,
                -0.061013199388980865,
                -0.03171149641275406,
                0.06029867008328438,
                0.010474449023604393,
                0.028534775599837303,
                -0.035800203680992126,
                -0.005042861215770245,
                0.03307240456342697,
                -0.016069523990154266,
                0.04083842784166336,
                -0.017881125211715698,
                0.08070437610149384,
                -0.039727017283439636,
                -0.04564239829778671,
                0.018408581614494324,
                0.02425403520464897,
                0.0036404116544872522,
                -0.03187033534049988,
                0.022178826853632927,
                -0.03350372985005379,
                -0.027111032977700233,
                0.021406549960374832,
                0.02766716666519642,
                -0.021231189370155334,
                0.009817995131015778,
                0.0038160220719873905,
                -0.01762493886053562,
                0.07623010873794556,
                0.03096378594636917,
                -0.010682864114642143,
                -0.07284107804298401,
                0.046017322689294815,
                0.02144140936434269,
                -0.07639637589454651,
                0.08134014159440994,
                -0.07846834510564804,
                -0.08199482411146164,
                0.01579645648598671,
                -0.022562777623534203,
                0.030360830947756767,
                -0.04730403423309326,
                -0.0008649157243780792,
                0.011457534506917,
                -0.008216933347284794,
                -0.02415953017771244,
                0.0031402630265802145,
                0.007827728055417538,
                -0.0013167258584871888,
                -0.03628413379192352,
                -0.09224312752485275,
                -0.044323064386844635,
                -0.027274727821350098,
                0.06254810839891434,
                0.04945231229066849,
                0.006200764328241348,
                -0.026299219578504562,
                -0.012041715905070305,
                0.0225369855761528,
                0.031336020678281784,
                -0.03238559886813164,
                0.011071417480707169,
                -0.003656231565400958,
                0.011215634644031525,
                0.04587830603122711,
                -0.007294298615306616,
                -0.005464326124638319,
                -0.001183502608910203,
                -0.016780411824584007,
                -0.013076313771307468,
                0.012781059369444847,
                -0.009669340215623379,
                0.014378031715750694,
                -5.876075854569836e-33,
                -0.003600932192057371,
                0.01175067387521267,
                -0.0022382372990250587,
                0.024665970355272293,
                0.011783920228481293,
                0.015842536464333534,
                0.012019150890409946,
                -0.024168990552425385,
                -0.0628623515367508,
                -0.024253087118268013,
                -0.020739829167723656,
                0.05355406180024147,
                0.012209744192659855,
                0.02517986297607422,
                -0.008430508896708488,
                -0.04667681083083153,
                0.02285776659846306,
                0.01302299927920103,
                -0.007831552997231483,
                -0.03964672237634659,
                0.017381122335791588,
                0.04845418408513069,
                0.01384189072996378,
                0.005350587423890829,
                0.02646082267165184,
                -0.047933127731084824,
                -0.04129692167043686,
                0.016360364854335785,
                -0.024244695901870728,
                -0.02829527109861374,
                0.01713477075099945,
                -0.04106200486421585,
                -0.0034455934073776007,
                -0.019000625237822533,
                -0.026580065488815308,
                0.05621824413537979,
                -0.035234760493040085,
                -0.03314218670129776,
                0.0023179412819445133,
                -0.02940785139799118,
                -0.013012166135013103,
                0.03491251915693283,
                0.03425626456737518,
                -0.06680618226528168,
                0.037602122873067856,
                0.009638172574341297,
                -0.03169570118188858,
                0.030414504930377007,
                -0.013636982068419456,
                0.06642885506153107,
                0.04237735643982887,
                -0.023468632251024246,
                0.008355014026165009,
                0.060855116695165634,
                0.08263309299945831,
                -0.019746458157896996,
                0.00041613541543483734,
                -0.04352400451898575,
                -0.03815996274352074,
                0.03191419318318367,
                0.010828355327248573,
                0.0029482280369848013,
                -0.018886178731918335,
                0.006032082252204418,
                0.05199991166591644,
                0.029334930703043938,
                0.0039527276530861855,
                -0.03551262989640236,
                -0.015270784497261047,
                0.02643350511789322,
                -0.058352768421173096,
                0.05819287151098251,
                0.0013721216237172484,
                0.030932273715734482,
                -0.049246422946453094,
                -0.04260946437716484,
                -0.011191802099347115,
                -0.011855341494083405,
                -0.025865845382213593,
                -0.07329730689525604,
                0.013936241157352924,
                0.010029179975390434,
                -0.048260025680065155,
                -0.018831361085176468,
                0.0013617515796795487,
                0.02600657381117344,
                -0.01707644574344158,
                0.006308247335255146,
                -0.03210078924894333,
                0.015926901251077652,
                -0.024921946227550507,
                0.10415536165237427,
                0.01970304735004902,
                -0.027250537648797035,
                -0.04193953052163124,
                -0.009605476632714272,
                0.08670759201049805,
                -0.06483379006385803,
                0.01223829947412014,
                -0.060444679111242294,
                -0.05673246830701828,
                0.05037723109126091,
                0.018595628440380096,
                0.057905398309230804,
                -0.015572273172438145,
                0.028063248842954636,
                0.07046609371900558,
                -0.01775117963552475,
                -0.03739851713180542,
                0.012816651724278927,
                -0.025257159024477005,
                0.032072119414806366,
                -0.008365849032998085,
                -0.06507598608732224,
                0.03010374866425991,
                0.01428541261702776,
                0.008874166756868362,
                0.01177101768553257,
                0.024844789877533913,
                0.005656722467392683,
                -0.0396156907081604,
                -0.04034121334552765,
                -0.03936037793755531,
                -0.012381373904645443,
                0.023841429501771927,
                0.008499658666551113,
                -0.023880405351519585,
                0.004216563422232866,
                0.0625983327627182,
                0.00018311278836335987,
                0.008314168080687523,
                -0.02919706515967846,
                2.0594652028194105e-07,
                0.01781133934855461,
                -0.0217873714864254,
                -0.021897200495004654,
                0.027127357199788094,
                0.006661855615675449,
                -0.010007645934820175,
                0.035000029951334,
                0.07150272279977798,
                -0.022414477542042732,
                0.01831299252808094,
                -9.116503497352824e-05,
                -0.022139932960271835,
                0.017410844564437866,
                0.029894590377807617,
                -0.02459886111319065,
                -0.06328524649143219,
                -0.037940867245197296,
                -0.026363419368863106,
                -0.020486030727624893,
                -0.06448754668235779,
                0.021777058020234108,
                -0.0032059133518487215,
                0.060997433960437775,
                0.00864538736641407,
                0.032587818801403046,
                0.014178654178977013,
                0.0012952678371220827,
                -0.05428560450673103,
                0.02711225301027298,
                -0.0215420164167881,
                0.03869355469942093,
                0.028895098716020584,
                -0.0038469042629003525,
                0.07453776895999908,
                -0.04130792245268822,
                -0.07888934761285782,
                -0.04009231552481651,
                0.002614137949422002,
                -0.004902173299342394,
                0.024615701287984848,
                0.016475021839141846,
                -0.09649845957756042,
                -0.07721356302499771,
                0.03161418437957764,
                0.010917095467448235,
                -0.0008879723609425128,
                -0.024192294105887413,
                0.08693581819534302,
                0.0713358074426651,
                -0.07133440673351288,
                0.04232701659202576,
                0.032140642404556274,
                0.022236686199903488,
                -0.009334275498986244,
                -0.015399303287267685,
                0.03629646077752113,
                0.002991654444485903,
                -0.001210851245559752,
                0.03343676030635834,
                -0.03999779745936394,
                0.015557503327727318,
                -0.03546065837144852,
                -0.01869109645485878,
                -0.036894477903842926,
                -0.011447147466242313,
                0.017091060057282448,
                0.02553548291325569,
                5.540604804907651e-35,
                -0.04706225171685219,
                0.013340767472982407,
                0.004191946238279343,
                -0.0069832936860620975,
                -0.01365385390818119,
                0.0008124251035042107,
                0.0754886269569397,
                -0.023742010816931725,
                0.009360456839203835,
                0.015362195670604706,
                -0.026355857029557228
            ]
        },
        "Education": {
            "name": "MSc/Entrepreneurship/Entrepreneurial Studies",
            "rep": [
                -0.017337771132588387,
                -0.05913116782903671,
                -0.027779972180724144,
                -0.01711931638419628,
                0.049953341484069824,
                0.028528280556201935,
                -0.001671061385422945,
                0.02718712016940117,
                0.04475697502493858,
                -0.008925561793148518,
                0.03521975502371788,
                0.04370126873254776,
                0.018576115369796753,
                0.061913032084703445,
                0.052282556891441345,
                0.08710024505853653,
                0.01115722581744194,
                0.007691203150898218,
                -0.035095859318971634,
                -0.013781910762190819,
                -0.05166957899928093,
                0.020190807059407234,
                -0.03765369951725006,
                0.03132778778672218,
                0.053560346364974976,
                -0.03862965479493141,
                0.013574131764471531,
                0.02450699359178543,
                0.0020944036077708006,
                0.013805621303617954,
                -0.004926763474941254,
                -0.010308697819709778,
                -0.018553532660007477,
                0.004010865930467844,
                9.630610975364107e-07,
                -0.045932698994874954,
                -0.04469817504286766,
                0.001152845099568367,
                0.019435876980423927,
                -0.034849170595407486,
                0.04063073545694351,
                0.10422565788030624,
                0.02160392329096794,
                0.04600441828370094,
                -0.007751776371151209,
                0.04666922613978386,
                0.006163447629660368,
                0.03754524886608124,
                -0.027315868064761162,
                0.010526862926781178,
                0.007544609718024731,
                -0.09317049384117126,
                -0.0016544614918529987,
                -0.054865799844264984,
                -0.04923751950263977,
                -0.0648321732878685,
                0.01945425383746624,
                0.0399647057056427,
                0.0077503751963377,
                0.01520129106938839,
                -0.007193506229668856,
                -0.036903537809848785,
                -0.06648147106170654,
                -0.012718845158815384,
                0.04055627062916756,
                0.034445635974407196,
                0.07867497950792313,
                -0.040494728833436966,
                -0.051878783851861954,
                -0.012248126789927483,
                0.030383389443159103,
                -0.0016194144263863564,
                -0.014240910299122334,
                0.004821790847927332,
                0.03848825395107269,
                0.0021767509169876575,
                0.011743423528969288,
                -0.006974153220653534,
                -0.019619809463620186,
                0.03390065208077431,
                0.00026292609982192516,
                0.031002098694443703,
                -0.015407784841954708,
                0.0386410728096962,
                -0.015546591021120548,
                0.005744632333517075,
                0.004641850478947163,
                0.014368262141942978,
                0.053167250007390976,
                -0.040930233895778656,
                0.06943505257368088,
                0.019857149571180344,
                -0.004607531242072582,
                -0.009431056678295135,
                0.007021999917924404,
                -0.05461039021611214,
                0.05688801780343056,
                0.014213966205716133,
                -0.02711394801735878,
                0.04702315106987953,
                -0.02799847163259983,
                -0.013253162615001202,
                0.03785504028201103,
                0.031120464205741882,
                -0.0239760372787714,
                0.02217787317931652,
                -0.011145487427711487,
                0.002164755016565323,
                -0.006402390077710152,
                0.013294451870024204,
                -0.042095646262168884,
                0.00674012815579772,
                -0.04880032315850258,
                -0.0016688770847395062,
                -0.05512605234980583,
                0.057962387800216675,
                -0.03098437562584877,
                -0.006116630509495735,
                0.011151328682899475,
                0.004249167628586292,
                -0.03163471817970276,
                0.014537883922457695,
                0.003637251676991582,
                0.00722908740863204,
                -0.006413618102669716,
                0.0003735623031388968,
                -0.018606355413794518,
                0.05164043977856636,
                0.013957522809505463,
                0.04470442607998848,
                0.004667454864829779,
                -0.028136732056736946,
                -0.011714641004800797,
                0.0005001534009352326,
                0.015344231389462948,
                -0.011773874051868916,
                -0.016037605702877045,
                0.012043528258800507,
                -0.05147745460271835,
                -0.00447319308295846,
                0.01727944053709507,
                -0.035935178399086,
                0.03208915889263153,
                0.03534309193491936,
                -0.004097940865904093,
                -0.02743522636592388,
                -0.010418188758194447,
                0.006025365088135004,
                0.04562428221106529,
                -0.017803514376282692,
                -0.022009622305631638,
                -0.05295203998684883,
                0.058005161583423615,
                -0.06502941250801086,
                0.013775202445685863,
                0.005069643259048462,
                0.10809159278869629,
                -0.017762798815965652,
                -0.003174480516463518,
                0.0516333244740963,
                0.005988926161080599,
                0.0083469832316041,
                0.03007109835743904,
                0.015366020612418652,
                -0.01716627925634384,
                -0.0186662208288908,
                -0.009579746052622795,
                0.018118226900696754,
                -0.08511842787265778,
                -0.008279046975076199,
                -0.049926940351724625,
                -0.05556214973330498,
                -0.008752506226301193,
                0.1088818684220314,
                0.0224627498537302,
                0.003752959193661809,
                0.010899605229496956,
                0.0256553553044796,
                -0.04897972196340561,
                0.005432880017906427,
                -0.03588845953345299,
                -0.09359390288591385,
                0.038326554000377655,
                -0.02049962617456913,
                0.02820626273751259,
                0.03720590099692345,
                0.01265947800129652,
                -0.003265485167503357,
                -0.016039913520216942,
                -0.017839640378952026,
                0.009527008049190044,
                0.060730308294296265,
                -0.10247476398944855,
                -0.008610105141997337,
                -0.00037184852408245206,
                -0.016877539455890656,
                -0.007123538292944431,
                -0.029787471517920494,
                -0.021335577592253685,
                -0.007859316654503345,
                -0.02749027870595455,
                0.05615439638495445,
                0.001659151166677475,
                0.0015530174132436514,
                0.014426779933273792,
                -0.0013846956426277757,
                0.0339677557349205,
                -0.064821258187294,
                -0.10124268382787704,
                0.0826629027724266,
                -0.02014913409948349,
                -0.023111678659915924,
                0.05952325835824013,
                -0.023913657292723656,
                -0.006851688493043184,
                -0.010824749246239662,
                -0.018144026398658752,
                -0.060775917023420334,
                -0.001043100724928081,
                0.007864536717534065,
                -0.02378508448600769,
                -0.06883005797863007,
                0.07992833852767944,
                -0.016696223989129066,
                -0.05210212990641594,
                0.0008480887627229095,
                0.03644045069813728,
                -0.013206101022660732,
                0.01564497873187065,
                0.09972838312387466,
                -0.0022799852304160595,
                -0.022493306547403336,
                0.04477907344698906,
                0.019796542823314667,
                -0.008618088439106941,
                -0.009739202447235584,
                0.08644531667232513,
                -0.05735671892762184,
                0.006941678002476692,
                -0.018348831683397293,
                -0.03520287945866585,
                0.027499765157699585,
                0.0076721361838281155,
                0.02737298421561718,
                -0.008029665797948837,
                0.021726513281464577,
                -0.013845493085682392,
                0.02214759588241577,
                0.013317897915840149,
                -0.017415784299373627,
                0.004681907594203949,
                -0.00553832808509469,
                -0.0011071021435782313,
                -0.02207481488585472,
                0.01957029104232788,
                0.013006743043661118,
                -0.07608882337808609,
                -0.012372555211186409,
                -0.053096700459718704,
                0.005030021537095308,
                -0.004603726789355278,
                -0.03498462960124016,
                0.049650825560092926,
                -0.012719960883259773,
                0.0011408758582547307,
                -0.03372611850500107,
                0.006731113884598017,
                -0.03248808532953262,
                -0.02891514077782631,
                0.04134400933980942,
                -0.01197059080004692,
                0.025967638939619064,
                0.04361194744706154,
                -0.013873799704015255,
                0.014226268045604229,
                0.006686373148113489,
                0.005998183507472277,
                0.08004036545753479,
                -0.07356385141611099,
                0.03216935694217682,
                0.005398046690970659,
                0.03154689446091652,
                -0.03466576337814331,
                -0.019422240555286407,
                -0.004340948071330786,
                -0.03410670533776283,
                0.029557378962635994,
                -0.019003188237547874,
                -0.0018195235170423985,
                -0.04284757003188133,
                -0.0086830984801054,
                0.009627738036215305,
                0.020537685602903366,
                -0.04418622702360153,
                -0.030665000900626183,
                0.05460572615265846,
                -0.050848301500082016,
                -0.023300550878047943,
                -0.020390015095472336,
                0.058640968054533005,
                -0.006939447019249201,
                -0.05117882043123245,
                0.04997288063168526,
                0.03848623111844063,
                -0.03389522805809975,
                0.07980240136384964,
                -0.01378191914409399,
                0.024918779730796814,
                -0.04486541450023651,
                -0.03241287171840668,
                -0.026968389749526978,
                0.026682045310735703,
                0.023791678249835968,
                -0.0061192079447209835,
                0.044655412435531616,
                -0.054495953023433685,
                -0.0018659398192539811,
                0.05008703097701073,
                0.016239741817116737,
                0.06376050412654877,
                0.003181352512910962,
                0.009655086323618889,
                -0.029761575162410736,
                -0.036477070301771164,
                0.008512122556567192,
                0.01772543229162693,
                -0.039457742124795914,
                0.01618492417037487,
                0.019767913967370987,
                -0.09048788994550705,
                -0.006520232651382685,
                -0.002980228513479233,
                -0.047776248306035995,
                -0.044399093836545944,
                -0.023898281157016754,
                -0.050339940935373306,
                -0.02531266026198864,
                -0.05236273258924484,
                -0.018483351916074753,
                -0.03415442258119583,
                0.035412196069955826,
                0.03605501726269722,
                -0.037724681198596954,
                -0.04822654277086258,
                0.04124852269887924,
                0.012587938457727432,
                0.004601196385920048,
                0.050288766622543335,
                -0.04932757467031479,
                -0.029127296060323715,
                0.02785278484225273,
                -0.0041638812981545925,
                0.014832286164164543,
                0.019118545576930046,
                -0.0400276742875576,
                0.03123529441654682,
                0.05702219530940056,
                0.01634017750620842,
                0.017398051917552948,
                -0.03781234100461006,
                -0.005610212683677673,
                -0.03568146005272865,
                -0.09565544128417969,
                -0.02120152860879898,
                0.04655788093805313,
                0.011540405452251434,
                0.04361869394779205,
                -0.03556148707866669,
                0.020645782351493835,
                -0.0038126390427351,
                0.004353079479187727,
                0.03196972608566284,
                -0.10336911678314209,
                0.009582159109413624,
                -0.026701828464865685,
                0.03195040300488472,
                -0.013001658953726292,
                -0.036794401705265045,
                -0.04429267719388008,
                0.058824941515922546,
                0.06123986840248108,
                -0.03145569562911987,
                0.05651276931166649,
                -0.039763499051332474,
                0.047144126147031784,
                -0.024193909019231796,
                0.036744195967912674,
                0.005889493972063065,
                -0.009791182354092598,
                0.01616065576672554,
                0.0009299627272412181,
                -0.048259299248456955,
                -0.05959196761250496,
                0.0451962873339653,
                0.0029903899412602186,
                -0.019426874816417694,
                0.007324156351387501,
                -0.049020592123270035,
                0.016521519050002098,
                0.009665428660809994,
                0.05807171389460564,
                0.026262681931257248,
                -0.008626559749245644,
                -0.0027775317430496216,
                -0.022361287847161293,
                -0.02143622189760208,
                -0.00477971974760294,
                -0.011983309872448444,
                -0.0947045385837555,
                0.059933874756097794,
                -0.04170802980661392,
                -0.015240388922393322,
                -0.004655053373426199,
                0.0028103606309741735,
                -0.008956287056207657,
                -0.0680345967411995,
                -0.008064130321145058,
                0.04145646095275879,
                -0.032747335731983185,
                -0.06884871423244476,
                0.022883247584104538,
                0.0007362602627836168,
                -0.012711012735962868,
                0.012286379933357239,
                0.05960216745734215,
                -0.009243683889508247,
                -0.015438434667885303,
                0.011138536036014557,
                0.008107341825962067,
                -0.04484261944890022,
                -0.002721283119171858,
                0.0008334285812452435,
                -0.0029036321211606264,
                0.002526654163375497,
                0.010772229172289371,
                0.003955346066504717,
                -0.01629548892378807,
                -0.014081134460866451,
                -0.044929806143045425,
                0.009723725728690624,
                -0.028658917173743248,
                0.06321034580469131,
                -0.0015052399830892682,
                -0.008328663185238838,
                0.063435398042202,
                0.03635141998529434,
                -0.03441469371318817,
                0.015473423525691032,
                -0.04168722778558731,
                -0.04847627878189087,
                0.03928849473595619,
                -0.07051870971918106,
                0.041459858417510986,
                -0.04955245926976204,
                -0.043085310608148575,
                -0.08766390383243561,
                -0.12014140188694,
                -0.07510847598314285,
                -0.02957165241241455,
                0.0009569921530783176,
                0.007857164368033409,
                0.01799912564456463,
                0.043715283274650574,
                -0.003085937350988388,
                0.025438053533434868,
                0.045857030898332596,
                -0.03323313966393471,
                0.04640292748808861,
                0.051059987396001816,
                0.0323876291513443,
                -0.011373687535524368,
                -0.005269874818623066,
                -0.030572159215807915,
                -0.01571165770292282,
                0.01730453036725521,
                0.00983717106282711,
                0.04161757603287697,
                -0.008112872950732708,
                -0.05886361747980118,
                0.006327388342469931,
                0.004678265657275915,
                0.04188378155231476,
                0.03524544835090637,
                -0.02979777194559574,
                -0.006737901829183102,
                0.030904361978173256,
                0.06391919404268265,
                -0.008908768184483051,
                0.006859422195702791,
                -0.05354020372033119,
                0.08682217448949814,
                0.0009831397328525782,
                -0.0011783952359110117,
                -0.057585135102272034,
                -0.005445195361971855,
                -0.022413458675146103,
                -0.024151373654603958,
                0.012959790416061878,
                -0.06901643425226212,
                0.03356625512242317,
                -0.05177755281329155,
                -0.005934146232903004,
                -0.009766440838575363,
                -0.006661614868789911,
                -0.030074773356318474,
                -0.02769283577799797,
                -0.010380605235695839,
                0.006685918662697077,
                -0.026325570419430733,
                -0.010080674663186073,
                0.03248981386423111,
                0.0013006811495870352,
                0.050266098231077194,
                0.03537040576338768,
                0.03647846728563309,
                -0.02540581300854683,
                0.006216001696884632,
                -0.049106430262327194,
                0.0453592911362648,
                0.02712106704711914,
                0.04450501501560211,
                -0.030206002295017242,
                0.05981660261750221,
                -0.028290124610066414,
                3.963673589169048e-05,
                -0.020664706826210022,
                0.005595169495791197,
                0.008500806987285614,
                0.03940865024924278,
                -0.00366358645260334,
                0.06151117384433746,
                -0.008741558529436588,
                -0.014335871674120426,
                0.011641060002148151,
                -0.0226654764264822,
                0.002813281025737524,
                0.03153914213180542,
                0.026791147887706757,
                0.03798497095704079,
                0.07537173479795456,
                -0.0048573375679552555,
                0.006369241513311863,
                0.050990231335163116,
                0.007256161421537399,
                -0.027678437530994415,
                -0.04292410612106323,
                0.035091955214738846,
                0.05163317546248436,
                0.024400625377893448,
                0.017811208963394165,
                -0.030938943848013878,
                -0.0019680100958794355,
                0.04036179557442665,
                0.023465527221560478,
                -0.009713732637465,
                -4.1640451070719624e-33,
                0.009953416883945465,
                -0.007877010852098465,
                0.03710111603140831,
                -0.0852983295917511,
                0.013184362091124058,
                -0.028297634795308113,
                0.03806976601481438,
                0.028270354494452477,
                -0.04010433331131935,
                -0.030876722186803818,
                -0.02735505811870098,
                0.0018098782747983932,
                0.005683851428329945,
                -0.006474240217357874,
                0.032992079854011536,
                0.048783864825963974,
                0.018449604511260986,
                0.03524063155055046,
                0.047602083534002304,
                -0.054889656603336334,
                -0.03739931061863899,
                -0.012591863051056862,
                0.024680936709046364,
                0.03320906311273575,
                0.0052968580275774,
                0.01349498052150011,
                -0.0013019046746194363,
                -0.007633599918335676,
                0.004053139127790928,
                -0.01311904564499855,
                0.025003377348184586,
                -0.05883253365755081,
                0.012102697044610977,
                -0.003362714545801282,
                0.021541647613048553,
                -0.04890351742506027,
                -0.0017581303836777806,
                0.013941793702542782,
                -0.0061570219695568085,
                0.008608962409198284,
                -0.002907677786424756,
                0.011362479999661446,
                -0.0016205767169594765,
                -0.04136188328266144,
                -0.03117428347468376,
                -0.051714226603507996,
                -0.03301459550857544,
                0.04006848856806755,
                0.016527917236089706,
                -0.016749776899814606,
                -0.030730031430721283,
                -0.022260114550590515,
                -0.029032576829195023,
                0.0513896569609642,
                0.011545608751475811,
                -0.07414120435714722,
                0.036951132118701935,
                -0.0006314608617685735,
                0.04286057502031326,
                -0.00261119962669909,
                -0.04012447968125343,
                0.0667966902256012,
                0.032989777624607086,
                0.002184564247727394,
                -0.03545291721820831,
                0.0021703459788113832,
                -0.05468651279807091,
                0.02761394903063774,
                0.06170409917831421,
                0.01793668605387211,
                -0.03353722393512726,
                0.06295274943113327,
                -0.05622495710849762,
                0.009963897056877613,
                -0.09067302197217941,
                0.0041847373358905315,
                0.01172496285289526,
                -0.05563649907708168,
                0.04441488906741142,
                0.007046232465654612,
                -0.040727242827415466,
                0.028249019756913185,
                0.05438321456313133,
                0.01871551387012005,
                -0.0004238033143337816,
                -0.07775142043828964,
                0.013682951219379902,
                0.09038832783699036,
                0.04829747974872589,
                -0.024019837379455566,
                -0.00903698056936264,
                0.05247228592634201,
                -0.017802931368350983,
                0.002907321322709322,
                -0.017552323639392853,
                -0.05754947289824486,
                0.0004063423548359424,
                0.03697648271918297,
                0.019623147323727608,
                0.01870805211365223,
                -0.05698033422231674,
                -0.02123894728720188,
                -0.03798413276672363,
                0.026684805750846863,
                0.009342193603515625,
                0.05933003127574921,
                -0.040790118277072906,
                -0.02913207747042179,
                0.009603939950466156,
                0.00244931410998106,
                0.037463728338479996,
                0.030931562185287476,
                -0.009582494385540485,
                0.001940229907631874,
                0.01653316430747509,
                0.027333596721291542,
                0.00884308386594057,
                -0.006902678869664669,
                0.01377053651958704,
                0.010468408465385437,
                0.06825398653745651,
                -0.11169060319662094,
                0.03013554960489273,
                0.006393562536686659,
                -0.02222016453742981,
                0.008206053636968136,
                0.03751964122056961,
                0.02502329833805561,
                0.023698953911662102,
                -0.008096123114228249,
                0.015485920011997223,
                0.00578512204810977,
                1.6652006706863176e-07,
                -0.019576769322156906,
                0.09426046907901764,
                0.023106008768081665,
                0.01367135252803564,
                -0.017549019306898117,
                0.0016196465585380793,
                0.027315843850374222,
                -0.009710393846035004,
                -0.06680026650428772,
                0.05647577717900276,
                -0.04704154282808304,
                -0.03594089671969414,
                -0.02219199948012829,
                -0.07434156537055969,
                -0.006867693271487951,
                -0.04304777830839157,
                -0.017306827008724213,
                0.06115039810538292,
                -0.05044548213481903,
                0.02029057778418064,
                -0.03353727608919144,
                0.017574630677700043,
                0.039229005575180054,
                0.008185967803001404,
                -0.02652818150818348,
                -0.016243871301412582,
                -0.015341076999902725,
                0.014375914819538593,
                0.07450084388256073,
                0.015685230493545532,
                -0.03188614919781685,
                0.06446463614702225,
                0.023240726441144943,
                -0.02113940753042698,
                0.01682177372276783,
                0.0008876918582245708,
                0.010759891010820866,
                0.008646534755825996,
                0.003898009890690446,
                0.05892283841967583,
                0.01337390299886465,
                -0.00897249300032854,
                -0.004895272199064493,
                -0.0020480791572481394,
                0.021983472630381584,
                0.04492010921239853,
                -0.01082848384976387,
                0.030371205881237984,
                -0.06975827366113663,
                0.0004901501815766096,
                -0.029709739610552788,
                0.027995118871331215,
                0.011368732899427414,
                -0.01888708584010601,
                0.030444249510765076,
                -0.03402961418032646,
                -0.01368445809930563,
                0.029070494696497917,
                0.06748782098293304,
                -0.0613531619310379,
                0.03497377410531044,
                -0.0166975948959589,
                0.003983055707067251,
                0.0015810952754691243,
                0.08247783780097961,
                -0.03583771362900734,
                -0.008507351391017437,
                1.2428466184989285e-34,
                -0.018112942576408386,
                -0.013483075425028801,
                0.006240454968065023,
                0.04920715466141701,
                -0.0033692854922264814,
                -0.048755452036857605,
                -0.017126651480793953,
                0.022510873153805733,
                -0.016140226274728775,
                -0.0025571982841938734,
                -0.022325925529003143
            ]
        },
        "Experience": {
            "name": "My aim is to connect people with rewarding work, simple really. Our focus is on energy and digital professionals who play an ever- growing role in the modern economy, we want to ensure they are supported in their journey. We use technology to make it as easy as possible to support this community. We are a B_Corp organisation as we want to be the change we see in the world, fairer more inclusive with a community, not corporate focus. My aim is to grow a diverse motivated team that develops great tech and plays its part in the Scottish digital economy and the communities in which we operate. Go team Nudge! Nudge is available now at www.nudgeexchange.com Experience",
            "rep": [
                0.05065951868891716,
                0.007171915378421545,
                -0.023951606824994087,
                -0.024681981652975082,
                0.009180442430078983,
                -0.00787061732262373,
                -0.016545550897717476,
                -0.0029238595161587,
                -0.010144848376512527,
                0.03231979161500931,
                0.041639022529125214,
                0.043137431144714355,
                0.0031656043138355017,
                0.10028301924467087,
                0.01556473970413208,
                -0.003596476512029767,
                0.011785153299570084,
                0.005924110300838947,
                -0.007627131417393684,
                0.010012115351855755,
                0.019891776144504547,
                0.01592925377190113,
                -0.014135573990643024,
                0.027353055775165558,
                -0.04832635074853897,
                0.013638598844408989,
                -0.002725240308791399,
                0.07391081005334854,
                0.029896102845668793,
                0.03209902346134186,
                0.040813036262989044,
                -0.03328637033700943,
                -0.038365453481674194,
                -0.004545116331428289,
                2.2910169263923308e-06,
                -0.0225522480905056,
                -0.020884551107883453,
                -0.025048457086086273,
                -0.06929856538772583,
                -0.06025393679738045,
                0.044194191694259644,
                0.03342277184128761,
                -0.012279548682272434,
                0.03823469206690788,
                0.02104867249727249,
                0.05946408957242966,
                0.055584654211997986,
                -0.04395162686705589,
                -0.01346744503825903,
                0.05876703932881355,
                -0.011269986629486084,
                0.0018542241305112839,
                -0.048613209277391434,
                -0.022526726126670837,
                -0.0435214601457119,
                -0.011834291741251945,
                0.0143800163641572,
                -0.004018132574856281,
                0.04768933728337288,
                0.015178781002759933,
                0.034589748829603195,
                0.007099423557519913,
                -0.06505080312490463,
                -0.01977282389998436,
                0.10327878594398499,
                0.0591631680727005,
                0.03114326298236847,
                -0.06879052519798279,
                -0.019016841426491737,
                -0.03798673674464226,
                0.06166551262140274,
                -0.0032064164988696575,
                -0.01985841989517212,
                0.007228029891848564,
                0.019203882664442062,
                0.008808224461972713,
                0.002976219169795513,
                0.018780257552862167,
                0.003359811846166849,
                0.02902359515428543,
                -0.042760372161865234,
                0.07850009202957153,
                -0.021591302007436752,
                0.004236480221152306,
                0.0338570661842823,
                0.0032439734786748886,
                -0.012501170858740807,
                0.03660587593913078,
                0.051134973764419556,
                0.07089149206876755,
                0.10331746935844421,
                -0.0071909306570887566,
                -0.0026236155536025763,
                0.011094294488430023,
                -0.030457844957709312,
                -0.013193146325647831,
                0.053057003766298294,
                0.02838154137134552,
                0.014616740867495537,
                0.010985697619616985,
                0.03643661364912987,
                0.0407288558781147,
                0.028084704652428627,
                0.039753757417201996,
                0.08281905204057693,
                -0.01814761571586132,
                0.016487453132867813,
                -0.06317703425884247,
                -0.07955923676490784,
                0.0028360094875097275,
                -0.004403168335556984,
                0.021562041714787483,
                -0.04007004573941231,
                0.055075377225875854,
                -0.027960404753684998,
                -0.03463631123304367,
                -0.000842271139845252,
                -0.002225817646831274,
                0.005511550232768059,
                0.011160733178257942,
                0.021739628165960312,
                0.042963478714227676,
                0.01749941147863865,
                -0.016329461708664894,
                0.03789353743195534,
                0.04199022054672241,
                -0.07016190141439438,
                0.013363519683480263,
                -0.04280860722064972,
                -0.023031454533338547,
                0.00288155535236001,
                0.026940876618027687,
                -0.007211003452539444,
                -0.013723588548600674,
                0.048531439155340195,
                0.010087423957884312,
                0.017739957198500633,
                -0.06449271738529205,
                0.0011957604438066483,
                -0.03498128429055214,
                0.011783784255385399,
                0.0034192062448710203,
                -0.03427102416753769,
                0.022930625826120377,
                0.00031765137100592256,
                -0.04462319612503052,
                -0.006905219051986933,
                0.020693296566605568,
                0.0022575268521904945,
                -0.015583989210426807,
                0.027585335075855255,
                -0.018444878980517387,
                -0.045619938522577286,
                0.010159255005419254,
                0.010256566107273102,
                0.02721160463988781,
                0.03379465267062187,
                0.008485119789838791,
                -0.04213669151067734,
                0.04754623398184776,
                0.069181889295578,
                -0.0006728381267748773,
                -0.039294108748435974,
                0.03809209167957306,
                -0.048054289072752,
                -0.007483960594981909,
                0.0986933559179306,
                0.0508468858897686,
                -0.06152792647480965,
                -0.013951989822089672,
                -0.06323518604040146,
                -0.01204710640013218,
                0.004230019636452198,
                0.06078863888978958,
                0.026840845122933388,
                -0.026432571932673454,
                0.06997844576835632,
                -0.03691471368074417,
                -0.05175662040710449,
                0.0008264630450867116,
                -0.04641971364617348,
                -0.17836779356002808,
                0.02123667486011982,
                0.02292141132056713,
                -0.0032372234854847193,
                0.031232595443725586,
                0.004174989648163319,
                0.037991926074028015,
                -0.03427978232502937,
                -0.006200559437274933,
                -0.05275129899382591,
                0.003802870400249958,
                -0.043050650507211685,
                0.07233387231826782,
                0.04065390303730965,
                -0.03738632798194885,
                -0.023777170106768608,
                -0.058076150715351105,
                -0.055693287402391434,
                -0.04141094163060188,
                0.040039341896772385,
                -0.040320124477148056,
                0.004228461999446154,
                0.04292544350028038,
                0.016631051898002625,
                0.03683297708630562,
                -0.07294415682554245,
                -0.0548323392868042,
                -0.022789496928453445,
                0.06668671220541,
                0.07549171894788742,
                0.012959454208612442,
                -0.04269436374306679,
                0.011833113618195057,
                -0.024942846968770027,
                -0.05337408557534218,
                -0.008577532134950161,
                -0.016654379665851593,
                0.012316963635385036,
                0.03436803072690964,
                -0.021984290331602097,
                0.03344815596938133,
                0.013887999579310417,
                -0.03333165869116783,
                0.023245755583047867,
                -0.00808051135390997,
                -0.02278614044189453,
                0.025594307109713554,
                -0.02042149007320404,
                0.11145631223917007,
                -0.016184212639927864,
                0.020541217178106308,
                0.045237571001052856,
                -0.010327113792300224,
                0.018241483718156815,
                -0.01944703795015812,
                0.09787902235984802,
                0.1115279272198677,
                0.0385001040995121,
                -0.05500872805714607,
                0.018750859424471855,
                0.0060781147330999374,
                -0.002331889234483242,
                0.03312262147665024,
                -0.017349129542708397,
                0.01970239356160164,
                -0.04037284478545189,
                0.014721735380589962,
                -0.057864610105752945,
                -0.0017164808232337236,
                -0.030741851776838303,
                0.014367356896400452,
                0.021577028557658195,
                0.04294104874134064,
                0.00494050420820713,
                -0.02368975058197975,
                -0.05814877897500992,
                0.002469635335728526,
                -0.04979864880442619,
                0.027083061635494232,
                -0.004390900023281574,
                -0.00907363835722208,
                -0.011256666854023933,
                0.04228651896119118,
                -0.011538628488779068,
                -0.052305981516838074,
                0.034601833671331406,
                -0.039466001093387604,
                -0.02214900031685829,
                -0.0009572276030667126,
                0.013472598977386951,
                0.013471136800944805,
                0.02826465107500553,
                -0.006593037862330675,
                -0.008316745981574059,
                -0.0020053640473634005,
                0.006346555892378092,
                0.03332878276705742,
                -0.031101282685995102,
                -0.0029314763378351927,
                0.06150706484913826,
                -0.009758583270013332,
                -0.033880557864904404,
                0.044144801795482635,
                0.006434409413486719,
                -0.030911162495613098,
                0.07185699790716171,
                -0.047626785933971405,
                -0.07481425255537033,
                -0.01779189333319664,
                0.006994010880589485,
                -0.04074464365839958,
                0.0035004878882318735,
                -0.0019786423072218895,
                0.014964640140533447,
                0.02217056043446064,
                0.00014004693366587162,
                -0.05340619385242462,
                0.03297318518161774,
                0.018492991104722023,
                -0.06559789180755615,
                -0.04391723498702049,
                0.01904679276049137,
                0.011264368891716003,
                -0.06079378351569176,
                0.021183378994464874,
                0.0010948383715003729,
                -0.03852703422307968,
                0.030784957110881805,
                -0.006023438181728125,
                0.011087918654084206,
                0.023604854941368103,
                -0.012457821518182755,
                0.07510823011398315,
                0.0073790703900158405,
                0.02535485476255417,
                -0.07957757264375687,
                -0.009545808658003807,
                0.02791607193648815,
                0.030192475765943527,
                0.010024616494774818,
                -0.0014315764419734478,
                0.00019749159400817007,
                -0.008385931141674519,
                -0.006294554565101862,
                0.002974523464217782,
                -0.08694060891866684,
                -0.0017608348280191422,
                0.061581093817949295,
                -0.1020108237862587,
                0.02181507647037506,
                -0.006557983811944723,
                -0.0024514556862413883,
                0.04369935020804405,
                -0.04729968309402466,
                -0.04998863488435745,
                -0.026921305805444717,
                -0.02510681562125683,
                -0.005674839485436678,
                -0.01895476132631302,
                -0.032421063631772995,
                0.013784622773528099,
                -0.024930942803621292,
                0.0031664094422012568,
                -0.037375327199697495,
                -0.008711595088243484,
                -0.0029065387789160013,
                0.0419180691242218,
                -0.021048059687018394,
                -0.023702073842287064,
                0.02626209706068039,
                0.008456090465188026,
                -0.0010344490874558687,
                -0.006536954548209906,
                0.04786089435219765,
                -0.043988995254039764,
                -0.029911084100604057,
                0.04562296345829964,
                0.027615847066044807,
                0.010605289600789547,
                -0.06580006331205368,
                0.0013348242500796914,
                -0.046671997755765915,
                -0.014239427633583546,
                -0.032127879559993744,
                -0.04278157278895378,
                0.07035589963197708,
                -0.010458951815962791,
                0.0011826347326859832,
                -0.029918964952230453,
                0.06667576730251312,
                0.04095858335494995,
                -0.04679744690656662,
                -0.012927133589982986,
                -0.02462407946586609,
                0.03349878266453743,
                0.019323496147990227,
                0.014418973587453365,
                0.011279302649199963,
                0.004082893952727318,
                0.054379381239414215,
                -0.07157661020755768,
                -0.003780683735385537,
                -0.006776961963623762,
                0.047585926949977875,
                0.005691098514944315,
                -0.011663696728646755,
                -0.053849466145038605,
                0.004952792078256607,
                0.02233954705297947,
                0.014814997091889381,
                0.011223375797271729,
                0.006523354910314083,
                0.02514469251036644,
                -0.0132609186694026,
                0.008707857690751553,
                -0.029997311532497406,
                0.011109820567071438,
                -0.02622150257229805,
                0.0034230616874992847,
                0.03395827114582062,
                0.03579434007406235,
                -0.018421918153762817,
                -0.030037054792046547,
                -0.0441833920776844,
                0.0358174704015255,
                0.10825145989656448,
                0.018869085237383842,
                0.03313842788338661,
                -0.017773941159248352,
                -0.03743492811918259,
                -0.05708462372422218,
                0.031134692952036858,
                -0.004114064387977123,
                -0.014185844920575619,
                -0.043533723801374435,
                0.009641616605222225,
                0.027055079117417336,
                0.058568522334098816,
                -0.08065866678953171,
                0.0578332357108593,
                -0.012320032343268394,
                -0.0400376059114933,
                0.015244255773723125,
                0.0039260732010006905,
                -0.04077041521668434,
                0.056615542620420456,
                0.029675317928195,
                0.057647474110126495,
                -0.060216572135686874,
                -0.03366684913635254,
                0.02264072559773922,
                0.010973580181598663,
                0.021771559491753578,
                0.008180221542716026,
                -0.035497575998306274,
                0.02860090509057045,
                -0.0048566823825240135,
                0.015082817524671555,
                0.022014591842889786,
                -0.05256110057234764,
                0.027436308562755585,
                -0.04792495444417,
                0.0028169318102300167,
                -0.04546526074409485,
                -0.03995645418763161,
                0.01939389854669571,
                0.011428306810557842,
                -0.03366651386022568,
                -0.021302364766597748,
                0.06934977322816849,
                -0.04221503064036369,
                -0.0299408957362175,
                -0.04414103925228119,
                -0.026480618864297867,
                0.01389725785702467,
                -0.06237321346998215,
                -0.0920208990573883,
                0.018543483689427376,
                -0.03138761967420578,
                0.05683262273669243,
                0.032840125262737274,
                0.01673145592212677,
                0.03896350413560867,
                0.06783941388130188,
                -0.011966532096266747,
                0.06894183158874512,
                -0.023481804877519608,
                0.02303033322095871,
                -0.06300346553325653,
                0.04731767624616623,
                -0.037844207137823105,
                -0.03791940212249756,
                -0.02429664321243763,
                0.010151536203920841,
                0.021923663094639778,
                0.007553688250482082,
                0.04713619127869606,
                -0.01528224442154169,
                0.008163073100149632,
                0.0074837650172412395,
                -0.0037346482276916504,
                -0.006453615613281727,
                -0.012951546348631382,
                -0.007561846170574427,
                -0.0005473135388456285,
                0.028024591505527496,
                0.015496855601668358,
                -0.036386262625455856,
                -0.06357087194919586,
                0.0031488738022744656,
                -0.04613381251692772,
                0.031968388706445694,
                0.013712353073060513,
                -0.07079848647117615,
                0.006207291968166828,
                -0.06533463299274445,
                0.049424491822719574,
                -0.08636494725942612,
                0.04552777111530304,
                -0.023124322295188904,
                0.007863560691475868,
                -0.021593457087874413,
                0.044929392635822296,
                -0.013433781452476978,
                -0.010303939692676067,
                -0.032276563346385956,
                -0.028175778687000275,
                -0.007225855253636837,
                0.007552749942988157,
                -0.00011848114809254184,
                -0.0063592055812478065,
                0.005660438910126686,
                -0.0964617058634758,
                0.050328999757766724,
                -0.00798743311315775,
                -0.022577038034796715,
                0.021457990631461143,
                0.001133567187935114,
                -0.02971968986093998,
                0.016628233715891838,
                0.0228735338896513,
                -0.032555270940065384,
                0.023240773007273674,
                -0.015740778297185898,
                -0.005521210841834545,
                -0.0008110435446724296,
                -0.009369205683469772,
                -0.053597625344991684,
                0.02283821813762188,
                -0.08727584779262543,
                -0.014210572466254234,
                -0.009966559708118439,
                0.022168882191181183,
                -0.050040461122989655,
                0.023476356640458107,
                0.02278907783329487,
                0.0352049246430397,
                -0.007289103697985411,
                0.020790187641978264,
                -0.03355655446648598,
                0.02671658992767334,
                0.011498664505779743,
                0.01082361675798893,
                -0.003024005563929677,
                -0.007644627243280411,
                0.009237190708518028,
                -0.014289440587162971,
                0.010744714178144932,
                -0.07674527913331985,
                0.01581566594541073,
                -0.0345064178109169,
                -0.07284872978925705,
                0.03798622265458107,
                -0.00987333059310913,
                -6.672881026854877e-33,
                0.00792632345110178,
                -0.03585188835859299,
                0.044635605067014694,
                0.04413146898150444,
                -0.04784076288342476,
                -0.002759877359494567,
                0.017890503630042076,
                -0.020275862887501717,
                -0.03725588694214821,
                -0.03096645511686802,
                -0.005138597451150417,
                0.020163070410490036,
                0.0183835681527853,
                0.0013489529956132174,
                -0.010623959824442863,
                0.007347920909523964,
                0.07242365181446075,
                0.015227293595671654,
                0.01795957051217556,
                -0.00786896888166666,
                -0.028637483716011047,
                -0.014299469999969006,
                0.007016915362328291,
                -0.018695993348956108,
                0.07735227793455124,
                0.024914748966693878,
                -0.07487742602825165,
                0.06314089149236679,
                0.04614654928445816,
                -0.014433645643293858,
                0.009667805396020412,
                -0.009360761381685734,
                -0.013931733556091785,
                0.00454984325915575,
                0.025492075830698013,
                0.06075979024171829,
                -0.055655352771282196,
                -0.023561103269457817,
                0.005286182276904583,
                -0.016647053882479668,
                -0.05257740989327431,
                -0.006671769078820944,
                0.03638068586587906,
                -0.04628109186887741,
                -0.03819289058446884,
                0.006769587751477957,
                0.0013056762982159853,
                -0.0030755051411688328,
                0.05802588164806366,
                0.026515593752264977,
                -0.028905602172017097,
                -0.028673898428678513,
                -0.03193357586860657,
                0.038308821618556976,
                -0.028534702956676483,
                0.01566355489194393,
                0.00439012236893177,
                -0.015087800100445747,
                -0.09490091353654861,
                -0.010757017880678177,
                -0.022043196484446526,
                -0.014653886668384075,
                0.0028186303097754717,
                0.021849244832992554,
                -0.019793519750237465,
                0.03442443907260895,
                -0.01874518021941185,
                0.008984267711639404,
                -0.0034613236784934998,
                0.023173458874225616,
                -0.012808391824364662,
                0.04321109876036644,
                0.04185713455080986,
                -0.04209397733211517,
                -0.05047667399048805,
                -0.029454631730914116,
                -0.00780528737232089,
                0.024092596024274826,
                0.08608463406562805,
                -0.02079412341117859,
                -0.009048903360962868,
                0.0045273383148014545,
                0.008545379154384136,
                0.014089786447584629,
                0.054917287081480026,
                -0.03480013832449913,
                -0.024768996983766556,
                -0.047940246760845184,
                0.02802402339875698,
                0.03212231770157814,
                -0.04549003764986992,
                0.04947412759065628,
                -0.040493082255125046,
                -0.002104369690641761,
                0.046031542122364044,
                -0.018670279532670975,
                0.05222320184111595,
                -0.00514341052621603,
                0.020993852987885475,
                -0.052726149559020996,
                0.007470854092389345,
                0.020146314054727554,
                -0.06249748915433884,
                -0.001127516501583159,
                -0.0011458639055490494,
                0.002343192230910063,
                -0.032969046384096146,
                -0.01538645289838314,
                -0.029541146010160446,
                0.014768983237445354,
                0.0022572677116841078,
                0.05374838039278984,
                -0.010020524263381958,
                -0.09747547656297684,
                0.014235399663448334,
                -0.008532566949725151,
                0.03613315522670746,
                0.04697059467434883,
                0.011251307092607021,
                0.03436780720949173,
                0.04750727862119675,
                0.019541973248124123,
                -0.007633539382368326,
                -0.002220564056187868,
                -0.013080007396638393,
                0.01614411547780037,
                0.0072123208083212376,
                0.03170744329690933,
                0.0008794754976406693,
                -0.019574444741010666,
                -0.05235571041703224,
                -0.008745669387280941,
                2.9017186875535117e-07,
                0.007773019373416901,
                0.02389305830001831,
                0.0021139022428542376,
                0.0985940545797348,
                -0.009301629848778248,
                -0.0009488899959251285,
                0.025701535865664482,
                -0.017809461802244186,
                -0.028600778430700302,
                0.0020605199970304966,
                0.000586933281738311,
                -0.0381602942943573,
                -0.021770969033241272,
                -0.008865907788276672,
                -0.028290413320064545,
                -0.05523531511425972,
                0.03355012834072113,
                0.025137947872281075,
                -0.01976182498037815,
                0.019443580880761147,
                0.05347329005599022,
                -0.025520844385027885,
                0.043595090508461,
                0.004551152233034372,
                -0.01452463585883379,
                -0.0013878901954740286,
                0.007477857638150454,
                -0.06357959657907486,
                0.0392468087375164,
                0.03128909692168236,
                -0.014892887324094772,
                -0.0437115877866745,
                0.010908789932727814,
                -0.06297627091407776,
                0.0015343036502599716,
                0.0021087820641696453,
                -0.04128508269786835,
                0.034989435225725174,
                -0.0009907427011057734,
                0.015068025328218937,
                -0.026437310501933098,
                -0.020938212051987648,
                0.03815873712301254,
                -0.005558929871767759,
                -0.013755093328654766,
                0.00525345653295517,
                4.103287574253045e-05,
                -0.008139427751302719,
                -0.012575211934745312,
                -0.01760508492588997,
                -0.015746507793664932,
                0.008579227142035961,
                -0.031176339834928513,
                -0.018403051421046257,
                0.051169052720069885,
                -0.009965888224542141,
                -0.043871812522411346,
                0.03750288113951683,
                0.05878099799156189,
                0.016821786761283875,
                -0.017239250242710114,
                -0.0004248930199537426,
                -0.01688656583428383,
                -0.0007109603611752391,
                -0.057002872228622437,
                -0.02666819840669632,
                -0.024252699688076973,
                2.406613920844951e-34,
                -0.02091806009411812,
                -0.008259001187980175,
                -0.038692329078912735,
                0.006681506987661123,
                0.023542078211903572,
                -0.0279177725315094,
                -0.025824222713708878,
                -0.0016017949674278498,
                0.0379224494099617,
                -0.025472739711403847,
                -0.032829511910676956
            ]
        },
        "Freelance": "Open to both",
        "Id": "5a2fb0c1-0044-4dc2-b5f0-5322dd3337e8",
        "Industry": {
            "name": "\tBioenergy",
            "rep": [
                0.07769370079040527,
                -0.005833006463944912,
                -0.01947455294430256,
                0.01913745515048504,
                -0.0045487890020012856,
                0.015439243987202644,
                -0.04848156496882439,
                0.05133933201432228,
                -0.03718943148851395,
                -0.020587919279932976,
                0.012728930450975895,
                0.027028651908040047,
                -0.0034611027222126722,
                0.031154338270425797,
                0.04978047311306,
                0.013662262819707394,
                0.08140690624713898,
                0.033506494015455246,
                -0.028240883722901344,
                -0.02238653600215912,
                -0.06964445114135742,
                -0.006685284432023764,
                0.0010355490958318114,
                0.04251566529273987,
                0.02488165907561779,
                0.040067318826913834,
                -0.01799609512090683,
                0.09161915630102158,
                0.026532072573900223,
                -0.01134824100881815,
                0.02127683535218239,
                -0.021407829597592354,
                -0.002684325445443392,
                0.00869807694107294,
                1.5375848079202115e-06,
                -0.06089668348431587,
                -0.04463314637541771,
                -0.021655479446053505,
                0.007336725015193224,
                -0.019927019253373146,
                0.04831172898411751,
                0.034228358417749405,
                -0.0020625467877835035,
                0.002090683439746499,
                0.05299313738942146,
                -0.05116841197013855,
                0.04753710329532623,
                -0.043239936232566833,
                -0.009483976289629936,
                -0.04148607328534126,
                -0.03434760123491287,
                -0.0033769700676202774,
                -0.016328485682606697,
                -0.025654282420873642,
                -0.04874476045370102,
                0.13100239634513855,
                -0.00514557771384716,
                -0.0376138836145401,
                -0.0032449616119265556,
                0.03564808517694473,
                -0.016830720007419586,
                0.06566894799470901,
                -0.03044041059911251,
                0.017063304781913757,
                0.07950837165117264,
                -0.01751537248492241,
                0.06326387077569962,
                -0.028281664475798607,
                0.041217781603336334,
                0.017141619697213173,
                0.014585047028958797,
                0.0051641506142914295,
                0.02582569234073162,
                0.044499967247247696,
                -0.049750424921512604,
                0.030383842065930367,
                0.058106061071157455,
                0.01475544273853302,
                -0.017628243193030357,
                0.0355837419629097,
                0.0015702785458415747,
                -0.029105110093951225,
                0.01595817692577839,
                -0.0025663282722234726,
                -0.009008453227579594,
                0.039504535496234894,
                -0.018601926043629646,
                -0.009344013407826424,
                0.04310806095600128,
                0.02630685456097126,
                0.05357867479324341,
                0.010644734837114811,
                0.008856864646077156,
                0.014530546963214874,
                0.07876280695199966,
                0.015942832455039024,
                -0.03868340700864792,
                0.09380640834569931,
                0.0008291350677609444,
                -0.015137210488319397,
                0.010731657035648823,
                -0.013618195429444313,
                0.04818231612443924,
                0.013922009617090225,
                -0.01977931708097458,
                -0.07702916115522385,
                -0.04054061695933342,
                -0.03187471628189087,
                -0.014399915933609009,
                -0.04717715457081795,
                0.012753316201269627,
                -0.013910778798162937,
                0.016896726563572884,
                0.006832195445895195,
                -0.0031564240343868732,
                -0.012732420116662979,
                -0.005643132142722607,
                -0.05255484953522682,
                -0.03998179733753204,
                -0.011227678507566452,
                0.01128085795789957,
                -0.0019573059398680925,
                0.03902939707040787,
                -5.530627822736278e-05,
                0.016656704246997833,
                0.05514170229434967,
                0.05911595746874809,
                0.027845323085784912,
                0.012282361276447773,
                -0.00695137120783329,
                -0.017577217891812325,
                -0.026996254920959473,
                -0.02245244011282921,
                0.0018740928499028087,
                -0.07004115730524063,
                0.05290386453270912,
                0.009772643446922302,
                -0.02403217926621437,
                -0.010829712264239788,
                -0.06015980243682861,
                0.0043336497619748116,
                -0.006039682310074568,
                -0.05556667968630791,
                0.022454552352428436,
                -0.004946937318891287,
                0.007883964106440544,
                0.039817970246076584,
                0.011738952249288559,
                0.030646145343780518,
                0.00043363607255741954,
                0.037956539541482925,
                0.0015394212678074837,
                -0.05766843259334564,
                0.014932656660676003,
                0.038172364234924316,
                0.01400343980640173,
                -0.04836254566907883,
                -0.03595392033457756,
                0.045366570353507996,
                0.0059347315691411495,
                -0.05416341871023178,
                0.016924792900681496,
                -0.03175705298781395,
                -0.016456445679068565,
                0.02911885641515255,
                -0.06023884937167168,
                -0.008503403514623642,
                -0.03783734142780304,
                -0.12460191547870636,
                -0.002340675098821521,
                0.03658510372042656,
                -0.06697295606136322,
                -0.04154273122549057,
                0.07161188125610352,
                0.007041345816105604,
                0.04506832733750343,
                0.040407050400972366,
                -0.02166597917675972,
                0.023374594748020172,
                -0.02258004993200302,
                0.011356103233993053,
                -0.07561272382736206,
                0.024082666262984276,
                -0.019536346197128296,
                -0.03744443878531456,
                -0.03163335099816322,
                0.050437428057193756,
                0.06156396493315697,
                0.03655551001429558,
                -0.034061044454574585,
                -0.004823030438274145,
                0.017098579555749893,
                -0.0005987699260003865,
                -0.005984135437756777,
                0.01771811582148075,
                0.04090520739555359,
                0.005830228328704834,
                0.04753405228257179,
                0.02659468911588192,
                -0.023265007883310318,
                0.019697412848472595,
                -0.0027878570836037397,
                -0.01602776348590851,
                -0.006665061693638563,
                -0.014461761340498924,
                0.017532004043459892,
                -0.016791386529803276,
                -0.0030751482117921114,
                -0.08955687284469604,
                -0.030050143599510193,
                -0.013572483323514462,
                -0.012628640979528427,
                -0.01763373240828514,
                0.043742477893829346,
                0.006689457688480616,
                -0.016887938603758812,
                -0.012522051110863686,
                -0.009399570524692535,
                -0.0898999571800232,
                0.0318705253303051,
                0.00029263622127473354,
                0.015905065461993217,
                0.019502010196447372,
                -0.009124604985117912,
                0.009242100641131401,
                0.0484689436852932,
                -0.05914345011115074,
                -0.012369616888463497,
                0.04590630158782005,
                0.006699858233332634,
                0.014293249696493149,
                0.0013552872696891427,
                0.06011122465133667,
                0.0074019418098032475,
                -0.0334511399269104,
                0.03297780454158783,
                0.053606048226356506,
                -0.001615356421098113,
                0.006846006028354168,
                0.023095199838280678,
                -0.03258242830634117,
                0.05139971897006035,
                0.061979036778211594,
                0.02085976116359234,
                0.037851978093385696,
                -2.213187144661788e-05,
                0.03687278553843498,
                0.005909954663366079,
                0.044575128704309464,
                0.021897345781326294,
                -0.0018799907993525267,
                0.010550325736403465,
                0.021379277110099792,
                0.047089386731386185,
                0.06397800147533417,
                -0.057579852640628815,
                0.00952207576483488,
                0.03376948833465576,
                -0.026998037472367287,
                0.023148512467741966,
                0.06551330536603928,
                -0.06952476501464844,
                -0.0489029735326767,
                0.02252940647304058,
                -0.004206588491797447,
                -0.03214092552661896,
                0.1049807220697403,
                -0.03526860475540161,
                -0.011357957497239113,
                -0.014195509254932404,
                0.010462685488164425,
                -0.00888054445385933,
                0.020822525024414062,
                0.02537834271788597,
                -0.002496645785868168,
                0.007408022414892912,
                0.04006671905517578,
                0.016252389177680016,
                -0.05577724799513817,
                -0.0023403975646942854,
                0.010694053955376148,
                0.026945561170578003,
                -0.01403158251196146,
                0.01039139460772276,
                -0.04473794996738434,
                0.09322294592857361,
                0.010355946607887745,
                -0.01117311604321003,
                -0.05787530168890953,
                0.009537608362734318,
                0.029031189158558846,
                -0.01880517601966858,
                -0.022024763748049736,
                -0.020509744063019753,
                -0.028751859441399574,
                0.003563511185348034,
                -0.015366989187896252,
                -0.024555208161473274,
                0.03575458750128746,
                -8.716567390365526e-05,
                -0.06576184928417206,
                0.05066289007663727,
                -0.047915246337652206,
                0.006820580922067165,
                0.01896158792078495,
                0.04890276491641998,
                0.008323414251208305,
                -0.057588960975408554,
                0.03772023320198059,
                -0.02950998581945896,
                0.021192850545048714,
                0.06090490520000458,
                0.039101749658584595,
                0.08194531500339508,
                0.012990353628993034,
                -0.0539543516933918,
                0.008180194534361362,
                -0.015444534830749035,
                0.03473031148314476,
                0.03909827396273613,
                0.006235014647245407,
                -0.018847433850169182,
                0.03096625953912735,
                0.006653914228081703,
                0.006519581191241741,
                -0.015995508059859276,
                -0.04780945926904678,
                0.06551871448755264,
                0.002322838641703129,
                -0.009697690606117249,
                0.013824456371366978,
                0.009402007795870304,
                -0.004721926990896463,
                0.007086333818733692,
                -0.030218807980418205,
                -0.018610075116157532,
                0.03329897299408913,
                -0.057255085557699203,
                -0.010545577853918076,
                0.014981837943196297,
                0.008987796492874622,
                -0.028315510600805283,
                -0.0556100457906723,
                -0.00898350216448307,
                0.0014228041982278228,
                0.020228853449225426,
                -0.00038289622170850635,
                -0.008412213064730167,
                -0.048079073429107666,
                -0.024836454540491104,
                0.08007044345140457,
                0.0030154462438076735,
                -0.020198479294776917,
                -0.03640724718570709,
                -0.003784773638471961,
                0.09388966113328934,
                -0.048181720077991486,
                -0.037024401128292084,
                -0.0024826000444591045,
                0.054888732731342316,
                -0.013866299763321877,
                0.017104703933000565,
                -0.06292162835597992,
                -0.03887171670794487,
                0.01196576002985239,
                0.054114095866680145,
                0.0074407923966646194,
                0.035422664135694504,
                0.028539272025227547,
                -0.04632601514458656,
                0.046682488173246384,
                0.01371600478887558,
                -0.05517718568444252,
                0.02196544036269188,
                -0.030467767268419266,
                0.04690352454781532,
                0.012439625337719917,
                0.016115494072437286,
                0.0009424760937690735,
                -0.019540198147296906,
                0.03648639842867851,
                -0.014956001192331314,
                -0.0365801677107811,
                0.017721425741910934,
                -0.0044959532096982,
                0.029935425147414207,
                0.021467993035912514,
                -0.03129544109106064,
                0.019467812031507492,
                0.0006497706635855138,
                -0.021132202818989754,
                0.027404818683862686,
                -0.06282279640436172,
                -0.0013861183542758226,
                0.017331337556242943,
                -0.03439303860068321,
                -0.03794718161225319,
                0.021274756640195847,
                -0.03805985301733017,
                0.0180681012570858,
                -0.004678746685385704,
                0.06111002713441849,
                0.012248153798282146,
                0.004953976254910231,
                -0.050508759915828705,
                -0.007437019143253565,
                0.10018204152584076,
                -0.023089533671736717,
                0.05350589379668236,
                -0.004263975191861391,
                0.055832937359809875,
                -0.050054002553224564,
                0.01928599923849106,
                -0.09453115612268448,
                -0.050704892724752426,
                0.0011249794624745846,
                -0.008744113147258759,
                -0.023815443739295006,
                -0.02101295441389084,
                -0.01132354699075222,
                -0.006181152071803808,
                0.048147138208150864,
                0.01996776834130287,
                -0.01747455634176731,
                0.011586232110857964,
                0.022934088483452797,
                0.006087134126573801,
                0.005405483301728964,
                0.022843658924102783,
                0.0096298111602664,
                -0.019791871309280396,
                -0.10727641731500626,
                -0.06321223080158234,
                0.01206936500966549,
                -0.05301312729716301,
                -0.018569137901067734,
                -0.03437361121177673,
                -0.019715895876288414,
                -0.022831348702311516,
                -0.013348699547350407,
                0.011511293239891529,
                -0.02553260140120983,
                -0.006747421342879534,
                -0.05695614218711853,
                -0.006551980506628752,
                0.11403977125883102,
                -0.011844796128571033,
                -0.018202178180217743,
                -0.048575613647699356,
                -0.0033093474339693785,
                -0.009251273237168789,
                -0.058267559856176376,
                -0.08918949216604233,
                0.051428619772195816,
                0.035449475049972534,
                0.026792801916599274,
                0.008311818353831768,
                0.011006140150129795,
                -0.009304545819759369,
                -0.025664810091257095,
                0.04331878200173378,
                0.021486669778823853,
                -0.052800290286540985,
                0.036620549857616425,
                0.06344719976186752,
                0.03260781615972519,
                0.0785449892282486,
                -0.06519021838903427,
                0.009291796945035458,
                0.0317382775247097,
                0.06067441403865814,
                -0.02267174981534481,
                0.019909238442778587,
                0.03209073096513748,
                -0.008203578181564808,
                -0.04983457550406456,
                0.015729399397969246,
                -0.05660279467701912,
                0.00982267688959837,
                0.004732244648039341,
                0.02710556797683239,
                -0.0019691381603479385,
                -0.03461930900812149,
                -0.013243140652775764,
                0.008360711857676506,
                -0.02463115192949772,
                0.008834119886159897,
                0.0062013347633183,
                -0.060583170503377914,
                0.001557788928039372,
                0.009264425374567509,
                -0.01744890958070755,
                0.024082748219370842,
                0.05046088621020317,
                0.002304146299138665,
                -0.03281452879309654,
                -0.0584053136408329,
                0.031578581780195236,
                0.01385329756885767,
                0.02551659755408764,
                -0.045895759016275406,
                0.003613230772316456,
                -0.005691687110811472,
                -0.05542095750570297,
                -0.023278292268514633,
                -0.021594593301415443,
                -0.010941535234451294,
                -0.004511886741966009,
                0.0017512498889118433,
                -0.019126253202557564,
                0.019824013113975525,
                0.04382014274597168,
                -0.012571061961352825,
                0.02608003094792366,
                0.04507562890648842,
                0.015816397964954376,
                -0.020512280985713005,
                0.014496739953756332,
                0.023477958515286446,
                0.01921743154525757,
                -0.017986971884965897,
                0.020543411374092102,
                0.016032934188842773,
                -0.08244277536869049,
                0.03508901596069336,
                -0.013675076887011528,
                0.04749632254242897,
                0.010756395757198334,
                -0.03167496249079704,
                0.06267333030700684,
                0.04212302342057228,
                0.02850954234600067,
                -0.018061455339193344,
                0.008534114807844162,
                -0.04977341741323471,
                0.0028718828689306974,
                0.0007572273607365787,
                0.019584106281399727,
                -0.0008962757419794798,
                0.03977850824594498,
                -0.006383167114108801,
                0.00454685278236866,
                0.057262957096099854,
                0.02342314086854458,
                -0.0018235960742458701,
                -0.0003558093449100852,
                -0.00466950936242938,
                -0.04850911349058151,
                -0.0007797002326697111,
                -0.09281454980373383,
                0.02172764018177986,
                0.05633847415447235,
                -0.05390535667538643,
                -0.04697959125041962,
                -0.012667600065469742,
                -6.420902792496581e-33,
                -0.012131985276937485,
                -0.017671091482043266,
                -0.009745354764163494,
                0.04155667498707771,
                -0.09108739346265793,
                -0.030104652047157288,
                -0.0017417395720258355,
                -0.021627113223075867,
                -0.03824084252119064,
                0.015900075435638428,
                0.023683443665504456,
                -0.03751282021403313,
                0.0065560974180698395,
                0.007411599159240723,
                0.013182380236685276,
                0.011159227229654789,
                -0.040675949305295944,
                0.0002860048261936754,
                0.019105257466435432,
                -0.026228828355669975,
                0.03472357615828514,
                0.007919690571725368,
                -0.01500053983181715,
                0.027503879740834236,
                0.014116683043539524,
                -0.0024301561061292887,
                -0.08145906776189804,
                0.011092397384345531,
                -0.061564601957798004,
                -0.038666825741529465,
                0.010988161899149418,
                0.002816195832565427,
                -0.0011817787308245897,
                -0.03484005108475685,
                -0.01473347656428814,
                0.0034920922480523586,
                -0.0003528632514644414,
                -0.019579291343688965,
                -0.046635594218969345,
                -0.011906168423593044,
                -0.1035294383764267,
                0.010019169189035892,
                0.04169116169214249,
                -0.013178803026676178,
                -0.04840471222996712,
                -0.02076629363000393,
                -0.008423126302659512,
                -0.06023407727479935,
                0.019939523190259933,
                -0.01282249391078949,
                0.004356296733021736,
                -0.03120281733572483,
                0.004363956395536661,
                0.025432510301470757,
                -0.014406229369342327,
                0.07795801013708115,
                -0.033694297075271606,
                0.05381428450345993,
                -0.02899416908621788,
                0.005351916886866093,
                0.05075303092598915,
                0.04992566257715225,
                0.007526209577918053,
                0.009534782730042934,
                -0.018722692504525185,
                0.02302587404847145,
                -0.029871823266148567,
                -0.03228195011615753,
                -0.033368825912475586,
                0.06091485917568207,
                -0.01526084914803505,
                0.040650565177202225,
                0.02370731718838215,
                -0.032258130609989166,
                -0.024791037663817406,
                -0.03734389692544937,
                -0.030927574262022972,
                0.03167693689465523,
                0.028962375596165657,
                0.004544598050415516,
                0.011908133514225483,
                -0.052895769476890564,
                0.09625892341136932,
                -0.019703492522239685,
                -0.008725300431251526,
                0.03086993470788002,
                -0.035729505121707916,
                0.06961160153150558,
                -0.00495481351390481,
                -0.016529114916920662,
                0.008206481114029884,
                0.0169337410479784,
                0.011388266459107399,
                -0.00430207559838891,
                0.023754162713885307,
                0.09624017030000687,
                -0.009780946187675,
                -0.048601869493722916,
                -6.387287430698052e-05,
                0.019817287102341652,
                0.025031518191099167,
                0.004822661634534597,
                -0.03366808593273163,
                0.07602503895759583,
                -0.007873127236962318,
                -0.0445832833647728,
                -0.020430931821465492,
                -0.03645617887377739,
                -0.002720061456784606,
                0.024212639778852463,
                -0.014820178039371967,
                -0.002126721665263176,
                0.01571919396519661,
                -0.03001222014427185,
                0.014060198329389095,
                -0.017557885497808456,
                0.008331090211868286,
                0.005033435765653849,
                -0.049245674163103104,
                0.06726126372814178,
                0.03177809342741966,
                0.04008544981479645,
                -0.027664141729474068,
                0.05288619548082352,
                -0.030269140377640724,
                0.007721554022282362,
                -0.014915388077497482,
                0.0468422994017601,
                -0.10608953982591629,
                0.0062406244687736034,
                0.011674268171191216,
                0.029114479199051857,
                2.1519096549127426e-07,
                -0.04702235758304596,
                -0.012728576548397541,
                -0.03053017519414425,
                -0.061577245593070984,
                -0.03023367002606392,
                0.024434782564640045,
                0.024789879098534584,
                0.029730459675192833,
                -0.04622102528810501,
                -0.028831299394369125,
                -0.023384355008602142,
                -0.03911999613046646,
                -0.003811078378930688,
                -0.02921210415661335,
                -0.002861558925360441,
                -0.036314867436885834,
                0.0001032764557749033,
                0.00041878342744894326,
                0.03903009742498398,
                -0.027808062732219696,
                0.0036603393964469433,
                -0.019828153774142265,
                -0.07310666888952255,
                -0.022767672315239906,
                -0.03108791820704937,
                0.036508798599243164,
                0.027127310633659363,
                -0.01977595128118992,
                0.05279792845249176,
                0.006552015896886587,
                0.022842245176434517,
                0.014237419702112675,
                0.03064526803791523,
                -0.07125736773014069,
                -0.017559127882122993,
                -0.03461310639977455,
                0.008013002574443817,
                0.030612988397479057,
                -0.03832947462797165,
                0.028534051030874252,
                0.005695690866559744,
                0.047107845544815063,
                0.002615922363474965,
                0.04485827684402466,
                -0.07261447608470917,
                0.039848022162914276,
                0.022247688844799995,
                -0.008642110042273998,
                -0.04482226446270943,
                -0.0029399569611996412,
                -0.03766849637031555,
                -0.005484469700604677,
                -0.007469597272574902,
                -0.0013865387300029397,
                -0.023116527125239372,
                -0.020296502858400345,
                -0.013992149382829666,
                0.01953285001218319,
                0.02892795391380787,
                0.03192707523703575,
                -0.023083489388227463,
                0.06276760250329971,
                -0.03892151638865471,
                0.08101075142621994,
                0.04617752134799957,
                -0.008770817890763283,
                0.01197048556059599,
                9.147847418724e-35,
                0.016436807811260223,
                -0.023830043151974678,
                -0.10275816917419434,
                -0.012594128958880901,
                -0.024151314049959183,
                0.006061450112611055,
                -0.049334924668073654,
                0.021394625306129456,
                -0.051190491765737534,
                -0.06057390198111534,
                -0.004399037454277277
            ]
        },
        "Location": "Glasgow, Scotland, United Kingdom",
        "Remuneration": 0,
        "Services Required": {
            "name": "Founder",
            "rep": [
                0.003924353513866663,
                0.07659849524497986,
                -0.0028219774831086397,
                0.009004507213830948,
                -0.008119435049593449,
                -0.009709787555038929,
                0.0004883158253505826,
                0.006515571381896734,
                -0.027424247935414314,
                0.032803721725940704,
                0.03142417594790459,
                -0.009872103109955788,
                0.005266138818114996,
                0.028518864884972572,
                -0.021683070808649063,
                0.043470922857522964,
                -0.024339236319065094,
                0.06066643446683884,
                -0.03083062916994095,
                -0.021933702751994133,
                0.03409456089138985,
                0.04049016162753105,
                0.02110627479851246,
                -0.0012270602164790034,
                0.0009160537156276405,
                -0.0004736749979201704,
                0.034546297043561935,
                0.0367453508079052,
                -0.044234249740839005,
                0.014721540734171867,
                0.013329983688890934,
                0.0021660346537828445,
                -0.016179243102669716,
                0.019668255001306534,
                1.960125018740655e-06,
                0.01770991086959839,
                0.027725577354431152,
                -0.035426754504442215,
                -0.00892742071300745,
                -0.013746114447712898,
                -0.013251936994493008,
                0.06285420060157776,
                -0.0333070307970047,
                0.013570327311754227,
                -0.038174230605363846,
                0.00252148206345737,
                0.02322615310549736,
                0.04102025926113129,
                -0.02669968642294407,
                0.021234674379229546,
                -0.0034483543131500483,
                -0.06244472414255142,
                0.0062582893297076225,
                -0.03724139183759689,
                -0.003913057968020439,
                2.0488381778704934e-05,
                -0.008700385689735413,
                0.08751320093870163,
                -0.07904678583145142,
                -0.023267000913619995,
                -0.018626917153596878,
                0.03949914872646332,
                -0.0015942830359563231,
                -0.015941841527819633,
                0.041756436228752136,
                0.003732716664671898,
                0.07430285960435867,
                -0.034651536494493484,
                -0.006439961958676577,
                0.005204865243285894,
                0.1012565940618515,
                0.03357303515076637,
                0.04286447912454605,
                0.11668947339057922,
                0.019801046699285507,
                -0.00531800277531147,
                0.02864711545407772,
                -0.00833872240036726,
                -0.031475406140089035,
                0.011936325579881668,
                0.0455893911421299,
                0.03393714129924774,
                -0.023378580808639526,
                0.020591838285326958,
                -0.03271108865737915,
                0.03345933184027672,
                -0.011012072674930096,
                -0.01657092198729515,
                0.004530887585133314,
                0.023766392841935158,
                0.06827519088983536,
                -0.03514840826392174,
                0.009098946116864681,
                0.024591242894530296,
                0.01538554672151804,
                -0.026378903537988663,
                -0.020142080262303352,
                0.0433942936360836,
                -0.039363376796245575,
                -0.03229685127735138,
                -0.08466623723506927,
                -0.00733441486954689,
                0.0037612507585436106,
                0.04209105670452118,
                -0.008801618590950966,
                0.051902979612350464,
                -0.00475966464728117,
                0.008827527984976768,
                0.014279332011938095,
                0.08242696523666382,
                -0.03399625048041344,
                0.02739042229950428,
                7.288496271939948e-05,
                0.035444214940071106,
                0.031675443053245544,
                -0.017452998086810112,
                -0.05850239843130112,
                0.04873435199260712,
                0.04485481232404709,
                -0.018785536289215088,
                -0.04772467166185379,
                0.018594488501548767,
                0.03519713878631592,
                0.03932533785700798,
                -0.023041829466819763,
                -0.034373022615909576,
                -0.0394272580742836,
                0.007390976883471012,
                0.02870924025774002,
                -0.03270559012889862,
                -0.027065858244895935,
                0.03194468095898628,
                -0.01816668175160885,
                -0.01674887351691723,
                0.020612778142094612,
                -0.12672273814678192,
                -0.029591666534543037,
                -0.038985565304756165,
                0.0037531054113060236,
                -0.03618105500936508,
                -1.1797599654528312e-05,
                -0.036606043577194214,
                -0.08331099897623062,
                -0.015531234443187714,
                0.007327938452363014,
                -0.012986590154469013,
                0.011915660463273525,
                -0.09002309292554855,
                0.00897346343845129,
                -0.011055327020585537,
                -0.0201541930437088,
                -0.0702870562672615,
                -0.027473295107483864,
                0.0336335189640522,
                0.06685864180326462,
                0.020833052694797516,
                0.0003060565795749426,
                -0.05686791241168976,
                0.006518635433167219,
                -0.029322125017642975,
                -0.028727276250720024,
                0.023104345425963402,
                -0.0349121168255806,
                0.008369793184101582,
                -0.0038928089197725058,
                -0.030410083010792732,
                0.1038297638297081,
                0.008121715858578682,
                -0.13051362335681915,
                0.007634328678250313,
                -0.04832558333873749,
                0.011668491177260876,
                -0.03480321168899536,
                0.10115338861942291,
                -0.006036003120243549,
                0.0038767121732234955,
                0.009122626855969429,
                -0.00515024596825242,
                -0.004149931482970715,
                0.0017078084638342261,
                0.023671474307775497,
                -0.18213149905204773,
                0.01227182149887085,
                -0.08182734996080399,
                -0.002686805324628949,
                -0.004487286321818829,
                -0.00907125510275364,
                0.03636475279927254,
                -0.009552694857120514,
                -0.0013614442432299256,
                0.03507842496037483,
                0.0102672865614295,
                -0.05146046727895737,
                0.055311791598796844,
                0.032460182905197144,
                -0.0012966236099600792,
                0.016052449122071266,
                0.03467670828104019,
                -0.039722029119729996,
                -0.029975896701216698,
                0.0006000013672746718,
                0.007900673896074295,
                0.006524081341922283,
                0.032921865582466125,
                -0.03410278260707855,
                0.004584462847560644,
                0.07379616796970367,
                -0.018621256574988365,
                -0.051853328943252563,
                -0.03467041626572609,
                0.02797541208565235,
                0.041778430342674255,
                -0.04036330804228783,
                0.0014049520250409842,
                -0.018756812438368797,
                0.021385423839092255,
                0.019204989075660706,
                0.036145344376564026,
                -0.024748288094997406,
                0.09691833704710007,
                -0.01594444364309311,
                0.003472591284662485,
                0.046565912663936615,
                -0.00498694833368063,
                -0.05516836419701576,
                -0.07274065911769867,
                0.00322817824780941,
                -0.006951752118766308,
                0.007869314402341843,
                0.03977980464696884,
                -0.02339646779000759,
                -0.0034235871862620115,
                0.007206397596746683,
                0.0088274534791708,
                -0.007475786842405796,
                0.0062602064572274685,
                0.05182787403464317,
                -0.061691876500844955,
                0.027486803010106087,
                -0.07969403266906738,
                0.01995350793004036,
                0.049830060452222824,
                0.03272133693099022,
                -0.041888926178216934,
                0.06752829253673553,
                -0.01160153467208147,
                0.018889406695961952,
                -0.03701336681842804,
                -0.003394004888832569,
                -0.029244516044855118,
                -0.01252280455082655,
                -0.03697318211197853,
                0.057730838656425476,
                -0.00011371848813723773,
                0.0052199214696884155,
                0.00013723396114073694,
                -0.045296307653188705,
                0.00015577218437101692,
                -0.0087830675765872,
                -0.034942276775836945,
                -0.05480771139264107,
                -0.06152975559234619,
                -0.007735375314950943,
                -0.017999162897467613,
                0.02504141442477703,
                0.001522770500741899,
                -0.000517258420586586,
                -0.07532227039337158,
                -0.0021199784241616726,
                -0.046071190387010574,
                0.0006716790958307683,
                -0.01471535675227642,
                0.041991014033555984,
                -0.0008734901784919202,
                0.05014285445213318,
                -0.023061223328113556,
                0.01343569066375494,
                -0.014605994336307049,
                0.0010169640881940722,
                0.019447702914476395,
                0.00462490925565362,
                -0.05580534785985947,
                -0.030520452186465263,
                -0.013704423792660236,
                0.020013099536299706,
                -0.031734857708215714,
                0.06710562855005264,
                -0.01965794526040554,
                0.01416687574237585,
                -0.017627514898777008,
                -0.04035447537899017,
                -0.019747383892536163,
                0.02169131301343441,
                0.06101443991065025,
                0.040263090282678604,
                -0.009461953304708004,
                -0.032181516289711,
                -0.023096414282917976,
                0.01394291128963232,
                0.08442878723144531,
                -0.04532159864902496,
                -0.02683687023818493,
                0.05481358990073204,
                0.005753127858042717,
                -0.043955039232969284,
                -0.026375241577625275,
                0.036922965198755264,
                0.016880657523870468,
                0.00703116599470377,
                0.0030776115600019693,
                0.00763656897470355,
                0.04774424806237221,
                0.006245863623917103,
                -0.030002258718013763,
                0.026140624657273293,
                -0.005740605294704437,
                0.030730318278074265,
                -0.031216038390994072,
                -0.009493455290794373,
                0.098580501973629,
                -0.016585933044552803,
                0.007549252361059189,
                -0.008962971158325672,
                0.01647261157631874,
                0.02365773171186447,
                0.0984283983707428,
                -0.019880065694451332,
                -0.0178645309060812,
                -0.03225239738821983,
                -0.002312726341187954,
                0.013104798272252083,
                0.057957474142313004,
                0.0030216665472835302,
                0.0055615524761378765,
                -0.000277962681138888,
                0.05113106593489647,
                -0.05323057994246483,
                -0.06243916228413582,
                0.018212374299764633,
                0.018823327496647835,
                0.020442582666873932,
                -0.011675120331346989,
                -0.006278678774833679,
                -0.04291877523064613,
                -0.030628494918346405,
                -0.026563851162791252,
                -0.0012657383922487497,
                0.029663244262337685,
                -0.04391948878765106,
                -0.03069736622273922,
                0.006544768810272217,
                -0.009450811892747879,
                -0.00420659314841032,
                -0.0071156760677695274,
                -0.0011004467960447073,
                0.017242342233657837,
                -0.028567219153046608,
                -0.02758103236556053,
                0.010187570005655289,
                0.017423365265130997,
                -0.03800377622246742,
                -0.056333787739276886,
                0.052944839000701904,
                -0.03385782614350319,
                0.056589167565107346,
                -0.03292341157793999,
                0.03105122782289982,
                0.04105052724480629,
                -0.011406638659536839,
                -0.019240280613303185,
                0.0022612272296100855,
                0.05658746138215065,
                0.0023340783081948757,
                0.019088223576545715,
                0.024383874610066414,
                0.015454074367880821,
                0.04478909820318222,
                0.014162982814013958,
                0.02786874957382679,
                0.03273366391658783,
                0.024378476664423943,
                -0.028174350038170815,
                0.016626544296741486,
                -0.03206433728337288,
                -0.018025964498519897,
                0.005384307820349932,
                -0.0351920910179615,
                0.0007363528711721301,
                0.007852040231227875,
                0.004615836776793003,
                -0.0025015107821673155,
                -0.07802169770002365,
                0.019592398777604103,
                0.005213549826294184,
                -0.01657090149819851,
                -0.05709030106663704,
                -0.02195148728787899,
                0.018968675285577774,
                -0.03367021307349205,
                0.01551117654889822,
                0.029033605009317398,
                0.034430261701345444,
                0.031140044331550598,
                -0.019233202561736107,
                -0.04412948340177536,
                -0.0752059817314148,
                0.07832279056310654,
                -0.03172297775745392,
                0.021101241931319237,
                0.04371321201324463,
                -0.01805930957198143,
                -0.021453164517879486,
                -0.029748963192105293,
                -0.0019277898827567697,
                -0.009809082373976707,
                -0.07773333042860031,
                -0.022163763642311096,
                0.01386972889304161,
                0.045626211911439896,
                -0.06555663794279099,
                0.04176447167992592,
                -0.01251364778727293,
                -0.009338164702057838,
                0.056416627019643784,
                0.04447900131344795,
                0.01342753041535616,
                0.0037832632660865784,
                0.05472632870078087,
                0.02174893207848072,
                -0.007245384156703949,
                0.012964236550033092,
                -0.02367791347205639,
                -0.02656138688325882,
                0.03549972176551819,
                0.04013093560934067,
                -0.10894016176462173,
                -0.07344707101583481,
                0.05255128815770149,
                0.010969786904752254,
                -0.045251861214637756,
                0.02272648736834526,
                0.03231535479426384,
                -0.05098177492618561,
                -0.00464523583650589,
                -0.025352023541927338,
                -0.04542165249586105,
                -0.008819088339805603,
                0.015174955129623413,
                -0.04021706059575081,
                -0.010120835155248642,
                -0.03901979699730873,
                -0.0742134377360344,
                -0.007937616668641567,
                -0.007374724838882685,
                -0.0009112931438721716,
                -0.015514831058681011,
                0.04827470704913139,
                -0.04562998563051224,
                -0.0030523736495524645,
                0.0038575506769120693,
                -0.029730940237641335,
                0.025432828813791275,
                0.029236190021038055,
                -0.027699749916791916,
                0.053764067590236664,
                0.0014881712850183249,
                -0.028812509030103683,
                -0.039540618658065796,
                0.025960834696888924,
                -0.04896026849746704,
                -0.022069156169891357,
                -0.0029395963065326214,
                0.016752149909734726,
                -0.028653357177972794,
                -0.038894832134246826,
                0.0016353640239685774,
                -0.02165803126990795,
                -0.002766588469967246,
                0.07398245483636856,
                0.03781944885849953,
                0.009150922298431396,
                0.05040579289197922,
                -0.025872627273201942,
                0.020862732082605362,
                0.03302258998155594,
                -0.003811539150774479,
                0.02291749231517315,
                0.006429347209632397,
                -0.015502488240599632,
                0.002995045157149434,
                0.013672024011611938,
                -0.019381871446967125,
                0.011483491398394108,
                -0.008055920712649822,
                0.023549512028694153,
                0.08101571351289749,
                -0.013770685531198978,
                -0.05996611714363098,
                -0.008741355501115322,
                -0.03731938451528549,
                -0.004374320153146982,
                0.023355059325695038,
                0.033795855939388275,
                0.07850933074951172,
                0.002076584380120039,
                0.0076098693534731865,
                -0.020085981115698814,
                -0.010648136027157307,
                -0.006958991289138794,
                -0.0019518232438713312,
                -0.02346744015812874,
                -0.02482929453253746,
                -0.032210689038038254,
                -0.06515873968601227,
                0.03251562640070915,
                -0.023411786183714867,
                -0.022857241332530975,
                -0.016032859683036804,
                0.06834106147289276,
                -0.010420884937047958,
                0.004842379130423069,
                -0.02282843552529812,
                0.013404382392764091,
                -0.000819791981484741,
                -0.03728650137782097,
                0.0023922764230519533,
                0.040929246693849564,
                0.017674211412668228,
                0.03164193406701088,
                0.012918236665427685,
                0.014433653093874454,
                0.03101733699440956,
                -0.02255871333181858,
                0.011786156333982944,
                -0.04263996332883835,
                0.032793037593364716,
                -0.0083151925355196,
                -0.07890674471855164,
                0.01002985704690218,
                -0.007906116545200348,
                -0.07052253186702728,
                0.08084265142679214,
                0.002357283141463995,
                0.0030447363387793303,
                0.03698902577161789,
                0.013297847472131252,
                0.013907513581216335,
                0.029591387137770653,
                -0.038549602031707764,
                0.03065403923392296,
                -0.028557797893881798,
                -0.021827245131134987,
                -0.08714974671602249,
                -0.037061657756567,
                0.02774125523865223,
                -7.831660582692441e-33,
                0.027827410027384758,
                0.04046449810266495,
                0.011522430926561356,
                -0.009835735894739628,
                -0.01776314340531826,
                -0.013034069910645485,
                0.005516435019671917,
                0.04569673165678978,
                -0.06354694068431854,
                -0.04033233970403671,
                -0.037355825304985046,
                -0.03682122379541397,
                0.02509165368974209,
                -0.0022123565431684256,
                -0.009253687225282192,
                -0.028106315061450005,
                -0.015177392400801182,
                -0.004829610697925091,
                -0.0014089263277128339,
                0.03203501179814339,
                -0.026423241943120956,
                -0.013473935425281525,
                -0.031930603086948395,
                0.04691532254219055,
                -0.005076071247458458,
                0.0015521578025072813,
                0.005377636756747961,
                0.036014754325151443,
                0.004614861216396093,
                -0.02841787599027157,
                -0.017640646547079086,
                -0.03395562618970871,
                -0.017410993576049805,
                0.008843839168548584,
                -0.0057350145652890205,
                -0.03362138941884041,
                0.0046957703307271,
                0.09199100732803345,
                0.01059802807867527,
                0.0008375751203857362,
                -0.0805952399969101,
                0.046627145260572433,
                -0.02501404844224453,
                0.0048769754357635975,
                0.02223334275186062,
                -0.005041054449975491,
                -0.005040483083575964,
                0.02548934519290924,
                0.011260774917900562,
                -0.011071758344769478,
                -0.018239345401525497,
                0.010103834792971611,
                -0.029971245676279068,
                -0.01993546076118946,
                0.019677715376019478,
                -0.019711166620254517,
                -0.020019667223095894,
                -0.03130623698234558,
                0.05999884381890297,
                -0.035243649035692215,
                0.011416886001825333,
                -0.0258963443338871,
                -0.011902653612196445,
                0.08400891721248627,
                -0.05456262826919556,
                -0.00872363056987524,
                0.08116628229618073,
                0.08533161133527756,
                0.0028924052603542805,
                0.0796617642045021,
                -0.038115791976451874,
                0.03557911142706871,
                0.010912049561738968,
                0.074427530169487,
                -0.04738440364599228,
                0.013654513284564018,
                -0.029381301254034042,
                0.0066963136196136475,
                0.030379215255379677,
                -0.0424603670835495,
                0.006026195362210274,
                -0.011285192333161831,
                0.009856085292994976,
                -0.01646275445818901,
                -0.01394105888903141,
                -0.06862641125917435,
                -0.01153558399528265,
                0.02231265977025032,
                -0.03065258078277111,
                0.008453070186078548,
                -0.0019192112376913428,
                -0.021127089858055115,
                0.01971992291510105,
                -0.022777598351240158,
                -0.020085657015442848,
                -0.04945766180753708,
                0.0011042029364034534,
                0.024719977751374245,
                0.036131102591753006,
                -0.027579180896282196,
                -0.027965256944298744,
                0.049483783543109894,
                -0.04414945840835571,
                0.10373177379369736,
                0.003077612491324544,
                0.004162202123552561,
                0.016065236181020737,
                -0.023267069831490517,
                0.018031414598226547,
                -0.0279147457331419,
                -0.0028820782899856567,
                0.0003333865897729993,
                0.029113825410604477,
                -0.050077781081199646,
                0.004430476576089859,
                0.013744995929300785,
                -0.01751568168401718,
                0.011429852806031704,
                0.0339629128575325,
                -0.03559073060750961,
                0.01836840622127056,
                -0.00435632886365056,
                0.006002048030495644,
                4.7815679863560945e-05,
                -0.03747190535068512,
                -0.0019116809125989676,
                0.013044449500739574,
                0.052232109010219574,
                0.037385717034339905,
                -0.01883372664451599,
                -0.0061648390255868435,
                -0.05527720972895622,
                2.5835262817963667e-07,
                0.007831153459846973,
                0.012710976414382458,
                0.009739313274621964,
                -0.0898110419511795,
                -0.010140364989638329,
                0.005867327563464642,
                0.061108238995075226,
                0.009962933138012886,
                -0.06812173128128052,
                -0.03505394235253334,
                0.0196105744689703,
                0.018467234447598457,
                -0.01207845751196146,
                -0.03220897540450096,
                0.00035025173565372825,
                -0.09549298882484436,
                0.00824467558413744,
                0.01218326948583126,
                0.03139032423496246,
                -0.023672619834542274,
                0.06634857505559921,
                -0.011199939996004105,
                0.02245209366083145,
                -0.017658717930316925,
                0.024095568805933,
                0.005784910172224045,
                0.04942571744322777,
                -0.01707974448800087,
                -0.004862857982516289,
                -0.005767785012722015,
                -0.05406275391578674,
                -0.021517286077141762,
                -0.04461413994431496,
                -0.06480187922716141,
                0.03059713914990425,
                -0.006190704181790352,
                -0.00031930944533087313,
                -0.012767205014824867,
                0.022831248119473457,
                0.08516994118690491,
                -0.04184316471219063,
                -0.008350562304258347,
                -0.00555624533444643,
                0.030053574591875076,
                -0.003162741893902421,
                0.09915921837091446,
                -0.0005637260037474334,
                0.04597488418221474,
                -0.08870269358158112,
                0.0042203264310956,
                -0.022041358053684235,
                0.014440644532442093,
                0.06242848187685013,
                0.015651727095246315,
                -0.013352600857615471,
                -0.01859448105096817,
                -0.014374981634318829,
                0.012291629798710346,
                0.03943371772766113,
                -0.051456328481435776,
                -0.014998353086411953,
                0.008414641954004765,
                0.017236795276403427,
                0.04832881689071655,
                0.04892854765057564,
                0.037528976798057556,
                0.034101542085409164,
                1.2347863317955118e-34,
                -0.008156649768352509,
                -0.013489686883985996,
                0.03020278736948967,
                0.04980161413550377,
                0.024728400632739067,
                -0.0055993665009737015,
                -0.03969408571720123,
                -0.0021096470300108194,
                -0.02406333014369011,
                -0.013031410053372383,
                0.00857425294816494
            ]
        },
        "Skills": {
            "name": [
                "Microsoft Power BI",
                "Pandas (Software)",
                "SQL"
            ],
            "rep": [
                -0.07438284903764725,
                0.04029863700270653,
                -0.038986556231975555,
                -0.05620068311691284,
                -0.02454148232936859,
                0.030031394213438034,
                0.007698372006416321,
                -0.018918296322226524,
                0.011085529811680317,
                0.0496254526078701,
                0.047289106994867325,
                -0.016394633799791336,
                0.019066236913204193,
                0.12347874045372009,
                0.03700907155871391,
                -0.019221540540456772,
                0.04368475079536438,
                0.06382524967193604,
                0.012164304032921791,
                0.03276154771447182,
                -0.0028340087737888098,
                -0.002924501895904541,
                -0.009334844537079334,
                -0.014489119872450829,
                -0.009743481874465942,
                0.023746773600578308,
                -0.003850323148071766,
                -0.014674639329314232,
                -0.02378527633845806,
                -0.032337576150894165,
                0.024345826357603073,
                0.047239359468221664,
                -0.007373608183115721,
                0.02160230092704296,
                1.269154267902195e-06,
                -0.039990007877349854,
                -0.03694785758852959,
                0.03699321672320366,
                -0.029148109257221222,
                -0.034499019384384155,
                -0.014784871600568295,
                0.02349574863910675,
                0.06600333005189896,
                0.041956640779972076,
                -0.039242684841156006,
                -0.0018132542027160525,
                -0.009877490811049938,
                -0.06230068579316139,
                -0.003432288533076644,
                -0.046746592968702316,
                -0.012437970377504826,
                0.023763444274663925,
                -0.010217527858912945,
                -0.033340584486722946,
                0.029794776812195778,
                0.0009732703329063952,
                0.01407180167734623,
                -0.018214823678135872,
                0.03818429633975029,
                -0.039451465010643005,
                0.037294842302799225,
                0.007895777001976967,
                -0.01875540241599083,
                -0.0030231052078306675,
                0.01105217169970274,
                0.012476139701902866,
                0.07483480125665665,
                -0.008537177927792072,
                -0.004438555333763361,
                -0.016154028475284576,
                0.028822416439652443,
                0.01720595546066761,
                0.0007087709382176399,
                0.024324651807546616,
                -0.017185505479574203,
                -0.0029813838191330433,
                7.602848927490413e-05,
                -0.015546322800219059,
                0.0066533880308270454,
                -0.018958378583192825,
                -0.044579263776540756,
                0.01651901565492153,
                -0.033923059701919556,
                -0.029693586751818657,
                -0.05432986095547676,
                0.01901404559612274,
                -0.025008654221892357,
                -0.0007211436750367284,
                0.03496209532022476,
                -0.004718235228210688,
                0.040536265820264816,
                0.006267623044550419,
                -0.006794555112719536,
                -0.006047939881682396,
                -0.03350139409303665,
                -0.004602885339409113,
                0.04136607050895691,
                0.051649510860443115,
                0.012851983308792114,
                -0.04674427583813667,
                0.04846510663628578,
                -0.008545415475964546,
                0.0032383969519287348,
                0.048402637243270874,
                0.07045767456293106,
                0.026503805071115494,
                -0.008352737873792648,
                -0.03959605470299721,
                0.026547500863671303,
                -0.032763056457042694,
                -0.01858399622142315,
                -0.0065762316808104515,
                -0.003772588912397623,
                0.006681532133370638,
                -0.023649264127016068,
                0.02569578029215336,
                -0.019258463755249977,
                0.0014646409545093775,
                -0.09088043123483658,
                0.0034712308552116156,
                0.019928816705942154,
                0.013238375075161457,
                0.019505299627780914,
                0.0075385719537734985,
                -0.043956954032182693,
                -0.0035096958745270967,
                -0.006431019864976406,
                0.022307375445961952,
                -0.045291878283023834,
                -0.09476328641176224,
                -0.004850196652114391,
                -0.028531735762953758,
                -0.0002941827697213739,
                0.0003716900246217847,
                0.014609993435442448,
                -0.05466524884104729,
                0.04366957023739815,
                0.015198934823274612,
                -0.07789097726345062,
                -0.05569463223218918,
                0.010810984298586845,
                -0.029038134962320328,
                -0.0025003503542393446,
                0.020689042285084724,
                0.06623177230358124,
                0.0037776234094053507,
                -0.03851425647735596,
                -0.03504106029868126,
                -0.00672704866155982,
                0.007616427727043629,
                -0.02377145178616047,
                -0.005753337871283293,
                -0.05526432767510414,
                0.05057254061102867,
                -0.014984283596277237,
                -0.036111678928136826,
                0.03926043584942818,
                0.024676481261849403,
                -0.029017973691225052,
                -0.03835246339440346,
                -0.024503588676452637,
                -2.5730334527906962e-05,
                0.004465090110898018,
                0.033353764563798904,
                0.04979202151298523,
                -0.01564124971628189,
                -0.033350758254528046,
                0.025118716061115265,
                -0.03316042199730873,
                -0.037120234221220016,
                -0.09557025879621506,
                -0.027421897277235985,
                0.030089134350419044,
                0.05092471092939377,
                0.05633920803666115,
                -0.06720473617315292,
                0.059491369873285294,
                0.02437550574541092,
                -0.023467697203159332,
                0.010485456325113773,
                -0.025242779403924942,
                -0.14158318936824799,
                0.057511504739522934,
                0.010860523208975792,
                -0.01818207837641239,
                -0.015022960491478443,
                0.0014645259361714125,
                0.014300115406513214,
                -0.012608679942786694,
                0.01288070809096098,
                0.01489745732396841,
                -0.02069859206676483,
                -0.04908017814159393,
                0.04119467735290527,
                0.041748251765966415,
                0.023710740730166435,
                0.06842979043722153,
                0.05097954347729683,
                0.030474454164505005,
                -0.012364999391138554,
                -0.024149274453520775,
                -0.0004951153532601893,
                0.010418862104415894,
                0.0006190314888954163,
                0.031172921881079674,
                -0.06658186763525009,
                0.06934881210327148,
                -0.040636226534843445,
                0.006406685803085566,
                0.009442987851798534,
                0.02681335061788559,
                -0.015788117423653603,
                0.001496588229201734,
                0.007245906628668308,
                -0.01062763761729002,
                0.04249732941389084,
                -0.0029630661010742188,
                -0.049630455672740936,
                -0.017615288496017456,
                0.0433388277888298,
                -0.05020664259791374,
                -0.0056428732350468636,
                0.004524773918092251,
                -0.007795841433107853,
                0.02280615083873272,
                0.07769787311553955,
                0.02305159904062748,
                0.006865190342068672,
                0.053620677441358566,
                0.03328321501612663,
                -0.02666202373802662,
                0.01018341165035963,
                -0.012609553523361683,
                -0.01552224438637495,
                0.007850546389818192,
                0.06242496520280838,
                0.07311030477285385,
                0.0500401146709919,
                0.02300059050321579,
                0.032816916704177856,
                0.03423435986042023,
                -0.031278230249881744,
                -0.0027121403254568577,
                0.016011005267500877,
                0.00876394659280777,
                -0.04747900739312172,
                -0.0449143685400486,
                -0.019421426579356194,
                0.03219420462846756,
                -0.034694913774728775,
                0.04433540627360344,
                -0.00304274819791317,
                -0.0046642557717859745,
                -0.010249637067317963,
                0.06477847695350647,
                -0.01941877417266369,
                -0.10741791129112244,
                -0.004329187795519829,
                0.019663192331790924,
                0.08863158524036407,
                0.019132357090711594,
                -0.010402055457234383,
                -0.010290917940437794,
                -0.03371306136250496,
                -0.11653989553451538,
                -0.016642948612570763,
                0.05908219888806343,
                0.027202507480978966,
                -0.006447551306337118,
                0.04234452173113823,
                -0.014186867512762547,
                -0.013359342701733112,
                0.025375066325068474,
                -0.01097142230719328,
                0.012745658867061138,
                -0.03733404725790024,
                -0.027324780821800232,
                -0.03825300186872482,
                -0.028591234236955643,
                0.024087773635983467,
                0.007818196900188923,
                0.05719126760959625,
                0.01422868948429823,
                -0.006613674107939005,
                -0.02915966510772705,
                0.010409334674477577,
                0.04649726301431656,
                -0.0149503443390131,
                0.015996940433979034,
                -0.016418496146798134,
                -0.010351988486945629,
                0.03491748496890068,
                -0.01586463674902916,
                0.06463941186666489,
                -0.022952735424041748,
                -0.02213398925960064,
                0.06932932138442993,
                0.010340477339923382,
                -4.449590778676793e-05,
                -0.02607695758342743,
                -0.018692318350076675,
                -0.02669566310942173,
                -0.037678804248571396,
                0.012925725430250168,
                -0.001332456129603088,
                0.07754053920507431,
                0.06856190413236618,
                -0.09416069835424423,
                0.039203889667987823,
                -0.03346521779894829,
                0.02285309135913849,
                0.004210364073514938,
                0.02879238687455654,
                -0.008032586425542831,
                0.004492124542593956,
                -0.03184209391474724,
                0.01993671804666519,
                0.019544309005141258,
                0.0012123315827921033,
                0.011242064647376537,
                0.02286999672651291,
                -0.004666054621338844,
                -0.04256238043308258,
                -0.04703298956155777,
                -0.007287115789949894,
                -0.025210808962583542,
                -0.05140694975852966,
                0.03029855526983738,
                0.02167317271232605,
                0.017170386388897896,
                0.005582485347986221,
                -0.08313930779695511,
                0.011910034343600273,
                0.03674014285206795,
                -0.06410461664199829,
                0.009485087357461452,
                -0.03564276546239853,
                -0.029402829706668854,
                -0.012587704695761204,
                -0.028488589450716972,
                0.004060849547386169,
                0.014954190701246262,
                -0.020889146253466606,
                0.02220696024596691,
                0.026860790327191353,
                0.0064904638566076756,
                0.04804231971502304,
                -0.0006127172964625061,
                -0.03431523218750954,
                0.02861812710762024,
                0.07421399652957916,
                0.025940872728824615,
                0.008454267866909504,
                -0.01952226646244526,
                0.06807971000671387,
                -0.04041006416082382,
                0.033520132303237915,
                0.041775621473789215,
                0.007487159688025713,
                -0.010996059514582157,
                -0.06430567800998688,
                -0.01060023345053196,
                -0.057964690029621124,
                0.00024994846899062395,
                -0.058329056948423386,
                0.012896775268018246,
                -0.02139616385102272,
                -0.034850407391786575,
                0.042268306016922,
                0.004491145256906748,
                -0.03161356970667839,
                0.006453316193073988,
                0.03551064059138298,
                0.009346558712422848,
                0.05244198814034462,
                -0.0546591617166996,
                -0.02684859000146389,
                0.007348039653152227,
                -0.06198425963521004,
                0.014743741601705551,
                -0.00904152076691389,
                -0.027157725766301155,
                0.03416362777352333,
                0.017772113904356956,
                0.03516938164830208,
                -0.04491378739476204,
                -0.0768093392252922,
                -0.02267080917954445,
                -0.061731960624456406,
                0.024352889508008957,
                -0.028417441993951797,
                0.02168458141386509,
                -0.11479510366916656,
                0.005651769228279591,
                0.03898592293262482,
                -0.035958290100097656,
                -0.07482799887657166,
                -0.0075041684322059155,
                0.02372683212161064,
                -0.021350407972931862,
                0.016704225912690163,
                -0.06920146942138672,
                -0.026535216718912125,
                0.004017950501292944,
                -0.01781134307384491,
                -0.03356684371829033,
                0.026700884103775024,
                0.05767082795500755,
                -0.05592089891433716,
                0.01648031920194626,
                -0.02516743168234825,
                0.004623800981789827,
                -0.016870904713869095,
                -0.050062984228134155,
                0.09353110939264297,
                0.02577791176736355,
                -0.0030896058306097984,
                -0.003637889865785837,
                0.0414777509868145,
                0.01739216223359108,
                0.017209606245160103,
                0.02856338769197464,
                0.043650709092617035,
                0.0056950743310153484,
                0.0007675582892261446,
                -0.05608521029353142,
                0.06076661869883537,
                0.03207791969180107,
                0.012479511089622974,
                0.027812791988253593,
                0.000272033445071429,
                0.007660830393433571,
                0.007329618092626333,
                0.032871995121240616,
                -0.029793668538331985,
                0.0134640634059906,
                0.04503205046057701,
                0.020316775888204575,
                -0.03968067094683647,
                -0.020239640027284622,
                -0.03452380374073982,
                0.04971591383218765,
                0.0430837906897068,
                -0.00168816396035254,
                -0.0008199618896469474,
                0.025031432509422302,
                0.003127772593870759,
                -0.0475948192179203,
                -0.030806466937065125,
                0.054605670273303986,
                0.08641017973423004,
                -0.013158218003809452,
                -0.016307804733514786,
                -0.004745589569211006,
                0.06843116879463196,
                -0.05403006449341774,
                0.010527314618229866,
                0.017670320346951485,
                -0.019379062578082085,
                0.032784849405288696,
                0.03461851924657822,
                0.02568603865802288,
                -0.061420243233442307,
                -0.017934652045369148,
                0.016100911423563957,
                -0.012254721485078335,
                0.07489002496004105,
                -0.06280165910720825,
                -0.04346563294529915,
                -0.05490637198090553,
                -0.00536586157977581,
                -0.014869487844407558,
                -0.005534762516617775,
                0.03379437327384949,
                -0.006687113083899021,
                0.027454443275928497,
                -0.015858706086874008,
                0.015685075893998146,
                0.015467504039406776,
                0.00340472930110991,
                0.022353805601596832,
                0.0012475310359150171,
                0.07206985354423523,
                -0.029199006035923958,
                -0.08615433424711227,
                0.014877465553581715,
                -0.03196774423122406,
                0.00999207142740488,
                -0.028447475284337997,
                -0.012773293070495129,
                0.002766515826806426,
                -0.033356502652168274,
                -0.006469772197306156,
                -0.003918598871678114,
                0.01898203045129776,
                -0.12698589265346527,
                -0.03447641059756279,
                0.010643525049090385,
                0.0024235264863818884,
                0.08188940584659576,
                -0.02294016256928444,
                -0.028494052588939667,
                0.015253527089953423,
                -0.05544402077794075,
                -0.010005222633481026,
                0.06269571185112,
                -0.004617787431925535,
                0.021706294268369675,
                -0.011959959752857685,
                -0.0319969616830349,
                -0.012271958403289318,
                0.0184075515717268,
                0.0011156067484989762,
                0.024219423532485962,
                0.024411989375948906,
                -0.030547332018613815,
                -0.027056507766246796,
                0.06104220822453499,
                -0.037374742329120636,
                -0.03957724943757057,
                -0.026818133890628815,
                0.006160289514809847,
                0.026225093752145767,
                -0.027079805731773376,
                0.06276916712522507,
                0.023838156834244728,
                0.014781891368329525,
                -0.07210355252027512,
                -0.001537122530862689,
                0.04310092702507973,
                0.03190067037940025,
                -0.009284386411309242,
                -0.06170421838760376,
                -0.006656022742390633,
                -0.04184667766094208,
                -0.03444836288690567,
                0.010418920777738094,
                -0.016513828188180923,
                -0.001126506133005023,
                0.03800426051020622,
                -0.06513159722089767,
                0.001213960931636393,
                0.04675617069005966,
                -0.03398095816373825,
                0.028207682073116302,
                -0.015881719067692757,
                -0.012629643082618713,
                0.004571942612528801,
                -0.012377263978123665,
                0.0004213092033751309,
                0.011333306320011616,
                0.03399990126490593,
                -0.030496200546622276,
                0.011621464043855667,
                -0.014981473796069622,
                -5.08645221808435e-33,
                -0.0316738523542881,
                -0.015904776751995087,
                -0.023850543424487114,
                -0.023021727800369263,
                -0.009682397358119488,
                -0.00048718880861997604,
                0.01164241787046194,
                -0.05188431218266487,
                -0.03325534239411354,
                -0.01744019240140915,
                -0.031353652477264404,
                0.018407177180051804,
                0.03492772579193115,
                -0.00606162566691637,
                0.020521005615592003,
                0.0397302471101284,
                -0.00020147858595009893,
                0.012149153277277946,
                -0.005189632531255484,
                -0.02279965952038765,
                -0.02148972824215889,
                0.009578154422342777,
                -0.032585907727479935,
                -0.06505987793207169,
                0.023385949432849884,
                0.037738874554634094,
                -0.02807648293673992,
                0.029672468081116676,
                -0.020012248307466507,
                0.00475569162517786,
                0.056347738951444626,
                -0.055849589407444,
                -0.007084980141371489,
                -0.02063228189945221,
                -0.03358147293329239,
                -0.004275632090866566,
                -0.05282671004533768,
                -0.012253317050635815,
                -0.022085679695010185,
                -0.026425188407301903,
                0.007087092846632004,
                0.03033442050218582,
                0.039114974439144135,
                0.005612964276224375,
                -0.0017152728978544474,
                -0.022403473034501076,
                0.030303191393613815,
                0.001253415597602725,
                -0.022120973095297813,
                -0.02508905902504921,
                0.02308170683681965,
                -0.003770926734432578,
                -0.03435551002621651,
                0.10317541658878326,
                0.024878863245248795,
                0.01484673097729683,
                -0.020988116040825844,
                0.030024245381355286,
                -0.027923936024308205,
                0.0288113784044981,
                0.05504734069108963,
                -0.016868513077497482,
                -0.0029326574876904488,
                0.023592310026288033,
                0.05710546299815178,
                -0.024688882753252983,
                0.009803896769881248,
                -0.03280938044190407,
                0.04224593564867973,
                0.04884584620594978,
                -0.021438343450427055,
                0.06017721816897392,
                0.061908312141895294,
                0.017571143805980682,
                -0.10021915286779404,
                -0.03979438543319702,
                -0.07823049277067184,
                0.05361121520400047,
                0.040532901883125305,
                -0.058837540447711945,
                -0.001408085459843278,
                -0.0637269914150238,
                -0.01304203737527132,
                0.02589867264032364,
                0.039666809141635895,
                -0.057634949684143066,
                -0.041955772787332535,
                -0.002639749785885215,
                9.642930672271177e-05,
                0.012465305626392365,
                -0.0006077589350752532,
                0.10026445239782333,
                -0.01676175743341446,
                -0.002736738184466958,
                -0.004060275852680206,
                0.03472936153411865,
                0.02732970006763935,
                0.020980089902877808,
                0.025582842528820038,
                -0.02705167792737484,
                -0.01569698192179203,
                0.021169133484363556,
                -0.00524490000680089,
                0.052812088280916214,
                0.0055945999920368195,
                0.017080597579479218,
                0.005559223238378763,
                -0.012652269564568996,
                -0.04677506163716316,
                0.005935885943472385,
                -0.009362519718706608,
                0.04553179442882538,
                -0.032287705689668655,
                -0.0004198727256152779,
                -0.0013715771492570639,
                0.020795421674847603,
                0.032998401671648026,
                0.023598233237862587,
                0.009407581761479378,
                0.0036787833087146282,
                0.005439912900328636,
                0.028550514951348305,
                -0.010123027488589287,
                -0.009377609007060528,
                -0.0145055977627635,
                -0.04613833874464035,
                0.018450867384672165,
                -0.03147290647029877,
                -0.004133638925850391,
                0.04150823876261711,
                -0.012777965515851974,
                -0.023634256795048714,
                1.9229044312396582e-07,
                0.033020615577697754,
                0.005287348758429289,
                -0.027290955185890198,
                0.09005136042833328,
                0.017643913626670837,
                -0.03391716629266739,
                0.03393477573990822,
                -0.006586129777133465,
                0.036329008638858795,
                0.10116811841726303,
                0.044427596032619476,
                -0.03654225170612335,
                0.03470464050769806,
                0.04894736036658287,
                -0.00830682273954153,
                -0.08320115506649017,
                0.052862390875816345,
                -0.01708637736737728,
                -0.06929833441972733,
                -0.05207531154155731,
                0.0028024022467434406,
                0.0526881068944931,
                0.046611472964286804,
                0.02818216197192669,
                0.058740533888339996,
                -0.10515756905078888,
                -0.03815745562314987,
                -0.05478779599070549,
                0.006914729718118906,
                0.025129741057753563,
                0.02956743724644184,
                0.02704073116183281,
                -0.016928458586335182,
                0.028252337127923965,
                -0.027756910771131516,
                0.0480445995926857,
                -0.035016681998968124,
                0.03963533043861389,
                -0.01067339163273573,
                -0.0048958538100123405,
                0.03876369819045067,
                -0.06191844493150711,
                0.018718626350164413,
                -0.03888847678899765,
                0.047120604664087296,
                -0.009713363833725452,
                0.005015792325139046,
                0.0636640191078186,
                -0.017522325739264488,
                -0.03855360671877861,
                0.04099297150969505,
                0.021271025761961937,
                0.010901957750320435,
                -0.006407976150512695,
                -0.03449712321162224,
                -0.019595030695199966,
                0.015940571203827858,
                0.06420748680830002,
                -0.0006271922611631453,
                0.021170679479837418,
                -0.006770544685423374,
                -0.0019188903970643878,
                0.017212029546499252,
                0.019524578005075455,
                -0.00519189890474081,
                0.06336846202611923,
                0.03871743753552437,
                1.4414990796862746e-34,
                -0.016584277153015137,
                0.029619984328746796,
                0.011356504634022713,
                -0.027507731691002846,
                0.04675184562802315,
                -0.02232593484222889,
                -0.03057774342596531,
                0.009000957943499088,
                6.83784019201994e-05,
                -0.04562555253505707,
                -0.014522400684654713,
                -0.013974770903587341,
                0.03199808672070503,
                -0.007955320179462433,
                -0.023231204599142075,
                0.03378401696681976,
                0.05431630462408066,
                0.10062886774539948,
                0.01940283551812172,
                0.003698088228702545,
                -0.015382133424282074,
                0.03169111907482147,
                0.007968268357217312,
                0.04895693063735962,
                0.08724398910999298,
                -0.03945203498005867,
                -0.041129302233457565,
                0.017021728679537773,
                -0.0050453138537704945,
                -0.007158434484153986,
                0.036385200917720795,
                -0.016710814088582993,
                -0.019451605156064034,
                -0.00868892204016447,
                0.015841228887438774,
                -0.05982773378491402,
                -0.05192946270108223,
                -0.02202189527451992,
                -0.0605868436396122,
                -0.00046619647764600813,
                -0.021140562370419502,
                0.054405633360147476,
                0.013858567923307419,
                0.01872141845524311,
                0.03858751803636551,
                1.4133493095869198e-06,
                -0.08406884223222733,
                0.0034522125497460365,
                -0.026917478069663048,
                0.04412929341197014,
                0.020986955612897873,
                0.06722140312194824,
                -0.001699480228126049,
                0.028864681720733643,
                -0.023307062685489655,
                0.011386146768927574,
                -0.07311389595270157,
                0.015810100361704826,
                -0.0019085771637037396,
                0.08927861601114273,
                0.025257745757699013,
                -0.005626237019896507,
                -0.016043709591031075,
                -0.044898055493831635,
                0.011716742999851704,
                -0.0676652193069458,
                -0.03620552644133568,
                0.04085071012377739,
                0.034967854619026184,
                -0.042391445487737656,
                -0.04685940220952034,
                0.01803489215672016,
                -0.002023138105869293,
                -0.04213324561715126,
                0.02231227420270443,
                0.025796061381697655,
                0.02445332705974579,
                0.009541346691548824,
                -0.06970571726560593,
                -0.024336665868759155,
                0.02278285287320614,
                0.018419301137328148,
                -0.06110677123069763,
                0.013054726645350456,
                6.639262574026361e-05,
                0.01262760441750288,
                0.006932345684617758,
                -0.01970052532851696,
                -0.017969081178307533,
                0.015706034377217293,
                -0.03990421071648598,
                0.04918446019291878,
                0.031505946069955826,
                -0.042878177016973495,
                0.04688551276922226,
                -0.029357587918639183,
                -0.02299971878528595,
                -0.03214890882372856,
                -0.047596853226423264,
                0.01213920395821333,
                -0.02448701485991478,
                0.018592486158013344,
                -0.042976126074790955,
                0.03545022010803223,
                0.039660919457674026,
                -0.0559963621199131,
                -0.0055472771637141705,
                -0.0021550257224589586,
                -0.07137776166200638,
                0.028254859149456024,
                -0.0035345260985195637,
                -0.054821521043777466,
                -0.006341538857668638,
                0.018078109249472618,
                0.006022936198860407,
                0.01011398620903492,
                0.029301149770617485,
                -0.02269471064209938,
                0.01910216733813286,
                -0.005386121571063995,
                0.043871644884347916,
                -0.0010946121765300632,
                0.003824033308774233,
                -0.04145313799381256,
                -0.007977770641446114,
                0.03707372024655342,
                -0.0022764895111322403,
                -0.00804189033806324,
                0.007484424859285355,
                -0.041526779532432556,
                -0.018110373988747597,
                -0.006390913389623165,
                0.05599521845579147,
                0.03997601196169853,
                0.051437053829431534,
                -0.07298260182142258,
                0.05206136405467987,
                -0.04480404779314995,
                0.03255625441670418,
                -0.009725459851324558,
                0.05346233770251274,
                -0.005758862476795912,
                0.04198979213833809,
                0.06254007667303085,
                -0.014171778224408627,
                0.005810434464365244,
                -0.025974353775382042,
                0.06287214159965515,
                0.0015574098797515035,
                -0.015542169101536274,
                0.02683473564684391,
                -0.047315847128629684,
                -0.07686662673950195,
                -0.0717788115143776,
                -0.011291875503957272,
                0.004685120191425085,
                0.029497135430574417,
                0.008641510270535946,
                0.012555976398289204,
                0.014594861306250095,
                -0.01105376798659563,
                0.02710491232573986,
                0.05391925945878029,
                -0.020431427285075188,
                0.009014020673930645,
                0.01746464893221855,
                -0.04092685878276825,
                -0.025039201602339745,
                -0.05599800869822502,
                -0.029450757429003716,
                0.045417848974466324,
                0.01665242575109005,
                -0.043376624584198,
                0.05438488349318504,
                -0.03431633859872818,
                -0.043059010058641434,
                0.026051010936498642,
                -0.03408006578683853,
                0.07398657500743866,
                -0.02199012041091919,
                -0.009655885398387909,
                -0.033019691705703735,
                0.0032329063396900892,
                0.00981499720364809,
                -0.003939403686672449,
                0.035290028899908066,
                -0.0055396538227796555,
                0.04246150329709053,
                -0.0059608761221170425,
                -0.02456071972846985,
                0.008323176763951778,
                0.03611768037080765,
                -0.05379284545779228,
                0.07038949429988861,
                0.03659345209598541,
                -0.03139851987361908,
                0.01718117482960224,
                -0.06987199187278748,
                0.0315028540790081,
                -0.002412395551800728,
                -0.049828946590423584,
                -0.006066224072128534,
                -0.06602534651756287,
                0.03436101973056793,
                0.0003715040802489966,
                0.05604787915945053,
                0.020674338564276695,
                0.020985770970582962,
                -0.02987486869096756,
                0.010135824792087078,
                -0.022990167140960693,
                -0.08659033477306366,
                -0.005756951402872801,
                0.04536157473921776,
                0.0423114150762558,
                0.006953548174351454,
                -0.05931801721453667,
                0.03979005664587021,
                -0.032530058175325394,
                0.0012361228000372648,
                -0.032514046877622604,
                -0.0021480212453752756,
                -0.014999941922724247,
                -0.03011382557451725,
                -0.05653563141822815,
                0.005965829826891422,
                -0.010440494865179062,
                -0.01937573403120041,
                -0.023140765726566315,
                0.02924574725329876,
                0.04334568232297897,
                -0.019895410165190697,
                0.0721154510974884,
                0.004175441339612007,
                0.0054631405510008335,
                -0.00828141625970602,
                -0.02461060881614685,
                -0.021804705262184143,
                0.011960608884692192,
                0.019582685083150864,
                0.04417673498392105,
                -0.007291421294212341,
                -0.021824749186635017,
                0.05270710214972496,
                0.05672450363636017,
                -0.016024509444832802,
                0.00786091759800911,
                0.07206086814403534,
                0.029930071905255318,
                0.007889842614531517,
                0.04877161607146263,
                -0.06657829880714417,
                -0.0011302782222628593,
                0.042511504143476486,
                0.010080048814415932,
                0.0370631217956543,
                0.0103376479819417,
                -0.05898099020123482,
                -0.04959313943982124,
                0.014780998229980469,
                -0.014340621419250965,
                0.0269746333360672,
                -0.02676546759903431,
                0.05100042745471001,
                -0.012788469903171062,
                0.036386098712682724,
                0.014580784365534782,
                -0.052998192608356476,
                0.021626142784953117,
                -0.029446352273225784,
                0.014741149730980396,
                0.02128422074019909,
                -0.0427577830851078,
                0.040792662650346756,
                -0.021449442952871323,
                0.0014624114846810699,
                -0.010362916626036167,
                0.03522401675581932,
                -0.002877849154174328,
                0.01304341945797205,
                0.03359507769346237,
                0.003241701750084758,
                -0.008225486613810062,
                -0.02666943147778511,
                -0.038515135645866394,
                -0.02784138172864914,
                0.014148260466754436,
                -0.031053027138113976,
                -0.07985548675060272,
                -0.03871920704841614,
                0.06127629429101944,
                -0.014856033958494663,
                0.009254018776118755,
                -0.015576906502246857,
                0.04927081987261772,
                0.015461932867765427,
                0.009425326250493526,
                0.006257993634790182,
                -0.10036469995975494,
                0.02805621176958084,
                0.01648705080151558,
                -0.016857990995049477,
                -0.017796888947486877,
                0.008390090428292751,
                -0.00868306402117014,
                0.00661039212718606,
                0.012252549640834332,
                -0.0033216262236237526,
                -0.0010465446393936872,
                0.013049617409706116,
                0.06954725086688995,
                0.0011575096286833286,
                -0.01509011723101139,
                -0.008420579135417938,
                0.039031267166137695,
                -0.009911719709634781,
                0.07646592706441879,
                0.06575682014226913,
                0.01979532279074192,
                0.03633950278162956,
                -0.02261922135949135,
                0.013778705149888992,
                -0.049058180302381516,
                -0.0026986426673829556,
                0.025324342772364616,
                0.004211374558508396,
                -0.027565134689211845,
                -0.05207856371998787,
                0.024045879021286964,
                -0.021237868815660477,
                -0.005522535182535648,
                0.05945451185107231,
                -0.03943534195423126,
                0.006291946861892939,
                -0.007275490555912256,
                0.005259260535240173,
                -0.002575166989117861,
                -0.0496077835559845,
                -0.012187229469418526,
                0.03536374866962433,
                0.013651897199451923,
                -0.01615724340081215,
                -0.016965022310614586,
                -0.027792902663350105,
                0.03229125216603279,
                0.058471545577049255,
                0.018309500068426132,
                -0.0778544694185257,
                -0.016537783667445183,
                0.04879378154873848,
                0.010136271826922894,
                0.005581505596637726,
                0.054521653801202774,
                -0.011106396093964577,
                -0.006971061695367098,
                -0.02455904893577099,
                -0.06306041032075882,
                -0.04208066314458847,
                -0.046537116169929504,
                -0.02119019441306591,
                0.04667389392852783,
                0.06816370785236359,
                0.018707381561398506,
                0.012194698676466942,
                -0.006390237249433994,
                0.01937110908329487,
                -0.0028064572252333164,
                -0.009720072150230408,
                -0.0028500317130237818,
                -0.07922732084989548,
                -0.017745183780789375,
                0.034406889230012894,
                -0.02145436964929104,
                -0.03148052468895912,
                0.05195710435509682,
                -0.009445098228752613,
                0.0052020130679011345,
                -0.05266494303941727,
                0.005866359919309616,
                -0.0016720278654247522,
                -0.0277413222938776,
                -0.060569535940885544,
                0.04105652496218681,
                0.0879138857126236,
                0.0043026236817240715,
                0.02967832237482071,
                0.012800625525414944,
                -0.005147500429302454,
                -0.023016609251499176,
                -0.07757099717855453,
                0.04030920937657356,
                -0.014146655797958374,
                -0.07320685684680939,
                -0.0005471128388307989,
                0.02640175260603428,
                0.019082240760326385,
                -0.04090441018342972,
                -0.03126794844865799,
                0.01788114197552204,
                0.02318381890654564,
                0.05246317386627197,
                -0.024104269221425056,
                0.04924984648823738,
                -0.04427230730652809,
                -0.0806586742401123,
                -0.0390373170375824,
                -0.044523775577545166,
                0.03469789773225784,
                0.0320952869951725,
                0.008360032923519611,
                0.01686372607946396,
                0.02697828598320484,
                0.028881937265396118,
                0.008785119280219078,
                -0.03956735134124756,
                0.0031260885298252106,
                -0.07437985390424728,
                -0.0242486409842968,
                0.0519220195710659,
                -0.026232948526740074,
                -0.031939998269081116,
                -0.011442841961979866,
                0.00020324302022345364,
                -0.006065795663744211,
                -0.02914014458656311,
                0.07325483113527298,
                0.019540568813681602,
                -0.039038319140672684,
                -0.03513545170426369,
                0.0049496968276798725,
                -0.012842250987887383,
                0.003949601203203201,
                0.004216786008328199,
                -0.019536105915904045,
                -0.0022659925743937492,
                -0.03155256807804108,
                -0.0019088896224275231,
                -0.013492319732904434,
                -0.021760720759630203,
                0.03522668406367302,
                0.032878629863262177,
                0.009443758986890316,
                0.02680860087275505,
                -0.01064237765967846,
                -0.006324687972664833,
                -0.013671974651515484,
                0.005894367583096027,
                -6.555268191732466e-05,
                -0.050346169620752335,
                -0.027900507673621178,
                -0.05795934796333313,
                0.042530279606580734,
                0.0473397895693779,
                -0.00324821169488132,
                -0.020613832399249077,
                0.012530759908258915,
                -0.002262243302538991,
                0.0072003076784312725,
                -0.030906619504094124,
                -0.03202994167804718,
                0.08550361543893814,
                0.06798714399337769,
                0.02383727952837944,
                -0.06264667958021164,
                0.01022992841899395,
                0.06881316006183624,
                -0.03531171381473541,
                0.020916571840643883,
                -0.01865360699594021,
                0.022561123594641685,
                0.01081535778939724,
                -0.006705144420266151,
                0.020059708505868912,
                -0.10246137529611588,
                0.0003121582558378577,
                0.062096625566482544,
                -0.047453831881284714,
                0.13009408116340637,
                0.040743157267570496,
                -0.00634375587105751,
                -0.09149890393018723,
                -0.07513920962810516,
                0.021803025156259537,
                -0.04696473479270935,
                0.011475912295281887,
                -0.02944183722138405,
                -0.038259122520685196,
                0.011648446321487427,
                0.048947401344776154,
                -0.005493745673447847,
                0.07330754399299622,
                0.06944466382265091,
                -0.013505902141332626,
                -0.002360992133617401,
                -0.09812553972005844,
                -0.017363285645842552,
                0.052560582756996155,
                -0.021117690950632095,
                -0.03533222898840904,
                0.01700482703745365,
                -0.01492034550756216,
                0.02054804563522339,
                0.025037482380867004,
                0.002073256066069007,
                0.04611266776919365,
                0.05205755680799484,
                -0.049963440746068954,
                -0.004256503190845251,
                0.028532763943076134,
                0.03630191087722778,
                0.04799862578511238,
                -0.015230393037199974,
                -0.03319673612713814,
                -0.006880246568471193,
                -0.040258318185806274,
                -0.02039915882050991,
                0.03380534052848816,
                0.05108199268579483,
                0.007682862691581249,
                -0.026544982567429543,
                -0.006581044755876064,
                0.017728347331285477,
                0.01946403831243515,
                0.023376589640975,
                0.0012359926477074623,
                -0.0037477307487279177,
                -0.0798974558711052,
                0.0036083925515413284,
                -0.04082367941737175,
                -0.09341853111982346,
                -0.0361415259540081,
                -0.02822069823741913,
                -0.025193212553858757,
                0.04046132043004036,
                0.02909841388463974,
                0.05753427743911743,
                -0.09873255342245102,
                0.007552148774266243,
                -0.0632002130150795,
                0.00791911594569683,
                0.01262770313769579,
                0.039112236350774765,
                -0.0027533071115612984,
                -0.012972962111234665,
                -0.052642110735177994,
                0.011617831885814667,
                0.030614491552114487,
                -0.055309467017650604,
                0.010641111992299557,
                -0.023439807817339897,
                -0.002892234828323126,
                -0.008933504112064838,
                -0.019615886732935905,
                -0.06537159532308578,
                0.05088677629828453,
                0.04271567240357399,
                -0.01920524798333645,
                -0.0565466471016407,
                -0.015578537248075008,
                -0.04781775921583176,
                0.05616609752178192,
                -0.02694803476333618,
                0.0636729896068573,
                -0.01636062003672123,
                0.03206569328904152,
                -0.03779924660921097,
                -4.2046643143646265e-33,
                0.02581596001982689,
                -0.06749703735113144,
                -0.006671339273452759,
                0.012998420745134354,
                -1.741446612868458e-05,
                -0.01551539171487093,
                0.004910742864012718,
                0.015529387630522251,
                0.02640881948173046,
                0.011980612762272358,
                0.005346592515707016,
                -0.005386881530284882,
                0.007430105470120907,
                -0.0466417595744133,
                -0.006864737719297409,
                0.013121983967721462,
                -0.03845243155956268,
                -0.007614886853843927,
                0.022423412650823593,
                -0.06295185536146164,
                0.03774462267756462,
                0.008166498504579067,
                0.007884376682341099,
                0.05559703707695007,
                -0.030335836112499237,
                0.004709657747298479,
                -0.059905942529439926,
                -0.007686087861657143,
                -0.03467473387718201,
                -0.019504936411976814,
                0.03000391647219658,
                0.0019297031685709953,
                0.021853206679224968,
                0.028774447739124298,
                -0.01349719800055027,
                0.011999235488474369,
                -0.039083339273929596,
                0.009269517846405506,
                -0.025973787531256676,
                -0.00878342054784298,
                0.059603769332170486,
                0.027649499475955963,
                0.05204019695520401,
                0.01809852384030819,
                -0.014856579713523388,
                0.05876140668988228,
                0.012180536985397339,
                -0.028513245284557343,
                -0.035046931356191635,
                -0.00686762435361743,
                0.008540450595319271,
                -0.012028579600155354,
                0.01620027981698513,
                0.03163064271211624,
                0.019108641892671585,
                0.019205156713724136,
                -0.054293982684612274,
                0.00830828957259655,
                0.05980473384261131,
                0.0400068573653698,
                0.10292451828718185,
                0.03323470056056976,
                0.006877024192363024,
                0.01670444756746292,
                0.037976235151290894,
                -0.023010918870568275,
                0.033619463443756104,
                -0.027953073382377625,
                -0.022269506007432938,
                -0.08152412623167038,
                -0.005235082935541868,
                -0.0013314440147951245,
                -0.011171179823577404,
                -0.0237574502825737,
                -0.026872526854276657,
                -0.05648260936141014,
                -0.04375484213232994,
                0.04986456409096718,
                0.04346726834774017,
                -0.03795715793967247,
                -0.012042395770549774,
                0.01583782769739628,
                0.024944040924310684,
                0.04005163162946701,
                -0.0015659610508009791,
                0.05045153200626373,
                -0.02709696628153324,
                0.007320971228182316,
                0.006880607455968857,
                0.021529344841837883,
                -0.03763344883918762,
                0.027616683393716812,
                -0.04569830000400543,
                -0.026294250041246414,
                0.01787496730685234,
                0.0026867049746215343,
                -0.03448648378252983,
                0.046140410006046295,
                0.00802392978221178,
                -0.051625750958919525,
                0.003831164911389351,
                0.011623271740972996,
                -0.02497822977602482,
                0.02605564333498478,
                0.05318812280893326,
                -0.01910877227783203,
                0.028725218027830124,
                0.002191852778196335,
                -0.05149860680103302,
                -0.000878729042597115,
                0.008944492787122726,
                0.021666279062628746,
                0.01438343059271574,
                0.037726275622844696,
                -0.03687148541212082,
                -0.004020290914922953,
                -0.013408632017672062,
                -0.01485427375882864,
                -0.0025528266560286283,
                -0.016120653599500656,
                -0.03475772961974144,
                0.04513461887836456,
                0.0072231171652674675,
                -0.06760882586240768,
                0.0037383786402642727,
                -0.03951270878314972,
                -0.033987145870923996,
                0.006051792297512293,
                -0.04169638454914093,
                0.0343393050134182,
                -0.003081953153014183,
                -0.013224903494119644,
                2.023495540015574e-07,
                0.000526252668350935,
                0.07705207169055939,
                0.03566012904047966,
                0.0810408815741539,
                0.014397925697267056,
                0.005886247847229242,
                -0.02660137601196766,
                0.007039559073746204,
                0.013205321505665779,
                0.006532902829349041,
                0.07046851515769958,
                -0.032655272632837296,
                -0.008677001111209393,
                0.013357474468648434,
                -0.02188688889145851,
                0.04016358405351639,
                -0.010834791697561741,
                0.013960294425487518,
                -0.027770983055233955,
                -0.03071371279656887,
                -0.03679482638835907,
                0.01662304997444153,
                0.05448390170931816,
                0.03293566405773163,
                0.0720868706703186,
                -0.041562531143426895,
                -0.014981168322265148,
                -0.0060051921755075455,
                0.036721255630254745,
                -0.014912690967321396,
                0.0027962769381701946,
                -0.02644312009215355,
                0.017843768000602722,
                0.03091030940413475,
                -0.04097342491149902,
                0.017062706872820854,
                -0.03020075336098671,
                0.047453224658966064,
                -0.016704726964235306,
                0.036910247057676315,
                0.02375739812850952,
                -0.021324163302779198,
                0.011274856515228748,
                -0.09454672783613205,
                0.013889705762267113,
                0.07083550095558167,
                0.037856169044971466,
                -0.01364536490291357,
                0.016685010865330696,
                0.037030722945928574,
                0.00010111360461451113,
                0.026592789217829704,
                0.02841220423579216,
                0.040758270770311356,
                -0.06411848962306976,
                0.013347432017326355,
                0.0514138862490654,
                0.03959076106548309,
                -0.0236473698168993,
                -0.02028154395520687,
                0.012829504907131195,
                -0.05465248227119446,
                -0.03296497464179993,
                0.025633664801716805,
                0.06524426490068436,
                0.04154684767127037,
                0.012089025229215622,
                1.0376124220963093e-34,
                -0.011513414792716503,
                0.007034712936729193,
                -0.03123009391129017,
                -0.07825815677642822,
                0.029330529272556305,
                -0.034780971705913544,
                -0.021630344912409782,
                -0.010160719975829124,
                0.01627427525818348,
                -0.0035124795977026224,
                0.03136232867836952,
                -0.0053420220501720905,
                0.028125286102294922,
                -0.012849277816712856,
                -0.006173610687255859,
                -0.01855514943599701,
                0.020746838301420212,
                -0.018386032432317734,
                0.007901860401034355,
                0.03711752966046333,
                -0.012818889692425728,
                0.07957416027784348,
                -0.01989876478910446,
                -0.014163455925881863,
                0.056728389114141464,
                0.04826056957244873,
                -0.04963521659374237,
                0.041758205741643906,
                0.0005465106223709881,
                -0.049696121364831924,
                0.004044407047331333,
                -0.02666505053639412,
                0.02456717938184738,
                0.00700667779892683,
                -0.03968951851129532,
                0.013540118932723999,
                -0.010215222835540771,
                -0.011597087606787682,
                -0.0008026803261600435,
                -0.027937177568674088,
                0.005073429085314274,
                0.003755181096494198,
                0.0073798643425107,
                0.006188923493027687,
                -0.001316727022640407,
                1.8255517488796613e-06,
                -0.045250121504068375,
                -0.016163939610123634,
                -0.013009189628064632,
                -0.032121673226356506,
                0.029884107410907745,
                -0.0012212233850732446,
                -0.017043370753526688,
                -0.0022914945147931576,
                0.03422138839960098,
                -0.021877193823456764,
                0.019783413037657738,
                0.04839169606566429,
                -0.08476381748914719,
                0.004469892010092735,
                0.007110723294317722,
                0.0039506880566477776,
                -0.03281288221478462,
                -0.07835542410612106,
                -0.004885661881417036,
                -0.013438028283417225,
                -0.04302488639950752,
                0.009216465055942535,
                0.08273185789585114,
                0.009104208089411259,
                -0.014859176240861416,
                -0.023854484781622887,
                -0.03224584087729454,
                0.0025462994817644358,
                -0.01142499316483736,
                0.015429941937327385,
                -0.00853362213820219,
                0.03845665976405144,
                -0.0008910649921745062,
                0.01083050575107336,
                -0.004191489424556494,
                0.051118966192007065,
                -0.011194872669875622,
                0.027255628257989883,
                0.06723538786172867,
                -0.04381445795297623,
                -0.0340811125934124,
                -0.008325561881065369,
                0.020595578476786613,
                0.042820002883672714,
                -0.03173356503248215,
                -0.03475699573755264,
                0.020226286724209785,
                0.011614270508289337,
                0.015398790128529072,
                -0.0604679174721241,
                -0.05424340069293976,
                -0.013593882322311401,
                -0.006690345238894224,
                7.147411815822124e-05,
                -0.008484668098390102,
                0.04234270751476288,
                -0.01526094600558281,
                0.04758324846625328,
                0.00021721688972320408,
                -0.02141675166785717,
                0.009165080264210701,
                -0.037533678114414215,
                0.06231001019477844,
                0.011397713795304298,
                -0.05468084290623665,
                -0.04183190315961838,
                0.0033238446339964867,
                0.04028021916747093,
                0.021578731015324593,
                0.0314117893576622,
                -0.008928370662033558,
                0.028794871643185616,
                0.0655997097492218,
                -0.0751434937119484,
                0.0008047380833886564,
                -0.02744634822010994,
                0.03593072295188904,
                -0.022513018921017647,
                0.032689306885004044,
                0.006369112990796566,
                0.008638858795166016,
                -0.06755368411540985,
                0.049864642322063446,
                -0.006875553168356419,
                0.01638040505349636,
                0.015306683257222176,
                0.03786427527666092,
                0.011308646760880947,
                0.007853691466152668,
                -0.04135314002633095,
                -0.080685555934906,
                -0.03665303811430931,
                0.02090209722518921,
                0.0031530840788036585,
                -0.028195682913064957,
                -0.023242291063070297,
                -0.004373203497380018,
                0.0406704843044281,
                -0.03126632049679756,
                0.019679022952914238,
                0.01978304609656334,
                -0.014251689426600933,
                0.03653540089726448,
                -0.09804096072912216,
                -0.036746133118867874,
                -0.01682446151971817,
                0.009836587123572826,
                -0.0657792016863823,
                0.015190784819424152,
                0.021016925573349,
                0.011701170355081558,
                0.0007399946334771812,
                0.04119567200541496,
                -0.004035486839711666,
                0.0018774938071146607,
                -0.007775506004691124,
                0.01339979562908411,
                -0.04302416369318962,
                0.011533099226653576,
                0.056789517402648926,
                0.010608398355543613,
                0.07947573810815811,
                -0.024251239374279976,
                -0.029550796374678612,
                0.007536860182881355,
                -0.01781734637916088,
                0.0065836659632623196,
                -0.003906543832272291,
                0.009377281181514263,
                0.0016244128346443176,
                0.025123830884695053,
                -0.10097004473209381,
                0.06414345651865005,
                -0.045242540538311005,
                -0.0023239701986312866,
                -0.07057413458824158,
                0.0368979349732399,
                -0.024905668571591377,
                0.006509376689791679,
                0.04357268288731575,
                -0.03172106668353081,
                0.0476318821310997,
                0.03278786316514015,
                -0.006054242141544819,
                -0.011462233029305935,
                -0.023434562608599663,
                -0.1662672609090805,
                -0.00023445927945431322,
                0.03436404839158058,
                0.016298215836286545,
                0.01706080324947834,
                0.019686194136738777,
                -0.06694740056991577,
                -0.04076531156897545,
                -0.035352613776922226,
                0.01186231430619955,
                -0.0004928404814563692,
                0.007562804501503706,
                0.05366818979382515,
                0.0005975886015221477,
                0.004680044949054718,
                0.0698775053024292,
                -0.009379750117659569,
                -0.02529413253068924,
                0.002513984451070428,
                -0.02194930426776409,
                -0.00728513253852725,
                -0.03657783195376396,
                0.07381775975227356,
                -0.030230844393372536,
                -0.005236870143562555,
                0.03979601711034775,
                -0.0013472039718180895,
                -0.0005097131943330169,
                -0.07656064629554749,
                0.026832183822989464,
                0.005062831100076437,
                -6.040339940227568e-05,
                0.00855264812707901,
                0.03396562486886978,
                0.08971148729324341,
                0.026154229417443275,
                -0.0013106566620990634,
                0.0271446593105793,
                0.04690432921051979,
                -0.0077644395641982555,
                0.03617998585104942,
                -0.011103746481239796,
                -0.03725827857851982,
                -0.022292491048574448,
                -0.016888177022337914,
                -0.017378412187099457,
                0.0018097086576744914,
                -0.006847609765827656,
                0.025435658171772957,
                0.0019395726267248392,
                0.043448660522699356,
                0.044425733387470245,
                0.05055982246994972,
                -0.026249272748827934,
                0.002997586503624916,
                0.10728831589221954,
                0.01886644959449768,
                0.001367121934890747,
                0.013076886534690857,
                -0.015063675120472908,
                -0.07008322328329086,
                0.0213274247944355,
                -0.00736019155010581,
                0.10364416986703873,
                0.008928078226745129,
                -0.009098362177610397,
                -0.014359638094902039,
                0.036391254514455795,
                -0.000319785118335858,
                -0.021401451900601387,
                0.010911747813224792,
                0.017097430303692818,
                0.0045875608921051025,
                0.05967779457569122,
                -0.011769105680286884,
                -0.10143430531024933,
                0.0449015349149704,
                -0.07302126288414001,
                0.03562382236123085,
                0.006508040241897106,
                -0.021804066374897957,
                0.04551628977060318,
                -0.059587445110082626,
                -0.014748130924999714,
                -0.017941603437066078,
                0.0034238880034536123,
                -0.04542243108153343,
                -0.02220320701599121,
                0.013626187108457088,
                0.006740652956068516,
                -0.007459873799234629,
                0.015398596413433552,
                -0.014326414093375206,
                -0.02450646087527275,
                -0.01071117538958788,
                -0.07375426590442657,
                -0.03416072577238083,
                -0.01000391785055399,
                0.017658717930316925,
                0.008866663090884686,
                0.02136119082570076,
                -0.03849056735634804,
                -0.0053889076225459576,
                0.001992557430639863,
                0.024621568620204926,
                -0.03565181791782379,
                -0.06511350721120834,
                0.024415520951151848,
                -0.024837996810674667,
                -0.008932939730584621,
                0.01349728461354971,
                0.008454700000584126,
                -0.012744375504553318,
                0.0009520649327896535,
                0.004018300212919712,
                -0.062341511249542236,
                0.008346231654286385,
                0.01722470298409462,
                -0.014262581244111061,
                0.01310756430029869,
                -0.006128054112195969,
                -0.0073585473001003265,
                -0.01949779875576496,
                0.0326198935508728,
                0.047103576362133026,
                0.06493128091096878,
                -0.03417225182056427,
                0.0540294349193573,
                -0.0202468391507864,
                0.048245444893836975,
                0.01799681782722473,
                0.0034879709128290415,
                0.03744848445057869,
                0.03629397228360176,
                -0.023487329483032227,
                -0.06012806296348572,
                0.021606162190437317,
                -0.0381150096654892,
                0.06801345199346542,
                0.024980301037430763,
                0.060864731669425964,
                -0.025985654443502426,
                0.018130386248230934,
                0.009048907086253166,
                0.004759689327329397,
                -0.05889667198061943,
                0.05639144778251648,
                0.008270769380033016,
                -0.036739129573106766,
                0.01953868195414543,
                -0.08946864306926727,
                -0.04100614786148071,
                0.06487316638231277,
                -0.09046707302331924,
                0.024620549753308296,
                -0.0177835114300251,
                0.02235046774148941,
                0.006155943498015404,
                -0.0021159236785024405,
                -0.029432376846671104,
                0.00646976288408041,
                -0.029574425891041756,
                -0.02470296248793602,
                -0.05663508176803589,
                -0.027082279324531555,
                0.053902070969343185,
                0.017069756984710693,
                0.03643947094678879,
                -0.024920940399169922,
                -0.01119141187518835,
                0.06187735125422478,
                -0.007869775407016277,
                -0.005075219087302685,
                0.01963183842599392,
                -0.07452483475208282,
                -0.012441110797226429,
                0.05155568569898605,
                -0.00031982053769752383,
                -0.0125289810821414,
                -0.0057894024066627026,
                -0.004324241541326046,
                -0.013762356713414192,
                -0.013998834416270256,
                -0.03416064754128456,
                0.008736160583794117,
                0.020011555403470993,
                0.03799897059798241,
                -0.020579706877470016,
                0.014025615528225899,
                -0.08707353472709656,
                0.017467141151428223,
                0.04609401896595955,
                0.007386897224932909,
                -0.010812138207256794,
                0.06331128627061844,
                0.013591940514743328,
                0.03477516025304794,
                -0.07062964886426926,
                0.03380999341607094,
                0.007036334369331598,
                -0.10249175876379013,
                -0.0001559102674946189,
                -0.03514907509088516,
                0.0004370728274807334,
                -0.004371036309748888,
                -0.02636345475912094,
                -0.030576521530747414,
                -0.03477652370929718,
                0.002028613118454814,
                -0.012023307383060455,
                -0.02152877300977707,
                0.006951319519430399,
                0.026937400922179222,
                -0.024505410343408585,
                -0.059891775250434875,
                0.021783476695418358,
                0.08791042864322662,
                0.02041061781346798,
                -0.03608682006597519,
                0.03704863786697388,
                0.03032664768397808,
                0.03796859830617905,
                -0.017887376248836517,
                -0.00863706972450018,
                -0.07423720508813858,
                -0.03198103606700897,
                0.0496070459485054,
                -0.04617661237716675,
                -0.052156925201416016,
                0.029021266847848892,
                -0.036591608077287674,
                -0.0039233192801475525,
                -0.030966129153966904,
                0.06563720107078552,
                0.002475867047905922,
                0.003355710068717599,
                0.014143580570816994,
                -0.027145903557538986,
                -0.01828242838382721,
                0.07074987143278122,
                0.004366513341665268,
                0.04865424707531929,
                -0.007743195164948702,
                0.005711537320166826,
                0.008812788873910904,
                0.008446349762380123,
                0.025072826072573662,
                0.02091307006776333,
                -0.01791439577937126,
                -5.835696720168926e-05,
                -0.04401354119181633,
                -0.0039049736224114895,
                -0.0063950661569833755,
                -0.04728248715400696,
                0.013116906397044659,
                0.027983548119664192,
                -0.0367036871612072,
                -0.0866943746805191,
                0.016156373545527458,
                -0.0010128803551197052,
                0.03825047239661217,
                -0.013794240541756153,
                0.004867624957114458,
                0.011621727608144283,
                0.06165746971964836,
                -0.0481293611228466,
                -0.05160517990589142,
                -0.08847317844629288,
                0.02895386330783367,
                0.015269267372786999,
                -0.012018692679703236,
                0.013794278725981712,
                -0.011281336657702923,
                0.036963533610105515,
                -0.012305276468396187,
                -0.11809446662664413,
                -0.041694313287734985,
                -0.007689966820180416,
                -0.010703637264668941,
                0.016857899725437164,
                0.020805099979043007,
                -0.07184335589408875,
                0.01637737825512886,
                -0.02457047998905182,
                -0.0723109096288681,
                -0.012734576128423214,
                -0.024574020877480507,
                -0.012293730862438679,
                -0.05186256021261215,
                -0.026320509612560272,
                -0.01979862153530121,
                -0.021566117182374,
                0.02452458254992962,
                -0.01629909873008728,
                0.005402448121458292,
                -0.09044450521469116,
                -0.08123116195201874,
                0.04293353110551834,
                0.030660271644592285,
                -0.0007407426019199193,
                0.010058614425361156,
                0.05480637028813362,
                -0.054555606096982956,
                -0.000547423551324755,
                0.01932656019926071,
                -0.06354543566703796,
                -0.02238713763654232,
                0.017795542255043983,
                -0.014148651622235775,
                0.007509728893637657,
                0.009838581085205078,
                -0.022131837904453278,
                -0.05374297499656677,
                0.01917012594640255,
                -0.025526799261569977,
                0.011009852401912212,
                -0.005818546749651432,
                0.04441457241773605,
                -0.023897886276245117,
                -0.04094604775309563,
                0.027887612581253052,
                -0.020463794469833374,
                -0.0020382367074489594,
                -0.00798135157674551,
                0.08968525379896164,
                -0.0029763029888272285,
                -0.016347112134099007,
                -0.021213211119174957,
                0.026931587606668472,
                0.03831634297966957,
                0.01836514286696911,
                0.01621132157742977,
                -0.026140019297599792,
                0.08000399172306061,
                -0.002508101984858513,
                -0.014696222729980946,
                -0.04181024804711342,
                -0.04464178532361984,
                0.007898692041635513,
                0.004216819070279598,
                -0.021717004477977753,
                0.008035835810005665,
                0.047954268753528595,
                0.0550859160721302,
                -0.020770514383912086,
                0.03209104388952255,
                -0.03406188264489174,
                -0.002238177228718996,
                0.02455553412437439,
                -0.008287372067570686,
                0.010336926206946373,
                -0.025275852531194687,
                -0.007919380441308022,
                -0.013956109993159771,
                0.06871755421161652,
                -0.0017575122183188796,
                -0.01311936229467392,
                -0.0011262049665674567,
                -0.009036567993462086,
                0.019707856699824333,
                0.03734254091978073,
                0.008903436362743378,
                0.06868128478527069,
                0.0057417359203100204,
                -0.027402298524975777,
                0.020377187058329582,
                0.0614314004778862,
                0.01699393428862095,
                0.05173397809267044,
                -0.03459388390183449,
                -0.020455017685890198,
                -0.06821788102388382,
                -0.03759937733411789,
                0.029077380895614624,
                -7.472541181044478e-33,
                -0.02556130476295948,
                -0.02636799030005932,
                0.03476351127028465,
                0.01205107569694519,
                0.0035283281467854977,
                -0.04101454094052315,
                -0.030517494305968285,
                0.03022840805351734,
                -0.07043799757957458,
                -0.002147109480574727,
                -0.014865864999592304,
                0.03134715184569359,
                0.037140484899282455,
                -0.028177130967378616,
                0.05375078320503235,
                -0.010871948674321175,
                -0.008482113480567932,
                -0.008055396378040314,
                0.044402409344911575,
                -0.07076346129179001,
                0.04727773368358612,
                0.006068275775760412,
                0.02221517451107502,
                0.08305000513792038,
                0.015599037520587444,
                0.022359786555171013,
                -0.03514758124947548,
                0.002927066059783101,
                0.03147244080901146,
                -0.018418310210108757,
                0.018680356442928314,
                -0.030680391937494278,
                0.0036500405985862017,
                0.03610091656446457,
                0.02758101373910904,
                -0.03230771794915199,
                -0.0003020215081050992,
                0.00018105765047948807,
                -0.030191408470273018,
                0.0063392044976353645,
                -0.0050234487280249596,
                -0.0086752874776721,
                0.08128707855939865,
                -0.031694404780864716,
                -0.06919881701469421,
                -0.020100442692637444,
                0.002078985795378685,
                -0.024970905855298042,
                0.00640234537422657,
                -0.06964835524559021,
                0.046875596046447754,
                0.010684959590435028,
                -0.0023234363179653883,
                0.06238822638988495,
                0.034485090523958206,
                0.002945921616628766,
                -0.028851067647337914,
                -0.007351606152951717,
                -0.009252344258129597,
                0.019616585224866867,
                0.013401221483945847,
                0.09112195670604706,
                -0.019294990226626396,
                0.02645432949066162,
                0.006057340651750565,
                -0.03572210296988487,
                -0.023793241009116173,
                -0.016021981835365295,
                -0.0041596353985369205,
                0.0967114120721817,
                -0.041916586458683014,
                0.09080276638269424,
                -0.036245185881853104,
                0.008834544569253922,
                -0.017865313217043877,
                -0.07734567672014236,
                -0.052420634776353836,
                0.019196877256035805,
                -0.0027936415281146765,
                -0.07472225278615952,
                -0.054207075387239456,
                0.03560445085167885,
                -0.013394871726632118,
                -0.004124454688280821,
                0.007540114689618349,
                0.04931694269180298,
                -0.02302720583975315,
                0.026445411145687103,
                -0.01567177288234234,
                0.023215247318148613,
                0.007362307980656624,
                0.020599501207470894,
                -0.03074900433421135,
                -0.011177275329828262,
                -0.04062104970216751,
                -0.030230233445763588,
                -0.013376541435718536,
                -0.054799456149339676,
                -0.006807806435972452,
                -0.022271404042840004,
                0.03846577927470207,
                0.02321610040962696,
                -0.0039104875177145,
                0.0019823145121335983,
                0.001269495114684105,
                0.03182607889175415,
                -0.005868949461728334,
                -0.014834449626505375,
                -0.056358702480793,
                0.01297137513756752,
                0.030498137697577477,
                0.02996586449444294,
                0.03199531137943268,
                0.004430112894624472,
                -0.020940739661455154,
                0.018420737236738205,
                0.020823942497372627,
                -0.003832718124613166,
                0.00916349608451128,
                -0.02083945833146572,
                -0.020768845453858376,
                0.014807423576712608,
                -0.013265402056276798,
                -0.009461838752031326,
                -0.018250668421387672,
                -0.012767847627401352,
                0.04348216950893402,
                -0.05230986699461937,
                -0.030946815386414528,
                -0.018820127472281456,
                -0.03620455041527748,
                0.0651359036564827,
                2.4575382440161775e-07,
                0.026435386389493942,
                0.018856458365917206,
                -0.00819308590143919,
                0.05445756018161774,
                -0.004354037810117006,
                0.008617674000561237,
                0.05524424836039543,
                0.02206272818148136,
                0.028083648532629013,
                0.025102214887738228,
                0.035712070763111115,
                -0.023481104522943497,
                0.03455929085612297,
                -0.030604111030697823,
                0.006396214943379164,
                -0.018882378935813904,
                -0.024954460561275482,
                0.008765004575252533,
                -0.027691572904586792,
                -0.02742118574678898,
                0.029323074966669083,
                0.04559098556637764,
                0.023960568010807037,
                0.019511176273226738,
                0.022060517221689224,
                -0.061568476259708405,
                -0.0021889894269406796,
                -0.01407494954764843,
                -0.008783334866166115,
                0.04894174262881279,
                0.07626546174287796,
                -0.04426463320851326,
                -0.01455496996641159,
                0.047475624829530716,
                -0.034261494874954224,
                -0.015248673968017101,
                -0.010158788412809372,
                0.04515830799937248,
                0.0028609870932996273,
                0.045836418867111206,
                0.009439464658498764,
                -0.10526353865861893,
                0.0037021285388618708,
                0.021548405289649963,
                0.048736050724983215,
                0.07708051800727844,
                0.012325592339038849,
                0.08901631832122803,
                0.011494291946291924,
                -0.07746272534132004,
                0.033689454197883606,
                0.012251914478838444,
                0.026038460433483124,
                -0.014089097268879414,
                0.004094499628990889,
                0.024522094056010246,
                0.03555403649806976,
                0.04174300655722618,
                0.0005251516122370958,
                0.1021745502948761,
                -0.01413592230528593,
                -0.01100191380828619,
                0.05059381574392319,
                0.028204765170812607,
                0.027280641719698906,
                0.05150318145751953,
                -0.020858904346823692,
                7.143986561063314e-35,
                0.009104020893573761,
                0.024348635226488113,
                0.0027585343923419714,
                -0.030604222789406776,
                0.023297762498259544,
                -0.00994917843490839,
                -0.02792181633412838,
                -0.05039627477526665,
                -0.02461598999798298,
                0.030661627650260925,
                -0.027690712362527847
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "On location",
        "hash__": "b'\\x86_\\xb1\\x13\\xc5\\xfdJ\\r\\xc8\\xee\\r\\xdeM\\xc3\\xf2-'",
        "id__": "5a2fb0c1-0044-4dc2-b5f0-5322dd3337e8"
    },
    {
        "Certificates": {
            "name": [
                "Professional Scrum Master",
                "Agile Project Manager Practitioner",
                "CMI L3 Coaching & Mentoring",
                "Chartered Engineer IET"
            ],
            "rep": [
                0.026484783738851547,
                -0.016784418374300003,
                -0.01500644814223051,
                -0.03839448094367981,
                0.02942488342523575,
                -0.01754452846944332,
                0.022715549916028976,
                -0.03591810166835785,
                -0.09688324481248856,
                0.02739616483449936,
                0.048562753945589066,
                0.01701314002275467,
                -0.039935994893312454,
                0.10383769869804382,
                0.051479633897542953,
                0.012990901246666908,
                0.041850343346595764,
                -0.03784756362438202,
                -0.021776817739009857,
                0.010758601129055023,
                0.005213005002588034,
                0.005274203605949879,
                -0.012018388137221336,
                0.040381111204624176,
                -0.01488391775637865,
                -0.04021485149860382,
                0.0389908067882061,
                0.037710338830947876,
                -0.016442423686385155,
                0.028853435069322586,
                -0.0026538739912211895,
                -0.0034422187600284815,
                0.002495993161574006,
                -0.008467819541692734,
                1.5151039178817882e-06,
                0.029139220714569092,
                -0.00990747194737196,
                -0.05153624340891838,
                -0.0057395873591303825,
                0.014485711231827736,
                -0.02980724535882473,
                0.009736960753798485,
                -0.020241985097527504,
                0.042949970811605453,
                -0.02153404988348484,
                0.08244063705205917,
                -0.019449274986982346,
                0.05162103474140167,
                0.00289425952360034,
                0.03852853924036026,
                0.0006093926494941115,
                0.05820586904883385,
                -0.05914491042494774,
                -0.03011094219982624,
                -0.08714428544044495,
                -0.02317580208182335,
                -0.03019384667277336,
                0.020637454465031624,
                -0.022388268262147903,
                0.04774681478738785,
                -0.004586419090628624,
                -0.01671431213617325,
                0.02776551991701126,
                -0.026555025950074196,
                0.04184088855981827,
                0.0453212708234787,
                0.03749042749404907,
                -0.013356981799006462,
                0.004208582453429699,
                -0.07598300278186798,
                0.0158090740442276,
                0.01725086197257042,
                -0.023590117692947388,
                0.015375609509646893,
                0.017604857683181763,
                0.012936808168888092,
                0.030360223725438118,
                0.0007015470182523131,
                -0.0187071543186903,
                0.01974688470363617,
                -0.06626962125301361,
                0.021296579390764236,
                -0.030938200652599335,
                -0.027260877192020416,
                0.006406352389603853,
                -0.016546839848160744,
                -0.01888405904173851,
                0.025595081970095634,
                0.00406596390530467,
                0.01758669503033161,
                -0.0046077207662165165,
                -0.01135166548192501,
                -0.006475020200014114,
                0.027618082240223885,
                -0.01866648532450199,
                -0.03461993858218193,
                0.01018316950649023,
                -0.051293157041072845,
                -0.0080550042912364,
                0.028727535158395767,
                -0.04371007904410362,
                -0.036489855498075485,
                0.0596562959253788,
                -0.018718546256422997,
                -0.005811106413602829,
                0.015750320628285408,
                0.054247789084911346,
                0.025578277185559273,
                -0.020909661427140236,
                0.050494205206632614,
                -0.026141026988625526,
                0.04154279828071594,
                -0.004458305425941944,
                -0.014591366983950138,
                -0.017432890832424164,
                -0.04848303273320198,
                -0.027999792248010635,
                -0.0013343010796234012,
                0.058828193694353104,
                0.017428111284971237,
                -0.020067332312464714,
                0.02250974252820015,
                -0.06435070931911469,
                0.043014753609895706,
                -0.01723829284310341,
                0.015182876959443092,
                -0.03978953883051872,
                0.06111039221286774,
                -0.008335484191775322,
                0.041282061487436295,
                0.030692368745803833,
                -0.000754278851673007,
                -0.03976372256875038,
                0.007535736076533794,
                0.01115263719111681,
                -0.038515929132699966,
                -0.03203146532177925,
                0.038913946598768234,
                -0.023650705814361572,
                -0.03177714720368385,
                0.011915793642401695,
                0.011094853281974792,
                -0.0883781909942627,
                0.014802037738263607,
                -0.033988162875175476,
                -0.03906004875898361,
                -0.035465508699417114,
                0.006141205783933401,
                0.008344870060682297,
                -0.00898743886500597,
                0.020670102909207344,
                -0.020728766918182373,
                -0.031013311818242073,
                -0.0037001671735197306,
                0.038277704268693924,
                0.03183918818831444,
                -0.026605769991874695,
                -0.03751329332590103,
                0.014485572464764118,
                0.04477223381400108,
                0.0017650504596531391,
                -0.015698276460170746,
                0.025368379428982735,
                0.04921698197722435,
                -0.09573360532522202,
                -0.012236818671226501,
                0.0497044213116169,
                -0.014774549752473831,
                0.027178088203072548,
                -0.0534307137131691,
                0.04308129474520683,
                -0.01981275901198387,
                -0.008266608230769634,
                0.06215517595410347,
                0.03171294182538986,
                -0.06625021249055862,
                0.015133345499634743,
                0.005299845244735479,
                -0.014145312830805779,
                -0.002494066022336483,
                -0.01926574483513832,
                -0.11995118856430054,
                0.01815851218998432,
                0.0021922283340245485,
                0.03395138680934906,
                0.0031142060179263353,
                -0.016147006303071976,
                0.05282869189977646,
                -0.02501014433801174,
                0.011459629982709885,
                0.05355367809534073,
                0.015560557134449482,
                -0.11732536554336548,
                0.056620921939611435,
                0.012199846096336842,
                -0.0015914455289021134,
                -0.056475330144166946,
                -0.0006375848897732794,
                0.016613846644759178,
                -0.037175703793764114,
                -0.030207283794879913,
                0.04785870015621185,
                -0.030299073085188866,
                -0.004893985576927662,
                -0.031095663085579872,
                -0.05321759730577469,
                -0.08475204557180405,
                -0.0446469821035862,
                -0.019479498267173767,
                0.03463229164481163,
                0.007225613109767437,
                -0.012392604723572731,
                -0.005985551048070192,
                0.030904414132237434,
                -0.012885401025414467,
                -0.012589470483362675,
                0.0030631006229668856,
                0.003791854251176119,
                0.013662020675837994,
                0.030135368928313255,
                -0.020104756578803062,
                0.06297524273395538,
                -0.061822883784770966,
                0.043188877403736115,
                -0.03308819234371185,
                -0.05550568923354149,
                0.03762316331267357,
                -0.03376784920692444,
                0.040069784969091415,
                0.008193815127015114,
                -0.01441572979092598,
                0.008447952568531036,
                0.041178148239851,
                -0.01867096498608589,
                0.0052058338187634945,
                0.0021790454629808664,
                0.07992460578680038,
                -0.02611282281577587,
                0.00333912530913949,
                -0.028798872604966164,
                -0.021595926955342293,
                0.0075178127735853195,
                -0.018946386873722076,
                -0.013056984171271324,
                0.003782151034101844,
                0.023431776091456413,
                -0.008852414786815643,
                0.005881570745259523,
                -0.008293782360851765,
                0.0034873434342443943,
                -0.03746188059449196,
                0.0005940872943028808,
                -0.009183458983898163,
                0.04974104091525078,
                -0.006821628659963608,
                -0.05021468549966812,
                -0.04273513704538345,
                -0.0061546326614916325,
                -0.04741164296865463,
                -0.0011681497562676668,
                -0.0018573090201243758,
                -0.05680260434746742,
                -0.006827422417700291,
                -0.0007789942901581526,
                0.07330146431922913,
                -0.013136130757629871,
                -0.022989774122834206,
                -0.032892946153879166,
                -0.004457694478332996,
                -0.023936985060572624,
                0.02576926350593567,
                -0.00011320467456243932,
                -0.01845044083893299,
                -0.036317601799964905,
                -0.027254743501544,
                -0.012795957736670971,
                -0.027212990447878838,
                0.024994969367980957,
                -0.021734312176704407,
                -0.019726602360606194,
                0.018483079969882965,
                -0.008315100334584713,
                -0.024546973407268524,
                0.02767481654882431,
                0.007441323716193438,
                0.0003031774249393493,
                0.0252202358096838,
                0.04471839964389801,
                -0.01890753023326397,
                0.03449012339115143,
                -0.06135151535272598,
                -0.004123730584979057,
                -0.008825869299471378,
                -0.026179784908890724,
                0.019166285172104836,
                0.008294336497783661,
                -0.05728751793503761,
                -0.06341561675071716,
                0.010648082941770554,
                0.02018149383366108,
                0.03773530572652817,
                -0.003634024178609252,
                0.05203026905655861,
                0.02075348235666752,
                -0.01879757083952427,
                -0.07303750514984131,
                -0.058888331055641174,
                0.042240697890520096,
                0.02549619786441326,
                0.006708050146698952,
                0.02604701928794384,
                0.03899472579360008,
                0.0383758470416069,
                -0.04935796186327934,
                0.02853521890938282,
                -0.030892061069607735,
                0.10932839661836624,
                -0.026436252519488335,
                0.04226973280310631,
                -0.015293971635401249,
                -0.042807333171367645,
                0.04679369181394577,
                -0.02373501844704151,
                -0.03516126424074173,
                0.011251140385866165,
                -0.011902617290616035,
                -0.08757425844669342,
                -0.008991265669465065,
                -0.024023141711950302,
                -0.08144263923168182,
                -0.011974848806858063,
                0.025233233347535133,
                -0.017077745869755745,
                0.003523796796798706,
                -0.02053978480398655,
                -0.02929789200425148,
                -0.03642825782299042,
                -0.07563657313585281,
                -0.04037560150027275,
                0.030811382457613945,
                0.03646213933825493,
                0.007681210059672594,
                -0.012525102123618126,
                0.03479019179940224,
                -0.05368690565228462,
                -0.033295582979917526,
                0.03983907774090767,
                0.0560268834233284,
                -0.031444914638996124,
                0.026578806340694427,
                0.023819521069526672,
                0.054657697677612305,
                0.0033268004190176725,
                -0.023228932172060013,
                -0.0004133006150368601,
                -0.028072761371731758,
                -0.008389987982809544,
                0.005345226265490055,
                0.018138037994503975,
                -0.0958171859383583,
                -0.03087085485458374,
                -0.006465561222285032,
                0.11431281268596649,
                -0.027099289000034332,
                0.014602352865040302,
                -0.011739296838641167,
                0.006070487666875124,
                -0.012115144170820713,
                0.005287403240799904,
                0.03142886236310005,
                0.019818207249045372,
                0.0009564727661199868,
                -0.023486360907554626,
                0.055892620235681534,
                0.007668802514672279,
                -0.029363134875893593,
                0.014331159181892872,
                0.017153272405266762,
                -0.012211943045258522,
                0.02656707540154457,
                0.04411214962601662,
                0.04282286763191223,
                -0.003279698546975851,
                -0.03465357795357704,
                0.020569123327732086,
                0.00729141291230917,
                0.013857833109796047,
                -0.02937622368335724,
                0.013421007432043552,
                -0.0018042256124317646,
                0.0032847493421286345,
                -0.1089184433221817,
                -0.011041509918868542,
                0.0060189696960151196,
                -0.003591313026845455,
                -0.045466408133506775,
                0.010596176609396935,
                0.033717598766088486,
                -0.029179289937019348,
                0.034514524042606354,
                0.014665789902210236,
                0.01299327053129673,
                -0.018966324627399445,
                0.044222794473171234,
                -0.05308239534497261,
                -0.0011822172673419118,
                0.07056988030672073,
                -0.05952422320842743,
                0.02108576148748398,
                0.041835591197013855,
                -0.036718934774398804,
                0.027719557285308838,
                0.04819469898939133,
                0.026348259299993515,
                0.011933736503124237,
                -0.08274418860673904,
                -0.02716180682182312,
                0.02868664264678955,
                0.006734066642820835,
                -0.009023278951644897,
                0.061589159071445465,
                0.0256960466504097,
                -0.008659283630549908,
                0.0071221305988729,
                0.01774503104388714,
                0.030877338722348213,
                0.011766936630010605,
                0.025731302797794342,
                -0.037510983645915985,
                0.013725039549171925,
                -0.023530138656497,
                -0.0198692437261343,
                0.028013385832309723,
                0.04572327435016632,
                0.06375016272068024,
                -0.007452734280377626,
                0.026226835325360298,
                0.05994901806116104,
                0.01294943317770958,
                -0.011493507772684097,
                -0.02302166447043419,
                0.15189386904239655,
                0.04405561834573746,
                -0.030244270339608192,
                0.006345192436128855,
                -0.08329340070486069,
                0.041416384279727936,
                -0.016486363485455513,
                -0.007988512516021729,
                -0.006836694665253162,
                0.021565258502960205,
                -0.021480442956089973,
                -0.01293922308832407,
                -0.011889804154634476,
                0.027645176276564598,
                0.0005673698033206165,
                -0.09568982571363449,
                -0.030594477429986,
                -0.0007331377128139138,
                -0.06193121522665024,
                0.04547969996929169,
                0.03599674254655838,
                -0.020347721874713898,
                0.03907075524330139,
                -0.03621694818139076,
                -0.037364497780799866,
                -0.03596281260251999,
                -0.14858737587928772,
                0.06047080084681511,
                -0.05899835750460625,
                -0.007074966561049223,
                -0.001642499933950603,
                -0.02381162717938423,
                -0.022928668186068535,
                0.028906365856528282,
                -0.01197766326367855,
                0.003035078989341855,
                -0.03489331156015396,
                0.03158746287226677,
                0.0211490485817194,
                -0.04940459132194519,
                -0.003892291570082307,
                0.020249370485544205,
                -0.03441552817821503,
                0.03323142230510712,
                0.01098509319126606,
                0.013636525720357895,
                -0.01896701753139496,
                -0.030757535248994827,
                -0.003512137569487095,
                0.05118032544851303,
                -0.03921496495604515,
                0.044720765203237534,
                0.01024089939892292,
                -0.04554765298962593,
                0.07703687995672226,
                0.001146055175922811,
                -0.018488498404622078,
                0.03238220512866974,
                -0.09774208813905716,
                -0.0012482265010476112,
                0.013998028822243214,
                -0.004462067503482103,
                -0.03315797448158264,
                0.01744157075881958,
                0.0003004632599186152,
                0.04491729289293289,
                -0.030850280076265335,
                -0.012145602144300938,
                0.04445758834481239,
                0.012360864318907261,
                -0.03285546973347664,
                -0.010928863659501076,
                -0.05717257782816887,
                0.018114015460014343,
                0.00014943830319680274,
                0.043624088168144226,
                0.019178424030542374,
                0.04274982959032059,
                0.014491806738078594,
                0.03613530844449997,
                -0.030345965176820755,
                -0.011537641286849976,
                -0.057467442005872726,
                -0.0008613195386715233,
                -0.010975007899105549,
                0.025474758818745613,
                -0.00870521180331707,
                0.014001402072608471,
                0.053636129945516586,
                0.0294274240732193,
                -0.017211265861988068,
                -0.0575200617313385,
                0.04516196250915527,
                -0.025983009487390518,
                0.04431065171957016,
                -0.004469435196369886,
                -0.008946066722273827,
                0.028048507869243622,
                0.03502407670021057,
                -0.03832836449146271,
                0.037914521992206573,
                0.06123865395784378,
                -0.024657974019646645,
                -0.0018642558716237545,
                -0.01355503685772419,
                -0.042720627039670944,
                0.01953972317278385,
                -0.01602882333099842,
                0.06283023953437805,
                0.007320791482925415,
                -0.08773072808980942,
                0.02686423622071743,
                0.011208907701075077,
                -0.002866659313440323,
                -5.335732325565524e-33,
                -0.008585470728576183,
                -0.05566294491291046,
                0.05077522248029709,
                -0.04070981219410896,
                -0.0072459811344742775,
                -0.00285252183675766,
                0.01305200532078743,
                -0.015156479552388191,
                0.014621960930526257,
                -0.024927249178290367,
                -0.014604433439671993,
                0.0018593420973047614,
                0.02999773994088173,
                -0.01760251447558403,
                0.06253500282764435,
                0.05136704444885254,
                0.06352958083152771,
                -0.00873679481446743,
                0.025178110226988792,
                0.048657484352588654,
                -0.01153646595776081,
                0.046443402767181396,
                0.02061999961733818,
                0.08211763203144073,
                -0.04315709322690964,
                0.0001768989604897797,
                -0.006314817350357771,
                -0.01092476025223732,
                0.038725148886442184,
                -0.026006996631622314,
                0.027593830600380898,
                -0.06779572367668152,
                -0.003931633196771145,
                0.024504760280251503,
                0.016958367079496384,
                -0.015251552686095238,
                0.03474145755171776,
                0.01273207738995552,
                -0.052146196365356445,
                -0.019611123949289322,
                -0.012259865179657936,
                0.04668648540973663,
                0.039204295724630356,
                -0.04650600254535675,
                0.024422120302915573,
                -0.00639328733086586,
                0.05106189101934433,
                0.03453515097498894,
                -0.001686555682681501,
                -0.09913544356822968,
                -0.0020351747516542673,
                0.00016607240831945091,
                -0.02443237230181694,
                0.01419865619391203,
                -0.07386214286088943,
                0.058093227446079254,
                -0.03551644831895828,
                -0.07090506702661514,
                0.013417515903711319,
                0.0016108201816678047,
                0.026629211381077766,
                0.01802193373441696,
                -0.003284448990598321,
                0.05296824499964714,
                -0.0692388191819191,
                0.0014920644462108612,
                -0.015987709164619446,
                0.06591492891311646,
                -0.013536090962588787,
                0.02837827056646347,
                -0.010369179770350456,
                0.07178467512130737,
                0.023875942453742027,
                -0.027316881343722343,
                -0.021150052547454834,
                9.95796945062466e-05,
                0.03792010247707367,
                0.009609580971300602,
                0.06627671420574188,
                0.07814962416887283,
                -0.04007281735539436,
                0.023429663851857185,
                -0.014349894598126411,
                0.006661961320787668,
                -0.017063215374946594,
                -0.04688641056418419,
                -0.004641797859221697,
                0.020107345655560493,
                0.03398383408784866,
                -0.02275797724723816,
                -0.03896703943610191,
                0.026302756741642952,
                -0.002534490777179599,
                0.06342938542366028,
                0.052234746515750885,
                -0.047081757336854935,
                0.004432851914316416,
                0.012675246223807335,
                -0.02670428529381752,
                0.015018335543572903,
                -0.05601723492145538,
                0.006919310428202152,
                -0.005983962211757898,
                -0.02590477280318737,
                -0.013126409612596035,
                -0.034939806908369064,
                0.004728368483483791,
                -0.023362698033452034,
                -0.009631152264773846,
                0.012611070647835732,
                0.018500279635190964,
                0.04835479333996773,
                0.03358446806669235,
                -0.020462172105908394,
                0.0018671703292056918,
                -0.015837175771594048,
                0.024251194670796394,
                -0.031697675585746765,
                -0.03708016872406006,
                -0.10990051925182343,
                0.010527119040489197,
                0.027292946353554726,
                -0.03409946337342262,
                0.019333316013216972,
                -0.023930510506033897,
                -0.01843186654150486,
                0.03325720876455307,
                -0.021754315122961998,
                0.0005766985123045743,
                -0.02403576858341694,
                0.001456818194128573,
                0.008684496395289898,
                2.1331113941869262e-07,
                0.0015165769727900624,
                0.04846565052866936,
                0.006112546660006046,
                0.0044677541591227055,
                0.0034159126225858927,
                0.006626976188272238,
                0.030237577855587006,
                0.00732079241424799,
                -0.01095003541558981,
                -0.021084556356072426,
                0.020963219925761223,
                0.019447552040219307,
                -0.025855673477053642,
                -0.005469224881380796,
                0.03570611774921417,
                -0.05858541652560234,
                -0.00016884990327525884,
                -0.0077399034053087234,
                -0.041784197092056274,
                -0.03150041028857231,
                -0.0069120400585234165,
                -0.003740202635526657,
                0.025080606341362,
                -0.028206003829836845,
                -0.008733738213777542,
                -0.03643582761287689,
                -0.0008670003735460341,
                0.028355609625577927,
                0.06735285371541977,
                0.07079966366291046,
                -0.002526205265894532,
                -0.04342900961637497,
                -0.053674742579460144,
                0.03684244304895401,
                0.03513384610414505,
                -0.03928016871213913,
                0.026390405371785164,
                0.035524047911167145,
                -0.02342907525599003,
                0.042459141463041306,
                -0.019742827862501144,
                -0.013285292312502861,
                -0.04446951672434807,
                0.10484319925308228,
                -0.00010178855882259086,
                -0.02906496450304985,
                0.007322964258491993,
                0.05409851297736168,
                -0.07447288930416107,
                -0.021301990374922752,
                -0.017427442595362663,
                0.050924018025398254,
                0.029075654223561287,
                0.04211099073290825,
                0.004799783695489168,
                -0.03080204501748085,
                -0.04698427766561508,
                0.04077928140759468,
                0.020242782309651375,
                0.03406741842627525,
                -0.03910144791007042,
                0.057559166103601456,
                0.026153167709708214,
                -0.014752542600035667,
                -0.042781390249729156,
                0.028965702280402184,
                -0.0073991031385958195,
                1.4334752975925807e-34,
                -0.02395101822912693,
                0.04719104245305061,
                0.004010593984276056,
                0.05497441068291664,
                -0.018900344148278236,
                0.005721601657569408,
                0.03263016790151596,
                -0.00984127912670374,
                -0.017520347610116005,
                0.013863591477274895,
                -0.023913651704788208,
                0.015796596184372902,
                -0.014164973050355911,
                -0.017800671979784966,
                -0.00835698563605547,
                0.0639750212430954,
                0.02855629101395607,
                -0.00810952764004469,
                -0.057216424494981766,
                -0.09738146513700485,
                0.09478671848773956,
                0.045707739889621735,
                0.0748722106218338,
                -0.030037248507142067,
                0.05242479592561722,
                0.04484875500202179,
                -0.04480130597949028,
                0.017178136855363846,
                -0.008125818334519863,
                -0.039612188935279846,
                0.04092373326420784,
                0.049556292593479156,
                -0.017914047464728355,
                -0.0009654437890276313,
                0.04705779626965523,
                -0.021845266222953796,
                -0.0008000004454515874,
                -0.04591898247599602,
                0.01875799335539341,
                0.0016269136685878038,
                -0.05526693910360336,
                -0.005609852261841297,
                -0.055230967700481415,
                -0.018847649917006493,
                -0.032134998589754105,
                1.272490294468298e-06,
                0.04069427028298378,
                -0.004968252964317799,
                -0.062135908752679825,
                0.007627330720424652,
                0.036604687571525574,
                -0.06072257086634636,
                0.02623184584081173,
                0.00013947069237474352,
                0.03899423032999039,
                -0.014332023449242115,
                -0.003275642404332757,
                0.003464702982455492,
                0.04143340885639191,
                0.007256095763295889,
                0.014439959079027176,
                0.009416460059583187,
                0.0039059040136635303,
                -0.0447872132062912,
                -0.033841781318187714,
                -0.011719740927219391,
                -0.08894871920347214,
                0.001314894761890173,
                -0.06746359169483185,
                -0.04179554060101509,
                0.07669351994991302,
                0.003506576642394066,
                -0.004376109689474106,
                0.011741520836949348,
                0.01891210861504078,
                0.0613352470099926,
                0.04590987414121628,
                0.013308524154126644,
                0.02512129582464695,
                0.0028861400205641985,
                -0.024657342582941055,
                0.050267089158296585,
                0.0055936467833817005,
                -0.0012207931140437722,
                -0.016754845157265663,
                0.057324402034282684,
                -0.01695362478494644,
                0.006826906930655241,
                -0.030943339690566063,
                -0.005786254070699215,
                0.05628791078925133,
                -0.08974107354879379,
                -0.007043163757771254,
                -0.018751464784145355,
                0.010822519659996033,
                0.02606535516679287,
                0.01685548946261406,
                -0.03913450986146927,
                0.02942146174609661,
                0.026242733001708984,
                0.018998540937900543,
                -0.018123256042599678,
                0.05102970078587532,
                -0.010167131200432777,
                0.05018949136137962,
                -0.0050109256990253925,
                -0.025502778589725494,
                0.0017132513457909226,
                -0.03786514326930046,
                -0.024146446958184242,
                0.008951597847044468,
                0.0013820704771205783,
                -0.025358278304338455,
                0.07919718325138092,
                -0.01735112816095352,
                0.00020429855794645846,
                0.05316120386123657,
                0.014136629179120064,
                -0.039347562938928604,
                0.006620601285248995,
                0.027808187529444695,
                -0.01317392848432064,
                0.03336433321237564,
                -0.03856584057211876,
                -3.648895653896034e-05,
                0.03801482170820236,
                0.014076749794185162,
                -0.03882038965821266,
                0.0310704056173563,
                0.018969448283314705,
                0.02148878388106823,
                -0.03563450649380684,
                0.0100848488509655,
                -0.03281222656369209,
                0.046714652329683304,
                -0.0390656441450119,
                0.03895322233438492,
                -0.07314004004001617,
                0.02874325029551983,
                -0.040221359580755234,
                0.07144033163785934,
                0.019524553790688515,
                0.00697580398991704,
                -0.03434263542294502,
                0.006177177187055349,
                0.00565247843042016,
                -0.007595956791192293,
                -0.03374139964580536,
                0.028491105884313583,
                -0.07425209134817123,
                0.01245514303445816,
                0.041027236729860306,
                0.00868945475667715,
                -0.019407404586672783,
                -0.027463579550385475,
                -0.05976605787873268,
                -0.024296488612890244,
                -0.08228974789381027,
                -0.00042163801845163107,
                0.03812902793288231,
                -0.023817028850317,
                0.018405884504318237,
                -0.07407903671264648,
                0.016213923692703247,
                0.00764303607866168,
                0.055731408298015594,
                0.01902887225151062,
                -0.005193562712520361,
                0.006018765736371279,
                -0.00111593387555331,
                0.016007494181394577,
                -0.012732897885143757,
                0.0040276749059557915,
                0.0016458143945783377,
                0.06253062933683395,
                -0.05857975780963898,
                0.02119327150285244,
                0.11809037625789642,
                -0.014446736313402653,
                0.013412767089903355,
                -0.0433192253112793,
                -0.011145674623548985,
                0.04387906938791275,
                0.0036185826174914837,
                0.06493613868951797,
                0.016461586579680443,
                -0.09853032231330872,
                0.03502361476421356,
                -0.05807535722851753,
                -0.013725820928812027,
                0.008721389807760715,
                0.026843972504138947,
                -0.06431939452886581,
                0.0658281221985817,
                0.013582590036094189,
                0.07513879984617233,
                0.012989160604774952,
                -0.04050634428858757,
                0.08108711987733841,
                -0.030503006651997566,
                0.019532831385731697,
                0.054015349596738815,
                -0.003363997209817171,
                -0.09131146967411041,
                0.02767913043498993,
                0.0074820988811552525,
                0.030527526512742043,
                -0.06478878110647202,
                0.0235760435461998,
                -0.014878183603286743,
                0.002545780036598444,
                -0.012567058205604553,
                0.06199583783745766,
                -0.07101194560527802,
                -0.049895547330379486,
                -0.042800869792699814,
                -0.0737520381808281,
                -0.02966482751071453,
                -0.06479321420192719,
                -0.03312879428267479,
                0.015703650191426277,
                0.015620469115674496,
                -0.00843143742531538,
                -0.03444161266088486,
                0.013821983709931374,
                0.011867256835103035,
                -0.009503239765763283,
                -0.016308432444930077,
                0.00040519461617805064,
                0.0533759705722332,
                0.03226084262132645,
                -0.02821284346282482,
                0.024186979979276657,
                0.014214341528713703,
                0.09302370995283127,
                -0.02931969054043293,
                -0.08294636011123657,
                0.04742196574807167,
                -0.048410024493932724,
                0.012005304917693138,
                0.013095352798700333,
                0.003647405654191971,
                -0.0029075758066028357,
                0.052920863032341,
                -0.0004156373906880617,
                0.010502450168132782,
                0.018931711092591286,
                0.05301033705472946,
                0.029515855014324188,
                0.03374669328331947,
                -0.018970390781760216,
                -0.01539540197700262,
                -0.04379979893565178,
                -0.015083717182278633,
                -0.01157291792333126,
                -0.0064019556157290936,
                0.03138560429215431,
                -0.00840427353978157,
                0.004413061775267124,
                0.03672038018703461,
                -0.0038808814715594053,
                0.006580932065844536,
                -0.00809371005743742,
                -0.0017376025207340717,
                0.03755031153559685,
                0.005401083268225193,
                -0.009533006697893143,
                -0.05033009126782417,
                0.0027840211987495422,
                -0.0016515606548637152,
                -0.009960745461285114,
                0.03677083179354668,
                0.004130436573177576,
                -0.003519797697663307,
                -0.0013666594168171287,
                0.058166444301605225,
                -0.04328670725226402,
                -0.019481046125292778,
                0.012650866061449051,
                -0.042655982077121735,
                0.030034145340323448,
                -0.0010762379970401525,
                0.011909998022019863,
                0.002755826571956277,
                -0.038849830627441406,
                -0.016081150621175766,
                0.0007516918703913689,
                0.031670283526182175,
                0.022373124957084656,
                -0.03909881040453911,
                0.009953642264008522,
                0.03344428166747093,
                0.0232500322163105,
                0.011984541080892086,
                0.07351398468017578,
                0.02078051120042801,
                -0.00135372718796134,
                0.004729763139039278,
                -0.046303216367959976,
                0.020023975521326065,
                0.0259122084826231,
                0.02905511111021042,
                -0.051009539514780045,
                0.006949140224605799,
                0.014819256030023098,
                0.015003746375441551,
                0.03654264658689499,
                0.022553429007530212,
                -0.09524499624967575,
                -0.055545125156641006,
                0.0453520342707634,
                -0.00020864389080088586,
                -0.015623267740011215,
                0.03755141794681549,
                -0.007208068389445543,
                -0.007989974692463875,
                -0.01816537417471409,
                -0.04282631352543831,
                0.030146107077598572,
                0.011903070844709873,
                -0.023569852113723755,
                -0.0026890502776950598,
                0.06805187463760376,
                0.07998231053352356,
                0.005384551826864481,
                0.050068046897649765,
                -0.003516237484291196,
                0.05539429560303688,
                0.013464806601405144,
                0.02868148684501648,
                -0.01285345945507288,
                -0.008840234950184822,
                0.060518890619277954,
                0.0034302370622754097,
                -0.0034285441506654024,
                0.02130137011408806,
                0.006538714747875929,
                -0.05642516165971756,
                -0.05087220296263695,
                -0.04583247750997543,
                -0.05713840201497078,
                -0.02491901069879532,
                -0.019417500123381615,
                -0.0016937183681875467,
                -0.04024231433868408,
                -0.07846562564373016,
                -0.03215964511036873,
                0.008872391656041145,
                -0.019268890842795372,
                -0.024222033098340034,
                -0.01466014701873064,
                0.001390908844769001,
                -0.0009708579163998365,
                -0.026487065479159355,
                0.0015136452857404947,
                -0.021956879645586014,
                -0.012871593236923218,
                0.01738305389881134,
                0.05406434088945389,
                -0.04709095507860184,
                0.0383542999625206,
                0.005706783849745989,
                0.031369030475616455,
                -0.02875395677983761,
                -0.01457486767321825,
                0.028928479179739952,
                -0.036936089396476746,
                -0.020647983998060226,
                -0.015433535911142826,
                -0.02363184466958046,
                -0.03728353977203369,
                -0.00022198645456228405,
                0.016427066177129745,
                0.08238209784030914,
                -0.04538217931985855,
                -0.016143228858709335,
                -0.01852368749678135,
                -0.003934522625058889,
                -0.04433819279074669,
                0.08637499064207077,
                0.026073943823575974,
                0.010949088260531425,
                -0.0010635557118803263,
                0.00520806759595871,
                -0.01726369559764862,
                0.014758789911866188,
                -0.03576389700174332,
                0.016451029106974602,
                0.006243513431400061,
                0.016909833997488022,
                0.017594678327441216,
                0.04486541822552681,
                -0.028071580454707146,
                0.007185904774814844,
                -0.054156821221113205,
                0.054590266197919846,
                -0.026310311630368233,
                -0.008193655870854855,
                -0.0024327216669917107,
                0.04123739153146744,
                -0.04826633259654045,
                0.022141214460134506,
                -0.017198380082845688,
                0.027157127857208252,
                0.014913027174770832,
                0.029703479260206223,
                -0.013165703974664211,
                0.012645747512578964,
                0.039318930357694626,
                -0.0032864210661500692,
                0.007377495989203453,
                0.02317279577255249,
                0.0025871223770081997,
                -0.0010851973202079535,
                -0.006147934589534998,
                -0.056286223232746124,
                -0.04540664330124855,
                0.05185246095061302,
                -0.030610300600528717,
                -0.030316391959786415,
                0.0527261421084404,
                -0.01247889269143343,
                0.0015761712566018105,
                0.011568667367100716,
                -0.0054969387128949165,
                0.004963383544236422,
                -0.04942816123366356,
                -0.0243969913572073,
                0.049614351242780685,
                0.012919250875711441,
                -0.03863437846302986,
                0.01728956028819084,
                0.06741183996200562,
                0.018008539453148842,
                -0.04521603509783745,
                0.046734441071748734,
                0.0191282220184803,
                -0.0402517169713974,
                -0.013409550301730633,
                -0.05241122841835022,
                0.0011723065981641412,
                0.010583823546767235,
                -0.007147884462028742,
                0.01637757010757923,
                0.0273903701454401,
                0.048644840717315674,
                0.022213587537407875,
                -0.012640026398003101,
                0.03834307938814163,
                0.04768326133489609,
                0.01981387287378311,
                0.019502511247992516,
                0.1051584780216217,
                0.0263811107724905,
                0.01071111299097538,
                -0.015957435593008995,
                -0.015562020242214203,
                0.03141150251030922,
                -0.016166510060429573,
                -0.05966513976454735,
                0.050296273082494736,
                0.04610441252589226,
                0.04964904114603996,
                -0.06768953800201416,
                -0.009785715490579605,
                -0.01189562026411295,
                0.032910335808992386,
                -0.04637763649225235,
                -0.06157486140727997,
                -0.007712589576840401,
                -0.02235911786556244,
                0.036272548139095306,
                0.020419960841536522,
                -0.029262538999319077,
                0.04086638614535332,
                -0.04254622012376785,
                -0.05825263261795044,
                -0.03941040486097336,
                -0.014148387126624584,
                0.024669364094734192,
                -0.06774167716503143,
                0.04892122372984886,
                -0.02888096682727337,
                -0.050283391028642654,
                -0.006657426245510578,
                -0.04117787256836891,
                0.01555682998150587,
                0.023333659395575523,
                -0.049464933574199677,
                -0.00518861785531044,
                0.016754930838942528,
                -0.05568518862128258,
                -0.004973042756319046,
                0.01140893716365099,
                -0.07399894297122955,
                0.0023068292066454887,
                -0.0026560649275779724,
                0.0043758973479270935,
                -0.006837734021246433,
                0.011033392511308193,
                -0.004980326630175114,
                0.008033097721636295,
                -0.008516525849699974,
                0.01612352393567562,
                -0.009775398299098015,
                -0.0073917582631111145,
                0.0229878481477499,
                0.012871823273599148,
                0.029634321108460426,
                0.055000368505716324,
                -0.09180064499378204,
                -0.050614383071660995,
                0.01108606904745102,
                0.0059895943850278854,
                -0.01773209124803543,
                0.0054282862693071365,
                -0.009978370741009712,
                0.035080358386039734,
                0.030480487272143364,
                -0.06371912360191345,
                0.02935304120182991,
                0.02823830395936966,
                -0.0231399554759264,
                0.010577009990811348,
                -0.06022109091281891,
                0.015751589089632034,
                0.014500027522444725,
                0.027963759377598763,
                0.034468043595552444,
                -0.03689921274781227,
                -0.05383186787366867,
                0.01221032626926899,
                -0.039562251418828964,
                -0.03266577422618866,
                -0.05400591343641281,
                0.007554700132459402,
                0.016452012583613396,
                0.019458556547760963,
                -0.015258146449923515,
                -0.015208128839731216,
                0.0396406315267086,
                0.048188723623752594,
                -0.02547883801162243,
                -0.06444115191698074,
                0.004078038036823273,
                -0.01197662390768528,
                0.031605497002601624,
                -0.008341529406607151,
                0.021985281258821487,
                0.016531595960259438,
                -0.006567653268575668,
                0.029583869501948357,
                0.045458242297172546,
                0.017901182174682617,
                -0.05023205280303955,
                -0.025782408192753792,
                -0.04430263116955757,
                -0.06136905029416084,
                0.006978497840464115,
                0.020674295723438263,
                0.028124699369072914,
                -0.022799400612711906,
                -0.055737726390361786,
                -4.287876799935475e-05,
                0.022499216720461845,
                -0.025928424671292305,
                -4.3711991704395124e-33,
                -0.02769901230931282,
                -0.032550472766160965,
                0.06896726042032242,
                -0.010602768510580063,
                -0.02703867293894291,
                -0.010478601790964603,
                -0.007216556929051876,
                0.01139331329613924,
                -0.04459052160382271,
                -0.03195267543196678,
                -0.0037660084199160337,
                -0.014138873666524887,
                0.00042418783414177597,
                -0.00843899603933096,
                0.009639271534979343,
                0.04629294201731682,
                0.06562372297048569,
                -0.010352585464715958,
                0.021932315081357956,
                0.0023624561727046967,
                -0.02848535031080246,
                0.02056441456079483,
                0.0025208748411387205,
                0.07550231367349625,
                -0.0507127046585083,
                -0.024901000782847404,
                -0.0005922011914663017,
                -0.0017094167415052652,
                0.04423533007502556,
                -0.003309290623292327,
                0.02368313819169998,
                -0.026327546685934067,
                0.0019194497726857662,
                0.015066467225551605,
                -0.00445425184443593,
                -0.011555684730410576,
                -0.0070052370429039,
                0.03053971938788891,
                -0.0629083439707756,
                0.010690190829336643,
                0.015731647610664368,
                0.02402004599571228,
                0.05159655213356018,
                -0.024585651233792305,
                -0.008394800126552582,
                0.04222653806209564,
                0.01460733450949192,
                -0.0008602292509749532,
                0.0064531476236879826,
                -0.04380176588892937,
                -0.03527310863137245,
                -0.005088520236313343,
                -0.03663834556937218,
                0.048413462936878204,
                -0.026457948610186577,
                -0.02243587002158165,
                -0.02895255759358406,
                -0.01951718144118786,
                0.010979246348142624,
                -0.00785525981336832,
                -0.047517478466033936,
                0.008946124464273453,
                0.014258768409490585,
                0.06320180743932724,
                -0.0029205004684627056,
                -0.015189078636467457,
                0.015457505360245705,
                0.04462669417262077,
                -0.014530052430927753,
                0.01931954175233841,
                -0.020251793786883354,
                0.015898054465651512,
                0.02257826365530491,
                -0.05016454681754112,
                -0.1110209971666336,
                -0.001994882943108678,
                0.04093572869896889,
                0.009553647600114346,
                0.09776110202074051,
                0.08115538954734802,
                -0.022812370210886,
                0.022369906306266785,
                0.008539377711713314,
                -0.015555056743323803,
                0.01039685308933258,
                -0.07680585980415344,
                0.010977950878441334,
                0.06170187145471573,
                0.021416591480374336,
                -0.016610004007816315,
                -0.027994833886623383,
                0.13098281621932983,
                0.0069104949943721294,
                0.026167962700128555,
                0.053664129227399826,
                0.0042486595921218395,
                -0.04052251577377319,
                0.017495758831501007,
                -0.00902643334120512,
                0.037215281277894974,
                -0.06918996572494507,
                0.01853376254439354,
                0.005970671307295561,
                -0.021226797252893448,
                0.0038984077982604504,
                -0.049317341297864914,
                -0.03852616623044014,
                -0.037814173847436905,
                -0.048431165516376495,
                0.014657721854746342,
                0.005215117242187262,
                0.04427775368094444,
                0.00761107774451375,
                0.016587425023317337,
                -0.06642477214336395,
                -0.04800751805305481,
                0.0017178241396322846,
                0.007695815060287714,
                -0.035836558789014816,
                -0.09815433621406555,
                -0.04647672548890114,
                0.05058134347200394,
                -0.04261862114071846,
                -0.0008771655848249793,
                -0.003847230691462755,
                -0.0003609131381381303,
                0.059679239988327026,
                0.020055444911122322,
                -0.011505902744829655,
                -0.0628916397690773,
                0.011410425417125225,
                0.03111594170331955,
                1.8960982117732783e-07,
                0.026128580793738365,
                -0.0007138549699448049,
                0.014150092378258705,
                -0.0015131136169657111,
                -0.002241194248199463,
                0.0077715241350233555,
                -0.0335022397339344,
                -0.06320582330226898,
                0.0015408338513225317,
                -0.01770816370844841,
                0.002051325747743249,
                0.04743962362408638,
                -0.015876181423664093,
                0.02278364636003971,
                0.06458111852407455,
                -0.021012214943766594,
                0.015012170188128948,
                0.018612710759043694,
                -0.08623552322387695,
                -0.02183404192328453,
                -0.006437938194721937,
                0.027387011796236038,
                0.02737085148692131,
                0.003698512213304639,
                0.01894141361117363,
                -0.04822053015232086,
                -0.010929525829851627,
                0.07789722084999084,
                0.05820665881037712,
                0.056260451674461365,
                -0.06189490482211113,
                -0.05178841948509216,
                -0.02624771185219288,
                0.0063134790398180485,
                0.03129832074046135,
                -0.020952267572283745,
                0.021655773743987083,
                0.053971465677022934,
                0.01720893383026123,
                -0.021097049117088318,
                0.02938937582075596,
                -0.018747659400105476,
                -0.03809165954589844,
                0.02332763560116291,
                -0.013769332319498062,
                -0.027004994451999664,
                -0.009439719840884209,
                0.09411139041185379,
                -0.0036231218837201595,
                -0.006322752218693495,
                -0.025969592854380608,
                0.054420582950115204,
                0.02738763578236103,
                0.023621918633580208,
                0.0030992489773780107,
                -0.039587028324604034,
                -0.07709876447916031,
                0.04311367869377136,
                0.022879386320710182,
                -0.03894107788801193,
                0.004105899948626757,
                0.008185459300875664,
                0.001305780024267733,
                0.019519658759236336,
                -0.06161848083138466,
                0.07576049119234085,
                0.0371539369225502,
                1.2397120434263158e-34,
                -0.04259418696165085,
                -0.024446528404951096,
                -0.01843264326453209,
                0.033535342663526535,
                -0.043909940868616104,
                -0.009959178976714611,
                0.016829010099172592,
                0.023899396881461143,
                -0.02275586687028408,
                -0.03337506949901581,
                0.002409862820059061,
                0.03772544488310814,
                -0.04261237010359764,
                -0.02209816500544548,
                0.0009785763686522841,
                0.018145663663744926,
                0.03692864626646042,
                0.008674015291035175,
                0.012791812419891357,
                -0.005316377617418766,
                0.037730567157268524,
                0.00467735854908824,
                -0.029692595824599266,
                0.02364550344645977,
                0.030983079224824905,
                -0.025360628962516785,
                -0.05129767954349518,
                0.02977479062974453,
                -0.02696392312645912,
                -0.014461551792919636,
                0.026669064536690712,
                0.02620428055524826,
                0.014438089914619923,
                -0.0505557581782341,
                0.002914577955380082,
                -0.04704088717699051,
                -0.03074774704873562,
                -0.012909297831356525,
                0.013825724832713604,
                -0.027892937883734703,
                0.005926888901740313,
                0.026909301057457924,
                -0.0046651046723127365,
                0.017199361696839333,
                -0.04581814259290695,
                1.6001954463717993e-06,
                -0.007571517024189234,
                -0.013016216456890106,
                -0.045082129538059235,
                -0.04257413372397423,
                -0.010114649310708046,
                0.012499813921749592,
                0.03425053507089615,
                0.020259888842701912,
                0.02842329815030098,
                -0.009739021770656109,
                0.062013305723667145,
                0.01258414052426815,
                0.022951364517211914,
                0.06766997277736664,
                0.0026035800110548735,
                0.015765054151415825,
                0.026334216818213463,
                -0.020044542849063873,
                0.01282072626054287,
                -0.06120724603533745,
                -0.011772258207201958,
                -0.024629168212413788,
                0.06718388944864273,
                0.04974086955189705,
                -0.019457831978797913,
                0.012110981158912182,
                -0.008449297398328781,
                -0.01973872259259224,
                -0.024931425228714943,
                0.07145565003156662,
                -0.007418173365294933,
                0.024440769106149673,
                -0.02573690563440323,
                -0.004189024679362774,
                -0.03036392107605934,
                0.07612710446119308,
                0.011043633334338665,
                -0.032187554985284805,
                -0.005677251610904932,
                0.04652175307273865,
                -0.02368064783513546,
                -0.0185775775462389,
                -0.03315189108252525,
                -0.012831111438572407,
                0.006814258173108101,
                0.02386154979467392,
                0.021137604489922523,
                -0.043536122888326645,
                -0.02381056360900402,
                0.07931452989578247,
                0.006901637185364962,
                -0.0202882569283247,
                -0.046779707074165344,
                -0.01072497759014368,
                0.05777491256594658,
                0.041485391557216644,
                0.02018270455300808,
                -0.020015165209770203,
                0.06284547597169876,
                -0.0324377678334713,
                -0.04954051971435547,
                -0.0010956019395962358,
                0.019725952297449112,
                -0.02637089416384697,
                0.1012381836771965,
                0.002842877060174942,
                0.007520337589085102,
                0.045147158205509186,
                -0.002855624770745635,
                0.010780737735331059,
                0.008823147043585777,
                0.05292774364352226,
                0.020582711324095726,
                -0.03450547158718109,
                -0.00020132050849497318,
                -0.04215473309159279,
                0.026042746379971504,
                -0.039047595113515854,
                0.028155315667390823,
                -0.017702994868159294,
                0.002374586882069707,
                -0.05388353765010834,
                0.006052731070667505,
                -0.022060588002204895,
                0.04283313825726509,
                0.05132579430937767,
                -0.03577647730708122,
                -0.020754188299179077,
                0.05584095045924187,
                0.0008536683744750917,
                0.04327930510044098,
                -0.01967702992260456,
                0.00523959519341588,
                0.003746052971109748,
                -0.08501570671796799,
                0.015366708859801292,
                0.02339484728872776,
                -0.015112628228962421,
                0.005037383176386356,
                0.02310457080602646,
                0.06845758855342865,
                -0.034084927290678024,
                -0.014515381306409836,
                -0.04487207531929016,
                0.009608868509531021,
                0.0006170982960611582,
                0.029522685334086418,
                0.015894440934062004,
                -0.021680472418665886,
                -0.025555234402418137,
                -0.042807579040527344,
                0.026088641956448555,
                0.013734061270952225,
                -0.0017436997732147574,
                -0.04352295398712158,
                0.0018010627245530486,
                -0.007375807501375675,
                -0.02289697527885437,
                -0.004876927938312292,
                0.05121482536196709,
                0.05196801573038101,
                -0.017007077112793922,
                -0.003502889070659876,
                -0.006383499130606651,
                0.004303223919123411,
                -0.009665659628808498,
                0.024512246251106262,
                0.013991721905767918,
                0.03555113077163696,
                -0.003960838541388512,
                0.07406311482191086,
                0.034879643470048904,
                0.019541652873158455,
                -0.013469845987856388,
                0.07069075852632523,
                -0.012594282627105713,
                -0.009682185016572475,
                -0.00306355906650424,
                0.04428369924426079,
                -0.010748043656349182,
                0.011691687628626823,
                0.08456308394670486,
                -0.05079532042145729,
                0.04293714091181755,
                0.002755820984020829,
                0.01477137953042984,
                -0.08865262567996979,
                0.002899447688832879,
                -0.00843573547899723,
                0.042572539299726486,
                0.0376170352101326,
                -0.051203105598688126,
                0.06241481378674507,
                -0.03629239648580551,
                -0.018153933808207512,
                0.03534659370779991,
                0.019706664606928825,
                -0.11027262359857559,
                -0.024951262399554253,
                0.00016371750098187476,
                -0.027389734983444214,
                -0.01541916187852621,
                -0.04355673864483833,
                0.034358032047748566,
                -0.06994682550430298,
                0.025519853457808495,
                0.005471140146255493,
                -0.0036431567277759314,
                0.045380521565675735,
                -0.0020914969500154257,
                0.006652860436588526,
                -0.03140091150999069,
                0.0013793763937428594,
                0.02329777553677559,
                0.02271670289337635,
                0.07481272518634796,
                0.0075690667144954205,
                0.013179873116314411,
                0.005268848035484552,
                0.0027262577787041664,
                0.01162798423320055,
                0.008678737096488476,
                -0.00803243089467287,
                0.03437451273202896,
                0.0064828344620764256,
                -0.06324632465839386,
                0.07657963037490845,
                -0.06511548906564713,
                0.036954037845134735,
                0.014484954066574574,
                -0.019972745329141617,
                -0.01634780876338482,
                0.01387391984462738,
                -0.005650596227496862,
                0.03516401723027229,
                -0.017106929793953896,
                -0.00875310879200697,
                0.033197127282619476,
                0.032697223126888275,
                -0.00044918086496181786,
                0.00536073325201869,
                -0.013491159304976463,
                -0.020947806537151337,
                0.002795560983940959,
                -0.00945059210062027,
                -0.040233779698610306,
                -0.08460159599781036,
                0.03625011444091797,
                0.006065696477890015,
                0.03515711799263954,
                0.004689199384301901,
                0.08091139048337936,
                0.04268224909901619,
                0.00838958565145731,
                0.00488988496363163,
                -0.039837103337049484,
                0.011280386708676815,
                -0.01103038527071476,
                -0.011094574816524982,
                -0.05772905796766281,
                0.00015890384383965284,
                -0.019314542412757874,
                -0.042438678443431854,
                -0.003863275283947587,
                0.04929955303668976,
                -0.007616688963025808,
                -0.011176162399351597,
                -0.008499030955135822,
                -0.023781144991517067,
                0.0639968290925026,
                -0.026765761896967888,
                -0.003488825634121895,
                0.01856520026922226,
                -0.04232293739914894,
                -0.006709394045174122,
                -0.013309565372765064,
                0.0014948005555197597,
                0.009845839813351631,
                -0.009399866685271263,
                0.021110203117132187,
                -0.027116939425468445,
                -0.05324450135231018,
                0.040460724383592606,
                -0.04540397599339485,
                0.01267260778695345,
                0.06672883033752441,
                0.013013719581067562,
                -0.00408710865303874,
                0.026518967002630234,
                0.015075667761266232,
                0.03748730942606926,
                0.005855366121977568,
                -0.057838939130306244,
                -0.02126581408083439,
                0.044211287051439285,
                -0.0031161881051957607,
                0.005193582270294428,
                -0.021241337060928345,
                0.014098276384174824,
                0.018545223399996758,
                -0.04222767800092697,
                -0.045801009982824326,
                0.007680513896048069,
                -0.05826724320650101,
                0.051714811474084854,
                -0.017342446371912956,
                -0.0362132228910923,
                -0.02081981860101223,
                0.0011638650903478265,
                0.02342437393963337,
                -0.07109849900007248,
                -0.012922569178044796,
                0.025260742753744125,
                -0.0019558416679501534,
                -0.03025846928358078,
                0.00013852599659003317,
                0.04150042310357094,
                -0.009377405978739262,
                -0.0010853124549612403,
                0.0072206477634608746,
                -0.008661299012601376,
                -0.0337221622467041,
                -0.003804238745942712,
                0.03100867010653019,
                -0.0049561611376702785,
                0.00726647162809968,
                -0.02561156451702118,
                -0.03133528307080269,
                -0.059764087200164795,
                0.0038021784275770187,
                -0.05865677818655968,
                -0.07535094022750854,
                -0.019784215837717056,
                -0.03334957733750343,
                -0.09136059880256653,
                0.008530762977898121,
                0.0037523023784160614,
                -0.04658713564276695,
                -0.044138044118881226,
                -0.013841856271028519,
                0.008172467350959778,
                0.016490835696458817,
                -0.05505726486444473,
                -0.06402098387479782,
                -0.016307247802615166,
                0.017228370532393456,
                0.016990913078188896,
                -0.0417390912771225,
                0.11396943777799606,
                -0.006888559088110924,
                -0.011979782022535801,
                0.04179246723651886,
                0.02705114521086216,
                -0.022654986009001732,
                -0.05382737144827843,
                0.005845016334205866,
                0.010694622993469238,
                -0.04396471753716469,
                -0.023067031055688858,
                0.034197788685560226,
                0.02520812675356865,
                -0.031176894903182983,
                0.01694357953965664,
                0.029105912894010544,
                -0.07391133904457092,
                0.004660833161324263,
                0.03603042662143707,
                0.051170315593481064,
                -0.0548938512802124,
                -0.033357493579387665,
                0.008852900937199593,
                0.03642794117331505,
                -0.019591333344578743,
                0.024915622547268867,
                0.04664594680070877,
                0.031917572021484375,
                0.042004555463790894,
                -0.10892733186483383,
                0.031405575573444366,
                0.0021343748085200787,
                -0.10538690537214279,
                -0.018081972375512123,
                0.00551402335986495,
                0.020357731729745865,
                -0.03977202624082565,
                -0.0021784957498311996,
                -0.033815398812294006,
                0.014392456039786339,
                -0.023399315774440765,
                0.05370298773050308,
                -0.027293754741549492,
                0.04061605781316757,
                -0.04875523969531059,
                0.003104651113972068,
                0.01963617652654648,
                0.019865257665514946,
                0.019908331334590912,
                -0.08500875532627106,
                0.023390816524624825,
                -0.0458185039460659,
                0.06913986057043076,
                -0.007596797309815884,
                -0.04031775891780853,
                -0.056646112352609634,
                0.04621502012014389,
                0.02730526588857174,
                -0.009220846928656101,
                -0.07138517498970032,
                -0.027367018163204193,
                0.007946892641484737,
                0.04083699360489845,
                0.00750848138704896,
                -0.07143261283636093,
                -0.012449026107788086,
                0.05069725587964058,
                -0.018975231796503067,
                0.06411662697792053,
                0.012641175650060177,
                -0.0029884467367082834,
                -0.040909212082624435,
                -0.0930304154753685,
                0.010130625218153,
                -0.02680724300444126,
                0.05280173942446709,
                -0.012244826182723045,
                0.02185291424393654,
                0.050848156213760376,
                0.03409288078546524,
                -0.046021223068237305,
                0.03566226363182068,
                -0.0036079876590520144,
                0.08054462820291519,
                0.021647552028298378,
                0.007100910879671574,
                0.04087435454130173,
                0.0033405174035578966,
                0.09337735176086426,
                0.010906394571065903,
                0.03270774707198143,
                0.11504048854112625,
                0.0040055615827441216,
                -0.010221004486083984,
                0.031179795041680336,
                0.1286827027797699,
                0.05051466450095177,
                -0.015766939148306847,
                0.10226578265428543,
                0.021877391263842583,
                0.005512844305485487,
                -0.0037660757079720497,
                -0.0903080627322197,
                -0.0033633552957326174,
                0.0006789645995013416,
                0.012114095501601696,
                -0.04732247069478035,
                0.03192907199263573,
                -0.0006926892674528062,
                -0.024413445964455605,
                -0.022488517686724663,
                -0.020218316465616226,
                0.020516712218523026,
                -0.0702008455991745,
                -0.029522234573960304,
                0.008855119347572327,
                -0.04733678698539734,
                0.06079689785838127,
                0.03451581299304962,
                0.04357844963669777,
                0.013206555508077145,
                0.045424193143844604,
                -0.07115969806909561,
                0.00648618396371603,
                0.024605974555015564,
                0.023411806672811508,
                -0.05403343215584755,
                0.07166939228773117,
                0.024169882759451866,
                -0.02077743038535118,
                -0.01962834969162941,
                0.016503198072314262,
                0.006066413130611181,
                -0.01218942366540432,
                -0.005083438940346241,
                -0.007006139028817415,
                -0.011910710483789444,
                -0.008116417564451694,
                -0.0068971929140388966,
                0.019447321072220802,
                -0.00848288182169199,
                0.022518999874591827,
                0.0007007665699347854,
                -0.018307294696569443,
                -0.023229844868183136,
                -0.004087147302925587,
                2.882806074921973e-05,
                0.03588634729385376,
                0.07724323868751526,
                0.0005037527298554778,
                -0.03280298411846161,
                -0.0022789426147937775,
                -0.02606877125799656,
                -0.08608707040548325,
                0.06289053708314896,
                0.0077690500766038895,
                0.024539148434996605,
                -0.03916335478425026,
                -0.007354183122515678,
                -0.02732185833156109,
                -0.03181857615709305,
                -0.009307799860835075,
                -0.01640775054693222,
                -0.006358874496072531,
                0.05223038047552109,
                0.01445909682661295,
                0.021324167028069496,
                0.0044907513074576855,
                0.0065973130986094475,
                -0.0034156085457652807,
                -0.06812836974859238,
                -0.022289421409368515,
                0.0774560496211052,
                0.01381117757409811,
                0.022515885531902313,
                -0.00867662113159895,
                -0.02916843630373478,
                0.030992621555924416,
                0.05236176401376724,
                0.007083076983690262,
                -0.04558606818318367,
                -0.019107390195131302,
                -0.009089299477636814,
                0.009197586216032505,
                -0.008391937240958214,
                0.0029068803414702415,
                -0.014119699597358704,
                0.0030138823203742504,
                0.002611876931041479,
                -0.010390322655439377,
                0.07165398448705673,
                -0.028765667229890823,
                -0.013601860031485558,
                0.050891462713479996,
                0.07236137986183167,
                0.012706342153251171,
                0.03094996139407158,
                0.002412685425952077,
                0.025934584438800812,
                -0.014285990037024021,
                -0.006650641094893217,
                -0.004150226712226868,
                -0.005014115013182163,
                -0.013069311156868935,
                -0.05162574723362923,
                0.017961060628294945,
                -0.020938806235790253,
                -0.0043824478052556515,
                -0.05935223773121834,
                -0.008931773714721203,
                0.012162629514932632,
                -0.02956973761320114,
                -4.977478748975339e-33,
                -0.00982346199452877,
                -0.052430301904678345,
                0.015636539086699486,
                0.03554968908429146,
                -0.029852773994207382,
                -0.002432858571410179,
                0.057473812252283096,
                -0.012784190475940704,
                -0.0031163147650659084,
                -0.03582533821463585,
                0.006706221029162407,
                0.016687821596860886,
                0.010267139412462711,
                -0.004200575407594442,
                0.054131004959344864,
                0.029974687844514847,
                -0.011749286204576492,
                -0.011660361662507057,
                -0.022372622042894363,
                -0.03247295692563057,
                -0.07618788629770279,
                0.051371511071920395,
                0.022084850817918777,
                0.001799321616999805,
                0.015236536040902138,
                -0.03323356807231903,
                -0.0285517368465662,
                0.02540053427219391,
                0.00038480127113871276,
                -0.008659962564706802,
                0.0046984427608549595,
                -0.026713252067565918,
                -0.005078515037894249,
                -0.010691579431295395,
                0.02974591590464115,
                -0.02945520542562008,
                -0.03125021606683731,
                -0.003342260839417577,
                -0.055466555058956146,
                0.023949913680553436,
                -0.016019824892282486,
                -0.008765323087573051,
                -0.003313594963401556,
                -0.023650724440813065,
                0.04613792151212692,
                -0.013299915008246899,
                0.027760019525885582,
                0.05174141749739647,
                0.02162867784500122,
                -0.04259847477078438,
                -0.005915096495300531,
                -0.019245650619268417,
                -0.04514896124601364,
                0.026006679981946945,
                -0.028504563495516777,
                -0.0017246019560843706,
                -0.01184004545211792,
                -0.01301482692360878,
                0.02310327999293804,
                -0.03315788507461548,
                -0.01835782080888748,
                0.009699090383946896,
                -0.027628442272543907,
                0.04816901683807373,
                -0.05361505597829819,
                0.030040275305509567,
                -0.039846938103437424,
                -0.004875960294157267,
                -0.004827487748116255,
                0.003577216062694788,
                0.017793314531445503,
                0.012509442865848541,
                -0.030696146190166473,
                -0.02213110588490963,
                -0.09401380270719528,
                0.04185524582862854,
                0.0017883036052808166,
                -0.0014955418882891536,
                0.08770212531089783,
                0.047516562044620514,
                -0.12921816110610962,
                0.000521128938999027,
                -0.06221657246351242,
                -0.009710104204714298,
                -0.007275926880538464,
                -0.1366882622241974,
                -0.01995299756526947,
                0.02013876661658287,
                0.021174894645810127,
                -0.04805656149983406,
                -0.017542192712426186,
                -0.01543398480862379,
                -0.03043937124311924,
                0.07312577217817307,
                0.06270785629749298,
                -0.04652712494134903,
                0.036185089498758316,
                0.027183474972844124,
                -0.029917027801275253,
                0.025498125702142715,
                -0.05078807845711708,
                -0.03967800363898277,
                -0.020338693633675575,
                -0.06084448844194412,
                -0.01660599373281002,
                0.017083853483200073,
                0.024768495932221413,
                -0.0016835209680721164,
                -0.0015709273284301162,
                -0.018247351050376892,
                -0.012873529456555843,
                0.006761380936950445,
                0.044178735464811325,
                -0.016147570684552193,
                -0.027607642114162445,
                -0.020024802535772324,
                -0.0010474200826138258,
                0.0533597394824028,
                0.002741269301623106,
                0.03748394921422005,
                0.020847246050834656,
                -0.04491395130753517,
                0.015870351344347,
                -0.07250311970710754,
                -0.03822686895728111,
                0.004940149839967489,
                -0.05031539499759674,
                0.04591420292854309,
                0.056722838431596756,
                -0.007433777209371328,
                0.015130196698009968,
                -0.012334282509982586,
                2.2785759767884883e-07,
                0.0013107494451105595,
                0.059273120015859604,
                0.026068812236189842,
                -0.048536330461502075,
                -0.01271065417677164,
                -0.04143822193145752,
                -0.029289282858371735,
                0.005326143931597471,
                -0.0012539855670183897,
                0.07895845919847488,
                0.018476061522960663,
                0.019251147285103798,
                0.008785313926637173,
                -0.00904429703950882,
                0.013691469095647335,
                -0.051506757736206055,
                -0.026367250829935074,
                -0.0025727353058755398,
                -0.06654339283704758,
                -0.005849473178386688,
                0.040396422147750854,
                0.022846026346087456,
                0.04194634035229683,
                -0.0057585579343140125,
                -0.012286036275327206,
                0.06060789152979851,
                -0.009040816687047482,
                0.024907920509576797,
                0.003135079052299261,
                0.02051171474158764,
                0.04863065481185913,
                -7.646555604878813e-05,
                -0.00809685792773962,
                0.05626741051673889,
                0.011103545315563679,
                -0.031003588810563087,
                -0.016437729820609093,
                0.0390482135117054,
                0.01483216043561697,
                0.01644832454621792,
                0.008347189985215664,
                0.03678824007511139,
                -0.027085458859801292,
                0.08617017418146133,
                -0.020396379753947258,
                -0.01571444608271122,
                0.0477561354637146,
                -0.04758792743086815,
                -0.07079138606786728,
                -0.020525861531496048,
                -0.024934513494372368,
                0.023326445370912552,
                0.017026768997311592,
                0.046524614095687866,
                0.009871688671410084,
                -0.04640694335103035,
                -0.015417596325278282,
                0.04616392403841019,
                0.06407085806131363,
                -0.05093056708574295,
                0.014390955679118633,
                -0.042359352111816406,
                0.014606707729399204,
                0.00617789663374424,
                -0.015211948193609715,
                0.02485414780676365,
                -0.019187839701771736,
                1.9927891702438786e-34,
                -0.056167714297771454,
                -0.02523699589073658,
                0.059804726392030716,
                -0.019678598269820213,
                0.04386892169713974,
                -0.041159939020872116,
                -0.04410654306411743,
                0.0036646847147494555,
                0.0017639431171119213,
                -0.020321445539593697,
                -0.026265691965818405,
                -0.019472047686576843,
                -0.09016011655330658,
                -0.027934126555919647,
                -0.007771547883749008,
                -0.014455504715442657,
                0.01830144040286541,
                0.02211540937423706,
                0.04514748603105545,
                0.08235934376716614,
                0.015672726556658745,
                0.03224729746580124,
                -0.0021915072575211525,
                5.8847086620517075e-05,
                0.05135159566998482,
                0.03997047245502472,
                0.028457876294851303,
                -0.006379616446793079,
                0.011051101610064507,
                0.009200282394886017,
                -0.016733620315790176,
                -0.024291832000017166,
                -9.295871859649196e-05,
                -0.03143615648150444,
                0.023506853729486465,
                -0.004392062779515982,
                0.02331649512052536,
                0.010479481890797615,
                0.016209430992603302,
                0.023852379992604256,
                0.0065812221728265285,
                0.10627208650112152,
                0.02861509658396244,
                -0.01847626082599163,
                -0.032376255840063095,
                1.4836551827102085e-06,
                0.020075174048542976,
                -0.022099725902080536,
                -0.02076478861272335,
                -0.006007747258991003,
                -0.015617957338690758,
                -0.03862093761563301,
                0.04100137576460838,
                0.006852701772004366,
                0.0071048857644200325,
                -0.00659335358068347,
                0.09324295818805695,
                0.05270467326045036,
                0.02416948974132538,
                0.010381046682596207,
                0.0306802187114954,
                0.029440194368362427,
                -0.009385327808558941,
                -0.03997816890478134,
                -0.06075793132185936,
                -0.021475812420248985,
                -0.00897199660539627,
                0.02006733976304531,
                -0.009927674196660519,
                0.038218360394239426,
                0.07243851572275162,
                -0.008463581092655659,
                -0.028655502945184708,
                0.02609895169734955,
                0.0027133035473525524,
                0.06890464574098587,
                0.06713312119245529,
                0.05943784862756729,
                0.005902609787881374,
                0.002276783110573888,
                -0.008197018876671791,
                0.06267734616994858,
                -0.0007874730508774519,
                -0.01784805953502655,
                0.03718149662017822,
                -0.024365045130252838,
                -0.011993988417088985,
                0.011598377488553524,
                -0.034290969371795654,
                -0.026489736512303352,
                -0.039847224950790405,
                0.0460331104695797,
                0.023312518373131752,
                -0.030546588823199272,
                0.015857692807912827,
                -0.06250417232513428,
                0.005271988455206156,
                0.015297377482056618,
                -0.022027987986803055,
                0.03247741609811783,
                0.016128528863191605,
                0.02580820769071579,
                0.01769736222922802,
                -0.03604141250252724,
                0.02302180975675583,
                0.012270858511328697,
                -0.0518016517162323,
                0.018689826130867004,
                0.015205270610749722,
                -0.028880150988698006,
                0.04958581551909447,
                -0.026949480175971985,
                0.011508699506521225,
                0.04989783093333244,
                0.051687270402908325,
                -0.018322905525565147,
                0.06456882506608963,
                0.013921995647251606,
                0.08062008023262024,
                -0.008869507350027561,
                0.016396742314100266,
                -0.04022234305739403,
                -0.027392372488975525,
                -0.017573779448866844,
                -0.01966523379087448,
                0.00869906134903431,
                0.046616509556770325,
                -0.029226046055555344,
                -0.01330493576824665,
                -0.016890786588191986,
                0.019708462059497833,
                0.07802542299032211,
                0.023715486750006676,
                -0.03379291668534279,
                0.0098795210942626,
                0.012021590024232864,
                -0.03574153035879135,
                0.002164941979572177,
                0.018614642322063446,
                0.037190183997154236,
                0.013973630033433437,
                -0.028640534728765488,
                -0.005705615039914846,
                -0.01668589748442173,
                -0.034839265048503876,
                0.019906779751181602,
                0.015297851525247097,
                -0.01645663194358349,
                -0.03283264487981796,
                -0.04013563692569733,
                -0.0060930377803742886,
                -0.0030292305164039135,
                -0.0048131016083061695,
                0.042574696242809296,
                0.009873355738818645,
                -0.027834465727210045,
                -0.06336918473243713,
                0.017715297639369965,
                -0.05161385238170624,
                0.00045882604899816215,
                -0.031532835215330124,
                -0.007691026199609041,
                -0.007251054048538208,
                -0.05716116726398468,
                -0.01234397292137146,
                0.055385563522577286,
                0.0374818854033947,
                0.05642278119921684,
                -0.018859300762414932,
                0.008573293685913086,
                0.0023319756146520376,
                -0.030407650396227837,
                -0.011836421675980091,
                -0.008525818586349487,
                0.04899946600198746,
                -0.021311944350600243,
                0.008360159583389759,
                0.053681451827287674,
                -0.027949878945946693,
                -0.10885722935199738,
                -0.02863634191453457,
                -0.025561239570379257,
                -0.029285119846463203,
                -0.018581317737698555,
                0.05731269344687462,
                -0.0037277003284543753,
                -0.03132983297109604,
                0.029780659824609756,
                0.012158711440861225,
                -0.007503206375986338,
                0.051246702671051025,
                0.017711950466036797,
                -0.09838159382343292,
                0.006920041516423225,
                0.02698911912739277,
                0.03039705753326416,
                -0.03253689035773277,
                0.012468800880014896,
                -0.03354780748486519,
                -0.05776410549879074,
                0.013180221430957317,
                0.03815337270498276,
                0.039039887487888336,
                -0.044084105640649796,
                -0.022167488932609558,
                0.018157919868826866,
                -0.003465548623353243,
                0.031058745458722115,
                -0.0029353259596973658,
                0.009832032956182957,
                0.029395734891295433,
                -0.030157776549458504,
                -0.020726747810840607,
                -0.011853339150547981,
                0.03595377132296562,
                -0.03252086788415909,
                -0.01037654746323824,
                -0.0617067813873291,
                -0.025614898651838303,
                -0.013982760719954967,
                0.07429252564907074,
                0.018828436732292175,
                -0.03990400210022926,
                0.029852570965886116,
                0.03970304876565933,
                -0.05366275832056999,
                -0.02335454523563385,
                -0.0016285422025248408,
                -0.008913804776966572,
                -0.033041585236787796,
                0.020782914012670517,
                -0.010569565929472446,
                -0.06593206524848938,
                -0.020825594663619995,
                -0.024394473060965538,
                -0.03367152065038681,
                -0.025982096791267395,
                0.03713804855942726,
                -0.0045251096598804,
                0.00810160581022501,
                0.03214312344789505,
                -0.04314783960580826,
                0.0018890235805884004,
                -0.03003072366118431,
                -0.014338011853396893,
                -0.005477261263877153,
                -0.022616084665060043,
                0.05335313826799393,
                0.04680022969841957,
                -0.01239713653922081,
                -0.11436519771814346,
                -0.012911379337310791,
                -0.001544739818200469,
                -0.00039592685061506927,
                -0.015660937875509262,
                -0.03593718260526657,
                -0.008731490932404995,
                0.0468083955347538,
                0.0038115570787340403,
                -0.0013906098902225494,
                -0.013764464296400547,
                -0.027066482231020927,
                -0.04648754745721817,
                0.016131164506077766,
                0.019469639286398888,
                0.017647327855229378,
                0.01629023067653179,
                -0.0010749144712463021,
                0.04850903898477554,
                -0.01324540562927723,
                -0.046790480613708496,
                0.0308868195861578,
                -0.008444404229521751,
                -0.02475695125758648,
                0.0006546794320456684,
                0.06940147280693054,
                -0.00464513897895813,
                -0.02568640001118183,
                -0.09999027848243713,
                -0.00428569782525301,
                -0.01226718258112669,
                0.00862119160592556,
                0.0717986673116684,
                0.021669644862413406,
                -0.010461127385497093,
                0.03318805247545242,
                -0.024908430874347687,
                -0.06759244948625565,
                -0.001337681314907968,
                -0.015859581530094147,
                -0.005439505446702242,
                0.009443148039281368,
                -0.00812523439526558,
                -0.006704478524625301,
                -0.04495062306523323,
                -0.01853858307003975,
                0.015179770067334175,
                0.042899101972579956,
                -0.027639217674732208,
                -0.013616479001939297,
                0.02486424893140793,
                0.0073216939345002174,
                -0.019611632451415062,
                -0.015575203113257885,
                -0.024312634021043777,
                -0.009494642727077007,
                0.0204937607049942,
                -0.0347968265414238,
                0.0043944078497588634,
                0.034389931708574295,
                0.030991530045866966,
                -0.04828965291380882,
                -0.04495170712471008,
                -0.03639094531536102,
                -0.005835109855979681,
                -0.0070230052806437016,
                0.037723053246736526,
                0.03630027547478676,
                0.016656838357448578,
                -0.04283006116747856,
                -0.029864909127354622,
                -0.028081459924578667,
                0.016636015847325325,
                -0.026757828891277313,
                0.034556515514850616,
                0.005911581683903933,
                -0.030956333503127098,
                -0.026709485799074173,
                0.01249502319842577,
                0.02003583312034607,
                0.022613966837525368,
                0.0030044852755963802,
                0.011879143305122852,
                -0.012274669483304024,
                -0.04566078633069992,
                -0.0010302782757207751,
                -0.012797543779015541,
                -0.02937997877597809,
                -0.007652349304407835,
                -0.026883069425821304,
                -0.08409509062767029,
                -0.009471067227423191,
                0.0855480208992958,
                -0.06763287633657455,
                -0.052302077412605286,
                0.04694969952106476,
                -0.07756811380386353,
                -0.001990741351619363,
                -0.0970502644777298,
                -0.011190509423613548,
                -0.003640237729996443,
                0.00015049873036332428,
                -0.02993081882596016,
                -0.030535945668816566,
                -0.007637577597051859,
                0.04367364943027496,
                0.027589455246925354,
                -0.024115296080708504,
                0.024629898369312286,
                0.02284632809460163,
                -0.026389144361019135,
                0.025898601859807968,
                0.015049158595502377,
                -0.015337353572249413,
                0.009348073042929173,
                -0.012420653365552425,
                0.0462760329246521,
                0.05364607274532318,
                0.03887072205543518,
                0.014092270284891129,
                -0.05782240629196167,
                -0.025512536987662315,
                -0.013401406817138195,
                -0.047725118696689606,
                -0.020098024979233742,
                -0.04498470947146416,
                0.019076697528362274,
                0.05684605613350868,
                -0.038378436118364334,
                0.006777856033295393,
                -0.026942607015371323,
                -0.03707527741789818,
                0.01870931126177311,
                -0.04683247208595276,
                0.03751391917467117,
                -0.02375222183763981,
                -0.027193192392587662,
                -0.026018250733613968,
                0.05230136588215828,
                0.04940969869494438,
                0.00419786898419261,
                -0.01789906434714794,
                -0.04064617305994034,
                0.06623934209346771,
                -0.011844899505376816,
                0.032687585800886154,
                -0.029656140133738518,
                -0.007220671512186527,
                -0.012648608535528183,
                0.011350213550031185,
                0.015769531950354576,
                0.014191380701959133,
                0.05840270221233368,
                -0.04780736193060875,
                0.04515380784869194,
                -0.005355718079954386,
                0.03591228276491165,
                -0.06971058994531631,
                0.005157496314495802,
                0.039152778685092926,
                0.04260460287332535,
                0.058031097054481506,
                -0.006149775814265013,
                -0.03783644735813141,
                0.030480898916721344,
                0.010224144905805588,
                0.030840028077363968,
                0.04397875443100929,
                -0.05454288795590401,
                -0.04868432506918907,
                0.019261155277490616,
                0.037198811769485474,
                -0.06704358756542206,
                -0.02609878219664097,
                -0.014708132483065128,
                0.003472384065389633,
                -0.07724633812904358,
                -0.03637722507119179,
                0.019779404625296593,
                0.031076468527317047,
                -0.055003922432661057,
                0.03829184174537659,
                0.03876938670873642,
                0.03031982108950615,
                -0.04772347956895828,
                0.06251891702413559,
                -0.02193627692759037,
                -0.04206753149628639,
                0.036778274923563004,
                -0.02420845627784729,
                -0.03450385108590126,
                -0.028510337695479393,
                -0.008207391016185284,
                -0.014895634725689888,
                0.010213944129645824,
                0.028420131653547287,
                -0.041697558015584946,
                0.045310426503419876,
                0.016117114573717117,
                -0.024222714826464653,
                0.017073869705200195,
                0.0009473839309066534,
                0.02607726864516735,
                0.006270915735512972,
                0.007372351363301277,
                -0.024896880611777306,
                0.047000739723443985,
                -0.032110460102558136,
                -0.01016696821898222,
                -0.013414093293249607,
                -0.04086427018046379,
                0.0848797857761383,
                -0.03863553702831268,
                -0.0003087952209170908,
                -0.01921660080552101,
                -0.013523637317121029,
                -0.051428064703941345,
                -0.07785869389772415,
                0.03864056617021561,
                -0.032080356031656265,
                0.005699581000953913,
                0.033239275217056274,
                0.03492610156536102,
                0.06376821547746658,
                -0.03383271023631096,
                0.015576543286442757,
                0.011672387830913067,
                -0.0813199132680893,
                0.02353408746421337,
                -0.010074829682707787,
                -0.062315456569194794,
                -0.04425014555454254,
                -0.012242143973708153,
                -0.025053691118955612,
                0.020650029182434082,
                0.023597870022058487,
                0.008395844139158726,
                -0.005033786874264479,
                0.04105377197265625,
                -0.054952796548604965,
                0.05277477204799652,
                0.03969436138868332,
                0.02958233281970024,
                0.03630775958299637,
                0.02348494902253151,
                -0.009857344441115856,
                0.03611978888511658,
                0.057669155299663544,
                -0.009699215181171894,
                0.0019567515701055527,
                -0.01815589889883995,
                0.025598015636205673,
                -0.04677055403590202,
                -0.042133260518312454,
                -0.021043621003627777,
                0.07913057506084442,
                -0.018817035481333733,
                -0.019763348624110222,
                0.06128382310271263,
                -0.08718317747116089,
                -0.0074739581905305386,
                -0.002552868565544486,
                -0.008668966591358185,
                0.01745489239692688,
                -0.017616497352719307,
                0.008492649532854557,
                0.02045406401157379,
                0.002509382553398609,
                0.01942201890051365,
                0.015404936857521534,
                -0.0029843139927834272,
                0.027333704754710197,
                0.04656727612018585,
                -0.008313830010592937,
                0.013241283595561981,
                0.007765159010887146,
                0.024195345118641853,
                0.016494667157530785,
                0.03647799417376518,
                0.00486026331782341,
                -0.015011434443295002,
                0.0433332659304142,
                -0.031215889379382133,
                0.012099787592887878,
                -0.00031265945290215313,
                -0.004134524613618851,
                -8.36125182104297e-05,
                0.02403242141008377,
                0.04419347271323204,
                0.0003453721874393523,
                0.03557826578617096,
                0.0007171671604737639,
                -0.0067968047223985195,
                -0.04457767307758331,
                0.023552443832159042,
                -0.020824387669563293,
                -0.009740636684000492,
                0.016089122742414474,
                0.005814834032207727,
                0.04226955026388168,
                0.05357901379466057,
                -0.005931601393967867,
                0.002637048251926899,
                0.03519872948527336,
                0.02016112580895424,
                -0.00011856580385938287,
                -0.015422007068991661,
                0.05062310770153999,
                0.043417926877737045,
                0.011898146942257881,
                -0.006945785600692034,
                0.06354201585054398,
                -0.047652289271354675,
                0.021435463801026344,
                0.012808853760361671,
                -0.005757797509431839,
                -5.6984086996506785e-33,
                -0.029837461188435555,
                0.00675938930362463,
                0.03218124061822891,
                0.04632265493273735,
                -0.045845452696084976,
                0.01441182941198349,
                5.277024320093915e-05,
                0.02839946560561657,
                -0.017297735437750816,
                -0.05571172758936882,
                -0.01793145015835762,
                0.03068448230624199,
                0.015506253577768803,
                0.019122373312711716,
                0.048375409096479416,
                0.03777598962187767,
                0.014765690080821514,
                0.023540310561656952,
                -0.01156133133918047,
                -0.011454412713646889,
                -0.07850465178489685,
                -0.006178871262818575,
                0.034847941249608994,
                -0.021862974390387535,
                0.04073959216475487,
                0.029972659423947334,
                0.0014457629295066,
                -0.011470087803900242,
                -0.014609919860959053,
                -0.01465230155736208,
                0.003627954050898552,
                -0.007584543898701668,
                0.013002162799239159,
                0.004029977135360241,
                0.007065732963383198,
                -0.029754512012004852,
                -0.045117076486349106,
                -0.01991254836320877,
                -0.06253347545862198,
                0.0015536699211224914,
                -0.0424218475818634,
                -0.05708251893520355,
                0.018637673929333687,
                -0.03296099230647087,
                -0.07613403350114822,
                -0.04390198364853859,
                -0.019714070484042168,
                0.04472970962524414,
                0.07845327258110046,
                0.04965704306960106,
                -0.051158495247364044,
                0.010416251607239246,
                -0.0615001879632473,
                0.05545810982584953,
                -0.05413340777158737,
                -0.026049412786960602,
                0.00968149770051241,
                -0.03806735947728157,
                -0.010650048032402992,
                -0.028183791786432266,
                -0.06228794530034065,
                0.05277249589562416,
                0.028829537332057953,
                -0.10108999907970428,
                -0.04370557516813278,
                0.020202426239848137,
                0.016007279977202415,
                0.07541507482528687,
                0.049205560237169266,
                -0.03682230785489082,
                -0.007889920845627785,
                0.06635098159313202,
                0.0046867006458342075,
                -0.04069960117340088,
                -0.12630070745944977,
                -0.05743037536740303,
                -0.024769678711891174,
                0.033901579678058624,
                -0.00032070704037323594,
                0.056376755237579346,
                -0.02325609140098095,
                -0.027734173461794853,
                -0.010709119960665703,
                -0.00596311129629612,
                0.0079375384375453,
                0.02485828474164009,
                0.0067334589548408985,
                0.026691267266869545,
                0.04148247465491295,
                -0.015531186014413834,
                0.02771226316690445,
                0.006416005548089743,
                -0.01972845569252968,
                0.00919706653803587,
                0.1025303527712822,
                -0.06588689237833023,
                -0.0062103006057441235,
                0.02938130311667919,
                0.006176990922540426,
                0.06305048614740372,
                -0.039274077862501144,
                -0.006807171739637852,
                -0.02832801826298237,
                0.005636813584715128,
                -0.007499951869249344,
                0.006561106536537409,
                -0.014818252995610237,
                0.0001588933082530275,
                -0.03122195042669773,
                -0.01381148211658001,
                -0.0016988512361422181,
                -0.025295348837971687,
                -0.010151974856853485,
                -0.007022402249276638,
                0.026902658864855766,
                0.025338876992464066,
                0.026927921921014786,
                -0.12407062947750092,
                -0.011620831675827503,
                -0.013711467385292053,
                0.028691401705145836,
                -0.05435933545231819,
                0.017211319878697395,
                0.015447746030986309,
                0.012230806984007359,
                0.015712900087237358,
                0.04309307038784027,
                -0.06143198907375336,
                -0.047857001423835754,
                0.010778920724987984,
                0.02371358685195446,
                0.01552463136613369,
                2.0360776886718668e-07,
                -0.03154917433857918,
                0.02705751359462738,
                0.030155960470438004,
                0.04907349497079849,
                0.02087450586259365,
                -0.03208666294813156,
                -0.02536630444228649,
                0.06347561627626419,
                0.027879424393177032,
                0.04788103699684143,
                -0.004537179134786129,
                -0.02445816621184349,
                -0.009809731505811214,
                -0.10572592914104462,
                0.00936802290380001,
                -0.05926203355193138,
                0.06339344382286072,
                0.02288050204515457,
                -0.030479270964860916,
                0.011242388747632504,
                0.027775662019848824,
                -0.004528502468019724,
                -0.011920328252017498,
                -0.012436486780643463,
                -0.01666961796581745,
                -0.06497685611248016,
                0.023070773109793663,
                -0.01407574862241745,
                0.07676389068365097,
                0.015850838273763657,
                0.029513012617826462,
                0.07197070866823196,
                0.04961758852005005,
                0.09488086402416229,
                0.0037485286593437195,
                -0.018978336825966835,
                0.07386647164821625,
                0.06326594948768616,
                -0.008574933744966984,
                0.0785873606801033,
                0.008413076400756836,
                -3.39550751959905e-05,
                0.009132839739322662,
                -0.005303152371197939,
                0.042859774082899094,
                0.013591364026069641,
                -0.008439753204584122,
                0.02206641063094139,
                -0.1214057207107544,
                -0.030958466231822968,
                -0.026896024122834206,
                0.06274261325597763,
                -0.02411511540412903,
                -0.04474607855081558,
                0.04089996591210365,
                -0.009161378256976604,
                -0.0037151037249714136,
                0.03220057487487793,
                0.07576227188110352,
                0.01775408536195755,
                -0.03725089132785797,
                -0.05049682408571243,
                -0.0012283395044505596,
                0.035218801349401474,
                0.0604800209403038,
                -0.027086561545729637,
                -0.0459357351064682,
                1.6720476158104738e-34,
                -0.04615234211087227,
                0.002539850538596511,
                0.017834408208727837,
                0.07899700105190277,
                0.01135109830647707,
                -0.010349970310926437,
                0.010178624652326107,
                -0.021466702222824097,
                -0.012167934328317642,
                -0.03529473394155502,
                -0.057961225509643555
            ]
        },
        "Education": {
            "name": "BSc/Electronics Engineering",
            "rep": [
                -0.03475391864776611,
                -0.07257798314094543,
                -0.04521907866001129,
                -0.016042465344071388,
                0.05160731449723244,
                0.005315156653523445,
                0.0017886534333229065,
                0.016978923231363297,
                0.06850289553403854,
                -0.0191633477807045,
                0.041253939270973206,
                0.030269427224993706,
                0.012921561487019062,
                0.09133598208427429,
                0.02857237122952938,
                0.022691600024700165,
                0.001554770627990365,
                0.006505999714136124,
                -0.07464945316314697,
                -0.0392853207886219,
                -0.05033983290195465,
                -0.005618981085717678,
                -0.009946838021278381,
                0.00522479135543108,
                -0.009574645198881626,
                -0.008203824982047081,
                0.005147834308445454,
                0.02589600160717964,
                0.024247881025075912,
                0.06393612921237946,
                0.00206689047627151,
                0.003988830838352442,
                -0.03179334104061127,
                -0.005045771133154631,
                1.0732245527833584e-06,
                -0.009682008065283298,
                0.009231282398104668,
                0.012929201126098633,
                -0.011517522856593132,
                -0.031172793358564377,
                0.005911063868552446,
                0.06049596145749092,
                0.00531734200194478,
                0.02451309561729431,
                0.03320872783660889,
                0.03477778285741806,
                0.04328199476003647,
                0.004658952355384827,
                -0.012537427246570587,
                0.02199268899857998,
                0.008732395246624947,
                -0.06163565069437027,
                -0.007791728712618351,
                0.00848319847136736,
                -0.035054367035627365,
                -0.018171723932027817,
                0.00933085661381483,
                -0.023886404931545258,
                0.03826698660850525,
                0.05872432142496109,
                -0.024784289300441742,
                -0.02317991852760315,
                -0.012939095497131348,
                0.005115584935992956,
                0.06335073709487915,
                0.06192474067211151,
                0.00031402328750118613,
                0.024940477684140205,
                -0.024396462365984917,
                -0.01976531185209751,
                -0.009477357380092144,
                -0.038138359785079956,
                -0.01392143964767456,
                0.022424006834626198,
                -0.03586619719862938,
                -0.017150698229670525,
                0.018875442445278168,
                -0.02763618901371956,
                -0.04029209166765213,
                -0.00622937735170126,
                -0.04974879324436188,
                0.016841355711221695,
                0.020769327878952026,
                0.010419433005154133,
                -0.01874665357172489,
                0.07853497564792633,
                0.0320398211479187,
                -0.00908399187028408,
                0.014156253077089787,
                0.01257165614515543,
                0.009676547721028328,
                0.018733451142907143,
                0.012436983175575733,
                -0.0032120218966156244,
                -0.03814356029033661,
                -0.032600630074739456,
                0.04529096558690071,
                0.008979488164186478,
                -0.050478655844926834,
                0.026794269680976868,
                -0.03603418543934822,
                -0.01839505322277546,
                0.004848357755690813,
                0.07123133540153503,
                0.004964584484696388,
                -0.006062058266252279,
                0.029847363010048866,
                0.0340326763689518,
                -0.006132108625024557,
                0.0040364256128668785,
                -0.0191278625279665,
                -0.04774590581655502,
                0.00012712852912954986,
                0.010563948191702366,
                -0.004191485699266195,
                0.042073991149663925,
                -0.025873621925711632,
                0.0020838764030486345,
                -0.01668822206556797,
                -0.012441144324839115,
                0.049860771745443344,
                0.005063839256763458,
                -0.03250167891383171,
                0.01571553386747837,
                0.029871834442019463,
                0.018206322565674782,
                0.0064621479250490665,
                0.004918987862765789,
                0.04479154199361801,
                0.046120692044496536,
                -0.018135445192456245,
                -0.06799793988466263,
                -0.03781852871179581,
                0.008718962781131268,
                -0.017906388267874718,
                0.016814444214105606,
                -0.01625012792646885,
                0.07158215343952179,
                -0.025141160935163498,
                0.00728385616093874,
                -0.015508510172367096,
                -0.01652873121201992,
                0.009432299993932247,
                0.07320684939622879,
                -0.030708152800798416,
                0.005975952837616205,
                -0.010501626878976822,
                -0.007082420401275158,
                0.008585379458963871,
                0.023280158638954163,
                0.006167476996779442,
                -0.005325067322701216,
                0.03766800835728645,
                -0.053396932780742645,
                0.033411696553230286,
                0.02042447030544281,
                0.015831414610147476,
                -0.0009171140263788402,
                -0.025120237842202187,
                0.033779799938201904,
                -0.021150430664420128,
                -0.019057855010032654,
                0.0013388954102993011,
                0.03840683028101921,
                -0.006859885528683662,
                -0.06344204396009445,
                -0.03304554149508476,
                -0.05791366100311279,
                -0.04498291015625,
                -0.03887995705008507,
                -0.03837193176150322,
                -0.019582552835345268,
                0.0033004076685756445,
                0.047839850187301636,
                -0.00022631781757809222,
                -0.04374540224671364,
                0.042217355221509933,
                0.03783747926354408,
                -0.08014600723981857,
                -0.005094962194561958,
                0.025635669007897377,
                -0.06797773391008377,
                0.0460919551551342,
                0.06250273436307907,
                -0.016234317794442177,
                -0.029121264815330505,
                0.003307388164103031,
                -0.007613711059093475,
                -0.043367769569158554,
                0.04066254943609238,
                0.01777764782309532,
                0.011578158475458622,
                -0.06439031660556793,
                -0.036946944892406464,
                0.0031367195770144463,
                -0.038420431315898895,
                0.011388934217393398,
                0.0039178975857794285,
                0.05041782930493355,
                -0.008184087462723255,
                -0.01112494245171547,
                0.03216034173965454,
                0.012539098970592022,
                0.02408517524600029,
                0.012536846101284027,
                -0.027250973507761955,
                0.013693219982087612,
                -0.06731811910867691,
                -0.03267698734998703,
                0.0637798011302948,
                -0.028379416093230247,
                -0.030293555930256844,
                0.06656012684106827,
                0.020372772589325905,
                -0.035764262080192566,
                -0.0394599549472332,
                0.03410334512591362,
                -0.0029368093237280846,
                -0.040139686316251755,
                -0.04459100961685181,
                -0.024661263450980186,
                0.018066057935357094,
                0.033082179725170135,
                -0.057858921587467194,
                -0.036432527005672455,
                0.06603439152240753,
                0.03979155793786049,
                -0.018191952258348465,
                0.028012989088892937,
                0.05137670785188675,
                -0.022878438234329224,
                -0.018755124881863594,
                -0.005873223301023245,
                -0.039896346628665924,
                0.03594406321644783,
                -0.06056881695985794,
                0.04220949485898018,
                -0.052442945539951324,
                0.014672973193228245,
                -0.06856657564640045,
                -0.03546790033578873,
                0.0016857561422511935,
                0.021495161578059196,
                -0.01592927984893322,
                0.02065436542034149,
                -0.003937180154025555,
                -0.0029888940043747425,
                0.02430601790547371,
                0.03007916547358036,
                -0.015644578263163567,
                -0.038916345685720444,
                0.023861778900027275,
                0.03978397324681282,
                0.06980660557746887,
                0.013490082696080208,
                0.01293940655887127,
                -0.06100325658917427,
                0.07604563236236572,
                -0.05011940747499466,
                -0.03997840732336044,
                -0.00026511831674724817,
                -0.04303915053606033,
                -0.01264783926308155,
                0.022625865414738655,
                -0.010397070087492466,
                0.0021568879019469023,
                0.005764612928032875,
                -0.011954233981668949,
                -0.03920477256178856,
                0.054665517061948776,
                -0.02132001705467701,
                0.0418739877641201,
                0.021668873727321625,
                0.00965665653347969,
                -0.010834111832082272,
                0.014706413261592388,
                -0.01190274115651846,
                -0.0009610119741410017,
                -0.08201485872268677,
                0.009964554570615292,
                0.02732120454311371,
                0.03577661141753197,
                0.0027274354360997677,
                -0.02529517374932766,
                -0.035891011357307434,
                0.007289858069270849,
                0.02552335150539875,
                0.008220046758651733,
                0.006927522365003824,
                0.032504625618457794,
                0.015273883007466793,
                -0.007894909009337425,
                0.015614328905940056,
                -0.04349634796380997,
                -0.041015736758708954,
                0.055299047380685806,
                0.005650662817060947,
                0.037391286343336105,
                0.06841354817152023,
                -0.006864248774945736,
                -0.00034503749338909984,
                -0.052511028945446014,
                0.00700311129912734,
                0.03383635729551315,
                -0.02479705959558487,
                0.046516988426446915,
                -0.02432829886674881,
                0.019918430596590042,
                -0.03236934915184975,
                -0.013924422673881054,
                -0.001738140475936234,
                0.039196837693452835,
                0.010575683787465096,
                0.004546688869595528,
                0.018949849531054497,
                -0.04763984680175781,
                -0.04108519107103348,
                0.01697339117527008,
                -0.0075728739611804485,
                0.007765032816678286,
                0.008294127881526947,
                0.03185587748885155,
                0.0586632564663887,
                -0.006851419806480408,
                0.01968185044825077,
                0.0004372381081338972,
                -0.006581802852451801,
                0.02289924956858158,
                -0.02148554101586342,
                -0.07961529493331909,
                0.03279714286327362,
                0.038471657782793045,
                -0.028933238238096237,
                -0.04293335974216461,
                0.06464647501707077,
                -0.0520748570561409,
                0.01155790314078331,
                -0.06918329745531082,
                -0.014271082356572151,
                -0.028896596282720566,
                -0.008333991281688213,
                0.00852340366691351,
                -0.023575518280267715,
                -0.004361449275165796,
                0.05585736781358719,
                0.01431618444621563,
                0.007353937719017267,
                0.03905625268816948,
                -0.018176201730966568,
                -0.005264290142804384,
                0.05425195023417473,
                -0.010119141079485416,
                0.022927701473236084,
                0.009922698140144348,
                -0.004931563511490822,
                0.040862567722797394,
                0.030893493443727493,
                0.01810324378311634,
                -0.009317228570580482,
                -0.03214827552437782,
                -0.03881709277629852,
                -0.058098189532756805,
                -0.07085837423801422,
                -0.017248965799808502,
                -0.008159893564879894,
                0.01895718462765217,
                0.05496075376868248,
                -0.02405550517141819,
                -0.014257662929594517,
                -0.006747896783053875,
                -0.01424382347613573,
                0.04000203683972359,
                -0.05313824117183685,
                0.031030206009745598,
                -0.04575563967227936,
                -0.013692337088286877,
                -0.03316217660903931,
                0.02641178108751774,
                -0.030974261462688446,
                -0.006658170372247696,
                0.032042246311903,
                -0.020992355421185493,
                0.05811718478798866,
                -0.020083831623196602,
                0.02330869808793068,
                -0.029734676703810692,
                0.02330472320318222,
                -0.01927839033305645,
                -0.01940062642097473,
                -0.04736282303929329,
                0.008218544535338879,
                0.0050772749818861485,
                -0.06153427064418793,
                0.03727417811751366,
                0.008124887943267822,
                0.003974304534494877,
                0.018706614151597023,
                -0.014308149926364422,
                0.03050326183438301,
                0.00699688820168376,
                0.06089460477232933,
                -0.026787690818309784,
                0.010905089788138866,
                0.03591443598270416,
                -0.0049085416831076145,
                0.018649540841579437,
                0.04832053557038307,
                -0.03259874880313873,
                -0.0872095376253128,
                0.017079537734389305,
                -0.02234899252653122,
                -0.02237928844988346,
                0.007516153622418642,
                -0.012408417649567127,
                -0.03216151148080826,
                -0.03899138793349266,
                -0.02347126603126526,
                0.011875597760081291,
                0.04507574811577797,
                -0.06113709509372711,
                0.016307927668094635,
                -0.008358283899724483,
                0.03683307021856308,
                -0.03788848966360092,
                0.03243870660662651,
                -0.033846788108348846,
                -0.049048226326704025,
                0.030200572684407234,
                0.0010903449729084969,
                -0.059292394667863846,
                -0.0052388920448720455,
                -0.021488836035132408,
                -0.029110794886946678,
                0.005371102597564459,
                0.027834858745336533,
                -0.043416284024715424,
                0.023453807458281517,
                0.0352320671081543,
                -0.06573173403739929,
                0.06598348915576935,
                -0.023656334728002548,
                -0.024300070479512215,
                -0.004614770878106356,
                0.009185977280139923,
                0.056300241500139236,
                0.11657115817070007,
                -0.0280578825622797,
                -0.006873367354273796,
                -0.015588541515171528,
                -0.02984948456287384,
                0.07492827624082565,
                -0.08028700202703476,
                -0.030297551304101944,
                -0.05616755411028862,
                -0.016864337027072906,
                -0.09734974801540375,
                -0.09754850715398788,
                0.06288394331932068,
                -0.009980257600545883,
                0.0328543521463871,
                0.015248379670083523,
                0.011550604365766048,
                0.013881615363061428,
                -0.013210183940827847,
                -0.02907327190041542,
                -0.05205999314785004,
                0.049979034811258316,
                0.007667865138500929,
                0.05234609544277191,
                0.007124332711100578,
                -0.04650813713669777,
                -0.016047343611717224,
                -0.015454261563718319,
                -0.011409658938646317,
                0.019075453281402588,
                0.017153484746813774,
                0.06022704392671585,
                0.031428880989551544,
                -0.022973380982875824,
                0.07038608193397522,
                -0.003913917113095522,
                -0.004121806938201189,
                0.023990986868739128,
                0.004617983940988779,
                -0.0020715179853141308,
                0.03896376118063927,
                0.032587114721536636,
                0.000626720953732729,
                0.009240055456757545,
                -0.08552581816911697,
                0.061696313321590424,
                -0.05418208986520767,
                0.0032783434726297855,
                -0.06848843395709991,
                0.06955564767122269,
                0.019403429701924324,
                0.0008885826682671905,
                0.0547306090593338,
                -0.05369420349597931,
                0.03293946757912636,
                -0.03375611826777458,
                -0.018211951479315758,
                0.01488429680466652,
                -0.05808333307504654,
                0.01916477084159851,
                0.013488578610122204,
                -0.031108932569622993,
                -0.009487954899668694,
                0.023532694205641747,
                0.006944794207811356,
                0.031153131276369095,
                0.02444942481815815,
                0.0029294476844370365,
                0.0625263899564743,
                0.005832983646541834,
                0.007916608825325966,
                0.01211122702807188,
                0.011985663324594498,
                0.055727556347846985,
                0.005930997896939516,
                -0.013653408735990524,
                -0.0443950854241848,
                0.052773524075746536,
                -0.016392085701227188,
                0.03790910169482231,
                -0.0075988550670444965,
                0.032153453677892685,
                0.042425286024808884,
                0.03550349175930023,
                -0.002767915138974786,
                0.011483761481940746,
                -0.015789629891514778,
                -0.0008947449387051165,
                -0.003883481491357088,
                0.011876894161105156,
                0.04075973853468895,
                0.035829052329063416,
                0.003389808116480708,
                0.023696381598711014,
                0.08495861291885376,
                -0.018811989575624466,
                0.0009664726094342768,
                0.061778776347637177,
                0.04085783287882805,
                0.0020870803855359554,
                -0.07101690024137497,
                0.07399497926235199,
                0.008534397929906845,
                -0.0007499447092413902,
                0.04694418981671333,
                0.043197087943553925,
                0.01462478656321764,
                0.02856142446398735,
                0.036062370985746384,
                -0.018324146047234535,
                -4.921333832702205e-33,
                -0.0684918686747551,
                -0.0017203378956764936,
                0.04184390604496002,
                0.01731625385582447,
                0.00601416639983654,
                -0.0007971904706209898,
                0.015167330391705036,
                0.00931626744568348,
                -0.02281847968697548,
                -0.04529070109128952,
                -0.028998013585805893,
                0.007152831181883812,
                0.008365855552256107,
                0.030845882371068,
                0.032037217170000076,
                0.03546779602766037,
                0.036130260676145554,
                0.08595654368400574,
                0.023795800283551216,
                -0.018385883420705795,
                -0.02166195586323738,
                0.020336680114269257,
                0.006340065971016884,
                -0.005994217004626989,
                -0.0031026958022266626,
                0.03665848448872566,
                -0.02329663373529911,
                -0.06556283682584763,
                -0.03684155270457268,
                -0.016254756599664688,
                0.005525078158825636,
                0.018487300723791122,
                0.0218771044164896,
                0.06002693623304367,
                -0.0029120356775820255,
                -0.0007787858485244215,
                -0.0014685157220810652,
                0.005100295878946781,
                -0.099595807492733,
                0.02696882002055645,
                0.04147348180413246,
                -0.019143013283610344,
                0.011807729490101337,
                -0.0025882001500576735,
                -0.11937392503023148,
                -0.036484986543655396,
                -0.008625626564025879,
                0.04178546741604805,
                0.05067092180252075,
                0.02039879932999611,
                -0.003386289346963167,
                -0.018992260098457336,
                -0.04635995626449585,
                0.023315461352467537,
                -0.07399415224790573,
                -0.07069279253482819,
                -0.014117729850113392,
                0.0006128950044512749,
                -0.008175755850970745,
                0.007384779397398233,
                0.023556115105748177,
                0.08681825548410416,
                0.08425310254096985,
                -0.07837837189435959,
                0.00408595334738493,
                -0.0024784542620182037,
                -0.01209981832653284,
                0.01898529753088951,
                0.06598284840583801,
                -0.04617394134402275,
                -0.07347863912582397,
                0.07121428102254868,
                -0.06780152022838593,
                -0.047458939254283905,
                -0.060561653226614,
                -0.07585170865058899,
                -0.03162287548184395,
                -0.00855825375765562,
                0.024572333320975304,
                0.042262643575668335,
                -0.07147041708230972,
                0.041684187948703766,
                0.03480400890111923,
                0.022006604820489883,
                0.023675067350268364,
                0.023610511794686317,
                0.02290753647685051,
                0.0036064046435058117,
                0.014502150937914848,
                -0.01757814735174179,
                4.617361992131919e-05,
                0.012643003836274147,
                -0.02940087579190731,
                -0.01815072074532509,
                0.05684254318475723,
                -0.028960514813661575,
                0.012249275110661983,
                -0.0027960878796875477,
                0.014425433240830898,
                0.06108194962143898,
                -0.022816626355051994,
                -0.01093687117099762,
                -0.019235124811530113,
                -0.020566176623106003,
                -0.000206996439374052,
                0.05245019495487213,
                -0.046267636120319366,
                0.012899129651486874,
                -0.0023278759326785803,
                -0.020531728863716125,
                -0.0184189323335886,
                -0.03350858390331268,
                -0.05238186568021774,
                0.02717548981308937,
                0.031560223549604416,
                0.009641113691031933,
                0.02816254086792469,
                -0.07499417662620544,
                -0.06325343996286392,
                -0.001672013895586133,
                0.041664768010377884,
                -0.05922938510775566,
                0.0014247506624087691,
                -0.016006268560886383,
                0.008272084407508373,
                -0.013631505891680717,
                0.012273645028471947,
                -0.024051785469055176,
                -0.035685084760189056,
                -0.008329150266945362,
                0.014546793885529041,
                0.00709637301042676,
                1.6787041090537969e-07,
                -0.007204036228358746,
                0.051006563007831573,
                0.02892361208796501,
                0.06953684985637665,
                -0.030935700982809067,
                -0.038994207978248596,
                0.0005886167054995894,
                0.01080044824630022,
                0.019852295517921448,
                0.040848102420568466,
                -0.0705493688583374,
                -0.03288881480693817,
                -0.032452646642923355,
                -0.12532733380794525,
                -0.024787645787000656,
                -0.046281661838293076,
                0.013888235203921795,
                0.028980327770113945,
                0.0008719441248103976,
                0.016936903819441795,
                0.01937384530901909,
                0.037017300724983215,
                0.010869607329368591,
                -0.006092344410717487,
                -0.030801642686128616,
                0.015541650354862213,
                0.00949784368276596,
                0.00794133823364973,
                0.08190569281578064,
                0.004642349202185869,
                -0.005456012673676014,
                0.024066902697086334,
                0.031325869262218475,
                0.07352561503648758,
                0.025376303121447563,
                -0.00977367628365755,
                0.047662246972322464,
                0.01799570769071579,
                0.019409988075494766,
                0.04438765347003937,
                -0.04787076264619827,
                0.03340037539601326,
                0.011199994944036007,
                0.008695365861058235,
                0.009217035956680775,
                0.02254544571042061,
                -0.03332149237394333,
                -0.0008820996154099703,
                -0.07758177816867828,
                -0.061348773539066315,
                -0.08859852701425552,
                0.056477613747119904,
                -0.013850104063749313,
                -0.10886472463607788,
                0.011432643048465252,
                -0.022224895656108856,
                -0.0009020389406941831,
                0.05106279253959656,
                0.033329565078020096,
                -0.0027562135364860296,
                -0.007210440002381802,
                -0.06849522143602371,
                0.006880062632262707,
                0.04345361515879631,
                0.09940522909164429,
                -0.01859029196202755,
                -0.02084650658071041,
                1.0509760939084798e-34,
                -0.00645671971142292,
                -0.010101421736180782,
                0.007346094585955143,
                0.056752610951662064,
                -0.02379007264971733,
                -0.02528414875268936,
                0.013703204691410065,
                -0.046639177948236465,
                -0.03405648469924927,
                -0.030994223430752754,
                -0.04289327561855316
            ]
        },
        "Experience": {
            "name": "Determined, agile and forward thinking leader, coach and operations professional, with wide experience in defence (submarines), project management, and commercial (Shipping, Oil & Gas) industries. Intellectually capable with the ability to assimilate information rapidly, provide time sensitive analysis and respond effectively with confidence and decisiveness. Key success factors in all roles have always been by facilitating dialogue, establishing team trust and driving change.\n",
            "rep": [
                0.043390002101659775,
                0.01559504121541977,
                0.0032823439687490463,
                -0.030290136113762856,
                0.023924533277750015,
                0.00545346038416028,
                -0.055322419852018356,
                -0.033523641526699066,
                -0.058545928448438644,
                0.011866913177073002,
                0.02454877458512783,
                -0.02005329355597496,
                -0.002281761495396495,
                0.05514402315020561,
                0.04533419758081436,
                -0.032390296459198,
                0.011840896680951118,
                0.0069616734981536865,
                -0.03361405432224274,
                0.022257190197706223,
                -0.044113509356975555,
                0.024370554834604263,
                -0.049989838153123856,
                0.011198257096111774,
                -0.01847284846007824,
                0.014207822270691395,
                -0.007400318514555693,
                0.013259260915219784,
                0.008882764726877213,
                0.009046000428497791,
                0.05225776880979538,
                -0.027031436562538147,
                0.010141033679246902,
                -0.05498487874865532,
                1.747603278090537e-06,
                0.027499785646796227,
                -0.010976037010550499,
                -0.04289957135915756,
                0.005874448921531439,
                -0.03372948616743088,
                -0.006623866967856884,
                0.002775106579065323,
                -0.0022466329392045736,
                0.02799779362976551,
                0.004488581325858831,
                0.04140078276395798,
                0.018918272107839584,
                0.01842164434492588,
                0.01798124425113201,
                0.04973626136779785,
                0.019715627655386925,
                0.007295730523765087,
                -0.05871449410915375,
                -0.016113219782710075,
                -0.05206089839339256,
                -0.09301993250846863,
                -0.02190854214131832,
                -0.010820062831044197,
                -0.028696035966277122,
                0.018570665270090103,
                0.005796932149678469,
                -0.0015129258390516043,
                -0.03294922038912773,
                -0.00886580254882574,
                -0.00067715352633968,
                0.03516359627246857,
                0.006712701637297869,
                -0.02391849085688591,
                0.01968010514974594,
                -0.002688528737053275,
                0.08748458325862885,
                -0.0011574168456718326,
                0.009292581118643284,
                0.04581788182258606,
                0.05069263279438019,
                0.008618774823844433,
                0.013053102418780327,
                -0.01483830250799656,
                -0.027131959795951843,
                -0.0024179723113775253,
                -0.03721078485250473,
                0.08838334679603577,
                -0.016297731548547745,
                -0.04984075576066971,
                0.006834083702415228,
                0.0743938684463501,
                -0.01260450854897499,
                0.025573551654815674,
                -0.00971428956836462,
                0.01784457638859749,
                -0.015723956748843193,
                0.010300730355083942,
                0.00436444440856576,
                0.024216575548052788,
                -0.046145278960466385,
                -0.01092743780463934,
                0.04083901643753052,
                0.005392300430685282,
                -0.005150483921170235,
                -0.016935130581259727,
                -0.033312760293483734,
                0.0285990871489048,
                0.024341637268662453,
                0.01582540012896061,
                -0.02566702477633953,
                0.06725238263607025,
                0.002292606048285961,
                -0.014145956374704838,
                -0.029416147619485855,
                -0.04448216035962105,
                -0.03263038024306297,
                -0.012711460702121258,
                -0.01504203025251627,
                -0.003767076414078474,
                0.05250345915555954,
                -0.023148922249674797,
                -0.035607289522886276,
                0.0014592616353183985,
                0.02313336916267872,
                0.0329761877655983,
                0.03472130745649338,
                0.025651397183537483,
                -0.02040264569222927,
                0.03816736862063408,
                -0.04056341201066971,
                0.02339477278292179,
                -0.012265716679394245,
                0.0016457450110465288,
                0.013353463262319565,
                0.03986015170812607,
                -0.008571824990212917,
                -0.01217377558350563,
                -0.027517545968294144,
                -0.01293249987065792,
                0.05157093331217766,
                0.02703331783413887,
                -0.030395440757274628,
                -0.011372838169336319,
                -0.052247386425733566,
                0.023857982829213142,
                -0.0006610285490751266,
                -0.035766344517469406,
                -0.055801525712013245,
                0.024513093754649162,
                -0.015845736488699913,
                -0.02368984930217266,
                -0.0017196942353621125,
                -0.0051284655928611755,
                0.011747850105166435,
                0.00687807472422719,
                0.05592644587159157,
                -0.07239381968975067,
                0.008413008414208889,
                0.021839385852217674,
                0.011553165502846241,
                0.037601176649332047,
                0.002705872058868408,
                -0.016533328220248222,
                -0.019293025135993958,
                0.021333778277039528,
                0.012941926717758179,
                -0.008931199088692665,
                -0.005053799599409103,
                0.036171745508909225,
                -0.04028784856200218,
                0.006950849667191505,
                0.071550153195858,
                0.007101345341652632,
                -0.007307777181267738,
                -0.0047034407034516335,
                0.0359654501080513,
                -0.024747995659708977,
                -0.0015364965656772256,
                0.04583755135536194,
                0.04461722448468208,
                -0.08285229653120041,
                0.06797536462545395,
                0.013320227153599262,
                0.05653471499681473,
                -0.007749122101813555,
                0.015102910809218884,
                -0.13700184226036072,
                0.027125881984829903,
                -0.003127009840682149,
                0.10304833948612213,
                0.016394706442952156,
                -0.011036072857677937,
                0.07111284881830215,
                -0.01310943253338337,
                -0.009488407522439957,
                0.0430888906121254,
                0.019801678135991096,
                -0.07125519216060638,
                0.03504800796508789,
                0.00648467754945159,
                0.022089969366788864,
                -0.0711539015173912,
                9.39730234676972e-05,
                -0.06091191992163658,
                -0.029102113097906113,
                0.022153286263346672,
                0.003369899932295084,
                -0.04893068969249725,
                0.0050583891570568085,
                0.03458197042346001,
                -0.02060665562748909,
                -0.03717584162950516,
                -0.04992358759045601,
                -0.030889004468917847,
                -0.009248722344636917,
                0.04439391940832138,
                -0.0016108130803331733,
                0.03097028285264969,
                0.02168440632522106,
                -0.021394843235611916,
                0.03967563807964325,
                0.02377796731889248,
                0.008650603704154491,
                0.03936784341931343,
                0.024541441351175308,
                -0.002746672835201025,
                0.05152343586087227,
                -0.03419242426753044,
                -0.0006375268567353487,
                0.07472767680883408,
                -0.07427389174699783,
                -0.010192315094172955,
                -0.010331852361559868,
                -0.001207039225846529,
                0.08012227714061737,
                -0.014916665852069855,
                0.025317108258605003,
                -0.03545033559203148,
                -0.020820295438170433,
                -0.04443884268403053,
                0.03409544378519058,
                0.044602710753679276,
                0.12467020004987717,
                0.010956388898193836,
                -0.03866593912243843,
                -0.01280516292899847,
                0.0251631960272789,
                0.04727337509393692,
                -0.014765487983822823,
                0.0044516813941299915,
                -0.013590640388429165,
                -0.01538369432091713,
                0.048737265169620514,
                0.022601086646318436,
                0.008011682890355587,
                -0.007230670191347599,
                0.03756106644868851,
                -0.002483530668541789,
                0.051131200045347214,
                -0.023887740448117256,
                0.038187988102436066,
                -0.015874972566962242,
                0.0449875071644783,
                -0.07463528960943222,
                0.006001784931868315,
                0.02334609068930149,
                -0.017292723059654236,
                -0.03847106173634529,
                0.038881611078977585,
                0.026314031332731247,
                -0.03607723489403725,
                0.017252255231142044,
                -0.03415406867861748,
                -0.006268380209803581,
                -0.025026889517903328,
                0.044901639223098755,
                0.006901028100401163,
                -0.010463732294738293,
                -0.03934592753648758,
                0.008033082820475101,
                0.012883820571005344,
                -0.028849897906184196,
                0.03995494171977043,
                -0.06236585974693298,
                0.03533150628209114,
                0.04976705461740494,
                -0.058712251484394073,
                -0.04643506929278374,
                0.022486628964543343,
                0.011412562802433968,
                0.016267484053969383,
                0.06264497339725494,
                0.039302024990320206,
                -0.05842708423733711,
                0.02330051362514496,
                -0.033279236406087875,
                -0.03659079223871231,
                0.009597056545317173,
                -0.07861817628145218,
                0.023551389575004578,
                0.018550343811511993,
                0.008788147941231728,
                0.005276517942547798,
                0.022540360689163208,
                0.03526239097118378,
                -0.019296567887067795,
                -0.07278140634298325,
                0.05542920157313347,
                -0.0010596407810226083,
                -0.055207349359989166,
                -0.010170208290219307,
                -0.0004950538859702647,
                0.045266132801771164,
                -0.03074655495584011,
                -0.01786329224705696,
                0.004402421414852142,
                0.01846954971551895,
                0.014965765178203583,
                -0.031519509851932526,
                0.03405066579580307,
                0.0013495349558070302,
                -0.025455575436353683,
                -0.030723240226507187,
                -0.012325898744165897,
                0.07386147975921631,
                0.018920091912150383,
                0.06050955876708031,
                -0.05183203145861626,
                -0.03518865630030632,
                0.02599826455116272,
                -0.03595331311225891,
                -0.06079133227467537,
                -0.03869139775633812,
                0.03802895545959473,
                -0.09226465225219727,
                -0.024970050901174545,
                1.66628433362348e-05,
                -0.021395422518253326,
                0.02451164834201336,
                -0.02136172726750374,
                -0.007345213554799557,
                -0.02296820655465126,
                -0.06790616363286972,
                -0.022786414250731468,
                -0.02566584013402462,
                -0.010757917538285255,
                0.041739508509635925,
                -0.03692999854683876,
                0.0075403559021651745,
                0.013287455774843693,
                -0.006431540474295616,
                0.05850910395383835,
                -0.024809053167700768,
                -0.08038189262151718,
                -0.03350162133574486,
                0.09878742694854736,
                0.032851122319698334,
                -0.018561191856861115,
                -0.007014669477939606,
                -0.0430905781686306,
                -0.021871043369174004,
                -0.003606064012274146,
                0.006310776341706514,
                0.05352155864238739,
                -0.010119118727743626,
                -0.028958672657608986,
                -0.017674770206212997,
                0.03957032412290573,
                -0.038391269743442535,
                0.001977949170395732,
                -0.0321996845304966,
                0.02004823461174965,
                0.010385881178081036,
                -0.003289006184786558,
                -0.0026047690771520138,
                0.03558996319770813,
                0.009871519170701504,
                -0.015499169938266277,
                -0.0109175443649292,
                0.0031582065857946873,
                0.0019770669750869274,
                -0.0029468382708728313,
                0.047461219131946564,
                0.01451025903224945,
                0.027561761438846588,
                0.05108107626438141,
                0.030059870332479477,
                0.05638303980231285,
                -0.029790567234158516,
                -0.0014916356885805726,
                -0.052552931010723114,
                0.009988754987716675,
                -0.04941979795694351,
                0.051702067255973816,
                0.03005213290452957,
                0.011545367538928986,
                0.004831943195313215,
                -0.07375504076480865,
                -0.01669762097299099,
                -0.0345199890434742,
                -0.021098587661981583,
                -0.036686189472675323,
                0.0218693595379591,
                -0.009510032832622528,
                0.007973701693117619,
                0.03822537139058113,
                -0.011373281478881836,
                -0.021191667765378952,
                0.05235908180475235,
                -0.04267938807606697,
                -0.062078725546598434,
                0.04549302160739899,
                -0.005116312298923731,
                0.0023462031967937946,
                0.04224430024623871,
                -0.08749877661466599,
                -0.005459325388073921,
                0.011468522250652313,
                0.04597708582878113,
                -0.015035782940685749,
                -0.08321594446897507,
                -0.022302204743027687,
                -0.038315549492836,
                0.009836839511990547,
                -0.03452621027827263,
                0.043652430176734924,
                0.014897504821419716,
                0.023615796118974686,
                -0.014465602114796638,
                0.06661123782396317,
                -0.03473852574825287,
                0.04964732006192207,
                0.044461920857429504,
                -0.009444709867238998,
                -0.04532795771956444,
                -0.037252359092235565,
                0.01691090129315853,
                0.043142031878232956,
                0.06465096771717072,
                0.014437239617109299,
                -0.09809412062168121,
                0.033107828348875046,
                0.02875751629471779,
                0.018636520951986313,
                0.029564784839749336,
                -0.0026496914215385914,
                0.02795570343732834,
                0.06523328274488449,
                0.011775335296988487,
                -0.008273692801594734,
                -0.1306319236755371,
                0.016618333756923676,
                0.021310700103640556,
                -0.00947178341448307,
                0.012988347560167313,
                0.08795687556266785,
                -0.04614434391260147,
                -0.013025136664509773,
                0.0241254810243845,
                -0.026947950944304466,
                0.005235061049461365,
                -0.04114643111824989,
                -0.025225546211004257,
                0.01680603250861168,
                0.00256786379031837,
                0.013312503695487976,
                0.03693130612373352,
                -0.058911703526973724,
                0.07061099261045456,
                -0.019254835322499275,
                -0.0001690395874902606,
                -0.026669126003980637,
                0.010538499802350998,
                0.007414964493364096,
                -0.0538177527487278,
                0.07142312824726105,
                -0.03259916231036186,
                -0.02926637977361679,
                -0.02497793734073639,
                -0.011058911681175232,
                -0.06049486622214317,
                -0.019317304715514183,
                0.0502445362508297,
                -0.016091009601950645,
                0.03415019437670708,
                -0.004760123789310455,
                -0.020870888605713844,
                -0.034450680017471313,
                -0.029933789744973183,
                0.00711330771446228,
                -0.022124188020825386,
                -0.011861621402204037,
                0.006950322538614273,
                0.01855621114373207,
                0.014482525177299976,
                0.006021664012223482,
                -0.02072685956954956,
                0.005692309699952602,
                -0.019452117383480072,
                0.008869688026607037,
                0.04489067196846008,
                -0.009541796520352364,
                0.04451917111873627,
                0.03092784807085991,
                -0.07656023651361465,
                -0.02016369253396988,
                1.735492332954891e-05,
                0.007906605489552021,
                -0.03770589828491211,
                -0.0027783226687461138,
                -0.014537480659782887,
                0.024888763204216957,
                0.03875815495848656,
                -0.02227923646569252,
                0.008922207169234753,
                -0.051589298993349075,
                -0.03445848822593689,
                -0.07464225590229034,
                -0.04829701781272888,
                0.030810588970780373,
                -0.019740264862775803,
                0.048513758927583694,
                0.018542781472206116,
                -0.0019557634368538857,
                -0.06562992930412292,
                0.03615303337574005,
                0.011330854147672653,
                -0.044114019721746445,
                -0.05248364061117172,
                0.04159969463944435,
                0.02866429090499878,
                0.009774846024811268,
                0.03141527250409126,
                0.00211686291731894,
                0.0019932053983211517,
                0.02479882352054119,
                -0.0093923294916749,
                -0.06444013863801956,
                0.0023262479808181524,
                -0.01792413927614689,
                0.001349952770397067,
                0.050367895513772964,
                0.07400912046432495,
                0.02164219692349434,
                0.008701831102371216,
                -0.057736556977033615,
                0.034097593277692795,
                0.05707339942455292,
                0.0718115046620369,
                0.00012258277274668217,
                -0.02097131311893463,
                -0.05317053198814392,
                0.027334023267030716,
                0.0019346550107002258,
                -0.013110886327922344,
                -0.005658106878399849,
                -0.09319688379764557,
                -0.015585393644869328,
                0.04628773406147957,
                0.008364281617105007,
                -5.096997137095195e-33,
                -0.02745184488594532,
                -0.026956571266055107,
                0.07332649827003479,
                0.0007903184741735458,
                -0.06884314119815826,
                0.0039497497491538525,
                0.03260939568281174,
                -0.0028415420092642307,
                -0.04859204962849617,
                -0.03644184395670891,
                -0.03321852535009384,
                -0.010427639819681644,
                0.010523680597543716,
                -0.009829169139266014,
                0.01484327670186758,
                0.04424994811415672,
                0.019117722287774086,
                -0.01589222624897957,
                0.005227177869528532,
                -0.0065033892169594765,
                -0.011615698225796223,
                0.0019102776423096657,
                -0.005836039781570435,
                0.012664959765970707,
                0.050110507756471634,
                -0.016226910054683685,
                -0.03677275404334068,
                0.02772969752550125,
                0.0438733734190464,
                -0.017970649525523186,
                0.019628971815109253,
                -0.0415482260286808,
                -0.0001439198385924101,
                -0.003646640805527568,
                0.010670294985175133,
                -0.01968676783144474,
                -0.020996492356061935,
                0.01104707084596157,
                -0.00553378788754344,
                0.03290506824851036,
                0.0024415154475718737,
                0.013503299094736576,
                0.04528272897005081,
                -0.018205055966973305,
                0.005161608103662729,
                -0.05706412345170975,
                -0.0050087738782167435,
                0.031195634976029396,
                0.059794168919324875,
                -0.011409127153456211,
                -0.057304807007312775,
                0.017435887828469276,
                -0.041452325880527496,
                0.041553858667612076,
                -0.014817426912486553,
                0.031155865639448166,
                -0.021769260987639427,
                -0.0387926883995533,
                -0.05355112999677658,
                -0.005487165413796902,
                -0.04817524924874306,
                0.023060942068696022,
                0.04966326430439949,
                0.04852500185370445,
                -0.03507862240076065,
                0.010907617397606373,
                0.0694330632686615,
                0.06051439791917801,
                0.006289873272180557,
                -0.04409394413232803,
                -0.0331403985619545,
                0.10245048999786377,
                0.005016895476728678,
                0.03410385921597481,
                -0.054401494562625885,
                -0.011196247301995754,
                -0.012029684148728848,
                0.0035226468462496996,
                0.10399216413497925,
                -0.029673801735043526,
                -0.0172968041151762,
                0.008685828186571598,
                -0.03910043463110924,
                0.01617313176393509,
                -0.002300472464412451,
                -0.028454426676034927,
                -0.006149780470877886,
                -0.017080584540963173,
                0.009324759244918823,
                0.00013748594210483134,
                -0.031189270317554474,
                0.03129966929554939,
                -0.003340312046930194,
                0.027100808918476105,
                0.10320062935352325,
                -0.023313680663704872,
                -0.0165176372975111,
                -0.01599392667412758,
                -0.017201580107212067,
                -0.019875848665833473,
                -0.01392421405762434,
                0.06455892324447632,
                -0.006526959128677845,
                -0.021764062345027924,
                0.01267332024872303,
                0.0024182440247386694,
                -0.08961327373981476,
                0.0520675890147686,
                -0.016799982637166977,
                -0.026698317378759384,
                0.014569218270480633,
                0.010651487857103348,
                0.006235575769096613,
                -0.06958010047674179,
                -0.054485537111759186,
                -0.03670109435915947,
                0.0009604210499674082,
                -0.012962955050170422,
                -0.0004597664810717106,
                -0.06502112001180649,
                0.04580482840538025,
                0.02098075859248638,
                0.010587839409708977,
                0.007971248589456081,
                -0.04146247357130051,
                -0.03448047861456871,
                0.03569187968969345,
                0.017666848376393318,
                -0.012240119278430939,
                -0.004017611034214497,
                -0.007086354773491621,
                0.0037954729050397873,
                2.3809955962406093e-07,
                0.023233335465192795,
                0.02673763781785965,
                0.02661292627453804,
                0.029500680044293404,
                0.004484797362238169,
                -0.012924207374453545,
                -0.01595851220190525,
                -0.002698018215596676,
                -0.02059732750058174,
                0.004642346408218145,
                0.08373359590768814,
                0.024544620886445045,
                -0.0803728997707367,
                0.00970273744314909,
                0.03104344755411148,
                -0.09452764689922333,
                0.016767529770731926,
                -0.11017469316720963,
                -0.0249019768089056,
                -0.0654255673289299,
                0.09383145719766617,
                -0.0015714161563664675,
                0.004670031368732452,
                -0.0378718227148056,
                0.005372305400669575,
                0.009079844690859318,
                -0.015700481832027435,
                -0.0029013461899012327,
                0.03243691101670265,
                0.04880537465214729,
                0.05161872133612633,
                -0.040256060659885406,
                0.014195730909705162,
                0.045764725655317307,
                0.013981310650706291,
                -0.003715943545103073,
                0.04445720091462135,
                0.04474859684705734,
                0.028347760438919067,
                0.018678095191717148,
                -0.03131027892231941,
                -0.0009087834623642266,
                -0.021215079352259636,
                -0.0009040287113748491,
                -0.0014430154114961624,
                -0.040184199810028076,
                -0.029589369893074036,
                -0.007407227996736765,
                -0.0952966958284378,
                -0.01952013187110424,
                -0.009394748136401176,
                0.0584709569811821,
                0.0016027877572923899,
                0.01904800720512867,
                0.0034374562092125416,
                -0.006163174752146006,
                -0.048045940697193146,
                0.01537122018635273,
                0.0492173470556736,
                -0.0030594461131840944,
                -0.030475113540887833,
                -0.01753310300409794,
                -0.012011230923235416,
                -0.021151665598154068,
                0.04242110252380371,
                0.03484807908535004,
                0.0024443333968520164,
                1.927712700956403e-34,
                0.003128639655187726,
                -0.0008613799582235515,
                -0.036261286586523056,
                0.001230582594871521,
                0.07727797329425812,
                -0.05001870542764664,
                -0.022582927718758583,
                -0.0028307188767939806,
                0.024680370464920998,
                0.015582159161567688,
                -0.028683219105005264
            ]
        },
        "Freelance": "Open to both",
        "Id": "6080dcc6-2c67-421e-aa2e-b8661440c48d",
        "Industry": {
            "name": "\tBusiness",
            "rep": [
                0.043107323348522186,
                0.041932884603738785,
                -0.007384799420833588,
                0.03133811429142952,
                0.01970391906797886,
                -0.0036362381651997566,
                0.05262527987360954,
                -0.004365634638816118,
                -0.039470698684453964,
                0.020421190187335014,
                0.051629818975925446,
                -0.02408663183450699,
                0.02999422885477543,
                0.08117306232452393,
                0.01334631908684969,
                0.022682562470436096,
                -0.0024797176010906696,
                0.01849457062780857,
                0.01242954470217228,
                -0.015798307955265045,
                -0.02742321789264679,
                0.030465908348560333,
                0.05463472381234169,
                -0.028198128566145897,
                0.02104838751256466,
                -0.009543216787278652,
                0.04667770117521286,
                0.0037125470116734505,
                -0.01776972971856594,
                0.019273880869150162,
                0.06287536025047302,
                -0.015326930209994316,
                0.018795233219861984,
                -0.021578602492809296,
                2.077298631775193e-06,
                -0.026611952111124992,
                -0.0400216169655323,
                0.009770991280674934,
                -0.08688123524188995,
                -0.014089783653616905,
                0.0028935412410646677,
                0.05636492371559143,
                -0.03369859606027603,
                0.04594637453556061,
                -0.02204824611544609,
                0.012847892940044403,
                0.013829309493303299,
                0.01775244250893593,
                -0.03140269219875336,
                0.01967070810496807,
                -0.007183928042650223,
                -0.04386594519019127,
                -0.1089373454451561,
                -0.04829937219619751,
                -0.0770169198513031,
                -0.04506230726838112,
                0.0465955026447773,
                0.013136080466210842,
                -0.009590242058038712,
                0.01163342036306858,
                0.005412649363279343,
                -0.03132571280002594,
                -0.015676310285925865,
                0.033974528312683105,
                0.04096580296754837,
                0.041222840547561646,
                0.08266788721084595,
                -0.021341724321246147,
                0.02427002042531967,
                -0.0015009143389761448,
                0.006424320861697197,
                0.031750962138175964,
                -0.008059381507337093,
                0.09565281867980957,
                0.0022320891730487347,
                -0.0860120952129364,
                -0.01965969242155552,
                0.04132649302482605,
                0.014047067612409592,
                0.006893696263432503,
                -0.02979961782693863,
                0.021641826257109642,
                0.0019229177851229906,
                0.08527813106775284,
                0.001888760831207037,
                0.0044697304256260395,
                -0.03033641166985035,
                0.010241765528917313,
                -0.022100625559687614,
                -0.009258787147700787,
                0.025856759399175644,
                -0.0209158044308424,
                0.03013797104358673,
                0.012253062799572945,
                0.009570572525262833,
                -0.03143993020057678,
                0.017288485541939735,
                0.10513869673013687,
                -0.0186112429946661,
                -0.03476894646883011,
                -0.0515577532351017,
                -0.002859254367649555,
                0.013888456858694553,
                0.025807827711105347,
                -0.007817711681127548,
                0.011807029135525227,
                0.021564124152064323,
                -0.024972446262836456,
                -0.017075713723897934,
                0.0754738375544548,
                -0.02112860046327114,
                0.0005567100015468895,
                -0.06857258826494217,
                0.042555149644613266,
                -0.04945015534758568,
                -0.01092981081455946,
                -0.023148978129029274,
                0.05002657696604729,
                -0.04332387447357178,
                0.03944888710975647,
                0.056029774248600006,
                0.029562469571828842,
                -0.009706632234156132,
                0.023172728717327118,
                0.024552389979362488,
                0.002022320404648781,
                -0.05924983322620392,
                0.030616454780101776,
                0.02830566093325615,
                0.013042998500168324,
                -0.0008048537420108914,
                -0.0006195840542204678,
                -0.002275100676342845,
                -0.022134611383080482,
                0.03921809792518616,
                -0.014871159568428993,
                0.002598281716927886,
                0.026318706572055817,
                -0.07985575497150421,
                -0.04173915460705757,
                -0.037058718502521515,
                -0.05809018015861511,
                -0.02994328923523426,
                0.024781228974461555,
                -0.02037348784506321,
                -0.004169674124568701,
                0.01704566553235054,
                0.05270786210894585,
                0.005074852611869574,
                -0.013558430597186089,
                -0.009171097539365292,
                0.010182513855397701,
                -0.00787291955202818,
                -0.023794053122401237,
                0.0852428749203682,
                0.03535729646682739,
                0.0957665964961052,
                -0.022520793601870537,
                -0.0020210030488669872,
                -0.011496715247631073,
                0.02251202054321766,
                0.016831664368510246,
                0.005957904737442732,
                0.009586905129253864,
                -0.03636515885591507,
                -0.012218842282891273,
                0.06791388988494873,
                0.0321865975856781,
                -0.06582581996917725,
                -0.010881168767809868,
                -0.0044505055993795395,
                -0.05956219509243965,
                -0.06468488276004791,
                0.1075667291879654,
                0.014296788722276688,
                -0.047451529651880264,
                -0.004842291120439768,
                0.027141768485307693,
                -0.07672885060310364,
                0.008428610861301422,
                -0.032659221440553665,
                -0.1363135278224945,
                -0.009255154058337212,
                -0.001253583119250834,
                0.0020028913859277964,
                0.01258697360754013,
                0.033433184027671814,
                0.006835915148258209,
                -0.04337867349386215,
                0.003463281784206629,
                0.0036154838744550943,
                -0.0008185029728338122,
                0.013840720057487488,
                0.02082054503262043,
                -0.002762546995654702,
                -0.03024515137076378,
                -0.024322429671883583,
                0.05369587615132332,
                -0.04874667897820473,
                -0.02007445879280567,
                0.0012660769280046225,
                -0.03997759893536568,
                -0.015860553830862045,
                0.08575406670570374,
                -0.030645158141851425,
                0.06206098571419716,
                0.04407645761966705,
                0.021415891125798225,
                -0.06366708129644394,
                -0.008666434325277805,
                0.020269114524126053,
                0.030221261084079742,
                0.0026773586869239807,
                0.03447911888360977,
                -0.003316800808534026,
                -0.013421344570815563,
                0.013475045561790466,
                -0.008642790839076042,
                -0.04215981066226959,
                0.022595079615712166,
                -0.018725521862506866,
                0.0423554889857769,
                0.04455750435590744,
                -0.049621935933828354,
                0.018544290214776993,
                -0.05648425593972206,
                -0.015843763947486877,
                0.027754714712500572,
                0.0028636876959353685,
                -0.005218257661908865,
                0.04400593787431717,
                0.025157148018479347,
                -0.0010876989690586925,
                0.011288043111562729,
                0.02484370768070221,
                0.0038867532275617123,
                0.11951575428247452,
                0.04289862513542175,
                0.054723452776670456,
                0.0012507227947935462,
                0.0028947042301297188,
                -0.022893168032169342,
                0.009226279333233833,
                -0.011023249477148056,
                0.07029898464679718,
                0.01945492997765541,
                -0.017067477107048035,
                0.021663257852196693,
                -0.040564749389886856,
                0.021006472408771515,
                -0.04554687440395355,
                -0.023189324885606766,
                0.012650547549128532,
                0.007587475702166557,
                0.0161401629447937,
                -0.0567270889878273,
                -0.05327456071972847,
                0.04769948869943619,
                0.0064982641488313675,
                -0.004544309340417385,
                0.03478100523352623,
                -0.03953041508793831,
                0.0037321005947887897,
                0.033888258039951324,
                0.030335500836372375,
                -0.019627133384346962,
                0.062427300959825516,
                -0.040479160845279694,
                -0.01684439927339554,
                -0.03679591044783592,
                -0.016245564445853233,
                0.029107170179486275,
                0.038823530077934265,
                -0.005089794751256704,
                -0.0026451495941728354,
                -0.021543927490711212,
                0.0034107747487723827,
                0.005453832447528839,
                -0.03515035659074783,
                -0.010635974816977978,
                0.00613396568223834,
                -0.03280395641922951,
                -0.028235211968421936,
                0.030427254736423492,
                0.001913390588015318,
                -0.019625745713710785,
                0.03859570622444153,
                -0.008711477741599083,
                -0.0589090958237648,
                -0.05156451463699341,
                -0.059981655329465866,
                -0.017016882076859474,
                0.015381486155092716,
                -0.04763094335794449,
                -0.00037242964026518166,
                -0.0073051126673817635,
                0.007578738499432802,
                -0.03397774696350098,
                -0.009085933677852154,
                -0.019110718742012978,
                0.0028844443149864674,
                -0.018102452158927917,
                0.04205754026770592,
                -0.04193631187081337,
                -0.05091720446944237,
                0.034470800310373306,
                -0.01321905292570591,
                -0.019433990120887756,
                -0.014806805178523064,
                -0.009398473426699638,
                -0.010450645349919796,
                0.014110884629189968,
                0.013367303647100925,
                0.051350824534893036,
                0.030998682603240013,
                0.03162562847137451,
                -0.04717695340514183,
                -0.02399585023522377,
                -0.019342441111803055,
                0.14043225347995758,
                0.02841510996222496,
                0.03177206590771675,
                -0.040888626128435135,
                0.00505260331556201,
                0.004542283248156309,
                0.01810428500175476,
                0.036609139293432236,
                0.01213595736771822,
                0.022131886333227158,
                -0.07659167051315308,
                -0.029830465093255043,
                0.014486575499176979,
                -0.04905683547258377,
                -0.033782780170440674,
                -0.0008294355939142406,
                0.048848435282707214,
                -0.05252404510974884,
                0.0019834255799651146,
                -0.03523968905210495,
                0.003321596886962652,
                0.022522641345858574,
                -0.002701063407585025,
                -0.05457733944058418,
                -0.0003585684753488749,
                -0.049212221056222916,
                -0.0017537608509883285,
                0.04137158393859863,
                0.030241359025239944,
                -0.06954644620418549,
                -0.017602290958166122,
                0.009187345393002033,
                0.03339080139994621,
                -0.0176054947078228,
                -0.018463987857103348,
                -0.0357913076877594,
                0.03261834755539894,
                -0.05818997696042061,
                0.04063328728079796,
                -0.005081491079181433,
                -0.024364959448575974,
                -0.07754458487033844,
                -0.0029603810980916023,
                -0.06378443539142609,
                0.01971299946308136,
                0.03199981153011322,
                -0.0021032688673585653,
                0.0653572529554367,
                0.05267040804028511,
                0.004108671098947525,
                0.033922288566827774,
                -0.0004220750997774303,
                0.06273698061704636,
                -0.05602861940860748,
                0.04189785197377205,
                -0.01610499434173107,
                0.07305702567100525,
                0.004189332481473684,
                -0.011047178879380226,
                -0.04040036350488663,
                0.010436678305268288,
                0.06511751562356949,
                -0.06615206599235535,
                0.03007216937839985,
                -0.01247754879295826,
                -0.0037727600429207087,
                0.04066726565361023,
                -0.006842961069196463,
                -0.04077908396720886,
                -0.01424800418317318,
                -0.0065261903218925,
                0.014763236977159977,
                -0.017544100061058998,
                -0.06882308423519135,
                0.04075204208493233,
                -0.009770478121936321,
                -0.03380289301276207,
                0.029453109949827194,
                0.0037457121070474386,
                0.009811930358409882,
                -0.018155988305807114,
                0.06516483426094055,
                0.005453921854496002,
                0.016714559867978096,
                0.04608318209648132,
                -0.03473823890089989,
                -0.0933055505156517,
                0.05831097066402435,
                0.041156962513923645,
                -0.0037696256767958403,
                0.005038077011704445,
                -0.06800302863121033,
                0.014744022861123085,
                -0.00642894534394145,
                0.027410531416535378,
                0.033773280680179596,
                -0.02021598257124424,
                0.002816571621224284,
                0.00372785865329206,
                0.02054906263947487,
                -0.08259611576795578,
                0.03403080627322197,
                -0.048062440007925034,
                -0.03447089344263077,
                0.025007197633385658,
                -0.023097408935427666,
                0.041366614401340485,
                0.04816016927361488,
                0.06054103001952171,
                0.046152353286743164,
                -0.04526788741350174,
                -0.024333730340003967,
                0.025184210389852524,
                -0.013612939044833183,
                0.027301087975502014,
                -0.03328938037157059,
                -0.005506835412234068,
                -0.04079677164554596,
                -0.04431997984647751,
                -0.05561143532395363,
                -0.016917185857892036,
                -0.010881861671805382,
                0.0878840833902359,
                -0.0012220618082210422,
                -0.013262162916362286,
                -0.054301708936691284,
                0.053733356297016144,
                0.00875532254576683,
                -0.0143484091386199,
                -0.08980163931846619,
                -0.003501849016174674,
                0.03475044295191765,
                -0.019051535055041313,
                0.01594349928200245,
                -0.09694977104663849,
                0.044139500707387924,
                -0.017393644899129868,
                -0.030267052352428436,
                -0.0782492384314537,
                0.0369136817753315,
                0.004419597797095776,
                0.004134670831263065,
                0.02743380330502987,
                -0.01914087124168873,
                -0.030142739415168762,
                0.007290435954928398,
                -0.04324782267212868,
                0.014672558754682541,
                -0.08116581290960312,
                0.02961362712085247,
                -0.02171393483877182,
                -0.0036746724508702755,
                0.024143800139427185,
                -0.007685943506658077,
                0.013898897916078568,
                0.025963423773646355,
                -0.004484840668737888,
                -0.02605167031288147,
                -0.0644858106970787,
                0.009010464884340763,
                -0.0043928856030106544,
                -0.02114139497280121,
                0.0021293654572218657,
                -0.024892523884773254,
                -0.030788525938987732,
                -0.0061631398275494576,
                -0.015382811427116394,
                0.04373703524470329,
                -0.008427205495536327,
                0.005141184199601412,
                -0.044904258102178574,
                -0.017618516460061073,
                -0.035153500735759735,
                -0.016388263553380966,
                -0.04097406566143036,
                -0.024109799414873123,
                -0.0397166833281517,
                -0.027244608849287033,
                -0.028096606954932213,
                -0.03952064737677574,
                0.0113622285425663,
                -0.012485519051551819,
                0.02529534325003624,
                0.01798711158335209,
                -0.02115781418979168,
                0.008425178937613964,
                -0.03670878708362579,
                -0.006065067835152149,
                0.03453351929783821,
                -0.017150655388832092,
                0.003995820879936218,
                0.016664618626236916,
                -0.010329598560929298,
                0.002444623736664653,
                -0.07383348792791367,
                0.0461965948343277,
                0.04765498638153076,
                -0.06808210909366608,
                -0.06310310959815979,
                0.004297349136322737,
                -0.04288017004728317,
                0.026796884834766388,
                -0.02367146499454975,
                0.032070010900497437,
                -0.015433019958436489,
                0.015225505456328392,
                -0.012278793379664421,
                0.0174541138112545,
                -0.01983826234936714,
                -0.03954020515084267,
                0.031825952231884,
                -0.03928292542695999,
                -0.03244517371058464,
                -0.01786704547703266,
                0.05752009525895119,
                -0.048951152712106705,
                0.03460917994379997,
                0.037704043090343475,
                0.09033746272325516,
                0.017036430537700653,
                0.006986812222748995,
                -0.03646613657474518,
                0.05150796100497246,
                0.002500561298802495,
                -0.011070375330746174,
                0.008487863466143608,
                -0.007171012461185455,
                0.003917864058166742,
                0.025904551148414612,
                -0.004786741454154253,
                0.047592464834451675,
                -0.026943635195493698,
                0.017456553876399994,
                -0.026506692171096802,
                0.008022048510611057,
                -0.032415971159935,
                -7.757941658532529e-33,
                0.004162705037742853,
                -0.048974744975566864,
                0.04740205034613609,
                -0.03678423538804054,
                -0.0424678660929203,
                -0.010753463953733444,
                0.04715084284543991,
                -0.00633653299883008,
                -0.05234893411397934,
                -0.03859454765915871,
                -0.03309621661901474,
                -0.01538621261715889,
                0.03804537653923035,
                0.02072739601135254,
                0.04949790984392166,
                -0.05120095983147621,
                0.002884926740080118,
                -0.005593177396804094,
                -0.006585961673408747,
                -0.005926439072936773,
                -0.00043908768566325307,
                0.008853564970195293,
                0.04930140823125839,
                0.024022215977311134,
                0.04630117863416672,
                0.0038889863062649965,
                -0.06131253018975258,
                -0.01374772284179926,
                0.030671333894133568,
                -0.016530821099877357,
                -0.034699708223342896,
                -0.0007988390862010419,
                -0.001130958553403616,
                -0.049286849796772,
                0.009472999721765518,
                0.028712686151266098,
                -0.012051170691847801,
                0.02195718325674534,
                0.007408580277115107,
                0.0030191249679774046,
                -0.06328973174095154,
                0.0020757648162543774,
                0.026350222527980804,
                -0.02657429128885269,
                0.014972880482673645,
                -0.024852657690644264,
                -0.002885672030970454,
                -0.010628570802509785,
                0.003699225839227438,
                0.0504644513130188,
                -0.04597003012895584,
                -0.02246486395597458,
                -0.02818801999092102,
                0.014962724409997463,
                0.044717561453580856,
                -0.0052110180258750916,
                -0.014039658010005951,
                0.0012782299891114235,
                -0.04644446074962616,
                -0.01094638742506504,
                -0.0039604161866009235,
                -0.005332679487764835,
                -0.055086132138967514,
                0.005616840906441212,
                -0.0058652074076235294,
                -0.004855348728597164,
                -0.061494145542383194,
                -0.0010060708737000823,
                0.01034580823034048,
                -0.047214411199092865,
                0.009236185811460018,
                0.12805604934692383,
                -0.027537241578102112,
                -0.05885322391986847,
                -0.06002924218773842,
                -0.016337646171450615,
                0.018203258514404297,
                -0.017624596133828163,
                0.11358987540006638,
                -0.018986234441399574,
                -0.024686278775334358,
                -0.008778524585068226,
                0.020798394456505775,
                -0.032043155282735825,
                0.028240785002708435,
                -0.0033068927004933357,
                -0.02626757323741913,
                0.11435230821371078,
                -0.04901719465851784,
                -0.0005565361934714019,
                -0.007099400274455547,
                0.06624225527048111,
                0.0049442448653280735,
                -0.004312728065997362,
                -0.09368827193975449,
                -0.016003362834453583,
                0.03984881937503815,
                -0.020475419238209724,
                0.04623953625559807,
                -0.04827049374580383,
                -0.010667560622096062,
                0.02670513466000557,
                -0.07344025373458862,
                0.015514499507844448,
                -0.025683162733912468,
                0.019963299855589867,
                0.0022495249286293983,
                -0.013644302263855934,
                -0.03712521865963936,
                -0.029388023540377617,
                -0.00319643784314394,
                0.005347774364054203,
                0.061332643032073975,
                -0.01713530346751213,
                0.008862693794071674,
                0.01986991986632347,
                0.029829109087586403,
                -0.024371176958084106,
                -0.009052049368619919,
                -0.014387938193976879,
                0.04196693375706673,
                -0.0014029095182195306,
                -0.0224741343408823,
                0.009641386568546295,
                -0.028648143634200096,
                0.009389504790306091,
                0.05511707067489624,
                -0.0010913925943896174,
                0.02503245137631893,
                -0.027150310575962067,
                -0.018018757924437523,
                -0.05082247778773308,
                2.560899758918822e-07,
                -0.02411649376153946,
                0.03350965678691864,
                -0.02777971886098385,
                0.05452302470803261,
                0.00205419072881341,
                0.025529658421874046,
                0.041349057108163834,
                0.026454269886016846,
                -0.036468639969825745,
                0.025527864694595337,
                0.06485771387815475,
                -0.028477178886532784,
                -0.01036851480603218,
                -0.013424916192889214,
                0.052302680909633636,
                -0.025464292615652084,
                0.02359195426106453,
                -0.005773442331701517,
                0.014330280013382435,
                -0.04745330289006233,
                0.015433297492563725,
                0.015408436767756939,
                0.03934541344642639,
                -0.0050926050171256065,
                0.0240897499024868,
                0.015986837446689606,
                0.023793432861566544,
                -0.06193820759654045,
                0.07279817759990692,
                0.003162792418152094,
                0.02637508139014244,
                0.0006602075300179422,
                -0.014937307685613632,
                -0.025475963950157166,
                -0.01127199362963438,
                -0.03593171387910843,
                0.020085176452994347,
                0.031085820868611336,
                0.047357477247714996,
                0.03642161935567856,
                -0.010101962834596634,
                -0.03720800578594208,
                -0.017909277230501175,
                0.022952135652303696,
                0.0056306892074644566,
                0.0035241711884737015,
                -0.004181977827101946,
                0.015338117256760597,
                -0.07761694490909576,
                0.0007475751917809248,
                -0.03916480019688606,
                0.022102510556578636,
                -0.005779046565294266,
                0.06774162501096725,
                0.008966433815658092,
                -0.0043969908729195595,
                0.045865241438150406,
                0.03279309347271919,
                0.066042460501194,
                0.026558320969343185,
                -0.01975245587527752,
                0.024849647656083107,
                -0.05083482339978218,
                -0.005519232712686062,
                0.060025446116924286,
                0.025227146223187447,
                -0.002740114228799939,
                1.0303531705081307e-34,
                -0.0004707284097094089,
                -0.032791346311569214,
                0.011109854094684124,
                0.015971139073371887,
                0.030575253069400787,
                -0.004537593573331833,
                -0.03133068233728409,
                0.012702742591500282,
                0.025055324658751488,
                0.0064957947470247746,
                -0.018579836934804916
            ]
        },
        "Location": "Tenerife & UK",
        "Remuneration": 32.45192307692308,
        "Services Required": {
            "name": "Operations & Project Manager PetroIneos Trading Limited",
            "rep": [
                0.03819577023386955,
                0.026333684101700783,
                -0.042651254683732986,
                0.05334331840276718,
                0.05105002224445343,
                0.005650253035128117,
                -0.003765120869502425,
                0.0294442567974329,
                -0.072280652821064,
                0.030127573758363724,
                0.016932765021920204,
                -0.01802416704595089,
                0.012714322656393051,
                0.09462769329547882,
                0.04171097278594971,
                -0.01944742351770401,
                -0.005147536750882864,
                0.00923736859112978,
                0.0040037380531430244,
                0.015143504366278648,
                -0.02887183241546154,
                0.03933417797088623,
                -0.03582778573036194,
                0.00934651494026184,
                -0.010113385505974293,
                0.010928617790341377,
                -0.03183551877737045,
                -0.005302950274199247,
                0.01934569887816906,
                0.014316367916762829,
                0.0723864957690239,
                -0.02983211539685726,
                0.011195328086614609,
                -0.014339480549097061,
                1.5235341379593592e-06,
                0.003382389899343252,
                -0.002386434469372034,
                0.003934773150831461,
                0.008889401331543922,
                0.00545367319136858,
                0.0001368344819638878,
                -0.003554928582161665,
                0.021196259185671806,
                0.003729258431121707,
                -0.003955576103180647,
                -0.02476474642753601,
                0.06213993951678276,
                0.055109426379203796,
                -0.02900277078151703,
                0.034389372915029526,
                0.007546516135334969,
                0.0897248312830925,
                -0.026049086824059486,
                -0.022875981405377388,
                -0.029183467850089073,
                -0.12297255545854568,
                0.015173611231148243,
                0.0016206973232328892,
                -0.0068497806787490845,
                0.03273313120007515,
                0.013552824966609478,
                0.009170709177851677,
                -0.004645171109586954,
                0.005860292352735996,
                -0.03271762281656265,
                0.020782990381121635,
                0.06283601373434067,
                -0.06660447269678116,
                0.033733345568180084,
                -0.012789455242455006,
                0.05907824635505676,
                0.01627880521118641,
                0.022335343062877655,
                0.08046325296163559,
                -0.015333518385887146,
                -0.08069901168346405,
                0.000873254204634577,
                0.04597727581858635,
                -0.040479641407728195,
                -0.018578408285975456,
                -0.036059293895959854,
                0.06357238441705704,
                0.0038315432611852884,
                0.005179884377866983,
                -0.03713156282901764,
                0.0022183542605489492,
                -0.019462116062641144,
                -0.004410763271152973,
                0.018650418147444725,
                0.007758557330816984,
                -0.019387928768992424,
                0.010116390883922577,
                0.023977620527148247,
                0.004147790838032961,
                -0.026210544630885124,
                -0.014744649641215801,
                0.06210685148835182,
                0.04616802930831909,
                -0.011992690153419971,
                -0.03865250572562218,
                -0.07246187329292297,
                0.043097011744976044,
                0.008962158113718033,
                0.02463505230844021,
                0.0011143434094265103,
                0.04349098727107048,
                0.007589063141494989,
                0.009963399730622768,
                -0.026563897728919983,
                -0.004048714879900217,
                -0.022956205531954765,
                -0.016612788662314415,
                -0.06538987159729004,
                -0.011913563124835491,
                -0.013095534406602383,
                0.018837256357073784,
                -0.04983101040124893,
                0.043546754866838455,
                0.011826748959720135,
                0.04024134948849678,
                0.06712673604488373,
                -0.0054383836686611176,
                0.011309961788356304,
                0.010709171183407307,
                -0.05574485659599304,
                0.030178477987647057,
                -0.014391137287020683,
                2.430359018035233e-05,
                -0.006599570158869028,
                0.02343236282467842,
                -0.024486221373081207,
                0.020685503259301186,
                -0.009221305139362812,
                -0.038002919405698776,
                -0.006246143952012062,
                0.02644224278628826,
                -0.026405539363622665,
                -0.017530053853988647,
                -0.040544167160987854,
                0.014626452699303627,
                -0.023639578372240067,
                -0.02427026815712452,
                -0.09607848525047302,
                0.05266180634498596,
                0.002819512505084276,
                -0.04545073211193085,
                -0.04360312968492508,
                0.03384151682257652,
                0.044021494686603546,
                0.008289589546620846,
                0.016440987586975098,
                -0.024239234626293182,
                0.0006142486818134785,
                -0.012899886816740036,
                0.013694411143660545,
                0.034907396882772446,
                0.022760959342122078,
                -0.05105804279446602,
                -0.03091108612716198,
                0.02376357652246952,
                -0.021013090386986732,
                -0.0004453876172192395,
                0.0020555194932967424,
                0.03658066689968109,
                -0.024370813742280006,
                -0.024919655174016953,
                0.10681403428316116,
                0.0061135017313063145,
                -0.03183000162243843,
                -0.06153328716754913,
                0.008893040008842945,
                0.004616547375917435,
                -0.015706300735473633,
                0.09015968441963196,
                0.043260764330625534,
                -0.145406112074852,
                0.02862539142370224,
                -0.015536597929894924,
                0.0007115395274013281,
                -0.002741620410233736,
                0.002777430694550276,
                -0.1564018577337265,
                0.03638630732893944,
                -0.04198140650987625,
                0.012713666073977947,
                0.04006979614496231,
                -0.09667182713747025,
                0.00025473657296970487,
                -0.009607570245862007,
                0.024787630885839462,
                0.034134820103645325,
                0.0012885307660326362,
                -0.05817173421382904,
                0.02668849565088749,
                0.036088354885578156,
                0.027685051783919334,
                -0.05661016330122948,
                0.010641826316714287,
                0.01391921378672123,
                0.043969206511974335,
                -0.004960706923156977,
                0.01753196492791176,
                0.008952452801167965,
                0.007900051772594452,
                -0.017517054453492165,
                -0.02362411469221115,
                0.035194650292396545,
                0.021348409354686737,
                -0.08174140006303787,
                0.020421303808689117,
                0.00749164866283536,
                0.013805728405714035,
                0.014877979643642902,
                0.01959911547601223,
                -0.03905939683318138,
                0.007874034345149994,
                0.020971082150936127,
                -0.01149681955575943,
                -0.019273262470960617,
                0.0650385171175003,
                -0.01762404851615429,
                0.00046838950947858393,
                0.0750972256064415,
                -0.009288042783737183,
                0.03880399093031883,
                -0.01819261908531189,
                0.0044168164022266865,
                0.0036497660912573338,
                -0.028722450137138367,
                0.04686091095209122,
                0.017829082906246185,
                0.008565355092287064,
                0.04702840745449066,
                -0.024517104029655457,
                0.019234612584114075,
                0.03539295122027397,
                0.044901005923748016,
                0.01922011561691761,
                0.01798580214381218,
                0.02358565479516983,
                -0.06180400773882866,
                -0.035441718995571136,
                -0.009792941622436047,
                0.02273862063884735,
                0.01996741071343422,
                0.011757400818169117,
                -0.018931947648525238,
                -0.031197557225823402,
                0.014316193759441376,
                -0.00396070396527648,
                0.004587799776345491,
                -0.01429770141839981,
                0.06144360825419426,
                0.032874125987291336,
                0.031912777572870255,
                0.033328015357255936,
                -0.033499009907245636,
                0.06023489683866501,
                -0.10729033499956131,
                0.013281362131237984,
                0.02624683268368244,
                -0.060491956770420074,
                -0.05134189873933792,
                0.0007921017240732908,
                0.06939190626144409,
                0.01436577457934618,
                0.004298432730138302,
                -0.04561001434922218,
                -0.04605114087462425,
                -0.008664350025355816,
                -0.0051950979977846146,
                0.00360327516682446,
                0.06681180000305176,
                -0.01896056719124317,
                -0.012073409743607044,
                0.012694474309682846,
                0.03074268437922001,
                0.015498870983719826,
                -0.04413285478949547,
                0.04532812908291817,
                0.0467117615044117,
                -0.045086976140737534,
                -0.021114768460392952,
                0.03870594874024391,
                0.011292017996311188,
                -0.049691520631313324,
                0.032221630215644836,
                -0.09204751253128052,
                -0.06309254467487335,
                0.006452877540141344,
                -0.025361180305480957,
                -0.03604910522699356,
                0.023870931938290596,
                -0.04653187096118927,
                -0.01710226759314537,
                0.018368514254689217,
                -0.007861610502004623,
                -0.01210911013185978,
                0.003611154155805707,
                0.020334439352154732,
                -0.050310518592596054,
                -0.06439068913459778,
                0.03832441195845604,
                -0.018894974142313004,
                -0.04234227538108826,
                -0.01524105854332447,
                0.07324014604091644,
                0.04484393820166588,
                -0.03745532035827637,
                -0.05158623307943344,
                0.03745359182357788,
                0.05183234438300133,
                0.006552099250257015,
                -0.03851180523633957,
                0.050483446568250656,
                0.008059478364884853,
                -0.012819263152778149,
                0.02111830934882164,
                0.01266715582460165,
                0.11218369752168655,
                -0.013940321281552315,
                0.016444751992821693,
                -0.026522217318415642,
                0.0070103127509355545,
                -0.028414104133844376,
                0.0052120075561106205,
                -0.04674927890300751,
                0.014687991701066494,
                -0.014448441565036774,
                -0.045367531478405,
                0.010179881006479263,
                -0.013279478065669537,
                -0.04386034607887268,
                -0.01925489865243435,
                0.016336515545845032,
                -0.05119071528315544,
                0.006560241803526878,
                -0.0941573902964592,
                -0.017435487359762192,
                -0.018047038465738297,
                -0.05441470444202423,
                -0.041535452008247375,
                -0.0349310003221035,
                0.01879737339913845,
                -0.0018180332845076919,
                -0.013832170516252518,
                0.0256047323346138,
                0.02022179216146469,
                -0.04472602531313896,
                0.021653782576322556,
                0.02602790668606758,
                0.016361068934202194,
                -0.03918881341814995,
                -0.022592658177018166,
                -0.016950761899352074,
                0.009138843044638634,
                -0.014865783974528313,
                -0.01044333353638649,
                0.04247431084513664,
                -0.001169223221950233,
                -0.029810182750225067,
                -0.036782775074243546,
                -0.014401234686374664,
                -0.023893160745501518,
                0.0013593424810096622,
                0.013557293452322483,
                -0.023455753922462463,
                0.022143857553601265,
                -0.003338709007948637,
                -0.032180625945329666,
                -0.059475887566804886,
                0.016115473583340645,
                -0.040586527436971664,
                0.0022943937219679356,
                -0.00603886554017663,
                0.02336832880973816,
                -2.3462166609533597e-06,
                0.05717834457755089,
                -0.046417199075222015,
                -0.0165640227496624,
                0.03115159273147583,
                0.0014636614359915257,
                0.10022076964378357,
                -0.018191123381257057,
                0.01863986626267433,
                0.0036355864722281694,
                -0.01245677936822176,
                -0.060804519802331924,
                -0.009824437089264393,
                0.01644734852015972,
                -0.0026589855551719666,
                0.01157383993268013,
                -0.06180216744542122,
                0.013589588925242424,
                -0.03404449671506882,
                -0.048067301511764526,
                0.004931900650262833,
                0.011985557153820992,
                0.016737500205636024,
                0.03833889961242676,
                0.020099621266126633,
                0.06356389075517654,
                0.03997086361050606,
                -0.016557632014155388,
                -0.016275577247142792,
                -0.12330994755029678,
                0.06974268704652786,
                -0.008546222001314163,
                0.008774830028414726,
                0.021087976172566414,
                -0.019443772733211517,
                -0.013487327843904495,
                -0.009073879569768906,
                0.015149146318435669,
                -0.03896738961338997,
                -0.05989113450050354,
                -0.047141462564468384,
                0.04277397319674492,
                0.030969006940722466,
                -0.06132403388619423,
                0.07809362560510635,
                0.033124227076768875,
                -0.01786014810204506,
                0.06375902891159058,
                0.0424211323261261,
                0.015290861949324608,
                0.07562800496816635,
                0.03600115329027176,
                0.009433071129024029,
                -0.029623134061694145,
                -0.01367699634283781,
                -0.00460799178108573,
                -0.009890155866742134,
                0.04576447606086731,
                0.0621468685567379,
                -0.005103409290313721,
                0.010910936631262302,
                0.02462669089436531,
                0.011294660158455372,
                -0.0152286347001791,
                0.009838257916271687,
                0.09219010174274445,
                0.050902847200632095,
                -0.022866331040859222,
                -0.01587730087339878,
                -7.050667773000896e-05,
                -0.021139666438102722,
                0.03779508173465729,
                -0.10059826821088791,
                0.0001249948691111058,
                0.04000597819685936,
                -0.04349333792924881,
                -0.004284973721951246,
                -0.04163293540477753,
                0.01640123873949051,
                -0.033869076520204544,
                -0.0614374577999115,
                -0.055598173290491104,
                0.016131922602653503,
                -0.04183049872517586,
                0.03511187806725502,
                0.03506895527243614,
                -0.04877321794629097,
                0.001994072226807475,
                0.03586679697036743,
                0.004943612031638622,
                0.00429840013384819,
                -0.0011642907047644258,
                0.013271545059978962,
                -0.06298769265413284,
                0.04240145534276962,
                0.009246361441910267,
                -0.019391844049096107,
                0.016599925234913826,
                0.007674914784729481,
                -0.027447080239653587,
                -0.009769911877810955,
                0.016723472625017166,
                -0.041087016463279724,
                0.06269308924674988,
                -0.0021051191724836826,
                0.022620107978582382,
                0.02784462459385395,
                -0.04119822010397911,
                0.00857613142579794,
                0.013226901181042194,
                0.05029718205332756,
                0.017947781831026077,
                -0.01798173598945141,
                0.02181481197476387,
                0.029495619237422943,
                -0.05146791785955429,
                -0.008285526186227798,
                -0.07691090553998947,
                0.009271454997360706,
                0.01329539529979229,
                0.060185953974723816,
                -0.05247794836759567,
                0.024725403636693954,
                -0.08103781193494797,
                -0.04536861181259155,
                0.046796225011348724,
                0.04793282970786095,
                -0.014603051356971264,
                0.010472796857357025,
                0.00486757280305028,
                -0.002261366695165634,
                -0.035943515598773956,
                -0.006731891073286533,
                -0.0023472122848033905,
                -0.017820460721850395,
                -0.019387679174542427,
                0.02653724141418934,
                -0.0227215476334095,
                0.0668381080031395,
                0.011020936071872711,
                0.01710907183587551,
                -0.021184654906392097,
                0.008859672583639622,
                -0.04741597920656204,
                0.03517083451151848,
                -0.03209299221634865,
                0.014899754896759987,
                -0.0141191640868783,
                -0.013448871672153473,
                -0.033259615302085876,
                0.022238992154598236,
                0.004838663153350353,
                0.014669678173959255,
                0.048334307968616486,
                -0.001678694854490459,
                0.0033048109617084265,
                -0.042984966188669205,
                0.027289578691124916,
                -0.06026313453912735,
                0.03652119264006615,
                0.0497649721801281,
                0.05277647823095322,
                0.043683215975761414,
                0.042953114956617355,
                0.0035870997235178947,
                0.03411302715539932,
                0.0028347200714051723,
                -0.0011066737351939082,
                0.010492286644876003,
                -0.01016498263925314,
                -0.014743253588676453,
                0.009800489991903305,
                -0.02223893254995346,
                0.004786483012139797,
                -0.009532888419926167,
                -0.01892688311636448,
                0.016384882852435112,
                0.03188111633062363,
                -0.04697977751493454,
                -5.1269520392320085e-33,
                -0.02610156498849392,
                -0.045231301337480545,
                0.010881062597036362,
                0.09472763538360596,
                -0.03820394352078438,
                -0.004355710931122303,
                0.017965225502848625,
                -0.009621827863156796,
                -0.04695422947406769,
                0.0041769640520215034,
                0.014199863187968731,
                -0.00715986592695117,
                0.044163256883621216,
                0.02858302928507328,
                0.017348073422908783,
                0.01802889071404934,
                0.02575431950390339,
                0.012619251385331154,
                -0.005489312577992678,
                -0.053475867956876755,
                -0.030909011140465736,
                0.03413517400622368,
                0.06259503960609436,
                0.03447583690285683,
                0.00958715658634901,
                0.0005158981657586992,
                -0.05167653039097786,
                0.07098601758480072,
                0.03547646105289459,
                0.009304509498178959,
                -0.005781021900475025,
                -0.026900919154286385,
                0.0013972080778330564,
                0.04553357884287834,
                -0.01162809133529663,
                0.003699708729982376,
                -0.02741941623389721,
                0.0037156192120164633,
                -0.03538025915622711,
                0.02385420724749565,
                -0.02597797103226185,
                0.014489452354609966,
                0.0301665049046278,
                -0.05230912193655968,
                0.007746449206024408,
                0.012052989564836025,
                -0.014661415480077267,
                -0.02007141336798668,
                0.018817735835909843,
                0.005946944002062082,
                -0.03821107745170593,
                -0.005961109418421984,
                -0.007204771973192692,
                0.0377253033220768,
                0.03551043942570686,
                0.016637209802865982,
                -0.0005229386151768267,
                -0.03807796910405159,
                -0.0015539417508989573,
                -0.06467869132757187,
                -0.03843391686677933,
                0.019765403121709824,
                0.02317877672612667,
                0.026191750541329384,
                -0.022000333294272423,
                -0.021019622683525085,
                0.01868845522403717,
                0.024548068642616272,
                0.015356854535639286,
                0.026183681562542915,
                -0.044386591762304306,
                0.04297330603003502,
                -0.05420200526714325,
                -0.04506497457623482,
                -0.14425553381443024,
                0.0010860441252589226,
                0.012495776638388634,
                -0.0021365561988204718,
                0.06993918120861053,
                0.004973496776074171,
                0.017789030447602272,
                -0.029014263302087784,
                0.023150650784373283,
                -0.029194248840212822,
                0.01788904517889023,
                -0.04945027083158493,
                -0.047168273478746414,
                0.005095988046377897,
                0.019151851534843445,
                0.012461225502192974,
                -0.030311642214655876,
                0.04023192450404167,
                -0.03604523837566376,
                -0.017042366787791252,
                0.01629558950662613,
                -0.07965907454490662,
                0.006060810759663582,
                -0.013834964483976364,
                0.02710544317960739,
                0.004669754300266504,
                -0.026576459407806396,
                0.06467533111572266,
                -0.06439539790153503,
                0.04717133566737175,
                0.004048131871968508,
                0.0012036440894007683,
                -0.029306897893548012,
                -0.025530735030770302,
                0.005628383718430996,
                0.009044256992638111,
                -0.009548776783049107,
                0.023009397089481354,
                -0.0029978989623486996,
                0.03531016409397125,
                -0.06098882481455803,
                -0.03070424124598503,
                -0.0007177943480201066,
                -0.03739003464579582,
                0.00668316101655364,
                -0.01990656368434429,
                0.02615705132484436,
                0.03799794241786003,
                -0.011149263009428978,
                0.006471823435276747,
                -0.0161729846149683,
                0.003587540937587619,
                0.040803320705890656,
                0.06228155270218849,
                0.0016684537986293435,
                0.025153817608952522,
                0.008471825160086155,
                -0.019933680072426796,
                2.0883889817469026e-07,
                -0.007334044203162193,
                0.05176370590925217,
                0.02721966616809368,
                0.0644257441163063,
                0.002948995213955641,
                -0.033126138150691986,
                -0.006995962932705879,
                0.012082339264452457,
                -0.06043894216418266,
                -0.04866436868906021,
                0.07685708999633789,
                0.0017777698813006282,
                -0.023935364559292793,
                -0.053597670048475266,
                -0.017360776662826538,
                -0.10159315168857574,
                0.028052806854248047,
                -0.027523545548319817,
                -0.03657154366374016,
                -0.01065012626349926,
                0.02353399060666561,
                0.025578752160072327,
                0.03049422986805439,
                -0.026865072548389435,
                0.015827341005206108,
                -0.003579426556825638,
                0.061604391783475876,
                -0.04366205632686615,
                0.0012058117426931858,
                0.02134835720062256,
                0.027688054367899895,
                0.010935730300843716,
                0.032237518578767776,
                -0.026687268167734146,
                -0.004433908965438604,
                -0.034188590943813324,
                0.030039433389902115,
                0.022820552811026573,
                0.028949253261089325,
                0.036321330815553665,
                0.005121903028339148,
                -0.07742771506309509,
                0.009767811745405197,
                -0.021632792428135872,
                -0.017852773889899254,
                -0.0035761671606451273,
                -0.024480992928147316,
                0.04156273975968361,
                0.022393207997083664,
                -0.02294330485165119,
                -0.00503667164593935,
                0.014211745001375675,
                0.0026219182182103395,
                0.04877344146370888,
                0.008550688624382019,
                -0.005991467274725437,
                0.0012087438954040408,
                0.04483242332935333,
                0.045206524431705475,
                -0.0716513842344284,
                -0.05444881319999695,
                -0.01762154884636402,
                -0.02005894109606743,
                0.01314693596214056,
                0.04500458389520645,
                0.04991599917411804,
                -0.008887579664587975,
                1.9036419286471722e-34,
                0.011075908318161964,
                -0.05436508357524872,
                -0.02723868004977703,
                -0.05150502175092697,
                0.032231785356998444,
                -0.03537062555551529,
                -0.005940729286521673,
                -0.0009952810360118747,
                -0.02731720544397831,
                -0.0359371155500412,
                -0.01901327818632126
            ]
        },
        "Skills": {
            "name": [
                "Shipping Operations & Chartering",
                "Oil & LNG Operations",
                "Defence Projects",
                "Scrum Master",
                "Project Management (Agile & Waterfall)",
                "Integrating Digital Solutions",
                "Leadership & Leadership Coaching"
            ],
            "rep": [
                0.0020353884901851416,
                0.0027244416996836662,
                -0.022434327751398087,
                -0.0342772901058197,
                0.02373787946999073,
                0.020605769008398056,
                0.05070208013057709,
                0.01090263668447733,
                -0.04032668098807335,
                -0.019998392090201378,
                -0.028184184804558754,
                -0.076207734644413,
                0.05466766655445099,
                0.09367826581001282,
                0.03977755084633827,
                -0.004849584307521582,
                0.01689520664513111,
                -0.028589976951479912,
                -0.07175794988870621,
                -0.018191559240221977,
                -0.006631695665419102,
                0.020043060183525085,
                -0.04338834434747696,
                -0.016314219683408737,
                0.00712605332955718,
                0.0006772138876840472,
                -0.0581093356013298,
                0.005751012358814478,
                -0.03434601053595543,
                -0.007000337820500135,
                0.07242575287818909,
                0.014997691847383976,
                0.06582476943731308,
                -0.0028090430423617363,
                1.4030225656824769e-06,
                0.013406445272266865,
                -0.021746693179011345,
                0.005191852804273367,
                -0.045955222100019455,
                0.018137622624635696,
                0.06906560808420181,
                0.012860431335866451,
                0.024649880826473236,
                0.007779116276651621,
                0.010067353025078773,
                -0.007150124292820692,
                0.06116143986582756,
                -0.01647941954433918,
                0.022706296294927597,
                0.017099497839808464,
                -0.003676960477605462,
                0.06017265468835831,
                -0.07204928994178772,
                0.020955106243491173,
                -0.07870888710021973,
                -0.09217645972967148,
                -0.014058754779398441,
                0.05278525501489639,
                -0.00851729977875948,
                -0.006507173180580139,
                -0.05128756910562515,
                -0.02057446911931038,
                -0.010392340831458569,
                0.015209240838885307,
                -0.0065523236989974976,
                0.024314843118190765,
                0.029937075451016426,
                0.01171175204217434,
                -0.020042460411787033,
                0.009050318039953709,
                0.11209706962108612,
                0.02243455871939659,
                0.004919821862131357,
                0.04204980283975601,
                -0.03323652967810631,
                -0.11624547094106674,
                -0.002598879858851433,
                0.023891974240541458,
                0.03384298086166382,
                -0.00011218912550248206,
                0.03584509342908859,
                0.006778817158192396,
                -0.010479209944605827,
                0.03822195902466774,
                -0.005507690832018852,
                0.06357849389314651,
                0.01061167661100626,
                -0.0028627135325223207,
                -0.02136428840458393,
                0.003803095780313015,
                -0.007086873520165682,
                -0.039634451270103455,
                0.005989651195704937,
                0.015572615899145603,
                -0.019264066591858864,
                -0.034552063792943954,
                0.03852184861898422,
                0.05356956645846367,
                -0.0028849144000560045,
                0.042381320148706436,
                0.005704170558601618,
                0.04476253315806389,
                -0.047330427914857864,
                -0.021025311201810837,
                0.01822144165635109,
                0.048391591757535934,
                0.017995066940784454,
                0.11671875417232513,
                -0.02348177321255207,
                0.0387357659637928,
                -0.03236362710595131,
                -0.05216621980071068,
                -0.08417757600545883,
                0.029896678403019905,
                -0.023535408079624176,
                -0.006778656970709562,
                -0.0035673468373715878,
                -0.009080627001821995,
                0.0035584925208240747,
                -0.03933992609381676,
                0.057627029716968536,
                -0.003543101716786623,
                -0.014962132088840008,
                0.03743000328540802,
                -0.019265735521912575,
                -0.018797339871525764,
                -0.05794640630483627,
                -0.004981202073395252,
                0.018550822511315346,
                0.00480024004355073,
                0.0043813735246658325,
                0.018213678151369095,
                0.005926704034209251,
                -0.037522755563259125,
                0.009957886300981045,
                -0.030485093593597412,
                -0.01865178719162941,
                0.03070075996220112,
                -0.030068308115005493,
                0.030751368030905724,
                -6.693573959637433e-05,
                -0.04012931510806084,
                -0.07467428594827652,
                -0.020218469202518463,
                -0.00046732681221328676,
                -0.05516521632671356,
                -0.010132852010428905,
                0.05865710973739624,
                0.008206800557672977,
                0.019464528188109398,
                0.000872323929797858,
                -0.05051523819565773,
                0.020281454548239708,
                -0.00993889756500721,
                0.04219450801610947,
                -0.01097183208912611,
                0.0933142602443695,
                -0.04876576364040375,
                0.025659851729869843,
                -0.036397840827703476,
                -0.00747265201061964,
                0.0209126528352499,
                0.010219584219157696,
                0.041738513857126236,
                -0.03646800294518471,
                0.024517705664038658,
                0.006462140008807182,
                0.0013520915526896715,
                -0.06178011745214462,
                0.026648402214050293,
                0.0703980028629303,
                -0.005253306124359369,
                -0.057838186621665955,
                0.0020695237908512354,
                -0.012781008146703243,
                -0.050322312861680984,
                0.0016708880430087447,
                0.008302563801407814,
                0.03206341341137886,
                -0.022388899698853493,
                -0.004795651882886887,
                -0.07100331783294678,
                -0.015544954687356949,
                -0.02789590135216713,
                0.061170291155576706,
                0.010541720315814018,
                -0.024026548489928246,
                0.026140429079532623,
                -0.015178135596215725,
                0.000343209394486621,
                0.0336739718914032,
                0.021277334541082382,
                -0.0359385721385479,
                0.0015740750823169947,
                -0.009393997490406036,
                0.01801588572561741,
                -0.045007940381765366,
                0.02703862451016903,
                -0.02413264289498329,
                -0.009522365406155586,
                -0.005896714050322771,
                -0.013706875964999199,
                -0.02276708371937275,
                0.01721067726612091,
                0.02144525572657585,
                0.06054798886179924,
                -0.06134728714823723,
                0.029260462149977684,
                -0.009904561564326286,
                -0.030375340953469276,
                0.015419510193169117,
                -0.005517477635294199,
                0.0016908923862501979,
                -0.019484076648950577,
                -0.013570867478847504,
                0.07097022980451584,
                0.011038725264370441,
                -0.04689399152994156,
                -0.006438640411943197,
                -0.010631662793457508,
                -0.03442247584462166,
                -0.0037005972117185593,
                -0.04250254109501839,
                0.05795236676931381,
                0.08545227348804474,
                -0.02383333072066307,
                -0.035916563123464584,
                0.02175908535718918,
                0.007523196283727884,
                0.014204327017068863,
                0.016795853152871132,
                -0.002896655583754182,
                0.019056372344493866,
                0.015473143197596073,
                -0.029429994523525238,
                0.046789709478616714,
                0.028063448145985603,
                0.08832405507564545,
                0.038332466036081314,
                -0.0094431322067976,
                -0.05025957152247429,
                -0.011161151342093945,
                -0.011242987588047981,
                -0.015006682835519314,
                -0.03527592122554779,
                0.02846105955541134,
                -0.10439537465572357,
                0.027274642139673233,
                0.017457706853747368,
                0.02361282892525196,
                -0.02676047571003437,
                -0.008320721797645092,
                0.027760358527302742,
                0.0002988083870150149,
                0.0788504034280777,
                -0.018348287791013718,
                0.07267768681049347,
                0.05835976451635361,
                0.025065038353204727,
                -0.019531013444066048,
                -0.006049416959285736,
                -0.015214568004012108,
                -0.029681220650672913,
                0.00295958761125803,
                0.011478917673230171,
                -0.016003094613552094,
                0.05372834950685501,
                -0.06711654365062714,
                -0.004148612730205059,
                0.0014786432730033994,
                0.03275725990533829,
                0.006059662438929081,
                0.01603095233440399,
                -0.02486260049045086,
                0.015422024764120579,
                -0.0009048290085047483,
                -0.030478714033961296,
                -0.006663522683084011,
                -0.03817771375179291,
                -0.0062493556179106236,
                -0.004342313855886459,
                -0.05009965971112251,
                -0.046811576932668686,
                -0.013062701560556889,
                0.0077020046301186085,
                -0.008828084915876389,
                0.03853745386004448,
                0.025059083476662636,
                -0.07671617716550827,
                -0.031006604433059692,
                -0.021031824871897697,
                -0.028034882619976997,
                0.0044531431049108505,
                -0.0672897920012474,
                -0.025185387581586838,
                -0.030959848314523697,
                -0.10489822924137115,
                -0.012806041166186333,
                -0.04560866951942444,
                -0.033080052584409714,
                0.044419173151254654,
                -0.05185556411743164,
                -0.02066929265856743,
                -0.0037853275425732136,
                -0.04195237159729004,
                -0.01740133948624134,
                0.04490247741341591,
                0.010766762308776379,
                -0.03965440019965172,
                0.03711830824613571,
                0.014582255855202675,
                0.04529040679335594,
                -0.014850865118205547,
                -0.05907723680138588,
                0.012695752084255219,
                -0.0021612916607409716,
                0.003938863053917885,
                0.05825764313340187,
                -0.016852598637342453,
                0.09367884695529938,
                0.031086431816220284,
                -0.00853920541703701,
                -0.027076443657279015,
                0.024328023195266724,
                -0.012864518910646439,
                -0.04429245367646217,
                0.014403722248971462,
                0.010206300765275955,
                -0.029163207858800888,
                -0.03843940794467926,
                0.019650736823678017,
                0.021252859383821487,
                -0.06990377604961395,
                0.031488608568906784,
                0.017974765971302986,
                0.017792845144867897,
                0.038565896451473236,
                0.0021146137733012438,
                -0.0013318684650585055,
                0.020162612199783325,
                0.003588627325370908,
                -0.029688678681850433,
                -0.003861425444483757,
                0.019871536642313004,
                0.00909105408936739,
                -0.006309180054813623,
                0.03694644942879677,
                -0.03310143202543259,
                0.00529447989538312,
                -0.0065283505246043205,
                -0.01314183883368969,
                -0.0024215742014348507,
                -0.012030981481075287,
                -0.005835041403770447,
                -0.020880552008748055,
                0.06979597359895706,
                -0.021403281018137932,
                0.019672749564051628,
                0.008946388028562069,
                -0.024628572165966034,
                -0.11528004705905914,
                -0.037427015602588654,
                -0.03693331032991409,
                -0.0038224649615585804,
                0.04406020790338516,
                0.03072728030383587,
                0.044968318194150925,
                0.06774650514125824,
                0.024285659193992615,
                0.030311234295368195,
                -0.07301473617553711,
                0.022203560918569565,
                -0.056113626807928085,
                -0.051727116107940674,
                0.03293246403336525,
                0.026607947424054146,
                0.004670936148613691,
                0.05008013918995857,
                0.024920104071497917,
                0.0037874719128012657,
                0.029926329851150513,
                -0.012145032174885273,
                0.0777922049164772,
                0.00084687425987795,
                -0.002423626836389303,
                -0.03979303315281868,
                -0.06431393325328827,
                -0.0548519641160965,
                0.004132576286792755,
                0.03665857017040253,
                -0.004485773388296366,
                -0.00916214007884264,
                -0.11910694092512131,
                -0.02238357998430729,
                -0.0007483700755983591,
                -0.06299051642417908,
                -0.034061249345541,
                0.011771343648433685,
                0.02903389371931553,
                0.017457928508520126,
                0.04477401077747345,
                -0.02730640396475792,
                0.07436664402484894,
                -0.033478863537311554,
                -0.01769155263900757,
                -0.02658797800540924,
                -0.006364250555634499,
                -1.7758633475750685e-05,
                -0.03274330869317055,
                -0.0013737196568399668,
                -0.046119324862957,
                0.01800411380827427,
                -0.005074695684015751,
                0.03529224172234535,
                -0.022891540080308914,
                -0.0004394377174321562,
                0.009858086705207825,
                -0.033088453114032745,
                0.025927169248461723,
                -0.004984728991985321,
                0.010427470318973064,
                0.026483600959181786,
                0.00045509496703743935,
                0.03044993430376053,
                0.030500352382659912,
                0.004003258887678385,
                0.08068357408046722,
                0.02564777061343193,
                -0.01573355123400688,
                -0.07077847421169281,
                -0.005456787534058094,
                -0.03765838220715523,
                -0.03559921681880951,
                0.04483878239989281,
                0.04940669611096382,
                0.0025548189878463745,
                0.07037656754255295,
                -0.007062874268740416,
                0.022212695330381393,
                -0.055235013365745544,
                0.013245799578726292,
                0.028606807813048363,
                0.05636178329586983,
                0.02376936934888363,
                0.026897115632891655,
                -0.017562102526426315,
                -0.015392555855214596,
                0.026721272617578506,
                -0.056920330971479416,
                -0.08970891684293747,
                0.030926961451768875,
                -0.005802213214337826,
                0.033653758466243744,
                -0.0317288301885128,
                -0.009471324272453785,
                -0.01966620609164238,
                -0.04708133637905121,
                -0.07269138842821121,
                -0.027366865426301956,
                0.05164239555597305,
                -0.03402603790163994,
                0.020295308902859688,
                0.00895989965647459,
                0.01139694545418024,
                0.01148147601634264,
                -0.04910273477435112,
                0.022587556391954422,
                0.04258809611201286,
                -0.0040829842910170555,
                -0.04582991078495979,
                -0.00568793062120676,
                0.02658916637301445,
                -0.019404107704758644,
                0.014900682494044304,
                -0.004770128522068262,
                -0.003129323711618781,
                -0.018382539972662926,
                -0.04480791464447975,
                0.0010139006190001965,
                0.025562459602952003,
                0.01040575746446848,
                -0.02471970207989216,
                0.00552673963829875,
                0.01068465318530798,
                -0.009292202070355415,
                -0.011931776069104671,
                0.049083732068538666,
                0.018313894048333168,
                0.02720324881374836,
                0.04660061001777649,
                -0.020438795909285545,
                -0.009710344485938549,
                -0.028239630162715912,
                0.01308412104845047,
                0.02721843682229519,
                -0.018600594252347946,
                0.061447419226169586,
                0.014722111634910107,
                0.021230202168226242,
                -0.06639276444911957,
                -0.0212155282497406,
                0.04578201472759247,
                0.019292376935482025,
                -0.048606306314468384,
                -0.013763743452727795,
                -0.0009654742898419499,
                -0.006839059758931398,
                -0.008850756101310253,
                0.028027893975377083,
                -0.0101877860724926,
                -0.05766607075929642,
                -0.05857823044061661,
                0.04619401693344116,
                -0.06841088086366653,
                0.02215944044291973,
                0.08142468333244324,
                -0.057047031819820404,
                -0.03530242666602135,
                -0.0599009208381176,
                -0.046901971101760864,
                0.008239044807851315,
                0.030159369111061096,
                -0.014064018614590168,
                0.005367145407944918,
                0.048455409705638885,
                -0.004920581821352243,
                0.05469752848148346,
                0.026058657094836235,
                -0.006657666061073542,
                0.022881940007209778,
                -0.017019007354974747,
                0.06528260558843613,
                0.022731967270374298,
                -0.0021892311051487923,
                -0.0031276470981538296,
                0.0413765087723732,
                0.04804568365216255,
                0.03621527552604675,
                0.04245155304670334,
                0.006779596209526062,
                -0.0033650093246251345,
                0.020318636670708656,
                0.028434669598937035,
                0.0445408932864666,
                0.03907579556107521,
                -0.04568606987595558,
                0.009717830456793308,
                -0.02540701813995838,
                0.00019471801351755857,
                0.03935253992676735,
                0.003439107444137335,
                -0.0700533464550972,
                0.020676234737038612,
                0.011115492321550846,
                0.015924563631415367,
                -5.1487218272672526e-33,
                -0.035059504210948944,
                -0.050074994564056396,
                0.049507223069667816,
                0.11401217430830002,
                -0.03273824229836464,
                -0.03360144421458244,
                0.016361402347683907,
                0.008336075581610203,
                -0.0022865154314786196,
                -0.007859923876821995,
                -0.010677832178771496,
                0.007756737992167473,
                0.03263320028781891,
                0.020013894885778427,
                -0.005434883292764425,
                0.006703347433358431,
                -0.0018101780442520976,
                -0.01798105239868164,
                -0.045478805899620056,
                -0.05522051081061363,
                -0.018759101629257202,
                0.051513414829969406,
                0.01756240241229534,
                0.046901922672986984,
                0.05151299759745598,
                -0.028068792074918747,
                -0.02614867314696312,
                0.033852752298116684,
                -0.005202313419431448,
                -0.02109263651072979,
                0.018175510689616203,
                -0.020158449187874794,
                0.0014178204583004117,
                0.04654424637556076,
                -0.015223155729472637,
                0.0051272157579660416,
                -0.004500607959926128,
                -0.012025787495076656,
                -0.0032068570144474506,
                -0.013851633295416832,
                -0.0028267677407711744,
                -0.01394642610102892,
                0.04033909738063812,
                -0.003347207559272647,
                -0.005138410720974207,
                -0.023732859641313553,
                -0.0017839467618614435,
                0.031417928636074066,
                0.0315186083316803,
                0.034157898277044296,
                -0.05922965705394745,
                0.004281946923583746,
                -0.05207575857639313,
                0.008287805132567883,
                0.08359880745410919,
                0.029829049482941628,
                -0.005626856815069914,
                -0.051371119916439056,
                0.04849861562252045,
                -0.012434358708560467,
                -0.01985512487590313,
                0.032421283423900604,
                -0.044062137603759766,
                -0.01127590611577034,
                -0.05181727185845375,
                -0.025082264095544815,
                -0.08407071232795715,
                -0.0415961779654026,
                0.01189242210239172,
                0.01775309629738331,
                -0.01585095003247261,
                0.10130886733531952,
                -0.05078445002436638,
                0.002993521047756076,
                -0.005929280072450638,
                -0.007094733417034149,
                0.006356663070619106,
                -0.010848632082343102,
                0.035094209015369415,
                -0.06126571446657181,
                0.0344870388507843,
                -0.025404712185263634,
                -0.018912017345428467,
                -0.012480775825679302,
                -0.061765156686306,
                -0.0688234493136406,
                -0.024783136323094368,
                0.039738334715366364,
                -0.013875315897166729,
                0.020986061543226242,
                0.0042884573340415955,
                -0.02598346397280693,
                -0.004881097469478846,
                0.06181064993143082,
                -0.0052339788526296616,
                -0.05174028500914574,
                0.036293480545282364,
                0.0004693293885793537,
                0.016166606917977333,
                -0.007547158282250166,
                0.009432951919734478,
                0.09268218278884888,
                -0.038333214819431305,
                -0.06382409483194351,
                0.00789997074753046,
                0.0009802196873351932,
                0.03677229955792427,
                0.012669714167714119,
                0.01822599768638611,
                0.014194211922585964,
                0.024028349667787552,
                0.0036293596494942904,
                0.01978025585412979,
                -0.0014955465449020267,
                -0.020118072628974915,
                0.0204496867954731,
                -0.01891556940972805,
                -0.01180792972445488,
                -0.010283362120389938,
                -0.021360978484153748,
                0.0628470778465271,
                -0.002715023700147867,
                0.048206016421318054,
                -0.01636362075805664,
                -0.04097000136971474,
                -0.004620610736310482,
                0.021771423518657684,
                0.03284218907356262,
                0.0544138140976429,
                0.0034091982524842024,
                -0.05088894069194794,
                0.05773945897817612,
                1.8590239392324293e-07,
                0.03851468861103058,
                0.030820332467556,
                0.03134789690375328,
                0.051281850785017014,
                0.009767827577888966,
                0.0040214997716248035,
                0.06563965231180191,
                -0.005764621775597334,
                -0.017104903236031532,
                0.01842929609119892,
                0.037461161613464355,
                -0.00879391934722662,
                -0.04532130807638168,
                -0.03880935534834862,
                0.025225741788744926,
                -0.09744323790073395,
                0.0898979976773262,
                -0.05011174455285072,
                0.011309521272778511,
                -0.031163634732365608,
                0.05900924652814865,
                0.03798161447048187,
                -0.03613869100809097,
                -0.058913033455610275,
                -0.02740115486085415,
                0.03516579791903496,
                -0.007963691838085651,
                -0.033044081181287766,
                0.02720995619893074,
                0.023126011714339256,
                -0.006060326471924782,
                -0.006209237035363913,
                0.02456384152173996,
                0.04035913571715355,
                -0.01994503103196621,
                -0.06164884194731712,
                0.04845684394240379,
                -0.009283583611249924,
                0.019869009032845497,
                0.030589967966079712,
                -0.006799580994993448,
                0.010077336803078651,
                -0.03327888622879982,
                -0.01712896302342415,
                -0.02320016361773014,
                -0.040046434849500656,
                0.008051615208387375,
                -0.11070621013641357,
                -0.024299796670675278,
                -0.025197124108672142,
                0.009120584465563297,
                0.0007822440820746124,
                -0.014798417687416077,
                0.049828268587589264,
                0.0036001126281917095,
                0.012541035190224648,
                0.04078010842204094,
                -0.004834751132875681,
                0.05843672901391983,
                0.040509287267923355,
                0.004526653792709112,
                0.005593733862042427,
                0.0007783998153172433,
                0.042787615209817886,
                0.04700087010860443,
                -0.015599846839904785,
                -0.004341290798038244,
                1.2753967917571669e-34,
                -0.052063290029764175,
                -0.019381072372198105,
                0.0005032873596064746,
                0.01582411862909794,
                0.05111173167824745,
                -0.023126795887947083,
                0.016744323074817657,
                -0.04499056562781334,
                -0.030118433758616447,
                -0.05015208572149277,
                -0.017311090603470802,
                0.016510561108589172,
                -0.012559686787426472,
                -0.005748396273702383,
                0.02154393494129181,
                0.016351133584976196,
                -0.018500950187444687,
                0.05360914394259453,
                0.06161478906869888,
                0.008606267161667347,
                -0.055629581212997437,
                -0.04581354558467865,
                -0.009639948606491089,
                0.023280978202819824,
                0.03767192363739014,
                0.06685033440589905,
                0.013386302627623081,
                -0.009629586711525917,
                -0.01967529207468033,
                -0.02399723045527935,
                -0.010705286636948586,
                -0.05975961685180664,
                0.018652740865945816,
                -0.014082171954214573,
                -0.021329330280423164,
                0.03317061811685562,
                0.008341017179191113,
                0.0030190579127520323,
                -0.028798824176192284,
                -0.00994853675365448,
                0.042618680745363235,
                0.06079229339957237,
                -0.030218154191970825,
                0.06664931029081345,
                -0.01975141651928425,
                1.712426183075877e-06,
                -0.00701572559773922,
                -0.05117363855242729,
                0.001602641656063497,
                0.00874493457376957,
                0.006057087332010269,
                0.04509122669696808,
                -0.014340199530124664,
                -0.005222165025770664,
                0.012167522683739662,
                -0.010760207660496235,
                -0.0417163260281086,
                0.05415773391723633,
                -0.08605179935693741,
                0.06732174009084702,
                0.02665145881474018,
                -0.000929740839637816,
                0.04203605651855469,
                -0.10049215704202652,
                0.020182672888040543,
                -0.010226281359791756,
                -0.020250646397471428,
                -0.013965965248644352,
                0.014413697645068169,
                -0.0005646663485094905,
                0.00353771960362792,
                0.025573913007974625,
                0.014195733703672886,
                -0.019527779892086983,
                -0.013103680685162544,
                -0.0034651621244847775,
                0.04324141517281532,
                -0.05892940238118172,
                -0.019399695098400116,
                0.03992525860667229,
                -0.006447327323257923,
                0.043480247259140015,
                -0.0433630645275116,
                0.04777858033776283,
                0.04943930357694626,
                -7.136609201552346e-05,
                -0.09029451757669449,
                -0.024516712874174118,
                0.05605209246277809,
                0.02422834187746048,
                -0.01157277263700962,
                0.048871736973524094,
                -0.028008796274662018,
                -0.0007049909327179193,
                0.025775043293833733,
                -0.0005257126176729798,
                0.041239745914936066,
                -0.018689248710870743,
                -0.02359721064567566,
                -0.026094531640410423,
                0.028842637315392494,
                -0.06448724865913391,
                0.0023868950083851814,
                -0.01444108784198761,
                -0.015788806602358818,
                -0.005087289493530989,
                -0.012679729610681534,
                0.04424867033958435,
                0.10385848581790924,
                0.03176892176270485,
                -0.006758305709809065,
                -0.005552087910473347,
                0.01590815559029579,
                -0.018903087824583054,
                0.01702985353767872,
                -0.04368750378489494,
                0.008588112890720367,
                0.00803035032004118,
                0.028652314096689224,
                0.01683509349822998,
                0.013568775728344917,
                -0.02613653801381588,
                -0.0386977382004261,
                -0.052990470081567764,
                0.03173693269491196,
                -0.03251909464597702,
                0.02685542404651642,
                -0.005771978758275509,
                0.012612409889698029,
                -0.028677215799689293,
                0.03416331857442856,
                0.06711621582508087,
                -0.00043443363392725587,
                0.042165350168943405,
                0.009784625843167305,
                0.04610558971762657,
                0.0376112163066864,
                0.01165721658617258,
                0.060041047632694244,
                0.04676466062664986,
                -0.03724873065948486,
                -0.020480355247855186,
                0.0377982072532177,
                0.027349017560482025,
                -0.008418936282396317,
                -0.010509910993278027,
                0.003404193092137575,
                0.0033145849592983723,
                -0.013695862144231796,
                -0.06687941402196884,
                -0.0011685900390148163,
                0.04329956695437431,
                -0.004578072112053633,
                -0.011894035153090954,
                0.039172250777482986,
                -0.04311787337064743,
                -0.056776534765958786,
                -0.004134123679250479,
                0.05168735608458519,
                -0.002594783902168274,
                0.005621465854346752,
                0.0435321070253849,
                -0.018028711900115013,
                0.002270536497235298,
                -0.02164466679096222,
                0.04713054001331329,
                0.03275035694241524,
                0.042355842888355255,
                -0.06346259266138077,
                0.05593866482377052,
                -0.014736399054527283,
                -0.008828612975776196,
                0.0029744680505245924,
                -0.052392199635505676,
                -0.030327318236231804,
                0.016348926350474358,
                -0.017503179609775543,
                0.036202408373355865,
                -0.014129589311778545,
                -0.09214197844266891,
                0.05160251259803772,
                0.016709206625819206,
                -0.022208688780665398,
                -0.027444323524832726,
                -0.022315258160233498,
                -0.00013367572682909667,
                -0.07406571507453918,
                0.026089435443282127,
                -0.05225669592618942,
                0.00736200762912631,
                0.03071863204240799,
                -0.031717658042907715,
                -0.08532730489969254,
                0.0189814493060112,
                -0.00032036760239861906,
                -0.013269202783703804,
                0.02978012152016163,
                -0.017712164670228958,
                0.03893324360251427,
                -0.005523804109543562,
                0.01687927357852459,
                0.03251054137945175,
                0.03430883213877678,
                -0.05067553371191025,
                -0.02126702107489109,
                0.0007559155928902328,
                0.043040093034505844,
                -0.022100580856204033,
                0.05231432989239693,
                -0.010929606854915619,
                0.06766809523105621,
                -0.012882666662335396,
                -0.02371709607541561,
                -0.046611737459897995,
                -0.04094643518328667,
                0.011769399978220463,
                -0.013176686130464077,
                -0.048799704760313034,
                -0.03848874941468239,
                -0.06467099487781525,
                -0.0046937428414821625,
                -0.0019272713689133525,
                0.021990234032273293,
                0.04381300508975983,
                0.014357672072947025,
                0.02307310700416565,
                -0.04284922406077385,
                0.02107938565313816,
                -0.0012679258361458778,
                -0.038810551166534424,
                0.03914881497621536,
                -0.03762379661202431,
                -0.062352873384952545,
                0.04207770898938179,
                0.0029294921550899744,
                0.02697012573480606,
                -0.010606782510876656,
                0.00492363516241312,
                0.01802845485508442,
                -0.02615455910563469,
                0.012265607714653015,
                0.0015268719289451838,
                0.02704361081123352,
                0.0289335735142231,
                -0.03264452889561653,
                -0.017991842702031136,
                0.031047144904732704,
                0.0860174372792244,
                0.1183946505188942,
                0.049681443721055984,
                0.04786726459860802,
                -0.012827735394239426,
                -0.06643715500831604,
                0.022463779896497726,
                -0.020898185670375824,
                0.046185027807950974,
                0.000642922823317349,
                -0.006379369646310806,
                -0.027384815737605095,
                0.02531374618411064,
                0.008071147836744785,
                -0.0101117929443717,
                -0.04625415429472923,
                0.03997769206762314,
                -0.005918618757277727,
                0.02823101170361042,
                -5.5576419981662184e-05,
                0.02923828735947609,
                0.0219044741243124,
                -0.06242546811699867,
                0.02995566464960575,
                0.014756953343749046,
                -0.08302401751279831,
                -0.02186892367899418,
                -0.02860289439558983,
                0.0015047172782942653,
                0.006508550141006708,
                0.07287682592868805,
                0.0005557405529543757,
                -0.005253477022051811,
                -0.036352358758449554,
                0.06478660553693771,
                0.00180826464202255,
                0.017925571650266647,
                -0.02966487966477871,
                -0.006401986349374056,
                -0.0075909728184342384,
                0.01645306684076786,
                0.010538592003285885,
                0.012452315539121628,
                0.02340521477162838,
                0.025957077741622925,
                -0.017204981297254562,
                -0.0577656626701355,
                -0.02810443937778473,
                0.002411486580967903,
                0.012300101108849049,
                0.022241348400712013,
                -0.014006498269736767,
                -0.0614449717104435,
                -0.017085591331124306,
                0.01800035685300827,
                -0.026960046961903572,
                0.0281173475086689,
                -0.061618346720933914,
                -0.03868900611996651,
                0.01024287287145853,
                -0.048665519803762436,
                0.07389822602272034,
                -0.01871347799897194,
                0.016449112445116043,
                -0.019712908193469048,
                -0.055227842181921005,
                -0.01915835589170456,
                0.013794738799333572,
                -0.0027617388404905796,
                0.0013235147343948483,
                0.054874978959560394,
                -0.010576771572232246,
                -0.016563214361667633,
                -0.002720898250117898,
                0.007281348574906588,
                0.0300835482776165,
                0.037168435752391815,
                0.011073615401983261,
                0.02226603589951992,
                -0.045020896941423416,
                0.013675517402589321,
                0.03363367170095444,
                -0.016507763415575027,
                0.04706041142344475,
                0.03810577839612961,
                0.011812140233814716,
                -0.029295360669493675,
                -0.007711857091635466,
                -0.03768390044569969,
                -0.0016771983355283737,
                -0.007340734824538231,
                -0.0061566480435431,
                0.04415970295667648,
                -0.04545864835381508,
                -0.01541909296065569,
                -0.015484871342778206,
                -0.07576403766870499,
                -0.01238362118601799,
                6.221202056622133e-05,
                -0.008321653120219707,
                0.04009256139397621,
                -0.043630633503198624,
                -0.007519859354943037,
                -0.007473886013031006,
                -0.003633643966168165,
                -0.020160019397735596,
                -0.013237458653748035,
                -0.00341062736697495,
                0.04560765624046326,
                -0.010121737606823444,
                0.04280948266386986,
                -0.0021821006666868925,
                0.005857182200998068,
                0.016588350757956505,
                0.023026632145047188,
                0.04544192925095558,
                -6.151356046757428e-06,
                -0.00022216862998902798,
                -0.014017176814377308,
                0.07150406390428543,
                9.36677897698246e-05,
                -0.011402266100049019,
                -0.004309505224227905,
                0.07147141546010971,
                0.0007820630562491715,
                -0.044395338743925095,
                -0.0974816307425499,
                -0.029482854530215263,
                0.0053038401529192924,
                0.022254088893532753,
                0.008065346628427505,
                -0.018529878929257393,
                -0.01828521490097046,
                -0.0031474188435822725,
                -0.06356224417686462,
                -0.008130552247166634,
                -0.108209028840065,
                -0.01367150153964758,
                0.0003251096932217479,
                0.08206529915332794,
                0.0014467319706454873,
                0.08829298615455627,
                -0.0245402529835701,
                -0.002570911543443799,
                0.0307222381234169,
                -0.05705546587705612,
                0.05432673171162605,
                -0.006407897919416428,
                -0.054215189069509506,
                0.02931843139231205,
                -0.03719763457775116,
                -0.061881717294454575,
                0.008097539655864239,
                0.05819620192050934,
                -0.036638565361499786,
                0.005831251386553049,
                -0.07109732925891876,
                -0.05582967400550842,
                0.03038090094923973,
                -0.07509326934814453,
                -0.04563745856285095,
                -0.0339127816259861,
                -0.016534192487597466,
                0.00017784448573365808,
                0.07195547223091125,
                0.01823974959552288,
                -0.028640959411859512,
                -0.04244711995124817,
                -0.05022871866822243,
                -0.05366810783743858,
                0.048228129744529724,
                0.023162703961133957,
                0.008238675072789192,
                -0.007376751396805048,
                0.02981867641210556,
                0.025378143414855003,
                0.00013557475176639855,
                0.017448166385293007,
                -0.03443719446659088,
                -0.00016929267439991236,
                -0.002501108916476369,
                0.031812869012355804,
                0.004022826440632343,
                -0.03542514145374298,
                0.06011483073234558,
                -0.03325960040092468,
                -0.003420859342440963,
                -0.04204879701137543,
                -0.014908134005963802,
                0.03527103364467621,
                0.10608425736427307,
                0.03451201319694519,
                -0.012509709224104881,
                -0.0384664461016655,
                -0.005868915002793074,
                -0.02095767669379711,
                -0.02920323796570301,
                -0.0483015701174736,
                0.022972507402300835,
                0.014674684032797813,
                0.025547273457050323,
                0.01704113557934761,
                -0.02456199750304222,
                -0.01988014578819275,
                -0.014250327832996845,
                -0.008989613503217697,
                0.025195470079779625,
                -0.01602022536098957,
                0.03826383501291275,
                0.01747806929051876,
                0.015043728984892368,
                0.052362989634275436,
                -0.087028868496418,
                -0.05033290386199951,
                0.024157021194696426,
                0.025171887129545212,
                -0.014722638763487339,
                -0.02139619179069996,
                -0.005982536356896162,
                -0.007119658403098583,
                -0.04880283400416374,
                -0.1086437851190567,
                -0.013542236760258675,
                0.014372059144079685,
                -0.00011600968718994409,
                0.025629334151744843,
                -0.03364143893122673,
                -0.00879883673042059,
                0.07642204314470291,
                -0.004797203466296196,
                0.07938820123672485,
                -0.038186926394701004,
                -0.010449293069541454,
                -0.04437195882201195,
                -0.022077756002545357,
                0.03946638107299805,
                0.0048754531890153885,
                0.03436190262436867,
                0.004533500876277685,
                0.011378331109881401,
                0.004008186981081963,
                -0.03288169205188751,
                -0.060634683817625046,
                0.0166713185608387,
                0.014444220811128616,
                -0.01843082532286644,
                0.01404525525867939,
                -0.02594447322189808,
                -0.027562402188777924,
                0.03163904696702957,
                0.035318613052368164,
                0.03878852725028992,
                -0.018501682206988335,
                0.023532865568995476,
                -0.011579815298318863,
                -0.04305154085159302,
                -0.0033601943869143724,
                0.06413446366786957,
                0.07204192131757736,
                0.012349274009466171,
                0.021652931347489357,
                0.001467066933400929,
                0.04078449308872223,
                -0.020377488806843758,
                0.018400318920612335,
                0.06752189993858337,
                0.03102472797036171,
                -0.07420192658901215,
                0.010230101644992828,
                0.04764416068792343,
                0.03988246992230415,
                0.022422686219215393,
                -0.009571723639965057,
                -0.042205922305583954,
                -0.000588333117775619,
                0.027618451043963432,
                0.01478573214262724,
                -0.032638441771268845,
                0.03291979432106018,
                -0.013696887530386448,
                0.013734951615333557,
                -0.03171687200665474,
                -0.024138081818819046,
                -0.051846157759428024,
                0.024577533826231956,
                0.02611616626381874,
                0.04618096724152565,
                -0.01475630234926939,
                -0.03887949138879776,
                -0.06297735124826431,
                -0.01407627947628498,
                0.014935556799173355,
                -0.01325319241732359,
                0.02111530303955078,
                0.02631850354373455,
                0.007767669390887022,
                0.020665239542722702,
                0.0457940511405468,
                -0.044078703969717026,
                0.00767646124586463,
                0.06378696113824844,
                0.05465148389339447,
                0.03008267469704151,
                0.05406428128480911,
                0.012934737838804722,
                -0.013260921463370323,
                -0.06090734899044037,
                0.013155573047697544,
                -0.015151767991483212,
                0.024661434814333916,
                0.044311463832855225,
                -0.005116827320307493,
                0.04523134604096413,
                0.005688644014298916,
                0.016144201159477234,
                -0.03180824592709541,
                -0.06489450484514236,
                -0.04471666365861893,
                0.008263184688985348,
                -6.19086736424829e-33,
                -0.01192432176321745,
                -0.025085942819714546,
                0.041922248899936676,
                0.0020456472411751747,
                -0.013877110555768013,
                0.027314648032188416,
                0.01585407555103302,
                -9.073018009075895e-05,
                -0.07878850400447845,
                -0.03662237524986267,
                -0.017712825909256935,
                0.0021390297915786505,
                0.02970966510474682,
                -0.010872697457671165,
                0.002069295383989811,
                -0.015170430764555931,
                -0.0038784085772931576,
                -0.007625780068337917,
                -0.009020051918923855,
                -0.02120788022875786,
                0.017061861231923103,
                0.006382475141435862,
                0.09391047805547714,
                0.03415435180068016,
                0.052300382405519485,
                0.09967213869094849,
                -0.04816754162311554,
                0.013924864120781422,
                -0.021562648937106133,
                0.004209958482533693,
                -0.020786652341485023,
                0.017460061237215996,
                0.010053956881165504,
                -0.0669652447104454,
                -0.015837179496884346,
                -0.029517022892832756,
                -0.04545671120285988,
                -0.0070299385115504265,
                0.0031183650717139244,
                -0.022008540108799934,
                -0.01786520890891552,
                -0.0128412414342165,
                0.06792659312486649,
                0.023428386077284813,
                0.05267157778143883,
                -0.0418788380920887,
                -0.03728276863694191,
                -0.006597664672881365,
                0.018308626487851143,
                0.04677590727806091,
                -0.05575645714998245,
                -0.02183968760073185,
                -0.0074501014314591885,
                0.048854731023311615,
                0.03446032851934433,
                -0.0023796139284968376,
                -0.040585752576589584,
                -0.006449478678405285,
                -0.053111206740140915,
                -0.02804185077548027,
                -0.0339234285056591,
                0.0070505826734006405,
                0.02600717730820179,
                -0.04021432250738144,
                0.009077181108295918,
                0.004417804069817066,
                -0.11452388018369675,
                0.04595143720507622,
                0.04429129511117935,
                -0.018939130008220673,
                -0.022034570574760437,
                0.04628178849816322,
                -0.11000532656908035,
                -0.0652029886841774,
                -0.07176029682159424,
                -0.025646481662988663,
                -0.017386335879564285,
                -0.010713334195315838,
                -0.010277727618813515,
                -0.0004312545352149755,
                0.04382101073861122,
                -0.027747076004743576,
                0.0539865680038929,
                0.019414158537983894,
                -0.044126953929662704,
                0.0040605394169688225,
                -0.03535830229520798,
                0.07273709028959274,
                -0.016535896807909012,
                0.0556432381272316,
                0.03476964682340622,
                0.0315711572766304,
                -0.0007391422404907644,
                -0.0022864306811243296,
                0.06918235123157501,
                -0.006214500404894352,
                0.004249174147844315,
                -0.04978412762284279,
                -0.006404984276741743,
                0.010931788943707943,
                -0.026152972131967545,
                0.07194208353757858,
                -0.061177123337984085,
                -0.03081127069890499,
                -0.004665955901145935,
                0.05075204372406006,
                -0.0303249042481184,
                -0.010910679586231709,
                0.0024856622330844402,
                0.005448926240205765,
                -0.021402690559625626,
                0.009275669232010841,
                0.05293649807572365,
                -0.025259103626012802,
                -0.0118267135694623,
                0.0020137380342930555,
                0.0006461743614636362,
                0.012259681709110737,
                -0.0002889050811063498,
                0.006277458276599646,
                0.07806678861379623,
                -0.0001500770158600062,
                -0.03452730551362038,
                -0.010165748186409473,
                -0.008611800149083138,
                0.02025514468550682,
                0.006714926101267338,
                -0.012210390530526638,
                -0.028758300468325615,
                0.02327711507678032,
                -0.007324801757931709,
                -0.045871932059526443,
                2.1959844787033944e-07,
                -0.013386771082878113,
                -0.016483142971992493,
                0.03498240187764168,
                0.05838894471526146,
                0.01416232530027628,
                0.004551006946712732,
                0.059320587664842606,
                0.03219842165708542,
                -0.024969642981886864,
                -0.0070404112339019775,
                0.03498588502407074,
                -0.026434045284986496,
                -0.03360523283481598,
                -0.04396984353661537,
                -0.04153650999069214,
                -0.03987874835729599,
                0.03281230852007866,
                -0.03420177474617958,
                -0.0050696684047579765,
                -0.03704733029007912,
                0.01257595606148243,
                0.06224057823419571,
                -0.013101140037178993,
                -0.03429320454597473,
                0.0066494690254330635,
                -0.00017635978292673826,
                0.01793513260781765,
                -0.07027830183506012,
                0.031500328332185745,
                0.03722692281007767,
                0.08515071123838425,
                0.010423989035189152,
                0.04547844082117081,
                -0.000665883591864258,
                -0.03971945121884346,
                -0.04746399074792862,
                0.003900229698047042,
                -0.012332861311733723,
                0.013257894665002823,
                -0.01027075294405222,
                -0.041465796530246735,
                -0.06623240560293198,
                -0.0196402408182621,
                -0.010735475458204746,
                -0.04293999820947647,
                0.017180604860186577,
                -0.0020850535947829485,
                -0.01769038662314415,
                0.0034464020282030106,
                -0.023250840604305267,
                -0.0312553346157074,
                -0.0011337976902723312,
                0.02335650660097599,
                0.012240871787071228,
                0.00847112201154232,
                -0.012610111385583878,
                0.02488289400935173,
                0.06516982614994049,
                0.0612531453371048,
                0.03645826503634453,
                -0.0024041153956204653,
                0.04500207304954529,
                -0.05783966928720474,
                0.05371572822332382,
                0.01569879800081253,
                0.0604732446372509,
                -0.02669869177043438,
                1.810825285007103e-34,
                -0.06117205321788788,
                -0.01636732555925846,
                -0.023096280172467232,
                0.05834325775504112,
                0.04015829414129257,
                0.003252403112128377,
                -0.08349866420030594,
                -0.007422869559377432,
                -0.004799612332135439,
                -0.0713564082980156,
                -0.0067892069928348064,
                0.0017668771324679255,
                0.01905055344104767,
                0.0017314573051407933,
                0.014835385605692863,
                -0.04851749539375305,
                -0.05183780938386917,
                0.09262874722480774,
                -0.04810348153114319,
                -0.07241947948932648,
                -0.010885871946811676,
                0.056646138429641724,
                -0.01310588326305151,
                0.035047926008701324,
                0.0264943428337574,
                0.035992152988910675,
                -0.009413594380021095,
                0.03147387132048607,
                -0.046404678374528885,
                -0.10999093949794769,
                -0.013970239087939262,
                -0.01847333088517189,
                -0.014936735853552818,
                -0.05250779539346695,
                0.009902319870889187,
                -0.007948221638798714,
                -0.010678443126380444,
                -0.060461197048425674,
                0.007734732702374458,
                0.019414620473980904,
                -0.052813682705163956,
                0.08609070628881454,
                -0.022542579099535942,
                0.01850898005068302,
                -0.06443411856889725,
                1.3132964795659063e-06,
                -0.013655014336109161,
                0.020512467250227928,
                -0.021216610446572304,
                -0.019244665279984474,
                0.05008602887392044,
                0.017454393208026886,
                -0.013769727200269699,
                -0.012532022781670094,
                0.035653021186590195,
                0.006410286761820316,
                -0.0657421424984932,
                0.01830804906785488,
                0.03717099130153656,
                0.006955204997211695,
                0.026733992621302605,
                0.021470999345183372,
                -0.04184754192829132,
                -0.04115739092230797,
                -0.05577525869011879,
                -0.06882726401090622,
                -0.02202157862484455,
                -0.0067062536254525185,
                0.020531216636300087,
                0.0450969897210598,
                0.07986278086900711,
                0.026770997792482376,
                -0.03673532232642174,
                -0.03905906900763512,
                0.003194663906469941,
                0.032386742532253265,
                0.04532071202993393,
                0.025087233632802963,
                0.009593337774276733,
                -0.00579863041639328,
                0.014452583156526089,
                0.05516429618000984,
                -0.009503106586635113,
                -0.01490832306444645,
                0.05232151597738266,
                0.006369666662067175,
                -0.023487549275159836,
                -0.01034128200262785,
                -0.024870065972208977,
                -0.019202977418899536,
                0.011942246928811073,
                0.031021634116768837,
                -0.01323938649147749,
                -0.004341308958828449,
                0.017533347010612488,
                0.04845641180872917,
                0.034940920770168304,
                -0.0196299459785223,
                0.01198966521769762,
                -0.01579229161143303,
                -0.017664704471826553,
                0.0288372952491045,
                -0.03519793599843979,
                0.014356747269630432,
                -0.0014335295418277383,
                -0.002181933494284749,
                -0.025000886991620064,
                -0.040316175669431686,
                -0.059594329446554184,
                0.06116180866956711,
                0.017188796773552895,
                0.000984939164482057,
                0.022068127989768982,
                -0.03144710510969162,
                0.04220748692750931,
                -0.03770437091588974,
                0.05580795928835869,
                -0.003843805054202676,
                -0.010121551342308521,
                0.02606956660747528,
                -0.007526907604187727,
                -0.05283704400062561,
                0.013377536088228226,
                -0.009112698957324028,
                0.06232758238911629,
                -0.00037697298103012145,
                -0.01590912416577339,
                -0.026074567809700966,
                0.030921367928385735,
                -0.03422912210226059,
                0.05204694718122482,
                0.055936478078365326,
                0.009511888958513737,
                -0.029195303097367287,
                0.007325353566557169,
                0.04613766074180603,
                -0.05586206540465355,
                -0.02905561774969101,
                0.04329274594783783,
                -0.010889210738241673,
                -0.006691026035696268,
                -0.02254306524991989,
                -0.030099855735898018,
                -0.02101927623152733,
                -0.05351140722632408,
                -0.013958216644823551,
                0.09584703296422958,
                -0.013516235165297985,
                -0.053546711802482605,
                -0.09177113324403763,
                -0.004508926533162594,
                0.029861094430088997,
                0.013067622669041157,
                -0.03216687589883804,
                -0.0008812897140160203,
                -0.0672776997089386,
                -0.054490357637405396,
                0.011077106930315495,
                -0.0019525513052940369,
                0.008251715451478958,
                -0.013966117985546589,
                -0.003507991787046194,
                0.03337162360548973,
                0.044575050473213196,
                -0.0002579183492343873,
                0.034239888191223145,
                0.0304466113448143,
                0.019821425899863243,
                -0.019525982439517975,
                -0.019839467480778694,
                0.017506958916783333,
                0.00446175504475832,
                0.038560010492801666,
                0.020683737471699715,
                0.0021814152132719755,
                -0.05494175851345062,
                0.020948300138115883,
                0.05404609441757202,
                0.043699152767658234,
                -0.05150147154927254,
                -0.013996943831443787,
                0.0028660509269684553,
                -0.028400102630257607,
                0.010501950979232788,
                0.001216946286149323,
                0.03328754007816315,
                -0.05520673468708992,
                -0.018578290939331055,
                -0.01415563840419054,
                -0.02905312180519104,
                0.08148224651813507,
                -0.013541487976908684,
                -0.12204468250274658,
                0.03295770287513733,
                0.02564176172018051,
                -0.039189018309116364,
                -0.008136005140841007,
                -0.03894371539354324,
                0.03853259235620499,
                -0.023756850510835648,
                0.012018590234220028,
                0.00420705322176218,
                -0.058524832129478455,
                -0.015287619084119797,
                -0.011420132592320442,
                0.05597288906574249,
                0.0009716494241729379,
                0.0027575152926146984,
                -0.052534621208906174,
                -0.04991483688354492,
                0.011946965008974075,
                -0.014771630056202412,
                -0.0405735969543457,
                -0.02257937379181385,
                0.025356564670801163,
                -0.018919846042990685,
                -0.04081473499536514,
                -0.10357674956321716,
                0.00020006176782771945,
                -0.06668759137392044,
                -0.016749978065490723,
                0.006970023736357689,
                -0.0008966691675595939,
                0.08513814955949783,
                0.01761333830654621,
                -0.020686250180006027,
                -0.0571291409432888,
                0.028440628200769424,
                0.015629662200808525,
                -0.0037459672894328833,
                0.04813352972269058,
                -0.0245016198605299,
                0.007937395013868809,
                0.001043854863382876,
                0.011025842279195786,
                0.08552483469247818,
                0.017550621181726456,
                0.06398151814937592,
                -0.006422445178031921,
                0.013681591488420963,
                0.053698599338531494,
                0.0016980371437966824,
                0.029655547812581062,
                -0.009796453639864922,
                -0.014123436063528061,
                0.06060858443379402,
                -0.015708481892943382,
                0.17819760739803314,
                0.0658438503742218,
                -0.004169459454715252,
                -0.06741795688867569,
                0.03039703518152237,
                0.018787730485200882,
                -0.016029689460992813,
                0.013700693845748901,
                0.08511976152658463,
                -0.006078739184886217,
                -0.023561278358101845,
                -0.0013947089901193976,
                0.005956171080470085,
                -0.001226366264745593,
                -0.05131412297487259,
                -0.036785274744033813,
                0.01478048600256443,
                0.02654794044792652,
                -0.012730392627418041,
                -0.005585718899965286,
                -0.021284904330968857,
                0.07858390361070633,
                -0.04268866777420044,
                -0.007325650192797184,
                0.005256472155451775,
                -0.035614755004644394,
                -0.009343122132122517,
                -0.020036017522215843,
                -0.003852867754176259,
                0.012584484182298183,
                0.04693193733692169,
                -0.02725750207901001,
                0.007012713234871626,
                -0.01284833811223507,
                -0.017951441928744316,
                0.017348024994134903,
                0.0007650973275303841,
                -0.015127698890864849,
                -0.020428281277418137,
                0.0338435173034668,
                0.001707287970930338,
                -0.009217169135808945,
                -0.06439147889614105,
                0.022796330973505974,
                0.04574384167790413,
                0.01811310648918152,
                -0.030111415311694145,
                0.0023788590915501118,
                0.02261093072593212,
                0.008608744479715824,
                0.0018957835854962468,
                0.0034052825067192316,
                -0.04666787013411522,
                0.011119266040623188,
                -0.003307691775262356,
                -8.710760448593646e-05,
                -0.01007925160229206,
                -0.07131501287221909,
                0.015522888861596584,
                0.03531590849161148,
                0.037474848330020905,
                -0.030302926898002625,
                0.016752813011407852,
                0.03372562304139137,
                0.05218619853258133,
                -0.03531340882182121,
                0.002659421181306243,
                0.006071685813367367,
                0.0018824697472155094,
                0.026224052533507347,
                0.022016767412424088,
                0.014811440370976925,
                -0.05185629799962044,
                -0.003538465825840831,
                0.011749177239835262,
                0.02756454609334469,
                0.010576095432043076,
                0.04232412949204445,
                0.015887217596173286,
                0.0007241084822453558,
                -0.04820643737912178,
                -0.0623335987329483,
                0.004522130358964205,
                0.09708699584007263,
                0.017354721203446388,
                -0.012356027029454708,
                -0.0022817018907517195,
                -0.03210517391562462,
                -0.009718582965433598,
                -0.03278854861855507,
                -0.015166440978646278,
                -0.01965109072625637,
                0.07174620777368546,
                -0.0458306148648262,
                -0.011012318544089794,
                0.04342637211084366,
                0.009930718690156937,
                0.017482221126556396,
                -0.001499443780630827,
                0.02207998000085354,
                -0.04570280387997627,
                -0.06361248344182968,
                -0.03608271852135658,
                -0.0441138930618763,
                -0.03150758147239685,
                -0.040812280029058456,
                -0.0610622800886631,
                -0.017507586628198624,
                -0.009913750924170017,
                -0.029935823753476143,
                -0.06999123096466064,
                0.007688477635383606,
                0.006683371961116791,
                -0.02308926172554493,
                0.06244795769453049,
                0.015055789612233639,
                -0.031146375462412834,
                -0.008318996988236904,
                -0.02598736621439457,
                0.0330733060836792,
                0.0028235733043402433,
                -0.06891746819019318,
                -0.03056040033698082,
                0.0401642732322216,
                -0.09686543047428131,
                -0.042950570583343506,
                0.014001783914864063,
                -0.023884562775492668,
                -0.05312788486480713,
                0.012653481215238571,
                0.045181792229413986,
                0.004421522840857506,
                -0.022538140416145325,
                0.012352139689028263,
                0.013022649101912975,
                0.02250993810594082,
                -0.008386560715734959,
                -0.013213250786066055,
                -0.007324930280447006,
                0.021816523745656013,
                0.011449686251580715,
                0.015456519089639187,
                -0.0066515193320810795,
                0.01542392373085022,
                0.006591469515115023,
                -0.02362815849483013,
                0.04466656595468521,
                0.02453215792775154,
                0.023577917367219925,
                -0.03307918459177017,
                -0.013552045449614525,
                -0.0414135679602623,
                -0.03240371122956276,
                0.03400459885597229,
                -0.01685916632413864,
                -0.002019452629610896,
                -0.030435848981142044,
                0.011054031550884247,
                0.038097355514764786,
                -0.00942376721650362,
                -0.07278191298246384,
                0.020932288840413094,
                0.03331992030143738,
                0.04569875821471214,
                0.04856635630130768,
                -0.005056146997958422,
                -0.015549425035715103,
                0.013025050051510334,
                -0.027483981102705002,
                -0.025852899998426437,
                0.05614495649933815,
                -0.006567577365785837,
                0.028122086077928543,
                -0.049105945974588394,
                0.022297171875834465,
                -0.026732120662927628,
                -0.014483828097581863,
                0.022206315770745277,
                0.024109192192554474,
                0.013944110833108425,
                -0.018825169652700424,
                0.036918919533491135,
                0.03397161141037941,
                -0.012283377349376678,
                -0.019716251641511917,
                0.04030250012874603,
                -0.01708141341805458,
                -0.07161062955856323,
                0.04774441570043564,
                -0.03944665938615799,
                -0.015451876446604729,
                0.04682578146457672,
                -0.004390505142509937,
                -0.047164712101221085,
                -0.014029956422746181,
                0.005757939536124468,
                -0.003938752226531506,
                0.022358549758791924,
                -0.0650286003947258,
                0.0040480284951627254,
                0.037148598581552505,
                0.01188808586448431,
                0.01880670338869095,
                -0.008618256077170372,
                -0.02717399410903454,
                0.05413702875375748,
                0.03368925303220749,
                0.0020927421282976866,
                -0.05725410580635071,
                -0.054301295429468155,
                0.039538510143756866,
                -0.02039475180208683,
                -0.05618331953883171,
                0.024927182123064995,
                0.04795413091778755,
                0.008587258867919445,
                0.009715698659420013,
                -0.0324627049267292,
                -0.03335491567850113,
                -0.0006116249714978039,
                -0.08149945735931396,
                -0.03974410146474838,
                -0.01267968025058508,
                0.05271027609705925,
                0.03969414159655571,
                0.039816755801439285,
                -0.06489573419094086,
                0.0015887506306171417,
                0.016685334965586662,
                0.029413850978016853,
                -0.02099054679274559,
                0.009948816150426865,
                -0.05055497959256172,
                -0.023170914500951767,
                -0.041496358811855316,
                0.009710216894745827,
                -0.035146743059158325,
                0.02625492587685585,
                0.02356015332043171,
                -0.004016943741589785,
                -0.06633695214986801,
                0.007315758615732193,
                -0.049181871116161346,
                0.04582326486706734,
                0.0121232271194458,
                0.06065927445888519,
                -0.024363165721297264,
                0.007928128354251385,
                0.011475604958832264,
                0.059427641332149506,
                0.0033339522778987885,
                0.02862938493490219,
                0.02613982930779457,
                0.005634899716824293,
                -0.010065849870443344,
                -0.04247773438692093,
                -0.02020459622144699,
                0.02544531784951687,
                0.03505579009652138,
                0.07200168073177338,
                0.006953318603336811,
                0.018060551956295967,
                0.08085664361715317,
                0.0075418995693326,
                0.03040793351829052,
                0.039734892547130585,
                0.030188063159585,
                -0.00957370363175869,
                -0.02952551655471325,
                -0.0021718258503824472,
                0.012030589394271374,
                -0.00023014786711428314,
                -0.019724419340491295,
                -0.021842868998646736,
                -0.008463769219815731,
                -0.003724612994119525,
                -0.013351463712751865,
                -0.08112266659736633,
                0.025021854788064957,
                0.02142021432518959,
                0.028602132573723793,
                0.06400665640830994,
                -0.02918449230492115,
                -0.03396179527044296,
                0.05238785222172737,
                -0.020859288051724434,
                0.01729660853743553,
                -0.029913052916526794,
                -0.004593140911310911,
                0.019554810598492622,
                0.01645543798804283,
                0.011373686604201794,
                0.030788905918598175,
                0.04820021241903305,
                -0.007705694064497948,
                0.018770547583699226,
                0.012232422828674316,
                0.024290619418025017,
                -0.03757685050368309,
                0.03448186814785004,
                -0.010644519701600075,
                0.1255505383014679,
                -0.015466928482055664,
                0.001419220818206668,
                -0.02548368088901043,
                -0.018367797136306763,
                0.011232803575694561,
                -0.011433756910264492,
                -0.00287907593883574,
                -0.012403817847371101,
                0.022838454693555832,
                -0.027784045785665512,
                0.02378387190401554,
                0.02060876227915287,
                0.005078436341136694,
                -0.004336935933679342,
                -0.04617052525281906,
                0.0019672508351504803,
                -0.009342875331640244,
                -6.050422605289905e-33,
                0.031957127153873444,
                -0.017488738521933556,
                0.041926998645067215,
                -0.009373635053634644,
                -0.056921981275081635,
                0.021571215242147446,
                -0.013685130514204502,
                0.01586398109793663,
                -0.06529312580823898,
                -0.04368352144956589,
                -0.014464989304542542,
                0.023970965296030045,
                0.04098294675350189,
                -0.014289859682321548,
                0.01387160737067461,
                0.0322515107691288,
                -0.014765561558306217,
                0.024717558175325394,
                -0.002988414606079459,
                -0.014452270232141018,
                0.0047998977825045586,
                0.02037653885781765,
                -0.011100850999355316,
                0.0029109844472259283,
                0.056725047528743744,
                0.04473501443862915,
                -0.031758252531290054,
                0.0002768510312307626,
                -0.0074089448899030685,
                -0.022996675223112106,
                -0.015846192836761475,
                -0.02652529627084732,
                0.014861015602946281,
                0.006138730328530073,
                -0.0026728820521384478,
                0.015177475288510323,
                -0.017583083361387253,
                0.018763158470392227,
                -0.03206508979201317,
                -0.022914277389645576,
                -0.018048085272312164,
                -0.06427662819623947,
                -0.005982903763651848,
                0.028095411136746407,
                -0.03360283747315407,
                0.0021530722733587027,
                -0.032142844051122665,
                0.0003644319949671626,
                0.02708107978105545,
                0.0036144950427114964,
                -0.008239349350333214,
                -0.026794977486133575,
                -0.038357801735401154,
                0.05051499232649803,
                0.0015762279508635402,
                -0.038331951946020126,
                0.0027905208989977837,
                0.04028642177581787,
                0.053506165742874146,
                0.0017193576786667109,
                -0.044666752219200134,
                0.03621868044137955,
                0.03290384262800217,
                -0.04875738173723221,
                -0.03842359781265259,
                -0.016698019579052925,
                -0.09488954395055771,
                -0.022858191281557083,
                0.05613209679722786,
                -0.058051686733961105,
                -0.01584111899137497,
                0.10620784759521484,
                0.03151281550526619,
                0.0464940182864666,
                -0.012813130393624306,
                -0.01955612190067768,
                -0.024868536740541458,
                -0.0030245662201195955,
                0.08982004970312119,
                0.0432087741792202,
                -0.000817007094155997,
                0.019887501373887062,
                0.037905462086200714,
                0.021790724247694016,
                0.01915116421878338,
                -0.012933327816426754,
                -0.029972659423947334,
                0.060959093272686005,
                -0.002281344961374998,
                -0.004637402947992086,
                -0.03294181078672409,
                0.057559624314308167,
                -0.035712435841560364,
                0.029589688405394554,
                0.13518276810646057,
                0.0400962196290493,
                -0.006932652555406094,
                0.004258250817656517,
                -0.003609054023399949,
                -0.013785642571747303,
                -0.009683165699243546,
                0.03126239404082298,
                -0.007679979782551527,
                0.004778856877237558,
                0.009246098808944225,
                0.003423196030780673,
                -0.06729920208454132,
                -0.009019152261316776,
                -0.028364485129714012,
                0.006125408690422773,
                -0.01686582900583744,
                0.05760816112160683,
                -0.016795022413134575,
                0.023298293352127075,
                -0.012254149653017521,
                -0.0329480841755867,
                0.00659516267478466,
                -0.10499126464128494,
                0.023170776665210724,
                -0.08316345512866974,
                0.02568170800805092,
                0.02394220419228077,
                -0.018124094232916832,
                0.01692633330821991,
                -0.017390618100762367,
                0.024407558143138885,
                0.03916545212268829,
                0.01920829527080059,
                -0.007807874120771885,
                -0.03840246796607971,
                -0.024589812383055687,
                -0.0008525589946657419,
                1.9369377923794673e-07,
                -0.039084937423467636,
                0.02320021577179432,
                0.044796936213970184,
                0.0533040389418602,
                -0.0017943175043910742,
                0.012652650475502014,
                -0.004769777879118919,
                0.06918613612651825,
                -0.03657493740320206,
                0.02192707359790802,
                0.03216656669974327,
                -0.04531356319785118,
                -0.019094014540314674,
                -0.010784042067825794,
                0.01952485740184784,
                -0.03691538795828819,
                -0.013449051417410374,
                0.00512703275308013,
                -0.0522499680519104,
                -0.0030716017354279757,
                0.04813311994075775,
                0.015008693560957909,
                0.02877804823219776,
                -0.022494342178106308,
                -0.031108101829886436,
                -0.008696239441633224,
                -0.045873526483774185,
                0.041060976684093475,
                0.03994721174240112,
                -0.011417740024626255,
                0.08144436776638031,
                0.04253900423645973,
                -0.0036554145626723766,
                0.06948180496692657,
                0.006403198000043631,
                -0.07721097767353058,
                -0.016355128958821297,
                -0.022047264501452446,
                0.04682639241218567,
                0.0016488363035023212,
                -0.07877624034881592,
                -0.05918993800878525,
                -0.0427958182990551,
                0.03507986664772034,
                0.055739887058734894,
                0.07342378050088882,
                0.01398039236664772,
                0.09999522566795349,
                -0.03785957023501396,
                0.016349980607628822,
                -0.009273836389183998,
                0.034337118268013,
                0.027063554152846336,
                -0.021588824689388275,
                0.016430364921689034,
                -0.011844172142446041,
                0.01406511478126049,
                0.024796796962618828,
                0.03812065348029137,
                0.02699444256722927,
                -0.050572507083415985,
                0.0040866839699447155,
                0.006519291549921036,
                0.05042634159326553,
                -0.014375329948961735,
                0.03611757233738899,
                -0.04321432113647461,
                8.606744639755202e-35,
                0.0010695920791476965,
                -0.031933192163705826,
                -0.06987238675355911,
                -0.013623801060020924,
                -0.03515341505408287,
                0.00922003760933876,
                -0.05644413083791733,
                -0.007264530751854181,
                -0.01872280240058899,
                -0.01299772784113884,
                -0.026265516877174377,
                0.007965468801558018,
                -0.01333691831678152,
                -0.01122219767421484,
                -0.02471611090004444,
                0.018650298938155174,
                0.013172715902328491,
                -0.009566069580614567,
                -0.03723566234111786,
                -0.10319801419973373,
                0.017191367223858833,
                0.04753879830241203,
                0.006452538538724184,
                -0.03366822376847267,
                0.08619888871908188,
                0.027696069329977036,
                0.04895354062318802,
                0.040446504950523376,
                -0.0358726941049099,
                -0.07170585542917252,
                0.002923812950029969,
                0.008698536083102226,
                -0.0046488214284181595,
                0.0019837617874145508,
                0.017710916697978973,
                -0.024339469149708748,
                -0.029777729883790016,
                0.022898752242326736,
                0.030348708853125572,
                -0.014794924296438694,
                0.0064509049989283085,
                0.004238117020577192,
                0.017909571528434753,
                0.003727867966517806,
                0.01749459281563759,
                1.7471691080572782e-06,
                0.019274288788437843,
                0.01545738521963358,
                -0.048615895211696625,
                -0.027048420161008835,
                0.0020811804570257664,
                -0.038419459015131,
                -0.012658189982175827,
                -0.03163224831223488,
                0.023870989680290222,
                -0.03399160876870155,
                0.09148438274860382,
                -0.011162426322698593,
                0.0503101609647274,
                -0.011197764426469803,
                0.033568862825632095,
                -0.0018539847806096077,
                0.04845763370394707,
                -0.06878538429737091,
                -0.03192082792520523,
                -0.05438857525587082,
                -0.04355933889746666,
                -0.039254821836948395,
                0.03147510439157486,
                -0.014815299771726131,
                0.028594650328159332,
                -0.018522923812270164,
                -0.009492514654994011,
                0.023622196167707443,
                -0.0031361097935587168,
                0.0610315166413784,
                0.03970308229327202,
                0.051584795117378235,
                -0.02341003157198429,
                0.007331311237066984,
                -0.06631585210561752,
                0.028197497129440308,
                0.016673438251018524,
                -0.015650294721126556,
                0.03980054706335068,
                0.00660818163305521,
                0.022857433184981346,
                0.020399466156959534,
                0.00031781388679519296,
                0.0032069452572613955,
                0.010717804543673992,
                -0.082574263215065,
                0.020140083506703377,
                -0.007907412946224213,
                -1.9308801711304113e-05,
                -0.013679628260433674,
                0.02501140534877777,
                -0.025745939463377,
                0.010431469418108463,
                -0.004741939716041088,
                0.0403938889503479,
                0.002813847502693534,
                -0.020019926130771637,
                -0.02297167479991913,
                0.010945141315460205,
                -0.02075652778148651,
                -0.030227193608880043,
                -0.014808922074735165,
                -0.034726519137620926,
                0.005466468632221222,
                0.028587276116013527,
                -0.008082407526671886,
                -0.04322614148259163,
                0.03837345913052559,
                -0.002385033993050456,
                0.03133818507194519,
                0.04942755401134491,
                0.035849373787641525,
                0.027879325672984123,
                -0.01704847812652588,
                0.0334150567650795,
                -0.016863759607076645,
                0.03547598049044609,
                0.03228297457098961,
                0.007556822616606951,
                -0.016261180862784386,
                -0.06655251979827881,
                -0.016612593084573746,
                0.013244697824120522,
                0.06149562820792198,
                0.009595158509910107,
                -0.043898794800043106,
                0.0006409373017959297,
                -0.06712628155946732,
                0.023991528898477554,
                -0.018705112859606743,
                -0.0026126725133508444,
                -0.05265241116285324,
                0.07224128395318985,
                0.011286933906376362,
                0.03361539915204048,
                0.0053947241976857185,
                -0.018730206415057182,
                -0.04393318295478821,
                -0.022224538028240204,
                0.02221675217151642,
                -0.06249120831489563,
                -0.03685272857546806,
                0.047704730182886124,
                -0.023450596258044243,
                -0.024916190654039383,
                0.00039655770524404943,
                0.001454785349778831,
                -0.08591639995574951,
                -0.00209841993637383,
                -0.017801698297262192,
                -0.025997547432780266,
                -0.011421547271311283,
                0.020007865503430367,
                0.0037321331910789013,
                -0.01122722402215004,
                0.025302492082118988,
                -0.021781431511044502,
                -0.007600951008498669,
                -0.004878392443060875,
                0.044929809868335724,
                0.041195910423994064,
                -0.030356712639331818,
                -0.03385325148701668,
                0.011750275269150734,
                0.06929517537355423,
                -0.006137646734714508,
                0.0067078289575874805,
                0.045292455703020096,
                0.043738752603530884,
                -0.07281090319156647,
                -0.00924765970557928,
                0.05844394490122795,
                -0.04059780016541481,
                0.006528253201395273,
                -0.04938569292426109,
                0.020853767171502113,
                -0.02132883109152317,
                0.004785525146871805,
                0.0678461492061615,
                0.03092474862933159,
                -0.041811585426330566,
                0.002630372764542699,
                -0.01549522951245308,
                -0.06527122110128403,
                -0.05327229201793671,
                -0.05456326901912689,
                -0.09262854605913162,
                0.04194273799657822,
                0.005461536347866058,
                0.04004490002989769,
                -0.01635161228477955,
                -0.01880492828786373,
                0.05173594132065773,
                -0.02744031697511673,
                0.012870301492512226,
                0.04936014860868454,
                0.004380479920655489,
                -0.11563858389854431,
                0.04388051852583885,
                0.009241046383976936,
                0.010637986473739147,
                -0.015012440271675587,
                0.014196760021150112,
                0.02305452711880207,
                -0.04467930644750595,
                -0.026447905227541924,
                0.0517922043800354,
                -0.03667323663830757,
                -0.027758680284023285,
                -0.02932109124958515,
                -0.034836988896131516,
                -0.08757870644330978,
                -0.030420616269111633,
                0.003389129415154457,
                0.026586096733808517,
                -0.00462327990680933,
                -0.00827424693852663,
                -0.008910693228244781,
                0.03823450952768326,
                -0.017653880640864372,
                0.0018334785709157586,
                0.01714755780994892,
                0.014134750701487064,
                -0.011634343303740025,
                0.01836642436683178,
                -0.024507218971848488,
                0.057577673345804214,
                -0.058733247220516205,
                0.044685471802949905,
                -0.032484736293554306,
                -0.04226100817322731,
                0.037623096257448196,
                -0.027359185740351677,
                0.056707076728343964,
                -0.011006818152964115,
                -0.023289455100893974,
                -0.0007924500969238579,
                0.037369586527347565,
                -0.023091953247785568,
                0.017415199428796768,
                0.010059847496449947,
                0.036494772881269455,
                -0.05131958797574043,
                0.00243773078545928,
                -0.007653229869902134,
                -0.0022256893571466208,
                0.04139671474695206,
                -0.019467810168862343,
                -0.034862760454416275,
                0.036468755453825,
                0.007874364033341408,
                -0.04696623235940933,
                0.008805418387055397,
                0.0016584477853029966,
                0.004782200325280428,
                -0.02860226109623909,
                0.00026974506909027696,
                -0.01619642786681652,
                0.01554048340767622,
                -0.026730570942163467,
                -0.06139232963323593,
                -0.052797023206949234,
                0.0009723746916279197,
                -0.004632178694009781,
                -0.003379079047590494,
                0.04393790662288666,
                -0.0516681894659996,
                -0.0047393119893968105,
                0.0019344621105119586,
                0.044584956020116806,
                0.001489447196945548,
                -0.031883448362350464,
                -0.04374825209379196,
                -0.010425559245049953,
                -0.016385052353143692,
                0.030666489154100418,
                -0.024121815338730812,
                0.022392060607671738,
                -0.02151498571038246,
                -0.023063234984874725,
                0.00018945576448459178,
                -0.01221716683357954,
                0.021533764898777008,
                -0.014910129830241203,
                -0.03366681933403015,
                0.019508199766278267,
                0.021239610388875008,
                -0.028584975749254227,
                0.012447713874280453,
                0.019191164523363113,
                0.015430505387485027,
                0.026463782414793968,
                0.04580762982368469,
                -0.0033052873332053423,
                0.018366822972893715,
                -0.06667404621839523,
                -0.0066408622078597546,
                -0.00843342486768961,
                -0.005491064861416817,
                0.03517955169081688,
                0.015766967087984085,
                -0.04021807760000229,
                -0.03854871541261673,
                0.023829124867916107,
                0.035566557198762894,
                0.028713233768939972,
                -0.02456778660416603,
                0.04086161032319069,
                0.0022735861130058765,
                -0.001776541001163423,
                -0.08647904545068741,
                -0.0414857491850853,
                0.031153662130236626,
                0.0338866263628006,
                0.003456941107288003,
                0.027911845594644547,
                0.041459228843450546,
                0.03957098722457886,
                -0.03444203734397888,
                0.02477213367819786,
                -0.044667020440101624,
                0.09835722297430038,
                0.0031677274964749813,
                0.022560464218258858,
                0.007631005719304085,
                -0.0377020500600338,
                0.0302733201533556,
                -0.014182887971401215,
                -0.023316141217947006,
                0.008438733406364918,
                0.005473794415593147,
                -0.08017963171005249,
                -0.0068929847329854965,
                -0.009477871470153332,
                -0.07562536001205444,
                -0.009565560147166252,
                0.024839282035827637,
                -0.01164929661899805,
                0.013175454922020435,
                -0.058547839522361755,
                -0.036538273096084595,
                -0.04299928992986679,
                -0.07377449423074722,
                -0.03542573004961014,
                0.02475462481379509,
                0.015662088990211487,
                0.0087483711540699,
                -0.007276169490069151,
                0.06257311254739761,
                -0.05523085594177246,
                -0.026431260630488396,
                0.04725136607885361,
                0.05275280773639679,
                -0.07070094347000122,
                0.027491966262459755,
                0.030706757679581642,
                0.04123877361416817,
                -0.009011623449623585,
                -0.013152475468814373,
                -0.00026549134054221213,
                -0.02834184467792511,
                -0.0022708321921527386,
                0.004430457949638367,
                0.016888445243239403,
                -0.07522276043891907,
                0.006921761203557253,
                0.0029236911796033382,
                0.14155313372612,
                -0.04104474559426308,
                0.00788765400648117,
                -0.006162801757454872,
                -0.008885577321052551,
                0.0005080379196442664,
                0.012983832508325577,
                0.010895916260778904,
                0.04117215424776077,
                0.015493903309106827,
                -0.02717447094619274,
                0.04167665168642998,
                0.0028062283527106047,
                -0.005128125660121441,
                0.0292345080524683,
                0.02648835815489292,
                0.011506342329084873,
                0.013472315855324268,
                0.02573125995695591,
                0.034232303500175476,
                -0.009722236543893814,
                -0.013714279048144817,
                0.026233384385704994,
                0.0031434420961886644,
                0.00017667615611571819,
                -0.008020874112844467,
                0.028048960492014885,
                0.01341643463820219,
                0.01386412512511015,
                -0.12257479876279831,
                -0.0037440683227032423,
                0.011259649880230427,
                0.002345436718314886,
                -0.04726848751306534,
                0.007372288964688778,
                0.01958600990474224,
                -0.012316131964325905,
                0.026758169755339622,
                0.01574273593723774,
                0.06238064542412758,
                0.018129641190171242,
                0.04721100255846977,
                -0.05664132907986641,
                -0.014196142554283142,
                0.07959847152233124,
                -0.03931243345141411,
                0.03266621381044388,
                0.0467713288962841,
                0.03229627385735512,
                0.003160857129842043,
                0.04317038133740425,
                -0.008665937930345535,
                0.022824188694357872,
                -0.06602796912193298,
                -0.019321464002132416,
                0.03217974677681923,
                -0.011152954772114754,
                0.017589949071407318,
                0.058114971965551376,
                0.029570138081908226,
                -0.000516275002155453,
                -0.008381460793316364,
                0.011387634091079235,
                0.02105163224041462,
                0.002692327369004488,
                0.01836586743593216,
                -0.02888680063188076,
                0.011689807288348675,
                0.007632708176970482,
                -0.020521918311715126,
                0.010604777373373508,
                0.04490754008293152,
                0.06197743862867355,
                -0.042270202189683914,
                0.043099090456962585,
                0.06911870092153549,
                0.03141738101840019,
                0.010450395755469799,
                -0.04518492519855499,
                0.13817641139030457,
                0.010267440229654312,
                -0.026888476684689522,
                0.009876172989606857,
                -0.08754671365022659,
                0.03374001756310463,
                -0.03463244065642357,
                -0.03535187616944313,
                -0.0019495976157486439,
                0.006158643867820501,
                -0.019273556768894196,
                -0.03962182626128197,
                -0.031069021672010422,
                0.04761355742812157,
                0.014023392461240292,
                -0.07591318339109421,
                -0.027254324406385422,
                -0.01241213921457529,
                -0.07174108922481537,
                0.0310087651014328,
                0.03443608060479164,
                -0.018751535564661026,
                0.05004867538809776,
                -0.021818507462739944,
                -0.028829427435994148,
                -0.03879636526107788,
                -0.1439020186662674,
                0.06421873718500137,
                -0.06176827847957611,
                -0.0033714596647769213,
                -0.0037855368573218584,
                -0.023613082244992256,
                -0.016254471614956856,
                0.06585336476564407,
                -0.030763844028115273,
                0.01997108943760395,
                -0.050871215760707855,
                0.07419955730438232,
                0.014215046539902687,
                -0.03167394921183586,
                0.00045348453568294644,
                0.008233161643147469,
                -0.038525279611349106,
                0.03493132069706917,
                0.008015250787138939,
                0.026142695918679237,
                -0.02174127660691738,
                -0.03706471249461174,
                -0.004321359563618898,
                0.044496387243270874,
                -0.03249705582857132,
                0.03720623254776001,
                -0.00023707750369794667,
                -0.05381377413868904,
                0.07327856868505478,
                -0.013722714968025684,
                -0.0224558524787426,
                0.030403969809412956,
                -0.09473101049661636,
                -0.004923446103930473,
                0.032219789922237396,
                -0.011214134283363819,
                -0.01163099892437458,
                0.023164795711636543,
                0.0007209431496448815,
                0.05257654935121536,
                -0.027148813009262085,
                -0.01330268383026123,
                0.025515375658869743,
                0.039140794426202774,
                -0.019636036828160286,
                -0.006684699561446905,
                -0.04388737678527832,
                0.018385639414191246,
                -0.03824891522526741,
                0.02165677584707737,
                0.02747000940144062,
                0.03476516157388687,
                0.029677800834178925,
                0.0399763360619545,
                -0.04943350702524185,
                -0.019614871591329575,
                -0.053948331624269485,
                0.02990809641778469,
                -0.04151253402233124,
                0.027134642004966736,
                -0.009363237768411636,
                0.035471998155117035,
                0.04117746651172638,
                0.023656655102968216,
                -0.009500982239842415,
                -0.03398699313402176,
                0.05650278180837631,
                -0.02945249155163765,
                0.06425165385007858,
                0.025361908599734306,
                -0.034784164279699326,
                0.03203849121928215,
                0.01127844862639904,
                -0.04434509575366974,
                0.03878629207611084,
                0.07907555252313614,
                -0.020816123113036156,
                0.028817003592848778,
                -0.007511598523706198,
                -0.03551977872848511,
                0.01587708480656147,
                -0.028770744800567627,
                0.06554992496967316,
                -0.005897742696106434,
                -0.07443922013044357,
                -0.0037793137598782778,
                0.004855101462453604,
                0.026104731485247612,
                -6.370050906880009e-33,
                -0.009626701474189758,
                -0.051059119403362274,
                0.039712805300951004,
                -0.045918986201286316,
                -0.027337411418557167,
                -0.003048176411539316,
                -0.011357973329722881,
                -0.0133667578920722,
                0.00914833229035139,
                -0.028107494115829468,
                -0.012419064529240131,
                0.004372970666736364,
                0.028253905475139618,
                -0.0223497673869133,
                0.08525995910167694,
                0.033769719302654266,
                0.043415676802396774,
                -0.014091750606894493,
                0.018479852005839348,
                0.04100530967116356,
                0.003965410403907299,
                0.06364420056343079,
                0.015514903701841831,
                0.07590095698833466,
                -0.0524778887629509,
                0.007095723412930965,
                -0.012513655237853527,
                -0.017220724374055862,
                0.024359431117773056,
                -0.023228222504258156,
                0.02394602820277214,
                -0.06945126503705978,
                -0.005463670939207077,
                0.0038876908365637064,
                0.019688041880726814,
                -0.0027065598405897617,
                0.0718967467546463,
                0.011676927097141743,
                -0.050278112292289734,
                -0.03245723247528076,
                -0.001263718237169087,
                0.047590721398591995,
                0.002547226846218109,
                -0.029267240315675735,
                0.022848866879940033,
                -0.01988016813993454,
                0.031852878630161285,
                0.032898932695388794,
                -0.012052293866872787,
                -0.09422269463539124,
                -0.0116581367328763,
                0.012495061382651329,
                -0.011422431096434593,
                -0.02353000082075596,
                -0.04081602022051811,
                0.044170208275318146,
                -0.030481288209557533,
                -0.0712750181555748,
                0.025280693545937538,
                0.023827075958251953,
                0.056916460394859314,
                0.022534888237714767,
                -0.012461865320801735,
                0.06497278809547424,
                -0.07314048707485199,
                0.003421651665121317,
                -0.05005266144871712,
                0.04888222739100456,
                -0.010698597878217697,
                0.06814417243003845,
                -0.015718000009655952,
                0.08444302529096603,
                0.029663002118468285,
                -0.027618149295449257,
                -0.021519741043448448,
                0.009216665290296078,
                0.039594683796167374,
                0.001253759954124689,
                0.05009382218122482,
                0.08821514993906021,
                -0.03921627998352051,
                0.025684883818030357,
                -0.008414470590651035,
                0.015525227412581444,
                -0.004449103493243456,
                -0.04730186238884926,
                -0.020706631243228912,
                0.02268308401107788,
                0.021842025220394135,
                -0.018123552203178406,
                -0.05132812261581421,
                0.02141646109521389,
                0.001901329611428082,
                0.0568120963871479,
                0.03548235818743706,
                -0.04463053494691849,
                0.016758978366851807,
                -0.0011781674111261964,
                -0.037952110171318054,
                -0.006884024478495121,
                -0.05572119355201721,
                0.005918274633586407,
                0.00749061768874526,
                0.027031082659959793,
                -0.0026226609479635954,
                -0.03778132051229477,
                -0.0053356848657131195,
                -0.030220596119761467,
                0.006052603479474783,
                -0.0019487931858748198,
                0.010605077259242535,
                0.03267889469861984,
                0.030089866369962692,
                -0.06557147204875946,
                -0.01312319841235876,
                -0.028703201562166214,
                0.014251381158828735,
                -0.04544202610850334,
                -0.0067571303807199,
                -0.13327179849147797,
                -0.0023770283441990614,
                0.03156935051083565,
                -0.027239201590418816,
                0.02212493121623993,
                -0.02655814215540886,
                -0.019333673641085625,
                -0.003661372000351548,
                -0.01223633624613285,
                0.023269079625606537,
                -0.011352071538567543,
                0.002981702797114849,
                0.002313716569915414,
                2.424090723707195e-07,
                -0.0016244202852249146,
                0.0530669130384922,
                -0.00382488826289773,
                -0.016264289617538452,
                -0.004817901644855738,
                0.030516119673848152,
                0.00580222625285387,
                -0.0020535036455839872,
                -0.004231560043990612,
                -0.027153540402650833,
                0.02376781962811947,
                0.012143881991505623,
                0.008087243884801865,
                0.019964873790740967,
                0.04301767796278,
                -0.06266158819198608,
                -0.018075503408908844,
                -0.014790153130888939,
                -0.03261818364262581,
                -0.021204443648457527,
                -0.034017931669950485,
                0.0031749177724123,
                0.008286036550998688,
                -0.019775180146098137,
                -0.010436075739562511,
                -0.03360917046666145,
                -0.020746948197484016,
                0.03451024740934372,
                0.05916149914264679,
                0.042671531438827515,
                -0.021726803854107857,
                -0.06286223977804184,
                -0.056559350341558456,
                0.013782025314867496,
                0.03976127877831459,
                -0.04105423390865326,
                0.004758245777338743,
                -0.01741624064743519,
                -0.017857415601611137,
                0.01859930530190468,
                -0.03783107548952103,
                -0.001792488037608564,
                -0.041064005345106125,
                0.08431607484817505,
                -0.02535843849182129,
                -0.037541989237070084,
                -0.00035727123031392694,
                0.03500756248831749,
                -0.04565482586622238,
                -0.00616399385035038,
                -0.003618626855313778,
                0.03976338356733322,
                0.053150467574596405,
                0.042778488248586655,
                -0.0008257426088675857,
                0.0017538934480398893,
                -0.03225315734744072,
                0.03799686208367348,
                0.0223875530064106,
                0.015246578492224216,
                -0.039059363305568695,
                0.02931840531527996,
                0.02917945198714733,
                -0.004364133346825838,
                -0.07504699379205704,
                0.021739929914474487,
                -0.016931692138314247,
                1.549716650826409e-34,
                -0.008195857517421246,
                0.04733840748667717,
                -0.0035673838574439287,
                0.0864056646823883,
                -0.00692304503172636,
                -0.00460639875382185,
                0.01420927420258522,
                -0.0037669967859983444,
                -0.018301311880350113,
                -0.00500578572973609,
                -0.030343761667609215,
                0.039214592427015305,
                -0.004869171418249607,
                -0.03575167804956436,
                -0.056937288492918015,
                0.04229044169187546,
                -0.012769731692969799,
                -0.030279027298092842,
                -0.032380346208810806,
                -0.09298543632030487,
                0.027041038498282433,
                0.005359778646379709,
                0.053271327167749405,
                -0.011644065380096436,
                0.04618942737579346,
                -0.03223399445414543,
                -0.0902465358376503,
                0.027279198169708252,
                -0.045002978295087814,
                -0.07714362442493439,
                0.016598539426922798,
                0.031810589134693146,
                -0.016166063025593758,
                -0.010023066774010658,
                0.041349951177835464,
                -0.0052479165606200695,
                0.02728116698563099,
                -0.02123534493148327,
                0.02019704319536686,
                -0.0039716060273349285,
                -0.027388524264097214,
                0.0065172710455954075,
                -0.02014465071260929,
                -0.014686868526041508,
                -0.034223515540361404,
                1.3033790082772612e-06,
                0.01817948929965496,
                -0.003922463860362768,
                -0.054957497864961624,
                -0.007433057762682438,
                0.05523007735610008,
                -0.033675678074359894,
                0.018375201150774956,
                0.0008391487062908709,
                0.03703990951180458,
                0.008630556054413319,
                0.009431621991097927,
                0.025393744930624962,
                0.051791056990623474,
                -0.006823907606303692,
                -0.034450680017471313,
                0.01300344243645668,
                -0.00035461370134726167,
                -0.05686444789171219,
                -0.028422316536307335,
                -0.005379607900977135,
                -0.06795783340930939,
                -0.008188893087208271,
                -0.011055811308324337,
                -0.022088151425123215,
                0.029913539066910744,
                -0.0038242312148213387,
                -0.01965952105820179,
                0.030939126387238503,
                0.01413709856569767,
                0.03475957736372948,
                0.03107648342847824,
                0.0231249388307333,
                -0.02328638918697834,
                -0.018970226868987083,
                -0.02996521256864071,
                0.038216061890125275,
                0.019357483834028244,
                -0.021621577441692352,
                -0.020720887929201126,
                0.03746173158288002,
                -0.054824549704790115,
                0.006460205651819706,
                -0.007665202487260103,
                0.018499264493584633,
                0.0438225194811821,
                -0.08266229927539825,
                0.03158903121948242,
                -0.05522151291370392,
                0.0035122577100992203,
                0.0373501218855381,
                0.00930663663893938,
                -0.04543471336364746,
                0.03747539222240448,
                -0.02176811173558235,
                0.02294549159705639,
                -0.008408261463046074,
                0.029097547754645348,
                0.009198814630508423,
                0.051331352442502975,
                -0.019748210906982422,
                -0.02602566033601761,
                -0.025453699752688408,
                -0.02519569732248783,
                0.024675102904438972,
                0.020717693492770195,
                -0.034315116703510284,
                -0.05691726878285408,
                0.04097425937652588,
                -0.00852909591048956,
                0.041392359882593155,
                0.05368013307452202,
                -0.024257717654109,
                -0.05807871371507645,
                -0.004157274030148983,
                -0.003093186067417264,
                -0.020979341119527817,
                0.02913925051689148,
                -0.023761332035064697,
                0.029549403116106987,
                0.02688087895512581,
                -0.00041759712621569633,
                -0.02686363272368908,
                -0.014499344862997532,
                0.013350198976695538,
                -0.0002903999702539295,
                -0.0011584270978346467,
                0.007054722867906094,
                -0.051889073103666306,
                0.02942870743572712,
                -0.01074362825602293,
                0.04531564190983772,
                -0.057753920555114746,
                0.04407400265336037,
                -0.06776507198810577,
                0.07638765126466751,
                0.055846456438302994,
                -0.01938057318329811,
                -0.017978211864829063,
                0.015586473979055882,
                -0.007863234728574753,
                -0.0007868846878409386,
                -0.01725912280380726,
                0.05652184411883354,
                -0.07148995250463486,
                0.02069977857172489,
                0.047686848789453506,
                0.022406676784157753,
                -0.001904172240756452,
                -0.025192609056830406,
                -0.065604068338871,
                -0.034927546977996826,
                -0.07912017405033112,
                0.021547282114624977,
                0.0329335480928421,
                -0.003501459024846554,
                0.0009793986100703478,
                -0.05956464260816574,
                -0.011796613223850727,
                0.013670844957232475,
                0.06668634712696075,
                0.03665512427687645,
                0.008983748964965343,
                0.013043494895100594,
                0.01728837750852108,
                0.04079145938158035,
                0.009244062006473541,
                0.02802473120391369,
                0.001423469977453351,
                0.029817646369338036,
                -0.06762509047985077,
                0.003844777587801218,
                0.0828804224729538,
                -0.025455417111516,
                0.028938772156834602,
                -0.07256335765123367,
                -0.0528901033103466,
                0.05047209560871124,
                0.024041885510087013,
                0.0735340416431427,
                0.008679904974997044,
                -0.09316126257181168,
                0.04588066413998604,
                -0.06555921584367752,
                -0.031110359355807304,
                0.010530044324696064,
                0.01992128975689411,
                -0.03119320049881935,
                0.0711057111620903,
                0.03945058211684227,
                0.11161815375089645,
                0.021867837756872177,
                -0.045084524899721146,
                0.04246028885245323,
                -0.046436719596385956,
                -0.02428075671195984,
                -0.008316004648804665,
                -0.025571810081601143,
                -0.08955474942922592,
                0.002616404090076685,
                0.006839744281023741,
                0.011925991624593735,
                -0.020436564460396767,
                0.03428863361477852,
                -0.004860821180045605,
                0.00022979317873250693,
                -0.015834083780646324,
                0.03520575910806656,
                -0.005944555625319481,
                -0.07634453475475311,
                -0.03331271931529045,
                -0.05865843594074249,
                -0.016040876507759094,
                -0.021869776770472527,
                -0.031634263694286346,
                -0.016058195382356644,
                0.018064681440591812,
                0.010034945793449879,
                -0.009154112078249454,
                0.0038330014795064926,
                0.01734992302954197,
                0.0038296892307698727,
                -0.01135972049087286,
                0.013259266503155231,
                0.013533959165215492,
                0.03313808515667915,
                -0.031200287863612175,
                0.09106917679309845,
                -0.04227060452103615,
                0.04947226494550705,
                0.021420247852802277,
                -0.0023932454641908407,
                0.038852397352457047,
                -0.03333991393446922,
                -0.020554449409246445,
                -0.015916811302304268,
                0.01992751657962799,
                0.025989839807152748,
                0.035862136632204056,
                -0.0038703822065144777,
                -0.03437790647149086,
                -0.009025435894727707,
                0.0780927836894989,
                0.02249768190085888,
                -0.011060681194067001,
                -0.03272716701030731,
                0.0019325856119394302,
                -0.0893048495054245,
                -0.013749243691563606,
                -0.006362659391015768,
                0.04593316838145256,
                0.00972017366439104,
                -0.046932704746723175,
                0.0062556881457567215,
                -0.002993350848555565,
                -0.03418205678462982,
                0.0035124903079122305,
                -0.044401030987501144,
                -0.0007977505447342992,
                0.04894915968179703,
                0.0006830138154327869,
                -0.05351826176047325,
                -0.07661687582731247,
                -0.011288942769169807,
                0.0244042556732893,
                -0.017564203590154648,
                0.09606631100177765,
                -0.012720929458737373,
                -0.0020724127534776926,
                0.010942005552351475,
                0.029005778953433037,
                -0.0495268739759922,
                -0.0019656200893223286,
                0.004949652124196291,
                -0.03847677260637283,
                0.02722451463341713,
                0.004761207848787308,
                -0.017016656696796417,
                0.005680339876562357,
                -0.014550315216183662,
                -0.045317668467760086,
                -0.015368567779660225,
                0.026965247467160225,
                0.04538784921169281,
                -0.0222939345985651,
                0.015527762472629547,
                0.03655782341957092,
                0.047628603875637054,
                -0.016316695138812065,
                0.05257902294397354,
                0.023036044090986252,
                -0.02354012429714203,
                0.030236689373850822,
                -0.07555980235338211,
                -0.011663049459457397,
                0.05210215225815773,
                0.00964026153087616,
                -0.054815199226140976,
                -0.0012488788925111294,
                0.029394710436463356,
                -0.012190193869173527,
                0.024079635739326477,
                0.04818294942378998,
                -0.09825624525547028,
                -0.04927782341837883,
                0.05343671143054962,
                0.018874581903219223,
                0.009020164608955383,
                0.046100493520498276,
                -0.030297737568616867,
                -0.011445642448961735,
                -0.01457222644239664,
                -0.009823705069720745,
                0.010606162250041962,
                0.021564915776252747,
                -0.005201096646487713,
                0.0239612627774477,
                0.03176594525575638,
                0.04985266178846359,
                0.025346679612994194,
                -0.03358408808708191,
                0.0252776350826025,
                0.026298198848962784,
                -0.0175384022295475,
                0.02931215800344944,
                0.002300918335095048,
                0.008328319527208805,
                0.021031532436609268,
                -0.01843731477856636,
                0.03110143356025219,
                0.01346349436789751,
                -0.015187687240540981,
                -0.05766216665506363,
                -0.06792652606964111,
                -0.010236098431050777,
                -0.0379384346306324,
                -0.000542102032341063,
                -0.0025032300036400557,
                -0.0277231577783823,
                -0.00955645926296711,
                -0.06860370188951492,
                -0.021033935248851776,
                -0.010310557670891285,
                -0.046001117676496506,
                0.011930045671761036,
                0.0035994374193251133,
                -0.004811502527445555,
                0.019221382215619087,
                0.004498117603361607,
                0.0071016051806509495,
                0.04711133614182472,
                0.026884092018008232,
                -0.00018873134104069322,
                0.04289751499891281,
                -0.0029535226058214903,
                0.04999632388353348,
                0.002628861926496029,
                0.011050071567296982,
                -0.03709478676319122,
                0.008656393736600876,
                0.024401944130659103,
                -0.013328731060028076,
                -0.01721915416419506,
                -0.03238574415445328,
                0.021557820960879326,
                0.007384682074189186,
                0.005121895112097263,
                -0.004926933906972408,
                0.14468705654144287,
                -0.0007059939089231193,
                -0.010645740665495396,
                -0.011639546602964401,
                -0.0058813318610191345,
                -0.02369239553809166,
                0.08827831596136093,
                0.031886957585811615,
                -0.05268343910574913,
                0.03660767152905464,
                0.01780848391354084,
                -0.014702719636261463,
                0.018553655594587326,
                -0.043841999024152756,
                0.015417016111314297,
                0.0022186662536114454,
                -0.026025831699371338,
                0.03809090331196785,
                0.0166364423930645,
                0.04395657405257225,
                0.004107357002794743,
                -0.023077087476849556,
                0.009914785623550415,
                -0.004768234211951494,
                -0.022790467366576195,
                -0.005366261582821608,
                0.07272445410490036,
                -0.012276251800358295,
                -0.005644406657665968,
                0.04551447927951813,
                0.04677664488554001,
                0.010405277833342552,
                0.03832612559199333,
                0.008036484010517597,
                -0.01400873064994812,
                0.01612372137606144,
                0.0060225059278309345,
                -0.009153267368674278,
                0.00682301539927721,
                -0.04597413167357445,
                0.03687763214111328,
                -0.008438938297331333,
                -0.05169858783483505,
                -0.07636838406324387,
                0.05490321293473244,
                0.00553310988470912,
                -0.03883878141641617,
                0.05527936667203903,
                0.03712373971939087,
                -0.009769711643457413,
                0.0023242917377501726,
                -0.013459877111017704,
                0.039053771644830704,
                -0.02585117518901825,
                -0.022317688912153244,
                0.04539202153682709,
                -0.024836359545588493,
                -0.011400079354643822,
                0.008171917870640755,
                0.032048970460891724,
                0.017980409786105156,
                -0.038047946989536285,
                0.012663633562624454,
                -0.009196599945425987,
                -0.04200446605682373,
                -0.0002761832147371024,
                -0.03578644618391991,
                -0.008692268282175064,
                0.011024805717170238,
                0.019907504320144653,
                0.05591271445155144,
                0.026674622669816017,
                0.06336931139230728,
                0.03440403938293457,
                0.045083560049533844,
                -0.009300935082137585,
                0.018548497930169106,
                0.005102989729493856,
                -0.012700177729129791,
                0.07770904898643494,
                0.03628237918019295,
                0.021604880690574646,
                0.005594904068857431,
                0.005447659175843,
                0.038804128766059875,
                0.0015122555196285248,
                -0.06098783388733864,
                0.08082059770822525,
                0.025867410004138947,
                0.023895975202322006,
                -0.033741094172000885,
                -0.005288650281727314,
                -0.012638133019208908,
                0.014026987366378307,
                -0.03671707957983017,
                -0.06437332928180695,
                0.013914956711232662,
                -0.03892279788851738,
                0.018721550703048706,
                0.01707441173493862,
                -0.09126605093479156,
                0.014537900686264038,
                -0.032361358404159546,
                -0.09908515214920044,
                -0.055105578154325485,
                0.0025113089941442013,
                0.002804028568789363,
                -0.04070734605193138,
                0.037873830646276474,
                -0.03518011048436165,
                -0.028411535546183586,
                0.0007751902448944747,
                -0.025307627394795418,
                0.0009201016509905457,
                0.024679942056536674,
                -0.028452398255467415,
                -0.020662926137447357,
                -0.016878632828593254,
                -0.034841060638427734,
                0.0038864347152411938,
                0.012492298148572445,
                -0.09534317255020142,
                -0.0007231311174109578,
                -0.019771335646510124,
                0.04295503348112106,
                0.009103110991418362,
                0.0039040460251271725,
                0.005353412590920925,
                0.0059358589351177216,
                0.0042178514413535595,
                0.025879310443997383,
                -0.007374081760644913,
                0.0032613417133688927,
                -0.04209373518824577,
                0.03829697147011757,
                0.03951764851808548,
                0.07021738588809967,
                -0.04332011938095093,
                -0.03468431532382965,
                0.05802813544869423,
                -0.006962479092180729,
                -0.028840944170951843,
                0.019628513604402542,
                -0.018772665411233902,
                0.0036929722409695387,
                0.018653856590390205,
                -0.0710538774728775,
                0.02576681412756443,
                -0.01037038303911686,
                -0.021129366010427475,
                0.002028177957981825,
                -0.05713867396116257,
                0.04136519134044647,
                0.0007868768880143762,
                -0.009312305599451065,
                0.0571911558508873,
                -0.023591022938489914,
                -0.04576043412089348,
                0.024037202820181847,
                -0.0005522031569853425,
                -0.020455291494727135,
                -0.03892292082309723,
                0.03381209447979927,
                -0.008756637573242188,
                -0.005219881888478994,
                -0.03022821433842182,
                -0.005579228978604078,
                0.01810302957892418,
                0.06761423498392105,
                -0.06891895085573196,
                -0.09459838271141052,
                -0.014089907519519329,
                0.03430020809173584,
                0.04719313979148865,
                0.00302949664182961,
                -0.01998928003013134,
                -0.03284895420074463,
                0.024029936641454697,
                0.02377743273973465,
                0.05490267276763916,
                0.015341876074671745,
                -0.03558079153299332,
                0.00018915660621132702,
                -0.055590543895959854,
                -0.034101977944374084,
                0.0029549752362072468,
                0.02535199001431465,
                0.04144836962223053,
                0.00576266273856163,
                -0.028985537588596344,
                -0.04301762208342552,
                0.030292803421616554,
                -0.0417625829577446,
                -4.168701901411142e-33,
                -0.041626159101724625,
                -0.013376012444496155,
                0.045202095061540604,
                0.011239203624427319,
                -0.0219779871404171,
                0.007114964071661234,
                0.019856778904795647,
                0.007261987309902906,
                -0.03252973034977913,
                -0.017222104594111443,
                0.006141922902315855,
                -0.009693837724626064,
                -0.007277548313140869,
                0.0011408431455492973,
                0.03560991212725639,
                0.029799528419971466,
                0.044842444360256195,
                -0.004064733628183603,
                0.02809820882976055,
                -0.0038292810786515474,
                -0.026434676721692085,
                0.015334054827690125,
                0.049298640340566635,
                0.12011753767728806,
                -0.01223385613411665,
                0.02368263714015484,
                0.0023245871998369694,
                0.00014886874123476446,
                0.05310262367129326,
                0.010628027841448784,
                0.008004127070307732,
                -0.04021493345499039,
                0.009682465344667435,
                -0.005560309160500765,
                0.005011939909309149,
                0.023197593167424202,
                0.00864760484546423,
                -0.00944217573851347,
                -0.04101857170462608,
                -0.003315027803182602,
                0.03357332572340965,
                0.014833683148026466,
                0.025653205811977386,
                -0.012092988938093185,
                -0.0399465374648571,
                -0.006797126494348049,
                0.012109993025660515,
                0.015197048895061016,
                0.016170410439372063,
                -0.05666492134332657,
                -0.03152889385819435,
                -0.014142357744276524,
                -0.018900999799370766,
                0.025212956592440605,
                -0.022057004272937775,
                0.006478424649685621,
                -0.02226833440363407,
                -0.010336850769817829,
                0.016717849299311638,
                0.002217452274635434,
                -0.04891657456755638,
                0.021121812984347343,
                -0.022801972925662994,
                -0.0005215105484239757,
                -0.04990493133664131,
                -0.026671789586544037,
                -0.02736702933907509,
                0.022717587649822235,
                -0.004007481038570404,
                -0.015306429006159306,
                0.003105622949078679,
                0.0569986030459404,
                0.03762311488389969,
                -0.08594130724668503,
                -0.08076570928096771,
                0.0007729752105660737,
                0.042550332844257355,
                0.010520675219595432,
                0.03562645614147186,
                0.12593591213226318,
                -0.022708429023623466,
                0.05442023277282715,
                0.018812935799360275,
                -0.010838071815669537,
                0.005312774330377579,
                -0.07039099931716919,
                0.023847190663218498,
                0.07842937111854553,
                0.01599907875061035,
                -0.007171877659857273,
                -0.0117094861343503,
                0.09560052305459976,
                0.03700984641909599,
                0.0383659228682518,
                0.005921950563788414,
                0.05002051964402199,
                -0.014967067167162895,
                0.04063034430146217,
                -0.017152689397335052,
                0.04444924369454384,
                -0.005968500394374132,
                0.023302817717194557,
                -0.0028200221713632345,
                -0.019790422171354294,
                -0.020413300022482872,
                -0.030009163543581963,
                -0.036076825112104416,
                -0.05591424182057381,
                0.007097790017724037,
                0.0318569578230381,
                0.026425950229167938,
                0.0177193284034729,
                -0.01993863470852375,
                0.0211443193256855,
                -0.046078719198703766,
                -0.031116457656025887,
                0.011933797039091587,
                -0.02222611755132675,
                -0.04354754090309143,
                -0.0898095965385437,
                -0.0771101713180542,
                0.036942124366760254,
                -0.007348864339292049,
                -0.006327337585389614,
                0.007911596447229385,
                -0.0425245575606823,
                0.006869926583021879,
                0.012763012200593948,
                -0.009685634635388851,
                -0.019372805953025818,
                -0.005933412816375494,
                0.05709078535437584,
                1.9230708403483732e-07,
                0.007432456128299236,
                0.014457984827458858,
                0.023306282237172127,
                0.034292999655008316,
                0.026855988427996635,
                0.04314625263214111,
                -0.013943852856755257,
                -0.08826342970132828,
                0.010562406852841377,
                0.021742695942521095,
                -0.009751720353960991,
                0.005838668905198574,
                -0.01830742135643959,
                0.030921071767807007,
                0.06144995987415314,
                -0.1322038620710373,
                0.01837422512471676,
                0.016005216166377068,
                -0.07262934744358063,
                -0.0025434966664761305,
                -0.012708915397524834,
                0.023434847593307495,
                0.024184532463550568,
                0.020566795021295547,
                -0.01715671643614769,
                -0.0183727890253067,
                -0.00399739807471633,
                0.07282080501317978,
                0.043101146817207336,
                0.07859206199645996,
                -0.09592684358358383,
                -0.06715089827775955,
                -0.03165826201438904,
                -0.016956927254796028,
                0.038732416927814484,
                -0.03722067177295685,
                0.07159647345542908,
                -0.006697865203022957,
                0.006972194649279118,
                -0.019101280719041824,
                -0.0110401576384902,
                -0.0012480344157665968,
                -0.020923558622598648,
                0.011372308246791363,
                -0.013548201881349087,
                0.01572282612323761,
                -0.015482918359339237,
                0.034622788429260254,
                0.0780092254281044,
                -0.015729695558547974,
                0.02917664311826229,
                -0.0009327456937171519,
                0.021579401567578316,
                -0.017944980412721634,
                0.002458559349179268,
                -0.012514188885688782,
                -0.05356071516871452,
                0.0067899106070399284,
                0.029151778668165207,
                -0.009468208067119122,
                -0.008895918726921082,
                0.005689481273293495,
                -0.03325578570365906,
                0.08836197853088379,
                -0.037498343735933304,
                0.0520053505897522,
                0.023347334936261177,
                7.966558248094143e-35,
                -0.03451607748866081,
                -0.02472100965678692,
                -0.015089081600308418,
                0.04159878194332123,
                -0.05684039369225502,
                0.010049278847873211,
                -0.008882354944944382,
                -0.013884659856557846,
                -0.03181886672973633,
                -0.004528781399130821,
                -0.004077652469277382,
                0.005421116948127747,
                0.057480379939079285,
                -0.017732447013258934,
                -0.03886590525507927,
                0.011803586967289448,
                0.003307001432403922,
                0.049820877611637115,
                -0.024937590584158897,
                -0.036760058254003525,
                -0.02611669898033142,
                0.04631698504090309,
                0.0635005384683609,
                0.004885999485850334,
                0.12358049303293228,
                0.007738423999398947,
                -0.015623858198523521,
                0.02491643652319908,
                0.03140247240662575,
                -0.0522763654589653,
                -0.004334807861596346,
                0.010290422476828098,
                -0.01810517907142639,
                0.005464726127684116,
                -0.02807198092341423,
                -0.04295545816421509,
                -0.029349396005272865,
                -0.04062628000974655,
                0.01729425974190235,
                0.012119567953050137,
                0.05011346563696861,
                -0.019129354506731033,
                -0.006556377746164799,
                0.010173988528549671,
                0.07190203666687012,
                1.6384608443331672e-06,
                0.006425222381949425,
                -0.0389091856777668,
                0.028354952111840248,
                -0.045079369097948074,
                0.018458621576428413,
                0.0083348723128438,
                -0.035508837550878525,
                -0.013065097853541374,
                -0.013170641846954823,
                -0.0007048387196846306,
                0.005540517158806324,
                0.017543859779834747,
                -0.019556742161512375,
                0.03914549574255943,
                -0.00670292554423213,
                0.01064032968133688,
                0.015343370847404003,
                0.028908200562000275,
                0.025121763348579407,
                -0.10792174190282822,
                0.055674679577350616,
                0.04831384867429733,
                0.09580574929714203,
                0.16343195736408234,
                0.0032175169326364994,
                0.006413130089640617,
                0.04245144873857498,
                0.015193271450698376,
                -0.028255388140678406,
                0.04819973558187485,
                0.01648648828268051,
                0.021722720935940742,
                -0.010919992811977863,
                -0.07186462730169296,
                -0.0002809908473864198,
                -0.01943880319595337,
                -0.00044827774399891496,
                0.00879481341689825,
                -0.0549587607383728,
                -0.04268571734428406,
                -0.0160015020519495,
                -0.023469889536499977,
                -0.08065662533044815,
                0.016849905252456665,
                -0.012034045532345772,
                -0.04735850170254707,
                -0.04699072614312172,
                -0.006111248396337032,
                0.022711660712957382,
                -0.0429873913526535,
                0.1133812740445137,
                0.03334375470876694,
                -0.007638794835656881,
                0.000680532946716994,
                -0.0030168043449521065,
                0.024381913244724274,
                -0.03822722285985947,
                0.01834334060549736,
                0.04283348098397255,
                -0.020496636629104614,
                -0.014287305064499378,
                -0.026745060458779335,
                -0.05166519433259964,
                -0.03024968132376671,
                -0.013586035929620266,
                0.04229767620563507,
                -0.007003019098192453,
                0.03003537841141224,
                0.022094449028372765,
                0.05205820873379707,
                -0.010060928761959076,
                -0.09440640360116959,
                -0.025447513908147812,
                -0.05566781759262085,
                0.06536459177732468,
                0.014626849442720413,
                -0.0273764505982399,
                -0.016012320294976234,
                0.08355315774679184,
                -0.040278855711221695,
                -0.08093941956758499,
                0.03090343251824379,
                0.012763174250721931,
                -0.005861126352101564,
                -0.0288326907902956,
                0.04200013354420662,
                0.03508290648460388,
                0.03241969272494316,
                -0.05435894802212715,
                -0.01827126368880272,
                0.06390982121229172,
                0.020676085725426674,
                -0.021020764485001564,
                -0.02782858908176422,
                0.009355453774333,
                0.04433178901672363,
                -0.017726674675941467,
                0.009755064733326435,
                -0.025006026029586792,
                0.001721423352137208,
                0.0043218014761805534,
                -0.017182588577270508,
                0.04010554403066635,
                -0.0024611535482108593,
                -0.036603204905986786,
                -0.010114346630871296,
                -0.04065726324915886,
                -0.07478544116020203,
                0.010808747261762619,
                -0.011498762294650078,
                -0.0013021985068917274,
                0.01103175338357687,
                0.039489567279815674,
                0.0027757291682064533,
                0.013199773617088795,
                0.0038921725936233997,
                0.04103751853108406,
                -0.026810305193066597,
                -0.010501629672944546,
                0.05010814219713211,
                0.00016963007510639727,
                0.06055568903684616,
                0.035419754683971405,
                -0.024149000644683838,
                -0.07385793328285217,
                -0.03720110282301903,
                0.002874289872124791,
                0.00024975347332656384,
                0.015025248751044273,
                0.017864732071757317,
                0.005507438909262419,
                0.011707176454365253,
                -0.01934891939163208,
                -0.012028497643768787,
                0.0004071465809829533,
                -0.021455902606248856,
                0.004506544675678015,
                0.029336674138903618,
                0.03157253935933113,
                0.023296693339943886,
                -0.05052458867430687,
                0.05760466307401657,
                -0.022033831104636192,
                0.004809886682778597,
                0.03853131830692291,
                -0.0048991902731359005,
                -0.030416570603847504,
                -0.022028416395187378,
                0.04384177178144455,
                0.015186524018645287,
                0.013528675772249699,
                0.0391433946788311,
                0.05450000986456871,
                -0.025415930896997452,
                -0.006650236435234547,
                -0.02177252806723118,
                -0.01431663241237402,
                0.03973452374339104,
                0.03961761295795441,
                -0.009797505103051662,
                -0.014651170931756496,
                0.006337116938084364,
                -0.041832275688648224,
                -0.040004946291446686,
                0.0031965654343366623,
                0.012937003746628761,
                -0.05359581857919693,
                0.0413651242852211,
                0.018519561737775803,
                0.03795478120446205,
                -0.038451530039310455,
                0.006510018836706877,
                -0.020453592762351036,
                0.015468994155526161,
                0.015473497100174427,
                0.05990798771381378,
                0.017206307500600815,
                -0.027622250840067863,
                0.03012457676231861,
                -0.016561947762966156,
                0.005697572138160467,
                0.029452063143253326,
                0.04485975205898285,
                -0.022061238065361977,
                0.01591399870812893,
                -0.055286724120378494,
                0.10968335717916489,
                0.031086139380931854,
                -0.03216100484132767,
                0.01185686606913805,
                -0.008385258726775646,
                0.01611870713531971,
                0.018426235765218735,
                -0.015479416586458683,
                0.05127592012286186,
                -0.011010680347681046,
                0.014087177813053131,
                0.007203533314168453,
                -0.008105806075036526,
                -0.06097410246729851,
                -0.027231698855757713,
                0.06240611523389816,
                0.14986412227153778,
                0.0869511291384697,
                -0.04194243624806404,
                0.015230484306812286,
                -0.032583583146333694,
                0.008051096461713314,
                -0.01983945444226265,
                0.051169801503419876,
                -0.021453581750392914,
                0.01490745972841978,
                0.046857137233018875,
                0.019808409735560417,
                0.007426079828292131,
                -0.0140446862205863,
                0.013178272172808647,
                0.03358782082796097,
                0.0146418996155262,
                0.0016085648676380515,
                0.017446031793951988,
                0.007648524362593889,
                0.0487208217382431,
                0.004569810815155506,
                0.0005209592054598033,
                -0.015292485244572163,
                -0.0006132156122475863,
                -0.001638309913687408,
                -0.002400064840912819,
                -0.0477544441819191,
                -0.001669713412411511,
                0.033064939081668854,
                0.04013408347964287,
                -0.03553594648838043,
                0.05048605054616928,
                0.007592370267957449,
                0.03418204188346863,
                -0.02424059994518757,
                0.020303431898355484,
                0.03406870737671852,
                0.0022674179635941982,
                -0.013316073454916477,
                0.032673515379428864,
                -0.05714193731546402,
                0.002770001068711281,
                0.04474077746272087,
                -0.011093776673078537,
                -0.017032204195857048,
                -0.0012362516717985272,
                -0.042439818382263184,
                0.0018325971905142069,
                -0.003133549587801099,
                -0.03493257984519005,
                -0.050953496247529984,
                -0.016790157184004784,
                -0.016068575903773308,
                0.008968639187514782,
                0.036062613129615784,
                -0.0036089958157390356,
                0.0029317389708012342,
                -0.03562059998512268,
                -0.06307822465896606,
                0.011874716728925705,
                -0.03543848171830177,
                -0.01337624341249466,
                0.008099626749753952,
                -0.0664689913392067,
                -0.026945291087031364,
                0.017203019931912422,
                0.04094682261347771,
                0.05296526476740837,
                0.07628025859594345,
                0.04216974973678589,
                -0.03831486776471138,
                -0.02038266323506832,
                0.026772957295179367,
                -0.023058215156197548,
                0.019511204212903976,
                0.013650025241076946,
                -0.011174888350069523,
                -0.019149942323565483,
                0.0339045487344265,
                -0.047350600361824036,
                0.014706745743751526,
                0.05307759717106819,
                0.009968348778784275,
                -0.015335021540522575,
                0.04283549264073372,
                -0.03902382776141167,
                0.008446017280220985,
                -0.00048117933329194784,
                -0.04561256989836693,
                -0.017577335238456726,
                -0.04801278933882713,
                -0.07863524556159973,
                0.01514949556440115,
                -0.01795726828277111,
                0.03163333237171173,
                -0.0215679369866848,
                -0.030647775158286095,
                0.03909813240170479,
                -0.04121765121817589,
                0.04379308223724365,
                -0.003676808439195156,
                -0.006059880368411541,
                -0.022910844534635544,
                -0.01896740309894085,
                0.02101288177073002,
                0.05760238319635391,
                -0.003108344040811062,
                0.014847900718450546,
                -0.02013833448290825,
                -0.0011577792465686798,
                -0.03536199405789375,
                0.023797979578375816,
                0.05861994996666908,
                -0.018392279744148254,
                -0.013231532648205757,
                -0.04653581976890564,
                -0.019844194874167442,
                -0.012765111401677132,
                -0.06003956496715546,
                0.03776192292571068,
                0.0057565681636333466,
                0.06437072902917862,
                -0.0029438987839967012,
                -0.029652461409568787,
                0.03576086461544037,
                0.01869197003543377,
                -0.06641591340303421,
                -0.014271397143602371,
                0.04213578999042511,
                0.02075435407459736,
                -0.01669851876795292,
                -0.0015086838975548744,
                -0.01648775115609169,
                0.014889298938214779,
                0.03168170899152756,
                -0.0296385046094656,
                -0.004998695570975542,
                0.0035360250622034073,
                -0.02447347715497017,
                0.02182893641293049,
                -0.07013130187988281,
                0.038578808307647705,
                0.022460421547293663,
                -0.05609249696135521,
                0.02737952023744583,
                -0.014764731749892235,
                -0.013616577722132206,
                -0.02894400991499424,
                -0.0021922816522419453,
                -0.040131598711013794,
                -0.00750392721965909,
                0.011624638922512531,
                0.04158857837319374,
                0.10717830806970596,
                -0.07078306376934052,
                0.06553808599710464,
                0.01613588072359562,
                0.026629945263266563,
                0.00713863130658865,
                0.0032663773745298386,
                -0.018681924790143967,
                0.012089965865015984,
                -0.006701793987303972,
                -0.03485755994915962,
                0.03055376186966896,
                -0.057285867631435394,
                0.04090665280818939,
                -0.058864519000053406,
                0.09314298629760742,
                0.022965384647250175,
                0.01316123828291893,
                -0.001385066076181829,
                -0.03975736349821091,
                -0.005002330057322979,
                0.02702316641807556,
                -0.029041586443781853,
                -0.08232129365205765,
                0.00605813367292285,
                -0.01066026370972395,
                0.013951231725513935,
                0.026346486061811447,
                -0.014273320324718952,
                0.010764258913695812,
                -0.013545711524784565,
                0.035784848034381866,
                -0.004745032638311386,
                0.04312915727496147,
                -0.04065863788127899,
                -0.03155384585261345,
                0.008828659541904926,
                -0.016887210309505463,
                -0.016141517087817192,
                0.019831640645861626,
                -0.03727531060576439,
                -0.008656937628984451,
                0.009028964675962925,
                0.07165738195180893,
                -0.018132532015442848,
                0.012373632751405239,
                0.01184146385639906,
                -0.04201328009366989,
                0.013266109861433506,
                -0.03312835842370987,
                -0.02721095271408558,
                0.06958651542663574,
                0.04676708206534386,
                -0.022733282297849655,
                0.016507549211382866,
                0.004508381709456444,
                -0.03870885446667671,
                -0.057842765003442764,
                -0.012922941707074642,
                0.041448742151260376,
                0.009909756481647491,
                0.0581047460436821,
                -0.059119150042533875,
                -0.05693778023123741,
                -0.014656046405434608,
                0.01513229962438345,
                -0.04531640186905861,
                -0.034866634756326675,
                0.02679942362010479,
                0.02292477712035179,
                0.027653781697154045,
                -0.07153470069169998,
                0.0004014235455542803,
                -0.0002527284959796816,
                -0.020986981689929962,
                0.007293024566024542,
                0.04083149880170822,
                0.00214417465031147,
                -0.007452479563653469,
                0.04543917998671532,
                0.0036112675443291664,
                -0.026187987998127937,
                -0.019255390390753746,
                -0.0432923324406147,
                0.03162343427538872,
                0.027071751654148102,
                -0.02090105041861534,
                0.034810177981853485,
                0.0038321760948747396,
                0.016680775210261345,
                0.007083313073962927,
                0.015717895701527596,
                0.012565049342811108,
                0.019503729417920113,
                0.0383889265358448,
                -0.01442032027989626,
                -0.01788918673992157,
                -0.019127536565065384,
                -0.0597400926053524,
                -0.03207623213529587,
                0.0013019947800785303,
                0.004916785284876823,
                -0.014992243610322475,
                0.027594156563282013,
                -0.02842683345079422,
                -0.020332053303718567,
                -0.02699046954512596,
                -0.009460247121751308,
                -0.006375646218657494,
                -0.005338866729289293,
                -0.0060578896664083,
                -0.0036910071503371,
                0.03384499251842499,
                -0.023253608494997025,
                0.013275275006890297,
                -0.03827580809593201,
                0.027651149779558182,
                -0.02131091058254242,
                0.04228150472044945,
                0.017074529081583023,
                7.675223605474457e-05,
                -0.02466406859457493,
                -0.10108965635299683,
                -0.0131178367882967,
                0.06797277927398682,
                0.030533989891409874,
                0.05696219950914383,
                -0.0032259987201541662,
                0.0384049154818058,
                0.000821843626908958,
                0.010065671056509018,
                0.013505210168659687,
                0.006925920024514198,
                0.05467462167143822,
                -0.02226213738322258,
                0.023083174601197243,
                0.023620352149009705,
                -0.05449620634317398,
                -0.033393193036317825,
                -0.20023129880428314,
                -0.037581343203783035,
                -0.0804726853966713,
                -0.0405036062002182,
                0.051860835403203964,
                0.044909730553627014,
                -0.01756575144827366,
                0.026792746037244797,
                -0.017016198486089706,
                -0.03272588178515434,
                -0.04602457955479622,
                -0.009781389497220516,
                -0.06130143627524376,
                0.025263933464884758,
                0.008716704323887825,
                -0.021072816103696823,
                -0.015559244900941849,
                -0.0429881289601326,
                0.044599562883377075,
                -0.0006341738626360893,
                0.04745632782578468,
                -0.010874695144593716,
                -0.03157883882522583,
                -0.012305937707424164,
                -0.014297141693532467,
                -6.556811245286746e-33,
                -0.017402227967977524,
                -0.015067938715219498,
                -0.023283693939447403,
                -0.00797249935567379,
                -0.01496419869363308,
                0.005501375067979097,
                0.0805012509226799,
                0.002953548217192292,
                -0.014986677095293999,
                -0.05332377552986145,
                -0.013983461074531078,
                -0.007405376061797142,
                0.028051545843482018,
                0.013605493120849133,
                -0.028992461040616035,
                -0.04012312367558479,
                0.010531466454267502,
                0.008694162592291832,
                0.0010244158329442143,
                -0.02401159331202507,
                -0.016255132853984833,
                0.0026269424706697464,
                -0.05935521796345711,
                -0.047240667045116425,
                0.009957247413694859,
                0.030298827216029167,
                0.004287082701921463,
                -0.025565186515450478,
                0.008550350554287434,
                0.027799660339951515,
                -0.0002751629799604416,
                0.03621267154812813,
                -0.026309438049793243,
                -0.018689274787902832,
                -0.012352077290415764,
                0.04618509113788605,
                -0.08882708102464676,
                -0.011450174264609814,
                0.016614768654108047,
                0.025968367233872414,
                0.09531721472740173,
                -0.03970097750425339,
                0.02882855385541916,
                -0.028114771470427513,
                0.011146653443574905,
                0.020168880000710487,
                0.006808750797063112,
                0.037247151136398315,
                -0.004349063150584698,
                -0.052450086921453476,
                -0.05449141189455986,
                -0.021212486550211906,
                -0.008122853934764862,
                0.05667119845747948,
                0.008121701888740063,
                -0.007470156531780958,
                -0.01644514501094818,
                -0.08950590342283249,
                -0.03367406502366066,
                0.012486427091062069,
                0.04116445407271385,
                0.0017387906555086374,
                0.03885980695486069,
                -0.06663897633552551,
                0.012252124026417732,
                0.0023285681381821632,
                -0.014513031579554081,
                -0.08110332489013672,
                -0.008694250136613846,
                -0.03585314378142357,
                -0.024755755439400673,
                -0.01587073691189289,
                0.026112310588359833,
                -0.024157464504241943,
                -0.0017228337237611413,
                -0.01764526776969433,
                0.021791864186525345,
                0.0028672039043158293,
                0.034209638833999634,
                0.0036670726258307695,
                0.010797139257192612,
                0.045443449169397354,
                -0.004289815202355385,
                -0.008458097465336323,
                0.05373690649867058,
                0.01846187375485897,
                -0.019395580515265465,
                -0.07262168079614639,
                -0.008084113709628582,
                0.012200539000332355,
                -0.024968033656477928,
                -0.0005792703595943749,
                0.008359651081264019,
                -0.05907529965043068,
                0.005737309344112873,
                -0.028119947761297226,
                0.05271421745419502,
                0.02057461626827717,
                0.018392840400338173,
                0.013571502640843391,
                0.011403784155845642,
                -0.025527818128466606,
                -0.013099904172122478,
                0.015132133848965168,
                0.005365318153053522,
                -0.021552909165620804,
                -0.08379513770341873,
                0.04649447649717331,
                -0.04234026372432709,
                0.008974584750831127,
                0.01139475405216217,
                -0.024700911715626717,
                -0.006410904228687286,
                -0.03214089944958687,
                0.03756450116634369,
                0.011400559917092323,
                0.020154530182480812,
                -0.07817374169826508,
                -0.07831577211618423,
                -0.05373380705714226,
                0.015922091901302338,
                0.05808830261230469,
                -0.002207281067967415,
                0.005093094892799854,
                0.0034788325428962708,
                0.017548399046063423,
                -0.009451058693230152,
                -0.0672716498374939,
                0.018494652584195137,
                -0.01658450812101364,
                -0.028226912021636963,
                0.05123196542263031,
                2.2770844054775807e-07,
                0.00618373928591609,
                -0.015600758604705334,
                0.023757144808769226,
                0.060275252908468246,
                0.021229051053524017,
                -0.03926701843738556,
                0.005368965677917004,
                0.03825632855296135,
                0.0286057498306036,
                -0.010211043059825897,
                -0.010801826603710651,
                -0.014501668512821198,
                0.0013944640522822738,
                -0.012610337696969509,
                0.04318033158779144,
                -0.08694668859243393,
                0.07438080757856369,
                -0.037982672452926636,
                0.007196394726634026,
                -0.01888527348637581,
                0.051820773631334305,
                -0.023166272789239883,
                0.021642103791236877,
                -0.0008092329953797162,
                0.010174248367547989,
                0.0006275989580899477,
                -0.0004479059425648302,
                0.061155278235673904,
                0.10141365230083466,
                -0.030608035624027252,
                -0.0075627281330525875,
                -0.023494405671954155,
                -0.012437106110155582,
                0.02988157980144024,
                0.028355319052934647,
                -0.005510320421308279,
                0.004323003347963095,
                0.044670470058918,
                -0.0018010359490290284,
                -0.02374873124063015,
                -0.025817643851041794,
                -0.02413361519575119,
                -0.01205067802220583,
                0.003665710799396038,
                0.031692955642938614,
                -0.0262939240783453,
                -0.008232373744249344,
                -0.02128349058330059,
                -0.03280795365571976,
                -0.06904052942991257,
                -0.00028693146305158734,
                0.02850363776087761,
                0.03932962566614151,
                0.0038624070584774017,
                -0.031245363876223564,
                0.004234698601067066,
                0.03476893901824951,
                0.024069860577583313,
                0.027385899797081947,
                0.09361110627651215,
                0.007632284425199032,
                -0.017927074804902077,
                0.008119606412947178,
                -0.023094579577445984,
                0.07923278212547302,
                -0.0331885851919651,
                -0.02104017324745655,
                1.529651168887739e-34,
                -0.029740620404481888,
                -0.0187732744961977,
                0.03340771421790123,
                0.016809780150651932,
                -0.015087488107383251,
                0.025957418605685234,
                -0.04538628086447716,
                -0.032155971974134445,
                0.05907232686877251,
                -0.02273935079574585,
                -0.0004948877030983567,
                0.06009536236524582,
                0.018063735216856003,
                -0.03332475572824478,
                0.03142069652676582,
                0.01862500235438347,
                0.023912448436021805,
                -0.046576306223869324,
                0.007187385577708483,
                0.012267397716641426,
                0.033629707992076874,
                0.012561481446027756,
                -0.04866931214928627,
                0.01950247958302498,
                -0.005602862685918808,
                0.030310506001114845,
                -0.07581858336925507,
                0.0018244866514578462,
                -0.021303584799170494,
                0.02963273413479328,
                0.0044008586555719376,
                0.0020072103943675756,
                0.028586795553565025,
                -0.0021065270993858576,
                -0.010250850580632687,
                -0.006336864549666643,
                -0.018396837636828423,
                0.0031796449329704046,
                0.0336189828813076,
                0.0006496526766568422,
                0.01858007349073887,
                0.013255752623081207,
                -0.02898586541414261,
                -0.028108585625886917,
                -0.0985439345240593,
                1.7017455320456065e-06,
                0.03305341303348541,
                -0.0029413884039968252,
                -0.06115688756108284,
                0.019090838730335236,
                -0.011425089091062546,
                0.045755766332149506,
                0.06993713974952698,
                -0.014264283701777458,
                0.029185015708208084,
                0.002610574709251523,
                0.10641420632600784,
                0.004340243525803089,
                0.06639467179775238,
                0.050251107662916183,
                0.032823510468006134,
                0.017571769654750824,
                0.028057025745511055,
                -0.02528426982462406,
                -0.018660681322216988,
                -0.07835415750741959,
                -0.025192594155669212,
                -0.0037778764963150024,
                -0.009955977089703083,
                -0.004244046751409769,
                0.02090448886156082,
                -0.02712930366396904,
                -0.044433217495679855,
                -0.04981137812137604,
                -0.0040967646054923534,
                0.005541096441447735,
                -0.003862312762066722,
                -0.03363880515098572,
                -0.026068245992064476,
                0.01648803986608982,
                0.007131831254810095,
                -0.032296184450387955,
                0.05936683341860771,
                0.0317612923681736,
                0.021389244124293327,
                0.04511258006095886,
                0.00042424839921295643,
                0.010740509256720543,
                -0.004336190409958363,
                0.02225618250668049,
                0.010752246715128422,
                0.0033592188265174627,
                0.02597774937748909,
                -0.034084949642419815,
                0.009637545794248581,
                0.0670415610074997,
                0.0014922701520845294,
                -0.004894830286502838,
                -0.0009570452966727316,
                -0.03917509317398071,
                0.04096737504005432,
                -0.034357111901044846,
                -0.03261812403798103,
                -0.01283494383096695,
                0.03536004573106766,
                -0.017203204333782196,
                -0.027595624327659607,
                -0.00332462415099144,
                0.038121387362480164,
                -0.01744612120091915,
                0.04577269032597542,
                0.025843454524874687,
                0.007732053752988577,
                -0.011527598835527897,
                -0.022022591903805733,
                0.00047542492393404245,
                0.03196118399500847,
                -0.0013545660767704248,
                0.02110186591744423,
                -0.045210711658000946,
                0.02042795903980732,
                -0.03990555182099342,
                0.026074809953570366,
                0.017044056206941605,
                0.014908148907124996,
                -0.017354846000671387,
                -0.011030110530555248,
                -0.038432493805885315,
                -0.022050591185688972,
                -0.005380040034651756,
                0.03183590620756149,
                -0.015385815873742104,
                0.0019711998756974936,
                -0.017684733495116234,
                0.017656514421105385,
                0.020090529695153236,
                0.044064391404390335,
                -0.03983131796121597,
                0.014028276316821575,
                0.011641056276857853,
                -0.002895739860832691,
                0.02952686697244644,
                0.010845511220395565,
                0.014837446622550488,
                0.0471138060092926,
                0.07856051623821259,
                0.012392895296216011,
                -0.02356543205678463,
                0.049418315291404724,
                -0.06002265587449074,
                0.026546329259872437,
                -0.022099168971180916,
                -0.04339948296546936,
                -0.02088114619255066,
                -0.016146110370755196,
                -0.028518835082650185,
                -0.0664479061961174,
                0.0033678847830742598,
                0.028453782200813293,
                0.05405575782060623,
                0.014823775738477707,
                0.0036281717475503683,
                -0.026703352108597755,
                0.030944569036364555,
                -0.009985051117837429,
                0.05334410071372986,
                0.02880171686410904,
                0.03859882056713104,
                0.030399179086089134,
                -0.028285754844546318,
                0.011540965177118778,
                -0.0007773344987072051,
                0.032549623399972916,
                0.020310690626502037,
                0.006206701509654522,
                0.03858987241983414,
                0.03965861722826958,
                0.05267487093806267,
                -0.008986229076981544,
                -0.005065699573606253,
                0.03140411525964737,
                -0.06809326261281967,
                0.06298446655273438,
                -0.03765600919723511,
                0.0539771169424057,
                0.011659604497253895,
                0.03154484182596207,
                0.04267869144678116,
                -0.06941038370132446,
                -0.040088459849357605,
                -0.01086210273206234,
                -0.007449764758348465,
                -0.1149301677942276,
                -0.019304964691400528,
                -0.036205630749464035,
                0.07506226003170013,
                0.03143247961997986,
                -0.029467031359672546,
                0.0678839460015297,
                -0.037190958857536316,
                -0.02423207089304924,
                0.0067414576187729836,
                0.019813962280750275,
                -0.08105246722698212,
                0.030189499258995056,
                -0.00028192708850838244,
                -0.007782012224197388,
                -0.04531526938080788,
                -0.05592832341790199,
                -0.044013604521751404,
                -0.04977419227361679,
                0.045187197625637054,
                0.02476770244538784,
                -0.06403880566358566,
                -0.006428385153412819,
                -0.03010263293981552,
                0.02034902200102806,
                0.016049779951572418,
                -0.03904801234602928,
                0.01689336635172367,
                -0.030123738572001457,
                0.017621267586946487,
                0.009179982356727123,
                0.03378800302743912,
                -3.6788238503504544e-05,
                0.011594918556511402,
                0.030228648334741592,
                0.004779054317623377,
                0.010317973792552948,
                -0.0071010165847837925,
                0.029528219252824783,
                -0.03723979741334915,
                0.06613073498010635,
                -0.04659396409988403,
                0.01933048479259014,
                0.02127222716808319,
                -0.04899091646075249,
                -0.009170222096145153,
                -0.034445323050022125,
                0.018252259120345116,
                0.05326066538691521,
                -0.008821162395179272,
                0.009140608832240105,
                -0.026990076526999474,
                0.023903658613562584,
                -0.04380251094698906,
                -0.06458146870136261,
                0.006374564953148365,
                0.019958214834332466,
                0.03854543715715408,
                -0.03635638952255249,
                -0.05868731439113617,
                -0.006273611448705196,
                0.01988062635064125,
                -0.006895659491419792,
                0.02900467813014984,
                0.020385054871439934,
                0.014456079341471195,
                0.01696084626019001,
                0.0003886974591296166,
                -0.023976748809218407,
                -0.005401506554335356,
                0.011403204873204231,
                -0.016495496034622192,
                0.001451682299375534,
                -0.021386945620179176,
                0.102351613342762,
                0.041456423699855804,
                -0.003034977475181222,
                -0.008270465768873692,
                0.017215978354215622,
                0.013398089446127415,
                -0.004222806077450514,
                -0.03094789944589138,
                0.026832040399312973,
                0.04820118099451065,
                -0.05523211881518364,
                0.008443974889814854,
                0.007158239837735891,
                -0.007637899834662676,
                -0.020672563463449478,
                0.008913951925933361,
                -0.010025674477219582,
                -0.0030520777218043804,
                -0.025226732715964317,
                0.003532000118866563,
                -0.007580325938761234,
                -0.09084755182266235,
                0.07020027935504913,
                -0.02416849322617054,
                2.0946019503753632e-05,
                0.016405152156949043,
                0.014621663838624954,
                -0.03971882537007332,
                -0.011748915538191795,
                0.023171070963144302,
                -0.017657799646258354,
                0.008620502427220345,
                0.0081313606351614,
                -0.006133946590125561,
                -0.0139554962515831,
                -0.018923964351415634,
                0.0026138124521821737,
                0.006394680123776197,
                -0.05595194175839424,
                0.0214824378490448,
                0.009236994199454784,
                0.08853793889284134,
                0.00012156504817539826,
                -0.007433404214680195,
                0.07096436619758606,
                -0.002104563405737281,
                -0.04007141292095184,
                0.049362070858478546,
                -0.005255240481346846,
                -0.015334741212427616,
                -0.03318318724632263,
                -0.03323531150817871,
                0.012499213218688965,
                0.00026961523690260947,
                -0.01820056326687336,
                0.058471210300922394,
                -0.0068403687328100204,
                0.01853199675679207,
                0.013481071218848228,
                -0.02843252755701542,
                0.0058083380572497845,
                -0.021525612100958824,
                -0.02181384153664112,
                0.031677428632974625,
                0.027870718389749527,
                0.008693664334714413,
                0.030619416385889053,
                -0.03093140758574009,
                -0.01232973299920559,
                -0.0036110414657741785,
                0.004214564338326454,
                -0.057653579860925674,
                -0.02318941056728363,
                -0.005692837759852409,
                -0.031222643330693245,
                -0.011460495181381702,
                -0.002155185677111149,
                -0.07682511955499649,
                -0.006848794408142567,
                0.0054293363355100155,
                0.028414275497198105,
                -0.037346746772527695,
                -0.07855703681707382,
                -0.005217829719185829,
                -0.009505804628133774,
                0.020533766597509384,
                0.03768068179488182,
                -0.04344840347766876,
                0.09783513098955154,
                -0.025326458737254143,
                -0.0031260442920029163,
                0.04471556842327118,
                0.060866110026836395,
                -0.06412722170352936,
                -0.06535989791154861,
                0.03461214900016785,
                -0.03454941511154175,
                -0.015648813918232918,
                -0.021343417465686798,
                0.01604294963181019,
                -0.01908518187701702,
                -0.028177183121442795,
                -0.011497545056045055,
                -0.011054441332817078,
                -0.06051194667816162,
                -0.023751141503453255,
                0.01923825591802597,
                0.04288754612207413,
                -0.022916607558727264,
                0.028220104053616524,
                0.027386551722884178,
                0.03192948177456856,
                -0.01098595466464758,
                -0.013055489398539066,
                0.04976392537355423,
                0.013479789718985558,
                0.04233592003583908,
                -0.0452413484454155,
                0.019907064735889435,
                0.021022900938987732,
                0.022598594427108765,
                0.004244663752615452,
                -0.027437949553132057,
                -0.007066270802170038,
                -0.007162885740399361,
                0.035215333104133606,
                0.0027871448546648026,
                0.028368672356009483,
                -0.022216711193323135,
                0.0009749705786816776,
                -0.029144279658794403,
                0.020374855026602745,
                -0.03208615630865097,
                0.01116435881704092,
                0.004052870906889439,
                0.022268498316407204,
                0.06752842664718628,
                -0.10167039930820465,
                0.05238103121519089,
                -0.04257265478372574,
                -0.0021564846392720938,
                -0.0012213692534714937,
                -0.007840421050786972,
                -0.02583596669137478,
                0.034097008407115936,
                0.05230220407247543,
                0.023491568863391876,
                0.0002138729760190472,
                -0.002724620047956705,
                -0.026366645470261574,
                -0.007457887288182974,
                0.10564318299293518,
                -0.0085564274340868,
                0.010824955999851227,
                0.044346489012241364,
                -0.07842910289764404,
                -0.03422478586435318,
                -0.01768595539033413,
                0.04687213897705078,
                -0.011179057881236076,
                -0.07226429134607315,
                -0.017827197909355164,
                -0.028094163164496422,
                0.06760977953672409,
                -0.01428790483623743,
                0.021567702293395996,
                0.028401335701346397,
                0.0005039797979407012,
                0.012322420254349709,
                0.00427996926009655,
                -0.01537406351417303,
                0.061109352856874466,
                0.0340552031993866,
                -0.027202175930142403,
                -0.03293207660317421,
                0.03225771710276604,
                0.014562400989234447,
                0.0014408075949177146,
                0.0486271008849144,
                0.07153461128473282,
                -0.12863481044769287,
                -0.01844657026231289,
                0.022960824891924858,
                0.15317481756210327,
                0.011971034109592438,
                0.009646326303482056,
                0.07170872390270233,
                0.006149467080831528,
                -0.004100988153368235,
                0.02902504801750183,
                -0.05945954844355583,
                -0.026391716673970222,
                0.027680331841111183,
                -0.041256461292505264,
                0.010871045291423798,
                0.030642444267868996,
                -0.04074883460998535,
                -0.019930565729737282,
                -0.04594828933477402,
                0.024429073557257652,
                -0.00809691846370697,
                -0.027115806937217712,
                -0.08161155879497528,
                -0.003083053044974804,
                -0.004930899944156408,
                -0.029445169493556023,
                0.012164618819952011,
                0.011583337560296059,
                0.07041306793689728,
                0.02331542782485485,
                -0.04827406629920006,
                0.006516937166452408,
                0.029509441927075386,
                0.01752280630171299,
                -0.05632747709751129,
                0.08204282075166702,
                0.020702967420220375,
                -0.015235875733196735,
                0.012003092095255852,
                0.07469257712364197,
                0.016315821558237076,
                -0.010830190032720566,
                0.014029765501618385,
                0.06225615367293358,
                -0.0010283965384587646,
                -0.008411621674895287,
                -0.0015996989095583558,
                -0.00465773930773139,
                -0.032920632511377335,
                -0.02072812430560589,
                -0.024411555379629135,
                -0.03971528634428978,
                -0.02973582223057747,
                -0.0016869757091626525,
                -0.010967131704092026,
                -0.009840610437095165,
                0.010907880030572414,
                0.013962890021502972,
                -0.05460001155734062,
                -0.009814686141908169,
                -0.0016027819365262985,
                -0.04469115287065506,
                0.04117623716592789,
                0.04318301007151604,
                -0.017176462337374687,
                -0.00591731071472168,
                0.002004804788157344,
                0.0021230161655694246,
                -0.03261338546872139,
                0.004937278106808662,
                -0.010743396356701851,
                -0.0054246727377176285,
                0.022886766120791435,
                0.004248920828104019,
                -0.01125548966228962,
                0.013178885914385319,
                -0.031164685264229774,
                -0.012927225790917873,
                -0.08306176960468292,
                -0.011043276637792587,
                0.03830689936876297,
                -0.020224668085575104,
                -0.015274018980562687,
                -0.043852921575307846,
                -0.03652036190032959,
                0.032571617513895035,
                0.04037607088685036,
                0.00023127674649003893,
                -0.04235827177762985,
                0.007574798073619604,
                0.03333674743771553,
                0.0067518954165279865,
                0.003750681644305587,
                0.022402890026569366,
                -0.048556920140981674,
                0.03204573318362236,
                -0.02914893999695778,
                -0.026451660320162773,
                0.04256030172109604,
                0.007079459726810455,
                0.038410305976867676,
                0.003880169242620468,
                0.07980808615684509,
                0.039237577468156815,
                0.04571571201086044,
                -0.017688332125544548,
                0.02504122629761696,
                0.0034544768277555704,
                0.015407925471663475,
                -0.002268239390105009,
                -0.014821638353168964,
                -0.005977865308523178,
                -0.0223066583275795,
                -0.01128255296498537,
                -0.020749086514115334,
                -0.02903437800705433,
                -0.029974650591611862,
                0.007738173473626375,
                -0.0037629033904522657,
                0.002432732144370675,
                -5.429066577020814e-33,
                0.021797362715005875,
                -0.028755581006407738,
                0.051380500197410583,
                -0.007214698474854231,
                -0.08752959221601486,
                0.003974298946559429,
                0.044529639184474945,
                0.0043653203174471855,
                -0.050847526639699936,
                -0.019571125507354736,
                -0.04147742688655853,
                0.026183005422353745,
                0.0022486881352961063,
                0.01888275146484375,
                0.0508563332259655,
                -0.007368116173893213,
                -0.006879445165395737,
                0.0006874495884403586,
                -0.003159091342240572,
                -0.027978140860795975,
                -0.06281904131174088,
                0.020620647817850113,
                0.03596070781350136,
                0.050817426294088364,
                0.022922176867723465,
                -0.03512243926525116,
                -0.02502008154988289,
                -0.0031187382992357016,
                0.04131355136632919,
                -0.037918295711278915,
                -0.005548269487917423,
                -0.047305628657341,
                -0.028394876047968864,
                -0.055346135050058365,
                -0.00663524866104126,
                0.036189887672662735,
                0.0065786452032625675,
                -0.03446566313505173,
                -0.022641494870185852,
                -0.01646447367966175,
                -0.04005051031708717,
                -0.023593489080667496,
                0.0024570391979068518,
                -0.03213309124112129,
                0.06519895792007446,
                -0.0727599710226059,
                -0.0003810545022133738,
                0.03972211852669716,
                0.0206350926309824,
                0.040238477289676666,
                -0.06641855090856552,
                0.007299999240785837,
                -0.03495211526751518,
                0.05615708976984024,
                -0.009313181973993778,
                0.024612320587038994,
                -0.03543764725327492,
                -0.020585790276527405,
                -0.02244645170867443,
                -0.019678650423884392,
                0.03668983653187752,
                0.06850937753915787,
                0.003957937937229872,
                0.051545556634664536,
                -0.08732961863279343,
                0.01528009120374918,
                -0.03840848058462143,
                0.02379942685365677,
                0.00436605978757143,
                -0.028179071843624115,
                -0.0267792996019125,
                0.09631246328353882,
                -0.005220573395490646,
                -0.029311975464224815,
                -0.09262678027153015,
                0.016941934823989868,
                0.0003378360124770552,
                -0.03587943688035011,
                0.10088904201984406,
                -0.02923869900405407,
                -0.09421170502901077,
                0.024756938219070435,
                -0.03647651895880699,
                -0.011782050132751465,
                -0.008778640069067478,
                -0.06640929728746414,
                -0.024365371093153954,
                0.033388152718544006,
                0.03702545911073685,
                0.009288898669183254,
                -0.018112804740667343,
                -0.0010304233292117715,
                0.006133293733000755,
                0.07009269297122955,
                0.021129321306943893,
                -0.011257939040660858,
                -0.007158313877880573,
                0.03986433893442154,
                -0.0021665028762072325,
                0.006539681926369667,
                -0.07288391888141632,
                -0.00014769041445106268,
                -0.00022248133609537035,
                -0.012799101881682873,
                -0.016357546672225,
                -0.02273251861333847,
                -0.08436188101768494,
                -0.03519779071211815,
                -0.025696853175759315,
                -0.018265357241034508,
                0.018818216398358345,
                0.02872110903263092,
                -0.002122531644999981,
                -0.1377478986978531,
                -0.052074600011110306,
                -0.0008744546212255955,
                0.0005273044807836413,
                0.06481534987688065,
                -0.009856254793703556,
                -0.05121545493602753,
                0.036251094192266464,
                0.004424452316015959,
                0.030325477942824364,
                -0.03741171956062317,
                -0.041565265506505966,
                -0.03024805523455143,
                -0.027295351028442383,
                0.06221863627433777,
                -0.0013239230029284954,
                -0.03520456701517105,
                0.006098793353885412,
                -0.0021290017757564783,
                2.2650216635611287e-07,
                0.03265954554080963,
                0.04092166945338249,
                0.0222292710095644,
                0.00504612922668457,
                -0.00723829073831439,
                -0.0036854033824056387,
                0.0024420861154794693,
                0.02292175590991974,
                0.024652626365423203,
                0.07404181361198425,
                0.03833571821451187,
                -0.010629544965922832,
                -0.04353319853544235,
                0.009814773686230183,
                0.048945557326078415,
                -0.13566765189170837,
                -0.04168310388922691,
                -0.05644923821091652,
                -0.0414956659078598,
                -0.051831360906362534,
                0.037983570247888565,
                0.01651747152209282,
                0.04132265970110893,
                0.012224708683788776,
                -0.0071581508964300156,
                0.08414965122938156,
                0.0007599260425195098,
                0.02296527475118637,
                0.04488736763596535,
                0.04318446293473244,
                0.035229116678237915,
                -0.02641647681593895,
                -0.01499046292155981,
                0.02459157630801201,
                -0.010804645717144012,
                -0.0166468508541584,
                0.02975304052233696,
                0.0313250869512558,
                0.006427125073969364,
                0.03212699666619301,
                -0.013796191662549973,
                0.03891822323203087,
                -0.04803631827235222,
                0.05346078425645828,
                0.018894007429480553,
                -0.04530812054872513,
                -0.026689406484365463,
                0.00015198996698018163,
                -0.07381076365709305,
                -0.0013615370262414217,
                -0.006920541636645794,
                0.054926492273807526,
                -0.005433294922113419,
                0.07980667054653168,
                -0.007180018816143274,
                0.014214553870260715,
                -0.006721751764416695,
                0.05512562394142151,
                0.06138991937041283,
                -0.05787449702620506,
                0.015535515733063221,
                -0.026811320334672928,
                -0.020408451557159424,
                0.018789295107126236,
                -0.024557381868362427,
                -0.005393868312239647,
                -0.04712151736021042,
                8.009868442054103e-35,
                -0.022815711796283722,
                -0.05273483321070671,
                0.008598665706813335,
                -0.028513368219137192,
                0.015126590617001057,
                -0.03132262080907822,
                -0.053307343274354935,
                -0.0031791573856025934,
                0.008892221376299858,
                0.016973480582237244,
                -0.04104916378855705
            ]
        },
        "Values": {
            "name": [
                "Courage",
                "Integrity",
                "Determination",
                "Discipline",
                "Respect",
                "Loyalty"
            ],
            "rep": [
                0.036864619702100754,
                0.04861823841929436,
                -0.018561696633696556,
                -0.004616595339030027,
                -0.007409667130559683,
                0.03492041677236557,
                -0.03794407844543457,
                -0.011861060746014118,
                -0.10041438788175583,
                0.052474621683359146,
                0.08672815561294556,
                -0.03462814539670944,
                -0.031133847311139107,
                -0.04631267860531807,
                -0.024024125188589096,
                -0.0019524527015164495,
                -0.011534315533936024,
                0.05168561264872551,
                0.040726374834775925,
                -0.002343598520383239,
                0.00019809097284451127,
                0.004061330575495958,
                0.017313161864876747,
                -0.005167047027498484,
                0.05379411205649376,
                -0.03235044330358505,
                0.004474765621125698,
                -0.02194593846797943,
                0.008265216834843159,
                -0.06149205565452576,
                0.011628224514424801,
                -0.014218340627849102,
                0.04765845090150833,
                0.008383067324757576,
                1.9416083887335844e-06,
                -0.0491458959877491,
                -0.012437132187187672,
                -0.01360408402979374,
                -0.051023535430431366,
                -0.04419441148638725,
                -0.023628639057278633,
                0.007990246638655663,
                -0.011577935889363289,
                0.03208033740520477,
                -0.021439101547002792,
                0.019294969737529755,
                0.035801373422145844,
                0.041248876601457596,
                -0.034210823476314545,
                0.013680217787623405,
                0.02527032606303692,
                0.023095058277249336,
                -0.018078869208693504,
                -0.03476819768548012,
                0.010181537829339504,
                0.03794749081134796,
                -0.03398844227194786,
                -0.06436402350664139,
                -0.07359258085489273,
                -0.013075372204184532,
                0.01128410641103983,
                0.003171458374708891,
                -0.03018205612897873,
                0.031196607276797295,
                0.0026998422108590603,
                -0.05543321743607521,
                0.023475337773561478,
                -0.01931275799870491,
                0.049015019088983536,
                0.008988698944449425,
                -0.03898118808865547,
                0.06421362608671188,
                0.014782620593905449,
                0.09257171303033829,
                -0.023694835603237152,
                0.013031198643147945,
                -0.02422121725976467,
                0.02621351182460785,
                0.01881686970591545,
                -0.026232071220874786,
                0.025314630940556526,
                0.05328436568379402,
                -0.042409028857946396,
                0.029240354895591736,
                0.008642898872494698,
                -0.051899198442697525,
                -0.016138406470417976,
                0.009175872430205345,
                0.05608164519071579,
                -0.050605352967977524,
                -0.027890602126717567,
                -0.06207965686917305,
                -0.0035892969463020563,
                -0.010477419011294842,
                0.017402440309524536,
                -0.010690683498978615,
                0.019221005961298943,
                0.019413817673921585,
                -0.003309408901259303,
                -0.046404462307691574,
                -0.042855214327573776,
                0.0035338818561285734,
                0.03532012179493904,
                -0.018707428127527237,
                -0.046294622123241425,
                0.06263169646263123,
                0.02059532143175602,
                0.017770377919077873,
                0.006329790689051151,
                0.04848836734890938,
                0.0003556754090823233,
                0.014874238520860672,
                0.01141874399036169,
                0.02219659462571144,
                -0.007580548524856567,
                -0.009848788380622864,
                -0.0600137934088707,
                0.019749324768781662,
                -0.08089456707239151,
                0.039004579186439514,
                -0.10710316896438599,
                -0.04106827452778816,
                -0.03189714252948761,
                0.02545592002570629,
                0.058318108320236206,
                0.02558210864663124,
                0.024935759603977203,
                0.007855499163269997,
                0.025521723553538322,
                -0.0033049590419977903,
                -0.007037588860839605,
                -0.04756573587656021,
                0.0033220904879271984,
                -0.008647512644529343,
                0.05709432438015938,
                0.07512851059436798,
                0.014740423299372196,
                -0.021333061158657074,
                0.012305748648941517,
                0.039952658116817474,
                0.05402470752596855,
                -0.04229110851883888,
                0.01400004979223013,
                -0.022678758949041367,
                0.007023106329143047,
                -0.0037413411773741245,
                0.04714098945260048,
                -0.10413037240505219,
                0.010821718722581863,
                -0.011126283556222916,
                0.08387316018342972,
                0.06053393334150314,
                0.05887048691511154,
                -0.022478360682725906,
                0.08847182989120483,
                0.03428469970822334,
                0.005107922945171595,
                -0.031135831028223038,
                -0.019815709441900253,
                -0.00395776703953743,
                0.023399176076054573,
                0.026064420118927956,
                0.03913993388414383,
                -0.024639591574668884,
                -0.02600225806236267,
                0.031498830765485764,
                0.03031296655535698,
                0.007473029661923647,
                0.025914771482348442,
                -0.051248081028461456,
                -0.02383335493505001,
                -0.04178557172417641,
                -0.007232369855046272,
                -0.0020955412182956934,
                -0.014187579974532127,
                0.06459323316812515,
                0.0027285171672701836,
                -0.08339694142341614,
                -0.08595404028892517,
                0.04914171248674393,
                0.029562009498476982,
                -0.11710600554943085,
                0.01693032681941986,
                -0.0031398236751556396,
                0.028049424290657043,
                0.06229781359434128,
                -0.02617548778653145,
                0.06342580169439316,
                -0.004311030730605125,
                0.026637449860572815,
                -0.027968956157565117,
                0.01771361380815506,
                -0.015186766162514687,
                0.024450546130537987,
                -0.031101135537028313,
                -0.009688358753919601,
                0.019149702042341232,
                -0.08520258963108063,
                0.0003644201497081667,
                -0.0753970593214035,
                -0.018243977800011635,
                -0.03551081568002701,
                0.046089306473731995,
                -0.01827031560242176,
                -0.05506123974919319,
                0.03739894554018974,
                0.038104861974716187,
                -0.009822553023695946,
                -0.03669583424925804,
                -0.011323078535497189,
                -0.02973831072449684,
                0.01735873334109783,
                0.03235476464033127,
                0.0008362377411685884,
                -0.011953665874898434,
                0.007842952385544777,
                -0.009828700684010983,
                0.019358627498149872,
                -0.04972144961357117,
                0.025097884237766266,
                -0.03435015305876732,
                0.07527328282594681,
                -0.002072582021355629,
                -0.005974954925477505,
                -0.016394440084695816,
                -0.039674125611782074,
                -0.006764196790754795,
                -0.013337692245841026,
                0.027719175443053246,
                -0.011037113144993782,
                -0.005761663429439068,
                0.031197641044855118,
                0.00604197708889842,
                0.002633980941027403,
                -0.01704723946750164,
                0.04103144630789757,
                0.03295847028493881,
                -0.015210107900202274,
                0.014406820759177208,
                -0.0660216361284256,
                -0.000504476425703615,
                -0.011573963798582554,
                0.0026397472247481346,
                0.046314992010593414,
                -0.013590744696557522,
                0.013567773625254631,
                0.018492307513952255,
                0.02099624089896679,
                0.03338133171200752,
                0.010098948143422604,
                -0.006460148841142654,
                -0.027752608060836792,
                0.043037790805101395,
                -0.02459590695798397,
                0.038482096046209335,
                -0.027227723971009254,
                0.0025780287105590105,
                0.015165898948907852,
                -0.030829092487692833,
                0.008364646695554256,
                0.020603813230991364,
                -0.005761691369116306,
                0.014902349561452866,
                -0.025802437216043472,
                0.041915811598300934,
                -0.009998131543397903,
                0.027594957500696182,
                -0.021654698997735977,
                8.188459469238296e-05,
                0.008924064226448536,
                0.01970938965678215,
                -0.01112379226833582,
                0.00402147276327014,
                -0.023750770837068558,
                0.012398021295666695,
                -0.00964127667248249,
                -0.05796075239777565,
                0.019695818424224854,
                -0.008944263681769371,
                -0.0283516775816679,
                0.019972993060946465,
                0.013932578265666962,
                -0.06165975704789162,
                -0.023441826924681664,
                0.012290174141526222,
                -0.010467764921486378,
                -0.045034151524305344,
                0.022982601076364517,
                0.05660159885883331,
                0.017589185386896133,
                -0.045772772282361984,
                0.012162481434643269,
                0.033431071788072586,
                -0.06540409475564957,
                0.03209250047802925,
                -0.01194677222520113,
                0.018115345388650894,
                -0.018448438495397568,
                0.059121739119291306,
                -0.03023122064769268,
                -0.0025442996993660927,
                0.023189296945929527,
                0.02913922443985939,
                -0.017256150022149086,
                -0.005791772622615099,
                -0.013228220865130424,
                0.008422489278018475,
                -0.02136436104774475,
                -0.016831178218126297,
                0.007419827859848738,
                0.017963523045182228,
                0.0286679919809103,
                0.022228986024856567,
                0.03598562628030777,
                -0.015224945731461048,
                0.00029326931689865887,
                -0.02189631015062332,
                -0.029438314959406853,
                0.02474331669509411,
                0.07732909172773361,
                0.016152748838067055,
                0.01731143891811371,
                -0.01340569369494915,
                0.0036223845090717077,
                0.036822590976953506,
                -0.012584863230586052,
                -0.00465162331238389,
                0.029098067432641983,
                0.11979065090417862,
                0.04068193584680557,
                -0.02347121387720108,
                -0.03196977823972702,
                0.022695371881127357,
                -0.004529282450675964,
                -0.09089905023574829,
                0.03484172374010086,
                -0.03943297639489174,
                0.04872314631938934,
                -0.014830018393695354,
                -0.02463202178478241,
                -0.014047008939087391,
                -0.02202034741640091,
                -0.0345102921128273,
                -0.02951066568493843,
                -0.038549575954675674,
                -0.046678222715854645,
                -0.004176146350800991,
                -0.0059626465663313866,
                0.0045106192119419575,
                -0.05333617702126503,
                0.09433159977197647,
                0.03057093918323517,
                -0.008894702419638634,
                -0.041469208896160126,
                0.028791453689336777,
                -0.011924958787858486,
                -0.01970677636563778,
                0.015166275203227997,
                0.07702786475419998,
                -0.019178686663508415,
                0.025831766426563263,
                0.011167504824697971,
                0.07913941144943237,
                0.056854549795389175,
                0.05845342576503754,
                0.024215104058384895,
                0.019058704376220703,
                0.0323982909321785,
                0.021805059164762497,
                -0.0018470948562026024,
                0.06079071760177612,
                0.08770231157541275,
                0.017460495233535767,
                0.033120062202215195,
                -0.006117961835116148,
                0.08071666210889816,
                -0.021210579201579094,
                -0.09687910974025726,
                0.04874592274427414,
                0.0009787344606593251,
                0.01991034485399723,
                -0.03543916717171669,
                0.020976480096578598,
                0.035921379923820496,
                0.01040688343346119,
                -0.030573910102248192,
                -0.008500760421156883,
                0.02463681250810623,
                -0.01051029097288847,
                -0.014297711662948132,
                0.012694550678133965,
                -0.011512557044625282,
                -0.01924663595855236,
                -0.01661934331059456,
                0.034695081412792206,
                0.01471628062427044,
                0.002590073039755225,
                0.00961295422166586,
                -0.011597159318625927,
                0.0012916227569803596,
                0.037374116480350494,
                0.08206017315387726,
                0.03807639330625534,
                0.06566523015499115,
                -0.023112531751394272,
                -0.022245442494750023,
                -0.041708964854478836,
                0.007692356593906879,
                0.011169690638780594,
                -0.03531036153435707,
                -0.06165210157632828,
                0.003745991736650467,
                -0.0016173594631254673,
                0.018232597038149834,
                -0.01735052652657032,
                -0.025996748358011246,
                -0.02270296774804592,
                -0.03320465609431267,
                -0.012920073233544827,
                0.005967624019831419,
                -0.019713571295142174,
                0.01334621012210846,
                -0.005370906554162502,
                0.0042090509086847305,
                0.04593732953071594,
                -0.01474305521696806,
                0.008984236977994442,
                -0.005350275430828333,
                0.02388138324022293,
                -0.025378886610269547,
                -0.02069748379290104,
                -0.0048939879052340984,
                -0.01644325815141201,
                0.037282794713974,
                -0.024919098243117332,
                -0.08495376259088516,
                -0.028617555275559425,
                -0.02887321449816227,
                0.03280841186642647,
                -0.001131746917963028,
                0.010219461284577847,
                0.0069648646749556065,
                0.013945262879133224,
                -0.006311847362667322,
                -0.047109995037317276,
                -0.0752408504486084,
                0.023834938183426857,
                -0.02970118820667267,
                -0.03896589204668999,
                0.038336414843797684,
                0.01220688596367836,
                0.002535608597099781,
                0.08622279018163681,
                -0.02569771558046341,
                0.01888221688568592,
                0.0028883584309369326,
                0.03297383338212967,
                0.035594552755355835,
                0.014334646984934807,
                0.05627379193902016,
                0.03464096412062645,
                0.031249569728970528,
                -0.0454937182366848,
                0.02255154214799404,
                0.006341374013572931,
                0.046976152807474136,
                0.0028183215763419867,
                -0.04794277250766754,
                0.05164019763469696,
                0.004517299123108387,
                -0.02938271313905716,
                -0.013453880324959755,
                -0.03374091535806656,
                0.02794865518808365,
                -0.02631739154458046,
                -0.021261056885123253,
                -0.05777647718787193,
                -0.03785789757966995,
                0.02065589651465416,
                0.003539616707712412,
                -0.014194652438163757,
                -0.02243022620677948,
                -0.011709759011864662,
                0.0021651338320225477,
                -0.02222452685236931,
                -0.01535519678145647,
                0.011269350536167622,
                -0.02692471258342266,
                0.0008211021777242422,
                -0.03552433103322983,
                0.019432077184319496,
                0.011967227794229984,
                -0.006814820226281881,
                -0.07773616164922714,
                0.0024088597856462,
                0.018722515553236008,
                -0.11517248302698135,
                -0.004285732284188271,
                0.05136427283287048,
                0.02998289279639721,
                -0.04040638729929924,
                0.00907270610332489,
                0.0021389795001596212,
                0.015097702853381634,
                -0.03385884687304497,
                -0.021397337317466736,
                0.024913983419537544,
                0.05112240090966225,
                -0.006983571220189333,
                -0.025843139737844467,
                0.028050720691680908,
                -0.026628606021404266,
                -0.08493684977293015,
                -0.09954201430082321,
                -0.004221362993121147,
                -0.06219973415136337,
                -0.04254460334777832,
                -0.050668418407440186,
                -0.01178817544132471,
                0.004803865682333708,
                -0.013525160029530525,
                -0.02278546616435051,
                -0.030979612842202187,
                -0.013045971281826496,
                0.06337311118841171,
                0.03199567645788193,
                -0.003651623148471117,
                0.03007389046251774,
                -0.01205181423574686,
                -0.015689944848418236,
                -0.08329688757658005,
                0.04634147137403488,
                -0.021702229976654053,
                -0.008238967508077621,
                -0.012637880630791187,
                0.05151553452014923,
                -0.01593693532049656,
                0.06273391097784042,
                0.025372302159667015,
                0.003061719238758087,
                -0.05133325234055519,
                -0.02344469353556633,
                0.07017942517995834,
                0.006949608214199543,
                0.06008419394493103,
                -0.019142411649227142,
                0.000704087084159255,
                -0.0006038261344656348,
                0.013661474920809269,
                0.021408425644040108,
                -0.028735945001244545,
                -0.05297426879405975,
                -0.03582507744431496,
                -0.008352845907211304,
                0.013391980901360512,
                -7.354874196527167e-33,
                -0.004586114082485437,
                0.008334839716553688,
                -0.03681796044111252,
                0.0033555906265974045,
                -0.09837110340595245,
                0.02044907957315445,
                0.009670165367424488,
                0.08491930365562439,
                -0.016191083937883377,
                -0.03180889040231705,
                -0.02402338571846485,
                -0.01738862320780754,
                0.02083979919552803,
                0.006884933914989233,
                0.013518113642930984,
                0.013780276291072369,
                -0.047080352902412415,
                0.019864782691001892,
                -0.010389333590865135,
                -0.0555371418595314,
                0.010524241253733635,
                -0.0258403979241848,
                -0.009527447633445263,
                -0.06304138898849487,
                0.026798035949468613,
                0.010111361742019653,
                -0.030102724209427834,
                -0.011598007753491402,
                0.019271083176136017,
                0.02046540193259716,
                -0.004070134833455086,
                -0.0775223895907402,
                -0.023780014365911484,
                -0.0673377737402916,
                0.004888399504125118,
                -0.0003956910513807088,
                -0.019766636192798615,
                -0.015694817528128624,
                -0.029479842633008957,
                -0.03922336548566818,
                -0.049793168902397156,
                -0.04010147601366043,
                -0.01575675792992115,
                -0.02035030722618103,
                0.010744565166532993,
                -0.049860820174217224,
                -0.030473632737994194,
                0.04008259251713753,
                0.039903268218040466,
                0.021235888823866844,
                0.005119935143738985,
                -0.004825318232178688,
                -0.04842530936002731,
                -0.05456383526325226,
                -0.028174538165330887,
                -0.028827114030718803,
                0.021623166278004646,
                -0.050711385905742645,
                -0.000860098225530237,
                0.006700125522911549,
                -0.056288495659828186,
                0.027238957583904266,
                -0.04354162886738777,
                0.06116789951920509,
                -0.02137603983283043,
                0.015478909946978092,
                -0.041071925312280655,
                -0.03236379474401474,
                0.04008890688419342,
                0.02269733138382435,
                -0.019491631537675858,
                0.07940871268510818,
                0.048135578632354736,
                0.0014067356241866946,
                -0.13326680660247803,
                -0.04375040903687477,
                -0.03211287781596184,
                -0.0012538947630673647,
                0.036441314965486526,
                -0.04361629858613014,
                0.03359949216246605,
                0.03585607931017876,
                0.026822134852409363,
                0.03676644340157509,
                -0.02421000972390175,
                -0.030502818524837494,
                -0.0007662177667953074,
                0.06233593821525574,
                -0.017561672255396843,
                -0.023765666410326958,
                -0.03411295637488365,
                -0.014892911538481712,
                0.04067610576748848,
                0.06694993376731873,
                -0.04331006854772568,
                0.05987611040472984,
                -0.027669843286275864,
                0.014760185964405537,
                0.03136758506298065,
                -0.03432558849453926,
                0.03455143794417381,
                0.025891795754432678,
                -0.018176086246967316,
                0.03975702077150345,
                0.004855148959904909,
                -0.0009804157307371497,
                0.017075976356863976,
                0.053984906524419785,
                -0.0792112797498703,
                -0.008006904274225235,
                0.0013919190969318151,
                0.01891133189201355,
                -0.01154367160052061,
                0.005982319824397564,
                -0.018010400235652924,
                -0.018855465576052666,
                0.014010218903422356,
                -0.028869062662124634,
                -0.02720772661268711,
                -0.022692278027534485,
                0.006028303410857916,
                0.04064962640404701,
                -0.052565254271030426,
                -0.0015596167650073767,
                -0.05894165113568306,
                0.0024026709143072367,
                -0.003670566249638796,
                0.0901215597987175,
                -0.017229240387678146,
                -0.016825787723064423,
                0.005706749856472015,
                -0.012948832474648952,
                2.572238031461893e-07,
                -0.10843568295240402,
                -0.02725153975188732,
                -0.001064941636286676,
                0.03683794289827347,
                0.0006126508815214038,
                0.04850216582417488,
                -0.0018248992273584008,
                0.031479161232709885,
                -0.007394677493721247,
                0.028713595122098923,
                0.05176255479454994,
                -0.015550104901194572,
                -0.024291450157761574,
                -0.03850138932466507,
                0.11546611785888672,
                -0.05895930156111717,
                0.043578021228313446,
                -0.017050841823220253,
                0.014806065708398819,
                -0.039449259638786316,
                0.02375122904777527,
                0.03021342307329178,
                -0.005705993622541428,
                0.00578400120139122,
                0.00528766168281436,
                0.05041509494185448,
                0.0015170617261901498,
                -0.043239228427410126,
                0.011457862332463264,
                -0.02946559712290764,
                0.012333525344729424,
                0.04075493663549423,
                -0.01523117907345295,
                -0.04034081846475601,
                -0.03165110573172569,
                -0.02088974229991436,
                0.054513897746801376,
                0.03545830398797989,
                0.012367615476250648,
                0.04768054187297821,
                0.02395196072757244,
                0.009094723500311375,
                -0.03905951604247093,
                0.06278429925441742,
                -0.011532075703144073,
                0.07348043471574783,
                -0.025060687214136124,
                -0.0262050312012434,
                -0.028434982523322105,
                -0.0034190169535577297,
                0.02231665886938572,
                0.034203510731458664,
                0.004503225442022085,
                0.030402928590774536,
                0.0029615021776407957,
                0.026544194668531418,
                0.024555398151278496,
                -0.015796130523085594,
                0.03407406806945801,
                -0.061891306191682816,
                0.026974692940711975,
                0.03248662129044533,
                -0.005027803126722574,
                0.06810380518436432,
                -0.03121221996843815,
                0.008897785097360611,
                -0.022790253162384033,
                9.988150244175534e-35,
                -0.012768951244652271,
                -0.019339481368660927,
                -0.04971752688288689,
                -0.0020923689007759094,
                0.049468137323856354,
                -0.013864908367395401,
                0.056500863283872604,
                -0.00135292939376086,
                0.011804296635091305,
                0.010678548365831375,
                -0.016157247126102448,
                0.007220093160867691,
                0.12690812349319458,
                -0.013330596499145031,
                -0.009178786538541317,
                -0.05898209661245346,
                -0.009154231287539005,
                -0.023061420768499374,
                -0.014431523159146309,
                -0.08194530755281448,
                0.022797027602791786,
                0.05601118132472038,
                -0.037067823112010956,
                0.018782606348395348,
                0.00963249709457159,
                -0.012924671173095703,
                -0.001317267189733684,
                0.012269774451851845,
                0.012224575504660606,
                0.037114500999450684,
                -0.0038826619274914265,
                -0.003940482158213854,
                0.018927721306681633,
                0.03301624953746796,
                -0.02350311167538166,
                -0.02588529698550701,
                -0.03591611981391907,
                0.027589676901698112,
                -0.00884229689836502,
                -0.022075248882174492,
                -0.009637098759412766,
                0.003521398873999715,
                0.010663026012480259,
                -0.011588972061872482,
                -0.02570822834968567,
                1.9046581201109802e-06,
                -0.029168391600251198,
                -0.015016445890069008,
                -0.007111200597137213,
                -0.049777280539274216,
                0.012773741967976093,
                0.007624432910233736,
                0.001422704430297017,
                -0.015381290577352047,
                0.005473981145769358,
                -0.03884754702448845,
                0.06750884652137756,
                0.03648354113101959,
                0.028269104659557343,
                -0.0009251724695786834,
                -0.014358358457684517,
                0.005042159929871559,
                0.06588180363178253,
                -0.02864660695195198,
                -0.019321197643876076,
                -0.01558055728673935,
                -0.003677650121971965,
                0.00537432124838233,
                -0.031251899898052216,
                -0.09369475394487381,
                0.037889547646045685,
                0.02838130295276642,
                -0.02475082315504551,
                -0.014498879201710224,
                -0.027326690033078194,
                0.0022591922897845507,
                -0.025624530389904976,
                -0.0024178256280720234,
                -0.005925825797021389,
                0.04400869458913803,
                -0.02564220316708088,
                0.021079346537590027,
                0.08640003204345703,
                -0.016894478350877762,
                0.0903797298669815,
                -0.03046954795718193,
                -0.06486929953098297,
                0.002105571096763015,
                0.03356126323342323,
                0.0019168260041624308,
                -0.00980145949870348,
                -0.005381995812058449,
                0.07332955300807953,
                -0.016969598829746246,
                0.04156991094350815,
                0.03207574784755707,
                -0.025060057640075684,
                -0.006022876128554344,
                -0.014646865427494049,
                -0.012717870064079762,
                0.014178866520524025,
                -0.029142368584871292,
                -0.04944000765681267,
                -0.045092564076185226,
                0.011200613342225552,
                0.041857779026031494,
                -0.028129398822784424,
                0.015408728271722794,
                0.04585038125514984,
                0.014360136352479458,
                -0.011638130992650986,
                -0.07877056300640106,
                0.026181602850556374,
                0.06631720811128616,
                0.0025787882041186094,
                0.028745556250214577,
                0.06589121371507645,
                0.028424406424164772,
                0.04087516665458679,
                -0.015823477879166603,
                0.04492426663637161,
                0.02272605337202549,
                0.02113666944205761,
                0.03347652032971382,
                0.058021146804094315,
                0.04927325248718262,
                0.015626585111021996,
                -0.042588282376527786,
                0.018813099712133408,
                -0.08500173687934875,
                0.03245951607823372,
                -0.06949996948242188,
                0.01761537976562977,
                -0.05397912859916687,
                -0.02156383916735649,
                -0.007821719162166119,
                -0.01260621752589941,
                -0.034002114087343216,
                0.016369309276342392,
                0.0032701261807233095,
                -0.025297846645116806,
                -0.0007633428322151303,
                -0.03427473455667496,
                -0.0017074086936190724,
                0.026016822084784508,
                0.04410139098763466,
                0.02328268438577652,
                0.015730401501059532,
                -0.027279367670416832,
                -0.05595480650663376,
                0.027094727382063866,
                0.006204534787684679,
                -0.06038930267095566,
                -0.024015070870518684,
                0.0016057631000876427,
                -0.016907161101698875,
                -0.018936043605208397,
                0.004777940921485424,
                -0.09367158263921738,
                0.0029072295874357224,
                -0.02582210674881935,
                -0.00858122855424881,
                0.004061397165060043,
                0.009909517131745815,
                0.014093874953687191,
                0.019935742020606995,
                0.049665309488773346,
                -0.00194942532107234,
                -0.04473806917667389,
                0.0010550370207056403,
                -0.034887298941612244,
                0.049838315695524216,
                -0.008260553702712059,
                -0.023401647806167603,
                -0.033540256321430206,
                -0.04671259969472885,
                0.02669326588511467,
                0.04550599679350853,
                0.01577076129615307,
                0.03138255700469017,
                -0.024317070841789246,
                -0.01378683652728796,
                0.024574335664510727,
                -0.031762365251779556,
                0.015957089141011238,
                0.03135454282164574,
                0.024010341614484787,
                -0.06998465210199356,
                -0.0525558777153492,
                -0.08357774466276169,
                -0.002202659612521529,
                -0.029558852314949036,
                -0.09003203362226486,
                -0.008859578520059586,
                0.015714796259999275,
                0.10593590140342712,
                0.019590085372328758,
                -0.0064702825620770454,
                0.05818169564008713,
                -0.02005760185420513,
                -0.0213478971272707,
                -0.014563341625034809,
                -0.021698787808418274,
                -0.02933651953935623,
                0.01829668879508972,
                -0.01258730050176382,
                -0.012515884824097157,
                0.032023921608924866,
                -0.068564772605896,
                -0.00883675366640091,
                -0.015206188894808292,
                0.002161479787901044,
                -0.029089227318763733,
                0.11086267977952957,
                0.0625510960817337,
                -0.02340935356914997,
                0.01909479685127735,
                0.04548031464219093,
                -0.02315167337656021,
                0.012297780252993107,
                0.007010817993432283,
                -0.01637658290565014,
                -0.014229003340005875,
                0.009078770875930786,
                -0.0019424670608714223,
                -0.01831245608627796,
                0.029145771637558937,
                0.03837144374847412,
                0.03194543719291687,
                -0.0352340042591095,
                0.029419371858239174,
                -0.02750684879720211,
                0.03316861391067505,
                -0.029776033014059067,
                -0.01170995645225048,
                0.005520451348274946,
                -0.0434754453599453,
                -0.0067659057676792145,
                -0.0015450486680492759,
                0.007180936634540558,
                -0.008180730976164341,
                -0.01234677154570818,
                0.016015321016311646,
                -0.010429020039737225,
                0.0413702018558979,
                0.0547175258398056,
                -0.02745622955262661,
                0.07980308681726456,
                0.12012399733066559,
                -0.012766983360052109,
                -0.09579169750213623,
                0.013409803621470928,
                -0.011241384781897068,
                0.024030352011322975,
                0.033421412110328674,
                0.020232992246747017,
                0.022167135030031204,
                -0.03386034816503525,
                -0.02882199175655842,
                0.0006805077427998185,
                -0.017736809328198433,
                -0.0003824306186288595,
                -0.020421642810106277,
                0.027861792594194412,
                -0.06452693790197372,
                0.032144736498594284,
                -0.006682137027382851,
                -0.10204853862524033,
                0.043787576258182526,
                -0.02279161661863327,
                0.01098623126745224,
                0.01804424822330475,
                -0.051321130245923996,
                0.02022491581737995,
                0.019175268709659576,
                0.06943424791097641,
                -0.015340046025812626,
                0.015236986801028252,
                -0.03224444389343262,
                0.014622892253100872,
                -0.010191423818469048,
                0.023221388459205627,
                -0.019258376210927963,
                0.02428850531578064,
                -0.016745200380682945,
                0.02513529360294342,
                -0.013556771911680698,
                -0.09459414333105087,
                0.014304611831903458,
                -0.027784883975982666,
                -0.0003780092520173639,
                0.02807721123099327,
                0.010516773909330368,
                -0.03449785336852074,
                0.0014931996120139956,
                0.019069146364927292,
                0.014175626449286938,
                -0.014479906298220158,
                0.025388918817043304,
                0.013695724308490753,
                -0.09060432016849518,
                -0.05623405799269676,
                -0.004683947190642357,
                0.016608543694019318,
                -0.06757647544145584,
                0.04928380250930786,
                -0.0015349802561104298,
                -0.0352654792368412,
                -0.004984854254871607,
                0.03481748327612877,
                -0.005224144086241722,
                0.0006253898027352989,
                0.014763753861188889,
                0.012924113310873508,
                -0.02882426418364048,
                -0.009911599569022655,
                0.06083396449685097,
                0.054520267993211746,
                -0.014454690739512444,
                -0.014201796613633633,
                0.030252138152718544,
                0.002702887635678053,
                0.013129477389156818,
                0.02389155887067318,
                0.06107516959309578,
                0.024718057364225388,
                -0.014814273454248905,
                0.037511661648750305,
                -0.04343470185995102,
                0.025218352675437927,
                0.08449586480855942,
                0.006593199912458658,
                0.00561861414462328,
                -0.003655750770121813,
                -0.029582418501377106,
                0.05010204389691353,
                0.047242965549230576,
                0.00829891487956047,
                0.06558775901794434,
                0.057202138006687164,
                -0.018615806475281715,
                -0.01474888063967228,
                0.09801466017961502,
                -0.004405722953379154,
                -0.012374136596918106,
                -0.03574983775615692,
                0.06842835247516632,
                -0.032656602561473846,
                -0.03139021620154381,
                -0.016018657013773918,
                -0.004041306208819151,
                0.00010336016566725448,
                -0.03363349288702011,
                -0.035849861800670624,
                -0.026920774951577187,
                0.013700272887945175,
                -0.04902292788028717,
                -0.011631963774561882,
                0.045735906809568405,
                -0.02167317271232605,
                -0.013853389769792557,
                0.019180065020918846,
                -0.012868771329522133,
                -0.03332202136516571,
                -0.014870701357722282,
                0.02800414152443409,
                -0.020142661407589912,
                -0.04077429696917534,
                0.002617558231577277,
                0.05052110552787781,
                -0.028406426310539246,
                -0.030504144728183746,
                0.020153062418103218,
                0.03529304638504982,
                0.036542393267154694,
                0.046495188027620316,
                0.008136283606290817,
                0.06548294425010681,
                0.04180089011788368,
                -0.03921842202544212,
                -0.0125245600938797,
                0.013560083694756031,
                0.04508731886744499,
                -0.040814027190208435,
                0.03672853112220764,
                -0.018213670700788498,
                0.08369408547878265,
                0.006480259355157614,
                -0.00679631857201457,
                0.031648922711610794,
                -0.029586002230644226,
                0.022843938320875168,
                -0.0777575746178627,
                0.020773056894540787,
                0.015500608831644058,
                0.008420449681580067,
                -0.052052803337574005,
                -0.03919024392962456,
                -0.009989743120968342,
                0.013841425999999046,
                0.00564642995595932,
                0.03099077381193638,
                -0.010737105272710323,
                -0.10529940575361252,
                0.052450649440288544,
                0.03143619745969772,
                -0.0016658380627632141,
                0.04526105523109436,
                0.045505814254283905,
                0.00963950902223587,
                0.017566440626978874,
                0.014724655076861382,
                0.04362710565328598,
                -0.014561167918145657,
                0.0531272254884243,
                -0.02125772275030613,
                -0.004914813209325075,
                0.07956857979297638,
                0.02550799958407879,
                -0.010589826852083206,
                -0.08616721630096436,
                -0.07216759026050568,
                -0.008622650988399982,
                -0.04439163953065872,
                0.024330895394086838,
                0.07451290637254715,
                -0.07755611836910248,
                -0.009674042463302612,
                -0.05114815756678581,
                0.021345634013414383,
                0.033276136964559555,
                0.006675639655441046,
                -0.03409911319613457,
                -0.003243678016588092,
                -0.01852993667125702,
                -0.033138781785964966,
                0.004415385890752077,
                -0.0325806587934494,
                0.05296918749809265,
                0.007781881373375654,
                -0.023828601464629173,
                0.006238799076527357,
                -0.014587691985070705,
                -0.04961219057440758,
                -0.014801996760070324,
                -0.031071117147803307,
                0.006913674529641867,
                0.0060755787417292595,
                0.004400583915412426,
                0.012551105581223965,
                -0.04504134878516197,
                -0.01753273978829384,
                -0.005915840156376362,
                0.01429878268390894,
                0.015161433257162571,
                -0.0490596704185009,
                -0.09617709368467331,
                -0.0029389469418674707,
                -0.02974698133766651,
                -0.0527971051633358,
                -0.01324068009853363,
                0.012258083559572697,
                0.041769228875637054,
                0.06034591421484947,
                -0.05356547236442566,
                0.020533649250864983,
                0.010080129839479923,
                -0.008590023033320904,
                0.07229235023260117,
                0.026651525869965553,
                0.07370408624410629,
                0.04539267346262932,
                0.0417725034058094,
                0.023070499300956726,
                -0.014040893875062466,
                0.022360896691679955,
                -0.06059574335813522,
                -0.030522236600518227,
                -0.05901522934436798,
                0.002027760725468397,
                0.012804615311324596,
                -0.05678028240799904,
                -0.016203423961997032,
                -0.009501469321548939,
                -0.007921099662780762,
                0.0299840085208416,
                0.022945616394281387,
                -0.050352733582258224,
                -0.0289726871997118,
                -0.0063408794812858105,
                -0.008745433762669563,
                0.00693872943520546,
                0.02275679260492325,
                -0.003082881448790431,
                0.04974944144487381,
                0.005502148065716028,
                -0.035423338413238525,
                0.007849366404116154,
                -0.016216689720749855,
                0.02941657416522503,
                -0.01654219627380371,
                0.029985910281538963,
                0.03193316236138344,
                -0.02629327028989792,
                -0.03874688223004341,
                -0.023050373420119286,
                0.03987140208482742,
                -0.08108989894390106,
                -0.008935811929404736,
                -0.06471994519233704,
                0.019271038472652435,
                -0.012750890105962753,
                0.011098715476691723,
                0.00971289537847042,
                0.051782697439193726,
                -0.004732117522507906,
                0.034985847771167755,
                0.0035870722495019436,
                0.04137001186609268,
                0.007082048337906599,
                -0.002556182211264968,
                0.019234756007790565,
                -0.0074669551104307175,
                -0.04413546994328499,
                0.009106332436203957,
                -0.003549985121935606,
                0.024007193744182587,
                -0.08024661988019943,
                -0.006893946323543787,
                -0.04866635799407959,
                0.02722732163965702,
                0.018889285624027252,
                0.019977809861302376,
                0.030637027695775032,
                0.03140416741371155,
                -0.010239674709737301,
                0.013699902221560478,
                0.019478995352983475,
                -0.021716495975852013,
                -0.011832987889647484,
                0.005819641053676605,
                -0.09570764750242233,
                0.011961414478719234,
                -0.0903700515627861,
                0.015233726240694523,
                -0.007250992115586996,
                0.05424884334206581,
                -0.018980396911501884,
                0.09275531023740768,
                -0.02347164787352085,
                -0.010834748856723309,
                -0.07285503298044205,
                0.029177634045481682,
                0.023623919114470482,
                0.012300928123295307,
                0.054637882858514786,
                -0.02472241409122944,
                -0.048754896968603134,
                0.02865642122924328,
                0.04946945607662201,
                0.035720083862543106,
                -0.011923598125576973,
                -0.083652563393116,
                -0.11151722073554993,
                -0.03717861324548721,
                0.02313348837196827,
                -7.601988088324967e-33,
                -0.0033825754653662443,
                -0.019693512469530106,
                -0.010365263558924198,
                0.021640779450535774,
                -0.08544564992189407,
                0.008891357108950615,
                0.016198091208934784,
                0.03150733560323715,
                -0.05716391280293465,
                -0.042422883212566376,
                -0.020727336406707764,
                -0.006449337583035231,
                0.016388677060604095,
                0.0007731726509518921,
                0.03495887294411659,
                -0.016696758568286896,
                -0.026147156953811646,
                0.02142287790775299,
                -0.02785431034862995,
                -0.034589264541864395,
                -0.0027886752504855394,
                -0.007213981356471777,
                0.02757444977760315,
                0.020380433648824692,
                0.049127668142318726,
                -0.0015857412945479155,
                -0.03266015276312828,
                -0.017298739403486252,
                -0.0029248904902487993,
                -0.007535844575613737,
                -0.012015796266496181,
                -0.023495672270655632,
                -0.013745570555329323,
                -0.04836667329072952,
                0.032538630068302155,
                0.07324875146150589,
                -0.026432819664478302,
                -0.004953879863023758,
                -0.006779173854738474,
                -0.010079947300255299,
                -0.058148905634880066,
                -0.08312271535396576,
                0.02114173024892807,
                -0.017910398542881012,
                -0.007074172608554363,
                -0.051755521446466446,
                0.008552821353077888,
                0.024593845009803772,
                0.02507046051323414,
                0.03700904920697212,
                0.006287120748311281,
                -0.011465050280094147,
                -0.04852145165205002,
                0.0053646015003323555,
                -0.006547981407493353,
                -0.011218507774174213,
                0.0013459421461448073,
                0.03865598142147064,
                -0.03333544731140137,
                -0.006636158563196659,
                -0.05561434477567673,
                -0.004618796054273844,
                -0.033339451998472214,
                -0.012015814892947674,
                0.015362735837697983,
                0.015058137476444244,
                -0.042604126036167145,
                0.04490929841995239,
                0.04010569676756859,
                0.015132279135286808,
                0.01397655438631773,
                0.0737074539065361,
                0.05618048086762428,
                0.008138592354953289,
                -0.11388938128948212,
                -0.04156568646430969,
                -0.00206947885453701,
                0.002496486296877265,
                0.049797821789979935,
                -0.014775686897337437,
                -0.01631035842001438,
                0.01229232270270586,
                -0.019243208691477776,
                0.004456475842744112,
                -0.014686793088912964,
                -0.09541141241788864,
                0.0049632638692855835,
                0.015554998070001602,
                -0.013045482337474823,
                -0.01945008896291256,
                -0.006241308059543371,
                0.03313110023736954,
                0.037043459713459015,
                0.015065093524754047,
                -0.005278264172375202,
                -0.008174476213753223,
                -0.02495860494673252,
                0.011951589956879616,
                0.009668194688856602,
                -0.012481773272156715,
                0.03355126082897186,
                0.05198721960186958,
                -0.006224299315363169,
                0.014744390733540058,
                0.010152355767786503,
                -0.017813319340348244,
                0.009866416454315186,
                0.027514729648828506,
                -0.03474545478820801,
                -0.0530754029750824,
                -0.008071103133261204,
                0.05008575692772865,
                0.02936948649585247,
                -0.005879739765077829,
                -0.03830396384000778,
                -0.002469847211614251,
                0.002607048721984029,
                -0.05130314454436302,
                0.0015151286497712135,
                0.03356805816292763,
                0.019099166616797447,
                0.028820108622312546,
                -0.05867042392492294,
                -0.014113236218690872,
                -0.03886804357171059,
                0.02233501523733139,
                -0.003902242984622717,
                -0.004959763493388891,
                -0.06286713480949402,
                -0.026405133306980133,
                -0.006286535412073135,
                -0.015326520428061485,
                2.587068479442678e-07,
                -0.06406529247760773,
                0.015865758061408997,
                -0.0018345164135098457,
                0.0021211036946624517,
                0.023841196671128273,
                0.043547023087739944,
                -0.006044283043593168,
                0.04338369891047478,
                -0.021255629137158394,
                0.007275091018527746,
                0.05577428266406059,
                -0.003802286460995674,
                0.011193761602044106,
                -0.01516739372164011,
                0.047506287693977356,
                -0.01258095819503069,
                0.027594290673732758,
                -0.05708960071206093,
                0.020830007269978523,
                -0.0349314883351326,
                -0.0041778311133384705,
                0.0036184368655085564,
                -0.018096555024385452,
                -0.020329684019088745,
                0.017045579850673676,
                0.005398617126047611,
                0.026505092158913612,
                -0.002547777257859707,
                0.03097790665924549,
                0.0043167187832295895,
                0.09024331718683243,
                -0.010001998394727707,
                -0.00432252325117588,
                0.011603238992393017,
                -0.005631972104310989,
                -0.020671190693974495,
                0.02951308712363243,
                0.06472813338041306,
                -0.005463019013404846,
                0.04563720151782036,
                -0.009643811732530594,
                -0.06421617418527603,
                0.03294874727725983,
                0.0075738392770290375,
                0.05432287976145744,
                0.06458047032356262,
                -0.024633420631289482,
                -0.04180772975087166,
                -0.09908565133810043,
                -0.017469340935349464,
                0.007942739874124527,
                0.05233721062541008,
                -0.026952769607305527,
                0.031525079160928726,
                0.014387144707143307,
                0.02329218201339245,
                0.030139192938804626,
                0.051891352981328964,
                0.060214344412088394,
                -0.036094535142183304,
                -0.0062032779678702354,
                0.03870505839586258,
                0.016362424939870834,
                -0.004636619705706835,
                -0.022693175822496414,
                -0.005176603328436613,
                -0.020189126953482628,
                1.3527310038943305e-34,
                -0.012983007356524467,
                -0.06621934473514557,
                -0.004657241981476545,
                0.04339743033051491,
                0.049671877175569534,
                -0.013110119849443436,
                0.018007660284638405,
                -0.01432633027434349,
                0.027006659656763077,
                0.01295783743262291,
                0.008708533830940723,
                0.03464648500084877,
                0.10280216485261917,
                -0.027116449549794197,
                -0.018160860985517502,
                -0.011486365459859371,
                0.007740922272205353,
                -0.04937805235385895,
                -0.011350985616445541,
                -0.04833165556192398,
                0.07011336833238602,
                0.0953591838479042,
                0.018229974433779716,
                0.009760045446455479,
                -0.0784233883023262,
                0.04274049028754234,
                -0.025059200823307037,
                -0.007037328090518713,
                0.0010914735030382872,
                -0.009597256779670715,
                0.00011967589671257883,
                -0.01787884347140789,
                -0.013796458952128887,
                -0.011478180065751076,
                -0.006860093213617802,
                -0.006939675658941269,
                -0.05340295657515526,
                -0.002712651388719678,
                -0.02978701703250408,
                -0.03142211213707924,
                -0.04924686625599861,
                0.0441218838095665,
                -0.0008569699712097645,
                0.028055349364876747,
                -0.036845866590738297,
                2.0207453417242505e-06,
                -0.031248662620782852,
                0.008122086524963379,
                -0.03528554365038872,
                -0.05074810981750488,
                0.02511081099510193,
                -0.004359900020062923,
                0.0005682505434378982,
                -0.026555776596069336,
                0.022018732503056526,
                -0.04480588808655739,
                0.028771519660949707,
                0.05157051980495453,
                -0.02210480161011219,
                -0.04389577358961105,
                0.007951023988425732,
                0.006341541185975075,
                -0.05367143452167511,
                -0.04318547993898392,
                -0.05490349978208542,
                0.003766030538827181,
                -0.021746819838881493,
                0.009740849025547504,
                -0.03612158074975014,
                0.00723020825535059,
                -0.01721644215285778,
                -0.03512176126241684,
                -0.021379394456744194,
                -0.031110204756259918,
                0.00894217099994421,
                -0.01247292198240757,
                -0.007768247742205858,
                0.06983707845211029,
                -0.020518915727734566,
                0.03506780043244362,
                -0.03185489773750305,
                0.0362345390021801,
                0.06059204041957855,
                0.012069033458828926,
                0.07784555107355118,
                -0.019629469141364098,
                -0.01937911845743656,
                -0.016402412205934525,
                -0.008524090051651001,
                0.0035425431560724974,
                -0.018427491188049316,
                0.026468247175216675,
                0.06500985473394394,
                -0.009238287806510925,
                0.0175453033298254,
                -0.010012727230787277,
                0.034971460700035095,
                0.0069680106826126575,
                -0.016141599044203758,
                0.010967429727315903,
                -0.0029002653900533915,
                0.021816415712237358,
                -0.08752837777137756,
                -0.0029961569234728813,
                0.02568231150507927,
                0.001478514983318746,
                -0.026087984442710876,
                0.027357017621397972,
                0.04290902242064476,
                0.0020618634298443794,
                -0.06130358949303627,
                -0.04190259799361229,
                0.018555518239736557,
                0.07151596993207932,
                0.0005399528308771551,
                0.012160916812717915,
                0.0605841726064682,
                -0.013237543404102325,
                -0.016478607431054115,
                -0.04893036559224129,
                0.05163116753101349,
                -0.018213089555501938,
                0.015339639969170094,
                -0.00541068846359849,
                0.012518929317593575,
                0.05365665629506111,
                -0.024456221610307693,
                -0.10011979937553406,
                -0.008190318942070007,
                -0.03524921461939812,
                0.02508583292365074,
                -0.099729984998703,
                0.00822193082422018,
                -0.031190447509288788,
                0.0209652129560709,
                -0.017560988664627075,
                0.06424353271722794,
                -0.031457819044589996,
                0.013458122499287128,
                0.03609512746334076,
                0.037401728332042694,
                -0.012034851126372814,
                -0.009421927854418755,
                -0.00999618973582983,
                -0.021976491436362267,
                0.038556069135665894,
                0.038179751485586166,
                -0.0035237285774201155,
                -0.012058348394930363,
                -0.0450344942510128,
                0.010051977820694447,
                -0.00997836608439684,
                -0.07351004332304001,
                -0.03461674600839615,
                0.023551438003778458,
                -0.010002773255109787,
                -0.0018080940935760736,
                0.02740032970905304,
                -0.2247895747423172,
                0.0007042448851279914,
                -0.03933221101760864,
                0.06275265663862228,
                0.06528741121292114,
                0.05447133257985115,
                0.005435140337795019,
                0.07099264860153198,
                0.06238573044538498,
                -0.02828420326113701,
                -0.007465028669685125,
                -0.01270297635346651,
                0.05191134288907051,
                -0.006465567275881767,
                0.0244889073073864,
                0.03999162092804909,
                -0.009745092131197453,
                0.009357310831546783,
                0.004795806482434273,
                -0.011879158206284046,
                0.024190109223127365,
                -0.05923129990696907,
                -0.03392811864614487,
                -0.030035067349672318,
                -0.007639193441718817,
                -0.018451781943440437,
                0.06879786401987076,
                -0.005030458327382803,
                0.06992539018392563,
                0.04463064670562744,
                -0.04683670029044151,
                -0.05972670763731003,
                0.004981734789907932,
                0.037238962948322296,
                -0.07994405925273895,
                0.009456108324229717,
                0.010556411929428577,
                0.03663990646600723,
                0.044286809861660004,
                0.03074588067829609,
                0.017913920804858208,
                -0.04137726500630379,
                -0.011714440770447254,
                0.0002921943669207394,
                0.014812852256000042,
                -0.03843274712562561,
                0.046187493950128555,
                0.004193793050944805,
                -0.007604014128446579,
                0.029704436659812927,
                0.012320592999458313,
                -0.015100938268005848,
                -0.0254815760999918,
                0.00414955522865057,
                -0.03995712101459503,
                0.10723971575498581,
                -0.015969641506671906,
                -0.047634560614824295,
                -0.0022605268750339746,
                -0.0076206219382584095,
                0.0009758941014297307,
                0.03901205584406853,
                0.00023265105846803635,
                -0.04408850148320198,
                0.007224126718938351,
                0.017119193449616432,
                0.033567242324352264,
                -0.006302737630903721,
                0.004299902357161045,
                0.0191759392619133,
                0.013656011782586575,
                -0.0205845944583416,
                0.03754621371626854,
                -0.029607949778437614,
                0.0005603591562248766,
                0.022298162803053856,
                0.004041983745992184,
                -0.009262507781386375,
                -0.05939049273729324,
                -0.00421189796179533,
                -0.017945662140846252,
                0.0073546296916902065,
                -0.015241137705743313,
                -0.009122150018811226,
                0.022500257939100266,
                -0.002453696448355913,
                0.0010980407241731882,
                0.03433357924222946,
                -0.006353101693093777,
                0.1487683355808258,
                0.05044509470462799,
                0.024290062487125397,
                -0.11795926094055176,
                -0.01156014483422041,
                -0.05026679486036301,
                0.03241826221346855,
                0.04038587585091591,
                0.025574341416358948,
                -0.004072221927344799,
                -0.000713056477252394,
                0.007936366833746433,
                -0.011336380615830421,
                0.00037365866592153907,
                -0.01170889101922512,
                0.00022782786982133985,
                0.04525033012032509,
                -0.017993159592151642,
                0.005211470182985067,
                0.0468968003988266,
                -0.001032504835166037,
                0.005855483002960682,
                -0.050922077149152756,
                0.018653441220521927,
                0.02695585787296295,
                -0.0029254257678985596,
                0.001375002320855856,
                -0.012300795875489712,
                0.04005526751279831,
                -0.04473249614238739,
                0.01887885294854641,
                0.015012980438768864,
                0.021587057039141655,
                0.014201042242348194,
                0.021198753267526627,
                -0.02971467562019825,
                0.0260632187128067,
                -0.01270980853587389,
                0.018726864829659462,
                0.022789448499679565,
                -0.07401753216981888,
                0.011349574662744999,
                -0.0251542367041111,
                -0.008701752871274948,
                0.04005550965666771,
                -0.002923917258158326,
                -0.0341472327709198,
                -0.0014873172622174025,
                0.04247696325182915,
                -0.00922788493335247,
                -0.03305971622467041,
                0.013908718712627888,
                0.0004154060152359307,
                0.0030941523145884275,
                -0.046271007508039474,
                -0.026686906814575195,
                -0.01169185247272253,
                -0.05927566811442375,
                0.041502635926008224,
                0.001755145494826138,
                -0.016693904995918274,
                -0.016219930723309517,
                0.007670146878808737,
                0.008932541124522686,
                -0.0038810952100902796,
                -0.0202584620565176,
                0.025083092972636223,
                -0.000613485521171242,
                -0.020471792668104172,
                0.002454290632158518,
                -0.01558978296816349,
                -0.041009970009326935,
                -0.01137531828135252,
                0.0211885217577219,
                -0.02716800384223461,
                0.015290001407265663,
                0.038865625858306885,
                0.07142006605863571,
                -0.020452752709388733,
                0.00825247447937727,
                -0.05600981414318085,
                0.005494920536875725,
                0.022984996438026428,
                0.10003727674484253,
                0.0061796423979103565,
                -0.0029719024896621704,
                -0.010927808471024036,
                -0.007322045508772135,
                0.021402765065431595,
                -0.0031674401834607124,
                -0.0009114501299336553,
                0.06250929832458496,
                0.05518021062016487,
                -0.007155335508286953,
                0.0029188271146267653,
                0.026895025745034218,
                0.004945298191159964,
                0.025265444070100784,
                -0.08124087750911713,
                0.04769264906644821,
                -0.03939608111977577,
                0.030904777348041534,
                -0.016002224758267403,
                0.03361539542675018,
                -0.023692432790994644,
                -0.031141066923737526,
                -0.05411199852824211,
                -0.012498884461820126,
                0.01143865380436182,
                -0.02428874745965004,
                -0.004161688033491373,
                0.007992546074092388,
                -0.05335581302642822,
                -0.01823245733976364,
                0.03837544098496437,
                0.021849744021892548,
                0.002652555238455534,
                -0.04314280301332474,
                -0.01505674421787262,
                -0.05310170352458954,
                -0.03503718227148056,
                0.052284177392721176,
                0.04944201931357384,
                -0.04987648129463196,
                -0.017154281958937645,
                0.03017956018447876,
                -0.013965881429612637,
                0.03167039901018143,
                0.025920022279024124,
                0.0345291942358017,
                0.04416035860776901,
                0.02140950597822666,
                -0.002459924900904298,
                0.005636019632220268,
                0.03762800246477127,
                0.07999293506145477,
                -0.050438832491636276,
                0.04076419398188591,
                -0.024708282202482224,
                0.06616193801164627,
                0.0007911989232525229,
                -0.07154124230146408,
                0.045356594026088715,
                -0.0004980512312613428,
                0.040773097425699234,
                -0.061181388795375824,
                0.02242753840982914,
                0.013271654956042767,
                0.035057030618190765,
                -0.003042082069441676,
                0.0013980732765048742,
                -0.005015973933041096,
                0.045781198889017105,
                0.028262142091989517,
                0.019378893077373505,
                -0.04937282204627991,
                -0.000134258036268875,
                0.04900413006544113,
                0.03637375310063362,
                -0.0020141545683145523,
                -0.023602763190865517,
                0.022380029782652855,
                -1.3527603186958004e-05,
                0.038251250982284546,
                0.029297294095158577,
                0.05773857235908508,
                -0.03535473719239235,
                0.030792688950896263,
                -0.030084650963544846,
                -0.049938324838876724,
                -0.054075099527835846,
                0.019664857536554337,
                0.018934791907668114,
                -0.10647270828485489,
                -0.03221847862005234,
                -0.051722776144742966,
                -0.0033565531484782696,
                -0.016703816130757332,
                0.008303642272949219,
                0.000868010800331831,
                -0.029740914702415466,
                -0.03478796035051346,
                0.010546808131039143,
                -0.04223014414310455,
                -0.016119297593832016,
                0.003869125386700034,
                0.015847811475396156,
                -0.010640584863722324,
                0.03860431909561157,
                -0.007604761514812708,
                -0.01788449101150036,
                0.033708639442920685,
                0.01701446808874607,
                -0.026324870064854622,
                -0.013163638301193714,
                -0.014866444282233715,
                -0.021052667871117592,
                0.0386924184858799,
                -0.006071769166737795,
                -0.0700935572385788,
                -0.026100032031536102,
                -0.012333004735410213,
                0.0506230890750885,
                -0.007345581892877817,
                -0.01159818097949028,
                0.035309530794620514,
                0.06256400793790817,
                0.0005636338028125465,
                -0.010291817598044872,
                0.0032276660203933716,
                0.009555979631841183,
                -0.04895521700382233,
                0.0019717782270163298,
                0.036439504474401474,
                0.013296124525368214,
                0.016324341297149658,
                0.018445031717419624,
                -0.03193271905183792,
                0.022378232330083847,
                -0.017853260040283203,
                -0.00792299211025238,
                -0.018166527152061462,
                0.014288302510976791,
                0.01487923227250576,
                0.042548295110464096,
                0.050249673426151276,
                -0.02709837071597576,
                -0.009382658638060093,
                8.619049367553089e-06,
                -0.0687524676322937,
                -0.02471870929002762,
                0.06877696514129639,
                0.01595286652445793,
                -0.015394424088299274,
                -0.03228916972875595,
                -0.022923534736037254,
                -0.02025848999619484,
                0.04609207436442375,
                0.018974395468831062,
                0.0057226261124014854,
                -0.05250335857272148,
                -0.02372438833117485,
                -0.016287073493003845,
                0.01032867282629013,
                -0.03542233631014824,
                0.013994051143527031,
                -0.03503357991576195,
                -0.06700911372900009,
                -0.020201047882437706,
                -0.02298739366233349,
                0.05343155562877655,
                -0.0058570061810314655,
                0.013356438837945461,
                -0.06749913841485977,
                0.029384681954979897,
                0.007161629851907492,
                -6.749331805622205e-05,
                -0.04326730966567993,
                0.0011063895653933287,
                -0.016739843413233757,
                -0.15192747116088867,
                0.012964547611773014,
                0.015217653475701809,
                -0.004372204188257456,
                0.012563705444335938,
                0.032157350331544876,
                0.019183170050382614,
                -0.0014212121022865176,
                -0.046314939856529236,
                0.025546979159116745,
                0.00792944710701704,
                0.03667530417442322,
                -0.00863727182149887,
                0.00042294891318306327,
                -5.1015933422604576e-05,
                -0.03478808328509331,
                -0.04719982296228409,
                -0.03880029544234276,
                -0.006124853156507015,
                0.016753237694501877,
                0.018803145736455917,
                -0.040569040924310684,
                -0.01957050897181034,
                -0.02212394028902054,
                0.022263478487730026,
                -0.011552691459655762,
                0.013280126266181469,
                0.0033029350452125072,
                0.09189426898956299,
                0.028657086193561554,
                0.026171604171395302,
                0.016950402408838272,
                -0.03665205463767052,
                0.015612835995852947,
                -0.07102485746145248,
                0.014315804466605186,
                -0.08104073256254196,
                0.013043458573520184,
                -0.03375256806612015,
                -0.02144952490925789,
                -0.08280450105667114,
                0.042661964893341064,
                -0.013831227086484432,
                -0.006525278091430664,
                -0.04113558679819107,
                -0.011709041893482208,
                -0.00346761429682374,
                0.03438510000705719,
                0.028732063248753548,
                -0.015688009560108185,
                -0.008772245608270168,
                0.005811050534248352,
                0.04627435654401779,
                -0.02064012922346592,
                -0.013635016046464443,
                -0.03011414222419262,
                -0.003210988361388445,
                -0.026640834286808968,
                0.01045400369912386,
                -8.264799394243745e-33,
                -0.022487634792923927,
                -0.0014227067586034536,
                0.030823882669210434,
                -0.019085939973592758,
                -0.09880296885967255,
                -0.008357004262506962,
                -0.0033709118142724037,
                0.07763294875621796,
                -0.034271616488695145,
                -0.017168929800391197,
                -0.02279924973845482,
                0.022123759612441063,
                0.03458888828754425,
                0.020490584895014763,
                0.05660274997353554,
                -0.005418002605438232,
                -0.013057984411716461,
                0.026509450748562813,
                -0.028593935072422028,
                -0.0497666597366333,
                -0.025190450251102448,
                0.012596034444868565,
                -0.01960790529847145,
                0.018507445231080055,
                0.014825367368757725,
                -0.036914121359586716,
                -0.04038490727543831,
                -0.012882058508694172,
                -0.029075562953948975,
                0.024779990315437317,
                -0.00761756906285882,
                -0.10801634937524796,
                -0.0030949118081480265,
                0.0012587319361045957,
                -0.010922722518444061,
                -0.010051852092146873,
                -0.025546079501509666,
                -0.02476949617266655,
                -0.004944663494825363,
                -0.011203301139175892,
                -0.0335325263440609,
                -0.044326141476631165,
                0.0193175021559,
                0.018788710236549377,
                -0.02184923365712166,
                -0.024228675290942192,
                -0.007303641643375158,
                0.017643727362155914,
                0.049176327884197235,
                0.043185196816921234,
                -0.003456621430814266,
                -0.0012000496499240398,
                -0.02567186951637268,
                -0.020149530842900276,
                0.02094019576907158,
                -0.0515434704720974,
                0.014394190162420273,
                -0.006993334274739027,
                -0.023547671735286713,
                -0.01741539128124714,
                -0.048271793872117996,
                0.05594627931714058,
                -0.03208727017045021,
                0.02995031140744686,
                -0.03485918045043945,
                -0.010969788767397404,
                -0.03900814801454544,
                -0.03899119421839714,
                0.02209075167775154,
                -0.0763273760676384,
                -0.017952460795640945,
                0.10031978785991669,
                0.02514008991420269,
                -0.01111518032848835,
                -0.03600531816482544,
                -0.05285215377807617,
                -0.040358465164899826,
                0.03006482496857643,
                0.04032164812088013,
                0.0019493865547701716,
                -0.014250076375901699,
                0.04264063760638237,
                0.012308163568377495,
                -0.008662058040499687,
                0.0001139577871072106,
                -0.01871653087437153,
                -0.007040899712592363,
                0.06794595718383789,
                0.00941434595733881,
                -0.0016090924618765712,
                -0.0034256260842084885,
                0.0009819722035899758,
                0.014995587058365345,
                0.06861498206853867,
                0.004018704406917095,
                0.018087519332766533,
                -0.017308635637164116,
                0.06499873846769333,
                0.03126665577292442,
                -0.05389118939638138,
                -0.0023424001410603523,
                0.005942190997302532,
                -0.0660163015127182,
                0.08139584958553314,
                0.02542056329548359,
                0.0017503153067082167,
                -0.030453085899353027,
                0.03830207511782646,
                -0.019314348697662354,
                -0.011301502585411072,
                -0.009605261497199535,
                0.0011796533362939954,
                -0.01306418888270855,
                -0.05393588915467262,
                0.016521276906132698,
                0.01008309330791235,
                0.011316433548927307,
                -0.06396359205245972,
                -0.01307976245880127,
                0.0147043876349926,
                0.026366861537098885,
                0.057869598269462585,
                -0.015036089345812798,
                0.018921971321105957,
                -0.030902929604053497,
                0.0028587605338543653,
                -0.011301328428089619,
                0.06298871338367462,
                -0.03176075965166092,
                -0.08089622110128403,
                -0.016140971332788467,
                -0.004373981151729822,
                2.7452523454485345e-07,
                -0.05700017139315605,
                -0.0006426239269785583,
                -0.04452734813094139,
                0.03467584773898125,
                0.040329087525606155,
                -0.01381857879459858,
                -0.06965156644582748,
                0.014444863423705101,
                0.009248732589185238,
                -0.004710900131613016,
                0.05640348792076111,
                0.001270949374884367,
                -0.03051610104739666,
                0.02284444496035576,
                0.0795602798461914,
                -0.054860517382621765,
                0.11955312639474869,
                0.018231619149446487,
                0.005910638254135847,
                -0.004600654821842909,
                0.023318687453866005,
                -0.005804901011288166,
                -0.0007159235537983477,
                -0.01563224382698536,
                -0.03006979450583458,
                -0.009948283433914185,
                0.014250918291509151,
                0.014843865297734737,
                0.06965626776218414,
                0.02594602294266224,
                0.10323283076286316,
                0.028916290029883385,
                -0.017929693683981895,
                -0.007836085744202137,
                -0.017870072275400162,
                -0.015123466029763222,
                0.04539107903838158,
                0.04031268134713173,
                0.02015669457614422,
                0.0995544046163559,
                -0.03811650350689888,
                0.02989976480603218,
                0.005909124854952097,
                0.0521836057305336,
                0.053558606654405594,
                0.04843142256140709,
                -0.006793476641178131,
                0.006834694184362888,
                -0.042854152619838715,
                -0.008752221241593361,
                0.022405950352549553,
                0.02508893609046936,
                0.0003309789171908051,
                0.0032211372163146734,
                0.017090970650315285,
                0.003765872912481427,
                0.002461356110870838,
                -0.006785750854760408,
                0.060751426964998245,
                0.03606462478637695,
                -0.010429183021187782,
                0.025071237236261368,
                0.0028113736771047115,
                0.005033299792557955,
                -0.014869059436023235,
                -0.015201283618807793,
                -0.042206279933452606,
                1.1159013790104054e-34,
                -0.012646394781768322,
                -0.00037843521567992866,
                -0.005337391048669815,
                -0.0034378294367343187,
                0.005482722073793411,
                -0.03595641255378723,
                0.0065585956908762455,
                -0.006036422681063414,
                0.052532654255628586,
                -0.03253507614135742,
                -0.010135754011571407,
                0.05863678827881813,
                0.0026286542415618896,
                -0.008179955184459686,
                0.016567930579185486,
                -0.014556493610143661,
                -0.0015214479062706232,
                -0.010860400274395943,
                -0.02670273557305336,
                -0.04309304431080818,
                0.04638608172535896,
                0.06179363653063774,
                0.011511296965181828,
                -0.006671619135886431,
                -0.02862454764544964,
                0.012697010301053524,
                -0.07207347452640533,
                0.011790954507887363,
                -0.014646097086369991,
                0.0016728203045204282,
                -0.01738809235394001,
                0.019381897523999214,
                -0.018768252804875374,
                0.011600708588957787,
                -0.004266032949090004,
                0.0019393663387745619,
                -0.04492532089352608,
                0.013374666683375835,
                -0.0012990839313715696,
                -0.024652903899550438,
                -0.040888674557209015,
                0.053040534257888794,
                0.04047694057226181,
                0.04257451370358467,
                -0.017375772818922997,
                2.005777560043498e-06,
                -0.03452291339635849,
                0.0036380616948008537,
                -0.027618790045380592,
                -0.0007822015322744846,
                0.01883871853351593,
                0.045180078595876694,
                -0.06874661892652512,
                -0.020598063245415688,
                0.012861009687185287,
                -0.029645616188645363,
                0.0447014719247818,
                0.019539015367627144,
                0.03125953674316406,
                0.04319947212934494,
                -0.010716424323618412,
                0.015102224424481392,
                -0.05466826632618904,
                -0.04697628691792488,
                -0.021578600630164146,
                -0.02933996170759201,
                0.03175538033246994,
                0.0030471920035779476,
                0.034886814653873444,
                0.003412739373743534,
                -0.0418437235057354,
                -0.04224340245127678,
                -0.05110327526926994,
                -0.043914105743169785,
                -0.00426672026515007,
                0.005373937077820301,
                8.270484977401793e-05,
                0.020720329135656357,
                -0.02432379312813282,
                0.05242263525724411,
                -0.025641383603215218,
                0.0007493832963518798,
                0.06661969423294067,
                0.02711164392530918,
                0.03220950812101364,
                -0.04531455412507057,
                -0.008328751660883427,
                -0.042961228638887405,
                -0.004645626060664654,
                0.004527540411800146,
                -0.012860399670898914,
                0.018980730324983597,
                0.05914526805281639,
                -0.015811605378985405,
                0.03468616306781769,
                0.040685880929231644,
                -0.018486158922314644,
                -0.006034089718014002,
                -0.0056125749833881855,
                0.01066209003329277,
                -0.00015219964552670717,
                0.027962421998381615,
                -0.08064170926809311,
                -0.030453408136963844,
                0.01716824248433113,
                0.02857847325503826,
                -0.040049683302640915,
                0.017870932817459106,
                -0.07328509539365768,
                0.004901697859168053,
                -0.019771860912442207,
                -0.06889421492815018,
                0.052779439836740494,
                0.05933109298348427,
                -0.0003802082210313529,
                0.0238436758518219,
                0.028874564915895462,
                -0.003153942758217454,
                0.06563001871109009,
                0.007438744883984327,
                0.06831365823745728,
                -0.04177248850464821,
                0.03892287611961365,
                0.005935564171522856,
                -0.026324959471821785,
                0.06799473613500595,
                -0.02477620728313923,
                -0.03606356680393219,
                0.007839517667889595,
                -0.06773599982261658,
                -0.0021477816626429558,
                -0.050011709332466125,
                0.009656401351094246,
                -0.01245125848799944,
                -0.014112712815403938,
                0.02945307455956936,
                0.03398948162794113,
                -0.05188104137778282,
                0.009662110358476639,
                0.014246952719986439,
                0.07109303772449493,
                -0.018865207210183144,
                -0.02061968483030796,
                -0.016251903027296066,
                -0.005605125334113836,
                0.04335467517375946,
                0.062310606241226196,
                0.02509179152548313,
                0.02248714119195938,
                -0.0969810038805008,
                -0.04297207295894623,
                -0.025607071816921234,
                -0.0630292147397995,
                0.005238509736955166,
                -0.0016331911319866776,
                -0.013177030719816685,
                -0.054627999663352966,
                0.021753860637545586,
                -0.11526328325271606,
                -0.01728276163339615,
                -0.044124748557806015,
                0.014246644452214241,
                0.04837550222873688,
                0.02882748283445835,
                -0.02031819522380829,
                0.09654196351766586,
                0.03642673045396805,
                0.0010969549184665084,
                -0.002147451974451542,
                0.013134453445672989,
                -0.012300174683332443,
                0.01073454786092043,
                0.014564372599124908,
                -0.021713584661483765,
                -0.020982732996344566,
                0.026466719806194305,
                0.02438178099691868,
                -0.03924942389130592,
                0.00011762980284402147,
                0.013901002705097198,
                -0.03223072364926338,
                0.012990334071218967,
                -0.018301881849765778,
                -0.02708958461880684,
                0.06862576305866241,
                0.01384046021848917,
                0.06125533953309059,
                -0.016516653820872307,
                -0.02309020608663559,
                -0.08910669386386871,
                -0.021682163700461388,
                -0.005604764446616173,
                -0.0544736348092556,
                0.00979747623205185,
                -0.005479205399751663,
                0.11083194613456726,
                0.04067077115178108,
                0.032805439084768295,
                0.004437882918864489,
                -0.0426751971244812,
                0.01237999927252531,
                0.018708711490035057,
                -0.004157037008553743,
                -0.011194996535778046,
                0.02842511050403118,
                0.017646128311753273,
                -0.0076655130833387375,
                0.05186748877167702,
                0.013018287718296051,
                0.03962389752268791,
                -0.012810011394321918,
                0.009875890798866749,
                -0.03278185427188873,
                0.09151013195514679,
                0.05273084715008736,
                -0.025876907631754875,
                0.01724875345826149,
                -0.011165974661707878,
                -0.024492958560585976,
                0.13010825216770172,
                0.0008531628991477191,
                -0.0729660615324974,
                0.020260417833924294,
                -0.029822813346982002,
                0.021505819633603096,
                -0.0052384925074875355,
                -0.02722381241619587,
                0.009020897559821606,
                -0.007008892484009266,
                -0.013538563624024391,
                -0.020047295838594437,
                -0.02543504349887371,
                0.12202392518520355,
                -0.047910939902067184,
                -0.003287672530859709,
                0.015403960831463337,
                -0.050662003457546234,
                0.0011624284088611603,
                -0.014982160180807114,
                0.029523830860853195,
                0.008996007032692432,
                -0.030273083597421646,
                0.00011000467202393338,
                -0.005298024043440819,
                0.010891186073422432,
                0.08505260199308395,
                -0.040093448013067245,
                0.08025199919939041,
                0.03801419213414192,
                0.02259701117873192,
                -0.08806463330984116,
                0.011910834349691868,
                -0.046084675937891006,
                0.014407387003302574,
                0.029197078198194504,
                0.021469315513968468,
                0.013720907270908356,
                0.05363684520125389,
                -0.011135534383356571,
                -0.028845202177762985,
                -0.02637813426554203,
                -0.023660773411393166,
                -0.008130041882395744,
                0.00834059901535511,
                -0.02779100462794304,
                0.00020251661771908402,
                -0.028922399505972862,
                -0.012867843732237816,
                0.021291736513376236,
                -0.018694164231419563,
                -0.015163663774728775,
                -0.027132663875818253,
                -0.022786103188991547,
                0.009950376115739346,
                0.0031159124337136745,
                0.07112850248813629,
                -0.039512135088443756,
                0.03234582021832466,
                0.05282244458794594,
                0.0075170439667999744,
                -0.01628127507865429,
                -0.016925787553191185,
                -0.021477486938238144,
                0.012119944207370281,
                -0.0038176823873072863,
                -0.0032577908132225275,
                0.04142050817608833,
                -0.06343401223421097,
                0.035548463463783264,
                -0.020455770194530487,
                -0.02089633233845234,
                0.046364545822143555,
                -0.007234519347548485,
                -0.019639838486909866,
                -0.004723415244370699,
                0.025075966492295265,
                0.032392602413892746,
                -0.016955824568867683,
                0.06576301902532578,
                -0.0013896586606279016,
                -0.009400373324751854,
                -0.05438603088259697,
                0.009512847289443016,
                -0.024774136021733284,
                -0.028517482802271843,
                0.039706818759441376,
                0.00726668257266283,
                0.023489490151405334,
                0.00025089544942602515,
                0.014013579115271568,
                -0.008983582258224487,
                0.05338291823863983,
                -0.004583386704325676,
                0.009667863138020039,
                -0.019400594756007195,
                -0.017001833766698837,
                -0.055544473230838776,
                0.001241068821400404,
                -0.026127254590392113,
                -0.038264866918325424,
                -0.009851005859673023,
                0.009785659611225128,
                0.021221471950411797,
                0.01795726642012596,
                0.04775967076420784,
                -0.001835313392803073,
                -0.0211303923279047,
                -0.02104194276034832,
                -0.0753575786948204,
                -0.0013326287735253572,
                0.043811049312353134,
                0.016224483028054237,
                0.00492200069129467,
                -0.03586309775710106,
                0.013766275718808174,
                0.020912250503897667,
                0.02917797863483429,
                0.01721944473683834,
                0.09497374296188354,
                0.06077957898378372,
                0.0037553063593804836,
                -0.01843683235347271,
                0.0370604544878006,
                0.02916744165122509,
                0.02774413861334324,
                -0.05335434526205063,
                0.08836758136749268,
                -0.01572195626795292,
                0.016529003158211708,
                -0.01751820743083954,
                0.02190382033586502,
                -0.010885266587138176,
                -0.025314508005976677,
                -0.045591454952955246,
                -0.0063467323780059814,
                -0.04404830187559128,
                -0.015049810521304607,
                -0.04365134984254837,
                0.024978548288345337,
                -0.006133394781500101,
                -0.027038490399718285,
                0.02792719006538391,
                -0.021736133843660355,
                -0.022100606933236122,
                -0.03742387890815735,
                0.02047204226255417,
                -0.056529413908720016,
                9.298842633143067e-05,
                0.02285885065793991,
                0.01831831969320774,
                -0.08309375494718552,
                -0.05044225603342056,
                0.028888599947094917,
                -0.011176100932061672,
                -0.0034748525358736515,
                0.0273736659437418,
                0.018775099888443947,
                0.058815453201532364,
                0.03243255987763405,
                -0.012789747677743435,
                0.03593939170241356,
                0.05106065794825554,
                0.04774384945631027,
                -0.059425823390483856,
                0.07261527329683304,
                -0.025580793619155884,
                0.04739587754011154,
                0.017858803272247314,
                -0.06984355300664902,
                0.03029714711010456,
                -0.022749319672584534,
                0.031073033809661865,
                -0.06605721265077591,
                -0.0037358608096837997,
                0.010758226737380028,
                0.04678533226251602,
                0.011516165919601917,
                -0.001204603468067944,
                -0.02517108805477619,
                0.03744078800082207,
                0.012582089751958847,
                0.014644336886703968,
                -0.03561605140566826,
                -0.03693469241261482,
                0.0603109709918499,
                0.005158466752618551,
                -0.0273938849568367,
                -0.013939203694462776,
                0.003923485055565834,
                0.03414716199040413,
                0.0018709469586610794,
                0.01816643588244915,
                0.07031461596488953,
                0.04289734736084938,
                0.05844304338097572,
                -0.06682512164115906,
                -0.021163254976272583,
                0.005358284339308739,
                -0.012663022615015507,
                0.034652359783649445,
                -0.12372926622629166,
                -0.037171412259340286,
                0.01329747959971428,
                -0.00943849515169859,
                -0.00017907170695252717,
                0.0379919558763504,
                -0.04398620128631592,
                -0.01510949619114399,
                -0.04361800104379654,
                0.04395680129528046,
                -0.019227195531129837,
                -0.02461506985127926,
                0.010945560410618782,
                0.05289734899997711,
                0.0067939735017716885,
                0.027064524590969086,
                -0.03698569908738136,
                0.019166452810168266,
                -0.0026898737996816635,
                0.019418079406023026,
                -0.006970307789742947,
                -0.01147223636507988,
                0.005268350709229708,
                -0.037975531071424484,
                0.00692534726113081,
                -0.02359401062130928,
                -0.046455565840005875,
                -0.07094208896160126,
                -0.008660966530442238,
                0.010739724151790142,
                0.0375325046479702,
                -0.022102152928709984,
                0.018165070563554764,
                0.020872745662927628,
                0.009076639078557491,
                -0.04213939979672432,
                -0.021390603855252266,
                0.030795155093073845,
                -0.02607070654630661,
                -0.03536425158381462,
                0.02787862718105316,
                0.0009452825761400163,
                0.004702550359070301,
                -0.005914297420531511,
                -0.08413770794868469,
                0.07991006970405579,
                0.0015759068774059415,
                -0.04073740914463997,
                -0.033451974391937256,
                0.03681332990527153,
                0.05677401274442673,
                0.04305020719766617,
                0.027498986572027206,
                -0.01618402637541294,
                0.036489129066467285,
                0.00930583942681551,
                -0.10591117292642593,
                -0.0022400880698114634,
                -0.035586778074502945,
                0.0006425731116905808,
                -0.002051888732239604,
                -0.04577202349901199,
                0.0033230483531951904,
                -0.0168869961053133,
                0.02437969669699669,
                0.03659941628575325,
                0.03457673639059067,
                -0.053890641778707504,
                -0.07346265017986298,
                -0.005735890008509159,
                -0.02730833925306797,
                -0.030423693358898163,
                0.016941970214247704,
                -0.05321671813726425,
                -0.08946815878152847,
                -0.030166899785399437,
                -0.045612405985593796,
                0.028460197150707245,
                -0.01604440249502659,
                0.031685881316661835,
                -0.044540535658597946,
                -0.008083166554570198,
                0.011493837460875511,
                0.001887576887384057,
                -0.036428652703762054,
                -0.020961584523320198,
                -0.023733582347631454,
                -0.16719657182693481,
                -0.0045816716738045216,
                -0.0032454845495522022,
                0.03124927543103695,
                -0.010851733386516571,
                0.036398809403181076,
                0.0032685769256204367,
                0.03563544154167175,
                -0.002220503054559231,
                -0.0068383654579520226,
                -0.02251839078962803,
                0.05783799663186073,
                0.02972518466413021,
                0.041034143418073654,
                -0.01035279594361782,
                -0.02568048983812332,
                -0.04040856286883354,
                0.006991139613091946,
                -0.014476954936981201,
                -0.015468142926692963,
                -0.03322311118245125,
                -0.042276494204998016,
                -0.016236497089266777,
                -0.0021207972895354033,
                -0.0023260493762791157,
                0.008175091817975044,
                0.03495286777615547,
                -0.014521307311952114,
                0.02464761957526207,
                0.04137801751494408,
                0.006091749761253595,
                -0.01085393875837326,
                -0.059705499559640884,
                -0.0006901524029672146,
                -0.0734466090798378,
                0.043629251420497894,
                0.009168264456093311,
                0.02703164704144001,
                -0.023723894730210304,
                -0.026601165533065796,
                -0.002158799674361944,
                0.07494601607322693,
                -0.003472342388704419,
                0.04354353994131088,
                -0.04313623532652855,
                0.01559953112155199,
                -0.016413439065217972,
                0.0087022315710783,
                0.015751374885439873,
                0.0048156436532735825,
                -0.020141461864113808,
                -0.018087182193994522,
                0.022225679829716682,
                -0.025753429159522057,
                -0.010407035239040852,
                -0.020932406187057495,
                0.015488138422369957,
                -0.04635859280824661,
                0.014328157529234886,
                -7.118912807382794e-33,
                -0.026502404361963272,
                -0.021473826840519905,
                0.025191284716129303,
                0.06417898088693619,
                -0.10207045078277588,
                -0.031632304191589355,
                -0.011683253571391106,
                0.027592850849032402,
                -0.05324997380375862,
                -0.02099200151860714,
                0.0032892876770347357,
                -0.008677184581756592,
                0.014058494940400124,
                0.0023773566354066133,
                0.08468552678823471,
                -0.025034099817276,
                -0.049487270414829254,
                0.006444962229579687,
                -0.00979899987578392,
                -0.039850201457738876,
                -0.005398234818130732,
                0.02503807656466961,
                -0.0006157155148684978,
                0.025204308331012726,
                0.029011856764554977,
                -0.003443819237872958,
                -0.028600161895155907,
                -0.023337777704000473,
                0.0052255201153457165,
                0.005329136271029711,
                -0.008460290729999542,
                -0.05583304539322853,
                -0.011631988920271397,
                -0.07505087554454803,
                -0.007946962490677834,
                0.029049977660179138,
                -0.018483616411685944,
                -0.019948527216911316,
                -0.004931674804538488,
                -0.01283436268568039,
                -0.04067147895693779,
                -0.025143928825855255,
                0.02835805155336857,
                0.029872557148337364,
                0.004507360514253378,
                -0.06675877422094345,
                0.00011351618741173297,
                0.02259623445570469,
                -0.018135059624910355,
                0.015233593061566353,
                -0.022999849170446396,
                -0.012570468708872795,
                -0.035963889211416245,
                -0.020417338237166405,
                0.00863879919052124,
                -0.06089583784341812,
                0.02450142614543438,
                0.041946157813072205,
                -0.05139625445008278,
                0.02097960188984871,
                0.01709797792136669,
                0.03445849567651749,
                -0.03776391223073006,
                0.03460759297013283,
                -0.010064358823001385,
                0.0046743182465434074,
                -0.09665436297655106,
                -0.012522107921540737,
                0.03122512437403202,
                -0.014179092831909657,
                0.028981639072299004,
                0.056239791214466095,
                0.012487409636378288,
                -0.05359876528382301,
                -0.09924135357141495,
                -0.07900424301624298,
                -0.05043281614780426,
                0.0012071103556081653,
                -0.00014265657227952033,
                0.005808527115732431,
                -0.03320373594760895,
                0.04157482087612152,
                0.014901283197104931,
                -0.00966444332152605,
                0.04467206448316574,
                0.03291182965040207,
                0.021881375461816788,
                0.07391061633825302,
                -0.016821930184960365,
                -0.0048781707882881165,
                -0.01561816968023777,
                -0.022007478401064873,
                0.002216976834461093,
                0.04994751513004303,
                0.034975565969944,
                0.0318930447101593,
                -0.015859005972743034,
                0.0030915681272745132,
                0.030590113252401352,
                -0.07877068966627121,
                -0.015035590156912804,
                -0.00628524599596858,
                -0.07473582774400711,
                0.03526168316602707,
                0.028364552184939384,
                0.006198849994689226,
                0.017878489568829536,
                0.015865352004766464,
                -0.022837944328784943,
                -0.028056874871253967,
                0.0030492336954921484,
                0.0390436016023159,
                0.0022135053295642138,
                -0.011625551618635654,
                -0.021572228521108627,
                0.004770494997501373,
                0.004569339565932751,
                -0.04241519048810005,
                0.023705219849944115,
                -0.03021545521914959,
                0.027114875614643097,
                0.11847033351659775,
                0.04514894634485245,
                -0.004515110980719328,
                -0.010151595808565617,
                0.01003496628254652,
                0.01564100943505764,
                0.0235157273709774,
                -0.005145194940268993,
                -0.05461724102497101,
                -0.01732880063354969,
                -0.032287318259477615,
                2.5323925001430325e-07,
                -0.07672669738531113,
                0.007405393291264772,
                -0.046268709003925323,
                0.021035436540842056,
                0.04398423433303833,
                0.0400884710252285,
                -0.035230204463005066,
                0.03722996264696121,
                -0.016281630843877792,
                0.033628515899181366,
                0.07478826493024826,
                -0.0028259516693651676,
                -0.011446265503764153,
                -0.013800114393234253,
                0.06728267669677734,
                -0.001233191811479628,
                -0.02101360820233822,
                -0.03721640259027481,
                0.011664996854960918,
                -0.038685932755470276,
                -0.01850515976548195,
                -0.012244890443980694,
                -0.009470262564718723,
                -0.008285085670650005,
                0.009558247402310371,
                -0.03018377535045147,
                0.01998864859342575,
                0.05601048097014427,
                0.07376907765865326,
                -0.018732979893684387,
                0.10882364958524704,
                0.03330240026116371,
                -0.03649128973484039,
                0.014920172281563282,
                -0.009581238962709904,
                -0.013696865178644657,
                -0.0037447623908519745,
                0.0639379620552063,
                0.01401336770504713,
                0.07388902455568314,
                -0.019419003278017044,
                0.03107425570487976,
                -0.021451707929372787,
                0.01748066395521164,
                0.03369415923953056,
                0.03891536965966225,
                -0.0012793369824066758,
                -0.004586759023368359,
                -0.0164224524050951,
                -0.029995065182447433,
                0.015156588517129421,
                0.027628367766737938,
                -0.013822016306221485,
                0.03140304610133171,
                0.00870866421610117,
                0.008662154898047447,
                0.0077183060348033905,
                0.01705257222056389,
                0.04081080108880997,
                0.03752327337861061,
                -0.02266361378133297,
                0.03956383839249611,
                -0.0022005203645676374,
                0.01619533635675907,
                -0.01730366237461567,
                0.022789575159549713,
                -0.02308720350265503,
                1.0206925354897908e-34,
                -0.012150418013334274,
                -0.008624639362096786,
                0.027828995138406754,
                -0.011516091413795948,
                -0.00158316595479846,
                -0.003844249527901411,
                0.037886738777160645,
                0.004015007987618446,
                0.02411017008125782,
                -0.016214657574892044,
                -0.023912612348794937,
                0.05221046134829521,
                0.026327423751354218,
                0.0024021612480282784,
                -0.004705697763711214,
                -0.011077526956796646,
                0.0027299935463815928,
                -0.03819569572806358,
                0.030546775087714195,
                -0.06682847440242767,
                0.02585902251303196,
                0.05979255586862564,
                0.004536303225904703,
                0.0041075339540839195,
                -0.02816084213554859,
                -0.03786462917923927,
                -0.07841608673334122,
                0.011238118633627892,
                0.04429127648472786,
                -0.016565997153520584,
                -0.003383405739441514,
                0.021299876272678375,
                0.002856482518836856,
                0.042241744697093964,
                -0.0474836640059948,
                -0.052621956914663315,
                0.0026608051266521215,
                0.023854495957493782,
                0.018493983894586563,
                -0.03521798551082611,
                -0.033042069524526596,
                -0.00901064369827509,
                0.030742136761546135,
                0.024519216269254684,
                0.0017014315817505121,
                1.8836487925000256e-06,
                -0.03664981946349144,
                -0.005155464634299278,
                0.017665045335888863,
                -0.04030968248844147,
                0.007720602210611105,
                0.023226596415042877,
                -0.05508341267704964,
                -0.011892758309841156,
                -0.0033427667804062366,
                -0.02687666565179825,
                -0.008303847163915634,
                0.01886630244553089,
                0.02058868110179901,
                0.009877322241663933,
                0.00424735713750124,
                0.004362463019788265,
                -0.04126478731632233,
                -0.07227910310029984,
                -0.029360463842749596,
                0.038589537143707275,
                0.003819272154942155,
                -0.013392623513936996,
                0.005656789988279343,
                -0.09139221161603928,
                -0.01851496659219265,
                -0.03762860968708992,
                0.0002554723178036511,
                0.00233894563280046,
                0.006050425115972757,
                0.08098456263542175,
                0.008771700784564018,
                0.09142480790615082,
                -0.0038168320897966623,
                0.03995058685541153,
                -0.008257197216153145,
                -0.04706390202045441,
                0.03848222643136978,
                0.0030830909963697195,
                0.0644894540309906,
                -0.07145330309867859,
                -0.02145695500075817,
                -0.05120875686407089,
                0.0020956192165613174,
                0.003957024775445461,
                -0.015608679503202438,
                -0.0775112509727478,
                0.04302973672747612,
                -0.010228995233774185,
                0.04419558495283127,
                0.01612584851682186,
                -0.0376131497323513,
                -0.02582603320479393,
                0.01936587505042553,
                -0.04697958007454872,
                0.00310563575476408,
                -0.033509790897369385,
                -0.033197302371263504,
                -0.0011092211352661252,
                0.04902896285057068,
                0.04583629220724106,
                -0.01710568182170391,
                0.03342035785317421,
                -0.01696094498038292,
                0.024103030562400818,
                -0.028555819764733315,
                -0.06979615986347198,
                0.058824360370635986,
                0.036781806498765945,
                0.03136734291911125,
                -0.014842106029391289,
                0.04252694547176361,
                0.013793647289276123,
                -0.04654645919799805,
                -0.009036497212946415,
                0.0052021583542227745,
                -0.04180455952882767,
                0.021808665245771408,
                0.00640721432864666,
                0.008578592911362648,
                0.03398316353559494,
                0.03706830367445946,
                -0.01665784791111946,
                -0.007045095320791006,
                -0.00416786503046751,
                0.015036068856716156,
                0.04318409785628319,
                -0.016914870589971542,
                -0.02928897738456726,
                -0.001566185150295496,
                0.043129485100507736,
                0.03446352109313011,
                -0.06642943620681763,
                0.0073861172422766685,
                0.019652919843792915,
                -0.04658190906047821,
                -0.02908948063850403,
                -0.037362176924943924,
                0.0038155768997967243,
                0.0369110144674778,
                0.03871658816933632,
                0.03807652369141579,
                0.004036434926092625,
                -0.0027241422794759274,
                -0.02976478822529316,
                0.031232938170433044,
                0.04786481335759163,
                -0.037532735615968704,
                -0.0052282861433923244,
                0.01306548435240984,
                0.032796550542116165,
                -0.018023468554019928,
                0.020556475967168808,
                0.015854069963097572,
                -0.013985592871904373,
                -0.029234744608402252,
                0.003255104646086693,
                0.04407062754034996,
                0.029536044225096703,
                0.000170734099810943,
                0.08297862857580185,
                0.016319692134857178,
                0.01602569967508316,
                -0.054999373853206635,
                0.010275579988956451,
                -0.049330249428749084,
                0.01191097404807806,
                0.015503956936299801,
                -0.021076561883091927,
                -0.028465108945965767,
                -0.003959146328270435,
                -0.009892217814922333,
                -0.04980582371354103,
                0.03515331447124481,
                -0.011851969175040722,
                -0.014228468760848045,
                0.002059757011011243,
                -0.019760189577937126,
                -0.0363653339445591,
                -0.007209048606455326,
                0.01699816808104515,
                0.015106895938515663,
                -0.0761813297867775,
                -0.032455794513225555,
                -0.05279812216758728,
                0.027921374887228012,
                0.016722893342375755,
                -0.14786778390407562,
                -0.011755493469536304,
                0.01981046423316002,
                0.11090489476919174,
                -7.800487219356e-05,
                -0.016352444887161255,
                0.0077844285406172276,
                -0.02149982936680317,
                0.00875985249876976,
                -0.0013778003631159663,
                -0.027211442589759827,
                0.03384155035018921,
                -0.0032537311781197786,
                0.02980366349220276,
                -0.01155606098473072,
                0.035661373287439346,
                -0.06651238352060318,
                0.03450290858745575,
                -0.061447110027074814,
                0.0008692123810760677,
                -0.03979095444083214,
                0.06942305713891983,
                0.027062857523560524,
                -0.006494111381471157,
                0.044041361659765244,
                0.06168132275342941,
                -0.02155085653066635,
                0.027773473411798477,
                -0.035561829805374146,
                -0.014667985029518604,
                0.006223924923688173,
                -0.003743209643289447,
                0.03400927409529686,
                0.005379092413932085,
                0.044410888105630875,
                0.028566628694534302,
                -0.02925989404320717,
                -0.027555007487535477,
                0.007723190821707249,
                -0.01975863426923752,
                0.017604492604732513,
                -0.002435752423480153,
                0.002479253802448511,
                0.05912488326430321,
                0.0007009356049820781,
                -0.012062042951583862,
                -0.006870884448289871,
                0.05618465691804886,
                -1.5256130609486718e-05,
                0.009159386157989502,
                0.040082454681396484,
                0.037326451390981674,
                0.00588490953668952,
                0.04793178290128708,
                -0.022088725119829178,
                0.053583428263664246,
                0.04790332168340683,
                -0.0010384004563093185,
                -0.09081395715475082,
                0.012752155773341656,
                -0.01198556274175644,
                -0.0049043078906834126,
                0.0375632643699646,
                -0.007913007400929928,
                0.053256455808877945,
                0.0485367625951767,
                -0.007519118953496218,
                0.008857714012265205,
                -0.016565734520554543,
                -0.019273217767477036,
                -0.012341627851128578,
                0.02127351053059101,
                -0.07523372024297714,
                0.0017150035127997398,
                -0.0202361810952425,
                -0.024016989395022392,
                0.05617707595229149,
                0.054521095007658005,
                -0.006956377066671848,
                0.02885494753718376,
                -0.004889354109764099,
                0.031185371801257133,
                -0.025938808917999268,
                0.08428453654050827,
                -0.016769006848335266,
                0.022813759744167328,
                -0.023639706894755363,
                0.00042748285341076553,
                -0.013478021137416363,
                -0.018015341833233833,
                0.011555887758731842,
                0.02136247791349888,
                -0.008420214988291264,
                -0.046384695917367935,
                0.011434641666710377,
                -0.07224766165018082,
                0.027491014450788498,
                -0.0449422188103199,
                -0.00376825500279665,
                -0.006446766667068005,
                -0.009502983652055264,
                -0.03927047550678253,
                -0.015181349590420723,
                0.044399116188287735,
                -0.001817434444092214,
                -0.03370349854230881,
                0.033801332116127014,
                0.03319110721349716,
                -0.05398282781243324,
                -0.07300592213869095,
                -0.007704162038862705,
                0.01574699580669403,
                -0.04117843136191368,
                0.04455970227718353,
                0.009614354930818081,
                -0.04114891588687897,
                -0.007364822085946798,
                0.03889431804418564,
                0.013316871598362923,
                -0.0009733986807987094,
                -0.0011634306283667684,
                0.010097846388816833,
                -0.025821996852755547,
                -0.04201175272464752,
                -0.04051249101758003,
                -0.007597332354635,
                0.034659236669540405,
                -0.013228422962129116,
                0.029948078095912933,
                0.000984493875876069,
                0.01967763528227806,
                -0.005854905117303133,
                0.03809984028339386,
                0.007352390326559544,
                -0.028340347111225128,
                0.006368954665958881,
                -0.01629980280995369,
                0.00960632972419262,
                0.024316973984241486,
                0.031760334968566895,
                0.020303599536418915,
                -0.02616390585899353,
                -0.015256469137966633,
                0.017381634563207626,
                0.012256412766873837,
                0.04308881238102913,
                0.06437008082866669,
                0.017843931913375854,
                0.017590925097465515,
                -0.008888947777450085,
                -0.034117333590984344,
                -0.04146598279476166,
                -0.01824873313307762,
                0.01867988333106041,
                0.06578221917152405,
                -0.04590032622218132,
                -0.06053424999117851,
                0.028099507093429565,
                0.00037972579593770206,
                -0.0002720182528719306,
                -0.0036236513406038284,
                -0.019635476171970367,
                0.020055929198861122,
                -0.04288218170404434,
                0.007664343807846308,
                -0.026312394067645073,
                0.012380518950521946,
                -0.05441652238368988,
                -0.008317569270730019,
                -0.006305971182882786,
                -0.05283987149596214,
                -0.012559371069073677,
                -0.017103515565395355,
                0.008600374683737755,
                -0.041999198496341705,
                -0.047208502888679504,
                0.050988733768463135,
                0.02841285429894924,
                -0.023954417556524277,
                -0.06508186459541321,
                0.03144873306155205,
                0.03923064470291138,
                -0.011604869738221169,
                0.06914237141609192,
                0.023916490375995636,
                0.07052312791347504,
                0.026997417211532593,
                -0.004580405540764332,
                0.036991462111473083,
                0.022275680676102638,
                0.04140331596136093,
                -0.007379652000963688,
                0.08268530666828156,
                0.01972845196723938,
                0.01822688616812229,
                -0.005800855811685324,
                -0.04359279200434685,
                0.028935139998793602,
                -0.012895404361188412,
                0.024048008024692535,
                -0.1196957379579544,
                -0.02501629665493965,
                0.020047666504979134,
                0.015637049451470375,
                0.005314674228429794,
                0.003595828078687191,
                -0.0035475504118949175,
                0.036514829844236374,
                -0.0134890740737319,
                0.0006754434434697032,
                -0.06730400025844574,
                -0.0441020131111145,
                0.017270555719733238,
                0.017020927742123604,
                -0.026198171079158783,
                0.03694020211696625,
                0.050007253885269165,
                0.04515794292092323,
                -0.010489266365766525,
                0.029303845018148422,
                0.09074676781892776,
                0.01284982543438673,
                0.049411240965127945,
                -0.04747934639453888,
                -0.04717090725898743,
                0.060008399188518524,
                0.05982367321848869,
                -0.010361618362367153,
                -0.06300144642591476,
                -0.13606439530849457,
                -0.001123691676184535,
                -0.02094905450940132,
                -0.015194359235465527,
                -0.012214326299726963,
                -0.07356680929660797,
                -0.012417519465088844,
                -0.06823963671922684,
                0.0014753974974155426,
                -0.0017141111893579364,
                0.020936662331223488,
                -0.020842785015702248,
                0.0010308569762855768,
                0.010531156323850155,
                -0.03435979038476944,
                -0.035056453198194504,
                -0.040094804018735886,
                -0.0074567352421581745,
                0.025160672143101692,
                0.020367149263620377,
                0.00048701715422794223,
                0.0055861384607851505,
                -0.02389388158917427,
                0.01858670264482498,
                0.057995982468128204,
                -0.0326051339507103,
                -0.06230649724602699,
                -0.007751434575766325,
                -0.01093321293592453,
                -0.015243833884596825,
                0.03149372711777687,
                0.03148229420185089,
                -0.014134225435554981,
                0.022143201902508736,
                -0.03435264527797699,
                -0.008317863568663597,
                -0.00884277280420065,
                -0.0523022897541523,
                -0.05425284802913666,
                0.023185785859823227,
                -0.0014151589712128043,
                0.026063671335577965,
                -0.010415105149149895,
                -0.04140587896108627,
                0.05897849053144455,
                0.012713231146335602,
                -0.019832104444503784,
                0.010344121605157852,
                0.03549300506711006,
                0.07908863574266434,
                0.048293087631464005,
                0.0326327383518219,
                -0.0004187690792605281,
                0.019435569643974304,
                -0.03455061838030815,
                -0.01702927239239216,
                0.007833653129637241,
                -0.05744452401995659,
                0.03813321888446808,
                -0.050079066306352615,
                -0.03579354286193848,
                -0.01720927283167839,
                0.020818646997213364,
                0.02664867602288723,
                0.03801451623439789,
                -0.01808011159300804,
                -0.06435255706310272,
                -0.006237552966922522,
                -0.0013888077810406685,
                -0.03392001613974571,
                -0.025156890973448753,
                0.022550033405423164,
                0.015908164903521538,
                0.03652114421129227,
                -0.0017767420504242182,
                -0.019562844187021255,
                0.013052762486040592,
                0.007350480183959007,
                0.0015432793879881501,
                -0.003182850545272231,
                -0.045604027807712555,
                -9.514363227935974e-06,
                -0.05342531204223633,
                -0.05339536815881729,
                -0.004583110101521015,
                -0.02236703224480152,
                -0.10922190546989441,
                -0.04751558601856232,
                0.021409306675195694,
                0.003612997243180871,
                -0.02599012851715088,
                0.006511090323328972,
                0.018573559820652008,
                -0.006884377915412188,
                0.02081618458032608,
                -0.00942584965378046,
                -0.01019554678350687,
                0.01663469523191452,
                0.02889072336256504,
                -0.018491476774215698,
                0.0035879414062947035,
                0.020128341391682625,
                -0.018645992502570152,
                -0.08539421856403351,
                -0.04256367310881615,
                -0.021667834371328354,
                -0.04317864030599594,
                0.007190064527094364,
                -0.0057844966650009155,
                0.01872285269200802,
                0.022124744951725006,
                0.0237555094063282,
                0.06866917014122009,
                0.006141364574432373,
                0.04862048849463463,
                0.03957200050354004,
                0.001071484643034637,
                -0.011072364635765553,
                -0.025251001119613647,
                0.00797446072101593,
                -0.08128828555345535,
                0.0029599054250866175,
                -0.05109528452157974,
                0.04822190850973129,
                -0.002392813330516219,
                0.08488981425762177,
                0.016838954761624336,
                0.08442135155200958,
                -0.02079407311975956,
                0.009591351263225079,
                -0.0032445306424051523,
                0.004156023729592562,
                0.021143591031432152,
                0.0014790467685088515,
                0.04739341512322426,
                0.003302398370578885,
                -0.002247564261779189,
                0.012546821497380733,
                0.02880074270069599,
                -0.01230052299797535,
                -0.004618164151906967,
                -0.07214056700468063,
                -0.07259775698184967,
                -0.0837128534913063,
                0.02902299351990223,
                -7.789741719458149e-33,
                -0.04514477029442787,
                0.009313788264989853,
                -0.01754577085375786,
                0.00405309209600091,
                -0.13146375119686127,
                -0.010426580905914307,
                0.018185239285230637,
                -0.00795748271048069,
                -0.03215648606419563,
                -0.05463658645749092,
                -0.006807355210185051,
                -0.014814884401857853,
                0.021699683740735054,
                -0.008678354322910309,
                0.0770447850227356,
                -0.07193798571825027,
                0.0043692514300346375,
                0.011513435281813145,
                -0.019665522500872612,
                0.018492594361305237,
                0.0201460849493742,
                0.04438365623354912,
                0.03851249814033508,
                0.030002230778336525,
                0.028000839054584503,
                -0.030152160674333572,
                -0.07311046868562698,
                0.020738886669278145,
                0.0382981076836586,
                0.00418599508702755,
                -0.01760273240506649,
                -0.008970246650278568,
                -0.007698622532188892,
                0.020155537873506546,
                -0.006373749580234289,
                0.05481364205479622,
                -0.01423583272844553,
                0.028145574033260345,
                -0.007213511038571596,
                0.010485226288437843,
                -0.021594304591417313,
                -0.014162967912852764,
                0.002105862367898226,
                0.013070269487798214,
                0.0037035245914012194,
                -0.04487455636262894,
                0.030739212408661842,
                0.056101903319358826,
                0.02222888544201851,
                0.03790782019495964,
                0.002939661266282201,
                -0.030443405732512474,
                -0.0615154393017292,
                -0.014154160395264626,
                0.0656207874417305,
                -0.019462062045931816,
                -0.012243988923728466,
                0.07934562116861343,
                -0.04932399466633797,
                0.0101193031296134,
                -0.05322721600532532,
                -0.009687114506959915,
                -0.00912109948694706,
                0.007647535298019648,
                0.06789250671863556,
                0.018656425178050995,
                -0.009452220983803272,
                -0.014023128896951675,
                0.05118571221828461,
                -0.018467843532562256,
                0.02668812684714794,
                0.05067676305770874,
                0.026912909001111984,
                -0.039298247545957565,
                -0.041274815797805786,
                -0.058447908610105515,
                -0.010368678718805313,
                -0.003289573360234499,
                0.05603422224521637,
                0.053802892565727234,
                -0.033808596432209015,
                0.04968833923339844,
                0.007473277393728495,
                0.01778455637395382,
                0.0016832638066262007,
                -0.05889235809445381,
                0.010026980191469193,
                0.00973119679838419,
                -0.005009402986615896,
                -0.037219420075416565,
                -0.018909621983766556,
                -0.019501816481351852,
                0.04051770642399788,
                0.017639223486185074,
                -0.045906879007816315,
                -0.013931344263255596,
                -0.001994563266634941,
                0.009931046515703201,
                0.024867981672286987,
                -0.03266086429357529,
                0.03836946189403534,
                0.045108597725629807,
                -0.019912147894501686,
                -0.040010809898376465,
                -0.013222558423876762,
                -0.00019655133655760437,
                -0.0016430284595116973,
                -0.0024890333879739046,
                -0.0192708820104599,
                -0.043479692190885544,
                -0.009050942026078701,
                -0.02041460946202278,
                -0.01663842424750328,
                -0.007915539667010307,
                -0.012574967928230762,
                0.025032108649611473,
                0.0012236962793394923,
                -0.044314220547676086,
                0.00921002496033907,
                -0.00541170546784997,
                0.01097957044839859,
                0.08314625173807144,
                0.020949579775333405,
                -0.02604854106903076,
                -0.07133052498102188,
                0.02223777025938034,
                0.005938486196100712,
                -0.027177885174751282,
                -0.01181319821625948,
                -0.02855217270553112,
                -0.024352990090847015,
                -0.03210299834609032,
                2.553424280904437e-07,
                -0.053344108164310455,
                0.04389064759016037,
                -0.010896717198193073,
                -0.004233473911881447,
                0.00038857394247315824,
                0.027072589844465256,
                -0.023365193977952003,
                0.061419978737831116,
                -0.011354418471455574,
                0.043690260499715805,
                0.054930679500103,
                -0.016936831176280975,
                0.005973162595182657,
                -0.011988739483058453,
                0.01845726929605007,
                -0.08560000360012054,
                -0.07442808896303177,
                -0.07474596053361893,
                0.03735840320587158,
                -0.052626218646764755,
                0.004259106703102589,
                -0.037472255527973175,
                -0.024017497897148132,
                0.005430215038359165,
                0.02800966054201126,
                0.12605738639831543,
                0.011013724841177464,
                -0.04921814054250717,
                0.04549529403448105,
                -0.02835785411298275,
                0.08796223998069763,
                -0.0101602328941226,
                0.0009695513872429729,
                0.01800679787993431,
                -0.010184573009610176,
                -0.01314660906791687,
                0.0707915723323822,
                0.03720121830701828,
                -0.0070253340527415276,
                0.06196899712085724,
                -0.004279224667698145,
                -0.0367310531437397,
                -0.004582974594086409,
                -0.036766402423381805,
                0.014525116421282291,
                0.07359273731708527,
                -0.04067102074623108,
                -0.020051296800374985,
                -0.07316865772008896,
                -0.029678883031010628,
                0.005220829509198666,
                0.015610403381288052,
                -0.020247716456651688,
                0.043518900871276855,
                0.00016792360111139715,
                0.0346980094909668,
                0.012659476138651371,
                0.03766939789056778,
                0.008154895156621933,
                -0.03198248893022537,
                -0.007299342658370733,
                0.03035883978009224,
                0.021055931225419044,
                0.005131402984261513,
                -0.012535826303064823,
                0.09288548678159714,
                -0.030207226052880287,
                5.805838919164502e-35,
                -0.011009953916072845,
                -0.06663527339696884,
                0.023853866383433342,
                0.06706058233976364,
                0.020078100264072418,
                -0.008442262187600136,
                0.0969083309173584,
                -0.002247493714094162,
                0.0206963699311018,
                0.011629863642156124,
                0.005360249895602465,
                0.032346732914447784,
                0.05078206956386566,
                0.004482433199882507,
                -0.023009581491351128,
                -0.03563617914915085,
                0.012547091580927372,
                -0.07693299651145935,
                0.0036865787114948034,
                -0.039650432765483856,
                0.05492686107754707,
                0.08461516350507736,
                -0.005762779153883457,
                0.019335046410560608,
                -0.025138070806860924,
                -0.01790878362953663,
                -0.010963523760437965,
                0.013394917361438274,
                0.02826094999909401,
                0.020877940580248833,
                -0.01728426292538643,
                0.02324337512254715,
                0.012267075479030609,
                0.04843487963080406,
                -0.04549172520637512,
                -0.034841809421777725,
                0.014798278920352459,
                0.03332650288939476,
                -0.029822004958987236,
                0.0075119552202522755,
                -0.01129759568721056,
                0.020556485280394554,
                0.007495393510907888,
                0.02986128069460392,
                -0.033253371715545654,
                2.061519580820459e-06,
                -0.05269810929894447,
                -0.03531865030527115,
                -0.00865985732525587,
                -0.02591007575392723,
                -0.0272436011582613,
                -0.02063765563070774,
                0.014520076103508472,
                0.002921357983723283,
                -0.00237577548250556,
                -0.019031954929232597,
                0.06459973007440567,
                0.032868869602680206,
                0.02722124382853508,
                0.02062157727777958,
                0.013679580762982368,
                -0.002104970859363675,
                0.006475919391959906,
                -0.023679660633206367,
                -0.02291768789291382,
                -0.0058393822982907295,
                -0.020193275064229965,
                -0.021438458934426308,
                -0.024577585980296135,
                -0.08300931006669998,
                -0.06453303247690201,
                0.0034376264084130526,
                -0.04363376647233963,
                -0.014753020368516445,
                -0.005194393917918205,
                0.06224904954433441,
                -0.012028512544929981,
                0.040590088814496994,
                -0.0071604992263019085,
                0.030970055609941483,
                -0.03689936175942421,
                -0.02527550235390663,
                0.08607068657875061,
                -0.0068634371273219585,
                0.09826606512069702,
                -0.05367578938603401,
                0.012014277279376984,
                -0.009702895767986774,
                -0.01438838616013527,
                0.0037953148130327463,
                -0.010137827135622501,
                -0.017286788672208786,
                0.04444598779082298,
                -0.0004709833301603794,
                0.033502619713544846,
                -0.029476119205355644,
                0.02549857273697853,
                0.004183270037174225,
                -0.0016634814674034715,
                0.0436953529715538,
                0.019682152196764946,
                0.00016234375652857125,
                -0.07117719948291779,
                -0.04437774792313576,
                0.004761572927236557,
                0.025495460256934166,
                -0.030384859070181847,
                0.003809317946434021,
                0.06714025884866714,
                -0.009473800659179688,
                -0.04225105792284012,
                -0.07919526100158691,
                0.03159545734524727,
                0.01659107767045498,
                0.005959277041256428,
                -0.01075736153870821,
                0.04201444610953331,
                0.0051796771585941315,
                0.007167596369981766,
                -0.02908742055296898,
                0.04111015051603317,
                -0.01738196797668934,
                0.011539245955646038,
                0.02057536318898201,
                0.038309499621391296,
                0.04195030406117439,
                -0.0017431762535125017,
                -0.02256854437291622,
                0.03107788972556591,
                -0.0448148250579834,
                0.06042437255382538,
                -0.016778727993369102,
                -0.016094157472252846,
                -0.045700009912252426,
                -0.02075902931392193,
                -0.009659703820943832,
                0.03258390724658966,
                -0.04756500571966171,
                0.010327513329684734,
                -0.0016001572366803885,
                0.01635153219103813,
                -0.012417885474860668,
                -0.039049334824085236,
                0.0004882977809756994,
                -0.021098019555211067,
                0.03668828308582306,
                0.058019328862428665,
                0.00622557895258069,
                -0.01167001947760582,
                -0.08215752989053726,
                0.04234853759407997,
                0.026016348972916603,
                -0.05326134338974953,
                -0.047107208520174026,
                -0.0010388529626652598,
                -0.01403725240379572,
                -0.026321541517972946,
                0.03834903985261917,
                -0.044960759580135345,
                0.0016465821536257863,
                -0.004922288935631514,
                0.033214468508958817,
                0.0076520489528775215,
                0.04266686365008354,
                0.004312653560191393,
                0.036042470484972,
                0.021021390333771706,
                0.01749941147863865,
                -0.05256280675530434,
                -0.02461159974336624,
                -0.006941074039787054,
                0.019231166690587997,
                -0.0017011718591675162,
                0.02010427415370941,
                -0.029973847791552544,
                -0.03420056775212288,
                0.03291962668299675,
                -0.00414445623755455,
                0.03651535138487816,
                -0.01957596279680729,
                -0.03515259549021721,
                -0.007939454168081284,
                -0.01757918857038021,
                -0.04665302857756615,
                -0.002607874572277069,
                0.02083330601453781,
                0.012951393611729145,
                0.0033250139094889164,
                -0.06123465672135353,
                -0.12378835678100586,
                0.014144926331937313,
                -0.04792235046625137,
                -0.08754455298185349,
                -0.017666175961494446,
                0.041770000010728836,
                0.10021301358938217,
                0.03133604675531387,
                -0.03130465745925903,
                0.03605261072516441,
                -0.0222054123878479,
                0.025799397379159927,
                -0.011273385025560856,
                -0.026553545147180557,
                -0.0398116260766983,
                0.011520866304636002,
                0.024815386161208153,
                0.009076314978301525,
                0.0467219352722168,
                -0.028419453650712967,
                0.004646727349609137,
                -0.035941995680332184,
                0.014295059256255627,
                -0.06607908010482788,
                0.1183769479393959,
                0.0610324926674366,
                -0.04819074645638466,
                0.04324264079332352,
                0.011427311226725578,
                -0.0017869953298941255,
                0.029584472998976707,
                -0.008940636180341244,
                0.02530718222260475,
                0.01660725101828575,
                -0.008960738778114319,
                0.00035227651824243367,
                -0.010670572519302368,
                0.03980562463402748,
                0.00554289435967803,
                0.0468907468020916,
                -0.05604017153382301,
                0.011682827956974506,
                -0.04277542605996132,
                0.013670671731233597,
                0.009832005016505718,
                -0.003362939925864339,
                0.02977246604859829,
                0.0024155068676918745,
                -0.01722714491188526,
                0.0007371493265964091,
                0.020312301814556122,
                -0.01258515939116478,
                -0.012760782614350319,
                0.024106411263346672,
                0.03082416206598282,
                0.014818486757576466,
                0.07464586943387985,
                0.030991515144705772,
                0.05940802022814751,
                0.04380795732140541,
                -0.0009131967090070248,
                -0.08580567687749863,
                0.020288459956645966,
                0.034680113196372986,
                0.048798464238643646,
                0.02070355974137783,
                -0.0005770737188868225,
                0.009317542426288128,
                0.055980339646339417,
                0.0004921650979667902,
                -0.022492967545986176,
                -0.02221797965466976,
                -0.0037407714407891035,
                -0.01577656902372837,
                0.03369923308491707,
                -0.06664600968360901,
                0.0063713230192661285,
                -0.0699014961719513,
                -0.02175285294651985,
                0.04332444816827774,
                -0.03481241315603256,
                0.02926241233944893,
                -0.009247997775673866,
                -0.013314064592123032,
                -0.0020402793306857347,
                -0.012547326274216175,
                0.07511047273874283,
                -0.022615918889641762,
                -0.004088531248271465,
                0.08279605954885483,
                0.012682133354246616,
                0.0016014613211154938,
                0.015573094598948956,
                -0.026880593970417976,
                -0.007131850812584162,
                -0.00735447509214282,
                0.005353725980967283,
                -0.006355712190270424,
                -0.05379151925444603,
                0.044912781566381454,
                -0.034794632345438004,
                -0.01263878121972084,
                0.021706756204366684,
                0.009305155836045742,
                -0.033423323184251785,
                -0.025317125022411346,
                0.033761776983737946,
                0.00011295003059785813,
                -0.002473467029631138,
                0.05428453907370567,
                -0.012016501277685165,
                -0.08981753885746002,
                -0.04448677599430084,
                -0.0012246078113093972,
                0.03226269781589508,
                -0.03554752841591835,
                0.03631552681326866,
                -0.011831280775368214,
                -0.02205192856490612,
                -0.012857087887823582,
                0.01089266687631607,
                0.0036300504580140114,
                -0.02001921460032463,
                0.006617232691496611,
                0.018360327929258347,
                -0.01880192942917347,
                -0.05263712257146835,
                -0.048515673726797104,
                0.008679930120706558,
                0.003924489486962557,
                -3.1570481951348484e-05,
                0.04524814710021019,
                0.015311465598642826,
                0.025759058073163033,
                0.019416894763708115,
                0.04350109398365021,
                0.006976657547056675,
                0.007574430201202631,
                0.041540127247571945,
                -0.03294200822710991,
                0.021507367491722107,
                0.07287521660327911,
                0.04115601256489754,
                -0.021249359473586082,
                -0.014354255050420761,
                -0.008460805751383305,
                0.056622132658958435,
                0.0006176561000756919,
                -0.006474426481872797,
                0.044933170080184937,
                0.06004462018609047,
                0.025792406871914864,
                -0.021711954846978188,
                0.02740011177957058,
                0.008472690358757973,
                -0.008882448077201843,
                -0.0436275452375412,
                0.032008424401283264,
                -0.019954053685069084,
                -0.030559837818145752,
                0.026916703209280968,
                -0.0014544890727847815,
                0.005511700175702572,
                -0.005575739312916994,
                -0.04126149043440819,
                0.020220458507537842,
                -0.040161892771720886,
                -0.015498763881623745,
                -0.04871796816587448,
                -0.0077715106308460236,
                -0.023021802306175232,
                0.007705274038016796,
                0.036338720470666885,
                -0.06916765123605728,
                -0.03129708021879196,
                -0.013792627491056919,
                0.017544610425829887,
                -0.0017763500800356269,
                -0.005856739357113838,
                0.03292272239923477,
                0.05236155167222023,
                -0.04038550332188606,
                -0.09363286197185516,
                0.0045752935111522675,
                0.027844132855534554,
                0.02406739443540573,
                0.03700396418571472,
                0.014265146106481552,
                0.07424288243055344,
                0.0424174889922142,
                -0.02861502766609192,
                -0.006846340838819742,
                0.0007969369762577116,
                0.07934258133172989,
                0.0040152170695364475,
                0.034127749502658844,
                -0.02034708485007286,
                0.05466544255614281,
                0.0026403290685266256,
                -0.076437808573246,
                0.04760779067873955,
                -0.026204463094472885,
                0.02612975426018238,
                -0.057352323085069656,
                0.005707914009690285,
                0.019003143534064293,
                0.005927533842623234,
                0.01575145311653614,
                -0.030414747074246407,
                -0.017600437626242638,
                0.0180783960968256,
                -0.02461315132677555,
                0.023180857300758362,
                -0.013938238844275475,
                -0.08764228969812393,
                0.03148498013615608,
                0.003334313165396452,
                -0.020785758271813393,
                0.00830826722085476,
                0.060098811984062195,
                0.022617608308792114,
                -0.010170703753829002,
                0.022873450070619583,
                0.013721497729420662,
                0.021005606278777122,
                0.07243261486291885,
                -0.05782880261540413,
                -0.0585513599216938,
                0.07532614469528198,
                0.04645039141178131,
                -0.021527180448174477,
                -0.09221861511468887,
                -0.0787520557641983,
                0.0035575355868786573,
                -0.03041202388703823,
                0.02074592001736164,
                0.04759488254785538,
                -0.04316253215074539,
                -0.011296161450445652,
                -0.028613662347197533,
                -0.003617923241108656,
                0.026087023317813873,
                0.05168914422392845,
                -0.0015811285702511668,
                -0.0135115347802639,
                0.04182488098740578,
                -0.011960821226239204,
                -0.025002487003803253,
                -0.025079740211367607,
                0.0026061651296913624,
                0.04354603588581085,
                -0.04269054904580116,
                -0.02576724998652935,
                0.006957874167710543,
                -0.04682591184973717,
                0.01647195778787136,
                -0.058983199298381805,
                -0.07090578228235245,
                -0.008069820702075958,
                -0.03218618407845497,
                0.009991414844989777,
                -0.04248825088143349,
                0.010146689601242542,
                0.026808995753526688,
                -0.00435189763084054,
                0.024799523875117302,
                -0.05217809975147247,
                -0.06722591817378998,
                -0.009217475540935993,
                0.0030369616579264402,
                -0.04760381579399109,
                0.00883779488503933,
                0.01786207966506481,
                -0.013394225388765335,
                0.0061092679388821125,
                -0.08176840841770172,
                0.03241918981075287,
                0.017912236973643303,
                -0.050477560609579086,
                0.025081317871809006,
                0.05539468303322792,
                0.02604866772890091,
                0.05248000845313072,
                0.03801615536212921,
                0.01718785986304283,
                0.005482510197907686,
                -0.01743018627166748,
                -0.07352553308010101,
                -0.03221537545323372,
                -0.06599842756986618,
                0.020177699625492096,
                -0.01158860232681036,
                -0.01273338496685028,
                -0.025353020057082176,
                0.007603257894515991,
                0.010008493438363075,
                0.0393497608602047,
                0.03428827226161957,
                -0.08716823905706406,
                0.03828565776348114,
                0.055920206010341644,
                0.016070229932665825,
                -0.008967491798102856,
                0.004530261270701885,
                -0.011798127554357052,
                0.06410656124353409,
                -0.0046561830677092075,
                -0.041771337389945984,
                0.05269486457109451,
                0.0052763051353394985,
                -0.018286461010575294,
                -0.023525763303041458,
                -0.0030345353297889233,
                0.028992190957069397,
                -0.020188502967357635,
                -0.14773334562778473,
                -0.011095280759036541,
                -0.007238779217004776,
                -0.09535403549671173,
                -0.02928236871957779,
                0.0766596570611,
                0.00826361682265997,
                0.0052253673784434795,
                0.0289215799421072,
                0.008289464749395847,
                -0.01551420334726572,
                0.004849011078476906,
                0.0018808593740686774,
                -0.019609758630394936,
                -0.017884328961372375,
                0.01919034868478775,
                -0.040425095707178116,
                -0.00800118874758482,
                -0.005938772112131119,
                -0.007258615922182798,
                -0.04552651569247246,
                -0.02764039672911167,
                -0.025932112708687782,
                -0.05572686716914177,
                -0.06380946934223175,
                2.1823854694957845e-05,
                -0.0012947325594723225,
                -0.0015196057502180338,
                -0.014169078320264816,
                0.020019836723804474,
                0.012317478656768799,
                0.042760368436574936,
                -0.016776567324995995,
                0.011122031137347221,
                -0.022558078169822693,
                0.001998312771320343,
                0.011408457532525063,
                -0.041205570101737976,
                0.009185023605823517,
                -0.09386172890663147,
                0.03525104373693466,
                -0.03767987713217735,
                0.05611526966094971,
                -0.007991288788616657,
                0.020629335194826126,
                -0.02160760574042797,
                -0.022167617455124855,
                -0.0938621461391449,
                0.011494199745357037,
                0.05372706428170204,
                -0.0065833828411996365,
                0.04438517987728119,
                -0.006492923945188522,
                -0.010031410492956638,
                0.01287523191422224,
                0.03349534422159195,
                0.05168067663908005,
                0.013100814074277878,
                -0.04901285469532013,
                -0.04249057173728943,
                -0.044950857758522034,
                0.06507030129432678,
                -7.786651638683425e-33,
                -0.0016277977265417576,
                0.02059001289308071,
                0.0066289943642914295,
                0.01905694790184498,
                -0.0851101204752922,
                0.024353040382266045,
                0.02203833870589733,
                0.02081303298473358,
                -0.020711639896035194,
                -0.07662045955657959,
                -0.030546540394425392,
                -0.03194459155201912,
                0.014498092234134674,
                0.020354708656668663,
                0.048408761620521545,
                -0.01705649308860302,
                -0.05818123370409012,
                -0.0012631164863705635,
                -0.013480723835527897,
                -0.02627624198794365,
                0.010918792337179184,
                0.021544544026255608,
                0.012054032646119595,
                0.025930266827344894,
                0.013241446577012539,
                -0.001867197803221643,
                -0.06418944895267487,
                -0.007918475195765495,
                0.007347981445491314,
                0.013482462614774704,
                -0.015240008942782879,
                -0.04310708865523338,
                -0.011194595135748386,
                -0.038388755172491074,
                -0.0015986324287950993,
                0.04645035043358803,
                -0.020538773387670517,
                0.0050717247650027275,
                -0.042622487992048264,
                -0.018570004031062126,
                -0.046292319893836975,
                -0.015303391963243484,
                -0.02639579586684704,
                -0.00542486784979701,
                0.019972769543528557,
                -0.03668695315718651,
                0.024426154792308807,
                0.023682374507188797,
                -0.014559006318449974,
                0.05281759798526764,
                -0.009059199132025242,
                0.014047822915017605,
                -0.05190886929631233,
                -0.00586106488481164,
                3.2829197152750567e-05,
                0.026882192119956017,
                -0.0024963081814348698,
                0.03162005543708801,
                -0.017700564116239548,
                0.022869577631354332,
                -0.03302159532904625,
                0.023570731282234192,
                -0.026220547035336494,
                0.006651288364082575,
                0.026468293741345406,
                0.026710214093327522,
                -0.07851510494947433,
                -0.0008280756301246583,
                0.03811667114496231,
                0.07059278339147568,
                -0.017014512792229652,
                0.07471165060997009,
                0.05632802098989487,
                -0.01609848439693451,
                -0.11285270750522614,
                -0.07146117091178894,
                -0.03795924410223961,
                0.016292694956064224,
                0.07363712042570114,
                -0.007727521006017923,
                -0.014941375702619553,
                0.062095221132040024,
                0.026631098240613937,
                9.558958117850125e-05,
                -0.00554315373301506,
                -0.04201144725084305,
                -0.0054936520755290985,
                0.02029825933277607,
                -0.006523876450955868,
                -0.034774769097566605,
                -0.033910028636455536,
                -0.04404212906956673,
                0.06319010257720947,
                0.04109575226902962,
                -0.04182831570506096,
                0.008137824013829231,
                -0.02644096501171589,
                -0.005177232436835766,
                0.02648010291159153,
                -0.015526464208960533,
                0.028456013649702072,
                0.034545935690402985,
                -0.04665134847164154,
                0.02091313898563385,
                0.003249751403927803,
                -0.0006053259130567312,
                0.0459764264523983,
                0.006352987606078386,
                -0.043193232268095016,
                -0.026853039860725403,
                -0.0035453219898045063,
                -0.0005495314835570753,
                0.009112609550356865,
                -0.04716690629720688,
                -0.005551816895604134,
                -0.009453185833990574,
                0.002789880381897092,
                -0.03913470730185509,
                0.022596651688218117,
                -0.03229422867298126,
                0.01268896646797657,
                0.08823543041944504,
                -0.0202171728014946,
                -0.021583842113614082,
                -0.05335378274321556,
                0.04374542087316513,
                -0.03727903962135315,
                0.044207196682691574,
                -0.03441137075424194,
                -0.023737957701086998,
                -0.0213224645704031,
                -0.04661894962191582,
                2.6637621886038687e-07,
                -0.09839028120040894,
                0.016124490648508072,
                -0.016818201169371605,
                -0.057355914264917374,
                0.013567403890192509,
                0.03763951361179352,
                -0.021013343706727028,
                0.040742289274930954,
                -0.009251151233911514,
                0.05010330304503441,
                0.04980640485882759,
                -0.03682703897356987,
                -0.006227004341781139,
                0.006428833119571209,
                0.06414376944303513,
                0.014878111891448498,
                -0.026614433154463768,
                -0.013542723841965199,
                0.06232566758990288,
                -0.03989525884389877,
                0.013120431452989578,
                0.0283871591091156,
                0.014299518428742886,
                0.011569018475711346,
                0.0070076584815979,
                0.050348155200481415,
                0.006630481220781803,
                -0.03281563147902489,
                0.03182878717780113,
                0.0007502465741708875,
                0.01924499310553074,
                -0.0033734124153852463,
                -0.030498307198286057,
                -0.007628678344190121,
                -0.016383400186896324,
                -0.009350576438009739,
                0.044632840901613235,
                0.0217681135982275,
                0.008099337108433247,
                0.037358421832323074,
                0.02426040731370449,
                -0.01901843212544918,
                0.010420968756079674,
                0.028762253001332283,
                0.024440186098217964,
                0.011958939954638481,
                -0.015223401598632336,
                -0.035888928920030594,
                -0.06417530030012131,
                -0.024875519797205925,
                -0.0006889262585900724,
                0.062142547219991684,
                -0.00822786521166563,
                0.07532745599746704,
                -0.005351722706109285,
                0.04437847435474396,
                0.01582866534590721,
                0.05534335598349571,
                0.03442457690834999,
                -0.018040966242551804,
                0.0023567716125398874,
                0.042554449290037155,
                0.015583663247525692,
                0.09929808229207993,
                -0.020181890577077866,
                0.07552710920572281,
                -0.04049870744347572,
                9.752010189073385e-35,
                -0.058967191725969315,
                -0.06040770560503006,
                -0.00620122067630291,
                0.032870836555957794,
                0.057835038751363754,
                -0.004293443635106087,
                0.017166942358016968,
                0.0001367280347039923,
                0.046994782984256744,
                0.009303843602538109,
                -0.01885680854320526
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\x83uK\\x80\\xd0M\\xce\\x10hO\\x85\\xf4\\xf7\\xe9qL'",
        "id__": "6080dcc6-2c67-421e-aa2e-b8661440c48d"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "University (Undergrad)",
            "rep": [
                0.01233732607215643,
                0.03603412210941315,
                0.012868590652942657,
                -0.04449424147605896,
                0.029674874618649483,
                0.005182517226785421,
                0.06764349341392517,
                -0.02288988046348095,
                0.013873476535081863,
                0.016563668847084045,
                0.0819014385342598,
                -0.026421047747135162,
                -0.012049267068505287,
                0.028352653607726097,
                0.010913267731666565,
                0.02803477644920349,
                0.026808729395270348,
                -0.028586968779563904,
                -0.04251005873084068,
                -0.048871226608753204,
                -0.06824077665805817,
                -0.0001444692607037723,
                -0.03813301771879196,
                0.0020817245822399855,
                0.012241610325872898,
                -0.07627362012863159,
                -0.0025690291076898575,
                0.0019969758577644825,
                -0.024308718740940094,
                0.0784284695982933,
                0.07415196299552917,
                -0.010644753463566303,
                0.00912720337510109,
                0.025821994990110397,
                1.5092263083715807e-06,
                -0.03602069988846779,
                -0.031337834894657135,
                -0.018967419862747192,
                -0.012397980317473412,
                -0.07041704654693604,
                0.08441156148910522,
                0.0946507528424263,
                0.006672827526926994,
                0.03793242201209068,
                -0.03101535327732563,
                0.024706117808818817,
                0.0387137308716774,
                -0.030370492488145828,
                -0.024782661348581314,
                -0.016705719754099846,
                -0.02316739782691002,
                -0.1410304605960846,
                -0.08130066841840744,
                -0.0376008078455925,
                -0.09054876118898392,
                -0.015296024270355701,
                -0.002877688966691494,
                -0.006442563142627478,
                0.02026546187698841,
                0.053710758686065674,
                0.0076347640715539455,
                -0.03959354758262634,
                -0.011954756453633308,
                -0.010334479622542858,
                0.07666019350290298,
                0.035515446215867996,
                0.015631893649697304,
                -0.021131303161382675,
                -0.024050643667578697,
                -0.015414822846651077,
                -0.03660842403769493,
                0.004094778560101986,
                -0.02631225436925888,
                0.056502774357795715,
                0.0006281815585680306,
                0.03375512734055519,
                -0.006976712960749865,
                -0.051952045410871506,
                -0.013877925463020802,
                0.025569789111614227,
                -0.03779049962759018,
                0.007216353435069323,
                -0.017174750566482544,
                0.08343689888715744,
                -0.014817697927355766,
                0.02892795391380787,
                0.02454359270632267,
                0.028013616800308228,
                0.030937258154153824,
                -0.028459521010518074,
                -0.03721344843506813,
                -0.01695622317492962,
                0.05387435108423233,
                0.04960818216204643,
                -0.021720804274082184,
                -0.07941624522209167,
                0.03594275191426277,
                0.019043389707803726,
                0.014490901492536068,
                0.04832945391535759,
                -0.01619386114180088,
                -0.022437451407313347,
                -0.023497741669416428,
                0.0381142757833004,
                -0.00023704855993855745,
                -0.002752656117081642,
                0.0307293813675642,
                -0.015034526586532593,
                -0.005264253821223974,
                0.024921171367168427,
                -0.05493581295013428,
                0.04110613465309143,
                -0.02603091485798359,
                0.038290124386548996,
                0.017413994297385216,
                0.023176901042461395,
                -0.016042353585362434,
                -0.020917072892189026,
                0.03891024366021156,
                0.015452893450856209,
                -0.03751992806792259,
                -0.011881738901138306,
                -0.05691835656762123,
                0.01370942685753107,
                0.007725783158093691,
                0.06348158419132233,
                -0.029083024710416794,
                0.04021114110946655,
                0.033894192427396774,
                0.07008849829435349,
                0.01395571418106556,
                -0.012163887731730938,
                -0.009954463690519333,
                -0.034025538712739944,
                0.01556578278541565,
                -0.009367832913994789,
                -0.03272673487663269,
                0.061702754348516464,
                -0.0173949021846056,
                -0.06117415428161621,
                0.008516802452504635,
                -0.04732450842857361,
                0.012535886839032173,
                0.003718319348990917,
                -0.02831197716295719,
                0.020402152091264725,
                0.005209966097027063,
                -0.022976335138082504,
                -0.0010693279327824712,
                -0.01502780057489872,
                0.02059303969144821,
                -0.02744540572166443,
                0.04938146471977234,
                -0.03997952863574028,
                0.029329823330044746,
                0.05019821971654892,
                0.028229795396327972,
                -0.055067893117666245,
                0.0032310113310813904,
                0.08815409243106842,
                -0.05997666344046593,
                -0.022351142019033432,
                0.0200094822794199,
                -0.0028053014539182186,
                0.010150706395506859,
                -0.04938466474413872,
                0.05269073322415352,
                -0.004646402318030596,
                -0.009414603002369404,
                0.030215468257665634,
                -0.04085014387965202,
                -0.010520199313759804,
                -0.018402280285954475,
                0.06972286850214005,
                0.02002406120300293,
                0.007834932766854763,
                0.03429557755589485,
                0.004851205740123987,
                -0.09058954566717148,
                0.009962453506886959,
                -0.001787327928468585,
                -0.12857012450695038,
                0.0075182123109698296,
                0.06004926934838295,
                0.022216415032744408,
                0.015039353631436825,
                -0.018331710249185562,
                0.02219199948012829,
                -0.013979511335492134,
                -0.005487461108714342,
                -0.01574065536260605,
                -0.00510205514729023,
                -0.0773552656173706,
                -0.005867646541446447,
                -0.026914894580841064,
                -0.06877582520246506,
                -0.01593245007097721,
                -0.007043543271720409,
                0.0032167863100767136,
                -0.03160906210541725,
                -0.020666837692260742,
                0.040923312306404114,
                0.01687374897301197,
                0.10685255378484726,
                -0.0014541983837261796,
                -0.005465666763484478,
                0.046050574630498886,
                -0.043107274919748306,
                0.03001094050705433,
                0.03660402446985245,
                -0.027874212712049484,
                -0.010920657776296139,
                0.11327504366636276,
                0.016273418441414833,
                0.014363040216267109,
                -0.018482394516468048,
                -0.001074062893167138,
                -0.05327850580215454,
                0.0203878004103899,
                0.018013952299952507,
                -0.04154953733086586,
                -0.016732724383473396,
                -0.022998707368969917,
                -0.003782332642003894,
                -0.05893056467175484,
                -0.021293221041560173,
                0.07023228704929352,
                -0.008264078758656979,
                -0.05458856374025345,
                0.013758149929344654,
                0.008635897189378738,
                -0.01829727552831173,
                0.0019098607590422034,
                -0.01026312354952097,
                0.033717017620801926,
                -0.051223691552877426,
                0.0426308810710907,
                -0.02252369187772274,
                0.02079898491501808,
                -0.0053909048438072205,
                0.015663014724850655,
                -0.021283458918333054,
                0.027505973353981972,
                0.0033186848741024733,
                0.033339034765958786,
                0.041378267109394073,
                0.02353249490261078,
                0.036763083189725876,
                -0.01736852526664734,
                -0.015844669193029404,
                -0.02353379875421524,
                0.0038538386579602957,
                -0.014279966242611408,
                -0.0018718186765909195,
                0.01913774386048317,
                -0.018248040229082108,
                -0.021113978698849678,
                0.027470804750919342,
                0.00570108974352479,
                -0.022491443902254105,
                -0.01118486188352108,
                -0.011005752719938755,
                -0.012346920557320118,
                -0.014290542341768742,
                0.013223999179899693,
                -0.001838998286984861,
                -0.021005962044000626,
                0.031935010105371475,
                -0.012351837940514088,
                0.02023812010884285,
                0.009987191297113895,
                0.023777944967150688,
                -0.041759055107831955,
                -0.002561350120231509,
                -0.03915117308497429,
                0.004351325798779726,
                0.009436558932065964,
                -0.0582510381937027,
                -0.07274454832077026,
                0.0467800535261631,
                0.05999848619103432,
                0.04012813791632652,
                -0.04912905395030975,
                -0.025919320061802864,
                -0.0045531936921179295,
                0.0010912989964708686,
                0.029625723138451576,
                -0.04752881079912186,
                0.020191442221403122,
                0.03076215647161007,
                0.024970175698399544,
                -0.008564085699617863,
                -0.015802064910531044,
                -0.034494321793317795,
                0.0007463836809620261,
                0.048531677573919296,
                0.06671848893165588,
                0.0015022363513708115,
                -0.018836695700883865,
                -0.013958490453660488,
                0.08565612882375717,
                -0.016687793657183647,
                0.020844822749495506,
                0.016104476526379585,
                -0.02370406873524189,
                0.01863488182425499,
                -0.028269318863749504,
                0.00897917989641428,
                -0.05540326237678528,
                -0.021777436137199402,
                -0.03651674464344978,
                -0.017322147265076637,
                0.05268348753452301,
                0.03775588423013687,
                0.006572752259671688,
                -0.026912018656730652,
                -0.05546979606151581,
                0.03895426541566849,
                0.025290487334132195,
                -0.007749689277261496,
                0.021385185420513153,
                0.022221336141228676,
                -0.017705900594592094,
                0.024713261052966118,
                0.010053017176687717,
                0.0013778764987364411,
                -0.01916302926838398,
                0.0403096042573452,
                0.02291293255984783,
                -0.08376851677894592,
                -0.028679266571998596,
                -0.05822732672095299,
                -0.012031621299684048,
                0.010943177156150341,
                0.040220364928245544,
                0.031390272080898285,
                -0.011641556397080421,
                -0.010767401196062565,
                -0.01737719215452671,
                -0.033576495945453644,
                -0.014490594156086445,
                -0.010817376896739006,
                -0.04293080419301987,
                -0.010866359807550907,
                0.0227125845849514,
                -0.00823158584535122,
                -0.02955077774822712,
                0.011278786696493626,
                -0.05058620125055313,
                -0.03341638669371605,
                0.014708445407450199,
                0.013809049502015114,
                -0.024965422227978706,
                -0.0046480013988912106,
                0.005252446047961712,
                0.028304021805524826,
                0.0005879568052478135,
                0.043797045946121216,
                -0.038142237812280655,
                -0.0294419564306736,
                0.009632088243961334,
                0.009783311747014523,
                0.00978440698236227,
                -0.003673137631267309,
                -0.005119888111948967,
                -0.04373563081026077,
                0.06601361185312271,
                -0.00565293850377202,
                -0.05517372488975525,
                0.012117709964513779,
                -0.015674496069550514,
                -0.0006338845123536885,
                -0.07210952043533325,
                0.03872668743133545,
                -0.03046930953860283,
                -0.008809439837932587,
                0.00970651675015688,
                -0.0008445778512395918,
                0.018099753186106682,
                0.00012600353511516005,
                0.04245990514755249,
                -0.05399053916335106,
                0.05363723263144493,
                -0.0478045754134655,
                0.05425220727920532,
                -0.0012073791585862637,
                0.04192470386624336,
                -0.005072061438113451,
                0.026674868538975716,
                -0.009184075519442558,
                0.035656388849020004,
                -0.010427475906908512,
                0.044610027223825455,
                0.002251989906653762,
                0.0011066432343795896,
                0.02835863269865513,
                0.03561020642518997,
                -0.02106752246618271,
                0.00815720483660698,
                0.018108157441020012,
                0.02725888602435589,
                -0.08155008405447006,
                -0.01666308380663395,
                0.03209302946925163,
                -0.005432369187474251,
                -0.03428562358021736,
                -0.020111827179789543,
                0.0449545755982399,
                -0.07365767657756805,
                -0.043857503682374954,
                0.05010112747550011,
                0.05878271535038948,
                0.01052752137184143,
                0.006566344294697046,
                -0.028071004897356033,
                -0.03836236894130707,
                0.012162033468484879,
                -0.004008698742836714,
                -0.008758713491261005,
                -0.05831238627433777,
                -0.03068188950419426,
                0.003157404949888587,
                0.012003563344478607,
                -0.01815759390592575,
                0.024640632793307304,
                -0.026736335828900337,
                -0.04397957772016525,
                0.01718902960419655,
                0.006866874173283577,
                -0.0727618932723999,
                -0.03333054855465889,
                -0.04560912773013115,
                0.023922206833958626,
                0.022084388881921768,
                -0.031477224081754684,
                0.008153261616826057,
                -0.00735127879306674,
                0.044424112886190414,
                -0.011960320174694061,
                0.038862090557813644,
                0.016173899173736572,
                0.07578226178884506,
                0.03371060639619827,
                -0.015629075467586517,
                0.028153298422694206,
                0.08466175198554993,
                0.012097480706870556,
                -0.060619860887527466,
                0.00845230184495449,
                0.019643202424049377,
                0.03283759206533432,
                -0.02379644475877285,
                -0.08172224462032318,
                -0.02022651582956314,
                0.015515508130192757,
                -0.051834966987371445,
                -0.03802620992064476,
                0.014782655984163284,
                -0.009558768942952156,
                0.03745075315237045,
                0.026100117713212967,
                0.021354248747229576,
                0.03700116276741028,
                0.001448135357350111,
                0.005713974591344595,
                -0.040506843477487564,
                0.02237456850707531,
                0.02358187921345234,
                0.03346659988164902,
                -0.02308853343129158,
                -0.07003899663686752,
                0.004479438532143831,
                -0.019896185025572777,
                -0.013273697346448898,
                0.005119468085467815,
                0.01200180221349001,
                0.013773667626082897,
                -0.009196089580655098,
                -0.011139347217977047,
                0.028357187286019325,
                -0.011640128679573536,
                0.044750962406396866,
                0.009093008935451508,
                0.004644009284675121,
                -0.005701308138668537,
                0.027786562219262123,
                0.023727403953671455,
                -0.006932530086487532,
                0.025733675807714462,
                -0.10446513444185257,
                0.0640442818403244,
                -0.02040206640958786,
                0.021853797137737274,
                -0.04156333953142166,
                0.05735403671860695,
                -0.009824155829846859,
                -0.016281791031360626,
                0.04347386956214905,
                -0.03718944266438484,
                0.06758180260658264,
                0.05082230269908905,
                -0.002929654670879245,
                -0.05452010780572891,
                -0.031609825789928436,
                -0.006410991307348013,
                0.0478455126285553,
                -0.011436608619987965,
                0.026263080537319183,
                0.00846206396818161,
                0.03574147820472717,
                0.05219244211912155,
                0.010531133972108364,
                0.013698669150471687,
                0.08991739153862,
                -0.056905750185251236,
                -0.016175460070371628,
                -0.026513798162341118,
                -0.015872683376073837,
                0.006209372542798519,
                0.016266580671072006,
                0.04750536382198334,
                -0.019492506980895996,
                0.027379661798477173,
                -0.05836673825979233,
                -0.02234005555510521,
                -0.02908335067331791,
                0.003140390384942293,
                -0.007673046085983515,
                0.04071558639407158,
                0.008468201383948326,
                -0.0029209961649030447,
                -0.049943193793296814,
                0.05250444635748863,
                0.06622437387704849,
                0.023852219805121422,
                0.10734382271766663,
                0.0027275048196315765,
                0.05428885668516159,
                -0.013155320659279823,
                0.0663168877363205,
                0.02754911221563816,
                0.016192764043807983,
                -0.013279790058732033,
                0.03744367137551308,
                -0.03429294750094414,
                -0.026585320010781288,
                0.04473463073372841,
                0.04404277727007866,
                0.0018027412006631494,
                0.008444166742265224,
                -0.019126366823911667,
                0.06953445822000504,
                -0.012847268022596836,
                0.02626906894147396,
                -0.0001603611308382824,
                -5.4620267039339015e-33,
                -0.012729418464004993,
                -0.04835054278373718,
                0.03627453371882439,
                -0.013884766027331352,
                -0.001232730457559228,
                -0.07830382883548737,
                0.019956674426794052,
                -3.2775209547253326e-05,
                -0.026220807805657387,
                -0.027173470705747604,
                -0.014303712174296379,
                0.04294748231768608,
                0.019936075434088707,
                0.0022232558112591505,
                0.058884311467409134,
                0.03804285451769829,
                0.030934441834688187,
                0.01199370063841343,
                0.005734393838793039,
                -0.039398401975631714,
                -0.0427653007209301,
                -0.020669987425208092,
                0.03468707203865051,
                0.015111484564840794,
                -0.00693294033408165,
                -0.05221652239561081,
                -0.004428582731634378,
                -0.06660464406013489,
                -0.03177914768457413,
                -0.021762972697615623,
                0.01191139779984951,
                -0.0017381208017468452,
                -0.026603031903505325,
                -0.01207850594073534,
                0.01913362182676792,
                -0.05248476564884186,
                -0.02456667833030224,
                0.009470848366618156,
                -0.03255636245012283,
                0.04401765018701553,
                -0.052120693027973175,
                -0.0751742422580719,
                0.005985146854072809,
                -0.04276037961244583,
                0.006446953862905502,
                -0.06397844105958939,
                -0.013441256247460842,
                0.037983451038599014,
                0.054049354046583176,
                0.005656089633703232,
                -0.03812001273036003,
                -0.024290459230542183,
                -0.022129876539111137,
                0.01761987805366516,
                -0.014744836837053299,
                -0.0695725753903389,
                0.026046384125947952,
                -0.04412871226668358,
                0.0063380030915141106,
                0.050921738147735596,
                -0.007582734804600477,
                0.043654728680849075,
                0.05136163905262947,
                -0.06697855144739151,
                -0.028252681717276573,
                0.03183251619338989,
                -0.04058440774679184,
                0.026313114911317825,
                0.03672713413834572,
                -0.09222760051488876,
                0.022879118099808693,
                0.06338270753622055,
                -0.014127366244792938,
                0.020345142111182213,
                0.01956670731306076,
                -0.00778473075479269,
                -0.03662623092532158,
                -0.0669858455657959,
                0.10194028913974762,
                -0.018059510737657547,
                -0.07554157078266144,
                0.02154182270169258,
                0.03957522287964821,
                -0.00039946852484717965,
                0.0022245761938393116,
                -0.03449654579162598,
                0.009985312819480896,
                0.03135545179247856,
                -0.007011286914348602,
                0.020371368154883385,
                -0.0008761566132307053,
                -0.008899164386093616,
                -0.028621556237339973,
                0.0009040948934853077,
                -0.01837083138525486,
                -0.04144779220223427,
                -0.012034785933792591,
                -0.0018529414664953947,
                -0.01828346960246563,
                0.04576769098639488,
                -0.03991943597793579,
                0.019884638488292694,
                -0.03574974089860916,
                -0.057939719408750534,
                0.0010057222098112106,
                0.05442699044942856,
                -0.03293090686202049,
                0.030624883249402046,
                0.012775883078575134,
                -0.03701775521039963,
                0.0217463206499815,
                -0.028638865798711777,
                -0.009751301258802414,
                -0.027389438822865486,
                0.05519881099462509,
                0.028712371364235878,
                0.013633226044476032,
                -0.05832340195775032,
                -0.05056961998343468,
                0.025415554642677307,
                -0.0188525952398777,
                0.009085102938115597,
                -0.03111979365348816,
                -0.018934905529022217,
                0.006950696464627981,
                -0.015628812834620476,
                0.07592622190713882,
                -0.0026048184372484684,
                0.007579735945910215,
                0.003726968774572015,
                0.009691379964351654,
                -0.0031035796273499727,
                2.18419629050004e-07,
                -0.018702177330851555,
                0.11698625981807709,
                0.03595359995961189,
                0.041120342910289764,
                -0.02590201608836651,
                -0.06510032713413239,
                -0.006070822477340698,
                0.007517916616052389,
                0.0073486268520355225,
                0.025706563144922256,
                -0.026277044788002968,
                0.0047197723761200905,
                -0.02872803807258606,
                -0.005819362588226795,
                -0.01236430462449789,
                -0.0017682876205071807,
                -0.020181167870759964,
                0.02364552766084671,
                -0.014645133167505264,
                -0.038865409791469574,
                -0.003855378832668066,
                0.017901619896292686,
                0.014112238772213459,
                -0.03035374917089939,
                -0.0335017666220665,
                -0.0149647556245327,
                -0.052215877920389175,
                0.042770400643348694,
                0.05702287331223488,
                0.0041983542032539845,
                0.019313806667923927,
                0.01232665590941906,
                0.05624372884631157,
                0.044434934854507446,
                0.0399944931268692,
                -0.055066950619220734,
                0.04378281533718109,
                0.06550946086645126,
                0.007159003987908363,
                0.04940656200051308,
                -0.05418356880545616,
                0.03700621426105499,
                -0.011547017842531204,
                -0.010973164811730385,
                0.026684658601880074,
                0.01515116821974516,
                0.03128707781434059,
                -0.0028800813015550375,
                -0.03151973709464073,
                -0.04019362851977348,
                -0.043975625187158585,
                0.02751844748854637,
                0.0009609703556634486,
                -0.016860565170645714,
                0.05105062574148178,
                -0.005095154978334904,
                0.03517449274659157,
                0.054592303931713104,
                -0.0041995481587946415,
                -0.012386906892061234,
                0.018549099564552307,
                -0.05692743510007858,
                -0.011774943210184574,
                -0.015734951943159103,
                0.07008242607116699,
                -0.02650788053870201,
                -0.021295111626386642,
                9.493403153808034e-35,
                -0.01390056498348713,
                -0.03812909498810768,
                -0.005208940710872412,
                0.03292166814208031,
                -0.0572768934071064,
                -0.03686048462986946,
                -0.028341222554445267,
                -0.040810924023389816,
                0.005369970574975014,
                0.0920015349984169,
                -0.027703074738383293
            ]
        },
        "Experience": {
            "name": "Robert Gordon University graduate with a BSc (Hons) in Digital Media. I have a lot of experience in various programs such as those found in the Adobe Creative Suite, as well as similar programs. My specialties are in video editing, animation and UI & UX design. In my spare time I enjoy working on personal projects involving writing, illustration and game design. I am looking for opportunities to expand on my current skills, work with a friendly and engaging team and help others.",
            "rep": [
                0.053575463593006134,
                -0.005688690114766359,
                -0.029274454340338707,
                -0.05867952108383179,
                0.03464224934577942,
                0.007014893926680088,
                -0.009026937186717987,
                0.014633562415838242,
                -0.023014653474092484,
                -0.048245206475257874,
                0.01683427393436432,
                0.01874103769659996,
                -0.015584612265229225,
                0.015768559649586678,
                0.02362140826880932,
                -0.0047050658613443375,
                -0.016552789136767387,
                0.013992423191666603,
                0.00932393129914999,
                -0.012713312171399593,
                0.003090922487899661,
                0.053343262523412704,
                -0.06350696086883545,
                0.013414379209280014,
                -0.041018128395080566,
                -0.024145767092704773,
                0.003931875340640545,
                0.06623764336109161,
                0.05954733490943909,
                0.06918281316757202,
                -0.017919981852173805,
                -0.012666153721511364,
                -0.013669617474079132,
                -0.005174335557967424,
                1.634171667319606e-06,
                0.0005393337341956794,
                0.016232313588261604,
                -0.02419969253242016,
                0.061241332441568375,
                -0.014402821660041809,
                0.005357055459171534,
                0.05558796226978302,
                -0.02115255780518055,
                0.02087598294019699,
                -0.017871076241135597,
                -0.00032333636772818863,
                0.06513021141290665,
                -0.05338705703616142,
                0.009278708137571812,
                0.02287830039858818,
                -0.016960427165031433,
                -0.04095030203461647,
                -0.0005548901972360909,
                -0.03129417076706886,
                -0.041923221200704575,
                0.021984994411468506,
                -0.014509628526866436,
                -0.006786598823964596,
                0.06600406020879745,
                -0.017968960106372833,
                0.019492285326123238,
                0.010003406554460526,
                -0.050801701843738556,
                -0.07302793860435486,
                0.026678504422307014,
                0.06530062109231949,
                0.015207282267510891,
                -0.0551784411072731,
                0.06328397244215012,
                -0.027060337364673615,
                0.05580195412039757,
                0.022222626954317093,
                -0.013889309018850327,
                0.023769786581397057,
                0.03142745420336723,
                0.0906534269452095,
                0.007160061504691839,
                -0.0006192836444824934,
                -0.018648510798811913,
                0.009385725483298302,
                -0.13232670724391937,
                0.02260940708220005,
                -0.03493959456682205,
                -0.07081698626279831,
                0.03631585091352463,
                -0.0012182864593341947,
                0.008709169924259186,
                0.02248825691640377,
                -0.017690278589725494,
                -0.002143957419320941,
                0.03767343610525131,
                0.0002438874071231112,
                -0.01225945446640253,
                0.030246397480368614,
                0.005461837165057659,
                -0.00558330025523901,
                0.028425708413124084,
                -0.0008353494922630489,
                -0.012074584141373634,
                0.057066891342401505,
                0.020192332565784454,
                0.03182430565357208,
                0.0210153516381979,
                0.05160770192742348,
                0.008851009421050549,
                -0.01931462064385414,
                -0.00719786062836647,
                -0.04691271111369133,
                -0.019704163074493408,
                0.010770144872367382,
                -0.05885619297623634,
                -0.036344289779663086,
                -0.03118181601166725,
                0.022568602114915848,
                -0.04526842385530472,
                0.025982094928622246,
                -0.014815433882176876,
                -0.016535378992557526,
                0.02010151371359825,
                0.000663905986584723,
                0.040037281811237335,
                0.026675468310713768,
                0.028760988265275955,
                0.06951801478862762,
                -0.061874356120824814,
                0.024438148364424706,
                0.010365409776568413,
                -0.005407990422099829,
                -0.01481755543500185,
                0.013487518765032291,
                0.04692521318793297,
                0.006148522719740868,
                -0.034864529967308044,
                -0.07022430747747421,
                0.0029041662346571684,
                0.025966232642531395,
                -0.010037108324468136,
                0.02298128232359886,
                -0.0012051822850480676,
                0.0074399192817509174,
                0.016859015449881554,
                0.016232607886195183,
                -0.0012344904243946075,
                0.05942211300134659,
                0.005727720446884632,
                -0.018632858991622925,
                0.0015285295667126775,
                0.009993542917072773,
                -0.006863179616630077,
                0.02075219340622425,
                0.05937698855996132,
                -0.012892922386527061,
                -0.006647375412285328,
                0.030064422637224197,
                0.03737262263894081,
                0.0028072677087038755,
                0.029658405110239983,
                0.022005733102560043,
                -0.003162488341331482,
                0.07993796467781067,
                0.042276717722415924,
                -0.03565537929534912,
                0.00881425105035305,
                0.0575234517455101,
                -0.09332980960607529,
                0.041815973818302155,
                0.03540384769439697,
                0.03071596473455429,
                -0.018894970417022705,
                0.034363582730293274,
                0.05357600003480911,
                -0.029372213408350945,
                -0.02064024657011032,
                0.01157268974930048,
                0.04425010085105896,
                -0.02675904519855976,
                0.0377703495323658,
                -0.024522023275494576,
                0.08087129145860672,
                0.015739573165774345,
                -0.025581926107406616,
                -0.17266245186328888,
                0.013068406842648983,
                0.06852394342422485,
                -0.003182255430147052,
                0.06036213040351868,
                -0.029558172449469566,
                0.08512517809867859,
                -0.01779879629611969,
                0.03288610279560089,
                -0.009158474393188953,
                0.008943280205130577,
                -0.04724118858575821,
                0.05714293569326401,
                -0.03805379569530487,
                0.005382356699556112,
                -0.05646633729338646,
                -0.06413246691226959,
                -0.0366954505443573,
                -0.09018231183290482,
                -0.02001381292939186,
                -0.009925507009029388,
                -0.039441175758838654,
                0.0585823655128479,
                0.03515073284506798,
                -0.026330778375267982,
                0.04851054400205612,
                -0.05002111941576004,
                0.004228518344461918,
                0.03910532221198082,
                0.07870608568191528,
                -0.002263684058561921,
                0.02280813828110695,
                0.025630874559283257,
                0.006487740203738213,
                -0.034385181963443756,
                -0.015503783710300922,
                0.00899044144898653,
                0.03803962469100952,
                0.06794700771570206,
                -0.00993526540696621,
                0.009056024253368378,
                0.010317100211977959,
                0.00455498369410634,
                0.005095003172755241,
                -0.04347306862473488,
                0.002048988128080964,
                0.004474898334592581,
                -0.021206358447670937,
                0.05473029613494873,
                -0.04870688542723656,
                -0.001707846880890429,
                0.046327855437994,
                -0.037959422916173935,
                -0.025748560205101967,
                0.022980522364377975,
                0.09680294245481491,
                -0.026849374175071716,
                -0.003375130007043481,
                -0.03160034865140915,
                -0.04286946356296539,
                0.016702093183994293,
                -0.01988840289413929,
                0.09266632795333862,
                -0.006505690515041351,
                0.039084214717149734,
                0.03269020840525627,
                0.012461354024708271,
                -0.01663125678896904,
                0.014593281783163548,
                -0.01602078042924404,
                -0.0025997383054345846,
                0.03364749997854233,
                0.034911755472421646,
                -0.02145661786198616,
                -0.032624706625938416,
                -0.051127638667821884,
                0.009415804408490658,
                -0.13550855219364166,
                -0.009657684713602066,
                -0.07611638307571411,
                -0.02481534145772457,
                -0.03305257484316826,
                -0.015562789514660835,
                -0.030688639730215073,
                -0.008189689368009567,
                0.029361315071582794,
                0.015786275267601013,
                -0.019217990338802338,
                0.013800595887005329,
                0.029574748128652573,
                -0.003909632563591003,
                -0.0005713750724680722,
                -0.034695833921432495,
                -0.009393333457410336,
                -0.0008209796505980194,
                -0.007487361319363117,
                0.018512781709432602,
                -0.019162742421030998,
                0.023476634174585342,
                0.027116095647215843,
                -0.011106344871222973,
                -0.02641856111586094,
                -0.01598196104168892,
                0.014498984441161156,
                0.004495653789490461,
                0.021632444113492966,
                -0.04701042175292969,
                -0.014012730680406094,
                0.022422978654503822,
                -0.025245003402233124,
                -0.002697171177715063,
                0.03868803009390831,
                -0.058769408613443375,
                -0.04403669387102127,
                0.007368853315711021,
                -0.071940578520298,
                -0.01848450116813183,
                -0.026911547407507896,
                0.03980667516589165,
                -0.014929769560694695,
                0.008877860382199287,
                0.06766671687364578,
                0.026320138946175575,
                -0.002067749621346593,
                0.030182071030139923,
                0.009450340643525124,
                0.026149960234761238,
                0.0014714484568685293,
                -0.030843349173665047,
                -0.007285514380782843,
                -0.011881154030561447,
                0.011174137704074383,
                0.019144900143146515,
                0.06278212368488312,
                0.02018338069319725,
                -0.041503582149744034,
                -0.053455859422683716,
                0.04182301461696625,
                0.056030359119176865,
                -0.029659010469913483,
                0.048147834837436676,
                -0.003953159786760807,
                -0.058691125363111496,
                0.009652948938310146,
                0.040629737079143524,
                -0.03922737389802933,
                -0.01861841231584549,
                0.0105808787047863,
                -0.05219614505767822,
                0.0044827829115092754,
                -0.049350716173648834,
                -0.004203988704830408,
                -0.010475663468241692,
                0.001615410321392119,
                -0.03452616184949875,
                0.0072841159999370575,
                -0.07003677636384964,
                0.011963612399995327,
                -0.024342691525816917,
                -0.027295798063278198,
                0.021786775439977646,
                -0.03536694124341011,
                0.03761165961623192,
                0.0014032984618097544,
                -0.038305141031742096,
                0.02962222695350647,
                0.035076986998319626,
                -0.04625494405627251,
                0.04516609385609627,
                -0.009625260718166828,
                0.06756636500358582,
                0.013395684771239758,
                -0.03540225699543953,
                -0.020364675670862198,
                0.010751978494226933,
                0.023461291566491127,
                0.04123314097523689,
                -0.04460277780890465,
                0.041260961443185806,
                -0.07167380303144455,
                -0.02164624072611332,
                -0.031167466193437576,
                0.04063168540596962,
                0.015068248845636845,
                0.009752251207828522,
                0.06830006837844849,
                -0.03410830348730087,
                -0.030999675393104553,
                0.0008863138500601053,
                0.006628620438277721,
                0.0322992168366909,
                -0.041609201580286026,
                0.010663461871445179,
                0.023017408326268196,
                -0.010014351457357407,
                0.015045998618006706,
                0.024783050641417503,
                -0.031084854155778885,
                0.01616652123630047,
                0.08028151094913483,
                -0.029479971155524254,
                0.03461431339383125,
                -0.04822564497590065,
                0.01187975611537695,
                -0.015607167035341263,
                0.0074723754078149796,
                0.02363641746342182,
                -0.03197645768523216,
                0.0478481650352478,
                0.004594237543642521,
                0.022253047674894333,
                0.000830631353892386,
                -0.018837101757526398,
                -0.009562014602124691,
                0.014692923054099083,
                -0.0326615534722805,
                -0.04082963988184929,
                -0.04279474541544914,
                0.032046087086200714,
                -0.01686149090528488,
                -0.0006310761091299355,
                -0.061072904616594315,
                0.003356749191880226,
                0.009980612434446812,
                -0.04859081283211708,
                0.07726963609457016,
                0.04090883210301399,
                0.025415722280740738,
                0.020178508013486862,
                -0.059227779507637024,
                0.0089887585490942,
                0.060968562960624695,
                0.08150684088468552,
                -0.06555254757404327,
                -0.07649069279432297,
                0.01507605891674757,
                0.0008185547776520252,
                0.026773424819111824,
                -0.055057935416698456,
                0.030161987990140915,
                0.012974261306226254,
                -0.007018609903752804,
                -0.0010695983655750751,
                0.06205986440181732,
                -0.051152318716049194,
                0.00605925265699625,
                0.0010470286943018436,
                0.04360957816243172,
                -0.039404287934303284,
                -0.07794299721717834,
                0.0503556989133358,
                0.020800093188881874,
                0.040885552763938904,
                -0.0245550237596035,
                -0.024167200550436974,
                -0.014587878249585629,
                0.010220492258667946,
                0.022759726271033287,
                -0.008196692913770676,
                0.02588627301156521,
                0.010640560649335384,
                0.004015552811324596,
                0.02570207603275776,
                -0.015131182968616486,
                -0.016757896170020103,
                0.010706344619393349,
                0.002020378829911351,
                -0.03849836811423302,
                0.00671138521283865,
                0.07814330607652664,
                0.033144596964120865,
                -0.016023224219679832,
                -0.08143715560436249,
                -0.0587134063243866,
                -0.04798491671681404,
                -0.020593969151377678,
                -0.0025510082487016916,
                0.04581952467560768,
                -0.024748852476477623,
                0.025330670177936554,
                0.019202446565032005,
                -0.012127860449254513,
                0.027965178713202477,
                -0.017256561666727066,
                -0.028086939826607704,
                0.04458541050553322,
                -0.022255931049585342,
                0.04407351091504097,
                -0.08746906369924545,
                0.02259739488363266,
                -0.02558835595846176,
                -0.07629098743200302,
                -0.058090690523386,
                -0.024950439110398293,
                -0.008589384146034718,
                0.007670909631997347,
                0.06583022326231003,
                -0.024503689259290695,
                -0.0348481684923172,
                -0.009504602290689945,
                -0.021192824468016624,
                0.019646909087896347,
                -0.03238305076956749,
                0.0037082990165799856,
                0.03815193474292755,
                0.02743680216372013,
                0.027238432317972183,
                -0.030603107064962387,
                -0.050196900963783264,
                0.0554843544960022,
                -0.04447869211435318,
                0.0016590593149885535,
                -0.02249443717300892,
                -0.003084838157519698,
                0.037017542868852615,
                -0.04526880010962486,
                0.005599404219537973,
                -0.020880043506622314,
                0.03532358258962631,
                -0.029037997126579285,
                -0.009345277212560177,
                -0.016468526795506477,
                0.014072726480662823,
                -0.025521039962768555,
                -0.018125133588910103,
                -0.04656520485877991,
                0.016553862020373344,
                -0.008529617451131344,
                0.04974565654993057,
                0.0429670587182045,
                0.003181271255016327,
                -0.022499535232782364,
                0.00989363994449377,
                0.04369466006755829,
                0.014349494129419327,
                0.024677179753780365,
                -0.06926646083593369,
                0.029253102838993073,
                -0.011537604965269566,
                0.033700376749038696,
                -0.02905716374516487,
                -0.008710216730833054,
                0.045080672949552536,
                -0.07163233309984207,
                -0.03844861686229706,
                0.001616773079149425,
                0.019409270957112312,
                0.043860021978616714,
                0.026468096300959587,
                -0.04311220720410347,
                -0.018156498670578003,
                -0.005518466699868441,
                0.02735140360891819,
                0.026024922728538513,
                0.025653133168816566,
                0.01572970487177372,
                0.06009407714009285,
                0.02526431530714035,
                0.07371911406517029,
                -0.02882480062544346,
                -0.002771867671981454,
                -0.028877681121230125,
                -0.033324457705020905,
                -0.05552591010928154,
                -0.009715160354971886,
                -0.03369484096765518,
                0.02172609232366085,
                0.04807426407933235,
                -0.024621302261948586,
                -0.007118078880012035,
                -0.011441127397119999,
                -0.05810566619038582,
                0.060173142701387405,
                -0.05254271626472473,
                -4.86983064974688e-33,
                -0.004597934428602457,
                -0.034843456000089645,
                0.005108965560793877,
                0.0007236382225528359,
                -0.014912151731550694,
                -0.04855724796652794,
                0.065201535820961,
                -0.062463462352752686,
                -0.006514550652354956,
                -0.020274898037314415,
                -0.006934944540262222,
                -0.013841281644999981,
                0.016982320696115494,
                0.0012272854801267385,
                -0.03589751943945885,
                0.06039346382021904,
                0.05230660364031792,
                0.024728624150156975,
                0.00445216940715909,
                -0.009160720743238926,
                -0.05325743183493614,
                -0.03141392767429352,
                -0.03289799764752388,
                -0.008766994811594486,
                -0.06734415888786316,
                -0.05729074403643608,
                -0.031007107347249985,
                0.04159101843833923,
                0.03860720247030258,
                0.036201342940330505,
                0.0014065945288166404,
                -0.006028333678841591,
                -0.019444191828370094,
                0.005392206367105246,
                0.037410274147987366,
                -0.04336341843008995,
                -0.03091198019683361,
                0.04932691529393196,
                0.032240547239780426,
                0.013366238214075565,
                0.014643550850450993,
                0.006197676993906498,
                -0.019245794042944908,
                -0.05002822354435921,
                0.022365901619195938,
                -0.01841583661735058,
                0.009527169167995453,
                0.03850262984633446,
                0.004836266860365868,
                -0.03301345929503441,
                -0.06967423856258392,
                -0.027983596548438072,
                -0.07688575237989426,
                0.024867171421647072,
                -0.07210502028465271,
                -0.02459093928337097,
                0.011283846572041512,
                -0.01475306786596775,
                -0.0869891494512558,
                -0.016878264024853706,
                -0.030147885903716087,
                0.04039638489484787,
                0.04997976869344711,
                -0.03347649425268173,
                0.005001399200409651,
                0.06149373576045036,
                0.0447344146668911,
                0.0833103209733963,
                0.01198506262153387,
                0.013016336597502232,
                -0.009936914779245853,
                0.007338732481002808,
                -0.02452884241938591,
                0.036512989550828934,
                -0.04957675188779831,
                -0.04197920858860016,
                0.005701371468603611,
                0.0007997815264388919,
                0.03378713130950928,
                -0.003965100739151239,
                -0.04722380265593529,
                0.026839157566428185,
                0.05064869672060013,
                -0.0312577523291111,
                0.021645046770572662,
                -0.029928801581263542,
                -0.03503162041306496,
                -0.028432343155145645,
                0.05219598114490509,
                0.018457503989338875,
                0.047636307775974274,
                0.015951408073306084,
                -0.05113929510116577,
                0.007415722589939833,
                0.07176155596971512,
                -0.02966117113828659,
                0.0014781239442527294,
                0.0006156241870485246,
                -0.04052095115184784,
                0.010246512480080128,
                0.032789863646030426,
                0.028039410710334778,
                -0.0263146311044693,
                -0.013660871423780918,
                0.034166108816862106,
                0.04549252614378929,
                -0.010016800835728645,
                0.021964820101857185,
                -0.020556118339300156,
                -0.009843760170042515,
                0.042848747223615646,
                0.037200722843408585,
                0.0034324852749705315,
                0.0007825206848792732,
                -0.0343656986951828,
                -0.0038070473819971085,
                0.03683850169181824,
                -0.04365651682019234,
                -0.03070060908794403,
                0.02164575643837452,
                0.06895677745342255,
                -0.06778522580862045,
                -0.02355198562145233,
                -0.003504233667626977,
                -0.004067970905452967,
                -0.02054729498922825,
                0.017142057418823242,
                0.006569056771695614,
                -0.025471001863479614,
                -0.03650752454996109,
                -0.01579597033560276,
                0.002922896994277835,
                2.442199615870777e-07,
                0.01658620499074459,
                -0.019973086193203926,
                -0.028575988486409187,
                0.03713901713490486,
                0.02252819761633873,
                -0.07442914694547653,
                0.005135216284543276,
                -0.004220529925078154,
                0.00030121777672320604,
                0.045221954584121704,
                -0.020569035783410072,
                -0.014785760082304478,
                -0.0360398106276989,
                -0.04973825439810753,
                -0.05875382572412491,
                -0.055252522230148315,
                0.0018070462392643094,
                0.0011748363031074405,
                -0.002705234568566084,
                0.03468439355492592,
                0.021948210895061493,
                -0.023265087977051735,
                0.05305574834346771,
                0.0009387974278070033,
                0.018832793459296227,
                0.016526302322745323,
                -0.014040278270840645,
                0.0039331200532615185,
                0.029221171513199806,
                0.021193088963627815,
                0.02389703318476677,
                -0.03341837227344513,
                0.02368815615773201,
                -0.010446486063301563,
                0.025971021503210068,
                -0.009526122361421585,
                0.037187609821558,
                0.05592535436153412,
                -0.030947363004088402,
                0.027524325996637344,
                0.014407197013497353,
                0.050945088267326355,
                0.017408324405550957,
                -0.01684902235865593,
                0.04432014748454094,
                -0.01467284094542265,
                0.015238644555211067,
                0.02870192565023899,
                -0.07122060656547546,
                -0.0391693152487278,
                -0.045285794883966446,
                0.03373698517680168,
                0.023248029872775078,
                -0.00539355119690299,
                0.039679720997810364,
                -0.03246673196554184,
                -0.05718771368265152,
                -0.0012420477578416467,
                0.04068085178732872,
                -0.007228661794215441,
                0.005287758074700832,
                -0.0026766029186546803,
                -0.0024674164596945047,
                -0.015280007384717464,
                0.07339814305305481,
                -0.03004811704158783,
                -0.02049250528216362,
                2.150726249415209e-34,
                -0.022508058696985245,
                0.0439920648932457,
                0.0023671011440455914,
                -0.002313267672434449,
                0.0416933111846447,
                -0.053464848548173904,
                -0.0833582952618599,
                -0.010025742463767529,
                0.002965308027341962,
                -0.028839144855737686,
                -0.021839452907443047
            ]
        },
        "Freelance": "Open to both",
        "Id": "6cd8c864-0ca2-4105-a328-67854977bb32",
        "Industry": {
            "name": "Design",
            "rep": [
                0.027160177007317543,
                -0.006390666589140892,
                -0.04312552884221077,
                0.046030040830373764,
                -0.04078924283385277,
                -0.005330369807779789,
                0.04418433830142021,
                -0.005830784328281879,
                -0.06254199147224426,
                0.0510014183819294,
                0.047945287078619,
                -0.011018871329724789,
                0.04562633857131004,
                0.09469538927078247,
                -0.006905362941324711,
                -0.021808722987771034,
                -0.048663027584552765,
                -0.0036394151393324137,
                -0.04333512857556343,
                -0.021901849657297134,
                -0.04430479556322098,
                0.006543759256601334,
                0.014048630371689796,
                -0.03001045249402523,
                -0.02730308100581169,
                -0.033930983394384384,
                -0.03750777989625931,
                0.008738071657717228,
                -0.03836655244231224,
                0.012977657839655876,
                0.03103417530655861,
                0.04662812128663063,
                -0.06302520632743835,
                -0.022722212597727776,
                1.8545463262853445e-06,
                -0.0011526531307026744,
                0.045260485261678696,
                -0.013546332716941833,
                -0.015617966651916504,
                0.04557105898857117,
                0.03443394973874092,
                -0.04624886065721512,
                -0.03602414205670357,
                0.000547729607205838,
                0.0005661704344674945,
                -0.022513730451464653,
                0.041325390338897705,
                0.07189809530973434,
                -0.030873795971274376,
                -0.044907096773386,
                -0.020922038704156876,
                -0.09755737334489822,
                -0.008231570944190025,
                -0.046724267303943634,
                -0.035625383257865906,
                0.04890643060207367,
                -0.006398147903382778,
                0.011185986921191216,
                0.10789987444877625,
                0.020687267184257507,
                -0.0005737575120292604,
                0.004338664934039116,
                0.0063929008319973946,
                0.03355288878083229,
                0.07195531576871872,
                0.008555583655834198,
                0.08519121259450912,
                -0.04579569399356842,
                0.009757516905665398,
                0.02252022549510002,
                0.05258637294173241,
                0.05531710013747215,
                0.00908432062715292,
                0.04872026666998863,
                -0.013936817646026611,
                -0.0306685883551836,
                -0.018280748277902603,
                -0.0025656367652118206,
                0.003146543400362134,
                -0.03817552700638771,
                -0.04431866109371185,
                -0.02273327298462391,
                -0.03635355830192566,
                -0.012785769067704678,
                0.035094160586595535,
                -0.0037707393057644367,
                0.006129625719040632,
                0.003102898597717285,
                -0.10762929916381836,
                0.041589464992284775,
                0.05645766481757164,
                -0.04044971987605095,
                0.04022904857993126,
                0.008842271752655506,
                -0.03204096853733063,
                -0.004716563504189253,
                0.04867800697684288,
                0.02594364434480667,
                0.011995956301689148,
                -0.02567337639629841,
                0.03341370075941086,
                0.04091554507613182,
                0.04316556826233864,
                0.027815161272883415,
                -0.027561089023947716,
                0.03354770690202713,
                -0.015929333865642548,
                -0.011779106222093105,
                -0.040357816964387894,
                0.11075357347726822,
                -0.019216908141970634,
                -0.0008022772963158786,
                -0.02099270187318325,
                -0.0012602960923686624,
                -0.0028145811520516872,
                -0.0023332037962973118,
                -0.04074582830071449,
                0.05197936296463013,
                0.03559377044439316,
                -0.006553135346621275,
                0.010295376181602478,
                0.04102393239736557,
                -0.028973650187253952,
                -0.006073059048503637,
                0.01827254891395569,
                -0.0026693283580243587,
                -0.039512019604444504,
                0.028464874252676964,
                0.013868358917534351,
                -0.0007913068984635174,
                0.014813612215220928,
                -0.0377519428730011,
                -0.014647937379777431,
                -0.030211813747882843,
                -0.002621972933411598,
                0.060693737119436264,
                -0.03890825808048248,
                0.016103262081742287,
                -0.04721888154745102,
                -0.06885185837745667,
                0.027242010459303856,
                -0.012406840920448303,
                -0.023668533191084862,
                -0.024904178455471992,
                -0.018429256975650787,
                -0.026980871334671974,
                0.02362213283777237,
                0.03910890966653824,
                -0.0441412515938282,
                -0.021274486556649208,
                -0.046506617218256,
                0.035800497978925705,
                0.011095551773905754,
                0.03690853342413902,
                0.015394452027976513,
                0.04282870888710022,
                0.017870867624878883,
                0.001132574281655252,
                -0.000673976493999362,
                0.0227688979357481,
                0.027730179950594902,
                0.016924895346164703,
                0.0024684437084943056,
                -0.023625073954463005,
                -0.018527792766690254,
                -0.010092100128531456,
                0.015839476138353348,
                -0.0006880726432427764,
                -0.07212934643030167,
                -0.03171195834875107,
                -0.042026620358228683,
                -0.010074630379676819,
                -0.02779592201113701,
                0.10390432924032211,
                0.03961862251162529,
                -0.049705199897289276,
                0.07158862799406052,
                0.014318767003715038,
                -0.0780070498585701,
                -0.023430809378623962,
                -0.026992373168468475,
                -0.10136394202709198,
                0.061607781797647476,
                0.009438582696020603,
                0.047481466084718704,
                0.023989595472812653,
                -0.022773317992687225,
                0.006056743208318949,
                -0.09629558026790619,
                0.007416123058646917,
                -0.010332221165299416,
                -0.032763443887233734,
                0.0289117693901062,
                0.04898456856608391,
                -0.006645283196121454,
                0.031161721795797348,
                0.03458975628018379,
                -0.03313666582107544,
                -0.03854302689433098,
                -0.0047928872518241405,
                -0.03118385374546051,
                -0.034231461584568024,
                0.04828391969203949,
                0.0711987242102623,
                -0.053502727299928665,
                0.03854355961084366,
                0.0541529543697834,
                0.0038197857793420553,
                0.002813430270180106,
                0.02418562024831772,
                0.038970548659563065,
                0.008022511377930641,
                -0.03364456444978714,
                0.06157386675477028,
                -0.02766585722565651,
                -0.019222674891352654,
                0.02918323501944542,
                0.012089814990758896,
                -0.10165029019117355,
                0.07484595477581024,
                -0.0072583830915391445,
                0.028781676664948463,
                0.023901211097836494,
                -0.013945469632744789,
                0.017008686438202858,
                -0.028761200606822968,
                0.04100431501865387,
                -0.02149283140897751,
                0.009005741216242313,
                -0.012117650359869003,
                -0.012391545809805393,
                0.043148886412382126,
                0.021973663941025734,
                -0.027359826490283012,
                0.029356863349676132,
                0.026117274537682533,
                0.08638835698366165,
                0.031476814299821854,
                0.006523262243717909,
                -0.11035195738077164,
                0.036319389939308167,
                0.010796698741614819,
                0.008156513795256615,
                -0.007956065237522125,
                0.0675630196928978,
                -0.05227304995059967,
                0.04036631062626839,
                0.014858891256153584,
                0.008058111183345318,
                -0.0019539562053978443,
                -0.0655757337808609,
                -0.03770345821976662,
                0.016519829630851746,
                -0.004079433623701334,
                0.028279174119234085,
                -0.01828591153025627,
                0.014654240570962429,
                0.050535865128040314,
                -0.03644990175962448,
                -0.04594583064317703,
                0.06582868844270706,
                -0.004185009282082319,
                0.028931427747011185,
                0.01568681001663208,
                0.06441279500722885,
                -0.023519856855273247,
                0.017591143026947975,
                -0.008272108621895313,
                -0.023033661767840385,
                0.012954450212419033,
                0.010091482661664486,
                0.03048383630812168,
                0.020000984892249107,
                -0.01687391847372055,
                0.013400323688983917,
                0.005788223817944527,
                -0.04274050146341324,
                -0.016585910692811012,
                0.022235164418816566,
                -0.01845407672226429,
                0.039969414472579956,
                -0.019832167774438858,
                -0.022640207782387733,
                -0.012937755323946476,
                0.048291753977537155,
                0.0041489736177027225,
                0.04392834007740021,
                -0.03100491315126419,
                -0.0280496496707201,
                0.048565346747636795,
                -0.03699786588549614,
                -0.05211622640490532,
                0.04176010936498642,
                -0.048067618161439896,
                0.05366698279976845,
                -0.010114817880094051,
                0.015669217333197594,
                0.00862378254532814,
                -0.028316453099250793,
                -0.0006194709567353129,
                -0.03267287090420723,
                -0.03959546238183975,
                0.006807500030845404,
                -0.01769416406750679,
                -0.04312781244516373,
                0.02230222523212433,
                0.06918942928314209,
                0.003047446720302105,
                -0.016070669516921043,
                -0.021362420171499252,
                0.007742118090391159,
                -0.00450665969401598,
                0.0093244519084692,
                0.05840367078781128,
                0.01803308166563511,
                0.0052368356846272945,
                0.017870118841528893,
                0.01512936968356371,
                0.029784176498651505,
                0.12540718913078308,
                -0.017610684037208557,
                0.013776663690805435,
                -0.0150937270373106,
                0.010330450721085072,
                0.04913415387272835,
                0.014697099104523659,
                -0.014608552679419518,
                0.009218739345669746,
                0.0187123641371727,
                -0.040219005197286606,
                -0.026015806943178177,
                0.01709059625864029,
                0.021108664572238922,
                -0.032190438359975815,
                -0.0031747035682201385,
                0.03295985609292984,
                -0.05501817166805267,
                -0.06217695772647858,
                -0.019085176289081573,
                -0.03152206540107727,
                -0.043947357684373856,
                -0.0594957172870636,
                -0.07385923713445663,
                0.0318128801882267,
                -0.05590851977467537,
                -0.025926146656274796,
                -0.005562108941376209,
                0.03130766376852989,
                -0.02753928117454052,
                0.05383443832397461,
                -0.009610269218683243,
                0.027980854734778404,
                -0.020724903792142868,
                0.012616794556379318,
                -0.015014007687568665,
                0.03258905187249184,
                -0.026877839118242264,
                0.02088034898042679,
                -0.03189600631594658,
                -0.041707221418619156,
                -0.058708880096673965,
                -8.805633115116507e-05,
                -0.06532986462116241,
                0.058995120227336884,
                -0.036684244871139526,
                0.019098203629255295,
                0.07178965955972672,
                0.07245960086584091,
                -0.012039653025567532,
                -0.007956394925713539,
                0.04699306562542915,
                0.05582600459456444,
                -0.0009464221075177193,
                0.03782428056001663,
                -9.748528827913105e-05,
                0.013138344511389732,
                0.036161284893751144,
                0.01313250046223402,
                0.022168362513184547,
                -0.025690825656056404,
                0.017107106745243073,
                -0.02984733134508133,
                0.037293482571840286,
                -0.027675628662109375,
                0.0019483814248815179,
                -0.027755197137594223,
                0.0325058214366436,
                0.012114984914660454,
                0.00023557264648843557,
                -0.0373782142996788,
                -0.010775694623589516,
                -0.03378435969352722,
                0.023090936243534088,
                0.025682801380753517,
                -0.012813949957489967,
                0.01727364957332611,
                -0.004375490825623274,
                0.028529735282063484,
                -0.00543510727584362,
                0.05277229845523834,
                0.022502968087792397,
                0.00330607988871634,
                -0.008525927551090717,
                0.01525959838181734,
                0.015069418586790562,
                -0.04933067411184311,
                0.08281256258487701,
                0.038749199360609055,
                0.0037135956808924675,
                -0.05823926255106926,
                0.051335301250219345,
                -0.012722788378596306,
                0.008585888892412186,
                -0.007391274441033602,
                -0.01698441617190838,
                0.006457590498030186,
                -0.06040557846426964,
                -0.07447420060634613,
                -0.021661976352334023,
                -0.05210021138191223,
                0.007178178522735834,
                0.01355801708996296,
                -0.004617542959749699,
                0.010603072121739388,
                0.015047281980514526,
                -0.06753521412611008,
                -0.029677916318178177,
                0.013821530155837536,
                0.04124228656291962,
                -0.017237745225429535,
                -0.028343040496110916,
                -0.01866229996085167,
                -0.031003911048173904,
                0.013412880711257458,
                -0.05506075546145439,
                -0.02795431762933731,
                -0.023459164425730705,
                -0.010853622108697891,
                0.011490223929286003,
                -0.024773210287094116,
                0.009321526624262333,
                0.01586170494556427,
                0.04672710970044136,
                0.02031458169221878,
                -0.02152472734451294,
                -0.05171703174710274,
                0.02575388364493847,
                -0.013818789273500443,
                -0.06755811721086502,
                0.030553752556443214,
                0.033639829605817795,
                -0.0019430579850450158,
                -0.009666968137025833,
                -0.03578983247280121,
                -0.0072870454750955105,
                0.006050518713891506,
                0.03093179129064083,
                0.0553022064268589,
                0.022255755960941315,
                0.0021826967131346464,
                0.03899015858769417,
                0.0427713617682457,
                -0.07078369706869125,
                -0.010201203636825085,
                -0.006578061729669571,
                -0.0805424302816391,
                -0.008284278213977814,
                0.015184462070465088,
                0.005666146520525217,
                -0.0332590751349926,
                -0.0274764783680439,
                0.025350326672196388,
                -0.02339133806526661,
                0.03759672865271568,
                0.023556092754006386,
                0.03799092024564743,
                -0.02748219296336174,
                -0.01595320925116539,
                0.027683669701218605,
                -0.007842592895030975,
                0.0362548828125,
                -0.01304080430418253,
                -0.02826911397278309,
                0.031440410763025284,
                0.013485010713338852,
                0.05223724991083145,
                0.0213070809841156,
                -0.009731465019285679,
                0.003410404548048973,
                -0.036908987909555435,
                -0.021870572119951248,
                -0.035504050552845,
                -0.021001501008868217,
                -0.01724138669669628,
                -0.039201520383358,
                0.029492177069187164,
                -0.07388996332883835,
                0.0669536218047142,
                0.025300316512584686,
                -0.02443409524857998,
                -0.03480076789855957,
                0.01971636340022087,
                0.02796965278685093,
                0.020363004878163338,
                -0.010262164287269115,
                0.0231078639626503,
                0.03211216628551483,
                -0.024897191673517227,
                0.005043499171733856,
                -0.008036698214709759,
                0.018124036490917206,
                0.044316474348306656,
                -0.009644449688494205,
                -0.09971652179956436,
                -0.0008188403444364667,
                -0.0024319197982549667,
                -0.062496576458215714,
                -0.04367212578654289,
                -0.05575741454958916,
                0.05349430814385414,
                -0.0014965113950893283,
                -0.03954680636525154,
                0.03815055266022682,
                0.019007492810487747,
                0.018134020268917084,
                -0.014956564642488956,
                0.043933089822530746,
                0.012000283226370811,
                -0.10063517093658447,
                0.006527033634483814,
                -0.06392500549554825,
                -0.04811102896928787,
                -0.03197570890188217,
                0.0032312385737895966,
                -0.032221972942352295,
                0.15318980813026428,
                0.014488599263131618,
                0.017861654981970787,
                -0.015295988880097866,
                -0.015047580935060978,
                -0.05559183284640312,
                -0.02063874714076519,
                0.04708639159798622,
                0.003926395438611507,
                0.05147508159279823,
                0.04458098113536835,
                0.014328232035040855,
                -0.034334760159254074,
                0.008785358630120754,
                -0.016260424628853798,
                0.026561740785837173,
                -0.033597782254219055,
                -0.05387907475233078,
                -0.007745189126580954,
                -0.026563281193375587,
                -7.496186249911269e-33,
                -0.02227787859737873,
                0.008122805505990982,
                0.00383149366825819,
                -0.0011770264245569706,
                -0.045859839767217636,
                0.01762503944337368,
                -0.02234637551009655,
                0.02806755341589451,
                -0.05879053473472595,
                -0.012434696778655052,
                -0.025730343535542488,
                -0.06819941103458405,
                0.026116348803043365,
                0.021551497280597687,
                0.032321084290742874,
                -0.0499967597424984,
                0.03968171775341034,
                0.03777335584163666,
                -0.04011370241641998,
                -0.026758048683404922,
                -0.026012839749455452,
                -0.00029016731423325837,
                0.00839630514383316,
                -0.03206358850002289,
                -0.04655641317367554,
                -0.05660758167505264,
                -0.026037734001874924,
                -0.00500396266579628,
                0.015751684084534645,
                0.026142362505197525,
                0.001708257826976478,
                0.020204534754157066,
                -0.0018449740018695593,
                0.018963975831866264,
                -0.0077730147168040276,
                0.04071943461894989,
                -0.010851448401808739,
                -0.02484641969203949,
                0.013966687023639679,
                0.04202647507190704,
                -0.0544862262904644,
                -0.05626649409532547,
                -0.007115315180271864,
                -0.012581409886479378,
                -0.022651009261608124,
                -0.03973975032567978,
                -0.007506207097321749,
                -0.04783236235380173,
                0.02994382753968239,
                0.037907619029283524,
                -0.05507459491491318,
                -0.02466212958097458,
                -0.011744680814445019,
                -0.018126949667930603,
                0.07763947546482086,
                -0.07753702998161316,
                0.016122642904520035,
                -0.004726399201899767,
                -0.031039267778396606,
                0.022386033087968826,
                0.011794431135058403,
                -0.029961952939629555,
                -0.01794765330851078,
                0.03863440081477165,
                0.009937506169080734,
                -0.022272933274507523,
                -0.014282841235399246,
                0.047465212643146515,
                0.03141414746642113,
                -0.017431125044822693,
                -0.023284880444407463,
                0.06374558806419373,
                0.03521313518285751,
                -0.0074868169613182545,
                -0.04386800527572632,
                -0.0478505976498127,
                0.007671650964766741,
                -0.02060719206929207,
                -0.011358741670846939,
                0.038176119327545166,
                -0.005888712592422962,
                0.04521119222044945,
                0.006738404743373394,
                -0.008787278085947037,
                -0.004949577618390322,
                -0.0646548718214035,
                -0.03574361279606819,
                0.02141617238521576,
                -0.009804356843233109,
                0.049760784953832626,
                -0.0018341769464313984,
                -0.0031623446848243475,
                0.004559526685625315,
                -0.023028042167425156,
                -0.008288892917335033,
                -0.07285720854997635,
                0.001845446415245533,
                -0.03181445598602295,
                -0.010967034846544266,
                -0.05263066291809082,
                0.04106636345386505,
                -0.012448838911950588,
                -0.03987285867333412,
                0.09048178791999817,
                -0.040284428745508194,
                -0.005003655329346657,
                -0.012807277031242847,
                -0.012113531120121479,
                -0.00330380629748106,
                -0.028276948258280754,
                -0.021299315616488457,
                -0.026554571464657784,
                0.05306774750351906,
                -0.03139973059296608,
                -0.038666173815727234,
                -0.007632899098098278,
                -0.004119759891182184,
                -0.07308536767959595,
                -0.03766940161585808,
                0.007630795240402222,
                0.015635548159480095,
                0.06874337047338486,
                -0.0399392805993557,
                -0.004016477148979902,
                -0.015896955505013466,
                -0.02202068641781807,
                0.03685598447918892,
                0.007143119350075722,
                0.04815863445401192,
                -0.06962797790765762,
                -0.021239859983325005,
                -0.012299242429435253,
                2.565475369920023e-07,
                0.015843689441680908,
                -0.014679309912025928,
                -0.021378953009843826,
                0.03927920013666153,
                0.042099278420209885,
                -0.0044106789864599705,
                -0.012394841760396957,
                0.0726931095123291,
                0.023640966042876244,
                -0.032672978937625885,
                -0.019480878487229347,
                0.006399743724614382,
                -0.022622322663664818,
                -0.03590887039899826,
                0.019121045246720314,
                -0.02094353549182415,
                0.06820317357778549,
                0.048736292868852615,
                -0.01682235486805439,
                -0.0099617475643754,
                0.0342956967651844,
                0.0026906789280474186,
                0.01966281048953533,
                -0.006790459621697664,
                -0.023680325597524643,
                0.0241717342287302,
                -0.01368935126811266,
                -0.014925560913980007,
                0.0751727819442749,
                -0.03107590228319168,
                0.020897142589092255,
                0.019687063992023468,
                0.011400130577385426,
                0.02048710361123085,
                0.013098984025418758,
                -0.06723376363515854,
                0.05200105533003807,
                0.03638676181435585,
                -0.004327533766627312,
                0.05396251752972603,
                -0.0404774472117424,
                -0.009011132642626762,
                -0.019297350198030472,
                -0.005031434819102287,
                0.017674120143055916,
                0.0261135995388031,
                -0.013953382149338722,
                0.04047321155667305,
                -0.06767628341913223,
                -0.006844988092780113,
                0.011851136572659016,
                0.03426028788089752,
                0.003874556627124548,
                0.04903358221054077,
                0.010770623572170734,
                -0.014594700187444687,
                0.037631578743457794,
                0.04454225301742554,
                0.050131164491176605,
                0.05753196403384209,
                -0.03471549227833748,
                -0.015094812959432602,
                -0.005923029966652393,
                0.021720049902796745,
                0.041187629103660583,
                0.0062014637514948845,
                -0.03938328102231026,
                5.979348887802454e-35,
                0.022625425830483437,
                -0.041432324796915054,
                0.036789484322071075,
                0.026403266936540604,
                0.017848823219537735,
                -0.016995493322610855,
                0.05704519525170326,
                -0.023026280105113983,
                0.05637244135141373,
                -0.010829652659595013,
                -0.013216136954724789
            ]
        },
        "Location": "Aberdeen, UK",
        "Remuneration": 62.5,
        "Services Required": {
            "name": "Animation, Video Editing, UI Design, UX Design, Technical Illustration",
            "rep": [
                0.05660988390445709,
                -0.02865804359316826,
                -0.06009332463145256,
                -0.02739040181040764,
                0.03953762352466583,
                -0.0038050205912441015,
                0.06318031996488571,
                0.02805820293724537,
                -0.04227384552359581,
                -0.033382538706064224,
                -0.003484134329482913,
                0.018491903319954872,
                0.030977770686149597,
                0.07707633078098297,
                0.01179500576108694,
                -0.06827796995639801,
                -0.03641295060515404,
                -0.033236660063266754,
                -0.050165917724370956,
                -0.018564734607934952,
                -0.04312605783343315,
                -0.004089080262929201,
                -0.052983783185482025,
                0.028786255046725273,
                -0.0392129011452198,
                -0.0737212672829628,
                0.024465909227728844,
                -0.015496623702347279,
                0.03970106318593025,
                0.05307704582810402,
                -0.0022825852502137423,
                -0.024194911122322083,
                0.014464800246059895,
                -0.014105022884905338,
                1.2202834795971285e-06,
                -0.0009580416954122484,
                0.022961987182497978,
                0.000829207943752408,
                0.0836765468120575,
                0.010379523038864136,
                0.04722599312663078,
                0.05578642711043358,
                0.004916866775602102,
                0.03283696994185448,
                0.0014857936184853315,
                0.03925718739628792,
                0.059215400367975235,
                0.03511189669370651,
                -8.159443677868694e-05,
                -0.007281776051968336,
                -0.03210614621639252,
                -0.05170239135622978,
                -0.030687835067510605,
                -0.03011891432106495,
                -0.0321173220872879,
                0.05165896564722061,
                -0.018178414553403854,
                -0.030477261170744896,
                0.09020748734474182,
                -0.023134592920541763,
                -0.012092828750610352,
                0.019496681168675423,
                -0.00737018883228302,
                -0.006389687769114971,
                0.032302603125572205,
                0.08035993576049805,
                0.008676774799823761,
                -0.04958777129650116,
                0.014685108326375484,
                -0.010729065164923668,
                -0.008743072859942913,
                0.035619258880615234,
                -0.003244820749387145,
                0.007164535578340292,
                0.04070000723004341,
                0.051498424261808395,
                -0.04184525087475777,
                0.02644813060760498,
                -0.012805269099771976,
                -0.04604894295334816,
                -0.018295451998710632,
                0.04069603607058525,
                -0.02448245882987976,
                -0.04167388752102852,
                0.08324190974235535,
                -0.02483093924820423,
                0.04252282530069351,
                0.03918895870447159,
                -0.018461432307958603,
                -0.015682781115174294,
                0.0460103340446949,
                -0.01825712062418461,
                0.004650201182812452,
                0.009243447333574295,
                0.022256851196289062,
                -0.00924554094672203,
                0.01202271319925785,
                0.002087213099002838,
                -0.035147350281476974,
                0.007411477155983448,
                0.03401967138051987,
                -0.011898080818355083,
                -0.0005507714813575149,
                0.04124007001519203,
                -0.014613279141485691,
                0.004935656674206257,
                -0.04279220849275589,
                -0.016838328912854195,
                0.04546460509300232,
                0.04198033735156059,
                -0.06079982966184616,
                -0.05621340125799179,
                -0.05180004984140396,
                0.03342687711119652,
                -0.06884587556123734,
                0.05635460838675499,
                0.009017528966069221,
                0.0512428879737854,
                -0.03245232626795769,
                -0.003987732343375683,
                0.074098601937294,
                0.07299788296222687,
                -0.025766858831048012,
                0.05879456549882889,
                -0.026172228157520294,
                0.028566930443048477,
                -0.034815337508916855,
                0.06774992495775223,
                0.025382669642567635,
                0.029089201241731644,
                0.01177780982106924,
                -0.018068360164761543,
                -0.001252164482139051,
                -0.025776363909244537,
                0.01216580905020237,
                -0.014736969023942947,
                -0.025390692055225372,
                0.0740664154291153,
                0.008806434459984303,
                -0.02317444048821926,
                -0.0026119984686374664,
                -0.013614839874207973,
                0.003849180880934,
                0.02924782782793045,
                -0.03869805485010147,
                -0.008636902086436749,
                0.020677847787737846,
                -0.02139364555478096,
                0.0015955411363393068,
                0.02001708187162876,
                0.01469090860337019,
                -0.028574137017130852,
                0.05084846168756485,
                -0.006824555341154337,
                0.029754968360066414,
                0.020692715421319008,
                0.029270410537719727,
                -0.007232653442770243,
                0.019567053765058517,
                0.08483457565307617,
                -0.007247207220643759,
                -0.01718275062739849,
                0.05734457075595856,
                0.02197098918259144,
                -0.06072968617081642,
                0.004506350494921207,
                -0.0241903867572546,
                -0.02353203296661377,
                -0.06202046200633049,
                -0.0059120203368365765,
                -0.013663046061992645,
                -0.02384025603532791,
                -0.02195730246603489,
                0.00910876039415598,
                0.0043093543499708176,
                -0.008537394925951958,
                0.03017757087945938,
                0.0039944127202034,
                0.0010196666698902845,
                -0.03743273392319679,
                0.01253934483975172,
                -0.04824966937303543,
                0.06339351087808609,
                0.08129040151834488,
                0.02071288414299488,
                0.0018906552577391267,
                -0.048716552555561066,
                0.022354112938046455,
                -0.06199208274483681,
                -0.013107500039041042,
                0.03771577402949333,
                0.007090427912771702,
                -0.03767796605825424,
                0.015572960488498211,
                0.004961296450346708,
                0.02653820440173149,
                -0.04662696644663811,
                -0.051993269473314285,
                -0.07550550997257233,
                -0.0405559279024601,
                0.014552555046975613,
                0.02850540354847908,
                -0.03512141481041908,
                0.05365321412682533,
                -0.014313017949461937,
                -0.0161591786891222,
                -0.0381975993514061,
                -0.041883960366249084,
                0.03598958998918533,
                0.03955554962158203,
                0.00883649755269289,
                -0.02491804212331772,
                0.010356108658015728,
                0.06110217422246933,
                0.02298205904662609,
                -0.07598553597927094,
                0.041534557938575745,
                -0.00926500465720892,
                -0.06263130158185959,
                0.02639504335820675,
                -0.026178676635026932,
                0.02210136316716671,
                -0.03155048191547394,
                -0.0178346149623394,
                -0.015929128974676132,
                0.004618803970515728,
                0.008728893473744392,
                -0.021347874775528908,
                0.006524931639432907,
                0.046419788151979446,
                -0.05005809664726257,
                -0.0075209797360002995,
                0.027713309973478317,
                -0.030242597684264183,
                0.03601019084453583,
                0.0204511396586895,
                0.0927978903055191,
                -0.0031851103994995356,
                0.003472324926406145,
                -0.07374697923660278,
                -0.03846713900566101,
                -0.012228427454829216,
                -0.013362649828195572,
                0.0052993204444646835,
                0.039845358580350876,
                -0.015655331313610077,
                -0.013102136552333832,
                0.05340040475130081,
                -0.011308734305202961,
                0.010639636777341366,
                -0.048684414476156235,
                0.00706976605579257,
                -0.0005419188528321683,
                0.0386342853307724,
                0.03218947723507881,
                -0.002206012373790145,
                -0.0014021153328940272,
                0.04613427817821503,
                -0.021705836057662964,
                -0.07005646824836731,
                0.036365311592817307,
                -0.057772330939769745,
                0.020319310948252678,
                0.004528192337602377,
                -0.025672748684883118,
                -0.02039157599210739,
                0.03404397517442703,
                -0.0371025986969471,
                -0.05609263852238655,
                0.026096200570464134,
                -0.013848534785211086,
                0.0021762135438621044,
                0.005509655922651291,
                -0.02801048569381237,
                -0.0071648540906608105,
                0.017641836777329445,
                -0.05969289317727089,
                -0.011078856885433197,
                -0.016254262998700142,
                0.02717619575560093,
                0.016099780797958374,
                -0.007428973913192749,
                0.01171249058097601,
                0.0005729120457544923,
                -0.03126002848148346,
                -0.0023865741677582264,
                0.06397013366222382,
                -0.07998622208833694,
                0.028011171147227287,
                0.026759620755910873,
                -0.017896652221679688,
                0.001715157413855195,
                0.01022460125386715,
                -0.07992631942033768,
                -0.038922253996133804,
                0.08055052161216736,
                -0.037654947489500046,
                -0.0025176815688610077,
                -0.016671404242515564,
                0.007805998437106609,
                -0.042044952511787415,
                -0.05338998883962631,
                0.07218693941831589,
                0.050443556159734726,
                -0.006769190542399883,
                0.010707604698836803,
                0.006553403101861477,
                0.034925058484077454,
                0.028056764975190163,
                0.01014637853950262,
                -0.012502884492278099,
                0.004858980420976877,
                -0.01703040488064289,
                0.02781006321310997,
                0.030565854161977768,
                0.012942050583660603,
                0.025793174281716347,
                0.009131243452429771,
                0.032016322016716,
                0.03822413831949234,
                -0.007082158699631691,
                0.04387223720550537,
                -0.009371057152748108,
                -0.00833950750529766,
                -0.020262766629457474,
                0.057239219546318054,
                0.000973148678895086,
                -0.010984686203300953,
                0.00408162223175168,
                -0.058446452021598816,
                0.03796973451972008,
                -0.008069793693721294,
                -0.051783181726932526,
                -0.015429586172103882,
                0.02089420147240162,
                0.03255743533372879,
                -0.04573575407266617,
                -0.07979695498943329,
                0.009411332197487354,
                -0.03778529912233353,
                -0.022037001326680183,
                -0.02890441194176674,
                -0.0492706373333931,
                0.03741040453314781,
                0.028028959408402443,
                -0.027107153087854385,
                -0.018047625198960304,
                -0.0260220468044281,
                -0.03670164942741394,
                -0.016881627961993217,
                0.0023200286086648703,
                0.016693810001015663,
                0.0005175818223506212,
                0.03470272198319435,
                -0.022909244522452354,
                0.044061046093702316,
                0.10031449049711227,
                0.028241269290447235,
                -0.1619109809398651,
                -0.005933817941695452,
                -0.01246196124702692,
                -0.01167645025998354,
                -0.04021443799138069,
                -0.021718289703130722,
                -0.022092603147029877,
                0.03157412260770798,
                0.04630580171942711,
                -0.054636400192976,
                -0.03283673897385597,
                0.0038592128548771143,
                0.03844881057739258,
                0.02167791686952114,
                0.026937691494822502,
                0.015091526322066784,
                0.014876502566039562,
                0.03886035829782486,
                0.015414663590490818,
                0.05086164176464081,
                -0.08829555660486221,
                0.04030054807662964,
                0.06978483498096466,
                -0.011386080645024776,
                0.048318490386009216,
                -0.05763968080282211,
                -0.01787964627146721,
                -0.03614480420947075,
                0.000694722926709801,
                0.03402937576174736,
                -0.014021608047187328,
                0.07228671759366989,
                0.00541972741484642,
                0.011719565838575363,
                -0.05230735242366791,
                -0.012145508080720901,
                0.03453955054283142,
                0.01740846037864685,
                -0.044413983821868896,
                -0.029955297708511353,
                -0.024688757956027985,
                0.006863239221274853,
                0.04345210641622543,
                -0.032525256276130676,
                -0.0457574687898159,
                0.01696542650461197,
                0.01800680160522461,
                -0.021839169785380363,
                0.05006745830178261,
                0.017229560762643814,
                -0.05083470046520233,
                -0.025183020159602165,
                -0.019341932609677315,
                0.057404693216085434,
                0.023943286389112473,
                0.0036467404570430517,
                -0.014885473996400833,
                -0.014643225818872452,
                0.01106356643140316,
                -0.011217937804758549,
                0.016544418409466743,
                0.014344555325806141,
                0.007601623889058828,
                -0.02211923897266388,
                0.02469196356832981,
                -0.05415886268019676,
                0.015525402501225471,
                -0.05992080271244049,
                -0.05046102777123451,
                0.005326328333467245,
                -0.03581548482179642,
                -0.0381709486246109,
                -0.05645972117781639,
                -0.0011093857465311885,
                -0.01310334075242281,
                0.018637141212821007,
                -0.04080096632242203,
                -0.01519753783941269,
                -0.06169777363538742,
                0.017649373039603233,
                -0.03863303363323212,
                -0.02367602474987507,
                0.04299847409129143,
                0.04110186547040939,
                0.05864772945642471,
                0.014964357018470764,
                0.009585605002939701,
                0.04266169294714928,
                0.018910599872469902,
                -0.060362692922353745,
                -0.03054485283792019,
                0.030183225870132446,
                0.014291876927018166,
                0.03747980669140816,
                -0.014856862835586071,
                -0.043635234236717224,
                -0.03833158314228058,
                -0.03895919397473335,
                -0.0387173667550087,
                0.007412520702928305,
                0.00657774368301034,
                -0.06514324247837067,
                0.0625462606549263,
                0.011630839668214321,
                -0.017433904111385345,
                0.019572779536247253,
                -0.03747357055544853,
                -0.0012591744307428598,
                0.06575797498226166,
                0.09034837782382965,
                0.015271068550646305,
                -0.02791142649948597,
                -0.031622882932424545,
                0.01962844468653202,
                -0.06339730322360992,
                -0.048646487295627594,
                -0.04276072233915329,
                0.032869283109903336,
                0.04209889844059944,
                0.060947030782699585,
                0.022651152685284615,
                -0.004746252205222845,
                -0.010893906466662884,
                -0.012995636090636253,
                -0.014909975230693817,
                0.0020630373619496822,
                -0.012213855981826782,
                0.029908375814557076,
                0.056571803987026215,
                -0.003089213278144598,
                -0.02829870395362377,
                -0.018780875951051712,
                0.036518409848213196,
                -0.029975270852446556,
                0.006076122634112835,
                0.030071306973695755,
                0.04188814386725426,
                -0.003035820322111249,
                -0.028601139783859253,
                -0.015330771915614605,
                -0.04412133991718292,
                0.08783914148807526,
                -0.04587635397911072,
                0.010034367442131042,
                0.013087907806038857,
                0.02289167232811451,
                -0.00898147001862526,
                0.03458157554268837,
                0.005097324959933758,
                0.03250877559185028,
                -0.015610730275511742,
                0.0559527650475502,
                0.023664463311433792,
                0.03808647021651268,
                0.03774566948413849,
                0.04879820719361305,
                -0.023223906755447388,
                0.04000013694167137,
                0.0946318507194519,
                -0.03601912781596184,
                -0.007260877173393965,
                0.0049500311724841595,
                0.011229688301682472,
                -0.04044312983751297,
                0.03061823546886444,
                -0.011827226728200912,
                -0.03046860359609127,
                -0.08943135291337967,
                -0.020394342020154,
                0.024573199450969696,
                -0.018008090555667877,
                0.04878100007772446,
                -0.025003861635923386,
                -0.018064212054014206,
                -0.01208823174238205,
                0.008516495116055012,
                0.028027638792991638,
                0.06710784137248993,
                0.005937265697866678,
                0.005428468808531761,
                0.0526687316596508,
                0.06172231212258339,
                -0.027083352208137512,
                -0.06862954050302505,
                0.05293819308280945,
                -0.06059684604406357,
                -0.029308918863534927,
                -0.025984155014157295,
                0.009092995896935463,
                -0.007850425317883492,
                -0.011363048106431961,
                0.05813128501176834,
                -0.03161849081516266,
                -0.06368987262248993,
                -0.0026110366452485323,
                0.014877388253808022,
                -0.02367483638226986,
                -4.340185956044974e-33,
                -0.012231467291712761,
                -0.01371218916028738,
                0.08860771358013153,
                0.0380350761115551,
                -0.0053727393969893456,
                -0.031546931713819504,
                0.04074949771165848,
                0.015538111329078674,
                -0.025997081771492958,
                -0.0012257358757779002,
                -0.02542869560420513,
                -0.04849929362535477,
                -0.016037581488490105,
                0.02643391489982605,
                0.0009888209169730544,
                0.03539072722196579,
                0.04768563434481621,
                0.04804886132478714,
                0.0020040678791701794,
                0.005858789663761854,
                -0.09044679999351501,
                -0.012902792543172836,
                0.045363206416368484,
                -0.018652494996786118,
                -0.0513937808573246,
                0.015828033909201622,
                0.00035616717650555074,
                -0.06441599875688553,
                0.025946324691176414,
                -0.00814357865601778,
                0.03115800768136978,
                0.002123290440067649,
                -0.006788844708353281,
                0.019873054698109627,
                0.03816748037934303,
                -0.051180534064769745,
                -0.007649948820471764,
                -0.023585190996527672,
                0.015942228958010674,
                0.02230469509959221,
                0.030657926574349403,
                -0.02808968909084797,
                -0.022186340764164925,
                0.004595302511006594,
                -0.038218919187784195,
                -0.05628690868616104,
                0.021117348223924637,
                0.030728831887245178,
                0.03570186719298363,
                0.004568565171211958,
                -0.04197390004992485,
                -0.005185915157198906,
                -0.04830460995435715,
                0.0016391656827181578,
                -0.022066500037908554,
                -0.013467838056385517,
                -0.020786847919225693,
                -0.025675110518932343,
                -0.0736960843205452,
                0.029714500531554222,
                -0.014695435762405396,
                0.048005301505327225,
                0.09125737100839615,
                -0.06140456348657608,
                0.008732608519494534,
                0.040440633893013,
                -0.030971776694059372,
                0.041217610239982605,
                -0.01645630970597267,
                -0.021275227889418602,
                -0.0457664392888546,
                0.11797750741243362,
                -0.04465925320982933,
                -0.020208138972520828,
                -0.038069140166044235,
                -0.0448785126209259,
                0.0460556335747242,
                -0.008171816356480122,
                -0.03559485450387001,
                0.03379315882921219,
                -0.0143863121047616,
                0.04115322604775429,
                0.03885052353143692,
                -0.018355872482061386,
                0.029202615842223167,
                -0.019696341827511787,
                0.017444737255573273,
                0.012349484488368034,
                0.05643909052014351,
                -0.0005685009527951479,
                0.07203547656536102,
                0.02042328380048275,
                -0.012512118555605412,
                -0.015471179038286209,
                0.006202786695212126,
                -0.0192506592720747,
                -0.004882811568677425,
                0.019390109926462173,
                0.021203631535172462,
                0.0525386780500412,
                0.023584945127367973,
                -0.002130958717316389,
                7.181332330219448e-05,
                -0.002426753519102931,
                0.005154427140951157,
                0.009801134467124939,
                -0.04791804775595665,
                0.015160215087234974,
                0.0031929544638842344,
                -0.01434006355702877,
                0.022596163675189018,
                0.03672165051102638,
                0.012924015522003174,
                0.006310213822871447,
                0.021310850977897644,
                0.010907583869993687,
                -0.024314062669873238,
                -0.05916336178779602,
                -0.05027574300765991,
                -0.0017455165507271886,
                0.008139335550367832,
                -0.06502413004636765,
                -0.053490400314331055,
                -0.03241480141878128,
                -0.024645302444696426,
                -0.013972359709441662,
                0.05424821749329567,
                0.003228981513530016,
                -0.043177083134651184,
                -0.036327261477708817,
                -0.03860469162464142,
                -0.0026527910958975554,
                1.9772076598201238e-07,
                -0.03121352754533291,
                0.031371913850307465,
                -0.01263345219194889,
                0.04450492933392525,
                -0.0021828841418027878,
                -0.03336253762245178,
                -0.009419486857950687,
                0.008076101541519165,
                0.03278648853302002,
                0.04488487169146538,
                -0.02217967063188553,
                -0.03353697061538696,
                -0.013185089454054832,
                -0.0667451024055481,
                -0.023691678419709206,
                -0.06974226236343384,
                -0.025312790647149086,
                0.04004039242863655,
                -0.015852872282266617,
                0.05832120031118393,
                0.0011460290988907218,
                0.009482720866799355,
                0.024091022089123726,
                -0.01238271128386259,
                -0.018328344449400902,
                -0.02420436404645443,
                0.03483422473073006,
                0.03499583154916763,
                0.06528124958276749,
                0.01674177311360836,
                -0.018846584483981133,
                0.03577147424221039,
                0.03002062812447548,
                -0.02163849025964737,
                0.013355334289371967,
                -0.017297327518463135,
                0.06547923386096954,
                -0.021251680329442024,
                -0.023455701768398285,
                0.06849221140146255,
                -0.016402985900640488,
                0.07818794250488281,
                -0.04080057144165039,
                -0.004820727743208408,
                -0.010616540908813477,
                0.013900122605264187,
                -0.003724951995536685,
                -0.017612753435969353,
                -0.0352029912173748,
                0.005147853400558233,
                -0.052679356187582016,
                0.011108935810625553,
                0.03697891905903816,
                -0.039338938891887665,
                0.016142548993229866,
                -0.015162261202931404,
                -0.06518333405256271,
                0.02750091440975666,
                0.029885154217481613,
                -0.0031862822361290455,
                -0.051181260496377945,
                -0.011832737363874912,
                -0.019056331366300583,
                0.03875565901398659,
                0.068198561668396,
                0.038468267768621445,
                -0.03259200602769852,
                9.870281680744156e-35,
                -0.02013370208442211,
                0.005549517460167408,
                -0.015982909128069878,
                0.04886455833911896,
                0.015390238724648952,
                -0.007668993901461363,
                -0.009158620610833168,
                0.0015351169276982546,
                -0.009445899166166782,
                0.027234740555286407,
                -0.03469130024313927
            ]
        },
        "Skills": {
            "name": [
                "CSS",
                "Computing",
                "Concepts Design",
                "Data Presentation",
                "Customer Service",
                "HTML",
                "REACT",
                "Teamwork",
                "Brainstorming",
                "Empathy",
                "JavaScript"
            ],
            "rep": [
                0.009525693953037262,
                -0.05485057458281517,
                -0.03649722784757614,
                -0.019954310730099678,
                -0.012971192598342896,
                0.02049221284687519,
                0.040536943823099136,
                0.06207513436675072,
                -0.0041640023700892925,
                -0.006729112472385168,
                0.05134101212024689,
                -0.07672952860593796,
                0.04626317694783211,
                0.013109298422932625,
                0.0344790518283844,
                -0.0582595095038414,
                -0.0005328922416083515,
                0.0003764007124118507,
                -0.017543548718094826,
                0.03413545340299606,
                0.015263109467923641,
                0.03738757595419884,
                -0.03753340616822243,
                -0.06507626920938492,
                0.012145431712269783,
                -0.02576533332467079,
                -0.01567046530544758,
                0.04667802155017853,
                -0.062262795865535736,
                0.004941177554428577,
                0.03910808637738228,
                0.06187145411968231,
                -0.041603196412324905,
                -0.027364380657672882,
                1.3613903320219833e-06,
                0.0226482842117548,
                -0.013737872242927551,
                -0.028953708708286285,
                0.03117218054831028,
                0.06863511353731155,
                0.01784016378223896,
                0.031006069853901863,
                -0.03178151324391365,
                -0.0009031460504047573,
                0.004667527507990599,
                -0.011759337037801743,
                0.04312216490507126,
                0.07778770476579666,
                -0.020921796560287476,
                -0.0067620836198329926,
                0.027082616463303566,
                -0.07334747165441513,
                -0.06712423264980316,
                0.011502942070364952,
                0.041045352816581726,
                -0.008581853471696377,
                -0.009423991665244102,
                0.028494058176875114,
                -0.03503289073705673,
                0.010692602954804897,
                0.01141633652150631,
                0.013041771948337555,
                -0.06597167998552322,
                -0.02231004647910595,
                -0.0121059725061059,
                0.0355633907020092,
                -0.02194618619978428,
                -0.0060601369477808475,
                0.006257576402276754,
                0.028374947607517242,
                0.04549785703420639,
                0.0015055978437885642,
                -0.025053635239601135,
                0.0621623732149601,
                -0.056429117918014526,
                -0.016628798097372055,
                -0.06050074100494385,
                0.03615335002541542,
                0.0438317246735096,
                0.001368348253890872,
                -0.06382658332586288,
                -0.0118631049990654,
                0.002652732189744711,
                0.04596274346113205,
                0.02968789078295231,
                -0.034177787601947784,
                0.019862743094563484,
                -0.05559152737259865,
                -0.028639398515224457,
                0.018993210047483444,
                0.04775141924619675,
                -0.06120732054114342,
                0.009611600078642368,
                -0.014247477985918522,
                -0.003103665541857481,
                -0.019703522324562073,
                -0.0014879879308864474,
                0.01803092658519745,
                0.007704298477619886,
                0.0757913812994957,
                0.02707560919225216,
                0.02096710354089737,
                0.004070836119353771,
                -0.03900677710771561,
                -0.02598484419286251,
                0.011211521923542023,
                0.03922411799430847,
                -0.0341288298368454,
                0.009435555897653103,
                0.08874054998159409,
                0.04321230947971344,
                -0.00029740348691120744,
                0.0010708140907809138,
                -0.011893220245838165,
                -0.014975671656429768,
                0.04421446472406387,
                0.020717235282063484,
                -0.02517903968691826,
                -0.0006104793283157051,
                0.038954898715019226,
                0.042624182999134064,
                0.010163404047489166,
                0.001036162255331874,
                0.005634058266878128,
                0.0012273350730538368,
                -0.0023336478043347597,
                0.010927124880254269,
                0.019491439685225487,
                0.022502640262246132,
                -0.005171759054064751,
                0.009180103428661823,
                -0.0020780032500624657,
                -0.021190747618675232,
                0.0072156330570578575,
                0.005913148634135723,
                0.013612758368253708,
                -0.03694669529795647,
                0.03632553294301033,
                0.019076066091656685,
                0.05785791203379631,
                0.0295322947204113,
                0.05721358582377434,
                -0.04091660678386688,
                0.010818585753440857,
                -0.03552902862429619,
                0.01946149580180645,
                0.009341610595583916,
                0.006223468109965324,
                0.02237769588828087,
                0.028695104643702507,
                -0.014579559676349163,
                0.003503567073494196,
                -0.0213907640427351,
                -0.002821205649524927,
                0.04662169888615608,
                -0.03845004737377167,
                -0.030663536861538887,
                -0.016596777364611626,
                0.017161427065730095,
                0.03515581414103508,
                0.07132776081562042,
                -0.0052146026864647865,
                -0.05623496323823929,
                -0.04044003784656525,
                -0.03282679244875908,
                0.043263208121061325,
                -0.04540108144283295,
                -0.042247988283634186,
                0.026527179405093193,
                0.023355355486273766,
                -0.01069564651697874,
                0.041281431913375854,
                -0.002910459414124489,
                -0.020511144772171974,
                0.06418827176094055,
                -0.01637573353946209,
                0.030414007604122162,
                0.06255429983139038,
                -0.01808643341064453,
                0.0007747138733975589,
                0.022087084129452705,
                -0.0048343101516366005,
                -0.030127426609396935,
                -0.05242926999926567,
                -0.07936830818653107,
                0.05700666457414627,
                -0.06150885298848152,
                0.0319984070956707,
                -0.05096021667122841,
                -0.004302158951759338,
                -0.045573875308036804,
                0.002727446611970663,
                0.02543051913380623,
                0.018856411799788475,
                0.0033911617938429117,
                -0.008887609466910362,
                -0.0337594710290432,
                -0.03271765261888504,
                0.02975541166961193,
                -0.0421750582754612,
                0.007459849119186401,
                -0.022957714274525642,
                0.026878932490944862,
                0.0827939510345459,
                -0.009848392568528652,
                -0.009617583826184273,
                0.031682007014751434,
                -0.0284426286816597,
                0.07030493766069412,
                0.02341924048960209,
                0.014417487196624279,
                0.016014140099287033,
                -0.04245036840438843,
                -0.03306891396641731,
                -0.04078330844640732,
                -0.019234461709856987,
                -0.025239553302526474,
                -0.03910328075289726,
                -0.04928414896130562,
                -0.016151607036590576,
                0.04333095997571945,
                -0.0018742497777566314,
                0.03119027055799961,
                0.04545779526233673,
                0.03778643533587456,
                0.0027180896140635014,
                -0.06480884552001953,
                0.010127157904207706,
                -0.04710257798433304,
                -0.011486620642244816,
                -0.03109525702893734,
                0.04387858137488365,
                -0.03538034111261368,
                0.0009888348868116736,
                0.008584338240325451,
                0.002701226156204939,
                0.04800458252429962,
                -0.00018003123113885522,
                0.010244528762996197,
                -0.07118578255176544,
                0.07978200912475586,
                0.04958897829055786,
                -0.023993033915758133,
                0.05048180744051933,
                0.046959757804870605,
                -0.048182643949985504,
                -0.010952411219477654,
                -0.027581261470913887,
                -0.05948682874441147,
                -0.008355221711099148,
                -0.07079774141311646,
                0.00456333439797163,
                -0.008843114599585533,
                0.02279186062514782,
                0.01115399319678545,
                0.022698450833559036,
                0.020266462117433548,
                0.0051896413788199425,
                -0.023737911134958267,
                0.010295436717569828,
                -0.00986362062394619,
                -0.026216579601168633,
                0.013993249274790287,
                0.03940320387482643,
                0.025692051276564598,
                0.012817948125302792,
                0.0030210837721824646,
                -0.015454339794814587,
                0.0057100411504507065,
                -0.01065958570688963,
                0.030360786244273186,
                0.0035409636329859495,
                0.018869614228606224,
                -0.0393502414226532,
                -0.0059672072529792786,
                0.015222959220409393,
                -0.02371235005557537,
                -0.048483721911907196,
                -0.022597692906856537,
                -0.007270910311490297,
                0.039678119122982025,
                -0.03554081544280052,
                -0.11192511767148972,
                -0.05769728869199753,
                0.040039803832769394,
                0.031434010714292526,
                -0.032985869795084,
                0.018351659178733826,
                -0.004715141840279102,
                0.01015226449817419,
                -0.035100143402814865,
                -0.00014732859563082457,
                0.0693211704492569,
                -0.06153349205851555,
                0.006617103237658739,
                -0.01696239970624447,
                -0.03396354988217354,
                0.055741086602211,
                0.08129177242517471,
                0.00752011826261878,
                -6.245607073651627e-05,
                0.009187649935483932,
                0.0005228611989878118,
                -0.008067265152931213,
                -0.05109529197216034,
                -0.0161842443048954,
                -0.025151336565613747,
                -0.00780778331682086,
                0.02831198275089264,
                0.02369505539536476,
                0.03396490961313248,
                -0.015309358946979046,
                -0.005487137008458376,
                0.004065325949341059,
                -0.007987961173057556,
                0.048909351229667664,
                0.02233453094959259,
                0.05565235763788223,
                -0.0026623778976500034,
                0.05700023099780083,
                0.02371331676840782,
                0.013140001334249973,
                -0.029047079384326935,
                -0.03405590355396271,
                0.006146008148789406,
                0.06372697651386261,
                -0.01659826561808586,
                0.013697133399546146,
                -0.061511050909757614,
                -0.026132851839065552,
                0.009190266951918602,
                0.046240583062171936,
                -0.023732777684926987,
                -0.004747218452394009,
                -0.013541916385293007,
                0.05235239863395691,
                -0.0460297092795372,
                -0.056211017072200775,
                0.010144143365323544,
                -0.020625699311494827,
                0.018098538741469383,
                -0.0412677638232708,
                -0.010569717735052109,
                -0.029080910608172417,
                -0.023831497877836227,
                0.015997281298041344,
                0.008241134695708752,
                0.017205407842993736,
                -0.0030729107093065977,
                0.0401378870010376,
                0.032034099102020264,
                -0.00985661055892706,
                -0.002791199367493391,
                0.008314838632941246,
                -0.02420812100172043,
                0.03225042670965195,
                -0.03915506228804588,
                -0.05231735482811928,
                0.022922711446881294,
                -0.029629845172166824,
                -0.08367153257131577,
                0.04709664732217789,
                0.09732332825660706,
                -0.0036629941314458847,
                -0.025178784504532814,
                0.0003380158159416169,
                0.021037165075540543,
                -0.004139272961765528,
                -0.029814252629876137,
                -0.005991052370518446,
                -0.0023255296982824802,
                0.05745411291718483,
                0.04289477691054344,
                0.10553903132677078,
                -0.026166675612330437,
                0.04431682080030441,
                0.0077764843590557575,
                0.03282894194126129,
                -0.007326237391680479,
                0.02645040489733219,
                0.01856200583279133,
                0.011813707649707794,
                -0.026348529383540154,
                0.03430326655507088,
                -0.02673911862075329,
                0.05388512462377548,
                0.006729526910930872,
                0.030037621036171913,
                0.05411466583609581,
                -0.03244516998529434,
                0.0023379605263471603,
                -0.0006113877752795815,
                -0.057105228304862976,
                0.03552331030368805,
                -0.012631253339350224,
                0.01580314338207245,
                0.024947568774223328,
                -0.03256521746516228,
                -0.03268136456608772,
                0.07345253229141235,
                0.029607171192765236,
                -0.017316626384854317,
                -0.0020475846249610186,
                -0.0032535854261368513,
                -0.006654154043644667,
                0.036101482808589935,
                0.02503601834177971,
                -0.009504305198788643,
                0.0170152485370636,
                -0.12215189635753632,
                -0.005745300557464361,
                -0.09350074082612991,
                0.02393786422908306,
                0.012920999899506569,
                -0.03044564463198185,
                -0.02791302464902401,
                0.00033594470005482435,
                -0.05874257907271385,
                -0.06042657420039177,
                0.04584597796201706,
                0.0018329715821892023,
                0.005293309222906828,
                -0.003687445307150483,
                -0.003484007203951478,
                -0.05713559314608574,
                -0.006176802329719067,
                0.04275207966566086,
                0.010995973832905293,
                0.01707296259701252,
                0.0014623109018430114,
                -0.01814757101237774,
                -0.00862106028944254,
                -0.1042531207203865,
                0.05770323425531387,
                0.012850643135607243,
                -0.029364673420786858,
                0.016157662495970726,
                -0.0371624156832695,
                -0.005365242715924978,
                -0.03560302034020424,
                0.03675362095236778,
                -0.032732099294662476,
                0.026233302429318428,
                0.00032967759761959314,
                -0.04080776497721672,
                -0.023293200880289078,
                0.04201607406139374,
                0.02289305441081524,
                -0.06975911557674408,
                -0.004046312533318996,
                0.053697630763053894,
                0.04941757023334503,
                -0.030186481773853302,
                0.009311401285231113,
                -0.047047875821590424,
                -0.0007782280445098877,
                -0.05076151713728905,
                0.015878256410360336,
                0.01312035322189331,
                -0.057308848947286606,
                -0.010987524874508381,
                0.02438049577176571,
                -0.06861016154289246,
                0.0024372839834541082,
                -0.061463821679353714,
                -0.03458981588482857,
                0.02114029973745346,
                -0.04190879687666893,
                -0.004506258759647608,
                -0.0496942438185215,
                -0.08610251545906067,
                0.08493350446224213,
                0.007289367262274027,
                0.021107438951730728,
                0.012754392810165882,
                -0.0018595049623399973,
                -0.013609006069600582,
                -0.028285829350352287,
                0.0510910227894783,
                -0.0035355519503355026,
                -0.04942120984196663,
                0.00502070551738143,
                -0.042524777352809906,
                0.039221931248903275,
                0.022547496482729912,
                0.024900637567043304,
                -0.01679927669465542,
                0.00017223313625436276,
                -0.00223612692207098,
                -0.0038875825703144073,
                0.0036904937587678432,
                0.01099911518394947,
                -0.018703434616327286,
                0.015804771333932877,
                0.013861371204257011,
                -0.039624180644750595,
                -0.06559979915618896,
                -0.0278952457010746,
                0.02152368053793907,
                -0.01192383747547865,
                -0.006157913710922003,
                0.016777198761701584,
                -0.004183802753686905,
                0.039027951657772064,
                -0.03569111227989197,
                -0.047873154282569885,
                -0.01638047955930233,
                -0.043483518064022064,
                0.014721914194524288,
                -0.02698196843266487,
                -0.018705042079091072,
                0.005005786661058664,
                0.0014218835858628154,
                0.01975046843290329,
                0.05940839275717735,
                0.05312832444906235,
                -0.011696233414113522,
                -0.05387791991233826,
                -0.06370586156845093,
                0.11045074462890625,
                0.033435434103012085,
                -0.0026776602026075125,
                0.056907862424850464,
                0.02954002097249031,
                -0.014995568431913853,
                -0.035463716834783554,
                0.03421524167060852,
                -0.013806362636387348,
                0.00047033611917868257,
                0.04356285184621811,
                0.0003160086052957922,
                -0.02381320670247078,
                0.027603691443800926,
                -0.02204045094549656,
                0.014097028411924839,
                0.09485473483800888,
                -0.006462948862463236,
                0.04937748238444328,
                0.03797154873609543,
                -0.020339282229542732,
                0.028530094772577286,
                -0.04804885387420654,
                0.02139076590538025,
                0.03362439200282097,
                0.03001238964498043,
                0.03993606939911842,
                0.032118652015924454,
                0.044105060398578644,
                -0.03022536262869835,
                0.0063234600238502026,
                0.007397918961942196,
                0.03166259080171585,
                -0.09900326281785965,
                0.002365702763199806,
                -0.013835897669196129,
                -5.5575925579458303e-33,
                -0.006313267629593611,
                0.017338398844003677,
                -0.06444553285837173,
                0.09280718117952347,
                -0.06798230856657028,
                -0.02073480747640133,
                0.035944875329732895,
                0.010269313119351864,
                -0.028454575687646866,
                0.04579716920852661,
                -0.04922884330153465,
                0.01677657850086689,
                0.03289802744984627,
                0.02193831466138363,
                0.011885689571499825,
                -0.03236290067434311,
                0.024196140468120575,
                -0.004039239138364792,
                0.02334200218319893,
                -0.039652105420827866,
                -0.057403650134801865,
                -0.001497653778642416,
                0.03504447638988495,
                -0.003536105854436755,
                -0.07997985929250717,
                -0.05291217565536499,
                -0.019300732761621475,
                -0.05330846458673477,
                0.05631620064377785,
                -0.0007429506513290107,
                0.03198893368244171,
                0.007726320531219244,
                -0.030948368832468987,
                0.03821221739053726,
                0.018009742721915245,
                0.05575912073254585,
                -0.01540331356227398,
                -0.05160226300358772,
                0.038039542734622955,
                0.04045926779508591,
                -0.027583014219999313,
                0.0025016276631504297,
                0.05405329912900925,
                -0.029965020716190338,
                -0.0059220572002232075,
                -0.05827999487519264,
                0.018393008038401604,
                -0.02848241664469242,
                0.0028964639641344547,
                -0.053047336637973785,
                0.025566844269633293,
                0.0052979192696511745,
                -0.0443563349545002,
                0.011743578128516674,
                0.015436502173542976,
                -0.035900890827178955,
                0.007800784427672625,
                -0.02930893748998642,
                0.01862873136997223,
                0.011885111220180988,
                0.006486453115940094,
                -0.019950607791543007,
                -0.0009029585053212941,
                0.0514838770031929,
                -0.031722839921712875,
                0.008262692019343376,
                -0.06633435934782028,
                0.026142543181777,
                -0.033806342631578445,
                0.042094483971595764,
                0.002087007276713848,
                0.03711667284369469,
                -0.030037960037589073,
                -0.010315774008631706,
                -0.057124726474285126,
                -0.04068172723054886,
                -0.032219041138887405,
                0.029648922383785248,
                0.04755370691418648,
                0.04614468663930893,
                -0.027159545570611954,
                0.0784466490149498,
                -0.002794424770399928,
                0.04636996611952782,
                -0.0408271849155426,
                -0.015947647392749786,
                0.0008126738830469549,
                -0.0012717703357338905,
                -0.02576407417654991,
                -0.011725961230695248,
                -0.0056840768083930016,
                0.03545891121029854,
                -0.00023761794727761298,
                -0.01983531005680561,
                -0.04915080964565277,
                0.004597207996994257,
                -0.011220059357583523,
                -0.009221541695296764,
                -0.010708064772188663,
                -0.0847005546092987,
                0.01871352829039097,
                -0.023699941113591194,
                -0.02444927953183651,
                0.01755182072520256,
                -0.0461481548845768,
                -0.01952260546386242,
                0.016854427754878998,
                -0.03362195938825607,
                -0.028166336938738823,
                -0.03662342205643654,
                0.010343578644096851,
                -0.025936955586075783,
                0.03911219909787178,
                -0.012227717787027359,
                -0.01749981753528118,
                0.019528962671756744,
                -0.056797489523887634,
                -0.00889041181653738,
                -0.06654895842075348,
                0.023180218413472176,
                0.014049886725842953,
                -0.07376673072576523,
                0.004678796045482159,
                0.0050507537089288235,
                -0.05076581612229347,
                -0.05540645867586136,
                -0.036884602159261703,
                0.04487968608736992,
                -0.02533283457159996,
                -0.020883582532405853,
                -0.027166025713086128,
                -0.007569365203380585,
                1.9056182054555393e-07,
                0.02700859308242798,
                0.017001202329993248,
                0.08206193894147873,
                0.07194610685110092,
                0.014544292353093624,
                -0.008269524201750755,
                0.015498291701078415,
                -0.004621296655386686,
                0.022267093881964684,
                -0.020744578912854195,
                -0.05796071141958237,
                -0.019729318097233772,
                -0.038535356521606445,
                -0.033464301377534866,
                0.08149798214435577,
                -0.025359775871038437,
                0.04313893988728523,
                -0.02313925139605999,
                0.014836698770523071,
                0.029603254050016403,
                0.008138585835695267,
                -0.03509700670838356,
                0.04838044196367264,
                0.02393069490790367,
                -0.008050499483942986,
                0.011998303234577179,
                0.0008945940644480288,
                -0.028523175045847893,
                0.07306409627199173,
                -0.02698245830833912,
                0.023996438831090927,
                0.010023406706750393,
                0.014379406347870827,
                0.03008364513516426,
                -0.01539684645831585,
                -0.050379782915115356,
                0.05768598988652229,
                -0.008922489359974861,
                0.006567264441400766,
                0.1048601046204567,
                -0.037236880511045456,
                -0.020092729479074478,
                -0.020778870210051537,
                -0.059721216559410095,
                0.08179546892642975,
                0.08280765265226364,
                0.04752499237656593,
                -0.0666549950838089,
                -0.038016654551029205,
                -0.0630480945110321,
                0.032928746193647385,
                0.011720646172761917,
                0.00503305671736598,
                -0.004467595834285021,
                -0.004166968632489443,
                0.001706989947706461,
                0.009895380586385727,
                -0.015916697680950165,
                -0.03824912756681442,
                0.060333266854286194,
                -0.0015962806064635515,
                0.04569946229457855,
                -0.016389112919569016,
                0.033437296748161316,
                0.01886061020195484,
                0.06289801001548767,
                -0.02892298623919487,
                5.516727575906573e-35,
                0.03413017466664314,
                -0.04329078271985054,
                0.04568232595920563,
                0.005082758609205484,
                0.023989472538232803,
                -0.0359029583632946,
                0.04087371006608009,
                0.009538444690406322,
                -0.018030887469649315,
                -0.03453260287642479,
                0.0166904479265213,
                0.001050399150699377,
                -0.015659792348742485,
                -0.04077407717704773,
                0.016929741948843002,
                -0.008977126330137253,
                0.039868488907814026,
                -0.016769936308264732,
                -0.012620354071259499,
                -0.02904624305665493,
                0.04010183736681938,
                0.04734214022755623,
                -0.016758110374212265,
                0.04003781080245972,
                0.01805502362549305,
                0.013277451507747173,
                -0.07238934934139252,
                0.06495878845453262,
                0.014860199764370918,
                -0.0881391316652298,
                -0.017173809930682182,
                -0.012098914012312889,
                -0.00595841882750392,
                -0.014531481079757214,
                0.0027492805384099483,
                -0.0401265025138855,
                -0.06826253980398178,
                -0.02299506962299347,
                -0.01441302802413702,
                -0.03382314369082451,
                0.05892297998070717,
                -0.03200894966721535,
                -0.03212839365005493,
                -0.006364014931023121,
                0.001141008804552257,
                1.840802042352152e-06,
                -0.008845952339470387,
                -0.001161392079666257,
                0.016112130135297775,
                -0.03841083124279976,
                0.03444686904549599,
                0.06722598522901535,
                0.034432098269462585,
                -0.014647034928202629,
                0.033243514597415924,
                0.0028093454893678427,
                -0.018339261412620544,
                0.08492673933506012,
                -0.06031711772084236,
                -0.026543276384472847,
                -0.012046934105455875,
                -0.018805550411343575,
                0.022356495261192322,
                -0.03167588263750076,
                -0.01057237945497036,
                -0.01961468532681465,
                -0.004850032739341259,
                0.007886524312198162,
                0.020368263125419617,
                0.13159625232219696,
                0.029717998579144478,
                -0.008588301949203014,
                -0.034112364053726196,
                0.01023987215012312,
                -0.019789813086390495,
                0.02070559747517109,
                0.01845438778400421,
                0.04086196422576904,
                -0.028145257383584976,
                -0.006619097199290991,
                -0.0195380337536335,
                0.026606259867548943,
                0.008117785677313805,
                0.020986586809158325,
                0.08271810412406921,
                -0.08442579209804535,
                -0.038963235914707184,
                -0.02105014957487583,
                0.01463725883513689,
                -0.03547781705856323,
                -0.06006230413913727,
                0.014710880815982819,
                -0.02334015443921089,
                -0.0038112045731395483,
                0.00907079502940178,
                0.04773765802383423,
                0.04378232732415199,
                0.022846154868602753,
                -0.024638475850224495,
                -0.020313730463385582,
                0.04141944646835327,
                0.005100949667394161,
                -0.030569104477763176,
                0.044109467417001724,
                0.017652221024036407,
                -0.006413087248802185,
                0.0008322324720211327,
                -0.02641347609460354,
                0.041266199201345444,
                -0.008095254190266132,
                -0.019637610763311386,
                0.06494405120611191,
                0.015917643904685974,
                0.049688488245010376,
                0.04331476613879204,
                0.041105642914772034,
                -0.018683569505810738,
                -0.06958452612161636,
                -0.049825552850961685,
                -0.04525195434689522,
                0.08422800153493881,
                -0.05179550126194954,
                -0.023106588050723076,
                -0.020036716014146805,
                0.08140003681182861,
                0.04017952084541321,
                -0.055546537041664124,
                -0.03937157616019249,
                0.051613833755254745,
                -0.005923300515860319,
                -0.017835626378655434,
                -0.03411431610584259,
                0.003130503697320819,
                0.019753267988562584,
                0.00635250099003315,
                0.0015587250236421824,
                -0.011741440743207932,
                -0.04866990074515343,
                -0.017277978360652924,
                0.0003957122389692813,
                -0.030901260673999786,
                -0.019523732364177704,
                -0.025420809164643288,
                -0.008243461139500141,
                -0.025667045265436172,
                0.006486151367425919,
                0.008717643097043037,
                -0.01595633290708065,
                0.05597070977091789,
                -0.05309491977095604,
                -0.026429476216435432,
                -0.010109438560903072,
                -0.049558766186237335,
                -0.023307695984840393,
                -0.022578619420528412,
                -0.026823876425623894,
                0.04297303035855293,
                0.037766776978969574,
                0.025769682601094246,
                0.00701992679387331,
                0.036966536194086075,
                -0.011378949508070946,
                0.04040917381644249,
                -0.018763570114970207,
                -0.027700349688529968,
                0.09673284739255905,
                0.04188377782702446,
                0.005122899077832699,
                -0.06344499439001083,
                -0.009941083379089832,
                -0.012327433563768864,
                0.020796796306967735,
                0.036839909851551056,
                0.05950932204723358,
                -0.006413269322365522,
                -0.004786558914929628,
                -0.018800726160407066,
                -0.016148090362548828,
                -0.026026684790849686,
                -0.016247881576418877,
                -0.05196582153439522,
                -0.026128940284252167,
                0.03568961098790169,
                -0.00818223599344492,
                0.07919932901859283,
                0.04305310547351837,
                0.017102450132369995,
                0.013251767493784428,
                0.06662061810493469,
                -0.005603658501058817,
                -0.019403528422117233,
                -0.016500860452651978,
                -0.03969026729464531,
                0.011782976798713207,
                0.06962613016366959,
                -0.002027027541771531,
                0.02160888910293579,
                0.04122365266084671,
                -0.015093082562088966,
                -0.03524862229824066,
                -0.003968361299484968,
                0.011224173940718174,
                -0.018507692962884903,
                -0.01705814339220524,
                0.00142373307608068,
                0.02266601473093033,
                0.023787811398506165,
                0.013855044730007648,
                -0.012730087153613567,
                -0.026716725900769234,
                -0.04212106391787529,
                -0.05153406783938408,
                -0.039834875613451004,
                0.04260658100247383,
                0.062382277101278305,
                -0.0014890250749886036,
                -0.028189998120069504,
                0.024605968967080116,
                -0.012500885874032974,
                0.018042201176285744,
                -0.0311132725328207,
                -0.009250106289982796,
                0.01806219108402729,
                -0.005028267856687307,
                0.035544510930776596,
                0.010201250202953815,
                0.005269527900964022,
                0.008876181207597256,
                0.03425154834985733,
                0.00933898240327835,
                0.0394655242562294,
                -0.03182836249470711,
                0.03306214511394501,
                0.017237503081560135,
                -0.022720495238900185,
                0.010734259150922298,
                -0.059998538345098495,
                0.0011029229499399662,
                0.008355247788131237,
                -0.026784183457493782,
                -0.0014683643821626902,
                -0.013683603145182133,
                0.024323489516973495,
                0.05782110244035721,
                -0.010050930082798004,
                -0.001276470604352653,
                -0.026938026770949364,
                0.027911167591810226,
                0.07687372714281082,
                0.04819824546575546,
                -0.07656493782997131,
                0.0012700336519628763,
                -0.01813432388007641,
                0.04800032079219818,
                0.0077132596634328365,
                0.08645160496234894,
                -0.03770699352025986,
                -0.02046327106654644,
                0.003213326446712017,
                0.015929967164993286,
                -0.005679801572114229,
                -0.061472516506910324,
                0.024159502238035202,
                0.06811429560184479,
                0.020702984184026718,
                0.06356686353683472,
                0.04812544584274292,
                0.0010174635099247098,
                0.04575071111321449,
                -0.0597180612385273,
                -0.02931039407849312,
                0.014143634587526321,
                -0.04628051444888115,
                -0.015068307518959045,
                -0.0012008375488221645,
                0.019901756197214127,
                -0.03513934835791588,
                0.008661944419145584,
                -0.053257185965776443,
                -0.05992796644568443,
                -0.004306747578084469,
                0.01401545386761427,
                0.02081066370010376,
                -0.019290423020720482,
                0.01894127018749714,
                -0.026467740535736084,
                0.022106293588876724,
                -0.005861344747245312,
                -0.03851483389735222,
                -0.01958603970706463,
                0.040000494569540024,
                -0.005665427073836327,
                -0.024774035438895226,
                -0.02983027696609497,
                -0.04480724036693573,
                0.002967794192954898,
                0.0001850397529779002,
                0.03478303179144859,
                -0.04158895090222359,
                -0.025392748415470123,
                0.032078880816698074,
                -0.014428640715777874,
                -0.024481723085045815,
                0.027904324233531952,
                0.006427435204386711,
                0.015124763362109661,
                -0.018280118703842163,
                -0.015220709145069122,
                0.0148286959156394,
                -0.012220459058880806,
                -0.0064271423034369946,
                0.001973438775166869,
                0.037131600081920624,
                0.061825208365917206,
                -0.009228087030351162,
                -0.010848709382116795,
                0.02347472682595253,
                0.046428531408309937,
                -0.03985251858830452,
                -0.037974677979946136,
                0.02113291807472706,
                0.018902702257037163,
                0.022405555471777916,
                0.03945361077785492,
                0.0565556101500988,
                -0.02948698028922081,
                -0.020312359556555748,
                -0.08754251152276993,
                -0.01594138890504837,
                0.00039531535003334284,
                0.0627632737159729,
                -0.02936553582549095,
                -0.016486667096614838,
                0.04452334716916084,
                -0.012052367441356182,
                0.019819332286715508,
                0.005291423294693232,
                -0.023189352825284004,
                0.027927104383707047,
                0.03194531053304672,
                -0.04360393434762955,
                0.02196747250854969,
                -0.04096030071377754,
                -0.01945352926850319,
                0.025406919419765472,
                -0.05823446065187454,
                0.07281749695539474,
                -0.05896923691034317,
                -0.04665585979819298,
                -0.029522528871893883,
                0.01778903603553772,
                -0.014554914087057114,
                -0.06526727229356766,
                0.01565389521420002,
                0.0578622967004776,
                0.03680256009101868,
                -0.0551929846405983,
                0.022464357316493988,
                0.007551484275609255,
                -0.02606358751654625,
                0.021509194746613503,
                0.06230660155415535,
                0.05273427441716194,
                0.0067187207750976086,
                -0.04593653976917267,
                -0.024317864328622818,
                0.03191719204187393,
                -0.06360755115747452,
                0.014824280515313148,
                -0.04741036519408226,
                -0.01717689074575901,
                -0.0037902798503637314,
                0.03236280009150505,
                0.04191311448812485,
                0.06861231476068497,
                -0.003224275540560484,
                0.03673577681183815,
                0.04705410450696945,
                0.019881507381796837,
                -0.05374787747859955,
                0.006565357092767954,
                0.02232009544968605,
                0.056098658591508865,
                -0.05585828423500061,
                0.020610280334949493,
                0.005741702858358622,
                0.005791533272713423,
                0.0008408088469877839,
                0.015191586688160896,
                -0.06885802745819092,
                0.028851749375462532,
                0.027419237419962883,
                -0.07440102100372314,
                0.03451578691601753,
                0.019946303218603134,
                0.007142598275095224,
                -0.0270611010491848,
                0.0010441639460623264,
                0.027484513819217682,
                -0.035208821296691895,
                0.0257598627358675,
                0.015513735823333263,
                0.008608361706137657,
                -0.050579436123371124,
                -0.04237048327922821,
                0.02169463224709034,
                0.006733546499162912,
                0.019320689141750336,
                -0.0017291142139583826,
                -0.02042100951075554,
                0.050129909068346024,
                0.014014584012329578,
                -0.015376035124063492,
                0.04829728603363037,
                0.02224227972328663,
                0.023473767563700676,
                -0.06292153149843216,
                0.09452930092811584,
                0.028870103880763054,
                -0.040990397334098816,
                -0.0631457269191742,
                -0.014220296405255795,
                -0.007350225932896137,
                0.049884945154190063,
                -0.03544957935810089,
                -0.04052774980664253,
                0.011860663071274757,
                -0.049396611750125885,
                0.0003144183137919754,
                0.009370005689561367,
                -0.020575832575559616,
                0.0131571926176548,
                -0.02132834494113922,
                0.012689381837844849,
                -0.0045292070135474205,
                0.022124558687210083,
                -0.002128742868080735,
                0.008793414570391178,
                0.03793345391750336,
                -0.004381436854600906,
                -0.004476488567888737,
                -0.043775178492069244,
                -0.03731615096330643,
                -0.04264414310455322,
                0.041594844311475754,
                0.03623761981725693,
                -0.038316305726766586,
                -0.04228940233588219,
                0.04522727429866791,
                -0.029486795887351036,
                -0.023092743009328842,
                0.01406148262321949,
                0.030467312783002853,
                0.060171645134687424,
                0.040172092616558075,
                -0.07198981195688248,
                0.056774236261844635,
                0.026904750615358353,
                -0.08090084791183472,
                -0.08721346408128738,
                0.007514536380767822,
                0.05986944958567619,
                -0.026676636189222336,
                0.04612397029995918,
                -0.05138034746050835,
                0.012987630441784859,
                -0.0425880067050457,
                -0.03324700891971588,
                -0.0005859725060872734,
                -0.02127688378095627,
                0.026167018339037895,
                0.09263096004724503,
                0.04186396300792694,
                -0.06304070353507996,
                0.003277919953688979,
                0.016159597784280777,
                -0.06114836037158966,
                0.02716222032904625,
                -0.05318780615925789,
                0.045625749975442886,
                -0.02870616503059864,
                -0.07075627893209457,
                0.0064666070975363255,
                -0.02461918629705906,
                0.02412448450922966,
                -0.016619915142655373,
                0.03119610995054245,
                -0.0068633072078228,
                -0.0685473382472992,
                -0.05120571330189705,
                0.022882577031850815,
                0.004073838237673044,
                -0.014833482913672924,
                0.017318131402134895,
                0.037844810634851456,
                0.002342951949685812,
                0.02426990680396557,
                0.03943921625614166,
                0.022772585973143578,
                0.018223436549305916,
                -0.045100677758455276,
                0.023728232830762863,
                0.01350597757846117,
                0.008185148239135742,
                -0.02303929068148136,
                0.0326165072619915,
                -0.033658042550086975,
                -0.04757043346762657,
                0.005505676846951246,
                -0.007058189250528812,
                0.05227456986904144,
                -0.019035695120692253,
                0.007872155867516994,
                -0.0299176424741745,
                0.017542749643325806,
                -0.024925198405981064,
                0.0469234436750412,
                -0.03371326997876167,
                0.033560965210199356,
                -0.02150152623653412,
                0.04223170503973961,
                -0.01731959916651249,
                0.022561628371477127,
                -0.039217401295900345,
                -0.08795572817325592,
                0.03136037290096283,
                0.05057499557733536,
                0.03125913813710213,
                -0.020078852772712708,
                -0.0653851106762886,
                -0.013962962664663792,
                0.009108467027544975,
                -0.044271502643823624,
                0.006197535898536444,
                0.0021387808956205845,
                0.036741096526384354,
                -0.054002489894628525,
                0.051492806524038315,
                -0.005331770982593298,
                0.0029381646309047937,
                0.036651138216257095,
                -0.1432391107082367,
                -0.033127982169389725,
                0.022163894027471542,
                -0.03590545803308487,
                -0.022625628858804703,
                0.04066832363605499,
                -0.06254786998033524,
                0.057491324841976166,
                0.009198208339512348,
                0.0040654633194208145,
                0.013890838250517845,
                -0.039899133145809174,
                0.013453499414026737,
                0.003873048583045602,
                0.007649639621376991,
                -0.05680793523788452,
                0.037815485149621964,
                0.024965278804302216,
                0.03779684379696846,
                0.0409642830491066,
                -0.0063181072473526,
                0.009009648114442825,
                -0.014011765830218792,
                0.027087191119790077,
                -0.012267026118934155,
                -7.314083073185695e-33,
                -0.02075960859656334,
                -0.04997764900326729,
                0.03811563178896904,
                0.059245627373456955,
                -0.020899351686239243,
                0.008249534294009209,
                0.01833595335483551,
                0.0662146806716919,
                -0.050134699791669846,
                -0.03425447642803192,
                -0.05323285982012749,
                0.0071239410899579525,
                0.05424114689230919,
                0.018199516460299492,
                0.019431063905358315,
                -0.022585250437259674,
                0.03728003054857254,
                0.06299490481615067,
                0.0010284411255270243,
                -0.006035654805600643,
                0.01803502067923546,
                0.015133596956729889,
                0.00012687413254752755,
                -0.04627015441656113,
                -0.003943333867937326,
                -0.0007425902294926345,
                -0.006923079490661621,
                -0.05853128805756569,
                0.004190391860902309,
                -0.009511268697679043,
                -0.011978184804320335,
                -0.009244603104889393,
                -0.024075184017419815,
                -0.008891366422176361,
                0.02813023515045643,
                0.062044642865657806,
                -0.0926155373454094,
                -0.024677593261003494,
                0.006360707338899374,
                0.01661207713186741,
                0.013397641479969025,
                -0.05856480449438095,
                0.008520166389644146,
                -0.006061214953660965,
                -0.05922585353255272,
                0.005282999482005835,
                0.005597648210823536,
                -0.012791775166988373,
                -0.014614327810704708,
                0.017337355762720108,
                -0.03213610127568245,
                -0.013426887802779675,
                -0.012301014736294746,
                0.022288372740149498,
                0.004394556395709515,
                -0.041757043451070786,
                -0.04992945119738579,
                -0.010362185537815094,
                -0.05857449397444725,
                0.07361777871847153,
                -0.005215383134782314,
                0.06004374474287033,
                0.02235211804509163,
                -0.0017067285953089595,
                0.034259699285030365,
                -0.01709575392305851,
                0.030274827033281326,
                -0.006784429773688316,
                -0.011957339011132717,
                -0.006076820194721222,
                -0.02232680656015873,
                0.036593757569789886,
                -0.012774701230227947,
                -0.016982262954115868,
                0.06378725916147232,
                -0.07697677612304688,
                0.016620006412267685,
                -0.03292764723300934,
                0.061022255569696426,
                -0.04296938329935074,
                0.007724552880972624,
                -0.01836804300546646,
                0.011621780693531036,
                -0.037877704948186874,
                0.006357943173497915,
                0.05278146639466286,
                -0.03650585189461708,
                -0.010625758208334446,
                -0.004475506953895092,
                0.04406360536813736,
                -0.010897668078541756,
                0.040954120457172394,
                -0.035805802792310715,
                -0.018452949821949005,
                0.03621184825897217,
                -0.0201418474316597,
                0.057509880512952805,
                0.04090240225195885,
                0.0017661486053839326,
                -0.033750154078006744,
                -0.050610896199941635,
                -0.03866349533200264,
                -0.0748460665345192,
                0.04395416006445885,
                0.020250141620635986,
                -0.0023451796732842922,
                -0.04102035239338875,
                0.026154424995183945,
                -0.037605151534080505,
                -0.04667678847908974,
                -0.004427917301654816,
                0.012970932759344578,
                0.009467457421123981,
                0.023761462420225143,
                0.03982909768819809,
                -0.014097345061600208,
                0.01269729807972908,
                -0.07502008229494095,
                -0.04545379430055618,
                0.018343297764658928,
                -0.002534453524276614,
                -0.050804462283849716,
                -0.07372186332941055,
                0.01260420586913824,
                -0.002360670128837228,
                -0.03320607915520668,
                -0.06753630191087723,
                0.006373470649123192,
                -0.0030331057496368885,
                -0.10683881491422653,
                -0.023582343012094498,
                0.006301203742623329,
                2.389051303453016e-07,
                -0.006645864341408014,
                0.03683968633413315,
                0.025199158117175102,
                0.05278284102678299,
                0.027897018939256668,
                -0.05371743068099022,
                0.012763753533363342,
                0.017791520804166794,
                0.0529000386595726,
                -0.014255831018090248,
                0.018367024138569832,
                0.017495589330792427,
                -0.011985454708337784,
                -0.004581645596772432,
                0.008716925047338009,
                -0.06207562983036041,
                -0.021593153476715088,
                0.03902376815676689,
                -0.02022331953048706,
                -0.00040100328624248505,
                0.045864976942539215,
                0.02158644050359726,
                9.482029417995363e-05,
                -0.016190193593502045,
                -0.007388755679130554,
                -0.09671375900506973,
                0.029073499143123627,
                0.012073783203959465,
                0.08187473565340042,
                -0.04076801612973213,
                -0.03406559303402901,
                0.02100241370499134,
                -0.0003489250666461885,
                0.041574154049158096,
                0.0038596224039793015,
                -0.05077887326478958,
                0.018098747357726097,
                0.034936390817165375,
                0.030191680416464806,
                0.07044757157564163,
                -0.009414386935532093,
                -0.01802120730280876,
                -0.018013734370470047,
                0.03570901229977608,
                0.0809394121170044,
                0.03968300670385361,
                0.017647359520196915,
                0.025830697268247604,
                -0.005839851219207048,
                -0.021623624488711357,
                -0.02936660498380661,
                -0.006210239138454199,
                0.044657837599515915,
                0.018196353688836098,
                0.006662462372332811,
                -0.003932369407266378,
                0.006598261184990406,
                0.046104393899440765,
                0.01564418151974678,
                0.08095505833625793,
                -0.005051188170909882,
                -0.051679451018571854,
                -0.01629314012825489,
                0.03628954663872719,
                0.059913672506809235,
                0.011871102266013622,
                -0.02015920728445053,
                1.1683914491663179e-34,
                -0.02567138709127903,
                -0.04139993339776993,
                0.01698235236108303,
                0.022200239822268486,
                -0.01799403689801693,
                0.007193833123892546,
                -0.012913031503558159,
                -0.049878496676683426,
                0.020573191344738007,
                -0.010052468627691269,
                -0.03073934093117714,
                0.008266055025160313,
                -0.006095334887504578,
                -0.052518319338560104,
                0.05084041506052017,
                -0.040198713541030884,
                -0.042081911116838455,
                0.025767942890524864,
                -0.0226090457290411,
                -0.15309357643127441,
                0.038734469562768936,
                0.04333360493183136,
                -0.01698993146419525,
                0.028295783326029778,
                0.11446275562047958,
                0.014344839379191399,
                -0.0419943630695343,
                -0.014634894207119942,
                0.011605619452893734,
                -0.011234387755393982,
                0.010271010920405388,
                -0.005637891124933958,
                -0.00435812771320343,
                0.0005555706447921693,
                0.014496246352791786,
                -0.0384029783308506,
                -0.02300039678812027,
                -0.03414051979780197,
                0.009416094049811363,
                -0.009541496634483337,
                -0.051084768027067184,
                0.01999393291771412,
                0.08645620197057724,
                -0.039481520652770996,
                -0.002072420436888933,
                1.619106910766277e-06,
                -0.0008957639220170677,
                -0.004534970037639141,
                0.000979893491603434,
                -0.009033148176968098,
                0.048339325934648514,
                0.021634336560964584,
                -0.0014136696700006723,
                0.009283042512834072,
                -0.025332003831863403,
                0.006656319368630648,
                0.050692349672317505,
                -0.027652235701680183,
                0.09908217936754227,
                -0.0017152030486613512,
                -0.05500262230634689,
                -0.0015277587808668613,
                -0.07513990253210068,
                -0.012646013870835304,
                -0.004089475143700838,
                0.017397038638591766,
                0.061617281287908554,
                -0.030342111364006996,
                0.028932729735970497,
                0.06509624421596527,
                -0.021572984755039215,
                0.021368421614170074,
                0.02862512320280075,
                -0.01994362659752369,
                0.011495798826217651,
                0.12477488070726395,
                -0.004755686037242413,
                0.039661213755607605,
                -0.03584225848317146,
                0.02911333180963993,
                0.027174988761544228,
                0.0859425812959671,
                0.054665546864271164,
                -0.026462366804480553,
                0.020808523520827293,
                -0.02693692222237587,
                -0.0508837029337883,
                -0.030275674536824226,
                -0.04563502222299576,
                -0.004972737282514572,
                -0.0340767540037632,
                -0.0506637878715992,
                0.002932775765657425,
                -0.0419197753071785,
                0.024375487118959427,
                -0.03418441861867905,
                0.004132018890231848,
                -0.012687301263213158,
                0.010230619460344315,
                -0.05206787958741188,
                0.008260334841907024,
                -0.025190921500325203,
                -0.0003679636283777654,
                0.0002199843293055892,
                -0.014438634738326073,
                -0.0017239585286006331,
                -0.020869966596364975,
                0.061322540044784546,
                0.021807650104165077,
                0.014568239450454712,
                -0.04092424362897873,
                0.09947846829891205,
                -0.0021917885169386864,
                0.0618441142141819,
                0.04133500158786774,
                0.013816051185131073,
                0.04124218970537186,
                -0.0038269353099167347,
                -0.011097932234406471,
                -0.03497716039419174,
                0.09553839266300201,
                -0.04320015385746956,
                0.009569709189236164,
                -0.05878158286213875,
                0.06718319654464722,
                0.01776936464011669,
                -0.02754802256822586,
                -0.030007891356945038,
                0.034916896373033524,
                -0.004686893429607153,
                0.0058522881008684635,
                -0.03351398557424545,
                0.017468556761741638,
                -0.003362306859344244,
                -0.032438650727272034,
                -0.011709289625287056,
                0.010237431153655052,
                -0.018809428438544273,
                0.012001274153590202,
                0.006856809835880995,
                -0.0537131130695343,
                0.03575501963496208,
                -0.0389554463326931,
                -0.06715340167284012,
                -0.006147401873022318,
                0.0010748229688033462,
                -0.034382108598947525,
                -0.01837681420147419,
                -0.004520230460911989,
                -0.07617022097110748,
                -0.037651967257261276,
                0.036413099616765976,
                -0.025392968207597733,
                -0.04664822295308113,
                -0.03324810042977333,
                -0.05418167635798454,
                -0.03816690295934677,
                0.0002780395734589547,
                0.028335999697446823,
                -0.012194721028208733,
                -0.03720931336283684,
                -0.011191857978701591,
                0.04050648212432861,
                0.012838599272072315,
                0.03986124321818352,
                0.02756616659462452,
                0.045387692749500275,
                -0.031798526644706726,
                0.0628427192568779,
                0.007425796706229448,
                -0.00933072343468666,
                -0.006162988021969795,
                -0.006722235586494207,
                -0.03493228927254677,
                -0.015983590856194496,
                -0.021375427022576332,
                -0.006426069885492325,
                -0.024309402331709862,
                0.008852059952914715,
                -0.05070760101079941,
                -0.001379673252813518,
                -0.06403328478336334,
                -0.022984905168414116,
                -0.03801974654197693,
                0.14230355620384216,
                0.03145385533571243,
                0.004487759433686733,
                0.01949317753314972,
                0.02789844013750553,
                -0.08178266882896423,
                -0.006320467218756676,
                0.03458061441779137,
                -0.08099313080310822,
                0.02941349893808365,
                0.005384249147027731,
                0.02732383832335472,
                0.02034599892795086,
                -0.04511246457695961,
                0.002030604286119342,
                -0.056309062987565994,
                -0.020363427698612213,
                -0.003502665553241968,
                0.009622829034924507,
                -0.011738860048353672,
                -0.02099420502781868,
                -0.0029648621566593647,
                0.02640904113650322,
                0.059764835983514786,
                -0.028863651677966118,
                -0.04050028696656227,
                -0.02377106063067913,
                -0.026303177699446678,
                -0.02185538038611412,
                0.053649310022592545,
                0.06521030515432358,
                -0.008402797393500805,
                0.022856561467051506,
                -0.023281486704945564,
                -0.01669549196958542,
                -0.05288456380367279,
                0.019398530945181847,
                0.06753146648406982,
                -0.00046124382060952485,
                -0.053491394966840744,
                0.023738697171211243,
                -0.017613589763641357,
                -0.018704703077673912,
                0.02270055003464222,
                -0.0007715524407103658,
                -0.09718307852745056,
                0.03827232867479324,
                -0.018281666561961174,
                0.020315922796726227,
                0.040344901382923126,
                0.0032945757266134024,
                0.07749269157648087,
                0.06371597200632095,
                0.03536740690469742,
                -0.009070205502212048,
                0.03188711404800415,
                0.0555376298725605,
                0.002700667129829526,
                0.03589988872408867,
                0.011438988149166107,
                -0.04624538496136665,
                0.01343393512070179,
                -0.013999870978295803,
                0.011734005995094776,
                0.057069309055805206,
                -0.004298155661672354,
                -0.07150822877883911,
                0.030532127246260643,
                -0.033412910997867584,
                0.012441052123904228,
                -0.017607618123292923,
                0.014926150441169739,
                0.01258978433907032,
                0.045742638409137726,
                0.0027694555465132,
                0.03647288680076599,
                -0.016822796314954758,
                -0.03246738761663437,
                -0.020601574331521988,
                -0.005287674255669117,
                -0.012887539342045784,
                0.03577812388539314,
                -0.04482593014836311,
                0.0024372295010834932,
                0.043953027576208115,
                0.0035516591742634773,
                -0.032620299607515335,
                0.045526180416345596,
                -0.013073759153485298,
                0.012961662374436855,
                0.011789287440478802,
                0.01488874014467001,
                -0.028866948559880257,
                0.03468812629580498,
                -0.010687457397580147,
                -0.0031768258195370436,
                0.02374192699790001,
                -0.019673366099596024,
                0.027471935376524925,
                -0.00544056436046958,
                -0.010249066166579723,
                0.028196653351187706,
                0.008260544389486313,
                0.030703969299793243,
                0.02079867385327816,
                -0.00010611485049594194,
                -0.013165397569537163,
                0.06990080326795578,
                -0.05290502309799194,
                0.025555307045578957,
                0.010532449930906296,
                0.011083701625466347,
                0.009690937586128712,
                0.044736750423908234,
                -0.04008040577173233,
                -0.010785051621496677,
                0.054641034454107285,
                -0.017384013161063194,
                -0.02802475169301033,
                0.024981191381812096,
                -0.016745751723647118,
                0.03978288546204567,
                -0.04331972822546959,
                -0.009523124434053898,
                -0.036415617913007736,
                -0.009021759033203125,
                0.03840130195021629,
                -0.017908576875925064,
                -0.046298738569021225,
                -0.014860458672046661,
                -0.0004229297337587923,
                -0.027466878294944763,
                0.013289605267345905,
                0.06548315286636353,
                0.04750266671180725,
                0.009867282584309578,
                0.0024412977509200573,
                -0.005334876943379641,
                0.011437826789915562,
                0.009199955500662327,
                0.06451668590307236,
                0.027045462280511856,
                0.009794366545975208,
                -0.052196066826581955,
                -0.011634277179837227,
                0.04940859600901604,
                0.08513730019330978,
                -0.028213610872626305,
                0.009564888663589954,
                0.0017463101539760828,
                -7.207215094240382e-05,
                0.02261229231953621,
                -0.013275046832859516,
                -0.011627503670752048,
                0.025144493207335472,
                0.027148444205522537,
                -0.02369270659983158,
                -0.0006095886928960681,
                0.11955904960632324,
                0.0031151503790169954,
                -0.025914959609508514,
                0.024396385997533798,
                0.021812282502651215,
                -0.012021336704492569,
                -0.002663566032424569,
                -0.00913744792342186,
                -0.0466601699590683,
                -0.022521862760186195,
                -0.046705298125743866,
                -0.0988885834813118,
                0.12226549535989761,
                0.022952154278755188,
                0.02571776509284973,
                0.020230945199728012,
                0.0453132726252079,
                -0.04548637196421623,
                0.03332415595650673,
                0.015128262341022491,
                5.48178686585743e-05,
                -0.004701424855738878,
                -0.0017700514290481806,
                -0.0016423397464677691,
                0.02373388223350048,
                -0.0010693236254155636,
                0.00314764934591949,
                -0.025968702509999275,
                -0.009911682456731796,
                -0.010965239256620407,
                0.0018563587218523026,
                -0.04605383798480034,
                -0.03966597467660904,
                -0.017874790355563164,
                0.014105668291449547,
                0.06718483567237854,
                0.023224402219057083,
                -0.020594781264662743,
                -0.008649716153740883,
                0.02559267170727253,
                0.03152327612042427,
                -0.0091480677947402,
                0.03353462368249893,
                0.023175330832600594,
                0.0036740328650921583,
                0.0004462636134121567,
                -0.0083767706528306,
                0.006725376006215811,
                -0.004610795062035322,
                0.0009531966061331332,
                -0.04519817233085632,
                0.08670710027217865,
                -0.031040579080581665,
                0.012856990098953247,
                -0.017223570495843887,
                -0.0101179713383317,
                -0.01892535760998726,
                0.031118301674723625,
                0.024248156696558,
                -0.005248700268566608,
                -0.13916437327861786,
                -0.02101505734026432,
                0.010690553113818169,
                -0.01174998376518488,
                0.01766045391559601,
                -0.04103793203830719,
                0.045964565128088,
                0.028566770255565643,
                0.0330359973013401,
                -0.00422699237242341,
                -0.003780623897910118,
                -0.01452291663736105,
                -0.005031104199588299,
                0.005795036442577839,
                -0.08940040320158005,
                0.0328206904232502,
                -0.01738942414522171,
                -0.0023062746040523052,
                -0.07193472236394882,
                -0.04727056995034218,
                -0.051933981478214264,
                0.0126798115670681,
                -0.016819007694721222,
                -0.04178176820278168,
                -0.004206857644021511,
                -0.026185648515820503,
                -0.02300134114921093,
                0.033363938331604004,
                -0.020950540900230408,
                0.006921885069459677,
                0.031140198931097984,
                0.015053929761052132,
                -0.027390465140342712,
                0.01763305254280567,
                -0.061191461980342865,
                -0.055453453212976456,
                0.023551881313323975,
                0.020193874835968018,
                0.00028756281244568527,
                -0.010408337228000164,
                0.004850250668823719,
                0.004826018121093512,
                0.03740517050027847,
                -0.018589938059449196,
                0.012540485709905624,
                -0.05202345922589302,
                -0.002172060776501894,
                0.028996825218200684,
                -0.027607137337327003,
                0.010187721811234951,
                -0.0018880072748288512,
                0.04247717559337616,
                -0.009456687606871128,
                -0.012007945217192173,
                -0.05435791611671448,
                0.008813656866550446,
                -0.03999542072415352,
                -0.022014958783984184,
                0.02831856720149517,
                0.016092738136649132,
                -0.04834980145096779,
                -0.043331462889909744,
                -0.009070971049368382,
                -0.004715421702712774,
                -0.008966373279690742,
                -0.010015892796218395,
                -0.007906084880232811,
                -0.020997483283281326,
                0.038208018988370895,
                0.020845653489232063,
                0.03813068941235542,
                -0.01977795735001564,
                -0.02002543956041336,
                0.038354650139808655,
                0.06741248816251755,
                0.022359665483236313,
                -0.04347023367881775,
                -0.034882668405771255,
                -0.01614455133676529,
                0.03656601905822754,
                0.003547210246324539,
                -0.018548794090747833,
                0.04381655529141426,
                0.06723619997501373,
                0.0006193777662701905,
                -0.027915310114622116,
                -0.029989782720804214,
                0.04037449136376381,
                -0.00898401252925396,
                0.0552511066198349,
                0.07984719425439835,
                -0.009754069149494171,
                0.024254467338323593,
                -0.0040083215571939945,
                0.032820165157318115,
                0.03157360851764679,
                -0.03705746680498123,
                0.013625475578010082,
                -0.03703382611274719,
                -0.007121375296264887,
                -0.0209489818662405,
                0.009543398395180702,
                -0.03787856176495552,
                0.006777293514460325,
                0.03935879096388817,
                0.02368747629225254,
                0.06190873309969902,
                0.04450221359729767,
                -0.011412016116082668,
                0.004779710900038481,
                0.012564931996166706,
                0.02357388660311699,
                0.012499996460974216,
                -0.03702995181083679,
                0.01795746199786663,
                0.013868371024727821,
                0.04391353204846382,
                -0.001929528429172933,
                -0.010033033788204193,
                0.012172762304544449,
                0.05267699807882309,
                0.0072411587461829185,
                -0.1009945198893547,
                -0.017868515104055405,
                0.013758924789726734,
                -0.038941770792007446,
                0.046162404119968414,
                -0.053928192704916,
                0.00519856670871377,
                0.014322622679173946,
                -0.030046161264181137,
                0.04013088718056679,
                -0.004985686391592026,
                0.00359697756357491,
                0.0072602760046720505,
                0.037153564393520355,
                0.026332223787903786,
                -0.07771062105894089,
                -0.0007971976301632822,
                -0.1469462811946869,
                -0.057550109922885895,
                -0.03420224413275719,
                -0.019656281918287277,
                -0.018302462995052338,
                0.08654750138521194,
                0.03737558424472809,
                0.015316407196223736,
                -0.01090236660093069,
                -0.02320847101509571,
                -0.08756933361291885,
                0.01913665607571602,
                0.08630287647247314,
                -0.01766211912035942,
                0.047057315707206726,
                0.03775514289736748,
                0.04071962833404541,
                0.015241007320582867,
                -0.014772423543035984,
                -0.03681715205311775,
                0.02121053822338581,
                -0.026967432349920273,
                -0.07686744630336761,
                0.0008125028107315302,
                -0.00497479410842061,
                -6.197013730335153e-33,
                -0.023447323590517044,
                0.05016964673995972,
                0.017154166474938393,
                0.010166044346988201,
                -0.022668028250336647,
                -0.004344696179032326,
                0.02053198032081127,
                0.026181470602750778,
                -0.03365711122751236,
                -0.027628062292933464,
                -0.027576547116041183,
                -0.06198804825544357,
                -0.0005138508859090507,
                0.0033865992445498705,
                -0.0032125927973538637,
                -0.0024465005844831467,
                0.04840517416596413,
                0.037839487195014954,
                -0.04452969506382942,
                -0.006596715655177832,
                -0.017872033640742302,
                0.0006166259408928454,
                -0.016039812937378883,
                -0.0351928174495697,
                -0.02376326359808445,
                -0.03112088143825531,
                -0.022634677588939667,
                0.0074759055860340595,
                0.023216433823108673,
                -0.004779776558279991,
                -0.021553993225097656,
                0.02194354683160782,
                -0.0102379834279418,
                -0.028550367802381516,
                0.0028236787766218185,
                0.007523929700255394,
                -0.01730632595717907,
                -0.0556720606982708,
                0.0016529810382053256,
                0.03776829317212105,
                0.0305505208671093,
                -0.027459831908345222,
                -0.022337058559060097,
                -0.006817182060331106,
                -0.020182669162750244,
                -0.01961628720164299,
                -0.013635304756462574,
                -0.02384015917778015,
                0.019477980211377144,
                0.046088822185993195,
                -0.03893129527568817,
                -0.0001307780185015872,
                -0.016577549278736115,
                0.05626089498400688,
                0.03237364441156387,
                -0.11279632151126862,
                -0.005455944687128067,
                -0.06463706493377686,
                -0.039985541254282,
                0.030672414228320122,
                0.034197159111499786,
                -0.022778375074267387,
                0.008933713659644127,
                -0.0042735678143799305,
                0.006489759776741266,
                0.0008634005207568407,
                -0.029859310016036034,
                0.06996450573205948,
                0.00897231139242649,
                0.024028709158301353,
                -0.0005571647197939456,
                0.11280377209186554,
                0.043053265661001205,
                -0.009917770512402058,
                -0.021983196958899498,
                -0.02434707246720791,
                0.010265391319990158,
                -0.01377181801944971,
                -0.025869198143482208,
                0.06766986101865768,
                -0.03649985045194626,
                0.044278502464294434,
                -0.01290752924978733,
                -0.004825645592063665,
                0.009717248380184174,
                -0.07778450846672058,
                -0.03182515874505043,
                -0.014709141105413437,
                0.004705807194113731,
                -0.01639643870294094,
                0.0028200880624353886,
                -0.019352387636899948,
                -0.02470499649643898,
                -0.014136002399027348,
                0.03425128012895584,
                -0.03636835515499115,
                0.01391203049570322,
                -0.001368361059576273,
                0.01204584538936615,
                -0.045306798070669174,
                -0.03381132334470749,
                -0.007841644808650017,
                -0.021225301548838615,
                0.08541250973939896,
                -0.03854694962501526,
                0.013995302841067314,
                0.013159476220607758,
                -0.01986939273774624,
                -0.0030910614877939224,
                -0.011663848534226418,
                -0.011988354846835136,
                -0.041856467723846436,
                0.026363803073763847,
                -0.08407262712717056,
                -0.034121740609407425,
                -0.042436618357896805,
                -0.0005436918581835926,
                -0.055161092430353165,
                -0.017493246123194695,
                -0.025840280577540398,
                -0.0044202422723174095,
                0.013413963839411736,
                -0.05345005914568901,
                0.01861472986638546,
                0.04346533119678497,
                -0.02114482782781124,
                0.011290565133094788,
                -0.02580750547349453,
                0.10423821955919266,
                -0.021546462550759315,
                -0.03354528173804283,
                -0.00933934934437275,
                2.3721317177205492e-07,
                -0.0013811084209010005,
                -0.012572810985147953,
                -0.012404766865074635,
                0.06106375902891159,
                0.014172779396176338,
                -0.0022059089969843626,
                0.0017193048261106014,
                0.021535398438572884,
                -0.02210415154695511,
                -0.0075128525495529175,
                0.03271052613854408,
                0.02393122762441635,
                -0.044755738228559494,
                -0.009245301596820354,
                -0.027062419801950455,
                -0.06648869067430496,
                0.06644094735383987,
                0.011196144856512547,
                -0.027527490630745888,
                0.00026122486451640725,
                0.02709188126027584,
                -0.013326971791684628,
                0.0382751040160656,
                -0.014153270050883293,
                -0.04456177353858948,
                0.019546790048480034,
                -0.02684558928012848,
                -0.029219184070825577,
                0.07165249437093735,
                0.04276714473962784,
                0.04084501042962074,
                -0.005599362310022116,
                0.021586447954177856,
                0.04354378581047058,
                0.03574970364570618,
                -0.04502319172024727,
                0.049278005957603455,
                0.001661650836467743,
                0.025079915300011635,
                0.06457983702421188,
                -0.016827654093503952,
                0.001498851808719337,
                -0.0031480754259973764,
                -0.036544520407915115,
                0.03707122057676315,
                -0.0242985300719738,
                0.0002709861728362739,
                0.05968986079096794,
                -0.06216008588671684,
                -0.003253357019275427,
                0.019756676629185677,
                0.021405110135674477,
                -0.002444707090035081,
                0.03176277503371239,
                -0.01012788712978363,
                -0.005253504496067762,
                0.01673981361091137,
                0.040487196296453476,
                0.054660819470882416,
                0.024917541071772575,
                0.011003896594047546,
                -0.00023349703405983746,
                0.017293402925133705,
                -0.019953159615397453,
                0.021984703838825226,
                -0.003058522939682007,
                -0.026937071233987808,
                1.2630014104576122e-34,
                -0.013859808444976807,
                -0.045229073613882065,
                0.016194120049476624,
                0.04412763565778732,
                -0.011627824045717716,
                -0.013277987949550152,
                0.02350718341767788,
                -0.028452182188630104,
                0.016586458310484886,
                0.009435303509235382,
                -0.015591513365507126,
                -0.07666144520044327,
                0.018290063366293907,
                -0.026293514296412468,
                0.05183900147676468,
                -0.010881364345550537,
                -0.00221538869664073,
                0.030436433851718903,
                0.004535253159701824,
                -0.05402539297938347,
                0.019087133929133415,
                0.028696132823824883,
                0.001277329633012414,
                0.03552116081118584,
                0.07728470861911774,
                0.02125842310488224,
                -0.11540286988019943,
                0.031025946140289307,
                0.028160560876131058,
                -0.03521537408232689,
                0.007001361344009638,
                0.03738705813884735,
                -0.05098838731646538,
                0.0034969986882060766,
                -0.027265846729278564,
                -0.01663653552532196,
                -0.04618452861905098,
                -0.009752199985086918,
                -0.006188756786286831,
                0.009663146920502186,
                -0.05292081460356712,
                -0.023658964782953262,
                0.027987834066152573,
                0.028754141181707382,
                -0.03924857825040817,
                1.775016698957188e-06,
                -0.01031599473208189,
                -0.010767395608127117,
                0.041615840047597885,
                -0.06638690084218979,
                0.03835991397500038,
                0.057137273252010345,
                -0.0014350091805681586,
                0.0447508804500103,
                -0.0014874069020152092,
                -0.0221326295286417,
                -0.05887319892644882,
                0.002898896113038063,
                0.022218188270926476,
                0.04704933986067772,
                -0.03378210961818695,
                0.021667703986167908,
                -0.02479938417673111,
                -0.057237155735492706,
                -0.018129775300621986,
                0.0014833789318799973,
                0.07560374587774277,
                -0.010649374686181545,
                0.05762635916471481,
                0.07469448447227478,
                -0.007653677836060524,
                -0.00029887768323533237,
                0.0066780452616512775,
                -0.0120698232203722,
                0.01426526065915823,
                0.0296471044421196,
                0.023813189938664436,
                0.007915697060525417,
                0.027356376871466637,
                -0.0036822275724262,
                0.05165288224816322,
                0.08384578675031662,
                -0.001558793126605451,
                0.017507540062069893,
                -0.010066033340990543,
                0.01293523982167244,
                -0.024635953828692436,
                -0.07341884821653366,
                -0.0019518955377861857,
                -0.020164843648672104,
                -0.0031291316263377666,
                -0.035295017063617706,
                0.03725419566035271,
                -0.011905703693628311,
                0.005732587538659573,
                -0.02675703540444374,
                -0.02152131497859955,
                -0.02883145958185196,
                -0.012431284412741661,
                -0.04598511382937431,
                -0.02713649533689022,
                0.012379666790366173,
                -0.027519527822732925,
                0.00726096099242568,
                0.024335952475667,
                -0.031981028616428375,
                0.0005620213341899216,
                0.024811137467622757,
                -0.07682894170284271,
                0.011295256204903126,
                -0.06521400809288025,
                0.04445143789052963,
                0.008857769891619682,
                0.018302572891116142,
                0.017057612538337708,
                0.05320358648896217,
                0.044426705688238144,
                -0.039271220564842224,
                0.009764371439814568,
                -0.0005723033682443202,
                0.025198258459568024,
                -0.007470464799553156,
                0.006243985146284103,
                -0.028973011299967766,
                0.046454720199108124,
                -0.013559907674789429,
                0.03298554569482803,
                -0.027865217998623848,
                0.003434891579672694,
                -0.038828931748867035,
                0.04903842881321907,
                0.01930214650928974,
                6.914813275216147e-05,
                -0.015310069546103477,
                0.040313150733709335,
                -0.022957952693104744,
                0.0080148596316576,
                -0.029840264469385147,
                -0.02017953433096409,
                0.02765071950852871,
                -0.05557942017912865,
                -0.004062516614794731,
                -0.024165019392967224,
                0.021130556240677834,
                0.031195152550935745,
                -0.02765507809817791,
                0.06705925613641739,
                0.010339420288801193,
                0.07484136521816254,
                -0.10497312247753143,
                -0.04251084849238396,
                0.03046025149524212,
                0.006893637124449015,
                0.022684641182422638,
                -0.01121179386973381,
                0.0556667298078537,
                0.013076226226985455,
                0.0005906628211960196,
                0.007750982418656349,
                0.0101056769490242,
                0.019482407718896866,
                0.0040626320987939835,
                0.046383727341890335,
                -0.01856667920947075,
                0.011455383151769638,
                0.0162501260638237,
                0.04386545345187187,
                -0.021719064563512802,
                -0.010054873302578926,
                0.04561814293265343,
                0.0018270009895786643,
                -0.024948306381702423,
                -0.0019340505823493004,
                0.05511573702096939,
                -0.02390410751104355,
                0.04803008586168289,
                0.0237420666962862,
                -0.0405232235789299,
                0.04358946159482002,
                -0.0026507852599024773,
                0.018099406734108925,
                -0.07348528504371643,
                0.0003415136015973985,
                -0.02582073025405407,
                0.07817971706390381,
                0.020863588899374008,
                -0.020742425695061684,
                0.06985680758953094,
                0.00868279580026865,
                -0.05659438297152519,
                0.05467422306537628,
                -0.015328137204051018,
                -0.06211455538868904,
                0.007865837775170803,
                0.08081401884555817,
                0.09459245204925537,
                -0.025635119527578354,
                -0.01070856861770153,
                -0.04350777715444565,
                -0.013644556514918804,
                -0.03877865895628929,
                -0.010264999233186245,
                -0.006947632413357496,
                -0.0026860006619244814,
                0.041030317544937134,
                -0.05782175809144974,
                0.030521253123879433,
                -0.0013575260527431965,
                -0.02218969538807869,
                0.03674088418483734,
                -0.05545671284198761,
                -0.012450631707906723,
                -0.0014398511266335845,
                0.0348316989839077,
                0.021878274157643318,
                -0.03316396847367287,
                0.017992014065384865,
                0.019171588122844696,
                -0.040653374046087265,
                0.052417267113924026,
                -0.008968827314674854,
                0.057807840406894684,
                -0.02007478103041649,
                0.0028346367180347443,
                0.010046896524727345,
                0.01669602282345295,
                0.03560160845518112,
                -0.015314515680074692,
                0.015219383873045444,
                -0.07436917722225189,
                0.011259002611041069,
                0.017769912257790565,
                -0.01979103870689869,
                0.013295837678015232,
                -0.0037529561668634415,
                0.05465595796704292,
                0.03181805461645126,
                0.05959010869264603,
                -0.009180525317788124,
                0.04568392038345337,
                0.019104866310954094,
                0.010845420882105827,
                0.0162463691085577,
                0.011450957506895065,
                0.04190903902053833,
                0.05002216249704361,
                0.009876313619315624,
                0.023608820512890816,
                -0.005306443199515343,
                -0.02264755219221115,
                -0.05125444382429123,
                0.04949066787958145,
                -0.07786931842565536,
                -0.029332678765058517,
                -0.011561146937310696,
                -0.001360583701170981,
                -0.01829305663704872,
                0.009335717186331749,
                0.01883571967482567,
                0.010050446726381779,
                0.02560102567076683,
                -0.018270006403326988,
                0.02784075401723385,
                0.029338793829083443,
                0.011741953901946545,
                0.03529464453458786,
                0.03882116451859474,
                0.0001933219173224643,
                0.02450106106698513,
                -0.10229092836380005,
                0.09084071964025497,
                0.017130380496382713,
                -0.014161346480250359,
                0.03448374569416046,
                -0.0024482591543346643,
                -0.04985427111387253,
                -0.0164321418851614,
                0.0639059841632843,
                0.004380739293992519,
                -0.04246411845088005,
                0.035163186490535736,
                0.019863421097397804,
                0.0006781095871701837,
                -0.0011018317891284823,
                -0.029314815998077393,
                -0.03991362825036049,
                -0.05654371157288551,
                -0.04170892760157585,
                -0.005202843341976404,
                -0.029818754643201828,
                0.03675507754087448,
                0.09049826860427856,
                0.015909649431705475,
                -0.037216026335954666,
                -0.005034709349274635,
                0.004463000223040581,
                0.014832788147032261,
                0.035705987364053726,
                -0.041459791362285614,
                -0.02338113822042942,
                -0.0487968735396862,
                0.0012578590540215373,
                0.003944065887480974,
                0.026707088574767113,
                0.01875382661819458,
                -0.002226715674623847,
                0.010259011760354042,
                0.039561111479997635,
                -0.03150944411754608,
                -0.04833971709012985,
                0.01111868117004633,
                0.011126479133963585,
                -0.03567171469330788,
                -0.033093441277742386,
                -0.012574784457683563,
                -0.011835139244794846,
                0.13477803766727448,
                0.029936179518699646,
                -0.034282609820365906,
                0.03571423515677452,
                -0.02850867062807083,
                0.06392936408519745,
                0.013221601024270058,
                0.04195274040102959,
                0.011827978305518627,
                -0.0016426148358732462,
                0.043228473514318466,
                -0.03881357982754707,
                -0.034203700721263885,
                0.009068374522030354,
                0.005833503324538469,
                -0.013966447673738003,
                0.014802333898842335,
                -0.01143547985702753,
                -0.022906675934791565,
                0.03103947639465332,
                0.03449038788676262,
                -0.020807385444641113,
                0.023622052744030952,
                0.03298687934875488,
                -0.04642429202795029,
                -0.005449975375086069,
                -0.06756502389907837,
                -0.008662644773721695,
                -0.000719043891876936,
                -0.04760720580816269,
                0.032468803226947784,
                -0.062255073338747025,
                -0.05525705963373184,
                -0.04019720479846001,
                -0.05524628236889839,
                -0.042595185339450836,
                -0.02423158846795559,
                -0.012925254181027412,
                -0.014278054237365723,
                0.04168061912059784,
                -0.004365729633718729,
                0.03821113333106041,
                0.018174655735492706,
                -0.048219408839941025,
                -0.04484182968735695,
                0.06646528840065002,
                -0.006814527790993452,
                0.01768532581627369,
                -0.018521668389439583,
                0.027977759018540382,
                -0.05772591382265091,
                -0.04796689376235008,
                0.022455010563135147,
                -0.025091005489230156,
                -0.025594787672162056,
                -0.01291328202933073,
                0.02524678222835064,
                -0.012311376631259918,
                0.04895833879709244,
                -0.05409378185868263,
                -0.013412133790552616,
                -0.02415701001882553,
                0.010606247931718826,
                -0.024414896965026855,
                0.019596094265580177,
                -0.026216935366392136,
                0.022142887115478516,
                0.07330360263586044,
                0.00725102424621582,
                0.01712075062096119,
                -0.03426133468747139,
                0.024200348183512688,
                0.032740458846092224,
                -0.06669098138809204,
                -0.0016724868910387158,
                0.05168791115283966,
                -0.06858903914690018,
                0.05352664366364479,
                -0.010825075209140778,
                0.05912637710571289,
                -0.017738761380314827,
                -0.02745089679956436,
                -0.05025586858391762,
                0.005715979728847742,
                0.03980807214975357,
                0.023202378302812576,
                0.03970183804631233,
                -0.07382043451070786,
                -0.046967558562755585,
                0.015729906037449837,
                -0.0076427822932600975,
                -0.018974317237734795,
                0.0005888465675525367,
                0.003090471960604191,
                0.00037590417196042836,
                0.017711715772747993,
                -0.005018056370317936,
                -0.032294873148202896,
                -0.03241351246833801,
                0.029459074139595032,
                -0.0386601984500885,
                -0.011648988351225853,
                0.052878908812999725,
                -0.015219168737530708,
                -0.004121508449316025,
                -0.05986178666353226,
                0.04832367226481438,
                -0.0071464525535702705,
                -0.05409679189324379,
                0.04177694022655487,
                0.022117773070931435,
                -0.017062818631529808,
                -0.026298053562641144,
                0.012258936651051044,
                0.09651794284582138,
                0.02336338348686695,
                -0.002833088394254446,
                0.009871337562799454,
                -0.05532955005764961,
                0.00842380803078413,
                -0.03615729510784149,
                -0.010730408132076263,
                0.07143930345773697,
                0.005495918914675713,
                0.020174609497189522,
                0.0407734215259552,
                0.026132069528102875,
                -0.00106678600423038,
                0.06182805076241493,
                0.028468823060393333,
                0.04546327888965607,
                0.003368781181052327,
                -0.023493273183703423,
                0.00958455353975296,
                -0.02207856997847557,
                -0.04036981984972954,
                0.025968151167035103,
                0.04886389151215553,
                0.04750984534621239,
                -0.03360994905233383,
                -0.007424567826092243,
                -0.012634445913136005,
                -0.040190115571022034,
                0.005231023766100407,
                0.00986604206264019,
                0.026460394263267517,
                -0.026074327528476715,
                -0.0433569960296154,
                -0.011299412697553635,
                -0.0015178363537415862,
                -0.016487762331962585,
                0.0007451730780303478,
                -0.06313610821962357,
                -0.001232959795743227,
                -0.0009859137935563922,
                -0.002770404564216733,
                0.017353149130940437,
                -0.07102244347333908,
                0.01771462894976139,
                -0.019043905660510063,
                0.007757405284792185,
                0.034329477697610855,
                -0.026120979338884354,
                -0.06306443363428116,
                -0.06596700102090836,
                -0.007907887920737267,
                -0.0013747604098170996,
                -0.025431953370571136,
                -0.0005249383393675089,
                0.026046767830848694,
                0.008883492089807987,
                -0.04708777740597725,
                -0.031998056918382645,
                0.04649507999420166,
                -0.0053253513760864735,
                0.03905411809682846,
                0.06516275554895401,
                0.01655535399913788,
                -0.12880758941173553,
                -0.012432150542736053,
                -0.001831144792959094,
                -0.053048331290483475,
                -0.044868096709251404,
                0.002654863055795431,
                -0.03180035576224327,
                -0.01237853616476059,
                -0.009914345107972622,
                0.03638804331421852,
                0.03521675243973732,
                0.014419110491871834,
                -0.07546550780534744,
                -0.1136181429028511,
                0.0413065105676651,
                0.04019921272993088,
                -0.09017542004585266,
                -0.032566457986831665,
                0.04880034923553467,
                -0.02425246872007847,
                -0.04980657622218132,
                0.02328113280236721,
                0.04807870835065842,
                -0.00440305145457387,
                0.03874865174293518,
                0.004546632058918476,
                0.004779560957103968,
                0.027312496677041054,
                0.028698744252324104,
                0.014882600866258144,
                0.003082908457145095,
                0.0025711830239742994,
                -0.0007223316351883113,
                -0.10561607778072357,
                0.06363844126462936,
                -0.04872376099228859,
                -0.029832005500793457,
                0.03947514668107033,
                -0.03358566388487816,
                0.031337857246398926,
                -0.04786640778183937,
                0.04994773119688034,
                0.02589099109172821,
                -0.015563923865556717,
                -0.01981208845973015,
                -0.047537822276353836,
                0.008228197693824768,
                -0.03755390644073486,
                -0.08333171159029007,
                -0.07530294358730316,
                -0.01042024977505207,
                -0.05111104995012283,
                0.049561552703380585,
                0.0608525425195694,
                -0.04611334949731827,
                -0.02416136860847473,
                -0.019818369299173355,
                -0.005690287798643112,
                -0.025544876232743263,
                -0.03096669539809227,
                -0.010111307725310326,
                -0.006468822248280048,
                -0.032125357538461685,
                -0.0022428627125918865,
                -0.018529856577515602,
                0.004715252202004194,
                0.00697061512619257,
                0.006588462274521589,
                0.017619509249925613,
                0.048076730221509933,
                -0.05852796882390976,
                -0.016424711793661118,
                -6.743963170249101e-33,
                -0.034367870539426804,
                -0.006669516209512949,
                -0.009853389114141464,
                -0.008201939053833485,
                0.044554755091667175,
                -0.03624853119254112,
                0.020835094153881073,
                0.005528120324015617,
                -0.06243433430790901,
                0.0026924072299152613,
                -0.023468786850571632,
                0.02132800966501236,
                0.01776377111673355,
                0.0002568472409620881,
                -0.023836299777030945,
                -0.03009481355547905,
                0.029362771660089493,
                0.0034406515769660473,
                -0.05414249002933502,
                -0.01591273583471775,
                -0.04655318707227707,
                0.001682103844359517,
                0.05126537010073662,
                -0.07310508191585541,
                0.06096625328063965,
                0.0016828353982418776,
                -0.03353647515177727,
                0.01840227283537388,
                -0.0014745729276910424,
                -0.0342198982834816,
                0.003934393171221018,
                0.0005681662587448955,
                0.0037638312205672264,
                -0.011043296195566654,
                -0.06553981453180313,
                0.07381656765937805,
                -0.05396860092878342,
                -0.04897206649184227,
                0.001957763684913516,
                0.0303215142339468,
                -0.0031876356806606054,
                0.012653177604079247,
                0.009061094373464584,
                -0.03050701506435871,
                0.011217555962502956,
                -0.03138326108455658,
                0.004069297108799219,
                0.0503365620970726,
                -0.022477230057120323,
                0.04967786371707916,
                0.05566874146461487,
                0.014411438256502151,
                -0.014029158279299736,
                0.0332905575633049,
                0.05895555764436722,
                0.017025690525770187,
                -0.008427483960986137,
                -0.02841559424996376,
                -0.016429174691438675,
                0.0522255003452301,
                0.0888703316450119,
                -0.003977334592491388,
                0.05036673694849014,
                -0.031936731189489365,
                0.0425671748816967,
                -0.014878924004733562,
                -0.004313858691602945,
                0.05221385508775711,
                -0.024153105914592743,
                -0.0010040869237855077,
                -0.03939850255846977,
                0.06232011690735817,
                0.01934080943465233,
                -0.013013195246458054,
                0.014333318918943405,
                -0.02747058868408203,
                0.0035507252905517817,
                0.021733449772000313,
                -0.04963720217347145,
                -0.05231205001473427,
                -0.055885400623083115,
                0.010723339393734932,
                -0.04939746856689453,
                -0.01885209046304226,
                0.008972871117293835,
                0.005057858303189278,
                -0.0002806448901537806,
                -0.013485477305948734,
                -0.018732110038399696,
                -0.026414858177304268,
                -0.016114292666316032,
                0.07656979560852051,
                -0.010427255183458328,
                0.02405623346567154,
                -0.06382595747709274,
                0.0036916607059538364,
                0.08280430734157562,
                0.0011187436757609248,
                -0.016010800376534462,
                -0.05293223261833191,
                0.024518826976418495,
                0.009137449786067009,
                0.03322221338748932,
                0.03957008570432663,
                -0.0044411225244402885,
                0.023605672642588615,
                0.0016652533086016774,
                0.01573045551776886,
                -0.02592385746538639,
                -0.005419251974672079,
                0.030644157901406288,
                -0.01930721290409565,
                -0.008462498895823956,
                -0.08393450826406479,
                -0.0007622140692546964,
                0.03927815333008766,
                0.021755551919341087,
                0.002155297202989459,
                0.002764242235571146,
                -0.020928556099534035,
                -0.010010618716478348,
                -0.00950197596102953,
                -0.04081064462661743,
                -0.008491242304444313,
                0.01574987918138504,
                -0.018705448135733604,
                -0.010107848793268204,
                -0.01596636138856411,
                0.06074993684887886,
                0.02136816829442978,
                -0.0141801992431283,
                -0.008491408079862595,
                2.2440381997057557e-07,
                0.011293348856270313,
                -0.0007562002283520997,
                -0.009329535067081451,
                0.12218410521745682,
                0.018152305856347084,
                -0.05735267326235771,
                -0.009470961056649685,
                0.03439806401729584,
                -0.00470703886821866,
                -0.04471782594919205,
                0.025964906439185143,
                0.021025020629167557,
                0.023103389889001846,
                0.07657358795404434,
                0.03213626891374588,
                -0.07438085228204727,
                -0.03262979909777641,
                -0.043433789163827896,
                -0.02790224552154541,
                0.021781733259558678,
                0.03210640698671341,
                0.0411762036383152,
                -0.003071349114179611,
                0.01807895302772522,
                -0.007444371934980154,
                -0.0047458927147090435,
                -0.0005976983811706305,
                -0.06032456085085869,
                0.04357762634754181,
                -0.004131035413593054,
                0.009119335561990738,
                -0.010703749023377895,
                0.014124407432973385,
                0.061129793524742126,
                -0.02010340429842472,
                -0.029873766005039215,
                -0.019030194729566574,
                0.005165939684957266,
                0.035039838403463364,
                0.0632210299372673,
                0.02613237127661705,
                -0.03804340958595276,
                -0.01723010651767254,
                0.038214411586523056,
                -0.017053253948688507,
                -0.002261640504002571,
                0.005388718564063311,
                0.10263277590274811,
                0.0021140759345144033,
                -0.03053329512476921,
                0.02732115611433983,
                0.007264645770192146,
                0.02932717464864254,
                -0.0023664983455091715,
                0.004458654671907425,
                -0.004653970245271921,
                -0.01602252386510372,
                0.040804672986269,
                0.000854426936712116,
                0.0014599172864109278,
                -0.011135523207485676,
                -0.01579006016254425,
                0.006783957593142986,
                -0.05662321299314499,
                0.025461453944444656,
                0.022972149774432182,
                -0.011132964864373207,
                1.407987962755113e-34,
                0.014245794154703617,
                -0.006447024643421173,
                -0.00864540133625269,
                -0.011038077063858509,
                0.009553859010338783,
                -0.006591887678951025,
                0.06162559986114502,
                -0.03325366601347923,
                0.036272697150707245,
                0.00048237835289910436,
                -0.03110898844897747,
                0.06592659652233124,
                -0.021937647834420204,
                -0.000717715360224247,
                -0.022774850949645042,
                -0.012794344685971737,
                0.08252991735935211,
                0.02193564735352993,
                -0.016835959628224373,
                0.0032832876313477755,
                -0.004432633053511381,
                0.09833279252052307,
                -0.03514960780739784,
                0.0265851691365242,
                -0.01128265168517828,
                -0.030346622690558434,
                0.018274227157235146,
                0.001302115386351943,
                0.02959025837481022,
                0.013567602261900902,
                -0.017356857657432556,
                -0.03607133775949478,
                0.06114014610648155,
                0.01391772460192442,
                -0.006784465629607439,
                -0.02330334670841694,
                -0.031387098133563995,
                0.03608216345310211,
                0.023198384791612625,
                0.03276124596595764,
                0.026460768654942513,
                0.09060540050268173,
                -0.06922788918018341,
                -0.00934099592268467,
                -0.010894440114498138,
                1.6988537936413195e-06,
                -0.003681725822389126,
                0.0021073571406304836,
                -0.001995180267840624,
                -0.10878551006317139,
                -0.034348469227552414,
                -0.0101317148655653,
                -0.029022449627518654,
                -0.011541674844920635,
                0.06830831617116928,
                0.007771653588861227,
                0.06724798679351807,
                0.05918150767683983,
                -0.014121927320957184,
                -0.013603471219539642,
                0.0001625059376237914,
                -0.034941963851451874,
                -0.024126779288053513,
                -0.10410717874765396,
                -0.033319681882858276,
                0.018483707681298256,
                -0.050826724618673325,
                0.008621559478342533,
                0.017486829310655594,
                0.030518896877765656,
                -0.013003483414649963,
                5.806057379231788e-05,
                -0.007325705140829086,
                -0.008759666234254837,
                -0.029188105836510658,
                0.006976959761232138,
                0.0209408737719059,
                0.038726188242435455,
                0.001525451778434217,
                0.02506616711616516,
                -0.028060609474778175,
                -0.025961531326174736,
                0.062476396560668945,
                -0.025819141417741776,
                0.12116570770740509,
                -0.01164476852864027,
                -0.018446430563926697,
                0.025131406262516975,
                0.014277036301791668,
                -0.038713421672582626,
                -0.014800225384533405,
                -0.03505164757370949,
                0.0036811872851103544,
                0.02154126577079296,
                0.002962239785119891,
                0.005656678229570389,
                0.014872897416353226,
                -0.0036291645374149084,
                -0.018140995875000954,
                0.0018997496226802468,
                0.013434813357889652,
                -0.02007906883955002,
                -0.0014047855511307716,
                0.030559919774532318,
                0.034246206283569336,
                -0.04068482294678688,
                -0.04138389602303505,
                0.038180772215127945,
                -0.00744320685043931,
                -0.02161874808371067,
                -0.008177977986633778,
                -0.038155969232320786,
                0.026002828031778336,
                -0.03583996370434761,
                0.0461881048977375,
                0.0011334854643791914,
                -0.00775425648316741,
                0.06787293404340744,
                -0.0073868934996426105,
                -0.018724998459219933,
                0.021572759374976158,
                0.03763076663017273,
                0.004524383693933487,
                -0.013522036373615265,
                0.03478669002652168,
                0.04813556373119354,
                -0.018032370135188103,
                0.004509127698838711,
                0.03233218565583229,
                0.006531145889312029,
                0.03817575052380562,
                0.012979630380868912,
                0.03560136631131172,
                -0.049012184143066406,
                -0.01171067077666521,
                -0.010508686304092407,
                0.019742321223020554,
                -0.10888195782899857,
                -0.011773576028645039,
                0.013924929313361645,
                0.016994034871459007,
                0.009341556578874588,
                -0.006804125849157572,
                -0.012455684132874012,
                -0.0018192501738667488,
                0.027186619117856026,
                0.015824250876903534,
                -0.011569251306355,
                0.0010709930211305618,
                -0.09703079611063004,
                -0.018117601051926613,
                -0.015804918482899666,
                -0.06136979162693024,
                -0.059535909444093704,
                0.02166230045258999,
                -0.03689735755324364,
                -0.0060110269114375114,
                0.0005390975857153535,
                0.033473849296569824,
                0.016620229929685593,
                -0.00467110937461257,
                -0.02221839316189289,
                0.009036938659846783,
                -0.025800850242376328,
                0.01949344389140606,
                0.07186325639486313,
                0.0323239266872406,
                0.06309713423252106,
                -0.029099877923727036,
                -0.050116460770368576,
                -0.02668803185224533,
                0.040671177208423615,
                -0.008007927797734737,
                0.01894223876297474,
                -0.014697013422846794,
                -0.04099110886454582,
                0.02184068225324154,
                0.062261540442705154,
                -0.009534220211207867,
                -0.08005622774362564,
                0.014114195480942726,
                0.02862546592950821,
                -0.0347217321395874,
                -0.037009257823228836,
                0.009932360611855984,
                -0.028239861130714417,
                -0.009899412281811237,
                -0.024746697396039963,
                -0.0132271908223629,
                -0.005050718784332275,
                0.014574631117284298,
                -0.06406581401824951,
                -0.08905422687530518,
                0.0053782290779054165,
                -0.00898054614663124,
                -0.00624110596254468,
                0.013876414857804775,
                -0.006164542865008116,
                -0.0044800275936722755,
                -0.04613596573472023,
                -0.018757754936814308,
                -0.016254406422376633,
                -0.05135791748762131,
                -0.06681966036558151,
                0.003519410500302911,
                0.013993285596370697,
                0.00905690435320139,
                -0.005395399406552315,
                -0.02951609529554844,
                -0.0353701189160347,
                -0.01551923155784607,
                -0.04521443694829941,
                -0.03028327040374279,
                0.03255549073219299,
                0.12374895811080933,
                -0.018033523112535477,
                -0.004404024686664343,
                -0.015300223603844643,
                0.015073418617248535,
                -0.04517845809459686,
                -0.01430859137326479,
                -0.0025633638724684715,
                0.011630694381892681,
                -0.025762958452105522,
                0.04244166240096092,
                -0.04023987427353859,
                0.015867367386817932,
                0.01978362910449505,
                0.01144606526941061,
                -0.04645592346787453,
                0.04277138411998749,
                -0.0011009692680090666,
                0.03914450854063034,
                -0.00911702960729599,
                -0.042846374213695526,
                -0.023067450150847435,
                -0.019179366528987885,
                -0.001577775925397873,
                0.018350299447774887,
                -0.0125984326004982,
                0.007561852224171162,
                0.02251191809773445,
                0.03424598276615143,
                0.04123329743742943,
                -0.0022800294682383537,
                0.018351104110479355,
                0.047666583210229874,
                0.06859703361988068,
                0.0064241159707307816,
                0.02237420156598091,
                -0.04229751229286194,
                -0.0206267349421978,
                0.08030561357736588,
                0.0219121016561985,
                -0.01089343149214983,
                0.048071108758449554,
                -0.02353128418326378,
                -0.014468633569777012,
                0.007705543655902147,
                0.006072866730391979,
                0.025755085051059723,
                -0.027925098314881325,
                -0.018935952335596085,
                0.03316611796617508,
                -0.009006811305880547,
                0.03497777134180069,
                -0.06753494590520859,
                -0.013197152875363827,
                0.06506454944610596,
                -0.06963671743869781,
                -0.08047102391719818,
                0.021652933210134506,
                -0.03520895168185234,
                -0.020038649439811707,
                -0.0028361142612993717,
                0.02668146789073944,
                -0.007202672306448221,
                0.042940858751535416,
                -0.06893246620893478,
                -0.010311145335435867,
                -0.0037296090740710497,
                -0.041117433458566666,
                -0.02014225162565708,
                0.014247463084757328,
                -0.012763358652591705,
                -0.0006683917599730194,
                -0.0023142420686781406,
                -0.023618213832378387,
                -0.0035122353583574295,
                -0.017099954187870026,
                0.016312995925545692,
                0.011687243357300758,
                -0.05878892168402672,
                -0.032356731593608856,
                0.004878639709204435,
                0.0048903063870966434,
                -0.04236537590622902,
                0.026626871898770332,
                -0.0011121205752715468,
                -0.05186888575553894,
                -0.0012147092493250966,
                -0.044725336134433746,
                -0.013087532483041286,
                0.0014677081489935517,
                -0.04304569959640503,
                0.009479141794145107,
                -0.010204062797129154,
                -0.08052582293748856,
                0.0274896789342165,
                -0.006631121039390564,
                0.016741478815674782,
                -0.04780116677284241,
                -0.031378354877233505,
                0.06221375614404678,
                -0.012623226270079613,
                -0.04320686310529709,
                -0.10497690737247467,
                0.024125829339027405,
                -0.02756637893617153,
                -0.01477024145424366,
                0.039849016815423965,
                0.015578682534396648,
                0.0014080146793276072,
                0.0076479725539684296,
                0.017100675031542778,
                0.019043607637286186,
                0.01571602188050747,
                -0.003400634741410613,
                0.015612354502081871,
                -0.010584075003862381,
                0.13625606894493103,
                0.04856360703706741,
                0.035475220531225204,
                -0.009179890155792236,
                0.002239289227873087,
                0.04573303461074829,
                -0.01625104993581772,
                -0.014303573407232761,
                0.00907199177891016,
                -0.02620605006814003,
                -0.044760167598724365,
                0.005290266592055559,
                -0.0031481662299484015,
                -0.01508333720266819,
                0.09031539410352707,
                0.0046285116113722324,
                0.026340235024690628,
                -0.04074897989630699,
                -0.08593680709600449,
                -0.03268923610448837,
                -0.017070144414901733,
                0.010451609268784523,
                -0.03630708530545235,
                -0.023859761655330658,
                0.02155303955078125,
                -0.03750192001461983,
                -0.031172452494502068,
                0.054566994309425354,
                0.014216704294085503,
                -0.06387870758771896,
                -0.03297072649002075,
                -0.010621394030749798,
                0.05926138535141945,
                -0.008176260627806187,
                -0.022051449865102768,
                -0.02418302744626999,
                -0.04366845265030861,
                -0.06669106334447861,
                0.0578322671353817,
                0.03991527482867241,
                -0.03107718750834465,
                -0.0009682242525741458,
                -0.021622035652399063,
                0.032395489513874054,
                -0.018458448350429535,
                0.050115954130887985,
                0.01638961210846901,
                0.057257458567619324,
                0.03330725058913231,
                -0.04003557935357094,
                -0.002249279757961631,
                -0.03485148400068283,
                0.041169170290231705,
                -0.017357273027300835,
                0.020582953467965126,
                0.01466984674334526,
                -0.03199249133467674,
                0.008292602375149727,
                -0.00635675061494112,
                -0.03655078634619713,
                0.022403717041015625,
                0.013078562915325165,
                -0.029275627806782722,
                0.03439961373806,
                0.048357341438531876,
                0.03704604133963585,
                -0.02677302062511444,
                -0.01067354530096054,
                -0.037571024149656296,
                -0.020894775167107582,
                0.05558087304234505,
                0.0012163647916167974,
                0.040309175848960876,
                -0.0863151103258133,
                0.08866895735263824,
                0.013336114585399628,
                0.009352578781545162,
                0.06619875878095627,
                0.006137080490589142,
                -0.033058036118745804,
                0.0067168939858675,
                0.0028309577610343695,
                0.025212429463863373,
                -0.0038800521288067102,
                0.030611835420131683,
                -0.04053036868572235,
                -0.0483224056661129,
                0.09275029599666595,
                -0.03192996606230736,
                0.046599939465522766,
                0.021108577027916908,
                -0.005024509970098734,
                0.038870666176080704,
                0.016049031168222427,
                0.01992744766175747,
                -0.03492138534784317,
                -0.06442704051733017,
                -0.01606864482164383,
                0.039430275559425354,
                0.030831849202513695,
                -0.046802520751953125,
                0.06334072351455688,
                -0.0024511797819286585,
                -0.05858295410871506,
                0.018412739038467407,
                0.02446943148970604,
                -0.009281530976295471,
                0.08739504218101501,
                0.04169956594705582,
                0.06713913381099701,
                -0.017077920958399773,
                -0.04332161322236061,
                -0.005069124512374401,
                -0.020474692806601524,
                0.028994804248213768,
                0.06846880912780762,
                -0.02527572400867939,
                0.034474797546863556,
                0.03904901072382927,
                -0.03269930183887482,
                0.02352134883403778,
                -0.015332034789025784,
                0.03375739976763725,
                -0.023410368710756302,
                -0.016205336898565292,
                -0.060158587992191315,
                -0.019930094480514526,
                0.015580112114548683,
                0.023039521649479866,
                -0.027126729488372803,
                0.013200579211115837,
                0.07673025131225586,
                -0.09328807145357132,
                0.05487633869051933,
                -0.09850013256072998,
                0.011954880319535732,
                -0.01495384518057108,
                -0.06579829007387161,
                0.0032496845815330744,
                0.03637784346938133,
                0.026187021285295486,
                0.052518900483846664,
                0.044815655797719955,
                0.012252334505319595,
                -0.010613451711833477,
                -0.014303497970104218,
                -0.12753576040267944,
                0.08403157442808151,
                -0.08683435618877411,
                0.038284674286842346,
                -0.03742699697613716,
                0.018386078998446465,
                -0.023255648091435432,
                -0.011719954200088978,
                0.0047339764423668385,
                0.006371397990733385,
                -0.03484669327735901,
                -0.030161993578076363,
                -0.02282104641199112,
                0.032180529087781906,
                0.019685914739966393,
                0.011701928451657295,
                -0.015763210132718086,
                0.02837989293038845,
                0.012586439028382301,
                0.022138994187116623,
                0.013915943913161755,
                0.04853557422757149,
                -0.017132531851530075,
                -0.008164123632013798,
                -0.029755720868706703,
                -0.04369605332612991,
                -0.037304215133190155,
                0.023947354406118393,
                -0.058126840740442276,
                0.006593232974410057,
                0.010343155823647976,
                -0.012674443423748016,
                -0.013448321260511875,
                -0.03367716446518898,
                -0.05975673720240593,
                -0.019266273826360703,
                0.016270508989691734,
                0.007726199924945831,
                0.019779149442911148,
                0.008765353821218014,
                0.016003794968128204,
                0.03641438111662865,
                0.031174032017588615,
                0.0016750089125707746,
                0.026474038138985634,
                -5.738254003517795e-06,
                -0.015885762870311737,
                0.004720805212855339,
                -0.13714508712291718,
                0.030593369156122208,
                0.03628037869930267,
                -0.057900018990039825,
                -0.0753784105181694,
                -0.017916150391101837,
                -0.006378090009093285,
                -0.008546988479793072,
                -0.017987288534641266,
                0.015804218128323555,
                0.07160209119319916,
                0.013052311725914478,
                -0.019280195236206055,
                0.01994159072637558,
                0.008219427429139614,
                -0.04746245965361595,
                0.04054956138134003,
                -0.05734238028526306,
                0.012861238792538643,
                -0.05947766825556755,
                0.012046563439071178,
                -0.03410966321825981,
                0.08895376324653625,
                0.021134639158844948,
                0.004323944449424744,
                0.010312606580555439,
                0.0009602524805814028,
                -0.06733402609825134,
                0.00961505901068449,
                0.008491664193570614,
                0.02621879242360592,
                -0.0008772710571065545,
                0.026653088629245758,
                -0.004007561597973108,
                0.043507542461156845,
                -0.00695048226043582,
                0.03240952640771866,
                -0.008853953331708908,
                -0.01202279794961214,
                -0.036529600620269775,
                0.03323772922158241,
                0.010158131830394268,
                -6.391986366150322e-33,
                -0.0547151044011116,
                -0.05568908900022507,
                -0.016733679920434952,
                0.06316012144088745,
                -0.04973478615283966,
                0.012161274440586567,
                0.0366419218480587,
                0.02971072494983673,
                0.018937433138489723,
                -0.017882680520415306,
                -0.017778916284441948,
                0.03036476857960224,
                0.03382110223174095,
                0.003583391197025776,
                -0.012641209177672863,
                -0.03946439176797867,
                0.02010110765695572,
                0.00700413016602397,
                0.005962068680673838,
                -0.0007254338706843555,
                -0.0010924708331003785,
                0.019631346687674522,
                -0.0029375574085861444,
                -0.03958698362112045,
                0.033790223300457,
                -0.010358612053096294,
                -0.051948048174381256,
                0.03956723213195801,
                0.0027561946772038937,
                -0.0336824469268322,
                -0.023202691227197647,
                0.02776769921183586,
                -0.009217029437422752,
                -0.03935815021395683,
                0.032687120139598846,
                0.03537081554532051,
                -0.026260463520884514,
                0.009040258824825287,
                -0.005249092821031809,
                0.03927391767501831,
                -0.07187493145465851,
                -0.03815954178571701,
                0.0006422846345230937,
                -0.009218472987413406,
                0.009180662222206593,
                -0.002269383054226637,
                0.02605624869465828,
                -0.027358507737517357,
                0.015401829034090042,
                0.032585177570581436,
                0.004618624225258827,
                0.0016915907617658377,
                -0.06802736967802048,
                -0.04873407632112503,
                0.013810454867780209,
                0.06518939882516861,
                -0.00033732331939972937,
                0.06293141096830368,
                -0.009308147244155407,
                -0.027505768463015556,
                -0.00016230855544563383,
                -0.002275669714435935,
                -0.05559086427092552,
                -0.03352249413728714,
                -0.02870868518948555,
                -0.02813848853111267,
                -0.04746565222740173,
                -0.03717310354113579,
                -0.031098315492272377,
                -0.013728505000472069,
                -0.02369590848684311,
                0.051360853016376495,
                0.003103062976151705,
                -0.030961675569415092,
                -0.06746603548526764,
                -0.07427707314491272,
                0.005685415584594011,
                -0.008257552981376648,
                0.11437378078699112,
                0.006125587038695812,
                0.005546809174120426,
                -0.014656072482466698,
                -0.014513984322547913,
                -0.007351606152951717,
                -0.029180224984884262,
                -0.04217803478240967,
                -0.012561781331896782,
                0.02362636849284172,
                -0.021674955263733864,
                0.028167493641376495,
                -0.01781584322452545,
                -0.03158215433359146,
                -0.016848310828208923,
                -0.007198338396847248,
                0.010494507849216461,
                -0.057220444083213806,
                0.031790804117918015,
                -0.007674246560782194,
                -0.005220860242843628,
                -0.020979972556233406,
                -0.012760021723806858,
                0.03196198493242264,
                -0.10829419642686844,
                0.0024945742916315794,
                0.022391756996512413,
                -0.0008001758833415806,
                0.03478173539042473,
                0.012839491479098797,
                0.0014210673980414867,
                -0.0006860042340122163,
                0.019736941903829575,
                0.04097292944788933,
                0.016550110653042793,
                0.09618537127971649,
                -0.005715810693800449,
                0.01271944958716631,
                0.019227517768740654,
                0.0030483221635222435,
                -0.014932021498680115,
                0.011870695278048515,
                0.01405341923236847,
                0.050530772656202316,
                -0.05598969757556915,
                0.0012100811582058668,
                -0.03396407887339592,
                -0.002617273246869445,
                0.01725606992840767,
                0.02583174780011177,
                0.006206154357641935,
                -0.04255607724189758,
                -0.027450338006019592,
                -0.03193536028265953,
                2.2885036798925285e-07,
                -0.009485467337071896,
                -0.03453051298856735,
                0.01861732266843319,
                0.0525505393743515,
                0.053954996168613434,
                0.025263788178563118,
                0.009476573206484318,
                0.024230726063251495,
                0.020935779437422752,
                -0.0007526508998125792,
                0.027113158255815506,
                -0.025599732995033264,
                -0.0018409023759886622,
                -0.02172306552529335,
                -0.011148185469210148,
                -0.00867798924446106,
                -0.01023207139223814,
                0.02505352720618248,
                0.04387733340263367,
                0.0017897812649607658,
                -0.012976166792213917,
                0.040116455405950546,
                -0.03211613744497299,
                -0.05415444076061249,
                0.014627791941165924,
                -0.007301336620002985,
                0.014679334126412868,
                -0.07495828717947006,
                0.012437506578862667,
                -0.0290844589471817,
                0.061775874346494675,
                0.060921065509319305,
                0.013590984977781773,
                0.03635226562619209,
                0.04026207700371742,
                -0.04982176050543785,
                0.0381009541451931,
                0.03206488862633705,
                0.04891403764486313,
                0.008487284183502197,
                0.014119759202003479,
                0.019209321588277817,
                0.01045500673353672,
                0.02619781158864498,
                0.030793050304055214,
                0.006487302947789431,
                -0.0015956894494593143,
                0.024544300511479378,
                -0.019751740619540215,
                -0.040994059294462204,
                -0.033470120280981064,
                0.011426283977925777,
                -0.022104790434241295,
                0.09214881807565689,
                0.012012184597551823,
                -0.0073073673993349075,
                0.02119401842355728,
                0.017174964770674706,
                0.06267352402210236,
                0.009470704011619091,
                -0.004199604503810406,
                0.0042000142857432365,
                0.004786995239555836,
                -0.0014302905183285475,
                -0.008190206252038479,
                0.028478698804974556,
                -0.06275228410959244,
                9.958669901965116e-35,
                0.05209255963563919,
                0.00680581945925951,
                -0.0019591280724853277,
                -0.003922440577298403,
                0.0181246567517519,
                -0.00889614224433899,
                0.05109681934118271,
                0.0032476254273205996,
                -0.006465488579124212,
                0.03929905220866203,
                -0.005824447143822908,
                0.027128394693136215,
                -0.02450205571949482,
                -0.00973587017506361,
                -0.008285588584840298,
                -6.804143049521372e-05,
                0.04386463016271591,
                -0.001889039296656847,
                0.02996867150068283,
                0.042271051555871964,
                -0.043870922178030014,
                0.038891397416591644,
                -0.05958633869886398,
                0.01885218359529972,
                0.06564245373010635,
                0.0034916833974421024,
                -0.10954853147268295,
                0.0024781213141977787,
                0.01099043246358633,
                -0.0009403121075592935,
                0.016184482723474503,
                0.027055922895669937,
                0.019513340666890144,
                -0.013595649972558022,
                -0.025868181139230728,
                0.007848971523344517,
                0.01569821685552597,
                -0.018686672672629356,
                0.013208340853452682,
                -0.04243755713105202,
                -0.04431810975074768,
                -0.009266598150134087,
                0.02794904075562954,
                -0.01569512113928795,
                -0.06634986400604248,
                1.5495584193558898e-06,
                -0.002137688919901848,
                -0.017200348898768425,
                0.008300432935357094,
                0.004300122149288654,
                0.02014268934726715,
                -0.020362623035907745,
                0.04406368359923363,
                -0.0693744346499443,
                0.04968931898474693,
                -0.023534318432211876,
                -0.029666753485798836,
                0.07173661142587662,
                0.017910420894622803,
                0.019436119124293327,
                0.023021403700113297,
                -0.01811356656253338,
                -0.0480661578476429,
                -0.04762735590338707,
                -0.02107330597937107,
                0.07608456909656525,
                0.04242353513836861,
                -0.0005217958823777735,
                -0.035923950374126434,
                0.010138417594134808,
                -0.009282858110964298,
                0.023685382679104805,
                0.03828299418091774,
                -0.01071943435817957,
                -0.0029608088079839945,
                -0.019058305770158768,
                0.050325095653533936,
                -0.01436145044863224,
                -0.04267076402902603,
                -0.0019027901580557227,
                0.019923103973269463,
                0.08552870899438858,
                -0.00698030274361372,
                0.013710834085941315,
                0.08390491455793381,
                -0.012227869592607021,
                0.0565115250647068,
                -0.05437431111931801,
                0.058307625353336334,
                0.024194063618779182,
                0.020101450383663177,
                -0.06715947389602661,
                -0.03393641486763954,
                0.0017676411662250757,
                0.02497023344039917,
                0.010024786926805973,
                0.012452165596187115,
                0.005253636743873358,
                -0.06832050532102585,
                -0.0254114530980587,
                0.061113741248846054,
                0.025324538350105286,
                -0.06899034976959229,
                0.026524128392338753,
                0.012783538550138474,
                0.11151716113090515,
                0.020654315128922462,
                -0.03439290449023247,
                -0.010331438854336739,
                0.009711764752864838,
                0.00013966132246423513,
                0.03263020142912865,
                0.011217363178730011,
                0.004518306814134121,
                -0.008075038902461529,
                -0.039850570261478424,
                -0.045498766005039215,
                -0.005931548308581114,
                -0.002519119530916214,
                0.02708117663860321,
                0.07210343331098557,
                -0.006898368708789349,
                -0.02840416133403778,
                -0.016156941652297974,
                0.02514335699379444,
                -0.0012120952596887946,
                0.005704089999198914,
                0.015253741294145584,
                0.04720216616988182,
                -0.004132723435759544,
                0.05142161622643471,
                0.008063111454248428,
                -0.03810674697160721,
                0.006378050893545151,
                0.035512976348400116,
                0.004283799324184656,
                -0.0011073860805481672,
                0.03658779338002205,
                0.013313152827322483,
                0.05561235919594765,
                -0.058912184089422226,
                0.019970742985606194,
                0.038072336465120316,
                -0.008729849942028522,
                0.019183924421668053,
                -0.025364277884364128,
                0.06962928175926208,
                -0.06493128836154938,
                0.008606893941760063,
                0.040719177573919296,
                -0.01566419005393982,
                -0.011039678007364273,
                0.04280406981706619,
                -0.0034132038708776236,
                -0.008465004153549671,
                -0.017644712701439857,
                0.02821347676217556,
                0.01198716089129448,
                0.00016438639431726187,
                0.03036310337483883,
                0.034577492624521255,
                -0.01390167511999607,
                0.05275934189558029,
                -0.07555900514125824,
                -0.018322566524147987,
                0.04413291811943054,
                -0.006089245434850454,
                -0.015284410677850246,
                -0.03845059126615524,
                0.006034119054675102,
                0.022325484082102776,
                0.0317702516913414,
                0.008394477888941765,
                -0.02202221378684044,
                0.0011337802279740572,
                -0.01267722062766552,
                0.032847654074430466,
                -0.0072081866674125195,
                -0.04111156985163689,
                -0.017817381769418716,
                0.008382190950214863,
                -0.05209944024682045,
                -0.004690938629209995,
                -0.046917349100112915,
                0.01512148231267929,
                0.0757075771689415,
                0.030637815594673157,
                0.01120859943330288,
                0.03599420189857483,
                0.021706119179725647,
                0.039753057062625885,
                0.05486289784312248,
                -0.041839778423309326,
                -0.04591904953122139,
                -0.006319828797131777,
                -0.05712011456489563,
                0.03302191570401192,
                -0.048277419060468674,
                0.004205245058983564,
                -0.0670914277434349,
                -0.011564040556550026,
                -0.027449902147054672,
                0.03168033808469772,
                0.05420001223683357,
                0.01300670113414526,
                0.007383235730230808,
                -0.024822119623422623,
                -0.03443737328052521,
                -0.01373656652867794,
                0.014641757123172283,
                -0.020134104415774345,
                -0.00036900080158375204,
                0.007450535893440247,
                0.03197130188345909,
                0.0962657630443573,
                0.012849380262196064,
                -0.003944420721381903,
                0.05237891897559166,
                -0.033759333193302155,
                -0.0008945486042648554,
                0.041603874415159225,
                -0.002249894430860877,
                0.023715322837233543,
                -0.09303611516952515,
                -0.0036130757071077824,
                -0.04441973194479942,
                -0.024609681218862534,
                0.008768295869231224,
                0.0062173642218112946,
                -0.06844179332256317,
                0.029307860881090164,
                0.024133529514074326,
                0.009964358061552048,
                0.04272060468792915,
                0.026364171877503395,
                0.019905848428606987,
                0.00042098559788428247,
                -0.07617086172103882,
                0.029733773320913315,
                -0.0029614835511893034,
                -0.020234495401382446,
                -0.05343484878540039,
                0.03510057181119919,
                0.0014990887138992548,
                0.02045438252389431,
                -0.01580622047185898,
                0.0015018506674095988,
                0.07906688749790192,
                -0.055578120052814484,
                0.038225553929805756,
                -0.13536381721496582,
                0.007774847559630871,
                0.051008034497499466,
                0.011364860460162163,
                0.00941374246031046,
                0.08469407260417938,
                -0.055040713399648666,
                0.05155980587005615,
                -0.04066610336303711,
                -0.021841058507561684,
                0.008439567871391773,
                -0.041023511439561844,
                -0.02918793447315693,
                0.01339932344853878,
                0.020085524767637253,
                0.015003615990281105,
                -0.004936804994940758,
                0.02046072483062744,
                -0.004505399148911238,
                -0.0020265590865164995,
                -0.0025073227006942034,
                0.017807992175221443,
                -0.00736562255769968,
                0.03172720596194267,
                0.02083599381148815,
                0.031584568321704865,
                -0.005578262731432915,
                -0.02260422147810459,
                -0.09003625065088272,
                -0.050286583602428436,
                0.013942682184278965,
                0.0075613269582390785,
                0.01461042184382677,
                0.013579676859080791,
                -0.005687762051820755,
                0.00020786246750503778,
                -0.04375198483467102,
                -0.05066130310297012,
                -0.045983679592609406,
                0.007801015395671129,
                -0.009817194193601608,
                0.019736740738153458,
                -0.0036845896393060684,
                -0.07800184935331345,
                -0.051701683551073074,
                0.02501966618001461,
                0.011863689869642258,
                -0.011797663755714893,
                -0.03413207828998566,
                0.04237198829650879,
                -0.0003865104808937758,
                -0.03299980238080025,
                -0.004795406945049763,
                0.06281613558530807,
                -0.07379698008298874,
                0.025675099343061447,
                -0.01860991306602955,
                -0.011664842255413532,
                0.018261324614286423,
                0.046244945377111435,
                0.05209830403327942,
                -0.00012258182687219232,
                0.0028565186075866222,
                0.011539767496287823,
                -0.009174044243991375,
                -0.026139914989471436,
                0.02838338166475296,
                0.007894305512309074,
                -0.0680464655160904,
                0.02873357944190502,
                0.06966180354356766,
                0.06469692289829254,
                0.026711884886026382,
                0.01493931096047163,
                -0.008564055897295475,
                -0.021663054823875427,
                0.0432460680603981,
                -0.011353651061654091,
                0.022142672911286354,
                -0.008587985299527645,
                0.07764432579278946,
                0.02356857806444168,
                0.03286971151828766,
                -0.0015718901995569468,
                -0.004976279567927122,
                0.024947650730609894,
                0.03944005072116852,
                0.0007848042878322303,
                0.027634557336568832,
                -0.0682847797870636,
                -0.05348527058959007,
                0.011125029996037483,
                -0.014281436800956726,
                -0.02439695969223976,
                -0.029669012874364853,
                -0.018328484147787094,
                0.08879438042640686,
                -0.02982315607368946,
                -0.029869021847844124,
                -0.007808691821992397,
                0.02681792341172695,
                -0.013111919164657593,
                -0.0507856085896492,
                0.00884949415922165,
                -0.06852354854345322,
                -0.0008188801584765315,
                -0.01890801265835762,
                -0.02038421481847763,
                0.016881680116057396,
                -0.05275864154100418,
                -0.0016143815591931343,
                0.04223607853055,
                0.0035142661072313786,
                -0.021712515503168106,
                -0.025338348001241684,
                -0.024243876338005066,
                0.07773418724536896,
                -0.04670744761824608,
                -0.07551779597997665,
                -0.004369445610791445,
                -0.04308970272541046,
                -0.068265400826931,
                0.06074187904596329,
                -0.013130910694599152,
                0.020395977422595024,
                0.013928926549851894,
                0.02459591254591942,
                0.028703389689326286,
                0.00399285601451993,
                -0.08270298689603806,
                0.012687294743955135,
                -0.03735696151852608,
                0.048594482243061066,
                0.00869514886289835,
                0.06935340911149979,
                0.02520439215004444,
                0.044811561703681946,
                -0.011561612598598003,
                0.014924146234989166,
                -0.022444330155849457,
                -0.005555575713515282,
                0.015955662354826927,
                0.008572233840823174,
                0.012756573036313057,
                0.0189022496342659,
                -0.0005955756059847772,
                0.038910213857889175,
                0.008326848037540913,
                0.04870035871863365,
                0.02157975547015667,
                -0.03869174048304558,
                -0.005212000105530024,
                0.09422456473112106,
                0.018247535452246666,
                0.008844345808029175,
                0.014110968448221684,
                0.08668331801891327,
                -0.02612297795712948,
                0.018106849864125252,
                -0.028657972812652588,
                0.029407305642962456,
                0.03849143907427788,
                -0.06993432343006134,
                -0.02111455239355564,
                -0.008499359712004662,
                0.03761367127299309,
                0.011887344531714916,
                0.10554517805576324,
                0.028782110661268234,
                -0.05003542825579643,
                -0.08862511068582535,
                0.04182963818311691,
                -0.041703805327415466,
                -0.027807319536805153,
                0.009134367108345032,
                -0.02209990657866001,
                -0.013520493172109127,
                -0.033201515674591064,
                -0.038568370044231415,
                -0.012695574201643467,
                0.04334334656596184,
                0.01177294086664915,
                -0.022595930844545364,
                0.055399708449840546,
                -0.04289029538631439,
                -0.010386299341917038,
                -0.026717346161603928,
                -0.010005448013544083,
                -0.007188641466200352,
                -0.005259305238723755,
                -0.0014637570129707456,
                -0.0709521695971489,
                -0.07979653030633926,
                -0.08887548744678497,
                -0.0026864181272685528,
                -0.010369820520281792,
                0.004697658587247133,
                0.01738056167960167,
                -0.058310482650995255,
                -0.08158085495233536,
                -0.06407192349433899,
                0.006499035283923149,
                -0.005326706916093826,
                0.007993635721504688,
                0.005883834324777126,
                -0.07070592790842056,
                0.04414872080087662,
                0.03109966404736042,
                -0.050107475370168686,
                -0.09496486186981201,
                -0.0013420574832707644,
                0.014676831662654877,
                -0.030817627906799316,
                -0.021073661744594574,
                0.016271982342004776,
                -0.0733761414885521,
                0.015117572620511055,
                -0.014967626892030239,
                0.05073816701769829,
                0.015449407510459423,
                -0.031007280573248863,
                -0.002174916211515665,
                0.015874844044446945,
                -0.07379517704248428,
                0.010076434351503849,
                -0.05441552773118019,
                -0.04751474782824516,
                -0.04850919544696808,
                -0.07275645434856415,
                0.01887635514140129,
                -0.011590851470828056,
                -0.05149587243795395,
                0.03467518836259842,
                -0.03625447303056717,
                -0.015363108366727829,
                0.003802184946835041,
                0.028112297877669334,
                -0.05836313217878342,
                -0.0422559529542923,
                0.07980480045080185,
                -0.0034137011971324682,
                -0.012054757215082645,
                -0.016866952180862427,
                -0.029878603294491768,
                0.026803486049175262,
                0.02318659983575344,
                -0.005751357413828373,
                -0.02956761233508587,
                -0.01415027305483818,
                -0.00930286105722189,
                -0.017927827313542366,
                0.04041546955704689,
                0.04343525692820549,
                0.011565648019313812,
                0.03527543321251869,
                0.016336770728230476,
                0.023507602512836456,
                -0.03505764156579971,
                0.03390711918473244,
                -0.01389518566429615,
                0.023380298167467117,
                0.01506710797548294,
                -0.012996855191886425,
                -0.019886745139956474,
                0.012328490614891052,
                -0.010240365751087666,
                -0.03369744494557381,
                0.0147750498726964,
                0.002844776026904583,
                0.016579611226916313,
                0.011904136277735233,
                0.008804909884929657,
                0.0009426345932297409,
                -0.006023487076163292,
                0.036149948835372925,
                0.061120446771383286,
                -0.0035943156108260155,
                -0.005805740598589182,
                -0.009788189083337784,
                -0.02697770670056343,
                0.020704738795757294,
                0.018521463498473167,
                -0.014846251346170902,
                -0.0039065685123205185,
                -0.001819981262087822,
                -0.028857238590717316,
                -0.014626480638980865,
                -0.003256108844652772,
                -0.013901382684707642,
                -0.024371258914470673,
                0.02020307630300522,
                -0.0003394247032701969,
                -0.04776829481124878,
                -0.003986791241914034,
                -0.03647349402308464,
                -0.020595166832208633,
                0.0820663794875145,
                0.04341214895248413,
                0.06638245284557343,
                0.019473783671855927,
                -0.037017472088336945,
                0.04141457378864288,
                -0.04422131925821304,
                -0.023151928558945656,
                0.02124461531639099,
                -0.013329285196959972,
                0.03339245915412903,
                0.034636545926332474,
                0.045485250651836395,
                -0.021915318444371223,
                0.03977563977241516,
                -0.02233000285923481,
                0.018644342198967934,
                0.004426269792020321,
                0.024349819868803024,
                -0.020151272416114807,
                -6.416582850757309e-33,
                -0.021823164075613022,
                -0.00805596262216568,
                -0.021976862102746964,
                0.011579174548387527,
                -0.027877014130353928,
                -0.015074743889272213,
                -0.024714292958378792,
                0.020714838057756424,
                -0.03760229051113129,
                0.023699715733528137,
                -0.008770773187279701,
                -0.012754480354487896,
                0.02014126628637314,
                0.009870590642094612,
                0.03098887950181961,
                -0.028162436559796333,
                0.0318509079515934,
                -0.02279544062912464,
                0.011619760654866695,
                -0.03369346261024475,
                -0.016300678253173828,
                -0.030389821156859398,
                0.02389649860560894,
                0.021520700305700302,
                0.005269434303045273,
                0.004041631240397692,
                -0.06811521202325821,
                -0.09022695571184158,
                0.08040604740381241,
                0.023464234545826912,
                -0.01534693967550993,
                0.0348300039768219,
                -0.014472272247076035,
                0.04297899454832077,
                -0.005610563792288303,
                0.06997214257717133,
                -0.020558621734380722,
                -0.05726422742009163,
                0.019653448835015297,
                0.03418668732047081,
                -0.04668667912483215,
                0.03132427856326103,
                0.004136241972446442,
                -0.04895232990384102,
                -0.0017767184181138873,
                -0.036894749850034714,
                0.015292785130441189,
                -0.04901396855711937,
                0.021868517622351646,
                -0.019827120006084442,
                0.002432438777759671,
                0.01638096570968628,
                -0.0227788258343935,
                0.017702482640743256,
                0.04405123367905617,
                -0.01201900839805603,
                0.0005507329478859901,
                -0.045678816735744476,
                -0.027036437764763832,
                0.021071352064609528,
                -0.012627724558115005,
                0.009521515108644962,
                0.044171154499053955,
                -0.018021561205387115,
                -0.03607508912682533,
                0.01594468206167221,
                -0.03402058035135269,
                0.01937238499522209,
                -0.02824491262435913,
                0.11005733162164688,
                0.024293676018714905,
                0.036658912897109985,
                0.030861252918839455,
                -0.05865766108036041,
                -0.08481289446353912,
                0.015261253342032433,
                0.0069976309314370155,
                0.010016730055212975,
                -0.011094844900071621,
                0.027364181354641914,
                -0.03527505323290825,
                0.01748562417924404,
                0.029079854488372803,
                0.006409092806279659,
                0.015789546072483063,
                0.024398989975452423,
                0.007010282948613167,
                -0.04336624965071678,
                0.011624755337834358,
                -0.026877420023083687,
                -0.008605689741671085,
                0.028394773602485657,
                -0.004145846236497164,
                -0.0029007967095822096,
                -0.043671879917383194,
                0.022430265322327614,
                -0.013011072762310505,
                0.04661818966269493,
                -0.032889533787965775,
                -0.03925349563360214,
                0.018508119508624077,
                -0.004525499418377876,
                -0.004177973140031099,
                -0.012959674932062626,
                -0.040755726397037506,
                0.015891050919890404,
                -0.04219552129507065,
                -0.023399874567985535,
                -0.049118027091026306,
                -0.061287183314561844,
                0.03633948788046837,
                0.026632770895957947,
                0.057797711342573166,
                0.02697724476456642,
                -0.002427387982606888,
                -0.022607432678341866,
                -0.014239716343581676,
                -0.014355741441249847,
                -0.040862228721380234,
                -0.022843368351459503,
                0.0022652028128504753,
                -0.014877785928547382,
                -0.026598243042826653,
                -0.008493104949593544,
                -0.025131862610578537,
                -0.018183041363954544,
                -0.0452692024409771,
                0.04082222655415535,
                -0.0014311791164800525,
                -0.028902128338813782,
                -0.009743970818817616,
                0.0200925525277853,
                2.1134142969003733e-07,
                0.04859936982393265,
                0.020259307697415352,
                0.04942367970943451,
                0.04672015830874443,
                0.03365031257271767,
                0.003913543652743101,
                -0.006430646870285273,
                0.019486302509903908,
                -0.02171512134373188,
                -0.028804926201701164,
                -0.01895236223936081,
                -0.022562891244888306,
                -0.006129077635705471,
                -0.008883729577064514,
                0.05661916732788086,
                -0.0004891951684840024,
                -0.010584065690636635,
                0.02785368636250496,
                -0.01150526013225317,
                0.03220022842288017,
                0.07232444733381271,
                -0.0034895874559879303,
                0.007729731034487486,
                0.014221190474927425,
                0.002761638257652521,
                0.05393541231751442,
                0.036535054445266724,
                0.00813515018671751,
                0.04989900812506676,
                0.013704026117920876,
                -0.05161792039871216,
                -0.007527558598667383,
                -0.01736675761640072,
                0.02407199516892433,
                -0.05018023028969765,
                -0.02724982053041458,
                0.056674450635910034,
                0.0015476447297260165,
                0.011201374232769012,
                0.11447206884622574,
                -0.016696467995643616,
                -0.05709748715162277,
                0.0058769891038537025,
                -0.07508721947669983,
                0.0380745530128479,
                0.0824768990278244,
                0.00917787104845047,
                0.03648895397782326,
                -0.12074405699968338,
                -0.07755088061094284,
                0.002632090589031577,
                -0.009894882328808308,
                -0.013170513324439526,
                -0.0003458969003986567,
                -0.029586410149931908,
                -0.006952293217182159,
                0.04607561603188515,
                0.014149153605103493,
                -0.013840703293681145,
                0.004568782635033131,
                -0.025991061702370644,
                0.01622026413679123,
                -0.0434860959649086,
                0.08732934296131134,
                0.05859528109431267,
                0.030156210064888,
                -0.0002122615260304883,
                8.087052732743789e-35,
                -0.003842748934403062,
                -0.018634185194969177,
                0.010598324239253998,
                0.05736590176820755,
                -0.011941371485590935,
                8.639274165034294e-05,
                0.012981069274246693,
                0.01715741492807865,
                0.01757029816508293,
                -0.025725536048412323,
                0.003175067948177457,
                -0.013672287575900555,
                -0.010403638705611229,
                -0.057277433574199677,
                -0.014956473372876644,
                -0.03530524671077728,
                -0.014257817529141903,
                0.011971351690590382,
                0.0678061693906784,
                0.09205884486436844,
                -0.03252117335796356,
                0.053168319165706635,
                -0.02044973149895668,
                0.04041362926363945,
                0.13214510679244995,
                0.050883423537015915,
                -0.013335095718502998,
                2.3451186279999092e-05,
                0.03258849307894707,
                -0.08743796497583389,
                0.014211299829185009,
                0.02436922863125801,
                0.017685584723949432,
                -0.000883533910382539,
                0.024761145934462547,
                0.020039429888129234,
                -0.011368037201464176,
                0.020871058106422424,
                -0.03706948831677437,
                0.01943860575556755,
                -0.016725054010748863,
                0.03642167150974274,
                0.0034990995191037655,
                -0.020477917045354843,
                -0.015879854559898376,
                2.1700636807509e-06,
                -0.032834503799676895,
                0.03962905332446098,
                -0.011431596241891384,
                0.07227084785699844,
                -0.03989240899682045,
                -0.009660077281296253,
                -0.01983637921512127,
                -0.025085171684622765,
                0.06857345998287201,
                -0.020614366978406906,
                -0.11044754832983017,
                0.03931867703795433,
                -0.004220255184918642,
                0.0032551211770623922,
                0.026044674217700958,
                -0.012973088771104813,
                -0.04180344194173813,
                0.0038952019531279802,
                0.017986631020903587,
                0.05520935729146004,
                0.04351358860731125,
                0.026712536811828613,
                -0.06343071907758713,
                0.057006705552339554,
                -0.08995911478996277,
                0.00674640154466033,
                -0.028442194685339928,
                -0.018314488232135773,
                -0.02309039607644081,
                -0.03009573370218277,
                0.009435961954295635,
                0.1212514340877533,
                -0.003671878483146429,
                0.010812632739543915,
                0.021680476143956184,
                0.054362207651138306,
                0.01661972887814045,
                0.00506921811029315,
                0.07385360449552536,
                -0.02024892345070839,
                0.012961491011083126,
                -0.008842702955007553,
                -0.0035020331852138042,
                -0.013447449542582035,
                -0.015655023977160454,
                -0.05858328193426132,
                -0.005534505471587181,
                -0.012549743987619877,
                -0.007812520489096642,
                0.02673092857003212,
                0.045311473309993744,
                -0.018678173422813416,
                0.0033635094296187162,
                -0.04941984638571739,
                0.025604387745261192,
                0.0482008196413517,
                -0.04526781290769577,
                0.00251017021946609,
                0.01404063031077385,
                0.011340434662997723,
                0.010629972442984581,
                -0.028085073456168175,
                -0.09030434489250183,
                -0.022956635802984238,
                -0.0912734717130661,
                0.00473517831414938,
                0.04231350123882294,
                -0.01556485053151846,
                0.004162654746323824,
                0.011430736631155014,
                0.030958691611886024,
                0.03809090703725815,
                -0.08490409702062607,
                0.006706679239869118,
                0.052484165877103806,
                -0.016920873895287514,
                -0.017349252477288246,
                0.01991894282400608,
                0.04233946651220322,
                -0.011474695056676865,
                -3.106792428297922e-05,
                -0.010607810690999031,
                0.0037387239281088114,
                -0.011708480305969715,
                -0.0007227339083328843,
                -0.07242695242166519,
                -0.010700758546590805,
                -0.0005747313844040036,
                -0.0005481900880113244,
                -0.018076229840517044,
                0.08121445775032043,
                -0.009462287649512291,
                -0.009232386946678162,
                0.03637825325131416,
                -0.03370659798383713,
                0.02851930446922779,
                -0.03978963568806648,
                0.016284767538309097,
                0.022784514352679253,
                -0.005551766138523817,
                0.02285529114305973,
                -0.014238225296139717,
                -0.0062773386016488075,
                -0.062435150146484375,
                -0.003688038792461157,
                -0.0020974327344447374,
                0.0036414398346096277,
                -0.0531260147690773,
                -0.0072607011534273624,
                -0.02487841434776783,
                0.002790312049910426,
                0.026342030614614487,
                -0.0927698165178299,
                -0.02147875726222992,
                0.01146762352436781,
                0.03866833075881004,
                0.027751993387937546,
                0.0025077606551349163,
                -0.021295027807354927,
                0.023190759122371674,
                0.03673199936747551,
                -0.008572332561016083,
                -0.01737084984779358,
                0.00874523725360632,
                0.015405585058033466,
                0.011043395847082138,
                -0.0053224749863147736,
                0.040428221225738525,
                0.003246941603720188,
                -0.02569434605538845,
                -0.006045287940651178,
                -0.05267072468996048,
                -0.03454817086458206,
                -0.08060376346111298,
                -0.03936884552240372,
                -0.021573379635810852,
                -0.021568726748228073,
                -0.03368844464421272,
                -0.010217525996267796,
                0.06269121170043945,
                -0.02179509960114956,
                -0.03781885281205177,
                0.03967517986893654,
                -0.04187890887260437,
                0.013719961047172546,
                -0.03814753517508507,
                -0.11724930256605148,
                0.00813622772693634,
                0.06444556266069412,
                -0.031392861157655716,
                0.029889816418290138,
                -0.01620517484843731,
                0.0338687002658844,
                -0.011509840376675129,
                -0.002172318985685706,
                -0.015137423761188984,
                -0.01711294986307621,
                -0.0008656253921799362,
                0.05926293879747391,
                -0.022252701222896576,
                -0.00758860819041729,
                -0.021074898540973663,
                -0.09370608627796173,
                0.01962609775364399,
                -0.027828892692923546,
                0.00023872527526691556,
                -0.05504843592643738,
                0.06929802149534225,
                0.08915594220161438,
                -0.019926389679312706,
                0.006389515940099955,
                0.04772862792015076,
                -0.01513448916375637,
                -0.013561666943132877,
                0.03248456493020058,
                0.03534029424190521,
                0.011036860756576061,
                -0.0006662204978056252,
                0.029811598360538483,
                0.0023315760772675276,
                -0.042962197214365005,
                0.005088100675493479,
                0.05403436720371246,
                -0.048938825726509094,
                0.06872285157442093,
                -0.04287991672754288,
                0.05211922526359558,
                0.08096993714570999,
                -0.0038642045110464096,
                0.08449855446815491,
                -0.05311930924654007,
                -0.010367244482040405,
                -0.006693474482744932,
                0.007466575130820274,
                0.004617451224476099,
                -0.020188363268971443,
                0.0002142715675290674,
                0.01275224145501852,
                -0.007081562653183937,
                0.007475751452147961,
                0.0182663407176733,
                0.057340189814567566,
                -0.09005755931138992,
                0.006684054620563984,
                0.001096235355362296,
                -0.04505077004432678,
                0.08918098360300064,
                0.011802108027040958,
                0.011321517638862133,
                0.07904703170061111,
                0.006026111077517271,
                0.003956003114581108,
                0.01979804039001465,
                -0.04547427222132683,
                -0.0046491920948028564,
                -0.010420911014080048,
                0.016629092395305634,
                0.00030981129384599626,
                0.011494175530970097,
                0.007596319075673819,
                -0.06547120958566666,
                0.009954798966646194,
                -0.0002650361566338688,
                -0.009132569655776024,
                -0.02244376391172409,
                0.02075597085058689,
                -0.010791090317070484,
                -0.0176688339561224,
                -0.0072557395324110985,
                -0.009699149057269096,
                0.0055544874630868435,
                0.025699608027935028,
                0.003934938460588455,
                -0.02320062927901745,
                -0.011766342446208,
                -0.007689665537327528,
                0.01952970214188099,
                0.07484661787748337,
                -0.024812141433358192,
                0.01059053372591734,
                -0.04145316407084465,
                -0.02897937409579754,
                0.015491564758121967,
                -0.0006515235872939229,
                -0.011901293881237507,
                -0.0039858752861619,
                -0.007567126303911209,
                -0.03101847693324089,
                -0.013314654119312763,
                0.022348234429955482,
                0.022079279646277428,
                -0.02411063387989998,
                -0.08153627812862396,
                0.027743389829993248,
                -0.062045689672231674,
                -0.024835791438817978,
                0.005419062450528145,
                0.014492819085717201,
                -0.022451914846897125,
                0.05422903597354889,
                0.008082365617156029,
                0.049565911293029785,
                -0.0074546560645103455,
                0.009089704602956772,
                0.030756298452615738,
                -0.00988494697958231,
                0.03498653322458267,
                -0.006501366384327412,
                -0.04365278407931328,
                0.006122701335698366,
                0.007509745657444,
                -0.013157319277524948,
                -0.025623124092817307,
                0.017960790544748306,
                0.06888633221387863,
                0.04209506884217262,
                0.01965745911002159,
                -0.021649394184350967,
                0.011517531238496304,
                0.0012597040040418506,
                -0.002861211309209466,
                -0.015000073239207268,
                -0.038530249148607254,
                -0.05436180159449577,
                0.03264092281460762,
                -0.014656327664852142,
                -0.031146857887506485,
                0.007503454573452473,
                0.020792096853256226,
                0.03356844186782837,
                -6.604853115277365e-05,
                0.017167994752526283,
                0.021283399313688278,
                0.10730601102113724,
                -0.02966729737818241,
                0.006299640517681837,
                -0.03156089782714844,
                0.016377979889512062,
                0.039297886192798615,
                -0.0631881058216095,
                0.029396047815680504,
                -0.033762749284505844,
                -0.0669466182589531,
                -0.015686945989727974,
                0.03584277257323265,
                -0.016006648540496826,
                -0.03950364887714386,
                0.024665746837854385,
                -1.0854269021365326e-05,
                -0.021273091435432434,
                -0.04415742680430412,
                -0.0015334398485720158,
                0.00028550360002554953,
                -0.05366352200508118,
                0.010823110118508339,
                0.0025609855074435472,
                0.05491471290588379,
                0.007641711737960577,
                -0.025986531749367714,
                0.0005765482201240957,
                -0.002276369370520115,
                -0.03527393192052841,
                0.04582761228084564,
                -0.01031411625444889,
                0.0018366995500400662,
                0.021553287282586098,
                0.04955301061272621,
                0.13919255137443542,
                -0.06750268489122391,
                -0.07174710929393768,
                0.019036054611206055,
                0.057011887431144714,
                0.022516466677188873,
                0.016545070335268974,
                0.005575564224272966,
                -0.01941666007041931,
                0.03284906595945358,
                0.03696766495704651,
                0.014575212262570858,
                -0.03141884133219719,
                0.015715181827545166,
                0.020801492035388947,
                -0.01584305427968502,
                -0.016030896455049515,
                0.0036519039422273636,
                0.037258610129356384,
                -0.04575135558843613,
                0.02922883629798889,
                -0.018114306032657623,
                -0.034422099590301514,
                0.02042466774582863,
                0.00032426643883809447,
                0.017323926091194153,
                -0.04687060788273811,
                -0.006739515345543623,
                -0.03262082487344742,
                -0.09211317449808121,
                -0.11109551042318344,
                0.052455835044384,
                0.03464527428150177,
                0.03309936821460724,
                -0.0003279121301602572,
                0.0064973426051437855,
                0.006630859803408384,
                0.006685909815132618,
                0.0026724704075604677,
                0.09555728733539581,
                0.03481674566864967,
                0.02516058459877968,
                -0.004358272068202496,
                -0.044207312166690826,
                -0.028694678097963333,
                0.023237671703100204,
                0.03356262296438217,
                0.037965353578329086,
                0.05434498190879822,
                -0.06492049247026443,
                -0.004163573030382395,
                -0.011163892224431038,
                -0.0547189936041832,
                -0.018013929948210716,
                -0.023743227124214172,
                -0.02138148434460163,
                0.024131357669830322,
                0.023146428167819977,
                -0.014796988107264042,
                0.012147469446063042,
                0.006472366396337748,
                -0.008369936607778072,
                -0.012734118849039078,
                -0.032914888113737106,
                -0.008265893906354904,
                0.007083268836140633,
                -0.023386787623167038,
                -0.00891113467514515,
                0.002876283135265112,
                -0.05165931582450867,
                0.00121219118591398,
                0.013441655784845352,
                -0.02380354143679142,
                -0.08762383460998535,
                -0.07337462157011032,
                0.0699390321969986,
                0.017472442239522934,
                -0.07881466299295425,
                0.029471104964613914,
                0.007497761398553848,
                0.05054822564125061,
                -0.00823439285159111,
                -0.029970629140734673,
                -0.03010508604347706,
                0.053140416741371155,
                0.07493718713521957,
                0.0011710062390193343,
                0.03309747204184532,
                0.031221311539411545,
                -0.01088978722691536,
                -0.01833152025938034,
                -0.03553552180528641,
                -0.003965501673519611,
                -0.04057502746582031,
                -0.006652817130088806,
                0.06608527898788452,
                -0.003317045047879219,
                -0.007929847575724125,
                0.040749408304691315,
                0.03353627771139145,
                -0.03995397686958313,
                -0.009113761596381664,
                -0.015287715010344982,
                -0.03483661636710167,
                0.05544266477227211,
                -0.05727968364953995,
                0.00917677953839302,
                -0.0129459910094738,
                -0.033035460859537125,
                -0.025066643953323364,
                -0.019295834004878998,
                0.002882580505684018,
                0.023467130959033966,
                -0.006483845412731171,
                -0.029826480895280838,
                -0.01138278003782034,
                -0.06468712538480759,
                -0.029938220977783203,
                -0.02873528189957142,
                0.03916707634925842,
                -0.04764489457011223,
                0.05846893787384033,
                -0.0183658879250288,
                -0.009252466261386871,
                0.026027483865618706,
                0.032405901700258255,
                -0.0027993032708764076,
                -0.008780230768024921,
                0.026725810021162033,
                -0.016512764617800713,
                -0.02225533314049244,
                0.03872115537524223,
                0.020151380449533463,
                0.014962851069867611,
                0.012130998075008392,
                -0.007054978981614113,
                0.03039718046784401,
                -0.008990727365016937,
                -0.016025802120566368,
                -0.009987801313400269,
                -0.004797511268407106,
                -0.0036186440847814083,
                0.012955468147993088,
                -0.0003987526288256049,
                -0.0035076430067420006,
                0.0014880995731800795,
                -0.01761595904827118,
                -0.02834984101355076,
                0.014521336182951927,
                0.02698887512087822,
                -0.00608291057869792,
                -0.03449079766869545,
                -0.012596837244927883,
                0.08205726742744446,
                -0.008988707326352596,
                -0.009980723261833191,
                0.02128474973142147,
                -0.027197618037462234,
                0.03374199941754341,
                0.00478551397100091,
                -0.024833794683218002,
                -0.00034784700255841017,
                0.02685500867664814,
                0.016702847555279732,
                0.009275330230593681,
                0.00936012715101242,
                0.026064101606607437,
                0.03283122181892395,
                0.031058624386787415,
                0.030205750837922096,
                -0.013664919883012772,
                0.012129993177950382,
                -0.019940640777349472,
                0.031468428671360016,
                -0.019933173432946205,
                0.027429858222603798,
                0.0017863715765997767,
                -0.01998053304851055,
                -0.03120938129723072,
                -0.0026722028851509094,
                0.028595903888344765,
                0.03810149058699608,
                0.04568600654602051,
                -0.03551030904054642,
                0.04379336163401604,
                0.010364639572799206,
                0.04668837785720825,
                0.036758262664079666,
                0.008490466512739658,
                0.062430452555418015,
                -0.07017455995082855,
                0.017214758321642876,
                -0.002926198299974203,
                -8.020323752579572e-33,
                -0.008090712130069733,
                0.005784456618130207,
                0.017647635191679,
                0.07922497391700745,
                -0.06656856089830399,
                -0.04119504988193512,
                -0.032279592007398605,
                0.005904879420995712,
                -0.0059731812216341496,
                -0.05191296339035034,
                0.006324383895844221,
                -0.020158808678388596,
                0.029811179265379906,
                -0.0020765673834830523,
                -0.01476350612938404,
                -0.0651518702507019,
                0.02260732091963291,
                0.018777133896946907,
                -0.03204106166958809,
                0.004516225308179855,
                0.026144541800022125,
                -0.0036797132343053818,
                0.014897618442773819,
                0.04760519042611122,
                -0.006671494338661432,
                -0.026514261960983276,
                -0.021329335868358612,
                -0.03305061534047127,
                -0.05682971328496933,
                0.0045678624883294106,
                -0.01776578091084957,
                0.027255713939666748,
                -0.020515087991952896,
                -0.018189935013651848,
                0.016964465379714966,
                0.1231447383761406,
                -0.01867690496146679,
                -0.02132427506148815,
                -0.005794980563223362,
                -0.003973258193582296,
                0.008865495212376118,
                -0.00024721978115849197,
                0.01804039627313614,
                0.014793609268963337,
                0.010313587263226509,
                0.008062941022217274,
                0.013865861110389233,
                -0.03532283380627632,
                -0.004859084263443947,
                -0.09260015934705734,
                -0.023954642936587334,
                0.02938779816031456,
                -0.0507187582552433,
                0.026436232030391693,
                -0.04197115823626518,
                0.0672505721449852,
                0.019648682326078415,
                -0.006519779097288847,
                -0.015149853192269802,
                0.0023767135571688414,
                -0.07851055264472961,
                0.014332126826047897,
                -0.002085592830553651,
                0.044844191521406174,
                0.002634865464642644,
                0.03180090710520744,
                -0.044714923948049545,
                0.05907302722334862,
                -0.0192909874022007,
                0.0897960439324379,
                0.00448481272906065,
                0.03747360780835152,
                0.06593840569257736,
                0.07113192230463028,
                -0.030982989817857742,
                -0.07189254462718964,
                -0.02291901968419552,
                0.03509745001792908,
                0.06807586550712585,
                0.03954925388097763,
                -0.030769437551498413,
                0.030019208788871765,
                1.1925072612939402e-05,
                0.001722735702060163,
                -0.027792122215032578,
                0.03581086918711662,
                0.002118592383340001,
                -0.014615870080888271,
                -0.011812880635261536,
                -0.004761246033012867,
                -0.0231596902012825,
                0.018213815987110138,
                0.03395157679915428,
                0.006197142880409956,
                -0.0211429912596941,
                -0.06515299528837204,
                0.005439684260636568,
                0.02844957634806633,
                0.006867934018373489,
                -0.09644833207130432,
                0.031502898782491684,
                -0.04169928655028343,
                -0.06044502928853035,
                0.05249682441353798,
                -0.017016369849443436,
                -0.016834968701004982,
                -0.0707455426454544,
                0.010537372902035713,
                -0.048703841865062714,
                -0.00569415045902133,
                -0.009142549708485603,
                0.024350930005311966,
                -0.015005072578787804,
                -0.01813877746462822,
                -0.0005639036535285413,
                0.016300709918141365,
                0.029042841866612434,
                -0.019062330946326256,
                -0.008006132207810879,
                0.029585620388388634,
                0.011821410618722439,
                0.06398323178291321,
                -0.06648887693881989,
                0.0003554266004357487,
                -0.03689604625105858,
                0.010469053871929646,
                -0.03764081746339798,
                0.10273287445306778,
                -0.05265308544039726,
                -0.089891716837883,
                -0.02389787882566452,
                0.00019460356270428747,
                2.7901845101041545e-07,
                -0.02372906729578972,
                -0.012112285941839218,
                -0.006502940785139799,
                -0.03331393003463745,
                0.059582360088825226,
                0.022984357550740242,
                0.008845647796988487,
                0.02668166533112526,
                0.045038823038339615,
                -0.030051570385694504,
                -0.02485469914972782,
                -0.037651240825653076,
                0.02316848188638687,
                0.019258059561252594,
                -0.013985035009682178,
                -0.03404077887535095,
                -0.07715858519077301,
                0.021595068275928497,
                -0.0025887489318847656,
                0.01360918115824461,
                -0.011323580518364906,
                0.017411965876817703,
                0.001974500948563218,
                0.013418917544186115,
                0.02749010920524597,
                0.004821419715881348,
                0.031176265329122543,
                -0.014892623759806156,
                0.02270025759935379,
                -0.02350766211748123,
                -0.018341930583119392,
                -0.01034324336796999,
                -0.029989169910550117,
                -0.034748375415802,
                -0.005792043171823025,
                -0.0025733262300491333,
                0.02222316898405552,
                -0.009057373739778996,
                0.034400757402181625,
                0.10145028680562973,
                -0.009299243800342083,
                -0.02078273892402649,
                -0.013537609949707985,
                -0.020677536725997925,
                0.048006750643253326,
                0.004378106910735369,
                -0.0009574635187163949,
                0.007048886734992266,
                -0.045806847512722015,
                -0.04752357676625252,
                -0.0008998334524221718,
                -0.023941559717059135,
                -0.021064480766654015,
                0.005114967469125986,
                -0.015190591104328632,
                -0.04384632408618927,
                0.007352143991738558,
                0.026723045855760574,
                -0.0023652776144444942,
                0.03746868669986725,
                -0.02059835195541382,
                0.002944956300780177,
                -0.017841553315520287,
                0.07184842973947525,
                -0.018782539293169975,
                0.08337423950433731,
                -0.051741328090429306,
                1.1404864300981209e-34,
                -0.007259677164256573,
                -0.03474454954266548,
                -0.015641160309314728,
                -0.0008229365921579301,
                0.019383510574698448,
                -0.031153680756688118,
                0.03220788761973381,
                -0.0006206700927577913,
                0.04127247631549835,
                -0.010948074981570244,
                -0.020904606208205223,
                0.06297138333320618,
                -0.020030226558446884,
                -0.01851087063550949,
                -0.01355514582246542,
                -0.0081430459395051,
                0.015342488884925842,
                -0.00289041711948812,
                -0.012953532859683037,
                -0.07682368159294128,
                0.03963539004325867,
                0.09105234593153,
                -0.010542556643486023,
                0.01994759775698185,
                9.340199903817847e-05,
                0.014310413971543312,
                -0.06361132115125656,
                -0.0213488657027483,
                -0.01084519550204277,
                -0.01234785933047533,
                -0.018096040934324265,
                0.016596073284745216,
                -0.0009509806404821575,
                0.020331501960754395,
                -0.01907469518482685,
                -0.007727308198809624,
                -0.001515217823907733,
                0.011137845925986767,
                -0.015745410695672035,
                0.0031539457850158215,
                0.009570619091391563,
                0.033249251544475555,
                -0.011063605546951294,
                -0.0345420315861702,
                -0.03493824973702431,
                1.7798971612137393e-06,
                -0.01617990992963314,
                -0.013270092196762562,
                -0.013051571324467659,
                -0.04737669974565506,
                -0.0006717214128002524,
                -0.025954782962799072,
                0.014087371528148651,
                0.0024699531495571136,
                0.012408548034727573,
                -0.018384279683232307,
                0.04357967525720596,
                0.04205286502838135,
                -0.02389903925359249,
                0.008362963795661926,
                -0.02042340114712715,
                0.013418659567832947,
                0.024848870933055878,
                -0.03989572823047638,
                -0.021699588745832443,
                -0.05298088863492012,
                -0.06360477954149246,
                0.010305345058441162,
                -0.00302508263848722,
                0.02660723589360714,
                -0.01115645281970501,
                0.02305903099477291,
                -0.036303967237472534,
                -0.017194148153066635,
                0.0017112118657678366,
                0.00916990265250206,
                0.028958143666386604,
                -0.006314282305538654,
                -0.026135539636015892,
                0.023788269609212875,
                -0.031529247760772705,
                -0.05683957412838936,
                0.0586889311671257,
                0.011101938784122467,
                0.04647001996636391,
                -0.028656432405114174,
                0.024476859718561172,
                -0.0155355678871274,
                0.002862231805920601,
                -0.004065073095262051,
                -0.025115838274359703,
                -0.0592896044254303,
                0.07618720829486847,
                0.004567478783428669,
                0.037021931260824203,
                0.05157000944018364,
                0.04116832837462425,
                -0.03368355333805084,
                0.014563703909516335,
                -0.007947874255478382,
                0.08154463768005371,
                -0.036198895424604416,
                -0.058073557913303375,
                -0.002967443782836199,
                0.0379207506775856,
                -0.0003316003130748868,
                -0.02395743504166603,
                0.047088682651519775,
                -0.034118056297302246,
                0.00019303627777844667,
                -0.026050778105854988,
                -0.03063163161277771,
                0.020076116546988487,
                0.06313139200210571,
                0.030274055898189545,
                -0.0013407990336418152,
                -0.03308170661330223,
                0.00038506320561282337,
                -0.02612236887216568,
                -0.013507059775292873,
                0.016545874997973442,
                -0.06862055510282516,
                0.0250538382679224,
                0.013826743699610233,
                0.02504534088075161,
                0.013198206201195717,
                -0.00898381695151329,
                0.013888045214116573,
                0.03566257283091545,
                0.0036194175481796265,
                0.02899121679365635,
                0.02174203470349312,
                0.033229317516088486,
                -0.013426052406430244,
                -0.01915440335869789,
                0.03667103126645088,
                0.0011879130033776164,
                -0.05156896635890007,
                -0.014935481362044811,
                -0.015188196673989296,
                0.024016929790377617,
                -0.029199765995144844,
                -0.008160333149135113,
                -0.007618365343660116,
                -0.0011331260902807117,
                0.015071714296936989,
                -0.007815597578883171,
                0.02046455256640911,
                0.052374158054590225,
                -0.001081508002243936,
                -0.023820433765649796,
                0.037750180810689926,
                -0.05113408342003822,
                -0.055241648107767105,
                -0.04340974986553192,
                0.006894682999700308,
                -0.019132912158966064,
                0.026869503781199455,
                0.0009733161423355341,
                0.002682944992557168,
                -0.04391414672136307,
                0.009451952762901783,
                0.009556841105222702,
                -0.025735413655638695,
                0.021754104644060135,
                0.06349022686481476,
                0.05943182110786438,
                -0.04374711960554123,
                -0.015244457870721817,
                0.007172335404902697,
                0.022682340815663338,
                0.026212647557258606,
                0.027126070111989975,
                0.02787509746849537,
                -0.017222387716174126,
                -0.03252493590116501,
                0.022180180996656418,
                0.05246797204017639,
                0.004503427539020777,
                0.004589589312672615,
                0.01009135227650404,
                -0.02794230729341507,
                -0.009793498553335667,
                -0.03598269075155258,
                0.03997126594185829,
                0.009018140845000744,
                -0.013576311059296131,
                0.0531267449259758,
                -0.010824387893080711,
                -0.045657649636268616,
                0.016049077734351158,
                -0.042207542806863785,
                -0.09788147360086441,
                -0.005282562226057053,
                0.030634956434369087,
                0.0911991074681282,
                0.03617919236421585,
                0.026997549459338188,
                0.07317988574504852,
                -0.06281581521034241,
                0.02162131667137146,
                -0.01426839642226696,
                0.00827897246927023,
                -0.0231942068785429,
                -0.01713011972606182,
                0.011120382696390152,
                -0.0019526334945112467,
                -0.0042784251272678375,
                -0.029859958216547966,
                -0.002049531089141965,
                -0.024714091792702675,
                0.019105181097984314,
                -0.03168540447950363,
                0.027602961286902428,
                0.030626701191067696,
                -0.028452545404434204,
                0.057803764939308167,
                -0.030067510902881622,
                -0.001172611489892006,
                0.018563827499747276,
                -0.021469103172421455,
                -0.01933840848505497,
                0.02620343118906021,
                -0.010435955598950386,
                0.030545320361852646,
                -0.013951357454061508,
                0.06578528881072998,
                -0.004512975458055735,
                0.023150019347667694,
                -0.06210441514849663,
                -0.013672965578734875,
                -0.035554736852645874,
                0.10513146966695786,
                -0.005403775721788406,
                0.015235996805131435,
                -0.011046680621802807,
                -0.015504547394812107,
                -0.04097675904631615,
                -0.0031822281889617443,
                0.02463957853615284,
                6.40039870631881e-05,
                -0.016770409420132637,
                0.007337599527090788,
                0.011007280088961124,
                0.009400242939591408,
                0.007126213051378727,
                0.037584275007247925,
                0.10329683870077133,
                0.005038606934249401,
                -0.007148104254156351,
                -0.07374077290296555,
                -0.032068077474832535,
                0.027800627052783966,
                0.028603093698620796,
                0.04130866751074791,
                0.034576255828142166,
                -0.016317671164870262,
                -0.014414221979677677,
                0.009620442986488342,
                -0.05512022599577904,
                0.002472243970260024,
                -0.06654636561870575,
                0.0026561871636658907,
                0.030905338004231453,
                -0.01881570555269718,
                -0.003957780543714762,
                -0.04775841534137726,
                -0.0015170031692832708,
                0.059204958379268646,
                -0.033476296812295914,
                0.04733232036232948,
                0.0703466609120369,
                0.018394768238067627,
                0.005961939692497253,
                -0.006907863076776266,
                0.0767509862780571,
                -0.03140188381075859,
                -0.016706222668290138,
                -0.06440400332212448,
                0.0036898429971188307,
                -0.026816075667738914,
                0.018902361392974854,
                -0.02756560407578945,
                0.003220499726012349,
                -0.009305300191044807,
                0.0035570894833654165,
                0.03546351566910744,
                -0.0011158682173117995,
                0.023799465969204903,
                -0.05539754405617714,
                -0.01020327489823103,
                0.03396446257829666,
                -0.05654710531234741,
                0.0044419062323868275,
                0.036499280482530594,
                0.01919539086520672,
                -0.0042818458750844,
                0.06759760528802872,
                -0.04361709952354431,
                -0.07257236540317535,
                -0.02830132655799389,
                -0.03987385705113411,
                -0.04812726750969887,
                0.04012399539351463,
                -0.037791717797517776,
                0.01316086109727621,
                0.01393275149166584,
                -0.02075197547674179,
                -0.0033891184721142054,
                -0.009835680015385151,
                -0.03351657837629318,
                0.009179085493087769,
                -0.01496406365185976,
                0.04329540953040123,
                0.002131384564563632,
                -0.026081660762429237,
                -0.041982658207416534,
                0.0025728351902216673,
                -0.03893110156059265,
                -0.02971639856696129,
                0.047545116394758224,
                0.019576862454414368,
                0.01674787886440754,
                0.017752882093191147,
                0.0053843907080590725,
                -0.004704164806753397,
                -0.012864630669355392,
                -0.03280482068657875,
                -0.09642548114061356,
                -0.024504996836185455,
                0.021875279024243355,
                -0.017571747303009033,
                0.037943899631500244,
                -0.012159526348114014,
                -0.010472841560840607,
                -0.010557218454778194,
                -0.0386449433863163,
                -0.013035117648541927,
                0.023990163579583168,
                0.09033310413360596,
                -0.025432433933019638,
                0.0005482584238052368,
                -0.046328309923410416,
                -0.018860172480344772,
                0.013286841101944447,
                -0.005099867936223745,
                0.04332202300429344,
                -0.02105230651795864,
                -0.08333152532577515,
                -0.03305012360215187,
                -0.007715372834354639,
                -0.0009039905271492898,
                0.02024284563958645,
                -0.02637132816016674,
                0.03161296993494034,
                -0.06449821591377258,
                0.008740589953958988,
                0.010908630676567554,
                0.005915893707424402,
                -0.029526276513934135,
                0.019498230889439583,
                0.07401979714632034,
                -0.03889836370944977,
                -0.027940141037106514,
                -0.02414744719862938,
                0.014507189393043518,
                0.002926306566223502,
                -0.04131206497550011,
                -0.0002484141441527754,
                0.01243541482836008,
                -0.11988945305347443,
                -0.02741401456296444,
                0.061648763716220856,
                0.12538467347621918,
                0.002705095801502466,
                0.007350313011556864,
                -0.015599802136421204,
                0.040194712579250336,
                0.04161231219768524,
                -0.06749708950519562,
                -0.007221169304102659,
                0.037984590977430344,
                0.04205646738409996,
                -0.04486502334475517,
                0.042188894003629684,
                0.0007470796699635684,
                0.045052140951156616,
                0.025495236739516258,
                -0.022806616500020027,
                -0.0021613396238535643,
                -0.03284895792603493,
                0.03910167142748833,
                0.005419054068624973,
                -0.0043020229786634445,
                0.016391055658459663,
                -0.018840402364730835,
                0.024968110024929047,
                -0.01971573755145073,
                -0.015733715146780014,
                0.006142922677099705,
                0.007660830393433571,
                0.04321318864822388,
                -0.028260303661227226,
                -0.04539937525987625,
                0.02094855159521103,
                0.03120153583586216,
                -0.03786049410700798,
                0.020583098754286766,
                0.018062828108668327,
                0.020447196438908577,
                0.024623561650514603,
                0.03932354971766472,
                0.05140425264835358,
                0.017823589965701103,
                0.03508676961064339,
                -0.1025950238108635,
                0.009912560693919659,
                0.07151670008897781,
                0.036134883761405945,
                0.022745097056031227,
                -0.0731261670589447,
                -0.002854143036529422,
                0.03063310869038105,
                0.04846082627773285,
                0.016994841396808624,
                0.07511763274669647,
                -0.05720028281211853,
                -0.004024891648441553,
                0.02624264359474182,
                -0.024716198444366455,
                -0.0352543443441391,
                0.004652857780456543,
                -0.012279428541660309,
                -0.03968528285622597,
                -0.024559546262025833,
                0.006311753764748573,
                -0.010698828846216202,
                0.041433658450841904,
                0.042660847306251526,
                0.0015124353813007474,
                -0.036041438579559326,
                -0.019328923895955086,
                0.03379042074084282,
                -0.016214273869991302,
                0.03256166726350784,
                -0.0306767039000988,
                -0.09799874573945999,
                -0.05594131350517273,
                0.026124877855181694,
                0.048051174730062485,
                0.007828796282410622,
                -0.03411595895886421,
                0.07888607680797577,
                -0.013248682022094727,
                -0.009196123108267784,
                0.005459252279251814,
                -0.10226037353277206,
                0.05206620320677757,
                -0.008977077901363373,
                -0.04128170758485794,
                0.017878437414765358,
                0.03726978972554207,
                -0.02290213294327259,
                -0.0413040891289711,
                -0.08380405604839325,
                0.00258586835116148,
                0.027490047737956047,
                -0.02535823918879032,
                -0.004178723786026239,
                0.028877148404717445,
                0.04377531260251999,
                0.04297036677598953,
                0.036934539675712585,
                -0.06302425265312195,
                0.03590594232082367,
                -0.034767188131809235,
                -0.042884454131126404,
                -0.0018384897848591208,
                -0.05047190189361572,
                0.0374758280813694,
                -0.033562272787094116,
                -0.03841419517993927,
                0.005249700974673033,
                0.001960208872333169,
                0.03827369585633278,
                0.04041573032736778,
                0.05714983865618706,
                -0.04552339017391205,
                -0.06972348690032959,
                -0.01462964154779911,
                -0.027301892638206482,
                -0.03253792226314545,
                0.021725105121731758,
                -0.01152777299284935,
                0.01193762756884098,
                0.006665598135441542,
                -0.012371590360999107,
                0.04657374322414398,
                -0.011601122096180916,
                0.007252522278577089,
                -0.032443754374980927,
                -0.027837581932544708,
                -0.0016823462210595608,
                0.017389174550771713,
                0.045055802911520004,
                0.013917909003794193,
                -0.003918072674423456,
                -0.0324154756963253,
                0.06939880549907684,
                0.03136226534843445,
                0.035907961428165436,
                -0.00027725001564249396,
                0.03315938264131546,
                0.017945628613233566,
                -0.015977568924427032,
                0.00580255500972271,
                0.0008367536356672645,
                0.001978115411475301,
                0.008855738677084446,
                -0.005266263149678707,
                -0.006640581414103508,
                0.03945579752326012,
                -0.015998175367712975,
                -0.05668594688177109,
                -0.08097890764474869,
                0.028766360133886337,
                0.017875347286462784,
                -0.011866318993270397,
                -0.013812423683702946,
                -0.021412905305624008,
                -0.019878756254911423,
                0.01576460711658001,
                -0.01734108291566372,
                -0.014141474850475788,
                -0.03820447623729706,
                0.09392786771059036,
                -0.05297766253352165,
                0.00857451930642128,
                -0.005229842849075794,
                -0.018155822530388832,
                0.013497824780642986,
                -0.073200523853302,
                0.011767003685235977,
                -0.024935856461524963,
                0.02958228997886181,
                -0.038923006504774094,
                0.07995864748954773,
                0.040595490485429764,
                0.07464562356472015,
                -0.010990714654326439,
                0.03030582331120968,
                -0.02796434611082077,
                0.07658019661903381,
                0.0036576094571501017,
                0.017104122787714005,
                0.0547112300992012,
                0.003721782937645912,
                -0.016776418313384056,
                0.020280389115214348,
                0.014258204028010368,
                0.007038504350930452,
                -0.024930492043495178,
                -0.025340555235743523,
                0.012995543889701366,
                -0.006621304899454117,
                0.034029342234134674,
                -6.658257142446679e-33,
                0.009736639447510242,
                -0.10228925198316574,
                0.005459193140268326,
                0.016655035316944122,
                -0.070747509598732,
                0.011791379190981388,
                0.02801264449954033,
                0.016763754189014435,
                -0.022320158779621124,
                -0.010693486779928207,
                -0.036130551248788834,
                0.007388413418084383,
                0.028734974563121796,
                -0.019877616316080093,
                0.049280039966106415,
                -0.006473969202488661,
                -0.003155846381559968,
                0.009668529033660889,
                0.0035773098934441805,
                -0.02533268742263317,
                -0.007635725662112236,
                0.0433528833091259,
                -0.01959475874900818,
                0.03690043464303017,
                0.0051634288392961025,
                -0.013903236016631126,
                -0.04856400564312935,
                0.013434428721666336,
                -0.012599753215909004,
                -0.018298281356692314,
                0.0450870580971241,
                -0.0728093683719635,
                -0.008558939211070538,
                -0.08625273406505585,
                0.006424303166568279,
                0.08432704210281372,
                0.011773946695029736,
                -0.012798511423170567,
                -0.02633892372250557,
                -0.01050441712141037,
                -0.053769856691360474,
                0.0030061749275773764,
                0.0069018942303955555,
                -0.00434002885594964,
                0.02054382488131523,
                -0.07773146778345108,
                1.0566177479631733e-06,
                0.0060662939213216305,
                -0.0007683397852815688,
                0.06105506047606468,
                0.015231376513838768,
                0.03440345823764801,
                0.008243108168244362,
                -0.0036457697860896587,
                0.04379427805542946,
                -0.06665921211242676,
                -0.02124221809208393,
                -0.018015773966908455,
                -0.07322943955659866,
                0.015409507788717747,
                -0.053782906383275986,
                0.04467401280999184,
                -0.01774664595723152,
                -0.00312166684307158,
                -0.008884329348802567,
                0.015252448618412018,
                -0.03256220743060112,
                -0.0003873383393511176,
                -0.018221944570541382,
                -0.02830142341554165,
                -0.06556708365678787,
                0.08289098739624023,
                0.08211833238601685,
                -0.0015126803191378713,
                -0.06837037950754166,
                -0.0475233756005764,
                -0.03473502770066261,
                0.029491975903511047,
                0.04087354987859726,
                -0.013408664613962173,
                -0.05617094784975052,
                0.04991144686937332,
                -0.018917495384812355,
                0.04226672276854515,
                0.049990370869636536,
                -0.01408837828785181,
                0.010678084567189217,
                -0.0020664772018790245,
                -0.050452955067157745,
                0.030050164088606834,
                -0.03912001848220825,
                0.039281316101551056,
                0.036170732229948044,
                0.04557414352893829,
                0.03632458671927452,
                0.024129901081323624,
                0.034668464213609695,
                0.003881108481436968,
                -0.006854180712252855,
                -0.06583387404680252,
                -0.06711961328983307,
                0.03966986760497093,
                -0.11437886953353882,
                0.017191670835018158,
                0.016402510926127434,
                -0.004306620918214321,
                0.005812039598822594,
                0.03375832736492157,
                -0.005069784354418516,
                -0.005271255504339933,
                -0.005256183445453644,
                0.007010695058852434,
                0.010919475927948952,
                -0.08314572274684906,
                -0.019881203770637512,
                -0.00820478517562151,
                0.011049947701394558,
                -0.05438787862658501,
                0.01055813767015934,
                -0.08225469291210175,
                0.017197631299495697,
                0.06558447331190109,
                -0.06462635099887848,
                -0.011064319871366024,
                -0.015142777003347874,
                0.016452563926577568,
                0.0033000782132148743,
                -0.002213215222582221,
                0.008443024009466171,
                0.006982580292969942,
                -0.04027076065540314,
                -0.08039932698011398,
                2.40127860706707e-07,
                -0.03779483586549759,
                0.029568150639533997,
                -0.006997796706855297,
                0.0005654068663716316,
                -0.006096625234931707,
                0.03579186275601387,
                -0.05577486380934715,
                0.04967151954770088,
                0.025772687047719955,
                -0.003521906677633524,
                0.07008292526006699,
                0.03125642240047455,
                -0.014891483820974827,
                0.0054951924830675125,
                0.03953378275036812,
                -0.04046189785003662,
                0.014985055662691593,
                -0.0299602709710598,
                0.057715170085430145,
                -0.07706625014543533,
                -0.01260012574493885,
                -0.014921383932232857,
                0.03963411971926689,
                0.010950140655040741,
                -0.017150195315480232,
                -0.006667299196124077,
                -0.017472408711910248,
                -0.025191301479935646,
                0.01826169155538082,
                0.01534043624997139,
                0.010136714205145836,
                -0.07717917114496231,
                -0.04460590332746506,
                0.007253971882164478,
                0.009397834539413452,
                -0.050046104937791824,
                0.03870610147714615,
                -0.004694999195635319,
                -0.023926591500639915,
                0.07079073041677475,
                -0.007876288145780563,
                -0.008752085268497467,
                -0.01322090532630682,
                0.04278379678726196,
                -0.0009034420363605022,
                -0.023675156757235527,
                -0.006392008159309626,
                0.043390918523073196,
                -0.052931733429431915,
                -0.01708471029996872,
                0.004165406804531813,
                0.06652325391769409,
                0.02469564415514469,
                0.07808101922273636,
                -0.0015964196063578129,
                0.023859353736042976,
                -0.0032899787183851004,
                0.039852071553468704,
                0.0165200587362051,
                -0.017948200926184654,
                -0.02303279936313629,
                -0.03320681303739548,
                0.04634419456124306,
                0.015774019062519073,
                -0.05442292243242264,
                0.0719001442193985,
                -0.07101555913686752,
                7.444094631014312e-35,
                -0.01676349528133869,
                -0.03465087711811066,
                -0.027406731620430946,
                0.0017532510682940483,
                0.048210617154836655,
                -0.016943544149398804,
                -0.013589381240308285,
                -0.009029293432831764,
                0.019016413018107414,
                0.08736943453550339,
                -0.03717505931854248,
                0.04312574118375778,
                -0.03711220994591713,
                -0.02699332870543003,
                -0.0325717069208622,
                -0.030633896589279175,
                0.012513838708400726,
                -0.016837691888213158,
                -0.018232056871056557,
                -0.040010590106248856,
                0.020308615639805794,
                0.04683232307434082,
                -0.022078201174736023,
                0.012474380433559418,
                -0.002021579537540674,
                -0.008355570025742054,
                -0.05912242829799652,
                0.01682433858513832,
                -0.005863156635314226,
                -0.05549165606498718,
                -0.012594911269843578,
                -0.019847778603434563,
                0.015817223116755486,
                -0.009190883487462997,
                0.03059179149568081,
                0.007555995602160692,
                -0.04791724681854248,
                0.0028384055476635695,
                0.00272307638078928,
                -0.030067352578043938,
                0.0052444362081587315,
                -0.008825481869280338,
                0.04020194336771965,
                -0.045989274978637695,
                -0.026032377034425735,
                1.9201459053874714e-06,
                -0.007094011176377535,
                -0.0020600256975740194,
                -0.046689387410879135,
                0.02218022383749485,
                -0.008526930585503578,
                0.0001472109870519489,
                0.03643684461712837,
                -0.01964263990521431,
                0.05955936759710312,
                -0.020191097632050514,
                0.033036522567272186,
                0.027823396027088165,
                0.03849494829773903,
                -0.008744031190872192,
                -0.04331686347723007,
                -0.009178011678159237,
                -0.017264770343899727,
                -0.055349208414554596,
                -0.04010048508644104,
                0.055097077041864395,
                0.021124735474586487,
                -0.006402518600225449,
                -0.03308121860027313,
                0.04946909844875336,
                -0.08521698415279388,
                -0.004712624009698629,
                -0.018322376534342766,
                -0.00978125724941492,
                0.0017874169861897826,
                0.07124203443527222,
                0.05100224167108536,
                0.02662946656346321,
                -0.03632593899965286,
                0.04535665735602379,
                -0.0016103460220620036,
                0.024842167273163795,
                0.02283155359327793,
                -0.0012087615905329585,
                0.038261234760284424,
                -0.003140049520879984,
                0.02388826757669449,
                -0.030344748869538307,
                0.01936129294335842,
                -0.022470751777291298,
                -0.028712211176753044,
                0.0016767600318416953,
                -0.0015692837769165635,
                -0.016623038798570633,
                0.052504025399684906,
                0.059526193886995316,
                0.02473989874124527,
                -0.023711374029517174,
                -0.014939709566533566,
                -0.0025872967671602964,
                0.018984772264957428,
                0.046909183263778687,
                -0.047269076108932495,
                0.02383027970790863,
                0.016125457361340523,
                -0.043328434228897095,
                -0.022028367966413498,
                0.033238667994737625,
                0.0397241935133934,
                -0.023649325594305992,
                0.04946818947792053,
                0.048541486263275146,
                0.021481595933437347,
                0.04873587563633919,
                0.0602654330432415,
                0.011423514224588871,
                -0.07549741864204407,
                -0.027049250900745392,
                0.0288836732506752,
                -0.045269981026649475,
                0.08681359887123108,
                -0.08535296469926834,
                -0.0204776618629694,
                -0.04881061613559723,
                0.050672903656959534,
                0.039178844541311264,
                -0.03517279028892517,
                -0.009276019409298897,
                0.020745474845170975,
                0.01777859590947628,
                -0.012331492267549038,
                -0.06172480806708336,
                0.05257156118750572,
                -0.024983840063214302,
                -0.00043325009755790234,
                -0.0010273336665704846,
                0.0033733819145709276,
                -0.029729725793004036,
                0.022243782877922058,
                0.015800120308995247,
                0.039004649966955185,
                -0.013158704154193401,
                -0.007610321044921875,
                -0.03799239918589592,
                -0.01977093704044819,
                0.02519833855330944,
                0.040398724377155304,
                -0.015109590254724026,
                0.03659617528319359,
                -0.053351279348134995,
                -0.049150530248880386,
                0.038685984909534454,
                -0.011625737883150578,
                -0.01714419387280941,
                -0.04964162036776543,
                -0.04829069972038269,
                -0.04302183911204338,
                0.02806287817656994,
                -0.0008563091978430748,
                -0.012991699390113354,
                -0.0046568443067371845,
                0.0036494184751063585,
                0.013480146415531635,
                -0.01348020788282156,
                0.023410046473145485,
                0.06317007541656494,
                0.06954070925712585,
                -0.01573176681995392,
                0.013863855041563511,
                0.044420670717954636,
                0.011159122921526432,
                -0.0030962771270424128,
                0.03744607791304588,
                0.050187498331069946,
                -0.02262500673532486,
                -0.020213140174746513,
                0.013787013478577137,
                0.024893227964639664,
                -0.012496699579060078,
                -0.026860898360610008,
                0.0020676793064922094,
                -0.08765055239200592,
                0.010814580135047436,
                -0.03309415653347969,
                0.12645940482616425,
                0.05142151564359665,
                0.06151759624481201,
                0.023586925119161606,
                0.05614420026540756,
                -0.06577945500612259,
                -0.05798866227269173,
                -0.04246925935149193,
                -0.08915459364652634,
                0.032249148935079575,
                0.06674216687679291,
                0.013857650570571423,
                0.03556475788354874,
                0.017769867554306984,
                0.03280963376164436,
                -0.05880250409245491,
                -0.005248714704066515,
                -0.011355905793607235,
                0.03203947842121124,
                -0.017558472231030464,
                0.008377715945243835,
                -0.049007598310709,
                -0.004808307159692049,
                -0.0033111663069576025,
                -0.011483443900942802,
                -0.07715608179569244,
                -0.02803811803460121,
                0.01737300120294094,
                -0.00848697591573,
                0.034484781324863434,
                0.1068992018699646,
                -0.014816046692430973,
                0.004431013483554125,
                -0.039711493998765945,
                -0.0579058974981308,
                0.01491175964474678,
                -0.009084708988666534,
                -0.007195896469056606,
                -0.004850263241678476,
                -0.03127190098166466,
                0.02537117898464203,
                -0.003583768615499139,
                -0.03718538582324982,
                0.005598499905318022,
                -0.0021251547150313854,
                -0.07190696150064468,
                0.019747525453567505,
                -0.013504613190889359,
                0.06020525470376015,
                0.03769897669553757,
                -0.0002840672095771879,
                -0.01615757681429386,
                0.022131871432065964,
                0.0016545943217352033,
                -0.004594164900481701,
                -0.011863588355481625,
                0.003500164020806551,
                -0.02738053724169731,
                0.07100138068199158,
                0.010694166645407677,
                -0.006295481696724892,
                -0.03366103395819664,
                0.0459832102060318,
                0.0685095265507698,
                0.050109006464481354,
                -0.012572725303471088,
                -0.06135553866624832,
                0.0011548253241926432,
                -0.06083013489842415,
                0.005674673244357109,
                0.020050140097737312,
                0.04336969926953316,
                -0.01473515946418047,
                -0.02015209011733532,
                0.004649803973734379,
                -0.005462457425892353,
                -0.02917827107012272,
                -0.058338772505521774,
                0.003520677564665675,
                0.0060205175541341305,
                -0.0012458814308047295,
                0.027505962178111076,
                0.0015109007945284247,
                -0.010152757167816162,
                0.032567236572504044,
                -0.04515659064054489,
                0.0008824777323752642,
                0.02033335529267788,
                -0.016798971220850945,
                0.005644702352583408,
                -0.015158851630985737,
                0.05893890932202339,
                -0.05586864799261093,
                -0.002397416392341256,
                -0.04441886022686958,
                -0.029380640015006065,
                -0.016314717009663582,
                0.006801682058721781,
                -0.002781072398647666,
                0.016402563080191612,
                -0.011857517063617706,
                -0.03978485241532326,
                0.018965478986501694,
                0.001969291362911463,
                -0.005053672008216381,
                0.022270172834396362,
                0.0073704952374100685,
                0.03898397833108902,
                0.003849523141980171,
                0.020773591473698616,
                0.017368214204907417,
                0.00473859952762723,
                0.025394268333911896,
                0.01467003021389246,
                -0.053553398698568344,
                -0.03116605430841446,
                0.031058961525559425,
                -0.07297999411821365,
                -0.024598119780421257,
                0.06078489497303963,
                0.003682725364342332,
                0.03663550689816475,
                -0.014279965311288834,
                0.00964732188731432,
                0.021943025290966034,
                0.022962024435400963,
                -0.04028403386473656,
                0.024815090000629425,
                -0.05769803375005722,
                0.04550384357571602,
                -0.0038855348248034716,
                0.007263063918799162,
                0.06888006627559662,
                0.039255280047655106,
                0.013044276274740696,
                0.009103693999350071,
                0.033826034516096115,
                0.008500237949192524,
                0.004521396942436695,
                -0.006205343641340733,
                0.09097692370414734,
                -0.009493415243923664,
                -0.018770985305309296,
                -0.05044224485754967,
                -0.04076078534126282,
                0.00325059168972075,
                0.10197419673204422,
                0.0014730794355273247,
                0.0074156504124403,
                0.024486729875206947,
                0.049396488815546036,
                0.036386143416166306,
                -0.00692426972091198,
                0.014210915192961693,
                -0.00702813221141696,
                0.02576204389333725,
                -0.09799046814441681,
                0.011662093922495842,
                -0.018583141267299652,
                -0.0032054788898676634,
                -0.03541317582130432,
                -0.03267446160316467,
                0.05904189124703407,
                -0.02197239361703396,
                -0.032631415873765945,
                -0.024468934163451195,
                -0.03448859229683876,
                -0.005881531164050102,
                -0.0625174269080162,
                -0.043510664254426956,
                0.04188805818557739,
                -0.01502318773418665,
                0.014244276098906994,
                0.04081377387046814,
                0.058607108891010284,
                -0.05939854681491852,
                0.012918699532747269,
                0.02528090588748455,
                0.060976628214120865,
                0.01934882439672947,
                -0.03071914240717888,
                0.027617385610938072,
                -0.021897776052355766,
                -0.04204180836677551,
                0.03461728245019913,
                0.01984846033155918,
                -0.047352228313684464,
                0.012603127397596836,
                0.0357445552945137,
                0.010122576728463173,
                -0.011592194437980652,
                0.014836988411843777,
                0.05041445791721344,
                0.03572440147399902,
                0.03033795952796936,
                -0.03424247354269028,
                0.015589780174195766,
                0.027797792106866837,
                0.045929502695798874,
                -0.03739835321903229,
                0.05332005023956299,
                0.04740573465824127,
                0.021414704620838165,
                0.024013638496398926,
                -0.049731191247701645,
                -0.027352580800652504,
                0.00034026982029899955,
                0.03736303746700287,
                0.002286833943799138,
                0.0024630455300211906,
                0.01803434267640114,
                -0.01161507610231638,
                -0.022806966677308083,
                -0.03710596635937691,
                -0.05007542669773102,
                -0.02265642210841179,
                0.05747595429420471,
                0.019518334418535233,
                -0.06147263944149017,
                0.03698163852095604,
                0.030897609889507294,
                -0.03168238699436188,
                -0.0118087287992239,
                -0.03422461077570915,
                -0.0034116432070732117,
                -0.003169234376400709,
                0.06644970178604126,
                0.00661206291988492,
                -0.022914865985512733,
                -0.006684433203190565,
                -0.011613667011260986,
                0.003857304574921727,
                -0.041031692177057266,
                0.08204308152198792,
                0.026127595454454422,
                0.04725373536348343,
                -0.0480865053832531,
                -0.02534656971693039,
                0.005090899765491486,
                0.036001987755298615,
                -0.02614487335085869,
                -0.02348809689283371,
                -0.020981721580028534,
                -0.010264494456350803,
                -0.003903730306774378,
                0.03029595874249935,
                -0.03425419330596924,
                -0.04941501095890999,
                0.04090404883027077,
                -0.012742706574499607,
                -0.03450190648436546,
                0.030402034521102905,
                -0.01302280928939581,
                0.07938481867313385,
                0.034425731748342514,
                0.019070446491241455,
                0.0066977073438465595,
                -0.021708909422159195,
                0.02407355047762394,
                -0.012284268625080585,
                0.02586044743657112,
                -0.047074656933546066,
                -0.0478043369948864,
                -0.06440789997577667,
                0.07286886870861053,
                0.09480278939008713,
                -0.049659088253974915,
                -0.006285716779530048,
                0.06034108251333237,
                0.05542190000414848,
                0.0443781353533268,
                0.04102656990289688,
                -0.08330240845680237,
                0.027563447132706642,
                -0.04174719378352165,
                -0.045884545892477036,
                0.05367221683263779,
                0.02812453918159008,
                -0.005335439462214708,
                -0.05102653428912163,
                -0.05620304122567177,
                0.0025226427242159843,
                -0.029797032475471497,
                0.05675085261464119,
                -0.07870163023471832,
                -0.021309297531843185,
                0.015824224799871445,
                -0.006601707544177771,
                0.04751737043261528,
                -0.03655261918902397,
                0.014355775900185108,
                0.006772002205252647,
                0.013197428546845913,
                0.02318423241376877,
                -0.027352608740329742,
                0.0059523507952690125,
                -0.04129255563020706,
                0.04310232773423195,
                0.01917913556098938,
                -0.001025972655043006,
                0.03183044120669365,
                0.019583722576498985,
                0.041441045701503754,
                0.013101435266435146,
                -0.056419555097818375,
                0.07242459058761597,
                0.019492873921990395,
                -0.00020794160082004964,
                0.009017083793878555,
                -0.048412859439849854,
                -0.038537245243787766,
                -0.023654110729694366,
                -0.0027619637548923492,
                0.053804218769073486,
                -0.06054522469639778,
                -0.028412479907274246,
                -0.03142591565847397,
                0.02977866865694523,
                -0.017385397106409073,
                0.02234124206006527,
                0.06220897659659386,
                -0.011439090594649315,
                0.013072873465716839,
                -0.017712613567709923,
                -0.004219969734549522,
                0.014415818266570568,
                -0.021119002252817154,
                0.025366954505443573,
                0.0038684902247041464,
                -0.0064746481366455555,
                -0.020043127238750458,
                -0.004465610720217228,
                0.01114498358219862,
                0.046053823083639145,
                0.065823033452034,
                0.008809677325189114,
                0.0077843861654400826,
                -0.00886688195168972,
                0.0328320749104023,
                -0.058492954820394516,
                -0.1701071560382843,
                -0.01653396524488926,
                0.09085588902235031,
                -0.06559724360704422,
                0.008433758281171322,
                -0.048986196517944336,
                0.00437069172039628,
                0.003067479934543371,
                0.0009431306389160454,
                0.028768830001354218,
                -0.05279412493109703,
                -0.012487899512052536,
                0.015723876655101776,
                0.024189220741391182,
                0.005288662854582071,
                -0.04638274759054184,
                0.012184007093310356,
                -0.13098150491714478,
                -0.024414706975221634,
                0.017681609839200974,
                0.01998627930879593,
                -0.03207048028707504,
                0.04939182102680206,
                0.012835327535867691,
                0.039698079228401184,
                0.00977039523422718,
                0.03957316279411316,
                -0.08619893342256546,
                0.0424441322684288,
                0.011143249459564686,
                -0.018466424196958542,
                0.02236330695450306,
                0.03207994997501373,
                -0.009439892135560513,
                0.024230750277638435,
                0.0024042106233537197,
                0.04485265910625458,
                -0.02310892380774021,
                -0.05275497958064079,
                -0.08860979229211807,
                -0.04954412952065468,
                -0.022568220272660255,
                -6.644101251726901e-33,
                0.005211835727095604,
                -0.06895553320646286,
                0.060370493680238724,
                0.016907978802919388,
                -0.07597767561674118,
                -0.04657343775033951,
                -0.02096731774508953,
                0.04743831604719162,
                0.005956845823675394,
                -0.023140618577599525,
                -0.021211085841059685,
                -0.0076540932059288025,
                0.01410047896206379,
                -0.030760789290070534,
                0.022460145875811577,
                0.0026926833670586348,
                0.03435089439153671,
                0.027951641008257866,
                -0.034884680062532425,
                -0.0383027158677578,
                -0.030503561720252037,
                0.019782178103923798,
                0.004970276728272438,
                0.020746497437357903,
                -0.026448894292116165,
                0.008562766946852207,
                -0.024383893236517906,
                -0.01656719110906124,
                -0.010869921185076237,
                -0.03041047416627407,
                -0.0005988499033264816,
                -0.023447135463356972,
                -0.01069731917232275,
                -0.04712900519371033,
                0.019467126578092575,
                0.05071638152003288,
                0.009748860262334347,
                -0.04972444847226143,
                0.03029034473001957,
                -0.006491150241345167,
                -0.03803244233131409,
                -0.01865040883421898,
                -0.03357765078544617,
                0.0210722628980875,
                -0.0003496907011140138,
                -0.055951327085494995,
                -0.006704217288643122,
                0.021859776228666306,
                -0.0023097966331988573,
                0.09778625518083572,
                -0.05845868960022926,
                -0.0029972114134579897,
                -0.030475614592432976,
                -0.035428404808044434,
                0.03913891687989235,
                0.0005425984272733331,
                -0.002542187925428152,
                -0.043880000710487366,
                -0.0539562813937664,
                0.06321676075458527,
                0.009181520901620388,
                0.050483588129282,
                0.007654811721295118,
                -0.027303917333483696,
                -0.030099481344223022,
                0.0492125041782856,
                0.00835640262812376,
                0.039772823452949524,
                -0.035668931901454926,
                -0.007788171526044607,
                -0.033832352608442307,
                0.06860439479351044,
                0.03872877359390259,
                0.02424709126353264,
                0.01729102060198784,
                -0.0011204249458387494,
                0.03892029821872711,
                -0.03046547994017601,
                0.018245510756969452,
                0.02480960078537464,
                0.022583939135074615,
                0.001189393806271255,
                0.010691029019653797,
                -0.010222329758107662,
                -0.005878841504454613,
                -0.06660076975822449,
                -0.02979438006877899,
                0.014819895848631859,
                -0.012472905218601227,
                0.014795992523431778,
                -0.04489365965127945,
                0.0674867331981659,
                -0.021860430017113686,
                0.018888475373387337,
                0.018010379746556282,
                0.028359854593873024,
                -0.019436577335000038,
                0.004888132680207491,
                -0.01622353121638298,
                -0.05334310233592987,
                -0.05028731748461723,
                0.00550159951671958,
                -0.003108940552920103,
                0.05265470966696739,
                0.011378115974366665,
                0.02799415774643421,
                -0.018698712810873985,
                -0.024331308901309967,
                -0.029900791123509407,
                -0.03394059091806412,
                -0.012846027500927448,
                0.03690804913640022,
                0.012717468664050102,
                -0.0323215015232563,
                -0.017321791499853134,
                0.006789997685700655,
                -0.00575009873136878,
                -0.09661529958248138,
                -0.0256669819355011,
                -0.07086711376905441,
                0.01603134721517563,
                0.003657805034890771,
                -0.04181397706270218,
                0.012686527334153652,
                0.010896077379584312,
                -0.022289499640464783,
                0.013655793853104115,
                -0.0656464621424675,
                0.06559447199106216,
                -0.03275075554847717,
                -0.03195797652006149,
                0.013393722474575043,
                2.54456125503566e-07,
                -0.04548915475606918,
                0.01907624676823616,
                -0.014256765134632587,
                -0.018675174564123154,
                0.01702619530260563,
                0.013634275645017624,
                -0.033061884343624115,
                0.018048278987407684,
                0.010869705118238926,
                0.00805586576461792,
                0.011652340181171894,
                0.021957475692033768,
                0.037240877747535706,
                -0.0020102080889046192,
                -0.05336850509047508,
                -0.03744916245341301,
                -0.03843149542808533,
                0.008790019899606705,
                0.015107504092156887,
                -0.029834339395165443,
                0.0018932023085653782,
                -0.025700172409415245,
                0.045450564473867416,
                -0.025251811370253563,
                -0.017162812873721123,
                -0.009066465310752392,
                0.007795416750013828,
                -0.03976264223456383,
                0.062357209622859955,
                0.00046740914694964886,
                0.0011486149160191417,
                0.002458354225382209,
                -0.010235299356281757,
                0.04182989522814751,
                -0.004546221345663071,
                -0.0993085578083992,
                0.011876766569912434,
                -0.032060686498880386,
                0.008092964999377728,
                0.05565815046429634,
                -0.04936552792787552,
                0.03775506466627121,
                -0.0253694299608469,
                -0.009361613541841507,
                -0.0017758728936314583,
                -0.05038854852318764,
                -0.000166684651048854,
                0.04740291088819504,
                -0.004956625401973724,
                -0.02439280040562153,
                0.03973918780684471,
                0.005358891095966101,
                0.045096512883901596,
                0.009048971347510815,
                0.01408650353550911,
                0.018105924129486084,
                0.02385435253381729,
                0.023626187816262245,
                0.025308307260274887,
                0.006586618255823851,
                -0.03384614735841751,
                -0.009967109188437462,
                -0.013173426501452923,
                0.033811990171670914,
                -0.024028964340686798,
                -0.024230055510997772,
                -0.030700920149683952,
                9.374072684072192e-35,
                0.008651928044855595,
                -0.022367114201188087,
                0.0032568294554948807,
                0.02195056714117527,
                -0.0059954943135380745,
                -0.013444323092699051,
                0.04770631715655327,
                -0.05406961962580681,
                -0.008936100639402866,
                0.07898014783859253,
                -0.039898328483104706,
                0.0027629362884908915,
                -0.013554886914789677,
                -0.0074622537940740585,
                -0.0056210653856396675,
                -0.017226645722985268,
                0.04743518307805061,
                -0.037416961044073105,
                0.0008273339481092989,
                0.0034077027812600136,
                0.03743090108036995,
                0.04919927567243576,
                0.05524240806698799,
                -0.04498247057199478,
                -0.07537245005369186,
                -0.00764619093388319,
                -0.05992960184812546,
                0.01884591393172741,
                0.04522884637117386,
                -0.00196952186524868,
                0.009029877372086048,
                -0.009296375326812267,
                0.034904103726148605,
                0.03352748230099678,
                0.010215528309345245,
                0.012502368539571762,
                -0.049462009221315384,
                0.0215710811316967,
                -0.004379442427307367,
                -0.01098652370274067,
                -0.005142203066498041,
                0.004962374456226826,
                -0.01529515627771616,
                0.011495443992316723,
                -0.03716849535703659,
                1.904137093333702e-06,
                -0.02916780114173889,
                0.018895968794822693,
                -0.010603436268866062,
                0.014692459255456924,
                -0.027281535789370537,
                0.06356441229581833,
                -0.09713640809059143,
                0.011786331422626972,
                0.025023380294442177,
                -0.005199367180466652,
                -0.026096686720848083,
                0.027345972135663033,
                -0.00421809870749712,
                -0.022542022168636322,
                0.003091752063483,
                -0.010939694941043854,
                0.026298176497220993,
                -0.057942070066928864,
                -0.035179078578948975,
                0.03785208240151405,
                0.0479569286108017,
                -0.0044656964018940926,
                -0.02983962371945381,
                0.02290588989853859,
                -0.06295538693666458,
                -0.012190586887300014,
                0.00835444312542677,
                -0.022258704528212547,
                0.018971554934978485,
                0.03224032744765282,
                -0.002229811390861869,
                0.08272536098957062,
                -0.03591671958565712,
                0.030950404703617096,
                0.0003323761629872024,
                -0.020083215087652206,
                0.06030644103884697,
                0.020029693841934204,
                0.013503450900316238,
                0.008739247918128967,
                -0.01077077817171812,
                -0.012688545510172844,
                0.003253416158258915,
                0.006549533922225237,
                0.0061324178241193295,
                0.00535047659650445,
                0.030805613845586777,
                0.003201642306521535,
                0.05813524127006531,
                0.0611393116414547,
                -0.04562380164861679,
                -0.019235823303461075,
                0.017524922266602516,
                0.011423168703913689,
                0.007287373300641775,
                -0.028264107182621956,
                -0.024348407983779907,
                -0.02404516004025936,
                0.025256386026740074,
                0.006796552333980799,
                -0.012412249110639095,
                0.019687015563249588,
                0.04280087351799011,
                -0.009277677163481712,
                -0.02112208679318428,
                -0.012979722581803799,
                0.0452757403254509,
                0.021706439554691315,
                0.012582092545926571,
                0.03856860473752022,
                -0.005152765195816755,
                -0.04013793542981148,
                0.013111147098243237,
                -0.016804445534944534,
                0.037746984511613846,
                -0.021027853712439537,
                0.02628466673195362,
                0.01709810644388199,
                0.005580628756433725,
                0.06300569325685501,
                0.007873591966927052,
                -0.02520328387618065,
                0.0414385125041008,
                -0.023057717829942703,
                0.01861494965851307,
                -0.08870330452919006,
                -0.020300105214118958,
                -0.010968332178890705,
                -0.0036126214545220137,
                0.010683096945285797,
                0.0737905204296112,
                -0.03847146034240723,
                -0.016489973291754723,
                0.016473183408379555,
                -0.03405243158340454,
                -0.024000728502869606,
                -0.042385466396808624,
                0.026330703869462013,
                0.00666098203510046,
                0.03727634996175766,
                0.07221610099077225,
                0.003490813309326768,
                -0.024255644530057907,
                -0.04874382168054581,
                -0.0001276662660529837,
                0.042814888060092926,
                -0.018937967717647552,
                -0.006553350482136011,
                -0.009397882036864758,
                -0.006483621429651976,
                -0.023159658536314964,
                0.04955974593758583,
                0.013348281383514404,
                0.0026312884874641895,
                -0.025578977540135384,
                -0.01543433777987957,
                0.03981267288327217,
                -0.00701757799834013,
                -0.0060425144620239735,
                0.03525446355342865,
                0.016048168763518333,
                0.0189494751393795,
                -0.02791326306760311,
                -0.039161164313554764,
                -0.04308827593922615,
                0.019112655892968178,
                -0.0025561125949025154,
                0.01731245219707489,
                -0.03588198125362396,
                -0.014737570658326149,
                -0.027584096416831017,
                0.041329409927129745,
                -0.02413279004395008,
                -0.045639459043741226,
                0.024524355307221413,
                -0.019574182108044624,
                -0.037993546575307846,
                -0.004594820085912943,
                0.007556811440736055,
                -0.007528776302933693,
                0.13601309061050415,
                -0.027963649481534958,
                0.03202635049819946,
                -0.06696163862943649,
                -0.006392334587872028,
                -0.02802618220448494,
                -0.03956892341375351,
                -0.017895478755235672,
                0.04583815857768059,
                0.07256526499986649,
                0.01165151596069336,
                -0.03397327661514282,
                0.01868387870490551,
                -0.005012040492147207,
                -0.007466478273272514,
                -0.012854691594839096,
                0.0031014380510896444,
                -0.03632340207695961,
                -0.01894715055823326,
                -0.007160494569689035,
                -0.024008365347981453,
                0.02190444990992546,
                -0.07804238051176071,
                0.016640212386846542,
                -0.041993118822574615,
                0.016910383477807045,
                -0.04948655888438225,
                0.01754515804350376,
                0.03842935338616371,
                -0.011333229951560497,
                -0.015685604885220528,
                0.015432558953762054,
                -0.0015252222074195743,
                -0.0441378578543663,
                -0.000608748581726104,
                0.00026012706803157926,
                0.040885861963033676,
                0.009115473367273808,
                0.0022775994148105383,
                -0.007671070285141468,
                0.022953292354941368,
                0.012932167388498783,
                0.044837433844804764,
                -0.11308135837316513,
                0.040994368493556976,
                -0.022330082952976227,
                0.007383265998214483,
                0.0377519354224205,
                0.026201369240880013,
                -0.06575615704059601,
                0.021893061697483063,
                -0.027676915749907494,
                0.00032080590608529747,
                0.03329750895500183,
                -0.0036137390416115522,
                0.010303317569196224,
                0.02704036422073841,
                0.02118314616382122,
                0.012494759634137154,
                0.04820218309760094,
                0.10943171381950378,
                0.015477923676371574,
                -0.003780822502449155,
                0.011788356117904186,
                -0.15123867988586426,
                -0.02925630286335945,
                0.07137764245271683,
                0.01383436843752861,
                -0.014720052480697632,
                0.0017093855421990156,
                0.024341097101569176,
                0.06305932998657227,
                -0.04272972792387009,
                0.0037445512134581804,
                -0.026457546278834343,
                -0.0125178974121809,
                0.004716800060123205,
                0.042583197355270386,
                -0.01136862300336361,
                0.05293446034193039,
                -0.06213589757680893,
                -0.04578839987516403,
                0.018477413803339005,
                -0.04067569226026535,
                -0.006067040376365185,
                0.008894241414964199,
                -0.00860676635056734,
                -0.01238276157528162,
                -0.04944808781147003,
                0.014784940518438816,
                -0.05841367319226265,
                0.011069119907915592,
                -0.07608438283205032,
                -0.009429548867046833,
                0.019871197640895844,
                0.002333028009161353,
                0.0032462365925312042,
                0.003297694493085146,
                -0.022987553849816322,
                0.01787343993782997,
                -0.043699491769075394,
                0.07833965122699738,
                0.014979597181081772,
                -0.02745744399726391,
                0.005090288817882538,
                0.003882661461830139,
                0.0423138253390789,
                -0.058551523834466934,
                0.0023486209101974964,
                -0.011137308552861214,
                -0.0014173005474731326,
                -0.0159579049795866,
                -0.008666987530887127,
                0.0231211818754673,
                -0.008453506976366043,
                -0.056255556643009186,
                0.002826223848387599,
                0.03441571444272995,
                -0.07487214356660843,
                0.014686554670333862,
                -0.031413789838552475,
                -0.049992747604846954,
                0.014462381601333618,
                0.013429798185825348,
                -0.01985371671617031,
                -0.028970465064048767,
                0.060118068009614944,
                -0.001981545938178897,
                0.003963978495448828,
                -0.05545113980770111,
                0.016075382009148598,
                -0.009307460859417915,
                -0.014962957240641117,
                -0.022248223423957825,
                0.07044108957052231,
                -0.00229018647223711,
                -0.002752306405454874,
                0.0008868314325809479,
                -0.015352820977568626,
                0.025177884846925735,
                -0.06882642954587936,
                -0.023196108639240265,
                -0.02870715968310833,
                0.03898179531097412,
                -0.009720114059746265,
                0.028629126027226448,
                -0.005878088995814323,
                -0.004070097114890814,
                0.014441399835050106,
                0.0311376191675663,
                0.03310372307896614,
                0.03484902158379555,
                0.032608240842819214,
                0.05142902210354805,
                0.0009486075723543763,
                -0.021384909749031067,
                0.013213051483035088,
                0.016347073018550873,
                -0.03413721174001694,
                -0.04736656695604324,
                0.027905214577913284,
                -0.00877540372312069,
                -0.02917792834341526,
                0.002166059101000428,
                -0.01484019123017788,
                0.04584208130836487,
                -0.006409632507711649,
                -0.024568378925323486,
                -0.046146612614393234,
                -0.008961285464465618,
                -0.026572229340672493,
                0.021804993972182274,
                -0.0072792223654687405,
                -0.021699441596865654,
                -0.025111597031354904,
                0.022370316088199615,
                0.07243379950523376,
                -0.012226591818034649,
                -0.023824313655495644,
                0.0011304781073704362,
                -0.03129125013947487,
                -0.01265277061611414,
                0.011962237767875195,
                0.042172517627477646,
                -0.04774123802781105,
                0.007403555326163769,
                0.03464134782552719,
                0.06854746490716934,
                0.006809457205235958,
                0.08386962860822678,
                0.06464646011590958,
                0.03373301401734352,
                0.05393923074007034,
                -0.013602676801383495,
                -0.014917423948645592,
                0.01976572349667549,
                0.07000651955604553,
                -0.033030785620212555,
                0.0821656733751297,
                -0.0009129558457061648,
                -0.04141795635223389,
                0.02467939630150795,
                -0.08525263518095016,
                -0.04895509406924248,
                -0.016608688980340958,
                -0.023185662925243378,
                -0.02694890648126602,
                0.0032464417163282633,
                0.021161146461963654,
                0.01051926240324974,
                -0.017481425777077675,
                -0.03807636350393295,
                0.04127257689833641,
                -0.005051682237535715,
                0.010340943932533264,
                0.02479238249361515,
                0.035547759383916855,
                -0.031622469425201416,
                0.043930839747190475,
                0.057552520185709,
                0.03870336338877678,
                0.001789535628631711,
                -0.010995480231940746,
                0.007458291947841644,
                0.021744465455412865,
                0.020731182768940926,
                0.08750180900096893,
                0.07972823828458786,
                0.04412028193473816,
                -0.021846003830432892,
                0.027355877682566643,
                0.0233914777636528,
                0.06884650141000748,
                0.030790772289037704,
                -0.04825172200798988,
                -0.060633234679698944,
                0.019169514998793602,
                0.029446979984641075,
                -0.0005382325616665184,
                0.02807617001235485,
                -0.05676767975091934,
                -0.014386295340955257,
                -0.04264582693576813,
                0.08553539961576462,
                -0.020961077883839607,
                0.024174440652132034,
                -0.014901951886713505,
                0.03655099496245384,
                -0.021938549354672432,
                -0.024349920451641083,
                -0.015886344015598297,
                0.018284011632204056,
                0.009394291788339615,
                0.026772016659379005,
                -0.006701132282614708,
                0.017220256850123405,
                0.008028329350054264,
                0.009342755191028118,
                0.02379511669278145,
                0.02452344261109829,
                -0.03439197689294815,
                0.011326933279633522,
                0.04330170899629593,
                -0.07978224009275436,
                -0.016630450263619423,
                -0.03427598625421524,
                -0.02137579396367073,
                -0.007862364873290062,
                -0.014527267776429653,
                -0.08079342544078827,
                0.029294811189174652,
                0.019861528649926186,
                -0.06402458250522614,
                -0.01514082495123148,
                0.06847559660673141,
                0.012713420204818249,
                0.04038893058896065,
                0.0614628866314888,
                -0.04620424658060074,
                0.022886570543050766,
                -0.01783415488898754,
                -0.020651785656809807,
                0.009226015768945217,
                0.011208845302462578,
                0.011295572854578495,
                -0.010794448666274548,
                0.02625112608075142,
                0.022838221862912178,
                -0.02736978977918625,
                0.013295857235789299,
                -0.036534424871206284,
                0.00934553425759077,
                -0.06162310764193535,
                0.052295323461294174,
                -0.023310234770178795,
                0.005391964688897133,
                -0.008875946514308453,
                0.010765980929136276,
                0.029758233577013016,
                0.054168570786714554,
                0.026058536022901535,
                -0.06280539929866791,
                0.006498852279037237,
                0.01787669211626053,
                -0.010286809876561165,
                0.010089279152452946,
                0.02605060301721096,
                -0.010772272944450378,
                -0.00811227411031723,
                -0.024284789338707924,
                -0.05158509314060211,
                0.012103046290576458,
                -0.017119823023676872,
                -0.016326554119586945,
                -0.027354983612895012,
                0.03280937671661377,
                0.011829152703285217,
                -0.01842423900961876,
                -0.08229053020477295,
                -0.047034285962581635,
                0.007409334648400545,
                -0.062000688165426254,
                -0.0436837263405323,
                -0.06273078173398972,
                -0.048840899020433426,
                -0.046168003231287,
                0.015142965130507946,
                0.023240257054567337,
                -0.017075924202799797,
                -0.014996434561908245,
                -0.020381173118948936,
                -0.0036638143938034773,
                0.0492468923330307,
                0.040176089853048325,
                -0.001739630475640297,
                0.03112606331706047,
                -0.03309164196252823,
                -0.03576955944299698,
                -0.0689980536699295,
                -0.03861561417579651,
                0.027579668909311295,
                -0.010528060607612133,
                -0.016147268936038017,
                -0.0232956875115633,
                0.0004930379800498486,
                0.0019188926089555025,
                0.021961968392133713,
                0.03922893851995468,
                0.02147618867456913,
                -0.00891080778092146,
                -0.030425354838371277,
                0.014045230112969875,
                -0.0028291905764490366,
                -0.03600582107901573,
                -0.031745072454214096,
                -0.14951753616333008,
                0.03339085727930069,
                -0.02866305597126484,
                0.0185420960187912,
                -0.01947709359228611,
                0.05385139212012291,
                0.037085022777318954,
                0.010675759986042976,
                -0.007222667336463928,
                0.030151888728141785,
                -0.06549575179815292,
                0.0022741947323083878,
                0.002388800261542201,
                -0.011312334798276424,
                -0.05392275005578995,
                -0.01822451315820217,
                -0.03510085865855217,
                0.03726730868220329,
                0.011984751559793949,
                0.021997880190610886,
                0.004428317304700613,
                0.022722503170371056,
                -0.10608255863189697,
                -0.023414110764861107,
                0.0098866056650877,
                -7.448338487045017e-33,
                -0.00324943196028471,
                0.015700072050094604,
                -0.06641267240047455,
                -0.042725346982479095,
                -0.1043563038110733,
                -0.009071353822946548,
                -0.0031987670809030533,
                0.021457215771079063,
                -0.02322906255722046,
                0.034821685403585434,
                -0.024034975096583366,
                0.06172614172101021,
                0.019848952069878578,
                -0.020925477147102356,
                0.013581700623035431,
                -0.04502347111701965,
                -0.06201387941837311,
                0.03257405385375023,
                -0.023076485842466354,
                -0.020454417914152145,
                0.008977919816970825,
                0.034465279430150986,
                0.011704735457897186,
                0.047697216272354126,
                0.00591064291074872,
                0.00046962741180323064,
                -0.03169277310371399,
                -0.034593239426612854,
                0.06468082219362259,
                -0.008899572305381298,
                -0.03274383395910263,
                -0.00991634652018547,
                0.007324513979256153,
                0.028354624286293983,
                0.026601696386933327,
                0.03536717966198921,
                -0.006662593688815832,
                0.004487371072173119,
                0.028164157643914223,
                -0.02540634758770466,
                -0.1126171350479126,
                -0.042105335742235184,
                0.008006527088582516,
                0.0260213240981102,
                -0.015004301443696022,
                -0.07244989275932312,
                0.021080024540424347,
                -0.01643197238445282,
                0.03645971417427063,
                0.07272854447364807,
                -0.03512515500187874,
                -0.037261802703142166,
                -0.048883695155382156,
                -0.029598336666822433,
                0.02535119093954563,
                0.022203361615538597,
                -0.02626335807144642,
                0.10422404110431671,
                0.008248839527368546,
                0.02951892837882042,
                -0.0596650056540966,
                -0.004376252181828022,
                0.019852396100759506,
                -0.019689198583364487,
                0.022819137200713158,
                0.04286002367734909,
                -0.026201698929071426,
                -0.01381662581115961,
                -0.00715750502422452,
                0.0522109679877758,
                0.02878592535853386,
                0.03140020743012428,
                0.05505840852856636,
                0.018410658463835716,
                -0.13661547005176544,
                -0.04479597136378288,
                0.04483932629227638,
                0.005951173137873411,
                0.041234735399484634,
                0.045063238590955734,
                0.017980650067329407,
                -0.005090807564556599,
                0.016440169885754585,
                0.03313672915101051,
                -0.04762404412031174,
                -0.09009288996458054,
                -0.0067720492370426655,
                0.04326320067048073,
                -0.03157138451933861,
                -0.026337549090385437,
                0.015786387026309967,
                0.004809455946087837,
                -0.02656935155391693,
                0.00043129120604135096,
                0.010281452909111977,
                -0.03547239676117897,
                0.014947809278964996,
                0.05185476690530777,
                0.0213765986263752,
                -0.028263729065656662,
                0.02496761828660965,
                0.018115829676389694,
                -0.02816510573029518,
                0.01996961422264576,
                0.05950668826699257,
                -0.02808229625225067,
                -0.018480462953448296,
                -0.01266380026936531,
                -0.021115534007549286,
                -0.03510437160730362,
                -0.055654287338256836,
                0.04250681400299072,
                0.04452161863446236,
                -0.00020274783310014755,
                -0.036909069865942,
                -0.023680001497268677,
                -0.009053852409124374,
                -0.06731564551591873,
                0.02766609936952591,
                0.0007157365907914937,
                -0.002655237913131714,
                0.009302154183387756,
                -0.05567558482289314,
                0.03287911042571068,
                -0.05958516523241997,
                0.017100784927606583,
                -0.0014458015793934464,
                0.005461347289383411,
                0.01636979728937149,
                -0.03815649822354317,
                -0.014355292543768883,
                -0.005686062853783369,
                2.49258647500028e-07,
                -0.09959902614355087,
                0.0063092936761677265,
                -0.023469053208827972,
                -0.056776873767375946,
                0.032876964658498764,
                0.08251792937517166,
                -0.00869970303028822,
                0.07936786860227585,
                0.027557363733649254,
                -0.04351983964443207,
                0.04636299982666969,
                -0.05144086852669716,
                0.049830637872219086,
                -0.013922291807830334,
                0.037946902215480804,
                0.05140490084886551,
                0.028362050652503967,
                -0.03416235372424126,
                0.029526986181735992,
                -0.045857857912778854,
                0.021052831783890724,
                0.024036327376961708,
                -0.002523130737245083,
                0.0015612923307344317,
                0.03237581253051758,
                0.060279443860054016,
                -0.02188187837600708,
                -0.09914571791887283,
                0.006322021130472422,
                -0.031084571033716202,
                0.003827961627393961,
                -0.007774001453071833,
                -0.028452685102820396,
                -0.00923817791044712,
                -0.016370873898267746,
                -0.010875274427235126,
                0.009574304334819317,
                0.030406000092625618,
                0.02095768041908741,
                0.010390630923211575,
                0.0018480088328942657,
                0.022125618532299995,
                -0.021438516676425934,
                -0.004352618474513292,
                0.005724715068936348,
                -0.05033598467707634,
                -0.03519531711935997,
                0.014155884273350239,
                -0.01888144388794899,
                0.02358854189515114,
                -0.04776066541671753,
                0.039319656789302826,
                -0.05544988438487053,
                0.06896420568227768,
                0.008014111779630184,
                0.012539058923721313,
                0.031961485743522644,
                -0.0020793115254491568,
                0.039028335362672806,
                -0.09664949774742126,
                0.0039335074834525585,
                -0.04670286178588867,
                -0.020329931750893593,
                0.055636804550886154,
                0.015521839261054993,
                0.009095310233533382,
                -0.03341958671808243,
                6.796516629856237e-35,
                0.0029833039734512568,
                -0.0016459283651784062,
                0.018257079645991325,
                -0.012140556238591671,
                0.01300265733152628,
                0.0067862048745155334,
                0.02215537242591381,
                0.049163252115249634,
                0.05329412221908569,
                -0.025058835744857788,
                0.02060278132557869,
                0.031198788434267044,
                -0.039661165326833725,
                -0.03440503031015396,
                -0.001646053628064692,
                -0.03604745492339134,
                0.012310086749494076,
                -0.0020180947612971067,
                0.045010264962911606,
                0.08127191662788391,
                -0.010825452394783497,
                0.09841453284025192,
                0.006828940007835627,
                0.05526510998606682,
                0.09862016886472702,
                0.01284649595618248,
                -0.0943596363067627,
                0.0021301847882568836,
                -0.005082271993160248,
                0.0037177440244704485,
                -0.002177484566345811,
                -0.023504899814724922,
                0.02412382699549198,
                -0.04902588948607445,
                -0.015918508172035217,
                0.005000726785510778,
                -0.025590011849999428,
                -0.036131352186203,
                -0.02348415181040764,
                -0.019384020939469337,
                -0.022152215242385864,
                -0.03631560504436493,
                0.012401950545608997,
                -0.043570615351200104,
                -0.016107747331261635,
                1.713727215246763e-06,
                -0.026495371013879776,
                0.01003147754818201,
                0.010507052764296532,
                0.03710606321692467,
                -0.00958134327083826,
                0.006761557888239622,
                0.05787166580557823,
                -0.05267345532774925,
                0.03109663724899292,
                0.019723866134881973,
                -0.036220379173755646,
                0.08692124485969543,
                -0.0656886175274849,
                -0.030342191457748413,
                -0.01984930783510208,
                -0.007671974133700132,
                -0.020067641511559486,
                -0.04712416231632233,
                -0.01595619134604931,
                0.08746155351400375,
                -0.009235496632754803,
                0.0449269637465477,
                -0.024985365569591522,
                0.03994312882423401,
                -0.00747746741399169,
                -0.01899847574532032,
                -0.020111722871661186,
                0.010930497199296951,
                -0.008413746953010559,
                0.02259678579866886,
                0.04822506010532379,
                -0.01601795293390751,
                -0.06603607535362244,
                0.004575933795422316,
                0.008378144353628159,
                0.03463733568787575,
                -0.019479071721434593,
                0.04773513600230217,
                0.07742545008659363,
                -0.0469142310321331,
                0.0634203627705574,
                -0.029859047383069992,
                -0.0017534299986436963,
                -0.003918292932212353,
                -0.00708075100556016,
                -0.06565995514392853,
                -0.02304733172059059,
                0.0028452500700950623,
                0.03454732522368431,
                0.028315186500549316,
                0.0011422084644436836,
                0.008392326533794403,
                -0.022832997143268585,
                0.004223168827593327,
                0.031075699254870415,
                0.03264787420630455,
                -0.11054417490959167,
                0.019316699355840683,
                -0.005967088975012302,
                0.09616389870643616,
                -0.031550612300634384,
                -0.005499610677361488,
                1.033682292472804e-05,
                0.059682346880435944,
                -0.023140668869018555,
                0.02017407864332199,
                0.0034190951846539974,
                -0.022273171693086624,
                0.04900753125548363,
                -0.013198275119066238,
                0.018507350236177444,
                0.08081722259521484,
                -0.045498523861169815,
                0.0015798762906342745,
                0.08402935415506363,
                -0.003957652021199465,
                -0.05311546102166176,
                0.008428020402789116,
                0.008516899310052395,
                0.028202425688505173,
                0.03887099772691727,
                -0.00832336861640215,
                0.03142274543642998,
                -0.01087945606559515,
                0.07527124136686325,
                -0.01149238832294941,
                -0.047946996986866,
                0.0437048077583313,
                0.052178315818309784,
                -0.03330506011843681,
                -0.00647004647180438,
                -0.044806115329265594,
                -0.020248500630259514,
                0.039279159158468246,
                -0.043850578367710114,
                0.03125675022602081,
                0.021774590015411377,
                -0.00521094910800457,
                0.015325183980166912,
                0.03105870448052883,
                0.05834642052650452,
                -0.04684459790587425,
                0.013444241136312485,
                -0.024223381653428078,
                -0.06848572939634323,
                0.011526521295309067,
                -0.020398491993546486,
                -0.008013433776795864,
                -0.049339428544044495,
                -0.0034850039519369602,
                0.007432205136865377,
                -0.004737827926874161,
                0.013846433721482754,
                0.03609040379524231,
                0.0357716865837574,
                0.007023099344223738,
                0.06225670501589775,
                -0.042594604194164276,
                0.012048623524606228,
                0.05734806880354881,
                -0.00793683622032404,
                -0.020572200417518616,
                -0.008023316040635109,
                -0.0026098289526998997,
                -0.0005192813696339726,
                0.05462714284658432,
                0.004312447272241116,
                0.007964352145791054,
                -0.026253994554281235,
                -0.007144552189856768,
                -0.0009885621257126331,
                -0.024638714268803596,
                -0.024852754548192024,
                -0.03115026466548443,
                -0.0022370375227183104,
                -0.08758457005023956,
                -0.0005697572487406433,
                -0.04533150792121887,
                -0.010695193894207478,
                0.024858323857188225,
                0.06989002972841263,
                0.01813887432217598,
                0.027839945629239082,
                0.030355945229530334,
                0.010545579716563225,
                0.024580948054790497,
                -0.06838355213403702,
                -0.07465657591819763,
                0.03316696360707283,
                -0.030212439596652985,
                0.021772800013422966,
                -0.030954409390687943,
                0.03123573027551174,
                -0.009976169094443321,
                -0.0009412624058313668,
                -0.0023150956258177757,
                -0.016496287658810616,
                0.005538674537092447,
                0.005656159482896328,
                0.021967260167002678,
                -0.014412220567464828,
                0.013010188937187195,
                -0.01999848708510399,
                -0.01579441875219345,
                0.009691295213997364,
                -0.06677556782960892,
                -0.0012106659123674035,
                0.002890754956752062,
                0.04777108132839203,
                0.010892420075833797,
                0.004382901359349489,
                0.025773487985134125,
                -0.015378008596599102,
                -0.037467628717422485,
                -0.04665461555123329,
                -0.0035605626180768013,
                0.03998300060629845,
                -0.04048386216163635,
                0.03890817239880562,
                -0.018099868670105934,
                0.045386455953121185,
                -0.002890521427616477,
                0.05402470380067825,
                -0.017601102590560913,
                0.02322518266737461,
                -0.02943320944905281,
                -0.03735724836587906,
                0.06892088055610657,
                0.012176665477454662,
                -0.03530384227633476,
                -0.04347764700651169,
                -0.03418169543147087,
                0.02997949719429016,
                -0.04271113499999046,
                -0.019492963328957558,
                0.008136185817420483,
                0.03958439454436302,
                0.0037739083636552095,
                0.0619913674890995,
                0.008849583566188812,
                -0.00185749726369977,
                0.09421741962432861,
                0.0009243432432413101,
                0.029969368129968643,
                -0.08948299288749695,
                -0.007600467652082443,
                0.06536737084388733,
                0.024266259744763374,
                0.017246855422854424,
                0.04773087799549103,
                -0.013801608234643936,
                0.021181436255574226,
                -0.009870319627225399,
                -0.02007877081632614,
                -0.02912183292210102,
                -0.0844423845410347,
                -0.0019176651258021593,
                0.0001833531423471868,
                0.022129137068986893,
                0.044336892664432526,
                0.016417836770415306,
                -0.018620187416672707,
                -0.0031481089536100626,
                0.0009168697870336473,
                0.02466801181435585,
                0.06075071915984154,
                -0.036584336310625076,
                0.00807890109717846,
                0.006079549435526133,
                0.05599402263760567,
                -0.02737596072256565,
                -0.03008291870355606,
                -0.05787533521652222,
                -0.10257827490568161,
                0.020255722105503082,
                -0.023990144953131676,
                0.004450115375220776,
                0.001996040577068925,
                0.008669089525938034,
                -0.025952978059649467,
                -0.021495604887604713,
                -0.016868317499756813,
                -0.07327328622341156,
                -0.024410301819443703,
                -0.020572571083903313,
                -0.007701711729168892,
                -0.07626323401927948,
                -0.03593103215098381,
                -0.03151557594537735,
                0.023242181167006493,
                0.020429005846381187,
                0.021027762442827225,
                -0.004183953162282705,
                0.014009116217494011,
                0.0002840197121258825,
                -0.057352159172296524,
                0.008164042606949806,
                0.03602854162454605,
                -0.022117964923381805,
                -0.0020856675691902637,
                -0.01778445392847061,
                -0.026714805513620377,
                0.07595853507518768,
                -0.006901031360030174,
                0.011686409823596478,
                0.016446296125650406,
                0.00652806693688035,
                0.017207922413945198,
                -0.05410749092698097,
                -0.038770440965890884,
                -0.017565976828336716,
                0.025057600811123848,
                -0.06538116186857224,
                -0.01185142993927002,
                0.12104080617427826,
                0.06805939972400665,
                0.022468794137239456,
                0.024836864322423935,
                -0.015509194694459438,
                -0.023582402616739273,
                0.06385055184364319,
                -0.03917302191257477,
                0.003502446925267577,
                -0.027230972424149513,
                0.09421372413635254,
                -0.0033311457373201847,
                -0.010624065063893795,
                0.059454165399074554,
                -0.023763049393892288,
                -0.013354484923183918,
                0.04035620391368866,
                -0.007998141460120678,
                0.06078851968050003,
                -0.02412939816713333,
                -0.016819369047880173,
                -0.0005218392470851541,
                -0.010237721726298332,
                0.013641524128615856,
                0.019058668985962868,
                0.002701032906770706,
                0.1306692212820053,
                -0.056424133479595184,
                -0.08210332691669464,
                0.017608074471354485,
                0.027664804831147194,
                -0.017985720187425613,
                -0.038815613836050034,
                0.0028636783827096224,
                -0.059283554553985596,
                0.006839109119027853,
                0.008161060512065887,
                0.023702997714281082,
                0.0004152467881795019,
                -0.029293512925505638,
                -0.01794854737818241,
                0.04748988524079323,
                0.0417357012629509,
                0.014793706126511097,
                -0.017034966498613358,
                -0.015321541577577591,
                -0.021192684769630432,
                -0.04892624169588089,
                -0.054276444017887115,
                -0.03659498691558838,
                -0.015816280618309975,
                0.016927305608987808,
                0.053346898406744,
                -0.01537016499787569,
                -0.0045390259474515915,
                -0.05031939968466759,
                0.03809938207268715,
                0.02844126895070076,
                -0.03385002166032791,
                -0.030662547796964645,
                0.03667434677481651,
                -0.003785425331443548,
                0.06903461366891861,
                -0.011968467384576797,
                0.0005749434349127114,
                0.02092118002474308,
                0.024976322427392006,
                0.01170490775257349,
                0.0006753028719685972,
                -0.030235808342695236,
                0.029214709997177124,
                0.01650203764438629,
                -0.07296928763389587,
                -0.009592648595571518,
                0.03167637810111046,
                0.044672705233097076,
                -0.033725228160619736,
                -0.01173072773963213,
                0.010909106582403183,
                -0.037083614617586136,
                0.0033899699337780476,
                -0.036328308284282684,
                0.015810027718544006,
                -0.0314839743077755,
                -0.009874116629362106,
                0.027686167508363724,
                0.0491347461938858,
                0.00855350960046053,
                0.04032571241259575,
                -0.0004707738698925823,
                0.02597922645509243,
                -0.009858684614300728,
                -0.055998627096414566,
                0.023494891822338104,
                0.03366332873702049,
                0.04422444850206375,
                -0.028235891833901405,
                0.07243023067712784,
                -0.0022541789803653955,
                0.025333788245916367,
                -0.00531381368637085,
                0.03478164225816727,
                -0.006516908761113882,
                -0.000868687522597611,
                -0.016535399481654167,
                0.020821135491132736,
                0.015076861716806889,
                -0.02359931170940399,
                -0.016455398872494698,
                0.003413486061617732,
                -0.01670714281499386,
                -0.010060095228254795,
                0.025449495762586594,
                0.023636244237422943,
                0.012960733845829964,
                0.030018264427781105,
                -0.012652281671762466,
                0.037643708288669586,
                0.03558332845568657,
                -0.038117244839668274,
                -0.003921831492334604,
                -0.005361052695661783,
                -0.03311337158083916,
                -0.054001111537218094,
                0.042975444346666336,
                -0.05476048216223717,
                -0.048639506101608276,
                -0.06803087890148163,
                0.035075075924396515,
                -0.05851409584283829,
                -0.03417373448610306,
                0.050337087363004684,
                -0.03635912388563156,
                -0.03146706894040108,
                -0.005718186963349581,
                -0.05931580439209938,
                0.08196714520454407,
                0.022911949083209038,
                -0.04741852730512619,
                -0.09528545290231705,
                0.04482713341712952,
                0.05405963957309723,
                -0.013137172907590866,
                -0.030745740979909897,
                0.016232145950198174,
                -0.01051880419254303,
                -0.01668045111000538,
                -0.003695069346576929,
                -0.028204593807458878,
                -0.0026775922160595655,
                -0.03752433508634567,
                0.021573184058070183,
                0.03573356568813324,
                -0.08765194565057755,
                -0.03794539347290993,
                0.03431561589241028,
                -0.1137312799692154,
                0.0003857025585602969,
                -0.07042453438043594,
                -0.012005644850432873,
                -0.026477176696062088,
                -0.02219093032181263,
                0.03695269674062729,
                -0.03279620781540871,
                -0.019604306668043137,
                0.028051026165485382,
                -0.009758081287145615,
                -0.06049705669283867,
                -0.07967828214168549,
                0.011010942049324512,
                0.008044804446399212,
                -0.003815610893070698,
                0.0013897112803533673,
                -0.04998520389199257,
                0.019934505224227905,
                0.03404264152050018,
                -0.006858346052467823,
                -0.01388614159077406,
                -0.031003225594758987,
                0.014592994935810566,
                -0.007542758714407682,
                0.00035324195050634444,
                0.026375601068139076,
                0.06292238086462021,
                0.041879478842020035,
                0.0005222824984230101,
                -0.03763091564178467,
                0.005112284328788519,
                0.021927671507000923,
                0.0036426191218197346,
                0.03041704185307026,
                -0.0021863568108528852,
                0.018441716209053993,
                -0.02373223379254341,
                -0.014163314364850521,
                -0.0032276727724820375,
                -0.002495868830010295,
                -0.023639002814888954,
                0.0026851666625589132,
                0.001670566969551146,
                0.005893759895116091,
                0.02985641360282898,
                -0.047102559357881546,
                -0.0359659381210804,
                -0.007491747383028269,
                0.05045630410313606,
                0.06971870362758636,
                0.034222666174173355,
                -0.02906505949795246,
                -0.026679174974560738,
                -0.0018111866666004062,
                0.03168145567178726,
                -0.03166482225060463,
                -0.04339056462049484,
                0.03229731321334839,
                0.01366209052503109,
                -0.0521065779030323,
                -0.011702345684170723,
                0.0035562103148549795,
                -0.037560172379016876,
                0.047487739473581314,
                -0.031623724848032,
                -0.045277807861566544,
                -0.007317493204027414,
                -0.03178669884800911,
                0.0013187415897846222,
                0.07940925657749176,
                0.04636058956384659,
                0.037147898226976395,
                0.02831103838980198,
                -0.022965582087635994,
                0.03888903185725212,
                -0.03097611479461193,
                -0.01445167139172554,
                -0.00738733122125268,
                0.04824676737189293,
                0.029405508190393448,
                0.06042223796248436,
                0.04651018604636192,
                0.016713352873921394,
                0.03453683480620384,
                -0.01378888264298439,
                0.041584938764572144,
                -0.013735164888203144,
                0.016682462766766548,
                -0.013554973527789116,
                -6.614293653725925e-33,
                -0.0292535237967968,
                -0.03209967538714409,
                -0.022860361263155937,
                0.04145721346139908,
                -0.004860998131334782,
                -0.05622076243162155,
                0.004548176191747189,
                0.00016448620590381324,
                -0.01211156789213419,
                -0.01129644364118576,
                0.010541915893554688,
                0.0008881331305019557,
                0.02841324731707573,
                -0.005975834093987942,
                0.019090667366981506,
                -0.03950504586100578,
                0.01579323038458824,
                -0.025252187624573708,
                -0.03050098568201065,
                -0.03686204180121422,
                0.017507707700133324,
                0.0023765750229358673,
                -0.029622768983244896,
                -0.0051303342916071415,
                0.028613530099391937,
                0.02992398478090763,
                -0.023830458521842957,
                -0.037897028028964996,
                0.03540555387735367,
                0.017629394307732582,
                0.016522176563739777,
                0.03076113387942314,
                -0.029025334864854813,
                -0.023809298872947693,
                -0.0008968113688752055,
                0.096335269510746,
                -0.022585425525903702,
                -0.057455144822597504,
                0.025660555809736252,
                0.047072090208530426,
                -0.03870123252272606,
                -0.0009156089508906007,
                0.022432561963796616,
                -0.05485791712999344,
                -0.013472765684127808,
                -0.05294085666537285,
                0.031623147428035736,
                -0.055783070623874664,
                0.013168812729418278,
                -0.033846911042928696,
                -0.017157066613435745,
                0.022133365273475647,
                -0.045920904725790024,
                -0.018479593098163605,
                0.04875968024134636,
                0.04846780747175217,
                0.021425679326057434,
                -0.07962039113044739,
                0.03269527480006218,
                0.014821871183812618,
                -0.01110095251351595,
                0.03304694592952728,
                -0.0028129564598202705,
                0.02400798164308071,
                -0.0053581492975354195,
                0.0007111876038834453,
                -0.018140550702810287,
                -0.011232836171984673,
                -0.042356669902801514,
                0.048703886568546295,
                -0.019981300458312035,
                0.057268861681222916,
                0.014588849619030952,
                -0.03093813546001911,
                -0.011762730777263641,
                -0.03678657487034798,
                -0.026497704908251762,
                0.03137814998626709,
                -0.020336870104074478,
                0.016258910298347473,
                -0.023728400468826294,
                -0.010490634478628635,
                0.03684326633810997,
                0.016271177679300308,
                0.0030600617174059153,
                -0.03609820082783699,
                -0.008936692029237747,
                -0.012843280099332333,
                0.0004550084995571524,
                0.009234687313437462,
                0.020357493311166763,
                -0.01412964891642332,
                -0.04640452563762665,
                -0.03371553122997284,
                -0.04502250999212265,
                0.002514961175620556,
                0.007083256263285875,
                0.013622382655739784,
                -0.023789450526237488,
                -0.0774533748626709,
                0.011985385790467262,
                0.02035810984671116,
                -0.046913739293813705,
                -0.01083800196647644,
                0.03010789304971695,
                0.014685401692986488,
                -0.05835621803998947,
                0.016702160239219666,
                -0.056517183780670166,
                -0.017770973965525627,
                0.03417690470814705,
                0.023756738752126694,
                0.01677052117884159,
                0.003492367919534445,
                0.029518039897084236,
                -0.005665361415594816,
                0.022707311436533928,
                -0.017063897103071213,
                -0.01438129972666502,
                -0.0016815762501209974,
                -0.031418848782777786,
                -0.053730275481939316,
                -0.038994889706373215,
                0.04710622876882553,
                -0.06106855347752571,
                -0.017842313274741173,
                -0.03197844699025154,
                -0.0013120821677148342,
                -0.02984658256173134,
                0.020498888567090034,
                -0.005926409270614386,
                0.0036111853551119566,
                2.249493178396733e-07,
                0.005361625459045172,
                0.01011997926980257,
                0.019084399566054344,
                0.024046411737799644,
                0.02571748197078705,
                0.000505114090628922,
                -0.012428964488208294,
                -0.010960415005683899,
                0.047621022909879684,
                -0.0083475885912776,
                -0.018891362473368645,
                -0.04048733413219452,
                0.04653315246105194,
                0.0304572694003582,
                0.021142717450857162,
                -0.011612920090556145,
                -0.011000108905136585,
                -0.00470913527533412,
                0.013893913477659225,
                0.05275775492191315,
                0.04726855829358101,
                0.013308074325323105,
                0.032872989773750305,
                0.023488448932766914,
                0.017319627106189728,
                -0.022864563390612602,
                -0.022134719416499138,
                -0.07562665641307831,
                0.04314815253019333,
                -0.0428822822868824,
                -0.05953846871852875,
                0.04764715954661369,
                0.023199092596769333,
                0.02105444297194481,
                -0.04756121709942818,
                -0.05310341715812683,
                0.02590014971792698,
                0.0292842835187912,
                0.00856856070458889,
                0.14246681332588196,
                0.015907086431980133,
                -0.06270064413547516,
                -0.00981671828776598,
                -0.0016706279711797833,
                0.05119428038597107,
                0.04297675937414169,
                -0.011175967752933502,
                0.033479224890470505,
                -0.06604523956775665,
                -0.06103486940264702,
                -0.01728174276649952,
                0.019558675587177277,
                -0.052946239709854126,
                -0.012741788290441036,
                -0.0018776844954118133,
                0.02421646937727928,
                0.022048240527510643,
                0.0688084065914154,
                -0.014745783992111683,
                0.08103535324335098,
                -0.02908582240343094,
                -0.03601876273751259,
                -0.017788153141736984,
                0.057363808155059814,
                0.024344552308321,
                0.08742132037878036,
                -0.02587686851620674,
                1.4323724680780934e-34,
                0.029671775177121162,
                -0.026989635080099106,
                0.005055766087025404,
                -0.004961940459907055,
                0.008712463080883026,
                -0.019802017137408257,
                0.005190177820622921,
                0.0500263087451458,
                0.0345689132809639,
                0.020728694275021553,
                0.005965755321085453
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\x8e\\x0b\\x80{\\xefni\\x1c?\\xc4on8\\xf1\\x1e\\xee'",
        "id__": "6cd8c864-0ca2-4105-a328-67854977bb32"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "",
            "rep": [
                -0.01250336691737175,
                0.061438772827386856,
                -0.006734526716172695,
                0.025237763300538063,
                0.014757898636162281,
                0.03317746892571449,
                -0.01709824614226818,
                -0.005254548974335194,
                -0.03517315536737442,
                0.02680721879005432,
                0.027776479721069336,
                0.0016695410013198853,
                -0.035905394703149796,
                0.08371193706989288,
                0.0020816551987081766,
                -0.0036474945954978466,
                -0.00808101985603571,
                0.007572959177196026,
                -0.04329586401581764,
                -0.026224762201309204,
                -0.021265223622322083,
                0.013262759894132614,
                -0.005541951861232519,
                -0.03117932379245758,
                0.005881241522729397,
                -0.0541393980383873,
                0.040238745510578156,
                -0.007921081967651844,
                0.017486119642853737,
                -0.041598841547966,
                0.018058065325021744,
                -0.00015737996727693826,
                -0.001450279843993485,
                -0.03499198332428932,
                3.953377017751336e-06,
                -0.0013549883151426911,
                0.006736484821885824,
                0.035783108323812485,
                -0.04817280173301697,
                0.04840705543756485,
                0.007813598960638046,
                0.03426700457930565,
                -0.017332350835204124,
                -0.03300396353006363,
                0.0231205802410841,
                0.0160819124430418,
                0.011691391468048096,
                -0.026407040655612946,
                -0.044467393308877945,
                0.028630157932639122,
                0.008412609808146954,
                -0.04301135614514351,
                -0.12538503110408783,
                -0.05047597363591194,
                0.05816846713423729,
                0.028868647292256355,
                -0.003383409231901169,
                -0.009581382386386395,
                0.016905196011066437,
                -0.00306876702234149,
                -0.007300361059606075,
                -0.0304871853441,
                -0.016616370528936386,
                0.04997849091887474,
                0.0009452405502088368,
                0.031931791454553604,
                0.03382967785000801,
                -0.03551234304904938,
                0.022719833999872208,
                -0.013534646481275558,
                -0.013018215075135231,
                -0.022502049803733826,
                -0.02794310636818409,
                0.12343361228704453,
                -0.03144539147615433,
                -0.015701577067375183,
                -0.033564116805791855,
                0.03753889352083206,
                0.029097864404320717,
                -0.0075263408944010735,
                -0.02213081531226635,
                -0.010136098600924015,
                0.037694983184337616,
                0.01747259683907032,
                -0.07048329710960388,
                0.041574276983737946,
                -0.03992348909378052,
                -1.9508279365254566e-05,
                -0.01863657496869564,
                0.0036532285157591105,
                0.004205846227705479,
                -0.06392322480678558,
                -0.02061384916305542,
                -0.009598635137081146,
                0.032927192747592926,
                0.0016774265095591545,
                -0.023945268243551254,
                -0.029483433812856674,
                0.019927263259887695,
                -0.006079182494431734,
                -0.05478913336992264,
                0.023929640650749207,
                -0.0070150429382920265,
                0.006951442454010248,
                -0.05057133734226227,
                0.006110988091677427,
                0.02058250829577446,
                -0.023523692041635513,
                0.02736492082476616,
                0.07505252212285995,
                -0.04204901307821274,
                0.03163759037852287,
                0.023439623415470123,
                0.021155521273612976,
                -0.003454080317169428,
                -0.015697283670306206,
                -0.01709374412894249,
                0.04316733777523041,
                0.022733589634299278,
                0.008051550947129726,
                0.09210117906332016,
                0.05982724204659462,
                -0.006791339255869389,
                -0.03160925954580307,
                0.027104921638965607,
                0.0061317854560911655,
                0.010315212421119213,
                -0.03920648992061615,
                -0.05529627576470375,
                0.0036144584883004427,
                -0.021970732137560844,
                0.023343071341514587,
                0.003332081949338317,
                -0.005414539482444525,
                0.0300702303647995,
                -0.011980688199400902,
                0.010207103565335274,
                -0.02246537245810032,
                0.039830293506383896,
                -0.0014929784229025245,
                -0.020150257274508476,
                0.027579449117183685,
                0.002707603620365262,
                -0.005354698281735182,
                -0.02411271072924137,
                0.010192600078880787,
                0.09005865454673767,
                -0.03506376966834068,
                0.0065099638886749744,
                0.003935432061553001,
                0.010230598039925098,
                0.05042262375354767,
                -0.01718307100236416,
                0.050957951694726944,
                0.042619846761226654,
                0.03845520690083504,
                -0.06702651083469391,
                -0.01923864334821701,
                0.002606004709377885,
                0.028123805299401283,
                0.053268007934093475,
                0.002303209388628602,
                -0.035049889236688614,
                -0.015614322386682034,
                -0.028400812298059464,
                -0.03784780204296112,
                0.03566346317529678,
                0.001885668491013348,
                -0.0038645542226731777,
                -0.06404998898506165,
                0.0010549839353188872,
                -0.00885031558573246,
                0.008581440895795822,
                0.012777621857821941,
                0.023975960910320282,
                0.02581288293004036,
                -0.10304789245128632,
                -0.02712412178516388,
                -0.005907086655497551,
                -0.0644407793879509,
                -0.03229215741157532,
                -0.06627113372087479,
                -0.008665882050991058,
                -0.006594209931790829,
                0.0484798364341259,
                0.01764615625143051,
                0.009447470307350159,
                0.015605838038027287,
                0.001336991903372109,
                0.0595303438603878,
                0.0556134432554245,
                -0.04280132055282593,
                0.030285101383924484,
                0.035216376185417175,
                -0.01916254125535488,
                -0.06644861400127411,
                0.005000254139304161,
                -0.03437502309679985,
                -0.01998600736260414,
                -0.019239002838730812,
                0.035958968102931976,
                -0.0642118975520134,
                -0.026285985484719276,
                -0.04305443912744522,
                -0.012780084274709225,
                0.0018684081733226776,
                0.021171214058995247,
                -0.05258452147245407,
                -0.023365097120404243,
                -0.015666605904698372,
                -0.06449753791093826,
                0.02580212615430355,
                0.022736266255378723,
                0.01733192428946495,
                -0.030923957005143166,
                -0.03914716839790344,
                0.043811142444610596,
                -0.013898184522986412,
                -0.049245189875364304,
                0.07740079611539841,
                0.026235133409500122,
                0.05054790899157524,
                0.011461357586085796,
                0.020236769691109657,
                0.06779534369707108,
                0.029156848788261414,
                -0.0023628000635653734,
                0.011973806656897068,
                0.04291291534900665,
                -0.030129238963127136,
                -0.02899443916976452,
                0.05344944819808006,
                0.05811570584774017,
                -0.04694709926843643,
                -0.05033904314041138,
                0.03681037202477455,
                0.06290840357542038,
                -0.010182899422943592,
                0.04336661845445633,
                -0.10038278996944427,
                -0.0408954918384552,
                0.05900667607784271,
                0.015469775535166264,
                0.018710315227508545,
                0.11131074279546738,
                -0.0007150724995881319,
                -0.004573279060423374,
                0.006786289159208536,
                -0.015809012576937675,
                0.009324673563241959,
                -0.027163337916135788,
                0.016815578565001488,
                -0.02251344732940197,
                0.029244842007756233,
                0.03637232631444931,
                0.07193945348262787,
                -0.012299098074436188,
                -0.009656124748289585,
                -0.0193575881421566,
                2.175197732867673e-05,
                0.012475758790969849,
                0.044392213225364685,
                -0.023234998807311058,
                -0.05611341819167137,
                0.024123501032590866,
                0.03691176697611809,
                -0.017150789499282837,
                -0.02631417289376259,
                -0.040450796484947205,
                -0.005599383730441332,
                0.051314495503902435,
                0.028135715052485466,
                0.034666381776332855,
                -0.010067619383335114,
                0.03729860112071037,
                -0.01594937965273857,
                -0.0234685055911541,
                -0.004213577136397362,
                -0.0482347272336483,
                -0.02087913453578949,
                -0.027857424691319466,
                0.08927794545888901,
                0.010641509667038918,
                -0.004202773794531822,
                0.027202021330595016,
                -0.005368836224079132,
                -0.019590962678194046,
                -0.02832445688545704,
                -0.055649660527706146,
                -0.02539868652820587,
                -0.02836921624839306,
                -0.026615502312779427,
                0.071470245718956,
                -0.051241930574178696,
                0.05324987322092056,
                0.003887547878548503,
                -0.03548124060034752,
                0.006939411628991365,
                0.022774415090680122,
                -0.009120232425630093,
                -0.051541924476623535,
                0.06765251606702805,
                -0.008341128006577492,
                -0.026966938748955727,
                -0.0160972960293293,
                -0.029533125460147858,
                -0.019868968054652214,
                0.00859929621219635,
                0.060439061373472214,
                -0.02311551570892334,
                0.00448680529370904,
                0.025432756170630455,
                0.008112108334898949,
                0.034015756100416183,
                -0.06135188415646553,
                -0.04630007594823837,
                -0.03193478286266327,
                -0.06767014414072037,
                0.00842998456209898,
                -0.006241044029593468,
                0.052949488162994385,
                -0.03604333475232124,
                -0.014186553657054901,
                0.00942138209939003,
                0.010135955177247524,
                0.08616578578948975,
                -0.057738397270441055,
                -0.02568899095058441,
                0.0010708898771554232,
                -0.014476461336016655,
                0.0036793805193156004,
                -0.0017947201849892735,
                -0.020894017070531845,
                -0.029539015144109726,
                0.019612127915024757,
                -0.03600529953837395,
                0.010122458450496197,
                0.007492783013731241,
                0.03685415908694267,
                0.016340676695108414,
                0.030536159873008728,
                -0.0466168075799942,
                -0.022464042529463768,
                -0.1031494066119194,
                -0.031066561117768288,
                -0.015180891379714012,
                -0.020436879247426987,
                0.001358519191853702,
                -0.063127301633358,
                0.011295993812382221,
                -0.036736778914928436,
                -0.030513843521475792,
                0.02684878744184971,
                0.008639818988740444,
                0.03397071734070778,
                0.005471780896186829,
                -0.0333276130259037,
                -0.01036415621638298,
                -0.04180051013827324,
                -0.040268488228321075,
                -0.054151054471731186,
                0.012869646772742271,
                0.06618642061948776,
                0.059644076973199844,
                -0.025526219978928566,
                0.0005774935707449913,
                0.029545694589614868,
                0.005512099247425795,
                -0.009801962412893772,
                -0.0659307986497879,
                -0.027822323143482208,
                0.020601699128746986,
                0.03394725173711777,
                0.07557912915945053,
                0.013692679814994335,
                0.011515580117702484,
                0.07788955420255661,
                0.011653480120003223,
                0.0665055513381958,
                -0.02443624846637249,
                -0.016621800139546394,
                -0.06951485574245453,
                -0.08817055821418762,
                -0.013690861873328686,
                0.011746413074433804,
                0.03695254027843475,
                0.015583361499011517,
                0.02966042421758175,
                -0.04149671643972397,
                0.021954426541924477,
                0.06375288218259811,
                0.016550829634070396,
                -0.016622504219412804,
                0.04531807452440262,
                0.016943901777267456,
                -0.05765059217810631,
                -0.010194423608481884,
                0.031080780550837517,
                0.005107048898935318,
                0.055147428065538406,
                0.028596973046660423,
                0.03635311499238014,
                -0.016057226806879044,
                0.012841739691793919,
                -0.01431287545710802,
                -0.037531837821006775,
                0.04440212994813919,
                0.0004841997579205781,
                0.029519900679588318,
                -0.06048693135380745,
                -0.053098440170288086,
                -0.02054908499121666,
                0.0295583363622427,
                0.015336153097450733,
                0.0033795111812651157,
                -0.01853654906153679,
                -0.056357868015766144,
                0.041306979954242706,
                0.03857751190662384,
                -0.04463161900639534,
                0.017224956303834915,
                -0.015480753034353256,
                0.09595205634832382,
                0.008647487498819828,
                -0.04171014949679375,
                -0.00011139697016915306,
                0.016000933945178986,
                0.05842599272727966,
                0.050819724798202515,
                -0.04454408958554268,
                -0.04044615477323532,
                0.039345063269138336,
                -0.031576961278915405,
                0.009894018992781639,
                -0.031009772792458534,
                -0.09126465022563934,
                0.03283938392996788,
                0.05852086469531059,
                -0.011089615523815155,
                -0.035230476409196854,
                -0.024147111922502518,
                -0.03197512403130531,
                0.03297773748636246,
                -0.03444661200046539,
                -0.028208263218402863,
                -0.007524717599153519,
                -0.018318410962820053,
                0.023612791672348976,
                0.025588609278202057,
                -0.051408395171165466,
                -0.0017879278166219592,
                -0.04118284955620766,
                0.016455762088298798,
                -0.07233195006847382,
                0.034944720566272736,
                -0.027807535603642464,
                0.04431791976094246,
                -0.02935546264052391,
                -0.005040796473622322,
                -0.04199744388461113,
                0.047499965876340866,
                -0.017872368916869164,
                0.04956725612282753,
                0.05600331723690033,
                0.018813494592905045,
                0.045953210443258286,
                0.04290954768657684,
                -0.05417734757065773,
                0.01149401068687439,
                -0.05444420874118805,
                -0.0327894352376461,
                -0.015426786616444588,
                -0.026156844571232796,
                0.021491097286343575,
                0.04103664308786392,
                -0.0008519602124579251,
                -0.05277550593018532,
                -0.04462529718875885,
                -0.061105795204639435,
                0.020359434187412262,
                0.03430336341261864,
                -0.01633555255830288,
                -0.016192030161619186,
                -0.034239284694194794,
                0.015339444391429424,
                0.027314649894833565,
                0.024318696931004524,
                -0.0031512542627751827,
                -0.004706866107881069,
                0.01429942436516285,
                -0.010600934736430645,
                -0.0014165134634822607,
                0.0033114859834313393,
                -0.026212375611066818,
                0.00033186323707923293,
                0.04606817662715912,
                -0.022524915635585785,
                -0.04993373155593872,
                0.0017128668259829283,
                -0.016752490773797035,
                0.01251006405800581,
                0.014586590230464935,
                0.07265426963567734,
                -0.0016377537976950407,
                0.01188112422823906,
                -0.057594817131757736,
                -0.027447110041975975,
                0.010108241811394691,
                -0.037495944648981094,
                0.014847256243228912,
                0.01594732515513897,
                -0.011699363589286804,
                0.023237043991684914,
                -0.08411238342523575,
                0.010783860459923744,
                0.05484925955533981,
                0.03383447602391243,
                -0.06735416501760483,
                0.024997036904096603,
                -0.006560595240443945,
                0.05912308022379875,
                0.00042185059282928705,
                0.0017148681217804551,
                0.057530973106622696,
                0.01949171908199787,
                0.06430315971374512,
                0.00849885679781437,
                -0.029345473274588585,
                -0.05449048802256584,
                0.0029640940483659506,
                -0.012674140743911266,
                -0.005498157348483801,
                0.08986900001764297,
                -0.005515096243470907,
                -0.0500158816576004,
                0.10746122896671295,
                -0.015796132385730743,
                -0.01628374494612217,
                -0.0069964975118637085,
                0.01894856058061123,
                -0.06770621985197067,
                -0.0190612580627203,
                0.00793536752462387,
                -0.009581093676388264,
                0.044688839465379715,
                -0.010958217084407806,
                -0.0020997945684939623,
                -0.05194688215851784,
                -0.027022937312722206,
                -0.04614638909697533,
                0.03908706083893776,
                0.059537459164857864,
                -0.027022406458854675,
                0.006158605217933655,
                -0.0768093541264534,
                -1.730158119350301e-32,
                0.03528092801570892,
                0.022059516981244087,
                -0.018010996282100677,
                -0.027064841240644455,
                -0.02653360366821289,
                -0.05503051355481148,
                0.04159459099173546,
                -0.02749549224972725,
                0.043656058609485626,
                -0.00019660104590002447,
                -0.034188613295555115,
                -0.061675556004047394,
                0.08859048783779144,
                0.030939098447561264,
                -0.0032329449895769358,
                -0.06554336100816727,
                0.03795536234974861,
                -0.005585714243352413,
                0.03275179862976074,
                -0.005317466799169779,
                -0.05043720453977585,
                -0.024948665872216225,
                -0.01248104777187109,
                -0.010867049917578697,
                -0.019292419776320457,
                0.017033228650689125,
                -0.05047468841075897,
                -0.058236826211214066,
                0.03986600413918495,
                -0.005010735709220171,
                -0.0396004356443882,
                0.032279692590236664,
                0.008718718774616718,
                0.04184601828455925,
                0.024127088487148285,
                0.0017391352448612452,
                0.043299317359924316,
                -0.011499935761094093,
                0.0025340374559164047,
                -0.028097141534090042,
                -0.08533523231744766,
                0.022075172513723373,
                0.015037428587675095,
                -0.09686551243066788,
                -0.005431173834949732,
                0.01971687562763691,
                0.02391023561358452,
                0.005778660532087088,
                0.03475715592503548,
                -0.0042331102304160595,
                -0.028772220015525818,
                0.015448767691850662,
                -0.01742612011730671,
                0.040681518614292145,
                -0.0020328962709754705,
                0.018227776512503624,
                0.004681279417127371,
                0.023332741111516953,
                0.03626178577542305,
                0.008099662140011787,
                0.018003972247242928,
                0.013520090840756893,
                0.05929096043109894,
                0.06149018183350563,
                -0.0018482997547835112,
                0.036634307354688644,
                -0.016218360513448715,
                0.055421456694602966,
                0.02500648982822895,
                -0.013119767419993877,
                -0.031167376786470413,
                0.0499003604054451,
                -0.002872346667572856,
                -0.02454444393515587,
                -0.01710682548582554,
                -0.05873878300189972,
                -0.024875743314623833,
                0.026413239538669586,
                0.06883824616670609,
                -0.017891239374876022,
                -0.036234691739082336,
                0.04582807049155235,
                0.04272537678480148,
                0.0017674289410933852,
                0.0018298695795238018,
                -0.06317424029111862,
                0.002034740522503853,
                -0.02380634844303131,
                -0.0019296574173495173,
                0.04175703227519989,
                -0.052683863788843155,
                0.04525110125541687,
                -0.060833532363176346,
                -0.02860703505575657,
                -0.022842764854431152,
                -0.07093805819749832,
                0.00788680836558342,
                -0.04167364910244942,
                0.002529716119170189,
                -0.01452034804970026,
                -0.0038260684814304113,
                -0.03441690281033516,
                0.0025128161069005728,
                0.043621644377708435,
                -0.014509053900837898,
                0.013221640139818192,
                0.011325109750032425,
                0.0009567454108037055,
                -0.015492920763790607,
                -0.003075995249673724,
                -0.02272666059434414,
                0.004478803835809231,
                0.03148900717496872,
                -0.023501772433519363,
                0.028348876163363457,
                0.012995311059057713,
                0.03040008619427681,
                -0.06870255619287491,
                0.0010267093311995268,
                0.0056553250178694725,
                0.09587330371141434,
                -0.0020150355994701385,
                0.0075897653587162495,
                0.039577189832925797,
                -0.03742733597755432,
                0.01763203553855419,
                0.0028627458959817886,
                -0.026773445308208466,
                0.011027363128960133,
                -0.07695167511701584,
                0.022407954558730125,
                -0.03295961022377014,
                4.5349463562160963e-07,
                -0.04426289722323418,
                -0.048142578452825546,
                0.018156467005610466,
                -0.0038795487489551306,
                0.028599794954061508,
                0.014348787255585194,
                -0.005038860719650984,
                0.037203967571258545,
                -0.0015346520813181996,
                -0.03904343396425247,
                0.0263198371976614,
                -0.06350988149642944,
                0.02455391176044941,
                -0.007087626960128546,
                -0.013716638088226318,
                0.03182315081357956,
                -0.02466454729437828,
                -0.032650403678417206,
                -0.0007668084581382573,
                -0.034429874271154404,
                0.09304381906986237,
                0.022653747349977493,
                0.03231470286846161,
                0.04472662881016731,
                -0.007484799716621637,
                -0.05654448643326759,
                0.014933639205992222,
                -0.03520038723945618,
                -0.023135541006922722,
                -0.010842367075383663,
                -0.07612742483615875,
                0.028194786980748177,
                -0.04507141187787056,
                -0.03395090252161026,
                -0.014637324027717113,
                0.020044617354869843,
                0.03170362859964371,
                0.00014150857168715447,
                0.01661676913499832,
                -0.02081580087542534,
                -0.07335412502288818,
                -0.06850661337375641,
                -0.024285418912768364,
                -0.018199952319264412,
                0.024370674043893814,
                -0.029325706884264946,
                0.0034502651542425156,
                0.004042342305183411,
                -0.02834298275411129,
                0.013160069473087788,
                -0.02183876931667328,
                0.00541174691170454,
                -0.005932594183832407,
                0.02486509643495083,
                -0.016246624290943146,
                -0.04698701947927475,
                0.009881804697215557,
                -0.004528544377535582,
                -0.01922021433711052,
                -0.016330735757946968,
                -0.023822886869311333,
                0.029560789465904236,
                -0.031946662813425064,
                0.02090991474688053,
                -0.003000350669026375,
                -0.044975150376558304,
                -0.017595702782273293,
                -1.2820022894070708e-34,
                0.05678420141339302,
                0.018224170431494713,
                -0.018970364704728127,
                0.004657310899347067,
                -0.03676501661539078,
                -0.022326216101646423,
                0.017543325200676918,
                -0.00906522199511528,
                -0.0019386187195777893,
                -0.05036444589495659,
                -0.019049443304538727
            ]
        },
        "Experience": {
            "name": "A results-orientated, competent & challenge-seeking chartered manager with wide-ranging experience & solid achievements. Proven track record & a versatile skill set with experience in business consultancy, project & programme management. Strong leadership skills providing strategic direction with a passion for organisation, collaboration & knowledge sharing. Expertise in improving team performance while securing customer loyalty and forging valuable relationships with internal and external stake",
            "rep": [
                0.026540067046880722,
                -0.03242345526814461,
                0.0023455359041690826,
                0.0012096230639144778,
                0.016825249418616295,
                0.009876569733023643,
                -0.07728082686662674,
                0.01253498438745737,
                -0.029066413640975952,
                0.014129137620329857,
                -0.002443676581606269,
                -0.02033977210521698,
                0.017455803230404854,
                0.043193064630031586,
                0.027297597378492355,
                0.0038017560727894306,
                0.007719425950199366,
                0.02472444623708725,
                0.004764545243233442,
                0.024340467527508736,
                -0.011509455740451813,
                0.029201986268162727,
                -0.01758451946079731,
                0.0005653141997754574,
                -0.05387487635016441,
                -0.02838839776813984,
                0.016866860911250114,
                0.03813418745994568,
                0.005618360359221697,
                0.007284545339643955,
                0.05016840621829033,
                -0.025420183315873146,
                -0.002311879536136985,
                -0.040503449738025665,
                1.5572254596918356e-06,
                0.0008899500244297087,
                -0.02664022333920002,
                -0.029486991465091705,
                0.005162172019481659,
                -0.06569179147481918,
                0.00997107569128275,
                0.02013997547328472,
                0.031779978424310684,
                0.032828520983457565,
                -0.013342037796974182,
                0.09932756423950195,
                0.004291853867471218,
                -0.033546797931194305,
                0.011840413324534893,
                0.06958790123462677,
                0.02058456838130951,
                -0.01513900700956583,
                -0.048525843769311905,
                -0.03282363712787628,
                -0.047112930566072464,
                -0.0780378133058548,
                0.01085264515131712,
                0.010106966830790043,
                0.03362743556499481,
                0.02475496381521225,
                -0.01592062972486019,
                -0.01609637588262558,
                -0.034275591373443604,
                -0.044423602521419525,
                0.030734030529856682,
                0.0260587427765131,
                0.032538291066884995,
                -0.03837209567427635,
                0.02300308085978031,
                -0.03878983110189438,
                0.06598123162984848,
                0.0004461327043827623,
                -0.0059507256373763084,
                0.01433135848492384,
                0.045059628784656525,
                -0.0047097778879106045,
                0.011446910910308361,
                0.010226622223854065,
                -0.04822298884391785,
                0.0250344630330801,
                -0.029189784079790115,
                0.0762525200843811,
                0.0014347131364047527,
                -0.032224588096141815,
                -0.0345340333878994,
                0.028095412999391556,
                -0.019135473296046257,
                0.008432725444436073,
                0.04715252295136452,
                0.009425836615264416,
                0.025795167312026024,
                0.02818276546895504,
                0.01178226713091135,
                0.026055360212922096,
                -0.04644139111042023,
                -0.014399891719222069,
                0.047780491411685944,
                0.030562331900000572,
                -0.009648998267948627,
                0.006447579711675644,
                -0.018429044634103775,
                -0.00562645448371768,
                0.017048552632331848,
                0.011388287879526615,
                0.0009666625410318375,
                0.02866736240684986,
                0.011538032442331314,
                0.024966483935713768,
                -0.07867325097322464,
                -0.05290878191590309,
                -0.005460415035486221,
                -0.027941027656197548,
                -0.021059179678559303,
                0.014758669771254063,
                0.008685346692800522,
                0.027492279186844826,
                -0.059635672718286514,
                0.011279665865004063,
                0.022301912307739258,
                0.023047398775815964,
                0.06506957113742828,
                0.00878447387367487,
                0.023454653099179268,
                0.046020541340112686,
                -0.051920708268880844,
                -0.0302464310079813,
                -0.006157776340842247,
                -0.03375214338302612,
                0.021531321108341217,
                0.01527400128543377,
                0.012927113100886345,
                -0.0022232853807508945,
                -0.018631545826792717,
                -0.02062762901186943,
                0.06762656569480896,
                0.013373284600675106,
                -0.0010730833746492863,
                -0.022416384890675545,
                -0.050229307264089584,
                0.038947444409132004,
                -0.02601582743227482,
                -0.011654159054160118,
                -0.007946211844682693,
                0.03433649241924286,
                -0.019115809351205826,
                -0.06113927438855171,
                -0.01748204603791237,
                0.042876485735177994,
                0.03193238750100136,
                0.010359703563153744,
                0.03197605535387993,
                -0.0553559772670269,
                0.007289858069270849,
                -0.003821754129603505,
                0.027921440079808235,
                0.01751992665231228,
                0.05132691189646721,
                0.0016326595796272159,
                -0.02558095008134842,
                -0.014366920106112957,
                0.025491314008831978,
                -0.011485044844448566,
                0.020940609276294708,
                0.034732844680547714,
                -0.07317045331001282,
                -0.020124701783061028,
                0.08751556277275085,
                0.050310391932725906,
                -0.046693846583366394,
                0.009459864348173141,
                0.01341127697378397,
                -0.033479854464530945,
                -0.010184488259255886,
                0.044347453862428665,
                0.01461925357580185,
                -0.037680864334106445,
                0.053711019456386566,
                -0.034325528889894485,
                0.060464099049568176,
                -0.03324058651924133,
                -0.00016049676924012601,
                -0.1186094656586647,
                0.004270696546882391,
                0.0030015064403414726,
                0.10120464861392975,
                0.05328003689646721,
                -0.019753694534301758,
                0.03864135965704918,
                -0.02135784737765789,
                -0.03290211036801338,
                0.05526100471615791,
                0.02414938621222973,
                -0.05969805270433426,
                0.03678184375166893,
                -0.003520082915201783,
                0.023195039480924606,
                -0.04609481245279312,
                -0.03410467132925987,
                -0.033558256924152374,
                -0.01314157247543335,
                0.00910826027393341,
                -0.003156908554956317,
                -0.03640715032815933,
                0.0025637035723775625,
                0.049435392022132874,
                -0.019526246935129166,
                -0.015831416472792625,
                -0.03137333318591118,
                -0.05257744714617729,
                0.05221036076545715,
                0.06302355974912643,
                -0.007506074849516153,
                0.030251557007431984,
                0.008394535630941391,
                -0.032464247196912766,
                0.033707521855831146,
                0.01710456795990467,
                -0.0014521453995257616,
                0.027832260355353355,
                0.005225830711424351,
                0.009001580998301506,
                -0.0014040175592526793,
                0.013578693382441998,
                -0.0028880545869469643,
                0.08130156248807907,
                -0.03836458548903465,
                0.017873462289571762,
                -0.011795495636761189,
                -0.002631204668432474,
                0.08711722493171692,
                0.007575304713100195,
                0.0210176520049572,
                0.015018031001091003,
                -0.026342663913965225,
                -0.007352118380367756,
                -0.013200639747083187,
                0.001679671579040587,
                0.07710491120815277,
                0.025382552295923233,
                -0.021539270877838135,
                -0.03887353464961052,
                0.051555339246988297,
                0.026740096509456635,
                -0.01062418520450592,
                -0.06488420069217682,
                0.019589511677622795,
                0.0036512380465865135,
                -0.011955863796174526,
                0.0011267188237980008,
                -0.0013686971506103873,
                -0.03438732773065567,
                0.053263213485479355,
                0.029360176995396614,
                0.02543107233941555,
                -0.035717885941267014,
                0.03739160671830177,
                -0.009462264366447926,
                0.008328673429787159,
                -0.06945838034152985,
                0.028603924438357353,
                -0.029050754383206367,
                -0.039185162633657455,
                -0.002590458607301116,
                0.012844840995967388,
                0.05438918620347977,
                -0.036757517606019974,
                0.003642733907327056,
                -0.023394430056214333,
                -0.0033847223967313766,
                0.00015405466547235847,
                0.0354800783097744,
                0.047044042497873306,
                0.039893895387649536,
                -0.01906023733317852,
                0.017446206882596016,
                0.010883985087275505,
                -0.006367354653775692,
                0.05383589491248131,
                -0.05562727525830269,
                0.040168050676584244,
                0.03361067175865173,
                -0.06642986088991165,
                -0.04291703552007675,
                0.020138727501034737,
                0.01211454626172781,
                -0.001193495700135827,
                0.0359843447804451,
                0.04259280115365982,
                -0.007556438911706209,
                0.014149973168969154,
                -0.030830608680844307,
                -0.01252877525985241,
                0.0005355947650969028,
                -0.033299967646598816,
                0.020258992910385132,
                0.012168081477284431,
                -0.03633910417556763,
                -0.010334521532058716,
                0.013676642440259457,
                0.02310679480433464,
                -0.038327205926179886,
                -0.04236570745706558,
                0.059695154428482056,
                -0.008597061038017273,
                -0.002166938502341509,
                -0.012522728182375431,
                -0.039410803467035294,
                0.023492081090807915,
                -0.0010219316463917494,
                -0.01561359316110611,
                -0.01604353077709675,
                0.001971930731087923,
                -0.008260649628937244,
                -0.03967181220650673,
                0.06503372639417648,
                -0.041527677327394485,
                -0.06427951157093048,
                -0.0020680956076830626,
                -0.012702161446213722,
                0.07142522931098938,
                -0.005013859365135431,
                0.03061399608850479,
                -0.0667581558227539,
                -0.05802195519208908,
                0.02617097645998001,
                -0.017834341153502464,
                -0.08115822821855545,
                -0.03150174394249916,
                0.008415437303483486,
                -0.10968830436468124,
                -0.021926162764430046,
                -0.004935421049594879,
                -0.0269906185567379,
                -0.048268139362335205,
                -0.055674489587545395,
                -0.03162221983075142,
                -0.02537616901099682,
                -0.08412893116474152,
                -0.006429189350455999,
                -0.03141748160123825,
                -0.014123511500656605,
                0.03832053020596504,
                -0.04448636621236801,
                -0.003342089708894491,
                0.012557749636471272,
                0.003584236605092883,
                0.08976544439792633,
                0.05815885588526726,
                -0.060005638748407364,
                0.00856090523302555,
                0.016964871436357498,
                0.02976720593869686,
                0.030257804319262505,
                0.011087294667959213,
                -0.030703101307153702,
                -0.025674870237708092,
                0.029712902382016182,
                0.06348251551389694,
                0.04275258257985115,
                -0.04305879399180412,
                -0.05415483936667442,
                0.01739601232111454,
                -0.031126542016863823,
                -0.02750226855278015,
                0.01814926229417324,
                -0.023482901975512505,
                -0.013262542895972729,
                -0.017473775893449783,
                0.012485573999583721,
                -0.005090626887977123,
                -0.030627483502030373,
                0.0016863488126546144,
                -0.04211578518152237,
                0.011236396618187428,
                0.000862253422383219,
                -0.021120715886354446,
                -0.016790639609098434,
                0.005941162817180157,
                -0.02609601616859436,
                0.030047886073589325,
                0.0657406821846962,
                -0.008974743075668812,
                0.07646378874778748,
                -0.001648294972255826,
                0.0322398915886879,
                -0.032209161669015884,
                0.033143363893032074,
                -0.06669248640537262,
                0.023718921467661858,
                0.028640959411859512,
                0.05340809375047684,
                0.010491393506526947,
                -0.09023391455411911,
                0.04247164726257324,
                -0.05925397574901581,
                0.0011133690131828189,
                -0.039211634546518326,
                0.00017984108126256615,
                -0.0035631260834634304,
                0.0067316810600459576,
                0.03506341576576233,
                -0.03536461293697357,
                -0.027901269495487213,
                0.028692074120044708,
                -0.03103896975517273,
                -0.06883268058300018,
                0.03132239729166031,
                -0.012760224752128124,
                0.014814484864473343,
                0.07410135120153427,
                -0.048778172582387924,
                -0.08912183344364166,
                -0.00900682620704174,
                0.043744008988142014,
                -0.02356821298599243,
                -0.1158483475446701,
                -0.01721491478383541,
                0.007163636852055788,
                0.004771140869706869,
                -0.04346403107047081,
                0.07938755303621292,
                0.017301101237535477,
                -0.00952352024614811,
                0.011726530268788338,
                0.048318084329366684,
                -0.0367400236427784,
                0.037585146725177765,
                -0.007576343137770891,
                0.01689997874200344,
                -0.025904154404997826,
                -0.04969681426882744,
                0.04703201353549957,
                0.021094348281621933,
                0.03197677806019783,
                0.059123694896698,
                -0.031466033309698105,
                0.04062938317656517,
                0.03710032254457474,
                0.05983736366033554,
                0.03612121194601059,
                0.011468453332781792,
                0.042433999478816986,
                0.037495192140340805,
                0.023673195391893387,
                -0.014444444328546524,
                -0.046165574342012405,
                -0.0447743684053421,
                0.07178621739149094,
                -0.034291163086891174,
                -0.036904461681842804,
                0.08971409499645233,
                -0.10621698945760727,
                0.008045518770813942,
                0.009699182584881783,
                -0.04192259907722473,
                -0.02978217415511608,
                -0.10316752642393112,
                -0.06424746662378311,
                0.011244482360780239,
                -0.04304138943552971,
                0.012485419400036335,
                0.02095641940832138,
                0.03633856773376465,
                0.012522866018116474,
                0.03991102799773216,
                0.009576275944709778,
                -0.06923515349626541,
                0.006340477149933577,
                0.04026707634329796,
                -0.05479424446821213,
                0.06201408430933952,
                -0.0447261780500412,
                -0.019652141258120537,
                -0.04608449712395668,
                0.002727074781432748,
                -0.030227797105908394,
                0.025772372260689735,
                0.06405160576105118,
                0.01863355003297329,
                0.04123813658952713,
                -0.016607290133833885,
                -0.030833464115858078,
                0.043639905750751495,
                -0.027915550395846367,
                0.006851067300885916,
                0.020988358184695244,
                -0.005431090947240591,
                -0.012475427240133286,
                0.01001184806227684,
                -0.05900505185127258,
                0.028280625119805336,
                0.0002710694679990411,
                -0.00607285974547267,
                -0.10643696784973145,
                -0.02482875995337963,
                0.02938138134777546,
                -0.005409958306699991,
                0.004437872674316168,
                -0.02128945291042328,
                -0.06369438022375107,
                -0.047603629529476166,
                -0.03995765000581741,
                0.008909707888960838,
                -0.036753781139850616,
                -0.0049669696018099785,
                -0.0030669935513287783,
                -0.015066966414451599,
                -0.0014021521201357245,
                -0.030890410766005516,
                0.02587312087416649,
                -0.04391288012266159,
                -0.027313102036714554,
                -0.03767434507608414,
                0.028934644535183907,
                0.06389379501342773,
                0.03793809562921524,
                0.017838094383478165,
                -0.06249053031206131,
                -0.00043868363718502223,
                -0.03519383817911148,
                0.05034879967570305,
                -0.030631739646196365,
                -0.03420199081301689,
                0.02164122462272644,
                -0.010372084565460682,
                0.022005977109074593,
                0.022830914705991745,
                0.04071899875998497,
                -0.027077767997980118,
                0.03036593832075596,
                -0.019665872678160667,
                -0.0481083020567894,
                -0.052910853177309036,
                0.017501821741461754,
                -0.040432512760162354,
                0.01795826479792595,
                0.03783780708909035,
                0.06875067949295044,
                0.04717369005084038,
                0.010135740041732788,
                -0.03614451363682747,
                0.002317880978807807,
                0.10963883996009827,
                0.03683704137802124,
                -0.03175835683941841,
                -0.021319543942809105,
                -0.04688306525349617,
                0.024269698187708855,
                0.02436268888413906,
                -0.038263387978076935,
                0.004515368957072496,
                -0.046784136444330215,
                0.015399018302559853,
                0.03444821015000343,
                -0.02071918360888958,
                -4.750049979449997e-33,
                -0.026098214089870453,
                0.00849215593189001,
                0.07268444448709488,
                0.03330373018980026,
                -0.021076541393995285,
                -0.03898085281252861,
                0.03908899426460266,
                -0.034579530358314514,
                -0.0542432926595211,
                -0.036899689584970474,
                -0.037313226610422134,
                0.020514342933893204,
                -0.007517294492572546,
                0.00020031856547575444,
                0.01770390011370182,
                0.029195565730333328,
                0.017631439492106438,
                -0.023313889279961586,
                0.009087592363357544,
                -0.042051762342453,
                -0.04783838614821434,
                0.02329598367214203,
                -0.030350888147950172,
                0.009860885329544544,
                0.007825658656656742,
                -0.05223947763442993,
                -0.010922511108219624,
                0.041779156774282455,
                0.03926382586359978,
                0.018801992759108543,
                0.047846317291259766,
                -0.03464919328689575,
                -0.0013697895919904113,
                0.03639549762010574,
                0.03690772131085396,
                -0.029449641704559326,
                -0.022009773179888725,
                0.020548060536384583,
                -0.0008290857076644897,
                0.06837225705385208,
                -0.00426107831299305,
                0.005000523757189512,
                -0.015187257900834084,
                -0.025476712733507156,
                0.016243141144514084,
                -0.04574098438024521,
                -0.0061828140169382095,
                -0.007235401310026646,
                0.06514693051576614,
                -0.013757775537669659,
                0.013550573028624058,
                -0.014627299271523952,
                -0.05784394592046738,
                0.06153711676597595,
                -0.033142946660518646,
                -0.020504165440797806,
                0.004717342089861631,
                -0.013790622353553772,
                0.03390901908278465,
                -0.012327506206929684,
                -0.03966793790459633,
                0.03153355419635773,
                0.04139557480812073,
                0.05965301766991615,
                -0.03984136879444122,
                0.025324001908302307,
                0.04788742959499359,
                0.037241142243146896,
                0.05259624496102333,
                0.04035567492246628,
                -0.014240516349673271,
                0.07061581313610077,
                -0.010751813650131226,
                0.04440867900848389,
                -0.08610539138317108,
                -0.00997612439095974,
                0.015199841000139713,
                0.008496872149407864,
                0.058200523257255554,
                -0.026379836723208427,
                -0.031592998653650284,
                -0.01999073475599289,
                0.005584253929555416,
                -0.01544039323925972,
                0.04138921573758125,
                -0.00551796006038785,
                -0.030513936653733253,
                0.04014643281698227,
                0.04633977264165878,
                -0.00882745161652565,
                -0.0100779440253973,
                0.043343253433704376,
                -0.05737755447626114,
                0.021319346502423286,
                0.09031463414430618,
                -0.044627878814935684,
                0.02606581151485443,
                -0.01697440817952156,
                -0.002074248855933547,
                -0.009429859928786755,
                0.0006257221102714539,
                -0.005582247395068407,
                -0.005640213843435049,
                -0.010231325402855873,
                -0.009336722083389759,
                0.039447441697120667,
                -0.05881301686167717,
                0.022537127137184143,
                -0.031196629628539085,
                -0.016325267031788826,
                0.054687537252902985,
                0.007355064153671265,
                0.0013231475604698062,
                -0.05467452481389046,
                -0.040505826473236084,
                -0.020149067044258118,
                0.009537839330732822,
                -0.03722425177693367,
                0.023869263008236885,
                -0.002424986567348242,
                0.04809357225894928,
                -0.017588766291737556,
                0.02347276173532009,
                -0.00664563337340951,
                0.01574493758380413,
                0.0013089128769934177,
                0.03417390212416649,
                0.0009950038511306047,
                0.024116868153214455,
                -0.010016914457082748,
                -0.005780769046396017,
                -0.02356628142297268,
                2.2635737195741967e-07,
                0.054495226591825485,
                0.03197328373789787,
                -0.02811010740697384,
                -0.020329879596829414,
                0.010254093445837498,
                -0.04720847308635712,
                -0.008515147492289543,
                -0.034792959690093994,
                -0.021393008530139923,
                -0.01714920625090599,
                0.048720140010118484,
                -0.02523067407310009,
                -0.0508769191801548,
                -0.0187932550907135,
                0.017261706292629242,
                -0.013378110714256763,
                -0.00886320136487484,
                -0.06389202177524567,
                -0.031379178166389465,
                -0.0437583364546299,
                0.014022648334503174,
                0.012180068530142307,
                0.01579395867884159,
                -0.01941925287246704,
                0.004747934639453888,
                -0.04906880110502243,
                -0.030459456145763397,
                -0.04946662485599518,
                0.044087931513786316,
                0.05668971687555313,
                0.008515714667737484,
                0.00036596241989172995,
                0.03823281452059746,
                0.021632684394717216,
                0.01921752467751503,
                0.049957405775785446,
                0.05444986745715141,
                0.0718461126089096,
                0.034191906452178955,
                0.037416353821754456,
                0.005975290201604366,
                0.031692370772361755,
                -0.002183513483032584,
                0.002310442039743066,
                0.012452038004994392,
                -0.014290088787674904,
                0.002896042773500085,
                -0.03415292873978615,
                -0.08866856247186661,
                0.020496251061558723,
                -0.03127036243677139,
                0.06254027038812637,
                -0.02571708895266056,
                -0.01897067204117775,
                0.018589694052934647,
                -0.02766607701778412,
                -0.012352592311799526,
                0.006574355997145176,
                0.061901431530714035,
                -0.018468167632818222,
                -0.015189500525593758,
                -0.01499189343303442,
                -0.022184398025274277,
                -0.01772463507950306,
                0.06324198096990585,
                -0.0016943960217759013,
                -0.030826587229967117,
                1.8019275733730957e-34,
                0.007248669862747192,
                -0.0066691129468381405,
                0.00521485973149538,
                -0.031104883179068565,
                0.05735492706298828,
                -0.048965271562337875,
                -0.03868720307946205,
                0.0025712898932397366,
                0.0020723401103168726,
                -0.033307455480098724,
                -0.027719521895051003
            ]
        },
        "Freelance": "Open to both",
        "Id": "71694161-7edb-4c8c-b963-3172dc477130",
        "Industry": {
            "name": "\tBioenergy",
            "rep": [
                0.07769370079040527,
                -0.005833006463944912,
                -0.01947455294430256,
                0.01913745515048504,
                -0.0045487890020012856,
                0.015439243987202644,
                -0.04848156496882439,
                0.05133933201432228,
                -0.03718943148851395,
                -0.020587919279932976,
                0.012728930450975895,
                0.027028651908040047,
                -0.0034611027222126722,
                0.031154338270425797,
                0.04978047311306,
                0.013662262819707394,
                0.08140690624713898,
                0.033506494015455246,
                -0.028240883722901344,
                -0.02238653600215912,
                -0.06964445114135742,
                -0.006685284432023764,
                0.0010355490958318114,
                0.04251566529273987,
                0.02488165907561779,
                0.040067318826913834,
                -0.01799609512090683,
                0.09161915630102158,
                0.026532072573900223,
                -0.01134824100881815,
                0.02127683535218239,
                -0.021407829597592354,
                -0.002684325445443392,
                0.00869807694107294,
                1.5375848079202115e-06,
                -0.06089668348431587,
                -0.04463314637541771,
                -0.021655479446053505,
                0.007336725015193224,
                -0.019927019253373146,
                0.04831172898411751,
                0.034228358417749405,
                -0.0020625467877835035,
                0.002090683439746499,
                0.05299313738942146,
                -0.05116841197013855,
                0.04753710329532623,
                -0.043239936232566833,
                -0.009483976289629936,
                -0.04148607328534126,
                -0.03434760123491287,
                -0.0033769700676202774,
                -0.016328485682606697,
                -0.025654282420873642,
                -0.04874476045370102,
                0.13100239634513855,
                -0.00514557771384716,
                -0.0376138836145401,
                -0.0032449616119265556,
                0.03564808517694473,
                -0.016830720007419586,
                0.06566894799470901,
                -0.03044041059911251,
                0.017063304781913757,
                0.07950837165117264,
                -0.01751537248492241,
                0.06326387077569962,
                -0.028281664475798607,
                0.041217781603336334,
                0.017141619697213173,
                0.014585047028958797,
                0.0051641506142914295,
                0.02582569234073162,
                0.044499967247247696,
                -0.049750424921512604,
                0.030383842065930367,
                0.058106061071157455,
                0.01475544273853302,
                -0.017628243193030357,
                0.0355837419629097,
                0.0015702785458415747,
                -0.029105110093951225,
                0.01595817692577839,
                -0.0025663282722234726,
                -0.009008453227579594,
                0.039504535496234894,
                -0.018601926043629646,
                -0.009344013407826424,
                0.04310806095600128,
                0.02630685456097126,
                0.05357867479324341,
                0.010644734837114811,
                0.008856864646077156,
                0.014530546963214874,
                0.07876280695199966,
                0.015942832455039024,
                -0.03868340700864792,
                0.09380640834569931,
                0.0008291350677609444,
                -0.015137210488319397,
                0.010731657035648823,
                -0.013618195429444313,
                0.04818231612443924,
                0.013922009617090225,
                -0.01977931708097458,
                -0.07702916115522385,
                -0.04054061695933342,
                -0.03187471628189087,
                -0.014399915933609009,
                -0.04717715457081795,
                0.012753316201269627,
                -0.013910778798162937,
                0.016896726563572884,
                0.006832195445895195,
                -0.0031564240343868732,
                -0.012732420116662979,
                -0.005643132142722607,
                -0.05255484953522682,
                -0.03998179733753204,
                -0.011227678507566452,
                0.01128085795789957,
                -0.0019573059398680925,
                0.03902939707040787,
                -5.530627822736278e-05,
                0.016656704246997833,
                0.05514170229434967,
                0.05911595746874809,
                0.027845323085784912,
                0.012282361276447773,
                -0.00695137120783329,
                -0.017577217891812325,
                -0.026996254920959473,
                -0.02245244011282921,
                0.0018740928499028087,
                -0.07004115730524063,
                0.05290386453270912,
                0.009772643446922302,
                -0.02403217926621437,
                -0.010829712264239788,
                -0.06015980243682861,
                0.0043336497619748116,
                -0.006039682310074568,
                -0.05556667968630791,
                0.022454552352428436,
                -0.004946937318891287,
                0.007883964106440544,
                0.039817970246076584,
                0.011738952249288559,
                0.030646145343780518,
                0.00043363607255741954,
                0.037956539541482925,
                0.0015394212678074837,
                -0.05766843259334564,
                0.014932656660676003,
                0.038172364234924316,
                0.01400343980640173,
                -0.04836254566907883,
                -0.03595392033457756,
                0.045366570353507996,
                0.0059347315691411495,
                -0.05416341871023178,
                0.016924792900681496,
                -0.03175705298781395,
                -0.016456445679068565,
                0.02911885641515255,
                -0.06023884937167168,
                -0.008503403514623642,
                -0.03783734142780304,
                -0.12460191547870636,
                -0.002340675098821521,
                0.03658510372042656,
                -0.06697295606136322,
                -0.04154273122549057,
                0.07161188125610352,
                0.007041345816105604,
                0.04506832733750343,
                0.040407050400972366,
                -0.02166597917675972,
                0.023374594748020172,
                -0.02258004993200302,
                0.011356103233993053,
                -0.07561272382736206,
                0.024082666262984276,
                -0.019536346197128296,
                -0.03744443878531456,
                -0.03163335099816322,
                0.050437428057193756,
                0.06156396493315697,
                0.03655551001429558,
                -0.034061044454574585,
                -0.004823030438274145,
                0.017098579555749893,
                -0.0005987699260003865,
                -0.005984135437756777,
                0.01771811582148075,
                0.04090520739555359,
                0.005830228328704834,
                0.04753405228257179,
                0.02659468911588192,
                -0.023265007883310318,
                0.019697412848472595,
                -0.0027878570836037397,
                -0.01602776348590851,
                -0.006665061693638563,
                -0.014461761340498924,
                0.017532004043459892,
                -0.016791386529803276,
                -0.0030751482117921114,
                -0.08955687284469604,
                -0.030050143599510193,
                -0.013572483323514462,
                -0.012628640979528427,
                -0.01763373240828514,
                0.043742477893829346,
                0.006689457688480616,
                -0.016887938603758812,
                -0.012522051110863686,
                -0.009399570524692535,
                -0.0898999571800232,
                0.0318705253303051,
                0.00029263622127473354,
                0.015905065461993217,
                0.019502010196447372,
                -0.009124604985117912,
                0.009242100641131401,
                0.0484689436852932,
                -0.05914345011115074,
                -0.012369616888463497,
                0.04590630158782005,
                0.006699858233332634,
                0.014293249696493149,
                0.0013552872696891427,
                0.06011122465133667,
                0.0074019418098032475,
                -0.0334511399269104,
                0.03297780454158783,
                0.053606048226356506,
                -0.001615356421098113,
                0.006846006028354168,
                0.023095199838280678,
                -0.03258242830634117,
                0.05139971897006035,
                0.061979036778211594,
                0.02085976116359234,
                0.037851978093385696,
                -2.213187144661788e-05,
                0.03687278553843498,
                0.005909954663366079,
                0.044575128704309464,
                0.021897345781326294,
                -0.0018799907993525267,
                0.010550325736403465,
                0.021379277110099792,
                0.047089386731386185,
                0.06397800147533417,
                -0.057579852640628815,
                0.00952207576483488,
                0.03376948833465576,
                -0.026998037472367287,
                0.023148512467741966,
                0.06551330536603928,
                -0.06952476501464844,
                -0.0489029735326767,
                0.02252940647304058,
                -0.004206588491797447,
                -0.03214092552661896,
                0.1049807220697403,
                -0.03526860475540161,
                -0.011357957497239113,
                -0.014195509254932404,
                0.010462685488164425,
                -0.00888054445385933,
                0.020822525024414062,
                0.02537834271788597,
                -0.002496645785868168,
                0.007408022414892912,
                0.04006671905517578,
                0.016252389177680016,
                -0.05577724799513817,
                -0.0023403975646942854,
                0.010694053955376148,
                0.026945561170578003,
                -0.01403158251196146,
                0.01039139460772276,
                -0.04473794996738434,
                0.09322294592857361,
                0.010355946607887745,
                -0.01117311604321003,
                -0.05787530168890953,
                0.009537608362734318,
                0.029031189158558846,
                -0.01880517601966858,
                -0.022024763748049736,
                -0.020509744063019753,
                -0.028751859441399574,
                0.003563511185348034,
                -0.015366989187896252,
                -0.024555208161473274,
                0.03575458750128746,
                -8.716567390365526e-05,
                -0.06576184928417206,
                0.05066289007663727,
                -0.047915246337652206,
                0.006820580922067165,
                0.01896158792078495,
                0.04890276491641998,
                0.008323414251208305,
                -0.057588960975408554,
                0.03772023320198059,
                -0.02950998581945896,
                0.021192850545048714,
                0.06090490520000458,
                0.039101749658584595,
                0.08194531500339508,
                0.012990353628993034,
                -0.0539543516933918,
                0.008180194534361362,
                -0.015444534830749035,
                0.03473031148314476,
                0.03909827396273613,
                0.006235014647245407,
                -0.018847433850169182,
                0.03096625953912735,
                0.006653914228081703,
                0.006519581191241741,
                -0.015995508059859276,
                -0.04780945926904678,
                0.06551871448755264,
                0.002322838641703129,
                -0.009697690606117249,
                0.013824456371366978,
                0.009402007795870304,
                -0.004721926990896463,
                0.007086333818733692,
                -0.030218807980418205,
                -0.018610075116157532,
                0.03329897299408913,
                -0.057255085557699203,
                -0.010545577853918076,
                0.014981837943196297,
                0.008987796492874622,
                -0.028315510600805283,
                -0.0556100457906723,
                -0.00898350216448307,
                0.0014228041982278228,
                0.020228853449225426,
                -0.00038289622170850635,
                -0.008412213064730167,
                -0.048079073429107666,
                -0.024836454540491104,
                0.08007044345140457,
                0.0030154462438076735,
                -0.020198479294776917,
                -0.03640724718570709,
                -0.003784773638471961,
                0.09388966113328934,
                -0.048181720077991486,
                -0.037024401128292084,
                -0.0024826000444591045,
                0.054888732731342316,
                -0.013866299763321877,
                0.017104703933000565,
                -0.06292162835597992,
                -0.03887171670794487,
                0.01196576002985239,
                0.054114095866680145,
                0.0074407923966646194,
                0.035422664135694504,
                0.028539272025227547,
                -0.04632601514458656,
                0.046682488173246384,
                0.01371600478887558,
                -0.05517718568444252,
                0.02196544036269188,
                -0.030467767268419266,
                0.04690352454781532,
                0.012439625337719917,
                0.016115494072437286,
                0.0009424760937690735,
                -0.019540198147296906,
                0.03648639842867851,
                -0.014956001192331314,
                -0.0365801677107811,
                0.017721425741910934,
                -0.0044959532096982,
                0.029935425147414207,
                0.021467993035912514,
                -0.03129544109106064,
                0.019467812031507492,
                0.0006497706635855138,
                -0.021132202818989754,
                0.027404818683862686,
                -0.06282279640436172,
                -0.0013861183542758226,
                0.017331337556242943,
                -0.03439303860068321,
                -0.03794718161225319,
                0.021274756640195847,
                -0.03805985301733017,
                0.0180681012570858,
                -0.004678746685385704,
                0.06111002713441849,
                0.012248153798282146,
                0.004953976254910231,
                -0.050508759915828705,
                -0.007437019143253565,
                0.10018204152584076,
                -0.023089533671736717,
                0.05350589379668236,
                -0.004263975191861391,
                0.055832937359809875,
                -0.050054002553224564,
                0.01928599923849106,
                -0.09453115612268448,
                -0.050704892724752426,
                0.0011249794624745846,
                -0.008744113147258759,
                -0.023815443739295006,
                -0.02101295441389084,
                -0.01132354699075222,
                -0.006181152071803808,
                0.048147138208150864,
                0.01996776834130287,
                -0.01747455634176731,
                0.011586232110857964,
                0.022934088483452797,
                0.006087134126573801,
                0.005405483301728964,
                0.022843658924102783,
                0.0096298111602664,
                -0.019791871309280396,
                -0.10727641731500626,
                -0.06321223080158234,
                0.01206936500966549,
                -0.05301312729716301,
                -0.018569137901067734,
                -0.03437361121177673,
                -0.019715895876288414,
                -0.022831348702311516,
                -0.013348699547350407,
                0.011511293239891529,
                -0.02553260140120983,
                -0.006747421342879534,
                -0.05695614218711853,
                -0.006551980506628752,
                0.11403977125883102,
                -0.011844796128571033,
                -0.018202178180217743,
                -0.048575613647699356,
                -0.0033093474339693785,
                -0.009251273237168789,
                -0.058267559856176376,
                -0.08918949216604233,
                0.051428619772195816,
                0.035449475049972534,
                0.026792801916599274,
                0.008311818353831768,
                0.011006140150129795,
                -0.009304545819759369,
                -0.025664810091257095,
                0.04331878200173378,
                0.021486669778823853,
                -0.052800290286540985,
                0.036620549857616425,
                0.06344719976186752,
                0.03260781615972519,
                0.0785449892282486,
                -0.06519021838903427,
                0.009291796945035458,
                0.0317382775247097,
                0.06067441403865814,
                -0.02267174981534481,
                0.019909238442778587,
                0.03209073096513748,
                -0.008203578181564808,
                -0.04983457550406456,
                0.015729399397969246,
                -0.05660279467701912,
                0.00982267688959837,
                0.004732244648039341,
                0.02710556797683239,
                -0.0019691381603479385,
                -0.03461930900812149,
                -0.013243140652775764,
                0.008360711857676506,
                -0.02463115192949772,
                0.008834119886159897,
                0.0062013347633183,
                -0.060583170503377914,
                0.001557788928039372,
                0.009264425374567509,
                -0.01744890958070755,
                0.024082748219370842,
                0.05046088621020317,
                0.002304146299138665,
                -0.03281452879309654,
                -0.0584053136408329,
                0.031578581780195236,
                0.01385329756885767,
                0.02551659755408764,
                -0.045895759016275406,
                0.003613230772316456,
                -0.005691687110811472,
                -0.05542095750570297,
                -0.023278292268514633,
                -0.021594593301415443,
                -0.010941535234451294,
                -0.004511886741966009,
                0.0017512498889118433,
                -0.019126253202557564,
                0.019824013113975525,
                0.04382014274597168,
                -0.012571061961352825,
                0.02608003094792366,
                0.04507562890648842,
                0.015816397964954376,
                -0.020512280985713005,
                0.014496739953756332,
                0.023477958515286446,
                0.01921743154525757,
                -0.017986971884965897,
                0.020543411374092102,
                0.016032934188842773,
                -0.08244277536869049,
                0.03508901596069336,
                -0.013675076887011528,
                0.04749632254242897,
                0.010756395757198334,
                -0.03167496249079704,
                0.06267333030700684,
                0.04212302342057228,
                0.02850954234600067,
                -0.018061455339193344,
                0.008534114807844162,
                -0.04977341741323471,
                0.0028718828689306974,
                0.0007572273607365787,
                0.019584106281399727,
                -0.0008962757419794798,
                0.03977850824594498,
                -0.006383167114108801,
                0.00454685278236866,
                0.057262957096099854,
                0.02342314086854458,
                -0.0018235960742458701,
                -0.0003558093449100852,
                -0.00466950936242938,
                -0.04850911349058151,
                -0.0007797002326697111,
                -0.09281454980373383,
                0.02172764018177986,
                0.05633847415447235,
                -0.05390535667538643,
                -0.04697959125041962,
                -0.012667600065469742,
                -6.420902792496581e-33,
                -0.012131985276937485,
                -0.017671091482043266,
                -0.009745354764163494,
                0.04155667498707771,
                -0.09108739346265793,
                -0.030104652047157288,
                -0.0017417395720258355,
                -0.021627113223075867,
                -0.03824084252119064,
                0.015900075435638428,
                0.023683443665504456,
                -0.03751282021403313,
                0.0065560974180698395,
                0.007411599159240723,
                0.013182380236685276,
                0.011159227229654789,
                -0.040675949305295944,
                0.0002860048261936754,
                0.019105257466435432,
                -0.026228828355669975,
                0.03472357615828514,
                0.007919690571725368,
                -0.01500053983181715,
                0.027503879740834236,
                0.014116683043539524,
                -0.0024301561061292887,
                -0.08145906776189804,
                0.011092397384345531,
                -0.061564601957798004,
                -0.038666825741529465,
                0.010988161899149418,
                0.002816195832565427,
                -0.0011817787308245897,
                -0.03484005108475685,
                -0.01473347656428814,
                0.0034920922480523586,
                -0.0003528632514644414,
                -0.019579291343688965,
                -0.046635594218969345,
                -0.011906168423593044,
                -0.1035294383764267,
                0.010019169189035892,
                0.04169116169214249,
                -0.013178803026676178,
                -0.04840471222996712,
                -0.02076629363000393,
                -0.008423126302659512,
                -0.06023407727479935,
                0.019939523190259933,
                -0.01282249391078949,
                0.004356296733021736,
                -0.03120281733572483,
                0.004363956395536661,
                0.025432510301470757,
                -0.014406229369342327,
                0.07795801013708115,
                -0.033694297075271606,
                0.05381428450345993,
                -0.02899416908621788,
                0.005351916886866093,
                0.05075303092598915,
                0.04992566257715225,
                0.007526209577918053,
                0.009534782730042934,
                -0.018722692504525185,
                0.02302587404847145,
                -0.029871823266148567,
                -0.03228195011615753,
                -0.033368825912475586,
                0.06091485917568207,
                -0.01526084914803505,
                0.040650565177202225,
                0.02370731718838215,
                -0.032258130609989166,
                -0.024791037663817406,
                -0.03734389692544937,
                -0.030927574262022972,
                0.03167693689465523,
                0.028962375596165657,
                0.004544598050415516,
                0.011908133514225483,
                -0.052895769476890564,
                0.09625892341136932,
                -0.019703492522239685,
                -0.008725300431251526,
                0.03086993470788002,
                -0.035729505121707916,
                0.06961160153150558,
                -0.00495481351390481,
                -0.016529114916920662,
                0.008206481114029884,
                0.0169337410479784,
                0.011388266459107399,
                -0.00430207559838891,
                0.023754162713885307,
                0.09624017030000687,
                -0.009780946187675,
                -0.048601869493722916,
                -6.387287430698052e-05,
                0.019817287102341652,
                0.025031518191099167,
                0.004822661634534597,
                -0.03366808593273163,
                0.07602503895759583,
                -0.007873127236962318,
                -0.0445832833647728,
                -0.020430931821465492,
                -0.03645617887377739,
                -0.002720061456784606,
                0.024212639778852463,
                -0.014820178039371967,
                -0.002126721665263176,
                0.01571919396519661,
                -0.03001222014427185,
                0.014060198329389095,
                -0.017557885497808456,
                0.008331090211868286,
                0.005033435765653849,
                -0.049245674163103104,
                0.06726126372814178,
                0.03177809342741966,
                0.04008544981479645,
                -0.027664141729474068,
                0.05288619548082352,
                -0.030269140377640724,
                0.007721554022282362,
                -0.014915388077497482,
                0.0468422994017601,
                -0.10608953982591629,
                0.0062406244687736034,
                0.011674268171191216,
                0.029114479199051857,
                2.1519096549127426e-07,
                -0.04702235758304596,
                -0.012728576548397541,
                -0.03053017519414425,
                -0.061577245593070984,
                -0.03023367002606392,
                0.024434782564640045,
                0.024789879098534584,
                0.029730459675192833,
                -0.04622102528810501,
                -0.028831299394369125,
                -0.023384355008602142,
                -0.03911999613046646,
                -0.003811078378930688,
                -0.02921210415661335,
                -0.002861558925360441,
                -0.036314867436885834,
                0.0001032764557749033,
                0.00041878342744894326,
                0.03903009742498398,
                -0.027808062732219696,
                0.0036603393964469433,
                -0.019828153774142265,
                -0.07310666888952255,
                -0.022767672315239906,
                -0.03108791820704937,
                0.036508798599243164,
                0.027127310633659363,
                -0.01977595128118992,
                0.05279792845249176,
                0.006552015896886587,
                0.022842245176434517,
                0.014237419702112675,
                0.03064526803791523,
                -0.07125736773014069,
                -0.017559127882122993,
                -0.03461310639977455,
                0.008013002574443817,
                0.030612988397479057,
                -0.03832947462797165,
                0.028534051030874252,
                0.005695690866559744,
                0.047107845544815063,
                0.002615922363474965,
                0.04485827684402466,
                -0.07261447608470917,
                0.039848022162914276,
                0.022247688844799995,
                -0.008642110042273998,
                -0.04482226446270943,
                -0.0029399569611996412,
                -0.03766849637031555,
                -0.005484469700604677,
                -0.007469597272574902,
                -0.0013865387300029397,
                -0.023116527125239372,
                -0.020296502858400345,
                -0.013992149382829666,
                0.01953285001218319,
                0.02892795391380787,
                0.03192707523703575,
                -0.023083489388227463,
                0.06276760250329971,
                -0.03892151638865471,
                0.08101075142621994,
                0.04617752134799957,
                -0.008770817890763283,
                0.01197048556059599,
                9.147847418724e-35,
                0.016436807811260223,
                -0.023830043151974678,
                -0.10275816917419434,
                -0.012594128958880901,
                -0.024151314049959183,
                0.006061450112611055,
                -0.049334924668073654,
                0.021394625306129456,
                -0.051190491765737534,
                -0.06057390198111534,
                -0.004399037454277277
            ]
        },
        "Location": "Dundee",
        "Remuneration": 32.45192307692308,
        "Services Required": {
            "name": "Head of Projects and Business Change - Abertay University",
            "rep": [
                0.039978690445423126,
                0.07228992879390717,
                -0.022307027131319046,
                0.039612170308828354,
                0.013607678934931755,
                0.02933214232325554,
                -0.009007598273456097,
                -0.05112949386239052,
                0.0020977165549993515,
                0.024982647970318794,
                0.030971020460128784,
                0.009113235399127007,
                0.008456741459667683,
                0.030291847884655,
                0.03166630119085312,
                -0.0125147495418787,
                0.028001833707094193,
                0.030609212815761566,
                -0.0499938502907753,
                -0.015849992632865906,
                -0.01659274287521839,
                0.030221002176404,
                -0.03433587774634361,
                0.029736798256635666,
                -0.030899561941623688,
                0.013201255351305008,
                -0.023785369470715523,
                0.03698234632611275,
                0.02572617679834366,
                0.017426665872335434,
                0.044740982353687286,
                0.017176572233438492,
                -0.06108416989445686,
                -0.04483148083090782,
                1.6536271232325817e-06,
                0.011970525607466698,
                -0.004307684954255819,
                -0.04309326410293579,
                -0.03035232424736023,
                0.03167323023080826,
                -0.005914205685257912,
                0.06848952919244766,
                -0.0367254801094532,
                0.019069936126470566,
                -0.023737074807286263,
                0.07828696072101593,
                0.0015583729837089777,
                0.020669372752308846,
                -0.03512759879231453,
                0.03538506105542183,
                0.005606635473668575,
                -0.04217933490872383,
                -0.01371108926832676,
                -0.05355451628565788,
                -0.026911240071058273,
                -0.047663260251283646,
                0.015451767481863499,
                -0.055597323924303055,
                -0.039632800966501236,
                0.04216436669230461,
                -0.010006953030824661,
                -0.013514062389731407,
                -0.04389730840921402,
                -0.031416162848472595,
                0.014265618287026882,
                0.025207139551639557,
                0.03002270683646202,
                -0.019438084214925766,
                0.047564443200826645,
                0.028408270329236984,
                0.09528552740812302,
                0.036083951592445374,
                0.025904925540089607,
                0.03559138998389244,
                0.056588586419820786,
                -0.005436896812170744,
                0.007174442522227764,
                -0.019992560148239136,
                -0.030690765008330345,
                0.01653522253036499,
                -0.016760412603616714,
                0.04185938090085983,
                0.0062957764603197575,
                0.04894062504172325,
                0.008455455303192139,
                -0.009885990992188454,
                -0.032528720796108246,
                0.005888459272682667,
                0.025684818625450134,
                0.02034563012421131,
                0.041017964482307434,
                0.017172755673527718,
                -0.0233791284263134,
                0.04317210242152214,
                -0.010638775303959846,
                -0.022814525291323662,
                0.03655049949884415,
                -0.004896515514701605,
                -0.006707426160573959,
                -0.026247650384902954,
                -0.036109548062086105,
                0.051888152956962585,
                -0.01920469105243683,
                0.037935689091682434,
                -0.008397065103054047,
                0.11322300881147385,
                -0.04170311614871025,
                -0.027331532910466194,
                -0.012227260507643223,
                0.06716939806938171,
                -0.03746356442570686,
                0.028064647689461708,
                -0.007244445383548737,
                -0.03190796822309494,
                -0.006340186111629009,
                0.029979785904288292,
                -0.05656935274600983,
                0.014684649184346199,
                0.00868938211351633,
                0.08825238794088364,
                0.021752754226326942,
                0.04878690093755722,
                -0.004215436987578869,
                0.023681167513132095,
                -0.028794603422284126,
                0.023107975721359253,
                0.00708312401548028,
                0.008509306237101555,
                -0.012611603364348412,
                0.052392058074474335,
                -0.010082472115755081,
                -0.02802053466439247,
                -0.005682489834725857,
                0.011428484693169594,
                0.03854383900761604,
                0.00955159030854702,
                0.0009365031728520989,
                0.012462510727345943,
                -0.030359743162989616,
                0.045103929936885834,
                -0.04175985977053642,
                0.010257594287395477,
                -0.04462536424398422,
                0.022897429764270782,
                -0.05676025524735451,
                -0.0288108941167593,
                -0.01980685070157051,
                -0.012847418896853924,
                0.020432036370038986,
                0.011275788769125938,
                0.0020285164937376976,
                -0.059467561542987823,
                0.015900617465376854,
                0.014639352448284626,
                0.06723242253065109,
                0.0488412119448185,
                0.021011842414736748,
                -0.02201276458799839,
                -0.04196802154183388,
                0.0502406544983387,
                -0.0010392925469204783,
                0.003590873209759593,
                -0.006495373789221048,
                0.057616766542196274,
                -0.02536631189286709,
                0.023146020248532295,
                0.09966898709535599,
                -0.01943635381758213,
                -0.018158143386244774,
                -0.0117839640006423,
                -0.06105339899659157,
                -0.008482969366014004,
                -0.01776685193181038,
                0.099330835044384,
                -0.0165947824716568,
                -0.09450153261423111,
                0.004172586370259523,
                -0.04104030877351761,
                -0.032529450953006744,
                -0.0024587379302829504,
                0.01552379410713911,
                -0.12655547261238098,
                0.011120257899165154,
                -0.011007013730704784,
                0.0054821958765387535,
                0.028222663328051567,
                -0.02938658744096756,
                0.026607969775795937,
                0.0029180683195590973,
                -0.0010604460258036852,
                0.04129016399383545,
                0.0175347737967968,
                -0.10055209696292877,
                0.03794759511947632,
                -0.007238269783556461,
                0.03485345095396042,
                -0.04036947712302208,
                -0.035949237644672394,
                -0.02454172447323799,
                -0.041861701756715775,
                0.004176637157797813,
                0.020697446539998055,
                -0.006919801235198975,
                0.02165933884680271,
                -0.003172581549733877,
                -0.05013377591967583,
                0.013612408190965652,
                -0.032955970615148544,
                -0.05200355872511864,
                0.02508816123008728,
                0.04918525367975235,
                0.00820184126496315,
                0.012240881100296974,
                0.05979759618639946,
                -0.0020494514610618353,
                -0.030473997816443443,
                -0.014745752327144146,
                0.005768339615315199,
                -0.026239551603794098,
                0.0752766951918602,
                -0.018116438761353493,
                0.026730453595519066,
                0.011159267276525497,
                0.014015666209161282,
                -0.015405387617647648,
                -0.016166260465979576,
                0.017424248158931732,
                -0.0018575997091829777,
                -0.021297411993145943,
                0.03436639904975891,
                0.0033523975871503353,
                0.004874896723777056,
                -0.006105625070631504,
                -0.019222235307097435,
                0.009920570068061352,
                -0.028066953644156456,
                0.031075619161128998,
                0.033211853355169296,
                0.0168220903724432,
                -0.004999316763132811,
                -0.03326994180679321,
                -0.003938016947358847,
                -0.011337824165821075,
                -3.491223105811514e-05,
                0.06087206304073334,
                0.021962638944387436,
                0.012052051723003387,
                -0.01057469192892313,
                0.038025856018066406,
                -0.0031467240769416094,
                0.005374354775995016,
                -0.047726456075906754,
                0.017167886719107628,
                0.010947874747216702,
                0.010669665411114693,
                0.0681532695889473,
                -0.043676283210515976,
                0.030626438558101654,
                -0.052547235041856766,
                0.012200780212879181,
                0.040014155209064484,
                -0.01825844869017601,
                -0.04022860527038574,
                0.012157151475548744,
                0.009314954280853271,
                -0.04580331966280937,
                0.01145940087735653,
                -0.024277901276946068,
                -0.05452253296971321,
                0.00035895913606509566,
                -0.015281908214092255,
                0.004750936757773161,
                0.026097305119037628,
                -0.026603825390338898,
                0.007196603808552027,
                0.008213627152144909,
                0.0024381624534726143,
                0.0533720962703228,
                -0.02826448529958725,
                0.013012434355914593,
                0.03379746526479721,
                -0.009793092496693134,
                -0.067232146859169,
                0.035115744918584824,
                0.018893295899033546,
                0.0004234304651618004,
                0.07092663645744324,
                -0.07729398459196091,
                -0.055054958909749985,
                0.02559037320315838,
                0.006862418260425329,
                -0.0664115697145462,
                0.031976498663425446,
                -0.004931487143039703,
                -0.03256099671125412,
                0.01869952119886875,
                0.055892348289489746,
                -0.008511526510119438,
                -0.0022127197589725256,
                0.06843665987253189,
                -0.01730019971728325,
                -0.06173398718237877,
                0.06256704032421112,
                -0.029379937797784805,
                -0.004957313649356365,
                0.03349068760871887,
                -0.01706071011722088,
                0.043190471827983856,
                -0.004529681988060474,
                -0.11108554899692535,
                0.021273508667945862,
                0.027756016701459885,
                0.0412789024412632,
                0.010313660837709904,
                0.009096557274460793,
                -0.014300411567091942,
                -0.02410106547176838,
                -0.0355355329811573,
                0.03596273437142372,
                0.09438358247280121,
                -0.011363822035491467,
                0.03342737257480621,
                -0.07130394876003265,
                -0.021067261695861816,
                0.01502644456923008,
                0.03103027679026127,
                -0.015564880333840847,
                -0.040688060224056244,
                0.04482591524720192,
                -0.058525584638118744,
                -0.0035936511121690273,
                -0.005716023035347462,
                -0.03175240382552147,
                -0.036747951060533524,
                0.026623906567692757,
                -0.016499826684594154,
                -0.012833097018301487,
                -0.0647604689002037,
                -0.03435973450541496,
                0.010222295299172401,
                -0.08118525892496109,
                -0.0468737855553627,
                -0.0560169480741024,
                0.009460235014557838,
                -0.00795884057879448,
                0.00776622025296092,
                0.01929682306945324,
                0.042081139981746674,
                -0.06734205782413483,
                -0.011028936132788658,
                0.033080197870731354,
                0.04125938192009926,
                -0.03674522414803505,
                -0.0475347675383091,
                -0.0039838082157075405,
                -0.04859567806124687,
                -0.03567204624414444,
                0.04598531499505043,
                0.010551096871495247,
                0.014096353203058243,
                -0.04298844560980797,
                -0.04046772047877312,
                -0.007039295509457588,
                -0.038315556943416595,
                -0.022804811596870422,
                -0.01480954885482788,
                0.014737297780811787,
                0.014104893431067467,
                0.00031393871176987886,
                8.199903822969645e-05,
                -0.020152507349848747,
                0.03174757584929466,
                -0.05776938050985336,
                -0.0011340194614604115,
                -0.020738884806632996,
                -0.00499632116407156,
                0.02214730717241764,
                -0.02429860457777977,
                -0.03981809690594673,
                0.02020219899713993,
                0.04441216215491295,
                -0.026427194476127625,
                0.02886255457997322,
                -0.02162240818142891,
                0.008185993880033493,
                -0.019343407824635506,
                -0.0051626767963171005,
                -0.03683728724718094,
                0.04923326522111893,
                0.0227885190397501,
                0.0013738629641011357,
                0.01857229880988598,
                -0.012267918325960636,
                0.011071008630096912,
                -0.02156177908182144,
                -0.0463423915207386,
                -0.036613330245018005,
                0.016751153394579887,
                -0.007597181014716625,
                0.008357033133506775,
                0.05496523901820183,
                0.011235693469643593,
                0.027644377201795578,
                -0.025651462376117706,
                0.04449482634663582,
                -0.06434144824743271,
                0.13757789134979248,
                -0.006711408495903015,
                0.0027763566467911005,
                0.06145208328962326,
                -0.08219661563634872,
                0.0038359768223017454,
                0.010478661395609379,
                0.022319091483950615,
                0.001993066631257534,
                -0.026942476630210876,
                -0.03560824692249298,
                0.055453140288591385,
                0.002502291928976774,
                -0.08927386999130249,
                0.03434906154870987,
                0.04817142337560654,
                0.0008488339371979237,
                -0.004837939515709877,
                0.08370686322450638,
                -0.01967022940516472,
                0.03189513087272644,
                0.0272300373762846,
                -0.020544905215501785,
                -0.04971037060022354,
                -0.010687477886676788,
                0.008721714839339256,
                0.004950863774865866,
                0.06747138500213623,
                0.06891567260026932,
                -0.029633743688464165,
                0.007411292288452387,
                -0.020038187503814697,
                0.026887308806180954,
                0.008488544262945652,
                0.008852635510265827,
                0.07497996836900711,
                -0.008249073289334774,
                0.0043907128274440765,
                -0.020395193248987198,
                0.007115354761481285,
                -0.016294658184051514,
                0.030031483620405197,
                -0.09031606465578079,
                -0.020224764943122864,
                0.10576370358467102,
                -0.039429131895303726,
                -0.027267804369330406,
                -0.03294505923986435,
                0.007600056007504463,
                -0.013608022592961788,
                -0.010002887807786465,
                -0.06182089075446129,
                -0.015169317834079266,
                0.00775553984567523,
                -0.01927189901471138,
                0.03654380515217781,
                -0.038430146872997284,
                0.0656437948346138,
                0.020109210163354874,
                -0.017900198698043823,
                -0.023638226091861725,
                0.04577941447496414,
                0.04360243305563927,
                -0.039458099752664566,
                0.05722568556666374,
                -0.0543089285492897,
                -0.039488621056079865,
                -0.01485698763281107,
                0.007978064939379692,
                0.014799687080085278,
                -0.001778754754923284,
                -0.027088141068816185,
                -0.016854025423526764,
                0.05699712038040161,
                -0.014964515343308449,
                0.03771454095840454,
                0.0005480167455971241,
                -0.06456074863672256,
                0.026442984119057655,
                -0.007037756498903036,
                0.06830962002277374,
                0.0010026962263509631,
                0.024485018104314804,
                -0.05961338058114052,
                0.05313735827803612,
                -0.02627934142947197,
                0.0007215244113467634,
                0.012705828994512558,
                0.0051178825087845325,
                0.006452624686062336,
                0.015406270511448383,
                0.005421762354671955,
                0.04254332184791565,
                -0.0066905091516673565,
                -0.005234954413026571,
                -0.0062919966876506805,
                0.022883521392941475,
                0.006441459525376558,
                -0.025465331971645355,
                -0.025286467745900154,
                -0.0005085947341285646,
                0.006815533619374037,
                0.00041302916361019015,
                0.002410586690530181,
                0.021886132657527924,
                -0.006409730762243271,
                -0.01127960067242384,
                -0.0270257405936718,
                -0.014510060660541058,
                -0.043697431683540344,
                0.014873892068862915,
                0.012958520092070103,
                -0.006341036409139633,
                -0.021915260702371597,
                0.026561804115772247,
                -0.03854907304048538,
                -0.021143265068531036,
                -0.04260854423046112,
                -0.032747041434049606,
                -0.017028236761689186,
                -0.007803219836205244,
                -0.0010743919992819428,
                0.028769807890057564,
                0.034255776554346085,
                0.0013971198350191116,
                -0.01093850377947092,
                -0.06124870851635933,
                0.07686489820480347,
                -0.012192275375127792,
                -0.045131489634513855,
                0.028284838423132896,
                0.009447148069739342,
                0.05014877766370773,
                0.017608193680644035,
                -0.026464104652404785,
                0.045922957360744476,
                0.048656489700078964,
                -0.04816113039851189,
                -0.007023629266768694,
                -0.0056731365621089935,
                -0.04257935285568237,
                -0.0019501654896885157,
                0.03368524834513664,
                0.004866226110607386,
                -0.007692937273532152,
                -0.009739785455167294,
                -0.03579292073845863,
                -0.005786449182778597,
                -0.01149885356426239,
                -5.0639389298722105e-33,
                0.02209162712097168,
                -0.006850112229585648,
                0.02407807856798172,
                -0.04480253905057907,
                -0.06226348131895065,
                0.027606070041656494,
                0.021573957055807114,
                0.0332975760102272,
                -0.10229523479938507,
                -0.023617668077349663,
                -0.062362030148506165,
                0.0023333746939897537,
                0.021047569811344147,
                0.030474288389086723,
                0.0682818591594696,
                0.01872553490102291,
                0.023551631718873978,
                0.014870434999465942,
                0.004807308316230774,
                -0.020712725818157196,
                -0.02945736236870289,
                -0.002191228326410055,
                0.03399874269962311,
                0.054266925901174545,
                0.014457921497523785,
                0.005222752224653959,
                -0.04373210296034813,
                0.046372201293706894,
                0.020975690335035324,
                -0.04848448559641838,
                -0.0021831123158335686,
                -0.011531254276633263,
                -0.0160101056098938,
                -0.06300953030586243,
                -0.0038449401035904884,
                -0.017244510352611542,
                -0.03496416285634041,
                0.013437503017485142,
                -0.02387937530875206,
                -0.012805130332708359,
                -0.027088817209005356,
                -0.032240130007267,
                0.021616864949464798,
                -0.012633751146495342,
                -0.01058628223836422,
                0.00685239490121603,
                -0.02451673336327076,
                -0.011713248677551746,
                0.0011693078558892012,
                0.02798657864332199,
                -0.1016061082482338,
                -0.010226620361208916,
                -0.0561186783015728,
                0.0644514262676239,
                -0.01889781653881073,
                -0.013158564455807209,
                0.005190087947994471,
                -0.006413124967366457,
                -0.027649907395243645,
                -0.026462621986865997,
                -0.02698199823498726,
                0.026440788060426712,
                0.022637050598859787,
                0.049505140632390976,
                -0.05070115625858307,
                -0.006996311247348785,
                0.07654028385877609,
                0.10944748669862747,
                -0.006873254664242268,
                -0.03170369192957878,
                -0.027626758441329002,
                0.05000925436615944,
                -0.0032144077122211456,
                -0.0050551616586744785,
                -0.11234317719936371,
                -0.012843558564782143,
                -0.011326073668897152,
                0.01607220619916916,
                0.1032225638628006,
                0.04808538034558296,
                -0.02488779090344906,
                0.006462460849434137,
                0.004625366069376469,
                -0.027355214580893517,
                0.04153561592102051,
                -0.06617224961519241,
                -0.02827875316143036,
                0.07777190208435059,
                0.019425461068749428,
                -0.02727144956588745,
                -0.01578458957374096,
                0.047687988728284836,
                -0.04997996985912323,
                -0.013324369676411152,
                0.06413391977548599,
                0.021160855889320374,
                -0.036344125866889954,
                0.021058782935142517,
                0.024396458640694618,
                0.030966270714998245,
                -0.08540209382772446,
                0.028496943414211273,
                0.004132193513214588,
                0.09565719217061996,
                -0.011037624441087246,
                0.005069643259048462,
                -0.04821498319506645,
                -0.02711191587150097,
                -0.031441446393728256,
                -0.003380777547135949,
                0.001909361220896244,
                0.015369284898042679,
                0.003422878682613373,
                -0.05392957851290703,
                -0.08304468542337418,
                -0.006705077365040779,
                -0.0004940915969200432,
                -0.018039101734757423,
                -9.536372999718878e-06,
                0.010427233763039112,
                0.014964692294597626,
                -0.04010610654950142,
                0.014821337535977364,
                0.006924061104655266,
                -0.042586635798215866,
                -0.011158685199916363,
                0.036120761185884476,
                0.014518741518259048,
                0.010411339811980724,
                -0.036306146532297134,
                0.00790778361260891,
                -0.02262759767472744,
                2.2507161645535234e-07,
                -0.0022724131122231483,
                0.027798045426607132,
                0.003754101227968931,
                0.008966323919594288,
                -0.00628228485584259,
                -0.030594870448112488,
                -0.008244962431490421,
                0.0010918378829956055,
                -0.04135109856724739,
                0.037291109561920166,
                0.046821098774671555,
                -0.04225102812051773,
                -0.008401728235185146,
                -0.026134535670280457,
                -0.01530077401548624,
                -0.1237466037273407,
                -0.043954260647296906,
                -0.06659852713346481,
                -0.04616814851760864,
                -0.04425334930419922,
                0.02036340907216072,
                0.03855504095554352,
                0.04297592118382454,
                -0.042313043028116226,
                -0.005388372112065554,
                0.04526355117559433,
                0.01020987331867218,
                -0.006618362385779619,
                0.059244461357593536,
                0.022353937849402428,
                0.047470834106206894,
                0.007328116334974766,
                0.007814190350472927,
                -0.011107906699180603,
                0.025392679497599602,
                -0.061681509017944336,
                0.03168061003088951,
                0.023073093965649605,
                0.019130783155560493,
                0.04168897122144699,
                -0.01346110925078392,
                -0.02179674804210663,
                -0.006605294533073902,
                0.03573475778102875,
                0.007373804692178965,
                0.02018638700246811,
                -0.0024798396043479443,
                0.023328708484768867,
                -0.08474098891019821,
                0.007907223887741566,
                0.004512515850365162,
                0.06925611197948456,
                0.04663892835378647,
                0.009356786496937275,
                -0.00014559192641172558,
                -0.036227479577064514,
                -0.057460539042949677,
                0.0355193093419075,
                0.073695108294487,
                -0.03279297426342964,
                -0.0036389133892953396,
                -0.011855687946081161,
                -0.017911048606038094,
                0.032675303518772125,
                0.055816058069467545,
                -0.005120911169797182,
                0.038957513868808746,
                1.608374852081773e-34,
                -0.005979770328849554,
                -0.037883203476667404,
                0.010526378639042377,
                -0.020097600296139717,
                0.01922103576362133,
                -0.030850153416395187,
                -0.023395828902721405,
                0.014385513961315155,
                0.0076744575053453445,
                -0.007578721735626459,
                -0.026180148124694824
            ]
        },
        "Skills": {
            "name": [
                "Project Management"
            ],
            "rep": [
                0.03208990395069122,
                0.06187519058585167,
                -0.033957935869693756,
                -0.02028234489262104,
                0.01045618299394846,
                0.01858975924551487,
                -0.012608196586370468,
                -0.028463374823331833,
                -0.10083688050508499,
                0.05331563204526901,
                0.049518734216690063,
                0.035046063363552094,
                -0.026278076693415642,
                0.04499281570315361,
                -0.01669459231197834,
                -0.0766165554523468,
                0.018337495625019073,
                -0.03007485903799534,
                -0.007751181721687317,
                0.014431130141019821,
                0.001312998472712934,
                0.009494315832853317,
                -0.005465133115649223,
                0.02681160904467106,
                -0.03357281535863876,
                -0.0011126691242679954,
                -0.0328974574804306,
                0.0030853666830807924,
                0.03248082846403122,
                -0.026725338771939278,
                0.0028203825931996107,
                0.02342466078698635,
                -0.03421087935566902,
                -0.025930063799023628,
                1.6188499785130261e-06,
                0.0481349378824234,
                0.007736665196716785,
                -0.040277719497680664,
                -0.025562895461916924,
                0.06614158302545547,
                0.010857929475605488,
                -0.0196718480437994,
                0.023414524272084236,
                0.014774019829928875,
                -0.002179560484364629,
                0.001229269546456635,
                0.0506124310195446,
                0.04177908971905708,
                -0.007803361862897873,
                -0.07237352430820465,
                0.026359014213085175,
                0.025475427508354187,
                -0.08939142525196075,
                -0.04713260382413864,
                -0.008149327710270882,
                -0.07104215025901794,
                0.0022029471583664417,
                -0.014129690825939178,
                0.02886030822992325,
                0.04152737185359001,
                -0.0003251821326557547,
                -0.014835312962532043,
                0.00329066370613873,
                0.016459403559565544,
                0.04105055332183838,
                0.009437876753509045,
                0.06486502289772034,
                -0.021058574318885803,
                -0.0009955663699656725,
                -0.03341180086135864,
                0.025474267080426216,
                0.03610611334443092,
                -0.014535283669829369,
                -0.02333618700504303,
                0.001326852012425661,
                -0.0549575537443161,
                -0.008986681699752808,
                -0.004378207493573427,
                -0.009659390896558762,
                0.0005670859245583415,
                -0.028494009748101234,
                0.02739514224231243,
                -0.021296696737408638,
                0.007458865642547607,
                0.016446029767394066,
                0.015550973825156689,
                -0.03943731635808945,
                0.01710379868745804,
                -0.02778109908103943,
                0.04754886403679848,
                0.00734592555090785,
                -0.0139584606513381,
                0.02486604079604149,
                0.03627762943506241,
                -0.052640389651060104,
                -0.008157050237059593,
                0.002101612975820899,
                -0.013848965056240559,
                0.02340875379741192,
                -0.004960120655596256,
                -0.0376274399459362,
                0.01027617882937193,
                0.012589155696332455,
                0.05053647607564926,
                0.07906399667263031,
                0.04246358573436737,
                -0.03765489533543587,
                -0.035069458186626434,
                -0.03505373373627663,
                0.005048596765846014,
                -0.060440339148044586,
                0.010660831816494465,
                -0.01702333614230156,
                0.04485201835632324,
                0.0040457285940647125,
                0.01246770191937685,
                -0.04728653281927109,
                0.030319031327962875,
                4.619492756319232e-05,
                0.03352048620581627,
                0.040645960718393326,
                0.021410075947642326,
                -0.04265008121728897,
                0.01449158601462841,
                -0.04215133935213089,
                -0.008729378692805767,
                -0.051169101148843765,
                0.017516251653432846,
                -0.06499838829040527,
                0.07334113866090775,
                0.024316953495144844,
                -0.03740505874156952,
                -0.010935399681329727,
                -0.0017754699802026153,
                -0.008750551380217075,
                0.03963710367679596,
                -0.005113689694553614,
                0.015407465398311615,
                -0.06250516325235367,
                -0.002340370789170265,
                0.025970689952373505,
                0.020397672429680824,
                -0.07091300934553146,
                -0.025478340685367584,
                -0.039433542639017105,
                -0.02791212871670723,
                -0.08791300654411316,
                0.03244173899292946,
                0.010494432412087917,
                -0.008606615476310253,
                -0.01108805276453495,
                -0.026348570361733437,
                0.0032997371163219213,
                0.02923842892050743,
                0.05809006467461586,
                0.07125724852085114,
                -0.015773704275488853,
                -0.012094917707145214,
                -0.019805606454610825,
                0.04231508821249008,
                -0.01120375469326973,
                0.0425398126244545,
                0.07270161807537079,
                0.04446524381637573,
                -0.05319051444530487,
                0.003275379305705428,
                0.08606723695993423,
                -0.011635130271315575,
                -0.012365027330815792,
                -0.04815021902322769,
                -0.08658202737569809,
                0.040572620928287506,
                0.011457199230790138,
                0.09409988671541214,
                0.044868215918540955,
                -0.07586777210235596,
                0.040171995759010315,
                -0.01387398224323988,
                -0.06487317383289337,
                0.009271028451621532,
                -0.026707753539085388,
                -0.07976903766393661,
                0.032165154814720154,
                0.08330924808979034,
                0.06508784741163254,
                0.012809433043003082,
                -0.05661197379231453,
                0.02159484662115574,
                -0.0428452342748642,
                0.002965444466099143,
                0.021510504186153412,
                -0.026458872482180595,
                -0.09469558298587799,
                -0.0061955624260008335,
                0.027799034491181374,
                -0.006169733591377735,
                0.015055738389492035,
                -0.017696449533104897,
                -0.023975493386387825,
                0.017691656947135925,
                0.0014349832199513912,
                0.02082582749426365,
                0.02499569021165371,
                0.0016688610194250941,
                -0.04762475565075874,
                -0.08561155200004578,
                -0.051220815628767014,
                -0.001639181631617248,
                -0.060854990035295486,
                -0.0359518863260746,
                0.027073487639427185,
                -0.01789778470993042,
                -0.0036183062475174665,
                0.04557343199849129,
                -0.0190154816955328,
                0.043245088309049606,
                -0.001039331778883934,
                0.010260420851409435,
                -0.051386747509241104,
                0.03226708993315697,
                -0.03088502585887909,
                0.010440931655466557,
                0.013390600681304932,
                0.02788827195763588,
                0.010765588842332363,
                -0.03156760707497597,
                0.021942149847745895,
                -0.014106826856732368,
                0.018409602344036102,
                0.005541260354220867,
                0.021742982789874077,
                0.006801282055675983,
                0.054584018886089325,
                -0.03338349610567093,
                -0.01883942075073719,
                -0.008016201667487621,
                0.0649528056383133,
                0.042243264615535736,
                0.00272623379714787,
                -0.04431147500872612,
                -0.021241653710603714,
                -0.07740683853626251,
                -0.04580696299672127,
                -0.014613903127610683,
                0.066911980509758,
                0.01774921640753746,
                0.008431977592408657,
                0.025250902399420738,
                0.03736865147948265,
                -0.04562128335237503,
                -0.04781639575958252,
                -0.04243797808885574,
                0.0360802486538887,
                0.019917864352464676,
                0.016182631254196167,
                0.07044365257024765,
                -0.06826318800449371,
                0.012536733411252499,
                -0.020833538845181465,
                -0.0035467962734401226,
                0.07014694809913635,
                0.012572251260280609,
                -0.0023792805150151253,
                -0.023373600095510483,
                0.024487784132361412,
                -0.017939167097210884,
                -0.018838083371520042,
                -0.024105912074446678,
                -0.053793199360370636,
                0.01819700561463833,
                0.011940224096179008,
                -0.008542927913367748,
                0.024392658844590187,
                -0.018336383625864983,
                -0.048880547285079956,
                0.0016523398226127028,
                -0.026773253455758095,
                0.03667958080768585,
                -0.02598920464515686,
                0.021548504009842873,
                0.015564091503620148,
                0.01887640729546547,
                -0.06281020492315292,
                0.03520873561501503,
                0.021395083516836166,
                -0.010491868481040001,
                0.017458777874708176,
                -0.07976725697517395,
                -0.04739035293459892,
                0.022630475461483,
                -0.032766666263341904,
                -0.06921432912349701,
                0.035192664712667465,
                0.02413882128894329,
                -0.025476617738604546,
                -0.0032878059428185225,
                0.060035884380340576,
                -0.07879284024238586,
                -0.02534930221736431,
                0.013055738992989063,
                0.01510667521506548,
                -0.04479428380727768,
                0.023537542670965195,
                -0.04534991458058357,
                0.016122836619615555,
                0.010075419209897518,
                0.06572342664003372,
                0.015683025121688843,
                -0.0116981016471982,
                0.0006045311456546187,
                0.028536537662148476,
                0.03340316563844681,
                0.018637806177139282,
                0.05668367072939873,
                -0.03120187483727932,
                0.05251002311706543,
                0.0028838959988206625,
                -0.008729325607419014,
                0.032442279160022736,
                0.06518550217151642,
                -0.017443211749196053,
                0.015921136364340782,
                -0.003489085705950856,
                0.011221285909414291,
                0.026404349133372307,
                0.015351473353803158,
                -0.05357914790511131,
                -0.03961214795708656,
                0.0541045106947422,
                -0.03432970866560936,
                -0.014056125655770302,
                -0.035513442009687424,
                -0.028187820687890053,
                -0.022480757907032967,
                0.0026878167409449816,
                -0.014175615273416042,
                0.004432894755154848,
                -0.04462350904941559,
                -0.030063116922974586,
                0.005682086106389761,
                -0.021673325449228287,
                -0.019065886735916138,
                -0.04026348516345024,
                0.007182043511420488,
                -0.03583916276693344,
                0.01453458797186613,
                0.020068982616066933,
                0.06485619395971298,
                -0.003765863599255681,
                0.02687539905309677,
                0.03842553868889809,
                0.01611078344285488,
                -0.010808569379150867,
                -0.006687352433800697,
                0.00011804432870121673,
                -0.043246038258075714,
                -0.06306514143943787,
                0.003976167645305395,
                -0.0021474298555403948,
                -0.023565536364912987,
                -0.07313240319490433,
                -0.010487128980457783,
                0.04743654653429985,
                -0.029853202402591705,
                -0.023612452670931816,
                -0.017249951139092445,
                0.030375590547919273,
                0.019182216376066208,
                0.02692795731127262,
                0.0220890324562788,
                -0.044896841049194336,
                0.011935306712985039,
                0.006669218186289072,
                -0.00816288497298956,
                0.028048064559698105,
                0.0022477207239717245,
                0.008900834247469902,
                -0.009098327718675137,
                -0.04157411679625511,
                0.005249375943094492,
                0.00026583511498756707,
                0.022044610232114792,
                0.019007958471775055,
                -0.030355727300047874,
                -0.0038499110378324986,
                0.012819617055356503,
                -0.024790218099951744,
                -0.005804451182484627,
                0.06471829861402512,
                0.021019039675593376,
                -0.02534462697803974,
                0.003423877526074648,
                0.006086477544158697,
                0.033601969480514526,
                0.03768696263432503,
                -0.03550628200173378,
                -0.06588364392518997,
                0.011284738779067993,
                0.0450906828045845,
                -0.00355461030267179,
                0.003028011880815029,
                -0.012440393678843975,
                0.03231918066740036,
                -0.008109715767204762,
                -0.04561762139201164,
                -0.055519949644804,
                0.08596912026405334,
                0.006486996542662382,
                -0.0056798397563397884,
                0.01734462007880211,
                -0.012870199047029018,
                0.011075001209974289,
                -0.0157296285033226,
                -0.013922193087637424,
                0.04533941671252251,
                0.004672557581216097,
                -0.0364333875477314,
                0.022885244339704514,
                -0.04902627691626549,
                -0.04624570533633232,
                0.01805914007127285,
                0.048080507665872574,
                0.024172717705368996,
                -0.036997899413108826,
                0.06061722710728645,
                -0.007688992191106081,
                -0.00767663074657321,
                0.02907201275229454,
                -0.013156705535948277,
                -0.010987523943185806,
                -0.032444264739751816,
                0.017381714656949043,
                -0.0033689767587929964,
                0.051930539309978485,
                0.055339664220809937,
                0.05642728507518768,
                0.006968607660382986,
                -0.019806986674666405,
                0.0893481895327568,
                0.0019006725633516908,
                0.013196422718465328,
                0.09992476552724838,
                0.052002761512994766,
                0.02391895465552807,
                -0.008022070862352848,
                0.026253538206219673,
                0.0014587721088901162,
                -0.02504795975983143,
                -0.11165529489517212,
                0.07154464721679688,
                0.035397548228502274,
                0.007495129480957985,
                -0.03778716176748276,
                -0.022537117823958397,
                -0.004786525387316942,
                0.022658657282590866,
                -0.03420066833496094,
                -0.05252423882484436,
                0.010848330333828926,
                -0.03013613261282444,
                0.06678637117147446,
                0.02879154495894909,
                -0.11949280649423599,
                0.0017202653689309955,
                -0.027221666648983955,
                -0.036213457584381104,
                -0.04383708909153938,
                0.012198150157928467,
                0.010660061612725258,
                -0.043194256722927094,
                0.05244578421115875,
                -0.03007497638463974,
                -0.018892107531428337,
                0.007015611510723829,
                0.023828735575079918,
                0.00932789221405983,
                0.03900428116321564,
                -0.06713448464870453,
                -0.0037923746276646852,
                0.002559267682954669,
                -0.011990874074399471,
                0.030987003818154335,
                -0.004278199747204781,
                -0.08088298887014389,
                -0.017404887825250626,
                0.0020667766220867634,
                0.02685476280748844,
                -0.0035527674481272697,
                -0.0015452944207936525,
                -0.0005717036547139287,
                0.0117404256016016,
                -0.01587965153157711,
                0.01438447367399931,
                0.006761892233043909,
                0.007735620252788067,
                -0.01636294089257717,
                0.034440185874700546,
                -0.00965727400034666,
                0.039973724633455276,
                -0.04874696210026741,
                0.008966405875980854,
                0.04138055443763733,
                -0.01618286408483982,
                -0.049418941140174866,
                0.0020089754834771156,
                -0.017978079617023468,
                -0.006600161083042622,
                0.008763769641518593,
                -0.03276795148849487,
                0.011085518635809422,
                -0.0145194036886096,
                -0.029460875317454338,
                0.030200297012925148,
                -0.08226223289966583,
                0.06653930246829987,
                0.04606220871210098,
                0.012544872239232063,
                0.04267186298966408,
                -0.02284502424299717,
                -0.03974508121609688,
                0.023163817822933197,
                -0.027218418195843697,
                0.0013504724483937025,
                -0.0218153428286314,
                0.05729028582572937,
                -0.017108609899878502,
                0.02740415558218956,
                -0.020335279405117035,
                -0.021483322605490685,
                0.023842262104153633,
                -0.0032622963190078735,
                -0.06152493506669998,
                -0.11012791842222214,
                0.010893156751990318,
                -0.008346738293766975,
                0.08030878007411957,
                0.004160693846642971,
                0.05471495911478996,
                -0.02648613229393959,
                0.002679153112694621,
                -0.050316065549850464,
                0.05267136171460152,
                0.007847779430449009,
                -0.0015847011236473918,
                0.014018921181559563,
                -0.06598485261201859,
                -0.02351682260632515,
                -0.007140382658690214,
                0.010879343375563622,
                0.032408151775598526,
                -0.010992305353283882,
                -0.03648783266544342,
                0.0010503977537155151,
                0.02866598218679428,
                -0.027972163632512093,
                -5.683616205271534e-33,
                -0.05298333615064621,
                -0.0688483789563179,
                0.03901948407292366,
                0.029863623902201653,
                0.009182602167129517,
                0.0015202785143628716,
                0.024961672723293304,
                0.04113603010773659,
                -0.01216693501919508,
                -0.05290563404560089,
                -0.001556790666654706,
                0.004131094552576542,
                0.02193627879023552,
                -0.008446070365607738,
                0.10536891222000122,
                0.04360052943229675,
                0.05317624285817146,
                0.023999571800231934,
                0.02665325254201889,
                -0.038025062531232834,
                -0.0232936330139637,
                0.022868050262331963,
                0.03162243589758873,
                0.06746840476989746,
                -0.014119207859039307,
                0.012679269537329674,
                0.020421721041202545,
                0.023443952202796936,
                0.0699121430516243,
                0.006362713873386383,
                0.00874320324510336,
                -0.04861536622047424,
                0.005716544575989246,
                -0.012018447741866112,
                -0.0006204135715961456,
                0.039036825299263,
                -0.008112738840281963,
                -0.023955106735229492,
                -0.03183998167514801,
                -0.016718586906790733,
                0.02718801237642765,
                0.022625381126999855,
                -0.015232361853122711,
                0.015149010345339775,
                -0.018174713477492332,
                -0.002921925624832511,
                0.009503892622888088,
                -0.0016216172371059656,
                0.00025291703059338033,
                0.06089138984680176,
                -0.04452301189303398,
                0.009181701578199863,
                -0.018199682235717773,
                0.03272561356425285,
                0.022421590983867645,
                -0.008497750386595726,
                -0.01080982107669115,
                0.04400359094142914,
                0.008268051780760288,
                0.010663234628736973,
                -0.07613397389650345,
                0.012673260644078255,
                0.0016278792172670364,
                0.006986299064010382,
                -0.04050757363438606,
                0.0029265566263347864,
                -0.044312044978141785,
                0.04325136914849281,
                -0.02874043583869934,
                -0.033437762409448624,
                -0.04565020278096199,
                0.08388998359441757,
                0.027731552720069885,
                -0.03751244768500328,
                -0.08409304171800613,
                0.010290783829987049,
                0.0035743515472859144,
                0.006550181191414595,
                0.07556717842817307,
                0.03304038196802139,
                -0.017003480345010757,
                0.07438560575246811,
                0.03386974334716797,
                -0.00885019265115261,
                0.017095545306801796,
                -0.06957782059907913,
                -0.01700502261519432,
                0.07555899769067764,
                0.006883630994707346,
                0.02523934841156006,
                -0.03240320459008217,
                0.053565338253974915,
                0.010533110238611698,
                0.02294366993010044,
                0.03587792441248894,
                0.03026638552546501,
                -0.016939710825681686,
                0.02424035780131817,
                -0.025388101115822792,
                0.028210468590259552,
                -0.05083364620804787,
                0.0351799875497818,
                -0.06888963282108307,
                -0.004754296038299799,
                -0.011278503574430943,
                -0.005466071888804436,
                -0.039434220641851425,
                -0.05520511046051979,
                -0.00544803636148572,
                0.0366014800965786,
                0.012286779470741749,
                -0.005768319591879845,
                -0.041899777948856354,
                -0.03406507894396782,
                -0.025926128029823303,
                -0.046543918550014496,
                0.023737406358122826,
                -0.03423314169049263,
                0.003670712234452367,
                -0.04141538217663765,
                -0.028419509530067444,
                0.017367471009492874,
                -0.027355602011084557,
                -0.022332211956381798,
                0.014980842359364033,
                -0.04135431349277496,
                0.006670400500297546,
                0.019313303753733635,
                0.04674111679196358,
                -0.01393807865679264,
                -0.01284155435860157,
                0.020780909806489944,
                2.2358189255555772e-07,
                -0.00642681960016489,
                0.03819578140974045,
                0.040856897830963135,
                0.04203781485557556,
                0.021065838634967804,
                0.03403599187731743,
                -0.022078268229961395,
                -0.031434327363967896,
                -0.017998313531279564,
                0.006782860960811377,
                0.049865201115608215,
                -0.029474565759301186,
                -0.001676215440966189,
                0.03722574561834335,
                0.04511256143450737,
                -0.12294664978981018,
                -0.014730163849890232,
                -0.008261250331997871,
                -0.06514047831296921,
                -0.03310244902968407,
                0.006858536507934332,
                0.03329465538263321,
                0.05817826837301254,
                -0.00011494427599245682,
                -0.005012588109821081,
                -0.016877438873052597,
                -0.010617809370160103,
                0.01905280165374279,
                0.03127400949597359,
                0.02953376993536949,
                0.02809394896030426,
                -0.01801225170493126,
                -0.039969246834516525,
                0.03358609601855278,
                0.03840290382504463,
                -0.08022067695856094,
                0.0517033189535141,
                0.022783836349844933,
                0.025440439581871033,
                0.02000313624739647,
                0.012695005163550377,
                -0.021539079025387764,
                -0.03278366103768349,
                0.05888742208480835,
                -0.06474548578262329,
                0.0025522587820887566,
                -0.0021004751324653625,
                0.06127573549747467,
                0.028266219422221184,
                -0.0023947099689394236,
                0.03980524092912674,
                0.042772747576236725,
                0.0537005178630352,
                0.00883602723479271,
                -0.013674202375113964,
                -0.029283246025443077,
                -0.04297828674316406,
                0.010521719232201576,
                0.054890040308237076,
                0.029747098684310913,
                -0.04167797788977623,
                -0.007549756206572056,
                -0.018549760803580284,
                0.008701415732502937,
                -0.003312797052785754,
                0.03573498874902725,
                -0.008067155256867409,
                4.942453241753658e-35,
                -0.030651070177555084,
                -0.05562421306967735,
                -0.05893056094646454,
                -0.04055287688970566,
                -0.03378883749246597,
                0.013660525903105736,
                -0.08730491995811462,
                -0.0156480073928833,
                -0.010841675102710724,
                0.018977338448166847,
                -0.025762176141142845
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\xdf\\x87p\\xf9\\xf8\\xab\\xf85\\x84\\x9d\\xe6Rf\\x00\\xa4%'",
        "id__": "71694161-7edb-4c8c-b963-3172dc477130"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "BSc/Business Management",
            "rep": [
                -0.0032023524399846792,
                -0.05618240684270859,
                -0.02376374788582325,
                -0.01897163689136505,
                0.05216611549258232,
                0.029772059991955757,
                -0.02584213577210903,
                -0.0024597228039056063,
                0.07283414900302887,
                0.002209126017987728,
                0.03413626179099083,
                -0.0011790033895522356,
                0.004600252956151962,
                0.11048018932342529,
                0.045748479664325714,
                0.03334837034344673,
                0.00812280923128128,
                -0.006703304126858711,
                -0.02521556429564953,
                -0.022556014358997345,
                -0.06189005821943283,
                0.013773735612630844,
                -0.030442701652646065,
                0.0014024212723597884,
                0.018258487805724144,
                -0.02816011942923069,
                0.01808762736618519,
                0.028422700241208076,
                -0.012867369689047337,
                0.0523175448179245,
                0.027311556041240692,
                0.012817678973078728,
                -0.012589424848556519,
                -0.012987852096557617,
                1.3886957503927988e-06,
                -0.03280269727110863,
                -0.044858887791633606,
                0.021172143518924713,
                -0.01213480532169342,
                -0.05380971357226372,
                0.03613826259970665,
                0.10787778347730637,
                0.005655187647789717,
                0.03409538418054581,
                -0.010523319244384766,
                0.08146320283412933,
                0.022418951615691185,
                0.0323101170361042,
                -0.025431640446186066,
                0.0013681843411177397,
                0.010246425867080688,
                -0.09600146859884262,
                -0.06022031605243683,
                -0.0446888692677021,
                -0.0759657546877861,
                -0.08355351537466049,
                0.04219747334718704,
                0.029510149732232094,
                0.015444159507751465,
                0.060539260506629944,
                -0.019805198535323143,
                -0.04358465597033501,
                -0.05471927672624588,
                0.015014777891337872,
                0.048714231699705124,
                0.06237465888261795,
                0.06504329293966293,
                0.006598289590328932,
                -0.032744843512773514,
                -0.030788788571953773,
                0.014645875431597233,
                0.0017891639145091176,
                -0.010137745179235935,
                0.028774375095963478,
                0.07103254646062851,
                -0.011323520913720131,
                0.027223816141486168,
                -0.015008957125246525,
                -0.01312105543911457,
                0.0024390865582972765,
                -0.08365853130817413,
                0.040737610310316086,
                0.025009343400597572,
                0.03579188138246536,
                -0.04427950084209442,
                0.035350605845451355,
                -0.02015514113008976,
                0.00740769924595952,
                0.021737074479460716,
                -0.02842484973371029,
                0.010410284623503685,
                0.019109496846795082,
                -0.030243882909417152,
                0.02125283144414425,
                -0.018063213676214218,
                -0.040961854159832,
                0.06920818239450455,
                0.0279636699706316,
                -0.03345203027129173,
                0.0260140523314476,
                -0.017513656988739967,
                -0.009840370155870914,
                -0.020625006407499313,
                0.05097894370555878,
                0.0026020340155810118,
                0.04261447489261627,
                0.029450617730617523,
                -0.0012352261692285538,
                0.01215604692697525,
                0.01397724449634552,
                -0.04995015636086464,
                0.007567547261714935,
                -0.0018993038684129715,
                0.006957645062357187,
                -0.0526687353849411,
                0.04018695279955864,
                -0.03375839814543724,
                -0.024572379887104034,
                -0.03345813229680061,
                0.022463498637080193,
                -0.019525064155459404,
                0.004214162938296795,
                -7.840045873308554e-05,
                0.029360568150877953,
                -0.006201683543622494,
                -0.01939394511282444,
                0.0020839155185967684,
                -0.006597717292606831,
                0.027370193973183632,
                0.08315335959196091,
                0.015549463219940662,
                -0.05970272794365883,
                -0.019916895776987076,
                0.012055856175720692,
                0.01826927810907364,
                -0.012465253472328186,
                -0.015791650861501694,
                0.045571424067020416,
                -0.05290080979466438,
                -0.014082428999245167,
                -0.019561996683478355,
                -0.04933135583996773,
                0.024050889536738396,
                0.06124643236398697,
                -0.004643086809664965,
                -0.020008834078907967,
                -0.01998163014650345,
                -0.007725572679191828,
                0.012348282150924206,
                -0.0028820910956710577,
                -0.032146185636520386,
                -0.042555611580610275,
                0.04159872233867645,
                -0.05744986981153488,
                0.03183288872241974,
                0.012853389605879784,
                0.08321938663721085,
                0.039397791028022766,
                -0.03472861647605896,
                0.06213300675153732,
                -0.006074964068830013,
                0.016354596242308617,
                0.007290615234524012,
                0.018694933503866196,
                -0.025622623041272163,
                -0.0349888913333416,
                0.016765186563134193,
                -0.0354660339653492,
                -0.019870642572641373,
                -0.02753175050020218,
                -0.02308313362300396,
                -0.053763218224048615,
                -0.029116740450263023,
                0.08643922954797745,
                0.017013827338814735,
                -0.04031962528824806,
                0.020422151312232018,
                0.037672147154808044,
                -0.04659772291779518,
                -0.01983458735048771,
                -0.017260463908314705,
                -0.06691018491983414,
                0.02122516930103302,
                0.0014229010557755828,
                -0.012029193341732025,
                0.019447997212409973,
                -0.02692343108355999,
                -0.010006191208958626,
                -0.0235698614269495,
                -0.007753852289170027,
                0.010505331680178642,
                0.03741597384214401,
                -0.07656227797269821,
                -0.003551831701770425,
                0.0013342881575226784,
                -0.027153167873620987,
                -0.025097334757447243,
                0.025989776477217674,
                -0.008658030070364475,
                -0.028866620734333992,
                -0.020785016939044,
                0.06082242727279663,
                -0.0002798329514916986,
                0.04879666864871979,
                -0.0022437400184571743,
                -0.021431773900985718,
                0.050763484090566635,
                -0.05777338892221451,
                -0.03859790042042732,
                0.08014390617609024,
                0.006755838170647621,
                -0.006663988344371319,
                0.046362388879060745,
                0.016274448484182358,
                -0.003422471461817622,
                -0.03640438988804817,
                -0.000774425221607089,
                -0.025585249066352844,
                -0.033865682780742645,
                -0.025419333949685097,
                -0.021148085594177246,
                0.021660149097442627,
                0.04970269650220871,
                -0.0314142070710659,
                -0.040107306092977524,
                0.02890191785991192,
                0.040320754051208496,
                -0.019883837550878525,
                0.014454315416514874,
                0.05509254336357117,
                0.0028055564034730196,
                -0.025499552488327026,
                0.01461578905582428,
                -0.01328656729310751,
                0.008406177163124084,
                -0.0530201680958271,
                0.05782400071620941,
                -0.03885139524936676,
                0.03088347800076008,
                0.03507031872868538,
                -0.026893828064203262,
                -0.01053505390882492,
                0.0073845600709319115,
                -0.023625481873750687,
                0.039980605244636536,
                0.03950485959649086,
                -0.04024793952703476,
                0.010030385106801987,
                0.027164634317159653,
                -0.051763154566287994,
                0.007949401624500751,
                0.01898379996418953,
                0.014837590046226978,
                0.008634665049612522,
                0.012943916954100132,
                -0.009475947357714176,
                -0.09299110621213913,
                0.016865013167262077,
                -0.013845660723745823,
                -0.011543065309524536,
                0.013329255394637585,
                -0.007867755368351936,
                -0.0006362632848322392,
                0.018960801884531975,
                -0.015057425945997238,
                -0.005882773082703352,
                0.015129986219108105,
                -0.00498882494866848,
                -0.010109510272741318,
                0.029688220471143723,
                -0.020779605954885483,
                0.04842730239033699,
                0.02172488532960415,
                -0.015065331012010574,
                -0.03274320438504219,
                0.007610984146595001,
                -0.01210036315023899,
                0.03042449802160263,
                -0.0876951813697815,
                0.014596347697079182,
                0.041351672261953354,
                0.032801173627376556,
                -0.03568011149764061,
                -0.009492765180766582,
                -0.011809317395091057,
                -0.03696475550532341,
                0.027184201404452324,
                -0.011034650728106499,
                -0.02347627654671669,
                0.010046258568763733,
                -0.02679983153939247,
                0.011380231939256191,
                -0.0029854080639779568,
                -0.0378609299659729,
                -0.02019483409821987,
                0.038996513932943344,
                0.009899509139358997,
                -0.0033250628039240837,
                -0.021888144314289093,
                0.0009431951912119985,
                0.011100769974291325,
                -0.04097524285316467,
                0.05393512174487114,
                0.021398451179265976,
                -0.027434952557086945,
                0.051840078085660934,
                -0.0177284087985754,
                0.015239115804433823,
                -0.030338428914546967,
                -0.04333476349711418,
                -0.04136429354548454,
                0.013410182669758797,
                0.029035991057753563,
                -0.0027393645141273737,
                0.04187570884823799,
                -0.04140741750597954,
                -0.07510067522525787,
                -0.000619138590991497,
                0.024019312113523483,
                0.046319592744112015,
                0.028060007840394974,
                0.04559905081987381,
                -0.05921792611479759,
                -0.01684539020061493,
                0.010150961577892303,
                0.03058282472193241,
                -0.026387298479676247,
                0.016053466126322746,
                0.01552770659327507,
                -0.08817179501056671,
                -0.021730754524469376,
                -0.03155675530433655,
                -0.03206860274076462,
                -0.05519099533557892,
                -0.011357912793755531,
                -0.04240184277296066,
                -0.03396913409233093,
                -0.05666310340166092,
                -0.011892168782651424,
                -0.030230429023504257,
                0.013112268410623074,
                0.03355177491903305,
                -0.03903641924262047,
                0.001319279195740819,
                0.011525407433509827,
                0.008997566998004913,
                0.03652668744325638,
                0.0468868650496006,
                -0.03930041939020157,
                -0.02019776962697506,
                0.030758308246731758,
                -0.016955949366092682,
                0.018747519701719284,
                0.005422631278634071,
                -0.02290942892432213,
                0.006844622083008289,
                0.04119429364800453,
                0.02896052785217762,
                0.03217443451285362,
                -0.0538761131465435,
                -0.05694019794464111,
                -0.031034385785460472,
                -0.08740323781967163,
                -0.04357437416911125,
                0.02744203247129917,
                -0.012529583647847176,
                0.07058153301477432,
                0.0013704440789297223,
                0.012209300883114338,
                -0.006096106022596359,
                -0.05756164342164993,
                0.018383532762527466,
                -0.040124934166669846,
                0.031762149184942245,
                -0.035723574459552765,
                0.023613980039954185,
                -0.0023500898387283087,
                -0.03478940948843956,
                -0.045749060809612274,
                -0.005093950312584639,
                0.06728088855743408,
                0.011816628277301788,
                0.043974582105875015,
                -0.029505984857678413,
                0.021502496674656868,
                -0.011706169694662094,
                0.01314589660614729,
                -0.005177929997444153,
                -0.022541219368577003,
                -0.014405806548893452,
                0.008074759505689144,
                0.00827636569738388,
                -0.046124398708343506,
                0.07970811426639557,
                0.0006137796444818377,
                -0.006291135214269161,
                0.032918673008680344,
                -0.006502468138933182,
                0.023839307948946953,
                -0.026232963427901268,
                0.06355639547109604,
                0.003033911343663931,
                0.05192430317401886,
                0.01980065181851387,
                -0.009831058792769909,
                -0.08170291036367416,
                0.03727532550692558,
                0.007527192588895559,
                -0.09879331290721893,
                0.06668800860643387,
                -0.03736799210309982,
                -0.012277405709028244,
                -0.0007550516165792942,
                0.04996683821082115,
                0.006739644333720207,
                -0.04344145953655243,
                -0.009905020706355572,
                0.03479420021176338,
                -0.029417138546705246,
                -0.08486240357160568,
                0.02201336994767189,
                0.020396890118718147,
                0.013491328805685043,
                0.0065015507861971855,
                0.02255435846745968,
                -0.013417771086096764,
                0.004382667131721973,
                -0.011109279468655586,
                0.007688174489885569,
                -0.059617746621370316,
                0.003102698130533099,
                -0.0032857428304851055,
                -0.01505950652062893,
                -0.0015606220113113523,
                0.027152761816978455,
                0.0013048185501247644,
                0.010889114812016487,
                -0.016640139743685722,
                -0.06489451229572296,
                0.031646233052015305,
                0.001505751279182732,
                0.04959307610988617,
                -0.03364599496126175,
                -0.02471160516142845,
                0.052519094198942184,
                0.0876208022236824,
                -0.04159752279520035,
                0.0011372213484719396,
                -0.05642920732498169,
                -0.041888631880283356,
                0.08281499147415161,
                -0.07047231495380402,
                -0.012277822010219097,
                -0.069090336561203,
                0.010391551069915295,
                -0.07753258943557739,
                -0.06530847400426865,
                0.009492779150605202,
                -0.015204858966171741,
                0.0015868687769398093,
                0.006946726702153683,
                0.008225631900131702,
                0.010659707710146904,
                -0.0037766234017908573,
                0.025626124814152718,
                0.0016796465497463942,
                0.03339453786611557,
                0.03567948937416077,
                0.06818392127752304,
                -0.003969185519963503,
                -0.0496402382850647,
                -0.02163117751479149,
                -0.018814658746123314,
                -0.03337565436959267,
                -0.0012584163341671228,
                0.028846807777881622,
                0.03786749020218849,
                0.015219095163047314,
                0.035709064453840256,
                0.010356823913753033,
                -0.006562428083270788,
                -0.01177864708006382,
                0.04292365163564682,
                -0.0367267020046711,
                0.009525245986878872,
                0.013477176427841187,
                0.0030856130179017782,
                -0.005366423167288303,
                0.017341747879981995,
                -0.07265254855155945,
                0.07544077932834625,
                -0.0387638583779335,
                -0.008222095668315887,
                -0.06856410950422287,
                0.009236684069037437,
                -0.015161877498030663,
                0.02805957943201065,
                0.008877295069396496,
                -0.050350822508335114,
                0.04720667749643326,
                -0.037435755133628845,
                -0.04628615081310272,
                -0.0283834058791399,
                -0.08224353194236755,
                0.04194745421409607,
                -0.03619271144270897,
                -0.0069222478196024895,
                0.024573370814323425,
                0.020638415589928627,
                0.01825707033276558,
                0.05768571048974991,
                0.003645525546744466,
                0.030292976647615433,
                0.06031185761094093,
                0.059803709387779236,
                -0.03384140133857727,
                0.001997802872210741,
                -0.02561495080590248,
                0.023991988971829414,
                0.01018341165035963,
                0.03271184116601944,
                -0.03169548511505127,
                0.03103823959827423,
                -0.02879338338971138,
                0.021870069205760956,
                -0.031875185668468475,
                0.005810174625366926,
                -0.03203876316547394,
                0.059896811842918396,
                0.012964543886482716,
                0.043514352291822433,
                -0.01687435247004032,
                -0.03112882561981678,
                0.01781756989657879,
                -0.005321776960045099,
                0.012493071146309376,
                0.052445825189352036,
                0.08014319092035294,
                0.054925546050071716,
                0.08836394548416138,
                -0.054403018206357956,
                0.01999734528362751,
                0.09556731581687927,
                0.008828215301036835,
                -0.010410860180854797,
                -0.03889136388897896,
                0.006804362870752811,
                0.011039912700653076,
                0.04256593808531761,
                0.056669361889362335,
                -0.012402229942381382,
                0.045539338141679764,
                0.03233003988862038,
                0.06362712383270264,
                -0.003190266666933894,
                -4.817769106974915e-33,
                -0.04610764607787132,
                -0.022043850272893906,
                0.03705236688256264,
                -0.07338069379329681,
                0.008525831624865532,
                -0.03613732010126114,
                0.03961518779397011,
                -0.0029134333599358797,
                -0.04367433115839958,
                -0.019552698358893394,
                -0.055751360952854156,
                -0.005675382446497679,
                0.01774274744093418,
                0.01870322786271572,
                0.0480988509953022,
                0.034342583268880844,
                0.02161213383078575,
                0.0660829171538353,
                0.030499329790472984,
                -0.023126212880015373,
                0.0010673587676137686,
                0.009882282465696335,
                0.004541102796792984,
                0.04739394783973694,
                0.003452808829024434,
                -0.0023732194676995277,
                -0.02293986640870571,
                -0.023406339809298515,
                0.012480160221457481,
                -0.0013479143381118774,
                0.025611301884055138,
                -0.03488122671842575,
                -0.02205054834485054,
                0.027692260220646858,
                -0.002179729053750634,
                -0.03682985529303551,
                0.0173542108386755,
                -0.00926162675023079,
                -0.019051648676395416,
                0.006893593352288008,
                0.016511281952261925,
                0.009734343737363815,
                -0.012442454695701599,
                -0.010082663036882877,
                -0.04099384322762489,
                -0.07304847240447998,
                -0.030721982941031456,
                0.015759022906422615,
                0.010939914733171463,
                -0.0033107104245573282,
                -0.054631054401397705,
                -0.03647192195057869,
                -0.027746614068746567,
                0.04860333725810051,
                -0.03193506971001625,
                -0.06343136727809906,
                0.011862454004585743,
                -0.0012118336744606495,
                0.012483400292694569,
                0.019171813502907753,
                0.03025951236486435,
                0.027496617287397385,
                0.033547867089509964,
                -0.016808954998850822,
                -0.007226439192891121,
                0.006106867920607328,
                -0.024495840072631836,
                0.04691198468208313,
                0.05026645213365555,
                -0.053814712911844254,
                -0.018768446519970894,
                0.09657298028469086,
                -0.044017620384693146,
                -0.02860209159553051,
                -0.09873798489570618,
                -0.027427485212683678,
                0.013003546744585037,
                -0.04812601953744888,
                0.045354537665843964,
                0.007227701600641012,
                -0.06414469331502914,
                0.023115068674087524,
                0.037579573690891266,
                0.014673536643385887,
                0.044068850576877594,
                -0.004563464783132076,
                0.03217822313308716,
                0.05452897399663925,
                0.03141266107559204,
                -0.01973593607544899,
                -0.006163289770483971,
                0.0398884154856205,
                -0.025017080828547478,
                -0.006292077712714672,
                -0.012015553191304207,
                -0.04790937528014183,
                -0.009871389716863632,
                0.009213254787027836,
                0.025588858872652054,
                0.028802376240491867,
                -0.06217323616147041,
                0.013302932493388653,
                -0.03162328898906708,
                -0.0023321635089814663,
                -0.030417367815971375,
                0.07186757773160934,
                -0.01398143358528614,
                -0.017964400351047516,
                0.006280722562223673,
                -0.013904603198170662,
                0.013314546085894108,
                0.04246624559164047,
                0.010351837612688541,
                -0.023021068423986435,
                -0.0013732697116211057,
                0.019066400825977325,
                0.0064193131402134895,
                -0.08106714487075806,
                -0.020960340276360512,
                0.036995697766542435,
                0.062250833958387375,
                -0.04230297729372978,
                0.015349183231592178,
                0.0005901570548303425,
                -0.021199263632297516,
                -0.035085394978523254,
                0.07230688631534576,
                -0.021625151857733727,
                -0.002791358157992363,
                -0.012872839346528053,
                0.019668051972985268,
                -0.022233355790376663,
                1.855969600228491e-07,
                0.021174436435103416,
                0.08063339442014694,
                -0.004782056901603937,
                0.040664590895175934,
                -0.03257018327713013,
                -0.008716902695596218,
                0.005289424676448107,
                -0.0011799054918810725,
                0.002909864066168666,
                0.043865788727998734,
                -0.028298165649175644,
                -0.02012638933956623,
                -0.04176563769578934,
                -0.08405277132987976,
                -0.020342297852039337,
                -0.06104090064764023,
                0.029018526896834373,
                -0.03716084733605385,
                -0.032346226274967194,
                -0.026328017935156822,
                -0.09207172691822052,
                0.0183345228433609,
                0.04050823673605919,
                -0.010569032281637192,
                0.013122450560331345,
                0.014825016260147095,
                -0.01619146391749382,
                -0.013166381977498531,
                0.06403852254152298,
                0.0031658958178013563,
                0.020468642935156822,
                0.018421459943056107,
                0.0332675501704216,
                0.006913585588335991,
                0.019518345594406128,
                0.012012620456516743,
                0.04583711177110672,
                0.053461477160453796,
                0.0056658643297851086,
                0.06384233385324478,
                -0.03237081319093704,
                0.015469587408006191,
                -0.0007979847141541541,
                0.032475151121616364,
                0.009316205978393555,
                0.0284435898065567,
                -0.017220377922058105,
                -0.016755541786551476,
                -0.110741026699543,
                -0.0319608673453331,
                -0.06703318655490875,
                0.06405762583017349,
                0.010930453427135944,
                -0.01641681417822838,
                0.01879875734448433,
                0.006199392955750227,
                -0.004711971152573824,
                0.0423290953040123,
                0.08739536255598068,
                -0.03550995513796806,
                0.022700078785419464,
                -0.04019319638609886,
                -0.007813535630702972,
                0.021553674712777138,
                0.09729350358247757,
                -0.03808534890413284,
                -0.042976249009370804,
                1.026722109784414e-34,
                0.023373296484351158,
                -0.036599282175302505,
                0.01727766916155815,
                0.02021712064743042,
                0.020409075543284416,
                -0.037756357342004776,
                -0.012711471877992153,
                0.02590291015803814,
                0.008263736963272095,
                0.005827976390719414,
                -0.024607041850686073
            ]
        },
        "Experience": {
            "name": "Operations manager, asset integrity specialist and CSWIP 3.4u certified inspection engineer with over 14 years of professional industry experience. Graduate of Robert Gordon University with a Bachelor of Arts with distinction. Implements strong leadership skills with particular focus on teamwork delivering improved safety, efficiency, and sustainability during the energy transition. ",
            "rep": [
                -0.04075157269835472,
                0.05171026289463043,
                -0.017406312748789787,
                0.005707500036805868,
                0.010485208593308926,
                -0.0022959704510867596,
                0.002663938794285059,
                0.015831466764211655,
                -0.02623581886291504,
                -0.038377389311790466,
                0.03446643054485321,
                0.03149456903338432,
                0.025224264711141586,
                0.026901014149188995,
                0.05559777468442917,
                0.02617277018725872,
                0.008030882105231285,
                0.012591146863996983,
                -0.004286996088922024,
                0.0009001775179058313,
                -0.03887792304158211,
                0.017922673374414444,
                -0.035129182040691376,
                0.021001523360610008,
                -0.06908868998289108,
                -0.021058691665530205,
                -0.004167720675468445,
                0.011486833915114403,
                0.04856431111693382,
                0.018884673714637756,
                0.0494096539914608,
                0.014063057489693165,
                -0.007360116578638554,
                -0.014514884911477566,
                1.8577501350591774e-06,
                0.018971409648656845,
                -0.052019450813531876,
                -0.012166032567620277,
                0.0268432404845953,
                -0.027035340666770935,
                -0.0009926434140652418,
                -0.02247445099055767,
                -0.008966171182692051,
                0.015929296612739563,
                -0.010906786657869816,
                0.06567772477865219,
                0.05124153569340706,
                0.00800748635083437,
                -0.002739456482231617,
                -0.023661481216549873,
                0.003532337723299861,
                0.04066391661763191,
                -0.09126730263233185,
                -0.027233680710196495,
                -0.03533687815070152,
                -0.05857015401124954,
                0.001207089051604271,
                0.013103078119456768,
                0.004402986727654934,
                0.006470439024269581,
                -0.003629666054621339,
                0.021682431921362877,
                -0.028698809444904327,
                -0.002441072603687644,
                0.06358429044485092,
                0.02437727153301239,
                0.012471761554479599,
                0.002788524143397808,
                0.01561242900788784,
                -0.005532285664230585,
                0.03819167986512184,
                -0.012351459823548794,
                -0.008868138305842876,
                0.03701749071478844,
                -0.030236616730690002,
                0.0014598181005567312,
                0.02644205279648304,
                -0.01615671068429947,
                -0.025664949789643288,
                -0.007846198976039886,
                -0.08191264420747757,
                0.06761688739061356,
                -0.030538666993379593,
                -0.037965163588523865,
                -0.01314829383045435,
                0.07543227821588516,
                -0.03125626966357231,
                -0.006720604375004768,
                0.05214056745171547,
                0.06434228271245956,
                0.033350870013237,
                0.00441756471991539,
                0.023500574752688408,
                0.033138781785964966,
                -0.040046077221632004,
                -0.014344729483127594,
                0.0718463659286499,
                0.03301098197698593,
                -0.0007441810448653996,
                0.03974457085132599,
                -0.004230084363371134,
                0.032537076622247696,
                0.05042991414666176,
                0.06356079876422882,
                -0.03721962496638298,
                0.07705117762088776,
                0.045715879648923874,
                0.016056908294558525,
                -0.03972577676177025,
                -0.0020682986360043287,
                -0.019670136272907257,
                -0.019651291891932487,
                -0.009523050859570503,
                -0.06499099731445312,
                -0.010816738940775394,
                0.042494095861911774,
                0.005941377952694893,
                0.014906543307006359,
                -0.031400810927152634,
                0.027189768850803375,
                0.07916487008333206,
                0.040942948311567307,
                0.005054965149611235,
                0.049825944006443024,
                0.013202705420553684,
                0.02745559811592102,
                0.009347363375127316,
                -0.01252159383147955,
                0.019667232409119606,
                0.020715471357107162,
                -0.020620515570044518,
                -0.011890960857272148,
                -0.01141149178147316,
                0.013977744616568089,
                0.02523919567465782,
                0.011499084532260895,
                0.004669737070798874,
                -0.048850707709789276,
                -0.0355728417634964,
                -0.01885554939508438,
                0.014161482453346252,
                0.010170386172831059,
                -0.0312892347574234,
                0.054010674357414246,
                -0.028474930673837662,
                -0.05056902766227722,
                0.0029798608738929033,
                -0.024230176582932472,
                -0.007025205064564943,
                -0.015932444483041763,
                0.010613090358674526,
                -0.048982806503772736,
                -0.021092519164085388,
                -0.0013868502574041486,
                0.02452171966433525,
                -0.012570506893098354,
                0.03163738176226616,
                -0.029164806008338928,
                -0.021606821566820145,
                0.0261523500084877,
                0.011961393058300018,
                -0.023617707192897797,
                -0.013736037537455559,
                0.035268038511276245,
                -0.02885506860911846,
                0.005607541184872389,
                0.04406845569610596,
                -0.029555274173617363,
                -0.027879875153303146,
                -0.000695932365488261,
                0.02319571003317833,
                -0.016508715227246284,
                -0.0013934643939137459,
                0.02157345600426197,
                0.005416078958660364,
                -0.05759537220001221,
                0.030621321871876717,
                -0.05516191944479942,
                0.014944670721888542,
                0.0631774365901947,
                -0.0010250076884403825,
                -0.19085492193698883,
                0.03944041579961777,
                -0.010297619737684727,
                0.02689102105796337,
                0.0015979782911017537,
                -0.018421178683638573,
                0.04063824191689491,
                -0.03300512582063675,
                -0.03269892558455467,
                -0.030977176502346992,
                0.02916678413748741,
                -0.05461930111050606,
                0.04016460105776787,
                8.536432142136618e-05,
                0.02609872817993164,
                -0.049994226545095444,
                -0.04433026537299156,
                -0.002017805352807045,
                0.03331229090690613,
                0.0030676915775984526,
                -0.0471387580037117,
                -0.03962818533182144,
                0.07657109200954437,
                0.0018395055085420609,
                -0.004734321963042021,
                -0.017475832253694534,
                -0.04891366884112358,
                -0.020835041999816895,
                -0.00754990940913558,
                0.05781252309679985,
                -0.027659352868795395,
                0.03670749440789223,
                0.04996001347899437,
                -0.041805200278759,
                -0.028533948585391045,
                0.03754974901676178,
                0.009386652149260044,
                -0.02154053933918476,
                -0.011895677074790001,
                -0.03462342917919159,
                -0.028586000204086304,
                -0.047579165548086166,
                0.017308896407485008,
                0.01250274758785963,
                -0.056730903685092926,
                -0.017742663621902466,
                0.028466802090406418,
                -0.04770316556096077,
                0.06459883600473404,
                -0.006029379088431597,
                -0.008037032559514046,
                -0.0030134646221995354,
                -0.011119864881038666,
                -0.03830927610397339,
                0.022553222253918648,
                0.02404485084116459,
                0.11039084941148758,
                0.05374660715460777,
                0.00014788525004405528,
                -0.024136759340763092,
                0.03302524611353874,
                0.0027653544675558805,
                0.015843134373426437,
                0.0026066815480589867,
                -0.030412612482905388,
                -0.05147881805896759,
                -0.001376504311338067,
                0.004838142078369856,
                0.011082332581281662,
                0.004008051473647356,
                -0.04565615579485893,
                0.03349749371409416,
                0.04193945229053497,
                -0.02749842032790184,
                0.0008433773182332516,
                -0.02020627073943615,
                0.038329385221004486,
                -0.04054153710603714,
                -0.023101795464754105,
                -0.020237227901816368,
                -0.049930915236473083,
                -0.03711239993572235,
                -0.030486194416880608,
                0.036446064710617065,
                0.02874564379453659,
                0.021579908207058907,
                -0.0342499315738678,
                0.011434209533035755,
                0.001206597895361483,
                0.026239875704050064,
                0.03749584034085274,
                0.015797868371009827,
                -0.020698029547929764,
                -0.02427235059440136,
                0.0017536393133923411,
                -0.01723436824977398,
                0.03348412364721298,
                -0.059101738035678864,
                -0.004075342323631048,
                0.03041676990687847,
                -0.0455557256937027,
                -0.032543860375881195,
                0.012485726736485958,
                -0.02678370475769043,
                0.05115929991006851,
                0.04513726010918617,
                -0.01614866778254509,
                -0.110463447868824,
                0.051565419882535934,
                0.03136127442121506,
                -0.003942838404327631,
                0.04543142765760422,
                -0.015449457801878452,
                -0.03399260342121124,
                0.021190915256738663,
                0.005747385788708925,
                0.033138979226350784,
                0.024090874940156937,
                0.09537971764802933,
                -0.01818876527249813,
                -0.03750235214829445,
                -0.003531350987032056,
                -0.008023053407669067,
                7.300786819541827e-05,
                -0.009572132490575314,
                0.047286055982112885,
                0.02756456844508648,
                -0.041286926716566086,
                -0.012888658791780472,
                -0.011780351400375366,
                0.0054740700870752335,
                0.049749262630939484,
                -0.005843408405780792,
                0.07266677916049957,
                -0.01779325306415558,
                0.011723051778972149,
                -0.03502299264073372,
                0.011423623189330101,
                0.04120195284485817,
                0.02342751994729042,
                0.03406078740954399,
                -0.023555027320981026,
                -0.05896763876080513,
                0.0048812865279614925,
                -0.0227292962372303,
                -0.06435258686542511,
                0.008316527120769024,
                0.02454998716711998,
                -0.07276340574026108,
                0.012655600905418396,
                0.017107978463172913,
                -0.024954890832304955,
                0.009361929260194302,
                0.02988595701754093,
                -0.07567247003316879,
                0.004797238856554031,
                -0.09244595468044281,
                -0.02404508739709854,
                0.0035889088176190853,
                -0.0325767882168293,
                -0.010501318611204624,
                -0.037318963557481766,
                0.04548238590359688,
                0.0014898247318342328,
                0.00313983834348619,
                0.03559345006942749,
                0.01794586516916752,
                -0.04164009541273117,
                0.021384689956903458,
                0.03703160583972931,
                0.06620065867900848,
                -0.03840186819434166,
                9.577078890288249e-05,
                -0.01587740145623684,
                0.02480633370578289,
                0.0006976465228945017,
                0.035448264330625534,
                0.03258818760514259,
                -0.011793194338679314,
                -0.015305762179195881,
                0.0014125817688181996,
                0.04938545078039169,
                0.0002525125164538622,
                -0.06520986557006836,
                -0.00904042273759842,
                0.05755330249667168,
                -0.009614325128495693,
                0.014713197946548462,
                -0.005851162131875753,
                -0.009204636327922344,
                -0.011425080709159374,
                -0.04590432718396187,
                -0.018878258764743805,
                -0.028841285035014153,
                0.01502006035298109,
                0.008738948963582516,
                0.0768011212348938,
                -0.027160776779055595,
                -0.007906930521130562,
                0.0728011354804039,
                -0.02295435220003128,
                0.050070326775312424,
                0.021721141412854195,
                0.03654128313064575,
                -0.052591003477573395,
                -0.038899634033441544,
                -0.026999136433005333,
                0.05816370248794556,
                0.009661299176514149,
                -0.0024056825786828995,
                0.037385422736406326,
                -0.06155064329504967,
                -0.0018090055091306567,
                -0.0007555568590760231,
                -0.0019020470790565014,
                -0.0377659872174263,
                -0.01477847620844841,
                -0.033765051513910294,
                0.01926356367766857,
                0.028104208409786224,
                0.08503970503807068,
                -0.047603707760572433,
                0.014350327663123608,
                -0.00396766746416688,
                -0.04652426019310951,
                0.09014123678207397,
                -0.03869161382317543,
                -0.002042852807790041,
                -0.013197665102779865,
                0.02937082201242447,
                -0.021090060472488403,
                0.016726503148674965,
                -0.004629652015864849,
                -0.060284484177827835,
                -0.08336295932531357,
                0.003965854179114103,
                0.0009258200880140066,
                0.056341543793678284,
                -0.03658205270767212,
                0.030942270532250404,
                0.02643662877380848,
                0.02749386802315712,
                -0.055548734962940216,
                0.03919189050793648,
                -0.037637848407030106,
                0.011245022527873516,
                0.03477579727768898,
                0.005005763377994299,
                -0.038300201296806335,
                -0.020495332777500153,
                -0.03689596801996231,
                -0.04470216482877731,
                0.030101219192147255,
                0.01164150144904852,
                -0.033009037375450134,
                -0.017893757671117783,
                0.01833002269268036,
                0.011439631693065166,
                0.00021886499598622322,
                -0.012828809209167957,
                0.051441337913274765,
                0.03350354731082916,
                0.016108036041259766,
                -0.04837041348218918,
                0.0007582378457300365,
                -0.014349094592034817,
                -0.019679250195622444,
                -0.018967662006616592,
                -0.04255852475762367,
                0.10484638810157776,
                0.001678627566434443,
                -0.009979047812521458,
                -0.03867071866989136,
                -0.001910284860059619,
                -0.01172331627458334,
                -0.030740471556782722,
                0.0009303868864662945,
                -0.019233889877796173,
                0.034424856305122375,
                0.025194868445396423,
                0.04926028847694397,
                -0.018795693293213844,
                -0.001220574020408094,
                0.025652218610048294,
                -0.03259117528796196,
                0.023478735238313675,
                -0.06860103458166122,
                -0.0031116148456931114,
                -0.0685606300830841,
                -0.014630110003054142,
                -0.004488181322813034,
                -0.021233858540654182,
                -0.014991192147135735,
                0.018931055441498756,
                -0.0413404256105423,
                -0.003702693386003375,
                0.05734219774603844,
                -0.008105587214231491,
                0.02508051134645939,
                -0.012415311299264431,
                -0.037520840764045715,
                0.030829597264528275,
                -0.0011784476228058338,
                0.0008389005088247359,
                -0.00969713181257248,
                0.0360516756772995,
                0.010918688960373402,
                -0.011674685403704643,
                0.031602803617715836,
                0.017371930181980133,
                -0.05167441815137863,
                0.002880504820495844,
                0.04523966461420059,
                0.07659436762332916,
                0.04536627233028412,
                -0.01862316206097603,
                0.030621029436588287,
                -0.06140287220478058,
                -0.032004185020923615,
                -0.07073156535625458,
                0.006745519116520882,
                0.03567937761545181,
                -0.022684773430228233,
                0.016916725784540176,
                -0.031103936955332756,
                0.04048493504524231,
                -0.008296247571706772,
                -0.005246191285550594,
                0.01736973412334919,
                -0.04448443278670311,
                0.00799934659153223,
                -0.08515989035367966,
                0.023670019581913948,
                0.026599671691656113,
                -0.005777753423899412,
                0.0010250487830489874,
                0.022556252777576447,
                0.024409474804997444,
                -0.05437517538666725,
                0.03299671411514282,
                -0.001313688582740724,
                -0.006043904926627874,
                -0.002456257352605462,
                -0.0011583724990487099,
                -0.02760269306600094,
                0.04329797998070717,
                0.03721149265766144,
                -0.02167533151805401,
                0.03543787822127342,
                0.010072117671370506,
                -0.007563866674900055,
                -0.07919152081012726,
                0.0015798462554812431,
                -0.03132476285099983,
                0.0013389707310125232,
                0.042297862470149994,
                0.030311819165945053,
                0.003140260698273778,
                0.0012591765262186527,
                -0.05390210077166557,
                0.04398685321211815,
                0.026831556111574173,
                0.06409574300050735,
                0.012791002169251442,
                -0.022163869813084602,
                -0.020304929465055466,
                0.00710599310696125,
                0.0001140335516538471,
                -0.051733002066612244,
                0.027541695162653923,
                -0.09031358361244202,
                0.030461929738521576,
                0.02254963107407093,
                0.04246014356613159,
                -5.7146139913027176e-33,
                0.014213520102202892,
                -0.08156517893075943,
                -0.0005854706396348774,
                0.03709860146045685,
                -0.03281042352318764,
                -0.04392042011022568,
                0.06864669919013977,
                -0.013100601732730865,
                -0.03622619807720184,
                -0.05404919758439064,
                -0.016613474115729332,
                -0.0012967651709914207,
                0.029870908707380295,
                -0.01715409941971302,
                0.019640469923615456,
                0.07933243364095688,
                0.059225358068943024,
                0.0021530943922698498,
                -0.03311106190085411,
                -0.019265009090304375,
                -0.026804404333233833,
                0.03147958219051361,
                0.04056204855442047,
                0.016040069982409477,
                0.025432240217924118,
                0.02390715666115284,
                -0.04657561331987381,
                0.067268006503582,
                0.016308244317770004,
                -0.03783193230628967,
                -0.00672814529389143,
                0.009944094344973564,
                0.010389106348156929,
                -0.00402291864156723,
                -0.00037084665382280946,
                0.0213298536837101,
                -0.059610676020383835,
                -0.004693771246820688,
                -0.048115916550159454,
                0.019475523382425308,
                -0.04046231508255005,
                -0.031587496399879456,
                0.0651339665055275,
                -0.015593087300658226,
                -0.015285689383745193,
                -0.036790840327739716,
                -0.0061236219480633736,
                -0.006832669954746962,
                0.10092882066965103,
                -0.0005291966954246163,
                -0.009253613650798798,
                -0.014180845580995083,
                -0.032827939838171005,
                0.042863741517066956,
                -0.045524898916482925,
                0.03993235155940056,
                0.014695881865918636,
                -0.004982371348887682,
                -0.037647660821676254,
                -0.017779380083084106,
                0.006601186003535986,
                0.007414066698402166,
                0.0324442982673645,
                -0.02725772187113762,
                -0.049227502197027206,
                0.04021856561303139,
                -0.013000121340155602,
                0.08675501495599747,
                0.048783354461193085,
                -0.053534772247076035,
                -0.020155660808086395,
                0.07095388323068619,
                -0.04087485000491142,
                -0.02473391778767109,
                -0.07713790237903595,
                -0.05270412191748619,
                -0.061569008976221085,
                0.02958187647163868,
                0.05203467234969139,
                0.0380484014749527,
                -0.03424984589219093,
                -0.02748735062777996,
                0.02715389057993889,
                0.016964660957455635,
                0.01469703670591116,
                -0.032240740954875946,
                -0.014332843944430351,
                0.002706678817048669,
                0.004467410035431385,
                0.028002485632896423,
                -0.011720558628439903,
                0.0309939906001091,
                -0.043339792639017105,
                0.0067352792248129845,
                0.08531028032302856,
                0.013325394131243229,
                0.001442526001483202,
                -0.04116148129105568,
                -0.009509021416306496,
                0.03200531005859375,
                -0.0069281235337257385,
                0.05711766704916954,
                -0.010564963333308697,
                0.05337337777018547,
                0.03182780370116234,
                0.0020717144943773746,
                -0.019746985286474228,
                0.02601749449968338,
                -0.02935195341706276,
                0.004433630499988794,
                0.0008969872142188251,
                -0.013262839987874031,
                -0.003822073107585311,
                -0.04651623219251633,
                -0.008687111549079418,
                -0.025864996016025543,
                0.04144444689154625,
                -0.030222827568650246,
                -0.053499236702919006,
                -0.012543827295303345,
                0.052129656076431274,
                -0.01868434064090252,
                -0.005574220325797796,
                0.01856847107410431,
                0.00885218195617199,
                -0.026453623548150063,
                0.0197772104293108,
                -0.03678160533308983,
                -0.07367526739835739,
                0.007746247109025717,
                -0.01010830421000719,
                0.041132573038339615,
                2.44661094939147e-07,
                -0.009474525228142738,
                0.007236436475068331,
                0.017201445996761322,
                0.040406543761491776,
                0.006886841729283333,
                -0.06272602081298828,
                -0.05639040842652321,
                -0.0033201652113348246,
                0.012416422367095947,
                0.00029728640220128,
                0.01072633359581232,
                -0.036532700061798096,
                -0.020200058817863464,
                -0.06945189833641052,
                0.002283883048221469,
                -0.09433755278587341,
                0.09521832317113876,
                -0.010658281855285168,
                -0.02050393633544445,
                -0.050876062363386154,
                0.07907649874687195,
                -0.007759472355246544,
                0.00905146636068821,
                0.007642013486474752,
                0.013064821250736713,
                -0.019748441874980927,
                0.027687236666679382,
                -0.0171362217515707,
                0.04396756365895271,
                0.006339714862406254,
                0.08648234605789185,
                -0.029001593589782715,
                0.05151607468724251,
                0.06266826391220093,
                0.021481696516275406,
                0.022915203124284744,
                0.025213025510311127,
                0.04522192105650902,
                0.02655455283820629,
                -0.020729783922433853,
                0.0033501158468425274,
                -0.05330721288919449,
                0.017242003232240677,
                -0.005579883232712746,
                0.023089919239282608,
                -0.01431126706302166,
                0.029699979349970818,
                0.06307338923215866,
                -0.10832344740629196,
                -0.032457977533340454,
                -0.07940393686294556,
                0.0351862870156765,
                -0.00022856595751363784,
                0.004302599001675844,
                0.0034016319550573826,
                0.006573041435331106,
                -0.020383086055517197,
                0.09262529760599136,
                0.05831220746040344,
                -0.014205138199031353,
                -0.026411032304167747,
                -0.05233190581202507,
                0.004194153472781181,
                -0.018001727759838104,
                0.06558314710855484,
                0.01605599746108055,
                -0.07054046541452408,
                2.512325301294934e-34,
                -0.023803627118468285,
                0.0011709175305441022,
                -0.0009775286307558417,
                -0.013334933668375015,
                0.030158376321196556,
                -0.025623194873332977,
                -0.05464579910039902,
                0.001578665105625987,
                -0.012346460483968258,
                -0.04246893152594566,
                -0.05280395597219467
            ]
        },
        "Freelance": "Staff",
        "Id": "0accc01e-4162-4839-af92-229f521e29c8",
        "Industry": {
            "name": "\tMaintenance & Inspection",
            "rep": [
                -0.020227128639817238,
                0.017378531396389008,
                -0.02882552333176136,
                0.028768721967935562,
                0.0165055301040411,
                0.019539395347237587,
                -0.009597881697118282,
                0.012005461379885674,
                0.02222522348165512,
                -0.03325718641281128,
                0.0822586938738823,
                0.015510251745581627,
                0.038436032831668854,
                0.01256328634917736,
                0.010215675458312035,
                -0.012170248664915562,
                -0.014418668113648891,
                0.006730065681040287,
                -0.08842401206493378,
                -0.02054251730442047,
                -0.031280968338251114,
                0.03971846029162407,
                -0.045952532440423965,
                -0.023361025378108025,
                -0.013474743813276291,
                -0.03983895480632782,
                0.028698105365037918,
                -0.05676068365573883,
                0.061141569167375565,
                0.016364065930247307,
                0.0020153503865003586,
                0.01730559580028057,
                0.03585733100771904,
                0.03984227776527405,
                1.4171534985507606e-06,
                -0.00184826017357409,
                0.01848231814801693,
                -8.683005580678582e-05,
                0.0016490177949890494,
                0.029536452144384384,
                0.00042433966882526875,
                -0.07730157673358917,
                0.00868134293705225,
                0.03271092474460602,
                -0.025196874514222145,
                -0.023598764091730118,
                0.03346896171569824,
                -0.009536395780742168,
                0.004276643972843885,
                0.009199162945151329,
                -0.02259485051035881,
                0.055035196244716644,
                -0.07544602453708649,
                0.01344998087733984,
                -0.05222995951771736,
                -0.05840372294187546,
                -0.001146218040958047,
                0.06322084367275238,
                0.020979857072234154,
                0.016788776963949203,
                -0.033244211226701736,
                0.018485575914382935,
                0.04399449750781059,
                -0.011364424601197243,
                0.043642327189445496,
                0.022483503445982933,
                0.042719319462776184,
                0.016481515020132065,
                0.018600428476929665,
                -0.04289894178509712,
                -0.00019008548406418413,
                0.023762915283441544,
                0.01642807573080063,
                0.05094795301556587,
                -0.035374194383621216,
                -0.02765011414885521,
                0.02811662293970585,
                0.007709102239459753,
                -0.030525218695402145,
                0.0026980366092175245,
                -0.018534958362579346,
                -0.00978486705571413,
                0.014173777773976326,
                -0.009831459261476994,
                0.014371301978826523,
                0.0007368907099589705,
                -0.020653143525123596,
                0.018227368593215942,
                -0.015561486594378948,
                0.09325973689556122,
                -0.01429623644798994,
                -0.029950786381959915,
                -0.019356178119778633,
                0.054329223930835724,
                0.0020404693204909563,
                -0.038442764431238174,
                0.003387064440175891,
                -0.005859083030372858,
                0.024417473003268242,
                0.04972762614488602,
                -0.09948325157165527,
                0.012396048754453659,
                -0.05606468766927719,
                0.03800344467163086,
                0.0024443131405860186,
                0.01893235556781292,
                0.03419334813952446,
                -0.0007885126979090273,
                -0.009053828194737434,
                0.013806931674480438,
                -0.00522891478613019,
                -0.03552410006523132,
                0.009552288800477982,
                0.04726925864815712,
                0.027934016659855843,
                -0.013552926480770111,
                0.012716376222670078,
                0.028023313730955124,
                0.024309737607836723,
                0.003500852268189192,
                0.056560903787612915,
                0.007928096689283848,
                -0.03374651074409485,
                -0.005542741622775793,
                0.004035445395857096,
                -0.035635143518447876,
                -0.06341744214296341,
                0.01836569793522358,
                0.01088992040604353,
                -0.001959623768925667,
                0.01001815591007471,
                0.001064805081114173,
                0.017917459830641747,
                -0.009274574927985668,
                -0.0050845034420490265,
                -0.04825344681739807,
                -0.024192260578274727,
                -0.009642376564443111,
                -0.1008557677268982,
                -0.030095748603343964,
                0.02782629244029522,
                -0.004351089708507061,
                -0.04208264872431755,
                0.07109492272138596,
                0.0033468531910330057,
                -0.025942549109458923,
                -0.0232265442609787,
                0.05688066408038139,
                0.018461162224411964,
                -0.004366438370198011,
                -0.02911444380879402,
                -0.018036101013422012,
                -0.017966151237487793,
                -0.002760932082310319,
                -0.0018227812834084034,
                0.01636675000190735,
                0.09726539254188538,
                -0.017350513488054276,
                -0.033413294702768326,
                -0.02452634833753109,
                0.008076237514615059,
                0.019090741872787476,
                0.030231377109885216,
                -0.013472016900777817,
                -0.04009169340133667,
                0.013595571741461754,
                0.013620568439364433,
                -0.015394024550914764,
                -0.04738173633813858,
                0.016035322099924088,
                0.018620027229189873,
                0.015188798308372498,
                -0.019942685961723328,
                0.04546867311000824,
                -0.03363393619656563,
                -0.055549416691064835,
                0.0069867284037172794,
                -0.04319803789258003,
                -0.02907486818730831,
                0.03172161057591438,
                -0.03607375547289848,
                -0.07553493231534958,
                0.015065544284880161,
                -0.021724695339798927,
                -0.051481910049915314,
                0.023691464215517044,
                -0.03197980672121048,
                -0.020874107256531715,
                -0.031006211414933205,
                -0.03735791891813278,
                -0.029482480138540268,
                0.013069952838122845,
                0.004384902305901051,
                0.001930655911564827,
                0.057093311101198196,
                0.005203246604651213,
                -0.027422334998846054,
                -0.020820464938879013,
                0.0013440951006487012,
                0.0503600649535656,
                -0.015954770147800446,
                -0.07412945479154587,
                0.049875225871801376,
                0.12095177918672562,
                0.007889857515692711,
                -0.012070797383785248,
                -0.028466034680604935,
                0.03361539915204048,
                -0.0340774804353714,
                -0.0747896209359169,
                0.03094269335269928,
                0.013052006252110004,
                0.040108758956193924,
                -0.013101533986628056,
                -0.05492090806365013,
                -0.047821566462516785,
                0.04402129724621773,
                0.019146887585520744,
                -0.07672069221735,
                0.06636938452720642,
                -0.03954537585377693,
                -0.00987413339316845,
                -0.04557139426469803,
                0.009694259613752365,
                0.0528782419860363,
                -0.007195580285042524,
                -0.007880945689976215,
                0.03042716532945633,
                -0.07690057903528214,
                -0.04400795325636864,
                0.03323155641555786,
                0.012383577413856983,
                -0.00418042903766036,
                0.02825951762497425,
                0.0479087270796299,
                0.044798433780670166,
                0.0819028690457344,
                0.05105986446142197,
                0.07646429538726807,
                -0.012418788857758045,
                0.0001568446314195171,
                0.014858710579574108,
                -0.011902476660907269,
                -0.04202665388584137,
                0.007453154772520065,
                -0.04190732538700104,
                -0.025554455816745758,
                0.001448380877263844,
                0.0071290344931185246,
                0.04739132523536682,
                -0.01518173422664404,
                -0.055919453501701355,
                0.04053555428981781,
                -0.005271299742162228,
                -0.02144848182797432,
                0.01246293168514967,
                0.02284548059105873,
                0.06483151763677597,
                -0.015628192573785782,
                -0.068882517516613,
                0.029018044471740723,
                -0.024006135761737823,
                -0.014588098041713238,
                -0.007769027724862099,
                0.044319141656160355,
                -0.005261837970465422,
                0.04892079904675484,
                -0.07559036463499069,
                -0.02544292062520981,
                -0.01582619920372963,
                -0.019522590562701225,
                0.0037939962930977345,
                0.05619964376091957,
                0.010878026485443115,
                -0.007666994817554951,
                0.02244916558265686,
                0.008935854770243168,
                0.02085421048104763,
                -0.008861584588885307,
                0.031065745279192924,
                -0.013617047108709812,
                -0.04531063139438629,
                -0.026774877682328224,
                0.013110285624861717,
                0.030238023027777672,
                0.011681880801916122,
                0.0693892389535904,
                -0.00318822986446321,
                -0.1209615021944046,
                0.028206201270222664,
                0.011572195217013359,
                -0.017592575401067734,
                0.007224592380225658,
                -0.07531393319368362,
                -0.032987941056489944,
                -0.0009107165387831628,
                -0.022697163745760918,
                0.04786594957113266,
                -0.03460364416241646,
                -0.0029887896962463856,
                -0.00878582801669836,
                -0.022864684462547302,
                -0.0026444154791533947,
                -0.027663972228765488,
                0.0028074318543076515,
                -0.014599225483834743,
                0.08033647388219833,
                0.02514019049704075,
                0.0028827700298279524,
                0.008514435030519962,
                -0.03643826022744179,
                0.0020645882468670607,
                -0.0021754505578428507,
                0.01926359534263611,
                0.016979476436972618,
                0.0046758707612752914,
                0.03249349445104599,
                0.008873656392097473,
                -0.02518386021256447,
                0.1183668002486229,
                0.03636012598872185,
                0.012267258018255234,
                -0.030821222811937332,
                0.04322873428463936,
                -0.0021165888756513596,
                -0.017435751855373383,
                -0.0015961212338879704,
                0.003956519532948732,
                0.02491713874042034,
                -0.039034489542245865,
                -0.01379189919680357,
                0.04755903407931328,
                -0.038031768053770065,
                0.024844545871019363,
                0.013049932196736336,
                0.01573147252202034,
                -0.0013337396085262299,
                -0.06980081647634506,
                -0.057713475078344345,
                0.06414909660816193,
                0.03450338914990425,
                -0.04311607405543327,
                0.013976131565868855,
                -0.0019323867745697498,
                -0.014372365549206734,
                -0.012499005533754826,
                -0.025877991691231728,
                0.019691575318574905,
                -0.04189657047390938,
                0.006574434693902731,
                -0.014442483894526958,
                0.048546284437179565,
                0.029478393495082855,
                0.02315456047654152,
                -0.021800242364406586,
                -0.00024730642326176167,
                -0.040800731629133224,
                0.0027783771511167288,
                -0.028181951493024826,
                -0.0381973497569561,
                -0.02405817247927189,
                -0.021676920354366302,
                0.011854458600282669,
                0.02171817421913147,
                -0.006501055788248777,
                -0.02938111685216427,
                0.032259199768304825,
                0.07162968814373016,
                -0.039902765303850174,
                0.004946279339492321,
                -0.059871774166822433,
                0.02715390734374523,
                -0.07214778661727905,
                -0.016878589987754822,
                0.0017529792385175824,
                0.032658886164426804,
                0.02523477002978325,
                0.034301675856113434,
                -0.04416724666953087,
                -0.02346508391201496,
                0.026767032220959663,
                -0.004975711926817894,
                0.06255172193050385,
                0.06414137035608292,
                0.06426216661930084,
                0.01681717485189438,
                -0.018312498927116394,
                0.014426232315599918,
                0.032682523131370544,
                0.03843830153346062,
                -0.0025152000598609447,
                -0.02162834443151951,
                -0.11727157235145569,
                0.04648023098707199,
                0.0020348848775029182,
                -0.06518858671188354,
                0.021731778979301453,
                -0.012725679203867912,
                0.017502477392554283,
                0.03809914365410805,
                0.02306947112083435,
                0.016725603491067886,
                -0.04332537576556206,
                0.002359838457778096,
                -0.04057328402996063,
                -0.059994932264089584,
                0.076073557138443,
                -0.03832064941525459,
                0.006321810185909271,
                -0.052464962005615234,
                0.08590806275606155,
                -0.017286842688918114,
                0.013906835578382015,
                -0.015477478504180908,
                0.0262333732098341,
                -0.01297797355800867,
                -0.03318978101015091,
                0.028278591111302376,
                0.03078158013522625,
                -0.021435217931866646,
                0.03370536118745804,
                0.0029042207170277834,
                -0.02219732478260994,
                -0.026475567370653152,
                0.03687301650643349,
                0.02286113239824772,
                0.040002789348363876,
                0.030870739370584488,
                -0.0015746568096801639,
                0.0026869187131524086,
                -0.021819131448864937,
                -0.006678726524114609,
                -0.038460973650217056,
                0.04065898433327675,
                -0.053179897367954254,
                -0.013562574982643127,
                -0.023093411698937416,
                -0.043252747505903244,
                -0.026741666719317436,
                -0.05578744038939476,
                -0.044294971972703934,
                0.07467931509017944,
                0.038642510771751404,
                -0.0195724219083786,
                -0.08576332777738571,
                0.03502880781888962,
                -0.0019199717789888382,
                -0.0056466818787157536,
                -0.04636457562446594,
                -0.007991652935743332,
                0.07041922211647034,
                0.010786532424390316,
                -0.01763136498630047,
                -0.025377457961440086,
                0.03166215494275093,
                -0.04516403004527092,
                -0.07658390700817108,
                -0.008988420478999615,
                -0.011570455506443977,
                0.06716400384902954,
                0.06537211686372757,
                0.04020247980952263,
                -0.005419541150331497,
                -0.0704919844865799,
                0.06827186793088913,
                -0.10474416613578796,
                -0.029489172622561455,
                -0.10288772732019424,
                0.004327177535742521,
                -0.04088724032044411,
                -0.05111963301897049,
                0.024316180497407913,
                -0.0026206455659121275,
                0.0029376172460615635,
                0.0016478190664201975,
                -0.0190071202814579,
                0.009071554988622665,
                0.019160810858011246,
                -0.02072417363524437,
                0.02168450504541397,
                0.01924074813723564,
                -0.03663702309131622,
                0.029803628101944923,
                -0.00930952001363039,
                -0.011002535931766033,
                0.0036834203638136387,
                0.11598129570484161,
                -0.01952940598130226,
                -0.025363340973854065,
                0.10821705311536789,
                -0.012561609037220478,
                -0.07849471271038055,
                -0.04301426559686661,
                0.044251445680856705,
                0.00041486878762952983,
                0.025407575070858,
                0.013307749293744564,
                -0.06588233262300491,
                -0.05110282450914383,
                -0.0644824355840683,
                0.008446792140603065,
                0.028142094612121582,
                0.039796534925699234,
                0.02030997909605503,
                0.03153364360332489,
                -0.014498911798000336,
                0.06419525295495987,
                7.63419084250927e-05,
                0.0155860036611557,
                0.015460185706615448,
                -0.020609388127923012,
                -0.05044454708695412,
                0.017147084698081017,
                -0.011366351507604122,
                0.022006846964359283,
                0.020755531266331673,
                -0.0169539675116539,
                0.005619506351649761,
                0.017989136278629303,
                -0.01979435794055462,
                0.02600737102329731,
                -0.010503143072128296,
                0.029062485322356224,
                0.041108302772045135,
                0.07471393048763275,
                -0.021750645712018013,
                0.011092372238636017,
                0.019634606316685677,
                -0.0515165813267231,
                0.050138019025325775,
                -0.024007581174373627,
                0.0038442250806838274,
                -0.030850468203425407,
                0.015477446839213371,
                -0.034123264253139496,
                0.054885827004909515,
                0.024066442623734474,
                0.04891309514641762,
                0.01876077428460121,
                -0.009584326297044754,
                -0.011652602814137936,
                0.013887948356568813,
                -0.05674804374575615,
                0.030750267207622528,
                0.020166803151369095,
                -0.04585762694478035,
                0.011814843863248825,
                0.012551242485642433,
                0.010926594026386738,
                0.05133521556854248,
                -0.010488823987543583,
                -0.035372957587242126,
                -0.0077240969985723495,
                0.04129043594002724,
                -0.002159148221835494,
                -5.521990874821254e-33,
                -0.02435084991157055,
                -0.06308287382125854,
                -0.01726481318473816,
                -0.0019549159333109856,
                -0.025863591581583023,
                -0.015348069369792938,
                0.007926907390356064,
                -0.0032833272125571966,
                0.0035319591406732798,
                -0.03498195484280586,
                0.0025926558300852776,
                0.04381566867232323,
                0.02211366407573223,
                0.01657918281853199,
                0.0673430860042572,
                -0.020962689071893692,
                0.05273812264204025,
                0.006705982144922018,
                -0.030051596462726593,
                -0.02594458870589733,
                -0.027098258957266808,
                0.032860517501831055,
                0.05327808856964111,
                0.020185356959700584,
                -0.012794232927262783,
                0.03362826630473137,
                -0.02743823267519474,
                0.026814300566911697,
                -0.007107995916157961,
                -0.020422697067260742,
                -0.03127697855234146,
                -0.030705543234944344,
                0.03360792621970177,
                -0.002970288507640362,
                0.01314401812851429,
                0.0523829311132431,
                -0.032031673938035965,
                -0.03686503320932388,
                0.020867682993412018,
                0.020643413066864014,
                -0.06606873124837875,
                -0.06641526520252228,
                0.04343599081039429,
                7.349652150878683e-05,
                -0.007262290455400944,
                -0.04518084228038788,
                -0.006884349510073662,
                -0.014863829128444195,
                0.04410746321082115,
                0.00607119919732213,
                0.012775004841387272,
                0.013123959302902222,
                -0.0051524885930120945,
                -0.05316907539963722,
                0.03249579295516014,
                0.015826087445020676,
                0.03872448951005936,
                0.018566245213150978,
                0.02912607602775097,
                -0.04330446943640709,
                0.03770560026168823,
                0.02275942824780941,
                -0.01955067366361618,
                -0.024608086794614792,
                -0.027481399476528168,
                -0.01453603059053421,
                -0.08782327175140381,
                -0.02374839037656784,
                0.017048779875040054,
                -0.02564065344631672,
                -0.0246454868465662,
                0.06963620334863663,
                -0.08281630277633667,
                -0.0898328348994255,
                0.010384622029960155,
                -0.020289689302444458,
                -0.021896079182624817,
                0.02797561138868332,
                0.03356438875198364,
                0.07450009882450104,
                -0.03683565929532051,
                -0.007512614130973816,
                0.011347929015755653,
                -0.02919089049100876,
                -0.030228491872549057,
                -0.015159777365624905,
                -0.01782682165503502,
                0.06080527603626251,
                -0.006454319227486849,
                0.082953080534935,
                -0.02950436994433403,
                0.020107554271817207,
                -0.042495619505643845,
                0.0180953498929739,
                0.04485251381993294,
                0.002414521062746644,
                0.011966860853135586,
                -0.023830953985452652,
                -0.0297667495906353,
                0.0074582952074706554,
                0.01801280304789543,
                0.022043418139219284,
                -0.06179632991552353,
                0.06160470470786095,
                0.005575091578066349,
                0.003018272342160344,
                -0.017630470916628838,
                -0.0034135503228753805,
                0.00440550409257412,
                0.03400839865207672,
                0.006832750979810953,
                0.01368509978055954,
                -0.02130894921720028,
                0.008173256181180477,
                0.00820513628423214,
                0.026924045756459236,
                0.02014758251607418,
                0.030303286388516426,
                -0.020855309441685677,
                -0.012034271843731403,
                0.016078613698482513,
                0.026747550815343857,
                0.0350702740252018,
                -0.008105892688035965,
                -0.008684533648192883,
                -0.026190461590886116,
                0.02246055193245411,
                -0.059690218418836594,
                0.002489798003807664,
                0.01500589307397604,
                -0.029271988198161125,
                0.029476219788193703,
                1.89202495448626e-07,
                0.0012976055731996894,
                0.029053524136543274,
                0.01826290600001812,
                0.02459186688065529,
                0.024275491014122963,
                -0.025113385170698166,
                0.02971055544912815,
                -0.010907967574894428,
                -0.052795082330703735,
                0.021361472085118294,
                0.025279561057686806,
                -0.05352963134646416,
                -0.02242838591337204,
                -0.04243075102567673,
                0.017746254801750183,
                -0.018153291195631027,
                -0.0026909708976745605,
                0.006599192973226309,
                -0.016515593975782394,
                -0.011543423868715763,
                -0.019857637584209442,
                -0.003672376275062561,
                -0.04786517843604088,
                0.010668749921023846,
                0.005779089871793985,
                -0.06526311486959457,
                0.03977479413151741,
                -0.03085825964808464,
                0.020902328193187714,
                -0.013002627529203892,
                0.10436075925827026,
                0.09362228959798813,
                0.04294406622648239,
                0.019130034372210503,
                0.03230636194348335,
                -0.02138274721801281,
                0.017997533082962036,
                0.031186185777187347,
                0.02440129779279232,
                -0.024029776453971863,
                -0.013563685119152069,
                -0.04745350405573845,
                -0.007724148221313953,
                0.016657045111060143,
                0.03223709017038345,
                0.025300581008195877,
                0.009792332537472248,
                0.019250968471169472,
                -0.05505286902189255,
                -0.029837792739272118,
                -0.05295190587639809,
                0.02932768128812313,
                -0.03357219696044922,
                0.02101903222501278,
                0.01526512298732996,
                -0.00512451259419322,
                -0.006671038456261158,
                0.05518731847405434,
                0.07514087855815887,
                0.03373575955629349,
                -0.03985891863703728,
                -0.0322955921292305,
                0.012288859114050865,
                -0.004933637101203203,
                -0.0012770014582201838,
                -0.03128044307231903,
                -0.10070372372865677,
                7.297505090131373e-35,
                -0.0025124060921370983,
                -0.019662847742438316,
                -0.06302458047866821,
                -0.007245934568345547,
                0.004346907138824463,
                0.005028041545301676,
                -0.013488741591572762,
                -0.03661224991083145,
                0.002557437401264906,
                -0.05854979529976845,
                -0.0009707206045277417
            ]
        },
        "Location": "Aberdeen / Southampton",
        "Remuneration": 40.86538461538461,
        "Services Required": {
            "name": "Operations Support Manager",
            "rep": [
                0.024744590744376183,
                -0.033995360136032104,
                -0.025869090110063553,
                -0.008741531521081924,
                0.015793681144714355,
                0.0511283241212368,
                0.029654307290911674,
                0.005272834561765194,
                -0.06226406991481781,
                0.01782688871026039,
                0.016688711941242218,
                0.011357731185853481,
                -0.01125430129468441,
                0.06675224751234055,
                -0.006028103642165661,
                -0.019132090732455254,
                -0.0008203468169085681,
                -0.005224291235208511,
                -0.041661232709884644,
                0.003444893751293421,
                -0.0422719269990921,
                0.037941884249448776,
                0.017493514344096184,
                0.019794391468167305,
                0.0006498967995867133,
                -0.01790638454258442,
                0.013435439206659794,
                0.011884846724569798,
                0.014763904735445976,
                0.05233509838581085,
                0.06048831343650818,
                -0.020253434777259827,
                0.0296892412006855,
                -0.033368248492479324,
                1.397336632180668e-06,
                0.028568917885422707,
                -0.007928784936666489,
                0.014719495549798012,
                -0.07135823369026184,
                0.01451179850846529,
                -0.005799599923193455,
                -0.03812635317444801,
                -0.015713678672909737,
                0.03156876191496849,
                0.013971345499157906,
                0.09864892810583115,
                0.03636786714196205,
                -0.0024774225894361734,
                -0.030533526092767715,
                -0.018188869580626488,
                -0.0018393126083537936,
                0.04522838443517685,
                -0.08876322954893112,
                -0.023278050124645233,
                -0.02886052429676056,
                -0.07383447885513306,
                -0.0015500769950449467,
                0.016593774780631065,
                0.02192567102611065,
                0.01976075954735279,
                -0.021647535264492035,
                0.00439278781414032,
                -0.02852424792945385,
                -0.000900966755580157,
                0.005139033310115337,
                0.0303203072398901,
                0.06889310479164124,
                0.03582216799259186,
                0.03319217637181282,
                -0.04493407532572746,
                0.03652495518326759,
                0.029257485643029213,
                0.004608577582985163,
                0.06750527769327164,
                0.00854297261685133,
                -0.008587077260017395,
                -0.015475292690098286,
                -0.021618319675326347,
                -0.008775196969509125,
                -0.025270268321037292,
                -0.011401680298149586,
                0.050327740609645844,
                -0.0049673765897750854,
                0.018320217728614807,
                -0.035370975732803345,
                0.035831987857818604,
                -0.056025926023721695,
                -0.04549922049045563,
                -0.006976601202040911,
                0.04699282720685005,
                -0.03232806548476219,
                0.02745465375483036,
                0.006491335574537516,
                0.034115783870220184,
                -0.08044533431529999,
                -0.006580564193427563,
                0.02682146616280079,
                -0.018925782293081284,
                -0.04290112480521202,
                -0.03811114281415939,
                0.03217247873544693,
                -0.002243651309981942,
                -0.03919975832104683,
                0.03344842046499252,
                0.0003688994620461017,
                0.01599552109837532,
                0.021097145974636078,
                0.03903990611433983,
                0.007199530024081469,
                0.03131518512964249,
                0.023437688127160072,
                -0.02311822585761547,
                0.0018401819979771972,
                0.012480594217777252,
                -0.011976022273302078,
                0.009724408388137817,
                -0.01634209416806698,
                0.04990759491920471,
                -0.022378001362085342,
                0.07892094552516937,
                0.037907663732767105,
                0.004838149528950453,
                -0.017571616917848587,
                0.018554864451289177,
                -0.03926858678460121,
                -0.028029760345816612,
                -0.031038612127304077,
                0.026969561353325844,
                0.02044500783085823,
                0.03895726427435875,
                -0.00582734402269125,
                0.009719486348330975,
                -0.005973652470856905,
                0.01755557395517826,
                0.0081332353875041,
                0.01731112040579319,
                0.008631783537566662,
                0.034612804651260376,
                -0.04896124079823494,
                0.004695577081292868,
                0.002558821113780141,
                -0.0036731979344040155,
                -0.10395875573158264,
                0.04107344150543213,
                -0.011090019717812538,
                -0.005835393443703651,
                -0.04593531787395477,
                0.0522303581237793,
                0.02503216452896595,
                0.028748245909810066,
                -0.01740323007106781,
                -0.06150197237730026,
                -0.010255146771669388,
                0.03228588402271271,
                0.0684097409248352,
                0.02214466594159603,
                0.05709680914878845,
                -0.05152512341737747,
                -0.045790642499923706,
                0.02501906268298626,
                0.014870410785079002,
                0.01216823235154152,
                0.009084728546440601,
                0.05423087626695633,
                -0.07780830562114716,
                0.0020793366711586714,
                0.044666584581136703,
                -0.00111273059155792,
                -0.08174476772546768,
                -0.036773793399333954,
                0.02171420119702816,
                -0.005204616114497185,
                -0.00847158394753933,
                0.03512027487158775,
                -0.022759156301617622,
                -0.09632247686386108,
                0.036655932664871216,
                0.01605304330587387,
                0.016837066039443016,
                0.006464013364166021,
                -0.030477231368422508,
                -0.10421701520681381,
                0.03516092896461487,
                0.011992411687970161,
                0.026894884184002876,
                0.028831252828240395,
                -0.07703154534101486,
                0.000897032383363694,
                -0.03271346911787987,
                0.009442309848964214,
                0.015089237131178379,
                -0.002736962866038084,
                -0.0981142595410347,
                0.03176706284284592,
                0.07889257371425629,
                -0.0008816516492515802,
                -0.06494372338056564,
                -0.019344722852110863,
                -0.03452508524060249,
                0.04692285135388374,
                -0.005135269369930029,
                -0.03798230364918709,
                -0.07284457981586456,
                0.054442763328552246,
                0.0336514450609684,
                -0.06787852197885513,
                -0.007864324375987053,
                -0.03657699376344681,
                -0.056456711143255234,
                0.013045639730989933,
                0.047834981232881546,
                0.00556256202980876,
                -0.02405221201479435,
                0.02597062475979328,
                -0.006287068128585815,
                0.044966135174036026,
                0.030494265258312225,
                0.016292493790388107,
                0.001954994397237897,
                0.01882084272801876,
                -0.020805248990654945,
                0.04483505338430405,
                -0.041966959834098816,
                -0.004566877614706755,
                0.001630833256058395,
                -0.05829134210944176,
                -0.014149636961519718,
                0.02228492684662342,
                -0.01722250133752823,
                0.027599705383181572,
                -0.0007623988785780966,
                0.003798447782173753,
                0.03089822642505169,
                0.011704199947416782,
                0.025842446833848953,
                0.012604142539203167,
                0.01186518743634224,
                0.05377348139882088,
                0.0020319942850619555,
                0.0795603021979332,
                -0.020386600866913795,
                0.00028653425397351384,
                0.0039796712808310986,
                -0.03170220926403999,
                0.05108635500073433,
                -0.01816560886800289,
                -0.01765412464737892,
                -0.019619889557361603,
                -0.017844462767243385,
                0.004239901900291443,
                0.005993048660457134,
                -0.013538980856537819,
                0.01826254278421402,
                0.023541463539004326,
                0.03208627924323082,
                0.014775466173887253,
                -0.04534045606851578,
                0.026476623490452766,
                -0.05269739031791687,
                0.0034064338542521,
                -0.004043081775307655,
                -0.053198155015707016,
                -0.02399102970957756,
                0.0072279334999620914,
                0.02458535134792328,
                0.03268353268504143,
                -0.04083358123898506,
                -0.06497259438037872,
                -0.011676601134240627,
                0.010436982847750187,
                -0.03217516839504242,
                0.030665112659335136,
                0.034943386912345886,
                -0.010145733132958412,
                -0.01604977622628212,
                0.03590437397360802,
                0.0113058527931571,
                0.04151837155222893,
                -0.06080615147948265,
                -0.007103553507477045,
                0.02353524975478649,
                -0.04433685168623924,
                -0.00042123612365685403,
                0.04842360317707062,
                0.02036120556294918,
                -0.027903230860829353,
                0.0029784375801682472,
                -0.04765038937330246,
                -0.036582786589860916,
                0.006848164368420839,
                -0.03986087441444397,
                -0.017283279448747635,
                0.018133187666535378,
                0.0016105945687741041,
                -0.020869681611657143,
                -0.000595730496570468,
                -0.05686422809958458,
                0.030128659680485725,
                -0.03861790895462036,
                0.029436836019158363,
                -0.01433513406664133,
                -0.08297640085220337,
                0.034284643828868866,
                -0.03356347233057022,
                -0.021483074873685837,
                -0.027723180130124092,
                0.03625309094786644,
                0.02781745232641697,
                -0.040771957486867905,
                -0.0024417920503765345,
                0.015386892482638359,
                0.03460216149687767,
                0.04331156983971596,
                -0.13330520689487457,
                0.01945478469133377,
                0.001997001701965928,
                -0.054135046899318695,
                0.010511113330721855,
                -0.03748190402984619,
                0.06774798035621643,
                -0.012929549440741539,
                0.04182488098740578,
                -0.04269595444202423,
                0.0006026074406690896,
                -0.007424673065543175,
                0.04009798914194107,
                -0.07458452135324478,
                -0.019405586645007133,
                -0.04101254791021347,
                -0.12016580998897552,
                0.01786450482904911,
                -0.04132324084639549,
                -0.05216230824589729,
                -0.0007776049897074699,
                0.0032790054101496935,
                -0.035523492842912674,
                -0.06839428097009659,
                -0.10559207946062088,
                -0.03235480561852455,
                -0.03239037096500397,
                -0.0011985909659415483,
                -0.006628113333135843,
                -0.010147281922399998,
                0.06315643340349197,
                -0.018822912126779556,
                0.0038596861995756626,
                0.01793406531214714,
                0.03185418248176575,
                -0.07154763489961624,
                0.004839572589844465,
                0.03662512078881264,
                0.0968056172132492,
                -0.01965283416211605,
                -0.004404798150062561,
                -0.03351063281297684,
                -0.05417130887508392,
                -0.021781405434012413,
                0.030984438955783844,
                0.015020080842077732,
                -0.06474422663450241,
                0.0156379546970129,
                -0.04174712300300598,
                -0.01629013940691948,
                -0.08492304384708405,
                0.04616637900471687,
                0.01745970919728279,
                0.05125388130545616,
                0.011881312355399132,
                0.032838448882102966,
                0.019891386851668358,
                -0.003532924922183156,
                0.003032369539141655,
                -0.041390202939510345,
                0.0007923130178824067,
                0.001068633166141808,
                -0.015532967634499073,
                -0.03450446575880051,
                -0.019380412995815277,
                -0.050922855734825134,
                0.003025494748726487,
                0.05127662420272827,
                0.02025097981095314,
                0.07754027843475342,
                -0.004050220362842083,
                0.040994059294462204,
                -0.013385942205786705,
                -0.032613322138786316,
                0.006127581000328064,
                0.054031431674957275,
                0.0519254133105278,
                0.007087415084242821,
                -0.06267202645540237,
                -0.010638689622282982,
                0.027352802455425262,
                0.01683826744556427,
                -0.04581786319613457,
                -0.02261563390493393,
                0.013197962194681168,
                -0.00019327554036863148,
                -0.01661021076142788,
                0.04243547096848488,
                0.07935947179794312,
                0.018724678084254265,
                0.024444811046123505,
                -0.029042845591902733,
                -0.039572589099407196,
                0.11281292140483856,
                -0.04239987954497337,
                -0.01066377479583025,
                0.05685046315193176,
                0.044129155576229095,
                0.05785934999585152,
                -0.0033064200542867184,
                0.028489910066127777,
                -0.00682082399725914,
                -0.06450054794549942,
                -0.014463010244071484,
                -0.0012933618854731321,
                0.010305192321538925,
                -0.042195748537778854,
                0.0807403028011322,
                -0.0017977479146793485,
                0.04156013950705528,
                0.03903311491012573,
                0.050384435802698135,
                -0.004740403965115547,
                0.017413174733519554,
                0.042930956929922104,
                0.03525266796350479,
                -0.04323936998844147,
                -0.05936269089579582,
                0.002874745288863778,
                0.0018864487065002322,
                0.034174930304288864,
                0.04384761303663254,
                0.019452888518571854,
                0.020404154434800148,
                0.0029863317031413317,
                -0.02246301807463169,
                0.030587278306484222,
                0.032735858112573624,
                0.06643853336572647,
                0.005484438501298428,
                -0.010931403376162052,
                -0.026145089417696,
                -0.03788205608725548,
                -0.022596176713705063,
                -0.009527611546218395,
                -0.12774953246116638,
                -0.03548908978700638,
                0.10311155021190643,
                -0.0018003141740337014,
                0.029721245169639587,
                -0.06756456941366196,
                0.04675787314772606,
                -0.01389668881893158,
                -0.11974239349365234,
                0.03243376687169075,
                0.011197635903954506,
                0.01574143022298813,
                0.05314582958817482,
                0.04020517319440842,
                -0.07308169454336166,
                -0.0007454553851857781,
                0.010820968076586723,
                -0.005594165530055761,
                0.03769838437438011,
                -0.05055661499500275,
                0.029351869598031044,
                -0.07366692274808884,
                -0.0055290283635258675,
                -0.021171802654862404,
                -0.01359257660806179,
                -0.00673137279227376,
                0.016005704179406166,
                -0.03501187264919281,
                -0.03847636282444,
                -0.036892496049404144,
                0.017212867736816406,
                0.028350261971354485,
                -0.042760130017995834,
                -0.022607747465372086,
                0.014939934946596622,
                -0.02868124283850193,
                0.023367974907159805,
                -0.012970341369509697,
                0.006120496895164251,
                0.0027133910916745663,
                -0.008742120116949081,
                0.009362290613353252,
                0.018890153616666794,
                -0.0400976724922657,
                0.02432740107178688,
                0.025686394423246384,
                -0.011495362967252731,
                0.01392074953764677,
                0.028488527983427048,
                -0.057771939784288406,
                -0.046937838196754456,
                0.007959332317113876,
                -0.04702172800898552,
                0.04157790541648865,
                -0.001891891355626285,
                -0.0443216972053051,
                0.00840615201741457,
                0.002480161376297474,
                0.017099447548389435,
                0.022080369293689728,
                -0.025326352566480637,
                0.03513414040207863,
                0.022637544199824333,
                -0.018837112933397293,
                0.0038344659842550755,
                0.005840674974024296,
                0.020005853846669197,
                0.005813157185912132,
                -3.588280378608033e-05,
                -0.036324821412563324,
                -0.013130512088537216,
                -0.023238688707351685,
                0.01968557946383953,
                -0.059704944491386414,
                -0.012847160920500755,
                -0.0061543467454612255,
                0.03845880925655365,
                -0.06208006665110588,
                0.03761964291334152,
                -0.003879623720422387,
                -0.018321996554732323,
                0.04001886397600174,
                -0.02937019243836403,
                -0.007202065084129572,
                -0.08581257611513138,
                -0.015964847058057785,
                -0.027816785499453545,
                0.0322699248790741,
                0.026956375688314438,
                0.10066061466932297,
                0.03674403950572014,
                0.03672251105308533,
                -0.08466524630784988,
                0.041258178651332855,
                -0.0040000127628445625,
                0.01590675674378872,
                0.012098275125026703,
                -0.028737425804138184,
                -0.06554704159498215,
                -0.0031670888420194387,
                -0.01392047107219696,
                0.05448845401406288,
                -0.04856454208493233,
                -0.035989247262477875,
                -0.05557408183813095,
                0.018094485625624657,
                0.030870338901877403,
                -5.308886238644651e-33,
                -0.027439702302217484,
                -0.0390041209757328,
                0.0044770496897399426,
                0.019978158175945282,
                -0.03746411204338074,
                -0.009275691583752632,
                0.02344777062535286,
                0.01698453724384308,
                -0.02834007889032364,
                -0.02869955077767372,
                -0.002798717236146331,
                -0.008731125853955746,
                0.037126973271369934,
                0.010522638447582722,
                -0.01489613763988018,
                0.10331130772829056,
                -0.0037872176617383957,
                0.005795192439109087,
                -0.0035425282549113035,
                -0.04829630255699158,
                0.036829691380262375,
                -0.0032276022247970104,
                0.00929330289363861,
                -0.0068203448317945,
                -0.0006323472480289638,
                0.005730213597416878,
                -0.02519349195063114,
                0.02232556790113449,
                0.04055434837937355,
                -0.029332997277379036,
                0.013221866451203823,
                -0.017959635704755783,
                -0.007872415706515312,
                0.02363601326942444,
                0.014238073490560055,
                0.06572174280881882,
                -0.015114502049982548,
                0.025906309485435486,
                -0.024583956226706505,
                0.0017188957426697016,
                -0.01236940361559391,
                0.028698435053229332,
                -0.0045484863221645355,
                -0.00044573142076842487,
                -0.007862119935452938,
                0.012064672075212002,
                -0.015548735857009888,
                -0.019208641722798347,
                0.0012339571258053184,
                0.08082794398069382,
                -0.03357387334108353,
                -0.00018742517568171024,
                -0.022588109597563744,
                0.026298506185412407,
                0.029957884922623634,
                0.02940959297120571,
                -0.01897657848894596,
                -0.03588053956627846,
                0.055727649480104446,
                -0.009697552770376205,
                -0.03103645145893097,
                0.043883342295885086,
                -0.004218121524900198,
                0.06591092795133591,
                -0.033697858452796936,
                -0.0036190159153193235,
                -0.09269887953996658,
                0.013289934024214745,
                -0.015510895289480686,
                0.030209749937057495,
                -0.02349306270480156,
                0.10942190885543823,
                -0.03048853948712349,
                -0.045954786241054535,
                -0.08218156546354294,
                -0.028740068897604942,
                0.029246913269162178,
                0.011928724125027657,
                0.03982400894165039,
                0.0018420976120978594,
                2.2791817173128948e-05,
                -0.014583456330001354,
                0.006765823345631361,
                -0.006087806075811386,
                0.03608491271734238,
                -0.030925367027521133,
                -0.04579013213515282,
                -0.00287076854147017,
                0.033957622945308685,
                0.029899882152676582,
                -0.0415312759578228,
                0.07318001240491867,
                -0.0314478762447834,
                0.007035412359982729,
                0.06979924440383911,
                -0.05101777985692024,
                0.012713953852653503,
                -0.00831683725118637,
                0.0015649795532226562,
                0.013503939844667912,
                -0.0769529864192009,
                0.025527263060212135,
                -0.018436593934893608,
                0.04914069175720215,
                -0.010942097753286362,
                0.002079243306070566,
                -0.006889163516461849,
                -0.011348654516041279,
                -0.026057086884975433,
                0.0368017703294754,
                0.031242089346051216,
                0.007139706052839756,
                0.025971265509724617,
                -0.0398176871240139,
                -0.0351482592523098,
                -0.050629448145627975,
                0.003991981968283653,
                -0.0006650482537224889,
                0.005878328811377287,
                -0.05238646641373634,
                0.039105284959077835,
                -0.024019859731197357,
                -0.018563073128461838,
                0.008966220542788506,
                -0.0033212960697710514,
                0.015062321908771992,
                0.02479427307844162,
                -0.01868121698498726,
                -0.013946128077805042,
                0.03814014419913292,
                0.008023067377507687,
                -0.005709263030439615,
                1.951584067683143e-07,
                0.008675801567733288,
                0.017049288377165794,
                -0.01747193932533264,
                0.055625803768634796,
                0.0362565703690052,
                -0.036814212799072266,
                0.062462255358695984,
                -0.002368532819673419,
                0.004277951084077358,
                -0.004383767955005169,
                0.02754848822951317,
                -0.0265762060880661,
                -0.006684747990220785,
                -0.03837326914072037,
                0.04919278621673584,
                -0.07588531076908112,
                0.03798740729689598,
                0.018128564581274986,
                -0.0025282553397119045,
                -0.0008579311543144286,
                0.046717219054698944,
                0.004454370588064194,
                0.03262612596154213,
                -0.030285535380244255,
                -0.015897231176495552,
                -0.024085531011223793,
                0.01613054983317852,
                -0.015351192094385624,
                0.06434939801692963,
                -0.008555741980671883,
                0.027305029332637787,
                0.04242369160056114,
                0.019630711525678635,
                0.021502042189240456,
                0.0005493797943927348,
                -0.006212139967828989,
                0.008899728767573833,
                0.059357039630413055,
                0.009116784669458866,
                0.024992503225803375,
                0.04741838574409485,
                -0.04643324017524719,
                -0.01460462249815464,
                0.009475206024944782,
                -0.03679909557104111,
                -0.0035181839484721422,
                0.01258231233805418,
                0.029501568526029587,
                -0.0281477440148592,
                -0.008174143731594086,
                -0.04920779541134834,
                0.016403933987021446,
                0.0025863628834486008,
                0.004428836517035961,
                0.025097087025642395,
                0.01746179349720478,
                -0.031194979324936867,
                0.036114469170570374,
                0.04176383465528488,
                0.05272423103451729,
                -0.007338938768953085,
                -0.03361770138144493,
                0.010079859755933285,
                0.02465231902897358,
                0.005987168289721012,
                -0.0038703568279743195,
                -0.04001379758119583,
                1.2481148765304018e-34,
                -0.007782939355820417,
                0.004472961183637381,
                -0.018720602616667747,
                0.03825061395764351,
                0.030753178521990776,
                -0.02812182903289795,
                -0.025378409773111343,
                0.01784532330930233,
                0.00844007171690464,
                0.003060500603169203,
                -0.003519816789776087
            ]
        },
        "Skills": {
            "name": [
                "Asset Integrity Management",
                "Subsea Inspection",
                "Remotely Operated Vehicles",
                "Mechanical Engineering",
                "Team Management",
                "Operations – proven record in a customer facing industry with high delivery / service expectation",
                "IOSH",
                "Safety and Environmental ",
                "Experienced in SharePoint, SAP, Maximo, JIRA, DNV Ship Manager, asset management",
                "Team player"
            ],
            "rep": [
                0.006056773476302624,
                0.10309996455907822,
                -0.008257556706666946,
                0.006228751968592405,
                -0.041739705950021744,
                -0.01525438018143177,
                0.0327441468834877,
                0.037185922265052795,
                -0.036824215203523636,
                -0.013107321225106716,
                0.009138206951320171,
                -0.026589859277009964,
                -0.016341088339686394,
                0.004802181851118803,
                -0.003477967344224453,
                0.011107874102890491,
                0.03240063041448593,
                -0.02602960169315338,
                -0.009032336995005608,
                0.0192706361413002,
                0.012667433358728886,
                -0.027015801519155502,
                0.04617355763912201,
                -0.023933295160531998,
                -0.03919725865125656,
                -0.030377600342035294,
                0.001463141874410212,
                0.019772227853536606,
                -0.008939321152865887,
                0.004765440709888935,
                -0.03907374292612076,
                0.04888492450118065,
                -0.01295852568000555,
                0.0042494856752455235,
                1.2006914857920492e-06,
                0.006958156358450651,
                -0.05888243764638901,
                -0.018967701122164726,
                0.0077547854743897915,
                0.05656737834215164,
                0.01843285746872425,
                -0.010692236945033073,
                -0.045519523322582245,
                -0.018154248595237732,
                0.007524383720010519,
                0.06292327493429184,
                0.011890681460499763,
                0.031133661046624184,
                0.049535755068063736,
                -0.059720974415540695,
                -0.01856459118425846,
                0.09268567711114883,
                0.026180634275078773,
                0.02892293967306614,
                -0.05393306538462639,
                -0.043276574462652206,
                -0.005423153284937143,
                0.03577122464776039,
                -0.005113590974360704,
                -0.005851322785019875,
                0.00163359590806067,
                0.04378070309758186,
                0.0007915728492662311,
                -0.05116116255521774,
                0.053964436054229736,
                -0.010354899801313877,
                -0.012966359034180641,
                -0.0038150979671627283,
                0.011878940276801586,
                -0.012333721853792667,
                0.004908799659460783,
                0.030841879546642303,
                0.0015817868988960981,
                -0.004547863733023405,
                0.012299430556595325,
                -0.009152187034487724,
                0.01568363979458809,
                0.051414646208286285,
                0.024528389796614647,
                0.0032635307870805264,
                -0.07456061244010925,
                0.013462868519127369,
                0.006597809959203005,
                0.005502799525856972,
                0.03310642018914223,
                -0.10627978295087814,
                0.026895511895418167,
                -0.04413655027747154,
                -0.0377991609275341,
                0.043105676770210266,
                -0.07732241600751877,
                -0.018040884286165237,
                -0.008163223043084145,
                0.006329471245408058,
                0.015651823952794075,
                -0.003740247804671526,
                -0.03788748383522034,
                -0.04626650735735893,
                0.015538209117949009,
                -0.017694663256406784,
                -0.020812340080738068,
                -0.017097104340791702,
                -0.049967266619205475,
                0.004454017151147127,
                0.026149116456508636,
                0.08080663532018661,
                -0.024342577904462814,
                -0.015070673078298569,
                0.056204654276371,
                0.011586909182369709,
                -0.031623195856809616,
                -0.033974844962358475,
                0.03851184993982315,
                0.04245537146925926,
                0.01581048034131527,
                -0.0108102448284626,
                -0.005124928895384073,
                0.021787861362099648,
                -0.043334126472473145,
                0.05709991604089737,
                0.008351934142410755,
                0.023084525018930435,
                -0.039996322244405746,
                -0.05677131935954094,
                -0.001351497950963676,
                -0.05477296933531761,
                -0.00038486236007884145,
                0.026355106383562088,
                -0.020848484709858894,
                0.00962410680949688,
                0.02912665158510208,
                0.0023394522722810507,
                0.011641831137239933,
                0.00955697987228632,
                0.013909873552620411,
                -0.02485031820833683,
                0.010088861919939518,
                -0.0026507587172091007,
                -0.11513898521661758,
                -0.028775686398148537,
                0.008314385078847408,
                -0.011740077286958694,
                0.004710741341114044,
                0.003249339759349823,
                -0.03823172301054001,
                -0.05211111530661583,
                -0.04178040474653244,
                -0.052118539810180664,
                -0.03823739290237427,
                -0.006219891365617514,
                -0.05504995211958885,
                -0.018403148278594017,
                -0.047628823667764664,
                0.028657296672463417,
                -0.023911071941256523,
                0.0627407506108284,
                0.038250185549259186,
                -0.057959772646427155,
                -0.00536772795021534,
                -0.07028073817491531,
                0.05186045914888382,
                0.016913948580622673,
                0.012706915847957134,
                -0.009051884524524212,
                -0.03764353320002556,
                -0.00047947856364771724,
                0.02149079740047455,
                0.004979791585355997,
                0.03704109042882919,
                -0.02631228417158127,
                -0.03608064725995064,
                0.04736720770597458,
                -0.004952629096806049,
                -0.004173728637397289,
                0.03181452676653862,
                -0.04658353328704834,
                0.0016743165906518698,
                -0.026026565581560135,
                -0.08202534914016724,
                0.026504535228013992,
                -0.05712513253092766,
                -0.019245894625782967,
                0.0103810615837574,
                0.057265542447566986,
                0.043601520359516144,
                -0.0208950936794281,
                -0.04344834387302399,
                0.01488647423684597,
                -0.020400922745466232,
                -0.006520453374832869,
                -0.03652677685022354,
                -0.027743568643927574,
                -0.03658466041088104,
                0.012258184142410755,
                -0.01875164918601513,
                0.010043026879429817,
                0.022385001182556152,
                -0.028607018291950226,
                -0.04852363094687462,
                -0.0013014498399570584,
                -0.03409329429268837,
                -0.05575896427035332,
                0.06468690186738968,
                0.06518088281154633,
                -0.009526122361421585,
                0.02116914466023445,
                0.01391619723290205,
                0.01421372126787901,
                0.01897706836462021,
                -0.030842473730444908,
                -0.03941655158996582,
                -0.03796618431806564,
                0.03660479187965393,
                -0.037418562918901443,
                -0.019007736817002296,
                0.030348924919962883,
                0.02120702527463436,
                0.014786827377974987,
                -0.07576557993888855,
                0.022630708292126656,
                -0.018436994403600693,
                0.0074931057170033455,
                -0.0004152621258981526,
                -0.0304022915661335,
                0.009233059361577034,
                -0.027807535603642464,
                0.006097177043557167,
                0.04291436821222305,
                -0.02178703434765339,
                0.003142428118735552,
                -0.013258758001029491,
                -0.03083915077149868,
                0.006358564831316471,
                0.06914198398590088,
                -0.005010323133319616,
                0.004995870869606733,
                0.08420298993587494,
                0.1539420485496521,
                0.05229155346751213,
                -0.004079027101397514,
                0.001400550128892064,
                -0.048220597207546234,
                -0.008407389745116234,
                -0.01741575449705124,
                0.032401345670223236,
                0.006019540596753359,
                -0.05181073024868965,
                -0.04065053537487984,
                0.051106974482536316,
                -0.00507125211879611,
                0.010184732265770435,
                -0.02591332420706749,
                0.02454729564487934,
                -0.020970001816749573,
                -0.03108184225857258,
                -0.007495144847780466,
                -0.0378090925514698,
                0.04714794456958771,
                0.0038274722173810005,
                0.0036139870062470436,
                0.017503630369901657,
                -0.015435060486197472,
                0.027417948469519615,
                0.03285876289010048,
                0.0003360386472195387,
                0.03937789797782898,
                0.026117807254195213,
                0.05191268399357796,
                0.004065779037773609,
                0.0028269505128264427,
                -0.007780812680721283,
                -0.036778975278139114,
                -0.006520239170640707,
                -0.0028267414309084415,
                0.011881675571203232,
                -0.0005402825190685689,
                -0.05622771009802818,
                0.03781787306070328,
                -0.012203140184283257,
                0.04990309849381447,
                -0.01089237630367279,
                -0.004744353238493204,
                0.014506402425467968,
                -0.015538415871560574,
                0.04792847856879234,
                0.022893982008099556,
                -0.025632891803979874,
                0.03715767711400986,
                -0.0933241918683052,
                0.011073246598243713,
                -0.02255186252295971,
                -0.003167395945638418,
                0.03497060388326645,
                0.01913638226687908,
                0.010210557840764523,
                -0.03134876489639282,
                -0.05339045822620392,
                0.003329361090436578,
                -0.05963598191738129,
                -0.009048573672771454,
                -0.01171555370092392,
                -0.0233465489000082,
                0.03701033443212509,
                -0.08362900465726852,
                0.07950121909379959,
                0.022518033161759377,
                0.0666167140007019,
                0.05662693828344345,
                0.05248047038912773,
                -0.05927543342113495,
                -0.027993693947792053,
                -0.031949251890182495,
                -0.017769215628504753,
                -0.00506519665941596,
                0.021456226706504822,
                0.013916189782321453,
                0.04461808130145073,
                -0.04277190938591957,
                -0.0068091824650764465,
                0.050894610583782196,
                -0.016765590757131577,
                0.010074938647449017,
                0.011506346054375172,
                -0.04642411321401596,
                0.02775307185947895,
                0.06071337312459946,
                -0.02944011613726616,
                0.03154469281435013,
                0.014485177583992481,
                -0.022354857996106148,
                -0.02000887133181095,
                0.09923913329839706,
                -0.023922855034470558,
                -0.0344109944999218,
                -0.02826671116054058,
                0.10282091051340103,
                -0.015792163088917732,
                -0.09189262986183167,
                -0.038779668509960175,
                -0.021336551755666733,
                0.020305870100855827,
                0.00230949348770082,
                0.045097172260284424,
                -0.011506211012601852,
                0.08904263377189636,
                -0.025171641260385513,
                -0.04975801333785057,
                0.05829780921339989,
                0.007523353677242994,
                -0.0031945251394063234,
                -0.024632619693875313,
                -0.00716114928945899,
                0.025653734803199768,
                0.002513829618692398,
                -0.008408412337303162,
                0.03616424277424812,
                -0.042484212666749954,
                -0.0607912503182888,
                0.012380619533360004,
                -0.058865100145339966,
                0.005823149811476469,
                -0.005510362330824137,
                -0.008145932108163834,
                -0.016685716807842255,
                -0.04630579799413681,
                0.009530400857329369,
                0.024729367345571518,
                0.05497605353593826,
                0.024693237617611885,
                -0.0012772930786013603,
                -0.015103373676538467,
                0.01876314915716648,
                -0.028507927432656288,
                -0.017056746408343315,
                -0.009032698348164558,
                0.06109650433063507,
                -0.009415499866008759,
                0.03502202779054642,
                -0.04358963295817375,
                -0.014968164265155792,
                -0.006076256278902292,
                -0.01822107657790184,
                0.029062416404485703,
                -0.013433034531772137,
                0.03490365669131279,
                -0.03052448108792305,
                -0.034107983112335205,
                0.030154991894960403,
                0.05346759781241417,
                0.024792781099677086,
                0.015013140626251698,
                0.00725720776244998,
                -0.09347222000360489,
                0.06618454307317734,
                0.0030488765332847834,
                -0.02979055978357792,
                0.056668028235435486,
                -0.02390497736632824,
                0.008492562919855118,
                0.039032381027936935,
                0.02292124181985855,
                0.0021075198892503977,
                0.03579680994153023,
                -0.0003046618076041341,
                -0.09519252181053162,
                -0.03441162779927254,
                0.09997555613517761,
                -0.01662694849073887,
                0.022055532783269882,
                -0.01319365855306387,
                -0.028262393549084663,
                0.029808392748236656,
                -0.01359553076326847,
                0.03865082561969757,
                0.019915832206606865,
                0.018477912992239,
                -0.001183778396807611,
                -0.019199784845113754,
                0.03917999565601349,
                0.058110885322093964,
                -0.031862854957580566,
                -0.06254644691944122,
                0.017643677070736885,
                -0.061418600380420685,
                0.006757641676813364,
                -0.007568016182631254,
                -0.07621700316667557,
                0.04860464110970497,
                -0.02226673997938633,
                0.03390730544924736,
                -0.009087961167097092,
                -0.024798022583127022,
                0.007440789137035608,
                0.02476693131029606,
                -0.05858875811100006,
                0.08855722099542618,
                -0.012869220227003098,
                -0.03699537739157677,
                0.036308590322732925,
                -0.04315986856818199,
                -0.043539807200431824,
                -0.04346277564764023,
                0.05982900783419609,
                0.004233157262206078,
                -0.050325658172369,
                -0.02450418472290039,
                -0.010422073304653168,
                -0.04478421062231064,
                -0.06489016115665436,
                -0.03846291825175285,
                0.016169341281056404,
                0.07292448729276657,
                0.06479208916425705,
                -0.012057255953550339,
                0.008968065492808819,
                -0.006876084487885237,
                -0.02408105880022049,
                0.015215475112199783,
                -0.004484577104449272,
                0.038265395909547806,
                0.05523952469229698,
                0.031113017350435257,
                -0.05023966729640961,
                0.032275810837745667,
                -0.02278548665344715,
                -0.017808709293603897,
                0.010907091200351715,
                -0.028363388031721115,
                -0.03237590193748474,
                -0.020210206508636475,
                -0.07199760526418686,
                -0.047157350927591324,
                -0.022162573412060738,
                -0.018271025270223618,
                -0.0022914705332368612,
                0.06771400570869446,
                0.012196390889585018,
                0.0045590708032250404,
                0.027071554213762283,
                -0.033648110926151276,
                0.01683882623910904,
                -0.030691420659422874,
                -0.012678099796175957,
                0.005173773970454931,
                0.05176762863993645,
                -0.007739590480923653,
                0.03417778015136719,
                0.011447750963270664,
                -0.005907945800572634,
                0.06569454818964005,
                0.048427190631628036,
                -0.012419948354363441,
                -0.014968064613640308,
                0.03990095481276512,
                0.017276089638471603,
                -0.035340044647455215,
                0.006590184755623341,
                -0.048850663006305695,
                -0.015111995860934258,
                0.00024649198167026043,
                -0.011820189654827118,
                0.03502409905195236,
                0.02974928729236126,
                0.03020932897925377,
                0.005994671024382114,
                0.02432846650481224,
                -0.03189072757959366,
                0.014140652492642403,
                0.0233764685690403,
                0.04723004624247551,
                -0.017792612314224243,
                -0.020872240886092186,
                0.04239049553871155,
                0.04766024649143219,
                0.021276794373989105,
                0.02928329072892666,
                0.0300405565649271,
                -0.016924554482102394,
                -0.027480648830533028,
                -0.045184217393398285,
                0.050974540412425995,
                -0.0013208277523517609,
                0.05476782098412514,
                0.027432050555944443,
                -0.02310182712972164,
                -0.03290468454360962,
                -0.020246651023626328,
                -0.017777683213353157,
                -0.002784149954095483,
                -0.030984528362751007,
                -0.018067557364702225,
                -0.026879768818616867,
                -0.1640011966228485,
                -0.034276194870471954,
                -0.02041236124932766,
                0.051208868622779846,
                -0.010441994294524193,
                -0.00330861727707088,
                -0.03068098984658718,
                0.002888515591621399,
                -0.03860120847821236,
                0.01675000786781311,
                -0.0072651877999305725,
                0.03941251337528229,
                0.025699665769934654,
                -0.049149468541145325,
                -0.03828255087137222,
                0.0005451432662084699,
                0.03883257508277893,
                0.050968561321496964,
                0.006952838972210884,
                -0.0713314488530159,
                -0.08256375044584274,
                -0.008121750317513943,
                -0.02184799499809742,
                -5.16195936302545e-33,
                0.001871560700237751,
                -0.05433931201696396,
                -0.013556254096329212,
                0.016591142863035202,
                0.03954537212848663,
                -0.021454252302646637,
                -0.04419860243797302,
                -0.015826527029275894,
                0.03689270466566086,
                -0.029030000790953636,
                0.018175436183810234,
                0.027931537479162216,
                0.03261268511414528,
                0.0032795052975416183,
                0.019562406465411186,
                -1.2590669939527288e-05,
                0.011874918825924397,
                0.02365681156516075,
                -0.035069096833467484,
                -0.036449432373046875,
                0.04194893315434456,
                0.0025097171310335398,
                0.02929248847067356,
                -0.009786287322640419,
                0.009638634510338306,
                0.005170143209397793,
                0.00859307311475277,
                0.01326069701462984,
                0.015114972367882729,
                0.015679147094488144,
                0.004148035775870085,
                -0.04708489403128624,
                0.012444276362657547,
                -0.06819183379411697,
                0.010926110669970512,
                0.035627953708171844,
                -0.022381378337740898,
                -0.004996796604245901,
                0.003943631891161203,
                0.019039427861571312,
                0.03775098919868469,
                -0.07002031803131104,
                0.04415034130215645,
                -0.036549124866724014,
                -0.035998277366161346,
                0.009680147282779217,
                0.006952011026442051,
                0.03609133139252663,
                -0.01988137699663639,
                -0.02544507570564747,
                -0.0190060306340456,
                -0.011353093199431896,
                -0.021459518000483513,
                0.08517827093601227,
                0.05436042696237564,
                -0.03958546370267868,
                0.01580205373466015,
                -0.0012915947008877993,
                -0.03597569093108177,
                -0.022323546931147575,
                -0.04742828384041786,
                0.007024260237812996,
                -0.04327303543686867,
                -0.034355927258729935,
                -0.01545043010264635,
                0.0800887942314148,
                -0.08108551055192947,
                -0.009452113881707191,
                0.041374772787094116,
                0.026623643934726715,
                0.0016143044922500849,
                0.0750160664319992,
                0.00849723257124424,
                0.02665066532790661,
                -0.019976405426859856,
                -0.015992242842912674,
                0.029794855043292046,
                0.03573423996567726,
                -0.0019008516101166606,
                0.011119392700493336,
                -0.010327450931072235,
                0.03322422876954079,
                0.043485548347234726,
                0.006585882510989904,
                0.004233942832797766,
                -0.08164876699447632,
                0.004333514720201492,
                -0.005822988227009773,
                0.024380747228860855,
                0.01690741628408432,
                -0.02570977993309498,
                0.0647159069776535,
                0.06923965364694595,
                -0.021514063701033592,
                -0.017358457669615746,
                -0.024442264810204506,
                0.024822723120450974,
                -0.0018100979505106807,
                -0.006077020429074764,
                -0.010583147406578064,
                0.0706453025341034,
                0.007688644342124462,
                0.03859642893075943,
                -0.0013553877361118793,
                0.026401951909065247,
                0.007629509083926678,
                0.04340384528040886,
                -0.010362200438976288,
                -0.01867874339222908,
                0.03821748495101929,
                0.030262459069490433,
                0.007776782847940922,
                -0.016943149268627167,
                -0.0235787034034729,
                -0.05828491598367691,
                0.0018204475054517388,
                0.020543240010738373,
                -0.024254178628325462,
                -0.021772772073745728,
                0.0011723610805347562,
                0.012689429335296154,
                0.0002545315073803067,
                0.013960683718323708,
                -0.009026480838656425,
                -0.010856672190129757,
                0.0032237342093139887,
                0.008729186840355396,
                -0.07386317104101181,
                0.024538926780223846,
                0.005464076995849609,
                -0.0027756989002227783,
                0.032028306275606155,
                1.938144293944788e-07,
                0.005278355907648802,
                0.02523954026401043,
                0.07077929377555847,
                -0.013271092437207699,
                0.011096972972154617,
                0.02699432708323002,
                -0.025163913145661354,
                -0.01392294093966484,
                -0.05309281125664711,
                -0.02702023647725582,
                0.03634493425488472,
                -0.047090914100408554,
                0.02643350139260292,
                -0.04191908240318298,
                0.026571612805128098,
                -0.057236190885305405,
                0.06490597873926163,
                -0.0055909231305122375,
                -0.01225137896835804,
                -0.002936167176812887,
                0.0012128312373533845,
                -0.015270913951098919,
                -0.015259220264852047,
                -0.06391323357820511,
                0.009013364091515541,
                -0.012980189174413681,
                0.023864056915044785,
                0.08848784863948822,
                0.057440049946308136,
                -0.01805335469543934,
                0.07935476303100586,
                0.02186470851302147,
                -0.012914532795548439,
                0.031612664461135864,
                -0.012548794038593769,
                -0.002304145134985447,
                -0.019865505397319794,
                0.036382634192705154,
                -0.022177478298544884,
                0.0022948384284973145,
                -0.009488222189247608,
                -0.035976264625787735,
                0.02876708097755909,
                0.061837296932935715,
                0.013832194730639458,
                0.10388332605361938,
                -0.03946528211236,
                -0.03060082159936428,
                -0.07232832908630371,
                -0.01804852858185768,
                -0.005623808596283197,
                0.0384485088288784,
                0.025406954810023308,
                0.016777729615569115,
                -0.029920149594545364,
                0.03786330297589302,
                0.009120919741690159,
                0.018642812967300415,
                0.06882965564727783,
                0.036990903317928314,
                0.0038994215428829193,
                0.06756460666656494,
                0.04048658907413483,
                -0.0004398780583869666,
                0.011313187889754772,
                -0.07411535829305649,
                -0.026483289897441864,
                1.1827630155488223e-34,
                -0.030050035566091537,
                -0.05160444229841232,
                0.011582896113395691,
                0.005371939856559038,
                -0.0005532284267246723,
                -0.009484265930950642,
                -0.021858463063836098,
                0.013254906050860882,
                0.01649697870016098,
                0.03000246174633503,
                0.03747011348605156,
                -0.016241835430264473,
                0.03341991826891899,
                -0.021864349022507668,
                -0.030595367774367332,
                -0.04087745398283005,
                -0.0187702439725399,
                0.028028454631567,
                -0.0025234867352992296,
                0.03144056349992752,
                0.0008633087272755802,
                0.010499243624508381,
                -0.050909847021102905,
                -0.00466266181319952,
                0.002705684630200267,
                0.00999536458402872,
                -0.04904080927371979,
                0.025231344625353813,
                -0.0308690145611763,
                -0.06505188345909119,
                -0.0001467940746806562,
                -0.04942726343870163,
                -0.024690868332982063,
                -0.03942500054836273,
                -0.028862981125712395,
                0.0446876659989357,
                0.018686136230826378,
                -0.04837241396307945,
                -0.050455860793590546,
                -0.0053113903850317,
                -0.01456458866596222,
                0.06872840970754623,
                0.004284176044166088,
                0.015022991225123405,
                0.040767937898635864,
                1.7846471109805861e-06,
                0.0526464581489563,
                -0.006118725519627333,
                -0.00549686374142766,
                -0.015625761821866035,
                0.03455521538853645,
                -0.00872882641851902,
                -0.015725230798125267,
                -0.007510756608098745,
                -0.018703706562519073,
                -0.025336066260933876,
                -0.04225553572177887,
                0.001254896866157651,
                -0.06514932215213776,
                0.02186466194689274,
                -0.012701938860118389,
                -0.005882158875465393,
                0.0069328490644693375,
                -0.07039365917444229,
                0.00462348572909832,
                -0.0387018583714962,
                -0.12163889408111572,
                -0.02538754604756832,
                -0.021199340000748634,
                0.02493298426270485,
                0.05631932616233826,
                0.049144890159368515,
                0.022397179156541824,
                -0.012945895083248615,
                -0.01631253957748413,
                -0.026041800156235695,
                0.018277419731020927,
                -0.03913664072751999,
                -0.02565591409802437,
                0.002767902100458741,
                0.01940145716071129,
                -0.049941353499889374,
                0.04033489152789116,
                0.004283283371478319,
                0.030502496287226677,
                -0.04445437714457512,
                0.004138493444770575,
                0.040578246116638184,
                0.020973030477762222,
                -0.021830150857567787,
                -0.02893994189798832,
                0.01971314288675785,
                0.008690239861607552,
                -0.01270113606005907,
                -0.008288124576210976,
                -0.03932350128889084,
                0.056028109043836594,
                -0.03560034558176994,
                0.027266329154372215,
                -0.003935568034648895,
                0.03452388569712639,
                0.012682648375630379,
                -0.04892678186297417,
                -0.015092986635863781,
                0.010988662950694561,
                -0.035097572952508926,
                -0.014144586399197578,
                0.024684632197022438,
                -0.04467272013425827,
                0.01186935231089592,
                0.011061148717999458,
                -0.008751536719501019,
                0.016336714848876,
                -0.017854485660791397,
                0.08424639701843262,
                0.028021393343806267,
                0.04230580851435661,
                -0.023659128695726395,
                0.03636760637164116,
                -0.007467587944120169,
                0.005861168261617422,
                0.031286679208278656,
                -0.009213253855705261,
                -0.0007308236672542989,
                0.0006069587543606758,
                0.03808250650763512,
                -0.007052371744066477,
                0.06396986544132233,
                -0.02929527871310711,
                0.060049742460250854,
                0.018074197694659233,
                -0.03590082749724388,
                -0.010035441257059574,
                0.036268215626478195,
                0.031311824917793274,
                0.0044015985913574696,
                -0.040483441203832626,
                0.044223684817552567,
                0.015976479277014732,
                0.01057338248938322,
                0.058563217520713806,
                -0.010538619011640549,
                0.01168391015380621,
                0.02695205621421337,
                -0.02819919027388096,
                0.0017910455353558064,
                -0.03214750438928604,
                -0.03175697848200798,
                -0.050982363522052765,
                -0.07968562096357346,
                -0.0071332696825265884,
                0.0674232542514801,
                0.020363671705126762,
                0.03967567905783653,
                0.028372587636113167,
                0.0009344933205284178,
                -0.0838075578212738,
                -0.022742336615920067,
                0.03938538208603859,
                -0.0025592150632292032,
                -0.03990339860320091,
                0.024403557181358337,
                -0.01649646647274494,
                -0.018600093200802803,
                -0.019631635397672653,
                -0.011673344299197197,
                0.030736053362488747,
                -0.006380576174706221,
                -0.012396177276968956,
                -0.011853870004415512,
                0.05763866379857063,
                -0.014860514551401138,
                -0.017159337177872658,
                -0.03769597038626671,
                0.05501795560121536,
                0.019753696396946907,
                -0.009156567975878716,
                0.031958792358636856,
                -0.040133699774742126,
                -0.04157569631934166,
                0.02013305015861988,
                0.04064887389540672,
                0.019128520041704178,
                0.030177712440490723,
                -0.007490906864404678,
                0.0053132264874875546,
                -0.021464137360453606,
                -0.003367615630850196,
                -0.002681702608242631,
                0.00042664771899580956,
                0.025444936007261276,
                0.019262922927737236,
                -0.0009886909974738955,
                -0.009109942242503166,
                0.008178985677659512,
                0.01817413605749607,
                -0.022761739790439606,
                -0.0005598164279945195,
                -0.027478426694869995,
                -0.03432372212409973,
                -0.03149465098977089,
                -0.03221207484602928,
                -0.0031068583484739065,
                -0.02333003841340542,
                0.0016496377065777779,
                0.018661372363567352,
                0.022130031138658524,
                -0.03676404803991318,
                -0.02790427766740322,
                -0.0016700309934094548,
                0.0337832048535347,
                -0.021516112610697746,
                -0.049527160823345184,
                0.13033919036388397,
                0.026629222556948662,
                0.007219493389129639,
                -0.04377366602420807,
                0.029803704470396042,
                -0.009524145163595676,
                0.006608771160244942,
                0.022083979099988937,
                -0.016352402046322823,
                0.0017421513330191374,
                0.04120147228240967,
                0.007338449824601412,
                -0.05456602945923805,
                -0.021269716322422028,
                0.014798326417803764,
                0.018243437632918358,
                -0.029103023931384087,
                0.024926530197262764,
                -0.02906743995845318,
                -0.0719914436340332,
                0.0055673252791166306,
                0.030239559710025787,
                0.025281190872192383,
                0.04044513776898384,
                -0.03476528450846672,
                0.04808560758829117,
                -0.060694631189107895,
                -0.007578836288303137,
                0.02952265925705433,
                -0.007010628003627062,
                -0.017955124378204346,
                0.015695275738835335,
                -0.03115013986825943,
                0.00322107900865376,
                0.05101446062326431,
                -0.0022424410562962294,
                0.006984984036535025,
                -0.07766304910182953,
                -0.019325172528624535,
                0.05528883635997772,
                -0.04913989081978798,
                0.0043296003714203835,
                -0.0068563721142709255,
                -0.012067286297678947,
                0.02168487198650837,
                0.01699555292725563,
                0.020591115579009056,
                0.020605040714144707,
                -0.029745474457740784,
                -0.019312622025609016,
                0.008726701140403748,
                -0.013724801130592823,
                0.0016493528382852674,
                -0.004498396534472704,
                -0.013046495616436005,
                0.04516269639134407,
                -0.038940418511629105,
                -0.05716651305556297,
                -0.027398377656936646,
                -0.037866223603487015,
                -0.013815517537295818,
                -0.0003782978164963424,
                0.06643052399158478,
                0.037210822105407715,
                0.08839540183544159,
                0.001073481049388647,
                -0.014024273492395878,
                -0.0006539068417623639,
                0.005338713061064482,
                -0.006241521332412958,
                0.031941596418619156,
                0.036838348954916,
                -0.02251431904733181,
                0.035191476345062256,
                -0.06488553434610367,
                0.00804781075567007,
                -0.02921045757830143,
                0.025696422904729843,
                -0.03239714354276657,
                0.03523586317896843,
                -0.07233825325965881,
                -0.03334244713187218,
                0.017840184271335602,
                0.06218916177749634,
                0.06038914620876312,
                0.03595643490552902,
                -0.03437329828739166,
                0.07993359863758087,
                0.06569194793701172,
                -0.03505817800760269,
                -0.0024842298589646816,
                -0.09294499456882477,
                -0.04104471951723099,
                -0.005771480035036802,
                -0.09928634762763977,
                0.02568931132555008,
                0.03526868298649788,
                0.010274806059896946,
                -0.015105372294783592,
                0.05396370217204094,
                -0.0031322806607931852,
                0.008189673535525799,
                -0.02432589791715145,
                0.032418955117464066,
                0.06623496115207672,
                0.04234908148646355,
                -0.04915131255984306,
                0.047192517668008804,
                -0.08181259781122208,
                -0.015322881750762463,
                0.042896829545497894,
                0.06475382298231125,
                0.01004371140152216,
                -0.039886344224214554,
                0.049964290112257004,
                -0.029788250103592873,
                -0.03234652429819107,
                0.03785433992743492,
                0.026205556467175484,
                0.001135517260991037,
                -0.06932708621025085,
                0.01656153053045273,
                -0.025779329240322113,
                -0.05431704595685005,
                -0.004459065850824118,
                0.025118602439761162,
                0.030336419120430946,
                -0.0411933995783329,
                0.007671674247831106,
                0.059083662927150726,
                -0.04247339442372322,
                0.03341200575232506,
                0.017620600759983063,
                0.01303291879594326,
                -0.005635377485305071,
                -0.056844186037778854,
                -0.04833750054240227,
                0.03489955887198448,
                0.024821028113365173,
                -0.04815935343503952,
                -0.06043149158358574,
                -0.010378259234130383,
                -0.006335976999253035,
                0.0026939103845506907,
                0.021574141457676888,
                -0.015433600172400475,
                -0.07980526238679886,
                0.009259587153792381,
                0.01470132078975439,
                -0.0025662595871835947,
                0.0296021681278944,
                0.0014690873213112354,
                -0.013936232775449753,
                0.0245295949280262,
                -0.028599265962839127,
                0.026294490322470665,
                0.007171007338911295,
                0.022385917603969574,
                0.010865245945751667,
                -0.007096012122929096,
                -0.008173859678208828,
                0.004283074289560318,
                -0.04528384655714035,
                -0.018875576555728912,
                -0.02076638862490654,
                0.052042096853256226,
                -0.0018016191897913814,
                -0.056956227868795395,
                -0.07194963842630386,
                0.030277861282229424,
                -0.07362569868564606,
                -0.040106095373630524,
                -0.012966841459274292,
                -0.004424461629241705,
                0.009828818030655384,
                0.05612406134605408,
                -0.06395768374204636,
                -0.020633848384022713,
                -0.014675182290375233,
                0.06668795645236969,
                -0.005362510681152344,
                0.03452269732952118,
                0.03601266071200371,
                -0.004872139077633619,
                0.006310516502708197,
                0.0007667187601327896,
                0.02008931338787079,
                0.05457049235701561,
                0.001623778254725039,
                -0.026737213134765625,
                -0.004627523012459278,
                -0.05269152671098709,
                0.05244361609220505,
                -0.008209336549043655,
                -0.006097720470279455,
                -0.004017680883407593,
                0.0596688948571682,
                0.013969196937978268,
                -0.008556768298149109,
                -0.021450506523251534,
                -0.06820852309465408,
                0.02159121446311474,
                -0.05887002870440483,
                -0.10268906503915787,
                -0.01145191304385662,
                0.022206686437129974,
                -0.004543398041278124,
                -0.02565479464828968,
                0.046915411949157715,
                -0.036418937146663666,
                0.043423302471637726,
                -0.006148929707705975,
                0.05044172331690788,
                0.0035417270846664906,
                -0.013576065190136433,
                -0.018301334232091904,
                -0.027572674676775932,
                -0.022163720801472664,
                0.05636238306760788,
                -0.012677682563662529,
                -0.001801688689738512,
                -0.06856634467840195,
                0.0455484464764595,
                -0.0474892295897007,
                0.015979567542672157,
                0.03915899246931076,
                -0.06185736507177353,
                0.010118232108652592,
                -0.0111708277836442,
                0.020417718216776848,
                0.03195241466164589,
                0.026591328904032707,
                0.0407995842397213,
                -0.010257558897137642,
                0.0626722201704979,
                -0.015991603955626488,
                0.021407874301075935,
                -0.030070524662733078,
                0.009354598820209503,
                -0.025296181440353394,
                0.023691149428486824,
                -0.015002763830125332,
                -0.04921291023492813,
                0.03037175163626671,
                0.032127659767866135,
                0.008048336952924728,
                -0.005718929227441549,
                -0.0071384962648153305,
                0.10061236470937729,
                0.009690232574939728,
                -0.01844838261604309,
                0.001233964692801237,
                -0.03972177952528,
                -0.0171002559363842,
                0.0457158200442791,
                -0.04645015299320221,
                -0.020069874823093414,
                0.08555816113948822,
                0.04951511323451996,
                0.020585574209690094,
                -0.06250753998756409,
                -0.047685712575912476,
                0.040169116109609604,
                -0.009180073626339436,
                -0.08744519203901291,
                -0.10565197467803955,
                -0.030707672238349915,
                0.028352390974760056,
                -0.03475406393408775,
                -0.006582987494766712,
                -0.03652973473072052,
                -0.007949006743729115,
                -0.031767021864652634,
                -0.028495049104094505,
                0.010093716904520988,
                0.012473762035369873,
                -0.023136312142014503,
                -0.010460950434207916,
                0.013025622814893723,
                0.05316167697310448,
                0.06673644483089447,
                -0.02742023579776287,
                0.021879639476537704,
                0.039700232446193695,
                0.06456714123487473,
                -0.001486950321123004,
                0.016236701980233192,
                0.0871509462594986,
                0.003388470271602273,
                -0.017042094841599464,
                -0.013605883345007896,
                0.07932094484567642,
                0.002609119052067399,
                0.038523267954587936,
                0.014410967007279396,
                0.021289082244038582,
                0.03964030370116234,
                -0.08933152258396149,
                0.02008943445980549,
                0.013796532526612282,
                0.02114694193005562,
                -0.020877277478575706,
                0.0010905254166573286,
                0.010218682698905468,
                0.04568735510110855,
                -0.04867826774716377,
                0.02648080326616764,
                -0.029309917241334915,
                -0.036938849836587906,
                -0.022584091871976852,
                -0.034576717764139175,
                -0.0023305932991206646,
                0.057931628078222275,
                0.006341016851365566,
                0.012477441690862179,
                0.024293633177876472,
                -0.014409378170967102,
                -0.010877037420868874,
                0.03633710741996765,
                0.01939297653734684,
                -0.006982753053307533,
                -0.014951403252780437,
                0.0055668530985713005,
                0.012971360236406326,
                0.0005299313925206661,
                0.05196014419198036,
                -0.008727681823074818,
                0.009632542729377747,
                0.010813532397150993,
                0.019610730931162834,
                -0.007912547327578068,
                0.01642708107829094,
                -0.008993559516966343,
                0.018536336719989777,
                0.04388224333524704,
                0.13502445816993713,
                -0.01891956478357315,
                -0.014614557847380638,
                -0.011659969575703144,
                0.007046486251056194,
                -0.038265470415353775,
                0.044020120054483414,
                0.019392356276512146,
                -0.01735146902501583,
                0.051903557032346725,
                0.03164278715848923,
                0.016106633469462395,
                -0.018305784091353416,
                0.01694638840854168,
                -0.05984804034233093,
                -0.007548070512712002,
                0.013327532447874546,
                -0.03719545155763626,
                -5.829237547477353e-33,
                -0.02597736194729805,
                -0.08963747322559357,
                0.012390839867293835,
                0.016922976821660995,
                -0.0026924354024231434,
                0.004421775229275227,
                -0.005302593111991882,
                0.0019241638947278261,
                0.0019620275124907494,
                -0.0548412948846817,
                -0.001227045082487166,
                0.059717193245887756,
                0.012044415809214115,
                -0.019459016621112823,
                -0.0024738823994994164,
                0.01500814501196146,
                0.06752694398164749,
                0.0019833745900541544,
                -0.05733855813741684,
                -0.0417267382144928,
                -0.01283366046845913,
                0.03837241232395172,
                0.04823726415634155,
                -0.022850392386317253,
                -0.038868676871061325,
                0.04956386983394623,
                0.030403725802898407,
                0.011231979355216026,
                -0.013179806992411613,
                -0.002420526696369052,
                -0.01733015850186348,
                -0.012040621601045132,
                0.009799471125006676,
                0.052648454904556274,
                -0.0017477963119745255,
                -0.024820905178785324,
                -0.06649062782526016,
                -0.016106365248560905,
                0.016311516985297203,
                0.032930247485637665,
                -0.026114949956536293,
                0.005528298672288656,
                -0.006478476338088512,
                -0.006052967626601458,
                0.005728838965296745,
                -0.06312505900859833,
                0.021127359941601753,
                0.04890171438455582,
                0.050066910684108734,
                -0.00015191441343631595,
                -0.00037050037644803524,
                0.03217979520559311,
                0.017905866727232933,
                -0.09167281538248062,
                0.08021844178438187,
                0.03425043076276779,
                0.029588039964437485,
                -0.0646684393286705,
                0.04617593437433243,
                0.05267145857214928,
                0.0414457805454731,
                -0.003111435566097498,
                -0.0037138806656003,
                -0.020380234345793724,
                -0.027975047007203102,
                0.025917723774909973,
                -0.0015557656297460198,
                0.037279799580574036,
                0.04507192596793175,
                -0.06105874106287956,
                0.0002228648227173835,
                0.025896070525050163,
                -0.033037178218364716,
                -0.016838649287819862,
                0.019255515187978745,
                0.002640275051817298,
                0.03196372464299202,
                0.02393624559044838,
                0.01750447414815426,
                0.07478959113359451,
                0.02041175588965416,
                0.009859229438006878,
                0.008505049161612988,
                0.009322176687419415,
                0.001590423402376473,
                -0.021665379405021667,
                0.0403793603181839,
                0.048426587134599686,
                -0.006171243730932474,
                0.04740462079644203,
                -0.03341154381632805,
                -0.06868603825569153,
                0.0151889743283391,
                0.01576106809079647,
                0.10214073210954666,
                -0.01671028323471546,
                0.04207440838217735,
                -0.028833335265517235,
                -0.0323287770152092,
                -0.003264667699113488,
                0.05559808015823364,
                0.010805460624396801,
                0.028142794966697693,
                0.004476636648178101,
                0.01585311070084572,
                0.021020719781517982,
                -0.10971006006002426,
                0.06288518011569977,
                0.026746254414319992,
                -0.0034959062468260527,
                -0.03635675087571144,
                -0.016518106684088707,
                0.009616892784833908,
                -0.029601434245705605,
                0.013945112004876137,
                0.01307415496557951,
                -0.00852435827255249,
                0.022084029391407967,
                -0.010768568143248558,
                0.04915956035256386,
                0.00710839219391346,
                -0.02625115215778351,
                -0.017146367579698563,
                0.01830347254872322,
                -0.014805429615080357,
                -0.043165870010852814,
                0.01157771423459053,
                -0.08277208358049393,
                -0.04772435501217842,
                0.01869829185307026,
                -0.00894157774746418,
                0.07537239789962769,
                2.1918413040111773e-07,
                -0.08827167004346848,
                0.009817579761147499,
                0.05357160046696663,
                0.057523827999830246,
                0.021858958527445793,
                -0.022182198241353035,
                0.017080826684832573,
                -0.028042631223797798,
                -0.045261405408382416,
                -0.05347881466150284,
                0.025333285331726074,
                -0.00766023201867938,
                -0.022171515971422195,
                0.011873739771544933,
                0.002145305974408984,
                0.01828473061323166,
                0.07766963541507721,
                -0.07728368043899536,
                -0.005649981554597616,
                -0.034906547516584396,
                0.046375520527362823,
                -4.558065120363608e-05,
                -0.023648139089345932,
                -0.03919828683137894,
                -0.035737521946430206,
                -0.032609377056360245,
                -0.007014891132712364,
                0.0005927757010795176,
                0.019090192392468452,
                -0.033085547387599945,
                0.06285900622606277,
                0.06548436731100082,
                -0.0018534909468144178,
                0.04372364282608032,
                0.011220697313547134,
                -0.021496305242180824,
                0.09171221405267715,
                -0.04566295072436333,
                -0.021219458431005478,
                -0.002904631430283189,
                -0.04690475016832352,
                -0.02052333764731884,
                0.014616858214139938,
                -0.055754777044057846,
                0.007306238170713186,
                0.02626538835465908,
                0.031125744804739952,
                -0.06028980016708374,
                -0.014049224555492401,
                -0.034250497817993164,
                -0.024158334359526634,
                0.010113367810845375,
                -0.012144121341407299,
                -0.04553592950105667,
                0.0036943608429282904,
                -0.00022039253963157535,
                -0.00027696980396285653,
                0.03326936066150665,
                0.03430537134408951,
                0.011973442509770393,
                -0.015949442982673645,
                -0.039528653025627136,
                0.0410306490957737,
                -0.00955118052661419,
                -0.022951364517211914,
                -0.01795201376080513,
                -0.0626087635755539,
                1.4930292393185956e-34,
                -0.021152690052986145,
                -0.013450682163238525,
                -0.07846613973379135,
                0.028971537947654724,
                -0.003956375643610954,
                0.000752915337216109,
                -0.06032789126038551,
                -0.023081932216882706,
                0.008389449678361416,
                -0.06008829548954964,
                0.012105545960366726,
                0.013227777555584908,
                -0.09282462298870087,
                0.0013183251721784472,
                0.013758844695985317,
                -0.011457639746367931,
                -0.02666820026934147,
                0.014190937392413616,
                -0.011500251479446888,
                -0.044333186000585556,
                0.0009029649663716555,
                -0.00011477393127279356,
                0.05352241173386574,
                0.03370261564850807,
                0.057951707392930984,
                0.038030412048101425,
                -0.07046998292207718,
                -0.0010141282109543681,
                -0.04292695224285126,
                -0.0763363242149353,
                -0.006359010934829712,
                -0.03251046687364578,
                -0.019546259194612503,
                -0.008276420645415783,
                -0.0012073492398485541,
                0.027777552604675293,
                -0.012895038351416588,
                0.0028281344566494226,
                0.007836476899683475,
                0.028690580278635025,
                -0.03968467190861702,
                0.06917285174131393,
                -0.006919275037944317,
                0.06697048991918564,
                0.0412604920566082,
                1.2730092748824973e-06,
                -0.014728634618222713,
                -0.039298370480537415,
                -0.023834560066461563,
                0.029752565547823906,
                -0.01349630393087864,
                0.03297287970781326,
                0.03249647095799446,
                0.00655357725918293,
                0.0052544535137712955,
                -0.006440115626901388,
                -0.044075336307287216,
                0.04851145297288895,
                0.005548103246837854,
                -0.02329775132238865,
                0.04940570145845413,
                -0.011500908061861992,
                0.006774276960641146,
                -0.05564228817820549,
                0.007185307331383228,
                -0.023793278262019157,
                0.06942331790924072,
                0.011379346251487732,
                0.03876684606075287,
                0.03316788747906685,
                -0.003598781768232584,
                -0.0710231363773346,
                -0.03858175501227379,
                0.0011943093268200755,
                -0.0076790014281868935,
                0.015042259357869625,
                0.06459948420524597,
                -0.014476633630692959,
                -0.0282761063426733,
                -0.061283938586711884,
                -0.02121562324464321,
                -0.0311591774225235,
                0.000969268789049238,
                0.052577175199985504,
                0.05727666988968849,
                -0.07083510607481003,
                0.00625240383669734,
                -0.02082400768995285,
                -0.005787860136479139,
                -0.011093778535723686,
                0.013020394369959831,
                -0.06378046423196793,
                -0.005598160903900862,
                -0.02130214124917984,
                -0.015100767835974693,
                -0.018716977909207344,
                0.025213466957211494,
                -0.011107114143669605,
                0.011708582751452923,
                -0.022591061890125275,
                0.00454268604516983,
                0.014359523542225361,
                -0.020678430795669556,
                0.006910969037562609,
                0.00031934454455040395,
                0.006711977533996105,
                -0.017994791269302368,
                -0.029913507401943207,
                0.02533198893070221,
                0.022807838395237923,
                -0.010782342404127121,
                0.020421670749783516,
                -0.009045989252626896,
                -0.030644318088889122,
                0.0389396958053112,
                0.010208695195615292,
                0.04459593445062637,
                -0.015557052567601204,
                0.018123915418982506,
                -0.009776303544640541,
                -0.0362924262881279,
                -0.02923015132546425,
                -0.018738800659775734,
                -0.00953192263841629,
                0.049328841269016266,
                -0.013238956220448017,
                -0.007626770995557308,
                0.000966557883657515,
                0.060047801584005356,
                0.027493160218000412,
                -0.016189856454730034,
                -0.039238519966602325,
                0.014781179837882519,
                -0.019479254260659218,
                -5.85949637752492e-05,
                -0.02123931236565113,
                0.0029811346903443336,
                0.03823092207312584,
                -0.008487045764923096,
                0.01540153007954359,
                0.0010929442942142487,
                0.030507372692227364,
                0.047841835767030716,
                -0.0281516145914793,
                -0.035454463213682175,
                -0.043319232761859894,
                0.031242305412888527,
                -0.009059704840183258,
                -0.0676800087094307,
                0.021911874413490295,
                -0.02338346466422081,
                0.01492353342473507,
                -0.05924975872039795,
                0.0004916095058433712,
                -0.034349821507930756,
                -0.055476751178503036,
                -0.013504963368177414,
                0.03628133237361908,
                0.07075624912977219,
                0.026243751868605614,
                -0.01760173961520195,
                -0.006523728370666504,
                0.04157617688179016,
                -0.02328810654580593,
                0.026209257543087006,
                0.00592842185869813,
                -0.018397245556116104,
                -0.017929743975400925,
                0.0036661711055785418,
                -0.01794525794684887,
                -0.04249173775315285,
                0.027485130354762077,
                0.0038766220677644014,
                0.00760582135990262,
                0.017109869047999382,
                -0.040624119341373444,
                0.026684248819947243,
                0.00510132173076272,
                0.018274318426847458,
                -0.016789456829428673,
                -0.030467713251709938,
                -0.04014820232987404,
                -0.0434325635433197,
                0.002036246471107006,
                0.0597049742937088,
                0.04393288493156433,
                -0.017008865252137184,
                -0.03909920156002045,
                -0.016254371032118797,
                -0.0013059049379080534,
                0.06008906289935112,
                0.028499634936451912,
                -0.04170691594481468,
                0.05635113641619682,
                -0.015719542279839516,
                -0.016629658639431,
                -0.014155742712318897,
                0.07032305747270584,
                0.010902868583798409,
                -0.011043563485145569,
                -0.013215695507824421,
                -0.023985933512449265,
                -0.06982727348804474,
                -0.05600249022245407,
                -0.010689872317016125,
                0.03226040303707123,
                -0.03046596422791481,
                -0.0013139634393155575,
                -0.037573039531707764,
                -0.005185896530747414,
                0.05179092660546303,
                0.0004110444278921932,
                -0.02486499957740307,
                0.020364411175251007,
                -0.024315843358635902,
                0.03427254781126976,
                -0.034660182893276215,
                -0.0072464910335838795,
                -0.001951044425368309,
                -0.018387416377663612,
                -0.012318666093051434,
                0.044318441301584244,
                0.037895992398262024,
                -0.009004265069961548,
                0.06348833441734314,
                -0.002100352430716157,
                0.046680498868227005,
                0.02363918535411358,
                0.037517085671424866,
                -0.005419858731329441,
                0.06166877597570419,
                -0.06924952566623688,
                -0.03261233866214752,
                -0.031084556132555008,
                0.0014384136302396655,
                0.07973577827215195,
                0.029217932373285294,
                -0.018852435052394867,
                0.03405734896659851,
                -0.016887182369828224,
                0.041225288063287735,
                -0.02697639726102352,
                0.016118917614221573,
                0.0031565427780151367,
                0.0336315743625164,
                0.03778049722313881,
                0.010313632898032665,
                0.13656951487064362,
                0.05566726624965668,
                -0.016085417941212654,
                -0.06288909912109375,
                0.02367558144032955,
                0.09606479108333588,
                -0.0338960625231266,
                0.022297803312540054,
                -0.05182800069451332,
                -0.001735136960633099,
                -0.0021651594433933496,
                0.006270776502788067,
                0.05208578705787659,
                0.0530569925904274,
                -0.004560567904263735,
                0.021992454305291176,
                -0.014671954326331615,
                -0.009200380183756351,
                0.03046381287276745,
                0.019274866208434105,
                -0.03720860928297043,
                0.07925329357385635,
                0.003914359491318464,
                0.018252944573760033,
                0.055256206542253494,
                -0.036252543330192566,
                -0.04466090351343155,
                -0.03711804375052452,
                0.02164001204073429,
                0.029878176748752594,
                0.0358133502304554,
                0.04824279248714447,
                -0.025144077837467194,
                -0.006401375401765108,
                -0.06212335452437401,
                0.06341169774532318,
                -0.010796246118843555,
                0.03822780027985573,
                -0.005997893866151571,
                0.02230067364871502,
                -0.004228684585541487,
                0.0036593095865100622,
                -0.07432319968938828,
                0.048376165330410004,
                -0.011558295227587223,
                -0.0655219703912735,
                0.0009469413198530674,
                -0.004466800019145012,
                0.05482814460992813,
                0.018521631136536598,
                0.06084051728248596,
                0.00566525012254715,
                -0.08166871964931488,
                -0.02764136530458927,
                -0.05152640491724014,
                -0.012990927323698997,
                0.033801544457674026,
                -0.07119860500097275,
                -0.0037764431908726692,
                0.019320184364914894,
                -0.026706695556640625,
                0.032188449054956436,
                0.02641550451517105,
                -0.010575775057077408,
                0.07538571953773499,
                0.04066474735736847,
                -0.0025262697599828243,
                0.01167782861739397,
                -0.06070668622851372,
                -0.03486337140202522,
                0.026205753907561302,
                0.04222909361124039,
                0.005571833346039057,
                -0.055843621492385864,
                -0.0386626273393631,
                0.01841730624437332,
                0.03965436667203903,
                -0.027189314365386963,
                -0.025154609233140945,
                -0.008221512660384178,
                0.020242983475327492,
                -0.0010503436205908656,
                -0.017539028078317642,
                0.07559652626514435,
                -0.020889468491077423,
                -0.0055455733090639114,
                -0.007400446105748415,
                -0.03149754926562309,
                -0.04261085018515587,
                0.007510494440793991,
                -0.010070783086121082,
                -0.022724132984876633,
                -0.03026253543794155,
                -0.018011344596743584,
                -0.006959992926567793,
                0.031218362972140312,
                -0.005931745283305645,
                0.05112791433930397,
                -0.04673533886671066,
                -0.022352352738380432,
                -0.014338985085487366,
                -0.03844887763261795,
                -0.027731996029615402,
                0.040731415152549744,
                -0.0180918388068676,
                -0.03154356777667999,
                -0.012446818873286247,
                0.026412151753902435,
                0.033989034593105316,
                -0.03547719120979309,
                -0.05628617852926254,
                -0.04723219946026802,
                0.032480012625455856,
                -0.03212437406182289,
                0.027778713032603264,
                0.024947520345449448,
                -0.022515811026096344,
                0.008502457290887833,
                -0.007443134672939777,
                0.025265229865908623,
                0.013787208124995232,
                -0.06531314551830292,
                0.01565137319266796,
                0.05494077876210213,
                0.039104755967855453,
                -0.034386586397886276,
                0.04367503151297569,
                -0.08668731898069382,
                -0.06664737313985825,
                0.018306870013475418,
                0.012643222697079182,
                0.006163717247545719,
                0.02791055105626583,
                0.019420728087425232,
                0.041409704834222794,
                0.015142889693379402,
                -0.027823608368635178,
                -0.04246049001812935,
                -0.01955900341272354,
                -0.014522470533847809,
                -0.019695524126291275,
                -0.0022404708433896303,
                0.0251265000551939,
                -0.019440630450844765,
                0.016557341441512108,
                0.023685818538069725,
                0.06015656888484955,
                0.0010243031429126859,
                0.016167163848876953,
                -0.030879570171236992,
                -0.04978311434388161,
                0.0028164496179670095,
                -0.03824340179562569,
                0.03267877548933029,
                -0.012019488029181957,
                -0.027236299589276314,
                -0.08282037079334259,
                0.018960878252983093,
                0.03363935649394989,
                0.01942828670144081,
                -0.016245456412434578,
                0.05000670999288559,
                -0.050416670739650726,
                0.033827219158411026,
                0.027034474536776543,
                -0.04966561868786812,
                0.029586484655737877,
                0.023099323734641075,
                -0.046160049736499786,
                -0.023755479604005814,
                0.03978808969259262,
                -0.014043728820979595,
                -0.0001740779698593542,
                -0.00655450951308012,
                -0.018306950107216835,
                0.006969544570893049,
                0.03985301032662392,
                -0.03928178548812866,
                -0.037709735333919525,
                0.02389974519610405,
                -0.016634277999401093,
                -0.051757924258708954,
                -0.005882179830223322,
                0.04737382382154465,
                0.0047480883076786995,
                -0.014534411020576954,
                0.04379450902342796,
                -0.03800605237483978,
                0.001339609851129353,
                -0.02727220393717289,
                -0.042109593749046326,
                0.022853584960103035,
                -0.03136986494064331,
                -0.08281562477350235,
                0.04360077157616615,
                -0.06498593091964722,
                0.029529636725783348,
                0.014980457723140717,
                0.03538230434060097,
                -0.04208787530660629,
                -0.03364397585391998,
                -0.04916539415717125,
                -0.049535058438777924,
                -0.04857758805155754,
                -0.06258255243301392,
                -0.00010097755148308352,
                0.005550670903176069,
                -0.006159307435154915,
                0.018647512421011925,
                0.04227134957909584,
                0.05647739768028259,
                -0.06651078909635544,
                -0.055098436772823334,
                -0.025193994864821434,
                -0.019290810450911522,
                0.03321933001279831,
                0.03216514736413956,
                0.01506592333316803,
                0.010264826938509941,
                -0.0029038020875304937,
                -0.06102783977985382,
                0.07294895499944687,
                0.014635888859629631,
                0.07751700282096863,
                0.025142410770058632,
                0.03244894742965698,
                -0.03410816565155983,
                -0.010241539217531681,
                0.004702866543084383,
                0.02690345048904419,
                -0.03638765215873718,
                -0.0278162881731987,
                -0.0028019710443913937,
                0.004115902353078127,
                0.04486348107457161,
                0.014781403355300426,
                0.009049393236637115,
                -0.0704554095864296,
                -0.026811374351382256,
                0.05901409685611725,
                -0.056807082146406174,
                -0.009592912159860134,
                0.024497738108038902,
                0.039150670170784,
                -0.041408464312553406,
                0.040989041328430176,
                0.016266971826553345,
                0.06920754909515381,
                0.02219388633966446,
                -0.015158876776695251,
                -0.006492209155112505,
                0.01958046667277813,
                0.007381211034953594,
                0.06861759722232819,
                -0.038083311170339584,
                -0.017766861245036125,
                0.007119856774806976,
                0.04110221564769745,
                0.038777075707912445,
                -0.009022860787808895,
                0.06762712448835373,
                -0.04477037116885185,
                0.010426404885947704,
                -0.032258085906505585,
                -0.025941355153918266,
                0.05017026141285896,
                -0.006758874747902155,
                -0.012098259292542934,
                0.004782250616699457,
                -0.0194950383156538,
                -0.0078084422275424,
                0.04807582497596741,
                0.007168336771428585,
                -0.028065649792551994,
                0.030409252271056175,
                -0.01660507544875145,
                0.01616939902305603,
                -0.06991122663021088,
                0.05065860226750374,
                0.06774484366178513,
                0.07331649959087372,
                0.06906303763389587,
                -0.02718912623822689,
                -0.05396445840597153,
                0.04111799970269203,
                -0.03222003951668739,
                0.01120717916637659,
                -0.0018069329671561718,
                0.06183943152427673,
                -0.03442590311169624,
                0.036523256450891495,
                0.008457653224468231,
                0.025787023827433586,
                -0.016466455534100533,
                0.031757716089487076,
                0.016530802473425865,
                0.02813013456761837,
                0.030697019770741463,
                -0.016673007979989052,
                0.06540019065141678,
                0.023713454604148865,
                -0.05755363032221794,
                -0.01956230401992798,
                0.001518855569884181,
                0.019896989688277245,
                -0.004817210603505373,
                0.019493816420435905,
                0.011647231876850128,
                -0.00038346636574715376,
                -0.04735671356320381,
                -0.027878444641828537,
                -0.030477607622742653,
                0.01990930549800396,
                -0.021192030981183052,
                0.012128549627959728,
                -0.014701711013913155,
                -0.06153067946434021,
                0.01764393225312233,
                0.019285349175333977,
                -5.656509305541572e-33,
                -0.0034462204203009605,
                -0.05773702263832092,
                -0.0005683680064976215,
                -0.00853902567178011,
                -0.03916792571544647,
                0.007918709889054298,
                0.038002513349056244,
                0.04826508089900017,
                -0.024299338459968567,
                -0.0391191728413105,
                0.00392614770680666,
                -0.02314719557762146,
                0.02867428958415985,
                -0.005601977463811636,
                -0.008352819830179214,
                -0.0032925547566264868,
                -0.0061960346065461636,
                -0.013384681195020676,
                -0.05563640221953392,
                -0.04819069057703018,
                -0.008904027752578259,
                -0.004669372923672199,
                -0.02834130823612213,
                -0.027354925870895386,
                0.020055081695318222,
                -0.049658920615911484,
                -0.03258081153035164,
                0.09446968883275986,
                -0.012543771415948868,
                -0.020024660974740982,
                0.016302870586514473,
                -0.004748261533677578,
                0.02026531472802162,
                0.026852939277887344,
                -0.053014468401670456,
                0.051400743424892426,
                -0.03486502915620804,
                -0.003931282553821802,
                -0.04820781946182251,
                0.04060891270637512,
                -0.056531693786382675,
                -0.03442399948835373,
                0.05817702040076256,
                -0.006672551389783621,
                -0.014688230119645596,
                -0.028501855209469795,
                0.016197478398680687,
                0.003803125349804759,
                0.033857930451631546,
                0.03287006914615631,
                -0.030406035482883453,
                -0.011182569898664951,
                -0.021060079336166382,
                -0.016683533787727356,
                0.0628349781036377,
                0.03767409175634384,
                -0.00928503554314375,
                -0.0036664665676653385,
                -0.015398463234305382,
                -0.008396080695092678,
                -0.060135647654533386,
                0.0765257179737091,
                0.005570251494646072,
                -0.05646466463804245,
                -0.018715906888246536,
                0.02582089975476265,
                -0.07031192630529404,
                -0.020839063450694084,
                0.0031289411708712578,
                0.0005982212605886161,
                -0.06742540746927261,
                0.10001224279403687,
                0.04563600942492485,
                -0.011830940842628479,
                -0.000699955562595278,
                0.032405950129032135,
                -0.004361559171229601,
                -0.029486455023288727,
                -0.040248241275548935,
                0.06996649503707886,
                0.07971606403589249,
                -0.0413910374045372,
                -0.016892459243535995,
                0.045841414481401443,
                0.08373439311981201,
                -0.07565707713365555,
                -0.031027410179376602,
                -0.020717930048704147,
                0.04195764288306236,
                0.034891825169324875,
                -0.03685392811894417,
                0.01073728408664465,
                -0.006373798009008169,
                0.025679241865873337,
                0.048007309436798096,
                0.011727300472557545,
                0.03646141290664673,
                -0.05146348476409912,
                -0.005841720849275589,
                -0.045398738235235214,
                0.06271252036094666,
                0.01176143903285265,
                -0.026144124567508698,
                -0.01721608266234398,
                0.002383680548518896,
                -0.003924914635717869,
                -0.081487737596035,
                0.027962395921349525,
                -0.0059221587143838406,
                0.03193158283829689,
                0.022432874888181686,
                0.026030560955405235,
                -0.005743935238569975,
                0.0017784747760742903,
                0.02509569190442562,
                -0.020676473155617714,
                0.029754670336842537,
                0.011224036104977131,
                -0.028421711176633835,
                -0.11424346268177032,
                -0.04076728597283363,
                -0.032735761255025864,
                -0.03378576040267944,
                0.019219035282731056,
                -0.042689163237810135,
                0.03553518280386925,
                -0.025977469980716705,
                0.0159788578748703,
                2.8122372896177694e-05,
                0.0572938397526741,
                -0.02673356607556343,
                -0.032528460025787354,
                2.0263425426492176e-07,
                -0.005147718358784914,
                0.0343174934387207,
                0.10437222570180893,
                0.038856375962495804,
                -0.010567321442067623,
                0.00407122727483511,
                0.02118605375289917,
                0.047832462936639786,
                0.017738990485668182,
                0.002988032763823867,
                -0.025313153862953186,
                -0.04420141503214836,
                -0.028845934197306633,
                -0.043157368898391724,
                0.01762872003018856,
                -0.05626746639609337,
                0.032097674906253815,
                0.04818163439631462,
                -0.02559341862797737,
                -0.044740598648786545,
                0.11707746982574463,
                0.009297854267060757,
                0.04742515832185745,
                0.013986729085445404,
                -0.041791971772909164,
                -0.041675955057144165,
                -0.03055904060602188,
                0.00998504925519228,
                0.04176543280482292,
                -0.010954120196402073,
                0.025339188054203987,
                0.06951851397752762,
                -0.02493414655327797,
                0.015340685844421387,
                0.014402524568140507,
                -0.03987735137343407,
                -0.005033294204622507,
                0.052112046629190445,
                -0.031599730253219604,
                -0.06522707641124725,
                -0.041048042476177216,
                -0.020832175388932228,
                -0.035307880491018295,
                0.0030864407308399677,
                0.00040219008224084973,
                -0.010571464896202087,
                0.017943503335118294,
                0.03647635877132416,
                0.00038602418499067426,
                -0.007821775041520596,
                -0.002156866015866399,
                0.0168576892465353,
                0.050883468240499496,
                -0.01712447591125965,
                -0.036084726452827454,
                0.01684054732322693,
                -0.004200732801109552,
                0.05827978625893593,
                0.04404042661190033,
                -0.01019943505525589,
                -0.033184509724378586,
                0.014869962818920612,
                0.03915945068001747,
                0.06754165887832642,
                0.013345976360142231,
                0.05176273360848427,
                -0.029569867998361588,
                6.84933868533076e-35,
                -0.05034540593624115,
                -0.035433776676654816,
                -0.05059792101383209,
                0.054092779755592346,
                -0.04500717669725418,
                0.030883122235536575,
                -0.028998639434576035,
                -0.009541245177388191,
                -0.031894877552986145,
                -0.016809353604912758,
                0.014034407213330269,
                -0.04348258301615715,
                -0.029778888449072838,
                -0.03911971673369408,
                0.019519327208399773,
                -0.02142840251326561,
                -9.028072963701561e-05,
                0.005129225552082062,
                0.04991162568330765,
                -0.046971097588539124,
                0.006125899031758308,
                0.08195419609546661,
                0.0057904887944459915,
                0.006060614716261625,
                0.002445630729198456,
                0.024785609915852547,
                -0.024428289383649826,
                -0.014865516684949398,
                0.025189025327563286,
                -0.08516959846019745,
                -0.024662354961037636,
                -0.04745810851454735,
                -0.003616356523707509,
                -0.03050266019999981,
                0.02131565473973751,
                0.0009543045307509601,
                0.04132243990898132,
                -0.009949794970452785,
                -0.03727133944630623,
                0.03885914757847786,
                0.0573154091835022,
                0.00021815745276398957,
                0.03253443166613579,
                -0.01221675705164671,
                0.06954138725996017,
                1.312009771936573e-06,
                0.024570109322667122,
                0.04745054990053177,
                0.01880682446062565,
                0.03581380471587181,
                0.017362935468554497,
                0.017575543373823166,
                -0.05325650796294212,
                -0.05617411807179451,
                0.006112277042120695,
                0.014868670143187046,
                0.011443426832556725,
                0.009815533645451069,
                -0.024962879717350006,
                -0.05459454283118248,
                -0.024205844849348068,
                0.009558378718793392,
                -0.018790554255247116,
                -0.022087788209319115,
                -0.03172144666314125,
                0.03856455534696579,
                -0.020609447732567787,
                0.0029262141324579716,
                0.018752116709947586,
                0.051303453743457794,
                0.05367325246334076,
                -0.04544110968708992,
                0.008450105786323547,
                0.010943376459181309,
                -0.03132857754826546,
                0.04343272000551224,
                0.02892148680984974,
                0.0006074985140003264,
                0.023343026638031006,
                0.005431962199509144,
                0.0440792590379715,
                0.035813625901937485,
                -0.0065758186392486095,
                -0.000974592927377671,
                0.02143559604883194,
                -0.030599622055888176,
                0.03338411822915077,
                -0.0043941219337284565,
                0.03503895923495293,
                0.002913976553827524,
                -0.023100215941667557,
                -0.023525958880782127,
                -0.01905542053282261,
                -0.018486233428120613,
                0.02589678205549717,
                0.012623336166143417,
                -0.015525267459452152,
                -0.027429841458797455,
                0.021041324362158775,
                0.021286122500896454,
                0.027966128662228584,
                0.04668011516332626,
                -0.005426474846899509,
                0.021828506141901016,
                -0.0008623651810921729,
                0.03146204724907875,
                -0.017223525792360306,
                0.014760802499949932,
                -0.04797844961285591,
                0.015142377465963364,
                -0.03181992471218109,
                -0.07061368227005005,
                0.03406403586268425,
                0.06484848260879517,
                0.03655657544732094,
                -0.07860639691352844,
                0.005786328576505184,
                -0.005981937516480684,
                0.018103426322340965,
                -0.025903107598423958,
                0.03460772708058357,
                -0.0916864424943924,
                -0.03559546172618866,
                0.01467872690409422,
                -0.03526483103632927,
                0.03164738044142723,
                0.02328753098845482,
                -0.005036903545260429,
                0.010362190194427967,
                0.02582140825688839,
                -0.022635802626609802,
                -0.022683434188365936,
                0.0013554117176681757,
                -0.04141790047287941,
                -0.028809068724513054,
                -0.010286068543791771,
                0.03891982510685921,
                -0.02950957417488098,
                0.02885376289486885,
                0.04443034529685974,
                0.04998168349266052,
                -0.03219159319996834,
                0.0381787046790123,
                -0.028739644214510918,
                -0.0274844691157341,
                -0.026937615126371384,
                0.031861983239650726,
                -0.03598407283425331,
                0.07040353119373322,
                -0.03909533843398094,
                -0.07726384699344635,
                0.023947304114699364,
                -0.018636111170053482,
                -0.004113425966352224,
                0.056376248598098755,
                0.012771185487508774,
                -0.004253210499882698,
                0.04601460322737694,
                0.01953877881169319,
                0.048344843089580536,
                -0.027197424322366714,
                -0.030720200389623642,
                0.004972806200385094,
                0.011601626873016357,
                -0.020262081176042557,
                0.058312494307756424,
                0.037617236375808716,
                0.01176376547664404,
                0.006266073789447546,
                -0.06055792048573494,
                0.0488632433116436,
                -0.012521078810095787,
                1.920400063681882e-05,
                0.012129233218729496,
                -0.003621066454797983,
                -0.004568359814584255,
                -0.03712170198559761,
                -0.033834297209978104,
                -0.05890234187245369,
                -0.07041686773300171,
                0.005663098767399788,
                -0.003036763519048691,
                -0.014239853248000145,
                -0.026150504127144814,
                0.08831702917814255,
                0.009391981177031994,
                -0.003788670524954796,
                0.04633908346295357,
                0.045721158385276794,
                -0.013371394015848637,
                0.030677763745188713,
                0.03695164620876312,
                -0.09504860639572144,
                0.05895792692899704,
                -0.02783099375665188,
                -0.039034828543663025,
                -0.023140668869018555,
                0.0037231335882097483,
                -0.028374897316098213,
                -0.07004822045564651,
                -0.053772252053022385,
                0.03619015961885452,
                -0.018765214830636978,
                -0.015812693163752556,
                0.0019151661545038223,
                0.03970784693956375,
                0.012719903141260147,
                0.028402969241142273,
                0.014374064281582832,
                -0.0026039048098027706,
                0.04882539063692093,
                -0.06186631694436073,
                0.01381539274007082,
                -0.0423172302544117,
                -0.01180938072502613,
                -0.006012259051203728,
                -0.043939705938100815,
                -0.011742915026843548,
                -0.008544838055968285,
                -0.04601428285241127,
                0.010789982974529266,
                0.028188321739435196,
                0.022188834846019745,
                0.013759768567979336,
                0.024173032492399216,
                -0.014046846888959408,
                -0.010160583071410656,
                -0.004622636362910271,
                -0.035487908869981766,
                -0.04363727942109108,
                -0.01068603340536356,
                -0.008209769614040852,
                -0.02229570597410202,
                -0.00211661821231246,
                -0.0379912443459034,
                -0.022568540647625923,
                0.023578960448503494,
                0.03828791528940201,
                -0.01145110186189413,
                -0.04223103076219559,
                0.022455774247646332,
                0.04185541346669197,
                -0.000104983126220759,
                0.013062489219009876,
                -0.027589933946728706,
                -0.01000240258872509,
                0.04680371657013893,
                0.06624507158994675,
                0.09212802350521088,
                0.023084254935383797,
                -0.1048920750617981,
                0.0071515487506985664,
                -0.04136208817362785,
                0.027049154043197632,
                -0.026556290686130524,
                0.020701609551906586,
                -0.01758819818496704,
                -0.030265333130955696,
                0.03592851758003235,
                0.02870607189834118,
                0.0038717063143849373,
                -0.03996487334370613,
                -0.03986247256398201,
                0.03307797759771347,
                0.027543246746063232,
                0.053458090871572495,
                -0.01974445953965187,
                0.02028079889714718,
                0.02758726105093956,
                0.02023494616150856,
                -0.0827212855219841,
                0.11709374934434891,
                -0.06263789534568787,
                -0.02887798473238945,
                0.019351471215486526,
                0.016522403806447983,
                -0.0070573738776147366,
                -0.03278428316116333,
                -0.024954913184046745,
                -0.061002302914857864,
                0.0514458604156971,
                -0.04324495047330856,
                0.025141727179288864,
                0.04219377413392067,
                0.0025282062124460936,
                0.02463383413851261,
                0.046109944581985474,
                0.010484005324542522,
                -0.0148171940818429,
                -0.11277304589748383,
                0.04143813997507095,
                0.026883598417043686,
                0.013698246330022812,
                -0.006060215178877115,
                -0.020193759351968765,
                0.016182301566004753,
                0.015918882563710213,
                0.08478862047195435,
                -0.0021035640966147184,
                -0.05127471312880516,
                0.036128703504800797,
                -0.004204283468425274,
                -0.052145201712846756,
                0.017472628504037857,
                -0.07587791234254837,
                -0.03163618966937065,
                0.022090809419751167,
                0.028575440868735313,
                0.004040634259581566,
                0.020403822883963585,
                0.025689542293548584,
                -0.0002630712988320738,
                0.021204903721809387,
                0.061077676713466644,
                -0.008770876564085484,
                0.008762543089687824,
                0.011304948478937149,
                0.024791225790977478,
                -0.03324016183614731,
                -0.0322185717523098,
                -0.007286114618182182,
                -0.010457026772201061,
                0.04429676756262779,
                -0.0018030103528872132,
                0.051636893302202225,
                4.5361874072114006e-05,
                -0.0389213040471077,
                0.00947739277034998,
                0.0009201544453389943,
                -0.0019113447051495314,
                0.04574979841709137,
                0.036149196326732635,
                0.05735741928219795,
                -0.02424485608935356,
                0.0027885071467608213,
                0.037256237119436264,
                0.04405265673995018,
                -0.005820493679493666,
                0.023219630122184753,
                0.026384510099887848,
                -0.031569045037031174,
                0.005347390193492174,
                0.07264464348554611,
                -0.018760129809379578,
                0.05549008026719093,
                0.10986214131116867,
                -0.027371857315301895,
                -0.013280009850859642,
                -0.07642154395580292,
                -0.04339761659502983,
                0.04260063171386719,
                -0.030412182211875916,
                -0.04886436462402344,
                -0.03852942958474159,
                -0.015672342851758003,
                -0.0003379657573532313,
                0.0302367452532053,
                -0.014165730215609074,
                -0.007782311644405127,
                0.027784135192632675,
                -0.056651704013347626,
                0.07266507297754288,
                0.020896362140774727,
                -0.014187082648277283,
                0.014678467065095901,
                -0.06794090569019318,
                0.02538662776350975,
                -0.00858001597225666,
                0.05775022134184837,
                -0.020568525418639183,
                -0.011096748523414135,
                -0.001618883921764791,
                -0.023692579939961433,
                0.02191784605383873,
                -0.01255626231431961,
                -0.05180075019598007,
                0.0038457370828837156,
                0.030095916241407394,
                0.059944357722997665,
                -0.0062643359415233135,
                0.017205480486154556,
                -0.04051969572901726,
                0.020688192918896675,
                -0.056514233350753784,
                -0.023931462317705154,
                -0.014294473454356194,
                0.04279101639986038,
                0.010526416823267937,
                0.03346561640501022,
                0.03385424241423607,
                -0.032685574144124985,
                0.004600345157086849,
                -0.031072435900568962,
                0.008737089112401009,
                0.023603659123182297,
                0.009723849594593048,
                -0.020001471042633057,
                -0.008216802030801773,
                0.018786266446113586,
                0.007003327831625938,
                0.012312016449868679,
                0.007287808693945408,
                0.0068863676860928535,
                -0.09847962111234665,
                0.003278884571045637,
                -0.0011333659058436751,
                -0.008441726677119732,
                -0.00845339521765709,
                0.01299755647778511,
                0.01606440171599388,
                0.05421772226691246,
                0.009496145881712437,
                0.034305837005376816,
                -0.048974402248859406,
                0.010220283642411232,
                -0.029848022386431694,
                -0.0309336856007576,
                0.04453461989760399,
                0.0033758000936359167,
                -0.07184717804193497,
                0.0019541680812835693,
                0.07278874516487122,
                0.013145525008440018,
                0.04343998059630394,
                -0.07854374498128891,
                -0.014410324394702911,
                0.01924963667988777,
                0.0012409334303811193,
                -0.03867621347308159,
                -0.01878354139626026,
                -0.045111555606126785,
                -0.017076851800084114,
                0.009219747968018055,
                0.05306606739759445,
                -0.056068193167448044,
                0.07830000668764114,
                -0.026011483743786812,
                -0.06237969547510147,
                -0.0059812734834849834,
                -0.012758200988173485,
                -0.014940403401851654,
                -0.05902347341179848,
                -0.06288576871156693,
                -0.062204811722040176,
                0.049875639379024506,
                -0.0061574368737638,
                -0.06616095453500748,
                -0.058274876326322556,
                0.025727568194270134,
                -0.012294594198465347,
                0.018657058477401733,
                0.02968231961131096,
                -0.003099350258708,
                -0.016941389068961143,
                -0.0075050657615065575,
                0.03338312730193138,
                0.07997208833694458,
                0.0171539094299078,
                -0.05373862013220787,
                -0.06885326653718948,
                -0.005318423733115196,
                0.06375777721405029,
                0.0015332550974562764,
                0.01799902878701687,
                -0.05215756222605705,
                -0.03105488233268261,
                -0.021351991221308708,
                -0.09509562700986862,
                0.04204021766781807,
                -0.006937152706086636,
                0.03378530591726303,
                0.05036387965083122,
                0.027020277455449104,
                -0.01734166592359543,
                -0.03198736533522606,
                -0.013049840927124023,
                -0.024485958740115166,
                -0.02104474976658821,
                -0.06855069100856781,
                -0.01644858345389366,
                -0.020733542740345,
                -0.05459633097052574,
                0.022188158705830574,
                -0.04618961364030838,
                0.026940329000353813,
                -0.039223723113536835,
                -0.02641720324754715,
                -0.005553572904318571,
                0.004106104373931885,
                -0.013190834783017635,
                -0.001184034743346274,
                0.03706124797463417,
                -0.012255119159817696,
                -0.009601177647709846,
                0.009792052209377289,
                -0.01456417329609394,
                0.028136350214481354,
                0.0787673145532608,
                -0.025269506499171257,
                0.03968879207968712,
                -0.0014815565664321184,
                0.05204642936587334,
                -0.10418377816677094,
                -0.016745397821068764,
                -0.03101225569844246,
                0.03906992822885513,
                0.046364862471818924,
                0.015475727617740631,
                0.07595659047365189,
                -0.06437898427248001,
                0.03821844980120659,
                -0.024431493133306503,
                -0.0054706246592104435,
                0.021826501935720444,
                -0.002190573140978813,
                -0.0003084490308538079,
                0.061616718769073486,
                -0.02685202844440937,
                0.00016538970521651208,
                -0.010973982512950897,
                0.012842678464949131,
                0.022090688347816467,
                0.004293904174119234,
                0.007120652589946985,
                0.003321646247059107,
                -0.01853206753730774,
                -0.011973317712545395,
                -0.0033455833327025175,
                -0.01324236486107111,
                0.021465333178639412,
                -0.0020196593832224607,
                -0.01345429103821516,
                -0.023357581347227097,
                0.022079922258853912,
                -0.03596806898713112,
                0.07320468872785568,
                -0.049389101564884186,
                0.018020786345005035,
                0.06319647282361984,
                -0.01946951076388359,
                0.011037429794669151,
                -0.03622303158044815,
                -0.026094136759638786,
                0.006454039365053177,
                0.04907151684165001,
                -0.02135547436773777,
                0.09370818734169006,
                0.06325949728488922,
                0.017827393487095833,
                -0.003664424642920494,
                -0.00045860683894716203,
                -0.019131531938910484,
                0.01721877232193947,
                -0.008019612170755863,
                0.03910130634903908,
                0.03537478297948837,
                -0.032612692564725876,
                0.026887694373726845,
                0.005963063333183527,
                -0.0011294493451714516,
                0.04217558726668358,
                -0.013151349499821663,
                -0.00524186110123992,
                0.04848949611186981,
                0.07773474603891373,
                0.014458036050200462,
                -5.4609008007510046e-33,
                -0.02057351917028427,
                -0.047145672142505646,
                -0.0003823061706498265,
                -0.0011282929917797446,
                -0.10183858871459961,
                -0.029090769588947296,
                0.009556678123772144,
                0.017446160316467285,
                -0.08140601217746735,
                -0.010150430724024773,
                -0.02281278744339943,
                -0.01593172922730446,
                0.0040076738223433495,
                0.023848367854952812,
                0.04069836065173149,
                -0.017333146184682846,
                0.0022734063677489758,
                0.015753474086523056,
                -0.016581004485487938,
                -0.036423008888959885,
                -0.05624888464808464,
                0.023555133491754532,
                -0.003718520049005747,
                0.04543662816286087,
                0.025608215481042862,
                -0.02494955249130726,
                -0.04313896596431732,
                -0.0017385617829859257,
                -0.03327201306819916,
                -0.06794607639312744,
                0.006162955425679684,
                0.021002275869250298,
                0.008685506880283356,
                -0.011302894912660122,
                -0.02249571681022644,
                0.024819806218147278,
                -0.027934547513723373,
                -0.05316311493515968,
                -0.07532769441604614,
                0.03687386214733124,
                -0.007737343665212393,
                0.004695994779467583,
                0.027022507041692734,
                -0.0047908504493534565,
                -0.06259133666753769,
                -0.07770316302776337,
                -0.02493671327829361,
                -0.025706464424729347,
                0.06486654281616211,
                -0.02126200869679451,
                -0.044701915234327316,
                -0.011072992347180843,
                -0.014718777500092983,
                -0.014054683968424797,
                0.07243235409259796,
                0.027411017566919327,
                0.005048524122685194,
                0.02342357113957405,
                0.013711037114262581,
                0.0057209269143640995,
                -0.021925995126366615,
                0.05991338565945625,
                0.03793932870030403,
                -0.06044921651482582,
                -0.0019830255769193172,
                -0.007830079644918442,
                -0.030072705820202827,
                -0.002772072795778513,
                0.017061034217476845,
                -0.04410944879055023,
                -0.05409404635429382,
                0.09552476555109024,
                -0.024471404030919075,
                -0.05921493098139763,
                0.002108264248818159,
                -0.05399766564369202,
                -0.03941820561885834,
                -0.02415391616523266,
                0.020863601937890053,
                0.06437326222658157,
                -0.02458968386054039,
                0.024084122851490974,
                0.01720988191664219,
                -0.02173973061144352,
                0.0025806985795497894,
                -0.019244302064180374,
                0.010626114904880524,
                0.058368753641843796,
                -0.03702366352081299,
                0.0284236129373312,
                0.03458976373076439,
                -0.0834108516573906,
                -0.01342364214360714,
                0.010400144383311272,
                0.10109516978263855,
                0.016904348507523537,
                0.022849146276712418,
                0.001967556541785598,
                -0.007169011514633894,
                0.035934850573539734,
                -0.015707645565271378,
                0.01485268771648407,
                -0.08098001033067703,
                0.017099104821681976,
                0.035951752215623856,
                -0.016008751466870308,
                -0.07686595618724823,
                -0.021052522584795952,
                -0.0026609408669173717,
                0.04396781325340271,
                -0.019813118502497673,
                -0.041858378797769547,
                0.031535737216472626,
                -0.0016093867598101497,
                -0.0044192178174853325,
                0.0005366248078644276,
                0.004073965363204479,
                -0.07056599855422974,
                -0.0008041977416723967,
                0.023419244214892387,
                -0.0013939483324065804,
                -0.0038067607674747705,
                -0.02319895289838314,
                0.020894348621368408,
                -0.03407827019691467,
                -0.01012229360640049,
                0.08510877937078476,
                -0.022955143824219704,
                0.0030928808264434338,
                -0.0617334209382534,
                -0.040756359696388245,
                0.005499489139765501,
                1.9102436965567904e-07,
                -0.02353648841381073,
                0.04294178634881973,
                0.03311556950211525,
                0.04611586779356003,
                -0.0007473539444617927,
                0.02119755931198597,
                -0.04043853282928467,
                0.015198473818600178,
                0.02828456461429596,
                -0.013334169052541256,
                0.05233006179332733,
                -0.02945292741060257,
                0.025548165664076805,
                -0.039028823375701904,
                0.009274258278310299,
                -0.004325773101300001,
                0.052838560193777084,
                0.05706879869103432,
                0.013506232760846615,
                0.015713682398200035,
                0.012650774791836739,
                0.02277122624218464,
                -0.013447022065520287,
                0.009764399379491806,
                -0.056625157594680786,
                0.0035292913671582937,
                0.006716476753354073,
                -0.017798611894249916,
                0.06034538149833679,
                0.015106644481420517,
                0.03657907620072365,
                0.011419708840548992,
                0.0006348345195874572,
                0.03433895483613014,
                -0.000357273209374398,
                -0.022493911907076836,
                0.06775566190481186,
                0.006481189746409655,
                -0.022223547101020813,
                0.057421982288360596,
                -0.0164497010409832,
                0.038556959480047226,
                0.038670122623443604,
                0.03724781796336174,
                -0.006093703676015139,
                0.012285923585295677,
                0.005459011998027563,
                -0.005830645095556974,
                -0.05594542622566223,
                0.001050956780090928,
                0.0007099588983692229,
                0.06306498497724533,
                0.043574683368206024,
                -0.03760172054171562,
                0.023609092459082603,
                0.029032405465841293,
                -0.03486734256148338,
                0.0011232802644371986,
                0.006089712958782911,
                0.03422490134835243,
                -0.04545048996806145,
                -0.014401452615857124,
                0.02319691702723503,
                0.003940479829907417,
                0.04698022082448006,
                -0.005566594656556845,
                -0.05511431768536568,
                6.734341129098022e-35,
                -0.012021316215395927,
                -0.008849271573126316,
                -0.0023831562139093876,
                0.06248542293906212,
                -0.0033044489100575447,
                -0.012914927676320076,
                0.017408909276127815,
                0.021719591692090034,
                0.010575736872851849,
                0.011386492289602757,
                -0.03675946593284607,
                0.07317197322845459,
                -0.03093470260500908,
                -0.029247304424643517,
                -0.010951932519674301,
                0.02564707025885582,
                0.04238792881369591,
                -0.0028654311317950487,
                0.01174980029463768,
                -0.056646816432476044,
                0.03891661763191223,
                0.06662610173225403,
                -0.0026963951531797647,
                -0.024054264649748802,
                0.01532681193202734,
                -0.00041438103653490543,
                -0.06647034734487534,
                0.01427672803401947,
                -0.018143605440855026,
                0.012026666663587093,
                -0.0120093934237957,
                0.014281029812991619,
                0.004243532661348581,
                0.02770441770553589,
                -0.0027564954943954945,
                0.0014620316214859486,
                -0.012984282337129116,
                0.017116257920861244,
                0.009311260655522346,
                0.040608759969472885,
                0.02439725399017334,
                -0.006171525921672583,
                0.0026619117707014084,
                -0.04286013916134834,
                -0.06293780356645584,
                1.6368960586987669e-06,
                0.04919574409723282,
                0.0063478536903858185,
                -0.06465469300746918,
                -0.028761953115463257,
                0.015748927369713783,
                0.00841689296066761,
                -0.01434394158422947,
                0.018124248832464218,
                0.011447659693658352,
                -0.014908702112734318,
                0.029377015307545662,
                0.04680958017706871,
                -0.02597319334745407,
                0.036028217524290085,
                -0.020365910604596138,
                0.021026646718382835,
                0.03584739938378334,
                -0.08112572133541107,
                -0.02897382713854313,
                -0.05212981253862381,
                -0.07504277676343918,
                -0.007405023090541363,
                -0.014620513655245304,
                0.019678210839629173,
                0.016476953402161598,
                0.016957975924015045,
                -0.04795581102371216,
                0.002922071609646082,
                0.01361759752035141,
                0.035210512578487396,
                0.028843093663454056,
                0.03788182511925697,
                -0.0273451991379261,
                0.021920911967754364,
                -0.016161957755684853,
                0.026655221357941628,
                0.040953174233436584,
                -0.017359748482704163,
                0.025179989635944366,
                0.05448923259973526,
                0.005529606249183416,
                0.04588807001709938,
                -0.01372735109180212,
                0.006156823597848415,
                -0.02164354920387268,
                -0.0843164250254631,
                0.05151385813951492,
                -0.03011753037571907,
                0.006986946798861027,
                0.0129754189401865,
                0.0274207703769207,
                -0.018057135865092278,
                0.0033812476322054863,
                -0.06082383543252945,
                0.057632703334093094,
                -0.030141619965434074,
                -0.013066514395177364,
                0.004466245882213116,
                0.03187889978289604,
                -0.046200916171073914,
                -0.0073935408145189285,
                -0.001971375197172165,
                -0.05438126251101494,
                -0.014014563523232937,
                -0.01848066970705986,
                -0.03997757285833359,
                0.017834439873695374,
                0.03529850393533707,
                0.03009815141558647,
                0.049341246485710144,
                -0.015608116053044796,
                -0.011086787097156048,
                -0.03932845592498779,
                -0.04204951226711273,
                -0.046236954629421234,
                -0.07625707983970642,
                0.026884282007813454,
                0.012998460792005062,
                0.00025642861146479845,
                0.0007448829128406942,
                -0.03047218918800354,
                -0.027017030864953995,
                0.017316428944468498,
                0.040022365748882294,
                0.0126592256128788,
                -0.001449049566872418,
                0.011631098575890064,
                -0.03108600154519081,
                0.020080897957086563,
                -0.00592239573597908,
                -0.004768430721014738,
                -0.0685131847858429,
                -0.011820116080343723,
                -0.01377864833921194,
                0.06341726332902908,
                0.014325062744319439,
                -0.024431439116597176,
                -0.02249187044799328,
                0.003771977499127388,
                0.029028937220573425,
                0.000981913530267775,
                -0.015646208077669144,
                0.027459900826215744,
                -0.012798862531781197,
                0.011117012239992619,
                0.013965570367872715,
                -0.03196212649345398,
                -0.08022644370794296,
                -0.035117894411087036,
                -0.03628712147474289,
                -0.026638254523277283,
                -0.031797803938388824,
                -0.008382733911275864,
                0.004633219446986914,
                -0.00922254379838705,
                -0.014062128029763699,
                -0.03578487038612366,
                -0.01730206236243248,
                0.04201815649867058,
                0.04127313569188118,
                0.06447011977434158,
                -0.015813332051038742,
                -0.029400309547781944,
                -0.005845085717737675,
                0.03998544067144394,
                0.007863184437155724,
                0.05240924283862114,
                0.035817328840494156,
                0.027874905616044998,
                -0.03868643194437027,
                0.026774812489748,
                0.07372751832008362,
                -0.02910594642162323,
                -0.05266434699296951,
                0.022002073004841805,
                -0.07026642560958862,
                0.04781469702720642,
                -0.021411724388599396,
                0.057002656161785126,
                0.02578434906899929,
                -0.04687967151403427,
                0.07421009242534637,
                -0.01653784140944481,
                -0.014600268565118313,
                -0.021808352321386337,
                -0.055892519652843475,
                -0.09183763712644577,
                -0.0014188558561727405,
                0.06771459430456161,
                0.06590074300765991,
                0.03604469448328018,
                0.0233920868486166,
                0.07862348109483719,
                -0.05971401184797287,
                0.0024894734378904104,
                0.03613660857081413,
                0.015054972842335701,
                -0.0703660100698471,
                0.004926125984638929,
                0.010496406815946102,
                -0.04101115092635155,
                -0.0127181401476264,
                0.0030535601545125246,
                -0.021677862852811813,
                -0.02023790031671524,
                0.031161092221736908,
                0.012571588158607483,
                0.024630075320601463,
                0.02096410281956196,
                -0.0347035713493824,
                0.00504075363278389,
                -0.05981514975428581,
                -0.015549466013908386,
                -0.015347870998084545,
                -0.015701010823249817,
                0.051152974367141724,
                0.015275709331035614,
                -0.0011931107146665454,
                0.00921743456274271,
                0.006134588271379471,
                0.10048031061887741,
                -0.00531114311888814,
                0.022921130061149597,
                -0.07107523083686829,
                0.028027230873703957,
                -0.037415795028209686,
                0.051747433841228485,
                0.010719570331275463,
                0.027396654710173607,
                -0.002523142611607909,
                -0.0464913472533226,
                -0.015890013426542282,
                -0.03066413290798664,
                0.014476754702627659,
                0.01590215601027012,
                -0.014594667591154575,
                -0.00761293713003397,
                0.012283846735954285,
                -0.008707523345947266,
                0.0035648790653795004,
                0.047414954751729965,
                0.03306860849261284,
                -0.002534823725000024,
                0.016556065529584885,
                -0.021622464060783386,
                -0.07365324348211288,
                -0.013601625338196754,
                0.0023050031159073114,
                -0.02358696050941944,
                0.08331190049648285,
                0.030744178220629692,
                -0.03216591477394104,
                -0.007679402362555265,
                -0.005627416539937258,
                0.007233879063278437,
                -0.02638566493988037,
                0.0188438817858696,
                0.03623003512620926,
                -0.02192503586411476,
                -0.026040157303214073,
                0.02777019329369068,
                -0.02260473184287548,
                0.003574516624212265,
                -0.029535068199038506,
                0.038330525159835815,
                0.03186425566673279,
                0.03978070616722107,
                0.003834962612017989,
                -0.0031831124797463417,
                0.060177188366651535,
                -0.0013496653409674764,
                -0.004559820052236319,
                -0.02650410123169422,
                0.01503746211528778,
                -0.0018787310691550374,
                -0.004224312491714954,
                -0.02323967032134533,
                0.03732069581747055,
                -0.011209526099264622,
                -0.02604309469461441,
                -0.01176298875361681,
                -0.019671708345413208,
                0.0628991350531578,
                -0.016691049560904503,
                -0.0060322773642838,
                0.026405569165945053,
                -0.033755771815776825,
                -0.051714085042476654,
                0.04745220020413399,
                0.014777722768485546,
                -0.02040053717792034,
                0.02688378468155861,
                -0.05677645280957222,
                -0.06015896797180176,
                0.006825558375567198,
                -0.03537176921963692,
                -0.022883273661136627,
                0.026728196069598198,
                -0.007262256927788258,
                -0.010746004991233349,
                -0.013193577527999878,
                0.021753627806901932,
                -0.019384970888495445,
                -0.007890898734331131,
                -0.0052678221836686134,
                -4.410795736475848e-05,
                -0.025217054411768913,
                0.04323921725153923,
                -0.0024178284220397472,
                -0.004028265364468098,
                -0.04317517951130867,
                0.0072711012326180935,
                0.0045410338789224625,
                -0.03234168142080307,
                0.025564739480614662,
                0.020479191094636917,
                0.02243155986070633,
                0.017015092074871063,
                0.014480167999863625,
                -0.018964242190122604,
                0.024288257583975792,
                -0.026686055585741997,
                -0.03650205209851265,
                0.007423187140375376,
                0.06858380138874054,
                -0.007390101905912161,
                0.04935721680521965,
                -0.02437429688870907,
                -0.002092591021209955,
                -0.0024587162770330906,
                -0.021257080137729645,
                -0.0650378093123436,
                -0.028652319684624672,
                0.052301980555057526,
                -0.026662811636924744,
                -0.005352430045604706,
                -0.013552514836192131,
                -0.048449113965034485,
                -0.014453190378844738,
                -0.010007322765886784,
                0.0019531166180968285,
                0.0008592843078076839,
                -0.042702335864305496,
                -0.018774814903736115,
                -0.010462285950779915,
                0.0016457847086712718,
                0.03587944433093071,
                -0.010967635549604893,
                0.04430685564875603,
                -0.0549430325627327,
                0.01810828037559986,
                0.05721268802881241,
                0.03390166163444519,
                -0.06908734142780304,
                0.004217556677758694,
                0.02599954977631569,
                -0.037808217108249664,
                0.0023726860526949167,
                -0.02649282105267048,
                0.00698165874928236,
                -0.010843340307474136,
                -0.0683126449584961,
                -0.01779853366315365,
                0.0003409619675949216,
                -0.12330231815576553,
                -0.038023896515369415,
                0.049479663372039795,
                0.0842815637588501,
                -0.025982122868299484,
                -0.007538786623626947,
                -0.031113045290112495,
                0.015094801783561707,
                0.033345673233270645,
                -0.00470339460298419,
                0.007750682067126036,
                0.02588837407529354,
                0.03208864480257034,
                -0.022563794627785683,
                0.037144191563129425,
                0.01574457623064518,
                0.012787946499884129,
                0.0076822200790047646,
                0.020105885341763496,
                -0.023796865716576576,
                -0.04588988423347473,
                0.01701047457754612,
                0.02199062705039978,
                0.007907556369900703,
                -0.01991613395512104,
                -0.008416282944381237,
                0.02136254496872425,
                -0.018606489524245262,
                0.008238418959081173,
                0.031598541885614395,
                -0.012992795556783676,
                0.01558251865208149,
                0.012921061366796494,
                -0.04122277349233627,
                0.06445327401161194,
                0.045922569930553436,
                -0.03151458501815796,
                0.03130238875746727,
                0.007756763603538275,
                0.004146516788750887,
                0.011267341673374176,
                0.024096081033349037,
                0.002784157870337367,
                0.0160446185618639,
                0.03428893908858299,
                -0.12212328612804413,
                -0.03618541732430458,
                0.08190806955099106,
                0.032815176993608475,
                0.013301987200975418,
                -0.01827276684343815,
                -0.0034249534364789724,
                0.009293453767895699,
                0.02399611286818981,
                -0.0017351703718304634,
                0.038152582943439484,
                -0.09105173498392105,
                -0.01464229915291071,
                0.003555694129317999,
                0.003059020033106208,
                -0.028385845944285393,
                0.07579536736011505,
                0.006023641675710678,
                -0.02662634290754795,
                -0.011571884155273438,
                0.04516543075442314,
                -0.008337034843862057,
                0.016717955470085144,
                0.026526985689997673,
                -0.008940353989601135,
                -0.021094078198075294,
                -0.017039300873875618,
                0.02641076035797596,
                -0.022767426446080208,
                0.06489087641239166,
                0.01186305470764637,
                -0.05320482701063156,
                -0.022298598662018776,
                0.0069564892910420895,
                0.08678504079580307,
                -0.005848045460879803,
                -0.0316460020840168,
                0.09770938754081726,
                -0.016434166580438614,
                0.01370935421437025,
                -0.0015046773478388786,
                -0.09440511465072632,
                0.024686818942427635,
                0.01832769438624382,
                -0.09147336333990097,
                0.026732830330729485,
                0.028131766244769096,
                -0.008659029379487038,
                -0.09021110832691193,
                -0.033918678760528564,
                0.039138566702604294,
                0.03511586785316467,
                -0.018925979733467102,
                0.011946259066462517,
                0.01955069787800312,
                -0.029239492490887642,
                0.03817524015903473,
                0.021613331511616707,
                -0.04823606461286545,
                0.03790133446455002,
                -0.015582125633955002,
                -0.03035367839038372,
                -0.020472528412938118,
                -0.0389934778213501,
                0.06284687668085098,
                -0.05232495814561844,
                0.030326027423143387,
                -0.02922804467380047,
                -0.008233652450144291,
                -0.0013289469061419368,
                0.09006566554307938,
                0.022071760147809982,
                -0.009818457067012787,
                -0.05519444867968559,
                0.02265964262187481,
                0.005261545535176992,
                -0.053278855979442596,
                -0.02918734960258007,
                0.008858236484229565,
                -0.02876872941851616,
                -0.00844604428857565,
                -0.021968090906739235,
                0.008727235719561577,
                -0.004660761449486017,
                -0.0023268514778465033,
                -0.04049958288669586,
                -0.002993122674524784,
                -0.009823117405176163,
                0.005484170280396938,
                9.197941835736856e-05,
                -0.010526248253881931,
                0.018507476896047592,
                0.0060254051350057125,
                0.0786709412932396,
                0.0063833920285105705,
                0.0023097547236829996,
                -0.014162426814436913,
                0.023220587521791458,
                -0.021145837381482124,
                -0.049567412585020065,
                -0.011383156292140484,
                -0.03065762296319008,
                0.018126053735613823,
                -0.00831358041614294,
                -0.019910864531993866,
                -0.009830392897129059,
                0.00866538193076849,
                -0.018676914274692535,
                0.0031760220881551504,
                -0.05248900502920151,
                0.07756649702787399,
                0.011201698333024979,
                0.010798613540828228,
                -0.004230172373354435,
                -0.00640006922185421,
                -0.03193630650639534,
                0.024337541311979294,
                0.0028543504886329174,
                -0.011439183726906776,
                -0.045153968036174774,
                0.10821817070245743,
                -0.011973904445767403,
                0.021743271499872208,
                -0.007000803016126156,
                0.0016842378536239266,
                0.007722335867583752,
                0.006061949767172337,
                -0.04390377178788185,
                -0.06608288735151291,
                0.05738767981529236,
                -0.02596718817949295,
                0.1222444698214531,
                0.01662558503448963,
                0.05863526836037636,
                -0.005071297287940979,
                0.013100171461701393,
                -0.05337076634168625,
                0.08578771352767944,
                0.025318045169115067,
                0.007522914092987776,
                0.03226018324494362,
                -0.0008520361152477562,
                -0.05871022492647171,
                0.035055261105298996,
                0.039215702563524246,
                0.02222803235054016,
                -0.028723103925585747,
                -0.020297197625041008,
                -0.015114235691726208,
                0.022239401936531067,
                -0.01643330231308937,
                -5.895891750588823e-33,
                -0.04253622889518738,
                -0.06290190666913986,
                0.012269853614270687,
                0.02663746289908886,
                -0.021571051329374313,
                -0.027153532952070236,
                0.03560072183609009,
                0.002991508459672332,
                -0.00669738557189703,
                -0.035792116075754166,
                -0.006752440705895424,
                0.021100006997585297,
                0.010681903921067715,
                -0.03634572774171829,
                0.08162158727645874,
                -0.0016502629732713103,
                0.05224711447954178,
                -0.0019849094096571207,
                0.020717879757285118,
                0.0007995573687367141,
                -0.0005810121074318886,
                0.05771058052778244,
                0.029125502333045006,
                0.03827153518795967,
                -0.04419563338160515,
                -0.023683739826083183,
                0.003900673007592559,
                0.01727563887834549,
                0.017548181116580963,
                -0.00622641621157527,
                0.029887380078434944,
                -0.08741750568151474,
                -0.025511829182505608,
                -0.0729493573307991,
                0.012660635635256767,
                0.07250837981700897,
                0.008514112792909145,
                0.004611519165337086,
                -0.014608989469707012,
                -0.021009033545851707,
                1.0157580618397333e-05,
                -0.016929369419813156,
                -0.019029051065444946,
                -0.020809073001146317,
                0.03359531611204147,
                -0.07078611105680466,
                0.010711527429521084,
                -0.01955239474773407,
                0.012251144275069237,
                0.023350516334176064,
                0.006711009424179792,
                0.03620411455631256,
                -0.003995541017502546,
                0.0013710373314097524,
                0.042551636695861816,
                -0.025644388049840927,
                -0.035267848521471024,
                -0.014656172133982182,
                -0.04308752715587616,
                -0.009379149414598942,
                -0.030222352594137192,
                0.02020014449954033,
                -0.03514161333441734,
                0.005271442234516144,
                -0.016069313511252403,
                0.02472674660384655,
                -0.027760982513427734,
                0.04490647837519646,
                -0.024863366037607193,
                -0.0027740257792174816,
                -0.060916874557733536,
                0.10320398211479187,
                0.04231930151581764,
                -0.033078812062740326,
                -0.07876840233802795,
                0.04122799262404442,
                0.007405449170619249,
                -0.008651492185890675,
                0.06356067210435867,
                0.01559471432119608,
                -0.06938176602125168,
                0.09239133447408676,
                -0.014906256459653378,
                0.023239921778440475,
                0.0446111261844635,
                -0.06198471412062645,
                0.0008494078065268695,
                -0.024356316775083542,
                0.004902603104710579,
                0.015649082139134407,
                -0.052282169461250305,
                0.03134346380829811,
                0.03650140389800072,
                0.05624643713235855,
                0.04804514721035957,
                0.003790251910686493,
                0.0431629978120327,
                0.007033807225525379,
                -0.041363321244716644,
                -0.03303936496376991,
                -0.09333761781454086,
                0.015154378488659859,
                -0.06168390065431595,
                0.0036698176991194487,
                -0.013679934665560722,
                -0.026475252583622932,
                -0.013826780021190643,
                -0.024787135422229767,
                0.012847649864852428,
                0.005945980083197355,
                0.030973495915532112,
                -0.0003853047383017838,
                -0.01857377029955387,
                -0.07010547071695328,
                -0.0296019297093153,
                -0.03842596709728241,
                0.023479290306568146,
                -0.045008335262537,
                0.018522609025239944,
                -0.08605821430683136,
                -0.011907492764294147,
                0.05664963647723198,
                -0.038374487310647964,
                -0.03581947460770607,
                -0.031208720058202744,
                -0.010722316801548004,
                0.005457791965454817,
                0.00904497317969799,
                0.06693413108587265,
                0.016496486961841583,
                0.002495566848665476,
                -0.05474405735731125,
                2.2294889845397847e-07,
                0.020064525306224823,
                0.015796255320310593,
                0.038815416395664215,
                0.012688576243817806,
                0.020159123465418816,
                0.03420598804950714,
                -0.01914241351187229,
                0.024989185854792595,
                0.016308393329381943,
                0.010645591653883457,
                0.10225728154182434,
                0.026269709691405296,
                -0.017893685027956963,
                0.0226765014231205,
                0.0675659105181694,
                -0.08643591403961182,
                -0.00816434994339943,
                -0.031913843005895615,
                0.0022858004085719585,
                -0.0672372579574585,
                -0.005381499417126179,
                0.022014135494828224,
                0.088179811835289,
                -0.0006256377091631293,
                0.00658615306019783,
                -0.015168179757893085,
                -0.008736838586628437,
                -0.01587180607020855,
                0.0022820739541202784,
                0.02356390841305256,
                0.022964157164096832,
                -0.06844154000282288,
                -0.06666717678308487,
                0.021525338292121887,
                0.040729817003011703,
                -0.04502476379275322,
                0.029218453913927078,
                2.3594091544509865e-05,
                -0.013444538228213787,
                0.08393004536628723,
                0.016075488179922104,
                -0.005323922261595726,
                -0.03854191675782204,
                0.09863942116498947,
                -0.036860495805740356,
                -0.02465955913066864,
                -0.035080816596746445,
                0.012851499952375889,
                -0.05652304366230965,
                -0.004225137177854776,
                0.016327282413840294,
                0.07220987975597382,
                0.0489807203412056,
                0.062136102467775345,
                -0.018237963318824768,
                -0.0004158297961112112,
                -0.0222859475761652,
                0.021096104755997658,
                0.04740210995078087,
                -0.01758022978901863,
                -0.007600563578307629,
                -0.029752274975180626,
                0.031867243349552155,
                0.014323566108942032,
                -0.0695795789361,
                0.03761444613337517,
                -0.030994843691587448,
                4.47556416215976e-35,
                -0.03708750382065773,
                -0.03755137324333191,
                -0.0645279735326767,
                -0.010468635708093643,
                0.006723751313984394,
                -0.024382509291172028,
                -0.05610457807779312,
                -0.023548481985926628,
                0.026783660054206848,
                0.04258003085851669,
                -0.01880459487438202,
                0.015240948647260666,
                0.038784392178058624,
                -0.00827794335782528,
                -0.019049111753702164,
                -0.0034658685326576233,
                0.0037156715989112854,
                -0.031820427626371384,
                0.011625164188444614,
                -0.017615577206015587,
                -0.021147212013602257,
                -0.009865643456578255,
                0.03352765738964081,
                -0.009929836727678776,
                0.10107724368572235,
                -0.010191039182245731,
                0.010621214285492897,
                -0.03254270926117897,
                0.04165445640683174,
                -0.03415725752711296,
                0.028461715206503868,
                -0.023623257875442505,
                -0.025290215387940407,
                0.008059414103627205,
                -0.019288023933768272,
                -0.006807605270296335,
                -0.03683099150657654,
                0.03117038682103157,
                -0.0007453608559444547,
                -0.027228740975260735,
                -0.01824881136417389,
                0.060388363897800446,
                -0.005768016912043095,
                0.006915153004229069,
                -0.029143990948796272,
                1.4111348036749405e-06,
                0.002333088079467416,
                -0.07131452113389969,
                0.014134051278233528,
                -0.06154026463627815,
                0.00891630444675684,
                -0.017074886709451675,
                0.01844177395105362,
                -0.0026871964801102877,
                0.019616493955254555,
                0.00632005650550127,
                0.06976516544818878,
                0.004139040131121874,
                -0.02053964138031006,
                -0.003518881741911173,
                0.002571894321590662,
                -0.014139516279101372,
                0.017821533605456352,
                -0.09034412354230881,
                0.013821876607835293,
                -0.08514594286680222,
                -0.03307972475886345,
                -0.0006462055025622249,
                0.008229202590882778,
                0.011191001161932945,
                -0.06774470955133438,
                -0.0351576991379261,
                -0.005987467244267464,
                0.008394315838813782,
                -0.023507272824645042,
                0.038834597915410995,
                0.019405921921133995,
                -0.02104029804468155,
                0.04054154455661774,
                -0.03770313039422035,
                -0.007443552371114492,
                0.019292112439870834,
                0.04106428101658821,
                -0.015019003301858902,
                0.020821979269385338,
                -0.03661936894059181,
                -0.11176992207765579,
                -0.017130238935351372,
                -0.04131348803639412,
                0.03068879432976246,
                0.0121298898011446,
                -0.07512491196393967,
                0.05285785347223282,
                0.008098666556179523,
                -0.003173273056745529,
                -0.007090981118381023,
                0.031448930501937866,
                -0.032329168170690536,
                -0.022943435236811638,
                0.015529259108006954,
                0.03686143085360527,
                0.011475599370896816,
                0.018140651285648346,
                0.027947349473834038,
                0.009078681468963623,
                -0.0456618033349514,
                -0.029608089476823807,
                0.10429994016885757,
                0.022690214216709137,
                -0.00450828718021512,
                -0.04604705795645714,
                0.027366451919078827,
                0.007372966036200523,
                -0.0013915180461481214,
                0.011835619807243347,
                -0.07770553976297379,
                0.048372238874435425,
                0.047706883400678635,
                0.011538463644683361,
                -0.05127758905291557,
                0.04192850738763809,
                0.006483935285359621,
                -0.01845761202275753,
                -0.0351717434823513,
                0.035054635256528854,
                -0.004520354326814413,
                -0.03710690513253212,
                -0.03455396369099617,
                0.033286649733781815,
                -0.015955328941345215,
                0.03702661767601967,
                0.047802750021219254,
                0.023754628375172615,
                -0.04183163121342659,
                -0.01580595225095749,
                -0.014568322338163853,
                0.02015865221619606,
                -0.007686279714107513,
                0.04022882878780365,
                -0.01661299541592598,
                0.017350267618894577,
                0.02661816217005253,
                0.02527523599565029,
                -0.01125919446349144,
                0.04329461231827736,
                0.008455948904156685,
                0.04804312810301781,
                -0.008859585970640182,
                0.02579527162015438,
                -0.04875682666897774,
                0.02522891014814377,
                0.00020758676691912115,
                0.0010426738299429417,
                -0.05389304459095001,
                0.008749052882194519,
                -0.059781044721603394,
                -0.005709504242986441,
                -0.049350179731845856,
                0.055374521762132645,
                0.025923732668161392,
                -0.011893258430063725,
                -0.003111034631729126,
                -0.08095667511224747,
                0.03549257665872574,
                0.007423157803714275,
                0.0550801083445549,
                0.023479431867599487,
                0.01720416732132435,
                -0.07397396862506866,
                0.028575126081705093,
                0.030711010098457336,
                0.002534983679652214,
                0.03448045253753662,
                0.05556450039148331,
                0.01992860995233059,
                -0.06074609234929085,
                -0.025346416980028152,
                0.10148502886295319,
                0.04110175371170044,
                -0.056843675673007965,
                0.007861044257879257,
                -0.011263789609074593,
                -0.010960767976939678,
                -0.004886135924607515,
                0.022859487682580948,
                -0.008016189560294151,
                -0.10475620627403259,
                -0.02802133746445179,
                -0.031088974326848984,
                -0.01117341686040163,
                -0.025785353034734726,
                -0.015157814137637615,
                -0.06812463700771332,
                0.06269601732492447,
                -0.002711690030992031,
                0.10966939479112625,
                0.04939465969800949,
                -0.00325256260111928,
                -0.005557972937822342,
                -0.019150657579302788,
                0.023835865780711174,
                -0.001573102897964418,
                -0.018018070608377457,
                -0.017608296126127243,
                0.047374527901411057,
                0.017378760501742363,
                0.014584526419639587,
                -0.10102161765098572,
                -0.03754622861742973,
                -0.06758876144886017,
                0.05744278058409691,
                -0.028570527210831642,
                -0.06024656444787979,
                -0.022737225517630577,
                -0.010808948427438736,
                0.024835575371980667,
                -0.007215602323412895,
                0.010430733673274517,
                -0.014178517274558544,
                -0.035378895699977875,
                0.031274329870939255,
                0.0625620111823082,
                -0.009425530210137367,
                0.009318573400378227,
                0.030399806797504425,
                0.00780025590211153,
                0.04736596718430519,
                0.022811418399214745,
                0.010635760612785816,
                -0.008563658222556114,
                0.009945298545062542,
                -0.0017078480450436473,
                0.018955854699015617,
                -0.003510214388370514,
                -0.022831501439213753,
                0.013464946299791336,
                -0.012916240841150284,
                -0.001060959999449551,
                0.027812765911221504,
                0.004244658630341291,
                0.02526085264980793,
                0.044306039810180664,
                0.034574221819639206,
                0.014500903896987438,
                0.01532408781349659,
                -0.013034963980317116,
                0.04082605987787247,
                0.05694112181663513,
                0.101819708943367,
                0.010884842835366726,
                0.004297028295695782,
                -0.04894089698791504,
                0.017086010426282883,
                0.01801270991563797,
                0.016904965043067932,
                -0.02645854279398918,
                0.011084473691880703,
                0.01928350329399109,
                -0.010206230916082859,
                0.011230239644646645,
                0.036857496947050095,
                -0.037738900631666183,
                -0.027952095493674278,
                0.014750231057405472,
                0.0028963331133127213,
                -0.008707618340849876,
                -0.06813308596611023,
                -0.06990931928157806,
                0.021784892305731773,
                0.0015962894540280104,
                0.008152800612151623,
                0.02464408054947853,
                -0.027414504438638687,
                0.004824383184313774,
                0.004837344400584698,
                0.0311309602111578,
                -0.025377150624990463,
                0.020555980503559113,
                -0.04991019144654274,
                0.01598929613828659,
                0.033480625599622726,
                -0.007055844645947218,
                0.007241702638566494,
                -0.005062761716544628,
                -0.016301292926073074,
                -0.023661134764552116,
                0.02113034762442112,
                0.022901689633727074,
                0.0409887321293354,
                -0.046947792172431946,
                0.007456181105226278,
                0.04730580374598503,
                -0.09647881239652634,
                0.01214179489761591,
                0.05478408560156822,
                0.0037545093800872564,
                -0.028171170502901077,
                -0.0035865812096744776,
                0.07484284043312073,
                -0.02055414579808712,
                -0.021631410345435143,
                -0.04286689683794975,
                -0.0560753159224987,
                0.0033585242927074432,
                -0.04602084681391716,
                0.009823997505009174,
                0.050654780119657516,
                0.03475968912243843,
                -0.04586723819375038,
                -0.03544703871011734,
                -0.01784473843872547,
                -0.022703638300299644,
                -0.07333660125732422,
                0.06564924120903015,
                -0.06064845249056816,
                -0.0721549466252327,
                -0.04012857750058174,
                0.048485517501831055,
                -0.014482667669653893,
                -0.014082834124565125,
                -0.017639219760894775,
                0.04043647646903992,
                -0.0038237893022596836,
                0.04634556546807289,
                -0.07594436407089233,
                0.017999041825532913,
                0.00022549243294633925,
                -0.039421211928129196,
                0.013347698375582695,
                -0.045528851449489594,
                0.08092266321182251,
                0.015152889303863049,
                0.020418299362063408,
                -0.04376138001680374,
                0.04655107855796814,
                0.01974138244986534,
                -0.03409218415617943,
                -0.013855112716555595,
                -0.015275315381586552,
                -0.012652182951569557,
                -0.0755564421415329,
                0.01483823824673891,
                -0.09803850203752518,
                -0.049582589417696,
                -0.012379591353237629,
                0.0006780611583963037,
                -0.028424082323908806,
                -0.007066320162266493,
                -0.039406489580869675,
                0.009524582885205746,
                -0.007349213119596243,
                0.02401871792972088,
                -0.020645413547754288,
                -0.021476605907082558,
                0.050963930785655975,
                0.044882882386446,
                0.01816648058593273,
                0.01692701131105423,
                0.048208728432655334,
                -0.002996518276631832,
                -0.0064257727935910225,
                -0.017152410000562668,
                0.07708750665187836,
                -0.016424329951405525,
                -0.005723088979721069,
                -0.0036017419770359993,
                -0.03877667337656021,
                -0.012014414183795452,
                0.07410944998264313,
                0.07617729157209396,
                -0.0420488603413105,
                -0.024326875805854797,
                -0.0092446468770504,
                0.019198594614863396,
                -0.009667793288826942,
                -0.012364712543785572,
                0.0025111904833465815,
                0.006094376090914011,
                -0.014809461310505867,
                0.028730418533086777,
                0.05223178118467331,
                -0.05516880005598068,
                0.022198636084794998,
                -0.012657173909246922,
                -0.04495863616466522,
                -0.007269400171935558,
                0.03743130713701248,
                -0.02048570290207863,
                0.03000333346426487,
                -0.03195015713572502,
                0.014147646725177765,
                0.07220374047756195,
                -0.017018213868141174,
                0.1054573580622673,
                -0.02403239533305168,
                0.013551479205489159,
                -0.028911007568240166,
                -0.028410421684384346,
                -0.04622728377580643,
                0.03732529655098915,
                0.07559837400913239,
                -0.03739236295223236,
                0.06082205846905708,
                -0.051462940871715546,
                -0.02225274220108986,
                0.007790484931319952,
                -0.028300167992711067,
                0.0043772743083536625,
                -0.004435851704329252,
                0.013001373037695885,
                -0.040017690509557724,
                0.02355537749826908,
                0.03065483085811138,
                -0.05878693237900734,
                0.027182817459106445,
                0.0046821837313473225,
                -0.0642348900437355,
                0.04264584183692932,
                -0.016171736642718315,
                0.010971665382385254,
                0.015185070224106312,
                -0.0017746686935424805,
                0.08170437067747116,
                -0.021070757880806923,
                0.039360225200653076,
                0.000750395527575165,
                -0.04372343793511391,
                -0.00916181318461895,
                -0.03446588292717934,
                -0.0020454234909266233,
                -0.039523929357528687,
                0.01973595842719078,
                0.004772927146404982,
                0.004525808151811361,
                -0.0028993834275752306,
                0.014744301326572895,
                0.04273755103349686,
                0.010614004917442799,
                0.02805999107658863,
                0.05872293934226036,
                -0.04555314779281616,
                -0.0004281469446141273,
                -0.006461178418248892,
                0.020690618082880974,
                -0.006574797444045544,
                0.04969116672873497,
                0.0066549526527523994,
                0.05404007434844971,
                -0.013938972726464272,
                -0.00514191621914506,
                0.002720404416322708,
                -0.00883081927895546,
                0.01220991276204586,
                0.022578060626983643,
                0.021400509402155876,
                0.019133437424898148,
                -0.03194654360413551,
                -0.00727834040299058,
                0.05210715904831886,
                -0.07519227266311646,
                -0.012103807181119919,
                0.05574626475572586,
                -0.08840608596801758,
                0.06187635287642479,
                -0.0070090643130242825,
                -0.015075105242431164,
                -0.022877834737300873,
                -0.13875600695610046,
                -0.010186627507209778,
                0.014910250902175903,
                0.07030953466892242,
                0.05511363595724106,
                0.032116521149873734,
                -0.07052968442440033,
                0.01376428734511137,
                0.013318557292222977,
                -0.004014706239104271,
                0.025218099355697632,
                -0.03900383785367012,
                -0.02357136458158493,
                -0.030444230884313583,
                0.01175548043102026,
                -0.027167977765202522,
                -0.01068923156708479,
                0.0193011574447155,
                0.03678996488451958,
                0.00493872445076704,
                -0.04010525718331337,
                -0.017893660813570023,
                0.008932135067880154,
                0.012803171761333942,
                -0.011552039533853531,
                -0.034337036311626434,
                -0.029634077101945877,
                -0.028987858444452286,
                -0.026769278571009636,
                -0.028758907690644264,
                -0.0005146550829522312,
                0.05538658797740936,
                -0.02005694806575775,
                -0.03243077173829079,
                -0.012639917433261871,
                0.019245903939008713,
                -0.011349285952746868,
                0.013529537245631218,
                -0.0029596202075481415,
                0.006807474419474602,
                -0.01117077935487032,
                0.02894369699060917,
                -0.02541038766503334,
                -0.0005285112420096993,
                -0.019685372710227966,
                0.004653782118111849,
                0.01264247763901949,
                -0.07579342275857925,
                0.015752429142594337,
                0.04449944943189621,
                -0.0010474465088918805,
                0.020977094769477844,
                -0.010325340554118156,
                0.013698603957891464,
                -0.04784337803721428,
                -0.015807852149009705,
                0.027717363089323044,
                -0.03546283394098282,
                -0.014531881548464298,
                0.034250445663928986,
                -0.04405266046524048,
                -0.04311498999595642,
                -0.048323869705200195,
                -0.05769699811935425,
                0.01781451515853405,
                -0.006121290847659111,
                -0.016812007874250412,
                0.05742800980806351,
                0.05951107665896416,
                -0.06707150489091873,
                0.033030200749635696,
                0.007078760303556919,
                -0.04151380434632301,
                0.03753083199262619,
                -0.06202214956283569,
                -0.028182631358504295,
                -0.11237084865570068,
                0.0326709970831871,
                -0.05042219161987305,
                0.03018416278064251,
                0.06990046054124832,
                -0.008605044335126877,
                0.039312899112701416,
                0.006206043064594269,
                -0.05755280703306198,
                0.040700409561395645,
                -0.017351321876049042,
                0.024089103564620018,
                0.0031466518994420767,
                -0.036100421100854874,
                -0.030277393758296967,
                -0.0038672308437526226,
                0.020569195970892906,
                0.032455407083034515,
                -0.007480767089873552,
                -0.06981401890516281,
                -0.0006871428922750056,
                0.0067722974345088005,
                0.04279574006795883,
                -4.817250787434599e-33,
                0.02346830628812313,
                0.0009248837013728917,
                0.0371452271938324,
                -0.0025483062490820885,
                -0.021353470161557198,
                0.047334857285022736,
                0.006489465944468975,
                0.00019057000463362783,
                -0.0031939896289259195,
                -0.03817925602197647,
                -0.011398468166589737,
                -0.031818490475416183,
                0.015473687089979649,
                -0.012998263351619244,
                -0.024904094636440277,
                0.03641827404499054,
                0.02156194858253002,
                -0.035434432327747345,
                -0.03880270943045616,
                -0.05200622230768204,
                -0.02488064393401146,
                -0.0053147608414292336,
                0.017086036503314972,
                -0.04882749542593956,
                0.015675349161028862,
                0.0034953502472490072,
                -0.0450364351272583,
                0.051854584366083145,
                0.009331871755421162,
                0.001581597956828773,
                0.01186616811901331,
                0.007663062773644924,
                0.003657946828752756,
                -0.08474511653184891,
                0.026949763298034668,
                0.0006227180128917098,
                -0.009750587865710258,
                0.023125674575567245,
                -0.02652006596326828,
                0.013472608290612698,
                0.032118186354637146,
                0.034649964421987534,
                -0.02832935005426407,
                -0.004440036136657,
                0.0020489401649683714,
                0.005137689411640167,
                -0.004526189062744379,
                -0.012851178646087646,
                0.04261745139956474,
                0.0481126606464386,
                -0.017837556079030037,
                -0.017626076936721802,
                -0.04058229923248291,
                0.01774282194674015,
                0.016942454501986504,
                0.010394882410764694,
                -0.01225863117724657,
                -0.02179143764078617,
                -0.08098325878381729,
                -0.025176826864480972,
                -0.03712219372391701,
                -0.01799081824719906,
                0.01093005109578371,
                -0.018455641344189644,
                -0.058985695242881775,
                -0.014321845956146717,
                -0.09705690294504166,
                0.01045354176312685,
                0.06275282055139542,
                -0.025277158245444298,
                -0.024822566658258438,
                0.08327805995941162,
                -0.051169559359550476,
                -0.06173144280910492,
                -0.0664825513958931,
                0.027629612013697624,
                0.005746596027165651,
                0.037093546241521835,
                0.04007038101553917,
                -0.07002785056829453,
                0.01406252384185791,
                -0.024268820881843567,
                -0.03886568173766136,
                0.0009862540755420923,
                0.03877978399395943,
                0.007889296859502792,
                -0.013102028518915176,
                0.005377237219363451,
                0.02893468737602234,
                0.033719535917043686,
                -0.0519556850194931,
                0.024605529382824898,
                0.028255464509129524,
                0.022604893893003464,
                -0.03660896420478821,
                -0.035635169595479965,
                -0.00966276228427887,
                0.01748737506568432,
                0.035617709159851074,
                0.01981981471180916,
                -0.01069323718547821,
                0.05058397352695465,
                -0.051771391183137894,
                0.006772050634026527,
                -0.0027734413743019104,
                0.01629152148962021,
                -0.007522584870457649,
                -0.01287197507917881,
                -0.023006660863757133,
                0.04364113509654999,
                0.041513849049806595,
                -0.01991693302989006,
                0.03547915816307068,
                -0.013836354948580265,
                -0.03902863338589668,
                -0.00861832220107317,
                0.004197882022708654,
                0.03984978049993515,
                -0.006331678479909897,
                -0.018120238557457924,
                0.0863538533449173,
                0.04076613485813141,
                0.014268850907683372,
                -0.037968896329402924,
                -0.007217587903141975,
                0.05366317555308342,
                0.07496853172779083,
                -0.026798095554113388,
                -0.016385959461331367,
                0.015635652467608452,
                -0.0065362779423594475,
                -0.027065349742770195,
                1.9633053227607888e-07,
                0.02278469316661358,
                -0.01523523312062025,
                -0.02396285906434059,
                0.07617542892694473,
                0.002869898919016123,
                -0.005740719381719828,
                0.04535812884569168,
                -0.036696657538414,
                -0.006371402181684971,
                -0.03574052453041077,
                0.0696544274687767,
                -0.023611348122358322,
                -0.056304387748241425,
                -0.00886265467852354,
                0.022168731316924095,
                -0.0610942542552948,
                0.038996271789073944,
                0.0024005582090467215,
                -0.018467489629983902,
                -0.012812835164368153,
                0.053146835416555405,
                0.04286787286400795,
                0.028832750394940376,
                -0.01530791912227869,
                0.010102844797074795,
                0.05925304442644119,
                0.016538400202989578,
                -0.03074444830417633,
                0.07702821493148804,
                0.016705023124814034,
                0.0203314870595932,
                0.008443811908364296,
                0.013362377882003784,
                0.021686511114239693,
                -0.015301190316677094,
                -0.03177427127957344,
                0.04765559360384941,
                0.053312163800001144,
                0.03519943356513977,
                -0.011098237708210945,
                0.07356981933116913,
                0.05051906406879425,
                0.049377333372831345,
                -0.0009226554539054632,
                -0.018336579203605652,
                -0.02467193268239498,
                -0.01246702577918768,
                -0.015344295650720596,
                -0.033667758107185364,
                -0.015832815319299698,
                0.00219780788756907,
                -0.0013175070052966475,
                -0.028348324820399284,
                0.02420918084681034,
                0.024348042905330658,
                -0.013067741878330708,
                0.06466025859117508,
                0.03613249212503433,
                0.05643872171640396,
                0.07136862725019455,
                -0.018728939816355705,
                -0.016106735914945602,
                -0.013213582336902618,
                -0.012181355617940426,
                0.04993228241801262,
                -0.010153533890843391,
                -0.02602546475827694,
                1.394153748819503e-34,
                -0.026431726291775703,
                0.002332653384655714,
                -0.0004982813261449337,
                0.05796053633093834,
                0.01990538462996483,
                0.013590400107204914,
                0.020523564890027046,
                -0.052657242864370346,
                0.006925877183675766,
                0.030313018709421158,
                -0.008655818179249763,
                -0.007192652672529221,
                0.012454026378691196,
                0.006848921068012714,
                -0.060776323080062866,
                -0.0553671196103096,
                -0.03145632520318031,
                0.008504248224198818,
                0.017880728468298912,
                0.04513131454586983,
                -0.01840599626302719,
                0.012290116399526596,
                -0.01670895516872406,
                0.015578603371977806,
                0.006299508269876242,
                -0.020310619845986366,
                0.022577492520213127,
                0.04304646700620651,
                -0.01660332642495632,
                -0.024989508092403412,
                -0.04166512191295624,
                0.01570463925600052,
                0.0026995413936674595,
                0.02137027308344841,
                0.04071016237139702,
                0.004805328790098429,
                0.0005227485671639442,
                0.0063081602565944195,
                0.0278413537889719,
                -0.03800326585769653,
                -0.06572329998016357,
                -0.0004818422021344304,
                -0.007770195137709379,
                -0.037732601165771484,
                -0.008509844541549683,
                2.2180254291015444e-06,
                -0.013912498950958252,
                -0.006550933234393597,
                -0.004926383960992098,
                -0.009159930981695652,
                0.01301672775298357,
                0.027219947427511215,
                -0.005622427444905043,
                -0.01913464069366455,
                -0.010823696851730347,
                0.022652724757790565,
                -0.03638070449233055,
                0.010914613492786884,
                0.011809823103249073,
                -0.009033460170030594,
                0.017604127526283264,
                0.003023373195901513,
                -0.047241199761629105,
                -0.05714816972613335,
                -0.024851659312844276,
                0.05400253087282181,
                0.08429062366485596,
                0.02066446840763092,
                -0.0418795645236969,
                -0.00016320793656632304,
                0.0021478531416505575,
                0.0031697703525424004,
                -0.006372790317982435,
                0.004843888338655233,
                -0.03201853483915329,
                -0.015700284391641617,
                0.036445245146751404,
                0.06116991490125656,
                -0.006849062629044056,
                0.00668381666764617,
                -0.006462781690061092,
                0.06702335178852081,
                0.012310368940234184,
                0.0217771977186203,
                0.09215974807739258,
                -0.01937057077884674,
                0.027569938451051712,
                0.007869678549468517,
                0.006588888354599476,
                -0.058060336858034134,
                -0.012640460394322872,
                0.024392545223236084,
                0.062216419726610184,
                -0.004915150348097086,
                0.04713254049420357,
                0.0030065116006881,
                -0.03563316538929939,
                0.03767797350883484,
                -0.024543320760130882,
                0.02494765818119049,
                0.015278518199920654,
                -0.09576582163572311,
                -0.061029624193906784,
                -0.01017670426517725,
                0.013315292075276375,
                -0.007918303832411766,
                -0.012566298246383667,
                -0.028157616034150124,
                -0.014416108839213848,
                0.007384622469544411,
                -0.06782378256320953,
                -0.06877564638853073,
                0.0541154146194458,
                0.016519932076334953,
                0.01876232773065567,
                -0.02282310277223587,
                0.007308600004762411,
                0.06254512816667557,
                0.02688790112733841,
                0.009801078587770462,
                0.020034048706293106,
                -0.017422320321202278,
                0.02317143604159355,
                -0.0022723358124494553,
                0.10350126773118973,
                -0.02020038291811943,
                -0.04280159994959831,
                -0.0591006875038147,
                -0.007626782637089491,
                -0.013140402734279633,
                -0.022303884848952293,
                0.037803664803504944,
                0.03719930723309517,
                -0.0035140833351761103,
                0.015504459850490093,
                0.0457695871591568,
                -0.03188956901431084,
                -0.03379533812403679,
                0.006088279187679291,
                -0.03599182888865471,
                -0.02026800625026226,
                -0.00400639558210969,
                0.04592115432024002,
                0.008925237692892551,
                0.017817582935094833,
                0.026821181178092957,
                0.059238824993371964,
                -0.03879554942250252,
                0.00824499037116766,
                -0.011233287863433361,
                0.01746279187500477,
                0.03922894969582558,
                0.007396444212645292,
                -0.035837963223457336,
                -0.0013906302629038692,
                -0.02757624164223671,
                -0.037695690989494324,
                0.03748653829097748,
                -0.06394974887371063,
                -0.03787992522120476,
                0.032222945243120193,
                0.053865041583776474,
                -0.03376706689596176,
                -0.026017772033810616,
                -0.00579446367919445,
                0.019568581134080887,
                0.03321316838264465,
                -0.01910482533276081,
                -0.035930391401052475,
                -0.0682622641324997,
                0.00012667197734117508,
                0.0032150764018297195,
                -0.0480746366083622,
                0.032648853957653046,
                -0.015899786725640297,
                0.02359084039926529,
                -0.004778583999723196,
                0.05883263796567917,
                0.03417300060391426,
                -0.13568544387817383,
                0.005174695048481226,
                -0.006406263913959265,
                0.01506957970559597,
                -0.028246650472283363,
                0.013164878822863102,
                0.02018122747540474,
                0.08779100328683853,
                0.014501875266432762,
                0.021968575194478035,
                -0.060856893658638,
                -0.01918823830783367,
                0.0059059555642306805,
                -0.0973014086484909,
                0.018735894933342934,
                0.05009760335087776,
                0.014515450224280357,
                0.029654573649168015,
                -0.04071485251188278,
                0.012403810396790504,
                -0.0143278231844306,
                -0.02494075335562229,
                0.01735450141131878,
                0.030388711020350456,
                -0.06717323511838913,
                0.05589005723595619,
                0.006567545235157013,
                0.024821365252137184,
                0.04474295303225517,
                0.0024041745346039534,
                0.023307284340262413,
                -0.04716372489929199,
                -0.04148710146546364,
                -0.02426258474588394,
                0.04103556647896767,
                0.027649836614727974,
                -0.04070602357387543,
                -0.03600803017616272,
                0.027240151539444923,
                -0.026508837938308716,
                0.04055578634142876,
                0.07702035456895828,
                0.007451882120221853,
                -0.028826305642724037,
                0.06657955050468445,
                0.004805444274097681,
                0.04622872918844223,
                0.02888527885079384,
                0.00036657584132626653,
                0.052587904036045074,
                -0.018743671476840973,
                0.055860746651887894,
                -0.014291549101471901,
                -0.004446393810212612,
                -0.028958411887288094,
                -0.009777635335922241,
                -0.0085901552811265,
                0.011543422006070614,
                0.0329432375729084,
                0.050643470138311386,
                -0.04095670208334923,
                0.034823037683963776,
                -0.008974215015769005,
                1.775896453182213e-05,
                0.004365096800029278,
                -0.016040824353694916,
                0.04652620851993561,
                0.0031638622749596834,
                0.07029832154512405,
                0.027080198749899864,
                -0.010162031278014183,
                -0.026478825137019157,
                -0.012285423465073109,
                -0.01919860951602459,
                0.018658319488167763,
                -0.0184527188539505,
                0.10166548192501068,
                -0.00978695135563612,
                0.05816017836332321,
                0.0034693533089011908,
                0.015547409653663635,
                -0.028973598033189774,
                -0.010686833411455154,
                -0.026030704379081726,
                0.02951943129301071,
                0.011296289041638374,
                -0.017126645892858505,
                0.0127452677115798,
                -0.019377242773771286,
                -0.013045028783380985,
                0.06140311434864998,
                -0.025602074339985847,
                0.05485027655959129,
                -0.019317325204610825,
                0.019875476136803627,
                -0.0086843715980649,
                0.05028046295046806,
                -0.012379282154142857,
                0.0009226087713614106,
                -0.06829746812582016,
                -0.009998558089137077,
                0.029132135212421417,
                0.009692407213151455,
                0.00943378359079361,
                0.012292406521737576,
                0.010628394782543182,
                0.013366914354264736,
                0.003822961123660207,
                -0.09857960790395737,
                0.004806297365576029,
                0.003452833741903305,
                -0.009777909144759178,
                0.017511209473013878,
                -0.02054760977625847,
                -0.037464603781700134,
                0.004511096980422735,
                0.0011804754612967372,
                0.041459016501903534,
                -0.015926538035273552,
                0.00531721068546176,
                -0.00397033616900444,
                -0.12201618403196335,
                -0.02126532606780529,
                -0.002896652091294527,
                0.03689923137426376,
                0.02654998190701008,
                0.010790329426527023,
                0.004370817914605141,
                -0.08603449910879135,
                0.02022223174571991,
                -0.02445184625685215,
                -0.014072246849536896,
                -0.031566016376018524,
                -0.08392700552940369,
                0.005915651097893715,
                -0.014313705265522003,
                0.017635531723499298,
                -0.030128508806228638,
                0.02486131154000759,
                -0.060175858438014984,
                -0.024622539058327675,
                0.0027991628739982843,
                0.02164445072412491,
                0.04381531849503517,
                0.014821500517427921,
                -0.04257788881659508,
                -0.009301254525780678,
                0.0024557511787861586,
                0.012540781870484352,
                -0.007841527462005615,
                0.013677071779966354,
                -0.0154279675334692,
                0.00973117258399725,
                -0.006143347360193729,
                0.015575772151350975,
                -0.04758742079138756,
                0.05664641410112381,
                0.047112442553043365,
                -0.02885393053293228,
                -0.0013511065626516938,
                0.014474545605480671,
                -0.06706603616476059,
                0.035895030945539474,
                0.07159075140953064,
                -0.03737231716513634,
                0.037406258285045624,
                0.034682709723711014,
                -0.049163080751895905,
                0.02273261360824108,
                -0.0754198208451271,
                -0.0005440369714051485,
                0.007980301044881344,
                0.03857068717479706,
                -0.03465094417333603,
                0.04315880313515663,
                -0.054677899926900864,
                -0.02753334492444992,
                -0.05316774547100067,
                -0.00835055485367775,
                0.003422534791752696,
                0.019103560596704483,
                0.00671794731169939,
                0.006081907544285059,
                -0.06074981018900871,
                -0.01915227249264717,
                -0.03131880983710289,
                -0.005313325207680464,
                0.022593988105654716,
                0.018890991806983948,
                -0.010562519542872906,
                -0.011560156010091305,
                0.0026399625930935144,
                0.04490265995264053,
                -0.020948534831404686,
                0.029569389298558235,
                0.029208604246377945,
                -0.013834628276526928,
                0.0466594323515892,
                0.02040782943367958,
                -0.012813100591301918,
                -0.0010822126641869545,
                -0.036644551903009415,
                -0.029889844357967377,
                0.05082521215081215,
                -0.017199022695422173,
                0.05526435375213623,
                -0.03912576660513878,
                0.0035629039630293846,
                0.0217690858989954,
                0.01882079988718033,
                0.05653446167707443,
                -0.0017668084474280477,
                0.04213418811559677,
                -0.009756922721862793,
                -0.027384862303733826,
                0.01824343390762806,
                -0.018169716000556946,
                -0.041915226727724075,
                -0.04814034327864647,
                0.028222352266311646,
                -0.0785703957080841,
                -0.03628886863589287,
                0.02692762389779091,
                0.031472835689783096,
                -0.04080607369542122,
                0.012997704558074474,
                0.022537505254149437,
                0.014776632189750671,
                -0.0637681633234024,
                -0.012329483404755592,
                -0.03230326622724533,
                0.06140133738517761,
                0.00813317485153675,
                0.046011507511138916,
                0.10649385303258896,
                0.025336535647511482,
                -0.0017811370780691504,
                -0.00367174856364727,
                0.0873073861002922,
                -0.028286689892411232,
                0.02033630944788456,
                0.04455363005399704,
                0.07980301231145859,
                -0.04049498960375786,
                0.00035405904054641724,
                0.016381440684199333,
                -0.07387489825487137,
                -0.08693166077136993,
                0.030232809484004974,
                -0.08546534180641174,
                0.043096642941236496,
                0.02163861133158207,
                0.019277174025774002,
                -0.010806056670844555,
                -0.024553393945097923,
                -0.0068264794535934925,
                0.06268154084682465,
                0.018779484555125237,
                0.020353257656097412,
                0.0030056436080485582,
                0.034823160618543625,
                0.005024439189583063,
                -0.01605558581650257,
                -0.052464667707681656,
                -0.02352141961455345,
                0.029859991744160652,
                0.007422272115945816,
                -0.055384691804647446,
                0.08495507389307022,
                0.09301108866930008,
                0.019905265420675278,
                -0.005490806885063648,
                -0.026948021724820137,
                -0.0650559663772583,
                0.010246717371046543,
                0.03634507581591606,
                -0.003750016912817955,
                -0.04722100496292114,
                0.06600916385650635,
                0.06439416110515594,
                -0.055675238370895386,
                0.02766803279519081,
                -0.013033916242420673,
                -0.06363390386104584,
                0.07995010912418365,
                -0.01785437762737274,
                -0.041133396327495575,
                -0.042583443224430084,
                0.08123071491718292,
                0.06547688692808151,
                -0.008747680112719536,
                0.027405278757214546,
                0.0028472410049289465,
                0.03145970031619072,
                0.02218705788254738,
                0.028992842882871628,
                -0.023791026324033737,
                0.028120482340455055,
                -0.030942240729928017,
                -0.02966579794883728,
                0.03401120752096176,
                -0.05578786879777908,
                -0.03735072910785675,
                -0.012667796574532986,
                0.02327449806034565,
                0.02174743264913559,
                0.007753449957817793,
                0.0012263590469956398,
                0.022868691012263298,
                -0.023966126143932343,
                0.09735043346881866,
                -0.043605104088783264,
                -0.009677348658442497,
                0.0179196298122406,
                -0.0008115118253044784,
                -0.07543017715215683,
                0.031225839629769325,
                -0.015519426204264164,
                0.02546877972781658,
                0.00836164690554142,
                0.028058921918272972,
                -0.01909037120640278,
                -0.009542180225253105,
                -0.014344603754580021,
                0.0010308735072612762,
                0.014211543835699558,
                0.060818638652563095,
                -0.00043158847256563604,
                0.03194819390773773,
                -0.013405032455921173,
                -0.06644733995199203,
                0.062473736703395844,
                -0.04862267151474953,
                0.021486477926373482,
                0.010626486502587795,
                0.035635679960250854,
                -0.011674273759126663,
                0.022879520431160927,
                0.022117046639323235,
                0.007259089034050703,
                -0.01970549114048481,
                -0.049024488776922226,
                -0.011454682797193527,
                0.00947294570505619,
                -0.014402687549591064,
                -0.008114704862236977,
                -0.02446901425719261,
                0.044763483107089996,
                0.020248068496584892,
                0.022550752386450768,
                0.05272892862558365,
                0.008341017179191113,
                -0.01337876170873642,
                0.019115008413791656,
                -0.0053817410953342915,
                0.01700207218527794,
                -0.026065673679113388,
                -0.05258011817932129,
                0.0131023358553648,
                -0.022114595398306847,
                -0.00840317364782095,
                0.0314553864300251,
                0.023571444675326347,
                0.04251262918114662,
                -0.002809577388688922,
                0.028174366801977158,
                -0.029170602560043335,
                0.043591275811195374,
                0.010721436701714993,
                -0.0009165530209429562,
                -0.011488189920783043,
                0.002231151796877384,
                -0.005535867530852556,
                0.04435734078288078,
                0.003967772237956524,
                -0.04577643796801567,
                -0.019663583487272263,
                0.000999889220111072,
                -0.01767830364406109,
                -0.002595718251541257,
                0.008944889530539513,
                0.008243568241596222,
                0.044774897396564484,
                0.008886333554983139,
                -0.08370134979486465,
                -0.02653169073164463,
                -0.016926323994994164,
                -7.921389005226583e-33,
                -0.039473649114370346,
                0.008931828662753105,
                -0.007499200291931629,
                -0.02498527429997921,
                -0.05862804129719734,
                0.05192391574382782,
                -0.03134138882160187,
                -0.002559854183346033,
                -0.035733748227357864,
                -0.0348777249455452,
                -0.001297720824368298,
                -0.03315795585513115,
                0.030836472287774086,
                0.02716175839304924,
                0.03350343555212021,
                -0.005266654305160046,
                0.017999665811657906,
                -0.0037631369195878506,
                -0.011831612326204777,
                0.004694231785833836,
                -0.00765267014503479,
                0.018479837104678154,
                0.005729116499423981,
                0.022726863622665405,
                -0.024319760501384735,
                -0.005485237576067448,
                -0.036312784999608994,
                0.06270404905080795,
                -0.015185225754976273,
                -0.06277258694171906,
                -0.023096531629562378,
                -0.030475841835141182,
                -0.03992876037955284,
                0.011528665199875832,
                0.058571089059114456,
                0.002883917186409235,
                -0.027336569502949715,
                -0.03032175824046135,
                -0.01844300515949726,
                -0.028901536017656326,
                -0.05113937705755234,
                0.006525750737637281,
                0.06532828509807587,
                0.01393254566937685,
                -0.019492927938699722,
                -0.00934217032045126,
                -0.02833031676709652,
                0.003805741434916854,
                0.00023441195662599057,
                0.07183775305747986,
                -0.03262440115213394,
                0.028778117150068283,
                0.01735621690750122,
                0.0024479427374899387,
                0.0046805329620838165,
                0.03538534417748451,
                -0.012332241050899029,
                -0.03044632449746132,
                -0.018269287422299385,
                -0.02927151694893837,
                -0.03821457549929619,
                0.009669538587331772,
                -0.01912545971572399,
                -0.02197819948196411,
                0.05793379992246628,
                0.08569151908159256,
                0.1318909078836441,
                -0.003401970723643899,
                0.041210971772670746,
                0.022810081019997597,
                -0.04243476316332817,
                0.042217910289764404,
                -0.014499262906610966,
                -0.020274752750992775,
                -0.06727910786867142,
                -0.04680870845913887,
                0.05176553502678871,
                0.006181272678077221,
                -0.04959024116396904,
                -0.000692007306497544,
                -0.025339094921946526,
                -0.022976823151111603,
                -0.048707034438848495,
                -0.022761903703212738,
                0.059372272342443466,
                -0.01255823951214552,
                -0.033583931624889374,
                -0.0782788023352623,
                -0.01580832153558731,
                -0.01463051326572895,
                -0.004736501257866621,
                0.02104230970144272,
                0.012771383859217167,
                0.0331108495593071,
                0.013140086084604263,
                -0.03563951328396797,
                -0.002902831183746457,
                0.03856968879699707,
                -0.01156102679669857,
                -0.01516222208738327,
                0.015327238477766514,
                -0.04237540811300278,
                -0.03319436311721802,
                0.040432270616292953,
                0.002033774508163333,
                0.00351669080555439,
                0.045341212302446365,
                -0.007760050240904093,
                -0.0571959987282753,
                -0.025493629276752472,
                -0.012538155540823936,
                -0.005498241633176804,
                -0.006333582103252411,
                -0.0023535541258752346,
                0.014393042773008347,
                0.04111504182219505,
                -0.008343145251274109,
                -0.03721851110458374,
                -0.034035809338092804,
                -0.03289061784744263,
                0.01951119862496853,
                -0.04708774387836456,
                -0.046030063182115555,
                0.01720154657959938,
                -0.03769077733159065,
                0.00658976286649704,
                -0.0027606822550296783,
                -0.07913873344659805,
                -0.09048811346292496,
                -0.036962952464818954,
                -0.024693788960576057,
                -0.022366123273968697,
                2.9272140977809613e-07,
                -0.03950250893831253,
                -0.0014144193846732378,
                -0.00016147388669196516,
                -0.038019679486751556,
                0.0002858669904526323,
                0.009346033446490765,
                0.008141579106450081,
                0.004706286359578371,
                -0.06008090823888779,
                0.006981393788009882,
                0.060519516468048096,
                -0.045171674340963364,
                0.03749333322048187,
                0.01075716782361269,
                0.03849831596016884,
                -0.0752962976694107,
                -0.034682393074035645,
                0.01619991473853588,
                0.022802427411079407,
                0.049475524574518204,
                -0.0037175144534558058,
                -0.03329138085246086,
                -0.017881100997328758,
                -0.021364133805036545,
                0.04437394067645073,
                -0.025367069989442825,
                -0.0075956061482429504,
                -0.004591624718159437,
                0.012873230502009392,
                0.027300288900732994,
                -0.12535764276981354,
                0.00877011101692915,
                0.0077323317527771,
                -0.03146476298570633,
                0.041892919689416885,
                -0.059316083788871765,
                0.0754372626543045,
                -0.03672739863395691,
                -0.037128038704395294,
                0.05458110570907593,
                -0.081934355199337,
                0.029071247205138206,
                -0.0015069354558363557,
                -0.009799342602491379,
                -0.012834182009100914,
                0.011142170056700706,
                -0.004030609503388405,
                0.008430905640125275,
                -0.06652672588825226,
                -0.045520883053541183,
                -0.051683567464351654,
                -0.00026870955480262637,
                0.035953111946582794,
                0.023701857775449753,
                -0.02566032111644745,
                0.004910223186016083,
                0.005423202645033598,
                -0.014345592819154263,
                -0.00919454824179411,
                -0.0033556008711457253,
                0.015031795017421246,
                0.012751458212733269,
                0.005906571634113789,
                0.1188291534781456,
                -0.0024942310992628336,
                -0.006100293714553118,
                0.011744201183319092,
                2.261630697584186e-34,
                0.041330497711896896,
                0.016493480652570724,
                0.017752768471837044,
                0.02331288531422615,
                0.025645138695836067,
                0.003765122266486287,
                0.05346088483929634,
                -0.07566554099321365,
                0.0007337399292737246,
                -0.021613458171486855,
                -0.030917201191186905,
                -0.03056894801557064,
                -0.02933693118393421,
                0.00562119297683239,
                0.011380847543478012,
                0.012478264980018139,
                -0.0025166834238916636,
                0.009935583919286728,
                0.012978470884263515,
                0.01845521852374077,
                -0.025207143276929855,
                0.03593483939766884,
                0.029718918725848198,
                0.030623923987150192,
                0.015118534676730633,
                0.042949989438056946,
                -0.030982505530118942,
                0.0376749224960804,
                -0.033971838653087616,
                -0.10866560786962509,
                0.011835739016532898,
                -0.017243068665266037,
                0.02679947391152382,
                0.003734141355380416,
                0.033013831824064255,
                -0.009091682732105255,
                0.008492645807564259,
                0.016056232154369354,
                -0.011139538139104843,
                0.038922153413295746,
                -0.012082180939614773,
                0.06351882964372635,
                -0.012028162367641926,
                0.05782323330640793,
                -0.036624640226364136,
                1.4296335848484887e-06,
                0.020122280344367027,
                0.019343944266438484,
                -0.0038905879482626915,
                -0.0028193097095936537,
                -0.04499896615743637,
                0.016202151775360107,
                -0.015677381306886673,
                -0.028732821345329285,
                0.041862450540065765,
                0.02614077553153038,
                -0.07915908098220825,
                0.021778268739581108,
                -0.023248521611094475,
                0.013224597088992596,
                0.008164769969880581,
                -0.031515397131443024,
                0.04692448303103447,
                -0.06400322169065475,
                0.006855017505586147,
                -0.008454738184809685,
                0.030358048155903816,
                -0.005420381668955088,
                0.01013921294361353,
                0.05539758503437042,
                0.03888079151511192,
                -0.03790280967950821,
                0.009880844503641129,
                -0.0002998575218953192,
                -0.005123662296682596,
                -0.004653585609048605,
                0.0015253601595759392,
                0.012969802133738995,
                -0.04841548576951027,
                0.001661035930737853,
                0.05724966898560524,
                0.01742332987487316,
                0.007728963159024715,
                -0.0021562366746366024,
                0.0729602575302124,
                -0.035779960453510284,
                -0.014709306880831718,
                0.006201998330652714,
                -0.0016155263874679804,
                -0.0011078796815127134,
                -0.04363293945789337,
                -0.0003117680025752634,
                0.05280619487166405,
                -0.023589400574564934,
                0.015072673559188843,
                0.08965056389570236,
                0.049553606659173965,
                -0.0022080400958657265,
                0.006209719926118851,
                0.007797372527420521,
                0.014195512048900127,
                0.03736856207251549,
                -0.009206067770719528,
                -0.018458368256688118,
                0.024824228137731552,
                -0.010275257751345634,
                -0.006569951307028532,
                0.03271544352173805,
                -0.0036049431655555964,
                0.0704800933599472,
                -0.0755971148610115,
                -0.03517620638012886,
                -0.013366609811782837,
                0.01155699323862791,
                0.012478409335017204,
                -0.006574384402483702,
                -0.04165259003639221,
                -0.0033640002366155386,
                0.000866935879457742,
                -0.011256867088377476,
                0.053938835859298706,
                -0.0008887879666872323,
                -0.008429114706814289,
                -0.014742603525519371,
                -0.03251918405294418,
                0.025111926719546318,
                0.032541584223508835,
                -0.005378203000873327,
                0.0007902428624220192,
                -0.01666959375143051,
                0.0015039866557344794,
                0.05449678748846054,
                0.009162761270999908,
                -0.017465131357312202,
                0.044181257486343384,
                0.07449882477521896,
                -0.014427950605750084,
                -0.04729197174310684,
                0.04317278042435646,
                0.021539608016610146,
                0.0038235215470194817,
                -0.00022721628192812204,
                0.009800797328352928,
                4.778429229190806e-06,
                -0.02484949305653572,
                -0.028391720727086067,
                0.10092078894376755,
                -0.003915408160537481,
                -0.030217992141842842,
                -0.10470698773860931,
                -0.013994216918945312,
                0.051721490919589996,
                0.03417631983757019,
                -0.01874653808772564,
                0.054946426302194595,
                -0.0013582143001258373,
                -0.01704264245927334,
                -0.029344875365495682,
                -0.02923690713942051,
                -0.023308096453547478,
                0.00827611144632101,
                -0.019977301359176636,
                -0.056514617055654526,
                -0.020460940897464752,
                -0.025585100054740906,
                0.026459118351340294,
                0.049978435039520264,
                0.005952613428235054,
                -0.03514856845140457,
                0.03748607635498047,
                0.01489337533712387,
                -0.0007516394252888858,
                0.005533813498914242,
                -0.03325601667165756,
                -0.04287145659327507,
                -0.04044850915670395,
                0.010017231106758118,
                0.017993224784731865,
                -0.005609314888715744,
                -0.03377601504325867,
                0.018099768087267876,
                0.007280038204044104,
                0.03333659470081329,
                -0.06374657899141312,
                0.08235064893960953,
                0.009391306899487972,
                -0.05758688226342201,
                0.000616546138189733,
                -0.03711838275194168,
                -0.027046827599406242,
                0.034897759556770325,
                -0.014853798784315586,
                -0.07099922746419907,
                0.00870142225176096,
                -0.02474024146795273,
                0.016194839030504227,
                -0.005357660353183746,
                0.038146622478961945,
                0.020705968141555786,
                -0.035005729645490646,
                -0.025243287906050682,
                0.006459411233663559,
                -0.0008372252923436463,
                0.03316080570220947,
                -0.005296104121953249,
                -0.0026704026386141777,
                -0.021946851164102554,
                -0.022567087784409523,
                0.01140831783413887,
                0.0004367814108263701,
                -0.03379359841346741,
                -0.020209796726703644,
                0.004385306965559721,
                0.028207125142216682,
                0.05714857578277588,
                -0.07474934309720993,
                0.03494463115930557,
                -0.024574916809797287,
                -0.07071187347173691,
                -0.06291878968477249,
                -0.09472434967756271,
                -0.047255437821149826,
                -0.012484884820878506,
                0.08609716594219208,
                0.017528120428323746,
                -0.02156878635287285,
                -0.005344085395336151,
                0.038521282374858856,
                0.011563948355615139,
                -0.03304802626371384,
                0.08659620583057404,
                -0.0104709817096591,
                0.011567693203687668,
                -0.04197010025382042,
                0.027365611866116524,
                -0.00039450087933801115,
                -0.019442107528448105,
                -0.045075248926877975,
                0.017664767801761627,
                -0.06010580062866211,
                -0.02423444576561451,
                0.013262966647744179,
                0.018548956140875816,
                -0.010942691937088966,
                0.019239632412791252,
                -0.03592413663864136,
                0.03646378964185715,
                0.05858074501156807,
                0.0749848410487175,
                0.03846956416964531,
                -0.038465410470962524,
                0.0030455223750323057,
                0.005168905947357416,
                0.017577536404132843,
                0.012270726263523102,
                -0.029489221051335335,
                0.03735346347093582,
                -0.03385442495346069,
                0.0371762253344059,
                0.002249813638627529,
                0.027051232755184174,
                -0.02134959027171135,
                -0.02666965313255787,
                0.006686569191515446,
                0.036195725202560425,
                -0.027785150334239006,
                -0.03700874000787735,
                0.013256185688078403,
                0.05273498594760895,
                -0.03869367018342018,
                -0.019519420340657234,
                -0.015183915384113789,
                -0.03624372556805611,
                0.020345821976661682,
                -0.02585885114967823,
                0.02683859132230282,
                -0.05712437257170677,
                0.07980484515428543,
                -0.006514735985547304,
                0.005420885514467955,
                -0.02738056145608425,
                -0.007066724821925163,
                -0.006979272700846195,
                0.010365934111177921,
                0.0013990089064463973,
                -0.01282499823719263,
                0.03975246474146843,
                -0.017612723633646965,
                -0.006830458529293537,
                -0.004475957714021206,
                0.007232440169900656,
                0.00581861287355423,
                -0.0010382450418546796,
                -0.016386184841394424,
                -0.028528645634651184,
                -0.006023040972650051,
                0.08473371714353561,
                0.0386815071105957,
                -0.05241721123456955,
                -0.06871042400598526,
                0.07341879606246948,
                0.03895483911037445,
                0.01255121361464262,
                0.02318562939763069,
                -0.03050338476896286,
                -0.04047321528196335,
                0.02132171206176281,
                -0.009539347141981125,
                -0.0101893600076437,
                0.021448062732815742,
                0.0356702096760273,
                -0.03745188191533089,
                0.010961118154227734,
                -0.019972074776887894,
                -0.004874935373663902,
                0.006585506722331047,
                0.01564016006886959,
                0.0746341198682785,
                0.014903653413057327,
                -0.0472235307097435,
                -0.026646509766578674,
                -0.06702794879674911,
                -0.025623207911849022,
                0.046942148357629776,
                0.0695805773139,
                0.002961372956633568,
                -0.009607256390154362,
                0.03312503546476364,
                -0.06728862971067429,
                0.0266379714012146,
                0.07719839364290237,
                0.05573570355772972,
                -0.02164177969098091,
                -0.03551264852285385,
                0.02787080593407154,
                0.01711525395512581,
                -0.007109255064278841,
                0.014674740843474865,
                0.026522688567638397,
                -0.015950428321957588,
                -0.06249493733048439,
                0.009544950909912586,
                0.05157352238893509,
                -0.0008151045185513794,
                0.024326350539922714,
                -0.035889264196157455,
                -0.001917580608278513,
                0.005398979876190424,
                -0.08504939824342728,
                -0.04487130790948868,
                0.04098333418369293,
                0.003457916434854269,
                -0.09015269577503204,
                -0.017527485266327858,
                -0.020319435745477676,
                0.03951854258775711,
                -0.00578762823715806,
                -0.03378915786743164,
                0.03292253613471985,
                -0.03301791846752167,
                0.013477097265422344,
                0.030509239062666893,
                0.011277062818408012,
                -0.008443586528301239,
                0.0020314843859523535,
                -0.026729347184300423,
                0.03810025379061699,
                0.036159493029117584,
                -0.033439673483371735,
                0.016735699027776718,
                -0.021147076040506363,
                -0.002017634455114603,
                0.02653992548584938,
                -0.008362362161278725,
                -0.028393471613526344,
                -0.02668256126344204,
                0.02395441383123398,
                0.0011859488440677524,
                0.041977327316999435,
                -0.04477918520569801,
                0.003593070898205042,
                -0.004372189752757549,
                0.038466379046440125,
                -0.02647155337035656,
                -0.037562787532806396,
                -0.032215435057878494,
                0.08138847351074219,
                0.03671582415699959,
                0.012912534177303314,
                -0.03166481480002403,
                -0.057915620505809784,
                0.03850367292761803,
                0.07914658635854721,
                0.05657077580690384,
                0.016589751467108727,
                -0.0198365431278944,
                0.005392478778958321,
                -0.010409447364509106,
                0.012064631097018719,
                0.055097609758377075,
                0.056447748094797134,
                0.015417248010635376,
                -0.08380957692861557,
                -0.054919272661209106,
                0.029861005023121834,
                0.01795853301882744,
                -0.03702324628829956,
                0.07823474705219269,
                0.020665422081947327,
                -0.014442899264395237,
                0.03269997984170914,
                0.02712542936205864,
                0.01928677223622799,
                -0.08107797801494598,
                0.007862543687224388,
                -0.043483469635248184,
                -0.026037566363811493,
                0.06449595838785172,
                -0.06895379722118378,
                -0.01322952564805746,
                -0.00585580337792635,
                0.041541822254657745,
                -0.027481192722916603,
                -0.01478953193873167,
                -0.019697781652212143,
                -0.011369411833584309,
                -0.04337313026189804,
                -0.01773497648537159,
                -0.009192160330712795,
                0.029605666175484657,
                -0.005371526814997196,
                -0.015481839887797832,
                0.012934058904647827,
                -0.010466646403074265,
                -0.03478644788265228,
                0.0027185685466974974,
                -0.03963398188352585,
                0.06566781550645828,
                0.048300690948963165,
                -0.024676213040947914,
                0.0008532382198609412,
                -0.01398239005357027,
                -0.029300019145011902,
                -0.036224525421857834,
                0.010312632657587528,
                -0.024987759068608284,
                0.0091715008020401,
                -0.030227815732359886,
                -0.08411858975887299,
                -0.07064507901668549,
                -0.024273725226521492,
                -0.002678916323930025,
                0.024284230545163155,
                0.05724743381142616,
                -0.012064014561474323,
                0.0013708455953747034,
                0.008456691168248653,
                0.024533409625291824,
                0.02026606909930706,
                0.03280380368232727,
                0.04158853366971016,
                0.04282219335436821,
                0.02306724712252617,
                0.07660263031721115,
                0.03142446279525757,
                0.05814743787050247,
                -0.036483343690633774,
                0.010668663308024406,
                -0.004072700627148151,
                -0.054936062544584274,
                0.05827009677886963,
                0.07318782806396484,
                0.04249640554189682,
                -0.02014908194541931,
                0.011431426741182804,
                0.045524612069129944,
                -0.019526630640029907,
                -0.035256315022706985,
                0.004751038737595081,
                -0.054659441113471985,
                -0.022692134603857994,
                -0.031091492623090744,
                0.02759179100394249,
                -0.023512793704867363,
                0.01651882380247116,
                0.0007606681901961565,
                -0.016419949010014534,
                -0.008685228414833546,
                -0.022007495164871216,
                0.004859278444200754,
                0.02933366782963276,
                0.037472568452358246,
                -0.007353933993726969,
                0.005886548198759556,
                0.05528685450553894,
                -0.05163324624300003,
                0.04804506525397301,
                0.07490874826908112,
                0.001524431281723082,
                -0.018564380705356598,
                0.05867919325828552,
                -0.021643662825226784,
                -0.03511936590075493,
                -0.03563766926527023,
                0.0049804565496742725,
                0.03611515089869499,
                0.04501780867576599,
                0.005816075950860977,
                0.04245540499687195,
                -0.020574448630213737,
                -0.0406620167195797,
                0.02705143578350544,
                0.04058148339390755,
                -0.007249456364661455,
                -0.014716967940330505,
                -0.0024506058543920517,
                0.02009672112762928,
                0.014014366082847118,
                0.0021582581102848053,
                0.018767446279525757,
                0.016735974699258804,
                -0.030254913493990898,
                0.0347394198179245,
                0.018793294206261635,
                0.020299509167671204,
                -0.027229461818933487,
                0.02086697146296501,
                -0.06277076154947281,
                -0.010017119348049164,
                -0.025527194142341614,
                -0.01789752021431923,
                0.02050979994237423,
                -0.041236408054828644,
                0.03593500331044197,
                -0.006198757328093052,
                -0.004210692830383778,
                0.02149823121726513,
                0.011656864546239376,
                0.02002137154340744,
                -0.0670507550239563,
                0.08271604031324387,
                0.03354841098189354,
                -0.03410559520125389,
                -0.017009297385811806,
                0.014427918940782547,
                -0.018873339518904686,
                0.04835246875882149,
                0.036657676100730896,
                0.021777302026748657,
                -0.009163198992609978,
                -0.009351411834359169,
                -0.015444410964846611,
                -0.06209949776530266,
                0.02620234340429306,
                0.016667328774929047,
                0.00902349129319191,
                0.02344593219459057,
                -0.0024510466028004885,
                -0.04407164454460144,
                0.061172690242528915,
                -0.0687459409236908,
                0.03675979748368263,
                -0.023168036714196205,
                -0.018059536814689636,
                0.011609768494963646,
                -0.0018073507817462087,
                -5.44432155495861e-33,
                -0.026076287031173706,
                -0.03339064493775368,
                -0.005278128664940596,
                0.00188030069693923,
                -0.04987606778740883,
                -0.008188729174435139,
                -0.003581180004402995,
                0.00833822600543499,
                -0.033025745302438736,
                -0.0261091161519289,
                -0.013967186212539673,
                0.018824467435479164,
                0.04378983750939369,
                -0.0029530993197113276,
                0.056407250463962555,
                -0.08172918111085892,
                -0.03558296337723732,
                0.01491470541805029,
                -0.018131082877516747,
                -0.050598353147506714,
                -0.011022030375897884,
                0.0061349933966994286,
                0.01884879544377327,
                0.02066505327820778,
                0.01763438619673252,
                0.018294362351298332,
                -0.023772018030285835,
                -0.0673840194940567,
                -0.04802278056740761,
                -0.0035351114347577095,
                0.02709590457379818,
                -0.031864479184150696,
                0.03088396228849888,
                -0.029203083366155624,
                -0.04853901267051697,
                0.11127500236034393,
                -0.024399863556027412,
                -0.025872139260172844,
                -0.004153104964643717,
                -0.03879654407501221,
                -0.10961227118968964,
                -0.06895749270915985,
                0.0378849022090435,
                -0.01609678380191326,
                -0.01113230362534523,
                -0.04138205200433731,
                -0.047519538551568985,
                -0.021306509152054787,
                0.015843480825424194,
                -0.034124113619327545,
                -0.013044451363384724,
                -0.0436997264623642,
                0.009941397234797478,
                0.02805737778544426,
                0.03387405723333359,
                0.04603786766529083,
                0.03493277356028557,
                -0.0069143581204116344,
                0.01907876506447792,
                -0.051675695925951004,
                -0.033604007214307785,
                0.0010519667994230986,
                0.007753034122288227,
                -0.0029488936997950077,
                -0.03541156277060509,
                -0.028004422783851624,
                -0.1356794387102127,
                0.04458914324641228,
                -0.0012154930736869574,
                -0.04878547415137291,
                -0.021848609670996666,
                0.06767808645963669,
                -0.0394924059510231,
                -0.0398031584918499,
                -0.07660654187202454,
                -0.013621458783745766,
                -0.08618056029081345,
                0.022361785173416138,
                0.00858132354915142,
                0.07708393782377243,
                -0.04056813567876816,
                -0.018795963376760483,
                0.02631775662302971,
                -0.015221605077385902,
                -0.0627179816365242,
                0.06513029336929321,
                -0.010007962584495544,
                0.06230909377336502,
                -0.005617835093289614,
                0.028126461431384087,
                -0.0015536525752395391,
                -0.026929577812552452,
                -0.04915003851056099,
                0.05070384591817856,
                0.07279936224222183,
                0.01576019823551178,
                0.04619193822145462,
                -0.027288496494293213,
                0.02207121253013611,
                0.059105921536684036,
                -0.00442930543795228,
                0.040926896035671234,
                -0.0703015998005867,
                0.030649229884147644,
                0.0077045694924890995,
                -0.01894363760948181,
                -0.038979556411504745,
                0.020425383001565933,
                0.007679715286940336,
                0.007968205958604813,
                0.007811825256794691,
                0.029400108382105827,
                0.003584933001548052,
                -0.032991379499435425,
                0.00746192829683423,
                -0.02204928733408451,
                0.0233452245593071,
                -0.013200027868151665,
                0.0028009710367769003,
                -0.011549235321581364,
                0.06869817525148392,
                0.008521389216184616,
                0.003809444373473525,
                0.011213666759431362,
                0.02376636676490307,
                -0.014229346066713333,
                0.08508627861738205,
                -0.02438483200967312,
                0.012454758398234844,
                -0.024337923154234886,
                0.018619973212480545,
                0.011078246869146824,
                1.9311349319650617e-07,
                -0.0032348502427339554,
                0.021538203582167625,
                0.028299856930971146,
                0.058423012495040894,
                0.047792091965675354,
                0.0013135612243786454,
                -0.027451569214463234,
                0.055296093225479126,
                -0.04635221138596535,
                0.020317725837230682,
                0.022349411621689796,
                -0.0302130039781332,
                -0.023590857163071632,
                -0.004860938526690006,
                0.016218941658735275,
                -0.05284147337079048,
                0.006141081918030977,
                -0.03443131968379021,
                -0.0005597450071945786,
                -0.014859287068247795,
                0.04485300928354263,
                0.011127789504826069,
                -0.06571372598409653,
                -0.013203752227127552,
                -0.018856190145015717,
                -0.020951448008418083,
                0.025916505604982376,
                -0.07404264807701111,
                0.058455940335989,
                0.0035165217705070972,
                0.04788065701723099,
                0.06918343901634216,
                0.026107588782906532,
                0.03776662424206734,
                -0.0024245574604719877,
                -0.06570654362440109,
                0.04119526967406273,
                0.05140175297856331,
                -0.00031213866895996034,
                0.018209444358944893,
                0.006016446277499199,
                -0.08653807640075684,
                -0.0347190797328949,
                0.021739011630415916,
                0.033325448632240295,
                0.005852266680449247,
                0.043557580560445786,
                0.038775037974119186,
                -0.09215369820594788,
                0.02063256874680519,
                -0.006247617769986391,
                0.0074636442586779594,
                -0.033227115869522095,
                -0.008101050741970539,
                0.007013835944235325,
                -0.017912639304995537,
                -0.0015979221789166331,
                0.057795993983745575,
                0.06690932810306549,
                -0.006486205849796534,
                0.0036937459371984005,
                -0.03793499246239662,
                0.006467925384640694,
                0.04441579431295395,
                -0.0002970501664094627,
                -0.040932077914476395,
                -0.0617346353828907,
                9.83812892559554e-35,
                -0.0034054014831781387,
                -0.06177634373307228,
                -0.06384485214948654,
                -0.038806986063718796,
                0.014066043309867382,
                -0.018641123548150063,
                -0.006098958197981119,
                -0.014410092495381832,
                -0.01696670427918434,
                -0.04235212132334709,
                -0.03951160982251167,
                0.03911018744111061,
                -0.04167555645108223,
                -0.04300825670361519,
                -0.04732726514339447,
                0.08078170567750931,
                -0.01536677684634924,
                -0.02402356266975403,
                0.018912622705101967,
                0.007838724181056023,
                -0.03583980351686478,
                -0.03949914872646332,
                0.042345769703388214,
                0.03884854540228844,
                0.06354261189699173,
                0.05166515335440636,
                -0.010189167223870754,
                0.009315986186265945,
                -0.010696128010749817,
                -0.09354665875434875,
                -0.008747899904847145,
                0.0019114271271973848,
                0.0038902575615793467,
                -0.05640915036201477,
                0.03876766934990883,
                -0.003881314070895314,
                0.004414037801325321,
                -0.01176685281097889,
                0.027142848819494247,
                0.007208331488072872,
                0.04516755789518356,
                0.005370157305151224,
                -0.048147328197956085,
                0.025761643424630165,
                -0.019024202600121498,
                1.6461407312817755e-06,
                0.023585520684719086,
                -0.007980928756296635,
                0.002568350173532963,
                0.022014698013663292,
                -0.012022491544485092,
                0.015144106931984425,
                0.036538586020469666,
                0.043881431221961975,
                0.04958214610815048,
                0.019500169903039932,
                0.008294608443975449,
                0.036256734281778336,
                0.01628132537007332,
                -0.008009259589016438,
                0.01537998765707016,
                0.012570667080581188,
                0.020751778036355972,
                0.005518723279237747,
                -0.028863249346613884,
                -0.05484021082520485,
                -0.08283170312643051,
                -0.01245891209691763,
                0.04613920673727989,
                0.02614223025739193,
                -0.03810034319758415,
                -0.013844748958945274,
                -0.03594230115413666,
                -0.024396361783146858,
                -0.022297270596027374,
                0.024702496826648712,
                0.06780944019556046,
                0.04083668813109398,
                -0.018143942579627037,
                0.004652028903365135,
                -0.06357844173908234,
                0.017684489488601685,
                0.007001797668635845,
                -0.02987169660627842,
                -0.014608259312808514,
                0.028457606211304665,
                -9.521548781776801e-05,
                0.038447074592113495,
                -0.01847328245639801,
                -0.02421502023935318,
                0.02601916901767254,
                -0.0010115179466083646,
                0.05604367330670357,
                -0.028460262343287468,
                -0.07066677510738373,
                -0.0023568281903862953,
                0.046553146094083786,
                -0.02188977412879467,
                -0.003215064527466893,
                0.074243925511837,
                -0.0454406812787056,
                0.008989531546831131,
                -0.002717469120398164,
                -0.009630732238292694,
                0.0019329262431710958,
                -0.03739580139517784,
                -0.005358275957405567,
                0.0419786237180233,
                -0.011952831409871578,
                0.0027627218514680862,
                0.0020121249835938215,
                -0.0008903435664251447,
                -0.006361030973494053,
                -0.030549056828022003,
                0.02869989722967148,
                -0.015334292314946651,
                0.03425072133541107,
                0.039806295186281204,
                0.012864233925938606,
                -0.0201870184391737,
                -0.011171435005962849,
                -0.029232973232865334,
                -0.051244188100099564,
                -0.023365801200270653,
                0.006095191929489374,
                -0.04212813079357147,
                0.06947612762451172,
                -0.04248510301113129,
                0.023503968492150307,
                0.012024285271763802,
                0.0005587979103438556,
                0.06844417750835419,
                0.008795620873570442,
                -0.027197858318686485,
                0.048399243503808975,
                -0.032889727503061295,
                -0.022006100043654442,
                -0.025060342624783516,
                0.04081226885318756,
                -0.007487910334020853,
                -0.021493827924132347,
                0.04696804657578468,
                0.0004013930738437921,
                -0.015206056647002697,
                -0.03545721620321274,
                0.003823252161964774,
                0.04305855184793472,
                -0.02780556119978428,
                0.03614117577672005,
                -0.07775411009788513,
                0.021983886137604713,
                0.008340387605130672,
                -0.039376672357320786,
                -0.016237298026680946,
                0.01838703826069832,
                -0.04076755419373512,
                -0.05469992011785507,
                -0.06818648427724838,
                0.020461855456233025,
                0.05061882734298706,
                -0.0334172360599041,
                0.007457728497684002,
                0.011059055104851723,
                0.03717811778187752,
                -0.03171628341078758,
                0.01636756770312786,
                -0.029588762670755386,
                0.08302424103021622,
                -0.014781150966882706,
                -0.02878870628774166,
                0.02966371923685074,
                -0.006926567759364843,
                -0.008290166035294533,
                0.009585913270711899,
                0.056724026799201965,
                -0.10430202633142471,
                0.013470464386045933,
                0.010592898353934288,
                0.04252989590167999,
                -0.02150544337928295,
                -0.004565461538732052,
                0.04294942319393158,
                -0.036897651851177216,
                -0.02220151759684086,
                0.056607723236083984,
                0.007148673292249441,
                -0.07215723395347595,
                0.05109398812055588,
                0.0016913828440010548,
                0.07042759656906128,
                0.019503267481923103,
                0.012315696105360985,
                -0.08394124358892441,
                0.023045385256409645,
                0.009009740315377712,
                0.05603198707103729,
                0.043409448117017746,
                -0.04990487918257713,
                0.026171229779720306,
                -0.015517637133598328,
                0.012483876198530197,
                0.022785477340221405,
                0.037164803594350815,
                -0.13315686583518982,
                0.03463456407189369,
                0.0222430769354105,
                0.029620612040162086,
                -0.0716525986790657,
                -0.03849303349852562,
                0.0017455724300816655,
                0.012263513170182705,
                0.0015526872593909502,
                0.03309471905231476,
                -0.04978511109948158,
                -0.00721319206058979,
                0.03381903097033501,
                -0.07933453470468521,
                -0.06424704939126968,
                -0.051861248910427094,
                -0.04654306918382645,
                0.016764432191848755,
                0.023409005254507065,
                -0.01474762987345457,
                -0.012719777412712574,
                0.004461332689970732,
                -0.02613545022904873,
                -0.016121506690979004,
                -0.005128014367073774,
                -0.011200991459190845,
                -0.010244637727737427,
                0.01813986524939537,
                -0.03373279049992561,
                0.005387017037719488,
                0.011618027463555336,
                0.02987111359834671,
                -0.005112211219966412,
                -0.00901076104491949,
                0.02049870230257511,
                0.007334326393902302,
                0.012289194390177727,
                0.06720933318138123,
                0.013314394280314445,
                -0.02981574647128582,
                0.04896299168467522,
                0.0028803448658436537,
                -0.006799373775720596,
                0.0419464185833931,
                0.03886472433805466,
                -0.011295020580291748,
                -0.021975750103592873,
                0.00243038940243423,
                -0.04395405948162079,
                -0.012027320452034473,
                -0.02254175953567028,
                0.019917082041502,
                -0.07390997558832169,
                -0.0005781525396741927,
                -0.016118178144097328,
                0.03347373381257057,
                0.01880037970840931,
                -0.023903312161564827,
                0.0076999180018901825,
                0.0031833217944949865,
                0.05019224435091019,
                0.05846988409757614,
                0.021657835692167282,
                -0.024783464148640633,
                -0.028451308608055115,
                0.07719175517559052,
                -0.07314115762710571,
                -0.02954489178955555,
                -0.022944409400224686,
                -0.05278787761926651,
                -0.006773919798433781,
                -0.008404717780649662,
                0.020556343719363213,
                -0.03367667272686958,
                0.01597190834581852,
                -0.06374925374984741,
                -0.022988487035036087,
                0.037095241248607635,
                0.026568908244371414,
                0.0654444471001625,
                0.006508237216621637,
                -0.02926536649465561,
                -0.015678133815526962,
                -0.019498053938150406,
                0.03037913143634796,
                0.07385636866092682,
                -0.032630398869514465,
                0.05907092243432999,
                0.0297081395983696,
                -0.04913199320435524,
                -0.020185425877571106,
                0.005460196174681187,
                -0.025838885456323624,
                -0.002008433686569333,
                0.02748013474047184,
                -0.059085819870233536,
                -0.03475554287433624,
                0.03431163355708122,
                -0.04291601479053497,
                0.023300429806113243,
                -0.0017827039118856192,
                -0.06312686204910278,
                -0.04981648549437523,
                0.04003363102674484,
                -0.007570941932499409,
                0.03804653137922287,
                -0.018545564264059067,
                0.05043664202094078,
                -0.04717133566737175,
                -0.012463419698178768,
                0.06959643214941025,
                -0.005544046871364117,
                -0.004385440144687891,
                0.022886507213115692,
                0.00568751385435462,
                0.049607664346694946,
                -0.01677342690527439,
                -0.04110254719853401,
                0.016270063817501068,
                0.024435613304376602,
                0.05045667290687561,
                -0.021640244871377945,
                0.03875647112727165,
                -0.024603363126516342,
                -0.009808652102947235,
                0.056423529982566833,
                -0.007272318471223116,
                0.04331205040216446,
                -0.005981759633868933,
                0.020887341350317,
                -0.050734810531139374,
                0.0011558780679479241,
                0.020960310474038124,
                0.0077444720081985,
                -0.09657450765371323,
                -0.0355801023542881,
                0.0030578337609767914,
                -0.06278102844953537,
                0.019211936742067337,
                0.0011220184387639165,
                -0.06892579793930054,
                -0.062729611992836,
                -0.006230926141142845,
                -0.05807598680257797,
                0.02711815945804119,
                -0.10984199494123459,
                0.014326964505016804,
                -0.03267604112625122,
                0.0035224203020334244,
                -0.0007886424427852035,
                -0.077665776014328,
                0.005597278941422701,
                0.04769132658839226,
                0.013914638198912144,
                0.05196565389633179,
                0.019294289872050285,
                -0.04669281840324402,
                0.021628931164741516,
                0.04463406279683113,
                0.0018804720602929592,
                0.007591667119413614,
                -0.013308664783835411,
                -0.04515323042869568,
                0.021913282573223114,
                0.061982832849025726,
                -0.005481415428221226,
                0.007427452597767115,
                0.008853142149746418,
                -0.07023392617702484,
                -0.054159991443157196,
                -0.05269823223352432,
                -0.04047710821032524,
                -0.011325343511998653,
                0.01151167694479227,
                0.027353059500455856,
                -0.06421616673469543,
                0.013242010958492756,
                0.0035985729191452265,
                -0.034625280648469925,
                -0.036570388823747635,
                -0.043982770293951035,
                -0.03777571767568588,
                0.0030965942423790693,
                0.02237395942211151,
                0.03350537642836571,
                0.005249532405287027,
                -0.0715155154466629,
                0.04040014371275902,
                0.07734392583370209,
                0.006595002952963114,
                0.03163889795541763,
                -0.024338098242878914,
                0.005108217243105173,
                -0.0383613258600235,
                0.0057362657971680164,
                -0.009370862506330013,
                -0.028761809691786766,
                0.0045561413280665874,
                -0.03729355335235596,
                -0.008349421434104443,
                -0.019745612516999245,
                0.00408738199621439,
                -0.0014150856295600533,
                -0.05006575211882591,
                -0.03326793387532234,
                -0.0033361667301505804,
                -0.007616034243255854,
                -0.03238264098763466,
                0.04748563468456268,
                0.015596889890730381,
                -0.003763392800465226,
                0.024322591722011566,
                -0.00840679369866848,
                -0.04057162255048752,
                0.07295338809490204,
                0.006533333100378513,
                -0.03404611721634865,
                0.018457945436239243,
                -0.008634132333099842,
                0.045692987740039825,
                0.012061876244843006,
                0.06025605648756027,
                -0.012466846965253353,
                -0.008746507577598095,
                -6.922778993612155e-05,
                0.0445738360285759,
                0.00525785842910409,
                -0.013606531545519829,
                0.07852686941623688,
                0.0416223406791687,
                0.005856151692569256,
                -0.008821463212370872,
                0.02669944427907467,
                -0.01830882951617241,
                0.014475659467279911,
                -0.0010883075883612037,
                -0.00808411743491888,
                -0.0557381696999073,
                -0.05650447681546211,
                -0.009950347244739532,
                0.007961287163197994,
                0.0403016172349453,
                0.09157684445381165,
                0.013794970698654652,
                0.013577464036643505,
                -0.018583420664072037,
                -0.008898967877030373,
                -0.012508251704275608,
                -0.014622755348682404,
                0.0630597397685051,
                0.05291229486465454,
                0.01994255930185318,
                0.002779025351628661,
                0.0030219629406929016,
                -0.053482502698898315,
                0.06249932572245598,
                -0.0674467533826828,
                -0.027123697102069855,
                0.08980034291744232,
                -0.040581535547971725,
                0.05514764413237572,
                -0.04853421449661255,
                -0.03392166644334793,
                -0.060030121356248856,
                -0.1168225035071373,
                -0.0008039395906962454,
                0.03472774848341942,
                -0.012870046310126781,
                0.024969888851046562,
                0.028863057494163513,
                -0.018146686255931854,
                0.014397462829947472,
                -0.02827310934662819,
                0.024973778054118156,
                0.021899119019508362,
                -0.01173547562211752,
                0.025023072957992554,
                -0.063687264919281,
                0.01433350145816803,
                -0.012480123899877071,
                -0.04995417222380638,
                -0.05508602783083916,
                -0.02978052757680416,
                0.017168406397104263,
                -0.017305217683315277,
                0.08418117463588715,
                0.0070496187545359135,
                0.032134030014276505,
                -0.009648126550018787,
                -0.026546796783804893,
                0.07152524590492249,
                -0.044853098690509796,
                0.02272791974246502,
                0.020491693168878555,
                0.022018393501639366,
                0.01841742731630802,
                -0.03132661432027817,
                0.002973453374579549,
                0.07092002779245377,
                -0.015724683180451393,
                0.03337493911385536,
                0.010301870293915272,
                0.04570918530225754,
                -0.0027834856882691383,
                0.023067042231559753,
                -0.01294611394405365,
                -0.0010313561651855707,
                -0.009029025211930275,
                -0.049049876630306244,
                0.003080914728343487,
                -0.015571797266602516,
                -0.07742032408714294,
                0.05134756863117218,
                -0.028543299064040184,
                0.0020901199895888567,
                0.010262877680361271,
                -0.04418899118900299,
                0.020689863711595535,
                -0.020270036533474922,
                -0.008928134106099606,
                -0.004242364317178726,
                0.008920717984437943,
                0.03216250613331795,
                0.027911461889743805,
                0.07048026472330093,
                -0.0500883124768734,
                0.0036494540981948376,
                -0.03976810723543167,
                0.037359677255153656,
                -0.011914313770830631,
                -0.02967839688062668,
                0.05532212182879448,
                -0.019671831279993057,
                -0.005991838872432709,
                0.009847034700214863,
                -0.02067544497549534,
                0.05512358993291855,
                0.03363998606801033,
                0.026887957006692886,
                -0.007166702765971422,
                -0.08169106394052505,
                -0.04454761743545532,
                -0.03324349969625473,
                0.04912955313920975,
                0.03970406949520111,
                0.056467801332473755,
                0.028202857822179794,
                0.057589393109083176,
                -0.0112940464168787,
                0.004600290209054947,
                0.03187515214085579,
                0.004431718494743109,
                -0.03802437335252762,
                -0.03287060931324959,
                -0.024640388786792755,
                -0.006880221422761679,
                0.046781811863183975,
                0.05031402409076691,
                -0.016667459160089493,
                -0.0423671118915081,
                0.001566804712638259,
                0.035002466291189194,
                -0.052942875772714615,
                -4.626034958096261e-33,
                -0.025599487125873566,
                -0.016043489798903465,
                0.046831317245960236,
                0.01458823774009943,
                0.03225189819931984,
                -0.011421444825828075,
                0.06391365826129913,
                -0.03377098590135574,
                -0.022119944915175438,
                0.01931997947394848,
                -0.015718907117843628,
                -0.03233949467539787,
                0.025631068274378777,
                0.011067303828895092,
                -0.03595559671521187,
                0.10363521426916122,
                -0.0027845753356814384,
                0.041268058121204376,
                -0.004739607218652964,
                -0.017109565436840057,
                -0.03609948232769966,
                0.019301114603877068,
                0.05062651261687279,
                0.019965533167123795,
                0.0009065536432899535,
                0.010832295753061771,
                -0.010753581300377846,
                0.052457209676504135,
                0.013358012773096561,
                0.009995012544095516,
                0.005264608189463615,
                -0.043805018067359924,
                -0.011773289181292057,
                0.014224852435290813,
                -0.0017116207163780928,
                -0.036565184593200684,
                -0.0488126240670681,
                0.03320358693599701,
                0.05898463726043701,
                0.02873930148780346,
                0.025885319337248802,
                0.023219522088766098,
                0.04881587624549866,
                -0.035071082413196564,
                0.00013087847037240863,
                -0.023284131661057472,
                0.007240979000926018,
                0.0359102338552475,
                0.01519789732992649,
                -0.060344260185956955,
                -0.03162103891372681,
                -0.010048508644104004,
                -0.06067902594804764,
                0.047539882361888885,
                -0.045299287885427475,
                -0.002367638982832432,
                -0.01894589699804783,
                -0.016596946865320206,
                -0.019424330443143845,
                -0.02342962846159935,
                -0.007097992580384016,
                0.055812109261751175,
                0.011746751144528389,
                -0.041127365082502365,
                0.004910592455416918,
                0.008508682250976562,
                0.023765765130519867,
                0.045465607196092606,
                0.07313375920057297,
                0.006344348192214966,
                -0.06061801686882973,
                0.09060744196176529,
                -0.06257923692464828,
                0.002458315808326006,
                -0.04871780425310135,
                -0.06473397463560104,
                0.022744731977581978,
                -0.013671102933585644,
                -0.020873170346021652,
                -0.003176032332703471,
                0.015561483800411224,
                0.024847818538546562,
                0.030204037204384804,
                0.009525284171104431,
                0.05633077770471573,
                -0.010987854562699795,
                -0.025219842791557312,
                0.018858732655644417,
                0.055778149515390396,
                -0.0015810239128768444,
                0.02931039035320282,
                0.053106922656297684,
                -0.011798685416579247,
                0.013546264730393887,
                0.048528559505939484,
                0.02771935984492302,
                -0.010208835825324059,
                0.007775689009577036,
                0.018719278275966644,
                0.023035382851958275,
                -0.028646372258663177,
                0.031744420528411865,
                -0.03056706115603447,
                -0.023197883740067482,
                -0.00630164984613657,
                0.044945280998945236,
                0.06056436151266098,
                -0.026984939351677895,
                -0.05503513664007187,
                0.028394164517521858,
                0.016938544809818268,
                0.0675114095211029,
                -0.03362685441970825,
                -0.02566109038889408,
                -0.02452438324689865,
                -0.026049353182315826,
                0.005872962065041065,
                -0.042860232293605804,
                -0.009990796446800232,
                -0.019787302240729332,
                0.0691230297088623,
                -0.04882027581334114,
                0.020049545913934708,
                -0.00923225563019514,
                0.014165903441607952,
                -0.024799147620797157,
                0.010028346441686153,
                -0.008638701401650906,
                -0.00994272530078888,
                0.0015153513522818685,
                -0.00014912728511262685,
                0.029229287058115005,
                2.1153215357117006e-07,
                0.015570949763059616,
                0.005187726579606533,
                0.02515900507569313,
                0.02153150551021099,
                0.01128346472978592,
                -0.008341727778315544,
                -0.013673710636794567,
                -0.05131947994232178,
                -0.039947591722011566,
                0.06935238838195801,
                -0.054914768785238266,
                -0.03585835173726082,
                -0.020427169278264046,
                -0.07526129484176636,
                -0.015868499875068665,
                -0.0909201055765152,
                0.012064941227436066,
                -0.0454457625746727,
                -0.0624605268239975,
                0.01890527829527855,
                -0.020983658730983734,
                0.03661024942994118,
                0.02290400303900242,
                -0.0258495956659317,
                0.016263805329799652,
                -0.011902101337909698,
                0.007310532499104738,
                0.03449035435914993,
                0.010798550210893154,
                0.0482611246407032,
                0.045896731317043304,
                0.012049525044858456,
                0.006108639296144247,
                0.005370097700506449,
                0.004509656224399805,
                0.003466997994109988,
                0.06592775881290436,
                0.034238096326589584,
                0.019774600863456726,
                0.03414780646562576,
                0.04840082302689552,
                -0.03932356461882591,
                -0.007261723279953003,
                -0.016678541898727417,
                -0.0010197986848652363,
                -0.023665551096200943,
                -0.049268435686826706,
                -0.0005708818789571524,
                -0.05394004285335541,
                -0.015709923580288887,
                -0.01178956963121891,
                0.024841705337166786,
                -0.0433063842356205,
                -0.02753427065908909,
                0.014972478151321411,
                -0.054541416466236115,
                -0.051168929785490036,
                0.04098464548587799,
                0.04942085221409798,
                0.05281990393996239,
                -0.014385166577994823,
                -0.004124113358557224,
                0.013782688416540623,
                0.02972446195781231,
                0.06799934059381485,
                -0.016954980790615082,
                -0.014949754811823368,
                2.0419289667055773e-34,
                -0.01583671197295189,
                0.0347825363278389,
                -0.018676450476050377,
                0.011166880838572979,
                0.03045540675520897,
                -0.05386584252119064,
                0.0033685846719890833,
                0.004488211590796709,
                0.004686566535383463,
                0.0019796499982476234,
                -0.03135182335972786,
                -0.028542887419462204,
                -0.001910280785523355,
                -0.0064485748298466206,
                -0.009368183091282845,
                0.018799474462866783,
                0.006852360442280769,
                -0.028314249590039253,
                -0.02100566029548645,
                -0.08144325017929077,
                -0.015135109424591064,
                0.08663319796323776,
                0.006021352484822273,
                -0.009174630977213383,
                0.02294730767607689,
                0.04166053608059883,
                -0.0223168283700943,
                0.014136376790702343,
                -0.0004522518429439515,
                0.1074470803141594,
                -0.03395937383174896,
                0.004746487829834223,
                0.02387186512351036,
                0.01976003684103489,
                0.050132982432842255,
                -0.028128042817115784,
                -0.008750238455832005,
                0.03628343716263771,
                -0.03401593491435051,
                -0.017630772665143013,
                -0.019807562232017517,
                -0.020521128550171852,
                -0.04080097749829292,
                0.012725114822387695,
                -0.04037760943174362,
                1.4930012639524648e-06,
                0.042359497398138046,
                0.019745077937841415,
                -0.03995480760931969,
                -0.06382742524147034,
                -0.03924907371401787,
                -0.021280864253640175,
                -0.007830233313143253,
                0.029413560405373573,
                0.0005442557740025222,
                -0.019010353833436966,
                0.0035876112524420023,
                0.04311699792742729,
                -0.04177436605095863,
                -0.061443813145160675,
                0.022989287972450256,
                0.023856893181800842,
                -0.005431268364191055,
                -0.010667216964066029,
                -0.03141317516565323,
                -0.0052622174844145775,
                -0.0825887992978096,
                -0.008346324786543846,
                -0.005083704832941294,
                -0.03674359619617462,
                0.04965483024716377,
                -0.008869308046996593,
                -0.019583502784371376,
                -0.0159132182598114,
                0.025233909487724304,
                0.004678563680499792,
                -0.0057965051382780075,
                0.036005932837724686,
                0.021659811958670616,
                0.06803053617477417,
                -0.03850909322500229,
                -0.014138491824269295,
                0.0347563810646534,
                -0.006681947503238916,
                0.08270762115716934,
                0.014556298032402992,
                0.020583126693964005,
                0.03153621032834053,
                -0.0002956412499770522,
                -0.000755138520617038,
                -0.0006134644499979913,
                -0.02521388605237007,
                0.050435200333595276,
                0.005486203357577324,
                0.052441466599702835,
                -0.0525105781853199,
                0.032211702316999435,
                -0.014836981892585754,
                0.026978418231010437,
                0.001399592962116003,
                0.06801880151033401,
                -0.03632983937859535,
                -0.06055215001106262,
                -0.034908510744571686,
                0.008939635939896107,
                -0.03194883465766907,
                -0.01334767509251833,
                0.03796711936593056,
                -0.045857492834329605,
                -0.02992442063987255,
                -0.021842513233423233,
                -0.0037232416216284037,
                0.056571345776319504,
                0.07030145078897476,
                0.03408849239349365,
                -0.031023647636175156,
                -0.030443686991930008,
                0.013744364492595196,
                -0.023998524993658066,
                0.02748630940914154,
                0.0056828991509974,
                -0.006032190751284361,
                0.01878054067492485,
                -0.005582562647759914,
                0.055307500064373016,
                0.004088930319994688,
                0.004163672681897879,
                0.014055044390261173,
                0.039705678820610046,
                0.013401377014815807,
                0.009367208927869797,
                0.05773065239191055,
                -0.012541092000901699,
                -0.009232975542545319,
                0.029197275638580322,
                -0.04283786192536354,
                -0.0483352392911911,
                -0.07796882092952728,
                0.0015723974211141467,
                0.0024880750570446253,
                -0.008476131595671177,
                0.004027812276035547,
                -0.03310675919055939,
                0.004309605807065964,
                -0.0046456074342131615,
                0.05306411162018776,
                -0.01246914267539978,
                0.036264512687921524,
                -0.00789218582212925,
                0.03602321073412895,
                -0.01838838867843151,
                0.011871176771819592,
                -0.07594720274209976,
                -0.04324197769165039,
                -0.04916909337043762,
                -0.0332961231470108,
                0.04713761433959007,
                0.034101326018571854,
                -0.018414298072457314,
                -0.00726839667186141,
                0.045144401490688324,
                0.06319987028837204,
                -0.024039089679718018,
                0.023437926545739174,
                0.0007612861809320748,
                -0.004467538092285395,
                -0.00537849823012948,
                -0.03139223903417587,
                -0.04612419009208679,
                0.009238426573574543,
                0.003887393046170473,
                -0.004164260346442461,
                -0.04366873577237129,
                -0.027600863948464394,
                -0.010281971655786037,
                -0.05582465976476669,
                -0.022850356996059418,
                0.05855399742722511,
                -0.041867177933454514,
                0.057121630758047104,
                -0.04293293505907059,
                -0.030789894983172417,
                -0.023532599210739136,
                -0.015535124577581882,
                0.020510075613856316,
                0.032327841967344284,
                -0.028804579749703407,
                0.08114948868751526,
                -0.017086632549762726,
                -0.01096874475479126,
                0.04059252515435219,
                -0.014925726689398289,
                -0.1169857531785965,
                0.01473172940313816,
                0.04798689857125282,
                0.12141350656747818,
                0.0027683363296091557,
                -0.046806398779153824,
                0.10900436341762543,
                -0.03377070277929306,
                -0.003772123483940959,
                0.059060413390398026,
                -0.013811473734676838,
                -0.0018472685478627682,
                0.01940060406923294,
                0.021712712943553925,
                -0.018631646409630775,
                -0.003441964276134968,
                0.0482865534722805,
                -0.03746507316827774,
                -0.0029774466529488564,
                0.05311252549290657,
                -0.03065076842904091,
                -0.006067750044167042,
                -0.025060398504137993,
                -0.005065758712589741,
                0.002486115787178278,
                -0.03642788901925087,
                0.006966344080865383,
                -0.03964787349104881,
                -0.018974710255861282,
                -0.047724053263664246,
                0.0367124006152153,
                0.014688488095998764,
                0.011031106114387512,
                0.009005529806017876,
                0.019735360518097878,
                0.013477788306772709,
                0.036021728068590164,
                -0.027716202661395073,
                0.038125790655612946,
                -0.0543564110994339,
                0.029161037877202034,
                -0.009334307163953781,
                0.03922683745622635,
                -0.0032960032112896442,
                -0.08493302762508392,
                0.021395191550254822,
                -0.025601519271731377,
                0.017156513407826424,
                0.009321896359324455,
                0.014087427407503128,
                -0.0016826799837872386,
                0.022269263863563538,
                -0.024281371384859085,
                0.01586597040295601,
                0.04535749927163124,
                0.0283319354057312,
                -0.07046691328287125,
                -0.006210463121533394,
                -0.022500768303871155,
                -0.005825662985444069,
                0.06523134559392929,
                0.02958531491458416,
                -0.015986517071723938,
                0.03964032232761383,
                -0.010007401928305626,
                0.02569332718849182,
                0.0008079210529103875,
                -0.011797767132520676,
                -0.03490192070603371,
                -0.006837435998022556,
                0.018965089693665504,
                0.011771583929657936,
                -0.007082089316099882,
                -0.029463067650794983,
                -0.059173956513404846,
                0.015590813010931015,
                0.04699113219976425,
                -0.08938358724117279,
                0.0022899701725691557,
                0.015054889023303986,
                0.004703423008322716,
                -0.003120571142062545,
                0.029340587556362152,
                0.07891711592674255,
                0.01737179607152939,
                -0.01633155159652233,
                -0.03427078574895859,
                0.04394283890724182,
                -0.045552413910627365,
                0.014028829522430897,
                -0.023447968065738678,
                0.05112124979496002,
                -0.00421869894489646,
                -0.025570744648575783,
                0.030431054532527924,
                -0.024458298459649086,
                0.06733435392379761,
                -0.019502170383930206,
                -0.024322552606463432,
                0.02952551655471325,
                -0.02934282086789608,
                -0.013137510046362877,
                0.0223198514431715,
                0.01838231272995472,
                -0.006326654460281134,
                -0.04376525059342384,
                -0.03186865895986557,
                -0.05396779999136925,
                -0.030904777348041534,
                -0.024311117827892303,
                -0.015855642035603523,
                0.02816775254905224,
                0.03927268087863922,
                0.021754803135991096,
                0.019127188250422478,
                -0.024305041879415512,
                -0.04867764189839363,
                0.02588411048054695,
                -0.0158262737095356,
                0.02567974105477333,
                -0.04629881680011749,
                0.027598803862929344,
                0.018403608351945877,
                -0.00767107680439949,
                -0.07345309853553772,
                -0.0524272695183754,
                0.061918072402477264,
                0.009535840712487698,
                0.006413870956748724,
                -0.0053040566854178905,
                0.06191209331154823,
                0.030677955597639084,
                -0.0797145888209343,
                0.0407724492251873,
                -0.02623390220105648,
                -0.0010711558861657977,
                -0.003955693915486336,
                0.007692074868828058,
                -0.026552041992545128,
                0.002233590465039015,
                0.05956045910716057,
                -0.033809151500463486,
                -0.006278543267399073,
                0.02387896180152893,
                0.006725633051246405,
                -0.05977923050522804,
                0.011876317672431469,
                0.03342802822589874,
                0.04320782795548439,
                0.026831790804862976,
                -0.05511422082781792,
                0.02027769386768341,
                0.023429566994309425,
                0.05156218633055687,
                -0.03670033439993858,
                -0.0077919727191329,
                -0.07208447903394699,
                -0.010715228505432606,
                -0.03405025601387024,
                -0.013435719534754753,
                0.03756647929549217,
                -0.005040520802140236,
                0.013202591799199581,
                -0.07886436581611633,
                -0.002307063667103648,
                0.045532986521720886,
                0.01701604574918747,
                -0.0501500628888607,
                0.018797805532813072,
                0.05859663337469101,
                -0.07788071036338806,
                -0.02420349232852459,
                -0.04059373587369919,
                -0.009096553549170494,
                -0.0005684582283720374,
                -0.03482171893119812,
                -0.0304583553224802,
                0.01772088184952736,
                -0.043838080018758774,
                -0.016289122402668,
                0.04267948493361473,
                0.0820586234331131,
                0.007447359152138233,
                0.01574466936290264,
                -0.02276424691081047,
                0.06553042680025101,
                -0.013573052361607552,
                -0.021871346980333328,
                -0.008546116761863232,
                0.030957426875829697,
                -0.03304675966501236,
                -0.04088220000267029,
                0.03336331620812416,
                0.0019039851613342762,
                0.0026008449494838715,
                0.028911994770169258,
                0.05274407938122749,
                0.07618378847837448,
                -0.03679041191935539,
                0.05343193560838699,
                0.013800596818327904,
                -0.024935441091656685,
                -0.024742869660258293,
                -0.04324953258037567,
                -0.028819629922509193,
                0.033007629215717316,
                -0.006491816136986017,
                0.01404096931219101,
                0.00028061075136065483,
                0.021432414650917053,
                0.008670140989124775,
                -0.02013978734612465,
                -0.013099835254251957,
                0.03946174308657646,
                -0.03342493996024132,
                0.02378595992922783,
                0.03604830056428909,
                0.017137104645371437,
                -0.013459292240440845,
                -0.00215746252797544,
                0.052150700241327286,
                -0.024950014427304268,
                0.008743682876229286,
                -0.03916367143392563,
                -0.03873451426625252,
                0.03142340108752251,
                0.06012952700257301,
                -0.0020296319853514433,
                -0.004322378896176815,
                -0.055806830525398254,
                0.04462122917175293,
                0.009644502773880959,
                0.023543909192085266,
                0.0001265790342586115,
                -0.10363098978996277,
                -0.032350290566682816,
                -0.032427165657281876,
                0.006062393542379141,
                -0.04208605736494064,
                0.004909023642539978,
                -0.005416108760982752,
                -0.04632805660367012,
                0.001456075464375317,
                0.061854537576436996,
                0.01776544563472271,
                0.0207846499979496,
                0.02145290933549404,
                -0.022112861275672913,
                -0.030382633209228516,
                -0.0273730531334877,
                -0.017353158444166183,
                -0.013995038345456123,
                0.02080782875418663,
                -0.030407961457967758,
                -0.11462699621915817,
                -0.033343952149152756,
                0.02376464381814003,
                0.08057468384504318,
                0.04876571148633957,
                -0.03411710262298584,
                0.06651906669139862,
                -0.030402423813939095,
                -0.024009814485907555,
                0.026494987308979034,
                -0.147834911942482,
                0.039296235889196396,
                -0.006203767377883196,
                -0.06943540275096893,
                -0.01722235232591629,
                0.023611051961779594,
                -0.0005183483008295298,
                -0.04931165277957916,
                0.050500545650720596,
                -0.004186313133686781,
                0.008353734388947487,
                0.006574038416147232,
                0.05891196429729462,
                0.04011322557926178,
                0.052418168634176254,
                -0.0175552386790514,
                0.022654389962553978,
                -0.047950420528650284,
                0.035226136445999146,
                -0.012058429419994354,
                -0.007867141626775265,
                -0.004431603010743856,
                -0.06657107174396515,
                0.025426015257835388,
                -0.05388689786195755,
                -0.07891518622636795,
                -0.013032623566687107,
                -0.00884276907891035,
                0.021917985752224922,
                0.00031976765603758395,
                -0.03053075261414051,
                -0.023953143507242203,
                -0.08493838459253311,
                0.04171847924590111,
                -0.01858445443212986,
                -0.024271061643958092,
                0.05580674111843109,
                0.01240802463144064,
                0.0312739759683609,
                0.02215742878615856,
                0.0012073091929778457,
                -0.004542933311313391,
                0.013476554304361343,
                0.015496102161705494,
                -0.009668413549661636,
                -0.02130279131233692,
                0.026228900998830795,
                -0.013818061910569668,
                0.026743432506918907,
                -0.0030839156825095415,
                -0.014757825061678886,
                -0.0050348034128546715,
                0.03613869473338127,
                0.0287848562002182,
                -0.014972105622291565,
                -0.04998820275068283,
                0.01222221553325653,
                0.019106443971395493,
                0.013699310831725597,
                0.041492853313684464,
                -0.03677920997142792,
                0.02340317890048027,
                -0.017352933064103127,
                -0.004294162150472403,
                -0.01662902720272541,
                0.052204329520463943,
                -0.0011318974429741502,
                -0.04877800866961479,
                0.007120238617062569,
                0.06465115398168564,
                -0.016450274735689163,
                0.007283833809196949,
                -0.00790616124868393,
                -0.027688292786478996,
                -0.038364458829164505,
                -0.014151898212730885,
                -0.008409992791712284,
                0.0008091969648376107,
                0.024835148826241493,
                0.03800233080983162,
                -0.05741327255964279,
                0.004857990890741348,
                -0.04813569784164429,
                -0.03675931692123413,
                0.027825823053717613,
                -0.013299194164574146,
                0.020432844758033752,
                -0.05115930736064911,
                0.007500798441469669,
                -0.03652041405439377,
                0.10866613686084747,
                0.03626259043812752,
                0.04357939586043358,
                -0.00895769614726305,
                -0.005395122803747654,
                -0.07141662389039993,
                0.05524503439664841,
                -0.00611016433686018,
                -0.020327722653746605,
                0.06975199282169342,
                0.008172199130058289,
                -0.04315194487571716,
                0.04450428858399391,
                0.0059818970039486885,
                0.0439082495868206,
                -0.01894501969218254,
                -0.023500537499785423,
                -0.04946357011795044,
                0.011035861447453499,
                0.039756178855895996,
                -6.151375162164477e-33,
                0.03646310418844223,
                -0.023939870297908783,
                0.024789663031697273,
                0.01820206455886364,
                -0.018280867487192154,
                0.0052587599493563175,
                0.04228728264570236,
                -0.0034857462160289288,
                -0.011524266563355923,
                0.018964190036058426,
                -0.017900200560688972,
                -0.009852461516857147,
                0.026692207902669907,
                -0.004534770734608173,
                0.032083846628665924,
                -0.00806733500212431,
                0.0374583974480629,
                -0.02166457660496235,
                0.03930538892745972,
                0.013629489578306675,
                0.0317552424967289,
                -0.012220070697367191,
                -0.016674472019076347,
                0.024444453418254852,
                -0.003909658640623093,
                -0.05506518483161926,
                -0.017592627555131912,
                0.035701729357242584,
                0.03421879932284355,
                -0.012033236213028431,
                0.03245796263217926,
                -0.05358639359474182,
                -0.011801431886851788,
                -0.03619097173213959,
                0.0026322510093450546,
                0.0929492861032486,
                -0.015880484133958817,
                0.024992555379867554,
                -0.0945991650223732,
                0.017411338165402412,
                -0.06401708722114563,
                -0.019353482872247696,
                0.01553201861679554,
                -0.0435018353164196,
                -0.01651015132665634,
                -0.020399155095219612,
                0.00041421098285354674,
                0.012823277153074741,
                0.04083966463804245,
                0.009089123457670212,
                -0.006527572404593229,
                0.03915072977542877,
                0.006807303987443447,
                -0.00870367418974638,
                -0.018429618328809738,
                -0.006606186740100384,
                -0.014191662892699242,
                0.04526447504758835,
                -0.044819485396146774,
                -0.000981259741820395,
                -0.05594087019562721,
                -0.0011401647934690118,
                0.005531262140721083,
                0.043261654675006866,
                0.009722111746668816,
                0.004443713929504156,
                0.09099572896957397,
                0.0580640472471714,
                -0.012990130111575127,
                -0.029249677434563637,
                -0.041477177292108536,
                0.05817057937383652,
                0.022093158215284348,
                0.011171305552124977,
                0.013162815943360329,
                -0.0024161762557923794,
                -0.029787853360176086,
                0.05147029086947441,
                0.08716233819723129,
                0.013689259998500347,
                -0.05590777471661568,
                0.04698198661208153,
                -0.033286262303590775,
                0.03816152736544609,
                -0.016880158334970474,
                -0.03470942750573158,
                0.030415624380111694,
                -0.05218096077442169,
                -0.007832051254808903,
                -0.02252691052854061,
                -0.03319563716650009,
                0.012157002463936806,
                0.042326681315898895,
                0.025878924876451492,
                0.05290113389492035,
                -0.032143764197826385,
                -0.03709840774536133,
                -0.06389062851667404,
                0.0008414517506025732,
                -0.0005995527608320117,
                -0.03097160905599594,
                0.03590003401041031,
                -0.039272405207157135,
                -0.04879603162407875,
                -0.015095258131623268,
                0.00460044527426362,
                0.008747725747525692,
                0.05513652041554451,
                -0.05199278146028519,
                -0.010890948586165905,
                -0.05095160752534866,
                8.641916065244004e-05,
                -0.008987316861748695,
                0.0024625451769679785,
                0.010616823099553585,
                -0.05160133168101311,
                0.01657884754240513,
                -0.011060803197324276,
                -0.057061489671468735,
                -0.05115067958831787,
                0.02245238982141018,
                0.06571988016366959,
                -0.08265935629606247,
                0.013286651112139225,
                0.020025044679641724,
                0.017924178391695023,
                0.004518278408795595,
                0.04568145424127579,
                -0.017422741279006004,
                -0.007793059106916189,
                -0.03627464920282364,
                -0.0547994002699852,
                2.275133823559372e-07,
                -0.00223755044862628,
                -0.00747752096503973,
                -0.04409579932689667,
                0.021655892953276634,
                0.006227371748536825,
                0.03954720124602318,
                -0.00474926270544529,
                0.047850266098976135,
                -0.026177065446972847,
                0.005319047253578901,
                0.07634662836790085,
                0.03779793530702591,
                -0.021989207714796066,
                0.00812931451946497,
                0.055842701345682144,
                -0.01241266168653965,
                0.06491024047136307,
                -0.05852913856506348,
                0.044023748487234116,
                -0.0667845606803894,
                -0.016075756400823593,
                -0.024335728958249092,
                -0.007192630786448717,
                -0.0075836931355297565,
                0.027531420812010765,
                -0.022074656561017036,
                0.060099538415670395,
                -0.012900708243250847,
                -0.001336822286248207,
                -0.008909356780350208,
                -0.0020186046604067087,
                -0.07074138522148132,
                -0.0130497757345438,
                0.0065266829915344715,
                0.006645841524004936,
                -0.009751137346029282,
                0.028789088129997253,
                0.03520326688885689,
                -0.023292934522032738,
                0.0440828837454319,
                0.008352510631084442,
                -0.020154142752289772,
                -0.01952558383345604,
                0.0849798247218132,
                -0.0064918724820017815,
                -0.036560092121362686,
                -0.027844013646245003,
                0.03898538649082184,
                -0.10869107395410538,
                0.014601757749915123,
                -0.02883385308086872,
                0.05210397392511368,
                -0.014672249555587769,
                0.06818481534719467,
                -0.020630542188882828,
                -0.026571666821837425,
                0.0042629605159163475,
                0.022044740617275238,
                -0.003484318032860756,
                -0.052751511335372925,
                -0.03029080666601658,
                0.01194569282233715,
                0.0413408987224102,
                -0.027351150289177895,
                0.002325894543901086,
                0.03359147161245346,
                0.032444361597299576,
                7.748486826861079e-35,
                -0.016806401312351227,
                -0.00770165678113699,
                0.009019540622830391,
                -0.012414987199008465,
                0.0008265855140052736,
                -0.02080274559557438,
                0.00010037897300207987,
                -0.02081426791846752,
                0.008480983786284924,
                0.046227917075157166,
                -0.01642168127000332
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\r|50p\\xa8x\\xcc\\xdb\\x8e\\xa7\\xb7&\\xadW\\xdd'",
        "id__": "0accc01e-4162-4839-af92-229f521e29c8"
    },
    {
        "Certificates": {
            "name": [],
            "rep": []
        },
        "Education": {
            "name": "HND/Mechanical Systems",
            "rep": [
                -0.053309012204408646,
                -0.061574537307024,
                -0.01710454747080803,
                -0.0283092949539423,
                -0.0034988352563232183,
                -0.004827138036489487,
                -0.007826382294297218,
                0.023749148473143578,
                0.0016380173619836569,
                -0.03614034131169319,
                0.003609615610912442,
                -0.039902687072753906,
                0.0051491293124854565,
                -0.005731489975005388,
                0.04243908077478409,
                -0.025856228545308113,
                0.002771276980638504,
                0.07790576666593552,
                -0.10522513836622238,
                -0.04039016366004944,
                0.01343647763133049,
                -0.018899809569120407,
                -0.006569848395884037,
                0.06630391627550125,
                0.0244541484862566,
                0.015166631899774075,
                -0.02692309021949768,
                0.005426379851996899,
                0.03926309198141098,
                0.026646362617611885,
                0.012476800009608269,
                0.037802740931510925,
                0.053115688264369965,
                0.10777642577886581,
                1.3318410765350563e-06,
                -0.013686963357031345,
                0.04938248172402382,
                -0.006490983534604311,
                -0.037179119884967804,
                0.007954130880534649,
                0.02344951033592224,
                -0.048593707382678986,
                -0.013671544380486012,
                -0.027793677523732185,
                0.04584844037890434,
                -0.04475462809205055,
                -0.03134956210851669,
                -0.06598645448684692,
                -0.016672702506184578,
                -0.00439105462282896,
                0.01363945472985506,
                0.03214253857731819,
                -0.011292153969407082,
                -0.01639636792242527,
                0.015322668477892876,
                0.015580393373966217,
                0.017159026116132736,
                0.062231481075286865,
                0.0729401707649231,
                -0.03685421124100685,
                -0.03284590691328049,
                0.02096683904528618,
                -0.0008574581006541848,
                -0.038726549595594406,
                0.06381576508283615,
                0.0112488167360425,
                0.02185198664665222,
                0.05294867604970932,
                -0.004921221639961004,
                0.06441189348697662,
                0.023181060329079628,
                -0.0032166584860533476,
                0.03333987668156624,
                0.007524735294282436,
                -0.052628085017204285,
                -0.008846229873597622,
                -0.020656444132328033,
                0.01629614643752575,
                -0.0001930080761667341,
                -0.006542570888996124,
                -0.022971002385020256,
                -0.021515294909477234,
                0.01923869177699089,
                -0.017474059015512466,
                0.0318366140127182,
                -0.009996363893151283,
                -0.0353667214512825,
                0.017527030780911446,
                0.021027401089668274,
                0.03264723718166351,
                0.092750683426857,
                -0.06561630964279175,
                0.014882313087582588,
                0.011107029393315315,
                0.03562675788998604,
                0.001549683976918459,
                -0.001259241602383554,
                -0.02685156650841236,
                0.03656024858355522,
                -0.09328842163085938,
                -0.10039875656366348,
                -0.022641563788056374,
                0.07842782139778137,
                -0.0027430111076682806,
                -0.004755931906402111,
                0.017309773713350296,
                -0.05462613329291344,
                0.017717234790325165,
                -0.0018560258904471993,
                0.04573372006416321,
                -0.0532052181661129,
                -0.023616809397935867,
                0.0035977449733763933,
                0.053424809128046036,
                -0.021932171657681465,
                -0.047390714287757874,
                -0.03420763090252876,
                0.009474028833210468,
                0.014078304171562195,
                -0.0301508866250515,
                -0.027432400733232498,
                -6.390026828739792e-05,
                0.007475842256098986,
                0.014406013302505016,
                -0.0030398524831980467,
                0.0476033017039299,
                0.017837494611740112,
                -0.0025771271903067827,
                0.0357523187994957,
                0.02252352423965931,
                0.014990240335464478,
                0.024660127237439156,
                -0.015999577939510345,
                0.00565763097256422,
                -0.07344942539930344,
                0.06832899898290634,
                0.005019765812903643,
                0.050690922886133194,
                -0.04435214772820473,
                -0.01303069107234478,
                0.058404967188835144,
                -0.02945091761648655,
                -0.0004801682662218809,
                0.038891229778528214,
                0.02323523722589016,
                0.021039070561528206,
                0.05113872140645981,
                0.03799017146229744,
                0.031755540519952774,
                0.004042733460664749,
                -0.05086499825119972,
                0.06162368506193161,
                0.014191577211022377,
                -0.0018798672826960683,
                0.032596006989479065,
                -0.01690790057182312,
                0.005426045041531324,
                0.020235322415828705,
                -0.032916080206632614,
                0.007713474333286285,
                0.004634929820895195,
                -0.004493031185120344,
                0.04440554976463318,
                0.019900882616639137,
                -0.03694208711385727,
                0.0008411190938204527,
                -0.022722482681274414,
                -0.053865108639001846,
                -0.03354260325431824,
                0.024935347959399223,
                -0.01797465607523918,
                0.013741983100771904,
                -0.040729373693466187,
                0.06758959591388702,
                0.006700996775180101,
                -0.05241062864661217,
                0.048803236335515976,
                0.01749107800424099,
                0.007758272346109152,
                0.028426265344023705,
                0.04761714115738869,
                -0.016540974378585815,
                -0.004250786267220974,
                -0.020497774705290794,
                -0.008344892412424088,
                -0.031264033168554306,
                0.006525990087538958,
                -0.05828815698623657,
                -0.02179623208940029,
                -0.0540822371840477,
                -0.00840140599757433,
                0.007243079133331776,
                -0.059936586767435074,
                0.010654705576598644,
                0.06962289661169052,
                0.05116754397749901,
                0.014655984938144684,
                0.03617372736334801,
                0.00976358912885189,
                0.034124016761779785,
                0.015867510810494423,
                0.011572366580367088,
                0.01287826243788004,
                0.027423599734902382,
                0.0027713479939848185,
                -0.01242990605533123,
                0.016088908538222313,
                -0.028809934854507446,
                -0.031665440648794174,
                -0.0054961382411420345,
                -0.015047670342028141,
                0.03552103787660599,
                0.002320617437362671,
                -0.008794677443802357,
                -0.008945249952375889,
                0.01391241978853941,
                0.003201951738446951,
                -0.029177365824580193,
                -0.10876131802797318,
                0.024129649624228477,
                -0.002728013787418604,
                0.08834578841924667,
                0.00905959028750658,
                -0.057284556329250336,
                0.025935372337698936,
                0.0764220803976059,
                0.00251251133158803,
                0.008727677166461945,
                -0.0499255508184433,
                0.015125410631299019,
                -0.0129474475979805,
                0.002806431846693158,
                -0.026642505079507828,
                -0.013320432975888252,
                -0.05877089127898216,
                -0.0208505317568779,
                0.02286406047642231,
                0.0530448742210865,
                0.00289562763646245,
                -0.020818624645471573,
                0.055081065744161606,
                -0.029122712090611458,
                0.050662487745285034,
                -0.0272784773260355,
                0.005640820134431124,
                -0.04612422361969948,
                -0.044562991708517075,
                -0.008188602514564991,
                0.02032284438610077,
                0.04696442931890488,
                -0.013786903582513332,
                -0.021501706913113594,
                0.015325712040066719,
                0.013825947418808937,
                0.009228408336639404,
                -0.06399223953485489,
                0.04139456897974014,
                0.055401600897312164,
                0.01583157479763031,
                -0.056031227111816406,
                0.10915333777666092,
                -0.060989536345005035,
                -0.030980709940195084,
                0.004252487327903509,
                -0.05431177094578743,
                0.03147486224770546,
                0.02956104651093483,
                -0.001070902799256146,
                -0.03652608022093773,
                0.035253386944532394,
                -0.027353698387742043,
                0.030775943771004677,
                0.002403151709586382,
                -0.016124024987220764,
                -9.861343278316781e-05,
                0.029863223433494568,
                0.07079356163740158,
                0.012698457576334476,
                -0.06948279589414597,
                0.047572873532772064,
                0.017770135775208473,
                -0.032922595739364624,
                0.003689894685521722,
                0.020364301279187202,
                0.0054284343495965,
                0.030840124934911728,
                0.02962139993906021,
                0.005505392327904701,
                -0.04946916550397873,
                0.02710101008415222,
                -0.0002395521296421066,
                -0.009600079618394375,
                0.0337509885430336,
                -0.06096823886036873,
                -0.04268999397754669,
                -0.007881955243647099,
                0.03177500516176224,
                -0.013787373900413513,
                -0.020296534523367882,
                0.054545383900403976,
                -0.017222244292497635,
                0.019812488928437233,
                -0.009177735075354576,
                -0.013537204824388027,
                -0.0035532130859792233,
                0.030613157898187637,
                0.0015875516692176461,
                0.022367654368281364,
                -0.043012432754039764,
                0.012538143433630466,
                0.03299413621425629,
                0.0030317881610244513,
                0.017106909304857254,
                0.029273420572280884,
                -0.02032756619155407,
                -0.0249948650598526,
                -0.03523661568760872,
                0.015046569518744946,
                0.0157333891838789,
                0.035350121557712555,
                0.05350246652960777,
                0.03628620132803917,
                0.0309466402977705,
                0.01716301403939724,
                -0.01723218522965908,
                0.07412164658308029,
                0.0028828256763517857,
                -0.034132376313209534,
                -0.05546609312295914,
                0.017465604469180107,
                -0.0312594510614872,
                0.06303707510232925,
                -0.02798309177160263,
                0.04742716625332832,
                0.06720968335866928,
                -0.015414568595588207,
                0.01002445351332426,
                -0.025903373956680298,
                0.007795844692736864,
                0.01905508153140545,
                -0.03666291758418083,
                -0.07024301588535309,
                -0.041115712374448776,
                0.00027912401128560305,
                0.02942304126918316,
                0.031122224405407906,
                -0.04142593592405319,
                -0.020922614261507988,
                0.05824878066778183,
                -0.05596339702606201,
                0.02912253886461258,
                -0.00020331217092461884,
                -0.015116330236196518,
                0.008059821091592312,
                -0.06436452269554138,
                0.06049419939517975,
                0.020549407228827477,
                0.040831197053194046,
                -0.0129957664757967,
                0.014519760385155678,
                0.030478281900286674,
                -0.03492335230112076,
                0.07405391335487366,
                -0.019364170730113983,
                -0.07695744186639786,
                -0.020462969318032265,
                0.03381796181201935,
                0.06900130957365036,
                -0.015662387013435364,
                -0.0022063027136027813,
                -0.09078563749790192,
                0.023806167766451836,
                -0.002933220472186804,
                -0.02549007162451744,
                -0.0195503830909729,
                0.00095004728063941,
                0.002595488680526614,
                0.008120938204228878,
                0.004905028268694878,
                0.0007323733298107982,
                -0.005752230994403362,
                -0.005856529343873262,
                0.030260564759373665,
                0.0184114221483469,
                -0.0022547438275069,
                -0.020384717732667923,
                -0.02197248861193657,
                0.02842022106051445,
                0.05106087028980255,
                0.0186642874032259,
                0.019347792491316795,
                -0.013409322127699852,
                -0.05946226045489311,
                0.01991795375943184,
                -0.021099358797073364,
                -0.061421386897563934,
                -0.05327574536204338,
                0.019543077796697617,
                0.019251558929681778,
                0.009872584603726864,
                0.03995981067419052,
                -0.007131141144782305,
                -0.008544272743165493,
                -0.04118051752448082,
                -0.0064465743489563465,
                -0.06525760143995285,
                0.05053960904479027,
                0.02177545055747032,
                -0.05231219157576561,
                -0.05914992094039917,
                0.04578250274062157,
                -0.013767304830253124,
                0.020287957042455673,
                -0.04662468656897545,
                -0.07392923533916473,
                0.014002715237438679,
                -0.03333941474556923,
                -0.058662451803684235,
                0.004736694041639566,
                -0.0167398601770401,
                0.0040916320867836475,
                -0.007927815429866314,
                0.04806961864233017,
                -0.060062702745199203,
                0.032627005130052567,
                -0.05677107721567154,
                -4.924506720271893e-05,
                0.04562022536993027,
                -0.020257195457816124,
                0.0023175212554633617,
                -0.05117155984044075,
                -0.04780077189207077,
                0.016352806240320206,
                -0.005024384241551161,
                0.061554767191410065,
                0.021365411579608917,
                -0.06833503395318985,
                -0.0050895363092422485,
                0.04107994958758354,
                -0.005299501586705446,
                -0.013139105401933193,
                -0.04000014439225197,
                0.026545027270913124,
                0.009479466825723648,
                0.07180901616811752,
                0.06717406213283539,
                0.04122227057814598,
                0.034865930676460266,
                -0.053163520991802216,
                -0.01308522466570139,
                0.012470125220716,
                0.026675645262002945,
                0.022331947460770607,
                0.028780687600374222,
                -0.027295751497149467,
                -9.764506830833852e-05,
                -0.086919866502285,
                0.05825909972190857,
                -0.01773441955447197,
                0.02387112006545067,
                0.013633291237056255,
                0.022323867306113243,
                -0.02588743343949318,
                -0.022472845390439034,
                0.0007521695224568248,
                -0.10014304518699646,
                -0.04774590581655502,
                -0.05842791870236397,
                -0.05952925235033035,
                -0.01299454178661108,
                0.006360325962305069,
                -0.004141299985349178,
                -0.002789246616885066,
                0.03472484275698662,
                -0.044564709067344666,
                0.028899921104311943,
                -0.02124590240418911,
                0.04656156897544861,
                0.0005294533330015838,
                0.014583291485905647,
                0.0469479039311409,
                0.03288622200489044,
                -0.032212503254413605,
                -0.029736999422311783,
                0.011178949847817421,
                0.023642396554350853,
                0.05810971185564995,
                -0.020469743758440018,
                -0.01667669042944908,
                0.022649798542261124,
                0.026801198720932007,
                -0.04905927553772926,
                -0.040754277259111404,
                0.02229076623916626,
                0.08131058514118195,
                0.030196093022823334,
                0.042425043880939484,
                0.04856850951910019,
                -0.05895746499300003,
                0.05460694432258606,
                -0.01941787451505661,
                0.003246138570830226,
                0.04347488284111023,
                0.027581144124269485,
                0.033307988196611404,
                0.03441334515810013,
                -0.02357645519077778,
                0.03082495927810669,
                -0.009323062375187874,
                -0.049114301800727844,
                0.005212800577282906,
                -0.015539462678134441,
                0.047323077917099,
                0.019791729748249054,
                -0.016730479896068573,
                -0.031094079837203026,
                0.03168318048119545,
                0.0333063118159771,
                -0.008136472664773464,
                -0.004708216059952974,
                0.0009756695362739265,
                -0.022422367706894875,
                0.004053102806210518,
                -0.024982167407870293,
                0.06360852718353271,
                -0.027308622375130653,
                -0.001287437160499394,
                0.01606404408812523,
                -0.045136600732803345,
                -0.02618606761097908,
                0.016432562842965126,
                -0.03531816974282265,
                -0.008776416070759296,
                0.031436290591955185,
                0.0033056882675737143,
                0.05557505786418915,
                0.058873940259218216,
                -0.02861674129962921,
                -0.0034828411880880594,
                -0.04035975784063339,
                -0.049204230308532715,
                -0.05179813876748085,
                -0.04027928039431572,
                0.009016579948365688,
                0.03317481651902199,
                0.004012256395071745,
                0.028412410989403725,
                -0.003624288598075509,
                -0.004244053736329079,
                -0.03151784464716911,
                0.0137547068297863,
                7.320541044464335e-05,
                -0.010475730523467064,
                0.043778643012046814,
                -0.014405098743736744,
                -4.7017085089563994e-33,
                0.00608895905315876,
                0.007994224317371845,
                -0.015821121633052826,
                -0.049065880477428436,
                -0.05209910124540329,
                -0.041442643851041794,
                0.016856065019965172,
                -0.0026964007411152124,
                -0.027392983436584473,
                0.019061869010329247,
                -0.004556433763355017,
                0.0055706677958369255,
                -0.00016800295270513743,
                0.013821213506162167,
                -0.0176566019654274,
                -0.05270923674106598,
                0.0201207734644413,
                -0.010132956318557262,
                -0.04681429639458656,
                0.013506945222616196,
                0.03715171292424202,
                0.02221277356147766,
                0.005326597951352596,
                0.008097701705992222,
                -0.0033652747515589,
                -0.02912410907447338,
                -0.004079326521605253,
                0.04929085820913315,
                -0.08722471445798874,
                -0.0635327398777008,
                -0.05250226706266403,
                0.07754120975732803,
                -0.016793007031083107,
                -0.03581956773996353,
                0.009977543726563454,
                0.07665491104125977,
                -0.05504973977804184,
                0.001454717479646206,
                -0.039053574204444885,
                0.032148104161024094,
                0.014778390526771545,
                -0.030226269736886024,
                0.04986647889018059,
                0.018773432821035385,
                -0.06709454953670502,
                -0.003061463823541999,
                -0.016371766105294228,
                -0.018513057380914688,
                0.05492298677563667,
                -0.0242101289331913,
                -0.04588641971349716,
                -0.01141057163476944,
                -0.026415294036269188,
                0.008769257925450802,
                -0.016192803159356117,
                0.03992375731468201,
                -0.024973934516310692,
                0.00890705268830061,
                -0.03318658471107483,
                -0.028183868154883385,
                -0.0016822974430397153,
                -0.033379387110471725,
                0.041050177067518234,
                -0.0442681647837162,
                -0.0030038165859878063,
                0.0011784324888139963,
                -0.046278178691864014,
                0.022069349884986877,
                -0.0644448846578598,
                0.01668999157845974,
                -0.03652084991335869,
                0.033932220190763474,
                0.022479504346847534,
                -0.08401895314455032,
                -0.009046848863363266,
                -0.009335169568657875,
                -0.04835349693894386,
                -0.02497122809290886,
                -0.004214982036501169,
                0.05240815505385399,
                -0.04409375414252281,
                0.025052526965737343,
                -0.013844210654497147,
                -0.002982095116749406,
                0.018367012962698936,
                0.04129456728696823,
                0.0284662377089262,
                -0.052140649408102036,
                -0.006323594134300947,
                0.00390653358772397,
                0.06163404509425163,
                -0.03851056471467018,
                -0.00919697992503643,
                -0.008279589004814625,
                0.05002773180603981,
                0.0642193928360939,
                0.11201813071966171,
                -0.05782919004559517,
                0.01500923465937376,
                -0.0045907325111329556,
                0.019789474084973335,
                0.049679361283779144,
                -0.05496973544359207,
                9.859206329565495e-05,
                -0.04216320067644119,
                -0.03219692036509514,
                -0.07127822190523148,
                -0.03530620038509369,
                0.017901945859193802,
                0.03839414194226265,
                0.0008023220580071211,
                -0.01984442211687565,
                0.020984331145882607,
                0.031599391251802444,
                0.02009577490389347,
                -0.04566243663430214,
                0.0010488653788343072,
                -0.019229277968406677,
                -0.06114204227924347,
                -0.04483076184988022,
                -0.01064539048820734,
                0.09400705993175507,
                -0.022372892126441002,
                0.0005332120344974101,
                0.015472719445824623,
                -0.0252706129103899,
                0.05662000551819801,
                0.023132452741265297,
                -0.0048456802032887936,
                -0.04777580872178078,
                -0.007735364604741335,
                0.03258087858557701,
                1.9931221117985842e-07,
                -0.05650686100125313,
                -0.020385976880788803,
                0.07665009796619415,
                -0.01998310722410679,
                0.028848884627223015,
                0.00529435882344842,
                -0.01872829906642437,
                0.0021466207690536976,
                -0.06614217162132263,
                -0.011660554446280003,
                -0.004127368796616793,
                -0.06138112396001816,
                0.04496600478887558,
                -0.06355825811624527,
                -0.03618790954351425,
                -0.08442281931638718,
                0.05641098693013191,
                0.015155076049268246,
                0.024767354130744934,
                -0.01962166093289852,
                0.020755602046847343,
                0.016158241778612137,
                0.011406855657696724,
                0.03173183649778366,
                -0.039442893117666245,
                0.003734994912520051,
                0.005109790712594986,
                0.03061365894973278,
                0.057712621986866,
                0.05483618006110191,
                -0.0017599681159481406,
                -0.025207990780472755,
                -0.01843838207423687,
                0.01410550158470869,
                -0.020811816677451134,
                0.03022065944969654,
                0.054530367255210876,
                -0.037624068558216095,
                0.009060293436050415,
                -0.02959662675857544,
                -0.04420003294944763,
                0.05009785667061806,
                0.06595516204833984,
                -0.03304724022746086,
                0.009362688288092613,
                -0.025853527709841728,
                0.004959723446518183,
                -0.010668554343283176,
                -0.01859036460518837,
                0.027676589787006378,
                0.0294297244399786,
                0.04425174742937088,
                0.041291601955890656,
                -0.007650332525372505,
                -0.009405466727912426,
                -0.016598373651504517,
                -0.007612971588969231,
                -0.0027168807573616505,
                -0.0020384956151247025,
                0.09072286635637283,
                -0.03319903835654259,
                -0.009398468770086765,
                0.033960022032260895,
                -0.017013341188430786,
                0.029619960114359856,
                -0.029374849051237106,
                -0.05188881605863571,
                1.152035202917469e-34,
                -0.040000904351472855,
                -0.03452802076935768,
                0.008968021720647812,
                0.03598471358418465,
                0.008494377136230469,
                0.012740496546030045,
                -0.018050402402877808,
                0.0014755700249224901,
                0.028490621596574783,
                -0.029664965346455574,
                0.0499587319791317
            ]
        },
        "Experience": {
            "name": "General Manager with extensive international energy industry experience of operations, sales, equipment and service personnel provision, leading and growing diverse and multi-cultural teams, CRM (Saleforce, Netsuite, & Exact), tender submissions, contract negotiations, budgets, project management, HSE, QA/QC systems, auditing, purchasing, asset and inventory management.\n\nI pride myself in being open-minded, self-motivated, driven as an individual and team player with a strong “hands on” ability.",
            "rep": [
                0.01303168572485447,
                0.04207778349518776,
                -0.0026461242232471704,
                0.006821427494287491,
                0.04626135528087616,
                0.03082229755818844,
                -0.06642000377178192,
                -0.01405995525419712,
                -0.01773473247885704,
                -0.013387023471295834,
                0.01959303766489029,
                0.02284686453640461,
                -0.020320819690823555,
                0.03315741568803787,
                0.07233598083257675,
                0.019583873450756073,
                0.011183825321495533,
                -0.024873608723282814,
                0.018488511443138123,
                -0.010710806585848331,
                -0.04193703830242157,
                0.043138179928064346,
                -0.01662367582321167,
                -0.003487787675112486,
                -0.022899188101291656,
                -0.006237649824470282,
                0.050091758370399475,
                0.026235369965434074,
                0.05080133676528931,
                0.06256028264760971,
                -0.007087138947099447,
                -0.034871526062488556,
                -0.021172182634472847,
                -0.021061744540929794,
                2.2976414584263694e-06,
                -0.008659318089485168,
                -0.032772716134786606,
                -0.010544994845986366,
                0.0070645445957779884,
                -0.04271639138460159,
                0.0011011463357135653,
                -0.01748330518603325,
                -0.028926804661750793,
                0.01668374240398407,
                0.010852901265025139,
                0.002545523690059781,
                0.040597159415483475,
                -0.018551072105765343,
                -0.0326644666492939,
                0.010569804348051548,
                -0.01631530188024044,
                0.0030892998911440372,
                -0.0605248361825943,
                -0.0636235773563385,
                -0.03542730584740639,
                -0.06805109232664108,
                -0.015888551250100136,
                -0.00021924135216977447,
                -0.03011634200811386,
                -0.04581629857420921,
                0.00839396845549345,
                -0.017441291362047195,
                -0.03562164306640625,
                -0.014763552695512772,
                0.06663975864648819,
                0.07423393428325653,
                0.05037364363670349,
                0.030906761065125465,
                0.04257721081376076,
                -0.027319777756929398,
                0.06366170197725296,
                -0.014603359624743462,
                -0.012314860709011555,
                0.0007425455842167139,
                0.04028764367103577,
                -0.0023489482700824738,
                0.04460044950246811,
                0.017761239781975746,
                -0.039184488356113434,
                0.021971391513943672,
                -0.06282787770032883,
                0.08011843264102936,
                -0.019434839487075806,
                -0.06917058676481247,
                -0.039022792130708694,
                0.06560525298118591,
                -0.029921721667051315,
                0.01885080151259899,
                0.03726370632648468,
                0.03632165864109993,
                -0.011728321202099323,
                -0.005738218314945698,
                -0.012230158783495426,
                0.02269822359085083,
                -0.02755318023264408,
                0.02285134792327881,
                0.054332077503204346,
                0.03514678031206131,
                0.028544999659061432,
                -0.015182578936219215,
                -0.017575355246663094,
                0.05823465436697006,
                0.06888224929571152,
                0.04831633344292641,
                -0.005840330850332975,
                0.03417070955038071,
                -0.025707632303237915,
                -0.03684505820274353,
                -0.04713338613510132,
                -0.046131715178489685,
                0.02755296230316162,
                -0.007582079153507948,
                0.02927335537970066,
                -0.005439011380076408,
                0.0018873332301154733,
                -0.0023964489810168743,
                -0.006936521269381046,
                -0.00923309475183487,
                0.020964834839105606,
                0.03052358143031597,
                0.05986827611923218,
                0.015395465306937695,
                0.0226516742259264,
                0.01336064375936985,
                -0.014081542380154133,
                -0.026073670014739037,
                -0.06444832682609558,
                0.025036241859197617,
                0.032716065645217896,
                0.006245323456823826,
                -0.03744526952505112,
                -0.023659110069274902,
                -0.011074787005782127,
                -0.029929596930742264,
                0.01930299401283264,
                0.059745997190475464,
                -0.03423673287034035,
                -0.00841685850173235,
                -0.029951168224215508,
                -0.0037904554046690464,
                0.005228549707680941,
                0.01723671332001686,
                -0.020050005987286568,
                0.061224110424518585,
                -0.005857265554368496,
                -0.0538506917655468,
                -0.004320133477449417,
                0.0336000919342041,
                0.006119555328041315,
                -0.018882988020777702,
                0.05538167804479599,
                -0.046406906098127365,
                0.007283654063940048,
                -0.007739086169749498,
                0.05489499494433403,
                0.03170090913772583,
                -0.0015365969156846404,
                0.0005553588853217661,
                -0.01344528328627348,
                0.042205214500427246,
                0.03545444458723068,
                -0.0521247424185276,
                0.01193296629935503,
                0.03222126513719559,
                -0.043146125972270966,
                0.01039030496031046,
                0.11218573898077011,
                0.009751230478286743,
                -0.03250954672694206,
                0.011615891940891743,
                0.020672744140028954,
                -0.04196712747216225,
                -0.007671736646443605,
                0.02557881735265255,
                0.04728461429476738,
                -0.04111820459365845,
                -0.02712750993669033,
                -0.01287724357098341,
                0.01393124833703041,
                -0.024668175727128983,
                -0.04693009331822395,
                -0.15293358266353607,
                0.02963663637638092,
                -0.004325696267187595,
                0.01921725831925869,
                0.036391519010066986,
                0.005810948554426432,
                0.07784788310527802,
                -0.00336448079906404,
                0.04850935935974121,
                0.013571803458034992,
                0.010104892775416374,
                -0.05801593139767647,
                0.07153023034334183,
                -0.011637819930911064,
                0.008937926031649113,
                -0.05715985596179962,
                -0.009655274450778961,
                -0.037073783576488495,
                -0.0329553484916687,
                -0.009217759594321251,
                -0.009491633623838425,
                -0.07722829282283783,
                0.02951107546687126,
                0.019919905811548233,
                -0.008450078777968884,
                -0.05029914900660515,
                -0.04600522667169571,
                -0.04241713136434555,
                0.026491688564419746,
                0.07136252522468567,
                0.03292744606733322,
                0.015230358578264713,
                0.023663494735956192,
                -0.006501494906842709,
                -0.014334644190967083,
                0.04672868922352791,
                0.013640651479363441,
                0.0009643527446314692,
                0.06555140763521194,
                -0.013010767288506031,
                -0.009707269258797169,
                0.02386344037950039,
                -0.013520845212042332,
                0.04999281466007233,
                -0.10856498777866364,
                0.014042028225958347,
                -0.015122677199542522,
                -0.021891944110393524,
                0.017199181020259857,
                -0.011496901512145996,
                -0.006517872679978609,
                -0.012219374999403954,
                -0.01902788132429123,
                -0.042853523045778275,
                0.03624093905091286,
                -0.009663659147918224,
                0.09498254209756851,
                -0.005255427677184343,
                0.028991732746362686,
                -0.022888444364070892,
                -0.023546287789940834,
                -0.03886321559548378,
                0.015871133655309677,
                -0.0638652890920639,
                0.017669275403022766,
                0.0035285153426229954,
                -0.029261000454425812,
                -0.041043415665626526,
                0.013863081112504005,
                0.002910716226324439,
                0.01968156173825264,
                0.009761725552380085,
                0.04804067313671112,
                -0.02042161114513874,
                0.02986077405512333,
                -0.024218756705522537,
                0.018765736371278763,
                -0.13251110911369324,
                0.015968719497323036,
                0.004031005781143904,
                -0.03544076159596443,
                -0.041432127356529236,
                -0.024410925805568695,
                0.0037343427538871765,
                -0.004370070528239012,
                0.06829685717821121,
                -0.05495934560894966,
                -0.018024910241365433,
                0.0016061428468674421,
                0.04356103017926216,
                0.058986518532037735,
                0.0055567678064107895,
                -0.0420711524784565,
                -0.04773513227701187,
                0.0010487131075933576,
                -0.017621301114559174,
                0.06444159150123596,
                -0.04470109939575195,
                0.011865652166306973,
                0.027797479182481766,
                0.0035974769853055477,
                -0.04535927250981331,
                0.02325962483882904,
                0.008010394871234894,
                0.025493191555142403,
                0.04696587845683098,
                0.011621887795627117,
                -0.03451532498002052,
                0.0387578085064888,
                -0.030451130121946335,
                -0.0016998235369101167,
                0.004185132682323456,
                -0.06209040805697441,
                -0.04443451762199402,
                0.009926068596541882,
                0.013588341884315014,
                0.013042198494076729,
                -0.011350458487868309,
                0.058740779757499695,
                -0.05256606265902519,
                -0.0604400597512722,
                0.06901409476995468,
                -0.02511676214635372,
                0.007751890458166599,
                -0.004724727012217045,
                0.027212580665946007,
                0.04806259647011757,
                -0.02709171734750271,
                0.004285506438463926,
                0.040516093373298645,
                0.0143224336206913,
                0.04652601480484009,
                0.0017021306557580829,
                0.02677794359624386,
                -0.018099183216691017,
                -0.031109755858778954,
                0.0032264261972159147,
                0.014624365605413914,
                0.07410146296024323,
                0.05781050771474838,
                0.06079545244574547,
                0.00014128218754194677,
                -0.047848764806985855,
                -0.015223231166601181,
                0.010296019725501537,
                -0.07075835019350052,
                -0.013070963323116302,
                0.013497358188033104,
                -0.11847527325153351,
                2.6260491722496226e-05,
                0.010995987802743912,
                -0.05691530928015709,
                -0.03304511681199074,
                -0.02814972959458828,
                -0.04526796191930771,
                0.001762594562023878,
                -0.03612843155860901,
                -0.039713598787784576,
                -0.022284628823399544,
                -0.026090087369084358,
                0.0038148919120430946,
                -0.02213919907808304,
                0.03524642810225487,
                -0.013058328069746494,
                -0.00874803401529789,
                0.08912757784128189,
                0.01815510354936123,
                -0.08758178353309631,
                -0.009154176339507103,
                0.022325241938233376,
                0.04479407146573067,
                -0.0023205108009278774,
                -0.003620111383497715,
                -0.0009521940955892205,
                0.0004468885890673846,
                -0.007735370192676783,
                0.05906115844845772,
                -0.005687291733920574,
                0.03550243005156517,
                -0.043603330850601196,
                -0.0038104660343378782,
                -0.012114576995372772,
                0.03470832109451294,
                0.00429629348218441,
                -0.03356162831187248,
                0.03022296354174614,
                -0.04522929713129997,
                -0.003772262018173933,
                -0.021884022280573845,
                -0.029054058715701103,
                0.0029435819014906883,
                -0.04607665166258812,
                0.0022245028521865606,
                -0.02790813148021698,
                0.02287561632692814,
                0.017773468047380447,
                0.03906035050749779,
                0.000503175426274538,
                -0.008737262338399887,
                0.079057976603508,
                -0.015914002433419228,
                0.06480662524700165,
                0.012992809526622295,
                0.009609587490558624,
                -0.016992418095469475,
                -0.01719166710972786,
                -0.0130685456097126,
                -0.03145058453083038,
                -0.03701057657599449,
                0.0041771153919398785,
                0.027336332947015762,
                0.013380573131144047,
                0.03218081220984459,
                -0.00143145932815969,
                -0.01779228448867798,
                -0.07273037731647491,
                -0.032958414405584335,
                -0.013387623243033886,
                -0.021461041644215584,
                0.04275023937225342,
                0.024584155529737473,
                -0.05236651003360748,
                0.027405275031924248,
                0.019908597692847252,
                -0.079899862408638,
                0.09944458305835724,
                -0.015743663534522057,
                0.002962679835036397,
                0.021731609478592873,
                -0.005854249466210604,
                -0.011876893229782581,
                -0.00018767571600619704,
                0.04530017077922821,
                -0.028036989271640778,
                -0.061786945909261703,
                -0.0003076020220760256,
                0.02329353429377079,
                0.03405122458934784,
                -0.06854893267154694,
                0.07653874158859253,
                0.03870435059070587,
                0.05881521850824356,
                -0.0015473029343411326,
                0.02604544907808304,
                -0.00582529790699482,
                0.055459413677453995,
                0.036313679069280624,
                0.03276834264397621,
                -0.05714626610279083,
                -0.026422573253512383,
                0.009848578833043575,
                0.02014629915356636,
                0.05830890312790871,
                -0.009130720980465412,
                -0.004049285314977169,
                -0.008815879933536053,
                0.037327107042074203,
                0.025904079899191856,
                0.014191566035151482,
                -0.028018388897180557,
                0.04341732710599899,
                -0.01361906062811613,
                0.03296986222267151,
                -0.04518809914588928,
                -0.026831775903701782,
                -0.006559642963111401,
                0.06604907661676407,
                -0.09703309088945389,
                -0.030260486528277397,
                0.06630680710077286,
                -0.037710633128881454,
                -0.047511883080005646,
                -0.00012504315236583352,
                0.02738400362432003,
                -0.029336964711546898,
                -0.031423576176166534,
                -0.053274307399988174,
                -9.562851482769474e-05,
                -0.06862148642539978,
                0.02473653294146061,
                0.031771399080753326,
                -0.01866861805319786,
                0.05842360109090805,
                0.049202144145965576,
                0.01323654130101204,
                0.009913071990013123,
                -0.04554133862257004,
                0.04907705634832382,
                -0.07759647816419601,
                0.05876624584197998,
                -0.0627165213227272,
                -0.0545196570456028,
                -0.036053311079740524,
                0.0014500231482088566,
                -0.016365353018045425,
                0.033819086849689484,
                0.05282796919345856,
                -0.005961970426142216,
                0.024410204961895943,
                -0.03035729192197323,
                -0.05698339268565178,
                -0.02103550359606743,
                -0.02593092992901802,
                -0.021166758611798286,
                -0.019599471241235733,
                0.018029360100626945,
                0.032973404973745346,
                -0.03341783583164215,
                -0.042926449328660965,
                0.05429457873106003,
                -0.058240655809640884,
                0.00703214667737484,
                0.009782189503312111,
                0.01244245283305645,
                0.05393659323453903,
                -0.030752496793866158,
                -0.012564592063426971,
                -0.044779639691114426,
                -0.02225833386182785,
                -0.04465925693511963,
                0.0033729267306625843,
                -0.0018329231534153223,
                -0.05438670516014099,
                0.023072047159075737,
                -0.03886491060256958,
                0.007906761951744556,
                0.036470938473939896,
                -0.031230200082063675,
                0.02536521852016449,
                -0.024783583357930183,
                0.014115535654127598,
                -0.019969407469034195,
                -0.0712173581123352,
                0.02046661265194416,
                0.0036236064042896032,
                -0.010164599865674973,
                -0.012803393416106701,
                0.03468737378716469,
                -0.016370166093111038,
                0.025815611705183983,
                -0.010506429709494114,
                -0.01552074495702982,
                0.013090412132441998,
                0.024714717641472816,
                -0.01786700449883938,
                0.016589559614658356,
                0.0010313293896615505,
                0.02454153634607792,
                0.046119824051856995,
                0.022681452333927155,
                -0.03057924471795559,
                -0.07680114358663559,
                0.03572189807891846,
                -0.01538617629557848,
                -0.00713298749178648,
                0.022501759231090546,
                0.004302260931581259,
                0.0253741554915905,
                -0.015139022842049599,
                -0.058897584676742554,
                0.048526111990213394,
                0.04939854145050049,
                -0.0007469691918231547,
                -0.04059447720646858,
                0.01822599768638611,
                -0.0057613239623606205,
                0.061192408204078674,
                0.03766520693898201,
                -0.0031393361277878284,
                0.007522189989686012,
                -0.050766944885253906,
                -0.054741133004426956,
                0.05298786982893944,
                -0.060382794588804245,
                -6.483511090989315e-33,
                -0.017054568976163864,
                -0.03427012264728546,
                0.01569371670484543,
                0.022000763565301895,
                -0.04088510572910309,
                -0.017445750534534454,
                0.01550671923905611,
                -0.045421894639730453,
                -0.025889690965414047,
                -0.03190706670284271,
                -0.037059884518384933,
                0.02071361243724823,
                0.020085595548152924,
                0.002300842432305217,
                -0.002442237688228488,
                0.06636949628591537,
                0.03821372985839844,
                -0.01607547700405121,
                -0.020998308435082436,
                0.024126458913087845,
                -0.021126192063093185,
                0.020580193027853966,
                0.03140072897076607,
                -0.0003822179278358817,
                0.0641075000166893,
                0.012243283912539482,
                -0.03243977949023247,
                0.04030246660113335,
                0.007686818018555641,
                -0.029171012341976166,
                -0.031006913632154465,
                -0.005289093591272831,
                -0.015316233038902283,
                0.011347947642207146,
                0.013644006103277206,
                0.0018713520839810371,
                -0.029071729630231857,
                0.014290858060121536,
                -0.005125428084284067,
                -0.027993960306048393,
                -0.013418793678283691,
                -0.043163225054740906,
                -0.0003468052891548723,
                0.003480598097667098,
                0.010700573213398457,
                -0.06841521710157394,
                0.015508005395531654,
                -0.006060927640646696,
                0.04513068124651909,
                -0.006853307597339153,
                -0.01927688904106617,
                -0.022649254649877548,
                -0.04240180924534798,
                0.09000645577907562,
                -0.058618806302547455,
                0.01856379210948944,
                -0.0037875298876315355,
                0.03437608852982521,
                -0.034166935831308365,
                -0.014609561301767826,
                0.004065698012709618,
                0.02485080435872078,
                0.03520851209759712,
                0.03601163253188133,
                0.022465581074357033,
                0.04315963014960289,
                -0.013433834537863731,
                0.10422273725271225,
                -0.02917482517659664,
                -0.007167370058596134,
                -0.0237160362303257,
                0.05007227882742882,
                -0.06303738057613373,
                0.011634712107479572,
                -0.055863600224256516,
                -0.01704300381243229,
                -0.03470008820295334,
                -0.008322964422404766,
                0.02465273067355156,
                0.0027971977833658457,
                -0.05392390117049217,
                0.006513551343232393,
                0.05639728531241417,
                0.005256390664726496,
                0.06031378358602524,
                -0.03301677107810974,
                -0.02465645596385002,
                0.004285415634512901,
                0.02155607007443905,
                0.012066086754202843,
                -0.03227256238460541,
                0.008565611205995083,
                -0.019489699974656105,
                0.025888752192258835,
                0.07452622801065445,
                -0.008549746125936508,
                0.006486044730991125,
                -0.012451794929802418,
                0.005322518292814493,
                -0.01846746727824211,
                0.0020722290500998497,
                0.07192033529281616,
                -0.024001041427254677,
                0.0332971066236496,
                0.026976313441991806,
                0.024455560371279716,
                -0.07173588126897812,
                0.007574764080345631,
                -0.009783229790627956,
                0.010104765184223652,
                0.005257634911686182,
                -0.02300170622766018,
                0.006154090166091919,
                -0.027763454243540764,
                -0.05886822193861008,
                -0.029862944036722183,
                0.0659899190068245,
                -0.018253657966852188,
                -0.00887684989720583,
                0.029475130140781403,
                0.0612594373524189,
                0.008202391676604748,
                -0.0009070145897567272,
                0.016280189156532288,
                -0.006323703099042177,
                -0.008105636574327946,
                0.0280002448707819,
                -0.03796028345823288,
                -0.005422054789960384,
                0.025390898808836937,
                -0.017618020996451378,
                0.0002076222881441936,
                2.930537732481753e-07,
                0.03094577230513096,
                -0.0476706363260746,
                -0.010413833893835545,
                0.03766396641731262,
                0.016594115644693375,
                -0.028800776228308678,
                -0.02468990534543991,
                -0.021885523572564125,
                -0.014771916903555393,
                0.01654738187789917,
                0.04048057273030281,
                -0.0228099524974823,
                -0.03661534562706947,
                -0.030704094097018242,
                0.00799547415226698,
                -0.04931071773171425,
                -0.014041686430573463,
                -0.0043283323757350445,
                -0.008561979979276657,
                -0.046617940068244934,
                0.045012567192316055,
                -0.034240540117025375,
                0.03646788001060486,
                0.005282585974782705,
                0.03264554217457771,
                -0.0462329164147377,
                0.027410825714468956,
                -0.09058120846748352,
                0.037739627063274384,
                0.03305666148662567,
                0.030230700969696045,
                -0.021474845707416534,
                -0.026950405910611153,
                -0.014700978063046932,
                0.015719449147582054,
                -0.04044385626912117,
                0.015442918054759502,
                0.06222795695066452,
                0.03164730593562126,
                -0.01550102699548006,
                0.00015501519374083728,
                -0.018259098753333092,
                0.062266044318675995,
                -0.028341496363282204,
                -0.019621701911091805,
                0.0023753931745886803,
                -0.02134191058576107,
                0.05235419422388077,
                -0.07470250874757767,
                -0.029547739773988724,
                -0.01714976131916046,
                0.025760527700185776,
                -0.0198920127004385,
                -0.016596980392932892,
                0.016155410557985306,
                0.008822716772556305,
                -0.044703610241413116,
                0.08648208528757095,
                0.05447695031762123,
                0.03204847127199173,
                -0.03745492920279503,
                -0.02299879491329193,
                -0.02073705941438675,
                -0.019543442875146866,
                0.054499853402376175,
                0.05858830362558365,
                -0.002701620338484645,
                2.7171278708656106e-34,
                -0.044988468289375305,
                0.018710907548666,
                -0.04151467978954315,
                0.021418508142232895,
                0.06223604455590248,
                -0.030075713992118835,
                -0.06974413245916367,
                0.0178984347730875,
                0.0033960749860852957,
                -0.0313565693795681,
                -0.030855411663651466
            ]
        },
        "Freelance": "Open to both",
        "Id": "3e509c76-bfb8-41ed-a826-65b4f89381c5",
        "Industry": {
            "name": "\tManagement",
            "rep": [
                0.059569697827100754,
                -0.004008868243545294,
                -0.01697634533047676,
                -0.018986662849783897,
                0.012872766703367233,
                0.03992195054888725,
                -0.016244608908891678,
                -0.002350707771256566,
                -0.04128986969590187,
                0.012974303215742111,
                0.088386669754982,
                0.041523002088069916,
                0.0002760113275144249,
                0.021300634369254112,
                -0.004114141687750816,
                -0.028619373217225075,
                0.007171860430389643,
                -0.003182376967743039,
                -0.06678058207035065,
                -0.011500043794512749,
                -0.03578713908791542,
                0.03678726777434349,
                0.02395286224782467,
                -0.02278679423034191,
                0.003570313099771738,
                -0.031188679859042168,
                0.02777797356247902,
                0.0014381634537130594,
                0.02772434987127781,
                0.012693927623331547,
                0.016711222007870674,
                -0.01591316983103752,
                -0.012557809241116047,
                -0.013770238496363163,
                1.9093486116616987e-06,
                0.004528159741312265,
                0.0001777980214683339,
                -0.02930116094648838,
                -0.01758532226085663,
                0.012870769947767258,
                0.045388441532850266,
                -0.05257972329854965,
                -0.02366478182375431,
                0.04859984293580055,
                -0.0027346170973032713,
                0.021848302334547043,
                0.05168849974870682,
                0.023738602176308632,
                -0.016893470659852028,
                -0.001973482081666589,
                -0.006215130444616079,
                0.00643185805529356,
                -0.10997667908668518,
                -0.03145360201597214,
                -0.09533829241991043,
                -0.06759937107563019,
                0.024454837664961815,
                0.011124820448458195,
                0.03470772132277489,
                -0.032701823860406876,
                -0.017658688127994537,
                -0.02646995708346367,
                -0.009164893999695778,
                0.006440243683755398,
                0.051691412925720215,
                0.02897101640701294,
                0.12560346722602844,
                -0.023155171424150467,
                0.0026558947283774614,
                -0.018413560464978218,
                0.0200436282902956,
                0.028102068230509758,
                0.015568489208817482,
                0.03950102999806404,
                0.014749660156667233,
                0.0010999510996043682,
                0.03432701528072357,
                0.0162828229367733,
                0.005949005018919706,
                -0.01419590413570404,
                -0.04456762969493866,
                0.07127881795167923,
                0.0034550614655017853,
                0.032674334943294525,
                -0.0012709200382232666,
                0.019523482769727707,
                -0.03552349656820297,
                -0.014414547011256218,
                -0.018999921157956123,
                0.040466126054525375,
                0.020699458196759224,
                -0.03170335292816162,
                0.017954757437109947,
                0.07069974392652512,
                -0.005312841851264238,
                -0.02153872884809971,
                0.007442470174282789,
                0.01615338772535324,
                0.01999199017882347,
                -0.03969686105847359,
                -0.0948687270283699,
                0.005047311075031757,
                0.01885828748345375,
                0.046001069247722626,
                0.04581153020262718,
                -0.014473211951553822,
                -0.056364692747592926,
                -0.028671005740761757,
                -0.042067062109708786,
                0.03780563920736313,
                -0.031406622380018234,
                0.009611004963517189,
                -0.005556575953960419,
                0.01908646523952484,
                0.04690568894147873,
                -0.005472405347973108,
                -0.03396200016140938,
                0.031631313264369965,
                0.005142060108482838,
                0.010042293928563595,
                -0.05596313998103142,
                0.001256324234418571,
                -0.02786773443222046,
                0.013363840989768505,
                -0.008014105260372162,
                0.019383620470762253,
                -0.09019888192415237,
                0.011169618926942348,
                0.020390843972563744,
                0.04734662175178528,
                0.01945197768509388,
                -0.030780192464590073,
                0.0044867810793221,
                -0.010173340328037739,
                0.0207824744284153,
                0.02109113335609436,
                0.00046156044118106365,
                0.017855800688266754,
                -0.10836325585842133,
                -0.030776899307966232,
                0.003446808783337474,
                -0.031540025025606155,
                -0.05626123026013374,
                0.010504133999347687,
                -0.027789166197180748,
                -0.027696972712874413,
                -0.022201042622327805,
                0.034569013863801956,
                0.002862871391698718,
                -0.008815434761345387,
                -0.017725853249430656,
                -0.0678626149892807,
                0.003205027896910906,
                0.0020256657153367996,
                0.10581106692552567,
                0.07920239865779877,
                0.06326236575841904,
                -0.02847852185368538,
                -0.03122607059776783,
                0.03269566223025322,
                0.008293849416077137,
                0.01007543783634901,
                0.06376233696937561,
                0.00905775185674429,
                -0.010779142379760742,
                0.014333554543554783,
                0.1046924963593483,
                -0.004188301973044872,
                -0.1196109727025032,
                -0.007694007828831673,
                -0.03709309175610542,
                -0.0039725517854094505,
                -0.03827745094895363,
                0.08099711686372757,
                -0.01416151411831379,
                -0.048497214913368225,
                0.0155394421890378,
                -0.027248565107584,
                -0.043140020221471786,
                -0.04390083998441696,
                -0.07888047397136688,
                -0.13550321757793427,
                -0.0001559378724778071,
                0.03806527704000473,
                0.0228507611900568,
                0.04202289879322052,
                0.02055293135344982,
                0.03172708675265312,
                -0.07539110630750656,
                -0.015470976941287518,
                0.005006413906812668,
                -0.011452801525592804,
                -0.07229528576135635,
                0.028692573308944702,
                0.0191181767731905,
                -0.015029150061309338,
                0.008410503156483173,
                -0.045186251401901245,
                -0.017346126958727837,
                0.005360636860132217,
                -0.017683129757642746,
                -0.025390377268195152,
                0.002006103051826358,
                0.07215479016304016,
                -0.050526298582553864,
                -0.015468931756913662,
                0.025622952729463577,
                -0.018987391144037247,
                -0.04191431775689125,
                -0.001488138921558857,
                0.04022984579205513,
                0.017194107174873352,
                -0.036188263446092606,
                0.02663172036409378,
                -0.0034158993512392044,
                0.02533021569252014,
                0.025712348520755768,
                0.022860415279865265,
                -0.05955660715699196,
                0.07673632353544235,
                -0.02217474952340126,
                0.016184071078896523,
                0.01739191822707653,
                -0.02683427184820175,
                -0.014064368791878223,
                -0.044931065291166306,
                0.0031557537149637938,
                -0.007836452685296535,
                -0.01582520082592964,
                -0.00864299014210701,
                0.007033555768430233,
                0.028719626367092133,
                0.0023033854085952044,
                0.022153262048959732,
                -0.001944240415468812,
                0.01626819372177124,
                0.032061513513326645,
                0.04129691794514656,
                -0.002647442976012826,
                -0.010655115358531475,
                -0.0460195429623127,
                -0.04947584867477417,
                0.015986129641532898,
                -0.03145270794630051,
                0.07141663879156113,
                0.023207062855362892,
                0.020202476531267166,
                0.004476079251617193,
                -0.020456463098526,
                0.017602214589715004,
                -0.020186014473438263,
                -0.02607719600200653,
                0.04113764315843582,
                -0.02493869885802269,
                -0.0005709126126021147,
                0.024798162281513214,
                -0.0454275980591774,
                0.057468850165605545,
                -0.039499226957559586,
                -0.015073596499860287,
                0.020813990384340286,
                -0.03963465243577957,
                0.007292266935110092,
                -0.013314839452505112,
                0.028842980042099953,
                -0.035818569362163544,
                0.03157733380794525,
                0.0009121732437051833,
                -0.026616467162966728,
                -0.01088327169418335,
                0.01480508130043745,
                0.007616243325173855,
                0.03482469543814659,
                -0.004614037927240133,
                -0.007660401985049248,
                -0.019454050809144974,
                -0.048864807933568954,
                0.030347468331456184,
                -0.02244238369166851,
                -0.0013395891292020679,
                0.04749612882733345,
                -0.0033028433099389076,
                -0.027337834239006042,
                0.007031694520264864,
                0.009110593236982822,
                -0.011029869318008423,
                0.017322853207588196,
                -0.03932785615324974,
                -0.04500006139278412,
                -0.00796195212751627,
                -0.07315794378519058,
                -0.039381418377161026,
                -0.0030574139673262835,
                -0.013787380419671535,
                -0.0008217597496695817,
                -0.001957572065293789,
                0.02131594531238079,
                -0.006507393904030323,
                -0.017843807116150856,
                0.03771020472049713,
                -0.02971058152616024,
                -0.007908298633992672,
                0.060335736721754074,
                -0.03438086807727814,
                -0.019873714074492455,
                -0.011135553941130638,
                0.02403024211525917,
                -0.0024507250636816025,
                -0.029612939804792404,
                0.0027346517890691757,
                0.02546994760632515,
                0.02328089438378811,
                0.01960604637861252,
                0.0762920081615448,
                -0.004686575848609209,
                0.0033485449384897947,
                -0.027647247537970543,
                0.006482974160462618,
                -0.00661707716062665,
                0.1535593867301941,
                0.04555342346429825,
                0.02767542377114296,
                -0.029286831617355347,
                0.01340148039162159,
                0.03787295147776604,
                0.01752801239490509,
                -0.039466675370931625,
                -0.00044969990267418325,
                0.001950963749550283,
                -0.06612714380025864,
                -0.01499067060649395,
                0.04379139095544815,
                -0.06392034143209457,
                0.01019679568707943,
                -0.0564519502222538,
                0.035968150943517685,
                -0.030239896848797798,
                -0.10054469853639603,
                0.0014999255072325468,
                0.008649388328194618,
                0.009791336953639984,
                0.007713738363236189,
                -0.005691803526133299,
                0.0029552127234637737,
                -0.036931298673152924,
                -0.014429613016545773,
                0.012515687383711338,
                0.06276151537895203,
                -0.06120904162526131,
                -0.03064863756299019,
                0.031114915385842323,
                0.004836575593799353,
                0.0019232510821893811,
                -0.00978165864944458,
                -0.027224162593483925,
                -0.029634637758135796,
                -0.05873041972517967,
                0.03881959617137909,
                0.009239433333277702,
                -0.06042200326919556,
                -0.035559285432100296,
                0.018398532643914223,
                0.017752008512616158,
                -0.03015146404504776,
                0.015654180198907852,
                -0.021831419318914413,
                0.029873477295041084,
                0.038640934973955154,
                -0.017546609044075012,
                0.02332366816699505,
                -0.012787684798240662,
                0.07001227885484695,
                -0.03062944859266281,
                0.03637378290295601,
                0.007681789807975292,
                0.0316632017493248,
                0.02569924294948578,
                -0.017861424013972282,
                -0.045918501913547516,
                -0.010256027802824974,
                0.04578256607055664,
                -0.020410198718309402,
                0.05175404995679855,
                0.0050203255377709866,
                0.024257298558950424,
                0.010445855557918549,
                -0.010296866297721863,
                0.02829713560640812,
                -0.0005397311178967357,
                -0.0044817253947257996,
                -0.0019554949831217527,
                -0.008103683590888977,
                -0.025020645931363106,
                0.07322954386472702,
                -0.004376416094601154,
                -0.02624286711215973,
                0.01587015762925148,
                -0.010684595443308353,
                0.015606040135025978,
                -0.006231557112187147,
                0.03648374229669571,
                0.02324296347796917,
                0.017076166346669197,
                0.05767199024558067,
                -0.05543690174818039,
                -0.08496109396219254,
                0.13017021119594574,
                0.018470073118805885,
                -0.018229622393846512,
                -0.025442160665988922,
                -0.015848008915781975,
                -0.0319579541683197,
                0.009149440564215183,
                -0.003803602885454893,
                0.020696746185421944,
                -0.07117345184087753,
                -0.01326989196240902,
                0.013190989382565022,
                0.016413334757089615,
                -0.03152430057525635,
                0.02621549181640148,
                -0.00386861152946949,
                0.03158259019255638,
                0.004839197266846895,
                0.003095255931839347,
                -0.00212161336094141,
                0.05600965768098831,
                0.028662310913205147,
                0.04254309460520744,
                -0.02425227127969265,
                -0.024193650111556053,
                0.004333068151026964,
                -0.017583422362804413,
                0.026925427839159966,
                0.04042024910449982,
                -0.005019590724259615,
                -0.010102944448590279,
                -0.012001460418105125,
                0.03455689921975136,
                -0.013974147848784924,
                0.003848147578537464,
                0.10017122328281403,
                0.006148119922727346,
                0.02554951421916485,
                -0.050267696380615234,
                0.013624782674014568,
                -0.02831626869738102,
                0.010644192807376385,
                -0.10120940208435059,
                0.02751666121184826,
                0.03807971253991127,
                -0.038716547191143036,
                -0.007830653339624405,
                -0.06188153475522995,
                0.0923447385430336,
                -0.012176412157714367,
                -0.06966232508420944,
                0.02254375070333481,
                0.006494103465229273,
                -0.033985480666160583,
                0.050689954310655594,
                0.03661993145942688,
                -0.0629606619477272,
                0.0012930160155519843,
                0.0324859544634819,
                -0.027437014505267143,
                -0.021827269345521927,
                -0.053340647369623184,
                0.0508686862885952,
                -0.057451579719781876,
                0.024352530017495155,
                -0.04263259470462799,
                -0.026310941204428673,
                -0.0031425519846379757,
                0.046994488686323166,
                0.03323926776647568,
                -0.00970127247273922,
                -0.034874118864536285,
                0.06766236573457718,
                0.012721588835120201,
                -0.03732563927769661,
                -0.03314608335494995,
                -0.016857456415891647,
                -0.05454383045434952,
                -0.004108794499188662,
                -0.017524007707834244,
                0.02918420545756817,
                -0.018778812140226364,
                0.021525660529732704,
                -0.015989383682608604,
                0.03036915697157383,
                -0.012301863171160221,
                -0.0056650335900485516,
                -0.0509285144507885,
                -0.03255702555179596,
                -0.02941647171974182,
                -0.008588844910264015,
                0.029886675998568535,
                -0.02894757129251957,
                -0.04623983055353165,
                -0.03339279443025589,
                0.018510421738028526,
                -0.03202284500002861,
                -0.015537622384727001,
                0.009885415434837341,
                0.007257671095430851,
                -0.008696138858795166,
                0.05378992483019829,
                0.011389108374714851,
                0.01923494040966034,
                0.0021719469223171473,
                -0.007341968361288309,
                0.00840423908084631,
                -0.04730051010847092,
                0.05414104461669922,
                0.009458335116505623,
                -0.039902087301015854,
                -0.07110320031642914,
                -0.012847039848566055,
                -0.006111902184784412,
                0.01753583922982216,
                -0.0071990229189395905,
                -0.0025529316626489162,
                0.006796445231884718,
                0.06644943356513977,
                0.005231644958257675,
                0.02091318555176258,
                -0.020165016874670982,
                0.0028317829128354788,
                0.023482542484998703,
                0.017673902213573456,
                -0.05520080029964447,
                -0.024941081181168556,
                0.06914549320936203,
                -0.034533463418483734,
                0.12797954678535461,
                0.04579056054353714,
                0.08174826949834824,
                0.006775449030101299,
                0.01906740851700306,
                -0.06458283960819244,
                0.050573378801345825,
                0.023284053429961205,
                0.003971545025706291,
                0.012491993606090546,
                -0.025655362755060196,
                -0.034795794636011124,
                0.019450757652521133,
                0.04977882280945778,
                0.02911253646016121,
                -0.047562241554260254,
                0.006393870338797569,
                -0.05521625652909279,
                0.022792665287852287,
                -0.02108343131840229,
                -7.30809980694001e-33,
                -0.0355633869767189,
                -0.030446982011198997,
                0.0567307285964489,
                -0.0008941676351241767,
                -0.03069261647760868,
                -0.024166997522115707,
                0.03649741783738136,
                0.021963974460959435,
                -0.05186678469181061,
                -0.03521871939301491,
                -0.027562379837036133,
                -0.004194524604827166,
                0.02362014167010784,
                0.0030621839687228203,
                0.05145017430186272,
                -0.032105933874845505,
                -0.02120768092572689,
                0.008433033712208271,
                -0.015401416458189487,
                -0.025216493755578995,
                -0.003136686747893691,
                0.03655921295285225,
                0.03033648431301117,
                0.06913267076015472,
                -0.02372557856142521,
                -0.011547298170626163,
                -0.02454240620136261,
                0.001865024445578456,
                -0.01002348493784666,
                -0.0038255956023931503,
                0.0001769384543877095,
                -0.042540043592453,
                -0.012018776498734951,
                -0.06349486857652664,
                0.0010236199013888836,
                0.08590295910835266,
                -0.002075315685942769,
                -0.006150422617793083,
                0.009582151658833027,
                -0.009056062437593937,
                -0.016632437705993652,
                -0.039146702736616135,
                0.0012101707980036736,
                -0.017239462584257126,
                0.034919373691082,
                -0.054910819977521896,
                0.015845850110054016,
                -0.03383320942521095,
                -0.003514468902722001,
                0.010455364361405373,
                -0.018578097224235535,
                0.02129111997783184,
                -0.038192734122276306,
                0.037642449140548706,
                0.016406750306487083,
                0.009306754916906357,
                -0.017515894025564194,
                0.03535854443907738,
                -0.041288379579782486,
                -0.02338940091431141,
                -0.002578308107331395,
                0.03555862978100777,
                -0.025067327544093132,
                0.026154564693570137,
                -0.01098611205816269,
                0.005991820711642504,
                -0.1213226392865181,
                0.03651990368962288,
                0.015424855053424835,
                0.03637720271945,
                -0.007286461535841227,
                0.10435816645622253,
                -0.019366618245840073,
                -0.05905372276902199,
                -0.06502708792686462,
                -0.035555511713027954,
                0.021817998960614204,
                -0.02970520406961441,
                0.031034374609589577,
                -0.030996102839708328,
                -0.04623298719525337,
                0.04786175489425659,
                0.013576151803135872,
                -0.019858330488204956,
                0.022751998156309128,
                -0.04428795725107193,
                -0.01931493543088436,
                0.038242701441049576,
                -0.005449048243463039,
                0.021327205002307892,
                -0.0313778854906559,
                0.027416089549660683,
                0.0052566928789019585,
                0.031068451702594757,
                -0.01013377495110035,
                -0.029462525621056557,
                0.04323603957891464,
                0.0198359414935112,
                -0.010839858092367649,
                -0.03526737540960312,
                -0.06128397583961487,
                0.0016833491390570998,
                -0.09117043763399124,
                0.030521659180521965,
                0.017260123044252396,
                0.019868075847625732,
                -0.017612280324101448,
                -0.0538937970995903,
                -0.020530683919787407,
                -0.004253132734447718,
                0.030855653807520866,
                0.016899263486266136,
                -0.0037505768705159426,
                -0.07246638089418411,
                -0.027412382885813713,
                -0.008659184910356998,
                0.03300949186086655,
                -0.044447075575590134,
                0.024255774915218353,
                -0.015410522930324078,
                0.027653180062770844,
                0.05426189303398132,
                -0.008375689387321472,
                -0.008218149654567242,
                -0.04771716892719269,
                0.0034431421663612127,
                0.028224220499396324,
                0.028754418715834618,
                0.07087256759405136,
                -0.040545668452978134,
                -0.006216519977897406,
                -0.012661714106798172,
                2.426999685667397e-07,
                0.026913711801171303,
                0.00425551226362586,
                0.002547121373936534,
                0.019065143540501595,
                0.04166404530405998,
                0.015048287808895111,
                -0.02953239716589451,
                0.0033212273847311735,
                -0.00355521566234529,
                -0.0005252440460026264,
                0.05811958760023117,
                -0.024447496980428696,
                -0.022417979314923286,
                -0.011421791277825832,
                0.06239660456776619,
                -0.06996367871761322,
                -0.012869554571807384,
                -0.007930493913590908,
                -0.007348401006311178,
                -0.05969178304076195,
                0.010015192441642284,
                0.03240998461842537,
                0.039556022733449936,
                -0.023116784170269966,
                0.007595733739435673,
                -0.0419820100069046,
                0.02638574130833149,
                -0.021262872964143753,
                0.032566823065280914,
                5.365705419535516e-06,
                0.07906176149845123,
                0.015024456195533276,
                -0.014765436761081219,
                -0.020476948469877243,
                -0.0002774363092612475,
                -0.02963368594646454,
                0.029502464458346367,
                0.04831349104642868,
                0.033559542149305344,
                0.04646841064095497,
                0.016258463263511658,
                -0.03778740018606186,
                -0.0068556624464690685,
                0.07526612281799316,
                0.0025987231638282537,
                0.02742009051144123,
                -0.03492053598165512,
                0.010558881796896458,
                -0.066175177693367,
                -0.026767300441861153,
                0.008211842738091946,
                0.04450578615069389,
                0.0137818967923522,
                0.06267163902521133,
                0.008332445286214352,
                0.0014570823404937983,
                0.00907185859978199,
                0.045478180050849915,
                0.06063007190823555,
                0.04557816684246063,
                0.009893868118524551,
                -0.031591493636369705,
                -0.006209885235875845,
                -0.008539278991520405,
                0.013399713672697544,
                0.03049478679895401,
                -0.056269120424985886,
                6.470440351451014e-35,
                0.002053188858553767,
                -0.03961149603128433,
                -0.039763275533914566,
                -0.01845022849738598,
                0.023627568036317825,
                -0.03012865036725998,
                -0.027546081691980362,
                0.004753387067466974,
                0.02549153007566929,
                0.011377880349755287,
                -0.001707734540104866
            ]
        },
        "Location": "Scotland, United Kingdom",
        "Remuneration": 68.75,
        "Services Required": {
            "name": "General Manager",
            "rep": [
                0.03966757282614708,
                -0.013458351604640484,
                -0.004009533673524857,
                0.04630811884999275,
                0.01156478002667427,
                0.08788298070430756,
                -0.018641049042344093,
                0.0010719136334955692,
                0.019756028428673744,
                0.007027996703982353,
                0.05196528136730194,
                -0.04839948192238808,
                0.0029812483116984367,
                -0.0009337793453596532,
                0.04880416765809059,
                -0.0064704264514148235,
                -0.002931848866865039,
                0.007113716099411249,
                0.03429365158081055,
                -0.038002148270606995,
                -0.020912814885377884,
                0.06061849743127823,
                0.012600886635482311,
                0.010699527338147163,
                -0.04240696132183075,
                -0.013538788072764874,
                0.047156922519207,
                0.02818111702799797,
                0.02126592956483364,
                0.02825528383255005,
                -0.025681104511022568,
                -0.04105706140398979,
                -0.022770816460251808,
                -0.010031809099018574,
                1.7602731077204226e-06,
                0.05167587101459503,
                0.020555775612592697,
                -0.026538746431469917,
                0.005250544287264347,
                -0.027329809963703156,
                -0.027704844251275063,
                -0.05013250187039375,
                -0.04865123704075813,
                0.032816898077726364,
                -0.017883634194731712,
                -0.035685423761606216,
                0.0418158657848835,
                0.005241239909082651,
                -0.0473613478243351,
                0.014178077690303326,
                -0.02128252573311329,
                -0.08626525849103928,
                -0.07830585539340973,
                -0.06107000261545181,
                -0.009133992716670036,
                -0.1025618314743042,
                -0.0003663108800537884,
                0.07176309823989868,
                -0.02616378851234913,
                0.015636222437024117,
                -0.03498251736164093,
                0.02220216952264309,
                -0.006920525338500738,
                0.01155564934015274,
                0.04534498602151871,
                0.030615612864494324,
                0.06762324273586273,
                0.002615096978843212,
                0.031049249693751335,
                -0.036403682082891464,
                0.04238620400428772,
                0.0173188429325819,
                0.04715203121304512,
                0.07218673825263977,
                0.05342600867152214,
                0.03437644988298416,
                0.028842777013778687,
                0.029048586264252663,
                -0.040481846779584885,
                0.026012780144810677,
                -0.047061849385499954,
                0.011142532341182232,
                0.02997679077088833,
                0.0011625539045780897,
                -0.05548613518476486,
                0.07589413970708847,
                -0.022655446082353592,
                0.00014226975326891989,
                0.004431505687534809,
                0.07327798008918762,
                -0.04275546967983246,
                0.007033929228782654,
                0.0039659827016294,
                0.05567917600274086,
                0.006378494668751955,
                0.0007090410217642784,
                0.010398785583674908,
                0.008320397697389126,
                -0.025318603962659836,
                -0.04183311387896538,
                -0.04372002184391022,
                0.03239661455154419,
                0.018304983153939247,
                0.03186193108558655,
                0.021108468994498253,
                0.10567817836999893,
                -0.03463863208889961,
                0.04419730603694916,
                0.002643160754814744,
                -0.012579339556396008,
                0.016558615490794182,
                0.001321921474300325,
                0.017449820414185524,
                -0.004350066184997559,
                0.04568348824977875,
                -0.0019976019393652678,
                -0.033560704439878464,
                0.052657995373010635,
                0.01426114235073328,
                0.06008408963680267,
                0.014034276828169823,
                0.02545866370201111,
                -0.021636737510561943,
                -0.0014972095377743244,
                -0.010120674967765808,
                -0.06524577736854553,
                -0.1044100821018219,
                0.018725300207734108,
                0.08062607795000076,
                0.04564687982201576,
                -0.044409167021512985,
                -0.02714010328054428,
                0.01323827262967825,
                0.027463873848319054,
                0.02156539261341095,
                0.02332380972802639,
                -0.0323338583111763,
                0.019674424082040787,
                -0.0454859584569931,
                0.014051788486540318,
                -0.03767931088805199,
                -0.018333829939365387,
                -0.09205850213766098,
                0.018436335027217865,
                0.007143470924347639,
                -0.017252905294299126,
                0.006184818223118782,
                0.026229113340377808,
                0.011478003114461899,
                -0.003609804203733802,
                0.011234723962843418,
                -0.053155358880758286,
                0.04575445502996445,
                0.023366427049040794,
                0.10443831980228424,
                0.039507292211055756,
                -0.003241209080442786,
                -0.007662460673600435,
                -0.025271160528063774,
                0.033032432198524475,
                0.023428013548254967,
                0.001411217381246388,
                0.021755047142505646,
                0.021407516673207283,
                -0.031177368015050888,
                0.014250080101191998,
                0.12017524242401123,
                -0.05320574715733528,
                -0.0489976704120636,
                0.020440666005015373,
                0.02264028787612915,
                0.010715862736105919,
                -0.00604878319427371,
                0.04093648120760918,
                0.01922149956226349,
                -0.023734616115689278,
                -0.0016748553607612848,
                0.012094876728951931,
                0.032590996474027634,
                -0.02439434453845024,
                -0.04088164120912552,
                -0.09115373343229294,
                0.027492720633745193,
                -0.04474661871790886,
                -0.05189039185643196,
                0.021070357412099838,
                -0.007760026026517153,
                0.03690922632813454,
                -0.005786459427326918,
                -0.007968670688569546,
                0.047064825892448425,
                0.0022012831177562475,
                -0.03241417184472084,
                0.03598504140973091,
                0.0304117351770401,
                0.02666698954999447,
                -0.03474816307425499,
                -0.04101601243019104,
                -0.03887419030070305,
                0.010607568547129631,
                -0.025667142122983932,
                0.01816212758421898,
                -0.0264408141374588,
                0.01409269217401743,
                -0.03156394511461258,
                0.0036290192510932684,
                -0.005581951234489679,
                -0.013560653664171696,
                -0.06088710576295853,
                -0.013031436130404472,
                0.04117219150066376,
                0.030130770057439804,
                -0.003744693472981453,
                0.0014794335002079606,
                -0.03066389262676239,
                0.06070510670542717,
                0.03974677249789238,
                0.04769137129187584,
                -0.02887582965195179,
                0.06592801958322525,
                0.008137007243931293,
                -0.038751013576984406,
                0.027210969477891922,
                -0.01793862134218216,
                0.02244114689528942,
                -0.10545427352190018,
                0.002611855510622263,
                -0.03080539032816887,
                -0.015274563804268837,
                -0.01103941909968853,
                0.015940988436341286,
                -0.0099635049700737,
                0.024554451927542686,
                0.00246620737016201,
                0.006767235230654478,
                0.012429891154170036,
                0.00120020960457623,
                -0.0012223378289490938,
                -0.003967785742133856,
                0.014358358457684517,
                -0.03857526555657387,
                0.026992613449692726,
                -0.02056794986128807,
                -0.03400919958949089,
                0.017525967210531235,
                -0.007921990938484669,
                0.047165028750896454,
                -0.018016299232840538,
                -0.042135197669267654,
                0.01673346571624279,
                -0.03700053319334984,
                0.0055474694818258286,
                0.01917378418147564,
                0.017447030171751976,
                -0.00021930245566181839,
                0.029734624549746513,
                -0.0004191025218460709,
                0.03743470832705498,
                -0.0855446457862854,
                -0.018648194149136543,
                0.052581027150154114,
                -0.017176032066345215,
                -0.012231418862938881,
                0.011569934897124767,
                0.05311978980898857,
                -0.0015417011454701424,
                0.005002542398869991,
                -0.08494426310062408,
                -0.02633996680378914,
                -0.009138020686805248,
                -0.0018666716059669852,
                0.0455964021384716,
                0.04452783986926079,
                -0.0323956198990345,
                0.009309042245149612,
                -0.003611978841945529,
                0.0016172773903235793,
                0.03247459977865219,
                -0.041166115552186966,
                -0.010276451706886292,
                0.04381166025996208,
                0.01733413338661194,
                -0.025320550426840782,
                0.04236410930752754,
                0.026717839762568474,
                -0.012321430258452892,
                0.030272668227553368,
                -0.009587087668478489,
                -0.016235992312431335,
                -0.02675592340528965,
                -0.07892674207687378,
                -0.024662256240844727,
                0.011957833543419838,
                -0.061156801879405975,
                -0.014677361585199833,
                -0.0010062704095616937,
                -0.02369401603937149,
                0.007958974689245224,
                -0.0023699142038822174,
                0.030107442289590836,
                -0.049681104719638824,
                -0.06036077067255974,
                0.06692587584257126,
                -0.008972281590104103,
                -0.007207880262285471,
                -0.08136560022830963,
                0.023225324228405952,
                0.048911165446043015,
                -0.039903100579977036,
                -0.009608288295567036,
                0.08465293049812317,
                0.030928906053304672,
                0.00042267414391972125,
                -0.010117054916918278,
                0.012791327200829983,
                0.0015732425963506103,
                -0.019952034577727318,
                0.049403995275497437,
                -0.00013791465607937425,
                0.09918075799942017,
                0.013666032813489437,
                0.013230018317699432,
                -0.017879238352179527,
                -0.01789208874106407,
                -0.007969879545271397,
                0.05984146147966385,
                -0.05784711614251137,
                -0.02115187980234623,
                -0.012505886144936085,
                -0.0732438862323761,
                0.014296717010438442,
                -0.0071757179684937,
                -0.030172014608979225,
                -0.02466648444533348,
                0.026900196447968483,
                0.016912614926695824,
                -0.03879352658987045,
                -0.049215167760849,
                -0.013036953285336494,
                -0.004015120677649975,
                0.035755135118961334,
                -0.0037930163089185953,
                0.012996469624340534,
                0.055218588560819626,
                -0.06606525182723999,
                -0.009419132024049759,
                0.05214166268706322,
                0.027586083859205246,
                -0.1092832088470459,
                -0.003711842931807041,
                -0.01495977770537138,
                -0.011962533928453922,
                -0.005944230128079653,
                -0.03854139521718025,
                -0.00803287047892809,
                -0.016141759231686592,
                -0.048417456448078156,
                0.016305476427078247,
                -0.05345071852207184,
                0.034686435014009476,
                -0.060750678181648254,
                0.0036802070681005716,
                -0.019580617547035217,
                -0.05075389891862869,
                0.0438876748085022,
                -0.016810070723295212,
                0.007428791373968124,
                0.05534597486257553,
                -0.055381469428539276,
                -0.029713358730077744,
                -0.018603051081299782,
                0.008290214464068413,
                -0.01533550675958395,
                0.0497535802423954,
                -0.018101554363965988,
                0.001271881745196879,
                0.025913935154676437,
                0.047445669770240784,
                0.016220388934016228,
                -0.015188593417406082,
                0.031746651977300644,
                0.004119512625038624,
                0.023437000811100006,
                0.012309351935982704,
                0.004316671285778284,
                0.0013577486388385296,
                0.007487725466489792,
                0.01405557245016098,
                -0.008490299805998802,
                -0.0009979828028008342,
                0.027936402708292007,
                -0.027934180572628975,
                -0.02350091002881527,
                0.050260793417692184,
                -0.0020740677136927843,
                -0.035844240337610245,
                -0.0220146756619215,
                -0.01636766828596592,
                -0.026114216074347496,
                0.007518529426306486,
                0.025599127635359764,
                0.05426197499036789,
                0.019159438088536263,
                0.00025763665325939655,
                -0.019235709682106972,
                -0.08236897736787796,
                0.1177983507514,
                -0.03856056556105614,
                -0.0034444001503288746,
                0.04628410562872887,
                0.04144274443387985,
                -0.010481998324394226,
                -0.04104585945606232,
                0.01000144612044096,
                -0.007271446753293276,
                -0.06779847294092178,
                -0.013481462374329567,
                0.008107959292829037,
                -0.01748799905180931,
                -0.0082910330966115,
                0.0610564760863781,
                0.01351972483098507,
                0.026515791192650795,
                0.021784067153930664,
                0.01429517101496458,
                0.03687061741948128,
                0.0850439965724945,
                0.05898452177643776,
                0.040818244218826294,
                -0.016280347481369972,
                0.017421022057533264,
                0.010334151796996593,
                -0.07461825013160706,
                0.0898800939321518,
                0.06740966439247131,
                -0.0360601544380188,
                -0.05013181269168854,
                0.07423817366361618,
                0.04069490730762482,
                0.005257452372461557,
                0.004254182334989309,
                0.08690781891345978,
                -0.06847770512104034,
                0.007733158301562071,
                -0.06123417988419533,
                -0.023499377071857452,
                -0.026800487190485,
                0.019100718200206757,
                -0.11819735914468765,
                -0.0076632630079984665,
                0.013523844070732594,
                -0.03300721198320389,
                -0.06942421197891235,
                -0.047539595514535904,
                0.08185264468193054,
                -0.04215243086218834,
                0.016187548637390137,
                -0.005002984777092934,
                -0.012532031163573265,
                -0.056697845458984375,
                0.03847000002861023,
                0.031327985227108,
                -0.03667585179209709,
                0.014324530959129333,
                0.0093437684699893,
                0.00790366530418396,
                -0.04522538185119629,
                -0.02696218714118004,
                0.07877662777900696,
                -0.03177391365170479,
                0.022489992901682854,
                -0.03826666623353958,
                -0.00939948670566082,
                -0.0217294879257679,
                0.01742701046168804,
                -0.0247044675052166,
                -0.017659276723861694,
                0.005382431671023369,
                0.08840208500623703,
                0.031329814344644547,
                -0.025677599012851715,
                -0.036544620990753174,
                -0.011904613114893436,
                -0.004825293086469173,
                -0.005192192737013102,
                -0.027851315215229988,
                0.008760727941989899,
                0.023180576041340828,
                -0.02986295148730278,
                0.013608396984636784,
                0.05490613356232643,
                -0.06243438646197319,
                -0.022444166243076324,
                -0.06419587880373001,
                -0.04083259776234627,
                0.02449704147875309,
                -0.07724395394325256,
                -0.09320267289876938,
                -0.03611830249428749,
                -0.07816992700099945,
                -0.028773987665772438,
                0.01870252564549446,
                0.012141845189034939,
                -0.03576495125889778,
                0.01372403372079134,
                -0.01837405003607273,
                0.021953491494059563,
                -0.00872719381004572,
                0.04003230854868889,
                -0.005502199288457632,
                0.02920863963663578,
                0.001213071751408279,
                -0.010523689910769463,
                -0.07812138646841049,
                0.033849529922008514,
                0.008651508949697018,
                0.044100090861320496,
                -0.02644229866564274,
                0.008891500532627106,
                0.03047567792236805,
                -0.004289135802537203,
                -0.033153362572193146,
                0.013486913405358791,
                -0.01213883701711893,
                0.012160364538431168,
                -0.04366105794906616,
                0.005809396971017122,
                0.00018170314433518797,
                0.03158823400735855,
                0.05994218587875366,
                0.01035933755338192,
                -0.0016351280501112342,
                -0.043774791061878204,
                0.04380352795124054,
                -0.03118377923965454,
                0.04034584388136864,
                0.0008306782110594213,
                0.03287045657634735,
                0.05346613749861717,
                -0.004478377755731344,
                -0.05319187045097351,
                0.035847008228302,
                -0.0202763881534338,
                -0.014827308245003223,
                0.015385893173515797,
                -0.013863441534340382,
                -0.02102080173790455,
                0.018469659611582756,
                0.0033599676098674536,
                0.04396830126643181,
                -0.03813660144805908,
                -0.011359868571162224,
                -0.056607943028211594,
                0.044292766600847244,
                -0.028658034279942513,
                -6.961705131639698e-33,
                -0.025770217180252075,
                -0.039207443594932556,
                -0.007624124176800251,
                -0.02333221025764942,
                -0.0580635704100132,
                -0.03675801306962967,
                0.040720660239458084,
                -0.0017292943084612489,
                -0.027072006836533546,
                -0.0009563204948790371,
                -0.017208557575941086,
                0.0068015060387551785,
                0.023391850292682648,
                0.030585292726755142,
                0.023114647716283798,
                0.006838968023657799,
                -0.0008935962687246501,
                0.020493755117058754,
                -0.01194455474615097,
                0.02628793753683567,
                0.0037891531828790903,
                0.02526736818253994,
                -0.004341954365372658,
                0.06426163762807846,
                -0.013934244401752949,
                -0.01951635628938675,
                -0.04279353469610214,
                0.0089756203815341,
                -0.006130018271505833,
                0.008092002011835575,
                0.005186138208955526,
                -0.011817147955298424,
                -0.007529258728027344,
                0.0029059918597340584,
                -0.014349340461194515,
                0.03655856102705002,
                -0.0072936625219881535,
                -0.008431836031377316,
                -0.02567511610686779,
                -0.021526768803596497,
                -0.02529638074338436,
                0.01065280195325613,
                -0.055189453065395355,
                0.0022224485874176025,
                0.026587624102830887,
                -0.03447399660944939,
                -0.014946344308555126,
                -0.023221496492624283,
                -0.0025136747863143682,
                -0.022137554362416267,
                0.006648033857345581,
                0.001046164077706635,
                -0.013051524758338928,
                0.08064282685518265,
                -0.0018952175742015243,
                0.010294444859027863,
                -0.005359395872801542,
                0.005637261550873518,
                0.0664404109120369,
                -0.03549228981137276,
                0.02231256477534771,
                0.03118530847132206,
                0.0010393927805125713,
                0.07685305178165436,
                -0.018128205090761185,
                0.01580858789384365,
                -0.03330554440617561,
                0.06136857718229294,
                -0.07928793877363205,
                0.006464362144470215,
                -0.061197467148303986,
                0.03935370221734047,
                -0.06586827337741852,
                -0.038439664989709854,
                -0.018950197845697403,
                -0.023256924003362656,
                -0.005321798380464315,
                0.008949853479862213,
                0.004035029094666243,
                0.014601986855268478,
                -0.06312962621450424,
                0.011402302421629429,
                0.023433607071638107,
                -0.010182070545852184,
                0.01916028931736946,
                -0.1007622480392456,
                -0.012066376395523548,
                0.00713606271892786,
                0.032501284033060074,
                -0.004948478192090988,
                -0.030784159898757935,
                -0.012991230934858322,
                -0.034929100424051285,
                -0.0017605164321139455,
                0.031167903915047646,
                -0.042328521609306335,
                -0.01758056879043579,
                0.0023346010129898787,
                0.0003257216594647616,
                -0.007966875098645687,
                -0.012532483786344528,
                -0.006099057849496603,
                -0.015354306437075138,
                0.09742409735918045,
                -0.002462780335918069,
                -0.01524814311414957,
                -0.027482299134135246,
                -0.010383693501353264,
                -0.01220703311264515,
                -0.009382156655192375,
                -0.030572980642318726,
                0.015779700130224228,
                0.04842273145914078,
                -0.05104738473892212,
                -0.03950555995106697,
                -0.030859140679240227,
                0.011746085248887539,
                0.007713618688285351,
                0.035836633294820786,
                -0.013461250811815262,
                0.004448607563972473,
                0.033729586750268936,
                -0.018570303916931152,
                -0.00814742036163807,
                -0.02925208769738674,
                0.0010491266148164868,
                0.020550841465592384,
                -0.003130500903353095,
                0.0521974191069603,
                0.010311713442206383,
                -0.008973868563771248,
                -0.08134406059980392,
                2.4032732426348957e-07,
                0.07232264429330826,
                0.003687345189973712,
                -0.0023066748399287462,
                0.015833651646971703,
                0.0036985029000788927,
                -0.01652047038078308,
                0.03474004566669464,
                0.00967585202306509,
                0.010592612437903881,
                0.004529023543000221,
                0.0655369758605957,
                0.008545568212866783,
                0.008710930123925209,
                -0.003651516977697611,
                0.014809089712798595,
                0.03626953810453415,
                -0.040735598653554916,
                -0.013324706815183163,
                0.004601582419127226,
                -0.0111296521499753,
                -0.014933760277926922,
                -0.013375148177146912,
                0.008908393792808056,
                -0.020926430821418762,
                0.009559857659041882,
                -0.06009877473115921,
                0.029683860018849373,
                -0.06391061842441559,
                0.06820079684257507,
                0.0010932786390185356,
                0.1001489907503128,
                0.05423882231116295,
                -0.0340656079351902,
                -0.014433289878070354,
                0.027826417237520218,
                -0.04999922588467598,
                -0.020475411787629128,
                0.010406806133687496,
                -0.0018326319986954331,
                0.03899647295475006,
                0.002012823475524783,
                -0.05421150475740433,
                -0.016498545184731483,
                0.004298531450331211,
                -0.01438269391655922,
                0.01599724590778351,
                0.015039533376693726,
                0.08295583724975586,
                -0.05802544578909874,
                -0.023165671154856682,
                -0.0475848950445652,
                0.008521105162799358,
                0.018778471276164055,
                0.03347598761320114,
                -0.0026278384029865265,
                -0.006199276074767113,
                -0.03723696619272232,
                0.04331149160861969,
                0.042071737349033356,
                0.01960250549018383,
                -0.03994354233145714,
                -0.00874826405197382,
                -0.005280057899653912,
                0.003563743783161044,
                -0.007276715245097876,
                0.03101942129433155,
                -0.00809759832918644,
                1.0660120901056873e-34,
                0.024949798360466957,
                0.014858280308544636,
                -0.0397249236702919,
                0.0014595413813367486,
                0.03734138235449791,
                -0.023258497938513756,
                -0.06263450533151627,
                -0.024478118866682053,
                -0.01779686100780964,
                -0.0067356606014072895,
                -0.0011591109214350581
            ]
        },
        "Skills": {
            "name": [
                "Operations – proven record in a customer facing industry with high delivery / service expectation",
                "Financial – Management of P&L, commercial awareness and decision making",
                "Sales – Bringing experience from a wide arena to support and obtain sales",
                "Business Development – successful record of generating opportunities and closing deals",
                "Project Management – Quality, safety, customer liaisons and high attention to detail",
                "Interpersonal Skills – Effective Communicator on both group and individual basis"
            ],
            "rep": [
                0.015240948647260666,
                0.038784392178058624,
                -0.00827794335782528,
                -0.019049111753702164,
                -0.0034658685326576233,
                0.0037156715989112854,
                -0.031820427626371384,
                0.011625164188444614,
                -0.017615577206015587,
                -0.021147212013602257,
                -0.009865643456578255,
                0.03352765738964081,
                -0.009929836727678776,
                0.10107724368572235,
                -0.010191039182245731,
                0.010621214285492897,
                -0.03254270926117897,
                0.04165445640683174,
                -0.03415725752711296,
                0.028461715206503868,
                -0.023623257875442505,
                -0.025290215387940407,
                0.008059414103627205,
                -0.019288023933768272,
                -0.006807605270296335,
                -0.03683099150657654,
                0.03117038682103157,
                -0.0007453608559444547,
                -0.027228740975260735,
                -0.01824881136417389,
                0.060388363897800446,
                -0.005768016912043095,
                0.006915153004229069,
                -0.029143990948796272,
                1.4111348036749405e-06,
                0.002333088079467416,
                -0.07131452113389969,
                0.014134051278233528,
                -0.06154026463627815,
                0.00891630444675684,
                -0.017074886709451675,
                0.01844177395105362,
                -0.0026871964801102877,
                0.019616493955254555,
                0.00632005650550127,
                0.06976516544818878,
                0.004139040131121874,
                -0.02053964138031006,
                -0.003518881741911173,
                0.002571894321590662,
                -0.014139516279101372,
                0.017821533605456352,
                -0.09034412354230881,
                0.013821876607835293,
                -0.08514594286680222,
                -0.03307972475886345,
                -0.0006462055025622249,
                0.008229202590882778,
                0.011191001161932945,
                -0.06774470955133438,
                -0.0351576991379261,
                -0.005987467244267464,
                0.008394315838813782,
                -0.023507272824645042,
                0.038834597915410995,
                0.019405921921133995,
                -0.02104029804468155,
                0.04054154455661774,
                -0.03770313039422035,
                -0.007443552371114492,
                0.019292112439870834,
                0.04106428101658821,
                -0.015019003301858902,
                0.020821979269385338,
                -0.03661936894059181,
                -0.11176992207765579,
                -0.017130238935351372,
                -0.04131348803639412,
                0.03068879432976246,
                0.0121298898011446,
                -0.07512491196393967,
                0.05285785347223282,
                0.008098666556179523,
                -0.003173273056745529,
                -0.007090981118381023,
                0.031448930501937866,
                -0.032329168170690536,
                -0.022943435236811638,
                0.015529259108006954,
                0.03686143085360527,
                0.011475599370896816,
                0.018140651285648346,
                0.027947349473834038,
                0.009078681468963623,
                -0.0456618033349514,
                -0.029608089476823807,
                0.10429994016885757,
                0.022690214216709137,
                -0.00450828718021512,
                -0.04604705795645714,
                0.027366451919078827,
                0.007372966036200523,
                -0.0013915180461481214,
                0.011835619807243347,
                -0.07770553976297379,
                0.048372238874435425,
                0.047706883400678635,
                0.011538463644683361,
                -0.05127758905291557,
                0.04192850738763809,
                0.006483935285359621,
                -0.01845761202275753,
                -0.0351717434823513,
                0.035054635256528854,
                -0.004520354326814413,
                -0.03710690513253212,
                -0.03455396369099617,
                0.033286649733781815,
                -0.015955328941345215,
                0.03702661767601967,
                0.047802750021219254,
                0.023754628375172615,
                -0.04183163121342659,
                -0.01580595225095749,
                -0.014568322338163853,
                0.02015865221619606,
                -0.007686279714107513,
                0.04022882878780365,
                -0.01661299541592598,
                0.017350267618894577,
                0.02661816217005253,
                0.02527523599565029,
                -0.01125919446349144,
                0.04329461231827736,
                0.008455948904156685,
                0.04804312810301781,
                -0.008859585970640182,
                0.02579527162015438,
                -0.04875682666897774,
                0.02522891014814377,
                0.00020758676691912115,
                0.0010426738299429417,
                -0.05389304459095001,
                0.008749052882194519,
                -0.059781044721603394,
                -0.005709504242986441,
                -0.049350179731845856,
                0.055374521762132645,
                0.025923732668161392,
                -0.011893258430063725,
                -0.003111034631729126,
                -0.08095667511224747,
                0.03549257665872574,
                0.007423157803714275,
                0.0550801083445549,
                0.023479431867599487,
                0.01720416732132435,
                -0.07397396862506866,
                0.028575126081705093,
                0.030711010098457336,
                0.002534983679652214,
                0.03448045253753662,
                0.05556450039148331,
                0.01992860995233059,
                -0.06074609234929085,
                -0.025346416980028152,
                0.10148502886295319,
                0.04110175371170044,
                -0.056843675673007965,
                0.007861044257879257,
                -0.011263789609074593,
                -0.010960767976939678,
                -0.004886135924607515,
                0.022859487682580948,
                -0.008016189560294151,
                -0.10475620627403259,
                -0.02802133746445179,
                -0.031088974326848984,
                -0.01117341686040163,
                -0.025785353034734726,
                -0.015157814137637615,
                -0.06812463700771332,
                0.06269601732492447,
                -0.002711690030992031,
                0.10966939479112625,
                0.04939465969800949,
                -0.00325256260111928,
                -0.005557972937822342,
                -0.019150657579302788,
                0.023835865780711174,
                -0.001573102897964418,
                -0.018018070608377457,
                -0.017608296126127243,
                0.047374527901411057,
                0.017378760501742363,
                0.014584526419639587,
                -0.10102161765098572,
                -0.03754622861742973,
                -0.06758876144886017,
                0.05744278058409691,
                -0.028570527210831642,
                -0.06024656444787979,
                -0.022737225517630577,
                -0.010808948427438736,
                0.024835575371980667,
                -0.007215602323412895,
                0.010430733673274517,
                -0.014178517274558544,
                -0.035378895699977875,
                0.031274329870939255,
                0.0625620111823082,
                -0.009425530210137367,
                0.009318573400378227,
                0.030399806797504425,
                0.00780025590211153,
                0.04736596718430519,
                0.022811418399214745,
                0.010635760612785816,
                -0.008563658222556114,
                0.009945298545062542,
                -0.0017078480450436473,
                0.018955854699015617,
                -0.003510214388370514,
                -0.022831501439213753,
                0.013464946299791336,
                -0.012916240841150284,
                -0.001060959999449551,
                0.027812765911221504,
                0.004244658630341291,
                0.02526085264980793,
                0.044306039810180664,
                0.034574221819639206,
                0.014500903896987438,
                0.01532408781349659,
                -0.013034963980317116,
                0.04082605987787247,
                0.05694112181663513,
                0.101819708943367,
                0.010884842835366726,
                0.004297028295695782,
                -0.04894089698791504,
                0.017086010426282883,
                0.01801270991563797,
                0.016904965043067932,
                -0.02645854279398918,
                0.011084473691880703,
                0.01928350329399109,
                -0.010206230916082859,
                0.011230239644646645,
                0.036857496947050095,
                -0.037738900631666183,
                -0.027952095493674278,
                0.014750231057405472,
                0.0028963331133127213,
                -0.008707618340849876,
                -0.06813308596611023,
                -0.06990931928157806,
                0.021784892305731773,
                0.0015962894540280104,
                0.008152800612151623,
                0.02464408054947853,
                -0.027414504438638687,
                0.004824383184313774,
                0.004837344400584698,
                0.0311309602111578,
                -0.025377150624990463,
                0.020555980503559113,
                -0.04991019144654274,
                0.01598929613828659,
                0.033480625599622726,
                -0.007055844645947218,
                0.007241702638566494,
                -0.005062761716544628,
                -0.016301292926073074,
                -0.023661134764552116,
                0.02113034762442112,
                0.022901689633727074,
                0.0409887321293354,
                -0.046947792172431946,
                0.007456181105226278,
                0.04730580374598503,
                -0.09647881239652634,
                0.01214179489761591,
                0.05478408560156822,
                0.0037545093800872564,
                -0.028171170502901077,
                -0.0035865812096744776,
                0.07484284043312073,
                -0.02055414579808712,
                -0.021631410345435143,
                -0.04286689683794975,
                -0.0560753159224987,
                0.0033585242927074432,
                -0.04602084681391716,
                0.009823997505009174,
                0.050654780119657516,
                0.03475968912243843,
                -0.04586723819375038,
                -0.03544703871011734,
                -0.01784473843872547,
                -0.022703638300299644,
                -0.07333660125732422,
                0.06564924120903015,
                -0.06064845249056816,
                -0.0721549466252327,
                -0.04012857750058174,
                0.048485517501831055,
                -0.014482667669653893,
                -0.014082834124565125,
                -0.017639219760894775,
                0.04043647646903992,
                -0.0038237893022596836,
                0.04634556546807289,
                -0.07594436407089233,
                0.017999041825532913,
                0.00022549243294633925,
                -0.039421211928129196,
                0.013347698375582695,
                -0.045528851449489594,
                0.08092266321182251,
                0.015152889303863049,
                0.020418299362063408,
                -0.04376138001680374,
                0.04655107855796814,
                0.01974138244986534,
                -0.03409218415617943,
                -0.013855112716555595,
                -0.015275315381586552,
                -0.012652182951569557,
                -0.0755564421415329,
                0.01483823824673891,
                -0.09803850203752518,
                -0.049582589417696,
                -0.012379591353237629,
                0.0006780611583963037,
                -0.028424082323908806,
                -0.007066320162266493,
                -0.039406489580869675,
                0.009524582885205746,
                -0.007349213119596243,
                0.02401871792972088,
                -0.020645413547754288,
                -0.021476605907082558,
                0.050963930785655975,
                0.044882882386446,
                0.01816648058593273,
                0.01692701131105423,
                0.048208728432655334,
                -0.002996518276631832,
                -0.0064257727935910225,
                -0.017152410000562668,
                0.07708750665187836,
                -0.016424329951405525,
                -0.005723088979721069,
                -0.0036017419770359993,
                -0.03877667337656021,
                -0.012014414183795452,
                0.07410944998264313,
                0.07617729157209396,
                -0.0420488603413105,
                -0.024326875805854797,
                -0.0092446468770504,
                0.019198594614863396,
                -0.009667793288826942,
                -0.012364712543785572,
                0.0025111904833465815,
                0.006094376090914011,
                -0.014809461310505867,
                0.028730418533086777,
                0.05223178118467331,
                -0.05516880005598068,
                0.022198636084794998,
                -0.012657173909246922,
                -0.04495863616466522,
                -0.007269400171935558,
                0.03743130713701248,
                -0.02048570290207863,
                0.03000333346426487,
                -0.03195015713572502,
                0.014147646725177765,
                0.07220374047756195,
                -0.017018213868141174,
                0.1054573580622673,
                -0.02403239533305168,
                0.013551479205489159,
                -0.028911007568240166,
                -0.028410421684384346,
                -0.04622728377580643,
                0.03732529655098915,
                0.07559837400913239,
                -0.03739236295223236,
                0.06082205846905708,
                -0.051462940871715546,
                -0.02225274220108986,
                0.007790484931319952,
                -0.028300167992711067,
                0.0043772743083536625,
                -0.004435851704329252,
                0.013001373037695885,
                -0.040017690509557724,
                0.02355537749826908,
                0.03065483085811138,
                -0.05878693237900734,
                0.027182817459106445,
                0.0046821837313473225,
                -0.0642348900437355,
                0.04264584183692932,
                -0.016171736642718315,
                0.010971665382385254,
                0.015185070224106312,
                -0.0017746686935424805,
                0.08170437067747116,
                -0.021070757880806923,
                0.039360225200653076,
                0.000750395527575165,
                -0.04372343793511391,
                -0.00916181318461895,
                -0.03446588292717934,
                -0.0020454234909266233,
                -0.039523929357528687,
                0.01973595842719078,
                0.004772927146404982,
                0.004525808151811361,
                -0.0028993834275752306,
                0.014744301326572895,
                0.04273755103349686,
                0.010614004917442799,
                0.02805999107658863,
                0.05872293934226036,
                -0.04555314779281616,
                -0.0004281469446141273,
                -0.006461178418248892,
                0.020690618082880974,
                -0.006574797444045544,
                0.04969116672873497,
                0.0066549526527523994,
                0.05404007434844971,
                -0.013938972726464272,
                -0.00514191621914506,
                0.002720404416322708,
                -0.00883081927895546,
                0.01220991276204586,
                0.022578060626983643,
                0.021400509402155876,
                0.019133437424898148,
                -0.03194654360413551,
                -0.00727834040299058,
                0.05210715904831886,
                -0.07519227266311646,
                -0.012103807181119919,
                0.05574626475572586,
                -0.08840608596801758,
                0.06187635287642479,
                -0.0070090643130242825,
                -0.015075105242431164,
                -0.022877834737300873,
                -0.13875600695610046,
                -0.010186627507209778,
                0.014910250902175903,
                0.07030953466892242,
                0.05511363595724106,
                0.032116521149873734,
                -0.07052968442440033,
                0.01376428734511137,
                0.013318557292222977,
                -0.004014706239104271,
                0.025218099355697632,
                -0.03900383785367012,
                -0.02357136458158493,
                -0.030444230884313583,
                0.01175548043102026,
                -0.027167977765202522,
                -0.01068923156708479,
                0.0193011574447155,
                0.03678996488451958,
                0.00493872445076704,
                -0.04010525718331337,
                -0.017893660813570023,
                0.008932135067880154,
                0.012803171761333942,
                -0.011552039533853531,
                -0.034337036311626434,
                -0.029634077101945877,
                -0.028987858444452286,
                -0.026769278571009636,
                -0.028758907690644264,
                -0.0005146550829522312,
                0.05538658797740936,
                -0.02005694806575775,
                -0.03243077173829079,
                -0.012639917433261871,
                0.019245903939008713,
                -0.011349285952746868,
                0.013529537245631218,
                -0.0029596202075481415,
                0.006807474419474602,
                -0.01117077935487032,
                0.02894369699060917,
                -0.02541038766503334,
                -0.0005285112420096993,
                -0.019685372710227966,
                0.004653782118111849,
                0.01264247763901949,
                -0.07579342275857925,
                0.015752429142594337,
                0.04449944943189621,
                -0.0010474465088918805,
                0.020977094769477844,
                -0.010325340554118156,
                0.013698603957891464,
                -0.04784337803721428,
                -0.015807852149009705,
                0.027717363089323044,
                -0.03546283394098282,
                -0.014531881548464298,
                0.034250445663928986,
                -0.04405266046524048,
                -0.04311498999595642,
                -0.048323869705200195,
                -0.05769699811935425,
                0.01781451515853405,
                -0.006121290847659111,
                -0.016812007874250412,
                0.05742800980806351,
                0.05951107665896416,
                -0.06707150489091873,
                0.033030200749635696,
                0.007078760303556919,
                -0.04151380434632301,
                0.03753083199262619,
                -0.06202214956283569,
                -0.028182631358504295,
                -0.11237084865570068,
                0.0326709970831871,
                -0.05042219161987305,
                0.03018416278064251,
                0.06990046054124832,
                -0.008605044335126877,
                0.039312899112701416,
                0.006206043064594269,
                -0.05755280703306198,
                0.040700409561395645,
                -0.017351321876049042,
                0.024089103564620018,
                0.0031466518994420767,
                -0.036100421100854874,
                -0.030277393758296967,
                -0.0038672308437526226,
                0.020569195970892906,
                0.032455407083034515,
                -0.007480767089873552,
                -0.06981401890516281,
                -0.0006871428922750056,
                0.0067722974345088005,
                0.04279574006795883,
                -4.817250787434599e-33,
                0.02346830628812313,
                0.0009248837013728917,
                0.0371452271938324,
                -0.0025483062490820885,
                -0.021353470161557198,
                0.047334857285022736,
                0.006489465944468975,
                0.00019057000463362783,
                -0.0031939896289259195,
                -0.03817925602197647,
                -0.011398468166589737,
                -0.031818490475416183,
                0.015473687089979649,
                -0.012998263351619244,
                -0.024904094636440277,
                0.03641827404499054,
                0.02156194858253002,
                -0.035434432327747345,
                -0.03880270943045616,
                -0.05200622230768204,
                -0.02488064393401146,
                -0.0053147608414292336,
                0.017086036503314972,
                -0.04882749542593956,
                0.015675349161028862,
                0.0034953502472490072,
                -0.0450364351272583,
                0.051854584366083145,
                0.009331871755421162,
                0.001581597956828773,
                0.01186616811901331,
                0.007663062773644924,
                0.003657946828752756,
                -0.08474511653184891,
                0.026949763298034668,
                0.0006227180128917098,
                -0.009750587865710258,
                0.023125674575567245,
                -0.02652006596326828,
                0.013472608290612698,
                0.032118186354637146,
                0.034649964421987534,
                -0.02832935005426407,
                -0.004440036136657,
                0.0020489401649683714,
                0.005137689411640167,
                -0.004526189062744379,
                -0.012851178646087646,
                0.04261745139956474,
                0.0481126606464386,
                -0.017837556079030037,
                -0.017626076936721802,
                -0.04058229923248291,
                0.01774282194674015,
                0.016942454501986504,
                0.010394882410764694,
                -0.01225863117724657,
                -0.02179143764078617,
                -0.08098325878381729,
                -0.025176826864480972,
                -0.03712219372391701,
                -0.01799081824719906,
                0.01093005109578371,
                -0.018455641344189644,
                -0.058985695242881775,
                -0.014321845956146717,
                -0.09705690294504166,
                0.01045354176312685,
                0.06275282055139542,
                -0.025277158245444298,
                -0.024822566658258438,
                0.08327805995941162,
                -0.051169559359550476,
                -0.06173144280910492,
                -0.0664825513958931,
                0.027629612013697624,
                0.005746596027165651,
                0.037093546241521835,
                0.04007038101553917,
                -0.07002785056829453,
                0.01406252384185791,
                -0.024268820881843567,
                -0.03886568173766136,
                0.0009862540755420923,
                0.03877978399395943,
                0.007889296859502792,
                -0.013102028518915176,
                0.005377237219363451,
                0.02893468737602234,
                0.033719535917043686,
                -0.0519556850194931,
                0.024605529382824898,
                0.028255464509129524,
                0.022604893893003464,
                -0.03660896420478821,
                -0.035635169595479965,
                -0.00966276228427887,
                0.01748737506568432,
                0.035617709159851074,
                0.01981981471180916,
                -0.01069323718547821,
                0.05058397352695465,
                -0.051771391183137894,
                0.006772050634026527,
                -0.0027734413743019104,
                0.01629152148962021,
                -0.007522584870457649,
                -0.01287197507917881,
                -0.023006660863757133,
                0.04364113509654999,
                0.041513849049806595,
                -0.01991693302989006,
                0.03547915816307068,
                -0.013836354948580265,
                -0.03902863338589668,
                -0.00861832220107317,
                0.004197882022708654,
                0.03984978049993515,
                -0.006331678479909897,
                -0.018120238557457924,
                0.0863538533449173,
                0.04076613485813141,
                0.014268850907683372,
                -0.037968896329402924,
                -0.007217587903141975,
                0.05366317555308342,
                0.07496853172779083,
                -0.026798095554113388,
                -0.016385959461331367,
                0.015635652467608452,
                -0.0065362779423594475,
                -0.027065349742770195,
                1.9633053227607888e-07,
                0.02278469316661358,
                -0.01523523312062025,
                -0.02396285906434059,
                0.07617542892694473,
                0.002869898919016123,
                -0.005740719381719828,
                0.04535812884569168,
                -0.036696657538414,
                -0.006371402181684971,
                -0.03574052453041077,
                0.0696544274687767,
                -0.023611348122358322,
                -0.056304387748241425,
                -0.00886265467852354,
                0.022168731316924095,
                -0.0610942542552948,
                0.038996271789073944,
                0.0024005582090467215,
                -0.018467489629983902,
                -0.012812835164368153,
                0.053146835416555405,
                0.04286787286400795,
                0.028832750394940376,
                -0.01530791912227869,
                0.010102844797074795,
                0.05925304442644119,
                0.016538400202989578,
                -0.03074444830417633,
                0.07702821493148804,
                0.016705023124814034,
                0.0203314870595932,
                0.008443811908364296,
                0.013362377882003784,
                0.021686511114239693,
                -0.015301190316677094,
                -0.03177427127957344,
                0.04765559360384941,
                0.053312163800001144,
                0.03519943356513977,
                -0.011098237708210945,
                0.07356981933116913,
                0.05051906406879425,
                0.049377333372831345,
                -0.0009226554539054632,
                -0.018336579203605652,
                -0.02467193268239498,
                -0.01246702577918768,
                -0.015344295650720596,
                -0.033667758107185364,
                -0.015832815319299698,
                0.00219780788756907,
                -0.0013175070052966475,
                -0.028348324820399284,
                0.02420918084681034,
                0.024348042905330658,
                -0.013067741878330708,
                0.06466025859117508,
                0.03613249212503433,
                0.05643872171640396,
                0.07136862725019455,
                -0.018728939816355705,
                -0.016106735914945602,
                -0.013213582336902618,
                -0.012181355617940426,
                0.04993228241801262,
                -0.010153533890843391,
                -0.02602546475827694,
                1.394153748819503e-34,
                -0.026431726291775703,
                0.002332653384655714,
                -0.0004982813261449337,
                0.05796053633093834,
                0.01990538462996483,
                0.013590400107204914,
                0.020523564890027046,
                -0.052657242864370346,
                0.006925877183675766,
                0.030313018709421158,
                -0.008655818179249763,
                0.0003469018847681582,
                0.0005009784363210201,
                -0.009399177506566048,
                -0.00854047667235136,
                -0.00033312581945210695,
                -0.005015262868255377,
                -0.022594483569264412,
                0.013837557286024094,
                0.015807026997208595,
                -0.029559968039393425,
                -0.00609134417027235,
                0.013299704529345036,
                -0.006665332708507776,
                0.03183906897902489,
                0.014579320326447487,
                0.040122125297784805,
                -0.018331607803702354,
                -0.007278257515281439,
                0.054571352899074554,
                0.04016374424099922,
                0.0009391932399012148,
                -0.007493395358324051,
                0.0010968403657898307,
                -0.033858124166727066,
                0.04048309847712517,
                -0.014418606646358967,
                -0.0007215846562758088,
                -0.03819578140974045,
                0.01855245791375637,
                -0.027603749185800552,
                -0.04578540846705437,
                0.0011061652330681682,
                0.051935140043497086,
                -0.05869857221841812,
                1.402962880092673e-06,
                -0.032867513597011566,
                -0.05331576243042946,
                -0.03108232468366623,
                -0.018768027424812317,
                0.03463542088866234,
                0.0030306149274110794,
                0.03540723770856857,
                0.029617249965667725,
                -0.008146432228386402,
                -0.018229231238365173,
                0.04489767178893089,
                0.03382839262485504,
                0.056030094623565674,
                -0.04373985156416893,
                0.02852495200932026,
                0.025072326883673668,
                -0.029946990311145782,
                -0.04664820432662964,
                0.01166999526321888,
                -0.06320160627365112,
                -0.12481799721717834,
                0.0019454560242593288,
                -0.009850529953837395,
                -0.02137790061533451,
                -0.08912582695484161,
                -0.06366775184869766,
                0.014991181902587414,
                0.04189459979534149,
                -0.019623419269919395,
                0.015242950059473515,
                0.02787928655743599,
                0.005457344464957714,
                -0.008324652910232544,
                -0.0023024235852062702,
                -0.026191627606749535,
                0.011457965709269047,
                -0.01893508993089199,
                0.05388704687356949,
                -0.031532999128103256,
                0.04486456885933876,
                -0.1186295673251152,
                -0.0031445459462702274,
                0.006182461511343718,
                0.007775239646434784,
                0.004975701682269573,
                0.03169912099838257,
                0.025260431692004204,
                0.03399616479873657,
                0.02386542409658432,
                0.03286809101700783,
                -0.035768333822488785,
                -0.04105570912361145,
                -0.058566030114889145,
                0.044340819120407104,
                0.02998928725719452,
                -0.03593234345316887,
                0.018636491149663925,
                0.0009604176157154143,
                0.021134402602910995,
                0.05124266445636749,
                0.023276815190911293,
                0.043770935386419296,
                -0.008866898715496063,
                -0.022113220766186714,
                -0.07168763875961304,
                -0.04254680499434471,
                -0.010295453481376171,
                -0.0004805063654202968,
                -0.0021686539985239506,
                -0.00793148111552,
                0.06060510128736496,
                -0.054781749844551086,
                0.02213084138929844,
                -0.007241543382406235,
                0.08717752248048782,
                0.025184476748108864,
                -0.008104548789560795,
                -0.06042111665010452,
                0.05284864455461502,
                -0.020333610475063324,
                0.015581963583827019,
                -0.07504889369010925,
                0.006243621930480003,
                -0.04158175364136696,
                0.018974607810378075,
                0.009802298620343208,
                -0.00595697108656168,
                0.026818105950951576,
                -0.014301755465567112,
                -0.019601814448833466,
                0.03779909014701843,
                -0.011943028308451176,
                -0.003972475416958332,
                -0.029332945123314857,
                0.0543254017829895,
                0.028822036460042,
                0.000909372407477349,
                -0.01293969713151455,
                0.0094913300126791,
                -0.004699330776929855,
                0.02711661532521248,
                -0.036123551428318024,
                0.024845018982887268,
                -0.11275119334459305,
                0.019197851419448853,
                -0.017345743253827095,
                -0.01429696474224329,
                -0.10933007299900055,
                0.011967985890805721,
                0.017977718263864517,
                -0.028116101399064064,
                -0.06869366019964218,
                0.007477566599845886,
                -0.012354540638625622,
                0.01018658559769392,
                0.021714895963668823,
                -0.015417647548019886,
                0.031874507665634155,
                -0.011456436477601528,
                -0.038863055408000946,
                0.01067315973341465,
                0.05861351266503334,
                -0.0032340995967388153,
                0.03422771766781807,
                -0.02394312433898449,
                -0.01745421439409256,
                -0.008286739699542522,
                -0.005326756741851568,
                -0.002191628096625209,
                -0.01929076947271824,
                -0.03462599590420723,
                -0.009916183538734913,
                0.040560074150562286,
                -0.055759724229574203,
                -0.0357217937707901,
                0.009659521281719208,
                0.004402975086122751,
                -0.0034408164210617542,
                0.029423788189888,
                0.007567376829683781,
                -0.10171332955360413,
                0.09380507469177246,
                -0.02283666282892227,
                -0.03590396046638489,
                -0.014624246396124363,
                0.005150218028575182,
                -0.012303953990340233,
                0.05247741937637329,
                0.005931899882853031,
                -0.020004170015454292,
                0.06062134727835655,
                -0.03680042922496796,
                -0.043668754398822784,
                -0.024336034432053566,
                0.018977297469973564,
                -0.02478822134435177,
                0.011953539215028286,
                -0.0638648197054863,
                0.08218218386173248,
                -0.02439902350306511,
                -0.01219068095088005,
                -0.03742210194468498,
                0.054733097553253174,
                0.013213391415774822,
                0.06780403852462769,
                0.021678229793906212,
                -0.010688730515539646,
                0.07518138736486435,
                0.04035443067550659,
                -5.4404074035119265e-05,
                -0.009159890003502369,
                0.046551790088415146,
                -0.024371610954403877,
                -0.07040528208017349,
                0.003340719034895301,
                0.07399862259626389,
                -0.0015657423064112663,
                0.011030150577425957,
                0.02082032337784767,
                0.011406750418245792,
                0.0078102280385792255,
                -0.02440479025244713,
                0.03359708562493324,
                -0.08814269304275513,
                0.004170984495431185,
                -0.04246806725859642,
                0.016576826572418213,
                0.06168888881802559,
                0.0012355489889159799,
                0.02683360129594803,
                -0.04413967579603195,
                -0.021529510617256165,
                -0.006615367252379656,
                -0.00824085995554924,
                0.03957407549023628,
                0.006797334644943476,
                0.006077668629586697,
                0.02603176049888134,
                -0.00022407545475289226,
                -0.01757350191473961,
                0.032937921583652496,
                0.0646192878484726,
                0.07153243571519852,
                0.015531091019511223,
                -0.008401601575314999,
                -0.01855362579226494,
                -0.07908835262060165,
                -0.014603656716644764,
                0.012662987224757671,
                0.030837049707770348,
                -0.019298970699310303,
                0.0389050617814064,
                -0.023667331784963608,
                -0.007558425422757864,
                -0.026800405234098434,
                -0.05751717835664749,
                -0.03482840582728386,
                0.03181865066289902,
                -0.0075952461920678616,
                0.012477843090891838,
                0.01207929290831089,
                0.05161743611097336,
                -0.0023852402810007334,
                -0.0008991406648419797,
                0.022675419226288795,
                0.07062560319900513,
                -0.03154074400663376,
                -0.026247220113873482,
                0.06633543968200684,
                0.06407544761896133,
                -0.03118143416941166,
                0.023221859708428383,
                -0.041389670222997665,
                -0.03516245260834694,
                0.017068684101104736,
                -0.002875568112358451,
                0.00850602611899376,
                -0.01821357011795044,
                -0.0297956895083189,
                -0.011917020194232464,
                -0.02563583478331566,
                -0.031658388674259186,
                0.025224406272172928,
                -0.018276100978255272,
                0.04051089659333229,
                0.028748327866196632,
                -0.03314866125583649,
                0.020344838500022888,
                0.017304960638284683,
                0.03856213390827179,
                -0.046346988528966904,
                0.05368722230195999,
                -0.05602295324206352,
                -0.04209055379033089,
                0.0125676728785038,
                -0.0056054722517728806,
                -0.05923772230744362,
                0.02325119823217392,
                0.0061490293592214584,
                -0.03907163813710213,
                -0.047289323061704636,
                -0.04483875259757042,
                -0.03239912912249565,
                -0.043475836515426636,
                0.03993819281458855,
                -0.0291752927005291,
                -0.03595837205648422,
                -0.0155812231823802,
                -0.00483619375154376,
                0.014377281069755554,
                0.015477616339921951,
                0.0016025248914957047,
                0.07412384450435638,
                0.0016479679616168141,
                0.01604037918150425,
                -0.008374336175620556,
                -0.029803892597556114,
                0.01212028507143259,
                -0.04239114746451378,
                0.029233239591121674,
                -0.07431305944919586,
                0.0008267689263448119,
                0.02811368927359581,
                -0.03940470516681671,
                0.10073959827423096,
                0.038658399134874344,
                0.0018800770631060004,
                -0.022430064156651497,
                0.0504511334002018,
                -0.01931973733007908,
                0.002830668818205595,
                0.016043463721871376,
                -0.0020090362522751093,
                0.018489258363842964,
                -0.05617472529411316,
                -0.02348252385854721,
                -0.0515265092253685,
                -0.07140956819057465,
                -0.024033518508076668,
                0.01830650120973587,
                0.005704984534531832,
                0.019245529547333717,
                0.060848914086818695,
                0.0034052159171551466,
                0.003225137246772647,
                -0.010178404860198498,
                0.013205400668084621,
                -0.02130267210304737,
                -0.0670371726155281,
                0.024306897073984146,
                -0.042300619184970856,
                0.022448673844337463,
                0.012927516363561153,
                -0.023802852258086205,
                -0.022509464994072914,
                -0.024842480197548866,
                -0.053225643932819366,
                -0.006899768486618996,
                0.017261885106563568,
                0.0027502686716616154,
                -0.0436946377158165,
                -0.041536591947078705,
                0.01866256445646286,
                0.08372093737125397,
                -0.03547842055559158,
                -0.019702231511473656,
                -0.027594897896051407,
                -0.021934309974312782,
                0.012891982682049274,
                0.03995689004659653,
                0.02529643476009369,
                -0.035139426589012146,
                0.03442062810063362,
                0.04843541979789734,
                0.08458895236253738,
                -0.06961893290281296,
                -0.0013928133994340897,
                -0.02316320315003395,
                0.040249209851026535,
                -0.019993823021650314,
                0.004166920203715563,
                -0.006454544607549906,
                0.012814111076295376,
                -0.054779380559921265,
                -0.016123326495289803,
                0.06850902736186981,
                0.044300202280282974,
                0.04834574833512306,
                -0.04606233909726143,
                -0.010968529619276524,
                -0.005446515511721373,
                -0.04428281635046005,
                -0.022152641788125038,
                0.004672633484005928,
                0.0005564303719438612,
                -0.012997887097299099,
                0.0272374264895916,
                -0.08402540534734726,
                0.036827798932790756,
                -0.050111908465623856,
                -0.03625723347067833,
                0.007264283020049334,
                -0.003598196664825082,
                -0.003030655439943075,
                0.013315711170434952,
                0.032879747450351715,
                -0.048062119632959366,
                0.03959912061691284,
                -0.01058849785476923,
                -0.024760359898209572,
                -0.10554618388414383,
                0.04901830479502678,
                -0.012032439932227135,
                -0.06773223727941513,
                -0.0023487976286560297,
                -0.06509171426296234,
                0.008577360771596432,
                -0.026833053678274155,
                0.014860829338431358,
                -0.01999139040708542,
                0.026181504130363464,
                -0.015616021119058132,
                0.05693246051669121,
                -0.005856746342033148,
                -0.000495529267936945,
                0.04282766953110695,
                0.018133217468857765,
                -0.030874596908688545,
                0.019922630861401558,
                -0.04353279620409012,
                0.05537541210651398,
                0.11166569590568542,
                -0.02526026964187622,
                0.03654022142291069,
                -0.04693358391523361,
                -0.004817701410502195,
                0.024185076355934143,
                -0.01730794459581375,
                -0.032382093369960785,
                -0.030096840113401413,
                0.11791975051164627,
                0.028573740273714066,
                -0.030646979808807373,
                0.032024823129177094,
                0.02984771318733692,
                0.004219170194119215,
                0.04116826131939888,
                0.05533786863088608,
                0.02301829494535923,
                0.034823160618543625,
                -0.030071917921304703,
                0.02592838555574417,
                0.0019613855984061956,
                -0.08858346939086914,
                -0.010400033555924892,
                0.015533831901848316,
                -0.00046403438318520784,
                0.04300398752093315,
                -0.04667079448699951,
                -0.006922281812876463,
                -0.025771817192435265,
                -0.038392458111047745,
                -0.06479229778051376,
                0.01115871500223875,
                0.03138384968042374,
                0.007476281374692917,
                0.02100955694913864,
                -0.06670267879962921,
                -0.013808962889015675,
                0.0424359068274498,
                -0.016611114144325256,
                0.026466775685548782,
                0.02049609273672104,
                -0.0047799670137465,
                -0.046228330582380295,
                0.014718745835125446,
                -0.002100131241604686,
                0.017476839944720268,
                -0.011575909331440926,
                -0.02854928933084011,
                0.0009292716858908534,
                0.02323964796960354,
                -0.0025565132964402437,
                -0.021031547337770462,
                0.014226065948605537,
                0.03181546553969383,
                -0.03129485994577408,
                -0.008826624602079391,
                -0.04062199965119362,
                -0.025549042969942093,
                -0.03222279995679855,
                0.04366809129714966,
                0.035417716950178146,
                -0.006646865513175726,
                -0.035373490303754807,
                0.060234520584344864,
                0.01276362594217062,
                0.011267012916505337,
                0.012683480978012085,
                -0.021612271666526794,
                -0.06369534134864807,
                0.04588017240166664,
                0.03427992761135101,
                0.048146530985832214,
                0.0011686790967360139,
                0.011017395183444023,
                0.030453426763415337,
                0.023731974884867668,
                -0.046368926763534546,
                0.016936754807829857,
                -0.014932449907064438,
                0.04023968428373337,
                0.015638040378689766,
                -0.010423567146062851,
                0.005638856906443834,
                -0.03202534094452858,
                -0.008415194228291512,
                0.05058365315198898,
                -0.001932910643517971,
                0.03953290730714798,
                0.053273964673280716,
                0.005161866545677185,
                -0.041088346391916275,
                -0.06353594362735748,
                -0.07660428434610367,
                0.021424226462841034,
                0.019609881564974785,
                0.031219057738780975,
                0.009055830538272858,
                -0.006680809427052736,
                0.03967481106519699,
                -0.002712576650083065,
                -0.01464816089719534,
                -0.04843569174408913,
                0.02596385031938553,
                -0.007599406875669956,
                -0.06276730448007584,
                -0.10525283962488174,
                0.02898259274661541,
                0.0016756249824538827,
                0.006624056492000818,
                0.06780295819044113,
                0.03716706112027168,
                0.046013690531253815,
                0.054170385003089905,
                -0.07664009183645248,
                0.05582140013575554,
                0.02510555274784565,
                -0.00230437726713717,
                0.015002379193902016,
                -0.03971424326300621,
                -0.01959715597331524,
                -0.02512499876320362,
                0.03779947757720947,
                0.045115888118743896,
                -0.02533830888569355,
                0.03570937737822533,
                0.012857233174145222,
                0.020410697907209396,
                -0.02922418899834156,
                -4.476416200413677e-33,
                -0.024035749956965446,
                -0.027948493137955666,
                0.007127562537789345,
                0.0186300165951252,
                -0.037514448165893555,
                0.004702471196651459,
                0.020072070881724358,
                -0.010584826581180096,
                -0.016305388882756233,
                -0.017556272447109222,
                -0.025783957913517952,
                0.013430187478661537,
                0.00853517185896635,
                0.024637825787067413,
                0.049945324659347534,
                -0.08398360759019852,
                -0.04696493223309517,
                -0.02427019365131855,
                0.04750492051243782,
                -0.06927479058504105,
                -0.008994875475764275,
                0.031632859259843826,
                0.05844314768910408,
                0.05659117177128792,
                -0.00016605068231001496,
                0.02227603644132614,
                0.014533990994095802,
                0.02480270527303219,
                0.03710728511214256,
                0.010991506278514862,
                0.04145999252796173,
                -0.07711653411388397,
                0.023241601884365082,
                -0.0495922677218914,
                0.0007261025020852685,
                0.03844691067934036,
                0.03377699851989746,
                -0.041353169828653336,
                -0.0015214812010526657,
                0.03344086930155754,
                0.01764635741710663,
                0.041106704622507095,
                0.02198326587677002,
                -0.0013875121949240565,
                -0.021907296031713486,
                -0.026450689882040024,
                -0.02178940735757351,
                0.02515796571969986,
                0.03776346892118454,
                -0.0420810729265213,
                -0.015988007187843323,
                -0.016438646242022514,
                0.0022972747683525085,
                0.09729259461164474,
                0.06030432507395744,
                0.02905394323170185,
                -0.021960308775305748,
                -0.024886375293135643,
                -0.01887962594628334,
                -0.08054552972316742,
                -0.05387090891599655,
                -0.041961200535297394,
                0.008879212662577629,
                0.025937194004654884,
                -0.03856363892555237,
                0.01828557439148426,
                -0.056246206164360046,
                -0.0002048689784714952,
                -0.014627398923039436,
                -0.007329951040446758,
                0.004648042842745781,
                0.011810347437858582,
                -0.07390906661748886,
                -0.04854368790984154,
                -0.02649862691760063,
                0.03135697916150093,
                0.0376591831445694,
                -0.0027323258109390736,
                0.10896648466587067,
                -0.0027589094825088978,
                0.008312051184475422,
                0.01289816852658987,
                0.011833542957901955,
                -0.02641933783888817,
                0.0162226390093565,
                -0.03427467867732048,
                -0.009813787415623665,
                0.029549796134233475,
                0.008717380464076996,
                -0.03315802663564682,
                0.049698006361722946,
                0.029344845563173294,
                0.017731016501784325,
                -0.0020170477218925953,
                -0.028538379818201065,
                -0.06887318193912506,
                0.0413079597055912,
                0.037357114255428314,
                0.04715167358517647,
                0.03757152706384659,
                -0.034320857375860214,
                0.03553053364157677,
                -0.0664750263094902,
                0.019580785185098648,
                -0.010682376101613045,
                0.012855499051511288,
                -0.003544475417584181,
                -0.005231927148997784,
                0.038778454065322876,
                0.023583756759762764,
                0.019901851192116737,
                -0.003830584930256009,
                0.06536418944597244,
                0.007882404141128063,
                -0.04411192238330841,
                -0.004110295325517654,
                -0.03059970587491989,
                -0.009588110260665417,
                0.004657743964344263,
                -0.03577787056565285,
                0.06329729408025742,
                0.010863278992474079,
                0.0200175940990448,
                0.0029620046261698008,
                0.021359993144869804,
                0.03639846295118332,
                0.03590533137321472,
                0.02869947999715805,
                0.027469243854284286,
                -0.037259671837091446,
                0.013309246860444546,
                0.019923588261008263,
                2.137463610552004e-07,
                0.030243204906582832,
                -0.038151759654283524,
                0.029351122677326202,
                0.03190126642584801,
                0.013556018471717834,
                0.0058905864134430885,
                -0.03534578159451485,
                0.031112227588891983,
                -0.008822060190141201,
                0.018369074910879135,
                0.06835675984621048,
                -0.026993360370397568,
                -0.07363659888505936,
                -0.0032043885439634323,
                0.002243233844637871,
                -0.04677082970738411,
                0.02205124869942665,
                -0.030886206775903702,
                0.00791860744357109,
                -0.011741413734853268,
                -0.0282528605312109,
                -0.019795019179582596,
                0.006853026337921619,
                -0.03041805326938629,
                0.03506004810333252,
                0.011634863913059235,
                0.02126852236688137,
                -0.03766011446714401,
                0.03153153881430626,
                0.0029315731953829527,
                0.09027861803770065,
                0.03265979886054993,
                -0.0012666707625612617,
                0.0075888121500611305,
                -0.023694969713687897,
                -0.01824290119111538,
                0.018427619710564613,
                0.030188679695129395,
                0.028555743396282196,
                0.01393912173807621,
                -0.00579883623868227,
                -0.0247578676789999,
                -0.002628222107887268,
                -0.0004733818059321493,
                -0.009672111831605434,
                0.016145698726177216,
                -0.011174214072525501,
                -0.001321870950050652,
                0.027088753879070282,
                -0.012541617266833782,
                -0.0034172991290688515,
                0.005340099800378084,
                0.027639558538794518,
                0.044665779918432236,
                -0.009828904643654823,
                -0.04722575098276138,
                0.03741644695401192,
                0.022992786020040512,
                -0.019900621846318245,
                0.06750109046697617,
                -0.007330362685024738,
                0.058854348957538605,
                -0.010560738854110241,
                0.028190623968839645,
                0.03280559927225113,
                -0.02569829486310482,
                -0.002883704612031579,
                1.296185363433711e-34,
                -0.005557299125939608,
                -0.05600988119840622,
                -0.026470743119716644,
                -0.0657656192779541,
                0.02626178413629532,
                -0.0008856692584231496,
                -0.029550982639193535,
                0.034803424030542374,
                0.006457990501075983,
                -0.04601985216140747,
                -0.019556095823645592,
                0.0419565886259079,
                0.04730454459786415,
                -0.022515742108225822,
                -0.03402497619390488,
                0.040403757244348526,
                0.008216275833547115,
                -0.016983995214104652,
                -0.017542505636811256,
                -0.02107168361544609,
                -0.021878600120544434,
                -0.061181411147117615,
                -0.02030962146818638,
                0.03809627890586853,
                0.023103637620806694,
                0.009349438361823559,
                -0.04414169490337372,
                -0.002963107777759433,
                0.038686733692884445,
                -0.02803332544863224,
                -0.011320612393319607,
                0.01373803149908781,
                0.015233048237860203,
                0.011295721866190434,
                0.00919998250901699,
                -0.02361355349421501,
                -0.04830961674451828,
                0.03740229830145836,
                -0.02882433868944645,
                0.043109867721796036,
                0.038376178592443466,
                0.06618651747703552,
                -0.03428484871983528,
                0.040595490485429764,
                -0.01894577592611313,
                1.547119040878897e-06,
                0.024499287828803062,
                -0.03112344816327095,
                -0.035274043679237366,
                -0.06706281006336212,
                0.0023817187175154686,
                0.08417439460754395,
                0.038115859031677246,
                0.022243745625019073,
                -0.016671814024448395,
                -0.03205528110265732,
                0.030847635120153427,
                -0.009824560955166817,
                -0.0065841833129525185,
                -0.008867304772138596,
                0.009047087281942368,
                -0.01297913957387209,
                -0.004643161781132221,
                -0.027668287977576256,
                -0.0247583519667387,
                -0.011788787320256233,
                -0.06256616860628128,
                0.004557508509606123,
                -0.06148212030529976,
                0.010756810195744038,
                -0.06338390707969666,
                -0.03781183063983917,
                0.0016083144582808018,
                -0.007129173260182142,
                -0.017169296741485596,
                0.07291921228170395,
                0.06399790942668915,
                -0.0054480028338730335,
                0.06312650442123413,
                -0.025203177705407143,
                -0.011807224713265896,
                0.013875685632228851,
                0.02712046541273594,
                -0.056554097682237625,
                0.025325607508420944,
                0.014691387303173542,
                0.03771356865763664,
                -0.04651850834488869,
                -0.022895673289895058,
                0.011717597022652626,
                0.03150342032313347,
                -0.10112854093313217,
                0.0933200940489769,
                -0.005678523797541857,
                -0.012037818320095539,
                0.034365374594926834,
                -0.016254233196377754,
                -0.04449442774057388,
                -0.01247213315218687,
                0.0008538624388165772,
                0.038759056478738785,
                -0.008587832562625408,
                0.01093664113432169,
                -0.021178070455789566,
                -0.0035415906459093094,
                -0.0626220852136612,
                0.0016260587144643068,
                0.08072858303785324,
                0.05744023248553276,
                -0.02832241728901863,
                -0.11252494156360626,
                0.013000311329960823,
                -0.03083096258342266,
                0.023063888773322105,
                0.014809302985668182,
                -0.019596794620156288,
                -0.05078994482755661,
                0.016402985900640488,
                -0.0011284910142421722,
                -0.052069101482629776,
                0.020826764404773712,
                -0.04303964227437973,
                -0.026723427698016167,
                -0.010434784926474094,
                0.020312802866101265,
                -0.0378241129219532,
                -0.06923092156648636,
                -0.033977508544921875,
                -0.04926978796720505,
                -0.02915365621447563,
                -0.035630546510219574,
                -0.03305304795503616,
                0.053017422556877136,
                -0.02342774160206318,
                0.008409887552261353,
                0.011530791409313679,
                0.021114349365234375,
                -0.005759159568697214,
                0.038140494376420975,
                -0.0018975838320329785,
                0.015066384337842464,
                0.014437148347496986,
                0.015246404334902763,
                -0.04057369381189346,
                0.0003614759480115026,
                0.013982056640088558,
                0.009519361890852451,
                -0.023601558059453964,
                0.034792687743902206,
                -0.06032668054103851,
                0.04098761826753616,
                0.0032799465116113424,
                0.01698651909828186,
                -0.005559248849749565,
                -0.0026504776906222105,
                -0.011967048048973083,
                -0.022870665416121483,
                -0.020297788083553314,
                0.03866681456565857,
                0.003947082441300154,
                -0.010386708192527294,
                -0.00907082948833704,
                -0.022432081401348114,
                -0.0010705327149480581,
                0.021689705550670624,
                0.09056609123945236,
                0.011857058852910995,
                0.09537483751773834,
                -0.025833120569586754,
                0.01863577961921692,
                0.05341145023703575,
                0.001376348896883428,
                0.0381595641374588,
                0.07703345268964767,
                0.02306569367647171,
                -0.07238364219665527,
                0.004236005712300539,
                0.0360230952501297,
                0.014321377500891685,
                -0.06356623768806458,
                -0.02594733238220215,
                -0.00767724821344018,
                -0.02184790000319481,
                -0.05649257451295853,
                0.0774964764714241,
                0.013557996600866318,
                -0.07577162235975266,
                0.0068083833903074265,
                -0.023215999826788902,
                0.026804663240909576,
                -0.018027542158961296,
                -0.021249638870358467,
                -0.09737174957990646,
                0.024835338816046715,
                0.024978285655379295,
                0.10422451049089432,
                0.014871601015329361,
                0.020411914214491844,
                0.012674513272941113,
                -0.0357167087495327,
                0.046040575951337814,
                0.03632531315088272,
                0.012338955886662006,
                -0.04848909750580788,
                0.08132608234882355,
                -0.03264450654387474,
                -0.007002434227615595,
                -0.022487767040729523,
                0.012674587778747082,
                -0.013154009357094765,
                -0.004756316542625427,
                -0.0021079452708363533,
                0.02237716317176819,
                -0.05437313765287399,
                0.039811424911022186,
                0.013389983214437962,
                0.019184362143278122,
                0.016273843124508858,
                -0.031193392351269722,
                -0.03727426379919052,
                0.0794868990778923,
                0.06073981150984764,
                -0.01439727284014225,
                0.0014502879930660129,
                0.026964593678712845,
                -0.007072155363857746,
                -0.014282268472015858,
                -0.0024559847079217434,
                0.07185138761997223,
                -0.023261554539203644,
                -0.0026419595815241337,
                0.03419206663966179,
                -0.0019434731220826507,
                0.004092830698937178,
                -0.02294743061065674,
                0.02012503333389759,
                -0.0315224789083004,
                -0.016697121784090996,
                0.00713069923222065,
                -0.030235467478632927,
                0.008302019909024239,
                0.005723539274185896,
                0.017707305029034615,
                0.01939297467470169,
                -0.013452794402837753,
                -0.009319663047790527,
                0.02994273044168949,
                0.02654537931084633,
                0.07107539474964142,
                -0.0005070097977295518,
                -0.0016093933954834938,
                -0.058343444019556046,
                -0.046009618788957596,
                0.012390975840389729,
                0.007033569738268852,
                0.0005707979435101151,
                0.014337580651044846,
                0.04965746030211449,
                0.01883121393620968,
                -0.031790945678949356,
                -0.002460772404447198,
                -0.046340640634298325,
                0.0005532162031158805,
                0.02364296093583107,
                0.05916612967848778,
                -0.047351956367492676,
                -0.009301366284489632,
                -0.09163645654916763,
                0.05185213312506676,
                0.033453233540058136,
                0.05898129194974899,
                -0.035504065454006195,
                0.010159539990127087,
                0.025315383449196815,
                0.022858640179038048,
                0.03116966038942337,
                -0.019364414736628532,
                0.028756387531757355,
                -0.07685450464487076,
                0.0017798711778596044,
                0.05387112498283386,
                0.022337138652801514,
                -0.00881208572536707,
                -0.006963292136788368,
                -0.00987712200731039,
                -0.0016984135145321488,
                0.003977648448199034,
                -0.008828358724713326,
                0.022838972508907318,
                -0.043547630310058594,
                0.00801286194473505,
                0.0603056475520134,
                -0.04287850484251976,
                0.02026277594268322,
                0.03979958966374397,
                -0.006298481952399015,
                0.007977964356541634,
                0.027958249673247337,
                -0.002380890306085348,
                -0.013939741998910904,
                -0.05375666543841362,
                -0.0726526752114296,
                -0.015911797061562538,
                0.013412164524197578,
                -0.032250821590423584,
                -0.020008722320199013,
                0.04647877812385559,
                -0.026323895901441574,
                -0.04768478497862816,
                -0.02556782215833664,
                -0.08776115626096725,
                -0.016925273463129997,
                -0.09694072604179382,
                0.05003594234585762,
                -0.031902410089969635,
                -0.01753591187298298,
                0.03676214814186096,
                -0.002321826061233878,
                0.037191227078437805,
                -0.010675098747015,
                0.005037783645093441,
                0.027929913252592087,
                -0.012917401269078255,
                0.045129746198654175,
                -0.010192503221333027,
                0.0302942655980587,
                -0.03179753199219704,
                -0.010320315137505531,
                -0.03053181804716587,
                -0.02575070969760418,
                0.03380366787314415,
                -0.03741270303726196,
                -0.03561561554670334,
                -0.042007774114608765,
                0.04095672443509102,
                0.031199192628264427,
                -0.004833576735109091,
                -0.035401783883571625,
                -0.06170929968357086,
                -0.03615330904722214,
                -0.08931438624858856,
                -0.00690733827650547,
                -0.0805719643831253,
                -0.041589558124542236,
                -0.008175941184163094,
                -0.022240303456783295,
                0.0226670540869236,
                0.027406033128499985,
                -0.011454114690423012,
                -0.009635587222874165,
                0.024769239127635956,
                -0.002173405373468995,
                -0.037561796605587006,
                -0.04345979169011116,
                -0.01757264882326126,
                0.0470861978828907,
                -0.009821588173508644,
                0.06816817075014114,
                0.048668473958969116,
                -0.05483291670680046,
                0.009837636724114418,
                0.048761650919914246,
                0.048756666481494904,
                0.017844635993242264,
                -0.040953800082206726,
                0.014478839002549648,
                -0.04551819711923599,
                -0.014671988785266876,
                0.0329224169254303,
                0.0163858812302351,
                -0.11140806972980499,
                -0.04637271165847778,
                -0.005523255094885826,
                -0.009391513653099537,
                0.014083133079111576,
                -0.01775304228067398,
                0.010885195806622505,
                0.004409444984048605,
                0.015370821580290794,
                0.007058404851704836,
                0.01439159456640482,
                -0.01794818788766861,
                0.018761206418275833,
                -0.0010638374369591475,
                -0.0029930071905255318,
                0.0021038828417658806,
                -0.009257056750357151,
                -0.007289314176887274,
                -0.06275948137044907,
                -0.028685685247182846,
                -0.02360176481306553,
                0.07371117174625397,
                0.003094753948971629,
                0.10770955681800842,
                -0.017871031537652016,
                0.022764114663004875,
                0.0017774618463590741,
                -0.0057362718507647514,
                -0.018267950043082237,
                -0.04896346479654312,
                0.044537078589200974,
                0.02075531706213951,
                -0.00409046420827508,
                0.024654652923345566,
                0.05396570265293121,
                -0.023098276928067207,
                -0.00892994087189436,
                0.04050112143158913,
                -0.02911333180963993,
                -0.03131656348705292,
                0.02290407568216324,
                0.053642164915800095,
                0.035075683146715164,
                0.017700733616948128,
                0.03908957168459892,
                0.021936768665909767,
                -0.05841471254825592,
                0.08392881602048874,
                0.020760469138622284,
                0.00643181474879384,
                0.02885068580508232,
                -0.022520657628774643,
                0.06912398338317871,
                0.03875890001654625,
                0.08111432194709778,
                0.022562606260180473,
                -0.031120415776968002,
                0.010685241781175137,
                0.026420071721076965,
                -0.0008697308367118239,
                -0.043373022228479385,
                0.032551299780607224,
                0.01955418847501278,
                -0.026798339560627937,
                0.032464154064655304,
                0.0017885363195091486,
                0.020971963182091713,
                0.031259555369615555,
                -0.007239161524921656,
                0.0032352323178201914,
                -0.009889155626296997,
                -0.012968243099749088,
                0.03783382475376129,
                0.014699149876832962,
                -0.006357251666486263,
                0.05422847345471382,
                -0.037083569914102554,
                0.04412836581468582,
                0.01210575271397829,
                0.005533936899155378,
                0.029734857380390167,
                -0.011453002691268921,
                0.06023120880126953,
                0.042296141386032104,
                0.06442326307296753,
                0.045835427939891815,
                -0.06313802301883698,
                -0.03369492292404175,
                0.08881212025880814,
                -0.03964642435312271,
                -0.073529914021492,
                0.0597524493932724,
                -0.02270382270216942,
                0.05179110914468765,
                -0.03383221849799156,
                -0.07357673346996307,
                -0.0036341985687613487,
                -0.09863200038671494,
                0.06186417117714882,
                -0.002741052070632577,
                -0.014100675471127033,
                0.06615250557661057,
                0.024998405948281288,
                0.02089734561741352,
                0.05654371902346611,
                -0.01985919289290905,
                -0.014785409905016422,
                0.08943523466587067,
                -0.04835699871182442,
                0.017763637006282806,
                -0.028353912755846977,
                0.03149587661027908,
                -0.004288376774638891,
                -0.011570969596505165,
                0.02839423529803753,
                0.05115676671266556,
                0.028316659852862358,
                -0.05014590919017792,
                0.07022440433502197,
                0.0016555867623537779,
                0.04133858159184456,
                -0.017247067764401436,
                -0.0037792734801769257,
                -0.0232539102435112,
                -0.014413128606975079,
                -0.002946040825918317,
                -0.0020341689232736826,
                0.029090018942952156,
                0.01490875706076622,
                -0.05421879515051842,
                -0.02722882479429245,
                0.0196845605969429,
                0.0014344938099384308,
                0.029494620859622955,
                0.029869746416807175,
                0.014789395965635777,
                0.03712299093604088,
                -0.07559957355260849,
                0.03805972635746002,
                -0.020011205226182938,
                0.008703014813363552,
                -0.04416905343532562,
                -0.006310114171355963,
                0.021153733134269714,
                -0.0685398057103157,
                0.03699786216020584,
                -0.0007789337541908026,
                0.017558030784130096,
                0.0299864262342453,
                0.008937783539295197,
                -0.013810303062200546,
                -0.01682654395699501,
                -0.031079692766070366,
                -0.03505344316363335,
                -0.0944352000951767,
                -0.0009887900669127703,
                0.059549592435359955,
                -0.036488715559244156,
                -0.04424750432372093,
                -0.027986353263258934,
                -0.0030230244155973196,
                0.031860899180173874,
                -0.011405635625123978,
                -0.03737211972475052,
                0.0039586275815963745,
                0.03520604223012924,
                -0.052638161927461624,
                0.009788909927010536,
                -0.004500552546232939,
                -0.0354560911655426,
                0.029099633917212486,
                -0.0948006883263588,
                -0.016319289803504944,
                -0.06815341860055923,
                0.03424984961748123,
                -0.036062415689229965,
                0.018044037744402885,
                0.09761318564414978,
                0.03621666505932808,
                0.016010330989956856,
                0.03343714773654938,
                -0.058526765555143356,
                0.056558649986982346,
                0.004823822528123856,
                -0.04020155966281891,
                0.016230860725045204,
                -0.016384651884436607,
                -0.03691967949271202,
                0.01465148851275444,
                -0.011861015111207962,
                0.05312380567193031,
                0.0018218301702290773,
                -0.029190748929977417,
                -0.01837015710771084,
                0.03500709310173988,
                -0.0019617367070168257,
                -4.523001041536764e-33,
                0.016674412414431572,
                -0.024748438969254494,
                0.030219879001379013,
                -0.010405875742435455,
                -0.05185970664024353,
                -0.008044218644499779,
                0.02982163242995739,
                0.027628837153315544,
                0.019248150289058685,
                -0.0037769426126033068,
                -0.018329288810491562,
                -0.028697039932012558,
                0.0074716415256261826,
                -0.003097672713920474,
                0.037423860281705856,
                0.02947206236422062,
                0.05515589937567711,
                -0.008005350828170776,
                -0.022029655054211617,
                -0.018180502578616142,
                -0.09235168993473053,
                0.028825871646404266,
                0.02342134714126587,
                -0.028132516890764236,
                -0.0059950887225568295,
                -0.018291182816028595,
                -0.002972153015434742,
                0.01321314089000225,
                0.01976015418767929,
                -0.004597173072397709,
                0.02440100908279419,
                -0.011204048059880733,
                -0.005292671266943216,
                -0.06299769878387451,
                0.0008092665812000632,
                0.04682588577270508,
                0.0016420766478404403,
                0.023792879655957222,
                0.05027399957180023,
                -0.012991360388696194,
                0.01689993590116501,
                0.02095729485154152,
                -0.025140948593616486,
                -0.02756551094353199,
                0.05649895966053009,
                0.011749951168894768,
                0.013538875617086887,
                -0.03391817584633827,
                0.012709473259747028,
                0.05511155351996422,
                -0.0350327342748642,
                -0.02245316468179226,
                -0.03506993129849434,
                -0.040366124361753464,
                0.0071911076083779335,
                0.06157049536705017,
                -0.008043824695050716,
                -0.03950326889753342,
                0.022095542401075363,
                -0.021904299035668373,
                0.0029175023082643747,
                -0.017645807936787605,
                0.008289745077490807,
                -0.01698972098529339,
                -0.03583090752363205,
                0.041130341589450836,
                -0.037948641926050186,
                0.010707261972129345,
                0.021210364997386932,
                -0.04436977207660675,
                -0.052308786660432816,
                0.0844523012638092,
                -0.05111830309033394,
                0.02574734576046467,
                -0.022700466215610504,
                0.04120895266532898,
                0.03509056195616722,
                -0.009467191994190216,
                0.025271732360124588,
                -0.04761776700615883,
                -0.0786081925034523,
                0.0011521826963871717,
                -0.033470455557107925,
                -0.01518572960048914,
                0.0483577661216259,
                0.01089000515639782,
                -0.013307629153132439,
                -0.0020986609160900116,
                0.024356268346309662,
                -0.004301622975617647,
                -0.006930077914148569,
                0.04987601190805435,
                -0.0136793851852417,
                -0.013923529535531998,
                -0.034463029354810715,
                -0.0008056333754211664,
                0.00750890513882041,
                -0.006693660747259855,
                0.01270497776567936,
                -0.013358633033931255,
                -0.04460274428129196,
                0.011186017654836178,
                -0.031137723475694656,
                -0.021771365776658058,
                -0.044670190662145615,
                0.04905439913272858,
                0.012976731173694134,
                -0.01244510617107153,
                0.005027718376368284,
                0.010493640787899494,
                0.03121269680559635,
                0.005096950102597475,
                0.01275306474417448,
                0.017729807645082474,
                0.005766612943261862,
                -0.00582500034943223,
                0.01636428013443947,
                -0.008556422777473927,
                -0.050770197063684464,
                -0.04277249798178673,
                0.08286690711975098,
                0.01586158014833927,
                -0.03530968353152275,
                -0.05869666114449501,
                -0.024184631183743477,
                0.019583240151405334,
                0.05476898327469826,
                -0.07748696208000183,
                -0.01684967614710331,
                -0.03049924224615097,
                -0.020774781703948975,
                -0.055781830102205276,
                2.083663872554098e-07,
                0.016965169459581375,
                0.003438859013840556,
                -0.001786776352673769,
                0.03649160638451576,
                -0.0005602915189228952,
                -0.038669403642416,
                -0.01969100534915924,
                0.0025306427851319313,
                -0.0024362877011299133,
                -0.012178470380604267,
                0.03914736583828926,
                0.02114861272275448,
                0.007035866379737854,
                -0.015864599496126175,
                -0.03289472311735153,
                -0.08752595633268356,
                0.010288194753229618,
                -0.07593890279531479,
                -0.005211867392063141,
                -0.011513391509652138,
                -0.012223427183926105,
                0.04155059531331062,
                0.04232470691204071,
                -0.021378668025135994,
                0.030316771939396858,
                0.001963734859600663,
                0.010728736408054829,
                -0.04604124277830124,
                0.045981019735336304,
                0.0574427954852581,
                -0.029132166877388954,
                -0.003197853686287999,
                -0.02636859007179737,
                0.02568398416042328,
                0.01621483825147152,
                0.011666919104754925,
                -0.0009427801705896854,
                0.010367403738200665,
                0.018334414809942245,
                0.031042613089084625,
                0.03279455006122589,
                0.012881660833954811,
                -0.003238856326788664,
                -0.007819275371730328,
                -0.023156121373176575,
                -0.0679565966129303,
                -0.02467111498117447,
                -0.055380113422870636,
                -0.024500276893377304,
                0.0013861634070053697,
                -0.004647265654057264,
                0.018342383205890656,
                0.03314432129263878,
                0.0004989385488443077,
                0.035933464765548706,
                -0.034891001880168915,
                -0.0027981074526906013,
                0.03392866253852844,
                0.03633573651313782,
                0.04775676876306534,
                -0.027953123673796654,
                -0.0094924196600914,
                0.010626680217683315,
                -0.039137933403253555,
                -0.0019098049961030483,
                -0.04314224421977997,
                -0.029366614297032356,
                7.192376979876364e-35,
                0.019691389054059982,
                -0.0036387706641107798,
                -0.008716619573533535,
                0.0413183756172657,
                0.006245910190045834,
                -0.03804478049278259,
                -0.02667204476892948,
                -0.03301841393113136,
                0.026705656200647354,
                0.06048380583524704,
                -0.007710595615208149,
                -0.020260389894247055,
                0.031709685921669006,
                -0.012038429267704487,
                -0.024893520399928093,
                0.032604578882455826,
                -0.03755323961377144,
                -0.0066714114509522915,
                -0.002958951285108924,
                -0.01468800287693739,
                -0.002505481243133545,
                -0.0010247445898130536,
                -0.018195446580648422,
                0.02287405915558338,
                0.06571679562330246,
                0.019812138751149178,
                0.03518246114253998,
                0.029384000226855278,
                0.041700221598148346,
                -0.04125101864337921,
                0.02588169276714325,
                -0.03061424382030964,
                0.0037916286382824183,
                -0.011670849286019802,
                0.0033374829217791557,
                -0.039798054844141006,
                -0.005554927513003349,
                0.020824328064918518,
                0.05008560046553612,
                -0.0001365917851217091,
                -0.07879572361707687,
                0.01363113708794117,
                -0.0011935706716030836,
                -0.017484288662672043,
                -0.0885695144534111,
                1.3368011195780127e-06,
                -0.029022131115198135,
                -0.043289221823215485,
                -0.015387147665023804,
                -0.03374858573079109,
                0.045893386006355286,
                -0.002796517452225089,
                0.008578014560043812,
                -0.013620227575302124,
                0.025955459102988243,
                -0.019967978820204735,
                -0.10040382295846939,
                -0.034829165786504745,
                0.021873464807868004,
                0.007985200732946396,
                0.01533939316868782,
                0.006641313433647156,
                -0.028881611302495003,
                0.013255853205919266,
                -0.042927078902721405,
                -0.06571906805038452,
                -0.0344952791929245,
                -0.005936046130955219,
                -0.014120080508291721,
                -0.008751991204917431,
                -0.03592443838715553,
                -0.022788280621170998,
                0.01341231632977724,
                -0.02649180218577385,
                -0.02829114906489849,
                0.05294695124030113,
                0.031327731907367706,
                0.06877662241458893,
                -0.008322501555085182,
                0.027193356305360794,
                -0.02043667808175087,
                0.0799974873661995,
                0.03374631702899933,
                0.005999875254929066,
                0.0278372410684824,
                0.027491681277751923,
                -0.048774413764476776,
                -0.011849368922412395,
                0.01547480933368206,
                -0.018860002979636192,
                0.02684478461742401,
                0.009147725999355316,
                0.039718467742204666,
                -0.0023519927635788918,
                -0.03132046014070511,
                0.05083731189370155,
                -0.03003527969121933,
                -0.01887410506606102,
                -6.788799510104582e-05,
                -0.019981930032372475,
                0.003191706957295537,
                0.08409842848777771,
                -0.015378625132143497,
                0.002724107587710023,
                -0.034745365381240845,
                0.048062436282634735,
                0.0035972062032669783,
                0.022045908495783806,
                0.08023874461650848,
                -0.012886732816696167,
                0.023635145276784897,
                -0.07376741617918015,
                -0.011085044592618942,
                -0.011229710653424263,
                -0.008377766236662865,
                -0.06076854467391968,
                0.05055477097630501,
                0.0024039053823798895,
                -0.034540265798568726,
                -0.017802463844418526,
                0.013794103637337685,
                -0.002972422633320093,
                0.015346810221672058,
                -0.07953765243291855,
                0.0380452461540699,
                0.012976093217730522,
                -0.023039240390062332,
                -0.07603027671575546,
                0.03417736664414406,
                0.012695830315351486,
                0.06319323927164078,
                0.03487914800643921,
                0.04500783234834671,
                0.024911167100071907,
                0.0037456429563462734,
                -0.014540381729602814,
                -0.029748721048235893,
                -0.010392853058874607,
                0.006681553088128567,
                0.006724541541188955,
                0.030326692387461662,
                -0.010729232802987099,
                -0.009527098387479782,
                0.011386550031602383,
                -0.02984686754643917,
                0.025780417025089264,
                0.05097167566418648,
                0.03289210796356201,
                -0.01473743561655283,
                -0.10366128385066986,
                0.034795816987752914,
                -0.008931731805205345,
                -0.011187954805791378,
                -0.02691846713423729,
                0.004040400963276625,
                -0.04692099988460541,
                -0.02636810578405857,
                -0.028948256745934486,
                -0.019163215532898903,
                0.029760465025901794,
                -0.016333123669028282,
                0.06828542053699493,
                -0.06592358648777008,
                -0.049582257866859436,
                0.009436200372874737,
                0.01137666404247284,
                0.014276054687798023,
                0.07657811790704727,
                -0.022170519456267357,
                0.001918368274345994,
                0.02667234279215336,
                0.017160512506961823,
                0.009867756627500057,
                -0.0041085779666900635,
                0.020553741604089737,
                -0.0255499966442585,
                0.022481931373476982,
                0.08795113861560822,
                0.05315301567316055,
                -0.008090981282293797,
                -0.021215641871094704,
                -0.035537928342819214,
                -0.04166274517774582,
                -0.042702335864305496,
                0.10498905926942825,
                0.05864856764674187,
                -0.09019690752029419,
                0.027427010238170624,
                0.030854597687721252,
                0.00493048457428813,
                -0.031185029074549675,
                -0.013417267240583897,
                -0.09911087900400162,
                0.04131770506501198,
                -0.038489386439323425,
                0.06171435862779617,
                0.06017575040459633,
                -0.002787482924759388,
                0.015562442131340504,
                0.00584752019494772,
                0.041649527847766876,
                -0.006799394730478525,
                0.03764655441045761,
                -0.008153464645147324,
                0.022119365632534027,
                -0.017183443531394005,
                0.044468775391578674,
                -0.06663885712623596,
                0.058516524732112885,
                -0.06153636425733566,
                -0.000643229519482702,
                0.005063388496637344,
                0.008700346574187279,
                0.017756862565875053,
                -0.02736011892557144,
                -0.010192261077463627,
                0.008893454447388649,
                0.0059616779908537865,
                -0.025700530037283897,
                -0.05908637121319771,
                0.03900294750928879,
                0.008052092976868153,
                -0.007246728520840406,
                0.03182626888155937,
                0.06302405893802643,
                0.009803518652915955,
                -0.0028851486276835203,
                -0.002422459190711379,
                0.007701591122895479,
                -0.002780959941446781,
                0.05072060972452164,
                -0.008353972807526588,
                0.018469655886292458,
                0.06066669151186943,
                -0.001313026063144207,
                0.08948815613985062,
                -0.03558012843132019,
                0.024850595742464066,
                -0.016463059931993484,
                -0.006766775622963905,
                0.03556448593735695,
                0.00510556111112237,
                -0.004411892965435982,
                0.03007865883409977,
                0.004187962040305138,
                0.0018354562344029546,
                0.026908043771982193,
                0.07803694158792496,
                0.013250203803181648,
                0.012072058394551277,
                0.028484297916293144,
                -0.04819898307323456,
                -0.013508391566574574,
                0.028319036588072777,
                0.0018359109526500106,
                0.005854784045368433,
                -0.013637378811836243,
                0.02045430801808834,
                -0.012548862025141716,
                0.0072080823592841625,
                -0.0079570934176445,
                -0.0468236468732357,
                0.01553428452461958,
                0.043214451521635056,
                0.010412579402327538,
                -0.000622941239271313,
                -0.026996221393346786,
                -0.06731235235929489,
                0.04383743926882744,
                -0.011621110141277313,
                0.03996744379401207,
                0.02991102822124958,
                -0.06987346708774567,
                -0.011379110626876354,
                0.0331001915037632,
                0.04467085003852844,
                -0.059355419129133224,
                0.06529074162244797,
                -0.052578315138816833,
                -0.002859676955267787,
                -0.028419621288776398,
                0.021200453862547874,
                0.002485188189893961,
                -0.003000172320753336,
                -0.0066658249124884605,
                0.016170214861631393,
                -0.004647881258279085,
                0.0023845622781664133,
                0.0754881277680397,
                -0.025360973551869392,
                0.03440902382135391,
                0.018359659239649773,
                -0.07967478781938553,
                -0.021314268931746483,
                0.044720716774463654,
                0.01104497816413641,
                -0.04280797764658928,
                0.005712224170565605,
                0.018534895032644272,
                -0.04957103356719017,
                0.0064298310317099094,
                -0.01359441690146923,
                -0.03311372548341751,
                0.001169114839285612,
                -0.02822648175060749,
                0.014361218549311161,
                0.06364279240369797,
                -0.022674573585391045,
                -0.0623156763613224,
                -0.020954100415110588,
                0.008683798834681511,
                -0.017125636339187622,
                -0.08103198558092117,
                0.04990672692656517,
                -0.029350774362683296,
                -0.029556412249803543,
                0.09148218482732773,
                0.00284309359267354,
                0.012320742011070251,
                -0.011083519086241722,
                -0.061401158571243286,
                0.05400722101330757,
                0.0003490680828690529,
                0.03153584897518158,
                -0.06426101177930832,
                0.04065767303109169,
                0.02644321136176586,
                -0.010436255484819412,
                -0.054147303104400635,
                -0.03968970105051994,
                0.11378729343414307,
                -0.019978361204266548,
                -0.03812219202518463,
                -0.031764738261699677,
                -0.02267192117869854,
                -0.013630584813654423,
                0.005289079621434212,
                -0.007437095511704683,
                -0.02452641911804676,
                0.0614679679274559,
                -0.07882314920425415,
                -0.028304647654294968,
                -0.07184333354234695,
                -0.08536392450332642,
                -0.022325046360492706,
                0.0021309824660420418,
                0.04845230653882027,
                -0.0030488925985991955,
                0.023274002596735954,
                -0.006668355781584978,
                0.009427642449736595,
                -0.028727993369102478,
                0.03274770826101303,
                -0.06707849353551865,
                -0.0112232631072402,
                0.05079532787203789,
                0.013764381408691406,
                0.07455044239759445,
                0.046320121735334396,
                -0.039876099675893784,
                0.033001936972141266,
                0.014466685242950916,
                0.0015921435551717877,
                -0.012252085842192173,
                -0.025593921542167664,
                0.0030842924024909735,
                -0.013372902758419514,
                -0.01660357229411602,
                -0.022511711344122887,
                0.06159021705389023,
                -0.0306567195802927,
                -0.08597682416439056,
                -0.04115065187215805,
                -0.10057462751865387,
                0.0749245434999466,
                0.010248488746583462,
                -0.02218199148774147,
                -0.025646086782217026,
                -0.012337286956608295,
                0.019697073847055435,
                -0.003427867079153657,
                0.006573992781341076,
                0.009517315775156021,
                -0.04967377707362175,
                -0.009848729707300663,
                -0.0243311058729887,
                0.09152417629957199,
                0.006339928600937128,
                0.029016772285103798,
                -0.05902668833732605,
                0.024100620299577713,
                0.043028105050325394,
                0.02709881402552128,
                0.07500272244215012,
                -0.04796746000647545,
                -0.015715744346380234,
                0.03562828525900841,
                0.013582509011030197,
                -0.03754163905978203,
                -0.0016462813364341855,
                0.027559794485569,
                -0.00644202483817935,
                -0.003410652279853821,
                -0.005389784928411245,
                0.01883445307612419,
                -0.028170490637421608,
                -0.0395842082798481,
                -0.03134627267718315,
                -0.02108198218047619,
                -0.04644372686743736,
                -0.021242881193757057,
                0.04350590705871582,
                0.038177791982889175,
                -0.042216043919324875,
                -0.013471635058522224,
                -0.02996993623673916,
                -0.06139030680060387,
                0.09838729351758957,
                -0.010148342698812485,
                0.02640894614160061,
                0.08085966110229492,
                -0.0782785564661026,
                -0.025196785107254982,
                -0.01391651201993227,
                0.029636651277542114,
                0.022884517908096313,
                -0.03914529085159302,
                -0.016867700964212418,
                0.011681093834340572,
                -0.014114017598330975,
                -0.049490295350551605,
                -0.0045716422609984875,
                0.004722593817859888,
                -0.013302831910550594,
                0.020520536229014397,
                0.03050713986158371,
                0.005626508500427008,
                0.05606146529316902,
                0.0013105019461363554,
                0.001274760696105659,
                -0.018486104905605316,
                -0.02097829431295395,
                0.03076966106891632,
                0.00553545169532299,
                -0.00528985820710659,
                0.03944310545921326,
                0.041448429226875305,
                0.007211335469037294,
                -0.05049755051732063,
                -0.017589453607797623,
                -0.02541307732462883,
                -0.02976107783615589,
                0.05809268727898598,
                0.03268517926335335,
                0.019461726769804955,
                0.03679535165429115,
                -0.04314916953444481,
                -0.01665310747921467,
                0.07692154496908188,
                -0.04860726743936539,
                0.003565437626093626,
                0.027976227924227715,
                -0.06340683251619339,
                0.017686080187559128,
                -0.025648416951298714,
                -0.027291297912597656,
                -0.033249806612730026,
                -0.07876548916101456,
                -0.05440119281411171,
                0.020831013098359108,
                -0.00015022020670585334,
                0.019548704847693443,
                0.028905296698212624,
                -0.03654710575938225,
                0.009135394357144833,
                0.06010260060429573,
                -0.04363977909088135,
                0.013895101845264435,
                -0.0032890604343265295,
                -0.03328031301498413,
                -0.012597543187439442,
                0.051669664680957794,
                -0.013928160071372986,
                -0.027010012418031693,
                0.0032735788263380527,
                0.04999915882945061,
                0.03326664865016937,
                0.0011739882174879313,
                -0.0002719730546232313,
                0.007984163239598274,
                0.032869983464479446,
                0.0019071453716605902,
                -0.0016562931705266237,
                -0.014582397416234016,
                -0.06339961290359497,
                -0.021826839074492455,
                -0.04800467565655708,
                0.033610787242650986,
                0.028642429038882256,
                -0.012751197442412376,
                -0.015741560608148575,
                0.06041451543569565,
                -0.03760005906224251,
                -0.020733769983053207,
                0.019228721037507057,
                -0.05886615067720413,
                0.026714418083429337,
                -0.036945801228284836,
                0.04041541740298271,
                -0.022812776267528534,
                0.04826705530285835,
                -0.0015825326554477215,
                0.02882852405309677,
                -0.0012931730598211288,
                -0.06767258048057556,
                -0.004536428488790989,
                -0.009707720018923283,
                -0.04783925786614418,
                0.017780756577849388,
                -0.04991059750318527,
                -0.004771584179252386,
                0.011004649102687836,
                -0.04286794736981392,
                0.029075803235173225,
                -0.021577414125204086,
                0.025887418538331985,
                0.01412420067936182,
                -0.0031886931974440813,
                0.005391579121351242,
                -0.006200083065778017,
                -0.040680088102817535,
                0.027840198948979378,
                -0.017827250063419342,
                -0.008986636064946651,
                -0.008870596066117287,
                -0.035153064876794815,
                -0.0054202601313591,
                0.013354778289794922,
                -0.012140365317463875,
                -0.030261272564530373,
                0.011957282200455666,
                -0.002968482207506895,
                -0.05393466353416443,
                -0.08470366150140762,
                0.04852183908224106,
                -0.017894620075821877,
                -0.023682400584220886,
                0.06797852367162704,
                0.06406459212303162,
                0.025111667811870575,
                0.038897931575775146,
                -0.038479965180158615,
                0.03264259919524193,
                0.03353102505207062,
                -0.026961345225572586,
                0.035228434950113297,
                0.027106162160634995,
                -0.02701650932431221,
                -0.025483839213848114,
                0.01832081563770771,
                0.01433036383241415,
                -0.01801753044128418,
                -0.04639887809753418,
                0.0007859744946472347,
                0.011276587843894958,
                -0.04902399331331253,
                -4.3889855019934075e-33,
                -0.010378075763583183,
                -0.0032484126277267933,
                0.05054308474063873,
                -0.08061279356479645,
                -0.049662020057439804,
                -0.010338678024709225,
                0.009363389573991299,
                0.039288271218538284,
                -0.049212709069252014,
                -0.028512243181467056,
                -0.02567589282989502,
                -0.027522031217813492,
                0.028954481706023216,
                0.03141225874423981,
                0.003043712582439184,
                -0.005677353590726852,
                0.03385721892118454,
                0.010865982621908188,
                -0.011556638404726982,
                -0.023700403049588203,
                -0.0787978395819664,
                0.016562379896640778,
                0.046257175505161285,
                0.024161839857697487,
                -0.0016856143483892083,
                -0.0055471728555858135,
                -0.03738268464803696,
                0.042838387191295624,
                0.06104328855872154,
                -0.00743041280657053,
                0.013130619190633297,
                -0.04177412390708923,
                -0.014166980050504208,
                -0.1010754331946373,
                0.023396817967295647,
                -0.07252534478902817,
                -0.028304096311330795,
                0.03783098980784416,
                -0.012555812485516071,
                -0.01159931905567646,
                -0.011932102963328362,
                0.07984454184770584,
                0.030906664207577705,
                -0.011739304289221764,
                -0.003851838642731309,
                -0.005411810707300901,
                -0.03185867890715599,
                -0.008405497297644615,
                -0.048720162361860275,
                0.002904655644670129,
                -0.060252588242292404,
                -0.028655139729380608,
                0.009356731548905373,
                0.05367844179272652,
                -0.004481783136725426,
                -0.05178557708859444,
                -0.014795737341046333,
                -0.03635433688759804,
                -0.04373769089579582,
                -0.056741151958703995,
                -0.023008888587355614,
                -0.02346702292561531,
                0.023041807115077972,
                -0.036804571747779846,
                -0.015063152648508549,
                0.02292362041771412,
                0.02095513604581356,
                0.05464489385485649,
                0.040839359164237976,
                0.003718950320035219,
                0.007150531746447086,
                0.0652749314904213,
                -0.06508049368858337,
                0.0009496001875959337,
                -0.06864623725414276,
                0.003476426936686039,
                0.015168295241892338,
                0.02934257686138153,
                0.04528546333312988,
                -0.026475604623556137,
                -0.01053486205637455,
                -0.056466829031705856,
                0.03645867481827736,
                -0.013384985737502575,
                0.02829141914844513,
                -0.014464562758803368,
                0.0028255933430045843,
                0.02390154078602791,
                0.030850371345877647,
                -0.01907442696392536,
                -0.03362711891531944,
                0.03531872108578682,
                -0.01808585226535797,
                0.013992822729051113,
                -0.0353214330971241,
                0.015095350332558155,
                -0.023883091285824776,
                0.033532582223415375,
                0.03448143228888512,
                -0.0271355789154768,
                0.01908930204808712,
                0.01116579957306385,
                -0.025937577709555626,
                -0.007189515046775341,
                -0.005274541210383177,
                0.009137927554547787,
                -0.0585293173789978,
                -0.017972372472286224,
                -0.030613506212830544,
                0.012269830331206322,
                0.022639663890004158,
                0.04538621008396149,
                0.031008413061499596,
                -0.04853581264615059,
                -0.02216970920562744,
                -0.018881425261497498,
                -0.0006658628699369729,
                0.03641115128993988,
                0.02237042598426342,
                -0.03692971542477608,
                0.05514863505959511,
                -0.02284654602408409,
                0.03741948679089546,
                0.01035244483500719,
                -0.008009535260498524,
                0.0407910980284214,
                0.10560320317745209,
                -0.03449222818017006,
                0.030889714136719704,
                0.019242070615291595,
                0.01299988105893135,
                -0.016562169417738914,
                2.0364932140637393e-07,
                0.014380572363734245,
                0.015921156853437424,
                0.0029828923288732767,
                0.05715729668736458,
                -0.006500561721622944,
                -0.019870921969413757,
                0.002736011054366827,
                -0.015232696197926998,
                -0.04491043463349342,
                0.008695856668055058,
                0.033822201192379,
                -0.024563005194067955,
                -0.05085746571421623,
                0.05062690004706383,
                -0.008747318759560585,
                -0.06312889605760574,
                0.058311961591243744,
                -0.03248411417007446,
                -0.04430830106139183,
                -0.019611245021224022,
                0.022601883858442307,
                0.01908729411661625,
                0.03510809317231178,
                -0.01418759673833847,
                0.05930348485708237,
                0.027208974584937096,
                0.01727144978940487,
                -0.007364623714238405,
                0.03615919128060341,
                0.030011048540472984,
                0.0009544146014377475,
                0.0005815589684061706,
                -0.0003183062362950295,
                -0.015952985733747482,
                0.0007417494198307395,
                0.008295872248709202,
                0.0052124252542853355,
                0.02411191537976265,
                0.054064709693193436,
                0.05949002131819725,
                0.010736220516264439,
                -0.03797850385308266,
                0.00877587590366602,
                0.050232358276844025,
                0.026588108390569687,
                0.018975527957081795,
                0.022383689880371094,
                0.010339462198317051,
                -0.04694168642163277,
                0.0028248336166143417,
                -0.004857625812292099,
                0.04304437339305878,
                0.030089080333709717,
                0.018866505473852158,
                0.028159622102975845,
                -0.033680420368909836,
                0.023806767538189888,
                0.018626755103468895,
                0.05559397116303444,
                0.08558893203735352,
                -0.02820785529911518,
                0.053437117487192154,
                -0.022180946543812752,
                0.013343100436031818,
                0.0624501071870327,
                -0.008049668744206429,
                0.01184657122939825,
                1.3948218520540524e-34,
                -0.0002798791683744639,
                -0.05962022766470909,
                -0.0005220250459387898,
                0.02679222822189331,
                0.019463123753666878,
                -0.031155776232481003,
                -0.0059877606108784676,
                -0.020912570878863335,
                0.011992566287517548,
                -0.0069688172079622746,
                -0.014751964248716831,
                -0.0003052819811273366,
                0.006855309009552002,
                -0.023458028212189674,
                0.01849287934601307,
                0.0030082992743700743,
                0.011649642139673233,
                -0.03746897354722023,
                -0.032696064561605453,
                -0.0811108872294426,
                0.05371489003300667,
                0.029285050928592682,
                0.019452203065156937,
                0.007531309500336647,
                0.03048589453101158,
                -0.009197942912578583,
                -0.057440996170043945,
                0.029856441542506218,
                -0.01757967099547386,
                -0.05061911791563034,
                0.032701343297958374,
                -0.0011622412130236626,
                -0.0036662316415458918,
                -0.0015861580614000559,
                0.0017958817770704627,
                -0.08022065460681915,
                0.028217751532793045,
                -0.029136892408132553,
                0.011396314948797226,
                0.024250326678156853,
                -0.030857568606734276,
                0.017217498272657394,
                -0.00428886990994215,
                -0.02859409525990486,
                -0.05909428372979164,
                1.640368850530649e-06,
                0.016584377735853195,
                0.022195106372237206,
                -0.012046060524880886,
                -0.02580317296087742,
                0.05923270061612129,
                -0.024045540019869804,
                -0.06935780495405197,
                0.004506232682615519,
                0.02425832860171795,
                0.016947999596595764,
                0.047800250351428986,
                0.014937881380319595,
                0.07429688423871994,
                0.011606231331825256,
                -0.0001817982119973749,
                0.013342061080038548,
                0.038241226226091385,
                -0.06828712671995163,
                -0.03845158964395523,
                -0.0020787802059203386,
                -0.08328615874052048,
                -0.009314270690083504,
                0.022266997024416924,
                0.01346395444124937,
                0.021403595805168152,
                -0.024300547316670418,
                -0.02142731100320816,
                0.002696175128221512,
                0.012168457731604576,
                0.03193478286266327,
                -0.004033496603369713,
                0.003783381311222911,
                -0.02604685164988041,
                0.005560931749641895,
                0.011915873736143112,
                -0.0008851030142977834,
                0.035191815346479416,
                -0.01536516658961773,
                -0.0024664741940796375,
                -0.00791869219392538,
                -0.06073356419801712,
                -0.01075728703290224,
                -0.035818807780742645,
                -0.013713277876377106,
                0.009693478234112263,
                -0.06484588235616684,
                0.0221963282674551,
                -0.00136840739287436,
                -0.02323468215763569,
                0.0759025365114212,
                -0.011393393389880657,
                -0.0375044010579586,
                -0.01029644813388586,
                -0.01182964351028204,
                0.055018190294504166,
                0.013295593671500683,
                0.020473945885896683,
                -0.005891361739486456,
                0.03676440566778183,
                -0.020467989146709442,
                -0.010522663593292236,
                0.035398997366428375,
                -0.027262143790721893,
                0.07454495877027512,
                -0.032278116792440414,
                -0.05305992811918259,
                -0.014825182966887951,
                0.04463152214884758,
                0.027903981506824493,
                -0.013914750888943672,
                0.039500072598457336,
                -0.0033001236151903868,
                -0.007368834223598242,
                -0.015945134684443474,
                -0.00331796589307487,
                0.03893536329269409,
                0.033164769411087036,
                -0.03543693572282791,
                0.004930573049932718,
                0.024305835366249084,
                0.05103423073887825,
                -0.044222861528396606,
                0.04074107110500336,
                0.018304038792848587,
                0.06770170480012894,
                0.07825631648302078,
                0.04918016493320465,
                -0.060630831867456436,
                0.0037888481747359037,
                -0.019520750269293785,
                -0.016913197934627533,
                -0.06678038090467453,
                0.028792638331651688,
                -0.07838611304759979,
                0.04453803226351738,
                0.009592795744538307,
                0.00023625820176675916,
                -0.005087917670607567,
                0.027231162413954735,
                0.015528461895883083,
                0.08929063379764557,
                -0.010819380171597004,
                0.00455615995451808,
                -0.10418742895126343,
                0.005100477486848831,
                -0.006869361735880375,
                0.02000848390161991,
                -0.05237400159239769,
                0.013016033917665482,
                -0.05442798137664795,
                -0.017795387655496597,
                -0.09507577121257782,
                0.029576154425740242,
                0.024121543392539024,
                0.004077855031937361,
                -0.006898070685565472,
                -0.06278111040592194,
                -0.045648008584976196,
                0.02561921440064907,
                0.010016903281211853,
                0.05919509381055832,
                -0.03134907782077789,
                -0.048273369669914246,
                0.023943087086081505,
                0.05618661269545555,
                -0.007987827062606812,
                0.03379681706428528,
                0.06871476024389267,
                0.018261026591062546,
                -0.05375109612941742,
                0.02620272897183895,
                0.06616686284542084,
                -0.0011980284471064806,
                0.022455362603068352,
                -0.03019532933831215,
                -0.06039976328611374,
                0.044743526726961136,
                -0.0031015623826533556,
                0.07438009977340698,
                0.029199734330177307,
                -0.1234118789434433,
                0.028845084831118584,
                -0.02543782629072666,
                -0.047812461853027344,
                0.028401274234056473,
                0.006821032613515854,
                -0.08423132449388504,
                0.02628767117857933,
                0.03518330305814743,
                0.1204078271985054,
                0.05558330938220024,
                -0.035260237753391266,
                0.049749862402677536,
                -0.031416501849889755,
                0.008978298865258694,
                0.01851155236363411,
                -0.04867357015609741,
                -0.058882858604192734,
                0.023990990594029427,
                0.006243058945983648,
                0.004718239884823561,
                -0.033050958067178726,
                -0.03197164833545685,
                -0.024519124999642372,
                0.008533486165106297,
                -0.016333939507603645,
                0.045136984437704086,
                0.026964599266648293,
                0.0006888886564411223,
                -0.06346391886472702,
                -0.026059439405798912,
                -0.04688780754804611,
                -0.019695807248353958,
                -0.031120428815484047,
                -0.02393529750406742,
                0.016957757994532585,
                -0.028505045920610428,
                0.03812060505151749,
                0.040707096457481384,
                -0.027640648186206818,
                0.03560375049710274,
                0.007203927729278803,
                0.02050265483558178,
                -0.05314963683485985,
                0.04745599254965782,
                -0.0033600630704313517,
                0.020578423514962196,
                -0.00913071259856224,
                0.03106808289885521,
                0.02109382301568985,
                -0.017448626458644867,
                -0.021438075229525566,
                -0.00916136335581541,
                0.005612244829535484,
                0.005109394900500774,
                0.059861648827791214,
                0.018233805894851685,
                0.05238662287592888,
                -0.025532877072691917,
                0.00033924190211109817,
                -0.00822355505079031,
                0.04454996436834335,
                0.07949081808328629,
                -0.006320015527307987,
                -0.04757019504904747,
                -0.0273445937782526,
                -0.047216519713401794,
                -0.04032925143837929,
                0.03037250228226185,
                0.021871693432331085,
                0.01646818406879902,
                0.014844564720988274,
                0.00806010328233242,
                0.016417335718870163,
                0.005049119237810373,
                -0.038941070437431335,
                -0.035186272114515305,
                -0.019304383546113968,
                0.022220799699425697,
                -0.008327332325279713,
                0.020684821531176567,
                -0.04596496745944023,
                0.04835623875260353,
                0.007377601228654385,
                -0.006072335876524448,
                0.06306162476539612,
                -0.021887104958295822,
                0.017781293019652367,
                -0.05149444192647934,
                0.04224590212106705,
                -0.032245442271232605,
                0.01608799770474434,
                -0.0234212689101696,
                -0.07491091638803482,
                0.021453361958265305,
                -0.002962586237117648,
                -0.0013437223387882113,
                0.002475260989740491,
                -0.03740400820970535,
                -0.03336675092577934,
                0.02196597121655941,
                -0.028588704764842987,
                0.04580528289079666,
                -0.040164388716220856,
                0.03203535079956055,
                0.030687829479575157,
                -0.020348111167550087,
                -0.03196645528078079,
                0.03494328260421753,
                0.005847316235303879,
                -0.028786828741431236,
                0.04434513300657272,
                -0.04824645072221756,
                -0.04717468097805977,
                0.030448317527770996,
                -0.008360468782484531,
                -0.09951503574848175,
                0.02478189393877983,
                -0.04918195679783821,
                -0.020864212885499,
                0.04338042438030243,
                0.03091367892920971,
                -0.048533596098423004,
                -0.00618330854922533,
                -0.020080512389540672,
                -0.017760133370757103,
                -0.0514189712703228,
                0.024995749816298485,
                -0.04697065427899361,
                -0.019005881622433662,
                -0.0026834148447960615,
                0.053078554570674896,
                0.043600887060165405,
                0.0008182390010915697,
                -0.018785975873470306,
                0.0029378794133663177,
                0.020037250593304634,
                0.042105406522750854,
                0.031974006444215775,
                6.306527939159423e-05,
                0.019165024161338806,
                0.06826289743185043,
                -0.045121412724256516,
                0.030164487659931183,
                0.0588977113366127,
                0.01895628497004509,
                0.008152755908668041,
                -0.07540781050920486,
                -0.019794413819909096,
                0.02722364291548729,
                0.0287554319947958,
                -0.0021026211325079203,
                -0.021624324843287468,
                0.010591725818812847,
                -0.06446876376867294,
                -0.01044907234609127,
                -0.08065958321094513,
                -0.03563584387302399,
                -0.02662162482738495,
                0.010963495820760727,
                0.0045671225525438786,
                -0.000673808332066983,
                -0.08546499162912369,
                0.015664895996451378,
                -0.011239987798035145,
                -0.044762201607227325,
                -0.011902389116585255,
                -0.047306906431913376,
                -0.004477798938751221,
                -0.013875113800168037,
                0.02045360580086708,
                -0.0015477393753826618,
                0.03860868513584137,
                -0.009926869533956051,
                0.05010215565562248,
                0.007904251106083393,
                -0.009478692896664143,
                -0.012072598561644554,
                0.013493122532963753,
                0.002397088101133704,
                -0.08864237368106842,
                -0.0401993989944458,
                0.021184243261814117,
                0.05507257208228111,
                -0.0432785302400589,
                -0.06683759391307831,
                0.0050584073178470135,
                0.031088031828403473,
                0.0032150771003216505,
                -0.042348504066467285,
                -0.027742596343159676,
                -0.031790655106306076,
                0.0329793244600296,
                0.022207088768482208,
                0.009905250743031502,
                -0.04003344103693962,
                0.01875653676688671,
                0.016909556463360786,
                -0.04669509083032608,
                -0.016420874744653702,
                0.015601265244185925,
                0.028506452217698097,
                0.007852672599256039,
                -0.042736466974020004,
                0.004309036768972874,
                -0.010528572835028172,
                0.007522102445363998,
                0.05078565329313278,
                -0.021394379436969757,
                0.04105830192565918,
                0.018063416704535484,
                -0.0038762849289923906,
                -0.00976657122373581,
                0.08327044546604156,
                0.028806596994400024,
                -0.009391100145876408,
                0.02885182946920395,
                -0.07577496021986008,
                0.017202209681272507,
                0.03086414933204651,
                -0.01753513514995575,
                0.015686985105276108,
                0.005097833462059498,
                0.03384895250201225,
                -0.005281456746160984,
                0.034526169300079346,
                0.0043470850214362144,
                -0.03340553492307663,
                -0.019785959273576736,
                -0.043747060000896454,
                -0.05197367072105408,
                0.08932571113109589,
                -0.026097852736711502,
                0.023073535412549973,
                0.01823916845023632,
                -0.0030115125700831413,
                0.0051969182677567005,
                -0.03636867180466652,
                0.010498449206352234,
                0.035793475806713104,
                -0.0337335430085659,
                -0.029357269406318665,
                0.015881892293691635,
                -0.029581308364868164,
                -0.02620409056544304,
                0.011974602937698364,
                0.02481094002723694,
                -0.017675217241048813,
                -0.05265670269727707,
                0.028581399470567703,
                -0.031258367002010345,
                0.035305898636579514,
                0.049831852316856384,
                -0.026908382773399353,
                0.0035425282549113035,
                -0.010226421058177948,
                0.004968017805367708,
                0.022727761417627335,
                0.028422972187399864,
                0.04490204155445099,
                0.03670634329319,
                0.03688972443342209,
                -0.03020717389881611,
                0.00504290871322155,
                0.018159786239266396,
                0.0035798095632344484,
                0.05800352245569229,
                0.03672236204147339,
                0.02835535630583763,
                -0.021575752645730972,
                -0.015950841829180717,
                -0.012849421240389347,
                0.02838693931698799,
                -0.046718161553144455,
                0.06306282430887222,
                0.035712532699108124,
                -0.030437687411904335,
                0.05818749591708183,
                -0.005783856380730867,
                -0.008000356145203114,
                0.025867752730846405,
                -0.08155800402164459,
                -0.04673950746655464,
                0.011178159154951572,
                0.03193185105919838,
                0.08035644888877869,
                0.029310403391718864,
                -0.08267220109701157,
                0.01794625073671341,
                -0.01755681447684765,
                -0.06666300445795059,
                0.00992242619395256,
                0.02413814328610897,
                -0.005410253535956144,
                -0.048688217997550964,
                0.061182692646980286,
                -0.02459154836833477,
                -0.030705509707331657,
                0.004776746034622192,
                -0.0064145405776798725,
                0.0037754345685243607,
                0.04708106070756912,
                -0.021942924708127975,
                -0.040801793336868286,
                -0.0011587782064452767,
                0.014765270985662937,
                0.020862089470028877,
                0.009928662329912186,
                0.026717068627476692,
                -0.016139626502990723,
                -0.009415137581527233,
                0.054438669234514236,
                0.011908253654837608,
                -0.00021407086751423776,
                0.020005622878670692,
                -0.029937051236629486,
                -0.016513973474502563,
                -0.023111429065465927,
                -0.06864621490240097,
                -0.001302845892496407,
                0.027063028886914253,
                0.036581769585609436,
                0.013464224524796009,
                -0.02235613577067852,
                -0.07428356260061264,
                0.008790253661572933,
                0.02336333505809307,
                0.02138417959213257,
                -0.0329093411564827,
                0.010274037718772888,
                -0.02123638242483139,
                0.0035178528632968664,
                -0.026682861149311066,
                -0.01773955300450325,
                0.04968550428748131,
                -0.06851261854171753,
                -0.010826744139194489,
                0.04771983623504639,
                -0.06247299537062645,
                -0.013795665465295315,
                -0.00022604303376283497,
                -0.006635126192122698,
                0.030231080949306488,
                -0.029675351455807686,
                -0.05861300602555275,
                0.03497568145394325,
                -0.039995983242988586,
                0.010877608321607113,
                -0.004243897274136543,
                0.04413098469376564,
                -0.026767851784825325,
                0.026409950107336044,
                -0.007130501791834831,
                -0.039870359003543854,
                0.04642992839217186,
                -0.057079482823610306,
                -0.07585708796977997,
                -0.09749968349933624,
                0.031282663345336914,
                0.014268456026911736,
                0.0790109634399414,
                0.04875550419092178,
                0.01704632304608822,
                -0.012459869496524334,
                -0.02135343663394451,
                -0.06607281416654587,
                0.02512945421040058,
                0.07259424030780792,
                -0.012572135776281357,
                0.008314929902553558,
                -0.026362845674157143,
                -0.03409001603722572,
                -0.012258865870535374,
                0.027603700757026672,
                0.0006119493045844138,
                0.02056225761771202,
                -0.04200035706162453,
                0.06859920918941498,
                0.01705632545053959,
                0.003610451938584447,
                -4.936492199698043e-33,
                -0.02495509199798107,
                -0.05343383550643921,
                0.013345747254788876,
                0.03654387965798378,
                -0.0344945564866066,
                0.019028903916478157,
                0.02695525996387005,
                0.04986744746565819,
                -0.025791719555854797,
                -0.048652343451976776,
                -0.0245697982609272,
                0.025292502716183662,
                0.013315381482243538,
                0.02255118452012539,
                0.09647225588560104,
                -0.018111277371644974,
                0.024277910590171814,
                0.0033061031717807055,
                0.024934016168117523,
                -0.03546357527375221,
                -0.06622084975242615,
                0.00926918163895607,
                0.06640973687171936,
                0.009146708063781261,
                -0.015108583495020866,
                0.016665153205394745,
                0.004215652123093605,
                0.02099858783185482,
                0.058155279606580734,
                0.022418083623051643,
                0.027376344427466393,
                -0.05187162756919861,
                0.020923947915434837,
                -0.008844178169965744,
                -0.007135041058063507,
                0.028685178607702255,
                -0.02491333708167076,
                -0.0037393567617982626,
                -0.055673811584711075,
                -0.009898650459945202,
                0.0028670947067439556,
                -0.022565564140677452,
                0.021013915538787842,
                -0.021102217957377434,
                -0.005708690732717514,
                -0.02390298806130886,
                -0.007191711571067572,
                0.001411818666383624,
                -0.020006094127893448,
                0.013707607984542847,
                -0.02762957476079464,
                -0.01604568585753441,
                -0.0317552387714386,
                0.02843506447970867,
                0.023021776229143143,
                0.018286574631929398,
                0.004478242713958025,
                0.015910552814602852,
                -0.04234585165977478,
                -0.02927538938820362,
                -0.04795153811573982,
                -0.02251856029033661,
                -0.019834376871585846,
                -0.00484874052926898,
                -0.06668497622013092,
                -0.01853233575820923,
                -0.019442202523350716,
                0.043603647500276566,
                -0.023327717557549477,
                -0.036737628281116486,
                -0.017877228558063507,
                0.0759640708565712,
                -0.007521156687289476,
                -0.028759384527802467,
                -0.1298510581254959,
                -0.020628435537219048,
                0.0028600995428860188,
                0.028578968718647957,
                0.011241253465414047,
                0.05614672228693962,
                -0.0009772296762093902,
                0.022967230528593063,
                0.007806594483554363,
                -0.03160034120082855,
                0.01734711043536663,
                -0.019159549847245216,
                0.01872682198882103,
                0.0692233070731163,
                -0.012566758319735527,
                0.030821893364191055,
                -0.012524990364909172,
                0.04716697335243225,
                -0.009266768582165241,
                0.04517293721437454,
                0.05603907257318497,
                -0.009409726597368717,
                0.006565054878592491,
                0.011017915792763233,
                0.011903711594641209,
                0.05435388535261154,
                -0.01737256348133087,
                0.08053873479366302,
                -0.07392721623182297,
                -0.0021279160864651203,
                2.502126335457433e-05,
                -0.028558604419231415,
                -0.042593441903591156,
                -0.0459073930978775,
                0.02818332426249981,
                0.024337809532880783,
                0.04647183045744896,
                -0.015365525148808956,
                -0.01790274865925312,
                -0.026249036192893982,
                -0.04336247220635414,
                -0.05094273388385773,
                0.007719274610280991,
                0.03371516615152359,
                -0.013600965030491352,
                -0.0006854796665720642,
                0.0057107023894786835,
                0.01840873621404171,
                -0.016699519008398056,
                -0.03217735514044762,
                0.0034704103600233793,
                -0.01882009394466877,
                0.014466585591435432,
                -0.013526457361876965,
                0.04452952370047569,
                -0.019906090572476387,
                -0.005453955382108688,
                -0.011060556396842003,
                2.2667562404876662e-07,
                -0.007188087794929743,
                0.041064947843551636,
                0.03847946599125862,
                0.06833168864250183,
                0.017931675538420677,
                0.03309519216418266,
                -0.0012725605629384518,
                -0.015589392744004726,
                -0.026627689599990845,
                0.014072437770664692,
                0.09001243114471436,
                -0.02402261272072792,
                -0.05666794255375862,
                0.020066065713763237,
                0.022525165230035782,
                -0.10878206044435501,
                0.002424202160909772,
                -0.02784912660717964,
                -0.059003472328186035,
                -0.01800498366355896,
                0.022946367040276527,
                0.009479304775595665,
                -0.01875716634094715,
                -0.031011831015348434,
                -0.0018178386380895972,
                0.009252000600099564,
                -0.008188970386981964,
                0.00024678942281752825,
                0.034776125103235245,
                0.05652938038110733,
                0.07748991996049881,
                0.0019423124613240361,
                0.012704726308584213,
                0.03233034163713455,
                0.011560313403606415,
                -0.0827372595667839,
                0.05969622731208801,
                0.03170249983668327,
                0.029711995273828506,
                -0.027116069570183754,
                0.039747387170791626,
                0.0013085922691971064,
                0.012155666947364807,
                0.04511784389615059,
                -0.03409474343061447,
                -0.0229488518089056,
                -0.018727915361523628,
                0.051737476140260696,
                -0.01336617860943079,
                0.0036699981428682804,
                0.03991398960351944,
                0.031590335071086884,
                -0.0020388299599289894,
                -0.010054612532258034,
                -0.002237198641523719,
                -0.025340287014842033,
                -0.025893231853842735,
                0.0349915437400341,
                0.06581217050552368,
                0.044045574963092804,
                -0.016513578593730927,
                -0.044813819229602814,
                0.01485662441700697,
                0.028203584253787994,
                0.01587674766778946,
                0.03989638760685921,
                -0.0012241927906870842,
                1.1414704474394537e-34,
                -0.010862666182219982,
                -0.08436469733715057,
                -0.0045018685050308704,
                -0.011336247436702251,
                0.006207406520843506,
                0.009093444794416428,
                -0.04634488373994827,
                0.009169335477054119,
                0.00268066069111228,
                0.022425152361392975,
                -0.028489239513874054,
                0.0845264121890068,
                -0.029273254796862602,
                -0.026137761771678925,
                0.011726454831659794,
                0.01889679953455925,
                0.03127143532037735,
                0.04947530850768089,
                -0.012968551367521286,
                0.03852470591664314,
                0.025838162750005722,
                -0.04709303379058838,
                -0.06115611270070076,
                0.005133095663040876,
                -0.06457366794347763,
                0.04789935052394867,
                -0.07070395350456238,
                0.03396531194448471,
                0.005778298247605562,
                0.02938474714756012,
                -0.015485499985516071,
                0.023109661415219307,
                -0.005871812347322702,
                -0.010098174214363098,
                -0.011963849887251854,
                -0.0026424601674079895,
                -0.04041663557291031,
                0.04059110954403877,
                -0.04616401717066765,
                0.030396979302167892,
                0.01114997360855341,
                0.05244791507720947,
                0.007903444580733776,
                -0.012048212811350822,
                -0.04841960594058037,
                1.634684167584055e-06,
                0.028017207980155945,
                -0.004259037785232067,
                -0.054808299988508224,
                -0.0014965046430006623,
                -0.024863041937351227,
                0.04583325609564781,
                0.007190849632024765,
                0.01607714220881462,
                0.04831579327583313,
                -0.04173644632101059,
                0.019739560782909393,
                0.00463835708796978,
                0.012420857325196266,
                0.07385322451591492,
                0.028744960203766823,
                0.006704343482851982,
                0.06576921045780182,
                -0.013081541284918785,
                -0.01682138256728649,
                -0.030912023037672043,
                -0.08012071251869202,
                -0.038778237998485565,
                0.03707273304462433,
                -0.034192319959402084,
                -0.00283236987888813,
                -0.01722308248281479,
                -0.015102663077414036,
                -0.008258421905338764,
                -0.04604821279644966,
                0.05726915970444679,
                -0.039003707468509674,
                -0.013450408354401588,
                -0.0076671564020216465,
                0.007168321404606104,
                0.00041030553984455764,
                0.050146229565143585,
                0.029798759147524834,
                -0.036731794476509094,
                0.050155896693468094,
                0.040688466280698776,
                0.027880363166332245,
                -0.04217907786369324,
                -0.03575409576296806,
                0.02708209864795208,
                -0.001167752081528306,
                -0.006170724984258413,
                0.05952225625514984,
                -0.06530052423477173,
                0.005166735965758562,
                0.09152911603450775,
                -0.015180307440459728,
                -0.03577515110373497,
                0.00015845242887735367,
                0.010958696715533733,
                -0.03897984325885773,
                -0.04265241697430611,
                -0.025916052982211113,
                -0.025759484618902206,
                0.018965110182762146,
                -0.04259669408202171,
                -0.024875331670045853,
                0.04117637127637863,
                0.016660703346133232,
                -0.011257220059633255,
                -0.03449780121445656,
                0.003645719261839986,
                0.011644124984741211,
                0.07968705147504807,
                -0.027108855545520782,
                0.04203081876039505,
                -0.0021148647647351027,
                0.04606938362121582,
                0.008001301437616348,
                0.008364981971681118,
                -0.001783359213732183,
                -0.04280563443899155,
                -0.02344743348658085,
                -0.017182134091854095,
                0.05824553593993187,
                0.04767294600605965,
                -0.0013203644193708897,
                -0.019562406465411186,
                0.016181200742721558,
                -0.03986520320177078,
                -0.012280959635972977,
                -0.0702054426074028,
                -0.008068082854151726,
                -0.006003272254019976,
                -0.012565053068101406,
                0.005976708605885506,
                0.049755681306123734,
                -0.04189767688512802,
                0.00512797012925148,
                0.003883566940203309,
                -0.05435013771057129,
                0.03046286106109619,
                0.009286267682909966,
                0.01315101608633995,
                0.009038236923515797,
                0.029573360458016396,
                0.03009064309298992,
                -0.037297144532203674,
                -0.02535419538617134,
                -0.031157303601503372,
                0.037889979779720306,
                -0.024302544072270393,
                -0.08132994174957275,
                -0.014290745370090008,
                -0.012903030030429363,
                -0.007073264103382826,
                0.013793161138892174,
                -0.01660475879907608,
                -0.0642249584197998,
                0.03144729509949684,
                -0.033964455127716064,
                0.018072474747896194,
                -0.029415039345622063,
                -0.001237285090610385,
                -0.0070452140644192696,
                0.09203650802373886,
                0.042494289577007294,
                0.07316248118877411,
                0.0022406200878322124,
                -0.017897067591547966,
                -0.05945051833987236,
                0.01001258660107851,
                -0.021319584921002388,
                0.044185299426317215,
                -0.016130130738019943,
                -0.00626675458624959,
                0.005185945425182581,
                0.06814664602279663,
                -0.030543901026248932,
                -0.007857998833060265,
                0.0005130018689669669,
                0.018303148448467255,
                0.03884081169962883,
                -0.05811353027820587,
                0.033025603741407394,
                0.03161405399441719,
                0.006296232808381319,
                0.04243258759379387,
                0.03264531493186951,
                -0.009334776550531387,
                0.06288294494152069,
                0.010091358795762062,
                -0.020220130681991577,
                0.008962715975940228,
                0.022940892726182938,
                0.07302197068929672,
                0.006017628591507673,
                0.028146708384156227,
                0.017556190490722656,
                -0.023763049393892288,
                -0.018440719693899155,
                -0.0066238464787602425,
                0.01509831752628088,
                0.019951237365603447,
                -0.01352919451892376,
                -0.04349985718727112,
                0.016641495749354362,
                0.009470388293266296,
                0.00552965234965086,
                0.00821603648364544,
                -0.05387672409415245,
                -0.00705517316237092,
                0.028588496148586273,
                -0.003752743825316429,
                -0.0028218745719641447,
                -0.022524144500494003,
                0.05881384015083313,
                -0.01974303647875786,
                -0.038013309240341187,
                0.015835465863347054,
                0.009488440118730068,
                0.03979498893022537,
                -0.04890940338373184,
                -0.011635446920990944,
                -0.011337725445628166,
                0.05413728952407837,
                0.07744405418634415,
                -0.019257448613643646,
                0.014942415989935398,
                0.02045862190425396,
                0.02692456729710102,
                0.00892659556120634,
                0.10629525780677795,
                -0.018377261236310005,
                -0.01272176206111908,
                0.043932054191827774,
                0.0024832538329064846,
                -0.013157985173165798,
                -0.02462889440357685,
                0.05032256990671158,
                0.015162970870733261,
                -0.0017797726904973388,
                0.0032444633543491364,
                -0.016355350613594055,
                0.055434808135032654,
                -0.063831627368927,
                0.04785308614373207,
                -0.0007010555127635598,
                -0.0015440576244145632,
                -0.00016606990539003164,
                -0.03661277890205383,
                -0.02880791760981083,
                -0.015908362343907356,
                0.05496145412325859,
                0.023618586361408234,
                0.025059692561626434,
                -0.03408081457018852,
                0.04932451248168945,
                0.023543793708086014,
                0.0006426153122447431,
                -0.006083799060434103,
                -0.00033739194623194635,
                0.0348072275519371,
                -0.025356024503707886,
                -0.009164542891085148,
                -0.03452809900045395,
                -0.013220046646893024,
                0.025397304445505142,
                -0.010170305147767067,
                -0.07256171107292175,
                0.05317400395870209,
                0.02627616934478283,
                -0.026400122791528702,
                -0.0016675033839419484,
                0.01194386649876833,
                0.05964646488428116,
                -0.048315055668354034,
                -0.013210110366344452,
                -0.09029731154441833,
                -0.026865696534514427,
                0.015200247056782246,
                0.010764194652438164,
                0.0056467726826667786,
                -0.0019369929796084762,
                -0.02670053392648697,
                -0.01129587646573782,
                -0.013284589163959026,
                -0.002991405548527837,
                0.051905836910009384,
                -0.030196551233530045,
                0.04583019018173218,
                0.028089459985494614,
                -0.05906485766172409,
                -0.02215116284787655,
                -0.018151607364416122,
                -0.006107257213443518,
                -0.022728104144334793,
                0.019220173358917236,
                -0.05558568239212036,
                0.04436039552092552,
                -0.00045761108049191535,
                0.014170601032674313,
                -0.004849456250667572,
                -0.02385903149843216,
                -0.07732201367616653,
                0.012355540879070759,
                0.05289321392774582,
                0.02090097963809967,
                0.016678787767887115,
                0.07221413403749466,
                0.007684597745537758,
                -0.07050999253988266,
                -0.0042036171071231365,
                0.027565397322177887,
                0.007194935344159603,
                0.0013582304818555713,
                -0.009430781938135624,
                -0.042707040905952454,
                0.008313796482980251,
                -0.02725198119878769,
                0.011585621163249016,
                0.0541449710726738,
                0.012862399220466614,
                0.036755338311195374,
                -0.03126133978366852,
                0.01974116824567318,
                -0.004446305334568024,
                -0.05618501454591751,
                -0.04719788208603859,
                0.0033092834055423737,
                -0.018148018047213554,
                0.012808695435523987,
                0.04190210998058319,
                -0.0637090653181076,
                -0.0293134693056345,
                0.006288115866482258,
                0.023502053692936897,
                -0.040804628282785416,
                -0.025321168825030327,
                0.04765789955854416,
                -0.0625404641032219,
                -0.032299887388944626,
                -0.023109283298254013,
                -0.05754857510328293,
                -0.0013237020466476679,
                -0.04051437973976135,
                0.06629030406475067,
                -0.004146181046962738,
                -0.08049116283655167,
                -0.021888498216867447,
                -0.03113420121371746,
                0.030143020674586296,
                -0.01148876454681158,
                -0.017057344317436218,
                0.03756174445152283,
                0.0373004749417305,
                0.019845206290483475,
                0.058666545897722244,
                0.023508861660957336,
                -0.02423708885908127,
                -0.0011294679716229439,
                0.05482678487896919,
                -0.059813883155584335,
                -0.010087927803397179,
                0.007472698576748371,
                0.038964517414569855,
                -0.03855406865477562,
                -0.026515962556004524,
                0.063045933842659,
                -0.011278430931270123,
                -0.08232442289590836,
                -0.013586893677711487,
                0.011896041221916676,
                -0.0007673553773202002,
                -0.004960415419191122,
                0.03455720469355583,
                -0.006998428143560886,
                0.03303558751940727,
                0.04843662306666374,
                -0.02653586119413376,
                0.002717377617955208,
                0.02229929156601429,
                0.01462295651435852,
                -0.02580850012600422,
                0.02541331760585308,
                -0.021028093993663788,
                -0.04131496325135231,
                -0.01769719272851944,
                -0.012449116446077824,
                -0.022445155307650566,
                -0.03468836843967438,
                0.04556627199053764,
                -0.009298445656895638,
                0.03640826791524887,
                -0.019790835678577423,
                -0.023828810080885887,
                -0.03580528497695923,
                0.0011817887425422668,
                -0.00040092060226015747,
                0.027272431179881096,
                0.02290087193250656,
                0.049342766404151917,
                -0.03266351670026779,
                -0.07283768057823181,
                0.034850988537073135,
                -0.038284074515104294,
                0.012462691403925419,
                0.05518209934234619,
                -0.021168362349271774,
                0.01226129662245512,
                -0.014640533365309238,
                0.029454566538333893,
                -0.10414879769086838,
                -0.04489165544509888,
                0.017011938616633415,
                -0.03933149576187134,
                0.024181630462408066,
                0.0725066065788269,
                0.029050596058368683,
                0.01849468983709812,
                -0.03405415639281273,
                0.0032173425424844027,
                0.004857555031776428,
                -9.103447519009933e-05,
                0.009343678131699562,
                0.06447931379079819,
                -0.10653772205114365,
                0.03618844226002693,
                -0.037716351449489594,
                0.021439893171191216,
                -0.011514646001160145,
                -0.010629972442984581,
                0.002767081605270505,
                -0.05651017278432846,
                -0.012111760675907135,
                0.01274799183011055,
                0.033763471990823746,
                0.04311319440603256,
                0.058999352157115936,
                -0.004603395238518715,
                -0.020774830132722855,
                0.005992707796394825,
                0.030792146921157837,
                0.07766980677843094,
                0.02764529176056385,
                0.05801200121641159,
                -0.03481866419315338,
                0.008814248256385326,
                0.03862271457910538,
                -0.020603064447641373,
                0.014040823094546795,
                -0.035597916692495346,
                0.0367455929517746,
                0.028498679399490356,
                -0.01975317671895027,
                -0.019767500460147858,
                -0.06490423530340195,
                0.020968852564692497,
                0.055688343942165375,
                -0.016680710017681122,
                0.006432719528675079,
                0.036840084940195084,
                -0.09878481179475784,
                -0.0322396457195282,
                -0.05755506455898285,
                -0.009480459615588188,
                -0.014276289381086826,
                -0.0669315904378891,
                0.002017379505559802,
                0.013696081005036831,
                0.0010911929421126842,
                -0.04139568656682968,
                0.02100328914821148,
                0.03314037621021271,
                0.04857959225773811,
                -0.05083916708827019,
                -0.020072326064109802,
                -0.011580285616219044,
                -0.03038758784532547,
                0.028035089373588562,
                -0.02709498070180416,
                -0.0004959839279763401,
                0.002763926750048995,
                0.02064960077404976,
                0.06252896040678024,
                0.02208806201815605,
                0.022144678980112076,
                -0.03380677476525307,
                0.01695195399224758,
                -0.037295978516340256,
                -0.005282312631607056,
                -0.004756228998303413,
                -0.03371855989098549,
                -0.012977175414562225,
                -0.0189185943454504,
                -0.03130606934428215,
                -0.030534984543919563,
                0.03118765912950039,
                -0.02991742640733719,
                -0.022061148658394814,
                -0.04062747210264206,
                -0.00036411569453775883,
                -0.0011028731241822243,
                0.012637150473892689,
                -0.04392993077635765,
                -0.024855654686689377,
                -0.001486781518906355,
                -0.002506820484995842,
                0.07221471518278122,
                -0.04179103672504425,
                -0.010421866551041603,
                0.0016702308785170317,
                0.016356412321329117,
                -0.01059909351170063,
                -0.04526430368423462,
                0.025036759674549103,
                0.005539997946470976,
                0.004316974896937609,
                0.09064696729183197,
                -0.020276471972465515,
                -0.020039785653352737,
                0.014710749499499798,
                -0.003905699122697115,
                0.0007998795481398702,
                -0.04867512360215187,
                -0.06042870134115219,
                0.0340491384267807,
                -0.029984408989548683,
                0.02512963116168976,
                -0.04439812898635864,
                -0.05379891023039818,
                0.02730902098119259,
                0.000347141147358343,
                0.03415830060839653,
                -0.03495878726243973,
                -0.006970682647079229,
                0.040449004620313644,
                -0.012038529850542545,
                -0.007838636636734009,
                0.0012603823561221361,
                -0.024174576625227928,
                -0.0532136932015419,
                -0.06185300648212433,
                -0.08031176030635834,
                -0.012193321250379086,
                -0.050007838755846024,
                0.07076980918645859,
                0.044387709349393845,
                0.04153676703572273,
                0.026311546564102173,
                0.03171365335583687,
                -0.050956737250089645,
                -0.01829984039068222,
                0.014042315073311329,
                0.03226495161652565,
                0.01754891499876976,
                -0.009675239212810993,
                0.0052808248437941074,
                0.017706401646137238,
                0.007807117886841297,
                -0.0028748707845807076,
                -0.056187186390161514,
                -0.01619555987417698,
                0.03576415777206421,
                0.01902880147099495,
                -0.007749304175376892,
                -5.093941953809011e-33,
                -0.05762069299817085,
                -0.03628295660018921,
                0.028543533757328987,
                0.06752362102270126,
                -0.06274862587451935,
                -0.05765233561396599,
                0.012638300657272339,
                0.0735604465007782,
                0.006555601488798857,
                0.002457632217556238,
                0.006560999434441328,
                0.03988038748502731,
                -0.01764705404639244,
                -0.013837658800184727,
                -0.010829003527760506,
                0.027037229388952255,
                -0.00043854815885424614,
                0.030765339732170105,
                0.011828603222966194,
                -0.017944835126399994,
                -0.03070181980729103,
                0.02394913136959076,
                0.022537529468536377,
                0.012252789922058582,
                0.03120032325387001,
                -0.025899743661284447,
                0.017947707325220108,
                -0.029851127415895462,
                0.03630151227116585,
                -0.014744815416634083,
                0.030057596042752266,
                -0.04824014753103256,
                0.007161274552345276,
                -0.025257762521505356,
                0.024298734962940216,
                0.0559188574552536,
                -0.03674398362636566,
                -0.010533028282225132,
                -0.024890273809432983,
                -0.023706214502453804,
                -0.020575078204274178,
                -0.06186405569314957,
                0.022901084274053574,
                -0.041541554033756256,
                0.03750167787075043,
                -0.03871481865644455,
                0.007724813185632229,
                0.04494181647896767,
                0.02781822346150875,
                0.09599664062261581,
                -0.054221540689468384,
                0.007805929519236088,
                -0.036564700305461884,
                0.03165952116250992,
                -0.06104534491896629,
                0.0249592587351799,
                -0.02689395658671856,
                -0.04647281393408775,
                -0.06563341617584229,
                -0.0016047712415456772,
                -0.05961092188954353,
                0.02002956159412861,
                0.01897399127483368,
                0.0005522439605556428,
                -0.024540318176150322,
                0.00874805636703968,
                0.0018854510271921754,
                0.01953331008553505,
                0.058353278785943985,
                -0.05377081409096718,
                0.0250676441937685,
                0.06737373024225235,
                -0.023824773728847504,
                0.07151167839765549,
                -0.06847191601991653,
                -0.06042787432670593,
                0.028756439685821533,
                -0.02983120270073414,
                0.05090319737792015,
                -0.03231609985232353,
                -0.05748613923788071,
                0.006132099777460098,
                -0.027221210300922394,
                -0.005487669724971056,
                -0.010687530040740967,
                0.007383798249065876,
                0.025152381509542465,
                -0.0448751375079155,
                0.012273682281374931,
                0.0010843058116734028,
                0.022221174091100693,
                -0.008713801391422749,
                0.06509573012590408,
                0.05202176794409752,
                -0.009426857344806194,
                -0.051699526607990265,
                -0.028675878420472145,
                0.046110834926366806,
                0.016039574518799782,
                -0.028552794829010963,
                -0.04661770164966583,
                -0.020147236064076424,
                -0.07017355412244797,
                -0.0031472290866076946,
                0.023181742057204247,
                -0.017227454110980034,
                0.02034648135304451,
                0.017859425395727158,
                -0.0027434134390205145,
                -0.03015097789466381,
                0.010021497495472431,
                0.03391057625412941,
                0.004448993597179651,
                -0.06054413318634033,
                -0.050718680024147034,
                -0.0020135976374149323,
                -0.05463175103068352,
                0.07940079271793365,
                -0.06821279972791672,
                -0.061654411256313324,
                0.02416032925248146,
                -0.02816208265721798,
                -0.012402430176734924,
                -0.05457841604948044,
                -0.033982664346694946,
                -0.014200082048773766,
                0.007989388890564442,
                0.018609903752803802,
                0.03782305493950844,
                0.005817694123834372,
                0.04249599575996399,
                -0.05068496987223625,
                2.238839016399652e-07,
                -0.015043049119412899,
                0.04045318067073822,
                0.029803942888975143,
                0.026211019605398178,
                0.011976733803749084,
                0.0075614419765770435,
                0.010029816068708897,
                0.029964467510581017,
                0.018353579565882683,
                0.04736913740634918,
                0.031018607318401337,
                0.0194392129778862,
                0.014391222968697548,
                -0.03787810727953911,
                0.023514093831181526,
                -0.03491462022066116,
                -0.01381345372647047,
                -0.093806192278862,
                0.07773195207118988,
                -0.028798799961805344,
                -0.00126487179659307,
                0.006489075720310211,
                -0.0033938393462449312,
                -0.014628862962126732,
                -0.02280072495341301,
                0.033417850732803345,
                0.020476778969168663,
                -0.004493694752454758,
                -0.005588417872786522,
                0.059500765055418015,
                0.018577512353658676,
                -0.034615714102983475,
                0.010543874464929104,
                0.13199293613433838,
                -0.005816603545099497,
                -0.03579064458608627,
                -0.005376885645091534,
                0.037962280213832855,
                -0.036719754338264465,
                0.0555172823369503,
                0.03400040045380592,
                0.050707150250673294,
                -0.00011173900566063821,
                0.04202023521065712,
                0.008699673227965832,
                -0.08791346848011017,
                -0.027334969490766525,
                0.03553769364953041,
                -0.024758432060480118,
                0.01828674040734768,
                -0.01797124557197094,
                0.026841068640351295,
                0.006779097951948643,
                0.03872457891702652,
                0.010579505003988743,
                -0.017713775858283043,
                0.021028492599725723,
                0.021780669689178467,
                0.07584987580776215,
                0.01953139901161194,
                -0.002304942812770605,
                -0.10418138653039932,
                0.021910032257437706,
                -0.054581210017204285,
                0.003118282649666071,
                0.052426137030124664,
                -0.007116398774087429,
                1.051559249309083e-34,
                -0.008768657222390175,
                0.03962395712733269,
                0.0005285344668664038,
                0.039270006120204926,
                0.07807152718305588,
                -0.04381050169467926,
                -0.03812643140554428,
                0.02272379957139492,
                0.010740702040493488,
                0.05091392621397972,
                -0.011598349548876286
            ]
        },
        "Values": {
            "name": [],
            "rep": []
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\xda\\xa2\\r\\xd2\\xd5\\xa2 \\x92\\x81\\x9f\\x18RD\\x84r\\xf9'",
        "id__": "3e509c76-bfb8-41ed-a826-65b4f89381c5"
    },
    {
        "Certificates": {
            "name": [
                "Bachelor of Science Degree"
            ],
            "rep": [
                -0.011120875366032124,
                -0.060704536736011505,
                -0.00284360209479928,
                -0.053547244518995285,
                0.019526487216353416,
                0.01714441552758217,
                0.012722057290375233,
                0.033371828496456146,
                0.08858837932348251,
                0.01512401644140482,
                0.045801792293787,
                0.006572453770786524,
                0.0019625849090516567,
                0.07426158338785172,
                0.027109982445836067,
                -0.0029646733310073614,
                -0.004844320006668568,
                -0.002235697116702795,
                0.0023110725451260805,
                -0.03410261124372482,
                -0.06599216908216476,
                0.023040778934955597,
                -0.04141157120466232,
                -0.010473146103322506,
                -0.030529171228408813,
                -0.06253965944051743,
                0.05620826408267021,
                0.018951505422592163,
                0.032358504831790924,
                0.04842536896467209,
                0.03616400063037872,
                0.02207290381193161,
                -0.02630123496055603,
                0.0027672278229147196,
                1.0899917697315686e-06,
                -0.01478014886379242,
                -0.02922828495502472,
                0.04194370284676552,
                -0.03096349909901619,
                -0.0813872441649437,
                0.05000931769609451,
                0.040371231734752655,
                0.025934867560863495,
                0.033587511628866196,
                -0.024463729932904243,
                0.06065843999385834,
                0.0368117056787014,
                -0.004042681772261858,
                -0.05555027350783348,
                -0.06367280334234238,
                0.017885809764266014,
                -0.08120531588792801,
                -0.06035594642162323,
                -0.02713008224964142,
                -0.056040871888399124,
                0.016513453796505928,
                0.02065061219036579,
                0.0557585246860981,
                0.017078334465622902,
                0.09515970200300217,
                0.020041093230247498,
                0.02859036810696125,
                -0.029671719297766685,
                -0.002026689238846302,
                0.07094017416238785,
                0.037381578236818314,
                0.08309786021709442,
                0.024458393454551697,
                -0.003436294151470065,
                -0.012697371654212475,
                -0.020199939608573914,
                -0.012711861170828342,
                0.009398942813277245,
                0.037758197635412216,
                0.013399689458310604,
                0.01871519535779953,
                -0.0370788536965847,
                -0.021489059552550316,
                -0.016896335408091545,
                -0.06607519835233688,
                -0.0036922520957887173,
                0.021226974204182625,
                0.012280256487429142,
                0.0563417486846447,
                -0.017248399555683136,
                0.05236545205116272,
                0.0015673281159251928,
                0.005229543894529343,
                -0.008097559213638306,
                -0.0023525976575911045,
                0.035514671355485916,
                -0.021903805434703827,
                0.01663263514637947,
                0.02228899486362934,
                -0.04531101882457733,
                -0.0006670465809293091,
                0.056080128997564316,
                0.011878930032253265,
                -0.03371189907193184,
                0.03256142511963844,
                -0.02710009552538395,
                -0.04122139513492584,
                -0.04853551462292671,
                0.07148537784814835,
                0.017604287713766098,
                0.020362339913845062,
                0.030664561316370964,
                0.022021258249878883,
                0.048425495624542236,
                -8.853642066242173e-05,
                -0.06926450878381729,
                -0.010413500480353832,
                -0.004928987938910723,
                -0.03973808139562607,
                0.03252081200480461,
                0.03546258434653282,
                -0.010323379188776016,
                -0.04506903886795044,
                -0.011049086228013039,
                0.017727578058838844,
                -0.013813527300953865,
                0.01505184918642044,
                -0.026635194197297096,
                0.041064269840717316,
                0.05067043751478195,
                0.034582577645778656,
                0.027369949966669083,
                0.002400324447080493,
                0.08770748972892761,
                0.0679020956158638,
                -0.040084972977638245,
                -0.033300820738077164,
                0.016303477808833122,
                0.0016810961533337831,
                -0.01339429710060358,
                -0.04504599794745445,
                0.011282220482826233,
                0.04240959882736206,
                0.05820383504033089,
                -0.09419196099042892,
                -0.02760409004986286,
                -0.04166457802057266,
                0.024596109986305237,
                0.05089779198169708,
                -0.004657367710024118,
                0.020280105993151665,
                0.01654440350830555,
                -0.0015675966860726476,
                0.01791735738515854,
                0.006242610048502684,
                -0.036802321672439575,
                -0.01460175309330225,
                0.012937375344336033,
                -0.07095468789339066,
                0.04290696606040001,
                0.04921838641166687,
                0.02928275428712368,
                -0.005900603719055653,
                -0.028874490410089493,
                0.07338503748178482,
                -0.013893661089241505,
                -0.007906092330813408,
                -0.01273645181208849,
                -0.016818838194012642,
                -0.01067447755485773,
                -0.018294479697942734,
                0.00021537038264796138,
                -0.0557052381336689,
                -0.03965507447719574,
                -0.0038814025465399027,
                -0.03671340271830559,
                -0.03822571039199829,
                -0.04090195521712303,
                0.0752529725432396,
                -0.01903819851577282,
                0.06421609222888947,
                0.030073467642068863,
                0.05721995234489441,
                -0.0009346224833279848,
                0.000798724009655416,
                -0.0037794841919094324,
                -0.050678834319114685,
                0.005594695918262005,
                0.053316451609134674,
                -0.048314087092876434,
                -0.021794281899929047,
                -0.0011792610166594386,
                -0.04126283898949623,
                -0.03994746506214142,
                -0.0004993052571080625,
                0.02063891477882862,
                0.024421215057373047,
                -0.06729517877101898,
                -0.01902143470942974,
                -0.008378112688660622,
                -0.030336521565914154,
                0.0257149375975132,
                0.002526402473449707,
                0.009534625336527824,
                -0.023252420127391815,
                -0.0014991561183705926,
                0.038132693618535995,
                0.0018268354469910264,
                0.058942779898643494,
                0.012086077593266964,
                0.022563979029655457,
                -0.005872156471014023,
                -0.033226341009140015,
                0.008718188852071762,
                0.07886885106563568,
                -0.009320699609816074,
                -0.02160797081887722,
                0.06443852931261063,
                0.03944911062717438,
                -0.036058459430933,
                -0.025675904005765915,
                0.030483823269605637,
                -0.03495166078209877,
                -0.03406042978167534,
                -0.004488138481974602,
                0.014364344999194145,
                -0.045734480023384094,
                0.049995265901088715,
                -0.03108479082584381,
                -0.06251820921897888,
                -0.012642040848731995,
                0.03643938899040222,
                -0.03325684368610382,
                -0.0012404769659042358,
                0.008863489143550396,
                0.010973905213177204,
                -0.005084634758532047,
                -0.004317156970500946,
                -0.009178395383059978,
                -0.002304703462868929,
                -0.045203499495983124,
                0.07678484171628952,
                -0.02746727131307125,
                -0.030555564910173416,
                0.0011102925054728985,
                0.01710440032184124,
                0.021347057074308395,
                0.04633141681551933,
                -0.018339375033974648,
                0.02355317771434784,
                -0.005699091125279665,
                -0.056065164506435394,
                0.03410281985998154,
                0.025558320805430412,
                -0.041833702474832535,
                -0.01751597411930561,
                -0.0067748017609119415,
                -0.03303355723619461,
                0.025377314537763596,
                0.07364185899496078,
                -0.04266248270869255,
                -0.08797058463096619,
                -0.001473765354603529,
                -0.048751071095466614,
                -0.04079646244645119,
                -0.053966570645570755,
                -0.014067242853343487,
                0.001145346206612885,
                0.012153265997767448,
                -0.00896605383604765,
                0.006320199929177761,
                0.0006872781086713076,
                -0.02040141075849533,
                -0.03905849531292915,
                0.014564416371285915,
                -0.024045053869485855,
                0.07100003212690353,
                0.019140638411045074,
                0.012945360504090786,
                0.0023615113459527493,
                -0.010810491628944874,
                0.010681544430553913,
                0.001966581679880619,
                -0.06313341110944748,
                0.01750342734158039,
                0.036285240203142166,
                0.015767594799399376,
                -0.05068621784448624,
                -0.015402337536215782,
                -0.01956537924706936,
                -0.014896976761519909,
                0.013877159915864468,
                0.0010097494814544916,
                -0.024585066363215446,
                0.03147509694099426,
                -0.057984061539173126,
                0.028164967894554138,
                -0.03131985664367676,
                -0.032799866050481796,
                0.023437870666384697,
                0.05494670197367668,
                -0.05643699690699577,
                0.025942016392946243,
                0.01667424850165844,
                -0.040645532310009,
                -0.0064385272562503815,
                -0.03228966146707535,
                -0.005589683074504137,
                0.0015059158904477954,
                -0.01245382521301508,
                0.04148842766880989,
                0.03735034540295601,
                0.029146183282136917,
                -0.0009097844595089555,
                -0.05925266072154045,
                -0.02816804312169552,
                0.01272397767752409,
                0.04371187835931778,
                0.020984811708331108,
                0.021865684539079666,
                -0.04110858216881752,
                -0.04058157652616501,
                0.023700347170233727,
                0.023216944187879562,
                -0.00040547337266616523,
                0.031311098486185074,
                0.03613223135471344,
                -0.032975733280181885,
                -0.04763215780258179,
                0.01778436079621315,
                0.01307877991348505,
                -0.011708011850714684,
                0.04518953710794449,
                -0.005028145853430033,
                -0.06396059691905975,
                -0.016436714679002762,
                -0.05141400918364525,
                0.026698144152760506,
                -0.016929056495428085,
                0.03260035812854767,
                -0.06451325863599777,
                -0.04614041745662689,
                -0.09089869260787964,
                -0.03726143017411232,
                -0.008634638972580433,
                0.033532124012708664,
                0.025756876915693283,
                -0.037428613752126694,
                0.025282546877861023,
                0.01197492890059948,
                0.014818764291703701,
                0.0030967227648943663,
                0.02120434306561947,
                -0.012808132916688919,
                -0.0026450438890606165,
                0.05336594954133034,
                0.012674924917519093,
                0.005961772985756397,
                0.009598293341696262,
                -0.01990429311990738,
                0.032969214022159576,
                0.04597286880016327,
                0.01847917027771473,
                -0.007343337871134281,
                -0.022745229303836823,
                -0.04727499559521675,
                -0.030367743223905563,
                -0.047985050827264786,
                -0.024814791977405548,
                0.009921048767864704,
                -0.03142557293176651,
                0.0700044184923172,
                0.019339947029948235,
                -0.05388777703046799,
                -0.011952349916100502,
                0.013720184564590454,
                0.020609954372048378,
                -0.06595882773399353,
                0.022789983078837395,
                -0.06186854466795921,
                0.056990716606378555,
                -0.010587479919195175,
                0.0026406454853713512,
                -0.033901166170835495,
                -0.012269174680113792,
                0.023663995787501335,
                -0.025832606479525566,
                0.05143772065639496,
                -0.038525551557540894,
                -0.01712948828935623,
                -0.0012578892055898905,
                0.03544556722044945,
                -0.032277707010507584,
                0.032175321131944656,
                0.011309990659356117,
                0.010050340555608273,
                0.009741492569446564,
                -0.03452342376112938,
                0.03218074515461922,
                0.01565544307231903,
                0.02129790000617504,
                -0.021445326507091522,
                -0.021272430196404457,
                0.03994743153452873,
                -0.014744476415216923,
                0.04668636992573738,
                0.03432857245206833,
                0.014748449437320232,
                0.04104507341980934,
                -0.007414500694721937,
                -0.0003600542841013521,
                0.03043191321194172,
                -0.006857079453766346,
                -0.054744068533182144,
                0.016067618504166603,
                0.0006852886872366071,
                -0.005680493079125881,
                -0.01362530142068863,
                0.02214052900671959,
                -0.01833011396229267,
                -0.07253262400627136,
                0.012374804355204105,
                -0.02737562172114849,
                0.015615302138030529,
                -0.05502573400735855,
                0.02763637900352478,
                0.007205248810350895,
                0.05386747792363167,
                -0.018251635134220123,
                0.027422625571489334,
                -0.049708638340234756,
                -0.015126106329262257,
                0.02876419760286808,
                0.004421391524374485,
                -0.028013406321406364,
                -0.0025308181066066027,
                -0.044494692236185074,
                -0.03131679818034172,
                0.022035660222172737,
                -0.007757204584777355,
                -0.013725845143198967,
                -0.03289360925555229,
                -0.06255383044481277,
                -0.04954203963279724,
                0.0276328194886446,
                0.021510722115635872,
                0.010191215202212334,
                -0.03184361755847931,
                -0.05306847393512726,
                0.045493774116039276,
                0.11780720949172974,
                -0.05708041414618492,
                -0.045335553586483,
                0.011758552864193916,
                -0.026476075872778893,
                0.07731921970844269,
                -0.027224881574511528,
                -0.038670219480991364,
                -0.054113857448101044,
                0.023466331884264946,
                -0.07942666113376617,
                -0.0617380291223526,
                0.05890102684497833,
                -0.005100022070109844,
                0.017842790111899376,
                0.031309228390455246,
                0.012873855419456959,
                -0.027571721002459526,
                -0.029353125020861626,
                -0.03784205764532089,
                0.008987007662653923,
                0.0635339617729187,
                0.0073282127268612385,
                0.019224533811211586,
                -0.03247030824422836,
                -0.06930533051490784,
                0.008635065518319607,
                -0.012231814675033092,
                0.020875653252005577,
                0.018493197858333588,
                0.0034955977462232113,
                0.020996415987610817,
                0.019590431824326515,
                -0.00013247979222796857,
                0.0009048599749803543,
                0.009490134194493294,
                0.04444604739546776,
                0.043709248304367065,
                0.017729775980114937,
                -0.006931121461093426,
                0.007067941594868898,
                0.004880121909081936,
                -0.009168731980025768,
                0.02856229618191719,
                -0.05933273211121559,
                0.05996819958090782,
                -0.03629785031080246,
                0.00014890619786456227,
                -0.057173941284418106,
                0.028870511800050735,
                -0.005568676628172398,
                -0.02441854029893875,
                0.04935990646481514,
                -0.0924801230430603,
                0.030725795775651932,
                -0.0045664990320801735,
                -0.03285788744688034,
                -0.03901166841387749,
                -0.053653284907341,
                -0.004808467347174883,
                0.002984732622280717,
                -0.01125666219741106,
                0.0022282314021140337,
                0.006845717318356037,
                0.04900837689638138,
                0.039218951016664505,
                0.00857567973434925,
                -0.01747407205402851,
                0.04050454497337341,
                0.01054440438747406,
                0.005617466755211353,
                -0.012086881324648857,
                -0.012416714802384377,
                0.016416063532233238,
                0.04581168666481972,
                0.0036498531699180603,
                -0.05126730352640152,
                0.007663927040994167,
                -0.023505782708525658,
                0.005963501986116171,
                0.018916180357336998,
                0.019005170091986656,
                0.03663752228021622,
                -0.003949437756091356,
                0.00515260873362422,
                0.0046057128347456455,
                0.03805689513683319,
                -0.01385561190545559,
                0.024463484063744545,
                0.0026641443837434053,
                0.023244863376021385,
                -0.001436062389984727,
                0.037137433886528015,
                0.06184249371290207,
                0.0766250342130661,
                -0.037182461470365524,
                0.005822467152029276,
                0.07443410158157349,
                0.033336542546749115,
                -0.010852595791220665,
                -0.02256021834909916,
                -0.0002874148776754737,
                0.028419092297554016,
                -0.007682018913328648,
                0.0034620536025613546,
                0.033374350517988205,
                0.05967869609594345,
                0.023228395730257034,
                0.022606853395700455,
                -0.03038869984447956,
                -4.820376867723817e-33,
                -0.010122505016624928,
                -0.043401725590229034,
                0.044965241104364395,
                -0.046987034380435944,
                0.028681721538305283,
                -0.002592766424641013,
                0.002820126013830304,
                -0.008775430731475353,
                -0.07223666459321976,
                -0.018478715792298317,
                -0.04113028571009636,
                -0.0144035117700696,
                0.014103999361395836,
                0.0029555116780102253,
                0.05666722357273102,
                0.07278941571712494,
                0.019893024116754532,
                0.03590485081076622,
                0.0025430009700357914,
                -0.02561195008456707,
                0.015031361021101475,
                -0.029745854437351227,
                0.00016346469055861235,
                0.024056047201156616,
                -0.016214916482567787,
                -0.0031599809881299734,
                0.0032657755073159933,
                -0.030842270702123642,
                -0.02541867271065712,
                0.0052834805101156235,
                0.026765093207359314,
                -0.00784673448652029,
                -0.014317424967885017,
                0.011947838589549065,
                -0.01785539649426937,
                -0.03518012911081314,
                0.02479843609035015,
                -0.009275593794882298,
                -0.02112734690308571,
                -0.010162201710045338,
                -0.02902337908744812,
                0.02205268107354641,
                -0.03288145363330841,
                0.023627784103155136,
                -0.07610462605953217,
                -0.07213307172060013,
                -0.030700741335749626,
                0.005421164445579052,
                0.024682680144906044,
                -0.004964662715792656,
                -0.016161005944013596,
                -0.023707615211606026,
                0.016972176730632782,
                0.024608837440609932,
                -0.016646243631839752,
                -0.08352233469486237,
                -0.013778714463114738,
                -0.038057245314121246,
                0.027417117729783058,
                0.045555513352155685,
                0.05318664759397507,
                0.07063404470682144,
                0.05169970914721489,
                -0.07977602630853653,
                -0.01901618205010891,
                0.00690331170335412,
                0.005671078804880381,
                -0.005951963365077972,
                0.035683754831552505,
                -0.05875459685921669,
                -0.02612256444990635,
                0.07956377416849136,
                -0.0022300623822957277,
                0.024872275069355965,
                -0.03554339334368706,
                -0.044803932309150696,
                -0.01866420917212963,
                -0.045246049761772156,
                0.037399813532829285,
                0.04769941791892052,
                -0.059038300067186356,
                0.027096642181277275,
                0.031728457659482956,
                -0.005293157882988453,
                -0.03353102505207062,
                -0.005304448306560516,
                0.020640157163143158,
                0.05394807457923889,
                0.02152140438556671,
                -0.011782464571297169,
                0.008958764374256134,
                -0.0007066483376547694,
                -0.00028652703622356057,
                -0.014626284129917622,
                0.0254988893866539,
                0.005006139632314444,
                0.022840755060315132,
                0.011479494161903858,
                0.020589616149663925,
                0.009901844896376133,
                -0.047136418521404266,
                -0.013177926652133465,
                0.016747957095503807,
                -0.032912567257881165,
                -0.016501203179359436,
                0.04741709306836128,
                0.005937544628977776,
                -0.028911102563142776,
                0.023123880848288536,
                -0.02420768514275551,
                -0.004456022754311562,
                -0.030167438089847565,
                0.03186929225921631,
                0.02857748419046402,
                0.03920316696166992,
                -0.0069517106749117374,
                0.016890596598386765,
                -0.07998526841402054,
                -0.027555471286177635,
                0.02461194433271885,
                0.04952474310994148,
                -0.05075294151902199,
                -0.004564299248158932,
                -0.0009577596792951226,
                -0.012548139318823814,
                -0.017162716016173363,
                0.07965869456529617,
                -0.07445348054170609,
                -0.042815566062927246,
                -0.010148201137781143,
                0.024722658097743988,
                -0.05532676726579666,
                1.7715679234697745e-07,
                0.019788889214396477,
                0.11473467200994492,
                0.02065691538155079,
                0.051165226846933365,
                -0.017821403220295906,
                -0.041633039712905884,
                0.013259360566735268,
                0.00639456557109952,
                0.013940734788775444,
                0.01057347934693098,
                -0.03086523525416851,
                -0.01885172724723816,
                -0.045157674700021744,
                -0.07558294385671616,
                0.043413445353507996,
                -0.03265703469514847,
                0.03250670060515404,
                -0.006687223445624113,
                0.0021923864260315895,
                -0.0488959364593029,
                -0.047375209629535675,
                -0.025434032082557678,
                0.01155636366456747,
                -0.03144557401537895,
                0.01647026836872101,
                0.031660690903663635,
                -0.028238892555236816,
                0.02817896194756031,
                0.06843405961990356,
                0.0031009691301733255,
                0.03241071105003357,
                0.024619320407509804,
                0.055070213973522186,
                0.06298626214265823,
                0.01064231339842081,
                -0.007732066325843334,
                0.059424348175525665,
                0.07109711319208145,
                -0.03290111944079399,
                0.07296397536993027,
                -0.029369575902819633,
                0.055061787366867065,
                0.0012773724738508463,
                0.021561315283179283,
                0.006220102775841951,
                0.03138405457139015,
                0.008578497916460037,
                -0.04720087721943855,
                -0.09363196045160294,
                0.0008831194136291742,
                -0.0875687226653099,
                0.03345281258225441,
                -0.0393986813724041,
                -0.026690902188420296,
                0.03777341917157173,
                0.039033129811286926,
                -0.02047162875533104,
                0.04807658866047859,
                0.060602765530347824,
                -0.007018336560577154,
                0.019837824627757072,
                -0.06703954190015793,
                -0.039697352796792984,
                0.017690768465399742,
                0.10341142863035202,
                -0.09781762212514877,
                -0.050677340477705,
                8.846175849450913e-35,
                0.0141242491081357,
                -0.01448590774089098,
                0.03233623877167702,
                -0.043514735996723175,
                -0.03194574639201164,
                -0.04421406239271164,
                0.04773006960749626,
                0.0008569029159843922,
                0.013106625527143478,
                -0.0035658415872603655,
                -0.050164323300123215
            ]
        },
        "Education": {
            "name": "BSc/COMPUTER SCIENCE",
            "rep": [
                -0.010190309025347233,
                -0.0556277297437191,
                -0.044325340539216995,
                -0.014039619825780392,
                0.054592594504356384,
                0.021114492788910866,
                -0.023687880486249924,
                -0.010503316298127174,
                0.08866269141435623,
                -0.019638419151306152,
                0.0745275542140007,
                -0.011230988427996635,
                0.016253434121608734,
                0.1059107705950737,
                0.029484882950782776,
                -0.0062918346375226974,
                0.007145220413804054,
                -0.029386254027485847,
                -0.048838019371032715,
                -0.04137830063700676,
                -0.06277507543563843,
                0.01003778725862503,
                -0.04177102819085121,
                0.01436684001237154,
                -0.003582026343792677,
                -0.05455963686108589,
                0.007322574965655804,
                0.020062969997525215,
                -0.002527398755773902,
                0.07009371370077133,
                0.025250449776649475,
                -0.026596015319228172,
                -0.009637388400733471,
                0.009965120814740658,
                1.1115923825855134e-06,
                -0.04932378977537155,
                -0.02421875298023224,
                0.07376903295516968,
                -0.0030402997508645058,
                -0.05053561180830002,
                0.01925019547343254,
                0.07789022475481033,
                0.021261464804410934,
                0.05701776593923569,
                0.026478732004761696,
                0.056993722915649414,
                0.04066768288612366,
                0.005472057033330202,
                -0.012104347348213196,
                0.014670896343886852,
                0.018739689141511917,
                -0.06357510387897491,
                -0.04844248667359352,
                -0.014287608675658703,
                -0.053244322538375854,
                -0.0408441387116909,
                0.0206600371748209,
                0.02270326018333435,
                0.04506295174360275,
                0.06173575669527054,
                -0.0077133201994001865,
                -0.05319250002503395,
                -0.051791634410619736,
                -0.003487327601760626,
                0.02676876075565815,
                0.06876754015684128,
                0.014816750772297382,
                0.008175820112228394,
                -0.013455193489789963,
                -0.04846702143549919,
                -0.009242304600775242,
                -0.028783220797777176,
                0.02446475438773632,
                0.03937104716897011,
                -0.0031883472111076117,
                -0.012924719601869583,
                -0.008815121836960316,
                0.019793832674622536,
                -0.022617913782596588,
                -0.01700587384402752,
                -0.009878997690975666,
                0.02711476758122444,
                -0.01389871072024107,
                0.030511334538459778,
                -0.024251729249954224,
                0.04546959698200226,
                0.024797264486551285,
                -0.011060589924454689,
                0.013226532377302647,
                0.005397162400186062,
                0.029037855565547943,
                0.003168594790622592,
                -0.0028434342239052057,
                0.01145573053508997,
                -0.06505216658115387,
                -0.03697587177157402,
                0.05841737613081932,
                0.027887271717190742,
                -0.031653858721256256,
                0.005185173824429512,
                0.009549953043460846,
                -0.02884834259748459,
                -0.01896793767809868,
                0.053901948034763336,
                0.021836889907717705,
                0.015188478864729404,
                0.024984559044241905,
                0.03583553805947304,
                0.027339046820998192,
                0.02849578484892845,
                -0.02593824453651905,
                -0.020624319091439247,
                0.004750909749418497,
                0.01902475394308567,
                0.009622043929994106,
                0.07934314012527466,
                -0.026296010240912437,
                0.029025454074144363,
                -0.012556293047964573,
                0.020761985331773758,
                0.0025169504806399345,
                -0.012754458002746105,
                -0.017646348103880882,
                0.034113842993974686,
                0.023340962827205658,
                -0.016699565574526787,
                -0.015731394290924072,
                0.00949405413120985,
                0.04904266819357872,
                0.018525538966059685,
                -0.027683045715093613,
                -0.03086691163480282,
                -0.0426221638917923,
                -0.01675816811621189,
                -0.003950513433665037,
                0.006029683165252209,
                -0.03358779847621918,
                0.11467701941728592,
                0.0034563906956464052,
                -0.022413255646824837,
                -0.04026133939623833,
                -0.05027769133448601,
                0.009066705591976643,
                0.03246200457215309,
                -0.05014092102646828,
                0.024569286033511162,
                -0.005772633943706751,
                -0.040405575186014175,
                -0.0004676220705732703,
                0.02706191875040531,
                -0.033202022314071655,
                0.02010972984135151,
                0.024686001241207123,
                -0.06237350031733513,
                0.03166739270091057,
                0.022063467651605606,
                0.03306911885738373,
                -0.025379231199622154,
                -0.04216798394918442,
                0.08421150594949722,
                -0.029581638053059578,
                -0.008577544242143631,
                0.032143596559762955,
                0.014441202394664288,
                -0.04000230133533478,
                -0.03982499614357948,
                -0.04171997308731079,
                0.0037701292894780636,
                -0.0020154304802417755,
                -0.02707279846072197,
                -0.02840055152773857,
                -0.04231442138552666,
                -0.0035986644215881824,
                0.04864801466464996,
                -0.020297391340136528,
                -0.009111424908041954,
                0.04711785167455673,
                0.045932870358228683,
                -0.01581563986837864,
                0.006737628951668739,
                0.007602607365697622,
                -0.048431191593408585,
                0.023487720638513565,
                0.04014666751027107,
                0.004305110778659582,
                -0.0016985742840915918,
                -0.02255391515791416,
                0.011251852847635746,
                -0.03873537480831146,
                0.012189334258437157,
                0.02465391345322132,
                -0.000346238783095032,
                -0.06752245873212814,
                -0.010543090291321278,
                0.011870141141116619,
                -0.008371659554541111,
                -0.0015970157692208886,
                -0.026982976123690605,
                0.008286645635962486,
                -0.03735940903425217,
                -0.010024701245129108,
                0.012318453751504421,
                0.01349963154643774,
                0.026491496711969376,
                0.016144882887601852,
                -0.07351956516504288,
                0.00940486416220665,
                -0.045123837888240814,
                -0.023926297202706337,
                0.039308544248342514,
                -0.007947497069835663,
                -0.01741969585418701,
                0.01796197146177292,
                0.009713643230497837,
                -0.015622366219758987,
                -0.033256035298109055,
                0.021627536043524742,
                -0.010300271213054657,
                -0.03127007186412811,
                -0.03708334639668465,
                -0.017928024753928185,
                0.05480475723743439,
                0.028389882296323776,
                -0.017591649666428566,
                -0.028398986905813217,
                0.0440375991165638,
                0.02841648831963539,
                -0.027211671695113182,
                0.04306354001164436,
                0.04345853999257088,
                -0.0066671292297542095,
                -0.026989523321390152,
                0.04186248406767845,
                0.006372138392180204,
                0.005921514704823494,
                -0.04546578228473663,
                0.06267145276069641,
                -0.06906819343566895,
                0.03304503485560417,
                -0.04414353147149086,
                -0.01951061561703682,
                0.0178412813693285,
                0.009226356633007526,
                -0.018091848120093346,
                0.08569608628749847,
                -0.01330661028623581,
                -0.03492854908108711,
                0.0319841243326664,
                0.038695190101861954,
                -0.04914773628115654,
                -0.05014759674668312,
                0.023098938167095184,
                0.040426578372716904,
                0.0697549358010292,
                0.012012552469968796,
                0.01645568385720253,
                -0.07936900109052658,
                0.05104460567235947,
                -0.038468409329652786,
                -0.038975510746240616,
                -0.028279349207878113,
                -0.041445087641477585,
                0.03581710159778595,
                0.03225962445139885,
                0.008004086092114449,
                -0.006748346146196127,
                -0.004617393482476473,
                -0.01812947914004326,
                -0.04780095815658569,
                0.0337461493909359,
                -0.01103228610008955,
                0.045588742941617966,
                0.0023992438800632954,
                -0.0016840535681694746,
                -0.051628030836582184,
                0.019163738936185837,
                -0.017729712650179863,
                -0.01806122064590454,
                -0.05717989057302475,
                0.049667660146951675,
                0.02486439235508442,
                0.025303278118371964,
                -0.01055893674492836,
                -0.0399872325360775,
                -0.04039039462804794,
                -0.005976436659693718,
                0.03197336569428444,
                -0.029964841902256012,
                0.01955169253051281,
                0.04819634556770325,
                -0.022705622017383575,
                0.023004120215773582,
                -0.013168716803193092,
                -0.034738846123218536,
                -0.02333446405827999,
                0.04085398465394974,
                -0.045960504561662674,
                0.05117762088775635,
                0.003382399445399642,
                -0.014468137174844742,
                0.0018290767911821604,
                -0.013768337666988373,
                0.045774057507514954,
                0.043895598500967026,
                -0.01368621364235878,
                0.021278169006109238,
                -0.0033525717444717884,
                0.016397934406995773,
                0.0007759627769701183,
                0.003959507681429386,
                -0.01607314683496952,
                0.022461695596575737,
                0.049060847610235214,
                -0.016070539131760597,
                0.042398206889629364,
                -0.025362059473991394,
                -0.045909468084573746,
                0.018297787755727768,
                -0.0030402725096791983,
                -0.0009537970763631165,
                0.007718181703239679,
                0.011525820940732956,
                0.018275756388902664,
                -0.032140061259269714,
                0.011525217443704605,
                0.009406336583197117,
                -0.05728921294212341,
                0.01717560365796089,
                -0.02242429181933403,
                -0.06794188916683197,
                0.01867654360830784,
                -0.025045279413461685,
                -0.04225241020321846,
                -0.06297675520181656,
                0.01902914047241211,
                -0.03819134086370468,
                -0.03214646503329277,
                -0.0871550589799881,
                -0.02090744487941265,
                -0.012580275535583496,
                0.03906397894024849,
                0.008003435097634792,
                -0.056881967931985855,
                -0.0006711473688483238,
                0.03598799929022789,
                -0.005848850589245558,
                0.038176875561475754,
                0.003143573645502329,
                0.018107488751411438,
                -0.0021266485564410686,
                0.04586769640445709,
                0.030601385980844498,
                0.036916907876729965,
                -0.0025446899235248566,
                -0.01636102981865406,
                0.023205777630209923,
                0.05395430698990822,
                0.05552258715033531,
                -0.0009576526354067028,
                -0.025553103536367416,
                -0.076288603246212,
                -0.040268007665872574,
                -0.036326054483652115,
                -0.03207879140973091,
                0.010299315676093102,
                0.005491928663104773,
                0.06139370799064636,
                -0.027799898758530617,
                -0.021508077159523964,
                0.01540722232311964,
                0.001690422068350017,
                0.019276058301329613,
                -0.06109253317117691,
                0.024816464632749557,
                -0.03715597093105316,
                -0.0006054571713320911,
                -0.02126816287636757,
                0.011298827826976776,
                -0.09682564437389374,
                0.01828599162399769,
                0.05484320968389511,
                0.007642711047083139,
                0.046630896627902985,
                -0.02245132438838482,
                0.016576841473579407,
                -0.02334243804216385,
                0.009943665936589241,
                -0.005306641571223736,
                0.005934705026447773,
                -0.045914262533187866,
                -0.001781343831680715,
                -0.016166485846042633,
                -0.030162155628204346,
                0.012731091119349003,
                0.015241839922964573,
                0.010443427599966526,
                0.007650924846529961,
                -0.02126258797943592,
                0.007564505562186241,
                -0.007083315402269363,
                0.06404575705528259,
                -0.01784564182162285,
                0.04341043531894684,
                0.03562941029667854,
                0.01526852510869503,
                0.02710191160440445,
                0.06434626877307892,
                -0.02623668499290943,
                -0.09562630206346512,
                0.04211338609457016,
                0.0031631686724722385,
                -0.019352996721863747,
                0.010178107768297195,
                0.003187173046171665,
                -0.007905715145170689,
                -0.04932316392660141,
                0.017246782779693604,
                0.03023434430360794,
                -0.00712101673707366,
                -0.058179061859846115,
                0.026042992249131203,
                0.02246808260679245,
                0.060739755630493164,
                -0.02079513855278492,
                0.00834018923342228,
                -0.059560440480709076,
                -0.014893854968249798,
                0.014688151888549328,
                0.006104727741330862,
                -0.057361748069524765,
                0.011741111055016518,
                -0.03128642961382866,
                -0.04991411790251732,
                -0.01387794315814972,
                0.04063432663679123,
                -0.017933528870344162,
                -0.022908717393875122,
                0.019331209361553192,
                -0.053674083203077316,
                0.07550934702157974,
                -0.00016817948198877275,
                0.006787905003875494,
                -0.031052496284246445,
                -0.037882205098867416,
                0.03201299533247948,
                0.11988826096057892,
                -0.009637325070798397,
                -0.07643532007932663,
                -0.008085273206233978,
                -0.05479470640420914,
                0.08239424973726273,
                -0.06482309103012085,
                0.025231895968317986,
                -0.08226040005683899,
                -0.0251099094748497,
                -0.10912157595157623,
                -0.05882496014237404,
                0.048808708786964417,
                0.001432927674613893,
                -0.01031335536390543,
                0.03504161536693573,
                0.01613471657037735,
                -0.02470734342932701,
                -0.03450840339064598,
                -0.02632969245314598,
                -0.04978134110569954,
                0.04946792498230934,
                0.0025904143694788218,
                0.060721393674612045,
                0.007092517800629139,
                -0.09491502493619919,
                -0.016049345955252647,
                -0.023337025195360184,
                -0.03517384082078934,
                -0.00919923186302185,
                0.027980534359812737,
                0.015079505741596222,
                0.025001725181937218,
                0.02900567278265953,
                0.015783393755555153,
                0.02916117012500763,
                -0.008311456069350243,
                0.04222816973924637,
                -0.008340374566614628,
                0.009354143403470516,
                0.03567349165678024,
                0.006988083943724632,
                -0.011754288338124752,
                0.01634625531733036,
                -0.10217966884374619,
                0.06044754013419151,
                -0.026675621047616005,
                -0.009745088405907154,
                -0.06821394711732864,
                0.0226464681327343,
                -0.007073754910379648,
                -0.008127323351800442,
                0.009517334401607513,
                -0.049263279885053635,
                0.07433892786502838,
                -0.033475611358881,
                -0.0389251708984375,
                -0.01052771508693695,
                -0.08083908259868622,
                0.032299403101205826,
                -0.001574793946929276,
                -0.0177229605615139,
                -0.001672740443609655,
                0.0002903802087530494,
                0.027207760140299797,
                0.04689989238977432,
                0.02166651375591755,
                -0.0019993691239506006,
                0.0502568855881691,
                0.041460953652858734,
                0.006270634476095438,
                0.02713507041335106,
                0.0036336525809019804,
                0.02466130629181862,
                0.019055716693401337,
                0.03379344940185547,
                -0.05597926303744316,
                0.0474545955657959,
                0.01438147947192192,
                -0.004789409693330526,
                -0.024637088179588318,
                0.013148161582648754,
                -0.022633399814367294,
                0.055123500525951385,
                0.03342829644680023,
                0.000885883579030633,
                -0.024892069399356842,
                -0.012301404029130936,
                -0.025599801912903786,
                0.007260946556925774,
                0.04972976818680763,
                -0.013630498200654984,
                0.06405901908874512,
                0.044232845306396484,
                0.0941297635436058,
                -0.028308862820267677,
                -0.014886822551488876,
                0.07024787366390228,
                0.01129424013197422,
                -0.005706090480089188,
                -0.029628466814756393,
                0.04191712290048599,
                0.02476595714688301,
                -0.004658983554691076,
                0.01687798462808132,
                -0.002240603556856513,
                0.03118041157722473,
                0.040996260941028595,
                0.03684757277369499,
                -0.013220639899373055,
                -4.3849542910540564e-33,
                -0.04474491998553276,
                -0.029638154432177544,
                0.02791067771613598,
                0.002381138736382127,
                0.029195578768849373,
                8.365760004380718e-05,
                0.05046205595135689,
                -0.00978698581457138,
                -0.03304537385702133,
                -0.01922975480556488,
                -0.044061772525310516,
                0.017959028482437134,
                0.027093827724456787,
                0.022175336256623268,
                0.035691142082214355,
                0.05752000957727432,
                0.03259313106536865,
                0.09957165271043777,
                0.019979165866971016,
                -0.03155035153031349,
                -0.032977666705846786,
                -0.011399945244193077,
                0.006152127869427204,
                -0.003969512414187193,
                0.005757317878305912,
                -0.003907694946974516,
                -0.011345818638801575,
                -0.05765258148312569,
                -0.005943713244050741,
                -0.009763703681528568,
                0.018307548016309738,
                -0.004388926085084677,
                0.003939357120543718,
                0.04433705657720566,
                0.004943805281072855,
                -0.016919003799557686,
                0.0019368035718798637,
                -0.03500354662537575,
                -0.02978542633354664,
                0.015272580087184906,
                0.029882855713367462,
                -0.0063086627051234245,
                0.013043694198131561,
                0.004767079837620258,
                -0.059626657515764236,
                -0.0692395493388176,
                -0.015383219346404076,
                0.0558195598423481,
                0.0754571333527565,
                -0.028296299278736115,
                -0.023235661908984184,
                -0.02293514274060726,
                -0.03806404024362564,
                0.022494932636618614,
                -0.06672214716672897,
                -0.0523969903588295,
                -0.0051216608844697475,
                -0.00180112908128649,
                -0.0021459206473082304,
                0.060250479727983475,
                0.022596390917897224,
                0.05635691434144974,
                0.07500258833169937,
                -0.04929434880614281,
                0.008203977718949318,
                -0.020937582477927208,
                -0.01671188697218895,
                -0.00208932813256979,
                0.026281991973519325,
                -0.02474186196923256,
                -0.04513907805085182,
                0.0779801532626152,
                -0.06591856479644775,
                -0.023945312947034836,
                -0.043658677488565445,
                -0.06628956645727158,
                -0.02681775577366352,
                -0.04682059958577156,
                0.03847634792327881,
                0.008663050830364227,
                -0.05863385647535324,
                0.012443002313375473,
                0.008286647498607635,
                -0.006398016586899757,
                0.04312637820839882,
                -0.008352220058441162,
                0.036129146814346313,
                0.021884946152567863,
                0.03202786296606064,
                -0.014009653590619564,
                0.006925539113581181,
                0.037682026624679565,
                -0.05196743458509445,
                -0.021482445299625397,
                0.017447739839553833,
                -0.035684797912836075,
                0.018206419423222542,
                0.006752397865056992,
                0.0397082082927227,
                0.06549496203660965,
                -0.02096998691558838,
                -0.006632428616285324,
                0.0033346526324748993,
                -0.029082149267196655,
                0.010960704647004604,
                0.04453461617231369,
                -0.01201357040554285,
                0.012036309577524662,
                -0.012552283704280853,
                -0.03402482345700264,
                0.0011907605221495032,
                0.0286762323230505,
                -0.015521422028541565,
                0.045612823218107224,
                0.020380277186632156,
                0.00795096717774868,
                0.02644222043454647,
                -0.09658529609441757,
                -0.04070599004626274,
                0.010991849936544895,
                0.0037133553996682167,
                -0.07862190157175064,
                -0.020759504288434982,
                -0.006315666250884533,
                -0.006108828820288181,
                -0.01221683993935585,
                0.029516981914639473,
                -0.023477397859096527,
                -0.04761030897498131,
                -0.03949006274342537,
                0.00507590314373374,
                -0.00989264715462923,
                1.6722717077755078e-07,
                0.014505221508443356,
                0.09216108918190002,
                0.015860144048929214,
                0.0613747276365757,
                -0.0257617998868227,
                -0.03437277302145958,
                0.016850827261805534,
                0.055914368480443954,
                0.023960648104548454,
                0.03533587604761124,
                -0.049228522926568985,
                -0.009628036059439182,
                -0.029790932312607765,
                -0.09990747272968292,
                -0.012188179418444633,
                -0.026598947122693062,
                0.011767010204494,
                -0.011555233038961887,
                -0.05529668182134628,
                0.028350643813610077,
                -0.029805557802319527,
                0.02949485369026661,
                0.011096534319221973,
                -0.0163508802652359,
                -0.005793344229459763,
                -0.011749031953513622,
                -0.03638482093811035,
                0.02861689403653145,
                0.06288450956344604,
                0.010602578520774841,
                -0.001148574287071824,
                0.025517061352729797,
                0.023368528112769127,
                0.05922073870897293,
                0.014607264660298824,
                0.0018313912441954017,
                0.05368335172533989,
                0.040520310401916504,
                -0.020993812009692192,
                0.09840110689401627,
                -0.03921708092093468,
                -0.0029243212193250656,
                -0.009740062989294529,
                -0.002428435254842043,
                0.020525870844721794,
                0.041180871427059174,
                0.009425174444913864,
                -0.0147977564483881,
                -0.07196564972400665,
                -0.05724664777517319,
                -0.059833455830812454,
                0.050413377583026886,
                0.0022052109707146883,
                -0.05493853986263275,
                0.025411881506443024,
                -0.00996076874434948,
                -0.023239005357027054,
                0.04097728058695793,
                0.041375838220119476,
                -0.012469572015106678,
                -0.010346404276788235,
                -0.07937686145305634,
                0.0018091828096657991,
                0.021530291065573692,
                0.12345334887504578,
                -0.03580018877983093,
                -0.021908480674028397,
                1.0654751968362736e-34,
                0.028743479400873184,
                -0.01903846301138401,
                0.011389702558517456,
                0.00280662439763546,
                -0.005128119140863419,
                -0.009904528968036175,
                0.017896879464387894,
                -0.007024760358035564,
                0.009440082125365734,
                -0.012276184745132923,
                -0.051988136023283005
            ]
        },
        "Experience": {
            "name": "PROJECCT MANAGER",
            "rep": [
                0.01521939318627119,
                -0.02226266637444496,
                0.0002385694970143959,
                0.03406577929854393,
                0.0014198727440088987,
                0.026150645688176155,
                0.012381917797029018,
                0.009358195587992668,
                -0.07903998345136642,
                0.05018545314669609,
                0.013898074626922607,
                0.04783434048295021,
                -0.016615930944681168,
                0.06822844594717026,
                0.008912775665521622,
                -0.06300851702690125,
                0.004435330629348755,
                -0.0018067577620968223,
                0.007659983821213245,
                0.021568499505519867,
                0.03321727737784386,
                0.01728358119726181,
                -0.010697132907807827,
                0.03347519040107727,
                -0.0019921385683119297,
                -0.032971885055303574,
                0.006015014834702015,
                0.025334015488624573,
                0.03157556802034378,
                -0.03687942028045654,
                0.013531297445297241,
                0.014420387335121632,
                0.0014259718591347337,
                0.02507975697517395,
                1.66010795510374e-06,
                -0.016980357468128204,
                0.016919786110520363,
                -0.01759987697005272,
                -0.004537309519946575,
                0.03331133723258972,
                -0.005171968135982752,
                -0.05126623064279556,
                -0.008908798918128014,
                0.0002607818169053644,
                0.023508450016379356,
                0.011526517570018768,
                0.0005447702133096755,
                0.03887099772691727,
                0.03905143961310387,
                0.013009696267545223,
                0.0009202558430843055,
                0.03457695618271828,
                -0.012334340251982212,
                -0.021528340876102448,
                -0.03802997246384621,
                -0.11193856596946716,
                0.005992612335830927,
                -0.03780757263302803,
                0.027490055188536644,
                0.01206133235245943,
                0.010068405419588089,
                -0.02786155603826046,
                -0.00542623084038496,
                -0.01231852825731039,
                0.053740404546260834,
                0.034334078431129456,
                0.07182665169239044,
                -0.09345895797014236,
                0.02081349678337574,
                -0.03186165913939476,
                0.01783418096601963,
                0.03341110050678253,
                0.023408886045217514,
                0.029019564390182495,
                0.03886859863996506,
                -0.016825176775455475,
                -0.004697471857070923,
                0.017847783863544464,
                -0.05099016800522804,
                -0.02652970515191555,
                -0.0026920847594738007,
                0.006409160792827606,
                0.00015564863861072809,
                0.004909487906843424,
                -0.0035116812214255333,
                0.05607448145747185,
                -0.0003196148027200252,
                -0.04791788011789322,
                0.0659395307302475,
                0.044468607753515244,
                -0.02986365742981434,
                -0.015994518995285034,
                -0.011230346746742725,
                0.008323542773723602,
                -0.02617974951863289,
                -0.0040230643935501575,
                0.007501078303903341,
                -0.01983312889933586,
                -0.038275592029094696,
                0.025652902200818062,
                -0.05600527673959732,
                -0.009871832095086575,
                0.049938928335905075,
                0.042043037712574005,
                0.041532691568136215,
                0.023323968052864075,
                -0.011179803870618343,
                -0.015911191701889038,
                -0.0020656734704971313,
                0.022997191175818443,
                -0.029221149161458015,
                0.003582859644666314,
                -0.05160587653517723,
                0.0066948551684618,
                0.03761982172727585,
                0.018519839271903038,
                -0.03895189240574837,
                0.04614103585481644,
                0.013505573384463787,
                0.058656126260757446,
                0.06799566745758057,
                0.01272912323474884,
                -0.027063511312007904,
                -0.007182612549513578,
                -0.061655208468437195,
                -0.009523920714855194,
                -0.07417698949575424,
                -0.000921985600143671,
                -0.002222171053290367,
                0.015072274953126907,
                0.007568091619759798,
                0.00655120424926281,
                -0.016143634915351868,
                -0.008400428108870983,
                -0.011447104625403881,
                0.01987019181251526,
                -0.03399752825498581,
                0.006817501038312912,
                -0.06721947342157364,
                -0.02368239499628544,
                0.011992593295872211,
                -0.03618365153670311,
                -0.10830406844615936,
                0.009187573567032814,
                0.02385959029197693,
                -0.03446584939956665,
                -0.03965725749731064,
                0.03820628300309181,
                0.010250702500343323,
                -0.000976657960563898,
                0.03181925415992737,
                -0.03088289313018322,
                -0.00996423326432705,
                0.010908083990216255,
                0.04722892865538597,
                0.025067754089832306,
                -0.032780878245830536,
                -0.04573846608400345,
                -0.04580868408083916,
                0.03632853552699089,
                -0.015485831536352634,
                -0.011842241510748863,
                0.06914500147104263,
                0.03408181667327881,
                -0.041898004710674286,
                -0.01866370253264904,
                0.023758793249726295,
                0.01429163571447134,
                -0.044799890369176865,
                -0.04111417755484581,
                -0.012021052651107311,
                -0.00048571694060228765,
                -0.007345559075474739,
                0.05458927899599075,
                0.011413466185331345,
                -0.06563115119934082,
                0.051746588200330734,
                0.010966320522129536,
                0.0001003748839139007,
                -0.02890469692647457,
                -0.04113978520035744,
                -0.13449448347091675,
                0.030392490327358246,
                0.010595129802823067,
                -0.006426734384149313,
                0.019108278676867485,
                -0.08797027170658112,
                0.011161519214510918,
                -0.018060071393847466,
                0.01590314880013466,
                0.07455887645483017,
                -0.007333224173635244,
                -0.1201169490814209,
                0.003353641601279378,
                0.047734953463077545,
                -0.008071932010352612,
                -0.02609225921332836,
                0.0015616395976394415,
                -0.03184337541460991,
                0.02425387315452099,
                -0.0005687323864549398,
                -0.005899065639823675,
                -0.00024015989038161933,
                0.07990334182977676,
                -0.07926469296216965,
                -0.030982650816440582,
                0.0347692035138607,
                -0.01610972359776497,
                -0.05511189624667168,
                0.017128227278590202,
                0.05486760288476944,
                0.01970822550356388,
                0.025629734620451927,
                -0.00618333974853158,
                -0.042838457971811295,
                0.017284562811255455,
                0.01989322155714035,
                0.05926430597901344,
                -0.04911156743764877,
                0.033816974610090256,
                -0.005975519306957722,
                -0.0038763736374676228,
                0.11243363469839096,
                0.0005166648188605905,
                0.0579947829246521,
                -0.08343194425106049,
                -0.00021679700876120478,
                -0.018673090264201164,
                -0.01656034030020237,
                -0.012735899537801743,
                -0.009393447078764439,
                0.0173411276191473,
                0.05269954353570938,
                -0.024384599179029465,
                0.038248348981142044,
                0.01781035214662552,
                0.0209005456417799,
                -0.04898226261138916,
                0.001664581592194736,
                -0.0004957437049597502,
                -0.012834817171096802,
                -0.022960498929023743,
                -0.011189883574843407,
                -0.04716694727540016,
                0.056700464338064194,
                -0.03711749613285065,
                0.05474041402339935,
                0.028368210420012474,
                0.048656582832336426,
                -0.020065873861312866,
                -0.023976746946573257,
                0.0019415166461840272,
                0.06323019415140152,
                0.021535556763410568,
                0.010694606229662895,
                0.05982711538672447,
                -0.03725621476769447,
                -0.003980762325227261,
                -0.039479732513427734,
                -0.014402317814528942,
                0.023484081029891968,
                -0.010471779853105545,
                0.005861307494342327,
                0.006162741221487522,
                0.031186431646347046,
                0.009609583765268326,
                -0.033481109887361526,
                -0.008473634719848633,
                0.03285665065050125,
                -0.0012715788325294852,
                -0.017350509762763977,
                0.019405240193009377,
                0.06131168082356453,
                -0.018717462196946144,
                0.0009548838133923709,
                -0.013359871692955494,
                0.011765845119953156,
                0.020849697291851044,
                -0.013368132524192333,
                0.005954314488917589,
                0.038079869002103806,
                -0.012022717855870724,
                -0.02484099008142948,
                0.008814343251287937,
                0.03300011530518532,
                -0.02567017823457718,
                -0.01887558586895466,
                -0.034309063106775284,
                -0.039132196456193924,
                -0.016301188617944717,
                -0.043588701635599136,
                -0.023580534383654594,
                0.005920579191297293,
                0.03805876523256302,
                0.012527363374829292,
                0.01457283552736044,
                0.014874013140797615,
                0.008737487718462944,
                -0.04549958556890488,
                0.018221985548734665,
                -0.014017938636243343,
                0.013313530012965202,
                -0.027792947366833687,
                -0.027440940961241722,
                0.028787128627300262,
                -0.07224047183990479,
                0.026492798700928688,
                0.010363731533288956,
                0.009514565579593182,
                -0.023395543918013573,
                0.0471855029463768,
                0.04728151112794876,
                0.004375158809125423,
                -0.01274362113326788,
                0.02305024117231369,
                -0.0009873841190710664,
                0.017337176948785782,
                -0.00930587574839592,
                -0.006502887699753046,
                0.04624713212251663,
                -0.010979973711073399,
                -0.01641010493040085,
                0.016888350248336792,
                -0.01196097768843174,
                -0.011066313832998276,
                0.03767590969800949,
                -0.1287418007850647,
                0.009326837956905365,
                -0.016584627330303192,
                0.0016883393982425332,
                -0.02548571303486824,
                -0.03328448534011841,
                -0.05936082452535629,
                0.013282268308103085,
                -7.604097481817007e-05,
                -0.011466109193861485,
                -0.03736082464456558,
                -0.0946410596370697,
                -0.0144192511215806,
                -0.0036335496697574854,
                0.030154861509799957,
                -0.02599608153104782,
                -0.009516715072095394,
                -0.04705250635743141,
                -0.061423029750585556,
                -0.027184730395674706,
                0.019305851310491562,
                0.0740315318107605,
                -0.05439911037683487,
                0.0011073898058384657,
                0.04947859048843384,
                0.03795879706740379,
                0.00552211282774806,
                -0.007917369715869427,
                0.008844505064189434,
                -0.08093434572219849,
                -0.020452089607715607,
                -0.002682528691366315,
                -0.004187254700809717,
                -0.05423760786652565,
                0.0017951526679098606,
                -0.024971237406134605,
                0.002417526673525572,
                -0.08369171619415283,
                0.014793982729315758,
                0.014817229472100735,
                -0.020279303193092346,
                -0.024624478071928024,
                -0.010153468698263168,
                -0.02472793683409691,
                -0.0024908084888011217,
                -0.01489795558154583,
                0.00538265286013484,
                0.036765407770872116,
                -0.011333555914461613,
                -0.0741141065955162,
                -0.00409110588952899,
                0.020150627940893173,
                -0.01461302861571312,
                0.024772705510258675,
                0.0189677681773901,
                0.010101497173309326,
                0.04764379560947418,
                -0.01531024556607008,
                0.07794025540351868,
                0.008146269246935844,
                -0.0212231557816267,
                -0.015496861189603806,
                0.03904233127832413,
                -0.02052200958132744,
                0.03209977596998215,
                -0.056474436074495316,
                -0.036862850189208984,
                -0.004312766715884209,
                -0.020656445994973183,
                -0.036928899586200714,
                -0.032775647938251495,
                0.015932995826005936,
                0.02061961218714714,
                0.017090510576963425,
                0.03096282109618187,
                0.022828198969364166,
                0.027115430682897568,
                -0.0012503952020779252,
                -0.0383940264582634,
                -0.0647713914513588,
                0.13957175612449646,
                -0.05330154672265053,
                0.010006417520344257,
                -0.030411677435040474,
                0.02373821847140789,
                -0.046187080442905426,
                -0.004722097888588905,
                -0.003824440995231271,
                0.0077931503765285015,
                -0.020416490733623505,
                -0.017187731340527534,
                0.010565299540758133,
                0.003923965618014336,
                -0.01378648355603218,
                0.03542463481426239,
                0.05367876589298248,
                -0.00490896450355649,
                0.017655450850725174,
                0.013266381807625294,
                -0.004515463020652533,
                0.04959172010421753,
                0.024437135085463524,
                -3.564803409972228e-05,
                0.031797535717487335,
                0.005714478902518749,
                -0.012076414190232754,
                -0.04625498875975609,
                -0.008751089684665203,
                0.07180263102054596,
                0.00919947400689125,
                -0.056811969727277756,
                0.029770007357001305,
                0.03234123811125755,
                0.026584120467305183,
                -0.008751004934310913,
                0.05694136768579483,
                0.10209204256534576,
                -0.024431422352790833,
                -0.049361854791641235,
                -0.023522144183516502,
                0.010854706168174744,
                -0.01542688999325037,
                -0.08797430247068405,
                0.03698461875319481,
                0.014981853775680065,
                -0.03849644958972931,
                -0.006670494098216295,
                -0.04230082035064697,
                0.06768050044775009,
                0.00386452698148787,
                -0.10886205732822418,
                -0.027245808392763138,
                0.010142961516976357,
                -0.062282294034957886,
                0.04922671988606453,
                0.04668812453746796,
                -0.043505653738975525,
                0.02019975334405899,
                0.032342929393053055,
                0.014504790306091309,
                -0.009751014411449432,
                -0.06146583333611488,
                0.03184543177485466,
                -0.0528021864593029,
                0.020680153742432594,
                0.0015925186453387141,
                0.006170613691210747,
                -0.013844635337591171,
                0.011421156115829945,
                -0.05701422691345215,
                0.05735942721366882,
                0.021183716133236885,
                -0.012384661473333836,
                0.04057367146015167,
                0.004791439510881901,
                -0.03397760167717934,
                -0.020928578451275826,
                -0.07019762694835663,
                0.02222200483083725,
                0.011828641407191753,
                0.018711239099502563,
                -0.04042764753103256,
                -0.006441846489906311,
                0.04556797072291374,
                0.050723642110824585,
                -0.055778030306100845,
                -0.0002266796655021608,
                -0.07529474794864655,
                -0.015088419429957867,
                -0.02561449632048607,
                0.03333725780248642,
                -0.0851462185382843,
                0.012096535414457321,
                -0.06874410063028336,
                -0.0088674770668149,
                0.03627670183777809,
                -0.030012493953108788,
                0.008625512942671776,
                0.04744187742471695,
                0.028339464217424393,
                0.011533516459167004,
                0.01824728026986122,
                -0.03626696392893791,
                -0.010591840371489525,
                0.015113746747374535,
                -0.021173205226659775,
                -0.018397977575659752,
                0.020173771306872368,
                0.07659760862588882,
                0.052550122141838074,
                0.07489126920700073,
                -0.04102293401956558,
                -0.0012800006661564112,
                -0.015590899623930454,
                0.004972814116626978,
                -0.039689697325229645,
                0.01069024857133627,
                -0.04902181774377823,
                0.011735531501471996,
                -0.017920972779393196,
                0.025291962549090385,
                0.00941523164510727,
                -0.019359614700078964,
                0.026751644909381866,
                0.028098493814468384,
                -0.051199771463871,
                -0.01824173890054226,
                0.011958242394030094,
                -0.08674188703298569,
                0.08406591415405273,
                -0.04565991833806038,
                0.08062143623828888,
                0.015615446493029594,
                0.010219492018222809,
                -0.05129002407193184,
                0.01870887354016304,
                -0.04200835898518562,
                0.036384593695402145,
                0.022045327350497246,
                -0.01746338978409767,
                -0.004411687608808279,
                -0.019345248118042946,
                -0.05422164499759674,
                -0.012673914432525635,
                -0.024735579267144203,
                0.06730952113866806,
                0.030401399359107018,
                0.021735886111855507,
                -0.033997610211372375,
                -5.672774473937106e-33,
                -0.0545235238969326,
                -0.05039737746119499,
                -0.0054375468753278255,
                0.016867775470018387,
                -0.02918488159775734,
                -0.057287391275167465,
                0.016580739989876747,
                0.02649175375699997,
                -0.0010120092192664742,
                -0.0019935767631977797,
                -0.0023076378274708986,
                -0.015960127115249634,
                0.01842319965362549,
                -0.02521641179919243,
                0.0526871457695961,
                0.04758898541331291,
                0.020601073279976845,
                -0.015770399942994118,
                -0.012539776973426342,
                -0.0036695802118629217,
                -0.0599704384803772,
                0.028501208871603012,
                0.023325547575950623,
                0.05040512606501579,
                -0.02553653158247471,
                -0.0097635043784976,
                0.014882327988743782,
                0.07236140966415405,
                0.012536234222352505,
                -0.008422525599598885,
                0.0409427285194397,
                0.006152835208922625,
                -0.01374901831150055,
                0.05259385704994202,
                0.003996201790869236,
                0.06867104768753052,
                0.006997858174145222,
                -0.012548613362014294,
                -0.0074622551910579205,
                0.02943085879087448,
                -0.05304127559065819,
                0.005304962862282991,
                0.007593831047415733,
                -0.0108796376734972,
                0.015475665219128132,
                0.04319068044424057,
                0.006782035809010267,
                -0.006812825798988342,
                -0.0125123905017972,
                0.009292978793382645,
                0.00614382978528738,
                -0.002265651011839509,
                -0.01560191623866558,
                0.014243393205106258,
                0.012813919223845005,
                -0.014053122140467167,
                0.0038985738065093756,
                -0.035021666437387466,
                -0.004218128509819508,
                -0.014341462403535843,
                -0.025162067264318466,
                -0.00682348245754838,
                0.025522461161017418,
                0.06253805756568909,
                -0.006443279795348644,
                -0.004457002505660057,
                -0.030524754896759987,
                0.037171609699726105,
                0.0495900958776474,
                0.054822005331516266,
                -0.03074062056839466,
                0.05912601575255394,
                0.004174711182713509,
                -0.06644011288881302,
                -0.01686158776283264,
                -0.005568791646510363,
                0.04590783268213272,
                -0.004892149940133095,
                0.0844137892127037,
                -0.04612734541296959,
                -0.06810278445482254,
                -0.02059568092226982,
                0.03866039961576462,
                -0.02523256465792656,
                0.021254321560263634,
                -0.026793720200657845,
                -0.036803726106882095,
                0.019664065912365913,
                0.019061462953686714,
                0.020310088992118835,
                -0.03454437106847763,
                0.03287841007113457,
                -0.011505240574479103,
                0.0007577692740596831,
                0.00017841260705608875,
                -0.0657004863023758,
                0.022570108994841576,
                -0.003736139042302966,
                -0.0090596042573452,
                0.015919068828225136,
                -0.08243640512228012,
                0.02011265605688095,
                -0.06205807626247406,
                0.05632002279162407,
                0.015469267964363098,
                0.012305444106459618,
                -0.052464671432971954,
                -0.04709099233150482,
                -0.04015662521123886,
                0.017825685441493988,
                -0.0001517890632385388,
                0.019224148243665695,
                -0.003385283285751939,
                -0.019529268145561218,
                0.0005766034009866416,
                -0.014657649211585522,
                0.008029692806303501,
                0.009757954627275467,
                0.0341300442814827,
                -0.12479595094919205,
                0.005545562133193016,
                0.01608291082084179,
                0.02727137878537178,
                -0.014002733863890171,
                -0.017903439700603485,
                0.00654942449182272,
                0.007164416369050741,
                0.07024885714054108,
                0.06314516812562943,
                -0.003037929069250822,
                0.021901478990912437,
                -0.029730621725320816,
                2.2852674419482355e-07,
                0.026961850002408028,
                0.05852440372109413,
                0.007197929080575705,
                0.0066214920952916145,
                0.0196863804012537,
                -0.015039131976664066,
                -0.01579323783516884,
                0.007333224639296532,
                -0.031428173184394836,
                -0.03892093896865845,
                0.026100721210241318,
                -0.0010441441554576159,
                0.0034948932006955147,
                0.02060844749212265,
                0.006282302085310221,
                -0.011108060367405415,
                -0.022614283487200737,
                0.03498625382781029,
                -0.09551989287137985,
                -0.01751219667494297,
                0.07468578219413757,
                0.033987101167440414,
                0.04574505239725113,
                -0.004940370097756386,
                0.0024784929119050503,
                -0.026981133967638016,
                0.018294963985681534,
                0.003102716989815235,
                0.05236686021089554,
                -0.011443577706813812,
                0.1044946014881134,
                0.056456468999385834,
                -0.021381821483373642,
                0.04205185920000076,
                0.016330309212207794,
                -0.019139070063829422,
                0.008160221390426159,
                0.06385663151741028,
                0.001769137685187161,
                0.007509944960474968,
                0.04942082613706589,
                -0.065175861120224,
                -0.025372063741087914,
                0.04650239273905754,
                -0.04578474164009094,
                0.004366766661405563,
                0.008955528028309345,
                0.05253355950117111,
                -0.06481102854013443,
                -0.0697103962302208,
                0.027491627261042595,
                0.02570166066288948,
                0.009245049208402634,
                0.06323614716529846,
                -0.005152704194188118,
                -0.012849424965679646,
                -0.048062585294246674,
                0.07352184504270554,
                0.00419967994093895,
                0.055371809750795364,
                -0.023136062547564507,
                -0.03320189565420151,
                -0.012163891457021236,
                -0.008720668032765388,
                -0.006176629103720188,
                0.026125527918338776,
                -0.015831831842660904,
                1.6200441589898285e-34,
                -0.0017484585987403989,
                -0.027062885463237762,
                -0.0028857807628810406,
                -0.05368846654891968,
                0.008907673880457878,
                0.012301212176680565,
                -0.03980112820863724,
                0.004769148305058479,
                -0.0013321464648470283,
                -0.029314372688531876,
                -0.00274282181635499
            ]
        },
        "Freelance": "Open to both",
        "Id": "482349bd-d463-482f-a458-6fdf0d3eec16",
        "Industry": {
            "name": "\tBioenergy",
            "rep": [
                0.07769370079040527,
                -0.005833006463944912,
                -0.01947455294430256,
                0.01913745515048504,
                -0.0045487890020012856,
                0.015439243987202644,
                -0.04848156496882439,
                0.05133933201432228,
                -0.03718943148851395,
                -0.020587919279932976,
                0.012728930450975895,
                0.027028651908040047,
                -0.0034611027222126722,
                0.031154338270425797,
                0.04978047311306,
                0.013662262819707394,
                0.08140690624713898,
                0.033506494015455246,
                -0.028240883722901344,
                -0.02238653600215912,
                -0.06964445114135742,
                -0.006685284432023764,
                0.0010355490958318114,
                0.04251566529273987,
                0.02488165907561779,
                0.040067318826913834,
                -0.01799609512090683,
                0.09161915630102158,
                0.026532072573900223,
                -0.01134824100881815,
                0.02127683535218239,
                -0.021407829597592354,
                -0.002684325445443392,
                0.00869807694107294,
                1.5375848079202115e-06,
                -0.06089668348431587,
                -0.04463314637541771,
                -0.021655479446053505,
                0.007336725015193224,
                -0.019927019253373146,
                0.04831172898411751,
                0.034228358417749405,
                -0.0020625467877835035,
                0.002090683439746499,
                0.05299313738942146,
                -0.05116841197013855,
                0.04753710329532623,
                -0.043239936232566833,
                -0.009483976289629936,
                -0.04148607328534126,
                -0.03434760123491287,
                -0.0033769700676202774,
                -0.016328485682606697,
                -0.025654282420873642,
                -0.04874476045370102,
                0.13100239634513855,
                -0.00514557771384716,
                -0.0376138836145401,
                -0.0032449616119265556,
                0.03564808517694473,
                -0.016830720007419586,
                0.06566894799470901,
                -0.03044041059911251,
                0.017063304781913757,
                0.07950837165117264,
                -0.01751537248492241,
                0.06326387077569962,
                -0.028281664475798607,
                0.041217781603336334,
                0.017141619697213173,
                0.014585047028958797,
                0.0051641506142914295,
                0.02582569234073162,
                0.044499967247247696,
                -0.049750424921512604,
                0.030383842065930367,
                0.058106061071157455,
                0.01475544273853302,
                -0.017628243193030357,
                0.0355837419629097,
                0.0015702785458415747,
                -0.029105110093951225,
                0.01595817692577839,
                -0.0025663282722234726,
                -0.009008453227579594,
                0.039504535496234894,
                -0.018601926043629646,
                -0.009344013407826424,
                0.04310806095600128,
                0.02630685456097126,
                0.05357867479324341,
                0.010644734837114811,
                0.008856864646077156,
                0.014530546963214874,
                0.07876280695199966,
                0.015942832455039024,
                -0.03868340700864792,
                0.09380640834569931,
                0.0008291350677609444,
                -0.015137210488319397,
                0.010731657035648823,
                -0.013618195429444313,
                0.04818231612443924,
                0.013922009617090225,
                -0.01977931708097458,
                -0.07702916115522385,
                -0.04054061695933342,
                -0.03187471628189087,
                -0.014399915933609009,
                -0.04717715457081795,
                0.012753316201269627,
                -0.013910778798162937,
                0.016896726563572884,
                0.006832195445895195,
                -0.0031564240343868732,
                -0.012732420116662979,
                -0.005643132142722607,
                -0.05255484953522682,
                -0.03998179733753204,
                -0.011227678507566452,
                0.01128085795789957,
                -0.0019573059398680925,
                0.03902939707040787,
                -5.530627822736278e-05,
                0.016656704246997833,
                0.05514170229434967,
                0.05911595746874809,
                0.027845323085784912,
                0.012282361276447773,
                -0.00695137120783329,
                -0.017577217891812325,
                -0.026996254920959473,
                -0.02245244011282921,
                0.0018740928499028087,
                -0.07004115730524063,
                0.05290386453270912,
                0.009772643446922302,
                -0.02403217926621437,
                -0.010829712264239788,
                -0.06015980243682861,
                0.0043336497619748116,
                -0.006039682310074568,
                -0.05556667968630791,
                0.022454552352428436,
                -0.004946937318891287,
                0.007883964106440544,
                0.039817970246076584,
                0.011738952249288559,
                0.030646145343780518,
                0.00043363607255741954,
                0.037956539541482925,
                0.0015394212678074837,
                -0.05766843259334564,
                0.014932656660676003,
                0.038172364234924316,
                0.01400343980640173,
                -0.04836254566907883,
                -0.03595392033457756,
                0.045366570353507996,
                0.0059347315691411495,
                -0.05416341871023178,
                0.016924792900681496,
                -0.03175705298781395,
                -0.016456445679068565,
                0.02911885641515255,
                -0.06023884937167168,
                -0.008503403514623642,
                -0.03783734142780304,
                -0.12460191547870636,
                -0.002340675098821521,
                0.03658510372042656,
                -0.06697295606136322,
                -0.04154273122549057,
                0.07161188125610352,
                0.007041345816105604,
                0.04506832733750343,
                0.040407050400972366,
                -0.02166597917675972,
                0.023374594748020172,
                -0.02258004993200302,
                0.011356103233993053,
                -0.07561272382736206,
                0.024082666262984276,
                -0.019536346197128296,
                -0.03744443878531456,
                -0.03163335099816322,
                0.050437428057193756,
                0.06156396493315697,
                0.03655551001429558,
                -0.034061044454574585,
                -0.004823030438274145,
                0.017098579555749893,
                -0.0005987699260003865,
                -0.005984135437756777,
                0.01771811582148075,
                0.04090520739555359,
                0.005830228328704834,
                0.04753405228257179,
                0.02659468911588192,
                -0.023265007883310318,
                0.019697412848472595,
                -0.0027878570836037397,
                -0.01602776348590851,
                -0.006665061693638563,
                -0.014461761340498924,
                0.017532004043459892,
                -0.016791386529803276,
                -0.0030751482117921114,
                -0.08955687284469604,
                -0.030050143599510193,
                -0.013572483323514462,
                -0.012628640979528427,
                -0.01763373240828514,
                0.043742477893829346,
                0.006689457688480616,
                -0.016887938603758812,
                -0.012522051110863686,
                -0.009399570524692535,
                -0.0898999571800232,
                0.0318705253303051,
                0.00029263622127473354,
                0.015905065461993217,
                0.019502010196447372,
                -0.009124604985117912,
                0.009242100641131401,
                0.0484689436852932,
                -0.05914345011115074,
                -0.012369616888463497,
                0.04590630158782005,
                0.006699858233332634,
                0.014293249696493149,
                0.0013552872696891427,
                0.06011122465133667,
                0.0074019418098032475,
                -0.0334511399269104,
                0.03297780454158783,
                0.053606048226356506,
                -0.001615356421098113,
                0.006846006028354168,
                0.023095199838280678,
                -0.03258242830634117,
                0.05139971897006035,
                0.061979036778211594,
                0.02085976116359234,
                0.037851978093385696,
                -2.213187144661788e-05,
                0.03687278553843498,
                0.005909954663366079,
                0.044575128704309464,
                0.021897345781326294,
                -0.0018799907993525267,
                0.010550325736403465,
                0.021379277110099792,
                0.047089386731386185,
                0.06397800147533417,
                -0.057579852640628815,
                0.00952207576483488,
                0.03376948833465576,
                -0.026998037472367287,
                0.023148512467741966,
                0.06551330536603928,
                -0.06952476501464844,
                -0.0489029735326767,
                0.02252940647304058,
                -0.004206588491797447,
                -0.03214092552661896,
                0.1049807220697403,
                -0.03526860475540161,
                -0.011357957497239113,
                -0.014195509254932404,
                0.010462685488164425,
                -0.00888054445385933,
                0.020822525024414062,
                0.02537834271788597,
                -0.002496645785868168,
                0.007408022414892912,
                0.04006671905517578,
                0.016252389177680016,
                -0.05577724799513817,
                -0.0023403975646942854,
                0.010694053955376148,
                0.026945561170578003,
                -0.01403158251196146,
                0.01039139460772276,
                -0.04473794996738434,
                0.09322294592857361,
                0.010355946607887745,
                -0.01117311604321003,
                -0.05787530168890953,
                0.009537608362734318,
                0.029031189158558846,
                -0.01880517601966858,
                -0.022024763748049736,
                -0.020509744063019753,
                -0.028751859441399574,
                0.003563511185348034,
                -0.015366989187896252,
                -0.024555208161473274,
                0.03575458750128746,
                -8.716567390365526e-05,
                -0.06576184928417206,
                0.05066289007663727,
                -0.047915246337652206,
                0.006820580922067165,
                0.01896158792078495,
                0.04890276491641998,
                0.008323414251208305,
                -0.057588960975408554,
                0.03772023320198059,
                -0.02950998581945896,
                0.021192850545048714,
                0.06090490520000458,
                0.039101749658584595,
                0.08194531500339508,
                0.012990353628993034,
                -0.0539543516933918,
                0.008180194534361362,
                -0.015444534830749035,
                0.03473031148314476,
                0.03909827396273613,
                0.006235014647245407,
                -0.018847433850169182,
                0.03096625953912735,
                0.006653914228081703,
                0.006519581191241741,
                -0.015995508059859276,
                -0.04780945926904678,
                0.06551871448755264,
                0.002322838641703129,
                -0.009697690606117249,
                0.013824456371366978,
                0.009402007795870304,
                -0.004721926990896463,
                0.007086333818733692,
                -0.030218807980418205,
                -0.018610075116157532,
                0.03329897299408913,
                -0.057255085557699203,
                -0.010545577853918076,
                0.014981837943196297,
                0.008987796492874622,
                -0.028315510600805283,
                -0.0556100457906723,
                -0.00898350216448307,
                0.0014228041982278228,
                0.020228853449225426,
                -0.00038289622170850635,
                -0.008412213064730167,
                -0.048079073429107666,
                -0.024836454540491104,
                0.08007044345140457,
                0.0030154462438076735,
                -0.020198479294776917,
                -0.03640724718570709,
                -0.003784773638471961,
                0.09388966113328934,
                -0.048181720077991486,
                -0.037024401128292084,
                -0.0024826000444591045,
                0.054888732731342316,
                -0.013866299763321877,
                0.017104703933000565,
                -0.06292162835597992,
                -0.03887171670794487,
                0.01196576002985239,
                0.054114095866680145,
                0.0074407923966646194,
                0.035422664135694504,
                0.028539272025227547,
                -0.04632601514458656,
                0.046682488173246384,
                0.01371600478887558,
                -0.05517718568444252,
                0.02196544036269188,
                -0.030467767268419266,
                0.04690352454781532,
                0.012439625337719917,
                0.016115494072437286,
                0.0009424760937690735,
                -0.019540198147296906,
                0.03648639842867851,
                -0.014956001192331314,
                -0.0365801677107811,
                0.017721425741910934,
                -0.0044959532096982,
                0.029935425147414207,
                0.021467993035912514,
                -0.03129544109106064,
                0.019467812031507492,
                0.0006497706635855138,
                -0.021132202818989754,
                0.027404818683862686,
                -0.06282279640436172,
                -0.0013861183542758226,
                0.017331337556242943,
                -0.03439303860068321,
                -0.03794718161225319,
                0.021274756640195847,
                -0.03805985301733017,
                0.0180681012570858,
                -0.004678746685385704,
                0.06111002713441849,
                0.012248153798282146,
                0.004953976254910231,
                -0.050508759915828705,
                -0.007437019143253565,
                0.10018204152584076,
                -0.023089533671736717,
                0.05350589379668236,
                -0.004263975191861391,
                0.055832937359809875,
                -0.050054002553224564,
                0.01928599923849106,
                -0.09453115612268448,
                -0.050704892724752426,
                0.0011249794624745846,
                -0.008744113147258759,
                -0.023815443739295006,
                -0.02101295441389084,
                -0.01132354699075222,
                -0.006181152071803808,
                0.048147138208150864,
                0.01996776834130287,
                -0.01747455634176731,
                0.011586232110857964,
                0.022934088483452797,
                0.006087134126573801,
                0.005405483301728964,
                0.022843658924102783,
                0.0096298111602664,
                -0.019791871309280396,
                -0.10727641731500626,
                -0.06321223080158234,
                0.01206936500966549,
                -0.05301312729716301,
                -0.018569137901067734,
                -0.03437361121177673,
                -0.019715895876288414,
                -0.022831348702311516,
                -0.013348699547350407,
                0.011511293239891529,
                -0.02553260140120983,
                -0.006747421342879534,
                -0.05695614218711853,
                -0.006551980506628752,
                0.11403977125883102,
                -0.011844796128571033,
                -0.018202178180217743,
                -0.048575613647699356,
                -0.0033093474339693785,
                -0.009251273237168789,
                -0.058267559856176376,
                -0.08918949216604233,
                0.051428619772195816,
                0.035449475049972534,
                0.026792801916599274,
                0.008311818353831768,
                0.011006140150129795,
                -0.009304545819759369,
                -0.025664810091257095,
                0.04331878200173378,
                0.021486669778823853,
                -0.052800290286540985,
                0.036620549857616425,
                0.06344719976186752,
                0.03260781615972519,
                0.0785449892282486,
                -0.06519021838903427,
                0.009291796945035458,
                0.0317382775247097,
                0.06067441403865814,
                -0.02267174981534481,
                0.019909238442778587,
                0.03209073096513748,
                -0.008203578181564808,
                -0.04983457550406456,
                0.015729399397969246,
                -0.05660279467701912,
                0.00982267688959837,
                0.004732244648039341,
                0.02710556797683239,
                -0.0019691381603479385,
                -0.03461930900812149,
                -0.013243140652775764,
                0.008360711857676506,
                -0.02463115192949772,
                0.008834119886159897,
                0.0062013347633183,
                -0.060583170503377914,
                0.001557788928039372,
                0.009264425374567509,
                -0.01744890958070755,
                0.024082748219370842,
                0.05046088621020317,
                0.002304146299138665,
                -0.03281452879309654,
                -0.0584053136408329,
                0.031578581780195236,
                0.01385329756885767,
                0.02551659755408764,
                -0.045895759016275406,
                0.003613230772316456,
                -0.005691687110811472,
                -0.05542095750570297,
                -0.023278292268514633,
                -0.021594593301415443,
                -0.010941535234451294,
                -0.004511886741966009,
                0.0017512498889118433,
                -0.019126253202557564,
                0.019824013113975525,
                0.04382014274597168,
                -0.012571061961352825,
                0.02608003094792366,
                0.04507562890648842,
                0.015816397964954376,
                -0.020512280985713005,
                0.014496739953756332,
                0.023477958515286446,
                0.01921743154525757,
                -0.017986971884965897,
                0.020543411374092102,
                0.016032934188842773,
                -0.08244277536869049,
                0.03508901596069336,
                -0.013675076887011528,
                0.04749632254242897,
                0.010756395757198334,
                -0.03167496249079704,
                0.06267333030700684,
                0.04212302342057228,
                0.02850954234600067,
                -0.018061455339193344,
                0.008534114807844162,
                -0.04977341741323471,
                0.0028718828689306974,
                0.0007572273607365787,
                0.019584106281399727,
                -0.0008962757419794798,
                0.03977850824594498,
                -0.006383167114108801,
                0.00454685278236866,
                0.057262957096099854,
                0.02342314086854458,
                -0.0018235960742458701,
                -0.0003558093449100852,
                -0.00466950936242938,
                -0.04850911349058151,
                -0.0007797002326697111,
                -0.09281454980373383,
                0.02172764018177986,
                0.05633847415447235,
                -0.05390535667538643,
                -0.04697959125041962,
                -0.012667600065469742,
                -6.420902792496581e-33,
                -0.012131985276937485,
                -0.017671091482043266,
                -0.009745354764163494,
                0.04155667498707771,
                -0.09108739346265793,
                -0.030104652047157288,
                -0.0017417395720258355,
                -0.021627113223075867,
                -0.03824084252119064,
                0.015900075435638428,
                0.023683443665504456,
                -0.03751282021403313,
                0.0065560974180698395,
                0.007411599159240723,
                0.013182380236685276,
                0.011159227229654789,
                -0.040675949305295944,
                0.0002860048261936754,
                0.019105257466435432,
                -0.026228828355669975,
                0.03472357615828514,
                0.007919690571725368,
                -0.01500053983181715,
                0.027503879740834236,
                0.014116683043539524,
                -0.0024301561061292887,
                -0.08145906776189804,
                0.011092397384345531,
                -0.061564601957798004,
                -0.038666825741529465,
                0.010988161899149418,
                0.002816195832565427,
                -0.0011817787308245897,
                -0.03484005108475685,
                -0.01473347656428814,
                0.0034920922480523586,
                -0.0003528632514644414,
                -0.019579291343688965,
                -0.046635594218969345,
                -0.011906168423593044,
                -0.1035294383764267,
                0.010019169189035892,
                0.04169116169214249,
                -0.013178803026676178,
                -0.04840471222996712,
                -0.02076629363000393,
                -0.008423126302659512,
                -0.06023407727479935,
                0.019939523190259933,
                -0.01282249391078949,
                0.004356296733021736,
                -0.03120281733572483,
                0.004363956395536661,
                0.025432510301470757,
                -0.014406229369342327,
                0.07795801013708115,
                -0.033694297075271606,
                0.05381428450345993,
                -0.02899416908621788,
                0.005351916886866093,
                0.05075303092598915,
                0.04992566257715225,
                0.007526209577918053,
                0.009534782730042934,
                -0.018722692504525185,
                0.02302587404847145,
                -0.029871823266148567,
                -0.03228195011615753,
                -0.033368825912475586,
                0.06091485917568207,
                -0.01526084914803505,
                0.040650565177202225,
                0.02370731718838215,
                -0.032258130609989166,
                -0.024791037663817406,
                -0.03734389692544937,
                -0.030927574262022972,
                0.03167693689465523,
                0.028962375596165657,
                0.004544598050415516,
                0.011908133514225483,
                -0.052895769476890564,
                0.09625892341136932,
                -0.019703492522239685,
                -0.008725300431251526,
                0.03086993470788002,
                -0.035729505121707916,
                0.06961160153150558,
                -0.00495481351390481,
                -0.016529114916920662,
                0.008206481114029884,
                0.0169337410479784,
                0.011388266459107399,
                -0.00430207559838891,
                0.023754162713885307,
                0.09624017030000687,
                -0.009780946187675,
                -0.048601869493722916,
                -6.387287430698052e-05,
                0.019817287102341652,
                0.025031518191099167,
                0.004822661634534597,
                -0.03366808593273163,
                0.07602503895759583,
                -0.007873127236962318,
                -0.0445832833647728,
                -0.020430931821465492,
                -0.03645617887377739,
                -0.002720061456784606,
                0.024212639778852463,
                -0.014820178039371967,
                -0.002126721665263176,
                0.01571919396519661,
                -0.03001222014427185,
                0.014060198329389095,
                -0.017557885497808456,
                0.008331090211868286,
                0.005033435765653849,
                -0.049245674163103104,
                0.06726126372814178,
                0.03177809342741966,
                0.04008544981479645,
                -0.027664141729474068,
                0.05288619548082352,
                -0.030269140377640724,
                0.007721554022282362,
                -0.014915388077497482,
                0.0468422994017601,
                -0.10608953982591629,
                0.0062406244687736034,
                0.011674268171191216,
                0.029114479199051857,
                2.1519096549127426e-07,
                -0.04702235758304596,
                -0.012728576548397541,
                -0.03053017519414425,
                -0.061577245593070984,
                -0.03023367002606392,
                0.024434782564640045,
                0.024789879098534584,
                0.029730459675192833,
                -0.04622102528810501,
                -0.028831299394369125,
                -0.023384355008602142,
                -0.03911999613046646,
                -0.003811078378930688,
                -0.02921210415661335,
                -0.002861558925360441,
                -0.036314867436885834,
                0.0001032764557749033,
                0.00041878342744894326,
                0.03903009742498398,
                -0.027808062732219696,
                0.0036603393964469433,
                -0.019828153774142265,
                -0.07310666888952255,
                -0.022767672315239906,
                -0.03108791820704937,
                0.036508798599243164,
                0.027127310633659363,
                -0.01977595128118992,
                0.05279792845249176,
                0.006552015896886587,
                0.022842245176434517,
                0.014237419702112675,
                0.03064526803791523,
                -0.07125736773014069,
                -0.017559127882122993,
                -0.03461310639977455,
                0.008013002574443817,
                0.030612988397479057,
                -0.03832947462797165,
                0.028534051030874252,
                0.005695690866559744,
                0.047107845544815063,
                0.002615922363474965,
                0.04485827684402466,
                -0.07261447608470917,
                0.039848022162914276,
                0.022247688844799995,
                -0.008642110042273998,
                -0.04482226446270943,
                -0.0029399569611996412,
                -0.03766849637031555,
                -0.005484469700604677,
                -0.007469597272574902,
                -0.0013865387300029397,
                -0.023116527125239372,
                -0.020296502858400345,
                -0.013992149382829666,
                0.01953285001218319,
                0.02892795391380787,
                0.03192707523703575,
                -0.023083489388227463,
                0.06276760250329971,
                -0.03892151638865471,
                0.08101075142621994,
                0.04617752134799957,
                -0.008770817890763283,
                0.01197048556059599,
                9.147847418724e-35,
                0.016436807811260223,
                -0.023830043151974678,
                -0.10275816917419434,
                -0.012594128958880901,
                -0.024151314049959183,
                0.006061450112611055,
                -0.049334924668073654,
                0.021394625306129456,
                -0.051190491765737534,
                -0.06057390198111534,
                -0.004399037454277277
            ]
        },
        "Location": "ABERDEEN",
        "Remuneration": 71.875,
        "Services Required": {
            "name": "PROJECT MANAGER",
            "rep": [
                0.004636390600353479,
                0.06633441895246506,
                -0.015874385833740234,
                0.0330984927713871,
                0.0210715364664793,
                0.0529584176838398,
                0.0038575888611376286,
                -0.01716390810906887,
                -0.10173097252845764,
                0.06769874691963196,
                0.05670958384871483,
                0.025754308328032494,
                -0.013811622746288776,
                0.03372577950358391,
                0.03440593555569649,
                -0.06047863885760307,
                -0.0013452664716169238,
                -0.019458264112472534,
                0.003520976286381483,
                0.024555683135986328,
                -0.012866746634244919,
                0.020504368469119072,
                0.0009210429270751774,
                0.04202103987336159,
                -0.05642755702137947,
                0.004248077515512705,
                -0.008161437697708607,
                0.024905970320105553,
                0.006573934573680162,
                -0.019085759297013283,
                0.023314902558922768,
                -0.004964896012097597,
                -0.03140493109822273,
                -0.02432570978999138,
                1.6055178093665745e-06,
                0.03893372043967247,
                0.05171223357319832,
                -0.031564850360155106,
                -0.000861181179061532,
                0.02069811150431633,
                -0.029035594314336777,
                -0.05056779459118843,
                0.0033657809253782034,
                0.018148623406887054,
                0.020890776067972183,
                -0.017518648877739906,
                0.050014711916446686,
                0.05826781317591667,
                -0.036760926246643066,
                -0.03630392998456955,
                0.006492882501333952,
                0.03278172016143799,
                -0.07836247980594635,
                -0.05416421964764595,
                0.005654707085341215,
                -0.08403277397155762,
                0.023165078833699226,
                -0.026352569460868835,
                0.0031986567191779613,
                0.06722786277532578,
                -0.005227544344961643,
                -0.022198578342795372,
                -0.01777506247162819,
                0.008805782534182072,
                0.023902198299765587,
                0.03408588841557503,
                0.09795175492763519,
                -0.026820996776223183,
                0.02893528714776039,
                -0.024512989446520805,
                0.0265245009213686,
                0.020498475059866905,
                0.00023117246746551245,
                0.040332287549972534,
                0.016347935423254967,
                -0.01715664193034172,
                0.00868082232773304,
                0.01080794632434845,
                -0.03651082143187523,
                -0.0008612039382569492,
                -0.023190360516309738,
                0.03679706156253815,
                0.00990015547722578,
                0.026400579139590263,
                -0.01720787212252617,
                0.04318512603640556,
                -0.018272945657372475,
                -0.0022325017489492893,
                -0.010026809759438038,
                0.04757525026798248,
                0.009219525381922722,
                0.0031378797721117735,
                0.003118060063570738,
                0.027414649724960327,
                -0.01942046917974949,
                0.011250640265643597,
                -0.009152566082775593,
                -0.016640527173876762,
                -0.013944313861429691,
                0.008997194468975067,
                -0.051115699112415314,
                0.020175307989120483,
                0.012542294338345528,
                0.05466558039188385,
                0.04583800211548805,
                0.07323307543992996,
                -0.006887830328196287,
                -0.01867636665701866,
                0.017178233712911606,
                0.003133489517495036,
                -0.00792607944458723,
                0.03200302645564079,
                -0.00283952453173697,
                0.0029241954907774925,
                0.022818878293037415,
                0.04018557444214821,
                -0.01878289319574833,
                0.05570775642991066,
                0.022039655596017838,
                0.06191356107592583,
                0.03571378439664841,
                -7.2901850217022e-05,
                -0.016802022233605385,
                0.017089860513806343,
                -0.035890109837055206,
                0.01405384112149477,
                -0.0598432682454586,
                0.003882533637806773,
                0.003165637841448188,
                0.06225498393177986,
                -0.03189089894294739,
                -0.007276313379406929,
                -0.017268499359488487,
                -0.019092151895165443,
                -0.0032717022113502026,
                0.024732885882258415,
                -0.021435635164380074,
                0.007753731682896614,
                -0.05758935213088989,
                0.009454241022467613,
                -0.008940307423472404,
                0.024560414254665375,
                -0.10404841601848602,
                -0.0003973872517235577,
                -0.020003266632556915,
                -0.020907407626509666,
                -0.07527267187833786,
                0.007631111424416304,
                0.02951645292341709,
                0.01712118647992611,
                0.009832948446273804,
                -0.027511263266205788,
                -0.006186208687722683,
                0.021015366539359093,
                0.04743668809533119,
                0.06546556204557419,
                -0.07409261912107468,
                -0.028402268886566162,
                -0.023240014910697937,
                0.05386880412697792,
                -0.002678764285519719,
                0.00740397535264492,
                0.04179459437727928,
                0.06466850638389587,
                -0.04417279362678528,
                0.006960983853787184,
                0.0934559777379036,
                -0.03806858882308006,
                -0.013999216258525848,
                -0.05060092359781265,
                -0.02718365378677845,
                0.010889529250562191,
                0.002032145392149687,
                0.08931367099285126,
                0.023497236892580986,
                -0.0846911147236824,
                0.026268530637025833,
                -0.0034088133834302425,
                -0.03201953321695328,
                0.010735533200204372,
                -0.029865704476833344,
                -0.1365036517381668,
                0.015415447764098644,
                0.011450030840933323,
                0.02363615110516548,
                0.00690264580771327,
                -0.07563290745019913,
                0.0399765782058239,
                -0.014588989317417145,
                0.031923986971378326,
                0.06444701552391052,
                -0.03681745007634163,
                -0.09725619107484818,
                -0.0008424555417150259,
                0.04612002521753311,
                0.022839080542325974,
                -0.03819490596652031,
                0.011064913123846054,
                -0.02303384430706501,
                0.005533476360142231,
                0.0015856331447139382,
                0.0396384671330452,
                -0.03629670664668083,
                0.009791583754122257,
                -0.06662166863679886,
                -0.09486422687768936,
                -0.00874932948499918,
                -0.01802831143140793,
                -0.07286851853132248,
                0.0005806113476864994,
                0.014644091948866844,
                -0.0007035377202555537,
                0.006725214887410402,
                0.058784473687410355,
                -0.03256255015730858,
                0.047469157725572586,
                0.017083382233977318,
                0.023503249511122704,
                -0.02660333178937435,
                0.05341409146785736,
                -0.01866721734404564,
                -0.02564878948032856,
                0.03681096062064171,
                0.03144421800971031,
                -0.02212374471127987,
                -0.0880095586180687,
                0.05706602707505226,
                -0.02425815537571907,
                -0.011124890297651291,
                -0.004799015820026398,
                0.00433653499931097,
                0.0036599424201995134,
                0.0673140212893486,
                -0.03942832350730896,
                0.010695579461753368,
                0.004297513514757156,
                0.03895863518118858,
                -0.00636225426569581,
                -0.0012145161163061857,
                -0.040020231157541275,
                -0.020950205624103546,
                -0.016343021765351295,
                -0.04196383059024811,
                -0.015620816498994827,
                0.060658570379018784,
                -0.020472433418035507,
                0.00536611583083868,
                0.012821430340409279,
                0.023181775584816933,
                -0.03421653062105179,
                -0.04038054496049881,
                -0.024443190544843674,
                0.01996617577970028,
                0.011755338869988918,
                0.01749545708298683,
                0.09240096807479858,
                -0.03561622276902199,
                0.029717179015278816,
                -0.05214592441916466,
                -0.011713153682649136,
                0.05133204162120819,
                -0.013569955714046955,
                -0.018014784902334213,
                -0.005015146918594837,
                0.06773803383111954,
                0.011909980326890945,
                -0.04582959786057472,
                -0.03007027693092823,
                -0.04803657531738281,
                5.48493662790861e-05,
                0.0010192666668444872,
                0.02537308819591999,
                0.04247347638010979,
                -0.03730929270386696,
                -0.03955192491412163,
                0.01969296857714653,
                -0.03217773884534836,
                0.013749212957918644,
                -0.028458354994654655,
                0.029717016965150833,
                0.009088349528610706,
                0.01906031183898449,
                -0.05190742760896683,
                0.039064906537532806,
                0.017112525179982185,
                -0.011668148450553417,
                0.014687465503811836,
                -0.09736604243516922,
                -0.005969813093543053,
                -0.0026409549172967672,
                -0.021252037957310677,
                -0.04519250616431236,
                0.011246060952544212,
                0.022696707397699356,
                0.003937032073736191,
                0.02814873494207859,
                0.04663176089525223,
                -0.05279034003615379,
                -0.017834708094596863,
                0.03297308459877968,
                -0.007078740745782852,
                -0.020776186138391495,
                0.04453840106725693,
                -0.028866145759820938,
                0.024468550458550453,
                -0.00356159801594913,
                0.030946293845772743,
                0.0351647287607193,
                -0.017733745276927948,
                -0.026440376415848732,
                0.029266521334648132,
                0.050773363560438156,
                0.016994724050164223,
                0.026258138939738274,
                0.015912460163235664,
                0.016966229304671288,
                0.0395885668694973,
                0.0034937411546707153,
                0.025082064792513847,
                0.06855707615613937,
                -0.019170494750142097,
                0.028194881975650787,
                -0.018114382401108742,
                -0.014756811782717705,
                0.005225047003477812,
                0.0649092048406601,
                -0.07392770797014236,
                -0.023380417376756668,
                0.016212373971939087,
                -0.03395013138651848,
                -0.03021005354821682,
                -0.08030883222818375,
                -0.03969871997833252,
                -0.0341704823076725,
                0.00948027241975069,
                -0.04015708714723587,
                -0.024333937093615532,
                -0.06764726340770721,
                -0.03861077502369881,
                -0.005339120049029589,
                -0.013922732323408127,
                -0.03582916781306267,
                -0.046175215393304825,
                -0.0017006240086629987,
                -0.08435238152742386,
                -0.0010559919755905867,
                0.0038483310490846634,
                0.057962797582149506,
                -0.04385519027709961,
                0.02908027544617653,
                0.025116758421063423,
                0.03882254287600517,
                -0.02016996406018734,
                -0.010575436986982822,
                -0.015169923193752766,
                -0.06278354674577713,
                -0.04506152123212814,
                0.048119787126779556,
                -0.024484720081090927,
                -0.010523924604058266,
                -0.05735382065176964,
                -0.028461074456572533,
                0.01939580589532852,
                -0.06342092901468277,
                -0.025007454678416252,
                -0.009105803444981575,
                0.010744920000433922,
                0.0013806322822347283,
                0.005348546430468559,
                0.002276294631883502,
                -0.03718573600053787,
                -0.022062543779611588,
                -0.0020424823742359877,
                0.0032692421227693558,
                -0.0020680101588368416,
                -0.026416851207613945,
                0.010727503336966038,
                0.036384496837854385,
                -0.0184786356985569,
                0.017564501613378525,
                0.004667583853006363,
                -0.0046055917628109455,
                0.003416264895349741,
                -0.021442634984850883,
                0.01634892448782921,
                0.015131142921745777,
                -0.006743019446730614,
                -0.0030288994312286377,
                0.04282470420002937,
                -0.0026645988691598177,
                0.009674299508333206,
                0.00028328067855909467,
                0.022365154698491096,
                0.024378526955842972,
                0.022028302773833275,
                -0.028943082317709923,
                -0.059224650263786316,
                0.018691178411245346,
                0.02318899892270565,
                0.005722387228161097,
                0.022672774270176888,
                0.055089861154556274,
                0.03579198569059372,
                0.004005224909633398,
                -0.03851867467164993,
                -0.09047888219356537,
                0.11626075208187103,
                -0.03461380675435066,
                -0.016036178916692734,
                0.03320498764514923,
                -0.012412956915795803,
                -0.008083468303084373,
                -0.03253354877233505,
                0.011250030249357224,
                0.022337954491376877,
                -0.030370911583304405,
                -0.028416654095053673,
                0.03098856285214424,
                -0.03526245430111885,
                -0.06958097219467163,
                0.042413581162691116,
                0.0471496544778347,
                0.01136892568320036,
                -0.0204811729490757,
                0.054586783051490784,
                0.0075754160061478615,
                0.041744817048311234,
                0.03603833168745041,
                -0.007307815365493298,
                -0.007461370900273323,
                -0.0314796157181263,
                0.009100981056690216,
                -0.02095983922481537,
                0.04625850170850754,
                0.06258000433444977,
                0.026377815753221512,
                -0.014917638152837753,
                0.02913581021130085,
                0.07705450803041458,
                0.020586593076586723,
                0.0410299152135849,
                0.11329293996095657,
                0.012035446241497993,
                0.006028777454048395,
                -0.045871540904045105,
                0.018064716830849648,
                -0.02017507329583168,
                -0.029401835054159164,
                -0.12127294391393661,
                0.042187195271253586,
                0.03430420532822609,
                0.02529614232480526,
                -0.034490276128053665,
                -0.031859561800956726,
                0.009565229527652264,
                0.024326421320438385,
                -0.03414691239595413,
                -0.0589115247130394,
                0.0075233085080981255,
                -0.059649914503097534,
                0.04368243366479874,
                0.030373409390449524,
                -0.07318233698606491,
                0.0117438193410635,
                -0.008890259079635143,
                0.011583242565393448,
                -0.015703760087490082,
                -0.02062711864709854,
                0.03659307211637497,
                -0.06896018236875534,
                0.05454294756054878,
                -0.029759423807263374,
                -0.02363138273358345,
                -0.037955641746520996,
                -0.006175311282277107,
                -0.028907345607876778,
                0.034159883856773376,
                -0.05409955233335495,
                -0.010466664098203182,
                0.04206191748380661,
                0.009606610983610153,
                0.025071095675230026,
                -0.007198185194283724,
                -0.054377708584070206,
                0.01193560753017664,
                -0.014208877459168434,
                -0.00865219160914421,
                0.003420931054279208,
                -0.014329909346997738,
                0.005914976820349693,
                0.006581875029951334,
                -0.03578205779194832,
                0.017624525353312492,
                -0.044582150876522064,
                -0.004459903575479984,
                0.011999235488474369,
                0.014686238951981068,
                -0.06855819374322891,
                0.01551949605345726,
                -0.09082462638616562,
                -0.02702689729630947,
                0.02452019974589348,
                0.003613848937675357,
                -0.03560258448123932,
                0.007413100451231003,
                -0.006412769202142954,
                0.020162714645266533,
                -0.011004652827978134,
                -0.0026093199849128723,
                0.013402355834841728,
                -0.007152507081627846,
                -0.03408335894346237,
                0.0005646322388201952,
                -0.031614601612091064,
                0.06169587001204491,
                0.021463394165039062,
                0.0468934141099453,
                0.009875217452645302,
                0.005950464401394129,
                -0.02847127802670002,
                0.011627770960330963,
                -0.04460106045007706,
                -0.027248961851000786,
                -0.01258099265396595,
                0.002177567919716239,
                -0.007139445282518864,
                0.017788849771022797,
                -0.009932519868016243,
                0.0001239647826878354,
                0.04745664447546005,
                0.015257046557962894,
                -0.029886672273278236,
                -0.073133684694767,
                0.03668436408042908,
                -0.042174018919467926,
                0.0709376409649849,
                0.012012374587357044,
                0.058397382497787476,
                0.006027291528880596,
                -0.0019676683004945517,
                -0.03925878927111626,
                0.044327374547719955,
                -0.0070085166953504086,
                -0.00697497371584177,
                0.00889616273343563,
                -0.03579474985599518,
                -0.04388643428683281,
                -0.00038377102464437485,
                -0.0007564361440017819,
                0.013512024655938148,
                -0.029140029102563858,
                -0.030525261536240578,
                0.016636809334158897,
                0.03496521711349487,
                -0.038595862686634064,
                -5.698904978671916e-33,
                -0.025007985532283783,
                -0.06574591249227524,
                0.035145264118909836,
                0.025319185107946396,
                -0.030392000451683998,
                -0.013116233982145786,
                0.02162330411374569,
                0.022009652107954025,
                -0.02712426334619522,
                -0.02522081322968006,
                -0.008923339657485485,
                -0.0028864529449492693,
                0.02274642139673233,
                0.024127699434757233,
                0.060752686113119125,
                0.05618555098772049,
                0.061404190957546234,
                0.0065657817758619785,
                0.017249537631869316,
                -0.008597762323915958,
                -0.02248871885240078,
                0.006541732233017683,
                -0.007026356644928455,
                0.07059308141469955,
                -0.01009896956384182,
                0.006625534035265446,
                -0.005814415402710438,
                0.0550822950899601,
                0.06439240276813507,
                -0.00011930599430343136,
                0.017357006669044495,
                -0.02137187123298645,
                -0.010124273598194122,
                0.029139366000890732,
                -0.013945523649454117,
                0.028402840718626976,
                -0.007327571976929903,
                0.011032816022634506,
                -0.0470910407602787,
                -0.022123849019408226,
                -0.03423548489809036,
                0.015654800459742546,
                0.027123024687170982,
                0.0002709900727495551,
                0.00043951201951131225,
                0.0041333334520459175,
                -0.002426145365461707,
                -0.011285149492323399,
                0.0021213358268141747,
                0.06426890939474106,
                -0.03906134143471718,
                -0.0077423774637281895,
                -0.037923213094472885,
                0.04206449165940285,
                0.01876366138458252,
                0.0024337531067430973,
                -0.016512691974639893,
                0.04886496439576149,
                0.01462209690362215,
                -0.01591046340763569,
                -0.06774438917636871,
                -0.0010938347550109029,
                0.035325583070516586,
                0.07094620168209076,
                -0.026173096150159836,
                -0.00012350564065854996,
                0.015624459832906723,
                0.06409876048564911,
                -0.055188823491334915,
                0.0005317434552125633,
                -0.03895611688494682,
                0.06917640566825867,
                0.006128057837486267,
                -0.04297315329313278,
                -0.07311436533927917,
                -3.511611430440098e-05,
                0.013919836841523647,
                0.013630550354719162,
                0.10495413094758987,
                0.022965099662542343,
                -0.04592112451791763,
                0.013562632724642754,
                0.030414365231990814,
                -0.014154425822198391,
                0.015064368955790997,
                -0.09251616150140762,
                -0.017339564859867096,
                0.06967142224311829,
                0.02506731078028679,
                0.00823416467756033,
                -0.024767085909843445,
                0.06371297687292099,
                -0.029488863423466682,
                -0.004796851426362991,
                0.07861004024744034,
                -0.00861323717981577,
                -0.05483941361308098,
                -0.026002833619713783,
                -0.007487069349735975,
                0.03341442719101906,
                -0.04010922461748123,
                0.03384917601943016,
                -0.034669384360313416,
                0.049033597111701965,
                -0.0011728639947250485,
                -0.021795224398374557,
                -0.06440243124961853,
                -0.04482216015458107,
                -0.0314854197204113,
                0.001543048769235611,
                -0.008106562308967113,
                0.015847286209464073,
                -0.003294086316600442,
                -0.00838164146989584,
                -0.024655891582369804,
                -0.05109892040491104,
                0.01562056876718998,
                -0.06308750063180923,
                0.017600461840629578,
                -0.03671492263674736,
                -6.652878801105544e-05,
                0.01883750781416893,
                -0.05749800428748131,
                0.006752443499863148,
                -0.013618672266602516,
                -0.02794358693063259,
                0.02879483252763748,
                0.01569983921945095,
                0.04898163303732872,
                -0.023258917033672333,
                -0.002763950964435935,
                -0.01678464561700821,
                2.236425586943369e-07,
                0.0020783543586730957,
                0.058712299913167953,
                0.02183348312973976,
                0.02239707112312317,
                0.00696886470541358,
                0.014202415011823177,
                -0.0353165827691555,
                -0.003327257465571165,
                -0.02097376622259617,
                -0.010161740705370903,
                0.053436268121004105,
                -0.003514119191095233,
                0.008520926348865032,
                0.020359404385089874,
                0.03424401953816414,
                -0.0549425408244133,
                -0.027729371562600136,
                -0.027907872572541237,
                -0.06335850805044174,
                -0.019665902480483055,
                0.04655512049794197,
                0.029244299978017807,
                0.025361277163028717,
                -0.03311458230018616,
                0.008137839846313,
                -0.03572091832756996,
                0.01483635138720274,
                0.015085989609360695,
                0.05084259808063507,
                0.012167024426162243,
                0.027676565572619438,
                -0.017539095133543015,
                -0.02162204124033451,
                0.037170879542827606,
                0.03995952010154724,
                -0.062154531478881836,
                0.0398978665471077,
                0.037746284157037735,
                0.015640510246157646,
                0.04932364448904991,
                0.00850153248757124,
                -0.03264893218874931,
                -0.01580643840134144,
                0.06598437577486038,
                -0.0664653554558754,
                0.0006735799834132195,
                0.0019170863088220358,
                0.10017343610525131,
                -0.024789951741695404,
                -0.004960148595273495,
                0.002164257224649191,
                0.04784503951668739,
                0.03860371932387352,
                0.009886951185762882,
                0.0020539385732263327,
                -0.015713322907686234,
                -0.06723843514919281,
                0.06511948257684708,
                0.04496545344591141,
                -0.011779388412833214,
                -0.0485551580786705,
                -0.011625341139733791,
                -0.005843983497470617,
                0.030489102005958557,
                -0.007444329094141722,
                0.04521002247929573,
                -0.0004078713245689869,
                1.1029906857830342e-34,
                -0.020811865106225014,
                -0.05608184263110161,
                -0.04124930128455162,
                -0.06381091475486755,
                -0.009629316627979279,
                -0.012778951786458492,
                -0.08156512677669525,
                0.008234088309109211,
                -0.030162004753947258,
                -0.011901650577783585,
                -0.0030406247824430466
            ]
        },
        "Skills": {
            "name": [
                "Active Listening"
            ],
            "rep": [
                -0.001181407948024571,
                -0.03056049719452858,
                -0.04570114612579346,
                -0.012009350582957268,
                0.026216592639684677,
                0.012410512194037437,
                -0.005495025310665369,
                -0.03737989068031311,
                -0.021512269973754883,
                0.05157751962542534,
                -0.011465627700090408,
                -0.016967231407761574,
                -0.027046089991927147,
                0.01158986147493124,
                0.017927004024386406,
                -0.05433907359838486,
                0.03195522725582123,
                -0.037401169538497925,
                0.02678598463535309,
                0.0011681824689731002,
                -0.02742278389632702,
                -0.024397633969783783,
                -0.05474564805626869,
                0.016131548210978508,
                -0.011210005730390549,
                -0.016630422323942184,
                -0.024857882410287857,
                -0.01199487503618002,
                0.026840271428227425,
                -0.014264626428484917,
                -0.05249650031328201,
                0.01671365462243557,
                -0.019786613062024117,
                -0.006261139642447233,
                1.5007125284682843e-06,
                0.011952840723097324,
                0.01355205848813057,
                -0.06275937706232071,
                -0.017323754727840424,
                -0.02145063318312168,
                -0.007565502543002367,
                0.01274112518876791,
                -0.003985875751823187,
                0.002303715329617262,
                -0.04155851528048515,
                -0.0172527227550745,
                0.031931743025779724,
                0.022078808397054672,
                0.027421709150075912,
                0.027919573709368706,
                0.011134473606944084,
                0.07004135847091675,
                -0.00821402296423912,
                -0.017628170549869537,
                0.0719882920384407,
                -0.048921674489974976,
                0.011268663220107555,
                0.02293318137526512,
                0.014224007725715637,
                0.03177125006914139,
                -0.050822071731090546,
                0.02057019993662834,
                0.008493848145008087,
                -0.047742944210767746,
                0.07837266474962234,
                0.005342547781765461,
                0.0014347576070576906,
                -0.013477964326739311,
                0.00967186875641346,
                0.029375610873103142,
                0.09167180210351944,
                0.014445734210312366,
                0.017001483589410782,
                -0.00768507132306695,
                -0.012068654410541058,
                0.00653809355571866,
                -0.0697934553027153,
                -0.06969296932220459,
                -0.013259642757475376,
                0.018127666786313057,
                -0.027437593787908554,
                0.005500349681824446,
                0.014691107906401157,
                0.004962671082466841,
                0.08547404408454895,
                0.03463525325059891,
                -0.06354472041130066,
                -0.04857955873012543,
                0.01643989235162735,
                0.016252627596259117,
                0.007367424666881561,
                -0.030483247712254524,
                -0.006463229190558195,
                0.05696761608123779,
                -0.05737236142158508,
                0.0042350031435489655,
                0.011048045009374619,
                0.01612408272922039,
                -0.03131958469748497,
                -0.029259471222758293,
                -0.004421175457537174,
                0.047111235558986664,
                -0.025649992749094963,
                0.02656392566859722,
                0.05156923830509186,
                -0.06098845601081848,
                0.016120415180921555,
                -0.024760568514466286,
                -0.023659640923142433,
                0.06400582194328308,
                0.00012763982522301376,
                0.008631454780697823,
                -0.029323020949959755,
                0.005299554672092199,
                0.015073816291987896,
                -0.08001136034727097,
                -0.011416235938668251,
                0.011970954947173595,
                -0.024666359648108482,
                0.015121043659746647,
                -0.06279224902391434,
                -0.03493209928274155,
                -0.05161121487617493,
                0.04057447239756584,
                0.00616527209058404,
                0.040971335023641586,
                -0.05497170239686966,
                0.06692545861005783,
                0.010540641844272614,
                -0.03778277710080147,
                -0.02838820032775402,
                0.004913426470011473,
                0.01022620964795351,
                0.042614806443452835,
                -0.004574046470224857,
                0.04278295859694481,
                -0.007618359290063381,
                -0.012327593751251698,
                -0.04968409985303879,
                0.008588891476392746,
                0.013251098804175854,
                -0.04530724510550499,
                -0.009679251350462437,
                -0.021425385028123856,
                0.004292703699320555,
                -0.03367014601826668,
                0.006097492296248674,
                -0.014912791550159454,
                0.020031597465276718,
                -0.04397055134177208,
                0.05231631174683571,
                0.01564275659620762,
                0.03283552825450897,
                0.018632706254720688,
                0.062347956001758575,
                0.030474545434117317,
                0.014684254303574562,
                0.06967398524284363,
                -0.01085143443197012,
                -0.06100935861468315,
                0.01193703431636095,
                -0.016824159771203995,
                -0.0018882806180045009,
                0.01169756893068552,
                0.005822543054819107,
                0.016822779551148415,
                0.08455934375524521,
                -0.03317200392484665,
                -0.03986320644617081,
                -0.002775396453216672,
                -0.010633827187120914,
                -0.012858210131525993,
                -0.032369256019592285,
                0.07302974909543991,
                0.08411408960819244,
                -0.009340454824268818,
                -0.009529363363981247,
                -0.00692377844825387,
                -0.02688026800751686,
                -0.00709877535700798,
                -0.05399215221405029,
                0.009356187656521797,
                -0.035693492740392685,
                0.04601775109767914,
                0.03934343159198761,
                0.017547838389873505,
                0.007685166317969561,
                -0.04903180152177811,
                -0.018871942535042763,
                -0.01679398864507675,
                -0.005042798351496458,
                0.029999595135450363,
                -0.03767774626612663,
                0.02652181126177311,
                -0.046207245439291,
                0.003266986459493637,
                0.014771807007491589,
                0.021280916407704353,
                0.03332388401031494,
                0.00889945961534977,
                -0.008797314018011093,
                -0.007070666644722223,
                0.04793892800807953,
                0.003762073814868927,
                -0.03874405845999718,
                -0.04433033615350723,
                0.0015393849462270737,
                -0.0411834679543972,
                0.0626092255115509,
                0.029796244576573372,
                0.058518458157777786,
                -0.0036114079412072897,
                0.01666688174009323,
                0.0059595718048512936,
                -0.01881084404885769,
                0.022395044565200806,
                0.03394097089767456,
                0.04160502180457115,
                -0.061396729201078415,
                0.08650430291891098,
                -0.038511987775564194,
                0.04204743728041649,
                0.025041546672582626,
                0.018937457352876663,
                0.021930528804659843,
                -0.04345126077532768,
                -0.003492391901090741,
                -0.0041268570348620415,
                0.06895531713962555,
                0.008752764202654362,
                -0.027795713394880295,
                0.003394428174942732,
                0.012884058989584446,
                0.009421388618648052,
                0.04094129428267479,
                0.07685635983943939,
                -0.006418339442461729,
                0.06925703585147858,
                0.008995654992759228,
                -0.07321418076753616,
                -0.040345773100852966,
                -0.007525485474616289,
                0.03997816890478134,
                -0.01685679517686367,
                0.032779760658741,
                -0.03143380209803581,
                0.051796358078718185,
                0.014769315719604492,
                0.016113456338644028,
                -0.014713498763740063,
                -0.052522994577884674,
                0.00971789937466383,
                -0.025782238692045212,
                -0.021292388439178467,
                0.004714216571301222,
                -0.011562369763851166,
                -0.053244151175022125,
                0.009789295494556427,
                -0.06609512865543365,
                0.025089286267757416,
                0.006777740083634853,
                -0.008859284222126007,
                -0.01002524048089981,
                -0.05622208118438721,
                0.051874157041311264,
                -0.04867936298251152,
                -0.011574232019484043,
                -0.0041922107338905334,
                -0.059769146144390106,
                -0.02813687175512314,
                -0.010532812215387821,
                -0.026445386931300163,
                0.009672396816313267,
                -0.01123730093240738,
                -0.02524893917143345,
                0.0058708274737000465,
                0.030266085639595985,
                0.019960729405283928,
                -0.012393065728247166,
                -0.031241202726960182,
                0.028924070298671722,
                -0.011961644515395164,
                -0.03242340683937073,
                -0.02997533418238163,
                0.028089556843042374,
                -0.017362797632813454,
                0.01903381757438183,
                -0.05092446506023407,
                -0.028795292600989342,
                -0.09678485989570618,
                0.027283944189548492,
                0.01472385786473751,
                0.005056280177086592,
                -0.010513653047382832,
                0.003112707519903779,
                -0.006825784221291542,
                -0.0177288968116045,
                0.07031179964542389,
                -0.0196988545358181,
                0.02163042314350605,
                0.009143051691353321,
                -0.013335766270756721,
                -0.001544266939163208,
                0.005071528255939484,
                -0.010457126423716545,
                -0.012378688901662827,
                -0.03848309442400932,
                0.012474560178816319,
                -0.029321830719709396,
                0.022048024460673332,
                0.04368074610829353,
                0.020947644487023354,
                0.025430884212255478,
                -0.012571788392961025,
                -0.02302221767604351,
                -0.0898786336183548,
                -0.04765518382191658,
                -0.08015745878219604,
                -0.018694933503866196,
                0.049216896295547485,
                0.011376510374248028,
                -0.002448614686727524,
                0.01177973486483097,
                0.02228318341076374,
                -0.004982483107596636,
                -0.03311939537525177,
                -0.009704356081783772,
                0.00997425802052021,
                0.033865612000226974,
                -0.05701666325330734,
                0.0006534596323035657,
                0.007341315969824791,
                -0.058233264833688736,
                -0.014315432868897915,
                -0.018613243475556374,
                0.06637367606163025,
                -0.008386650122702122,
                0.0002165416080970317,
                -0.04624081775546074,
                0.012225186452269554,
                0.06008382514119148,
                -0.0231543630361557,
                -0.043385665863752365,
                0.054515913128852844,
                -0.054564036428928375,
                -0.02287958934903145,
                -0.004576113540679216,
                0.025568051263689995,
                -0.11724651604890823,
                0.025900308042764664,
                0.03141650930047035,
                0.009921967051923275,
                0.011815043166279793,
                -0.02188682183623314,
                0.013673237524926662,
                -0.03894900158047676,
                0.014752223156392574,
                0.030891593545675278,
                0.038678672164678574,
                -0.06113862991333008,
                0.013981472700834274,
                0.02401929721236229,
                0.015236731618642807,
                -0.017992110922932625,
                0.08343077450990677,
                0.021361256018280983,
                0.05877352133393288,
                0.005458724685013294,
                -0.004694290459156036,
                0.05005587637424469,
                0.04672398045659065,
                -0.0081397145986557,
                -0.06163151562213898,
                0.06213871389627457,
                0.025565775111317635,
                -0.07157568633556366,
                0.012992581352591515,
                -0.06037696450948715,
                0.0012810865882784128,
                -0.01612946018576622,
                0.005018553230911493,
                -0.05018492043018341,
                0.00728851743042469,
                0.007122081238776445,
                0.0665317103266716,
                0.009393886663019657,
                -0.002646400360390544,
                0.008166794665157795,
                0.0050930748693645,
                0.03744744509458542,
                0.057572219520807266,
                -0.015100621618330479,
                -0.004980052821338177,
                0.019667718559503555,
                0.049897413700819016,
                -0.02544347383081913,
                0.002829293254762888,
                0.01698492094874382,
                0.01647612452507019,
                0.0017344864318147302,
                0.033358026295900345,
                0.004553170874714851,
                0.019774235785007477,
                0.0001485248503740877,
                -0.006182120181620121,
                0.03407865762710571,
                0.03867777809500694,
                0.006631753873080015,
                0.021705904975533485,
                -0.049149010330438614,
                0.0002418979856884107,
                -0.011775476858019829,
                0.012066357769072056,
                -0.010274982079863548,
                -0.009473657235503197,
                -0.06627324968576431,
                -0.010295243002474308,
                -0.015106845647096634,
                0.09594325721263885,
                0.02142302133142948,
                0.002621664432808757,
                0.04166597127914429,
                -0.013972372747957706,
                -0.023843832314014435,
                0.022355930879712105,
                -0.0026615781243890524,
                0.08369521051645279,
                0.09578504413366318,
                -0.0006814326043240726,
                0.0222016591578722,
                0.002599598839879036,
                0.040318384766578674,
                0.011111150495707989,
                0.014605611562728882,
                0.017719097435474396,
                -0.0687546655535698,
                -0.0019860481843352318,
                0.03469218313694,
                -0.06483994424343109,
                -0.023519249632954597,
                -0.04588944837450981,
                0.051150131970644,
                0.07350856065750122,
                0.017139913514256477,
                0.026635773479938507,
                0.03543009236454964,
                0.02747410349547863,
                0.011930415406823158,
                0.0053198328241705894,
                0.0684908777475357,
                0.0600423701107502,
                -0.010169170796871185,
                -0.11646927148103714,
                -0.032712191343307495,
                -0.0278790220618248,
                -0.029248349368572235,
                0.007001934107393026,
                -0.04837924242019653,
                -0.005347580648958683,
                0.05394823104143143,
                -0.012162738479673862,
                0.04141706973314285,
                0.04571126028895378,
                -0.003911738749593496,
                0.0005451328470371664,
                -0.057592734694480896,
                -0.01485451590269804,
                -0.08242302387952805,
                0.0526852160692215,
                0.020677346736192703,
                -0.01589110493659973,
                0.011685612611472607,
                0.02484537847340107,
                -0.027113212272524834,
                0.02953748032450676,
                0.021982014179229736,
                -0.01448940858244896,
                -0.013345897197723389,
                -0.048547759652137756,
                -0.02141762524843216,
                -0.05112507939338684,
                0.01889880560338497,
                -0.017905374988913536,
                -0.0369173102080822,
                -0.016306597739458084,
                0.04233325645327568,
                0.030195487663149834,
                0.022946903482079506,
                -0.03663250431418419,
                0.011738400906324387,
                -0.008885913528501987,
                0.04146290943026543,
                0.007224063854664564,
                0.009148254990577698,
                -0.02639877051115036,
                -0.010010315105319023,
                -0.0416414700448513,
                0.023465735837817192,
                0.014939149841666222,
                -0.07021239399909973,
                -0.014204414561390877,
                0.027314724400639534,
                -0.014293435961008072,
                0.013334452174603939,
                0.04942792281508446,
                -0.0037465356290340424,
                0.05987726151943207,
                0.06847032159566879,
                0.030978810042142868,
                0.0010112476302310824,
                0.01931161805987358,
                -0.005172836594283581,
                -0.04951252043247223,
                -0.035957347601652145,
                0.00044479782809503376,
                0.11052641272544861,
                0.013401866890490055,
                -0.006071262992918491,
                -0.011643706820905209,
                -0.02256535366177559,
                -0.00018407432071398944,
                0.037615761160850525,
                0.0678754597902298,
                0.005708666518330574,
                0.0033124203328043222,
                -0.004206658340990543,
                0.021098194643855095,
                0.019569821655750275,
                -0.014359969645738602,
                -0.017227694392204285,
                -0.09645780920982361,
                -0.003746924689039588,
                -0.050003670156002045,
                0.0540030375123024,
                -0.0444711297750473,
                0.02480924502015114,
                -0.0354168601334095,
                0.08688271790742874,
                -0.021516811102628708,
                0.06832760572433472,
                -0.03312593698501587,
                -0.003339775837957859,
                -0.004622938577085733,
                0.01828993484377861,
                -0.007174008991569281,
                -0.050745993852615356,
                0.005205897614359856,
                0.003620848525315523,
                0.03186087682843208,
                -0.013826271519064903,
                0.0030879853293299675,
                -0.02832414023578167,
                -0.03475813567638397,
                -0.015611285343766212,
                -0.01885106787085533,
                -6.08607571629233e-33,
                -0.046283528208732605,
                -0.022160891443490982,
                -0.011214111000299454,
                0.05596539378166199,
                -0.09787601977586746,
                -0.024991896003484726,
                -0.01567476987838745,
                0.02827030047774315,
                0.0224986020475626,
                -0.027443503960967064,
                0.024323100224137306,
                0.05877196788787842,
                0.030148282647132874,
                -0.002221090719103813,
                0.038265082985162735,
                -0.0472499318420887,
                0.04221750795841217,
                0.03548099845647812,
                -0.0029233498498797417,
                -0.0017514362698420882,
                -0.04088597744703293,
                0.040957383811473846,
                -0.007961471565067768,
                -0.06727077066898346,
                0.001703915768302977,
                0.02223639003932476,
                0.0015338356606662273,
                -0.008259252645075321,
                -0.02825365960597992,
                0.01140116062015295,
                0.001123978872783482,
                0.02803233079612255,
                -0.04220956936478615,
                -0.019811293110251427,
                -0.018608376383781433,
                0.05556388944387436,
                -0.07123112678527832,
                -2.6522926418692805e-05,
                0.025794824585318565,
                0.04341243579983711,
                -0.044812239706516266,
                0.04022729769349098,
                -0.0016486168606206775,
                0.021336428821086884,
                0.036445923149585724,
                -0.03022429160773754,
                0.018189741298556328,
                0.006059430539608002,
                -0.04851758852601051,
                0.039294350892305374,
                -0.07704699784517288,
                -0.0016979811480268836,
                -0.09520607441663742,
                -0.013873614370822906,
                0.014135907404124737,
                0.016020219773054123,
                -0.033107683062553406,
                -0.024871092289686203,
                -0.05988024175167084,
                0.0188263151794672,
                0.0003389980411157012,
                0.009753095917403698,
                -0.015995565801858902,
                0.0017524639843031764,
                0.0003337087982799858,
                0.06197391077876091,
                0.02028488554060459,
                -0.029768023639917374,
                -0.016109343618154526,
                0.00048058241372928023,
                -0.004990588407963514,
                0.05560407042503357,
                -0.0020261621102690697,
                0.005624797660857439,
                -0.06604613363742828,
                -0.022263096645474434,
                0.011248827911913395,
                -0.005632760003209114,
                0.07254468649625778,
                0.026924237608909607,
                0.002041449537500739,
                0.01784500852227211,
                -0.03306280076503754,
                0.02226348966360092,
                -0.03555021807551384,
                -0.007342181168496609,
                0.002458511618897319,
                -0.020076552405953407,
                -0.014097742736339569,
                0.011680098250508308,
                -0.031204771250486374,
                0.003580078249797225,
                -0.001282249460928142,
                -0.014030597172677517,
                0.0454602986574173,
                -0.04797184839844704,
                -0.013002602383494377,
                0.04455717280507088,
                -0.02851489745080471,
                -0.03274213895201683,
                -0.02946791797876358,
                0.015222473070025444,
                -0.019237354397773743,
                0.048240602016448975,
                0.02513907290995121,
                -0.030299369245767593,
                0.006596672814339399,
                0.026063881814479828,
                -0.03685759752988815,
                -0.01131405495107174,
                0.0041998508386313915,
                0.03823301941156387,
                0.04011726379394531,
                -0.0006071589887142181,
                0.015781404450535774,
                -0.024905521422624588,
                -0.0359797477722168,
                0.07618419080972672,
                -0.0080445297062397,
                -0.04726400226354599,
                -0.007714028004556894,
                -0.019429106265306473,
                -0.0383925661444664,
                0.01998952589929104,
                -0.06382445991039276,
                0.0013379206648096442,
                0.00015279259241651744,
                -0.0036106857005506754,
                -0.005414212588220835,
                -0.09742996096611023,
                0.00654088519513607,
                -0.01588059961795807,
                2.202167905807073e-07,
                -0.0478014275431633,
                -0.021161241456866264,
                -0.013457260094583035,
                0.02367875538766384,
                0.06855036318302155,
                0.005069552920758724,
                -0.037904493510723114,
                0.02606339380145073,
                0.046769630163908005,
                -0.01469034980982542,
                0.031175537034869194,
                -0.0005891636828891933,
                0.043563228100538254,
                -0.008204388432204723,
                -0.07692296802997589,
                -0.07708330452442169,
                -0.09615882486104965,
                0.03844302520155907,
                0.0011167869670316577,
                0.0061346557922661304,
                0.0067860339768230915,
                0.0569274015724659,
                -0.00984699372202158,
                0.003196926787495613,
                0.05068064108490944,
                -0.014600896276533604,
                -0.007414302323013544,
                0.002184523968026042,
                0.08089930564165115,
                -0.04658184200525284,
                0.013140717521309853,
                -0.04540003836154938,
                -0.011964861303567886,
                0.04687816649675369,
                -0.009650914929807186,
                -0.022352149710059166,
                -0.0048383246175944805,
                0.07270507514476776,
                0.026221977546811104,
                -0.03278880566358566,
                -0.04712957516312599,
                0.10769174247980118,
                -0.017691483721137047,
                0.05216369032859802,
                0.04091724380850792,
                -0.07509790360927582,
                -0.052723877131938934,
                0.07809477299451828,
                -0.022568412125110626,
                -0.036491889506578445,
                -0.025705549865961075,
                0.04915827140212059,
                0.01946796476840973,
                0.04673057794570923,
                -0.03895437344908714,
                -0.00018125673523172736,
                0.03700702637434006,
                -0.006672386545687914,
                0.04038499295711517,
                -0.05588600039482117,
                -0.027977796271443367,
                -0.07232997566461563,
                0.013433278538286686,
                -0.01983337476849556,
                -0.04658687114715576,
                0.025510000064969063,
                -0.059585582464933395,
                4.370178338543657e-35,
                0.0237748883664608,
                0.017676739022135735,
                0.019074572250247,
                0.06039264798164368,
                -0.029723234474658966,
                -0.023325802758336067,
                0.05007585883140564,
                -0.007739157881587744,
                0.03950886055827141,
                -0.0023599860724061728,
                -0.041903771460056305
            ]
        },
        "Values": {
            "name": [
                "TRUST",
                "INNOVATION"
            ],
            "rep": [
                0.05414777994155884,
                0.03948406130075455,
                -0.005036409478634596,
                0.016415966674685478,
                -0.012285172007977962,
                -0.007498631253838539,
                -0.034417055547237396,
                -0.0184017326682806,
                0.05461392551660538,
                0.05329851061105728,
                0.05489245802164078,
                -0.04631984606385231,
                0.0431121401488781,
                0.02960469387471676,
                -0.026929549872875214,
                -0.03256624564528465,
                0.024648824706673622,
                0.02505704201757908,
                -0.014422331936657429,
                0.001030166749842465,
                0.0054189651273190975,
                -0.004953314550220966,
                0.032138627022504807,
                -0.05251395329833031,
                -0.008660748600959778,
                -0.007714101113379002,
                0.01997997798025608,
                -0.030599528923630714,
                0.01458698883652687,
                -0.00665372284129262,
                0.011364873498678207,
                0.015275049023330212,
                0.0009959599701687694,
                -0.04394545406103134,
                2.1028749870311003e-06,
                -0.032713841646909714,
                -0.03170183300971985,
                -0.030023720115423203,
                -0.0317683108150959,
                0.0005337639595381916,
                -0.03894960135221481,
                0.0015155822038650513,
                0.006795750930905342,
                -0.013917369768023491,
                -0.0127815380692482,
                0.06058959662914276,
                0.003814121475443244,
                -0.00824224017560482,
                0.0033303822856396437,
                -0.0023494812194257975,
                0.003372203093022108,
                0.05487470701336861,
                -0.04413610324263573,
                -0.025037942454218864,
                0.0475022979080677,
                -0.05710111930966377,
                0.01814875192940235,
                -0.019411206245422363,
                -0.05064515769481659,
                0.0029333331622183323,
                -0.001593391178175807,
                -0.035893455147743225,
                0.0012386857997626066,
                0.0028928935062140226,
                0.10193437337875366,
                0.02469259314239025,
                0.07445430010557175,
                -0.0718766525387764,
                -0.0007425241637974977,
                -0.04419858381152153,
                0.022034669294953346,
                0.015535078942775726,
                -0.0038099773228168488,
                0.0849899873137474,
                -0.0565471351146698,
                0.06938368082046509,
                -0.006891780998557806,
                0.0021008155308663845,
                -0.000826653849799186,
                -0.01877356320619583,
                -0.0582476407289505,
                0.029794706031680107,
                0.0035187217872589827,
                0.04214221611618996,
                0.01158985123038292,
                0.02330203354358673,
                0.013098664581775665,
                0.00013066158862784505,
                0.017506133764982224,
                0.05427630618214607,
                0.016269244253635406,
                -0.07968008518218994,
                -0.011989708058536053,
                -0.006811488885432482,
                0.03518754243850708,
                -0.00044095705379731953,
                0.01303186547011137,
                0.08111321181058884,
                0.002794410102069378,
                0.05097055435180664,
                -0.12901081144809723,
                -0.002603667788207531,
                -0.03468647599220276,
                0.004206023644655943,
                0.0021423758007586002,
                0.019881315529346466,
                0.027123555541038513,
                -0.006860192399471998,
                -0.02539091184735298,
                0.04923300817608833,
                -0.006226296070963144,
                0.04179678484797478,
                0.0037274309433996677,
                -0.016243446618318558,
                0.017140690237283707,
                0.0034351355861872435,
                -0.07224371284246445,
                0.02892286144196987,
                -0.03862311691045761,
                0.0632094219326973,
                0.03477262705564499,
                -0.0062143695540726185,
                -0.028883013874292374,
                -0.006386762950569391,
                -0.010159982368350029,
                0.007948641665279865,
                0.008948049508035183,
                0.03794191777706146,
                0.00850239023566246,
                -0.029572805389761925,
                -0.020313894376158714,
                -0.04071417823433876,
                0.013797285035252571,
                0.01105561200529337,
                -0.01681235432624817,
                0.04616426303982735,
                -0.015257813967764378,
                -0.029223810881376266,
                -0.057282380759716034,
                0.03324820101261139,
                0.011307685635983944,
                -0.046298906207084656,
                -0.04184478521347046,
                -0.017250606790184975,
                -0.028672391548752785,
                -0.03524063155055046,
                0.05072523280978203,
                -0.027512025088071823,
                -0.0007551428279839456,
                -0.021577220410108566,
                -0.007680341135710478,
                0.049155671149492264,
                0.017242472618818283,
                0.001763032516464591,
                0.03536786139011383,
                0.05277853086590767,
                -0.016636285930871964,
                -0.11243224143981934,
                -0.020988453179597855,
                -0.011799006722867489,
                0.03741580992937088,
                0.03230380639433861,
                0.018490714952349663,
                -0.0383305661380291,
                0.023640675470232964,
                0.0381598062813282,
                0.08472192287445068,
                0.018129246309399605,
                -0.05588730797171593,
                -0.03668232634663582,
                -0.017630623653531075,
                -0.0038035255856812,
                -0.04792030155658722,
                0.0019002807093784213,
                0.0674855038523674,
                0.02406880632042885,
                -0.015164336189627647,
                -0.01788262650370598,
                -0.0721963420510292,
                0.007388658355921507,
                -0.02217691019177437,
                -0.1020263060927391,
                -0.019569212570786476,
                0.03970155119895935,
                0.037616416811943054,
                0.01633918099105358,
                -0.012489257380366325,
                0.04315270856022835,
                -0.02006290853023529,
                0.01852867193520069,
                -0.03287800773978233,
                -0.04226139932870865,
                -0.014561830088496208,
                0.00047237894614227116,
                -0.01361903827637434,
                -0.00690419040620327,
                0.029387539252638817,
                -0.044689666479825974,
                -0.012301918119192123,
                -0.027570834383368492,
                0.0019100930076092482,
                -0.051191624253988266,
                0.13547511398792267,
                0.02417091466486454,
                -0.059877004474401474,
                0.0515783354640007,
                0.019637692719697952,
                -0.017638321965932846,
                0.042326051741838455,
                -0.02033236064016819,
                -0.03628674894571304,
                0.01655467040836811,
                -0.0015389994950965047,
                0.01947004161775112,
                0.013949834741652012,
                0.08559522777795792,
                0.016681188717484474,
                0.034141167998313904,
                -0.009762831963598728,
                0.03950989246368408,
                -0.0420491099357605,
                -0.0015836100792512298,
                0.03602265566587448,
                -0.005743710324168205,
                0.04877902567386627,
                0.004964496474713087,
                -0.009395248256623745,
                0.003386159660294652,
                0.02265949733555317,
                -0.00094466662267223,
                0.015503168106079102,
                0.033951286226511,
                0.0113431541249156,
                0.04639710858464241,
                -0.015040627680718899,
                0.040707506239414215,
                0.03462575376033783,
                0.030280381441116333,
                -0.004305885639041662,
                -0.08198735117912292,
                -0.0005602436722256243,
                -0.00318935071118176,
                0.037427596747875214,
                -0.010259674862027168,
                0.014130990020930767,
                0.008479450829327106,
                0.06298121064901352,
                0.0002138508716598153,
                0.007644184865057468,
                -0.023309193551540375,
                -0.04449508339166641,
                -0.001966312527656555,
                0.04282388836145401,
                -0.0630401149392128,
                0.005394647363573313,
                -0.08135147392749786,
                0.03213194012641907,
                0.05254676192998886,
                -0.016628118231892586,
                0.05931835249066353,
                0.027194708585739136,
                0.01441917009651661,
                0.03859075531363487,
                0.00533199030905962,
                0.08309843391180038,
                -0.030907390639185905,
                0.004189317114651203,
                0.012868505902588367,
                -0.029087908565998077,
                -0.0023768602404743433,
                0.0005141290603205562,
                0.01619706302881241,
                -0.0335160531103611,
                -0.00043575020390562713,
                -0.016009893268346786,
                -0.034378781914711,
                0.015346994623541832,
                0.05346168950200081,
                -0.0345042385160923,
                -0.016934199258685112,
                -0.01224821899086237,
                -0.011408121325075626,
                -0.01237520296126604,
                0.021235384047031403,
                0.01059939805418253,
                0.033581800758838654,
                -0.02112264186143875,
                -0.032361336052417755,
                -0.031181540340185165,
                -0.022867295891046524,
                -0.043066106736660004,
                -0.005297019146382809,
                0.023692017421126366,
                -0.026681160554289818,
                0.018838616088032722,
                0.018932156264781952,
                -0.07878798991441727,
                -0.014571299776434898,
                0.034129317849874496,
                0.005711684934794903,
                -0.024798434227705002,
                0.00877018179744482,
                0.017387744039297104,
                -0.05445273220539093,
                0.004411306697875261,
                -0.07065119594335556,
                0.006212855223566294,
                -0.016320902854204178,
                -0.019416553899645805,
                0.03265244886279106,
                0.04422923922538757,
                0.013861299492418766,
                0.012276293709874153,
                0.017461884766817093,
                0.02954944036900997,
                -0.005985332187265158,
                0.020315710455179214,
                -0.08374160528182983,
                -0.013571704737842083,
                0.09342062473297119,
                0.00468925666064024,
                -0.004687960259616375,
                -0.002068842761218548,
                -0.04103359207510948,
                0.03518306463956833,
                0.012694143690168858,
                -0.008020416833460331,
                0.03731947019696236,
                0.025425132364034653,
                0.010748133063316345,
                -0.006278976798057556,
                0.004669641610234976,
                -0.006623082794249058,
                -0.0071836840361356735,
                -0.06635607033967972,
                0.06804271042346954,
                -0.021577123552560806,
                -0.04503943398594856,
                0.0018344257259741426,
                -0.0018412867793813348,
                0.027117954567074776,
                -0.013198330998420715,
                -0.06081027165055275,
                -0.009580759331583977,
                -0.07417789846658707,
                -0.019056186079978943,
                -0.011159452609717846,
                -0.04063480719923973,
                -0.010431189090013504,
                -0.034356314688920975,
                0.03962447866797447,
                -0.04044174775481224,
                -0.01428826805204153,
                -0.036217451095581055,
                0.016055794432759285,
                0.047710202634334564,
                -0.03980380669236183,
                -0.03430243209004402,
                0.07200942933559418,
                -0.06744435429573059,
                -0.04521031305193901,
                0.03579295054078102,
                0.013655563816428185,
                0.03344352915883064,
                -0.008505415171384811,
                -0.012150597758591175,
                0.04827052354812622,
                0.05979969725012779,
                -0.003460417967289686,
                0.021880419924855232,
                0.03329722583293915,
                0.09702277183532715,
                -0.012001154944300652,
                0.04253099113702774,
                -0.008350987918674946,
                0.03872765228152275,
                0.029998525977134705,
                -0.05725587159395218,
                -0.005470586009323597,
                -0.02750229649245739,
                -0.015079093165695667,
                -0.06814801692962646,
                -0.005032951943576336,
                0.002465922851115465,
                -0.012468252331018448,
                -0.0017971954075619578,
                -0.014361008070409298,
                -0.006735468283295631,
                -0.014441490173339844,
                0.0006914333789609373,
                0.035890813916921616,
                -0.015275736339390278,
                -0.027901235967874527,
                0.0042362334206700325,
                0.005208464805036783,
                -0.03976063057780266,
                0.018846863880753517,
                0.05200330913066864,
                0.025069300085306168,
                0.007534251548349857,
                -0.00920533575117588,
                0.032554686069488525,
                0.014336279593408108,
                0.06563284993171692,
                -0.08593741804361343,
                0.005226710811257362,
                0.02476072870194912,
                0.02790689840912819,
                -0.012814014218747616,
                -0.11623944342136383,
                -0.0935453549027443,
                0.024986281991004944,
                -0.01641171984374523,
                0.006208582315593958,
                0.04962887987494469,
                -0.06189584732055664,
                -0.011915838345885277,
                -0.0027579099405556917,
                0.03227607533335686,
                -0.014880026690661907,
                0.019102299585938454,
                -0.010401556268334389,
                -0.010945907793939114,
                0.0076547348871827126,
                -0.019709529355168343,
                -0.007053557317703962,
                -0.009904262609779835,
                0.01648406684398651,
                0.03217436745762825,
                -0.02724074013531208,
                0.02695418708026409,
                0.03138226270675659,
                -0.0318007618188858,
                0.005283897742629051,
                0.035649824887514114,
                -0.04665061831474304,
                0.03259444236755371,
                -0.02017088793218136,
                0.009389329701662064,
                -0.040089841932058334,
                0.04734998941421509,
                0.02405332401394844,
                0.009286470711231232,
                -0.0030091393273323774,
                -0.04055629298090935,
                0.061976946890354156,
                0.015514318831264973,
                -0.04759035259485245,
                -0.06358959525823593,
                -0.001476265606470406,
                0.018516842275857925,
                -0.000948740285821259,
                0.0773206278681755,
                -0.055170878767967224,
                -0.00011010888556484133,
                0.029946470633149147,
                -0.024474939331412315,
                0.034065134823322296,
                0.023238038644194603,
                0.011147444136440754,
                0.038623496890068054,
                0.04863936826586723,
                -0.017865478992462158,
                0.005920788273215294,
                -0.027250835672020912,
                -0.008309588767588139,
                -0.052168767899274826,
                -0.09419170022010803,
                0.015054254792630672,
                -0.017373042181134224,
                -0.0212789885699749,
                -0.015540892258286476,
                0.008121649734675884,
                0.02210022322833538,
                0.01686909608542919,
                0.04057895392179489,
                -0.07430713623762131,
                -0.01670350506901741,
                0.022087320685386658,
                0.03120884671807289,
                -0.004375734832137823,
                0.039748355746269226,
                -0.012170576490461826,
                0.09126579016447067,
                -0.008379514329135418,
                -0.00971437618136406,
                -0.014656773768365383,
                0.03254341706633568,
                0.004475951194763184,
                0.013180469162762165,
                0.011383755132555962,
                0.0098408879712224,
                -0.023733828216791153,
                -0.11091248691082001,
                -0.033472977578639984,
                0.022132862359285355,
                -0.0707734078168869,
                -0.053927645087242126,
                0.011150755919516087,
                0.034099020063877106,
                -0.016132431104779243,
                -0.0007308353087864816,
                0.004484664183109999,
                -0.013767585158348083,
                -0.03109259530901909,
                0.0031504181679338217,
                0.032848358154296875,
                -0.017810506746172905,
                0.014195844531059265,
                -0.05084830895066261,
                0.009160265326499939,
                -0.008189015090465546,
                -0.05577855929732323,
                -0.06963561475276947,
                -0.05509818345308304,
                -0.027427401393651962,
                -0.021339457482099533,
                -0.028872832655906677,
                -0.0010154498741030693,
                -0.019410640001296997,
                0.008841289207339287,
                0.002452622400596738,
                0.015111558139324188,
                -0.008346452377736568,
                0.03967735171318054,
                0.012951409444212914,
                -0.008898294530808926,
                -0.012936958111822605,
                0.033750902861356735,
                -0.0014725103974342346,
                -0.07405588030815125,
                -0.021981915459036827,
                -0.09203343093395233,
                -0.024652695283293724,
                -0.045007139444351196,
                0.06835473328828812,
                -0.006283800583332777,
                0.06585589796304703,
                -0.0473523773252964,
                -0.05014568194746971,
                -0.016767313703894615,
                -0.012049521319568157,
                0.04068231210112572,
                0.010561897419393063,
                0.03061344474554062,
                0.006893709301948547,
                0.003849996719509363,
                0.02822774276137352,
                0.04905274882912636,
                0.0385812446475029,
                -0.001651845290325582,
                -0.061805132776498795,
                -0.024899384006857872,
                -0.059664636850357056,
                0.0338401161134243,
                -8.158911597805643e-33,
                0.015852339565753937,
                -0.054615721106529236,
                -0.010325266048312187,
                0.031031794846057892,
                -0.025404492393136024,
                0.05032267048954964,
                0.005732262507081032,
                0.03156186267733574,
                0.004871570039540529,
                -0.04223163053393364,
                5.847103238920681e-06,
                0.0036051394417881966,
                0.02071255072951317,
                -0.013630437664687634,
                0.06952886283397675,
                0.025488469749689102,
                -0.007420999463647604,
                0.01599162630736828,
                -0.020814383402466774,
                -0.033539462834596634,
                0.0018523519393056631,
                0.04553309455513954,
                0.039840735495090485,
                0.018148750066757202,
                0.028246978297829628,
                -0.0342397503554821,
                -0.0790063887834549,
                0.006347085814923048,
                -0.0286396574229002,
                0.011687537655234337,
                -0.0208697821944952,
                -0.047462258487939835,
                -0.016522273421287537,
                -0.041283171623945236,
                -0.015737276524305344,
                0.06416651606559753,
                -0.036886777728796005,
                -0.01934785023331642,
                -0.011362971737980843,
                -0.00411244248971343,
                -0.05513741821050644,
                4.171779073658399e-05,
                -0.04203840717673302,
                -0.022372694686055183,
                -0.0014745538355782628,
                -0.00955750048160553,
                0.01113816350698471,
                0.01123060379177332,
                0.017027046531438828,
                0.052350062876939774,
                0.022384442389011383,
                -0.0010510991560295224,
                -0.0060837967321276665,
                -0.032932098954916,
                0.008044603280723095,
                0.06325743347406387,
                -0.022984690964221954,
                -0.0205707848072052,
                -0.02549579367041588,
                0.059953540563583374,
                -0.06259582936763763,
                0.03679749742150307,
                -0.020565658807754517,
                -0.021862052381038666,
                -0.010969450697302818,
                0.053737811744213104,
                -0.08969394862651825,
                -0.03652416542172432,
                -0.005999691318720579,
                0.01762065850198269,
                -0.026629019528627396,
                0.06451099365949631,
                0.05417132377624512,
                0.07224985957145691,
                -0.10528238862752914,
                -0.0530649796128273,
                -0.04160086810588837,
                0.015126458369195461,
                0.0563083253800869,
                0.022409342229366302,
                0.0004296705883461982,
                0.06841345876455307,
                0.0023043681867420673,
                0.027749430388212204,
                -0.019711248576641083,
                -0.06585048139095306,
                -0.028008129447698593,
                0.006815609987825155,
                -0.01528109796345234,
                -0.007657764479517937,
                -0.04178033396601677,
                0.019332602620124817,
                0.022250177338719368,
                -0.0013475276064127684,
                -0.017460588365793228,
                -0.02743309549987316,
                0.0016636509681120515,
                -0.0018393525388091803,
                0.0025112160947173834,
                0.014574832282960415,
                0.02899247407913208,
                0.013809648342430592,
                0.01874919794499874,
                0.035946331918239594,
                0.009769432246685028,
                0.035796258598566055,
                0.035368070006370544,
                -0.03623601794242859,
                -0.013433585874736309,
                -0.01279513631016016,
                -0.013778531923890114,
                -0.03952774778008461,
                0.025835804641246796,
                -0.04537903144955635,
                0.0020024776458740234,
                0.000501972739584744,
                -0.02936767041683197,
                -0.03039400652050972,
                -0.0101331677287817,
                -0.034665562212467194,
                -0.0033802601974457502,
                0.12861330807209015,
                -0.018728740513324738,
                0.002255659317597747,
                0.0036410526372492313,
                -0.0049834647215902805,
                -0.02431511878967285,
                -0.004458072129637003,
                0.005033258348703384,
                -0.012877182103693485,
                0.003610389307141304,
                -0.051713284105062485,
                2.7743462283069675e-07,
                -0.09618715196847916,
                0.01586557924747467,
                -0.02850881963968277,
                -0.05154942721128464,
                0.03254867345094681,
                0.03835389018058777,
                0.00755685567855835,
                0.06833560019731522,
                -0.03974267840385437,
                -0.0961788147687912,
                0.02215592749416828,
                -0.035859111696481705,
                0.002778384368866682,
                0.011497443541884422,
                0.07519269734621048,
                -0.06258948892354965,
                -0.020240260288119316,
                -0.051386814564466476,
                0.041325319558382034,
                -0.03967410326004028,
                0.024916162714362144,
                0.0030894556548446417,
                0.046353425830602646,
                -0.02104085497558117,
                0.014409215189516544,
                0.0485905259847641,
                0.019995586946606636,
                0.00927783828228712,
                0.02051965706050396,
                -0.021207766607403755,
                0.07787661254405975,
                0.011408898048102856,
                -0.03515157103538513,
                0.040098801255226135,
                -0.026518603786826134,
                -0.019205106422305107,
                0.027064070105552673,
                0.009810124523937702,
                0.0007819053716957569,
                0.06507356464862823,
                -0.005095901433378458,
                -0.025745535269379616,
                -0.030422544106841087,
                -0.016561493277549744,
                -0.007171656470745802,
                -7.160045788623393e-05,
                -0.01290917955338955,
                0.018218418583273888,
                -0.03603854030370712,
                -0.014359215274453163,
                0.0052046263590455055,
                0.030006710439920425,
                0.03682278096675873,
                0.04983755573630333,
                0.004592217970639467,
                0.047919731587171555,
                0.009936788119375706,
                0.04314050450921059,
                0.040400292724370956,
                -0.0013607561122626066,
                0.011685322038829327,
                -0.008780649863183498,
                0.02232905477285385,
                0.018921440467238426,
                -0.03878793865442276,
                0.011535532772541046,
                -0.04104132205247879,
                7.938160416794689e-35,
                -0.020331624895334244,
                -0.05192997306585312,
                -0.013507616706192493,
                0.003043568227440119,
                0.06975678354501724,
                -0.008908746764063835,
                0.028757255524396896,
                -0.006747585255652666,
                0.03476366028189659,
                0.04273836687207222,
                -0.018221132457256317,
                0.028494834899902344,
                -0.013687191531062126,
                -0.03299400955438614,
                -0.00319861457683146,
                -0.012805473059415817,
                -0.028248101472854614,
                0.005216194316744804,
                0.02670094557106495,
                -0.06437978148460388,
                0.013466035947203636,
                0.054746322333812714,
                0.04991421848535538,
                -0.01444450207054615,
                0.08966292440891266,
                -0.0006735369679518044,
                0.02549026533961296,
                0.012121072970330715,
                0.014828525483608246,
                -0.054060954600572586,
                -0.006796685978770256,
                0.006771683227270842,
                0.014661985449492931,
                0.033820200711488724,
                -0.010350908152759075,
                0.005252387840300798,
                -0.016435779631137848,
                -0.006030181422829628,
                -0.00879254937171936,
                -0.026080112904310226,
                -0.03500622510910034,
                0.02276649698615074,
                0.014843149110674858,
                -0.04235167056322098,
                0.009756110608577728,
                1.8896903384302277e-06,
                -0.05679352581501007,
                0.004712890833616257,
                -0.013644557446241379,
                -0.04192511364817619,
                0.03914067894220352,
                0.08767883479595184,
                0.00557219423353672,
                -0.02477368898689747,
                0.012806353159248829,
                -0.04665404558181763,
                -0.011304552666842937,
                0.007636364549398422,
                -0.0016469061374664307,
                -0.020815687254071236,
                -0.02080339752137661,
                -0.007703742012381554,
                -0.025864431634545326,
                -0.01951146498322487,
                -0.05066254362463951,
                -0.013836978003382683,
                0.024407098069787025,
                0.02834150567650795,
                -0.016393236815929413,
                0.09195730835199356,
                -0.012234163470566273,
                -0.015891913324594498,
                -0.00021298619685694575,
                -0.018427755683660507,
                0.0008816474000923336,
                0.06295030564069748,
                0.02007118985056877,
                0.02642817422747612,
                -0.016631003469228745,
                0.0051576909609138966,
                0.022396832704544067,
                -0.02190759964287281,
                0.011392056941986084,
                -0.01588883437216282,
                0.07995549589395523,
                0.002042034873738885,
                -0.010509808547794819,
                -0.030832717195153236,
                -0.03956678882241249,
                0.015980591997504234,
                0.0028811548836529255,
                0.008742294274270535,
                -0.0006920743035152555,
                -0.023409878835082054,
                0.036997973918914795,
                0.016580281779170036,
                0.06232237443327904,
                -0.026278376579284668,
                -0.012878941372036934,
                -0.031108282506465912,
                0.018857082352042198,
                0.03647570684552193,
                -0.018233943730592728,
                -0.00551490718498826,
                0.015160612761974335,
                0.003649897174909711,
                -0.01899748481810093,
                0.037606947124004364,
                -0.0023020440712571144,
                -0.019613882526755333,
                -0.021864308044314384,
                -0.021299265325069427,
                0.00819521863013506,
                0.056566547602415085,
                0.05371427536010742,
                -0.069194495677948,
                0.008468303829431534,
                -0.05037301406264305,
                -0.028060132637619972,
                -0.03885936364531517,
                0.07503039389848709,
                -0.06660483777523041,
                -0.023056108504533768,
                -0.039096031337976456,
                0.013208184391260147,
                0.01488682720810175,
                -0.044092807918787,
                -0.030416563153266907,
                0.06703677028417587,
                0.011448264122009277,
                -0.023593293502926826,
                -0.08008159697055817,
                0.042246922850608826,
                -0.029262317344546318,
                0.025069305673241615,
                0.0016426972579210997,
                0.022531503811478615,
                -0.005121214780956507,
                0.02099345438182354,
                0.017577530816197395,
                0.01659037172794342,
                0.006244696211069822,
                -0.015545612201094627,
                0.021262988448143005,
                0.008685199543833733,
                0.013486449606716633,
                -0.04888487234711647,
                -0.016476133838295937,
                -0.0028730412013828754,
                -0.061142854392528534,
                -0.048789314925670624,
                -0.011716547422111034,
                -0.010065318085253239,
                -0.01095265056937933,
                -0.024670056998729706,
                -0.04730050265789032,
                -0.01777051016688347,
                0.023321164771914482,
                -0.01732293702661991,
                -0.00731659634038806,
                -0.026725078001618385,
                -0.03543321415781975,
                -0.032405752688646317,
                -0.0557846762239933,
                0.026462558656930923,
                0.035589639097452164,
                0.0636403039097786,
                0.025246726348996162,
                -0.006824016105383635,
                0.006484072655439377,
                -0.05206592008471489,
                0.023397184908390045,
                0.031675949692726135,
                0.0021345550194382668,
                -0.017224669456481934,
                0.014319133013486862,
                -0.0028171564918011427,
                0.06167317181825638,
                -0.016992852091789246,
                -0.11297339200973511,
                0.0008925881702452898,
                -0.04300949349999428,
                -0.03044678270816803,
                -0.046464644372463226,
                0.13424192368984222,
                0.03976186737418175,
                -0.026938728988170624,
                0.027442622929811478,
                0.0223997849971056,
                -0.04610637575387955,
                -0.021417906507849693,
                -0.06377366930246353,
                -0.1240183413028717,
                0.07056871056556702,
                -0.014563019387423992,
                0.05162111297249794,
                0.030418360605835915,
                0.04055726155638695,
                0.023088905960321426,
                -0.07928362488746643,
                0.0025121208745986223,
                0.01569182612001896,
                -0.0054274434223771095,
                0.027055734768509865,
                0.000950123299844563,
                -0.005328187253326178,
                0.031798508018255234,
                0.02243310958147049,
                -0.014595963060855865,
                -0.03825484961271286,
                -0.017470451071858406,
                0.010978800244629383,
                -0.046182096004486084,
                0.08295302838087082,
                0.07181084156036377,
                -0.02951117977499962,
                0.014796467497944832,
                0.05994298309087753,
                -0.01379611436277628,
                0.02941032499074936,
                0.009046006016433239,
                0.05882050096988678,
                0.028231430798768997,
                -0.06656192988157272,
                0.04942489415407181,
                0.004400284960865974,
                -0.042774002999067307,
                0.013066808693110943,
                -0.01660681888461113,
                -0.013715509325265884,
                0.08391916006803513,
                -0.01821454055607319,
                0.03150158375501633,
                0.08424170315265656,
                -0.04923976585268974,
                -0.033417217433452606,
                -0.03906207159161568,
                0.02145630680024624,
                -0.03480285406112671,
                0.00355345057323575,
                0.022015366703271866,
                0.010614066384732723,
                0.05153682827949524,
                0.022228706628084183,
                -0.016780521720647812,
                -0.015413044951856136,
                0.08075511455535889,
                0.11207929253578186,
                0.04414672404527664,
                -0.026568343862891197,
                -0.11028912663459778,
                0.019683048129081726,
                0.009678915143013,
                0.034179337322711945,
                0.00895463302731514,
                0.06293020397424698,
                -0.02627452090382576,
                0.06911873817443848,
                -0.02997605875134468,
                0.012182291597127914,
                0.001496296958066523,
                -0.036753907799720764,
                -0.010466559790074825,
                0.019307883456349373,
                -0.006506446283310652,
                0.03407502919435501,
                -0.02121664211153984,
                -0.037680208683013916,
                0.00865115225315094,
                0.001229786197654903,
                -0.012342631816864014,
                0.0433255173265934,
                0.011809069663286209,
                -0.00416845316067338,
                0.010981670580804348,
                0.05117763578891754,
                -0.04959135130047798,
                0.0494036041200161,
                -0.03554535284638405,
                -0.028783101588487625,
                0.005427346099168062,
                0.0032924464903771877,
                0.028415875509381294,
                0.08077871054410934,
                0.00990285724401474,
                0.016070742160081863,
                0.0006817306857556105,
                -0.02716878615319729,
                0.01903780736029148,
                0.024411160498857498,
                0.01282100472599268,
                0.03826445713639259,
                -0.018232354894280434,
                -0.005005846731364727,
                -0.012287773191928864,
                0.02044413611292839,
                -0.016742078587412834,
                -0.0029170589987188578,
                0.0022980966605246067,
                -0.020144859328866005,
                -0.0798610970377922,
                -0.005678016692399979,
                0.008723064325749874,
                0.019207166507840157,
                0.02729906514286995,
                0.06364569813013077,
                -0.015483248047530651,
                0.010276474058628082,
                -0.04723404347896576,
                -0.015410391613841057,
                -0.006044755224138498,
                -0.06273756921291351,
                -0.019336190074682236,
                0.03706593066453934,
                -0.021674340590834618,
                -0.04237747564911842,
                0.036225154995918274,
                0.05125509947538376,
                0.007734754588454962,
                -0.0006635400932282209,
                -0.01841914840042591,
                -0.010410540737211704,
                0.011264466680586338,
                0.03177495300769806,
                0.057328734546899796,
                0.0166469719260931,
                -0.06339090317487717,
                -0.01955254003405571,
                -0.06327778100967407,
                -0.0016589652514085174,
                0.09031342715024948,
                -0.028079424053430557,
                0.008728764951229095,
                -0.04759574308991432,
                0.03010787069797516,
                0.019782090559601784,
                -0.004738186486065388,
                0.0031336359679698944,
                -0.006160408724099398,
                0.04155532643198967,
                -0.0688246414065361,
                -0.002258730586618185,
                0.015535027720034122,
                0.02566085197031498,
                -0.01994757354259491,
                -0.0008011647732928395,
                0.05135791376233101,
                -0.05460413172841072,
                -0.007862526923418045,
                -0.03719513863325119,
                0.008995524607598782,
                0.002443216508254409,
                -0.039456166326999664,
                -0.038804370909929276,
                -0.07367605715990067,
                0.049291834235191345,
                -0.0035456581972539425,
                0.007056097965687513,
                0.03373058885335922,
                -0.05956364795565605,
                -0.006609780713915825,
                0.018830634653568268,
                0.07495839893817902,
                0.007262812461704016,
                -0.003859369084239006,
                -0.0017943775746971369,
                0.005023992154747248,
                -0.05422201752662659,
                0.046165771782398224,
                -0.02281019277870655,
                -0.016910307109355927,
                0.04041225463151932,
                0.015271544456481934,
                -0.01652829721570015,
                -0.019601035863161087,
                -0.01631169393658638,
                0.053033653646707535,
                0.02033597230911255,
                0.02671797201037407,
                -0.020221590995788574,
                -0.0274606104940176,
                0.060276132076978683,
                0.04412313923239708,
                -0.03491765260696411,
                0.020874066278338432,
                0.005689270328730345,
                0.09295301884412766,
                0.016621682792901993,
                0.0217379629611969,
                -0.029373537749052048,
                0.008126595988869667,
                0.03368666395545006,
                -0.04903982952237129,
                0.06406335532665253,
                -0.036391399800777435,
                -0.014839572831988335,
                -0.01760922372341156,
                -0.00861075147986412,
                -0.016296803951263428,
                -0.029554812237620354,
                0.005800993647426367,
                -0.0076229809783399105,
                0.0030560933519154787,
                -0.019169004634022713,
                0.04862202703952789,
                0.014032444916665554,
                0.0343208909034729,
                -0.03556976467370987,
                0.015237810090184212,
                0.004620786756277084,
                0.025112271308898926,
                -0.008914654143154621,
                0.009244350716471672,
                0.043472714722156525,
                -0.006668452173471451,
                0.011027874425053596,
                -0.10230553150177002,
                0.1279040277004242,
                0.01235060766339302,
                0.00597925391048193,
                -0.04630325734615326,
                0.02032363787293434,
                0.023346252739429474,
                0.027824746444821358,
                -0.019602593034505844,
                0.0013585927663370967,
                -0.022570736706256866,
                -0.03013225458562374,
                0.004544268362224102,
                0.0188547782599926,
                -0.03982415422797203,
                -0.0011153423693031073,
                -0.04788239672780037,
                0.013522484339773655,
                0.01979338750243187,
                0.009922131896018982,
                -0.027800194919109344,
                -0.0199899822473526,
                0.030225887894630432,
                -0.002541055204346776,
                -0.013676983304321766,
                -0.034984562546014786,
                -0.006351836957037449,
                -0.0003240023215766996,
                -0.017161501571536064,
                -0.005110095255076885,
                -0.07019967585802078,
                -0.0712343230843544,
                0.02920730598270893,
                0.0021081320010125637,
                -0.07567626982927322,
                0.026052147150039673,
                0.00812325906008482,
                -0.009761619381606579,
                0.009172034449875355,
                0.0028553223237395287,
                -0.03579028695821762,
                -0.014285910874605179,
                -0.029935644939541817,
                -0.07538662850856781,
                0.043376658111810684,
                -0.04096286743879318,
                0.00428622355684638,
                0.017052358016371727,
                -0.0586225725710392,
                0.0018217862816527486,
                -0.008491069078445435,
                -0.00919574685394764,
                0.0012746949214488268,
                -0.008432825095951557,
                -0.020796481519937515,
                -0.015622377395629883,
                0.03440161421895027,
                -0.0010630171746015549,
                -0.008584091439843178,
                0.024872688576579094,
                0.04144803807139397,
                0.03332190588116646,
                0.0400984100997448,
                0.007890983484685421,
                -0.03531839698553085,
                0.014487162232398987,
                -0.0034465473145246506,
                -0.007990951649844646,
                0.03291477635502815,
                -0.03720206394791603,
                0.010942191816866398,
                -0.01167515479028225,
                -0.05784991383552551,
                0.011540843173861504,
                0.016973303630948067,
                -0.03219751641154289,
                0.04043213650584221,
                -0.01395481638610363,
                0.0320424884557724,
                0.0006893118261359632,
                -0.013127580285072327,
                0.09483259916305542,
                -0.013420029543340206,
                0.0037655108608305454,
                -0.04154454916715622,
                -0.009258788079023361,
                -0.026624536141753197,
                0.0193531084805727,
                0.05853509530425072,
                0.02219463139772415,
                0.026305556297302246,
                -0.09896192699670792,
                0.005264716222882271,
                -0.034115999937057495,
                0.035137757658958435,
                -0.028081756085157394,
                0.024678891524672508,
                0.023509452119469643,
                0.0460270419716835,
                -0.023450098931789398,
                0.0020488654263317585,
                0.011804231442511082,
                0.043153729289770126,
                -0.006862621288746595,
                -0.004092456307262182,
                0.029613668099045753,
                0.036172423511743546,
                -0.039482731372117996,
                -0.12293293327093124,
                -0.03283063322305679,
                0.09984974563121796,
                -0.05953545868396759,
                -0.008519371040165424,
                0.007520944345742464,
                0.0065063415095210075,
                -0.03129321709275246,
                -0.01359181571751833,
                0.020178038626909256,
                -0.022529365494847298,
                0.04729641601443291,
                0.01649072766304016,
                0.061760224401950836,
                0.020659836009144783,
                -0.020489046350121498,
                -0.01319156214594841,
                -0.0823323130607605,
                0.0010867887176573277,
                -0.05193132534623146,
                0.06029397249221802,
                -0.03478601202368736,
                0.0695340633392334,
                0.0137105043977499,
                0.04323827475309372,
                0.016730939969420433,
                0.029557378962635994,
                -0.04668011888861656,
                0.059201981872320175,
                0.027573801577091217,
                -0.00570641178637743,
                0.05541379004716873,
                0.00277241924777627,
                0.024090567603707314,
                0.0017350810812786222,
                0.005498511716723442,
                -0.02316650189459324,
                -0.024748150259256363,
                -0.048990000039339066,
                -0.06741616129875183,
                -0.05727477744221687,
                -1.5115921087272e-05,
                -7.340486145673102e-33,
                0.030647428706288338,
                -0.001180867780931294,
                0.019914641976356506,
                -0.0027834002394229174,
                -0.06283128261566162,
                0.01927981525659561,
                -0.021090656518936157,
                0.02323966473340988,
                -0.07550503313541412,
                -0.03347624093294144,
                -0.018785934895277023,
                -0.06601306796073914,
                0.028431866317987442,
                -0.008481297641992569,
                0.018982699140906334,
                -0.056812725961208344,
                0.015223153866827488,
                0.028254341334104538,
                -0.015715423971414566,
                -0.018637629225850105,
                -0.001224963809363544,
                0.011864332482218742,
                0.00017496316286269575,
                0.020781084895133972,
                0.016668742522597313,
                -0.002748848171904683,
                -0.03984628617763519,
                -0.04762924090027809,
                0.0013340087607502937,
                -0.008043825626373291,
                -0.0016043881187215447,
                0.017678799107670784,
                0.005213344935327768,
                -0.11123529821634293,
                -0.00801024679094553,
                0.03635265305638313,
                0.004859982989728451,
                -0.012175151146948338,
                -0.002451503649353981,
                0.024553965777158737,
                -0.07762851566076279,
                -0.03337659314274788,
                0.005022694822400808,
                0.026073161512613297,
                0.0003508497029542923,
                -0.02244184911251068,
                0.00461400905624032,
                0.01864699460566044,
                0.038219526410102844,
                0.06627313792705536,
                -0.04884669929742813,
                -0.014606324024498463,
                -0.03661443293094635,
                -0.02814311720430851,
                0.005560573656111956,
                -0.043905094265937805,
                -0.008627855218946934,
                -0.025331182405352592,
                -0.07247775793075562,
                0.033217597752809525,
                -0.0037088217213749886,
                0.04313893988728523,
                -0.007148295640945435,
                -0.01987701654434204,
                -0.03245112672448158,
                -0.03905094414949417,
                -0.03491184487938881,
                0.021529417484998703,
                0.03160133957862854,
                -0.0025083853397518396,
                -0.010677342303097248,
                0.0984722450375557,
                0.03131609037518501,
                0.0015128337545320392,
                -0.05763457715511322,
                0.0012762342812493443,
                0.04033053666353226,
                -0.02415669709444046,
                0.024763518944382668,
                0.006361907813698053,
                0.002306185197085142,
                0.002040402265265584,
                -0.0022449528332799673,
                -0.00754186324775219,
                0.018059901893138885,
                -0.02693614363670349,
                -0.013903734274208546,
                0.024263447150588036,
                -0.016383841633796692,
                0.02475627139210701,
                0.015573113225400448,
                0.048039983958005905,
                0.005324604921042919,
                -0.026965072378516197,
                -0.012668427079916,
                -0.05833229795098305,
                -0.04411499947309494,
                0.041687462478876114,
                0.008073019795119762,
                -0.07244477421045303,
                -0.034225497394800186,
                0.002746222075074911,
                -0.043217167258262634,
                0.10898224264383316,
                -0.0038906901609152555,
                0.003094955813139677,
                -0.023355169221758842,
                -0.011184786446392536,
                -0.035433974117040634,
                -0.01226332038640976,
                -0.010591543279588223,
                0.00430543115362525,
                0.03070502169430256,
                -0.07954005151987076,
                -0.025539187714457512,
                -0.01435352023690939,
                0.015152381733059883,
                -0.024606334045529366,
                -0.01932119019329548,
                -0.03306332603096962,
                0.001918305759318173,
                0.02483443357050419,
                -0.0276326946914196,
                -0.0058459932915866375,
                -0.04586811363697052,
                0.04022280499339104,
                0.025973103940486908,
                0.02762894704937935,
                -0.018274027854204178,
                -0.05505770817399025,
                -0.03844887763261795,
                0.010980327613651752,
                2.4884081994969165e-07,
                -0.004023879766464233,
                0.008044837042689323,
                -0.002603977220132947,
                0.0006723160040564835,
                0.04735695198178291,
                -0.0146494060754776,
                -0.024763790890574455,
                0.03885062038898468,
                0.03362669423222542,
                -0.002642006380483508,
                0.013927550055086613,
                0.014934116043150425,
                -0.03204568475484848,
                -0.021294526755809784,
                -0.03108273446559906,
                -0.12215173244476318,
                -0.042712971568107605,
                0.029192714020609856,
                0.0008873164770193398,
                -0.024427322670817375,
                0.0831281766295433,
                -0.028742898255586624,
                -0.00568619417026639,
                -0.0371193028986454,
                -0.025468481704592705,
                -0.021446775645017624,
                0.046662844717502594,
                0.0064772507175803185,
                0.08243676275014877,
                -0.014983618631958961,
                0.003089067293331027,
                0.04928622767329216,
                -0.012607067823410034,
                -0.009685452096164227,
                -0.022299285978078842,
                -0.016020018607378006,
                0.006521879229694605,
                -0.004827750846743584,
                0.04231216013431549,
                0.07732639461755753,
                0.00025690472102724016,
                -0.014802644029259682,
                -0.0037613394670188427,
                -0.001358025474473834,
                0.02218102477490902,
                0.06255913525819778,
                0.01360615249723196,
                0.04523264616727829,
                -0.06989345699548721,
                0.015807190909981728,
                -0.005876458249986172,
                -0.024942556396126747,
                0.01728104054927826,
                0.01893410086631775,
                -0.005979063920676708,
                0.03131795674562454,
                -0.030942190438508987,
                0.04751299321651459,
                0.03891380876302719,
                -0.011369496583938599,
                -0.01433531939983368,
                -0.020808830857276917,
                -0.008766005747020245,
                0.04111609235405922,
                0.030568895861506462,
                0.05387027561664581,
                -0.013956570066511631,
                7.439554513673001e-35,
                0.010530462488532066,
                -0.013125061988830566,
                0.05305555835366249,
                0.0420735664665699,
                -0.006768851540982723,
                -0.008290094323456287,
                0.04098263382911682,
                -0.016645004972815514,
                0.01600510999560356,
                0.019307171925902367,
                -0.027431465685367584
            ]
        },
        "Work Mode": "Open to both",
        "hash__": "b'\\xac\\xf0\\xce\\xd9<#\\x9eE\\x08\\xfe\\x99\\xb5=3U\\xab'",
        "id__": "482349bd-d463-482f-a458-6fdf0d3eec16"
    }
]

const TestingGround = ({ id }) => {
    const findId = `5e6d1235-2b65-4957-a63b-324fb78c8432`;
    console.log(obj);

    const [data, setData] = React.useState();
    async function getCases() {
        const getCaseUrl = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/case`;

        const findBody = {
            projectId: id,
        };

        const response = await authPostData(findBody, getCaseUrl);
        setData(response);
    }

    return (
        <>
            {data ? data.map((item, index) => <div>{item.id}</div>) : null}
            <ClickButton onClick={getCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
