import * as Yup from 'yup';

export const FIELDS = {
    PROJECTS: 'projects',
    YEARS: 'years'
};

export const validationSchema = Yup.object({
    [FIELDS.PROJECTS]: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name field is required'),
            years: Yup.number().typeError("Must be a number.").min(0, "Must be at least 0.").required('Years field is required'),
            months: Yup.number().when(FIELDS.YEARS, {
                is: (label) => label == 0,
                then: Yup.number().typeError("Must be a number.").min(1, "Must be greater than 0.").max(11, "Cannot be greater than 11.").required('Months field is required')
            }).max(11, "Cannot be greater than 11.").required("Months field is required"),
            ref_email: Yup.string().email('Invalid email address').nullable(),
        }),
    ),
});

export const initialValues = {
    [FIELDS.PROJECTS]: [{}],
};
