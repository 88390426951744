import React from 'react';
import { Button, Col, Form, FormGroup } from 'reactstrap';

import { AppInputWithoutAutocomplete } from '../../../components/AppInput';
import { forgotUserPassword } from '../../../contexts/auth/tools';
import style from '../style.module.scss';
import { FIELDS } from './validation';
import ClickButton from '../../../components/buttons/NudgeButton';

const ForgotPasswordEmailForm = ({ formik, email }) => {
    const onSendEmail = (e) => {
        e.preventDefault();
        forgotUserPassword(email);
    };

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <FormGroup row>
                <Col md={{ size: 10, offset: 1 }}>
                    <AppInputWithoutAutocomplete id={FIELDS.CODE} formik={formik} label="Verification code" />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col md={{ size: 10, offset: 1 }}>
                    <AppInputWithoutAutocomplete
                        id={FIELDS.NEW_PASSWORD}
                        type="password"
                        formik={formik}
                        label="New password"
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col md={{ size: 10, offset: 1 }}>
                    <AppInputWithoutAutocomplete
                        id={FIELDS.REPEAT_PASSWORD}
                        type="password"
                        formik={formik}
                        label="Repeat password"
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col md={{ size: 5, offset: 1 }}>
                    <ClickButton color="success" type="submit" className={style.btnSubmit} size="lg">
                        Submit
                    </ClickButton>
                </Col>
                <Col md={{ size: 5 }}>
                    <ClickButton color="danger" className={style.btnSend} size="lg" onClick={onSendEmail}>
                        Resend code
                    </ClickButton>
                </Col>
            </FormGroup>
        </Form>
    );
};

export default ForgotPasswordEmailForm;
