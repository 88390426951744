/* eslint-disable max-len */
import React from 'react';
import { Form, FormGroup } from 'reactstrap';

import { generateFeedbackFlask } from '../../../../api/flask';
import openai from '../../../../assets/images/openai.svg';
import openai_dark from '../../../../assets/images/openai-dark.png';
import AppInput from '../../../AppInput';
import ClickButton from '../../../buttons/NudgeButton';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import CheckboxField from '../../../CheckboxField';
import { Field } from 'formik';

const TextFieldModalForm = ({ formik, sending, generateContent, extraData }) => {
    const [isGenerating, setIsGenerating] = React.useState(false);
    const theme = GetPageTheme();

    const generateFeedback = async () => {
        if (formik.values['reasons'] === '') {
            return;
        }

        const flaskData = [
            extraData.companyName,
            extraData.offerName,
            extraData.contractorName
        ]

        setIsGenerating(true);
        generateFeedbackFlask([formik.values['reasons'], flaskData])
            .then((response) => {
                if (response) {
                    response = response.trim();
                    formik.setFieldValue(FIELDS.REASON, response);
                }
            })
            .catch((error) => { 
                setErrorMessage('Unable to generate. Please try again later.');
            })
            .finally(() => {
                setIsGenerating(false);
            });
    };

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-20 ">
                <AppInput
                    id={FIELDS.REASON}
                    formik={formik}
                    label="Feedback"
                    type="textarea"
                    className={style.textInput}
                />
            </FormGroup>

            { !!extraData?.smartMatch?.feedback ? <>
                <h5>This match was supported by our smart-matching system. Generate a PDF report and send to this candidate?</h5>
                <div style={{ display: 'flex', fontSize: '14px', columnGap: '6px', justifyContent: 'center', marginTop: '12px'}}>
                    <Field component={CheckboxField} formik={formik} name={FIELDS.GENERATE_REPORT} label={"Generate Feedback Report"} />
                </div>
                <br/>
            </> : null }
            

            {generateContent ? (
                <div id={theme == 'light' ? 'card-sub-light' : 'card-dark'} className={style.generateJobDesc}>
                    <p>
                        Need help coming up with feedback? Type in some key reasons and we&apos;ll create a draft for
                        you!
                    </p>
                    <AppInput id="reasons" formik={formik} label="Reasons" type="input" className={style.genInput} />
                    <ClickButton color="success" onClick={() => generateFeedback()} disabled={isGenerating}>
                        {isGenerating ? 'Generating, please wait...' : 'Generate Feedback'}
                    </ClickButton>
                    <p className={style.poweredBy}>
                        Powered by <img src={theme === 'light' ? openai : openai_dark} alt="OpenAI" />
                    </p>
                </div>
            ) : null}
        </Form>
    );
};

export default TextFieldModalForm;
