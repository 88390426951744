import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../consts/userRoles';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import Loader from '../Loader';
import PageTemplate from '../PageTemplate';
import { ELEMENTS_ADMIN, ELEMENTS_COMPANY, ELEMENTS_RECRUITER, ELEMENTS_USER } from './NewSidebar/elements';
import style from './style.module.scss';
import ScrollButton from './ScrollButton';
import useMobile from '../../hooks/useMobile';

import NewSidebar, { isActiveStep } from './NewSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from '../../contexts/theme/ThemeContextWrapper';
import { URLS } from '../../consts/urls';

const getSidebarElements = (user, roles) => {
    if (roles.includes(USER)) {
        return ELEMENTS_USER(user);
    } else if (roles.includes(COMPANY)) {
        return ELEMENTS_COMPANY;
    } else if (roles.includes(ADMIN)) {
        return ELEMENTS_ADMIN;
    } else if (roles.includes(RECRUITER)) {
        return ELEMENTS_RECRUITER;
    } else {
        return [];
    }
};

export const SignInPageTemplate = ({ children, className = '', customHeader }) => {
    const { user, userRoles, mutate, userMutating } = useContext(AuthContext);

    const { isMobileWide } = useMobile();
    const { theme, modalOpen, rtl: leftHanded } = useContext(ThemeContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const elements = getSidebarElements(user, userRoles);
    const [scroll, setScroll] = React.useState(false);

    if(scroll || !!modalOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'scroll';
    }

    if(isMobileWide) {
        if(window.screen.height <= 512 || window.screen.width <= 320) {
            return (
                <div className={style.rotateContainer}>
                    { window.matchMedia("(orientation: landscape)").matches ? <>
                        <h5>To continue using Nudge, please rotate your device to portrait mode.</h5>
                        <FontAwesomeIcon className={style.phoneIcon} icon={['fas', 'mobile-android-alt']}/>
                    </> : window.matchMedia("(orientation: portrait)").matches ? <>
                        <h5>To continue using Nudge, please unfold your device.</h5>
                        <FontAwesomeIcon className={style.phoneIconFold} icon={['fas', 'mobile-android-alt']}/>
                    </> : null }
    
                </div>
            )
        }
    }

    const NO_PADDING = [
        URLS.COMPANY_DASHBOARD,
        URLS.FOREST
    ]
    var needsPadding = NO_PADDING.find(isActiveStep);
    const rtl = leftHanded;

    return user ? (
        <PageTemplate className={`${style.page} ${className}`}>
            <Container fluid>
                <Row>
                    <div className={ rtl ? style.backgroundFlip : style.background}>
                        <NewSidebar user={user} mutate={mutate} userRoles={userRoles} elements={elements} customHeader={customHeader} setScroll={setScroll} leftHand={rtl} sending={userMutating}/>
                    </div>

                    <div id={`full-page-${theme}`} className={`${rtl ? style.childrenFlip : style.children} ${!needsPadding ? style.topBuffer : ''}`}>
                        {children}
                    </div>
                    { !scroll ? <ScrollButton /> : null }
                </Row>
            </Container>
        </PageTemplate>
    ) : (
        <Loader fullPage/>
    );
};

SignInPageTemplate.propTypes = {
    children: PropTypes.any.isRequired,
    user: PropTypes.object,
    className: PropTypes.string,
    navbar: PropTypes.node,
};

export default SignInPageTemplate;
