import React from 'react';

import { FIELDS, initialObject } from '../../validation';
import OfferField from '../../OfferField';

import style from '../../style.module.scss';
import { Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ErrorMessage, Field } from 'formik';
import SelectField from '../../../../../../components/SelectField';
import AppInput from '../../../../../../components/AppInput';

import RequiredBox from '../../RequiredBox';
import { usePlusButtonWithAlwaysMinus } from '../../../../../../components/buttons/PlusButton';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NudgeTabs from '../../../../../../components/NudgeTabs';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import QuestionsForm from '../../../../../../components/forms/QuestionsForm';

import {
    options,
    currencyOptions,
    offerTypeOptions,
    typeOptions,
    timingOptions,
    levelOptions,
    salaryFixedOptions,
    currencyLookup,
    ratePayOptions
} from '../../options';

const AddtlInfo = ({ formik, isEdit, tooltip }) => {
    
    const ErrorMessage = ({ field }) => {
        return formik.errors[field] ? <h5 className={style.errorText}>{formik.errors[field]}</h5> : null;
    };

    return (<>
        
        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Employee Type"
            click={() => tooltip.set(FIELDS.OFFER_TYPE)}
        >
            <Field
                name={FIELDS.OFFER_TYPE}
                component={SelectField}
                options={offerTypeOptions}
                placeholder="Freelancer or Staff Required"
            />
        </OfferField>
        
        {/* <OfferField
            selected={tooltip}
            isEdit={isEdit}
            click={() => setTooltip(FIELDS.INTERNAL)}
            label="Internal or Public Position?"
        >
            <Field
                name={FIELDS.INTERNAL}
                component={SelectField}
                options={internalExternalOptions}
                placeholder="Internal or Public Position?"
            />
        </OfferField> */}

        {formik.values[FIELDS.OFFER_TYPE] === 'Freelance' ? (
            <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label="Outside of IR35"
                click={() => tooltip.set(FIELDS.OUTSIDE)}
            >
                <>
                    <Field
                        name={FIELDS.OUTSIDE}
                        component={SelectField}
                        options={options}
                        placeholder="Outside IR35"
                    />
                </>
            </OfferField>
        ) : null}

        {'Freelance/PayE'.includes(formik.values[FIELDS.OFFER_TYPE]) ? (
            <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label="Duration of Contract in Days"
                click={() => tooltip.set(FIELDS.DURATION)}
            >
                <>
                    <AppInput
                        id={FIELDS.DURATION}
                        formik={formik}
                        label="Contract duration (days)"
                    />
                </>
            </OfferField>
        ) : null}

        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Paid or Volunteer?"
            click={() => tooltip.set(FIELDS.WORK_TYPE)}
        >
            <Field
                name={FIELDS.WORK_TYPE}
                component={SelectField}
                options={typeOptions}
                placeholder="Paid or Volunteer?"
            />
        </OfferField>

        <Row>
            {formik.values[FIELDS.WORK_TYPE] == typeOptions[0].value ? (
                <>
                    <Col md={6}>
                        <OfferField
                            selected={tooltip.get}
                            isEdit={isEdit}
                            showOnEdit
                            label="Currency Type"
                            click={() => tooltip.set(FIELDS.CURRENCY)}
                        >
                            <Field
                                name={FIELDS.CURRENCY}
                                component={SelectField}
                                options={currencyOptions}
                                placeholder={'Currencies'}
                            />
                        </OfferField>
                    </Col>
                    <Col md={6}>
                        <OfferField
                            selected={tooltip.get}
                            isEdit={isEdit}
                            showOnEdit
                            label="Variable or Fixed Rate?"
                            click={() => tooltip.set(FIELDS.REVENUE_FIXED)}
                        >
                            <Field
                                name={FIELDS.REVENUE_FIXED}
                                component={SelectField}
                                options={salaryFixedOptions}
                                placeholder={'Salary Fixed'}
                            />
                        </OfferField>
                    </Col>
                </>
            ) : null}
        </Row>
        

        {formik.values[FIELDS.WORK_TYPE] == typeOptions[0].value ? (
            <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label={
                    formik.values[FIELDS.REVENUE_FIXED] == 'Variable'
                        ? 'Range of Pay (Optional)'
                        : 'Fixed Rate'
                }
                click={() => tooltip.set(FIELDS.REVENUE_FIXED)}
            >
                <Row>
                    <Col
                        xs="12"
                        xl={formik.values[FIELDS.REVENUE_FIXED] == 'Variable' ? 4 : 8}
                        className={style.payRangeField}
                    >
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                {formik.values[FIELDS.REVENUE_FIXED] == 'Variable'
                                    ? 'Min. ' + currencyLookup[formik.values[FIELDS.CURRENCY]]
                                    : currencyLookup[formik.values[FIELDS.CURRENCY]]}
                            </InputGroupAddon>
                            <AppInput
                                id={FIELDS.REVENUEMIN}
                                formik={formik}
                                label=""
                                showError={false}
                                type="number"
                            />
                        </InputGroup>
                        <ErrorMessage field={FIELDS.REVENUEMIN} />
                    </Col>

                    {formik.values[FIELDS.REVENUE_FIXED] == 'Variable' ? (
                        <Col xs="12" xl="4" className={style.payRangeField}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    {'Max. ' + currencyLookup[formik.values[FIELDS.CURRENCY]]}
                                </InputGroupAddon>
                                <AppInput
                                    id={FIELDS.REVENUEMAX}
                                    formik={formik}
                                    label=""
                                    showError={false}
                                    type="number"
                                />
                            </InputGroup>
                            <ErrorMessage field={FIELDS.REVENUEMAX} />
                        </Col>
                    ) : null}

                    <Col xs="12" xl="4" className={style.payRangeField}>
                        <Field
                            name={FIELDS.REVENUEFREQ}
                            component={SelectField}
                            options={ratePayOptions}
                            placeholder="Frequency"
                            disabled={formik.values[FIELDS.OFFER_TYPE] == 'Staff'}
                        />
                    </Col>
                </Row>
            </OfferField>
        ) : null}

        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Full or Part Time?"
            click={() => tooltip.set(FIELDS.TIMING)}
        >
            <Field
                name={FIELDS.TIMING}
                component={SelectField}
                options={timingOptions}
                placeholder="Full Time or Part Time?"
            />
        </OfferField>

        <OfferField
            selected={tooltip.get}
            isEdit={isEdit}
            showOnEdit
            label="Employee Level"
            click={() => tooltip.set(FIELDS.EMPLOYEE_LEVEL)}
        >
            <Field
                name={FIELDS.EMPLOYEE_LEVEL}
                component={SelectField}
                options={levelOptions}
                placeholder="Employee Level"
            />
        </OfferField>
    </>)
}

export default AddtlInfo;