import React from 'react';
import { Button } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData, NEVER_REFRESH } from '../../../../../api/tools';
import { capitaliseFirstLetter } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { API_DASHBOARD_ADMIN, API_DASHBOARD_COMPANY } from '../../../../../consts/urls';
import { ADMIN } from '../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../contexts/auth/AuthProtectedRouter';
import moment from 'moment';

import SearchBox from '../../../../../components/SearchBox';

const TestingGround = ({ id }) => {
    function arrToString(input) {
        let str = '';
        if (input.length) {
            input.forEach((i, index) => {
                if (index == 0) {
                    str += `${i.title ? i.title : i.content ? i.content : i.name}`;
                } else {
                    str += `,${i.title ? i.title : i.content ? i.content : i.name}`;
                }
            });
        }
        return str;
    }

    const [search, setSearch] = React.useState('');
    const [alert, setAlert] = React.useState('');
    const getURL = `${API_DASHBOARD_ADMIN}/Contractors`;
    const { data, mutate } = useSWR(getURL, authFetcher, NEVER_REFRESH);

    const postURL = `https://zv8bm4dd8d.execute-api.eu-west-2.amazonaws.com/dev/retain`;

    async function retainCase() {
        let contractor;
        try {
            contractor = await authFetcher(`${API_DASHBOARD_ADMIN}/Contractors/${search}`);
            const split = contractor.expectedEarnings.replaceAll(' ', '').split('-');

            const body = {
                projectId: id,
                data: {
                    Certificates: arrToString(contractor.certificates),
                    Education: contractor.education.replaceAll('/', ' in '),
                    Experience: contractor.bio,
                    Freelance: contractor.freelancerStaff,
                    id: contractor.id,
                    Id: contractor.id,
                    Industry: contractor.job.industry,
                    Location: contractor.location || '',
                    Remuneration: (parseInt(split[0]) + parseInt(split[1])) / 2,
                    'Services Required': contractor.job.name,
                    Skills: arrToString(contractor.skills),
                    'Start Date': moment(contractor.startDate).format(`YYYY-MM-DD`),
                    Values: arrToString(contractor.values),
                    'Work Mode': capitaliseFirstLetter(contractor.workMode),
                },
            };

            const response = await authPostData(body, postURL);

            if (response) {
                setAlert('Succeeded. ' + contractor.id);
            } else {
                setAlert('Error occurred. Case not added. ' + contractor.id);
            }
        } catch (e) {
            console.log('Something went wrong: ' + e.message);
        }
    }

    React.useEffect(() => {
        if (data) console.log(data);
    }, [data]);

    return (
        <>
            {alert ? <h5>{alert}</h5> : null}
            <SearchBox value={search} setValue={setSearch} button={false} placeholder={'Contractor ID goes here...'} />
            <Button onClick={retainCase} color={'success'}>
                Post
            </Button>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
