import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData, NO_REFRESH_INTERVAL } from '../../../api/tools';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_CLOOD, URLS } from '../../../consts/urls';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../../consts/userRoles';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import BackButton from '../../buttons/BackButton';
import LinkButton from '../../buttons/LinkButton';
import { GetPageTheme, OfferClosedFunction } from '../../pages/GenericCompanyProfile/HelperComponents';
import { countNew } from '../../SignInPageTemplate/SignInNavbar/NotificationsModal';
import OfferInfo from '../OfferInfo';
import style from '../style.module.scss';
import CompanyAdminModals from './CompanyAdminModals';
import CompanyMatching from './CompanyMatching';
import KanbanCandidates from './KanbanCandidates';
import CandidatesToMatch from './NewCandidatesToMatch';
import RecruitersTab from './RecruitersTab';
import NudgeTabs from '../../NudgeTabs';
import ClickButton from '../../buttons/NudgeButton';
import NudgeAlert from '../../NudgeAlert';
import moment from 'moment';
import NudgeButtonGroup from '../../buttons/ButtonGroup';

export const ContractOffer = ({ roleUrl, disableEdit }) => {
    const navigate = useNavigate();
    const { user, userRoles } = useContext(AuthContext);

    const { id, tab } = useParams();

    const { data, isValidating, mutate } = useSWR(`${roleUrl}/Offers/${id}`, authFetcher, NO_REFRESH_INTERVAL);
    const theme = GetPageTheme()

    const [alert, setAlert] = useState("");
    const [showModal, setShowModal] = useState(false);

    const toggle = () => setShowModal(!showModal);

    const getEditOfferUrl = (roles) =>
        roles.includes(ADMIN) ? URLS.ADMIN_CONTRACTS_EDIT : URLS.COMPANY_CONTRACTS_EDIT;
    const onClickEdit = () => navigate(`${getEditOfferUrl(userRoles)}/${id}`);
    const onReopenOffer = () => navigate(`${getEditOfferUrl(userRoles)}/${id}`);

    function GetProfileLink() {
        if (userRoles.includes('Admin')) {
            return `${URLS.ADMIN_COMPANIES}/${data.company.id}`;
        } else if (userRoles.includes('User')) {
            return `${URLS.DIRECTORY}/${data.company.id}`;
        } else {
            return `${URLS.COMPANY_PROFILE}`;
        }
    }

    const TAB_NAMES = {
        OFFER_DETAILS: 'offer-details',
        CANDIDATES: 'candidates',
        SHORTLIST: 'shortlist',
        RECRUITERS: 'recruiters',
        KANBAN: 'overview'
    };

    React.useEffect(() => {
        async function retain() {
            const response = await authFetcher(`${API_DASHBOARD_CLOOD}/Projects/CaseExists/Recommender/${id}`);

            if (response == false) {
                const postResponse = await authPostData({}, `${API_DASHBOARD_CLOOD}/Projects/RetainOffer/${id}`, {});
            } else {
                console.log('Case already retained.');
            }
        }

        retain();
    }, []);

    const [activeTab, setActiveTab] = useState(TAB_NAMES.OFFER_DETAILS);

    var newNotifications = 0;
    if (user && userRoles) {
        if (!userRoles.includes('Admin')) {
            newNotifications = countNew(user.notifications);
        }
    }

    function getTabs() {
        const TABS = [
            { id: TAB_NAMES.OFFER_DETAILS, label: 'Details', condition: true },
            { id: TAB_NAMES.CANDIDATES, label: 'Candidates', condition: !userRoles.includes(RECRUITER) && !OfferClosedFunction(data.availabilityText) },
            { id: TAB_NAMES.RECRUITERS, label: 'Recruiters', condition: userRoles.includes(COMPANY) && !OfferClosedFunction(data.availabilityText) },
            { id: TAB_NAMES.KANBAN, label: 'Overview', condition: data?.matches.length > 0 && !userRoles.includes(USER)}, // && !OfferClosedFunction(data.availabilityText) 
        ] 
        return TABS;
    }

    function getTabCount() {
        var count = 0;
        getTabs().forEach((p) => {
            if(p.condition) {
                count++;
            }
        })
        return count;
    }

    return (
        <SignInPageTemplate>
            <Helmet>
                <title>
                    {user ? (newNotifications.length ? `(${newNotifications})` : '') : ''}{' '}
                    {data ? `${data.title} - Nudge Energy Talent Lab` : 'Contracts'}
                </title>
            </Helmet>
            {data ? (
                <>
                    <CompanyAdminModals toggle={toggle} showModal={showModal} roleUrl={roleUrl} />
                    {userRoles.includes('Admin') ? (
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Card className={style.mobileCompanyCard}>
                                    <Row>
                                        <Col xs={2} md={2}>
                                            <img
                                                className={style.mobileCompanyImage}
                                                src={data.company.logo}
                                                alt="company"
                                            />
                                        </Col>
                                        <Col xs={6} md={6}>
                                            Posted by:
                                            <h5 className={style.mobileCompanyTitle}>{data.company.name}</h5>
                                        </Col>
                                        <Col xs={4} md={4}>
                                            {userRoles ? (
                                                <LinkButton
                                                    color="success"
                                                    link={GetProfileLink()}
                                                    className={style.mobileCompanyButton}
                                                >
                                                    View Profile
                                                </LinkButton>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    ) : null}

                    <Row>
                        <Col xs={12} md={12} lg={10}>
                            <NudgeTabs
                                label="Job Tabs"
                                tabs={getTabs()}
                                selected={activeTab}
                                setTab={setActiveTab}
                            />
                        </Col>
                        { !disableEdit && userRoles.includes("Company") ? (
                            <Col xs={12} md={12} lg={2}>
                                {data.offerStatus !== 'Closed' ? (
                                    <div style={{ display: 'flex', columnGap: '6px', marginBottom: '12px' }}>
                                        <NudgeButtonGroup
                                            buttons={[
                                                { label: 'Edit', color: 'success', onClick: onClickEdit  },
                                                { label: 'Archive', color: 'danger', onClick: toggle },
                                            ]}
                                        />
                                    </div>
                                ) : (
                                    <ClickButton className={style.offerButton} color="danger" onClick={onReopenOffer}>
                                        Reopen
                                    </ClickButton>
                                )}
                            </Col>
                        ) : null}
                    </Row>
                    
                    {activeTab === TAB_NAMES.OFFER_DETAILS ? (
                        <>
                            <OfferInfo data={data} company mutate={mutate} />
                        </>
                    ) : activeTab === TAB_NAMES.CANDIDATES ? (
                        <>
                            {userRoles.includes('Company') ? (
                                data.weights ? (
                                    <CompanyMatching
                                        user={user}
                                        offer={data}
                                        help={data.requiresHelp}
                                        offerMatches={data.matches}
                                        setAlert={setAlert}
                                        mutate={mutate}
                                    />
                                ) : (
                                    <h5>
                                        Cannot use smart matching as there are no weights associated with this offer.
                                    </h5>
                                )
                            ) : (
                                <CandidatesToMatch offer={data} />
                            )}
                        </>
                    ) : activeTab === TAB_NAMES.KANBAN ? (
                        <>
                            <Row>
                                <Col md={12}>
                                    <KanbanCandidates roleUrl={roleUrl} offer={data} key="kanbancandidates" />
                                </Col>
                            </Row>
                        </>
                    ) : activeTab === TAB_NAMES.RECRUITERS ? (
                        <Row>
                            <Col lg={10}>
                                <RecruitersTab help={data.requiresHelp} referred={data.referredRecruiters} mutate={mutate}/>
                            </Col>
                        </Row>
                    ) : null}
                </>
            ) : null }



            {data ? null : isValidating ? (
                <Loader />
            ) : (
                <>
                    {' '}
                    <BackButton className={style.offerButton} show={true} />{' '}
                    <h5 className="text-center">
                        Offer couldn't be found. Either this contract isn't tied to your account, or there is another
                        problem.
                    </h5>{' '}
                </>
            )}
            <NudgeAlert text={alert} toggle={() => setAlert("")}/>
        </SignInPageTemplate>
    );
};

ContractOffer.propTypes = {};

export default withAuthProtectedRouter(ContractOffer, [COMPANY, ADMIN, RECRUITER]);
