import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import style from './style.module.scss';
import ClickButton from '../NudgeButton';

const CreateResourceButtons = ({ onSubmit, postText = 'Post', sending, disabled }) => {
    return (
        <>
            <Row className="mt-15">
                <Col xs={12} md={{ size: 4, offset: 4 }}>
                    <ClickButton
                        type="submit"
                        disabled={disabled}
                        color={'success'}
                        size="lg"
                        className={style.btnPost}
                        onClick={onSubmit}
                    >
                        {postText}
                    </ClickButton>
                </Col>
            </Row>
        </>
    );
};

CreateResourceButtons.propTypes = {
    onBack: PropTypes.func.isRequired,
    postText: PropTypes.string,
};

export default CreateResourceButtons;
