import React, { useState, useContext } from 'react';

import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { prepareAuthHeaders } from '../../../api/tools';
import ActionOrBackModal from '../../../components/modals/ActionOrBackModal';
import { API_PROFILE } from '../../../consts/urls';
import style from './style.module.scss';
import NudgeButton from '../../../components/buttons/NudgeButton';

const DeleteProfile = () => {
    const [isOpen, setIsOpen] = useState(false);
    const disableMessage = `The account will be disabled. After 24 months of inactivity, in line with GDPR, the account will be terminated, and all personal data will be disposed of. Are you sure you want to disable your profile?`;
    const { logoutUser } = useContext(AuthContext);
    const toggle = () => setIsOpen(!isOpen);
    const onClick = () => setIsOpen(true);
    const onDelete = async () => {
        const headers = await prepareAuthHeaders();
        await fetch(API_PROFILE, { headers, method: 'DELETE' });
        logoutUser();
    };

    return (
        <>
            <div style={{ display: 'flex'}}>
                <NudgeButton color="danger" size="lg" className={style.button} onClick={onClick}>
                    Disable My Profile
                </NudgeButton>
            </div>
            <ActionOrBackModal
                toggle={toggle}
                isOpen={isOpen}
                onGoBack={toggle}
                onClick={onDelete}
                header={disableMessage}
                buttonText="Disable My Profile"
            />
        </>
    );
};

export default DeleteProfile;
