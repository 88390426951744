import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Status from './Status';
import style from './style.module.scss';
import { Col, Row, Tooltip } from 'reactstrap';
import LinkButton from '../buttons/LinkButton';
import { ProcessSalary, ResolveSalary } from '../pages/GenericCompanyProfile/HelperComponents';
import { MATCH_STATUSES_DICTIONARY } from '../../consts/matchOfferStatuses';
import NudgeBoxes from '../NudgeBoxes';

const Offer = ({ offer, className = '', url }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    let rateString = 'TBD';
    rateString = ResolveSalary(offer.rangeExpected, offer.currencyType, offer.frequency, offer.workType);

    function resolveStatus(mStatus, oStatus) {
        if (mStatus == 'CompanyAccepted') return mStatus;

        switch (oStatus) {
            case 'Closed':
            case 'Expired':
                return oStatus;
            default:
                return mStatus;
        }
    }

    return (
        <div className={`${className}`}>
            <div className={style.header}>
                <div>
                    <Link to={`${url}/${offer.id}`}>
                        <div className={style.nameLink}>{offer.name}</div>
                    </Link>
                    <h4 className={style.subtitle}>{offer.companyName}</h4>
                    <NudgeBoxes
                        data={[
                            { label : offer.dates, danger: false },
                            { label: rateString, danger: false}
                        ]}
                    />
                </div>
            </div>

            <div className={style.description}>{offer.description}</div>

            <Row className={style.bottomRow}>
                <Col md={12}>
                    <h5 className={style.info}>
                        {MATCH_STATUSES_DICTIONARY[resolveStatus(offer.matchStatus, offer.offerStatus)]}
                    </h5>
                </Col>
            </Row>
        </div>
    );
};

Offer.propTypes = {
    offer: PropTypes.object.isRequired,
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
};

export default Offer;
