import React, { useContext, useState } from 'react';
import { Formik } from 'formik';

import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';
import { USER, COMPANY } from '../../consts/userRoles';

import KariaFormModal from './KariaFormModal';
import AberdeenFormModal from './AberdeenFormModal';
import KingsbridgeFormModal from './KingsbridgeFormModal';
import EmailForm from './AberdeenFormModal copy';

import { authPostData } from '../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../consts/urls';

import Items from './Items';
import { karmiaValues, karmiaSchema } from './KariaFormModal/validation';
import { aberdeenValues, aberdeenSchema } from './AberdeenFormModal/validation';
import { kingsbridgeValues, kingsbridgeSchema } from './KingsbridgeFormModal/validation';

import { emailValues, emailSchema } from './AberdeenFormModal copy/validation';

import SignInPageTemplate from '../../components/SignInPageTemplate';

const sendForm = async (v, name) => {
    const url = `${API_DASHBOARD_CONTRACTOR}/Services/${name}`;
    await authPostData(v, url);
};

export const SupportService = () => {
    const { user } = useContext(AuthContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [aberdeenOpen, setAberdeenOpen] = useState(false);
    const [kingsbridgeOpen, setKingsbridgeOpen] = useState(false);

    const toggleKaria = (e) => {
        e?.preventDefault();
        trackModal('Karmia Digital', modalOpen ? 'close' : 'open');
        setModalOpen(!modalOpen);
    };
    const toggleAberdeen = (e) => {
        e?.preventDefault();
        trackModal('Aberdeen Accountants', aberdeenOpen ? 'close' : 'open');
        setAberdeenOpen(!aberdeenOpen);
    };
    const toggleKingsbridge = (e) => {
        e?.preventDefault();
        trackModal('Kingsbridge', kingsbridgeOpen ? 'close' : 'open');
        setKingsbridgeOpen(!kingsbridgeOpen);
    };

    const clickHonestMobile = (e) => {
        trackModal('Honest Mobile', 'click');
    };

    const toggle = {
        karmia: toggleKaria,
        aberdeen: toggleAberdeen,
        kingsbridge: toggleKingsbridge,
        honestMobile: clickHonestMobile,
    };

    // Log modal and click events to Google Analytics
    const trackModal = (name, state) => {
        if(window?.ga) {
            window?.ga('send', 'event', {
                eventCategory: 'Community Service',
                eventAction: state,
                eventLabel: name,
            });
        }
    };

    var role;
    if (user) {
        if (user.job) role = 'Contractor';
        else if (user.offers) role = 'Company';
        else role = 'Admin';
    }

    const propsKarmia = {
        initialValues: karmiaValues,
        validationSchema: karmiaSchema,
        onSubmit: async (values) => {
            sendForm(values, 'KarmiaDigital');
            values.successMethod();
        },
    };

    const propsAberdeen = {
        initialValues: aberdeenValues,
        validationSchema: aberdeenSchema,
        onSubmit: async (values) => {
            values.price = values.tally(values);
            sendForm(values, 'Aberdeen');
            values.successMethod();
        },
    };

    const propsKingsbridge = {
        initialValues: kingsbridgeValues,
        validationSchema: kingsbridgeSchema,
        onSubmit: async (values) => {
            sendForm(values, 'Kingsbridge');
            values.successMethod();
        },
    };

    return (
        <SignInPageTemplate>
            <Formik {...propsKarmia}>
                {(formik) => <KariaFormModal isOpen={modalOpen} toggle={toggle} formik={formik} />}
            </Formik>
            <Formik {...propsAberdeen}>
                {(formik) => <AberdeenFormModal isOpen={aberdeenOpen} toggle={toggle} formik={formik} />}
            </Formik>
            <Formik {...propsKingsbridge}>
                {(formik) => <KingsbridgeFormModal isOpen={kingsbridgeOpen} toggle={toggle} formik={formik} />}
            </Formik>
            <Items toggle={toggle} />
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(SupportService, [USER, COMPANY]);
