import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const renderPaidCell = ({ cell }) => (
    <div className="text-left">
        {cell.row.original.isPaid ? (
            <FontAwesomeIcon icon={['far', 'check']} className="text-green" />
        ) : (
            <FontAwesomeIcon icon={['far', 'times']} className="text-danger" />
        )}
    </div>
);
