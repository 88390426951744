import React from 'react';
import { Formik } from 'formik';

import EmailForm from './EmailForm';

import { validationSchema, FIELDS, initialValues } from './EmailForm/validation';

const SendEmailNotification = () => {
    function onSubmit() {}

    const props = {
        initialValues: { ...initialValues },
        validationSchema,
        onSubmit: onSubmit,
    };

    return (
        <>
            <Formik {...props}>{(formik) => <EmailForm formik={formik} />}</Formik>
        </>
    );
};

export default SendEmailNotification;
