import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from './style.module.scss';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../components/NudgeModal';

const DeleteModal = ({ toggle, isOpen, onGoBack, onClick, buttonText, backText, header, text, color }) => {
    return (
        <NudgeModal headerText={header} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                <h5>
                    This operation is
                    <b> permanent </b>
                    and
                    <b> cannot be reversed later</b>.
                    <br />
                    <br />
                    Are you sure about this?
                </h5>
                <br />

                <div style={{ display: 'flex', columnGap: '6px' }}>
                    <ClickButton size="lg" color="danger" onClick={onClick} className={style.button}>
                        <b>{buttonText}</b>
                    </ClickButton>
                </div>
            </ModalBody>
        </NudgeModal>
    );
};

DeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
