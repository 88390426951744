import * as Yup from 'yup';

import { FIELDS as FIELDS_CERTIFICATION } from '../../../forms/CertificatesForm/validation';
import { FIELDS as FIELDS_QUALIFICATION } from '../../../forms/QualificationsForm/validation';

export const FIELDS = {
    ...FIELDS_CERTIFICATION,
    ...FIELDS_QUALIFICATION,
};

export const validationSchema = Yup.object({
    [FIELDS.STUDY_FIELD]: Yup.string().required('This field is required.'),
    [FIELDS.QUALIFICATION]: Yup.string().required('One of these fields is required'),
});

export const initialValues = {
    [FIELDS.STUDY_FIELD]: '',
    [FIELDS.QUALIFICATION]: '',
};
