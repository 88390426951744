import React from 'react';
import NudgeButton from '../../buttons/NudgeButton';

const FormlessFileInput = ({ id, uploadResume, contractor, sending }) => {

    const [file, setFile] = React.useState(null);
    const [error, setError] = React.useState('');

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    async function uploadFile(event) {
        const file = event.target.files[0];

        if (file) {
            const base64 = await convertBase64(file);
            setFile(file);

            var extension = base64.split(':')[1].split(';')[0].split('/')[1];

            if (extension == 'pdf') {
                setError('');
            } else {
                var doc = document.getElementById('user_details');
                doc.value = null;

                setError('Invalid file type. Must be a pdf.');
            }
        }
    };

    function clickFile() {
        var doc = document.getElementById(`file-${id}`);

        if(doc) {
            doc.click();
        }
    }

    return (
        <>
            <input type="file" style={{ display: 'none'}} id={`file-${id}`} onChange={(e) => uploadFile(e)}/>
            <div style={{ display: 'flex', columnGap: '6px' }}>
                <NudgeButton disabled={sending} onClick={clickFile} color="success">{ file ? 'Change File' : 'Upload File'}</NudgeButton>
                { file ? <NudgeButton disabled={sending} onClick={() => uploadResume(file, contractor.id)} color="success">Save Résumé</NudgeButton> : null }
            </div>
                { file ? <p>{file.name}</p> : null }
        </>
    )
}

export default FormlessFileInput;