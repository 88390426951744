import React from 'react';

import { Modal, ModalBody, ModalHeader, Button, Row, Col } from 'reactstrap';
import ActionOrBackModal from '../../../modals/ActionOrBackModal';
import style from './style.module.scss';

const LogOutModal = ({ toggle, isOpen, logout }) => {
    return (
        <ActionOrBackModal
            isOpen={isOpen}
            toggle={toggle}
            onGoBack={toggle}
            onClick={logout}
            header={'Log out?'}
            text={'Are you sure you want to log out?'}
            buttonText="Yes"
            backText="Cancel"
        />
    );
};

export default LogOutModal;
