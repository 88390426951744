import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import Status from './Status';
import useSWR from 'swr';

import { authFetcher } from '../../../api/tools';
import Loader from '../../../components/Loader';
import SearchBox from '../../../components/SearchBox';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { OfferCards } from './helpers';

import Select from 'react-select';
import { customStyles } from '../../../components/SelectField';

import style from './style.module.scss';
import NudgeTooltip from '../../../components/NudgeTooltip';

const historyOptions = [
    {
        label: 'Awarded',
        value: 'awarded',
    },
    {
        label: 'Pending',
        value: 'pending',
    },
    {
        label: 'Dismissed',
        value: 'dismissed',
    },
];

export const NudgeHistory = () => {
    const { data, mutate } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Offers`, authFetcher, { refreshInterval: 0 });
    const [Tab, setTab] = React.useState(historyOptions[0]);
    const [search, setSearch] = React.useState('');

    const [awardedTip, setAwardedTip] = React.useState(false);
    const [pendingTip, setPendingTip] = React.useState(false);
    const [dismissedTip, setDismissedTip] = React.useState(false);

    const toggleAwarded = () => setAwardedTip(!awardedTip);
    const togglePending = () => setPendingTip(!pendingTip);
    const toggleDismissed = () => setDismissedTip(!dismissedTip);

    useEffect(() => {
        mutate();
    }, [mutate]);

    return (
        <SignInPageTemplate>
            {data ? (
                <>
                    <h1 className={style.title}>My Nudge History</h1>
                    <div className={style.searchBoxContainer}>
                        <SearchBox value={search} setValue={setSearch} />
                    </div>

                    <Tooltip
                        placement="bottom"
                        isOpen={awardedTip}
                        target={'historyTooltip-awarded'}
                        toggle={toggleAwarded}
                    >
                        {'These are contracts you have been accepted for.'}
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        isOpen={pendingTip}
                        target={'historyTooltip-pending'}
                        toggle={togglePending}
                    >
                        {'These are contracts currently under consideration.'}
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        isOpen={dismissedTip}
                        target={'historyTooltip-dismissed'}
                        toggle={toggleDismissed}
                    >
                        {'These are contracts you have either been unsuccessful for, or have dismissed yourself.'}
                    </Tooltip>

                    <div className={style.desktopOnly}>
                        <Row className={style.laneContainer}>
                            <Col md={4}>
                                <NudgeTooltip id="awarded">

                                </NudgeTooltip>
                            </Col>
                            <Col md={4}>
                                <h5 className={style.subtitle}>
                                    <NudgeTooltip id="pending">
                                        
                                    </NudgeTooltip>
                                </h5>
                            </Col>
                            <Col md={4}>
                                <h5 className={style.subtitle}>
                                    <NudgeTooltip id="dismissed">
                                        
                                    </NudgeTooltip>
                                </h5>
                            </Col>
                        </Row>
                        <Row className={style.laneContainer}>
                            <Col md={4} className={style.laneOne}>
                                <OfferCards search={search} data={data.awarded} label={'Awarded'} />
                            </Col>
                            <Col md={4} className={style.laneTwo}>
                                <OfferCards search={search} data={data.pending} label={'Pending'} />
                            </Col>
                            <Col md={4} className={style.laneThree}>
                                <OfferCards search={search} data={data.dismissed} label={'Dismissed'} />
                            </Col>
                        </Row>
                    </div>

                    <div className={style.mobileOnly}>
                        <Select
                            styles={customStyles()}
                            options={historyOptions}
                            defaultValue={historyOptions[0]}
                            onChange={(option) => {
                                setTab(option);
                            }}
                            value={Tab}
                            isSearchable={false}
                        />
                        {Tab ? (
                            <Row className={style.laneContainer}>
                                <Col xs={12} md={12} className={style.laneOne}>
                                    <OfferCards search={search} data={data[Tab.value]} label={Tab.label} tab={Tab} />
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

NudgeHistory.propTypes = {
    showEdit: PropTypes.bool,
};

export default withAuthProtectedRouter(NudgeHistory, [USER]);
