import React from 'react';
import { COMPANY, RECRUITER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import CompanyAdminCandidatePage from '../../../components/OpportunityPages/CompanyAdmin';
import { API_DASHBOARD_COMPANY, API_DASHBOARD_RECRUITER } from '../../../consts/urls';

export const CompanyContractOffer = () => {
    return <CompanyAdminCandidatePage roleUrl={API_DASHBOARD_RECRUITER} disableEdit />;
};

export default withAuthProtectedRouter(CompanyContractOffer, [RECRUITER]);
