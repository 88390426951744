import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import { deleteData, prepareAuthHeaders } from '../../../../api/tools';
import { useEditButton } from '../../../../components/buttons/EditButton';
import { testNewCertificates } from '../../../../pages/SignUp/signUpHelper';
import EditableCertificatesAndQualifications from './NewEditableCerts';
import NonEditableCertificatesAndQualifications from './NonEditableCertificatesAndQualifications';
import { FIELDS, initialValues, validationSchema } from './validation';
import style from './style.module.scss';
import Loader from '../../../Loader';
import { updateCasebase } from '../../../ContractorInformation';
import NudgeCard from '../../../NudgeCard';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeTooltip from '../../../NudgeTooltip';

const CertificatesAndQualifications = ({
    user,
    certificates,
    qualification,
    showEdit,
    refresh,
    url,
    changeKey,
    theme,
    canCancel = true,
    reset,
}) => {
    const { isOpen, setIsOpen, onClickEdit } = useEditButton();
    const [sending, setIsSending] = useState(false);

    React.useEffect(() => {
        setIsOpen(reset ? true : false);
    }, [changeKey]);

    const [certsToRemove, setCertsToRemove] = useState([]);

    const onClose = () => {
        setIsOpen(false);
        setCertsToRemove([]);
    };

    const toggle = () => setIsOpen(!isOpen);

    const onRemoveExistingCertificate = (el) => {
        el.edit = false;
        setCertsToRemove([el, ...certsToRemove])
    };

    function onEditExistingCertificate(el) {
        el.edit = true;
        setCertsToRemove([...certsToRemove, el]);
    }

    function resetCertsToRemove(el) {
        setCertsToRemove(certsToRemove.filter((item) => item.id != el.id))
    }

    const displayCerts = certificates;

    const props = {
        initialValues: {
            ...initialValues,
            certifications: [],
            qualification: qualification || initialValues[FIELDS.QUALIFICATION],
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsSending(true);

            await updateCertificates(values);
            await deleteCertificates();
            await updateCasebase(user);

            await refresh();
            if (reset) reset();

            setIsSending(false);
            setIsOpen(false);
        },
    };

    const updateCertificates = async (values) => {
        const certsToUpdate = values.certifications.filter((cert) => typeof cert == 'object');

        if (certsToUpdate) {
            const headers = await prepareAuthHeaders();
            await testNewCertificates(certsToUpdate, `${url}/Certificate`);
        }
    };

    const deleteCertificates = async () => {
        if (!certsToRemove) {
            return;
        }

        const idsToRemove = certsToRemove.map((cert) => cert.id);
        if (idsToRemove) {
            await deleteData(idsToRemove, `${url}/Certificate`, {});
        }
    };

    return (
        <>
            <div className={style.profileHeader}>
            <div className={style.profileHeaderTitle}>
                    <NudgeTooltip id={`certs-header`} label={<b>{"Certifications"}</b>}>
                        <div class="text-center">
                            Tell us about any certifications you've been awarded.
                        </div>
                    </NudgeTooltip>
                </div>
                <div class="w-100">
                    {!isOpen && showEdit ? (
                        <ClickButton color="success" className={style.profileHeaderButton} onClick={toggle}>
                            Edit
                        </ClickButton>
                    ) : null}
                </div>
            </div>
            <Card id={`card-${theme}`} className="mt-10 mb-10">
                <CardBody>
                    {isOpen ? (
                        <Formik {...props}>
                            {(formik) =>
                                !sending ? (
                                    <EditableCertificatesAndQualifications
                                        formik={formik}
                                        onClose={canCancel ? onClose : null}
                                        onRemove={onRemoveExistingCertificate}
                                        onEdit={onEditExistingCertificate}
                                        certificates={displayCerts}
                                        removeCerts={certsToRemove}
                                        reset={resetCertsToRemove}
                                        theme={theme}
                                    />
                                ) : (
                                    <Loader />
                                )
                            }
                        </Formik>
                    ) : displayCerts.length ? (
                        <NonEditableCertificatesAndQualifications
                            certificates={displayCerts}
                            showEdit={showEdit}
                            onEdit={onClickEdit}
                            qualification={qualification}
                            theme={theme}
                        />
                    ) : (
                        <p style={{ fontStyle: 'italic' }}>No certificates or qualifications have been added yet.</p>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

CertificatesAndQualifications.propTypes = {
    certificates: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string.isRequired })).isRequired,
    qualification: PropTypes.string.isRequired,
    showEdit: PropTypes.bool,
    refresh: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export default CertificatesAndQualifications;
