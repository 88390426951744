import React from 'react';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { ADMIN } from '../../../consts/userRoles';
import SignInPageTemplate from '../../../components/SignInPageTemplate';

import useSWR from 'swr';
import { API_DASHBOARD_ADMIN, FLASK_API, URLS } from '../../../consts/urls';
import { NEVER_REFRESH, NO_REFRESH_INTERVAL, authFetcher } from '../../../api/tools';
import { ContainerCard, FullStatCardScaling, GetPageTheme, count_by_key, sort_by_key, sort_by_key_length } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import Loader from '../../../components/Loader';
import { Card, Col, Input, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Helmet } from 'react-helmet-async';
import { MATCH_STATUSES_DICTIONARY } from '../../../consts/matchOfferStatuses';

import style from './style.module.scss';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import ProfileCard from './ProfileCard';
import useModal from '../../../hooks/useModal';
import NudgeModal from '../../../components/NudgeModal';
import NudgeButton from '../../../components/buttons/NudgeButton';

import moment from 'moment';

const AdminNewDashboard = () => {
    const { user, mutate } = React.useContext(AuthContext);

    const [request_buffer, set_request_buffer] = React.useState(90);
    const [request_days, set_request_days] = React.useState(request_buffer);

    const { data: data_days } = useSWR(`${API_DASHBOARD_ADMIN}/Dashboard/Info/${request_days}`, authFetcher, NO_REFRESH_INTERVAL);

    const theme = GetPageTheme();

    const [disciplines, setDisciplines] = React.useState();
    const [matches, setMatches] = React.useState();
    React.useEffect(() => {
        if(data_days) {
            var disc = Object.keys(data_days.disciplines)
                .filter((key) => key != "" && key != " " && !key.includes("test"))
                .map((item) => ({ title: item, contractors: data_days.disciplines[item]}));
            disc = sort_by_key_length(disc, 'contractors', 1);

            var mat = Object.keys(data_days.matchesByStatus)
                .map((item) => ({ title: MATCH_STATUSES_DICTIONARY[item], amount: data_days.matchesByStatus[item]}))
                .filter(p => p.title != "Legacy");
            //mat = sort_by_key(mat, 'amount', 1);

            setMatches(mat); setDisciplines(disc);
        }
    }, [data_days])

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(parseInt(request_buffer) && request_buffer != request_days) set_request_days(request_buffer);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [request_buffer])

    const [ selectedDiscipline, setSelectedDiscipline ] = React.useState();
    const toggle = () => setSelectedDiscipline()
    const disciplineSplit = "/:/"

    return  (
        <SignInPageTemplate>
            
            <NudgeModal headerText={selectedDiscipline?.title || "Title"} id={`modal-${theme}`} isOpen={!!selectedDiscipline} toggle={toggle}>
                    {selectedDiscipline ? <ModalBody>
                        { selectedDiscipline?.contractors.map((item) => <div style={{ display: 'flex', columnGap: '12px', marginBottom: '6px' }}>
                            <h5>{item.split(disciplineSplit)[1]} - {item.split(disciplineSplit)[0]}</h5>
                            <NudgeButton onClick={() => window.open(`/admin/contractors/${item.split(disciplineSplit)[0]}`, '_blank', 'noreferrer')}>View Profile</NudgeButton>
                        </div> )}
                    </ModalBody> : null }
                <ModalFooter></ModalFooter>
            </NudgeModal>

            <Helmet><title>Dashboard | Nudge Energy Talent Lab</title></Helmet>
                <Row>
                <Col md={12} lg={3}>
                    <ProfileCard user={user} mutate={mutate}/>
                </Col>
                <Col md={12} lg={9}>
                    { data_days ? <>
                        <Row>
                            <Col sm={6} md={6} lg={6}>
                                <FullStatCardScaling
                                    header={<div className={style.input}><h4>Signups</h4><Input value={request_buffer} onChange={(e) => set_request_buffer(e.target.value)}/><h5>Days</h5></div>}
                                    data={[
                                        { label: 'Talent', data: data_days.contractors.signUpsDays },
                                        { label: 'Companies', data: data_days.companies.signUpsDays },
                                        { label: 'Recruiters', data: data_days.recruiters.signUpsDays },
                                    ]}
                                    minHeight={140}
                                />
                            </Col>
                            <Col sm={6} md={6} lg={6}>
                                <FullStatCardScaling
                                    header={<div style={{ minHeight: '38px' }}>{`Total Sign-Ups`}</div>}
                                    data={[
                                        { label: 'Talent', data: data_days.contractors.signUpsTotal },
                                        { label: 'Companies', data: data_days.companies.signUpsTotal },
                                        { label: 'Recruiters', data: data_days.recruiters.signUpsTotal },
                                    ]}
                                    minHeight={140}
                                />
                            </Col>
                            <Col sm={6} md={6}>
                                <FullStatCardScaling
                                    header={<>Jobs - <Link to={`${URLS.ADMIN_OFFERS}`}>Go To</Link></>}
                                    data={[
                                        { label: 'Live', data: data_days.upcomingOffers.liveOffers },
                                        { label: 'Scheduled', data: data_days.upcomingOffers.scheduledOffers },
                                        { label: 'Archived', data: data_days.upcomingOffers.closedOffers },
                                        { label: 'Expired', data: data_days.upcomingOffers.expiredOffers },
                                    ]}
                                />

                                <FullStatCardScaling
                                    header={<>{"Interest Registered"} - <Link to={`${URLS.ADMIN_APPROVAL}`}>Go To</Link></>}
                                    data={[
                                        { label: "", data: data_days.approvalMatches },
                                    ]}
                                />
                                
                                <FullStatCardScaling
                                    header={`Total Recruiter Stats`}
                                    data={[
                                        { label: 'Referral Requests', data: data_days.referrals.requests },
                                        { label: 'Talent Recruited', data: data_days.referrals.talentRecruited },
                                        { label: 'Talent Requested', data: data_days.referrals.talentRequested },
                                    ]}
                                />
                            </Col>
                            <Col sm={6} md={6}>
                                <ContainerCard id={theme == 'light' ? `card-light` : `card-sub-dark`} className={style.disciplineCard}>
                                    <h5>Talent by Discipline - {disciplines?.length}</h5>
                                    <div className={theme == 'dark' ? style.dividerDark : style.divider}/>
                                    <div id={theme == 'light' ? `card-sub-light` : `card-dark`} className={style.disciplinesContainer}>
                                        {disciplines ? disciplines.map((item) => <>
                                            <Row className={theme == 'dark' ? style.disciplineDark : style.discipline}>
                                                <Col xs={9} md={9}>
                                                    <h5 className={style.disciplineLink} onClick={() => setSelectedDiscipline(item)}>{item.title}</h5>
                                                </Col>
                                                <Col xs={3} md={3}>
                                                    <h5 style={{ textAlign: 'right' }}>{item.contractors.length}</h5>
                                                </Col>
                                            </Row>
                                        </>) : null }
                                    </div>
                                </ContainerCard>
                            </Col>
                            <Col sm={12} md={12}>
                                <ContainerCard id={theme == 'light' ? `card-light` : `card-sub-dark`} className={style.disciplineCard}>
                                    <h5>Matches by Status</h5>
                                    <div className={theme == 'dark' ? style.dividerDark : style.divider}/>
                                    <div id={theme == 'light' ? `card-sub-light` : `card-dark`} className={style.disciplinesContainer}>
                                        {matches ? matches.map((item) => <>
                                            <Row className={theme == 'dark' ? style.disciplineDark : style.discipline}>
                                                <Col xs={9} md={9}>
                                                    <h5>{item.title}</h5>
                                                </Col>
                                                <Col xs={3} md={3}>
                                                    <h5 style={{ textAlign: 'right' }}>{item.amount}</h5>
                                                </Col>
                                            </Row>
                                        </>) : null }
                                    </div>
                                </ContainerCard>
                            </Col>
                        </Row>
                    </> : <Loader/> } 
                </Col>
            </Row>          
        </SignInPageTemplate>
    )
}

export default withAuthProtectedRouter(AdminNewDashboard, [ADMIN]);