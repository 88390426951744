import {
    API_DASHBOARD_ADMIN,
    API_DASHBOARD_COMPANY,
    API_DASHBOARD_CONTRACTOR,
    API_DASHBOARD_RECRUITER,
    API_PROFILE,
    FLASK_API,
} from '../../../consts/urls';
import { ADMIN, COMPANY, RECRUITER } from '../../../consts/userRoles';

export const getContractorDashboardUrl = (roles, id = '') => {
    if (roles.includes(ADMIN)) {
        return `${API_DASHBOARD_ADMIN}/Contractors/${id}`;
    } else if (roles.includes(COMPANY)) {
        return `${API_DASHBOARD_COMPANY}/Contractors/${id}`;
    } else if (roles.includes(RECRUITER)) {
        return `${API_DASHBOARD_RECRUITER}/Contractors/${id}`;
    } else {
        return API_DASHBOARD_CONTRACTOR;
    }
};

export const getContractorProfiledUrl = (roles, id = '') => {
    if (roles.includes(ADMIN)) {
        return `${API_DASHBOARD_ADMIN}/Contractors/${id}`;
    } else if (roles.includes(COMPANY)) {
        return `${API_DASHBOARD_COMPANY}/Contractors/${id}`;
    } else {
        return API_PROFILE;
    }
};

export const getContractorExtUrl = (roles, id = '') => {
    if (roles.includes(ADMIN)) {
        return `${FLASK_API}/ext/${id}`;
    } else {
        return `${FLASK_API}/ext`;
    }
};
