import React from 'react';

import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

import style from './style.module.scss';
import { articleArray } from './dictionary';
import { GetPageTheme } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

const Description =
    'This page has been created to provide relevant services to the community, from ' +
    'accountancy support to insurances everything that maybe needed to support you in your ' +
    'career journey. We have used the power of the many to agree preferential rates, and have ' +
    'vetted the services so only those that are recommended and have the communities best ' +
    'interests at heart have been requested to join.';

const Items = ({ toggle }) => {
    
    const theme = GetPageTheme()
    return (
        <>
            <div style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                <Card className={style.container}>
                    <h1>
                        <b>Community Support Services</b> - Welcome to Nudge Nation Community Page!
                    </h1>

                    <h5>{Description}</h5>
                    <br />
                    <h5 className="text-center">Go Nudge Nation!</h5>
                </Card>

                <div className={style.jumbotron}>
                    {articleArray.map((label) => (
                        <Card id={`card-sub-${theme}`} className={style.card}>
                            {label.headline ? <div className={style.headline}>{label.headline}</div> : null}
                            <CardBody>
                                {label.link ? (
                                    <a
                                        href={label.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={toggle[label.action]}
                                    >
                                        <CardImg className={style.image} src={label.image}></CardImg>
                                    </a>
                                ) : (
                                    <a href="" onClick={toggle[label.action]}>
                                        <CardImg className={style.image} src={label.image}></CardImg>
                                    </a>
                                )}

                                {label.link ? (
                                    <CardTitle tag="h5">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={label.link}
                                            onClick={toggle[label.action]}
                                        >
                                            {label.title}
                                        </a>
                                    </CardTitle>
                                ) : label.action ? (
                                    <CardTitle tag="h5">
                                        <a href="" onClick={toggle[label.action]}>
                                            {label.title}
                                        </a>
                                    </CardTitle>
                                ) : (
                                    <CardTitle>
                                        <h5>{label.title}</h5>
                                    </CardTitle>
                                )}

                                <CardSubtitle tag="h6" className="mb-2">
                                    {label.subtitle}
                                </CardSubtitle>
                                <CardText>{label.body}</CardText>
                                <CardText>{label.body2}</CardText>
                                <CardText>{label.body3}</CardText>
                            </CardBody>
                        </Card>
                    ))}
                </div>

                <div className={style.email}>
                    <h5>
                        If you would like to offer relevant services on this page, contact Darren at{' '}
                        <a href="mailto:darren@nudgeexchange.com">darren@nudgeexchange.com</a>.
                    </h5>
                </div>
            </div>
        </>
    )
};

export default Items;

// <Video />
