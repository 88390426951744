import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppInput from '../../../../../components/AppInput';
import SaveButton from '../../../../../components/buttons/SaveButton';
import { FIELDS, WORD_LIMIT } from './validation';

import { DEFAULT_PICTURE } from '../../../../../consts/urls';

import style from '../style.module.scss';
import UploadModal from '../../../../ContractorInformation/ContractorInformationEdit/UploadModal';
import ColourInput from '../../../../ColourInput';
import { GetPageTheme, calculateLightness, generateMonoLinearGradient } from '../../../GenericCompanyProfile/HelperComponents';
import CheckboxField from '../../../../CheckboxField';
import { Field } from 'formik';
import NudgeButton from '../../../../buttons/NudgeButton';
import VideoBrandingEdit from '../VideoBrandingEdit';
import NudgeTooltip from '../../../../NudgeTooltip';
import useMobile from '../../../../../hooks/useMobile';

const emptyText = () => {
    var inputVal = document.getElementById(FIELDS.BIO);
    if (inputVal?.value === ' ') inputVal.value = '';
};

const CompanyInformationEdit = ({ user, extraData, refresh, formik, onClose, colour, setColour }) => {
    const theme = GetPageTheme();
    const { isMobileWide } = useMobile();

    const atWordLimit = formik.values.bio.length > WORD_LIMIT;
    if (atWordLimit) {
        formik.setFieldValue(FIELDS.BIO, formik.values[FIELDS.BIO].substring(0, WORD_LIMIT));
    }

    const [canDelete, setCanDelete] = React.useState(false);
    React.useEffect(() => {
        if(formik.values[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            setCanDelete(true);
        }
    }, [])

    const [photoModalOpen, setPhotoModalOpen] = React.useState(false);
    const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

    const [photo, setPhoto] = React.useState(formik.values[FIELDS.PHOTO]);

    React.useEffect(() => {
        setPhoto(getPhotoField(formik.values[FIELDS.PHOTO]));
        
        formik.setFieldValue(FIELDS.DELETE_PHOTO, false);
    }, [formik.values[FIELDS.PHOTO]]);

    function setPhotoField(photo) { 
        if (photo) {
            const objectURL = photo;
            formik.setFieldValue(FIELDS.PHOTO, objectURL);
            return true;
        } else {
            return false;
        }
    }

    function getPhotoField(photo) {
        if (!photo) return DEFAULT_PICTURE;

        if (typeof photo == 'object') {
            return URL.createObjectURL(photo);
        } else {
            return photo;
        }
    }

    function getProfileIcon(v) {
        if(v[FIELDS.DELETE_PHOTO]) {
            return 'ban'
        }

        if(canDelete || v[FIELDS.PHOTO] != DEFAULT_PICTURE) {
            return 'pencil'
        } else {
            return 'plus-circle'
        }
    }

    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <div style={{ background: generateMonoLinearGradient(`${formik.values[FIELDS.BACK_COLOUR]}` || '#407962'), borderBottom: `2px solid ${`${formik.values[FIELDS.BACK_COLOUR]}` || '#407962'}`}} className={style.banner}/>
            
            { isMobileWide ? <div className={style.cancelButton}>
                <NudgeButton onClick={onClose} color="danger">Cancel</NudgeButton>
            </div> : null }
            <div className={style.saveButton}>
                <SaveButton formik={formik} onClose={!isMobileWide ? onClose : null }/>
            </div>

            <FormGroup className="mb-20">
                <div className={isMobileWide ? "text-center" : "text-left"}>
                    <div className={style.avatar} style={{ borderColor: `${colour || '#407962'}` }}>
                        <div
                            className={style.photoUpload}
                            style={{ backgroundImage: `url(${photo})`, objectFit: 'contain'  }}
                            onClick={togglePhotoModal}
                        >
                            <h5>
                                <FontAwesomeIcon icon={['fas', getProfileIcon(formik.values)]} />
                            </h5>
                        </div>

                        <UploadModal
                            isOpen={photoModalOpen}
                            toggle={togglePhotoModal}
                            avatar={``}
                            setPhoto={setPhotoField}
                        />
                        
                        { canDelete ? <div style={{ display: 'flex', fontSize: '14px', columnGap: '6px', justifyContent: 'center', marginTop: '12px'}}>
                            <Field component={CheckboxField} formik={formik} name={FIELDS.DELETE_PHOTO} label={"Delete Photo?"} bool labelOption={2} />
                        </div> : null }
                    </div>
                </div>
                <br/>

                { formik.values[FIELDS.DELETE_PHOTO] ? "Press 'Save' to delete profile picture." : null }
            </FormGroup>

            <FormGroup className="mb-10">
                <Label for={FIELDS.NAME}>Company Name</Label>
                <AppInput id={FIELDS.NAME} formik={formik} label="Name" />
            </FormGroup>

            <FormGroup className="mb-20">
                <Row style={{ rowGap: '6px' }}>
                    <Col xs={12} md={6} lg={12}>
                        <Label for={FIELDS.BACK_COLOUR}>Background Colour</Label>
                        <ColourInput id={FIELDS.BACK_COLOUR} formik={formik} extraSet={setColour} />
                    </Col>
                    <Col xs={12} md={6} lg={12}>
                        { user.permissions.find((item) => item.permissionType == "willo_interview" && !!item.enabled) ?
                            <>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <NudgeTooltip id={`video-interview`} label={`Video Interview Branding`} style={{ textAlign: 'center', marginBottom: '7.5px' }}>
                                        You may, when using the video interview feature, use your own company's branding. This is what the interviewee will
                                        see throughout the process.
                                    </NudgeTooltip>
                                </div>
                                <VideoBrandingEdit user={user} extraData={extraData} />
                            </> 
                        : null }
                    </Col>
                </Row>
                
            </FormGroup>


            <FormGroup className="mb-20" onClick={emptyText}>
                <Label for={FIELDS.BIO}>User Bio</Label>
                <AppInput id={FIELDS.BIO} formik={formik} className={style.bioInput} label="Bio" type="textarea" charLimit={WORD_LIMIT}/>
            </FormGroup>
 
            <FormGroup className="mb-20" onClick={emptyText}>
                <Label for={FIELDS.VISION}>Vision Statement</Label>
                <AppInput
                    className={style.visionInput}
                    id={FIELDS.VISION}
                    type="textarea"
                    formik={formik}
                    label="Vision Statement"
                />
            </FormGroup>

            <FormGroup className="mb-20">
                <Label for={FIELDS.VIDEO_LINK}>Youtube Video Link</Label>
                <AppInput id={FIELDS.VIDEO_LINK} formik={formik} label="Company Video" />
            </FormGroup>
        </Form>
    );
};

CompanyInformationEdit.propTypes = {
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CompanyInformationEdit;
