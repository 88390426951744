import React from 'react';

import { Col, Row, Card } from 'reactstrap';
import { currencyLookup } from '../../../pages/company/NewOfferPage/OfferForm/options';

import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';
import style from './style.module.scss';
import Loader from '../../Loader';

export const CERTIFICATE_EXPIRY_THRESHOLD = 3;

export function GetPageTheme() {
    const { theme } = React.useContext(ThemeContext);
    return theme;
}

//shortened substring
export function sub(string, i1, i2) {
    return string.substring(i1, i2);
}

export function handleSearch(search, target) {
    if(!search || !target) return false;

    return search.toUpperCase().includes(target.toUpperCase());
}

//sorts array by key, e.g name, salary, etc
export function sort_by_key(array, key, ascending) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];

        if(!ascending) { 
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
            return ((y < x) ? -1 : ((y > x) ? 1 : 0));
        }
    });
}

//sorts array by key, e.g name, salary, etc
export function sort_by_nested_keys(array, keys, ascending) {
    return array.sort(function (a, b) {
        var searchObjA = a;
        var searchObjB = b;

        keys.forEach((key, index) => {
            searchObjA = searchObjA[key];
            searchObjB = searchObjB[key];
        })

        var x = searchObjA; 
        var y = searchObjB;

        if(!ascending) { 
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
            return ((y < x) ? -1 : ((y > x) ? 1 : 0));
        }
    });
}

//sorts array by the length of a key
export function sort_by_key_length(array, key, ascending) {
    return array.sort(function (a, b) {
        var x = a[key].length;
        var y = b[key].length;
        
        if(!ascending) { 
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
            return ((y < x) ? -1 : ((y > x) ? 1 : 0));
        }
    });
}

export function count_by_key(arr, key) {
    var obj = {};

    arr.forEach((item, index) => {
        if(obj[item[key]]) {
            obj[item[key]]++;
        } else {
            obj[item[key]] = 1
        }
    })

    return Object.keys(obj).map((key) => ({ title: key, amount: obj[key]}))
}

//capitalises the first letter of a string
export function capitaliseFirstLetter(string, doAll = false) {
    let concat = '';
    const words = string.split(' ');

    if (doAll) {
        words.forEach((w) => {
            concat += w.charAt(0).toUpperCase() + w.slice(1) + ' ';
        });
    } else {
        concat = string.charAt(0).toUpperCase() + string.slice(1);
    }

    return concat.trim();
}

export function ResolveDaysRemaining(offer) {
    let left = offer.daysLeft;
    let until = offer.daysUntil;

    var daysLabel = '';
    var danger = false;

    var object = { label: daysLabel, danger: danger, daysLeft: left};

    if(left == 1) {
        object.label = `Expires today`
        object.danger = true;
        return object;
    }

    if (left == -9999) {
        if (offer.offerStatus == 'Closed') {
            object.label = "Closed";
            object.danger = true;
            return object;
        } else {
            if (-until == 0) {
                object.label = `${-until + 1} day until published`
                return object;
            } else {
                object.label = `${-until + 1} days until published`
                return object;
            }
        }
    } else {
        if (offer.offerStatus == 'Closed') {
            object.label = "Closed";
            object.danger = true;
            return object;
        } else if (left <= 0) {
            object.label = "Expired";
            object.danger = true;
            return object;
        } else if (left > 0 && left < 7) {
            object.label = `${left} day${left == 1 ? '' : 's'} left`
            object.danger = true;
            return object;
        } else {
            object.label = `${left} day${left == 1 ? '' : 's'} left`
            return object;
        }
    }
}

//get the number of days between two dates
export function getDaysBetweenDates(d0, d1) {
    var msPerDay = 8.64e7;

    // Copy dates so don't mess them up
    var x0 = new Date(d0);
    var x1 = new Date(d1);

    // Set to noon - avoid DST errors
    x0.setHours(12, 0, 0);
    x1.setHours(12, 0, 0);

    // Round to remove daylight saving errors
    return Math.round((x1 - x0) / msPerDay);
}

export function processRank(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st';
    }
    if (j == 2 && k != 12) {
        return i + 'nd';
    }
    if (j == 3 && k != 13) {
        return i + 'rd';
    }
    return i + 'th';
}

//processes a salary number adding seperators and/or pound signs
export function ProcessSalary(salary, currency = '£') {
    if (!salary) return '';

    if (salary % 1 == 0) {
        salary = parseInt(salary);
    }

    var decimals = '';
    var salaryString = `${salary}`;
    var separator = ',';

    if (salaryString.includes('.')) {
        const split = salaryString.split('.');
        salaryString = split[0];
        decimals += `.${split[1]}`;
    }

    salaryString = `${salaryString}`;
    var salaryArr = salaryString.split('').reverse();

    var construct = '';

    salaryArr.forEach((item, index) => {
        if (index > 0 && index < salaryArr.length - 1 && index % 3 == 0) {
            construct = separator + construct;
        }

        construct = item + construct;
    });

    return `${currency}${construct}${decimals}`;
}

//gets highest index in array, rather than doing array.length - 1 all the time
export function highestIndex(array) {
    return array.length - 1;
}

const frequencyOptions = {
    YEAR: 'annum',
    DAY: 'day',
    HOUR: 'hour',
};

//turns salary range into something nice-looking
export function ResolveSalary(range, currency, frequency, type) {
    if (!range || !currency || !frequency) return '';

    if(type == "Paid Work") {
        if(range == "0-0") return "Negotiable";
    } else {
        return "Volunteer Role";
    }

    let constructor = '';

    const split = range.split('-');

    const from = ProcessSalary(split[0], currencyLookup[currency]);
    const to = ProcessSalary(split[1], currencyLookup[currency]);

    constructor += `${from}`;

    if (from != to) {
        constructor += ` to ${to}`;
    }

    constructor += ` per ${frequencyOptions[frequency.toUpperCase()]}`;

    return constructor;
}

//determines if an offer is closed or not based on input text
export function OfferClosedFunction(availability) {
    if (!availability) return false;
    let words = ['closed', 'archived', 'will go', 'expired'];
    if (words.some((o) => availability.includes(o))) {
        return true;
    } else {
        return false;
    }
}

export function generateRandomNumber(max) {
    return Math.random() * max;
}

export function generateRandomString(length) {
    // declare all characters
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function generateRandomStringWithSpaces(length, paragraphs) {
    // declare all characters
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = '';
    let sinceLast = 0;

    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));

        if(Math.random() < 0.25) {
            result += " "
        }

        if(paragraphs)  {
            if(Math.random() < 0.00001 * sinceLast) {
                result += "\n\n"
                sinceLast = 0;
            } else {
                sinceLast++;
            }
        }
        
    }

    return result;
}

export function generateRandomParagraphsWithSpaces(length) {
    return generateRandomStringWithSpaces(length, true);
}

export function generateRandomStringArray(arrLength, stringLength) {

    var arr = [];
    for(var i = 0; i < arrLength; i++) {
        arr.push(generateRandomStringWithSpaces(stringLength));
    }
    return arr;
}

export function arrToString(input) {
    let str = '';

    str = input.join(', ')

    return str;
}

export const FullStatCardScaling = ({ header, data, minHeight = 0 }) => {
    
    const theme = GetPageTheme();

    return (
        <Card id={`stat-card-${theme}`} className={style.dataCard} style={{ minHeight: `${minHeight}px` }}>
            { header ? <h4 className={style.slotHeader}>{header}</h4> : null }
            <div className={style.slotContainer}>
                { data.map((item, index) => <>
                    <p className={style.slot}>
                        <h4 className={style.slotData}>{item.loading ? <Loader onlyIcon/> : item.data}</h4>
                        <h5 className={style.slotLabel}>{item.label}</h5>
                    </p>
                </>) }
            </div>
        </Card>
    );
}

export const FullStatCardDouble = ({ header, labelLeft, labelRight, dataLeft, dataRight }) => {
    return (
        <Card className={style.dataCard}>
            <h4>{header}</h4>
            <Row>
                <Col xs="6" md="6" className={style.cardLabelLeft}>
                    <h4 className={style.labelText}>{dataLeft}</h4>
                    <h5 className={style.cardLabel}>{labelLeft}</h5>
                </Col>
                <Col xs="6" md="6" className={style.cardLabelRight}>
                    <h4 className={style.labelText}>{dataRight}</h4>
                    <h5 className={style.cardLabel}>{labelRight}</h5>
                </Col>
            </Row>
        </Card>
    );
};

export const FullStatCardSingle = ({ header, label, data, loading }) => {
    return (
        <Card className={style.dataCard}>
            <h4>{header}</h4>
            <Row>
                <Col xs="12" md="12" className={style.cardLabel}>
                    <h4 className={style.labelText}>{data}</h4>
                    <h5 className={style.cardLabel}>{label}</h5>
                </Col>
            </Row>
        </Card>
    );
};

export const ContainerCard = ({ id = '', header, children, className = '', HeaderAdd, columns = [6, 6] }) => {
    const theme = GetPageTheme();
    
    return (
        <Card id={id} className={`${style.containerCard} ${className}`}>
            {header ? (
                <Row>
                    <Col md={columns[0]}>
                        <h4 className={style.containerHeader}>{header}</h4>
                    </Col>
                    <Col md={columns[1]}>{HeaderAdd ? HeaderAdd() : null}</Col>
                </Row>
            ) : null}
            {children}
        </Card>
    );
};

export const SmallStatCard = ({ label, data }) => {
    return (
        <Card className={style.dataCardHalf}>
            <Row>
                <Col xs="12" md="12" className={style.cardLabel}>
                    <h4 className={style.labelText}>{data || "Empty"}</h4>
                    <h5>{label}</h5>
                </Col>
            </Row>
        </Card>
    );
};

export function deCamelString(value) {
    return value
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([0-9])([A-Z])/g, '$1 $2') // IPv4Addresses => IPv4 Addresses
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z][^0-9])/, '$1 $2$3') // // IPv4 Addresses != I Pv4 Addresses
        .replace(/\bId\b/, "ID")
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
        // uppercase all the space lowercase chars
        .replace(/ ([a-z])/g, function(str){ return str.toUpperCase(); })
}

export function generateMonoLinearGradient(hexColor) {
    if(!hexColor) return;

    // Convert the hexadecimal color to RGB format
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);

    return `linear-gradient(0.5turn, rgba(${r}, ${g}, ${b}, 1), 60%, rgba(${r}, ${g}, ${b}, 0.5))`
}

export function convertHexToRGB(hexColor) {

    // Convert the hexadecimal color to RGB format
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);

    return { r, g, b }
}

export function calculateLightness(hexColor) {

    // Convert the hexadecimal color to RGB format
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);

    // Normalize RGB values to the range [0, 1]
    const normalizedR = r / 255;
    const normalizedG = g / 255;
    const normalizedB = b / 255;

    // Find the maximum and minimum values among normalized R, G, and B
    const max = Math.max(normalizedR, normalizedG, normalizedB);
    const min = Math.min(normalizedR, normalizedG, normalizedB);

    // Calculate lightness
    const lightness = ((max + min) / 2) * 100;

    return lightness;
}