import React from 'react';
import { Button, Card, Col, Row } from 'reactstrap';

import { authPostData } from '../../api/tools';
import { FLASK_API, URLS } from '../../consts/urls';
import NudgeButton from '../buttons/NudgeButton';
import { useNavigate } from 'react-router-dom';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const NeotalentToggle = ({ user, extUser, mutate }) => {
    const setNeotalentUrl = `${FLASK_API}/checklist/toggle`;
    const navigate = useNavigate();

    async function handleNeotalentToggle(status) {
        await authPostData({ username: user.id, action: status }, setNeotalentUrl);
        await mutate();
    }

    async function handleViewCandidateProgress() {
        navigate(`${URLS.ADMIN_NEOTALENT}/${user.id}`);
    }

    /*
    
    .profileHeaderTitle { 
        font-size: 20px;

        width: 50%;
    }
    
    */

    return (
        <div>
            <Row>
                <Col lg={12}>
                    <div style={{ fontSize: '20px', width: '50%' }}>NEO Talent</div>
                    <Card id={`card-${GetPageTheme()}`} class="mt-10 mb-10" style={{ padding: '24px', marginBottom: '24px', width: '100%' }}>
                        {extUser?.neotalent ? (
                            <div>
                                <p>
                                    NEO Talent is <strong>enabled</strong> for this user.
                                </p>
                                <div class="flexy" style={{ columnGap: '6px' }}>
                                    <NudgeButton color="success" onClick={() => handleViewCandidateProgress()}>
                                        View Candidate Progress
                                    </NudgeButton>
                                    <NudgeButton color="success" onClick={() => handleNeotalentToggle(false)}>
                                        Disable NEO Talent
                                    </NudgeButton>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    NEO Talent is <strong>disabled</strong> for this user.
                                </p>
                                <NudgeButton color="success" onClick={() => handleNeotalentToggle(true)}>
                                    Enable NEO Talent
                                </NudgeButton>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default NeotalentToggle;
