import * as Yup from 'yup';

export const FIELDS = {
    SALARY: 'amount',
    COMMENT: 'comment',
};

export const validationSchema = Yup.object({
    [FIELDS.SALARY]: Yup.string()
        .matches(/^[+]?\d+(\.\d+)?$/, 'Please use numbers only')
        .required('This field is required'),
    [FIELDS.COMMENT]: Yup.string(),
});

export const initialValues = {
    [FIELDS.SALARY]: '',
    [FIELDS.COMMENT]: '',
};
