import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from './style.module.scss';
import { GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../buttons/NudgeButton';
import NudgeModal from '../../../../NudgeModal';

const CloseModal = ({ isOpen, toggle, onDelete }) => {
    return (
        <NudgeModal id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered headerText={"Are you sure you want to archive your job posting?"}>
            <ModalBody className={style.modalBody}>
                <div className={style.text}>
                    Job posting will be archived and will no longer be live. This action can be reversed at a later date.
                </div>

                <Row>
                    <Col xs={6} md={{ size: 4, offset: 2 }}>
                        <ClickButton size="lg" color="danger" onClick={onDelete} style={{ width: '100%' }}>
                            <b style={{ textDecoration: 'underline' }}>! Archive !</b>
                        </ClickButton>
                    </Col>
                    <Col xs={6} md={{ size: 4 }}>
                        <ClickButton size="lg" color="success" onClick={toggle} style={{ width: '100%' }}>
                            Go Back
                        </ClickButton>
                    </Col>
                </Row>
            </ModalBody>
        </NudgeModal>
    );
};
CloseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CloseModal;
