import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Card, Col } from 'reactstrap';
import { URLS } from '../../../../consts/urls';

import { GetPageTheme, ResolveSalary } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import style from '../style.module.scss';
import { MATCH_STATUSES_DICTIONARY } from '../../../../consts/matchOfferStatuses';

const OfferCard = ({ index, offer, score }) => {
    const statuses = Object.values(MATCH_STATUSES_DICTIONARY); 

    const navigate = useNavigate();

    const theme = GetPageTheme();
    return offer ? (
        <Col xs={12} md={6}>
            <Card className={style.offerCard} onClick={() => window.open(`${URLS.OFFERS}/${offer.id}`, '_blank', 'noreferrer')}>
                <h5 className={style.offerTitle}>{offer.title}</h5>
                <p>
                    {offer.company.name || 'Unavailable'} -{' '}
                    {offer.salaryFrom && offer.salaryTo
                        ? ResolveSalary(`${offer.salaryFrom}-${offer.salaryTo}`, offer.currencyType, offer.salaryFreq, offer.workType)
                        : 'TBD'}{' '}
                </p>
                {offer.matchStatus ? (
                    <h4 className={style.matchedText} style={ theme == 'light' ? { color:  'red' } : { color: 'white', background: 'red', padding: '4px', borderRadius: '0.25rem' }}>
                        Already matched: {MATCH_STATUSES_DICTIONARY[offer.matchStatus]}
                    </h4>
                ) : null}

                {/* <h4 className={style.score}>{`${processRank(index + 1)}`}</h4> */}
                <h4 className={style.score}>{`${(offer.score*10).toFixed(0)}%`}</h4>
            </Card>
        </Col>
    ) : null;
};

export default OfferCard;
