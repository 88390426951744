import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import { URLS } from '../../../consts/urls';
import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';

const LinkButton = ({ matchId }) => {
    const navigate = useNavigate();
    const onClick = () => navigate(`${URLS.COMPANY_INTERVIEWS}/${matchId}`);

    return (
        <ClickButton size="lg" color="success" className={style.btn} onClick={onClick}>
            View Interview Progress
        </ClickButton>
    );
};

export default LinkButton;
