import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';
import { Card } from 'reactstrap';

const Skill = ({ name, icon, title }) => (
    <div>
        <div className={style.skill}>
            <div className={style.skillIcon}><FontAwesomeIcon icon={['fas', icon]} /></div>
            <span className={style.skillName}>
                {name}
                <div className={style.skillTitle}>
                    {title}
                </div>
            </span>
        </div>
    </div>
);

Skill.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Skill;
