import React from 'react';
import { Card, Col, Progress, Row } from 'reactstrap';

import { uploadCVFlask } from '../../../../api/flask';
import { patchData } from '../../../../api/tools';
import { getParserCompletion, getTasks } from '../../../../consts/tasks';
import { API_PROFILE } from '../../../../consts/urls';
import { testNewCertificates } from '../../../../pages/SignUp/signUpHelper';
import {
    freelanceStaffOptions,
    timingOptions,
    workModeOptions,
    workSearchOptions,
    workTypeOptions,
} from '../../../ContractorInformation/ContractorInformationEdit/helpers';
import { skillIconOptions } from '../../../forms/SkillsForm/SkillsFormBody';
import { parseCertificates, parseExperience } from './helpers';
import style from './style.module.scss';

const CvParser = ({ user, mutate, toggle }) => {
    const [sending, setSending] = React.useState(false);
    const [error, setError] = React.useState('');
    const [status, setStatus] = React.useState('');

    const [progress, setProgress] = React.useState(0);
    const progressRef = React.useRef(progress);

    const tasks = getTasks(user);

    React.useEffect(() => {
        if (error !== ' ' || error) {
            setTimeout(() => setError(''), 10000);
        }
    }, [error]);

    React.useEffect(() => {
        progressRef.current = progress;
    }, [progress]);

    async function startProgress() {
        setProgress(0);
        let i = 0;
        while (i < 100) {
            await new Promise((resolve) => setTimeout(resolve, 45000 / 100));
            i += 1;
            if (progressRef.current >= 100) break;
            setProgress(i);
        }
    }

    async function parseResume(file) {
        toggle();
        setSending(true);
        startProgress();
        setStatus('Reading CV, please wait');
        const model = document.getElementById('model').value;
        let data;

        try {
            data = await uploadCVFlask(file, model);
        } catch (e) {
            console.log(e);
        }

        if (!data) {
            setSending(false);
            setError('Unable to read CV. Please try again later.');
            return;
        }

        setStatus('Updating profile');

        //Experience
        const experience = parseExperience(data.workExperience, user.experience);
        const current = experience.length ? experience.find((item) => item.present === true) : null;

        //Skills
        const currentSkills = user.skills.map((item) => ({ Id: item.id, name: item.name, icon: item.icon }));
        const newSkills = data.skills.map((item) => ({
            Id: 0,
            name: item.name,
            icon: skillIconOptions.find((icon) => icon.altValue === item.type).icon,
        }));
        // concat and deduplicate
        const patchSkills = currentSkills.concat(
            newSkills.filter((item) => !currentSkills.find((skill) => skill.name === item.name)),
        );

        const patchObj = {
            Username: data.name,
            skills: newSkills, //patchSkills,
            bio: data.bio,
            education: data.highestQualification.degree + '/' + data.highestQualification.fieldOfStudy,

            workMode: workModeOptions.find((item) => item.altValue === data.workMode).value,
            workType: workTypeOptions.find((item) => item.altValue === data.workType).value,
            FreelancerStaff: freelanceStaffOptions.find((item) => item.altValue === data.freelanceOrStaff).value,
            timingOptions: timingOptions.find((item) => item.altValue === data.fullTimeOrPartTime).value,

            contractorValues: data.coreValues.map((item) => ({ content: item })),

            location: data.location,

            Experience: experience,

            JobName: data.currentOrLastJobTitle,
            JobSubIndustryId: data.subIndustryId,
            Company: data.currentOrLastEmployer,
        };

        const followUp = await patchData(patchObj, `${API_PROFILE}`);

        //Certificates
        setStatus('Uploading certifications');
        const certificates = parseCertificates(data.certifications);
        if (certificates?.length) {
            await testNewCertificates(certificates, `${API_PROFILE}/Certificate`);
        }

        setStatus('Finished. Please double check your updated profile details.');
        setProgress(100);

        if (mutate) await mutate();

        setTimeout(() => {
            setSending(false);
        }, 5000);
    }

    return getParserCompletion(tasks) ? (
        <>
            {user && !sending ? (
                <Card className={style.parserCard} style={{ color: 'white' }}>
                    <Row>
                        <Col xs={12} md={6} className={style.col}>
                            <h5>Use your CV to fill in parts of your Nudge profile automatically.</h5>
                        </Col>
                        <Col xs={12} md={6}>
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => parseResume(e.target.files[0])}
                            />
                            <p style={{ fontSize: '90%' }}>PDF only, max. 5MB file size</p>
                            <select id="model" style={{ display: 'none' }}>
                                <option value="gpt-4o">gpt-4o</option>
                                <option value="gpt-4-turbo" selected>gpt-4-turbo</option>
                                <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                            </select>
                        </Col>
                    </Row>

                    {error ? (
                        <>
                            {' '}
                            <br />
                            <h5>{error}</h5>{' '}
                        </>
                    ) : null}
                </Card>
            ) : null}
            {sending ? (
                <Card className={style.parserCard}>
                    <h4>{status}</h4>
                    <Progress value={progress} />
                </Card>
            ) : null}
        </>
    ) : null;
};

export default CvParser;
