import { useState } from 'react';

import cn from 'classnames';
import style from './style.module.scss';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tooltip } from 'reactstrap';
import Status from './Status';

import AppInput from '../../AppInput';
import SelectField from '../../SelectField';
import NewArrayFieldForm from '../NewArrayFieldForm';
import SkipAndSubmit from '../SkipAndSubmit';
import DURATION_OPTIONS from './durationOptions';
import { FIELDS } from './validation';
import NudgeTooltip from '../../NudgeTooltip';

const getErrorForField = (formik, index, field) => {
    
    if(!formik.submitCount) return null;

    return formik.touched[FIELDS.PROJECTS] &&
    formik.touched[FIELDS.PROJECTS][index] &&
    formik.errors[FIELDS.PROJECTS] &&
    formik.errors[FIELDS.PROJECTS][index] &&
    formik.errors[FIELDS.PROJECTS][index][field]
}
const ErrorMessage = ({ formik, index, field }) => (
    <div className="custom-input-error">{getErrorForField(formik, index, field)}</div>
);

const ArrayField = ({ index, formik, tabObj }) => {
    const [toolOpen, setToolOpen] = useState(false);
    const toggle = () => {
        setToolOpen(!toolOpen);
    };

    React.useEffect(() => {
        let obj = formik.values[FIELDS.PROJECTS];
        
        const y = obj[index].years;
        const m = obj[index].months;
        
        obj[index].duration = `${y}-${m}`

        formik.setFieldValue(FIELDS.PROJECTS, obj)

    }, [formik.values[FIELDS.PROJECTS][index].years, formik.values[FIELDS.PROJECTS][index].months])

    const body = (
        <>
            <Col md={6}>
                <h6 className={style.headers}>Title</h6>
                <Field
                    name={`${FIELDS.PROJECTS}.${index}.name`}
                    placeholder="Title"
                    className={cn('form-control', {
                        'is-invalid': getErrorForField(formik, index, 'name'),
                    })}
                />
                <ErrorMessage formik={formik} index={index} field={"name"}/>
            </Col>

            <Col xs={6} sm={6} md={3}>
                <h6 className={style.headers}>Years</h6>
                <Field
                    name={`${FIELDS.PROJECTS}.${index}.years`}
                    type="number"
                    placeholder="Years"
                    className={cn('form-control', {
                        'is-invalid': getErrorForField(formik, index, 'years'),
                    })}
                />
                <ErrorMessage formik={formik} index={index} field={"years"}/>
            </Col>
            <Col xs={6} sm={6} md={3}>
                <h6 className={style.headers}>Months</h6>
                <Field
                    name={`${FIELDS.PROJECTS}.${index}.months`}
                    type="number"
                    placeholder="Months"
                    className={cn('form-control', {
                        'is-invalid': getErrorForField(formik, index, 'months'),
                    })}
                />
                <ErrorMessage formik={formik} index={index} field={"months"}/>
            </Col>

            <Col md={12}>
                <h6 className={style.headers}>Project Details</h6>
                <AppInput
                    id={`${FIELDS.PROJECTS}.${index}.details`}
                    formik={formik}
                    label="Tell us a little more about this project."
                    placeholder="Tell us a little more about this project."
                    value={formik.values[FIELDS.PROJECTS][index].details}
                    className={style.details}
                    type="textarea"
                />
                <ErrorMessage formik={formik} index={index} field={"details"}/>
            </Col>
            <Col md={12}>
                <div class="mb-10 mt-10">
                    <NudgeTooltip label={"Reference Email"} id={`ref_email-${index}`}>
                        Nudge will send an email requesting a small description of the referee's connection to you.
                    </NudgeTooltip>
                </div>
                
                <Field
                    name={`${FIELDS.PROJECTS}.${index}.ref_email`}
                    className={cn('form-control')}
                    placeholder="Referee Email (Required)"
                />

                <ErrorMessage formik={formik} index={index} field={"ref_email"}/>
            </Col>

            <div className={style.buffer} />
        </>
    );

    const addValue = { name: '', duration: DURATION_OPTIONS[0].value, ref_email: '' };
    return (
        <NewArrayFieldForm formik={formik} index={index} field={FIELDS.PROJECTS} addValue={addValue} fieldLimit={12} labelGap tabObj={tabObj}>
            {body}
        </NewArrayFieldForm>
    );
};

const IndustryExperienceFormBody = ({ formik, skipCurrentStep, alwaysShowMinus }) => {

    const [selectedTab, setSelectedTab] = React.useState(0);
    const tabObj = { setTab: setSelectedTab, selected: selectedTab }

    React.useEffect(() => {
        if(!formik.values[FIELDS.PROJECTS][selectedTab]) {
            setSelectedTab(selectedTab - 1 < 0 ? 0 : selectedTab - 1)
        }
    }, [formik.values[FIELDS.PROJECTS].length])

    return (
        <FieldArray name={FIELDS.PROJECTS}>
            {(arrayHelpers) => (
                <>
                    {formik.values[FIELDS.PROJECTS].map((_, index) => (
                        <ArrayField
                            key={index}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            formik={formik}
                            alwaysShowMinus={alwaysShowMinus}
                            tabObj={tabObj}
                        />
                    ))}

                    {skipCurrentStep ? <SkipAndSubmit skipCurrentStep={skipCurrentStep} formik={formik} /> : null}
                </>
            )}
        </FieldArray>
    );
};

IndustryExperienceFormBody.propTypes = {
    formik: PropTypes.object.isRequired,
    skipCurrentStep: PropTypes.func.isRequired,
    alwaysShowMinus: PropTypes.bool,
};

export default IndustryExperienceFormBody;
