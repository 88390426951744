import React from 'react';
import { ModalBody, ModalHeader, Tooltip } from 'reactstrap';
import Status from './Status';

import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../NudgeModal';

import scssStyle from './style.module.scss';

const NudgeTooltip = ({ id, label, style = {}, large, icon, iconOnly, noModal, children }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => { setModalOpen(!modalOpen) }

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => { setTooltipOpen(!tooltipOpen) }

    const theme = GetPageTheme();

    return (
        id ? <>
            <Status id={id} large={large} status={label} comment={''} onClick={toggleModal} style={style} icon={icon} iconOnly={iconOnly}/>
            <Tooltip placement="right" isOpen={tooltipOpen} target={`statusTooltip-${id}`} toggle={toggle} className={scssStyle.tooltipText}>
                <h5 style={{ color: 'white', whiteSpace: 'pre-wrap' }}>{ children }</h5>
            </Tooltip>

            { !noModal ? <NudgeModal id={`modal-${theme}`} isOpen={modalOpen} toggle={toggleModal} className={scssStyle.modal} headerText={label}>
                <ModalBody>
                    <h5 style={{ whiteSpace: 'pre-wrap', maxWidth: '900px', margin: 'auto', textAlign: 'center' }}>{ children }</h5>
                </ModalBody>
            </NudgeModal> : null }
        </> : <Status status={label} large={large}/>
    )
}

export default NudgeTooltip;