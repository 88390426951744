import React from 'react';
import { Button } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import style from './style.module.scss';
import ClickButton from '../NudgeButton';

const BackButton = ({ show, className = '', link }) => {
    const navigate = useNavigate();
    

    return show ? (
        <ClickButton className={className || style.edit} color="danger" onClick={() => navigate( link ? link : -1)}>
            Go Back
        </ClickButton>
    ) : null;
};

export default BackButton;
