import React, { useState } from 'react';
import { Formik } from 'formik';
import { Card, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import AppInput from '../../../components/AppInput';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import SwitchToDesktop from '../../../components/SwitchToDesktop';

import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { authPostData } from '../../../api/tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USER, COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';

import { initialValues, validationSchema, FIELDS } from './validation';
import ClickButton from '../../../components/buttons/NudgeButton';

var toggleFeedback;
var toggleSuccess;
var successTimer;

const submitFeedback = async (v) => {
    var url = `${API_DASHBOARD_CONTRACTOR}/Trello`;
    await authPostData(v, url, { feedback: v.feedback });

    toggleFeedback();
    toggleSuccess();
};

export const Feedback = () => {
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    toggleFeedback = () => {
        setFeedbackOpen(!feedbackOpen);
    };

    toggleSuccess = () => {
        setShowSuccess(!showSuccess);
    };
    successTimer = () => {
        toggleSuccess();
    };

    var feedbackIcon;

    !feedbackOpen ? (feedbackIcon = 'angle-double-down') : (feedbackIcon = 'angle-double-up');

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            submitFeedback(values);
            setTimeout(() => {
                toggleSuccess();
            }, 4000);
        },
    };

    return (
        <SignInPageTemplate>
            <SwitchToDesktop />
            <div className={style.jumbotron}>
                <Card className={style.container}>
                    <h1>
                        <b>Nudge Roadmap</b> - This page is just a little look at what we have coming to Nudge in the
                        future.
                    </h1>
                    <h2>
                        Use the form in the dropdown below to send us your own feedback for features or fixes you'd like
                        to see.
                    </h2>
                </Card>
                <br />

                <iframe
                    title="trello"
                    className={style.frame}
                    src="https://trello.com/b/p6MSL95a.html"
                    width="100%"
                    height="500px"
                ></iframe>
                <br />

                <Card className={feedbackOpen ? style.container : style.closeContainer}>
                    <Row>
                        <Col md={10}>
                            <b style={{ fontSize: '24px' }}>{`Feedback & Suggestions`}</b>
                        </Col>
                        <Col md={2}>
                            <ClickButton onClick={() => toggleFeedback()} className={style.selectButton}>
                                {<FontAwesomeIcon icon={['fal', feedbackIcon]} />}
                            </ClickButton>
                        </Col>
                        
                    </Row>

                    <div className={style.container}>
                        <Formik {...props}>
                            {(formik) => (
                                <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                                    <h5 className={style.header}>
                                        If you'd like to take a few moments to give us your suggestions and feedback on
                                        our site, it would be more than appreciated.
                                    </h5>

                                    <FormGroup className={style.header}>
                                        <AppInput
                                            className={style.feedback}
                                            id={FIELDS.FEEDBACK}
                                            formik={formik}
                                            label="Feedback goes here..."
                                            type="textarea"
                                        />
                                    </FormGroup>

                                    <div className={style.header}>
                                        <ClickButton color="success" className={style.uploadButton} type="submit">
                                            Submit
                                        </ClickButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    {showSuccess ? (
                        <h1 className={style.container}>Your feedback has been received. Thank you.</h1>
                    ) : null}
                </Card>
            </div>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Feedback, [USER, COMPANY]);
