import React, { useState } from 'react';
import { Row, Card, Button, Col } from 'reactstrap';

import CompanyInfoForm from '../../../forms/CompanyInfoForm';

import { FullStatCardDouble, FullStatCardScaling, SmallStatCard } from '../../GenericCompanyProfile/HelperComponents';

import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import style from '../style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';

export const CompanyData = ({ extraDetails, data, offers, mutate, id, showEdit }) => {
    const [infoEdit, setInfoEdit] = useState(false);

    if (!offers) {
        offers = [];
    }

    return (
        <div style={{ marginTop: '24px' }}>

            <Card className={style.infoCard}>
                {infoEdit ? (
                    <CompanyInfoForm
                        url={`${API_DASHBOARD_COMPANY}/CompanyInfo/${id}`}
                        id={data.id}
                        onClickClose={setInfoEdit}
                        refresh={mutate}
                        info={extraDetails.companyInfo}
                    />
                ) : (
                    <div>
                        <Row>
                            <Col md={9}/>
                            <Col md={3}>
                                {showEdit ? (
                                    <ClickButton className={style.editButton} color="success" onClick={() => setInfoEdit(true)}>
                                        Edit
                                    </ClickButton>
                                ) : <br/> }
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FullStatCardScaling
                                    header=""
                                    data={[
                                        { label: 'Industry', data: extraDetails.companyInfo.industry },
                                        { label: 'Size (Employees)', data: extraDetails.companyInfo.size },
                                    ]}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FullStatCardScaling
                                    header=""
                                    data={[
                                        { label: 'HQ (Based In)', data: extraDetails.companyInfo.headquarters },
                                        { label: 'Founded (Year)', data: extraDetails.companyInfo.founded },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
                
                <FullStatCardScaling
                    header="Open Opportunities"
                    data={[
                        { label: 'Permanent', data: offers.filter((item) => item.type === 'Staff').length },
                        { label: 'Contract', data: offers.filter((item) => item.type !== 'Staff').length },
                    ]}
                />
            </Card>
        </div>
    );
};

export default CompanyData;
