import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { authFetcher, authPostData } from '../../../../api/tools';
import { API_DASHBOARD_CLOOD, API_PROFILE } from '../../../../consts/urls';
import { getTasks } from '../../../../consts/tasks';
import style from './style.module.scss';
import { ContainerCard, GetPageTheme, capitaliseFirstLetter } from '../../../pages/GenericCompanyProfile/HelperComponents';
import Skills from '../../../pages/ContractorProfilePage/Skills';
import { getApiByRole } from '../../../../consts/userRoles';
import { AuthContext } from '../../../../contexts/auth/AuthContextWrapper';
import { getContractorDashboardUrl } from '../../../pages/ContractorProfilePage/helper';
import Values from '../../../pages/ContractorProfilePage/Values';
import Calendar from '../../../pages/ContractorProfilePage/Calendar';
import { parseDate } from '../../../../tools/dateHelpers';
import Education from '../../../pages/ContractorProfilePage/Education';
import CertificatesAndQualifications from '../../../pages/ContractorProfilePage/CertificatesAndQualifications';
import ProjectHistory from '../../../pages/ContractorProfilePage/ProjectHistory';
import ContractorInformation from '../../../ContractorInformation';
import NudgeModal from '../../../NudgeModal';
import NudgeTabs from '../../../NudgeTabs';
import useMobile from '../../../../hooks/useMobile';
import NudgeButton from '../../../buttons/NudgeButton';

async function handleCaseBase(user) {
    const projectId = `ec32f707d74145619ddff1cbd258bca0`;
    const getCaseUrl = `${API_DASHBOARD_CLOOD}/Projects/CaseExists/Matching/${user.id}`;

    if(user?.parentId) { console.log("Cannot retain internal candidates.") }

    const response = await authFetcher(getCaseUrl);

    if (response == false) {
        const retainCaseUrl = `${API_DASHBOARD_CLOOD}/Projects/RetainContractor`;
        const retainResponse = await authPostData({}, retainCaseUrl, {});
    } else if (response == true) {
        return;
    } else {
        console.log("Something's gone wrong.");
    }
}

const ChecklistModal = ({ user, tasks, mutate, completion, isOpen, toggle }) => {
    React.useEffect(() => {
        if (completion == 100) {
            handleCaseBase(user);
        }
    }, []);

    const [changeKey, setChangeKey] = React.useState(false);
    const theme = GetPageTheme();
    const toggleKey = () => {
        setChangeKey(!changeKey);
    };

    const { isMobileWide } = useMobile();
    const [selectedTask, setSelectedTask] = React.useState('none_selected');

    const props = {
        showEdit: true,
        url: API_PROFILE,
        changeKey: 1,
        refresh: mutate,
        reset: () => setSelectedTask('form_success'),
        theme: theme,
        canCancel: false
    };

    const contractorProfile = (
        <ContractorInformation
            user={user}
            theme={theme}
            resume={user.resumeUrl}
            expectedEarnings={user.expectedEarnings}
            company={user.company}
            job={user.job}
            location={user.location}
            showResume={true}
            {...props}
        />
    );

    const taskComponents = {
        none_selected: <h5 className={style.success}>Click sections to jump to incomplete parts of your profile.</h5>,
        add_skills: <Skills user={user} skills={user.skills} {...props} />,
        add_values: <Values user={user} data={user.values} {...props} />,
        add_availability: (
            <Calendar
                user={user}
                startDate={user.availableFrom ? parseDate(user.availableFrom) : null}
                endDate={user.availableFrom ? parseDate(user.availableTo) : null}
                hours={user.availableHours}
                {...props}
            />
        ),
        add_education: <Education user={user} qualification={user.education} {...props} />,
        add_certs: (
            <CertificatesAndQualifications certificates={user.certificates} qualification={user.education} {...props} />
        ),
        add_projects: <ProjectHistory user={user} steps={user.experience} {...props} />,
        add_bio: contractorProfile,
        add_location: contractorProfile,
        add_expected_earnings: contractorProfile,
        upload_cv: contractorProfile,
        add_photo: contractorProfile,

        form_success: <h5 className={style.success}>Profile changes successful.</h5>,
    };
    
    function resolveIsOpen() {
        if(selectedTask == 'none_selected' || !isMobileWide) {
            return true;
        }
    }

    return (
        <NudgeModal headerText={`Profile Progress is ${completion}%.`} id={`modal-${theme}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered={false}>
            <ModalBody className={style.modalBody}>
                <Row>
                    { resolveIsOpen() ? <Col md={4} className={style.taskCol}>
                        { Object.keys(tasks).map((task) =>
                            !tasks[task][1] ? (
                                <Card
                                    id={`card-${theme}`}
                                    key={task}
                                    className={selectedTask == task ? style.taskCardSelected : style.taskCard}
                                    onClick={() => setSelectedTask(selectedTask !== task ? task : 'none_selected')}
                                >
                                    {capitaliseFirstLetter(tasks[task][0], true)}
                                </Card>
                            ) : null,
                        )}
                    </Col> : <Col md={2} className={style.taskCol}>
                        <NudgeButton color="danger" onClick={() => setSelectedTask('none_selected')}>Back</NudgeButton>
                    </Col> }
                    <Col md={resolveIsOpen() ? 8 : 10} className={style.taskScroller}>
                        {selectedTask ? (
                            <Card id={`card-sub-${theme}`} className={style.componentCard}>
                                {taskComponents[selectedTask]}
                            </Card>
                        ) : null}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter/>
        </NudgeModal>
    );
};

export default ChecklistModal;
