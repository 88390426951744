import React from 'react';
import { Button } from 'reactstrap';
import { GetPageTheme } from '../../pages/GenericCompanyProfile/HelperComponents';

const NudgeButton = ({ id, onClick, size, className, color = "success", disabled, children, type = "button", style, selected, tabIndex, title  }) => {
    const theme = GetPageTheme();

    function idBuilder() {
        if(id) return id;

        return `button-${theme}-${disabled ? "disabled-" : ""}${color}${selected ? "-selected" : ""}`;
    }

    return (
        <Button title={disabled ? `Disabled` : title} size={size} tabIndex={tabIndex} id={idBuilder()} onClick={onClick} className={`${className}`} type={type} disabled={disabled} color={disabled ? `disabled-${color}` : `${color}`} style={style}>
            {children}
        </Button>
    )
}

export default NudgeButton;