import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import scssStyle from './style.module.scss';

const Status = ({ status, comment, id, onClick, large, icon, iconOnly, style }) => (
        <div className={scssStyle.statusWrapper} style={style}>
            { !iconOnly ? <span className={large ? scssStyle.statusLarge : scssStyle.status}><b>{status}</b></span> : null }

            { id ? <span className={scssStyle.tooltip} id={'statusTooltip-' + id}>
                <FontAwesomeIcon className={scssStyle.icon} icon={['fas', icon ? icon : 'question-circle']} onClick={onClick} />
            </span> : null }

            {comment ? `(${comment})` : ''}
        </div>
    )

Status.propTypes = {
    status: PropTypes.string,
    comment: PropTypes.string,
};

export default Status;
