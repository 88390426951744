import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getFormikError } from '../../tools/formikHelpers';
import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const DEFAULT_VALUE = '';

const AppInput = ({ id, type = '', formik, label, onChange, onClick, className, showError = true, charLimit, isDisabled, children, onEnter, ...props }) => {
    const formikError = getFormikError(formik, id);
    const [show, setShow] = useState(false);

    if (type !== 'password' && !show) {
        setShow(true);
    }

    function onKeyUpValue(event) {
        event.preventDefault();
        
        if (event.which === 13 && !event.shiftKey) {
            if (!event.repeat) {

                if(onEnter) onEnter();
            }
    
             // Prevents the addition of a new line in the text field
        }
    }

    const atWordLimit = formik.values[id]?.length >= charLimit;

    const onChangeInput = (data) => {
        onChange && onChange(data);
        formik.handleChange(data);
    };

    const theme = GetPageTheme();
    return type === 'password' ? (
        <>
            <div style={{ display: 'flex' }} className={type === 'password' ? style.display : null}>
                <Input
                    className={`${className} ${style.display}`}
                    name={id}
                    type={show ? 'text' : 'password'}
                    onChange={onChangeInput}
                    value={formik.values[id] || DEFAULT_VALUE}
                    data-testid={id}
                    invalid={!!formikError}
                    placeholder={label.toUpperCase()}
                    onClick={onClick}
                    {...props}
                />
                {type === 'password' ? (
                    <div class="flexy" style={{ alignItems: 'center', width: '36px' }}>
                        <FontAwesomeIcon icon={['fas', show ? 'eye' : 'eye-slash']} className={style.eye} onClick={() => setShow(!show)} />
                    </div>
                ) : null}
            </div>
            {showError && !!formik.errors[id] && formik.submitCount > 0 ? <h5 className={theme == 'light' ? style.error : style.errorDark }>{formikError}</h5> : null}
        </>
    ) : (
        <>
            <Input
                className={`${className} ${style.display} ${isDisabled ? style.disabled : ""}`}
                name={id}
                type={type}
                onChange={onChangeInput}
                value={formik.values[id] || DEFAULT_VALUE}
                data-testid={id}
                invalid={!!formikError}
                placeholder={label.toUpperCase()}
                onClick={onClick}
                maxLength={`${charLimit}`}
                disabled={isDisabled}
                style={ !!charLimit ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}}
                onKeyUp={(e) => {
                    onKeyUpValue(e);
                }}
                {...props}
            />
            
            { !!charLimit ? <div className={style.wordLimitContainer}>
                <h5 className={`${theme == 'light' ? style.words : style.wordsDark} ${atWordLimit || !!formikError ? style.wordLimit : null}`}>
                    { atWordLimit ? "MAX" : formik.values[id].length} / {charLimit}
                </h5>
            </div> : null }
            
            { showError && !!formik.errors[id] && formik.submitCount > 0 ? <h5 className={theme == 'light' ? style.error : style.errorDark }>{formikError}</h5> : null}

            { children ? <div style={{ display: show ? 'block' : 'none' }}>
                { children }
            </div> : null }
        </>
    );
};

AppInput.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    formik: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

export const AppInputWithoutAutocomplete = (props) => (
    <form autoComplete="off">
        <AppInput {...props} />
    </form>
);

export default AppInput;
