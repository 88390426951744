import React from 'react';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../consts/urls';
import Loader from '../Loader';
import SignInPageTemplate from '../SignInPageTemplate';

const CompanyPageTemplate = ({ children, withArrow }) => {
    const { data: company } = useSWR(API_DASHBOARD_COMPANY, authFetcher, NO_REFRESH_INTERVAL);
    const navigate = useNavigate();

    return (
        <SignInPageTemplate>
            {company ? (
                <Row>
                    <Col md="12">{children}</Col>
                </Row>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default CompanyPageTemplate;
