import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData } from '../../api/tools';
import NudgeModal from '../../components/NudgeModal';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import { FLASK_API, URLS } from '../../consts/urls';
import NudgeButton from '../buttons/NudgeButton';
import { getContractorExtUrl } from '../pages/ContractorProfilePage/helper';
import style from './style.module.scss';

const ScheduleModal = ({ item, toggle, mutate, resultsUrl }) => {
    const theme = GetPageTheme();
    const [sending, setSending] = React.useState(false);

    const [meetingDate, setMeetingDate] = React.useState(new Date());

    React.useEffect(() => {
        if (item?.date) {
            setMeetingDate(moment(item.date).toDate());
        }
    }, [item]);

    function handleDateChange(date) {
        setMeetingDate(date);
    }

    async function handleConfirmDate() {
        setSending(true);
        await authPostData(
            { action: 'schedule', item: item.id, date: meetingDate.toISOString().split('.')[0] + 'Z' },
            resultsUrl,
        );
        await mutate();
        setSending(false);
        toggle();
    }

    async function handleCancelMeeting() {
        setSending(true);
        await authPostData({ action: 'cancel', item: item.id }, resultsUrl);
        await mutate();
        setSending(false);
        toggle();
    }

    return (
        <NudgeModal
            id={`modal-${theme}`}
            isOpen={!!item}
            toggle={toggle}
            headerText={`${item?.date ? 'Reschedule' : 'Schedule'} meeting: ${item?.name} (${item?.period})`}
        >
            <ModalBody>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DatePicker
                        selected={meetingDate}
                        onChange={(date) => handleDateChange(date)}
                        showTimeSelect
                        inline
                        timeIntervals={5}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <p>
                    <strong>Date selected</strong>: {moment(meetingDate).format('Do MMMM YYYY, h:mma')}
                </p>
                <NudgeButton onClick={handleConfirmDate} disabled={sending}>
                    {item?.date ? 'Save changes' : 'Confirm'}
                </NudgeButton>
                {item?.date && (
                    <NudgeButton onClick={handleCancelMeeting} disabled={sending}>
                        Cancel meeting
                    </NudgeButton>
                )}
                <NudgeButton onClick={toggle} disabled={sending}>
                    {item?.date ? 'Close' : 'Cancel'}
                </NudgeButton>
            </ModalFooter>
        </NudgeModal>
    );
};

const ContractorDashProjectProgress = ({ userId, roles }) => {
    const resultsUrl = !roles.includes('Admin') ? `${FLASK_API}/checklist` : `${FLASK_API}/checklist/${userId}`;
    const theme = GetPageTheme();

    const { data, isValidating, mutate } = useSWR(resultsUrl, authFetcher, { revalidateOnFocus: false });
    const { data: extData } = useSWR(getContractorExtUrl(roles, userId), authFetcher, { revalidateOnFocus: false });
    const [sending, setSending] = React.useState(true);
    const navigate = useNavigate();

    const [schedulingItem, setSchedulingItem] = React.useState();
    const toggle = () => setSchedulingItem();

    React.useEffect(() => {
        if (data) {
            let currentStep;
            data.checklist.sort((a, b) => a.id - b.id);
            data.checklist.forEach((item, index, array) => {
                item.current = false;
                //item.document = '#'; // for testing
                if (item.complete) {
                    item.style = theme === 'dark' ? 'itemCompleteDark' : 'itemComplete';
                    item.formattedCompletion = moment(item.completionDate).format('Do MMMM YYYY, h:mma');
                } else {
                    item.style = theme === 'dark' ? 'itemIncompleteDark' : 'itemIncomplete';
                    if (!currentStep) {
                        currentStep = item.id;
                        item.style = theme === 'dark' ? 'itemCurrentDark' : 'itemCurrent';
                        item.current = true;
                        if (index > 0) {
                            array[index - 1].isPreviousComplete = true;
                        }
                    }
                }
            });
            setSending(false);
        }
    }, [data, theme]);

    const adminContractorId = useParams().id;

    async function handleToggleCompletion(item) {
        setSending(true);
        await authPostData({ action: item.complete ? false : true, item: item.id }, resultsUrl);
        await mutate();
        setSending(false);
    }

    async function handleScheduleMeeting(item) {
        setSchedulingItem(item);
    }

    async function handleViewResource(e, item) {
        e.preventDefault();
        window.open(item.document, '_blank');
        if (!roles.includes('Admin') && item.current) {
            setSending(true);
            await authPostData({ action: 'view', item: item.id }, resultsUrl);
            await mutate();
            setSending(false);
        }
    }

    async function handleJoinMeeting(item) {
        navigate(
            roles.includes('Admin')
                ? `${URLS.ADMIN_NEOTALENT}/${adminContractorId}/meeting/${item.id}`
                : `${URLS.NEO_TALENT_MEETING}/${item.id}`,
        );
    }

    function calculatePercentage() {
        if (data?.checklist) {
            const checkedItems = data.checklist.filter((item) => item.complete);
            const percentage = (checkedItems.length / data.checklist.length) * 100;
            return percentage;
        }
    }

    function displayStep(item) {
        return (
            <Row className={`${style.projectRow} ${style[item.style]}`} key={item.id}>
                <Col xs={6} style={{ paddingLeft: '5px' }}>
                    <div>
                        <h4>{item.period}</h4>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <h5>
                            {item.type === 'call' && (
                                <FontAwesomeIcon
                                    icon={['fas', 'video']}
                                    style={{ marginRight: '10px', width: '16px' }}
                                />
                            )}
                            {item.type === 'link' && (
                                <FontAwesomeIcon
                                    icon={['far', 'book-alt']}
                                    style={{ marginRight: '10px', width: '16px' }}
                                />
                            )}
                            {item.type === 'webinar' && (
                                <FontAwesomeIcon
                                    icon={['fas', 'users']}
                                    style={{ marginRight: '10px', width: '16px' }}
                                />
                            )}
                            {item.name} (
                            {item.type === 'call' ? 'Video meeting' : item.type === 'webinar' ? 'Webinar' : 'Document'})
                        </h5>
                    </div>
                    <div>
                        {item.document && (
                            <p
                                style={{
                                    textDecoration: 'underline',
                                    display: 'inline-block',
                                    marginBottom: '3px',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['far', 'external-link']}
                                    style={{ marginRight: '10px', width: '16px' }}
                                />
                                <Link to={item.document} onClick={(e) => handleViewResource(e, item)}>
                                    <span className={style.supportingLink}>Supporting documents</span>
                                </Link>
                            </p>
                        )}
                    </div>
                    <div>
                        {item.complete && (
                            <div>
                                <p style={{ margin: '0' }}>
                                    <FontAwesomeIcon
                                        icon={['far', 'check']}
                                        style={{ marginRight: '10px', width: '16px' }}
                                    />
                                    Completed on {item.formattedCompletion}
                                </p>
                            </div>
                        )}
                        {!item.complete && (
                            <>
                                {(item.type === 'call' || item.type === 'webinar') && (
                                    <div>
                                        {item.date ? (
                                            <p style={{ margin: '0' }}>
                                                <FontAwesomeIcon
                                                    icon={['far', 'clock']}
                                                    style={{ marginRight: '10px', width: '16px' }}
                                                />
                                                Scheduled for {moment(item.date).format('Do MMMM YYYY, h:mma')}
                                            </p>
                                        ) : (
                                            <p style={{ margin: '0' }}>
                                                <FontAwesomeIcon
                                                    icon={['far', 'clock']}
                                                    style={{ marginRight: '10px', width: '16px' }}
                                                />
                                                Not yet scheduled
                                            </p>
                                        )}
                                    </div>
                                )}
                                {item.type === 'link' && !roles.includes('Admin') && (
                                    <div>
                                        <p style={{ margin: 0 }}>
                                            Complete this milestone by opening the supporting documents link.
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Col>
                <Col xs={6} className="d-flex flex-column justify-content-center">
                    {item.date && !item.complete && (
                        <div
                            className={`flex-center-align-justify ${
                                roles.includes('Admin') && item.current ? 'mb-10' : ''
                            }`}
                        >
                            <NudgeButton
                                disabled={isValidating || sending}
                                onClick={() => handleJoinMeeting(item)}
                                style={{ marginRight: '10px' }}
                            >
                                Join {item.type === 'call' ? 'meeting' : 'webinar'}
                            </NudgeButton>

                            <AddToCalendarButton
                                name={`NEO Talent - ${item.name}`}
                                options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                                location="World Wide Web"
                                startDate={moment(item.date).format('YYYY-MM-DD')}
                                endDate={moment(item.date).add(1, 'hour').format('YYYY-MM-DD')}
                                startTime={moment(item.date).format('HH:mm')}
                                endTime={moment(item.date).add(1, 'hour').format('HH:mm')}
                                timeZone="Europe/London"
                                buttonStyle="default"
                                hideBranding={true}
                            />
                        </div>
                    )}
                    {roles.includes('Admin') && (
                        <div className="flex-center-align-justify">
                            {!item.complete && item.type === 'call' && (
                                <NudgeButton
                                    disabled={isValidating || sending}
                                    onClick={() => handleScheduleMeeting(item)}
                                    style={{ marginRight: '10px' }}
                                >
                                    {item.date ? 'Modify meeting' : 'Schedule meeting'}
                                </NudgeButton>
                            )}
                            {item.isPreviousComplete || item.current ? (
                                <NudgeButton
                                    disabled={isValidating || sending}
                                    onClick={() => handleToggleCompletion(item)}
                                >
                                    {item.complete ? 'Mark as incomplete' : 'Mark as complete'}
                                </NudgeButton>
                            ) : null}
                        </div>
                    )}
                </Col>
            </Row>
        );
    }

    if (extData && !extData.neotalent) {
        return <div>This feature is not available for your account.</div>;
    }

    return (
        <>
            <ScheduleModal item={schedulingItem} toggle={toggle} mutate={mutate} resultsUrl={resultsUrl} />
            <div style={{ display: 'block', width: userId ? '100%' : '90%' }}>
                <Row>
                    <Col xs={12} lg={6}>
                        <h2 className={style.offerListHeader}>Candidate Milestones</h2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <div className={style.progressBarContainer}>
                            {!sending && !isValidating ? (
                                <div className={style.progressBar} style={{ width: `${calculatePercentage()}%` }}>
                                    {calculatePercentage() === 100 ? 'Done' : `${calculatePercentage().toFixed(0)}%`}
                                </div>
                            ) : (
                                <div className={'w-100 h-100 flex-center-align-justify loading'}>
                                    <FontAwesomeIcon icon={['far', 'sync-alt']} />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>

                <Card
                    style={{
                        display: 'block',
                        overflow: 'visible',
                        background: 'none',
                    }}
                    className={style.projectCard}
                >
                    {data?.checklist?.map((item) => displayStep(item))}
                </Card>
            </div>
        </>
    );
};

export default ContractorDashProjectProgress;
