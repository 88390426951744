import PropTypes from 'prop-types';
import React from 'react';

import style from './style.module.scss';

const PlusButton = ({ onClick }) => (
    <a onClick={onClick} data-testid="plus-button" className={`${style.plus} text-success`}>
        Add
    </a>
);

PlusButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default PlusButton;

export const usePlusButtonWithAlwaysMinus = (formik) => {
    const onClickPlusButton =
        (field, value = [{}]) =>
        () =>
            formik.setFieldValue(field, value);

    const isPlusButtonShowed = (field) => formik.values[field].length < 1;

    return { onClickPlusButton, isPlusButtonShowed };
};
