import { createContext } from 'react';

import {
    API_DASHBOARD_ADMIN,
    API_DASHBOARD_COMPANY,
    API_DASHBOARD_CONTRACTOR,
    API_DASHBOARD_RECRUITER,
    URLS,
} from '../../consts/urls';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../consts/userRoles';
export const AuthContext = createContext({});

export const getApiUrl = (roles = []) => {
    if (roles.length) {
        if (roles.includes(USER)) {
            return API_DASHBOARD_CONTRACTOR;
        } else if (roles.includes(COMPANY)) {
            return API_DASHBOARD_COMPANY;
        } else if (roles.includes(ADMIN)) {
            return API_DASHBOARD_ADMIN;
        } else if (roles.includes(RECRUITER)) {
            return API_DASHBOARD_RECRUITER;
        }
    }
};

export const prepareUser = (user) => {
    const unifiedUser = { ...user };
    unifiedUser.photo = user.photo || user.logo;
    unifiedUser.name = user.name || user.username;

    return unifiedUser;
};

export const getRedirectUrl = (roles) => {
    if (roles.includes(USER)) {
        return URLS.DASHBOARD;
    } else if (roles.includes(COMPANY)) {
        return URLS.COMPANY_DASHBOARD;
    } else if (roles.includes(ADMIN)) {
        return URLS.ADMIN_DASHBOARD;
    } else if (roles.includes(RECRUITER)) {
        return URLS.RECRUITER_DASHBOARD;
    } else {
        return URLS.SIGN_IN;
    }
};
