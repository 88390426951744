import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { authFetcher, authPostDataWithNoResponse, NEVER_REFRESH } from '../../../api/tools';
import GenericTable from '../../../components/GenericTable';
import { renderNameCellWithId, sortByDate, renderAmountCell } from '../../../components/GenericTable/helpers';
import Loader from '../../../components/Loader';
import ActionOrBackModal from '../../../components/modals/ActionOrBackModal';
import { CONTRACT_STATUSES } from '../../../consts/contractStatuses';
import { API_GATEWAY, URLS } from '../../../consts/urls';
import style from './style.module.scss';

const columnSizeByIndex = [2, 2, 2, 2, 1, 1, 2];
const contractsURL = `${API_GATEWAY}/InvoiceCompany/Contracts`;

const ContractorsTimesheet = () => {
    const { data } = useSWR(contractsURL, authFetcher, NEVER_REFRESH);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedContractId, setSelectedContractId] = useState(null);

    const closeContract = async () => {
        await authPostDataWithNoResponse(null, `${API_GATEWAY}/InvoiceCompany/Contracts/${selectedContractId}/Close`);
        setIsPopupOpen(false);
        setSelectedContractId(null);
        mutate(contractsURL);
    };

    const selectContract = (contractId) => {
        setSelectedContractId(contractId);
        setIsPopupOpen(true);
    };

    const columns = useMemo(() => {
        const renderActionCell = ({ cell }) => (
            <>
                <div>
                    <Link to={`${URLS.COMPANY_CONTRACT_TIMESHEETS}/${cell.row.original.id}`}>View timesheets</Link>
                </div>
                {cell.row.original.status === CONTRACT_STATUSES.Open && (
                    <div className={style.linkClose}>
                        <Link onClick={() => selectContract(cell.row.original.id)}>Close contract</Link>
                    </div>
                )}
            </>
        );
        return [
            {
                Header: 'Contract Name',
                accessor: 'title',
                Cell: renderNameCellWithId(),
            },
            {
                Header: 'Contractor Name',
                accessor: 'contractorName',
            },
            {
                Header: 'Start Date',
                accessor: 'contractStartDate',
                sortType: sortByDate('contractStartDate'),
            },
            {
                Header: 'Finish Date',
                accessor: 'contractEndDate',
                sortType: sortByDate('contractEndDate'),
            },
            {
                Header: 'Offer Price',
                accessor: 'amount',
                Cell: renderAmountCell,
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Action',
                disableSortBy: true,
                Cell: renderActionCell,
            },
        ];
    }, []);

    return (
        <>
            {data ? (
                <>
                    <GenericTable
                        columns={columns}
                        data={data.contracts}
                        columnSizeByIndex={columnSizeByIndex}
                        className={style.table}
                    />
                    <ActionOrBackModal
                        isOpen={isPopupOpen}
                        onGoBack={() => setIsPopupOpen(false)}
                        buttonText="Yes, close this contract"
                        text="Are you sure you want to close this contract?"
                        backText="Cancel"
                        onClick={() => closeContract()}
                        header="Confirmation required"
                        toggle={() => {}}
                    />
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default ContractorsTimesheet;
