import { Form } from 'formik';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';

import SaveButton from '../../../buttons/SaveButton';
import AvailabilityForm from '../../../forms/AvailabilityForm';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';

const EditableCalendar = ({ formik, onClose }) => {
    const theme = GetPageTheme();
    return (
        <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <Card id={`card-${theme}`} style={{ padding: '12px' }}>
                <AvailabilityForm formik={formik} />
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <SaveButton onClose={onClose} />
                </div>
            </Card>
        </Form>
    )
};

EditableCalendar.propTypes = {
    formik: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditableCalendar;
