import * as Yup from 'yup';

export const FIELDS = {
    CERTIFICATIONS: 'certifications',
};

export const validationSchema = Yup.object({
    [FIELDS.CERTIFICATIONS]: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required('Name field is required'),
                expireDate: Yup.date().required('Date field is required'),
                file: Yup.mixed().required('Upload is required'),
            }),
        )
        .required(),
});

export const initialValues = {
    [FIELDS.CERTIFICATIONS]: [{ name: '', expireDate: '' }],
};
