import React from 'react';

import style from './style.module.scss';
import { Col, Row } from 'reactstrap';

import BackButton from '../../components/buttons/BackButton';

const ErrorPage = () => {
    
    if(1 == 0) {
        return (
            <>
            
            </>
        )
    } else {
        return (
            <div className={style.errorBackground}>
                <Row>
                    <Col xs={12} md={3}>
                        <h5 className={style.errorHeader}>Error 404</h5>
                    </Col>
                    <Col xs={12} md={9}>
                        <h5 className={style.errorText}>
                            { "Once upon a midnight dreary,\n" 
                                + "while I pondered weak and weary,\n"
                                + "Over many a quaint and curious\n"
                                + "volume of forgotten lore,\n\n"
    
                                + "While I nodded, nearly napping,\n"
                                + "suddenly there came a tapping,\n"
                                + "As of some one gently rapping,\n"
                                + "rapping at my chamber door.\n\n"
    
                                + "'Tis some visitor,' I muttered,\n"
                                + "'tapping at my chamber door -\n"
                                + "Only this, and nothing more.'\n\n"
    
                            + "Page not found: 404.\n\n"
                            
                            + "Something went wrong. If this issue persists,\n contact us at nudgeme@nudgeexchange.com"}
                        </h5>
                        <p className={style.errorSubtitle}>Please contact us at: <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgeexchange.com</a> to let us know.</p>
                        <BackButton steps={3} show/>
                    </Col>
                </Row>
            </div>
        )
    }

    
};

export default ErrorPage;
