import React, { useContext } from 'react';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import brooke from '../../assets/images/brooke.png';
import companyLogoKOWL from '../../assets/images/company_logos/kowl.png';
import companyLogoMagnora from '../../assets/images/company_logos/magnora.png';
import companyLogoMoray from '../../assets/images/company_logos/moray.png';
import companyLogoOcean from '../../assets/images/company_logos/ocean.png';
import companyLogoOrsted from '../../assets/images/company_logos/orsted.png';
import companyLogoScottishPower from '../../assets/images/company_logos/scottish_power.png';
import companyLogoSSE from '../../assets/images/company_logos/sse.png';
import companyLogoVattenfall from '../../assets/images/company_logos/vattenfall.png';
import sea from '../../assets/images/sea.png';
import gradient from '../../assets/videos/GRAD_HD_low.mp4';
import lightbulb from '../../assets/videos/Lightbulb.mp4';
import person from '../../assets/videos/Person.mp4';
import turbines from '../../assets/videos/Turbines.mp4';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import AppNavbar from './AppNavbar';
import Footer from './Footer';
import style from './style.module.scss';

const HomePage = () => {
    const { user, isAuth } = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <div>
            <AppNavbar />
            <Helmet>
                <title>Home Page - Nudge Energy Talent Lab</title>
            </Helmet>

            <section>
                <video autoPlay muted loop className={style.videobg}>
                    <source id="gradient" src={gradient} type="video/mp4" />
                </video>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={style.firstSection}>
                                <h1>{t('home.title')}</h1>
                                <h3 style={{ marginTop: '5vh' }}>{t('home.subtitle')}</h3>
                                <div style={{ marginTop: '5vh' }}>
                                    {!isAuth && !user ? (
                                        <Link to="/sign-up" className={style.button}>
                                            <span>Join our club →</span>
                                        </Link>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionSupport}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <div className={style.subHeading}>
                                We support, empower and push you into the career you want.
                            </div>
                            <img src={brooke} alt="Brooke" className={style.brookeMobile} />
                            <h3>
                                You&apos;re ambitious, and so are the companies you seek. Our carbon-neutral career
                                platform uses data science and human expertise to reduce bias and find better matches
                                for candidates and companies.
                            </h3>
                        </Col>
                        <img src={brooke} alt="Brooke" className={style.brookeDesktop} />
                    </Row>
                </Container>
            </section>

            <section className={style.sectionHow}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{ textAlign: 'center', margin: '10vh 0 10vh 0' }}>How Nudge Works</h3>
                            <div style={{ textAlign: 'center' }}>
                                <Carousel showDots cols={1} loop>
                                    {!isAuth && !user ? (
                                        <Carousel.Item>
                                            <div className={style.howItemContainer}>
                                                <span className={style.howItem}>
                                                    Sign up for free &amp; create your profile
                                                </span>
                                            </div>
                                        </Carousel.Item>
                                    ) : null}
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span className={style.howItem}>
                                                Search full or part time, freelance &amp; pro-bono roles.
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span className={style.howItem}>
                                                Get job alerts that match your skills.
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span className={style.howItem}>
                                                Offer feedback for roles that aren&apos;t the right fit.
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span className={style.howItem}>
                                                Schedule video interviews &amp; set reminders.
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.howItemContainer}>
                                            <span className={style.howItem}>
                                                Use feedback to make your applications stronger
                                            </span>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionGuide}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div
                                className={`${style.subHeading} ${style.subTextSerif}`}
                                style={{ textAlign: 'center' }}
                            >
                                Every hero needs a guide
                            </div>

                            <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <div className={style.guideItem}>
                                        <div className={style.guideItemVideo}>
                                            <video className={style.guideVideo} autoPlay muted loop id="lightbulb">
                                                <source id="lightbulb" src={lightbulb} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className={style.guideItemHeading}>Your next move</div>
                                        <div className={style.guideItemText}>
                                            Whether it&apos;s a full-time position, project by project work or a
                                            pro-bono gig. You&apos;ll find it on Nudge.
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <div className={style.guideItem}>
                                        <div className={style.guideItemVideo}>
                                            <video className={style.guideVideo} autoPlay muted loop id="person">
                                                <source id="person" src={person} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className={style.guideItemHeading}>Human powered</div>
                                        <div className={style.guideItemText}>
                                            You&apos;ll get help from our experts who will suggest opportunities to
                                            match your skills. You&apos;ll receive constructive feedback for every
                                            submit.
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <div className={style.guideItem}>
                                        <div className={style.guideItemVideo}>
                                            <video className={style.guideVideo} autoPlay muted loop id="turbines">
                                                <source id="turbines" src={turbines} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className={style.guideItemHeading}>Engineering insiders</div>
                                        <div className={style.guideItemText}>
                                            We know the energy industry. That&apos;s why we&apos;ve built a platform
                                            dedicated to STEM professionals.
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <div style={{ marginTop: '10vh', textAlign: 'center' }}>
                                {!isAuth && !user ? (
                                    <Link to="/sign-up" className={style.buttonChalk}>
                                        <span>Find your future →</span>
                                    </Link>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionCompanyLogos}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{ textAlign: 'center', margin: '10vh 0 10vh 0' }}>
                                Here are some of the hand-picked industry leaders we work with:
                                <p class="mobile-only" style={{ fontSize: '1.5rem', marginTop: '12px' }}>Swipe to view more.</p>
                            </h3>

                            <div style={{ textAlign: 'center', marginBottom: '10vh' }}>
                                <Carousel showDots cols={4} loop>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoMagnora} alt="Acuation Lab logo" />
                                            <p>1</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoMoray} alt="Acuation Lab logo" />
                                            <p>2</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoOcean} alt="Acuation Lab logo" />
                                            <p>3</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoScottishPower} alt="Acuation Lab logo" />
                                            <p>4</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoSSE} alt="Acuation Lab logo" />
                                            <p>5</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoVattenfall} alt="Acuation Lab logo" />
                                            <p>6</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoKOWL} alt="Acuation Lab logo" />
                                            <p>7</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className={style.companyLogo}>
                                            <img src={companyLogoOrsted} alt="Acuation Lab logo" />
                                            <p>8</p>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionGoodEnergy} style={{ paddingTop: '10vh' }}>
                <Container>
                    <Row>
                        <Col xs={12} md={5} xl={4}>
                            <div className={style.subHeading} style={{ paddingTop: 0 }}>
                                Good energy club
                            </div>
                            <div className={style.subText} style={{ marginTop: '40px' }}>
                                We believe that the energy sector can tackle the problems our world faces rather than
                                create them. Our carbon neutral service supports areas at the front line of the climate
                                crisis.
                            </div>
                            <div className={style.subsubText} style={{ marginTop: '40px' }}>
                                We work with non-profit organisations and continually question industry norms to open up
                                the job application process.
                            </div>
                            <div style={{ marginTop: '50px', marginBottom: '40px' }}>
                                <Link to="/about" className={style.button}>
                                    <span>Find out more →</span>
                                </Link>
                            </div>
                        </Col>
                        {/*<Col xl={1}></Col>*/}
                        <Col xs={12} md={7} xl={8}>
                            <img src={sea} className={style.sea} alt="The ocean" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={style.sectionGetStarted}>
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <div className={style.subHeading}>We search. We connect. We nudge.</div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <div style={{ marginTop: '12vh', textAlign: 'center' }}>
                                {!isAuth && !user ? (
                                    <Link to="/sign-up" className={style.buttonChalk}>
                                        <span>Let&apos;s get started →</span>
                                    </Link>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    );
};

export default HomePage;
