import cn from 'classnames';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Label } from 'reactstrap';

import useSWR from 'swr';
import { API_DASHBOARD_CONTRACTOR } from '../../../consts/urls';
import { datalistArray } from '../../../consts/skills';
import { authFetcher, NO_REFRESH_INTERVAL } from '../../../api/tools';

import AutocompleteInput from '../../AutocompleteInput';
import ArrayFieldWithPlusAndMinus from '../ArrayFieldWithPlusAndMinus';
import SkipAndSubmit from '../SkipAndSubmit';
import { FIELDS } from './validation';
import SelectField from '../../SelectField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewArrayFieldForm from '../NewArrayFieldForm';
import { convertNumber } from '../NewInputForm/helpers';
import { capitaliseFirstLetter } from '../../pages/GenericCompanyProfile/HelperComponents';

export const skillIconOptions = [
    { icon: 'brain', label: 'Problem Solving', altValue: 'problemSolving' },
    { icon: 'desktop', label: 'Computing', altValue: 'computing' },
    { icon: 'wrench', label: 'Engineering', altValue: 'engineering' },
    { icon: 'wind-turbine', label: 'Off-Shore Wind', altValue: 'offshoreWind' },
    { icon: 'server', label: 'Network Engineering', altValue: 'networkEngineering' },
];

const getErrorForField = (formik, index) =>
    formik.touched[FIELDS.SKILLS] &&
    formik.touched[FIELDS.SKILLS][index] &&
    formik.errors[FIELDS.SKILLS] &&
    formik.errors[FIELDS.SKILLS][index];
var comboArray = [];

const addToDatalist = (d) => {
    var newArray = [];

    d.forEach((element) => {
        if (!newArray.includes(element.name)) newArray[newArray.length] = element.name;
    });
    comboArray = datalistArray.concat(newArray);
    comboArray = [...new Set(comboArray)];
    comboArray.sort();
};

const ArrayField = ({ index, formik }) => {
    const LIMIT = 10;
    const { data } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Skills/All`, authFetcher, NO_REFRESH_INTERVAL);

    if (data) {
        addToDatalist(data);
    }

    let hasError = null;
    if (formik.errors[FIELDS.SKILLS]) {
        if (formik.errors[FIELDS.SKILLS][index] && formik.submitCount > 0) {
            hasError = formik.errors[FIELDS.SKILLS][index];
        }
    }

    

    const iconArr = skillIconOptions.map((item) => ({ label: <><FontAwesomeIcon icon={['fas', item.icon]}/> {capitaliseFirstLetter(item.label)}</>, value: item.icon }))
    

    const body = (<>
        <Col xs={12} sm={5} md={5} lg={5}>
            <Label style={{ display: 'none' }}>Skill Icon</Label>
            <Field
                name={`${FIELDS.SKILLS}.${index}.icon`}
                component={SelectField}
                placeholder={"Icon"}
                options={iconArr}

            />
        </Col>
        <Col xs={12} sm={7} md={7} lg={7}>
            <Label style={{ display: 'none' }}>Skill Name</Label>
            <Field
                name={`${FIELDS.SKILLS}.${index}.name`}
                component={AutocompleteInput}
                suggestions={comboArray}
                placeholder={`SKILL ${convertNumber(index+1).toUpperCase()}`}
                className={cn('form-control', {
                    'is-invalid': getErrorForField(formik, index, 'name'),
                })}
                error={hasError}
            />
        </Col>
    </>);

    const errors = (
        <Col xs={8} md={{ size: 9 }}>
            <h5 style={{ color: 'red', fontSize: '1rem', paddingTop: '4px' }}>{hasError ? hasError.name : null}</h5>
        </Col>
    );

    const addValue = { icon: 'brain', name: '' }
    return (
        <NewArrayFieldForm
            formik={formik}
            index={index}
            field={FIELDS.SKILLS}
            addValue={addValue}
            fieldLimit={12}
            showRemove
            showAdd={true}
        >
            {body}
        </NewArrayFieldForm>
    );
};

const SkillsFormBody = ({ formik, skipCurrentStep, alwaysShowMinus }) => (
    <FieldArray name={FIELDS.SKILLS}>
        {(arrayHelpers) => (
            <>
                {formik.values[FIELDS.SKILLS].map((_, index) => (
                    <ArrayField
                        key={index}
                        index={index}
                        arrayHelpers={arrayHelpers}
                        formik={formik}
                        alwaysShowMinus={alwaysShowMinus}
                    />
                ))}

                {skipCurrentStep ? <SkipAndSubmit skipCurrentStep={skipCurrentStep} formik={formik} /> : null}
            </>
        )}
    </FieldArray>
);

SkillsFormBody.propTypes = {
    formik: PropTypes.object.isRequired,
    skipCurrentStep: PropTypes.func.isRequired,
    alwaysShowMinus: PropTypes.bool,
};

export default SkillsFormBody;
