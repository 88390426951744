import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'ref_name',
    REFERENCE: 'reference',
};

export const validationSchema = Yup.object({
    [FIELDS.REFERENCE]: Yup.string().required('This field is required.'),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.REFERENCE]: '',
};
