import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import useSWR from 'swr';

import { NEVER_REFRESH, NO_REFRESH_INTERVAL, authFetcher } from '../../../api/tools';
import { RECRUITER, USER } from '../../../consts/userRoles';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import { parseDate } from '../../../tools/dateHelpers';
import BackButton from '../../buttons/BackButton';
import ContractorInformation from '../../ContractorInformation';
import Loader from '../../Loader';
import SignInPageTemplate from '../../SignInPageTemplate';
import Calendar from './Calendar';
import CertificatesAndQualifications from './CertificatesAndQualifications';
import { getContractorDashboardUrl, getContractorProfiledUrl, getContractorExtUrl } from './helper';
import ProjectHistory from './ProjectHistory';
import Education from './Education';
import Skills from './Skills';
import style from './style.module.scss';
import Values from './Values';
import CvParser from './CvParser';
import { countNew } from '../../SignInPageTemplate/SignInNavbar/NotificationsModal';
import ReadyPlayerMeModal from './ReadyPlayerMeModal';
import { GetPageTheme } from '../GenericCompanyProfile/HelperComponents';
import ContractorDashProjectProgress from '../../ContractorDashProjectProgress';
import NeotalentToggle from '../../NeotalentToggle';

export const ContractorProfilePage = ({ showEdit, additions, showResume }) => {
    const { id } = useParams();
    const { user, userRoles } = useContext(AuthContext);

    const [errorCount, setErrorCount] = React.useState(0);
    const { data, mutate, isValidating } = useSWR(getContractorDashboardUrl(userRoles, id), authFetcher, NEVER_REFRESH);
    const { data: extData, mutate: extMutate } = useSWR(getContractorExtUrl(userRoles, id), authFetcher, NEVER_REFRESH);


    const [key, setKey] = React.useState(false);
    const toggle = () => {
        setKey(!key);
    };

    const theme = GetPageTheme();
    const props = { showEdit, refresh: mutate, url: getContractorProfiledUrl(userRoles, id), changeKey: key, theme: theme };

    var newNotifications = 0;
    if (user && userRoles) {
        if (!userRoles.includes('Admin')) {
            newNotifications = countNew(user.notifications);
        }
    }

    return (
        <SignInPageTemplate>
            {data ? <>
                {additions}

            <Helmet>
                <title>
                    {user ? (newNotifications.length ? `(${newNotifications})` : ``) : ``}{' '}
                    {`${data.username || 'User'} - Nudge Energy Talent Lab`}{' '}
                </title>
            </Helmet>
            <Row className={style.contractorProfile}>
                <Col md={12} lg={8}>
                    {userRoles.includes('User') ? 
                        <CvParser user={user} mutate={mutate} toggle={toggle} /> 
                    : null}

                    <ContractorInformation
                        user={data}
                        resume={data.resumeUrl}
                        expectedEarnings={data.expectedEarnings}
                        company={data.company}
                        job={data.job}
                        location={data.location}
                        showResume={showResume}
                        email={data.email}
                        videoLink={data.videoLink}
                        {...props}
                    />
                    <Skills user={data} skills={data.skills} showEdit {...props} />
                    
                    <Education user={data} qualification={data.education} showEdit {...props} />
                    
                    <CertificatesAndQualifications
                        {...props}
                        user={data}
                        certificates={data.certificates}
                    />
                            
                    <ProjectHistory user={data} steps={data.experience} {...props} />
                </Col>
                <Col md={12} lg={4}>
                    <Calendar
                        {...props}
                        user={data}
                        startDate={data.availableFrom ? parseDate(data.availableFrom) : null}
                        endDate={data.availableFrom ? parseDate(data.availableTo) : null}
                        hours={data.availableHours}
                    />

                    <Values {...props} user={data} data={data.values} />
                    <br/>

                    { userRoles.includes('Admin') ? <NeotalentToggle user={data} extUser={extData} mutate={extMutate} /> : null }
                </Col>
            </Row>
    </> : isValidating ? <Loader fullPage/> : <h5>Cannot find this candidate.</h5> }
        </SignInPageTemplate> 
)};

export default withAuthProtectedRouter(ContractorProfilePage, [USER, RECRUITER]);
