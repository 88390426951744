import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from './style.module.scss';
import ReadyPlayerMeFrame from './ReadyPlayerMeFrame';
import useMobile from '../../../../hooks/useMobile';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../buttons/NudgeButton';
import NudgeModal from '../../../NudgeModal';

const ReadyPlayerMeModal = ({ avatar }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    React.useEffect(() => {
        if (avatar.get) {
        }
    }, [avatar.get]);

    const { isMobileWide } = useMobile();

    return (
        <div style={{ width: '100%' }}>
            <ClickButton style={{ width: '100%' }} className={style.button} color="success" onClick={toggle}>
                {avatar.get ? 'Edit' : 'Create'}
            </ClickButton>
            <NudgeModal headerText={"Customise your avatar"} id={`modal-${GetPageTheme()}`} isOpen={!!isOpen} toggle={toggle} className={`${style.modal}`} centered>
                <ModalBody className={style.modalBody}>
                    {isMobileWide ? <h5>Feature does not work on mobile.</h5> : <ReadyPlayerMeFrame setUrl={avatar.set} />}
                    <br />

                    <h5 className="text-center">
                        {avatar.get
                            ? 'Your ReadyPlayerMe avatar has been received. Please feel free to close this pop-up. Click your profile picture to use the avatar as your profile photo.'
                            : null}
                    </h5>
                </ModalBody>
            </NudgeModal>
        </div>
    );
};

export default ReadyPlayerMeModal;
