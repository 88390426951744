import React from 'react';

import { FIELDS, initialObject } from '../../validation';
import OfferField from '../../OfferField';

import style from '../../style.module.scss';
import { Col, Row } from 'reactstrap';
import { Field } from 'formik';
import SelectField from '../../../../../../components/SelectField';


import RequiredBox from '../../RequiredBox';
import { usePlusButtonWithAlwaysMinus } from '../../../../../../components/buttons/PlusButton';
import NudgeButton from '../../../../../../components/buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NudgeTabs from '../../../../../../components/NudgeTabs';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import QuestionsForm from '../../../../../../components/forms/QuestionsForm';

import {
    technologyOptions,
    socialIntelligenceOptions,
    innovationOptions,
    selfManagementOptions,
    YesNoOptions
} from '../../options';


const Locked = (text, disabled) => {
    return (
        <>
            {text}
            <FontAwesomeIcon icon={[`far`, disabled ? `lock-open` : `lock`]} className={style.padlock} />
        </>
    );
};


const Recruitment = ({ formik, isEdit, tooltip, recruitObj, matches }) => {
    
    const { isPlusButtonShowed, onClickPlusButton } = usePlusButtonWithAlwaysMinus(formik);
    const [selectedInterviewTab, setSelectedInterviewTab] = React.useState();
    const theme = GetPageTheme();
    
    const matchTypeOptions = [
        { label: 'No', value: false },
        { label: Locked('Yes', recruitObj.willo), value: true, isDisabled: !recruitObj.willo },
    ];

    const hasEdifyOptions = [
        { label: 'No', value: false },
        { label: Locked('Yes', recruitObj.edify), value: true, isDisabled: !recruitObj.edify },
    ];

    function toggleAssessmentSkill(skill, section) {
        var assessed_skills = formik.values[FIELDS.ASSESSMENT_SKILLS];

        if (assessed_skills[section].includes(skill)) {
            assessed_skills[section] = assessed_skills[section].filter((i) => i != skill);
        } else {
            assessed_skills[section].push(skill);
        }

        formik.setFieldValue(FIELDS.ASSESSMENT_SKILLS, assessed_skills);
    }

    function checkForSkill(skill, section) {
        var assessed_skills = formik.values[FIELDS.ASSESSMENT_SKILLS];

        if (assessed_skills) {
            var findSkill = assessed_skills[section].find((i) => i == skill);

            return findSkill ? true : false;
        } else {
            return false;
        }
    }
    
    React.useEffect(() => {
        if (formik.values[FIELDS.MATCH_TYPE]) {
            if (!formik.values[selectedInterviewTab]) setSelectedInterviewTab(FIELDS.MATCH_TYPE);
        } else if (formik.values[FIELDS.HAS_EDIFY]) {
            if (!formik.values[selectedInterviewTab]) setSelectedInterviewTab(FIELDS.HAS_EDIFY);
        } else if (formik.values[FIELDS.FACE_TO_FACE]) {
            if (!formik.values[selectedInterviewTab]) setSelectedInterviewTab(FIELDS.FACE_TO_FACE);
        } else {
            setSelectedInterviewTab();
        }
    }, [formik.values[FIELDS.MATCH_TYPE], formik.values[FIELDS.HAS_EDIFY], formik.values[FIELDS.FACE_TO_FACE]]);

    return (<>
        {isEdit && !formik.values[FIELDS.MATCH_TYPE] && !formik.values[FIELDS.QUESTIONS].length && !formik.values[FIELDS.FACE_TO_FACE] ? (
            <h5 className="text-center">
                No additional interview features associated with this offer.
            </h5>
        ) : null}
        <Row>
            <Col md={4}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    label="Video Interview?"
                    click={() => tooltip.set(FIELDS.MATCH_TYPE)}
                >
                    <Field
                        name={FIELDS.MATCH_TYPE}
                        component={SelectField}
                        options={matchTypeOptions}
                        placeholder="Match Type"
                    />
                </OfferField>
            </Col>
            <Col md={4}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    label="VR Assessment?"
                    click={() => tooltip.set(FIELDS.HAS_EDIFY)}
                >
                    <Field
                        name={FIELDS.HAS_EDIFY}
                        component={SelectField}
                        options={hasEdifyOptions}
                        placeholder="VR Assessment?"
                    />
                </OfferField>
            </Col>
            <Col md={4}>
                <OfferField
                    selected={tooltip.get}
                    isEdit={isEdit}
                    label="Face to Face Interview?"
                    click={() => tooltip.set(FIELDS.FACE_TO_FACE)}
                >
                    <Field
                        name={FIELDS.FACE_TO_FACE}
                        component={SelectField}
                        options={YesNoOptions}
                        placeholder="Face to Face Interview"
                    />
                </OfferField>
            </Col>
        </Row>

        <Row>
            <Col md={12}>
                <OfferField>
                    <NudgeTabs
                        label="Interview Steps"
                        selected={selectedInterviewTab}
                        setTab={setSelectedInterviewTab}
                        tabs={[
                            { id: FIELDS.MATCH_TYPE, label: 'Video Interview', condition: !!formik.values[FIELDS.MATCH_TYPE], error: !!formik.errors[FIELDS.QUESTIONS] },
                            { id: FIELDS.HAS_EDIFY, label: 'VR Assessment', condition: !!formik.values[FIELDS.HAS_EDIFY] },
                            { id: FIELDS.FACE_TO_FACE, label: 'Face-to-Face', condition: !!formik.values[FIELDS.FACE_TO_FACE] },
                        ]}
                    />
                </OfferField>
            </Col>
        </Row>

        {selectedInterviewTab == FIELDS.MATCH_TYPE ? (<>
            
            { !matches?.length && <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label="Questions"
                click={() => tooltip.set(FIELDS.QUESTIONS)}
            >
                    <>
                        <Row>
                            { !formik.values[FIELDS.QUESTIONS]?.length ? <Col md={6}>
                                <Row>
                                    <Col xs={4} md={3}>
                                        <h5>Limit: 0/8</h5>
                                    </Col>
                                    <Col xs={8} md={9}>
                                        { typeof formik.errors[FIELDS.QUESTIONS] == 'string' ? <h5 className={theme == 'light' ? style.errorText : style.errorTextDark}>{formik.errors[FIELDS.QUESTIONS]}</h5>  : ''}
                                    </Col>
                                </Row>
                            </Col> : null }
                            <Col md={6}>
                                {isPlusButtonShowed(FIELDS.QUESTIONS) ? <div class="flexy" style={{ columnGap: '6px' }}>
                                    <NudgeButton disabled={formik.values[FIELDS.QUESTIONS].length >= 8} style={{ width: '100%' }} tabindex="-1" color="success" onClick={onClickPlusButton(FIELDS.QUESTIONS, [initialObject[FIELDS.QUESTIONS]])}>
                                        +
                                    </NudgeButton>
                                    <NudgeButton disabled={!formik.values[FIELDS.QUESTIONS].length} style={{ width: '100%' }} tabindex="-1" color="danger" onClick={() => { formik.setFieldValue(field, []) }}>
                                        Clear All
                                    </NudgeButton>
                                </div> : null }
                            </Col>
                        </Row>

                        {formik.values[FIELDS.QUESTIONS].length ? (
                            <QuestionsForm
                                name={FIELDS.QUESTIONS}
                                basicName={FIELDS.QUESTIONS}
                                getName={(index) => `${FIELDS.QUESTIONS}.${index}`}
                                formik={formik}
                                addValue={initialObject[FIELDS.QUESTIONS]}
                                alwaysShowMinus
                            >
                                <div className={style.confirmBox}> { formik.values[FIELDS.QUESTIONS].map((item) => item.type).includes("idv") ? 
                                    <RequiredBox formik={formik} basicName={FIELDS.CONFIRM} label={"I understand that right-to-work checks are a paid feature of Nudge and will be invoiced accordingly."} value={"Y"}/>
                                : null } </div>
                            </QuestionsForm>
                        ) : null }
                    </>
            </OfferField> }

            { !!matches?.length && <h5 className="text-center mb-50">Questions frozen.</h5> }
            
        </>) : selectedInterviewTab == FIELDS.HAS_EDIFY ? (
            <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label=""
                click={() => tooltip.set(FIELDS.HAS_EDIFY)}
            >
                <Row>
                    <Col md={3}>
                        <h5 className="text-center">Self Management</h5>
                        {selfManagementOptions.map((item) => (
                            <p
                                className={
                                    theme == 'light' ? checkForSkill(item, 'selfManagement')
                                    ? style.selectedAssessmentSkill
                                    : style.assessmentSkill : checkForSkill(item, 'selfManagement')
                                    ? style.selectedAssessmentSkillDark
                                    : style.assessmentSkillDark
                                }
                                onClick={() => toggleAssessmentSkill(item, 'selfManagement')}
                            >
                                <Row>
                                    <Col sm={10} md={9}>{item}</Col>
                                    { checkForSkill(item, 'selfManagement') ? <Col sm={2} md={3}>
                                        <FontAwesomeIcon
                                            icon={[
                                                'far',
                                                checkForSkill(item, 'selfManagement')
                                                    ? 'check-circle'
                                                    : '',
                                            ]}
                                        />
                                    </Col> : null }
                                </Row>
                            </p>
                        ))}
                    </Col>
                    <Col md={3}>
                        <h5 className="text-center">Social Intelligence</h5>
                        {socialIntelligenceOptions.map((item) => (
                            <p
                                className={
                                    theme == 'light' ? checkForSkill(item, 'socialIntelligence')
                                    ? style.selectedAssessmentSkill
                                    : style.assessmentSkill : checkForSkill(item, 'socialIntelligence')
                                    ? style.selectedAssessmentSkillDark
                                    : style.assessmentSkillDark
                                }
                                onClick={() => toggleAssessmentSkill(item, 'socialIntelligence')}
                            >
                                <Row>
                                    <Col sm={10} md={9}>{item}</Col>
                                    { checkForSkill(item, 'socialIntelligence') ? <Col sm={2} md={3}>
                                        <FontAwesomeIcon
                                            icon={[
                                                'far',
                                                checkForSkill(item, 'socialIntelligence')
                                                    ? 'check-circle'
                                                    : '',
                                            ]}
                                        />
                                    </Col> : null }
                                </Row>
                            </p>
                        ))}
                    </Col>
                    <Col md={3}>
                        <h5 className="text-center">Innovation</h5>
                        {innovationOptions.map((item) => (
                            <p
                                className={
                                    theme == 'light' ? checkForSkill(item, 'innovation')
                                    ? style.selectedAssessmentSkill
                                    : style.assessmentSkill : checkForSkill(item, 'innovation')
                                    ? style.selectedAssessmentSkillDark
                                    : style.assessmentSkillDark
                                }
                                onClick={() => toggleAssessmentSkill(item, 'innovation')}
                            >
                                <Row>
                                    <Col sm={10} md={9}>{item}</Col>
                                    { checkForSkill(item, 'innovation') ? <Col sm={2} md={3}>
                                        <FontAwesomeIcon
                                            icon={[
                                                'far',
                                                checkForSkill(item, 'innovation')
                                                    ? 'check-circle'
                                                    : '',
                                            ]}
                                        />
                                    </Col> : null }
                                </Row>
                            </p>
                        ))}
                    </Col>
                    <Col md={3}>
                        <h5 className="text-center">Discipline</h5>
                        {technologyOptions.map((item) => (
                            <p
                                className={
                                    theme == 'light' ? checkForSkill(item, 'discipline')
                                    ? style.selectedAssessmentSkill
                                    : style.assessmentSkill : checkForSkill(item, 'discipline')
                                    ? style.selectedAssessmentSkillDark
                                    : style.assessmentSkillDark
                                }
                                onClick={() => toggleAssessmentSkill(item, 'discipline')}
                            >
                                <Row>
                                    <Col sm={10} md={9}>{item}</Col>
                                    { checkForSkill(item, 'discipline') ? <Col sm={2} md={3}>
                                        <FontAwesomeIcon
                                            icon={[
                                                'far',
                                                checkForSkill(item, 'discipline')
                                                    ? 'check-circle'
                                                    : '',
                                            ]}
                                        />
                                    </Col> : null }
                                </Row>
                            </p>
                        ))}
                    </Col>
                </Row>
            </OfferField>
        ) : selectedInterviewTab == FIELDS.FACE_TO_FACE ? (
            <OfferField
                selected={tooltip.get}
                isEdit={isEdit}
                showOnEdit
                label=""
                click={() => tooltip.set(FIELDS.FACE_TO_FACE)}
            >
                Under Construction
            </OfferField>
        ) : null}
    </>)
}

export default Recruitment;