import React from 'react';

import { authFetcher } from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';
import SearchBox from '../../../../../components/SearchBox';
import { API_DASHBOARD_ADMIN } from '../../../../../consts/urls';
import CompanyCard from './CompanyCard';

const ManageCompanyPermissions = () => {
    const [companies, setCompanies] = React.useState();
    const [search, setSearch] = React.useState('');

    const [mutateKey, setMutateKey] = React.useState(false);
    const mutate = () => setMutateKey(!mutateKey);

    function findIn(string, search) {
        return upper(string).includes(upper(search)) ? true : false;
    }

    function upper(string) {
        return string.toUpperCase();
    }

    function filterCompanies(company) {
        if (findIn(company.name, search)) {
            return true;
        }
    }

    React.useEffect(() => {
        async function getData() {
            const response = await authFetcher(`${API_DASHBOARD_ADMIN}/Companies`);
 
            setCompanies(response?.companies);
        }

        if (companies) setCompanies();

        getData();
    }, [mutateKey]);

    var videoLink =
        'https://willo-production.s3.amazonaws.com/1af4cd99eacc427a9cc95d6c766db278/f8ac597ce2ff430ba19bbae3b48e2edc.webm?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXUW3MXVSROY4IR72%2F20230228%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230228T052209Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=43e93649aa37abbff9b849d6701b5be164891334ee3e38e6b9e57effc9810085';

    return companies?.length ? (
        <>
            <SearchBox value={search} setValue={setSearch} />
            {companies
                .filter((i) => filterCompanies(i))
                .map((item, index) => (
                    <CompanyCard item={item} mutate={mutate} />
                ))}
        </>
    ) : (
        <Loader message="Fetching permissions" />
    );
};

export default ManageCompanyPermissions;
