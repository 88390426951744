import PropTypes from 'prop-types';
import React from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { Col, Row } from 'reactstrap';

import useMobile from '../../hooks/useMobile';
import AppPagination from '../AppPagination';
import SearchBox from '../SearchBox';
import SortArrow from './SortArrow';
import style from './style.module.scss';
import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';

const DEFAULT_PAGE = 0;

const GenericTable = ({ columns, data, withSearch = true }) => {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        width,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, globalFilter },
    } = useTable(
        { columns, data, initialState: { pageIndex: DEFAULT_PAGE }, autoResetPage: true },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const { isMobileWide } = useMobile();
    const theme = GetPageTheme();

    return (
        <div className={style.table}>
            {withSearch ? (
                <Row>
                    <Col md={12}>
                        <div className={style.search}>
                            <SearchBox value={globalFilter || ''} setValue={setGlobalFilter} />
                        </div>
                    </Col>
                </Row>
            ) : null}

            <div {...getTableProps()} className={style.table}>
                {!isMobileWide &&
                    headerGroups.map((headerGroup, index) => (
                        <Row {...headerGroup.getHeaderGroupProps()} key={index} className={theme == 'light' ? style.header : style.headerDark}>
                            {headerGroup.headers.map((column, index) => (
                                <Col
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    key={index}
                                    className={style.headerElement}
                                >
                                    {column.render('Header')}
                                    <SortArrow column={column} />
                                </Col>
                            ))}
                        </Row>
                    ))}
                <br />

                {page.map((row, index) => {
                    prepareRow(row);

                    return !isMobileWide ? (
                        <Row {...row.getRowProps()} key={index} className={style.cellWrapper}>
                            {row.cells.map((cell, index) => (
                                <Col {...cell.getCellProps()} key={index}>
                                    {cell.render('Cell')}
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row {...row.getRowProps()} key={index} className={style.cellWrapper}>
                            {row.cells.map((cell, index) => (
                                <>
                                    <Col xs="6" className={style.innerCellWrapper}>
                                        {headerGroups[0].headers[index].Header}
                                    </Col>
                                    <Col xs="6" className={style.innerCellWrapper}>
                                        {cell.render('Cell')}
                                    </Col>
                                </>
                            ))}
                        </Row>
                    );
                })}
            </div>

            <AppPagination
                onClickNext={nextPage}
                onClickPrev={previousPage}
                onClickPage={gotoPage}
                total={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                page={pageIndex}
                mobile={isMobileWide}
                width={width}
            />
        </div>
    );
};

GenericTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    columnSizeByIndex: PropTypes.array.isRequired,
    withSearch: PropTypes.bool,
};

export default GenericTable;
