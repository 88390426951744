import React from 'react';
import useSWR from 'swr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import { authFetcher, NEVER_REFRESH } from '../../../api/tools';
import { API_DASHBOARD_COMPANY, URLS } from '../../../consts/urls';
import { COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';
import { GetPageTheme, ResolveSalary, sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import Loader from '../../../components/Loader';
import SearchBox from '../../../components/SearchBox';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { renderActionCell } from './helper';

import { MATCH_STATUSES } from '../../../consts/matchOfferStatuses';
import { MATCH_STATUSES_DICTIONARY } from '../../../consts/matchOfferStatuses';
import { ThemeContext } from '../../../contexts/theme/ThemeContextWrapper';

function getStatusStyle(status) {
    switch (status) {
        case MATCH_STATUSES.CompanyAccepted:
            return style.accepted;
        case MATCH_STATUSES.Rejected:
        case MATCH_STATUSES.CompanyRejected:
            return style.rejected;
        default:
            return style.status;
    }
}

const renderStatusCell = (status) => (
    <>
        <h5 className={`${getStatusStyle(status)}`}>{MATCH_STATUSES_DICTIONARY[status]}</h5>
    </>
);

const InterviewsPage = () => {
    const [selectedIndex, setSelectedIndex] = React.useState();

    const [offerSearch, setOfferSearch] = React.useState('');
    const [candidateSearch, setCandidateSearch] = React.useState('');

    const [interviews, setInterviews] = React.useState();
    const [matches, setMatches] = React.useState();
    const [experiment, setExperiment] = React.useState();

    const [offer, setOffer] = React.useState();
    const { data, isValidating } = useSWR(`${API_DASHBOARD_COMPANY}/Matches`, authFetcher, NEVER_REFRESH);

    const { theme, rtl } = React.useContext( ThemeContext );

    function doExperiment(d) {
        const obj = {};

        d.filter((p) => p.offerId == offer.id && p.contractor.name?.toUpperCase().includes(candidateSearch.toUpperCase())).forEach((item) => {
            if(obj[item.matchStatus]) {
                obj[item.matchStatus].push({
                    cId: item.contractor.id,
                    cName: item.contractor.name,
                    status: item.matchStatus,
                    code: item.statusId,
                    matchId: item.id,
                    contractId: item.contractId,
                    messages: item.messages,
                    unread: item.unread
                })
            } else {
                obj[item.matchStatus] = [{
                    cId: item.contractor.id,
                    cName: item.contractor.name,
                    status: item.matchStatus,
                    code: item.statusId,
                    matchId: item.id,
                    contractId: item.contractId,
                    messages: item.messages,
                    unread: item.unread
                }]
            }
        })

        setExperiment(obj);
    }

    function compileInterviews(d) {
        const obj = {};

        //loops through company's interviews and groups them by offer ID
        d.forEach((item) => {
            if (obj[item.offerId]) {
                if (item.contractor.name != null) {
                    obj[item.offerId].interviews.push({
                        cId: item.contractor.id,
                        cName: item.contractor.name,
                        status: item.matchStatus,
                        code: item.statusId,
                        matchId: item.id,
                        contractId: item.contractId
                    });
                    
                    obj[item.offerId].messages += item.messages;
                    obj[item.offerId].matches++;
                }
            } else {
                obj[item.offerId] = {
                    id: item.offerId,
                    title: item.offerName,
                    salary: item.rangeExpected,
                    start: item.startDate,
                    salary: ResolveSalary(item.rangeExpected, item.currencyType, item.frequency, item.workType),
                    matches: 0,
                    interviews: [],
                    messages: 0,
                    unread: 0
                };

                if (item.contractor.name != null) {
                    obj[item.offerId].interviews.push({
                        cId: item.contractor.id,
                        cName: item.contractor.name,
                        status: item.matchStatus,
                        code: item.statusId,
                        matchId: item.id,
                        contractId: item.contractId
                    });
                    
                    obj[item.offerId].messages += item.messages;
                    obj[item.offerId].unread += item.unread;
                    obj[item.offerId].matches++;
                }
            }
        });

        return obj;
    }

    React.useEffect(() => {
        if (data) {
            // groups interviews by job title, sorts them by matches in descending order
            setInterviews(
                handleSearch(
                    sort_by_key(Object.values(compileInterviews(data)), 'unread')
                        .filter((item) => item.matches > 0)
                        .reverse(),
                ),
            );
        }
    }, [data, offerSearch]);

    React.useEffect(() => {
        if(data && offer) {
            doExperiment(data);
        }
    }, [offer, candidateSearch])

    React.useEffect(() => {
        reset();
    }, [offerSearch]);

    React.useEffect(() => {
        setCandidateSearch("");
        if (selectedIndex != null) {
            setOffer(interviews[selectedIndex]);
        } else {
            setOffer();
        }
    }, [selectedIndex]);

    function sortMatches(arr, asc) {
        const array = arr.sort((a, b) => {
            // // Only sort on matches if not identical
            if (a.matches < b.matches) return -1;
            if (a.matches > b.matches) return 1;

            // Sort on unread
            if (a.unread < b.unread) return -1;
            if (a.unread > b.unread) return 1;

            // Sort on unread
            if (a.title < b.title) return 1;
            if (a.title > b.title) return -1;

            // Both idential, return 0
            return 0;
        });

        if(asc) {
            return array;
        } else {
            return array.reverse();
        }
    }

    function reset() {
        setSelectedIndex();
        setMatches();
    }

    function handleSearch(interviews) {

        const searchString = (item) => { console.log(item); return `${item.title}` };

        return interviews.filter((item) => searchString(item).toUpperCase().includes(offerSearch.toUpperCase()));
    }

    function getSplitColumns() {
        const ListCol = <Col sm={6} md={5} lg={4}>
            <SearchBox value={offerSearch} setValue={setOfferSearch} />
            <div className={style.interviewContainer}>
                { interviews
                    ? sortMatches(interviews).map((item, index) => (
                            <Card
                                className={`${style.offerCard} ${
                                    selectedIndex == index ? style.pick : null
                                }`}
                                onClick={() => setSelectedIndex(index)}
                            >
                                <h5 className={style.jobTitle}>{item.title}</h5>
                                <i>{!!item.unread && `${item.unread || 0} new chat messages`}</i>

                                <div className={style.applicants}>
                                    <FontAwesomeIcon
                                        icon={['fas', 'users']}
                                        className={style.applicantIcon}
                                    />
                                    <h5>{item.matches || 0}</h5>
                                </div>
                            </Card>
                        ))
                    : null}
            </div>
        </Col>

        const OfferCol = experiment && offer ? <>
            <div className={style.mobileDivider} onClick={reset} />
                <Col sm={6} md={7} lg={8}>
                    <div id={`card-sub-${theme}`} className={`${style.interviewsWrapper}`}>
                        { offer ? <>
                            <Card className={style.jobCard}>
                                <h5 className={style.jobTitle}>{offer.title}</h5>
                                <FontAwesomeIcon
                                    icon={['fas', 'chevron-square-left']}
                                    className={theme == 'light' ? style.backIcon : style.backIconDark}
                                    onClick={reset}
                                />
                                {offer.salary} - starts {offer.start}
                                <h5 className={style.offerLink}>
                                    <Link to={`${URLS.COMPANY_CONTRACTS}/${offer.id}`}>
                                        {'View Offer Page'}
                                    </Link>
                                </h5>
                            </Card>
                            <SearchBox value={candidateSearch} setValue={setCandidateSearch} />
                        </> : null }
                    
                        { experiment && offer ? Object.keys(experiment).map((ex) => 
                            <Card className={style.containerCard}>
                                <Row>
                                    <Col md={12} lg={{ offset: rtl ? 6 : 0, size: 6 }}>
                                        <h5>{renderStatusCell(ex)}</h5>
                                    </Col>
                                    <Col md={12}>
                                        <Row style={{ flexDirection: rtl ? 'row-reverse' : '' }}>
                                            {experiment[ex].map((item, index) => 
                                                <Col md={12} lg={6}>
                                                    <Card className={style.applicantCard}>
                                                        <Row>
                                                            <Col sm={9} md={9}>
                                                                <h5 style={{ textAlign: 'left' }}>
                                                                    <Link to={`/company/offer/${offer.id}/contractors/${item.cId}`}>
                                                                        {item.cName}
                                                                    </Link>
                                                                </h5>
                                                            </Col>
                                                            <div className={style.applicants}>
                                                                <FontAwesomeIcon
                                                                    icon={['fas', 'envelope']}
                                                                    className={style.applicantIcon}
                                                                />
                                                                <h5>{item.messages || 0} { item.unread > 0 ? `(${item.unread})` : '' }</h5>
                                                            </div>
                                                            <Col md={12}>
                                                                {renderActionCell(item.matchId, item.contractId)}
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        ) : null }
                    </div>
                </Col>
            </> : <>
            
            </>

        const combined = [ListCol, OfferCol];

        return rtl ? combined.reverse() : combined;
    } 

    return (
        <SignInPageTemplate>
            <h5 className={style.title}>Recruitment</h5>
            <Row>
                {data ? (
                    <>
                        {' '}

                        { getSplitColumns() }
                        
                    </>
                ) : null}
                {isValidating && !data ? <Loader /> : null}
                {!isValidating && !data ? <h5>No interviews found.</h5> : null}
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(InterviewsPage, [COMPANY]);
