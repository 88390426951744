import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { URLS } from '../consts/urls';
import AdminNewDashboard from '../pages/admin/AdminNewDashboard';
import AdminCandidateProfile from '../pages/admin/AdminCandidateProfile';
import AdminCompanies from '../pages/admin/AdminCompanies';
import AdminCompaniesAdd from '../pages/admin/AdminCompaniesAdd';
import AdminCompanyOffer from '../pages/admin/AdminCompanyOffer';
import AdminCompanyProfile from '../pages/admin/AdminCompanyProfile';
import AdminContractors from '../pages/admin/AdminContractors';
import AdminDashboardPage from '../pages/admin/AdminDashboardPage';
import AdminApproval from '../pages/admin/AdminMatchApproval';
import AdminRecruiterProfile from '../pages/admin/AdminRecruiterProfile';
import AdminRecruiters from '../pages/admin/AdminRecruiters';
import TestingGround from '../pages/admin/TestingGround';
import ChangePasswordPage from '../pages/ChangePassword';
import Chatbot from '../pages/Chatbot';
import Community from '../pages/community';
import CandidateProfile from '../pages/company/CandidateProfile';
import CompanyContractTimesheets from '../pages/company/CompanyContractTimesheets';
import CompanyKycPage from '../pages/company/CompanyKyc';
import CompanyOpportunityPage from '../pages/company/CompanyOpportunityPage';
import CompanyPayments from '../pages/company/CompanyPayments';
import CompanyProfile from '../pages/company/CompanyProfile';
import DashboardCompany from '../pages/company/DashboardCompany';
import InterviewDetails from '../pages/company/InterviewDetails';
import Interviews from '../pages/company/Interviews';
import NewOfferPage from '../pages/company/NewOfferPage';
import SalaryPredictor from '../pages/company/SalaryPredictor';
import AddTimesheet from '../pages/contractor/AddTimesheet';
import CompanyOffers from '../pages/contractor/CompanyOffers';
import Dashboard from '../pages/contractor/Dashboard';
import Directory from '../pages/contractor/Directory';
import Feedback from '../pages/contractor/Feedback';
import InterviewsPage from '../pages/contractor/Interviews';
import NewCalendar from '../pages/contractor/NewCalendar';
import JobsReel from '../pages/contractor/NewJobReel';
import NudgeHistory from '../pages/contractor/NudgeHistory';
import OpportunityPage from '../pages/contractor/OpportunityPage';
import PaymentsPage from '../pages/contractor/Payments';
import ProfilePage from '../pages/contractor/Profile';
import Recommender from '../pages/contractor/Recommender';
import Timesheets from '../pages/contractor/Timesheets';
import ZoomPocPage from '../pages/contractor/ZoomPocPage';
import LeavePage from '../pages/contractor/ZoomPocPage/LeavePage';
import ErrorPage from '../pages/Error';
import Events from '../pages/events';
import Forest from '../pages/Forest';
import ForgotPasswordEmailPage from '../pages/ForgotPassword/ForgotPasswordEmailForm';
import ForgotPasswordNewPasswordPage from '../pages/ForgotPassword/ForgotPasswordNewPasswordPage';
import AberdeenForm from '../pages/forms/AberdeenAccount';
import HomeAbout from '../pages/HomeAbout';
import HomeCompanies from '../pages/HomeCompanies';
import HomePage from '../pages/HomePage';
import NewPasswordRequired from '../pages/NewPasswordRequired';
import ProjectMap from '../pages/projectmap';
import RecruiterCandidateProfile from '../pages/recruiter/RecruiterCandidateProfile';
import RecruiterCompanyProfile from '../pages/recruiter/RecruiterCompanyProfile';
import RecruiterDashboard from '../pages/recruiter/RecruiterDashboard';
import RecruiterOffer from '../pages/recruiter/RecruiterOffer';
import References from '../pages/References';
import SimpleAccountVerify from '../pages/SimpleAccountVerify';
import SignInPage from '../pages/SimpleSignIn';
import NewSignUp from '../pages/SingleSignUp';
import SpaceStation from '../pages/SpaceStation';
import SpaceStationTasks from '../pages/SpaceStationTasks';
import Welcome from '../pages/Welcome';
import CompanyRecruiterProfile from '../pages/company/CompanyRecruiterProfile';
import CompanyVRBooking from '../pages/company/CompanyVRBooking';
import StatisticsTimePerPage from '../pages/company/StatisticsTimePerPage';
import DiscourseSSO from '../pages/DiscourseSSO';

import Loading from '../pages/Loading';

import NeoTalent from '../pages/contractor/NeoTalent';
import NeoTalentMeeting from '../pages/contractor/NeoTalentMeeting';
import NeoTalentMeetingFrame from '../pages/contractor/NeoTalentMeetingFrame';
import HomeNeoTalent from '../pages/HomeNeoTalent';
import MeetingEnd from '../pages/MeetingEnd';
import AdminNeodash from '../pages/admin/AdminNeodash';

const AllRoutes = () => (
    <>
        <Routes>
            {/* Home Routes */}
            <Route path={URLS.HOME} element={<HomePage />} />
            <Route path={URLS.ABOUTPAGE} element={<HomeAbout />} />
            <Route path={URLS.COMPANYPAGE} element={<HomeCompanies />} />
            <Route path={URLS.HOME_NEO_TALENT} element={<HomeNeoTalent/>}/>

            <Route path={`/fallback`} element={<Loading/>}/>

            <Route path={URLS.SIGN_UP} element={<NewSignUp />} />
            <Route path={`${URLS.SIGN_UP}/:id`} element={<NewSignUp />} exact />

            <Route path={URLS.SIGN_IN} element={<SignInPage />} />

            <Route path={URLS.SETTINGS} element={<ChangePasswordPage />} />

            <Route path={`${URLS.FORGOT_PASSWORD_CODE}/:email`} element={<ForgotPasswordNewPasswordPage />} />
            <Route path={URLS.FORGOT_PASSWORD} element={<ForgotPasswordEmailPage />} />
            <Route path={URLS.WELCOME} element={<Welcome />} />
            <Route path={`${URLS.NEW_PASSWORD_REQUIRED}/:email`} element={<NewPasswordRequired />} />
            <Route path={URLS.SIMPLE_ACCOUNT_VERIFY} element={<SimpleAccountVerify />} />
            <Route path={URLS.SPACE_STATION} element={<SpaceStation />} />
            <Route path={URLS.SPACE_STATION_TASKS} element={<SpaceStationTasks />} />

            <Route path={URLS.DISCOURSE_SSO} element={<DiscourseSSO />} />

            <Route path={`${URLS.REFERENCES}/:userId/:refId`} element={<References />} />

            <Route path={URLS.ZOOM_LEAVE} element={<LeavePage />} />

            {/* Contractor Routes */}
            <Route path={URLS.PROFILE} element={<ProfilePage showEdit />} />
            <Route path={URLS.DIRECTORY} element={<Directory />} />
            <Route path={`${URLS.DIRECTORY}/:id`} element={<CompanyOffers />} />
            <Route path={`${URLS.OFFERS}/:id`} element={<OpportunityPage />} />
            <Route path={`${URLS.INTERVIEW_CALENDAR}/:id`} element={<NewCalendar />} />
            <Route path={`${URLS.RESCHEDULE_CALENDAR}/:id`} element={<NewCalendar />} />
            <Route path={URLS.DASHBOARD} element={<Dashboard />} />
            <Route path={URLS.JOBS_REEL} element={<JobsReel />} />
            <Route path={URLS.OFFERS} element={<NudgeHistory />} />
            <Route path={URLS.INTERVIEWS} element={<InterviewsPage />} />
            <Route path={URLS.PAYMENTS} element={<PaymentsPage />} />
            <Route path={`${URLS.TIMESHEETS}/:id`} element={<Timesheets />} />
            <Route path={`${URLS.ADD_TIMESHEET}/:id`} element={<AddTimesheet />} />
            <Route path={`${URLS.MEETING}/:id`} element={<ZoomPocPage />} />
            <Route path={URLS.FOREST} element={<Forest />} />
            <Route path={URLS.FEEDBACK} element={<Feedback />} />
            <Route path={URLS.COMMUNITY} element={<Community />} />
            <Route path={URLS.EVENTS} element={<Events />} />
            <Route path={URLS.PROJECT_MAP} element={<ProjectMap />} />
            <Route path={URLS.ABERDEEN_FORM} element={<AberdeenForm />} />
            <Route path={URLS.RECOMMENDER} element={<Recommender />} />
            <Route path={URLS.NEO_TALENT} element={<NeoTalent />} />
            <Route path={`${URLS.NEO_TALENT_MEETING}/:itemId`} element={<NeoTalentMeeting />} />
            <Route path={`${URLS.NEO_TALENT_MEETING}frame/:itemId`} element={<NeoTalentMeetingFrame />} />
            <Route path={URLS.NEO_TALENT_MEETING_END} element={<MeetingEnd />} />

            {/* Company Routes */}
            <Route path={URLS.COMPANY_DASHBOARD} element={<DashboardCompany />} />
            <Route path={URLS.COMPANY_PROFILE} element={<CompanyProfile />} />

            <Route path={`${URLS.COMPANY_CONTRACTS}/:id`} element={<CompanyOpportunityPage />} />


            <Route path={`${URLS.COMPANY_RECRUITER_PROFILE}/:id`} element={<CompanyRecruiterProfile />}/>

            <Route path={URLS.COMPANY_CONTRACTS_ADD} element={<NewOfferPage />} />
            <Route path={`${URLS.COMPANY_CONTRACTS_EDIT}/:id`} element={<NewOfferPage />} />

            <Route path={`${URLS.COMPANY_INTERVIEWS}/:id`} element={<InterviewDetails />} />
            <Route path={URLS.COMPANY_INTERVIEWS} exact element={<Interviews />} />
            <Route path={URLS.COMPANY_PAYMENTS} exact element={<CompanyPayments />} />
            <Route path={URLS.COMPANY_KYC} exact element={<CompanyKycPage />} />
            <Route path={`${URLS.COMPANY_CONTRACT_TIMESHEETS}/:id`} exact element={<CompanyContractTimesheets />} />

            <Route path={'/company/offer/:offerId/contractors/:id'} element={<CandidateProfile />} />
            <Route path={'/company/contractors/:id'} element={<CandidateProfile />} />


            <Route path={URLS.SALARY_PREDICTOR} element={<SalaryPredictor />} />
            <Route path={URLS.CHATBOT} element={<Chatbot />} />
            <Route path={URLS.COMPANY_VR_BOOKING} element={<CompanyVRBooking/>}/>

            <Route path={URLS.COMPANY_STATISTICS} element={<StatisticsTimePerPage/>}/>

            {/* Admin Routes */}
            <Route path={URLS.ADMIN_DASHBOARD} element={<AdminNewDashboard />} />
            <Route path={URLS.ADMIN_OFFERS} element={<AdminDashboardPage />} />
            <Route path={URLS.ADMIN_CONTRACTORS} element={<AdminContractors />} />
            <Route path={`${URLS.ADMIN_CONTRACTORS}/:id`} element={<AdminCandidateProfile />} />
            <Route path={URLS.ADMIN_COMPANIES} element={<AdminCompanies />} />
            <Route path={URLS.ADMIN_COMPANIES_ADD} element={<AdminCompaniesAdd />} />
            <Route path={`${URLS.ADMIN_COMPANIES}/:id`} element={<AdminCompanyProfile />} />
            <Route path={`${URLS.ADMIN_CONTRACTS}/:id`} element={<AdminCompanyOffer />} />
            <Route path={'/admin/offer/:offerId/contractors/:id'} element={<AdminCandidateProfile />} />
            <Route path={`${URLS.ADMIN_CONTRACTS_EDIT}/:id`} element={<NewOfferPage />} />
            <Route path={`${URLS.ADMIN_APPROVAL}`} element={<AdminApproval />} />
            <Route path={`${URLS.TESTING_GROUND}`} element={<TestingGround />} />
            <Route path={`${URLS.ADMIN_RECRUITERS}`} element={<AdminRecruiters />} />
            <Route path={`${URLS.ADMIN_RECRUITERS}/:id`} element={<AdminRecruiterProfile />} />
            <Route path={`${URLS.ADMIN_NEOTALENT}/:id`} element={<NeoTalent />} />
            <Route path={`${URLS.ADMIN_NEOTALENT}/:userId/meeting/:itemId`} element={<NeoTalentMeeting />} />
            <Route path={`${URLS.ADMIN_NEOTALENT}/:userId/meetingframe/:itemId`} element={<NeoTalentMeetingFrame />} />
            <Route path={`${URLS.ADMIN_WEBINAR}/:itemId`} element={<NeoTalentMeeting />} />
            <Route path={`${URLS.ADMIN_NEODASH}`} element={<AdminNeodash />} />

            {/* Recruiter Routes */}
            <Route path={URLS.RECRUITER_DASHBOARD} element={<RecruiterDashboard />} />
            <Route path={`${URLS.RECRUITER_OPPORTUNITY}/:id`} element={<RecruiterOffer />} />
            <Route path={`${URLS.RECRUITER_COMPANY_PROFILE}/:id`} element={<RecruiterCompanyProfile />} />
            <Route path={`${URLS.RECRUITER_CANDIDATE_PROFILE}/:id`} element={<RecruiterCandidateProfile />} />

            <Route path="*" element={<ErrorPage />} />
        </Routes>
    </>
);

export default AllRoutes;
