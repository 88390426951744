import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import BackButton from '../../../components/buttons/BackButton';

import { authPostData, authSendFile } from '../../../api/tools';
import SuccessModal from '../../../components/modals/SuccessModal';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_PROFILE, API_DASHBOARD_ADMIN, DEFAULT_PICTURE } from '../../../consts/urls';
import AdminCompaniesAddForm from './AdminCompaniesAddForm';
import { FIELDS, initialValues, validationSchema } from './AdminCompaniesAddForm/validate';
import style from './style.module.scss';
import { GetPageTheme } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../components/buttons/NudgeButton';
import NudgeModal from '../../../components/NudgeModal';

export function parseYouTube(values) {
    var linkArr = [];
    var linkStr = '';

    if (values[FIELDS.VIDEO_LINK]) {
        if (values[FIELDS.VIDEO_LINK].includes('youtu.be')) {
            linkArr = values[FIELDS.VIDEO_LINK].split('/');
            if (linkArr) {
                linkStr = linkArr[3].split('?')[0];
            }
        }

        if (values[FIELDS.VIDEO_LINK].includes('youtube.com')) {
            linkArr = values[FIELDS.VIDEO_LINK].split('?v=');
            if (linkArr) {
                linkStr = linkArr[1].split('&')[0];
            }
        }
    }

    return linkStr;
}

const AdminCompaniesAdd = ({ isOpen, toggle }) => {
    const navigate = useNavigate();
    const onBack = () => navigate(-1);
    const [error, setError] = useState('');

    const [successOpen, setSuccessOpen] = React.useState(false);
    const toggleError = () => setError('');

    const closeModal = () => {
        setSuccessOpen(false);
        navigate(-1);
    };

    const onSubmit = async (values) => {
        values[FIELDS.VIDEO_LINK] = parseYouTube(values);

        try {
            values.pictureUrl = DEFAULT_PICTURE;
            setError('');

            const response = await authPostData(values, `${API_DASHBOARD_ADMIN}/Profile/Company`);
            await authSendFile(values.photo, `${API_PROFILE}/Photo`, { uid: response.rId });

            response.success ? toggle() : setError(response.message);
        } catch (e) {
            console.log(e.message);
            setError('Something went wrong. Try again later.');
        }
    };

    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    return (
        <Formik {...props}>{(formik) => <NudgeModal headerText={"Add New Company"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle}>
            <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                <ModalBody>
                    <div style={{ padding: '24px' }}>
                        <Alert isOpen={!!error} color="danger" toggle={toggleError} className="mb-40">
                            {error}
                        </Alert>

                        <SuccessModal isOpen={successOpen} toggle={closeModal} header="Success" body="Company profile is created." />

                        <div className={`mb-30 ${style.addCard}`}>
                            <CardBody className={style.wrapper}>
                                <AdminCompaniesAddForm formik={formik} onBack={onBack} />
                            </CardBody>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ClickButton type="submit" color="success">Post</ClickButton>
                </ModalFooter>
            </Form>
        </NudgeModal>}</Formik>
    );
};

export default AdminCompaniesAdd;
