import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Col, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { FIELDS, validationSchema, initialValues } from './validation';
import UploadFileFieldInline from '../../../../../../components/UploadFileFieldInline';
import AppInput from '../../../../../../components/AppInput';
import { authPostData } from '../../../../../../api/tools';
import { API_DASHBOARD_ADMIN } from '../../../../../../consts/urls';

import CompanyVideo from '../../../../../../components/pages/FullCompanyProfile/PageComponents/CompanyVideo';
import ClickButton from '../../../../../../components/buttons/NudgeButton';
import { GetPageTheme } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../../../../../components/NudgeModal';

import style from '../style.module.scss';

const UploadResultsModal = ({ booking, isOpen, toggle, mutate }) => {

    const [videoOpen, setVideoOpen] = React.useState(false);
    const toggleVideo = () => setVideoOpen(!videoOpen);

    const [sending, setSending] = React.useState();

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);

            const base64 = await convertBase64(values[FIELDS.REPORT_LINK])
            const obj = {
                base64: base64.split(',')[1],
                videoLink: values[FIELDS.VIDEO_LINK]
            };

            const response = await authPostData(obj, `${API_DASHBOARD_ADMIN}/AssessmentBookings/${booking.id}/UploadResult`);

            await mutate();
            toggle();
            setSending(false);
        }
    }

    const theme = GetPageTheme();
    
    if(booking) {
        if(booking.reportLink != null) {
            return (
                <NudgeModal headerText={`Assessment Results - ${ booking.id }`} id={`modal-${theme}`} isOpen={isOpen} toggle={toggle}>
                    <CompanyVideo isOpen={videoOpen} toggle={toggleVideo} videoLink={booking.videoLink}/>
                    <ModalBody>
                        <Row style={{ textAlign: 'center' }}>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <h5><b>Report File</b></h5>
                                <ClickButton disabled={sending} color="success" onClick={() => window.open(booking.reportLink,'_blank','noopener')}>View Report</ClickButton>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <h5><b>Video Link</b></h5>
                                <ClickButton disabled={sending} color="success" onClick={toggleVideo}>View Video</ClickButton>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter/>
                    
                </NudgeModal>
            )
        }
    }

    return booking ? (
        <NudgeModal headerText={`Assessment Results - ${ booking.id }`} id={`modal-${theme}`} isOpen={isOpen} toggle={toggle}>
            <Formik {...props}>
                {(formik) =>  
                    <Form noValidate onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                            <ModalBody>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-20">
                                            <Label>Assessment Report</Label>
                                            <Field
                                                form={formik}
                                                name={FIELDS.REPORT_LINK}
                                                component={UploadFileFieldInline}
                                                className={style.fileUpload}
                                                title={"Report File"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className="mb-20">
                                            <Label>Video Link</Label>
                                            <Field
                                                component={AppInput}
                                                formik={formik}
                                                id={FIELDS.VIDEO_LINK}
                                                label={"Video Link"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>


                            </ModalBody>
                        <ModalFooter>
                            <ClickButton disabled={sending} color="success" type="submit">Upload Results</ClickButton>
                        </ModalFooter>
                    </Form>
                }
            </Formik>
        </NudgeModal>
    ) : null 
}

export default UploadResultsModal;