import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { OFFER_STATUS } from '../../../../consts/offers';
import style from './style.module.scss';
import ClickButton from '../../../buttons/NudgeButton';

import RegisterInterestModal from './RegisterInterestModal';

const MATCH_STATUS = {
    NoMatch: ['NoMatch', ''],
    
    AwaitingCompanyDecision: ['AwaitingCompanyDecision', 'Awaiting company decision'],
    Pending: ['Pending', 'Match is pending...'],

    Accepted: ['Accepted', 'You have applied for this job.'],
    Applied: ['Applied', 'You have applied for this job.'],

    Rejected: ['Rejected', 'You have dismissed this job.'],
    Negotiation: ['Negotiation', 'You have been invited to complete a interview.'],
    WilloInvited: ['WilloInvited', 'You have been invited to complete a video interview.'],
    ContractorInvited: ['ContractorInvited', 'Awaiting user application'],
    InterviewScheduled: ['InterviewScheduled', 'Interview has been scheduled.'],
    InterviewSubmitted: ['InterviewSubmitted', 'Submitted.'],
    CompanyPending: ['CompanyPending', ''],
    CompanyAccepted: ['CompanyAccepted', 'You have been accepted for this job.'],
    CompanyRejected: ['CompanyRejected', 'Unfortunately, you have not been successful.'],
    AdminApproval: ['AdminApproval', 'Registered interest. Awaiting admin approval.'],
    AdminRejected: ['AdminRejected', 'Admin has determined you are not right for this position.'],

    VRAssessmentScheduled: ['VRAssessmentScheduled', 'Your VR assessment has been scheduled.'],
    VRAssessmentSubmitted: ['VRAssessmentSubmitted', 'Your VR assessment has been submitted.'],

    FaceInterviewScheduled: ['FaceInterviewScheduled', 'Your face-to-face interview has been scheduled.'],
};

function isDisabled(baseColor, sending) {
    return baseColor;
}

const Buttons = ({
    onClickDismiss,
    onClickAccept,
    clickView,
    clickRegister,
    offerStatus,
    matchStatus,
    matchId,
    sending,
    companyFeedback,
    contractorFeedback
}) => {

    const accepted = matchStatus === MATCH_STATUS.Accepted[0];
    const approval = matchStatus === MATCH_STATUS.AdminApproval[0] || matchStatus === MATCH_STATUS.AdminRejected[0];

    const canApply = matchStatus === MATCH_STATUS.ContractorInvited[0]; //matchStatus === MATCH_STATUS.AwaitingCompanyDecision[0] && offerStatus !== OFFER_STATUS.CLOSED;
    const canRegister = matchStatus === MATCH_STATUS.NoMatch[0];

    const canView =
        matchStatus === MATCH_STATUS.Negotiation[0] ||
        matchStatus === MATCH_STATUS.InterviewScheduled[0] ||
        matchStatus === MATCH_STATUS.InterviewSubmitted[0] ||
        matchStatus === MATCH_STATUS.CompanyAccepted[0] ||
        matchStatus === MATCH_STATUS.VRAssessmentSubmitted[0] ||
        matchStatus === MATCH_STATUS.VRAssessmentScheduled[0] ||
        matchStatus === MATCH_STATUS.FaceInterviewScheduled[0];
    const canSchedule = matchStatus === MATCH_STATUS.WilloInvited[0];

    matchId = 0;

    return (<div style={{ display: 'block' }}>
            <div>
                <h5 className={style.text}>{MATCH_STATUS[matchStatus][1]}</h5>

                { contractorFeedback && matchStatus === MATCH_STATUS.Rejected[0] ? <h5 className={style.text}>Your reason was: <i>"{contractorFeedback}"</i></h5> : null }
                { companyFeedback && matchStatus === MATCH_STATUS.CompanyRejected[0] ? <h5 className={style.text}>Reason given was: <i>"{companyFeedback}"</i></h5> : null }
            </div>
            <div>
                {canApply ? (
                    <div className={style.buttonContainer}>
                        <ClickButton
                            color={isDisabled('danger', sending)}
                            disabled={sending}
                            size="lg"
                            className={style.register}
                            onClick={onClickDismiss}
                        >
                            Dismiss
                        </ClickButton>

                        <ClickButton
                            color={isDisabled('success', sending)}
                            disabled={sending}
                            size="lg"
                            className={style.register}
                            onClick={onClickAccept}
                        >
                            Apply
                        </ClickButton>
                    </div>
                ) : null}

                {canView ? (
                    <div className={style.buttonContainer}>
                        <ClickButton
                            color={isDisabled('success', sending)}
                            disabled={sending}
                            size="lg"
                            className={style.register}
                            onClick={clickView}
                        >
                            Go to Interview
                        </ClickButton>
                    </div>
                ) : null}

                {canSchedule ? (
                    <div className={style.buttonContainer}>
                        <ClickButton
                            color={isDisabled('success', sending)}
                            disabled={sending}
                            size="lg"
                            className={style.register}
                            onClick={clickView}
                        >
                            Go to Interview
                        </ClickButton>
                    </div>
                ) : null}

                {canRegister ? (
                    <div className={style.buttonContainer}>
                        <RegisterInterestModal
                            onClick={clickRegister}
                            disabled={ sending }
                        >
                            { sending ? 'Registering...' : 'Register Interest'}
                        </RegisterInterestModal>
                    </div>
                ) : null}
            </div>
        
    </div>);
};

Buttons.propTypes = {
    onClickDismiss: PropTypes.func.isRequired,
    onClickAccept: PropTypes.func.isRequired,
    offerStatus: PropTypes.string.isRequired,
    matchStatus: PropTypes.string.isRequired,
};

export default Buttons;
