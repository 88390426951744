import * as Yup from 'yup';

export const DEFAULT_LABELS = [
    'High School',
    'College',
    'University (Undergrad)',
    'University (Masters)',
    'University (PhD)',
];

export const OTHER_LABEL = 'Other';

export const FIELDS = {
    QUALIFICATION: 'qualification',
    STUDY_FIELD: 'studyField',
    OTHERS: 'otherDescription',
};

export const validationSchema = Yup.object({
    [FIELDS.QUALIFICATION]: Yup.string().required('One of these fields is required'),
    [FIELDS.STUDY_FIELD]: Yup.string().required('One of these fields is required'),
});

export const initialValues = {
    [FIELDS.QUALIFICATION]: '',
    [FIELDS.OTHERS]: '',
};
