import React from 'react';

import CompanyPageTemplate from '../../../components/CompanyPageTemplate';
import PaymentsCard from './PaymentsCard';

const CompanyKycPage = () => (
    <CompanyPageTemplate>
        <h1 className="title mb-40">KYC</h1>
        <PaymentsCard />
    </CompanyPageTemplate>
);

export default CompanyKycPage;
