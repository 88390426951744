import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import useSWR from 'swr';

import { fetcher } from '../../../api/tools';
import { API_PROFILE } from '../../../consts/urls';
import SelectField from '../../SelectField';
import { FIELDS } from './validation';

const prepareOptions = (elements) => elements && elements.map((el) => ({ value: el.id, label: el.name }));

const ServiceOfferedEdit = ({ formik }) => {
    const { data } = useSWR(`${API_PROFILE}/Industries`, fetcher, { refreshInterval: 0 });

    const prepareCategoryForSelectedSector = (id) => {
        if (data) {
            const element = data.find((el) => el.id === id);
            return element && prepareOptions(element.subIndustries);
        }
    };

    useEffect(() => {
        let options = prepareCategoryForSelectedSector(formik.values[FIELDS.SECTOR]);
        if (options) {
            formik.setFieldValue(FIELDS.CATEGORY, options[0].value);
        }
        /* eslint-disable */
    }, [formik.values[FIELDS.SECTOR]]);
    /* eslint-enable */

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label for={FIELDS.SECTOR}>Work Sector</Label>
                        <Field
                            name={FIELDS.SECTOR}
                            component={SelectField}
                            options={prepareOptions(data)}
                            placeholder="Sector"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label for={FIELDS.CATEGORY}>Category</Label>
                        <Field
                            name={FIELDS.CATEGORY}
                            component={SelectField}
                            options={prepareCategoryForSelectedSector(formik.values[FIELDS.SECTOR])}
                            placeholder="Category"
                        />
                    </FormGroup>
                </Col>
            </Row>

            {/*<FormGroup>
            <Label for={FIELDS.SERVICE_OFFERED}>Service Offered</Label>
            {formik.values[FIELDS.CATEGORY] ? (
                <ServiceOfferedInput
                    formik={formik}
                    categoryField={FIELDS.CATEGORY}
                    serviceOfferedField={FIELDS.SERVICE_OFFERED}
                />
            ) : (
                
                <Field
                    name={FIELDS.SERVICE_OFFERED}
                    component={AutocompleteInput}
                    id={FIELDS.SERVICE_OFFERED}
                    formik={formik}
                    label="Service offered"
                    suggestions={[]}
                    placeholder="Service offered"
                />
            )}
        </FormGroup>*/}
        </>
    );
};

ServiceOfferedEdit.propTypes = {
    formik: PropTypes.object.isRequired,
};

export default ServiceOfferedEdit;
