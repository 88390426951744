import React from 'react';
import { Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, NO_REFRESH_INTERVAL } from '../../api/tools';
import { API_DASHBOARD_CONTRACTOR } from '../../consts/urls';
import BackArrow from '../BackArrow';
import ContractorInformation from '../ContractorInformation';
import Loader from '../Loader';
import SignInPageTemplate from '../SignInPageTemplate';

const ContractorPageTemplate = ({ children, withArrow }) => {
    const { data: user } = useSWR(API_DASHBOARD_CONTRACTOR, authFetcher, NO_REFRESH_INTERVAL);

    return (
        <SignInPageTemplate>
            {user ? (
                <Row>
                    <Col md={{ size: 12 }}>{children}</Col>
                </Row>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default ContractorPageTemplate;
