import React, { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { authFetcher } from '../../../api/tools';
import {
    renderCertificatesCell,
    renderCvCell,
    renderImageCell,
    renderNameCell,
    renderServiceOfferedCell,
    renderSkillsCell,
} from '../../../components/AdminContractors/helper';
import GenericTable from '../../../components/GenericTable';
import { sortByDate } from '../../../components/GenericTable/helpers';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_ADMIN, URLS } from '../../../consts/urls';

import ContractorList from './ContractorList';

import style from './style.module.scss';

const AdminContractors = () => {
    const { data } = useSWR(`${API_DASHBOARD_ADMIN}/Contractors`, authFetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
    });

    return (
        <SignInPageTemplate>
            <h1 className={style.title}>Talent</h1>

            {data ? <ContractorList contractors={data.contractors} /> : <Loader message={`Loading contractors`} />}
        </SignInPageTemplate>
    );
};

export default AdminContractors;
