import React, { useState } from 'react';
import { Button } from 'reactstrap';

import StepsPopup from './StepsPopup';
import style from './style.module.scss';
import ClickButton from '../../../components/buttons/NudgeButton';

const StepsButton = () => {
    const [isContactOpen, setIsContactOpen] = useState(false);
    const toggleContact = () => setIsContactOpen(!isContactOpen);

    return (
        <>
            <StepsPopup toggle={toggleContact} isOpen={isContactOpen} />
            <ClickButton size="lg" color="success" className={style.btn} onClick={toggleContact}>
                Next Steps
            </ClickButton>
        </>
    );
};

export default StepsButton;
