import * as Yup from 'yup';

export const FIELDS = {
    EMAIL: 'email',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY_NAME: 'companyName',
    ACCEPT: 'accept',
    PASSWORD: 'password',
    REPEAT_PASSWORD: 'repeatPassword',
};

export const validationSchema = Yup.object({
    [FIELDS.FIRST_NAME]: Yup.string().required('This field is required'),
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
    [FIELDS.LAST_NAME]: Yup.string().required('This field is required'),
    [FIELDS.COMPANY_NAME]: Yup.string().required('This field is required'),
    [FIELDS.ACCEPT]: Yup.boolean().required('This field is required').oneOf([true], 'Field must be checked'),
    [FIELDS.PASSWORD]: Yup.string()
        .min(8, 'This field should be at last 8 chars length')
        .matches(
            /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
            'Password should contain upper and lower case letters and numbers',
        )
        .required('This field is required'),
    [FIELDS.REPEAT_PASSWORD]: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const initialValues = {
    [FIELDS.LAST_NAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.FIRST_NAME]: '',
    [FIELDS.COMPANY_NAME]: '',
    [FIELDS.ACCEPT]: false,
    [FIELDS.PASSWORD]: '',
    [FIELDS.REPEAT_PASSWORD]: '',
};
