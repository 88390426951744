import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';
import { Alert, Col, ModalFooter, Row } from 'reactstrap';
import AppInput from '../../../../components/AppInput';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS } from './validation';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../components/buttons/NudgeButton';
import NudgeModal from '../../../../components/NudgeModal';

const FeedbackModal = ({ isOpen, toggle, formik, error, toggleError }) => {
    return (
        <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <NudgeModal headerText={"Enter the new sub-login's username and email address."} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
                <ModalBody>
                    <Alert style={{ margin: '24px' }} color="danger" isOpen={!!error} toggle={toggleError}>
                        {error}
                    </Alert>
                    <Row>
                        <Col md={{ size: 10, offset: 1}}>
                            <FormGroup className="mb-10 ">
                                <Field name={FIELDS.NAME} id={FIELDS.NAME} formik={formik} component={AppInput} label="Name" />
                            </FormGroup>
                            <FormGroup className="mb-10 ">
                                <Field
                                    name={FIELDS.EMAIL}
                                    id={FIELDS.EMAIL}
                                    formik={formik}
                                    component={AppInput}
                                    label="Email Address"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <ClickButton type="submit" color="success" size="lg" className={style.button}>
                        Refer
                    </ClickButton>
                </ModalFooter>
            </NudgeModal>
        </Form>
    );
};

FeedbackModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default FeedbackModal;
