import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import GenericModal from './GenericModal';
import ClickButton from '../buttons/NudgeButton';

const Body = ({ body, toggle }) => (
    <>
        {body}
        <div className="mt-40 text-right">
            <ClickButton color="danger" onClick={toggle}>
                Close
            </ClickButton>
        </div>
    </>
);

const SuccessModal = ({ body, toggle, ...props }) => (
    <GenericModal {...props} toggle={toggle} body={<Body body={body} toggle={toggle} />} />
);

SuccessModal.propTypes = {
    isOpen: PropTypes.any.isRequired,
    toggle: PropTypes.func.isRequired,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
};

export default SuccessModal;
