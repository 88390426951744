import * as Yup from 'yup';

export const FIELDS = {
    SKILLS: 'skills',
};

export const validationSchema = Yup.object({
    [FIELDS.SKILLS]: Yup.array()
        .of(
            Yup.object()
                .shape({
                    icon: Yup.string().length(50).required('This field is required.'),
                    name: Yup.string().length(50).required('This field is required.')
                })
                .required('No thanks'),
        )
        .required('No thanks'),
});

export const initialValues = {
    [FIELDS.SKILLS]: [{ icon: 'brain', name: '' }],
};
