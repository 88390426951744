import { FIELDS, VALUE_TO_SHOW_LOCATION_INPUT } from './validation';

export const tooltipsAndNames = (isEdit) => {
    return [
        {
            id: 0,
            label: 'Templates',
            value: 0,
            condition: !isEdit
        },
        {
            //PAGE 1
            id: 1,
            label: 'Basic Info',
            value: 1,
            condition: true,
    
            [FIELDS.NAME]: ['Job Title', 'What type of work are you requesting applicants for?'],
            [FIELDS.SCOPE]: ['Description', 'What does the job entail? (e.g responsibilities and roles)'],
            [FIELDS.SECTOR]: ['Industry', 'What industry will this job work within?'],
            [FIELDS.CATEGORY]: ['Sub-Industry', 'The subindustry'],
    
            [FIELDS.LOCATION]: ['Location', 'Where is this job based?'],
            [FIELDS.LOCATION_SELECT]: ['Location Type', 'Is this job remote or on location?'],
        },
        {
            //PAGE 2
            id: 2,
            label: 'Dates/Hours',
            value: 2,
            condition: true,
            [FIELDS.START]: ['Start Date', 'What date will the employment period start?'],
            [FIELDS.SCHEDULING]: ['Scheduled', 'Will the offer be posted upon creation or at a point later in time?'],
    
            [FIELDS.PUBLISH_DATE]: ['Publish Date', 'What date will the job posting be made public?'],
            [FIELDS.CLOSE_DATE]: ['Close Date', 'What date will the job posting be made unavailable to view?'],
    
            [FIELDS.FLEXIBILITY]: ['Flexibility', 'Are the hours flexible or fixed?'],
    
            [FIELDS.HOURS_FROM]: ['Hours From', ''],
            [FIELDS.HOURS_TO]: ['Hours To', ''],
            [FIELDS.MINUTES_FROM]: ['Minutes From', ''],
            [FIELDS.MINUTES_TO]: ['Minutes To', ''],
    
            [FIELDS.FIXED_FROM]: ['Fixed From', 'When would the candidate start work?'],
            [FIELDS.FIXED_TO]: ['Fixed To', 'When would the candidate usually finish work?'],
        },
        {
            //PAGE 3
            id: 3,
            label: 'Skills/Education',
            value: 3,
            condition: true,
            [FIELDS.MIN_QUALIFICATIONS]: [
                'Minimum Qualifications',
                "What is the minimum qualification you're looking for?",
            ],
            [FIELDS.STUDY_FIELD]: ['Study Field', ''],
            [FIELDS.QUAL_REQUIRED]: ['Qualification Required', ''],
            [FIELDS.SKILLS]: ['Skills', 'What skills are you looking for?'],
            [FIELDS.CERTIFICATES]: ['Certificates', 'What certificates or qualifications are you looking for?'],
            [FIELDS.FILES]: ['Files', 'What additional files would you like to list alongside the job posting.'],
        },
        {
            //PAGE 4
            id: 4,
            label: 'Recruitment',
            value: 4,
            condition: true,
            [FIELDS.MATCH_TYPE]: [
                'Has Video Interview?',
                'Will the recruitment process include a video interview? (Access to this is a paid feature of Nudge)',
            ],
            [FIELDS.HAS_EDIFY]: [
                'Has VR Assessment?',
                'Will the recruitment process include a VR assessment? (Access to this is a paid feature of Nudge)',
            ],
            [FIELDS.FACE_TO_FACE]: [
                'Has Face-to-Face Interview?',
                'Will the recruitment process include a face-to-face interview?',
            ],
            [FIELDS.QUESTIONS]: ['Questions', 'What questions will you ask this prospective candidate?'],
            [FIELDS.CONFIRM]: ['Confirm', "Confirm this, please"]
        },
        {
            //PAGE 5
            id: 5,
            label: "Addt'l Info",
            value: 5,
            condition: true,
            [FIELDS.OUTSIDE]: ['Outside IR35?', 'This determines whether the job is outside of IR35.'],
            [FIELDS.OFFER_TYPE]: ['Job Type', 'Is this job a staff opportunity, freelance or PAYE?'],
    
            [FIELDS.DURATION]: ['Contract Duration', 'How many days of work does this contract last?'],
            [FIELDS.REVENUE]: ['Salary', 'What range of pay would the prospective candidate earn?'],
            [FIELDS.REVENUEMIN]: ['Min. Salary', 'Minimum range of salary.'],
            [FIELDS.REVENUEMAX]: ['Max. Salary', 'Maximum range of salary.'],
            [FIELDS.REVENUEFREQ]: ['Salary Frequency', 'How often would salary be paid?'],
    
            [FIELDS.TIMING]: ['Full Time or Part Time?', 'Is this job full-time or part-time?'],
            [FIELDS.WORK_TYPE]: ['Paid or Volunteer?', 'Is this a paid position or volunteer?'],
            [FIELDS.EMPLOYEE_LEVEL]: ['Employee Level', 'Is this a high-standing position or entry-level?'],
    
            [FIELDS.REVENUE_FIXED]: ['Fixed or Variable?', 'Is the salary fixed, or variable?'],
            [FIELDS.CURRENCY]: ['Currency Type', 'What currency is the salary paid in?'],
        },
        {
            id: 6,
            label: 'Klara',
            value: 6,
            condition: true,
            //KLARA DASHBOARD
        },
        {
            id: 7,
            label: 'Summary',
            value: 7,
            condition: !isEdit,
            //SUMMARY
        },
    ]
};

export const YesNoOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
];

export const locationOptions = [
    { label: 'Remote', value: 'Remote' },
    { label: 'On location', value: VALUE_TO_SHOW_LOCATION_INPUT },
    { label: 'Open to both', value: 'Open to both' },
];

export const levelOptions = [
    { label: 'Internship', value: 'Internship' },
    { label: 'Graduate', value: 'Graduate' },
    { label: 'Junior', value: 'Junior' },
    { label: 'Mid-Level', value: 'Mid-level' },
    { label: 'Senior', value: 'Senior' },
];

export const ratePayOptions = [
    { label: 'per day', value: 'Day' },
    { label: 'per hour', value: 'Hour' },
    { label: 'per annum', value: 'Year' },
];

export const options = [
    { label: 'Potentially', value: 'Potentially' },
    { label: 'No', value: 'No' },
];

export const timingOptions = [
    { label: 'Full Time', value: 'Full Time' },
    { label: 'Part Time', value: 'Part Time' },
];

export const typeOptions = [
    { label: 'Paid Work', value: 'Paid Work' },
    { label: 'Volunteer', value: 'Volunteer' },
];

export const offerTypeOptions = [
    { label: 'Staff', value: 'Staff' },
    { label: 'Freelancer', value: 'Freelance' },
    { label: 'PAYE', value: 'PAYE' },
];

export const flexibilityOptions = [
    { label: 'Fixed', value: 'Fixed' },
    { label: 'Flexible', value: 'Flexible' },
];

export const schedulingOptions = [
    { label: 'Now', value: 'NOW' },
    { label: 'Later', value: 'LATER' },
];

export const currencyOptions = [
    { label: 'GBP (£)', value: 'GBP' },
    { label: 'USD ($)', value: 'USD' },
    { label: 'EUR (€)', value: 'EUR' },
];

export const salaryFixedOptions = [
    { label: 'Variable', value: 'Variable' },
    { label: 'Fixed', value: 'Fixed' },
];

export const internalExternalOptions = [
    { label: 'Internal', value: true },
    { label: 'Public', value: false },
]

export const currencyLookup = {
    GBP: '£',
    USD: '$',
    EUR: '€',
};

export const selfManagementOptions = ['Focusing', 'Integrity', 'Adapting', 'Initiative'];

export const socialIntelligenceOptions = ['Communicating', 'Feeling', 'Collaborating', 'Leading'];

export const innovationOptions = ['Curiosity', 'Creativity', 'Sense Making', 'Critical Thinking'];

export const technologyOptions = ['Technician', 'Engineering', 'Project Management', 'Business Services'];
