import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';

import { GetPageTheme } from '../../../pages/GenericCompanyProfile/HelperComponents';
import moment from 'moment';

import style from './style.module.scss';
import Loader from '../../../Loader';
import NudgeButtonGroup from '../../../buttons/ButtonGroup';
import useMobile from '../../../../hooks/useMobile';

const Notification = ({ index, notification, sending, onClick, onDelete }) => {

    const theme = GetPageTheme();
    const { isMobileSlim } = useMobile();

    return (
        <Row
            id={theme == 'light' ? `card-sub-light` : `card-dark`}
            key={index}
            className={style.notification}
            style={{ borderLeft: !notification.hasRead ? '12px solid #962929' : null, paddingLeft: !notification.hasRead ? '0': '' }}
        >
            <Col title={`ID is ${notification.notificationId}`} xs={12} sm={4} md={4} lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <b>{`${notification.title} ` }</b>
                    <br/>
                    <i>{moment(notification.dateSent).format('DD/MM/YYYY, hh:mm:ss a')}</i>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                {notification.content}
            </Col>
            <Col xs={12} sm={2} md={2} lg={1}>
                <NudgeButtonGroup
                    buttons={[
                        { disabled: !!sending, available: true, onClick: () => onClick(notification.link, notification.notificationId, index), label: <FontAwesomeIcon onClick={() => onClick(notification.link, notification.notificationId, index)} icon={['fas', notification.link ? 'share-square' : 'envelope-open-text']} /> },
                        { disabled: !!sending, available: true, color: 'danger', onClick: () => onDelete(notification.notificationId), label: <FontAwesomeIcon onClick={() => onDelete(notification.notificationId)} icon={['fas', 'trash-alt']} /> },
                    ]}
                    vertical={!isMobileSlim}
                />
            </Col>
        </Row>
    )
}

export default Notification;