import React from 'react';


import OfferTemplates from './OfferTemplates';
import WeightsDashboard from '../WeightsDashboard';
import OfferSummary from './OfferSummary';

import { Card, CardBody, Alert } from 'reactstrap';
import { FIELDS } from './validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_PROFILE, URLS } from '../../../../consts/urls';

import style from './style.module.scss';

import { tooltipsAndNames } from './options';

import { handleTime } from '../NewCreateOffer';
import useMobile from '../../../../hooks/useMobile';

import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeButton from '../../../../components/buttons/NudgeButton';
import NudgeTabs from '../../../../components/NudgeTabs';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContext } from '../../../../contexts/toast/ToastContext';
import { OFFER_ERROR } from '..';
import BasicInfo from './Sections/BasicInfo';
import DatesHours from './Sections/DatesHours';
import SkillsEducation from './Sections/SkillsEducation';
import Recruitment from './Sections/Recruitment';
import AddtlInfo from './Sections/AddtlInfo';
import { NEVER_REFRESH, fetcher } from '../../../../api/tools';
import useSWR from 'swr';

export const translateDates = (f, inbound) => {
    function doDate(dateArr) {
        if (inbound) {
            return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
        } else {
            return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
        }
    }

    const splitSymbol = inbound ? '/' : '-';

    var arr;

    if (f.startDate) {
        if (f.startDate.includes(splitSymbol)) {
            arr = f.startDate?.split(splitSymbol);
            f.startDate = doDate(arr);
        }
    }

    if (f.validTo) {
        if (f.validTo.includes(splitSymbol)) {
            arr = f.validTo?.split(splitSymbol);
            f.validTo = doDate(arr);
        }
    }

    if (f.validFrom) {
        if (f.validFrom.includes(splitSymbol)) {
            arr = f.validFrom?.split(splitSymbol);
            f.validFrom = doDate(arr);
        }
    }

    return f;
};

function handleHours(v) {
    const fixedFrom = v[FIELDS.FIXED_FROM];
    const fixedTo = v[FIELDS.FIXED_TO];

    if (fixedFrom) {
        v[FIELDS.HOURS_FROM] = fixedFrom.substring(0, 2);
        v[FIELDS.MINUTES_FROM] = fixedFrom.substring(2, 4);
    }

    if (fixedTo) {
        v[FIELDS.HOURS_TO] = fixedTo.substring(0, 2);
        v[FIELDS.MINUTES_TO] = fixedTo.substring(2, 4);
    }

    return v;
}


/////////////////////////////////////////////////////////////////////////////
const OfferForm = ({ isEdit, formik, canWillo, canEdify, files, onRemove, matches }) => {
    const MIN_SECTION = isEdit ? 1 : 0;
    const MAX_SECTION = tooltipsAndNames(isEdit).length - 1;

    const { isMobileWide } = useMobile();
    const theme = GetPageTheme();
    const { createToast } = React.useContext( ToastContext );
    const { id } = useParams();

    const { data: selectorData } = useSWR(`${API_PROFILE}/Industries`, fetcher, NEVER_REFRESH);
    let sectors = { selectorData }.selectorData;
    
    const [key, setKey] = React.useState(false);
    const [section, setSection] = React.useState(MIN_SECTION);
    const [tooltip, setTooltip] = React.useState();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [submits, setSubmits] = React.useState(0);

    const navigate = useNavigate();

    //STATE VARIABLES

    function getTooltip() {
        const arr1 = tooltipsAndNames(isEdit)[section];
        const arr2 = arr1[tooltip] || [];

        return arr2[1];
    }

    const TooltipAlert = ({ name }) => {
        if(!name) {
            return (
                <Alert className={style.tooltip} color={formik.errors[name] ? 'danger' : 'success'} fade={false}>
                    {isMobileWide ? 'Tap' : 'Click'} a field to see details about it.
                </Alert>
            );
        }

        return (
            <Alert className={style.tooltip} color={formik.errors[name] ? 'danger' : 'success'} fade={false}>
                {getTooltip()}{' '}
                {<FontAwesomeIcon icon={['fas', formik.errors[name] ? 'times-circle' : 'check-circle']} />}
            </Alert>
        )
    };


    const beginForm = () => setSection(MIN_SECTION + 1);

    const SECTIONS = [];
    for (var i = MIN_SECTION; i <= MAX_SECTION; i++) {
        SECTIONS.push(i);
    }

    function hasError(page) {
        if (!formik.submitCount) return null;
        
        if(page < 0) page = 0;
        if(page > SECTIONS.length - 1) page = SECTIONS.length - 1;

        const errors = Object.keys(formik.errors);
        const sections = Object.keys(tooltipsAndNames(isEdit)[page]).filter((item) => item != 'label');

        if (!errors.length || !sections.length) return null;

        let found = false;
        errors.forEach((error) => {
            sections.forEach((section) => {
                if (error == section) found = true;
            });
        });

        if (found) {
            return page == section ? style.selectedError : style.error;
        }
    }

    const ErrorMessage = ({ field }) => {
        return formik.errors[field] ? <h5 className={style.errorText}>{formik.errors[field]}</h5> : null;
    };


    //REACT HOOK EFFECTS
    

    React.useEffect(() => {
        if(parseInt(formik.values[FIELDS.SECTOR])) {
            formik.setFieldValue(FIELDS.CATEGORY, sectors[formik.values[FIELDS.SECTOR] - 1].subIndustries[0].id);
        }
    }, [formik.values[FIELDS.SECTOR]])

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            formik.validateForm();
        }, 250);

        return () => clearTimeout(delayDebounceFn);
    }, [formik.values]);

    React.useEffect(() => {
        var formBody = document.getElementById('form-body');

        if (tooltip) setTooltip();

        if (formBody) {
            formBody.scrollTo(0, 0);
        }
    }, [section]);

    React.useEffect(() => {
        if (formik.values[FIELDS.OFFER_TYPE] == 'Staff') {
            formik.setFieldValue(FIELDS.REVENUEFREQ, 'Year');
        }
    }, [formik.values[FIELDS.OFFER_TYPE]]);

    React.useEffect(() => {
        formik.setFieldValue(
            FIELDS.FIXED_FROM,
            handleTime(formik.values[FIELDS.HOURS_FROM], formik.values[FIELDS.MINUTES_FROM]),
        );
    }, [formik.values[FIELDS.HOURS_FROM], formik.values[FIELDS.MINUTES_FROM]]);

    React.useEffect(() => {
        formik.setFieldValue(
            FIELDS.FIXED_TO,
            handleTime(formik.values[FIELDS.HOURS_TO], formik.values[FIELDS.MINUTES_TO]),
        );
    }, [formik.values[FIELDS.HOURS_TO], formik.values[FIELDS.MINUTES_TO]]);

    React.useEffect(() => {
        if (isEdit) {
            formik.setValues(translateDates(formik.values, true));
            formik.validateForm();
        }

        setKey(!key);
    }, []);

    React.useEffect(() => {
        formik.values = handleHours(formik.values);
    }, [formik.values[FIELDS.FIXED_FROM], formik.values[FIELDS.FIXED_TO]]);


    React.useEffect(() => {
        var curr = '';
        if (formik.values[FIELDS.REVENUE_FIXED] == 'Variable') {
            curr = `${formik.values[FIELDS.REVENUEMIN] || 0}-${formik.values[FIELDS.REVENUEMAX] || 0}`;
        } else {
            formik.setFieldValue(FIELDS.REVENUEMAX, formik.values[FIELDS.REVENUEMIN]);
            curr = `${formik.values[FIELDS.REVENUEMIN] || 0}-${formik.values[FIELDS.REVENUEMIN] || 0}`;
        }
        
        formik.setFieldValue(FIELDS.REVENUE, curr);
    }, [formik.values[FIELDS.REVENUEMIN], formik.values[FIELDS.REVENUEMAX], formik.values[FIELDS.REVENUE_FIXED]]);

    React.useEffect(() => {
        setSubmits(formik.submitCount + 1);

        if(formik.submitCount == submits && !formik.isValid) {
            createToast(OFFER_ERROR, `error`);
        }
    }, [formik.submitCount]);

    function getTabs() {
        const tabConstructor = [];

        tooltipsAndNames(isEdit).forEach((item, index) => {
            item.hasError = hasError(item.value) ? true : false
            
            tabConstructor.push(item);
        })

        return tabConstructor;
    }

    React.useEffect(() => {
        async function doIndustry() {
            const v = formik.values;
            if (selectorData) {
                selectorData.forEach((item1) => {
                    item1.subIndustries.forEach((item2) => {
                        if(v[FIELDS.SECTOR] == item2.name) {
                            formik.setFieldValue(FIELDS.SECTOR, item1.id);
                            formik.setFieldValue(FIELDS.CATEGORY, item2.id);
                        }
                    });
                });
            }
        }

        doIndustry();
    }, [sectors, key])

    return (
        <>
            <div className={style.headerButtonContainer}>
                <NudgeButton color="danger" className={style.headerButtons} onClick={() =>  navigate(isEdit ? -1 : `${URLS.COMPANY_DASHBOARD}`)}>Go Back</NudgeButton>
                
                <h5 className={style.header}>{isEdit ? 'Edit Job Description' : 'Post New Job'}</h5>
                
                <NudgeButton className={style.headerButtons} color="danger" onClick={() => formik.resetForm()}>
                    {`${isEdit ? 'Reset Values' : 'Clear Form'}`}
                </NudgeButton>
            </div>

            <Alert
                isOpen={!!errorMessage}
                toggle={() => setErrorMessage('')}
                color="danger"
                fade
                className={style.errorMessage}
            >
                {errorMessage}
            </Alert>
            
            <NudgeTabs
                selected={section}
                setTab={setSection}
                tabs={getTabs()}
                dots={formik.submitCount > 0}
            />

            <Card className={style.formCard}>
                <CardBody id="form-body" className={`${section != 0 ? style.formCardHidden : style.formCardBody} ${theme == 'light' ? style.formCardLight : style.formCardDark}`}>
                    {section > 0 && section < 6 ? (
                        <div className={style.tooltipContainer}>
                            <TooltipAlert name={tooltip} />
                        </div>
                    ) : null}

                    <div className={theme == 'light' ? style.divider : style.dividerDark} />

                    <h2 className={style.subtitle}>{tooltipsAndNames(isEdit)[section].label}</h2>

                    {section == 0 ? (
                        <div className={style.sectionWrapper}>
                            <OfferTemplates formik={formik} begin={beginForm} canWillo={canWillo} canEdify={canEdify} />
                        </div>
                    ) : section == 1 ? (
                        <div className={style.sectionWrapper}>
                            <BasicInfo formik={formik} tooltip={{ get: tooltip, set: setTooltip}} isEdit={isEdit} sectors={sectors} selectorData={selectorData}/>                               
                        </div>
                    ) : section == 2 ? (
                        <div className={style.sectionWrapper}>
                            <DatesHours formik={formik} tooltip={{ get: tooltip, set: setTooltip}} isEdit={isEdit}/>
                        </div>
                    ) : section == 3 ? (
                        <div className={style.sectionWrapper}>
                            <SkillsEducation formik={formik} tooltip={{ get: tooltip, set: setTooltip}} isEdit={isEdit} files={files}/>
                        </div>
                    ) : section == 4 ? (
                        <div className={style.sectionWrapper}>
                            <Recruitment formik={formik} tooltip={{ get: tooltip, set: setTooltip}} isEdit={isEdit} recruitObj={{ willo: canWillo, edify: canEdify }} matches={matches}/>
                        </div>
                    ) : section == 5 ? (
                        <div className={style.sectionWrapper}>
                            <AddtlInfo formik={formik} tooltip={{ get: tooltip, set: setTooltip}} isEdit={isEdit} />
                        </div>
                    ) : section == 6 ? (
                        <div className={style.sectionWrapper}>
                            <WeightsDashboard formik={formik} />
                        </div>
                    ) : section == 7 ? (
                        <div className={style.sectionWrapper}>
                            <OfferSummary formik={formik} pointers={tooltipsAndNames(isEdit)} sectors={sectors} />
                        </div>
                    ) : null}

                </CardBody>
            </Card>
        </>
    );
};

export default OfferForm;
