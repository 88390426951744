import * as Yup from 'yup';

export const FIELDS = {
    NAME: 'name',
    COMPANY: 'company',
    BIO: 'bio',
    LOCATION: 'location',
    YEAR_FOUNDED: 'yearFounded',
    SECTORS: 'sectors',
    SPECIALISM: 'specialism',
    BACK_COLOUR: 'backColour',
    PHOTO: 'photo',
    CONFIRM: 'confirm'
};

export const MAX_BIO = 500;
export const validationSchema = Yup.object({
    [FIELDS.NAME]: Yup.string().required('This field is required'),
    [FIELDS.COMPANY]: Yup.string().required('This field is required'),
    [FIELDS.LOCATION]: Yup.string().required('This field is required'),
    [FIELDS.BIO]: Yup.string().required('This field is required').max(500, 'Must be less than 500 characters.'),
    [FIELDS.YEAR_FOUNDED]: Yup.number().typeError("Must be a number").required('This field is required'),
    [FIELDS.BACK_COLOUR]: Yup.string().required('This field is required'),

    [FIELDS.SECTORS]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string()
                            .test('len', 'Must be between 1 and 50 characters.', (val) =>
                                val ? val.length <= 50 : null,
                            )
                            .required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),

    [FIELDS.SPECIALISM]: Yup.array().when({
        is: (data) => data.length > 0,
        then: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string()
                            .test('len', 'Must be between 1 and 50 characters.', (val) =>
                                val ? val.length <= 50 : null,
                            )
                            .required('This field is required.'),
                    })
                    .required('No thanks'),
            )
            .required('No thanks'),
    }),
});

export const initialValues = {
    [FIELDS.NAME]: '',
    [FIELDS.COMPANY]: '',
    [FIELDS.LOCATION]: '',
    [FIELDS.BIO]: '',
    [FIELDS.YEAR_FOUNDED]: '',

    [FIELDS.SECTORS]: [],
    [FIELDS.SPECIALISM]: [],
    [FIELDS.BACK_COLOUR]: '',
    [FIELDS.CONFIRM]: false
};
