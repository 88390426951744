import { Formik } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { authPostDataWithNoResponse } from '../../../api/tools';
import ContractorPageTemplate from '../../../components/ContractorPageTemplate';
import { useModal } from '../../../components/helper';
import SuccessModal from '../../../components/modals/SuccessModal';
import SmallHeader from '../../../components/SmallHeader';
import { API_GATEWAY } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import AddTimesheetForm from './AddTimesheetForm';
import { initialValues, validationSchema } from './AddTimesheetForm/validate';

import Loader from '../../../components/Loader';

const AddTimesheet = () => {
    const { id } = useParams();

    const [sending, setSending] = React.useState(false);

    const { toggle, isOpen } = useModal();
    const navigate = useNavigate();

    const props = {
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setSending(true);
            await authPostDataWithNoResponse(values, `${API_GATEWAY}/InvoiceContractor/Contracts/${id}/Timesheets`);
            setSending(false);
            toggle();
        },
    };

    const closeModal = () => {
        toggle();
        navigate(-1);
    };

    return (
        <ContractorPageTemplate withArrow>
            <SuccessModal
                header="Add timesheet"
                body="New timesheet was added sucessfully."
                toggle={closeModal}
                isOpen={isOpen}
            />
            <Card>
                <CardBody>
                    <SmallHeader>Add new timesheet</SmallHeader>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <Formik {...props}>
                                {(formik) => <AddTimesheetForm formik={formik} sending={sending} />}
                            </Formik>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </ContractorPageTemplate>
    );
};

export default withAuthProtectedRouter(AddTimesheet, [USER]);
