import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, NEVER_REFRESH } from '../../../api/tools';
import NudgeButton from '../../../components/buttons/NudgeButton';
import NudgeTabs from '../../../components/NudgeTabs';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { FLASK_API } from '../../../consts/urls';
import { URLS } from '../../../consts/urls';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';

const AdminNeodash = () => {
    const TABS = {
        UPCOMING_EVENTS: 'upcoming-events',
        CANDIDATES: 'candidates',
        PROJECT_DETAILS: 'project-details',
    };

    const [selectedTab, setSelectedTab] = React.useState(Object.values(TABS)[0]);
    const navigate = useNavigate();

    const { data: neoData } = useSWR(`${FLASK_API}/checklist/events`, authFetcher, NEVER_REFRESH);

    return (
        <SignInPageTemplate>
            <Helmet>
                <title>NEO Talent Dashboard - Nudge Energy Talent Lab</title>
            </Helmet>
            <Row>
                <Col md={12}>
                    <NudgeTabs
                        label="Dashboard"
                        selected={selectedTab}
                        setTab={setSelectedTab}
                        tabs={[
                            {
                                id: TABS.UPCOMING_EVENTS,
                                label: `Upcoming Events (${neoData?.events?.length})`,
                                condition: neoData,
                            },
                            {
                                id: TABS.CANDIDATES,
                                label: `Candidates (${neoData?.candidates?.length})`,
                                condition: neoData,
                            },
                            {
                                id: TABS.PROJECT_DETAILS,
                                label: 'Project Schedule',
                                condition: neoData,
                            },
                        ]}
                    />
                </Col>
                <Col md={12}>
                    {selectedTab === TABS.UPCOMING_EVENTS ? (
                        neoData ? (
                            neoData.events.map((event) => (
                                <Card
                                    key={event.id}
                                    style={{
                                        padding: '24px',
                                        marginBottom: '24px',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    {event.type ? (
                                        event.type === 'webinar' ? (
                                            <h5>Webinar</h5>
                                        ) : event.type === 'call' && event.contractor_name ? (
                                            <h5>
                                                Meeting with{' '}
                                                <Link to={`${URLS.ADMIN_CONTRACTORS}/${event.contractor_username}`}>
                                                    {event.contractor_name}
                                                </Link>
                                            </h5>
                                        ) : null
                                    ) : null}
                                    <p>{event.name}</p>
                                    <p style={{ paddingBottom: '0' }}>
                                        {moment(event.date).format('Do MMMM YYYY, h:mma')} (
                                        {moment(event.date).fromNow()})
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ flex: 1, padding: '0 5px' }}>
                                            <NudgeButton
                                                color="success"
                                                style={{ width: '100%' }}
                                                onClick={() =>
                                                    navigate(
                                                        `${
                                                            event.type === 'webinar'
                                                                ? URLS.ADMIN_WEBINAR + '/' + event.id
                                                                : URLS.ADMIN_NEOTALENT +
                                                                  '/' +
                                                                  event.contractor_username +
                                                                  '/meeting/' +
                                                                  event.id
                                                        }`,
                                                    )
                                                }
                                            >
                                                Join via Nudge
                                            </NudgeButton>
                                        </div>
                                        <div style={{ flex: 1, padding: '0 5px' }}>
                                            <NudgeButton
                                                color="success"
                                                style={{ width: '100%' }}
                                                onClick={() =>
                                                    window.open(
                                                        'https://app.zoom.us/wc/' +
                                                            event.meeting.id +
                                                            '/join?fromPWA=1&pwd=' +
                                                            event.meeting.password,
                                                    )
                                                }
                                            >
                                                Join externally
                                            </NudgeButton>
                                        </div>
                                    </div>
                                </Card>
                            ))
                        ) : (
                            <p>Loading...</p>
                        )
                    ) : selectedTab === TABS.CANDIDATES ? (
                        neoData ? (
                            neoData.candidates.map((candidate) => (
                                <Card
                                    key={candidate.id}
                                    style={{
                                        padding: '24px',
                                        marginBottom: '24px',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <h5>
                                        <Link to={`${URLS.ADMIN_CONTRACTORS}/${candidate.username}`}>
                                            {candidate.name}
                                        </Link>
                                    </h5>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ flex: 1, padding: '0 5px' }}>
                                            <NudgeButton
                                                color="success"
                                                style={{ width: '100%' }}
                                                onClick={() =>
                                                    navigate(`${URLS.ADMIN_NEOTALENT}/${candidate.username}`)
                                                }
                                            >
                                                View NEO Talent Progress
                                            </NudgeButton>
                                        </div>
                                    </div>
                                </Card>
                            ))
                        ) : (
                            <p>Loading...</p>
                        )
                    ) : selectedTab === TABS.PROJECT_DETAILS ? (
                        neoData ? (
                            <table className={style.prettytable}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Document</th>
                                        <th>Period</th>
                                        <th>Meeting ID</th>
                                        <th>Meeting Password</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {neoData.default.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.id}</td>
                                            <td>{row.name}</td>
                                            <td>{row.type}</td>
                                            <td title={row.date}>
                                                {row.date ? moment(row.date).format('Do MMMM YYYY, h:mma') : null}
                                            </td>
                                            <td>
                                                {row.document ? (
                                                    <a href={row.document} target="_blank" rel="noreferrer">
                                                        {row.document}
                                                    </a>
                                                ) : null}
                                            </td>
                                            <td>{row.period}</td>
                                            <td>
                                                {row.meeting ? (
                                                    <a
                                                        href={
                                                            'https://app.zoom.us/wc/' +
                                                            row.meeting.id +
                                                            '/join?fromPWA=1&pwd=' +
                                                            row.meeting.password
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {row.meeting.id}
                                                    </a>
                                                ) : null}
                                            </td>
                                            <td>{row.meeting ? row.meeting.password : null}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>Loading...</p>
                        )
                    ) : null}
                </Col>
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(AdminNeodash, [ADMIN]);
