import React from 'react';
import RecruiterList from '../CompanyMatching/RecruiterList';
import { Button, Card, Col, Input, Label, Row } from 'reactstrap';
import { API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import { authPostData } from '../../../../api/tools';
import { useParams } from 'react-router-dom';
import NudgeTooltip from '../../../NudgeTooltip';
import NudgeButton from '../../../buttons/NudgeButton';

const RecruitersTab = ({ help, referred, mutate }) => {
    const { id } = useParams();
    const [recruiters, setRecruiters] = React.useState([]);
    
    const [askingHelp, setAskingHelp] = React.useState(false);
    const [extraTalent, setExtraTalent] = React.useState(1);
    const [key, setKey] = React.useState(false);
    
    function validateExtraTalent(input) {
        var parsed = parseInt(input);

        if (`${parsed}` == `NaN`) {
            return 'Must be a number.';
        } else {
            if (parsed < 1 || parsed > 10) {
                return 'Must be between 1 and 10.'; //"Must be between 1 and 10.";
            }
        }

        return false;
    }

    function recruiterSet(set) {
        setRecruiters(set);
        setKey(!key);
    }

    const recruiterObj = {
        set: recruiterSet,
        get: recruiters,
    };

    async function askForHelp() {
        const url = `${API_DASHBOARD_COMPANY}/Offers/${id}/Help`;
        const obj = {
            recruiters: recruiters,
            extraTalent: parseInt(extraTalent)
        }

        setAskingHelp(true);
        
        await authPostData(obj, url);
        await mutate();

        recruiterObj.set([]);

        setAskingHelp(false);
    }
    
    return (
            <RecruiterList recruiters={recruiterObj} referred={referred}>
                <Row>
                    { !referred?.length ? <Col md={6}>
                        <NudgeTooltip label={<Label>No. of Extra Talent</Label>} id={'extraTalent'}>
                            This is the number of extra talent you're requesting to be found.
                        </NudgeTooltip>
                        
                        <Input
                            value={extraTalent}
                            invalid={validateExtraTalent(extraTalent)}
                            onChange={(e) => {
                                setExtraTalent(e.target.value);
                            }}
                        />
                        <p style={{ color: 'red', marginTop: '6px' }}>
                            {validateExtraTalent(extraTalent)}
                        </p>
                    </Col> : null }
                    <Col md={ !referred?.length ? 6 : 12 }>
                        <NudgeButton
                            style={{ marginTop: '6px', height: '65px' }}
                            disabled={askingHelp || !recruiters.length || validateExtraTalent(extraTalent)}
                            onClick={askForHelp}
                            color="success"
                        >
                            {askingHelp
                                ? `Requesting...`
                                : 'Request Additional Support'}
                        </NudgeButton>
                    </Col>
                </Row>
            </RecruiterList>
    )
}

export default RecruitersTab;