import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import LinkButton from '../../../components/buttons/LinkButton';
import { ProcessSalary } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import { MATCH_STATUSES, MATCH_STATUSES_DICTIONARY } from '../../../consts/matchOfferStatuses';
import { URLS } from '../../../consts/urls';
import style from './style.module.scss';

export const renderActionCell = (matchId, timesheetId) => (
    <Row>
        <Col md={timesheetId ? 6 : 12}>
            <LinkButton
                link={`${URLS.COMPANY_INTERVIEWS}/${matchId}`}
                className={style.viewButton}
                target="_blank"
                rel="noopener noreferrer"
            >
                View Interview
            </LinkButton>
        </Col>
        {timesheetId ? (
            <Col md={6}>
                <LinkButton
                    link={`${URLS.COMPANY_CONTRACT_TIMESHEETS}/${timesheetId}`}
                    className={style.viewButton}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Timesheets
                </LinkButton>
            </Col>
        ) : null}
    </Row>
);

function getStatusStyle(status) {
    switch (status) {
        case MATCH_STATUSES.CompanyAccepted:
            return style.accepted;
        case MATCH_STATUSES.Rejected:
        case MATCH_STATUSES.CompanyRejected:
            return style.rejected;
        default:
            return style.status;
    }
}

export const renderOfferCell = ({ cell }) => (
    <div className="text-strong">
        <Link
            to={`${URLS.COMPANY_CONTRACTS}/${cell.row.original.offerId}`}
            className="d-block"
            target="_blank"
            rel="noopener noreferrer"
        >
            {cell.row.original.offerName}
        </Link>
    </div>
);

export const renderStatusCell = ({ cell }) => (
    <div style={{ float: 'right' }} className={`${getStatusStyle(cell.row.original.matchStatus)}`}>
        {MATCH_STATUSES_DICTIONARY[cell.row.original.matchStatus]}
    </div>
);

export const renderRangeCell = ({ cell }) => {
    let split = cell.row.original.rangeExpected.split(' ');

    return split[0] == split[2] ? (
        <div>{`${ProcessSalary(split[0])} per ${split[4]}`}</div>
    ) : (
        <div>{`${ProcessSalary(split[0])} to ${ProcessSalary(split[2])} per ${split[4]}`}</div>
    );
};

export const renderContractorNameCell = ({ cell }) => (
    <Link
        to={`/company/offer/${cell.row.original.offerId}/contractors/${cell.row.original.contractor.id}`}
        className={style.nameCell}
    >
        {cell.row.original.contractor.name}
    </Link>
);
