import cn from 'classnames';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Label } from 'reactstrap';

import SelectField from '../../SelectField';

import ArrayFieldWithPlusAndMinus from '../ArrayFieldWithPlusAndMinus';
import SkipAndSubmit from '../SkipAndSubmit';

import style from './style.module.scss';
import NewArrayFieldForm from '../NewArrayFieldForm';
import { convertNumber } from '../NewInputForm/helpers';
import { authFetcher, fetcherWithAuthorizationHeader } from '../../../api/tools';

const getErrorForField = (formik, index, basicName, add) =>
    formik.errors[basicName] &&
    formik.errors[basicName][index] &&
    formik.errors[basicName][index][add] &&
    formik.submitCount > 0;

const ErrorMessage = ({ formik, index, basicName, add }) => (
    <div className={`custom-input-error ${style.error}`}>{getErrorForField(formik, index, basicName, add)}</div>
);



const ArrayField = ({ index, formik, getName, basicName, addValue, confirm }) => {

    const options = [
        { label: 'Text', value: 'text' },
        { label: 'Video', value: 'video' },
        { label: 'Audio', value: 'audio' },
        { label: 'File Upload', value: 'file' },
        { label: 'ID Check', value: 'idv', isDisabled: formik.values.questions.map((item) => item.type).includes("idv") },
    ];
    

    const country_options = [
        { label: 'United Kingdom', value: 'GBR' },
        { label: 'United States of America', value: 'USA' },
    ]

    const formObjects = [
        { name: 'question', label: `Question`, size: 7, condition: 'text/video/audio/file/idv' },
        {
            name: 'type',
            label: `Question Type`,
            size: 5,
            condition: 'text/video/audio/file/idv',
            options: options,
            component: SelectField,
        },
        { name: 'max_words', label: `Max Words`, size: 12, condition: 'text' },
        { name: 'max_retakes', label: `Max Retakes`, size: 4, condition: 'video/audio' },
        { name: 'max_duration', label: `Max Duration (seconds)`, size: 4, condition: 'video/audio' },
        { name: 'thinking_time', label: `Thinking Time (seconds)`, size: 4, condition: 'video/audio' },
        { name: 'location_of_employment', label: `Location of Employment`, size: 12, condition: 'idv', options: country_options, component: SelectField },
    ];

    const body = (
        <>
            {formObjects.map((item) =>
                item.condition.includes(formik.values[basicName][index].type) ? (
                    <Col xs={12} md={{ size: item.size }}>
                        <h6 className={style.headers}>{item.label}</h6>
                        <Field
                            name={`${getName(index)}.${item.name}`}
                            component={item.options ? item.component : null}
                            options={item.options}
                            placeholder={`${item.label}`}
                            className={cn('form-control', {
                                'is-invalid': getErrorForField(formik, index, basicName, item.name),
                            })}
                        />
                        <ErrorMessage formik={formik} index={index} basicName={basicName} add={item.name} />
                    </Col>
                ) : null,
            )}
            { formik.values[basicName][index].type == 'idv' ? <Col md={{ size: 12, offset: 0}}>{confirm}</Col> : null }
        </>
    );
    
    return (
        <NewArrayFieldForm formik={formik} index={index} field={basicName} addValue={addValue} fieldLimit={8} labelGap>
            {body}
        </NewArrayFieldForm>
    );
};

const QuestionsForm = ({ formik, skipCurrentStep, alwaysShowMinus, basicName, getName, addValue, children }) => (
    <FieldArray name={basicName}>
        {(arrayHelpers) => (
            <>
                {formik.values[basicName].map((_, index) => (
                    <>
                        <ArrayField
                            key={index}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            formik={formik}
                            alwaysShowMinus={alwaysShowMinus}
                            getName={getName}
                            basicName={basicName}
                            addValue={addValue}
                            confirm={children}
                        />
                    </>
                ))}

                {skipCurrentStep ? <SkipAndSubmit skipCurrentStep={skipCurrentStep} formik={formik} /> : null}
            </>
        )}
    </FieldArray>
);

QuestionsForm.propTypes = {
    formik: PropTypes.object.isRequired,
    skipCurrentStep: PropTypes.func,
    alwaysShowMinus: PropTypes.bool,
    basicName: PropTypes.string.isRequired,
    getName: PropTypes.func.isRequired,
};

export default QuestionsForm;
