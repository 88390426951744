import React from 'react';
import { Container } from 'reactstrap';

const SimpleLoader = () => (
    <Container>
        <div className="flex-center-justify">Loading...</div>
    </Container>
);

export default SimpleLoader;
