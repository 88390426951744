import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import Loader from '../../../components/Loader';
import SmallHeader from '../../../components/SmallHeader';
import { formatDateForCalendar_yyy_mm_dd } from '../../../tools/dateHelpers';
import DateSelector from './DateSelector';
import TimeForm from './TimeForm';

import style from './TimeForm/style.module.scss';

const SEPARATOR = '-';

const CalendarCard = ({ data, selectedDate, recount, setSelectedDate, props, id }) => (
    <Card className="mb-40">
        <CardBody>
            <SmallHeader>
                <b>Choose a date from calendar below, good luck!</b>
            </SmallHeader>
            {window.location.href.includes('reschedule') ? (
                <SmallHeader>You can reschedule a total of {3 - recount} more time(s).</SmallHeader>
            ) : null}

            <Row>
                <Col md={12}>
                    <DateSelector data={data} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                    {selectedDate ? (
                        <>
                            <h5 className={style.help}>Select a time from the available slots.</h5>
                            <Formik {...props}>
                                {(formik) => (
                                    <TimeForm
                                        formik={formik}
                                        id={id}
                                        date={formatDateForCalendar_yyy_mm_dd(selectedDate, SEPARATOR)}
                                    />
                                )}
                            </Formik>
                        </>
                    ) : (
                        <h5 className={style.help}>Select a date from the calendar to view available slots.</h5>
                    )}
                </Col>
            </Row>
        </CardBody>
    </Card>
);

CalendarCard.propTypes = {
    data: PropTypes.object.isRequired,
    selectedDate: PropTypes.object.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    props: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};

export default CalendarCard;
