import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const ContractDetailsCard = ({ data }) => (
    <Card>
        <CardBody>
            <Row>
                <Col xs="6" md="3">
                    Contract Title: <h2>{data.title}</h2>
                </Col>
                <Col xs="6" md="3">
                    Contractor: <h2>{data.contractorName}</h2>
                </Col>
            </Row>
        </CardBody>
    </Card>
);

export default ContractDetailsCard;
