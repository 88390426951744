import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import DismissModalForm from './DismissModalForm';
import { initialValues, validationSchema } from './DismissModalForm/validation';
import style from './style.module.scss';
import { GetPageTheme } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import NudgeModal from '../../../../components/NudgeModal';

const DismissCandidateModal = ({ isOpen, toggle, onSubmit, showRejectTooltip }) => {
    const props = {
        initialValues,
        validationSchema,
        onSubmit,
    };

    return (
        <NudgeModal headerText={"Why did this candidate not suit your needs?"} id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered>
            <ModalBody className={style.modalBody}>
                <Formik {...props}>{(formik) => <DismissModalForm formik={formik} />}</Formik>
            </ModalBody>
            {showRejectTooltip ? <div className={style.tooltip}>The contract has been declined.</div> : null}
        </NudgeModal>
    );
};

DismissCandidateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showRejectTooltip: PropTypes.bool,
};

export default DismissCandidateModal;
