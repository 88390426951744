import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FIELDS } from '../validation';

import style from '../style.module.scss';

const OfferField = ({ selected, isEdit, showOnEdit, label, click, children }) => {
    var techLabel = '';
    
    if (click) {
        techLabel = `${click}`.split('FIELDS.')[1];

        if(techLabel) {
            techLabel = techLabel.substring(0, techLabel.length - 1);
            techLabel = FIELDS[techLabel];
        }

    }

    return isEdit ? (
        <>
            {showOnEdit ? (
                <FormGroup className={style.formField} onClick={click} onMouseEnter={click}>
                    {label ? (
                        <Label for={techLabel} className={selected == techLabel ? style.selectedLabel : style.label}>{label}</Label>
                    ) : null}
                    {children}
                </FormGroup>
            ) : null}
        </>
    ) : (
        <>
            <FormGroup className={style.formField} onClick={click} onMouseEnter={click}>
                {label ? (
                    <Label for={techLabel} className={selected == techLabel ? style.selectedLabel : style.label}>{label}</Label>
                ) : null}
                {children}
            </FormGroup>
        </>
    );
};

export default OfferField;
