import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'formik';
import SelectField from '../../../../SelectField';
import AppInput from '../../../../AppInput';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Form, Col, Row } from 'reactstrap';
import style from './style.module.scss';
import { FIELDS, OPTIONS } from './validation';
import { GetPageTheme } from '../../../../pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../../../buttons/NudgeButton';
import NudgeModal from '../../../../NudgeModal';

const options = [
    { label: 'Position no longer available.', value: OPTIONS.NOT_AVAILABLE },
    { label: 'Position has been filled internally.', value: OPTIONS.INTERNAL },
    { label: 'Other Reason', value: OPTIONS.OTHER },
];

const ifOther = (f) => {
    if (f.values.feedback === OPTIONS.OTHER) return true;
    return false;
};

const FeedbackModal = ({ isOpen, toggle, onSend, formik }) => (
    <NudgeModal id={`modal-${GetPageTheme()}`} isOpen={isOpen} toggle={toggle} className={style.modal} centered headerText={"Send Feedback to rejected candidates?"}>
        <ModalBody className={style.modalBody}>
            <div className={style.text}>Choose the feedback you would like to send to candidates.</div>

            <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-20 ">
                    <Field
                        name={FIELDS.FEEDBACK}
                        id={FIELDS.FEEDBACK}
                        component={SelectField}
                        options={options}
                        placeholder="Feedback here"
                    />
                </FormGroup>
                {ifOther(formik) ? (
                    <FormGroup className="mb-20 ">
                        <Field
                            name={FIELDS.OTHER}
                            id={FIELDS.OTHER}
                            formik={formik}
                            component={AppInput}
                            label="Other Reason"
                        />
                    </FormGroup>
                ) : null}

                <Row>
                    <Col xs={6} md={{ size: 4, offset: 2 }}>
                        <ClickButton size="lg" color="danger" onClick={onSend}>
                            Submit
                        </ClickButton>
                    </Col>
                    <Col xs={6} md={{ size: 4 }}>
                        <ClickButton size="lg" color="success" onClick={toggle}>
                            Go Back
                        </ClickButton>
                    </Col>
                </Row>
            </Form>
        </ModalBody>
    </NudgeModal>
);

FeedbackModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default FeedbackModal;
