import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { authPostData } from '../../../api/tools';
import { authFetcher } from '../../../api/tools';
import { FLASK_API } from '../../../consts/urls';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

const NeoTalentMeetingFrame = () => {
    window.zE('messenger', 'hide');

    const { user, userRoles } = React.useContext(AuthContext);
    const adminContractorId = useParams().userId;

    const resultsUrl = !userRoles.includes('Admin')
        ? `${FLASK_API}/checklist`
        : `${FLASK_API}/checklist/${adminContractorId}`;
    const { data } = useSWR(resultsUrl, authFetcher, { revalidateOnFocus: false });

    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);

    var tokenUrl = `${FLASK_API}/checklist/meeting-token`;
    if (userRoles.includes('Admin') && adminContractorId === '0') {
        tokenUrl = `${FLASK_API}/checklist/admin-webinar-token`;
    }

    const [ZoomMtg, setZoomMtg] = useState(null);

    const joinMeeting = async () => {
        if (!ZoomMtg) {
            const { ZoomMtg: ZoomMtgImport } = await import('@zoom/meetingsdk');
            setZoomMtg(ZoomMtgImport);
        }
        if (ZoomMtg && user) {
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();

            const resp = await authPostData(
                { item: id, username: adminContractorId ? adminContractorId : null },
                tokenUrl,
            );
            ZoomMtg.init({
                leaveUrl: `${window.location.origin}/meeting-end`,
                disablePreview: true,
                isSupportAV: true,
                success: function () {
                    ZoomMtg.join({
                        meetingNumber: resp.meeting.id,
                        userName: !userRoles.includes('Admin') ? user.name : 'Nudge',
                        signature: resp.token,
                        sdkKey: resp.client_id,
                        passWord: resp.meeting.password,
                        success: function (res) {
                            console.log('join meeting success');
                        },
                        error: function (res) {
                            console.log(res);
                        },
                    });
                },
                error: function (res) {
                    console.log(res);
                },
            });

            ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
                if (data.reason && data.reason === 'You are leave meeting by yourself') {
                    window.parent.postMessage('selfLeave');
                }
            });
        }
    };

    React.useEffect(() => {
        if (data) {
            joinMeeting();
        }

        const handleBeforeUnload = () => {
            if (ZoomMtg) {
                ZoomMtg.leaveMeeting({});
            }
        };

        const handleMessage = ({ data }) => {
            if (data === 'leave') {
                if (ZoomMtg) {
                    ZoomMtg.leaveMeeting({});
                }
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('message', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, ZoomMtg, user]);

    return null;
};

export default NeoTalentMeetingFrame;
