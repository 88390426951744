import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style.module.scss';
import NudgeCheckbox from '../../../../components/NudgeCheckbox';

const RequiredBox = ({ formik, basicName, label, value = true }) => {
    const [key, setKey] = useState(false);

    const toggleCheck = () => {
        const formValue = formik.values[basicName];

        formik.setFieldValue(basicName, formValue == value ? '' : value);

        setKey(!key);
    };

    return (
        <NudgeCheckbox checked={formik.values[basicName] == value} label={label} onClick={toggleCheck} error={formik.errors[basicName]} value={value}/>
    );
};

export default RequiredBox;
