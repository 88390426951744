import React from 'react';
import {
    deleteWithAuthorizationHeader,
    fetcherWithAuthorizationHeader,
    postWithAuthorizationHeader,
} from '../../../../../api/tools';
import Loader from '../../../../../components/Loader';

import { Row, Col, Button, Card } from 'reactstrap';
import style from '../style.module.scss';
import { API_DASHBOARD_CLOOD, API_DASHBOARD_WILLO } from '../../../../../consts/urls';
import NudgeButton from '../../../../../components/buttons/NudgeButton';

const ManageWebhooks = ({ api_key }) => {
    const [data, setData] = React.useState();
    const [page, setPage] = React.useState(1);

    const [sending, setSending] = React.useState(false);

    let fetchUrl = `https://api.willotalent.com/api/integrations/v2/webhooks/`;

    React.useEffect(() => {
        async function getData() {
            setSending(true);

            const response = await fetcherWithAuthorizationHeader(fetchUrl, api_key);

            setData(response);

            setSending(false);
        }

        getData();
    }, [fetchUrl]);

    async function createWebhook() {
        const url = ``;
        let postUrl = `https://api.willotalent.com/api/integrations/v2/webhooks/`;

        const body = {
            follow_all_interviews: true,
            url: 'https://nudgeapi-env.nudgeexchange.com/api/Video/Matches/MatchResult',
            interviews: [],
            event: 'stage_change',
            token: '7turz0IMFoFe28pK9UbX9rhhkl5vYE0wZJfM',
        };

        const response = await postWithAuthorizationHeader(body, postUrl, api_key);
    }

    async function deleteWebhook(key) {
        const url = `https://api.willotalent.com/api/integrations/v2/webhooks/${key}/`;

        const response = await deleteWithAuthorizationHeader(url, api_key);
    }

    async function testWebhook() {
        const url = `${API_DASHBOARD_WILLO}/Matches/MatchResult`;

        const matchId = '867';
        const question = {
            key: 'd0e74f1cf8224bf1bbdb7dc51d9d5a9e',
            created_at: '2022-08-10T01:30:00Z',
            updated_at: '2022-08-10T01:30:00Z',
            is_finished: true,
            media_extension: 'none',
            remote_link: 'https://willotalent.com/3fvdvhj34v.mp4/',
            is_media_deleted: false,
            text: null,
            question: {
                key: '46c8b4dff2364f37ac3ad90f72349716',
                text: 'Didjupuyuneimindagoblidrfar?',
                order: 1,
                max_duration: 60,
                max_retakes: 5,
                answer_type: 'text',
                thinking_time: 300,
                max_characters: null,
                max_words: null,
            },
        };

        const body = {
            key: 'd7964979cd364d59b3d6c457712d8857',
            email: 'rosa.parks@yahoo.com',
            name: 'Rosa Parks',
            phone: '+13373484584543',
            status: 'Invited',
            send_notifications: true,
            custom_id: '940',
            interview: {
                key: '12f46389d89b410ba75cff562a52b679',
                title: 'Activist',
            },
            department: {
                key: 'c8a065f2f0454bb681f8212446c16bd9',
                name: 'HR',
            },
            created_at: '2022-08-15T01:30:00Z',
            updated_at: '2022-08-15T01:30:00Z',
            location: {
                city: 'Detroit',
                country_name: 'United States',
                country_code: 'US',
            },
            showcase_link: 'https://willotalent.com/3v3v34/',
            candidate_overview_link: 'https://willotalent.com/interviwer/3v3v34/',
            avatar_remote_link: 'https://willotalent.com/avatar.png/',
            availability: [
                '2023-03-27T10:00:00Z',
                '2023-03-27T10:30:00Z',
                '2023-03-28T10:30:00Z',
                '2023-03-28T10:00:00Z',
                '2023-03-28T09:30:00Z',
                '2023-03-29T09:00:00Z',
                '2023-03-30T09:00:00Z',
                '2023-03-30T10:30:00Z',
                '2023-03-30T11:00:00Z',
                '2023-03-29T11:30:00Z',
                '2023-03-30T11:30:00Z',
                '2023-03-29T12:00:00Z',
                '2023-03-30T12:00:00Z',
                '2023-03-30T12:30:00Z',
                '2023-03-28T12:30:00Z',
                '2023-03-28T13:00:00Z',
                '2023-03-29T13:00:00Z',
                '2023-03-27T13:00:00Z',
                '2023-03-27T13:30:00Z',
                '2023-03-27T14:00:00Z',
                '2023-03-27T14:30:00Z',
                '2023-03-28T14:30:00Z',
                '2023-03-27T15:00:00Z',
                '2023-03-28T15:00:00Z',
                '2023-03-29T15:00:00Z',
                '2023-03-30T15:00:00Z',
                '2023-03-30T14:00:00Z',
                '2023-03-31T14:00:00Z',
                '2023-03-30T14:30:00Z',
                '2023-03-31T14:30:00Z',
                '2023-03-31T12:00:00Z',
                '2023-03-31T12:30:00Z',
                '2023-03-31T13:00:00Z',
                '2023-03-31T09:30:00Z',
                '2023-03-31T10:00:00Z',
                '2023-03-31T10:30:00Z',
                '2023-03-31T11:00:00Z',
                '2023-03-31T08:00:00Z',
                '2023-03-31T08:30:00Z',
            ],
            answers: [
                {
                    key: 'd0e74f1cf8224bf1bbdb7dc51d9d5a9e',
                    created_at: '2022-08-10T01:30:00Z',
                    updated_at: '2022-08-10T01:30:00Z',
                    is_finished: true,
                    media_extension: 'mp4',
                    remote_link: 'https://willotalent.com/3fvdvhj34v.mp4/',
                    is_media_deleted: false,
                    text: null,
                    additional_info: {
                        share_code: 'DDD-EEE-FFF',
                    },
                    question: {
                        key: '46c8b4dff2364f37ac3ad90f72349716',
                        text: 'Didjupuyuneimindagoblidrfar?',
                        order: 1,
                        max_duration: 60,
                        max_retakes: 5,
                        answer_type: 'video',
                        thinking_time: 300,
                        max_characters: 75,
                        max_words: 75,
                    },
                },
            ],
        };

        await postWithAuthorizationHeader(body, url, '7turz0IMFoFe28pK9UbX9rhhkl5vYE0wZJfM');
    }

    return data ? (
        <>
            <Row>
                <NudgeButton color="success" onClick={testWebhook}>
                    Test
                </NudgeButton>

                <NudgeButton color="success" onClick={createWebhook}>
                    Create
                </NudgeButton>
                <Col md={12}>
                    {data.results && !sending ? (
                        data.results.map((item, index) => (
                            <Card className={style.card}>
                                <div>
                                    <h5 className={style.content}>
                                        {item.key} - {item.event}
                                    </h5>
                                    <h5 className={style.content}>{item.url}</h5>
                                    <NudgeButton color="danger" onClick={() => deleteWebhook(item.key)}>
                                        Delete Webhook
                                    </NudgeButton>
                                </div>
                                <br />
                            </Card>
                        ))
                    ) : (
                        <Loader />
                    )}
                </Col>
            </Row>
        </>
    ) : (
        <Loader />
    );
};

export default ManageWebhooks;
