import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardTitle, FormGroup, Label, Tooltip } from 'reactstrap';

import nudge_logo_white from '../../assets/images/nudge-n-chalk.png';
import nudge_logo from '../../assets/images/nudge-n-forest.png';
import pattern from '../../assets/videos/PATTERN_HD.mp4';
import AppInput from '../../components/AppInput';
import CustomFormikError from '../../components/CustomFormikError';
import { URLS } from '../../consts/urls';
import { ADMIN, COMPANY, RECRUITER, USER } from '../../consts/userRoles';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import { checkIfEmailExists } from '../../contexts/auth/tools';
import { signUpUserHelper } from './signUpHelper';
import style from './style.module.scss';
import { FIELDS, initialValues, REG_EX, validationSchema } from './validation';
import Status from './Status';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import ClickButton from '../../components/buttons/NudgeButton';
import NudgeTooltip from '../../components/NudgeTooltip';

const SingleSignUp = () => {

    const { id } = useParams();

    const [sending, setSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasSignedUp] = useState(0);
    const navigate = useNavigate();

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const activateSignUp = (v) => {
        navigate(`${URLS.SIMPLE_ACCOUNT_VERIFY}`, { state: { userEmail: v.email, userPassword: v.password } });
    };

    const { isAuth, isAuthenticating, userRoles } = useContext(AuthContext);

    if (isAuthenticating === false && isAuth === true) {
        if (userRoles.includes(ADMIN)) {
            return <Navigate to={URLS.ADMIN_DASHBOARD} />;
        } else if (userRoles.includes(COMPANY)) {
            return <Navigate to={URLS.COMPANY_DASHBOARD} />;
        } else if (userRoles.includes(USER)) {
            return <Navigate to={URLS.DASHBOARD} />;
        } else if (userRoles.includes(RECRUITER)) {
            return <Navigate to={URLS.RECRUITER_DASHBOARD} />;
        }
    }

    const validateLength = (p) => {
        if (p.length >= 8) {
            return style.successOrb;
        } else {
            return style.errorOrb;
        }
    };

    const matchExpression = (p) => {
        if (REG_EX.test(p)) {
            return style.successOrb;
        } else {
            return style.errorOrb;
        }
    };

    const submitMethod = (v) => {
        try {
            signUpUserHelper(v, navigate, function () {
                activateSignUp(v);
            });
        } catch (e) {
            setErrorMessage('There was a problem signing up.');
        } finally {
            setSending(false);
        }
    };

    const props = {
        initialValues: { ...initialValues, refCode: id },
        validationSchema,
        onSubmit: async (values) => {
            const isEmailAvailable = await checkIfEmailExists(values.email);
            setSending(true);

            if (!isEmailAvailable) {
                submitMethod(values);
            } else {
                setErrorMessage('An account with the given email already exists.');
            }
        },
    };
    const theme = GetPageTheme();

    return (
        <div className={style.box}>
            <div className={style.half1}>
                <Helmet>
                    <title>Sign Up - Nudge Energy Talent Lab</title>
                </Helmet>
                <h5 className={style.videoSubtitle}>
                    <b>Find Your Why</b>
                    <br />
                    <i style={{ fontSize: '24px' }}>
                        Nudge theory: To help people without forcing them. From that small nudge comes a whole new life
                        and a whole new energy industry all from a single nudge.
                    </i>
                </h5>
                <video className={style.video} autoPlay muted loop id="myVideo">
                    <source id="myVideo" src={pattern} type="video/mp4" />
                </video>
            </div>
            <div className={style.half2}>
                <div className={theme == 'light' ? style.logoContainer : style.logoContainerDark}>
                    <Link to={URLS.HOME}>
                        <img src={nudge_logo_white} className={style.nudgeLogo} alt="nudge-white" />
                    </Link>
                    <h5 className={style.logoLabel}>Welcome to Nudge!</h5>
                </div>

                <Alert
                    isOpen={!!errorMessage}
                    toggle={() => setErrorMessage('')}
                    color="danger"
                    fade={true}
                    className={style.errorMessage}
                >
                    {errorMessage}
                </Alert>

                {!hasSignedUp ? (
                    <Card id={`card-${theme}`} className={theme == 'light' ? style.signUpCard : style.signUpCardDark}>
                        {!sending ? (
                            <>
                                <CardTitle id={`card-sub-${theme}`}>
                                    <h4 className={style.signUpTitle}>Sign up to get started!</h4>
                                </CardTitle>

                                <Formik {...props}>
                                    {(formik) => (
                                        <div>
                                            <Form
                                                style={{ padding: '18px' }}
                                                noValidate
                                                onReset={formik.handleReset}
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <FormGroup className="mb-20">
                                                    <Label for={FIELDS.NAME}>Name</Label>
                                                    <AppInput id={FIELDS.NAME} formik={formik} label="Name" />
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <Label for={FIELDS.EMAIL}>Email</Label>
                                                    <AppInput id={FIELDS.EMAIL} formik={formik} label="Email" />
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <Label for={FIELDS.PASSWORD}>Password</Label>
                                                    <AppInput
                                                        id={FIELDS.PASSWORD}
                                                        type={'password'}
                                                        formik={formik}
                                                        label="Password"
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <Label for={FIELDS.CONFIRM_PASSWORD}>Confirm Password</Label>
                                                    <AppInput
                                                        id={FIELDS.CONFIRM_PASSWORD}
                                                        type={'password'}
                                                        formik={formik}
                                                        label="Confirm"
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <NudgeTooltip id={id ? "" : `refCode`} label={<Label for={FIELDS.REF_CODE}>Reference Code</Label>}>
                                                        If you were given a code by one of our expert recruiters, type
                                                        it here to be automatically placed into the job posting on
                                                        sign-up.
                                                    </NudgeTooltip>
                                                    <AppInput
                                                        id={FIELDS.REF_CODE}
                                                        formik={formik}
                                                        label="Reference Code"
                                                        isDisabled={!!id}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <FontAwesomeIcon
                                                        className={validateLength(formik.values.password)}
                                                        icon={['fas', 'lock-alt']}
                                                    />
                                                    Password must be 8 characters long.
                                                </FormGroup>

                                                <FormGroup className="mb-20">
                                                    <FontAwesomeIcon
                                                        className={matchExpression(formik.values.password)}
                                                        icon={['fas', 'lock-alt']}
                                                    />
                                                    Password must contain upper and lower case letters and numbers.
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label check>
                                                        <Field
                                                            type="checkbox"
                                                            name={FIELDS.ACCEPT}
                                                            className="mr-2"
                                                            data-testid={FIELDS.ACCEPT}
                                                        />
                                                        <p className="d-inline font-12">
                                                            By signing up you agree to the
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={URLS.SERVICE_TERMS}
                                                            >
                                                                &nbsp; Terms of Service
                                                            </a>
                                                            &nbsp; and
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={URLS.PRIVACY_POLICY}
                                                            >
                                                                &nbsp; Privacy Policy
                                                            </a>
                                                        </p>
                                                    </Label>
                                                    <CustomFormikError form={formik} name={FIELDS.ACCEPT} />
                                                </FormGroup>

                                                <ClickButton
                                                    disabled={sending}
                                                    className={style.signUp}
                                                    color={'success'}
                                                    type="submit"
                                                >
                                                    Sign Up
                                                </ClickButton>

                                                <div className={style.logIn}>
                                                    Already have a Nudge account? <Link to={URLS.SIGN_IN}>Log in!</Link>
                                                </div>
                                            </Form>
                                        </div>
                                    )}
                                </Formik>
                            </>
                        ) : null}
                    </Card>
                ) : (
                    <Card id={`card-${theme}`} className={style.signUpCard}>
                        <CardTitle>
                            <h4 className={style.signUpTitle}>Verifying your Login</h4>
                        </CardTitle>
                        <CardBody>
                            <h5 className={style.verifyBody}>
                                Thanks for signing up! We just need to verify your email address to complete setting up
                                your account.
                                <br />
                                <br />
                                Not received our email? Get in touch with us at{' '}
                                <a href="mailto:nudgeme@nudgeexchange.com">nudgeme@nudgexchange.com</a>.
                            </h5>
                        </CardBody>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default SingleSignUp;
