import { Field, Formik, useFormikContext } from 'formik';
import OsGridRef from 'geodesy/osgridref';
import Papa from 'papaparse';
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Input, InputGroup, Label, Row } from 'reactstrap';

import AutocompleteInput from '../../components/AutocompleteInput';
import SignInPageTemplate from '../../components/SignInPageTemplate';
import { MAP_DATA } from '../../consts/urls';
import { COMPANY, USER } from '../../consts/userRoles';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';
import GoogleMapContainer from './map';

export const ProjectMap = () => {
    const [parsedCsvData, setParsedCsvData] = useState([]);
    const [operatorNames, setOperatorNames] = useState([]);
    const [operatorName, setOperatorName] = useState('');

    const [batteryChecked, setBatteryChecked] = useState(true);
    const [solarChecked, setSolarChecked] = useState(true);
    const [onshoreChecked, setOnshoreChecked] = useState(true);
    const [offshoreChecked, setOffshoreChecked] = useState(true);

    const [submittedChecked, setSubmittedChecked] = useState(true);
    const [awaitingChecked, setAwaitingChecked] = useState(true);
    const [operationalChecked, setOperationalChecked] = useState(true);
    const [underConstructionChecked, setUnderConstructionChecked] = useState(true);

    const mapState = useRef(null);

    const OnMapFormikChange = () => {
        const { values } = useFormikContext();
        useEffect(() => {
            if (values['projectmap-operator'] !== null) {
                if (values['projectmap-operator'] === '' || operatorNames.includes(values['projectmap-operator'])) {
                    setOperatorName(values['projectmap-operator']);
                }
            }
        }, [values]);

        return null;
    };

    useEffect(() => {
        async function getData() {
            let operatorNamesTemp = [];
            let output = [];

            Papa.parse(MAP_DATA, {
                download: true,
                header: true,
                step: function (row) {
                    let outRow = row.data;
                    if (outRow['X-coordinate'] && outRow['Y-coordinate']) {
                        try {
                            var ref = new OsGridRef(
                                parseInt(outRow['X-coordinate']),
                                parseInt(outRow['Y-coordinate']),
                            ).toLatLon();
                            if (ref.lat && ref.lon) {
                                outRow['lat'] = ref.lat;
                                outRow['lng'] = ref.lon;
                            }
                            output.push(outRow);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (outRow['Operator (or Applicant)']) {
                        if (!operatorNamesTemp.includes(outRow['Operator (or Applicant)'])) {
                            operatorNamesTemp.push(outRow['Operator (or Applicant)']);
                        }
                    }
                },
                complete: function () {
                    setParsedCsvData(output);
                    setOperatorNames(operatorNamesTemp);
                },
            });
        }
        getData();
    }, []);

    return (
        <SignInPageTemplate>
            <Row>
                <Col xs={12} xl={4}>
                    <Card>
                        <CardBody>
                            <h3 style={{ marginTop: '0', fontWeight: '600' }}>Project Map</h3>
                            <p>Use our interactive Project Map to find renewable energy opportunities near you!</p>
                            <p>Use the filters below to fine tune your search.</p>

                            <h5>Operator Name</h5>
                            <Formik initialValues={{}}>
                                <>
                                    <OnMapFormikChange />
                                    <Field
                                        name="projectmap-operator"
                                        component={AutocompleteInput}
                                        suggestions={operatorNames}
                                    />
                                </>
                            </Formik>

                            <h5 style={{ marginTop: '1em' }}>Energy Type</h5>
                            <div style={{ marginLeft: '1.25em' }}>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={batteryChecked}
                                            onChange={() => setBatteryChecked(!batteryChecked)}
                                            disabled={false}
                                        />
                                        Battery
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={solarChecked}
                                            onChange={() => setSolarChecked(!solarChecked)}
                                            disabled={false}
                                        />
                                        Solar Photovoltaics
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={onshoreChecked}
                                            onChange={() => setOnshoreChecked(!onshoreChecked)}
                                            disabled={false}
                                        />
                                        Wind Onshore
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={offshoreChecked}
                                            onChange={() => setOffshoreChecked(!offshoreChecked)}
                                            disabled={false}
                                        />
                                        Wind Offshore
                                    </Label>
                                </InputGroup>
                            </div>

                            <h5 style={{ marginTop: '0.5em' }}>Site Status</h5>
                            <div style={{ marginLeft: '1.25em' }}>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={submittedChecked}
                                            onChange={() => setSubmittedChecked(!submittedChecked)}
                                            disabled={false}
                                        />
                                        Application Submitted
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={awaitingChecked}
                                            onChange={() => setAwaitingChecked(!awaitingChecked)}
                                            disabled={false}
                                        />
                                        Awaiting Construction
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={underConstructionChecked}
                                            onChange={() => setUnderConstructionChecked(!underConstructionChecked)}
                                            disabled={false}
                                        />
                                        Under Construction
                                    </Label>
                                </InputGroup>
                                <InputGroup>
                                    <Label>
                                        <Input
                                            type="checkbox"
                                            checked={operationalChecked}
                                            onChange={() => setOperationalChecked(!operationalChecked)}
                                            disabled={false}
                                        />
                                        Operational
                                    </Label>
                                </InputGroup>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} xl={8}>
                    <Card style={{ color: 'black' }}>
                        <CardBody style={{ padding: '0.2em' }}>
                            <div style={{ height: '75vh', width: '100%' }}>
                                {parsedCsvData.length ? (
                                    <GoogleMapContainer
                                        data={{ parsedCsvData }}
                                        ref={mapState}
                                        batteryChecked={batteryChecked}
                                        solarChecked={solarChecked}
                                        onshoreChecked={onshoreChecked}
                                        offshoreChecked={offshoreChecked}
                                        submittedChecked={submittedChecked}
                                        awaitingChecked={awaitingChecked}
                                        operationalChecked={operationalChecked}
                                        underConstructionChecked={underConstructionChecked}
                                        operatorName={operatorName}
                                    />
                                ) : null}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(ProjectMap, [USER, COMPANY]);
