import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Tooltip } from 'reactstrap';
import Status from './Status';

import style from './style.module.scss';
import NudgeTooltip from '../../../NudgeTooltip';

const Step = ({ ref_name, ref_email, ref_message, index, theme }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    ref_name = ref_name || 'Anonymous';

    return (
        <div>
            <div className={style.stepWrapper}>
                <div className={style.stepTexts}>
                    {ref_message ? (
                        <div className={style.reference}>
                            <NudgeTooltip id={`referee-${index}`} label={'Check Message from Referee'} icon={'comment-alt-check'} iconOnly>
                                <h5 className={style.tooltipText}>
                                    From:{' ' + ref_name}
                                    <br />
                                    They said:{' ' + ref_message}
                                </h5>
                            </NudgeTooltip>
                        </div>
                    ) : null}
                    {ref_email && !ref_message ? (
                        <div className={style.reference}>
                            <NudgeTooltip id={`pendingReferee-${index}`} label={'Reference Pending'} icon={'comment-alt-dots'} iconOnly>
                                <h5 className={style.tooltipText}>We are currently verifying this reference.</h5>
                            </NudgeTooltip>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={style.divider} />
        </div>
    );
};

Step.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
};

export default Step;
