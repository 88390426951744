import React from 'react';

import { ContractorProfilePage } from '../../../components/pages/ContractorProfilePage';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

const AdminCandidateProfile = () => {

    return (
        <>
            
            <ContractorProfilePage showEdit showResume />
        </>
    )
}

export default withAuthProtectedRouter(AdminCandidateProfile, [ADMIN]);
