import React, { useState } from 'react';

import { GetPageTheme } from '../pages/GenericCompanyProfile/HelperComponents';
import useMobile from '../../hooks/useMobile';
import style from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NudgeCheckbox = ({ checked, onClick, options, labelOption = 0, label = "Required?", error }) => {
    const [iconState, setIconState] = useState(checked);
    const [key, setKey] = useState(false);
    
    const theme = GetPageTheme();
    const { isMobileWide } = useMobile();

    const toggleCheck = () => {
        const lBool = !checked;

        onClick();
        setIconState(lBool);

        setKey(!key);
    };

    React.useEffect(() => {
        setIconState(checked);
    }, [checked])
    
    const OPTIONS = options || [
        ["OFF", "ON"],
        ["FALSE", "TRUE"],
        ["NO", "YES"],
        ["HIDE", "SHOW"],
        ["icon:moon", "icon:sun"]
    ];

    function resolveIconState() {
        const isChecked = +iconState;
        const state = OPTIONS[labelOption][isChecked];

        if(state.includes("icon")) {
            return <FontAwesomeIcon icon={['fas', OPTIONS[labelOption][isChecked].split("icon:")[1]]}/>
        }

        return state;
    }

    return (<>
        {/* <h5>{ `${iconState}` }</h5> */}
        { !isMobileWide ? <div style={{ display: 'flex', alignItems: 'center', paddingTop: '2px', height: '46px' }}>
            <label style={{ wordWrap: 'break-word', display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '6px' }}>
                <input type="checkbox" value={checked} onChange={() => toggleCheck()} checked={checked}/>
                <h5 style={{ fontSize: '1rem', margin: 0 }}>{label}</h5>
            </label>
            { !!error && <h5 className={theme == 'light' ? style.errorText : style.errorTextDark}>{error}</h5> }
        </div> : <>
            <div className={style.mobileCheckboxContainer}>
                <h5 onClick={() => toggleCheck()} className={style.requiredLabel}>{label}</h5>
                <div onClick={() => toggleCheck()} style={{ background: !OPTIONS[labelOption][+iconState].includes("icon") ? `${iconState == false ? "red" : "green"}` : '' }} className={`${iconState == true ? style.mobileCheckboxOn : style.mobileCheckboxOff} ${ style.checkboxBorder }`}>
                    <h5 style={{ color: !OPTIONS[labelOption][+iconState].includes("icon") ? `${iconState == false ? "red" : "green"}` : '' }} className={iconState ? style.rockerOn : style.rockerOff}>{resolveIconState()}</h5>
                </div>
            </div>
            { !!error && <h5 className={theme == 'light' ? style.errorText : style.errorTextDark}>{error}</h5> }
        </> }
    </>);
};

export default NudgeCheckbox;
