import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import Select from 'react-select';
import { customStyles } from '../../../components/SelectField';
import { authFetcher } from '../../../api/tools';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';
import { API_DASHBOARD_ADMIN, URLS } from '../../../consts/urls';
import { ADMIN } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';

import OfferList from './OfferList';

import NudgeButton from '../../../components/buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deCamelString } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import { Col, Row } from 'reactstrap';
import NudgeCheckbox from '../../../components/NudgeCheckbox';

const columnSizeByIndex = ['1', '1', '2', '1', '1', '1', '1', '1', '1', '1', '1'];
const PER_PAGE = 9;
export const renderActionCell = ({ cell }) => (
    <Link to={`${URLS.ADMIN_CONTRACTS}/${cell.row.original.id}`}>View offer</Link>
);

const AdminDashboardPage = () => {
    const { data, mutate } = useSWR(`${API_DASHBOARD_ADMIN}/Offers`, authFetcher, { refreshInterval: 0 });
    const [ascending, setAscending] = React.useState(true);
    
    // useEffect(() => {
    //     mutate((data) => ({ ...data }));
    //     /* eslint-disable */
    // }, []);
    // /* eslint-enable */

    const [options, setOptions] = React.useState([
        { label: 'Date Created', value: 'id', reverse: false },
        { label: 'Job Name', value: 'jobName', reverse: true },
        { label: 'Company Name', value: 'companyName', reverse: true },
    ]);
    const [sortOption, setSortOption] = React.useState(options[0]);

    const [ascendingOption, setAscendingOption] = React.useState(false);
    const ascendingOptions = [
        { label: <>{'Descending '}<FontAwesomeIcon icon={['far', 'sort-amount-down']}/></>, value: false },
        { label: <>{'Ascending '}<FontAwesomeIcon icon={['far', 'sort-amount-up']}/></>, value: true },
    ]

    React.useEffect(() => {
        setAscending(ascendingOption.value)
    }, [ascendingOption])



    return (
        <SignInPageTemplate>
            <div className={style.header}>
                <h1 className={style.title}>Jobs</h1>
            </div>
            
            <Row className={style.sortRow}>
                <Col xs={12} sm={8} md={8} className={style.col}>
                    <Select
                        defaultValue={options[0]}
                        styles={customStyles()}
                        onChange={(e) => setSortOption(e)}
                        options={options}
                        isSearchable={false}
                    />
                </Col>
                <Col xs={12} sm={4} md={4}>
                    <Select
                        defaultValue={ascendingOptions[0]}
                        styles={customStyles()}
                        onChange={(e) => setAscendingOption(e)}
                        options={ascendingOptions}
                        isSearchable={false}
                    />
                </Col>
            </Row>

            {data ? (
                <>
                    <OfferList offers={data} ascending={ascending} sortOption={sortOption?.value} reverse={sortOption?.reverse}/>
                </>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(AdminDashboardPage, [ADMIN]);
