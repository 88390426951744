import React from 'react';
import { sort_by_key } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { Card } from 'reactstrap';

import AppPagination, { UseAppPagination, calculateBounds } from '../../../../../components/AppPagination';
import VideoModal from './VideoModal';

import style from './style.module.scss';
import MatchFeedbackCard from '../FeedbackForms/MatchFeedbackCard';
import AudioModal from './AudioModal';

//<h5 onClick={() => window.open(answer.resourceLink,'_blank','noopener')} className={style.questionLink}>View Video Response</h5>

function decideMedia(answer) {
    switch (answer.answerType) {
        case 'video':
            return (
                <VideoModal
                    header={answer.questionText}
                    video={answer.resourceLink}
                    extension={`video/${answer.mediaExtension}`}
                />
            );
        case 'audio':
            return (
                <AudioModal
                    header={answer.questionText}
                    audio={answer.resourceLink}
                    extension={`audio/${answer.mediaExtension}`}
                />
            );
        case 'file':
            return (
                <div className="text-center">
                    <a href={answer.resourceLink}>
                        <h5 className={style.resourceLink}>View Uploaded File</h5>
                    </a>
                </div>
            );
        case 'idv':
            return (
                <div className="text-center">
                    <a href={answer.resourceLink}>
                        <h5 className={style.resourceLink}>View ID Check Report</h5>
                    </a>
                </div>
            )
        case 'text':
            return <h5 className={style.questionText}>{answer.answerText}</h5>;
    }
}

const AnswerView = ({ id, user, answers, mutate, children, feedback }) => {
    if (!answers?.length) {
        return (
            <Card className={style.answerCard}>
                <h5>No Answers to View</h5>
            </Card>
        );
    }

    const PER_PAGE = 1;
    const total = answers?.length ? Math.ceil(answers.length / PER_PAGE) : 0;
    const { page, onClickNext, onClickFirst, onClickLast, onClickPrev, onClickPage, setPage } = UseAppPagination(total);
    const { start, end } = calculateBounds(page, PER_PAGE);
    const answersToDisplay = [...sort_by_key(answers, 'questionOrder')].slice(start, end);

    return (
        <>
            {answers?.length ? (
                <Card className={style.answerScroll}>
                    {children}
                    {answersToDisplay.map((item, index) => (
                        <div style={{ display: 'flex', alignItems: 'center', minHeight: '227px' }}>
                            <div className={style.answerContainer} key={index}>
                                <h5 className={style.questionTitle}>{`${item.questionText}`}</h5>
                                <div>{decideMedia(item)}</div>
                            </div>
                        </div>
                    ))}

                    <AppPagination
                        total={total}
                        page={page}
                        onClickNext={onClickNext}
                        onClickPrev={onClickPrev}
                        onClickPage={onClickPage}
                        onClickFirst={onClickFirst}
                        onClickLast={onClickLast}
                        inBuffer={2}
                        canPreviousPage={answers.length > 5 ? true : false}
                        canNextPage={answers.length > 5 ? true : false}
                    />
                </Card>
            ) : null}

            {user ? (
                <MatchFeedbackCard
                    matchId={id}
                    user={user}
                    page={page}
                    answers={answers}
                    setAnswer={setPage}
                    mutate={mutate}
                    feedback={feedback}
                />
            ) : null}
        </>
    );
};

export default AnswerView;
