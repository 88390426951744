import React from 'react';
import { Link } from 'react-router-dom';

import DefaultAvatar from '../../assets/images/avatar.png';
import { URLS } from '../../consts/urls';
import { getDate } from '../../tools/dateHelpers';
import { ProcessSalary } from '../pages/GenericCompanyProfile/HelperComponents';
import style from './style.module.scss';

export const sortByDate = (key) => (a, b) => {
    const a1 = getDate(a.original[key], null);
    const b1 = getDate(b.original[key], null);

    if (a1 < b1) {
        return 1;
    } else if (a1 > b1) {
        return -1;
    } else if (!(a1 && b1)) {
        return 1;
    } else {
        return 0;
    }
};

export const renderLogoCell = (key) => {
    const LogoCell = ({ cell }) => <img src={cell.row.original[key]} className={style.logo} alt="Logo" />;
    LogoCell.displayName = 'LogoCell';
    return LogoCell;
};

export const renderLogoCellByGetter = (getter) => {
    const LogoCellByGetter = ({ cell }) => (
        <div style={{ backgroundImage: `url(${getter(cell.row.original) || DefaultAvatar})` }} className={style.logo} />
    );
    LogoCellByGetter.displayName = 'LogoCellByGetter';
    return LogoCellByGetter;
};

export const renderStatusCell = ({ cell }) => <span className={style.status}>{cell.row.original.offerStatus}</span>;

export const renderAmountCell = ({ cell }) => (
    <span className={style.status}>£{ProcessSalary(cell.row.original.amount)}</span>
);

export const renderNameCell = (idKey = 'id', nameKey = 'name') => {
    const NameCell = ({ cell }) =>
        cell.row.original[nameKey] !== '-1' ? (
            <Link to={`${URLS.ADMIN_COMPANIES}/${cell.row.original[idKey]}`} className={style.nameCell}>
                {cell.row.original[nameKey]}
            </Link>
        ) : null;
    NameCell.displayName = 'NameCell';
    return NameCell;
};

export const renderContractorCell = (idKey = 'id', nameKey = 'name') => {
    const ContractorCell = ({ cell }) =>
        cell.row.original[nameKey] !== '-1' ? (
            <Link to={`${URLS.ADMIN_CONTRACTORS}/${cell.row.original[idKey]}`} className={style.nameCell}>
                {cell.row.original[nameKey]}
            </Link>
        ) : (
            'n/a'
        );
    ContractorCell.displayName = 'ContractorCell';
    return ContractorCell;
};
export const renderNameCellWithId = (idKey = 'id', nameKey = 'title') => {
    const NameCellWithId = ({ cell }) => (
        <strong>
            {cell.row.original[nameKey]} ({cell.row.original[idKey]})
        </strong>
    );
    NameCellWithId.displayName = 'NameCellWithId';
    return NameCellWithId;
};

export const renderNameCellByGetterForContractor = (idGetter, nameGetter) => {
    const NameCellByGetterForContractor = ({ cell }) => (
        <Link to={`${URLS.DIRECTORY}/${idGetter(cell.row.original)}`} className={style.nameCell}>
            {nameGetter(cell.row.original)}
        </Link>
    );
    NameCellByGetterForContractor.displayName = 'NameCellByGetterForContractor';
    return NameCellByGetterForContractor;
};
