import React, { useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import useSWR from 'swr';

import { exFetcher } from '../../api/tools.js';
import Loader from '../../components/Loader';
import SignInPageTemplate from '../../components/SignInPageTemplate';
import { COMPANY, USER } from '../../consts/userRoles';
import { AuthContext } from '../../contexts/auth/AuthContextWrapper';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';
import useMobile from '../../hooks/useMobile.js';

export const Forest = () => {
    const { user } = useContext(AuthContext);
    const { data } = useSWR('https://public.ecologi.com/users/nudge/impact', exFetcher, { refreshInterval: 0 });
    const { isMobileWide } = useMobile();
    
    var treeSymbol = '🌲';
    
    const PER_ROW = isMobileWide ? 10 : 15;
    const [userTrees, setUserTrees] = React.useState([]);
    const [overallTrees, setOverallTrees] = React.useState([]);

    function doTrees(amount) {
        let counter = 0;
        let alt = true;

        let trees = [];

        while(counter <= amount - 1) {
            let addValue = alt ? PER_ROW : PER_ROW - 1;

            if(counter + addValue > amount) {
                addValue = amount - counter
            }
            trees.push(treeSymbol.repeat(addValue));

            alt = !alt
            counter += addValue;
        }

        return trees;
    }

    React.useEffect(() => {
        if(data && user) {
            setUserTrees(doTrees(user.trees))
            setOverallTrees(doTrees(data.trees))
        }
    }, [data, user])

    return (
        <SignInPageTemplate>
            {user && data ? (
                <div className={style.wrapper}>
                    <Card className={style.titleCard}>
                        <h1 style={{ fontFamily: 'Work Sans', fontSize: '2rem', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                            {`${treeSymbol} Nudge's ${treeSymbol}\nEcologi Forest`}
                        </h1>
                    </Card>
                    <h2>
                        Find our forest{' '}
                        <a href="https://ecologi.com/nudge" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                        .
                    </h2>

                    <br />

                    <Row className={style.bodyText}>
                        <br />
                        <Col xs="12" md="6">
                            <h2>
                                Your individual contributions so far have planted {user.trees}{' '}
                                {user.trees === 1 ? 'tree' : 'trees'} in the Nudge Forest.
                            </h2>
                            <br />

                            <div className={style.treeContainer}>
                                { userTrees.map((item, index) =>
                                    <h2>{item}</h2>
                                ) }
                            </div>
                        </Col>
                        <br />

                        <Col xs="12" md="6">
                            <h2>
                                Overall, we have planted {data.trees} {data.trees === 1 ? 'tree' : 'trees'} in the Nudge
                                Forest.
                            </h2>
                            <br />
                            <div className={style.treeContainer}>
                                { overallTrees.map((item, index) =>
                                    <h2>{item}</h2>
                                ) }
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        {user.experience ? (
                            <h2>
                                <br />
                                If you are accepted for a job through Nudge, we will plant an additional tree.
                            </h2>
                        ) : null}
                        {user.offers ? (
                            <h2>
                                <br />
                                You can make further contribution by posting job listings, and for every listing filled,
                                Nudge will plant 10 trees.
                            </h2>
                        ) : null}
                        <br />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(Forest, [USER, COMPANY]);
