import React from 'react';

import SignInPageTemplate from '../../../components/SignInPageTemplate';

import { ADMIN, COMPANY } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';

import NewCreateOffer from './NewCreateOffer';
import NewEditOffer from './NewEditOffer';

export const OFFER_ERROR = 'There are unresolved errors. To continue, please see the red-coloured tabs above.';
const NewOfferPage = () => {
    const isEdit = window.location.href.includes('edit-contract');

    return (
        <SignInPageTemplate>
            <div>{isEdit ? <NewEditOffer /> : <NewCreateOffer />}</div>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(NewOfferPage, [COMPANY, ADMIN]);
