import React from 'react';
import { Button } from 'reactstrap';
import ClickButton from '../NudgeButton';

const SubmitButton = ({ text = 'Next' }) => (
    <ClickButton type="submit" color="success" data-testid="submit-button" className="w-100">
        {text}
    </ClickButton>
);

export default SubmitButton;
