import React from 'react';
import { debounce } from 'lodash';
import AppInput from '../AppInput';

import style from './style.module.scss';
import NudgeButton from '../buttons/NudgeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ColourInput = ({ id, formik, extraSet }) => {
    
    const onChange = (e) => {
        formik.setFieldValue(id, e.target.value);
        if(extraSet) extraSet(e.target.value)
    }
    
    const debouncedOnChange = debounce(onChange, 250);

    const random = () => {
        const input = document.getElementById(`colour-${id}`)

        const random = (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
        formik.setFieldValue(id, `#${random}`);
        input.value = `#${random}`;
        if(extraSet) extraSet(`#${random}`)
    }

    const changeColour = (value) => {
        const input = document.getElementById(`colour-${id}`);
        input.value = value;
        if(extraSet) extraSet(value)
    }

    return (
        <div className={style.wrapper}>
            <input
                id={`colour-${id}`}
                className={style.colour}
                type="color"
                defaultValue={formik.values[id]}
                onChange={(e) => debouncedOnChange(e)}
            />

            <div style={{ display: 'block', width: '100%' }}>
                <AppInput id={id} onChange={(e) => changeColour(e.target.value)} formik={formik} className={style.textInput} label="Colour" />
            </div>

            <NudgeButton style={{ height: '40px', width: '25%'}} color="success" onClick={random}><FontAwesomeIcon icon={['far', 'dice']}/></NudgeButton>
        </div>
    );

}

export default ColourInput;