const DURATION_OPTIONS = [
    { label: '0-1 YEARS', value: '0-1' },
    { label: '1-2 YEARS', value: '1-2' },
    { label: '2-3 YEARS', value: '2-3' },
    { label: '3-4 YEARS', value: '3-4' },
    { label: '4-5 YEARS', value: '4-5' },
    { label: '5+ YEARS', value: '5+' },
];

export default DURATION_OPTIONS;
