import PropTypes from 'prop-types';
import React from 'react';

import Skill from './Skill';
import style from './style.module.scss';
import { skillIconOptions } from '../../../forms/SkillsForm/SkillsFormBody';
import { Card, Col, Row } from 'reactstrap';
import { GetPageTheme } from '../../GenericCompanyProfile/HelperComponents';

const NonEditableSkills = ({ skills, showEdit, onEdit }) => {

    const theme = GetPageTheme();

    return (
        <>
            <Row className={style.textsWrapper}>
                { skills.map((el, index) => (el ? <Col xs={12} sm={6} md={6} lg={6}>
                    <Card id={`card-sub-${theme}`} style={{ display: 'flex', width: '100%', padding: '6px', marginBottom: '2%' }}>
                        <Skill title={skillIconOptions.find((item) => item.icon == el.icon)?.label} {...el} key={el.name} />
                    </Card>
                </Col> : null))}
            </Row>
        </>
    );
};

NonEditableSkills.propTypes = {
    skills: PropTypes.array.isRequired,
    showEdit: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
};

export default NonEditableSkills;
