import * as Yup from 'yup';

import { DEFAULT_PICTURE } from '../../../../consts/urls';

export const FIELDS = {
    PHOTO: 'photo',
    COMPANY_NAME: 'companyName',
    COMPANY_NUMBER: 'companyRegistrationNumber',
    COMPANY_ADDRESS: 'companyRegistrationAddress',
    DESCRIPTION: 'description',
    USERNAME: 'username',
    EMAIL: 'email',
    FEE: 'fee',
    VISION_STATEMENT: 'VisionStatement',
    VIDEO_LINK: 'VideoLink',
    PARENT_ID: 'parentId',
};

// eslint-disable-next-line max-len
const VIDEO_REG_EX =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

export const validationSchema = Yup.object({
    [FIELDS.COMPANY_NAME]: Yup.string().required('This field is required'),
    [FIELDS.COMPANY_NUMBER]: Yup.string().required('This field is required'),
    [FIELDS.COMPANY_ADDRESS]: Yup.string().required('This field is required'),
    [FIELDS.DESCRIPTION]: Yup.string().required('This field is required'),
    [FIELDS.USERNAME]: Yup.string().required('This field is required'),
    [FIELDS.EMAIL]: Yup.string().email('Invalid email address').required('This field is required'),
    [FIELDS.FEE]: Yup.number().typeError('This field should be a number').required('This field is required'),

    [FIELDS.VISION_STATEMENT]: Yup.string().required('This field is required'),
    [FIELDS.VIDEO_LINK]: Yup.string()
        .matches(VIDEO_REG_EX, 'Must be a valid YouTube link')
        .required('This field is required'),
});

export const initialValues = {
    [FIELDS.PHOTO]: DEFAULT_PICTURE,
    [FIELDS.COMPANY_NAME]: '',
    [FIELDS.COMPANY_NUMBER]: '',
    [FIELDS.COMPANY_ADDRESS]: '',
    [FIELDS.DESCRIPTION]: '',
    [FIELDS.USERNAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.FEE]: '10',
    [FIELDS.VISION_STATEMENT]: '',
    [FIELDS.VIDEO_LINK]: 'https://www.youtube.com/watch?v=',
    [FIELDS.PARENT_ID]: ' ',
};
