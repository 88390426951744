import React from 'react';
import Loader from '../../../components/Loader';
import SignInPageTemplate from '../../../components/SignInPageTemplate';

import { useContext } from 'react';
import { AuthContext } from '../../../contexts/auth/AuthContextWrapper';

import { IndustryData } from './JobReel/IndustryLookup';

import JobReel from './JobReel';

const JobsReel = () => {
    const industryData = IndustryData();
    const { user } = useContext(AuthContext);

    return user ? (
        <SignInPageTemplate>
            {industryData ? <JobReel industryData={industryData} user={user} /> : <Loader />}
        </SignInPageTemplate>
    ) : null;
};

export default JobsReel;
