import React from 'react';
import { Button } from 'reactstrap';
import useSWR from 'swr';

import { authFetcher, authPostData, NEVER_REFRESH } from '../../../../../../api/tools';
import { capitaliseFirstLetter } from '../../../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { API_DASHBOARD_ADMIN, API_DASHBOARD_CLOOD, API_DASHBOARD_COMPANY } from '../../../../../../consts/urls';
import { ADMIN } from '../../../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../../../contexts/auth/AuthProtectedRouter';
import ClickButton from '../../../../../../components/buttons/NudgeButton';

const TestingGround = ({ id }) => {
    const [alert, setAlert] = React.useState(``);
    const [progress, setProgress] = React.useState('');

    async function retainCases() {
        try {
            const response = await authPostData({}, `${API_DASHBOARD_CLOOD}/Projects/RetainAllOffers`, {});
        } catch (e) {
            console.log('Something went wrong: ' + e.message);
        }
    }

    return (
        <>
            {progress ? <h5>{progress}</h5> : null}
            {alert ? <h5>{alert}</h5> : null}
            <ClickButton onClick={retainCases} color={'success'}>
                Post
            </ClickButton>
        </>
    );
};

export default withAuthProtectedRouter(TestingGround, [ADMIN]);
