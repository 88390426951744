import React from 'react';
import NudgeButton from '../../../components/buttons/NudgeButton';
import { Card, Col, Row } from 'reactstrap';
import { GetPageTheme, sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';

import style from '../style.module.scss';
import useSWR from 'swr';
import { NEVER_REFRESH, authFetcher, authPostData } from '../../../api/tools';
import { API_DASHBOARD_COMPANY } from '../../../consts/urls';
import Loader from '../../../components/Loader';
import useModal from '../../../hooks/useModal';
import CreateEmployeeModal from './CreateEmployeeModal';
import { Link } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';

const EmployeeCard = ({ }) => {

    const theme = GetPageTheme();
    const { data, mutate } = useSWR(`${API_DASHBOARD_COMPANY}/Employees`, authFetcher, NEVER_REFRESH);
    
    const { open: isOpen, toggle } = useModal();

    const [dismissOpen, setDismissOpen] = React.useState();
    const toggleDismiss = () => { setDismissOpen(); }


    const [sending, setSending] = React.useState(false);

    async function dismissEmployee(id) {
        setSending(true);

        const response = await authPostData({}, `${API_DASHBOARD_COMPANY}/Employees/${id}/Dismiss`)

        await mutate();
        setSending(false);
    }

    return (<>
        <ConfirmModal employee={dismissOpen} toggle={toggleDismiss} onClick={() => dismissEmployee(item.id)}/>
        <CreateEmployeeModal isOpen={isOpen} toggle={toggle} refresh={mutate}/>
        { data ? <Card id={`card-${theme}`} style={{ height: '450px', padding: '28px' }}>
            <Row>
                <Col xs={12} md={6}>
                    <h5 class="w-100">Employee Accounts</h5>
                </Col>
                <Col xs={12} md={6}>
                    <NudgeButton onClick={toggle} className={style.subAccountAdd}>Create Employee</NudgeButton>
                </Col>
            </Row>
            <Card id={`card-sub-${theme}`} style={{ height: '340px', padding: '12px', overflowY: 'auto' }}>
                { sort_by_key(data, 'applied', true).map((item, index) => <Card style={{ padding: '12px', marginBottom: '12px' }}>
                    <Link target="_blank" rel="noopener noreferrer" to={`/company/contractors/${item.id}`}><h5>{item.name}</h5></Link>
                    <p style={{ margin: 0}}>{item.email}</p>
                    <p class="text-right" style={{ marginBottom: '6px' }}>Jobs Applied For: {item.applied}</p>
                    <NudgeButton disabled={sending} color="danger" onClick={() => setDismissOpen(item?.name)}>Dismiss</NudgeButton>
                </Card>)}
            </Card>
        </Card> : <Loader message="Loading employees"/> }
    </>);
}

export default EmployeeCard;