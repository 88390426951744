import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Button } from 'reactstrap';

import { useLoader } from '../../../../components/Loader';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import ClickButton from '../../../../components/buttons/NudgeButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripePayForm = ({ secret, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { isLoading, setIsLoading } = useLoader();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const result = await stripe.confirmCardPayment(secret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (result.error) {
            onError(result);
        } else {
            onSuccess(result);
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />

            {isLoading ? <SimpleLoader /> : null}

            <div className="text-right mt-20">
                <ClickButton type="submit" disabled={!stripe || isLoading} color="adzuna" size="lg">
                    Pay
                </ClickButton>
            </div>
        </form>
    );
};

const StripePay = (props) => (
    <Elements stripe={stripePromise}>
        <StripePayForm {...props} />
    </Elements>
);

export default StripePay;
