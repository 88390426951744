import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { authPostData } from '../../../../api/tools';
import GenericModal from '../../../../components/modals/GenericModal';
import SuccessModal from '../../../../components/modals/SuccessModal';
import { API_GATEWAY } from '../../../../consts/urls';
import StripePay from './StripePay';
import ClickButton from '../../../../components/buttons/NudgeButton';

const PayButton = ({ contractId, id, refresh }) => {
    const [error, setError] = useState('');
    const [secret, setSecret] = useState('');
    const [success, setSuccess] = useState('');

    const onClick = async () => {
        const url = `${API_GATEWAY}/InvoiceCompany/Contracts/${contractId}/Timesheets/${id}/Pay`;
        const response = await authPostData({}, url);

        if (response.success) {
            setSecret(response.secret);
        } else {
            try {
                setError(JSON.parse(response.message));
            } catch {
                setError(response.message || 'An error occurred.');
            }
        }
    };

    const onClose = async () => {
        await refresh();
        setSuccess('');
        setSecret('');
    };

    const onSuccess = async () => {
        setSecret('');
        setSuccess('Payment successful.');
        await refresh();
    };

    const onError = async ({ error: { message } }) => {
        setSecret('');
        setError(message);
        await refresh();
    };

    return (
        <>
            <GenericModal
                isOpen={!!secret}
                toggle={() => setSecret('')}
                header="Payments Stripe"
                body={<StripePay secret={secret} onSuccess={onSuccess} onError={onError} />}
            />
            <SuccessModal isOpen={!!success} toggle={onClose} header="Payments" body={success} />
            <SuccessModal
                isOpen={error}
                toggle={() => setError('')}
                header="Error (payments)"
                body={error && (error.Message || error)}
            />

            <ClickButton onClick={onClick} className="w-100" color="adzuna">
                Pay
            </ClickButton>
        </>
    );
};

export default PayButton;
