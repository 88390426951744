import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import style from './style.module.scss';
import ClickButton from '../NudgeButton';

export const useEditButton = (startState = false) => {
    const [isOpen, setIsOpen] = useState(startState);
    const onClickEdit = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    return { isOpen, setIsOpen, onClickEdit, onClose };
};

const EditButton = ({ onEdit, show }) => (
    <div className={style.edit}>
        {show ? (
            <ClickButton className={style.buttonEdit} color="success" onClick={onEdit} type="submit">
                Edit
            </ClickButton>
        ) : null}
    </div>
);

EditButton.propTypes = {
    onEdit: PropTypes.func.isRequired,
    show: PropTypes.bool,
};

export default EditButton;
