import * as Yup from 'yup';

export const FIELDS = {
    ID: 'projectId',
    TITLE: 'title',
    DESCRIPTION: 'description',
};

export const TAB_NAMES = {
    DETAILS: 'details',
    CANDIDATES: 'candidates'
}

export const FIELD_NAMES = {
    [FIELDS.TITLE]: 'Project Title',
    [FIELDS.DESCRIPTION]: 'Project Description',
};

export const validationSchema = Yup.object({
    [FIELDS.TITLE]: Yup.string().required('This field is required.'),
    [FIELDS.DESCRIPTION]: Yup.string()
        .required('This field is required.')
        .min(0, 'What?')
        .max(1000, 'Bio cannot be larger than 1000 characters.'),
});

export const initialValues = {
    [FIELDS.ID]: 0,
    [FIELDS.TITLE]: '',
    [FIELDS.DESCRIPTION]: '',
};
