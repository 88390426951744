import React from 'react';
import { Card, Col, Row } from 'reactstrap';

import { authFetcher, authPostData } from '../../../../api/tools';
import Loader from '../../../../components/Loader';
import { API_DASHBOARD_CLOOD, API_DASHBOARD_COMPANY } from '../../../../consts/urls';
import { COMPANY } from '../../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../../contexts/auth/AuthProtectedRouter';
import { GetPageTheme, capitaliseFirstLetter, sort_by_key, sort_by_nested_keys } from '../../../../components/pages/GenericCompanyProfile/HelperComponents';
import { useParams } from 'react-router-dom';


import UserCard, { recordSmartMatchAndNotify } from './UserCard';
import NudgeTooltip from '../../../NudgeTooltip';
import NudgeButton from '../../../buttons/NudgeButton';
import { MATCH_STATUSES_DICTIONARY } from '../../../../consts/matchOfferStatuses';
import NudgeTabs, { UseTabs } from '../../../NudgeTabs';

const CompanyMatching = ({ user, offer, help, mutate, offerMatches, setAlert }) => {
    const { id } = useParams();

    const [error, setError] = React.useState('');
    const [matches, setMatches] = React.useState();
    const [key, setKey] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [inviting, setInviting] = React.useState(false);
    
    const { tab, setTab } = UseTabs('followers');

    const weighted_attrs = [
        { id: 'services_Required', name: 'services required' },
        { id: 'remuneration', name: 'remuneration' },
        //{ id: "industry", name: 'industry'  },
        { id: 'education', name: 'education' },
        { id: 'certificates', name: 'certificates' },
        { id: 'skills', name: 'skills' },
        { id: 'experience', name: 'experience' },
    ];

    function calculateMatchPercent(user) {
        let total = 0;

        weighted_attrs.forEach((item) => {
            let attr = user.match_explanation.find((ex) => item.name.toLowerCase() == ex.field.toLowerCase());
            if (attr) {
                let sim = attr.similarity;
                let weight = offer.weights[item.id];

                if (weight > 0) {
                    total += (sim / weight) * 100;
                } else {
                    total += sim * 100;
                }
            }
        });

        return total / weighted_attrs.length;
    }

    React.useEffect(() => {
        setKey(!key);
    }, [matches]);
    
    function directUser(userId) {
        window.open(`/company/offer/${id}/contractors/${userId}`, '_blank', 'noreferrer');
    }

    async function getMatches() {
        setSending(true);

        try {
            const response = await authFetcher(`${API_DASHBOARD_CLOOD}/Offers/GetSmartMatches/${id}`);

            var IDs = [];
            if (response?.bestK.length) {
                IDs = response?.bestK.map((item) => item.Id);

                const contractorProfiles = await authPostData(IDs, `${API_DASHBOARD_COMPANY}/Contractors/GetList`);

                if (response?.bestK.length) {
                    response.bestK.forEach((item) => {
                        const matchedContractor = contractorProfiles.find(
                            (contractor) => contractor?.id == item?.Id,
                        );
                        if (matchedContractor) {
                            item.profileJob = matchedContractor.job.name;
                            item.name = matchedContractor.name;

                            item.refId = matchedContractor.refId || 0

                            item.totalScore__ = calculateMatchPercent(item);
                        }
                    });

                    const best = {
                        bestK: sort_by_key(response?.bestK, 'totalScore__').reverse(),
                    };
                    setMatches(best);
                }
            } else {
                setError(error + `Something went wrong.`);
            }
        } catch (e) {
            setError(error + `Something went wrong: ${e.message}\n`);
        }

        setSending(false);
    }

    function findFollowerInMatches(follower) {
        if(tab == 'employees') return "Cannot smart match with internal candidates.";

        var fId = follower.id;

        var match = matches?.bestK?.find(p => p.Id == id);
        var localMatch = offerMatches?.find(p => p.contractor.id == id);

        if(match) {
            return `${match.totalScore__.toFixed(1)}%`;
        } else if(localMatch) {
            if(localMatch.smartMatch) {
                return `${localMatch.smartMatch?.totalScore.toFixed(1)}%`;
            } else {
                return tab == 'followers' ? "No results" : "Cannot smart match with internal candidates.";
            }
        } else {
            return tab == 'followers' ? "No results" : "Cannot smart match with internal candidates.";
        }
    }

    async function inviteCandidate(user, userId, offerId) {
        setInviting(true);

        var weights = offer.weights;
        var body = {};
        if(user) {
            body = {
                Services: user.match_explanation.find((i) => i.field == "Services Required")?.similarity / weights["services_Required"] || 0,
                Remuneration: user.match_explanation.find((i) => i.field == "Remuneration")?.similarity / weights["remuneration"] || 0,
                Work_Mode: user.match_explanation.find((i) => i.field == "Work Mode")?.similarity || 0,
                Education: user.match_explanation.find((i) => i.field == "Education")?.similarity / weights["education"] || 0,
                Experience: user.match_explanation.find((i) => i.field == "Experience")?.similarity / weights["experience"] || 0,
                Freelance: user.match_explanation.find((i) => i.field == "Freelance")?.similarity || 0,
                User_Values: user.match_explanation.find((i) => i.field == "Values")?.similarity || 0,
                Certificates: user.match_explanation.find((i) => i.field == "Certificates")?.similarity / weights["certificates"] || 0,
                Skills: user.match_explanation.find((i) => i.field == "Skills")?.similarity / weights["skills"] || 0,

                Feedback: JSON.stringify(user.feedback)
            }
        }
    
        var url = `${API_DASHBOARD_COMPANY}/Offers/${offerId}/Match/${userId}`;
        const response = await authPostData(body, url);
    
        await mutate();
        setInviting(false);
    };

    const theme = GetPageTheme();
    return (
        <Row>
            <Col md={7}>
                { !matches ? <>
                    <NudgeTooltip style={{ paddingBottom: '12px' }} label={`Klara Smart Matching`} id={'klara-no_matches'} large>
                        Klara is a matching model that matches candidates to your
                        opportunity. Click the button to continue.
                    </NudgeTooltip>
                    <NudgeButton disabled={sending} color="success" onClick={getMatches}>Get New Smart Matches</NudgeButton>
                </> : null }
                
                { offerMatches.filter(p => p.smartMatch != null).length && !matches && !sending ? <div style={{ marginTop: '18px' }}>
                    <h5>
                        You will find previously smart matched users below, and the scores they received at the time of matching.
                    </h5>
                    { sort_by_nested_keys(offerMatches.filter(p => p.smartMatch != null), ['smartMatch', 'totalScore'], true).map((match, index) => 
                        <>
                            <UserCard 
                                user={null} 
                                offerId={id}
                                index={index}
                                weights={offer.weights} 
                                match={match}
                                inviteCandidate={inviteCandidate}
                                sending={inviting}
                                setAlert={setAlert}
                            />
                        </>
                    )}
                </div> : null }

                {!sending ? (
                    <>
                        { matches ? (
                            !matches?.bestK.length ? (
                                <h5>No matches returned from the matching model.</h5>
                            ) : null
                        ) : null}

                        { matches ? (
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <NudgeTooltip style={{ paddingBottom: '12px' }} label={`Klara Matches`} id={'klara-match'} large>
                                                Klara is a matching model that matches candidates to your
                                                opportunity. These are her ranked suggestions:
                                            </NudgeTooltip>
                                        </Col>
                                    </Row>
                                    {matches ? (
                                        matches.bestK.map((item, index) => (
                                            <UserCard 
                                                user={item} 
                                                offerId={id}
                                                index={index}
                                                weights={offer.weights} 
                                                match={offerMatches.find((i) => i.contractor.id == item.Id)}
                                                inviteCandidate={inviteCandidate}
                                                sending={inviting}
                                                setAlert={setAlert}
                                            />
                                        ))
                                    ) : (
                                        <h5>Error 1</h5>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <h5 style={{ color: 'red', fontSize: '1.4rem' }}>{error}</h5>
                        )}
                    </>
                ) : (
                    <Loader message="Klara is finding your ideal candidates. This may take a while" />
                )}
            </Col>

            

            <Col md={5}>
                <NudgeTabs
                    selected={tab}
                    setTab={setTab}
                    tabs={[
                        { id: 'followers', label: 'Followers', condition: !!user?.followers?.length },
                        { id: 'employees', label: 'Employees', condition: !!user?.employees?.length },
                    ]}
                    buttons={1}
                />


                    <NudgeTooltip style={{ paddingBottom: '12px' }} label={capitaliseFirstLetter(tab)} id={'followers'} large>
                        {tab == 'followers' ? "These are users who have noted they are interested in your job postings." : "These are employees who have been assigned to this profile by you."

                        + "\n\nInvite to apply: Sends a request to this candidate to apply for this job if interested."

                        + "\n\nRecord Results: If this option appears, the candidate has been signed up by an expert recruiter. This will notify the recruiter and let them know you're interested in this candidate."}
                    </NudgeTooltip>

                { user?.followers ? <>
                    { (tab == 'followers' ? user?.followers : tab == 'employees' ? user?.employees : []).map((follower, index) => <Card style={{ padding: '12px', marginBottom: '12px' }}>
                        <Row>
                            <Col xs={12} md={12} lg={7}>
                                <h5 style={{ width: '100%' }}>{follower.name}</h5>
                            </Col>
                            <Col xs={12} md={12} lg={5}>
                                <h5 style={{ textAlign: 'right' }}>{ findFollowerInMatches(follower) }</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={6}>
                                { offerMatches.find((i) => i.contractor.id == follower.id) ? <h5 style={theme == 'dark' ? { color: 'white', backgroundColor: 'red', padding: '6px', borderRadius: '6px' } : { color: 'red' }}>{MATCH_STATUSES_DICTIONARY[offerMatches.find((i) => i.contractor.id == follower.id)?.statusText]}</h5> : null }
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <div style={{ display: 'flex', columnGap: '6px' }}>
                                    { tab == 'followers' ? <>
                                        { follower.refId != 0 && matches?.bestK?.find(p => p.Id == follower.id) ? <NudgeButton style={{ width: '100%' }} disabled={sending || inviting} color="success" onClick={() => recordSmartMatchAndNotify(matches?.bestK?.find(p => p.Id == follower.id), offer.weights, id, setAlert)}>
                                            Record Results
                                        </NudgeButton> : null }

                                        { !offerMatches.find((i) => i.contractor.id == follower.id) && follower.refId == 0 && !matches?.bestK?.find(p => p.Id == follower.id) ? <NudgeButton disabled={sending || inviting} style={{ width: '100%' }} color="success" onClick={() => inviteCandidate(null, follower.id, id)}>
                                            Invite to Apply
                                        </NudgeButton> : null }

                                        { !offerMatches.find((i) => i.contractor.id == follower.id) && follower.refId == 0 && matches?.bestK?.find(p => p.Id == follower.id) ? <NudgeButton style={{ width: '100%' }} disabled={sending || inviting} color="success" onClick={() => inviteCandidate(matches?.bestK?.find(p => p.Id == follower.id), follower.id, id)}>
                                            Invite to Apply
                                        </NudgeButton> : null }
                                    </> : null }

                                    <NudgeButton style={{ width: '100%' }} color="success" onClick={() => directUser(follower.id)}>View Profile</NudgeButton>
                                </div>
                            </Col>  
                        </Row>
                    </Card>)}
                </> : <i>No {tab}.</i> }
            </Col>
        </Row>
    );
};

export default withAuthProtectedRouter(CompanyMatching, [COMPANY]);
