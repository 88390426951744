import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

import Person from '../../assets/images/welcomePerson.png';
import SignUpNavbar from '../../components/navbars/SignUpNavbar';
import PageTemplate from '../../components/PageTemplate';
import { URLS } from '../../consts/urls';
import style from './style.module.scss';
import ClickButton from '../../components/buttons/NudgeButton';

const Welcome = () => {
    const navigate = useNavigate();
    const onClickStart = () => navigate(URLS.DASHBOARD);

    return (
        <div className={style.page}>
            <Container fluid>
                <SignUpNavbar className={style.navbar} white />
            </Container>

            <PageTemplate data-testid="WelcomePage" className="center-width-height h-100vh-with-navbar">
                <div className="w-100 h-100">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div className="text-center">
                                    <img src={Person} alt="Person" className={style.person} />
                                </div>
                                <div className="text-center mt-40">
                                    <h1 className={style.title}>Congrats! Welcome to the Nudge Nation.</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ size: 2, offset: 5 }}>
                                <ClickButton className={`w-100 mt-40 ${style.button}`} onClick={onClickStart}>
                                    Let's start!
                                </ClickButton>
                            </Col>
                        </Row>
                        <div className={style.info}>
                            Please check your e-mail (including spam file) to verify your Nudge account.
                        </div>
                    </Container>
                </div>
            </PageTemplate>
        </div>
    );
};

export default Welcome;
