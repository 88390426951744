import React from 'react';
import Select from 'react-select';

import { Input } from 'reactstrap';
import { NO_REFRESH_INTERVAL, authFetcher, fetcherWithAuthorizationHeader } from '../../../../../api/tools';
import { API_DASHBOARD_ADMIN, API_DASHBOARD_WILLO, URLS } from '../../../../../consts/urls';
import NudgeButton from '../../../../../components/buttons/NudgeButton';

import useSWR from 'swr';
import { customStyles } from '../../../../../components/SelectField';
import { sort_by_key } from '../../../../../components/pages/GenericCompanyProfile/HelperComponents';

const TestGetShareCode = () => {

    var { data } = useSWR(`${API_DASHBOARD_ADMIN}/Matches`, authFetcher, NO_REFRESH_INTERVAL);

    const [selectedMatch, setSelectedMatch] = React.useState();

    const [shareCode, setShareCode] = React.useState("");
    const [error, setError] = React.useState("");
    async function getCode() {
        var pKey = "";
        setError("");
        setShareCode("");

        try {
            pKey = await authFetcher(`${API_DASHBOARD_WILLO}/Participants/${selectedMatch.value.id}`);
        } catch(e) {
            console.log(e.message)
        }

        var share_code = "";
        if(pKey?.share_code) {
            share_code = pKey?.share_code;
        }
        else {
            if(pKey?.key) {
                const response = await fetcherWithAuthorizationHeader(`https://api.willotalent.com/api/integrations/v2/participants/${pKey.key}`, `rflafwr3v5bqm4r19ytfuutnjjbcs1o13004`);
    
                response?.answers.forEach((item, index) => {
                    if(item.additional_info.share_code) {
                        share_code = item.additional_info.share_code;
                    }
                })
            }
            else {
                setError("Participant key not found.")
            }
        }

        if(share_code) {
            setShareCode(share_code);
        } else {
            setError("Share code not found.")
        }

    }

    return data ? (<>
        <div style={{ display: 'block', columnGap: '12px', width: '100%' }}>
            <Select
                styles={customStyles()}
                options={sort_by_key(data, 'id', true).map((item) => ({ label: `${item.id} - ${item.contractor.name} - ${item.offer.company.name} - ${item.offer.title}`, value: item }))}
                onChange={(option) => {
                    setSelectedMatch(option);
                }}
                placeholder={'Matches'}
                value={selectedMatch}
                isSearchable={false}
            />
            <br/>
            <NudgeButton onClick={getCode} color="success">Click</NudgeButton>
        </div>
        <br/>

        { shareCode ? <h5>Share code is: {shareCode}</h5> : null }
        { error ? <h5>{error}</h5> : null }
    </>) : null;
}

export default TestGetShareCode;