import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import LogoWhite from '../../../assets/images/nudge_logo_white.svg';
import Logo from '../../../assets/images/Nudge_logo.svg';
import { URLS } from '../../../consts/urls';
import { Button } from 'reactstrap';
import style from './style.module.scss';
import ClickButton from '../../buttons/NudgeButton';

const SignUpNavbar = ({ className, white }) => (
    <Container fluid data-testid="SignUpNavbar" className={`pt-40 ${className}`}>
        <nav>
            <Row>
                <Col>
                    <Link to={URLS.HOME}>
                        <img src={white ? LogoWhite : Logo} alt="Nudge Logo" className="logo" />
                    </Link>
                </Col>
                <Col>
                    <div className={style.signUpNavbarContent}>
                        <Link to={URLS.HOME} className="mr-3">
                            <ClickButton color="success">Home</ClickButton>
                        </Link>
                        <Link to={URLS.CONTACTPAGE}>
                            <ClickButton color="success">Contact</ClickButton>
                        </Link>
                    </div>
                </Col>
            </Row>
        </nav>
        <br />
    </Container>
);

export default SignUpNavbar;

// Changed contact to a page rather than just return to home page.
