import React from 'react';
import useSWR from 'swr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import { authFetcher, NEVER_REFRESH } from '../../../api/tools';
import { API_DASHBOARD_CONTRACTOR, URLS } from '../../../consts/urls';
import { USER } from '../../../consts/userRoles';
import { withAuthProtectedRouter } from '../../../contexts/auth/AuthProtectedRouter';
import style from './style.module.scss';
import { GetPageTheme, ResolveSalary, sort_by_key } from '../../../components/pages/GenericCompanyProfile/HelperComponents';
import Loader from '../../../components/Loader';
import SearchBox from '../../../components/SearchBox';
import SignInPageTemplate from '../../../components/SignInPageTemplate';

import { MATCH_STATUSES } from '../../../consts/matchOfferStatuses';
import { MATCH_STATUSES_DICTIONARY } from '../../../consts/matchOfferStatuses';
import ClickButton from '../../../components/buttons/NudgeButton';
import moment from 'moment';

function getStatusStyle(status) {
    switch (status) {
        case MATCH_STATUSES.CompanyAccepted:
            return style.accepted;
        case MATCH_STATUSES.Rejected:
        case MATCH_STATUSES.CompanyRejected:
        case MATCH_STATUSES.AdminRejected:
            return style.rejected;
        default:
            return style.status;
    }
}

const InterviewsPage = () => {
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();

    const [offerSearch, setOfferSearch] = React.useState('');
    const [interviews, setInterviews] = React.useState();
    const theme = GetPageTheme();
    const [offer, setOffer] = React.useState();

    const { data, isValidating } = useSWR(`${API_DASHBOARD_CONTRACTOR}/Matches`, authFetcher, NEVER_REFRESH);

    function handleSearch(interviews) {
        
        const searchString = (item) => { return `${item.title}${MATCH_STATUSES_DICTIONARY[item.status]}` }

        return interviews.filter((item) => searchString(item).toUpperCase().includes(offerSearch.toUpperCase()));
    }

    //
    function fillInterviews(d) {
        const interviews = [];
        d.filter((item, index) => item.statusId != 2 ).forEach((p) => {
            const obj = {
                id: p.id,
                offerId: p.offerId,
                company: p.company,
                title: p.title,
                salary: ResolveSalary(p.rangeExpected, p.currencyType, p.frequency, p.workType),
                start: p.startDate,
                statusId: p.statusId,
                status: p.matchStatus,
                messages: p.messages,
                unreadMessages: p.unread,
                dateUpdated: moment(p.lastUpdated).unix()
            };
            interviews.push(obj);
        });

        setInterviews(handleSearch(sort_by_key(interviews, 'dateUpdated', 1)));
    }

    React.useEffect(() => {
        if (data) {
            fillInterviews(data);
            reset();
        }
    }, [data, offerSearch]);

    React.useEffect(() => {
        if (interviews) {
            setOffer();
            setOffer(interviews[selectedIndex]);
        }
    }, [selectedIndex]);

    function reset() {
        setSelectedOffer();
        setOffer();
    }

    return (
        <SignInPageTemplate>
            <h5 className={style.title}>Interviews</h5>
            <Row>
                {data ? (
                    <>
                        {' '}
                        <Col sm={6} md={6} lg={4}>
                            <SearchBox value={offerSearch} setValue={setOfferSearch} />
                            <div className={style.interviewContainer}>
                                {interviews
                                    ? interviews.map((item, index) => (
                                          <Card
                                              className={`${style.offerCard} ${
                                                  selectedOffer?.offerId == item.offerId ? style.pick : null
                                              }`}
                                              onClick={() => setSelectedOffer(item)}
                                          >
                                              <h5 className={style.jobTitle}>
                                                  {item.title}
                                                  <h4>{item.company.name}</h4>
                                              </h5>

                                              <div className={style.applicants}>
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'envelope']}
                                                        className={style.applicantIcon}
                                                    />
                                                    <h5>{item.messages || 0}{item.unreadMessages > 0 ? ` (${item.unreadMessages})` : ""}</h5>
                                                </div>

                                              <h5 className={style.jobDetails}>
                                                  {item.salary} - starts {item.start}
                                              </h5>
                                              <h5 className={getStatusStyle(item.status)}>
                                                  {MATCH_STATUSES_DICTIONARY[item.status]}
                                              </h5>
                                          </Card>
                                      ))
                                    : null}
                            </div>
                        </Col>
                        {selectedOffer ? (
                            <>
                                <div className={style.mobileDivider} onClick={reset} />
                                <Col sm={6} md={6} lg={8} className={style.interviewsWrapper} id={`card-sub-${theme}`}>
                                    <Card className={style.jobCard}>
                                        <h5 className={style.jobTitle}>
                                            {selectedOffer.title}
                                            <h4>
                                                <Link to={`/companies/${selectedOffer.company.id}`}>{selectedOffer.company.name}</Link>
                                            </h4>
                                        </h5>
                                        <FontAwesomeIcon
                                            icon={['fas', 'chevron-square-left']}
                                            className={theme == 'light' ? style.backIcon : style.backIconDark}
                                            onClick={reset}
                                        />
                                        {selectedOffer.salary} - starts {selectedOffer.start}
                                        <Row style={{ marginTop: '12px' }}>
                                            <Col md={6}>
                                                <ClickButton
                                                    style={{ width: '100%', marginBottom: '12px' }}
                                                    color="success"
                                                    onClick={() => navigate(`/meeting/${selectedOffer.id}`)}
                                                >
                                                    View Interview
                                                </ClickButton>
                                            </Col>
                                            <Col md={6}>
                                                <ClickButton
                                                    style={{ width: '100%' }}
                                                    color="success"
                                                    onClick={() => navigate(`${URLS.OFFERS}/${selectedOffer.offerId}`)}
                                                >
                                                    View Offer Page
                                                </ClickButton>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </>
                        ) : null}
                    </>
                ) : null}
                {isValidating && !data ? <Loader /> : null}
                {!isValidating && !data ? <h5>No interviews found.</h5> : null}
            </Row>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(InterviewsPage, [USER]);
