import 'react-datepicker/dist/react-datepicker.css';
import './styles/style.scss';
import './styles/_page-themes.scss';
import './fontAwsome';
import './i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { ProSidebarProvider } from 'react-pro-sidebar';

import App from './App';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ProSidebarProvider>
                <GoogleReCaptchaProvider reCaptchaKey="6Lec42whAAAAAFk9Alq-Jx10axaJZkEPyD3euYjt">
                    <App />
                </GoogleReCaptchaProvider>
            </ProSidebarProvider>
        </HelmetProvider>
    </React.StrictMode>,
);
