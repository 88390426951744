import { Formik } from 'formik';
import React, { useState } from 'react';
import { Alert, Card, Col, Row } from 'reactstrap';

import { AuthContext } from '../../contexts/auth/AuthContextWrapper';

import SignInPageTemplate from '../../components/SignInPageTemplate';
import { COMPANY, USER, ADMIN, RECRUITER } from '../../consts/userRoles';
import { withAuthProtectedRouter } from '../../contexts/auth/AuthProtectedRouter';
import { changeUserPassword } from '../../contexts/auth/tools';
import ChangePasswordPageForm from './ChangePasswordPageForm';
import { initialValues, validationSchema } from './ChangePasswordPageForm/validation';
import DeleteProfile from './DeleteProfile';
import style from './style.module.scss';
import SubAccountCard from './SubAccountCard';
import useMobile from '../../hooks/useMobile';
import NudgeTabs from '../../components/NudgeTabs';
import { GetPageTheme } from '../../components/pages/GenericCompanyProfile/HelperComponents';
import EmployeeCard from './EmployeeCard';

export const ChangePasswordPage = () => {
    const [showOk, setShowOk] = useState(false);
    const { user, userRoles } = React.useContext(AuthContext);
    const { isMobileWide } = useMobile();

    const theme = GetPageTheme(); 

    const callback = () => setShowOk(true);

    const toggle = () => setShowOk(!showOk);

    const [error, setError] = useState('');
    const toggleError = () => setError('');

    const onError = ({ message }) => setError(message);

    const props = {
        initialValues,
        validationSchema,
        onSubmit: (values) => changeUserPassword(values, callback, onError),
    };

    const TABS = {
        PASSWORD: 'password',
        SUB_COMPANIES: 'subCompanies',
        SUB_USERS: 'subUsers'

    }
    const [selectedTab, setSelectedTab] = React.useState(TABS.PASSWORD);

    return (
        <SignInPageTemplate>
            <div>
                <Row className={style.settingsContainer}>
                    <Col md={12} lg={4}>
                        
                        { isMobileWide ? <NudgeTabs
                            label="Profile Options"
                            selected={selectedTab}
                            setTab={setSelectedTab}
                            tabs={[
                                { id: TABS.PASSWORD, label: 'Current Offers', condition: true },
                                { id: TABS.SUB_COMPANIES, label: 'Sub-Accounts', condition: userRoles.includes('Company') },
                                { id: TABS.SUB_USERS, label: 'Sub-Users', condition: userRoles.includes("Company") }
                            ]}
                        /> : null }

                        { selectedTab == TABS.PASSWORD || !isMobileWide ? <Card className={style.card}>
                            <Alert color="success" isOpen={!!showOk} toggle={toggle}>
                                Password was changed
                            </Alert>

                            <Alert isOpen={!!error} toggle={toggleError} color="danger">
                                {error}
                            </Alert>


                            <h1 className={style.title}>Change Password</h1>
                            <Row>
                                <Col md={{ size: 12 }}>
                                    <Formik {...props}>
                                        {(formik) => <ChangePasswordPageForm formik={formik} deleteProfile={!userRoles.includes('Admin') ? <DeleteProfile /> : null} />}
                                    </Formik>
                                </Col>
                            </Row>
                        </Card> : null }
                    </Col>

                    { userRoles.includes('Company') ? (<>
                        { selectedTab == TABS.SUB_COMPANIES || !isMobileWide ? <Col md={12} lg={4}>
                            <SubAccountCard user={user} />
                        </Col> : null }
                        
                        { selectedTab == TABS.SUB_USERS || !isMobileWide ? <Col md={12} lg={4}>
                            <EmployeeCard />
                        </Col> : null }
                    </>) : null }
                </Row>
            </div>
        </SignInPageTemplate>
    );
};

export default withAuthProtectedRouter(ChangePasswordPage, [USER, COMPANY, ADMIN, RECRUITER]);
